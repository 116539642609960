import request from "@/utils/request";
export function getSalaryGroups(params) {
  return request({
    url: "/salary/api/v1/salaryGroups/listAllByCompanyId",
    method: "get",
    params: params
  });
}
export function deleteSalaryGroup(id) {
  return request({
    url: "/salary/api/v1/salaryGroups/".concat(id),
    method: "delete"
  });
}
export function addSalaryGroup(data) {
  return request({
    url: "/salary/api/v1/salaryGroups",
    method: "post",
    data: data
  });
}
export function selectSalaryGroupEmp(data) {
  return request({
    url: "/salary/api/v1/salaryGroups/selectSalaryGroupEmp",
    method: "post",
    data: data
  });
}
export function getSalaryDetail(id) {
  return request({
    url: "/salary/api/v1/salaryGroups/select/".concat(id),
    method: "get"
  });
}
export function editSalaryGroup(data) {
  return request({
    url: "/salary/api/v1/salaryGroups",
    method: "put",
    data: data
  });
}
export function getRules(params) {
  return request({
    url: "/salary/api/v1/taxRules/listAllByCompanyId",
    method: "get",
    params: params
  });
}
export function getdetail(id) {
  return request({
    url: "/salary/api/v1/taxRules/".concat(id),
    method: "get"
  });
}
export function editRule(data) {
  return request({
    url: "/salary/api/v1/taxRules",
    method: "put",
    data: data
  });
}
export function findTaxRule(id) {
  return request({
    url: "/salary/api/v1/taxRules/findTaxRule/".concat(id),
    method: "get"
  });
}