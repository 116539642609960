import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
import moment from "moment";
var mixin = new UploadMixin();
var formalType = ["试用", "正式", "实习"];
var columnDetail = [{
  title: "错误信息",
  dataIndex: "reason",
  fixed: "left",
  scopedSlots: {
    customRender: "reason"
  },
  ellipsis: true,
  width: 260
}];
import axios from "axios";
import qs from "qs";
export default {
  components: {},
  mixins: [mixin],
  data: function data() {
    return {
      spinning: false,
      count: 0,
      selectedList: [],
      succ: 0,
      fial: 0,
      errData: {},
      formalType: formalType,
      isChooseStaff: false,
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showQuickJumper: true
      },
      dataDetail: [],
      callback: false,
      dialogFailVisible: false,
      downloadurl: undefined,
      columnDetail: columnDetail,
      uploadImg: require("@/assets/img/uploadImg.png"),
      params: {
        templateName: "socBackImportTemplate.xlsx"
      },
      url: "/hr/api/v1/excel/template/download",
      excelName: "导入回款记录模板.xlsx"
    };
  },
  created: function created() {
    var companyId = this.$store.state.hr.companyId;
    this.action = "/hr/api/v1/empSocBackRecordRecords/importRecord";
  },
  methods: {
    beforeUpload: function beforeUpload(file) {
      var _this = this;
      if (this.fileList.length > 0) {
        this.$message("最多只能上传一个");
        return false;
      }
      this.fileList = [].concat(_toConsumableArray(this.fileList), [file]);
      if (this.fileList && this.fileList.length > 0) {
        var fileList = this.fileList;
        var formData = new FormData();
        fileList.forEach(function (file) {
          formData.append("file", file);
          formData.append("month", _this.$route.query.month);
          formData.append("year", _this.$route.query.year);
          formData.append("companyIds", _this.$route.query.companyIds);
        });
        this.uploading = true;
        axios({
          url: this.action,
          method: "post",
          data: formData,
          headers: this.headers
        }).then(function (res) {
          _this.reset();
          _this.handleSuccess(res.data);
          _this.uploading = false;
        });
      } else {
        this.reset();
        this.handleUploadError();
      }
      return false;
    },
    isGoBack: function isGoBack() {
      // if (this.callback) {
      //   this.callback = false;
      // } else {
      this.goback();
      // }
    },
    handleSuccess: function handleSuccess(res) {
      var _this2 = this;
      this.errData = res.data;
      var that = this;
      if (res.success && res.data !== null) {
        var numAll = res.data.allCount;
        var numFail = res.data.errorCount;
        var numSuc = res.data.successCount;
        if (that.showFaild) {
          that.Faildata = res.data.errorList;
          that.Faildata.forEach(function (item, index) {
            item = Object.assign(item, item.allMap);
          });
          that.showFaild(numAll, numSuc, numFail, that.Faildata);
        } else {
          if (numFail === 0) {
            this.$confirm({
              okText: "确定",
              title: "\u5171\u4FEE\u6539".concat(numAll, "\u4EBA\uFF0C").concat(numSuc, "\u4EBA\u6210\u529F\uFF0C").concat(numFail, "\u4EBA\u672A\u6210\u529F"),
              onOk: function onOk() {
                that.goback();
              },
              onCancel: function onCancel() {
                that.goback();
              }
            });
          } else {
            this.$confirm({
              okText: "确定",
              cancelText: "查看未成功名单",
              title: "\u5171\u4FEE\u6539".concat(numAll, "\u4EBA\uFF0C").concat(numSuc, "\u4EBA\u6210\u529F\uFF0C").concat(numFail, "\u4EBA\u672A\u6210\u529F"),
              onOk: function onOk() {
                that.goback();
              },
              onCancel: function onCancel() {
                that.Faildata = res.data.importFailList;
                that.dialogFailVisible = true;
              }
            });
          }
        }
      } else if (res.data === null) {
        this.$confirm({
          okText: "确定",
          title: res.msg
        });
      } else {
        this.$notification["error"]({
          message: "error",
          description: res.msg || "上传失败"
        });
        setTimeout(function () {
          _this2.reset();
        }, 2000);
      }
    },
    buCha: function buCha() {
      var token = this.$store.state.auth.token;
      axios({
        url: "/hr/api/v1/empSocBackRecordRecords/exportErrorList",
        // 后端提供的导出接口
        data: this.errData,
        // 传递后端需要的导出参数
        method: "post",
        responseType: "blob",
        headers: {
          token: token,
          "Content-Type": "application/json"
        }
      }).then(function (response) {
        var data = response.data;
        var url = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.href = url;
        link.download = "异常数据表.xlsx"; // 自定义导出文件名
        link.click();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    },
    handleFailClose: function handleFailClose() {
      this.dialogFailVisible = false;
      this.uploading = false;
      this.goback();
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
    },
    showFaild: function showFaild(numAll, numSuc, numFail, Faildata, downloadurl, columns) {
      this.columnDetail = [{
        title: "姓名",
        dataIndex: "name",
        scopedSlots: {
          customRender: "name"
        },
        ellipsis: true,
        width: 260
      }, {
        title: "身份证号",
        dataIndex: "idCard",
        scopedSlots: {
          customRender: "idCard"
        },
        ellipsis: true,
        width: 260
      }, {
        title: "回款途径",
        dataIndex: "wayStatus",
        scopedSlots: {
          customRender: "wayStatus"
        },
        ellipsis: true,
        width: 260
      }, {
        title: "回款金额",
        dataIndex: "amount",
        scopedSlots: {
          customRender: "amount"
        },
        ellipsis: true,
        width: 260
      }
      // {
      //   title: "错误信息",
      //   dataIndex: "reason",
      //   fixed: "left",
      //   scopedSlots: { customRender: "reason" },
      //   ellipsis: true,
      //   width: 260
      // }
      ];

      this.count = numAll;
      this.succ = numSuc;
      this.fial = numFail;
      this.dataDetail = Faildata;
      if (this.count === this.succ) {
        this.dialogFailVisible = true;
      } else {
        this.callback = true;
      }
      // this.downloadurl = downloadurl;
      // columns.forEach((item, index) => {
      //   this.columnDetail.push({
      //     title: `${item}`,
      //     dataIndex: `${item}`,
      //     align: "center",
      //     width: item === "身份证号" ? 220 : 150,
      //     scopedSlots: { customRender: `${item}` },
      //     ellipsis: true
      //     // fixed: item === "身份证号" || item === "姓名" ? "left" : null
      //   });
      // });
    }
  }
};