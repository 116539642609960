import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  path: "/console",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("xqjr-module-console/layout/index.vue"));
    });
  },
  children: [{
    path: "",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/index"));
        });
      }
    }]
  }, {
    path: "initSite",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/views/initSite.vue"));
      });
    }
  }, {
    path: "system",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/system/index"));
        });
      }
    }, {
      path: "moduleDetail",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/system/moduleDetail"));
        });
      }
    }, {
      path: "configRoleAuth",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/system/configRoleAuth"));
        });
      }
    }, {
      path: "/form-set/:id?",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/system/components/approval/form-list.vue"));
        });
      }
    }]
  }, {
    path: "messageType",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/messageConfig/messageType/index.vue"));
        });
      }
    }]
  }, {
    path: "businessConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "confign",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/businessConfig/confign"));
        });
      }
    }, {
      path: "toApplyFor",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/businessConfig/toApplyFor"));
        });
      }
    }, {
      path: "management",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/businessConfig/management"));
        });
      }
    }, {
      path: "paySalary",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/businessConfig/paySalary"));
        });
      }
    }, {
      path: "contractConfig",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/contractConfig/index"));
        });
      }
    }, {
      path: "messageConfig",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/messageConfig/index"));
        });
      }
    }, {
      path: "shebaoConfig",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/shebaoConfig/index"));
        });
      }
    }, {
      path: "insuranceCompany",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/shangyebaoxian/insuranceCompany/index"));
        });
      }
    }, {
      path: "insuranceForSite",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/shangyebaoxian/insuranceForSite/index"));
        });
      }
    }, {
      path: "taxConfig",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/taxConfig/index"));
        });
      }
    }]
  }, {
    path: "contract",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/contractConfig"));
        });
      }
    }]
  }, {
    path: "messageConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/messageConfig"));
        });
      }
    }]
  }, {
    path: "icongroup",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/icongroup"));
        });
      }
    }]
  }, {
    path: "site",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/site/index"));
        });
      }
    }, {
      path: "checkSite",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/site/checkSite"));
        });
      }
    }, {
      path: "configRole",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/site/configRole"));
        });
      }
    }]
  }, {
    path: "moduleConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/moduleConfig/index"));
        });
      }
    }]
  }, {
    path: "tableHeader",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/tableHeader/index"));
        });
      }
    }]
  }, {
    path: "templateConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      meta: {
        pageType: "console"
      },
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/templateConfig/index"));
        });
      }
    }]
  }, {
    path: "recruitmentPoolInformation",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      meta: {
        pageType: "console"
      },
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/recruitmentPoolInformation/index"));
        });
      }
    }]
  }, {
    path: "tempContractSetting",
    // 合同设置
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      meta: {
        pageType: "console"
      },
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/contract/type-manage/settingNew"));
        });
      }
    }]
  }, {
    path: "tempContractTypeSeal/:id",
    // 合同设置
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      meta: {
        pageType: "console"
      },
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/contract/type-manage/editSealNew"));
        });
      }
    }]
  }, {
    path: "calendarConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/calendarConfig/index"));
        });
      }
    }]
  }, {
    path: "parameter",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/parameter/index"));
        });
      }
    }]
  }, {
    path: "siteUsers",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/siteUsers/index"));
        });
      }
    }, {
      path: "detail",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/siteUsers/detail"));
        });
      }
    }]
  }, {
    path: "document",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/document/index"));
        });
      }
    }]
  }, {
    path: "weixinTemplate",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/weixinTemplate/index"));
        });
      }
    }]
  }, {
    path: "smsTemplate",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/smsTemplate"));
        });
      }
    }, {
      path: "add",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/smsTemplate/systemSms/add"));
        });
      }
    }, {
      path: "config",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/smsTemplate/systemSms/config"));
        });
      }
    }, {
      path: "tenantAdd",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/smsTemplate/tenantSms/add"));
        });
      }
    }, {
      path: "tenantConfig",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/smsTemplate/tenantSms/config"));
        });
      }
    }]
  }, {
    path: "appConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/appConfig/index"));
        });
      }
    }]
  }, {
    path: "log",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "actionLog",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/log/actionLog/index"));
        });
      }
    }, {
      path: "serviceLog",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/log/serviceLog/index"));
        });
      }
    }, {
      path: "loginLog",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/log/loginLog/index"));
        });
      }
    }]
  }, {
    path: "version",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "app",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/version/app"));
        });
      }
    }, {
      path: "web",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/version/web"));
        });
      }
    }, {
      path: "index",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/version/index"));
        });
      }
    }, {
      path: "addApp",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/version/components/addVersion"));
        });
      }
    }]
  }, {
    path: "settings",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/settings/index.vue"));
        });
      }
    }]
  }, {
    path: "trialList",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/trialList/index"));
        });
      }
    }]
  }, {
    path: "recruitmentPoolInformation",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/recruitmentPoolInformation/index"));
        });
      }
    }]
  }, {
    path: "systemFields",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/fields/systemFields/index"));
        });
      }
    }]
  }, {
    path: "systemTemplate",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/fields/systemTemplate/index"));
        });
      }
    }]
  }]
};