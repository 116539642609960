var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wrapper"
  }, [_c("a-tabs", {
    on: {
      change: _vm.onTabChange
    }
  }, [_c("a-tab-pane", {
    key: "wwj",
    attrs: {
      tab: "未完结"
    }
  }), _c("a-tab-pane", {
    key: "ywj",
    attrs: {
      tab: "已完结"
    }
  })], 1), _c("div", {
    staticClass: "main"
  }, [_c("ListContent", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeTab === "wwj",
      expression: "activeTab === 'wwj'"
    }],
    attrs: {
      isFinish: false,
      eventTypeLst: _vm.eventTypeLst,
      dispatchLst: _vm.dispatchLst,
      templateLst: _vm.templateLst,
      companyLst: _vm.companyLst
    }
  }), _c("ListContent", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeTab === "ywj",
      expression: "activeTab === 'ywj'"
    }],
    attrs: {
      isFinish: true,
      eventTypeLst: _vm.eventTypeLst,
      dispatchLst: _vm.dispatchLst,
      templateLst: _vm.templateLst,
      companyLst: _vm.companyLst
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };