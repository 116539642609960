import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import { mapGetters } from "vuex";
import moment from "moment";
import "moment/locale/zh-cn";
import { onlineNotice, deleteSysNotice, offlineNotice, updateSysNotices, screenEmployees, getNoticeById, getPageEmpList } from "@/api/company/index";
import VueWangeditor from "@/components/ZXR/vueWangeditor";
export default {
  components: {
    VueWangeditor: VueWangeditor
  },
  data: function data() {
    var _this = this;
    return {
      fileList: [],
      spinning: false,
      previewVisible: false,
      bgImage: {
        backgroundImage: "url(" + require("@/assets/img/iphone.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
      },
      checkAllText: "全选当页",
      staffVisible: false,
      staffrightData: [],
      staffData: [],
      staffFrom: {
        postType: "",
        companyId: "",
        pageNo: 1,
        pageSize: 10
      },
      checkAll: false,
      visible: false,
      confirmLoading: false,
      treeData: [],
      tabbleData: [],
      pageCount: 0,
      pageTitle: "",
      // 内容区域
      caeatForm: {
        companyId: "",
        // 公司id
        content: "",
        // 公告内容
        contentHasStyle: "",
        // 带样式的公告内容
        finalUserIds: "",
        // 员工对应的用户
        isPublishNow: true,
        // 公告立即发布
        formalType: 2,
        // 员工正式类型(1 正式 2试用 0无状态)
        isTop: false,
        // 是否置顶(1:置顶,0:不置顶)
        noticeType: ["1"],
        // 通知类型
        offlineDate: "",
        // 下线时间
        props: "",
        // 补充信息
        publishDate: "",
        // 发布时间
        publishEmpIds: [],
        // 公告发布范围,员工id(部分员工时使用)
        publishEmpRange: "",
        // 部分发布的时候选择的员工id(用,分隔)
        publishEmpRangeIsAll: true,
        // 是否全公司发布(1:全公司,0:部分员工)
        publishEmpType: [],
        // 部分员工类型时选择的员工类型code(用,分隔)
        publishEmpTypeIsAll: true,
        // 是否全部员工类型发布(1:全部员工类型,0:部分员工类型)
        publishUserId: "",
        // 发布公告的用户id
        title: "",
        // 公告标题
        topDateCreate: "" // 发起置顶时间
      },

      options: [{
        label: "微信",
        value: "1"
      }
      // { label: "短信", value: "2" },
      // { label: "邮件", value: "3" }
      ],

      fold: false,
      chooseMemberVisible: false,
      defaultSelectedKeys: [],
      selectedList: [],
      memberColumn: [{
        title: "员工姓名",
        dataIndex: "name",
        align: "center",
        width: 200
      }, {
        title: "身份证号",
        dataIndex: "idCard",
        align: "center",
        width: 200,
        customRender: function customRender(text) {
          return text != null ? text : "--";
        }
      }, {
        title: "人员性质",
        dataIndex: "postType",
        align: "center",
        width: 200,
        customRender: function customRender(text) {
          return _this.$store.state.enums.postTypeArr[text - 1];
        }
      }, {
        title: "手机号码",
        dataIndex: "phone",
        align: "center",
        width: 250,
        customRender: function customRender(text) {
          return text != null ? text : "--";
        }
      }],
      dateString: ""
    };
  },
  computed: _objectSpread({}, mapGetters(["isJF"])),
  filters: {
    SelectedString: function SelectedString(data) {
      var _data = JSON.parse(JSON.stringify(data));
      var str = "";
      if (_data.length > 0) {
        str = _data.slice(0, 2).map(function (item) {
          return item.name;
        }).join(",");
      }
      return str;
    }
  },
  created: function created() {
    this.caeatForm.companyId = this.$store.state.hr.companyId;
    if (this.$route.query.sign === "0") {
      this.pageTitle = "新建公告";
    } else {
      this.pageTitle = "详情";
      this.getNoticeByIdDel(this.$route.query.id);
    }
  },
  methods: {
    saveFileList: function saveFileList(data) {
      this.fileList = data;
    },
    groupChange: function groupChange() {
      this.staffrightData = [];
      this.staffData = [];
      this.selectedList = [];
    },
    // 预览
    preview: function preview() {
      this.previewVisible = true;
    },
    publishEmpTypeIsAllChange: function publishEmpTypeIsAllChange(e) {
      this.caeatForm.publishEmpTypeIsAll = e.target.value;
    },
    onDet: function onDet() {
      var _this2 = this;
      deleteSysNotice(this.caeatForm.id).then(function (res) {
        _this2.$message.success("删除成功!");
        _this2.$router.go(-1);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    online: function online() {
      var _this3 = this;
      onlineNotice(this.caeatForm.id).then(function (res) {
        _this3.getNoticeByIdDel(_this3.$route.query.id);
        _this3.$message.success("上线成功!");
      }).catch(function (err) {
        _this3.$message.error("上线失败!");
        console.log("debug log --> ", err);
      });
    },
    // 下线
    offline: function offline() {
      var _this4 = this;
      offlineNotice(this.caeatForm.id).then(function (res) {
        _this4.$refs.editorStyle.isTxt = true;
        _this4.getNoticeByIdDel(_this4.$route.query.id);
        _this4.$message.success("下线成功!");
      }).catch(function (err) {
        _this4.$message.error("下线失败!");
        console.log("debug log --> ", err);
      });
    },
    // 修改
    save: function save() {
      var _this5 = this;
      console.log(this.caeatForm.contentHasStyle);
      var caeatForm = JSON.parse(JSON.stringify(this.caeatForm));
      caeatForm.empInfoList = caeatForm.empInfoList.map(function (z) {
        return z.id;
      });
      caeatForm.publishEmpIds = caeatForm.empInfoList;
      caeatForm.file = JSON.stringify(this.fileList);
      caeatForm.publishEmpType = caeatForm.publishEmpType.length > 0 ? caeatForm.publishEmpType.join(",") : "";
      caeatForm.noticeType = caeatForm.noticeType.length > 0 ? caeatForm.noticeType.join(",") : "";
      caeatForm.publishEmpRange = caeatForm.publishEmpIds && caeatForm.publishEmpIds.length > 0 ? JSON.parse(JSON.stringify(caeatForm.publishEmpIds)).join(",") : "";
      if (caeatForm.isPublishNow === true) {
        caeatForm.publishDate = moment(moment().add(1, "seconds")).format("YYYY-MM-DD HH:mm:ss");
      }
      updateSysNotices(caeatForm).then(function (res) {
        _this5.$router.go(-1);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 全选当前页面
    onCheckAll: function onCheckAll() {
      var _this6 = this;
      this.checkAll = !this.checkAll;
      if (this.checkAll) {
        this.staffData.forEach(function (element) {
          if (!element.isTrue) {
            _this6.onRow(element);
          }
        });
      } else {
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            _this6.deleteItem(element);
          }
        });
      }
    },
    deleteItems: function deleteItems(item) {
      var _this7 = this;
      this.staffrightData.forEach(function (element, index) {
        if (item.id === element.id) {
          _this7.staffrightData.splice(index, 1);
        }
      });
    },
    deleteItem: function deleteItem(item, index) {
      var _this8 = this;
      this.staffrightData.splice(index, 1);
      this.staffData.forEach(function (element) {
        if (element.id === item.id) {
          element.isTrue = false;
          _this8.checkAll = false;
        }
      });
    },
    onSearch: function onSearch() {
      this.staffFrom.pageNo = 1;
      this.getPageEmpLists();
    },
    showSizeChange: function showSizeChange(current, size) {
      this.staffFrom.pageNo = 1;
      this.staffFrom.pageSize = size;
      this.getPageEmpLists();
    },
    onChangePagination: function onChangePagination(pageNumber) {
      this.staffFrom.pageNo = pageNumber;
      this.getPageEmpLists();
    },
    onRow: function onRow(item) {
      var _this9 = this;
      item.isTrue = !item.isTrue;
      if (item.isTrue) {
        var num = 0;
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            num += 1;
          }
        });
        this.checkAll = this.staffData.length === num;
        var arr = this.staffrightData.filter(function (items) {
          return items.id === item.id;
        });
        if (arr.length === 0) {
          this.staffrightData.push(item);
        }
      } else {
        this.checkAll = false;
        this.staffrightData.forEach(function (element, index) {
          if (item.id === element.id) {
            _this9.staffrightData.splice(index, 1);
          }
        });
      }
    },
    staffHandleOk: function staffHandleOk() {
      this.selectedList = JSON.parse(JSON.stringify(this.staffrightData));
      if (this.selectedList.length === 0) {
        this.$message.error("请选择员工");
        return;
      }
      this.staffVisible = false;
      var arr = [];
      this.selectedList.forEach(function (element) {
        arr.push(element.id);
      });
      this.caeatForm.publishEmpIds = arr;
    },
    staffUp: function staffUp() {
      this.visible = true;
      this.staffVisible = false;
    },
    getPageEmpLists: function getPageEmpLists() {
      var _this10 = this;
      this.staffFrom.postType = JSON.parse(JSON.stringify(this.caeatForm.publishEmpType)).join(",");
      this.staffFrom.companyId = this.$store.state.hr.companyId;
      this.staffFrom.formalType = this.caeatForm.formalType;
      getPageEmpList(this.staffFrom).then(function (res) {
        _this10.visible = false;
        _this10.staffVisible = true;
        res.data.entities.forEach(function (element, index) {
          res.data.entities[index].isTrue = false;
        });
        console.log(_this10.staffrightData, res);
        _this10.staffrightData.forEach(function (element) {
          res.data.entities.forEach(function (esy) {
            if (element.id === esy.id) {
              esy.isTrue = true;
            }
          });
        });
        _this10.staffData = res.data.entities;
        _this10.pageCount = res.data.entityCount;
        var arr = _this10.staffData.filter(function (item) {
          return item.isTrue;
        });
        _this10.checkAll = !!(arr.length === _this10.staffData.length && arr.length !== 0);
        console.log("arr", arr, _this10.checkAll);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    xiayibu: function xiayibu() {
      if (this.caeatForm.publishEmpType.length === 0 && !this.isJF) {
        this.$message.error("请选择员工性质");
        // this.staffFrom.postType = "";
      } else {
        this.getPageEmpLists();
      }
    },
    handleOk: function handleOk(e) {
      // this.confirmLoading = true;
      // setTimeout(() => {
      //   this.visible = false;
      //   this.confirmLoading = false;
      //   this.staffrightData = [];
      //   this.staffData = [];
      //   this.selectedList = [];
      // }, 100);
      if (this.caeatForm.publishEmpType.length === 0 && !this.isJF) {
        this.$message.error("请选择员工性质");
      } else {
        this.getPensionOk();
      }
    },
    getPensionOk: function getPensionOk() {
      var _this11 = this;
      this.staffFrom.postType = JSON.parse(JSON.stringify(this.caeatForm.publishEmpType)).join(",");
      this.staffFrom.companyId = this.$store.state.hr.companyId;
      this.staffFrom.formalType = this.caeatForm.formalType;
      this.staffFrom.pageSize = 1000;
      console.log(this.staffFrom);
      getPageEmpList(this.staffFrom).then(function (res) {
        // console.log("res", res);
        res.data.entities.forEach(function (element, index) {
          res.data.entities[index].isTrue = true;
        });
        _this11.staffData = res.data.entities;
        _this11.staffrightData = _this11.staffData;
        _this11.staffHandleOk();
        _this11.visible = false;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleCancel: function handleCancel(e) {
      this.visible = false;
    },
    getNoticeByIdDel: function getNoticeByIdDel(id) {
      var _this12 = this;
      this.spinning = true;
      getNoticeById(id).then(function (res) {
        if (res.data.publishDate) {
          res.data.publishDate = moment(parseInt(res.data.publishDate)).format("YYYY-MM-DD hh:mm:ss");
        }
        res.data.joinCityCode = res.data.joinCityCode ? res.data.joinCityCode.split(",") : [];
        res.data.publishEmpType = res.data.publishEmpType ? res.data.publishEmpType.split(",") : [];
        res.data.noticeType = res.data.noticeType ? res.data.noticeType.split(",") : [];
        res.data.isPublishNow = false;
        _this12.caeatForm = res.data;
        _this12.fileList = JSON.parse(res.data.file);
        _this12.selectedList = res.data.empInfoList;
        _this12.staffrightData = res.data.empInfoList;
      }).catch(function (err) {
        _this12.$message.error("失败!");
        console.log("debug log --> ", err);
      }).finally(function () {
        _this12.spinning = false;
      });
    },
    // 确认日期
    onOk: function onOk() {
      this.caeatForm.publishDate = this.date;
    },
    // 定时日期
    onChangeDate: function onChangeDate(date, dateString) {
      this.date = dateString;
      if (dateString === "") {
        this.caeatForm.publishDate = "";
      }
    },
    // 确认发布
    publish: function publish() {
      var _this13 = this;
      if (!this.caeatForm.title) {
        this.$message.error("请输入公告标题");
        return;
      }
      if (!this.caeatForm.contentHasStyle) {
        this.$message.error("请输入公告内容");
        return;
      }
      var caeatForm = JSON.parse(JSON.stringify(this.caeatForm));
      console.log("debug log --> ", caeatForm.publishEmpType);
      caeatForm.publishEmpType = caeatForm.publishEmpType.length > 0 ? caeatForm.publishEmpType.join(",") : "";
      caeatForm.noticeType = caeatForm.noticeType.length > 0 ? caeatForm.noticeType.join(",") : "";
      caeatForm.publishEmpRange = JSON.parse(JSON.stringify(caeatForm.publishEmpIds)).join(",");
      caeatForm.file = JSON.stringify(this.fileList);
      console.log("caeatForm", caeatForm);
      screenEmployees(caeatForm).then(function (res) {
        _this13.$router.go(-1);
      });
    },
    // 选择人员
    selectMember: function selectMember() {
      this.visible = true;
    },
    VueWangeditor: function VueWangeditor(html, text) {
      console.log("123", html, text);
      this.caeatForm.contentHasStyle = html;
      this.caeatForm.content = text;
    },
    // 发布范围变动时
    changePublishEmpRangeIsAll: function changePublishEmpRangeIsAll(e) {
      this.caeatForm.publishEmpRangeIsAll = e.target.value;
      if (e.target.value) {
        this.defaultSelectedKeys = [];
        this.caeatForm.publishEmpIds = [];
        this.selectedList = [];
        this.fold = false;
      }
    }
  }
};