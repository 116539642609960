import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { findCompanyGroupReq } from "./api";
import { mapState } from "vuex";
import _ from "lodash";
import { findAllParentIds } from "@/utils/tools";
export default {
  props: {
    showSelect: {
      type: String,
      default: undefined
    }
  },
  data: function data() {
    return {
      replaceFields: {
        children: "companyList",
        title: "name",
        key: "id",
        value: "id"
      },
      popoverVisible: false,
      // popover显示状态
      dataList: [],
      // 树数据
      expandedKeys: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    companyId: function companyId() {
      return this.hr.companyId;
    },
    companyName: function companyName() {
      return this.hr.companyName;
    }
  }),
  created: function created() {
    this.findCompanyGroup();
    this.debouncedSearch = _.debounce(this.onSearchChange, 1000);
  },
  methods: {
    onSearchChange: function onSearchChange(e) {
      this.findCompanyGroup(e.target.value);
    },
    onExpand: function onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
    },
    handleClickChange: function handleClickChange(visible) {
      this.popoverVisible = visible;
    },
    // 选择树点击事件
    onSelect: function onSelect(selectedKeys, info) {
      if (selectedKeys.length === 0) {
        return;
      }
      if (info.node.dataRef.companyList) {
        var ind = this.expandedKeys.indexOf(selectedKeys[0]);
        if (ind !== -1) {
          this.expandedKeys.splice(ind, 1);
        } else {
          this.expandedKeys.push(selectedKeys[0]);
        }
        return;
      }
      var selectOne = info.node.dataRef;
      if (selectOne.type === "company") {
        var theCompany = {
          id: selectOne.id,
          name: selectOne.name.replace(new RegExp('<span style="color: #3C8AFF">', "g"), "").replace(new RegExp("</span>", "g"), "")
        };
        this.$store.dispatch("onCompanyClick", theCompany);
        this.$emit("selectCompany", theCompany);
      }
      this.popoverVisible = false;
    },
    // 查询所有组和用工单位
    findCompanyGroup: function findCompanyGroup(value) {
      var _this = this;
      findCompanyGroupReq({
        name: value || undefined
      }).then(function (res) {
        _this.dataList = _this.addTreeSlot(res.data);
        if (value) {
          _this.expandedKeys = findAllParentIds(_this.dataList);
        }
        _this.dataList.map(function (item, index) {
          _this.dataList[index].name = _this.ruleTitle(_this.dataList[index].name, value);
          if (item.companyList) {
            item.companyList.map(function (item2, index2) {
              _this.dataList[index].companyList[index2].name = _this.ruleTitle(_this.dataList[index].companyList[index2].name, value);
            });
          }
        });
      }).catch(function (err) {
        console.log("Company load error", err);
      });
    },
    ruleTitle: function ruleTitle(titleString, searchName) {
      if (!titleString || !searchName) {
        return titleString;
      }
      if (searchName && searchName.length > 0) {
        // 匹配关键字正则
        var replaceReg = new RegExp(searchName, "g");
        // 高亮替换v-html值
        var replaceString = '<span style="color: #3C8AFF">' + searchName + "</span>";
        // 开始替换
        titleString = titleString.replace(replaceReg, replaceString);
      }
      return titleString;
    },
    addTreeSlot: function addTreeSlot(data) {
      var _this2 = this;
      // treeData添加字段
      if (!data) {
        return [];
      }
      data.forEach(function (el) {
        el.scopedSlots = {
          title: "custom"
        };
        _this2.addTreeSlot(el.companyList);
      });
      return data;
    }
  }
};