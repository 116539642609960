import potential from "./potential";
import signing from "./signing";
export default {
  components: {
    potential: potential,
    signing: signing
  },
  data: function data() {
    return {
      componentName: "potential",
      numArr: [{
        type: "potential",
        name: "潜在客户",
        num: 0
      }, {
        type: "signing",
        name: "签约客户",
        num: 0
      }]
    };
  }
};