import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.timers.js";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      dataSource: [],
      columns: [{
        title: "工资表项目",
        dataIndex: "name",
        scopedSlots: {
          customRender: "name"
        }
      }, {
        title: "是否启用",
        dataIndex: "isOpen",
        width: 100,
        scopedSlots: {
          customRender: "isOpen"
        }
      }, {
        title: "操作",
        dataIndex: "action",
        width: 60,
        scopedSlots: {
          customRender: "action"
        }
      }],
      loading: false,
      modalVisible: false,
      modalTitle: "",
      name: "",
      currentItem: null,
      updateDict: {},
      keyword: ""
    };
  },
  computed: {
    dataSourceNew: function dataSourceNew() {
      var _this = this;
      var lst = this.dataSource.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          isOpen: _this.updateDict[item.id] === true || _this.updateDict[item.id] === false ? _this.updateDict[item.id] : item.isOpen
        });
      });
      return lst;
    },
    dataSourceNewFiltered: function dataSourceNewFiltered() {
      var _this2 = this;
      return this.dataSourceNew.filter(function (item) {
        return item.name.indexOf(_this2.keyword) >= 0;
      });
    }
  },
  watch: {
    id: {
      handler: function handler(newVal) {
        if (newVal) {
          this.loadData();
        }
      },
      immediate: true
    },
    dataSourceNew: {
      handler: function handler(newVal) {
        this.$emit("update:wageItemLst", newVal.filter(function (item) {
          return item.isOpen;
        }));
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    doDelete: function doDelete(wageItemId) {
      var _this3 = this;
      this.$request({
        url: "/salary/api/v1/wageItems/".concat(wageItemId),
        method: "delete"
      }).then(function (res) {
        _this3.$message.success("删除成功");
        _this3.loadData();
      }).catch(function (err) {
        console.log(err);
      });
    },
    onOpenChange: function onOpenChange(evt, id) {
      this.updateDict = _objectSpread(_objectSpread({}, this.updateDict), {}, _defineProperty({}, "".concat(id), evt.target.checked));
    },
    doCancel: function doCancel() {
      this.modalVisible = false;
      this.modalTitle = "";
      this.name = "";
      this.currentItem = null;
    },
    doOk: function doOk() {
      var _this4 = this;
      if (!this.name) {
        this.$message.warning("内容未填写");
        return false;
      }
      if (this.currentItem) {
        this.$request({
          url: "/salary/api/v1/wageItems",
          method: "put",
          data: {
            id: this.currentItem.id,
            name: this.name
          }
        }).then(function (res) {
          _this4.$message.success("修改成功");
          _this4.loadData();
          _this4.doCancel();
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        this.$request({
          url: "/salary/api/v1/wageItems",
          method: "post",
          data: {
            name: this.name,
            canClose: true,
            canUpdate: true,
            canDelete: true,
            isOpen: true
          }
        }).then(function (res) {
          _this4.$message.success("添加成功");
          _this4.loadData();
          _this4.doCancel();
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    onAdd: function onAdd() {
      var _this5 = this;
      this.modalTitle = "添加自定义工资项";
      this.name = "";
      setTimeout(function () {
        _this5.modalVisible = true;
      }, 0);
    },
    onEdit: function onEdit(record) {
      var _this6 = this;
      this.modalTitle = "编辑自定义工资项";
      this.name = record.name;
      this.currentItem = record;
      setTimeout(function () {
        _this6.modalVisible = true;
      }, 0);
    },
    loadData: function loadData() {
      var _this7 = this;
      this.loading = true;
      this.$request({
        url: "/salary/api/v1/templateWageItems/selectByNameLike",
        params: {
          salaryTemplateId: this.id
        }
      }).then(function (res) {
        _this7.dataSource = res.data;
        _this7.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this7.loading = false;
      });
    }
  }
};