export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  methods: {}
};