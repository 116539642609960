import Vue from "vue";
var h = new Vue().$createElement;
export default {
  fields: {
    status: {
      model: "status",
      type: "select",
      wrapper: {
        label: ""
      },
      customize: {
        allowClear: true,
        placeholder: "员工状态",
        options: [{
          value: 1,
          label: "待入职"
        }, {
          value: 2,
          label: "在职"
        }, {
          value: 3,
          label: "待离职"
        }, {
          value: 4,
          label: "离职"
        }]
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: true,
          message: "请选择"
        }]
      },
      extend: {}
    },
    // isFixed: {
    //   model: "isFixed",
    //   type: "select",
    //   wrapper: {
    //     label: ""
    //   },
    //   customize: {
    //     allowClear: true,
    //     placeholder: "是否定薪",
    //     options: [
    //       {
    //         value: 1,
    //         label: "已定薪"
    //       },
    //       {
    //         value: 2,
    //         label: "未定薪"
    //       }
    //     ]
    //   },
    //   decorator: {
    //     initialValue: "",
    //     rules: [
    //       {
    //         required: true,
    //         message: "请选择"
    //       }
    //     ]
    //   },
    //   extend: {}
    // },
    name: {
      model: "name",
      type: "input",
      wrapper: {
        label: ""
      },
      customize: {
        allowClear: true,
        placeholder: "姓名/手机号/身份证号"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      },
      extend: {
        mode: "search"
      }
    },
    phone: {
      model: "phone",
      type: "input",
      wrapper: {
        label: "手机号"
      },
      customize: {
        allowClear: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    },
    empDepId: {
      model: "empDepId",
      type: "treeSelect",
      wrapper: {
        label: ""
      },
      customize: {
        allowClear: true,
        placeholder: "请选择部门"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      },
      extend: {
        dynamic: true,
        dynamicKey: "depNameTree",
        dynamicUrl: "/hr/api/v1/depInfos/listTree",
        dynamicTail: "data",
        dynamicFormatter: "depNameTreeFormatter",
        dynamicBuilder: "depNameTreeBuilder"
      }
    },
    positionName: {
      model: "positionName",
      type: "input",
      wrapper: {
        label: ""
      },
      customize: {
        allowClear: true,
        placeholder: "请选择岗位"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    },
    joinDate: {
      model: "joinDate",
      type: "date",
      wrapper: {
        label: ""
      },
      customize: {
        allowClear: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    },
    postType: {
      model: "postType",
      type: "select",
      wrapper: {
        label: ""
      },
      customize: {
        allowClear: true,
        placeholder: "工作性质"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      },
      extend: {
        dynamic: true,
        dynamicKey: "postTypeLst"
      }
    },
    formalType: {
      model: "formalType",
      type: "select",
      wrapper: {
        label: ""
      },
      customize: {
        allowClear: true,
        placeholder: "员工状态",
        options: [{
          value: 1,
          label: "试用"
        }, {
          value: 2,
          label: "正式"
        }]
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    },
    salarySumDetails: {
      model: "salarySumDetails",
      type: "input",
      wrapper: {
        label: "手机号"
      },
      customize: {
        allowClear: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    }
  },
  validators: {},
  config: {
    search: {
      fieldKeys: ["name", "postType", "status"],
      customize: {
        layout: "inline",
        labelCol: {
          span: 0
        },
        wrapperCol: {
          span: 25
        }
      }
    },
    table: {
      fields: [{
        model: "name",
        title: "姓名"
      }, {
        model: "phone",
        title: "手机号"
      }, {
        model: "joinDate",
        title: "入职时间"
      }, {
        model: "postType",
        title: "工作性质"
      }, {
        model: "formalType",
        title: "员工类型"
      }, {
        model: "salarySumDetails",
        title: "总工资"
      }],
      customize: {
        rowKey: "id"
      },
      extend: {
        batch: false,
        action: true
      }
    }
  }
};