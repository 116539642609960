import "core-js/modules/es.number.constructor.js";
import { formatDateAll } from "@/utils/index";
import vueWangeditor from "./vueWangeditor";
import { UploadGeneralFileWithName } from "xqjr-plugin-common";
import moment from "moment";
export default {
  name: "Edit",
  components: {
    vueWangeditor: vueWangeditor,
    UploadGeneralFileWithName: UploadGeneralFileWithName
  },
  props: {
    noticeTitleInit: {
      type: String,
      default: ""
    },
    noticeTimeInit: {
      type: Number,
      default: 0
    },
    noticeFileListInit: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    noticeContentInit: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      template: null,
      title: "",
      fileList: [],
      menus: ["bold",
      // 粗体
      "underline",
      // 下划线
      "italic",
      // 斜体
      "strikeThrough",
      // 中线
      "eraser",
      // 清空格式
      "foreColor",
      // 文字颜色
      "backColor",
      // 背景颜色
      "quote",
      // 引用
      "fontSize",
      // 字号
      "fontName",
      // 字体
      "head",
      // 标题
      "list",
      // 无序列表
      "justify",
      // 有序列表
      "undo",
      // 撤销
      "redo",
      // 重做
      "fullscreen", "link", "image" // 插入图片
      ],

      initContent: "",
      content: "",
      contentHasStyle: "",
      options: {},
      cursorPosition: null,
      variables: [],
      variableList: [],
      isNext: false,
      editorHeight: 0,
      moment: moment,
      dataTime: moment()
    };
  },
  watch: {
    noticeTitleInit: function noticeTitleInit(val) {
      this.noticeTitleInit = val;
      this.title = val;
    },
    noticeTimeInit: function noticeTimeInit(val) {
      if (val) {
        this.dataTime = moment(val);
      }
    },
    noticeFileListInit: function noticeFileListInit(val) {
      this.fileList = val;
    },
    noticeContentInit: function noticeContentInit(val) {
      this.noticeContentInit = val;
      this.contentHasStyle = val;
      this.$refs.editor.setHtml(val);
      this.content = this.$refs.editor.getText();
      this.contentHasStyle = this.$refs.editor.getHtml();
    },
    dataTime: function dataTime(val) {}
  },
  mounted: function mounted() {
    // if (document.getElementById("editor-main")) {
    //   this.editorHeight = 500;
    // }
  },
  methods: {
    // 选择发布时间
    handleChange: function handleChange(e) {
      this.dataTime = e;
    },
    onFileChange: function onFileChange(list) {
      this.fileList = list;
    },
    initEditContent: function initEditContent() {
      // 编辑器赋值
      this.$refs.editor.setHtml(this.noticeContentInit);
      this.title = this.noticeTitleInit;
      this.content = this.$refs.editor.getText();
      this.contentHasStyle = this.$refs.editor.getHtml();
    },
    NextStep: function NextStep() {
      var aData = new Date().getTime();
      var dataTime1 = this.dataTime ? this.dataTime.format("YYYY-MM-DD HH:ss:mm") : formatDateAll(aData);
      // this.dataTime = formatDateAll(this.dataTime);
      // this.noticeTime = formatDateAll(aData);

      if (this.title === "" || this.content === "") {
        this.$message.warning("请填写新闻标题及公告内容");
      } else {
        this.$emit("current", 2);
        this.$emit("update:noticeTitle", this.title);
        this.$emit("update:noticeFileList", this.fileList);
        this.$emit("update:noticeContent", this.content);
        this.$emit("update:noticeContentHasStyle", this.contentHasStyle);
        this.$emit("update:noticeTime", dataTime1);
        // if (dataTime1) {
        //   this.$emit("update:noticeTime", dataTime1);
        // } else {
        //   this.$emit("update:noticeTime", this.noticeTime);
        // }
      }
    },
    changeContent: function changeContent(text) {
      this.content = this.$refs.editor.getText();
      this.contentHasStyle = this.$refs.editor.getHtml();
    }
  }
};