export default {
  fields: {
    name: {
      model: "name"
    },
    idCard: {
      model: "idCard"
    },
    phone: {
      model: "phone"
    },
    identity: {
      model: "identity"
    },
    money: {
      model: "money"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "name",
        title: "姓名"
      }, {
        model: "idCard",
        title: "身份证号"
      }, {
        model: "phone",
        title: "手机号"
      }, {
        model: "identity",
        title: "账号"
      }, {
        model: "money",
        title: "发放金额"
      }],
      customize: {
        rowKey: "id"
        // pagination: {
        //   pageSize: 10
        // }
      },

      extend: {
        batch: false,
        action: false
      }
    }
  }
};