var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "新增记录",
      "confirm-loading": _vm.confirmLoading,
      width: 560
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    staticStyle: {
      "padding-left": "18px"
    },
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-form-item", {
    staticStyle: {
      margin: "0"
    },
    attrs: {
      label: "拜访时间"
    }
  }, [_c("a-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["notes", {
        rules: [{
          required: true,
          message: ""
        }]
      }],
      expression: "['notes', { rules: [{ required: true, message: '' }] }]"
    }]
  }), _c("a-form-item", {
    staticStyle: {
      float: "left",
      "margin-right": "16px"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["visitTime1", {
        rules: [{
          required: true,
          message: "日期不能为空"
        }]
      }],
      expression: "['visitTime1', { rules: [{ required: true, message: '日期不能为空' }] }]"
    }],
    staticStyle: {
      width: "142px"
    },
    attrs: {
      valueFormat: "YYYY-MM-DD",
      placeholder: "请选择日期"
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      float: "left"
    }
  }, [_c("a-time-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["visitTime2", {
        rules: [{
          required: true,
          message: "时间不能为空"
        }]
      }],
      expression: "['visitTime2', { rules: [{ required: true, message: '时间不能为空' }] }]"
    }],
    staticStyle: {
      width: "142px"
    },
    attrs: {
      valueFormat: "hh:mm",
      format: "hh:mm",
      placeholder: "请选择时间"
    }
  })], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "拜访地点"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["visitAddress", {
        rules: [{
          required: true,
          message: "拜访地点不能为空"
        }]
      }],
      expression: "['visitAddress', { rules: [{ required: true, message: '拜访地点不能为空' }] }]"
    }],
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请输入拜访地点",
      maxLength: 100
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "拜访记录"
    }
  }, [_c("a-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["visitPicture", {
        rules: [{
          required: true,
          message: "图片不能为空"
        }]
      }],
      expression: "['visitPicture', { rules: [{ required: true, message: '图片不能为空' }] }]"
    }]
  }), _c("div", {
    staticStyle: {
      float: "left"
    }
  }, [_c("uploadImg", {
    staticStyle: {
      height: "104px"
    },
    attrs: {
      val: _vm.logos
    },
    on: {
      upData: _vm.upData
    }
  }, [_c("div", {
    staticClass: "uploadBox"
  }, [_c("div", [_c("a-icon", {
    staticStyle: {
      color: "#6B6BF2",
      "font-size": "20px",
      "margin-bottom": "10px"
    },
    attrs: {
      type: "plus-circle",
      theme: "filled"
    }
  })], 1), _c("div", [_vm._v(" 上传拜访记录 ")])])])], 1), _c("div", {
    staticClass: "uploadFont"
  }, [_vm._v(" 支持 JPG、JPEG、PNG、BMP 格式，大小不超过 5 MB。 ")])], 1), _c("a-form-item", {
    attrs: {
      label: "拜访详情"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["visitInfo", {
        rules: [{
          required: true,
          message: "拜访详情不能为空"
        }]
      }],
      expression: "['visitInfo', { rules: [{ required: true, message: '拜访详情不能为空' }] }]"
    }],
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请输入",
      "auto-size": {
        minRows: 3,
        maxRows: 5
      },
      maxLength: 300
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };