var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.currentGroup ? _c("div", {
    staticClass: "pv16 pr16"
  }, [_c("div", {
    staticClass: "flex-center-between"
  }, [_c("div", [_c("div", {
    staticClass: "fs16 fw500"
  }, [_vm._v(_vm._s(_vm.currentGroup.institutionTypeName))]), _c("div", {
    staticClass: "fs12 tc-l2 mt8"
  }, [_vm._v(_vm._s(_vm.currentGroup.institutionTypeIntroduce))])]), _c("a-button", {
    attrs: {
      disabled: _vm.currentGroup && _vm.currentGroup.level === 1 || !_vm.currentGroup,
      type: "primary"
    },
    on: {
      click: _vm.goAdd
    }
  }, [_vm._v("添加制度")])], 1), _c("div", {
    staticClass: "mt24"
  }, [_c("a-table", {
    attrs: {
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: "id"
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "gmtCreate",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(_vm.$dayjs(text).format("YYYY-MM-DD")) + " ")];
      }
    }, {
      key: "isOpen",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text ? "开启" : "关闭") + " ")];
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("a-space", [record.isOpen ? _c("span", {
          staticClass: "tc-theme pointer",
          on: {
            click: function click($event) {
              return _vm.doClose(record.id);
            }
          }
        }, [_vm._v("关闭")]) : _c("span", {
          staticClass: "tc-theme pointer",
          on: {
            click: function click($event) {
              return _vm.doOpen(record.id);
            }
          }
        }, [_vm._v("开启")]), _c("span", {
          staticClass: "tc-theme pointer",
          on: {
            click: function click($event) {
              return _vm.goEdit(record);
            }
          }
        }, [_vm._v("编辑")]), _c("a-popconfirm", {
          attrs: {
            title: "确认要删除吗？"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.doDelete(record.id);
            }
          }
        }, [_c("span", {
          staticClass: "tc-theme pointer"
        }, [_vm._v("删除")])])], 1)];
      }
    }], null, false, 929448408)
  })], 1)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };