var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "process",
    class: {
      first: _vm.index === 0,
      end: _vm.isEnd
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_c("a-space", [_c("span", [_vm._v(_vm._s(_vm.index + 1) + " " + _vm._s(_vm.nameCopy))]), _c("a-icon", {
    attrs: {
      type: "edit"
    },
    on: {
      click: _vm.editProcessItem
    }
  }), _c("a-popconfirm", {
    attrs: {
      title: "确认要删除流程吗"
    },
    on: {
      confirm: _vm.delProcessItem
    }
  }, [_c("a-icon", {
    attrs: {
      type: "delete"
    }
  })], 1)], 1)], 1), _c("TemplateConfigTable", {
    attrs: {
      dataSource: _vm.fieldsCopy
    },
    on: {
      editOk: _vm.editField,
      delItem: _vm.deleteField,
      sortItem: _vm.sortField
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };