import request from "@/utils/request";

// 分页查询
export function retrieveReq(params) {
  return request({
    url: "/salary/api/v1/fixedSalaryItems/selectPageEmpSalaryNew",
    method: "GET",
    params: params
  });
}

// 新增
export function createReq(data) {
  return request({
    url: "/mfs/api/v1/channels",
    method: "POST",
    data: data
  });
}
export function getAllChannels(params) {
  return request({
    url: "/hr/api/v1/channels/listAll",
    method: "get",
    params: params
  });
}
// 计时档案分页查询
export function selectOutsourceSalary(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/selectOutsourceSalary",
    method: "GET",
    params: params
  });
}
export function getEmpInfoId(empInfoId, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/" + empInfoId + "?companyId=" + companyId,
    method: "GET"
  });
}
// 修改
export function updateReq(data) {
  return request("/mfs/api/v1/channels", {
    method: "PUT",
    data: data
  });
}

// 根据id查询详情
export function detailReq(data) {
  return request({
    url: "/mfs/api/v1/channels/".concat(data),
    method: "GET"
  });
}

// 删除
export function deleteReq(params) {
  return request({
    url: "/mfs/api/v1/channels/".concat(params),
    method: "DELETE"
  });
}

// 批量删除
export function deleteBatchReq(data) {
  return request({
    url: "/mfs/api/v1/channels",
    method: "DELETE",
    data: data
  });
}
export function getNotList(params) {
  return request({
    url: "/hr/api/v1/empInfos/pageSalaryFile",
    method: "GET",
    params: params
  });
}