import request from "@/utils/request";
export function getMessage(params) {
  return request({
    url: "/message/api/v1/smsTemplates/listAll",
    method: "GET",
    params: params
  });
}
export function getScene(params) {
  return request({
    url: "/message/api/v1/smsScenes/listAll",
    method: "GET",
    params: params
  });
}
export function getConfig(params) {
  return request({
    url: "/message/api/v1/smsSites/listAll",
    method: "GET",
    params: params
  });
}
export function addTempalte(data) {
  return request({
    url: "/message/api/v1/smsTemplates",
    method: "POST",
    data: data
  });
}
export function editTempalte(data) {
  return request({
    url: "/message/api/v1/smsTemplates",
    method: "PUT",
    data: data
  });
}
export function getTemplateDetail(id) {
  return request({
    url: "/message/api/v1/smsTemplates/".concat(id),
    method: "GET"
  });
}
export function addConfig(data) {
  return request({
    url: "/message/api/v1/smsSites/batch",
    method: "POST",
    data: data
  });
}