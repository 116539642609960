import { findByCompanyId, updateCompanyShow } from "@/api/company/contract";
export default {
  data: function data() {
    return {
      companyId: "",
      pageTitle: "合同设置",
      groupList: [{
        name: "显示设置"
      }],
      selectway: "true",
      optionlist: [{
        label: "员工签署完成后，默认允许查看合同内容",
        value: "true"
      }, {
        label: "员工签署完成后，默认不允许查看合同内容",
        value: "false"
      }]
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.getCheck(this.companyId);
  },
  methods: {
    goback: function goback() {
      this.$router.go(-1);
    },
    selectSetting: function selectSetting(e) {
      this.selectway = e;
    },
    getCheck: function getCheck(companyId) {
      var _this = this;
      findByCompanyId(companyId).then(function (res) {
        _this.selectway = res.data.isShow + "";
      });
    },
    confirmSetting: function confirmSetting() {
      var _this2 = this;
      updateCompanyShow(this.companyId, this.selectway).then(function (res) {
        _this2.$message.success("设置成功");
      });
    }
  }
};