import { Project, ProjectApi } from "@/api/project/new-index";
export default {
  inject: ["reload"],
  props: {
    newGroupOptions: {
      show: false,
      obj: {}
    }
  },
  data: function data() {
    return {
      visible: false,
      obj: {
        moduleType: "hr",
        groupName: "",
        groupDesc: "",
        companyId: this.$store.state.hr.companyId
      }
    };
  },
  watch: {
    newGroupOptions: {
      handler: function handler(val) {
        this.visible = val.show;
        val.obj.moduleType = "hr";
        this.obj = val.obj;
      },
      deep: true
    }
  },
  methods: {
    // 删除分组
    deleteGroup: function deleteGroup(item) {
      var _this = this;
      this.$confirm({
        title: "确定删除?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: function onOk() {
          Project({
            url: ProjectApi.postGroupList + "/".concat(item.id),
            method: "DELETE"
          }).then(function (data) {
            if (data.success) {
              _this.$notification["success"]({
                message: "删除成功"
              });
              _this.$parent.getGroupList();
              _this.reload();
            }
          });
        }
      });
    },
    saveData: function saveData() {
      var _this2 = this;
      if (this.obj.id) {
        Project({
          url: ProjectApi.postGroupList,
          method: "PUT",
          data: this.obj
        }).then(function (data) {
          if (data.success) {
            _this2.$notification["success"]({
              message: "修改成功"
            });
            /*  this.$parent.getGroupList() */
            _this2.reload();
          }
        });
      } else {
        Project({
          url: ProjectApi.postGroupList,
          method: "POST",
          data: this.obj
        }).then(function (data) {
          if (data.success) {
            _this2.$notification["success"]({
              message: "新增成功"
            });
            /* this.$parent.getGroupList() */
            _this2.reload();
          }
        });
      }
      this.visible = false;
    }
  }
};