import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { mapState, mapGetters } from "vuex";
export default {
  name: "PayslipHistory",
  data: function data() {
    return {
      params: {
        name: "",
        dep: ""
      },
      pagination: {
        current: 1,
        pagesize: 10,
        total: 0
      },
      dataSource: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), mapGetters({
    PAYSLIP: "wages/PAYSLIP"
  })),
  created: function created() {
    this.getList();
  },
  methods: {
    // 查看详情
    // this.PAYSLIP.tableId
    getList: function getList() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/salaryBillSends/salaryBillSendListPage",
        params: {
          companyId: this.hr.companyId,
          tableId: this.PAYSLIP.tableId,
          pagesize: 10,
          sendType: 1
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.dataSource = data.entities || [];
        _this.pagination.total = data.entityCount || 0;
      });
    },
    goDetail: function goDetail(id) {
      this.$router.push({
        path: "payslip-excel-detail",
        query: {
          id: id
        }
      });
    }
  }
};