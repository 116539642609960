import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "pageStyle"
  }, [_c("div", {
    staticClass: "page-miH",
    staticStyle: {
      "min-width": "250px",
      width: "250px",
      "border-right": "1px solid #e8e8e8"
    }
  }, [_c("div", {
    staticClass: "leftBox"
  }, [_c("div", {
    staticClass: "searchBox"
  }, [_c("div", {
    staticClass: "titleStyle"
  }, [_vm._v("租户列表")]), _c("div", {
    staticClass: "addIcon"
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    },
    on: {
      click: _vm.showSiteAll
    }
  })], 1)]), _c("a-input-search", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      placeholder: "请输入"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.selectSiteName,
      callback: function callback($$v) {
        _vm.selectSiteName = $$v;
      },
      expression: "selectSiteName"
    }
  }), _c("div", {
    staticClass: "siteBox"
  }, _vm._l(_vm.siteList, function (item, index) {
    return _c("div", {
      key: index,
      class: item.isSelected ? "sitemItemOn" : "sitemItem",
      on: {
        click: function click($event) {
          return _vm.siteChange(item, index);
        }
      }
    }, [_c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.customerName) + " ")]), _c("div", {
      staticClass: "planTitle",
      staticStyle: {
        width: "180px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.customerName) + " ")])], 2), _c("a-popconfirm", {
      attrs: {
        title: "\u786E\u5B9A\u8981\u5C06\u79DF\u6237".concat(item.customerName, "\u5728\u5217\u8868\u4E2D\u79FB\u9664\u5417"),
        cancelText: "取消",
        okText: "确定"
      },
      on: {
        confirm: function confirm($event) {
          $event.stopPropagation();
          return _vm.deleleSite(item);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
        }
      }
    })], 1)], 1);
  }), 0), _c("a-pagination", {
    staticStyle: {
      "margin-right": "0px",
      "margin-top": "24px"
    },
    attrs: {
      pageSize: 10,
      total: _vm.total
    },
    on: {
      change: _vm.onPageChange
    },
    model: {
      value: _vm.current,
      callback: function callback($$v) {
        _vm.current = $$v;
      },
      expression: "current"
    }
  })], 1)]), _c("employee-select", {
    attrs: {
      show: _vm.visibleForSite,
      "default-selected-keys": _vm.selectedSiteList,
      "selected-list": _vm.selectedSiteList
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.visibleForSite = $event;
      },
      "update:selectedList": function updateSelectedList($event) {
        _vm.selectedSiteList = $event;
      },
      "update:selected-list": function updateSelectedList($event) {
        _vm.selectedSiteList = $event;
      },
      change: _vm.onEmployeeSelectChange
    }
  }), _c("div", {
    staticClass: "rightBox"
  }, [_c("div", {
    staticClass: "functionBtn"
  }, [_c("a-button", {
    attrs: {
      disabled: !_vm.siteList.length,
      type: "primary"
    },
    on: {
      click: _vm.addPlan
    }
  }, [_vm._v("新增投保方案")])], 1), _vm.planList && _vm.planList.length ? _c("div", {
    staticClass: "planBox"
  }, _vm._l(_vm.planList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "planItem"
    }, [_c("a-dropdown", [_c("a-menu", {
      attrs: {
        slot: "overlay"
      },
      on: {
        click: function click(e) {
          return _vm.handleMenuClick(e, item);
        }
      },
      slot: "overlay"
    }, [_c("a-menu-item", {
      key: "1"
    }, [_vm._v("编辑")]), _c("a-menu-item", {
      key: "2"
    }, [_vm._v("删除")])], 1), _c("a-icon", {
      staticClass: "funcBtn",
      attrs: {
        type: "ellipsis"
      }
    })], 1), _c("div", {
      staticClass: "contentBox"
    }, [_c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.schemeName) + " ")]), _c("div", {
      staticClass: "planTitle",
      staticStyle: {
        width: "180px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.schemeName) + " ")])], 2), _c("div", {
      staticClass: "planContent"
    }, [_c("div", {
      staticClass: "planKey"
    }, [_vm._v("投保公司：")]), _c("div", {
      staticClass: "planValue"
    }, [_vm._v(_vm._s(item.companyName))])]), _c("div", {
      staticClass: "planContent",
      staticStyle: {
        display: "flex",
        "align-items": "center"
      }
    }, [_c("div", {
      staticClass: "planKey"
    }, [_vm._v("方案明细:")]), _c("a-popover", {
      attrs: {
        placement: "bottomLeft",
        title: "方案明细"
      }
    }, [_c("template", {
      slot: "content"
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        width: "540px",
        "flex-wrap": "wrap"
      }
    }, _vm._l(item.personTypeList, function (item, index) {
      return _c("div", {
        key: index,
        staticStyle: {
          display: "flex",
          "justify-content": "space-between",
          "align-items": "center",
          padding: "0 16px",
          margin: "12px 12px 0 0",
          width: "254px",
          height: "41px",
          background: "#f6f6f6",
          "border-radius": "4px"
        }
      }, [_c("a-tooltip", [_c("template", {
        slot: "title"
      }, [_vm._v(" " + _vm._s(item.typeName) + " ")]), _c("div", {
        staticStyle: {
          "font-size": "14px",
          "font-weight": "500",
          color: "#55565d",
          width: "98px",
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden"
        }
      }, [_vm._v(" " + _vm._s(item.typeName) + " ")])], 2), _c("div", [_c("span", {
        staticClass: "label"
      }, [_vm._v("保险月费：")]), _c("span", {
        staticClass: "value"
      }, [_vm._v(_vm._s(item.monthAmount))])])], 1);
    }), 0)]), _c("a-button", {
      attrs: {
        type: "link"
      }
    }, [_vm._v("查看")])], 2)], 1), _c("div", {
      staticClass: "planContent"
    }, [_c("div", {
      staticClass: "planKey"
    }, [_vm._v("方案确认函：")]), _c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_c("div", [_vm._v(_vm._s(item.remark))])]), _c("div", {
      staticClass: "planValue",
      staticStyle: {
        width: "120px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.remark) + " ")])], 2)], 1)], 1)], 1);
  }), 0) : _c("div", {
    staticStyle: {
      padding: "120px 40%"
    }
  }, [_c("a-empty")], 1)]), _c("a-modal", {
    attrs: {
      title: _vm.title,
      width: 660
    },
    on: {
      ok: _vm.handelOk,
      cancel: function cancel($event) {
        _vm.visible = false;
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "modalContent"
  }, [_c("div", {
    staticClass: "itemContent"
  }, [_c("div", {
    staticClass: "itemTitle"
  }, [_c("span", {
    staticStyle: {
      color: "#fa5051"
    }
  }, [_vm._v("*")]), _vm._v("投保公司")]), _c("a-select", {
    staticStyle: {
      width: "300px",
      "margin-left": "24px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择"
    },
    on: {
      change: _vm.insuranceChange
    },
    model: {
      value: _vm.planItem.insCompanyId,
      callback: function callback($$v) {
        _vm.$set(_vm.planItem, "insCompanyId", $$v);
      },
      expression: "planItem.insCompanyId"
    }
  }, _vm._l(_vm.insuranceList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.insCompanyId
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticClass: "itemContent"
  }, [_c("div", {
    staticClass: "itemTitle"
  }, [_c("span", {
    staticStyle: {
      color: "#fa5051"
    }
  }, [_vm._v("*")]), _vm._v("投保方案")]), _c("a-select", {
    staticStyle: {
      width: "300px",
      "margin-left": "24px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择"
    },
    on: {
      change: _vm.selectPlan
    },
    model: {
      value: _vm.selectPlanItem,
      callback: function callback($$v) {
        _vm.selectPlanItem = $$v;
      },
      expression: "selectPlanItem"
    }
  }, _vm._l(_vm.selectPlanList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.schemeName) + " ")]);
  }), 1)], 1), _c("div", {
    staticClass: "itemContent"
  }, [_c("div", {
    staticClass: "itemTitle"
  }, [_vm._v("方案确认函")]), _c("a-upload", {
    ref: "upload",
    staticStyle: {
      "margin-left": "24px",
      display: "flex"
    },
    attrs: {
      "file-list": _vm.fileList,
      headers: _vm.headers,
      action: "/file/api/v1/general/upload/attachment",
      name: "files",
      showUploadList: {
        showDownloadIcon: true
      }
    },
    on: {
      change: _vm.handleChange,
      download: _vm.download
    }
  }, [_c("a-button", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" " + _vm._s(!_vm.fileList.length ? "上传文件" : "重新上传") + " ")], 1)], 1)], 1), _vm.realityMonthAmount ? _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "0 36px",
      width: "526px",
      height: "48px",
      background: "#fafafa",
      "box-shadow": "inset 0px -1px 0px 0px #e8e8e8",
      display: "flex",
      "justify-content": "flex-start",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "500",
      color: "#252525"
    }
  }, [_vm._v("职业分类")]), _c("div", {
    staticStyle: {
      "margin-left": "60px",
      "font-size": "14px",
      "font-weight": "500",
      color: "#252525"
    }
  }, [_vm._v("实际保险月费")]), _c("div", {
    staticStyle: {
      "margin-left": "52px",
      "font-size": "14px",
      "font-weight": "500",
      color: "#252525"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#fa5051"
    }
  }, [_vm._v("*")]), _vm._v("保险月费"), _c("span", {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "400",
      color: "#a6a8b4",
      "margin-left": "16px"
    }
  }, [_vm._v("京普给人资公司的价格")])])]), _vm._l(_vm.realityMonthAmount, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "flex-start",
        width: "526px",
        height: "48px",
        background: "#ffffff",
        "box-shadow": "inset 0px -1px 0px 0px #e8e8e8"
      }
    }, [_c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_c("div", [_vm._v(_vm._s(item.typeName))])]), _c("div", {
      staticClass: "value",
      staticStyle: {
        width: "120px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden",
        "margin-left": "36px"
      }
    }, [_vm._v(" " + _vm._s(item.typeName) + " ")])], 2), _c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_c("div", [_vm._v(_vm._s(item.realityMonthAmount))])]), _c("div", {
      staticClass: "value",
      staticStyle: {
        width: "120px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.realityMonthAmount) + " ")])], 2), _c("a-input-number", {
      staticStyle: {
        width: "180px",
        "margin-left": "16px"
      },
      attrs: {
        placeholder: "请输入"
      },
      model: {
        value: item.monthAmount,
        callback: function callback($$v) {
          _vm.$set(item, "monthAmount", $$v);
        },
        expression: "item.monthAmount"
      }
    })], 1);
  })], 2) : _vm._e()])])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };