import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.sort.js";
import { formatDateAll } from "@/utils/index";
import { getNewsGroups, selectNewsByType, addNewsTypes, updateNewsTypes, batchDelNews, batchDelNewsTypes } from "@/api/company/news";
import { addCategories, updateCategories } from "@/api/company/contract";
var noticeColumns = [{
  title: "标题",
  dataIndex: "name",
  width: 260,
  onCell: function onCell() {
    return {
      style: {
        maxWidth: 340,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        cursor: "pointer"
      }
    };
  },
  scopedSlots: {
    customRender: "title"
  }
}, {
  title: "发布时间",
  dataIndex: "pushDate",
  width: 200,
  customRender: function customRender(text, record, index) {
    return formatDateAll(text);
  }
}, {
  title: "操作",
  dataIndex: "action",
  width: 180,
  scopedSlots: {
    customRender: "action"
  }
}];
export default {
  data: function data() {
    return {
      noticeColumns: noticeColumns,
      newslist: [],
      showIndex: true,
      haveType: false,
      // 添加图标
      addIcon: false,
      // 设置图标
      act: false,
      companyId: this.$store.state.hr.companyId,
      id: null,
      groupType: null,
      groupCode: null,
      groupTitle: "添加",
      groupList: [],
      data: [],
      batchDeleteNewVisible: false,
      batchDeleteGroupVisible: false,
      addGroupVisible: false,
      addTypeVisible: false,
      confirmLoading: false,
      groupForm: this.$form.createForm(this),
      typeForm: this.$form.createForm(this),
      selectedRowKeys: [],
      theCurrentGroup: "",
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true
      },
      tableLoading: false
    };
  },
  mounted: function mounted() {
    this.getNewsTypes();
  },
  methods: {
    /* 搜索 */onSearch: function onSearch(e) {
      var _this = this;
      if (e) {
        selectNewsByType({
          name: e,
          groupType: this.groupType,
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        }).then(function (res) {
          _this.newslist = res.data.entities;
          _this.pagination.total = res.data && res.data.entityCount;
        });
      } else {
        selectNewsByType({
          groupType: this.groupType,
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        }).then(function (res) {
          _this.newslist = res.data.entities;
          _this.pagination.total = res.data && res.data.entityCount;
        });
      }
    },
    // 获取分组列表
    getNewsTypes: function getNewsTypes() {
      var _this2 = this;
      this.tableLoading = true;
      getNewsGroups().then(function (res) {
        _this2.groupList = res.data;
        selectNewsByType({
          groupCode: _this2.groupCode,
          groupType: _this2.groupType,
          pageNo: _this2.pagination.current,
          pageSize: _this2.pagination.pageSize
        }).then(function (res) {
          _this2.tableLoading = false;
          _this2.newslist = res.data.entities;
          _this2.pagination.total = res.data && res.data.entityCount;
        });
      });
    },
    // 获取分组下的合同类型列表
    getNewlistByType: function getNewlistByType(item) {
      var _this3 = this;
      this.groupType = item.type;
      this.groupCode = item.code;
      this.haveType = true;
      selectNewsByType({
        groupCode: item.code,
        groupType: item.type,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }).then(function (res) {
        _this3.newslist = res.data.entities;
        _this3.pagination.total = res.data && res.data.entityCount;
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      var _this4 = this;
      this.pagination.current = pagination.current;
      selectNewsByType({
        groupCode: this.groupCode,
        groupType: this.groupType,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }).then(function (res) {
        _this4.newslist = res.data.entities;
        _this4.pagination.total = res.data && res.data.entityCount;
      });
    },
    // 新增文档
    createNew: function createNew() {
      this.$router.push({
        path: "news/createNew",
        query: {
          groupCode: this.groupCode
        }
      });
    },
    updateNew: function updateNew(record) {
      this.$router.push({
        path: "news/createNew",
        query: {
          id: record.id,
          groupType: this.groupType,
          state: "edit"
        }
      });
    },
    goDetail: function goDetail(record) {
      this.$router.push({
        path: "news/detail",
        query: {
          id: record.id
        }
      });
    },
    // 新增合同分组
    createGroup: function createGroup() {
      this.groupTitle = "添加";
      this.addGroupVisible = true;
      this.groupForm.resetFields();
    },
    // 编辑合同分组
    updateGroup: function updateGroup(item) {
      var _this5 = this;
      this.theCurrentGroup = item;
      this.groupTitle = "编辑";
      this.addGroupVisible = true;
      setTimeout(function () {
        _this5.groupForm.setFieldsValue({
          name: item.name
        });
      }, 0);
    },
    // 删除合同分组
    deleteGroup: function deleteGroup(item) {
      this.batchDeleteGroupVisible = true;
      this.theCurrentGroup = item;
    },
    deleteNew: function deleteNew(item) {
      this.batchDeleteNewVisible = true;
      this.theCurrentGroup = item;
    },
    // 确定增加合同分组
    confirmAddGroup: function confirmAddGroup(e) {
      var _this6 = this;
      var that = this;
      e.preventDefault();
      this.groupForm.validateFields(function (err, values) {
        values.sort = "1";
        values.subjectId = _this6.companyId;
        if (!err) {
          if (that.groupTitle === "添加") {
            addNewsTypes(values).then(function (res) {
              that.$message.success("添加成功");
              _this6.addGroupVisible = false;
              that.getNewsTypes();
            });
          } else {
            updateNewsTypes(_objectSpread(_objectSpread({}, values), {}, {
              id: that.theCurrentGroup.id
            })).then(function (res) {
              that.$message.success("编辑成功");
              _this6.addGroupVisible = false;
              that.getNewsTypes();
            });
          }
        }
      });
    },
    confirmDeleteNew: function confirmDeleteNew() {
      var _this7 = this;
      var newsTypeIds = [];
      newsTypeIds.push(this.theCurrentGroup.id);
      batchDelNews(newsTypeIds).then(function (res) {
        _this7.$message.success("删除成功");
        _this7.batchDeleteNewVisible = false;
        selectNewsByType({
          groupCode: _this7.groupCode,
          groupType: _this7.groupType,
          pageNo: _this7.pagination.current,
          pageSize: _this7.pagination.pageSize
        }).then(function (res) {
          _this7.newslist = res.data.entities;
          _this7.pagination.total = res.data && res.data.entityCount;
        });
      });
    },
    // 确定删除新闻类型分组
    confirmDeleteGroup: function confirmDeleteGroup() {
      var _this8 = this;
      var contractGroupIds = [];
      contractGroupIds.push(this.theCurrentGroup.id);
      batchDelNews(contractGroupIds).then(function (res) {
        _this8.$message.success("删除成功");
        _this8.batchDeleteGroupVisible = false;
        _this8.getNewsTypes();
      });
    },
    // // 确定增加合同分类
    confirmAddType: function confirmAddType(e) {
      var _this9 = this;
      var that = this;
      e.preventDefault();
      this.typeForm.validateFields(function (err, values) {
        values.sort = "1";
        values.subjectId = _this9.companyId;
        if (!err) {
          if (that.groupTitle === "添加") {
            addCategories(values).then(function (res) {
              that.$message.success("添加成功");
              _this9.addTypeVisible = false;
              that.getNewsTypes();
              _this9.$router.push({
                name: "setting",
                query: {
                  id: res.data.id
                }
              });
            });
          } else {
            updateCategories(_objectSpread(_objectSpread({}, values), {}, {
              id: that.theCurrentGroup.id,
              subjectId: _this9.$store.state.hr.companyId
            })).then(function (res) {
              that.$message.success("编辑成功");
              _this9.addTypeVisible = false;
              that.getNewsTypes();
            });
          }
        }
      });
    },
    handleCancel: function handleCancel() {
      this.addGroupVisible = false;
      this.batchDeleteVisible = false;
      this.addTypeVisible = false;
      this.batchDeleteNewVisible = false;
    },
    // 多选合同列表
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    }
  }
};