import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import Draggable from "vuedraggable";
import { fieldTypeArr, fieldTypeMap } from "./templateConfigTableFuncs";
export default {
  name: "",
  components: {
    Draggable: Draggable
  },
  mixins: [],
  props: {
    dataSource: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    editIcon: {
      type: Boolean,
      default: true
    },
    deleteIcon: {
      type: Boolean,
      default: true
    },
    addIcon: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      fieldTypeArr: fieldTypeArr,
      fieldTypeMap: fieldTypeMap,
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      },
      modalMode: "add",
      modalTitle: false,
      modalVisible: false,
      confirmLoading: false,
      form: {
        options: []
      },
      editItemIndex: null,
      dataSourceCopy: []
    };
  },
  computed: {},
  watch: {
    dataSource: {
      handler: function handler(val) {
        this.dataSourceCopy = val;
      },
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    addDomain: function addDomain() {
      this.form = _objectSpread(_objectSpread({}, this.form), {}, {
        options: [].concat(_toConsumableArray(this.form.options), [""])
      });
    },
    fieldTypeChange: function fieldTypeChange(index) {
      if (index === 1) {
        this.form = _objectSpread(_objectSpread({}, this.form), {}, {
          options: [""]
        });
      } else {
        this.form = _objectSpread(_objectSpread({}, this.form), {}, {
          options: null
        });
      }
    },
    removeOption: function removeOption(index) {
      var temp = _toConsumableArray(this.form.options);
      temp.splice(index, 1);
      this.form = _objectSpread(_objectSpread({}, this.form), {}, {
        options: temp
      });
    },
    onNecChange: function onNecChange(checked, index) {
      this.$emit("editOk", {
        params: {
          isNecessary: checked
        },
        mode: "edit",
        index: index
      });
    },
    onOpenChange: function onOpenChange(checked, index) {
      this.$emit("editOk", {
        params: {
          isOpen: checked
        },
        mode: "edit",
        index: index
      });
    },
    handleOk: function handleOk() {
      var _this = this;
      this.confirmLoading = true;
      this.$refs.ruleForm.validate(function (valid, res) {
        console.log("fieldEdit", valid, res);
        if (valid) {
          var _form = JSON.parse(JSON.stringify(_this.form));
          var flag = false;
          if (_form.fieldType === 1) {
            _form.options.forEach(function (item, index) {
              if (item === "") {
                flag = true;
              }
            });
          }
          if (flag) {
            _this.$notification["error"]({
              message: "error",
              description: "自定义选项不能为空"
            });
            return false;
          } else {
            if (_form.fieldType === 1) {
              _form.options = _form.options.join(",");
            }
            _this.$emit("editOk", {
              params: _form,
              mode: _this.modalMode,
              index: _this.modalMode === "edit" ? _this.editItemIndex : null
            });
            _this.handleCancel();
          }
        } else {
          return false;
        }
      });
      this.confirmLoading = false;
    },
    handleCancel: function handleCancel() {
      this.modalVisible = false;
    },
    onEditItem: function onEditItem(idx, isDisabled) {
      if (isDisabled) {
        return false;
      }
      this.editItemIndex = idx;
      this.modalMode = "edit";
      this.modalTitle = "编辑字段";
      this.form = _objectSpread(_objectSpread({}, this.dataSourceCopy[idx]), {}, {
        options: this.dataSourceCopy[idx].options ? this.dataSourceCopy[idx].options.split(",") : []
      });
      this.modalVisible = true;
    },
    onDelItem: function onDelItem(idx, isDisabled) {
      if (isDisabled) {
        return false;
      }
      this.$emit("delItem", idx);
    },
    onAddItem: function onAddItem() {
      this.modalMode = "add";
      this.modalTitle = "新增字段";
      this.form = {
        options: null
      };
      this.modalVisible = true;
    },
    onDragEnd: function onDragEnd(evt) {
      this.$emit("sortItem", evt.oldIndex, evt.newIndex);
    }
  }
};