import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import moment from "moment";
import echarts from "echarts";
import { selectRevenueAmountCount as _selectRevenueAmountCount } from "../api";
export default {
  props: ["siteId"],
  data: function data() {
    return {
      colors: localStorage.getItem("primary"),
      mode: ["month", "month"],
      value: ["".concat(moment().subtract(5, "months").format("YYYY-MM")), "".concat(moment().format("YYYY-MM"))],
      queryType: 1,
      chartMonths: [],
      chartData: []
    };
  },
  watch: {
    value: function value(val) {
      this.selectRevenueAmountCount(val);
      this.chartMonths = this.getDiffDate(this.value[0], this.value[1]);
    },
    siteId: function siteId(newValue) {
      this.selectRevenueAmountCount(this.value);
      // console.log("debug log --> newValue", newValue);
    }
  },

  computed: {},
  mounted: function mounted() {
    this.selectRevenueAmountCount(this.value);
    this.chartMonths = this.getDiffDate(this.value[0], this.value[1]);
  },
  methods: {
    handlePanelChange2: function handlePanelChange2(value, mode) {
      this.value = value;
    },
    showChart: function showChart() {
      var _this = this;
      this.$nextTick(function () {
        var myChart = echarts.init(document.getElementById("revenueChart"));
        var option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985"
              }
            }
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            top: "32px",
            containLabel: true
          },
          xAxis: [{
            type: "category",
            boundaryGap: false,
            data: _this.chartMonths,
            axisLine: {
              lineStyle: {
                color: "#767885"
              }
            }
          }],
          yAxis: [{
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#767885"
              }
            }
          }],
          series: [{
            name: "营收总金额",
            type: "line",
            stack: "总量",
            smooth: true,
            areaStyle: {
              normal: {
                color: _this.colors // 改变区域颜色
              }
            },

            itemStyle: {
              normal: {
                color: _this.colors,
                // 改变折线点的颜色
                lineStyle: {
                  color: _this.colors // 改变折线颜色
                }
              }
            },

            emphasis: {
              focus: "series"
            },
            data: _this.chartData
          }]
        };
        myChart.setOption(option);
        window.onresize = myChart.resize;
      });
    },
    selectRevenueAmountCount: function selectRevenueAmountCount(data) {
      var _this2 = this;
      var da = {
        startDate: moment(data[0]).startOf("month").format("YYYY-MM-DD"),
        endDate: moment(data[1]).endOf("month").format("YYYY-MM-DD")
      };
      if (this.siteId && this.siteId !== "undefined" && this.siteId !== "-1") {
        da.companyIds = this.siteId;
      }
      _selectRevenueAmountCount(da).then(function (res) {
        var data = res.data;
        _this2.chartData = new Array(_this2.chartMonths.length).fill(0);
        data.forEach(function (element) {
          var yearly = element.yearly;
          var monthlyString = element.monthly + "";
          var monthly = monthlyString.length === 1 ? "0" + element.monthly : element.monthly.toString;
          var chartMonthsPos = _this2.chartMonths.indexOf(yearly + "-" + monthly);
          _this2.chartData.splice(chartMonthsPos, 1, element.totalAmount);
        });
        _this2.showChart();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    getDiffDate: function getDiffDate(minDate, maxDate) {
      var months = [];
      var startDate = new Date(moment(minDate).format("YYYY-MM-DD"));
      var endDate = new Date(moment(maxDate).format("YYYY-MM-DD"));
      // 把时间的天数都设置成当前月第一天
      startDate.setDate(1);
      endDate.setDate(1);
      // new Date(yyyy-MM-dd) 不知为何有时候小时是 08 有时候是00
      endDate.setHours(0);
      startDate.setHours(0);
      while (endDate.getTime() >= startDate.getTime()) {
        var year = startDate.getFullYear();
        var month = startDate.getMonth() + 1;
        // 加一个月
        startDate.setMonth(month);
        if (month.toString().length === 1) {
          month = "0" + month;
        }
        months.push(year + "-" + month);
      }
      return months;
    }
  }
};