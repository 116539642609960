import request from "@/utils/request";

// 员工参保金额统计
export function getAmount(params) {
  return request({
    url: "/hr/api/v1/empSocInfos/getAmount",
    method: "get",
    params: params
  });
}
export function getEmpSocCount(params) {
  return request({
    url: "/hr/api/v1/empSocTransform/selectEmpSocCount",
    method: "get",
    params: params
  });
}
export function getAllUser(params) {
  return request({
    url: "/hr/api/v1/users/dispatchList",
    method: "get",
    params: params
  });
}
export function registrationUnit(areaId) {
  return request({
    url: "/hr/api/v1/ztcConfigs/registrationUnit/" + areaId,
    method: "get"
  });
}
export function getShebaoToken(areaId) {
  return request({
    url: "hr/api/v1/ztcConfigs/getWebSiteCookie/" + areaId,
    method: "get"
  });
}
// 初次新增月账单
export function empSocMonths(data) {
  return request({
    url: "/hr/api/v1/empSocMonths/initSave",
    method: "post",
    data: data
  });
}
// 分页查询渠道管理
export function getChannels(params) {
  return request({
    url: "/hr/api/v1/channels",
    method: "get",
    params: params
  });
}
// 查询标签
export function listByCompanyIdGet(params) {
  return request({
    url: "/hr/api/v1/empTag/listByCompanyId",
    method: "get",
    params: params
  });
}
// 分页查询新增员工参保信息
export function pageAddEmpSocInfo(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/pageAddEmpSocInfo",
    method: "get",
    params: data
  });
}
export function getCityAcc(params) {
  return request({
    url: "hr/api/v1/socCache",
    method: "get",
    params: params
  });
}
// 社保名单导出
export function getCity(params) {
  return request({
    url: "/hr/api/v1/socWebsites/listAllNotSitId",
    method: "get",
    params: params
  });
}
// 同步社保
export function syncData(data) {
  return request({
    url: "/hr/api/v1/syncSocWebSite/batchSync",
    method: "post",
    data: data
  });
}
export function getToken(companyId) {
  return request({
    url: "/hr/api/v1/ztcConfigs/ztcAccessTokenExist/" + companyId,
    method: "get"
  });
}
export function verAccToken(data) {
  return request({
    url: "/hr/api/v1/ztcConfigs/saveCacheAccessToken",
    method: "post",
    data: data
  });
}
export function settingsResponsible(data) {
  return request({
    url: "/hr/api/v1/empSocBackResponsibles/settingsResponsible",
    method: "post",
    data: data
  });
}
export function incrementSocEmp(data) {
  return request({
    url: "/hr/api/v1/syncSocWebSite/incrementSocEmp",
    method: "post",
    data: data
  });
}
export function selectByDictCode(params) {
  return request({
    url: "/hr/api/v1/ztcConfigs/selectByDictCode",
    method: "get",
    params: params
  });
}
export function selectLikeByCodeAndName(params) {
  return request({
    url: "hr/api/v1/ztcConfigs/selectLikeByCodeAndName",
    method: "get",
    params: params
  });
}
export function selectShiName(params) {
  return request({
    url: "/hr/api/v1/ztcConfigs/selectDictByParentId",
    method: "get",
    params: params
  });
}
export function checkEmpQ(data) {
  return request({
    url: "/hr/api/v1/ztcConfigs/checkEmpQdWebSite",
    method: "post",
    data: data
  });
}
export function differenceOk(data) {
  return request({
    url: "hr/api/v1/socDifferences/bachDifference",
    method: "post",
    data: data
  });
}

// 分页查询员工参保信息
export function pageEmpSocInfo(params) {
  return request({
    url: "/hr/api/v1/empSocInfos/pageEmpSocInfo",
    method: "get",
    params: params
  });
}
export function selectSchemeByEmpSocInfoId(params) {
  return request({
    url: "/hr/api/v1/socProgs/selectSchemeByEmpSocInfoId",
    method: "get",
    params: params
  });
}

// 分页查询停保员工参保信息
export function pageSuspendEmpSocInfo(params) {
  return request({
    url: "/hr/api/v1/empSocInfos/pageSuspendEmpSocInfo",
    method: "get",
    params: params
  });
}

// 按照参保金额编辑
export function setAmount(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/setAmount",
    method: "post",
    data: data
  });
}

// 按照参保方案编辑
export function setCardinality(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/setCardinality",
    method: "post",
    data: data
  });
}

// 批量删除员工参保信息表，彻底删除
export function deleteEmpSocInfos(data) {
  return request({
    url: "/hr/api/v1/empSocMonths",
    method: "delete",
    data: data
  });
}

// 查询员工参保月账单列表
export function getEmpSocInfoMonthList(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/getEmpSocInfoMonthList",
    method: "get",
    params: data
  });
}
// 查询参保方案相关
export function getListAllRelation(data) {
  return request({
    url: "/hr/api/v1/socProgs/listAllRelation",
    method: "get",
    params: data
  });
}
// 编辑减员
export function updatajianyuan(data) {
  return request({
    url: "/hr/api/v1/empSocPlans/decreaseUpdateExtra",
    method: "put",
    data: data
  });
}
// 增员编辑
export function updatazengyuan(data) {
  return request({
    url: "/hr/api/v1/empSocPlans/increaseUpdateExtra",
    method: "put",
    data: data
  });
}
// 取消增员
export function remvoeZenyuanOk(planId) {
  return request({
    url: "/hr/api/v1/empSocPlans/cancelIncrement/".concat(planId),
    method: "put"
  });
}
// 取消增员
export function remvoeJianyuanOk(planId) {
  return request({
    url: "/hr/api/v1/empSocPlans/cancelDecrease/".concat(planId),
    method: "put"
  });
}
// 查询员工参保信息表
export function getEmpSocListAll(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/listAll",
    method: "get",
    data: data
  });
}

// 新增员工参保次月账单
export function saveEmpSocInfoNextMonth(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/saveEmpSocInfoNextMonth",
    method: "post",
    data: data
  });
}

// 删除单个员工参保信息列表
export function deleteSingle(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/" + data.id,
    method: "delete",
    data: data
  });
}

// 修改员工参保信息表
export function changeEmpSocInfos(data) {
  return request({
    url: "/hr/api/v1/empSocInfos",
    method: "put",
    data: data
  });
}
// 批量撤销参保
export function changeEmpBatchRevocation(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/batchRevocation",
    method: "put",
    data: data
  });
}

// 参保记录
export function checkEmpSocInfos(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/selectEmpSocInfoByEmpId",
    method: "get",
    params: data
  });
}
export function getEnum(data) {
  return request({
    url: "/hr/api/v1/empSocTransform/getEmpSocListQueryEnum",
    method: "get",
    params: data
  });
}
// 员工参保管理停保
export function updateEmpSocStatus(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/updateEmpSocStatus",
    method: "put",
    data: data
  });
}

// 查询某个参保方案试用的月份
export function selectTimeInfoByProgId(params) {
  return request({
    url: "/hr/api/v1/empSocInfos/selectTimeInfoByProgId",
    method: "get",
    params: params
  });
}
// 查询增减员顶部统计数据
export function getTopZengJian(params) {
  return request({
    url: "/hr/api/v1/empSocPlans/increaseDecreaseListCount",
    method: "get",
    params: params
  });
}
// 查询增减员计划列表
export function getTopZengJianList(params) {
  return request({
    url: "/hr/api/v1/empSocPlans/increaseOrDecreaseList",
    method: "get",
    params: params
  });
}
// 查询未反馈人数
export function getNoCount(companyId) {
  return request({
    url: "/hr/api/v1/empSocPlans/notFeedbackCount/" + companyId,
    method: "get"
  });
}
// 批量添加
export function addSoc(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/addSoc",
    method: "post",
    data: data
  });
}
// 减员列表
export function getJianYuanList(data) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpSocByEmpIdList",
    method: "post",
    data: data
  });
}
// 社保增员信息
export function addEmpSocInfos(data) {
  return request({
    url: "/hr/api/v1/empSocPlans/increaseExtra",
    method: "post",
    data: data
  });
}

// 社保减员信息
export function decEmpSocInfos(data) {
  return request({
    url: "/hr/api/v1/empSocPlans/decreaseExtra",
    method: "post",
    data: data
  });
}
export function getHead(params) {
  return request({
    url: "hr/api/v1/empSocInfos/getDynamicHead",
    method: "GET",
    params: params
  });
}
export function getUpdateHead(data) {
  return request({
    url: "/admin/api/v1/headerFields/updateHead ",
    method: "PUT",
    data: data
  });
}
// 校验减员人员
export function checkDecrease(data) {
  return request({
    url: "/hr/api/v1/empSocPlans/checkDecrease",
    method: "post",
    data: data
  });
}

// 增员确认
export function increaseConfirm(data) {
  return request({
    url: "/hr/api/v1/empSocPlans/increaseFeedbackExtra",
    method: "put",
    data: data
  });
}

// 减员确认
export function decreaseConfirm(data) {
  return request({
    url: "/hr/api/v1/empSocPlans/decreaseFeedbackExtra",
    method: "put",
    data: data
  });
}
export function deleteById(empSocInfoId) {
  return request({
    url: "hr/api/v1/empSocInfos/deleteById/" + empSocInfoId,
    method: "delete"
  });
}
// 社保名单查询所有表头
export function selectAllHead(companyId, monthId) {
  return request({
    url: "/hr/api/v1/empSocTransform/selectAllHead/".concat(companyId, "?monthId=") + monthId,
    method: "GET"
  });
}
// 补收
export function bushou(data) {
  return request({
    url: "/hr/api/v1/empSocTransform/fillGather",
    method: "post",
    data: data
  });
}
// 补收补缴预览
export function bushoubujiaoprivew(data) {
  return request({
    url: "/hr/api/v1/empSocTransform/previewFill",
    method: "post",
    data: data
  });
}
// 补缴
export function bujiao(data) {
  return request({
    url: "/hr/api/v1/empSocTransform/pfFillPayment",
    method: "post",
    data: data
  });
}
// 获取补收年月
export function getbushoudate(params) {
  return request({
    url: "/hr/api/v1/empSocInfos/selectEmpIsHaveFillCollectAndNormalDate",
    method: "get",
    params: params
  });
}
// 获取月id
export function getMonthId(params) {
  return request({
    url: "/hr/api/v1/empSocMonths/checkMonthNextAndPrevious",
    method: "get",
    params: params
  });
}
// 批量参保
export function addBatchNormalSoc(data) {
  return request({
    url: "/hr/api/v1/empSocTransform/batchNormalSoc",
    method: "post",
    data: data
  });
}
// 新增社保回款记录(批量)
export function addBatchRecord(data) {
  return request({
    url: "/hr/api/v1/empSocBackRecordInfos/saveBatch",
    method: "post",
    data: data
  });
}
// 分页查询社保回款信息
export function getRecordInfos(data) {
  return request({
    url: "/hr/api/v1/empSocBackRecordInfos/pagePost",
    // `/hr/api/v1/empSocBackRecordInfos`,
    method: "post",
    data: data
  });
}
// 初始化公司下所有员工的社保相关信息
export function initAllRelatedByCompany(companyId) {
  return request({
    url: "/hr/api/v1/empSocRelateds/initAllRelatedByCompany/" + companyId,
    method: "post"
  });
}
// 删除单个社保回款信息，彻底删除
export function deleteRecord(empSocBackRecordInfoId) {
  return request({
    url: "/hr/api/v1/empSocBackRecordInfos/".concat(empSocBackRecordInfoId),
    method: "delete"
  });
}
// 新增社保回款记录
export function addRecordRecords(data) {
  return request({
    url: "/hr/api/v1/empSocBackRecordRecords",
    method: "post",
    data: data
  });
}
// 分页查询社保回款记录
export function getRecordRecords(params) {
  return request({
    url: "/hr/api/v1/empSocBackRecordRecords",
    method: "get",
    params: params
  });
}
// 分页查询社保回款记录
export function getEmpSocImport(data) {
  return request({
    url: "/hr/api/v1/empSocRelateds/empSocRelatedImportData",
    method: "post",
    data: data
  });
}
// 方案名字
export function selectAllEmpFiled(companyId) {
  return request({
    url: "hr/api/v1/customTemplateFields/selectAllEmpFiled/" + companyId,
    method: "get"
  });
}