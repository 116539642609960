import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(_vm._s(_vm.title))]), _c("div", {
    staticClass: "head"
  }, [_c("div", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.personalPeriod))]), _c("div", [_vm.isAll === false && _vm.buttonArchive.isArchive === "false" ? _c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      disabled: _vm.approStatus === "true",
      type: "primary"
    },
    on: {
      click: _vm.goImport
    }
  }, [_vm._v("01 工资数据导入")]) : _vm._e(), _vm.isAll === false && _vm.isArchive !== "true" ? _c("a-icon", {
    staticStyle: {
      "margin-right": "10px",
      color: "#9c9c9c"
    },
    attrs: {
      type: "double-right"
    }
  }) : _vm._e(), _vm.isAll === false && _vm.buttonArchive.isArchive === "false" ? _c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      disabled: _vm.approStatus === "true",
      type: "primary"
    },
    on: {
      click: _vm.showSynchronous
    }
  }, [_vm._v("02 同步社保")]) : _vm._e(), _vm.isAll === false && _vm.isArchive !== "true" ? _c("a-icon", {
    staticStyle: {
      "margin-right": "10px",
      color: "#9c9c9c"
    },
    attrs: {
      type: "double-right"
    }
  }) : _vm._e(), _vm.isAll === false && _vm.buttonArchive.isArchive === "false" ? _c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      disabled: _vm.approStatus === "true",
      type: "primary"
    },
    on: {
      click: _vm.taxCalculation
    }
  }, [_vm._v("03 计算个税")]) : _vm._e(), _vm.isAll === false && _vm.isArchive !== "true" ? _c("a-icon", {
    staticStyle: {
      "margin-right": "10px",
      color: "#9c9c9c"
    },
    attrs: {
      type: "double-right"
    }
  }) : _vm._e(), _c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuClick
    },
    slot: "overlay"
  }, [_vm.isArchive === "true" ? _c("a-menu-item", {
    key: "1"
  }, [_vm._v(" 生成银行报盘 ")]) : _vm._e(), _vm.isArchive === "true" ? _c("a-menu-item", {
    key: "2"
  }, [_vm._v(" 生成电子工资条 ")]) : _vm._e(), _vm.isAll === false && _vm.buttonArchive.isArchive === "false" ? _c("a-menu-item", {
    key: "5",
    attrs: {
      disabled: _vm.approStatus === "true"
    }
  }, [_vm._v("新增计薪人员")]) : _vm._e(), _vm.isAll === false && _vm.buttonArchive.isArchive === "false" ? _c("a-menu-item", {
    key: "10",
    attrs: {
      disabled: _vm.approStatus === "true"
    }
  }, [_vm._v("考勤数据导入")]) : _vm._e(), _vm.isAll === false && _vm.buttonArchive.isArchive === "false" ? _c("a-menu-item", {
    key: "6",
    attrs: {
      disabled: _vm.approStatus === "true"
    }
  }, [_vm._v("归档")]) : _vm._e(), _vm.isAll ? _c("a-menu-item", {
    key: "7",
    attrs: {
      disabled: _vm.detailList.customerFees === 0 || _vm.detailList.customerFees === null || _vm.approStatus === "true"
    }
  }, [_vm._v("生成薪资账单")]) : _vm._e()], 1), _c("a-button", [_vm._v(" 更多功能 "), _c("a-icon", {
    attrs: {
      type: "caret-down"
    }
  })], 1)], 1)], 1)]), _c("a-modal", {
    attrs: {
      title: "生成电子工资条",
      "ok-text": "点击生成",
      "cancel-text": "取消",
      "ok-button-props": {
        props: {
          disabled: !_vm.tableName
        }
      }
    },
    on: {
      ok: _vm.generateWage
    },
    model: {
      value: _vm.generatelVisible,
      callback: function callback($$v) {
        _vm.generatelVisible = $$v;
      },
      expression: "generatelVisible"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "0 30px"
    }
  }, [_c("label", [_vm._v("工资条名称：")]), _c("a-input", {
    staticStyle: {
      width: "160px",
      "margin-left": "10px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.tableName,
      callback: function callback($$v) {
        _vm.tableName = $$v;
      },
      expression: "tableName"
    }
  })], 1)]), _c("a-modal", {
    attrs: {
      title: "生成银行报盘",
      "ok-text": "点击生成",
      "cancel-text": "取消",
      "ok-button-props": {
        props: {
          disabled: !_vm.bankType
        }
      }
    },
    on: {
      ok: _vm.statisticalOk
    },
    model: {
      value: _vm.statisticalVisible,
      callback: function callback($$v) {
        _vm.statisticalVisible = $$v;
      },
      expression: "statisticalVisible"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "0 30px"
    }
  }, [_c("label", [_vm._v("银行类型：")]), _c("a-select", {
    staticStyle: {
      width: "160px",
      "margin-left": "10px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.bankChange
    },
    model: {
      value: _vm.bankType,
      callback: function callback($$v) {
        _vm.bankType = $$v;
      },
      expression: "bankType"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "HUAXIA_BANK"
    }
  }, [_vm._v("华夏银行")]), _c("a-select-option", {
    attrs: {
      value: "QINGDAO_BANK"
    }
  }, [_vm._v("青岛银行")]), _c("a-select-option", {
    attrs: {
      value: "MINSHENG_BANK"
    }
  }, [_vm._v("民生银行")]), _c("a-select-option", {
    attrs: {
      value: "ZHONGXIN_BANK"
    }
  }, [_vm._v("中信银行")])], 1)], 1)]), _c("EmployeeSelect", {
    attrs: {
      show: _vm.chooseMemberVisible,
      monthly: _vm.monthly,
      yearly: _vm.yearly,
      status: _vm.status,
      "default-selected-keys": _vm.defaultSelectedKeys,
      "selected-list": _vm.selectedList
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.chooseMemberVisible = $event;
      },
      "update:selectedList": function updateSelectedList($event) {
        _vm.selectedList = $event;
      },
      "update:selected-list": function updateSelectedList($event) {
        _vm.selectedList = $event;
      },
      change: _vm.memberSelectedChange
    }
  }), _c("a-modal", {
    attrs: {
      "ok-button-props": {
        props: {
          disabled: !_vm.selectRowKey.length
        }
      },
      title: "确认计薪人员",
      width: "600px",
      okText: "更换",
      cancelText: "取消"
    },
    on: {
      ok: _vm.replace
    },
    model: {
      value: _vm.empVisible,
      callback: function callback($$v) {
        _vm.empVisible = $$v;
      },
      expression: "empVisible"
    }
  }, [_c("div", {
    staticClass: "emp-title"
  }, [_c("span", [_vm._v("检测到以下员工本月已创建月工资表，是否选择更换工资表？")])]), _c("a-table", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      columns: _vm.empColumns,
      "data-source": _vm.createdList,
      "row-key": "key",
      scroll: {
        y: 300
      },
      pagination: false,
      "row-selection": {
        selectedRowKeys: _vm.selectRowKey,
        onChange: _vm.onSelectChange2
      }
    }
  }), _c("a-layout")], 1), _c("a-modal", {
    attrs: {
      width: 450
    },
    on: {
      ok: _vm.handleAbolition
    },
    model: {
      value: _vm.abolitionVisible,
      callback: function callback($$v) {
        _vm.abolitionVisible = $$v;
      },
      expression: "abolitionVisible"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "left"
    }
  }, [_c("div", {
    staticClass: "primary_color",
    staticStyle: {
      "margin-left": "15px",
      "font-size": "35px"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "question-circle"
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-left": "15px",
      position: "relative",
      top: "3px",
      "font-size": "16px",
      "font-weight": "500",
      color: "#000000ff"
    }
  }, [_c("span", [_vm._v(" 是否确认归档？归档后本工资表所有数据 "), _c("span", {
    staticStyle: {
      color: "#ec5252ff"
    }
  }, [_vm._v("不允许删除或更改")])])])])]), _c("div", {
    staticClass: "fix-item-wrapper flex-center"
  }, [_c("div", {
    staticClass: "fix-item-one"
  }, [_c("div", {
    staticClass: "fix-item-oneBox"
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: _vm.combined,
      alt: ""
    }
  }), _c("div", {
    staticClass: "fix-item-name",
    staticStyle: {
      "margin-left": "16px"
    }
  }, [_vm._v("合计")])])]), _c("div", {
    staticClass: "fix-item-two overflow-y-auto flex-center"
  }, [_c("div", {
    staticClass: "item-two-Box"
  }, [_c("div", {
    staticClass: "item-two-border"
  }, [_c("div", {
    staticClass: "childpProjectName"
  }, [_vm._v("本月计薪人员")]), _c("div", {
    staticClass: "childContentName"
  }, [_vm._v(_vm._s(_vm.detailList.empNumber ? _vm.detailList.empNumber : 0) + "人")])])]), _vm._l(_vm.detailList.covarianceItemAmountVOList, function (item) {
    return _c("div", {
      key: item.name,
      staticClass: "item-two-Box"
    }, [_c("div", {
      staticClass: "item-two-border"
    }, [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v("¥" + _vm._s(item.amount || item.amount === 0 ? item.amount : "--"))])])]);
  })], 2)]), _vm.isAll === false && _vm.isArchive !== "true" ? _c("div", {
    staticStyle: {
      padding: "0 20px 20px 20px"
    }
  }, [_c("a-alert", {
    attrs: {
      closable: "",
      message: "我们建议您按此流程进行工资计算：工资数据导入 ——> 同步社保 ——> 计算个税",
      type: "info",
      "show-icon": ""
    }
  })], 1) : _vm._e(), _vm.isAll === false && _vm.approStatus === "true" || _vm.isAll === false && _vm.buttonArchive.isArchive === "true" ? _c("div", {
    staticStyle: {
      padding: "0 20px 20px 20px"
    }
  }, [_c("a-alert", {
    attrs: {
      message: "工资表已归档 不可进行操作",
      type: "warning",
      "show-icon": ""
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "operation"
  }, [_c("div", [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "姓名/手机号/身份证号"
    },
    on: {
      search: _vm.inputChange
    }
  }), _c("a-popover", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      trigger: "click",
      placement: "bottom"
    }
  }, [_c("div", {
    staticStyle: {
      width: "485px",
      height: "100px"
    },
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("a-select", {
    staticStyle: {
      "min-width": "145px",
      width: "145px",
      "margin-left": "12px",
      "margin-top": "24px"
    },
    attrs: {
      mode: "multiple",
      placeholder: "请选择标签",
      "allow-clear": ""
    },
    on: {
      change: _vm.selectChange
    },
    model: {
      value: _vm.tags,
      callback: function callback($$v) {
        _vm.tags = $$v;
      },
      expression: "tags"
    }
  }, _vm._l(_vm.labelList, function (item) {
    return _c("a-select-option", {
      key: item.tagName
    }, [_vm._v(" " + _vm._s(item.tagName))]);
  }), 1), _c("a-select", {
    staticStyle: {
      "min-width": "145px",
      width: "145px",
      "margin-left": "12px",
      "margin-top": "24px"
    },
    attrs: {
      placeholder: "请选择渠道",
      "allow-clear": true
    },
    on: {
      change: _vm.selectChange
    },
    model: {
      value: _vm.channelId,
      callback: function callback($$v) {
        _vm.channelId = $$v;
      },
      expression: "channelId"
    }
  }, _vm._l(_vm.channelNames, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-select", {
    staticStyle: {
      "min-width": "145px",
      width: "145px",
      "margin-left": "12px",
      "margin-top": "24px"
    },
    attrs: {
      options: _vm.$store.state.enums.postTypeLst,
      allowClear: "",
      placeholder: "请选择工作性质"
    },
    on: {
      change: _vm.selectChange
    },
    model: {
      value: _vm.postType,
      callback: function callback($$v) {
        _vm.postType = $$v;
      },
      expression: "postType"
    }
  })], 1), _c("a-icon", {
    attrs: {
      type: "filter"
    }
  })], 1)], 1), _c("div", [_c("a-button", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    },
    attrs: {
      type: "link",
      size: "small"
    },
    on: {
      click: _vm.reload
    }
  }, [_vm._v("刷新"), _c("a-icon", {
    attrs: {
      type: "reload"
    }
  })], 1), _c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }), _c("a-popover", {
    attrs: {
      title: "",
      trigger: "click",
      placement: "bottom"
    },
    on: {
      visibleChange: _vm.handleHeadChange
    },
    model: {
      value: _vm.headVisible,
      callback: function callback($$v) {
        _vm.headVisible = $$v;
      },
      expression: "headVisible"
    }
  }, [_c("div", {
    staticStyle: {
      width: "600px",
      height: "320px"
    },
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", [_c("div", [_vm._v("已选择表头")]), _c("div", {
    staticClass: "overview-tag-wrapper"
  }, [_c("draggable", {
    attrs: {
      filter: ".forbid",
      animation: "3000",
      move: _vm.onMove
    },
    on: {
      start: _vm.onStart,
      end: _vm.onEnd
    },
    model: {
      value: _vm.tempSelectedHead,
      callback: function callback($$v) {
        _vm.tempSelectedHead = $$v;
      },
      expression: "tempSelectedHead"
    }
  }, [_c("transition-group", _vm._l(_vm.tempSelectedHead, function (item, index) {
    return _c("a-tag", {
      key: index,
      class: item.title !== "姓名" && item.title !== "身份证号" ? "overview-select-tag" : "overview-select-tag-name forbid",
      attrs: {
        closable: item.title !== "姓名" && item.title !== "身份证号",
        color: item.title !== "姓名" && item.title !== "身份证号" ? "#fff" : "#E8E8E8"
      },
      on: {
        close: function close($event) {
          return _vm.deleteSelectedHead($event, index);
        }
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)], 1)], 1)]), _c("div", [_c("div", [_vm._v("可选择表头")]), _c("div", _vm._l(_vm.tempNoSelectedHead, function (item, index) {
    return _c("a-tag", {
      key: index,
      staticClass: "overview-no-select-tag",
      on: {
        click: function click($event) {
          return _vm.addSelectedHead(index);
        }
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)]), _c("a-row", {
    staticStyle: {
      position: "absolute",
      bottom: "20px",
      right: "20px"
    },
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: _vm.handleHeadCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleHeadSearch
    }
  }, [_vm._v("查询")])], 1)], 1), _c("a-button", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    },
    attrs: {
      type: "link",
      size: "small"
    }
  }, [_vm._v("选择表头"), _c("a-icon", {
    attrs: {
      type: "menu"
    }
  })], 1)], 1), _c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }), _c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuClick
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "8",
    attrs: {
      disabled: !_vm.dataList.length
    }
  }, [_vm._v("全部导出")])], 1), _c("a-button", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    },
    attrs: {
      type: "link",
      size: "small"
    }
  }, [_vm._v("导出"), _c("a-icon", {
    attrs: {
      type: "caret-down"
    }
  })], 1)], 1)], 1)]), _c("a-modal", {
    attrs: {
      title: "同步社保"
    },
    on: {
      ok: _vm.synchronousOk
    },
    model: {
      value: _vm.synchronousVisible,
      callback: function callback($$v) {
        _vm.synchronousVisible = $$v;
      },
      expression: "synchronousVisible"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "flex-start",
      "margin-left": "36px"
    }
  }, [_c("div", [_c("span", {
    staticStyle: {
      color: "#ff0000"
    }
  }, [_vm._v("*")]), _vm._v("选择月份：")]), _c("a-month-picker", {
    attrs: {
      placeholder: "请选择月份"
    },
    model: {
      value: _vm.synchronousMonth,
      callback: function callback($$v) {
        _vm.synchronousMonth = $$v;
      },
      expression: "synchronousMonth"
    }
  })], 1)]), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      "row-key": "id",
      loading: _vm.loading,
      columns: _vm.columns,
      "data-source": _vm.dataList,
      pagination: _vm.pagination,
      scroll: {
        x: 1200
      },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    }
  })], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.buttonState,
      expression: "!buttonState"
    }],
    staticStyle: {
      width: "100%",
      display: "flex",
      "justify-content": "center",
      position: "fixed",
      bottom: "0",
      height: "60px",
      "align-items": "center",
      background: "#ffffff",
      "box-shadow": "-1px 0px 4px 0px rgba(0, 0, 0, 0.13)",
      "z-index": "1"
    }
  }, [_vm.isAll === false && _vm.buttonArchive.isArchive === "false" ? _c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.buttonState,
      expression: "!buttonState"
    }],
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      disabled: _vm.approStatus === "true"
    },
    on: {
      click: _vm.deleteEmployes
    }
  }, [_vm._v("删除计薪人员")]) : _vm._e(), _vm.isAll === false && _vm.buttonArchive.isArchive === "false" ? _c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.buttonState,
      expression: "!buttonState"
    }],
    staticStyle: {
      "margin-right": "10%"
    },
    attrs: {
      type: "primary",
      disabled: _vm.approStatus === "true"
    },
    on: {
      click: _vm.goEdit
    }
  }, [_vm._v("在线编辑")]) : _vm._e()], 1), _c("checkModal", {
    attrs: {
      visible: _vm.visibleKQ,
      salaryTemplateSalaryIds: _vm.salaryTemplateId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleKQ = $event;
      },
      "update:salaryTemplateSalaryIds": function updateSalaryTemplateSalaryIds($event) {
        _vm.salaryTemplateId = $event;
      },
      "update:salary-template-salary-ids": function updateSalaryTemplateSalaryIds($event) {
        _vm.salaryTemplateId = $event;
      },
      upDate: _vm.upDate
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };