import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("header", {
    staticClass: "header-flex"
  }, [_c("div", {
    staticClass: "l_title"
  }, [_vm._v(" 打卡规则 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addRules
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 添加规则 ")], 1)], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "punchTimeList" ? _c("div", {
            key: index
          }, [_vm._l(text[0].workWeekList, function (tag) {
            return _c("a-tag", {
              key: tag,
              staticClass: "mg_b10",
              attrs: {
                color: "#6A66F6"
              }
            }, [_vm._v(_vm._s(tag))]);
          }), _vm._l(text[0].periodList, function (inneritem, j) {
            return _c("a-row", {
              key: j
            }, [_vm._v(" 上班 " + _vm._s(inneritem.startPeriod) + " - 下班" + _vm._s(inneritem.endPeriod) + " ")]);
          })], 2) : col.dataIndex === "locationList" ? _c("div", {
            key: index
          }, [_c("div", {
            staticClass: "locationList-box"
          }, _vm._l(text, function (pos) {
            return _c("a-tooltip", {
              key: pos.positioname,
              attrs: {
                placement: "bottomLeft"
              }
            }, [_c("template", {
              slot: "title"
            }, [_vm._v(" " + _vm._s(pos.positionName) + " ")]), _vm._v(" " + _vm._s(pos.positionName) + " ")], 2);
          }), 1)]) : col.dataIndex === "wifiList" ? _c("div", {
            key: index
          }, _vm._l(text, function (item) {
            return _c("a-tooltip", {
              key: item.macAddr,
              attrs: {
                placement: "bottomLeft"
              }
            }, [_c("template", {
              slot: "title"
            }, [_vm._v(" " + _vm._s(item.name + "   " + item.macAddr) + " ")]), _vm._v(" " + _vm._s(item.name + "   " + item.macAddr)), _c("br")], 2);
          }), 1) : col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("span", {
            staticClass: "tc-theme cursor",
            on: {
              click: function click($event) {
                return _vm.editor(record);
              }
            }
          }, [_vm._v(" 编辑 ")]), _c("a-divider", {
            staticClass: "tc-theme",
            attrs: {
              type: "vertical"
            }
          }), _c("a-popconfirm", {
            attrs: {
              title: "确定删除?",
              "ok-text": "确定",
              "cancel-text": "取消"
            },
            on: {
              confirm: function confirm($event) {
                return _vm.deleteItem(record);
              }
            }
          }, [_c("span", {
            staticClass: "tc-theme cursor"
          }, [_vm._v("删除")])])], 1) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };