import request from "@/utils/request";
export function getHead(params) {
  // if (params.moduleCode === "月工资表列表") {
  //   ReqQuerySalaryHead(params.companyId);
  // }
  return request({
    url: "/admin/api/v1/headerFields/getHead",
    method: "GET",
    params: params
  });
}
export function getUpdateHead(data) {
  return request({
    url: "/admin/api/v1/headerFields/updateHead ",
    method: "PUT",
    data: data
  });
}
export function cstomField(companyId) {
  return request({
    url: "hr/api/v1/companyNecessaryFields/getCustomField/".concat(companyId),
    method: "GET"
  });
}