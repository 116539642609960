var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.title === "确认到岗" && !this.confirmedPost ? _c("a-alert", {
    attrs: {
      message: "批量到岗时，填写的数据将覆盖选中员工的旧数据",
      banner: ""
    }
  }) : _vm._e(), _c("inTemplateForm", {
    ref: "basic",
    staticClass: "pt24",
    attrs: {
      dataSource: _vm.basicData,
      dynamicData: _vm.empInfoDynamicData,
      formFields: _vm.basicFormJson.fields,
      sortedKeys: _vm.basicFormJson.sortedKeys,
      formConfig: {},
      remove: _vm.remove,
      lookShow: _vm.lookShow
    },
    on: {
      onRemove: _vm.onRemove,
      cancel: _vm.cancel,
      change: _vm.dynamicChange,
      customValueChange: _vm.onCustomValueChange
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };