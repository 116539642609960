import "core-js/modules/es.function.name.js";
import { fixStatus } from "@/api/check/checkCard";
import moment from "moment";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    jsonData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      form: {
        status: "未打卡",
        type: "补卡",
        time: "",
        name: "",
        note: ""
      }
    };
  },
  watch: {
    visible: function visible(v) {
      if (v) {
        this.form.time = this.jsonData.time;
        this.form.note = this.jsonData.note;
        this.form.name = this.$store.state.auth.name;
      }
    }
  },
  methods: {
    fixOk: function fixOk() {
      var _this = this;
      var data = {
        punchRecordId: this.jsonData.punchRecordId,
        number: this.jsonData.number,
        remark: this.form.note,
        calibrationMethod: "补卡",
        punchTime: moment(this.form.time).format("YYYY-MM-DD HH:mm:ss")
      };
      if (this.form.time) {
        fixStatus(data).then(function (res) {
          _this.$emit("onUpdate");
          _this.$emit("update:visible", false);
        });
      } else {
        this.$message.error("请选择时间!");
      }
    }
  }
};