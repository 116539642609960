import "D:\\tools\\frontend_workspace\\e-hr-master\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\tools\\frontend_workspace\\e-hr-master\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\tools\\frontend_workspace\\e-hr-master\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\tools\\frontend_workspace\\e-hr-master\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.number.to-fixed.js";
import Vue from "vue";
import App from "./App";
import store from "./store";
import { store as auth } from "xqjr-module-auth";
import { store as common } from "xqjr-plugin-common";
import approval from "xqjr-module-console/store/approval";
store.registerModule("auth", auth);
store.registerModule("common", common);
store.registerModule("approval", approval);
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
import consolePermission from "xqjr-module-console/store/permission";
store.registerModule("consolePermission", consolePermission);
import { XqTabs } from "xqjr-plugin-common";
Vue.component("xq-tabs", XqTabs);
import onceClick from "xqjr-plugin-common/directives/onceClick";
Vue.use(onceClick);
import directiveDrag from "./directive/drag";
Vue.use(directiveDrag);
import XqModalFooter from "xqjr-plugin-common/components/XqModalFooter";
Vue.component("XqModalFooter", XqModalFooter);
function findViewerImg(node) {
  if (node.parentElement.className.includes("viewer-container")) {
    var imgEle = node.parentElement.getElementsByTagName("img")[0];
    return imgEle;
  } else {
    return findViewerImg(node.parentElement);
  }
}
import { getToken } from "xqjr-module-auth";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
Vue.use(VueViewer, {
  defaultOptions: {
    zIndex: 9999,
    button: false,
    navbar: false,
    title: false,
    toolbar: {
      "zoom-in": true,
      "zoom-out": true,
      "one-to-one": true,
      prev: true,
      next: true,
      "rotate-left": true,
      "rotate-right": true,
      download: function download(evt) {
        var imgEle = findViewerImg(evt.target);
        var a = document.createElement("a");
        a.href = "".concat(imgEle.src, "?token=").concat(getToken());
        a.download = imgEle.alt;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },
    tooltip: true
  }
});
import { v4 as uuidv4 } from "uuid";
Vue.prototype.$uuidv4 = uuidv4;
Vue.prototype.$hasPermission = function (perStr) {
  var hasAny = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var menus = arguments.length > 2 ? arguments[2] : undefined;
  if (!perStr) {
    return false;
  }
  var permissionStringList = [];
  if (menus) {
    permissionStringList = menus;
  } else {
    var permissions = store.state.auth.permissions;
    if (permissions && permissions.permissionStringList && permissions.permissionStringList.length > 0) {
      permissionStringList = permissions.permissionStringList;
    }
  }
  var perArr = perStr.split(",");
  var flag = permissionStringList.includes(perArr[0]);
  for (var i = 1; i < perArr.length; i++) {
    flag = hasAny ? flag || permissionStringList.includes(perArr[i]) : flag && permissionStringList.includes(perArr[i]);
    if (!hasAny && !flag) {
      break;
    }
  }
  return flag;
};

// 二级权限判断，用于管理端顶部菜单显示隐藏判断
Vue.prototype.$hasPermissionSecond = function (perStr, menus) {
  if (!perStr) {
    return false;
  }
  var permissionStringList = [];
  if (menus) {
    permissionStringList = menus;
  } else {
    var permissions = store.state.auth.permissions;
    if (permissions && permissions.permissionStringList && permissions.permissionStringList.length > 0) {
      permissionStringList = permissions.permissionStringList;
    }
  }
  var flag = false;
  for (var i = 0; i < permissionStringList.length; i++) {
    if (permissionStringList[i].split(":")[1] === perStr.split(":")[1]) {
      flag = true;
      break;
    }
  }
  return flag;
};

// 二级权限判断，用于管理端顶部菜单显示隐藏判断
Vue.prototype.$hasPermissionFirst = function (perStr, menus) {
  if (!perStr) {
    return false;
  }
  var permissionStringList = [];
  if (menus) {
    permissionStringList = menus;
  } else {
    var permissions = store.state.auth.permissions;
    if (permissions && permissions.permissionStringList && permissions.permissionStringList.length > 0) {
      permissionStringList = permissions.permissionStringList;
    }
  }
  var flag = false;
  for (var i = 1; i < permissionStringList.length; i++) {
    if (permissionStringList[i].split(":")[0] === perStr.split(":")[0]) {
      flag = true;
      break;
    }
  }
  return flag;
};
// 乘法（处理计算丢失精度）
Vue.prototype.$NumberMul = function (arg1, arg2) {
  var m = 0;
  var s1 = arg1 ? arg1.toString() : "0";
  var s2 = arg2 ? arg2.toString() : "0";
  try {
    m += s1.split(".")[1].length;
  } catch (e) {
    console.log("err", e);
  }
  try {
    m += s2.split(".")[1].length;
  } catch (e) {
    console.log("err", e);
  }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
};
// 加法（处理计算丢失精度）
Vue.prototype.$NumberAdd = function (arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
    console.log("err", e);
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
    console.log("err", e);
  }
  m = Math.pow(10, Math.max(r1, r2));
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m + arg2 * m) / m).toFixed(n);
};
import { setCondition, getCondition, setPageSize, getPageSize, setPage, getPage } from "./utils/globalFuncs";
Vue.prototype.$setCondition = setCondition;
Vue.prototype.$getCondition = getCondition;
Vue.prototype.$setPageSize = setPageSize;
Vue.prototype.$getPageSize = getPageSize;
Vue.prototype.$setPage = setPage;
Vue.prototype.$getPage = getPage;
import router from "./router";
import antdv, { Icon } from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
Vue.use(antdv);
import vcolorpicker from "vcolorpicker";
Vue.use(vcolorpicker);
var XqIcon = Icon.createFromIconfontCN({
  scriptUrl: "https://at.alicdn.com/t/c/font_1600898_bhktrufdttm.js"
});
Vue.component("xq-icon", XqIcon);
import VuePerfectScrollbar from "vue-perfect-scrollbar";
Vue.component("xq-scrollbar", VuePerfectScrollbar);
/* 表单设计器 */
import KFormDesign from "k-form-design-callcter";
import "k-form-design-callcter/lib/k-form-design-mini.css";
Vue.use(KFormDesign);
import "./components/CustomFormDesign";
import "@/styles/index.scss"; // global css
import "xqjr-plugin-common/styles/index.scss";
import "xqjr-plugin-form/styles/index.scss";
import service from "@/utils/request.js";
Vue.prototype.$request = service;

/* 全局混入 */
import mixin from "@/utils/mixin";
import "./styles/iconfont/iconfont.css";
/* 全局$eventBus */
Vue.prototype.$eventBus = new Vue();
// 事件总线
Vue.prototype.eventBus = new Vue();
import moment from "moment"; // 导入文件
import "moment/locale/zh-cn";
moment.locale("zh-cn");
Vue.prototype.moment = moment; // 赋值使用
Vue.prototype.$moment = moment; // 赋值使用
import dayjs from "dayjs";
Vue.prototype.$dayjs = dayjs;
import axios from "axios";
Vue.prototype.$axios = axios;
Vue.mixin(mixin);
Vue.config.productionTip = false;
Vue.directive("focus", {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function inserted(el) {
    el.focus();
  }
});
/**
 * 全局下载方法
 */

import all from "./utils/globalMethod";
Vue.use(all); // 将全局函数当做插件来进行注册
import zxr from "./components/ZXR/index";
Vue.use(zxr);
import TableBody from "./components/Table/tableBody";
import TableTitle from "./components/Table/tableTitle";
import TableScreening from "./components/Table/tableScreening";
Vue.component("TableBody", TableBody);
Vue.component("TableTitle", TableTitle);
Vue.component("TableScreening", TableScreening);
new Vue({
  el: "#app",
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});