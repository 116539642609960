var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "反馈详情",
      visible: _vm.visible,
      "destroy-on-close": true
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, [_c("a-row", {
    staticClass: "title"
  }, [_c("strong", [_vm._v(_vm._s(_vm.options["姓名"]))])]), _c("a-row", {
    staticClass: "title"
  }, [_c("a-col", {
    attrs: {
      span: 4
    }
  }, [_c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("身份证号")])]), _c("a-col", {
    attrs: {
      span: 20
    }
  }, [_c("span", {
    staticClass: "sub_tit"
  }, [_vm._v(_vm._s(_vm.options["身份证号"]))])])], 1), _vm.history.length !== 0 ? _c("a-divider", {
    staticStyle: {
      "margin-top": "8px",
      "margin-bottom": "6px"
    }
  }) : _vm._e(), _vm.history.lenght !== 0 ? _c("div", {
    class: [_vm.history.length <= 2 ? "" : _vm.heightClass]
  }, _vm._l(_vm.history, function (item, i) {
    return _c("div", {
      key: i
    }, [_c("a-row", {
      staticClass: "title"
    }, [_c("a-col", {
      attrs: {
        span: 4
      }
    }, [_c("span", {
      staticClass: "sub_tit"
    }, [_vm._v("反馈人")])]), _c("a-col", {
      attrs: {
        span: 20
      }
    }, [_c("span", {
      staticClass: "sub_tit"
    }, [_vm._v(_vm._s(item.createUserName))])])], 1), _c("a-row", {
      staticClass: "title"
    }, [_c("a-col", {
      attrs: {
        span: 4
      }
    }, [_c("span", {
      staticClass: "sub_tit"
    }, [_vm._v("反馈状态")])]), _c("a-col", {
      attrs: {
        span: 20
      }
    }, [_c("span", {
      staticClass: "sub_tit"
    }, [_vm._v(_vm._s(["未完成", "已完成", "异常"][item.state]))])])], 1), _c("a-row", {
      staticClass: "title"
    }, [_c("a-col", {
      attrs: {
        span: 4
      }
    }, [_c("span", {
      staticClass: "sub_tit"
    }, [_vm._v("反馈时间")])]), _c("a-col", {
      attrs: {
        span: 20
      }
    }, [_c("span", {
      staticClass: "sub_tit"
    }, [_vm._v(_vm._s(_vm.moment(item.gmtCreate).format("YYYY-MM-DD HH:MM")))])])], 1), _c("a-row", {
      staticClass: "title"
    }, [_c("a-col", {
      attrs: {
        span: 4
      }
    }, [_c("span", {
      staticClass: "sub_tit"
    }, [_vm._v("反馈内容")])]), _c("a-col", {
      attrs: {
        span: 20
      }
    }, [_c("span", {
      staticClass: "sub_tit"
    }, [_vm._v(_vm._s(item.feedbackContent))])])], 1), i !== _vm.history.length - 1 ? _c("a-divider", {
      staticStyle: {
        "margin-top": "8px",
        "margin-bottom": "6px"
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e(), _vm.history.length !== 0 ? _c("a-divider", {
    staticStyle: {
      "margin-top": "8px",
      "margin-bottom": "6px"
    }
  }) : _vm._e(), _c("a-row", {
    staticClass: "title"
  }, [_c("a-col", {
    attrs: {
      span: 4
    }
  }, [_c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("状态")])]), _c("a-col", {
    attrs: {
      span: 20
    }
  }, [_c("a-radio-group", {
    attrs: {
      "default-value": _vm.options.status
    },
    on: {
      change: _vm.handleChange
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("完成")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("异常")])], 1)], 1)], 1), _c("a-row", {
    staticClass: "title mg_t12 content"
  }, [_c("a-textarea", {
    attrs: {
      placeholder: "反馈内容"
    },
    model: {
      value: _vm.options.remake,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "remake", $$v);
      },
      expression: "options.remake"
    }
  })], 1), _c("a-row", {
    staticClass: "primary",
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("可重复提交反馈，但会以最后提交的反馈为准!")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };