import PageHeader from "./PageHeader";
import Transfer from "./Transfer";
import Title from "./Title";
import SelectEmp from "./SelectEmp.vue";
import LeftMenu from "./leftMenu.vue";
import Collapse from "./collapse";
import DynamicForm from "./DynamicForm";
import Tab from "./Tab";
import PhotoAlbum from "./PhotoAlbum";
// import VueWangeditor from "./vueWangeditor"

var zxr = {
  install: function install(Vue) {
    Vue.component("PageHeader", PageHeader);
    Vue.component("Transfer", Transfer);
    Vue.component("Title", Title);
    Vue.component("SelectEmp", SelectEmp);
    Vue.component("LeftMenu", LeftMenu);
    Vue.component("Collapse", Collapse);
    Vue.component("DynamicForm", DynamicForm);
    Vue.component("Tab", Tab);
    Vue.component("PhotoAlbum", PhotoAlbum);
  }
};
export default zxr;