import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white p24"
  }, [_c("div", {
    staticClass: "flex-center-between"
  }, [_c("a-space", {
    attrs: {
      size: 24
    }
  }, [_c("a-input-search", {
    attrs: {
      placeholder: "销售名称/用工单位名称"
    },
    on: {
      change: _vm.onRulesNameChange
    },
    model: {
      value: _vm.companyOrSaleName,
      callback: function callback($$v) {
        _vm.companyOrSaleName = $$v;
      },
      expression: "companyOrSaleName"
    }
  }), _c("a-range-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      value: _vm.dateRange,
      placeholder: ["开始月份", "结束月份"],
      mode: ["month", "month"],
      format: "YYYY-MM"
    },
    on: {
      panelChange: _vm.onPanelChange
    }
  }), _c("a-input-search", {
    attrs: {
      placeholder: "提成规则名称"
    },
    on: {
      change: _vm.onRulesNameChange
    },
    model: {
      value: _vm.rulesName,
      callback: function callback($$v) {
        _vm.rulesName = $$v;
      },
      expression: "rulesName"
    }
  })], 1), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.calc
    }
  }, [_vm._v("提成计算")])], 1), _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-table", {
    staticClass: "mt24",
    attrs: {
      dataSource: _vm.recordLst,
      columns: _vm.calcColumns,
      pagination: _vm.pagination,
      rowKey: "id"
    },
    on: {
      change: _vm.onTableChange
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "计算提成"
    },
    on: {
      ok: _vm.calcOk
    },
    model: {
      value: _vm.calcVisible,
      callback: function callback($$v) {
        _vm.calcVisible = $$v;
      },
      expression: "calcVisible"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "选择用工单位",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.companyLst,
      showSearch: "",
      filterOption: _vm.filterOption,
      mode: "multiple",
      placeholder: "请选择"
    },
    model: {
      value: _vm.companyIdList,
      callback: function callback($$v) {
        _vm.companyIdList = $$v;
      },
      expression: "companyIdList"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "选择月份",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-range-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      value: _vm.calcDateRange,
      placeholder: ["开始月份", "结束月份"],
      mode: ["month", "month"],
      format: "YYYY-MM"
    },
    on: {
      panelChange: _vm.onCalcPanelChange
    }
  })], 1)], 1), _c("a-modal", {
    attrs: {
      title: "提成计算异常结果",
      width: "800px"
    },
    on: {
      ok: _vm.onErrOk
    },
    model: {
      value: _vm.errVisible,
      callback: function callback($$v) {
        _vm.errVisible = $$v;
      },
      expression: "errVisible"
    }
  }, [_c("div", [_c("div", {
    staticClass: "flex-center pl10",
    staticStyle: {
      "flex-flow": "row",
      height: "35px",
      width: "100%",
      "background-color": "#fffbe6",
      border: "1px solid #ffe58f",
      "border-radius": "5px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "5px"
    },
    attrs: {
      src: require("@/assets/icons/重复.png")
    }
  }), _c("span", {
    attrs: {
      sytle: "color:#55565D;font-size:14px;"
    }
  }, [_c("label", {
    staticClass: "redc"
  }, [_vm._v(_vm._s(_vm.errorCount))]), _vm._v("条数据计算失败 ")])]), _c("a-input-search", {
    staticClass: "mv12",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请输入用工单位"
    },
    model: {
      value: _vm.term,
      callback: function callback($$v) {
        _vm.term = $$v;
      },
      expression: "term"
    }
  }), _c("a-table", {
    attrs: {
      dataSource: _vm.errorLstFilter,
      columns: _vm.calcErrColumns,
      pagination: _vm.pagination2,
      rowKey: function rowKey(record) {
        return "".concat(record.companyName, "_").concat(record.month);
      }
    },
    on: {
      change: _vm.onTableChange2
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };