var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_c("span", [_vm._v("供应商")])]), _c("a-spin", {
    staticClass: "pb48 formStyle",
    staticStyle: {
      width: "840px",
      margin: "0 auto"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("Collapse", {
    attrs: {
      title: "基本信息",
      disabled: _vm.basicFormJson.editable,
      showBtns: _vm.mode !== "add"
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("basicFormJson");
      }
    }
  }, [_vm.basicData ? _c("DynamicForm", {
    ref: "basic",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.basicData,
      editable: _vm.basicFormJson.editable,
      dynamicData: {},
      formFields: _vm.basicFormJson.fields,
      sortedKeys: _vm.basicFormJson.sortedKeys,
      formConfig: {},
      showBtns: _vm.mode !== "add",
      formLayout: "vertical"
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.basicFormJson, "editable", $event);
      },
      change: function change($event) {
        return _vm.dynamicChange($event, "basicFormJson");
      }
    }
  }) : _vm._e()], 1), _c("Collapse", {
    attrs: {
      title: "联系信息",
      disabled: _vm.contactFormJson.editable,
      showBtns: _vm.mode !== "add"
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("contactFormJson");
      }
    }
  }, [_vm.contactData ? _c("DynamicForm", {
    ref: "contact",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.contactData,
      editable: _vm.contactFormJson.editable,
      dynamicData: {},
      formFields: _vm.contactFormJson["fields"],
      sortedKeys: _vm.contactFormJson.sortedKeys,
      formConfig: {},
      showBtns: _vm.mode !== "add",
      formLayout: "vertical"
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.contactFormJson, "editable", $event);
      },
      change: function change($event) {
        return _vm.dynamicChange($event, "contactFormJson");
      }
    }
  }) : _vm._e()], 1), _c("Collapse", {
    attrs: {
      title: "法人信息",
      disabled: _vm.corporateFormJson.editable,
      showBtns: _vm.mode !== "add"
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("corporateFormJson");
      }
    }
  }, [_vm.corporateData ? _c("DynamicForm", {
    ref: "corporate",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.corporateData,
      editable: _vm.corporateFormJson.editable,
      dynamicData: {},
      formFields: _vm.corporateFormJson.fields,
      sortedKeys: _vm.corporateFormJson.sortedKeys,
      formConfig: {},
      showBtns: _vm.mode !== "add",
      formLayout: "vertical"
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.corporateFormJson, "editable", $event);
      },
      change: function change($event) {
        return _vm.dynamicChange($event, "corporateFormJson");
      }
    }
  }) : _vm._e()], 1), _vm.mode === "add" ? _c("div", {
    staticClass: "bgc-white fixedBtn"
  }, [_c("a-space", [_c("a-button", {
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveActive
    }
  }, [_vm._v("保存")])], 1)], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };