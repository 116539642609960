import typeWork from "./typeWork";
import check from "./check";
export default {
  components: {
    typeWork: typeWork,
    check: check
  },
  data: function data() {
    return {
      componentName: "typeWork",
      numArr: [{
        type: "typeWork",
        name: "工种管理",
        num: 0
      }, {
        type: "check",
        name: "考勤管理",
        num: 0
      }],
      address: "",
      isSign: false
    };
  }
};