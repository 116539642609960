import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.slice.js";
import { getDepEmpInfo, getAllEmp, getAllEmp2, empListByIdCards, listByCompanyIdGet, empListByIdCards2, salaryCalculateRulesListall, empListByIdCardsRYK, empListByIdCardsTEM } from "@/api/company/contract";
import { getSalaryCalculateRuleGroups } from "@/api/outsourcing/outrules";
import { getNotList, getPeopleList, selectOutsourceSalary, getmpByCardAndSalaryType, isSameTemplate, getAllList as _getAllList } from "./api";
import { selectByCompanyId } from "@/api/wages/file";
import { getWageEmp, getWageEmp2 } from "@/api/company/contract";
import moment from "moment";
var columns = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: 80
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  width: 120
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 180
}];
export default {
  props: {
    status: {
      type: Number,
      default: 2,
      require: true
    },
    yearly: {
      type: String,
      default: "",
      require: true
    },
    monthly: {
      type: String,
      default: "",
      require: true
    },
    show: {
      type: Boolean,
      default: false
    },
    number: {
      type: Number,
      default: 0
    },
    templateId: {
      type: Number,
      default: 0
    },
    defaultSelectedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectedList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isOnlineOrLeave: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      seniorList: [],
      dataList: [],
      fixData: [],
      nameOrPhone: "",
      columns: columns,
      spinning: false,
      selectedRowKeys: [],
      copyVisible: false,
      importDataLength: 0,
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 16
      },
      mode: "1",
      form: {},
      labelList: [],
      treeData: [],
      errorState: false,
      selectedListTemp: [],
      chosenList: [],
      serviceFeeRuleList: [],
      rulusList: [],
      isAllSelected: false,
      seniorVisible: false,
      checkStatue: 0,
      textContent: "",
      selectedKeysBeforeSearch: [],
      postTypeValue: 3,
      pageSizeOptions: ["10", "20", "50", "100"],
      pageSize: 10,
      current: 1,
      templateItemId: "",
      Channels: [],
      total: 50,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      }
    };
  },
  computed: {
    postTypeList: function postTypeList() {
      return this.$store.state.enums.postTypeLst;
    },
    postList: function postList() {
      return this.postTypeList.map(function (item) {
        return {
          value: item.title,
          key: item.value
        };
      });
    }
  },
  watch: {
    textContent: function textContent(e) {
      if (e) {
        this.errorState = false;
      }
    },
    show: function show(val) {
      if (val) {
        this.selectedListTemp = JSON.parse(JSON.stringify(this.selectedList));
        this.treeData = [];
        this.current = 1;
        this.postTypeValue = 3;
        this.mode = "1";
        this.importDataLength = this.selectedList.length;
        // this.onPageChange(1);
        this.spinning = false;
        if (this.status === 6) {
          this.initTemplate();
        } else {
          this.getSelectDepTree();
        }
      }
    },
    month: function month(val) {}
  },
  created: function created() {
    this.getAllList();
  },
  methods: {
    getAllList: function getAllList() {
      var _this = this;
      this.loading = true;
      _getAllList().then(function (res) {
        // this.getPrePersonnelList();
        _this.Channels = res.data;
      });
    },
    templateClick: function templateClick(e, index) {
      var _this2 = this;
      this.fixData.forEach(function (item2, index2) {
        if (index2 === index) {
          _this2.fixData[index2].hover = true;
          _this2.templateItemId = _this2.fixData[index2].id;
          _this2.pageSize = 10;
          _this2.current = 1;
          _this2.getSelectDepTree();
        } else {
          _this2.fixData[index2].hover = false;
        }
      });
      this.fixData = JSON.parse(JSON.stringify(this.fixData));
      console.log(this.fixData);
    },
    initTemplate: function initTemplate() {
      var _this3 = this;
      selectByCompanyId({
        companyId: this.$store.state.hr.companyId
      }).then(function (res) {
        _this3.fixData = res.data;
        _this3.fixData[0].hover = true;
        _this3.templateItemId = _this3.fixData[0].id;
        _this3.pageSize = 10;
        _this3.current = 1;
        _this3.getSelectDepTree();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    rangeChange: function rangeChange(e) {
      this.form.joinDateStart = e[0];
      this.form.joinDateEnd = e[1];
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    deleteSeniorItem: function deleteSeniorItem(e) {
      var _this4 = this;
      this.seniorList.forEach(function (item, index) {
        if (item.id === e.id) {
          _this4.seniorList.splice(index, 1);
          _this4.selectedRowKeys.forEach(function (item2, index2) {
            if (item2 === e.id) {
              _this4.selectedRowKeys.splice(index2, 1);
            }
          });
        }
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys, e) {
      var _this5 = this;
      this.selectedRowKeys = selectedRowKeys;
      this.dataList.forEach(function (item) {
        selectedRowKeys.forEach(function (item2) {
          if (item.id === item2) {
            _this5.seniorList.push(item);
            _this5.seniorList = _this5.unique(_this5.seniorList);
          }
        });
      });
      // this.seniorList.forEach((item, index) => {
      //   if (selectedRowKeys.indexOf(item.id) === -1) {
      //     this.seniorList.splice(index, 1);
      //   }
      // });
      this.seniorList.forEach(function (item, index) {
        if (_this5.selectedRowKeys.indexOf(item.id) === -1) {
          item.isSelectedItem = 2;
        }
      });
      var arr = [];
      this.seniorList.forEach(function (item, index) {
        if (!item.isSelectedItem) {
          arr.push(item);
        }
      });
      this.seniorList = JSON.parse(JSON.stringify(arr));
    },
    unique: function unique(arr) {
      var res = new Map();
      return arr.filter(function (arr) {
        return !res.has(arr.id) && res.set(arr.id, 1);
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      // this.checkStatue = 0;
      this.getSeniorList();
    },
    getSeniorListCheck: function getSeniorListCheck() {
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.checkStatue = 1;
      this.getSeniorList();
    },
    getSeniorList: function getSeniorList() {
      var _this6 = this;
      var params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        companyId: this.$store.state.hr.companyId
      };
      if (this.checkStatue === 1) {
        params = {
          // onlyCurrentDep: true,
          // onlyUser: true,
          salaryRuleId: this.form.rule ? this.form.rule : null,
          serviceFeeRuleId: this.form.serviceFeeRuleId ? this.form.serviceFeeRuleId : null,
          postType: this.form.postType ? this.form.postType : null,
          tag: this.form.tag ? this.form.tag : null,
          nameOrPhone: this.form.name ? this.form.name : null,
          joinDateStart: this.form.joinDateStart ? moment(this.form.joinDateStart).format("YYYY-MM-DD ") : null,
          joinDateEnd: this.form.joinDateEnd ? moment(this.form.joinDateEnd).format("YYYY-MM-DD ") : null,
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          companyId: this.$store.state.hr.companyId,
          channelId: this.form.channelId ? this.form.channelId : null
        };
      }
      if (this.status === 2 || this.status === 3) {
        getAllEmp(params).then(function (res) {
          _this6.spinning = false;
          if (res.data.entities === null) {
            _this6.total = 0;
          } else {
            _this6.pagination.total = res.data && res.data.entityCount;
            _this6.dataList = res.data.entities;
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      } else if (this.status === 1) {
        getAllEmp2(_objectSpread(_objectSpread({}, params), {}, {
          monthly: this.monthly,
          yearly: this.yearly
        })).then(function (res) {
          if (res.data.entities === null) {
            _this6.total = 0;
          } else {
            _this6.pagination.total = res.data && res.data.entityCount;
            _this6.dataList = res.data.entities;
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      } else if (this.status === 4) {
        getNotList(params).then(function (res) {
          _this6.spinning = false;
          if (res.data.entities === null) {
            _this6.total = 0;
          } else {
            _this6.pagination.total = res.data && res.data.entityCount;
            _this6.dataList = res.data.entities;
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      } else if (this.status === 6) {
        params.name = params.nameOrPhone;
        params.nameOrPhone = undefined;
        getWageEmp2(params).then(function (res) {
          _this6.spinning = false;
          if (res.data.entities === null) {
            _this6.total = 0;
          } else {
            _this6.pagination.total = res.data && res.data.entityCount;
            _this6.dataList = res.data.entities;
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      } else if (this.status === 7) {
        selectOutsourceSalary(params).then(function (res) {
          _this6.spinning = false;
          if (res.data.entities === null) {
            _this6.total = 0;
          } else {
            _this6.pagination.total = res.data && res.data.entityCount;
            _this6.dataList = res.data.entities;
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      }
    },
    clearForm: function clearForm() {
      this.form = {};
    },
    seniorCancel: function seniorCancel() {
      this.seniorVisible = false;
      this.selectedRowKeys = [];
    },
    showSenior: function showSenior() {
      var _this7 = this;
      this.checkStatue = 0;
      this.$emit("update:show", false);
      this.seniorVisible = true;
      this.clearForm();
      this.getSeniorList();
      this.getListByCompanyId();
      this.update();
      // setTimeout(function() {
      if (this.selectedListTemp.length > 0) {
        this.seniorList = JSON.parse(JSON.stringify(this.selectedListTemp));
        this.selectedListTemp.forEach(function (item, index) {
          _this7.selectedRowKeys.push(item.id);
        });
      } else {
        this.selectedRowKeys = [];
        this.seniorList = [];
      }
      // }, 1000);
    },
    showCopy: function showCopy() {
      this.$emit("update:show", false);
      this.copyVisible = true;
      if (this.selectedListTemp.length > 0) {
        this.chosenList = JSON.parse(JSON.stringify(this.selectedListTemp));
      } else {
        this.chosenList = [];
      }
    },
    deleteCopyItem: function deleteCopyItem(e) {
      var _this8 = this;
      this.chosenList.forEach(function (item, index) {
        if (item.id === e.id) {
          _this8.chosenList.splice(index, 1);
        }
      });
    },
    reset: function reset() {
      this.textContent = "";
      this.errorState = false;
    },
    getEmpListByIdCards: function getEmpListByIdCards() {
      var _this9 = this;
      if (this.textContent !== "") {
        var arr = [];
        arr = this.textContent.split(/[\W]/);
        if (this.status === 4 || this.status === 1) {
          empListByIdCards2(this.$store.state.hr.companyId, arr).then(function (res) {
            if (_this9.selectedListTemp.length > 0) {
              _this9.chosenList = res.data.empInfoVOList.concat(_this9.selectedListTemp);
              _this9.chosenList = _this9.unique(_this9.chosenList);
            } else {
              _this9.chosenList = res.data.empInfoVOList;
            }
            _this9.textContent = res.data.idCardList.join(",");
            if (res.data.idCardList.length > 0) {
              _this9.$nextTick(function () {
                _this9.errorState = true;
              });
            }
          });
        } else if (this.status === 5) {
          empListByIdCardsRYK({
            idCardsList: arr
          }).then(function (res) {
            if (_this9.selectedListTemp.length > 0) {
              _this9.chosenList = _this9.chosenList.concat(res.data.transferAccountsPeopleVOList.concat(_this9.selectedListTemp));
              _this9.chosenList = _this9.unique(_this9.chosenList);
            } else {
              _this9.chosenList = _this9.chosenList.concat(res.data.transferAccountsPeopleVOList);
            }
            _this9.textContent = res.data.idCardList.join(",");
            if (res.data.idCardList.length > 0) {
              _this9.$nextTick(function () {
                _this9.errorState = true;
              });
            }
          });
        } else if (this.status === 6) {
          empListByIdCardsTEM({
            companyId: this.$store.state.hr.companyId,
            idCardList: arr
          }).then(function (res) {
            // if (this.selectedListTemp.length > 0) {
            _this9.chosenList = res.data.empInfoListVOList.concat(_this9.selectedListTemp);
            _this9.chosenList = _this9.unique(_this9.chosenList);
            // } else {
            //   this.chosenList = res.data.empInfoVOList;
            // }
            _this9.textContent = res.data.idCardList.join(",");
            if (res.data.idCardList.length > 0) {
              _this9.$nextTick(function () {
                _this9.errorState = true;
              });
            }
          });
        } else if (this.status === 7) {
          getmpByCardAndSalaryType({
            companyId: this.$store.state.hr.companyId,
            idCardList: arr,
            salaryType: 2
          }).then(function (res) {
            // if (this.selectedListTemp.length > 0) {
            if (res.data.empInfoVOList && res.data.empInfoVOList.length) {
              _this9.chosenList = res.data.empInfoVOList.concat(_this9.selectedListTemp);
              _this9.chosenList = _this9.unique(_this9.chosenList);
            }

            // } else {
            //   this.chosenList = res.data.empInfoVOList;
            // }
            _this9.textContent = res.data.idCardList.join(",");
            if (res.data.idCardList.length > 0) {
              _this9.$nextTick(function () {
                _this9.errorState = true;
              });
            }
          });
        } else {
          empListByIdCards(this.$store.state.hr.companyId, arr).then(function (res) {
            if (_this9.selectedListTemp.length > 0) {
              _this9.chosenList = res.data.empInfoVOList.concat(_this9.selectedListTemp);
              _this9.chosenList = _this9.unique(_this9.chosenList);
            } else {
              _this9.chosenList = res.data.empInfoVOList;
            }
            _this9.textContent = res.data.idCardList.join(",");
            if (res.data.idCardList.length > 0) {
              _this9.$nextTick(function () {
                _this9.errorState = true;
              });
            }
          });
        }
      }
    },
    // 分页变化
    update: function update() {
      var _this10 = this;
      this.rulusList = [];
      this.serviceFeeRuleList = [];
      getSalaryCalculateRuleGroups({
        companyId: this.$store.state.hr.companyId
      }).then(function (res) {
        if (res.data.length > 0) {
          res.data.map(function (item) {
            if (item.ruleType === 1) {
              _this10.rulusList.push(item);
            }
            if (item.ruleType === 2) {
              _this10.serviceFeeRuleList.push(item);
            }
          });
        }
      });
    },
    onPageChange: function onPageChange(pageNumber) {
      this.current = pageNumber;
      this.getSelectDepTree();
    },
    postTypeClick: function postTypeClick(value) {
      this.postTypeValue = value;
      this.isAllSelected = false;
      this.current = 1;
      this.getSelectDepTree();
    },
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = 1;
      this.getSelectDepTree();
    },
    choseAll: function choseAll(data) {
      this.isAllSelected = !this.isAllSelected;
      var that = this;
      this.spinning = true;
      for (var j = 0, len = data.length; j < len; j++) {
        data[j].isSelected = that.isAllSelected;
        that.ergodicAll(data[j]);
      }
      setTimeout(function () {
        that.spinning = false;
      }, "10");
    },
    chose: function chose(item, data) {
      item.isSelected = !item.isSelected;
      this.onChange(item);
    },
    onChangeTab: function onChangeTab(e) {
      this.isAllSelected = false;
      this.current = 1;
      this.mode = e;
      this.getSelectDepTree();
    },
    onChange: function onChange(item) {
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.$nextTick(function () {
          that.ergodic(item);
        });
        that.spinning = false;
      }, "10");
    },
    getListByCompanyId: function getListByCompanyId() {
      var _this11 = this;
      listByCompanyIdGet({
        companyId: this.$store.state.hr.companyId
      }).then(function (_ref) {
        var data = _ref.data;
        _this11.labelList = data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    ergodic: function ergodic(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除

          this.treeData.some(function (e) {
            if (e.id === item.id) {
              e.isSelected = false;
              return true;
            }
          });
          var allState = true;
          this.treeData.forEach(function (item, index) {
            if (item.isSelected === false) {
              allState = false;
            }
          });
          this.isAllSelected = allState;
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
          var count = this.treeData.length;
          var allSelect = true;
          for (var o = 0; o < count; o++) {
            if (!this.treeData[o].isSelected) {
              allSelect = false;
              break;
            }
          }
          this.isAllSelected = allSelect;
        }
      }
    },
    ergodicAll: function ergodicAll(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
        }
      }
    },
    // 获取部门树
    getSelectDepTree: function getSelectDepTree(query) {
      var _this12 = this;
      if (query) {
        this.current = 1;
      }
      var params = {
        // onlyCurrentDep: true,
        // onlyUser: true,
        pageSize: this.pageSize,
        pageNo: this.current,
        companyId: this.$store.state.hr.companyId
      };
      if (this.nameOrPhone) {
        params["nameOrPhone"] = this.nameOrPhone;
      }
      this.spinning = true;
      if (this.status === 2 || this.status === 3) {
        getAllEmp(params).then(function (res) {
          _this12.spinning = false;
          if (res.data.entities === null) {
            _this12.$message.success("暂无数据");
            _this12.treeData = [];
            _this12.total = 0;
            _this12.isAllSelected = false;
          } else {
            _this12.treeData = _this12.formatCompanyData(res.data.entities);
            _this12.total = res.data.entityCount;
            var count = res.data.entities.length;
            _this12.pageSize = res.data.pageSize;
            _this12.current = res.data.pageNo;
            var allSelect = true;
            _this12.spinning = false;
            if (count !== 0) {
              for (var i = 0; i < count; i++) {
                if (!_this12.treeData[i].isSelected) {
                  allSelect = false;
                  break;
                }
              }
              _this12.isAllSelected = allSelect;
            } else {
              _this12.isAllSelected = false;
            }
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
          _this12.spinning = false;
        });
      } else if (this.status === 4) {
        getNotList(params).then(function (res) {
          _this12.spinning = false;
          if (res.data.entities === null) {
            _this12.$message.success("暂无数据");
            _this12.treeData = [];
            _this12.total = 0;
            _this12.isAllSelected = false;
          } else {
            _this12.treeData = _this12.formatCompanyData(res.data.entities);
            _this12.total = res.data.entityCount;
            var count = res.data.entities.length;
            _this12.pageSize = res.data.pageSize;
            _this12.current = res.data.pageNo;
            var allSelect = true;
            _this12.spinning = false;
            if (count !== 0) {
              for (var i = 0; i < count; i++) {
                if (!_this12.treeData[i].isSelected) {
                  allSelect = false;
                  break;
                }
              }
              _this12.isAllSelected = allSelect;
            } else {
              _this12.isAllSelected = false;
            }
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
          _this12.spinning = false;
        });
      } else if (this.status === 1) {
        getAllEmp2(_objectSpread(_objectSpread({}, params), {}, {
          monthly: this.monthly,
          yearly: this.yearly
        })).then(function (res) {
          _this12.spinning = false;
          if (res.data.entities === null) {
            _this12.$message.success("暂无数据");
            _this12.treeData = [];
            _this12.total = 0;
            _this12.isAllSelected = false;
          } else {
            _this12.treeData = _this12.formatCompanyData(res.data.entities);
            _this12.total = res.data.entityCount;
            var count = res.data.entities.length;
            _this12.pageSize = res.data.pageSize;
            _this12.current = res.data.pageNo;
            var allSelect = true;
            _this12.spinning = false;
            if (count !== 0) {
              for (var i = 0; i < count; i++) {
                if (!_this12.treeData[i].isSelected) {
                  allSelect = false;
                  break;
                }
              }
              _this12.isAllSelected = allSelect;
            } else {
              _this12.isAllSelected = false;
            }
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
          _this12.spinning = false;
        });
      } else if (this.status === 5) {
        params.companyId = undefined;
        getPeopleList({
          nameOrPhoneOrIdCard: this.nameOrPhone,
          pageSize: this.pageSize,
          pageNo: this.current
        }).then(function (res) {
          _this12.spinning = false;
          if (res.data.entities === null) {
            _this12.$message.success("暂无数据");
            _this12.treeData = [];
            _this12.total = 0;
            _this12.isAllSelected = false;
          } else {
            _this12.treeData = _this12.formatCompanyData(res.data.entities);
            _this12.total = res.data.entityCount;
            var count = res.data.entities.length;
            _this12.pageSize = res.data.pageSize;
            _this12.current = res.data.pageNo;
            var allSelect = true;
            _this12.spinning = false;
            if (count !== 0) {
              for (var i = 0; i < count; i++) {
                if (!_this12.treeData[i].isSelected) {
                  allSelect = false;
                  break;
                }
              }
              _this12.isAllSelected = allSelect;
            } else {
              _this12.isAllSelected = false;
            }
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
          _this12.spinning = false;
        });
      } else if (this.status === 6) {
        params.name = params.nameOrPhone;
        params.nameOrPhone = undefined;
        getWageEmp(_objectSpread(_objectSpread({}, params), {}, {
          salaryTemplateId: this.templateItemId
        })).then(function (res) {
          _this12.spinning = false;
          if (res.data.entities === null) {
            _this12.$message.success("暂无数据");
            _this12.treeData = [];
            _this12.total = 0;
            _this12.isAllSelected = false;
          } else {
            _this12.treeData = _this12.formatCompanyData(res.data.entities);
            _this12.total = res.data.entityCount;
            var count = res.data.entities.length;
            _this12.pageSize = res.data.pageSize;
            _this12.current = res.data.pageNo;
            var allSelect = true;
            _this12.spinning = false;
            if (count !== 0) {
              for (var i = 0; i < count; i++) {
                if (!_this12.treeData[i].isSelected) {
                  allSelect = false;
                  break;
                }
              }
              _this12.isAllSelected = allSelect;
            } else {
              _this12.isAllSelected = false;
            }
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
          _this12.spinning = false;
        });
      } else if (this.status === 7) {
        selectOutsourceSalary(params).then(function (res) {
          _this12.spinning = false;
          if (res.data.entities === null) {
            _this12.$message.success("暂无数据");
            _this12.treeData = [];
            _this12.total = 0;
            _this12.isAllSelected = false;
          } else {
            _this12.treeData = _this12.formatCompanyData(res.data.entities);
            _this12.total = res.data.entityCount;
            var count = res.data.entities.length;
            _this12.pageSize = res.data.pageSize;
            _this12.current = res.data.pageNo;
            var allSelect = true;
            _this12.spinning = false;
            if (count !== 0) {
              for (var i = 0; i < count; i++) {
                if (!_this12.treeData[i].isSelected) {
                  allSelect = false;
                  break;
                }
              }
              _this12.isAllSelected = allSelect;
            } else {
              _this12.isAllSelected = false;
            }
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
          _this12.spinning = false;
        });
      }
    },
    formatCompanyData: function formatCompanyData(company) {
      var parent = [];
      for (var key = 0; key < company.length; key++) {
        var selected = false;
        this.selectedListTemp.forEach(function (item, index) {
          if (item.id === company[key].id) {
            selected = true;
          }
        });
        var emp = {
          id: company[key].id,
          name: company[key].name,
          phone: company[key].phone,
          idCard: company[key].idCard,
          depName: company[key].depName,
          positionName: company[key].positionName,
          isSelected: selected
        };
        // this.selectedListTemp = this.selectedList;
        this.selectedListTemp.forEach(function (item, index) {
          item.isSelected = true;
        });
        parent.push(emp);
      }
      this.importDataLength = 0; // 清空传入的默认值
      return parent;
    },
    searchPeople: function searchPeople(query) {
      this.pageSize = 10;
      this.current = 1;
      this.nameOrPhone = query;
      this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
        return item.id;
      });
      this.getSelectDepTree(query);
    },
    changePeople: function changePeople(e) {
      if (e.target.value === "") {
        this.pageSize = 10;
        this.current = 1;
        this.getSelectDepTree();
        this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
          return item.id;
        });
      }
    },
    deleteItem: function deleteItem(item, index) {
      this.spinning = true;
      item.isSelected = false;
      var that = this;
      setTimeout(function () {
        that.$nextTick(function () {
          that.ergodic(item);
        });
        that.spinning = false;
      }, 1000);
    },
    copyOk: function copyOk() {
      var _this13 = this;
      if (this.chosenList.length === 0) {
        this.$message.success("请添加选择人员");
        return;
      } else if (this.status === 6) {
        var arr = [];
        this.chosenList.forEach(function (item) {
          arr.push(item.id);
        });
        isSameTemplate({
          empIds: arr
        }).then(function (_ref2) {
          var success = _ref2.success;
          if (success) {
            _this13.$emit("update:selectedList", _this13.chosenList);
            _this13.$emit("update:selectThree", _this13.chosenList.slice(0, 3).map(function (item) {
              return item.name;
            }).join(","));
            _this13.copyVisible = false;
            _this13.$emit("change", _this13.chosenList);
          }
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        this.$emit("update:selectedList", this.chosenList);
        this.$emit("update:selectThree", this.chosenList.slice(0, 3).map(function (item) {
          return item.name;
        }).join(","));
        this.copyVisible = false;
        this.$emit("change", this.chosenList);
      }
    },
    seniorOk: function seniorOk() {
      var _this14 = this;
      if (this.seniorList.length === 0) {
        this.$message.success("请添加选择人员");
        return;
      } else if (this.status === 6) {
        var arr = [];
        this.seniorList.forEach(function (item) {
          arr.push(item.id);
        });
        isSameTemplate({
          empIds: arr
        }).then(function (_ref3) {
          var success = _ref3.success;
          if (success) {
            _this14.$emit("update:selectedList", _this14.seniorList);
            _this14.$emit("update:selectThree", _this14.seniorList.slice(0, 3).map(function (item) {
              return item.name;
            }).join(","));
            _this14.seniorVisible = false;
            _this14.$emit("change", _this14.seniorList);
          }
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        this.$emit("update:selectedList", this.seniorList);
        this.$emit("update:selectThree", this.seniorList.slice(0, 3).map(function (item) {
          return item.name;
        }).join(","));
        this.seniorVisible = false;
        this.$emit("change", this.seniorList);
      }
    },
    ok: function ok() {
      var _this15 = this;
      if (this.selectedListTemp.length === 0) {
        this.$message.success("请选择人员");
        return;
      } else if (this.status === 6) {
        var arr = [];
        this.selectedListTemp.forEach(function (item) {
          arr.push(item.id);
        });
        isSameTemplate({
          empIds: arr
        }).then(function (_ref4) {
          var success = _ref4.success;
          if (success) {
            _this15.$emit("update:selectedList", _this15.selectedListTemp);
            _this15.$emit("update:selectThree", _this15.selectedListTemp.slice(0, 3).map(function (item) {
              return item.name;
            }).join(","));
            _this15.$emit("update:show", false);
            _this15.$emit("change", _this15.selectedListTemp);
          }
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        this.$emit("update:selectedList", this.selectedListTemp);
        this.$emit("update:selectThree", this.selectedListTemp.slice(0, 3).map(function (item) {
          return item.name;
        }).join(","));
        this.$emit("update:show", false);
        this.$emit("change", this.selectedListTemp);
      }
    },
    cancel: function cancel() {
      this.$emit("update:show", false);
    }
  }
};