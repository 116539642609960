export default {
  fields: {
    createUserId: {
      model: "createUserId",
      type: "input",
      wrapper: {
        label: "createUserId"
      },
      customize: {
        placeholder: "请输入createUserId",
        allowClear: true
      },
      decorator: {
        rules: [{
          required: false,
          message: "请输入createUserId"
        }]
      },
      extend: {}
    },
    enable: {
      model: "enable",
      type: "switch",
      wrapper: {
        label: "是否启用"
      },
      customize: {
        placeholder: "请输入是否启用",
        allowClear: true
      },
      decorator: {
        valuePropName: "checked",
        initialValue: true,
        rules: [{
          required: true,
          message: "请输入gmtCreate"
        }]
      }
    },
    gmtCreate: {
      model: "gmtCreate",
      type: "input",
      wrapper: {
        label: "gmtCreate"
      },
      customize: {
        placeholder: "请输入gmtCreate",
        allowClear: true
      },
      decorator: {
        rules: [{
          required: false,
          message: "请输入gmtCreate"
        }]
      },
      extend: {}
    },
    gmtModified: {
      model: "gmtModified",
      type: "input",
      wrapper: {
        label: "gmtModified"
      },
      customize: {
        placeholder: "请输入gmtModified",
        allowClear: true
      },
      decorator: {
        rules: [{
          required: false,
          message: "请输入gmtModified"
        }]
      },
      extend: {}
    },
    id: {
      model: "id",
      type: "input",
      wrapper: {
        label: "id"
      },
      customize: {
        placeholder: "请输入id",
        allowClear: true
      },
      decorator: {
        rules: [{
          required: false,
          message: "请输入id"
        }]
      },
      extend: {}
    },
    modifiedUserId: {
      model: "modifiedUserId",
      type: "input",
      wrapper: {
        label: "modifiedUserId"
      },
      customize: {
        placeholder: "请输入modifiedUserId",
        allowClear: true
      },
      decorator: {
        rules: [{
          required: false,
          message: "请输入modifiedUserId"
        }]
      },
      extend: {}
    },
    quotaRemain: {
      model: "quotaRemain",
      type: "number",
      wrapper: {
        label: "剩余额度"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入剩余额度"
      },
      decorator: {
        rules: [{
          required: true,
          message: "请输入剩余额度"
        }]
      },
      extend: {}
    },
    siteId: {
      model: "siteId",
      type: "select",
      wrapper: {
        label: "租户"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择租户",
        options: [{
          value: 1,
          label: "选项1"
        }, {
          value: 2,
          label: "选项2"
        }]
      },
      decorator: {
        rules: [{
          required: true,
          message: "请选择租户"
        }]
      },
      extend: {
        dynamic: true,
        // 开启动态数据
        dynamicKey: "siteList",
        // 指定动态数据的 key
        dynamicUrl: "/admin/api/v1/sites/listAll",
        // 动态数据的请求地址
        dynamicTail: "data",
        // 动态数据返回值的链式调用路径
        dynamicValueKey: "id",
        // 动态数据 value 的 key
        dynamicLabelKey: "name" // 动态数据 label 的 key
      }
    },

    siteName: {
      model: "siteName",
      type: "input",
      wrapper: {
        label: "租户名"
      },
      customize: {
        placeholder: "请输入租户名",
        allowClear: true
      },
      decorator: {
        rules: [{
          required: true,
          message: "请输入租户名"
        }]
      },
      extend: {}
    },
    totalCount: {
      model: "totalCount",
      type: "input",
      wrapper: {
        label: "累计发送"
      },
      customize: {
        placeholder: "请输入累计发送",
        allowClear: true
      },
      decorator: {
        rules: [{
          required: true,
          message: "请输入累计发送"
        }]
      },
      extend: {}
    },
    type: {
      model: "type",
      type: "select",
      wrapper: {
        label: "计费类型"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择计费类型",
        options: [{
          value: 1,
          label: "预付费"
        }, {
          value: 2,
          label: "后付费"
        }]
      },
      decorator: {
        rules: [{
          required: true,
          message: "请选择计费类型"
        }]
      },
      extend: {}
    }
  },
  validators: {},
  config: {
    create: {
      fieldKeys: {
        siteId: {},
        quotaRemain: {},
        enable: {},
        totalCount: {},
        type: {}
      },
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    table: {
      fields: [{
        model: "siteName",
        title: "租户名"
      }, {
        model: "totalCount",
        title: "累计发送"
      }, {
        model: "quotaRemain",
        title: "剩余额度"
      }, {
        model: "enable",
        title: "是否启用"
      }],
      customize: {
        labelCol: {},
        wrapperCol: {}
      },
      extend: {
        batch: false,
        action: {
          width: 250
        }
      }
    }
  }
};