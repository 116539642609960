var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("xq-tabs", {
    staticClass: "bgc-white ph24 pv12"
  }, [_c("a-tab-pane", {
    key: "electronic",
    attrs: {
      tab: "电子合同记录"
    }
  }, [_c("ListElectronic")], 1), _c("a-tab-pane", {
    key: "paper",
    attrs: {
      tab: "纸质合同记录"
    }
  }, [_c("ListPaper")], 1), _c("a-tab-pane", {
    key: "all",
    attrs: {
      tab: "全部合同记录"
    }
  }, [_c("ListAll")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };