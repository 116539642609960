export default {
  fields: {
    postName: {
      model: "postName"
    },
    companyUnitName: {
      model: "companyUnitName"
    },
    cityName: {
      model: "cityName"
    },
    number: {
      model: "number"
    },
    sharedCount: {
      model: "sharedCount"
    },
    personSize: {
      model: "personSize"
    },
    recruitedCount: {
      model: "recruitedCount"
    },
    gmtCreate: {
      model: "gmtCreate"
    },
    empCompanyIds: {
      model: "empCompanyIds"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "postName",
        title: "职位名称",
        fixed: "left"
      }, {
        model: "cityName",
        title: "地点"
      }, {
        model: "companyUnitName",
        title: "招工企业"
      }, {
        model: "number",
        title: "招聘人数"
      }, {
        model: "sharedCount",
        title: "已推荐人数"
      }, {
        model: "recruitedCount",
        title: "推荐成功入职"
      }, {
        model: "gmtCreate",
        title: "创建时间"
      }, {
        model: "empCompanyIds",
        title: "内推范围",
        width: 180
      }],
      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: {
          width: 200
        }
      }
    }
  }
};