import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  path: "/contractAdmin",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/adminNew"));
    });
  },
  redirect: "ele-contract/index",
  meta: {
    title: "电子合同",
    icon: "yuangongguanli"
  },
  children: [{
    path: "settlementRules",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/settlementRules"));
      });
    }
  }, {
    path: "settlementRules/wagesRules",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/settlementRules/wagesRules"));
      });
    }
  }, {
    path: "settlementRules/laborRules",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/settlementRules/laborRules"));
      });
    }
  }, {
    path: "holiday",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/holiday"));
      });
    }
  }, {
    path: "elecontract",
    name: "elecontractAdmin",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/ele-contract/index"));
      });
    },
    meta: {
      title: "签署记录",
      icon: "tree"
    }
  }, {
    path: "type",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/type-manage/manage"));
      });
    },
    meta: {
      title: "合同类型管理",
      icon: "tree"
    }
  }, {
    path: "group",
    // 合同分组
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/type-manage/manage/group"));
      });
    }
  }, {
    path: "type/issued",
    // 合同分组
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/type-manage/manage/group/issued"));
      });
    }
  }, {
    path: "type/update/:categoryId/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/type-manage/edit.vue"));
      });
    }
  }, {
    path: "type/seal/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/type-manage/editSealNew"));
      });
    }
  }, {
    path: "stamp",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/stamp-manage/index"));
      });
    },
    meta: {
      title: "印章管理",
      icon: "tree"
    }
  }, {
    path: "stamp/create",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/stamp-manage/edit"));
      });
    },
    meta: {
      title: "印章创建"
    }
  }, {
    path: "stamp/update/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/stamp-manage/edit"));
      });
    },
    meta: {
      title: "印章编辑"
    }
  }, {
    path: "stamp/verify/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/stamp-manage/verify"));
      });
    },
    meta: {
      title: "印章认证"
    }
  }, {
    hidden: true,
    path: "contract-set",
    name: "contract-set",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/ele-contract/setting"));
      });
    },
    meta: {
      title: "设置"
    }
  }, {
    hidden: true,
    path: "setting",
    name: "settingAdmin",
    // component: () => import("@/views/contract/type-manage/setting"),
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/type-manage/settingNew"));
      });
    },
    meta: {
      title: "合同设置"
    }
  }, {
    hidden: true,
    path: "type/initiateSign",
    name: "initiateSignAdmin",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/type-manage/initiateSign"));
      });
    },
    meta: {
      title: "发起合同"
    }
  }, {
    hidden: true,
    path: "type/previewDetail",
    name: "previewDetailAdmin",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/type-manage/previewDetail"));
      });
    },
    meta: {
      title: "预览"
    }
  }, {
    path: "template",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/template"));
      });
    }
  }, {
    path: "template/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/template/add"));
      });
    }
  }, {
    path: "template/edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/template/edit"));
      });
    }
  }, {
    path: "gongzibiaomuban",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/xinzi/gongzibiaomuban"));
      });
    }
  }, {
    path: "gongzibiaomuban/edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/xinzi/gongzibiaomuban/edit"));
      });
    }
  }, {
    path: "ruzhi",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/entryTemplateSet4"));
      });
    },
    meta: {
      title: "入职模板",
      icon: "dashboard"
    }
  }, {
    path: "ruzhi/newTemplate",
    meta: {
      title: "新建入职模板设置"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/newTemplate"));
      });
    }
  }, {
    path: "ruzhi/template_ydj",
    meta: {
      title: "预登记模板设置"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/newTemplate"));
      });
    }
  }, {
    path: "shebaoMethod",
    name: "shebaoMethodAdmin",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoMethod/index"));
      });
    },
    meta: {
      title: "参保方案",
      icon: "tree"
    }
  }, {
    path: "shebaoMethod/addMethod",
    name: "addMethodAdmin",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoMethod/addMethod"));
      });
    },
    meta: {
      title: "添加参保方案"
    }
  }, {
    path: "shebaoMethod/methodDetail",
    name: "methodDetailAdmin",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoMethod/methodDetail"));
      });
    },
    meta: {
      title: "参保详情"
    }
  }, {
    path: "shebaoMethod/editMethod",
    name: "editMethodAdmin",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoMethod/editMethod"));
      });
    }
  }]
};