var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("header", {
    staticClass: "l_title"
  }, [_c("span", {
    staticStyle: {
      cursor: "pointer",
      "font-size": "16px"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c("a-icon", {
    staticStyle: {
      margin: "30px 10px 0 20px"
    },
    attrs: {
      type: "left"
    }
  }), _vm._v("返回 ")], 1), _c("div", {
    staticClass: "flex space_center"
  }, [_vm._v(_vm._s(this.$route.params.id === "0" ? "新增打卡规则" : "编辑打卡规则"))])]), !_vm.addFreeCard ? _c("a-layout-content", {
    staticClass: "editor_content"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("a-form", {
    staticClass: "lab_l",
    attrs: {
      form: _vm.form
    }
  }, [_c("a-form-item", {
    staticClass: "requireLeft",
    attrs: {
      label: "规则名称",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["planName", {
        rules: [{
          required: true,
          message: "必填"
        }],
        initialValue: _vm.postRules.planName
      }],
      expression: "[\n              'planName',\n              {\n                rules: [{ required: true, message: '必填' }],\n                initialValue: postRules.planName\n              }\n            ]"
    }],
    staticStyle: {
      width: "225px"
    },
    attrs: {
      placeholder: "请输入打卡规则名称"
    }
  })], 1), _c("a-form-item", {
    staticClass: "requireLeft",
    attrs: {
      label: "规则类型",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      },
      required: true
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.postRules.planType,
      callback: function callback($$v) {
        _vm.$set(_vm.postRules, "planType", $$v);
      },
      expression: "postRules.planType"
    }
  }, [_c("a-row", [_c("a-radio", {
    staticClass: "w110",
    attrs: {
      value: 1
    }
  }, [_vm._v("固定时间上下班 ")]), _c("span", {
    staticClass: "sub_tit",
    staticStyle: {
      "padding-left": "10px"
    }
  }, [_vm._v(" 所有人按照相同时间打卡")])], 1)], 1)], 1)], 1)], 1), _c("div", {
    staticClass: "content"
  }, [_c("a-form", {
    staticClass: "lab_l"
  }, [_c("a-form-item", {
    staticClass: "requireLeft",
    attrs: {
      label: "打卡人员",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      },
      required: true
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("a-col", [_c("a-button", {
    staticClass: "tc-theme pdl",
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.addUsers
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-square"
    }
  }), _vm._v("添加 ")], 1)], 1), _vm._l(_vm.postRules.staffList, function (item, index) {
    return _c("a-col", {
      key: item.empInfoId
    }, [_c("a-tag", {
      attrs: {
        color: "green"
      }
    }, [_vm._v("   " + _vm._s(item.empInfoName) + " "), _c("a-icon", {
      attrs: {
        type: "close"
      },
      on: {
        click: function click($event) {
          return _vm.deleteUser(index, _vm.postRules.staffList);
        }
      }
    })], 1)], 1);
  })], 2)], 1)], 1)], 1), _c("div", {
    staticClass: "content"
  }, [_c("a-form", {
    staticClass: "lab_l"
  }, [_c("a-form-item", {
    staticClass: "requireLeft",
    attrs: {
      label: "打卡地点",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      },
      required: true
    }
  }), _c("a-form-item", {
    attrs: {
      label: "位置",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 21
      }
    }
  }, [_c("a-button", {
    staticClass: "tc-theme",
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.addCardPosition
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-square"
    }
  }), _vm._v("添加")], 1), _c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("可添加多个打卡地点,并设置打卡范围 ")]), _vm._l(_vm.postRules.locationList, function (item, i) {
    return _c("a-row", {
      key: i,
      staticClass: "tab_item",
      staticStyle: {
        width: "100%"
      },
      attrs: {
        type: "flex",
        align: "middle"
      }
    }, [_c("a-col", {
      staticClass: "mg_l20"
    }, [_vm._v(_vm._s(item.positionName + " " + item.distance + "米"))]), _c("a-col", {
      staticClass: "mg_l_o"
    }, [_c("span", {
      staticClass: "tc-theme cursor",
      on: {
        click: function click($event) {
          return _vm.deletePos(i);
        }
      }
    }, [_vm._v(" 删除 ")])])], 1);
  })], 2), _c("a-form-item", {
    staticClass: "requireLeft",
    attrs: {
      label: "打卡时间",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      },
      required: true
    }
  }, [_vm.postRules.planType === 1 ? [!_vm.postRules.punchTimeList.length ? _c("a-button", {
    staticClass: "tc-theme pdl",
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.addCardTime
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-square"
    }
  }), _vm._v("添加 ")], 1) : _vm._e(), _vm._l(_vm.postRules.punchTimeList, function (item, i) {
    return _c("a-row", {
      key: i,
      staticClass: "tab_item",
      staticStyle: {
        border: "0"
      },
      attrs: {
        type: "flex",
        justify: "space-between",
        align: "middle"
      }
    }, [_c("a-col", {
      staticClass: "pl20"
    }, _vm._l(item.workWeekList, function (w, p) {
      return _c("a-tag", {
        key: p,
        attrs: {
          color: "#6A66F6"
        }
      }, [_vm._v(_vm._s(w))]);
    }), 1), _c("a-col", _vm._l(item.periodList, function (t, j) {
      return _c("a-row", {
        key: j
      }, [_vm._v(" 上班 " + _vm._s(t.startPeriod) + " - 下班 " + _vm._s(t.endPeriod) + " ")]);
    }), 1), _c("a-col", {
      staticClass: "fr"
    }, [_c("span", {
      staticClass: "tc-theme cursor",
      on: {
        click: function click($event) {
          return _vm.editorPunchTime(item, i);
        }
      }
    }, [_vm._v(" 编辑 ")]), _c("a-divider", {
      attrs: {
        type: "vertical"
      }
    }), _c("span", {
      staticClass: "tc-theme cursor",
      on: {
        click: function click($event) {
          return _vm.deleteCardTime(i);
        }
      }
    }, [_vm._v(" 删除 ")])], 1)], 1);
  })] : _vm._e(), _vm.postRules.planType === 2 ? [_c("a-button", {
    staticClass: "tc-theme",
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.addFreeTime
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-square"
    }
  }), _vm._v("编辑排班")], 1)] : _vm._e(), _vm.postRules.planType === 3 ? [_c("a-checkbox-group", {
    on: {
      change: _vm.punchTime
    },
    model: {
      value: _vm.workWeekList,
      callback: function callback($$v) {
        _vm.workWeekList = $$v;
      },
      expression: "workWeekList"
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期一"
    }
  }, [_vm._v("星期一")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期二"
    }
  }, [_vm._v("星期二")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期三"
    }
  }, [_vm._v("星期三")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期四"
    }
  }, [_vm._v("星期四")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期五"
    }
  }, [_vm._v("星期五")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期六"
    }
  }, [_vm._v("星期六")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期日"
    }
  }, [_vm._v("星期日")])], 1)], 1)], 1)] : _vm._e()], 2), _c("a-form-item", {
    attrs: {
      label: "特殊日期",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-button", {
    staticClass: "tc-theme",
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.addCardDate
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-square"
    }
  }), _vm._v("添加")], 1), _c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("必须打卡的日期 ")]), _c("a-row", {
    staticClass: "tab_title",
    staticStyle: {
      "border-top": "0"
    }
  }), _vm._l(_vm.postRules.needPunchDateList, function (item, i) {
    return _c("a-row", {
      key: i,
      staticClass: "tab_item",
      staticStyle: {
        width: "100%"
      },
      attrs: {
        type: "flex",
        align: "middle"
      }
    }, [_c("a-col", {
      staticClass: "pl20 mg_r20"
    }, [_vm._v(_vm._s(item.daily))]), _c("a-col", [_vm._l(item.periodList, function (d, j) {
      return _c("a-row", {
        key: j
      }, [_vm._v(" 上班 " + _vm._s(d.startPeriod) + " --下班 " + _vm._s(d.endPeriod) + " ")]);
    }), _vm._v(" " + _vm._s(item.reason) + " ")], 2), _c("a-col", {
      staticClass: "mg_l_o"
    }, [_c("span", {
      staticClass: "tc-theme cursor",
      on: {
        click: function click($event) {
          return _vm.editorCardDate(item, i);
        }
      }
    }, [_vm._v(" 编辑 ")]), _c("a-divider", {
      attrs: {
        type: "vertical"
      }
    }), _c("span", {
      staticClass: "tc-theme cursor",
      on: {
        click: function click($event) {
          return _vm.deleteSpTime(i);
        }
      }
    }, [_vm._v(" 删除 ")])], 1)], 1);
  }), _c("a-button", {
    staticClass: "tc-theme",
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.addNoUseClock
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-square"
    }
  }), _vm._v("添加")], 1), _c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("不用打卡的日期 ")]), _vm._l(_vm.postRules.noNeedPunchDateList, function (item, i) {
    return _c("a-row", {
      key: i + 99999999,
      staticClass: "tab_item",
      staticStyle: {
        width: "100%"
      },
      attrs: {
        type: "flex",
        align: "middle"
      }
    }, [_c("a-col", {
      staticClass: "pl20 mg_r20"
    }, [_vm._v(_vm._s(item.daily))]), _c("a-col", [_vm._v(_vm._s(item.reason))]), _c("a-col", {
      staticClass: "mg_l_o"
    }, [_c("span", {
      staticClass: "tc-theme cursor",
      on: {
        click: function click($event) {
          return _vm.editorNoUseCard(item, i);
        }
      }
    }, [_vm._v(" 编辑 ")]), _c("a-divider", {
      attrs: {
        type: "vertical"
      }
    }), _c("span", {
      staticClass: "tc-theme cursor",
      on: {
        click: function click($event) {
          return _vm.deleteNoUseClock(i);
        }
      }
    }, [_vm._v(" 删除 ")])], 1)], 1);
  })], 2), _c("a-form-item", {
    attrs: {
      label: "排休设置",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-checkbox", {
    model: {
      value: _vm.postRules.isSynchLegalHoliday,
      callback: function callback($$v) {
        _vm.$set(_vm.postRules, "isSynchLegalHoliday", $$v);
      },
      expression: "postRules.isSynchLegalHoliday"
    }
  }, [_vm._v(" 同步中国大陆法定节假日 ")]), _c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("自动排休 ")]), _c("a", {
    staticStyle: {
      "font-size": "14px",
      margin: "0 16px"
    },
    attrs: {
      disabled: !_vm.postRules.isSynchLegalHoliday
    },
    on: {
      click: function click($event) {
        _vm.visible = true;
      }
    }
  }, [_vm._v("查看排休日历")])], 1)], 1)], 1), _c("div", {
    staticClass: "content"
  }, [_c("a-form", {
    staticClass: "lab_l"
  }, [_c("a-form-item", {
    attrs: {
      label: "手机提醒",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("span", {
    staticClass: "mg_r10"
  }, [_vm._v("上班前")]), _c("a-input-number", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      min: 0,
      formatter: function formatter(value) {
        return "".concat(value, "\u5206\u949F");
      }
    },
    model: {
      value: _vm.postRules.remindBefore,
      callback: function callback($$v) {
        _vm.$set(_vm.postRules, "remindBefore", $$v);
      },
      expression: "postRules.remindBefore"
    }
  }), _vm._v(" - "), _c("span", {
    staticClass: "mg_r10"
  }, [_vm._v("下班后")]), _c("a-input-number", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      min: 0,
      formatter: function formatter(value) {
        return "".concat(value, "\u5206\u949F");
      }
    },
    model: {
      value: _vm.postRules.remindAfter,
      callback: function callback($$v) {
        _vm.$set(_vm.postRules, "remindAfter", $$v);
      },
      expression: "postRules.remindAfter"
    }
  }), _c("a-checkbox", {
    staticStyle: {
      "margin-left": "12px"
    },
    model: {
      value: _vm.postRules.isRemind,
      callback: function callback($$v) {
        _vm.$set(_vm.postRules, "isRemind", $$v);
      },
      expression: "postRules.isRemind"
    }
  }, [_vm._v(" 是否提醒 ")])], 1), _c("a-form-item", {
    attrs: {
      label: "范围打卡",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.postRules.punchRange,
      callback: function callback($$v) {
        _vm.$set(_vm.postRules, "punchRange", $$v);
      },
      expression: "postRules.punchRange"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 允许范围外打卡,记录为地点异常 ")]), _c("a-radio", {
    attrs: {
      value: 3
    }
  }, [_vm._v("不允许范围外打卡")])], 1)], 1)], 1)], 1), _c("a-form", {
    staticClass: "lab_l"
  }, [_c("a-form-item", {
    attrs: {
      label: "规则生效时间",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.postRules.takeEffect,
      callback: function callback($$v) {
        _vm.$set(_vm.postRules, "takeEffect", $$v);
      },
      expression: "postRules.takeEffect"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("立即生效")]), _c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("明日生效")])], 1)], 1)], 1), _c("a-row", {
    staticClass: "editorRules_btn"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveNeedData
    }
  }, [_vm._v("保存")])], 1)], 1) : _vm._e(), _vm.addFreeCard ? _c("a-layout-content", [_c("check-free-time", {
    attrs: {
      "free-card": _vm.freeCard
    }
  })], 1) : _vm._e(), _c("add-wifi", {
    attrs: {
      "wifi-options": _vm.wifiOptions
    },
    on: {
      editorWifi: function editorWifi($event) {
        return _vm.editorWifi($event);
      }
    }
  }), _c("no-use-clock", {
    attrs: {
      show: _vm.showNoUseClock,
      "no-need-punch-date": _vm.noNeedPunchDate,
      "edit-no-use-index": _vm.editNoUseIndex
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.showNoUseClock = $event;
      },
      handleNoNeedList: _vm.handleNoNeedList,
      handleNoUseShow: _vm.handleNoUseShow
    }
  }), _c("card-position", {
    attrs: {
      "card-position-options": _vm.cardPositionOptions
    },
    on: {
      cardPos: function cardPos($event) {
        return _vm.cardPos($event);
      }
    }
  }), _c("do-work-rules", {
    attrs: {
      "do-work-rules-options": _vm.doWorkRulesOptions
    },
    on: {
      addWorkRules: function addWorkRules($event) {
        return _vm.addWorkRules($event);
      }
    }
  }), _vm.timeList.periodList ? _c("card-time", {
    attrs: {
      "show-card-time": _vm.showCardTime,
      "time-list": _vm.timeList,
      "edit-index": _vm.editIndex
    },
    on: {
      handleTimeList: _vm.handleTimeList,
      handleTimeShow: _vm.handleTimeShow
    }
  }) : _vm._e(), _c("card-date", {
    attrs: {
      show: _vm.showCardDate,
      "need-punch-date": _vm.needPunchDate,
      "edit-date-index": _vm.editDateIndex
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.showCardDate = $event;
      },
      handleNeedList: _vm.handleNeedList,
      handleDateShow: _vm.handleDateShow
    }
  }), _c("employee-select", {
    attrs: {
      show: _vm.isChooseStaff,
      "default-selected-keys": _vm.defaultSelectedKeys
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.isChooseStaff = $event;
      },
      change: _vm.onEmployeeSelectChange
    }
  }), _c("employee-select", {
    attrs: {
      show: _vm.isChooseStaff2,
      "default-selected-keys": _vm.defaultSelectedKeys2
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.isChooseStaff2 = $event;
      },
      change: _vm.onEmployeeSelectChange2
    }
  }), _c("cardCalendar", {
    attrs: {
      visible: _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };