import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: "MessageSet",
  components: {
    addMessage: function addMessage() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/common/add_message"));
      });
    }
  },
  data: function data() {
    return {
      msgOptions: {}
    };
  },
  methods: {
    // 调薪提醒
    onChangeSwitch: function onChangeSwitch(checked) {},
    // 添加调薪提醒
    addMessage: function addMessage() {
      this.msgOptions = {
        show: true,
        data: []
      };
    }
  }
};