var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.alertiVisible ? _c("a-alert", {
    staticStyle: {
      margin: "24px",
      "background-color": "#fff1f0"
    },
    attrs: {
      type: "error",
      message: "\u6709".concat(_vm.errorDataCount, "\u6761\u6570\u636E\u5F02\u5E38\uFF0C\u8BF7\u5C3D\u5FEB\u5904\u7406"),
      "show-icon": "",
      closable: "",
      "after-close": _vm.handleClose,
      "close-text": "查看详情"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "xq-form-wrapper",
    staticStyle: {
      margin: "24px"
    }
  }, [_c("a-spin", {
    staticClass: "xq-form-content",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 70
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "positionCount" ? _c("span", {
            key: index
          }, [_vm._v(" 导入 ")]) : col.dataIndex === "skill" ? _c("div", {
            key: index,
            staticClass: "tags"
          }, _vm._l(record.skill, function (item, index) {
            return _c("div", {
              key: index
            }, [_vm._v(_vm._s(item))]);
          }), 0) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "preview-btn"
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("onAction", 0);
      }
    }
  }, [_vm._v("返回")]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.errorDataCount > 0
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(" 提交 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };