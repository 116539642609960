import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
var columns = [{
  title: "项目",
  dataIndex: "itemName",
  scopedSlots: {
    customRender: "itemName"
  }
}, {
  title: "默认个人缴纳基数",
  dataIndex: "cardinalityDefault",
  scopedSlots: {
    customRender: "cardinalityDefault"
  }
}, {
  title: "单位缴纳比例",
  dataIndex: "persentCompany",
  scopedSlots: {
    customRender: "persentCompany"
  }
}, {
  title: "个人缴纳比例",
  dataIndex: "persentPersonal",
  scopedSlots: {
    customRender: "persentPersonal"
  }
}];
import { findEmpSocRelated, empUpdateSocDate } from "./api";
export default {
  props: {
    editable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    editable: function editable(val) {
      console.log(val);
      if (val === true) {
        this.edit();
      }
    }
  },
  data: function data() {
    return {
      defaultEmpId: "",
      companyId: "",
      cacheData: [],
      cacheData1: [],
      cacheData2: [],
      data: [],
      data1: [],
      data2: [],
      record: {},
      columns: columns,
      editingKey: ""
    };
  },
  created: function created() {},
  methods: {
    findEmpSocRelatedFun: function findEmpSocRelatedFun(defaultEmpId, companyId) {
      this.defaultEmpId = defaultEmpId;
      this.companyId = companyId;
      this.getFindEmpSocRelated();
    },
    getFindEmpSocRelated: function getFindEmpSocRelated() {
      var _this = this;
      findEmpSocRelated({
        empId: this.defaultEmpId,
        companyId: this.companyId
      }).then(function (res) {
        // console.log(res);
        if (res.data.itemList.length === 0) {
          _this.data = [];
          _this.data1 = [];
          _this.data2 = [];
          _this.$emit("turnOnEditingFun", false);
        } else {
          _this.record = res.data;
          if (!_this.record.turnOnEditing) {
            _this.$emit("turnOnEditingFun", _this.record.turnOnEditing);
          }
          _this.data = res.data.itemList.filter(function (z) {
            return z.itemType === 1;
          }).filter(function (item, index) {
            return item.key = index.toString();
          });
          _this.data1 = res.data.itemList.filter(function (z) {
            return z.itemType === 3;
          }).filter(function (item, index) {
            return item.key = index.toString();
          });
          _this.data2 = res.data.itemList.filter(function (z) {
            return z.itemType === 2;
          }).filter(function (item, index) {
            return item.key = index.toString();
          });
          _this.cacheData = _this.data;
          _this.cacheData1 = _this.data1;
          _this.cacheData2 = _this.data2;
          if (_this.data.length === 0 && _this.data1.length === 0 && _this.data2.length === 0) {
            _this.$emit("turnOnEditingFun", false);
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleChange: function handleChange(value, key, column, type) {
      console.log(value, key, column, type);
      var newData = type === "type1" ? _toConsumableArray(this.data) : type === "type2" ? _toConsumableArray(this.data1) : _toConsumableArray(this.data2);
      var target = newData.filter(function (item) {
        return key === item.key;
      })[0];
      if (target) {
        target[column] = value;
        type === "type1" ? this.data : type === "type2" ? this.data1 : this.data2 = newData;
      }
    },
    edit: function edit() {
      this.data = this.data.filter(function (item, index) {
        return item.editable = true;
      });
      this.data1 = this.data1.filter(function (item, index) {
        return item.editable = true;
      });
      this.data2 = this.data2.filter(function (item, index) {
        return item.editable = true;
      });
    },
    save: function save() {
      var _this2 = this;
      var newData = [].concat(_toConsumableArray(this.data), _toConsumableArray(this.data1), _toConsumableArray(this.data2));
      var data = Object.assign(this.record, {
        itemList: newData
      });
      empUpdateSocDate(data).then(function (res) {
        _this2.getFindEmpSocRelated();
        _this2.$emit("closeEdit");
      }).catch(function (err) {
        console.log(err);
      });
    },
    cancel: function cancel() {
      // console.log(this.cacheData);
      this.data.filter(function (item) {
        return item.editable = false;
      });
      this.data1.filter(function (item) {
        return item.editable = false;
      });
      this.data2.filter(function (item) {
        return item.editable = false;
      });
      this.data = this.cacheData;
      this.data1 = this.cacheData1;
      this.data2 = this.cacheData2;
      this.$emit("closeEdit");
    }
  }
};