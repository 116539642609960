import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { selectServiceLog as _selectServiceLog } from "../api";
import moment from "moment";
import { parseTime } from "@/utils/index";
export default {
  props: ["siteId"],
  data: function data() {
    return {
      parseTime: parseTime,
      logDate: moment(),
      logListData: [],
      logName: undefined,
      pageSize: 10,
      current: 1,
      entityCount: 0
    };
  },
  watch: {
    logDate: function logDate(val) {
      this.selectServiceLog({
        userName: this.logName
      });
    },
    logName: function logName(val) {
      this.current = 1;
      this.selectServiceLog({
        userName: val
      });
    },
    siteId: function siteId(newValue) {
      this.logName = undefined;
      this.pageSize = 10;
      this.current = 1;
      this.selectServiceLog();
      // console.log("debug log --> newValue", newValue);
    }
  },
  created: function created() {
    this.selectServiceLog();
  },
  methods: {
    moment: moment,
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    selectServiceLog: function selectServiceLog(data) {
      var _this = this;
      var pr = _objectSpread(_objectSpread({}, data), {}, {
        pageNo: this.current,
        pageSize: this.pageSize
      });
      if (this.logDate) {
        pr.gmtCreateStart = moment(this.logDate).format("YYYY-MM-DD") + " 00:00:00";
        pr.gmtCreateEnd = moment(this.logDate).format("YYYY-MM-DD") + " 23:59:59";
      }
      if (this.siteId && this.siteId !== "undefined" && this.siteId !== "-1") {
        pr.companyStr = this.siteId;
      }
      _selectServiceLog(pr).then(function (res) {
        _this.logListData = res.data.entities;
        _this.entityCount = res.data.entityCount;
        if (res.data.entities) {
          _this.current = res.data.pageNo === 0 ? 1 : res.data.pageNo;
          _this.pageSize = res.data.pageSize;
        } else {
          _this.current = 1;
          _this.pageSize = 10;
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = 1;
      this.selectServiceLog({
        userName: this.logName
      });
    },
    onChange: function onChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = current;
      this.selectServiceLog({
        userName: this.logName
      });
    }
  }
};