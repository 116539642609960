import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import request from "@/utils/request";
import axios from "axios";
import { getToken } from "xqjr-module-auth";
import { notification } from "ant-design-vue";
export function findByDefault(params) {
  return request({
    url: "/admin/api/v1/customTemplateFields/findByDefault",
    method: "GET",
    params: params
  });
}
export function findBySite(params) {
  return request({
    url: "/admin/api/v1/customTemplateFields/findBySite",
    method: "GET",
    params: params
  });
}
export function generateQRCode(data) {
  return request({
    url: "/recruit2/api/v1/jobSeekers/generateQRCode",
    method: "post",
    data: data
  });
}
export function editCustomTemplate(data) {
  return request({
    url: "/admin/api/v1/customTemplates/editCustomTemplate",
    method: "put",
    data: data
  });
}
export function onPostDownload(url, name) {
  axios({
    url: "/file/api/v1/site/download",
    method: "post",
    data: {
      siteFileUrls: [url]
    },
    responseType: "blob",
    headers: {
      token: getToken()
    }
  }).then(function (res) {
    f(res, name);
  }).catch(function (err) {
    console.log("error log --> ", err);
  });
}
var f = function f(res, name) {
  if (res.data) {
    var rspHeaders = res.headers;
    var blob = new Blob([res.data], {
      type: "application/octet-stream"
    });
    var link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    if (rspHeaders["content-disposition"]) {
      try {
        var fileName = decodeURI(rspHeaders["content-disposition"].split(";")[1].split("=")[1].replaceAll('"', ""));
        var fileNameArr = fileName.split(".");
        link.download = name + "." + fileNameArr[1];
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } catch (err) {
        console.log("error log --> ", err);
      }
    } else {
      notification.error({
        message: "下载失败"
      });
    }
  }
};