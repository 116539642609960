export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    target: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      fileList: [],
      prodId: undefined,
      banner: ""
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.prodId = this.target.productId;
    this.banner = this.target.banner;
  },
  mounted: function mounted() {},
  methods: {
    onProdIdChange: function onProdIdChange(value) {
      if (this.target.id) {
        this.$emit("edit", {
          id: this.target.id,
          productId: value,
          banner: this.banner
        });
      }
    },
    beforeUploadClick: function beforeUploadClick() {
      this.$message.warning("请先选择企业服务");
    },
    onFileChange: function onFileChange(_ref) {
      var file = _ref.file,
        fileList = _ref.fileList;
      this.fileList = fileList;
      if (file.status === "done" && file.response && file.response.data && file.response.data[0]) {
        this.banner = file.response.data[0];
        if (this.target.id) {
          this.$emit("edit", {
            id: this.target.id,
            productId: this.prodId,
            banner: this.banner
          });
        } else {
          this.$emit("add", {
            productId: this.prodId,
            banner: this.banner
          });
        }
      }
    },
    delItem: function delItem() {
      this.$emit("del");
    }
  }
};