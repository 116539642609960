import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { mapState, mapMutations, mapGetters } from "vuex";
import { importPayroll } from "@/api/wages/monthly";
import axios from "axios";
import qs from "qs";
export default {
  name: "AddPayslip",
  data: function data() {
    return {
      inputText: "",
      selectDate: undefined,
      tableName: "",
      action: "",
      columns: [{
        key: "empName",
        title: "姓名",
        width: 150,
        dataIndex: "empName",
        fixed: "left"
      }, {
        title: "企业员工",
        key: "empInCompany",
        dataIndex: "empInCompany",
        width: 150,
        scopedSlots: {
          customRender: "empInCompany"
        }
      }, {
        title: "手机",
        dataIndex: "phoneStatus",
        key: "phoneStatus",
        width: 150,
        scopedSlots: {
          customRender: "phoneStatus"
        }
      }, {
        title: "微信",
        dataIndex: "wechatStatus",
        key: "wechatStatus",
        width: 150,
        scopedSlots: {
          customRender: "wechatStatus"
        }
      }, {
        title: "手机号",
        dataIndex: "phone",
        key: "phone",
        width: 150
      }, {
        title: "基本工资",
        dataIndex: "salaryBasic",
        key: "salaryBasic",
        width: 150
      }, {
        title: "个人社保",
        dataIndex: "empSoc",
        key: "empSoc",
        width: 150
      }, {
        title: "实发工资",
        dataIndex: "salaryReal",
        key: "salaryReal",
        width: 150
      }],
      titles: {},
      cbParams: {
        pageNo: 1,
        reloadCount: true,
        pageSize: 10
      },
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      salary: [],
      excelVisible: false,
      excelOptions: {},
      hide: false,
      form: this.$form.createForm(this, {
        name: "coordinated"
      }),
      params: {
        month: "",
        year: "",
        tableName: ""
      },
      tableId: ""
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(["hr"])), mapGetters({
    PAYSLIP: "wages/PAYSLIP"
  })), {}, {
    disabled: function disabled() {
      return !(this.tableName !== "" && this.selectDate !== undefined);
    }
  }),
  methods: _objectSpread(_objectSpread({
    monthChange: function monthChange(e) {}
  }, mapMutations({
    getSuccessList: "wages/successList",
    cbData: "wages/cbData",
    tableIds: "wages/tableId",
    postParams: "wages/postParams",
    resetPostParams: "wages/resetPostParams"
  })), {}, {
    // 导入工资表
    onUpload: function onUpload() {
      var _this$selectDate$form = this.selectDate.format("YYYY-MM").split("-"),
        _this$selectDate$form2 = _slicedToArray(_this$selectDate$form, 2),
        year = _this$selectDate$form2[0],
        month = _this$selectDate$form2[1];
      this.action = "/salary/api/v1/employeeSalaries/import?companyId=".concat(this.hr.companyId, "&month=").concat(month, "&year=").concat(year, "&salaryTemplateName=").concat(this.tableName);
      this.params.year = year;
      this.params.month = month;
    },
    cbUpload: function cbUpload(e) {
      if (e.file) {
        if (e.file.response && e.file.response.data) {
          var data = e.file.response.data;
          this.tableId = data.tableId;
          var response = e.file.response;
          if (response && response.data && response.data) {
            // this.onUploadChange(response.data);
            this.$notification["success"]({
              message: "success",
              description: "上传成功"
            });
            this.$router.go(-1);
          }
        } else {
          if (e.file.response && e.file.response.msg) {
            this.$notification["error"]({
              message: e.file.response.msg
            });
          }
        }
      }
    },
    onUploadChange: function onUploadChange(data) {
      this.excelVisible = true;
      this.excelOptions = data;
      this.tableId = data.tableId;
    },
    // 上传成功的excel
    successExcel: function successExcel() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/salaryBills/pageRelation",
        params: _objectSpread(_objectSpread({
          companyId: this.hr.companyId,
          tableId: this.tableId
        }, this.cbParams), {}, {
          pageSize: this.pagination.pageSize
        })
      }).then(function (_ref) {
        var data = _ref.data;
        //  this.cbData(data);
        _this.titles = {
          countAll: data.countAll,
          countInCompany: data.countInCompany,
          countNotInCompany: data.countNotInCompany,
          countWeChatBind: data.countWeChatBind,
          countWeChatUnBind: data.countWeChatUnBind,
          sumSalaryAmount: data.sumSalaryAmount,
          sumSalaryReal: data.sumSalaryReal
        };
        _this.salary = data.salaryBillListVOPage.entities || [];
        // this.getSuccessList(this.salary);
        _this.pagination.total = data.salaryBillListVOPage.entityCount || 0;
        _this.salary.forEach(function (item) {
          item.salaryBillFieldsDOList.forEach(function (key) {
            key.dataIndex = key.sort;
          });
        });
        var colum = [];
        _this.salary[0].salaryBillFieldsDOList.map(function (key) {
          colum.push({
            dataIndex: key.sort,
            title: key.fieldName,
            width: 150,
            key: key.sort
          });
        });
        _this.salary = _this.salary.map(function (item) {
          var obj = {};
          item.salaryBillFieldsDOList.map(function (key) {
            obj[key["dataIndex"]] = key["fieldValue"];
          });
          item = _objectSpread(_objectSpread({}, item), obj);
          return item;
        });
        _this.columns = [].concat(_toConsumableArray(_this.columns), colum);
      });
    },
    // excelCalback
    excelChange: function excelChange(e) {
      if (e.visibleWrong) {
        this.excelVisible = e.visibleWrong;
      } else {
        this.excelVisible = false;
        this.$router.push({
          path: "add-payslip-sort",
          query: {
            tableId: this.tableId,
            month: this.params.month,
            year: this.params.year,
            sendName: this.tableName
          }
        });
      }
    },
    // 下载模板
    download: function download() {
      var token = this.$store.state.auth.token;
      var params = {};
      params = {
        templateName: "recordSalaryUpload.xlsx"
      };
      axios({
        url: " /salary/api/v1/excel/template/download",
        params: params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "multipart/form-data"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "历史工资上传模板.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 表单提交
    handleSubmit: function handleSubmit(e) {
      var _this2 = this;
      this.form.validateFields(function (err, values) {
        if (!err) {
          values.selectDate = values.selectDate.format("YYYY-MM");
          _this2.params.tableName = values.tableName;
          _this2.params.year = values.selectDate.split("-")[0];
          _this2.params.month = values.selectDate.split("-")[1];
          _this2.hide = !_this2.hide;
          _this2.postParams({
            sendName: values.tableName,
            month: _this2.params.month,
            year: _this2.params.year
          });
        }
      });
    },
    // 重新上传
    reUpload: function reUpload() {
      this.salary = [];
      this.tableName = "";
      this.selectDate = undefined;
    },
    // 翻页
    handlePage: function handlePage(e) {
      var _this3 = this;
      this.pagination.current = e.current;
      this.cbParams.pageNo = e.current;
      this.$request({
        url: "/hr/api/v1/salaryBills/pageRelation",
        params: _objectSpread({
          companyId: this.hr.companyId,
          tableId: this.tableId
        }, this.cbParams)
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this3.titles = {
          countAll: data.countAll,
          countInCompany: data.countInCompany,
          countNotInCompany: data.countNotInCompany,
          countWeChatBind: data.countWeChatBind,
          countWeChatUnBind: data.countWeChatUnBind,
          sumSalaryAmount: data.sumSalaryAmount,
          sumSalaryReal: data.sumSalaryReal
        };
        _this3.salary = data.salaryBillListVOPage.entities || [];
        _this3.pagination.total = data.salaryBillListVOPage.entityCount || 0;
        _this3.getSuccessList(_this3.salary);
      });
    },
    // 下一页
    next: function next() {
      this.$router.push({
        path: "/wages/add-payslip-second",
        query: {
          unSendCount: this.pagination.total
        }
      });
    },
    // 筛选
    selectItem: function selectItem(type) {}
  })
};