import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "file-edit"
  }, [_c("a-row", {
    staticClass: "head"
  }, [_c("a-icon", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goBack
    }
  }), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")]), _c("a-col", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("员工调薪")])])], 1), _c("a-layout", [_c("a-layout-sider", {
    staticClass: "border-r-line ta-center",
    attrs: {
      width: "276"
    }
  }, [_c("div", {
    staticClass: "file-user",
    staticStyle: {
      "margin-top": "40px"
    }
  }, [_c("a-row", {
    staticClass: "file-user-head",
    staticStyle: {
      "padding-left": "30px",
      "padding-right": "30px"
    }
  }, [_c("a-col", {
    staticStyle: {
      height: "170px"
    }
  }, [_c("a-row", {
    staticClass: "pd_b10"
  }, [_c("strong", {
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.routerData.name))])]), _c("a-row", {
    staticClass: "pd_b10",
    staticStyle: {
      "padding-top": "6px",
      "padding-bottom": "25px",
      color: "#adadad"
    }
  }, [_vm._v(" " + _vm._s(_vm.routerData.depName ? _vm.routerData.depName : "暂无") + " "), _c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }), _vm._v(" " + _vm._s(_vm.routerData.positionName ? _vm.routerData.positionName : "暂无") + " ")], 1), _c("a-row", [_c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "0px"
    }
  }, [_vm._v(" 入职日期: "), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(_vm._s(_vm.formatDate(Number(_vm.routerData.joinDate), "d")))])]), _c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "35px"
    }
  }, [_vm._v(" 工作性质："), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(_vm._s(_vm.postType[_vm.routerData.postType - 1]))])]), _c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "70px"
    }
  }, [_vm._v(" 员工状态："), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(_vm._s(["试用", "正式"][_vm.routerData.formalType - 1]))])])])], 1), _c("a-divider")], 1)], 1)]), _c("a-layout-content", {
    staticClass: "ph24 mb100"
  }, [_c("a-form-model", {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      model: _vm.form
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: " 调薪模板",
      rules: {
        required: true,
        message: "必选"
      }
    }
  }, [_c("div", [_c("a-button", {
    staticClass: "modelBotton"
  }, [_vm._v(" " + _vm._s(_vm.fixedSalaryTemplateName) + " ")])], 1)]), _vm.routerData.formalType === 1 ? _c("a-form-model-item", {
    attrs: {
      colon: false,
      label: " 调薪明细：试用期工资",
      rules: {
        required: true,
        message: "必选"
      }
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      "row-key": "itemName",
      "data-source": _vm.trainingList,
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "amount",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text.toFixed(2)) + " ")];
      }
    }, {
      key: "afterAmount",
      fn: function fn(text, record) {
        return [_c("div", [record.isSum !== "1" ? _c("a-input-number", {
          staticStyle: {
            margin: "-5px 0",
            width: "120px"
          },
          attrs: {
            "default-value": text,
            min: 0,
            step: 1,
            max: 100000,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleInputChange1(value, record.projectId);
            }
          }
        }) : _c("span", [_vm._v(_vm._s(text.toFixed(2)))])], 1)];
      }
    }, {
      key: "amplitude",
      fn: function fn(text, record) {
        return [_c("span", {
          style: {
            color: record.relativecolor
          }
        }, [_vm._v(_vm._s(text))])];
      }
    }], null, false, 1044762948)
  })], 1) : _vm._e(), _c("a-form-model-item", {
    attrs: {
      label: " 正式工资",
      rules: {
        required: true,
        message: "必选"
      }
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      "row-key": "itemName",
      "data-source": _vm.officialList,
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "amount",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text.toFixed(2)) + " ")];
      }
    }, {
      key: "afterAmount",
      fn: function fn(text, record) {
        return [_c("div", [record.isSum !== "1" ? _c("a-input-number", {
          staticStyle: {
            margin: "-5px 0",
            width: "120px"
          },
          attrs: {
            "default-value": text,
            min: 0,
            step: 1,
            max: 100000,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleInputChange2(value, record.projectId);
            }
          }
        }) : _c("span", [_vm._v(_vm._s(text.toFixed(2)))])], 1)];
      }
    }, {
      key: "amplitude",
      fn: function fn(text, record) {
        return [_c("span", {
          style: {
            color: record.relativecolor
          }
        }, [_vm._v(_vm._s(text))])];
      }
    }])
  })], 1), _c("a-form-model-item", [_c("label", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("* ")]), _vm._v(" "), _c("span", {
    staticStyle: {
      color: "rgba(0, 0, 0, 0.85)"
    }
  }, [_vm._v("调薪原因：")])]), _c("a-select", {
    staticStyle: {
      width: "300px",
      "margin-left": "28px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reason", $$v);
      },
      expression: "form.reason"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 转正 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 晋升 ")]), _c("a-select-option", {
    attrs: {
      value: 3
    }
  }, [_vm._v(" 调动 ")]), _c("a-select-option", {
    attrs: {
      value: 4
    }
  }, [_vm._v(" 年中调薪 ")]), _c("a-select-option", {
    attrs: {
      value: 5
    }
  }, [_vm._v(" 年度调薪 ")]), _c("a-select-option", {
    attrs: {
      value: 6
    }
  }, [_vm._v(" 特别调薪 ")]), _c("a-select-option", {
    attrs: {
      value: 7
    }
  }, [_vm._v(" 其他 ")])], 1)], 1), _c("a-form-model-item", [_c("label", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("* ")]), _vm._v(" "), _c("span", {
    staticStyle: {
      color: "rgba(0, 0, 0, 0.85)"
    }
  }, [_vm._v(" 调薪生效时间： ")])]), _c("a-date-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请选择",
      "disabled-date": _vm.disabledEndDate
    },
    on: {
      change: _vm.handleTimeChange
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      "label-col": {
        span: 1
      },
      "wrapper-col": {
        span: 13
      },
      label: "备注"
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "400px",
      "margin-left": "64px"
    },
    attrs: {
      "max-length": 50
    },
    model: {
      value: _vm.form.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  }), _c("a-row", {
    staticClass: "ta-right textarea-tip",
    staticStyle: {
      width: "465px"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.remark ? _vm.form.remark.length : 0) + "/50字 ")])], 1)], 1), _c("a-divider"), _c("a-row", {
    staticClass: "file-foot-save"
  }, [_c("a-col", {
    staticClass: "fr"
  }, [_c("a-button", {
    staticClass: "mg_r10",
    on: {
      click: _vm.goOut
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };