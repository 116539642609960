var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-table", {
    staticClass: "mg_t24",
    attrs: {
      "row-key": function rowKey(a, b) {
        return b;
      },
      columns: _vm.columns,
      "list-type": "picture",
      pagination: _vm.pagination,
      "data-source": _vm.dataSource
    },
    on: {
      change: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function fn(text, record) {
        return [_c("span", {
          staticClass: "round",
          class: ["no", "ok", "err"][record.status]
        }), _vm._v(" " + _vm._s(["未完成", "已完成", "异常"][record.status]) + " ")];
      }
    }, {
      key: "socEndDate",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(_vm.moment(record.socEndDate).format("YYYY-MM-DD")) + " ")];
      }
    }, {
      key: "fundEndDate",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(_vm.moment(record.fundEndDate).format("YYYY-MM-DD")) + " ")];
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("span", {
          staticClass: "primary",
          on: {
            click: function click($event) {
              return _vm.handelEvent(record);
            }
          }
        }, [_vm._v("查看反馈")])];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };