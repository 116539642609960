import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.timers.js";
import moment from "moment";
import "moment/locale/zh-cn";
import { userNoticeIdSysNoticesDel, offlineNotice, EditEmpNotices, addEmpNotices, getuserNoticeId, getPageEmpList } from "@/api/company/index";
import VueWangeditor from "@/components/ZXR/vueWangeditor";
export default {
  components: {
    VueWangeditor: VueWangeditor
  },
  data: function data() {
    var _this = this;
    return {
      fileList: [],
      spinning: false,
      previewVisible: false,
      bgImage: {
        backgroundImage: "url(" + require("@/assets/img/iphone.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
      },
      checkAllText: "全选当页",
      staffVisible: false,
      staffrightData: [],
      staffData: [],
      staffFrom: {
        postType: "",
        companyId: "",
        pageNo: 1,
        pageSize: 10
      },
      checkAll: false,
      visible: false,
      confirmLoading: false,
      treeData: [],
      tabbleData: [],
      pageCount: 0,
      pageTitle: "",
      // 内容区域
      caeatForm: {
        companyId: "",
        // 公司id
        content: "",
        // 公告内容
        contentHasStyle: "",
        // 带样式的公告内容
        finalUserIds: "",
        // 员工对应的用户
        isPublishNow: true,
        // 公告立即发布
        formalType: 2,
        // 员工正式类型(1 正式 2试用 0无状态)
        noticeType: [],
        // 通知类型
        offlineDate: "",
        // 下线时间
        props: "",
        // 补充信息
        publishDate: null,
        // 发布时间
        publishEmpIds: [],
        // 公告发布范围,员工id(部分员工时使用)
        publishEmpRange: "",
        // 部分发布的时候选择的员工id(用,分隔)
        publishEmpRangeIsAll: true,
        // 是否全公司发布(1:全公司,0:部分员工)
        publishEmpType: [],
        // 部分员工类型时选择的员工类型code(用,分隔)
        publishEmpTypeIsAll: true,
        // 是否全部员工类型发布(1:全部员工类型,0:部分员工类型)
        publishUserId: "",
        // 发布公告的用户id
        title: "",
        // 公告标题
        topDateCreate: "" // 发起置顶时间
      },

      options: [{
        label: "微信",
        value: "1"
      }
      // { label: "短信", value: "2" },
      // { label: "邮件", value: "3" }
      ],

      fold: false,
      chooseMemberVisible: false,
      defaultSelectedKeys: [],
      selectedList: [],
      memberColumn: [{
        title: "员工姓名",
        dataIndex: "name",
        align: "center",
        width: 200
      }, {
        title: "身份证号",
        dataIndex: "idCard",
        align: "center",
        width: 200,
        customRender: function customRender(text) {
          return text != null ? text : "--";
        }
      }, {
        title: "人员性质",
        dataIndex: "postType",
        align: "center",
        width: 200,
        customRender: function customRender(text) {
          _this.$store.state.enums.postTypeArr[text - 1];
        }
      }, {
        title: "手机号码",
        dataIndex: "phone",
        align: "center",
        width: 250,
        customRender: function customRender(text) {
          return text != null ? text : "--";
        }
      }],
      dateString: ""
    };
  },
  filters: {
    SelectedString: function SelectedString(data) {
      var _data = JSON.parse(JSON.stringify(data));
      var str = "";
      if (_data.length > 0) {
        str = _data.slice(0, 2).map(function (item) {
          return item.name;
        }).join(",");
      }
      return str;
    }
  },
  created: function created() {
    this.caeatForm.companyId = this.$store.state.hr.companyId;
    if (this.$route.query.sign === "0") {
      this.pageTitle = "新建公告";
    } else {
      this.pageTitle = "详情";
      this.getNoticeByIdDel(this.$route.query.id);
    }
  },
  methods: {
    saveFileList: function saveFileList(data) {
      this.fileList = data;
    },
    groupChange: function groupChange() {
      this.staffrightData = [];
      this.staffData = [];
      this.selectedList = [];
    },
    // 预览
    preview: function preview() {
      this.previewVisible = true;
    },
    publishEmpTypeIsAllChange: function publishEmpTypeIsAllChange(e) {
      this.caeatForm.publishEmpTypeIsAll = e.target.value;
    },
    onDet: function onDet() {
      var _this2 = this;
      userNoticeIdSysNoticesDel(this.caeatForm.id).then(function (res) {
        _this2.$message.success("删除成功!");
        _this2.$router.go(-1);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 下线
    offline: function offline() {
      var _this3 = this;
      offlineNotice(this.caeatForm.id).then(function (res) {
        _this3.getNoticeByIdDel(_this3.$route.query.id);
        _this3.$message.success("下线成功!");
      }).catch(function (err) {
        _this3.$message.error("下线失败!");
        console.log("debug log --> ", err);
      });
    },
    // 修改
    save: function save() {
      var _this4 = this;
      console.log(this.caeatForm, "this.caeatFormthis.caeatFormthis.caeatForm");
      var caeatForm = JSON.parse(JSON.stringify(this.caeatForm));
      caeatForm.publishEmpType = caeatForm.publishEmpType.length > 0 ? caeatForm.publishEmpType.join(",") : "";
      caeatForm.noticeType = caeatForm.noticeType.length > 0 ? caeatForm.noticeType.join(",") : "";
      caeatForm.publishEmpRange = caeatForm.publishEmpIds && caeatForm.publishEmpIds.length > 0 ? caeatForm.publishEmpIds.join(",") : "";
      EditEmpNotices(caeatForm).then(function (res) {
        _this4.$router.go(-1);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 全选当前页面
    onCheckAll: function onCheckAll() {
      var _this5 = this;
      this.checkAll = !this.checkAll;
      if (this.checkAll) {
        this.staffData.forEach(function (element) {
          if (!element.isTrue) {
            _this5.onRow(element);
          }
        });
      } else {
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            _this5.deleteItem(element);
          }
        });
      }
    },
    deleteItems: function deleteItems(item) {
      var _this6 = this;
      this.staffrightData.forEach(function (element, index) {
        if (item.id === element.id) {
          _this6.staffrightData.splice(index, 1);
        }
      });
    },
    deleteItem: function deleteItem(item, index) {
      var _this7 = this;
      this.staffrightData.splice(index, 1);
      this.staffData.forEach(function (element) {
        if (element.id === item.id) {
          element.isTrue = false;
          _this7.checkAll = false;
        }
      });
    },
    onSearch: function onSearch() {
      this.onCheckAll = false;
      this.staffFrom.pageNo = 1;
      this.getPageEmpLists();
    },
    showSizeChange: function showSizeChange(current, size) {
      this.staffFrom.pageNo = 1;
      this.staffFrom.pageSize = size;
      this.getPageEmpLists();
    },
    onChangePagination: function onChangePagination(pageNumber) {
      this.staffFrom.pageNo = pageNumber;
      this.getPageEmpLists();
    },
    onRow: function onRow(item) {
      var _this8 = this;
      item.isTrue = !item.isTrue;
      if (item.isTrue) {
        var num = 0;
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            num += 1;
          }
        });
        this.checkAll = this.staffData.length === num;
        var arr = this.staffrightData.filter(function (items) {
          return items.id === item.id;
        });
        if (arr.length === 0) {
          this.staffrightData.push(item);
        }
      } else {
        this.checkAll = false;
        this.staffrightData.forEach(function (element, index) {
          if (item.id === element.id) {
            _this8.staffrightData.splice(index, 1);
          }
        });
      }
    },
    staffHandleOk: function staffHandleOk() {
      this.selectedList = JSON.parse(JSON.stringify(this.staffrightData));
      this.staffVisible = false;
      var arr = [];
      this.selectedList.forEach(function (element) {
        arr.push(element.id);
      });
      this.caeatForm.publishEmpIds = arr;
    },
    staffUp: function staffUp() {
      this.visible = true;
      this.staffVisible = false;
    },
    getPageEmpLists: function getPageEmpLists() {
      var _this9 = this;
      this.staffFrom.postType = JSON.parse(JSON.stringify(this.caeatForm.publishEmpType)).join(",");
      this.staffFrom.companyId = this.$store.state.hr.companyId;
      this.staffFrom.formalType = this.caeatForm.formalType;
      getPageEmpList(this.staffFrom).then(function (res) {
        _this9.visible = false;
        _this9.staffVisible = true;
        res.data.entities.forEach(function (element, index) {
          res.data.entities[index].isTrue = false;
        });
        _this9.staffrightData.forEach(function (element) {
          res.data.entities.forEach(function (esy) {
            if (element.id === esy.id) {
              esy.isTrue = true;
            }
          });
        });
        _this9.staffData = res.data.entities;
        _this9.pageCount = res.data.entityCount;
        var arr = _this9.staffData.filter(function (item) {
          return item.isTrue;
        });
        _this9.checkAll = !!(arr.length === _this9.staffData.length && arr.length !== 0);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    xiayibu: function xiayibu() {
      if (this.caeatForm.publishEmpType.length === 0) {
        this.$message.error("请选择员工性质");
      } else {
        this.getPageEmpLists();
      }
    },
    handleOk: function handleOk(e) {
      var _this10 = this;
      this.confirmLoading = true;
      setTimeout(function () {
        _this10.visible = false;
        _this10.confirmLoading = false;
        _this10.staffrightData = [];
        _this10.staffData = [];
        _this10.selectedList = [];
      }, 100);
    },
    handleCancel: function handleCancel(e) {
      this.visible = false;
    },
    getNoticeByIdDel: function getNoticeByIdDel(id) {
      var _this11 = this;
      this.spinning = true;
      getuserNoticeId(id).then(function (res) {
        if (res.data.publishDate) {
          res.data.publishDate = moment(parseInt(res.data.publishDate)).format("YYYY-MM-DD hh:mm:ss");
        }
        res.data.joinCityCode = res.data.joinCityCode ? res.data.joinCityCode.split(",") : [];
        res.data.publishEmpType = res.data.publishEmpType ? res.data.publishEmpType.split(",") : [];
        res.data.noticeType = res.data.noticeType ? res.data.noticeType.split(",") : [];
        res.data.isPublishNow = false;
        _this11.caeatForm = res.data;
        _this11.caeatForm.publishEmpIds = res.data.publishEmpId.split(",");
        _this11.fileList = JSON.parse(_this11.caeatForm.file);
        _this11.selectedList = res.data.empInfoList;
        _this11.staffrightData = res.data.empInfoList;
      }).catch(function (err) {
        _this11.$message.error("失败!");
        console.log("debug log --> ", err);
      }).finally(function () {
        _this11.spinning = false;
      });
    },
    // 确认日期
    onOk: function onOk() {
      this.caeatForm.publishDate = this.date;
    },
    // 定时日期
    onChangeDate: function onChangeDate(date, dateString) {
      this.date = dateString;
      if (dateString === "") {
        this.caeatForm.publishDate = null;
      }
    },
    // 确认发布
    publish: function publish() {
      var _this12 = this;
      var caeatForm = JSON.parse(JSON.stringify(this.caeatForm));
      caeatForm.publishEmpType = caeatForm.publishEmpType.length > 0 ? caeatForm.publishEmpType.join(",") : "";
      caeatForm.noticeType = caeatForm.noticeType.length > 0 ? caeatForm.noticeType.join(",") : "";
      caeatForm.file = JSON.stringify(this.fileList);
      caeatForm.readEmpIds = undefined;
      caeatForm.readStatus = undefined;
      caeatForm.readCount = undefined;
      caeatForm.publishEmpRange = caeatForm.publishEmpIds && caeatForm.publishEmpIds.length > 0 ? JSON.parse(JSON.stringify(caeatForm.publishEmpIds)).join(",") : "";
      addEmpNotices(caeatForm).then(function (res) {
        _this12.$router.go(-1);
      });
    },
    // 选择人员
    selectMember: function selectMember() {
      this.visible = true;
    },
    VueWangeditor: function VueWangeditor(html, text) {
      this.caeatForm.contentHasStyle = html;
      this.caeatForm.content = text;
    },
    // 发布范围变动时
    changePublishEmpRangeIsAll: function changePublishEmpRangeIsAll(e) {
      this.caeatForm.publishEmpRangeIsAll = e.target.value;
      if (e.target.value) {
        this.defaultSelectedKeys = [];
        this.caeatForm.publishEmpIds = [];
        this.selectedList = [];
        this.fold = false;
      }
    }
  }
};