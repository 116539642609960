import "core-js/modules/es.function.name.js";
export default {
  name: "PayEditor",
  props: {
    payOptions: {
      type: Object,
      default: function _default() {
        return {
          show: false,
          obj: {}
        };
      }
    }
  },
  data: function data() {
    return {
      form: this.$form.createForm(this, {
        name: "coordinated"
      }),
      visible: false,
      data: {},
      addData: {}
    };
  },
  watch: {
    payOptions: {
      handler: function handler(val) {
        this.visible = val.show;
        this.data = val.obj;
        this.addData = val.obj.addData;
      }
    }
  },
  methods: {
    /* handleName */handleName: function handleName(val) {},
    /* 显示 */showModal: function showModal() {
      this.visible = true;
    },
    /* 提交 */handleSubmit: function handleSubmit(e) {
      var _this = this;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this.visible = false;
          _this.addData.name = values.data.name;
          _this.addData.type = 1;
          _this.$emit("paySetCb", _this.addData);
        }
      });
    },
    /* toggle */onChange: function onChange(checked) {}
  }
};