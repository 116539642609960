import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import { columns } from "./funcs";
import moment from "moment";
import { getSpecialDetails, postSpecialDetails } from "@/api/tax/tax";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
export default {
  mixins: [mixin],
  data: function data() {
    return {
      abolitionImg: require("@/assets/home/lizhi.png"),
      empIdList: [],
      inputName: null,
      empDetail: {},
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      form: {},
      visible: false,
      loading: false,
      selectedRowKeys: [],
      selectRowKey: [],
      dataList: [],
      columns: columns,
      isDaoru: false,
      groupList: [],
      empStatus: undefined,
      companyId: this.$store.state.hr.companyId,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      }
    };
  },
  created: function created() {
    this.getSpecialDetailsList();
  },
  beforeDestory: function beforeDestory() {},
  methods: {
    handleChange: function handleChange(e) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.empStatus = e;
      this.getSpecialDetailsList();
    },
    // onTimeChange(e) {
    //   const bb = parseInt(moment(e).format("MM")) === 1 ? 12 : moment(e).format("MM") - 1;
    //   console.log(e, bb);
    // },
    onClose: function onClose() {
      this.visible = false;
    },
    handleCancel: function handleCancel() {
      this.isDaoru = false;
    },
    onSelectChange: function onSelectChange(selectedRowKeys, e) {
      var _this = this;
      this.empIdList = [];
      this.selectedRowKeys = selectedRowKeys;
      e.forEach(function (item, index) {
        _this.empIdList.push(item.id);
      });
    },
    download: function download(i) {
      var token = this.$store.state.auth.token;
      if (i === 2) {
        this.exportData = {
          companyId: this.companyId,
          empIdList: [],
          status: this.empStatus ? this.empStatus : undefined,
          nameOrPhoneOrIdCard: this.inputName ? this.inputName : undefined
        };
      } else {
        this.exportData = {
          companyId: this.companyId,
          empIdList: this.empIdList
        };
      }
      this.url = "/salary/api/v1/specialAdditionalDetails/export";
      this.excelName = "".concat(this.$store.state.hr.companyName, "_") + "专项附加扣除导出详情" + ".xlsx";
      this.postDownload();
    },
    showEdit: function showEdit(record) {
      this.empDetail = JSON.parse(JSON.stringify(record));
      this.visible = true;
    },
    handleOk: function handleOk() {
      var _this2 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          postSpecialDetails({
            month: parseInt(moment().format("MM")) === 1 ? 12 : moment().format("MM") - 1,
            year: parseInt(parseInt(moment().format("MM")) === 1 ? moment().format("YYYY") - 1 : moment().format("YYYY")),
            companyId: _this2.companyId,
            empId: _this2.empDetail.id,
            id: _this2.empDetail.specialAdditionalDetailVO ? _this2.empDetail.specialAdditionalDetailVO.id : null,
            sumChildEducation: _this2.empDetail.sumChildEducation,
            sumHousingRent: _this2.empDetail.sumHousingRent,
            sumHomeLoanInterest: _this2.empDetail.sumHomeLoanInterest,
            sumSupportOldMan: _this2.empDetail.sumSupportOldMan,
            sumContinuingEducation: _this2.empDetail.sumContinuingEducation,
            sumChildCare: _this2.empDetail.sumChildCare,
            sumIndividualPensions: _this2.empDetail.sumIndividualPensions,
            monthlyChildEducation: _this2.empDetail.monthlyChildEducation,
            monthlyHousingRent: _this2.empDetail.monthlyHousingRent,
            monthlyHomeLoanInterest: _this2.empDetail.monthlyHomeLoanInterest,
            monthlySupportOldMan: _this2.empDetail.monthlySupportOldMan,
            monthlyContinuingEducation: _this2.empDetail.monthlyContinuingEducation,
            monthlyChildCare: _this2.empDetail.monthlyChildCare,
            individualPensions: _this2.empDetail.individualPensions
          }).then(function (res) {
            _this2.$notification["success"]({
              message: "success",
              description: "编辑成功"
            });
            _this2.getSpecialDetailsList();
            _this2.visible = false;
          });
        } else {
          return false;
        }
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getSpecialDetailsList();
    },
    inputChange: function inputChange(e) {
      this.inputName = e;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getSpecialDetailsList();
    },
    goImport: function goImport() {
      this.$router.push({
        path: "/tax/special/importSpecial",
        query: {}
      });
      // this.isDaoru = true;
    },
    getSpecialDetailsList: function getSpecialDetailsList() {
      var _this3 = this;
      this.loading = true;
      getSpecialDetails({
        status: this.empStatus,
        name: this.inputName,
        companyId: this.companyId,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        monthly: parseInt(moment().format("MM")) === 1 ? 12 : moment().format("MM") - 1,
        yearly: parseInt(moment().format("MM")) === 1 ? moment().format("YYYY") - 1 : moment().format("YYYY")
      }).then(function (res) {
        _this3.selectedRowKeys = [];
        _this3.loading = false;
        _this3.contractlist = res.data.entities;
        _this3.pagination.total = res.data && res.data.entityCount;
        _this3.dataList = res.data.entities;
        if (res.data.entities) {
          res.data.entities.forEach(function (item, index) {
            _this3.dataList[index].name = item.name;
            _this3.dataList[index].phone = item.phone;
            if (item.specialAdditionalDetailVO) {
              _this3.dataList[index].sumChildEducation = item.specialAdditionalDetailVO.sumChildEducation ? item.specialAdditionalDetailVO.sumChildEducation : "";
              _this3.dataList[index].sumHousingRent = item.specialAdditionalDetailVO.sumHousingRent ? item.specialAdditionalDetailVO.sumHousingRent : "";
              _this3.dataList[index].sumHomeLoanInterest = item.specialAdditionalDetailVO.sumHomeLoanInterest ? item.specialAdditionalDetailVO.sumHomeLoanInterest : "";
              _this3.dataList[index].sumSupportOldMan = item.specialAdditionalDetailVO.sumSupportOldMan ? item.specialAdditionalDetailVO.sumSupportOldMan : "";
              _this3.dataList[index].sumContinuingEducation = item.specialAdditionalDetailVO.sumContinuingEducation ? item.specialAdditionalDetailVO.sumContinuingEducation : "";
              _this3.dataList[index].sumChildCare = item.specialAdditionalDetailVO.sumChildCare ? item.specialAdditionalDetailVO.sumChildCare : "";
              _this3.dataList[index].sumIndividualPensions = item.specialAdditionalDetailVO.sumIndividualPensions ? item.specialAdditionalDetailVO.sumIndividualPensions : "";
              //
              _this3.dataList[index].monthlyChildEducation = item.specialAdditionalDetailVO.monthlyChildEducation ? item.specialAdditionalDetailVO.monthlyChildEducation : "";
              _this3.dataList[index].monthlyContinuingEducation = item.specialAdditionalDetailVO.monthlyContinuingEducation ? item.specialAdditionalDetailVO.monthlyContinuingEducation : "";
              _this3.dataList[index].monthlyHomeLoanInterest = item.specialAdditionalDetailVO.monthlyHomeLoanInterest ? item.specialAdditionalDetailVO.monthlyHomeLoanInterest : "";
              _this3.dataList[index].monthlyHousingRent = item.specialAdditionalDetailVO.monthlyHousingRent ? item.specialAdditionalDetailVO.monthlyHousingRent : "";
              _this3.dataList[index].monthlySupportOldMan = item.specialAdditionalDetailVO.monthlySupportOldMan ? item.specialAdditionalDetailVO.monthlySupportOldMan : "";
              _this3.dataList[index].monthlyChildCare = item.specialAdditionalDetailVO.monthlyChildCare ? item.specialAdditionalDetailVO.monthlyChildCare : "";
              _this3.dataList[index].individualPensions = item.specialAdditionalDetailVO.individualPensions ? item.specialAdditionalDetailVO.individualPensions : "";
            }
          });
        }
      });
    }
  }
};