import request from "@/utils/request";
// 分页查询专项附加项详情
export function getSpecialDetails(params) {
  return request({
    url: "/salary/api/v1/specialAdditionalDetails/selectSpecialAdditionalDetailPage",
    method: "get",
    params: params
  });
}
// 修改或新增专项附加项详情
export function postSpecialDetails(data) {
  return request({
    url: "/salary/api/v1/specialAdditionalDetails/updateOrSave",
    method: "post",
    data: data
  });
}
// 分页查询个税人员信息申报
export function getIncomeTax(params) {
  return request({
    url: "/salary/api/v1/specialAdditionalDetails/selectTaxPersonalInformationPage",
    method: "get",
    params: params
  });
}
// 分页查询正常工资薪金申报
export function getWages(params) {
  return request({
    url: "/salary/api/v1/specialAdditionalDetails/selectNormalSalaryPage",
    method: "get",
    params: params
  });
}
// 分页查询全年一次性奖金申报
export function getBonus(params) {
  return request({
    url: "/salary/api/v1/specialAdditionalDetails/selectBonusDeclarationPage",
    method: "get",
    params: params
  });
}