import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("rTitle", {
    attrs: {
      type: "back",
      tit: "人才详情"
    }
  }), _c("a-tabs", {
    staticStyle: {
      padding: "24px"
    }
  }, [_c("a-tab-pane", {
    key: "baseinfo",
    attrs: {
      tab: "基本信息"
    }
  }, [_c("div", {
    staticClass: "r-add-box"
  }, [_c("a-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    staticClass: "col-left",
    attrs: {
      span: 13
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_vm.avatar ? _c("a-avatar", {
    staticClass: "title-s-avatar",
    attrs: {
      src: _vm.avatar
    }
  }) : _vm._e(), !_vm.avatar && _vm.gender === "男" ? _c("a-avatar", {
    staticClass: "title-s-avatar",
    attrs: {
      src: _vm.n1
    }
  }) : _vm._e(), !_vm.avatar && _vm.gender === "女" ? _c("a-avatar", {
    staticClass: "title-s-avatar",
    attrs: {
      src: _vm.n2
    }
  }) : _vm._e(), _c("span", {
    staticClass: "title-s-name"
  }, [_vm._v(_vm._s(_vm.name))]), _vm.principalType === "broker" ? _c("span", {
    staticClass: "title-s-j",
    staticStyle: {
      width: "72px"
    }
  }, [_vm._v("经纪人人脉")]) : _vm._e(), _vm.principalType === "emp" ? _c("span", {
    staticClass: "title-s-n"
  }, [_vm._v("内推")]) : _vm._e(), _vm.principalType === "broker" ? _c("span", [_vm._v("来自经纪人")]) : _vm._e(), _vm.principalType === "broker" ? _c("span", {
    staticStyle: {
      color: "#6a66f6"
    }
  }, [_vm._v(_vm._s(_vm.$route.query.principalName))]) : _vm._e(), _vm.principalType === "emp" ? _c("span", [_vm._v("来自内推人")]) : _vm._e(), _vm.principalType === "emp" ? _c("span", {
    staticStyle: {
      color: "#32ccaa"
    }
  }, [_vm._v(_vm._s(_vm.$route.query.principalName))]) : _vm._e()], 1), _c("a-col", {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      span: 12
    }
  }, [_c("a-button", {
    staticClass: "peopleBoxConCZ",
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "link",
      icon: "form"
    },
    on: {
      click: _vm.onEdit
    }
  }, [_vm._v("编辑")])], 1)], 1), _c("ResumeDetails", {
    attrs: {
      status: _vm.statusLook,
      info: {
        talentPoolId: _vm.$route.query.talentPoolId
      }
    }
  })], 1), _c("a-col", {
    staticClass: "col-right",
    attrs: {
      span: 11
    }
  }, [!_vm.deliverRecordId ? _c("a-button", {
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: function click($event) {
        return _vm.onPosition(true);
      }
    }
  }, [_vm._v("添加职位")]) : _c("div", [_c("div", {
    staticClass: "drawer-msg"
  }, [_c("actionPoint", {
    staticClass: "swap-btn"
  }, [_c("template", {
    slot: "actionPointBtn"
  }, [_c("a-button", {
    attrs: {
      icon: "swap"
    }
  })], 1), _vm._l(_vm.posArr, function (item, index) {
    return _c("li", {
      key: index,
      on: {
        click: function click($event) {
          return _vm.onPos(item);
        }
      }
    }, [_c("div", {
      staticClass: "swap-btn-title"
    }, [_c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [item.postName ? _c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.postName))])]) : _vm._e(), _c("span", {
      staticClass: "overflowsQH"
    }, [_vm._v(_vm._s(item.postName))])], 2), item.status == 1 ? _c("span", [_vm._v("已投递")]) : _vm._e(), item.status == 2 ? _c("span", [_vm._v("已约面")]) : _vm._e(), item.status == 3 ? _c("span", [_vm._v("待入职")]) : _vm._e(), item.status == 4 ? _c("span", [_vm._v("已招聘")]) : _vm._e(), item.status >= 5 ? _c("span", [_vm._v("未通过")]) : _vm._e()], 1), _c("div", {
      staticClass: "swap-btn-time"
    }, [_vm._v(_vm._s(_vm.moment(item.gmtCreate).format("YYYY-MM-DD")))])]);
  })], 2), _c("div", [_c("a-tooltip", {
    attrs: {
      placement: "top"
    }
  }, [_vm.postName ? _c("template", {
    slot: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.postName))])]) : _vm._e(), _c("div", {
    staticClass: "overflows"
  }, [_vm._v(_vm._s(_vm.postName))])], 2), _vm._v("> "), _vm.companyUnitsStatus === 1 ? _c("p", [_vm._v("已投递")]) : _vm._e(), _vm.companyUnitsStatus === 2 ? _c("p", [_vm._v("已约面")]) : _vm._e(), _vm.companyUnitsStatus === 3 ? _c("p", [_vm._v("待入职")]) : _vm._e(), _vm.companyUnitsStatus === 4 ? _c("p", [_vm._v("已招聘")]) : _vm._e(), _vm.companyUnitsStatus >= 5 ? _c("p", [_vm._v("未通过")]) : _vm._e()], 1), _c("div", {
    staticClass: "desc"
  }, [_c("a-tooltip", {
    attrs: {
      placement: "bottom"
    }
  }, [_vm.companyUnitName ? _c("template", {
    slot: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.companyUnitName))])]) : _vm._e(), _c("div", {
    staticClass: "desc-font"
  }, [_vm._v(_vm._s(_vm.companyUnitName))])], 2), _vm._v(" / " + _vm._s(_vm.cityName ? _vm.cityName.split(",")[1].split("/").join("-") : "") + " / " + _vm._s(_vm.activeDelivery === 1 ? "投递人主动投递" : "投递人非主动投递") + " / " + _vm._s(_vm.moment(_vm.deliverDays).format("YYYY-MM-DD HH:mm:ss")) + " ")], 1)], 1), _c("div", [_vm.companyUnitsStatus === 1 ? _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.interviewVisible = true;
      }
    }
  }, [_vm._v("安排面试")]) : _vm._e(), _vm.companyUnitsStatus == 1 || _vm.companyUnitsStatus == 2 || _vm.companyUnitsStatus == 3 || _vm.companyUnitsStatus == 4 ? _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: _vm.onDelete
    }
  }, [_vm._v("不合适")]) : _vm._e(), _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: function click($event) {
        _vm.phaseVisible = true;
      }
    }
  }, [_vm._v("转移阶段")]), _c("a-button", {
    on: {
      click: function click($event) {
        return _vm.onPosition(false);
      }
    }
  }, [_vm._v("添加职位")])], 1)]), _c("fTitle", {
    attrs: {
      tit: "员工路径"
    }
  }), _vm.timelineArr.length === 0 ? _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("img", {
    staticClass: "noJobimg",
    attrs: {
      src: require("../../../../assets/noJobimg.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "noJobimgText"
  }, [_vm._v("暂无员工路径")])]) : _vm._e(), _c("div", {
    staticClass: "timeline"
  }, [_c("a-timeline", _vm._l(_vm.timelineArr, function (item) {
    return _c("a-timeline-item", {
      key: item.id
    }, [_c("div", {
      staticClass: "l-item"
    }, [_c("div", [_c("div", {
      staticClass: "time"
    }, [_vm._v(_vm._s(item.gmtCreate ? _vm.moment(item.gmtCreate).format("YYYY-MM-DD HH:mm:ss") : ""))]), _c("div", {
      staticClass: "content"
    }, [_vm._v(_vm._s(item.record))])]), _c("div", {
      staticClass: "btn"
    }, [item.deliverRecordStatus === 2 ? _c("a-button", {
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          _vm.interviewId = item.interviewId;
          _vm.interviewMsgVisible = true;
        }
      }
    }, [_vm._v("面试详情")]) : _vm._e()], 1)])]);
  }), 1)], 1)], 1)], 1), _c("Position", {
    attrs: {
      visible: _vm.positionVisible,
      type: _vm.positionType,
      id: _vm.recruitInfoId,
      statusP: _vm.companyUnitsStatus,
      deliverRecordId: _vm.deliverRecordId,
      talentPoolId: _vm.$route.query.talentPoolId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.positionVisible = $event;
      },
      upDate: _vm.upDate
    }
  }), _c("phase", {
    attrs: {
      visible: _vm.phaseVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.phaseVisible = $event;
      },
      upDate: _vm.upDatePhase
    }
  }), _c("interview", {
    attrs: {
      visible: _vm.interviewVisible,
      deliverRecordId: _vm.deliverRecordId,
      talentPoolId: _vm.$route.query.talentPoolId,
      mjson: _vm.mjson,
      info: _vm.info,
      positionValue: _vm.positionValue,
      positionType: _vm.positionType
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.interviewVisible = $event;
      },
      onShare: _vm.onShare,
      onShareDeliverRecordId: _vm.onShareDeliverRecordId
    }
  }), _c("interviewMsg", {
    attrs: {
      visible: _vm.interviewMsgVisible,
      deliverRecordId: _vm.deliverRecordId,
      interviewId: _vm.interviewId,
      info: _vm.info
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.interviewMsgVisible = $event;
      },
      onCloseInterview: _vm.onCloseInterview,
      onEvaluate: _vm.onEvaluate
    }
  }), _c("share", {
    attrs: {
      visible: _vm.shareVisible,
      qrCodeUrlV: _vm.qrCodeUrlV
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.shareVisible = $event;
      }
    }
  }), _c("evaluate", {
    attrs: {
      visible: _vm.evaluateVisible,
      evaluateId: _vm.evaluateId,
      recruitInfoId: _vm.recruitInfoId,
      companyUnitsId: _vm.companyUnitsId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.evaluateVisible = $event;
      },
      upDate: _vm.upDateEvaluate
    }
  })], 1)]), _c("a-tab-pane", {
    key: "records",
    attrs: {
      tab: "跟进记录"
    }
  }, [_c("Records")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };