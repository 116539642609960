import ListElectronic from "./list_electronic.vue";
import ListPaper from "./list_paper.vue";
import ListAll from "./list_all.vue";
export default {
  name: "",
  components: {
    ListElectronic: ListElectronic,
    ListPaper: ListPaper,
    ListAll: ListAll
  },
  mixins: [],
  props: {},
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {
    this.$store.commit("navtab/updateSelectedKeys", ["/contract/elecontract"]);
  },
  mounted: function mounted() {},
  methods: {}
};