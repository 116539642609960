import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import { columns } from "./funcs";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    companyId: {
      type: String,
      default: null
    },
    currentGroup: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      columns: columns,
      dataSource: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      }
    };
  },
  computed: {},
  watch: {
    companyId: function companyId() {
      this.pagination.current = 1;
      this.onSearchChange();
    },
    currentGroup: {
      handler: function handler() {
        this.pagination.current = 1;
        this.onSearchChange();
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.onSearchChange();
  },
  methods: {
    goEdit: function goEdit(record) {
      localStorage.setItem("tempZhidu", JSON.stringify(record));
      this.$router.push("/admin/zhidugongshi/edit?id=".concat(record.id, "&cId=").concat(this.companyId, "&tId=").concat(this.currentGroup.id));
    },
    goAdd: function goAdd() {
      this.$router.push("/admin/zhidugongshi/edit?cId=".concat(this.companyId, "&tId=").concat(this.currentGroup.id));
    },
    doDelete: function doDelete(institutionId) {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/institutions/".concat(institutionId),
        method: "delete"
      }).then(function (res) {
        _this.$message.success("已删除");
        _this.onSearchChange();
      }).catch(function (err) {
        console.log(err);
      });
    },
    doOpen: function doOpen(institutionId) {
      var _this2 = this;
      this.$request({
        url: "/hr/api/v1/institutions/openInstitution",
        method: "put",
        data: {
          institutionId: institutionId
        }
      }).then(function (res) {
        _this2.$message.success("已开启");
        _this2.onSearchChange();
      }).catch(function (err) {
        console.log(err);
      });
    },
    doClose: function doClose(institutionId) {
      var _this3 = this;
      this.$request({
        url: "/hr/api/v1/institutions/closeInstitution",
        method: "put",
        data: {
          institutionId: institutionId
        }
      }).then(function (res) {
        _this3.$message.success("已关闭");
        _this3.onSearchChange();
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleTableChange: function handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.onSearchChange();
    },
    onSearchChange: function onSearchChange() {
      var _this4 = this;
      this.$request({
        url: "/hr/api/v1/institutions/selectByTypeIdAndCompanyId",
        params: {
          companyId: this.companyId,
          institutionTypeId: this.currentGroup.id,
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        }
      }).then(function (res) {
        _this4.dataSource = res.data.entities;
        _this4.pagination.total = res.data.entityCount;
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};