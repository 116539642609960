import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wage-set-rule-detail-wrapper page-miH page-bgBack"
  }, [_c("a-row", {
    staticClass: "head"
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goDetail
    }
  }), _c("div", {
    staticClass: "left2",
    on: {
      click: _vm.goDetail
    }
  }, [_vm._v("返回")])], 1), _c("a-col", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("修改计税规则")])])], 1), _c("div", {
    staticClass: "rule-item-wrapper"
  }, [_c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("方案名称")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.dataDetail.name))])]), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("个税类型")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.taxRule[_vm.dataDetail.type - 1]))])]), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("描述")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.dataDetail.description))])]), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("计税周期")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_c("span", [_c("span", {
    class: _vm.selected === 1 ? "rule-circle-item circle-primary" : "rule-circle-item",
    staticStyle: {
      "margin-right": "15px"
    },
    on: {
      click: function click($event) {
        _vm.selected = 1;
      }
    }
  }, [_c("span", {
    staticClass: "rule-circle-item-title"
  }, [_vm._v("上年12月到今年11月")]), _c("span", {
    staticClass: "rule-circle-item-text"
  }, [_vm._v(" 对应的工资发放方式为次月发上月的工资 ")])]), _c("span", {
    class: _vm.selected === 2 ? "rule-circle-item circle-primary" : "rule-circle-item",
    on: {
      click: function click($event) {
        _vm.selected = 2;
      }
    }
  }, [_c("span", {
    staticClass: "rule-circle-item-title"
  }, [_vm._v("今年1月到12月")]), _c("span", {
    staticClass: "rule-circle-item-text"
  }, [_vm._v(" 对应的工资发放方式为当月发当月的工资 ")])])]), _c("div", {
    staticClass: "rule-circle-tip"
  }, [_vm._v(" 提示：工资发放方式将影响计税周期和报税 "), _c("span", {
    staticClass: "rule-circle-detail",
    on: {
      click: _vm.toDetail
    }
  }, [_vm._v("了解详情")])])])]), _vm.selected === 1 ? _c("div", {
    staticClass: "rule-item",
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("员工入职当月基本减除费用")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_c("a-radio-group", {
    model: {
      value: _vm.deduction,
      callback: function callback($$v) {
        _vm.deduction = $$v;
      },
      expression: "deduction"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 10000元 ")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 5000元 ")])], 1)], 1)]) : _vm._e(), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("是否计税")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.dataDetail.isCalculate ? "是" : "否"))])]), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("起征点")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.dataDetail.threshold === 0 ? "-" : "".concat(_vm.dataDetail.threshold, "/\u6708")))])]), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("个税结果")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.dataDetail.type === 3 ? "-" : _vm.dataDetail.taxResult))])]), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }), _c("span", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveRule
    }
  }, [_vm._v("保存")])], 1)])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };