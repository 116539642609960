export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      list: [1]
    };
  },
  methods: {
    // 确定
    handleOk: function handleOk(v) {
      this.$emit("changeSE", v);
      this.$emit("update:visible", false);
    }
  }
};