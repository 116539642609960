import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.includes.js";
import { getEmpSocCount, pageEmpSocInfo, getEnum as _getEnum, decEmpSocInfos, getListAllRelation, addSoc, setCardinality, deleteEmpSocInfos, remvoeZenyuanOk, remvoeJianyuanOk, changeEmpBatchRevocation, selectAllHead, bushou, getbushoudate, getMonthId, bujiao, listByCompanyIdGet, getChannels, selectSchemeByEmpSocInfoId, checkDecrease, deleteById, differenceOk as _differenceOk } from "@/api/shebao/shebaoInfo.js";
import { formatDate } from "@/utils/index";
import moment from "moment";
import { getDepartmentInTree, selectSchemeByEmpRelated, refunds } from "@/api/department/department";
import EmployeeSelect from "@/components/EmployeeSelect2/employeeSelect2";
import EmployeeSelect2 from "@/components/EmployeeSelect2/employeeSelect9";
import EmployeeSelect3 from "@/components/EmployeeSelect2/employeeSelect10";
import { AreaPicker as city } from "xqjr-plugin-common";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
import CountTo from "vue-count-to";
import axios from "axios";
import screen from "./components/screen";
import { socTypeSource } from "./funcs";
var mixin = new UploadMixin();
import _ from "lodash";
import SelectWithInputAdd from "xqjr-plugin-form/components/XqSelectWithInputAdd.vue";
var columnDialogName = [{
  title: "行号",
  dataIndex: "rowIndex",
  fixed: "left",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "姓名",
  dataIndex: "empName",
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "缴费类型",
  dataIndex: "payCostType",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "原因",
  dataIndex: "result",
  align: "center",
  ellipsis: true
}];
var columns = [{
  title: "姓名",
  dataIndex: "name",
  scopedSlots: {
    customRender: "name"
  },
  align: "center",
  fixed: "left",
  ellipsis: true,
  width: 120,
  isEdit: false,
  colDefault: true
}, {
  title: "手机号",
  dataIndex: "phone",
  scopedSlots: {
    customRender: "phone"
  },
  ellipsis: true,
  width: 120,
  colDefault: true
}, {
  title: "身份证号",
  dataIndex: "idCard",
  scopedSlots: {
    customRender: "idCard"
  },
  ellipsis: true,
  width: 200,
  colDefault: true
}, {
  title: "社保状态",
  dataIndex: "empSocStatus",
  align: "center",
  scopedSlots: {
    customRender: "empSocStatus"
  },
  ellipsis: true,
  width: 130,
  colDefault: true
}, {
  title: "医保状态",
  dataIndex: "empMeStatus",
  align: "center",
  scopedSlots: {
    customRender: "empMeStatus"
  },
  ellipsis: true,
  width: 130,
  colDefault: true
}, {
  title: "公积金状态",
  dataIndex: "empPfStatus",
  align: "center",
  scopedSlots: {
    customRender: "empPfStatus"
  },
  ellipsis: true,
  width: 130,
  colDefault: true
}, {
  title: "户口类型",
  dataIndex: "regType",
  align: "center",
  scopedSlots: {
    customRender: "regType"
  },
  ellipsis: true,
  width: 100,
  colDefault: true
}, {
  title: "参保月份",
  dataIndex: "month",
  scopedSlots: {
    customRender: "month"
  },
  align: "center",
  ellipsis: true,
  width: 100,
  colDefault: true
}, {
  title: "缴费类型",
  dataIndex: "payCostType",
  scopedSlots: {
    customRender: "payCostType"
  },
  align: "center",
  ellipsis: true,
  width: 100,
  colDefault: true
}, {
  title: "参保方案",
  dataIndex: "progName",
  align: "center",
  ellipsis: true,
  width: 100,
  colDefault: true
}, {
  title: "参保城市",
  dataIndex: "socCityName",
  align: "center",
  ellipsis: true,
  width: 130,
  colDefault: true
}, {
  title: "社保账号",
  dataIndex: "socAccount",
  scopedSlots: {
    customRender: "socAccount"
  },
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "医保账号",
  dataIndex: "meAccount",
  scopedSlots: {
    customRender: "meAccount"
  },
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "公积金账号",
  dataIndex: "pfAccount",
  scopedSlots: {
    customRender: "pfAccount"
  },
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "签约主体",
  dataIndex: "contractSubject",
  scopedSlots: {
    customRender: "contractSubject"
  },
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "个人社保费",
  dataIndex: "personalSocAmount",
  align: "center",
  ellipsis: true,
  width: 105,
  colDefault: true
}, {
  title: "单位社保费",
  dataIndex: "companySocAmount",
  align: "center",
  ellipsis: true,
  width: 105,
  colDefault: true
}, {
  title: "个人公积金费",
  dataIndex: "personalPfAmount",
  align: "center",
  ellipsis: true,
  width: 150,
  colDefault: true
}, {
  title: "单位公积金费",
  dataIndex: "companyPfAmount",
  align: "center",
  ellipsis: true,
  width: 150,
  colDefault: true
}, {
  title: "服务费",
  dataIndex: "serviceCost",
  scopedSlots: {
    customRender: "serviceCost"
  },
  ellipsis: true,
  width: 90,
  colDefault: true
}, {
  title: "滞纳金",
  dataIndex: "socialSecurityFine",
  scopedSlots: {
    customRender: "socialSecurityFine"
  },
  ellipsis: true,
  width: 90,
  colDefault: false
}, {
  title: "退费开始时间",
  dataIndex: "refundsBeginTime",
  scopedSlots: {
    customRender: "refundsBeginTime"
  },
  ellipsis: true,
  width: 120,
  colDefault: false
}, {
  title: "退费结束时间",
  dataIndex: "refundsEndTime",
  scopedSlots: {
    customRender: "refundsEndTime"
  },
  ellipsis: true,
  width: 120,
  colDefault: false
}, {
  title: "退费类型",
  dataIndex: "refundsType",
  scopedSlots: {
    customRender: "refundsType"
  },
  ellipsis: true,
  width: 120,
  colDefault: false
}, {
  title: "渠道",
  dataIndex: "channelName",
  scopedSlots: {
    customRender: "channelName"
  },
  ellipsis: true,
  width: 120,
  colDefault: false
}, {
  title: "标签",
  dataIndex: "tag",
  scopedSlots: {
    customRender: "tag"
  },
  ellipsis: true,
  width: 120,
  colDefault: false
}, {
  title: "备注",
  dataIndex: "remark",
  scopedSlots: {
    customRender: "remark"
  },
  ellipsis: true,
  width: 120,
  colDefault: false
}, {
  title: "操作",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  },
  fixed: "right",
  width: 80,
  ellipsis: true
}];
var columnDialogDetailJianYuan = [{
  title: "姓名",
  dataIndex: "empName",
  fixed: "left",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "社保",
  dataIndex: "socDescription",
  scopedSlots: {
    customRender: "socDescription"
  },
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "医保",
  dataIndex: "meDescription",
  align: "center",
  scopedSlots: {
    customRender: "meDescription"
  },
  ellipsis: true,
  width: 100
}, {
  title: "公积金",
  dataIndex: "pfDescription",
  align: "center",
  scopedSlots: {
    customRender: "pfDescription"
  },
  ellipsis: true,
  width: 100
}];
var columnDialogDetail = [{
  title: "所属时间",
  dataIndex: "belongsDate",
  scopedSlots: {
    customRender: "belongsDate"
  },
  fixed: "left",
  align: "center",
  ellipsis: true,
  width: 120
}, {
  title: "个人缴纳基数",
  dataIndex: "cardinalityDefault",
  align: "center",
  ellipsis: true,
  width: 120
}, {
  title: "险种类型",
  dataIndex: "itemName",
  align: "center",
  ellipsis: true,
  width: 110
}, {
  title: "单位缴纳比例",
  dataIndex: "persentCompany",
  scopedSlots: {
    customRender: "persentCompany"
  },
  align: "center",
  ellipsis: true,
  width: 120
}, {
  title: "单位金额",
  dataIndex: "amountCompany",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "个人缴纳比例",
  dataIndex: "persentPersonal",
  scopedSlots: {
    customRender: "persentPersonal"
  },
  align: "center",
  ellipsis: true,
  width: 120
}, {
  title: "个人金额",
  dataIndex: "amountPersonal",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "合计",
  dataIndex: "allAmount",
  align: "center",
  ellipsis: true,
  width: 80
}];
var columnDialogDetailguding = [{
  title: "所属时间",
  dataIndex: "belongsDate",
  scopedSlots: {
    customRender: "belongsDate"
  },
  fixed: "left",
  align: "center",
  ellipsis: true,
  width: 120
}, {
  title: "个人缴纳基数",
  dataIndex: "cardinalityDefault",
  align: "center",
  ellipsis: true,
  width: 120
}, {
  title: "险种类型",
  dataIndex: "itemName",
  align: "center",
  ellipsis: true,
  width: 110
}, {
  title: "单位金额",
  dataIndex: "amountCompany",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "个人金额",
  dataIndex: "amountPersonal",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "合计",
  dataIndex: "allAmount",
  align: "center",
  ellipsis: true,
  width: 80
}];
export default {
  mixins: [mixin],
  components: {
    SelectWithInputAdd: SelectWithInputAdd,
    EmployeeSelect: EmployeeSelect,
    EmployeeSelect2: EmployeeSelect2,
    EmployeeSelect3: EmployeeSelect3,
    city: city,
    CountTo: CountTo,
    screen: screen
  },
  data: function data() {
    var _this = this;
    return {
      socTypeSource: socTypeSource,
      columns: columns,
      showTable: true,
      labelList: [],
      channelNames: [],
      zhanajin: 0,
      recordList: [],
      isChooseStaff4: false,
      userId: "",
      renliStatus: 0,
      // 0拖拽，1成功，2失败
      renliStatusFail: 0,
      // 0失败，1重复
      headVisible: false,
      yibaoStatus: 0,
      // 0拖拽，1成功，2失败
      yibaoStatusFail: 0,
      // 0失败，1重复

      gongjijinStatus: 0,
      // 0拖拽，1成功，2失败
      gongjijinStatusFail: 0,
      // 0失败，1重复
      isTuifei: false,
      tuifeiType: 1,
      looknames: false,
      // 失败人员名单
      serviceCost: 0,
      shebaostartyear: "",
      shebaostartmonth: "",
      shebaoStartDate: 0,
      xiangqing: false,
      shebaoendyear: "",
      shebaoendmonth: "",
      shebaoEndDate: 0,
      personAmount: 0,
      companyAmount: 0,
      defaultFeiyong: 1,
      FeiyongList: [{
        id: 1,
        name: "全部个人缴纳"
      }, {
        id: 2,
        name: "全部单位缴纳"
      }, {
        id: 3,
        name: "正常参保方案缴纳"
      }],
      dataDetail3: [],
      shebaoErro: [],
      gongjijinErro: [],
      yibaoErro: [],
      defaultFeiyongtest: 1,
      FeiyongtestList: [{
        id: 1,
        name: "逾期补缴"
      }, {
        id: 2,
        name: "少缴补缴"
      }],
      tempId: "",
      defaultFangan: 0,
      action: "",
      ExportVisible: false,
      confirmLoading: false,
      defaultSelectedKeys: [],
      checkedKey: {
        checkedKeys: [],
        rightData: []
      },
      selectAllHeadData: [],
      // 导出表头信息
      isDaoru: false,
      topData: [{
        title: "在保人数",
        num: 0,
        empIdList: [],
        url: require("@/assets/shebao/全部.png")
      }, {
        title: "正常参保人数",
        num: 0,
        empIdList: [],
        url: require("@/assets/shebao/正常.png")
      }, {
        title: "增员人数",
        num: 0,
        empIdList: [],
        url: require("@/assets/shebao/停保.png")
      }, {
        title: "减员人数",
        num: 0,
        empIdList: [],
        url: require("@/assets/shebao/正常参保.png")
      }],
      shouDongBuChaVisible: false,
      typeValueTime: 1,
      lastDt: [],
      dateList: [],
      timeList: [],
      dateOpen: false,
      cutYear: "",
      companyDifference: undefined,
      personalDifference: undefined,
      nameDifference: undefined,
      cutMonth: "",
      depId: "",
      typeValue: 1,
      searchName: null,
      // columnStopDetail,
      tempEmpSocStatus: 0,
      isExistName: false,
      empcompanyPfAmount: "",
      empcompanySocAmount: "",
      emppersonalPfAmount: "",
      emppersonalSocAmount: "",
      isUndoShebao: false,
      isUndoShebao2: false,
      tuiFeiVisible: false,
      singleUndoShebao: true,
      isShowShebaoInfo: false,
      progNameList: [],
      dataDetail4: [],
      socPayCostTypeMap: [],
      shebaoDetailList: [],
      isCloseMethod: false,
      isSetting: false,
      columnDialogName: columnDialogName,
      columnDialogDetailguding: columnDialogDetailguding,
      clumnDetailGuding: false,
      columnDialogDetailJianYuan: columnDialogDetailJianYuan,
      dataDetail2: [],
      // isStaffInfo: false,
      depInfoOptions: [],
      isChooseStaff: false,
      isChooseStaff2: false,
      isChooseStaff3: false,
      monthFormat: "YYYY-MM",
      dataDetail: [],
      dataStopDetail: [],
      topStatus: "",
      empSocStatus: undefined,
      empMeStatus: undefined,
      empPfStatus: undefined,
      socCityName: "",
      existName: [],
      socPayCostType: undefined,
      // existEmpId: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171".concat(total, "\u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.pagination.current = current;
          _this.pagination.defaultCurrent = pageSize;
        }
      },
      pagination2: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171".concat(total, "\u6761");
        },
        showQuickJumper: true
        // showSizeChanger: true
      },

      // currType: "total",
      searchForm: {},
      isStopShebao: false,
      isShebaoBase: true,
      isGongJJBase: true,
      isEmpy: false,
      differenceVisible: false,
      socRefunds: false,
      meRefunds: false,
      pfRefunds: false,
      empSocInfoCount: "",
      allCount: "",
      monthIncrement: "",
      columnDialogDetail: columnDialogDetail,
      monthStop: "",
      personalSocAmount: 0,
      companySocAmount: 0,
      personalPfAmount: 0,
      companyPfAmount: 0,
      serviceCostAmount: 0,
      bushoubujiaook: false,
      accumulationFundFine: 0,
      socialSecurityFine: 0,
      totalAmount: 0,
      personalDifferenceAmount: 0,
      companyDifferenceAmount: 0,
      jianyuanItem: undefined,
      bushouTable: false,
      bushoubujiao1: false,
      progName: "",
      monthYear: "",
      selectState: 0,
      selectState2: 0,
      id: "",
      monthNext: "",
      monthPrevious: "",
      monthCurrent: "",
      year: "",
      month: "",
      progId: "",
      // 方案id
      progIdser: undefined,
      staffList: [],
      formData: [],
      tags: [],
      selectedRowKeys: [],
      // 多选
      defaultCard: 0,
      // 社保设置全部的基数
      defaultGongjiJin: 0,
      // 公积金基数
      // socProgAddDTO: [],
      evName: "",
      evIdCard: "",
      evDepName: "",
      evsocCityName: "",
      evprogName: "",
      evprogId: "",
      evpfCard: "",
      socCard: "",
      bushouTypeValue: 1,
      empId: "",
      progKeyId: "",
      keyId: "",
      // allowAdd: false,
      empPhoto: "",
      hasGongjijin: true,
      socCity: "",
      Dvisible: false,
      currentDetail: null,
      databushou: [],
      regType: undefined,
      channelId: undefined,
      currentItem: undefined,
      meFile: undefined,
      pfFile: undefined,
      socFile: undefined,
      isChooseStaffLeave: false,
      empMonthList: [],
      removeZengVisible: false,
      payProvidentFund: false,
      // 是否缴纳公积金
      onlySocAmount: false,
      // 仅设置参保金额
      active: 0,
      progFilterList: [],
      progTuiFeiFilterList: [],
      params: {
        templateName: "batchImportSocTemplate.xlsx"
      },
      url: "/hr/api/v1/excel/template/download",
      excelName: "社保公积金统计表.xlsx",
      inApproval: false,
      // 是否在审批中
      monthWhetherCurrent: true,
      // 是否是当前月
      liststatus: [{
        value: "待入职",
        id: "1"
      }, {
        value: "在职",
        id: "2"
      }, {
        value: "待离职",
        id: "3"
      }, {
        value: "离职",
        id: "4"
      }],
      rStatus: undefined,
      selectedHead: [],
      jianShebao: false,
      jianYibao: false,
      jianGjj: false,
      jianShebao_2: true,
      jianYibao_2: true,
      jianGjj_2: true,
      stopyear: "",
      stopmonth: "",
      stopTuiFeiyear: "",
      stopTuiFeimonth: "",
      stopTuiFeiyear2: "",
      stopTuiFeimonth2: "",
      JYserviceCost: undefined,
      stopYByear: "",
      stopYBmonth: "",
      empIdListDiff: [],
      stopGjyear: "",
      stopGjmonth: "",
      Lvisible: false,
      drag: false,
      tempDate: "",
      selectionBtnLock: false,
      dparams: {},
      socAccount: null,
      meAccount: null,
      pfAccount: null,
      contractSubject: null
    };
  },
  computed: {
    tableHeadParams: function tableHeadParams() {
      return {
        subjectId: this.$store.state.hr.companyId,
        "orders[0].property": "sort",
        "orders[0].desc": false,
        moduleCode: "shebao4",
        monthId: this.monthCurrent
      };
    }
  },
  watch: {
    socAccount: function socAccount(v) {
      if (v) {
        this.dparams.socAccount = v;
      } else {
        delete this.dparams.socAccount;
      }
    },
    meAccount: function meAccount(v) {
      if (v) {
        this.dparams.meAccount = v;
      } else {
        delete this.dparams.meAccount;
      }
    },
    pfAccount: function pfAccount(v) {
      if (v) {
        this.dparams.pfAccount = v;
      } else {
        delete this.dparams.pfAccount;
      }
    },
    contractSubject: function contractSubject(v) {
      if (v) {
        this.dparams.contractSubject = v;
      } else {
        delete this.dparams.contractSubject;
      }
    },
    selectedRowKeys: function selectedRowKeys(v) {
      if (v.length > 0) this.selectionBtnLock = true;
    },
    channelId: function channelId(v) {
      if (v) {
        this.dparams.channelId = v;
      } else {
        delete this.dparams.channelId;
      }
    },
    tags: function tags(v) {
      if (v) {
        if (v.join(",")) {
          this.dparams.tag = v.join(",");
        } else {
          delete this.dparams.tag;
        }
      } else {
        delete this.dparams.tag;
      }
    },
    regType: function regType(v) {
      if (v) {
        this.dparams.regType = v;
      } else {
        delete this.dparams.regType;
      }
    },
    searchName: function searchName(v) {
      if (v) {
        this.dparams.name = v;
      } else {
        delete this.dparams.name;
      }
    },
    progIdser: function progIdser(v) {
      if (v) {
        this.dparams.socProgId = v;
      } else {
        delete this.dparams.socProgId;
      }
    },
    socPayCostType: function socPayCostType(v) {
      if (v) {
        this.dparams.socPayCostType = v;
      } else {
        delete this.dparams.socPayCostType;
      }
    },
    rStatus: function rStatus(v) {
      if (v) {
        this.dparams.status = v;
      } else {
        delete this.dparams.status;
      }
    },
    empSocStatus: function empSocStatus(v) {
      if (v) {
        this.dparams.empSocStatus = v;
      } else {
        delete this.dparams.empSocStatus;
      }
    },
    empMeStatus: function empMeStatus(v) {
      if (v) {
        this.dparams.empMeStatus = v;
      } else {
        delete this.dparams.empMeStatus;
      }
    },
    empPfStatus: function empPfStatus(v) {
      if (v) {
        this.dparams.empPfStatus = v;
      } else {
        delete this.dparams.empPfStatus;
      }
    }
  },
  created: function created() {
    var _this2 = this;
    if (JSON.stringify(this.$getCondition("cbgl_condition")) !== "{}") {
      this.dparams = JSON.parse(this.$getCondition("cbgl_condition"));
      if (this.dparams.companyIdc && this.dparams.companyIdc === this.$store.state.hr.companyId) {
        if (this.dparams.channelId) {
          this.channelId = this.dparams.channelId;
        }
        if (this.dparams.tag) {
          this.tags = this.dparams.tag.split(",");
        }
        if (this.dparams.regType) {
          this.regType = this.dparams.regType;
        }
        if (this.dparams.month) {
          this.month = this.dparams.month;
        }
        if (this.dparams.year) {
          this.year = this.dparams.year;
        }
        if (this.dparams.name) {
          this.searchName = this.dparams.name;
        }
        if (this.dparams.socCity) {
          this.socCityName = this.dparams.socCity;
        }
        if (this.dparams.depId) {
          this.depId = this.dparams.depId;
        }
        if (this.dparams.socProgId) {
          this.progIdser = this.dparams.socProgId;
        }
        if (this.dparams.monthId) {
          this.monthCurrent = this.dparams.monthId;
        }
        if (this.dparams.socPayCostType) {
          this.socPayCostType = this.dparams.socPayCostType;
        }
        if (this.dparams.status) {
          this.rStatus = this.dparams.status;
        }
        if (this.dparams.topStatus) {
          this.active = this.dparams.topStatus === 4 ? 3 : this.dparams.topStatus;
        }
        // 读取缓存
        if (this.$getPage("cbgl_condition")) {
          this.pagination.current = parseInt(this.$getPage("cbgl_condition")) === 0 ? 1 : parseInt(this.$getPage("cbgl_condition"));
        }
        if (this.$getPageSize("cbgl_condition")) {
          this.pagination.pageSize = parseInt(this.$getPageSize("cbgl_condition")) === 0 ? 10 : parseInt(this.$getPageSize("cbgl_condition"));
        }
      }
    }
    getDepartmentInTree({
      companyId: this.$store.state.hr.companyId
    }).then(function (res) {
      _this2.depInfoOptions = _this2.tree1(res.data, "depChildList", false);
    });
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.year = this.$route.query.year;
      this.month = this.$route.query.month;
      this.dparams.month = this.month;
      this.dparams.year = this.year;
      getMonthId({
        companyId: this.$store.state.hr.companyId,
        month: this.month,
        year: this.year
      }).then(function (res) {
        _this2.monthNext = res.data.monthIdNext;
        _this2.monthPrevious = res.data.monthIdPrevious;
        _this2.monthCurrent = res.data.monthCurrent;
        _this2.dparams.monthId = res.data.monthCurrent;
        _this2.inApproval = res.data.monthCurrentCanNotEdited;
        _this2.monthWhetherCurrent = res.data.monthWhetherCurrent;
        _this2.checkPeopleAmount(true);
      });
    } else {
      var _nowDate = new Date();
      this.year = "" + _nowDate.getFullYear();
      this.month = _nowDate.getMonth() + 1;
      if (this.month < 10) {
        this.month = "0" + this.month;
      }
      this.dparams.month = this.month;
      this.dparams.year = this.year;
    }
    var nowDate = new Date();
    this.stopyear = "" + nowDate.getFullYear();
    this.stopmonth = this.month;
    this.stopYByear = "" + nowDate.getFullYear();
    this.stopYBmonth = this.month;
    this.stopGjyear = "" + nowDate.getFullYear();
    this.stopGjmonth = this.month;
    this.stopTuiFeiyear = "" + nowDate.getFullYear();
    this.stopTuiFeimonth = this.month;
    this.stopTuiFeiyear2 = "" + nowDate.getFullYear();
    this.stopTuiFeimonth2 = this.month;
    this.searchShebaoInfo();
    this.checkShebaoMethod();
    this.getEnum();
    this.getListByCompanyId();
    this.getChannelsList();
  },
  mounted: function mounted() {},
  methods: {
    differenceOk: function differenceOk() {
      var _this3 = this;
      if (!this.nameDifference) {
        this.$message.warning("请填写差额费用名称");
      } else {
        _differenceOk({
          companyId: this.$store.state.hr.companyId,
          empIdList: this.empIdListDiff,
          differenceName: this.nameDifference,
          personalDifference: this.personalDifference,
          companyDifference: this.companyDifference,
          monthId: this.monthCurrent
        }).then(function (res) {
          _this3.$refs.tbody.init();
          _this3.$message.success("更新成功");
          _this3.checkPeopleAmount(false);
          _this3.searchShebaoInfo();
          _this3.differenceVisible = false;
        }).catch(function (err) {
          console.log(err);
          _this3.differenceVisible = false;
        });
      }
    },
    handlePageChange2: function handlePageChange2(pagination, filters, sorter) {
      this.pagination2.current = pagination.current;
      this.pagination2.pageSize = pagination.pageSize;
    },
    getTuiTime: function getTuiTime(txt) {
      if (txt) {
        return moment(txt).format(this.monthFormat);
      } else {
        return "--";
      }
    },
    onRadioChange: function onRadioChange(e) {
      this.tuifeiType = e.target.value;
      this.getFeiYong();
    },
    // 禁止拖动到id为1的对象
    onMove: function onMove(e) {
      if (e.relatedContext.element.title === "姓名") return false;
      return true;
    },
    // 开始拖拽事件
    onStart: function onStart() {
      this.drag = true;
    },
    // 拖拽结束事件
    onEnd: function onEnd() {
      this.drag = false;
    },
    cleanSelects: function cleanSelects() {
      this.selectedRowKeys = [];
    },
    TBFclose: function TBFclose() {
      this.selectedRowKeys = [];
      this.selectionBtnLock = false;
    },
    gotoZengyuan: function gotoZengyuan() {
      this.isEmpy = false;
      this.$router.push({
        path: "/shebao/shebaoManage/shebaoZengJianY"
      });
    },
    LhandleOk: function LhandleOk() {
      this.Lvisible = false;
    },
    disabledDatejian: function disabledDatejian(current) {
      // 只能选择当月往后的月份
      return current && current < moment().startOf("month"); // 月份第一天
    },
    onStopYearMonthChange_Tuifei: function onStopYearMonthChange_Tuifei(date, dateString) {
      var s = dateString.split("-");
      this.stopTuiFeiyear = s[0];
      this.stopTuiFeimonth = s[1];
      this.getFeiYong();
    },
    onStopYearMonthChange_Tuifei2: function onStopYearMonthChange_Tuifei2(date, dateString) {
      var s = dateString.split("-");
      this.stopTuiFeiyear2 = s[0];
      this.stopTuiFeimonth2 = s[1];
      this.getFeiYong();
    },
    getFeiYong: function getFeiYong() {
      this.personAmount = 0;
      this.companyAmount = 0;
      if (!this.onlySocAmount) {
        for (var i = 0; i < this.formData.length; i++) {
          if (this.tuifeiType === 1 && this.formData[i].itemType === 1) {
            this.personAmount += this.formData[i].cardinalityDefault * this.formData[i].persentPersonal * 0.01;
            this.companyAmount += this.formData[i].cardinalityDefault * this.formData[i].persentCompany * 0.01;
          } else if (this.tuifeiType === 2 && this.formData[i].itemType === 3) {
            this.personAmount += this.formData[i].cardinalityDefault * this.formData[i].persentPersonal * 0.01;
            this.companyAmount += this.formData[i].cardinalityDefault * this.formData[i].persentCompany * 0.01;
          } else if (this.tuifeiType === 3 && this.formData[i].itemType === 2) {
            this.personAmount += this.formData[i].cardinalityDefault * this.formData[i].persentPersonal * 0.01;
            this.companyAmount += this.formData[i].cardinalityDefault * this.formData[i].persentCompany * 0.01;
          }
        }
        var t1 = moment(this.stopTuiFeiyear + "-" + this.stopTuiFeimonth, this.monthFormat);
        var t2 = moment(this.stopTuiFeiyear2 + "-" + this.stopTuiFeimonth2, this.monthFormat);
        this.personAmount = this.personAmount * (t2.diff(t1, "month") + 1);
        this.companyAmount = this.companyAmount * (t2.diff(t1, "month") + 1);
        if (this.defaultFangan) {
          for (i = 0; i < this.progTuiFeiFilterList.length; i++) {
            if (this.progTuiFeiFilterList[i].id === this.defaultFangan) {
              this.personAmount = this.calculationDot(this.progTuiFeiFilterList[i].socProgItemVOList[0].mantissaProcPersonal, NP.strip(this.personAmount));
              this.companyAmount = this.calculationDot(this.progTuiFeiFilterList[i].socProgItemVOList[0].mantissaProcCompany, NP.strip(this.companyAmount));
              break;
            }
          }
        }
      } else {
        for (var j = 0; j < this.formData.length; j++) {
          if (this.tuifeiType === 1 && this.formData[j].itemType === 1) {
            this.personAmount += this.formData[j].amountPersonal;
            this.companyAmount += this.formData[j].amountCompany;
          } else if (this.tuifeiType === 2 && this.formData[j].itemType === 3) {
            this.personAmount += this.formData[j].amountPersonal;
            this.companyAmount += this.formData[j].amountCompany;
          } else if (this.tuifeiType === 3 && this.formData[j].itemType === 2) {
            this.personAmount += this.formData[j].amountPersonal;
            this.companyAmount += this.formData[j].amountCompany;
          }
        }
        var _t = moment(this.stopTuiFeiyear + "-" + this.stopTuiFeimonth, this.monthFormat);
        var _t2 = moment(this.stopTuiFeiyear2 + "-" + this.stopTuiFeimonth2, this.monthFormat);
        this.personAmount = this.personAmount * (_t2.diff(_t, "month") + 1);
        this.companyAmount = this.companyAmount * (_t2.diff(_t, "month") + 1);
        if (this.defaultFangan) {
          for (i = 0; i < this.progTuiFeiFilterList.length; i++) {
            if (this.progTuiFeiFilterList[i].id === this.defaultFangan) {
              this.personAmount = this.calculationDot(this.progTuiFeiFilterList[i].socProgItemVOList[0].mantissaProcPersonal, NP.strip(this.personAmount));
              this.companyAmount = this.calculationDot(this.progTuiFeiFilterList[i].socProgItemVOList[0].mantissaProcCompany, NP.strip(this.companyAmount));
            }
          }
        }
      }
    },
    // 计算小数点
    calculationDot: function calculationDot(type, value) {
      console.log(type + "/" + value);
      switch (type) {
        case 0:
          // 不处理
          var s = value.toString().split(".");
          if (s.length === 1) {
            return s + ".00";
          } else {
            if (s[1].length === 1) {
              s[1] = s[1] + "0";
            }
            return s[0] + "." + s[1];
          }
        case 1:
          // 四舍五入到元
          return this.siWuDot(value, 0);
        case 2:
          // 四舍五入到角
          return this.siWuDot(value, 1);
        case 3:
          // 四舍五入到分
          return this.siWuDot(value, 2);
        case 4:
          // 四舍五入到厘
          return this.siWuDot(value, 3);
        case 5:
          // 四舍五入到小数点后4位
          return this.siWuDot(value, 4);
        case 6:
          // 见厘进分
          return this.jieQueDot(value, 2);
        case 7:
          // 见分进角
          return this.jieQueDot(value, 1);
        case 8:
          // 见角进元
          return this.jieQueDot(value, 0);
        case 9:
          // 见角舍去
          return this.sheQuDot(value, 0);
        case 10:
          // 见分舍去
          return this.sheQuDot(value, 1);
        case 11:
          // 见厘舍去
          return this.sheQuDot(value, 2);
      }
    },
    // 四舍五入
    siWuDot: function siWuDot(value, n) {
      var f = Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
      var s = f.toString();
      var rs = s.indexOf(".");
      if (n !== 0 && rs < 0) {
        s += ".";
      }
      for (var i = s.length - s.indexOf("."); i <= n; i++) {
        s += "0";
      }
      if (n === 0) {
        s += ".00";
      }
      return s;
    },
    // 进1截取
    jieQueDot: function jieQueDot(value, n) {
      var f = value.toString();
      var s = f.split(".");
      if (s.length === 1) {
        return s + ".00";
      } else {
        if (n !== 0) {
          if (s[1].length > n) {
            var _n = Number(s[1].substring(0, _n));
            var n2 = _n + 1;
            s[1] = n2.toString();
          } else if (s[1].length < n) {
            var c = n - s[1].length;
            for (var i = 0; i < c; i++) {
              s[1] += "0";
            }
          }
          return s[0] + "." + s[1];
        } else {
          var _n2 = Number(s[0]) + 1;
          return _n2 + ".00";
        }
      }
    },
    // 直接舍去
    sheQuDot: function sheQuDot(value, n) {
      var f = value.toString();
      var s = f.split(".");
      if (s.length === 1) {
        return s + ".00";
      } else {
        if (n !== 0) {
          if (s[1].length > n) {
            s[1] = s[1].substring(0, n);
          } else if (s[1].length < n) {
            var c = n - s[1].length;
            for (var i = 0; i < c; i++) {
              s[1] += "0";
            }
          }
          return s[0] + "." + s[1];
        } else {
          return s[0] + ".00";
        }
      }
    },
    // 停供社保
    onStopYearMonthChange: function onStopYearMonthChange(date, dateString) {
      var s = dateString.split("-");
      this.stopyear = s[0];
      this.stopmonth = s[1];
    },
    onStopYBYearMonthChange: function onStopYBYearMonthChange(date, dateString) {
      var s = dateString.split("-");
      this.stopYByear = s[0];
      this.stopYBmonth = s[1];
    },
    // 封存公积金
    onStopGJYearMonthChange: function onStopGJYearMonthChange(date, dateString) {
      var s = dateString.split("-");
      this.stopGjyear = s[0];
      this.stopGjmonth = s[1];
    },
    onTypejianChange1: function onTypejianChange1(e) {
      this.jianShebao = e.target.checked;
    },
    onTypejianChange2: function onTypejianChange2(e) {
      this.jianYibao = e.target.checked;
    },
    onTypejianChange3: function onTypejianChange3(e) {
      this.jianGjj = e.target.checked;
    },
    openDetail: function openDetail(detail) {
      this.currentDetail = detail;
      this.xiangqing = true;
    },
    getChannelsList: function getChannelsList() {
      var _this4 = this;
      getChannels({
        pageNo: 1,
        pageSize: 1000
      }).then(function (res) {
        _this4.channelNames = res.data.entities;
      });
    },
    getListByCompanyId: function getListByCompanyId() {
      var _this5 = this;
      listByCompanyIdGet({
        companyId: this.$store.state.hr.companyId
      }).then(function (_ref) {
        var data = _ref.data;
        _this5.labelList = data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 生成回款
    addBatchRecordList: function addBatchRecordList() {
      this.isChooseStaff = true;
      this.selectState = 1;
    },
    backFeiyong: function backFeiyong() {
      this.isChooseStaff3 = true;
    },
    BatchFix: function BatchFix() {
      this.$router.push({
        path: "/shebao/shebaoManage/BatchFix"
      });
    },
    onChangeZhiNajin: function onChangeZhiNajin(value) {
      console.log(this.zhanajin);
      if (this.bushouTypeValue === 1) {
        // 社保
      } else if (this.bushouTypeValue === 2) {
        // 公积金
      }
    },
    getDefaultCard: function getDefaultCard() {
      var value = 0;
      var same = true;
      for (var i = 0; i < this.formData.length; i++) {
        if (value !== 0) {
          var v = this.onlySocAmount ? this.formData[i].cardinalityDefaultAmount : this.formData[i].cardinalityDefault;
          if (v !== value) {
            same = false;
            break;
          }
        } else {
          value = this.onlySocAmount ? this.formData[i].cardinalityDefaultAmount : this.formData[i].cardinalityDefault;
        }
      }
      if (same) {
        this.defaultCard = value;
      } else {
        this.editBaseInfo();
      }
    },
    shouDongBuChaOkHandel: function shouDongBuChaOkHandel() {
      this.shouDongBuChaVisible = false;
      // 下一步是否是批量
    },
    tranDate: function tranDate(item) {
      return moment(item).format("YYYY-MM");
    },
    onTypeChangeValue: function onTypeChangeValue(e) {
      this.typeValueTime = e.target.value;
    },
    onTopTabs: function onTopTabs(index) {
      this.active = index;
      if (this.active !== 0) {
        switch (this.active) {
          case 1:
            this.dparams.topStatus = 1;
            break;
          case 2:
            this.dparams.topStatus = 2;
            break;
          case 3:
            this.dparams.topStatus = 4;
            break;
        }
      } else {
        delete this.dparams.topStatus;
      }
      this.pagination.current = 1;
      this.checkPeopleAmount();
    },
    customExpandIcon: function customExpandIcon(props) {
      var h = this.$createElement;
      if (props.expanded) {
        return h("a", {
          "on": {
            "click": function click(e) {
              props.onExpand(props.record, e);
            }
          }
        }, ["\u6536\u8D77"]);
      } else {
        return h("a", {
          "on": {
            "click": function click(e) {
              props.onExpand(props.record, e);
            }
          }
        }, ["\u5C55\u5F00"]);
      }
    },
    ExportHandle: function ExportHandle() {
      this.beforeUpload();
    },
    closeLookNms: function closeLookNms() {
      this.looknames = false;
      this.isDaoru = true;
    },
    renliClick: function renliClick(index) {
      switch (index) {
        case 1:
          // 社保
          this.dataDetail3 = this.shebaoErro;
          break;
        case 2:
          // 医保
          this.dataDetail3 = this.yibaoErro;
          break;
        case 3:
          // 公积金
          this.dataDetail3 = this.gongjijinErro;
          break;
      }
      this.looknames = true;
      this.isDaoru = false;
    },
    huamingce: function huamingce() {
      this.$router.push({
        path: "/shebao/employee"
      });
    },
    //
    disabledDateTuifei2: function disabledDateTuifei2(current) {
      var startValue = moment(this.stopTuiFeiyear2 + "-" + this.stopTuiFeimonth2, this.monthFormat);
      if (!current || !startValue) {
        return false;
      }
      return startValue.valueOf() < current.valueOf();
    },
    disabledDateTuiFei3: function disabledDateTuiFei3(current) {
      var startValue = moment(this.stopTuiFeiyear + "-" + this.stopTuiFeimonth, this.monthFormat);
      if (!current || !startValue) {
        return false;
      }
      return startValue.valueOf() > current.valueOf();
    },
    // 减员停保日期
    disabledDate2: function disabledDate2(current) {
      return current && current <= moment().subtract(1, "months");
    },
    disabledDate3: function disabledDate3(current) {
      return current && (current < moment().startOf("month") || current > moment().endOf("month")); // 月份第一天
    },
    disabledDate: function disabledDate(current) {
      return current && current >= moment().month(moment().month() - 1).endOf("day");
    },
    gotoEdit: function gotoEdit() {
      this.bushoubujiao1 = false;
      this.$router.push({
        path: "/shebao/shebaoMethod/editMethod",
        query: {
          id: this.progId
        }
      });
    },
    bushoujiaoHandle: function bushoujiaoHandle(text) {
      var _this6 = this;
      if (this.inApproval) {
        this.$message.success("账单已生成，不允许操作");
        return;
      }
      var data = {
        companyId: this.$store.state.hr.companyId,
        empId: this.staffList[0].id
      };
      this.databushou = [];
      getbushoudate(data).then(function (res) {
        if (res.code === 200) {
          res.data.map(function (item) {
            _this6.databushou.push(Number(item));
          });
        }
      });
      this.evprogId = text.progId;
      this.tempId = text.id;
      this.defaultCard = 0; // 社保设置全部的基数
      this.defaultGongjiJin = 0; // 公积金基数
      this.bushoubujiao1 = true;
      this.shebaostartyear = "";
      this.shebaostartmonth = "";
      this.shebaoendyear = "";
      this.shebaoendmonth = "";
      this.shebaoEndDate = 0;
      this.shebaoStartDate = 0;
      this.currentItem = text;
      if (this.currentItem) {
        this.formData = this.deepCopy(this.currentItem.progItemListVo);
      }
      this.zhanajin = 0;
      this.clumnDetailGuding = text.onlySocAmount;
      this.hasGongjijin = !text.payProvidentFund;
    },
    // 完成对话框社保编辑
    bushoubujiaof: function bushoubujiaof() {
      var _this7 = this;
      this.bushouTable = false;
      var data = {
        companyId: this.$store.state.hr.companyId,
        serviceCost: this.serviceCost,
        month: this.month,
        monthId: this.monthCurrent,
        payAttribution: this.defaultFeiyong
      };
      var empIds = [];
      for (var i = 0; i < this.staffList.length; i++) {
        empIds.push(this.staffList[i].id);
      }
      data.empIdList = empIds;
      if (this.bushouTypeValue === 1) {
        data.fillGatherStartDate = this.shebaoStartDate;
        data.fillGatherEndDate = this.shebaoEndDate;
        data.fine = this.zhanajin;
        data.socProgId = this.progId;
        data.empSocItemDOList = this.formData;
        bushou(data).then(function (res) {
          if (res.code === 200) {
            _this7.bushoubujiaook = true;
            _this7.checkPeopleAmount();
            _this7.searchShebaoInfo();
            _this7.checkShebaoMethod();
          }
        });
      } else if (this.bushouTypeValue === 2) {
        // 公积金补缴
        data.fillPaymentEndDate = this.shebaoEndDate;
        data.fillPaymentStartDate = this.shebaoStartDate;
        data.fine = this.zhanajin;
        data.socProgId = this.progId;
        data.empSocItemDOList = this.formData;
        bujiao(data).then(function (res) {
          if (res.code === 200) {
            _this7.bushoubujiaook = true;
            _this7.checkPeopleAmount();
            _this7.searchShebaoInfo();
            _this7.checkShebaoMethod();
          }
        });
      }
    },
    // 创建参保选择方案
    chooseType: function chooseType(value) {
      this.defaultFeiyong = value;
    },
    chooseTypetest: function chooseTypetest(value) {
      this.defaultFeiyongtest = value;
    },
    // 停供社保
    onShebaoStartChange: function onShebaoStartChange(date, dateString) {
      var s = dateString.split("-");
      this.shebaostartyear = s[0];
      this.shebaostartmonth = s[1];
      this.shebaoStartDate = Number(date);
      if (this.shebaoStartDate !== 0 && this.shebaoEndDate !== 0) {
        if (this.shebaoStartDate > this.shebaoEndDate) {
          this.$message.success("起止年月必须晚与终止年月");
        }
      }
    },
    onShebaoEndChange: function onShebaoEndChange(date, dateString) {
      var s = dateString.split("-");
      this.shebaoendyear = s[0];
      this.shebaoendmonth = s[1];
      this.shebaoEndDate = Number(date);
      if (this.shebaoStartDate !== 0 && this.shebaoEndDate !== 0) {
        if (this.shebaoStartDate > this.shebaoEndDate) {
          this.$message.success("起止年月必须晚与终止年月");
        }
      }
    },
    // 业务类型选择
    onSheBaoTypeChange: function onSheBaoTypeChange(e) {
      this.shebaostartyear = "";
      this.shebaostartmonth = "";
      this.shebaoendyear = "";
      this.shebaoendmonth = "";
      this.bushouTypeValue = e.target.value;
    },
    bushoubujiao1next: function bushoubujiao1next() {
      if (this.shebaoStartDate === 0 || this.shebaoEndDate === 0) {
        this.$message.success("请选择起始和终止日期");
        return;
      }
      if (this.shebaoStartDate >= this.shebaoEndDate) {
        this.$message.success("起止年月必须晚与终止年月");
        return;
      }
      this.bushoubujiao1 = false;
      this.bushouTable = true;
      this.bushoubujiaof();
    },
    prviewBushou: function prviewBushou() {
      this.bushoubujiao1 = true;
      this.bushouTable = false;
    },
    BushouBuJiaoOkHandle: function BushouBuJiaoOkHandle() {
      this.bushoubujiaook = false;
    },
    canbaoguanli: function canbaoguanli() {
      this.bushoubujiaook = false;
      this.goback();
    },
    // 业务类型选择
    onTypeChange: function onTypeChange(e) {
      this.typeValue = e.target.value;
    },
    DhandleOk: function DhandleOk() {
      this.Dvisible = false;
      this.finishEdit2();
    },
    finishEdit2: function finishEdit2() {
      var _this8 = this;
      // 减员

      var param2 = {
        companyId: this.$store.state.hr.companyId,
        empIdList: [this.jianyuanItem.empId],
        serviceCost: this.JYserviceCost
      };
      if (this.jianShebao) {
        param2.stopSoc = true;
        param2.planStopSocMonth = this.stopmonth;
        param2.planStopSocYear = this.stopyear;
      } else {
        param2.stopSoc = false;
      }
      if (this.jianYibao) {
        param2.stopMe = true;
        param2.planStopMeMonth = this.stopYBmonth;
        param2.planStopMeYear = this.stopYByear;
      } else {
        param2.stopMe = false;
      }
      if (this.jianGjj) {
        param2.stopPf = true;
        param2.sealedDateMonth = this.stopGjmonth;
        param2.sealedDateYear = this.stopGjyear;
      } else {
        param2.stopPf = false;
      }
      decEmpSocInfos(param2).then(function (res) {
        _this8.progId = "";
        if (res.code === 200) {
          _this8.Dvisible = false;
          _this8.Lvisible = true;
          _this8.dataDetail4 = res.data;
          _this8.checkPeopleAmount();
          _this8.searchShebaoInfo();
          _this8.checkShebaoMethod();
        }
      });
    },
    // 取消增员
    remvoeZenyuanOkHandel: function remvoeZenyuanOkHandel() {
      var _this9 = this;
      this.removeZengVisible = false;
      var that = this;
      var data = {
        empId: this.empId,
        empSocInfoId: this.tempId
      };
      if (this.tempEmpSocStatus === 2 || this.tempEmpSocStatus === 3) {
        // 取消增员
        remvoeZenyuanOk(data).then(function (res) {
          that.$message.success("取消增员成功");
          _this9.checkPeopleAmount();
        });
      } else if (this.tempEmpSocStatus === 4 || this.tempEmpSocStatus === 5) {
        if (this.typeValue === 1 || this.typeValue === "1") {
          data.isUpdate = true;
        } else {
          data.isUpdate = false;
        }

        // 取消减员
        remvoeJianyuanOk(data).then(function (res) {
          that.$message.success("取消减员成功");
          _this9.checkPeopleAmount();
        });
      }
    },
    onUpload: function onUpload() {},
    beforeUpload1: function beforeUpload1(_ref2) {
      var file = _ref2.file;
      this.socFile = file;
      console.log(this.socFile);
    },
    beforeUpload2: function beforeUpload2(_ref3) {
      var file = _ref3.file;
      this.meFile = file;
    },
    beforeUpload3: function beforeUpload3(_ref4) {
      var file = _ref4.file;
      this.pfFile = file;
    },
    // 预导入
    beforeUpload: function beforeUpload() {
      var _this10 = this;
      var url = "/hr/api/v1/empSocTransform/importSoc";
      var tdata = new FormData();
      tdata.append("meFile", this.meFile);
      tdata.append("monthId", this.monthCurrent);
      tdata.append("pfFile", this.pfFile);
      tdata.append("socFile", this.socFile);
      this.$request({
        url: url,
        method: "post",
        data: tdata
      }).then(function (_ref5) {
        var data = _ref5.data;
        if (data) {
          if (data.meImportResult != null) {
            if (data.meImportResult.failed === null || data.meImportResult.failed.length === 0) {
              // 成功
              // 医保成功
              _this10.yibaoStatus = 1;
            } else {
              // 失败
              // 医保失败
              _this10.yibaoStatus = 2;
              _this10.yibaoStatusFail = 0;
              _this10.yibaoErro = data.meImportResult.failed;
            }
          } else {
            // 没导入文件
            _this10.yibaoStatus = 0;
            _this10.yibaoStatusFail = 0;
          }
          if (data.pfImportResult != null) {
            if (data.pfImportResult.failed === null || data.pfImportResult.failed.length === 0) {
              // 公积金成功
              _this10.gongjijinStatus = 1;
            } else {
              // 公积金失败
              _this10.gongjijinStatus = 2;
              _this10.gongjijinStatusFail = 0;
              _this10.gongjijinErro = data.pfImportResult.failed;
            }
          } else {
            _this10.gongjijinStatus = 0;
            _this10.gongjijinStatusFail = 0;
          }
          if (data.socImportResult != null) {
            if (data.socImportResult.failed === null || data.socImportResult.failed.lenght === 0) {
              // 社保成功
              _this10.renliStatus = 1;
            } else {
              // 社保失败
              _this10.renliStatus = 2;
              _this10.renliStatusFail = 0;
              _this10.shebaoErro = data.socImportResult.failed;
            }
          } else {
            _this10.renliStatus = 0;
            _this10.renliStatusFail = 0;
          }
          // 刷新数据
          _this10.pagination.current = 1;
          _this10.checkPeopleAmount();
          _this10.searchShebaoInfo();
          _this10.checkShebaoMethod();
        }
      });
    },
    handleChange: function handleChange(info) {
      if (info.file.status === "done") {
        this.$message.success("".concat(info.file.name, " file uploaded successfully"));
      } else if (info.file.status === "error") {
        this.$message.error("".concat(info.file.name, " file upload failed."));
      }
    },
    getZhiNaJinCout: function getZhiNaJinCout(record) {
      if (!record.socialSecurityFine) record.socialSecurityFine = 0;
      if (!record.accumulationFundFine) record.accumulationFundFine = 0;
      return record.socialSecurityFine + record.accumulationFundFine;
    },
    getPostTypeNew: function getPostTypeNew(val) {
      return this.socTypeSource[val] ? this.socTypeSource[val]["label"] : "";
    },
    getPayCostType: function getPayCostType(vl) {
      switch (vl) {
        case 1:
          return "正常应缴";
        case 2:
          return "调整计划";
        case 3:
          return "补收/补缴";
        case 4:
          return "个人退收";
        case 7:
          return "退费";
      }
    },
    leftClick: function leftClick() {
      var _this11 = this;
      this.selectedRowKeys = [];
      if (this.monthPrevious.length === 0) {
        this.$message.success("暂无社保数据");
        return;
      }
      var month = Number(this.month) - 1;
      if (month === 0) {
        month = 12;
        this.year = Number(this.year) - 1 + "";
      }
      if (month < 10) {
        this.month = "0" + month;
      } else {
        this.month = "" + month;
      }
      this.pagination.current = 1;
      this.dparams.month = this.month;
      this.dparams.year = this.year;
      getMonthId({
        companyId: this.$store.state.hr.companyId,
        month: this.month,
        year: this.year
      }).then(function (res) {
        _this11.monthNext = res.data.monthIdNext;
        _this11.monthPrevious = res.data.monthIdPrevious;
        _this11.monthCurrent = res.data.monthCurrent;
        _this11.dparams.monthId = res.data.monthCurrent;
        _this11.inApproval = res.data.monthCurrentCanNotEdited;
        _this11.monthWhetherCurrent = res.data.monthWhetherCurrent;
        _this11.$nextTick(function () {
          _this11.$refs.tbody.init();
          _this11.searchShebaoInfo();
          _this11.checkPeopleAmount();
        });
      });
    },
    rightClick: function rightClick() {
      var _this12 = this;
      this.selectedRowKeys = [];
      if (this.monthNext.length === 0) {
        this.$message.success("暂无社保数据");
        return;
      }
      var month = Number(this.month) + 1;
      if (month > 12) {
        month = 1;
        this.year = Number(this.year) + 1 + "";
      }
      if (month < 10) {
        this.month = "0" + month;
      } else {
        this.month = "" + month;
      }
      this.pagination.current = 1;
      this.dparams.month = this.month;
      this.dparams.year = this.year;
      getMonthId({
        companyId: this.$store.state.hr.companyId,
        month: this.month,
        year: this.year
      }).then(function (res) {
        _this12.monthNext = res.data.monthIdNext;
        _this12.monthPrevious = res.data.monthIdPrevious;
        _this12.monthCurrent = res.data.monthCurrent;
        _this12.dparams.monthId = res.data.monthCurrent;
        _this12.inApproval = res.data.monthCurrentCanNotEdited;
        _this12.monthWhetherCurrent = res.data.monthWhetherCurrent;
        _this12.$nextTick(function () {
          _this12.$refs.tbody.init();
          _this12.searchShebaoInfo();
          _this12.checkPeopleAmount();
        });
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys, data) {
      var _this13 = this;
      this.recordList = [];
      this.selectedRowKeys = selectedRowKeys;
      data.forEach(function (item, index) {
        _this13.recordList.push({
          companyId: _this13.$store.state.hr.companyId,
          empId: item.empId,
          empSocInfoId: item.id,
          month: item.month,
          year: item.year,
          serviceCost: item.serviceCost,
          socAmount: item.personalSocAmount + item.companySocAmount + item.personalPfAmount + item.companyPfAmount
        });
      });
    },
    judgeDefault: function judgeDefault(item) {
      if (item.id === "1") return "--";
      if (item.cardinalityDefaultAmount !== null) {
        return item.cardinalityDefaultAmount;
      } else return item.cardinalityDefault;
    },
    // 树形图
    tree: function tree(array, childKey, onlyLastChild) {
      var _this14 = this;
      if (array === null || array.length < 1) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id;
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        if (item.leave !== 3) {
          item["disabled"] = onlyLastChild;
        }
        if (item.children) {
          _this14.tree1(item.children, childKey, onlyLastChild);
        }
      });
      return array;
    },
    tree1: function tree1(array, childKey, onlyLastChild) {
      var _this15 = this;
      if (array === null || array.length < 1) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id;
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        if (item.leave !== 3) {
          item["disabled"] = onlyLastChild;
        }
        if (item.children) {
          _this15.tree(item.children, childKey, onlyLastChild);
        }
      });
      return array;
    },
    // 停止参保
    deleteShebao: function deleteShebao() {
      var that = this;
      this.$confirm({
        title: "确认删除",
        content: "确认删除该月份账单？",
        onOk: function onOk() {
          var data = [that.id];
          deleteEmpSocInfos(data).then(function (res) {
            that.$router.go(-1);
          });
        }
      });
    },
    // 设置社保基数
    setAllContent: function setAllContent(val) {
      var _this16 = this;
      this.formData.map(function (item, index) {
        if (item.itemType === 1) {
          if (_this16.onlySocAmount) {
            item.cardinalityDefaultAmount = _this16.defaultCard;
          } else item.cardinalityDefault = _this16.defaultCard;
        }
      });
    },
    setGongJiJinContent: function setGongJiJinContent(val) {
      var _this17 = this;
      this.formData.map(function (item, index) {
        if (item.itemType === 2) {
          if (_this17.onlySocAmount) {
            item.cardinalityDefaultAmount = _this17.defaultGongjiJin;
          } else item.cardinalityDefault = _this17.defaultGongjiJin;
        }
      });
    },
    // 分页查询
    handlePageChange: function handlePageChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.checkPeopleAmount();
    },
    // 按员工姓名检索
    handleSearch: function handleSearch() {
      this.pagination.current = 1;
      this.checkPeopleAmount();
    },
    // 按城市检索
    handleCity: function handleCity(e) {
      if (e.value.length > 0) {
        var value = e.value;
        var label = e.label;
        this.socCity = value[value.length - 1];
        // this.socCityName = label[0] + "/" + label[1] + "/" + label[2];
        for (var i = 0; i < label.length; i++) {
          if (i === label.length - 1) {
            this.socCityName += label[i] + "";
          } else {
            this.socCityName += label[i] + "/";
          }
        }
      } else {
        this.socCity = "";
        this.socCityName = "";
      }
      this.dparams.socCity = this.socCityName;
    },
    // 按部门检索
    handleDep: function handleDep(val) {
      this.depId = val;
      this.dparams.depId = this.depId;
      this.pagination.current = 1;
      this.checkPeopleAmount();
    },
    // 根据条件不同查询参保人员信息
    checkPeopleAmount: function checkPeopleAmount(isemy) {
      var _this18 = this;
      this.$setPage("cbgl_condition", this.pagination.current + "");
      this.$setPageSize("cbgl_condition", this.pagination.pageSize + "");
      var data = _objectSpread({
        companyId: this.$store.state.hr.companyId,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.dparams);
      pageEmpSocInfo(data).then(function (res) {
        _this18.pagination.total = res.data.entityCount;
        _this18.dataDetail = res.data.entities;
        _this18.dataDetail.forEach(function (item) {
          if (item.differenceHead) {
            for (var key in item.differenceHead) {
              item[key] = item.differenceHead[key];
            }
          }
        });
        if (_this18.active === 0 && _this18.dataDetail.length === 0 && isemy) {
          _this18.isEmpy = true;
        }
      });
      this.dparams.companyIdc = this.$store.state.hr.companyId;
      this.$setCondition("cbgl_condition", JSON.stringify(this.dparams));
    },
    getEnum: function getEnum() {
      var _this19 = this;
      var data = {
        companyId: this.$store.state.hr.companyId
      };
      _getEnum(data).then(function (res) {
        _this19.socPayCostTypeMap = [];
        for (var key in res.data.socPayCostTypeMap) {
          if (key !== "补收" && key !== "补缴") {
            if (key === "社保退费") {
              _this19.socPayCostTypeMap.push({
                id: res.data.socPayCostTypeMap[key],
                value: "退费"
              });
            } else {
              _this19.socPayCostTypeMap.push({
                id: res.data.socPayCostTypeMap[key],
                value: key
              });
            }
          }
        }
      });
    },
    // 添加参保选择员工
    onEmployeeSelectChange: function onEmployeeSelectChange(ids) {
      this.staffList = ids;
      console.log(this.staffList);
      // this.getDefaultCard();
      this.serviceCost = 0;
      this.isCloseMethod = true;
      this.chooseProgName(this.defaultFangan);
    },
    // 补收补缴选择员工
    onEmployeeSelectChange2: function onEmployeeSelectChange2(ids) {
      this.staffList = ids;
      console.log(this.staffList);
      this.serviceCost = 0;
      this.bushoujiaoHandle2();
    },
    getShebao: function getShebao(index) {
      switch (index) {
        case 1:
          // 社保退费
          for (var i = 0; i < this.formData.length; i++) {
            if (this.formData[i].itemType === 1) {
              return false && this.socRefunds;
            }
          }
          return true;
        case 2:
          // 医保退费
          for (i = 0; i < this.formData.length; i++) {
            if (this.formData[i].itemType === 3) {
              return false && this.meRefunds;
            }
          }
          return true;
        case 3:
          // 公积金退费
          for (i = 0; i < this.formData.length; i++) {
            if (this.formData[i].itemType === 2) {
              return false && this.pfRefunds;
            }
          }
          return true;
      }
    },
    differenceChange: function differenceChange(e) {
      var _this20 = this;
      this.empIdListDiff = [];
      this.differenceVisible = true;
      this.nameDifference = undefined;
      this.personalDifference = undefined;
      this.companyDifference = undefined;
      e.forEach(function (item) {
        _this20.empIdListDiff.push(item.id);
      });
    },
    onEmployeeSelectChange3: function onEmployeeSelectChange3(ids) {
      var _this21 = this;
      if (ids.length === 0) {
        this.$message.success("请选择员工");
        return;
      }
      this.isChooseStaff3 = false;
      this.staffList = ids;
      var data = {
        companyId: this.$store.state.hr.companyId,
        empId: ids[0].id
      };
      this.progTuiFeiFilterList = this.progNameList;
      if (this.progNameList.length !== 0) {
        selectSchemeByEmpRelated(data).then(function (res) {
          if (res.code === 200) {
            if (res.data !== null) {
              _this21.socRefunds = res.data.socRefunds;
              _this21.meRefunds = res.data.meRefunds;
              _this21.pfRefunds = res.data.meRefunds;
              _this21.defaultFangan = res.data.id;
            } else {
              _this21.defaultFangan = _this21.progNameList[0].id;
            }
            _this21.progNameList.map(function (item) {
              if (item.id === _this21.defaultFangan) {
                _this21.progId = _this21.defaultFangan;
                _this21.formData = _this21.deepCopy(item.socProgItemVOList);
                _this21.payProvidentFund = item.payProvidentFund;
                _this21.onlySocAmount = item.onlySocAmount;
                _this21.siteId = item.siteId;
                _this21.progKeyId = item.id;
                _this21.tuifeiType = 0;
                for (var i = 0; i < _this21.formData.length; i++) {
                  if (_this21.formData[i].itemType === 1) {
                    _this21.tuifeiType = 1;
                    break;
                  }
                }
                if (_this21.tuifeiType === 0) {
                  for (i = 0; i < _this21.formData.length; i++) {
                    if (_this21.formData[i].itemType === 3) {
                      _this21.tuifeiType = 2;
                      break;
                    }
                  }
                }
                if (_this21.tuifeiType === 0) {
                  for (i = 0; i < _this21.formData.length; i++) {
                    if (_this21.formData[i].itemType === 2) {
                      _this21.tuifeiType = 3;
                      break;
                    }
                  }
                }
                _this21.getFeiYong();
              }
            });
            _this21.isTuifei = true;
          }
        });
      }
    },
    bushoujiaoHandle2: function bushoujiaoHandle2() {
      var _this22 = this;
      if (this.inApproval) {
        this.$message.success("账单已生成，不允许操作");
        return;
      }
      var data = {
        companyId: this.$store.state.hr.companyId,
        empId: this.staffList[0].id
      };
      this.databushou = [];
      getbushoudate(data).then(function (res) {
        if (res.code === 200) {
          res.data.map(function (item) {
            _this22.databushou.push(Number(item));
          });
        }
      });
      this.serviceCost = 0;
      if (this.progNameList.length !== 0) {
        this.defaultFangan = this.progNameList[0].id;
        // console.log("-------------")
        this.progNameList.map(function (item) {
          if (item.id === _this22.defaultFangan) {
            _this22.progId = _this22.defaultFangan;
            _this22.formData = _this22.deepCopy(item.socProgItemVOList);
            _this22.payProvidentFund = item.payProvidentFund;
            _this22.onlySocAmount = item.onlySocAmount;
            _this22.siteId = item.siteId;
            _this22.progKeyId = item.id;
            if (_this22.currentItem && _this22.currentItem.socProgName === item.progName && _this22.currentItem.serviceCost) {
              _this22.serviceCost = _this22.currentItem.serviceCost;
            } else {
              _this22.serviceCost = item.serviceCost ? item.serviceCost : 0;
            }
          }
        });
      }
      this.defaultCard = 0; // 社保设置全部的基数
      this.defaultGongjiJin = 0; // 公积金基数
      this.bushoubujiao1 = true;
      this.shebaostartyear = "";
      this.shebaostartmonth = "";
      this.shebaoendyear = "";
      this.shebaoendmonth = "";
      this.shebaoEndDate = 0;
      this.shebaoStartDate = 0;
      this.progId = "";
      if (this.currentItem) {
        this.formData = this.deepCopy(this.currentItem.progItemListVo);
      }
      this.zhanajin = 0;
    },
    privCancel: function privCancel() {
      this.isCloseMethod = false;
      this.toSetshebao();
    },
    formatDate2: function formatDate2(text) {
      var s = formatDate(text).split("-");
      return s[0] + "年" + s[1] + "月";
    },
    // 选择在职员工按钮
    chooseOnWork: function chooseOnWork() {
      this.isChooseStaff = true;
      this.selectState = 0;
      this.currentItem = undefined;
    },
    chooseOnWork2: function chooseOnWork2() {
      this.isChooseStaff2 = true;
    },
    // 取消创建参保方案
    handleCancel: function handleCancel() {
      this.tuiFeiVisible = false;
      this.isTuifei = false;
      this.isEmpy = false;
      this.isDaoru = false;
      this.bushoubujiao1 = false;
      this.bushoubujiaook = false;
      this.bushouTable = false;
      this.isChooseStaff = false;
      this.isUndoShebao = false;
      this.isUndoShebao2 = false;
      this.shouDongBuChaVisible = false;
      this.isSetting = false;
      this.isCloseMethod = false;
      // this.isAddShebao = false;
      this.isStopShebao = false;
      this.isExistName = false;
      this.removeZengVisible = false;
      this.init(2);
      this.monthYear = "";
      this.Dvisible = false;
    },
    // 减员
    finishjian: function finishjian() {
      if ((this.defaultGongjiJin + "").trim() === "") {
        this.$message.success("公积金基数不能为空");
        return;
      }
      if ((this.defaultCard + "").trim() === "") {
        this.$message.success("个人社保基数不能为空");
        return;
      }
      var that = this;
      if (this.zengyuanOrJianyuan) {
        if (!this.addShebao && !this.addGjj && !this.addYibao) {
          this.$message.success("请选择增员项目");
          return;
        }
      } else {
        if (!this.jianShebao && !this.jianGjj && !this.jianYibao) {
          this.$message.success("请选择减员项目");
          return;
        }
      }
      if (this.defaultFangan === 0) {
        that.$message.success("请先创建参保方案");
        return;
      } else {
        // 弹出添加增减员下一步对话框
        this.Dvisible = true;
        this.isStopShebao = false;
      }
    },
    // 撤销参保
    handleUndo: function handleUndo() {
      var _this23 = this;
      var data = [];
      data.push(this.keyId);
      var that = this;
      changeEmpBatchRevocation(data).then(function (res) {
        that.$message.success("参保撤销成功");
        _this23.isUndoShebao = false;
        _this23.isUndoShebao2 = false;
        if (_this23.pagination.total % _this23.pagination.pageSize === 1 && _this23.pagination.current > 1) {
          _this23.pagination.current -= 1;
        }
        _this23.checkPeopleAmount();
        _this23.searchShebaoInfo();
      });
      // this.$router.go(0);
    },
    // 批量撤销
    handleUndo2: function handleUndo2() {
      var _this24 = this;
      var that = this;
      var data = [];
      for (var i = 0; i < this.selectedRowKeys.length; i++) {
        data.push(this.selectedRowKeys[i]);
      }
      changeEmpBatchRevocation(data).then(function (res) {
        _this24.isUndoShebao = false;
        _this24.isUndoShebao2 = false;
        that.$message.success("参保撤销成功");
        if (_this24.pagination.total % _this24.pagination.pageSize - _this24.selectedRowKeys.length === 0 && _this24.pagination.current > 1) {
          _this24.pagination.current -= 1;
        }
        _this24.checkPeopleAmount();
        _this24.searchShebaoInfo();
        _this24.selectedRowKeys = [];
      });
      // this.$router.go(0);
    },
    // 减员弹框
    stopShebao: function stopShebao(text) {
      if (this.currentDetail ? this.currentDetail.empExist === 1 : true) {
        if (this.inApproval) {
          this.$message.success("账单已生成，不允许操作");
          return;
        }
        this.xiangqing = false;
        this.jianyuanItem = text;
        this.empId = text.empId;
        this.tempId = text.id;
        this.jianShebao = false;
        this.jianYibao = false;
        this.jianGjj = false;
        // this.getJianYuanList(); // 获取列表
        this.getJianYuanJiaoYanList(); // 获取减员校验
      } else {
        this.$message.error("员工已被删除");
      }
    },
    deletShebao: function deletShebao(text) {
      var _this25 = this;
      deleteById(text.id).then(function (res) {
        _this25.xiangqing = false;
        _this25.checkPeopleAmount();
        _this25.searchShebaoInfo();
        _this25.checkShebaoMethod();
      });
    },
    getJianYuanJiaoYanList: function getJianYuanJiaoYanList() {
      var _this26 = this;
      var data = [this.jianyuanItem.empId];
      checkDecrease(data).then(function (res) {
        // this.pagination2.total = res.data;
        if (res.data != null) {
          _this26.stopyear = "";
          _this26.stopmonth = "";
          _this26.stopGjyear = "";
          _this26.stopGjmonth = "";
          _this26.stopYByear = "";
          _this26.stopYBmonth = "";
          for (var i = 0; i < res.data.length; i++) {
            _this26.jianShebao_2 = res.data[i].socNoPayment;
            _this26.jianYibao_2 = res.data[i].meNoPayment;
            _this26.jianGjj_2 = res.data[i].pfNoPayment;
          }
          if (!_this26.jianShebao_2) {
            _this26.stopyear = "" + _this26.year;
            _this26.stopmonth = _this26.month;
          }
          if (!_this26.jianGjj_2) {
            _this26.stopGjyear = "" + _this26.year;
            _this26.stopGjmonth = _this26.month;
          }
          if (!_this26.jianYibao_2) {
            _this26.stopYByear = "" + _this26.year;
            _this26.stopYBmonth = _this26.month;
          }
        }
        _this26.isStopShebao = true;
        _this26.JYserviceCost = undefined;
      });
    },
    // 完成对话框社保编辑
    finishEdit: function finishEdit() {
      var _this27 = this;
      if ((this.defaultGongjiJin + "").trim() === "") {
        this.$message.success("公积金基数不能为空");
        return;
      }
      if ((this.defaultCard + "").trim() === "") {
        this.$message.success("个人社保基数不能为空");
        return;
      }
      if (this.progId) {
        var ids = [];
        this.staffList.map(function (item) {
          ids.push(item.id);
        });
        this.isCloseMethod = false;
        var data = ids.map(function (empId) {
          return {
            onlySocAmount: _this27.onlySocAmount,
            payProvidentFund: _this27.payProvidentFund,
            companyId: _this27.$store.state.hr.companyId,
            monthId: _this27.monthCurrent,
            empId: empId,
            year: _this27.year,
            month: _this27.month,
            empSocItemDTOList: _this27.formData,
            progId: _this27.progId,
            serviceCost: _this27.serviceCost
          };
        });
        addSoc({
          empSocInfoAddDTOList: data
        }).then(function (res) {
          if (res.data !== null && res.data.empInfoDOListRepeat !== null && res.data.empInfoDOListRepeat) {
            res.data.empInfoDOListRepeat.map(function (item) {
              _this27.existName.push(item.name);
              // this.existEmpId.push(item.empId)
            });

            _this27.isExistName = true;
          }
          if (res.code === 200) {
            _this27.$notification["success"]({
              message: "添加成功"
            });
            _this27.checkPeopleAmount();
            _this27.searchShebaoInfo();
            _this27.checkShebaoMethod();
          }
        });
      }
      this.init(2);
    },
    // 完成抽屉社保编辑
    submitEdit: function submitEdit(bushoubujiao) {
      var _this28 = this;
      if ((this.defaultGongjiJin + "").trim() === "") {
        this.$message.success("公积金基数不能为空");
        return;
      }
      if ((this.defaultCard + "").trim() === "") {
        this.$message.success("个人社保基数不能为空");
        return;
      }
      this.formData.map(function (item) {
        item.empSocId = _this28.keyId;
      });
      var data = {
        companyId: this.$store.state.hr.companyId,
        progId: this.progId,
        year: this.currentItem.year,
        month: this.currentItem.month,
        id: this.keyId,
        empSocItemListVOList: this.formData,
        onlySocAmount: this.onlySocAmount,
        payProvidentFund: this.payProvidentFund,
        serviceCost: this.serviceCost
      };
      setCardinality(data).then(function (res) {
        _this28.checkPeopleAmount();
        _this28.searchShebaoInfo();
      });
      this.isShowShebaoInfo = false;
      this.init(1);
    },
    submitTuiFei: function submitTuiFei() {
      var _this29 = this;
      if (this.progTuiFeiFilterList.length === 0) {
        this.$message.success("无参保方案");
        return;
      }
      var data = {
        companyId: this.$store.state.hr.companyId,
        progId: this.defaultFangan,
        empId: this.staffList[0].id,
        monthId: this.monthCurrent,
        year: this.year,
        month: this.month,
        refundsBeginTime: moment(this.stopTuiFeiyear + "-" + this.stopTuiFeimonth, this.monthFormat).format(),
        refundsEndTime: moment(this.stopTuiFeiyear2 + "-" + this.stopTuiFeimonth2, this.monthFormat).format()
      };
      if (this.tuifeiType === 1 || this.tuifeiType === 2) {
        data.companySocAmount = this.companyAmount;
        data.personalSocAmount = this.personAmount;
        data.companyPfAmount = 0;
        data.personalPfAmount = 0;
        if (this.tuifeiType === 1) {
          data.refundsType = 1;
        } else {
          data.refundsType = 3;
        }
      } else {
        data.refundsType = 2;
        data.companySocAmount = 0;
        data.personalSocAmount = 0;
        data.companyPfAmount = this.companyAmount;
        data.personalPfAmount = this.personAmount;
      }
      refunds(data).then(function (res) {
        if (res.code === 200) {
          _this29.isTuifei = false;
          _this29.tuiFeiVisible = true;
          _this29.checkPeopleAmount();
          _this29.searchShebaoInfo();
          _this29.checkShebaoMethod();
        }
      });
    },
    // 编辑参保各项目基数
    editBaseInfo: function editBaseInfo() {
      this.isShebaoBase = false;
    },
    returnEdit: function returnEdit() {
      this.isShebaoBase = true;
    },
    editBaseInfoGJ: function editBaseInfoGJ() {
      this.isGongJJBase = false;
    },
    returnEditGJ: function returnEditGJ() {
      this.isGongJJBase = true;
    },
    piLiangDaoRu: function piLiangDaoRu() {
      if (this.inApproval) {
        this.$message.success("账单已生成，不允许操作");
        return;
      }
      this.renliStatus = 0; // 0拖拽，1成功，2失败
      this.renliStatusFail = 0; // 0失败，1重复

      this.yibaoStatus = 0; // 0拖拽，1成功，2失败
      this.yibaoStatusFail = 0; // 0失败，1重复

      this.gongjijinStatus = 0; // 0拖拽，1成功，2失败
      this.gongjijinStatusFail = 0; // 0失败，1重复
      this.shebaoErro = [];
      this.gongjijinErro = [];
      this.yibaoErro = [];
      this.meFile = undefined;
      this.pfFile = undefined;
      this.socFile = undefined;
      this.isDaoru = true;
    },
    exportExel: function exportExel() {
      var _this30 = this;
      this.checkedKey = {
        checkedKeys: [],
        rightData: []
      };
      selectAllHead(this.$store.state.hr.companyId, this.monthCurrent).then(function (res) {
        var checkedKeysTemp = [];
        var rightDataTemp = [];
        res.data.forEach(function (element, index) {
          element.enName = index;
          element.name = element.groupName;
          element.groupItem.forEach(function (esy) {
            if (esy.defaultFlag || esy.isSelect) {
              checkedKeysTemp.push({
                key: esy.enName,
                sort: esy.sort
              });
              rightDataTemp.push(esy);
              if (esy.defaultFlag) {
                esy.disableCheckbox = esy.defaultFlag;
              }
            }
          });
        });
        _this30.checkedKey = {
          checkedKeys: _.sortBy(checkedKeysTemp, ["sort"], ["asc"]).map(function (item) {
            return item.key;
          }),
          rightData: _.sortBy(rightDataTemp, ["sort"], ["asc"])
        };

        // this.selectAllHeadData = res.data;
        _this30.selectAllHeadData = [{
          groupItem: res.data,
          name: "全选"
        }];
        _this30.ExportVisible = true;
      }).catch(function (err) {
        _this30.$message.error("失败!");
        console.log("debug log --> ", err);
      });
    },
    handleOk: function handleOk(e) {
      var _this31 = this;
      var data = {
        channelId: this.channelId,
        tag: this.tags.join(",") ? this.tags.join(",") : undefined,
        regType: this.regType,
        companyId: this.$store.state.hr.companyId,
        month: this.month,
        year: this.year,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        name: this.searchName,
        socCity: this.socCityName,
        // this.socCity,
        depId: this.depId,
        socProgId: this.progIdser,
        monthId: this.monthCurrent,
        headInfo: this.$refs.transfer.rightData
      };
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        data.empSocInfoIdList = this.selectedRowKeys;
      }
      if (this.selectedRowKeys.length > 0) {
        data.empSocInfoIdList = this.selectedRowKeys;
      }
      if (this.socPayCostType !== undefined) {
        data.socPayCostType = this.socPayCostType;
      }
      if (this.rStatus) {
        data.status = this.rStatus;
      }
      if (this.active !== 0) {
        switch (this.active) {
          case 1:
            data.topStatus = 1;
            break;
          case 2:
            data.topStatus = 2;
            break;
          case 3:
            data.topStatus = 4;
            break;
        }
      }
      axios({
        url: "/hr/api/v1/empSocTransform/exportEmpSocInfo",
        data: data,
        method: "post",
        responseType: "blob",
        headers: {
          token: this.$store.state.auth.token,
          "Content-Type": "application/json"
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "".concat(_this31.$store.state.hr.companyName, "_") + "参保管理" + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      this.$refs.transfer.checkedKeys = [];
      this.$refs.transfer.rightData = [];
      this.ExportVisible = false;
    },
    handleExportCancel: function handleExportCancel() {
      this.$refs.transfer.checkedKeys = [];
      this.$refs.transfer.rightData = [];
      this.ExportVisible = false;
    },
    // 点击添加参保按钮
    toSetshebao: function toSetshebao() {
      var _this32 = this;
      if (this.inApproval) {
        this.$message.success("账单已生成，不允许操作");
        return;
      }
      this.checkShebaoMethod();
      if (this.progNameList.length !== 0) {
        this.progNameList.map(function (item) {
          if (item.progName && item.progName !== null) {
            _this32.isSetting = false;
            // this.isAddShebao = true;
            _this32.chooseOnWork();
          } else {
            _this32.isSetting = true;
            // this.isAddShebao = false;
            _this32.isChooseStaff = false;
          }
        });
      } else {
        this.isSetting = true;
        // this.isAddShebao = false;
        this.isChooseStaff = false;
      }
      this.formData = [];
    },
    goback: function goback() {
      // this.$router.go(-1);
      this.$router.push({
        path: "/shebao/shebaoManage"
      });
    },
    // 未创建参保方案时去创建参保方案
    toSetMethod: function toSetMethod() {
      this.$router.push({
        path: "/shebao/shebaoMethod/addMethod"
      });
    },
    editShebaoInfo: function editShebaoInfo(value) {
      var _this33 = this;
      if (this.inApproval) {
        this.$message.success("账单已生成，不允许操作");
        return;
      }
      this.xiangqing = false;
      this.defaultCard = 0; // 社保设置全部的基数
      this.defaultGongjiJin = 0; // 公积金基数
      this.currentItem = value;
      console.log(this.currentItem);
      this.dataDetail.map(function (item) {
        if (item.id === value.id) {
          _this33.keyId = value.id;
          _this33.formData = _this33.deepCopy(item.progItemListVo);
          _this33.evprogName = item.progName;
          _this33.chooseEditProgName(item.progId);
          return;
        }
      });
      // this.getDefaultCard();

      this.isShowShebaoInfo = true;
      selectSchemeByEmpSocInfoId({
        companyId: this.$store.state.hr.companyId,
        empSocInfoId: this.currentItem.id
      }).then(function (res) {
        _this33.progFilterList = [];
        // console.log(this.progNameList)
        for (var j = 0; j < res.data.length; j++) {
          for (var i = 0; i < _this33.progNameList.length; i++) {
            if (_this33.progNameList[i].id === res.data[j]) {
              _this33.progFilterList.push(_this33.progNameList[i]);
              break;
            }
          }
        }
      });
    },
    undoShebao: function undoShebao() {
      // 发请求
      this.singleUndoShebao = true; // 单独撤销参保

      this.isUndoShebao = true;
    },
    undoShebao2: function undoShebao2() {
      if (this.inApproval) {
        this.$message.success("账单已生成，不允许操作");
        return;
      }
      // 发请求
      this.singleUndoShebao = false; // 批量撤销参保

      if (this.selectedRowKeys.length !== 0) {
        this.isUndoShebao2 = true;
      }
    },
    // 查询参保方案
    checkShebaoMethod: function checkShebaoMethod() {
      var _this34 = this;
      getListAllRelation({
        companyId: this.$store.state.hr.companyId
      }).then(function (res) {
        _this34.progNameList = res.data;
        if (_this34.progNameList.length !== 0) {
          _this34.defaultFangan = _this34.progNameList[0].id;
          _this34.progNameList.map(function (item) {
            if (item.id === _this34.defaultFangan) {
              _this34.progId = _this34.defaultFangan;
              if (_this34.currentItem && _this34.currentItem.progId === _this34.progId) {
                _this34.formData = _this34.deepCopy(_this34.currentItem.progItemListVo);
              } else {
                _this34.formData = _this34.deepCopy(item.socProgItemVOList);
              }
              // this.formData = this.deepCopy(item.socProgItemVOList);
              _this34.payProvidentFund = item.payProvidentFund;
              _this34.onlySocAmount = item.onlySocAmount;
              _this34.siteId = item.siteId;
              _this34.progKeyId = item.id;
            }
          });
        }
      });
    },
    // 进入参保记录页面
    enterRecord: function enterRecord() {
      this.xiangqing = false;
      this.$router.push({
        path: "/shebao/shebaoMingDan/detailed/".concat(this.currentDetail.empId),
        query: {
          id: this.id,
          year: this.year,
          month: this.month,
          prevpage: "/shebao/shebaoManage/shebaoDetail",
          disable: this.currentDetail ? this.currentDetail.empExist === null ? false : this.currentDetail.empExist === 1 : true
        }
      });
    },
    // 个人档案
    personInfo: function personInfo() {
      if (this.currentDetail ? this.currentDetail.empExist === null ? false : this.currentDetail.empExist === 1 : true) {
        this.xiangqing = false;
        // console.log(location);
        this.$router.push({
          path: "/staff/detail",
          query: {
            id: this.currentDetail.empId,
            userId: this.currentDetail.userId,
            isDelete: true,
            prevpage: location.hash.slice(1)
          }
        });
      } else {
        this.$message.error("员工已被删除");
      }
    },
    closeDrawer: function closeDrawer() {
      this.isShowShebaoInfo = false;
      this.init(1);
    },
    closeXiangq: function closeXiangq() {
      this.xiangqing = false;
    },
    // 深拷贝，解决引用赋值
    deepCopy: function deepCopy(arr) {
      var newArr = [];
      arr.forEach(function (item, index) {
        var record = {};
        for (var k in item) {
          record[k] = item[k];
        }
        newArr.push(record);
      });
      return newArr;
    },
    // 展开个人信息
    expandedRowsChange: function expandedRowsChange(expend, value) {
      var _this35 = this;
      this.dataDetail.map(function (item) {
        if (item.id === value.id) {
          _this35.empId = item.empId;
          _this35.userId = item.userId;
        }
      });
    },
    // 改变社保基数
    changeDefault: function changeDefault(val, index) {
      this.personAmount = 0;
      this.companyAmount = 0;
      if (this.onlySocAmount) {
        this.formData[index].cardinalityDefaultAmount = val;
      } else {
        this.formData[index].cardinalityDefault = val;
      }
      this.getFeiYong();
    },
    // 处理减员月份
    handleCutMonth: function handleCutMonth(val) {
      this.monthYear = val;
      this.cutMonth = this.monthYear.substring(5, 7);
      this.cutYear = this.monthYear.substring(0, 4);
    },
    // 创建参保选择方案
    chooseProgName: function chooseProgName(value) {
      var _this36 = this;
      this.defaultFangan = value;
      this.progNameList.map(function (item) {
        if (item.id === _this36.defaultFangan) {
          _this36.progId = _this36.defaultFangan;
          if (_this36.currentItem && _this36.currentItem.progId === _this36.progId) {
            _this36.formData = _this36.deepCopy(_this36.currentItem.progItemListVo);
          } else {
            _this36.formData = _this36.deepCopy(item.socProgItemVOList);
          }
          console.log(_this36.formData);
          // this.formData = this.deepCopy(item.socProgItemVOList);
          _this36.payProvidentFund = item.payProvidentFund;
          _this36.onlySocAmount = item.onlySocAmount;
          // this.socProgAddDTO = item

          _this36.siteId = item.siteId;
          _this36.progKeyId = item.id;
          if (_this36.currentItem && _this36.currentItem.progId === _this36.progId && _this36.currentItem.serviceCost) {
            _this36.serviceCost = _this36.currentItem.serviceCost;
          } else {
            _this36.serviceCost = item.serviceCost;
          }
        }
      });
      var d = -1;
      var d2 = -1;
      for (var i = 0; i < this.formData.length; i++) {
        var item = this.formData[i];
        if (item.itemType === 1) {
          // 社保
          if (this.onlySocAmount) {
            if (d === -1) {
              d = item.cardinalityDefaultAmount;
            }
            if (d !== item.cardinalityDefaultAmount) {
              // 展开
              this.isShebaoBase = false;
              return;
            }
          } else {
            if (d === -1) {
              d = item.cardinalityDefault;
            }
            if (d !== item.cardinalityDefault) {
              // 展开
              this.isShebaoBase = false;
              return;
            }
          }
        } else if (item.itemType === 2) {
          // 公积金
          if (this.onlySocAmount) {
            if (d2 === -1) {
              d2 = item.cardinalityDefaultAmount;
            }
            if (d2 !== item.cardinalityDefaultAmount) {
              // 展开
              this.isGongJJBase = false;
              return;
            }
          } else {
            if (d2 === -1) {
              d2 = item.cardinalityDefault;
            }
            if (d2 !== item.cardinalityDefault) {
              // 展开
              this.isGongJJBase = false;
              return;
            }
          }
        }
      }
    },
    getprogName: function getprogName() {
      var _this37 = this;
      this.progTuiFeiFilterList.map(function (item) {
        console.log(_this37.defaultFangan + "/" + item.id);
        if (item.id === _this37.defaultFangan) {
          console.log("iiiiii=" + item.progName);
          return item.progName;
        }
      });
    },
    chooseEditTuiFeiProgName: function chooseEditTuiFeiProgName(value) {
      var _this38 = this;
      this.defaultFangan = value;
      this.progTuiFeiFilterList.map(function (item) {
        if (item.id === _this38.defaultFangan) {
          _this38.progId = _this38.defaultFangan;
          _this38.formData = _this38.deepCopy(item.socProgItemVOList);

          // this.formData = this.deepCopy(item.socProgItemVOList);
          _this38.payProvidentFund = item.payProvidentFund;
          _this38.onlySocAmount = item.onlySocAmount;
          _this38.siteId = item.siteId;
          _this38.progKeyId = item.id;
          _this38.tuifeiType = 0;
          for (var i = 0; i < _this38.formData.length; i++) {
            if (_this38.formData[i].itemType === 1) {
              _this38.tuifeiType = 1;
              break;
            }
          }
          if (_this38.tuifeiType === 0) {
            for (i = 0; i < _this38.formData.length; i++) {
              if (_this38.formData[i].itemType === 3) {
                _this38.tuifeiType = 2;
                break;
              }
            }
          }
          if (_this38.tuifeiType === 0) {
            for (i = 0; i < _this38.formData.length; i++) {
              if (_this38.formData[i].itemType === 2) {
                _this38.tuifeiType = 3;
                break;
              }
            }
          }
          _this38.getFeiYong();
        }
      });
      // progTuiFeiFilterList
    },
    // 编辑员工参保信息选择方案
    chooseEditProgName: function chooseEditProgName(value) {
      var _this39 = this;
      this.progNameList.map(function (item) {
        if (item.id === value) {
          _this39.progId = item.socProgItemVOList[0].progId;
          if (_this39.currentItem && _this39.currentItem.progId === _this39.progId) {
            _this39.formData = _this39.deepCopy(_this39.currentItem.progItemListVo);
          } else {
            _this39.formData = _this39.deepCopy(item.socProgItemVOList);
          }

          // 过滤没有的
          if (_this39.currentItem) {
            var has = [];
            for (var i = 0; i < _this39.currentItem.progItemListVo.length; i++) {
              if (_this39.currentItem.progItemListVo[i].itemType && !has.includes(_this39.currentItem.progItemListVo[i].itemType)) {
                has.push(_this39.currentItem.progItemListVo[i].itemType);
              }
            }
            var newFormD = [];
            for (var j = 0; j < _this39.formData.length; j++) {
              for (var k = 0; k < has.length; k++) {
                if (!_this39.formData[j].itemType || _this39.formData[j].itemType === has[k]) {
                  newFormD.push(_this39.formData[j]);
                }
              }
            }
            _this39.formData = [];
            _this39.formData = newFormD;
          }
          _this39.onlySocAmount = item.onlySocAmount;
          _this39.payProvidentFund = item.payProvidentFund;
          if (_this39.currentItem && _this39.currentItem.progId === _this39.progId && _this39.currentItem.serviceCost) {
            _this39.serviceCost = _this39.currentItem.serviceCost;
          } else {
            _this39.serviceCost = item.serviceCost;
          }
        }
      });
      var d = -1;
      var d2 = -1;
      for (var i = 0; i < this.formData.length; i++) {
        var item = this.formData[i];
        if (item.itemType === 1) {
          // 社保
          if (this.onlySocAmount) {
            if (d === -1) {
              d = item.cardinalityDefaultAmount;
            }
            if (d !== item.cardinalityDefaultAmount) {
              // 展开
              this.isShebaoBase = false;
              return;
            }
          } else {
            if (d === -1) {
              d = item.cardinalityDefault;
            }
            if (d !== item.cardinalityDefault) {
              // 展开
              this.isShebaoBase = false;
              return;
            }
          }
        } else if (item.itemType === 2) {
          // 公积金
          if (this.onlySocAmount) {
            if (d2 === -1) {
              d2 = item.cardinalityDefaultAmount;
            }
            if (d2 !== item.cardinalityDefaultAmount) {
              // 展开
              this.isGongJJBase = false;
              return;
            }
          } else {
            if (d2 === -1) {
              d2 = item.cardinalityDefault;
            }
            if (d2 !== item.cardinalityDefault) {
              // 展开
              this.isGongJJBase = false;
              return;
            }
          }
        }
      }
    },
    chooseEditProgId: function chooseEditProgId(value) {
      var _this40 = this;
      this.progId = value;
      this.progNameList.map(function (item) {
        if (item.id === value) {
          _this40.progId = item.socProgItemVOList[0].progId;
          if (_this40.currentItem && _this40.currentItem.progId === _this40.progId) {
            _this40.formData = _this40.deepCopy(_this40.currentItem.progItemListVo);
          } else {
            _this40.formData = _this40.deepCopy(item.socProgItemVOList);
          }
          _this40.onlySocAmount = item.onlySocAmount;
          _this40.payProvidentFund = item.payProvidentFund;
          if (_this40.currentItem && _this40.currentItem.progId === _this40.progId && _this40.currentItem.serviceCost) {
            _this40.serviceCost = _this40.currentItem.serviceCost;
          } else {
            _this40.serviceCost = item.serviceCost;
          }
        }
      });
      var d = -1;
      var d2 = -1;
      for (var i = 0; i < this.formData.length; i++) {
        var item = this.formData[i];
        if (item.itemType === 1) {
          // 社保
          if (this.onlySocAmount) {
            if (d === -1) {
              d = item.cardinalityDefaultAmount;
            }
            if (d !== item.cardinalityDefaultAmount) {
              // 展开
              this.isShebaoBase = false;
              return;
            }
          } else {
            if (d === -1) {
              d = item.cardinalityDefault;
            }
            if (d !== item.cardinalityDefault) {
              // 展开
              this.isShebaoBase = false;
              return;
            }
          }
        } else if (item.itemType === 2) {
          // 公积金
          if (this.onlySocAmount) {
            if (d2 === -1) {
              d2 = item.cardinalityDefaultAmount;
            }
            if (d2 !== item.cardinalityDefaultAmount) {
              // 展开
              this.isGongJJBase = false;
              return;
            }
          } else {
            if (d2 === -1) {
              d2 = item.cardinalityDefault;
            }
            if (d2 !== item.cardinalityDefault) {
              // 展开
              this.isGongJJBase = false;
              return;
            }
          }
        }
      }
    },
    handleExistName: function handleExistName() {
      this.isExistName = false;
    },
    // 查询总体员工社保信息
    searchShebaoInfo: function searchShebaoInfo() {
      var _this41 = this;
      var data2 = {
        companyId: this.$store.state.hr.companyId,
        year: this.year,
        month: this.month,
        progId: this.progIdser
      };
      getEmpSocCount(data2).then(function (res) {
        _this41.topData[0].num = res.data.allCount || 0;
        _this41.topData[1].num = res.data.normalSocCount || 0;
        _this41.topData[2].num = res.data.increaseCount || 0;
        _this41.topData[3].num = res.data.decreaseCount || 0;
        _this41.personalSocAmount = res.data.personalSocAmount; // 个人社保
        _this41.companySocAmount = res.data.companySocAmount; // 公司社保
        _this41.personalPfAmount = res.data.personalPfAmount; // 个人公积金
        _this41.companyPfAmount = res.data.companyPfAmount; // 公司公积金
        _this41.totalAmount = res.data.allAmount; // 合计

        _this41.personalDifferenceAmount = res.data.personalDifferenceAmount;
        _this41.companyDifferenceAmount = res.data.companyDifferenceAmount;
        _this41.accumulationFundFine = res.data.accumulationFundFine; // 公积金代缴
        _this41.socialSecurityFine = res.data.socialSecurityFine; // 社保补收
        _this41.serviceCostAmount = res.data.serviceCost; // 服务费
      });
    },
    // 初始化编辑基数的表单
    init: function init(type) {
      var _this42 = this;
      this.formData = [];
      this.isShebaoBase = true;
      this.isGongJJBase = true;
      if (type === 1) {
        this.dataDetail.map(function (item) {
          if (item.id === _this42.keyId) {
            _this42.evprogName = item.progName;
            _this42.serviceCost = item.serviceCost;
          }
        });
      }
      if (type === 2) {
        this.evprogName = "";
      }
    }
  }
};