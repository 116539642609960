import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "warp"
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "pSlide-title",
    on: {
      click: _vm.onTitle
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c("a-tooltip", {
    attrs: {
      placement: "right"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v("新增" + _vm._s(_vm.title))])]), _c("a-icon", {
    staticStyle: {
      "font-weight": "500"
    },
    attrs: {
      type: "plus"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.newGroup.apply(null, arguments);
      }
    }
  })], 2)], 1), _c("a-menu", {
    staticStyle: {
      width: "100%"
    },
    model: {
      value: _vm.menuKey,
      callback: function callback($$v) {
        _vm.menuKey = $$v;
      },
      expression: "menuKey"
    }
  }, _vm._l(_vm.data, function (item, index) {
    return _c("a-menu-item", {
      key: index,
      on: {
        click: function click($event) {
          return _vm.getApprovalList(item);
        }
      }
    }, [_c("div", {
      staticClass: "menu_item"
    }, [item.name.length < 12 ? _c("div", [_vm._v(_vm._s(item.name))]) : _c("a-tooltip", {
      attrs: {
        placement: "left"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", {
      staticStyle: {
        display: "inline-block",
        width: "150px"
      }
    }, [_vm._v(_vm._s(item.name))])]), _c("span", {
      staticClass: "astrict"
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 2), _c("div", {
      staticStyle: {
        width: "40px"
      }
    }, [_c("a-icon", {
      staticStyle: {
        cursor: "pointer",
        margin: "0 5px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.delTmp(item);
        }
      }
    }), _vm.edit ? _c("a-tooltip", {
      attrs: {
        placement: "right"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v("修改【" + _vm._s(item.name) + "】")])]), _c("a-icon", {
      staticStyle: {
        cursor: "pointer"
      },
      attrs: {
        type: "edit"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.modification(item);
        }
      }
    })], 2) : _vm._e()], 1)], 1)]);
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };