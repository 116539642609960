import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar-wrapper",
    class: {
      bback: _vm.sourceType === 1
    }
  }, [_vm.sourceType === 0 ? _c("div", {
    staticClass: "toggle-wrapper",
    on: {
      click: _vm.toggleSideBar
    }
  }, [_c("a-icon", {
    style: {
      fontSize: "20px"
    },
    attrs: {
      type: _vm.sidebar.opened ? "menu-fold" : "menu-unfold"
    }
  })], 1) : _vm._e(), _vm.sourceType === 0 && _vm.isShow_jiedan ? _c("breadcrumb", {
    staticClass: "breadcrumb-wrapper"
  }) : _vm._e(), _vm.sourceType === 1 ? _c("div", {
    staticClass: "logo-wrapper",
    staticStyle: {
      "margin-left": "24px",
      width: "120px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: _vm.logo,
      alt: ""
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "navbar-wrapper-right"
  }, [_vm.sourceType === 1 && JSON.stringify(this.permissions_menu).indexOf("/staff/employee") !== -1 ? _c("div", {
    staticClass: "switch-wrapper-index"
  }, [_c("div", {
    staticClass: "switch-select"
  }, [_c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-left": "10px"
    },
    attrs: {
      src: require("@/assets/indexImg/search.png"),
      alt: ""
    }
  }), _vm.isShow ? _c("a-select", {
    staticStyle: {
      width: "290px",
      "border-radius": "18px"
    },
    attrs: {
      "show-search": "",
      "show-arrow": false,
      placeholder: "搜索员工姓名/手机号/身份证号",
      "option-filter-prop": "children",
      "default-active-first-option": false,
      "filter-option": false
    },
    on: {
      search: _vm.debouncedSearch,
      change: _vm.handlePersonnel
    },
    scopedSlots: _vm._u([{
      key: "dropdownRender",
      fn: function fn(menu) {
        return _c("div", {}, [_c("v-nodes", {
          attrs: {
            vnodes: menu
          }
        }), _c("a-divider", {
          staticStyle: {
            margin: "4px 0"
          }
        }), _c("div", {
          staticStyle: {
            width: "100%",
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            cursor: "pointer",
            "margin-bottom": "6px"
          },
          on: {
            mousedown: function mousedown(e) {
              return e.preventDefault();
            },
            click: _vm.reSearch
          }
        }, [_vm._v(" 加载更多 ")])], 1);
      }
    }], null, false, 3968271003)
  }, _vm._l(_vm.searchPersonnelList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      staticClass: "select-text",
      attrs: {
        value: item.id
      }
    }, [_c("div", [_vm._v(_vm._s(item.name || "") + " " + _vm._s(item.idCard || ""))]), _c("div", [_vm._v(_vm._s(item.companyName || ""))])]);
  }), 1) : _vm._e()], 1)]) : _vm._e(), _vm.companyList.length > 0 && _vm.sourceType === 0 && _vm.isShow_jiedan ? _c("div", {
    staticClass: "switch-wrapper",
    staticStyle: {
      "margin-left": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "row",
      "justify-content": "center",
      "align-items": "center",
      "margin-top": "15px",
      "padding-top": "4px",
      "min-width": "240px",
      border: "1px solid #e8e8e8",
      "border-radius": "17px"
    }
  }, [_c("SelectCompanyGroup", {
    ref: "sonV",
    staticClass: "wp100 pl16 pb4",
    on: {
      selectCompany: _vm.changeId
    }
  }), _c("a-icon", {
    staticClass: "select-icon",
    staticStyle: {
      display: "block",
      "margin-top": "8px",
      "margin-right": "10px",
      "margin-left": "5px"
    },
    attrs: {
      slot: "suffixIcon",
      type: "caret-down"
    },
    on: {
      click: _vm.sonFun
    },
    slot: "suffixIcon"
  })], 1)]) : _vm._e(), _c("div", {
    staticClass: "nav-right-wrapper",
    class: _vm.sourceType === 1 ? "border-no-left border-no-padding-right" : ""
  }, [_vm.$hasPermissionFirst("hr") ? _c("div", {
    staticClass: "content-to-enterprise",
    staticStyle: {
      height: "35px",
      "padding-left": "15px",
      "padding-right": "15px",
      "margin-left": "24px"
    },
    on: {
      click: function click($event) {
        return _vm.goAdmin();
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-houtaiguanli2",
    staticStyle: {
      "margin-right": "4px",
      "font-size": "12px"
    }
  }), _c("span", {
    staticClass: "content-to-enterprise-font"
  }, [_vm._v("进入管理后台")])]) : _vm._e(), _c("a-popover", {
    attrs: {
      placement: "bottomRight"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("div", {
    ref: "noticeDropdown",
    staticClass: "overlay-wrapper",
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_c("a-tabs", {
    staticClass: "overlay-tab-wrapper",
    attrs: {
      "default-active-key": "1"
    },
    on: {
      change: _vm.changeTab
    }
  }, [_c("a-tab-pane", {
    key: "1"
  }, [_c("span", {
    attrs: {
      slot: "tab"
    },
    slot: "tab"
  }, [_vm._v(" 消息通知 "), _vm.noNotice ? _c("span", {
    staticClass: "ant-badge ant-badge-status ant-badge-not-a-wrapper",
    staticStyle: {
      position: "absolute",
      top: "10px",
      right: "0px"
    }
  }, [_c("span", {
    staticClass: "ant-badge-status-dot ant-badge-status-red"
  }), _c("span", {
    staticClass: "ant-badge-status-text"
  })]) : _vm._e()]), _c("div", {
    staticClass: "notice-wrapper"
  }, [_c("div", {
    staticClass: "Top_classify_warp"
  }, [_c("div", {
    staticClass: "Top_classify",
    class: _vm.classify === 10086 ? "active" : "",
    on: {
      click: function click($event) {
        return _vm.onClassify(10086, "");
      }
    }
  }, [_vm._v("全部")]), _vm._l(_vm.TopData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "Top_classify",
      class: _vm.classify === index ? "active" : "",
      on: {
        click: function click($event) {
          return _vm.onClassify(index, item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2), _c("div", {
    staticClass: "mamessageList"
  }, [_vm.noticeList.length === 0 ? _c("div", {
    staticStyle: {
      width: "100%",
      height: "100%",
      display: "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c("a-empty")], 1) : _c("a-list", {
    attrs: {
      "item-layout": "horizontal",
      "data-source": _vm.noticeList
    },
    scopedSlots: _vm._u([{
      key: "renderItem",
      fn: function fn(item) {
        return _c("a-list-item", {
          staticStyle: {
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.read(item);
            }
          }
        }, [_c("div", {
          staticClass: "notice_wrapper"
        }, [_c("div", {
          staticClass: "item-left"
        }, [_c("div", {
          class: item.readStatus ? "bac-6D69F71A-radius-2px-gray font-6A66F6-10px-gray" : "bac-6D69F71A-radius-2px font-6A66F6-10px",
          staticStyle: {
            "min-width": "40px",
            "margin-right": "10px",
            "padding-left": "5px",
            "padding-right": "8px",
            "padding-top": "5px",
            "padding-bottom": "5px"
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c("div", {
          class: item.readStatus ? "yidu" : "weidu"
        }, [_vm._v(_vm._s(item.title))])]), _c("div", {
          staticClass: "weidu"
        }, [_vm._v(_vm._s(item.gmtCreate ? _vm.formatDateAll(item.gmtCreate, "d") : "--"))])])]);
      }
    }])
  })], 1)])])], 1), _c("div", {
    staticClass: "footer-overlay footer-overlays"
  }, [_c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.initNotices(1);
      }
    }
  }, [_vm._v(" 刷新消息 "), _c("a-icon", {
    attrs: {
      type: "redo"
    }
  })], 1), _c("div", {
    staticStyle: {
      height: "50px",
      "border-right": "1px solid #d4d2d2"
    }
  }), _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.getMore
    }
  }, [_vm._v(" 查看更多 "), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1)], 1)], 1)]), _vm.sourceType === 1 ? _c("span", {
    staticClass: "notice-bell"
  }, [!_vm.noNotice ? _c("img", {
    attrs: {
      src: require("@/assets/icons/wnotice.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/icons/wnotice2.png"),
      alt: ""
    }
  })]) : _c("span", {
    staticClass: "notice-bell"
  }, [!_vm.noNotice ? _c("img", {
    attrs: {
      src: require("@/assets/icons/notice.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/icons/notice2.png"),
      alt: ""
    }
  })])], 2), _c("a-dropdown", {
    staticClass: "avatar-dropdown",
    attrs: {
      trigger: ["hover"],
      placement: "bottomRight"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("img", {
    staticClass: "avatar-img",
    attrs: {
      src: _vm.avatar
    }
  })]), _c("a-menu", {
    attrs: {
      slot: "overlay",
      "default-selected-keys": _vm.defaultSelectedKeys
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "0",
    on: {
      click: _vm.goPerson
    }
  }, [_c("div", {
    staticClass: "item-div"
  }, [_c("img", {
    staticClass: "avatar-img-com",
    attrs: {
      src: _vm.avatar
    }
  }), _c("span", {
    staticClass: "item-span title-name ove"
  }, [_vm._v(_vm._s(_vm.name))]), _c("a-icon", {
    staticClass: "avatar-mid-right jt",
    attrs: {
      type: "right"
    }
  })], 1)]), _c("a-menu-divider", {
    staticStyle: {
      background: "#f2f2f2"
    }
  }), _c("a-menu-item", {
    key: "1",
    on: {
      click: _vm.logout
    }
  }, [_c("a-icon", {
    attrs: {
      type: "poweroff"
    }
  }), _c("span", {
    staticClass: "pd_l4"
  }, [_vm._v("退出登录")])], 1), _vm.showShangbao ? _c("a-menu-item", {
    key: "shangbao",
    on: {
      click: _vm.goShangbao
    }
  }, [_c("span", [_vm._v("保险公司端-商保")])]) : _vm._e(), _vm.showConsole ? _c("a-menu-item", {
    key: "console"
  }, [_c("a", {
    attrs: {
      href: "#/console"
    }
  }, [_vm._v("打开控制台")])]) : _vm._e(), _vm.permissions_site.length > 0 ? [_c("a-menu-divider", {
    staticStyle: {
      background: "#f2f2f2"
    }
  }), _vm._l(_vm.permissions_site, function (site, index) {
    return _c("a-menu-item", {
      key: (index + 2).toString()
    }, [_c("div", {
      staticClass: "item-div",
      on: {
        click: function click($event) {
          return _vm.switchSite(site);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "shop"
      }
    }), _c("span", {
      staticClass: "item-span ove"
    }, [_vm._v(_vm._s(site.name))])], 1)]);
  })] : _vm._e()], 2)], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };