var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "right-wrapper"
  }, [_c("div", {
    staticClass: "content-bag-fff p24"
  }, [_c("div", {
    staticClass: "flex-center-between mb24"
  }, [_vm._m(0), _c("a-month-picker", {
    attrs: {
      "allow-clear": false,
      format: "YYYY-MM"
    },
    model: {
      value: _vm.briefingMonth,
      callback: function callback($$v) {
        _vm.briefingMonth = $$v;
      },
      expression: "briefingMonth"
    }
  })], 1), _c("a-row", {
    staticClass: "mt12"
  }, [_c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("details-top-item", {
    attrs: {
      imgUrl: require("@/assets/icons/hr_zhigong.png"),
      title: _vm.empTitle,
      content: _vm.empWorkingCount
    }
  }, [_c("div", {
    staticClass: "item-wrapper-n",
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", {
    staticStyle: {
      flex: "1"
    }
  }), _c("div", {
    staticClass: "item-wrapper-n-column"
  }, [_c("span", {
    staticClass: "content-span-a6a8b4"
  }, [_vm._v("入职 ")]), _c("span", {
    staticClass: "content-span-a6a8b4 mt16"
  }, [_vm._v("离职 ")])]), _c("div", {
    staticClass: "item-wrapper-n-column ml4"
  }, [_c("span", {
    staticClass: "content-span-55565d"
  }, [_vm._v(_vm._s(_vm.empCurrentWorkingCount))]), _c("span", {
    staticClass: "content-span-55565d mt16"
  }, [_vm._v(_vm._s(_vm.empCurrentLeavingCount))])]), _c("div", {
    staticClass: "item-wrapper-n-column ml4"
  }, [_c("span", {
    staticClass: "content-span-a6a8b4"
  }, [_vm._v("较上月")]), _c("span", {
    staticClass: "content-span-a6a8b4 mt16"
  }, [_vm._v("较上月")])]), _c("div", {
    staticClass: "item-wrapper-n-column ml4"
  }, [_vm.empBeforeWorkingCount === 0 ? _c("span", {
    staticClass: "content-span-a6a8b4"
  }) : _c("span", {
    staticClass: "content-span-a6a8b4"
  }, [_c("img", {
    staticStyle: {
      width: "10px",
      height: "10px",
      "margin-top": "-2px"
    },
    attrs: {
      src: _vm.empBeforeWorkingCount < 0 ? require("@/assets/icons/hr_jianyuan.png") : require("@/assets/icons/hr_zengyuan.png"),
      alt: ""
    }
  })]), _vm.empBeforeLeavingCount === 0 ? _c("span", {
    staticClass: "content-span-a6a8b4 mt16"
  }) : _c("span", {
    staticClass: "content-span-a6a8b4 mt16"
  }, [_c("img", {
    staticStyle: {
      width: "10px",
      height: "10px",
      "margin-top": "-3px"
    },
    attrs: {
      src: _vm.empBeforeLeavingCount < 0 ? require("@/assets/icons/hr_jianyuan.png") : require("@/assets/icons/hr_zengyuan.png"),
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "item-wrapper-n-column ml4"
  }, [_c("span", {
    class: _vm.empBeforeWorkingCount === 0 ? "content-span-a6a8b4" : _vm.empBeforeWorkingCount < 0 ? "content-span-32CCAA" : "content-span-FF7085"
  }, [_vm._v(_vm._s(_vm.empBeforeWorkingCount > 0 ? _vm.empBeforeWorkingCount : Math.abs(_vm.empBeforeWorkingCount)))]), _c("span", {
    staticClass: "mt16",
    class: _vm.empBeforeLeavingCount === 0 ? "content-span-a6a8b4" : _vm.empBeforeLeavingCount < 0 ? "content-span-32CCAA" : "content-span-FF7085"
  }, [_vm._v(_vm._s(_vm.empBeforeLeavingCount > 0 ? _vm.empBeforeLeavingCount : Math.abs(_vm.empBeforeLeavingCount)))])])])])], 1), _c("a-col", {
    staticClass: "right-lin",
    attrs: {
      span: 8
    }
  }, [_c("details-top-item", {
    staticStyle: {
      "margin-left": "24px"
    },
    attrs: {
      imgUrl: require("@/assets/icons/hr_canbao.png"),
      title: _vm.socTitle,
      content: _vm.socCount
    }
  }, [_c("div", {
    staticClass: "item-wrapper-n",
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", {
    staticStyle: {
      flex: "1"
    }
  }), _c("div", {
    staticClass: "item-wrapper-n-column ml4"
  }, [_c("span", {
    staticClass: "content-span-a6a8b4"
  }, [_vm._v("增员")]), _c("span", {
    staticClass: "content-span-a6a8b4 mt16"
  }, [_vm._v("减员")])]), _c("div", {
    staticClass: "item-wrapper-n-column ml4"
  }, [_c("span", {
    staticClass: "content-span-55565d"
  }, [_vm._v(_vm._s(_vm.socIncreaseCount))]), _c("span", {
    staticClass: "content-span-55565d mt16"
  }, [_vm._v(_vm._s(_vm.socDecreaseCount))])])])])], 1), _c("a-col", {
    staticClass: "right-lin",
    attrs: {
      span: 8
    }
  }, [_c("details-top-item", {
    staticStyle: {
      "margin-left": "24px"
    },
    attrs: {
      imgUrl: require("@/assets/icons/hr_yingyee.png"),
      title: _vm.amountTitle,
      content: _vm.amountTotalRevenueAmount
    }
  }, [_c("a-popover", {
    attrs: {
      slot: "content",
      placement: "bottom"
    },
    slot: "content"
  }, [_c("template", {
    slot: "content"
  }, [_c("div", {
    staticClass: "content-span-popover"
  }, [_c("div", {
    staticClass: "popover-div"
  }, [_c("div", {
    staticClass: "popover-top"
  }, [_c("span", {
    staticClass: "popoverContentSpan"
  }, [_vm._v("未到账金额")]), _c("span", {
    staticClass: "popover-contentSpan ml4"
  }, [_vm._v(_vm._s(_vm.amountUnpaidAmountCount))]), _c("span", {
    staticClass: "popoverContentSpan"
  }, [_vm._v("笔")])]), _c("span", {
    staticClass: "content_span"
  }, [_vm._v("¥ " + _vm._s(_vm.amountUnpaidAmount))])]), _c("a-divider", {
    staticStyle: {
      height: "30px",
      "margin-left": "10px",
      "margin-right": "10px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("div", {
    staticClass: "popover-div"
  }, [_c("div", {
    staticClass: "popover-top"
  }, [_c("span", {
    staticClass: "popoverContentSpan"
  }, [_vm._v("已结算金额")]), _c("span", {
    staticClass: "popover-contentSpan ml4"
  }, [_vm._v(_vm._s(_vm.amountPaidAmountCount))]), _c("span", {
    staticClass: "popoverContentSpan"
  }, [_vm._v("笔")])]), _c("span", {
    staticClass: "content_span"
  }, [_vm._v("¥ " + _vm._s(_vm.amountPaidAmount))])])], 1)]), _c("div", {
    staticClass: "item-wrapper-n",
    staticStyle: {
      "min-width": "100px",
      "margin-right": "0px"
    }
  }, [_c("div", {
    staticStyle: {
      flex: "1"
    }
  }), _c("div", {
    staticClass: "item-wrapper-n-column ml4"
  }, [_c("span", {
    staticClass: "content-span-a6a8b4"
  }, [_vm._v("未到账金额")]), _c("span", {
    staticClass: "content-span-a6a8b4 mt16"
  }, [_vm._v("已结算金额")])]), _c("div", {
    staticClass: "item-wrapper-n-column ml4"
  }, [_c("span", {
    staticClass: "contentSpans"
  }, [_vm._v(_vm._s(_vm.amountUnpaidAmountCount) + "笔")]), _c("span", {
    staticClass: "contentSpans mt16"
  }, [_vm._v(_vm._s(_vm.amountPaidAmountCount) + "笔")])])])], 2)], 1)], 1)], 1)], 1), _c("div", {
    staticClass: "content-bag-fff mt24"
  }, [_c("revenue-chart", {
    attrs: {
      siteId: _vm.siteId
    }
  })], 1), _c("div", {
    staticClass: "content-bag-fff mt24",
    staticStyle: {
      "padding-bottom": "24px"
    }
  }, [_c("log-list", {
    attrs: {
      siteId: _vm.siteId
    }
  })], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-center"
  }, [_c("div", {
    staticClass: "bag-6a66f6-radius-2px",
    staticStyle: {
      width: "4px",
      height: "16px",
      "margin-right": "12px"
    }
  }), _c("span", {
    staticClass: "font-16px-3c3d43"
  }, [_vm._v("企业简报")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };