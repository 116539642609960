var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "timeline"
  }, [_c("a-timeline", _vm._l(_vm.timelineArr, function (item) {
    return _c("a-timeline-item", {
      key: item.id
    }, [_c("div", {
      staticClass: "l-item"
    }, [_c("div", [_c("div", {
      staticClass: "time"
    }, [_vm._v(_vm._s(_vm.moment(item.gmtCreate).format("YYYY-MM-DD")))]), _c("div", {
      staticClass: "content"
    }, [_vm._v(" " + _vm._s(item.distributionInfo) + " "), item.distributionReason ? _c("div", {
      staticClass: "content-y"
    }, [_c("span", [_vm._v("原因：" + _vm._s(item.distributionReason))])]) : _vm._e()])])])]);
  }), 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };