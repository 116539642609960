var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-alert", {
    staticStyle: {
      width: "610px",
      "margin-bottom": "24px"
    },
    attrs: {
      "show-icon": "",
      type: "warning",
      message: "若员工工资已生成工资表并完成归档，则工资数据无法删除。",
      closable: ""
    }
  })], 1)], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", [_c("a-month-picker", {
    staticStyle: {
      width: "180px",
      "margin-right": "14px"
    },
    attrs: {
      "allow-clear": false,
      placeholder: "选择月份"
    },
    on: {
      change: _vm.monthChange
    },
    model: {
      value: _vm.time,
      callback: function callback($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  }), _c("a-input-search", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "搜索工时导入表"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.attendanceExcelName,
      callback: function callback($$v) {
        _vm.attendanceExcelName = $$v;
      },
      expression: "attendanceExcelName"
    }
  })], 1), _c("a-col", [_c("a-button", {
    attrs: {
      type: "primary",
      disabled: !_vm.time
    },
    on: {
      click: _vm.onJump
    }
  }, [_vm._v(" 导入考勤数据 ")])], 1)], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-table", {
    staticClass: "xq-form-content-table",
    attrs: {
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      rowClassName: _vm.rowClass
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "name" ? _c("div", {
            key: index
          }, [_c("div", {
            staticClass: "t-name"
          }, [_c("div", {
            staticClass: "overflows"
          }, [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [_c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(record.fileName))])]), _c("div", {
            staticClass: "overflows"
          }, [_vm._v(" " + _vm._s(record.fileName) + " ")])], 2)], 1)])]) : _vm._e(), col.dataIndex === "legalPerson" ? _c("div", {
            key: index
          }, [_c("div", {
            staticClass: "t-num"
          }, [_c("p", [_vm._v("员工人数")]), _c("div", {
            staticClass: "t-num-n"
          }, [_vm._v(_vm._s(record.empCount) + " "), _c("span", [_vm._v("人")])])]), _c("div", {
            staticClass: "t-time"
          }, [_c("p", [_vm._v("导入时间")]), _c("div", {
            staticClass: "t-time-n"
          }, [_vm._v(" " + _vm._s(_vm.moment(record.gmtCreate).format("YYYY-MM-DD HH:mm:ss")) + " ")])])]) : _vm._e(), col.dataIndex === "tableAction" ? _c("div", {
            key: index
          }, [_c("div", {
            staticClass: "t-action"
          }, [_c("a", {
            staticStyle: {
              "margin-right": "30px"
            },
            on: {
              click: function click($event) {
                return _vm.onMsg(record);
              }
            }
          }, [_vm._v("查看详情")]), _c("a-popconfirm", {
            attrs: {
              title: "\u786E\u8BA4\u8981\u5220\u9664\u5417\uFF1F",
              "ok-text": "确认",
              "cancel-text": "取消",
              disabled: record.isHandle
            },
            on: {
              confirm: function confirm($event) {
                return _vm.onDel(record.id);
              }
            }
          }, [_c("a", {
            attrs: {
              disabled: record.isHandle
            }
          }, [_vm._v("删除")])])], 1)]) : _vm._e()];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };