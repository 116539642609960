import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
import axios from "axios";
var mixin = new UploadMixin();
export default {
  mixins: [mixin],
  data: function data() {
    return {
      columns: [],
      excelName: "批量导入模板.xlsx",
      tempInfo: {},
      uploading: false,
      allCount: 0,
      errorCount: 0,
      successCount: 0,
      uploadImg: require("@/assets/img/uploadImg.png"),
      action: "",
      socFile: undefined,
      dialogFailVisible: false,
      errorLst: [],
      errorDataFull: null
    };
  },
  computed: {
    url: function url() {
      return "/hr/api/v1/treatmentDisposeTemplates/generateExcel/".concat(this.tempInfo.id);
    }
  },
  created: function created() {
    this.tempInfo = JSON.parse(localStorage.getItem("importDaiyuchuliItem"));
    this.action = "/hr/api/v1/treatmentDisposeEvents/importEvent/".concat(this.tempInfo.id);
    this.errorLst = [];
  },
  methods: {
    handleCancel: function handleCancel() {
      this.dialogFailVisible = false;
      this.goback();
    },
    handleSuccess: function handleSuccess(res) {
      this.uploading = false;
      if (res && res.code === 200) {
        this.allCount = res.data.allCount;
        this.errorCount = res.data.errorCount;
        this.successCount = res.data.successCount;
        this.errorLst = res.data.errorList;
        this.errorDataFull = res.data;
        this.columns = res.data.headList.map(function (title) {
          return {
            title: title,
            dataIndex: title,
            key: title,
            width: 150
          };
        });
        if (this.errorLst == null || this.errorLst.length === 0) {
          this.dialogFailVisible = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    },
    batchGet: function batchGet() {
      var _this = this;
      this.uploading = true;
      var url = "/hr/api/v1/treatmentDisposeEvents/exportErrorList";
      axios({
        url: url,
        data: this.errorDataFull,
        method: "post",
        responseType: "blob",
        headers: {
          token: this.$store.state.auth.token,
          "Content-Type": "application/json"
        }
      }).then(function (res) {
        _this.uploading = false;
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "事件导入错误.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};