export default {
  name: "Contentitem",
  props: {
    options: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      modalVisible: false
    };
  },
  computed: {
    urls: function urls() {
      return this.options.fileUrl ? this.options.fileUrl.split(",") : [];
    }
  },
  methods: {
    previewImg: function previewImg(idx) {
      this.$viewerApi({
        images: this.urls,
        options: {
          initialViewIndex: idx
        }
      });
    },
    doConfirm: function doConfirm() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/missionCenters/confirmReceive/".concat(this.options.id),
        method: "PUT"
      }).then(function () {
        _this.$message.success("接单成功");
        _this.modalVisible = false;
        _this.$emit("received");
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleEvent: function handleEvent(e) {
      this.$emit("change", e);
    },
    getName: function getName(name) {
      return name;
    }
  }
};