var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "padding-top": "74px"
    }
  }, [_c("div", {
    staticClass: "row"
  }, [_c("a-icon", {
    staticClass: "icon",
    staticStyle: {
      color: "#8CD579"
    },
    attrs: {
      type: "check-circle",
      theme: "filled"
    }
  })], 1), _vm._m(0), _c("div", {
    staticClass: "row content"
  }, [_c("div", {
    staticClass: "content-one"
  }, [_c("span"), _vm._v("共导入" + _vm._s(_vm.vJson.allDataCount) + "条数据")]), _c("div", {
    staticClass: "content-two"
  }, [_vm._v(" 成功导入" + _vm._s(_vm.vJson.allDataCount - _vm.vJson.errorDataCount) + "条数据, "), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.vJson.errorDataCount))]), _vm._v(" 条数据导入失败。 ")])]), _c("div", {
    staticClass: "row"
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "24px"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("onAction", 0);
      }
    }
  }, [_vm._v("重新上传")]), _c("a-button", {
    staticStyle: {
      "margin-right": "24px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(" 数据同步 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.vJson.errorDataCount <= 0
    },
    on: {
      click: _vm.onExport
    }
  }, [_vm._v("导出错误数据")])], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "tit"
  }, [_vm._v("考勤数据导入完成")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };