import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import XqFormMixin from "xqjr-plugin-form/mixins/XqFormMixin";
var FormMixin = XqFormMixin();
import moment from "moment";
export default {
  mixins: [FormMixin],
  props: {
    sortedKeys: {
      type: Array,
      default: function _default() {
        return null;
      }
    },
    remove: {
      type: Boolean,
      default: false
    },
    lookShow: {
      type: Boolean,
      default: false
    },
    dataSource: {
      type: Object,
      default: function _default() {}
    },
    editable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: true
    },
    dynamicData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      isReward: true,
      spouseInfo: false,
      bankName: null,
      bankCard: null
    };
  },
  methods: {
    getFieldValue: function getFieldValue(key) {
      return this.form.getFieldValue(key);
    },
    setFieldsValueShow: function setFieldsValueShow(value) {
      var _this = this;
      setTimeout(function () {
        _this.form.setFieldsValue({
          turnStraightDate: value
        });
      }, 100);
    },
    setFieldsValueShow1: function setFieldsValueShow1(key, value) {
      var _this2 = this;
      setTimeout(function () {
        _this2.form.setFieldsValue(_defineProperty({}, key, value));
      }, 100);
    },
    XqItemChange: function XqItemChange(val, item, dataSource, dynamicData) {
      var _this3 = this;
      if (item.model === "rewardPunish") {
        this.isReward = false;
        this.form.rewardType = null;
        this.$emit("itemChange", val);
        this.isReward = true;
      } else if (item.model === "idCard") {
        setTimeout(function () {
          _this3.form.setFieldsValue({
            idCard: val.toUpperCase()
          });
          _this3.form.validateFields(["idCard"], function (errors, values) {});
        }, 10);
      } else if (item.model === "idcard") {
        setTimeout(function () {
          _this3.form.setFieldsValue({
            idcard: val.toUpperCase()
          });
          _this3.form.validateFields(["idcard"], function (errors, values) {});
        }, 10);
      } else if (item.model === "annexSalaryCard") {
        if (val && val.length > 0) {
          var theItem = val[val.length - 1];
          if (theItem.response && theItem.response.code === 200) {
            setTimeout(function () {
              try {
                if (theItem.response.data.bankCardType && theItem.response.data.bankCardType !== 0) {
                  var values = _this3.form.getFieldsValue();
                  var valueKeys = Object.keys(values);
                  if (valueKeys.includes("bankName")) {
                    _this3.form.setFieldsValue({
                      bankName: theItem.response.data.bankName
                    });
                  } else {
                    _this3.bankName = theItem.response.data.bankName;
                  }
                  if (valueKeys.includes("bankCard")) {
                    _this3.form.setFieldsValue({
                      bankCard: theItem.response.data.bankCardNumber.split(" ").join("")
                    });
                  } else {
                    _this3.bankCard = theItem.response.data.bankCardNumber.split(" ").join("");
                  }
                }
              } catch (f_err) {
                console.log(f_err);
              }
            }, 10);
          }
        }
      }
      this.$emit("customValueChange", val, item.model);
    },
    save: function save() {
      var _this4 = this;
      this.getData().then(function (res) {
        var resKeys = Object.keys(res);
        resKeys.forEach(function (element) {
          if (res[element] === "true") {
            res[element] = true;
          }
          if (res[element] === "false") {
            res[element] = false;
          }
          if (res[element] && res[element]._isAMomentObject) {
            if (element === "birthday") {
              res[element] = moment(res[element]).format("YYYY-MM-DD") + " 00:00:00";
            } else {
              res[element] = moment(res[element]).format("YYYY-MM-DD");
            }
          }
          if (element === "photo" || element.includes("annex") || _this4.formFields[element].type === "upload") {
            var elePropType = Object.prototype.toString.call(res[element]);
            if (elePropType === "[object String]") {
              if (!res[element].includes("http")) {
                res[element] = null;
              }
            } else if (elePropType === "[object Array]") {
              var strJoin = [];
              for (var i = 0; i < res[element].length; i++) {
                if (Object.prototype.toString.call(res[element][i]) === "[object Object]" && res[element][i].url) {
                  strJoin.push(res[element][i].url);
                } else if (Object.prototype.toString.call(res[element][i]) === "[object String]" && res[element][i].includes("http")) {
                  strJoin.push(res[element][i]);
                }
              }
              res[element] = strJoin.join(",");
            } else {
              res[element] = null;
            }
          }
          if (["area"].includes(_this4.formFields[element].type)) {
            var _elePropType = Object.prototype.toString.call(res[element]);
            if (_elePropType === "[object Array]") {
              res[element] = res[element].join(",");
            }
          }
          if (["preJoinDep", "preJoinPosition"].includes(element)) {
            var _elePropType2 = Object.prototype.toString.call(res[element]);
            if (_elePropType2 === "[object Array]") {
              res[element] = res[element].join(",");
            }
          }
        });
        if (_this4.bankName && !resKeys.includes("bankName")) {
          res.bankName = _this4.bankName;
        }
        if (_this4.bankCard && !resKeys.includes("bankCard")) {
          res.bankCard = _this4.bankCard;
        }
        _this4.$emit("change", res);
      }).catch(function (err) {
        _this4.$emit("change");
        console.log("debug log --> ", err);
      });
    },
    onRemove: function onRemove() {
      this.$emit("onRemove");
    },
    cancel: function cancel() {
      this.$emit("update:editable", false);
      this.$emit("cancel");
    },
    onValuesChangeCustom: function onValuesChangeCustom(values) {}
  }
};