import "core-js/modules/es.number.constructor.js";
var initNoNeed = {
  daily: moment().format("YYYY-MM-DD"),
  reason: ""
};
import moment from "moment";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    noNeedPunchDate: {
      type: Object,
      default: initNoNeed
    },
    handleNoNeedList: {
      type: Function,
      default: function _default() {}
    },
    editNoUseIndex: {
      type: Number,
      default: -1
    },
    handleNoUseShow: {
      type: Function,
      default: function _default() {}
    }
  },
  methods: {
    onChange: function onChange(date, dateString) {
      this.noNeedPunchDate.daily = dateString;
    },
    /* okHander */handleOk: function handleOk() {
      this.$emit("handleNoNeedList", this.noNeedPunchDate, this.editNoUseIndex);
      this.$emit("handleNoUseShow", false);
    }
  }
};