export default {
  fields: {
    empName: {
      model: "empName"
    },
    companyUnitName: {
      model: "companyUnitName"
    },
    jobSeekerName: {
      model: "jobSeekerName"
    },
    postName: {
      model: "postName"
    },
    grandReason: {
      model: "grandReason"
    },
    moneyCount: {
      model: "moneyCount"
    },
    settlementStatus: {
      model: "settlementStatus"
    },
    grantDate: {
      model: "grantDate"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "empName",
        title: "姓名",
        width: 200
      }, {
        model: "companyUnitName",
        title: "招工企业"
      }, {
        model: "jobSeekerName",
        title: "已投递"
      }, {
        model: "postName",
        title: "内推职位"
      }, {
        model: "moneyCount",
        title: "已获取赏金"
      }, {
        model: "grandReason",
        title: "赏金获取原因"
      }, {
        model: "grantDate",
        title: "赏金核算时间"
      }, {
        model: "settlementStatus",
        title: "是否核算"
      }],
      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: true
      }
    }
  }
};