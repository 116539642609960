import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import { addTempalte, getTemplateDetail, editTempalte } from "./api";
export default {
  data: function data() {
    return {
      templateId: "",
      type: null,
      prompt: "",
      placeholders: "",
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px"
      },
      form: {
        type: 1
      },
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 8
      },
      isEdit: false
    };
  },
  created: function created() {
    this.isEdit = this.$route.query.isEdit === "1";
    if (this.isEdit) {
      this.templateId = this.$route.query.id;
      this.init();
    }
  },
  beforeDestory: function beforeDestory() {},
  methods: {
    init: function init() {
      var _this = this;
      getTemplateDetail(this.templateId).then(function (res) {
        _this.form.content = res.data.content;
        _this.form.description = res.data.description;
        _this.form.name = res.data.name;
        _this.form.signName = res.data.signName;
        _this.form.thirdId = res.data.thirdId;
        _this.form.type = res.data.type;
        _this.form.value = res.data.value;
        _this.form.key = res.data.key;
        _this.onChange();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    onChange: function onChange() {
      if (this.form.type === 1) {
        this.placeholders = "如：您的验证码为：，该验证码10分钟内有效，请勿泄露于他人。";
        this.prompt = "短信字数含“公司签名+模板内容+验证码(<=6位数字或字母)”，短信70个字数含以内，按1条 短信计费；超出70个字为长短信，按照67个字数记为1条短信费用；500字以内。";
      } else if (this.form.type === 2) {
        this.placeholders = "如：尊敬的，您的合同已发送至职薪人小程序，请留意并及时签署。";
        this.prompt = "短信字数含“公司签名+模板内容+时间/姓名等”，短信70个字数含以内，按1条短信计费；超出 70个字为长短信，按照67个字数记为1条短信费用；500字以内。";
      } else if (this.form.type === 3) {
        this.placeholders = "请输入短信模板内容";
        this.prompt = "短信字数含“公司签名+模板内容”，短信70个字数含以内，按1条短信计费；超出70个字为长短信， 按照67个字数记为1条短信费用；500字以内。";
      }
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      var that = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = _objectSpread(_objectSpread({
            isDefault: false
          }, _this2.form), {}, {
            thirdBeanName: "messageAliyunSMServiceImpl"
          });
          if (_this2.isEdit) {
            data.id = _this2.templateId;
            editTempalte(data).then(function (res) {
              that.$confirm({
                type: "success",
                title: "提交成功",
                content: "若立即发送短信，需关联发送场景",
                okText: "前往配置",
                cancelText: "暂不发送",
                onOk: function onOk() {
                  that.$router.push({
                    path: "/thirdService/smsTemplate/config",
                    query: {
                      templateId: res.data.id
                    }
                  });
                },
                onCancel: function onCancel() {
                  that.$router.push({
                    path: "/thirdService/smsTemplate"
                  });
                }
              });
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          } else {
            addTempalte(data).then(function (res) {
              that.$confirm({
                type: "success",
                title: "提交成功",
                content: "若立即发送短信，需关联发送场景",
                okText: "前往配置",
                cancelText: "暂不发送",
                onOk: function onOk() {
                  that.$router.push({
                    path: "/thirdService/smsTemplate/config",
                    query: {
                      templateId: res.data.id
                    }
                  });
                },
                onCancel: function onCancel() {
                  that.$router.push({
                    path: "/thirdService/smsTemplate"
                  });
                }
              });
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          }
        }
      });
    },
    handleCancel: function handleCancel() {
      this.$router.push("/thirdService/smsTemplate");
    }
  }
};