var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      title: "算税批次来源",
      width: "560px",
      footer: null
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("a-row", {
    staticClass: "item",
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "middle"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.handleOk(2);
      }
    }
  }, [_c("a-col", [_c("div", {
    staticClass: "point",
    staticStyle: {
      background: "#32ccaa"
    }
  }), _vm._v(" 我要自己上传税数据表 ")]), _c("a-col", [_c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1)], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };