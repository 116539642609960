import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  path: "/shebao",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  redirect: "",
  meta: {
    title: "社保服务",
    icon: "yuangongguanli"
  },
  children: [{
    path: "shebaoManage",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoManage/setManage"));
      });
    },
    meta: {
      title: "参保管理",
      icon: "tree"
    }
  }, {
    path: "shebaoMingDan",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoManage/shebaoMingDan/index"));
      });
    },
    meta: {
      title: "社保名单",
      icon: "tree"
    }
  }, {
    path: "shebaoMingDan/detailed/:empId",
    name: "shebaoMingDanDetailed",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoManage/shebaoMingDan/detailed"));
      });
    },
    meta: {
      title: "社保名单详情",
      icon: "tree"
    }
  }, {
    path: "shebaoMethod",
    name: "shebaoMethod",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoMethod/index"));
      });
    },
    meta: {
      title: "参保方案",
      icon: "tree"
    }
  }, {
    path: "statistic",
    name: "statistic",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/statistic/index"));
      });
    },
    meta: {
      title: "统计分析",
      icon: "tree"
    }
  }, {
    path: "paidan",
    name: "paidan",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/index"));
      });
    },
    meta: {
      title: "社保派单",
      icon: "tree"
    }
  }, {
    path: "jiedan",
    name: "jiedan",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoJieDan/index"));
      });
    },
    meta: {
      title: "社保接单",
      icon: "tree"
    }
  }, {
    path: "jiedanzhongxin",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/jiedanzhongxin/index"));
      });
    },
    meta: {
      title: "社保接单",
      icon: "tree"
    }
  }, {
    path: "check",
    name: "check",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/check"));
      });
    },
    meta: {
      title: "社保校验"
    }
  }, {
    path: "detail",
    name: "detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/check/detail"));
      });
    },
    meta: {
      title: "校验结果"
    }
  }, {
    path: "shebaoMethod/addMethod",
    name: "addMethod",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoMethod/addMethod"));
      });
    },
    meta: {
      title: "添加参保方案"
    }
  }, {
    path: "shebaoMethod/methodDetail",
    name: "methodDetail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoMethod/methodDetail"));
      });
    },
    meta: {
      title: "参保详情"
    }
  }, {
    path: "shebaoMethod/import",
    name: "import",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoManage/import"));
      });
    },
    meta: {
      title: "批量增减员"
    }
  }, {
    path: "shebaoMethod/buCha2",
    name: "buCha",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoMethod/buCha2"));
      });
    },
    meta: {
      title: "添加补差"
    }
  }, {
    path: "shebaoMethod/editMethod",
    name: "editMethod",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoMethod/editMethod"));
      });
    }
  }, {
    path: "shebaoManage/shebaoDetail",
    name: "shebaoDetail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoManage/shebaoDetail"));
      });
    },
    meta: {
      title: "参保管理"
    }
  }, {
    path: "myClientele/imports/batchShebaoImports",
    // 导入
    name: "batchMyClienteleImports",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/clientele/imports/batchShebaoImports"));
      });
    }
  }, {
    path: "employee",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/management/employee"));
      });
    },
    meta: {
      title: "花名册",
      icon: "dashboard"
    }
  }, {
    path: "shebaoManage/shebaoZengJianY",
    name: "shebaoZengJianY",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoManage/shebaoZengJianY"));
      });
    },
    meta: {
      title: "增减员计划"
    }
  }, {
    path: "shebaoManage/shebaoRecord",
    name: "shebaoRecord",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoManage/shebaoRecord"));
      });
    }
  }, {
    path: "shebaoPaidan/shebaoUpload",
    name: "shebaoUpload",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/shebaoUpload"));
      });
    },
    meta: {
      title: "上传社保派单表"
    }
  }, {
    path: "shebaoPaidan/preview",
    name: "preview",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/preview"));
      });
    },
    meta: {
      title: "预览社保派单表"
    }
  }, {
    path: "shebaoPaidan/shebao-add-list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/shebao-add-list"));
      });
    }
  }, {
    path: "shebaoPaidan/shebao-add-list-less",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/shebao-add-list-less"));
      });
    }
  }, {
    path: "shebaoJieDan/shebao-add-list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoJieDan/shebao-detail"));
      });
    }
  }, {
    path: "shebaoJieDan/shebao-add-list-less",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoJieDan/shebao-detail-less"));
      });
    }
  }, {
    path: "shebaoPaidan/shebao-detail-add-second",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/shebao-add-list-second"));
      });
    }
  }, {
    path: "shebaoPaidan/shebao-detail-add-second-less",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/shebao-add-list-second-less"));
      });
    }
  }, {
    path: "shebaoPaidan/shebao-detail-add-third",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/shebao-add-list-third"));
      });
    }
  }, {
    path: "shebaoPaidan/shebao-detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/shebao-detail"));
      });
    }
  }, {
    path: "shebaoPaidan/shebao-detail-less",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/shebao-detail-less"));
      });
    }
  }]
};