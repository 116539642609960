import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.number.constructor.js";
import { Check, EditorRules, Common } from "@/api/check";
import EmployeeSelect from "@/components/EmployeeSelect2/employeeSelect4";
import reportUser from "./component/report-user";
import cardDate from "./component/card-date";
import cardTime from "./component/card-time";
import cardCalendar from "./component/card-calendar";
import noUseClock from "./component/nouse-clock";
import doWorkRules from "./component/do-work-rules";
import cardPosition from "./component/card-position";
import addWifi from "./component/add_wifi";
import checkFreeTime from "./component/check-free-time";
import { mapState } from "vuex";
import moment from "moment";
var initTimeList = {
  periodList: [{
    startPeriod: "09:00",
    endPeriod: "18:00",
    type: true,
    sort: 0,
    hasRestPeriod: false
  }],
  restPeriod: {
    startPeriod: "12:00",
    endPeriod: "13:00",
    type: false,
    sort: 0
  },
  afterWorkNeedPunch: true,
  earliestPunchTime: "15",
  earlyMinute: 1,
  isAllowLateLate: false,
  isAllowedLateEarly: false,
  lateMinute: 1,
  latestPunchTime: "60",
  offWorkLateMinute: 1,
  onWorkLateMinute: 1,
  workWeekList: ["星期一", "星期二", "星期三", "星期四", "星期五"],
  latestOnPunchTime: "15",
  earliestOffPunchTime: "60"
};
var initNeed = {
  daily: moment().format("YYYY-MM-DD"),
  reason: "",
  periodList: [{
    startPeriod: "09:00",
    endPeriod: "18:00",
    type: true,
    sort: 0,
    hasRestPeriod: false
  }],
  restPeriod: {
    startPeriod: "12:00",
    endPeriod: "13:00",
    type: false,
    sort: 0
  }
};
var initNoNeed = {
  daily: moment().format("YYYY-MM-DD"),
  reason: ""
};
var dateJson = {
  星期一: {
    k: 1,
    v: "星期一"
  },
  星期二: {
    k: 2,
    v: "星期二"
  },
  星期三: {
    k: 3,
    v: "星期三"
  },
  星期四: {
    k: 4,
    v: "星期四"
  },
  星期五: {
    k: 5,
    v: "星期五"
  },
  星期六: {
    k: 6,
    v: "星期六"
  },
  星期日: {
    k: 7,
    v: "星期日"
  }
};
export default {
  components: {
    addWifi: addWifi,
    noUseClock: noUseClock,
    cardPosition: cardPosition,
    doWorkRules: doWorkRules,
    cardTime: cardTime,
    cardDate: cardDate,
    checkFreeTime: checkFreeTime,
    EmployeeSelect: EmployeeSelect,
    cardCalendar: cardCalendar
  },
  data: function data() {
    return {
      detail: {
        companyId: "",
        deptList: [],
        id: "",
        isMakeUp: false,
        // 是否补卡1是0否
        isOnlyPhoto: false,
        isRemind: false,
        isSynchLegalHoliday: false,
        isTakePhoto: false,
        locationList: [],
        // 打卡位置
        maxMakeUpCounts: 6,
        needPunchDateList: [],
        // 特殊日期必须打卡
        noNeedPunchDateList: [],
        // 特殊日期不必须打卡
        overtimeRule: 1,
        planName: "规则名称",
        planType: 1,
        punchRange: 3,
        punchTimeList: [],
        // 打卡时间
        remindAfter: 0,
        remindBefore: 0,
        reportList: [],
        staffList: [],
        takeEffect: 2,
        tmpAttendShiftId: "",
        whitelist: [],
        wifiList: [],
        workDayRuleDTO: []
      },
      showUsers: false,
      // 添加打卡人员弹窗
      showWhiteList: false,
      // 显示白名单
      showReportList: false,
      showNoUseClock: false,
      showCardTime: false,
      showCardDate: false,
      addFreeCard: false,
      freeCard: {},
      editor: this.$route.params.id,
      companyId: this.$store.state.hr.companyId,
      companyName: this.$store.state.hr.companyName,
      /* 自由上下班 */
      workWeekList: ["星期一", "星期二", "星期三", "星期四", "星期五"],
      whiteOptions: {},
      /* 汇报人员 */
      reportUserOptions: {},
      /* 打卡人员数组 */
      usersList: {},
      postRules: {
        id: "",
        companyId: this.$store.state.hr.companyId,
        deptList: [],
        locationList: [],
        needPunchDateList: [],
        noNeedPunchDateList: [],
        punchTimeList: [],
        reportList: [],
        staffList: [],
        whitelist: [],
        wifiList: [],
        maxMakeUpCounts: -1,
        isMakeUp: true /* 1-是,0-否是否补卡申请 */,
        isSynchLegalHoliday: true /* 1-是,0-否是否同步中国节假日 */,
        isTakePhoto: false /* 1-是,0-否员工打卡时必须拍照 */,
        isOnlyPhoto: true /* 备注不允许上传本地图片,只能拍照;1-是,0-否只能拍照 */,
        isRemind: true /* 1-是,0-否是否手机提醒 */,
        planType: 1 /* 1-固定时间上下班,2-按班次上下班,3-自由上下班方案类型 */,
        planName: "",
        overtimeRule: 1 /* 1-以加班申请为准,2-以打卡时间为准,3-以加班申请核算打卡记录加班规则 */,
        punchRange: 3 /* 1-允许记录为地点异常,2-允许记录为正常外勤,3-不允许 */,
        remindAfter: 0 /* 下班后x分钟 */,
        remindBefore: 0 /* 上班前x分钟*/,
        takeEffect: 2 /* 1-明日生效，2-立即生效生效时间 */
      },

      form: this.$form.createForm(this, {
        name: "coordinated"
      }),
      holidays: true,
      /* addwifi */
      wifiOptions: {},
      noUseClockOptions: {},
      cardPositionOptions: {},
      doWorkRulesOptions: {},
      timeList: {},
      editIndex: -1,
      cardDateOptions: {},
      userOptions: {},
      uploadImgOptions: {},
      dataSource: [],
      isChooseStaff: false,
      isChooseStaff2: false,
      isEdit: false,
      // 判断是编辑规则页面还是添加规则页面
      // defaultSelectedKeys: [],
      defaultSelectedKeys2: [],
      initTimeList: initTimeList,
      editDateIndex: -1,
      initNeed: initNeed,
      needPunchDate: {},
      initNoNeed: initNoNeed,
      noNeedPunchDate: {},
      editNoUseIndex: -1,
      isPX: false,
      visible: false,
      dateJson: dateJson
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    /* 补卡次数 */cardTimes: function cardTimes() {
      var i = 100;
      var box = [];
      while (i > -2) {
        box.push({
          name: i === -1 ? "\u65E0\u9650\u6B21" : "".concat(i, "\u6B21"),
          time: i
        });
        i--;
      }
      return box;
    },
    defaultSelectedKeys: function defaultSelectedKeys() {
      return this.postRules.staffList.map(function (item) {
        return item.empInfoId;
      });
    }
  }),
  watch: {
    /* postRules */
    /*    postRules: {
         handler(val) {
          },
         deep: true
       } */
  },
  created: function created() {
    /* 查询部门下的人员 */
    // this.getUsersList();
    /*   if (this.editor != 0) {
        this.getPunchCard();
      } */
    if (this.$route.params.id !== "0") {
      this.isEdit = true;
      this.getPunchCard();
    }
  },
  methods: {
    // 添加员工
    onEmployeeSelectChange: function onEmployeeSelectChange(value) {
      var _this = this;
      this.postRules.staffList = JSON.parse(JSON.stringify(value));
      this.postRules.staffList.map(function (item, index, arr) {
        _this.postRules.staffList[index].empInfoId = item.id;
        _this.postRules.staffList[index].empInfoName = item.name;
      });
      // this.defaultSelectedKeys = value.map(item => item.id)
    },
    onEmployeeSelectChange2: function onEmployeeSelectChange2(value) {
      var _this2 = this;
      var flag = false;
      this.postRules.staffList.forEach(function (item, index) {
        if (item.id === value.id) {
          flag = true;
          return;
        }
      });
      if (flag) {
        this.$message.error("白名单人员应在打卡人员中！");
        return;
      }
      this.postRules.whitelist = JSON.parse(JSON.stringify(value));
      this.postRules.whitelist.map(function (item, index) {
        _this2.postRules.whitelist[index].empInfoId = item.id;
        _this2.postRules.whitelist[index].empInfoName = item.name;
      });
      this.defaultSelectedKeys2 = value.map(function (item) {
        return item.id;
      });
    },
    /* 重构 */deleteUser: function deleteUser(key, arr) {
      arr.splice(key, 1);
      // this.defaultSelectedKeys = this.postRules.staffList.map(item => item.id)
    },
    staffListCustom: function staffListCustom(e) {
      this.postRules.staffList = e;
    },
    /* 按班次打卡 */addFreeTime: function addFreeTime() {
      var needData = [].concat(_toConsumableArray(this.postRules.deptList), _toConsumableArray(this.postRules.staffList));
      if (needData.length) {
        this.freeCard = this;
        this.addFreeCard = true;
      } else {
        this.$message.error("请选择打卡人员");
      }
    },
    /* 获取打卡详情 */getPunchCard: function getPunchCard() {
      var _this3 = this;
      Check({
        url: EditorRules.getCheckDetail + "/".concat(this.$route.params.id)
      }).then(function (_ref) {
        var data = _ref.data;
        _this3.detail = data;
        data.isSynchLegalHoliday = Boolean(data.isSynchLegalHoliday);
        data.isTakePhoto = Boolean(data.isTakePhoto);
        data.isOnlyPhoto = Boolean(data.isOnlyPhoto);
        data.isSynchLegalHoliday = Boolean(data.isSynchLegalHoliday);
        data.isRemind = Boolean(data.isRemind);
        data.isMakeUp = Boolean(data.isMakeUp);
        var arr = data.punchTimeList[0].workWeekList.map(function (e) {
          return _this3.dateJson[e];
        });
        arr.sort(function (a, b) {
          return a.k - b.k;
        });
        data.punchTimeList[0].workWeekList = arr.map(function (e) {
          return e.v;
        });
        _this3.postRules = data;
      });
    },
    /* 删除打卡部门 */deletedeptList: function deletedeptList(id, arr) {
      arr.find(function (value, i, arrs) {
        if (value.deptId === id) return arrs.splice(i, 1);
      });
    },
    /* 白名单列表 */whiteEditors: function whiteEditors(e) {
      var c = JSON.parse(JSON.stringify(e));
      this.postRules.whitelist = _toConsumableArray(c);
    },
    /* 删除白名单 */deleteWhiteList: function deleteWhiteList(i) {
      this.postRules.whitelist.splice(i, 1);
      this.defaultSelectedKeys2 = this.postRules.whitelist.map(function (item) {
        return item.id;
      });
    },
    /* 位置 */cardPos: function cardPos(e) {
      var flag = false;
      this.postRules.locationList.forEach(function (item, index) {
        if (e.address === item.positionName) flag = true;
      });
      if (flag) {
        this.$notification["error"]({
          message: "失败",
          description: "打卡位置不可以重复！"
        });
        return;
      }
      this.postRules.locationList.push({
        distance: e.distance,
        positionName: e.address,
        punchLatitude: e.lat,
        punchLongitude: e.lng
      });
    },
    /* Wifi */editorWifi: function editorWifi(e) {
      var flag = false;
      this.postRules.wifiList.forEach(function (item, index) {
        if (e.macAddr === item.macAddr && e.name === item.name) flag = true;
      });
      if (flag) {
        this.$notification["error"]({
          message: "失败",
          description: "打卡WiFi不可以重复！"
        });
        return;
      }
      if (Object.keys(e).length) {
        this.postRules.wifiList.push(e);
      }
    },
    /* 删除Wifi */deleteWifi: function deleteWifi(i) {
      this.postRules.wifiList.splice(i, 1);
    },
    /* 特殊日期 /必须打卡的日期*/editorNeedCardDate: function editorNeedCardDate(e) {
      if (typeof e.editorIndex === "number") {
        this.postRules.needPunchDateList[e.editorIndex] = e.data;
      } else {
        this.postRules.needPunchDateList.push(e.data);
      }
    },
    /* 删除/必须打卡的日期 */deleteSpTime: function deleteSpTime(i) {
      this.postRules.needPunchDateList.splice(i, 1);
    },
    /* 特殊日期 /不用打卡的日期 */editorNoUseClock: function editorNoUseClock(e) {
      if (typeof e.editorIndex === "number") {
        this.postRules.noNeedPunchDateList[e.editorIndex] = e.data;
      } else {
        this.postRules.noNeedPunchDateList.push(e.data);
      }
    },
    /* 删除/不用必须打卡 */deleteNoUseClock: function deleteNoUseClock(i) {
      this.postRules.noNeedPunchDateList.splice(i, 1);
    },
    /* 加班规则 */addWorkRules: function addWorkRules(e) {
      this.postRules = _objectSpread(_objectSpread({}, this.postRules), e);
    },
    /* 删除位置 */deletePos: function deletePos(i) {
      this.postRules.locationList.splice(i, 1);
    },
    /* 添加打卡时间 */addCardTime: function addCardTime() {
      if (this.postRules.punchTimeList.length > 0) {
        this.$message.error("最多添加一条打卡时间！");
        return;
      }
      this.showCardTime = true;
      this.editIndex = -1;
      this.timeList = JSON.parse(JSON.stringify(this.initTimeList));
    },
    /* 编辑打卡时间 */editorPunchTime: function editorPunchTime(obj, i) {
      this.showCardTime = true;
      this.editIndex = i;
      this.timeList = JSON.parse(JSON.stringify(obj));
    },
    handleTimeList: function handleTimeList(data, editIndex) {
      var _this4 = this;
      var arr = data.workWeekList.map(function (e) {
        return _this4.dateJson[e];
      });
      arr.sort(function (a, b) {
        return a.k - b.k;
      });
      data.workWeekList = arr.map(function (e) {
        return e.v;
      });
      if (editIndex < 0) {
        this.postRules.punchTimeList.push(JSON.parse(JSON.stringify(data)));
      } else {
        this.postRules.punchTimeList[editIndex] = JSON.parse(JSON.stringify(data));
      }
    },
    handleTimeShow: function handleTimeShow(show) {
      this.showCardTime = show;
    },
    /* 删除打卡时间 */deleteCardTime: function deleteCardTime(i) {
      this.postRules.punchTimeList.splice(i, 1);
    },
    /* 添加打卡特殊日期 */addCardDate: function addCardDate() {
      this.showCardDate = true;
      this.editDateIndex = -1;
      this.needPunchDate = JSON.parse(JSON.stringify(this.initNeed));
    },
    /* 编辑特殊打卡日期 */editorCardDate: function editorCardDate(obj, i) {
      this.showCardDate = true;
      this.editDateIndex = i;
      this.needPunchDate = JSON.parse(JSON.stringify(obj));
    },
    handleDateShow: function handleDateShow(show) {
      this.showCardDate = show;
    },
    handleNeedList: function handleNeedList(data, editDateIndex) {
      var _this5 = this;
      var flag = false;
      if (editDateIndex === -1) {
        this.postRules.needPunchDateList.forEach(function (item, index) {
          if (data.daily === item.daily) {
            flag = true;
            _this5.$notification["error"]({
              message: "失败",
              description: "日期不可以重复！"
            });
          }
        });
        this.postRules.noNeedPunchDateList.forEach(function (item, index) {
          if (data.daily === item.daily) {
            flag = true;
            _this5.$notification["error"]({
              message: "失败",
              description: "必须打卡日期和不用打卡日期不可以重复！"
            });
          }
        });
      }
      if (flag) return;
      if (editDateIndex < 0) {
        this.postRules.needPunchDateList.push(JSON.parse(JSON.stringify(data)));
      } else {
        this.postRules.needPunchDateList[editDateIndex] = JSON.parse(JSON.stringify(data));
      }
    },
    /* 不用打卡日期 */addNoUseClock: function addNoUseClock() {
      this.showNoUseClock = true;
      this.editNoUseIndex = -1;
      this.noNeedPunchDate = JSON.parse(JSON.stringify(this.initNoNeed));
    },
    handleNoNeedList: function handleNoNeedList(data, editNoUseIndex) {
      var _this6 = this;
      var flag = false;
      if (editNoUseIndex === -1) {
        this.postRules.noNeedPunchDateList.forEach(function (item, index) {
          if (data.daily === item.daily) {
            flag = true;
            _this6.$notification["error"]({
              message: "失败",
              description: "日期不可以重复！"
            });
          }
        });
        this.postRules.needPunchDateList.forEach(function (item, index) {
          if (data.daily === item.daily) {
            flag = true;
            _this6.$notification["error"]({
              message: "失败",
              description: "必须打卡日期和不用打卡日期不可以重复！"
            });
          }
        });
      }
      if (flag) return;
      if (editNoUseIndex < 0) {
        this.postRules.noNeedPunchDateList.push(JSON.parse(JSON.stringify(data)));
      } else {
        this.postRules.noNeedPunchDateList[editNoUseIndex] = JSON.parse(JSON.stringify(data));
      }
    },
    handleNoUseShow: function handleNoUseShow(show) {
      this.showNoUseClock = show;
    },
    /* 编辑不用打卡日期 */editorNoUseCard: function editorNoUseCard(obj, i) {
      this.showNoUseClock = true;
      this.editNoUseIndex = i;
      this.noNeedPunchDate = JSON.parse(JSON.stringify(obj));
    },
    // 自由上下班时打卡
    punchTime: function punchTime() {},
    /* 打卡时间 */
    /* 添加成员 */
    addUsers: function addUsers(_ref2) {
      var _ref2$title = _ref2.title,
        title = _ref2$title === void 0 ? "打卡人员" : _ref2$title,
        _ref2$types = _ref2.types,
        types = _ref2$types === void 0 ? 1 : _ref2$types;
      this.isChooseStaff = true;
    },
    /* 添加白名单 */addWhiteList: function addWhiteList(_ref3) {
      var _ref3$title = _ref3.title,
        title = _ref3$title === void 0 ? "添加白名单" : _ref3$title,
        _ref3$types = _ref3.types,
        types = _ref3$types === void 0 ? 2 : _ref3$types;
      this.isChooseStaff2 = true;
    },
    /* 汇报人员 */addreportUser: function addreportUser() {
      this.showReportList = true;
      this.reportUserOptions = {
        show: true,
        types: 3,
        obj: {
          reportList: this.postRules.reportList
        }
      };
    },
    /* 重构 */compareDate: function compareDate(date1, date2, time1, time2) {
      var baseDate = "2020-01-01 ";
      date1 = moment(baseDate + date1, "YYYY-MM-DD HH:mm:ss").valueOf();
      date2 = moment(baseDate + date2, "YYYY-MM-DD HH:mm:ss").valueOf();
      time1 = moment(baseDate + time1, "YYYY-MM-DD HH:mm:ss").valueOf();
      time2 = moment(baseDate + time2, "YYYY-MM-DD HH:mm:ss").valueOf();
      if (date1 > time1 && date2 < time2 && date1 < time2 && date2 > time1) {
        return false;
      } else {
        return true;
      }
    },
    compareTime: function compareTime(time1, time2) {
      var baseDate = "2020-01-01 ";
      time1 = moment(baseDate + time1, "YYYY-MM-DD HH:mm:ss").valueOf();
      time2 = moment(baseDate + time2, "YYYY-MM-DD HH:mm:ss").valueOf();
      if (time1 >= time2) return true;else return false;
    },
    judgeTime: function judgeTime() {
      var flag1 = false;
      var flag2 = false;
      var noExist = false;
      if (this.postRules.punchTimeList === null || this.postRules.punchTimeList.length === 0) {
        flag1 = true;
      }
      if (this.postRules.needPunchDateList === null || this.postRules.needPunchDateList.length === 0) {
        noExist = true;
      }
      if (!flag1) {
        var start1 = this.postRules.punchTimeList[0].periodList[0].startPeriod;
        var end1 = this.postRules.punchTimeList[0].periodList[0].endPeriod;
        var start2 = this.postRules.punchTimeList[0].restPeriod.startPeriod;
        var end2 = this.postRules.punchTimeList[0].restPeriod.endPeriod;
        if (this.postRules.punchTimeList[0].workWeekList.length === 0) {
          flag1 = true;
        }
        if (this.compareTime(start1, end1)) flag1 = true;
        if (this.postRules.punchTimeList[0].periodList[0].hasRestPeriod) {
          if (this.compareTime(start2, end2)) flag1 = true;
          // if (this.compareDate(start2, end2, start1, end1)) flag1 = true;
        }
      }

      if (!noExist) {
        var start3 = this.postRules.needPunchDateList[0].periodList[0].startPeriod;
        var end3 = this.postRules.needPunchDateList[0].periodList[0].endPeriod;
        var start4 = this.postRules.needPunchDateList[0].restPeriod.startPeriod;
        var end4 = this.postRules.needPunchDateList[0].restPeriod.endPeriod;
        if (this.compareTime(start3, end3)) flag2 = true;
        if (this.postRules.needPunchDateList[0].periodList[0].hasRestPeriod) {
          if (this.postRules.needPunchDateList[0].periodList.length === 1) {
            if (this.compareTime(start4, end4)) flag2 = true;
            if (this.compareDate(start4, end4, start3, end3)) flag2 = true;
          }
          if (this.postRules.needPunchDateList[0].periodList.length === 2) {
            if (this.compareTime(start4, end4)) flag2 = true;
            var t = this.postRules.needPunchDateList[0].periodList[1].endPeriod;
            if (this.compareDate(start4, end4, start3, t)) flag2 = true;
          }
        }
      }
      if (flag1) {
        this.$notification["error"]({
          message: "失败",
          description: "请检查打卡时间是否设置正确！"
        });
      }
      if (!noExist && flag2) {
        this.$notification["error"]({
          message: "失败",
          description: "请检查特殊日期是否设置正确！"
        });
      }
      return flag1 || !noExist && flag2;
    },
    judgePos: function judgePos() {
      var flag1 = false;
      var flag2 = false;
      if (this.postRules.wifiList.length === 0 && this.postRules.locationList.length === 0) {
        this.$notification["error"]({
          message: "失败",
          description: "请添加打卡位置！"
        });
        flag1 = true;
      }
      this.postRules.wifiList.forEach(function (item, index) {
        if (!(item.macAddr && item.name)) {
          flag2 = true;
        }
      });
      if (flag2) {
        this.$notification["error"]({
          message: "失败",
          description: "Wifi选项请填写完全！"
        });
      }
      return flag1 || flag2;
    },
    saveNeedData: function saveNeedData() {
      var _this7 = this;
      this.form.validateFields(function (err, values) {
        if (values.planName === "") {
          _this7.$notification["error"]({
            message: "失败",
            description: "规则名称不可以为空！"
          });
          return;
        }
        if (_this7.postRules.staffList.length === 0) {
          _this7.$notification["error"]({
            message: "失败",
            description: "打卡人员不可以为空！"
          });
          return;
        }
        if (!err) {
          _this7.postRules.planName = values.planName;
          _this7.postRules.companyId = _this7.$store.state.hr.companyId;
          var needData = JSON.parse(JSON.stringify(_this7.postRules));
          needData.isSynchLegalHoliday = Number(needData.isSynchLegalHoliday);
          needData.isTakePhoto = Number(needData.isTakePhoto);
          needData.isOnlyPhoto = Number(needData.isOnlyPhoto);
          if (_this7.judgeTime()) return;
          if (_this7.judgePos()) return;
          if (_this7.postRules.id) {
            if (!needData.punchTimeList[0].periodList[0].hasRestPeriod) {
              needData.punchTimeList.forEach(function (e) {
                e.restPeriod = {
                  startPeriod: "",
                  endPeriod: "",
                  type: false,
                  sort: 0
                };
              });
            }
            Check({
              url: EditorRules.editorCheck,
              data: needData,
              method: "PUT"
            }).then(function (data) {
              if (data.success) {
                _this7.$notification["success"]({
                  message: "成功",
                  description: "修改成功"
                });
                _this7.$router.push("/check/checkSet");
              }
            }).catch(function (err) {
              console.log("error log --> ", err);
            });
          } else {
            if (!_this7.postRules.punchTimeList[0].periodList[0].hasRestPeriod) {
              _this7.postRules.punchTimeList.forEach(function (e) {
                e.restPeriod = {
                  startPeriod: "",
                  endPeriod: "",
                  type: false,
                  sort: 0
                };
              });
            }
            Check({
              url: EditorRules.checkSet,
              method: "POST",
              data: _this7.postRules
            }).then(function (data) {
              if (data.success) {
                _this7.$router.push("/check/checkSet");
                _this7.$notification["success"]({
                  message: "成功",
                  description: "新增成功"
                });
              }
            }).catch(function (err) {
              console.log("error log --> ", err);
            });
          }
        }
      });
    },
    editorReportUser: function editorReportUser(e) {
      var c = JSON.parse(JSON.stringify(e));
      this.postRules.reportList = _toConsumableArray(c);
    },
    /* 获取所有部门人员 */getUsersList: function getUsersList() {
      var _this8 = this;
      Check({
        url: Common.selectPeoInfo
      }).then(function (_ref4) {
        var data = _ref4.data;
        _this8.usersList = data;
      });
    },
    /* 导出位置 */exportPos: function exportPos() {},
    /* 删除汇报人员 */deleteReportUser: function deleteReportUser(i) {
      this.postRules.reportList.splice(i, 1);
    },
    /* 添加WIFI */addWIFI: function addWIFI() {
      this.wifiOptions = {
        show: true,
        obj: {}
      };
    },
    /* 添加打卡地点 */addCardPosition: function addCardPosition() {
      this.cardPositionOptions = {
        show: true,
        obj: {}
      };
    },
    /* 加班规则 */editorDoWorkRules: function editorDoWorkRules() {
      this.doWorkRulesOptions = {
        show: true,
        obj: {}
      };
    }
  }
};