import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "box"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("span", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("接单时间：")]), _c("span", {
    staticStyle: {
      "margin-right": "24px"
    }
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.dataSource.takeOrdersDate)))]), _c("span", [_vm._v(_vm._s(_vm.dataSource.companyName))])]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticStyle: {
      flex: "1"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      "margin-bottom": "4px",
      display: "flex",
      "align-items": "center"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.dataSource.empName))]), _vm.dataSource.isPrecedence ? _c("img", {
    staticStyle: {
      width: "51px",
      height: "18px",
      "margin-left": "8px"
    },
    attrs: {
      src: require("./isPrecedence.png")
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "detail"
  }, [_c("div", {
    staticClass: "item",
    staticStyle: {
      width: "25%"
    }
  }, [_c("span", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("身份证：")]), _c("span", [_vm._v(_vm._s(_vm.dataSource.empIdCard))])]), _c("div", {
    staticClass: "item",
    staticStyle: {
      width: "20%"
    }
  }, [_c("span", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("目前流程：")]), _c("span", [_vm._v(_vm._s(_vm.dataSource.currentWorkflow || "--"))])]), _c("div", {
    staticClass: "item",
    staticStyle: {
      width: "15%"
    }
  }, [_c("span", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("事件类型：")]), _c("span", [_vm._v(_vm._s(_vm.dataSource.eventType))])]), _c("div", {
    staticClass: "item",
    staticStyle: {
      width: "15%"
    }
  }, [_c("span", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("接单客服：")]), _c("span", [_vm._v(_vm._s(_vm.dataSource.takeOrdersUserName))])]), _c("div", {
    staticClass: "item",
    staticStyle: {
      width: "15%"
    }
  }, [_c("span", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("工作地点：")]), _c("span", [_vm._v(_vm._s(_vm.dataSource.workCity || "--"))])])])]), _c("a-space", {
    staticStyle: {
      "flex-shrink": "0"
    }
  }, [_vm.permissions_dycl.includes("dycl:dycl.dyclList:detail") ? _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.detailItem
    }
  }, [_vm._v("详情")]) : _vm._e(), _vm.permissions_dycl.includes("dycl:dycl.dyclList:delete") ? _c("a-popconfirm", {
    attrs: {
      title: "确定要删除记录吗？"
    },
    on: {
      confirm: _vm.deleteItem
    }
  }, [_c("a-button", {
    attrs: {
      type: "link"
    }
  }, [_vm._v("删除")])], 1) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };