export default {
  fields: {
    productTypeId: {
      model: "productTypeId",
      type: "select",
      wrapper: {
        label: "企业服务分类"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        dynamic: true,
        dynamicKey: "productTypeIdList"
      }
    },
    productName: {
      model: "productName",
      type: "input",
      wrapper: {
        label: "企业服务名称"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {}
    },
    productTag: {
      model: "productTag",
      type: "select_add",
      wrapper: {
        label: "企业服务标签"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入段名称"
      },
      decorator: {
        initialValue: undefined,
        rules: []
      },
      extend: {
        dictCode: "1224",
        multiple: true,
        maxLength: 6
      }
    },
    productIntroduce: {
      model: "productIntroduce",
      type: "textarea",
      wrapper: {
        label: "企业服务介绍"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入"
      },
      decorator: {
        initialValue: undefined,
        rules: []
      },
      extend: {}
    },
    productServiceCharge: {
      model: "productServiceCharge",
      type: "input",
      wrapper: {
        label: "企业服务价格"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入"
      },
      decorator: {
        initialValue: undefined,
        rules: []
      },
      extend: {}
    },
    productUrl: {
      model: "productUrl",
      type: "input",
      wrapper: {
        label: "企业服务详情链接"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {}
    },
    productCover: {
      model: "productCover",
      type: "upload",
      wrapper: {
        label: "企业服务封面"
      },
      customize: {
        action: "/file/api/v1/site/upload/inline",
        accept: ".jpg, .jpeg, .png, .bmp, .gif",
        listType: "picture-card",
        multiple: false
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        limit: 1,
        size: 5,
        wrapperStyle: {
          width: "272px",
          height: "98px"
        },
        tip: "尺寸：168x160px，图片支持jpg，png格式文件，最多1个，大小不要超过5M。"
      }
    },
    isPrincipal: {
      model: "isPrincipal",
      type: "switch",
      wrapper: {
        label: "是否推荐"
      },
      customize: {},
      decorator: {
        valuePropName: "checked",
        initialValue: false,
        rules: []
      },
      extend: {}
    }
  },
  validators: {},
  // 自定义校验，如果需要自定义校验方法，定义后方法名放在这里面，没有必须是个空对象
  config: {
    // 所有的配置
    search: {
      // 搜索栏的配置，通过 FormJson['config']['search'] 的方式为搜索表单设置 formConfig
      fieldKeys: [
      // 搜索表单包含的字段
      "productName"],
      customize: {
        // 搜索表单的配置
        layout: "inline",
        labelCol: {
          span: 8
        },
        wrapperCol: {
          span: 16
        }
      }
    },
    create: {
      // 搜索栏的配置，通过 FormJson['config']['create'] 的方式为创建表单设置 formConfig
      fieldKeys: [
      // 创建表单包含的字段
      "productTypeId", "productName", "productTag", "productIntroduce", "productServiceCharge", "productUrl", "productCover", "isPrincipal"],
      customize: {
        labelCol: {
          span: 8
        },
        wrapperCol: {
          span: 16
        }
      }
    },
    detail: {
      // 搜索栏的配置，通过 FormJson['config']['update'] 的方式为更新表单设置 formConfig
      fieldKeys: {
        // 更新表单包含的字段，可以通过对象的方式传入字段的配置，配置为表单的配置 API
        fieldName: {
          disabled: true
        }
      },
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    update: {
      // 搜索栏的配置，通过 FormJson['config']['update'] 的方式为更新表单设置 formConfig
      fieldKeys: [
      // 创建表单包含的字段
      "productTypeId", "productName", "productTag", "productIntroduce", "productServiceCharge", "productUrl", "productCover", "isPrincipal"],
      customize: {
        labelCol: {
          span: 8
        },
        wrapperCol: {
          span: 16
        }
      }
    },
    table: {
      // 表格的配置，可以自定义 key 值，通过 tableConfigKey 来修改，默认为 'table'
      fields: [
      // 表格中的字段
      {
        model: "productName",
        title: "企业服务名称"
      }, {
        model: "productTag",
        title: "企业服务标签"
      }, {
        model: "productIntroduce",
        title: "企业服务介绍"
      }, {
        model: "productServiceCharge",
        title: "企业服务价格"
      }, {
        model: "productUrl",
        title: "企业服务详情链接",
        width: 160
      }, {
        model: "productTypeId",
        title: "企业服务分类"
      }],
      customize: {
        // 表格的配置
        rowKey: "id"
      },
      extend: {
        // 表格的自定义配置，例如是否开启批量操作（batch）
        batch: false,
        action: {
          width: 110
        }
      }
    }
  }
};