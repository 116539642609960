import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "main-sidebar-height",
    staticStyle: {
      "padding-bottom": "24px",
      "background-color": "#fff"
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goback
    }
  }, [_vm._v(_vm._s(_vm.tempInfo.name) + " 批量导入")]), _vm.errorLst === null || _vm.errorLst.length === 0 ? _c("div", [_c("a-spin", {
    attrs: {
      spinning: _vm.uploading
    }
  }, [_c("div", {
    staticClass: "ant-upload-select-text-box",
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-upload", {
    attrs: {
      name: "file",
      multiple: false,
      accept: ".xls, .xlsx, .xlsm",
      "show-upload-list": false,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("img", {
    staticStyle: {
      width: "139px",
      height: "106px"
    },
    attrs: {
      src: _vm.uploadImg
    }
  }), _c("div", {
    staticClass: "ant-modal-body"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#666"
    }
  }, [_vm._v("点击添加文件或将文件拖拽到此处")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#999"
    }
  }, [_vm._v("(文件大小不超过5M)")])])])], 1)]), _c("div", {
    staticClass: "tipBox"
  }, [_c("a-row", {
    staticStyle: {
      color: "#252525",
      "font-size": "16px",
      "font-weight": "500"
    }
  }, [_vm._v(" 温馨提示 ")]), _c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("span", [_vm._v("1. 首先下载标准模板，填写信息后再上传 ")]), _c("a-button", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "12px",
      height: "18px"
    },
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("点击下载模板")])], 1), _c("div", {
    staticClass: "rowMargin"
  }, [_vm._v("2. 接单时间、接单客服、所属用工单位、姓名、身份证号为必填项，顺序可调整，但不可删除")]), _c("div", {
    staticClass: "rowMargin"
  }, [_vm._v("3. 若Excel文件设有权限密码，需取消Excel文件密码，否则系统无法识别")])])], 1)], 1)], 1) : _c("div", {
    staticStyle: {
      display: "flex",
      "padding-top": "15px",
      "padding-left": "24px",
      "padding-right": "24px",
      "flex-direction": "column",
      width: "100%"
    }
  }, [_c("div", [_c("div", {
    staticStyle: {
      "padding-left": "10px",
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      height: "35px",
      width: "100%",
      "background-color": "#fffbe6",
      "border-style": "solid",
      "border-color": "#ffe58f",
      "border-width": "1px",
      "border-radius": "5px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "5px"
    },
    attrs: {
      src: require("@/assets/icons/重复.png")
    }
  }), _c("span", {
    attrs: {
      sytle: "color:#55565D;font-size:14px;"
    }
  }, [_vm._v(" 共" + _vm._s(_vm.allCount) + "条数据，成功导入" + _vm._s(_vm.successCount) + "条数据， "), _c("label", {
    staticClass: "redc"
  }, [_vm._v(_vm._s(_vm.errorCount))]), _vm._v("条数据导入失败 ")])])]), _c("div", {
    staticClass: "header-title-box"
  }, [_vm._m(0), _c("div", {
    staticClass: "btn-box"
  }, [_c("a-button", {
    staticClass: "fr",
    attrs: {
      type: "primary",
      disabled: _vm.uploading
    },
    on: {
      click: _vm.batchGet
    }
  }, [_vm._v(_vm._s(_vm.uploading ? "数据导出中..." : "导出错误数据"))])], 1)]), _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.errorLst,
      scroll: {
        x: 800
      },
      "row-key": "身份证号"
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "批量导入",
      width: "500px"
    },
    on: {
      ok: _vm.handleCancel
    },
    model: {
      value: _vm.dialogFailVisible,
      callback: function callback($$v) {
        _vm.dialogFailVisible = $$v;
      },
      expression: "dialogFailVisible"
    }
  }, [_c("div", {
    staticStyle: {
      "padding-left": "10px",
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      height: "35px",
      width: "100%"
    }
  }, [_c("img", {
    staticStyle: {
      width: "25px",
      height: "25px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/icons/duihao.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "15px",
      "font-weight": "700"
    }
  }, [_vm._v("共 " + _vm._s(_vm.allCount) + " 条数据，全部导入成功！")])])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticStyle: {
      "font-size": "15px",
      "font-weight": "700",
      "font-color": "#252525"
    }
  }, [_vm._v("错误信息")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };