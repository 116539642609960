import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
export default {
  name: "Shifts",
  props: {
    shiftsOptions: {
      show: true,
      editorType: false,
      obj: {}
    }
  },
  data: function data() {
    return {
      currentIndex: 0,
      editorType: false,
      visible: false,
      form: this.$form.createForm(this, {
        name: "addFreeName1"
      }),
      attendShiftList: {
        isAdvanc: false,
        afterWorkNeedPunch: false,
        // 下班是否需要打卡 : 1-是,0-否
        attendPlanId: "",
        // 考勤方案ID
        earliestPunchTime: 480,
        // 最早上班打卡时间(分钟)
        earlyMinute: 0,
        // 下班允许早退(分钟)
        isAllowLateLate: false,
        // 允许晚走晚到 : 1-允许,0-不允许
        isAllowedLateEarly: false,
        // 允许迟到早退 : 1-允许,0-不允许
        lateMinute: 0,
        // 上班允许迟到(分钟)
        latestPunchTime: 480,
        // 最晚下班打卡时间(分钟)
        name: "",
        // 班次名称
        offWorkLateMinute: 0,
        // 下班晚走(分钟)
        onWorkLateMinute: 0,
        // 上班晚到(分钟)
        periodJson: "",
        // 打卡时段JSON
        attendPeriodList: [{
          endPeriod: this.moment("00:00", "HH:mm"),
          startPeriod: this.moment("00:00", "HH:mm"),
          sort: 0,
          type: 1
        }],
        resetPeriod: {
          endPeriod: this.moment("00:00", "HH:mm"),
          // 结束时段
          startPeriod: this.moment("00:00", "HH:mm"),
          // 开始时段
          type: 2,
          sort: 0
        }
      }
    };
  },
  watch: {
    shiftsOptions: {
      handler: function handler(val) {
        this.visible = val.show;
        this.editorType = val.editorType;
        if (val.editorType) {
          this.attendShiftList = val.obj;
        } else {
          this.attendShiftList = {
            isAdvanc: false,
            afterWorkNeedPunch: false,
            // 下班是否需要打卡 : 1-是,0-否
            attendPlanId: "",
            // 考勤方案ID
            earliestPunchTime: 480,
            // 最早上班打卡时间(分钟)
            earlyMinute: 0,
            // 下班允许早退(分钟)
            isAllowLateLate: false,
            // 允许晚走晚到 : 1-允许,0-不允许
            isAllowedLateEarly: false,
            // 允许迟到早退 : 1-允许,0-不允许
            lateMinute: 0,
            // 上班允许迟到(分钟)
            latestPunchTime: 480,
            // 最晚下班打卡时间(分钟)
            name: "",
            // 班次名称
            offWorkLateMinute: 0,
            // 下班晚走(分钟)
            onWorkLateMinute: 0,
            // 上班晚到(分钟)
            periodJson: "",
            // 打卡时段JSON
            attendPeriodList: [{
              endPeriod: this.moment("00:00", "HH:mm"),
              startPeriod: this.moment("00:00", "HH:mm"),
              sort: 0,
              type: 1
            }],
            resetPeriod: {
              endPeriod: this.moment("00:00", "HH:mm"),
              // 结束时段
              startPeriod: this.moment("00:00", "HH:mm"),
              // 开始时段
              type: 2,
              sort: 0
            }
          };
        }
      }
    }
  },
  computed: {
    away: function away() {
      var i = 0;
      var awayBox = [];
      while (i <= 60) {
        awayBox.push({
          value: "".concat(i, "\u5206\u949F"),
          key: i
        });
        i += 15;
      }
      return awayBox;
    },
    contralTime: function contralTime() {
      var i = 480;
      var contralTimeBox = [];
      while (i >= 0) {
        contralTimeBox.push({
          value: "".concat(i / 60, "\u5C0F\u65F6\u5185"),
          key: i
        });
        i -= 30;
      }
      return contralTimeBox;
    }
  },
  methods: {
    /* 新增时段 */addNewFreeTime: function addNewFreeTime(arr) {
      this.currentIndex = arr.length;
      var i = 1;
      arr.push({
        startPeriod: this.moment("00:00", "HH:mm"),
        endPeriod: this.moment("00:00", "HH:mm"),
        type: 1,
        sort: i++
      });
    },
    /* 删除时段 */deleteNewFreeTime: function deleteNewFreeTime(i, arr) {
      this.currentIndex = arr.length - 2;
      return this.attendShiftList.attendPeriodList.splice(i, 1);
    },
    editorItem: function editorItem() {},
    /* 删除 */deleteItem: function deleteItem(item, arr) {},
    /* 添加班次 */handleOk: function handleOk() {
      var _this = this;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this.attendShiftList.name = values.name;
          if (_this.editorType) {
            _this.$parent.editorData.attendShiftList.filter(function (val) {
              if (val.name === _this.attendShiftList.name) {
                return val = _this.attendShiftList;
              }
            });
          } else {
            _this.$parent.editorData.attendShiftList.push(_this.attendShiftList);
          }
          _this.visible = false;
        }
      });
    }
  }
};