var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("dir", {
    staticClass: "payslip-excel-detail"
  }, [_c("a-button", {
    staticClass: "mg_b12",
    attrs: {
      icon: "left",
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 返回 ")]), _c("a-table", {
    attrs: {
      "row-key": function rowKey(i, j) {
        return j;
      },
      pagination: _vm.pagination,
      columns: _vm.columns,
      "data-source": _vm.dataSource,
      scroll: {
        x: 1500,
        y: 400
      }
    },
    on: {
      change: _vm.change
    },
    scopedSlots: _vm._u([{
      key: "sendType",
      fn: function fn(sendType) {
        return [sendType === 1 ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/weixin.png")
          }
        }) : _vm._e(), sendType === 2 ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/xuanzhong.png")
          }
        }) : _vm._e()];
      }
    }, {
      key: "readStatus",
      fn: function fn(readStatus) {
        return [!readStatus ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/weixuanzhong.png")
          }
        }) : _vm._e(), readStatus ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/xuanzhong.png")
          }
        }) : _vm._e()];
      }
    }, {
      key: "checkStatus",
      fn: function fn(checkStatus) {
        return [!checkStatus ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/weixuanzhong.png")
          }
        }) : _vm._e(), checkStatus ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/xuanzhong.png")
          }
        }) : _vm._e()];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };