import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { formatDate } from "@/utils/index";
var columns3 = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: "100px",
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  width: "100px",
  scopedSlots: {
    customRender: "phone"
  }
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: "100px",
  scopedSlots: {
    customRender: "idCard"
  }
}, {
  title: "渠道",
  dataIndex: "channelVO",
  key: "channelVO",
  width: "150px",
  ellipsis: true,
  scopedSlots: {
    customRender: "channelVO"
  }
}, {
  title: "入职时间",
  dataIndex: "joinDate",
  key: "joinDate",
  width: "100px",
  scopedSlots: {
    customRender: "joinDate"
  }
}, {
  title: "工作性质",
  dataIndex: "postType",
  key: "postType",
  width: "100px",
  scopedSlots: {
    customRender: "postType"
  }
}, {
  title: "操作",
  dataIndex: "action",
  key: "action",
  width: "100px",
  scopedSlots: {
    customRender: "action"
  }
}];
var columns2 = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: "100px",
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: "200px"
}, {
  title: "渠道",
  dataIndex: "channelName",
  key: "channelName",
  width: "150px",
  ellipsis: true,
  scopedSlots: {
    customRender: "channelName"
  }
}, {
  title: "入职日期",
  dataIndex: "joinDate",
  key: "joinDate",
  width: "150px",
  customRender: function customRender(text, record, index) {
    return formatDate(text);
  },
  scopedSlots: {
    customRender: "joinDate"
  }
}, {
  title: "累计工时",
  dataIndex: "workHour",
  key: "workHour",
  width: "100px",
  scopedSlots: {
    customRender: "workHour"
  }
}, {
  title: "出勤天数 ",
  dataIndex: "workDay",
  key: "workDay",
  width: "100px",
  scopedSlots: {
    customRender: "workDay"
  }
}, {
  title: "工价规则",
  dataIndex: "salaryRuleName",
  key: "salaryGroupName",
  width: "300px",
  scopedSlots: {
    customRender: "salaryRuleName"
  },
  ellipsis: true
}, {
  title: "招工劳务费规则",
  dataIndex: "serviceFeeRuleName",
  key: "serviceFeeRuleName",
  width: "300px",
  scopedSlots: {
    customRender: "serviceFeeRuleName"
  },
  ellipsis: true
}, {
  title: "操作",
  dataIndex: "action",
  key: "action",
  width: "70px",
  scopedSlots: {
    customRender: "action"
  },
  fixed: "right"
}];
import FormJson from "./FormJson";
import qs from "qs";
import axios from "axios";
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
import { retrieveReq, createReq, deleteReq, updateReq, selectOutsourceSalary, getAllChannels, getNotList as _getNotList } from "./api";
import { mapState } from "vuex";
var curdMixin = new XqCurdMixin({
  createReq: createReq,
  retrieveReq: retrieveReq,
  updateReq: updateReq,
  deleteReq: deleteReq,
  FormJson: FormJson,
  tableConfigKey: "table"
});
export default {
  components: {
    EmployeeSelect: function EmployeeSelect() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/components/EmployeeSelect2/employeeSelect-xinzi"));
      });
    }
  },
  mixins: [curdMixin, mixin],
  data: function data() {
    return {
      selectedRowKeys4: [],
      Channels: [],
      columns3: columns3,
      selectedRowKeys: [],
      selectedRowKeys2: [],
      selectedRowKeys3: [],
      abolitionImg: require("@/assets/home/lizhi.png"),
      abolitionImg2: require("@/assets/home/待入职.png"),
      dataList: [],
      notWageList: [],
      columns2: columns2,
      nameOrPhone: "",
      nameOrPhone2: "",
      spinning1: false,
      tabsKey: this.$route.query.tabsKey ? this.$route.query.tabsKey : "3",
      effectVisible: false,
      isChooseStaff: false,
      empList: [],
      recordCopy: {},
      channelId: null,
      channelId2: undefined,
      params: {},
      isChooseStaff2: false,
      isChooseStaff3: false,
      selectedRowKeys5: [],
      empStatus: undefined,
      empStatus2: undefined,
      pageSize: 10,
      postTypeCheck: undefined,
      pagination2: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr", "enums"])), {}, {
    retrieveParams: function retrieveParams() {
      return {
        request: {
          companyId: this.hr.companyId,
          "orders[0].property": "gmtCreate",
          "orders[0].desc": true
        }
      };
    },
    createParams: function createParams() {
      return {};
    },
    dynamicParams: function dynamicParams() {
      return {
        depNameTree: {
          companyId: this.hr.companyId
        }
      };
    },
    postTypeLst: function postTypeLst() {
      return this.enums.postTypeLst;
    },
    postTypeArr: function postTypeArr() {
      return this.enums.postTypeArr;
    }
  }),
  created: function created() {
    if (JSON.stringify(this.$getCondition("xzda_condition")) !== "{}") {
      var dparams = JSON.parse(this.$getCondition("xzda_condition"));
      if (dparams.companyId && dparams.companyId === this.hr.companyId) {
        if (this.tabsKey === "3") {
          if (dparams.postType) {
            this.postTypeCheck = dparams.postType;
          }
          if (dparams.nameOrPhone) {
            this.nameOrPhone2 = dparams.nameOrPhone;
          }
          if (dparams.status) {
            this.empStatus2 = dparams.status;
          }
          if (dparams.pageNo2) {
            this.pagination2.current = dparams.pageNo2;
          }
          if (dparams.pageSize2) {
            this.pagination2.pageSize = dparams.pageSize2;
          }
          if (dparams.channelId) {
            this.channelId2 = dparams.channelId;
          }
        }
        if (this.tabsKey === "2") {
          if (dparams.status) {
            this.empStatus = dparams.status;
          }
          if (dparams.pageNo) {
            this.pagination.current = dparams.pageNo;
          }
          if (dparams.pageSize) {
            this.pagination.pageSize = dparams.pageSize;
          }
          if (dparams.nameOrPhone) {
            this.nameOrPhone = dparams.nameOrPhone;
          }
          if (dparams.channelId) {
            this.channelId = dparams.channelId;
          }
        }
      }
    }
    this.getCurrentPage();
    this.dataLists();
    this.getAllChannelsList();
    this.getNotList(); // 查询未定薪列表
  },

  methods: {
    buildDynamicDataCustom: function buildDynamicDataCustom(tempDynamicData) {
      this.dynamicData = _objectSpread(_objectSpread({}, tempDynamicData), {}, {
        postTypeLst: this.postTypeLst
      });
    },
    batchEdit: function batchEdit(e) {
      var _this = this;
      var arr = [];
      var nameList = [];
      if (e === 1) {
        this.selectedRowKeys4.forEach(function (item, index) {
          arr.push(item.id);
          nameList.push(item.name);
          sessionStorage.setItem("empList", JSON.stringify(arr));
          sessionStorage.setItem("empNameList", JSON.stringify(nameList));
          _this.$router.push({
            path: "/wages/file/batchOutsourcing",
            query: {
              selectedRowKeys: JSON.stringify(arr),
              isEdit: true,
              templateId: arr,
              isFixed: true
            }
          });
        });
      } else if (e === 2) {
        this.selectedRowKeys5.forEach(function (item, index) {
          arr.push(item.id);
          nameList.push(item.name);
        });
        sessionStorage.setItem("empList", JSON.stringify(arr));
        sessionStorage.setItem("empNameList", JSON.stringify(nameList));
        this.$router.push({
          path: "/wages/file/batchOutsourcing",
          query: {
            selectedRowKeys: JSON.stringify(arr),
            isEdit: true,
            templateId: arr,
            isFixed: false
          }
        });
      }
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    goGongJia: function goGongJia() {
      this.$router.push("/wages/file/wagesRules");
    },
    goLaoWu: function goLaoWu() {
      this.$router.push("/wages/file/laborRules");
    },
    getNotList: function getNotList() {
      var _this2 = this;
      var par = {
        postType: this.postTypeCheck,
        nameOrPhone: this.nameOrPhone2,
        status: this.empStatus2,
        pageNo: this.pagination2.current,
        pageSize: this.pagination2.pageSize,
        companyId: this.hr.companyId,
        channelId: this.channelId2 ? this.channelId2 : null
      };
      _getNotList(par).then(function (res) {
        _this2.notWageList = res.data.entities;
        _this2.notWageList.forEach(function (item, index) {
          if (item.channelVO && item.channelVO.name) {
            item.channelName = item.channelVO.name;
          }
        });
        _this2.pagination2.total = res.data.entityCount;
        var p = JSON.parse(JSON.stringify({
          postType: _this2.postTypeCheck,
          nameOrPhone: _this2.nameOrPhone2,
          status: _this2.empStatus2,
          companyId: _this2.hr.companyId,
          channelId: _this2.channelId2 ? _this2.channelId2 : null,
          pageNo2: _this2.pagination2.current,
          pageSize2: _this2.pagination2.pageSize
        }));
        p.tabsKey = _this2.tabsKey;
        _this2.$setCondition("xzda_condition", JSON.stringify(p));
      });
    },
    onSelectChange2: function onSelectChange2(selectedRowKeys) {
      this.selectedRowKeys2 = selectedRowKeys;
    },
    onSelectChange3: function onSelectChange3(selectedRowKeys) {
      this.selectedRowKeys3 = selectedRowKeys;
      this.empList = selectedRowKeys;
    },
    selectCheck2: function selectCheck2(e) {
      this.pagination2.current = 1;
      this.pagination2.pageSize = 10;
      this.getNotList();
    },
    selectCheckChannel: function selectCheckChannel(e) {
      this.channelId2 = e;
      this.pagination2.current = 1;
      this.pagination2.pageSize = 10;
      this.getNotList();
    },
    selectCheck: function selectCheck(e) {
      this.channelId = e;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.dataLists();
    },
    getAllChannelsList: function getAllChannelsList() {
      var _this3 = this;
      this.loading = true;
      getAllChannels().then(function (res) {
        _this3.Channels = res.data;
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys, e) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleChange: function handleChange(e) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.empStatus = e;
      this.dataLists();
    },
    handleChange2: function handleChange2(e) {
      this.pagination2.current = 1;
      this.pagination2.pageSize = 10;
      this.empStatus2 = e;
      this.getNotList();
    },
    tabsChange: function tabsChange(e) {
      var query = JSON.parse(JSON.stringify(this.$router.history.current.query)); // 取当前路由query值并且深拷贝
      var path = this.$router.history.current.path; // 取当前路由地址
      query.tabsKey = e; // 修改变动的query值
      this.$router.push({
        path: path,
        query: query
      }); // 改变query值
      if (e === "1") {
        this.$setCondition("xzda_condition", JSON.stringify({}));
      }
    },
    goDetail: function goDetail(record, e) {
      console.log(record);
      this.$router.push({
        path: "/wages/file/outsourcingDetail",
        query: {
          showType: e,
          info: JSON.stringify(record),
          routerData: JSON.stringify({
            formalType: record.formalType,
            empId: record.id,
            name: record.name,
            depName: record.depName,
            positionName: record.positionName,
            joinDate: record.joinDate,
            employType: record.employType,
            postType: record.postType
          }),
          currentPage: this.currentPage
        }
      });
    },
    goDetail2: function goDetail2() {
      var arr = [];
      var nameList = [];
      this.selectedRowKeys3.forEach(function (item, index) {
        arr.push(item.id);
        nameList.push(item.name);
      });
      sessionStorage.setItem("empList", JSON.stringify(arr));
      sessionStorage.setItem("empNameList", JSON.stringify(nameList));
      this.$router.push({
        path: "/wages/file/batchOutsourcing",
        query: {
          templateId: arr
        }
      });
    },
    // 列表接口
    dataLists: function dataLists() {
      var _this4 = this;
      this.spinning1 = true;
      var params = {
        status: this.empStatus,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        companyId: this.hr.companyId,
        nameOrPhone: this.nameOrPhone ? this.nameOrPhone : null,
        channelId: this.channelId ? this.channelId : null
      };
      selectOutsourceSalary(params).then(function (res) {
        _this4.dataList = res.data.entities;
        _this4.pagination.total = res.data.entityCount;
        _this4.spinning1 = false;
        var p = JSON.parse(JSON.stringify(params));
        p.tabsKey = _this4.tabsKey;
        _this4.$setCondition("xzda_condition", JSON.stringify(p));
      });
    },
    // 搜索事件
    inputChange: function inputChange(value) {
      this.pagination.current = 1;
      this.nameOrPhone = value;
      this.dataLists();
    },
    inputChange2: function inputChange2(value) {
      this.pagination2.current = 1;
      this.nameOrPhone2 = value;
      this.getNotList();
    },
    // 分页事件
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.dataLists();
    },
    handleTableChange2: function handleTableChange2(pagination, filters, sorter) {
      this.pagination2.current = pagination.current;
      this.pagination2.pageSize = pagination.pageSize;
      this.getNotList();
    },
    getCurrentPage: function getCurrentPage() {
      if (this.$route.query.currentPage) {
        this.currentPage = parseInt(this.$route.query.currentPage);
      }
    },
    goUpload: function goUpload() {
      this.$router.push({
        path: "/wages/file/upload"
      });
    },
    goTemplate: function goTemplate() {
      this.$router.push({
        path: "/wages/file/template"
      });
    },
    tree: function tree(array, childKey, onlyLastChild) {
      var _this5 = this;
      if (array === null || array.length < 1) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id;
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        if (item.leave !== 3) {
          item["disabled"] = onlyLastChild;
        }
        if (item.children) {
          _this5.tree1(item.children, childKey, onlyLastChild);
        }
      });
      return array;
    },
    tree1: function tree1(array, childKey, onlyLastChild) {
      var _this6 = this;
      if (array === null || array.length < 1) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id;
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        if (item.children) {
          _this6.tree1(item.children, childKey, onlyLastChild);
        }
      });
      return array;
    },
    batch: function batch() {
      this.$router.push({
        path: "/wages/file/batch"
      });
    },
    fileAdd: function fileAdd(record) {
      this.$router.push({
        path: "/wages/file/add",
        query: {
          showType: 2,
          routerData: JSON.stringify({
            formalType: record.formalType,
            empId: record.id,
            name: record.name,
            depName: record.depName,
            positionName: record.positionName,
            joinDate: record.joinDate,
            employType: record.employType,
            postType: record.postType
          }),
          currentPage: this.currentPage
        }
      });
    },
    handleOk: function handleOk() {
      this.$router.push({
        path: "/wages/file/edit",
        query: {
          routerData: JSON.stringify({
            formalType: this.recordCopy.formalType,
            empId: this.recordCopy.id,
            name: this.recordCopy.name,
            depName: this.recordCopy.depName,
            positionName: this.recordCopy.positionName,
            joinDate: this.recordCopy.joinDate,
            employType: this.recordCopy.employType,
            postType: this.recordCopy.postType
          })
        }
      });
    },
    handleCancel: function handleCancel() {
      this.$router.push({
        path: "/wages/file/detail",
        query: _objectSpread({}, this.recordCopy)
      });
    },
    fileEdit: function fileEdit(record) {
      this.recordCopy = record;
      this.$router.push({
        path: "/wages/file/editDetail",
        query: {
          info: JSON.stringify(record),
          routerData: JSON.stringify({
            formalType: record.formalType,
            empId: record.id,
            name: record.name,
            depName: record.depName,
            positionName: record.positionName,
            joinDate: record.joinDate,
            employType: record.employType,
            postType: record.postType
          }),
          currentPage: this.currentPage
        }
      });
      // }
      // });
    },
    fileDetail: function fileDetail(record) {
      this.$router.push({
        path: "/wages/file/detail",
        query: _objectSpread(_objectSpread({}, record), {}, {
          currentPage: this.currentPage
        })
      });
    },
    formatValues: function formatValues(values) {
      return values;
    },
    formatDataSource: function formatDataSource(record) {
      return record;
    },
    depNameTreeBuilder: function depNameTreeBuilder(data) {
      var tree = this.tree(data, "depChildList", true);
      return tree;
    },
    download: function download(values) {
      var _this7 = this;
      var token = this.$store.state.auth.token;
      var params = {};
      params = _objectSpread({
        companyId: this.hr.companyId
      }, values);
      axios({
        url: "/salary/api/v1/fixedSalaryItems/export",
        params: params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "".concat(_this7.$store.state.hr.companyName, "_") + "员工薪资档案表" + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    exportRecord: function exportRecord() {
      var _this8 = this;
      this.$refs["searchForm"].getData().then(function (values) {
        _this8.params = _objectSpread({
          companyId: _this8.hr.companyId
        }, values);
        _this8.url = "/salary/api/v1/fixedSalaryItems/export";
        _this8.excelName = "".concat(_this8.$store.state.hr.companyName, "_") + "员工薪资档案表" + ".xlsx";
        _this8.getDownload();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};