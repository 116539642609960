import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      mask: "",
      "destroy-on-close": "",
      width: "800px",
      title: _vm.data.title
    },
    on: {
      ok: _vm.handleSubmit
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "名称",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["data.name", {
        rules: [{
          required: true,
          message: "名字不能为空"
        }]
      }],
      expression: "[\n            'data.name',\n            {\n              rules: [{ required: true, message: '名字不能为空' }]\n            }\n          ]"
    }],
    attrs: {
      placeholder: _vm.addData.name
    },
    on: {
      change: _vm.handleName
    }
  })], 1), _vm.data.remark ? _c("a-form-item", {
    attrs: {
      label: "备注",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-textarea", {
    attrs: {
      placeholder: "写点备注...",
      rows: 2
    },
    model: {
      value: _vm.addData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.addData, "remark", $$v);
      },
      expression: "addData.remark"
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "税前发放",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-switch", {
    model: {
      value: _vm.addData.beforeTax,
      callback: function callback($$v) {
        _vm.$set(_vm.addData, "beforeTax", $$v);
      },
      expression: "addData.beforeTax"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };