import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", [_c("a-layout", [_c("a-row", {
    staticClass: "topline",
    attrs: {
      type: "flex"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  }), _c("h3", [_vm._v(_vm._s(_vm.contractInfo.name + "的" + "xx合同类型"))]), _c("a-icon", {
    attrs: {
      type: "add"
    }
  })], 1), _c("a-layout-content", {
    staticClass: "stepbox"
  }, [_c("div", {
    staticClass: "contract",
    staticStyle: {
      "white-space": "pre-wrap"
    },
    domProps: {
      innerHTML: _vm._s(_vm.htmlContent)
    }
  })])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };