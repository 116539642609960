import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      "border-right": "1px solid #d8d8d8",
      flex: "1",
      padding: "0 15px"
    }
  }, [_c("a-input-search", {
    attrs: {
      placeholder: "搜索人员姓名"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.staffFrom.name,
      callback: function callback($$v) {
        _vm.$set(_vm.staffFrom, "name", $$v);
      },
      expression: "staffFrom.name"
    }
  }), _c("div", {
    staticStyle: {
      cursor: "pointer",
      margin: "5px"
    },
    on: {
      click: _vm.onCheckAll
    }
  }, [_vm._v(" 全选 "), _vm.checkAll ? _c("i", {
    staticClass: "iconfont icon-check-circle-fill primary_color",
    staticStyle: {
      "font-size": "19px"
    }
  }) : _c("img", {
    staticStyle: {
      width: "18px",
      height: "18px"
    },
    attrs: {
      src: require("@/assets/icons/未选择.png")
    }
  })]), _c("a-spin", {
    attrs: {
      tip: "人员加载中...",
      delay: 300,
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticStyle: {
      height: "420px",
      "overflow-y": "auto",
      padding: "0 15px"
    }
  }, [_vm.staffData.length === 0 && !_vm.spinning ? _c("a-empty", {
    attrs: {
      description: "暂无人员"
    }
  }) : _vm._l(_vm.staffData, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        cursor: "pointer",
        height: "40px",
        display: "flex",
        "justify-content": "space-between",
        "align-items": "center"
      },
      on: {
        click: function click($event) {
          return _vm.onRow(item);
        }
      }
    }, [_c("span", [_c("a-avatar", {
      staticStyle: {
        "margin-right": "5px"
      },
      attrs: {
        src: item.empPhoto,
        size: "small",
        icon: "user"
      }
    }), _vm._v(_vm._s(item.name) + _vm._s(item.phone))], 1), item.isTrue ? _c("i", {
      staticClass: "iconfont icon-check-circle-fill primary_color",
      staticStyle: {
        "font-size": "19px"
      }
    }) : _c("img", {
      staticStyle: {
        width: "18px",
        height: "18px"
      },
      attrs: {
        src: require("@/assets/icons/未选择.png")
      }
    })]);
  })], 2)])], 1), _c("div", {
    staticStyle: {
      flex: "1",
      padding: "0 15px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "15px"
    }
  }, [_vm._v(" 已选择的人员 "), _c("span", {
    staticClass: "empList"
  }, [_vm._v(_vm._s(_vm.empList.length) + "人")]), _c("div", {
    staticStyle: {
      height: "420px",
      "overflow-y": "auto",
      padding: "0 15px"
    }
  }, _vm._l(_vm.empList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        cursor: "pointer",
        height: "40px",
        display: "flex",
        "justify-content": "space-between",
        "align-items": "center"
      }
    }, [_c("span", [_c("a-avatar", {
      staticStyle: {
        "margin-right": "5px"
      },
      attrs: {
        src: item.empPhoto,
        size: "small",
        icon: "user"
      }
    }), _vm._v(" " + _vm._s(item.name) + _vm._s(item.phone))], 1), _c("img", {
      staticStyle: {
        width: "18px",
        height: "18px"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(item, index);
        }
      }
    })]);
  }), 0)])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };