import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "page page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "headerBox"
  }, [_c("a-month-picker", {
    attrs: {
      size: _vm.size,
      allowClear: false,
      placeholder: "选择月份"
    },
    on: {
      change: _vm.monthChange
    },
    model: {
      value: _vm.now,
      callback: function callback($$v) {
        _vm.now = $$v;
      },
      expression: "now"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#3C3D43",
      cursor: "pointer"
    }
  }, [_c("a-icon", {
    staticClass: "iconStyle",
    attrs: {
      type: "calendar"
    }
  }), _vm._v(_vm._s(_vm.now ? _vm.moment(_vm.now).format("YYYY") + "年" + _vm.moment(_vm.now).format("MM") + "月劳务费结算" : "劳务费结算") + " "), _c("a-icon", {
    staticStyle: {
      color: "rgba(0, 0, 0, 0.4)"
    },
    attrs: {
      type: "caret-down"
    }
  })], 1)]), _c("div", {
    staticStyle: {
      color: "#767885",
      "margin-left": "20px"
    }
  }, [_vm._v(_vm._s(_vm.typeValueTime === 1 ? "全部分组" : "部分分组"))]), _c("div", {
    staticClass: "settingcolor",
    staticStyle: {
      "margin-left": "10px"
    },
    on: {
      click: _vm.setGroupPanel
    }
  }, [_vm._v("设置")])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "fix-item-wrapper"
  }, [_c("div", {
    staticClass: "fix-item-one"
  }, [_c("p", {
    staticClass: "fix-item-name"
  }, [_vm._v("合计")])]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("渠道总数")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v(" " + _vm._s(_vm.combined.channelCount !== null ? _vm.combined.channelCount : "--") + " ")])])])]), _c("div", {
    staticClass: "fix-item-wrapper2"
  }, [_c("div", {
    staticClass: "fix-item-two",
    staticStyle: {
      "border-right": "1px solid #e8e8e8"
    }
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("渠道产生费用人数")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v(" " + _vm._s(_vm.combined.feePeopleCount !== null ? _vm.combined.feePeopleCount : "--") + " "), _vm.combined.countChange ? _c("span", {
    staticStyle: {
      color: "#A6A8B4",
      "font-size": "12px",
      "margin-left": "10px"
    }
  }, [_vm._v(" 较上月 ")]) : _vm._e(), _vm.combined.countChange > 0 ? _c("span", {
    staticStyle: {
      color: "#FF7085",
      "font-size": "12px",
      "margin-left": "5px"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "caret-up"
    }
  }), _vm._v(" " + _vm._s(_vm.combined.countChange !== null ? _vm.combined.countChange : "--") + " ")], 1) : _vm._e(), _vm.combined.countChange < 0 ? _c("span", {
    staticStyle: {
      color: "#32CCAA",
      "font-size": "12px",
      "margin-left": "5px"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "caret-down"
    }
  }), _vm._v(" " + _vm._s(_vm.combined.countChange !== null ? -1 * _vm.combined.countChange : "--") + " ")], 1) : _vm._e()])])]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("应付渠道费用总数")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v(" ¥" + _vm._s(_vm.combined.feeSum !== null ? _vm.combined.feeSum : "--") + " "), _vm.combined.sumChange ? _c("span", {
    staticStyle: {
      color: "#A6A8B4",
      "font-size": "12px",
      "margin-left": "10px"
    }
  }, [_vm._v(" 较上月 ")]) : _vm._e(), _vm.combined.sumChange > 0 ? _c("span", {
    staticStyle: {
      color: "#FF7085",
      "font-size": "12px",
      "margin-left": "5px"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "caret-up"
    }
  }), _vm._v(" " + _vm._s(_vm.combined.sumChange !== null ? _vm.combined.sumChange : "--") + " ")], 1) : _vm._e(), _vm.combined.sumChange < 0 ? _c("span", {
    staticStyle: {
      color: "#32CCAA",
      "font-size": "12px",
      "margin-left": "5px"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "caret-down"
    }
  }), _vm._v(" " + _vm._s(_vm.combined.sumChange !== null ? -1 * _vm.combined.sumChange : "--") + " ")], 1) : _vm._e()])])])])]), _c("div", {
    staticClass: "operation"
  }, [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "渠道名称/身份证号"
    },
    on: {
      search: _vm.inputChange
    }
  }), _c("div", [_c("a-button", {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: function click() {
        return _vm.download(1);
      }
    }
  }, [_vm._v("批量导出")]), _c("a-button", {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showAdd
    }
  }, [_vm._v("渠道结算")]), _c("a-button", {
    on: {
      click: function click() {
        return _vm.download(2);
      }
    }
  }, [_vm._v("全部导出")])], 1)], 1), _c("a-modal", {
    attrs: {
      title: "新增渠道结算",
      visible: _vm.addVisible
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, [_c("a-form-model", {
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "劳务费结算月份"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#000000",
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.now ? _vm.moment(_vm.now).format("YYYY") + "年" + _vm.moment(_vm.now).format("MM") + "月" : "--"))])]), _c("a-form-model-item", {
    ref: "companyId",
    attrs: {
      label: "选择用工单位",
      prop: "companyId"
    }
  }, [_c("a-tree-select", {
    attrs: {
      placeholder: "请选择",
      filterTreeNode: false,
      "tree-data": _vm.dataList2,
      replaceFields: _vm.replaceFields2,
      labelInValue: "",
      "tree-checkable": ""
    },
    on: {
      search: _vm.treeSearch
    },
    model: {
      value: _vm.companyList,
      callback: function callback($$v) {
        _vm.companyList = $$v;
      },
      expression: "companyList"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      "row-key": "id",
      columns: _vm.columns,
      "data-source": _vm.dataList,
      pagination: _vm.pagination,
      scroll: {
        x: 1200
      },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "channelGroupName",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text === null || text === "null" ? "默认分组" : text))])];
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("span", {
          staticClass: "settingcolor",
          staticStyle: {
            "margin-right": "15px"
          },
          on: {
            click: function click($event) {
              return _vm.goDetail(record);
            }
          }
        }, [_vm._v("详情")]), _c("span", {
          staticClass: "settingcolor",
          on: {
            click: function click($event) {
              return _vm.goDelete(record);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c("a-modal", {
    attrs: {
      title: "设置分组范围",
      visible: _vm.isConfirmPlan,
      width: "680px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancelGroup
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancelGroup
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmOk
    }
  }, [_vm._v(" 确认 ")])], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "0px"
    }
  }, [_c("a-form", [_c("a-form-item", {
    staticClass: "baseInfo-item2"
  }, [_c("a-radio-group", {
    on: {
      change: _vm.onTypeChangeValue
    },
    model: {
      value: _vm.typeValueTime,
      callback: function callback($$v) {
        _vm.typeValueTime = $$v;
      },
      expression: "typeValueTime"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 全部分组 ")]), _c("a-radio", {
    staticStyle: {
      "margin-left": "30px"
    },
    attrs: {
      value: 2
    }
  }, [_vm._v(" 部分分组 ")])], 1)], 1), _vm.typeValueTime === 2 ? _c("div", {
    staticClass: "employee-selector-wrapper2"
  }, [_c("div", {
    staticStyle: {
      width: "300px"
    }
  }, [_c("div", {
    staticClass: "employee-selector-block"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "80%"
    },
    attrs: {
      placeholder: "请输入搜索内容",
      defaultValue: _vm.querys,
      allowClear: ""
    },
    on: {
      search: _vm.searchPeople
    }
  })], 1), _c("div", {
    staticClass: "tree-item2"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.treeData.length !== 0 && _vm.querys.length === 0,
      expression: "treeData.length !== 0 && querys.length === 0"
    }]
  }, [_vm._v("全选")]), _vm.isAllSelected ? _c("i", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.treeData.length !== 0 && _vm.querys.length === 0,
      expression: "treeData.length !== 0 && querys.length === 0"
    }],
    staticClass: "iconfont icon-check-circle-fill primary_color",
    staticStyle: {
      "font-size": "19px",
      "margin-left": "10px"
    },
    on: {
      click: function click($event) {
        return _vm.choseAll(_vm.treeData);
      }
    }
  }) : _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.treeData.length !== 0 && _vm.querys.length === 0,
      expression: "treeData.length !== 0 && querys.length === 0"
    }],
    staticStyle: {
      width: "18px",
      height: "18px",
      "margin-left": "10px"
    },
    attrs: {
      src: require("@/assets/icons/未选择.png")
    },
    on: {
      click: function click($event) {
        return _vm.choseAll(_vm.treeData);
      }
    }
  })]), _c("div", {
    staticClass: "employee-selector"
  }, [_vm.treeData.length === 0 ? _c("a-empty", {
    staticStyle: {
      "margin-top": "100px"
    },
    attrs: {
      description: "暂无人员"
    }
  }) : _vm._l(_vm.treeData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "mg_t10"
    }, [item.show ? _c("div", {
      staticClass: "tree-item",
      on: {
        click: function click($event) {
          return _vm.chose(item, _vm.treeData);
        }
      }
    }, [_c("span", [_c("img", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "margin-right": "10px"
      },
      attrs: {
        src: require("@/assets/icons/person1.png")
      }
    }), _vm._v(" " + _vm._s(item.name) + " ")]), item.isSelected ? _c("i", {
      staticClass: "iconfont icon-check-circle-fill primary_color",
      staticStyle: {
        "font-size": "19px"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.chose(item, _vm.treeData);
        }
      }
    }) : _c("img", {
      staticStyle: {
        width: "18px",
        height: "18px"
      },
      attrs: {
        src: require("@/assets/icons/未选择.png")
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.chose(item, _vm.treeData);
        }
      }
    })]) : _vm._e()]);
  })], 2)]), _c("div", {
    staticClass: "employee-selector-block2"
  }, [_c("div", {
    staticClass: "employee-selector-item2"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "row",
      "font-weight": "700"
    }
  }, [_c("div", [_vm._v("当前选中")]), _c("div", {
    staticClass: "settingcolor",
    staticStyle: {
      "margin-left": "6px",
      "margin-right": "6px"
    }
  }, [_vm._v(_vm._s(_vm.selectedListTemp.length))]), _c("div", [_vm._v("个分组")])]), _c("div", {
    staticClass: "settingcolor",
    on: {
      click: _vm.cleanT
    }
  }, [_vm._v("清空")])]), _c("div", {
    staticClass: "employee-selector"
  }, _vm._l(_vm.selectedListTemp, function (item, i) {
    return _c("div", {
      key: i
    }, [_c("div", {
      staticClass: "tree-item employee-selector-item"
    }, [_c("div", [_c("img", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "margin-right": "10px"
      },
      attrs: {
        src: require("@/assets/icons/person1.png")
      }
    }), _vm._v(" " + _vm._s(item.name) + " ")]), _c("img", {
      staticStyle: {
        width: "20px",
        height: "20px"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(item);
        }
      }
    })])]);
  }), 0)])]) : _vm._e()], 1)], 1)], 2)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };