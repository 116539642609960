import request from "@/utils/request";

// 以树形结构获取部门信息
export function getPositionInTree(params) {
  return request({
    url: "/hr/api/v1/positionInfos/listTree",
    method: "get",
    params: params
  });
}
export function getPositionAll(params) {
  return request({
    url: "/hr/api/v1/positionInfos/listAll",
    method: "get",
    params: params
  });
}
export function getPosition(params) {
  return request({
    url: "/hr/api/v1/positionInfos",
    method: "get",
    params: params
  });
}
export function getPositionInfoListPage(params) {
  return request({
    url: "/hr/api/v1/positionInfos/getPositionInfoListPage",
    method: "get",
    params: params
  });
}

// 查询公司下岗位类型 new
export function getListPositionType(companyId) {
  return request({
    url: "/hr/api/v1/positionInfos/listPositionType/".concat(companyId),
    method: "get"
  });
}

// 添加岗位
export function addPosition(data) {
  return request({
    url: "/hr/api/v1/positionInfos/",
    method: "post",
    data: data
  });
}
// 修改岗位
export function editPosition(data) {
  return request({
    url: "/hr/api/v1/positionInfos/",
    method: "put",
    data: data
  });
}

// 删除岗位
export function delPosition(data) {
  return request({
    url: "/hr/api/v1/positionInfos/" + data.id,
    method: "delete",
    data: data
  });
}