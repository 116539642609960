import selectPeople from "./selectPeople";
import selectPeople2 from "./selectPeople2";
import permissions from "./permissions";
// import { putDeliverRecords } from "./api";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "add"
    },
    info: {
      type: Object,
      default: function _default() {}
    }
  },
  components: {
    selectPeople: selectPeople,
    selectPeople2: selectPeople2,
    permissions: permissions
  },
  watch: {
    visible: function visible(v) {
      if (v) {
        this.current = 0;
        this.permissionsJson = this.info;
        if (this.type === "add") {
          this.show = 0;
          this.steps = [{
            title: "选择员工"
          }, {
            title: "配置权限"
          }, {
            title: "权限范围"
          }];
        }
        if (this.type === "edit") {
          this.show = 1;
          this.steps = [{
            title: "配置权限"
          }, {
            title: "权限范围"
          }];
        }
      }
    }
  },
  data: function data() {
    return {
      show: 0,
      current: 0,
      confirmLoading: false,
      steps: [{
        title: "选择员工"
      }, {
        title: "配置权限"
      }, {
        title: "权限范围"
      }],
      permissionsJson: {},
      jurisdiction: 0
    };
  },
  methods: {
    upDate: function upDate(e, val) {
      this.show = e;
      if (this.type === "add") {
        this.current = e;
      }
      if (this.type === "edit") {
        this.current = e - 1;
      }
      if (e === 1 && val) {
        this.permissionsJson = val;
      }
      if (e === 2 && val) {
        this.jurisdiction = val;
      }
      if (e === "success") {
        this.$emit("update:visible", false);
        this.$emit("upDate");
      }
    },
    upDate2: function upDate2() {
      this.show = this.show - 1;
    }
  }
};