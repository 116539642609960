import _typeof from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  var date;
  if (_typeof(time) === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + "月" + d.getDate() + "日" + d.getHours() + "时" + d.getMinutes() + "分";
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  var search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, " ") + '"}');
}
export function getUrlKey(name) {
  return decodeURIComponent((new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.href) || [undefined, ""])[1].replace(/\+/g, "%20")) || null;
}
// 年月日，空值返回今天
export function formatDateDefault(value) {
  var date = value ? new Date(value) : new Date();
  var Y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();
  var H = date.getHours();
  var i = date.getMinutes();
  var s = date.getSeconds();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  if (H < 10) {
    H = "0" + H;
  }
  if (i < 10) {
    i = "0" + i;
  }
  if (s < 10) {
    s = "0" + s;
  }
  var t = Y + "-" + m + "-" + d;
  return t;
}
// 年月日
export function formatDate(value) {
  if (isNaN(value) || value === "" || value == null) {
    return "";
  }
  var date = new Date(value);
  var Y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();
  var H = date.getHours();
  var i = date.getMinutes();
  var s = date.getSeconds();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  if (H < 10) {
    H = "0" + H;
  }
  if (i < 10) {
    i = "0" + i;
  }
  if (s < 10) {
    s = "0" + s;
  }
  var t = Y + "-" + m + "-" + d;
  return t;
}
// 年月日时分秒
export function formatDateAll(value) {
  if (isNaN(value) || value === "" || value == null) {
    return "";
  }
  var date = new Date(value);
  var Y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();
  var H = date.getHours();
  var i = date.getMinutes();
  var s = date.getSeconds();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  if (H < 10) {
    H = "0" + H;
  }
  if (i < 10) {
    i = "0" + i;
  }
  if (s < 10) {
    s = "0" + s;
  }
  var t = Y + "-" + m + "-" + d + " " + H + ":" + i + ":" + s;
  return t;
}

// 获取当天多少年后的日期
export function afterYear(now, num) {
  var d = new Date(now);
  var yy1 = d.getFullYear() + num;
  var mm1 = d.getMonth() + 1;
  var dd1 = d.getDate();
  if (mm1 === 2) {
    var monthEndDate = new Date(yy1, mm1, 0).getDate();
    if (dd1 > monthEndDate) {
      dd1 = monthEndDate;
    }
  }
  if (mm1 < 10) {
    mm1 = "0" + mm1;
  }
  if (dd1 < 10) {
    dd1 = "0" + dd1;
  }
  return yy1 + "-" + mm1 + "-" + dd1;
}
/*
  num 获取当天多少天后的日期
  */

export function afterTime(now, num) {
  num = parseInt(num);
  var d = new Date(now);
  d.setDate(d.getDate() + num);
  var yy1 = d.getFullYear();
  var mm1 = d.getMonth() + 1; // 因为getMonth（）返回值是 0（一月） 到 11（十二月） 之间的一个整数。所以要给其加1
  var dd1 = d.getDate();
  if (mm1 < 10) {
    mm1 = "0" + mm1;
  }
  if (dd1 < 10) {
    dd1 = "0" + dd1;
  }
  return yy1 + "-" + mm1 + "-" + dd1;
}
// js判断距离今天时间
export function dateDiff(date1, date2, type) {
  date1 = typeof date1 === "string" ? new Date(date1) : date1;
  date1 = date1.getTime();
  date2 = typeof date2 === "string" ? new Date(date2) : date2;
  date2 = date2.getTime();
  type = type || "hour";
  var diffValue = Math.abs(date2 - date1);
  var second = 1000;
  var minute = second * 60;
  var hour = minute * 60;
  var day = hour * 24;
  var month = day * 30;
  var year = month * 12;
  var timeType = {
    second: second,
    minute: minute,
    hour: hour,
    day: day,
    month: month,
    year: year
  };
  return (diffValue / timeType[type]).toFixed(1);
  // Math.ceil(diffValue / timeType[type]);
}

export function checkInAndOutDate(data_start, data_end) {
  var date1 = Number(data_start.replace(/-/g, ""));
  var date2 = Number(data_end.replace(/-/g, ""));
  return date2 >= date1;
}
export function isJson(data) {
  var result = false;
  try {
    JSON.parse(data);
    result = true;
  } catch (err) {
    result = false;
  }
  return result;
}
export function parseQueryString(url) {
  var obj = {};
  var keyValue = [];
  var key = "";
  var value = "";
  var paraString = url.substring(url.indexOf("?") + 1, url.length).split("&");
  for (var i in paraString) {
    keyValue = paraString[i].split("=");
    key = keyValue[0];
    value = keyValue[1];
    obj[key] = value;
  }
  return obj;
}
export function buildTabUrl(tabKey, queryKey) {
  var query = this.parseQueryString(window.location.href);
  delete query[queryKey];
  query[queryKey] = tabKey;
  var newUrl = window.location.href.split("?")[0];
  newUrl = "".concat(newUrl).concat(this.params2Query(query));
  window.history.replaceState(null, null, newUrl);
}
export function params2Query(params) {
  if (!params) {
    return "";
  }
  var keys = Object.keys(params);
  var url = "?";
  for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
    var key = _keys[_i];
    if (!(params[key] === null || params[key] === undefined)) {
      url += "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(params[key]), "&");
    }
  }
  url = url.substring(0, url.length - 1);
  return url;
}

// 判断当前url是否包含传入字符串
export function isContainByString(val) {
  // true 包含
  // false 不包含
  return location.hash.indexOf(val) !== -1;
}

// 数组去重
export function removalDuplicate(a, b) {
  var nA = a.slice();
  for (var i = 0; i < b.length; i++) {
    for (var j = 0; j < nA.length; j++) {
      if (nA[j].id === b[i].id) {
        nA.splice(j, 1);
        j = j - 1;
      }
    }
  }
  return nA;
}