import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.constructor.js";
import { findSocProgRelation } from "@/api/shebao/shebaoMethod.js";
import { AreaPicker as city } from "xqjr-plugin-common";
import shebaoTable from "./shebaoTable";
import onlyAmountTable from "./onlyAmountTable";
import { isContainByString } from "@/utils/index";
import SelectWithInputAdd from "xqjr-plugin-form/components/XqSelectWithInputAdd.vue";
export default {
  components: {
    city: city,
    shebaoTable: shebaoTable,
    onlyAmountTable: onlyAmountTable,
    SelectWithInputAdd: SelectWithInputAdd
  },
  data: function data() {
    return {
      socAccount: undefined,
      meAccount: undefined,
      pfAccount: undefined,
      isContainByString: isContainByString,
      showBackground: false,
      progId: "",
      progName: "",
      deductionsDate: 1,
      servercost: 0,
      onlySocAmount: false,
      socCityName: "",
      socCity: "",
      onlyAmount: 0
    };
  },
  created: function created() {
    if (this.$route.query.id) {
      this.progId = this.$route.query.id;
      this.toInquire();
    }
  },
  methods: {
    hideMenu: function hideMenu() {
      this.showBackground = false;
      if (this.onlyAmount === 0) {
        this.$refs.bili.hideItemShow();
      } else {
        this.$refs.guding.hideItemShow();
      }
    },
    showMenu: function showMenu() {
      this.showBackground = true;
    },
    // 处理城市
    handleCity: function handleCity(e) {
      if (e.value.length > 0) {
        var value = e.value;
        var label = e.label;
        this.socCity = value[value.length - 1].toString();
        if (label.length && label.length === 3) {
          this.socCityName = label[0] + "/" + label[1] + "/" + label[2];
        } else if (label.length && label.length === 2) {
          this.socCityName = label[0] + "/" + label[1];
        } else if (label.length && label.length === 1) {
          this.socCityName = label[0];
        }
      } else {
        this.socCity = "";
        this.socCityName = "";
      }
    },
    // 查询方案详情
    toInquire: function toInquire() {
      var _this = this;
      findSocProgRelation({
        progId: this.progId,
        companyId: this.isContainByString("contractAdmin") ? "0" : this.$store.state.hr.companyId
      }).then(function (res) {
        _this.progName = res.data.progName;
        _this.socCityName = res.data.socCityName;
        _this.socCity = res.data.socCity.toString();
        _this.onlySocAmount = res.data.onlySocAmount;
        _this.onlyAmount = Number(res.data.onlySocAmount);
        _this.deductionsDate = res.data.deductionsDate;
        _this.servercost = res.data.serviceCost;
        _this.socAccount = res.data.socAccount;
        _this.meAccount = res.data.meAccount;
        _this.pfAccount = res.data.pfAccount;
      });
    }
  }
};