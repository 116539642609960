import FormJson from "./FormJson";
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
import { retrieveReq, updateReq } from "./api";
var curdMixin = new XqCurdMixin({
  retrieveReq: retrieveReq,
  updateReq: updateReq,
  FormJson: FormJson,
  tableConfigKey: "table"
});
export default {
  mixins: [curdMixin],
  data: function data() {
    return {
      pageSize: 20
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {
          "orders[0].desc": true,
          "orders[0].property": "gmtCreate"
        }
      };
    }
  },
  methods: {
    afterRetrieve: function afterRetrieve() {
      this.$store.dispatch("app/loadTrialCount");
    },
    onFormChangeCustom: function onFormChangeCustom(value, key) {
      if (key === "auditStatus") {
        if (value === 1 || value === 2) {
          this.$set(this.FormJson["config"]["update"], "fieldKeys", {
            auditStatus: {}
          });
        } else if (value === 3) {
          this.$set(this.FormJson["config"]["update"]["fieldKeys"], "denialReason", {});
        }
      }
    }
  }
};