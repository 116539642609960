var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table"
  }, [_vm._m(0), _vm.dataSourceCopy.length > 0 ? _c("Draggable", {
    attrs: {
      group: "sjgk",
      animation: "300",
      filter: ".undraggable"
    },
    on: {
      end: _vm.onDragEnd
    },
    model: {
      value: _vm.dataSourceCopy,
      callback: function callback($$v) {
        _vm.dataSourceCopy = $$v;
      },
      expression: "dataSourceCopy"
    }
  }, _vm._l(_vm.dataSourceCopy, function (li, idx) {
    return _c("div", {
      key: li.fieldName,
      staticClass: "row",
      class: li.fieldSource === 1 ? "undraggable" : "draggable"
    }, [_c("div", {
      staticClass: "span"
    }, [_vm._v(_vm._s(li.fieldName))]), _c("div", {
      staticClass: "span"
    }, [_vm._v(_vm._s(_vm.fieldTypeMap[li.fieldType]))]), _c("div", {
      staticClass: "action"
    }, [_c("a-space", [_vm.editIcon ? _c("a-icon", {
      class: {
        able: li.fieldSource !== 1
      },
      attrs: {
        type: "edit"
      },
      on: {
        click: function click($event) {
          return _vm.onEditItem(idx, li.fieldSource === 1);
        }
      }
    }) : _vm._e(), _c("a-popconfirm", {
      attrs: {
        title: "确认要删除字段吗"
      },
      on: {
        confirm: function confirm($event) {
          return _vm.onDelItem(idx, li.fieldSource === 1);
        }
      }
    }, [_vm.deleteIcon ? _c("a-icon", {
      class: {
        able: li.fieldSource !== 1
      },
      attrs: {
        type: "delete"
      }
    }) : _vm._e()], 1), _c("a-icon", {
      class: {
        able: li.fieldSource !== 1
      },
      attrs: {
        type: "drag"
      }
    })], 1)], 1)]);
  }), 0) : _c("a-empty", {
    staticClass: "empty"
  }, [_c("div", {
    attrs: {
      slot: "image"
    },
    slot: "image"
  }), _c("div", {
    staticStyle: {
      padding: "16px",
      color: "#ddd"
    },
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_vm._v("暂无数据")])]), _vm.addIcon ? _c("div", {
    staticClass: "addBtn",
    on: {
      click: _vm.onAddItem
    }
  }, [_c("a-icon", {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 新增自定义字段 ")], 1) : _vm._e(), _c("a-modal", {
    staticClass: "form",
    attrs: {
      title: _vm.modalTitle,
      visible: _vm.modalVisible,
      confirmLoading: _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    staticClass: "form",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "fieldName",
    attrs: {
      help: "1-20个字符，不能与现有字段重复",
      rules: [{
        required: true,
        message: "请输入字段名称"
      }],
      label: "字段名称",
      prop: "fieldName"
    }
  }, [_c("a-input", {
    attrs: {
      allowClear: "",
      placeholder: "请输入字段名称",
      "max-length": 20
    },
    model: {
      value: _vm.form.fieldName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldName", $$v);
      },
      expression: "form.fieldName"
    }
  })], 1), _c("a-form-model-item", {
    ref: "fieldType",
    attrs: {
      rules: [{
        required: true,
        message: "请选择字段类型"
      }],
      label: "字段类型",
      prop: "fieldType"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      allowClear: "",
      disabled: _vm.modalMode === "edit" ? true : false,
      placeholder: "请选择字段类型"
    },
    on: {
      change: _vm.fieldTypeChange
    },
    model: {
      value: _vm.form.fieldType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldType", $$v);
      },
      expression: "form.fieldType"
    }
  }, _vm._l(_vm.fieldTypeArr, function (item) {
    return _c("a-select-option", {
      key: item.k,
      attrs: {
        value: item.k
      }
    }, [_vm._v(_vm._s(item.v))]);
  }), 1)], 1), _vm.form.fieldType == "1" ? _c("a-form-model-item", {
    attrs: {
      label: "选项值",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 22
      }
    }
  }, [_vm._l(_vm.form.options, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        display: "flex",
        "align-items": "center",
        width: "80%",
        margin: "0 auto"
      }
    }, [_c("div", {
      staticStyle: {
        width: "120px"
      }
    }, [_vm._v(_vm._s("\u9009\u9879 ".concat(index)))]), _c("a-input", {
      attrs: {
        allowClear: "",
        placeholder: "请输入字段名称",
        "max-length": 15
      },
      model: {
        value: _vm.form.options[index],
        callback: function callback($$v) {
          _vm.$set(_vm.form.options, index, $$v);
        },
        expression: "form.options[index]"
      }
    }), _vm.form.options.length > 1 ? _c("a-icon", {
      staticStyle: {
        margin: "0 15px",
        cursor: "pointer"
      },
      attrs: {
        disabled: _vm.form.options.length === 1,
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.removeOption(index);
        }
      }
    }) : _vm._e()], 1);
  }), _c("div", {
    staticStyle: {
      "text-align": "center",
      "margin-top": "12px"
    }
  }, [_c("a-button", {
    staticStyle: {
      width: "80%"
    },
    attrs: {
      type: "dashed"
    },
    on: {
      click: _vm.addDomain
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 添加自定义选项 ")], 1)], 1)], 2) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "row head"
  }, [_c("div", {
    staticClass: "span"
  }, [_vm._v("字段名称")]), _c("div", {
    staticClass: "span"
  }, [_vm._v("字段类型")]), _c("div", {
    staticClass: "action"
  }, [_vm._v("操作")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };