var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goback
    }
  }, [_vm._v("已离职列表")]), _c("div", {
    staticClass: "table m24"
  }, [_c("a-input-search", {
    staticClass: "mb24",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      placeholder: "搜索员工姓名"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.empName,
      callback: function callback($$v) {
        _vm.empName = $$v;
      },
      expression: "empName"
    }
  }), _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.openEmployeesMove("批量恢复在职");
      }
    }
  }, [_vm._v("批量恢复在职")]), _c("a-button", {
    staticClass: "fr mr12",
    on: {
      click: function click($event) {
        return _vm.openEmployeesMove("批量删除");
      }
    }
  }, [_vm._v("批量删除")]), _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "row-key": function rowKey(record) {
        return record.id;
      },
      "data-source": _vm.data,
      pagination: _vm.pagination,
      size: "middle"
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "operate",
      fn: function fn(text, record) {
        return [_c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.detail(record);
            }
          }
        }, [_vm._v("查看")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.restore(record);
            }
          }
        }, [_vm._v("恢复在职")])];
      }
    }])
  })], 1), _c("employeesMoveAdvancedScreening", {
    ref: "employeesMove",
    on: {
      getNextStep: _vm.getNextStep
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };