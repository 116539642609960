import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import { getAllEmp } from "@/api/outsourcing/outsourcing";
import { getSalaryCalculateRuleGroups } from "@/api/outsourcing/outrules";
import moment from "moment";
export default {
  props: {
    status: {
      type: Number,
      default: 2,
      require: true
    },
    yearly: {
      type: String,
      default: "",
      require: true
    },
    monthly: {
      type: String,
      default: "",
      require: true
    },
    show: {
      type: Boolean,
      default: false
    },
    number: {
      type: Number,
      default: 0
    },
    templateId: {
      type: Number,
      default: 0
    },
    defaultSelectedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectedList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isOnlineOrLeave: {
      type: Boolean,
      default: true
    },
    groupId: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      dataList: [],
      nameOrPhone: "",
      spinning: false,
      selectedRowKeys: [],
      copyVisible: false,
      importDataLength: 0,
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 16
      },
      mode: "1",
      form: {},
      labelList: [],
      treeData: [],
      errorState: false,
      selectedListTemp: [],
      chosenList: [],
      serviceFeeRuleList: [],
      rulusList: [],
      isAllSelected: false,
      seniorVisible: false,
      checkStatue: 0,
      textContent: "",
      selectedKeysBeforeSearch: [],
      postTypeValue: 3,
      pageSizeOptions: ["10", "20", "50", "100"],
      pageSize: 10,
      current: 1,
      total: 50,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      }
    };
  },
  watch: {
    textContent: function textContent(e) {
      if (e) {
        this.errorState = false;
      }
    },
    show: function show(val) {
      if (val) {
        this.selectedListTemp = JSON.parse(JSON.stringify(this.selectedList));
        this.treeData = [];
        this.current = 1;
        this.postTypeValue = 3;
        this.mode = "1";
        this.importDataLength = this.selectedList.length;
        // this.onPageChange(1);
        this.spinning = false;
        this.getSelectDepTree();
      }
    },
    month: function month(val) {}
  },
  created: function created() {},
  methods: {
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      // this.checkStatue = 0;
      this.getSeniorList();
    },
    // 分页变化
    update: function update() {
      var _this = this;
      this.rulusList = [];
      getSalaryCalculateRuleGroups({
        companyId: this.$store.state.hr.companyId
      }).then(function (res) {
        if (res.data.length > 0) {
          res.data.map(function (item) {
            if (item.ruleType === 1) {
              _this.rulusList.push(item);
            }
            if (item.ruleType === 2) {
              _this.serviceFeeRuleList.push(item);
            }
          });
        }
      });
    },
    onPageChange: function onPageChange(pageNumber) {
      this.current = pageNumber;
      this.getSelectDepTree();
    },
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = 1;
      this.getSelectDepTree();
    },
    choseAll: function choseAll(data) {
      this.isAllSelected = !this.isAllSelected;
      var that = this;
      this.spinning = true;
      for (var j = 0, len = data.length; j < len; j++) {
        data[j].isSelected = that.isAllSelected;
        that.ergodicAll(data[j]);
      }
      setTimeout(function () {
        that.spinning = false;
      }, "10");
    },
    chose: function chose(item, data) {
      item.isSelected = !item.isSelected;
      this.onChange(item);
    },
    onChangeTab: function onChangeTab(e) {
      this.isAllSelected = false;
      this.current = 1;
      this.mode = e;
      this.getSelectDepTree();
    },
    onChange: function onChange(item) {
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.$nextTick(function () {
          that.ergodic(item);
        });
        that.spinning = false;
      }, "10");
    },
    ergodic: function ergodic(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
          this.isAllSelected = false;
          this.treeData.some(function (e) {
            if (e.id === item.id) {
              e.isSelected = false;
              return true;
            }
          });
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
          var count = this.treeData.length;
          var allSelect = true;
          for (var i = 0; i < count; i++) {
            if (!this.treeData[i].isSelected) {
              allSelect = false;
              break;
            }
          }
          this.isAllSelected = allSelect;
        }
      }
    },
    ergodicAll: function ergodicAll(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
        }
      }
    },
    // 获取部门树
    getSelectDepTree: function getSelectDepTree(query) {
      var _this2 = this;
      if (query) {
        this.current = 1;
      }
      var params = {
        // onlyCurrentDep: true,
        // onlyUser: true,
        pageSize: this.pageSize,
        pageNo: this.current,
        groupId: this.groupId
      };
      if (this.nameOrPhone) {
        params["nameOrPhone"] = this.nameOrPhone;
      }
      this.spinning = true;
      getAllEmp(params).then(function (res) {
        _this2.spinning = false;
        if (res.data.entities === null) {
          _this2.$message.success("暂无数据");
          _this2.treeData = [];
          _this2.total = 0;
          _this2.isAllSelected = false;
        } else {
          _this2.treeData = _this2.formatCompanyData(res.data.entities);
          _this2.total = res.data.entityCount;
          var count = res.data.entities.length;
          _this2.pageSize = res.data.pageSize;
          _this2.current = res.data.pageNo;
          var allSelect = true;
          _this2.spinning = false;
          if (count !== 0) {
            for (var i = 0; i < count; i++) {
              if (!_this2.treeData[i].isSelected) {
                allSelect = false;
                break;
              }
            }
            _this2.isAllSelected = allSelect;
          } else {
            _this2.isAllSelected = false;
          }
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this2.spinning = false;
      });
    },
    formatCompanyData: function formatCompanyData(company) {
      var parent = [];
      for (var key = 0; key < company.length; key++) {
        var selected = false;
        this.selectedListTemp.forEach(function (item, index) {
          if (item.id === company[key].id) {
            selected = true;
          }
        });
        var emp = {
          id: company[key].id,
          name: company[key].name,
          phone: company[key].phone,
          idCard: company[key].idCard,
          depName: company[key].depName,
          positionName: company[key].positionName,
          isSelected: selected
        };
        // this.selectedListTemp = this.selectedList;
        this.selectedListTemp.forEach(function (item, index) {
          item.isSelected = true;
        });
        parent.push(emp);
      }
      this.importDataLength = 0; // 清空传入的默认值
      return parent;
    },
    searchPeople: function searchPeople(query) {
      this.pageSize = 10;
      this.current = 1;
      this.nameOrPhone = query;
      this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
        return item.id;
      });
      this.getSelectDepTree(query);
    },
    changePeople: function changePeople(e) {
      if (e.target.value === "") {
        this.pageSize = 10;
        this.current = 1;
        this.getSelectDepTree();
        this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
          return item.id;
        });
      }
    },
    deleteAllItem: function deleteAllItem() {
      var _this3 = this;
      this.spinning = true;
      this.selectedListTemp.forEach(function (item) {
        item.isSelected = false;
        var that = _this3;
        setTimeout(function () {
          that.$nextTick(function () {
            that.ergodic(item);
          });
        });
      });
      this.spinning = false;
    },
    deleteItem: function deleteItem(item, index) {
      this.spinning = true;
      item.isSelected = false;
      var that = this;
      setTimeout(function () {
        that.$nextTick(function () {
          that.ergodic(item);
        });
        that.spinning = false;
      }, 1000);
    },
    ok: function ok() {
      if (this.selectedListTemp.length === 0) {
        this.$message.success("请选择人员");
        return;
      }
      console.log(this.selectedListTemp);
      // if (this.selectedListTemp.length > 50) {
      //   this.$message.success("一次性最多选择50人");
      //   return;
      // }
      this.$emit("update:selectedList", this.selectedListTemp);
      this.$emit("update:selectThree", this.selectedListTemp.slice(0, 3).map(function (item) {
        return item.name;
      }).join(","));
      this.$emit("update:show", false);
      this.$emit("change", this.selectedListTemp);
    },
    cancel: function cancel() {
      this.$emit("update:show", false);
    }
  }
};