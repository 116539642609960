var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "main-sidebar-height",
    staticStyle: {
      "padding-bottom": "24px",
      "background-color": "#fff"
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goback
    }
  }, [_vm._v(" 批量离职员工 ")]), _vm.dataDetail2 === null || _vm.dataDetail2.length === 0 ? _c("div", [_c("a-spin", {
    attrs: {
      spinning: _vm.uploading
    }
  }, [_c("div", {
    staticClass: "ant-upload-select-text-box",
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-upload", {
    attrs: {
      name: "file",
      multiple: false,
      accept: ".xls, .xlsx, .xlsm",
      "show-upload-list": false,
      action: _vm.action,
      "custom-request": _vm.beforeUpload1
    }
  }, [_c("img", {
    staticStyle: {
      width: "139px",
      height: "106px"
    },
    attrs: {
      src: _vm.uploadImg
    }
  }), _c("div", {
    staticClass: "ant-modal-body"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#666"
    }
  }, [_vm._v("点击添加文件或将文件拖拽到此处")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#999"
    }
  }, [_vm._v("(文件大小不超过5M)")])])])], 1)]), _c("div", {
    staticClass: "tipBox"
  }, [_c("a-row", {
    staticStyle: {
      color: "#252525",
      "font-size": "16px",
      "font-weight": "500"
    }
  }, [_vm._v(" 温馨提示： ")]), _c("a-row", [_c("a-col", {
    staticStyle: {
      "line-height": "25px",
      color: "#999"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "6px"
    }
  }, [_vm._v(" 首先下载标准模板，填写信息后上传"), _c("a-button", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      size: "small",
      type: "link"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("下载标准模板")])], 1), _c("div", [_vm._v("1.请将要上传的内容放在模板的离职员工中,单次导入员工上限为2000条")]), _c("div", [_vm._v("2.身份证号、离职原因、离职日期为必填项，顺序可调整，但不可删除")]), _c("div", [_vm._v("3.身份证号用来判断员工的唯一性")]), _c("div", [_vm._v("4.若Excel文件设有权限密码，需取消Excel文件密码，否则系统无法识别")])])], 1)], 1)], 1) : _c("div", {
    staticStyle: {
      display: "flex",
      "padding-top": "15px",
      "padding-left": "24px",
      "padding-right": "24px",
      "flex-direction": "column",
      width: "100%"
    }
  }, [_c("div", [_c("div", {
    staticStyle: {
      "padding-left": "10px",
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      height: "35px",
      width: "100%",
      "background-color": "#fffbe6",
      "border-style": "solid",
      "border-color": "#ffe58f",
      "border-width": "1px",
      "border-radius": "5px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "5px"
    },
    attrs: {
      src: require("../../../assets/icons/重复.png")
    }
  }), _c("span", {
    attrs: {
      sytle: "color:#55565D;font-size:14px;"
    }
  }, [_vm._v(" 共" + _vm._s(_vm.allCount) + "条数据，成功导入" + _vm._s(_vm.successCount) + "条数据， "), _c("label", {
    staticClass: "redc"
  }, [_vm._v(_vm._s(_vm.errorCount))]), _vm._v("条数据导入失败 ")])])]), _c("div", {
    staticClass: "header-title-box"
  }, [_vm._m(0), _c("div", {
    staticClass: "btn-box"
  }, [_c("a-button", {
    staticClass: "fr",
    attrs: {
      type: "primary",
      disabled: _vm.uploading
    },
    on: {
      click: _vm.BatchGet
    }
  }, [_vm._v(_vm._s(_vm.uploading ? "数据导出中..." : "导出错误数据"))])], 1)]), _c("a-table", {
    attrs: {
      columns: _vm.columnDialogDetailguding,
      "data-source": _vm.dataDetail2,
      scroll: {
        x: 800
      },
      "row-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "errorInfo",
      fn: function fn(text) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "topLeft"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(text))])]), _vm._v(" " + _vm._s(text) + " ")], 2)];
      }
    }, {
      key: "empName",
      fn: function fn(text, record) {
        return [_c("div", {
          style: {
            color: _vm.getRed("empName", record) ? "#ff0000" : "#000000"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])];
      }
    }, {
      key: "idCard",
      fn: function fn(text, record) {
        return [_c("div", {
          style: {
            color: _vm.getRed("idCard", record) ? "#ff0000" : "#000000"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])];
      }
    }, {
      key: "leaveOfficeReason",
      fn: function fn(text, record) {
        return [_c("div", {
          style: {
            color: _vm.getRed("leaveOfficeReason", record) ? "#ff0000" : "#000000"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])];
      }
    }, {
      key: "leaveOfficeDate",
      fn: function fn(text, record) {
        return [_c("div", {
          style: {
            color: _vm.getRed("leaveOfficeDate", record) ? "#ff0000" : "#000000"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])];
      }
    }, {
      key: "remark",
      fn: function fn(text, record) {
        return [_c("div", {
          style: {
            color: _vm.getRed("remark", record) ? "#ff0000" : "#000000"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])];
      }
    }, {
      key: "stopSocDate",
      fn: function fn(text, record) {
        return [_c("div", {
          style: {
            color: _vm.getRed("stopSocDate", record) ? "#ff0000" : "#000000"
          }
        }, [_vm._v(" " + _vm._s(text === null ? "-" : text) + " ")])];
      }
    }, {
      key: "stopMeDate",
      fn: function fn(text, record) {
        return [_c("div", {
          style: {
            color: _vm.getRed("stopMeDate", record) ? "#ff0000" : "#000000"
          }
        }, [_vm._v(" " + _vm._s(text === null ? "-" : text) + " ")])];
      }
    }, {
      key: "sealedDate",
      fn: function fn(text, record) {
        return [_c("div", {
          style: {
            color: _vm.getRed("sealedDate", record) ? "#ff0000" : "#000000"
          }
        }, [_vm._v(" " + _vm._s(text === null ? "-" : text) + " ")])];
      }
    }, {
      key: "whetherSocDecrease",
      fn: function fn(text, record) {
        return [_c("div", {
          style: {
            color: _vm.getRed("whetherSocDecrease", record) ? "#ff0000" : "#000000"
          }
        }, [_vm._v(" " + _vm._s(record.whetherSocDecrease ? "是" : "否") + " ")])];
      }
    }, {
      key: "whetherMeDecrease",
      fn: function fn(text, record) {
        return [_c("div", {
          style: {
            color: _vm.getRed("whetherMeDecrease", record) ? "#ff0000" : "#000000"
          }
        }, [_vm._v(" " + _vm._s(record.whetherMeDecrease ? "是" : "否") + " ")])];
      }
    }, {
      key: "whetherSealed",
      fn: function fn(text, record) {
        return [_c("div", {
          style: {
            color: _vm.getRed("whetherSealed", record) ? "#ff0000" : "#000000"
          }
        }, [_vm._v(" " + _vm._s(record.whetherSealed ? "是" : "否") + " ")])];
      }
    }])
  })], 1), _c("a-modal", {
    attrs: {
      title: "离职Excel导入",
      visible: _vm.isDaoru,
      width: "600px"
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消导入")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.ExportHandle
    }
  }, [_vm._v("继续导入")])], 1), _c("div", {
    staticClass: "modal-box2",
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }), _c("div", {
    staticStyle: {
      "background-color": "#faf9f9",
      "border-radius": "5px",
      "padding-left": "15px",
      "padding-top": "15px",
      "padding-right": "15px",
      "padding-bottom": "15px"
    }
  }, [_c("div", {
    attrs: {
      sytle: "font-size:16px;color:#3C3D43;font-weight:bold;"
    }
  }, [_vm._v("温馨提示")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4",
      "margin-top": "8px"
    }
  }, [_vm._v(" 表格中的员工导入后直接离职，已离职的员工无法发起合同。 请先签署合同，再办理离职员工 ")])])], 2), _c("a-modal", {
    attrs: {
      title: "批量离职",
      width: "500px"
    },
    on: {
      ok: _vm.handleCancel
    },
    model: {
      value: _vm.dialogFailVisible,
      callback: function callback($$v) {
        _vm.dialogFailVisible = $$v;
      },
      expression: "dialogFailVisible"
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleLizhi
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticStyle: {
      "padding-left": "10px",
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      height: "35px",
      width: "100%"
    }
  }, [_c("img", {
    staticStyle: {
      width: "25px",
      height: "25px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("../../../assets/icons/duihao.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "15px",
      "font-weight": "700"
    }
  }, [_vm._v("共" + _vm._s(_vm.allCount) + "条数据，全部导入成功！")])])], 2)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticStyle: {
      "font-size": "15px",
      "font-weight": "700",
      "font-color": "#252525"
    }
  }, [_vm._v("错误信息")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };