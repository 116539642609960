var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", [_c("div", {
    staticClass: "head"
  }, [_c("a-button", {
    staticStyle: {
      color: "#d8d8d8"
    },
    attrs: {
      icon: "left",
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")])], 1), _c("div", {
    staticClass: "operation"
  }, [_c("p", {
    staticStyle: {
      "font-size": "14px",
      color: "#222426"
    }
  }, [_vm._v(" 技术部" + _vm._s(_vm.personalPeriod) + " "), _c("span", {
    staticStyle: {
      "font-size": "14px",
      color: "#777777"
    }
  }, [_vm._v(" （计薪周期：" + _vm._s(this.monthly) + "月01日-" + _vm._s(this.monthly) + "月" + _vm._s(_vm.monthDateCount) + "日） ")])])]), _c("div", {
    staticClass: "fix-item-wrapper"
  }, [_c("div", {
    staticClass: "fix-item-one"
  }, [_c("p", {
    staticClass: "fix-item-name"
  }, [_vm._v("专项附加扣除累计")])]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("请从税务局系统导出专项附加扣除累计报表后，直接导入本系统参与每月个税计算")])])]), _c("div", {
    staticClass: "fix-item-three"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_c("a-button", {
    attrs: {
      type: "link",
      block: ""
    },
    on: {
      click: _vm.showSpecial
    }
  }, [_vm._v(" 导入专项附加扣除累计 ")])], 1)])])]), _c("div", {
    staticClass: "fix-item-wrapper"
  }, [_c("div", {
    staticClass: "fix-item-one"
  }, [_c("p", {
    staticClass: "fix-item-name"
  }, [_vm._v("截止上月个税累计")])]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("若非纳税年度第一个月份开始使用系统，或上月个税累计值有误，请通过录入截止上月个税累计进行修改")])])])]), _c("div", [_c("a-button", {
    staticClass: "primary_bg",
    staticStyle: {
      width: "152px",
      height: "36px",
      "border-radius": "4px",
      "margin-left": "45%",
      "margin-bottom": "100px"
    },
    attrs: {
      disabled: _vm.empList.length <= 0,
      type: "primary"
    },
    on: {
      click: _vm.accountWage
    }
  }, [_vm._v(" 计算工资 ")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };