import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-layout", [_c("div", {
    staticClass: "header-title-box"
  }, [_c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("社保")])])]), _c("a-modal", {
    attrs: {
      title: "总计尾数处理",
      visible: _vm.editVisible
    },
    on: {
      ok: _vm.confirmAddType,
      cancel: _vm.handleDialCancel
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      form: _vm.typeForm,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      },
      colon: true
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "单位尾数处理"
    }
  }, [_c("a-select", {
    attrs: {
      value: _vm.isSheBao ? _vm.groupItemDefault : _vm.groupItemDefault2
    },
    on: {
      change: _vm.weiShuHandleChange
    }
  }, _vm._l(_vm.groupList, function (group, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: group.id
      }
    }, [_vm._v(" " + _vm._s(group.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "个人尾数处理"
    }
  }, [_c("a-select", {
    attrs: {
      value: _vm.isSheBao ? _vm.GgroupItemDefault : _vm.GgroupItemDefault2
    },
    on: {
      change: _vm.GweiShuHandleChange
    }
  }, _vm._l(_vm.groupList, function (group, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: group.id
      }
    }, [_vm._v(" " + _vm._s(group.name) + " ")]);
  }), 1)], 1)], 1)], 1)]), _c("a-row", [_c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-table", {
    attrs: {
      "data-source": _vm.shebaoNewList,
      pagination: false,
      "row-key": "defaultType",
      columns: _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "itemName",
      fn: function fn(text, record) {
        return record.defaultType < 200 ? _c("span", {}, [_vm._v(_vm._s(text))]) : _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          },
          attrs: {
            "validate-status": text.length > 0 ? "success" : "error",
            help: text.length > 0 ? "" : "必填项"
          }
        }, [_c("a-input", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            placeholder: "项目名称",
            value: record.itemName
          },
          on: {
            change: function change(e) {
              return _vm.handleName(e, record, _vm.index, _vm.shebaoNewList);
            }
          }
        })], 1);
      }
    }, {
      key: "cardinalityDefaultAmount",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: true,
            expression: "true"
          }],
          staticStyle: {
            "margin-bottom": "0px",
            width: "100px"
          }
        }, [_c("a-input-number", {
          attrs: {
            value: record.cardinalityDefaultAmount,
            min: 0,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleDefault(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "amountCompany",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            value: record.amountCompany,
            min: 0,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleCompanyAmount(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "amountPersonal",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            disabled: record.itemName === "工伤保险",
            value: record.amountPersonal,
            min: 0,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handlePersonalAmount(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "delete",
      fn: function fn(text, record) {
        return _c("span", {
          staticClass: "primary_color",
          staticStyle: {
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.deleteShebaoItem(record, 1);
            }
          }
        }, [_vm._v(" 删除 ")]);
      }
    }], null, true)
  }, [_c("span", {
    staticClass: "addCursor",
    attrs: {
      slot: "customTitle"
    },
    slot: "customTitle"
  }, [_c("span", {
    on: {
      click: _vm.handleItemShow
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "5px"
    }
  }, [_vm._v("项目")]), _c("a-icon", {
    attrs: {
      type: "plus-circle"
    }
  })], 1), _vm.itemShow ? _c("div", {
    staticClass: "checkboxWrapper"
  }, [_c("a-checkbox-group", {
    on: {
      change: _vm.handleCheckbox
    },
    model: {
      value: _vm.itemChecked,
      callback: function callback($$v) {
        _vm.itemChecked = $$v;
      },
      expression: "itemChecked"
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "101"
    }
  }, [_vm._v(" 养老保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "103"
    }
  }, [_vm._v(" 失业保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "104"
    }
  }, [_vm._v(" 工伤保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "106"
    }
  }, [_vm._v(" 企业(职业)年金 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "107"
    }
  }, [_vm._v(" 商业健康保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "108"
    }
  }, [_vm._v(" 税延养老保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "109"
    }
  }, [_vm._v(" 补充养老保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "111"
    }
  }, [_vm._v(" 残保金 ")])], 1)], 1)], 1), _c("a-divider"), _c("span", {
    on: {
      click: function click() {
        return _vm.addOtherItem(1);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v("其他项目")], 1)], 1) : _vm._e()])])], 1), _c("div", {
    staticClass: "header-title-box",
    staticStyle: {
      "margin-top": "50px"
    }
  }, [_c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("医保")])])]), _c("a-form", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      form: _vm.form
    }
  }, [_c("a-table", {
    attrs: {
      "data-source": _vm.yibaoNewList,
      pagination: false,
      "row-key": "defaultType",
      columns: _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "itemName",
      fn: function fn(text, record) {
        return record.defaultType < 200 ? _c("span", {}, [_vm._v(_vm._s(text))]) : _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          },
          attrs: {
            "validate-status": text.length > 0 ? "success" : "error",
            help: text.length > 0 ? "" : "必填项"
          }
        }, [_c("a-input", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            placeholder: "项目名称",
            value: record.itemName
          },
          on: {
            change: function change(e) {
              return _vm.handleName(e, record, _vm.index, _vm.yibaoNewList);
            }
          }
        })], 1);
      }
    }, {
      key: "cardinalityDefaultAmount",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: true,
            expression: "true"
          }],
          staticStyle: {
            "margin-bottom": "0px",
            width: "100px"
          }
        }, [_c("a-input-number", {
          attrs: {
            value: record.cardinalityDefaultAmount,
            min: 0,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleDefault(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "amountCompany",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            value: record.amountCompany,
            min: 0,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleCompanyAmount(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "amountPersonal",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            disabled: record.itemName === "生育保险",
            value: record.amountPersonal,
            min: 0,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handlePersonalAmount(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "delete",
      fn: function fn(text, record) {
        return _c("span", {
          staticClass: "primary_color",
          staticStyle: {
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.deleteShebaoItem(record, 2);
            }
          }
        }, [_vm._v(" 删除 ")]);
      }
    }], null, true)
  }, [_c("span", {
    staticClass: "addCursor",
    attrs: {
      slot: "customTitle"
    },
    slot: "customTitle"
  }, [_c("span", {
    on: {
      click: _vm.handleItemShow3
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "5px"
    }
  }, [_vm._v("项目")]), _c("a-icon", {
    attrs: {
      type: "plus-circle"
    }
  })], 1), _vm.itemShow3 ? _c("div", {
    staticClass: "checkboxWrapper"
  }, [_c("a-checkbox-group", {
    on: {
      change: _vm.handleCheckbox2
    },
    model: {
      value: _vm.itemChecked2,
      callback: function callback($$v) {
        _vm.itemChecked2 = $$v;
      },
      expression: "itemChecked2"
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "102"
    }
  }, [_vm._v(" 医疗保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "105"
    }
  }, [_vm._v(" 生育保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "110"
    }
  }, [_vm._v(" 补充(大病医疗)保险 ")])], 1)], 1)], 1), _c("a-divider"), _c("span", {
    on: {
      click: function click() {
        return _vm.addOtherItem(3);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v("其他项目")], 1)], 1) : _vm._e()])])], 1), _c("div", {
    staticClass: "totalBox"
  }, [_c("div", {
    staticClass: "company"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("社保单位总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getCompanyAll()))]), _c("span", {
    staticClass: "icon",
    staticStyle: {
      "margin-left": "10px",
      cursor: "pointer",
      color: "#000"
    },
    on: {
      click: _vm.comZong
    }
  }, [_c("a-icon", {
    staticStyle: {
      fontsize: "20px"
    },
    attrs: {
      type: "edit"
    }
  })], 1)]), _c("div", {
    staticClass: "person"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("社保个人总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPersonAll()))]), _c("span", {
    staticClass: "icon",
    staticStyle: {
      "margin-left": "10px",
      cursor: "pointer",
      color: "#000"
    },
    on: {
      click: _vm.comZong
    }
  }, [_c("a-icon", {
    staticStyle: {
      fontsize: "20px"
    },
    attrs: {
      type: "edit"
    }
  })], 1)])])], 1), _c("a-row", [_c("a-row", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm._v(" 公积金 "), _c("a-checkbox", {
    staticStyle: {
      "margin-left": "24px",
      "margin-right": "5px"
    },
    on: {
      change: _vm.jijinShowChange
    },
    model: {
      value: _vm.jijinShow,
      callback: function callback($$v) {
        _vm.jijinShow = $$v;
      },
      expression: "jijinShow"
    }
  }, [_vm._v("不缴公积金")])], 1)]), !_vm.jijinShow ? _c("a-row", [_c("a-table", {
    attrs: {
      "data-source": _vm.jijin,
      pagination: false,
      "row-key": "defaultType",
      columns: _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "itemName",
      fn: function fn(text, record) {
        return record.defaultType < 200 ? _c("span", {}, [_vm._v(_vm._s(text))]) : _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          },
          attrs: {
            "validate-status": text.length > 0 ? "success" : "error",
            help: text.length > 0 ? "" : "必填项"
          }
        }, [_c("a-input", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            placeholder: "项目名称",
            value: record.itemName
          },
          on: {
            change: function change(e) {
              return _vm.handleName(e, record, _vm.index, _vm.jijin);
            }
          }
        })], 1);
      }
    }, {
      key: "cardinalityDefaultAmount",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: true,
            expression: "true"
          }],
          staticStyle: {
            "margin-bottom": "0px",
            width: "100px"
          }
        }, [_c("a-input-number", {
          attrs: {
            value: record.cardinalityDefaultAmount,
            min: 0,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleDefault(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "amountCompany",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px",
            width: "100px"
          }
        }, [_c("a-input-number", {
          attrs: {
            value: record.amountCompany,
            min: 0,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleCompanyAmount(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "amountPersonal",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px",
            width: "100px"
          }
        }, [_c("a-input-number", {
          attrs: {
            disabled: record.itemName === "公积金",
            value: record.itemName === "公积金" ? record.amountPersonal = record.amountCompany : record.amountPersonal,
            min: 0,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handlePersonalAmount(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "delete",
      fn: function fn(text, record) {
        return _c("span", {
          staticClass: "primary_color",
          staticStyle: {
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.deleteShebaoItem(record, 3);
            }
          }
        }, [_vm._v(" 删除 ")]);
      }
    }], null, true)
  }, [_c("span", {
    staticClass: "addCursor",
    attrs: {
      slot: "customTitle"
    },
    slot: "customTitle"
  }, [_c("span", {
    on: {
      click: _vm.handleItemShow2
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "5px"
    }
  }, [_vm._v("项目")]), _c("a-icon", {
    attrs: {
      type: "plus-circle"
    }
  })], 1), _vm.itemShow2 ? _c("div", {
    staticClass: "checkboxWrapper"
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-checkbox", {
    on: {
      change: _vm.handleJijinCheckbox
    },
    model: {
      value: _vm.jijinChecked,
      callback: function callback($$v) {
        _vm.jijinChecked = $$v;
      },
      expression: "jijinChecked"
    }
  }, [_vm._v(" 公积金 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    on: {
      change: _vm.handleJijinCheckbox2
    },
    model: {
      value: _vm.jijinChecked2,
      callback: function callback($$v) {
        _vm.jijinChecked2 = $$v;
      },
      expression: "jijinChecked2"
    }
  }, [_vm._v(" 补充公积金 ")])], 1)], 1), _c("a-divider"), _c("span", {
    on: {
      click: function click() {
        return _vm.addOtherItem(2);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v("其他项目")], 1)], 1) : _vm._e()])])], 1) : _vm._e(), _c("div", {
    staticClass: "totalBox"
  }, [_c("div", {
    staticClass: "company"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("公积金单位总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfCompanyAll()))]), _c("span", {
    staticClass: "icon",
    staticStyle: {
      "margin-left": "10px",
      cursor: "pointer",
      color: "#000"
    },
    on: {
      click: _vm.comGJZong
    }
  }, [_c("a-icon", {
    staticStyle: {
      fontsize: "20px"
    },
    attrs: {
      type: "edit"
    }
  })], 1)]), _c("div", {
    staticClass: "person"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("公积金个人总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfPersonAll()))]), _c("span", {
    staticClass: "icon",
    staticStyle: {
      "margin-left": "10px",
      cursor: "pointer",
      color: "#000"
    },
    on: {
      click: _vm.comGJZong
    }
  }, [_c("a-icon", {
    staticStyle: {
      fontsize: "20px"
    },
    attrs: {
      type: "edit"
    }
  })], 1)])])], 1), _c("a-divider", {
    attrs: {
      dashed: ""
    }
  }), _c("a-row", [_c("div", {
    staticClass: "totalBox2"
  }, [_c("div", {
    staticClass: "company"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("社保总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfCompanyAll2()))])]), _c("div", {
    staticClass: "person"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("公积金总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfPersonAll2()))])])])])], 1), _c("a-modal", {
    attrs: {
      visible: _vm.isEnter,
      title: "修改确认"
    },
    on: {
      cancel: _vm.handleCancel,
      ok: _vm.handleOk
    }
  }, [_c("div", {
    staticClass: "fw500 fs18"
  }, [_vm._v("模板修改后，创建未来新账单时将自动根据修改后的方案进行生成，请选择以前的未归档账单是否更新")]), _c("div", {
    staticClass: "fs16 mt12"
  }, [_vm._v("缴纳基数和比例是否更新")]), _c("div", {
    staticClass: "mt16"
  }, [_c("a-radio-group", {
    on: {
      change: _vm.handleChangeInfo
    },
    model: {
      value: _vm.ChangeInfo,
      callback: function callback($$v) {
        _vm.ChangeInfo = $$v;
      },
      expression: "ChangeInfo"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("不更新未归档账单的缴纳基数和比例")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("同步更新所有未归档账单中的缴纳基数和比例")])], 1)], 1)]), _c("div", {
    staticStyle: {
      width: "85%",
      height: "50px",
      display: "flex",
      "justify-content": "center",
      position: "fixed",
      bottom: "20px",
      "align-items": "center",
      background: "#ffffff",
      "z-index": "1"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.unSaveMethod
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveMethod
    }
  }, [_vm._v("保存")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };