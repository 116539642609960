export default {
  fields: {
    name: {
      model: "name"
    },
    phone: {
      model: "phone"
    },
    licenseNumber: {
      model: "licenseNumber"
    }
  },
  config: {
    table: {
      fields: [{
        model: "name",
        title: "姓名"
      }, {
        model: "licenseNumber",
        title: "身份证号"
      }, {
        model: "phone",
        title: "手机号"
      }],
      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: true
      }
    }
  }
};