import request from "@/utils/request";
export function getCompanies(data) {
  return request({
    url: "/hr/api/v1/companies/".concat(data),
    method: "GET"
  });
}
// 根据用户所属的租户下的用工单位，查询功能模块列表
export function getSubjectListReq(params) {
  return request({
    url: "/admin/api/v1/subjectFunctionModulars/subjectList",
    method: "GET",
    params: params
  });
}
// 修改租户的某个用工单位，拥有的功能模块
export function editSubjectList(data) {
  return request({
    url: "/admin/api/v1/subjectFunctionModulars",
    method: "put",
    data: data
  });
}
// 查询公历表,补全前后日期分别到周一和周日
export function getCalendar(params) {
  return request({
    url: "/admin/api/v1/gregorianCalendars/month",
    method: "GET",
    params: params
  });
}
// 同步
export function synchronous(data) {
  return request({
    url: "/admin/api/v1/gregorianCalendars/syncHoliday",
    method: "POST",
    data: data
  });
}
// 编辑日期类型
export function editType(data) {
  return request({
    url: "/admin/api/v1/gregorianCalendars/updateDateType",
    method: "put",
    data: data
  });
}
// 查询合同模板,树状
export function selectTreeBySubjectId(data) {
  return request({
    url: "/contract/api/v1/categories/selectTreeBySubjectId",
    method: "GET",
    params: data
  });
}
// 查询审批列表树状
export function selectTreeByCompanyId(data) {
  return request({
    url: "/approval/api/v1/approFlows/selectTreeByCompanyId",
    method: "GET",
    params: data
  });
}
// 查询自定义模板
export function customTemplates(params) {
  return request({
    url: "/hr/api/v1/customTemplates",
    method: "GET",
    params: params
  });
}