import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
var columns = [{
  title: "定薪项目",
  dataIndex: "itemName",
  align: "itemName",
  key: "itemName",
  scopedSlots: {
    customRender: "itemName"
  },
  width: 150
}, {
  title: "试用期工资",
  dataIndex: "trial",
  align: "trial",
  key: "trial",
  scopedSlots: {
    customRender: "trial"
  },
  width: 150
}, {
  title: "转正后工资",
  dataIndex: "formal",
  align: "formal",
  key: "formal",
  scopedSlots: {
    customRender: "formal"
  },
  width: 150
}];
import { selectByCompanyId, detaiByempId, empWage, addSalaryTemplate } from "@/api/wages/file";
import { mapState } from "vuex";
var fixItems = [{
  id: 1,
  name: "基本工资",
  selected: true,
  isDefault: true
}, {
  id: 2,
  name: "交通补贴",
  selected: false,
  isDefault: false
}, {
  id: 3,
  name: "住房补贴",
  selected: false,
  isDefault: false
}, {
  id: 4,
  name: "全勤奖",
  selected: false,
  isDefault: false
}, {
  id: 5,
  name: "餐补",
  selected: false,
  isDefault: false
}, {
  id: 6,
  name: "高温补贴",
  selected: false,
  isDefault: false
}, {
  id: 7,
  name: "工龄补贴",
  selected: false,
  isDefault: false
}, {
  id: 8,
  name: "夜班津贴",
  selected: false,
  isDefault: false
}, {
  id: 9,
  name: "通讯补贴",
  selected: false,
  isDefault: false
}, {
  id: 10,
  name: "节假日补贴",
  selected: false,
  isDefault: false
}, {
  id: 11,
  name: "员工慰问金",
  selected: false,
  isDefault: false
}, {
  id: 12,
  name: "推荐奖",
  selected: false,
  isDefault: false
}, {
  id: 13,
  name: "职务津贴",
  selected: false,
  isDefault: false
}, {
  id: 14,
  name: "绩效工资",
  selected: false,
  isDefault: false
}, {
  id: 15,
  name: "季度奖",
  selected: false,
  isDefault: false
}, {
  id: 16,
  name: "销售提成",
  selected: false,
  isDefault: false
}, {
  id: 17,
  name: "计件工资",
  selected: false,
  isDefault: false
}, {
  id: 18,
  name: "工龄工资",
  selected: false,
  isDefault: false
}, {
  id: 19,
  name: "司龄工资",
  selected: false,
  isDefault: false
}, {
  id: 20,
  name: "职称工资",
  selected: false,
  isDefault: false
}, {
  id: 21,
  name: "技能工资",
  selected: false,
  isDefault: false
}, {
  id: 22,
  name: "学历工资",
  selected: false,
  isDefault: false
}];
export default {
  name: "FileAdd",
  data: function data() {
    return {
      fixedSalaryTemplateName: null,
      fixItems: fixItems,
      addForm: {
        name: ""
      },
      addVisible: false,
      lastId: "",
      lastName: "",
      buttonState: false,
      form: {},
      empName: "",
      detailsListCopy: [],
      trialTemplateList: [],
      formalTemplateList: [],
      detailsList: [],
      templateId: "",
      templateList: [],
      companyId: this.$store.state.hr.companyId,
      desc: "",
      columns: columns,
      routerData: {},
      rules: {
        name: [{
          required: true,
          message: "必填",
          trigger: "blur"
        }]
      },
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      currentPage: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    status: function status() {
      return ["待入职", "入职", "待离职", "离职"][this.routerData.status - 1];
    },
    doWords: function doWords() {
      return ["salaryPerformance", "mealSubsidy", "trafficSubsidy", "salaryBasic"];
    },
    postType: function postType() {
      return this.$store.state.enums.postTypeArr;
    },
    allmoney: function allmoney() {
      var _this$postData = this.postData,
        salaryPerformance = _this$postData.salaryPerformance,
        mealSubsidy = _this$postData.mealSubsidy,
        trafficSubsidy = _this$postData.trafficSubsidy,
        salaryBasic = _this$postData.salaryBasic;
      var arr = [salaryPerformance, mealSubsidy, trafficSubsidy, salaryBasic];
      return arr.reduce(function (preValue, curValue) {
        return Number(preValue) + Number(curValue);
      }, 0);
    },
    reasons: function reasons() {
      return [{
        label: "转正",
        value: 1
      }, {
        label: "晋升",
        value: 2
      }, {
        label: "调动",
        value: 3
      }, {
        label: "年中调薪",
        value: 4
      }, {
        label: "年度调薪",
        value: 5
      }, {
        label: "特别调薪",
        value: 6
      }, {
        label: "其他",
        value: 7
      }];
    }
  }),
  created: function created() {
    this.routerData = JSON.parse(this.$route.query.routerData);
    this.currentPage = this.$route.query.currentPage;
    this.getSelectByCompanyId();

    // this.getDetaiByempId()
  },
  beforeDestory: function beforeDestory() {
    this.clearInterval(this.timer);
  },
  methods: {
    goBack: function goBack() {
      this.$router.push({
        path: "/wages/file",
        query: {
          currentPage: this.currentPage
        }
      });
    },
    handleAddSelected: function handleAddSelected(item) {
      if (item.id === 1) return;
      item.selected = !item.selected;
    },
    computeItemClass: function computeItemClass(item) {
      if (item.id === 1) return "fix-salary-item-tag fix-salary-item-default";
      if (item.selected) return "fix-salary-item-tag fix-salary-item-selected";
      return "fix-salary-item-tag";
    },
    handleAddCancel: function handleAddCancel() {
      this.$refs.addForm.clearValidate();
    },
    handleAddOk: function handleAddOk() {
      var _this2 = this;
      var arr = this.fixItems.filter(function (item, index) {
        if (item.selected) return true;
      });
      var data = {
        companyId: this.companyId,
        isDefault: false,
        name: this.addForm.name,
        itemIds: arr.map(function (item) {
          return item.id;
        }).join(",")
      };
      this.$refs.addForm.validate(function (valid) {
        if (valid) {
          addSalaryTemplate(data).then(function (res) {
            _this2.addVisible = false;
            _this2.getSelectByCompanyId();
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        }
      });
    },
    goIncrease: function goIncrease() {
      this.addVisible = true;
      this.addForm = {};
      this.fixItems.forEach(function (item, index) {
        if (index > 0) item.selected = false;
      });
      // this.$router.push({
      //   path: '/wages/file/template'
      // })
    },
    templateChange: function templateChange(id, key, item) {
      // item.style = "buttonStyleClick";
      this.templateList[0].style = null;
      this.fixedSalaryTemplateName = item.name;
      // this.templateList[0].color = null;
      for (var i = 0; i < this.templateList.length; i++) {
        if (this.templateList[i].id === id) {
          this.templateList[i].style = "buttonStyleClick";
        } else {
          this.templateList[i].style = null;
        }
      }
      this.detailsList = [];
      this.templateId = id;
      this.detailsList;
      this.getDetaiByempId();
    },
    getDetaiByempId: function getDetaiByempId() {
      var _this3 = this;
      var id = this.templateId ? this.templateId : this.lastId;
      detaiByempId(id).then(function (res) {
        _this3.detailsList = res.data;
        _this3.trialTemplateList = JSON.parse(JSON.stringify(res.data));
        _this3.formalTemplateList = JSON.parse(JSON.stringify(res.data));
        for (var i = 0; i < _this3.detailsList.length; i++) {
          _this3.detailsList[i].projectId = i;
          _this3.detailsList[i].trial = _this3.detailsList[i].trial ? _this3.detailsList[i].trial : 0;
          _this3.detailsList[i].formal = _this3.detailsList[i].formal ? _this3.detailsList[i].formal : 0;
        }
      });
    },
    getSelectByCompanyId: function getSelectByCompanyId() {
      var _this4 = this;
      selectByCompanyId({
        companyId: this.companyId
      }).then(function (res) {
        _this4.templateList = res.data;
        _this4.templateList.forEach(function (item, index) {
          _this4.templateList[0].style = "buttonStyleClick";
        });
        _this4.templateId = _this4.templateList[0].id;
        _this4.lastId = _this4.templateList[0].id;
        _this4.lastName = _this4.templateList[0].name;
        // this.templateList[0].color = "#6A66F6";
        _this4.getDetaiByempId();
      });
    },
    goOut: function goOut() {
      var _this = this;
      this.$confirm({
        title: "员工定薪尚未保存，是否确认离开？",
        okText: "确定",
        cancelText: "取消",
        onOk: function onOk() {
          _this.$router.go(-1);
        }
      });
    },
    // 表格输入框变化
    handleInputChange: function handleInputChange(value, key, col) {
      var arr = JSON.parse(JSON.stringify(this.detailsList));
      if (col === "trial") {
        for (var i = 0; i < arr.length; i++) {
          if (i === key) {
            arr[i].trial = value;
            this.trialTemplateList[i].amount = value;
            this.trialTemplateList[i].name = this.trialTemplateList[i].itemName;
            // this.trialTemplateList[i].projectId = key
          }
        }

        var s = 0;
        for (var t = 0; t < arr.length - 1; t++) {
          s += arr[t].trial;
          arr[arr.length - 1].trial = s;
          this.trialTemplateList[this.trialTemplateList.length - 1].amount = s;
        }
      }
      if (col === "formal") {
        for (var p = 0; p < arr.length; p++) {
          if (p === key) {
            arr[p].formal = value;
            this.formalTemplateList[p].amount = value;
            this.formalTemplateList[p].name = this.formalTemplateList[p].itemName;
            // this.formalTemplateList[p].projectId = key
          }
        }

        var sum = 0;
        for (var a = 0; a < arr.length - 1; a++) {
          sum += arr[a].formal;
          arr[arr.length - 1].formal = sum;
          this.formalTemplateList[this.formalTemplateList.length - 1].amount = sum;
        }
      }
      this.detailsList = JSON.parse(JSON.stringify(arr));
    },
    // 表单提交
    handleSubmit: function handleSubmit() {
      var _this5 = this;
      for (var i = 0; i < this.detailsList.length; i++) {
        this.trialTemplateList[i].amount = this.trialTemplateList[i].amount ? this.trialTemplateList[i].amount : 0;
        this.formalTemplateList[i].amount = this.formalTemplateList[i].amount ? this.formalTemplateList[i].amount : 0;
      }
      empWage({
        fixedSalaryTemplateName: this.fixedSalaryTemplateName ? this.fixedSalaryTemplateName : this.lastName,
        isFixed: 1,
        isOfficial: parseInt(this.routerData.employType),
        companyId: this.companyId,
        empId: this.routerData.empId,
        fixedSalaryTemplateId: this.templateId ? this.templateId : this.lastId,
        reason: 0,
        trainingFixedSalaryDetail: JSON.stringify(this.trialTemplateList),
        officialFixedSalaryDetail: JSON.stringify(this.formalTemplateList),
        officialSalarySum: this.formalTemplateList[this.formalTemplateList.length - 1].amount ? this.formalTemplateList[this.formalTemplateList.length - 1].amount : 0,
        trainingSalarySum: this.trialTemplateList[this.trialTemplateList.length - 1].amount ? this.trialTemplateList[this.trialTemplateList.length - 1].amount : 0,
        remark: this.form.remark
      }).then(function (_ref) {
        var success = _ref.success;
        if (success) {
          _this5.goBack();
          _this5.$notification["success"]({
            message: "定薪成功"
          });
        }
      });
    },
    onChange: function onChange() {}
  }
};