import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "新增合同类型",
      visible: _vm.visible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.confirmAddType,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      form: _vm.typeForm,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 16
      },
      colon: true
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同模板名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '请输入' }] }]"
    }],
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请输入合同模板名称"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "分组"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["groupId", {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "['groupId', { rules: [{ required: true, message: '请选择' }] }]"
    }],
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请选择分组"
    }
  }, _vm._l(_vm.groupList, function (group, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: group.id
      }
    }, [_vm._v(_vm._s(group.name))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "名称描述"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["description", {
        rules: [{
          required: false,
          message: "请输入"
        }]
      }],
      expression: "['description', { rules: [{ required: false, message: '请输入' }] }]"
    }],
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请输入名称描述",
      rows: 4
    }
  })], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };