import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-layout", [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goOut
    }
  }, [_vm._v(" 编辑 ")]), _c("a-layout", [_c("a-layout-sider", {
    staticClass: "border-r-line ta-center border",
    attrs: {
      width: "276"
    }
  }, [_c("div", {
    staticClass: "file-user",
    staticStyle: {
      "margin-top": "40px"
    }
  }, [_c("a-row", {
    staticClass: "file-user-head",
    staticStyle: {
      "padding-left": "30px",
      "padding-right": "30px"
    }
  }, [_c("a-col", {
    staticStyle: {
      height: "200px"
    }
  }, [_c("a-row", {
    staticClass: "pd_b10"
  }, [_c("strong", {
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.info && _vm.info.name ? _vm.info.name : "暂无姓名"))])]), _c("a-row", [_c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "0px"
    }
  }, [_vm._v(" 工时累计 : "), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(" " + _vm._s(_vm.info && _vm.info.workHour ? _vm.info.workHour : "0") + "工时")])]), _c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "35px"
    }
  }, [_vm._v(" 出勤天数 : "), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(" " + _vm._s(_vm.info && _vm.info.workDay ? _vm.info.workDay : "0") + "天")])]), _c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "70px"
    }
  }, [_vm._v(" 入职日期 : "), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(" " + _vm._s(_vm.info ? _vm.formatDate(Number(_vm.info.joinDate), "d") : ""))])]), _c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "105px"
    }
  }, [_vm._v(" 工作性质 : "), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(" " + _vm._s(_vm.info ? _vm.$store.state.enums.postTypeArr[_vm.info.postType - 1] : ""))])]), _c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "140px"
    }
  }, [_vm._v(" 渠道来源 : "), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(" " + _vm._s(_vm.info ? _vm.info.channelName ? _vm.info.channelName : "---" : ""))])])])], 1), _c("a-divider")], 1)], 1)]), _c("a-layout-content", {
    staticClass: "ph24 mb20"
  }, [_c("div", {
    staticClass: "shu"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("定薪规则")])]), _c("a-radio-group", {
    staticStyle: {
      "margin-top": "16px"
    },
    on: {
      change: _vm.radioGroupChange
    },
    model: {
      value: _vm.size,
      callback: function callback($$v) {
        _vm.size = $$v;
      },
      expression: "size"
    }
  }, [_c("a-radio-button", {
    attrs: {
      value: "small"
    }
  }, [_vm._v(" 计时制 ")]), _c("a-radio-button", {
    attrs: {
      value: "default"
    }
  }, [_vm._v(" 固定薪资 ")])], 1), _vm.size === "default" ? _c("div", [_c("a-form-model", {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      model: _vm.form
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: " 调薪模板",
      rules: {
        required: true,
        message: "必选"
      }
    }
  }, [_c("div", [_c("a-button", {
    staticClass: "modelBotton"
  }, [_vm._v(" " + _vm._s(_vm.fixedSalaryTemplateName) + " ")])], 1)]), _vm.routerData.formalType === 1 ? _c("a-form-model-item", {
    attrs: {
      colon: false,
      label: " 调薪明细：试用期工资",
      rules: {
        required: true,
        message: "必选"
      }
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      "row-key": "itemName",
      "data-source": _vm.trainingList,
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "amount",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text.toFixed(2)) + " ")];
      }
    }, {
      key: "afterAmount",
      fn: function fn(text, record) {
        return [_c("div", [record.isSum !== "1" ? _c("a-input-number", {
          staticStyle: {
            margin: "-5px 0",
            width: "120px"
          },
          attrs: {
            "default-value": text,
            min: 0,
            step: 1,
            max: 100000,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleInputChange1(value, record.projectId);
            }
          }
        }) : _c("span", [_vm._v(_vm._s(text.toFixed(2)))])], 1)];
      }
    }, {
      key: "amplitude",
      fn: function fn(text, record) {
        return [_c("span", {
          style: {
            color: record.relativecolor
          }
        }, [_vm._v(_vm._s(text))])];
      }
    }], null, false, 1044762948)
  })], 1) : _vm._e(), _c("a-form-model-item", {
    attrs: {
      label: " 正式工资",
      rules: {
        required: true,
        message: "必选"
      }
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      "row-key": "itemName",
      "data-source": _vm.officialList,
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "amount",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text.toFixed(2)) + " ")];
      }
    }, {
      key: "afterAmount",
      fn: function fn(text, record) {
        return [_c("div", [record.isSum !== "1" ? _c("a-input-number", {
          staticStyle: {
            margin: "-5px 0",
            width: "120px"
          },
          attrs: {
            "default-value": text,
            min: 0,
            step: 1,
            max: 100000,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleInputChange2(value, record.projectId);
            }
          }
        }) : _c("span", [_vm._v(_vm._s(text.toFixed(2)))])], 1)];
      }
    }, {
      key: "amplitude",
      fn: function fn(text, record) {
        return [_c("span", {
          style: {
            color: record.relativecolor
          }
        }, [_vm._v(_vm._s(text))])];
      }
    }], null, false, 2231849095)
  })], 1), _c("a-form-model-item", [_c("label", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("* ")]), _vm._v(" "), _c("span", {
    staticStyle: {
      color: "rgba(0, 0, 0, 0.85)"
    }
  }, [_vm._v("调薪原因：")])]), _c("a-select", {
    staticStyle: {
      width: "300px",
      "margin-left": "28px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reason", $$v);
      },
      expression: "form.reason"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 转正 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 晋升 ")]), _c("a-select-option", {
    attrs: {
      value: 3
    }
  }, [_vm._v(" 调动 ")]), _c("a-select-option", {
    attrs: {
      value: 4
    }
  }, [_vm._v(" 年中调薪 ")]), _c("a-select-option", {
    attrs: {
      value: 5
    }
  }, [_vm._v(" 年度调薪 ")]), _c("a-select-option", {
    attrs: {
      value: 6
    }
  }, [_vm._v(" 特别调薪 ")]), _c("a-select-option", {
    attrs: {
      value: 7
    }
  }, [_vm._v(" 其他 ")])], 1)], 1), _c("a-form-model-item", [_c("label", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("* ")]), _vm._v(" "), _c("span", {
    staticStyle: {
      color: "rgba(0, 0, 0, 0.85)"
    }
  }, [_vm._v(" 调薪生效时间： ")])]), _c("a-date-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请选择",
      "disabled-date": _vm.disabledEndDate
    },
    on: {
      change: _vm.handleTimeChange
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      "label-col": {
        span: 1
      },
      "wrapper-col": {
        span: 13
      },
      label: "备注"
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "400px",
      "margin-left": "64px"
    },
    attrs: {
      "max-length": 50
    },
    model: {
      value: _vm.form.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  }), _c("a-row", {
    staticClass: "ta-right textarea-tip",
    staticStyle: {
      width: "465px"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.remark ? _vm.form.remark.length : 0) + "/50字 ")])], 1)], 1)], 1) : _vm._e(), _vm.size === "small" || _vm.size === "large" ? _c("div", [_c("div", {
    staticClass: "gj",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("工价规则")]), _c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      placeholder: "请选择",
      allowClear: ""
    },
    on: {
      change: _vm.handleRulesChange
    },
    model: {
      value: _vm.salaryCalculateRuleId,
      callback: function callback($$v) {
        _vm.salaryCalculateRuleId = $$v;
      },
      expression: "salaryCalculateRuleId"
    }
  }, _vm._l(_vm.rulusListCopy, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "15px",
      "font-size": "16px",
      "font-weight": "400",
      color: "#000000"
    }
  }, [_vm._v("已添加规则")]), _c("div", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("a-table", {
    attrs: {
      rowKey: "id",
      columns: _vm.columns2,
      "data-source": _vm.dataList,
      pagination: _vm.paginationOpt
    }
  })], 1)]) : _vm._e(), _c("div", {
    staticClass: "shu2"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("招工劳务费规则")])]), _c("div", {
    staticClass: "gj",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("招工劳务费规则")]), _c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      placeholder: "请选择",
      allowClear: ""
    },
    on: {
      change: _vm.redPackChange
    },
    model: {
      value: _vm.redPackId,
      callback: function callback($$v) {
        _vm.redPackId = $$v;
      },
      expression: "redPackId"
    }
  }, _vm._l(_vm.redPack, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "15px",
      "font-size": "16px",
      "font-weight": "400",
      color: "#000000"
    }
  }, [_vm._v("已添加规则")]), _c("div", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("a-table", {
    attrs: {
      rowKey: "id",
      columns: _vm.columns2,
      "data-source": _vm.dataList2,
      pagination: _vm.paginationOpt2
    }
  })], 1), _vm.ruleShow1 ? _c("div", {
    staticStyle: {
      "margin-bottom": "40px"
    }
  }) : _vm._e(), _c("a-divider"), _c("a-row", [_c("a-col", {
    staticClass: "fr"
  }, [_c("a-button", {
    staticClass: "mg_r10",
    on: {
      click: _vm.goOut
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleEmpyleeSubmitType
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1), _c("drawer", {
    ref: "drawers",
    on: {
      update: _vm.update
    }
  }), _c("drawers", {
    ref: "drawersl",
    on: {
      fatherMethod: _vm.fatherMethod
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };