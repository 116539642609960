import checkWork from "./work";
import workingHours from "./workingHours";
export default {
  components: {
    checkWork: checkWork,
    workingHours: workingHours
  },
  created: function created() {
    if (this.$route.query && this.$route.query.selectId) {
      console.log("------------");
      this.defu_tab = "workingHours";
    }
  },
  data: function data() {
    return {
      defu_tab: "checkWork",
      componentName: "checkWork",
      numArr: [{
        type: "checkWork",
        name: "上下班打卡",
        num: 0
      }, {
        type: "workingHours",
        name: "工时导入",
        num: 0
      }]
    };
  }
};