var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flowSetting"
  }, [_c("section", {
    staticClass: "dingflow-design"
  }, [_c("div", {
    staticClass: "zoom"
  }, [_c("a-button", {
    class: "zoom-out" + (_vm.nowVal == 50 ? " disabled" : ""),
    attrs: {
      icon: "minus"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.zoomSize(1);
      }
    }
  }), _c("span", {
    staticClass: "zoom-span"
  }, [_vm._v(_vm._s(_vm.nowVal) + "%")]), _c("a-button", {
    class: "zoom-in" + (_vm.nowVal == 300 ? " disabled" : ""),
    attrs: {
      icon: "plus"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.zoomSize(2);
      }
    }
  })], 1), _c("div", {
    staticClass: "box-scale",
    style: "transform: scale(".concat(_vm.nowVal / 100, "); transform-origin: 50% 0px 0px;")
  }, [_c("NodeWrapper", {
    staticClass: "mg_t20",
    attrs: {
      process: _vm.value,
      formlist: _vm.formlist,
      "node-id": _vm.nodeId
    },
    on: {
      "update:process": function updateProcess($event) {
        _vm.value = $event;
      }
    }
  }), _vm._m(0)], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flow-container"
  }, [_c("div", {
    staticClass: "end-node"
  }, [_c("div", {
    staticClass: "end-node-circle"
  }), _c("div", {
    staticClass: "end-node-text"
  }, [_vm._v("流程结束")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };