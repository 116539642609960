import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ql-editor-wrapper ql-editor-wrapperStyle"
  }, [_c("div", {
    staticClass: "ql-editor-header"
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.back
    }
  }), _c("div", {
    staticClass: "ql-editor-header-title"
  }, [_vm._v("编辑合同")])], 1), _c("div", {
    staticClass: "ql-editor-content ql-editor-content2"
  }, [_c("div", {
    staticClass: "editor-main",
    attrs: {
      id: "editor-main"
    }
  }, [_c("vue-wangeditor", {
    ref: "editor",
    attrs: {
      menus: _vm.menus,
      height: _vm.editorHeight
    },
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1), _c("div", {
    staticClass: "editor-config editor-config2"
  }, [_c("div", {
    staticClass: "editor-config-block"
  }, [_c("div", {
    staticClass: "editor-config-block-title",
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("设置模板名称")]), _c("div", {
    staticClass: "editor-config-block-content"
  }, [_c("a-input", {
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)]), _c("div", {
    staticClass: "editor-config-block"
  }, [_c("div", {
    staticClass: "editor-config-block-title",
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("设置模板变量")]), _c("div", {
    staticClass: "editor-config-block-content"
  }, [_c("div", {
    staticClass: "editor-config-block-content-tip"
  }, [_vm._v("快速添加员工姓名、部门、岗位等信息，内容中会自动替换为真实信息")]), _vm._l(_vm.variables, function (item, index) {
    return _c("div", {
      key: index
    }, [item.title === "员工个人信息" ? _c("div", [_c("div", {
      staticClass: "editor-config-block-content-title",
      staticStyle: {
        "margin-bottom": "8px"
      }
    }, [_vm._v(_vm._s(item.title))]), _c("a-collapse", {
      staticStyle: {
        background: "#fff"
      },
      attrs: {
        "default-active-key": "0",
        bordered: false
      },
      scopedSlots: _vm._u([{
        key: "expandIcon",
        fn: function fn(props) {
          return [_c("a-icon", {
            staticClass: "colorIcons",
            attrs: {
              type: "caret-right",
              rotate: props.isActive ? 90 : 0
            }
          })];
        }
      }], null, true)
    }, _vm._l(item.children, function (item2, index2) {
      return _c("a-collapse-panel", {
        key: index2,
        style: _vm.customStyle,
        attrs: {
          header: item2.name
        }
      }, [_c("div", {
        staticClass: "editor-config-block-content-tag-wrapper"
      }, _vm._l(item2.val, function (item3, index3) {
        return _c("div", {
          key: index3,
          staticClass: "editor-config-block-content-tag mb10",
          on: {
            click: function click($event) {
              return _vm.handleVariable(item3.name);
            }
          }
        }, [_vm._v(" " + _vm._s(item3.name) + " ")]);
      }), 0)]);
    }), 1)], 1) : _c("div", [_c("div", {
      staticClass: "editor-config-block-content-title"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "editor-config-block-content-tag-wrapper"
    }, _vm._l(item.children, function (tag, idx) {
      return _c("div", {
        key: idx,
        staticClass: "editor-config-block-content-tag",
        on: {
          click: function click($event) {
            return _vm.handleVariable(tag.name);
          }
        }
      }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
    }), 0)])]);
  })], 2)])])]), _c("div", {
    staticClass: "ql-editor-footer footerBtn",
    staticStyle: {
      width: "100%",
      "min-width": "1002px"
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateTemplate
    }
  }, [_vm._v(" 设置默认印章位置")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveTemplate
    }
  }, [_vm._v(" 仅保存")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };