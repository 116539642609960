var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "body page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "company"
  }, [_vm._v("用工单位分组管理")]), _c("a-row", {
    staticClass: "mg_t20 mg_b20",
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-space", {
    attrs: {
      size: 48
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "搜索分组",
      allowClear: ""
    },
    on: {
      change: _vm.onChange
    }
  }), _c("a-select", {
    staticStyle: {
      width: "240px"
    },
    model: {
      value: _vm.isRecursion,
      callback: function callback($$v) {
        _vm.isRecursion = $$v;
      },
      expression: "isRecursion"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: true
    }
  }, [_vm._v("显示全部用工单位")]), _c("a-select-option", {
    attrs: {
      value: false
    }
  }, [_vm._v("仅显示当前分组用工单位")])], 1)], 1), _c("a-button", {
    attrs: {
      icon: "plus",
      type: "primary"
    },
    on: {
      click: _vm.addCompany
    }
  }, [_vm._v("添加分组")])], 1), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      width: "300px",
      "flex-shrink": "0",
      overflow: "auto"
    }
  }, [_vm.selectedKeys.length > 0 ? _c("a-tree", {
    attrs: {
      selectedKeys: _vm.selectedKeys,
      expandedKeys: _vm.expandedKeys,
      autoExpandParent: _vm.autoExpandParent,
      treeData: _vm.groupTreeFilter
    },
    on: {
      expand: _vm.onExpand,
      select: _vm.onGroupSelect
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn(node) {
        return [node.label.indexOf(_vm.searchValue) > -1 ? _c("div", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [_c("a-tooltip", {
          attrs: {
            title: node.label
          }
        }, [_c("div", {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(node.label.substr(0, node.label.indexOf(_vm.searchValue))) + " "), _c("span", {
          staticStyle: {
            color: "#f50"
          }
        }, [_vm._v(_vm._s(_vm.searchValue))]), _vm._v(" " + _vm._s(node.label.substr(node.label.indexOf(_vm.searchValue) + _vm.searchValue.length)) + " ")])]), node.editable ? _c("a-icon", {
          attrs: {
            type: "setting"
          },
          on: {
            click: function click($event) {
              return _vm.geDetail(node.value);
            }
          }
        }) : _vm._e()], 1) : _c("div", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [_c("a-tooltip", {
          attrs: {
            title: node.label
          }
        }, [_c("div", {
          staticClass: "title"
        }, [_vm._v(_vm._s(node.label))])]), node.editable ? _c("a-icon", {
          attrs: {
            type: "setting"
          },
          on: {
            click: function click($event) {
              return _vm.geDetail(node.value);
            }
          }
        }) : _vm._e()], 1)];
      }
    }], null, false, 2200554978)
  }) : _vm._e()], 1), _c("div", {
    staticClass: "ml24 pl24",
    staticStyle: {
      flex: "1",
      "border-left": "1px solid #e8e8e8"
    }
  }, [_c("a-table", {
    attrs: {
      pagination: _vm.pagination,
      dataSource: _vm.dataSource,
      loading: _vm.tableLoading,
      columns: _vm.columns,
      rowKey: function rowKey(record) {
        return record.id || "site";
      },
      childrenColumnName: "childList"
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return [_c("a-space", [_c("a", {
          on: {
            click: function click($event) {
              return _vm.management(record);
            }
          }
        }, [_vm._v("设置")]), _c("a", {
          on: {
            click: function click($event) {
              return _vm.geEditor(record);
            }
          }
        }, [_vm._v("详情")])])];
      }
    }])
  })], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };