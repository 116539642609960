import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.dom-collections.for-each.js";
import moment from "moment";
import { pageByMonthIdAndCheckType, getGinseng } from "./api";
import axios from "axios";
import qs from "qs";
var columns = [{
  title: "姓名",
  width: 120,
  dataIndex: "empName",
  key: "empName",
  fixed: "left"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 200
}, {
  title: "缴费类型",
  dataIndex: "payCostType",
  key: "payCostType",
  width: 120,
  scopedSlots: {
    customRender: "payCostType"
  }
}, {
  title: "参保方案",
  dataIndex: "socProgName",
  key: "socProgName",
  width: 120
}, {
  title: "参保城市",
  dataIndex: "socProgCity",
  key: "socProgCity",
  width: 120
}, {
  title: "个人社保费",
  dataIndex: "sysPersonalSocAmount",
  key: "sysPersonalSocAmount",
  width: 120,
  scopedSlots: {
    customRender: "sysPersonalSocAmount"
  }
}, {
  title: "单位社保费",
  dataIndex: "sysCompanySocAmount",
  key: "sysCompanySocAmount",
  width: 120,
  scopedSlots: {
    customRender: "sysCompanySocAmount"
  }
}, {
  title: "个人公积金费",
  dataIndex: "sysPersonalPfAmount",
  key: "sysPersonalPfAmount",
  width: 120,
  scopedSlots: {
    customRender: "sysPersonalPfAmount"
  }
}, {
  title: "单位公积金费",
  dataIndex: "sysCompanyPfAmount",
  key: "sysCompanyPfAmount",
  width: 120,
  scopedSlots: {
    customRender: "sysCompanyPfAmount"
  }
}, {
  title: "服务费",
  dataIndex: "sysServiceCost",
  key: "sysServiceCost",
  width: 120,
  scopedSlots: {
    customRender: "sysServiceCost"
  }
},
// {
//   title: "补收滞纳金",
//   dataIndex: "sysSocialSecurityFine",
//   key: "sysSocialSecurityFine",
//   width: 120,
//   scopedSlots: { customRender: "sysSocialSecurityFine" }
// },
// {
//   title: "补缴滞纳金",
//   dataIndex: "sysAccumulationFundFine",
//   key: "sysAccumulationFundFine",
//   width: 120,
//   scopedSlots: { customRender: "sysAccumulationFundFine" }
// },
{
  title: "状态",
  dataIndex: "wrongsStatus",
  key: "wrongsStatus",
  width: 120,
  scopedSlots: {
    customRender: "wrongsStatus"
  }
}
// {
//   title: "操作",
//   key: "action",
//   fixed: "right",
//   width: 150,
//   scopedSlots: { customRender: "action" },
//   align: "center"
// }
];

export default {
  data: function data() {
    return {
      selectItem: undefined,
      tableList: [{
        key: "0",
        name: "全部状态"
      }, {
        key: "1",
        name: "系统内无此人"
      }, {
        key: "2",
        name: "文件内无此人"
      },
      // { key: "3", name: "文件数据缺失" },
      {
        key: "3",
        name: "未参保"
      }, {
        key: "4",
        name: "社保金异常"
      }, {
        key: "5",
        name: "公积金异常"
      }, {
        key: "9",
        name: "服务费异常"
      }],
      current: 1,
      total: 0,
      columns: columns,
      loading: false,
      dataList: [],
      infoList: [],
      errDataList: [],
      isContentShow: false,
      companyId: this.$store.state.hr.companyId,
      dataListCopy: [],
      url: "",
      params2: {},
      state: null,
      dynamicDate: moment(moment().format("YYYY-MM"), "YYYY-MM"),
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        editCompanyName: null,
        ticketRecord: null,
        receivableRecord: null
      }
    };
  },
  created: function created() {
    this.dynamicDate = moment(this.$route.query.year + "-" + this.$route.query.month, "YYYY-MM");
    this.state = this.$route.query.state;
    if (this.state === "1") {
      this.tableList = [{
        key: "0",
        name: "全部状态"
      }, {
        key: "1",
        name: "系统内无此人"
      }, {
        key: "2",
        name: "文件内无此人"
      },
      // { key: "3", name: "文件数据缺失" },
      {
        key: "3",
        name: "未参保"
      }, {
        key: "4",
        name: "社保金异常"
      }, {
        key: "5",
        name: "公积金异常"
      }, {
        key: "9",
        name: "服务费异常"
      }];
    } else if (this.state === "2") {
      this.tableList = [{
        key: "0",
        name: "全部状态"
      }, {
        key: "1",
        name: "系统内无此人"
      },
      // { key: "2", name: "文件内无此人" },
      // { key: "3", name: "文件数据缺失" },
      {
        key: "3",
        name: "未参保"
      }, {
        key: "4",
        name: "社保金异常"
      }, {
        key: "5",
        name: "公积金异常"
      }, {
        key: "6",
        name: "社保局无此人"
      }, {
        key: "7",
        name: "医保局无此人"
      }, {
        key: "8",
        name: "公积金无此人"
      }
      // { key: "9", name: "服务费异常" }
      ];
    }

    this.getGinsengList();
  },
  methods: {
    exportFile: function exportFile(e) {
      // console.log(e);
      this.params2 = {
        socCheckId: e.id
      };
      if (this.selectItem) {
        this.params2.wrongsStatus = this.selectItem;
      }
      this.url = "/hr/api/v1/socCheckItems/export";
      this.excelName = "\u5F02\u5E38\u6570\u636E.xlsx";
      this.getDownload();
    },
    getDownload: function getDownload() {
      var _this = this;
      axios({
        url: this.url,
        params: this.params2,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob"
        // headers: {
        //   token: this.token
        // }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", _this.excelName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    goActionDetail: function goActionDetail(e) {
      if (e === 1) {
        this.$router.push("/staff/employee");
      } else if (e === 2) {
        this.$router.push("/shebao/shebaoManage/shebaoZengJianY");
      }
    },
    downLoad: function downLoad(e) {
      var url = e;
      window.open(url + "?token=".concat(this.$store.state.auth.token));
    },
    getGinsengList: function getGinsengList() {
      var _this2 = this;
      console.log(this.state, " this.state");
      getGinseng({
        companyId: this.companyId,
        checkType: this.state === "1" ? 1 : 2,
        month: moment(this.dynamicDate).format("MM"),
        year: moment(this.dynamicDate).format("YYYY")
      }).then(function (res) {
        _this2.infoList = JSON.parse(JSON.stringify(res.data));
        if (_this2.infoList.length > 0) {
          _this2.getpageByMonthIdAndCheckType();
        }
      });
    },
    getpageByMonthIdAndCheckType: function getpageByMonthIdAndCheckType() {
      var _this3 = this;
      this.loading = true;
      pageByMonthIdAndCheckType({
        pageSize: 10,
        pageNo: this.current,
        monthId: this.infoList[0].id,
        checkType: this.state === "1" ? 1 : 2
      }).then(function (res) {
        _this3.loading = false;
        _this3.dataList = JSON.parse(JSON.stringify(res.data.entities));
        _this3.dataListCopy = JSON.parse(JSON.stringify(res.data.entities));
        _this3.total = res.data.entityCount;
        _this3.dataList.forEach(function (item, index) {
          item.isContentShow = false;
        });
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    onChange: function onChange(current, pageSize) {
      this.current = current;
      this.getGinsengList();
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      // this.getRecordInfosList();
    },
    handleSelectChange: function handleSelectChange(item, index) {
      var _this4 = this;
      this.dataList[index].socCheckItemList = [];
      this.dataListCopy[index].socCheckItemList.forEach(function (item2, index2) {
        if (_this4.selectItem === "0") {
          _this4.dataList[index].socCheckItemList = JSON.parse(JSON.stringify(_this4.dataListCopy[index].socCheckItemList));
        } else if (item2.wrongsStatus === parseInt(_this4.selectItem)) {
          _this4.dataList[index].socCheckItemList.push(item2);
        }
      });
    },
    switchChange: function switchChange(item, index) {
      this.$set(this.dataList, index, _objectSpread(_objectSpread({}, this.dataList[index]), {}, {
        isContentShow: !item.isContentShow
      }));
    },
    goBack: function goBack() {
      this.$router.go(-1);
    },
    goBackDate: function goBackDate() {
      // this.dynamicDate = moment(moment().format("YYYY-MM"), "YYYY-MM");
      this.dynamicDate = moment(this.$route.query.year + "-" + this.$route.query.month, "YYYY-MM");
      this.getGinsengList();
    },
    dateChange: function dateChange(e) {
      if (e === 1) {
        this.dynamicDate = moment(this.dynamicDate).subtract(1, "months");
        this.getGinsengList();
      }
      if (e === 2) {
        this.dynamicDate = moment(this.dynamicDate).subtract(-1, "months");
        this.getGinsengList();
      }
    }
  }
};