import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { getDetailsReqNew, updateAutoTaxGrand, getSelectHeadersReqNew, updateHead } from "./api";
import draggable from "vuedraggable";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
import { initColumns } from "./funcs";
import axios from "axios";
var mixin = new ExportMixin();
export default {
  mixins: [mixin],
  components: {
    draggable: draggable
  },
  data: function data() {
    return {
      tempSelectedHead: [],
      tempNoSelectedHead: [],
      headVisible: false,
      title: "",
      buttonState: true,
      inputName: null,
      selectedRowId: [],
      selectedRowKeys: [],
      loading: false,
      dataList: [],
      initColumns: initColumns,
      columns: [],
      detailList: [],
      empStatus: null,
      selectString: {},
      monthly: 5,
      yearly: 2021,
      bankType: undefined,
      formType: "",
      groupType: "",
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      }
    };
  },
  created: function created() {
    this.detailList = JSON.parse(localStorage.getItem("kehumingxibiao_item"));
    console.log(this.detailList);
    this.monthly = this.$route.query.monthly;
    this.yearly = this.$route.query.yearly;
    this.formType = this.$route.query.formType;
    this.groupType = this.$route.query.groupType;
    this.getSelectHeaders();
    this.getDetailsList();
  },
  methods: {
    reload: function reload() {
      this.getDetailsList();
      this.$notification["success"]({
        message: "成功",
        description: "刷新成功"
      });
    },
    getSelectHeaders: function getSelectHeaders() {
      var _this = this;
      getSelectHeadersReqNew({
        id: this.detailList.subjectId,
        type: this.detailList.subjectType,
        customerDetailsIdList: this.detailList.customerDetailsIds,
        monthly: this.monthly,
        yearly: this.yearly
      }).then(function (res) {
        var arr = [];
        _this.selectString = res.data;
        _this.tempSelectedHead = [];
        arr = JSON.parse(JSON.stringify(res.data.canSee));
        var tempArr = [];
        arr.forEach(function (item) {
          if (item.fieldEnName === "empName" || item.fieldEnName === "idCard") {
            tempArr.push({
              title: item.fieldName,
              dataIndex: item.fieldEnName,
              width: item.fieldName === "身份证号" ? 200 : 130,
              fixed: item.fieldName === "姓名" || item.fieldName === "身份证号" ? "left" : null,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          } else {
            tempArr.push({
              title: item.fieldName,
              dataIndex: item.fieldName,
              width: 130,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          }
        });
        _this.tempSelectedHead = tempArr;
        _this.columns = JSON.parse(JSON.stringify(_this.tempSelectedHead));
        var arr2 = [];
        _this.tempNoSelectedHead = [];
        arr2 = JSON.parse(JSON.stringify(res.data.ignore));
        var tempArr2 = [];
        arr2.forEach(function (item, index) {
          if (item.fieldEnName === "empName" || item.fieldEnName === "idCard") {
            tempArr2.push({
              title: item.fieldName,
              dataIndex: item.fieldEnName,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          } else {
            tempArr2.push({
              title: item.fieldName,
              dataIndex: item.fieldName,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          }
        });
        _this.tempNoSelectedHead = tempArr2;
      });
    },
    handleHeadSearch: function handleHeadSearch() {
      var _this2 = this;
      this.selectString.canSee = this.tempSelectedHead;
      this.selectString.ignore = this.tempNoSelectedHead;
      updateHead(this.selectString).then(function (res) {
        _this2.getSelectHeaders();
        _this2.headVisible = false;
      });
    },
    handleHeadChange: function handleHeadChange(visible) {
      if (visible === false) {
        this.tempSelectedHead = JSON.parse(JSON.stringify(this.selectedHead));
        this.tempNoSelectedHead = JSON.parse(JSON.stringify(this.noSelectedHead));
      }
    },
    addSelectedHead: function addSelectedHead(index) {
      var temp = JSON.parse(JSON.stringify(this.tempNoSelectedHead[index]));
      this.tempNoSelectedHead.splice(index, 1);
      this.tempSelectedHead.push(temp);
    },
    deleteSelectedHead: function deleteSelectedHead(e, index) {
      e.preventDefault();
      var temp = JSON.parse(JSON.stringify(this.tempSelectedHead[index]));
      this.tempSelectedHead.splice(index, 1);
      this.tempNoSelectedHead.push(temp);
    },
    handleHeadCancel: function handleHeadCancel() {
      this.headVisible = false;
      this.tempSelectedHead = JSON.parse(JSON.stringify(this.selectedHead));
      this.tempNoSelectedHead = JSON.parse(JSON.stringify(this.noSelectedHead));
    },
    onStart: function onStart() {
      this.drag = true;
    },
    onEnd: function onEnd() {
      this.drag = false;
    },
    onMove: function onMove(e) {
      if (e.relatedContext.element.title === "姓名" || e.relatedContext.element.title === "身份证号") return false;
      return true;
    },
    autoAdd: function autoAdd() {
      var _this3 = this;
      updateAutoTaxGrand({
        monthly: this.monthly,
        yearly: this.yearly,
        companyId: this.detailList.companyId,
        companyName: this.detailList.name,
        unpaidAmount: this.detailList.customerFees
      }).then(function (res) {
        _this3.addVisible = false;
        _this3.$notification["success"]({
          message: "success",
          description: "生成成功"
        });
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys, selectedRow) {
      var _this4 = this;
      this.selectedRowId = [];
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length) {
        this.buttonState = false;
      } else {
        this.buttonState = true;
      }
      selectedRow.forEach(function (item, index) {
        _this4.selectedRowId.push(item.id);
      });
    },
    getDetailsList: function getDetailsList() {
      var _this5 = this;
      this.loading = true;
      getDetailsReqNew({
        empStatus: this.empStatus,
        id: this.detailList.subjectId,
        type: this.detailList.type,
        customerDetailsIdList: this.detailList.customerDetailsIds,
        nameOrPhoneOrIdCard: this.inputName,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        monthly: this.monthly,
        yearly: this.yearly
      }).then(function (res) {
        _this5.loading = false;
        _this5.dataList = res.data.entities.map(function (item) {
          var newItem = _objectSpread({}, item);
          if (item.detailsAmountVOList && item.detailsAmountVOList.length > 0) {
            for (var i = 0; i < item.detailsAmountVOList.length; i++) {
              var temp = item.detailsAmountVOList[i];
              newItem[temp.name] = temp.amount;
            }
          }
          return newItem;
        });
        _this5.pagination.total = res.data.entityCount; // 共多少条
      });
    },
    handleChange2: function handleChange2(e) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.empStatus = e;
      this.getDetailsList();
    },
    // 返回
    goBack: function goBack() {
      this.$router.go(-1);
    },
    // 导出
    download: function download() {
      var _this6 = this;
      axios({
        url: "/hr/api/v1/customerDetailsNew/exportNew",
        method: "post",
        data: {
          id: this.detailList.subjectId,
          type: this.groupType,
          customerDetailsIdList: this.detailList.customerDetailsIds,
          monthly: this.monthly,
          yearly: this.yearly
        },
        responseType: "blob",
        headers: {
          token: this.token
        }
      }).then(function (res) {
        if (res.data && res.data.type === "application/json") {
          var reader = new FileReader();
          reader.readAsText(res.data, "utf-8");
          reader.onload = function () {
            var resJson = JSON.parse(reader.result);
            _this6.$message.error(resJson.msg);
          };
        } else {
          var blob = new Blob([res.data], {
            type: "application/octet-stream"
          });
          var link = document.createElement("a");
          link.download = "".concat(_this6.detailList.name + _this6.detailList.personalPeriod, "_") + "客户明细表导出详情" + ".xlsx";
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    inputChange: function inputChange(e) {
      this.inputName = e;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getDetailsList();
    },
    // 分页
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getDetailsList();
    }
  }
};