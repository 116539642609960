var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "r-management-box page-miH page-bgBack"
  }, [_c("a-tabs", {
    attrs: {
      activeKey: _vm.isCancel
    },
    on: {
      change: _vm.change
    }
  }, [_c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "在招职位"
    }
  }), _c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "已关闭职位"
    }
  })], 1), _c("tables", {
    attrs: {
      isCancel: _vm.isCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };