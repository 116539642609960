import request from "@/utils/request";
// 查询公历表,补全前后日期分别到周一和周日
export function getCalendar(params) {
  return request({
    url: "/admin/api/v1/gregorianCalendars/month",
    method: "GET",
    params: params
  });
}
// 同步
export function synchronous(data) {
  return request({
    url: "/admin/api/v1/gregorianCalendars/syncHoliday",
    method: "POST",
    data: data
  });
}
// 编辑日期类型
export function editType(data) {
  return request({
    url: "/admin/api/v1/gregorianCalendars/updateDateType",
    method: "put",
    data: data
  });
}