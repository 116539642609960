import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
var mixin = new UploadMixin();
var columnDialogDetailguding = [{
  title: "错误信息",
  dataIndex: "errorInfo",
  key: "errorInfo",
  scopedSlots: {
    customRender: "errorInfo"
  },
  align: "center",
  width: 160,
  ellipsis: true,
  fixed: "left"
}, {
  title: "姓名",
  dataIndex: "empName",
  scopedSlots: {
    customRender: "empName"
  },
  align: "center",
  ellipsis: true,
  key: "empName",
  width: 140
}, {
  title: "身份证号",
  dataIndex: "idCard",
  scopedSlots: {
    customRender: "idCard"
  },
  align: "center",
  ellipsis: true,
  key: "idCard",
  width: 200
}, {
  title: "离职原因",
  dataIndex: "leaveOfficeReason",
  scopedSlots: {
    customRender: "leaveOfficeReason"
  },
  align: "center",
  ellipsis: true,
  key: "leaveOfficeReason",
  width: 170
}, {
  title: "离职日期",
  dataIndex: "leaveOfficeDate",
  scopedSlots: {
    customRender: "leaveOfficeDate"
  },
  align: "center",
  ellipsis: true,
  key: "leaveOfficeDate",
  width: 140
}, {
  title: "离职备注",
  dataIndex: "remark",
  scopedSlots: {
    customRender: "remark"
  },
  align: "center",
  ellipsis: true,
  key: "remark",
  width: 170
}, {
  title: "是否社保减员",
  dataIndex: "whetherSocDecrease",
  scopedSlots: {
    customRender: "whetherSocDecrease"
  },
  align: "center",
  ellipsis: true,
  key: "whetherSocDecrease",
  width: 130
}, {
  title: "社保减员年月",
  dataIndex: "stopSocDate",
  scopedSlots: {
    customRender: "stopSocDate"
  },
  align: "center",
  ellipsis: true,
  key: "stopSocDate",
  width: 140
}, {
  title: "是否医保减员",
  dataIndex: "whetherMeDecrease",
  scopedSlots: {
    customRender: "whetherMeDecrease"
  },
  align: "center",
  ellipsis: true,
  key: "whetherMeDecrease",
  width: 130
}, {
  title: "医保减员年月",
  dataIndex: "stopMeDate",
  scopedSlots: {
    customRender: "stopMeDate"
  },
  align: "center",
  ellipsis: true,
  key: "stopMeDate",
  width: 140
}, {
  title: "是否公积金减员",
  dataIndex: "whetherSealed",
  scopedSlots: {
    customRender: "whetherSealed"
  },
  align: "center",
  ellipsis: true,
  key: "whetherSealed",
  width: 130
}, {
  title: "公积金减员年月",
  dataIndex: "sealedDate",
  ellipsis: true,
  scopedSlots: {
    customRender: "sealedDate"
  },
  align: "center",
  key: "sealedDate",
  width: 140
}, {
  title: "服务费",
  dataIndex: "serviceCost",
  ellipsis: true,
  scopedSlots: {
    customRender: "serviceCost"
  },
  align: "center",
  key: "serviceCost",
  width: 140
}];
import axios from "axios";
import qs from "qs";
export default {
  mixins: [mixin],
  data: function data() {
    return {
      isDaoru: false,
      uploading: false,
      allCount: 0,
      errorCount: 0,
      successCount: 0,
      columnDialogDetailguding: columnDialogDetailguding,
      uploadImg: require("@/assets/img/uploadImg.png"),
      action: "",
      socFile: undefined,
      dialogFailVisible: false,
      dataDetail2: []
    };
  },
  created: function created() {
    this.$route.query.companyId;
    this.dataDetail2 = [];
  },
  methods: {
    handleLizhi: function handleLizhi() {
      this.isDaoru = false;
      this.dialogFailVisible = false;
      this.$router.push({
        path: "/staff/LeaveList"
      });
    },
    handleCancel: function handleCancel() {
      this.isDaoru = false;
      this.dialogFailVisible = false;
    },
    ExportHandle: function ExportHandle() {
      this.isDaoru = false;
      this.handleImportChange();
    },
    getRed: function getRed(key, record) {
      if (record !== undefined && record.errorColumn !== null) {
        for (var i = 0; i < record.errorColumn.length; i++) {
          if (record.errorColumn[i] === key) {
            return true;
          }
        }
      }
      return false;
    },
    BatchGet: function BatchGet() {
      var _this = this;
      this.uploading = true;
      var url = "/hr/api/v1/empChanges/exportErrorList";
      var data = {
        errorList: this.dataDetail2
      };
      axios({
        url: url,
        data: data,
        method: "post",
        responseType: "blob",
        headers: {
          token: this.$store.state.auth.token,
          "Content-Type": "application/json"
        }
      }).then(function (res) {
        _this.uploading = false;
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "".concat(_this.$store.state.hr.companyName, "_") + "批量离职错误数据" + ".xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleImportChange: function handleImportChange() {
      var _this2 = this;
      this.uploading = true;
      var url = "/hr/api/v1/empChanges/importExcel";
      var tdata = new FormData();
      tdata.append("companyId", this.$store.state.hr.companyId);
      tdata.append("file", this.socFile);
      this.$request({
        url: url,
        method: "post",
        data: tdata
      }).then(function (res) {
        _this2.uploading = false;
        if (res.data) {
          _this2.allCount = res.data.allCount;
          _this2.errorCount = res.data.errorCount;
          _this2.successCount = res.data.successCount;
          _this2.dataDetail2 = res.data.errorList;
          if (_this2.dataDetail2 == null || _this2.dataDetail2.length === 0) {
            _this2.dialogFailVisible = true;
          }
        }
      }).catch(function (err) {
        _this2.uploading = false;
        console.log("debug log --> ", err);
      });
    },
    beforeUpload1: function beforeUpload1(_ref) {
      var file = _ref.file;
      this.socFile = file;
      if (this.socFile) {
        this.isDaoru = true;
      }
    },
    // 下载模板
    download: function download() {
      var token = this.$store.state.auth.token;
      var params = {};
      params = {
        templateName: "batchLeaveOfficeImportTemplate.xlsx"
      };
      axios({
        url: " /hr/api/v1/excel/template/download",
        params: params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "multipart/form-data"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "批量确认离职模板.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    }
  }
};