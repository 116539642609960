export default {
  fields: {
    name: {
      model: "name"
    },
    phone: {
      model: "phone"
    },
    sex: {
      model: "sex"
    },
    skill: {
      model: "skill"
    },
    mark: {
      model: "mark"
    },
    errorReason: {
      model: "errorReason"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "name",
        title: "姓名"
      }, {
        model: "phone",
        title: "手机号码"
      }, {
        model: "sex",
        title: "性别"
      }, {
        model: "skill",
        title: "技能标签"
      }, {
        model: "mark",
        title: "备注"
      }, {
        model: "errorReason",
        title: "异常原因"
      }],
      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: true
      }
    }
  }
};