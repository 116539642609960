var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "notice-editor-wrapper"
  }, [_c("div", {
    staticClass: "ql-editor-content"
  }, [_c("div", {
    staticClass: "notice-info"
  }, [_vm._m(0), _c("a-input", {
    staticStyle: {
      width: "70%"
    },
    attrs: {
      placeholder: "请输入标题"
    },
    domProps: {
      innerHTML: _vm._s(_vm.title)
    },
    model: {
      value: _vm.title,
      callback: function callback($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  })], 1), _c("div", {
    staticClass: "notice-info",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._m(1), _c("a-date-picker", {
    staticStyle: {
      "margin-top": "10px",
      "margin-bottom": "10px"
    },
    attrs: {
      "show-time": "",
      format: "YYYY-MM-DD HH:mm:ss"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.dataTime,
      callback: function callback($$v) {
        _vm.dataTime = $$v;
      },
      expression: "dataTime"
    }
  })], 1), _c("div", [_c("div", {
    staticClass: "notice-title"
  }, [_vm._v("附件上传")]), _c("div", [_c("UploadGeneralFileWithName", {
    attrs: {
      "default-value": _vm.fileList,
      limit: 100
    },
    on: {
      change: _vm.onFileChange
    }
  })], 1)]), _c("div", {
    staticClass: "editor-main",
    attrs: {
      id: "editor-main"
    }
  }, [_c("div", {
    staticClass: "notice-title"
  }, [_vm._v("新闻内容")]), _c("vue-wangeditor", {
    ref: "editor",
    attrs: {
      menus: _vm.menus,
      height: _vm.editorHeight
    },
    on: {
      change: _vm.changeContent
    },
    model: {
      value: _vm.contentHasStyle,
      callback: function callback($$v) {
        _vm.contentHasStyle = $$v;
      },
      expression: "contentHasStyle"
    }
  })], 1)]), _c("div", {
    staticClass: "btnline"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.NextStep
    }
  }, [_vm._v("下一步")])], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "notice-title",
    staticStyle: {
      display: "flex",
      "justify-content": "space-betwwen"
    }
  }, [_c("span", [_vm._v("新闻标题")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "notice-title",
    staticStyle: {
      display: "flex",
      "justify-content": "space-betwwen"
    }
  }, [_c("span", [_vm._v("选择发布时间")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };