import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "Transfer"
  }, [_c("div", {
    staticClass: "son border-r-line"
  }, [_c("a-tree", {
    attrs: {
      checkedKeys: _vm.checkedKeys,
      checkable: "",
      defaultExpandAll: "",
      "selected-keys": _vm.selectedKeys,
      "default-selected-keys": _vm.defaultSelectedKeys,
      "replace-fields": _vm.replaceFields,
      "tree-data": _vm.transferData
    },
    on: {
      check: _vm.onCheck
    }
  })], 1), _c("div", {
    staticClass: "son pl28"
  }, [_c("div", [_vm._v("表头信息")]), _c("draggable", {
    attrs: {
      handle: ".mover",
      chosenClass: "chosen",
      forceFallback: "true",
      group: "people",
      animation: "300",
      filter: ".forbid"
    },
    on: {
      start: _vm.onStart,
      end: _vm.onEnd
    },
    model: {
      value: _vm.rightData,
      callback: function callback($$v) {
        _vm.rightData = $$v;
      },
      expression: "rightData"
    }
  }, [_c("transition-group", _vm._l(_vm.rightData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "dsy_sb"
    }, [_c("div", {
      staticClass: "display-flex ai-center texts"
    }, [_c("div", {
      staticClass: "w30 mover",
      class: item.disableCheckbox ? "forbid" : ""
    }, [_c("a-icon", {
      attrs: {
        type: "unordered-list"
      }
    })], 1), _vm._v(" " + _vm._s(item.name) + " ")]), _c("a-space", [index !== 0 && !item.disableCheckbox ? _c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v("向上移动")])]), _c("a-icon", {
      attrs: {
        type: "vertical-align-top"
      },
      on: {
        click: function click($event) {
          return _vm.transposition(index, index - 1);
        }
      }
    })], 2) : _vm._e(), index < _vm.rightData.length - 1 && !item.disableCheckbox ? _c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v("向下移动")])]), _c("a-icon", {
      attrs: {
        type: "vertical-align-bottom"
      },
      on: {
        click: function click($event) {
          return _vm.transposition(index, index + 1);
        }
      }
    })], 2) : _vm._e(), !item.disableCheckbox ? _c("a-icon", {
      attrs: {
        type: "close"
      },
      on: {
        click: function click($event) {
          return _vm.remove(index);
        }
      }
    }) : _vm._e()], 1)], 1);
  }), 0)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };