import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wrap"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "back",
    on: {
      click: _vm.back
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  })], 1), _c("span", [_vm._v(_vm._s(_vm.mode === "create" ? "新增" : "") + "申报" + _vm._s(_vm.mode === "edit" ? "详情" : ""))])]), _c("a-spin", {
    staticClass: "main",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "info"
  }, [_c("div", [_c("div", {
    staticClass: "name"
  }, [_c("a-switch", {
    attrs: {
      disabled: !_vm.isEditing,
      size: "small"
    },
    model: {
      value: _vm.isPrecedence,
      callback: function callback($$v) {
        _vm.isPrecedence = $$v;
      },
      expression: "isPrecedence"
    }
  }), _c("span", [_vm._v("是否优先")])], 1), _vm.mode === "create" ? _c("div", {
    staticClass: "detail"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("span", [_vm._v("接单时间：")]), _vm._v(_vm._s(_vm.initValues.takeOrdersDate))]), _c("div", {
    staticClass: "item"
  }, [_c("span", [_vm._v("接单客服：")]), _vm._v(_vm._s(_vm.initValues.takeOrdersUserName))]), _c("div", {
    staticClass: "item"
  }, [_c("span", [_vm._v("所属用工单位：")]), _vm._v(_vm._s(_vm.initValues.companyName))])]) : _vm._e(), _vm.mode === "edit" && _vm.tempInfo ? _c("div", {
    staticClass: "detail"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("span", [_vm._v("接单时间：")]), _vm._v(_vm._s(_vm.formatDate(_vm.tempInfo.takeOrdersDate)))]), _c("div", {
    staticClass: "item"
  }, [_c("span", [_vm._v("接单客服：")]), _vm._v(_vm._s(_vm.tempInfo.takeOrdersUserName))]), _c("div", {
    staticClass: "item"
  }, [_c("span", [_vm._v("所属用工单位：")]), _vm._v(_vm._s(_vm.tempInfo.companyName))])]) : _vm._e()]), _vm.mode !== "create" ? _c("a-space", [_vm.mode === "edit" && _vm.isFinish ? _c("a-button", {
    on: {
      click: _vm.handleEventFinish
    }
  }, [_vm._v("恢复为未完结")]) : _vm._e(), _vm.mode === "edit" && !_vm.isFinish && !_vm.isEditing ? _c("a-button", {
    on: {
      click: _vm.handleEventFinish
    }
  }, [_vm._v("事件完结")]) : _vm._e(), _vm.permissions_dycl.includes("dycl:dycl.dyclList:update") && _vm.isEditing === false && !_vm.isFinish ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.isEditing = true;
      }
    }
  }, [_vm._v("编辑")]) : _vm._e()], 1) : _vm._e()], 1), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "box"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("员工信息")]), _vm.mode !== "create" ? _c("div", {
    staticClass: "empinfo"
  }, [_vm.empFields && _vm.empFields.length > 0 ? _c("FieldForm", {
    ref: "empInfo",
    attrs: {
      isEmpInfo: true,
      fieldList: _vm.empFields,
      editable: _vm.isEditing,
      isDetail: true
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.mode === "create" && _vm.empInfo ? [_c("a-button", {
    on: {
      click: _vm.selectStart
    }
  }, [_vm._v("重新选择")]), _c("div", {
    staticClass: "empinfo"
  }, [_vm.empFields && _vm.empFields.length > 0 ? _c("FieldForm", {
    ref: "empInfo",
    attrs: {
      isEmpInfo: true,
      fieldList: _vm.empFields,
      editable: _vm.isEditing,
      dataSource: _vm.empInfo
    }
  }) : _vm._e()], 1)] : _vm.mode === "create" ? _c("a-button", {
    staticStyle: {
      "margin-bottom": "24px"
    },
    on: {
      click: _vm.selectStart
    }
  }, [_vm._v("选择员工")]) : _vm._e(), _c("div", {
    staticClass: "title"
  }, [_vm._v("事件概况")]), _vm.eventFields && _vm.eventFields.length > 0 ? _c("FieldForm", {
    ref: "eventInfo",
    attrs: {
      fieldList: _vm.eventFields,
      editable: _vm.isEditing,
      isDetail: _vm.mode !== "create"
    }
  }) : _vm._e()], 2), _c("div", {
    staticClass: "line"
  }), _c("div", {
    staticClass: "box"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("处理过程")]), _c("div", {
    staticClass: "mb24"
  }, [_c("span", {
    staticClass: "mr12"
  }, [_vm._v("目前流程")]), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      options: _vm.workflowLst,
      disabled: !_vm.isEditing,
      allowClear: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.currentWorkflow,
      callback: function callback($$v) {
        _vm.currentWorkflow = $$v;
      },
      expression: "currentWorkflow"
    }
  })], 1), _vm._l(_vm.processes, function (li, idx) {
    return _c("div", {
      key: "".concat(li.name, "_").concat(idx),
      staticClass: "process",
      class: {
        first: idx === 0
      }
    }, [_c("div", {
      staticClass: "pTitle"
    }, [_vm._v(_vm._s(idx + 1) + " " + _vm._s(li.name))]), _c("div", {
      staticClass: "pBox"
    }, [li.fields && li.fields.length > 0 ? _c("FieldForm", {
      ref: "processInfo_".concat(idx),
      refInFor: true,
      attrs: {
        fieldList: li.fields,
        editable: _vm.isEditing,
        isDetail: _vm.mode !== "create"
      }
    }) : _vm._e()], 1)]);
  })], 2)])]), _vm.isEditing ? _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    attrs: {
      disabled: _vm.spinning,
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保存")])], 1) : _vm._e(), _c("a-modal", {
    attrs: {
      title: "选择员工"
    },
    on: {
      ok: _vm.selectOk
    },
    model: {
      value: _vm.selectModalVisible,
      callback: function callback($$v) {
        _vm.selectModalVisible = $$v;
      },
      expression: "selectModalVisible"
    }
  }, [_vm.empLst && _vm.empLst.length > 0 ? _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      defaultValue: _vm.defaultSelectValue,
      options: _vm.empLst,
      filterOption: _vm.filterOption,
      placeholder: "员工姓名/身份证号/手机号",
      showSearch: ""
    },
    on: {
      change: _vm.selectChange
    }
  }) : _c("a-empty", {
    staticClass: "empty",
    attrs: {
      description: "正在加载员工..."
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      slot: "image",
      type: "loading"
    },
    slot: "image"
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };