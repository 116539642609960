import "core-js/modules/es.array.concat.js";
export var followTypeList = [{
  label: "拜访",
  value: 1
}, {
  label: "电话",
  value: 2
}, {
  label: "微信",
  value: 3
}, {
  label: "QQ",
  value: 4
}, {
  label: "邮件",
  value: 5
}, {
  label: "短信",
  value: 6
}, {
  label: "其他",
  value: 7
}];
export var followColumns = [{
  dataIndex: "followUserName",
  title: "跟进人",
  width: 150
}, {
  dataIndex: "followType",
  title: "跟进方式",
  width: 100,
  customRender: function customRender(text) {
    return followTypeList[text - 1].label;
  }
}, {
  dataIndex: "followContent",
  title: "跟进内容",
  scopedSlots: {
    customRender: "followContent"
  }
}, {
  dataIndex: "gmtCreate",
  title: "创建时间",
  width: 200,
  customRender: function customRender(text) {
    var date = new Date(text);
    return "".concat(date.getFullYear(), "-").concat(date.getMonth() + 1, "-").concat(date.getDate(), " ").concat(date.getHours(), ":").concat(date.getMinutes());
  }
}, {
  dataIndex: "action",
  title: "操作",
  width: 100,
  scopedSlots: {
    customRender: "action"
  }
}];