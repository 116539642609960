import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "r-msg-box page-bgBack"
  }, [_c("div", {
    staticClass: "backBtn-box"
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  }), _vm._v("返回 ")], 1)], 1), _c("rTitle", {
    attrs: {
      tit: _vm.postName
    }
  }, [_c("div", {
    staticStyle: {
      "margin-left": "-18px"
    },
    attrs: {
      slot: "left"
    },
    slot: "left"
  }, [this.isCancel === 2 ? _c("div", {
    staticClass: "r-status",
    style: {
      background: _vm.primaryColor
    }
  }, [_vm._v("招聘中")]) : _c("div", {
    staticClass: "r-status"
  }, [_vm._v("已关闭")])]), _c("div", {
    attrs: {
      slot: "right"
    },
    slot: "right"
  }, [_vm.isCancel === 1 ? _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      disabled: this.dataJson.companyUnitsVO == null
    },
    on: {
      click: function click($event) {
        return _vm.close("打开职位");
      }
    }
  }, [_vm._v("打开职位")]) : _vm._e(), _vm.isCancel === 2 ? _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      disabled: this.dataJson.companyUnitsVO == null
    },
    on: {
      click: function click($event) {
        return _vm.close("关闭职位");
      }
    }
  }, [_vm._v("关闭职位")]) : _vm._e(), _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onEdit
    }
  }, [_vm._v("编辑")]), this.isCancel === 2 ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onShare
    }
  }, [_vm._v("生成岗位链接")]) : _vm._e()], 1)]), _c("div", {
    staticClass: "r-msg-status"
  }, _vm._l(_vm.numArr, function (item, index) {
    return _c("div", {
      key: item.type,
      staticClass: "s-item"
    }, [_c("div", {
      staticClass: "s-item-t"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "s-item-b"
    }, [_vm._v(_vm._s(item.num))]), index != _vm.numArr.length - 1 ? _c("div", {
      staticClass: "s-item-line"
    }) : _vm._e()]);
  }), 0), _c("a-tabs", {
    attrs: {
      type: "card",
      "default-active-key": "1"
    },
    on: {
      change: _vm.callback
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "职位详情"
    }
  }, [_c("Position", {
    attrs: {
      dataJson: _vm.dataJson
    }
  })], 1), _c("a-tab-pane", {
    key: "4",
    attrs: {
      tab: "投递简历设置"
    }
  }, [_c("resumeDeliverySettings")], 1), _vm.isBroker === 1 ? _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "经纪人"
    }
  }, [_c("economic", {
    attrs: {
      brokerRule: _vm.brokerRule
    }
  })], 1) : _vm._e(), _vm.isEmp === 1 ? _c("a-tab-pane", {
    key: "3",
    attrs: {
      tab: "内推"
    }
  }, [_c("mPush")], 1) : _vm._e()], 1), _c("share", {
    attrs: {
      visible: _vm.visible,
      shareIdArr: _vm.shareIdArr,
      shareType: "poster"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };