import "core-js/modules/es.array.push.js";
export function getAge(strBirthday) {
  var returnAge = "";
  var strBirthdayArr = strBirthday.split("-");
  var birthYear = strBirthdayArr[0];
  var birthMonth = strBirthdayArr[1];
  var birthDay = strBirthdayArr[2];
  var d = new Date();
  var nowYear = d.getFullYear();
  var nowMonth = d.getMonth() + 1;
  var nowDay = d.getDate();
  if (nowYear === birthYear) {
    returnAge = 0; // 同年 则为0周岁
  } else {
    var ageDiff = nowYear - birthYear; // 年之差
    if (ageDiff > 0) {
      if (nowMonth === birthMonth) {
        var dayDiff = nowDay - birthDay; // 日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      } else {
        var monthDiff = nowMonth - birthMonth; // 月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      }
    } else {
      returnAge = -1; // 返回-1 表示出生日期输入错误 晚于今天
    }
  }

  return returnAge; // 返回周岁年龄
}

export var typeList = {
  laborContractLimit: "固定期限劳动合同",
  laborContractUnlimit: "无固定期限劳动合同",
  laborContractJobs: "以完成一定工作任务为期限的劳动合同",
  internAccords: "实习协议",
  laborContract: "劳务合同",
  reemployAccords: "返聘协议",
  laborContractDispatch: "劳务派遣合同",
  borrowedContract: "借调合同",
  engagementLetter: "约定书",
  partTime: "兼职合同",
  noviciateAgreement: "见习协议",
  dimissionContract: "离职合同",
  retireReEmploy: "退休返聘合同",
  other: "其他"
};
export var fieldsName = ["annexPersonPhoto", "annexIdCard", "annexDegree", "annexHousehold", "annexSalaryCard", "annexSocCard", "annexPfCard", "annexPractising", "annexReward", "annexNoCriminal", "annexLaborContract", "annexLastLeaveCert", "annexOnbCheckup", "annexOnbResume", "annexWorkApply", "annexAdjustNotice", "annexPersonalChange", "annexSocTransfer", "annexPerformanceReport", "annexNoPregnant", "annexLeaveAppro", "annexLeaveCert", "annexLeaveApply", "annexHandoverTable", "annexLeaving"];
export var fieldsIcon = {
  annexPersonPhoto: "icon-zhengjianzhao",
  annexIdCard: "icon-shenfenzheng",
  annexDegree: "icon-xueli",
  annexHousehold: "icon-hukouben",
  annexSalaryCard: "icon-gongzika",
  annexSocCard: "icon-gongzika",
  annexPfCard: "icon-gongzika",
  annexPractising: "icon-zhengshu",
  annexReward: "icon-zhengshu",
  annexNoCriminal: "icon-jilu",
  annexLaborContract: "icon-laodonghetong",
  annexLastLeaveCert: "icon-lizhizhengming",
  annexOnbCheckup: "icon-ruzhitijian",
  annexOnbResume: "icon-ruzhijianli",
  annexWorkApply: "icon-zidingyi",
  annexAdjustNotice: "icon-tiaoxin",
  annexPersonalChange: "icon-renshiyidong",
  annexSocTransfer: "icon-shehuibaoxian",
  annexPerformanceReport: "icon-jixiao",
  annexNoPregnant: "icon-weiyun",
  annexLeaveAppro: "icon-lizhizhengming",
  annexLeaveCert: "icon-lizhizhengming",
  annexLeaveApply: "icon-lizhizhengming",
  annexHandoverTable: "icon-zidingyi",
  annexLeaving: "icon-zidingyi"
};
export var box = {
  status1: "base",
  status2: "base",
  status3: "base",
  status4: "base",
  status5: "base",
  status6: "base",
  status7: "base",
  status8: "base",
  status9: "base",
  status10: "base",
  status11: "base",
  status12: "base",
  status13: "base",
  status14: "base",
  status15: "base",
  status16: "base",
  status17: "base",
  status18: "base",
  status19: "base"
};
export function buildTabListType(showShangbao, showDycl) {
  var baseArr = [{
    value: 0,
    label: "个人信息"
  }, {
    value: 1,
    label: "在职信息"
  }, {
    value: 2,
    label: "用工档案"
  }, {
    value: 3,
    label: "合同信息"
  }, {
    value: 4,
    label: "工资信息"
  }, {
    value: 7,
    label: "社保信息"
  }, {
    value: 5,
    label: "材料附件"
  }, {
    value: 6,
    label: "个税信息"
  }];
  if (showShangbao) {
    baseArr.push({
      value: 9,
      label: "商保信息"
    });
  }
  if (showDycl) {
    baseArr.push({
      value: 8,
      label: "待遇处理"
    });
  }
  return baseArr;
}