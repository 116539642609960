export default {
  fields: {
    empName: {
      model: "empName"
    },
    empPhone: {
      model: "empPhone"
    },
    workingHours: {
      model: "workingHours"
    },
    workDays: {
      model: "workDays"
    },
    sallaryImpact: {
      model: "sallaryImpact"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "empName",
        title: "姓名"
      }, {
        model: "empPhone",
        title: "手机号"
      },
      // {
      //   model: "workingHours",
      //   title: "岗位"
      // },
      {
        model: "workDays",
        title: "上班天数"
      }, {
        model: "sallaryImpact",
        title: "工资影响"
      }],
      customize: {
        rowKey: "id"
      },
      extend: {
        batch: false,
        action: true
      }
    }
  }
};