import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import { formatDateAll } from "@/utils/index";
import { ListEmpNotices, deleteUserNoticeBatch } from "@/api/company/index";
var noticeColumns = [{
  title: "通知标题",
  dataIndex: "title",
  scopedSlots: {
    customRender: "title"
  }
}, {
  title: "阅读状态",
  dataIndex: "readStatus",
  scopedSlots: {
    customRender: "readStatus"
  }
}, {
  title: "发布时间",
  dataIndex: "publishDate",
  customRender: function customRender(text, record, index) {
    return formatDateAll(text);
  }
},
// {
//   title: "已阅读人",
//   dataIndex: "readEmpName",
//   width: 250,
//   scopedSlots: { customRender: "readEmpName" }
// },
// {
//   title: "未阅读人",
//   dataIndex: "noReadEmpName",
//   width: 250,
//   scopedSlots: { customRender: "noReadEmpName" }
// },
{
  title: "发布范围",
  dataIndex: "publishEmpRangeIsAll",
  width: 180,
  customRender: function customRender(text, record, index) {
    return text === true ? "全公司" : "部分员工";
  }
}, {
  title: "推送设置",
  dataIndex: "noticeType",
  width: 200,
  customRender: function customRender(text, record, index) {
    var content = [];
    if (text) {
      text.split(",").forEach(function (element) {
        if (element === "2") {
          content.push("短信");
        } else if (element === "1") {
          content.push("微信");
        } else if (element === "3") {
          content.push("邮件");
        }
      });
    } else {
      content.push("--");
    }
    return content;
  }
}, {
  title: "操作",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  }
}];
export default {
  data: function data() {
    return {
      loading: false,
      noticeColumns: noticeColumns,
      searchForm: this.$form.createForm(this),
      title: null,
      relatedStaffName: "",
      noticelist: [],
      categorieslistAll: [],
      detailVisible: false,
      confirmLoading: false,
      deleteVisible: false,
      currentContract: {},
      eleContractDetail: {},
      recordDetail: {},
      recordVisible: false,
      currentTab: 1,
      companyId: "",
      status: [{
        id: 0,
        name: "待发布"
      }, {
        id: 1,
        name: "已发布"
      }, {
        id: 2,
        name: "已下线"
      }],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true
      }
    };
  },
  created: function created() {
    this.relatedStaffName = this.$store.state.auth.name;
    this.companyId = this.$store.state.hr.companyId;
    this.getAllNoticelist();
  },
  methods: {
    showDetail: function showDetail(e) {
      console.log(e);
      this.recordDetail = e;
      this.recordVisible = true;
    },
    createNotice: function createNotice() {
      this.$router.push({
        path: "person/createNotice",
        query: {
          sign: "0"
        }
      });
    },
    getAllNoticelist: function getAllNoticelist() {
      var _this = this;
      this.loading = true;
      ListEmpNotices({
        companyId: this.companyId,
        title: this.title,
        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current
      }).then(function (res) {
        _this.noticelist = res.data.entities;
        _this.pagination.total = res.data.entityCount;
      }).finally(function () {
        _this.loading = false;
      });
    },
    tabChange: function tabChange(pagination, current) {
      this.pagination.current = pagination.current;
      this.getAllNoticelist();
    },
    goDetail: function goDetail(record) {
      this.$router.push({
        path: "person/detail",
        query: {
          userId: record.publishUserId,
          empName: record.empName,
          sign: "1",
          id: record.id
        }
      });
    },
    searchContarct: function searchContarct(e) {
      var _this2 = this;
      console.log("123", e);
      if (e) {
        this.pagination.current = 1;
        ListEmpNotices({
          companyId: this.companyId,
          title: e,
          pageSize: this.pagination.pageSize,
          pageNo: 1 // this.pagination.current
        }).then(function (res) {
          _this2.noticelist = res.data.entities;
          _this2.pagination.total = res.data.entityCount;
        });
      } else {
        this.pagination.current = 1;
        this.getAllNoticelist();
      }
    },
    deleteNotice: function deleteNotice(record) {
      this.currentNotice = record;
      this.deleteVisible = true;
    },
    confirmDelete: function confirmDelete() {
      var _this3 = this;
      var userNoticeIds = [];
      userNoticeIds.push(this.currentNotice.id);
      deleteUserNoticeBatch(userNoticeIds).then(function (res) {
        _this3.deleteVisible = false;
        _this3.$message.success("删除成功");
        _this3.getAllNoticelist();
      });
    }
  }
};