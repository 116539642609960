var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-center-between"
  }, [_c("div", {
    staticClass: "flex-center p16 br4 pointer",
    class: {
      "bgc-themeA10": _vm.status === 1
    },
    staticStyle: {
      "min-width": "240px"
    },
    on: {
      click: function click($event) {
        return _vm.setStatus(1);
      }
    }
  }, [_c("img", {
    staticClass: "mr12",
    staticStyle: {
      width: "48px",
      height: "48px"
    },
    attrs: {
      src: require("./zaibao.png")
    }
  }), _c("div", [_c("div", [_vm._v("在保中人员")]), _c("div", [_vm._v(_vm._s(_vm.dataSource.insIngCount || _vm.dataSource.insIngCount === 0 ? _vm.dataSource.insIngCount : "--"))])])]), _c("div", {
    staticClass: "flex-center p16 br4 pointer",
    class: {
      "bgc-themeA10": _vm.status === 2
    },
    staticStyle: {
      "min-width": "240px"
    },
    on: {
      click: function click($event) {
        return _vm.setStatus(2);
      }
    }
  }, [_c("img", {
    staticClass: "mr12",
    staticStyle: {
      width: "48px",
      height: "48px"
    },
    attrs: {
      src: require("./yishixiao.png")
    }
  }), _c("div", [_c("div", [_vm._v("已失效人员")]), _c("div", [_vm._v(_vm._s(_vm.dataSource.insLapseCount || _vm.dataSource.insLapseCount === 0 ? _vm.dataSource.insLapseCount : "--"))])])]), _c("div", {
    staticClass: "flex-center p16 br4 pointer",
    class: {
      "bgc-themeA10": _vm.status === 3
    },
    staticStyle: {
      "min-width": "240px"
    },
    on: {
      click: function click($event) {
        return _vm.setStatus(3);
      }
    }
  }, [_c("img", {
    staticClass: "mr12",
    staticStyle: {
      width: "48px",
      height: "48px"
    },
    attrs: {
      src: require("./daishengxiao.png")
    }
  }), _c("div", [_c("div", [_vm._v("待生效人员")]), _c("div", [_vm._v(_vm._s(_vm.dataSource.insFutureCount || _vm.dataSource.insFutureCount === 0 ? _vm.dataSource.insFutureCount : "--"))])])]), _c("div", {
    staticClass: "flex-center p16 br4",
    staticStyle: {
      "min-width": "240px"
    }
  }, [_c("img", {
    staticClass: "mr12",
    staticStyle: {
      width: "48px",
      height: "48px"
    },
    attrs: {
      src: require("./zaibaobaofei.png")
    }
  }), _c("div", [_c("div", [_vm._v("在保保费合计")]), _c("div", [_vm._v(_vm._s(_vm.dataSource.amount || _vm.dataSource.amount === 0 ? _vm.dataSource.amount : "--") + "元/月")])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };