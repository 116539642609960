export default {
  fields: {
    configurationName: {
      model: "configurationName"
    },
    configurationStatus: {
      model: "configurationStatus"
    },
    configurationExplain: {
      model: "configurationExplain"
    },
    sallaryImpact: {
      model: "sallaryImpact"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "configurationStatus",
        title: "工种"
      }, {
        model: "configurationExplain",
        title: "说明"
      }, {
        model: "sallaryImpact",
        title: "基本工资"
      }],
      customize: {
        rowKey: "id"
      },
      extend: {
        batch: false,
        action: true
      }
    }
  }
};