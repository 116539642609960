import moment from "moment";
import { getApprovalStatus, getApprovalStatusIcon, getApprovalStatusColorBlue, getApprovalStatusColor } from "../funcs";
export default {
  props: ["approvalRecord"],
  watch: {
    approvalRecord: {
      handler: function handler(val, oldval) {
        // console.log("123123", val);
        if (val) {
          this.creatApprovalProcess(val);
        }
      },
      immediate: true,
      deep: true
    }
  },
  data: function data() {
    return {
      popRecord: null
    };
  },
  methods: {
    getApprovalStatus: getApprovalStatus,
    getApprovalStatusIcon: getApprovalStatusIcon,
    getApprovalStatusColorBlue: getApprovalStatusColorBlue,
    getApprovalStatusColor: getApprovalStatusColor,
    moment: moment,
    creatApprovalProcess: function creatApprovalProcess(val) {
      this.popRecord = val;
    }
  }
};