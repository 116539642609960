import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.set.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import { getTemplates, deleteSalaryTemplates, deleteSalary, batchDeleteSalary } from "@/api/wages/template";
import { findCompanyGroup as _findCompanyGroup } from "@/api/company/contract";
import { getNotArchiveById } from "@/api/wages/monthly";
import { isContainByString } from "@/utils/index";
import { saveIssuedSeal as _saveIssuedSeal } from "./api";
import { formatCompanyTree } from "@/utils/tools";
export default {
  data: function data() {
    return {
      isContainByString: isContainByString,
      loading: true,
      columns: [],
      data: [],
      dataList: [],
      companyId: this.$store.state.hr.companyId,
      // modal显示隐藏
      issueModalVisible: false,
      // 下发选择框合同数据
      contractData: [],
      // 已选的合同模板数据
      contractList: [],
      // 已选的公司数据
      companyList: []
    };
  },
  created: function created() {
    this.findCompanyGroup();
    this.getTemplatesList();
    if (this.isContainByString("contractAdmin")) {
      this.columns = [{
        title: "模板名称",
        dataIndex: "name",
        key: "name"
      }, {
        title: "工资项",
        dataIndex: "salaryTemplateItem",
        key: "salaryTemplateItem",
        scopedSlots: {
          customRender: "salaryTemplateItem"
        }
      }, {
        title: "操作",
        key: "action",
        scopedSlots: {
          customRender: "action"
        }
      }];
    } else {
      this.columns = [{
        title: "模板名称",
        dataIndex: "name",
        key: "name"
      }, {
        title: "工资项",
        dataIndex: "salaryTemplateItem",
        key: "salaryTemplateItem",
        scopedSlots: {
          customRender: "salaryTemplateItem"
        }
      }, {
        title: "适用薪资组",
        dataIndex: "salaryGroup",
        key: "salaryGroup",
        scopedSlots: {
          customRender: "salaryGroup"
        }
      }, {
        title: "操作",
        key: "action",
        scopedSlots: {
          customRender: "action"
        }
      }];
    }
  },
  methods: {
    companySelectChange: function companySelectChange(companyKeys) {
      this.companyList = companyKeys.filter(function (item) {
        return item.includes("_company_");
      });
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    treeSearch: function treeSearch(e) {
      this.findCompanyGroup(e);
    },
    findCompanyGroup: function findCompanyGroup(e) {
      var _this2 = this;
      _findCompanyGroup({
        name: e || null
      }).then(function (res) {
        _this2.dataList = formatCompanyTree(res.data);
        _this2.companyList = JSON.parse(JSON.stringify(_this2.companyList));
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    deleteSalaryTemplatesList: function deleteSalaryTemplatesList(record) {
      var _this3 = this;
      getNotArchiveById({
        salaryTemplateId: record.id
      }).then(function (res) {
        // res.data.monthly
        if (res.data) {
          var arr = [];
          res.data.forEach(function (item, index) {
            arr.push(item.monthly);
          });
          arr = arr.join(",");
          var idList = [];
          res.data.forEach(function (item, index) {
            idList.push(item.id);
          });
          var _this = _this3;
          _this3.$confirm({
            okText: "确定",
            cancelText: "取消",
            title: "\u8BE5\u6A21\u677F\u5DF2\u5728".concat(arr, "\u6708\u521B\u5EFA\u6708\u5DE5\u8D44\u8868\uFF0C\u5220\u9664\u6A21\u677F\u4F1A\u540C\u65F6\u5220\u9664\u672A\u5F52\u6863\u7684\u6708\u5DE5\u8D44\u8868\uFF0C\u4ECD\u65E7\u7EE7\u7EED\u5417\uFF1F"),
            onOk: function onOk() {
              deleteSalaryTemplates(record.id).then(function (_ref) {
                var success = _ref.success;
                if (success) {
                  _this.$notification["success"]({
                    message: "删除成功"
                  });
                  _this.getTemplatesList();
                }
              });
              batchDeleteSalary(idList).then(function (_ref2) {
                var success = _ref2.success;
              });
              // deleteSalary(res.data.id).then(({ success }) => {});
            }
          });
        } else {
          deleteSalaryTemplates(record.id).then(function (_ref3) {
            var success = _ref3.success;
            if (success) {
              _this3.$notification["success"]({
                message: "删除成功"
              });
              _this3.getTemplatesList();
            }
          });
        }
      });
    },
    getTemplatesList: function getTemplatesList() {
      var _this4 = this;
      this.loading = true;
      getTemplates({
        companyId: this.isContainByString("contractAdmin") ? "0" : this.companyId
      }).then(function (res) {
        _this4.loading = false;
        _this4.data = res.data;
        _this4.contractData = res.data;
        for (var i = 0; i < res.data.length; i++) {
          _this4.data[i].salaryTemplateItem = [];
          _this4.data[i].salaryGroup = [];
          if (res.data[i].salaryTemplateItemVOList && res.data[i].salaryTemplateItemVOList.length) {
            for (var p = 0; p < res.data[i].salaryTemplateItemVOList.length; p++) {
              if (res.data[i].salaryTemplateItemVOList[p].isUse === true) {
                _this4.data[i].salaryTemplateItem.push(res.data[i].salaryTemplateItemVOList[p].name + " ");
              }
            }
          }
          if (res.data[i].salaryGroupVOList && res.data[i].salaryGroupVOList.length) {
            for (var s = 0; s < res.data[i].salaryGroupVOList.length; s++) {
              _this4.data[i].salaryGroup.push(res.data[i].salaryGroupVOList[s].name);
            }
          }
        }
      });
    },
    // 跳转到新增工资条模板
    goAdd: function goAdd() {
      this.$router.push({
        path: "/".concat(this.isContainByString("contractAdmin") ? "contractAdmin" : "wages", "/template/add")
      });
    },
    goEdit: function goEdit(record) {
      this.$router.push({
        path: "/".concat(this.isContainByString("contractAdmin") ? "contractAdmin" : "wages", "/template/edit"),
        query: {
          id: record.id
        }
      });
    },
    saveIssuedSeal: function saveIssuedSeal() {
      var _this5 = this;
      if (this.companyList.length === 0) {
        this.$notification["error"]({
          message: "错误",
          description: "请选择需要下发的公司"
        });
        return;
      }
      if (this.contractList.length === 0) {
        this.$notification["error"]({
          message: "错误",
          description: "请选择需要下发的合同模板"
        });
        return;
      }
      var companyArr = Array.from(new Set(this.companyList.map(function (item) {
        return item.split("_")[2];
      })));
      var data = {
        companyId: companyArr,
        salaryTemplateIds: this.contractList
      };
      _saveIssuedSeal(data).then(function (res) {
        _this5.issueModalVisible = false;
        _this5.$notification["success"]({
          message: "成功",
          description: "下发成功"
        });
        _this5.companyList = [];
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 方法
    confirmIssueModal: function confirmIssueModal() {
      // TODO 下发接口
      this.saveIssuedSeal();
    },
    handleCancelIssueModal: function handleCancelIssueModal() {
      this.issueModalVisible = false;
      // 已选的合同模板数据
      this.contractList = [];
      // 已选的公司数据
      this.companyList = [];
    },
    showIssueModa: function showIssueModa() {
      this.issueModalVisible = true;
    }
  }
};