import request from "@/utils/request";
import api from "xqjr-module-auth/api";

// 根据员工信息id查询员工信息详情
export function empInfos(params, empInfoId) {
  return request({
    url: "/hr/api/v1/empInfos/".concat(empInfoId),
    method: "GET",
    params: params
  });
}

// 查询员工奖惩记录
// export function empRewardPunishes(companyId, empId) {
export function empRewardPunishes(empId) {
  return request({
    url: "/hr/api/v1/empRewardPunishes/listAll",
    method: "GET",
    // params: { companyId, empId }
    params: {
      empId: empId
    }
  });
}

// 修改员工信息
export function empInfosEdit(data) {
  return request({
    url: "/hr/api/v1/empInfos",
    method: "PUT",
    data: data
  });
}

// 查询公司动态表单模块
export function listCompanyModuleCodes(params) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/listCompanyModuleCodes",
    method: "GET",
    params: params
  });
}

// 查询公司动态表单员工填写
export function getEmpFieldValue(params) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/getEmpFieldValue",
    method: "GET",
    params: params
  });
}

// 查询详情全部字段值
export function getAllEmpFieldValueReq(params) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/getAllEmpFieldValue",
    method: "GET",
    params: params
  });
}

// 员工保存动态表单字段的值
export function empFieldValueSave(data, url) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/empFieldValue".concat(url || ""),
    method: "POST",
    data: data
  });
}

// 员工保存动态表单字段的值
export function empFieldValueSavePut(data, url) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/empFieldValue".concat(url || ""),
    method: "put",
    data: data
  });
}

// 员工修改动态表单字段的值
export function empFieldValueUpdata(data) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/empFieldValue",
    method: "PUT",
    data: data
  });
}
export function empFieldValueDelet(data) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/empFieldValue",
    method: "DELETE",
    data: data
  });
}

// 根据员工个税累计表id查询员工个税累计表详情
export function getEmpTaxGrand(params) {
  return request({
    url: "/salary/api/v1/empTaxGrandTotals/findByEmpId",
    method: "get",
    params: params
  });
}

// 根据员工id,年,月查询专项附加项详情
export function getSpecial(params) {
  return request({
    url: "/salary/api/v1/specialAdditionalDetails/findByEmpIdAndYearAndMonth",
    method: "get",
    params: params
  });
}

// 修改或新增专项附加项详情
export function updateSpecial(data) {
  return request({
    url: "/salary/api/v1/specialAdditionalDetails/updateOrSave",
    method: "POST",
    data: data
  });
}

// 新增或修改个税
export function updateTaxGrand(data) {
  return request({
    url: "/salary/api/v1/empTaxGrandTotals/saveOrUpdate",
    method: "POST",
    data: data
  });
}

// 新增员工奖惩记录
export function empRewardPunishesAdd(data) {
  return request({
    url: "/hr/api/v1/empRewardPunishes",
    method: "POST",
    data: data
  });
}

// 修改员工奖惩记录
export function empRewardPunishesUpdata(data) {
  return request({
    url: "/hr/api/v1/empRewardPunishes",
    method: "PUT",
    data: data
  });
}

// 删除单个员工奖惩记录，彻底删除
export function empRewardPunishesDet(empRewardPunishId) {
  return request({
    url: "/hr/api/v1/empRewardPunishes/".concat(empRewardPunishId),
    method: "DELETE"
  });
}

// 删除员工
export function delEmpInfo(data) {
  return request({
    url: "/hr/api/v1/empInfos/" + data.id,
    method: "delete"
  });
}
export function delEmpInfoBatchReq(data) {
  return request({
    url: "/hr/api/v1/empInfos",
    method: "delete",
    data: data
  });
}
export function getEmpCurrentPosition(params) {
  return request({
    url: "/hr/api/v1/empInfos/getEmpCurrentPosition",
    method: "get",
    params: params
  });
}
export function userInfoReq(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectUserInfoById",
    method: "GET",
    params: params
  });
}
export function unbindWechatReq(data) {
  return request({
    url: "/hr/api/v1/empInfos/unbindWechatByUserId",
    method: "POST",
    data: data
  });
}

// 新增员工标签
export function empTagAdd(data) {
  return request({
    url: "/hr/api/v1/empTag",
    method: "POST",
    data: data
  });
}

// 删除员工标签
export function empTagDelete(data) {
  return request({
    url: "/hr/api/v1/empTag",
    method: "DELETE",
    data: data
  });
}

// 查询标签
export function listByCompanyIdGet(params) {
  return request({
    url: "/hr/api/v1/empTag/listByCompanyId",
    method: "get",
    params: params
  });
}
// 后台查询标签
export function listByGroupCompanyIdGet(data) {
  return request({
    url: "/hr/api/v1/empTag/listByCompanyIds",
    method: "post",
    data: data
  });
}

// 修改员工标签&备注
export function editEmpFieldValue(data) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/empFieldValue",
    method: "PUT",
    data: data
  });
}

// 下发模板
export function selectOpenTemplateOldReq(params) {
  return request({
    url: "/hr/api/v1/customTemplates/selectOpenTemplate",
    method: "get",
    params: params
  });
}

// 下发模板
export function selectOpenTemplate3Req(params) {
  return request({
    url: "/hr/api/v1/customTemplates/selectOpenTemplate3",
    method: "get",
    params: params
  });
}

// 下发模板
export function selectOpenTemplate(params) {
  return request({
    url: "/hr/api/v1/customTemplates/selectOpenTemplate2",
    method: "get",
    params: params
  });
}

// 查询预登记个人信息
export function getEmpField(params) {
  return request({
    url: "/hr/api/v1/customTemplates/getEmpField",
    method: "get",
    params: params
  });
}

// 粘贴选择：查询员工(查询离职员工相关信息)
export function selectEmpInfoLeaveRelationempInfos(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoLeaveRelation/".concat(companyId),
    method: "post",
    data: data
  });
}
// 粘贴选择：查询员工(查询在职员工相关信息)
export function selectEmpInfoOnJobRelationempInfos(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoOnJobRelation/".concat(companyId),
    method: "post",
    data: data
  });
}

// 根据员工Id确认离职(批量)
export function leaveBatchReq(data) {
  return request({
    url: "/hr/api/v1/empChanges/confimByEmp/leave/batch",
    method: "post",
    data: data
  });
}

// 固定薪资
export function listAllByEmpId(params) {
  return request({
    url: "/salary/api/v1/fixedSalaryRecords/listAllByEmpId",
    method: "get",
    params: params
  });
}