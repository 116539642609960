export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    tit: {
      type: String,
      default: ""
    }
  }
};