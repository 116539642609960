import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      width: 850,
      title: "添加员工",
      visible: _vm.show
    },
    on: {
      cancel: _vm.cancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticStyle: {
      "padding-top": "4px"
    }
  }, [_c("a-button", {
    attrs: {
      size: "small",
      type: "link"
    },
    on: {
      click: _vm.showSenior
    }
  }, [_c("a-icon", {
    attrs: {
      type: "search"
    }
  }), _vm._v("高级搜索")], 1), _c("a-button", {
    attrs: {
      size: "small",
      type: "link"
    },
    on: {
      click: _vm.showCopy
    }
  }, [_c("a-icon", {
    attrs: {
      type: "copy"
    }
  }), _vm._v("粘贴选择")], 1)], 1), _c("div", [_c("a-button", {
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.ok
    }
  }, [_vm._v(" 确定 ")])], 1)])]), _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "employee-selector-wrapper2"
  }, [_c("div", [_c("div", {
    staticClass: "employee-selector-block"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "输入姓名或手机号搜索员工"
    },
    on: {
      search: _vm.searchPeople,
      change: _vm.changePeople
    }
  })], 1), _c("a-tabs", {
    staticStyle: {
      "margin-bottom": "10px",
      width: "450px"
    },
    attrs: {
      "default-active-key": "1",
      size: "small",
      tabBarGutter: 3
    },
    on: {
      change: _vm.onChangeTab
    }
  }, _vm._l(_vm.tabsList, function (item2, index2) {
    return _c("a-tab-pane", {
      key: index2,
      attrs: {
        tab: item2
      }
    }, [_c("div", {
      staticClass: "tree-item2"
    }, [_c("div", [_vm._v("全选当页")]), _vm.isAllSelected ? _c("xq-icon", {
      staticClass: "iconStyle",
      attrs: {
        type: "icon-wancheng2"
      },
      on: {
        click: function click($event) {
          return _vm.choseAll(_vm.treeData);
        }
      }
    }) : _c("img", {
      staticStyle: {
        width: "18px",
        height: "18px",
        "margin-left": "10px"
      },
      attrs: {
        src: require("@/assets/icons/未选择.png")
      },
      on: {
        click: function click($event) {
          return _vm.choseAll(_vm.treeData);
        }
      }
    })], 1), _c("div", {
      staticClass: "employee-selector",
      staticStyle: {
        position: "relative"
      }
    }, _vm._l(_vm.treeData, function (item, index) {
      return _c("div", {
        key: index,
        staticClass: "mg_t10"
      }, [_c("div", {
        staticClass: "tree-item",
        on: {
          click: function click($event) {
            return _vm.chose(item, _vm.treeData);
          }
        }
      }, [_c("span", [_c("img", {
        staticStyle: {
          width: "30px",
          height: "30px",
          "margin-right": "10px"
        },
        attrs: {
          src: require("@/assets/icons/person1.png")
        }
      }), _vm._v(" " + _vm._s(item.name) + " " + _vm._s(item.phone ? "(" + item.phone + ")" : "") + " ")]), _c("div", {
        staticStyle: {
          position: "absolute",
          right: "60px"
        }
      }, [item.contractExpirationDay ? _c("a-popover", [_c("template", {
        staticStyle: {
          "font-size": "12px",
          "font-weight": "400",
          color: "#55565d",
          "line-height": "18px"
        },
        slot: "content"
      }, [_c("div", [_vm._v(" 剩余签署时间： " + _vm._s(item.contractExpirationDay !== -1 ? item.contractExpirationDay + "天" : "已到期") + " ")]), _c("div", [_vm._v("合同开始时间：" + _vm._s(item.contractSignDate))]), _c("div", [_vm._v("合同结束时间：" + _vm._s(item.contractExpirationDate))])]), item.contractExpirationDay ? _c("span", [_vm._v(" " + _vm._s(item.contractExpirationDay !== -1 ? item.contractExpirationDay + "天" : "已到期") + " ")]) : _vm._e()], 2) : index2 === 1 ? _c("span", [_vm._v(" 未签署 ")]) : _c("span")], 1), item.isSelected ? _c("xq-icon", {
        staticClass: "iconStyle",
        attrs: {
          type: "icon-wancheng2"
        },
        on: {
          click: function click($event) {
            $event.stopPropagation();
            return _vm.chose(item, _vm.treeData);
          }
        }
      }) : _c("img", {
        staticStyle: {
          width: "18px",
          height: "18px"
        },
        attrs: {
          src: require("@/assets/icons/未选择.png")
        },
        on: {
          click: function click($event) {
            $event.stopPropagation();
            return _vm.chose(item, _vm.treeData);
          }
        }
      })], 1)]);
    }), 0), _c("a-pagination", {
      attrs: {
        size: "small",
        "page-size-options": _vm.pageSizeOptions,
        total: _vm.total,
        "show-size-changer": "",
        "show-less-items": "",
        "page-size": _vm.pageSize
      },
      on: {
        showSizeChange: _vm.onShowSizeChange,
        change: _vm.onPageChange
      },
      scopedSlots: _vm._u([{
        key: "buildOptionText",
        fn: function fn(props) {
          return [_c("span", [_vm._v(_vm._s(props.value) + "条/页")])];
        }
      }], null, true),
      model: {
        value: _vm.current,
        callback: function callback($$v) {
          _vm.current = $$v;
        },
        expression: "current"
      }
    })], 1);
  }), 1)], 1), _c("div", {
    staticClass: "verticalBar"
  }), _c("div", {
    staticClass: "employee-selector-block2"
  }, [_c("div", {
    staticClass: "employee-selector-title"
  }, [_vm._v("已经选择人员 " + _vm._s(_vm.selectedListTemp.length) + "人(每次签署的员工最多100人)")]), _c("div", {
    staticClass: "employee-selector"
  }, _vm._l(_vm.selectedListTemp, function (item, i) {
    return _c("div", {
      key: i,
      staticClass: "employee-selector-item"
    }, [_c("div", [_c("img", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "margin-right": "10px"
      },
      attrs: {
        src: require("@/assets/icons/person1.png")
      }
    }), _vm._v(" " + _vm._s(item.name) + " ")]), _c("img", {
      staticStyle: {
        width: "20px",
        height: "20px"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(item, i);
        }
      }
    })]);
  }), 0)])])])], 2), _c("a-modal", {
    attrs: {
      title: "适用范围-粘贴选择",
      width: 850,
      visible: _vm.copyVisible
    },
    on: {
      cancel: function cancel($event) {
        _vm.copyVisible = false;
      },
      ok: _vm.copyOk
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      width: "65%"
    }
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      height: "125px",
      background: "#faf9f9",
      "border-radius": "4px",
      padding: "14px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-weight": "400",
      color: "#252525line-height:18px",
      "font-size": "16px"
    }
  }, [_vm._v("温馨提示")]), _c("div", {
    staticStyle: {
      "font-weight": "400",
      color: "#999999",
      "line-height": "18px",
      "font-size": "12px",
      "margin-top": "8px"
    }
  }, [_vm._v(" 1、在文本框内输入或批量粘贴企业在职员工身份证号，便可识别添加。 ")]), _c("div", {
    staticStyle: {
      "font-weight": "400",
      color: "#999999",
      "line-height": "18px",
      "font-size": "12px",
      "margin-top": "8px"
    }
  }, [_vm._v(" 2、非企业员工、已离职员工无法识别。 ")]), _c("div", {
    staticStyle: {
      "font-weight": "400",
      color: "#999999",
      "line-height": "18px",
      "font-size": "12px",
      "margin-top": "8px"
    }
  }, [_vm._v(" 3、支持以换行、回车、空格、逗号等符号隔开的身份证号识别 ")])]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("a-textarea", {
    attrs: {
      placeholder: "请输入或粘贴员工身份证号",
      rows: 15
    },
    model: {
      value: _vm.textContent,
      callback: function callback($$v) {
        _vm.textContent = $$v;
      },
      expression: "textContent"
    }
  })], 1), _c("div", {
    staticStyle: {
      "padding-top": "12px",
      display: "flex",
      "justify-content": "flex-end"
    }
  }, [_vm.errorState ? _c("div", {
    staticStyle: {
      color: "#ff0000",
      "margin-right": "130px"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "16px",
      "margin-right": "8px"
    },
    attrs: {
      type: "exclamation-circle"
    }
  }), _vm._v("文本框内存在无法识别的身份证号 ")], 1) : _vm._e(), _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: _vm.reset
    }
  }, [_vm._v("重置")]), _c("a-button", {
    attrs: {
      disabled: _vm.textContent === "" || _vm.errorState
    },
    on: {
      click: _vm.getEmpListByIdCards
    }
  }, [_vm._v("识别")])], 1)]), _c("a-divider", {
    staticStyle: {
      height: "480px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("div", {
    staticStyle: {
      width: "35%"
    }
  }, [_c("div", [_vm._v(" 已选择的员工 "), _c("span", {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.chosenList.length) + "人")])]), _c("div", {
    staticStyle: {
      height: "450px",
      overflow: "scroll"
    }
  }, _vm._l(_vm.chosenList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        padding: "8px",
        display: "flex",
        "justify-content": "space-between"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " "), _c("img", {
      staticStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteCopyItem(item);
        }
      }
    })]);
  }), 0)])], 1)]), _c("a-modal", {
    attrs: {
      title: "适用范围-高级筛选",
      width: 1000,
      visible: _vm.seniorVisible
    },
    on: {
      cancel: _vm.seniorCancel,
      ok: _vm.seniorOk
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      width: "35%"
    }
  }, [_c("a-form-model", {
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "筛选条件"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "姓名/手机号"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "员工标签"
    }
  }, [_c("a-select", {
    attrs: {
      mode: "multiple",
      "option-label-prop": "label",
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.tag,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tag", $$v);
      },
      expression: "form.tag"
    }
  }, _vm._l(_vm.labelList, function (item) {
    return _c("a-select-option", {
      key: item.tagName,
      attrs: {
        value: item.tagName,
        label: item.tagName
      }
    }, [_vm._v(_vm._s(item.tagName))]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "工作性质"
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.postType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "postType", $$v);
      },
      expression: "form.postType"
    }
  }, _vm._l(_vm.postList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.key
      }
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "入职日期"
    }
  }, [_c("a-range-picker", {
    on: {
      change: _vm.rangeChange
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "是否首次签署"
    }
  }, [_c("a-select", {
    attrs: {
      allowClear: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.signingStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "signingStatus", $$v);
      },
      expression: "form.signingStatus"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "0"
    }
  }, [_vm._v("首次签署")]), _c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("非首次签署")])], 1)], 1)], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _c("a-button", {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.getSeniorListCheck
    }
  }, [_vm._v("查询")])], 1)], 1), _c("a-divider", {
    staticStyle: {
      height: "550px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("div", {
    staticStyle: {
      width: "40%"
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.dataList,
      pagination: _vm.pagination,
      scroll: {
        x: 500
      },
      "row-key": "empId",
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    }
  })], 1), _c("a-divider", {
    staticStyle: {
      height: "550px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("div", {
    staticStyle: {
      width: "25%"
    }
  }, [_c("div", [_vm._v(" 已选择的员工 "), _c("span", {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.seniorList.length) + "人")])]), _c("div", {
    staticStyle: {
      height: "550px",
      overflow: "scroll"
    }
  }, _vm._l(_vm.seniorList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        padding: "8px",
        display: "flex",
        "justify-content": "space-between"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " "), _c("img", {
      staticStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteSeniorItem(item);
        }
      }
    })]);
  }), 0)])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };