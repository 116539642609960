var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "notice-editor-wrapper"
  }, [_c("a-row", {
    staticClass: "topline",
    attrs: {
      type: "flex"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  }), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goback
    }
  }, [_vm._v("返回")]), _c("h3", [_vm._v("详情")]), _c("a-icon", {
    attrs: {
      type: "add"
    }
  })], 1), _c("div", {
    staticClass: "ql-editor-content"
  }, [_c("div", {
    staticClass: "preview-new-box"
  }, [_c("div", {
    staticClass: "notice-content"
  }, [_c("div", {
    staticClass: "notice-company-title"
  }, [_vm._v(_vm._s(_vm.currentNew.title))]), _c("div", {
    staticClass: "notice-company-date"
  }, [_vm._v("发布时间：" + _vm._s(_vm._f("formatDateAll")(_vm.currentNew.gmtCreate)))]), _c("div", {
    staticClass: "notice-company-content",
    domProps: {
      innerHTML: _vm._s(_vm.currentNew.contentHasStyle)
    }
  })])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };