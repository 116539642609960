import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import EditGongzi from "./editGongzi.vue";
import EditTongji from "./editTongji.vue";
import EditChuangjian from "./editChuangjiang.vue";
import { mapState } from "vuex";
import { decimalPlacesOptions } from "./funcs";
import { isContainByString } from "@/utils/index";
export default {
  name: "",
  components: {
    EditGongzi: EditGongzi,
    EditTongji: EditTongji,
    EditChuangjian: EditChuangjian
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      currentStep: 0,
      createVisible: false,
      name: "",
      decimalPlaces: 4,
      decimalPlacesOptions: decimalPlacesOptions,
      wageItemLst: [],
      statItemLst: [],
      isEditing: false,
      templateItems: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    isAdmin: function isAdmin() {
      return isContainByString("contractAdmin");
    },
    companyId: function companyId() {
      return this.isAdmin ? "0" : this.hr.companyId;
    },
    id: function id() {
      return this.$route.query.id;
    }
  }),
  watch: {
    companyId: {
      handler: function handler(newVal) {
        if (newVal && !this.id) {
          this.onCreate();
        }
      },
      immediate: true
    },
    id: function id(newVal) {
      // 创建后更新当前路由
      if (newVal) {
        this.initConfig();
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    // 编辑模式下
    if (this.id) {
      this.initConfig();
    }
  },
  methods: {
    loadTemplateDetail: function loadTemplateDetail() {
      var _this = this;
      this.$request({
        url: "/salary/api/v1/salaryTemplates/selectOpenSortByTemplateId?salaryTemplateId=".concat(this.id)
      }).then(function (res) {
        _this.templateItems = res.data;
      }).catch(function (err) {
        console.log(err);
      });
    },
    onItemDelete: function onItemDelete(tab, id) {
      if (tab === "gongzi") {
        this.$refs.gongzi.onOpenChange({
          target: {
            checked: false
          }
        }, id);
      } else if (tab === "tongji") {
        this.$refs.tongji.onOpenChange({
          target: {
            checked: false
          }
        }, id);
      }
    },
    onSave: function onSave(lst) {
      var _this2 = this;
      if (!this.name) {
        this.$message.warning("模板名称未填写");
        return false;
      }
      var sortedLst = lst.map(function (item, idx) {
        var _ref;
        return _ref = {}, _defineProperty(_ref, "".concat(item.statisticalFormula || item.statisticalFormula === null ? "templateCovarianceItemId" : "wageItemId"), item.id), _defineProperty(_ref, "sort", idx), _ref;
      });
      this.$request({
        url: "/salary/api/v1/salaryTemplates/configurationSalaryTemplate",
        method: "put",
        data: {
          id: this.id,
          companyId: this.companyId,
          name: this.name,
          decimalPlaces: this.decimalPlaces,
          issue: false,
          openWageItemListVOList: sortedLst.filter(function (item) {
            return item.wageItemId;
          }),
          openCovarianceItemListVOList: sortedLst.filter(function (item) {
            return item.templateCovarianceItemId;
          })
        }
      }).then(function (res) {
        _this2.$message.success("模板配置成功");
        _this2.$router.back();
      }).catch(function (err) {
        console.log(err);
      });
    },
    onStepChange: function onStepChange(nextStep) {
      this.currentStep = nextStep;
      if (nextStep === 1) {
        this.$refs.tongji.loadData();
      } else if (nextStep === 2) {
        this.$refs.chuangjian.initData();
      }
    },
    onStepCancel: function onStepCancel() {
      this.$router.back();
    },
    initConfig: function initConfig() {
      var temp = localStorage.getItem("xinzimubanTemp");
      if (temp) {
        temp = JSON.parse(temp);
        this.name = temp.name;
        this.decimalPlaces = temp.decimalPlaces;
        this.loadTemplateDetail();
      }
    },
    onCreate: function onCreate() {
      this.isEditing = true;
      this.name = "";
      this.createVisible = true;
    },
    doCreate: function doCreate() {
      var _this3 = this;
      if (!this.name) {
        this.$message.warning("内容未填写");
        return false;
      }
      this.$request({
        url: "/salary/api/v1/salaryTemplates",
        method: "post",
        data: {
          companyId: this.companyId,
          name: this.name,
          decimalPlaces: this.decimalPlaces,
          issue: false
        }
      }).then(function (res) {
        _this3.createVisible = false;
        localStorage.setItem("xinzimubanTemp", JSON.stringify(res.data));
        _this3.$router.replace("".concat(_this3.$route.path, "?id=").concat(res.data.id));
      }).catch(function (err) {
        console.log(err);
      });
    },
    doCancel: function doCancel() {
      this.createVisible = false;
      this.$router.back();
    }
  }
};