var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.reloaded ? _c("div", {
    staticClass: "formFontStyle",
    class: "".concat(_vm.editable ? "editable" : "editable-no")
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      layout: _vm.formLayout,
      labelCol: _vm.formItemLayout.labelCol,
      wrapperCol: _vm.formItemLayout.wrapperCol
    }
  }, [_vm.isReward && _vm.formFields ? _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_vm.sortedKeys ? [_vm._l(_vm.sortedKeys, function (key) {
    return [!(_vm.formFields[key].isHidden === true) ? _c("div", {
      key: key
    }, [_c("a-col", {
      attrs: {
        lg: 12,
        md: 24
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: _vm.formFields[key],
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator(key)
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, _vm.formFields[key], _vm.dataSource, _vm.dynamicData);
        },
        inputKeydown: function inputKeydown($event) {
          return _vm.onInputKeydown($event, _vm.formFields[key]);
        }
      }
    })], 1)], 1) : _vm._e()];
  })] : _vm._l(_vm.formFields, function (item) {
    return _c("div", {
      key: item.model
    }, [_c("a-col", {
      attrs: {
        lg: 12,
        md: 24
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: item,
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator(item.model)
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, item, _vm.dataSource, _vm.dynamicData);
        },
        inputKeydown: function inputKeydown($event) {
          return _vm.onInputKeydown($event, item);
        }
      }
    })], 1)], 1);
  })], 2) : _vm._e()], 1), _vm.showBtns ? _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editable,
      expression: "editable"
    }],
    staticClass: "DynamicForm"
  }, [_c("a-space", [_vm.remove ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: _vm.onRemove
    }
  }, [_vm._v("删 除")]) : _vm._e(), _c("a-button", {
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取 消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保 存")])], 1)], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };