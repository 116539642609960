var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "add-payslip-forth"
  }, [_c("div", {
    staticClass: "content-con"
  }, [_c("a-result", {
    attrs: {
      status: "success",
      title: "工资条发送成功"
    },
    scopedSlots: _vm._u([{
      key: "extra",
      fn: function fn() {
        return [_c("a-row", {
          attrs: {
            type: "flex"
          }
        }, [_c("a-col", {
          staticClass: "col-left"
        }, [_c("xq-icon", {
          staticClass: "primary_color w32",
          staticStyle: {
            display: "block",
            "font-size": "30px"
          },
          attrs: {
            type: "icon-wancheng1"
          }
        }), _c("a-divider", {
          staticClass: "img-v",
          attrs: {
            type: "vertical"
          }
        }), _c("xq-icon", {
          staticClass: "primary_color w32",
          staticStyle: {
            display: "block",
            "font-size": "30px"
          },
          attrs: {
            type: "icon-wancheng1"
          }
        })], 1), _c("a-col", {
          staticClass: "full",
          staticStyle: {
            "text-align": "left"
          }
        }, [_c("a-row", {
          staticClass: "content-con-text mg_b24"
        }, [_c("a-col", {
          staticClass: "mg_b12 round"
        }, [_c("strong", [_vm._v("发放工资条")])]), _c("a-col", {
          staticClass: "sub_tit"
        }, [_vm._v(" 员工可以直接打开微信服务号-服务平台,点击我的工资条进行查看 ")])], 1), _c("a-row", {
          staticClass: "content-con-text"
        }, [_c("a-col", {
          staticClass: "mg_b12 round"
        }, [_c("strong", [_vm._v(" 发送完成 "), _c("span", {
          staticClass: "primary"
        }, [_vm._v(" 100%")])])]), _c("a-col", {
          staticClass: "sub_tit"
        }, [_vm._v(" 发放共 "), _c("span", {
          staticClass: "primary mg_l4 mg_r4"
        }, [_vm._v(" " + _vm._s(_vm.$route.query.sendCount) + " ")]), _vm._v(" 条微信通知 "), _c("span", {
          staticClass: "primary mg_l4 mg_r4"
        }, [_vm._v("0")]), _vm._v(" 条短信通知 ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goDetail
    }
  }, [_vm._v("查看发放详情 ")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };