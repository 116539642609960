import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
var certificate = ["中国护照", "居民身份证", "港澳居民来往内地通行证", "台湾居民来往大陆通行证", "外国护照", "中华人民共和国港澳居民居住证", "中华人民共和国台湾居民居住证", "外国人永久居留身份证", "中华人民共和国外国人工作许可证（A类）", "中华人民共和国外国人工作许可证（B类）", "中华人民共和国外国人工作许可证（C类）", "其他个人证件"];
var columns = [{
  title: "姓名",
  dataIndex: "empName",
  key: "empName",
  fixed: "left",
  width: 170,
  colDefault: true,
  isEdit: false,
  scopedSlots: {
    customRender: "empName"
  }
}, {
  title: "证件类型",
  dataIndex: "certificateType",
  key: "certificateType",
  colDefault: true,
  isEdit: false,
  scopedSlots: {
    customRender: "certificateType"
  },
  width: 120
}, {
  title: "证件号码",
  dataIndex: "idCard",
  key: "idCard",
  colDefault: true,
  isEdit: false,
  width: 150
}, {
  title: "全年一次性奖金额",
  dataIndex: "annualOneOffBonusAmount",
  key: "annualOneOffBonusAmount",
  colDefault: true,
  width: 120
}, {
  title: "免税收入",
  dataIndex: "noTaxIncome	",
  key: "noTaxIncome	",
  colDefault: true,
  scopedSlots: {
    customRender: "gender"
  },
  width: 100
}, {
  title: "其他",
  dataIndex: "other",
  key: "other",
  colDefault: true,
  width: 100
}, {
  title: "准予扣除的捐赠额",
  dataIndex: "allowableDeductionDonations",
  key: "allowableDeductionDonations",
  colDefault: true,
  width: 120
}, {
  title: "减免税额",
  dataIndex: "taxDeduction",
  key: "taxDeduction",
  colDefault: true,
  width: 100
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark",
  colDefault: true,
  width: 100
}];
import { getBonus } from "@/api/tax/tax";
import moment from "moment";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
export default {
  mixins: [mixin],
  data: function data() {
    return {
      abolitionImg: require("@/assets/home/lizhi.png"),
      certificate: certificate,
      inputName: null,
      loading: false,
      empStatus: undefined,
      selectedRowKeys: [],
      selectRowKey: [],
      dataList: [],
      columns: columns,
      empIdList: [],
      groupList: [],
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      search1: "",
      search2: undefined
    };
  },
  created: function created() {
    this.getBonusList();
  },
  beforeDestory: function beforeDestory() {},
  methods: {
    handleChange: function handleChange(e) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.empStatus = e;
      this.getBonusList();
    },
    onSelectChange: function onSelectChange(selectedRowKeys, e) {
      var _this = this;
      this.empIdList = [];
      this.selectedRowKeys = selectedRowKeys;
      e.forEach(function (item, index) {
        _this.empIdList.push(item.empId);
      });
    },
    download: function download(i) {
      var token = this.$store.state.auth.token;
      if (i === 2) {
        this.exportData = {
          companyId: this.companyId,
          empIdList: [],
          status: this.empStatus ? this.empStatus : undefined,
          nameOrPhoneOrIdCard: this.inputName ? this.inputName : undefined
        };
      } else {
        this.exportData = {
          companyId: this.companyId,
          empIdList: this.empIdList
        };
      }
      this.url = "/salary/api/v1/specialAdditionalDetails/exportBonusDeclaration";
      this.excelName = "".concat(this.$store.state.hr.companyName, "_") + "全年一次性奖金申报导出详情" + ".xlsx";
      this.postDownload();
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getBonusList();
    },
    getBonusList: function getBonusList() {
      var _this2 = this;
      this.loading = true;
      getBonus({
        status: this.empStatus,
        name: this.inputName,
        companyId: this.companyId,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }).then(function (res) {
        _this2.selectedRowKeys = [];
        _this2.loading = false;
        _this2.dataList = res.data.entities || [];
        _this2.pagination.total = res.data && res.data.entityCount;
      });
    },
    inputChange: function inputChange(e) {
      this.inputName = e;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getBonusList();
    }
  }
};