import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "positionBox page_set page-miH page-bgBack"
  }, [_c("a-layout", {
    staticClass: "mt16 bgc-white"
  }, [_c("a-layout-sider", [_c("a-row", {
    staticClass: "pos-common-title"
  }, [_vm._v(" 岗位类型 "), _c("a-icon", {
    attrs: {
      type: "plus"
    },
    on: {
      click: _vm.addType
    }
  })], 1), _c("a-menu", {
    attrs: {
      mode: "vertical"
    },
    model: {
      value: _vm.current,
      callback: function callback($$v) {
        _vm.current = $$v;
      },
      expression: "current"
    }
  }, _vm._l(_vm.menuData, function (item) {
    return _c("a-menu-item", {
      key: item.id,
      staticClass: "bbE8E8E8",
      on: {
        click: function click($event) {
          return _vm.getSelectId(item);
        }
      }
    }, [_c("a-tooltip", {
      attrs: {
        placement: "rightTop"
      }
    }, [item.name.length > 8 ? _c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.name) + "（" + _vm._s(item.childCount) + "）")])]) : _vm._e(), _c("span", {
      staticClass: "ove",
      staticStyle: {
        width: "150px",
        display: "inline-block"
      }
    }, [_vm._v(" " + _vm._s(item.name) + "（" + _vm._s(item.childCount) + "） ")])], 2), _c("a-icon", {
      attrs: {
        type: "setting"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.editType(item);
        }
      }
    })], 1);
  }), 1)], 1), _c("a-layout-content", {
    staticClass: "pl24 pr24"
  }, [_c("a-row", {
    staticClass: "pos-header"
  }, [_c("a-col", [_c("h2", [_vm._v(_vm._s(_vm.name22))])]), _c("a-col", {
    staticStyle: {
      overflow: "hidden",
      "text-overflow": "ellipsis",
      "white-space": "nowrap"
    }
  }, [_vm._v(_vm._s(_vm.desc22))])], 1), _c("a-row", {
    staticClass: "pt24 h84 bbE8E8E8"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "输入岗位名称"
    },
    on: {
      search: _vm.onSearch
    }
  }), _c("a-button", {
    staticClass: "fr",
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: _vm.addPos
    }
  }, [_vm._v(" 新建岗位 ")])], 1), _c("a-table", {
    attrs: {
      "row-key": "id",
      columns: _vm.columns,
      pagination: _vm.pagination,
      "data-source": _vm.tableData
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "empCount",
      fn: function fn(text, record) {
        return [_c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.showEmp(record);
            }
          }
        }, [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.edit(record);
            }
          }
        }, [_vm._v("编辑")])];
      }
    }])
  }), _c("a-modal", {
    attrs: {
      title: "".concat(_vm.gangNam) + "岗位",
      width: "800px"
    },
    on: {
      ok: _vm.handleEmpOk
    },
    model: {
      value: _vm.empVisible,
      callback: function callback($$v) {
        _vm.empVisible = $$v;
      },
      expression: "empVisible"
    }
  }, [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px",
      "margin-left": "10px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "姓名或手机号"
    },
    on: {
      search: _vm.unitNameChange
    },
    model: {
      value: _vm.unitSearchForm.keyWord,
      callback: function callback($$v) {
        _vm.$set(_vm.unitSearchForm, "keyWord", $$v);
      },
      expression: "unitSearchForm.keyWord"
    }
  }), _c("a-table", {
    staticStyle: {
      "margin-top": "50px"
    },
    attrs: {
      "data-source": _vm.empGroup,
      columns: _vm.empList,
      pagination: _vm.pagination2
    },
    on: {
      change: _vm.handlePageChange
    }
  })], 1)], 1)], 1), _c("a-drawer", {
    attrs: {
      title: _vm.titleType,
      width: 520,
      visible: _vm.posTypeVisible,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: function close($event) {
        return _vm.onClose("posTypeVisible");
      }
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.posTypeForm,
      layout: "vertical"
    }
  }, [_c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入名称"
        }]
      }],
      expression: "[\n                'name',\n                {\n                  rules: [{ required: true, message: '请输入名称' }]\n                }\n              ]"
    }],
    attrs: {
      placeholder: "请输入名称"
    }
  })], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "说明"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["desc"],
      expression: "['desc']"
    }],
    attrs: {
      rows: 4,
      placeholder: "请输入说明"
    }
  })], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["id"],
      expression: "['id']"
    }],
    attrs: {
      type: "hidden"
    }
  })], 1)], 1)], 1)], 1), _c("div", {
    staticClass: "drawer-footer-box"
  }, [_vm.titleType == "编辑岗位类型" ? _c("a-button", {
    staticClass: "mr12",
    on: {
      click: _vm.delType
    }
  }, [_vm._v("删除")]) : _vm._e(), _c("a-button", {
    staticClass: "mr12",
    on: {
      click: function click($event) {
        return _vm.onClose("posTypeVisible");
      }
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addTypeSubmit
    }
  }, [_vm._v("确定")])], 1)], 1), _c("a-drawer", {
    attrs: {
      title: _vm.title,
      width: 520,
      visible: _vm.posVisible,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: function close($event) {
        return _vm.onClose("posVisible");
      }
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.posForm,
      layout: "vertical"
    }
  }, [_c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入名称"
        }]
      }],
      expression: "[\n                'name',\n                {\n                  rules: [{ required: true, message: '请输入名称' }]\n                }\n              ]"
    }],
    attrs: {
      placeholder: "请输入名称"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "岗位编号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["code"],
      expression: "['code']"
    }],
    attrs: {
      placeholder: "请输入"
    }
  })], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "岗位类型"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["parentId", {
        rules: [{
          required: true,
          message: "请选择岗位类型"
        }]
      }],
      expression: "[\n                'parentId',\n                {\n                  rules: [{ required: true, message: '请选择岗位类型' }]\n                }\n              ]"
    }],
    attrs: {
      placeholder: "请选择岗位类型"
    }
  }, _vm._l(_vm.menuData, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)], 1), _vm.menuData == null || _vm.menuData.length === 0 ? _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(" 没有岗位类型？ "), _c("a-button", {
    staticClass: "mt24",
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.addType
    }
  }, [_vm._v(" 去添加 ")])], 1) : _vm._e()], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "岗位说明"
    }
  }, [_c("a-textarea", {
    attrs: {
      rows: 4,
      placeholder: "请输入岗位说明",
      "max-length": 50
    },
    model: {
      value: _vm.desc,
      callback: function callback($$v) {
        _vm.desc = $$v;
      },
      expression: "desc"
    }
  }), _c("a-row", {
    staticClass: "ta-right textarea-tip"
  }, [_vm._v(" " + _vm._s(_vm.desc ? _vm.desc.length : 0) + "/50字 ")])], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["id"],
      expression: "['id']"
    }],
    attrs: {
      type: "hidden"
    }
  })], 1)], 1)], 1)], 1), _c("div", {
    staticClass: "drawer-footer-box"
  }, [_vm.title == "编辑岗位" ? _c("a-button", {
    staticClass: "mr12",
    on: {
      click: _vm.delPos
    }
  }, [_vm._v(" 删除 ")]) : _vm._e(), _c("a-button", {
    staticClass: "mr12",
    on: {
      click: function click($event) {
        return _vm.onClose("posVisible");
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addPosSubmit
    }
  }, [_vm._v("确定")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };