import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import DBNormal from "./index.vue";
import DBJF from "./jf.vue";
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {
    DBNormal: DBNormal,
    DBJF: DBJF
  },
  mixins: [],
  props: {},
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapGetters(["isJF"])),
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};