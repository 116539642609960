var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper page-miH page-bgBack p24"
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "240px"
    },
    attrs: {
      allowClear: "",
      placeholder: "输入批次名称"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.searchName,
      callback: function callback($$v) {
        _vm.searchName = $$v;
      },
      expression: "searchName"
    }
  }), _c("a-month-picker", {
    staticClass: "mr12",
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择月份",
      valueFormat: "YYYY-MM"
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.time,
      callback: function callback($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  }), _c("a-tree-select", {
    staticStyle: {
      width: "270px"
    },
    attrs: {
      "tree-data": _vm.dataList,
      "show-search": "",
      allowClear: true,
      placeholder: "企业/税局",
      treeNodeFilterProp: "title",
      "dropdown-style": {
        maxHeight: "400px",
        overflow: "auto"
      },
      replaceFields: _vm.replaceFields,
      treeDefaultExpandAll: true,
      dropdownClassName: "taxCalculate-select-action-popover"
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.authId,
      callback: function callback($$v) {
        _vm.authId = $$v;
      },
      expression: "authId"
    }
  })], 1), _c("a-col", [_vm.$hasPermission("zhinengsuanshui:geshuijisuan:create") ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onAdd
    }
  }, [_vm._v("创建算税批次")]) : _vm._e()], 1)], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(_vm._s(col.customTitle))])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "gmtCreate" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text ? _vm.moment(text).format("YYYY-MM-DD HH:mm") : "--"))]) : col.dataIndex === "status" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(_vm.getCalculateTaxStatus(text)))]) : col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("a", {
            on: {
              click: function click($event) {
                return _vm.onInfo(record);
              }
            }
          }, [_vm._v("详情")]), _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), record.status !== 2 && _vm.$hasPermission("zhinengsuanshui:geshuijisuan:del") ? _c("a-popconfirm", {
            attrs: {
              title: "\u786E\u8BA4\u8981\u5220\u9664\u5417\uFF1F",
              "ok-text": "确认",
              "cancel-text": "取消"
            },
            on: {
              confirm: function confirm($event) {
                return _vm.onClose(record.id);
              }
            }
          }, [_c("a", [_vm._v("删除")])]) : _vm._e(), record.status === 2 && _vm.$hasPermission("zhinengsuanshui:geshuijisuan:del") ? _c("a-tooltip", [_c("template", {
            slot: "title"
          }, [_vm._v(" 算税中不可删除 ")]), _c("a", [_vm._v("删除")])], 2) : _vm._e()], 1) : col.dataIndex === "period" ? _c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(text.substring(0, 4)) + "-" + _vm._s(text.substring(4, 6)) + " ")]) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"],
      "page-size": _vm.pageSize
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1), _c("select-way", {
    attrs: {
      visible: _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      changeSW: _vm.changeSW
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };