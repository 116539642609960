import request from "@/utils/request";
import axios from "axios";
import { getToken } from "xqjr-module-auth/utils";

// 供应商
export function gysListReq(params) {
  return request({
    url: "/hr/api/v1/gysInfos/selectPageAllField",
    method: "get",
    params: params
  });
}
export function gysCreateReq(data) {
  return request({
    url: "/hr/api/v1/gysInfos/saveGsyInfo",
    method: "post",
    data: data
  });
}
export function gysUpdateReq(data) {
  return request({
    url: "/hr/api/v1/gysInfos/updateGsyInfo",
    method: "put",
    data: data
  });
}
export function gysDeleteReq(gid) {
  return request({
    url: "/hr/api/v1/gysInfos/".concat(gid),
    method: "delete"
  });
}
export function gysDetailReq(gysId) {
  return request({
    url: "/hr/api/v1/gysInfos/findGsyById/".concat(gysId),
    method: "get"
  });
}
export function gysExportReq(params) {
  return axios({
    url: "/hr/api/v1/gysInfos/export",
    method: "get",
    params: params,
    responseType: "blob",
    headers: {
      token: getToken(),
      "Content-Type": "application/json"
    }
  });
}

// 分组
export function groupListReq(params) {
  return request({
    url: "/hr/api/v1/gysGroups/selectGroupTree",
    method: "get",
    params: params
  });
}
export function groupCreateReq(data) {
  return request({
    url: "/hr/api/v1/gysGroups",
    method: "post",
    data: data
  });
}
export function groupUpdateReq(data) {
  return request({
    url: "/hr/api/v1/gysGroups",
    method: "put",
    data: data
  });
}
export function groupDeleteReq(gid) {
  return request({
    url: "/hr/api/v1/gysGroups/".concat(gid),
    method: "delete"
  });
}

// 字段设置
export function findFormGysReq(params) {
  return request({
    url: "/hr/api/v1/formGroups/findForm/gys",
    method: "get",
    params: params
  });
}
export function findFormGysDeleteReq(fid) {
  return request({
    url: "/hr/api/v1/formGroupFields/".concat(fid),
    method: "delete"
  });
}
export function findFormGysUpdateReq(data) {
  return request({
    url: "/hr/api/v1/formGroupFields",
    method: "put",
    data: data
  });
}
export function findFormGysCreateReq(data) {
  return request({
    url: "/hr/api/v1/formGroupFields/saveSiteField",
    method: "post",
    data: data
  });
}