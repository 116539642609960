import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pConfig page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 配置 ")]), _c("a-tabs", {
    staticClass: "tabs",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "tab-bar-style": {
        width: "214px"
      },
      "tab-position": "left",
      "default-active-key": "1"
    },
    on: {
      change: _vm.tabChange
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "权限配置"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    on: {
      change: _vm.selectChange
    },
    model: {
      value: _vm.roleId,
      callback: function callback($$v) {
        _vm.roleId = $$v;
      },
      expression: "roleId"
    }
  }, _vm._l(_vm.roleOptions, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.savePermission
    }
  }, [_vm._v("保存")])], 1), _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("a-tabs", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      type: "card",
      size: "small"
    },
    on: {
      change: _vm.onSubjectChange
    }
  }, [_c("a-tab-pane", {
    key: "company",
    attrs: {
      tab: "操作端"
    }
  }, [_c("div", {
    staticClass: "titleBox"
  }, [_c("a-checkbox", {
    staticStyle: {
      margin: "0 12px 0 12px"
    },
    on: {
      change: _vm.checkAll
    },
    model: {
      value: _vm.checkAllState,
      callback: function callback($$v) {
        _vm.checkAllState = $$v;
      },
      expression: "checkAllState"
    }
  }), _vm._v(" 员工端权限")], 1), _vm._l(_vm.treeData, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "childBox"
    }, [_c("div", {
      staticStyle: {
        width: "200px",
        height: "18px",
        "border-right": "1px solid #e8e8e8"
      }
    }, [_c("a-checkbox", {
      staticStyle: {
        margin: "0 12px 0 12px"
      },
      attrs: {
        disabled: !item.children || item.children.length === 0
      },
      on: {
        change: function change($event) {
          return _vm.checkGroup(item);
        }
      },
      model: {
        value: item.checked,
        callback: function callback($$v) {
          _vm.$set(item, "checked", $$v);
        },
        expression: "item.checked"
      }
    }), _vm._v(" " + _vm._s(item.categoryName) + " ")], 1), _c("div", {
      staticStyle: {
        flex: "1",
        display: "flex",
        "flex-wrap": "wrap"
      }
    }, _vm._l(item.children, function (item2) {
      return _c("div", {
        key: item2.id
      }, [_c("a-checkbox", {
        staticStyle: {
          margin: "0 12px 0 24px"
        },
        on: {
          change: function change($event) {
            return _vm.checkItem(item2);
          }
        },
        model: {
          value: item2.checked,
          callback: function callback($$v) {
            _vm.$set(item2, "checked", $$v);
          },
          expression: "item2.checked"
        }
      }), _vm._v(" " + _vm._s(item2.name) + " ")], 1);
    }), 0)]);
  })], 2), _c("a-tab-pane", {
    key: "hr",
    attrs: {
      tab: "管理端"
    }
  }, [_c("div", {
    staticClass: "titleBox"
  }, [_c("a-checkbox", {
    staticStyle: {
      margin: "0 12px 0 12px"
    },
    on: {
      change: _vm.checkAll
    },
    model: {
      value: _vm.checkAllState,
      callback: function callback($$v) {
        _vm.checkAllState = $$v;
      },
      expression: "checkAllState"
    }
  }), _vm._v(" 管理端权限")], 1), _vm._l(_vm.treeData, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "childBox"
    }, [_c("div", {
      staticStyle: {
        width: "200px",
        height: "18px",
        "border-right": "1px solid #e8e8e8"
      }
    }, [_c("a-checkbox", {
      staticStyle: {
        margin: "0 12px 0 12px"
      },
      on: {
        change: function change($event) {
          return _vm.checkGroup(item);
        }
      },
      model: {
        value: item.checked,
        callback: function callback($$v) {
          _vm.$set(item, "checked", $$v);
        },
        expression: "item.checked"
      }
    }), _vm._v(" " + _vm._s(item.categoryName) + " ")], 1), _c("div", {
      staticStyle: {
        flex: "1",
        display: "flex",
        "flex-wrap": "wrap"
      }
    }, [_vm._l(item.children, function (item2) {
      return [_vm.showBox(item2) ? _c("div", {
        key: item2.id
      }, [_c("a-checkbox", {
        staticStyle: {
          margin: "0 12px 0 24px"
        },
        on: {
          change: function change($event) {
            return _vm.checkItem(item2);
          }
        },
        model: {
          value: item2.checked,
          callback: function callback($$v) {
            _vm.$set(item2, "checked", $$v);
          },
          expression: "item2.checked"
        }
      }), _vm._v(" " + _vm._s(item2.name) + " ")], 1) : _vm._e()];
    })], 2)]);
  })], 2), _c("a-tab-pane", {
    key: "addedServices",
    attrs: {
      tab: "拓展应用"
    }
  }, [_vm.subjectType === "addedServices" && _vm.treeData.length ? _c("a-table", {
    attrs: {
      rowKey: "id",
      columns: _vm.columns,
      "data-source": _vm.treeData,
      pagination: false,
      childrenColumnName: "childList"
    },
    scopedSlots: _vm._u([{
      key: "categoryName",
      fn: function fn(text, record) {
        return _c("div", {
          class: !record.serviceIcon ? "noIcon" : null,
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "margin-left": "35px",
            "margin-bottom": "18px"
          }
        }, [record.serviceIcon && record.serviceIcon.length ? _c("div", [_c("img", {
          staticStyle: {
            width: "30px",
            height: "30px",
            "margin-left": "12px",
            "margin-right": "12px"
          },
          attrs: {
            src: record.serviceIcon[0]
          }
        })]) : _vm._e(), _vm._v(" " + _vm._s(text) + " ")]);
      }
    }, {
      key: "permissionList",
      fn: function fn(text) {
        return _c("div", {
          staticStyle: {
            display: "flex"
          }
        }, [_vm._l(text, function (item, index) {
          return [_c("div", {
            key: "".concat(item.enName, "_").concat(index)
          }, [_c("a-checkbox", {
            staticStyle: {
              margin: "0 12px 0 24px"
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          }, [_vm._v(_vm._s(item.name))])], 1)];
        })], 2);
      }
    }], null, false, 2367646182)
  }) : _vm._e()], 1), _c("a-tab-pane", {
    key: "xcxAdmin",
    attrs: {
      tab: "小程序管理端"
    }
  }, [_vm.subjectType === "xcxAdmin" && _vm.treeData.length ? _c("a-table", {
    attrs: {
      rowKey: "id",
      columns: _vm.columns,
      "data-source": _vm.treeData,
      pagination: false,
      childrenColumnName: "childList"
    },
    scopedSlots: _vm._u([{
      key: "categoryName",
      fn: function fn(text) {
        return _c("div", {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "margin-left": "35px"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")]);
      }
    }, {
      key: "permissionList",
      fn: function fn(text) {
        return _c("div", {
          staticStyle: {
            display: "flex",
            "flex-wrap": "wrap"
          }
        }, [_vm._l(text, function (item, index) {
          return [_c("div", {
            key: item.enName + index
          }, [_c("a-checkbox", {
            staticStyle: {
              margin: "0 12px 0 24px"
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          }, [_vm._v(_vm._s(item.name))])], 1)];
        })], 2);
      }
    }], null, false, 3481104355)
  }) : _vm._e()], 1), _c("a-tab-pane", {
    key: "xcxJiafang",
    attrs: {
      tab: "小程序甲方端"
    }
  }, [_vm.subjectType === "xcxJiafang" && _vm.treeData.length ? _c("a-table", {
    attrs: {
      rowKey: "id",
      columns: _vm.columns,
      "data-source": _vm.treeData,
      pagination: false,
      childrenColumnName: "childList"
    },
    scopedSlots: _vm._u([{
      key: "categoryName",
      fn: function fn(text) {
        return _c("div", {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "margin-left": "35px"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")]);
      }
    }, {
      key: "permissionList",
      fn: function fn(text) {
        return _c("div", {
          staticStyle: {
            display: "flex",
            "flex-wrap": "wrap"
          }
        }, [_vm._l(text, function (item, index) {
          return [_c("div", {
            key: item.enName + index,
            staticStyle: {
              display: "flex"
            }
          }, [_c("a-checkbox", {
            staticStyle: {
              margin: "0 12px 0 24px"
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          }, [_vm._v(_vm._s(item.name))])], 1)];
        })], 2);
      }
    }], null, false, 2997724139)
  }) : _vm._e()], 1)], 1)], 1)], 1), _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "角色信息"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    on: {
      change: _vm.selectInfo
    },
    model: {
      value: _vm.roleId,
      callback: function callback($$v) {
        _vm.roleId = $$v;
      },
      expression: "roleId"
    }
  }, _vm._l(_vm.roleOptions, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-form-item", {
    attrs: {
      label: "角色名称",
      "label-align": "left"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请输入角色",
      "allow-clear": ""
    },
    model: {
      value: _vm.roleName,
      callback: function callback($$v) {
        _vm.roleName = $$v;
      },
      expression: "roleName"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "角色描述",
      "label-align": "left"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请输入描述",
      "allow-clear": ""
    },
    model: {
      value: _vm.roleIntro,
      callback: function callback($$v) {
        _vm.roleIntro = $$v;
      },
      expression: "roleIntro"
    }
  })], 1), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateRole
    }
  }, [_vm._v("更新角色设置")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };