var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white p24"
  }, [_c("div", {
    staticClass: "flex-center-between"
  }, [_c("a-space", {
    attrs: {
      size: 24
    }
  }, [_c("a-input-search", {
    attrs: {
      placeholder: "用工单位"
    },
    on: {
      change: _vm.onInputChange
    },
    model: {
      value: _vm.companyName,
      callback: function callback($$v) {
        _vm.companyName = $$v;
      },
      expression: "companyName"
    }
  }), _c("a-range-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      value: _vm.dateRange,
      placeholder: ["开始月份", "结束月份"],
      mode: ["month", "month"],
      format: "YYYY-MM"
    },
    on: {
      panelChange: _vm.onPanelChange
    }
  })], 1), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goImport
    }
  }, [_vm._v("服务费导入")])], 1), _c("a-table", {
    staticClass: "mt24",
    attrs: {
      dataSource: _vm.recordLst,
      columns: _vm.feeColumns,
      pagination: _vm.pagination,
      rowKey: "id"
    },
    on: {
      change: _vm.onTableChange
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };