import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "FeedBack",
  props: ["options", "visible"],
  data: function data() {
    return {
      copy: {},
      history: [],
      heightClass: "heightclass"
    };
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      this.$on("getHistory", function (id) {
        var _this = this;
        this.$request({
          url: "hr/api/v1/missionItemFeedbacks/selectFeedbackByItemId/" + id,
          method: "GET"
        }).then(function (_ref) {
          var data = _ref.data;
          if (data) {
            _this.history = data;
            console.log(_this.history);
          }
        });
      });
    });
  },
  computed: _objectSpread(_objectSpread({}, mapState(["admin"])), {}, {
    footer: function footer() {
      return this.admin.activeCompany && this.admin.activeCompany.includes("总");
    }
  }),
  watch: {
    visible: function visible(val) {
      if (val) {
        this.copy = JSON.parse(JSON.stringify(this.options));
      }
    }
  },
  methods: {
    handleChange: function handleChange(e) {
      this.options.status = e.target.value;
    },
    handleOk: function handleOk() {
      if (this.options.status === 0) {
        this.$message.success("请选择状态");
        return;
      }
      if (this.options.status === 2 && (!this.options.remake || this.options.remake.replace(/^\s*|\s*$/g, "").length === 0)) {
        // 异常状态，内容为必填
        this.$message.success("请填写反馈内容");
        return;
      }
      this.$emit("change", {
        visible: false,
        success: true,
        options: this.options
      });
    },
    handleCancel: function handleCancel() {
      this.$emit("change", {
        visible: false,
        success: false,
        options: this.copy
      });
    }
  }
};