import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
var regHznzi = /[\u4e00-\u9fa5|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/;
export function decodeColumns(columns) {
  return columns.map(function (item) {
    return {
      name: item.title,
      childList: item.children.map(function (li) {
        return {
          name: li.title,
          code: li.dataIndex,
          selected: li.selected
        };
      }).filter(function (item) {
        return item.dataIndex !== "columnsAction";
      })
    };
  });
}
export function selectAllColumns(idx, columns, checked) {
  var columnsTemp = JSON.parse(JSON.stringify(columns));
  columnsTemp[idx].selectAll = checked;
  columnsTemp[idx].children = columnsTemp[idx].children.map(function (item) {
    return _objectSpread(_objectSpread({}, item), {}, {
      selected: ["name", "idCard"].includes(item.dataIndex) ? true : checked
    });
  });
  return columnsTemp;
}
export function updateColumns(columns) {
  var columnsTemp = JSON.parse(JSON.stringify(columns));
  return {
    columnsFull: columnsTemp,
    columnsSelected: generateColumns(columnsTemp)
  };
}
export function selectedToggleColumns(subIdx, idx, columns) {
  var columnsTemp = JSON.parse(JSON.stringify(columns));
  columnsTemp[idx].children[subIdx] = _objectSpread(_objectSpread({}, columnsTemp[idx].children[subIdx]), {}, {
    selected: !columnsTemp[idx].children[subIdx].selected
  });
  columnsTemp[idx].selectAll = columnsTemp[idx].children.length === columnsTemp[idx].children.filter(function (item) {
    return item.selected;
  }).length;
  return columnsTemp;
}
export function buildColumns(list) {
  var res = [];
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    var children = [];
    var selectAll = true;
    var _loop = function _loop() {
      var subItem = item.childList[j];
      var subItemNew = {
        title: subItem.name,
        dataIndex: subItem.code,
        selected: subItem.selected,
        disabled: ["name", "idCard"].includes(subItem.code),
        ellipsis: true,
        width: 200
      };
      if (regHznzi.test(subItem.code)) {
        subItemNew["customRender"] = function (text, record) {
          return record.data[subItem.code];
        };
      }
      children.push(subItemNew);
      if (!subItem.selected) {
        selectAll = false;
      }
    };
    for (var j = 0; j < item.childList.length; j++) {
      _loop();
    }
    res.push({
      title: item.name,
      dataIndex: "group_".concat(i),
      children: children,
      selectAll: selectAll
    });
  }
  return {
    columnsFull: res,
    columnsSelected: generateColumns(res, true)
  };
}
function generateColumns(columns) {
  var isInit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var columnsNew = columns.map(function (item) {
    var children = item.children.filter(function (li) {
      return li.selected;
    });
    if (children.length > 0) {
      return _objectSpread(_objectSpread({}, item), {}, {
        children: children
      });
    } else {
      return false;
    }
  });
  var columnsNew2 = columnsNew.filter(function (item) {
    return item;
  });
  if (isInit) {
    columnsNew2.push({
      title: "操作",
      dataIndex: "columnsAction",
      fixed: "right",
      scopedSlots: {
        customRender: "columnsAction"
      }
    });
  }
  return columnsNew2;
}