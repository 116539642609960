import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";

// 合同概况
export function getContractCount(data) {
  return request({
    url: "/hr/api/v1/memoInfos/getContractCount",
    method: "get",
    params: data
  });
}

// 分页查询合同
export function getChannelGroups(data) {
  return request({
    url: "/hr/api/v1/channelGroups/getMyChannelGroup",
    method: "get",
    params: data
  });
}
// 分页查询合同
export function getChannelGroupsAll(data) {
  return request({
    url: "/hr/api/v1/channelGroups/listAll",
    method: "get",
    params: data
  });
}
//
export function getContractlist(data) {
  return request({
    url: "/hr/api/v1/empContracts/selectContractPage",
    method: "get",
    params: data
  });
}
// 查询是否有默认值（合同开始时间，合同期限，合同结束时间）
export function getContractdefault(data) {
  return request({
    url: "/hr/api/v1/empContracts/listByType",
    method: "get",
    params: data
  });
}
// 查询合同类型分组表
export function getContractGroups(data) {
  return request({
    // url: "/contract/api/v1/contractGroups/listAll",
    url: "/hr/api/v1/contracts/selectAndSynchronizeContractGroup",
    method: "PUT",
    data: data
  });
}

// 新增合同类型分组表
export function addContractGroups(data) {
  return request({
    url: "/contract/api/v1/contractGroups",
    method: "post",
    data: data
  });
}

// 修改合同类型分组表
export function updateContractGroups(data) {
  return request({
    url: "/contract/api/v1/contractGroups",
    method: "put",
    data: data
  });
}

// 批量删除合同类型分组表，彻底删除
export function batchDelContractGroups(data) {
  return request({
    url: "/contract/api/v1/contractGroups",
    method: "DELETE",
    data: data
  });
}

// 合同类型表
export function getCategorieslistAll(data) {
  return request({
    url: "/contract/api/v1/categories/listAll",
    method: "get",
    params: data
  });
}

// 新增合同类型分组表
export function addCategories(data) {
  return request({
    url: "/contract/api/v1/categories",
    method: "post",
    data: data
  });
}

// 修改合同类型分组表
export function updateCategories(data) {
  return request({
    url: "/contract/api/v1/categories",
    method: "put",
    data: data
  });
}
// 修改系统模板名称
export function updateByAdmin(data) {
  return request({
    url: "/admin/api/v1/contracts/updateCategoryByAdmin",
    method: "put",
    data: data
  });
}

// 合同设置中修改合同类型
export function saveCategoryUpdate(data, type) {
  var url = "/contract/api/v1/categories";
  if (type) url = "/admin/api/v1/contracts/updateCategoryByAdmin";
  return request({
    url: url,
    method: "put",
    data: data
  });
}

// 根据合同类型id查询合同类型详情
export function getCategoriesDetail(categoryId, type) {
  var url = "/contract/api/v1/categories/".concat(categoryId);
  if (type) url = "/admin/api/v1/contracts/selectCategoryById/".concat(categoryId);
  return request({
    url: url,
    method: "get"
  });
}

// 根据合同id修改合同编号
export function updateContractNoById(data) {
  return request({
    // url: `/contract/api/v1/contracts/updateContractNoById/${contractId}`,
    url: "/hr/api/v1/contracts/updateContractNo",
    method: "put",
    data: data
  });
}
// 根据id查询合同类型
export function getCategoriesBycategoryId(categoryId) {
  return request({
    url: "/hr/api/v1/contracts/categories/".concat(categoryId),
    method: "get"
  });
}
// 批量删除合同类型分组表，彻底删除
export function batchDelCategories(data) {
  return request({
    url: "/contract/api/v1/categories",
    method: "DELETE",
    data: data
  });
}

// 查询合同模板
export function templatesListAll(data) {
  return request({
    url: "/contract/api/v1/templates/listAll",
    method: "get",
    params: data
  });
}
// 根据公司id查询公司详情
export function getCompanyInfo(companyId) {
  return request({
    url: "/hr/api/v1/companies/".concat(companyId),
    method: "get"
  });
}
// 获取公司部门和员工信息
export function getDepAndEmpInfo(companyId) {
  return request({
    url: "/hr/api/v1/depInfos/getDepAndEmpInfo/".concat(companyId),
    method: "get"
  });
}
// 分页查询全部在职员工相关信息(确认到岗且没确认离职的)
export function getAllSignEmp(params, signingStatus) {
  return request({
    url: "/hr/api/v1/empInfos/pageEmpList/".concat(signingStatus),
    method: "get",
    params: params
  });
}
export function getAllEmp(params) {
  return request({
    url: "/hr/api/v1/empInfos/pageEmpList",
    method: "get",
    params: params
  });
}
export function getAllEmp2(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectCurrentMonthByCompanyId",
    method: "get",
    params: params
  });
}
export function getRecordInfo(params) {
  return request({
    url: "/hr/api/v1/empSocInfos/pageEmpSocBack",
    method: "get",
    params: params
  });
}
export function getDepEmpInfoBu(params) {
  return request({
    url: "/hr/api/v1/empInfos/pageByFill",
    method: "get",
    params: params
  });
}
export function getWageEmp(params) {
  return request({
    url: "salary/api/v1/fixedSalaryItems/selectPageEmpSalaryNew",
    method: "get",
    params: params
  });
}
export function getWageEmp2(params) {
  return request({
    url: "salary/api/v1/fixedSalaryItems/selectPageEmpSalaryByHaveTemplate",
    method: "get",
    params: params
  });
}
// export function getDepEmpInfo(params) {
//   return request({
//     url: `/hr/api/v1/empInfos/pageEmpList`,
//     method: "get",
//     params
//   });
// }
export function getDepEmpInfo(companyId, params) {
  return request({
    url: "/hr/api/v1/depInfos/getCompanyDepEmp/".concat(companyId),
    method: "get",
    params: params
  });
}

// 根据岗位类型查询员工（添加社保）
export function getDepEmpInfo2(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpGroupByPostTypeWithEmpSocInfo",
    method: "get",
    params: params
  });
}
// 根据岗位类型查询员工（增员）
export function getDepEmpInfo3(params) {
  return request({
    url: "/hr/api/v1/empInfos/increaseSiftEmp",
    method: "get",
    params: params
  });
}
// 社保差额费用
export function getEmpForDifference(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectDifferenceEmp",
    method: "get",
    params: params
  });
}
export function pasteIdCardSelect(companyId, data) {
  return request({
    url: "/hr/api/v1/empInfos/pasteIdCardSelect/".concat(companyId),
    method: "post",
    data: data
  });
}
// 根据岗位类型查询员工（减员）
export function getDepEmpInfo4(params) {
  return request({
    url: "/hr/api/v1/empInfos/decreaseSiftEmp",
    method: "get",
    params: params
  });
}
// 模板变量管理
export function variableListAll() {
  return request({
    url: "/contract/api/v1/variables/listAll",
    method: "get"
  });
}
export function variableListAll2(params, type) {
  var url = "/hr/api/v1/contracts/selectVariableNotPersonal";
  if (type) url = "/admin/api/v1/contracts/selectVariableNotPersonal";
  return request({
    url: url,
    method: "get",
    params: params
  });
}

// 模板关联变量批量操作
// export function templateVariablesBatchReq(data) {
//   return request({
//     url: `/contract/api/v1/templateVariables/batch`,
//     method: "post",
//     data: {
//       templateVariableAddDTOList: data
//     }
//   });
// }
export function updateTemplateVariableBySite(data) {
  return request({
    url: "/hr/api/v1/contracts/updateTemplateVariable",
    method: "post",
    data: data
  });
}
export function templateVariablesBatchReq(data, type) {
  var url = "/hr/api/v1/contracts/updateTemplateVariable";
  if (type) url = "/admin/api/v1/contracts/updateTemplateVariableByAdmin";
  return request({
    url: url,
    method: type ? "put" : "post",
    routeChangeCancel: false,
    data: data
  });
}

// 模板印章
export function templateSealListAllReq(templateId, type) {
  var url = "/contract/api/v1/sealPositions/listAll?templateId=".concat(templateId);
  if (type) url = "/admin/api/v1/contracts/selectSealPositionByAdmin?templateId=".concat(templateId);
  return request({
    url: url,
    method: "get"
  });
}
export function templateSealCreateReq(data) {
  return request({
    url: "/contract/api/v1/sealPositions/saveOrUpdate",
    method: "post",
    data: data
  });
}
export function postSaveOrUpdate(data, templateId, type) {
  var url = "/contract/api/v1/sealPositions/saveOrUpdate/".concat(templateId);
  if (type) url = "/admin/api/v1/contracts/saveOrUpdateSealPositionByAdmin/".concat(templateId);
  return request({
    url: url,
    method: "post",
    data: data
  });
}
export function templateSealDeleteReq(data) {
  return request({
    url: "/contract/api/v1/sealPositions",
    method: "delete",
    data: data
  });
}

// 合同模板管理
export function templateListAllReq(categoryId, type) {
  var url = "/contract/api/v1/templates/listAll?categoryId=".concat(categoryId);
  if (type) url = "/admin/api/v1/contracts/selectTemplateByCategoryId?categoryId=".concat(categoryId);
  return request({
    url: url,
    method: "get"
  });
}
export function templateCreateReq(data) {
  return request({
    url: "/contract/api/v1/templates",
    method: "post",
    data: data
  });
}
export function templateDetailReq(templateId, type) {
  var url = "/contract/api/v1/templates/".concat(templateId);
  if (type) url = "/admin/api/v1/contracts/selectTemplateById/".concat(templateId);
  return request({
    url: url,
    method: "get"
  });
}
export function templateUpdateReq(data, type) {
  var url = "/contract/api/v1/templates";
  if (type) url = "/admin/api/v1/contracts/updateTemplate";
  return request({
    url: url,
    method: "put",
    routeChangeCancel: false,
    data: data
  });
}
export function templateDeleteReq(data) {
  return request({
    url: "/contract/api/v1/templates",
    method: "delete",
    data: data
  });
}
export function getSealListAll(data) {
  return request({
    url: "/contract/api/v1/seals/listAll",
    method: "get",
    params: data
  });
}
// 印章管理
export function sealListAllReq(params) {
  var reqObj = {
    url: "/contract/api/v1/seals/listAll",
    method: "get",
    params: params
  };
  // if (query) {
  //   reqObj["params"] = {
  //     name: query,
  //     companyId: companyId
  //   };
  // }
  return request(reqObj);
}
export function sealCreateReq(data) {
  return request({
    url: "/contract/api/v1/seals",
    method: "post",
    data: data
  });
}
export function sealUpdateReq(data) {
  return request({
    url: "/contract/api/v1/seals",
    method: "put",
    data: data
  });
}
export function sealDeleteReq(data) {
  return request({
    url: "/contract/api/v1/seals",
    method: "delete",
    data: data
  });
}
export function sealDetailReq(sealId) {
  return request({
    url: "/contract/api/v1/seals/".concat(sealId),
    method: "get"
  });
}
export function sealVerifyReq(data) {
  return request({
    url: "/hr/api/v1/contracts/verifyEnterprise",
    method: "post",
    data: data
  });
}

// 小程序端是否展示根据合同id修改小程序app是否展示
export function updateAppShowById(contractId, isShow) {
  return request({
    url: "/contract/api/v1/contracts/updateAppShowById/".concat(contractId, "/").concat(isShow),
    method: "put"
  });
}

// 查询员工相关合同信息
export function getEmpInfoListForContract(data) {
  return request({
    url: "/hr/api/v1/empInfos/getContractEmpInfoList",
    method: "post",
    data: data
  });
}
// url: `/hr/api/v1/empInfos/getEmpInfoListForContract`,

// 企业签署方签署
export function enterpriseSign0(data) {
  return request({
    url: "/contract/api/v1/signs/enterpriseSign",
    method: "post",
    data: data
  });
}

// 发起模板签署
export function publishTemplateSignReq(data) {
  return request({
    url: "hr/api/v1/contracts/publishTemplateSignAsync",
    method: "post",
    data: data
  });
}

// 查询合同文件
export function checkEleContract(data) {
  return request({
    url: "/contract/api/v1/appendixes/listAll",
    method: "get",
    params: data
  });
}

// 获取模板变量map列表 员工单方签署
export function getVariableListMap(templateId) {
  return request({
    url: "/hr/api/v1/contracts/template/".concat(templateId, "/variableList"),
    method: "get"
  });
}

// 根据公司id查询小程序合同员工签署后是否默认展示
export function findByCompanyId(companyId) {
  return request({
    url: "/contract/api/v1/companyContractShows/findByCompanyId/".concat(companyId),
    method: "get"
  });
}
// 修改某公司合同员工签署完成后是否默认展示
export function updateCompanyShow(companyId, isShow) {
  return request({
    url: "/contract/api/v1/companyContractShows/updateCompanyShow/".concat(companyId, "/").concat(isShow),
    method: "put"
  });
}
// 查询员工列表（粘贴选择）

export function empListByIdCards(companyId, data) {
  return request({
    url: "/hr/api/v1/empInfos/empListByIdCards/".concat(companyId),
    method: "post",
    data: data
  });
}
export function empListByIdCards2(companyId, data) {
  return request({
    url: "/hr/api/v1/empInfos/selectNoFixSalaryFileGroupByIdCards/".concat(companyId),
    method: "post",
    data: data
  });
}
export function empListByIdCardsRYK(data) {
  return request({
    url: "/hr/api/v1/transferAccountsPeoples/selectByIdCards",
    method: "post",
    data: data
  });
}
export function empListByIdCardsTEM(data) {
  return request({
    url: "/salary/api/v1/fixedSalaryItems/parseIdCard",
    method: "post",
    data: data
  });
}
// 发起合同（粘贴选择）

export function empContractByIdCards(companyId, data) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoByIdCardsAddConUse/".concat(companyId),
    method: "post",
    data: data
  });
}
export function listByCompanyIdGet(params) {
  return request({
    url: "/hr/api/v1/empTag/listByCompanyId",
    method: "get",
    params: params
  });
}
// 获取工价计算规则列表
export function salaryCalculateRulesListall(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/listAll",
    method: "get",
    params: params
  });
}
// 查询分组下公司
export function findCompanyGroup(data) {
  return request({
    url: "/hr/api/v1/childrenCompany/listAll",
    method: "GET",
    params: data
  });
}
// 生成渠道結算
export function saveChannelSettlement(data) {
  return request({
    url: "/salary/api/v1/channelFeeSettlements/saveChannelSettlement",
    method: "post",
    data: data
  });
}
// 根据合同pdf转图片放到合同文件中(签署完调用)
export function setJpgToAppendix(contractId) {
  return request({
    url: "/hr/api/v1/contracts/setJpgToAppendix/".concat(contractId),
    method: "put"
  });
}

// 根据合同pdf转图片放到合同文件中
export function putSetJpgToMoreAppendix(data) {
  return request({
    url: "/hr/api/v1/contracts/setJpgToMoreAppendix",
    method: "put",
    data: data
  });
}

// 查询所有通知分组
export function userList(params, subjectId) {
  return request({
    url: "/hr/api/v1/users/listUserHasRoleInCompany/".concat(subjectId),
    method: "GET",
    params: params
  });
}
// 查询租户所有渠道
export function listSiteAll(params) {
  return request({
    url: "/hr/api/v1/channels/listSiteAll",
    method: "GET",
    params: params
  });
}
// 修改签署记录合同名称
export function putUpdateContractName(data) {
  return request({
    url: "/hr/api/v1/contracts/updateContractName",
    method: "put",
    data: data
  });
}