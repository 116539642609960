var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c("div", {
    directives: [{
      name: "drag",
      rawName: "v-drag"
    }],
    attrs: {
      id: "drag_task_box"
    }
  }, [_c("div", {
    staticClass: "br4 bgc-white",
    staticStyle: {
      width: "380px",
      overflow: "hidden",
      "box-shadow": "0 6px 16px 8px rgba(0, 0, 0, 0.03)"
    }
  }, [_c("div", {
    staticClass: "pv16 pl12 pr8",
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_vm.countFinished === _vm.list.length ? _c("div", {
    staticClass: "fs16 fw500",
    staticStyle: {
      color: "rgba(60, 61, 67, 1)",
      "line-height": "16px"
    }
  }, [_vm._v(" 任务已完成（" + _vm._s(_vm.list.length) + "） ")]) : _c("div", {
    staticClass: "fs16 fw500",
    staticStyle: {
      color: "rgba(60, 61, 67, 1)",
      "line-height": "16px"
    }
  }, [_vm._v(" 任务进行中（" + _vm._s(_vm.countFinished) + "/" + _vm._s(_vm.list.length) + "） ")]), _c("div", {
    staticStyle: {
      display: "flex",
      color: "rgba(140, 140, 140, 1)"
    }
  }, [_vm.listVisible ? _c("span", {
    staticClass: "iconfont icon-shouqi2 mr4 ta-center",
    staticStyle: {
      "font-size": "14px",
      "margin-top": "-2px",
      width: "24px",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        _vm.listVisible = false;
      }
    }
  }) : _c("span", {
    staticClass: "iconfont icon-shouqi1 mr4 ta-center",
    staticStyle: {
      "font-size": "14px",
      "margin-top": "-2px",
      width: "24px",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        _vm.listVisible = true;
      }
    }
  }), _c("span", {
    staticClass: "ta-center",
    staticStyle: {
      "margin-top": "-2px",
      width: "24px",
      cursor: "pointer"
    },
    on: {
      click: _vm.onClose
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "16px"
    },
    attrs: {
      type: "close"
    }
  })], 1)])]), _vm.listVisible ? _c("div", {
    staticStyle: {
      "max-height": "340px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.list, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "ph12 pt16 pb12",
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "box-shadow": "inset 0px 1px 0px 0px #e8e8e8"
      }
    }, [_c("img", {
      staticClass: "mr8",
      staticStyle: {
        width: "24px",
        height: "24px flex-shrink: 0"
      },
      attrs: {
        src: _vm.iconDict[item.taskType]
      }
    }), _c("div", {
      staticStyle: {
        flex: "1"
      }
    }, [_c("a-tooltip", {
      attrs: {
        title: item.taskName
      }
    }, [_c("div", {
      staticClass: "mb8 ellipsis",
      staticStyle: {
        width: "324px",
        "line-height": "14px",
        color: "rgba(85, 86, 93, 1)"
      }
    }, [_vm._v(_vm._s(item.taskName))])]), item.taskStatus === "1" ? _c("div", [_c("a-progress", {
      attrs: {
        percent: Math.round(item.finishedTask / item.totalTask * 100)
      }
    })], 1) : item.taskStatus === "3" && item.taskResult === "0" ? _c("div", {
      staticClass: "fs12",
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "space-between",
        color: "rgba(166, 168, 180, 1)"
      }
    }, [_c("span", [_vm._v(_vm._s(_vm.textDict[item.taskType]) + "失败")]), _c("span", [_vm._v(_vm._s(_vm.diffTime(item.gmtCreate)))]), _c("span", {
      staticClass: "tc-theme",
      staticStyle: {
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.showError(item);
        }
      }
    }, [_vm._v("失败原因")])]) : item.taskStatus === "3" && item.taskResult === "1" ? _c("div", {
      staticClass: "fs12",
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "space-between",
        color: "rgba(166, 168, 180, 1)"
      }
    }, [_c("span", [_vm._v(_vm._s(_vm.textDict[item.taskType]) + "成功")]), _c("span", [_vm._v(_vm._s(_vm.diffTime(item.gmtCreate)))]), _c("span")]) : _vm._e()], 1)]);
  }), 0) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.failReasonVisible,
      destroyOnClose: true,
      title: "失败原因"
    },
    on: {
      cancel: _vm.hideError,
      ok: _vm.hideError
    }
  }, [_c("div", [_c("div", {
    staticClass: "fs16 fw500 mb24",
    staticStyle: {
      color: "rgba(60, 61, 67, 1)"
    }
  }, [_c("img", {
    staticClass: "mr12",
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: require("./images/error.png")
    }
  }), _vm._v(" " + _vm._s(_vm.failReasonTitle) + "失败原因 ")]), _c("div", [_c("a-table", {
    attrs: {
      dataSource: _vm.errorList,
      columns: _vm.columns,
      size: "small",
      rowKey: "id"
    }
  })], 1)])])], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };