var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.recordLst && _vm.recordLst.length > 0 ? _c("div", {
    staticClass: "mt16"
  }, _vm._l(_vm.recordLst, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "mt16 border br4 p12"
    }, [_c("div", [_vm._v(_vm._s(_vm.formatDate(item.beginTime)) + " - " + _vm._s(_vm.formatDate(item.endTime)))]), _c("div", {
      staticClass: "flex-center-between tc-l3 mt12"
    }, [_c("div", {
      staticStyle: {
        width: "30%"
      }
    }, [_vm._v(" 参保方案："), _c("span", {
      staticClass: "tc-l1"
    }, [_vm._v(_vm._s(item.schemeName))])]), _c("div", {
      staticStyle: {
        width: "30%"
      }
    }, [_vm._v(" 保险公司："), _c("span", {
      staticClass: "tc-l1"
    }, [_vm._v(_vm._s(item.insCompanyName))])]), _c("div", {
      staticStyle: {
        width: "30%"
      }
    }, [_vm._v(" 保费："), _c("span", {
      staticClass: "tc-l1"
    }, [_vm._v(_vm._s(item.monthAmount) + "/月")])])]), _c("div", {
      staticClass: "flex-center-between tc-l3 mt12"
    }, [_c("div", {
      staticStyle: {
        width: "30%"
      }
    }, [_vm._v(" 缴费类型："), _c("span", {
      staticClass: "tc-l1"
    }, [_vm._v(_vm._s(item.paymentType || ""))])]), _c("div", {
      staticStyle: {
        width: "60%"
      }
    }, [_vm._v(" 方案详情："), _c("span", {
      staticClass: "tc-l1"
    }, [_vm._v(_vm._s(item.schemeDetail || ""))])])]), _c("div", {
      staticClass: "flex-center-between tc-l3 mt12"
    }, [_c("div", {
      staticStyle: {
        width: "90%"
      }
    }, [_vm._v(" 赔付内容："), _c("span", {
      staticClass: "tc-l1"
    }, [_vm._v(_vm._s(item.payoutContent || ""))])])])]);
  }), 0) : _c("a-empty", {
    staticClass: "mt16"
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };