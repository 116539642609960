import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white",
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "p16",
    staticStyle: {
      width: "300px",
      "flex-shrink": "0"
    }
  }, [_c("div", {
    staticClass: "flex-center-between"
  }, [_c("span", {
    staticClass: "fs16 fw500"
  }, [_vm._v("销售分组")]), _c("span", {
    staticStyle: {
      display: "inline-block",
      cursor: "pointer"
    },
    on: {
      click: _vm.onAddGroupItem
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1)]), _c("div", {
    staticClass: "mt12"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "268px"
    },
    attrs: {
      placeholder: "搜索分组",
      allowClear: ""
    },
    on: {
      change: _vm.onGroupSearchChange
    }
  })], 1), _vm.groupSite ? _c("div", {
    staticClass: "mt24 ph8 pv4 flex-center-between",
    staticStyle: {
      "border-bottom": "1px solid #e8e8e8",
      cursor: "pointer",
      "border-radius": "4px"
    },
    style: {
      background: _vm.groupSite.id === _vm.currentGroupId ? "var(--primary30)" : "transparent"
    },
    on: {
      click: function click($event) {
        return _vm.onGroupChange(_vm.groupSite.id);
      }
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.groupSite.name) + "（" + _vm._s(_vm.groupSite.count) + "）")]), _vm.groupSite.editable ? _c("span", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.onEditGroupItem(_vm.groupSite.id);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "setting"
    }
  })], 1) : _c("span", {
    staticStyle: {
      color: "rgba(0, 0, 0, 0.15)"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "setting"
    }
  })], 1)]) : _vm._e(), _c("div", {
    staticClass: "mt8",
    staticStyle: {
      width: "100%",
      "overflow-x": "auto"
    }
  }, [_c("a-tree", {
    attrs: {
      treeData: _vm.groupSubTree,
      selectedKeys: [_vm.currentGroupId],
      defaultExpandAll: ""
    },
    on: {
      select: function select(selectedKeys) {
        return _vm.onGroupChange(selectedKeys[0]);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn(node) {
        return [_c("div", {
          staticClass: "flex-center"
        }, [_c("div", {
          staticClass: "ellipsis",
          staticStyle: {
            width: "200px"
          }
        }, [_vm._v(_vm._s(node.title) + "（" + _vm._s(node.count) + "）")]), node.editable ? _c("a-icon", {
          attrs: {
            type: "setting"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.onEditGroupItem(node.value);
            }
          }
        }) : _c("a-icon", {
          staticStyle: {
            color: "rgba(0, 0, 0, 0.15)"
          },
          attrs: {
            type: "setting"
          }
        })], 1)];
      }
    }])
  })], 1)]), _c("div", {
    staticClass: "p16",
    staticStyle: {
      flex: "1",
      "border-left": "1px solid #e8e8e8"
    }
  }, [_vm.currentGroupTarget ? [_c("div", [_c("span", {
    staticClass: "fs16 fw500 mr8"
  }, [_vm._v(_vm._s(_vm.currentGroupTarget.name))]), _vm.currentGroupTarget.groupSupervisor ? _c("span", [_vm._v("分组主管：" + _vm._s(_vm.currentGroupTarget.groupSupervisor))]) : _vm._e()]), _vm.currentGroupTarget.description ? _c("div", {
    staticClass: "fs12 mt12",
    staticStyle: {
      color: "rgba(102, 102, 102, 1)"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentGroupTarget.description) + " ")]) : _vm._e()] : _vm._e(), _c("div", {
    staticClass: "flex-center-between mt16"
  }, [_c("a-space", [_c("a-input-search", {
    staticStyle: {
      width: "230px"
    },
    attrs: {
      placeholder: "销售名称/联系电话/身份证号",
      allowClear: ""
    },
    on: {
      change: _vm.onEmpSearchChange
    },
    model: {
      value: _vm.saleNameOrPhoneOrIdCard,
      callback: function callback($$v) {
        _vm.saleNameOrPhoneOrIdCard = $$v;
      },
      expression: "saleNameOrPhoneOrIdCard"
    }
  }), _c("a-cascader", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      options: _vm.common.areaList,
      changeOnSelect: "",
      placeholder: "所在地区",
      "show-search": {
        filter: _vm.filter
      },
      allowClear: ""
    },
    on: {
      change: _vm.onEmpSearchChange
    },
    model: {
      value: _vm.region,
      callback: function callback($$v) {
        _vm.region = $$v;
      },
      expression: "region"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      placeholder: "显示成员"
    },
    on: {
      change: _vm.onEmpSearchChange
    },
    model: {
      value: _vm.isCurrentGroup,
      callback: function callback($$v) {
        _vm.isCurrentGroup = $$v;
      },
      expression: "isCurrentGroup"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "0"
    }
  }, [_vm._v("显示全部分组成员")]), _c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("仅显示当前部门成员")])], 1)], 1), _c("a-space", [_c("a-button", {
    on: {
      click: _vm.onExportEmp
    }
  }, [_vm._v("全部导出")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onAddEmpItem
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("新增销售")], 1)], 1)], 1), _c("a-table", {
    staticClass: "mt16",
    attrs: {
      dataSource: _vm.empLst,
      pagination: _vm.pagination,
      columns: _vm.empColumns,
      rowKey: "id",
      size: "middle"
    },
    on: {
      change: _vm.onEmpTableChange
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return _c("a-space", {}, [_c("a", {
          on: {
            click: function click($event) {
              return _vm.onEditEmpItem(record);
            }
          }
        }, [_vm._v("编辑")]), _c("a", {
          on: {
            click: function click($event) {
              return _vm.onDelEmpItem(record.id);
            }
          }
        }, [_vm._v("删除")])]);
      }
    }, {
      key: "uc",
      fn: function fn(text, record) {
        return [_c("div", {
          staticClass: "ellipsis pointer",
          staticStyle: {
            width: "200px"
          },
          on: {
            click: function click($event) {
              return _vm.$refs.uc.onItemClick(record.saleUserId);
            }
          }
        }, [_c("a", [_vm._v(_vm._s(text))])])];
      }
    }])
  })], 2), _c("a-modal", {
    attrs: {
      title: _vm.groupModalTitle
    },
    model: {
      value: _vm.groupModalVisible,
      callback: function callback($$v) {
        _vm.groupModalVisible = $$v;
      },
      expression: "groupModalVisible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.groupForm
    }
  }, [!_vm.disabled ? _c("a-form-item", {
    attrs: {
      label: "选择上层分组",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["parentId", {
        rules: [{
          required: true,
          message: "必须选择上层分组"
        }]
      }],
      expression: "['parentId', { rules: [{ required: true, message: '必须选择上层分组' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      treeData: _vm.groupFullTree,
      showSearch: "",
      treeNodeFilterProp: "title",
      placeholder: "请选择"
    },
    on: {
      select: _vm.onGroupParentChange
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "分组名称",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "必须填写名称"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '必须填写名称' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      disabled: _vm.disabled,
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "分组主管",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["groupSupervisor"],
      expression: "['groupSupervisor']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.groupManagers,
      showSearch: "",
      filterOption: _vm.filterOption,
      disabled: _vm.tempGroup && _vm.tempGroup.editAdminPermissions !== true,
      optionFilterProp: "label",
      mode: "multiple",
      placeholder: "请选择"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "分组说明",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["description"],
      expression: "['description']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请输入"
    }
  })], 1)], 1), _c("a-space", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        _vm.groupModalVisible = false;
      }
    }
  }, [_vm._v("取消")]), _vm.groupModalTitle === "编辑分组" ? _c("a-button", {
    attrs: {
      disabled: _vm.disabled,
      type: "danger"
    },
    on: {
      click: _vm.onDelGroupItem
    }
  }, [_vm._v("删除")]) : _vm._e(), _c("a-button", {
    attrs: {
      loading: _vm.confirmLoadingGroup,
      type: "primary"
    },
    on: {
      click: _vm.onGroupModalOk
    }
  }, [_vm._v("提交")])], 1)], 1), _c("a-modal", {
    attrs: {
      title: _vm.empModalTitle,
      confirmLoading: _vm.confirmLoadingEmp
    },
    on: {
      ok: _vm.onEmpModalOk
    },
    model: {
      value: _vm.empModalVisible,
      callback: function callback($$v) {
        _vm.empModalVisible = $$v;
      },
      expression: "empModalVisible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.empForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "所属分组",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["saleGroupId", {
        rules: [{
          required: true,
          message: "必须选择所属分组"
        }]
      }],
      expression: "['saleGroupId', { rules: [{ required: true, message: '必须选择所属分组' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      treeData: _vm.groupSubTree,
      showSearch: "",
      treeNodeFilterProp: "title",
      placeholder: "请选择"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "销售名称",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["saleUserId", {
        rules: [{
          required: true,
          message: "必须选择销售"
        }]
      }],
      expression: "['saleUserId', { rules: [{ required: true, message: '必须选择销售' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.groupManagers2,
      disabled: !!_vm.tempEmp,
      showSearch: "",
      filterOption: _vm.filterOption,
      optionFilterProp: "label",
      placeholder: "请选择"
    },
    on: {
      change: _vm.onEmpChange
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "联系电话",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["salePhone", {
        rules: [{
          required: true,
          message: "必须填写联系电话"
        }]
      }],
      expression: "['salePhone', { rules: [{ required: true, message: '必须填写联系电话' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      disabled: "",
      placeholder: "请选择销售"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "所在地区",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-cascader", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["region"],
      expression: "['region']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.common.areaList,
      "show-search": {
        filter: _vm.filter
      },
      changeOnSelect: "",
      placeholder: "请选择"
    },
    on: {
      change: _vm.onRegionChange
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "身份证号",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["saleIdCard"],
      expression: "['saleIdCard']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请填写"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "银行卡号",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["saleBankCard"],
      expression: "['saleBankCard']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请填写"
    }
  })], 1)], 1)], 1), _c("UserCompany", {
    ref: "uc"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };