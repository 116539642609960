var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "payslip-detail page-miH page-bgBack",
    staticStyle: {
      padding: "0 0 24px 0"
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(" 工资条发放详情 ")]), _c("a-row", {
    staticClass: "top",
    staticStyle: {
      padding: "0 24px 0 24px"
    },
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("a-col", {
    staticClass: "full"
  }, [_c("a-tabs", {
    attrs: {
      "default-active-key": "0"
    },
    on: {
      change: _vm.changeTab
    }
  }, [_c("a-tab-pane", {
    key: "0",
    attrs: {
      tab: "\u5DF2\u53D1\u653E".concat(_vm.tabs.sendCount, "\u4EBA")
    }
  }, [_c("payslipProvide")], 1), _c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "\u5F85\u53D1\u653E".concat(_vm.tabs.unSendCount, "\u4EBA")
    }
  }, [_c("payslipWaitProvide")], 1), _c("a-tab-pane", {
    key: "3",
    attrs: {
      tab: "\u53D1\u653E\u8BB0\u5F55"
    }
  }, [_c("payslipHistory")], 1)], 1)], 1), _vm.hide ? _c("a-col", {
    staticClass: "mg_t6 pos"
  }, [false ? _c("a-button", {
    on: {
      click: _vm.statistics
    }
  }, [_vm._v("查看统计分析")]) : _vm._e()], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };