import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import { mapState } from "vuex";
import { approvalDetailReq, approvalHandlerAgreeReq, approvalHandlerRejectReq } from "./api";
import ApprovalForm from "./components/approvalForm";
import ApprovalProcess from "./components/approvalProcess";
import approvalTimeLine from "./components/approvalTimeLine";
import approvalDetail from "./components/approvalDetail";
import { getApprovalStatus, getApprovalStatusColorBlue } from "./funcs";
export default {
  name: "",
  components: {
    ApprovalForm: ApprovalForm,
    ApprovalProcess: ApprovalProcess,
    approvalTimeLine: approvalTimeLine,
    approvalDetail: approvalDetail
  },
  props: {},
  data: function data() {
    return {
      appRovalDet: true,
      openState: "",
      confirmLoading: false,
      visible: false,
      placeholder: "请输入同意理由",
      Title: "审批同意",
      approval: null,
      approvalLogs: [],
      approvalNodes: [],
      userApproInfoList: [],
      dataSource: null,
      dynamicData: null,
      formFields: null,
      formConfig: null,
      formVisible: false,
      remark: ""
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr", "company"])), {}, {
    companyEmployeeList: function companyEmployeeList() {
      return this.company.companyEmployeeList;
    },
    companyDepartmentBaseList: function companyDepartmentBaseList() {
      return this.company.companyDepartmentBaseList;
    },
    companyPositionBaseList: function companyPositionBaseList() {
      return this.company.companyPositionBaseList;
    },
    companyName: function companyName() {
      return this.hr.companyName;
    },
    approvalId: function approvalId() {
      return this.$route.params.approvalId;
    },
    userApprovalId: function userApprovalId() {
      return this.$route.params.userApprovalId;
    },
    dynamicValueMap: function dynamicValueMap() {
      return {
        applyUser: this.companyEmployeeList,
        oldDepartment: this.companyDepartmentBaseList,
        newDepartment: this.companyDepartmentBaseList,
        oldPosition: this.companyPositionBaseList,
        newPosition: this.companyPositionBaseList
      };
    },
    dynamicValueMapKeys: function dynamicValueMapKeys() {
      return Object.keys(this.dynamicValueMap);
    },
    relationDataLoaded: function relationDataLoaded() {
      return this.companyEmployeeList.length > 0 && this.companyPositionBaseList.length > 0 && this.companyDepartmentBaseList.length > 0;
    }
  }),
  watch: {
    relationDataLoaded: {
      handler: function handler(newVal) {
        if (newVal) {
          this.loadData();
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    openModal: function openModal(e) {
      this.openState = e;
      this.visible = true;
      this.remark = "";
      if (e === "agree") {
        this.Title = "审批同意";
        this.placeholder = "请输入同意理由";
      } else {
        this.Title = "驳回意见";
        this.placeholder = "请输入驳回理由";
      }
    },
    handleOk: function handleOk() {
      if (this.openState === "agree") {
        this.handlerAgree();
      } else {
        this.handlerReject();
      }
    },
    handlerBack: function handlerBack() {
      this.$router.go(-1);
    },
    buildUserApproInfoList: function buildUserApproInfoList(values) {
      var keys = Object.keys(values);
      var userApproInfoList = this.userApproInfoList.filter(function (item) {
        return item.settingType === "edit";
      });
      keys.map(function (key) {
        var idx = userApproInfoList.findIndex(function (item) {
          return item.formId === key;
        });
        if (idx > -1) {
          userApproInfoList[idx]["value"] = values[key];
        }
      });
      return {
        userApproInfoList: userApproInfoList,
        remark: this.remark
      };
    },
    handlerAgree: function handlerAgree() {
      var _this = this;
      this.$refs["approvalForm"].getData().then(function (values) {
        approvalHandlerAgreeReq(_this.userApprovalId, _this.buildUserApproInfoList(values)).then(function (res) {
          // TODO 审批完成的提示，刷新数据
          _this.visible = false;
          _this.loadData();
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this.$message.error("数据未填写或格式错误");
      });
    },
    handlerReject: function handlerReject() {
      var _this2 = this;
      this.$refs["approvalForm"].getData().then(function (values) {
        approvalHandlerRejectReq(_this2.userApprovalId, _this2.buildUserApproInfoList(values)).then(function (res) {
          // TODO 审批完成的提示，刷新数据
          _this2.visible = false;
          _this2.loadData();
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this2.$message.error("数据未填写或格式错误");
      });
    },
    getApprovalStatusColorBlue: getApprovalStatusColorBlue,
    getApprovalStatus: getApprovalStatus,
    loadData: function loadData() {
      var _this3 = this;
      approvalDetailReq(this.approvalId).then(function (res) {
        // console.log(res);
        _this3.approval = res.data;
        _this3.approvalLogs = res.data.approLogList;
        _this3.approvalNodes = res.data.userApproNodeList;
        _this3.approvalNodes[0].approUserList = [{
          userName: _this3.approval.userName,
          avatar: _this3.approval.userAvatar
        }];
        // 用于审批流程展示
        _this3.approvalNodes[0].userFlowApproverList = [{
          approverUserAvatar: _this3.approval.userAvatar,
          approverUserName: _this3.approval.userName,
          gmtCreate: _this3.approval.gmtCreate,
          approverOpinion: "发起审批",
          status: 2
        }];
        _this3.userApproInfoList = res.data.userApproInfoList;
        _this3.buildForm(res.data.userApproInfoList);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    buildForm: function buildForm(list) {
      var _this4 = this;
      var formMap = {};
      var valueMap = {};
      var fieldKeys = [];
      for (var i = 0; i < list.length; i++) {
        var item = list[i];
        var field = JSON.parse(item.props);
        if (item.settingType === "read") {
          field.customize.disabled = true;
        }
        valueMap[field.model] = this.formatFormValues(item.value, item.widgetType, item.formCode);
        formMap[field.model] = field;
        if (["read", "edit"].includes(item.settingType)) {
          fieldKeys.push(field.model);
        }
      }
      this.dataSource = valueMap;
      this.formFields = formMap;
      this.formConfig = {
        fieldKeys: fieldKeys,
        customize: {
          layout: "vertical",
          labelAlign: "left"
        }
      };
      this.$nextTick(function () {
        _this4.formVisible = true;
      });
    },
    formatFormValues: function formatFormValues(value, type, model) {
      if (type === "switch") {
        return value === "true";
      } else if (type === "number") {
        return value ? Number(value) : value;
      } else {
        if (this.dynamicValueMapKeys.includes(model)) {
          var dataMap = this.dynamicValueMap[model];
          var theItem = dataMap.filter(function (item) {
            return item.id === value;
          });
          if (theItem.length > 0) {
            return theItem[0].name;
          } else {
            return value;
          }
        }
        return value;
      }
    }
  }
};