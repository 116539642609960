var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      width: "100%",
      height: "calc(100% - 42px)",
      position: "relative",
      "z-index": "1"
    }
  }, [_c("div", {
    ref: "editor",
    staticStyle: {
      width: "100%",
      height: "100%"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };