import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import { empFieldsSource, generateFieldItem } from "./funcs";
import TemplateConfigTable from "../components/templateConfigTable.vue";
import { loadEventTypeReq, addEventTypeReq } from "./api";
export default {
  name: "",
  components: {
    TemplateConfigTable: TemplateConfigTable
  },
  mixins: [],
  props: {
    isCreate: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      tempInfo: {},
      tempEmpInfo: {},
      tempEventInfo: {},
      name: "",
      eventType: undefined,
      eventTypeNew: "",
      eventTypeLst: [],
      empFields: [],
      eventFields: []
    };
  },
  computed: {
    groupKey: function groupKey() {
      return this.isCreate ? "formGroupAddList" : "formGroupUpdateList";
    },
    fieldKey: function fieldKey() {
      return this.isCreate ? "formGroupFieldAddList" : "formGroupFieldUpdateList";
    }
  },
  watch: {},
  created: function created() {
    this.loadEventType();
  },
  mounted: function mounted() {
    if (this.isCreate) {
      this.initEmpFields();
    }
  },
  methods: {
    getValues: function getValues() {
      return _objectSpread(_objectSpread({
        templateType: "custom"
      }, this.tempInfo), {}, _defineProperty({
        templateName: this.name,
        eventType: this.eventType
      }, "".concat(this.groupKey), [_objectSpread(_objectSpread({
        moduleName: "员工个人信息",
        moduleCode: "personalInfo",
        formType: "dycl",
        subjectType: "site",
        parentId: "0"
      }, this.tempEmpInfo), {}, _defineProperty({}, "".concat(this.fieldKey), this.empFields.map(function (item, idx) {
        return _objectSpread(_objectSpread({
          moduleName: "员工个人信息",
          moduleCode: "personalInfo",
          subjectType: "site",
          parentId: "0"
        }, item), {}, {
          sort: idx + 1
        });
      }))), _objectSpread(_objectSpread({
        moduleName: "事件概况",
        moduleCode: "eventOverview",
        formType: "dycl",
        subjectType: "site",
        parentId: "0"
      }, this.tempEventInfo), {}, _defineProperty({}, "".concat(this.fieldKey), this.eventFields.map(function (item, idx) {
        return _objectSpread(_objectSpread({
          moduleName: "事件概况",
          moduleCode: "eventOverview",
          subjectType: "site",
          parentId: "0"
        }, item), {}, {
          sort: idx + 1
        });
      })))]));
    },
    setValues: function setValues(initValues) {
      var _this = this;
      this.$nextTick(function () {
        var temp = _objectSpread({}, initValues);
        _this.name = initValues.templateName;
        _this.eventType = initValues.eventType;
        var tempEmp = _objectSpread({}, initValues.formGroupTree[0]);
        var tempEvent = _objectSpread({}, initValues.formGroupTree[1]);
        _this.empFields = initValues.formGroupTree[0].formGroupFieldList;
        _this.eventFields = initValues.formGroupTree[1].formGroupFieldList;
        delete temp.formGroupTree;
        _this.tempInfo = temp;
        delete tempEmp.formGroupFieldList;
        _this.tempEmpInfo = tempEmp;
        delete tempEvent.formGroupFieldList;
        _this.tempEventInfo = tempEvent;
      });
    },
    editEmpField: function editEmpField(_ref) {
      var params = _ref.params,
        mode = _ref.mode,
        index = _ref.index;
      if (mode === "add") {
        var newItem = generateFieldItem(params);
        this.empFields = [].concat(_toConsumableArray(this.empFields), [newItem]);
      } else if (mode === "edit") {
        var tempLst = _toConsumableArray(this.empFields);
        tempLst[index] = generateFieldItem(params, tempLst[index]);
        this.empFields = tempLst;
      }
    },
    sortEmpField: function sortEmpField(oldIdx, newIdx) {
      var tempLst = _toConsumableArray(this.empFields);
      var oldItem = tempLst[oldIdx];
      tempLst.splice(oldIdx, 1);
      tempLst.splice(newIdx, 0, oldItem);
      tempLst.forEach(function (item, index) {
        item.sort = index + 1;
      });
      this.empFields = tempLst;
    },
    editEventField: function editEventField(_ref2) {
      var params = _ref2.params,
        mode = _ref2.mode,
        index = _ref2.index;
      if (mode === "add") {
        var newItem = generateFieldItem(params);
        this.eventFields = [].concat(_toConsumableArray(this.eventFields), [newItem]);
      } else if (mode === "edit") {
        var tempLst = _toConsumableArray(this.eventFields);
        tempLst[index] = generateFieldItem(params, tempLst[index]);
        this.eventFields = tempLst;
      }
    },
    sortEventField: function sortEventField(oldIdx, newIdx) {
      var tempLst = _toConsumableArray(this.eventFields);
      var oldItem = tempLst[oldIdx];
      tempLst.splice(oldIdx, 1);
      tempLst.splice(newIdx, 0, oldItem);
      tempLst.forEach(function (item, index) {
        item.sort = index + 1;
      });
      this.eventFields = tempLst;
    },
    deleteEventField: function deleteEventField(index) {
      var tempLst = _toConsumableArray(this.eventFields);
      tempLst.splice(index, 1);
      this.eventFields = tempLst;
    },
    initEmpFields: function initEmpFields() {
      this.empFields = empFieldsSource.map(function (item) {
        return generateFieldItem(item);
      });
    },
    addEventType: function addEventType() {
      var _this2 = this;
      addEventTypeReq({
        dictItemValue: this.eventTypeNew,
        dictItemKey: this.eventTypeNew,
        dictCode: "1216"
      }).then(function (res) {
        if (res && res.code === 200) {
          _this2.$message.success("添加成功");
          _this2.loadEventType();
        } else {
          _this2.$message.error("添加失败");
        }
      }).catch(function (err) {
        console.log("addEventType", err);
      });
    },
    loadEventType: function loadEventType() {
      var _this3 = this;
      loadEventTypeReq().then(function (res) {
        if (res && res.data && res.data.length >= 0) {
          _this3.eventTypeLst = res.data.map(function (item) {
            return {
              label: item.dictItemValue,
              value: item.dictItemValue
            };
          });
        } else {
          _this3.eventTypeLst = [];
        }
      }).catch(function (err) {
        console.log("loadEventType", err);
      });
    }
  }
};