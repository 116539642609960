import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
export default {
  name: "CardTime",
  props: {
    chechCardTimeOptions: {
      show: false,
      editorType: false,
      obj: {}
    }
  },
  data: function data() {
    return {
      visible2: false,
      editorType: false,
      form2: this.$form.createForm(this, {
        name: "addFreeName2"
      }),
      attendCycleList: {
        cycleName: "",
        // 周期名称
        cycleDays: [{
          daysName: "第1天",
          // 第几天
          shiftName: "休息" // 班次名称
        }]
      }
    };
  },

  computed: {
    away: function away() {
      var i = 0;
      var awayBox = [];
      while (i <= 60) {
        awayBox.push({
          value: "".concat(i, "\u5206\u949F"),
          key: i
        });
        i += 15;
      }
      return awayBox;
    },
    contralTime: function contralTime() {
      var i = 480;
      var contralTimeBox = [];
      while (i >= 0) {
        contralTimeBox.push({
          value: "".concat(i / 60, "\u5C0F\u65F6\u5185"),
          key: i
        });
        i -= 30;
      }
      return contralTimeBox;
    },
    select: function select() {
      return this.$parent.editorData.attendShiftList;
    }
  },
  watch: {
    chechCardTimeOptions: {
      handler: function handler(val) {
        this.visible2 = val.show;
        this.editorType = val.editorType;
        if (val.editorType) {
          this.attendCycleList = val.obj;
        } else {
          this.attendCycleList = {
            cycleName: "",
            // 周期名称
            cycleDays: [{
              daysName: "第1天",
              // 第几天
              shiftName: "休息" // 班次名称
            }]
          };
        }
      }
    }
  },

  methods: {
    /* 增加 */addCycle: function addCycle(item) {
      this.attendCycleList.cycleDays.push({
        daysName: "\u7B2C".concat(item.length + 1, "\u5929"),
        // 第几天
        shiftName: "休息" // 班次名称
      });
    },
    /* 删除 */deleteCycle: function deleteCycle(item, arr) {
      arr = arr.pop();
    },
    /* 添加周期 */handleOk2: function handleOk2() {
      var _this = this;
      this.form2.validateFields(function (err, values) {
        if (!err) {
          _this.attendCycleList.cycleName = values.cycleName;
          if (_this.editorType) {
            _this.$parent.editorData.attendCycleList.filter(function (val) {
              if (val.cycleName === _this.attendCycleList.cycleName) {
                return val = _this.attendCycleList;
              }
            });
          } else {
            _this.$parent.editorData.attendCycleList.push(_this.attendCycleList);
          }
          _this.visible2 = false;
        }
      });
    }
  }
};