import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      destroyOnClose: "",
      width: 800,
      title: "添加班次"
    },
    on: {
      ok: _vm.handleOk
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      },
      label: "班次名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        initialValue: _vm.attendShiftList.name,
        rules: [{
          required: true,
          message: "名称由1~8个中文、英文、数字组成"
        }]
      }],
      expression: "[\n            'name',\n            {\n              initialValue: attendShiftList.name,\n              rules: [\n                {\n                  required: true,\n                  message: '名称由1~8个中文、英文、数字组成'\n                }\n              ]\n            }\n          ]"
    }],
    staticStyle: {
      width: "249px"
    },
    attrs: {
      placeholder: "例如'早班', 不能超过8个字符"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      },
      label: "打卡时间"
    }
  }, _vm._l(_vm.attendShiftList.attendPeriodList, function (freeTime, f) {
    return _c("div", {
      key: f
    }, [_c("div", [_vm._v(" 上班 "), _c("a-time-picker", {
      attrs: {
        format: "HH:mm"
      },
      model: {
        value: freeTime.startPeriod,
        callback: function callback($$v) {
          _vm.$set(freeTime, "startPeriod", $$v);
        },
        expression: "freeTime.startPeriod"
      }
    }), _vm._v(" — 下班 "), _c("a-time-picker", {
      attrs: {
        format: "HH:mm"
      },
      model: {
        value: freeTime.endPeriod,
        callback: function callback($$v) {
          _vm.$set(freeTime, "endPeriod", $$v);
        },
        expression: "freeTime.endPeriod"
      }
    }), _vm.attendShiftList.attendPeriodList.length > 1 ? _c("span", {
      staticClass: "mg_l10 cursor tc-theme",
      on: {
        click: function click($event) {
          return _vm.deleteNewFreeTime(f, _vm.attendShiftList.attendPeriodList);
        }
      }
    }, [_vm._v("删除")]) : _vm._e(), _vm.attendShiftList.attendPeriodList.length <= 3 && f == _vm.currentIndex ? _c("span", {
      staticClass: "mg_l10 cursor tc-theme",
      on: {
        click: function click($event) {
          return _vm.addNewFreeTime(_vm.attendShiftList.attendPeriodList);
        }
      }
    }, [_vm._v("新增时段")]) : _vm._e()], 1), _vm.attendShiftList.attendPeriodList.length == 1 ? _c("div", [_c("a-checkbox", [_vm._v("休息时间")]), _c("span", {
      staticClass: "sub_tit"
    }, [_vm._v("休息时间内不计算工作时长")]), _c("div", [_vm._v(" 开始 "), _c("a-time-picker", {
      attrs: {
        format: "HH:mm"
      },
      model: {
        value: _vm.attendShiftList.resetPeriod.startPeriod,
        callback: function callback($$v) {
          _vm.$set(_vm.attendShiftList.resetPeriod, "startPeriod", $$v);
        },
        expression: "attendShiftList.resetPeriod.startPeriod"
      }
    }), _vm._v(" — 结束 "), _c("a-time-picker", {
      attrs: {
        format: "HH:mm"
      },
      model: {
        value: _vm.attendShiftList.resetPeriod.endPeriod,
        callback: function callback($$v) {
          _vm.$set(_vm.attendShiftList.resetPeriod, "endPeriod", $$v);
        },
        expression: "attendShiftList.resetPeriod.endPeriod"
      }
    })], 1)], 1) : _vm._e()]);
  }), 0), _c("a-checkbox", {
    model: {
      value: _vm.attendShiftList.isAdvanc,
      callback: function callback($$v) {
        _vm.$set(_vm.attendShiftList, "isAdvanc", $$v);
      },
      expression: "attendShiftList.isAdvanc"
    }
  }, [_vm._v("高级设置")]), _vm.attendShiftList.isAdvanc ? [_c("a-form-item", {
    attrs: {
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      },
      label: "弹性时间"
    }
  }, [_c("a-checkbox", {
    model: {
      value: _vm.attendShiftList.isAllowedLateEarly,
      callback: function callback($$v) {
        _vm.$set(_vm.attendShiftList, "isAllowedLateEarly", $$v);
      },
      expression: "attendShiftList.isAllowedLateEarly"
    }
  }, [_vm._v(" 允许迟到早退 ")]), _vm.attendShiftList.isAllowedLateEarly ? _c("div", [_c("span", {
    staticClass: "sub_tit mg_r10"
  }, [_vm._v("上班允许迟到")]), _c("a-select", {
    staticStyle: {
      width: "120px"
    },
    model: {
      value: _vm.attendShiftList.lateMinute,
      callback: function callback($$v) {
        _vm.$set(_vm.attendShiftList, "lateMinute", $$v);
      },
      expression: "attendShiftList.lateMinute"
    }
  }, _vm._l(_vm.away, function (item) {
    return _c("a-select-option", {
      key: item.key,
      attrs: {
        value: item.key
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 1), _c("span", {
    staticClass: "sub_tit mg_r10 mg_l10"
  }, [_vm._v(", 下班允许早退")]), _c("a-select", {
    staticStyle: {
      width: "120px"
    },
    model: {
      value: _vm.attendShiftList.earlyMinute,
      callback: function callback($$v) {
        _vm.$set(_vm.attendShiftList, "earlyMinute", $$v);
      },
      expression: "attendShiftList.earlyMinute"
    }
  }, _vm._l(_vm.away, function (item) {
    return _c("a-select-option", {
      key: item.key,
      attrs: {
        value: item.key
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 1)], 1) : _vm._e(), _c("div", [_c("a-checkbox", {
    model: {
      value: _vm.attendShiftList.isAllowLateLate,
      callback: function callback($$v) {
        _vm.$set(_vm.attendShiftList, "isAllowLateLate", $$v);
      },
      expression: "attendShiftList.isAllowLateLate"
    }
  }, [_vm._v(" 允许晚走晚到 ")])], 1), _vm.attendShiftList.isAllowLateLate ? _c("div", [_c("span", {
    staticClass: "sub_tit mg_r10"
  }, [_vm._v("下班晚走")]), _c("a-select", {
    staticStyle: {
      width: "120px"
    },
    model: {
      value: _vm.attendShiftList.offWorkLateMinute,
      callback: function callback($$v) {
        _vm.$set(_vm.attendShiftList, "offWorkLateMinute", $$v);
      },
      expression: "attendShiftList.offWorkLateMinute"
    }
  }, _vm._l(_vm.away, function (item) {
    return _c("a-select-option", {
      key: item.key,
      attrs: {
        value: item.key
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 1), _c("span", {
    staticClass: "sub_tit mg_r10 mg_l10"
  }, [_vm._v("，次日可晚到")]), _c("a-select", {
    staticStyle: {
      width: "120px"
    },
    model: {
      value: _vm.attendShiftList.onWorkLateMinute,
      callback: function callback($$v) {
        _vm.$set(_vm.attendShiftList, "onWorkLateMinute", $$v);
      },
      expression: "attendShiftList.onWorkLateMinute"
    }
  }, _vm._l(_vm.away, function (item) {
    return _c("a-select-option", {
      key: item.key,
      attrs: {
        value: item.key
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 1)], 1) : _vm._e()], 1), _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      },
      label: "打卡时间限制"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "120px"
    },
    model: {
      value: _vm.attendShiftList.earliestPunchTime,
      callback: function callback($$v) {
        _vm.$set(_vm.attendShiftList, "earliestPunchTime", $$v);
      },
      expression: "attendShiftList.earliestPunchTime"
    }
  }, _vm._l(_vm.contralTime, function (item) {
    return _c("a-select-option", {
      key: item.key,
      attrs: {
        value: item.key
      }
    }, [_vm._v(_vm._s(item.value))]);
  }), 1), _c("span", {
    staticClass: "sub_tit mg_l10 mg_r10"
  }, [_vm._v("可打上班卡，")]), _c("a-select", {
    staticStyle: {
      width: "120px"
    },
    model: {
      value: _vm.attendShiftList.latestPunchTime,
      callback: function callback($$v) {
        _vm.$set(_vm.attendShiftList, "latestPunchTime", $$v);
      },
      expression: "attendShiftList.latestPunchTime"
    }
  }, _vm._l(_vm.contralTime, function (item) {
    return _c("a-select-option", {
      key: item.key,
      attrs: {
        value: item.key
      }
    }, [_vm._v(_vm._s(item.value))]);
  }), 1), _c("span", {
    staticClass: "sub_tit mg_r10 mg_l10"
  }, [_vm._v("可打下班卡")])], 1), _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      },
      label: "下班"
    }
  }, [_c("a-checkbox", {
    model: {
      value: _vm.attendShiftList.afterWorkNeedPunch,
      callback: function callback($$v) {
        _vm.$set(_vm.attendShiftList, "afterWorkNeedPunch", $$v);
      },
      expression: "attendShiftList.afterWorkNeedPunch"
    }
  }, [_vm._v(" 下班不需要打卡 ")])], 1)] : _vm._e()], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };