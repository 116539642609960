import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "payslip-detail"
  }, [_c("a-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", [_c("a-button", {
    attrs: {
      icon: "left",
      type: "link"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")])], 1), _c("a-col", {
    staticClass: "full ta-center fs16"
  }, [_c("strong", [_vm._v(_vm._s(_vm.tabs.name))])])], 1), _c("a-row", {
    staticClass: "salary",
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    staticClass: "item",
    attrs: {
      span: 10
    }
  }, [_c("a-row", {
    ref: "box",
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.getItem.apply(null, arguments);
      }
    }
  }, [_c("a-col", {
    attrs: {
      span: 7
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "primary"
  }, [_vm._v("总共")])]), _c("a-row", {
    staticClass: "primary"
  }, [_c("a-statistic", {
    attrs: {
      value: _vm.tabs.numAll || 0
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function fn() {
        return [_vm._v("人")];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 7
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("公司社保合计")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      precision: 2,
      value: _vm.tabs.companySocAmount || 0
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function fn() {
        return [_vm._v("￥")];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 7
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("公司公积金合计")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      precision: 2,
      value: _vm.tabs.companyPfAmount || 0
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function fn() {
        return [_vm._v("￥")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c("a-col", {
    staticClass: "item",
    attrs: {
      span: 8
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 10
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("个人社保合计")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      precision: 2,
      value: _vm.tabs.personalSocAmount || 0
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function fn() {
        return [_vm._v("￥")];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 10
    }
  }, [_c("a-row", {
    staticClass: "ove"
  }, [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("个人公积金合计")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      precision: 2,
      value: _vm.tabs.personalPfAmount || 0
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function fn() {
        return [_vm._v("￥")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c("a-col", {
    staticClass: "item",
    attrs: {
      span: 4
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("总合计预缴金额")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      precision: 2,
      value: _vm.tabs.allAmount || 0
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function fn() {
        return [_vm._v("￥")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c("a-row", {
    staticClass: "top"
  }, [_c("a-tabs", {
    attrs: {
      "default-active-key": _vm.defaultKey
    },
    on: {
      change: _vm.changeTab
    }
  }, [_c("a-tab-pane", {
    key: "all",
    attrs: {
      tab: "\u603B\u6D3E\u5355".concat(_vm.tabs.numAll || 0, "\u4EBA")
    }
  }), _c("a-tab-pane", {
    key: 1,
    attrs: {
      tab: "\u5DF2\u5B8C\u6210".concat(_vm.tabs.numHasFinish || 0, "\u4EBA")
    }
  }), _c("a-tab-pane", {
    key: 0,
    attrs: {
      tab: "\u672A\u5B8C\u6210".concat(_vm.tabs.numNotFinish || 0, "\u4EBA")
    }
  }), _c("a-tab-pane", {
    key: 2,
    attrs: {
      tab: "\u5F02\u5E38".concat(_vm.tabs.numError || 0, "\u4EBA")
    }
  })], 1), _c("a-button", {
    staticClass: "pos"
  }, [_vm._v("查看统计分析")])], 1), _c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "搜索姓名"
    },
    on: {
      search: _vm.onSearchName
    }
  }), _c("table-detail", {
    attrs: {
      options: _vm.allOptions
    },
    on: {
      change: _vm.handleTable
    }
  }), _c("feed-back", {
    attrs: {
      visible: _vm.feedVisible,
      options: _vm.feedOptions
    },
    on: {
      change: _vm.handleDetail
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };