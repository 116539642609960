import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.fixed.js";
import "core-js/modules/es.array.push.js";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
import { getToken } from "xqjr-module-auth";
var mixin = new UploadMixin();
var columnDialogDetailguding = [{
  title: "错误信息",
  dataIndex: "errorInfo",
  key: "errorInfo",
  scopedSlots: {
    customRender: "errorInfo"
  },
  align: "center",
  width: 160,
  ellipsis: true,
  fixed: "left"
}, {
  title: "姓名",
  dataIndex: "empName",
  scopedSlots: {
    customRender: "empName"
  },
  align: "center",
  ellipsis: true,
  key: "empName",
  width: 140
}, {
  title: "身份证号",
  dataIndex: "idCard",
  scopedSlots: {
    customRender: "idCard"
  },
  align: "center",
  ellipsis: true,
  key: "idCard",
  width: 200
}, {
  title: "离职原因",
  dataIndex: "leaveOfficeReason",
  scopedSlots: {
    customRender: "leaveOfficeReason"
  },
  align: "center",
  ellipsis: true,
  key: "leaveOfficeReason",
  width: 170
}, {
  title: "离职日期",
  dataIndex: "leaveOfficeDate",
  scopedSlots: {
    customRender: "leaveOfficeDate"
  },
  align: "center",
  ellipsis: true,
  key: "leaveOfficeDate",
  width: 140
}, {
  title: "离职备注",
  dataIndex: "remark",
  scopedSlots: {
    customRender: "remark"
  },
  align: "center",
  ellipsis: true,
  key: "remark",
  width: 170
}, {
  title: "是否社保减员",
  dataIndex: "whetherSocDecrease",
  scopedSlots: {
    customRender: "whetherSocDecrease"
  },
  align: "center",
  ellipsis: true,
  key: "whetherSocDecrease",
  width: 130
}, {
  title: "社保减员年月",
  dataIndex: "stopSocDate",
  scopedSlots: {
    customRender: "stopSocDate"
  },
  align: "center",
  ellipsis: true,
  key: "stopSocDate",
  width: 140
}, {
  title: "是否医保减员",
  dataIndex: "whetherMeDecrease",
  scopedSlots: {
    customRender: "whetherMeDecrease"
  },
  align: "center",
  ellipsis: true,
  key: "whetherMeDecrease",
  width: 130
}, {
  title: "医保减员年月",
  dataIndex: "stopMeDate",
  scopedSlots: {
    customRender: "stopMeDate"
  },
  align: "center",
  ellipsis: true,
  key: "stopMeDate",
  width: 140
}, {
  title: "是否公积金减员",
  dataIndex: "whetherSealed",
  scopedSlots: {
    customRender: "whetherSealed"
  },
  align: "center",
  ellipsis: true,
  key: "whetherSealed",
  width: 130
}, {
  title: "公积金减员年月",
  dataIndex: "sealedDate",
  ellipsis: true,
  scopedSlots: {
    customRender: "sealedDate"
  },
  align: "center",
  key: "sealedDate",
  width: 140
}, {
  title: "服务费",
  dataIndex: "serviceCost",
  ellipsis: true,
  scopedSlots: {
    customRender: "serviceCost"
  },
  align: "center",
  key: "serviceCost",
  width: 140
}];
import axios from "axios";
import qs from "qs";
export default {
  mixins: [mixin],
  data: function data() {
    return {
      uploading: false,
      allCount: 0,
      errorCount: 0,
      successCount: 0,
      columnDialogDetailguding: columnDialogDetailguding,
      uploadImg: require("@/assets/img/uploadImg.png"),
      action: "",
      socFile: undefined,
      dialogFailVisible: false,
      dataDetail2: [],
      title: "",
      erroData: undefined
    };
  },
  created: function created() {
    console.log(this.$route);
    this.title = this.$route.query.title;
    this.dataDetail2 = [];
  },
  methods: {
    backHandler: function backHandler() {
      if (this.dataDetail2 && this.dataDetail2.length > 0) {
        this.dataDetail2 = [];
        this.columnDialogDetailguding = [];
        this.allCount = 0;
        this.errorCount = 0;
        this.successCount = 0;
        this.erroData = {};
      } else {
        this.goback();
      }
    },
    getLog: function getLog(item) {
      console.log(item.key);
    },
    handleLizhi: function handleLizhi() {
      this.dialogFailVisible = false;
      this.$router.go(-1);
    },
    handleCancel: function handleCancel() {
      this.dialogFailVisible = false;
    },
    ExportHandle: function ExportHandle() {
      this.handleImportChange();
    },
    getRed: function getRed(key, record) {
      if (record !== undefined && record.errorColumn !== null) {
        for (var i = 0; i < record.errorColumn.length; i++) {
          if (record.errorColumn[i] === key) {
            return true;
          }
        }
      }
      return false;
    },
    BatchGet: function BatchGet() {
      var _this = this;
      this.uploading = true;
      var url = "/hr/api/v1/empSocPlans/exportIncreaseErrorList";
      var data = {};
      if (this.title === "批量增员") {
        url = "/hr/api/v1/empSocPlans/exportIncreaseErrorList";
        data = this.erroData;
      } else if (this.title === "批量减员") {
        url = "/hr/api/v1/empSocPlans/exportDecreaseErrorList";
        data = this.erroData;
      }
      axios({
        url: url,
        data: data,
        method: "post",
        responseType: "blob",
        headers: {
          token: this.$store.state.auth.token,
          "Content-Type": "application/json"
        }
      }).then(function (res) {
        _this.uploading = false;
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "".concat(_this.$store.state.hr.companyName, "_") + _this.title + "错误数据" + ".xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleImportChange: function handleImportChange() {
      var _this2 = this;
      this.uploading = true;
      var url = "/hr/api/v1/empSocPlans/importIncrease";
      if (this.title === "批量增员") {
        url = "/hr/api/v1/empSocPlans/importIncrease";
      } else if (this.title === "批量减员") {
        url = "/hr/api/v1/empSocPlans/importDecrease";
      }
      var tdata = new FormData();
      tdata.append("companyId", this.$store.state.hr.companyId);
      tdata.append("file", this.socFile);
      this.$request({
        url: url,
        method: "post",
        data: tdata
      }).then(function (res) {
        _this2.uploading = false;
        if (res.data) {
          _this2.erroData = JSON.parse(JSON.stringify(res.data));
          if (_this2.title === "批量增员") {
            _this2.allCount = res.data.allCount;
            _this2.errorCount = res.data.errorCount;
            _this2.successCount = res.data.successCount;
            if (res.data.headList) {
              _this2.columnDialogDetailguding = [];
              res.data.headList.map(function (item) {
                var v = {
                  title: item,
                  dataIndex: item,
                  key: item,
                  scopedSlots: {
                    customRender: item
                  },
                  align: "center",
                  width: 160,
                  ellipsis: true
                };
                if (v.title === "姓名") {
                  v.fixed = "left";
                }
                _this2.columnDialogDetailguding.push(v);
              });
            }
            if (res.data.errorList) {
              _this2.dataDetail2 = [];
              res.data.errorList.map(function (item) {
                var r = item.excelDate;
                if (item.errorInfo) {
                  r.errorInfo = item.errorInfo;
                }
                _this2.dataDetail2.push(r);
              });
            }
            if (_this2.dataDetail2 == null || _this2.dataDetail2.length === 0) {
              _this2.dialogFailVisible = true;
            }
          } else if (_this2.title === "批量减员") {
            _this2.allCount = res.data.allCount;
            _this2.errorCount = res.data.errorCount;
            _this2.successCount = res.data.successCount;
            res.data.headList = [{
              key: "name",
              value: "姓名"
            }, {
              key: "idCard",
              value: "证件号码"
            }, {
              key: "whetherSocDecrease",
              value: "是否社保减员"
            }, {
              key: "stopSocDate",
              value: "社保减员年月"
            }, {
              key: "whetherMeDecrease",
              value: "是否医保减员"
            }, {
              key: "stopMeDate",
              value: "医保减员年月"
            }, {
              key: "whetherSealed",
              value: "是否公积金减员"
            }, {
              key: "sealedDate",
              value: "公积金减员年月"
            }, {
              key: "serviceCost",
              value: "服务费"
            }];
            if (res.data.headList) {
              _this2.columnDialogDetailguding = [];
              res.data.headList.map(function (item) {
                var v = {
                  title: item.value,
                  dataIndex: item.key,
                  key: item.key,
                  scopedSlots: {
                    customRender: item.key
                  },
                  align: "center",
                  width: 160,
                  ellipsis: true
                };
                if (v.key === "name") {
                  v.fixed = "left";
                }
                _this2.columnDialogDetailguding.push(v);
              });
            }
            if (res.data.errorList) {
              res.data.errorList.map(function (item) {
                if (item.whetherMeDecrease) {
                  item.whetherMeDecrease = "是";
                } else {
                  item.whetherMeDecrease = "否";
                }
                if (item.whetherSealed) {
                  item.whetherSealed = "是";
                } else {
                  item.whetherSealed = "否";
                }
                if (item.whetherSocDecrease) {
                  item.whetherSocDecrease = "是";
                } else {
                  item.whetherSocDecrease = "否";
                }
              });
              _this2.dataDetail2 = res.data.errorList;
            }
            if (_this2.dataDetail2 == null || _this2.dataDetail2.length === 0) {
              _this2.dialogFailVisible = true;
            }
          }
        }
      }).catch(function (err) {
        _this2.uploading = false;
        console.log("debug log --> ", err);
      });
    },
    getErro: function getErro(item, tkey) {
      var v = false;
      for (var key in item) {
        if (key === tkey) {
          v = true;
        }
      }
      return v;
    },
    getErroInfo: function getErroInfo(item, tkey) {
      for (var key in item) {
        if (key === tkey) {
          return item[key];
        }
      }
    },
    beforeUpload1: function beforeUpload1(_ref) {
      var file = _ref.file;
      this.socFile = file;
      if (this.socFile) {
        // this.isDaoru = true;
        this.handleImportChange();
      }
    },
    // 下载模板
    download: function download() {
      var _this3 = this;
      var token = this.$store.state.auth.token;
      var params = {};
      if (this.title === "批量增员") {
        params = {
          templateName: "importIncreaseTemplate.xlsx"
        };
      } else if (this.title === "批量减员") {
        params = {
          templateName: "importDecreaseTemplate.xlsx"
        };
      }
      axios({
        url: " /hr/api/v1/excel/template/download",
        params: params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "multipart/form-data"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", _this3.title + "模板.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    }
  }
};