import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import { pageUserHasRoleInCompany } from "./api";
import { mapState, mapGetters } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "添加公司管理角色"
    },
    year: {
      type: String,
      default: ""
    },
    month: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    },
    number: {
      type: Number,
      default: 0
    },
    templateId: {
      type: Number,
      default: 0
    },
    defaultSelectedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectedList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isOnlineOrLeave: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      spinning: false,
      importDataLength: 0,
      mode: "1",
      treeData: [],
      selectedListTemp: [],
      isAllSelected: false,
      selectedKeysBeforeSearch: [],
      postTypeValue: 3,
      pageSizeOptions: ["10", "20", "50", "100"],
      pageSize: 50,
      current: 1,
      total: 50
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(["companyAdminList"])), mapState(["hr"])), {}, {
    postTypeList: function postTypeList() {
      return this.$store.state.enums.postTypeLst;
    }
  }),
  watch: {
    show: function show(val) {
      if (val) {
        this.selectedListTemp = [];
        this.treeData = [];
        this.spinning = false;
        this.current = 1;
        this.postTypeValue = 3;
        this.mode = "1";
        this.importDataLength = this.defaultSelectedKeys.length;
        this.getSelectDepTree();
      }
    },
    month: function month(val) {}
  },
  mounted: function mounted() {},
  methods: {
    // 分页变化
    onPageChange: function onPageChange(pageNumber) {
      this.current = pageNumber;
      this.getSelectDepTree();
    },
    postTypeClick: function postTypeClick(value) {
      this.postTypeValue = value;
      this.isAllSelected = false;
      this.current = 1;
      this.getSelectDepTree();
    },
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = 1;
      this.getSelectDepTree();
    },
    choseAll: function choseAll(data) {
      this.isAllSelected = !this.isAllSelected;
      var that = this;
      this.spinning = true;
      for (var j = 0, len = data.length; j < len; j++) {
        data[j].isSelected = that.isAllSelected;
        that.ergodicAll(data[j]);
      }
      setTimeout(function () {
        that.spinning = false;
      }, "10");
    },
    chose: function chose(item, data) {
      item.isSelected = !item.isSelected;
      this.onChange(item);
    },
    onChangeTab: function onChangeTab(e) {
      this.isAllSelected = false;
      this.current = 1;
      this.mode = e;
      this.getSelectDepTree();
    },
    onChange: function onChange(item) {
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.ergodic(item);
        that.spinning = false;
      }, "10");
    },
    ergodic: function ergodic(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
          this.isAllSelected = false;
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
          var count = this.treeData.length;
          var allSelect = true;
          for (var i = 0; i < count; i++) {
            if (!this.treeData[i].isSelected) {
              allSelect = false;
              break;
            }
          }
          this.isAllSelected = allSelect;
        }
      }
    },
    ergodicAll: function ergodicAll(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
        }
      }
    },
    // 获取部门树
    getSelectDepTree: function getSelectDepTree(query) {
      var _this = this;
      var params = {
        subjectType: "company"
      };
      if (query) {
        params.name = query;
      }
      this.spinning = true;
      params["pageNo"] = this.current;
      params["pageSize"] = this.pageSize;
      pageUserHasRoleInCompany(params, this.hr.companyId).then(function (res) {
        if (res.data === null) {
          _this.$message.success("暂无数据");
          _this.treeData = [];
          _this.total = 0;
          _this.isAllSelected = false;
        } else {
          _this.treeData = _this.formatCompanyData(res.data.entities);
          _this.total = res.data.entityCount;
          var count = res.data.entityCount;
          var allSelect = true;
          if (count !== 0) {
            for (var i = 0; i < count; i++) {
              if (!_this.treeData[i].isSelected) {
                allSelect = false;
                break;
              }
            }
            _this.isAllSelected = allSelect;
          } else {
            _this.isAllSelected = false;
          }
        }
        _this.$nextTick(function () {
          _this.spinning = false;
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    formatCompanyData: function formatCompanyData(company) {
      var parent = [];
      for (var key = 0; key < company.length; key++) {
        var selected = false;
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === company[key].id;
        }) !== -1) {
          selected = true;
        }
        var emp = {
          id: company[key].id,
          name: company[key].name + "(" + company[key].roleName + ")",
          phone: company[key].phone,
          idCard: company[key].idCard,
          depName: company[key].depName,
          positionName: company[key].positionName,
          isSelected: selected
        };
        for (var i = 0; i < this.importDataLength; i++) {
          if (this.defaultSelectedKeys[i].id === emp.id) {
            emp.isSelected = true;
            this.selectedListTemp.push(emp);
            break;
          }
        }
        parent.push(emp);
      }
      this.importDataLength = 0; // 清空传入的默认值
      return parent;
    },
    searchPeople: function searchPeople(query) {
      this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
        return item.id;
      });
      this.current = 1;
      this.getSelectDepTree(query);
    },
    changePeople: function changePeople(e) {
      if (e.target.value === "") {
        this.current = 1;
        this.getSelectDepTree();
        this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
          return item.id;
        });
      }
    },
    deleteItem: function deleteItem(item) {
      item.isSelected = false;
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.ergodic(item);
        that.spinning = false;
      }, "10");
    },
    ok: function ok() {
      this.$emit("update:selectedList", this.selectedListTemp);
      this.$emit("update:selectThree", this.selectedListTemp.slice(0, 3).map(function (item) {
        return item.name;
      }).join(","));
      this.$emit("update:show", false);
      this.$emit("change", this.selectedListTemp);
    },
    cancel: function cancel() {
      this.$emit("update:show", false);
    }
  }
};