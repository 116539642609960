import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "z_notice"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("消息通知")]), _c("div", {
    staticClass: "z_notices"
  }, [_c("Title", [_vm._v("消息通知")]), _c("div", {
    staticStyle: {
      height: "12px"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "选择用工单位"
    },
    on: {
      change: _vm.selectChangge
    },
    model: {
      value: _vm.companyId,
      callback: function callback($$v) {
        _vm.companyId = $$v;
      },
      expression: "companyId"
    }
  }, _vm._l(_vm.hr.companyList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      staticClass: "select-text",
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("div", {
    staticStyle: {
      height: "24px"
    }
  }), _c("a-tabs", {
    staticStyle: {
      height: "100%"
    },
    attrs: {
      type: "card",
      size: "small",
      tabBarGutter: 10
    },
    on: {
      change: _vm.tabChange
    }
  }, [_vm._l(_vm.TopData, function (item, index) {
    return _c("a-tab-pane", {
      key: index,
      attrs: {
        tab: item.name
      }
    }, [_c("a-layout", {
      staticClass: "mt24"
    }, [_c("a-layout-sider", {
      staticStyle: {
        "border-right": "1px solid #e8e8e8"
      },
      attrs: {
        width: 220
      }
    }, [_c("a-menu", {
      staticClass: "groupleft"
    }, _vm._l(_vm.topSubData, function (item) {
      return _c("a-menu-item", {
        key: item.id,
        staticStyle: {
          "border-bottom": "1px solid #e8e8e8",
          margin: "0 10px"
        },
        on: {
          click: function click($event) {
            return _vm.changeStatus(item.id);
          }
        }
      }, [_c("a-row", {
        attrs: {
          type: "flex",
          align: "middle"
        }
      }, [_c("a-col", {
        staticClass: "groupname"
      }, [_vm._v(_vm._s(item.name) + "(" + _vm._s(item.count || item.counts) + ")")])], 1)], 1);
    }), 1)], 1), _c("a-layout", [_c("div", {
      staticClass: "newslist"
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "space-between",
        "border-bottom": "1px solid #e8e8e8",
        "margin-top": "10px",
        "margin-left": "10px"
      }
    }, [_c("div", {
      staticClass: "footer-overlay"
    }, [_c("a-button", {
      staticStyle: {
        color: "#999999"
      },
      attrs: {
        type: "link"
      }
    }, [_vm._v(_vm._s(_vm.NoReadNum) + "条消息未读")]), _c("a-divider", {
      attrs: {
        type: "vertical"
      }
    }), _c("a-button", {
      attrs: {
        type: "link"
      },
      on: {
        click: _vm.allReadSign
      }
    }, [_vm._v("全部标为已读")])], 1), _c("div", [_vm.groupid !== 22 ? _c("div", {
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "flex-direction": "row"
      }
    }, [_c("xq-icon", {
      staticClass: "primary_color",
      staticStyle: {
        width: "14px",
        height: "14px",
        "margin-top": "4px",
        "margin-left": "15px"
      },
      attrs: {
        type: "icon-tishi"
      }
    }), _c("div", {
      staticStyle: {
        "font-size": "12px",
        color: "#767885",
        "margin-top": "2px",
        "margin-left": "5px",
        "margin-right": "10px"
      }
    }, [_vm._v(" 点击消息可直接跳转到相应页面处理 ")])], 1) : _vm._e()])]), _c("div", {
      staticStyle: {
        height: "62vh"
      }
    }, [_c("a-empty", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.noticelist.length === 0,
        expression: "noticelist.length === 0"
      }],
      staticStyle: {
        "margin-top": "80px"
      }
    }), _vm._l(_vm.noticelist, function (item, index) {
      return _c("li", {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.noticelist.length > 0,
          expression: "noticelist.length > 0"
        }],
        key: index,
        staticStyle: {
          padding: "0 20px",
          cursor: "pointer"
        },
        on: {
          click: function click($event) {
            return _vm.read(item);
          }
        }
      }, [_c("div", {
        staticClass: "new-top"
      }, [_c("div", {
        staticStyle: {
          display: "flex",
          "flex-flow": "row"
        }
      }, [item.readStatus ? _c("div", {
        staticClass: "bac-6D69F71A-radius-2px-gray font-6A66F6-10px-gray",
        staticStyle: {
          "min-width": "40px",
          "margin-right": "10px",
          "padding-left": "5px",
          "padding-right": "8px",
          "padding-top": "5px",
          "padding-bottom": "5px"
        }
      }, [_vm._v(" " + _vm._s(item.title) + " ")]) : _c("div", {
        staticClass: "bac-6D69F71A-radius-2px font-6A66F6-10px",
        staticStyle: {
          "min-width": "40px",
          "margin-right": "10px",
          "padding-left": "5px",
          "padding-right": "8px",
          "padding-top": "5px",
          "padding-bottom": "5px"
        }
      }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c("span", {
        staticClass: "modalitys_title",
        class: item.readStatus ? "modalitys_active" : ""
      }, [_vm._v(_vm._s(item.companyName))])]), _c("div", [_vm._v(_vm._s(item.gmtCreate ? _vm.formatDateAll(item.gmtCreate, "d") : "--"))])]), _c("div", {
        staticClass: "new-btm"
      }, [_c("div", {
        staticClass: "modalitys_title",
        class: item.readStatus ? "modalitys_active" : ""
      }, [_vm._v(_vm._s(item.content))])])]);
    })], 2)])])], 1)], 1);
  }), !_vm.isJF ? _c("a-tab-pane", {
    key: "10086",
    attrs: {
      tab: "备忘录"
    }
  }, [_c("Today", {
    attrs: {
      companyIdVal: _vm.companyId,
      isModule: 1
    }
  })], 1) : _vm._e()], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };