var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper"
  }, [_c("div", {
    class: {
      "fixed-header": _vm.fixed_header
    }
  }, [_c("navbar", {
    attrs: {
      aMenu: _vm.adminMenu
    }
  })], 1), _c("div", {
    staticClass: "flex"
  }, [_vm.showSidebar ? _c("sidebar", {
    attrs: {
      aMenu: _vm.adminMenu
    }
  }) : _vm._e(), _c("app-main")], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };