import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.push.js";
import moment from "moment";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
import { mapGetters } from "vuex";
import { selectPageByEmpInfoReq, exportPaperContractReq, downloadAttachmentReq, empPaperContractsDeleteReq, empPaperContractsDeleteBatchReq } from "./api";
import { mapState } from "vuex";
import { isContainByString } from "@/utils/index";
import { buildColumns, enumerateList, customRenderContractType, customRenderMoment } from "./funcs";
import SummaryBarPaper from "./components/summaryBar_paper.vue";
export default {
  components: {
    SummaryBarPaper: SummaryBarPaper
  },
  mixins: [mixin],
  props: {
    mode: {
      type: String,
      default: "company"
    }
  },
  data: function data() {
    var columns = buildColumns("paper", this.mode === "overview");
    return {
      isContainByString: isContainByString,
      selectedRowList: [],
      columns: columns,
      enumerateList: enumerateList,
      searchForm: this.$form.createForm(this),
      companyName: this.$store.state.hr.companyName,
      updateForm: this.$form.createForm(this, {
        name: "updateForm"
      }),
      selectedRowKeys: [],
      contractList: [],
      updateVisible: false,
      detailVisible: false,
      confirmLoading: false,
      deleteVisible: false,
      currentContract: {},
      eleContractDetail: {},
      eleContractDetailShow: false,
      selectTypeIndex: 4,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      spinning: false,
      selectedHead: [],
      updateNameForm: this.$form.createForm(this, {}),
      updateNameVisible: false,
      confirmNameLoading: false,
      putUpdateContractNameId: "",
      // 搜索条件
      contractNameOrNoOrSignNo: "",
      idCard: "",
      fileType: undefined,
      empStatus: undefined,
      publishDateRange: null,
      endDateRange: null,
      signFinishDateRange: null,
      tempSubject: undefined,
      loading_delete: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(["permissions_site"])), mapState(["auth", "site", "hr"])), {}, {
    siteName: function siteName() {
      return this.auth.siteName;
    },
    targetIds: function targetIds() {
      if (this.mode === "company") {
        return [this.hr.companyId];
      } else {
        if (this.site.selectAllIds && this.site.selectAllIds !== "-1") {
          return Array.from(new Set(this.site.selectAllIds.split(",")));
        }
        return [];
      }
    },
    tempSubjectOptions: function tempSubjectOptions() {
      var _this = this;
      return this.targetIds.map(function (item) {
        return {
          value: item,
          label: _this.hr.companyMap[item] ? _this.hr.companyMap[item].name : undefined
        };
      }).filter(function (item) {
        return item.label;
      });
    }
  }),
  watch: {
    $route: {
      handler: function handler(to, from) {
        var _this2 = this;
        if (this.$route.query.immediatelyMaturityCountId) {
          // 消息推送进来
          this.$nextTick(function () {
            _this2.searchForm.setFieldsValue({
              contractNameOrNoOrSignNo: _this2.$route.query.contractNameOrNoOrSignNo
            });
          });
          this.selectTypeIndex = Number(this.$route.query.immediatelyMaturityCountId);
          this.searchContract(this.$route.query.contractNameOrNoOrSignNo);
        } else {
          this.selectFunc(4);
        }
      },
      deep: true,
      immediate: true
    },
    targetIds: function targetIds() {
      this.tempSubject = undefined;
      this.selectFunc();
    }
  },
  created: function created() {},
  methods: {
    customRenderContractType: customRenderContractType,
    customRenderMoment: customRenderMoment,
    handleTableChange: function handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.loadContractList();
    },
    searchContract: function searchContract() {
      this.pagination.current = 1;
      this.loadContractList();
    },
    selectFunc: function selectFunc(typeIndex) {
      this.pagination.current = 1;
      this.selectedRowKeys = [];
      if (typeIndex || typeIndex === 0) {
        this.selectTypeIndex = typeIndex;
      }
      if (this.$refs.summaryBar) {
        this.$refs.summaryBar.loadData();
      }
      this.loadContractList();
    },
    loadContractList: function loadContractList() {
      var _this3 = this;
      if (this.spinning) {
        return false;
      }
      this.spinning = true;
      var params = _objectSpread(_objectSpread({}, this.buildParams()), {}, {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      });
      selectPageByEmpInfoReq(params).then(function (res) {
        _this3.spinning = false;
        _this3.contractList = res.data.entities ? res.data.entities : [];
        _this3.pagination.total = res.data && res.data.entityCount;
      }).catch(function (err) {
        _this3.spinning = false;
        console.log(err);
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys, tList) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowList = tList;
    },
    downloadContract: function downloadContract(record) {
      var _this4 = this;
      if (!record.file) return false;
      this.spinning = true;
      downloadAttachmentReq({
        siteFileUrls: record.file.split(",")
      }).then(function (res) {
        _this4.spinning = false;
        if (res.data && res.data.type === "application/json") {
          var reader = new FileReader();
          reader.readAsText(res.data, "utf-8");
          reader.onload = function () {
            var resJson = JSON.parse(reader.result);
            _this4.$message.error(resJson.msg);
          };
        } else {
          var rspHeaders = res.headers;
          var blob = new Blob([res.data], {
            type: "application/octet-stream"
          });
          var link = document.createElement("a");
          try {
            link.download = decodeURI(rspHeaders["content-disposition"].split(";")[1].split("=")[1].replaceAll('"', ""));
          } catch (err) {
            console.log(err);
          }
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this4.spinning = false;
      });
    },
    deleteContract: function deleteContract(record) {
      this.currentNotice = record;
      this.deleteVisible = true;
      if (record) {
        this.selectedRowKeys = [];
        this.selectedRowKeys.push(record.id);
      }
    },
    confirmDelete: function confirmDelete() {
      var _this5 = this;
      if (this.contractList.length === this.selectedRowKeys.length && this.pagination.current > 1) {
        this.pagination.current -= 1;
      }
      this.loading_delete = true;
      if (this.selectedRowKeys.length < 1) {
        empPaperContractsDeleteReq(this.currentNotice.id).then(function () {
          _this5.deleteVisible = false;
          _this5.loading_delete = false;
          _this5.$message.success("删除成功");
          if (_this5.$refs.summaryBar) {
            _this5.$refs.summaryBar.loadData();
          }
          _this5.loadContractList();
          _this5.selectedRowKeys = [];
        }).catch(function () {
          _this5.loading_delete = false;
          _this5.$message.error("删除失败");
        });
      } else {
        empPaperContractsDeleteBatchReq(this.selectedRowKeys).then(function () {
          _this5.deleteVisible = false;
          _this5.loading_delete = false;
          _this5.$message.success("删除成功");
          if (_this5.$refs.summaryBar) {
            _this5.$refs.summaryBar.loadData();
          }
          _this5.loadContractList();
          _this5.selectedRowKeys = [];
        }).catch(function () {
          _this5.loading_delete = false;
          _this5.$message.error("删除失败");
        });
      }
    },
    buildParams: function buildParams() {
      var params = {
        companyIdList: this.tempSubject ? [this.tempSubject] : this.targetIds,
        status: this.selectTypeIndex > 3 ? null : this.selectTypeIndex,
        userNameOrConNameOrPhone: this.contractNameOrNoOrSignNo,
        idCard: this.idCard,
        type: this.fileType,
        empStatus: this.empStatus,
        startTimeStart: this.publishDateRange && this.publishDateRange.length > 0 ? moment(this.publishDateRange[0]).format("YYYY-MM-DD 00:00:00") : null,
        startTimeEnd: this.publishDateRange && this.publishDateRange.length > 0 ? moment(this.publishDateRange[1]).format("YYYY-MM-DD 23:59:59") : null,
        endTimeStart: this.endDateRange && this.endDateRange.length > 0 ? moment(this.endDateRange[0]).format("YYYY-MM-DD 00:00:00") : null,
        endTimeEnd: this.endDateRange && this.endDateRange.length > 0 ? moment(this.endDateRange[1]).format("YYYY-MM-DD 23:59:59") : null,
        subscribeTimeStart: this.signFinishDateRange && this.signFinishDateRange.length > 0 ? moment(this.signFinishDateRange[0]).format("YYYY-MM-DD 00:00:00") : null,
        subscribeTimeEnd: this.signFinishDateRange && this.signFinishDateRange.length > 0 ? moment(this.signFinishDateRange[1]).format("YYYY-MM-DD 23:59:59") : null
      };
      if (this.selectTypeIndex === 5) {
        params["willExpire"] = true;
      }
      return params;
    },
    selectDownloadType: function selectDownloadType(i) {
      this.exportType = i;
      this.excel_export();
    },
    excel_export: function excel_export() {
      var _this6 = this;
      this.spinning = true;
      var contractExcelDTO = this.exportType === 1 ? {
        companyIdList: this.tempSubject ? [this.tempSubject] : this.targetIds,
        exportColumns: this.selectedHead,
        idList: this.selectedRowKeys
      } : _objectSpread(_objectSpread({}, this.buildParams()), {}, {
        exportColumns: this.selectedHead
      });
      exportPaperContractReq(contractExcelDTO).then(function (res) {
        var data = res.data;
        var url = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.href = url;
        link.download = "".concat(_this6.mode === "overview" ? _this6.siteName : _this6.companyName, "\u5408\u540C\u5217\u8868\u8BE6\u60C5.xlsx");
        link.click();
        _this6.spinning = false;
      }).catch(function (err) {
        console.log("error log --> ", err);
        _this6.spinning = false;
      });
    }
  }
};