import approvalpopup from "./approvalpopup.vue";
import { getApprovalStatus, getApprovalType, getApprovalStatusIcon, getApprovalStatusColor } from "../funcs";
export default {
  components: {
    approvalpopup: approvalpopup
  },
  data: function data() {
    return {
      createProcessList: []
    };
  },
  props: ["approvalVal"],
  watch: {
    approvalVal: {
      handler: function handler(val, oldval) {
        if (val) {
          this.createApprovalProcess(val);
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getApprovalStatus: getApprovalStatus,
    getApprovalStatusIcon: getApprovalStatusIcon,
    getApprovalStatusColor: getApprovalStatusColor,
    getApprovalType: getApprovalType,
    createApprovalProcess: function createApprovalProcess(val) {
      this.createProcessList = val;
    },
    typeOfArray: function typeOfArray(arr) {
      return arr instanceof Array;
    }
  }
};