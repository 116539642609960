import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import { getSelectEmpByDepId } from "@/api/department/department";
import { deleteEmpByDep } from "@/api/staff/staff";
import { getEmpListByDep, empDistribute } from "@/api/empDepRelation/empDepRelation";
import { mapState } from "vuex";
var table = [{
  title: "姓名",
  dataIndex: "name",
  align: "center"
}, {
  title: "岗位名称",
  dataIndex: "positionName",
  align: "center"
}, {
  title: "手机号码",
  dataIndex: "phone",
  align: "center"
}, {
  title: "直属部门",
  dataIndex: "depName",
  align: "center"
}, {
  title: "操作",
  dataIndex: "action",
  align: "center",
  scopedSlots: {
    customRender: "action"
  }
}];
var columns = [{
  title: "姓名",
  dataIndex: "name",
  align: "center"
}, {
  title: "手机号",
  dataIndex: "phone",
  align: "center"
}, {
  title: "岗位",
  dataIndex: "positionName",
  align: "center"
}, {
  title: "工号",
  dataIndex: "number",
  align: "center"
}, {
  title: "工作邮箱",
  dataIndex: "email",
  align: "center"
}];
var pagination = {
  pageSize: 20,
  total: 10,
  current: 1,
  showQuickJumper: true,
  showTotal: function showTotal(total) {
    return "\u5171 ".concat(total, " \u6761");
  },
  pageSizeOptions: ["10", "20", "50", "100"]
};
var pagination1 = {
  pageSize: 10,
  total: 0,
  size: "default",
  current: 1,
  showTotal: function showTotal(total) {
    return "\u5171 ".concat(total, " \u6761");
  },
  showQuickJumper: true,
  showSizeChanger: true,
  personalPeriod: null
};
export default {
  props: ["selectedId", "company", "userMun"],
  inject: ["reload"],
  data: function data() {
    return {
      loadingShow: true,
      cantUseAdd: true,
      searchDetail: "",
      pagination: pagination,
      table: table,
      columns: columns,
      visible: false,
      nameOrPhoneOrEmail: "",
      adjustButtonDisabled: false,
      selectedRowKeys: [],
      selectedMerber: [],
      empInfoList: [],
      empInfoIdList: [],
      data: [],
      pagination1: pagination1,
      onlyCurrentDep: "false"
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  watch: {
    company: {
      handler: function handler(val) {
        this.cantUseAdd = val;
      },
      immediate: true
    },
    selectedId: {
      handler: function handler(val, old) {
        if (val !== old) {
          this.pagination.current = pagination1.current = 1;
        }
        if (val) {
          this.initData(val);
        }
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {},
  methods: {
    getCheckboxProps: function getCheckboxProps() {
      return {
        disabled: true
      };
    },
    tableChange: function tableChange(pagination, filters, sorter) {
      this.pagination1.current = pagination.current;
      this.pagination1.pageSize = pagination.pageSize;
      this.initData(this.selectedId, this.searchDetail);
    },
    handleEmpInfoTableChange: function handleEmpInfoTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.EmpListByDep();
    },
    handleSelectChange: function handleSelectChange(e) {
      this.onlyCurrentDep = e;
      this.initData(this.selectedId);
    },
    showDialog: function showDialog() {
      this.visible = true;
      this.adjustButtonDisabled = true;
      this.pagination.current = 1;
      this.nameOrPhoneOrEmail = "";
      this.EmpListByDep();
    },
    cancel: function cancel() {
      this.visible = false;
    },
    adjustEmp: function adjustEmp() {
      var _this = this;
      var that = this;
      var data = {
        depId: this.selectedId,
        companyId: this.hr.companyId,
        empIdList: this.selectedMerber
      };
      empDistribute(data).then(function (res) {
        if (res.code === 200) {
          that.$message.success("添加成功");
          _this.visible = false;
          _this.initData(_this.selectedId);
          _this.$emit("hasChanged", true);
        }
      });
    },
    selectionChange: function selectionChange(selection) {
      this.selectedMerber = selection;
      this.adjustButtonDisabled = selection.length === 0;
    },
    selectEmp: function selectEmp() {
      this.pagination.current = 1;
      this.EmpListByDep();
    },
    EmpListByDep: function EmpListByDep() {
      var _this2 = this;
      var data = {
        companyId: this.hr.companyId,
        parentId: 0,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        nameOrPhoneOrEmail: this.nameOrPhoneOrEmail
      };
      this.loadingShow = true;
      getEmpListByDep(data).then(function (res) {
        if (res.data && res.data.entityCount) {
          _this2.pagination.total = res.data && res.data.entityCount;
        } else {
          _this2.pagination.total = 0;
        }
        _this2.empInfoList = _this2.getSubtract(res.data.entities, _this2.data);
        if (res.data.entities !== null) {
          for (var i = 0; i < res.data.entities.length; i++) {
            _this2.empInfoIdList.push(res.data.entities[i].userId);
          }
        }
        if (res.data.entities === null) {
          _this2.empInfoList = [];
        } else {
          _this2.empInfoList = res.data.entities;
        }
        _this2.loadingShow = false;
      });
    },
    getSubtract: function getSubtract(unionArr, subsetArr) {
      var new_tmp = [];
      if (unionArr !== null) {
        for (var i = 0; i < unionArr.length; i++) {
          var flag = true;
          for (var j = 0; j < subsetArr.length; j++) {
            if (unionArr[i].id === subsetArr[j].id) {
              flag = false;
            }
          }
          if (flag) {
            new_tmp.push(unionArr[i]);
          }
        }
      }
      return new_tmp;
    },
    initData: function initData(id, empName) {
      var _this3 = this;
      getSelectEmpByDepId({
        companyId: this.hr.companyId,
        empName: empName,
        depId: id,
        displayAll: this.onlyCurrentDep ? this.onlyCurrentDep : "false",
        pageNo: this.pagination1.current,
        pageSize: this.pagination1.pageSize
      }).then(function (_ref) {
        var data = _ref.data;
        if (data) {
          _this3.data = data.entities || [];
          _this3.pagination1.total = data && data.entityCount || 0;
        } else {
          _this3.data = [];
          _this3.pagination1.total = 0;
        }
        _this3.dataLength = data && data.entityCount || 0;
        _this3.$emit("dataLength", _this3.dataLength);
      });
    },
    dels: function dels() {
      var that = this;
      this.$confirm({
        title: "删除员工",
        content: "此操作将删除员工, 是否继续?",
        okText: "确认",
        cancelText: "取消",
        onOk: function onOk() {
          deleteEmpByDep(that.selectedId, that.selectedRowKeys).then(function (res) {
            that.$notification.open({
              title: "Success",
              message: "员工删除成功",
              type: "success",
              duration: 1
            });
            that.initData(that.selectedId);
            that.$emit("hasChanged", true);
            that.$emit("refush");
            that.selectedRowKeys = [];
          });
        },
        onCancel: function onCancel() {}
      });
    },
    del: function del(record) {
      var that = this;
      this.$confirm({
        title: "删除员工",
        content: "此操作将删除该员工, 是否继续?",
        okText: "确认",
        cancelText: "取消",
        onOk: function onOk() {
          deleteEmpByDep(that.selectedId, [record.id]).then(function (data) {
            if (data.success) {
              that.$notification["success"]({
                message: "删除成功"
              });
              that.initData(that.selectedId);
              that.$emit("hasChanged", true);
              that.$emit("refush");
              var index = that.selectedRowKeys.indexOf(record.id);
              if (index !== -1) that.selectedRowKeys.splice(index, 1);
            }
          });
        }
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onSearch: function onSearch(value) {
      this.searchDetail = value;
      this.initData(this.selectedId, value);
    }
  }
};