import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "setter-wrapper-outer"
  }, [_c("div", {
    staticClass: "setter-wrapper"
  }, [_c("a-form", [_c("a-form-item", {
    attrs: {
      label: "审批名称",
      required: true
    }
  }, [_c("a-input", {
    attrs: {
      value: _vm.value.typeName,
      placeholder: "请输入..."
    },
    on: {
      change: function change($event) {
        return _vm.onValueChange($event, "typeName");
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "选择分组",
      required: true
    }
  }, [_c("a-select", {
    attrs: {
      value: _vm.value.groupId,
      placeholder: "请选择..."
    },
    on: {
      change: function change($event) {
        return _vm.onValueChange($event, "groupId");
      }
    }
  }, _vm._l(_vm.groupList, function (item, i) {
    return _c("a-select-option", {
      key: i,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.groupName))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "选择类型",
      required: true
    }
  }, [_c("a-select", {
    attrs: {
      value: _vm.value.type,
      placeholder: "请选择..."
    },
    on: {
      change: function change($event) {
        return _vm.onValueChange($event, "type");
      }
    }
  }, _vm._l(_vm.typeList, function (item, i) {
    return _c("a-select-option", {
      key: i,
      attrs: {
        value: item.code
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "撤回方式"
    }
  }, [_c("a-select", {
    attrs: {
      value: _vm.value.revokeType,
      placeholder: "请选择..."
    },
    on: {
      change: function change($event) {
        return _vm.onValueChange($event, "revokeType");
      }
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 0
    }
  }, [_vm._v("无审批人操作发起人可以撤回")]), _c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("未完全通过时候允许撤回")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "审批发起"
    }
  }, [_c("a-select", {
    attrs: {
      value: _vm.value.isSysStart,
      placeholder: "请选择..."
    },
    on: {
      change: function change($event) {
        return _vm.onValueChange($event, "isSysStart");
      }
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("系统发起")]), _c("a-select-option", {
    attrs: {
      value: 0
    }
  }, [_vm._v("个人发起")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "模板图标"
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("img", {
    staticClass: "mg_r20",
    staticStyle: {
      width: "48px",
      height: "48px"
    },
    attrs: {
      src: _vm.value.icon,
      alt: "加载失败"
    }
  }), _c("a-button", {
    on: {
      click: function click($event) {
        _vm.showIcon = true;
      }
    }
  }, [_vm._v("选择图标")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "项目说明(最多100字)"
    }
  }, [_c("a-textarea", {
    attrs: {
      value: _vm.value.typeDesc,
      "max-length": 100,
      placeholder: "请输入内容"
    },
    on: {
      change: function change($event) {
        return _vm.onValueChange($event, "typeDesc");
      }
    }
  })], 1)], 1), _c("a-modal", {
    attrs: {
      width: 528,
      title: "选择图标",
      footer: false
    },
    model: {
      value: _vm.showIcon,
      callback: function callback($$v) {
        _vm.showIcon = $$v;
      },
      expression: "showIcon"
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex"
    }
  }, _vm._l(_vm.icons, function (item, i) {
    return _c("a-col", {
      key: i,
      staticStyle: {
        padding: "10px"
      }
    }, [_c("img", {
      staticStyle: {
        width: "48px",
        height: "48px"
      },
      attrs: {
        src: item,
        alt: "加载失败"
      },
      on: {
        click: function click($event) {
          return _vm.choseIcon(item);
        }
      }
    })]);
  }), 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };