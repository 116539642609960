import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/web.timers.js";
import ItemList from "./list.vue";
import { mapState } from "vuex";
import ATreeMixinFunc from "@/mixins/aTree";
var ATreeMixin = ATreeMixinFunc({
  replaceFields: {
    title: "institutionTypeName",
    key: "id",
    children: "childList"
  }
});
export default {
  name: "",
  components: {
    ItemList: ItemList
  },
  mixins: [ATreeMixin],
  props: {},
  data: function data() {
    return {
      companyId: "",
      groupModalTitle: "添加制度分类",
      groupModalVisible: false,
      groupForm: this.$form.createForm(this),
      tempGroup: null,
      confirmLoadingGroup: false,
      emptyImg: require("../../admin/chanpinjieshao/empty.png")
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    companyList: function companyList() {
      return this.hr.companyList;
    },
    companyLst: function companyLst() {
      return this.companyList.map(function (item) {
        return {
          value: item.id,
          label: item.name
        };
      });
    },
    parentLst: function parentLst() {
      return this.treeData.map(function (item) {
        return {
          label: item.institutionTypeName,
          value: item.id,
          sort: item.sort
        };
      });
    }
  }),
  watch: {
    companyList: {
      handler: function handler(newVal) {
        if (newVal && newVal.length > 0) {
          if (!this.companyId) {
            var temp = localStorage.getItem("zhidugongshi_companyId");
            this.companyId = temp || this.companyLst[0].value;
          }
        }
      },
      immediate: true
    },
    companyId: {
      handler: function handler(newVal) {
        if (newVal) {
          localStorage.setItem("zhidugongshi_companyId", newVal);
          this.onGroupSearchChange();
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onGroupSelected: function onGroupSelected(node) {
      this.currentItem = node;
      localStorage.setItem("zhidugongshiGroupId", this.currentItem.id);
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    onGroupSearchChange: function onGroupSearchChange() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/institutionTypes/selectBySite",
        params: {
          companyId: this.companyId
        }
      }).then(function (res) {
        var tempId = localStorage.getItem("zhidugongshiGroupId");
        tempId = _this.currentItem ? _this.currentItem.id : tempId;
        _this.formatData(res.data, tempId);
      }).catch(function (err) {
        console.log("qyCompanies/selectBySite", err);
      });
    },
    onDelGroupItem: function onDelGroupItem() {
      var _this2 = this;
      this.$confirm({
        title: "删除确认",
        content: "公司删除后将无法恢复，确认要删除吗？",
        onOk: function onOk() {
          _this2.$request({
            url: "/hr/api/v1/institutionTypes/".concat(_this2.tempGroup.id),
            method: "delete"
          }).then(function () {
            _this2.$message.success("删除成功");
            _this2.onGroupSearchChange();
            _this2.groupModalVisible = false;
          }).catch(function (err) {
            console.log("deleteReq qyCompanies", err);
          });
        }
      });
    },
    onAddGroupItem: function onAddGroupItem() {
      this.groupForm.resetFields();
      this.groupModalTitle = "添加制度分类";
      this.tempGroup = null;
      this.groupModalVisible = true;
    },
    onEditGroupItem: function onEditGroupItem(target) {
      var _this3 = this;
      this.tempGroup = target;
      this.groupModalTitle = "编辑制度分类";
      this.groupModalVisible = true;
      setTimeout(function () {
        _this3.groupForm.setFieldsValue({
          institutionTypeName: target.institutionTypeName,
          institutionTypeIntroduce: target.institutionTypeIntroduce,
          parentId: target.parentId === "0" ? undefined : target.parentId,
          sort: target.sort
        });
      }, 0);
    },
    onGroupModalOk: function onGroupModalOk() {
      var _this4 = this;
      this.groupForm.validateFields(function (err, values) {
        if (!err) {
          var parentId = values.parentId || "0";
          var newValues = {
            institutionTypeName: values.institutionTypeName,
            institutionTypeIntroduce: values.institutionTypeIntroduce,
            parentId: parentId,
            sort: values.sort,
            level: parentId === "0" ? 1 : 2
          };
          _this4.confirmLoadingGroup = true;
          if (_this4.groupModalTitle === "添加制度分类") {
            _this4.$request({
              url: "/hr/api/v1/institutionTypes",
              method: "post",
              data: newValues
            }).then(function (res) {
              _this4.$message.success("添加成功");
              _this4.onGroupSearchChange();
              _this4.groupModalVisible = false;
              _this4.confirmLoadingGroup = false;
            }).catch(function (err) {
              _this4.confirmLoadingGroup = false;
              console.log("saveReq qyCompanies", err);
            });
          } else {
            _this4.$request({
              url: "/hr/api/v1/institutionTypes",
              method: "put",
              data: _objectSpread(_objectSpread({}, newValues), {}, {
                id: _this4.tempGroup.id
              })
            }).then(function (res) {
              _this4.$message.success("修改成功");
              _this4.onGroupSearchChange();
              _this4.groupModalVisible = false;
              _this4.confirmLoadingGroup = false;
            }).catch(function (err) {
              _this4.confirmLoadingGroup = false;
              console.log("updateReq qyCompanies", err);
            });
          }
        }
      });
    }
  }
};