import request from "@/utils/request";
export function listEmpAndRoleUserInCompany(params, companyId) {
  return request({
    url: "/hr/api/v1/users/listEmpAndRoleUserInCompany/".concat(companyId),
    method: "GET",
    params: params
  });
}
export function pageEmpAndRoleUserInCompany(params, companyId) {
  return request({
    url: "/hr/api/v1/users/pageEmpAndRoleUserInCompany/".concat(companyId),
    method: "GET",
    params: params
  });
}
export function increaseSiftEmpCount(companyId) {
  return request({
    url: "hr/api/v1/empInfos/increaseSiftEmpCount/".concat(companyId),
    method: "GET"
  });
}
export function getPeopleList(params) {
  return request({
    url: "/hr/api/v1/transferAccountsPeoples",
    method: "get",
    params: params
  });
}
export function decreaseSiftEmpCount(companyId) {
  return request({
    url: "hr/api/v1/empInfos/decreaseSiftEmpCount/".concat(companyId),
    method: "GET"
  });
}
export function listUserHasRoleInCompany(params, companyId) {
  return request({
    url: "/hr/api/v1/users/listUserHasRoleInCompany/".concat(companyId),
    method: "GET",
    params: params
  });
}
export function pageUserHasRoleInCompany(params, companyId) {
  return request({
    url: "/hr/api/v1/users/pageUserHasRoleInCompany/".concat(companyId),
    method: "GET",
    params: params
  });
}
// 粘贴选择：查询员工(增员计划用)
export function addEmpListByIdCards(companyId, data) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpGroupByIdCards/".concat(companyId),
    method: "post",
    data: data
  });
}
export function getNotList(params) {
  return request({
    url: "/hr/api/v1/empInfos/pageSalaryFile",
    method: "GET",
    params: params
  });
}
export function selectOutsourceSalary(params) {
  return request({
    url: "hr/api/v1/salaryCalculateRules/selectOutsourceSalary",
    method: "GET",
    params: params
  });
}
export function getmpByCardAndSalaryType(data) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpByCardAndSalaryType",
    method: "post",
    data: data
  });
}
export function isSameTemplate(data) {
  return request({
    url: "salary/api/v1/empFixedSalaries/isSameTemplate",
    method: "post",
    data: data
  });
}
export function getAllList(params) {
  return request({
    url: "/hr/api/v1/channels/listAll",
    method: "get",
    params: params
  });
}
// 粘贴选择：查询员工(减员计划用)
export function deleteEmpListByIdCards(companyId, data) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpGroupByIdCards2/".concat(companyId),
    method: "post",
    data: data
  });
}

// 退费员工
export function tuifeiList(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectPageByPostType",
    method: "get",
    params: params
  });
}