import request from "@/utils/request";

// 查询公司内信息
export function selectCompanyAndEmpCountStatisticsReq(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/selectCompanyAndEmpCountStatistics",
    method: "post",
    data: data
  });
}

// 社保统计
export function selectSocCount(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/selectSocCount",
    method: "post",
    data: data
  });
}

// 操作日志
export function selectServiceLog(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/selectServiceLog",
    method: "post",
    data: data
  });
}

// 查询员工统计
export function selectHomeEmpCount(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/selectHomeEmpCount",
    method: "post",
    data: data
  });
}

// 查询营收总额
export function selectRevenueAmount(data) {
  return request({
    url: "/salary/api/v1/customerSettlements/selectRevenueAmount",
    method: "post",
    data: data
  });
}

// 查询指定月份内营收金额
export function selectRevenueAmountCount(data) {
  return request({
    url: "/salary/api/v1/customerSettlements/selectRevenueAmountCount",
    method: "post",
    data: data
  });
}

// 修改租户logo
export function updateSitePic(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/updateSitePic",
    method: "POST",
    params: data
  });
}

// 查询分组下公司
export function findCompanyGroup(data) {
  return request({
    url: "/hr/api/v1/childrenCompany/listAll",
    method: "GET",
    params: data
  });
}

// 查询账面余额
export function findCompanyBalances(data) {
  return request({
    url: "/hr/api/v1/companyBalances/selectByCompanyIds",
    method: "POST",
    data: data
  });
}
// 分页查询余额明细
export function companyBalanceRecords(data) {
  return request({
    url: "/hr/api/v1/companyBalanceRecords/pageByCompanyId",
    method: "POST",
    data: data
  });
}