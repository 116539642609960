import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "flex-center-between"
  }, [_c("div", {
    staticClass: "flex-center"
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: false,
      placeholder: "选择月份"
    },
    on: {
      change: _vm.onMonthChange
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }), _c("a-input-search", {
    staticClass: "ml16",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      placeholder: "请输入公司名称",
      allowClear: ""
    },
    on: {
      change: _vm.onNameChange
    },
    model: {
      value: _vm.companyName,
      callback: function callback($$v) {
        _vm.companyName = $$v;
      },
      expression: "companyName"
    }
  }), _c("a-select", {
    staticClass: "ml16",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      placeholder: "请选择明细表类型",
      allowClear: ""
    },
    model: {
      value: _vm.customerDetailsType,
      callback: function callback($$v) {
        _vm.customerDetailsType = $$v;
      },
      expression: "customerDetailsType"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "customer"
    }
  }, [_vm._v("客户明细表")]), _c("a-select-option", {
    attrs: {
      value: "salary"
    }
  }, [_vm._v("薪资明细表")]), _c("a-select-option", {
    attrs: {
      value: "social"
    }
  }, [_vm._v("社保明细表")])], 1)], 1), _vm.groupType === "company" ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showAdd
    }
  }, [_c("a-icon", {
    attrs: {
      type: "container"
    }
  }), _vm._v("生成明细表")], 1) : _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.$refs.tabGroup.showAdd
    }
  }, [_c("a-icon", {
    attrs: {
      type: "container"
    }
  }), _vm._v("生成自定义分组")], 1)], 1), _c("div", {
    staticClass: "flex-center mv16"
  }, [_c("div", {
    staticClass: "ta-center fh32 br20 ph24 pointer bgc-white mr16 fw500",
    class: {
      "bgc-theme": _vm.groupType === "company",
      "tc-white": _vm.groupType === "company"
    },
    on: {
      click: function click($event) {
        _vm.groupType = "company";
      }
    }
  }, [_vm._v(" 客户明细 ")]), _c("div", {
    staticClass: "ta-center fh32 br20 ph24 pointer bgc-white fw500",
    class: {
      "bgc-theme": _vm.groupType === "customGrouping",
      "tc-white": _vm.groupType === "customGrouping"
    },
    on: {
      click: function click($event) {
        _vm.groupType = "customGrouping";
      }
    }
  }, [_vm._v(" 统计报表 ")])]), _c("TabGroup", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.groupType === "customGrouping",
      expression: "groupType === 'customGrouping'"
    }],
    ref: "tabGroup",
    attrs: {
      date: _vm.date
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.groupType === "company",
      expression: "groupType === 'company'"
    }]
  }, [_vm.groupList && _vm.groupList.length ? _c("div", [_vm._l(_vm.groupList, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "bgc-white p24 mt12 br8 flex-center",
      staticStyle: {
        height: "100px"
      }
    }, [_c("a-tooltip", {
      attrs: {
        title: item.name
      }
    }, [_c("div", {
      staticClass: "flex-center flex-shrink-0 border-right",
      staticStyle: {
        width: "200px"
      }
    }, [_c("div", {
      staticClass: "noShowIcon",
      staticStyle: {
        cursor: "pointer"
      }
    }, [_c("a-icon", {
      attrs: {
        type: "file-text",
        theme: "filled"
      }
    })], 1), _c("div", {
      staticClass: "fw500 fs18 ellipsis tc-l1 pr20 flex-1"
    }, [_vm._v(_vm._s(item.name))])])]), _c("div", {
      staticClass: "flex-center-between ml24 flex-1"
    }, [_c("div", [_c("div", {
      staticClass: "ta-center fs12 tc-l2"
    }, [_vm._v("明细表月份")]), _c("div", {
      staticClass: "ta-center fs14 tc-l3 mt8"
    }, [_vm._v(_vm._s(_vm.formatYM(item.yearly, item.monthly)))])]), _c("div", [_c("div", {
      staticClass: "ta-center fs12 tc-l2"
    }, [_vm._v("明细表类型")]), _c("div", {
      staticClass: "ta-center fs14 tc-l3 mt8"
    }, [_vm._v(_vm._s(_vm.typeDict[item.customerDetailsType]))])]), _c("div", [_c("div", {
      staticClass: "ta-center fs12 tc-l2"
    }, [_vm._v("薪资数据来源月份")]), _c("div", {
      staticClass: "ta-center fs14 tc-l3 mt8"
    }, [_vm._v(_vm._s(_vm.formatYM(item.salaryYearly, item.salaryMonthly)))])]), _c("div", [_c("div", {
      staticClass: "ta-center fs12 tc-l2"
    }, [_vm._v("社保数据来源月份")]), _c("div", {
      staticClass: "ta-center fs14 tc-l3 mt8"
    }, [_vm._v(_vm._s(_vm.formatYM(item.socialYearly, item.socialMonthly)))])]), _c("div", [_c("div", {
      staticClass: "ta-center fs12 tc-l2"
    }, [_vm._v("本月人数")]), _c("div", {
      staticClass: "ta-center fs14 tc-l3 mt8"
    }, [_vm._v(_vm._s(item.empNumber))])]), _c("div", [_c("div", {
      staticClass: "ta-center fs12 tc-l2"
    }, [_vm._v("创建人")]), _c("div", {
      staticClass: "ta-center fs14 tc-l3 mt8"
    }, [_vm._v(_vm._s(item.createUserName))])]), _c("div", [_c("div", {
      staticClass: "ta-center fs12 tc-l2"
    }, [_vm._v("创建时间")]), _c("div", {
      staticClass: "ta-center fs14 tc-l3 mt8"
    }, [_vm._v(_vm._s(_vm.$dayjs(item.gmtCreate).format("YYYY-MM-DD")))])]), _c("a-space", [_c("span", {
      staticClass: "pointer tc-theme",
      on: {
        click: function click($event) {
          return _vm.goDetail(item);
        }
      }
    }, [_vm._v("详情")]), _c("a-popconfirm", {
      attrs: {
        title: "记录删除后将无法恢复，确定要删除吗？",
        placement: "topRight"
      },
      on: {
        confirm: function confirm($event) {
          return _vm.deleteItem(item);
        }
      }
    }, [!item.hrMonthlySalarySummaryVOList || item.hrMonthlySalarySummaryVOList.length === 0 ? _c("span", {
      staticClass: "pointer tc-theme"
    }, [_vm._v(" 删除 ")]) : _vm._e()])], 1)], 1)], 1)]);
  }), _c("div", {
    staticClass: "flex-center-end mt24"
  }, [_c("a-pagination", {
    attrs: {
      total: _vm.pagination.total,
      pageSize: _vm.pagination.pageSize,
      showQuickJumper: _vm.pagination.showQuickJumper,
      showSizeChanger: _vm.pagination.showSizeChanger,
      showTotal: _vm.pagination.showTotal,
      size: _vm.pagination.size,
      pageSizeOptions: _vm.pagination.pageSizeOptions
    },
    on: {
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.pagination, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.pagination, "pageSize", $event);
      },
      change: _vm.onShowSizeChange,
      showSizeChange: _vm.onShowSizeChange
    },
    model: {
      value: _vm.pagination.current,
      callback: function callback($$v) {
        _vm.$set(_vm.pagination, "current", $$v);
      },
      expression: "pagination.current"
    }
  })], 1)], 2) : _c("div", {
    staticStyle: {
      padding: "120px 0px 560px 0px",
      "background-color": "#f2f3f5"
    }
  }, [_c("a-empty")], 1)]), _c("a-modal", {
    attrs: {
      title: "生成明细表"
    },
    on: {
      ok: _vm.handleAddOk,
      cancel: _vm.onCancel
    },
    model: {
      value: _vm.addVisible,
      callback: function callback($$v) {
        _vm.addVisible = $$v;
      },
      expression: "addVisible"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      model: _vm.addForm,
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-model-item", {
    ref: "date",
    attrs: {
      rules: {
        required: true,
        message: "请选择"
      },
      label: "明细表月份",
      prop: "date"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择月份"
    },
    model: {
      value: _vm.addForm.date,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "date", $$v);
      },
      expression: "addForm.date"
    }
  })], 1), _c("a-form-model-item", {
    ref: "companyInfoList",
    attrs: {
      rules: {
        required: true,
        message: "请选择"
      },
      label: "选择用工单位",
      prop: "companyInfoList"
    }
  }, [_c("a-tree-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      "tree-data": _vm.companyDataList,
      filterTreeNode: false,
      replaceFields: _vm.replaceFields2,
      labelInValue: "",
      treeCheckable: "",
      placeholder: "请选择"
    },
    on: {
      search: _vm.treeSearch
    },
    model: {
      value: _vm.addForm.companyInfoList,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "companyInfoList", $$v);
      },
      expression: "addForm.companyInfoList"
    }
  })], 1), _c("a-form-model-item", {
    ref: "type",
    attrs: {
      rules: {
        required: true,
        message: "请选择"
      },
      label: "明细表类型",
      prop: "type"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.addForm.type,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "type", $$v);
      },
      expression: "addForm.type"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "customer"
    }
  }, [_vm._v(" 客户明细表 ")]), _c("a-select-option", {
    attrs: {
      value: "salary"
    }
  }, [_vm._v(" 薪资明细表 ")]), _c("a-select-option", {
    attrs: {
      value: "social"
    }
  }, [_vm._v(" 社保明细表 ")])], 1)], 1), _vm.addForm.type === "customer" || _vm.addForm.type === "salary" ? _c("a-form-model-item", {
    ref: "date",
    attrs: {
      rules: {
        required: _vm.addForm.type === "salary",
        message: "请选择"
      },
      label: "薪资数据来源月份",
      prop: "date"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择月份"
    },
    model: {
      value: _vm.addForm.salaryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "salaryDate", $$v);
      },
      expression: "addForm.salaryDate"
    }
  })], 1) : _vm._e(), _vm.addForm.type === "customer" || _vm.addForm.type === "social" ? _c("a-form-model-item", {
    ref: "date",
    attrs: {
      rules: {
        required: _vm.addForm.type === "social",
        message: "请选择"
      },
      label: "社保数据来源月份",
      prop: "date"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择月份"
    },
    model: {
      value: _vm.addForm.socialDate,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "socialDate", $$v);
      },
      expression: "addForm.socialDate"
    }
  })], 1) : _vm._e(), _vm.addForm.type === "customer" ? _c("a-form-model-item", {
    ref: "serviceChargeIds",
    attrs: {
      rules: {
        required: false,
        message: "请选择"
      },
      label: "服务费项",
      prop: "serviceChargeIds"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      options: _vm.fuwufeixiangLst,
      filterOption: _vm.filterOption,
      mode: "multiple",
      showSearch: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.addForm.serviceChargeIds,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "serviceChargeIds", $$v);
      },
      expression: "addForm.serviceChargeIds"
    }
  })], 1) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };