import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-back approval-detail-wrapper",
    staticStyle: {
      position: "relative"
    }
  }, [_c("a-space", [_c("a-button", {
    on: {
      click: _vm.handlerBack
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  }), _vm._v("返回 ")], 1)], 1), _vm.$route.query.pageType && _vm.applyForSubjectStatus >= 5 || _vm.pageState === true && _vm.payStatus === 2 ? _c("div", {
    staticClass: "examine-success"
  }, [_c("div", {
    staticClass: "mb10 examine-success-tit"
  }, [_c("a-tooltip", {
    attrs: {
      placement: "top"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.approvalRecord && _vm.approvalRecord.orderTitle))])]), _c("div", {
    staticClass: "examine-success-tit-f"
  }, [_c("img", {
    staticClass: "examine-img",
    attrs: {
      src: _vm.img,
      alt: "",
      srcset: ""
    }
  }), _vm._v(" " + _vm._s(_vm.approvalRecord && _vm.approvalRecord.orderTitle) + " ")])], 2), _c("div", {
    staticClass: "examine-success-tit-tag ml8",
    style: {
      background: _vm.getPayStatusApplyForSuccess(_vm.approvalRecord.payStatus).background,
      color: _vm.getPayStatusApplyForSuccess(_vm.approvalRecord.payStatus).color
    }
  }, [_vm._v(" " + _vm._s(_vm.getPayStatusApplyForSuccess(_vm.approvalRecord.payStatus).val) + " ")])], 1), _c("div", {
    staticClass: "examine-success-row"
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("发放账户：")]), _c("div", {
    staticClass: "approvalTopNameShow overflowsn"
  }, [_vm._v(_vm._s(_vm.approvalRecord && _vm.approvalRecord.issueAccountName))])]), _c("div", {
    staticClass: "examine-success-row"
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("发放人：")]), _c("div", {
    staticClass: "approvalTopNameShow overflowsn"
  }, [_vm._v(_vm._s(_vm.issueUserName))])]), _c("div", {
    staticClass: "examine-success-row"
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("发放时间：")]), _c("div", {
    staticClass: "approvalTopNameShow overflowsn"
  }, [_vm._v(_vm._s(_vm.issueCreate))])]), _c("div", {
    staticClass: "examine-success-row"
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("批次号：")]), _c("a-tooltip", [_c("template", {
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.approvalRecord.thirdPartyInteriorBatchId) + " ")]), _c("div", {
    staticClass: "approvalTopNameShow overflowsn"
  }, [_vm._v(_vm._s(_vm.approvalRecord.thirdPartyInteriorBatchId))])], 2)], 1)]) : _vm._e(), _vm.approvalRecord ? _c("a-row", {
    staticClass: "approval-block",
    class: _vm.$route.query.pageType && _vm.applyForSubjectStatus >= 5 || _vm.pageState === true && _vm.payStatus === 2 ? "payStatus" : "",
    staticStyle: {
      "padding-bottom": "32px"
    }
  }, [_c("div", {
    staticStyle: {
      position: "absolute",
      right: "24px",
      "z-index": "999"
    }
  }, [_vm.approvalRecord.subjectStatus === 4 && _vm.approvalRecord.payStatus === 5 && _vm.pageState ? _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: _vm.chakanjieguo
    }
  }, [_vm._v("查看结果")]) : _vm._e(), _vm.approvalRecord.subjectStatus === 4 && _vm.approvalRecord.payStatus === 5 && _vm.pageState && _vm.approvalRecord.type === 1 && _vm.approvalRecord.secondType !== 2 ? _c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.payContinue
    }
  }, [_vm._v("继续支付")]) : _vm._e(), (_vm.approvalRecord.subjectStatus === 4 && _vm.approvalRecord.payStatus === 1 || _vm.approvalRecord.subjectStatus === 5 && _vm.approvalRecord.payStatus === 3) && _vm.pageState ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goPaySalary
    }
  }, [_vm._v(_vm._s(_vm.approvalRecord.subjectStatus === 4 ? "薪资发放" : "重新发放"))]) : _vm._e()], 1), _vm.$route.query.pageType && _vm.applyForSubjectStatus >= 5 ? _c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "mb10"
  }, [_c("titborder", [_vm._v("基本信息")])], 1)]) : _c("a-col", {
    attrs: {
      span: 24
    }
  }, [_vm.payStatus !== 2 ? _c("div", {
    staticClass: "mb10",
    style: _vm.pageState ? "display:flex" : ""
  }, [_c("span", {
    staticClass: "approval-block-top-title"
  }, [_c("img", {
    staticClass: "examine-img",
    attrs: {
      src: _vm.img,
      alt: "",
      srcset: ""
    }
  }), _vm._v(" " + _vm._s(_vm.approvalRecord && _vm.approvalRecord.orderTitle) + " ")]), _vm.pageState ? _c("div", {
    staticClass: "examineTag",
    style: {
      background: _vm.getPayStatus(_vm.payStatus).background,
      color: _vm.getPayStatus(_vm.payStatus).color
    }
  }, [_vm._v(" " + _vm._s(_vm.getPayStatus(_vm.payStatus).val) + " ")]) : _vm.$route.query.pageType && _vm.applyForSubjectStatus < 5 ? _c("a-tag", {
    attrs: {
      color: _vm.getPayStatusApplyFor(_vm.applyForSubjectStatus, _vm.approvalRecord.payStatus).color
    }
  }, [_vm._v(_vm._s(_vm.getPayStatusApplyFor(_vm.applyForSubjectStatus, _vm.approvalRecord.payStatus).val))]) : _vm.$route.query.pageType && _vm.applyForSubjectStatus > 4 ? _c("div") : _c("a-tag", {
    attrs: {
      color: _vm.getApprovalStatusColorBlue(_vm.approval && _vm.approval.status)
    }
  }, [_vm._v(_vm._s(_vm.getApprovalStatus(_vm.approval && _vm.approval.status)))])], 1) : _c("div", {
    staticClass: "mb10"
  }, [_c("titborder", [_vm._v("基本信息")])], 1)]), _c("a-row", [_c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("用工单位：")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v(_vm._s(_vm.approvalRecord && _vm.approvalRecord.companyName))])]), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("申请人：")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v(_vm._s(_vm.approvalRecord && _vm.approvalRecord.createUserName))])]), _vm.pageState && _vm.payStatus === 4 ? _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("实际发放人数：")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v(_vm._s(_vm.approvalRecord && _vm.approvalRecord.successCount))])]) : _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("发放人数：")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v(_vm._s(_vm.approvalRecord && _vm.approvalRecord.totalCount))])]), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("备注：")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v(_vm._s(_vm.approvalRecord && _vm.approvalRecord.remark))])])], 1), _c("a-row", {
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("发放方式：")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v(_vm._s(_vm.typeFun(_vm.approvalRecord && _vm.approvalRecord.type)))])]), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("申请时间：")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v(_vm._s(_vm.approvalRecord && _vm.moment(_vm.approvalRecord.gmtCreate).format("YYYY-MM-DD HH:mm:ss")))])]), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v(" " + _vm._s(_vm.pageState && _vm.payStatus === 1 || _vm.pageState && _vm.payStatus === 5 || _vm.pageState && _vm.payStatus === 3 || _vm.pageState && _vm.payStatus === 4 ? "预计发放总金额：" : "发放总金额：") + " ")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v("￥" + _vm._s(_vm.approvalRecord && _vm.approvalRecord.money))])]), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_vm.pageState && _vm.payStatus === 3 ? _c("div", [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("失败原因：")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v(_vm._s(_vm.approvalRecord && _vm.approvalRecord.failCause ? _vm.approvalRecord.failCause : "--"))])]) : _vm._e(), _vm.pageState && _vm.payStatus === 4 ? _c("div", [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("实际发放总金额：")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v(" ￥" + _vm._s(_vm.approvalRecord && _vm.approvalRecord.successMoney ? _vm.approvalRecord.successMoney : "--") + " ")])]) : _vm._e(), _vm.pageState && _vm.payStatus === 2 && _vm.approvalRecord.type === 3 ? _c("div", [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("手续费：")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v(_vm._s(_vm.approvalRecord && _vm.approvalRecord.feeAll ? _vm.approvalRecord.feeAll : "--"))])]) : _vm._e()])], 1), _c("a-row", {
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 6
    }
  }, [_vm.pageState && _vm.payStatus === 4 && _vm.approvalRecord.type === 3 || _vm.pageState && _vm.payStatus === 6 && _vm.approvalRecord.type === 3 ? _c("div", [_c("div", {
    staticClass: "approvalTopName"
  }, [_vm._v("手续费：")]), _c("div", {
    staticClass: "approvalTopNameShow"
  }, [_vm._v(_vm._s(_vm.approvalRecord && _vm.approvalRecord.feeAll ? _vm.approvalRecord.feeAll : "--"))])]) : _vm._e()])], 1)], 1) : _vm._e(), _c("a-row", {
    staticClass: "approval-block",
    staticStyle: {
      "padding-bottom": "32px"
    }
  }, [_c("a-col", {
    staticClass: "approval-block-title",
    attrs: {
      span: 24
    }
  }, [_vm._v("审批流程")]), _c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("ApprovalProcess", {
    attrs: {
      approvalVal: _vm.approvalNodes
    }
  })], 1)], 1), _c("a-row", {
    staticClass: "approval-block"
  }, [_c("a-col", {
    staticClass: "approval-block-title",
    attrs: {
      span: 24
    }
  }, [_vm._v("发薪详情")]), _c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("approvalDetail", {
    attrs: {
      approvalRecord: _vm.approvalRecord,
      pageState: _vm.pageState,
      payStatus: _vm.payStatus
    },
    on: {
      pageRefresh: _vm.pageRefresh
    }
  })], 1)], 1), _vm.approval && _vm.approval.needAppro && _vm.approvalRecord.userApproverId && _vm.approvalRecord.userApproverId !== "read" ? _c("div", [_c("div", {
    staticStyle: {
      height: "64px"
    }
  }), _c("div", {
    staticClass: "approval-block approvalBor"
  }, [_vm.approval && _vm.approval.status === 1 ? _c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    on: {
      click: function click($event) {
        return _vm.openModal("reject");
      }
    }
  }, [_vm._v("拒绝")]) : _vm._e(), _vm.approval && _vm.approval.status === 1 ? _c("a-button", {
    staticClass: "detailBack",
    on: {
      click: function click($event) {
        return _vm.openModal("agree");
      }
    }
  }, [_vm._v("同意")]) : _vm._e()], 1)]) : _vm._e(), _c("a-modal", {
    attrs: {
      title: _vm.Title,
      visible: _vm.visible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        _vm.visible = false;
      }
    }
  }, [_c("a-textarea", {
    attrs: {
      placeholder: _vm.placeholder,
      rows: 4
    },
    model: {
      value: _vm.remark,
      callback: function callback($$v) {
        _vm.remark = $$v;
      },
      expression: "remark"
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "薪资发放",
      visible: _vm.weiXinPay
    },
    on: {
      ok: _vm.handleWXOk,
      cancel: function cancel($event) {
        _vm.weiXinPay = false;
      }
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end",
      "align-items": "center",
      "margin-right": "55px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#767885"
    }
  }, [_vm._v("发放方式：")]), _c("a-select", {
    staticStyle: {
      width: "260px"
    },
    attrs: {
      placeholder: "请选择发放方式"
    },
    on: {
      select: _vm.subMchidChange
    }
  }, _vm._l(_vm.weixinList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: index,
        disabled: !item.zfbAccountBookId && item.type === 4
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _vm.Verification ? _c("div", [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end",
      "align-items": "center",
      "margin-right": "55px",
      padding: "24px 0"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#767885"
    }
  }, [_vm._v("支付验证手机号：")]), _c("div", {
    staticStyle: {
      width: "260px"
    }
  }, [_vm._v(_vm._s(_vm.paySelectItem.phone))])]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end",
      "align-items": "center",
      "margin-right": "55px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#767885"
    }
  }, [_vm._v("验证码：")]), _c("a-input-search", {
    staticStyle: {
      width: "260px"
    },
    attrs: {
      placeholder: "请输入验证码"
    },
    on: {
      search: _vm.sendCode
    },
    model: {
      value: _vm.codeBySend,
      callback: function callback($$v) {
        _vm.codeBySend = $$v;
      },
      expression: "codeBySend"
    }
  }, [_c("a-button", {
    attrs: {
      slot: "enterButton",
      disabled: !_vm.clickable
    },
    slot: "enterButton"
  }, [_vm._v(" " + _vm._s(_vm.contentSend) + " ")])], 1)], 1)]) : _vm._e()])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };