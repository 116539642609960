import request from "@/utils/request";

// 下发审批模版接口
// export function saveIssuedApproFlow(data) {
//   return request({
//     url: "/approval/api/v1/approFlows/saveIssuedApproFlow",
//     method: "POST",
//     data
//   });
// }
export function saveIssuedApproFlow(data) {
  return request({
    url: "/hr/api/v1/approval/saveIssuedApproFlow",
    method: "POST",
    data: data
  });
}

// 查询审批列表树状
export function selectTreeByCompanyId(data) {
  return request({
    url: "/approval/api/v1/approFlows/selectTreeByCompanyId",
    method: "GET",
    params: data
  });
}