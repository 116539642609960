import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
export default {
  name: "PayslipDetail",
  components: {
    payslipProvide: function payslipProvide() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./payslip-provide"));
      });
    },
    payslipWaitProvide: function payslipWaitProvide() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./payslip-wait-provide"));
      });
    },
    payslipHistory: function payslipHistory() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./payslip-history"));
      });
    }
  },
  data: function data() {
    return {
      tabs: {
        feedbackCount: 0,
        sendCount: 0,
        unSendCount: 0
      },
      hide: true
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  created: function created() {
    this.tabsCount();
  },
  mounted: function mounted() {
    var that = this;
    window.onresize = function () {
      return function () {
        window.screenWidth = document.body.clientWidth;
        that.hide = window.screenWidth > 1080;
      }();
    };
  },
  methods: {
    goBack: function goBack() {
      this.$router.push("/wages/payslip");
    },
    // tabs数量
    tabsCount: function tabsCount() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/salaryBillSends/salaryBillSendDetailTab",
        params: {
          tableId: this.$route.query.tableId,
          companyId: this.hr.companyId
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.tabs.feedbackCount = data.feedbackCount;
        _this.tabs.sendCount = data.sendCount;
        _this.tabs.unSendCount = data.unSendCount;
      });
    },
    // 切换详情
    changeTab: function changeTab(i) {},
    // 发放工资条
    payslip: function payslip() {
      var _this2 = this;
      this.$request({
        url: "/hr/api/v1/salaryBills/listAllRelation",
        params: {
          companyId: this.hr.companyId,
          tableId: this.$route.query.tableId,
          sendStatus: 0,
          reloadCount: true
        }
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this2.$router.push({
          path: "/wages/add-payslip-sort/add-payslip-second",
          query: _objectSpread({
            unSendCount: _this2.tabs.unSendCount
          }, _this2.$route.query)
        });
      });
    },
    // 查看统计
    statistics: function statistics() {}
  }
};