import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import Navbar from "@/views/paySalary/component/navbar.vue";
import Sidebar from "@/layout/components/sidebar/adminNew";
import AppMain from "@/layout/components/appmain/adminNew";
import { mapGetters } from "vuex";
export default {
  components: {
    Navbar: Navbar,
    Sidebar: Sidebar,
    AppMain: AppMain
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["permissions_shangbao"])), {}, {
    adminMenu: function adminMenu() {
      var arr = [];
      var menus = [];
      if (this.permissions_shangbao.includes("insuranceAddService:insuranceAddService.zaibaorenyuan:selectList")) {
        menus.push({
          name: "在保人员",
          router: "/shangbao/list"
        });
      }
      if (this.permissions_shangbao.includes("insuranceAddService:insuranceAddService.shangbaofangan:selectList")) {
        menus.push({
          name: "商保方案",
          router: "/shangbao/plan"
        });
      }
      arr.push({
        icon: "",
        name: "商保",
        router: "/shangbao",
        children: menus
      });
      return arr;
    }
  }),
  watch: {
    adminMenu: {
      handler: function handler(newVal) {
        try {
          var url = newVal[0].children[0].router;
          if (this.$route.path === "/shangbao") {
            this.$router.replace(url);
          }
        } catch (err) {
          console.log(err);
        }
      },
      immediate: true
    }
  }
};