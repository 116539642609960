import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("ListSearch", {
    attrs: {
      eventTypeLst: _vm.eventTypeLst
    },
    on: {
      search: _vm.onSearch
    }
  }), _c("a-divider", {
    attrs: {
      dashed: ""
    }
  }), _c("div", {
    staticClass: "top"
  }, [_c("a-space", [_vm.permissions_dycl.includes("dycl:dycl.dyclList:add") ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addItem
    }
  }, [_vm._v("新增申报")]) : _vm._e(), _vm.permissions_dycl.includes("dycl:dycl.dyclList:import") ? _c("a-button", {
    on: {
      click: _vm.importItems
    }
  }, [_vm._v("批量导入")]) : _vm._e(), _vm.permissions_dycl.includes("dycl:dycl.dyclList:export") ? _c("a-button", {
    on: {
      click: _vm.exportItems
    }
  }, [_vm._v("批量导出")]) : _vm._e()], 1), _c("a-space", [_c("span", {
    staticClass: "antbtn",
    on: {
      click: _vm.onRefresh
    }
  }, [_vm._v("刷新"), _c("a-icon", {
    attrs: {
      type: "reload"
    }
  })], 1)])], 1), _vm.dataLst && _vm.dataLst.length > 0 ? [_c("div", _vm._l(_vm.dataLst, function (item) {
    return _c("ListItem", {
      key: item.id,
      attrs: {
        dataSource: item
      },
      on: {
        deleteItem: _vm.onDeleteItem
      }
    });
  }), 1), _c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("a-pagination", {
    attrs: {
      total: _vm.total,
      pageSize: _vm.pageSize
    },
    on: {
      change: _vm.onPageChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)] : _c("a-empty"), _c("a-modal", {
    attrs: {
      destroyOnClose: true,
      title: "批量导入",
      okText: "下一步"
    },
    on: {
      ok: _vm.importOk
    },
    model: {
      value: _vm.modalImportVisible,
      callback: function callback($$v) {
        _vm.modalImportVisible = $$v;
      },
      expression: "modalImportVisible"
    }
  }, [_c("a-form-item", {
    attrs: {
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      label: "选择模板",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.templateLst,
      filterOption: _vm.filterOption,
      "show-search": "",
      placeholder: "请选择"
    },
    on: {
      change: _vm.onImportTemplateChange
    },
    model: {
      value: _vm.templateIdImport,
      callback: function callback($$v) {
        _vm.templateIdImport = $$v;
      },
      expression: "templateIdImport"
    }
  })], 1)], 1), _c("a-modal", {
    attrs: {
      destroyOnClose: true,
      title: "批量导出",
      okText: "下一步"
    },
    on: {
      ok: _vm.exportOk
    },
    model: {
      value: _vm.modalExportVisible,
      callback: function callback($$v) {
        _vm.modalExportVisible = $$v;
      },
      expression: "modalExportVisible"
    }
  }, [_c("a-form-item", {
    attrs: {
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      label: "选择模板",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.templateLst,
      filterOption: _vm.filterOption,
      "show-search": "",
      placeholder: "请选择"
    },
    on: {
      change: _vm.onExportTemplateChange
    },
    model: {
      value: _vm.templateIdExport,
      callback: function callback($$v) {
        _vm.templateIdExport = $$v;
      },
      expression: "templateIdExport"
    }
  })], 1)], 1), _c("a-modal", {
    attrs: {
      destroyOnClose: true,
      title: "新增申报",
      okText: "下一步"
    },
    on: {
      ok: _vm.addOk
    },
    model: {
      value: _vm.modalVisible,
      callback: function callback($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c("a-form-item", {
    attrs: {
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      label: "接单时间",
      required: ""
    }
  }, [_c("a-date-picker", {
    staticStyle: {
      width: "100%"
    },
    model: {
      value: _vm.takeOrdersDate,
      callback: function callback($$v) {
        _vm.takeOrdersDate = $$v;
      },
      expression: "takeOrdersDate"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      label: "是否优先处理",
      required: ""
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.isPrecedence,
      callback: function callback($$v) {
        _vm.isPrecedence = $$v;
      },
      expression: "isPrecedence"
    }
  }, [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_vm._v("是")]), _c("a-radio", {
    attrs: {
      value: false
    }
  }, [_vm._v("否")])], 1)], 1), _c("a-form-item", {
    attrs: {
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      label: "选择模板",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.templateLst,
      filterOption: _vm.filterOption,
      "show-search": "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.templateId,
      callback: function callback($$v) {
        _vm.templateId = $$v;
      },
      expression: "templateId"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      label: "接单客服",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.dispatchLst,
      filterOption: _vm.filterOption,
      "show-search": "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.takeOrdersUserId,
      callback: function callback($$v) {
        _vm.takeOrdersUserId = $$v;
      },
      expression: "takeOrdersUserId"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      label: "所属用工单位",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.companyLst,
      placeholder: "请选择",
      filterOption: _vm.filterOption,
      "show-search": ""
    },
    model: {
      value: _vm.companyId,
      callback: function callback($$v) {
        _vm.companyId = $$v;
      },
      expression: "companyId"
    }
  })], 1)], 1), _c("ExportBox", {
    attrs: {
      eventTypeLst: _vm.eventTypeLst,
      isFinish: _vm.isFinish,
      templateId: _vm.templateIdExport,
      templateName: _vm.templateNameExport
    },
    model: {
      value: _vm.exportVisible,
      callback: function callback($$v) {
        _vm.exportVisible = $$v;
      },
      expression: "exportVisible"
    }
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };