import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack flex flex-col setting-wrapper"
  }, [_c("a-row", {
    staticClass: "topline",
    attrs: {
      type: "flex"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  }), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goback
    }
  }, [_vm._v("返回")]), _c("h3", [_vm._v(_vm._s(_vm.pageTitle))]), _c("a-icon", {
    attrs: {
      type: "add"
    }
  })], 1), _c("a-row", {
    staticClass: "setting-content",
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    staticStyle: {
      "border-right": "1px solid #e8e8e8"
    },
    attrs: {
      span: 4
    }
  }, [_c("a-menu", {
    staticClass: "groupleft"
  }, _vm._l(_vm.groupList, function (item) {
    return _c("a-menu-item", {
      key: item.id,
      staticStyle: {
        "border-bottom": "1px solid #e8e8e8"
      },
      on: {
        click: function click($event) {
          return _vm.getCategorieslistAll(item.id);
        }
      }
    }, [_c("span", {
      staticClass: "groupname"
    }, [_vm._v(_vm._s(item.name))])]);
  }), 1)], 1), _c("a-col", {
    staticClass: "setting-right"
  }, [_c("div", {
    staticClass: "bleft"
  }, [_vm._v("员工端合同详情")]), _c("div", {
    staticClass: "setting-tip"
  }, [_vm._v("当员工签署完合同时，员工是否可以查看已签署合同的详情内容。")]), _c("a-select", {
    staticStyle: {
      width: "360px"
    },
    attrs: {
      placeholder: "设置是否允许查看"
    },
    on: {
      change: _vm.selectSetting
    },
    model: {
      value: _vm.selectway,
      callback: function callback($$v) {
        _vm.selectway = $$v;
      },
      expression: "selectway"
    }
  }, _vm._l(_vm.optionlist, function (form, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: form.value
      }
    }, [_vm._v(" " + _vm._s(form.label) + " ")]);
  }), 1)], 1)], 1), _c("div", {
    staticClass: "btnline"
  }, [_c("a-button", {
    staticStyle: {
      margin: "24px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmSetting
    }
  }, [_vm._v(" 保存 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };