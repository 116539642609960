import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import { getToken } from "xqjr-module-auth";
import { onGetDownload } from "./api";
export default {
  props: {
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "700px"
    },
    src: {
      type: String,
      default: ""
    },
    upload: {
      type: Boolean,
      default: true
    },
    action: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    src: {
      handler: function handler(v) {
        var regImg = /\.(?:png|jpg|jpeg)$/i;
        var regPdf = /\.(?:pdf)$/i;
        if (typeof v === "string") {
          if (v.match(regImg)) {
            this.fileType = "img";
          }
          if (v.match(regPdf)) {
            this.fileType = "pdf";
          }
        }
      },
      immediate: true
    }
  },
  data: function data() {
    return {
      fileType: "file",
      jl: require("@/assets/recruitment/jl.png"),
      headers: {}
    };
  },
  created: function created() {
    this.headers = {
      token: getToken()
    };
  },
  methods: {
    onDelSrc: function onDelSrc() {
      var _this = this;
      this.$confirm({
        okText: "确定",
        title: "\u786E\u5B9A\u8981\u5220\u9664\u7B80\u5386\uFF1F",
        onOk: function onOk() {
          _this.$emit("upData", "");
        }
      });
    },
    beforeUpload: function beforeUpload(file) {
      var v = this.getFileSize(file.size);
      if (v.unit === "MB" || v.unit === "KB" || v.unit === "B") {
        if (v.unit === "MB" && parseFloat(v.num) > 5) {
          this.$notification.error({
            message: "文件大小不能超过5M"
          });
          return false;
        }
      } else {
        this.$notification.error({
          message: "文件大小不能超过5M"
        });
        return false;
      }
    },
    getFileSize: function getFileSize(size) {
      function bytesToSize(bytes) {
        if (bytes === 0) return "0 B";
        var k = 1024;
        var sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return {
          num: (bytes / Math.pow(k, i)).toPrecision(4),
          unit: sizes[i]
        };
      }
      return bytesToSize(parseInt(size));
    },
    handleChange: function handleChange(_ref) {
      var file = _ref.file,
        fileList = _ref.fileList;
      if (file.status === "done" || file.status === "removed") {
        if (file.response.code === 200) {
          this.$emit("upData", file.response.data);
          if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            this.fileType = "img";
          } else if (file.type === "application/pdf") {
            this.fileType = "pdf";
          } else {
            this.fileType = "file";
          }
        }
      }
    },
    onDownload: function onDownload() {
      this.$notification.warning({
        message: "正在下载简历"
      });
      onGetDownload(this.src, "简历");
    }
  }
};