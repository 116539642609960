import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.showTable ? _c("div", {
    staticClass: "tabBodyNewStyle"
  }, [_c("div", {
    staticClass: "table-body-top"
  }, [_c("div", {
    staticClass: "table-body-top-search"
  }, [_c("TableScreening", {
    attrs: {
      tableName: _vm.tableName
    },
    on: {
      onClose: function onClose($event) {
        return _vm.$emit("onClose");
      },
      onSearch: function onSearch($event) {
        return _vm.$emit("onSearch");
      }
    }
  }, [_vm._t("search")], 2), _vm._t("search-right")], 2), _c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._t("action-btn"), _vm._t("action", function () {
    return [!_vm.action ? _c("div", {
      style: _vm.$slots["action-btn"] ? "margin-top:16px" : ""
    }, [_vm.$listeners["onReload"] ? _c("a-button", {
      staticClass: "action-btn",
      attrs: {
        type: "link"
      },
      on: {
        click: function click() {
          _vm.$emit("onReload");
          _vm.$message.success("刷新成功");
        }
      }
    }, [_vm._v(" 刷新 "), _c("a-icon", {
      attrs: {
        type: "reload"
      }
    })], 1) : _vm._e(), _vm.selectHead ? _c("a-divider", {
      attrs: {
        type: "vertical"
      }
    }) : _vm._e(), _c("a-popover", {
      attrs: {
        title: "",
        trigger: "click",
        placement: "bottomRight",
        overlayClassName: "table-body-popover"
      },
      model: {
        value: _vm.headVisible,
        callback: function callback($$v) {
          _vm.headVisible = $$v;
        },
        expression: "headVisible"
      }
    }, [_c("div", {
      staticClass: "table-body-popover-content",
      attrs: {
        slot: "content"
      },
      slot: "content"
    }, [_c("div", [_c("div", [_vm._v("已选择表头")]), _c("div", {
      staticClass: "overview-tag-wrapper"
    }, [_c("draggable", {
      attrs: {
        filter: ".forbid",
        animation: "30",
        move: _vm.onMove
      },
      model: {
        value: _vm.tempSelectedHead,
        callback: function callback($$v) {
          _vm.tempSelectedHead = $$v;
        },
        expression: "tempSelectedHead"
      }
    }, [_vm._l(_vm.tempSelectedHead, function (item, index) {
      return [!(_vm.isJF && item.dataIndex === "postType") ? _c("a-tag", {
        key: "".concat(item.title || item.customTitle, "_").concat(index),
        class: item.isEdit ? "overview-select-tag" : "overview-select-tag-name forbid",
        attrs: {
          closable: item.isEdit
        },
        on: {
          close: function close($event) {
            return _vm.deleteSelectedHead($event, index, item.isFix);
          }
        }
      }, [_vm._v(_vm._s(item.title || item.customTitle || item.fieldName))]) : _vm._e()];
    })], 2)], 1)]), _c("div", [_c("div", [_vm._v("可选择表头")]), _c("div", {
      staticClass: "overview-tag-noselect"
    }, [_c("div", [_vm._l(_vm.tempNoSelectedHead, function (item, index) {
      return [!(_vm.isJF && item.dataIndex === "postType") ? _c("a-tag", {
        key: "".concat(item.title || item.customTitle, "_").concat(index),
        staticClass: "overview-no-select-tag",
        on: {
          click: function click($event) {
            return _vm.addSelectedHead(index);
          }
        }
      }, [_vm._v(_vm._s(item.title || item.customTitle))]) : _vm._e()];
    })], 2)])]), _vm.tableName === "empInfoByCompany" || _vm.tableName === "empInfo" ? _c("div", [_c("div", [_vm._v("自定义字段")]), _c("div", {
      staticClass: "overview-tag-noselect"
    }, [_c("div", _vm._l(_vm.cstomFieldList, function (item, index) {
      return _c("a-tag", {
        key: "".concat(item.fieldName || item.title, "_").concat(index),
        staticClass: "overview-no-select-tag",
        on: {
          click: function click($event) {
            return _vm.addFieldName(index);
          }
        }
      }, [_vm._v(_vm._s(item.fieldName || item.title))]);
    }), 1)])]) : _vm._e(), _c("div", {
      staticStyle: {
        height: "45px"
      }
    }), _c("a-row", {
      staticClass: "table-body-popover-btn",
      attrs: {
        type: "flex",
        justify: "end"
      }
    }, [_c("a-button", {
      staticStyle: {
        "margin-right": "12px"
      },
      on: {
        click: _vm.handleHeadCancel
      }
    }, [_vm._v("取消")]), _c("a-button", {
      attrs: {
        type: "primary",
        loading: _vm.handleHeadSumbitLoading
      },
      on: {
        click: _vm.handleHeadSumbit
      }
    }, [_vm._v("保存")])], 1)], 1), _vm.selectHead ? _c("a-button", {
      staticClass: "action-btn",
      attrs: {
        type: "link"
      }
    }, [_vm._v(" 选择表头 "), _c("a-icon", {
      attrs: {
        type: "menu"
      }
    })], 1) : _vm._e()], 1), !_vm.selectHead ? _vm._t("selectHead") : _vm._e(), _vm.$listeners["onOrdered"] ? _c("a-divider", {
      attrs: {
        type: "vertical"
      }
    }) : _vm._e(), _vm.$listeners["onOrdered"] ? _c("a-button", {
      staticClass: "action-btn",
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          return _vm.$emit("onOrdered");
        }
      }
    }, [_vm._v(" 排序 "), _c("a-icon", {
      attrs: {
        type: "ordered-list"
      }
    })], 1) : _vm._e(), _vm.$listeners["onExport"] || _vm.$listeners["onExportAll"] ? _c("a-divider", {
      attrs: {
        type: "vertical"
      }
    }) : _vm._e(), _vm.$listeners["onExport"] || _vm.$listeners["onExportAll"] ? _c("a-dropdown", [_c("a-menu", {
      attrs: {
        slot: "overlay"
      },
      slot: "overlay"
    }, [_vm.$listeners["onExport"] && _vm.selection ? _c("a-menu-item", {
      key: "1",
      on: {
        click: function click($event) {
          _vm.selectionBtnLock = true;
        }
      }
    }, [_vm._v("批量导出")]) : _vm._e(), _vm.$listeners["onExportAll"] ? _c("a-menu-item", {
      key: "2",
      attrs: {
        disabled: !_vm.source.length
      },
      on: {
        click: function click($event) {
          return _vm.$emit("onExportAll");
        }
      }
    }, [_vm._v("全部导出")]) : _vm._e()], 1), _c("a-button", {
      staticClass: "action-btn",
      attrs: {
        type: "link"
      }
    }, [_vm._v(" 导出 "), _c("a-icon", {
      attrs: {
        type: "caret-down"
      }
    })], 1)], 1) : _vm._e()], 2) : _vm._e()];
  })], 2)]), _vm._t("default", null, {
    className: "xq-draggable-table",
    columns: _vm.columnsArr,
    components: _vm.components,
    headerRowID: _vm.headerRowID,
    scrollWidth: _vm.scrollWidth
  }), _vm.selection ? _c("div", {
    staticClass: "table-body-footer",
    class: _vm.$listeners["onExport"] && (_vm.selectedRowKeys.length > 0 || _vm.selectionBtnLock) ? "table-body-footer-enter" : "table-body-footer-leave",
    style: {
      width: "calc(100vw - ".concat(this.tableBodyFooterWidth, "px)"),
      left: "".concat(this.tableBodyFooterWidth, "px")
    }
  }, [_c("div", [_vm._v(" 选中 " + _vm._s(_vm.selectedRowKeys.length) + " 个 "), _c("a-button", {
    attrs: {
      type: "link",
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: function click($event) {
        return _vm.$emit("update:selectedRowKeys", []);
      }
    }
  }, [_vm._v("清除")])], 1), _c("a-button", {
    staticClass: "mr12",
    on: {
      click: _vm.TBFclose
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: function click($event) {
        return _vm.$emit("onExport");
      }
    }
  }, [_vm._v("导出")])], 1) : _vm._e()], 2) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };