import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "fix-salary-wrapper"
  }, [_c("a-row", {
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", [_c("a-icon", {
    staticStyle: {
      "font-size": "24px"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  })], 1), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 返回 ")]), _c("a-col", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500"
    }
  }, [_vm._v("定薪模板")]), _c("a-col", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleAddTemplate
    }
  }, [_vm._v(" 新增定薪模板 ")])], 1)], 1), _c("div", _vm._l(_vm.fixData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "fix-item-wrapper"
    }, [_c("div", {
      staticClass: "fix-item-one"
    }, [_c("div", {
      staticStyle: {
        "margin-bottom": "5px"
      }
    }, [_c("span"), _c("span", {
      staticClass: "fix-item-name"
    }, [_vm._v(_vm._s(item.name))])]), item.isDefault ? _c("div", {
      staticStyle: {
        color: "#222426",
        opacity: "0.5"
      }
    }, [_vm._v(" 默认 ")]) : _vm._e()]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", {
      staticStyle: {
        color: "#9C9C9C",
        "margin-bottom": "5px"
      }
    }, [_vm._v("定薪项")]), _c("div", _vm._l(item.fixedSalaryItemVOList, function (item2) {
      return _c("a-tag", {
        key: item2.id
      }, [_vm._v(" " + _vm._s(item2.itemName) + " ")]);
    }), 1)]), _c("div", {
      staticClass: "fix-item-three"
    }, [_c("div", [_c("a-button", {
      attrs: {
        type: "link",
        block: ""
      },
      on: {
        click: function click($event) {
          return _vm.handleEditTemplate(item, index);
        }
      }
    }, [_vm._v(" 编辑模板 ")])], 1), _c("div", [!item.isDefault ? _c("a-button", {
      attrs: {
        type: "link",
        block: ""
      },
      on: {
        click: function click($event) {
          return _vm.handleDeleteTemplate(item.id);
        }
      }
    }, [_vm._v(" 删除 ")]) : _vm._e()], 1)])]);
  }), 0), _c("a-modal", {
    attrs: {
      title: "新增定薪模板"
    },
    on: {
      ok: _vm.handleAddOk,
      cancel: _vm.handleAddCancel
    },
    model: {
      value: _vm.addVisible,
      callback: function callback($$v) {
        _vm.addVisible = $$v;
      },
      expression: "addVisible"
    }
  }, [_c("a-form-model", {
    ref: "addForm",
    attrs: {
      model: _vm.addForm,
      rules: _vm.rules,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "模板名称",
      prop: "name"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请填写"
    },
    model: {
      value: _vm.addForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "name", $$v);
      },
      expression: "addForm.name"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "定薪项",
      prop: "itemIds"
    }
  }, [_c("div", {
    staticClass: "fix-salary-item-wrapper"
  }, _vm._l(_vm.fixItems, function (item, index) {
    return _c("div", {
      key: index,
      class: _vm.computeItemClass(item),
      on: {
        click: function click($event) {
          return _vm.handleAddSelected(item);
        }
      }
    }, [_c("span", [_vm._v(" " + _vm._s(item.name) + " ")])]);
  }), 0)])], 1)], 1), _c("a-modal", {
    attrs: {
      title: "编辑定薪模板"
    },
    on: {
      ok: _vm.handleEditOk,
      cancel: _vm.handleEditCancel
    },
    model: {
      value: _vm.editVisible,
      callback: function callback($$v) {
        _vm.editVisible = $$v;
      },
      expression: "editVisible"
    }
  }, [_c("a-form-model", {
    ref: "editForm",
    attrs: {
      model: _vm.editForm,
      rules: _vm.rules,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "模板名称",
      prop: "name"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请填写"
    },
    model: {
      value: _vm.editForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm, "name", $$v);
      },
      expression: "editForm.name"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "定薪项",
      prop: "itemIds"
    }
  }, [_c("div", {
    staticClass: "fix-salary-item-wrapper"
  }, _vm._l(_vm.fixItems, function (item, index) {
    return _c("div", {
      key: index,
      class: _vm.computeItemClass(item),
      on: {
        click: function click($event) {
          return _vm.handleAddSelected(item);
        }
      }
    }, [_c("span", [_vm._v(" " + _vm._s(item.name) + " ")])]);
  }), 0)])], 1)], 1), _c("a-modal", {
    attrs: {
      title: "删除确认"
    },
    on: {
      ok: _vm.deleteOk
    },
    model: {
      value: _vm.deleteVisible,
      callback: function callback($$v) {
        _vm.deleteVisible = $$v;
      },
      expression: "deleteVisible"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_vm._v(" 是否确认删除？ ")])]), _c("a-modal", {
    attrs: {
      title: "更改确认"
    },
    on: {
      ok: _vm.fixOk
    },
    model: {
      value: _vm.fixVisible,
      callback: function callback($$v) {
        _vm.fixVisible = $$v;
      },
      expression: "fixVisible"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_vm._v(" 该模板正在被使用，是否确认更改定薪项？ ")])])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };