var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    staticClass: "page-miH page-bgBack",
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(" " + _vm._s(_vm.routerData.channelName + " " + _vm.routerData.yearly + "年" + _vm.routerData.monthly + "月劳务费结算") + " ")]), _c("div", {
    staticClass: "page"
  }, [_c("div", [_c("div", {
    staticClass: "fix-item-wrapper"
  }, [_c("div", {
    staticClass: "fix-item-one"
  }, [_c("p", {
    staticClass: "fix-item-name"
  }, [_vm._v("合计")])]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("产生费用人数")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v(" " + _vm._s(_vm.routerData.feePeopleNumber !== null ? _vm.routerData.feePeopleNumber : "--") + " ")])])]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("工时抽成")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.routerData.sumWorkTimeRake !== null ? _vm.routerData.sumWorkTimeRake : "--"))])])]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("一次性费用")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.routerData.sumOneTimeFee !== null ? _vm.routerData.sumOneTimeFee : "--"))])])]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("特殊费用")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.routerData.sumSpecialFee !== null ? _vm.routerData.sumSpecialFee : "--"))])])]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("应付总费用")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.routerData.sumTotalFee !== null ? _vm.routerData.sumTotalFee : "--"))])])])])]), _c("div", {
    staticClass: "operation"
  }, [_c("div", [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "姓名/身份证号"
    },
    on: {
      search: _vm.inputChange
    }
  }), _c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "搜索用工单位"
    },
    on: {
      search: _vm.inputChange2
    }
  })], 1), _c("div", [_c("a-button", {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: function click() {
        return _vm.download(1);
      }
    }
  }, [_vm._v("批量导出")]), _c("a-button", {
    on: {
      click: function click() {
        return _vm.download(2);
      }
    }
  }, [_vm._v("全部导出")])], 1)]), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      "row-key": "id",
      columns: _vm.columns,
      "data-source": _vm.dataList,
      pagination: _vm.pagination,
      scroll: {
        x: 1200
      },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };