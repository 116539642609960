import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      title: "添加职位",
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "职位"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["recruitInfoId", {
        rules: [{
          required: true,
          message: "职位不能为空"
        }]
      }],
      expression: "['recruitInfoId', { rules: [{ required: true, message: '职位不能为空' }] }]"
    }]
  }, _vm._l(_vm.tableData, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.postName) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "阶段"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["status", {
        rules: [{
          required: true,
          message: "阶段不能为空"
        }]
      }],
      expression: "['status', { rules: [{ required: true, message: '阶段不能为空' }] }]"
    }]
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 已投递 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 已约面 ")]), _c("a-select-option", {
    attrs: {
      value: 3
    }
  }, [_vm._v(" 待入职 ")]), _c("a-select-option", {
    attrs: {
      value: 4
    }
  }, [_vm._v(" 已招聘 ")]), _c("a-select-option", {
    attrs: {
      value: 5
    }
  }, [_vm._v(" 未通过 ")])], 1)], 1)], 1)], 1), _c("a-drawer", {
    attrs: {
      title: "投递岗位",
      placement: "right",
      closable: false,
      width: "500px",
      visible: _vm.visibleDeliveringJobs,
      destroyOnClose: true
    },
    on: {
      close: _vm.onCloseDeliveringJobs
    }
  }, [_c("ShowResume", {
    attrs: {
      status: "sendResume",
      companyUnitsInfo: _objectSpread({}, _vm.peopleInfo)
    },
    on: {
      sendResume: _vm.sendResumeFun,
      handleSubmit: _vm.handleSubmit
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };