import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "校准打卡记录",
      width: "645px",
      "destroy-on-close": true
    },
    on: {
      ok: _vm.fixOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form-model", {
    attrs: {
      model: _vm.form,
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "打卡状态"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: true
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "校准方式"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: true
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "补卡时间",
      help: "校准后，原打卡记录变成失效记录",
      required: true
    }
  }, [_c("a-date-picker", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      disabled: true,
      format: "YYYY-MM-DD"
    },
    model: {
      value: _vm.form.time,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "time", $$v);
      },
      expression: "form.time"
    }
  }), _c("a-time-picker", {
    attrs: {
      format: "HH:mm"
    },
    model: {
      value: _vm.form.time,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "time", $$v);
      },
      expression: "form.time"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "校准人"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: true
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "备注"
    }
  }, [_c("a-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };