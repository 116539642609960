import { getSignData, putSignData } from "@/api/news/sign";
import { UploadGeneralImg } from "xqjr-plugin-common";
export default {
  components: {
    UploadGeneralImg: UploadGeneralImg
  },
  data: function data() {
    return {
      signUpList: []
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      getSignData().then(function (res) {
        _this.signUpList = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleSubmit: function handleSubmit(e) {
      var _this2 = this;
      e.preventDefault();
      putSignData({
        signUpUpdateDTOList: this.signUpList
      }).then(function (res) {
        _this2.$notification["success"]({
          message: "success",
          description: "保存成功"
        });
        _this2.init();
      });
    }
  }
};