import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import { existsNotFinishReq, delTemplateReq, copyTemplateReq } from "../templates/api";
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    dataSource: {
      type: Object,
      default: function _default() {}
    },
    isDefault: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapGetters(["permissions_dycl"])),
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    copyItem: function copyItem(id) {
      var _this = this;
      copyTemplateReq(id).then(function (res) {
        if (res && res.code === 200) {
          _this.$message.success("复制成功");
          _this.$emit("reload");
        } else {
          _this.$message.error(res.msg);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    delItem: function delItem(id) {
      var _this2 = this;
      existsNotFinishReq(id).then(function (res) {
        if (res && res.code === 200) {
          if (res.data) {
            _this2.$message.warning("模板正在使用中，不能删除！");
          } else {
            _this2.$confirm({
              title: "模板删除确认",
              content: "确认要删除模板吗",
              onOk: function onOk() {
                delTemplateReq(id).then(function (res) {
                  if (res && res.code === 200) {
                    _this2.$message.success("删除成功！");
                    _this2.$emit("reload");
                  } else {
                    _this2.$message.error("删除失败！");
                  }
                }).catch(function (err) {
                  _this2.$message.error("删除失败！");
                  console.log(err);
                });
              }
            });
          }
        }
      });
    },
    goEdit: function goEdit(id) {
      this.$router.push({
        path: "/daiyuchuli/templates/".concat(id, "/edit")
      });
    },
    formatDate: function formatDate(stamp) {
      if (stamp) {
        var date = new Date(stamp);
        return "".concat(date.getFullYear(), "-").concat(date.getMonth() + 1, "-").concat(date.getDate(), " ").concat(date.getHours(), ":").concat(date.getMinutes());
      }
      return "--";
    }
  }
};