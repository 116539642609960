import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", [_c("a-row", {
    staticClass: "topline",
    attrs: {
      type: "flex"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  }), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goback
    }
  }, [_vm._v("返回")]), _c("h3", [_vm._v(_vm._s(_vm.pageTitle))]), _c("a-icon", {
    attrs: {
      type: "add"
    }
  })], 1), _c("a-layout-content", {
    staticClass: "stepbox"
  }, [_c("a-steps", {
    attrs: {
      current: _vm.current
    },
    on: {
      change: _vm.stepChange
    }
  }, [_c("a-step", {
    attrs: {
      title: "选择模板"
    }
  }), _c("a-step", {
    attrs: {
      title: "确认合同内容"
    }
  }), _c("a-step", {
    attrs: {
      title: "预览合同发起签署"
    }
  })], 1), _vm.current === 0 ? _c("div", [_c("div", {
    staticClass: "stepcontent",
    staticStyle: {
      margin: "0",
      "margin-top": "24px",
      "min-height": "auto",
      "padding-bottom": "60px"
    }
  }, [_c("li", [_c("p", {
    staticClass: "bleft"
  }, [_vm._v("选择员工签署方")]), _c("a-row", {
    staticClass: "licontent",
    attrs: {
      type: "flex"
    }
  }, [_c("a-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.selectMember
    }
  }, [_vm._v(_vm._s(_vm.btnName))]), _vm.selectedList ? _c("a-col", [_c("span", [_vm._v(" 已选择 "), _c("span", {
    staticClass: "nameStyle"
  }, [_vm._v(_vm._s(_vm.selectedList.slice(0, 3).map(function (item) {
    return item.name;
  }).join(",")))]), _vm._v(" " + _vm._s(" 等 " + _vm.selectedList.length + " 个人") + " ")]), _vm.fold == true ? _c("span", {
    staticClass: "fold",
    on: {
      click: function click($event) {
        _vm.fold = false;
      }
    }
  }, [_vm._v(" 收起 "), _c("a-icon", {
    attrs: {
      type: "arrow-up"
    }
  })], 1) : _vm._e(), _vm.fold == false ? _c("span", {
    staticClass: "fold",
    on: {
      click: function click($event) {
        _vm.fold = true;
      }
    }
  }, [_vm._v(" 展开 "), _c("a-icon", {
    attrs: {
      type: "arrow-down"
    }
  })], 1) : _vm._e()]) : _vm._e()], 1), _vm.fold == true ? _c("a-table", {
    staticStyle: {
      "margin-top": "12px",
      width: "100%"
    },
    attrs: {
      columns: _vm.memberColumn,
      "data-source": _vm.selectedList,
      scroll: {
        y: 320
      },
      "row-key": "id",
      size: "small"
    }
  }) : _vm._e(), _c("employee-select", {
    attrs: {
      show: _vm.chooseMemberVisible,
      "default-selected-keys": _vm.defaultSelectedKeys,
      "selected-list": _vm.selectedList,
      "template-id": _vm.templateId
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.chooseMemberVisible = $event;
      },
      "update:selectedList": function updateSelectedList($event) {
        _vm.selectedList = $event;
      },
      "update:selected-list": function updateSelectedList($event) {
        _vm.selectedList = $event;
      }
    }
  })], 1), !_vm.personOnly ? _c("li", [_c("p", {
    staticClass: "bleft"
  }, [_vm._v("选择企业签署方")]), _c("a-input", {
    staticStyle: {
      width: "260px",
      "margin-top": "12px"
    },
    attrs: {
      placeholder: _vm.InfoDetail.enterpriseName,
      disabled: ""
    },
    model: {
      value: _vm.InfoDetail.enterpriseName,
      callback: function callback($$v) {
        _vm.$set(_vm.InfoDetail, "enterpriseName", $$v);
      },
      expression: "InfoDetail.enterpriseName"
    }
  })], 1) : _vm._e(), _c("li", {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_c("p", {
    staticClass: "bleft"
  }, [_vm._v("选择合同模板")]), _c("div", {
    staticClass: "boxlist"
  }, [_vm._l(_vm.templist, function (temp, idx) {
    return _c("div", {
      key: idx,
      staticClass: "choosebox",
      class: _vm.templateId == temp.id ? "addclass" : "",
      on: {
        click: function click($event) {
          return _vm.onClick(temp.id);
        }
      }
    }, [_c("img", {
      attrs: {
        src: require("../../../assets/img/hetong.png"),
        alt: ""
      }
    }), _c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(temp.name))])]), _c("span", {
      staticStyle: {
        width: "100px",
        overflow: "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap"
      }
    }, [_vm._v(_vm._s(_vm.getName(temp.name)))])], 2)], 1);
  }), _vm.templist.length === 0 ? _c("div", {
    staticClass: "choosebox"
  }, [_c("p", [_vm._v(" 当前合同类型暂无合同模板， "), _c("span", {
    staticClass: "primary_color",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.toAddTemplate
    }
  }, [_vm._v("去添加")])])]) : _vm._e()], 2)])]), _c("div", {
    staticClass: "btnline bgc-white",
    style: {
      position: "fixed",
      width: "auto",
      bottom: _vm.fixedBottom,
      right: "24px",
      zIndex: "10",
      left: _vm.fixedLeft
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.nextStep
    }
  }, [_vm._v("下一步")])], 1)]) : _vm._e(), _vm.current === 1 ? _c("ConfirmContract", {
    ref: "ConfirmContract",
    attrs: {
      "template-id": _vm.templateId,
      "enterprise-name": _vm.enterpriseName,
      contractlist: _vm.contractlist,
      variableListMap: _vm.variableListMap
    },
    on: {
      "update:contractlist": function updateContractlist($event) {
        _vm.contractlist = $event;
      },
      "update:variableListMap": function updateVariableListMap($event) {
        _vm.variableListMap = $event;
      },
      "update:variable-list-map": function updateVariableListMap($event) {
        _vm.variableListMap = $event;
      },
      current: _vm.change
    }
  }) : _vm._e(), _vm.current === 2 ? _c("PreviewContract", {
    attrs: {
      contractlist: _vm.contractlist,
      contractinfo: _vm.InfoDetail,
      variableListMap: _vm.variableListMap,
      "template-id": _vm.templateId,
      isCheckFace: _vm.isCheckFace
    },
    on: {
      current: _vm.change
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };