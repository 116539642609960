import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.push.js";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
var mixin = new UploadMixin();
var formalType = ["试用", "正式", "实习"];
var columnDetail = [{
  title: "错误信息",
  dataIndex: "reason",
  fixed: "left",
  scopedSlots: {
    customRender: "reason"
  },
  ellipsis: true,
  width: 260
}];
import axios from "axios";
import qs from "qs";
export default {
  mixins: [mixin],
  data: function data() {
    return {
      count: 0,
      succ: 0,
      fial: 0,
      formalType: formalType,
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showQuickJumper: true
      },
      dataDetail: [],
      callback: false,
      dialogFailVisible: false,
      downloadurl: undefined,
      columnDetail: columnDetail,
      uploadImg: require("@/assets/img/uploadImg.png"),
      data: {
        // templateName: "monthlySalaryTemplate.xlsx"
        salaryTemplateSalaryId: this.$route.query.salaryTemplateId,
        companyId: this.$store.state.hr.companyId
      },
      url: "/salary/api/v1/salaryTemplateSalaries/exportBySalaryTemplateSalaryIds",
      excelName: "工资数据上传模板.xlsx"
    };
  },
  created: function created() {
    this.salaryTemplateId = this.$route.query.salaryTemplateId;
    this.month = this.$route.query.monthly;
    this.year = this.$route.query.yearly;
    var companyId = this.$store.state.hr.companyId;
    this.action = "/salary/api/v1/employeeSalaries/importMonthlySalary?companyId=".concat(companyId, "&force=false&month=").concat(this.month, "&year=").concat(this.year, "&salaryTemplateSalaryId=").concat(this.salaryTemplateId);
  },
  methods: {
    download: function download() {
      var _this = this;
      var token = this.$store.state.auth.token;
      axios({
        url: this.url,
        data: this.data,
        paramsSerializer: function paramsSerializer(data) {
          return qs.stringify(data, {
            indices: false
          });
        },
        method: "post",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", _this.excelName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleSuccess: function handleSuccess(res) {
      var _this2 = this;
      var that = this;
      if (res.success && res.data !== null) {
        var numAll = res.data.importFailList.length + res.data.importSucList.length;
        var numFail = res.data.importFailList.length;
        var numSuc = res.data.importSucList.length;
        if (that.showFaild) {
          that.Faildata = res.data.importFailList;
          that.Faildata.forEach(function (item, index) {
            item = Object.assign(item, item.allMap);
          });
          that.showFaild(numAll, numSuc, numFail, that.Faildata, res.data.excelFilePath, res.data.failHeaders);
        } else {
          if (numFail === 0) {
            this.$confirm({
              okText: "确定",
              title: "\u5171\u5BFC\u5165".concat(numAll, "\u4EBA\uFF0C").concat(numSuc, "\u4EBA\u6210\u529F\uFF0C").concat(numFail, "\u4EBA\u672A\u6210\u529F"),
              onOk: function onOk() {
                that.goback();
              },
              onCancel: function onCancel() {
                that.goback();
              }
            });
          } else {
            this.$confirm({
              okText: "确定",
              cancelText: "查看未成功名单",
              title: "\u5171\u5BFC\u5165".concat(numAll, "\u4EBA\uFF0C").concat(numSuc, "\u4EBA\u6210\u529F\uFF0C").concat(numFail, "\u4EBA\u672A\u6210\u529F"),
              onOk: function onOk() {
                that.goback();
              },
              onCancel: function onCancel() {
                that.Faildata = res.data.importFailList;
                that.dialogFailVisible = true;
              }
            });
          }
        }
      } else if (res.data === null) {
        this.$confirm({
          okText: "确定",
          title: res.msg
        });
      } else {
        this.$notification["error"]({
          message: "error",
          description: res.msg || "上传失败"
        });
        setTimeout(function () {
          _this2.reset();
        }, 2000);
      }
    },
    buCha: function buCha() {
      var token = this.$store.state.auth.token;
      window.open(this.downloadurl + "?token=".concat(token));
    },
    isGoBack: function isGoBack() {
      if (this.callback) {
        this.callback = false;
      } else {
        this.goback();
      }
    },
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    },
    handleFailClose: function handleFailClose() {
      this.dialogFailVisible = false;
      this.uploading = false;
      this.goback();
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
    },
    showFaild: function showFaild(numAll, numSuc, numFail, Faildata, downloadurl, columns) {
      var _this3 = this;
      this.columnDetail = [{
        title: "错误信息",
        dataIndex: "reason",
        fixed: "left",
        scopedSlots: {
          customRender: "reason"
        },
        ellipsis: true,
        width: 260
      }];
      this.count = numAll;
      this.succ = numSuc;
      this.fial = numFail;
      this.dataDetail = Faildata;
      if (this.count === this.succ) {
        this.dialogFailVisible = true;
      } else {
        this.callback = true;
      }
      this.downloadurl = downloadurl;
      columns.forEach(function (item, index) {
        _this3.columnDetail.push({
          title: "".concat(item),
          dataIndex: "".concat(item),
          align: "center",
          width: item === "身份证号" ? 220 : 150,
          scopedSlots: {
            customRender: "".concat(item)
          },
          ellipsis: true,
          fixed: item === "身份证号" || item === "姓名" ? "left" : null
        });
      });
    },
    getMaritalStatus: function getMaritalStatus(maritalStatus) {
      if (maritalStatus === 1) {
        return "已婚";
      } else if (maritalStatus === 2) {
        return "未婚";
      } else if (maritalStatus === 3) {
        return "离异";
      }
    }
  }
};