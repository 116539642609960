var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isZXR ? _c("div", {
    class: _vm.show ? "toggle" : "toggle2"
  }, [_vm.show ? _c("div", {
    staticClass: "col",
    on: {
      click: _vm.yindao
    }
  }, [_c("div", {
    staticClass: "hicon"
  }), _c("span", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("新手")]), _c("span", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("引导")])]) : _vm._e(), _vm.show ? _c("div", {
    staticStyle: {
      "border-bottom": "1px solid #e8e8e8",
      width: "50px"
    }
  }) : _vm._e(), _vm.show ? _c("div", {
    staticClass: "col",
    on: {
      click: _vm.change
    }
  }, [_c("div", {
    staticClass: "hicon2"
  }), _c("span", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("帮助")])]) : _vm._e(), _vm.show ? _c("div", {
    staticStyle: {
      "border-bottom": "1px solid #e8e8e8",
      width: "50px"
    }
  }) : _vm._e(), _c("span", {
    staticClass: "col",
    on: {
      click: _vm.toggle
    }
  }, [_c("a-icon", {
    class: _vm.show ? "arrow_up" : "arrow_up2",
    attrs: {
      type: "double-left"
    }
  })], 1)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };