import { setFormDesignConfig } from "k-form-design-callcter";
import Position from "./position.vue";
import Department from "./department.vue";
import Employee from "./employee.vue";
import AreaSelector from "./AreaSelector";
import CitySelector from "./CitySelector";
import UploadImg from "./UploadImg";
import UploadFile from "./UploadFile";
setFormDesignConfig({
  uploadFile: "/file/api/v1/general/upload/attachment",
  uploadImage: "/file/api/v1/general/uploadImg",
  title: "专业控件",
  list: [{
    type: "uploadSiteImg",
    label: "上传图片",
    icon: "icon-gallery",
    component: UploadImg,
    options: {
      multiple: true,
      defaultValue: "",
      limit: 1
    },
    rules: [{
      required: false,
      message: "必填项"
    }]
  }, {
    type: "uploadSiteFile",
    label: "上传文件",
    icon: "icon-gallery",
    component: UploadFile,
    options: {
      multiple: true,
      defaultValue: "",
      limit: 1
    },
    rules: [{
      required: false,
      message: "必填项"
    }]
  }, {
    type: "employee",
    label: "人员选择",
    icon: "icon-gallery",
    component: Employee,
    options: {
      multiple: false,
      clearable: false,
      disabled: false,
      placeholder: "请选择人员",
      defaultValue: ""
    },
    rules: [{
      required: true,
      message: "必填项"
    }]
  }, {
    type: "position",
    label: "职位选择",
    icon: "icon-gallery",
    component: Position,
    options: {
      multiple: false,
      clearable: false,
      disabled: false,
      placeholder: "请选择职位",
      defaultValue: ""
    },
    rules: [{
      required: true,
      message: "必填项"
    }]
  }, {
    type: "department",
    label: "部门选择",
    icon: "icon-gallery",
    component: Department,
    options: {
      multiple: false,
      clearable: false,
      disabled: false,
      placeholder: "请选择部门",
      defaultValue: ""
    },
    rules: [{
      required: true,
      message: "必填项"
    }]
  }, {
    type: "area",
    label: "地区选择",
    icon: "icon-gallery",
    component: AreaSelector,
    options: {
      clearable: false,
      disabled: false,
      placeholder: "请选择地区",
      defaultValue: "",
      multiple: false
    },
    rules: [{
      required: true,
      message: "必填项"
    }]
  }, {
    type: "city",
    label: "城市选择",
    icon: "icon-gallery",
    component: CitySelector,
    options: {
      clearable: false,
      disabled: false,
      placeholder: "请选择城市",
      defaultValue: "",
      multiple: false
    },
    rules: [{
      required: true,
      message: "必填项"
    }]
  }]
});