export default {
  fields: {
    name: {
      model: "name"
    },
    phone: {
      model: "phone"
    },
    jurisdiction: {
      model: "jurisdiction"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "name",
        title: "员工姓名"
      }, {
        model: "phone",
        title: "员工手机"
      }, {
        model: "jurisdiction",
        title: "员工权限"
      }],
      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: {
          width: 250
        }
      }
    }
  }
};