import request from "@/utils/request";
export function getEmployeeFile(params) {
  return request({
    url: "/hr/api/v1/personalEvent/selectByEventTime",
    method: "get",
    params: params
  });
}
export function addPersonalEvent(data) {
  return request({
    url: "/hr/api/v1/personalEvent",
    method: "post",
    data: data
  });
}
export function updatePersonalEvent(data) {
  return request({
    url: "/hr/api/v1/personalEvent",
    method: "put",
    data: data
  });
}
export function deletePersonalEvent(id) {
  return request({
    url: "/hr/api/v1/personalEvent/".concat(id),
    method: "delete"
  });
}
export function uploadGeneralFile(data, url) {
  url = !!url ? url : "/file/api/v1/site/upload/attachment";
  return request({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
}