import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "shebao-detail-list"
  }, [_c("a-button", {
    attrs: {
      icon: "left",
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")]), _c("a-row", {
    staticClass: "content-top"
  }), _c("navItem", {
    attrs: {
      options: _vm.tabs
    },
    on: {
      change: _vm.handleNav
    }
  }), _c("a-table", {
    staticClass: "mg_t24",
    attrs: {
      "row-key": function rowKey(a, b) {
        return a.id;
      },
      columns: _vm.column,
      "list-type": "picture",
      pagination: _vm.pagination,
      "data-source": _vm.dataSource
    },
    on: {
      change: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "customName",
      fn: function fn(text, record) {
        return [record.noError ? _c("img", {
          staticStyle: {
            width: "24px"
          },
          attrs: {
            src: require("@/assets/img/shebaopaidan/Info2@2x.png"),
            alt: ""
          }
        }) : _c("img", {
          staticStyle: {
            width: "24px"
          },
          attrs: {
            src: require("@/assets/img/shebaopaidan/Info-Circle@2x.png"),
            alt: ""
          }
        }), _vm._v(" " + _vm._s(record.name) + " ")];
      }
    }, {
      key: "socEndDate",
      fn: function fn(record) {
        return [_vm._v(_vm._s(_vm.moment(record).format("YYYY-MM-DD")))];
      }
    }, {
      key: "fundEndDate",
      fn: function fn(record) {
        return [_vm._v(_vm._s(_vm.moment(record).format("YYYY-MM-DD")))];
      }
    }, {
      key: "assignCompanyName",
      fn: function fn(record) {
        return [_c("span", {
          staticClass: "primary"
        }, [_vm._v(_vm._s(record ? record : "--"))])];
      }
    }, {
      key: "action",
      fn: function fn(record) {
        return [_c("span", {
          staticClass: "primary",
          on: {
            click: function click($event) {
              return _vm.deleteItem(record);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  }), _c("a-row", {
    staticClass: "foot"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.reUpload
    }
  }, [_vm._v("重新上传")]), _c("a-button", {
    on: {
      click: _vm.doNext
    }
  }, [_vm._v("下一步")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };