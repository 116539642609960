import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.set.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import { pageRelation, deleteSocProgs, socProgListAll as _socProgListAll } from "@/api/shebao/shebaoMethod";
import { isContainByString } from "@/utils/index";
import { findCompanyGroup as _findCompanyGroup } from "@/api/company/contract";
import { saveIssuedSocProg as _saveIssuedSocProg } from "./api";
import { formatCompanyTree } from "@/utils/tools";
var columns = [{
  title: "方案名称",
  dataIndex: "progName",
  align: "center",
  fixed: "left",
  scopedSlots: {
    customRender: "progName"
  },
  width: 180
}, {
  title: "参保城市",
  dataIndex: "socCityName",
  align: "center",
  width: 150,
  ellipsis: true
}, {
  title: "方案类型",
  dataIndex: "onlySocAmount",
  align: "center",
  ellipsis: true,
  width: 180,
  scopedSlots: {
    customRender: "onlySocAmount"
  }
}, {
  title: "使用人数",
  dataIndex: "empCount",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "社保基数",
  dataIndex: "socCardinality",
  align: "center",
  ellipsis: true,
  width: 360
}, {
  title: "公积金基数",
  dataIndex: "pfCardinality",
  align: "center",
  ellipsis: true,
  width: 200
}, {
  title: "操作",
  key: "action",
  align: "center",
  fixed: "right",
  width: 80,
  scopedSlots: {
    customRender: "action"
  }
}];
export default {
  data: function data() {
    var _this = this;
    return {
      isContainByString: isContainByString,
      columns: columns,
      companyId: this.$store.state.hr.companyId,
      isDelete: false,
      methodList: [],
      searchName: undefined,
      id: "",
      progName: "",
      dataList: [],
      socCityName: "",
      socCity: "",
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171".concat(total, "\u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.pagination.current = current;
          _this.pagination.pageSize = pageSize;
        }
      },
      // modal显示隐藏
      issueModalVisible: false,
      // 下发选择框合同数据
      contractData: [],
      // 已选的合同模板数据
      contractList: [],
      // 已选的公司数据
      companyList: []
    };
  },
  created: function created() {
    this.findCompanyGroup();
    this.getPageRelation();
    this.socProgListAll();
  },
  methods: {
    filterTreeNode: function filterTreeNode(inVal, node) {
      if (node.data.props.title.toLowerCase().indexOf(inVal.toLowerCase()) >= 0) {
        return true;
      }
      return false;
    },
    companySelectChange: function companySelectChange(companyKeys) {
      this.companyList = companyKeys.filter(function (item) {
        return item.includes("_company_");
      });
    },
    getProgName: function getProgName(txt) {
      if (txt.length > 10) {
        return txt.substring(0, 10);
      } else {
        return txt;
      }
    },
    treeSearch: function treeSearch(e) {
      this.findCompanyGroup(e);
    },
    findCompanyGroup: function findCompanyGroup(e) {
      var _this2 = this;
      _findCompanyGroup({
        name: e || null
      }).then(function (res) {
        _this2.dataList = formatCompanyTree(res.data);
        _this2.companyList = JSON.parse(JSON.stringify(_this2.companyList));
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleSearch: function handleSearch() {
      this.pagination.current = 1;
      this.getPageRelation();
    },
    // 分页查询
    handlePageChange: function handlePageChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getPageRelation();
    },
    enterAddMethod: function enterAddMethod() {
      this.$router.push({
        path: "/".concat(this.isContainByString("contractAdmin") ? "contractAdmin" : "shebao", "/shebaoMethod/addMethod")
      });
    },
    // 保存删除参保方案
    handleChange: function handleChange() {
      this.isDelete = false;
    },
    // 点击删除参保方案
    deleteMethod: function deleteMethod(id, empCount) {
      var that = this;
      this.$confirm({
        title: "删除方案",
        content: "确认删除方案？",
        onOk: function onOk() {
          if (empCount !== 0) {
            that.$notification["error"]({
              message: "删除失败",
              description: "该参保方案还有参保人使用，不能删除！"
            });
            return;
          } else {
            var data = {
              id: id
            };
            deleteSocProgs(data).then(function (res) {
              if (res.code === 200) {
                that.$notification["success"]({
                  message: "删除成功"
                });
              }
              that.getPageRelation();
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          }
        }
      });
    },
    enterDetail: function enterDetail(id) {
      this.$router.push({
        path: "/".concat(this.isContainByString("contractAdmin") ? "contractAdmin" : "shebao", "/shebaoMethod/methodDetail"),
        query: {
          id: id
        }
      });
    },
    // 查询获取当前社保方案列表
    getPageRelation: function getPageRelation() {
      var _this3 = this;
      var data = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        progName: this.searchName,
        companyId: this.isContainByString("contractAdmin") ? "0" : this.companyId
      };
      pageRelation(data).then(function (res) {
        if (res.data.entityCount) _this3.pagination.total = res.data.entityCount;
        if (res.data.entities) _this3.methodList = res.data.entities;
        if (!res.data.entities) {
          _this3.pagination.total = 0;
          _this3.methodList = [];
        }
      });
    },
    socProgListAll: function socProgListAll() {
      var _this4 = this;
      var data = {
        companyId: this.isContainByString("contractAdmin") ? "0" : this.companyId
      };
      _socProgListAll(data).then(function (res) {
        _this4.contractData = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 下发参保方案
    saveIssuedSocProg: function saveIssuedSocProg() {
      var _this5 = this;
      if (this.companyList.length === 0) {
        this.$notification["error"]({
          message: "错误",
          description: "请选择需要下发的公司"
        });
        return;
      }
      if (this.contractList.length === 0) {
        this.$notification["error"]({
          message: "错误",
          description: "请选择需要下发的合同模板"
        });
        return;
      }
      var companyArr = Array.from(new Set(this.companyList.map(function (item) {
        return item.split("_")[2];
      })));
      var data = {
        companyIds: companyArr,
        socProgIds: this.contractList
      };
      _saveIssuedSocProg(data).then(function (res) {
        _this5.issueModalVisible = false;
        _this5.$notification["success"]({
          message: "成功",
          description: "下发成功"
        });
        _this5.companyList = [];
      }).catch(function (err) {
        console.log("debug log --> ", err);
        // this.$notification["error"]({
        //   message: "错误",
        //   description: "下发失败"
        // });
      });
    },
    // 方法
    confirmIssueModal: function confirmIssueModal() {
      // TODO 下发接口
      this.saveIssuedSocProg();
    },
    handleCancelIssueModal: function handleCancelIssueModal() {
      this.issueModalVisible = false;
      // 已选的合同模板数据
      this.contractList = [];
      // 已选的公司数据
      this.companyList = [];
    },
    showIssueModa: function showIssueModa() {
      this.issueModalVisible = true;
    }
  }
};