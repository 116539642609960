var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.record.options.multiple ? _c("area-picker-multi", {
    attrs: {
      level: 3,
      "default-value": _vm.value,
      disabled: _vm.record.options.disabled,
      clearable: _vm.record.options.clearable
    },
    on: {
      change: _vm.onChange
    }
  }) : _c("area-picker", {
    attrs: {
      level: 3,
      "default-value": _vm.value,
      disabled: _vm.record.options.disabled,
      clearable: _vm.record.options.clearable
    },
    on: {
      change: _vm.onChange
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };