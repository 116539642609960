var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "depInfo"
  }, [_c("div", {
    staticClass: "menu"
  }, [_c("a-tabs", {
    staticClass: "menuTap",
    attrs: {
      "default-active-key": "1",
      "tab-position": "left"
    },
    on: {
      change: _vm.changeTab
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "添加员工"
    }
  }, [_vm.key == 1 ? _c("employ", {
    attrs: {
      status: 2
    }
  }) : _vm._e()], 1), _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "添加待入职员工"
    }
  }, [_vm.key == 2 ? _c("employ", {
    attrs: {
      status: 1
    }
  }) : _vm._e()], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };