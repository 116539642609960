import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "depInfo"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "avatar"
  }, [_c("a-avatar", {
    attrs: {
      src: _vm.avatarUrl,
      size: 100
    }
  })], 1), _c("div", {
    staticClass: "empInfo"
  }, [_c("div", [_vm._v(" " + _vm._s(_vm.name)), _c("span", {
    staticStyle: {
      display: "inline-block",
      "line-height": "22px",
      width: "60px",
      height: "22px",
      "text-align": "center",
      background: "rgb(255,223,223)",
      "border-radius": "11px",
      border: "1px solid rgba(255,0,0,0.42)",
      color: "rgb(255,124,124)",
      "font-size": "14px",
      "margin-left": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getEmployeeStatus(_vm.status, _vm.formalType)) + " ")])]), _c("div", {
    staticStyle: {
      display: "inline-block",
      width: "166px",
      height: "30px",
      "line-height": "30px",
      "text-align": "center",
      background: "rgba(252,175,79,0.12)",
      "border-radius": "15px",
      "font-size": "14px",
      "font-weight": "500"
    }
  }, [_vm._v(" 手机号 "), _c("span", {
    staticStyle: {
      color: "rgba(106, 101, 246,1)"
    }
  }, [_vm._v(_vm._s(_vm.phone))])]), _vm.joinDate ? _c("div", {
    staticStyle: {
      "margin-left": "10px",
      display: "inline-block",
      width: "166px",
      height: "30px",
      "line-height": "30px",
      "text-align": "center",
      background: "rgba(252,175,79,0.12)",
      "border-radius": "15px",
      "font-size": "14px",
      "font-weight": "500"
    }
  }, [_vm._v(" 入职时间 "), _c("span", {
    staticStyle: {
      color: "rgba(106, 101, 246,1)"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.joinDate)))])]) : _vm._e()])]), _c("div", {
    staticClass: "container"
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      colon: false,
      label: "离职类型"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["quitType", {
        rules: [{
          required: true,
          message: "请输入"
        }],
        initialValue: 1
      }],
      expression: "[\n            'quitType',\n            {\n              rules: [{ required: true, message: '请输入' }],\n              initialValue: 1\n            }\n          ]"
    }],
    attrs: {
      options: _vm.quitTypeOptions
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "离职时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["quitDate", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['quitDate', { rules: [{ required: true, message: '请输入' }] }]"
    }],
    attrs: {
      type: "date"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "办理离职时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["handleQuitDate"],
      expression: "['handleQuitDate']"
    }],
    attrs: {
      type: "date"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "个人主因"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["personalReason"],
      expression: "['personalReason']"
    }],
    attrs: {
      options: _vm.personReasonOptions
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "公司主因"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyReason"],
      expression: "['companyReason']"
    }],
    attrs: {
      options: _vm.companyReasonOptions
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      width: "90%"
    },
    attrs: {
      colon: false,
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      },
      label: "离职原因"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["quitReason"],
      expression: "['quitReason']"
    }],
    attrs: {
      type: "textarea"
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      width: "90%"
    },
    attrs: {
      colon: false,
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      },
      label: "申请离职原因"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["applyQuitReason"],
      expression: "['applyQuitReason']"
    }],
    attrs: {
      type: "textarea"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "补偿金"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["compensation"],
      expression: "['compensation']"
    }]
  }, [_c("div", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v("元")])])], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "待通知金"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["paymentLieuNotice"],
      expression: "['paymentLieuNotice']"
    }]
  }, [_c("div", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v("元")])])], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "社保减员月"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["ssAttritionMonth", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['ssAttritionMonth', { rules: [{ required: true, message: '请输入' }] }]"
    }],
    attrs: {
      type: "month"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "公积金减员月"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["pfAttritionMonth", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['pfAttritionMonth', { rules: [{ required: true, message: '请输入' }] }]"
    }],
    attrs: {
      type: "month"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "剩余年假"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["surplusYearHoliday"],
      expression: "['surplusYearHoliday']"
    }]
  }, [_c("div", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v("天")])])], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "剩余调休"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["surplusBreakOff"],
      expression: "['surplusBreakOff']"
    }]
  }, [_c("div", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v("天")])])], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "直属下属数量"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["directlyUnderNumber"],
      expression: "['directlyUnderNumber']"
    }]
  })], 1), _c("div", {
    staticClass: "button-area"
  }, [_c("a-button", {
    staticClass: "mr20",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticClass: "submit-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitLeaveInfo
    }
  }, [_vm._v(" 提交 ")])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };