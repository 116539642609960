import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";
export function getTransferAccountsDetails(params) {
  return request({
    url: "/hr/api/v1/transferAccountsDetails",
    method: "GET",
    params: params
  });
}
export function getStartApprover(transferAccountsBatchId, flowId) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/startApprover/".concat(transferAccountsBatchId, "/").concat(flowId),
    method: "GET"
  });
}
export function getAllByCompany(companyId, params) {
  return request({
    url: "/approval/api/v1/approFlows/allByCompany/".concat(companyId, "/").concat(params.moduleType),
    method: "GET",
    params: params
  });
}
export function putTransferAccountsDetails(data) {
  return request({
    url: "/hr/api/v1/transferAccountsDetails",
    method: "PUT",
    data: data
  });
}
export function delTransferAccountsDetails(transferAccountsDetailId) {
  return request({
    url: "/hr/api/v1/transferAccountsDetails/".concat(transferAccountsDetailId),
    method: "DELETE"
  });
}