import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.constructor.js";
import { mapState } from "vuex";
import { getSalaryDetail } from "@/api/wages/file";
var reasons = ["入职定薪", "转正", "晋升", "调动", "年中调薪", "年度调薪", "特别调薪", "其他"];
export default {
  name: "FileDetail",
  data: function data() {
    return {
      currentPage: null,
      detailData: [],
      dataSource: [],
      routerData: {},
      reasons: reasons,
      effectImg: require("@/assets/img/wages/file/effect.png"),
      noEffectImg: require("@/assets/img/wages/file/noEffect.png")
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    status: function status() {
      return ["待入职", "入职", "待离职", "离职"][this.routerData.status - 1];
    },
    doWords: function doWords() {
      return ["salaryPerformance", "mealSubsidy", "trafficSubsidy", "salaryBasic"];
    }
  }),
  created: function created() {
    this.routerData = _objectSpread({}, this.$route.query);
    this.currentPage = this.$route.query.currentPage;
    this.getDetail();
  },
  methods: {
    goBack: function goBack() {
      this.$router.push({
        path: "/wages/file",
        query: {
          currentPage: this.currentPage
        }
      });
    },
    getDetail: function getDetail() {
      var _this = this;
      var data = {
        companyId: this.hr.companyId,
        empId: this.routerData.id
      };
      getSalaryDetail(data).then(function (_ref) {
        var data = _ref.data;
        _this.init(data);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    init: function init(data) {
      var _this2 = this;
      var arr = [];
      data.forEach(function (item, index) {
        var dataSource = {};
        var columns = [];
        item.trainingList.forEach(function (item2, index2) {
          if (index2 < item.trainingList.length - 1) {
            dataSource["training".concat(index2)] = item2.amount;
            columns.push({
              title: "\u8BD5\u7528\u671F".concat(item2.itemName),
              dataIndex: "training".concat(index2),
              key: "training".concat(index2),
              width: 150,
              ellipsis: true
            });
          }
        });
        item.officialList.forEach(function (item2, index2) {
          if (index2 < item.officialList.length - 1) {
            dataSource["official".concat(index2)] = item2.amount;
            columns.push({
              title: "\u8F6C\u6B63\u540E".concat(item2.itemName),
              dataIndex: "official".concat(index2),
              key: "official".concat(index2),
              width: 150,
              ellipsis: true
            });
          }
        });
        dataSource.reason = _this2.reasons[Number(item.reason)];
        columns.push({
          title: item.isFixed ? "定薪原因" : "调薪原因",
          dataIndex: "reason",
          key: "reason",
          width: 150,
          ellipsis: true
        });
        dataSource.remark = item.remark;
        columns.push({
          title: "备注",
          dataIndex: "remark",
          key: "remark",
          width: 150,
          ellipsis: true
        });
        dataSource.officialSalarySum = item.officialSalarySum;
        dataSource.trainingSalarySum = item.trainingSalarySum;
        if (!item.isFixed) {
          dataSource.effectTime = item.effectTime;
          columns.push({
            title: "调薪生效时间",
            dataIndex: "effectTime",
            key: "effectTime",
            scopedSlots: {
              customRender: "effectTime"
            },
            width: 200,
            ellipsis: true
          });
          if (item.isOfficial) {
            columns.push({
              title: "调薪后正式工资总计",
              dataIndex: "officialSalarySum",
              key: "officialSalarySum",
              scopedSlots: {
                customRender: "officialSalarySum"
              },
              width: 200,
              fixed: "right",
              ellipsis: true
            });
          } else {
            columns.push({
              title: "调薪后试用期工资总计",
              dataIndex: "trainingSalarySum",
              key: "trainingSalarySum",
              scopedSlots: {
                customRender: "trainingSalarySum"
              },
              width: 200,
              fixed: "right",
              ellipsis: true
            });
            columns.push({
              title: "调薪后正式工资总计",
              dataIndex: "officialSalarySum",
              key: "officialSalarySum",
              scopedSlots: {
                customRender: "officialSalarySum"
              },
              width: 200,
              fixed: "right",
              ellipsis: true
            });
          }
        } else {
          columns.push({
            title: "试用期工资总计",
            dataIndex: "trainingSalarySum",
            key: "trainingSalarySum",
            scopedSlots: {
              customRender: "trainingSalarySum"
            },
            width: 200,
            fixed: "right",
            ellipsis: true
          });
          columns.push({
            title: "正式工资总计",
            dataIndex: "officialSalarySum",
            key: "officialSalarySum",
            scopedSlots: {
              customRender: "officialSalarySum"
            },
            width: 200,
            fixed: "right",
            ellipsis: true
          });
        }
        arr.push({
          columns: columns,
          dataSource: [_objectSpread({}, dataSource)],
          gmtCreate: item.gmtCreate,
          isFixed: item.isFixed,
          isStart: item.isStart
        });
      });
      this.detailData = arr;
    }
  }
};