var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("header", {
    staticClass: "flex-center-between"
  }, [_c("div", {
    staticClass: "l_title"
  }, [_vm._v(" 调薪提醒 "), _c("a-switch", {
    staticClass: "switch",
    attrs: {
      checkedChildren: "开",
      unCheckedChildren: "关",
      defaultChecked: ""
    },
    on: {
      change: _vm.onChangeSwitch
    }
  }), _c("a-button", {
    staticClass: "btn_bag",
    attrs: {
      icon: "plus"
    },
    on: {
      click: _vm.addMessage
    }
  }, [_vm._v(" 添加提醒 ")])], 1), _c("a-button", [_vm._v("保存并返回")])], 1), _c("a-layout-content", {
    staticClass: "a_layout_content"
  }, [_c("a-row", {
    staticClass: "a_title",
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("a-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("调薪邮件提醒")]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-row", {
    staticClass: "sub_tit pb12"
  }, [_vm._v("提醒时间")]), _c("a-tag", {
    attrs: {
      color: "#6A66F6"
    }
  }, [_vm._v("orange")]), _c("a-tag", {
    attrs: {
      color: "#6A66F6"
    }
  }, [_vm._v("orange")])], 1), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-row", {
    staticClass: "sub_tit pb12"
  }, [_vm._v("通知人")]), _c("a-tag", {
    attrs: {
      color: "#6A66F6"
    }
  }, [_vm._v("orange")])], 1), _c("a-col", {
    staticClass: "ta-right",
    attrs: {
      span: 2
    }
  }, [_c("a-icon", {
    attrs: {
      type: "delete"
    }
  })], 1)], 1)], 1), _c("add-message", {
    attrs: {
      msgOptions: _vm.msgOptions
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };