var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.pagination.total > 0 || _vm.tempCount > 0 ? _c("div", [_c("a-table", {
    attrs: {
      columns: _vm.columnDetail,
      dataSource: _vm.dataSource,
      pagination: _vm.pagination,
      scroll: {
        x: _vm.columnDetail.length * 200
      },
      rowKey: "id",
      "row-selection": _vm.hidePage ? null : {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
        getCheckboxProps: function getCheckboxProps(record) {
          return {
            props: {
              disabled: !!record.feedbackFlag
            }
          };
        }
      }
    },
    on: {
      change: _vm.onTableChange
    },
    scopedSlots: _vm._u([{
      key: "companySocAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "feedbackFlag",
      fn: function fn(text, record) {
        return [!record.feedbackFlag ? _c("span", {
          staticStyle: {
            color: "#ffa600"
          }
        }, [_vm._v("•")]) : _vm._e(), record.feedbackFlag === 1 ? _c("span", {
          staticStyle: {
            color: "#24cdaa"
          }
        }, [_vm._v("•")]) : _vm._e(), record.feedbackFlag === 2 ? _c("span", {
          staticStyle: {
            color: "#fa5051"
          }
        }, [_vm._v("•")]) : _vm._e(), record.feedbackFlag === 3 ? _c("span", {
          staticStyle: {
            color: "#aeb5bf"
          }
        }, [_vm._v("•")]) : _vm._e(), _c("span", [_vm._v(" " + _vm._s(record.feedbackFlag ? record.feedbackFlag === 1 ? " 已成功" : record.feedbackFlag === 2 ? " 已失败" : " 已取消" : " 待反馈") + " ")])];
      }
    }, {
      key: "type",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text === 1 ? "社保" : text === 2 ? "公积金" : "医保"))])];
      }
    }, {
      key: "personalSocAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "companyPfAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "personalPfAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "serviceCost",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.serviceCost !== null ? record.serviceCost : "-"))])];
      }
    }, {
      key: "schemeName",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "socCityName",
      fn: function fn(text, record) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text) + " ")]), _c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])], 2)];
      }
    }, {
      key: "businessType",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text != null ? text === "1" ? "增加/开户" : "调入/续保" : "-"))])];
      }
    }, {
      key: "socOrStopSocDate",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(_vm.formatDate2(record.socOrStopSocDate)))])];
      }
    }, {
      key: "postType",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(_vm.$store.state.enums.postTypeArr[text - 1]))])];
      }
    }, {
      key: "empSocState",
      fn: function fn(record) {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "flex-flow": "column"
          }
        }, [_c("div", [_vm._v(_vm._s(_vm.getPostType(record)))])])];
      }
    }, {
      key: "opration",
      fn: function fn(text, record) {
        return [!record.feedbackFlag ? _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.doEdit(record);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), !record.feedbackFlag ? _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.goDetail(record);
            }
          }
        }, [_vm._v("反馈")]) : _vm._e(), record.feedbackFlag === 1 ? _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.lookPlan(record);
            }
          }
        }, [_vm._v("查看计划")]) : _vm._e(), record.feedbackFlag === 2 ? _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.lookReason(record);
            }
          }
        }, [_vm._v("查看原因")]) : _vm._e(), record.feedbackFlag === 2 || record.feedbackFlag === 3 ? _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.doEdit(record);
            }
          }
        }, [_vm._v("重新提交")]) : _vm._e()];
      }
    }], null, false, 1125807311)
  }), _c("a-modal", {
    attrs: {
      title: _vm.editPlan ? "编辑增员信息" : "增员",
      visible: _vm.isCloseMethod,
      width: "690px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_vm.editPlan && (_vm.tempEmpSocStatus === 2 || _vm.tempEmpSocStatus === 3) && _vm.currentItem.feedbackFlag !== 3 ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: _vm.cancelZengY
    }
  }, [_vm._v(" " + _vm._s(_vm.getStatusTitle()) + " ")]) : _vm._e(), !_vm.editPlan ? _c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]) : _vm._e(), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.finishEdit
    }
  }, [_vm._v(" " + _vm._s(_vm.editPlan ? "确定" : "下一步") + " ")])], 1), _c("div", {
    staticStyle: {
      padding: "0 56px"
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "参保时间"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      defaultValue: _vm.getDefaultValue(),
      "disabled-date": _vm.disabledDate,
      allowClear: false
    },
    on: {
      change: _vm.onYearMonthChange
    }
  }), _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "10px",
      "margin-top": "-10px",
      "margin-left": "0px"
    }
  }, [_vm._v(" 若需对之前月份进行补收，请前往社保名单进行设置 ")])], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "参保方案"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "66%"
    },
    attrs: {
      value: _vm.defaultFangan === 0 ? "请选择参保方案" : _vm.defaultFangan,
      placeholder: "请选择参保方案"
    },
    on: {
      change: _vm.chooseProgName
    }
  }, _vm._l(_vm.editPlan ? _vm.getProgNameList() : _vm.progNameList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.progName))]);
  }), 1), _c("a-button", {
    staticClass: "ml12",
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.addFangan
    }
  }, [_vm._v(" 新增方案 ")])], 1), _c("div", [!_vm.editPlan && _vm.hasShebao(_vm.formData) ? _c("a-checkbox", {
    staticStyle: {
      "margin-bottom": "18px",
      "margin-left": "105px",
      "margin-top": "10px"
    },
    attrs: {
      checked: _vm.addShebao
    },
    on: {
      change: _vm.onTypeChange1
    }
  }, [_vm._v(" 社保增员 ")]) : _vm._e(), _vm.addShebao || _vm.editPlan ? _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 1 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault : item.cardinalityDefault,
        max: _vm.useSocImport ? 99999999 : item.cardinalityMax,
        min: _vm.useSocImport ? 0 : item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), !_vm.onlySocAmount ? _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "]")]) : _vm._e()], 1) : _vm._e()];
  })], 2) : _vm._e()], 1), _c("div", [!_vm.editPlan && _vm.hasYibao(_vm.formData) ? _c("a-checkbox", {
    staticStyle: {
      "margin-bottom": "18px",
      "margin-left": "105px",
      "margin-top": "10px"
    },
    attrs: {
      checked: _vm.addYibao
    },
    on: {
      change: _vm.onTypeChange2
    }
  }, [_vm._v(" 医保增员 ")]) : _vm._e(), _vm.addYibao || _vm.editPlan ? _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 3 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault : item.cardinalityDefault,
        max: _vm.useMeImport ? 99999999 : item.cardinalityMax,
        min: _vm.useMeImport ? 0 : item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), !_vm.onlySocAmount ? _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "]")]) : _vm._e()], 1) : _vm._e()];
  })], 2) : _vm._e()], 1), !_vm.payProvidentFund && _vm.progNameList.length > 0 ? _c("div", [!_vm.editPlan ? _c("a-checkbox", {
    staticStyle: {
      "margin-bottom": "18px",
      "margin-left": "105px",
      "margin-top": "10px"
    },
    attrs: {
      checked: _vm.addGjj
    },
    on: {
      change: _vm.onTypeChange3
    }
  }, [_vm._v(" 公积金增员 ")]) : _vm._e(), _vm.addGjj || _vm.editPlan ? _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 2 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault : item.cardinalityDefault,
        max: _vm.usePfImport ? 99999999 : item.cardinalityMax,
        min: _vm.usePfImport ? 0 : item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), !_vm.onlySocAmount ? _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "]")]) : _vm._e()], 1) : _vm._e()];
  })], 2) : _vm._e()], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "服务费"
    }
  }, [_c("a-input-number", {
    attrs: {
      placeholder: "每月服务费",
      min: 0
    },
    model: {
      value: _vm.serviceCost,
      callback: function callback($$v) {
        _vm.serviceCost = $$v;
      },
      expression: "serviceCost"
    }
  })], 1)], 1)], 1)], 2), _c("a-modal", {
    attrs: {
      title: _vm.editPlan ? "编辑减员信息" : "减员",
      visible: _vm.isJCloseMethod,
      width: _vm.editPlan ? "600px" : "800px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_vm.editPlan && (_vm.tempEmpSocStatus === 4 || _vm.tempEmpSocStatus === 5) && _vm.currentItem.feedbackFlag !== 3 ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: _vm.cancelJianY
    }
  }, [_vm._v(" " + _vm._s(_vm.getStatusTitle()) + " ")]) : _vm._e(), !_vm.editPlan ? _c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]) : _vm._e(), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.finishEdit
    }
  }, [_vm._v(" " + _vm._s(_vm.editPlan ? "确定" : "下一步") + " ")])], 1), _c("div", [_c("a-form", {
    attrs: {
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 7
      }
    }
  }, [!_vm.editPlan && _vm.stopyear !== "" && _vm.stopmonth !== "" ? _c("a-checkbox", {
    staticStyle: {
      "margin-left": "245px",
      "margin-top": "15px",
      "margin-bottom": "15px"
    },
    attrs: {
      checked: _vm.jianShebao,
      disabled: _vm.jianShebao_2
    },
    on: {
      change: _vm.onTypejianChange1
    }
  }, [_vm._v(" 社保减员 ")]) : _vm._e(), _vm.stopyear !== "" && _vm.stopmonth !== "" ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "社保停保年月"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: false,
      value: _vm.moment(_vm.stopyear + "-" + _vm.stopmonth, "YYYY-MM"),
      "disabled-date": _vm.disabledDate,
      disabled: !_vm.jianShebao && !_vm.editPlan
    },
    on: {
      change: _vm.onStopYearMonthChange
    }
  })], 1) : _vm._e(), !_vm.editPlan && _vm.stopYByear !== "" && _vm.stopYBmonth !== "" ? _c("a-checkbox", {
    staticStyle: {
      "margin-left": "245px",
      "margin-top": "15px",
      "margin-bottom": "15px"
    },
    attrs: {
      checked: _vm.jianYibao,
      disabled: _vm.jianYibao_2
    },
    on: {
      change: _vm.onTypejianChange2
    }
  }, [_vm._v(" 医保减员 ")]) : _vm._e(), _vm.stopYByear !== "" && _vm.stopYBmonth !== "" ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "医保停保年月"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: false,
      value: _vm.moment(_vm.stopYByear + "-" + _vm.stopYBmonth, "YYYY-MM"),
      "disabled-date": _vm.disabledDate,
      disabled: !_vm.jianYibao && !_vm.editPlan
    },
    on: {
      change: _vm.onStopYBYearMonthChange
    }
  })], 1) : _vm._e(), !_vm.editPlan && _vm.stopGjyear !== "" && _vm.stopGjmonth !== "" ? _c("a-checkbox", {
    staticStyle: {
      "margin-left": "245px",
      "margin-top": "15px",
      "margin-bottom": "15px"
    },
    attrs: {
      checked: _vm.jianGjj,
      disabled: _vm.jianGjj_2
    },
    on: {
      change: _vm.onTypejianChange3
    }
  }, [_vm._v(" 公积金减员 ")]) : _vm._e(), _vm.stopGjyear !== "" && _vm.stopGjmonth !== "" ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "公积金封存年月"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: false,
      value: _vm.moment(_vm.stopGjyear + "-" + _vm.stopGjmonth, "YYYY-MM"),
      "disabled-date": _vm.disabledDate,
      disabled: !_vm.jianGjj && !_vm.editPlan
    },
    on: {
      change: _vm.onStopGJYearMonthChange
    }
  })], 1) : _vm._e(), !_vm.editPlan && _vm.stopGjyear !== "" && _vm.stopGjmonth !== "" ? _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "10px",
      "margin-top": "-10px",
      "margin-left": "345px"
    }
  }, [_vm._v(" 如仅停缴公积金，请前往新建无参保公积金方案 ")]) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "服务费"
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      placeholder: "请输入服务费",
      min: 0,
      precision: 2,
      disabled: !_vm.editPlan && (_vm.jianShebao || _vm.jianYibao || _vm.jianGjj) === false
    },
    model: {
      value: _vm.JYserviceCost,
      callback: function callback($$v) {
        _vm.JYserviceCost = $$v;
      },
      expression: "JYserviceCost"
    }
  })], 1)], 1), !_vm.editPlan ? _c("div", {
    staticClass: "marginL2"
  }, [_vm._v("服务费不填写默认不更改，填0即服务费改为0")]) : _vm._e()], 1)], 2), _c("a-modal", {
    attrs: {
      title: _vm.typeValueTime === 1 ? "查看计划" : "查看原因",
      visible: _vm.confirmPlanZengVisible,
      width: "800px"
    },
    on: {
      cancel: _vm.handleCancel,
      ok: _vm.confirmPlanOkHandel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmPlanOkHandel
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", {
    staticClass: "flex-center"
  }, [_vm.typeValueTime === 1 ? _c("img", {
    staticStyle: {
      width: "28px",
      height: "28px"
    },
    attrs: {
      src: require("@/assets/img/overviewContract/icon4.png")
    }
  }) : _vm._e(), _vm.typeValueTime === 2 ? _c("img", {
    staticStyle: {
      width: "28px",
      height: "28px"
    },
    attrs: {
      src: require("@/assets/icons/失败.png")
    }
  }) : _vm._e(), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentItem ? _vm.getPostType(_vm.currentItem.empSocState) : "") + "计划执行" + _vm._s(_vm.typeValueTime === 1 ? "成功" : "失败") + " ")])]), _c("a-table", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      columns: _vm.columnDialogDetail,
      pagination: _vm.pagination2,
      "data-source": _vm.dataDetail3,
      scroll: {
        x: 700
      },
      colspan: "0",
      "row-key": "id"
    },
    on: {
      change: _vm.handlePageChange2
    },
    scopedSlots: _vm._u([{
      key: "socOrStopSocDate",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(_vm.formatDate3(record.socOrStopSocDate)))])];
      }
    }, {
      key: "serviceCost",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.serviceCost !== null ? record.serviceCost : "-"))])];
      }
    }, {
      key: "empSocState",
      fn: function fn(record) {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "flex-flow": "column"
          }
        }, [_c("div", [_vm._v(_vm._s(_vm.getPostType(record)))])])];
      }
    }, {
      key: "postType",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(_vm.$store.state.enums.postTypeArr[text - 1]))])];
      }
    }, {
      key: "schemeName",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "socCityName",
      fn: function fn(text, record) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])]), _c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])], 2)];
      }
    }], null, false, 1926029710)
  }), _vm.fankuiDetail && _vm.fankuiDetail.length > 0 ? _c("div", {
    staticClass: "mt16 pt12",
    staticStyle: {
      "border-top": "1px solid #e8e8e8"
    }
  }, [_c("div", {
    staticClass: "fs16 fw500"
  }, [_vm._v("反馈详情")]), _c("div", {
    staticClass: "ta-left",
    staticStyle: {
      "max-height": "500px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.fankuiDetail, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "pb12",
      staticStyle: {
        "border-bottom": "1px solid #e8e8e8"
      }
    }, [_c("div", {
      staticClass: "flex-center mt8",
      staticStyle: {
        color: "#919191"
      }
    }, [_c("div", {
      staticStyle: {
        width: "100px"
      }
    }, [_vm._v("反馈人")]), _c("div", [_vm._v(_vm._s(item.userName))])]), _c("div", {
      staticClass: "flex-center mt8",
      staticStyle: {
        color: "#919191"
      }
    }, [_c("div", {
      staticStyle: {
        width: "100px"
      }
    }, [_vm._v("反馈状态")]), _c("div", [_vm._v(_vm._s(item.feedbackState))])]), _c("div", {
      staticClass: "flex-center mt8",
      staticStyle: {
        color: "#919191"
      }
    }, [_c("div", {
      staticStyle: {
        width: "100px"
      }
    }, [_vm._v("反馈时间")]), _c("div", [_vm._v(_vm._s(_vm.getDate(new Date(item.gmtCreate), true)))])]), _c("div", {
      staticClass: "flex-center mt8",
      staticStyle: {
        color: "#919191"
      }
    }, [_c("div", {
      staticStyle: {
        width: "100px"
      }
    }, [_vm._v("反馈内容")]), _c("div", [_vm._v(_vm._s(item.feedbackContent))])]), _c("div", {
      staticClass: "flex mt8",
      staticStyle: {
        color: "#919191"
      }
    }, [_c("div", {
      staticStyle: {
        width: "100px"
      }
    }, [_vm._v("附件")]), _c("div", [_c("UploadGeneralInline", {
      attrs: {
        limit: 10,
        accept: ".doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .jpg, .jpeg, .png",
        disabled: true
      },
      scopedSlots: _vm._u([{
        key: "list",
        fn: function fn(_ref) {
          var list = _ref.list;
          return _c("div", {}, _vm._l(list, function (li) {
            return _c("PreviewItem", {
              key: "" + li.uid,
              staticStyle: {
                width: "238px"
              },
              attrs: {
                url: li.url,
                size: 128
              }
            });
          }), 1);
        }
      }], null, true),
      model: {
        value: item.annex,
        callback: function callback($$v) {
          _vm.$set(item, "annex", $$v);
        },
        expression: "item.annex"
      }
    })], 1)])]);
  }), 0)]) : _vm._e()], 2), _c("a-modal", {
    attrs: {
      spinning: _vm.spinning,
      title: _vm.confirmPlanZeng ? "增员计划反馈" : "减员计划反馈",
      visible: _vm.isConfirmPlan,
      width: "800px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary",
      disabled: _vm.spinning
    },
    on: {
      click: _vm.confirmOk
    }
  }, [_vm._v(" " + _vm._s(_vm.spinning ? "数据校验中" : "确认") + " ")])], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "0px"
    }
  }, [_c("a-form", [_c("div", {
    staticClass: "title-block mb24"
  }, [_vm._v("人员列表")]), _c("a-table", {
    attrs: {
      columns: _vm.columnDialogDetail,
      pagination: _vm.pagination2,
      "data-source": _vm.dataDetail3,
      scroll: {
        x: 700
      },
      colspan: "0",
      "row-key": "id"
    },
    on: {
      change: _vm.handlePageChange2
    },
    scopedSlots: _vm._u([{
      key: "socOrStopSocDate",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(_vm.formatDate3(record.socOrStopSocDate)))])];
      }
    }, {
      key: "serviceCost",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.serviceCost !== null ? record.serviceCost : "-"))])];
      }
    }, {
      key: "empSocState",
      fn: function fn(record) {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "flex-flow": "column"
          }
        }, [_c("div", [_vm._v(_vm._s(_vm.getPostType(record)))])])];
      }
    }, {
      key: "postType",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(_vm.$store.state.enums.postTypeArr[text - 1]))])];
      }
    }, {
      key: "schemeName",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "socCityName",
      fn: function fn(text, record) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])]), _c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])], 2)];
      }
    }], null, false, 1926029710)
  }), _c("div", {
    staticClass: "title-block mb24"
  }, [_vm._v("计划操作")]), _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#252525",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.confirmPlanZeng ? "增员计划是否已执行？" : "减员计划是否已执行？") + " ")]), _c("a-form-item", {
    staticClass: "baseInfo-item2"
  }, [_c("a-radio-group", {
    on: {
      change: _vm.onTypeChangeValue
    },
    model: {
      value: _vm.typeValueTime,
      callback: function callback($$v) {
        _vm.typeValueTime = $$v;
      },
      expression: "typeValueTime"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 执行成功 ")]), _c("a-radio", {
    staticStyle: {
      "margin-left": "30px"
    },
    attrs: {
      value: 2
    }
  }, [_vm._v(" 执行失败 ")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "备注"
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      rows: 3,
      maxLength: 200,
      placeholder: "请输入备注（不得超过200字）"
    },
    model: {
      value: _vm.feedbackContent,
      callback: function callback($$v) {
        _vm.feedbackContent = $$v;
      },
      expression: "feedbackContent"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "附件"
    }
  }, [_c("UploadGeneralInline", {
    attrs: {
      accept: ".doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .jpg, .jpeg, .png",
      limit: 10
    },
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn(_ref2) {
        var list = _ref2.list;
        return _c("div", {}, _vm._l(list, function (item) {
          return _c("PreviewItem", {
            key: "" + item.uid,
            staticStyle: {
              width: "238px"
            },
            attrs: {
              url: item.url,
              size: 128
            }
          });
        }), 1);
      }
    }], null, false, 4031710437),
    model: {
      value: _vm.annex,
      callback: function callback($$v) {
        _vm.annex = $$v;
      },
      expression: "annex"
    }
  })], 1)], 1)], 1)], 2), _c("a-modal", {
    attrs: {
      title: "提示",
      visible: _vm.removeZengVisible
    },
    on: {
      cancel: _vm.handleCancel,
      ok: _vm.remvoeZenyuanOkHandel
    }
  }, [_c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px",
      "margin-left": "40px"
    },
    attrs: {
      src: require("@/assets/icons/ask.png")
    }
  }), _vm.tempEmpSocStatus === 2 || _vm.tempEmpSocStatus === 3 ? _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" 取消增员后将回到未参保状态，是否取消增员？ ")]) : _vm._e(), _vm.tempEmpSocStatus === 4 || _vm.tempEmpSocStatus === 5 ? _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" 取消减员后将回到正常参保状态，是否取消减员？ ")]) : _vm._e()])])], 1) : _c("div", {
    staticClass: "flex-center-center pv24",
    staticStyle: {
      "flex-direction": "column",
      height: "565px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "200px",
      height: "100px"
    },
    attrs: {
      src: require("./zanwusbjd.png")
    }
  }), _c("div", {
    staticClass: "mt24",
    staticStyle: {
      color: "rgba(159, 163, 176, 1)"
    }
  }, [_vm._v("抱歉，暂无社保接单")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };