import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "clearfix"
  }, [_vm._l(_vm.fileList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "Uploading_img_warps"
    }, [_c("div", {
      staticClass: "Uploading_img_warp"
    }, [_c("div", {
      staticClass: "Uploading_imgs"
    }, [item.imgUrl && _vm.limit !== 0 ? _c("div", {
      staticClass: "masking"
    }, [_c("div", {
      staticClass: "maskings"
    }, [item.type === "img" ? _c("a-icon", {
      staticClass: "icon",
      staticStyle: {
        color: "#fdfdfd"
      },
      attrs: {
        type: "eye"
      },
      on: {
        click: function click($event) {
          return _vm.handlePreview(item);
        }
      }
    }) : _vm._e(), item.type === "file" ? _c("a-icon", {
      staticClass: "icon",
      staticStyle: {
        color: "#fdfdfd"
      },
      attrs: {
        type: "eye-invisible"
      }
    }) : _vm._e(), _c("a-icon", {
      staticClass: "icon",
      staticStyle: {
        color: "#fdfdfd"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.ondelete(index);
        }
      }
    })], 1)]) : _vm._e(), item.type === "img" && item.imgUrl ? _c("img", {
      staticClass: "Uploading_img",
      attrs: {
        src: item.imgUrl,
        alt: ""
      }
    }) : _vm._e(), _c("div", [!item.imgUrl ? _c("div", {
      staticClass: "Uploading_loading"
    }, [_c("a-icon", {
      staticStyle: {
        "font-size": "24px"
      },
      attrs: {
        slot: "indicator",
        type: "loading",
        spin: ""
      },
      slot: "indicator"
    })], 1) : _vm._e()]), item.type === "file" && item.imgUrl ? _c("a-icon", {
      staticClass: "Uploading_file",
      attrs: {
        type: "file-text"
      }
    }) : _vm._e()], 1)]), _c("p", {
      staticStyle: {
        overflow: "hidden",
        "white-space": "nowrap",
        "text-overflow": "ellipsis"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), _c("a-upload", {
    staticClass: "uploading",
    attrs: {
      action: "/file/api/v1/site/upload/inline",
      "list-type": "picture-card",
      "file-list": _vm.fileList,
      multiple: _vm.multiple,
      name: "files",
      accept: ".doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .jpg, .jpeg, .png",
      showUploadList: false
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.fileList.length < _vm.limit ? _c("div", [_vm._t("antUpload", function () {
    return [_c("a-icon", {
      attrs: {
        type: "plus"
      }
    }), _c("div", {
      staticClass: "ant-upload-text"
    }, [_vm._v("上传")])];
  })], 2) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };