export default {
  name: "CustomTree",
  props: {
    treeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isRadio: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    chose: function chose(item, data) {
      item.isSelected = !item.isSelected;
      this.$emit("change", item);
    }
  }
};