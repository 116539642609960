export var columnsList = [{
  title: "姓名",
  dataIndex: "empName",
  width: 120,
  ellipsis: true,
  scopedSlots: {
    customRender: "empName"
  }
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200,
  ellipsis: true,
  scopedSlots: {
    customRender: "idCard"
  }
}, {
  title: "用工单位",
  dataIndex: "companyName",
  width: 150,
  ellipsis: true,
  scopedSlots: {
    customRender: "companyName"
  }
}, {
  title: "商保方案",
  dataIndex: "schemeName",
  width: 150,
  ellipsis: true,
  scopedSlots: {
    customRender: "schemeName"
  }
}, {
  title: "保险公司",
  dataIndex: "insCompanyName",
  width: 150,
  ellipsis: true,
  scopedSlots: {
    customRender: "insCompanyName"
  }
}, {
  title: "保费",
  dataIndex: "monthAmount",
  width: 100,
  ellipsis: true,
  scopedSlots: {
    customRender: "monthAmount"
  }
}, {
  title: "人员职业分类",
  dataIndex: "careerType",
  width: 100,
  ellipsis: true,
  scopedSlots: {
    customRender: "careerType"
  }
}, {
  title: "在保有效区间",
  dataIndex: "dateRange",
  width: 200,
  ellipsis: true,
  scopedSlots: {
    customRender: "dateRange"
  }
}, {
  title: "总保费",
  dataIndex: "insAmount",
  width: 100,
  ellipsis: true,
  scopedSlots: {
    customRender: "insAmount"
  }
}, {
  title: "操作",
  dataIndex: "action",
  fixed: "right",
  width: 60,
  ellipsis: true,
  scopedSlots: {
    customRender: "action"
  }
}];
export var columnsResultList = [{
  title: "姓名",
  dataIndex: "name",
  width: 120,
  ellipsis: true,
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200,
  ellipsis: true,
  scopedSlots: {
    customRender: "idCard"
  }
}, {
  title: "手机号",
  dataIndex: "phone",
  width: 150,
  ellipsis: true,
  scopedSlots: {
    customRender: "phone"
  }
}, {
  title: "用工单位",
  dataIndex: "companyName",
  width: 150,
  ellipsis: true,
  scopedSlots: {
    customRender: "companyName"
  }
}, {
  title: "商保方案",
  dataIndex: "schemeName",
  width: 150,
  ellipsis: true,
  scopedSlots: {
    customRender: "schemeName"
  }
}, {
  title: "在保有效区间",
  dataIndex: "dateRange",
  width: 200,
  ellipsis: true,
  scopedSlots: {
    customRender: "dateRange"
  }
}, {
  title: "职业分类",
  dataIndex: "careerType",
  width: 100,
  ellipsis: true,
  scopedSlots: {
    customRender: "careerType"
  }
}, {
  title: "保费",
  dataIndex: "monthAmount",
  width: 100,
  ellipsis: true,
  scopedSlots: {
    customRender: "monthAmount"
  }
}, {
  title: "失败原因",
  dataIndex: "errorCause",
  width: 100,
  ellipsis: true,
  scopedSlots: {
    customRender: "errorCause"
  }
}, {
  title: "操作",
  dataIndex: "action",
  fixed: "right",
  width: 160,
  ellipsis: true,
  scopedSlots: {
    customRender: "action"
  }
}];
export var columnsRecordList = [{
  title: "商保方案",
  dataIndex: "schemeName",
  width: 150,
  ellipsis: true,
  scopedSlots: {
    customRender: "schemeName"
  }
}, {
  title: "保险公司",
  dataIndex: "insCompanyName",
  width: 150,
  ellipsis: true,
  scopedSlots: {
    customRender: "insCompanyName"
  }
}, {
  title: "在保有效区间",
  dataIndex: "dateRange",
  width: 200,
  ellipsis: true,
  scopedSlots: {
    customRender: "dateRange"
  }
}, {
  title: "职业分类",
  dataIndex: "careerType",
  width: 100,
  ellipsis: true,
  scopedSlots: {
    customRender: "careerType"
  }
}, {
  title: "保费",
  dataIndex: "monthAmount",
  width: 100,
  ellipsis: true,
  scopedSlots: {
    customRender: "monthAmount"
  }
}, {
  title: "总保费",
  dataIndex: "insAmount",
  width: 100,
  ellipsis: true,
  scopedSlots: {
    customRender: "insAmount"
  }
}, {
  title: "操作",
  dataIndex: "action",
  width: 100,
  ellipsis: true,
  scopedSlots: {
    customRender: "action"
  }
}];