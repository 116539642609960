import request from "@/utils/request";
// 查询分组下公司
export function findCompanyGroup(data) {
  return request({
    url: "/hr/api/v1/childrenCompany/listAll",
    method: "GET",
    params: data
  });
}
// 查询公司内信息
export function selectCompanyAndEmpCountStatisticsReq(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/selectCompanyAndEmpCountStatistics",
    method: "post",
    data: data
  });
}
// 查询用户用工单位
export function getByUserId(params) {
  return request({
    url: "/hr/api/v1/childrenCompany/selectByUserId",
    method: "GET",
    params: params
  });
}
// 查询账号
export function getSite(params, id) {
  return request({
    url: "/admin/api/v1/permissions/roleList/site/".concat(id),
    method: "GET",
    params: params
  });
}
// 查询账号
export function getList(params) {
  return request({
    url: "/hr/api/v1/users/list",
    method: "GET",
    params: params
  });
}
// export function getByUserId(params) {
//   return request({
//     url: "/hr/api/v1/childrenCompany/selectByUserId",
//     method: "GET",
//     params: params
//   });
// }