import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import { parseQueryString, buildTabUrl, isContainByString } from "@/utils/index";
import { getCategoriesDetail, updateCategories, getContractGroups as _getContractGroups, getSealListAll, updateByAdmin } from "@/api/company/contract";
export default {
  data: function data() {
    return {
      isContainByString: isContainByString,
      buildTabUrl: buildTabUrl,
      parseQueryString: parseQueryString,
      updateVisible: false,
      confirmLoading: false,
      companyId: this.$store.state.hr.companyId,
      updateForm: this.$form.createForm(this, {
        name: "updateForm"
      }),
      signForm: this.$form.createForm(this, {
        name: "signForm"
      }),
      fileForm: this.$form.createForm(this, {
        name: "fileForm"
      }),
      groupList: [],
      currentCategory: {},
      categorieslistAll: [],
      sealList: [],
      personOnly: false,
      enterpriseOnly: false,
      fileTypeChange: false,
      signedWay: [{
        name: "企业-员工双方签署 (默认员工先行签署，企业再签署)",
        value: "both"
      }, {
        name: "员工单方签署",
        value: "person_only"
      }, {
        name: "企业单方签署",
        value: "enterprise_only"
      }],
      validityDay: [{
        day: "长期有效",
        id: -1
      }, {
        day: "1天",
        id: 1
      }, {
        day: "2天",
        id: 2
      }, {
        day: "3天",
        id: 3
      }, {
        day: "4天",
        id: 4
      }, {
        day: "5天",
        id: 5
      }, {
        day: "6天",
        id: 6
      }, {
        day: "7天",
        id: 7
      }, {
        day: "8天",
        id: 8
      }, {
        day: "9天",
        id: 9
      }, {
        day: "10天",
        id: 10
      }, {
        day: "11天",
        id: 11
      }, {
        day: "12天",
        id: 12
      }, {
        day: "13天",
        id: 13
      }, {
        day: "14天",
        id: 14
      }, {
        day: "15天",
        id: 15
      }, {
        day: "16天",
        id: 16
      }, {
        day: "17天",
        id: 17
      }, {
        day: "18天",
        id: 18
      }, {
        day: "19天",
        id: 19
      }, {
        day: "20天",
        id: 20
      }, {
        day: "21天",
        id: 21
      }, {
        day: "22天",
        id: 22
      }, {
        day: "23天",
        id: 23
      }, {
        day: "24天",
        id: 24
      }, {
        day: "25天",
        id: 25
      }, {
        day: "26天",
        id: 26
      }, {
        day: "27天",
        id: 27
      }, {
        day: "28天",
        id: 28
      }, {
        day: "29天",
        id: 29
      }, {
        day: "30天",
        id: 30
      }],
      enumeratelist: [{
        name: "固定期限劳动合同",
        value: "laborContractLimit"
      }, {
        name: "无固定期限劳动合同",
        value: "laborContractUnlimit"
      }, {
        name: "以完成一定工作任务为期限的劳动合同",
        value: "laborContractJobs"
      }, {
        name: "实习协议",
        value: "internAccords"
      }, {
        name: "劳务合同",
        value: "laborContract"
      }, {
        name: "返聘协议",
        value: "reemployAccords"
      }, {
        name: "劳务派遣合同",
        value: "laborContractDispatch"
      }, {
        name: "借调合同",
        value: "borrowedContract"
      }, {
        name: "约定书",
        value: "engagementLetter"
      }, {
        name: "兼职合同",
        value: "partTime"
      }, {
        name: "见习协议",
        value: "noviciateAgreement"
      }, {
        name: "离职合同",
        value: "dimissionContract"
      }, {
        name: "退休返聘合同",
        value: "retireReEmploy"
      }, {
        name: "其它",
        value: "other"
      }]
    };
  },
  created: function created() {
    this.getDetail();
    this.initValue();
    if (!this.$route.meta.pageType) {
      this.getSealList();
    }
  },
  methods: {
    initValue: function initValue() {
      var _this = this;
      if (sessionStorage.getItem("signedsStatus")) {
        var categoryUpdateDTOFront = JSON.parse(sessionStorage.getItem("categoryUpdateDTO"));
        if (categoryUpdateDTOFront.signType === "person_only") {
          this.personOnly = true;
        } else {
          this.personOnly = false;
        }
        if (categoryUpdateDTOFront.signType === "enterprise_only") {
          this.enterpriseOnly = true;
        } else {
          this.enterpriseOnly = false;
        }
        setTimeout(function () {
          _this.signForm.setFieldsValue({
            signType: categoryUpdateDTOFront.signType || _this.signedWay[0].value,
            enterpriseSealId: categoryUpdateDTOFront.enterpriseSealId || undefined,
            expireDays: categoryUpdateDTOFront.expireDays || _this.validityDay[0].id,
            fileType: categoryUpdateDTOFront.fileType || undefined
          });
        }, 0);
      }
    },
    getDetail: function getDetail(t) {
      var _this2 = this;
      this.categorieslistAll = [];
      var id = this.$route.query.id;
      getCategoriesDetail(id, this.$route.meta.pageType).then(function (res) {
        _this2.categorieslistAll.push(res.data);
        _this2.currentCategory = res.data;
        if (t !== "edit") {
          if (!sessionStorage.getItem("signedsStatus")) {
            if (_this2.currentCategory.signType === "person_only") {
              _this2.personOnly = true;
            } else {
              _this2.personOnly = false;
            }
            if (_this2.currentCategory.signType === "enterprise_only") {
              _this2.enterpriseOnly = true;
            } else {
              _this2.enterpriseOnly = false;
            }
            setTimeout(function () {
              _this2.signForm.setFieldsValue({
                signType: _this2.currentCategory.signType || _this2.signedWay[0].value,
                enterpriseSealId: _this2.currentCategory.enterpriseSealId || undefined,
                expireDays: _this2.currentCategory.expireDays || _this2.validityDay[0].id,
                fileType: _this2.currentCategory.fileType || undefined
              });
            });
          }
        }
      });
    },
    // 获取分组列表
    getContractGroups: function getContractGroups() {
      var _this3 = this;
      _getContractGroups({
        subjectId: this.isContainByString("contractAdmin") ? "0" : this.companyId
      }).then(function (res) {
        _this3.groupList = res.data;
      });
    },
    // 获取印章列表
    getSealList: function getSealList() {
      var _this4 = this;
      var data = {
        status: 1,
        subjectId: this.isContainByString("contractAdmin") ? "0" : this.companyId
      };
      getSealListAll(data).then(function (res) {
        _this4.sealList = res.data;
      });
    },
    updateCategory: function updateCategory() {
      var _this5 = this;
      this.updateVisible = true;
      if (this.$route.meta.pageType) {
        setTimeout(function () {
          _this5.updateForm.setFieldsValue({
            name: _this5.currentCategory.name,
            description: _this5.currentCategory.description
          });
        });
      } else {
        this.getContractGroups();
        setTimeout(function () {
          _this5.updateForm.setFieldsValue({
            name: _this5.currentCategory.name,
            description: _this5.currentCategory.description,
            groupId: _this5.currentCategory.groupId
          });
        });
      }
    },
    // 确定编辑合同分类
    confirmAddType: function confirmAddType(e) {
      var _this6 = this;
      e.preventDefault();
      this.updateForm.validateFields(function (err, values) {
        values.sort = "1";
        if (!err) {
          var types = _this6.isContainByString("console") || _this6.isContainByString("contractAdmin");
          if (_this6.$route.meta.pageType) {
            var v = {
              id: _this6.$route.query.id,
              name: values.name,
              groupId: values.groupId,
              description: values.description,
              subjectId: types ? "0" : _this6.$store.state.hr.companyId
            };
            updateByAdmin(v).then(function (res) {
              _this6.$message.success("编辑成功");
              sessionStorage.setItem("contractTypeQueryName", values.name);
              _this6.updateVisible = false;
              _this6.updateForm.resetFields();
              _this6.getDetail("edit");
            });
          } else {
            var json = _objectSpread(_objectSpread({}, values), {}, {
              subjectId: types ? "0" : _this6.$store.state.hr.companyId,
              id: _this6.$route.query.id
            });
            if (_this6.isContainByString("contractAdmin")) json.isSiteUpdate = true;
            updateCategories(json).then(function (res) {
              _this6.$message.success("编辑成功");
              var path = _this6.$router.history.current.path;
              var query = _this6.$router.history.current.query;
              var newQuery = JSON.parse(JSON.stringify(query));
              newQuery.groupId = values.groupId;
              _this6.$router.replace({
                path: path,
                query: newQuery
              });
              sessionStorage.setItem("contractTypeQueryName", values.name);
              _this6.updateVisible = false;
              _this6.updateForm.resetFields();
              _this6.getDetail("edit");
            });
          }
        }
      });
    },
    createSeal: function createSeal() {
      this.$router.push({
        path: "stamp"
      });
    },
    selectSignWays: function selectSignWays(e) {
      var _this7 = this;
      if (e === "person_only") {
        this.personOnly = true;
      } else {
        this.personOnly = false;
      }
      if (e === "enterprise_only") {
        this.enterpriseOnly = true;
      } else {
        this.enterpriseOnly = false;
      }
      setTimeout(function () {
        _this7.signForm.setFieldsValue({
          enterpriseSealId: undefined
        });
      });
    },
    watchChange: function watchChange(e) {
      this.sealList.some(function (ele, i) {
        if (ele.id === e) {
          return true;
        }
      });
      var fileChangeItem = this.enumeratelist.filter(function (item) {
        return item.value === e;
      });
      if (fileChangeItem.length > 0) {
        this.fileTypeChange = true;
      } else {
        this.fileTypeChange = false;
      }
    },
    // 签署方式保存
    saveSign: function saveSign() {
      var _this8 = this;
      this.signForm.validateFields(function (err, values) {
        values.sort = "1";
        if (!err) {
          if (values.signType === "person_only") {
            delete values.enterpriseSealId;
          }
          var categoryUpdateDTO = _objectSpread({}, values);
          sessionStorage.setItem("signedsStatus", "signedsStatus");
          sessionStorage.setItem("cateSignType", values.signType);
          sessionStorage.setItem("categoryUpdateDTO", JSON.stringify(categoryUpdateDTO));
          _this8.setNextCurrent();
        }
      });
    },
    setNextCurrent: function setNextCurrent(val) {
      this.$emit("upData", 1);
    },
    sealFun: function sealFun(v, t) {
      var json = {};
      this.sealList.some(function (e) {
        if (e.id === v) {
          json.name = e.name;
          json.img = e.sealString;
          return true;
        }
      });
      return json[t];
    }
  }
};