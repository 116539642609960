import request from "@/utils/request";
export function getCalculateBatches(params) {
  return request({
    url: "/tax/api/v1/calculateBatches",
    method: "GET",
    params: params
  });
}
export function delCalculateBatches(data) {
  return request({
    url: "/tax/api/v1/calculateBatches/".concat(data),
    method: "DELETE"
  });
}
export function delCalculateBatchesdeleteById(calculateBatchId) {
  return request({
    url: "/tax/api/v1/calculateBatches/deleteById/".concat(calculateBatchId),
    method: "DELETE"
  });
}
export function getCompanyAuthoritiesListAll(params) {
  return request({
    url: "/tax/api/v1/taxCompanies/listTree",
    method: "GET",
    params: params
  });
}