import "core-js/modules/es.function.name.js";
import { getEmpChanges, leavePosition } from "@/api/staff/empChanges";
import { findEmpInfoTitle } from "@/api/staff/staff";
export default {
  name: "Detail",
  filters: {
    formatDate: formatDate
  },
  data: function data() {
    return {
      form: this.$form.createForm(this),
      companyId: "",
      name: "",
      status: "",
      formalType: "",
      phone: "",
      joinDate: "",
      employInfoId: this.$route.query.empInfoId,
      employInfo: "",
      empIdCardVo: {},
      avatarUrl: "",
      empChanges: {},
      quitTypeOptions: [{
        label: "主动离职",
        value: 1
      }, {
        label: "被动离职",
        value: 2
      }, {
        label: "其它",
        value: 3
      }],
      personReasonOptions: [{
        label: "请选择",
        value: ""
      }, {
        label: "个人身体健康因素",
        value: 1
      }, {
        label: "家庭原因",
        value: 2
      }, {
        label: "交通不便，离住处远",
        value: 3
      }, {
        label: "回校深造",
        value: 4
      }, {
        label: "找到更好的企业工作",
        value: 5
      }, {
        label: "实习生返校",
        value: 6
      }, {
        label: "自己经营生意",
        value: 7
      }, {
        label: "转换行业",
        value: 8
      }, {
        label: "其它",
        value: 9
      }],
      companyReasonOptions: [{
        label: "请选择",
        value: ""
      }, {
        label: "工作量太大，压力大",
        value: 1
      }, {
        label: "工资低",
        value: 2
      }, {
        label: "福利",
        value: 3
      }, {
        label: "与领导关系不和谐",
        value: 4
      }, {
        label: "无晋升机会",
        value: 5
      }, {
        label: "不满意公司策略和措施",
        value: 6
      }, {
        label: "同事关系不融洽",
        value: 7
      }, {
        label: "获取工作必须的资料信息或支持困难",
        value: 8
      }, {
        label: "工作时间",
        value: 9
      }, {
        label: "工作意见不受重视",
        value: 10
      }, {
        label: "工作目标/要求不明确",
        value: 11
      }, {
        label: "其它",
        value: 12
      }],
      rules: {
        quitDate: {
          required: true,
          message: "不可为空"
        },
        quitType: {
          required: true,
          message: "不可为空"
        },
        ssAttritionMonth: {
          required: true,
          message: "不可为空"
        },
        pfAttritionMonth: {
          required: true,
          message: "不可为空"
        }
      }
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    alertResultMessage: function alertResultMessage(result) {
      var type = "success";
      if (result.code !== 0) {
        type = "error";
      }
      this.$notification[type]({
        message: result.msg
      });
    },
    cancel: function cancel() {
      this.$router.go(-1);
    },
    submitLeaveInfo: function submitLeaveInfo(e) {
      e.preventDefault();
      var that = this;
      this.form.validateFields(function (err, values) {
        if (!err) {
          that.$confirm({
            title: "离职",
            content: "确定离职该员工此操作不可逆, 是否继续?",
            onOk: function onOk() {
              values.empIds = [that.employInfoId];
              values.companyId = that.companyId;
              leavePosition(values).then(function (res) {
                that.alertResultMessage(res);
                if (res.code === 200) {
                  that.cancel();
                }
              });
            },
            onCancel: function onCancel() {}
          });
        }
      });
    },
    getEmployeeStatus: function getEmployeeStatus(status, formalType) {
      if (status === 1) {
        return "待入职";
      } else if (status === 2) {
        if (formalType === 1) {
          return "适用期";
        }
      } else if (status === 4) {
        return "离职";
      }
      // return "在职"
    },
    initData: function initData() {
      var _this = this;
      findEmpInfoTitle({
        id: this.employInfoId,
        companyId: this.companyId
      }).then(function (res) {
        var data = res.data;
        _this.name = data.name;
        _this.joinDate = data.joinDate;
        _this.phone = data.phone;
        _this.avatarUrl = data.empPhoto;
        _this.formalType = data.formalType;
        _this.status = data.status;
      });
    }
  }
};
function formatDate(value) {
  if (isNaN(value) || value === "") {
    return "";
  }
  var date = new Date(value);
  var Y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();
  var H = date.getHours();
  var i = date.getMinutes();
  var s = date.getSeconds();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  if (H < 10) {
    H = "0" + H;
  }
  if (i < 10) {
    i = "0" + i;
  }
  if (s < 10) {
    s = "0" + s;
  }
  var t = Y + "-" + m + "-" + d;
  return t;
}