var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("XqForm", {
    attrs: {
      form: _vm.form,
      customize: _vm.formConfig["customize"]
    }
  }, [_c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_vm._l(_vm.fieldKeys, function (key) {
    return [_c("a-col", {
      key: key,
      attrs: {
        span: 12
      }
    }, [_c("XqItem", {
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        dynamicData: _vm.dynamicData,
        item: _vm.formatFormField(_vm.formFields[key]),
        validator: _vm.getValidator(key),
        editable: true,
        labelAlign: "left"
      }
    })], 1)];
  })], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };