import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import ListContent from "./listContent.vue";
import { loadEventTypeReq } from "./templates/api";
import { dispatchListReq, selectAllTemplateBySiteReq } from "./api";
import { mapState } from "vuex";
export default {
  name: "",
  components: {
    ListContent: ListContent
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      eventTypeLst: [],
      dispatchLst: [],
      templateLst: [],
      companyLst: [],
      activeTab: "wwj",
      tipped: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    companyList: function companyList() {
      return this.hr.companyList;
    }
  }),
  watch: {
    companyList: {
      handler: function handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.loadDynamicData();
        } else {
          if (!this.tipped) {
            this.tipped = true;
            this.$message.warning("未查询到用工单位，请先创建用工单位");
          }
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    loadDynamicData: function loadDynamicData() {
      var _this = this;
      loadEventTypeReq().then(function (res) {
        if (res && res.data && res.data.length >= 0) {
          _this.eventTypeLst = res.data.map(function (item) {
            return {
              label: item.dictItemValue,
              value: item.dictItemValue
            };
          });
        } else {
          _this.eventTypeLst = [];
        }
      }).catch(function (err) {
        console.log("loadEventType", err);
      });
      dispatchListReq().then(function (res) {
        if (res && res.data && res.data.length >= 0) {
          _this.dispatchLst = res.data.map(function (item) {
            return {
              label: item.name,
              value: item.id
            };
          });
        } else {
          _this.dispatchLst = [];
        }
      }).catch(function (err) {
        console.log("dispatchList", err);
      });
      selectAllTemplateBySiteReq().then(function (res) {
        if (res && res.data && res.data.length >= 0) {
          _this.templateLst = res.data.map(function (item) {
            return {
              label: item.templateName,
              value: item.id
            };
          });
        } else {
          _this.templateLst = [];
        }
      }).catch(function (err) {
        console.log("dispatchList", err);
      });
      this.companyLst = this.hr.companyList.map(function (item) {
        return {
          label: item.name,
          value: item.id
        };
      });
    },
    onTabChange: function onTabChange(key) {
      if (this.activeTab !== key) {
        this.activeTab = key;
      }
    }
  }
};