import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-layout", [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goOut
    }
  }, [_vm._v(" 批量操作 ")]), _c("a-layout", [_c("a-layout-sider", {
    staticClass: "border-r-line ta-center border",
    staticStyle: {
      "border-right": "none"
    },
    attrs: {
      width: "206"
    }
  }), _c("a-layout-content", {
    staticClass: "ph24 mb20"
  }, [_vm.empNameList && _vm.empNameList.length ? _c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#55565d",
      "margin-top": "32px",
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#fa5051"
    }
  }, [_vm._v("*")]), _vm._v(" " + _vm._s(_vm.$route.query.isEdit ? "调薪人员" : "定薪人员") + " "), _c("div", {
    staticStyle: {
      "margin-left": "24px"
    }
  }, [_vm.empNameList.length < 4 ? _c("div", _vm._l(_vm.empNameList, function (item, index) {
    return _c("span", {
      key: index,
      staticStyle: {
        "font-size": "14px",
        "font-weight": "500",
        color: "#252525"
      }
    }, [_vm._v(" " + _vm._s(index + 1 === _vm.empNameList.length ? item : item + "、") + " ")]);
  }), 0) : _c("div", [_c("span", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "500",
      color: "#252525"
    }
  }, [_vm._v(_vm._s(_vm.empNameList[0] + "、" + _vm.empNameList[1] + "、" + _vm.empNameList[2]))]), _c("span", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#55565d"
    }
  }, [_vm._v(_vm._s("等" + _vm.empNameList.length + "人"))])])])]) : _vm._e(), _c("div", {
    staticClass: "shu"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v(_vm._s(_vm.$route.query.isEdit ? "调薪规则" : "定薪规则"))])]), _c("a-radio-group", {
    staticStyle: {
      "margin-top": "16px"
    },
    on: {
      change: _vm.radioGroupChange
    },
    model: {
      value: _vm.size,
      callback: function callback($$v) {
        _vm.size = $$v;
      },
      expression: "size"
    }
  }, [_c("a-radio-button", {
    attrs: {
      value: "small"
    }
  }, [_vm._v(" 计时制 ")]), _c("a-radio-button", {
    attrs: {
      value: "default"
    }
  }, [_vm._v(" 固定薪资 ")])], 1), _vm.size === "default" ? _c("div", [_c("a-form-model", {
    ref: "ruleForm",
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      model: _vm.form
    }
  }, [_c("a-form-model-item", {
    ref: "template",
    attrs: {
      label: _vm.$route.query.isEdit ? "调薪模板" : "定薪模板",
      prop: "template",
      rules: {
        required: true,
        message: "必选"
      }
    }
  }, [_vm.$route.query.isFixed === "true" ? _c("div", [_c("a-button", {
    staticClass: "modelBotton"
  }, [_vm._v(" " + _vm._s(_vm.fixedSalaryTemplateName ? _vm.fixedSalaryTemplateName : "--") + " ")])], 1) : _c("div", [_vm._l(_vm.templateList, function (item, index) {
    return _c("a-button", {
      key: index,
      staticClass: "modelBotton",
      class: item.style,
      attrs: {
        value: "horizontal"
      },
      on: {
        click: function click($event) {
          return _vm.templateChange(item.id, index, item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), _c("a-button", {
    staticClass: "add-field-add",
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.goIncrease();
      }
    }
  }, [_c("a-icon", {
    staticClass: "add-field-icon",
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v("新增模板 ")], 1)], 2)]), _c("a-form-model-item", {
    ref: "detailsList",
    attrs: {
      label: _vm.$route.query.isEdit ? "调薪明细  正式工资" : "定薪明细",
      prop: "detailsList",
      rules: {
        required: true,
        message: "必填"
      }
    }
  }, [_vm.$route.query.isFixed === "true" ? _c("a-table", {
    attrs: {
      columns: _vm.columns3,
      "row-key": "itemName",
      "data-source": _vm.officialList,
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "amount",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text.toFixed(2)) + " ")];
      }
    }, {
      key: "afterAmount",
      fn: function fn(text, record) {
        return [_c("div", [record.isSum !== "1" ? _c("a-input-number", {
          staticStyle: {
            margin: "-5px 0",
            width: "120px"
          },
          attrs: {
            "default-value": text,
            min: 0,
            step: 1,
            max: 100000,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleInputChange2(value, record.projectId);
            }
          }
        }) : _c("span", [_vm._v(_vm._s(text.toFixed(2)))])], 1)];
      }
    }, {
      key: "amplitude",
      fn: function fn(text, record) {
        return [_c("span", {
          style: {
            color: record.relativecolor
          }
        }, [_vm._v(_vm._s(text))])];
      }
    }], null, false, 2231849095)
  }) : _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "row-key": "itemName",
      "data-source": _vm.detailsList,
      pagination: false
    },
    scopedSlots: _vm._u([_vm._l(["trial", "formal"], function (col) {
      return {
        key: col,
        fn: function fn(text, record) {
          return [_c("div", {
            key: col
          }, [record.isSum !== "1" ? _c("a-input-number", {
            staticStyle: {
              margin: "-5px 0",
              width: "120px"
            },
            attrs: {
              value: text,
              min: 0,
              step: 1,
              max: 100000,
              precision: 2
            },
            on: {
              change: function change(value) {
                return _vm.handleInputChange(value, record.projectId, col);
              }
            }
          }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text.toFixed(2)))])], 1)];
        }
      };
    })], null, true)
  })], 1), _c("a-form-model-item", [_c("label", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("* ")]), _c("span", {
    staticStyle: {
      color: "rgba(0, 0, 0, 0.85)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$route.query.isEdit ? "调薪原因：" : "定薪原因：") + " ")])]), _vm.$route.query.isFixed === "true" ? _c("a-select", {
    staticStyle: {
      width: "300px",
      "margin-left": "28px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reason", $$v);
      },
      expression: "form.reason"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 转正 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 晋升 ")]), _c("a-select-option", {
    attrs: {
      value: 3
    }
  }, [_vm._v(" 调动 ")]), _c("a-select-option", {
    attrs: {
      value: 4
    }
  }, [_vm._v(" 年中调薪 ")]), _c("a-select-option", {
    attrs: {
      value: 5
    }
  }, [_vm._v(" 年度调薪 ")]), _c("a-select-option", {
    attrs: {
      value: 6
    }
  }, [_vm._v(" 特别调薪 ")]), _c("a-select-option", {
    attrs: {
      value: 7
    }
  }, [_vm._v(" 其他 ")])], 1) : _c("span", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v(" 入职定薪")])], 1), _vm.$route.query.isFixed === "true" ? _c("a-form-model-item", [_c("label", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("* ")]), _vm._v(" "), _c("span", {
    staticStyle: {
      color: "rgba(0, 0, 0, 0.85)"
    }
  }, [_vm._v(" 调薪生效时间： ")])]), _c("a-date-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请选择",
      "disabled-date": _vm.disabledEndDate
    },
    on: {
      change: _vm.handleTimeChange
    }
  })], 1) : _vm._e(), _c("a-form-model-item", {
    ref: "remark",
    attrs: {
      prop: "remark",
      label: "备注",
      "label-col": {
        span: 1
      },
      "wrapper-col": {
        span: 13
      }
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "400px"
    },
    attrs: {
      "max-length": 50
    },
    model: {
      value: _vm.form.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  }), _c("a-row", {
    staticClass: "ta-right textarea-tip",
    staticStyle: {
      width: "405px"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.remark ? _vm.form.remark.length : 0) + "/50字 ")])], 1)], 1), _c("a-modal", {
    attrs: {
      title: "新增定薪模板"
    },
    on: {
      ok: _vm.handleAddOk,
      cancel: _vm.handleAddCancel
    },
    model: {
      value: _vm.addVisible,
      callback: function callback($$v) {
        _vm.addVisible = $$v;
      },
      expression: "addVisible"
    }
  }, [_c("a-form-model", {
    ref: "addForm",
    attrs: {
      model: _vm.addForm,
      rules: _vm.rules2,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "模板名称",
      prop: "name"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请填写"
    },
    model: {
      value: _vm.addForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "name", $$v);
      },
      expression: "addForm.name"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "定薪项",
      prop: "itemIds"
    }
  }, [_c("div", {
    staticClass: "fix-salary-item-wrapper"
  }, _vm._l(_vm.fixItems, function (item, index) {
    return _c("div", {
      key: index,
      class: _vm.computeItemClass(item),
      on: {
        click: function click($event) {
          return _vm.handleAddSelected(item);
        }
      }
    }, [_c("span", [_vm._v(" " + _vm._s(item.name) + " ")])]);
  }), 0)])], 1)], 1)], 1) : _vm._e(), _vm.size === "small" || _vm.size === "large" ? _c("div", [_c("div", {
    staticClass: "gj",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("工价规则")]), _c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      placeholder: "请选择",
      allowClear: ""
    },
    on: {
      change: _vm.handleRulesChange
    },
    model: {
      value: _vm.salaryCalculateRuleId,
      callback: function callback($$v) {
        _vm.salaryCalculateRuleId = $$v;
      },
      expression: "salaryCalculateRuleId"
    }
  }, _vm._l(_vm.rulusListCopy, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "15px",
      "font-size": "16px",
      "font-weight": "400",
      color: "#000000"
    }
  }, [_vm._v("已添加规则")]), _c("div", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("a-table", {
    attrs: {
      rowKey: "id",
      columns: _vm.columns2,
      "data-source": _vm.dataList,
      pagination: _vm.paginationOpt
    }
  })], 1)])]) : _vm._e(), _c("div", {
    staticClass: "shu"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("招工劳务费规则")])]), _c("div", {
    staticClass: "gj",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("招工劳务费规则")]), _c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      placeholder: "请选择",
      allowClear: ""
    },
    on: {
      change: _vm.redPackChange
    },
    model: {
      value: _vm.redPackId,
      callback: function callback($$v) {
        _vm.redPackId = $$v;
      },
      expression: "redPackId"
    }
  }, _vm._l(_vm.redPack, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "15px",
      "font-size": "16px",
      "font-weight": "400",
      color: "#000000"
    }
  }, [_vm._v("已添加规则")]), _c("div", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("a-table", {
    attrs: {
      rowKey: "id",
      columns: _vm.columns2,
      "data-source": _vm.dataList2,
      pagination: _vm.paginationOpt2
    }
  })], 1), _c("a-divider"), _c("a-row", [_c("a-col", {
    staticClass: "fr"
  }, [_c("a-button", {
    staticClass: "mg_r10",
    on: {
      click: _vm.goOut
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleEmpyleeSubmitType
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1), _c("drawer", {
    ref: "drawers",
    on: {
      update: _vm.update
    }
  }), _c("drawers", {
    ref: "drawersl",
    on: {
      fatherMethod: _vm.fatherMethod
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };