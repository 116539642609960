import request from "@/utils/request";
// 查询用工单位
export function getComany(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/selectComanyWithEmpCount",
    method: "POST",
    data: data
  });
}
// 查询子公司信息
export function getChildrenComany(params) {
  return request({
    // url: "/hr/api/v1/statisticsPage/listAll",
    url: "/hr/api/v1/statisticsPage/pageChildCompany",
    method: "get",
    params: params
  });
}

// 根据子公司信息id查询子公司信息详情及关联信息
export function getChildAndRelation(childCompanyId) {
  return request({
    url: "/hr/api/v1/childrenCompany/findChildAndRelation/".concat(childCompanyId),
    method: "get"
  });
}
// 用工单位查询在职员工数
export function getEmp(params) {
  return request({
    url: "/hr/api/v1/statisticsPage/empContractInfoList",
    method: "get",
    params: params
  });
}
// 导出子公司
export function exportPage(params) {
  return request({
    url: "/hr/api/v1/statisticsPage/export",
    method: "get",
    params: params
  });
}
// 导出用工单位
export function exportPageCompany(params) {
  return request({
    url: "/hr/api/v1/statisticsPage/exportComanyWithEmpCount",
    method: "get",
    params: params
  });
}
// 根据子公司id查询管理员
export function getChildCompanyAdmin(data) {
  return request({
    // url: `/hr/api/v1/statisticsPage/getChildCompanyAdmin`,
    url: "/hr/api/v1/users/pageUserHasRoleInCompanyList",
    method: "post",
    data: data
  });
}
// 根据子公司id查询分管单位
export function getSubCompany(params) {
  return request({
    url: "/hr/api/v1/statisticsPage/getSubCompany",
    method: "get",
    params: params
  });
}