var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wage-set-wrapper p24 page-miH page-bgBack"
  }, [_c("a-tabs", {
    on: {
      change: _vm.tabsChange
    },
    model: {
      value: _vm.tabs,
      callback: function callback($$v) {
        _vm.tabs = $$v;
      },
      expression: "tabs"
    }
  }, [_c("a-tab-pane", {
    key: "0",
    attrs: {
      tab: "薪资组"
    }
  }, [_c("group")], 1), _c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "计税规则"
    }
  }, [_c("rule")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };