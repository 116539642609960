import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "r-people-box page-miH page-bgBack"
  }, [_c("a-tabs", {
    on: {
      change: _vm.callback
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }, _vm._l(_vm.numArr, function (item) {
    return _c("a-tab-pane", {
      key: item.type
    }, [_c("span", {
      attrs: {
        slot: "tab"
      },
      slot: "tab"
    }, [_c("a-badge", {
      attrs: {
        count: item.num,
        offset: [5, -2]
      }
    }, [_vm._v(_vm._s(item.name))])], 1)]);
  }), 1), _c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: "",
      placeholder: "搜索求职者姓名"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.jobSeekerName,
      callback: function callback($$v) {
        _vm.jobSeekerName = $$v;
      },
      expression: "jobSeekerName"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: "",
      placeholder: "求职者/经纪人/内推"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.sourceType,
      callback: function callback($$v) {
        _vm.sourceType = $$v;
      },
      expression: "sourceType"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("求职者")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("经纪人")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("内推")])], 1)], 1), _c("a-col")], 1), _c("div", {
    staticClass: "xq-form-wrapper mt24"
  }, [_c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "8px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-table", {
    attrs: {
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      bordered: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      rowClassName: _vm.rowClass,
      "row-key": _vm.rkFun,
      showHeader: false
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [_c("div", {
            key: index
          }, [col.dataIndex === "name" ? _c("div", [_c("div", {
            staticClass: "table-name"
          }, [_c("div", [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [record.postName ? _c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(record.postName))])]) : _vm._e(), _c("div", {
            staticClass: "overflowsPeople"
          }, [_c("span", {
            staticStyle: {
              cursor: "pointer"
            }
          }, [_vm._v("应聘职位：" + _vm._s(record.postName))])])], 2), _vm._v(" " + _vm._s(record.deliverGmtCreate ? _vm.moment(record.deliverGmtCreate).format("YYYY-MM-DD HH:mm") : "") + " ")], 1)]), _c("div", {
            staticClass: "table-desc tdisplay"
          }, [_c("div", {
            staticClass: "l"
          }, [_c("a-list-item-meta", {
            attrs: {
              description: record.address || ""
            }
          }, [_c("span", {
            attrs: {
              slot: "title"
            },
            slot: "title"
          }, [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [record.name ? _c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(record.name))])]) : _vm._e(), _c("div", {
            staticClass: "overflowsName",
            staticStyle: {
              "font-size": "18px"
            }
          }, [_vm._v(_vm._s(record.name))])], 2), record.sourceType === "broker" ? _c("a-popover", {
            attrs: {
              placement: "right"
            }
          }, [_c("template", {
            slot: "content"
          }, [_c("p", [_vm._v("经纪人：" + _vm._s(record.principalName))]), _c("p", [_vm._v("日期：" + _vm._s(_vm.moment(record.deliverGmtCreate).format("YYYY-MM-DD")))])]), _c("div", {
            staticClass: "title-s-j"
          }, [_vm._v("经纪人")])], 2) : _vm._e(), record.sourceType === "emp" ? _c("a-popover", {
            attrs: {
              placement: "right"
            }
          }, [_c("template", {
            slot: "content"
          }, [_c("p", [_vm._v("内推人：" + _vm._s(record.principalName))]), _c("p", [_vm._v("日期：" + _vm._s(_vm.moment(record.deliverGmtCreate).format("YYYY-MM-DD")))])]), _c("div", {
            staticClass: "title-s-n"
          }, [_vm._v("内推")])], 2) : _vm._e()], 1), record.avatar ? _c("a-avatar", {
            staticStyle: {
              width: "42px",
              height: "42px"
            },
            attrs: {
              slot: "avatar",
              src: record.avatar
            },
            slot: "avatar"
          }) : _vm._e(), !record.avatar && record.gender === 1 ? _c("a-avatar", {
            staticStyle: {
              width: "42px",
              height: "42px"
            },
            attrs: {
              slot: "avatar",
              src: _vm.n1
            },
            slot: "avatar"
          }) : _vm._e(), !record.avatar && record.gender === 2 ? _c("a-avatar", {
            staticStyle: {
              width: "42px",
              height: "42px"
            },
            attrs: {
              slot: "avatar",
              src: _vm.n2
            },
            slot: "avatar"
          }) : _vm._e()], 1)], 1), _c("div", {
            staticClass: "tags pt10"
          }, _vm._l(record.skill, function (item, index) {
            return _c("div", {
              key: index
            }, [_c("a-tooltip", {
              attrs: {
                placement: "top"
              }
            }, [_c("template", {
              slot: "title"
            }, [_c("span", [_vm._v(_vm._s(item))])]), _c("div", {
              staticClass: "tags-item overflowsTags"
            }, [_vm._v(_vm._s(item))])], 2)], 1);
          }), 0)])]) : _vm._e(), col.dataIndex === "createUserName" ? _c("div", [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [record.createUserName ? _c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(record.createUserName))])]) : _vm._e(), _c("div", {
            staticClass: "table-desc pt10 overCreate"
          }, [_vm._v("创建来源：" + _vm._s(record.createUserName))])], 2)], 1) : _vm._e(), col.dataIndex === "status" ? _c("div", [_c("div", {
            staticClass: "table-desc pt10"
          }, [_vm._v(" 状态： "), record.status == 1 ? _c("span", [_vm._v("已投递")]) : _vm._e(), record.status == 2 ? _c("span", [_vm._v("已约面")]) : _vm._e(), record.status == 3 ? _c("span", [_vm._v("待入职")]) : _vm._e(), record.status == 4 ? _c("span", [_vm._v("已招聘")]) : _vm._e(), record.status == 5 ? _c("span", [_vm._v("简历不合适")]) : _vm._e(), record.status == 6 ? _c("span", [_vm._v("面试已拒绝")]) : _vm._e(), record.status == 7 ? _c("span", [_vm._v("面试不合适")]) : _vm._e(), record.status == 8 ? _c("span", [_vm._v("放弃入职")]) : _vm._e(), record.status == 9 ? _c("span", [_vm._v("取消面试")]) : _vm._e()])]) : _vm._e(), col.dataIndex === "remark" ? _c("div", [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [record.remark ? _c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(record.remark))])]) : _vm._e(), _c("div", {
            staticClass: "table-desc pt10 overflowsRemak"
          }, [_vm._v("备注：" + _vm._s(record.remark))])], 2)], 1) : _vm._e(), col.dataIndex === "tableAction" ? _c("div", {
            staticStyle: {
              "margin-top": "56px"
            }
          }, [_c("a", {
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.rowclick(record);
              }
            }
          }, [_vm._v("人员信息")]), _vm.status === "1" ? _c("span", [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.onAdd(record);
              }
            }
          }, [_vm._v("安排面试")])], 1) : _vm._e(), record.status != "1" && record.status != "5" ? _c("span", [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.onMsg(record);
              }
            }
          }, [_vm._v("面试详情")])], 1) : _vm._e(), _vm.status === "3" ? _c("span", [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.onInterview(record.deliverRecordId, record.companyUnitId);
              }
            }
          }, [_vm._v("进入已招聘")])], 1) : _vm._e(), _vm.status === "1" || _vm.status === "3" ? _c("span", [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.deleteRecord(record.deliverRecordId, record.companyUnitId);
              }
            }
          }, [_vm._v(_vm._s(_vm.status === "3" ? "放弃入职" : "不合适"))])], 1) : _vm._e(), _vm.status === "5" ? _c("span", [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.onAdd(record);
              }
            }
          }, [_vm._v("重新发起面试")])], 1) : _vm._e()]) : _vm._e()])];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1), _c("drawer", {
    attrs: {
      visible: _vm.drawerVisible,
      talentPoolId: _vm.talentPoolId,
      companyUnitsInfo: _vm.companyUnitsInfo,
      deliverRecordId: _vm.deliverRecordId,
      status: _vm.status,
      mjson: _vm.mjson
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.drawerVisible = $event;
      },
      onMsg: function onMsg($event) {
        _vm.interviewDrawerVisible = true;
      },
      onAction: _vm.onAction,
      onShare: _vm.onShare
    }
  }), _c("interviewDrawer", {
    attrs: {
      visible: _vm.interviewDrawerVisible,
      deliverRecordId: _vm.deliverRecordId,
      talentPoolId: _vm.talentPoolId,
      mjson: _vm.mjson
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.interviewDrawerVisible = $event;
      },
      onShare: _vm.onShare
    }
  }), _c("interview", {
    attrs: {
      visible: _vm.interviewVisible,
      deliverRecordId: _vm.deliverRecordId,
      talentPoolId: _vm.talentPoolId,
      mjson: _vm.mjson
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.interviewVisible = $event;
      },
      onShare: _vm.onShare
    }
  }), _c("share", {
    attrs: {
      visible: _vm.visibles,
      qrCodeUrlV: _vm.qrCodeUrlV
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibles = $event;
      }
    }
  }), _c("interviewMsg", {
    attrs: {
      visible: _vm.interviewMsgVisible,
      interviewId: _vm.interviewId,
      info: _vm.info,
      status: _vm.statusMsg
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.interviewMsgVisible = $event;
      },
      onEvaluate: _vm.onEvaluate,
      onCloseInterview: _vm.onCloseInterview
    }
  }), _c("evaluate", {
    attrs: {
      visible: _vm.evaluateVisible,
      evaluateId: _vm.evaluateId,
      recruitInfoId: _vm.recruitInfoId,
      companyUnitsId: _vm.companyUnitsId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.evaluateVisible = $event;
      },
      upDate: _vm.onGetPageJobSeekerByStatus
    }
  }), _c("note", {
    attrs: {
      visible: _vm.noteVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.noteVisible = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };