import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white",
    staticStyle: {
      display: "flex",
      width: "100%",
      height: "calc(100vh - 112px)",
      overflow: "hidden"
    }
  }, [_c("div", {
    staticClass: "leftBox"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "fs16 fw500",
    staticStyle: {
      color: "#252525"
    }
  }, [_vm._v("供应商分组")]), _c("a-button", {
    staticStyle: {
      padding: "0"
    },
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.showAddGroup
    }
  }, [_c("a-icon", {
    staticStyle: {
      color: "#000000"
    },
    attrs: {
      type: "plus"
    }
  })], 1)], 1), _c("a-input-search", {
    staticClass: "mt12",
    staticStyle: {
      width: "252px"
    },
    attrs: {
      placeholder: "搜索分组",
      "allow-clear": ""
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.groupNameQuery,
      callback: function callback($$v) {
        _vm.groupNameQuery = $$v;
      },
      expression: "groupNameQuery"
    }
  }), _vm.groupList ? _c("div", {
    staticClass: "mt12"
  }, [_c("a-tree", {
    staticClass: "tree-control",
    attrs: {
      treeData: _vm.groupList,
      defaultExpandAll: true,
      selectedKeys: [_vm.currentGroup.value]
    },
    scopedSlots: _vm._u([{
      key: "custom",
      fn: function fn(item) {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center"
          },
          on: {
            click: function click($event) {
              return _vm.onGroupClick(item);
            }
          }
        }, [_c("a-tooltip", {
          attrs: {
            title: "".concat(item.title, "\uFF08").concat(item.count || 0, "\uFF09")
          }
        }, [_c("div", {
          staticClass: "item-text"
        }, [_vm._v(_vm._s(item.title) + "（" + _vm._s(item.count || 0) + "）")])]), item.type !== "site" ? _c("div", {
          staticClass: "ta-right",
          staticStyle: {
            width: "40px"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.showEditGroup(item);
            }
          }
        }, [_c("a-icon", {
          attrs: {
            type: "setting",
            theme: "filled"
          }
        })], 1) : _vm._e()], 1)];
      }
    }], null, false, 2537163475)
  })], 1) : _vm._e()], 1), _vm.columns ? _c("a-spin", {
    staticClass: "p24",
    staticStyle: {
      width: "calc(100% - 300px)"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "p24 mb24",
    staticStyle: {
      border: "1px solid rgba(217, 217, 217, 1)",
      "border-radius": "4px"
    }
  }, [_c("div", {
    staticClass: "fs18 fw500",
    staticStyle: {
      color: "rgba(60, 61, 67, 1)"
    }
  }, [_vm._v(_vm._s(this.currentGroup ? this.currentGroup.title : ""))]), this.currentGroup && this.currentGroup.desc ? _c("div", {
    staticClass: "mt16 fs12",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v(" " + _vm._s(this.currentGroup ? this.currentGroup.desc : "") + " ")]) : _vm._e()]), _c("TableTitle", {
    staticStyle: {
      padding: "0",
      height: "32px",
      "line-height": "32px",
      margin: "4px 0 16px"
    }
  }, [_c("template", {
    slot: "title"
  }), _c("template", {
    slot: "action"
  }, [_vm.dropdownDisabled ? _c("a-tooltip", {
    attrs: {
      title: "请先选中供应商分组再新增供应商"
    }
  }, [_c("a-icon", {
    staticClass: "mr12",
    staticStyle: {
      color: "#d9d9d9",
      "font-size": "16px"
    },
    attrs: {
      type: "question-circle",
      theme: "filled"
    }
  })], 1) : _vm._e(), _c("a-dropdown", {
    attrs: {
      disabled: _vm.dropdownDisabled
    }
  }, [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    on: {
      click: function click($event) {
        return _vm.goDetail("add");
      }
    }
  }, [_vm._v("手动添加")]), _c("a-menu-item", {
    on: {
      click: _vm.genQrcode
    }
  }, [_vm._v("链接邀请")])], 1), _c("a-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("新增供应商")])], 1)], 1)], 2), _c("TableBody", {
    staticClass: "mt12 gys-tb",
    attrs: {
      selectHead: "",
      selection: "",
      tableName: "gys",
      tableHeadUrl: "/hr/api/v1/formGroups/getHead",
      hasAddition: true,
      selectedHead: _vm.selectedHead,
      selectedRowKeys: _vm.selectedRowKeys,
      source: _vm.gysList,
      headerRowID: "sortable_list",
      columns: _vm.columns,
      subjectId: _vm.siteId,
      subjectType: "site"
    },
    on: {
      "update:selectedHead": function updateSelectedHead($event) {
        _vm.selectedHead = $event;
      },
      "update:selected-head": function updateSelectedHead($event) {
        _vm.selectedHead = $event;
      },
      "update:selectedRowKeys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      "update:selected-row-keys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      onClose: function onClose() {},
      onSearch: function onSearch($event) {
        return _vm.selectFunc();
      },
      onReload: function onReload($event) {
        return _vm.selectFunc();
      },
      headLoaded: _vm.onHeadLoaded
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(prop) {
        return [_c("a-table", {
          class: prop.className,
          attrs: {
            components: prop.components,
            columns: prop.columns,
            scroll: {
              x: prop.scrollWidth,
              y: _vm.scrollHeight
            },
            customHeaderRow: function customHeaderRow() {
              return {
                domProps: {
                  id: prop.headerRowID
                }
              };
            },
            "data-source": _vm.gysList,
            "row-key": "id",
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange
            },
            pagination: _vm.pagination
          },
          on: {
            change: _vm.handleTableChange
          },
          scopedSlots: _vm._u([_vm._l(prop.columns, function (col) {
            return {
              key: col.dataIndex,
              fn: function fn(text, record) {
                return [col.dataIndex === "action" ? _c("span", {
                  key: col.dataIndex,
                  staticClass: "table-action"
                }, [_c("a-space", {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    width: "80px"
                  }
                }, [_c("a", {
                  on: {
                    click: function click($event) {
                      return _vm.goDetail("edit", record.id);
                    }
                  }
                }, [_vm._v("详情")]), _c("a-popconfirm", {
                  attrs: {
                    title: "确认要删除记录吗？",
                    okText: "确认",
                    cancelText: "取消"
                  },
                  on: {
                    confirm: function confirm($event) {
                      return _vm.deleteGys(record);
                    }
                  }
                }, [_c("a", [_vm._v("删除")])])], 1)], 1) : _vm.columnMap[col.dataIndex] ? _c("a-tooltip", {
                  key: col.dataIndex,
                  attrs: {
                    title: _vm.formatColumnItem(_vm.columnMap[col.dataIndex], text)
                  }
                }, [_c("span", [_vm._v(" " + _vm._s(_vm.formatColumnItem(_vm.columnMap[col.dataIndex], text)) + " ")])]) : _c("span", {
                  key: col.dataIndex
                }, [_vm._v(_vm._s(text))])];
              }
            };
          })], null, true)
        })];
      }
    }], null, false, 4045821742)
  }, [_c("template", {
    slot: "search"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      allowClear: true,
      placeholder: "搜索名称/联系电话/联系人"
    },
    on: {
      search: _vm.searchGys
    },
    model: {
      value: _vm.searchKey,
      callback: function callback($$v) {
        _vm.searchKey = $$v;
      },
      expression: "searchKey"
    }
  })], 1), _c("template", {
    slot: "export"
  }, [_c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }), _c("a-button", {
    staticClass: "action-btn",
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.exportExcel
    }
  }, [_vm._v("导出全部 "), _c("a-icon", {
    attrs: {
      type: "download"
    }
  })], 1)], 1)], 2)], 1) : _vm._e(), _c("a-drawer", {
    attrs: {
      width: 450,
      title: _vm.groupTitle,
      placement: "right",
      visible: _vm.groupVisible
    },
    on: {
      close: _vm.onGroupClose
    }
  }, [_c("div", [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#fa5051",
      "margin-right": "4px"
    }
  }, [_vm._v("*")]), _c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#55565d"
    }
  }, [_vm._v("上级分组")]), _c("a-tree-select", {
    staticStyle: {
      width: "248px",
      "margin-left": "10px"
    },
    attrs: {
      treeData: _vm.groupList,
      placeholder: "请选择上级分组"
    },
    on: {
      select: _vm.onGroupParentSelect
    },
    model: {
      value: _vm.groupParentId,
      callback: function callback($$v) {
        _vm.groupParentId = $$v;
      },
      expression: "groupParentId"
    }
  })], 1), _c("div", {
    staticClass: "mt24",
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#fa5051",
      "margin-right": "4px"
    }
  }, [_vm._v("*")]), _c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#55565d"
    }
  }, [_vm._v("分组名称")]), _c("a-input", {
    staticStyle: {
      width: "248px",
      "margin-left": "10px"
    },
    attrs: {
      placeholder: "请输入分组名称"
    },
    model: {
      value: _vm.groupName,
      callback: function callback($$v) {
        _vm.groupName = $$v;
      },
      expression: "groupName"
    }
  })], 1), _c("div", {
    staticClass: "mt24",
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "align-items": "flex-start"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#55565d",
      "margin-left": "9px"
    }
  }, [_vm._v("分组说明")]), _c("a-textarea", {
    staticStyle: {
      width: "248px",
      "margin-left": "10px",
      "min-height": "120px"
    },
    attrs: {
      maxLength: 150,
      placeholder: "最多150个字"
    },
    model: {
      value: _vm.groupDescribe,
      callback: function callback($$v) {
        _vm.groupDescribe = $$v;
      },
      expression: "groupDescribe"
    }
  })], 1)]), _c("a-space", {
    style: {
      position: "absolute",
      right: 0,
      bottom: 0,
      width: "100%",
      borderTop: "1px solid #e9e9e9",
      padding: "10px 16px",
      background: "#fff",
      textAlign: "right",
      zIndex: 1,
      display: "flex",
      justifyContent: "center"
    }
  }, [_vm.groupTitle === "编辑分组" ? _c("a-button", {
    style: {
      color: "#FA5051",
      borderColor: "#FA5051"
    },
    on: {
      click: _vm.deleteGroup
    }
  }, [_vm._v(" 删除 ")]) : _vm._e(), _c("a-button", {
    on: {
      click: _vm.onGroupClose
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addGroupOk
    }
  }, [_vm._v(" 确定 ")])], 1)], 1), _c("a-modal", {
    attrs: {
      destroyOnClose: "",
      title: "登记二维码"
    },
    model: {
      value: _vm.qrcodeVisible,
      callback: function callback($$v) {
        _vm.qrcodeVisible = $$v;
      },
      expression: "qrcodeVisible"
    }
  }, [_c("div", {
    staticStyle: {
      width: "200px",
      height: "200px",
      margin: "0 auto"
    },
    attrs: {
      id: "qrbox"
    }
  }), _c("div", {
    staticClass: "mt24 ta-center"
  }, [_c("a-button", {
    on: {
      click: _vm.saveQrcode
    }
  }, [_vm._v("下载")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };