var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_vm.imgUrl ? _c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-right": "8px",
      "margin-top": "3px"
    },
    attrs: {
      src: _vm.imgUrl,
      alt: ""
    }
  }) : _vm._e(), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column"
    }
  }, [_c("span", {
    staticClass: "details-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c("span", {
    staticClass: "details-content",
    staticStyle: {
      "margin-top": "12px"
    },
    on: {
      click: _vm.contentClick
    }
  }, [_vm._v(_vm._s(_vm.content ? _vm.content : 0))])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };