import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  components: {
    mapDrag: function mapDrag() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./mapDrag"));
      });
    }
  },
  props: {
    cardPositionOptions: {
      type: Object,
      default: function _default() {
        return {
          show: false,
          obj: {}
        };
      }
    }
  },
  data: function data() {
    return {
      visible: false,
      dragData: {
        lng: null,
        lat: null,
        address: null,
        nearestJunction: null,
        nearestRoad: null,
        nearestPOI: null,
        distance: 1000
      }
    };
  },
  watch: {
    cardPositionOptions: {
      handler: function handler(val) {
        this.visible = val.show;
      }
    },
    location: {
      handler: function handler(val) {}
    }
  },
  methods: {
    dragMap: function dragMap(data) {
      this.dragData.lng = data.position.lng;
      this.dragData.lat = data.position.lat;
      this.dragData.address = data.address;
      this.dragData.nearestJunction = data.nearestJunction;
      this.dragData.nearestRoad = data.nearestRoad;
      this.dragData.nearestPOI = data.nearestPOI;
    },
    handerOk: function handerOk() {
      if (this.dragData.address === null) {
        this.$notification["error"]({
          message: "失败",
          description: "请填写打卡位置！"
        });
        return;
      }
      if (!this.dragData.distance) {
        this.dragData.distance = 1000;
      }
      this.$emit("cardPos", this.dragData);
      this.visible = false;
    }
  }
};