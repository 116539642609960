import "core-js/modules/es.function.name.js";
import Draggable from "vuedraggable";
import XqFormMixin from "xqjr-plugin-form/mixins/XqFormMixin";
var FormMixin = XqFormMixin();
export default {
  components: {
    Draggable: Draggable
  },
  mixins: [FormMixin],
  props: {
    sortedKeysNew: {
      type: Array,
      default: function _default() {
        return null;
      }
    },
    status: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    remove: {
      type: Boolean,
      default: false
    },
    dataSource: {
      type: Object,
      default: function _default() {}
    },
    editable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: true
    },
    dynamicData: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: {
    fixedLeft: function fixedLeft() {
      var isOpened = this.$store.state.app.sidebar.opened;
      return "".concat((isOpened ? 210 : 64) + 24 + 360, "px");
    }
  },
  watch: {
    sortedKeysNew: {
      handler: function handler(val) {
        this.sortedKeys = val;
      },
      deep: true,
      immediate: true
    }
  },
  data: function data() {
    return {
      scrollTop: 0,
      sortedKeys: [],
      form1: this.$form.createForm(this, {
        name: "coordinated"
      }),
      reportList: ["12", "34", "23"],
      isReward: true,
      spouseInfo: false
    };
  },
  created: function created() {
    var _this = this;
    var data = {
      name: this.name
    };
    this.$nextTick(function () {
      _this.form1.setFieldsValue(data);
    });
  },
  methods: {
    onDragEnd: function onDragEnd(event) {
      this.$emit("dragEnd", event.newIndex, event.oldIndex);
    },
    onDragAdd: function onDragAdd(event) {
      this.$emit("dragAdd", event.newIndex);
    },
    handleChange: function handleChange(e, test) {
      this.$emit("changItemVal", test, "updata");
    },
    delClose: function delClose(e) {
      this.$emit("changItemVal", e, "del");
    },
    XqItemChange: function XqItemChange(val, item, dataSource, dynamicData) {
      if (item.type === "select_add") {
        this.selectAddValueDict[item.model] = val;
      } else if (item.model === "rewardPunish") {
        console.log("debug log --> ", this.form.resetFields());
        this.isReward = false;
        this.form.rewardType = null;
        this.$emit("itemChange", val);
        this.isReward = true;
      }
    },
    save: function save() {
      var _this2 = this;
      this.form1.validateFields(function (err, values) {
        if (!err) {
          _this2.$emit("change", values, _this2.sortedKeys); // 排序的
        } else {
          document.querySelector("#target1").scrollIntoView();
          _this2.$emit("scrollTop");
        }
      });
    },
    onRemove: function onRemove() {
      this.$emit("remove");
    },
    cancel: function cancel() {
      this.$emit("update:editable", false);
      this.$emit("cancel");
    },
    onValuesChangeCustom: function onValuesChangeCustom(values) {}
  }
};