import moment from "moment";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    msgVal: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    moment: moment
  }
};