import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "information"
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 16
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-row", {
    staticStyle: {
      "margin-bottom": "26px"
    }
  }, [_c("tit", [_vm._v("公司信息")]), _vm._l(_vm.label3, function (item) {
    return _c("a-col", {
      key: item.val,
      attrs: {
        span: 12
      }
    }, [["公司类型:", "公司规模:", "预计签约时间:", "预计派单时间:"].includes(item.name) ? _c("a-form-item", {
      attrs: {
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(_vm.form[item.val] || "暂无") + " ")]) : _c("a-form-item", {
      attrs: {
        label: item.name
      }
    }, [_c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(_vm.form[item.val] || "暂无"))])]), _c("div", {
      staticClass: "overflows"
    }, [_vm._v(" " + _vm._s(_vm.form[item.val] || "暂无") + " ")])], 2)], 1)], 1);
  })], 2), _c("a-row", {
    staticStyle: {
      "margin-bottom": "26px"
    }
  }, [_c("tit", [_vm._v("联系人信息")]), _vm._l(_vm.label1, function (item) {
    return _c("a-col", {
      key: item.val,
      attrs: {
        span: 12
      }
    }, [_c("a-form-item", {
      staticStyle: {
        height: "100%"
      },
      attrs: {
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(_vm.form[item.val] || "暂无") + " ")])], 1);
  })], 2), _c("a-row", [_c("tit", [_vm._v("法人信息")]), _vm._l(_vm.label2, function (item) {
    return _c("a-col", {
      key: item.val,
      attrs: {
        span: 12
      }
    }, [_c("a-form-item", {
      attrs: {
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(_vm.form[item.val] || "暂无") + " ")])], 1);
  })], 2), _vm._l(_vm.label4, function (item) {
    return _c("a-row", {
      key: item.val
    }, [_c("a-col", {
      attrs: {
        span: 24
      }
    }, [_c("a-form-item", {
      attrs: {
        label: item.name,
        "label-col": {
          span: 3
        },
        "wrapper-col": {
          span: 18
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.form[item.val] || "暂无") + " ")])], 1)], 1);
  }), _c("a-row", _vm._l(_vm.label5, function (item) {
    return _c("a-col", {
      key: item.val,
      attrs: {
        span: item.name === "上传合同:" || item.name === "营业执照:" ? 24 : 12
      }
    }, [item.name === "上传合同:" ? _c("a-form-item", {
      attrs: {
        label: item.name,
        labelCol: {
          span: 3
        },
        wrapperCol: {
          span: 21
        }
      }
    }, [_vm.form[item.val] ? _c("div", {
      staticStyle: {
        display: "flex",
        "flex-wrap": "wrap"
      }
    }, _vm._l(_vm.form[item.val].split(","), function (htItem) {
      return _c("div", {
        key: htItem,
        staticClass: "ht mr8 mb8"
      }, [_c("div", [_c("img", {
        staticStyle: {
          width: "32px",
          height: "37px"
        },
        attrs: {
          src: _vm.jl
        }
      })]), _c("div", [_c("a-tooltip", {
        attrs: {
          placement: "top"
        }
      }, [_c("template", {
        slot: "title"
      }, [_c("span", [_vm._v(_vm._s(_vm.getName(htItem)))])]), _c("div", {
        staticClass: "htname"
      }, [_vm._v(" " + _vm._s(_vm.getName(htItem)) + " ")])], 2), _c("div", {
        staticClass: "hta"
      }, [_c("a", {
        attrs: {
          href: "".concat(htItem, "?token=").concat(_vm.token),
          download: "",
          target: "_blank"
        }
      }, [_vm._v("预览下载")])])], 1)]);
    }), 0) : _c("div", [_vm._v("暂无")])]) : item.name === "营业执照:" ? _c("a-form-item", {
      attrs: {
        label: item.name,
        labelCol: {
          span: 3
        },
        wrapperCol: {
          span: 21
        }
      }
    }, [_vm.form[item.val] ? _c("div", {
      staticStyle: {
        display: "flex",
        "flex-wrap": "wrap"
      }
    }, _vm._l(_vm.form[item.val].split(","), function (htItem) {
      return _c("div", {
        key: htItem,
        staticClass: "ht mr8 mb8"
      }, [_c("div", [_c("img", {
        staticStyle: {
          width: "32px",
          height: "37px"
        },
        attrs: {
          src: _vm.jl
        }
      })]), _c("div", [_c("a-tooltip", {
        attrs: {
          placement: "top"
        }
      }, [_c("template", {
        slot: "title"
      }, [_c("span", [_vm._v(_vm._s(_vm.getName(htItem)))])]), _c("div", {
        staticClass: "htname"
      }, [_vm._v(" " + _vm._s(_vm.getName(htItem)) + " ")])], 2), _c("div", {
        staticClass: "hta"
      }, [_c("a", {
        attrs: {
          href: "".concat(htItem, "?token=").concat(_vm.token),
          download: "",
          target: "_blank"
        }
      }, [_vm._v("预览下载")])])], 1)]);
    }), 0) : _c("div", [_vm._v("暂无")])]) : _c("a-form-item", {
      attrs: {
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(_vm.form[item.val] || "暂无") + " ")])], 1);
  }), 1)], 2)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };