import "core-js/modules/web.timers.js";
import "core-js/modules/es.json.stringify.js";
export default {
  props: {
    remove: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    edit: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    title: {
      type: String,
      default: function _default() {
        return "产品";
      }
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      menuKey: [0],
      spinning: true
    };
  },
  watch: {
    data: function data(newData) {
      this.menuKey = [0];
    }
  },
  created: function created() {
    var _this = this;
    setTimeout(function () {
      _this.spinning = false;
    }, 800);
  },
  methods: {
    delTmp: function delTmp(data) {
      this.$emit("delTmp", JSON.parse(JSON.stringify(data)));
    },
    newGroup: function newGroup(data) {
      this.$emit("addition", JSON.parse(JSON.stringify(data)));
    },
    modification: function modification(data) {
      this.$emit("modification", JSON.parse(JSON.stringify(data)));
    },
    clone: function clone(data) {
      this.$emit("clone", JSON.parse(JSON.stringify(data)));
    },
    confirmtemp: function confirmtemp(data) {
      this.$emit("remove", JSON.parse(JSON.stringify(data)));
    },
    onTitle: function onTitle() {
      this.$emit("menuAll");
    },
    getApprovalList: function getApprovalList(data) {
      this.$emit("change", JSON.parse(JSON.stringify(data)));
    }
  }
};