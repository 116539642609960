import request from "@/utils/request";

// 查询分组下公司
export function findCompanyGroup(data) {
  return request({
    url: "/hr/api/v1/childrenCompany/listAll",
    method: "GET",
    params: data
  });
}

// 获取公司名
export function childCompanyName(data) {
  return request({
    url: "/hr/api/v1/users/childCompanyName",
    method: "GET",
    params: data
  });
}

// 今日新增
export function getQueryCount(params) {
  return request({
    url: "/hr/api/v1/operationEmpRecords/queryCount",
    method: "get",
    params: params
  });
}
export function postQueryCount(data) {
  return request({
    url: "hr/api/v1/operationEmpRecords/postQueryCount",
    method: "post",
    data: data
  });
}
export function getQueryEmpPageList(params) {
  return request({
    url: "/hr/api/v1/operationEmpRecords/queryEmpPageList",
    method: "get",
    params: params
  });
}
export function postQueryEmpPageList(data) {
  return request({
    url: "/hr/api/v1/operationEmpRecords/postQueryEmpPageList",
    method: "post",
    data: data
  });
}