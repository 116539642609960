import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";
export function getCalculateBatchDetailsList(params) {
  return request({
    url: "/tax/api/v1/calculateBatchDetails",
    method: "GET",
    params: params
  });
}
export function getStartApprover(transferAccountsBatchId, flowId) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/startApprover/".concat(transferAccountsBatchId, "/").concat(flowId),
    method: "GET"
  });
}
export function getAllByCompany(companyId, params) {
  return request({
    url: "/approval/api/v1/approFlows/allByCompany/".concat(companyId, "/").concat(params.moduleType),
    method: "GET",
    params: params
  });
}
export function putTransferAccountsDetails(data) {
  return request({
    url: "/hr/api/v1/transferAccountsDetails",
    method: "PUT",
    data: data
  });
}
export function delTransferAccountsDetails(transferAccountsDetailId) {
  return request({
    url: "/hr/api/v1/transferAccountsDetails/".concat(transferAccountsDetailId),
    method: "DELETE"
  });
}
export function getCalculateBatchDetails(params) {
  return request({
    url: "/tax/api/v1/calculateBatchDetails/getBatchDetail",
    method: "GET",
    params: params
  });
}
export function getActiveByType(params) {
  return request({
    url: "/tax/api/v1/requests/getActiveByType",
    method: "GET",
    params: params
  });
}
export function calculateTaxFeedBack(data) {
  return request({
    url: "/tax/api/v1/calculateBatchDetails/calculateTaxFeedBack",
    method: "POST",
    data: data
  });
}
export function deleteAndUpdateById(calculateBatchDetailId) {
  return request({
    url: "/tax/api/v1/calculateBatchDetails/deleteAndUpdateById/".concat(calculateBatchDetailId),
    method: "DELETE"
  });
}