import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("TableTitle", [_c("template", {
    slot: "title"
  }, [_vm._v("员工详情")]), _c("template", {
    slot: "action"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("a-badge", {
    attrs: {
      count: _vm.allCount,
      offset: [-18, 0]
    }
  }, [_c("a-button", {
    style: _vm.allCount ? "margin-right:18px;" : "margin-right:12px;",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onTodayAdd
    }
  }, [_vm._v("今日新增")])], 1), _c("a-upload", {
    ref: "upload",
    staticClass: "mr12",
    attrs: {
      "file-list": _vm.fileList,
      remove: _vm.handleRemove,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      block: ""
    }
  }, [_vm._v("导入")])], 1), _c("a-button", {
    staticClass: "mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.download2
    }
  }, [_vm._v("下载导入模板")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showAdd
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加预登记人员 ")], 1)], 1)])], 2), _c("div", {
    staticClass: "body"
  }, [_c("div", {
    staticClass: "tableBox"
  }, [_c("TableBody", {
    attrs: {
      selectHead: "",
      tableName: "prePersonnel",
      source: _vm.dataList,
      headerRowID: "sortable_list",
      columns: _vm.columns,
      isJF: _vm.isJF
    },
    on: {
      onReload: _vm.getPrePersonnelList,
      onExportAll: _vm.exportFile
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(prop) {
        return [_c("a-table", {
          class: prop.className,
          attrs: {
            components: prop.components,
            columns: prop.columns,
            scroll: {
              x: prop.scrollWidth
            },
            customHeaderRow: function customHeaderRow() {
              return {
                domProps: {
                  id: prop.headerRowID
                }
              };
            },
            loading: _vm.loading,
            rowKey: "id",
            "data-source": _vm.dataList,
            pagination: _vm.pagination
          },
          on: {
            change: _vm.handleTableChange
          },
          scopedSlots: _vm._u([{
            key: "status",
            fn: function fn(text) {
              return _c("a", {
                staticStyle: {
                  cursor: "text"
                }
              }, [text === 2 ? _c("div", {
                staticClass: "statusStyle",
                staticStyle: {
                  background: "rgba(255, 166, 0, 0.1)",
                  border: "1px solid #ffa600",
                  color: "#ffa600"
                }
              }, [_vm._v(" 已安排 ")]) : _vm._e(), text === 1 ? _c("div", {
                staticClass: "statusStyle",
                staticStyle: {
                  background: "rgba(106, 102, 246, 0.1)",
                  border: "1px solid #6a66f6",
                  color: "#6a66f6"
                }
              }, [_vm._v(" 已接到 ")]) : _vm._e(), text === 3 ? _c("div", {
                staticClass: "statusStyle",
                staticStyle: {
                  background: "rgba(36, 205, 170, 0.1)",
                  border: "1px solid #24cdaa",
                  color: "#24cdaa"
                }
              }, [_vm._v(" 已面试 ")]) : _vm._e(), text === 4 ? _c("div", {
                staticClass: "statusStyle",
                staticStyle: {
                  background: "rgba(66, 130, 255, 0.1)",
                  border: "1px solid #4282ff",
                  color: "#4282ff"
                }
              }, [_vm._v(" 已入职 ")]) : _vm._e(), text === 5 ? _c("div", {
                staticClass: "statusStyle",
                staticStyle: {
                  background: "rgba(0, 0, 0, 0.04)",
                  border: "1px solid rgba(0, 0, 0, 0.15)",
                  color: "#767885"
                }
              }, [_vm._v(" 已离职 ")]) : _vm._e(), text === 6 ? _c("div", {
                staticClass: "statusStyle",
                staticStyle: {
                  background: "rgba(255, 166, 0, 0.1)",
                  border: "1px solid #ffa600",
                  color: "#ffa600"
                }
              }, [_vm._v(" 待入职 ")]) : _vm._e(), text === 7 ? _c("div", {
                staticClass: "statusStyle",
                staticStyle: {
                  background: "rgba(0, 0, 0, 0.04)",
                  border: "1px solid rgba(0, 0, 0, 0.15)",
                  color: "#767885"
                }
              }, [_vm._v(" 未到岗 ")]) : _vm._e()]);
            }
          }, {
            key: "channelName",
            fn: function fn(text) {
              return _c("a", {
                staticStyle: {
                  cursor: "text",
                  color: "#595959"
                }
              }, [_vm._v(_vm._s(text === undefined ? "" : text))]);
            }
          }, {
            key: "gmtCreate",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.moment(text).format("YYYY-MM-DD HH:mm")))]);
            }
          }, {
            key: "postType",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.$store.state.enums.postTypeArr[text - 1]))]);
            }
          }, {
            key: "joinDate",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.moment(text).format("YYYY-MM-DD") : ""))]);
            }
          }, {
            key: "birthday",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(" " + _vm._s(text != null ? "".concat(text.substring(0, 10)) : "") + " ")]);
            }
          }, {
            key: "formalType",
            fn: function fn(text) {
              return _c("span", {}, [text == 1 ? _c("span", [_vm._v("试用")]) : _vm._e(), text == 2 ? _c("span", [_vm._v("正式")]) : _vm._e()]);
            }
          }, {
            key: "action",
            fn: function fn(text, record) {
              return _c("a", {
                staticClass: "primary_color"
              }, [_c("a-popconfirm", {
                attrs: {
                  title: "请选择",
                  disabled: [4, 6, 7].includes(record.status),
                  placement: "bottom",
                  "ok-text": "入职",
                  "cancel-text": "待入职"
                },
                on: {
                  cancel: function cancel($event) {
                    return _vm.induction(record, "toBehired");
                  },
                  confirm: function confirm($event) {
                    return _vm.induction(record, "induction");
                  }
                }
              }, [_c("a-button", {
                attrs: {
                  type: "link",
                  size: "small",
                  disabled: [4, 6, 7].includes(record.status)
                }
              }, [_vm._v("入职")])], 1), _c("a-divider", {
                attrs: {
                  type: "vertical"
                }
              }), _c("a-button", {
                attrs: {
                  type: "link",
                  size: "small"
                },
                on: {
                  click: function click($event) {
                    return _vm.edit(record);
                  }
                }
              }, [_vm._v(" " + _vm._s([4, 6, 7].includes(record.status) ? "查看" : "编辑") + " ")])], 1);
            }
          }], null, true)
        })];
      }
    }])
  }, [_c("template", {
    slot: "search"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "225px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "搜索姓名/手机号/身份证号"
    },
    on: {
      search: _vm.inputChange
    }
  }), _c("a-select", {
    staticStyle: {
      width: "225px"
    },
    attrs: {
      allowClear: "",
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption3,
      placeholder: "请选择渠道"
    },
    on: {
      change: _vm.selectCheck
    }
  }, _vm._l(_vm.Channels, function (item, index) {
    return _c("a-select-option", {
      key: index,
      model: {
        value: item.id,
        callback: function callback($$v) {
          _vm.$set(item, "id", $$v);
        },
        expression: "item.id"
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "225px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择人员状态"
    },
    on: {
      change: _vm.selectCheck2
    }
  }, _vm._l(_vm.preStatusVarList, function (li) {
    return _c("a-select-option", {
      key: li.value,
      attrs: {
        value: li.value
      }
    }, [_vm._v(_vm._s(li.label))]);
  }), 1)], 1), _c("template", {
    slot: "action-btn"
  }, [_c("a-select", {
    staticStyle: {
      width: "150px"
    },
    on: {
      change: _vm.batchHandleChange
    },
    model: {
      value: _vm.batchVal,
      callback: function callback($$v) {
        _vm.batchVal = $$v;
      },
      expression: "batchVal"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "状态"
    }
  }, [_vm._v("批量修改状态")]), _c("a-select-option", {
    attrs: {
      value: "渠道"
    }
  }, [_vm._v("批量修改渠道")])], 1)], 1)], 2)], 1), _c("div", {
    staticClass: "drawerBox"
  }, [_c("a-drawer", {
    attrs: {
      title: _vm.title,
      placement: "right",
      closable: false,
      visible: _vm.visible,
      destroyOnClose: true,
      width: 580
    },
    on: {
      close: _vm.onClose
    }
  }, [_c("a-tabs", {
    attrs: {
      type: "card"
    },
    on: {
      change: _vm.callback
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "手动添加"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      background: "#0000000d",
      height: "40px",
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticClass: "emp-title"
  }, [_vm._v("员工信息")])]), _c("DynamicAddEmployees", {
    attrs: {
      title: _vm.title,
      checkInAllValue: _vm.checkInAllValue,
      checkInIdForm: _vm.checkInIdForm,
      moduleCode: "checkIn"
    },
    on: {
      saveValueChange: _vm.saveValueChange,
      onRemove: _vm.onRemove,
      cancelForm: _vm.cancelForm
    }
  })], 1), _vm.form.status !== 4 ? _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "机器识别"
    }
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-row", {
    staticStyle: {
      "margin-bottom": "15px"
    },
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_vm.isConnect ? _c("img", {
    staticStyle: {
      width: "20px",
      height: "20px",
      "margin-right": "5px"
    },
    attrs: {
      src: require("@/assets/icons/duihao.png")
    }
  }) : _c("img", {
    staticStyle: {
      width: "20px",
      height: "20px",
      "margin-right": "5px"
    },
    attrs: {
      src: require("@/assets/icons/失败.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-right": "15px"
    }
  }, [_vm._v(_vm._s(_vm.isConnect ? "设备已连接" : "设备未连接"))]), !_vm.isConnect ? _c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    },
    on: {
      click: _vm.connectDevice
    }
  }, [_vm._v("连接设备")]) : _c("a-button", {
    attrs: {
      "html-type": "submit"
    },
    on: {
      click: _vm.connectDevice
    }
  }, [_vm._v("断开设备")]), _c("a-icon", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "question-circle"
    },
    on: {
      click: _vm.howShow
    }
  })], 1), _c("a-button", {
    attrs: {
      disabled: !_vm.isConnect,
      type: "primary",
      "html-type": "submit"
    },
    on: {
      click: _vm.readIdCard
    }
  }, [_vm._v("读卡")])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      background: "#0000000d",
      height: "40px",
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticClass: "emp-title"
  }, [_vm._v("员工信息")])]), _c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "name",
    attrs: {
      label: "姓名",
      prop: "name",
      rules: {
        required: true,
        message: "请输入员工姓名"
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入",
      disabled: _vm.form.status === 4
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("a-form-model-item", {
    ref: "idcard",
    attrs: {
      label: "身份证号",
      prop: "idcard",
      rules: {
        required: true,
        validator: _vm.validatorIdCard
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入",
      disabled: _vm.form.status === 4
    },
    model: {
      value: _vm.form.idcard,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "idcard", $$v);
      },
      expression: "form.idcard"
    }
  })], 1), _c("a-form-model-item", {
    ref: "phone",
    attrs: {
      label: "手机号码",
      prop: "phone",
      rules: {
        validator: _vm.validatorPhone
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入",
      disabled: _vm.form.status === 4
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c("a-form-model-item", {
    ref: "channelId",
    attrs: {
      label: "渠道来源",
      prop: "name"
    }
  }, [_c("a-select", {
    attrs: {
      disabled: _vm.form.status === 4,
      allowClear: "",
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption2,
      placeholder: "请选择"
    },
    on: {
      change: _vm.selectChange
    },
    model: {
      value: _vm.form.channelId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "channelId", $$v);
      },
      expression: "form.channelId"
    }
  }, _vm._l(_vm.Channels, function (item, index) {
    return _c("a-select-option", {
      key: index,
      model: {
        value: item.id,
        callback: function callback($$v) {
          _vm.$set(item, "id", $$v);
        },
        expression: "item.id"
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-form-model-item", {
    ref: "status",
    attrs: {
      label: "人员状态",
      prop: "status"
    }
  }, [_vm.form.status === 4 ? _c("span", [_vm._v("已入职")]) : _vm.form.status === 5 ? _c("span", [_vm._v("已离职")]) : _c("a-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v("已安排")]), _c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("已接到")]), _c("a-select-option", {
    attrs: {
      value: 3
    }
  }, [_vm._v("已面试")])], 1)], 1), _c("a-form-model-item", {
    ref: "remark",
    attrs: {
      label: "备注",
      prop: "remark"
    }
  }, [_c("a-textarea", {
    attrs: {
      placeholder: "请输入",
      disabled: _vm.form.status === 4
    },
    model: {
      value: _vm.form.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1)], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      background: "#0000000d",
      height: "40px",
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticClass: "emp-title"
  }, [_vm._v("身份信息")])]), _c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "nation",
    attrs: {
      label: "民族",
      prop: "nation"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入",
      disabled: _vm.form.status === 4
    },
    model: {
      value: _vm.form.nation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nation", $$v);
      },
      expression: "form.nation"
    }
  })], 1), _c("a-form-model-item", {
    ref: "birthday",
    attrs: {
      label: "出生日期",
      prop: "birthday"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入",
      disabled: _vm.form.status === 4
    },
    model: {
      value: _vm.form.birthday,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "birthday", $$v);
      },
      expression: "form.birthday"
    }
  })], 1), _c("a-form-model-item", {
    ref: "nativeLocation",
    attrs: {
      label: "户籍住址",
      prop: "nativeLocation"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入",
      disabled: _vm.form.status === 4
    },
    model: {
      value: _vm.form.nativeLocation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nativeLocation", $$v);
      },
      expression: "form.nativeLocation"
    }
  })], 1), _c("a-form-model-item", {
    ref: "issuedBy",
    attrs: {
      label: "签发机关",
      prop: "issuedBy"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入",
      disabled: _vm.form.status === 4
    },
    model: {
      value: _vm.form.issuedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "issuedBy", $$v);
      },
      expression: "form.issuedBy"
    }
  })], 1), _c("a-form-model-item", {
    ref: "startDate",
    attrs: {
      label: "开始时间",
      prop: "startDate"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入",
      disabled: _vm.form.status === 4
    },
    model: {
      value: _vm.form.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "startDate", $$v);
      },
      expression: "form.startDate"
    }
  })], 1), _c("a-form-model-item", {
    ref: "endDate",
    attrs: {
      label: "结束时间",
      prop: "endDate"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入",
      disabled: _vm.form.status === 4
    },
    model: {
      value: _vm.form.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "endDate", $$v);
      },
      expression: "form.endDate"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "身份证照片"
    }
  }, [_vm.form.img ? _c("img", {
    staticStyle: {
      width: "124px",
      height: "150px",
      "margin-bottom": "25px"
    },
    attrs: {
      src: _vm.form.img
    }
  }) : _c("Uploading", {
    attrs: {
      srcData: _vm.url,
      limit: 1
    },
    on: {
      "update:srcData": function updateSrcData($event) {
        _vm.url = $event;
      },
      "update:src-data": function updateSrcData($event) {
        _vm.url = $event;
      }
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1), _vm.key !== "1" ? _c("div", {
    staticClass: "drawer-footer-box"
  }, [_vm.title === "编辑" ? _c("a-button", {
    staticStyle: {
      "margin-right": "12px",
      color: "#fa5051",
      "border-color": "#fa5051"
    },
    on: {
      click: _vm.showDelete
    }
  }, [_vm._v("删除")]) : _vm._e(), _c("a-button", {
    style: {
      marginRight: "12px"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    },
    on: {
      click: _vm.handleAddOK
    }
  }, [_vm._v("确定")])], 1) : _vm._e()], 1)], 1), _c("a-modal", {
    attrs: {
      title: "删除预登记人员",
      visible: _vm.deleteVisible
    },
    on: {
      ok: _vm.deleteOk,
      cancel: _vm.deleteCancel
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "28px",
      color: "#6a66f6"
    },
    attrs: {
      type: "question-circle"
    }
  }), _c("p", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#3c3d43",
      "line-height": "20px",
      "margin-left": "20px"
    }
  }, [_vm._v(" 您确定要删除该预登记人员吗？一旦删除不可恢复 ")])], 1)]), _c("a-modal", {
    attrs: {
      title: "".concat(_vm.goInductionUrl === "induction" ? "" : "待", "\u5165\u804C"),
      visible: _vm.inductionVisible
    },
    on: {
      ok: _vm.InductOk,
      cancel: _vm.InductCancel
    }
  }, [_c("div", {
    staticClass: "headerBox"
  }, [_c("div", {
    staticClass: "titleName"
  }, [_vm._v(_vm._s(_vm.inductDetail.name))]), _vm.inductDetail.status === 2 ? _c("div", {
    staticStyle: {
      "text-align": "center",
      width: "50px",
      height: "20px",
      background: "rgba(255, 166, 0, 0.1)",
      "border-radius": "2px",
      border: "1px solid #ffa600",
      "font-size": "11px",
      "font-weight": "400",
      color: "#ffa600",
      "margin-left": "10px"
    }
  }, [_vm._v(" 已安排 ")]) : _vm._e(), _vm.inductDetail.status === 1 ? _c("div", {
    staticStyle: {
      "text-align": "center",
      width: "50px",
      height: "20px",
      background: "rgba(106, 102, 246, 0.1)",
      "border-radius": "2px",
      border: "1px solid #6a66f6",
      "font-size": "11px",
      "font-weight": "400",
      color: "#6a66f6",
      "margin-left": "10px"
    }
  }, [_vm._v(" 已接到 ")]) : _vm._e(), _vm.inductDetail.status === 3 ? _c("div", {
    staticStyle: {
      "text-align": "center",
      width: "50px",
      height: "20px",
      background: "rgba(36, 205, 170, 0.1)",
      "border-radius": "2px",
      border: "1px solid #24cdaa",
      "font-size": "11px",
      "font-weight": "400",
      color: "#24cdaa",
      "margin-left": "10px"
    }
  }, [_vm._v(" 已面试 ")]) : _vm._e()]), _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailTitle"
  }, [_vm._v("联系电话")]), _c("div", {
    staticClass: "detailContent"
  }, [_vm._v(_vm._s(_vm.inductDetail.phone))])]), _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailTitle"
  }, [_vm._v("身份证号")]), _c("div", {
    staticClass: "detailContent"
  }, [_vm._v(_vm._s(_vm.inductDetail.idcard))])]), _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailTitle"
  }, [_vm._v("渠道来源")]), _c("div", {
    staticClass: "detailContent"
  }, [_vm._v(_vm._s(_vm.inductDetail.channelName))])]), _c("div", {
    staticClass: "detailBox",
    staticStyle: {
      "margin-top": "10px",
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "detailTitle"
  }, [_vm._v("用工单位")]), _c("div", {
    staticClass: "detailContent"
  }, [_c("a-select", {
    staticStyle: {
      width: "215px"
    },
    attrs: {
      allowClear: "",
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      placeholder: "请选择用工单位"
    },
    model: {
      value: _vm.storyCompanyId,
      callback: function callback($$v) {
        _vm.storyCompanyId = $$v;
      },
      expression: "storyCompanyId"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)])]), _c("a-modal", {
    attrs: {
      title: "使用说明",
      visible: _vm.isCloseMethod,
      width: "620px",
      "destroy-on-close": true,
      "mask-closable": false
    },
    on: {
      cancel: _vm.cancelSyn
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.cancelSyn
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.cancelSyn
    }
  }, [_vm._v("确定")])], 1), _c("div", [_c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d"
    }
  }, [_vm._v("1、该功能需要外部连接设备读卡器使用")]), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d"
    }
  }, [_vm._v("2、需要使用配套驱动和网站。")]), _c("div", {
    staticClass: "edit",
    staticStyle: {
      "font-size": "14px",
      "margin-left": "5px",
      cursor: "pointer"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("驱动及网站链接")])]), _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d"
    }
  }, [_vm._v("3、设备连接后不会因为页面关闭而主动断开，除非外部连接断开或主动选择设备断开")]), _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d"
    }
  }, [_vm._v("4、每次读取身份证信息需要点击“读卡”")])])], 2), _c("bulkChanges", {
    ref: "select",
    on: {
      saveOk: _vm.saveOk
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };