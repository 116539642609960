var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    staticStyle: {
      height: "300px"
    },
    attrs: {
      title: "添加工资条",
      visible: _vm.visible,
      footer: null
    },
    on: {
      cancel: _vm.cancel
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("p", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v(" 请选择添加工资条方式")]), _c("a-row", {
    staticClass: "item",
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "middle"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.handleOk(2);
      }
    }
  }, [_c("a-col", [_vm._v("直接从系统中同步工资表")]), _c("a-col", [_c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1)], 1), _vm._l(_vm.list, function (item, i) {
    return _c("a-row", {
      key: i,
      staticClass: "item",
      attrs: {
        type: "flex",
        justify: "space-between",
        align: "middle"
      },
      nativeOn: {
        click: function click($event) {
          return _vm.handleOk(item);
        }
      }
    }, [_c("a-col", [_vm._v("我要自己上传工资表")]), _c("a-col", [_c("a-icon", {
      attrs: {
        type: "right"
      }
    })], 1)], 1);
  })], 2)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };