var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("a-spin", {
    staticClass: "xq-form-content",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      customize: _vm.FormJson["config"]["table"]["customize"],
      dataSource: _vm.tableData,
      columns: _vm.columns,
      rowSelection: _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      } : null
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("a", {
            on: {
              click: function click($event) {
                return _vm.showUpdateModal(record);
              }
            }
          }, [_vm._v("编辑")]), _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a-popconfirm", {
            attrs: {
              title: "\u786E\u8BA4\u8981\u5220\u9664\u8BB0\u5F55\u5417\uFF1F",
              okText: "确认",
              cancelText: "取消"
            },
            on: {
              confirm: function confirm($event) {
                return _vm.deleteRecord(record);
              }
            }
          }, [_c("a", [_vm._v("删除")])])], 1) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      pageSize: _vm.pageSize,
      total: _vm.total,
      showSizeChanger: true,
      showQuickJumper: true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };