import request from "@/utils/request";
// 查询用工单位
export function getComany(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/selectComanyWithEmpCount",
    method: "POST",
    data: data
  });
}
// 分页查询客户结算表
export function getSettlements(params) {
  return request({
    url: "/salary/api/v1/customerSettlements",
    method: "get",
    params: params
  });
}
// 新增客户结算表
export function addSettlements(data) {
  return request({
    url: "/salary/api/v1/customerSettlements",
    method: "post",
    data: data
  });
}
// 新增结算开票表
export function addTicket(data) {
  return request({
    url: "/salary/api/v1/customerInvoiceRecords",
    method: "post",
    data: data
  });
}
// 根据结算id查询结算开票表
export function getTicket(customerSettlementId) {
  return request({
    url: "/salary/api/v1/customerInvoiceRecords/listAllByCustomerSettlementId/".concat(customerSettlementId),
    method: "get"
  });
}
// 根据结算id查询结算回款记录
export function getReceivable(customerSettlementId) {
  return request({
    url: "/salary/api/v1/customerPayBackRecords/listAllByCustomerSettlementId/".concat(customerSettlementId),
    method: "get"
  });
}
// 新增结算回款表
export function addReceivable(data) {
  return request({
    url: "/salary/api/v1/customerPayBackRecords",
    method: "post",
    data: data
  });
}
// 废除客户结算单记录
export function wasteReord(customerSettlementId) {
  return request({
    url: "/salary/api/v1/customerSettlements/updateAbolishCustomerSettlement/".concat(customerSettlementId),
    method: "put"
  });
}
// 恢复客户结算单记录
export function restoreReord(customerSettlementId) {
  return request({
    url: "/salary/api/v1/customerSettlements/updateRestoreCustomerSettlement/".concat(customerSettlementId),
    method: "put"
  });
}
// 删除单个客户结算表，彻底删除
export function deleteReord(customerSettlementId) {
  return request({
    url: "/salary/api/v1/customerSettlements/".concat(customerSettlementId),
    method: "DELETE"
  });
}
// 删除单个结算回款表，彻底删除
export function deleteBackRecord(customerPayBackRecordId) {
  return request({
    url: "/salary/api/v1/customerPayBackRecords/".concat(customerPayBackRecordId),
    method: "DELETE"
  });
}
// 废除开票记录
export function wasteTicketReord(id) {
  return request({
    url: "/salary/api/v1/customerInvoiceRecords/abolishInvoiceRecord/".concat(id),
    method: "put"
  });
}
// 根据客户结算表id查询客户结算表详情
export function getCustomerDetail(customerSettlementId) {
  return request({
    url: "/salary/api/v1/customerSettlements/".concat(customerSettlementId),
    method: "get"
  });
}
export function getSum(data) {
  return request({
    url: "/salary/api/v1/customerSettlements/getSum",
    method: "post",
    data: data
  });
}