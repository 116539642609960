var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "uplaodBoxs page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 客户导入 ")]), _c("div", {
    staticClass: "steps"
  }, [_c("a-steps", {
    attrs: {
      current: _vm.current
    }
  }, _vm._l(_vm.steps, function (item) {
    return _c("a-step", {
      key: item.title,
      attrs: {
        title: item.title
      }
    });
  }), 1)], 1), _vm.current === 0 ? _c("upload", {
    on: {
      onAction: _vm.onAction
    }
  }) : _vm._e(), _vm.current === 1 ? _c("preview", {
    attrs: {
      dataJson: _vm.dataJson
    },
    on: {
      onAction: _vm.onAction,
      onActionS: _vm.onActionS
    }
  }) : _vm._e(), _vm.current === 2 ? _c("results", {
    attrs: {
      status: _vm.status,
      rdata: _vm.rdata
    },
    on: {
      onAction: _vm.onAction
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };