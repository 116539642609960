import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.fixed.js";
import "core-js/modules/es.array.push.js";
var regHznzi = /[\u4e00-\u9fa5|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/;
export function buildColumnsDetail(list) {
  var widthMap = {
    empName: 100,
    socOrStopSocDate: 195,
    empSocState: 100,
    idCard: 200,
    postType: 100,
    schemeName: 200,
    feedbackFlag: 100
  };
  var columns = list.map(function (item) {
    var newItem = {
      title: item.name,
      dataIndex: item.key,
      width: widthMap[item.key] || 150,
      ellipsis: true
    };
    if (regHznzi.test(item.key)) {
      newItem["customRender"] = function (text, record) {
        return record.data[item.key];
      };
    } else {
      newItem["scopedSlots"] = {
        customRender: item.key
      };
    }
    if (["empName"].includes(item.key)) {
      newItem["fixed"] = "left";
    }
    if (["feedbackFlag"].includes(item.key)) {
      newItem["fixed"] = "right";
    }
    return newItem;
  });
  columns.push({
    title: "操作",
    dataIndex: "opration",
    align: "center",
    scopedSlots: {
      customRender: "opration"
    },
    ellipsis: true,
    fixed: "right",
    width: 180
  });
  return columns;
}
export var socTypeSource = [{
  value: 0,
  label: "未参保"
}, {
  value: 1,
  label: "正常参保"
}, {
  value: 2,
  label: "增员"
}, {
  value: 3,
  label: "待增员"
}, {
  value: 4,
  label: "减员"
}, {
  value: 5,
  label: "待减员"
}, {
  value: 6,
  label: "停保"
}];
export var columnDetail = [{
  title: "姓名",
  dataIndex: "empName",
  fixed: "left",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "计划增减员时间",
  dataIndex: "socOrStopSocDate",
  scopedSlots: {
    customRender: "socOrStopSocDate"
  },
  align: "center",
  ellipsis: true,
  width: 195
}, {
  title: "人员状态",
  dataIndex: "empSocState",
  align: "center",
  scopedSlots: {
    customRender: "empSocState"
  },
  ellipsis: true,
  width: 100
}, {
  title: "增减员类型",
  dataIndex: "type",
  align: "center",
  scopedSlots: {
    customRender: "type"
  },
  ellipsis: true,
  width: 150
}, {
  title: "身份证号",
  dataIndex: "idCard",
  align: "center",
  ellipsis: true,
  width: 200
}, {
  title: "人员类型",
  dataIndex: "postType",
  scopedSlots: {
    customRender: "postType"
  },
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "参保方案",
  dataIndex: "schemeName",
  scopedSlots: {
    customRender: "schemeName"
  },
  align: "center",
  ellipsis: true,
  width: 200
}, {
  title: "参保城市",
  dataIndex: "socCityName",
  scopedSlots: {
    customRender: "socCityName"
  },
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "单位社保费",
  dataIndex: "companySocAmount",
  scopedSlots: {
    customRender: "companySocAmount"
  },
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "个人社保费",
  dataIndex: "personalSocAmount",
  scopedSlots: {
    customRender: "personalSocAmount"
  },
  align: "center",
  width: 150
}, {
  title: "单位公积金费",
  dataIndex: "companyPfAmount",
  scopedSlots: {
    customRender: "companyPfAmount"
  },
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "个人公积金费",
  dataIndex: "personalPfAmount",
  scopedSlots: {
    customRender: "personalPfAmount"
  },
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "服务费",
  dataIndex: "serviceCost",
  scopedSlots: {
    customRender: "serviceCost"
  },
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "计划状态",
  dataIndex: "feedbackFlag",
  scopedSlots: {
    customRender: "feedbackFlag"
  },
  align: "center",
  ellipsis: true,
  fixed: "right",
  width: 100
}, {
  title: "操作",
  dataIndex: "opration",
  align: "center",
  scopedSlots: {
    customRender: "opration"
  },
  ellipsis: true,
  fixed: "right",
  width: 180
}];
export var columnDialogDetailJianYuan = [{
  title: "姓名",
  dataIndex: "empName",
  fixed: "left",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "社保",
  dataIndex: "socDescription",
  scopedSlots: {
    customRender: "socDescription"
  },
  align: "center",
  ellipsis: true,
  width: 120
}, {
  title: "医保",
  dataIndex: "meDescription",
  align: "center",
  scopedSlots: {
    customRender: "meDescription"
  },
  ellipsis: true,
  width: 120
}, {
  title: "公积金",
  dataIndex: "pfDescription",
  align: "center",
  scopedSlots: {
    customRender: "pfDescription"
  },
  ellipsis: true,
  width: 120
}];
export var columnDialogDetail = [{
  title: "姓名",
  dataIndex: "empName",
  fixed: "left",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "计划增减员时间",
  dataIndex: "socOrStopSocDate",
  scopedSlots: {
    customRender: "socOrStopSocDate"
  },
  align: "center",
  ellipsis: true,
  width: 160
}, {
  title: "人员状态",
  dataIndex: "empSocState",
  align: "center",
  scopedSlots: {
    customRender: "empSocState"
  },
  ellipsis: true,
  width: 100
}, {
  title: "身份证号",
  dataIndex: "idCard",
  align: "center",
  ellipsis: true,
  width: 200
}, {
  title: "人员类型",
  dataIndex: "postType",
  scopedSlots: {
    customRender: "postType"
  },
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "参保方案",
  dataIndex: "schemeName",
  scopedSlots: {
    customRender: "schemeName"
  },
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "参保城市",
  dataIndex: "socCityName",
  scopedSlots: {
    customRender: "socCityName"
  },
  align: "center",
  ellipsis: true,
  width: 150
}, {
  title: "服务费",
  dataIndex: "serviceCost",
  scopedSlots: {
    customRender: "serviceCost"
  },
  align: "center",
  ellipsis: true,
  width: 100
}];
export var columnDialogTongbu = [{
  title: "姓名",
  dataIndex: "name",
  fixed: "left",
  align: "center",
  ellipsis: true,
  width: 100
}, {
  title: "同步类型",
  dataIndex: "type",
  align: "center",
  ellipsis: true,
  width: 160
}, {
  title: "同步结果",
  dataIndex: "result",
  align: "center",
  scopedSlots: {
    customRender: "result"
  },
  ellipsis: true,
  width: 100
}, {
  title: "备注",
  dataIndex: "info",
  align: "center",
  scopedSlots: {
    customRender: "info"
  },
  ellipsis: true,
  width: 180
}];