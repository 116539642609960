var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("扫码录入")]), _c("div", {
    staticStyle: {
      padding: "24px"
    }
  }, [_c("div", {
    staticClass: "entryLeft"
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      "padding-left": "84px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "120px",
      height: "120px"
    },
    attrs: {
      src: _vm.QrCode,
      alt: ""
    }
  })]), _c("div", {
    staticStyle: {
      "margin-left": "48px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#3c3d43"
    }
  }, [_vm._v("人员录入二维码")]), _c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#a6a8b4"
    }
  }, [_c("div", [_vm._v("说明：")]), _c("div", [_vm._v("可以自定义设置二维码表单")]), _c("div", [_vm._v("用户通过扫码填写的人员信息，将自动录入人才库中；")]), _c("div", [_vm._v("二维码长期有效")])])])]), _c("div", {
    staticStyle: {
      "margin-right": "84px"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.downloadCod
    }
  }, [_vm._v("下载二维码")])], 1)]), _c("fieldTemplate", {
    attrs: {
      typeVal: "SweepCodeEntry"
    },
    on: {
      ok: _vm.ok
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };