import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import moment from "moment";
export function buildColumns(tab, needCompanyId) {
  var companyId = {
    title: "用工单位",
    dataIndex: "companyName",
    width: 200,
    scopedSlots: {
      customRender: "companyName"
    }
  };
  var columns = [];
  switch (tab) {
    case "paper":
      columns = JSON.parse(JSON.stringify(columns_paper));
      break;
    case "all":
      columns = JSON.parse(JSON.stringify(columns_all));
      break;
    default:
      columns = JSON.parse(JSON.stringify(columns_electronic));
      break;
  }
  if (needCompanyId) {
    columns.splice(columns.length - 2, 0, companyId);
  }
  return columns;
}
export { enumerateList, customRenderContractType, customRenderMoment };
function customRenderContractType(text) {
  if (text) {
    return contractTypeDict[text] || "--";
  }
  return "--";
}
function customRenderMoment(text) {
  if (text) {
    return moment(text).format("YYYY-MM-DD") || "--";
  }
  return "--";
}
var enumerateList = [{
  name: "固定期限劳动合同",
  value: "laborContractLimit"
}, {
  name: "无固定期限劳动合同",
  value: "laborContractUnlimit"
}, {
  name: "以完成一定工作任务为期限的劳动合同",
  value: "laborContractJobs"
}, {
  name: "实习协议",
  value: "internAccords"
}, {
  name: "劳务合同",
  value: "laborContract"
}, {
  name: "返聘协议",
  value: "reemployAccords"
}, {
  name: "劳务派遣合同",
  value: "laborContractDispatch"
}, {
  name: "借调合同",
  value: "borrowedContract"
}, {
  name: "约定书",
  value: "engagementLetter"
}, {
  name: "兼职合同",
  value: "partTime"
}, {
  name: "见习协议",
  value: "noviciateAgreement"
}, {
  name: "离职合同",
  value: "dimissionContract"
}, {
  name: "退休返聘合同",
  value: "retireReEmploy"
}, {
  name: "其它",
  value: "other"
}];
var contractTypeDict = {
  laborContractLimit: "固定期限劳动合同",
  laborContractUnlimit: "无固定期限劳动合同",
  laborContractJobs: "以完成一定工作任务为期限的劳动合同",
  internAccords: "实习协议",
  laborContract: "劳务合同",
  reemployAccords: "返聘协议",
  laborContractDispatch: "劳务派遣合同",
  borrowedContract: "借调合同",
  engagementLetter: "约定书",
  partTime: "兼职合同",
  noviciateAgreement: "见习协议",
  dimissionContract: "离职合同",
  retireReEmploy: "退休返聘合同",
  other: "其它"
};
var columns_electronic = [{
  title: "姓名",
  dataIndex: "partyBName",
  width: 100,
  scopedSlots: {
    customRender: "partyBName"
  },
  colDefault: true,
  isEdit: false,
  fixed: "left"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200,
  scopedSlots: {
    customRender: "idCard"
  },
  colDefault: true,
  isEdit: false,
  fixed: "left"
}, {
  title: "合同名称",
  dataIndex: "name",
  width: 280,
  colDefault: true,
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "合同开始时间",
  dataIndex: "publishDate",
  scopedSlots: {
    customRender: "publishDate"
  },
  ellipsis: true,
  colDefault: true,
  width: 150
}, {
  title: "合同结束时间",
  dataIndex: "endDate",
  width: 150,
  colDefault: true,
  scopedSlots: {
    customRender: "endDate"
  }
}, {
  title: "合同类型",
  dataIndex: "fileType",
  width: 200,
  colDefault: true,
  scopedSlots: {
    customRender: "fileType"
  }
}, {
  title: "签署状态",
  dataIndex: "status",
  width: 140,
  colDefault: true,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "签署编号",
  dataIndex: "signNo",
  width: 200,
  scopedSlots: {
    customRender: "signNo"
  },
  colDefault: true
}, {
  title: "合同备注",
  dataIndex: "contractNo",
  width: 280,
  colDefault: true,
  scopedSlots: {
    customRender: "contractNo"
  }
}, {
  title: "手机号",
  dataIndex: "partyBPhone",
  width: 150,
  scopedSlots: {
    customRender: "partyBPhone"
  }
}, {
  title: "剩余有效时间",
  dataIndex: "remainingValidTime",
  width: 150,
  scopedSlots: {
    customRender: "remainingValidTime"
  }
}, {
  title: "小程序是否展示",
  dataIndex: "appIsShow",
  width: 150,
  scopedSlots: {
    customRender: "appIsShow"
  }
}, {
  title: "发起签署时间",
  dataIndex: "signDate",
  width: 150,
  scopedSlots: {
    customRender: "signDate"
  }
}, {
  title: "员工签署时间",
  dataIndex: "empSignDate",
  width: 150,
  scopedSlots: {
    customRender: "empSignDate"
  }
}, {
  title: "合同签署完成时间",
  dataIndex: "signFinishDate",
  width: 150,
  scopedSlots: {
    customRender: "signFinishDate"
  }
}, {
  title: "合同期限",
  dataIndex: "dateline",
  width: 100,
  scopedSlots: {
    customRender: "dateline"
  }
}, {
  title: "员工是否已查看",
  dataIndex: "isView",
  width: 140,
  scopedSlots: {
    customRender: "isView"
  }
}, {
  title: "操作",
  dataIndex: "action",
  fixed: "right",
  scopedSlots: {
    customRender: "action"
  }
}];
var columns_paper = [{
  title: "姓名",
  dataIndex: "empName",
  width: 100,
  scopedSlots: {
    customRender: "empName"
  },
  colDefault: true,
  isEdit: false,
  fixed: "left"
}, {
  title: "身份证号",
  dataIndex: "empIdCard",
  width: 200,
  scopedSlots: {
    customRender: "empIdCard"
  },
  colDefault: true,
  isEdit: false,
  fixed: "left"
}, {
  title: "合同名称",
  dataIndex: "name",
  width: 280,
  colDefault: true,
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "合同开始时间",
  dataIndex: "startTime",
  scopedSlots: {
    customRender: "startTime"
  },
  ellipsis: true,
  colDefault: true,
  width: 150
}, {
  title: "合同结束时间",
  dataIndex: "endTime",
  width: 150,
  colDefault: true,
  scopedSlots: {
    customRender: "endTime"
  }
}, {
  title: "合同类型",
  dataIndex: "type",
  width: 200,
  colDefault: true,
  scopedSlots: {
    customRender: "type"
  }
}, {
  title: "合同状态",
  dataIndex: "status",
  width: 180,
  colDefault: true,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "合同编号",
  dataIndex: "number",
  width: 200,
  scopedSlots: {
    customRender: "number"
  },
  colDefault: true
}, {
  title: "合同公司",
  dataIndex: "companyName",
  width: 200,
  scopedSlots: {
    customRender: "companyName"
  },
  colDefault: true
}, {
  title: "合同备注",
  dataIndex: "remarks",
  width: 280,
  colDefault: true,
  scopedSlots: {
    customRender: "remarks"
  }
}, {
  title: "手机号",
  dataIndex: "phone",
  width: 150,
  scopedSlots: {
    customRender: "phone"
  }
}, {
  title: "剩余有效时间",
  dataIndex: "remainingValidTime",
  width: 150,
  scopedSlots: {
    customRender: "remainingValidTime"
  }
}, {
  title: "合同签署完成时间",
  dataIndex: "subscribeTime",
  width: 150,
  scopedSlots: {
    customRender: "subscribeTime"
  }
}, {
  title: "合同期限",
  dataIndex: "contractLine",
  width: 100,
  scopedSlots: {
    customRender: "contractLine"
  }
}, {
  title: "操作",
  dataIndex: "action",
  width: 80,
  fixed: "right",
  scopedSlots: {
    customRender: "action"
  }
}];
var columns_all = [{
  title: "姓名",
  dataIndex: "userName",
  width: 100,
  scopedSlots: {
    customRender: "userName"
  },
  colDefault: true,
  ellipsis: true,
  isEdit: false,
  fixed: "left"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200,
  scopedSlots: {
    customRender: "idCard"
  },
  colDefault: true,
  isEdit: false,
  ellipsis: true,
  fixed: "left"
}, {
  title: "纸质/电子合同",
  dataIndex: "isEntity",
  width: 280,
  colDefault: true,
  ellipsis: true,
  scopedSlots: {
    customRender: "isEntity"
  }
}, {
  title: "合同名称",
  dataIndex: "conName",
  width: 280,
  colDefault: true,
  ellipsis: true,
  scopedSlots: {
    customRender: "conName"
  }
}, {
  title: "合同开始时间",
  dataIndex: "startDate",
  scopedSlots: {
    customRender: "startDate"
  },
  ellipsis: true,
  colDefault: true,
  width: 150
}, {
  title: "合同结束时间",
  dataIndex: "endDate",
  width: 150,
  colDefault: true,
  ellipsis: true,
  scopedSlots: {
    customRender: "endDate"
  }
}, {
  title: "合同类型",
  dataIndex: "conType",
  width: 200,
  colDefault: true,
  ellipsis: true,
  scopedSlots: {
    customRender: "conType"
  }
}, {
  title: "合同备注",
  dataIndex: "conRemark",
  width: 280,
  colDefault: true,
  ellipsis: true,
  scopedSlots: {
    customRender: "conRemark"
  }
}, {
  title: "手机号",
  dataIndex: "phone",
  width: 150,
  scopedSlots: {
    customRender: "phone"
  }
}, {
  title: "剩余有效时间",
  dataIndex: "remainingValidTime",
  width: 150,
  scopedSlots: {
    customRender: "remainingValidTime"
  }
}, {
  title: "合同签署完成时间",
  dataIndex: "signFinishDate",
  width: 150,
  scopedSlots: {
    customRender: "signFinishDate"
  }
}, {
  title: "合同期限",
  dataIndex: "contractLine",
  width: 100,
  scopedSlots: {
    customRender: "contractLine"
  }
}, {
  title: "操作",
  dataIndex: "action",
  width: 80,
  fixed: "right",
  scopedSlots: {
    customRender: "action"
  }
}];