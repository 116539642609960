import _mergeJSXProps2 from "@vue/babel-helper-vue-jsx-merge-props";
import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
import _objectWithoutProperties from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["key"];
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import Vue from "vue";
import VueDraggableResizable from "vue-draggable-resizable";
Vue.component("vue-draggable-resizable", VueDraggableResizable);
export default function (columns, init, initW) {
  var draggingMap = {};
  var actionWidth = 150;
  var actionIndex = columns.findIndex(function (item) {
    return item.dataIndex === "action";
  });
  var hasAction = actionIndex > -1;
  columns.forEach(function (col) {
    draggingMap[col.dataIndex] = col.width;
  });
  if (hasAction) {
    actionWidth = columns[actionIndex].width || actionWidth;
    draggingMap["action"] = actionWidth;
  }
  if (init) {
    var contentW = document.querySelector(".main-content").offsetWidth - 108 - (hasAction ? actionWidth : 0);
    if (initW < contentW) {
      var n = contentW / (columns.length - (hasAction ? 1 : 0));
      columns.forEach(function (col) {
        if (col.dataIndex === "action") {
          col.width = actionWidth;
        } else {
          col.width = n;
          draggingMap[col.dataIndex] = col.width;
        }
      });
    }
  }
  var draggingState = Vue.observable(draggingMap);
  var resizeableTitle = function resizeableTitle(h, props, children) {
    var thDom = null;
    var key = props.key,
      restProps = _objectWithoutProperties(props, _excluded);
    var col = columns.find(function (col) {
      var k = col.dataIndex || col.key;
      return k === key;
    });
    if (!col || !col.width) {
      return h("th", _mergeJSXProps([{}, restProps]), [children]);
    }
    var onDrag = function onDrag(x) {
      draggingState[key] = 0;
      col.width = Math.max(x, 1);
      return false;
    };
    var onDragstop = function onDragstop() {
      draggingState[key] = thDom.getBoundingClientRect().width;
      return false;
    };
    return h("th", _mergeJSXProps2([{}, restProps, {
      "directives": [{
        name: "ant-ref",
        value: function value(r) {
          return thDom = r;
        }
      }],
      "attrs": {
        "width": col.width
      },
      "class": "resize-table-th"
    }]), [h("div", {
      "class": "table-draggable-content",
      "style": "padding: 16px"
    }, [children]), col.dataIndex !== "action" && h("vue-draggable-resizable", {
      "key": col.key,
      "class": "table-draggable-handle",
      "attrs": {
        "w": 10,
        "x": draggingState[key] || col.width,
        "z": 1,
        "axis": "x",
        "draggable": true,
        "resizable": false
      },
      "on": {
        "dragging": onDrag,
        "dragstop": onDragstop
      }
    })]);
  };
  return resizeableTitle;
}
export function headerRow() {
  return {
    domProps: {
      id: "sortable_list"
    }
  };
}