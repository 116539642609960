import request from "@/utils/request";

// 查询所有通知分组
export function groupListReq(params) {
  return request({
    url: "/hr/api/v1/notice/listAllByCompanyId",
    method: "GET",
    params: params
  });
}
export function userNoticesReq(params) {
  return request({
    url: "/notice/api/v1/userNotices",
    method: "get",
    params: params
  });
}