import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.timers.js";
import { formatDate as _formatDate } from "@/utils/index";
import CountTo from "vue-count-to";
import moment from "moment";
import { listData, selectEmpSocListCount, Enum, getCity, getCityAcc, syncData } from "./api";
import { AreaPicker } from "xqjr-plugin-common";
import { socTypeSource } from "./../funcs";
import _ from "lodash";
import SelectWithInputAdd from "xqjr-plugin-form/components/XqSelectWithInputAdd.vue";
var columnDialogDetailguding = [{
  title: "姓名",
  dataIndex: "empName",
  fixed: "left",
  align: "center",
  ellipsis: true,
  width: 80
}, {
  title: "身份证",
  dataIndex: "idCard",
  align: "center",
  ellipsis: true,
  width: 200
}, {
  title: "数据所属月份",
  dataIndex: "paymentBelongsDate",
  align: "center",
  ellipsis: true,
  width: 90
}, {
  title: "险种",
  dataIndex: "itemName",
  align: "center",
  ellipsis: true,
  width: 90
}, {
  title: "缴费类型",
  dataIndex: "payCostType",
  align: "center",
  ellipsis: true,
  width: 90
}, {
  title: "个人缴费金额",
  dataIndex: "amountPersonal",
  align: "center",
  ellipsis: true,
  width: 80
}, {
  title: "单位缴费金额",
  dataIndex: "amountCompany",
  align: "center",
  ellipsis: true,
  width: 80
}];
export default {
  components: {
    SelectWithInputAdd: SelectWithInputAdd,
    CountTo: CountTo,
    AreaPicker: AreaPicker,
    BatchAdd: function BatchAdd() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("../components/batchAdd"));
      });
    }
  },
  data: function data() {
    return {
      socTypeSource: socTypeSource,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showQuickJumper: true
      },
      columnDialogDetailguding: columnDialogDetailguding,
      dataDetail2: [],
      dataDetailOrgin: [],
      spinning: false,
      confirmPlanZengVisible: false,
      shebaoSwitch: false,
      yibaoSwitch: false,
      gongjijinSwitch: false,
      shebaoLogin: {
        accountId: null,
        accountName: null,
        accountType: "社保",
        areaId: "282",
        areaName: null,
        cookie: null,
        extraField: null,
        isLogin: false
      },
      yibaoLogin: {
        accountId: null,
        accountName: null,
        accountType: "医保",
        areaId: "282",
        areaName: null,
        cookie: null,
        extraField: null,
        isLogin: false
      },
      gongjijinLogin: {
        accountId: null,
        accountName: null,
        accountType: "公积金",
        areaId: "282",
        areaName: null,
        cookie: null,
        extraField: null,
        isLogin: false
      },
      syncDate: [],
      // 时间

      mode2: ["month", "month"],
      value: [],
      defaultFangan: 0,
      progNameList: [],
      visible: false,
      isCloseMethod: false,
      confirmLoading: false,
      socCityName: "",
      active: 0,
      checkedKey: {
        checkedKeys: [],
        rightData: []
      },
      loading: true,
      empSocStatusOptions: [],
      // 社保状态枚举
      payTypeOptions: [],
      // 缴费类型枚举
      progTypeOptions: [],
      // 参保方案枚举
      selectAllHeadData: [],
      // 导出表头信息
      defaultSelectedKeys: [],
      topData: [{
        title: "全部人数",
        num: 0,
        empIdList: [],
        url: require("../../../../assets/shebao/全部.png")
      }, {
        title: "正常参保",
        num: 0,
        empIdList: [],
        url: require("../../../../assets/shebao/正常参保.png")
      }, {
        title: "增员/待增员人数",
        num: 0,
        empIdList: [],
        url: require("../../../../assets/shebao/增员减员.png")
      }, {
        title: "减员/待减员人数",
        num: 0,
        empIdList: [],
        url: require("../../../../assets/shebao/减员.png")
      }, {
        title: "未参保/停保",
        num: 0,
        empIdList: [],
        url: require("../../../../assets/shebao/停保.png")
      }],
      selectedRowKeys: [],
      searchVisible: false,
      staffSort: [{
        name: "养老保险",
        key: "1"
      }, {
        name: "工伤保险",
        key: "2"
      }, {
        name: "失业保险",
        key: "3"
      }, {
        name: "医疗保险",
        key: "4"
      }, {
        name: "生育保险",
        key: "5"
      }, {
        name: "公积金",
        key: "6"
      }],
      // 查询参数
      queryParams: {
        companyId: this.$store.state.hr.companyId,
        pageNo: 1,
        pageSize: 10,
        nameOrIdCard: "",
        empSocStatusString: ""
      },
      form: {
        progTypeOptions: "",
        socCityId: "",
        stopSocDate: "",
        sealedDate: "",
        empSocDate: "",
        empSocStatus: undefined,
        empMeStatus: undefined,
        empPfStatus: undefined
      },
      // 总条数
      total: 0,
      // 查询社保名单表格数据
      tableData: [],
      xianzhong: "",
      searchContent: "",
      filterMonth: "",
      needSyncDate: [{
        areId: 283,
        needSyncType: ["医保"]
      } // 青岛医保
      ],

      yibaoSycnData: [],
      shebaoSycnData: [],
      gongjijinSycnData: [],
      liststatus: [{
        value: "待入职",
        id: "1"
      }, {
        value: "在职",
        id: "2"
      }, {
        value: "待离职",
        id: "3"
      }, {
        value: "离职",
        id: "4"
      }],
      rStatus: undefined,
      isLock: false,
      socAccount: null,
      meAccount: null,
      pfAccount: null,
      contractSubject: null
    };
  },
  computed: {
    postTypeLst: function postTypeLst() {
      return this.$store.state.enums.postTypeLst;
    }
  },
  created: function created() {
    if (JSON.stringify(this.$getCondition("sbmd_condition")) !== "{}") {
      var ps = JSON.parse(this.$getCondition("sbmd_condition"));
      if (ps.companyId && ps.companyId === this.$store.state.hr.companyId) {
        if (ps.nameOrIdCard) {
          this.queryParams.nameOrIdCard = ps.nameOrIdCard;
        }
        if (ps.status) {
          this.rStatus = ps.status;
        }
        if (ps.active) {
          this.active = ps.active;
        }
        if (ps.empSocStatusString) {
          this.queryParams.empSocStatusString = ps.empSocStatusString;
        }
        if (ps.progTypeOptions) {
          this.form.progTypeOptions = ps.progTypeOptions;
        }
        if (ps.socCityId) {
          this.form.socCityId = ps.socCityId;
        }
        if (ps.stopSocDate) {
          this.form.stopSocDate = ps.stopSocDate;
        }
        if (ps.sealedDate) {
          this.form.sealedDate = ps.sealedDate;
        }
        if (ps.empSocDate) {
          this.form.empSocDate = ps.empSocDate;
        }
        if (ps.socAccount) {
          this.socAccount = ps.socAccount;
        }
        if (ps.meAccount) {
          this.meAccount = ps.meAccount;
        }
        if (ps.pfAccount) {
          this.pfAccount = ps.pfAccount;
        }
        if (ps.contractSubject) {
          this.contractSubject = ps.contractSubject;
        }

        // 读取缓存
        if (this.$getPageSize("sbmd_condition")) {
          this.pagination.pageSize = this.queryParams.pageSize = parseInt(this.$getPageSize("sbmd_condition")) === 1 ? 10 : parseInt(this.$getPageSize("sbmd_condition"));
        }
        if (this.$getPage("sbmd_condition")) {
          this.queryParams.pageNo = this.pagination.current = parseInt(this.$getPage("sbmd_condition")) === 0 ? 1 : parseInt(this.$getPage("sbmd_condition"));
        }
      }
    }
    this.getEnum();
    this.getSelectEmpSocListCount();
    var that = this;
    // 监听来自插件的同步数据
    document.addEventListener("SynDataButton", function (e) {
      that.spinning = false;
      switch (e.detail.from) {
        case "青岛医保":
          var count = e.detail.arry.length;
          for (var i = 0; i < count; i++) {
            that.yibaoSycnData.push(e.detail.arry[i]);
          }
          break;
      }
    });
  },
  filters: {
    // 过滤枚举
    filtersOptions: function filtersOptions(a, b) {
      var data = b.filter(function (item) {
        return item.value === a;
      });
      return data.length > 0 ? data[0].label : "-";
    }
  },
  methods: {
    getPostTypeNew: function getPostTypeNew(val) {
      return socTypeSource[val]["label"];
    },
    // 按人员状态检索
    handleProgName2: function handleProgName2(val) {
      this.rStatus = val;
      this.queryParams.pageNo = 1;
      this.getList();
    },
    handleChange: function handleChange(e) {
      this.pagination.current = 1;
      this.filterDate();
    },
    handleChangeStaff: function handleChangeStaff(e) {
      this.pagination.current = 1;
      if (e === undefined) {
        this.xianzhong = "";
      } else {
        switch (e) {
          case "1":
            this.xianzhong = "养老保险";
            break;
          case "2":
            this.xianzhong = "工伤保险";
            break;
          case "3":
            this.xianzhong = "失业保险";
            break;
          case "4":
            this.xianzhong = "医疗保险";
            break;
          case "5":
            this.xianzhong = "生育保险";
            break;
          case "6":
            this.xianzhong = "公积金";
            break;
        }
      }
      this.filterDate();
    },
    onMonthChange: function onMonthChange(date, dateString) {
      this.pagination.current = 1;
      this.filterMonth = dateString;
      this.filterDate();
    },
    getEmyState: function getEmyState(record) {
      // {增员: 2, 减员: 4, 未参保: 0, 停保: 6, 正常参保: 1, 待增员: 3, 待减员: 5}
      var status = "";
      switch (record.socStatus) {
        case 0:
          status += "社保未参保";
          break;
        case 1:
          status += "社保正常参保";
          break;
        case 2:
          status += "社保增员";
          break;
        case 3:
          status += "社保待增员";
          break;
        case 4:
          status += "社保减员";
          break;
        case 5:
          status += "社保待减员";
          break;
        case 6:
          status += "社保停保";
          break;
      }
      switch (record.meStatus) {
        case 0:
          status += "/医保未参保";
          break;
        case 1:
          status += "/医保正常参保";
          break;
        case 2:
          status += "/医保增员";
          break;
        case 3:
          status += "/医保待增员";
          break;
        case 4:
          status += "/医保减员";
          break;
        case 5:
          status += "/医保待减员";
          break;
        case 6:
          status += "/医保停保";
          break;
      }
      switch (record.pfStatus) {
        case 0:
          status += "/公积金未参保";
          break;
        case 1:
          status += "/公积金正常参保";
          break;
        case 2:
          status += "/公积金增员";
          break;
        case 3:
          status += "/公积金待增员";
          break;
        case 4:
          status += "/公积金减员";
          break;
        case 5:
          status += "/公积金待减员";
          break;
        case 6:
          status += "/公积金停保";
          break;
      }
      return status;
    },
    // 过滤数据再分页
    filterDate: function filterDate() {
      var len = this.dataDetailOrgin.length;
      this.dataDetail2 = [];
      for (var i = 0; i < len; i++) {
        if ((this.dataDetailOrgin[i].empName.indexOf(this.searchContent) !== -1 || this.dataDetailOrgin[i].idCard.indexOf(this.searchContent) !== -1) && this.dataDetailOrgin[i].itemName.indexOf(this.xianzhong) !== -1 && this.dataDetailOrgin[i].paymentBelongsDate.indexOf(this.filterMonth) !== -1) {
          this.dataDetail2.push(this.dataDetailOrgin[i]);
        }
      }
      this.pagination.total = this.dataDetail2.length;
    },
    disabledDate: function disabledDate(current) {
      return current && (current <= this.value[0] || current >= moment(this.value[1]).subtract(-1, "months"));
    },
    Login: function Login(index) {
      switch (index) {
        case 1:
          // 社保
          if (this.shebaoLogin.loginUrl !== null && this.shebaoLogin.loginUrl.length !== 0) {
            window.open(this.shebaoLogin.loginUrl);
          }
          break;
        case 2:
          // 医保
          if (this.yibaoLogin.loginUrl !== null && this.yibaoLogin.loginUrl.length !== 0) {
            window.open(this.yibaoLogin.loginUrl);
          }
          break;
        case 3:
          // 公积金
          if (this.gongjijinLogin.loginUrl !== null && this.gongjijinLogin.loginUrl.length !== 0) {
            window.open(this.gongjijinLogin.loginUrl);
          }
          break;
      }
    },
    SyncHandle: function SyncHandle() {
      var _this = this;
      if (this.syncDate.length === 0) {
        this.$message.error("请选择数据同步时间");
        return;
      }
      if (this.defaultFangan === 0) {
        this.$message.error("请选择城市");
        return;
      }
      if (this.shebaoSwitch === false && this.yibaoSwitch === false && this.gongjijinSwitch === false) {
        this.$message.success("请选择要同步的数据");
        return;
      }
      this.spinning = true;
      var beanName = "";
      for (var i = 0; i < this.progNameList.length; i++) {
        if (this.progNameList[i].areaId === this.defaultFangan) {
          beanName = this.progNameList[i].syncServiceBeanName;
          break;
        }
      }
      syncData({
        areaId: this.defaultFangan,
        companyId: this.$store.state.hr.companyId,
        endDate: this.syncDate[1],
        socIsTrue: this.shebaoSwitch,
        meIsTrue: this.yibaoSwitch,
        pfIsTrue: this.gongjijinSwitch,
        startDate: this.syncDate[0],
        syncServiceBeanName: beanName,
        socInfo: this.shebaoSycnData,
        meInfo: this.yibaoSycnData,
        pfInfo: this.gongjijinSycnData
      }).then(function (res) {
        _this.spinning = false;
        _this.dataDetailOrgin = res.data;
        _this.dataDetail2 = _this.dataDetailOrgin;
        _this.pagination.total = _this.dataDetail2.length;
        _this.pagination.current = 1;
        _this.confirmPlanZengVisible = true;
        _this.isCloseMethod = false;
        _this.xianzhong = "";
        _this.searchContent = "";
        _this.filterMonth = "";
      }).catch(function (err) {
        _this.spinning = false;
        console.log("debug log --> ", err);
      });
    },
    // 分页查询
    handlePageChange: function handlePageChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
    },
    onShebaoChange: function onShebaoChange(checked) {
      this.shebaoSwitch = checked;
    },
    onYibaoChange: function onYibaoChange(checked) {
      this.yibaoSwitch = checked;
      if (this.yibaoSwitch === true) {
        // 检查该地区医保是否需要前端进行同步数据
        var isSync = false;
        for (var i = 0; i < this.needSyncDate.length; i++) {
          if (this.needSyncDate[i].areId === this.defaultFangan) {
            // 确认地区
            for (var j = 0; j < this.needSyncDate[i].needSyncType.length; j++) {
              if (this.needSyncDate[i].needSyncType[j] === "医保") {
                isSync = true;
                break;
              }
            }
            break;
          }
        }
        if (isSync) {
          // 需要同步
          switch (this.defaultFangan) {
            case 283:
              // 青岛地区
              this.spinning = true;
              window.postMessage({
                start: this.syncDate[0],
                end: this.syncDate[1],
                cookie: this.yibaoLogin.cookie,
                type: "青岛医保" // 这个值会原封不动的传递回来
              }, "*");
              break;
          }
        }
      } else {
        this.yibaoSycnData = [];
      }
    },
    onGongjijinChange: function onGongjijinChange(checked) {
      this.gongjijinSwitch = checked;
    },
    huamingce: function huamingce() {
      this.$router.push({
        path: "/shebao/employee"
      });
    },
    download: function download() {
      window.open("https://www.yuque.com/docs/share/aadc62e4-0ea4-4387-80f3-ad8cd724018f?#");
    },
    chooseProgName: function chooseProgName(value) {
      var _this2 = this;
      this.defaultFangan = value;
      this.spinning = true;
      getCityAcc({
        areaId: this.defaultFangan
      }).then(function (res) {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].accountType === "社保") {
            _this2.shebaoLogin = res.data[i];
          } else if (res.data[i].accountType === "医保") {
            _this2.yibaoLogin = res.data[i];
          } else if (res.data[i].accountType === "公积金") {
            _this2.gongjijinLogin = res.data[i];
          }
        }
        _this2.spinning = false;
      }).catch(function (err) {
        _this2.$message.error("失败!");
        _this2.spinning = false;
        console.log("debug log --> ", err);
      });
    },
    dateOk: function dateOk() {
      this.$refs.date.$refs.picker.sOpen = false;
    },
    handlePanelChange2: function handlePanelChange2(value, mode) {
      this.value = value;
      this.syncDate = [];
      this.syncDate.push(moment(value[0]).format("YYYYMM"));
      this.syncDate.push(moment(value[1]).format("YYYYMM"));

      // this.mode2 = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]];
    },
    formatDate: function formatDate(text) {
      return _formatDate(text);
    },
    OnsearchRef: function OnsearchRef() {
      this.form = {
        progTypeOptions: "",
        socCityId: "",
        stopSocDate: "",
        sealedDate: "",
        empSocDate: ""
      };
      this.socAccount = null;
      this.meAccount = null;
      this.pfAccount = null;
      this.contractSubject = null;
      this.getList();
    },
    // 批量正常参保
    onBatchNormalSoc: function onBatchNormalSoc() {
      this.isLock = true;
    },
    checkboxChange: function checkboxChange(list) {},
    showModal: function showModal() {
      this.visible = true;
    },
    handleOk: function handleOk(e) {
      var data = _objectSpread(_objectSpread(_objectSpread({}, this.queryParams), this.form), {}, {
        companyId: this.$store.state.hr.companyId
      });
      if (this.socAccount) {
        data["socAccount"] = this.socAccount;
      }
      if (this.meAccount) {
        data["meAccount"] = this.meAccount;
      }
      if (this.pfAccount) {
        data["pfAccount"] = this.pfAccount;
      }
      if (this.contractSubject) {
        data["contractSubject"] = this.contractSubject;
      }
      data.empSocStatusString = this.active ? this.topData[this.active].empIdList.join(",") : "";
      if (this.rStatus) {
        data.status = this.rStatus;
      }
      this.$exportFilePost("/hr/api/v1/empSocTransform/export", data, "社保名单");
    },
    handleCancel: function handleCancel(e) {
      this.$refs.transfer.checkedKeys = [];
      this.$refs.transfer.rightData = [];
      this.visible = false;
    },
    cancelSyn: function cancelSyn() {
      this.isCloseMethod = false;
      this.confirmPlanZengVisible = false;
    },
    onDataSyn: function onDataSyn() {
      var _this3 = this;
      this.syncDate = [];
      this.defaultFangan = 0;
      this.value = [];
      this.spinning = false;
      getCity({
        isDistinct: true
      }).then(function (res) {
        if (res.data != null) {
          if (res.data != null) {
            _this3.progNameList = [];
            for (var i = 0; i < res.data.length; i++) {
              _this3.progNameList.push(res.data[i]);
            }
          }
        }
      }).catch(function (err) {
        _this3.$message.error("失败!");
        console.log("debug log --> ", err);
      });
      this.isCloseMethod = true;
    },
    // 导出
    onExport: function onExport() {
      this.handleOk();
    },
    tableChange: function tableChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 处理城市
    handleCity: function handleCity(e) {
      if (e.value.length > 0) {
        var value = e.value;
        var label = e.label;
        this.form.socCityId = value[value.length - 1].toString();
        if (label.length && label.length === 3) {
          this.socCityName = label[0] + "/" + label[1] + "/" + label[2];
        } else if (label.length && label.length === 2) {
          this.socCityName = label[0] + "/" + label[1];
        }
      } else {
        this.form.socCityId = "";
        this.socCityName = "";
      }
    },
    getEnum: function getEnum() {
      var _this4 = this;
      // this.isTop = false;
      Enum(this.$store.state.hr.companyId).then(function (res) {
        _this4.payTypeOptions = [];
        _this4.progTypeOptions = [];
        _this4.empSocStatusOptions = [];
        if (Object.keys(res.data.socPayCostTypeMap).length > 0) {
          for (var key in res.data.socPayCostTypeMap) {
            _this4.payTypeOptions.push({
              value: res.data.socPayCostTypeMap[key],
              label: key
            });
          }
        }
        if (Object.keys(res.data.empSocStatusMap).length > 0) {
          for (var _key in res.data.empSocStatusMap) {
            if (_key === "正常参保") {
              _this4.topData[1].empIdList.push(res.data.empSocStatusMap[_key]);
            }
            if (_key === "增员") {
              _this4.topData[2].empIdList.push(res.data.empSocStatusMap[_key]);
            }
            if (_key === "待增员") {
              _this4.topData[2].empIdList.push(res.data.empSocStatusMap[_key]);
            }
            if (_key === "待减员") {
              _this4.topData[3].empIdList.push(res.data.empSocStatusMap[_key]);
            }
            if (_key === "减员") {
              _this4.topData[3].empIdList.push(res.data.empSocStatusMap[_key]);
            }
            if (_key === "未参保") {
              _this4.topData[4].empIdList.push(res.data.empSocStatusMap[_key]);
            }
            if (_key === "停保") {
              _this4.topData[4].empIdList.push(res.data.empSocStatusMap[_key]);
            }
            _this4.empSocStatusOptions.push({
              value: res.data.empSocStatusMap[_key],
              label: _key
            });
          }
        }
        if (Object.keys(res.data.socProgMap).length > 0) {
          for (var _key2 in res.data.socProgMap) {
            _this4.progTypeOptions.push({
              value: res.data.socProgMap[_key2],
              label: _key2
            });
          }
        }
        _this4.getList();
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this4.$message.error("失败!");
      });
    },
    detailed: function detailed(data) {
      this.$router.push({
        path: "shebaoMingDan/detailed/".concat(data.empId),
        query: {
          prevpage: "/shebao/shebaoMingDan"
        }
      });
    },
    showSizeChange: function showSizeChange(current, size) {
      this.queryParams.pageNo = 1;
      this.queryParams.pageSize = size;
      this.getList();
    },
    onTopTabs: function onTopTabs(data, index) {
      this.active = index;
      this.queryParams.pageNo = 1;
      this.getList();
    },
    // 查询社保名单顶部数量统计
    getSelectEmpSocListCount: function getSelectEmpSocListCount() {
      var _this5 = this;
      selectEmpSocListCount({
        companyId: this.$store.state.hr.companyId
      }).then(function (res) {
        _this5.topData[0].num = res.data.allCount || 0;
        _this5.topData[1].num = res.data.normalSocCount || 0;
        _this5.topData[2].num = res.data.increaseCount + res.data.waitIncreaseCount || 0;
        _this5.topData[3].num = res.data.decreaseCount + res.data.waitDecreaseCount || 0;
        _this5.topData[4].num = res.data.stopSocCount + res.data.notSocCount || 0;
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this5.$message.error("失败!");
      });
    },
    /** 查询社保名单列表 */getList: function getList() {
      var _this6 = this;
      this.loading = true;
      var data = _objectSpread(_objectSpread({}, this.queryParams), this.form);
      if (this.socAccount) {
        data["socAccount"] = this.socAccount;
      }
      if (this.meAccount) {
        data["meAccount"] = this.meAccount;
      }
      if (this.pfAccount) {
        data["pfAccount"] = this.pfAccount;
      }
      if (this.contractSubject) {
        data["contractSubject"] = this.contractSubject;
      }
      data.empSocStatusString = this.active ? this.topData[this.active].empIdList.join(",") : "";
      if (this.rStatus) {
        data.status = this.rStatus;
      }
      listData(data).then(function (res) {
        _this6.tableData = res.data.entities;
        _this6.total = res.data.entityCount;
        setTimeout(function () {
          _this6.loading = false;
        }, 500);
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this6.$message.error("查询社保名单列表失败!");
        setTimeout(function () {
          _this6.loading = false;
        }, 500);
      });

      // 缓存
      if (this.active) {
        data.active = this.active;
      }
      this.$setCondition("sbmd_condition", JSON.stringify(data));
      this.$setPage("sbmd_condition", this.queryParams.pageNo + "");
      this.$setPageSize("sbmd_condition", this.queryParams.pageSize + "");
    },
    // 分页变化
    onChange: function onChange(pageNumber) {
      this.queryParams.pageNo = pageNumber;
      this.getList();
    },
    tap: function tap() {
      this.selectedRowKeys = [];
      this.getList();
      this.getEnum();
      this.getSelectEmpSocListCount();
    }
  }
};