import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
export function ruleTitle(titleString, searchName) {
  if (!titleString || !searchName) {
    return titleString;
  }
  if (searchName && searchName.length > 0) {
    // 匹配关键字正则
    var replaceReg = new RegExp(searchName, "g");
    // 高亮替换v-html值
    var replaceString = '<span style="color: #3C8AFF">' + searchName + "</span>";
    // 开始替换
    titleString = titleString.replace(replaceReg, replaceString);
  }
  return titleString;
}

// treeData添加字段
export function addTreeSlot(data) {
  if (!data) {
    return [];
  }
  data.forEach(function (el) {
    el.scopedSlots = {
      title: "custom"
    };
    addTreeSlot(el.companyList);
  });
  return data;
}

// 递归查询所有公司
export function allCompany(companyList, idArr) {
  for (var i = 0; i < companyList.length; i++) {
    if (companyList[i].type === "company") {
      idArr.push(companyList[i].id);
    }
    if (companyList[i].companyList != null) {
      allCompany(companyList[i].companyList, idArr);
    }
  }
}