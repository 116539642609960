var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.type === "back" ? _c("div", {
    staticClass: "tTitle-box-r"
  }, [_c("a-icon", {
    staticClass: "icon",
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _c("strong", [_vm._v(_vm._s(_vm.tit))])], 1) : _vm.type === "center" ? _c("div", {
    staticClass: "tTitle-box-c"
  }, [_c("strong", [_vm._v(_vm._s(_vm.tit))])]) : _c("div", {
    staticClass: "tTitle-box"
  }, [_c("div", {
    staticClass: "tTitle-l"
  }, [_c("a-tooltip", {
    attrs: {
      placement: "bottom"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.tit))])]), _c("div", {
    staticClass: "tTitle-l-tit"
  }, [_vm._v(_vm._s(_vm.tit))])], 2), _c("div", {
    staticClass: "tTitle-l-desc"
  }, [_vm._t("left")], 2)], 1), _c("div", {
    staticClass: "tTitle-r"
  }, [_vm._t("right")], 2)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };