import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { Approval, ApprovalApi } from "./apiOld";
import { mapState } from "vuex";
import { getApprovalStatus, getApprovalStatusColorBlue, approvalTypeArr } from "./funcs";
export default {
  props: ["approvalTabState"],
  data: function data() {
    return {
      selectedKeys: [],
      // 二级菜单
      current: [],
      spinning: false,
      listNumArr: ["waitTaskNum", "hasTaskNum", "createByMeNum", "notifierMeNum"],
      pagination: {
        pageNo: 1,
        pageSize: 20,
        pageSizeOptions: ["10", "20", "50", "100"],
        // 3,4 有
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      params: {
        statous: null,
        apprvId: null,
        creatUserId: null,
        gmtCreateStart: null,
        gmtCreateEnd: null
      },
      listNumAll: 0,
      userApproverId: "",
      // 审批操作参数
      form: this.$form.createForm(this, {
        name: "coordinated"
      }),
      companyId: this.$store.state.hr.companyId,
      data: [],
      approvalType: [],
      items: [],
      itemTypes: []
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  watch: {
    approvalTabState: {
      handler: function handler(val, oldval) {
        if (val) {
          this.createdFun(val);
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    openChange: function openChange(e) {
      this.current = [];
      this.selectedKeys = [];
    },
    getApprovalStatus: getApprovalStatus,
    getApprovalStatusColorBlue: getApprovalStatusColorBlue,
    approvalTypeArr: approvalTypeArr,
    // 初始加载
    createdFun: function createdFun(val) {
      this.approvalState = val;
      this.form.resetFields();
      this.items = [];
      this.current = [];
      this.selectedKeys = [];
      this.params = {
        status: null,
        approvId: null,
        creatUserId: null,
        gmtCreateStart: null,
        gmtCreateEnd: null
      };
      this.pagination = {
        pageNo: 1,
        pageSize: 20,
        pageSizeOptions: ["10", "20", "50", "100"],
        // 3,4 有
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      };
      this.getApprovalList(); // 获取审批列表
      this.getAllBySite(); // 获取所有审批
    },
    // 查看审批节点
    statusDetail: function statusDetail(record) {
      if (this.approvalState === 3) {
        this.$router.push("/approval/detail/".concat(record.id, "/").concat(record.userId));
      } else {
        this.$router.push("/approval/detail/".concat(record.userApproId, "/").concat(record.userApproverId));
      }
    },
    // 审批组
    subMenuGroup: function subMenuGroup(item) {
      this.params.approvId = null;
      this.itemTypes = item.approFlowList;
      this.params.approvGroupId = item.id;
      this.getApprovalList(1);
    },
    /* menuItem */menuItem: function menuItem(item) {
      this.params.approvGroupId = null;
      this.params.approvId = item.id;
      this.getApprovalList(1);
    },
    // 获取所有审批 bysite
    getAllBySite: function getAllBySite() {
      var _this = this;
      var listNumArr = this.listNumArr;
      Approval({
        url: ApprovalApi.allByCompanyAndUser + this.companyId + "/hr"
      }).then(function (data) {
        _this.items = data.data;
        var listNum = 0;
        _this.items.forEach(function (item) {
          item.key = item.id;
          item.title = item.groupName;
          listNum += item[listNumArr[_this.approvalState - 1]];
        });
        _this.listNumAll = listNum;
      }).catch(function (err) {
        return console.warn(err);
      });
    },
    /* 获取审批列表 */getApprovalList: function getApprovalList(e) {
      var _this2 = this;
      var urlArr = ["myApproval", "myApproval", "creFromMe", "ccMe"];
      this.spinning = true;
      if (e) {
        this.pagination.pageNo = 1;
        this.pagination.current = 1;
      }
      Approval({
        url: ApprovalApi[urlArr[this.approvalState - 1]],
        params: _objectSpread(_objectSpread(_objectSpread({}, this.params), this.pagination), {}, {
          companyId: this.companyId,
          moduleType: "hr",
          hasAppro: this.approvalState === 1 ? false : this.approvalState === 2 ? true : null // 是否审批
        })
      }).then(function (_ref) {
        var data = _ref.data;
        _this2.spinning = false;
        _this2.data = data.entities;
        _this2.pagination.total = data.entityCount;
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.pageNo = pagination.current;
      this.getApprovalList();
    },
    // 筛选审批类型
    selectApproval: function selectApproval(e) {
      this.params.status = e;
      this.getApprovalList(1);
    },
    /* 日期选择 */onChange: function onChange(date, dateString) {
      this.params.gmtCreateStart = null;
      this.params.gmtCreateEnd = null;
      var CreateStart = "".concat(dateString[0], " 00:00:00");
      var CreateEnd = "".concat(dateString[0], " 23:59:59");
      if (date.length) {
        this.params.gmtCreateStart = Date.parse(new Date(CreateStart));
        this.params.gmtCreateEnd = Date.parse(new Date(CreateEnd));
      }
      this.getApprovalList();
    }
  }
};