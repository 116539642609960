import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_vm.isContainByString("file") ? _c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 工价规则 ")]) : _vm._e(), _c("div", {
    staticClass: "body page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "body"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "343px",
      height: "32px",
      "margin-top": "24px"
    },
    attrs: {
      placeholder: "搜索规则名称",
      allowClear: ""
    },
    on: {
      search: _vm.onSearch
    }
  }), _c("div", {
    staticClass: "left-top"
  }, [_c("div", [_vm.deleteState ? _c("a-checkbox", {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      disabled: !_vm.list.length
    },
    on: {
      change: _vm.onCheckChange
    },
    model: {
      value: _vm.allState,
      callback: function callback($$v) {
        _vm.allState = $$v;
      },
      expression: "allState"
    }
  }) : _vm._e(), _c("span", {
    staticClass: "title"
  }, [_vm._v("工价规则")])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "primary",
    staticStyle: {
      "font-size": "14px",
      "font-weight": "500",
      "margin-right": "16px"
    },
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.onCreate
    }
  }, [_vm._v(" 新增规则 ")]), _c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuClick
    },
    slot: "overlay"
  }, [!_vm.isContainByString("file") ? _c("a-menu-item", {
    key: "1"
  }, [_vm._v("下发规则")]) : _vm._e(), _c("a-menu-item", {
    key: "2"
  }, [_vm._v("批量删除")])], 1), _c("a-button", {
    attrs: {
      type: "link"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "22px",
      "font-weight": "500"
    },
    attrs: {
      type: "ellipsis"
    }
  })], 1)], 1)], 1)]), _c("div", {
    staticClass: "xian"
  }), _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index + 1,
      staticClass: "left-center",
      class: _vm.listIndex == index ? "active" : "",
      on: {
        click: function click($event) {
          return _vm.onClickRule(item, index);
        }
      }
    }, [_c("div", {
      staticStyle: {
        display: "flex"
      }
    }, [_vm.deleteState ? _c("a-checkbox", {
      staticStyle: {
        "margin-left": "4px"
      },
      on: {
        change: _vm.itemCheckChange
      },
      model: {
        value: item.isChecked,
        callback: function callback($$v) {
          _vm.$set(item, "isChecked", $$v);
        },
        expression: "item.isChecked"
      }
    }) : _vm._e(), _c("div", {
      class: _vm.listIndex === index ? "listTitle" : "left-center-title",
      staticStyle: {
        width: "240px",
        overflow: "hidden",
        "white-space": "nowrap",
        "text-overflow": "ellipsis"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1), _c("span", {
      staticClass: "left-center-title1"
    }, [_vm._v(_vm._s(item.usePeopleNumber) + " 人")])]);
  }), _c("a-pagination", {
    staticStyle: {
      "margin-top": "12px",
      float: "right"
    },
    attrs: {
      "page-size-options": _vm.pageSizeOptions,
      total: _vm.total,
      "show-size-changer": "",
      "page-size": _vm.pageSize,
      size: "small"
    },
    on: {
      showSizeChange: _vm.onShowSizeChange,
      change: _vm.currentChange
    },
    model: {
      value: _vm.current,
      callback: function callback($$v) {
        _vm.current = $$v;
      },
      expression: "current"
    }
  }), _vm.lastCheckState ? _c("div", {
    staticClass: "functionBox"
  }, [_c("a-button", {
    on: {
      click: _vm.checkCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "16px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.deleteItems
    }
  }, [_vm._v(" 确定 ")])], 1) : _vm._e()], 2), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "right-top"
  }, [!_vm.ruleNameInput ? _c("div", {
    staticClass: "topTile",
    staticStyle: {
      color: "#3c3d43",
      "font-size": "18px",
      "font-weight": "bold"
    }
  }, [_vm._v(" " + _vm._s(_vm.detaile ? _vm.detaile.name : "---") + " "), _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.editRuleName
    }
  }, [_c("xq-icon", {
    staticStyle: {
      width: "18px",
      height: "18px"
    },
    attrs: {
      type: "icon-bianji"
    }
  })], 1)], 1) : _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("a-input", {
    ref: "nameInput",
    attrs: {
      placeholder: "请输入规则名称"
    },
    model: {
      value: _vm.detaile.name,
      callback: function callback($$v) {
        _vm.$set(_vm.detaile, "name", $$v);
      },
      expression: "detaile.name"
    }
  }), _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.addRuleOk
    }
  }, [_vm._v(" 确认 ")]), _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.cancelRule
    }
  }, [_vm._v(" 取消 ")])], 1), _c("div", [_c("a-button", {
    staticStyle: {
      "margin-right": "24px"
    },
    attrs: {
      type: "primary",
      disabled: _vm.ruleNameInput || !_vm.list.length,
      ghost: ""
    },
    on: {
      click: function click($event) {
        _vm.deleteVisible2 = true;
      }
    }
  }, [_vm._v(" 删除 ")])], 1)]), _vm.list.length > 0 ? _c("div", [_c("div", [_c("div", {
    staticClass: "addIngItem"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "24px"
    }
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px",
      "font-weight": "bold"
    }
  }, [_vm._v("已添加的规则项")])]), _c("a-button", {
    staticStyle: {
      display: "flex",
      "flex-direction": "row",
      "align-items": "center",
      cursor: "pointer",
      "margin-top": "16px"
    },
    attrs: {
      type: "link",
      disabled: _vm.ruleNameInput
    },
    on: {
      click: _vm.onCreateItem
    }
  }, [_c("a-icon", {
    staticClass: "icon",
    staticStyle: {
      "margin-top": "4px"
    },
    attrs: {
      type: "plus-circle"
    }
  }), _c("span", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "1000"
    }
  }, [_vm._v("添加规则")])], 1)], 1), _c("div", [_c("a-table", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      rowKey: "id",
      columns: _vm.columns2,
      "data-source": _vm.dataList,
      pagination: _vm.paginationOpt
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return _c("div", {}, [_c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.onClickEdit(record);
            }
          }
        }, [_vm._v("编辑")]), _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.showDelete(record);
            }
          }
        }, [_vm._v("删除")])], 1);
      }
    }], null, false, 2780035168)
  })], 1)]), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticClass: "shu"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px",
      "font-weight": "bold"
    }
  }, [_vm._v("使用者")])])]), _c("a-input-search", {
    staticStyle: {
      width: "201px",
      height: "32px",
      "margin-top": "14px"
    },
    attrs: {
      placeholder: "搜索姓名/身份证号"
    },
    on: {
      search: _vm.onSearch1
    },
    model: {
      value: _vm.nameOrIdCard,
      callback: function callback($$v) {
        _vm.nameOrIdCard = $$v;
      },
      expression: "nameOrIdCard"
    }
  }), _c("a-table", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      rowKey: "id",
      columns: _vm.columns,
      "data-source": _vm.dataList2,
      pagination: _vm.paginationOpt2
    }
  })], 1) : _c("div", {
    staticClass: "zanwushuju"
  }, [_c("a-empty")], 1)]), _c("a-drawer", {
    staticClass: "drawer",
    attrs: {
      title: _vm.title,
      placement: _vm.placement,
      width: "580px",
      visible: _vm.visible
    },
    on: {
      close: function close($event) {
        _vm.visible = false;
      }
    }
  }, [_c("div", [_c("span", {
    staticStyle: {
      color: "#55565d"
    }
  }, [_vm._v(" 生效时间 ")]), _c("a-select", {
    staticStyle: {
      width: "255px",
      "margin-left": "15px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.effectTypeChange
    },
    model: {
      value: _vm.effectType,
      callback: function callback($$v) {
        _vm.effectType = $$v;
      },
      expression: "effectType"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 长期 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 半长期 ")]), _c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 限时 ")])], 1)], 1), _vm.effectType === "2" || _vm.effectType === "1" ? _c("div", [_c("a-radio-group", {
    staticStyle: {
      "margin-top": "16px"
    },
    on: {
      change: _vm.typeChange
    },
    model: {
      value: _vm.timeStart.type,
      callback: function callback($$v) {
        _vm.$set(_vm.timeStart, "type", $$v);
      },
      expression: "timeStart.type"
    }
  }, [_c("a-radio-button", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 按时间 ")]), _c("a-radio-button", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 按日期 ")])], 1)], 1) : _vm._e(), _c("div", [_vm.timeStart.type === "2" && _vm.effectType === "1" ? _c("a-range-picker", {
    staticStyle: {
      "margin-top": "16px"
    },
    on: {
      change: _vm.bothChange
    },
    model: {
      value: _vm.rangeDate,
      callback: function callback($$v) {
        _vm.rangeDate = $$v;
      },
      expression: "rangeDate"
    }
  }) : _vm._e(), _vm.timeStart.type === "1" && _vm.effectType === "1" ? _c("div", {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_vm._v(" 入职后的第 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 1
    },
    model: {
      value: _vm.timeStart.startNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.timeStart, "startNumber", $$v);
      },
      expression: "timeStart.startNumber"
    }
  }), _vm._v("至 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: _vm.timeStart.startNumber
    },
    model: {
      value: _vm.timeStart.endNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.timeStart, "endNumber", $$v);
      },
      expression: "timeStart.endNumber"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "108px"
    },
    on: {
      change: _vm.unitChange
    },
    model: {
      value: _vm.timeStart.unit,
      callback: function callback($$v) {
        _vm.$set(_vm.timeStart, "unit", $$v);
      },
      expression: "timeStart.unit"
    }
  }, _vm._l(_vm.option, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.timeStart.type === "1" && _vm.effectType === "2" ? _c("div", {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_vm._v(" 始于第"), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 1
    },
    model: {
      value: _vm.timeStart.startNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.timeStart, "startNumber", $$v);
      },
      expression: "timeStart.startNumber"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "108px",
      margin: "0 5px"
    },
    on: {
      change: _vm.unitChange
    },
    model: {
      value: _vm.timeStart.unit,
      callback: function callback($$v) {
        _vm.$set(_vm.timeStart, "unit", $$v);
      },
      expression: "timeStart.unit"
    }
  }, _vm._l(_vm.option, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.timeStart.type === "2" && _vm.effectType === "2" ? _c("div", {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_vm._v(" 始于"), _c("a-date-picker", {
    staticStyle: {
      margin: "0 5px"
    },
    on: {
      change: _vm.onlyStartChange
    },
    model: {
      value: _vm.startDatePick,
      callback: function callback($$v) {
        _vm.startDatePick = $$v;
      },
      expression: "startDatePick"
    }
  })], 1) : _vm._e()], 1), _vm.effectType ? _c("div", [_c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticClass: "shu"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("基础规则")])])]), _c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      checked: _vm.calculation
    },
    model: {
      value: _vm.calculation,
      callback: function callback($$v) {
        _vm.calculation = $$v;
      },
      expression: "calculation"
    }
  }, [_vm._v(" 工价计算 ")])], 1), _vm.calculation ? _c("a-radio-group", {
    staticStyle: {
      "margin-top": "20px"
    },
    model: {
      value: _vm.ruleType,
      callback: function callback($$v) {
        _vm.ruleType = $$v;
      },
      expression: "ruleType"
    }
  }, [_c("a-radio-button", {
    staticStyle: {
      width: "104px",
      "text-align": "center"
    },
    attrs: {
      value: "2",
      disabled: _vm.timeStart.unit === 2
    }
  }, [_vm._v(" 计时制 ")]), _c("a-radio-button", {
    staticStyle: {
      width: "104px",
      "text-align": "center"
    },
    attrs: {
      value: "1",
      disabled: _vm.timeStart.unit === 3
    }
  }, [_vm._v(" 计天制 ")])], 1) : _vm._e(), _c("div", {
    staticStyle: {
      "margin-bottom": "70px"
    }
  }, [_vm.calculation ? _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" 工价 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 0
    },
    model: {
      value: _vm.wagesdetail,
      callback: function callback($$v) {
        _vm.wagesdetail = $$v;
      },
      expression: "wagesdetail"
    }
  }), _vm.ruleType === "1" ? _c("span", [_vm._v("元/天")]) : _vm._e(), _vm.ruleType === "2" ? _c("span", [_vm._v("元/工时")]) : _vm._e()], 1) : _vm._e(), _c("div", [_c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "20px"
    },
    model: {
      value: _vm.welfareChecked,
      callback: function callback($$v) {
        _vm.welfareChecked = $$v;
      },
      expression: "welfareChecked"
    }
  }, [_vm._v("红包")])], 1), _vm.welfareChecked ? _c("div", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_vm._v(" 每满 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      formatter: _vm.limitNumber,
      parser: _vm.limitNumber,
      min: 0
    },
    model: {
      value: _vm.RedPacketRule.number,
      callback: function callback($$v) {
        _vm.$set(_vm.RedPacketRule, "number", $$v);
      },
      expression: "RedPacketRule.number"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "108px",
      margin: "0 5px"
    },
    model: {
      value: _vm.RedPacketRule.units,
      callback: function callback($$v) {
        _vm.$set(_vm.RedPacketRule, "units", $$v);
      },
      expression: "RedPacketRule.units"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1",
      disabled: _vm.timeStart.unit === 3
    }
  }, [_vm._v(" 月 ")]), _c("a-select-option", {
    attrs: {
      value: "2",
      disabled: _vm.timeStart.unit === 3
    }
  }, [_vm._v(" 天 ")]), _c("a-select-option", {
    attrs: {
      value: "3",
      disabled: _vm.timeStart.unit === 2
    }
  }, [_vm._v(" 工时 ")])], 1), _vm._v(" 发放 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 1
    },
    model: {
      value: _vm.RedPacketRule.redPacketAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.RedPacketRule, "redPacketAmount", $$v);
      },
      expression: "RedPacketRule.redPacketAmount"
    }
  }), _vm._v(" 元红包 ")], 1) : _vm._e()]), _c("div", {
    staticStyle: {
      "margin-top": "30px"
    }
  }, [_c("div", {
    staticClass: "shu"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("离职规则")])])]), _c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      checked: _vm.dissatisfyMonth
    },
    model: {
      value: _vm.dissatisfyMonth,
      callback: function callback($$v) {
        _vm.dissatisfyMonth = $$v;
      },
      expression: "dissatisfyMonth"
    }
  }, [_vm._v(" 不满月限制 ")]), _c("a-popover", {
    attrs: {
      placement: "top"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("span", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("对离职当月不满月有特殊规则时，需勾选")])]), _c("a-icon", {
    staticStyle: {
      cursor: "pointer",
      color: "#bebebe"
    },
    attrs: {
      type: "question-circle",
      theme: "filled"
    }
  })], 2)], 1), _c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      checked: _vm.lastLimit
    },
    model: {
      value: _vm.lastLimit,
      callback: function callback($$v) {
        _vm.lastLimit = $$v;
      },
      expression: "lastLimit"
    }
  }, [_vm._v(" 最后阶段限制 ")]), _c("a-popover", {
    attrs: {
      placement: "top"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("span", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("对离职的最后某阶段有特殊规则时，需在此配置时间")])]), _c("a-icon", {
    staticStyle: {
      cursor: "pointer",
      color: "#bebebe"
    },
    attrs: {
      type: "question-circle",
      theme: "filled"
    }
  })], 2), _vm.lastLimit ? _c("div", {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_vm._v(" 最后"), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 1
    },
    model: {
      value: _vm.EndAstrict.number,
      callback: function callback($$v) {
        _vm.$set(_vm.EndAstrict, "number", $$v);
      },
      expression: "EndAstrict.number"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "108px",
      margin: "0 5px"
    },
    on: {
      change: _vm.unitChange2
    },
    model: {
      value: _vm.EndAstrict.unit,
      callback: function callback($$v) {
        _vm.$set(_vm.EndAstrict, "unit", $$v);
      },
      expression: "EndAstrict.unit"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1",
      disabled: _vm.timeStart.unit === 3
    }
  }, [_vm._v(" 月 ")]), _c("a-select-option", {
    attrs: {
      value: "2",
      disabled: _vm.timeStart.unit === 3
    }
  }, [_vm._v(" 天 ")]), _c("a-select-option", {
    attrs: {
      value: "3",
      disabled: _vm.timeStart.unit === 2
    }
  }, [_vm._v(" 工时 ")])], 1)], 1) : _vm._e()], 1), _c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      checked: _vm.calculation2
    },
    model: {
      value: _vm.calculation2,
      callback: function callback($$v) {
        _vm.calculation2 = $$v;
      },
      expression: "calculation2"
    }
  }, [_vm._v(" 工价计算 ")])], 1), _vm.calculation2 ? _c("a-radio-group", {
    staticStyle: {
      "margin-top": "20px"
    },
    model: {
      value: _vm.ruleType2,
      callback: function callback($$v) {
        _vm.ruleType2 = $$v;
      },
      expression: "ruleType2"
    }
  }, [_c("a-radio-button", {
    staticStyle: {
      width: "104px",
      "text-align": "center"
    },
    attrs: {
      value: "2",
      disabled: _vm.timeStart.unit === 2 || _vm.EndAstrict.unit === "2"
    }
  }, [_vm._v(" 计时制 ")]), _c("a-radio-button", {
    staticStyle: {
      width: "104px",
      "text-align": "center"
    },
    attrs: {
      value: "1",
      disabled: _vm.timeStart.unit === 3 || _vm.EndAstrict.unit === "3"
    }
  }, [_vm._v(" 计天制 ")])], 1) : _vm._e(), _vm.calculation2 ? _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" 工价 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 0
    },
    model: {
      value: _vm.departureDetail,
      callback: function callback($$v) {
        _vm.departureDetail = $$v;
      },
      expression: "departureDetail"
    }
  }), _vm.ruleType2 === "1" ? _c("span", [_vm._v("元/天")]) : _vm._e(), _vm.ruleType2 === "2" ? _c("span", [_vm._v("元/工时")]) : _vm._e()], 1) : _vm._e(), _c("div", [_c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      checked: _vm.welfareChecked2
    },
    model: {
      value: _vm.welfareChecked2,
      callback: function callback($$v) {
        _vm.welfareChecked2 = $$v;
      },
      expression: "welfareChecked2"
    }
  }, [_vm._v(" 红包 ")])], 1), _vm.welfareChecked2 ? _c("div", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_vm._v(" 每满 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      formatter: _vm.limitNumber,
      parser: _vm.limitNumber,
      min: 0
    },
    model: {
      value: _vm.RedPacketRule2.number,
      callback: function callback($$v) {
        _vm.$set(_vm.RedPacketRule2, "number", $$v);
      },
      expression: "RedPacketRule2.number"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "108px",
      margin: "0 5px"
    },
    model: {
      value: _vm.RedPacketRule2.units,
      callback: function callback($$v) {
        _vm.$set(_vm.RedPacketRule2, "units", $$v);
      },
      expression: "RedPacketRule2.units"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1",
      disabled: _vm.timeStart.unit === 3 || _vm.EndAstrict.unit === "3"
    }
  }, [_vm._v(" 月 ")]), _c("a-select-option", {
    attrs: {
      value: "2",
      disabled: _vm.timeStart.unit === 3 || _vm.EndAstrict.unit === "3"
    }
  }, [_vm._v(" 天 ")]), _c("a-select-option", {
    attrs: {
      value: "3",
      disabled: _vm.timeStart.unit === 2 || _vm.EndAstrict.unit === "2"
    }
  }, [_vm._v(" 工时 ")])], 1), _vm._v(" 发放 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 1
    },
    model: {
      value: _vm.RedPacketRule2.redPacketAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.RedPacketRule2, "redPacketAmount", $$v);
      },
      expression: "RedPacketRule2.redPacketAmount"
    }
  }), _vm._v(" 元红包 ")], 1) : _vm._e()]), _c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      checked: _vm.checked1
    },
    on: {
      change: _vm.onChange1
    },
    model: {
      value: _vm.checked1,
      callback: function callback($$v) {
        _vm.checked1 = $$v;
      },
      expression: "checked1"
    }
  }, [_vm._v(" 离职倒扣 ")])], 1), _vm.checked1 ? _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("span", [_vm._v(" 倒扣 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 1
    },
    model: {
      value: _vm.specialCostDouble,
      callback: function callback($$v) {
        _vm.specialCostDouble = $$v;
      },
      expression: "specialCostDouble"
    }
  }), _vm._v(" 元工资 ")], 1)]) : _vm._e()], 1)], 1) : _vm._e(), _c("div", {
    style: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      borderTop: "1px solid #e8e8e8",
      padding: "10px 16px",
      textAlign: "center",
      left: 0,
      background: "#fff",
      borderRadius: "0 0 4px 4px",
      zIndex: "10"
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "20px"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v(" 确定 ")])], 1)]), _c("a-modal", {
    attrs: {
      title: "删除工价规则",
      visible: _vm.deleteVisible2
    },
    on: {
      ok: _vm.deleteOk2,
      cancel: function cancel($event) {
        _vm.deleteVisible2 = false;
      }
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "24px",
      color: "#6a66f6"
    },
    attrs: {
      type: "question-circle"
    }
  }), _c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#3c3d43",
      "line-height": "20px",
      "margin-left": "16px"
    }
  }, [_vm._v(" 您确定要删除该规则？一旦删除不可恢复 ")])], 1)]), _c("a-modal", {
    attrs: {
      title: "删除工价规则",
      visible: _vm.deleteVisible
    },
    on: {
      ok: _vm.deleteOk,
      cancel: function cancel($event) {
        _vm.deleteVisible = false;
      }
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "24px",
      color: "#6a66f6"
    },
    attrs: {
      type: "question-circle"
    }
  }), _c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#3c3d43",
      "line-height": "20px",
      "margin-left": "16px"
    }
  }, [_vm._v(" 您确定要删除该规则项吗？一旦删除不可恢复 ")])], 1)]), _c("a-modal", {
    attrs: {
      visible: _vm.issueModalVisible,
      title: "下发规则",
      width: "560px",
      "destroy-on-close": true
    },
    on: {
      ok: _vm.confirmIssueModal,
      cancel: _vm.handleCancelIssueModal
    }
  }, [_c("a-form-model", {
    staticClass: "antClassSelectHei",
    attrs: {
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "请选择规则"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      mode: "multiple",
      "show-search": "",
      placeholder: "请选择规则",
      "option-filter-prop": "children"
    },
    model: {
      value: _vm.contractList,
      callback: function callback($$v) {
        _vm.contractList = $$v;
      },
      expression: "contractList"
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "用工单位"
    }
  }, [_c("a-tree-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请选择用工单位",
      "tree-data": _vm.companyDataList,
      replaceFields: _vm.replaceFields,
      labelInValue: "",
      filterTreeNode: false,
      "tree-checkable": ""
    },
    on: {
      search: _vm.treeSearch
    },
    model: {
      value: _vm.companyList,
      callback: function callback($$v) {
        _vm.companyList = $$v;
      },
      expression: "companyList"
    }
  })], 1)], 1)], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };