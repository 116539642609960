import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from "vuex";
export default {
  data: function data() {
    return {
      // logo: require("@/assets/auth/auth_logo.png"),
      // backImg: require("@/assets/auth/auth_backimg.png")
      logo: "",
      backImg: "",
      showBeian: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["settings"])), {}, {
    systemBackground: function systemBackground() {
      return this.settings.systemBackground;
    },
    homeLogo: function homeLogo() {
      return this.settings.homeLogo;
    }
  }),
  watch: {
    systemBackground: {
      handler: function handler(newVal) {
        var _this = this;
        if (newVal) {
          this.$nextTick(function () {
            _this.backImg = newVal;
          });
        }
      },
      immediate: true
    },
    homeLogo: {
      handler: function handler(newVal) {
        var _this2 = this;
        if (newVal) {
          this.$nextTick(function () {
            _this2.logo = newVal;
          });
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.showBeian = location.host === "xingqitu.com";
  } // mounted() {
  //   sizeWidth();
  //   window.onresize = function () {
  //     sizeWidth();
  //   };
  //   function sizeWidth() {
  //     var screenWidth = document.querySelector("html").offsetWidth;
  //     var baseSz = 100;
  //     var pageWidth = 1920;
  //     var fZ = (baseSz * screenWidth) / pageWidth;
  //     document.querySelector("html").style.fontSize = fZ + "px";
  //   }
  // },
  // destroyed() {
  //   this.$nextTick(() => {
  //     document.querySelector("html").removeAttribute("style");
  //   });
  // }
};