import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "main-content-header"
  }, [_c("a-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "搜索印章名称",
      allowClear: true
    },
    on: {
      pressEnter: _vm.search
    },
    model: {
      value: _vm.query,
      callback: function callback($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffix",
      type: "search"
    },
    on: {
      click: _vm.search
    },
    slot: "suffix"
  })], 1), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_vm.isContainByString("contractAdmin") ? _c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showIssueModa
    }
  }, [_vm._v(" 下发印章 ")]) : _vm._e(), _vm.showSealEdit ? _c("a-button", {
    staticClass: "mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addStampCreate
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("上传印章 ")], 1) : _vm._e(), _vm.showSealEdit ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goStampCreate
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加印章 ")], 1) : _vm._e()], 1)], 1), _c("div", {
    staticClass: "main-content-main",
    staticStyle: {
      height: "78vh"
    }
  }, [_c("a-table", {
    staticClass: "seal-table",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.list,
      "row-key": "id",
      scroll: {
        y: _vm.editorHeight
      },
      loading: _vm.loading,
      pagination: _vm.pagination
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "sealString",
      fn: function fn(sealString) {
        return _c("img", {
          staticStyle: {
            width: "64px",
            height: "auto"
          },
          attrs: {
            src: sealString
          }
        });
      }
    }, {
      key: "legalPersonPicture",
      fn: function fn(legalPersonPicture) {
        return _c("img", {
          staticStyle: {
            width: "64px",
            height: "auto"
          },
          attrs: {
            src: legalPersonPicture
          }
        });
      }
    }, {
      key: "status",
      fn: function fn(status) {
        return _c("span", {
          staticStyle: {
            "text-align": "center"
          }
        }, [_vm._v(_vm._s(status === 1 ? "已认证" : "未认证"))]);
      }
    }, {
      key: "action",
      fn: function fn(record) {
        return _c("span", {
          staticClass: "action"
        }, [record.status !== 1 ? [record.content === "此印章是上传的" ? _c("span", {
          staticClass: "edit",
          on: {
            click: function click($event) {
              return _vm.editStamp(record);
            }
          }
        }, [_vm._v("编辑")]) : _c("span", {
          staticClass: "edit",
          on: {
            click: function click($event) {
              return _vm.goStampUpdate(record);
            }
          }
        }, [_vm._v("编辑")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("span", {
          staticClass: "edit",
          on: {
            click: function click($event) {
              return _vm.goStampVerify(record);
            }
          }
        }, [_vm._v("认证")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        })] : _vm._e(), _c("a-popconfirm", {
          attrs: {
            title: "确定要删除这个印章吗？",
            "ok-text": "确定",
            "cancel-text": "取消"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.deleteStamp(record);
            }
          }
        }, [_c("span", {
          staticClass: "edit"
        }, [_vm._v("删除")])])], 2);
      }
    }])
  })], 1), _c("a-modal", {
    attrs: {
      visible: _vm.issueModalVisible,
      title: "下发印章",
      width: "538px",
      "destroy-on-close": true
    },
    on: {
      ok: _vm.confirmIssueModal,
      cancel: _vm.handleCancelIssueModal
    }
  }, [_c("a-row", {
    staticStyle: {
      width: "500px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", [_vm._v("请选择印章")]), _c("a-select", {
    staticStyle: {
      width: "240px",
      "margin-top": "10px"
    },
    attrs: {
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      mode: "multiple",
      placeholder: "请选择印章"
    },
    model: {
      value: _vm.contractList,
      callback: function callback($$v) {
        _vm.contractList = $$v;
      },
      expression: "contractList"
    }
  }, _vm._l(_vm.contractData, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        disabled: item.status !== 1,
        value: item.id
      }
    }, [_c("a-tooltip", {
      attrs: {
        placement: "left",
        title: item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 1), _c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("（未认证印章不允许下发）")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", [_vm._v("请选择下发用工单位")]), _c("a-tree-select", {
    staticStyle: {
      width: "240px",
      "margin-top": "10px",
      "max-height": "350px",
      overflow: "auto"
    },
    attrs: {
      value: _vm.companyList,
      treeData: _vm.dataList,
      filterTreeNode: _vm.filterTreeNode,
      treeCheckable: "",
      placeholder: "请选择用工单位"
    },
    on: {
      change: _vm.companySelectChange
    }
  })], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "".concat(_vm.stateStamp === "add" ? "新建" : "编辑", "\u5370\u7AE0"),
      visible: _vm.visibleSeal,
      "confirm-loading": _vm.confirmLoadingSeal
    },
    on: {
      ok: _vm.handleOkSeal,
      cancel: _vm.handleCancelSeal
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "印章名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入印章名称!"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '请输入印章名称!' }] }]"
    }]
  })], 1), _c("a-form-item", {
    attrs: {
      label: "公司全称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["roundText", {
        rules: [{
          required: false,
          message: "请输入公司全称!"
        }]
      }],
      expression: "['roundText', { rules: [{ required: false, message: '请输入公司全称!' }] }]"
    }]
  })], 1), _c("a-form-item", {
    attrs: {
      label: "法人姓名"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["legalPerson", {
        rules: [{
          required: false,
          message: "请输入法人姓名!"
        }]
      }],
      expression: "['legalPerson', { rules: [{ required: false, message: '请输入法人姓名!' }] }]"
    }]
  })], 1), _c("a-form-item", {
    attrs: {
      label: "印章图片"
    }
  }, [_c("a-upload", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["sealString", {
        rules: [{
          required: true,
          message: "请选择印章图片!"
        }]
      }],
      expression: "['sealString', { rules: [{ required: true, message: '请选择印章图片!' }] }]"
    }],
    attrs: {
      name: "files",
      accept: ".jpg, .jpeg, .png, .bmp, .svg, .gif",
      "list-type": "picture-card",
      "file-list": _vm.fileList,
      action: "/file/api/v1/site/upload/inline"
    },
    on: {
      change: _vm.handleChange,
      preview: _vm.handlePreview
    }
  }, [_vm.fileList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _c("div", {
    staticClass: "ant-upload-text"
  }, [_vm._v("上传")])], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.previewImage,
      alt: "example"
    }
  })])], 1), _c("a-form-item", {
    attrs: {
      label: "法人章图片"
    }
  }, [_c("a-upload", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["legalPersonPicture", {
        rules: [{
          required: false,
          message: "请选择法人章图片!"
        }]
      }],
      expression: "['legalPersonPicture', { rules: [{ required: false, message: '请选择法人章图片!' }] }]"
    }],
    attrs: {
      name: "files",
      accept: ".jpg, .jpeg, .png, .bmp, .svg, .gif",
      "list-type": "picture-card",
      "file-list": _vm.fileListLP,
      action: "/file/api/v1/site/upload/inline"
    },
    on: {
      change: _vm.handleLPChange,
      preview: _vm.handlePreview
    }
  }, [_vm.fileListLP.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _c("div", {
    staticClass: "ant-upload-text"
  }, [_vm._v("上传")])], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.previewImage,
      alt: "example"
    }
  })])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };