import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
var commonRoutes = [{
  path: "overview",
  // 职位预览
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/overview"));
    });
  }
}, {
  path: "management",
  // 职位管理
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/management"));
    });
  }
}, {
  name: "managementAdd",
  path: "management/add",
  // 添加职位
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/management/add"));
    });
  }
}, {
  name: "managementEdit",
  path: "management/edit",
  // 编辑职位
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/management/add"));
    });
  }
}, {
  name: "managementMsg",
  path: "management/msg",
  // 详情职位
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/management/msg"));
    });
  }
}, {
  name: "resumeeliverySettings",
  path: "management/resumeeliverySettings",
  // 投递简历设置
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/management/component/resumeDeliverySettings"));
    });
  }
}, {
  name: "people",
  path: "people",
  // 求职者
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/people"));
    });
  }
}, {
  name: "entry",
  path: "entry",
  // 扫码录入
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/entry/index"));
    });
  }
}, {
  name: "peopleAdd",
  path: "people/add",
  // 添加候选人
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/people/add"));
    });
  }
}, {
  name: "talentEdit",
  path: "talent/edit",
  // 编辑候选人
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/people/add"));
    });
  }
}, {
  name: "economic",
  path: "economic",
  // 经纪人管理
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/economic"));
    });
  }
}, {
  name: "push",
  path: "push",
  // 内推管理
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/push"));
    });
  }
}, {
  path: "laborUnit",
  // 招工企业
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/laborUnit"));
    });
  }
}, {
  path: "talent",
  // 人才库
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/talent/list"));
    });
  }
}, {
  path: "talentZhineng",
  // 人才库
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/talent/list/indexZhineng"));
    });
  }
}, {
  name: "talentMsg",
  path: "talent/msg",
  // 人才库/员工详情
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/talent/msg"));
    });
  }
}, {
  name: "talentImports",
  path: "talent/imports",
  // 人才库/员工详情
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/talent/imports"));
    });
  }
}, {
  name: "talentAbnormal",
  path: "talent/abnormal",
  // 人才库/员工详情
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/recruitment/talent/abnormal"));
    });
  }
}];
export default {
  path: "/recruitment",
  // 人事管理
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/adminNew"));
    });
  },
  redirect: "/recruitment/overview",
  children: [].concat(commonRoutes)
};