import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_vm.type ? _c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm.type == "4" ? _c("span", [_vm._v("入职模板设置")]) : _vm._e(), _vm.type == "5" ? _c("span", [_vm._v("待入职模板设置")]) : _vm._e()]) : _vm._e(), !_vm.type ? _c("div", {
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_c("a-tabs", {
    on: {
      change: _vm.callback
    },
    model: {
      value: _vm.typeKey,
      callback: function callback($$v) {
        _vm.typeKey = $$v;
      },
      expression: "typeKey"
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "入职模板"
    }
  }), _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "待入职模板"
    }
  }), _c("a-tab-pane", {
    key: "3",
    attrs: {
      tab: "预登记模板"
    }
  })], 1)], 1) : _vm._e(), _c("div", {
    staticStyle: {
      padding: "24px 24px 12px 24px"
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 16
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请输入模板/编辑人名称"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c("a-col", {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      span: 8
    }
  }, [!_vm.type && _vm.typeKey != "3" ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.issueTemplate
    }
  }, [_vm._v("下发模板")]) : _vm._e(), _c("a-button", {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: _vm.newTemplate
    }
  }, [_vm._v("创建模板")])], 1)], 1)], 1), _c("div", [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticStyle: {
      "padding-right": "15px"
    }
  }, [_c("a-row", _vm._l(_vm.entities, function (i) {
    return _c("a-col", {
      key: i.id,
      attrs: {
        span: 6
      }
    }, [_c("div", {
      staticClass: "temBox"
    }, [_c("div", {
      staticStyle: {
        padding: "24px 14px 24px 24px"
      }
    }, [_c("a-row", {
      staticStyle: {
        "margin-bottom": "12px"
      }
    }, [_c("a-col", {
      attrs: {
        span: 20
      }
    }, [_c("span", {
      staticClass: "temTitle"
    }, [_vm._v(_vm._s(i.name))]), i.isDefault ? _c("span", {
      staticClass: "temTitleStatus"
    }, [_vm._v("默认")]) : _vm._e(), i.isIssue ? _c("span", {
      staticClass: "temTitleStatus temTitleStatus1"
    }, [_vm._v("下发")]) : _vm._e()]), _c("a-col", {
      staticClass: "temRig",
      attrs: {
        span: 4
      }
    }, [_c("span", {
      staticClass: "temRigSpanHove"
    }, [_c("span", {
      staticClass: "temRigSpan"
    }), _c("span", {
      staticClass: "temRigSpan"
    }), _c("span", {
      staticClass: "temRigSpan"
    }), _c("div", {
      staticClass: "temRigSpanBoxSHow"
    }, [_c("p", {
      staticClass: "temRigP",
      on: {
        click: function click($event) {
          return _vm.editTemplates(i);
        }
      }
    }, [_vm._v("编辑")]), !i.isDefault ? _c("p", {
      staticClass: "temRigP",
      on: {
        click: function click($event) {
          return _vm.customTemplatesDel(i.id);
        }
      }
    }, [_vm._v("删除")]) : _vm._e()])])])], 1), _c("div", {
      staticClass: "temEdit"
    }, [_c("span", {
      staticClass: "temEditWid"
    }, [_vm._v("编辑人:")]), _vm._v(" " + _vm._s(i.editBy) + " ")]), _c("div", {
      staticClass: "temEdit"
    }, [_c("span", {
      staticClass: "temEditWid"
    }, [_vm._v("编辑时间:")]), _vm._v(" " + _vm._s(_vm.moment(i.gmtModified).format("YYYY-MM-DD HH:mm:ss")) + " ")])], 1), _vm.type || _vm.typeKey === "3" ? _c("div", {
      staticStyle: {
        "border-top": "1px solid #e8e8e8"
      }
    }, [_c("div", {
      staticClass: "temBoxBottom"
    }, [_c("a-switch", {
      on: {
        change: function change(e) {
          return _vm.isOpenChange(e, i);
        }
      },
      model: {
        value: i.isOpen,
        callback: function callback($$v) {
          _vm.$set(i, "isOpen", $$v);
        },
        expression: "i.isOpen"
      }
    }, [_c("a-icon", {
      attrs: {
        slot: "checkedChildren",
        type: "check"
      },
      slot: "checkedChildren"
    }), _c("a-icon", {
      attrs: {
        slot: "unCheckedChildren",
        type: "close"
      },
      slot: "unCheckedChildren"
    })], 1), _c("span", {
      staticClass: "temBoxBottomShow"
    }, [_vm._v(_vm._s(i.isOpen ? "已启用" : "停用"))])], 1)]) : _vm._e()])]);
  }), 1)], 1), _c("div", {
    staticStyle: {
      position: "fixed",
      bottom: "35px",
      right: "25px"
    }
  }, [_c("a-pagination", {
    attrs: {
      defaultPageSize: 8,
      total: _vm.total,
      "show-total": function showTotal(total) {
        return "\u5171 ".concat(total, " \u6761");
      },
      "show-quick-jumper": ""
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.current,
      callback: function callback($$v) {
        _vm.current = $$v;
      },
      expression: "current"
    }
  })], 1)])], 1), _c("a-modal", {
    attrs: {
      title: "下发模板",
      visible: _vm.visible,
      destroyOnClose: true,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        _vm.visible = false;
      }
    }
  }, [_c("a-form", {
    staticClass: "antClassSelectHei",
    attrs: {
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      "label-col": _vm.formItemLayout.labelCol,
      "wrapper-col": _vm.formItemLayout.wrapperCol,
      label: "选择模板"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["templateId", {
        rules: [{
          required: true,
          message: "请选择模板"
        }]
      }],
      expression: "['templateId', { rules: [{ required: true, message: '请选择模板' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "option-filter-prop": "children",
      "show-search": "",
      "filter-option": _vm.filterOption,
      placeholder: "请选择模板"
    }
  }, _vm._l(_vm.templateEntities, function (i) {
    return _c("a-select-option", {
      key: i.id,
      attrs: {
        value: i.id
      }
    }, [_vm._v(_vm._s(i.name))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      "label-col": _vm.formItemLayout.labelCol,
      "wrapper-col": _vm.formItemLayout.wrapperCol,
      label: "用工单位"
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyList", {
        rules: [{
          required: true,
          message: "请选择用工单位"
        }]
      }],
      expression: "['companyList', { rules: [{ required: true, message: '请选择用工单位' }] }]"
    }],
    staticStyle: {
      width: "200px",
      "margin-top": "10px",
      "max-height": "350px"
    },
    attrs: {
      placeholder: "请选择用工单位",
      "tree-data": _vm.dataList,
      filterTreeNode: false,
      replaceFields: _vm.replaceFields2,
      labelInValue: "",
      "tree-checkable": ""
    },
    on: {
      search: _vm.treeSearch,
      change: _vm.treeSelectChange
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };