import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find-index.js";
import ListSearch from "./components/listSearch.vue";
import ListItem from "./components/listItem.vue";
import ExportBox from "./components/exportBox.vue";
import { treatmentDisposeEventsReq, delTreatmentDisposeEventsReq } from "./api";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {
    ListSearch: ListSearch,
    ListItem: ListItem,
    ExportBox: ExportBox
  },
  mixins: [],
  props: {
    eventTypeLst: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dispatchLst: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    templateLst: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    companyLst: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isFinish: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      exportVisible: false,
      templateIdImport: undefined,
      templateIdExport: undefined,
      templateNameExport: undefined,
      templateNameImport: undefined,
      modalImportVisible: false,
      modalExportVisible: false,
      takeOrdersDate: null,
      isPrecedence: false,
      templateId: undefined,
      takeOrdersUserId: undefined,
      companyId: undefined,
      modalVisible: false,
      dataLst: [],
      searchParams: {},
      pageNo: 1,
      pageSize: 8,
      total: 0
    };
  },
  computed: _objectSpread({}, mapGetters(["permissions_dycl"])),
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    onDeleteItem: function onDeleteItem(item) {
      var _this = this;
      delTreatmentDisposeEventsReq(item.id).then(function (res) {
        if (res && res.code === 200) {
          _this.$message.success("删除成功");
          _this.loadData();
        } else {
          _this.$message.error(res.msg);
        }
      }).catch(function (err) {
        _this.$message.error(err.message);
      });
    },
    exportOk: function exportOk() {
      if (this.templateIdExport) {
        this.modalExportVisible = false;
        this.exportVisible = true;
      } else {
        this.$message.error("请选择模板");
      }
    },
    exportItems: function exportItems() {
      this.templateIdExport = undefined;
      this.modalExportVisible = true;
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    addOk: function addOk() {
      if (this.takeOrdersDate && this.templateId && this.takeOrdersUserId && this.companyId) {
        localStorage.setItem("addDaiyuchuliItem", JSON.stringify({
          takeOrdersDate: this.takeOrdersDate.format("YYYY-MM-DD"),
          isPrecedence: this.isPrecedence,
          templateId: this.templateId,
          takeOrdersUserId: this.takeOrdersUserId,
          takeOrdersUserName: findName(this.takeOrdersUserId, this.dispatchLst),
          companyId: this.companyId,
          companyName: findName(this.companyId, this.companyLst)
        }));
        this.$router.push("/daiyuchuli/add");
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    onExportTemplateChange: function onExportTemplateChange(val, option) {
      this.templateNameExport = option.componentOptions.children[0].text;
    },
    onImportTemplateChange: function onImportTemplateChange(val, option) {
      this.templateNameImport = option.componentOptions.children[0].text;
    },
    importOk: function importOk() {
      if (this.templateIdImport) {
        localStorage.setItem("importDaiyuchuliItem", JSON.stringify({
          id: this.templateIdImport,
          name: this.templateNameImport
        }));
        this.$router.push("/daiyuchuli/upload");
      } else {
        this.$message.error("请选择模板");
      }
    },
    importItems: function importItems() {
      this.templateIdImport = undefined;
      this.modalImportVisible = true;
    },
    addItem: function addItem() {
      this.takeOrdersDate = moment();
      this.isPrecedence = false;
      this.templateId = undefined;
      this.takeOrdersUserId = undefined;
      this.companyId = undefined;
      this.modalVisible = true;
    },
    onPageChange: function onPageChange(p1) {
      this.pageNo = p1;
      this.loadData();
    },
    onRefresh: function onRefresh() {
      this.pageNo = 1;
      this.loadData();
    },
    onSearch: function onSearch(params) {
      this.searchParams = params;
      this.pageNo = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this2 = this;
      var params = _objectSpread({
        isFinish: this.isFinish,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }, this.searchParams || {});
      treatmentDisposeEventsReq(params).then(function (res) {
        if (res && res.data) {
          _this2.dataLst = res.data.entities;
          _this2.total = res.data.entityCount;
        }
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};
function findName(val, lst) {
  var idx = lst.findIndex(function (item) {
    return item.value === val;
  });
  if (idx > -1) {
    return lst[idx]["label"];
  }
  return null;
}