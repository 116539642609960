var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("a-row", {
    staticClass: "setHead",
    attrs: {
      type: "flex"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "16px",
      cursor: "pointer"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  }), _c("div", [_vm._v(_vm._s(_vm.componentTit[_vm.current]))]), _c("div")], 1), _c("div", {
    staticClass: "tabbox"
  }, [_c("div", {
    staticClass: "setting-stepbox"
  }, [_c("a-steps", {
    attrs: {
      current: _vm.current
    },
    on: {
      change: _vm.stepsChange
    }
  }, [_c("a-step", {
    attrs: {
      title: "签署设置"
    }
  }), _c("a-step", {
    attrs: {
      title: "合同模板"
    }
  })], 1)], 1)]), _vm.current == 0 ? _c("signed", {
    ref: "signed",
    on: {
      upData: _vm.upData
    }
  }) : _vm._e(), _vm.current == 1 ? _c("temp", {
    on: {
      upData: _vm.upData
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };