import "core-js/modules/es.array.concat.js";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
var mixin = new UploadMixin();
var columns = [{
  title: "行号",
  dataIndex: "id",
  key: "id"
}, {
  title: "姓名",
  dataIndex: "empName",
  key: "empName"
}, {
  title: "原因",
  dataIndex: "reason",
  key: "reason"
}];
import axios from "axios";
import qs from "qs";
import moment from "moment";
export default {
  mixins: [mixin],
  data: function data() {
    return {
      columns: columns,
      uploadImg: require("@/assets/img/uploadImg.png"),
      params: {
        templateName: "taxGrandTemplate.xlsx"
      },
      url: "/hr/api/v1/excel/template/download",
      excelName: "个税累计导入模板.xlsx"
    };
  },
  created: function created() {
    this.salaryTemplateId = this.$route.query.salaryTemplateId;
    this.month = moment().format("MM");
    this.year = moment().format("YYYY");
    var companyId = this.$store.state.hr.companyId;
    this.action = "/salary/api/v1/empTaxGrandTotals/importExcel?companyId=".concat(companyId, "&monthly=").concat(this.month, "&yearly=").concat(this.year, "&force=false");
  },
  methods: {
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    } // beforeUpload(file) {
    //   if (this.fileList.length > 0) {
    //     this.$message("最多只能上传一个");
    //     return false;
    //   }
    //   this.fileList = [...this.fileList, file];
    //   if (this.fileList && this.fileList.length > 0) {
    //     const { fileList } = this;
    //     const formData = new FormData();
    //     fileList.forEach(file => {
    //       formData.append("file", file);
    //     });
    //     this.uploading = true;
    //     axios({
    //       url: this.action,
    //       method: "get",
    //       data: formData,
    //       headers: this.headers
    //     }).then(res => {
    //       this.reset();
    //       this.handleSuccess(res.data);
    //       this.uploading = false;
    //     });
    //   } else {
    //     this.reset();
    //     this.handleUploadError();
    //   }
    //   return false;
    // }
  }
};