import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.concat.js";
// import moment from "moment";
// import { postSaveBrokerOrEmpPost, putRecruitInfos } from "../api";
export default {
  props: {
    val: {
      type: String,
      default: ""
    },
    empRuleIsOpen: {
      type: Boolean,
      default: true
    },
    empMoneyReward: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      // form: this.$form.createForm(this, { keys: [{ date: "", money: "" }] }),
      switchs: true,
      timeType: "1",
      grantType: "1",
      values: null,
      switchsDis: false
    };
  },
  beforeCreate: function beforeCreate() {
    this.form = this.$form.createForm(this, {});
    this.form.getFieldDecorator("keys", {
      initialValue: [{
        workingTime: "",
        timeType: "",
        grantCount: "",
        grantType: ""
      }],
      preserve: true
    });
  },
  created: function created() {
    var _this = this;
    if (this.val) {
      var arr = JSON.parse(this.val);
      this.timeType = arr[0].timeType;
      this.grantType = arr[0].grantType;
      this.form.getFieldDecorator("keys", {
        initialValue: arr,
        preserve: true
      });
      this.switchs = this.empRuleIsOpen;
      this.switchsDis = true;
      setTimeout(function () {
        _this.form.setFieldsValue({
          empMoneyReward: _this.empMoneyReward
        });
        arr.forEach(function (e, i) {
          var _this$form$setFieldsV;
          _this.form.setFieldsValue((_this$form$setFieldsV = {}, _defineProperty(_this$form$setFieldsV, "workingTime".concat(i), e.workingTime), _defineProperty(_this$form$setFieldsV, "grantCount".concat(i), e.grantCount), _this$form$setFieldsV));
        });
      });
    }
  },
  methods: {
    handleOk: function handleOk() {
      var _this2 = this;
      this.values = null;
      this.form.validateFields(function (err, values) {
        if (_this2.switchs) {
          if (!err) {
            _this2.setValue(values);
          }
        } else {
          _this2.setValue(values);
        }
      });
    },
    setValue: function setValue(values) {
      var _this3 = this;
      var num = 0;
      var s = true;
      values.keys.forEach(function (e, i) {
        e.timeType = _this3.timeType;
        e.grantType = _this3.grantType;
        e.workingTime = values["workingTime".concat(i)];
        e.grantCount = values["grantCount".concat(i)];
        num += parseFloat(e.grantCount);
      });
      if (this.switchs) {
        if (this.grantType === "1") {
          if (num > 100) {
            s = false;
            this.$message.error("百分比总和不能大于100%");
          }
        }
        if (this.grantType === "2") {
          if (num > values.empMoneyReward) {
            s = false;
            this.$message.error("金额总和不能大于赏金金额");
          }
        }
      }
      if (s) {
        values.empRuleIsOpen = this.switchs;
        this.values = values;
      }
    },
    remove: function remove(k) {
      if (!this.switchs) return;
      var form = this.form;
      var keys = form.getFieldValue("keys");
      if (keys.length === 1) {
        return;
      }
      setTimeout(function () {
        form.setFieldsValue({
          keys: keys.filter(function (key) {
            return key !== k;
          })
        });
      });
    },
    add: function add() {
      var form = this.form;
      var keys = form.getFieldValue("keys");
      var nextKeys = keys.concat([{
        workingTime: "",
        timeType: "",
        grantCount: "",
        grantType: ""
      }]);
      form.setFieldsValue({
        keys: nextKeys
      });
    }
  }
};