import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", [_c("a-spin", {
    attrs: {
      tip: "加载中..",
      spinning: _vm.spinning,
      delay: 300
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(_vm._s(_vm.pageTitle))]), _c("div", {
    staticStyle: {
      padding: "15px 150px"
    }
  }, [_c("p", {
    staticClass: "bleft"
  }, [_vm._v("公告标题")]), _c("p", [_vm._v("输入标题请不要超过35个字。")]), _c("a-input", {
    staticStyle: {
      width: "336px",
      "margin-right": "15px"
    },
    attrs: {
      "max-length": 35,
      placeholder: "请输入公告标题",
      "allow-clear": ""
    },
    model: {
      value: _vm.caeatForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.caeatForm, "title", $$v);
      },
      expression: "caeatForm.title"
    }
  }), _c("p", {
    staticClass: "bleft"
  }, [_vm._v("内容")]), _c("VueWangeditor", {
    staticClass: "wangeditor",
    staticStyle: {
      "z-index": "10",
      margin: "0"
    },
    attrs: {
      fileListVal: _vm.fileList
    },
    on: {
      change: _vm.VueWangeditor,
      saveFileList: _vm.saveFileList
    },
    model: {
      value: _vm.caeatForm.contentHasStyle,
      callback: function callback($$v) {
        _vm.$set(_vm.caeatForm, "contentHasStyle", $$v);
      },
      expression: "caeatForm.contentHasStyle"
    }
  }), _c("p", {
    staticClass: "bleft"
  }, [_vm._v("员工类型")]), _c("a-radio-group", {
    on: {
      change: _vm.groupChange
    },
    model: {
      value: _vm.caeatForm.formalType,
      callback: function callback($$v) {
        _vm.$set(_vm.caeatForm, "formalType", $$v);
      },
      expression: "caeatForm.formalType"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("正式")]), _c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("试用")])], 1), _c("p", {
    staticClass: "bleft"
  }, [_vm._v("发布范围")]), _c("a-radio-group", {
    on: {
      change: _vm.changePublishEmpRangeIsAll
    },
    model: {
      value: _vm.caeatForm.publishEmpRangeIsAll,
      callback: function callback($$v) {
        _vm.$set(_vm.caeatForm, "publishEmpRangeIsAll", $$v);
      },
      expression: "caeatForm.publishEmpRangeIsAll"
    }
  }, [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_vm._v("全公司")]), _c("a-radio", {
    attrs: {
      value: false
    }
  }, [_vm._v("指定范围")])], 1), !_vm.caeatForm.publishEmpRangeIsAll ? _c("a-button", {
    staticStyle: {
      "margin-right": "15px"
    },
    on: {
      click: _vm.selectMember
    }
  }, [_vm._v("选择人员")]) : _vm._e(), !_vm.caeatForm.publishEmpRangeIsAll ? _c("span", [_vm._v("已选择 " + _vm._s(_vm._f("SelectedString")(_vm.defaultSelectedKeys)) + "等 " + _vm._s(_vm.selectedList.length) + " 个人")]) : _vm._e(), _vm.fold && !_vm.caeatForm.publishEmpRangeIsAll ? _c("span", {
    staticClass: "fold",
    on: {
      click: function click($event) {
        _vm.fold = false;
      }
    }
  }, [_vm._v(" 收起 "), _c("a-icon", {
    attrs: {
      type: "arrow-up"
    }
  })], 1) : _vm._e(), !_vm.fold && !_vm.caeatForm.publishEmpRangeIsAll ? _c("span", {
    staticClass: "fold",
    on: {
      click: function click($event) {
        _vm.fold = true;
      }
    }
  }, [_vm._v(" 展开 "), _c("a-icon", {
    attrs: {
      type: "arrow-down"
    }
  })], 1) : _vm._e(), _vm.fold == true ? _c("a-table", {
    staticStyle: {
      "margin-top": "12px",
      width: "95%"
    },
    attrs: {
      columns: _vm.memberColumn,
      "data-source": _vm.selectedList,
      scroll: {
        y: 320
      },
      "row-key": "id"
    }
  }) : _vm._e(), _c("a-modal", {
    attrs: {
      title: "选择人员",
      width: 800,
      visible: _vm.staffVisible
    },
    on: {
      cancel: function cancel($event) {
        _vm.staffVisible = false;
      }
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.staffUp
    }
  }, [_vm._v("上一步")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.staffHandleOk
    }
  }, [_vm._v("确认")])], 1), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      "border-right": "1px solid #d8d8d8",
      flex: "1",
      padding: "0 15px"
    }
  }, [_c("a-input-search", {
    attrs: {
      placeholder: "搜索人员或手机号"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.staffFrom.nameOrPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.staffFrom, "nameOrPhone", $$v);
      },
      expression: "staffFrom.nameOrPhone"
    }
  }), _c("div", {
    staticStyle: {
      cursor: "pointer",
      margin: "5px"
    },
    on: {
      click: _vm.onCheckAll
    }
  }, [_vm._v(" 全选当页 "), _vm.checkAll ? _c("i", {
    staticClass: "iconfont icon-check-circle-fill primary_color",
    staticStyle: {
      "font-size": "20px"
    }
  }) : _c("img", {
    staticStyle: {
      width: "18px",
      height: "18px"
    },
    attrs: {
      src: require("@/assets/icons/未选择.png")
    }
  })]), _c("div", {
    staticStyle: {
      height: "420px",
      "overflow-y": "auto",
      padding: "0 15px"
    }
  }, _vm._l(_vm.staffData, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        cursor: "pointer",
        height: "40px",
        display: "flex",
        "justify-content": "space-between",
        "align-items": "center"
      },
      on: {
        click: function click($event) {
          return _vm.onRow(item);
        }
      }
    }, [_c("span", [_c("a-avatar", {
      staticStyle: {
        "margin-right": "5px"
      },
      attrs: {
        src: item.empPhoto,
        size: "small",
        icon: "user"
      }
    }), _vm._v(" " + _vm._s(item.name) + _vm._s(item.phone) + " ")], 1), item.isTrue ? _c("i", {
      staticClass: "iconfont icon-check-circle-fill primary_color",
      staticStyle: {
        "font-size": "20px"
      }
    }) : _c("img", {
      staticStyle: {
        width: "18px",
        height: "18px"
      },
      attrs: {
        src: require("@/assets/icons/未选择.png")
      }
    })]);
  }), 0), _c("a-pagination", {
    attrs: {
      size: "small",
      total: _vm.pageCount,
      current: _vm.staffFrom.pageNo,
      "show-size-changer": "",
      pageSizeOptions: ["10", "20", "50", "100"],
      "show-total": function showTotal(total) {
        return "\u5171 ".concat(_vm.pageCount, " \u6761");
      }
    },
    on: {
      change: _vm.onChangePagination,
      showSizeChange: _vm.showSizeChange
    }
  })], 1), _c("div", {
    staticStyle: {
      flex: "1",
      padding: "0 15px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "15px"
    }
  }, [_vm._v(" 已选择的人员 "), _c("span", {
    staticClass: "primary_color"
  }, [_vm._v(_vm._s(_vm.staffrightData.length) + "人")])]), _c("div", {
    staticStyle: {
      height: "420px",
      "overflow-y": "auto",
      padding: "0 15px"
    }
  }, _vm._l(_vm.staffrightData, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        cursor: "pointer",
        height: "40px",
        display: "flex",
        "justify-content": "space-between",
        "align-items": "center"
      }
    }, [_c("span", [_c("a-avatar", {
      staticStyle: {
        "margin-right": "5px"
      },
      attrs: {
        src: item.empPhoto,
        size: "small",
        icon: "user"
      }
    }), _vm._v(" " + _vm._s(item.name) + _vm._s(item.phone) + " ")], 1), _c("img", {
      staticStyle: {
        width: "18px",
        height: "18px"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(item, index);
        }
      }
    })]);
  }), 0)])])], 2), _c("a-modal", {
    attrs: {
      width: 800,
      title: "选择人员",
      visible: _vm.visible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "back",
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), !_vm.caeatForm.publishEmpTypeIsAll ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.xiayibu
    }
  }, [_vm._v("下一步")]) : _vm._e(), _vm.caeatForm.publishEmpTypeIsAll ? _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary",
      loading: _vm.confirmLoading
    },
    on: {
      click: _vm.handleOk
    }
  }, [_vm._v("确认")]) : _vm._e()], 1), !_vm.isJF ? [_c("p", {
    staticClass: "bleft"
  }, [_vm._v("员工性质")]), _c("a-checkbox-group", {
    attrs: {
      name: "checkboxgroup",
      options: _vm.$store.state.enums.postTypeLst
    },
    model: {
      value: _vm.caeatForm.publishEmpType,
      callback: function callback($$v) {
        _vm.$set(_vm.caeatForm, "publishEmpType", $$v);
      },
      expression: "caeatForm.publishEmpType"
    }
  })] : _vm._e(), _c("p", {
    staticClass: "bleft"
  }, [_vm._v("发布范围")]), _c("a-radio-group", {
    on: {
      change: _vm.publishEmpTypeIsAllChange
    },
    model: {
      value: _vm.caeatForm.publishEmpTypeIsAll,
      callback: function callback($$v) {
        _vm.$set(_vm.caeatForm, "publishEmpTypeIsAll", $$v);
      },
      expression: "caeatForm.publishEmpTypeIsAll"
    }
  }, [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_vm._v("全部人员")]), _c("a-radio", {
    attrs: {
      value: false
    }
  }, [_vm._v("部分人员")])], 1)], 2), _c("p", {
    staticClass: "bleft"
  }, [_vm._v("发布时间")]), _c("a-radio-group", {
    model: {
      value: _vm.caeatForm.isPublishNow,
      callback: function callback($$v) {
        _vm.$set(_vm.caeatForm, "isPublishNow", $$v);
      },
      expression: "caeatForm.isPublishNow"
    }
  }, [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_vm._v("立即发布")]), _c("a-radio", {
    attrs: {
      value: false
    }
  }, [_vm._v("定时发布")])], 1), _c("a-space", [!_vm.caeatForm.isPublishNow ? _c("a-date-picker", {
    attrs: {
      "show-time": "",
      placeholder: "发布时间"
    },
    on: {
      change: _vm.onChangeDate,
      ok: _vm.onOk
    },
    model: {
      value: _vm.caeatForm.publishDate,
      callback: function callback($$v) {
        _vm.$set(_vm.caeatForm, "publishDate", $$v);
      },
      expression: "caeatForm.publishDate"
    }
  }) : _vm._e()], 1), _c("p", {
    staticClass: "bleft"
  }, [_vm._v("推送设置")]), _c("a-checkbox-group", {
    attrs: {
      options: _vm.options
    },
    model: {
      value: _vm.caeatForm.noticeType,
      callback: function callback($$v) {
        _vm.$set(_vm.caeatForm, "noticeType", $$v);
      },
      expression: "caeatForm.noticeType"
    }
  })], 1), _c("a-divider"), _c("div", {
    staticStyle: {
      "text-align": "right",
      "margin-right": "20px",
      "margin-bottom": "15px"
    }
  }, [_c("a-space", [_vm.$route.query.sign === "0" ? _c("a-button", {
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("取消")]) : _vm._e(), _vm.$route.query.sign === "1" ? _c("a-button", {
    on: {
      click: _vm.onDet
    }
  }, [_vm._v("删除")]) : _vm._e(), _vm.$route.query.sign === "1" ? _c("a-button", {
    on: {
      click: _vm.offline
    }
  }, [_vm._v("下线")]) : _vm._e(), _vm.$route.query.sign === "1" ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保存")]) : _vm._e(), _vm.$route.query.sign === "0" ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.publish
    }
  }, [_vm._v("确认发布")]) : _vm._e()], 1)], 1), _c("a-modal", {
    attrs: {
      title: "预览",
      visible: _vm.previewVisible
    },
    on: {
      ok: function ok() {
        return _vm.previewVisible = false;
      },
      cancel: function cancel() {
        return _vm.previewVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "notice-editor-wrapper"
  }, [_c("div", {
    staticClass: "ql-editor-content"
  }, [_c("div", {
    staticClass: "preview-box",
    style: _vm.bgImage
  }, [_c("div", {
    staticClass: "notice-content"
  }, [_c("div", {
    staticClass: "notice-company-title"
  }, [_vm._v(_vm._s(_vm.caeatForm.title))]), _c("div", {
    staticClass: "notice-company-date"
  }, [_c("span", [_vm._v(_vm._s(_vm.caeatForm.title))]), _c("span", [_vm._v(_vm._s(_vm.caeatForm.title.publishDate))])]), _c("div", {
    staticClass: "notice-company-content",
    domProps: {
      innerHTML: _vm._s(_vm.caeatForm.contentHasStyle)
    }
  })])])])])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };