import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { getToken } from "xqjr-module-auth";
import { updateEmpAllInfo, getEmpInfoDetail } from "@/api/staff/staff";
import { UploadSiteFile } from "xqjr-plugin-common";
export default {
  components: {
    UploadSiteFile: UploadSiteFile
  },
  data: function data() {
    return {
      edit: null,
      id: "",
      name: "",
      fileType: 1,
      allList: [],
      itemType: "",
      currIndex: 0,
      currData: {},
      url: "",
      employInfoId: "",
      companyId: "",
      phone: ""
    };
  },
  created: function created() {
    var _this = this;
    if (this.$route.query.edit !== undefined) {
      // 从员工概况进来的tag不为null
      this.edit = this.$route.query.edit;
    }
    this.employInfoId = this.$route.query.employInfoId;
    this.companyId = this.$route.query.companyId;
    var data = {
      id: this.employInfoId,
      companyId: this.companyId
    };
    this.id = this.$route.query.id;
    this.fileType = parseInt(this.$route.query.fileType);
    getEmpInfoDetail(data).then(function (res) {
      _this.name = res.data.name;
      _this.phone = res.data.phone;
      if (_this.fileType === 1) {
        _this.allList = JSON.parse(JSON.stringify(res.data.companyAdditionalFieldsListAttachmentVOList));
      } else if (_this.fileType === 2) {
        _this.allList = JSON.parse(JSON.stringify(res.data.companyAdditionalFieldsListArchiveVOList));
      } else {
        _this.allList = JSON.parse(JSON.stringify(res.data.companyAdditionalFieldsListLeaveOfficeVOList));
      }
      _this.allList.forEach(function (item, index) {
        if (parseInt(item.id) === parseInt(_this.id)) {
          _this.currIndex = index;
          _this.itemType = item.fieldName;
          _this.currData = JSON.parse(JSON.stringify(item));
          _this.url = _this.currData.empAdditionalFieldsVO ? _this.currData.empAdditionalFieldsVO.content : "";
        }
      });
    });
  },
  methods: {
    downloadImg: function downloadImg() {
      this.$refs.uploadTool.download();
    },
    onFileChange: function onFileChange(urls) {
      var _this2 = this;
      var data = {};
      var empData = {
        content: urls,
        companyId: this.companyId,
        empId: this.employInfoId,
        fieldId: this.currData.id,
        moduleCode: "archive"
      };
      if (this.currData.empAdditionalFieldsVO) {
        empData.id = this.currData.empAdditionalFieldsVO.id;
      }
      this.currData.empAdditionalFieldsVO = empData;
      this.allList[this.currIndex] = this.currData;
      if (this.fileType === 1) {
        data.companyAdditionalFieldsListAttachmentVOList = JSON.parse(JSON.stringify(this.allList));
      } else if (this.fileType === 2) {
        data.companyAdditionalFieldsListArchiveVOList = JSON.parse(JSON.stringify(this.allList));
      } else {
        data.companyAdditionalFieldsListLeaveOfficeVOList = JSON.parse(JSON.stringify(this.allList));
      }
      data.id = this.employInfoId;
      data.phone = this.phone;
      updateEmpAllInfo(data).then(function (res) {
        _this2.$notification["success"]({
          message: "操作成功"
        });
        _this2.$emit("cancelEdit");
      });
    },
    goback: function goback() {
      this.$router.go(-1);
    },
    pageGetToken: function pageGetToken() {
      return getToken();
    }
  }
};