import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "r-overview-box",
    style: {
      height: _vm.heights + "px"
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    staticClass: "r-overview-box-tab",
    attrs: {
      span: 24
    }
  }, [_c("rTitle", {
    attrs: {
      tit: "招聘数据概览"
    }
  }, [_c("div", {
    attrs: {
      slot: "left"
    },
    slot: "left"
  }, [_vm._v(" 数据周期：2021.10-2021.05.16 ")]), _c("div", {
    attrs: {
      slot: "right"
    },
    slot: "right"
  }, [_c("a-select", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "default-value": "lucy"
    },
    on: {
      change: _vm.handleChange
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "lucy"
    }
  }, [_vm._v(" 周 ")])], 1)], 1)]), _c("div", {
    staticClass: "num"
  }, _vm._l(_vm.numArr, function (item) {
    return _c("div", {
      key: item.type,
      staticClass: "num-box"
    }, [_c("div", {
      staticClass: "num-top"
    }, [_vm._v(_vm._s(item.num))]), _c("div", {
      staticClass: "num-bottom"
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0)], 1)], 1), _c("a-row", {
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "r-overview-box-tab mr7"
  }, [_c("rTitle", {
    attrs: {
      tit: "我的面试"
    }
  }), _c("a-tabs", {
    attrs: {
      "default-active-key": "1"
    },
    on: {
      change: _vm.callback
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "未开始"
    }
  }, [_c("interview")], 1), _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "待评价"
    }
  }, [_c("interview")], 1)], 1), _c("div", {
    staticClass: "tab-btn"
  }, [_c("a-button", {
    staticStyle: {
      "font-size": "16px"
    },
    attrs: {
      type: "link"
    }
  }, [_vm._v(" 查看全部 ")])], 1)], 1)]), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "r-overview-box-tab ml7"
  }, [_c("rTitle", {
    attrs: {
      tit: "新投简历"
    }
  }), _c("a-tabs", {
    attrs: {
      "default-active-key": "1"
    },
    on: {
      change: _vm.callback
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "全部"
    }
  }, [_c("resume")], 1), _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "自主投递"
    }
  }, [_c("resume")], 1), _c("a-tab-pane", {
    key: "3",
    attrs: {
      tab: "内推"
    }
  }, [_c("resume")], 1), _c("a-tab-pane", {
    key: "4",
    attrs: {
      tab: "渠道"
    }
  }, [_c("resume")], 1)], 1), _c("div", {
    staticClass: "tab-btn"
  }, [_c("a-button", {
    staticStyle: {
      "font-size": "16px"
    },
    attrs: {
      type: "link"
    }
  }, [_vm._v(" 查看全部 ")])], 1)], 1)])], 1), _c("a-row", {
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    staticStyle: {
      "margin-top": "-96px"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "r-overview-box-tab mr7"
  }, [_c("rTitle", {
    attrs: {
      tit: "渠道数据排行榜"
    }
  }), _c("tables")], 1)]), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "r-overview-box-tab ml7"
  }, [_c("rTitle", {
    attrs: {
      tit: "浏览记录"
    }
  }), _c("tables")], 1)])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };