import moment from "moment";
import { putDeliverRecords } from "../api";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: function visible(v) {
      if (v) {
        this.select = "1";
      }
    }
  },
  data: function data() {
    return {
      select: "1",
      confirmLoading: false
    };
  },
  methods: {
    moment: moment,
    handleOk: function handleOk() {
      this.$emit("upDate", this.select);
      this.$emit("update:visible", false);
    }
  }
};