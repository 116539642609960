import "core-js/modules/es.array.map.js";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    target: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      modalVisible: false,
      planLst: [],
      itemLst: [],
      schemeId: undefined,
      dateRange: null,
      insCompanyId: undefined,
      monthAmount: "",
      careerTypeId: undefined,
      careerType: ""
    };
  },
  computed: {},
  watch: {
    value: {
      handler: function handler(newVal) {
        this.modalVisible = newVal;
      },
      immediate: true
    },
    modalVisible: function modalVisible() {
      this.$emit("input", this.modalVisible);
      this.$emit("change", this.modalVisible);
      if (this.modalVisible) {
        this.clear();
        if (this.target && this.target.id) {
          this.schemeId = this.target.schemeId;
          this.insCompanyId = this.target.insCompanyId;
          this.monthAmount = this.target.monthAmount;
          this.careerType = this.target.careerType;
          this.dateRange = [this.$moment(this.target.beginTime), this.$moment(this.target.endTime)];
        }
        this.loadPlanLst();
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onCareerTypeChange: function onCareerTypeChange(value, node) {
      this.monthAmount = node.data.props.monthAmount;
      this.careerType = node.data.props.careerType;
    },
    onSchemeIdChange: function onSchemeIdChange(value, node) {
      this.insCompanyId = node.data.props.cId;
      this.itemLst = node.data.props.itemList;
      this.careerTypeId = undefined;
      this.monthAmount = "";
      this.careerType = "";
    },
    loadPlanLst: function loadPlanLst() {
      var _this = this;
      this.$request({
        url: "/insurance/api/v1/qyCompanySchemes/selectScheme"
      }).then(function (res) {
        _this.planLst = res.data.map(function (item) {
          var pId = item.id;
          var tempLst = {
            value: pId,
            label: item.schemeName,
            cId: item.insCompanyId,
            itemList: item.itemList.map(function (li) {
              if (_this.target && _this.target.id && _this.schemeId === pId && _this.careerType === li.careerType) {
                _this.careerTypeId = li.id;
              }
              return {
                value: li.id,
                label: li.careerType,
                careerType: li.careerType,
                monthAmount: li.monthAmount
              };
            })
          };
          if (_this.target && _this.target.id && _this.schemeId === pId) {
            _this.itemLst = tempLst.itemList;
          }
          return tempLst;
        });
      }).catch(function (err) {
        console.log("qyCompanySchemes", err);
      });
    },
    clear: function clear() {
      this.itemLst = [];
      this.schemeId = undefined;
      this.dateRange = null;
      this.insCompanyId = undefined;
      this.monthAmount = "";
      this.careerTypeId = undefined;
      this.careerType = "";
    },
    doConfirm: function doConfirm() {
      if (this.dateRange && this.dateRange.length > 0 && this.insCompanyId && this.schemeId && (this.monthAmount || this.monthAmount === 0 || this.monthAmount === "0") && this.careerType) {
        var params = {};
        var dates = this.dateRange.map(function (item) {
          return item.format("YYYY-MM-DD");
        });
        params["beginTime"] = dates[0];
        params["endTime"] = dates[1];
        params["insCompanyId"] = this.insCompanyId;
        params["schemeId"] = this.schemeId;
        params["monthAmount"] = this.monthAmount;
        params["careerType"] = this.careerType;
        this.$emit("confirm", params);
        this.modalVisible = false;
      } else {
        console.log(this.dateRange);
        console.log(this.insCompanyId);
        console.log(this.schemeId);
        console.log(this.monthAmount);
        console.log(this.careerType);
        this.$message.warn("有内容未填写");
        return false;
      }
    },
    doCancel: function doCancel() {
      this.modalVisible = false;
    },
    onRangeChange: function onRangeChange(value) {
      if (value && value.length === 0) {
        this.dateRange = null;
      } else {
        this.dateRange = value;
      }
    },
    onPanelChange: function onPanelChange(value) {
      this.dateRange = value;
    }
  }
};