import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import { addContract, getContract, editContract } from "./api";
export default {
  data: function data() {
    return {
      prompt: "",
      form: {
        name: "",
        apiHost: "",
        apiUrl: "",
        cost: "",
        projectId: "",
        secret: ""
      },
      rules: {
        name: [{
          required: true,
          message: "必填"
        }],
        apiHost: [{
          required: true,
          message: "必填"
        }],
        apiUrl: [{
          required: true,
          message: "必填"
        }],
        cost: [{
          required: true,
          message: "必填"
        }],
        projectId: [{
          required: true,
          message: "必填"
        }],
        secret: [{
          required: true,
          message: "必填"
        }]
      },
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 8
      },
      isEdit: false,
      editId: ""
    };
  },
  created: function created() {
    this.isEdit = this.$route.query.isEdit;
    if (this.isEdit) {
      this.init();
    }
  },
  beforeDestory: function beforeDestory() {},
  methods: {
    handleCancel: function handleCancel() {
      this.$router.push("/thirdService");
    },
    init: function init() {
      var _this = this;
      getContract().then(function (res) {
        _this.form.name = res.data.name;
        _this.form.apiHost = res.data.apiHost;
        _this.form.apiUrl = res.data.apiUrl;
        _this.form.cost = res.data.cost;
        _this.form.projectId = res.data.projectId;
        _this.form.secret = res.data.secret;
        _this.editId = res.data.id;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = _objectSpread({}, _this2.form);
          if (_this2.isEdit) {
            data.id = _this2.editId;
            editContract(data).then(function (res) {
              _this2.$router.push("/thirdService");
              _this2.$message("保存成功");
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          } else {
            addContract(data).then(function (res) {
              _this2.$router.push("/thirdService");
              _this2.$message("保存成功");
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          }
        }
      });
    }
  }
};