var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("canvas", {
    attrs: {
      id: "canvas",
      width: "250",
      height: "250"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };