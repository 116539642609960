import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wrapper"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("a-space", [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "自定义模板名称"
    },
    on: {
      search: _vm.loadTemps
    },
    model: {
      value: _vm.keywordT,
      callback: function callback($$v) {
        _vm.keywordT = $$v;
      },
      expression: "keywordT"
    }
  }), _c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "编辑人名称"
    },
    on: {
      search: _vm.loadTemps
    },
    model: {
      value: _vm.keywordU,
      callback: function callback($$v) {
        _vm.keywordU = $$v;
      },
      expression: "keywordU"
    }
  })], 1), _vm.permissions_dycl.includes("dycl:dycl.dyclTemplate:add") ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goAdd
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 新增模板 ")], 1) : _vm._e()], 1), _c("div", {
    staticClass: "title"
  }, [_vm._v("默认模板")]), _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, _vm._l(_vm.defaultLst, function (li) {
    return _c("a-col", {
      key: li.id,
      attrs: {
        span: 6
      }
    }, [_c("TempItem", {
      attrs: {
        dataSource: li,
        isDefault: true
      },
      on: {
        reload: _vm.loadTemps
      }
    })], 1);
  }), 1), _c("div", {
    staticClass: "title"
  }, [_vm._v("自定义模板")]), _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, _vm._l(_vm.customLst, function (li) {
    return _c("a-col", {
      key: li.id,
      attrs: {
        span: 6
      }
    }, [_c("TempItem", {
      attrs: {
        dataSource: li
      },
      on: {
        reload: _vm.loadTemps
      }
    })], 1);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };