import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  path: "/auth",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("xqjr-module-auth/layout/index.vue"));
    });
  },
  children: [{
    path: "/",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/layout/auth"));
      });
    },
    children: [{
      path: "login",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-auth/views/login.vue"));
        });
      }
    }, {
      path: "forget",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-auth/views/forget.vue"));
        });
      }
    }]
  }, {
    path: "config",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-auth/views/config/config.vue"));
      });
    }
  }, {
    path: "for-phone",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-auth/views/config/for-phone.vue"));
      });
    }
  }, {
    path: "for-password",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-auth/views/config/for-password.vue"));
      });
    }
  }]
};