import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
export default {
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    active: {
      type: String,
      default: "1"
    }
  },
  methods: {
    onTab: function onTab(index, item) {
      // this.active = index + 1;
      var active = (index + 1).toString();
      this.$emit("update:active", active);
    }
  }
};