import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "box page-miH page-bgBack p24"
  }, [_c("a-layout", [_vm.groupType === "company" ? _c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(" " + _vm._s("【" + _vm.detailList.name + "】" + _vm.detailList.personalPeriod) + " " + _vm._s(_vm.formType === "customer" ? "客户" : _vm.formType === "salary" ? "薪资" : _vm.formType === "social" ? "社保" : "") + "明细表 ")]) : _c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(_vm._s("【" + _vm.detailList.name + "】" + _vm.detailList.personalPeriod) + "自定义分组")]), _c("div", {
    staticClass: "flex-center br8 border mt24 p24"
  }, [_c("div", {
    staticClass: "border-right pr24 flex-shrink-0 mr24",
    staticStyle: {
      height: "40px"
    }
  }, [_c("div", {
    staticClass: "fs18 fw500 tc-l1"
  }, [_vm._v("合计")])]), _c("div", {
    staticClass: "flex-center overflow-x-auto flex-1"
  }, [_c("div", {
    key: "本月人数",
    staticClass: "flex-shrink-0 ph12",
    staticStyle: {
      height: "60px"
    }
  }, [_vm.groupType === "company" ? _c("div", {
    staticClass: "fs14 tc-l3 fh20"
  }, [_vm._v(" " + _vm._s(_vm.formType === "customer" ? "本月人数" : _vm.formType === "salary" ? "本月计薪人数" : "本月参保人数") + " ")]) : _c("div", {
    staticClass: "fs14 tc-l3 fh20"
  }, [_vm._v("本月人数")]), _c("div", {
    staticClass: "fs18 fw500 tc-l1 fh32 mt8"
  }, [_vm._v(_vm._s(_vm.detailList.empNumber ? _vm.detailList.empNumber : 0) + "人")])]), this.detailList && this.detailList.detailsAmountVOList && this.detailList.detailsAmountVOList.length > 0 ? _vm._l(this.detailList.detailsAmountVOList, function (item) {
    return _c("div", {
      key: item.name,
      staticClass: "flex-shrink-0 ph12",
      staticStyle: {
        height: "60px"
      }
    }, [_c("div", {
      staticClass: "fs14 tc-l3 fh20"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "fs18 fw500 tc-l1 fh32 mt8"
    }, [_vm._v("¥" + _vm._s(item.amount || "--"))])]);
  }) : _vm._e()], 2)]), _c("div", {
    staticClass: "flex-center-between mt24"
  }, [_c("div", [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "姓名/手机号/身份证号"
    },
    on: {
      search: _vm.inputChange
    }
  }), _c("a-select", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: "",
      placeholder: "员工状态"
    },
    on: {
      change: _vm.handleChange2
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 在职 ")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 待离职 ")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v(" 离职 ")])], 1)], 1), _c("div", [_c("a-button", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    },
    attrs: {
      type: "link",
      size: "small"
    },
    on: {
      click: _vm.reload
    }
  }, [_vm._v("刷新"), _c("a-icon", {
    attrs: {
      type: "reload"
    }
  })], 1), _c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }), _c("a-popover", {
    attrs: {
      title: "",
      trigger: "click",
      placement: "bottom"
    },
    on: {
      visibleChange: _vm.handleHeadChange
    },
    model: {
      value: _vm.headVisible,
      callback: function callback($$v) {
        _vm.headVisible = $$v;
      },
      expression: "headVisible"
    }
  }, [_c("div", {
    staticStyle: {
      width: "600px",
      height: "320px"
    },
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", [_c("div", [_vm._v("已选择表头")]), _c("div", {
    staticClass: "overview-tag-wrapper"
  }, [_c("draggable", {
    attrs: {
      filter: ".forbid",
      animation: "3000",
      move: _vm.onMove
    },
    on: {
      start: _vm.onStart,
      end: _vm.onEnd
    },
    model: {
      value: _vm.tempSelectedHead,
      callback: function callback($$v) {
        _vm.tempSelectedHead = $$v;
      },
      expression: "tempSelectedHead"
    }
  }, [_c("transition-group", _vm._l(_vm.tempSelectedHead, function (item, index) {
    return _c("a-tag", {
      key: index,
      class: item.title !== "姓名" && item.title !== "身份证号" ? "overview-select-tag" : "overview-select-tag-name forbid",
      attrs: {
        closable: item.title !== "姓名" && item.title !== "身份证号",
        color: item.title !== "姓名" && item.title !== "身份证号" ? "#fff" : "#E8E8E8"
      },
      on: {
        close: function close($event) {
          return _vm.deleteSelectedHead($event, index);
        }
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)], 1)], 1)]), _c("div", [_c("div", [_vm._v("可选择表头")]), _c("div", _vm._l(_vm.tempNoSelectedHead, function (item, index) {
    return _c("a-tag", {
      key: index,
      staticClass: "overview-no-select-tag",
      on: {
        click: function click($event) {
          return _vm.addSelectedHead(index);
        }
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)]), _c("a-row", {
    staticStyle: {
      position: "absolute",
      bottom: "20px",
      right: "20px"
    },
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: _vm.handleHeadCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleHeadSearch
    }
  }, [_vm._v("查询")])], 1)], 1), _c("a-button", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    },
    attrs: {
      type: "link",
      size: "small"
    }
  }, [_vm._v("选择表头"), _c("a-icon", {
    attrs: {
      type: "menu"
    }
  })], 1)], 1), _c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }), _c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.download
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "8",
    attrs: {
      disabled: !_vm.dataList.length
    }
  }, [_vm._v("全部导出")])], 1), _c("a-button", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    },
    attrs: {
      type: "link",
      size: "small"
    }
  }, [_vm._v("导出"), _c("a-icon", {
    attrs: {
      type: "caret-down"
    }
  })], 1)], 1)], 1)]), _c("div", {
    staticClass: "mt24"
  }, [_c("a-table", {
    attrs: {
      loading: _vm.loading,
      columns: _vm.columns,
      "data-source": _vm.dataList,
      pagination: _vm.pagination,
      rowKey: "id",
      scroll: {
        x: 1200
      }
    },
    on: {
      change: _vm.handleTableChange
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };