import "core-js/modules/es.array.concat.js";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
var mixin = UploadMixin();
var columns = [{
  title: "行号",
  dataIndex: "id",
  key: "id"
}, {
  title: "姓名",
  dataIndex: "name",
  key: "name"
}, {
  title: "原因",
  dataIndex: "failureReason",
  key: "failureReason"
}];
export default {
  mixins: [mixin],
  data: function data() {
    return {
      columns: columns,
      params: {
        templateName: "cumulativeDeductionOfSpecialAdditionalItems.xlsx"
      },
      url: "/salary/api/v1/excel/template/download",
      excelName: "专项附加扣除累计导入模板.xlsx",
      uploadImg: require("@/assets/img/uploadImg.png")
    };
  },
  computed: {
    month: function month() {
      return this.$route.query.month;
    },
    year: function year() {
      return this.$route.query.year;
    },
    companyId: function companyId() {
      return this.$store.state.hr.companyId;
    }
  },
  created: function created() {
    this.action = "/salary/api/v1/employeeSalaries/importSpecialAdditionalItems?companyId=".concat(this.companyId, "&force=false&month=").concat(this.month, "&year=").concat(this.year);
  },
  methods: {
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    }
  }
};