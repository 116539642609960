import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: _vm.mode === "select" ? "选择人员" : "适用范围-粘贴选择",
      width: _vm.mode === "select" ? "1200px" : "800px"
    },
    model: {
      value: _vm.modalVisible,
      callback: function callback($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_vm.mode === "select" ? [_c("div", {
    staticStyle: {
      width: "290px"
    }
  }, [_c("div", {
    staticClass: "flex-center"
  }, [_c("span", [_vm._v("筛选条件：")]), _c("a-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "员工姓名/身份证号/手机号"
    },
    model: {
      value: _vm.nameOrPhoneOrIdCard,
      callback: function callback($$v) {
        _vm.nameOrPhoneOrIdCard = $$v;
      },
      expression: "nameOrPhoneOrIdCard"
    }
  })], 1), _c("div", {
    staticClass: "flex-center mt16"
  }, [_c("span", [_vm._v("用工单位：")]), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      options: _vm.companyLst,
      placeholder: "请选择",
      filterOption: _vm.filterOption,
      "show-search": ""
    },
    model: {
      value: _vm.companyId,
      callback: function callback($$v) {
        _vm.companyId = $$v;
      },
      expression: "companyId"
    }
  })], 1)]), _c("div", {
    staticStyle: {
      width: "630px"
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      dataSource: _vm.currentEmpLst,
      loading: _vm.loading,
      rowKey: "id",
      size: "small",
      pagination: _vm.pagination,
      rowSelection: {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    }
  })], 1)] : _c("div", {
    staticStyle: {
      width: "520px"
    }
  }, [_c("div", {
    staticClass: "pv8 ph12 mb16",
    staticStyle: {
      background: "#f7f7f7"
    }
  }, [_c("div", {
    staticClass: "fs16 tc-l1"
  }, [_vm._v("温馨提示")]), _c("div", {
    staticClass: "tc-l3 mt12"
  }, [_vm._v("1.在文本框内输入或批量粘贴企业在职员工身份证号，便可识别添加。")]), _c("div", {
    staticClass: "tc-l3 mt4"
  }, [_vm._v("2.非企业员工无法识别。")]), _c("div", {
    staticClass: "tc-l3 mt4"
  }, [_vm._v("3.支持以换行、回车、空格等符号隔开的身份证号识别。")])]), _c("a-textarea", {
    staticStyle: {
      resize: "none"
    },
    attrs: {
      placeholder: "请输入或粘贴员工身份证号",
      rows: 10,
      autoSize: false
    },
    model: {
      value: _vm.idCards,
      callback: function callback($$v) {
        _vm.idCards = $$v;
      },
      expression: "idCards"
    }
  }), _c("div", {
    staticClass: "ta-right mt12"
  }, [_c("a-space", {
    attrs: {
      size: 16
    }
  }, [_c("a-button", {
    on: {
      click: _vm.doReset
    }
  }, [_vm._v("重置")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.doDistinguish
    }
  }, [_vm._v("识别")])], 1)], 1)], 1), _c("div", {
    staticClass: "ml12 pl12 border-left"
  }, [_c("div", {
    staticClass: "fs12 mb12"
  }, [_vm._v(" 已选择人员"), _c("span", {
    staticClass: "tc-theme"
  }, [_vm._v(" " + _vm._s(_vm.selectedEmpLst.length) + " ")]), _vm._v("人 ")]), _c("div", {
    staticClass: "overflow-y-auto",
    staticStyle: {
      "max-height": "450px"
    }
  }, _vm._l(_vm.selectedEmpLst, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "flex-center-between mb8"
    }, [_c("div", {
      staticClass: "ellipsis",
      staticStyle: {
        width: "180px"
      }
    }, [_c("a-tooltip", {
      attrs: {
        title: "".concat(item.name, " ").concat(item.phone, " ").concat(item.idCard)
      }
    }, [_vm._v(_vm._s(item.name) + " " + _vm._s(item.phone))])], 1), _c("a-icon", {
      staticClass: "fs16 pointer ml12",
      staticStyle: {
        color: "rgba(215, 215, 215, 1)",
        "flex-shrink": "0"
      },
      attrs: {
        type: "close-circle",
        theme: "filled"
      },
      on: {
        click: function click($event) {
          return _vm.delFromSelectedLst(item.id);
        }
      }
    })], 1);
  }), 0)])], 2), _c("div", {
    staticClass: "flex-center-between",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.mode === "select" ? _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        _vm.mode = "input";
      }
    }
  }, [_vm._v("粘贴选择")]) : _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        _vm.mode = "select";
      }
    }
  }, [_vm._v("条件选择")]), _c("a-space", {
    attrs: {
      size: 16
    }
  }, [_c("a-button", {
    on: {
      click: _vm.doCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.doConfirm
    }
  }, [_vm._v("确定")])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };