var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", _vm._l(_vm.list, function (item) {
    return _c("div", {
      key: item.type,
      staticClass: "i-box"
    }, [_vm._m(0, true), _c("div", {
      staticClass: "phone"
    }, [_vm._v(" ui设计师 ")]), _c("div", {
      staticClass: "btn"
    }, [_c("a-button", {
      attrs: {
        type: "link"
      }
    }, [_vm._v(" 查看详情 ")])], 1)]);
  }), 0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "name-box"
  }, [_c("span", {
    staticClass: "name"
  }, [_vm._v("11yy111")]), _c("br"), _c("span", {
    staticClass: "name-desc"
  }, [_vm._v("男 / 28岁 / 5年以上 / 本科")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };