import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import { mapState } from "vuex";
export default {
  name: "EditorModal",
  props: ["show", "options"],
  data: function data() {
    return {
      commonDate: null,
      copyData: {},
      //  params: {},
      progList: [],
      formList: {},
      publicMoney: {},
      disabled: true,
      subCompany: [],
      option: {},
      copyFormList: []
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  watch: {
    show: function show(val) {
      if (val) {
        if (this.options) {
          this.option = JSON.parse(JSON.stringify(this.options));
          if (this.option.socProgId) {
            this.getSubCompanys(this.option.socProgId);
          }
          this.option.assignCompanyId = this.options.assignCompanyId;
          this.commonDate = this.moment(this.option.socStartDate);
          //  this.params.progName = this.options.socProgName || "";
          this.copyData = JSON.parse(JSON.stringify(this.option));
          if (this.option.socDispatchEmpItemList) {
            this.formList = this.option.socDispatchEmpItemList.map(function (item) {
              item.cardinalityDefault = item.cardinalityPerson;
              return item;
            });
            this.copyFormList = this.formList;
            this.publicMoney = this.formList.find(function (item) {
              return item.itemName.includes("公积金");
            });
          }
        }
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 清空指派公司
    handleSelect: function handleSelect(value) {
      this.option.assignCompanyId = value;
      this.option.assignCompanyName = this.subCompany.find(function (i) {
        return i.id === value;
      }).name;
    },
    // 获取初始子公司
    getSubCompanys: function getSubCompanys(id) {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/socDispatches/listChildCompanyBySoc/".concat(id, "/").concat(this.hr.companyId)
      }).then(function (_ref) {
        var data = _ref.data;
        return _this.subCompany = data || [];
      });
    },
    // 获取子公司
    getSubCompany: function getSubCompany(id) {
      var _this2 = this;
      this.$request({
        url: "/hr/api/v1/socDispatches/listChildCompanyBySoc/".concat(id, "/").concat(this.hr.companyId)
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this2.subCompany = data || [];
        if (data && data.length) {
          _this2.option.assignCompanyId = data[0].id;
          _this2.option.assignCompanyName = data[0].name;
        } else {
          _this2.option.assignCompanyId = "";
        }
      });
    },
    // 编辑每一个
    editItem: function editItem(item) {
      this.disabled = !this.disabled;
    },
    // 获取参保方案
    getList: function getList() {
      var _this3 = this;
      this.$request({
        url: "/hr/api/v1/socProgs/listAllRelation"
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this3.progList = data;
      });
    },
    // 选择方案
    handleChange: function handleChange(value, option) {
      var _this4 = this;
      this.option.socProgId = option.data.key;
      var formList = this.progList.find(function (item) {
        return item.progName === value;
      }).socProgItemVOList;
      formList = formList.map(function (item) {
        _this4.copyFormList.map(function (f) {
          if (f.itemName === item.itemName) {
            item.cardinalityDefault = f.cardinalityPerson;
          }
        });
        return item;
      });
      this.formList = formList;
      this.option.socDispatchEmpItemList = this.progList.find(function (item) {
        return item.progName === value;
      }).socProgItemVOList;
      this.getSubCompany(option.key);
    },
    // 确定
    onOk: function onOk() {
      this.option.fundStartDate = this.option.socStartDate = new Date(this.commonDate.format()).getTime();
      this.disabled = true;
      this.$emit("change", {
        options: this.option,
        onOk: true
      });
    },
    // 取消
    onCancel: function onCancel() {
      this.disabled = true;
      this.$emit("change", {
        options: this.copyData,
        onOk: false
      });
    }
  }
};