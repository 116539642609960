import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
export default {
  name: "PayslipExcelDetail",
  data: function data() {
    return {
      dataSource: [],
      columns: [{
        title: "员工姓名",
        width: 150,
        dataIndex: "empName",
        fixed: "left"
      }, {
        title: "发送方式",
        dataIndex: "sendType",
        width: 150,
        scopedSlots: {
          customRender: "sendType"
        }
      }, {
        title: "确认工资条",
        dataIndex: "checkStatus",
        width: 150,
        scopedSlots: {
          customRender: "checkStatus"
        }
      }, {
        title: "手机号",
        dataIndex: "phone",
        width: 150
      }, {
        title: "个人所得税",
        dataIndex: "empTax",
        width: 150
      }, {
        title: "个人社保",
        dataIndex: "empSoc",
        width: 150
      }, {
        title: "个人公积金",
        dataIndex: "empPf",
        width: 150
      }, {
        title: "基本工资",
        dataIndex: "salaryBasic",
        width: 150
      }, {
        title: "应发工资",
        dataIndex: "salaryAmount",
        width: 150
      }, {
        title: "实发工资",
        dataIndex: "salaryReal",
        width: 150
      }],
      pagination: {
        current: 1,
        total: 0,
        pageSize: 1000
      },
      params: {
        pageNo: 1,
        pageSize: 1000
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList: function getList() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/salaryBillSends/salaryBillPageBySendDetail",
        params: _objectSpread(_objectSpread({}, this.params), {}, {
          sendId: this.$route.query.id,
          sendStatus: 1
        })
      }).then(function (_ref) {
        var data = _ref.data;
        _this.dataSource = data.entities || [];
        _this.pagination.total = data.entityCount || 0;
        _this.dataSource.forEach(function (item) {
          item.salaryBillFieldsDOList.forEach(function (key) {
            key.dataIndex = key.sort;
          });
        });
        var colum = [];
        _this.dataSource[0].salaryBillFieldsDOList.map(function (key) {
          colum.push({
            dataIndex: key.sort,
            title: key.fieldName,
            width: 150
          });
        });
        _this.dataSource = _this.dataSource.map(function (item) {
          var obj = {};
          item.salaryBillFieldsDOList.map(function (key) {
            obj[key["dataIndex"]] = key["fieldValue"];
          });
          item = _objectSpread(_objectSpread({}, item), obj);
          return item;
        });
        _this.columns = [].concat(_toConsumableArray(_this.columns), colum);
      });
    },
    change: function change(e) {
      this.params.pageNo = e.current;
      this.pagination.current = e.current;
      this.getList();
    }
  }
};