import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
var column = [{
  title: "姓名",
  dataIndex: "name",
  fixed: "left",
  scopedSlots: {
    customRender: "customName"
  }
}, {
  title: "参保城市",
  dataIndex: "socCityName"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200
}, {
  title: "参保方案",
  dataIndex: "socProgName"
}, {
  title: "社保停缴时间",
  dataIndex: "socEndDate",
  scopedSlots: {
    customRender: "socEndDate"
  }
}, {
  title: "公积金停缴时间",
  dataIndex: "fundEndDate",
  scopedSlots: {
    customRender: "fundEndDate"
  }
}, {
  title: "指派公司",
  dataIndex: "assignCompanyName",
  scopedSlots: {
    customRender: "assignCompanyName"
  }
}, {
  title: "操作",
  dataIndex: "",
  scopedSlots: {
    customRender: "action"
  }
}];
import step from "./common/step";
import navItem from "./common/nav-item";
export default {
  name: "ShebaoDetailList",
  components: {
    // step,
    navItem: navItem
  },
  data: function data() {
    return {
      tabs: {},
      params: {
        pageSize: 10,
        pageNo: 1
      },
      column: column,
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      titles: {},
      dataSource: []
    };
  },
  created: function created() {
    this.getList();
    this.getNavs();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var url = "/hr/api/v1/socDispatchEmpLesses";
      this.$request({
        url: url,
        params: _objectSpread(_objectSpread({}, this.params), {}, {
          socDispatchId: this.$route.query.id,
          showItem: true
        })
      }).then(function (_ref) {
        var data = _ref.data;
        _this.dataSource = data.entities;
        _this.pagination.total = data.entityCount;
      });
    },
    getNavs: function getNavs() {
      var _this2 = this;
      this.$request({
        url: "/hr/api/v1/socDispatches/findDetails/".concat(this.$route.query.id)
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this2.tabs = data;
      });
    },
    selectItem: function selectItem(e) {},
    handlePage: function handlePage(_ref3) {
      var current = _ref3.current;
      this.params.pageNo = current;
      this.getList();
    },
    handleNav: function handleNav(e) {},
    // 删除item
    deleteItem: function deleteItem(_ref4) {
      var _this3 = this;
      var id = _ref4.id;
      this.$confirm({
        title: "确定删除?",
        okType: "danger",
        onOk: function onOk() {
          _this3.$request({
            url: "/hr/api/v1/socDispatchEmpLesses/".concat(id),
            method: "delete"
          }).then(function (_ref5) {
            var success = _ref5.success;
            if (success) {
              _this3.$notification["success"]({
                message: "删除成功"
              });
              _this3.getList();
              _this3.getNavs();
            }
          });
        }
      });
    },
    // 重新上传
    reUpload: function reUpload() {
      this.$router.push("/shebao/shebaoPaidan/shebaoUpload");
    },
    // 下一页
    doNext: function doNext() {
      var path = "/shebao/shebaoPaidan/shebao-detail-add-second-less";
      this.$router.push({
        path: path,
        query: _objectSpread({}, this.$route.query)
      });
    }
  }
};