import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import { getDepEmpInfo2, getRecordInfo } from "@/api/company/contract";
import { addBatchRecord } from "@/api/shebao/shebaoInfo.js";
export default {
  props: {
    state: {
      type: Number,
      default: 0
    },
    year: {
      type: String,
      default: ""
    },
    month: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    },
    number: {
      type: Number,
      default: 0
    },
    templateId: {
      type: Number,
      default: 0
    },
    defaultSelectedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isOnlineOrLeave: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      recordList: [],
      spinning: false,
      mode: "1",
      treeData: [],
      selectedListTemp: [],
      isAllSelected: false,
      selectedKeysBeforeSearch: [],
      postTypeValue: 3,
      pageSizeOptions: ["10", "20", "50", "100"],
      pageSize: 10,
      current: 1,
      total: 50
    };
  },
  computed: {
    postTypeList: function postTypeList() {
      return this.$store.state.enums.postTypeLst;
    }
  },
  watch: {
    show: function show(val) {
      if (val) {
        console.log(this.state);
        this.selectedListTemp = [];
        this.treeData = [];
        this.spinning = false;
        this.current = 1;
        this.postTypeValue = 3;
        this.mode = "1";
        if (this.defaultSelectedKeys.length !== this.selectedListTemp.length || this.defaultSelectedKeys.length === 0) {
          this.getSelectDepTree();
        }
      }
    },
    month: function month(val) {}
  },
  mounted: function mounted() {},
  methods: {
    // 分页变化
    onPageChange: function onPageChange(pageNumber) {
      this.current = pageNumber;
      this.getSelectDepTree();
    },
    postTypeClick: function postTypeClick(value) {
      this.postTypeValue = value;
      this.isAllSelected = false;
      this.current = 1;
      this.getSelectDepTree();
    },
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = 1;
      this.getSelectDepTree();
    },
    choseAll: function choseAll(data) {
      this.isAllSelected = !this.isAllSelected;
      var that = this;
      this.spinning = true;
      for (var j = 0, len = data.length; j < len; j++) {
        data[j].isSelected = that.isAllSelected;
        that.ergodicAll(data[j]);
      }
      setTimeout(function () {
        that.spinning = false;
      }, "10");
    },
    chose: function chose(item, data) {
      item.isSelected = !item.isSelected;
      this.onChange(item);
    },
    onChangeTab: function onChangeTab(e) {
      this.isAllSelected = false;
      this.current = 1;
      this.mode = e;
      this.getSelectDepTree();
    },
    onChange: function onChange(item) {
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.ergodic(item);
        that.spinning = false;
      }, "10");
    },
    ergodic: function ergodic(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
          this.isAllSelected = false;
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
          var count = this.treeData.length;
          var allSelect = true;
          for (var i = 0; i < count; i++) {
            if (!this.treeData[i].isSelected) {
              allSelect = false;
              break;
            }
          }
          this.isAllSelected = allSelect;
        }
      }
    },
    ergodicAll: function ergodicAll(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
        }
      }
    },
    // 获取部门树
    getSelectDepTree: function getSelectDepTree(query) {
      var _this = this;
      if (query) {
        this.current = 1;
      }
      var params = {
        companyId: this.$store.state.hr.companyId,
        year: this.year,
        month: this.month,
        pageNo: this.current,
        pageSize: this.pageSize,
        postType: this.postTypeValue + ""
      };
      if (query && this.state === 1) {
        params["name"] = query;
      } else {
        params["nameOrPhone"] = query;
      }
      this.spinning = true;
      if (this.state === 1) {
        getRecordInfo(params).then(function (res) {
          if (res.data.entities === null) {
            _this.$message.success("暂无数据");
            res.data.entityCount = [];
            _this.treeData = [];
            _this.total = 0;
            _this.isAllSelected = false;
          } else {
            _this.treeData = _this.formatCompanyData(res.data.entities);
            _this.total = res.data.entityCount;
            var count = _this.treeData.length;
            var allSelect = true;
            if (count !== 0) {
              for (var i = 0; i < count; i++) {
                if (!_this.treeData[i].isSelected) {
                  allSelect = false;
                  break;
                }
              }
              _this.isAllSelected = allSelect;
            } else {
              _this.isAllSelected = false;
            }
          }
          _this.$nextTick(function () {
            _this.spinning = false;
          });
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      } else {
        getDepEmpInfo2(params).then(function (res) {
          if (res.data.entities === null) {
            _this.$message.success("暂无数据");
            res.data.entityCount = [];
            _this.treeData = [];
            _this.total = 0;
            _this.isAllSelected = false;
          } else {
            _this.treeData = _this.formatCompanyData(res.data.entities);
            _this.total = res.data.entityCount;
            var count = _this.treeData.length;
            var allSelect = true;
            if (count !== 0) {
              for (var i = 0; i < count; i++) {
                if (!_this.treeData[i].isSelected) {
                  allSelect = false;
                  break;
                }
              }
              _this.isAllSelected = allSelect;
            } else {
              _this.isAllSelected = false;
            }
          }
          _this.$nextTick(function () {
            _this.spinning = false;
          });
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      }
    },
    formatCompanyData: function formatCompanyData(company) {
      var parent = [];
      for (var key = 0; key < company.length; key++) {
        var selected = false;
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === company[key].id;
        }) !== -1) {
          selected = true;
        }
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === company[key].empId;
        }) !== -1) {
          selected = true;
        }
        var emp = {};
        if (this.state === 1) {
          emp = company[key];
          emp.id = emp.empId;
          emp.isSelected = selected;
          parent.push(emp);
        } else {
          emp = {
            id: company[key].id,
            name: company[key].name,
            phone: company[key].phone,
            idCard: company[key].idCard,
            depName: company[key].depName,
            positionName: company[key].positionName,
            isSelected: selected
          };
          parent.push(emp);
        }
      }
      return parent;
    },
    searchPeople: function searchPeople(query) {
      this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
        return item.id;
      });
      this.getSelectDepTree(query);
    },
    changePeople: function changePeople(e) {
      if (e.target.value === "") {
        this.getSelectDepTree();
        this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
          return item.id;
        });
      }
    },
    deleteItem: function deleteItem(item) {
      item.isSelected = false;
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.ergodic(item);
        that.spinning = false;
      }, "10");
    },
    ok: function ok() {
      var _this2 = this;
      if (this.selectedListTemp.length === 0) {
        this.$message.success("请选择人员");
        return;
      }
      if (this.state === 1) {
        this.recordList = [];
        this.selectedListTemp.forEach(function (item, index) {
          _this2.recordList.push({
            remark: item.remark,
            companyId: _this2.$store.state.hr.companyId,
            empId: item.empId,
            // empSocInfoId: item.id,
            year: _this2.year,
            month: _this2.month,
            serviceCost: item.serviceCost,
            socAmount: item.personalSocAmount + item.companySocAmount + item.personalPfAmount + item.companyPfAmount,
            overdueFine: item.accumulationFundFine + item.socialSecurityFine
          });
        });
        addBatchRecord(this.recordList).then(function (res) {
          _this2.$emit("update:show", false);
          _this2.$notification["success"]({
            message: "success",
            description: "计划生成成功"
          });
        });
      } else {
        this.$emit("update:selectedList", this.selectedListTemp);
        this.$emit("update:selectThree", this.selectedListTemp.slice(0, 3).map(function (item) {
          return item.name;
        }).join(","));
        this.$emit("update:show", false);
        this.$emit("change", this.selectedListTemp);
      }
    },
    cancel: function cancel() {
      this.$emit("update:show", false);
    }
  }
};