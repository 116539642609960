import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import BaseSet from "./components/setters/BaseSet";
import FormSet from "./components/setters/FormSet";
import ProcessSet from "./components/setters/ProcessSet";
import SeniorSet from "./components/setters/SeniorSet";
import { publishApproval, getApprovalDetail } from "../api";
import { isJson, isContainByString } from "@/utils/index";
export default {
  components: {
    BaseSet: BaseSet,
    FormSet: FormSet,
    ProcessSet: ProcessSet,
    SeniorSet: SeniorSet
  },
  data: function data() {
    return {
      blockList: [],
      baseinfo: {
        // 基础设置
        isSysStart: "",
        id: null,
        typeName: "",
        // 审批名称
        groupId: "",
        // 审批分组
        type: "",
        // 审批类型
        revokeType: "",
        // 撤回方式
        icon: "https://res.csxr.cn/icon/approval/baoxiao.png",
        // 审批图标
        typeDesc: "" // 审批说明
      },

      formlist: [],
      // 审批表单
      process: {
        // 审批流程
        name: "发起人",
        nodeType: "start",
        nodeId: 0,
        limitDepartment: "",
        limitUser: "",
        props: "",
        formSetting: []
      },
      setting: {
        // 高级设置
        id: null,
        flowId: null,
        uplicateRemoval: "",
        tipsRemake: "",
        remakeRequire: false,
        remakeEnableOther: false,
        remakeShow: true
      },
      defaultTabs: 1
    };
  },
  computed: {
    approFlowId: function approFlowId() {
      return this.$route.query.id;
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    console.log("this.approFlowId", this.approFlowId);
    if (this.approFlowId) {
      getApprovalDetail(this.approFlowId).then(function (res) {
        var flow = res.data;
        _this2.baseinfo = {
          isSysStart: Number(flow.isSysStart),
          id: flow.id,
          typeName: flow.typeName,
          groupId: flow.groupId,
          type: flow.type,
          revokeType: flow.revokeType,
          icon: flow.icon,
          typeDesc: flow.typeDesc
        };
        if (flow.flowForm && flow.flowForm.length) {
          _this2.formlist = JSON.parse(flow.flowForm).map(function (item) {
            return Object.assign({}, item, {
              props: JSON.parse(item.props)
            });
          });
        } else {
          flow.flowForm = [];
        }
        _this2.process = _this2.buildFormPowerList(flow.process);
        _this2.setting = {
          id: flow.setting.id,
          flowId: flow.setting.flowId,
          uplicateRemoval: flow.setting.uplicateRemoval,
          tipsRemake: flow.setting.tipsRemake,
          remakeRequire: flow.setting.remakeRequire,
          remakeEnableOther: flow.setting.remakeEnableOther,
          remakeShow: flow.setting.remakeShow
        };
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  },
  methods: {
    onFormTypeChange: function onFormTypeChange(data) {
      this.formlist = data;
    },
    onFormListChange: function onFormListChange(e) {
      this.blockList = e;
      this.process = this.buildFormPowerList(this.process);
    },
    goOut: function goOut() {
      var _this = this;
      this.$confirm({
        title: "有修改的内容未发布，确认离开吗？",
        onOk: function onOk() {
          _this.$router.go(-1);
        }
      });
    },
    saveData: function saveData(type) {
      var _this3 = this;
      if (!this.baseinfo.typeName || !this.baseinfo.groupId || !this.baseinfo.type) {
        this.$message.error("基础设置中有必填项未填写");
        return;
      }
      var oldType = this.defaultTabs;
      this.defaultTabs = type;
      if (oldType === 2) this.$refs.formSet.handleFormDesignSave();
      if (!type) {
        this.defaultTabs = oldType;
        publishApproval(_objectSpread(_objectSpread({}, this.baseinfo), {}, {
          flowForm: this.formlist,
          process: this.process,
          setting: this.setting
        })).then(function (res) {
          _this3.$message.success("发布成功");
          if (isContainByString("newApprovalAdmin")) {
            _this3.$router.replace("approvalAdmin/setting");
          } else if (isContainByString("defaultTransferAccounts")) {
            _this3.$router.replace("/paySalary/examine");
          } else {
            _this3.$router.replace("approval/setting");
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      }
    },
    buildFormPowerList: function buildFormPowerList(data) {
      var dataTemp = Object.assign({}, data);
      this.buildProcessFormPower(dataTemp);
      return dataTemp;
    },
    buildProcessFormPower: function buildProcessFormPower(data) {
      var _this4 = this;
      // 审批流表单权限
      if (isJson(data.props)) {
        data.props = JSON.parse(data.props);
      }
      var hasFormSetting = data.formSetting && data.formSetting.length && data.formSetting.length > 0;
      if (data.nodeType !== "notifier" && data.nodeType !== "block" && data.nodeType !== "condition" && hasFormSetting) {
        if (isJson(data.formSetting)) {
          data.formSetting = JSON.parse(data.formSetting);
        }
        var formCodes = data.formSetting.map(function (f) {
          return f.formCode;
        });
        var formPowers = [];
        this.formlist.map(function (item) {
          if (formCodes.includes(item.code)) {
            var form = data.formSetting.find(function (f) {
              return f.formCode === item.code;
            });
            if (form) {
              formPowers.push(form);
            }
          } else {
            formPowers.push({
              formCode: item.code,
              flowFormName: item.label,
              sort: item.sort,
              type: data.nodeType === "start" ? "edit" : "read"
            });
          }
        });
        data.formSetting = formPowers;
      }
      if (data.childNode) {
        var childNodeTemp = Object.assign({}, data.childNode);
        this.buildProcessFormPower(childNodeTemp);
        data.childNode = childNodeTemp;
      }
      if (data.conditionNodes) {
        data.conditionNodes.map(function (item) {
          if (item.childNode) {
            var itemChildNodeTemp = Object.assign({}, item.childNode);
            _this4.buildProcessFormPower(itemChildNodeTemp);
            item.childNode = itemChildNodeTemp;
          }
        });
      }
    }
  }
};