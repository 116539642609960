import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "面试详情",
      footer: null,
      width: "520px"
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "interviewMsg-modal"
  }, [_c("div", {
    staticClass: "m-box"
  }, [_c("a-list-item-meta", {
    attrs: {
      description: _vm.description
    }
  }, [_c("div", {
    staticClass: "tit",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", [_vm._v(_vm._s(_vm.info.name))])]), _vm.info.avatar ? _c("a-avatar", {
    attrs: {
      slot: "avatar",
      src: _vm.info.avatar
    },
    slot: "avatar"
  }) : _vm._e(), !_vm.info.avatar && _vm.info.gender === 1 ? _c("a-avatar", {
    attrs: {
      slot: "avatar",
      src: _vm.n1
    },
    slot: "avatar"
  }) : _vm._e(), !_vm.info.avatar && _vm.info.gender === 2 ? _c("a-avatar", {
    attrs: {
      slot: "avatar",
      src: _vm.n2
    },
    slot: "avatar"
  }) : _vm._e()], 1), _vm.interviewId ? _c("div", [_vm.status <= "2" ? _c("div", {
    staticClass: "m-status"
  }, [_vm._v("等待面试")]) : _vm.status == "9" ? _c("div", {
    staticClass: "m-status"
  }, [_vm._v("面试已取消")]) : _c("div", {
    staticClass: "m-status"
  }, [_vm._v("已面试")])]) : _vm._e()], 1), _c("div", {
    staticClass: "m-content"
  }, [_c("div", [_c("span", [_vm._v("面试方式：")]), _vm._v(" "), _vm.interviewId ? _c("span", [_vm._v(_vm._s(_vm.interviewType === 1 ? "现场面试" : "电话面试"))]) : _vm._e(), _c("br")]), _c("div", [_c("span", [_vm._v("面试时间：")]), _vm.interviewId ? _c("span", [_vm._v(_vm._s(_vm.interviewDate ? _vm.moment(_vm.interviewDate).format("YYYY-MM-DD HH:mm") : "") + " ")]) : _vm._e(), _c("br")]), _c("div", [_c("span", [_vm._v("面试人：")]), _vm._v(" "), _vm.interviewId ? _c("span", [_vm._v(_vm._s(_vm.interviewPersonName))]) : _vm._e(), _c("br")]), _c("div", [_c("span", [_vm._v("面试地点：")]), _vm._v(" "), _vm.interviewId ? _c("span", [_vm._v(_vm._s(_vm.interviewLocation))]) : _vm._e(), _c("br")]), _c("div", [_c("span", [_vm._v("备注：")]), _vm._v(" "), _vm.interviewId ? _c("span", [_vm._v(_vm._s(_vm.remarks))]) : _vm._e(), _c("br")])]), _vm.status >= 3 && _vm.status != 9 ? _c("div", {
    staticClass: "m-content"
  }, [_c("div", [_c("span", [_vm._v("面试评价：")]), _c("br"), _vm.interviewId ? _c("span", [_vm._v(_vm._s(_vm.interviewEvaluation))]) : _vm._e()])]) : _vm._e(), _vm.interviewId && _vm.status == 2 ? _c("div", {
    staticClass: "interviewMsg-btn"
  }, [_c("a-button", {
    staticClass: "mr12",
    on: {
      click: _vm.onCancel
    }
  }, [_vm._v("取消面试")]), _c("a-button", {
    staticClass: "mr12",
    on: {
      click: _vm.onShare
    }
  }, [_vm._v("面试二维码")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleOk
    }
  }, [_vm._v(" 评价 ")])], 1) : _vm._e(), _vm.interviewId && _vm.status != 2 ? _c("div", {
    staticClass: "interviewMsg-btn"
  }, [_c("a-button", {
    on: {
      click: _vm.onShare
    }
  }, [_vm._v("面试二维码")])], 1) : _vm._e()]), _c("delModal", {
    attrs: {
      visible: _vm.delVisible,
      title: "确定取消面试吗？",
      okText: "暂不取消",
      cancelText: "取消面试"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.delVisible = $event;
      },
      onCancel: _vm.onCancelDel
    }
  }), _c("share", {
    attrs: {
      visible: _vm.shareVisible,
      qrCodeUrlV: _vm.info.qrCodeUrlV
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.shareVisible = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };