import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import ListStats from "./listStats.vue";
import { filterEmptyValue } from "@/utils/tools";
import { columnsList } from "./funcs";
import SelectCompanyGroup from "@/components/SelectCompany";
import SelectEmployee from "./selectEmployee.vue";
import SelectConfirm from "./selectConfirm.vue";
import ListDetail from "./listDetail.vue";
import { mapGetters } from "vuex";
import Result from "./result.vue";
import { getToken } from "xqjr-module-auth/utils";
export default {
  name: "",
  components: {
    ListStats: ListStats,
    SelectCompanyGroup: SelectCompanyGroup,
    SelectEmployee: SelectEmployee,
    SelectConfirm: SelectConfirm,
    ListDetail: ListDetail,
    Result: Result
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      resultVisible: false,
      resultData: null,
      currentEmp: null,
      detailVisible: false,
      confirmVisible: false,
      selectVisible: false,
      columnsList: columnsList,
      status: 1,
      nameOrIdCard: "",
      schemeName: "",
      insCompanyName: "",
      dateRange: null,
      params: {
        status: 1
      },
      siteId: "",
      type: "",
      dataSource: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      },
      empLst: [],
      loading: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["permissions_shangbao"])), {}, {
    hasExport: function hasExport() {
      return this.permissions_shangbao.includes("insuranceAddService:insuranceAddService.zaibaorenyuan:exportIns");
    },
    hasAdd: function hasAdd() {
      return this.permissions_shangbao.includes("insuranceAddService:insuranceAddService.zaibaorenyuan:addIns");
    }
  }),
  watch: {
    siteId: function siteId() {
      this.params = _objectSpread(_objectSpread({}, this.params), {}, {
        type: {
          id: this.siteId,
          type: this.type
        }
      });
    },
    status: function status(newVal) {
      this.params = _objectSpread(_objectSpread({}, this.params), {}, {
        status: newVal
      });
    },
    params: {
      handler: function handler() {
        this.onSearchChange();
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    doResultConfirm: function doResultConfirm() {
      this.resultClear();
      this.pagination.current = 1;
      this.onSearchChange();
    },
    resultClear: function resultClear() {
      this.resultVisible = false;
      this.resultData = null;
    },
    doDetail: function doDetail(item) {
      var _this = this;
      this.currentEmp = item;
      setTimeout(function () {
        _this.detailVisible = true;
      }, 0);
    },
    formatDate: function formatDate(val) {
      if (val) {
        return this.$dayjs(val).format("YYYY-MM-DD");
      }
      return "--";
    },
    doExport: function doExport() {
      var _this2 = this;
      this.$axios({
        url: "/hr/api/v1/hrInsurance/exportList",
        method: "post",
        data: this.params,
        responseType: "blob",
        headers: {
          token: getToken(),
          "Content-Type": "application/json"
        }
      }).then(function (res) {
        if (res.data && res.data.type === "application/json") {
          var reader = new FileReader();
          reader.readAsText(res.data, "utf-8");
          reader.onload = function () {
            var resJson = JSON.parse(reader.result);
            _this2.$message.error(resJson.msg);
          };
        } else {
          var rspHeaders = res.headers;
          var blob = new Blob([res.data], {
            type: "application/octet-stream"
          });
          var link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          if (rspHeaders["content-disposition"]) {
            try {
              var fileName = decodeURI(rspHeaders["content-disposition"].split(";")[1].split("=")[1].replaceAll('"', ""));
              var fileNameArr = fileName.split(".");
              link.download = "商保记录." + fileNameArr[1];
              link.click();
              URL.revokeObjectURL(link.href);
              document.body.removeChild(link);
            } catch (err) {
              console.log("error log --> ", err);
            }
          } else {
            _this2.$notification.error({
              message: "下载失败"
            });
          }
        }
      }).catch(function (err) {
        console.log("hrInsurance/exportList", err);
      });
    },
    doConfirm: function doConfirm(params) {
      var _this3 = this;
      this.$request({
        url: "/insurance/api/v1/qyInsuranceRecords/addInsurance",
        method: "post",
        data: _objectSpread(_objectSpread({}, params), {}, {
          empList: this.empLst.map(function (item) {
            return {
              id: item.id,
              name: item.name,
              idCard: item.idCard,
              phone: item.phone,
              companyId: item.companyId,
              companyName: item.companyName
            };
          })
        })
      }).then(function (res) {
        _this3.confirmVisible = false;
        _this3.pagination.current = 1;
        _this3.onSearchChange();
        _this3.resultData = res.data;
        if (_this3.resultData.errorCount) {
          _this3.resultVisible = true;
        }
      }).catch(function (err) {
        console.log("qyInsuranceRecords/addInsurance", err);
      });
    },
    onEmpSelected: function onEmpSelected(empLst) {
      if (empLst.length <= 0) {
        this.$message.warning("未选择参保人员");
        return false;
      }
      this.empLst = empLst;
      this.confirmVisible = true;
    },
    onInputChange: function onInputChange(key) {
      this.params = filterEmptyValue(_objectSpread(_objectSpread({}, this.params), {}, _defineProperty({}, "".concat(key), this[key])));
    },
    onRangeChange: function onRangeChange(value) {
      this.dateRange = value;
      var params = {};
      if (this.dateRange && this.dateRange.length > 0) {
        var dates = this.dateRange.map(function (item) {
          return item.format("YYYY-MM-DD");
        });
        params["beginTime"] = dates[0];
        params["endTime"] = dates[1];
      } else {
        params["beginTime"] = null;
        params["endTime"] = null;
      }
      this.params = filterEmptyValue(_objectSpread(_objectSpread({}, this.params), params));
    },
    onPanelChange: function onPanelChange(value) {
      this.dateRange = value;
      var params = {};
      if (this.dateRange && this.dateRange.length > 0) {
        var dates = this.dateRange.map(function (item) {
          return item.format("YYYY-MM-DD");
        });
        params["beginTime"] = dates[0];
        params["endTime"] = dates[1];
      } else {
        params["beginTime"] = null;
        params["endTime"] = null;
      }
      this.params = filterEmptyValue(_objectSpread(_objectSpread({}, this.params), params));
    },
    handleTableChange: function handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.onSearchChange();
    },
    onSearchChange: function onSearchChange() {
      var _this4 = this;
      this.loading = true;
      this.$request({
        url: "/hr/api/v1/hrInsurance/selectPage",
        method: "post",
        data: _objectSpread(_objectSpread({}, this.params), {}, {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        })
      }).then(function (res) {
        _this4.dataSource = res.data.entities;
        _this4.pagination.total = res.data.entityCount;
        _this4.loading = false;
        if (_this4.pagination.current === 1) {
          _this4.$refs.stats.loadData();
        }
      }).catch(function (err) {
        console.log("hrInsurance/selectPage", err);
        _this4.loading = false;
      });
    }
  }
};