var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "top-item-wrapper"
  }, [_c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: _vm.imgUrl,
      alt: ""
    }
  }), _c("div", {
    staticClass: "text-wrapper"
  }, [_c("div", {
    staticClass: "text-div"
  }, [_c("span", {
    staticClass: "text-div-title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.content && _vm.content.length >= 11 ? _c("a-tooltip", [_c("template", {
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.content) + " ")]), _c("span", {
    staticClass: "text-div-content"
  }, [_vm._v(" " + _vm._s(_vm.content.substring(0, 11)) + " ")])], 2) : _c("span", {
    staticClass: "text-div-content"
  }, [_vm._v(_vm._s(_vm.content ? _vm.content : 0))])], 1)]), _vm._t("content")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };