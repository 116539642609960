import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.slice.js";
import moment from "moment";
import { getToken } from "xqjr-module-auth";
import { publicDownLoad } from "../downLoad";
import { selectCustomerConfig, selectCustomerCompanyConfig as _selectCustomerCompanyConfig, selectCompanyBySiteId, addorder, selectCompanyById } from "./api";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      current: 0,
      fileList: [],
      estimate: 0,
      fileListMultiple: [],
      fileListMultipleCopy: [],
      participation: undefined,
      successVisible: false,
      steps: [{
        title: "选择方案",
        content: "First"
      }, {
        title: "上传投保人员",
        content: "Second"
      }, {
        title: "核对数据确认提交",
        content: "Last"
      }],
      insuranceList: [],
      planItem: {},
      startDate: null,
      endDate: null,
      loading: false,
      schemeConfirmFile: null,
      schemeConfirmFileName: null,
      selectPlanList: [],
      realityMonthAmount: null,
      selectPlanItem: undefined,
      detailTemplateFileName: "",
      rangeDate: [],
      detailTemplateFile: "",
      companyDetail: {},
      headers: {}
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.headers = {
      token: getToken()
    };
    this.selectCustomerCompanyConfig();
  },
  mounted: function mounted() {},
  methods: {
    limitNumber: function limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      } else {
        return 0;
      }
    },
    download: function download(file) {
      if (file.url) {
        publicDownLoad(file.url);
      } else if (file.response.data && file.response.data[0]) {
        publicDownLoad(file.response.data[0]);
      } else {
        this.$message.warning("文件下载失败");
      }
    },
    downLoadDetailFile: function downLoadDetailFile() {
      if (this.companyDetail && this.companyDetail.detailTemplateFile) {
        publicDownLoad(this.companyDetail.detailTemplateFile);
      } else {
        this.$message.warning("请联系京普商务人员配置");
      }
    },
    // 提交
    onSubmit: function onSubmit() {
      var _this = this;
      // if (!this.participation) {
      //   this.$message.warning("投保人数不能为0");
      // } else {
      var arr = {};
      this.insuranceList.forEach(function (item) {
        if (item.insCompanyId === _this.planItem.insCompanyId) {
          arr = item;
        }
      });
      var schemeName = "";
      var schemeId = "";
      this.selectPlanList.forEach(function (item) {
        if (item.id === _this.selectPlanItem) {
          schemeName = item.schemeName;
          schemeId = item.schemeId;
        }
      });
      this.loading = true;
      addorder({
        schemeName: schemeName,
        configId: this.selectPlanItem,
        // 保险方案id
        customerAttachment: this.fileListMultipleCopy,
        // 附件json字符串
        insEffectTime: this.startDate,
        insFailureTime: this.endDate,
        personTypeList: this.realityMonthAmount,
        // insuredNumber: this.participation,
        // monthAmount: this.realityMonthAmount,
        state: 0,
        // totalFee: this.estimate,
        schemeId: schemeId,
        insCompanyId: arr.insCompanyId,
        latestSubmitTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        companyName: arr.companyName,
        detailFile: this.detailTemplateFile
      }).then(function (res) {
        _this.successVisible = true;
        _this.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this.loading = false;
      });
      // }
    },
    // participationChange() {
    //   if (this.endDate && this.startDate) {
    //     var endDate = moment(moment(this.endDate, "YYYY-MM-DD")).format("YYYY-MM");
    //     var startDate = moment(moment(this.startDate, "YYYY-MM-DD")).format("YYYY-MM");
    //     var monthDiff = moment(endDate).diff(moment(startDate), "month") + 1;
    //   }
    //   if (this.realityMonthAmount && this.participation && monthDiff) {
    //     // 合计=月费*人数*一共几个月
    //     this.estimate = this.realityMonthAmount * this.participation * monthDiff;
    //   } else {
    //     this.estimate = 0;
    //   }
    // },
    downLoadFile: function downLoadFile() {
      publicDownLoad(this.schemeConfirmFile);
    },
    // 查询保险公司列表
    selectCustomerCompanyConfig: function selectCustomerCompanyConfig() {
      var _this2 = this;
      _selectCustomerCompanyConfig().then(function (res) {
        _this2.insuranceList = res.data;
      });
    },
    handleChangeMultiple: function handleChangeMultiple(info) {
      if (_toConsumableArray(info.fileList) && _toConsumableArray(info.fileList).length > 10) {
        this.$message.warning("最多上传十个附件");
      } else {
        var fileList = _toConsumableArray(info.fileList);
        fileList = fileList.map(function (file) {
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });
        this.fileListMultiple = fileList;
        var arr = [];
        if (this.fileListMultiple && this.fileListMultiple.length) {
          this.fileListMultiple.forEach(function (item, index) {
            if (item.status === "done" && item.response.data && item.response.data[0]) {
              arr.push({
                name: item.name,
                url: item.response.data[0]
              });
            }
          });
        }
        if (arr && arr.length) {
          this.fileListMultipleCopy = JSON.stringify(arr);
        } else {
          this.fileListMultipleCopy = undefined;
        }
      }
    },
    handleChange: function handleChange(info) {
      var fileList = _toConsumableArray(info.fileList);
      fileList = fileList.slice(-1);
      fileList = fileList.map(function (file) {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
      if (this.fileList && this.fileList.length) {
        if (this.fileList[0].status === "done" && this.fileList[0].response.data && this.fileList[0].response.data[0]) {
          this.detailTemplateFile = this.fileList[0].response.data[0];
          this.detailTemplateFileName = this.fileList[0].name;
        }
      } else {
        this.detailTemplateFile = "";
        this.detailTemplateFileName = "";
      }
    },
    // 选择保险公司变化
    insuranceChange: function insuranceChange(e) {
      var _this3 = this;
      this.selectPlanItem = undefined;
      this.realityMonthAmount = null;
      this.schemeConfirmFile = null;
      if (e) {
        selectCustomerConfig({
          insCompanyId: e
        }).then(function (res) {
          _this3.selectPlanList = res.data;
        });
      } else {
        this.selectPlanList = [];
      }
      selectCompanyById(e).then(function (res) {
        _this3.companyDetail = res.data;
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 新增编辑投保方案-选择参保方案变化
    selectPlan: function selectPlan(e) {
      var _this4 = this;
      if (e) {
        this.selectPlanList.forEach(function (item) {
          if (item.id === e) {
            _this4.realityMonthAmount = item.personTypeList;
            _this4.schemeConfirmFile = item.schemeConfirmFile;
            _this4.schemeConfirmFileName = item.remark;
          }
        });
      } else {
        this.realityMonthAmount = null;
        this.schemeConfirmFile = null;
        this.schemeConfirmFileName = null;
      }
    },
    // 选择时间
    onDateChange: function onDateChange(e) {
      this.startDate = e[0];
      this.endDate = e[1];
      if (this.endDate && this.startDate) {
        var endDate = moment(moment(this.endDate, "YYYY-MM-DD")).format("YYYY-MM");
        var startDate = moment(moment(this.startDate, "YYYY-MM-DD")).format("YYYY-MM");
        var monthDiff = moment(endDate).diff(moment(startDate), "month") + 1;
      }
      // if (this.realityMonthAmount && this.participation && monthDiff) {
      //   // 合计=月费*人数*一共几个月
      //   this.estimate = this.realityMonthAmount * this.participation * monthDiff;
      // } else {
      //   this.estimate = 0;
      // }
    },
    // 下一步
    next: function next() {
      if (this.current === 0) {
        if (!this.planItem.insCompanyId) {
          this.$message.warning("请选择保险公司");
        } else if (!this.selectPlanItem) {
          this.$message.warning("请选择保险方案");
        } else if (!this.startDate || !this.endDate) {
          this.$message.warning("请选择保险周期");
        } else {
          this.current++;
        }
      } else if (this.current === 1) {
        if (!this.fileList.length) {
          this.$message.warning("未上传投保人员明细表");
        } else {
          this.current++;
        }
      }
    },
    // 上一步
    prev: function prev() {
      this.current--;
    }
  }
};