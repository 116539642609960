import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import { selectCountAndProportion } from "@/api/shebao/statistic";
import echarts from "echarts";
export default {
  props: {
    dateBox: {
      type: Object,
      default: function _default() {
        return {
          dataOfNum: []
        };
      }
    },
    queryType: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      emptyImg: require("@/assets/img/statistic/kong.png"),
      GinsengPlan: null,
      Ginseng: null,
      xAxis: [],
      series: [],
      sideList: [],
      statisticalList: [],
      sideList2: [],
      statisticalList2: []
    };
  },
  watch: {
    dateBox: {
      handler: function handler(val, oldVal) {
        this.getSelectCountAndProportion();
      },
      deep: true
    }
  },
  created: function created() {
    // this.dailyDetails();
    // this.getSelectCountAndProportion();
  },
  methods: {
    goAdd: function goAdd() {
      this.$router.push({
        path: "./shebaoMethod/addMethod"
      });
    },
    getSelectCountAndProportion: function getSelectCountAndProportion() {
      var _this = this;
      selectCountAndProportion({
        companyId: this.$store.state.hr.companyId,
        startMonth: this.dateBox.startMonth,
        endMonth: this.dateBox.endMonth,
        startYear: this.dateBox.startYear,
        endYear: this.dateBox.endYear,
        queryType: this.queryType,
        step: this.dateBox.step,
        isAcrossYear: this.dateBox.startYear !== this.dateBox.endYear
      }).then(function (res) {
        _this.statisticalList = [];
        _this.statisticalList2 = [];
        _this.sideList = [];
        _this.sideList2 = [];
        _this.dailyDetails();
        _this.Ginseng = res.data.socCityStatistical && res.data.socCityStatistical !== null ? res.data.socCityStatistical.length : 0;
        _this.GinsengPlan = res.data.socProgStatistical && res.data.socProgStatistical !== null ? res.data.socProgStatistical.length : 0;
        res.data.socProgStatistical.forEach(function (item, index) {
          _this.sideList2.push(item.socProgName);
          _this.statisticalList2.push({
            name: item.socProgName,
            value: item.socProgCount
          });
        });
        res.data.socCityStatistical.forEach(function (item, index) {
          _this.sideList.push(item.socCityName);
          _this.statisticalList.push({
            name: item.socCityName,
            value: item.socCityCount
          });
        });
      });
    },
    dailyDetails: function dailyDetails() {
      var _this2 = this;
      this.$nextTick(function () {
        var myChart1 = echarts.init(document.getElementById("place"));
        var option1 = {
          title: {
            zlevel: 0,
            text: ["{name|参保地}", "{value|" + "".concat(_this2.Ginseng) + "}"].join("\n"),
            top: "35%",
            left: "24%",
            textAlign: "center",
            textStyle: {
              rich: {
                value: {
                  color: "#303133",
                  fontSize: 24,
                  lineHeight: 24
                },
                name: {
                  color: "#909399",
                  fontSize: 14,
                  lineHeight: 35
                }
              }
            }
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)"
          },
          color: ["#44D7B6", "#2294FF", "#FA5051", "#354785", "#8CD579", "#6A66F6", "#24CDAA", "#4282FF", "#FF7F50", "#FCCF5E", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A", "#6DC8EC", "#9270CA", "#FF9D4D", "#269A99", "#FF99C3", "#5B8FF9", "#BDD2FD", "#BDEFDB", "#C2C8D5", "#FBE5A2", "#F6C3B7", "#B6E3F5", "#9270CA", "#D3C6EA", "#FFD8B8", "#AAD8D8"],
          legend: {
            type: "scroll",
            orient: "vertical",
            left: "50%",
            top: 50,
            bottom: 20,
            icon: "circle",
            itemGap: 25,
            data: _this2.sideList // 侧边显示
          },

          series: [{
            name: "占比",
            type: "pie",
            radius: ["60%", "75%"],
            center: ["25%", "47%"],
            avoidLabelOverlap: false,
            stillShowZeroSum: false,
            zlevel: 1,
            label: {
              normal: {
                // padding: [20, 20, 20, 20],
                backgroundColor: "#fff",
                show: false,
                position: "center",
                formatter: ["{name|{b}}", "{value|{c}}"].join("\n"),
                rich: {
                  value: {
                    color: "#303133",
                    fontSize: 24,
                    lineHeight: 24
                  },
                  name: {
                    color: "#909399",
                    fontSize: 14,
                    lineHeight: 35
                  }
                }
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "16",
                  fontWeight: "bold"
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            itemStyle: {
              // 此配置
              normal: {
                borderWidth: 4,
                borderColor: "#ffffff"
              },
              emphasis: {
                borderWidth: 0,
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            },
            data: _this2.statisticalList
          }]
        };
        myChart1.setOption(option1);
        var myChart2 = echarts.init(document.getElementById("plan"));
        var option2 = {
          title: {
            zlevel: 0,
            text: ["{name|参保方案}", "{value|" + "".concat(_this2.GinsengPlan) + "}"].join("\n"),
            top: "35%",
            left: "24%",
            textAlign: "center",
            textStyle: {
              rich: {
                value: {
                  color: "#303133",
                  fontSize: 24,
                  lineHeight: 24
                },
                name: {
                  color: "#909399",
                  fontSize: 14,
                  lineHeight: 35
                }
              }
            }
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)"
          },
          color: ["#44D7B6", "#2294FF", "#FA5051", "#354785", "#8CD579", "#6A66F6", "#24CDAA", "#4282FF", "#FF7F50", "#FCCF5E", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A", "#6DC8EC", "#9270CA", "#FF9D4D", "#269A99", "#FF99C3", "#5B8FF9", "#BDD2FD", "#BDEFDB", "#C2C8D5", "#FBE5A2", "#F6C3B7", "#B6E3F5", "#9270CA", "#D3C6EA", "#FFD8B8", "#AAD8D8"],
          legend: {
            type: "scroll",
            orient: "vertical",
            left: "50%",
            top: 50,
            bottom: 20,
            icon: "circle",
            itemGap: 25,
            data: _this2.sideList2 // 侧边显示
          },

          series: [{
            name: "占比",
            type: "pie",
            radius: ["60%", "75%"],
            center: ["25%", "47%"],
            avoidLabelOverlap: false,
            stillShowZeroSum: false,
            zlevel: 1,
            label: {
              normal: {
                // padding: [20, 20, 20, 20],
                backgroundColor: "#fff",
                show: false,
                position: "center",
                formatter: ["{name|{b}}", "{value|{c}}"].join("\n"),
                rich: {
                  value: {
                    color: "#303133",
                    fontSize: 24,
                    lineHeight: 24
                  },
                  name: {
                    color: "#909399",
                    fontSize: 14,
                    lineHeight: 35
                  }
                }
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "16",
                  fontWeight: "bold"
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            itemStyle: {
              // 此配置
              normal: {
                borderWidth: 4,
                borderColor: "#ffffff"
              },
              emphasis: {
                borderWidth: 0,
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            },
            data: _this2.statisticalList2
          }]
        };
        myChart2.setOption(option2);
      });
    }
  }
};