var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "revset"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 查看参保方案 ")]), _c("a-layout", [_c("a-row", {
    staticStyle: {
      width: "100%",
      "text-align": "center",
      "margin-top": "20px"
    }
  }, [_c("span", {
    staticStyle: {
      "font-weight": "800",
      "font-size": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.progName) + "-" + _vm._s(_vm.socCityName) + "(" + _vm._s(_vm.onlySocAmount ? "仅设置参保金额" : "设置参保金额和比例") + ")")])]), _c("a-row", {
    staticStyle: {
      width: "100%",
      "text-align": "center",
      "margin-top": "10px"
    }
  }, [_c("span", {
    staticStyle: {
      "font-weight": "400",
      "font-size": "14px",
      "margin-right": "10px"
    }
  }, [_vm._v(" 缴扣日期：每月" + _vm._s(_vm.deductionsDate) + "日 ")]), _c("span", {
    staticStyle: {
      "font-weight": "400",
      "font-size": "14px",
      "margin-left": "10px"
    }
  }, [_vm._v(" 开始使用时间：" + _vm._s(_vm.getDateForm(_vm.userDate)) + " ")])]), _c("div", {
    staticClass: "header-title-box"
  }, [_c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("社保")])]), _c("div", {
    staticClass: "btn-box"
  }, [!_vm.isContainByString("contractAdmin") ? _c("a-button", {
    staticClass: "mr12",
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      icon: "plus",
      type: "primary"
    },
    on: {
      click: _vm.buCha
    }
  }, [_vm._v(" 补差 ")]) : _vm._e(), _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: _vm.deleteMethod
    }
  }, [_vm._v(" 删除 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.handleEdit
    }
  }, [_vm._v("编辑")])], 1)]), _c("a-row", [_c("a-table", {
    attrs: {
      "data-source": _vm.getSheBao(1),
      pagination: false,
      "row-key": "id",
      columns: _vm.onlySocAmount ? _vm.columnsOnlySoc : _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "cardinalityDefault",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.onlySocAmount ? record.cardinalityDefaultAmount : text))]);
      }
    }, {
      key: "amountCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getCompanyAmount(_vm.groupItemDefault, record)))]);
      }
    }, {
      key: "amountPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getPersonAmount(_vm.GgroupItemDefault, record)))]);
      }
    }, {
      key: "persentCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(record.persentCompany) + "%")]);
      }
    }, {
      key: "persentPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(record.persentPersonal) + "%")]);
      }
    }, {
      key: "mantissaProcCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getMantissa(record.mantissaProcCompany)))]);
      }
    }, {
      key: "mantissaProcPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getMantissa(record.mantissaProcPersonal)))]);
      }
    }])
  }), _c("div", {
    staticClass: "header-title-box",
    staticStyle: {
      "margin-top": "70px"
    }
  }, [_c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("医保")])])]), _c("a-table", {
    attrs: {
      "data-source": _vm.getSheBao(3),
      pagination: false,
      "row-key": "id",
      columns: _vm.onlySocAmount ? _vm.columnsOnlySoc : _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "cardinalityDefault",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.onlySocAmount ? record.cardinalityDefaultAmount : text))]);
      }
    }, {
      key: "amountCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getCompanyAmount(_vm.groupItemDefault, record)))]);
      }
    }, {
      key: "amountPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getPersonAmount(_vm.GgroupItemDefault, record)))]);
      }
    }, {
      key: "persentCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(record.persentCompany) + "%")]);
      }
    }, {
      key: "persentPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(record.persentPersonal) + "%")]);
      }
    }, {
      key: "mantissaProcCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getMantissa(record.mantissaProcCompany)))]);
      }
    }, {
      key: "mantissaProcPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getMantissa(record.mantissaProcPersonal)))]);
      }
    }])
  }), _c("div", {
    staticClass: "totalBox"
  }, [_c("div", {
    staticClass: "company"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("社保单位总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getCompanyAll()))])]), _c("div", {
    staticClass: "person"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("社保个人总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPersonAll()))])])])], 1), !_vm.jijinShow ? _c("a-row", [_c("a-row", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm._v("公积金")])]), _c("a-row", [_c("a-table", {
    attrs: {
      "data-source": _vm.jijin,
      pagination: false,
      "row-key": "id",
      columns: _vm.onlySocAmount ? _vm.columnsOnlySoc : _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "cardinalityDefault",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.onlySocAmount ? record.cardinalityDefaultAmount : text))]);
      }
    }, {
      key: "amountCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getCompanyAmount(_vm.groupItemDefault2, record)))]);
      }
    }, {
      key: "amountPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getPersonAmount(_vm.GgroupItemDefault2, record)))]);
      }
    }, {
      key: "persentCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(record.persentCompany) + "%")]);
      }
    }, {
      key: "persentPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(record.persentPersonal) + "%")]);
      }
    }, {
      key: "mantissaProcCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getMantissa(record.mantissaProcCompany)))]);
      }
    }, {
      key: "mantissaProcPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getMantissa(record.mantissaProcPersonal)))]);
      }
    }], null, false, 1207810861)
  }), _c("div", {
    staticClass: "totalBox"
  }, [_c("div", {
    staticClass: "company"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("公积金单位总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfCompanyAll()))])]), _c("div", {
    staticClass: "person"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("公积金个人总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfPersonAll()))])])])], 1)], 1) : _vm._e(), _c("a-divider", {
    attrs: {
      dashed: ""
    }
  }), _c("a-row", [_c("div", {
    staticClass: "totalBox"
  }, [_c("div", {
    staticClass: "company"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("单位总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfCompanyAll2()))])]), _c("div", {
    staticClass: "person"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("个人总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfPersonAll2()))])])])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };