import { formatDateAll } from "@/utils/index";
import { getNewDetail as _getNewDetail } from "@/api/company/news";
export default {
  name: "Detail",
  filters: {
    formatDateAll: formatDateAll
  },
  data: function data() {
    return {
      newId: "",
      currentNew: "",
      companyId: ""
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.newId = this.$route.query.id;
    this.getNewDetail(this.newId);
  },
  methods: {
    goback: function goback() {
      this.$router.go(-1);
    },
    getNewDetail: function getNewDetail(newId) {
      var _this = this;
      _getNewDetail({
        newsTypeId: newId
      }).then(function (res) {
        _this.currentNew = res.data;
      });
    }
  }
};