var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      width: 820,
      title: "添加打卡地点"
    },
    on: {
      ok: _vm.handerOk
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("map-drag", {
    attrs: {
      lat: "22.574405",
      lng: "114.095388"
    },
    on: {
      drag: _vm.dragMap
    }
  }), _c("ul", {
    staticClass: "info"
  }, [_c("li", [_c("span", [_vm._v("经度：")]), _vm._v(_vm._s(_vm.dragData.lng))]), _c("li", [_c("span", [_vm._v("纬度：")]), _vm._v(_vm._s(_vm.dragData.lat))]), _c("li", [_c("span", [_vm._v("地址：")]), _vm._v(_vm._s(_vm.dragData.address))]), _c("li", [_c("span", [_vm._v("最近的路口：")]), _vm._v(_vm._s(_vm.dragData.nearestJunction))]), _c("li", [_c("span", [_vm._v("最近的路：")]), _vm._v(_vm._s(_vm.dragData.nearestRoad))]), _c("li", [_c("span", [_vm._v("最近的POI：")]), _vm._v(_vm._s(_vm.dragData.nearestPOI))]), _c("li", [_c("span", [_vm._v("打卡距离：")]), _c("a-input-number", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      min: 0,
      placeholder: "打卡距离"
    },
    model: {
      value: _vm.dragData.distance,
      callback: function callback($$v) {
        _vm.$set(_vm.dragData, "distance", $$v);
      },
      expression: "dragData.distance"
    }
  }), _vm._v(" 米 ")], 1)])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };