var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "titBox"
  }, [_c("div", {
    staticClass: "line"
  }), _c("div", {
    staticClass: "font"
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };