import request from "@/utils/request";

// 下发合同模版到公司
export function saveIssuedCategory(data) {
  return request({
    // url: "/contract/api/v1/categories/saveIssuedCategory",
    url: "/hr/api/v1/contracts/saveIssuedCategory",
    method: "POST",
    data: data
  });
}

// 查询合同模板,树状
export function selectTreeBySubjectId(data) {
  return request({
    url: "/contract/api/v1/categories/selectTreeBySubjectId",
    method: "GET",
    params: data
  });
}

// 是否人脸识别
export function getSelectBySiteId(data) {
  return request({
    url: "/contract/api/v1/contractConfigSites/selectBySiteId",
    method: "GET",
    params: data
  });
}
export function checkTemplateByCategoryIdReq(categoryId) {
  return request({
    url: "/contract/api/v1/categories/checkTemplateByCategoryId/".concat(categoryId),
    method: "get",
    noNotification: true
  });
}