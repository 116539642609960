import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-spin", {
    attrs: {
      spinning: _vm.uploadSpinning,
      tip: "数据保存中..."
    }
  }, [_vm.alertiVisible ? _c("a-alert", {
    staticStyle: {
      margin: "24px",
      "background-color": "#fff1f0"
    },
    attrs: {
      type: "error",
      message: this.dataJson.errorMessage,
      "show-icon": "",
      closable: "",
      "after-close": _vm.handleClose,
      "close-text": "导出错误数据"
    }
  }) : _c("a-alert", {
    staticStyle: {
      margin: "24px"
    },
    attrs: {
      message: this.dataJson.errorMessage,
      type: "success",
      "show-icon": ""
    }
  }), _c("div", {
    staticClass: "xq-form-wrapper pbox"
  }, [_c("a-spin", {
    staticClass: "xq-form-content",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [_c("div", {
            key: index
          }, [_c("a-popover", {
            attrs: {
              title: "数据错误",
              placement: "right"
            }
          }, [_c("template", {
            slot: "content"
          }, [_c("p", [_vm._v(_vm._s(record.errorReason))])]), col.dataIndex === record.typeName ? _c("div", {
            staticClass: "triangle"
          }) : _vm._e()], 2), ["email", "wechat", "companyName", "companyAddress"].includes(col.dataIndex) ? _c("span", [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [_c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(text))])]), _c("div", {
            staticClass: "overflows"
          }, [_vm._v(" " + _vm._s(text) + " ")])], 2)], 1) : _c("span", [_vm._v(" " + _vm._s(text) + " ")])], 1)];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "preview-btn"
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("onAction", 0);
      }
    }
  }, [_vm._v("返回")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(" 下一步 ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };