import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    staticClass: "page-miH",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_vm.mode === "company" ? _c("TableTitle", {
    staticStyle: {
      padding: "0",
      height: "32px",
      "line-height": "32px",
      margin: "4px 0 16px"
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm._v("签署记录")]), _c("template", {
    slot: "action"
  }, [_vm.selectTypeIndex === 2 ? [_c("a-button", {
    attrs: {
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: _vm.signBatch
    }
  }, [_vm._v("批量签署")]), _c("a-divider", {
    staticStyle: {
      margin: "0 16px"
    },
    attrs: {
      type: "vertical"
    }
  })] : _vm._e(), _c("a-button", {
    attrs: {
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: function click($event) {
        return _vm.deleteContract();
      }
    }
  }, [_vm._v("批量删除")]), _c("a-divider", {
    staticStyle: {
      margin: "0 16px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a", {
    on: {
      click: _vm.goSetting
    }
  }, [_c("a-icon", {
    attrs: {
      type: "setting",
      theme: "filled"
    }
  }), _vm._v("设置 ")], 1)], 2)], 2) : _vm._e(), _c("div", {
    staticClass: "contract-wrapper"
  }, [_vm.mode === "company" ? _c("SummaryBarElectronic", {
    ref: "summaryBar",
    attrs: {
      selectTypeIndex: _vm.selectTypeIndex
    },
    on: {
      typeChange: _vm.selectFunc
    }
  }) : _vm._e(), _c("TableBody", {
    class: _vm.mode === "company" ? "mt24" : "",
    attrs: {
      selectHead: "",
      selection: "",
      tableName: _vm.mode === "overview" ? "eleContractOV" : "eleContract",
      selectedHead: _vm.selectedHead,
      selectedRowKeys: _vm.selectedRowKeys,
      source: _vm.contractList,
      headerRowID: "sortable_list",
      columns: _vm.columns
    },
    on: {
      "update:selectedHead": function updateSelectedHead($event) {
        _vm.selectedHead = $event;
      },
      "update:selected-head": function updateSelectedHead($event) {
        _vm.selectedHead = $event;
      },
      "update:selectedRowKeys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      "update:selected-row-keys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      onClose: function onClose() {},
      onSearch: function onSearch($event) {
        return _vm.selectFunc();
      },
      onReload: function onReload($event) {
        return _vm.selectFunc(_vm.selectTypeIndex);
      },
      onExport: function onExport() {
        return _vm.selectDownloadType(1);
      },
      onExportAll: function onExportAll() {
        return _vm.selectDownloadType(2);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(prop) {
        return [_c("a-table", {
          class: prop.className,
          attrs: {
            components: prop.components,
            columns: prop.columns,
            scroll: {
              x: prop.scrollWidth
            },
            customHeaderRow: function customHeaderRow() {
              return {
                domProps: {
                  id: prop.headerRowID
                }
              };
            },
            "data-source": _vm.contractList,
            "row-key": "id",
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange
            },
            pagination: _vm.pagination
          },
          on: {
            change: _vm.handleTableChange
          },
          scopedSlots: _vm._u([{
            key: "name",
            fn: function fn(text, record) {
              return _c("span", {}, [_c("a-tooltip", {
                attrs: {
                  placement: "bottom"
                }
              }, [text ? _c("template", {
                slot: "title"
              }, [_c("span", [_vm._v(_vm._s(text))])]) : _vm._e(), _c("div", {
                staticClass: "contractNoOver"
              }, [_vm._v(" " + _vm._s(text ? text : "--") + " ")])], 2), _vm.mode === "company" ? _c("a-icon", {
                staticClass: "ml8",
                staticStyle: {
                  color: "#e8e8e8",
                  "font-size": "18px"
                },
                attrs: {
                  type: "form"
                },
                on: {
                  click: function click($event) {
                    return _vm.updateName(record);
                  }
                }
              }) : _vm._e()], 1);
            }
          }, {
            key: "fileType",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.customRenderContractType(text)))]);
            }
          }, {
            key: "publishDate",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.customRenderMoment(text)))]);
            }
          }, {
            key: "signDate",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.customRenderMoment(text)))]);
            }
          }, {
            key: "endDate",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.customRenderMoment(text)))]);
            }
          }, {
            key: "empSignDate",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.customRenderMoment(text)))]);
            }
          }, {
            key: "signFinishDate",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.customRenderMoment(text)))]);
            }
          }, {
            key: "signNo",
            fn: function fn(text) {
              return _c("span", {}, [_c("span", {
                staticStyle: {
                  "font-size": "12px"
                }
              }, [_vm._v(_vm._s(text ? text : "--"))])]);
            }
          }, {
            key: "isView",
            fn: function fn(text) {
              return _c("span", {}, [text ? _c("span", {
                staticClass: "fs12 ta-center",
                staticStyle: {
                  display: "inline-block",
                  background: "rgba(50, 204, 170, 0.12)",
                  "border-radius": "2px",
                  width: "48px",
                  height: "22px",
                  "line-height": "22px",
                  color: "rgba(17, 174, 139, 1)"
                }
              }, [_vm._v("已查看")]) : _c("span", {
                staticClass: "fs12 ta-center",
                staticStyle: {
                  display: "inline-block",
                  background: "rgba(126, 126, 126, 0.12)",
                  "border-radius": "2px",
                  width: "48px",
                  height: "22px",
                  "line-height": "22px",
                  color: "rgba(132, 132, 132, 1)"
                }
              }, [_vm._v("未查看")])]);
            }
          }, {
            key: "dateline",
            fn: function fn(text) {
              return _c("span", {}, [_c("span", {
                staticStyle: {
                  "font-size": "12px"
                }
              }, [_vm._v(_vm._s(text ? text % 12 === 0 ? text / 12 + "年" : text + "月" : "--"))])]);
            }
          }, {
            key: "contractNo",
            fn: function fn(text, record) {
              return _c("span", {}, [_c("a-tooltip", {
                attrs: {
                  placement: "bottom"
                }
              }, [text ? _c("template", {
                slot: "title"
              }, [_c("span", [_vm._v(_vm._s(text))])]) : _vm._e(), _c("div", {
                staticClass: "contractNoOver"
              }, [_vm._v(_vm._s(text ? text : "--"))])], 2), _vm.mode === "company" ? _c("a-icon", {
                staticClass: "ml8",
                staticStyle: {
                  color: "#e8e8e8",
                  "font-size": "18px"
                },
                attrs: {
                  type: "form"
                },
                on: {
                  click: function click($event) {
                    return _vm.updateContactNumber(record);
                  }
                }
              }) : _vm._e()], 1);
            }
          }, {
            key: "status",
            fn: function fn(text) {
              return _c("span", {}, [_c("span", {
                class: text === 3 ? "color2294FF" : text === 2 ? "colorFAC04A" : "colorC0C0C0"
              }, [_vm._v("•")]), _vm._v(" " + _vm._s(text === 1 ? "待员工签署" : text === 2 ? "待公司签署" : text === -1 ? "合同过期未签署" : "签署完成") + " ")]);
            }
          }, {
            key: "partyBName",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text))]);
            }
          }, {
            key: "remainingValidTime",
            fn: function fn(text) {
              return _c("span", {}, [_c("span", {
                class: text === 3 ? "color2294FF" : text === 2 ? "colorFAC04A" : "colorC0C0C0"
              }, [text <= 30 && text >= 0 && text !== null ? _c("a-icon", {
                style: {
                  fontSize: "16px",
                  color: "#FA5051"
                },
                attrs: {
                  type: "exclamation-circle"
                }
              }) : _vm._e()], 1), text >= 0 && text !== null ? _c("span", {
                style: text <= 30 && text >= 0 && text !== null ? "color: #FA5051" : ""
              }, [_vm._v(_vm._s(text) + " 天")]) : text === null ? _c("span", {
                staticStyle: {
                  color: "#909090"
                }
              }, [_vm._v("--")]) : _c("span", {
                staticStyle: {
                  color: "#909090"
                }
              }, [_vm._v("已到期")])]);
            }
          }, {
            key: "appIsShow",
            fn: function fn(text, record) {
              return _c("span", {}, [_c("a-switch", {
                attrs: {
                  "checked-children": "是",
                  "un-checked-children": "否",
                  disabled: _vm.mode !== "company",
                  "default-checked": record.appIsShow
                },
                on: {
                  change: function change($event) {
                    return _vm.changeUsed($event, record);
                  }
                }
              })], 1);
            }
          }, {
            key: "action",
            fn: function fn(text, record) {
              return _c("span", {}, [_c("span", {
                staticClass: "flex-center",
                style: {
                  width: _vm.selectTypeIndex === 2 && _vm.mode === "company" ? 120 : 80
                }
              }, [_vm.mode === "company" ? [_vm.selectTypeIndex === 2 ? _c("a", {
                directives: [{
                  name: "onceClick",
                  rawName: "v-onceClick"
                }],
                staticClass: "edit",
                attrs: {
                  type: "link"
                },
                on: {
                  click: function click($event) {
                    return _vm.confirmSign(record);
                  }
                }
              }, [_vm._v("确认签署")]) : _vm._e(), _vm.selectTypeIndex === 2 ? _c("a-divider", {
                attrs: {
                  type: "vertical"
                }
              }) : _vm._e()] : _vm._e(), _c("a", {
                staticClass: "edit",
                attrs: {
                  type: "link"
                },
                on: {
                  click: function click($event) {
                    return _vm.check(record);
                  }
                }
              }, [_vm._v("预览")])], 2)]);
            }
          }], null, true)
        })];
      }
    }])
  }, [_c("template", {
    slot: "search"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      allowClear: true,
      placeholder: "姓名/手机号/合同名称"
    },
    on: {
      search: _vm.searchContract
    },
    model: {
      value: _vm.contractNameOrNoOrSignNo,
      callback: function callback($$v) {
        _vm.contractNameOrNoOrSignNo = $$v;
      },
      expression: "contractNameOrNoOrSignNo"
    }
  }), _vm.mode === "overview" ? _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择合同状态"
    },
    on: {
      select: _vm.selectFunc
    },
    model: {
      value: _vm.selectTypeIndex,
      callback: function callback($$v) {
        _vm.selectTypeIndex = $$v;
      },
      expression: "selectTypeIndex"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 4
    }
  }, [_vm._v("全部合同")]), _c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("待员工签署")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v("待公司签署")]), _c("a-select-option", {
    attrs: {
      value: 3
    }
  }, [_vm._v("签署完成")]), _c("a-select-option", {
    attrs: {
      value: -1
    }
  }, [_vm._v("合同过期未签署")]), _c("a-select-option", {
    attrs: {
      value: 5
    }
  }, [_vm._v("即将到期")]), _c("a-select-option", {
    attrs: {
      value: 6
    }
  }, [_vm._v("已到期")])], 1) : _vm._e(), _vm.mode === "company" ? [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: true,
      placeholder: "请选择合同类型"
    },
    on: {
      change: _vm.searchContract
    },
    model: {
      value: _vm.fileType,
      callback: function callback($$v) {
        _vm.fileType = $$v;
      },
      expression: "fileType"
    }
  }, _vm._l(_vm.enumerateList, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: true,
      placeholder: "请选择员工状态"
    },
    on: {
      change: _vm.searchContract
    },
    model: {
      value: _vm.empStatus,
      callback: function callback($$v) {
        _vm.empStatus = $$v;
      },
      expression: "empStatus"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("待入职")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("在职")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("待离职")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("离职")])], 1)] : [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: true,
      label: "合同类型",
      placeholder: "请选择合同类型"
    },
    model: {
      value: _vm.fileType,
      callback: function callback($$v) {
        _vm.fileType = $$v;
      },
      expression: "fileType"
    }
  }, _vm._l(_vm.enumerateList, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: true,
      label: "员工状态",
      placeholder: "请选择员工状态"
    },
    model: {
      value: _vm.empStatus,
      callback: function callback($$v) {
        _vm.empStatus = $$v;
      },
      expression: "empStatus"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("待入职")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("在职")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("待离职")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("离职")])], 1)], _c("a-input-search", {
    attrs: {
      label: "身份证号",
      allowClear: true,
      placeholder: "身份证号"
    },
    model: {
      value: _vm.idCard,
      callback: function callback($$v) {
        _vm.idCard = $$v;
      },
      expression: "idCard"
    }
  }), _vm.mode === "overview" ? _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: true,
      label: "用工单位",
      placeholder: "请选择用工单位"
    },
    model: {
      value: _vm.tempSubject,
      callback: function callback($$v) {
        _vm.tempSubject = $$v;
      },
      expression: "tempSubject"
    }
  }, _vm._l(_vm.tempSubjectOptions, function (subItem, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: subItem.value
      }
    }, [_vm._v(" " + _vm._s(subItem.label) + " ")]);
  }), 1) : _vm._e(), _c("a-range-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      allowClear: true,
      placeholder: ["合同开始时间", "合同开始时间"],
      label: "合同开始时间",
      format: "YYYY-MM-DD"
    },
    model: {
      value: _vm.publishDateRange,
      callback: function callback($$v) {
        _vm.publishDateRange = $$v;
      },
      expression: "publishDateRange"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1), _c("a-range-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      allowClear: true,
      placeholder: ["合同结束时间", "合同结束时间"],
      label: "合同结束时间",
      format: "YYYY-MM-DD"
    },
    model: {
      value: _vm.endDateRange,
      callback: function callback($$v) {
        _vm.endDateRange = $$v;
      },
      expression: "endDateRange"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1), _c("a-range-picker", {
    staticStyle: {
      width: "320px"
    },
    attrs: {
      allowClear: true,
      placeholder: ["合同签署完成时间", "合同签署完成时间"],
      label: "合同签署完成时间",
      format: "YYYY-MM-DD"
    },
    model: {
      value: _vm.signFinishDateRange,
      callback: function callback($$v) {
        _vm.signFinishDateRange = $$v;
      },
      expression: "signFinishDateRange"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: true,
      label: "员工是否已查看",
      placeholder: "员工是否已查看"
    },
    model: {
      value: _vm.isView,
      callback: function callback($$v) {
        _vm.isView = $$v;
      },
      expression: "isView"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("已查看")]), _c("a-select-option", {
    attrs: {
      value: "0"
    }
  }, [_vm._v("未查看")])], 1)], 2)], 2), _c("a-modal", {
    attrs: {
      title: _vm.exportContractTitle,
      footer: ""
    },
    model: {
      value: _vm.editVisible,
      callback: function callback($$v) {
        _vm.editVisible = $$v;
      },
      expression: "editVisible"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v("*请选择合同导出样式")]), _c("div", {
    staticClass: "staff-top-dialog"
  }, [_c("a-button", {
    staticClass: "staff-one",
    on: {
      click: _vm.pdf_export
    }
  }, [_c("div", {
    staticClass: "staff-top-dialog-item"
  }, [_c("div", [_vm._v("导出电子合同PDF文件")]), _c("img", {
    staticStyle: {
      width: "18px",
      height: "18px"
    },
    attrs: {
      src: require("@/assets/img/arrow.png")
    }
  })])]), _c("a-button", {
    staticClass: "staff-one",
    on: {
      click: _vm.excel_export
    }
  }, [_c("div", {
    staticClass: "staff-top-dialog-item"
  }, [_c("div", [_vm._v("导出电子合同详情列表")]), _c("img", {
    staticStyle: {
      width: "18px",
      height: "18px"
    },
    attrs: {
      src: require("@/assets/img/arrow.png")
    }
  })])])], 1)]), _c("a-modal", {
    attrs: {
      title: "预览",
      "confirm-loading": _vm.confirmLoading,
      visible: _vm.detailVisible,
      width: "800px"
    },
    on: {
      ok: function ok($event) {
        ;
        _vm.detailVisible = false, _vm.eleContractDetailShow = false;
      },
      cancel: function cancel($event) {
        ;
        _vm.detailVisible = false, _vm.eleContractDetailShow = false;
      }
    }
  }, [_c("div", {
    staticClass: "flex-start-center"
  }, [_vm.eleContractDetailShow ? _c("iframe", {
    staticClass: "checkbox",
    staticStyle: {
      width: "700px",
      height: "70vh",
      overflow: "auto"
    },
    attrs: {
      src: "".concat(_vm.eleContractDetail.path, "?token=").concat(_vm.auth.token)
    }
  }) : _vm._e()])]), _c("a-modal", {
    attrs: {
      title: "编辑合同备注",
      visible: _vm.updateVisible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.confirmUpdateContactNumber,
      cancel: _vm.handleCancel
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      form: _vm.updateForm,
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 16
      },
      colon: true
    }
  }, [_c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      label: "合同名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name"],
      expression: "['name']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请输入",
      allowClear: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "合同备注"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["contractNo"],
      expression: "['contractNo']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请输入",
      allowClear: "",
      maxLength: 255,
      "auto-size": {
        minRows: 8,
        maxRows: 15
      }
    }
  })], 1)], 1)], 1)]), _c("a-modal", {
    attrs: {
      title: "编辑合同名称",
      visible: _vm.updateNameVisible,
      "confirm-loading": _vm.confirmNameLoading
    },
    on: {
      ok: _vm.confirmUpdateName,
      cancel: _vm.handleNameCancel
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      form: _vm.updateNameForm,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      },
      colon: true
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "已签署的合同名称不能为空"
        }]
      }],
      expression: "[\n                'name',\n                {\n                  rules: [{ required: true, message: '已签署的合同名称不能为空' }]\n                }\n              ]"
    }],
    attrs: {
      maxLength: 80
    }
  })], 1)], 1)], 1)]), _c("a-modal", {
    staticClass: "notice-modal",
    attrs: {
      title: "删除合同",
      visible: _vm.deleteVisible,
      width: "468px",
      okButtonProps: {
        props: {
          disabled: _vm.loading_delete
        }
      }
    },
    on: {
      ok: _vm.confirmDelete,
      cancel: function cancel($event) {
        _vm.deleteVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "deleteline"
  }, [_c("p", [_c("a-icon", {
    attrs: {
      type: "exclamation-circle"
    }
  }), _vm._v("确定删除该合同？")], 1)])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };