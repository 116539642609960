import "core-js/modules/es.array.push.js";
import { getdetail, editRule } from "@/api/wages/set";
var taxRule = ["工资薪金所得", "劳动报酬所得", "不计税"];
export default {
  data: function data() {
    return {
      dataDetail: {},
      taxRule: taxRule,
      deduction: 1,
      selected: 1,
      companyId: "",
      taxRuleId: "",
      options: []
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.init();
  },
  methods: {
    goDetail: function goDetail() {
      this.$router.push({
        path: "/wages/set",
        query: {
          tabs: "1"
        }
      });
    },
    toDetail: function toDetail() {
      window.open("https://www.yuque.com/docs/share/b7da4e77-4ba1-40e1-8622-d5e4206ed877?# 《2019年最新税制》");
    },
    init: function init() {
      var _this = this;
      this.taxRuleId = this.$route.query.taxRuleId;
      getdetail(this.taxRuleId).then(function (res) {
        _this.dataDetail = res.data;
        _this.deduction = res.data.deduction;
        _this.selected = res.data.taxCycle;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    saveRule: function saveRule() {
      var _this2 = this;
      var data = {
        companyId: this.companyId,
        deduction: this.deduction,
        taxCycle: this.selected,
        id: this.taxRuleId
      };
      if (this.selected === 2) {
        delete data.deduction;
      }
      editRule(data).then(function (res) {
        _this2.$router.push({
          path: "/wages/set"
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};