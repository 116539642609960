import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { getDepartmentInTree } from "@/api/department/department";
export default {
  name: "Search",
  props: {
    isShow: {
      type: Boolean,
      default: function _default() {
        false;
      }
    },
    name: {
      type: String,
      default: function _default() {}
    },
    dep: {
      type: String,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      names: this.name,
      deps: this.dep,
      treeData: []
    };
  },
  created: function created() {
    this.getDep();
  },
  methods: {
    payslip: function payslip() {
      this.$emit("payslipOk", {
        name: undefined,
        dep: undefined
      });
    },
    // 刷新
    reload: function reload() {
      this.names = undefined;
      this.deps = undefined;
      this.$emit("change", {
        name: undefined,
        dep: undefined
      });
    },
    // 搜索员工
    onSearch: function onSearch(e) {
      this.$emit("change", {
        name: this.names,
        dep: this.deps
      });
      this.$emit("input", {
        name: this.names,
        dep: this.deps
      });
    },
    // 选择部门
    handleChange: function handleChange(e) {
      this.$emit("change", {
        name: this.names,
        dep: this.deps
      });
    },
    // 获取部门
    getDep: function getDep() {
      var _this = this;
      getDepartmentInTree({
        companyId: this.$store.state.hr.companyId
      }).then(function (_ref) {
        var data = _ref.data;
        var child = data[0].depChildList || [];
        _this.treeData = _this.deep(child);
      });
    },
    deep: function deep(data) {
      var _this2 = this;
      data.forEach(function (key) {
        key.key = key.id;
        key.value = key.id;
        key.title = key.name;
        key.children = key.depChildList;
        if (key.depChildList) {
          _this2.deep(key.depChildList);
        }
      });
      return data;
    }
  }
};