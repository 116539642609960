var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm._m(0), _c("div", [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      scroll: {
        x: 950
      },
      "data-source": _vm.ruleList,
      "row-key": "id",
      loading: _vm.loading,
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function fn(text) {
        return _c("span", {}, [_vm._v(_vm._s(text === 3 ? "-" : _vm.taxRule[text - 1]))]);
      }
    }, {
      key: "isCalculate",
      fn: function fn(text) {
        return _c("span", {}, [_vm._v(_vm._s(text ? "是" : "否"))]);
      }
    }, {
      key: "threshold",
      fn: function fn(text) {
        return _c("span", {}, [_vm._v(_vm._s(text === 0 ? "-" : "".concat(text, "/\u6708")))]);
      }
    }, {
      key: "taxResult",
      fn: function fn(text) {
        return _c("span", {}, [_vm._v(_vm._s(text ? text : "-"))]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("span", {
          staticStyle: {
            "margin-left": "-15px"
          }
        }, [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.enterDetail(record.id, record.type);
            }
          }
        }, [_vm._v(" " + _vm._s(record.type === 1 ? "编辑" : "查看") + " ")])], 1);
      }
    }])
  })], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wage-set-group"
  }, [_c("div", {
    staticClass: "top-wrapper"
  }, [_c("div", {
    staticClass: "wage-top-title"
  }, [_vm._v("使用指南")]), _c("div", {
    staticClass: "wage-top-explain"
  }, [_c("div", [_vm._v("1、计税规则，由系统统一提供。请到【薪资组】关联人员，计薪规则及适用的个税规则。")]), _c("div", [_vm._v("2、核算工资时，按员工适用的个税方案，自动计算出当月个税金额。")])])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };