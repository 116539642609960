import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("div", {
    staticClass: "r-msg-status"
  }, _vm._l(_vm.numArr, function (item, index) {
    return _c("div", {
      key: item.type,
      staticClass: "s-item"
    }, [_c("div", {
      staticClass: "s-item-t"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "s-item-b"
    }, [_vm._v(_vm._s(item.num || 0))]), index != _vm.numArr.length - 1 ? _c("div", {
      staticClass: "s-item-line"
    }) : _vm._e()]);
  }), 0), _c("div", {
    staticClass: "xq-form-search"
  }, [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "姓名/身份证号/手机号"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.jobSeekerNameOrCode,
      callback: function callback($$v) {
        _vm.jobSeekerNameOrCode = $$v;
      },
      expression: "jobSeekerNameOrCode"
    }
  }), _c("div", {
    staticStyle: {
      "text-align": "right",
      "margin-top": "-32px"
    }
  }, [_c("a-space", [_c("a-button", {
    staticClass: "mr2",
    on: {
      click: function click($event) {
        _vm.batchSelectVisible = true;
      }
    }
  }, [_vm._v(" 同步经纪人 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: _vm.onAdd
    }
  }, [_vm._v(" 新建经纪人 ")])], 1)], 1)], 1), _c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      "row-selection": _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      } : null
    },
    on: {
      change: _vm.onTableChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "name" ? _c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(text) + " ")]) : col.dataIndex === "gmtCreate" ? _c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(text ? _vm.moment(text).format("YYYY-MM-DD") : "") + " ")]) : col.dataIndex === "sourceType" ? _c("span", {
            key: index
          }, [text == 1 ? _c("span", [_vm._v("手动添加")]) : _vm._e(), text == 2 ? _c("span", [_vm._v("同步数据")]) : _vm._e()]) : col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("a", {
            on: {
              click: function click($event) {
                return _vm.onMsg(record.brokerId);
              }
            }
          }, [_vm._v("推荐详情")]), _c("tableActionPoint", {
            staticStyle: {
              "vertical-align": "bottom"
            }
          }, [_c("li", {
            class: record.sourceType == 2 ? "economic-action-eli" : ""
          }, [_c("a", {
            staticStyle: {
              width: "100%",
              height: "100%",
              display: "inline-block",
              color: "rgba(0, 0, 0, 0.65)"
            },
            style: record.sourceType == 2 ? "color: rgba(0, 0, 0, 0.25) !important;" : "",
            attrs: {
              disabled: record.sourceType == 2
            },
            on: {
              click: function click($event) {
                return _vm.onEdit(record);
              }
            }
          }, [_vm._v("编辑")])]), _c("li", {
            on: {
              click: function click($event) {
                return _vm.onDelete(record);
              }
            }
          }, [_vm._v(" 删除 ")])])], 1) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1), _c("add", {
    attrs: {
      visible: _vm.visible,
      dataJson: _vm.dataJson
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      upDate: _vm.upDate
    }
  }), _c("msg", {
    attrs: {
      visibleMsg: _vm.visibleMsg,
      brokerId: _vm.brokerId
    },
    on: {
      "update:visibleMsg": function updateVisibleMsg($event) {
        _vm.visibleMsg = $event;
      },
      "update:visible-msg": function updateVisibleMsg($event) {
        _vm.visibleMsg = $event;
      }
    }
  }), _c("batchSelectModal", {
    attrs: {
      visible: _vm.batchSelectVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.batchSelectVisible = $event;
      },
      upDate: _vm.onGetBrokerPageById
    }
  }), _c("delModal", {
    attrs: {
      visible: _vm.delVisible,
      title: "确定删除该经纪人吗？"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.delVisible = $event;
      },
      onOk: _vm.onOk
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };