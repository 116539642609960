var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper bac-f4f6fa"
  }, [_c("div", {
    staticClass: "enterprise-wrapper"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "flex-center-center flex-col"
  }, [_c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c("a-upload", {
    staticClass: "ant-upload",
    attrs: {
      "list-type": "picture-card",
      "show-upload-list": false,
      "before-upload": _vm.beforeUpload,
      accept: ".jpg, .jpeg, .png"
    }
  }, [_c("div", {
    staticClass: "xqjr-upload-avatar-inner"
  }, [_c("img", {
    staticClass: "xqjr-upload-avatar-img",
    attrs: {
      src: _vm.sitePicShow,
      alt: ""
    }
  }), _c("span", {
    staticClass: "xqjr-upload-avatar-mask"
  }, [_vm._v("点击更换")])])])], 1), _c("selectCompanyGroup", {
    staticClass: "mt16",
    attrs: {
      hasLeftIcon: true,
      siteIds: _vm.siteId,
      selectTreeId: _vm.selectTreeId,
      type: _vm.type
    },
    on: {
      "update:siteIds": function updateSiteIds($event) {
        _vm.siteId = $event;
      },
      "update:site-ids": function updateSiteIds($event) {
        _vm.siteId = $event;
      },
      "update:selectTreeId": function updateSelectTreeId($event) {
        _vm.selectTreeId = $event;
      },
      "update:select-tree-id": function updateSelectTreeId($event) {
        _vm.selectTreeId = $event;
      },
      "update:type": function updateType($event) {
        _vm.type = $event;
      }
    }
  }), _c("div", {
    staticClass: "company-details mt24 mr24 ml24 mb4"
  }, [_c("a-row", _vm._l(_vm.statisticsData.keyNameList, function (item) {
    return _c("a-col", {
      key: item.key,
      staticClass: "mb12",
      attrs: {
        span: 12
      }
    }, [_c("company-details", {
      attrs: {
        title: item.key,
        content: item.value
      },
      on: {
        contentClick: _vm.toYongGong
      }
    })], 1);
  }), 1), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("div", {
    staticClass: "details-button mt30 mb12",
    on: {
      click: _vm.toYuanGong
    }
  }, [_c("span", {
    staticClass: "content"
  }, [_vm._v("查看更多")])])])], 1), _c("div", {
    staticClass: "company-details mt12 mr24 ml24 mb12",
    staticStyle: {
      "padding-top": "12px",
      "padding-bottom": "12px"
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("company-details", {
    attrs: {
      imgUrl: require("@/assets/icons/hr_hetong.png"),
      title: "电子合同余量",
      content: _vm.statisticsData.siteContractLeftCount === -1 ? "∞" : _vm.statisticsData.siteContractLeftCount
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("company-details", {
    attrs: {
      imgUrl: require("@/assets/icons/hr_duanxin.png"),
      title: "短信余量",
      content: _vm.statisticsData.siteMessageLeftCount === -1 ? "∞" : _vm.statisticsData.siteMessageLeftCount
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "company-details mt12 mr24 ml24 mb12",
    staticStyle: {
      "padding-top": "12px",
      "padding-bottom": "12px",
      cursor: "pointer"
    },
    on: {
      click: _vm.showDetail
    }
  }, [_c("a-row", [_c("a-col", [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "row"
    }
  }, [_c("span", {
    staticClass: "icon iconfont icon-xinzizonglan"
  }), _c("div", {
    staticStyle: {
      "margin-left": "5px",
      "margin-top": "2px"
    }
  }, [_vm._v("账面余额")]), _c("div", {
    staticClass: "popover-contentSpan",
    staticStyle: {
      "margin-left": "10px",
      "margin-top": "4px"
    }
  }, [_vm._v("明细")])]), _c("div", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "18px",
      "margin-left": "10px",
      "margin-right": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.amount) + " ")])])], 1)], 1)], 1)]), _c("div", {
    staticClass: "right"
  }, [_c("right-details", {
    attrs: {
      siteId: _vm.siteId
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "余额明细",
      visible: _vm.bushouTable,
      width: "900px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", {
    staticClass: "headerTitle"
  }, [_c("a-range-picker", {
    ref: "date",
    staticClass: "mr20",
    attrs: {
      placeholder: ["开始月份", "结束月份"],
      format: "YYYY-MM",
      value: _vm.now,
      mode: _vm.mode2
    },
    on: {
      panelChange: _vm.handlePanelChange2,
      change: _vm.onMonthChange
    }
  }, [_c("template", {
    slot: "renderExtraFooter"
  }, [_c("a-button", {
    key: "submit",
    staticStyle: {
      "margin-left": "460px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.dateOk
    }
  }, [_vm._v(" 确定 ")])], 1)], 2), _c("a-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      placeholder: "请选择",
      allowClear: ""
    },
    on: {
      change: _vm.approStatusChange
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 客户回款计划 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 社保回款计划 ")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 渠道劳务费结算 ")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v(" 费用账单 ")])], 1)], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "0px"
    }
  }, [_c("a-form", [_c("a-table", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      columns: _vm.columnKuanxiang,
      "data-source": _vm.dataDetail2,
      scroll: {
        x: 700,
        y: 400
      },
      pagination: _vm.pagination,
      "row-key": "id"
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "gmtCreate",
      fn: function fn(text, item) {
        return [_c("span", [_vm._v(_vm._s(_vm.moment(item.gmtCreate).format("YYYY-MM-DD HH:mm:ss")))])];
      }
    }, {
      key: "changeType",
      fn: function fn(text, item) {
        return [item.deleteStatus ? _c("div", {
          staticStyle: {
            position: "relative"
          }
        }, [_c("img", {
          staticStyle: {
            position: "absolute",
            top: "-16px",
            left: "-15px",
            width: "40px"
          },
          attrs: {
            src: require("../../assets/img/删除.png"),
            alt: ""
          }
        })]) : _vm._e(), _c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(item.yearly + "年" + item.monthly + "月" + _vm.getType(item)))])]), _c("span", [_vm._v(_vm._s(item.yearly + "年" + item.monthly + "月" + _vm.getType(item)))])], 2)];
      }
    }, {
      key: "changeAmount",
      fn: function fn(text, item) {
        return [_c("div", {
          style: item.changeAmount > 0 ? "color:#FA5051" : "color:#24CDAA"
        }, [_vm._v(" " + _vm._s(text) + " ")])];
      }
    }])
  })], 1)], 1)], 2)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };