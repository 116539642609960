var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "bgc-white"
  }, [_c("a-row", [_c("div", {
    staticClass: "template-title"
  }, [_vm._v("员工字段设置")])]), _c("div", {
    staticClass: "boxBack"
  }, [_vm._v("员工字段设置")]), _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, _vm._l(_vm.fieldsData, function (item, index) {
    return _c("a-col", {
      key: index,
      staticClass: "mt24",
      attrs: {
        span: 8
      },
      on: {
        click: function click($event) {
          return _vm.goTemplate(item);
        }
      }
    }, [_c("div", {
      staticClass: "gutter-row border pointer ph12",
      staticStyle: {
        display: "flex"
      }
    }, [_c("div", {
      staticClass: "gutter-item",
      staticStyle: {
        width: "80px",
        height: "80px",
        "flex-shrink": "0",
        "margin-right": "12px"
      }
    }, [_c("img", {
      attrs: {
        src: item.url,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "pt54 pointer",
      staticStyle: {
        flex: "1"
      }
    }, [_vm._v(_vm._s(item.title))])])]);
  }), 1), _c("div", {
    staticClass: "boxBack mt24"
  }, [_vm._v("入职模板设置")]), _c("a-row", {
    staticClass: "gutter-box",
    staticStyle: {
      "margin-top": "5px"
    },
    attrs: {
      gutter: 24
    }
  }, _vm._l(_vm.fieldsData1, function (item, index) {
    return _c("a-col", {
      key: index,
      staticClass: "mt24",
      attrs: {
        span: 8
      },
      on: {
        click: function click($event) {
          return _vm.goTemplate(item);
        }
      }
    }, [index !== 2 ? _c("div", {
      staticClass: "gutter-row pointer ph12",
      class: index === 2 ? "" : "border",
      staticStyle: {
        display: "flex"
      }
    }, [_c("div", {
      staticClass: "gutter-item",
      staticStyle: {
        width: "80px",
        height: "80px",
        "flex-shrink": "0",
        "margin-right": "12px"
      }
    }, [_c("img", {
      attrs: {
        src: item.url,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "pt54 pointer",
      staticStyle: {
        flex: "1"
      }
    }, [_vm._v(_vm._s(item.title))])]) : _vm._e()]);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };