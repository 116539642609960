import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.slice.js";
import { getHead as _getHead, updateHead } from "@/api/company/index";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
var contData = [{
  value: 1,
  label: "花名册",
  img: require("@/assets/indexImg/huamingce.png"),
  url: "/staff/employee",
  premission: "company:people:list"
}, {
  value: 2,
  label: "月工资表",
  img: require("@/assets/indexImg/yuegongzibiao.png"),
  url: "/wages/monthly",
  premission: "company:salary:salary"
}, {
  value: 4,
  label: "发起合同",
  img: require("@/assets/indexImg/hetong@2x.png"),
  url: "/contract/type",
  premission: "company:contract:publish"
}, {
  value: 5,
  label: "社保管理",
  img: require("@/assets/indexImg/shebao@2x.png"),
  url: "/shebao/shebaoManage",
  premission: "company:soc:intend"
}, {
  value: 7,
  label: "招聘管理",
  img: require("@/assets/indexImg/zhaopin@2x.png"),
  url: "/recruitment/management",
  premission: "recruitment"
}, {
  value: 8,
  label: "薪资代发",
  img: require("@/assets/indexImg/xinzi@2x.png"),
  url: "/paySalary/applyFor",
  premission: "transferAccount"
}, {
  value: 6,
  label: "考勤打卡",
  img: require("@/assets/indexImg/yuegongzibiao@2x.png"),
  url: "/check/checkTable",
  premission: "company:attendance:report"
}, {
  value: 9,
  label: "业务审批",
  img: require("@/assets/indexImg/shenpi@2x.png"),
  url: "/approval/list",
  premission: "company:appro:list"
}, {
  value: 10,
  label: "CRM",
  img: require("@/assets/indexImg/crm.png"),
  url: "/clientele/myClientele",
  premission: "clientele"
}, {
  value: 11,
  label: "个税",
  img: require("@/assets/indexImg/shui.png"),
  url: "/taxCalculate/management",
  premission: "company:geshui"
}, {
  value: 12,
  label: "商业保险",
  img: require("@/assets/indexImg/shangbao.png"),
  url: "/shangbao",
  premission: "insuranceAddService:insuranceAddService.zaibaorenyuan"
}, {
  value: 13,
  label: "待遇处理",
  img: require("@/assets/indexImg/dycl.png"),
  url: "/daiyuchuli",
  premission: "dycl:"
}];
export default {
  components: {
    draggable: draggable
  },
  data: function data() {
    return {
      widHeight: 900,
      quickEdit: false,
      // 快捷入口数据
      entryList: [],
      drag: false,
      tempEntryListNet: [],
      entryListNet: []
    };
  },
  created: function created() {
    this.widHeight = window.screen.height;
    this.getHead();
  },
  computed: _objectSpread({}, mapGetters(["permissions_menu", "permissions"])),
  methods: {
    seveQuick: function seveQuick() {
      var _this = this;
      this.quickEdit = false;
      this.entryListNet = JSON.parse(JSON.stringify(this.tempEntryListNet));
      this.getEntryList();
      var see = [];
      for (var i = 0; i < this.entryListNet[0].length; i++) {
        see.push({
          fieldName: this.entryListNet[0][i].label,
          isEdit: false
        });
      }
      updateHead({
        id: "all",
        moduleCode: "shortcut",
        moduleType: "快捷入口",
        canSee: see,
        ignore: [] // 无用的字段
      }).then(function (res) {
        _this.$message.success("保存成功");
      });
    },
    showEdit: function showEdit() {
      this.quickEdit = true;
      this.tempEntryListNet = JSON.parse(JSON.stringify(this.entryListNet));
    },
    deleteSelectedHead: function deleteSelectedHead(index) {
      var temp = JSON.parse(JSON.stringify(this.tempEntryListNet[0][index]));
      this.tempEntryListNet[0].splice(index, 1);
      this.tempEntryListNet[1].push(temp);
    },
    addSelectedHead: function addSelectedHead(index) {
      if (this.tempEntryListNet[0].length >= 6) {
        this.$message.success("已选择模块，最多6个！");
        return;
      }
      var temp = JSON.parse(JSON.stringify(this.tempEntryListNet[1][index]));
      this.tempEntryListNet[1].splice(index, 1);
      this.tempEntryListNet[0].push(temp);
    },
    // 禁止拖动到id为1的对象
    onMove: function onMove(e) {
      if (e.relatedContext.element.title === "姓名") return false;
      return true;
    },
    // 开始拖拽事件
    onStart: function onStart() {
      this.drag = true;
    },
    // 拖拽结束事件
    onEnd: function onEnd() {
      this.drag = false;
    },
    // 根据返回权限显示快捷入口
    getEntryList: function getEntryList() {
      this.entryList = this.entryListNet[0];
      this.entryList = this.group(this.entryList, 3);
      // 补充不足的数量
      if (this.entryList.length !== 0) {
        for (var i = this.entryList[this.entryList.length - 1].length; i < 3; i++) {
          var empty = JSON.parse(JSON.stringify(this.entryList[this.entryList.length - 1][this.entryList[this.entryList.length - 1].length - 1]));
          empty.hide = 1;
          empty.value = empty.value + 1;
          this.entryList[this.entryList.length - 1].push(empty);
        }
      }
    },
    group: function group(array, subGroupLength) {
      var index = 0;
      var newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
    handleCancel: function handleCancel() {
      this.quickEdit = false;
    },
    permissionsCheck: function permissionsCheck(arry) {
      for (var i = arry.length - 1; i >= 0; i--) {
        if (JSON.stringify(this.permissions.permissionStringList).indexOf(arry[i].premission) === -1) {
          arry.splice(i, 1); // 从数组中删除没有权限的元素
        }
      }
    },
    getHead: function getHead() {
      var _this2 = this;
      _getHead({
        moduleCode: "shortcut"
      }).then(function (res) {
        // 默认数据
        var selected = [contData[0], contData[1], contData[2], contData[3], contData[4], contData[5]];
        var noselect = [];
        if (res.data) {
          // 存储数据
          if (res.data.canSee) {
            selected = [];
            for (var i = 0; i < res.data.canSee.length; i++) {
              _this2.mapUIData(selected, res.data.canSee[i].fieldName);
            }
          }
        }
        _this2.permissionsCheck(selected);
        _this2.entryListNet.push(selected);
        // 将剩余的所有添加到未选择列表里面
        for (i = 0; i < contData.length; i++) {
          var isExist = false;
          for (var j = 0; j < selected.length; j++) {
            if (selected[j].label === contData[i].label) {
              isExist = true;
              break;
            }
          }
          if (!isExist) {
            noselect.push(contData[i]);
          }
        }
        _this2.permissionsCheck(noselect);
        _this2.entryListNet.push(noselect);
        _this2.getEntryList();
      });
    },
    mapUIData: function mapUIData(arry, fieldName) {
      for (var i = 0; i < contData.length; i++) {
        if (contData[i].label === fieldName) {
          arry.push(contData[i]);
        }
      }
    },
    goEntry: function goEntry(val) {
      this.$emit("changeUrl", val);
    },
    getshow: function getshow(item) {
      return item.hide === undefined;
    }
  }
};