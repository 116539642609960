import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "f-box"
  }, [_c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        initialValue: _vm.defaultVal.name
      }],
      expression: "['name', { initialValue: defaultVal.name }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      allowClear: "",
      placeholder: "请输入",
      maxLength: 80
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同类型"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        initialValue: _vm.defaultVal.type || undefined
      }],
      expression: "['type', { initialValue: defaultVal.type || undefined }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "show-search": "",
      "filter-option": _vm.filterOption,
      allowClear: "",
      placeholder: "请选择"
    },
    on: {
      change: _vm.changeType
    }
  }, _vm._l(_vm.typeList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1), _vm.show ? _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同期限"
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["timeLimit", {
        initialValue: _vm.defaultVal.timeLimit || undefined
      }],
      expression: "[`timeLimit`, { initialValue: defaultVal.timeLimit || undefined }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "show-search": "",
      "filter-option": _vm.filterOption,
      allowClear: "",
      placeholder: "请选择"
    },
    on: {
      change: _vm.change1
    }
  }, _vm._l(12, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["timeLimitType", {
        initialValue: _vm.getTimeLimitType(_vm.defaultVal.timeLimitType)
      }],
      expression: "[\n                        'timeLimitType',\n                        {\n                          initialValue: getTimeLimitType(defaultVal.timeLimitType)\n                        }\n                      ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "show-search": "",
      "filter-option": _vm.filterOption,
      placeholder: "请选择"
    },
    on: {
      change: _vm.change3
    }
  }, _vm._l(_vm.timeList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同编号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["number", {
        initialValue: _vm.defaultVal.number
      }],
      expression: "[`number`, { initialValue: defaultVal.number }]"
    }],
    attrs: {
      placeholder: "请输入"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同开始时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["startTime", {
        initialValue: _vm.getMoment(_vm.defaultVal.startTime)
      }],
      expression: "['startTime', { initialValue: getMoment(defaultVal.startTime) }]"
    }],
    attrs: {
      disabledDate: _vm.disabledStartDate
    },
    on: {
      change: _vm.change2
    }
  })], 1)], 1), _vm.show ? _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同结束时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["endTime", {
        initialValue: _vm.getMoment(_vm.defaultVal.endTime)
      }],
      expression: "['endTime', { initialValue: getMoment(defaultVal.endTime) }]"
    }],
    attrs: {
      disabledDate: _vm.disabledEndDate
    },
    on: {
      change: _vm.change2
    }
  })], 1)], 1) : _vm._e(), _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同公司"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyName", {
        initialValue: _vm.defaultVal.companyName
      }],
      expression: "[`companyName`, { initialValue: defaultVal.companyName }]"
    }],
    attrs: {
      placeholder: "请输入",
      allowClear: ""
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同状态"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["status", {
        initialValue: _vm.defaultVal.status || undefined
      }],
      expression: "[`status`, { initialValue: defaultVal.status || undefined }]"
    }],
    attrs: {
      placeholder: "请选择",
      allowClear: "",
      disabled: "",
      "allow-clear": ""
    }
  }, _vm._l(_vm.statusList, function (stat, statusIndex) {
    return _c("a-select-option", {
      key: statusIndex + 1,
      attrs: {
        value: statusIndex + 1
      }
    }, [_vm._v(_vm._s(stat))]);
  }), 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同签署完成时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["subscribeTime", {
        initialValue: _vm.getMoment(_vm.defaultVal.subscribeTime)
      }],
      expression: "['subscribeTime', { initialValue: getMoment(defaultVal.subscribeTime) }]"
    }]
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 11
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "合同备注"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["remarks", {
        initialValue: _vm.defaultVal.remarks
      }],
      expression: "['remarks', { initialValue: defaultVal.remarks }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      allowClear: "",
      maxLength: 255,
      "auto-size": {
        minRows: 8,
        maxRows: 15
      }
    }
  })], 1), _c("a-form-item", [_c("div", {
    staticClass: "paper-uplod mt24"
  }, [_c("Uploading", {
    attrs: {
      srcData: _vm.defaultVal.file,
      limit: 12
    },
    on: {
      "update:srcData": function updateSrcData($event) {
        return _vm.$set(_vm.defaultVal, "file", $event);
      },
      "update:src-data": function updateSrcData($event) {
        return _vm.$set(_vm.defaultVal, "file", $event);
      },
      change: function change($event) {
        return _vm.fileChange(_vm.formIndex, $event);
      }
    }
  }, [_c("template", {
    slot: "antUpload"
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _c("div", {
    staticClass: "upload-text"
  }, [_vm._v("点击添加文件或 将文件拖拽到此处")])], 1)], 2)], 1)]), _c("a-form-item", {
    staticClass: "mr24",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v(" 保存 ")]), _c("a-button", {
    staticClass: "ml12",
    on: {
      click: function click($event) {
        return _vm.$emit("upDate", _vm.index, false);
      }
    }
  }, [_vm._v(" 取消 ")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };