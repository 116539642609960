import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
var commonRoutes = [{
  path: "elecontract",
  // 签署记录
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/ele-contract/index"));
    });
  }
}, {
  path: "type",
  // 合同类型管理
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/type-manage/manage"));
    });
  }
}, {
  path: "group",
  // 合同分组
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/type-manage/manage/group"));
    });
  }
}, {
  path: "type/update/:categoryId/:id",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/type-manage/edit.vue"));
    });
  }
}, {
  path: "type/seal/:id",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/type-manage/editSealNew"));
    });
  }
}, {
  path: "stamp",
  // 印章管理
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/stamp-manage/index"));
    });
  }
}, {
  path: "stamp/create",
  // 印章创建
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/stamp-manage/edit"));
    });
  }
}, {
  path: "stamp/update/:id",
  // 印章编辑
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/stamp-manage/edit"));
    });
  }
}, {
  path: "stamp/verify/:id",
  // 印章认证
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/stamp-manage/verify"));
    });
  }
}, {
  path: "contract-set",
  // 设置
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/ele-contract/setting"));
    });
  }
}, {
  path: "setting",
  name: "setting",
  // 合同设置
  meta: {
    pageType: ""
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/type-manage/settingNew"));
    });
  }
}, {
  name: "initiateSign",
  path: "type/initiateSign",
  // 发起合同
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/type-manage/initiateSign"));
    });
  }
}, {
  path: "type/previewDetail",
  // 预览
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/contract/type-manage/previewDetail"));
    });
  }
}];
export default {
  path: "/contract",
  // 电子合同
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  redirect: "ele-contract/index",
  children: [].concat(commonRoutes, [])
};