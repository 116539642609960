import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.uploading,
      tip: "上传中..."
    }
  }, [_c("div", {
    staticClass: "uplaodBoxB page-miH page-bgBack"
  }, [_c("a-row", {
    staticClass: "head"
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.isGoBack
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  }), _c("div", {
    staticClass: "left2"
  }, [_vm._v("返回")])], 1), _c("a-col", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("批量导入新员工")])])], 1), !_vm.callback ? _c("div", {
    staticClass: "ant-upload-select-text-box",
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-upload", {
    ref: "upload",
    attrs: {
      "file-list": _vm.fileList,
      remove: _vm.handleRemove,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("img", {
    staticStyle: {
      width: "139px",
      height: "106px"
    },
    attrs: {
      src: _vm.uploadImg
    }
  }), _c("div", {
    staticClass: "ant-modal-body"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#666"
    }
  }, [_vm._v("点击添加文件或将文件拖拽到此处")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#999"
    }
  }, [_vm._v("(文件大小不超过5M)")])]), _c("a-button", {
    staticStyle: {
      "margin-bottom": "15px"
    },
    attrs: {
      type: "primary"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加文件")], 1)], 1)], 1) : _vm._e(), !_vm.callback ? _c("div", {
    staticClass: "tipBox"
  }, [_c("a-row", {
    staticStyle: {
      color: "#252525",
      "font-size": "16px",
      "font-weight": "500"
    }
  }, [_vm._v(" 温馨提示 ")]), _c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", {
    staticStyle: {
      "padding-right": "5px"
    }
  }, [_vm._v(" 首先下载标准模板，填写信息后再上传 ")]), _c("a-button", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("点击下载模板")]), _c("div", {
    staticClass: "rowMargin"
  }, [_vm._v("1.请将要上传的内容放在模板的员工数据中,单次导入员工上限为2000条")]), _c("div", {
    staticClass: "rowMargin"
  }, [_vm._v("2.姓名、身份证号、手机号、工作性质、入职日期、用工单位为必填项，顺序可调整，但不可删除")]), _c("div", {
    staticClass: "rowMargin"
  }, [_vm._v("3.若字段为下拉选项，单元格的内容只能在支持的选项值内；不在范围内的单元格内容无法导入")]), _c("div", {
    staticClass: "rowMargin"
  }, [_vm._v("4.若Excel文件设有权限密码，需取消Excel文件密码，否则系统无法识别")]), _c("div", {
    staticClass: "rowMargin"
  }, [_vm._v("5.已添加到系统中的自定义字段，可实现批量导入相关信息（需手动添加表头）")])], 1)], 1)], 1) : _vm._e(), _vm.callback ? _c("div", [_c("div", {
    staticStyle: {
      "padding-left": "24px",
      "padding-right": "24px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "15px",
      "padding-left": "10px",
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      height: "35px",
      width: "100%",
      "background-color": "#fffbe6",
      "border-style": "solid",
      "border-color": "#ffe58f",
      "border-width": "1px",
      "border-radius": "5px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "5px"
    },
    attrs: {
      src: require("../../../assets/icons/重复.png")
    }
  }), _c("span", {
    attrs: {
      sytle: "color:#55565D;font-size:14px;"
    }
  }, [_vm._v("共" + _vm._s(_vm.count) + "条数据，成功导入" + _vm._s(_vm.succ) + "条数据，"), _c("label", {
    staticClass: "redc"
  }, [_vm._v(_vm._s(_vm.fial))]), _vm._v("条数据导入失败。")])])]), _c("div", {
    staticClass: "header-title-box"
  }, [_c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticStyle: {
      "font-size": "15px",
      "font-weight": "700",
      "font-color": "#252525"
    }
  }, [_vm._v("错误信息")])]), _c("div", {
    staticClass: "btn-box"
  }, [_c("a-button", {
    attrs: {
      disabled: !_vm.downloadurl,
      type: "primary"
    },
    on: {
      click: _vm.buCha
    }
  }, [_vm._v(" 导出错误数据 ")])], 1)]), _c("a-table", {
    staticStyle: {
      "padding-left": "24px",
      "padding-right": "24px"
    },
    attrs: {
      columns: _vm.columnDetail,
      pagination: _vm.pagination,
      "data-source": _vm.dataDetail,
      scroll: {
        x: 800
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "reason",
      fn: function fn(text) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "topLeft"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(text))])]), _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))])], 2)];
      }
    }, {
      key: "name",
      fn: function fn(text, record) {
        return [record.field === "name" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "idCard",
      fn: function fn(text, record) {
        return [record.field === "idCard" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "phone",
      fn: function fn(text, record) {
        return [record.field === "phone" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "postType",
      fn: function fn(text, record) {
        return [record.field === "postType" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(record.postType != null ? _vm.$store.state.enums.postTypeArr[record.postType - 1] : ""))]) : _c("span", [_vm._v(_vm._s(record.postType != null ? _vm.$store.state.enums.postTypeArr[record.postType - 1] : ""))])];
      }
    }, {
      key: "joinDate",
      fn: function fn(text, record) {
        return [record.field === "joinDate" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(record.joinDate ? _vm.moment(text).format("YYYY-MM-DD") : ""))]) : _c("span", [_vm._v(_vm._s(record.joinDate ? _vm.moment(text).format("YYYY-MM-DD") : ""))])];
      }
    }, {
      key: "turnStraightDate",
      fn: function fn(text, record) {
        return [record.field === "turnStraightDate" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(record.turnStraightDate ? _vm.moment(text).format("YYYY-MM-DD") : ""))]) : _c("span", [_vm._v(_vm._s(record.turnStraightDate ? _vm.moment(text).format("YYYY-MM-DD") : ""))])];
      }
    }, {
      key: "formalType",
      fn: function fn(text, record) {
        return [record.field === "formalType" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(record.formalType ? _vm.formalType[Number(text - 1)] : ""))]) : _c("span", [_vm._v(_vm._s(record.formalType ? _vm.formalType[Number(text - 1)] : ""))])];
      }
    }, {
      key: "probationDays",
      fn: function fn(text, record) {
        return [record.field === "probationDays" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "nation",
      fn: function fn(text, record) {
        return [record.field === "nation" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "nativeLocation",
      fn: function fn(text, record) {
        return [record.field === "nativeLocation" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "maritalStatus",
      fn: function fn(text, record) {
        return [record.field === "maritalStatus" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(record.maritalStatus ? _vm.getMaritalStatus(record.maritalStatus) : ""))]) : _c("span", [_vm._v(_vm._s(record.maritalStatus ? _vm.getMaritalStatus(record.maritalStatus) : ""))])];
      }
    }, {
      key: "joinWorkDate",
      fn: function fn(text, record) {
        return [record.field === "joinWorkDate" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(record.joinWorkDate ? _vm.moment(text).format("YYYY-MM-DD") : ""))]) : _c("span", [_vm._v(_vm._s(record.joinWorkDate ? _vm.moment(text).format("YYYY-MM-DD") : ""))])];
      }
    }, {
      key: "regType",
      fn: function fn(text, record) {
        return [record.field === "regType" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(record.regType ? _vm.getRegType(record.regType) : ""))]) : _c("span", [_vm._v(_vm._s(record.regType ? _vm.getRegType(record.regType) : ""))])];
      }
    }, {
      key: "currentLocation",
      fn: function fn(text, record) {
        return [record.field === "currentLocation" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "politicalStatus",
      fn: function fn(text, record) {
        return [record.field === "politicalStatus" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(record.politicalStatus ? _vm.renderPoliticalStatus(record.politicalStatus) : ""))]) : _c("span", [_vm._v(_vm._s(record.politicalStatus ? _vm.renderPoliticalStatus(record.politicalStatus) : ""))])];
      }
    }, {
      key: "contractStartDate",
      fn: function fn(text, record) {
        return [record.field === "contractStartDate" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(record.contractStartDate ? _vm.moment(text).format("YYYY-MM-DD") : ""))]) : _c("span", [_vm._v(_vm._s(record.contractStartDate ? _vm.moment(text).format("YYYY-MM-DD") : ""))])];
      }
    }, {
      key: "contractEndDate",
      fn: function fn(text, record) {
        return [record.field === "contractEndDate" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(record.contractEndDate ? _vm.moment(text).format("YYYY-MM-DD") : ""))]) : _c("span", [_vm._v(_vm._s(record.contractEndDate ? _vm.moment(text).format("YYYY-MM-DD") : ""))])];
      }
    }, {
      key: "contractType",
      fn: function fn(text, record) {
        return [record.field === "contractType" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "contactsTerm",
      fn: function fn(text, record) {
        return [record.field === "reacontactsTermson" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "channelName",
      fn: function fn(text, record) {
        return [record.field === "channelName" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "bankName",
      fn: function fn(text, record) {
        return [record.field === "bankName" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "bankCardBranch",
      fn: function fn(text, record) {
        return [record.field === "bankCardBranch" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "bankCard",
      fn: function fn(text, record) {
        return [record.field === "bankCard" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "education",
      fn: function fn(text, record) {
        return [record.field === "education" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "pfCard",
      fn: function fn(text, record) {
        return [record.field === "pfCard" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "remark",
      fn: function fn(text, record) {
        return [record.field === "remark" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "tag",
      fn: function fn(text, record) {
        return [record.field === "tag" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }], null, false, 2014042122)
  })], 1) : _vm._e(), _c("a-modal", {
    attrs: {
      title: "批量导入",
      width: "500px"
    },
    on: {
      cancel: _vm.handleFailClose,
      ok: _vm.handleFailClose
    },
    model: {
      value: _vm.dialogFailVisible,
      callback: function callback($$v) {
        _vm.dialogFailVisible = $$v;
      },
      expression: "dialogFailVisible"
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleFailClose
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", {
    staticStyle: {
      "padding-left": "10px",
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      height: "35px",
      width: "100%"
    }
  }, [_c("img", {
    staticStyle: {
      width: "25px",
      height: "25px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("../../../assets/icons/duihao.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "15px",
      "font-weight": "700"
    }
  }, [_vm._v("共" + _vm._s(_vm.count) + "条数据，开始导入，请在右下角任务列表查看进度")])])], 2)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };