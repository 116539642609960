import "core-js/modules/es.object.to-string.js";
import defaultSettings from "@/settings";
import request from "@/utils/request";
function getSetting() {
  return request({
    url: "/hr/api/v1/site/domain/free",
    method: "get"
  });
}
var copyright_title = defaultSettings.copyright_title,
  system_title = defaultSettings.system_title,
  need_subject = defaultSettings.need_subject,
  subject_title = defaultSettings.subject_title,
  need_project = defaultSettings.need_project,
  project_title = defaultSettings.project_title,
  fixed_header = defaultSettings.fixed_header,
  need_logo = defaultSettings.need_logo,
  need_tab = defaultSettings.need_tab,
  login_wx = defaultSettings.login_wx,
  use_site_name = defaultSettings.use_site_name;
var state = {
  copyright_title: copyright_title,
  system_title: system_title,
  need_subject: need_subject,
  subject_title: subject_title,
  need_project: need_project,
  project_title: project_title,
  fixed_header: fixed_header,
  need_logo: need_logo,
  need_tab: need_tab,
  login_wx: login_wx,
  use_site_name: use_site_name,
  deviceType: "web",
  login_code: true,
  login_pwd: true,
  wechatDomain: process.env.VUE_APP_HOST,
  loaded: false,
  settings: {},
  homeLogo: null,
  systemBackground: null,
  dashboradLogoUrl: null,
  siteLogoUrl: null,
  systemIcon: null,
  leftMenuHideLogoUrl: null,
  themeStr: null,
  xcxCode: "zxr_xcx"
};
var mutations = {
  updateSetting: function updateSetting(state, payload) {
    state.settings = payload;
    state.login_wx = payload.wechatLoginEnable;
    state.copyright_title = payload.copyRight || state.copyright_title;
    state.deviceType = payload.deviceType || "web";
    state.login_code = payload.verificationLoginEnable;
    state.login_pwd = payload.passwordLoginEnable;
    state.wechatDomain = payload.wechatDomain || process.env.VUE_APP_HOST;
    state.homeLogo = payload.homeLogo;
    state.systemBackground = payload.systemBackground;
    state.systemIcon = payload.systemIcon;
    state.leftMenuHideLogoUrl = payload.leftMenuHideLogoUrl;
    state.dashboradLogoUrl = payload.dashboradLogoUrl;
    state.siteLogoUrl = payload.siteLogoUrl;
    state.themeStr = payload.colorMatching;
    state.xcxCode = payload.xcxCode || "zxr_xcx";
    state.loaded = true;
  }
};
var actions = {
  changeSetting: function changeSetting(_ref) {
    var commit = _ref.commit;
    return getSetting().then(function (res) {
      var data = res.data;
      var colorMatching = JSON.parse(data.colorMatching);
      localStorage.setItem("primary", colorMatching.primary);
      localStorage.setItem("menuDark", colorMatching.menuDark);
      localStorage.setItem("menuSub", colorMatching.menuSub);
      localStorage.setItem("sidebar", colorMatching.sidebar || "#2d2e39");
      localStorage.setItem("footer", colorMatching.footer || "#2d2e39");
      localStorage.setItem("menuTheme", colorMatching.menuTheme || "dark");
      if (data.webTitle) {
        document.title = data.webTitle;
      }
      if (data.webIcon) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = data.webIcon;
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      commit("updateSetting", data);
    }).catch(function (err) {
      console.log("debug log --> ", err);
      return Promise.reject(false);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};