import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.timers.js";
import { findCompanyGroupReq } from "./api";
import { ruleTitle, addTreeSlot, allCompany } from "./funcs";
import { mapState } from "vuex";
import _ from "lodash";
import { findAllParentIds } from "@/utils/tools";
export default {
  props: {
    hasLeftIcon: {
      // 是否有左边图标
      type: Boolean,
      default: true
    },
    showSelect: {
      // 是否显示选中的
      type: String,
      default: undefined
    }
  },
  data: function data() {
    return {
      // 树结构
      replaceFields: {
        children: "companyList",
        title: "name",
        key: "id",
        value: "id"
      },
      // popover显示状态
      clicked: false,
      // 树数据
      dataList: [],
      expandedKeys: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["site"])), {}, {
    selectName: function selectName() {
      return this.site.selectName;
    }
  }),
  created: function created() {
    this.findCompanyGroup();
  },
  methods: {
    updateSync: function updateSync() {
      this.$emit("update:siteIds", this.site.selectAllIds);
      this.$emit("update:siteName", this.selectName);
      this.$emit("update:selectTreeId", this.site.selectId);
      this.$emit("update:type", this.site.selectType);
    },
    onSearchChange: _.debounce(function (event) {
      this.findCompanyGroup(event.target.value);
    }, 1000),
    onExpand: function onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
    },
    // popover显示方法
    handleClickChange: function handleClickChange(visible) {
      this.clicked = visible;
    },
    // 选择树点击事件
    onSelect: function onSelect(selectedKeys, info) {
      if (selectedKeys.length === 0) {
        return;
      }
      if (info.node.dataRef.type === "group" || info.node.dataRef.type === "site") {
        // 分组
        if (info.node.dataRef.companyList && info.node.dataRef.companyList.length > 0) {
          var idArr = [];
          allCompany(info.node.dataRef.companyList, idArr);
          this.$store.commit("site/updateSelected", {
            selectAllIds: idArr.join(),
            selectName: info.node.dataRef.name
          });
        } else {
          // 分组中没有任何用工单位，如果传空字符串，会返回所有。不符合预期，那么就将分组id传入，服务器会返回空数据，符合预期
          this.$store.commit("site/updateSelected", {
            selectAllIds: info.node.dataRef.id,
            selectName: info.node.dataRef.name
          });
        }
      } else if (info.node.dataRef.type === "company") {
        this.$store.commit("site/updateSelected", {
          selectAllIds: info.node.dataRef.id,
          selectName: info.node.dataRef.name
        });
      }

      // 开始替换
      var name = this.selectName.replace(new RegExp('<span style="color: #3C8AFF">', "g"), "");
      name = name.replace(new RegExp("</span>", "g"), "");
      this.$store.commit("site/updateSelected", {
        selectId: info.node.dataRef.id !== null ? info.node.dataRef.id : "-1",
        selectName: name,
        selectType: info.node.dataRef.type
      });

      // 将数据发送的父组件
      this.updateSync();
      this.clicked = false;
      if (info.node.dataRef.type === "group") {
        sessionStorage.setItem("tenantId", info.node.dataRef.id);
      } else if (info.node.dataRef.type === "site") {
        sessionStorage.setItem("tenantId", "");
      } else {
        sessionStorage.setItem("tenantId", "-1");
      }
      this.$emit("selectChange");
    },
    // 查询所有组和用工单位
    findCompanyGroup: function findCompanyGroup(value) {
      var _this = this;
      findCompanyGroupReq({
        name: value || undefined
      }).then(function (res) {
        var dataListTemp = addTreeSlot(res.data);
        dataListTemp.map(function (item, index) {
          dataListTemp[index].name = ruleTitle(dataListTemp[index].name, value);
          if (item.companyList) {
            item.companyList.map(function (item2, index2) {
              dataListTemp[index].companyList[index2].name = ruleTitle(dataListTemp[index].companyList[index2].name, value);
            });
          }
        });
        if (value) {
          _this.expandedKeys = findAllParentIds(_this.dataList);
        }
        _this.dataList = dataListTemp;
        if (!_this.site.selectName) {
          // 第一次进来，本地没有缓存，就默认选择site
          _this.$store.commit("site/updateSelected", {
            companyTree: JSON.stringify(_this.dataList),
            selectId: _this.dataList[0] ? _this.dataList[0].id : "-1",
            selectName: _this.dataList[0] ? _this.dataList[0].name : null,
            selectType: "site"
          });
          // 默认选择第一个，模拟点击操作
          var that = _this;
          var info = {
            node: {
              dataRef: that.dataList[0]
            }
          };
          setTimeout(function () {
            _this.onSelect([_this.site.selectId], info);
          }, 0);
        } else {
          _this.$store.commit("site/updateSelected", {
            companyTree: JSON.stringify(_this.dataList)
          });
          _this.updateSync();
        }
      }).catch(function (err) {
        console.log("Company load error", err);
      });
    }
  }
};