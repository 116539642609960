import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "roleSet page-miH page-bgBack"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "margin-bottom": "20px"
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "搜索角色名称",
      allowClear: ""
    },
    on: {
      search: _vm.checkByRoleName
    },
    model: {
      value: _vm.roleName,
      callback: function callback($$v) {
        _vm.roleName = $$v;
      },
      expression: "roleName"
    }
  }), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("a-button", {
    attrs: {
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: _vm.deleteRoles
    }
  }, [_vm._v(" 批量删除 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: _vm.newRole
    }
  }, [_vm._v(" 新建角色 ")])], 1)], 1), _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.defaultRoleList,
      "row-key": "id",
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
        getCheckboxProps: function getCheckboxProps(record) {
          return {
            props: {
              disabled: record.reserveFlag,
              name: record.name
            }
          };
        }
      },
      loading: _vm.tableLoading
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return [_c("span", [_c("a", {
          attrs: {
            size: "mini",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.configPermission(record);
            }
          }
        }, [_vm._v("配置")]), !record.reserveFlag ? [_c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a", {
          attrs: {
            size: "mini",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.deleteRoleClick(record);
            }
          }
        }, [_vm._v("删除")])] : _vm._e()], 2)];
      }
    }])
  }), _c("a-modal", {
    attrs: {
      title: "新建角色"
    },
    on: {
      ok: _vm.handleOk
    },
    model: {
      value: _vm.addRoleVisible,
      callback: function callback($$v) {
        _vm.addRoleVisible = $$v;
      },
      expression: "addRoleVisible"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.dataForm,
      rules: _vm.editRules,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "角色名称",
      prop: "addRoleName"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.dataForm.addRoleName,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "addRoleName", $$v);
      },
      expression: "dataForm.addRoleName"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "角色描述",
      prop: "addRoleIntro"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.dataForm.addRoleIntro,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "addRoleIntro", $$v);
      },
      expression: "dataForm.addRoleIntro"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };