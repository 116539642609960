var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", [_c("a-spin", {
    attrs: {
      tip: "加载中..",
      spinning: _vm.spinning,
      delay: 300
    }
  }, [_c("a-row", {
    staticClass: "topline",
    attrs: {
      type: "flex"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  }), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goback
    }
  }, [_vm._v("返回")]), _c("h3", [_vm._v("公告详情")]), _c("a-icon", {
    attrs: {
      type: "add"
    }
  })], 1), _c("a-layout-content", [_c("a-table", {
    staticStyle: {
      margin: "12px auto 0",
      width: "95%"
    },
    attrs: {
      columns: _vm.noticeColumns,
      "data-source": _vm.noticelist,
      scroll: {
        y: 320
      },
      "row-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "publishEmpRangeIsAll",
      fn: function fn(text) {
        return _c("span", {}, [_c("span", [_vm._v(" " + _vm._s(text === true ? "全公司" : "部分员工") + " ")]), _c("span", {
          staticClass: "tc-theme",
          on: {
            click: _vm.checkEmpTable
          }
        }, [_vm._v("查看")])]);
      }
    }, {
      key: "publishEmpTypeIsAll",
      fn: function fn(text) {
        return _c("span", {}, [_c("span", [_vm._v(" " + _vm._s(text === true ? "全部" : "部分") + " ")]), _c("span", {
          staticClass: "tc-theme",
          on: {
            click: _vm.checkEmpTypelist
          }
        }, [_vm._v("查看")])]);
      }
    }])
  }), _c("div", {
    staticClass: "notice-editor-wrapper"
  }, [_c("div", {
    staticClass: "ql-editor-content"
  }, [_c("div", {
    staticClass: "preview-box",
    style: _vm.bgImage
  }, [_c("div", {
    staticClass: "notice-content"
  }, [_c("div", {
    staticClass: "notice-company-title"
  }, [_vm._v(" " + _vm._s(_vm.currentNotice.title) + " ")]), _c("div", {
    staticClass: "notice-company-date"
  }, [_c("span", [_vm._v(_vm._s(_vm.currentNotice.publishUserName))]), _c("span", [_vm._v(_vm._s(_vm._f("formatDateAll")(_vm.currentNotice.publishDate)))])]), _c("div", {
    staticClass: "notice-company-content",
    domProps: {
      innerHTML: _vm._s(_vm.currentNotice.content)
    }
  })])])]), _vm.currentNotice.isOnline ? _c("div", {
    staticClass: "btnline"
  }, [!_vm.currentNotice.isOnline ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onlineHandle
    }
  }, [_vm._v("上线")]) : _vm._e(), _vm.currentNotice.isOnline ? _c("a-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.offlineHandle
    }
  }, [_vm._v("下线")]) : _vm._e(), !_vm.currentNotice.isTop ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.setTopHandle
    }
  }, [_vm._v("置顶")]) : _vm._e(), _vm.currentNotice.isTop ? _c("a-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.concelTop
    }
  }, [_vm._v(" 取消置顶 ")]) : _vm._e()], 1) : _vm._e(), !_vm.currentNotice.isOnline ? _c("div", {
    staticClass: "btnline"
  }, [!_vm.currentNotice.isOnline ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onlineHandle
    }
  }, [_vm._v("重新上线")]) : _vm._e()], 1) : _vm._e()])], 1), _c("a-modal", {
    staticClass: "hidebtn",
    attrs: {
      title: "已选择员工",
      visible: _vm.empTableVisible,
      width: "788px"
    },
    on: {
      cancel: function cancel($event) {
        _vm.empTableVisible = false;
      }
    }
  }, [_c("a-table", {
    staticStyle: {
      "margin-top": "12px",
      width: "95%"
    },
    attrs: {
      columns: _vm.memberColumn,
      "data-source": _vm.currentNotice.empInfoList,
      scroll: {
        y: 320
      },
      "row-key": "id"
    }
  })], 1), _c("a-modal", {
    staticClass: "hidebtn",
    attrs: {
      title: "选择性质范围",
      visible: _vm.natureVisible,
      width: "488px"
    },
    on: {
      cancel: function cancel($event) {
        _vm.natureVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "select-container"
  }, [_c("a-checkbox-group", {
    model: {
      value: _vm.checkedList,
      callback: function callback($$v) {
        _vm.checkedList = $$v;
      },
      expression: "checkedList"
    }
  }, [_c("a-row", _vm._l(_vm.type, function (item, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 6
      }
    }, [_c("a-checkbox", {
      attrs: {
        value: item.value,
        disabled: _vm.isHandle
      }
    }, [_vm._v(_vm._s(item.label))])], 1);
  }), 1)], 1)], 1)]), _c("a-modal", {
    staticClass: "notice-modal",
    attrs: {
      title: "发布公告",
      visible: _vm.offlineVisible,
      width: "468px"
    },
    on: {
      ok: _vm.confirmOffline,
      cancel: function cancel($event) {
        _vm.offlineVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "select-container"
  }, [_c("p", {
    staticClass: "publish-title"
  }, [_vm._v("请选择公告下线方式")]), _c("a-radio-group", {
    model: {
      value: _vm.isOfflineNow,
      callback: function callback($$v) {
        _vm.isOfflineNow = $$v;
      },
      expression: "isOfflineNow"
    }
  }, [_c("a-row", [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_vm._v("立即下线")])], 1), _c("a-row", {
    staticClass: "mt12"
  }, [_c("a-radio", {
    attrs: {
      value: false
    }
  }, [_vm._v("定时下线")]), _c("a-date-picker", {
    attrs: {
      format: "YYYY-MM-DD HH:mm:ss",
      "show-time": {
        defaultValue: _vm.moment("00:00:00", "HH:mm:ss")
      }
    },
    on: {
      change: _vm.handleChange
    }
  })], 1)], 1)], 1)]), _c("a-modal", {
    staticClass: "notice-modal",
    attrs: {
      title: "发布公告",
      visible: _vm.onlineVisible,
      width: "468px"
    },
    on: {
      ok: _vm.confirmOnline,
      cancel: function cancel($event) {
        _vm.onlineVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "select-container"
  }, [_c("p", {
    staticClass: "publish-title"
  }, [_vm._v("请选择公告上线方式")]), _c("a-radio-group", {
    model: {
      value: _vm.isPublishNow,
      callback: function callback($$v) {
        _vm.isPublishNow = $$v;
      },
      expression: "isPublishNow"
    }
  }, [_c("a-row", [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_vm._v("立即上线")])], 1)], 1)], 1)]), _c("a-modal", {
    staticClass: "notice-modal",
    attrs: {
      title: "置顶",
      visible: _vm.cancelTopVisible,
      width: "488px"
    },
    on: {
      ok: _vm.confirmCancelTop,
      cancel: function cancel($event) {
        _vm.cancelTopVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "tips"
  }, [_vm._v(" " + _vm._s(_vm.setTopTip) + " ")])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };