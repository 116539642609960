import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pSlide page-miH page-bgBack"
  }, [_c("a-layout", [_c("a-layout-sider", {
    staticStyle: {
      "border-right": "1px solid #e8e8e8"
    },
    attrs: {
      width: 225
    }
  }, [_c("div", {
    staticClass: "pSlide-title",
    on: {
      mouseenter: function mouseenter($event) {
        _vm.addIcon = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.addIcon = false;
      },
      click: function click($event) {
        $event.stopPropagation();
        return _vm.getAllList.apply(null, arguments);
      }
    }
  }, [_vm._v(" 审批分组(" + _vm._s(_vm.allList) + ") "), _c("a-icon", {
    staticClass: "mg_l81",
    staticStyle: {
      "font-weight": "500"
    },
    attrs: {
      type: "plus"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.newGroup.apply(null, arguments);
      }
    }
  })], 1), _c("a-menu", {
    staticStyle: {
      "min-height": "500px"
    },
    model: {
      value: _vm.menuKey,
      callback: function callback($$v) {
        _vm.menuKey = $$v;
      },
      expression: "menuKey"
    }
  }, _vm._l(_vm.groupList, function (item) {
    return _c("a-menu-item", {
      key: item.id,
      on: {
        mouseenter: function mouseenter($event) {
          _vm.act = item.id;
        },
        mouseleave: function mouseleave($event) {
          _vm.act = false;
        },
        click: function click($event) {
          return _vm.getApprovalList(item);
        }
      }
    }, [_c("a-row", {
      attrs: {
        type: "flex",
        align: "middle",
        justify: "space-between"
      }
    }, [_c("a-col", {
      staticClass: "ove",
      attrs: {
        span: 20
      }
    }, [_c("a-tooltip", {
      attrs: {
        placement: "rightTop"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s("".concat(item.groupName, "(").concat(item.approFlowNum, ")")))])]), _c("span", [_vm._v(_vm._s("".concat(item.groupName, "(").concat(item.approFlowNum, ")")))])], 2)], 1), _c("a-col", [_c("a-icon", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.act == item.id,
        expression: "act == item.id"
      }],
      attrs: {
        type: "setting"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.setting(item);
        }
      }
    })], 1)], 1)], 1);
  }), 1)], 1), _c("a-layout", {
    staticClass: "mg_l20"
  }, [_c("a-row", {
    staticClass: "lay-head",
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: function click($event) {
        return _vm.createApproval(null);
      }
    }
  }, [_vm._v("新建审批")]), _vm.isContainByString("approvalAdmin") ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showIssueModa
    }
  }, [_vm._v("下发审批")]) : _vm._e()], 1)]), _c("a-col", {
    staticClass: "mg_l_o"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请输入",
      allowClear: ""
    },
    on: {
      search: _vm.onSearch
    }
  })], 1)], 1), _c("a-layout-content", [_c("a-table", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      pagination: _vm.pagination,
      dataSource: _vm.data,
      rowKey: "id"
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "审批列表"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("a-row", {
          attrs: {
            type: "flex",
            align: "middle"
          }
        }, [_c("a-col", {
          staticClass: "icon mg_r10"
        }, [_c("img", {
          staticStyle: {
            width: "48px"
          },
          attrs: {
            src: text.icon,
            alt: "加载失败"
          }
        })]), _c("a-col", [_c("a-row", {
          staticClass: "title1"
        }, [_vm._v(_vm._s(text.typeName))]), _c("a-row", {
          staticClass: "subTitle"
        }, [_vm._v(_vm._s(text.typeDesc))])], 1)], 1)];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "操作",
      width: 200
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("a-popover", {
          attrs: {
            title: "编辑",
            trigger: "focus"
          }
        }, [_c("template", {
          slot: "content"
        }, [_c("a-button", {
          staticStyle: {
            "margin-right": "5px"
          },
          attrs: {
            type: "small"
          },
          on: {
            click: function click($event) {
              return _vm.createApproval(text.id);
            }
          }
        }, [_vm._v("编辑详情")]), _c("a-button", {
          attrs: {
            type: "small"
          },
          on: {
            click: function click($event) {
              return _vm.deleteUseItem(text);
            }
          }
        }, [_vm._v("删除")])], 1), _c("a-button", [_vm._v("编辑")])], 2), !_vm.isContainByString("approvalAdmin") ? _c("a-switch", {
          staticStyle: {
            "border-radius": "4px",
            "margin-left": "24px"
          },
          attrs: {
            "checked-children": "已启用",
            "un-checked-children": "未启用"
          },
          on: {
            change: function change($event) {
              return _vm.onChangeUse($event, text);
            }
          },
          model: {
            value: text.inUse,
            callback: function callback($$v) {
              _vm.$set(text, "inUse", $$v);
            },
            expression: "text.inUse"
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1)], 1), _c("NewGroup", {
    attrs: {
      "new-group-options": _vm.newGroupOptions
    }
  }), _c("a-modal", {
    attrs: {
      visible: _vm.issueModalVisible,
      title: "下发审批模版",
      width: "538px",
      "destroy-on-close": true
    },
    on: {
      ok: _vm.confirmIssueModal,
      cancel: _vm.handleCancelIssueModal
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", [_vm._v("请选择审批模板")]), _c("a-tree-select", {
    staticStyle: {
      width: "240px",
      "margin-top": "10px",
      "max-height": "350px",
      "overflow-y": "auto"
    },
    attrs: {
      treeData: _vm.contractData,
      replaceFields: _vm.replaceFields,
      filterTreeNode: false,
      treeCheckable: "",
      placeholder: "请选择审批模板"
    },
    on: {
      search: _vm.treeSearch2,
      change: _vm.treeSelectChange
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", [_vm._v("请选择下发用工单位")]), _c("a-tree-select", {
    staticStyle: {
      width: "240px",
      "margin-top": "10px",
      "max-height": "350px",
      overflow: "auto"
    },
    attrs: {
      value: _vm.companyList,
      treeData: _vm.dataList,
      filterTreeNode: false,
      treeCheckable: "",
      placeholder: "请选择用工单位"
    },
    on: {
      search: _vm.treeSearch,
      change: _vm.companySelectChange
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };