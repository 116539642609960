import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import { selectSiteWithInsurance, selectCompanyBySiteId, updateCompanyBySiteId, addPlan, editPlan, getPlan as _getPlan, deletePlan } from "./api";
import { getToken } from "xqjr-module-auth";
import { publicDownLoad } from "../downLoad";
export default {
  data: function data() {
    return {
      siteList: [],
      planList: [],
      visible: false,
      title: "新增保险方案",
      planItem: {},
      companyDetail: {},
      companyDetailCopy: {},
      searchPlanName: undefined,
      current: 1,
      total: 0,
      count: 3,
      typeList: [{
        key: 0,
        realityMonthAmount: 0,
        typeName: "1-3类人员"
      }, {
        key: 1,
        realityMonthAmount: 0,
        typeName: "4类人员"
      }, {
        key: 2,
        realityMonthAmount: 0,
        typeName: "5类人员"
      }],
      isShow: false,
      fileList: [],
      fileListCopy: [],
      siteName: undefined,
      loading: false,
      detailTemplateFile: undefined,
      detailTemplateFileName: undefined,
      selectSite: {},
      headers: {},
      loading_submit: false
    };
  },
  watch: {
    visible: function visible(val) {
      if (val === false) {
        this.getPlan();
      }
    }
  },
  created: function created() {
    this.getSiteList();
    this.headers = {
      token: getToken()
    };
  },
  methods: {
    handleAdd: function handleAdd() {
      if (this.typeList.length === 10) {
        this.$message.warning("最多添加10个职业分类");
      } else {
        var count = this.count,
          typeList = this.typeList;
        var newData = {
          key: count
        };
        this.typeList = [].concat(_toConsumableArray(typeList), [newData]);
        this.count = count + 1;
      }
    },
    onDelete: function onDelete(key) {
      var typeList = _toConsumableArray(this.typeList);
      this.typeList = typeList.filter(function (item) {
        return item.key !== key;
      });
    },
    download: function download(file) {
      if (file.url) {
        publicDownLoad(file.url);
      } else if (file.response.data && file.response.data[0]) {
        publicDownLoad(file.response.data[0]);
      } else {
        this.$message.warning("文件下载失败");
      }
    },
    handleChange: function handleChange(info) {
      var fileList = _toConsumableArray(info.fileList);
      fileList = fileList.slice(-1);
      fileList = fileList.map(function (file) {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
      if (this.fileList && this.fileList.length) {
        if (this.fileList[0].status === "done" && this.fileList[0].response.data && this.fileList[0].response.data[0]) {
          this.detailTemplateFile = this.fileList[0].response.data[0];
          this.detailTemplateFileName = this.fileList[0].name;
        }
      } else {
        this.detailTemplateFile = "";
        this.detailTemplateFileName = "";
      }
    },
    onPageChange: function onPageChange() {
      this.getDetail(this.selectSite.id);
    },
    siteChange: function siteChange(item, index) {
      var _this = this;
      this.siteList.forEach(function (e, idx) {
        if (idx === index) {
          e.isSelected = true;
          _this.current = 1;
          _this.getDetail(e.id);
          _this.selectSite = e;
        } else {
          e.isSelected = false;
        }
      });
    },
    getSiteList: function getSiteList() {
      var _this2 = this;
      selectSiteWithInsurance({
        siteName: this.siteName
      }).then(function (res) {
        _this2.siteList = res.data;
        if (_this2.siteList && _this2.siteList.length) {
          _this2.siteList[0].isSelected = true;
          _this2.getDetail(_this2.siteList[0].id);
          _this2.selectSite = _this2.siteList[0];
          // this.getPlan();
        } else {
          _this2.getDetail();
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    getDetail: function getDetail(id) {
      var _this3 = this;
      this.loading = true;
      if (id) {
        selectCompanyBySiteId(id).then(function (res) {
          _this3.companyDetail = JSON.parse(JSON.stringify(res.data));
          _this3.companyDetailCopy = JSON.parse(JSON.stringify(res.data));
          _this3.getPlan();
          _this3.detailTemplateFile = res.data.detailTemplateFile;
          _this3.detailTemplateFileName = res.data.remark;
          if (_this3.detailTemplateFileName && _this3.detailTemplateFile) {
            _this3.fileList = [{
              uid: "1",
              name: _this3.detailTemplateFileName,
              status: "done",
              response: "Server Error 500",
              url: _this3.detailTemplateFile
            }];
          } else {
            _this3.fileList = [];
          }
          _this3.fileListCopy = _this3.fileList;
          _this3.loading = false;
        }).catch(function (err) {
          _this3.loading = false;
          console.log(err);
        });
      } else {
        this.companyDetail = {};
        this.companyDetailCopy = {};
        this.detailTemplateFile = null;
        this.detailTemplateFileName = null;
        this.planList = [];
        this.total = 0;
        this.fileList = [];
        this.loading = false;
      }
    },
    updateCancel: function updateCancel() {
      this.fileList = JSON.parse(JSON.stringify(this.fileListCopy));
      this.companyDetail = JSON.parse(JSON.stringify(this.companyDetailCopy));
      this.isShow = false;
    },
    updateDetail: function updateDetail() {
      var _this4 = this;
      if (!this.fileList.length) {
        this.$message.warning("请上传明细表模板");
      } else {
        updateCompanyBySiteId({
          id: this.companyDetail.id,
          companySiteId: this.selectSite.id,
          companyName: this.selectSite.name,
          linkmanName: this.companyDetail.linkmanName,
          linkmanPhone: this.companyDetail.linkmanPhone,
          detailTemplateFile: this.detailTemplateFile,
          remark: this.detailTemplateFileName
        }).then(function (res) {
          _this4.$message.success("编辑成功");
          _this4.getDetail(_this4.selectSite.id);
          _this4.isShow = false;
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    getPlan: function getPlan() {
      var _this5 = this;
      _getPlan({
        companyId: this.companyDetail.id,
        pageSize: 9,
        pageNo: this.current,
        schemeName: this.searchPlanName
      }).then(function (res) {
        if (res.data.entities.length === 0 && _this5.current > 1) {
          // 当本页非第一页（比第一页大）时，删除最后一条后台会返回当页页码，
          // 但是本页数据为空，table组件会展示为当前页的上一页数据为空，需要加加判断再拉一下上页数据
          _this5.current = _this5.current - 1;
          _this5.pageSize = 9;
          _this5.getPlan();
        } else {
          _this5.planList = res.data.entities;
          _this5.total = res.data.entityCount;
        }
      });
    },
    addPlan: function addPlan() {
      this.visible = true;
      this.title = "新增保险方案";
      this.planItem = {};
      this.count = 3;
      this.typeList = [{
        key: 0,
        realityMonthAmount: 0,
        typeName: "1-3类人员"
      }, {
        key: 1,
        realityMonthAmount: 0,
        typeName: "4类人员"
      }, {
        key: 2,
        realityMonthAmount: 0,
        typeName: "5类人员"
      }];
    },
    handelOk: function handelOk() {
      var _this6 = this;
      var hasName = true;
      var haAmount = true;
      var isZero = true;
      this.typeList.forEach(function (item) {
        if (!item.typeName) {
          hasName = false;
        } else if (!item.realityMonthAmount && item.realityMonthAmount !== 0) {
          haAmount = false;
        } else if (item.realityMonthAmount === 0) {
          isZero = false;
        }
      });
      if (!hasName) {
        this.$message.warning("请填写职业分类名称");
      } else if (!haAmount) {
        this.$message.warning("请填写实际保险月费");
      } else if (!isZero) {
        this.$message.warning("实际保险月费不能为0");
      } else if (!this.planItem.schemeName) {
        this.$message.warning("请填写保险方案");
      } else {
        var arr = _objectSpread(_objectSpread({}, this.planItem), {}, {
          insCompanyId: this.companyDetail.id,
          personTypeList: this.typeList
        });
        this.loading_submit = true;
        if (this.title === "新增保险方案") {
          addPlan(arr).then(function (res) {
            _this6.$message.success("新增成功");
            _this6.visible = false;
            _this6.getPlan();
            _this6.loading_submit = false;
          }).catch(function (err) {
            _this6.loading_submit = false;
            console.log(err);
          });
        } else {
          editPlan(arr).then(function (res) {
            _this6.$message.success("编辑成功");
            _this6.visible = false;
            _this6.getPlan();
            _this6.loading_submit = false;
          }).catch(function (err) {
            _this6.loading_submit = false;
            console.log(err);
          });
        }
      }
    },
    onSearch: function onSearch() {
      this.current = 1;
      this.getPlan();
    },
    handleMenuClick: function handleMenuClick(e, item) {
      var h = this.$createElement;
      if (e.key === "1") {
        this.visible = true;
        this.title = "编辑保险方案";
        this.planItem = {
          schemeName: item.schemeName,
          realityMonthAmount: item.realityMonthAmount ? item.realityMonthAmount : undefined,
          id: item.id
        };
        item.personTypeList.forEach(function (item, index) {
          item.key = index;
        });
        this.typeList = item.personTypeList;
        this.count = item.personTypeList.length;
      } else if (e.key === "2") {
        var that = this;
        this.$confirm({
          title: "确定删除此投保方案吗?",
          content: function content(h) {
            return h("div", {
              "style": "color:red;"
            }, [item.schemeName]);
          },
          onOk: function onOk() {
            deletePlan(item.id).then(function (res) {
              that.getPlan();
              that.$message.success("删除成功");
            });
          },
          onCancel: function onCancel() {
            console.log("Cancel");
          },
          class: "test"
        });
      }
    }
  }
};