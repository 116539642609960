import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _c("div", {
    staticClass: "add-payslip"
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-row", {
    staticClass: "content-con"
  }, [_c("h2", {
    staticClass: "title"
  }, [_c("a-icon", {
    staticClass: "mr4",
    staticStyle: {
      color: "var(--primary)"
    },
    attrs: {
      type: "file-text",
      theme: "filled"
    }
  }), _vm._v("上传派单表")], 1), _c("a-form", {
    staticClass: "form",
    attrs: {
      form: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-row", [_c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "派单任务名称"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.TaskName,
      callback: function callback($$v) {
        _vm.TaskName = $$v;
      },
      expression: "TaskName"
    }
  })], 1)], 1), _c("a-row", [_c("a-form-item", {
    attrs: {
      label: "选择模式"
    }
  }, [_c("SelectWithInputAdd", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      editable: "",
      allowClear: "",
      dictCode: "1204",
      placeholder: "请选择模式"
    },
    model: {
      value: _vm.tableName,
      callback: function callback($$v) {
        _vm.tableName = $$v;
      },
      expression: "tableName"
    }
  })], 1)], 1), _c("a-row", [_c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "选择月份"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      format: "YYYY-MM",
      placeholder: "请选择"
    },
    model: {
      value: _vm.selectDate,
      callback: function callback($$v) {
        _vm.selectDate = $$v;
      },
      expression: "selectDate"
    }
  })], 1)], 1), _c("a-row", [_c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "选择派单对象"
    }
  }, [_c("a-radio-group", {
    attrs: {
      name: "radioGroup"
    },
    on: {
      change: _vm.onRadioChange
    },
    model: {
      value: _vm.comtype,
      callback: function callback($$v) {
        _vm.comtype = $$v;
      },
      expression: "comtype"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 固定用工单位 ")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 固定业务员 ")])], 1)], 1)], 1), _c("a-row", [_c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "派单单位/业务员"
    }
  }, [_vm.comtype === 1 ? _c("a-tree-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      treeData: _vm.companyTreeWithUuid,
      replaceFields: _vm.replaceFields2,
      labelInValue: "",
      showSearch: "",
      treeNodeFilterProp: "title",
      placeholder: "请选择用工单位",
      "tree-checkable": ""
    },
    model: {
      value: _vm.companyList,
      callback: function callback($$v) {
        _vm.companyList = $$v;
      },
      expression: "companyList"
    }
  }) : _vm._e(), _vm.comtype === 2 ? _c("a-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      labelInValue: "",
      mode: "multiple",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      placeholder: "请选择业务员"
    },
    model: {
      value: _vm.selectCompanyId,
      callback: function callback($$v) {
        _vm.selectCompanyId = $$v;
      },
      expression: "selectCompanyId"
    }
  }, _vm._l(_vm.dataSource, function (i) {
    return _c("a-select-option", {
      key: i.id
    }, [_vm._v(" " + _vm._s(i.name) + " ")]);
  }), 1) : _vm._e()], 1)], 1), _c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "选择图片/表单"
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.uploadType,
      callback: function callback($$v) {
        _vm.uploadType = $$v;
      },
      expression: "uploadType"
    }
  }, [_c("a-radio", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("上传图片")]), _c("a-radio", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("导入表单")])], 1)], 1), _vm.uploadType === "1" ? [_c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.uploadType === "1",
      expression: "uploadType === '1'"
    }],
    staticClass: "form-item",
    attrs: {
      label: "上传图片"
    }
  }, [_c("UploadGeneralInline", {
    attrs: {
      limit: 10
    },
    on: {
      change: _vm.onImgChange,
      loading: _vm.onUploadLoading
    }
  })], 1), _c("div", {
    staticClass: "ta-center mt24 ml48"
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.disabledBtn
    },
    on: {
      click: _vm.doUploadImgs
    }
  }, [_vm._v("提交")])], 1)] : _vm._e(), _vm.uploadType === "2" ? _c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "选择派单表"
    }
  }, [_c("a-upload", {
    attrs: {
      "show-upload-list": false,
      action: _vm.action,
      "custom-request": _vm.beforeUpload
    }
  }, [_c("a-button", {
    ref: "onUpload",
    staticStyle: {
      width: "300px"
    },
    attrs: {
      type: "primary",
      disabled: _vm.disabled
    },
    on: {
      click: _vm.onUpload
    }
  }, [_vm._v("导入派单表")]), _vm.spining ? _c("a-spin", {
    staticStyle: {
      "margin-left": "15px"
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 2)], 1), _c("a-row", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.uploadType === "2",
      expression: "uploadType === '2'"
    }],
    staticClass: "content-foot"
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#55565d"
    }
  }, [_vm._v("导入说明")]), _c("div", {
    staticClass: "sub_tit",
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("1、导入模板前两列必须为姓名、身份证号")]), _c("div", {
    staticClass: "sub_tit",
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("2、不支持合并单元格上传")])])], 1), _c("a-modal", {
    attrs: {
      title: "错误信息",
      visible: _vm.showModal
    },
    on: {
      ok: _vm.sureImport,
      cancel: function cancel($event) {
        return _vm.$router.go(-1);
      }
    }
  }, _vm._l(_vm.wrongList, function (item, i) {
    return _c("a-row", {
      key: i
    }, [_vm._v(" 第 " + _vm._s(item.row) + " 行 : " + _vm._s(item.msgList))]);
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };