import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "signed-box"
  }, [_c("div", {
    staticClass: "tabboxSigned"
  }, [_c("a-list", {
    attrs: {
      "item-layout": "horizontal",
      "data-source": _vm.categorieslistAll
    },
    scopedSlots: _vm._u([{
      key: "renderItem",
      fn: function fn(item) {
        return _c("a-list-item", {
          staticClass: "category"
        }, [_c("a-list-item-meta", {
          attrs: {
            description: item.description
          }
        }, [_c("div", {
          staticStyle: {
            "font-size": "16px"
          },
          attrs: {
            slot: "title"
          },
          slot: "title"
        }, [_vm._v(_vm._s(item.name))])]), _c("div", [_c("a-icon", {
          staticStyle: {
            "font-size": "20px",
            color: "#bdbcbf"
          },
          attrs: {
            type: "form"
          },
          on: {
            click: _vm.updateCategory
          }
        })], 1)], 1);
      }
    }])
  }), _c("a-form", {
    attrs: {
      form: _vm.signForm,
      "label-col": {
        span: 24
      },
      "wrapper-col": {
        span: 18
      },
      colon: true
    }
  }, [_c("a-form-item", [_c("div", {
    staticClass: "labels"
  }, [_c("p"), _vm._v(" 签署方式 ")]), _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["signType", {
        rules: [{
          required: false,
          message: "签署方式不能为空"
        }]
      }],
      expression: "['signType', { rules: [{ required: false, message: '签署方式不能为空' }] }]"
    }],
    staticStyle: {
      width: "360px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.selectSignWays
    }
  }, _vm._l(_vm.signedWay, function (form, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: form.value
      }
    }, [_vm._v(_vm._s(form.name))]);
  }), 1)], 1), _c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.personOnly,
      expression: "!personOnly"
    }]
  }, [_c("div", {
    staticClass: "labels"
  }, [_c("p"), _vm._v(" 加盖印章 ")]), _vm.$route.meta.pageType ? _c("div", [_c("a-select", {
    staticStyle: {
      width: "360px"
    },
    attrs: {
      disabled: true
    }
  })], 1) : _c("div", [_c("div", {
    staticClass: "tips"
  }, [_vm._v("选择该合同类型加盖的企业印章")]), _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["enterpriseSealId", {
        rules: [{
          required: false,
          message: "印章不能为空"
        }]
      }],
      expression: "['enterpriseSealId', { rules: [{ required: false, message: '印章不能为空' }] }]"
    }],
    staticStyle: {
      width: "360px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.watchChange
    }
  }, _vm._l(_vm.sealList, function (seal, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: seal.id
      }
    }, [_vm._v(_vm._s(seal.name))]);
  }), 1), _vm.sealList.length > 0 ? _c("span", {
    staticClass: "tips",
    staticStyle: {
      "margin-left": "16px",
      color: "#666666"
    }
  }, [_vm._v(" " + _vm._s(_vm.signForm.getFieldValue("enterpriseSealId") ? "已选择" : "未选择") + " "), _c("span", {
    staticStyle: {
      "margin-left": "4px",
      color: "#252525"
    }
  }, [_vm._v(_vm._s(_vm.sealFun(_vm.signForm.getFieldValue("enterpriseSealId"), "name")))])]) : _c("span", {
    staticClass: "tips",
    staticStyle: {
      "margin-left": "16px"
    }
  }, [_vm._v(" 无开通成功的电子印章， "), _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.createSeal
    }
  }, [_vm._v(" 去创建 ")])], 1), _vm.signForm.getFieldValue("enterpriseSealId") ? _c("div", {
    staticClass: "sealimg"
  }, [_c("img", {
    attrs: {
      src: _vm.sealFun(_vm.signForm.getFieldValue("enterpriseSealId"), "img"),
      alt: "",
      srcset: ""
    }
  })]) : _vm._e()], 1)]), !_vm.enterpriseOnly ? _c("a-form-item", [_c("div", {
    staticClass: "labels"
  }, [_c("p"), _vm._v(" 员工签署有效期 ")]), _c("div", {
    staticClass: "tips"
  }, [_vm._v("发起签署后，员工签署方如未在设定有效期间签订，合同将自动撤回")]), _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["expireDays", {
        rules: [{
          required: false,
          message: "员工签署有效期不能为空"
        }]
      }],
      expression: "['expireDays', { rules: [{ required: false, message: '员工签署有效期不能为空' }] }]"
    }],
    staticStyle: {
      width: "360px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.watchChange
    }
  }, _vm._l(_vm.validityDay, function (time, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: time.id
      }
    }, [_vm._v(_vm._s(time.day))]);
  }), 1)], 1) : _vm._e(), _c("a-form-item", [_c("div", {
    staticClass: "labels"
  }, [_c("p"), _vm._v(" 合同类型 ")]), _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["fileType", {
        rules: [{
          required: true,
          message: "合同类型不能为空"
        }]
      }],
      expression: "[\n            'fileType',\n            {\n              rules: [{ required: true, message: '合同类型不能为空' }]\n            }\n          ]"
    }],
    staticStyle: {
      width: "360px"
    },
    attrs: {
      placeholder: "请选择",
      "allow-clear": false
    },
    on: {
      change: function change($event) {
        return _vm.watchChange($event);
      }
    }
  }, _vm._l(_vm.enumeratelist, function (enumerate, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: enumerate.value
      }
    }, [_vm._v(_vm._s(enumerate.name))]);
  }), 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "编辑",
      visible: _vm.updateVisible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.confirmAddType,
      cancel: function cancel($event) {
        _vm.updateVisible = false;
      }
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      form: _vm.updateForm,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      },
      colon: true
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同模板名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '请输入' }] }]"
    }]
  })], 1), _vm.$route.meta.pageType ? _c("a-form-item", {
    attrs: {
      label: "选择分组"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["groupId", {
        initialValue: 0
      }, {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "['groupId', { initialValue: 0 }, { rules: [{ required: true, message: '请选择' }] }]"
    }]
  }, [_c("a-select-option", {
    attrs: {
      value: 0
    }
  }, [_vm._v("系统提供合同模板")])], 1)], 1) : _c("a-form-item", {
    attrs: {
      label: "选择分组"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["groupId", {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "['groupId', { rules: [{ required: true, message: '请选择' }] }]"
    }]
  }, _vm._l(_vm.groupList, function (group, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: group.id
      }
    }, [_vm._v(_vm._s(group.name))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "名称描述"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["description", {
        rules: [{
          required: false,
          message: "请输入"
        }]
      }],
      expression: "['description', { rules: [{ required: false, message: '请输入' }] }]"
    }],
    attrs: {
      maxLength: 255,
      placeholder: "请输入名称描述",
      rows: 4
    }
  })], 1)], 1)], 1)])], 1), _c("div", {
    staticClass: "btnlines",
    class: _vm.isContainByString("contractAdmin") ? "w-232" : ""
  }, [_c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveSign
    }
  }, [_vm._v("下一步")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };