import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper rmt"
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", [_c("a-select", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      placeholder: "选择企业",
      allowClear: ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.companyUnitId,
      callback: function callback($$v) {
        _vm.companyUnitId = $$v;
      },
      expression: "companyUnitId"
    }
  }, _vm._l(_vm.companyUnitIdArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1), _c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: "",
      placeholder: "搜索职位"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.positionName,
      callback: function callback($$v) {
        _vm.positionName = $$v;
      },
      expression: "positionName"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      mode: "multiple",
      placeholder: "职位类型",
      allowClear: ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.queryRecruitType,
      callback: function callback($$v) {
        _vm.queryRecruitType = $$v;
      },
      expression: "queryRecruitType"
    }
  }, _vm._l(_vm.queryRecruitTypeArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-col", [_c("a-button", {
    staticClass: "mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.generateLink
    }
  }, [_vm._v("生成招聘小程序链接")]), _vm.isCancel === "2" ? _c("a-button", {
    staticClass: "mr12",
    on: {
      click: _vm.resumeDeliverySettings
    }
  }, [_c("span", {
    staticClass: "icon iconfont",
    staticStyle: {
      "padding-right": "6px"
    }
  }, [_vm._v("")]), _vm._v(" 投递简历设置 ")]) : _vm._e(), _vm.isCancel === "2" ? _c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: _vm.onAllShare
    }
  }, [_vm._v(" 生成岗位链接 ")]) : _vm._e(), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onAdd
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "14px"
    },
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 发布职位 ")], 1)], 1)], 1), _c("a-alert", {
    staticClass: "glinfo",
    attrs: {
      type: "info",
      closable: ""
    }
  }, [_c("p", {
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_c("a-icon", {
    staticStyle: {
      color: "#4686ff",
      "margin-right": "2px"
    },
    attrs: {
      type: "exclamation-circle",
      theme: "filled"
    }
  }), _vm._v(" 快速创建 "), _c("a", {
    on: {
      click: function click($event) {
        return _vm.onJump("economic", "position");
      }
    }
  }, [_vm._v("经纪人岗位")]), _vm._v(" 和 "), _c("a", {
    on: {
      click: function click($event) {
        return _vm.onJump("push", "position");
      }
    }
  }, [_vm._v("内推岗位")])], 1)]), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      rowSelection: _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
        getCheckboxProps: function getCheckboxProps(record) {
          return {
            props: {
              disabled: record.companyUnitsVO === null
            }
          };
        }
      } : null
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "postName" ? _c("div", {
            key: index,
            staticClass: "table-col"
          }, [_c("div", {
            staticClass: "table-name",
            on: {
              click: function click($event) {
                return _vm.showUpdateModal(record);
              }
            }
          }, [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [record.postName ? _c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(record.postName))])]) : _vm._e(), _c("div", {
            staticClass: "table-postName"
          }, [_vm._v(_vm._s(record.postName))])], 2), record.isEmergency ? _c("div", {
            staticClass: "t-status isEmergency"
          }, [_vm._v("急")]) : _vm._e(), record.isEmp === 1 ? _c("div", {
            staticClass: "t-status isEmp"
          }, [_vm._v("内推")]) : _vm._e(), record.isBroker === 1 ? _c("div", {
            staticClass: "t-status isBroker"
          }, [_vm._v("经纪人")]) : _vm._e()], 1), record.cityName.split(",")[1] ? _c("div", {
            staticClass: "table-desc mb12",
            staticStyle: {
              "text-align": "left"
            }
          }, [_vm._v(" " + _vm._s(record.cityName.split(",")[1].split("/").join("-")) + " / "), record.workingSeniority === 1 ? _c("span", [_vm._v("不限")]) : _vm._e(), record.workingSeniority === 2 ? _c("span", [_vm._v("应届生")]) : _vm._e(), record.workingSeniority === 3 ? _c("span", [_vm._v("1-3年")]) : _vm._e(), record.workingSeniority === 4 ? _c("span", [_vm._v("3-5年")]) : _vm._e(), record.workingSeniority === 5 ? _c("span", [_vm._v("5-10年")]) : _vm._e(), record.workingSeniority === 6 ? _c("span", [_vm._v("10年以上")]) : _vm._e(), _vm._v(" / "), record.lowestEducation === 1 ? _c("span", [_vm._v("初中")]) : _vm._e(), record.lowestEducation === 2 ? _c("span", [_vm._v("中专")]) : _vm._e(), record.lowestEducation === 3 ? _c("span", [_vm._v("大专")]) : _vm._e(), record.lowestEducation === 4 ? _c("span", [_vm._v("高中")]) : _vm._e(), record.lowestEducation === 5 ? _c("span", [_vm._v("本科")]) : _vm._e(), record.lowestEducation === 6 ? _c("span", [_vm._v("硕士")]) : _vm._e(), record.lowestEducation === 7 ? _c("span", [_vm._v("博士")]) : _vm._e(), record.lowestEducation === 8 ? _c("span", [_vm._v("其他")]) : _vm._e(), _c("span", [_vm._v("以上/")]), _c("span", [record.highestSalary === record.lowestSalary ? _c("span", [_vm._v(_vm._s(_vm._f("nFormatter")(record.highestSalary)) + "元")]) : _c("span", [_vm._v(_vm._s(_vm._f("nFormatter")(record.lowestSalary)) + " - " + _vm._s(_vm._f("nFormatter")(record.highestSalary)) + "元")])]), _vm._v("/ "), record.salaryGrantType == 0 ? _c("span", [_vm._v("小时")]) : _vm._e(), record.salaryGrantType == 1 ? _c("span", [_vm._v("日")]) : _vm._e(), record.salaryGrantType == 2 ? _c("span", [_vm._v("月")]) : _vm._e(), record.salaryGrantType == 3 ? _c("span", [_vm._v("年")]) : _vm._e()]) : _vm._e(), _c("a-tooltip", {
            attrs: {
              placement: "bottom"
            }
          }, [record.companyUnitsVO ? _c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(record.companyUnitsVO.name))])]) : _vm._e(), _c("div", {
            staticClass: "table-desc companyUnitsVOName",
            staticStyle: {
              "text-align": "left"
            }
          }, [_vm._v(" " + _vm._s(record.companyUnitsVO != null ? record.companyUnitsVO.name : "企业已删除") + " ")])], 2)], 1) : _vm._e(), col.dataIndex === "candidateCount" ? _c("div", {
            key: index,
            staticClass: "table-col table-col-hover",
            on: {
              click: function click($event) {
                return _vm.onView(record.id, "1");
              }
            }
          }, [_c("div", {
            staticClass: "table-status-t"
          }, [_vm._v(" " + _vm._s(record.candidateCount) + " ")]), _c("div", {
            staticClass: "table-desc"
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])]) : _vm._e(), col.dataIndex === "faceToFaceCount" ? _c("div", {
            key: index,
            staticClass: "table-col table-col-hover",
            on: {
              click: function click($event) {
                return _vm.onView(record.id, "2");
              }
            }
          }, [_c("div", {
            staticClass: "table-status-t"
          }, [_vm._v(" " + _vm._s(record.faceToFaceCount) + " ")]), _c("div", {
            staticClass: "table-desc"
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])]) : _vm._e(), col.dataIndex === "toBeEmployedCount" ? _c("div", {
            key: index,
            staticClass: "table-col table-col-hover",
            on: {
              click: function click($event) {
                return _vm.onView(record.id, "3");
              }
            }
          }, [_c("div", {
            staticClass: "table-status-t"
          }, [_vm._v(" " + _vm._s(record.toBeEmployedCount) + " ")]), _c("div", {
            staticClass: "table-desc"
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])]) : _vm._e(), col.dataIndex === "recruitedCount" ? _c("div", {
            key: index,
            staticClass: "table-col table-col-hover",
            on: {
              click: function click($event) {
                return _vm.onView(record.id, "4");
              }
            }
          }, [_c("div", {
            staticClass: "table-status-t"
          }, [_vm._v(" " + _vm._s(record.recruitedCount) + " ")]), _c("div", {
            staticClass: "table-desc"
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])]) : _vm._e(), col.dataIndex === "failCount" ? _c("div", {
            key: index,
            staticClass: "table-col table-col-hover",
            on: {
              click: function click($event) {
                return _vm.onView(record.id, "5");
              }
            }
          }, [_c("div", {
            staticClass: "table-status-t"
          }, [_vm._v(" " + _vm._s(record.failCount) + " ")]), _c("div", {
            staticClass: "table-desc"
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])]) : _vm._e(), col.dataIndex === "gmtCreate" ? _c("div", {
            key: index,
            staticClass: "table-col",
            staticStyle: {
              "text-align": "center"
            }
          }, [_c("div", {
            staticClass: "table-desc",
            staticStyle: {
              color: "#a6a8b4",
              "margin-top": "40px"
            }
          }, [_vm._v(_vm._s(_vm.moment(record.gmtCreate).format("YYYY-MM-DD")) + " 发布")])]) : _vm._e(), col.dataIndex === "tableAction" ? _c("div", {
            key: index,
            staticClass: "table-col",
            staticStyle: {
              "border-right": "1px solid #e8e8e8"
            }
          }, [_c("div", {
            staticStyle: {
              "border-radius": "4px 0 0 4px",
              "margin-top": "40px",
              "text-align": "center"
            }
          }, [_c("a", {
            on: {
              click: function click($event) {
                return _vm.showUpdateModal(record);
              }
            }
          }, [_vm._v("详情")]), _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a-popconfirm", {
            attrs: {
              disabled: record.companyUnitsVO === null,
              title: "\u786E\u8BA4\u8981".concat(_vm.isCancel === "2" ? "关闭" : "打开", "\u5417\uFF1F"),
              "ok-text": "确认",
              "cancel-text": "取消"
            },
            on: {
              confirm: function confirm($event) {
                return _vm.close(record.id);
              }
            }
          }, [_c("a", {
            attrs: {
              disabled: record.companyUnitsVO === null
            }
          }, [_vm._v(_vm._s(_vm.isCancel === "2" ? "关闭" : "打开"))])]), _vm.isCancel === "2" ? _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }) : _vm._e(), _vm.isCancel === "2" ? _c("a", {
            on: {
              click: function click($event) {
                return _vm.onShare(record.id);
              }
            }
          }, [_vm._v("分享")]) : _vm._e()], 1)]) : _vm._e()];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"],
      "page-size": _vm.pageSize
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1), _vm.selectedRowKeys.length > 0 ? _c("div", {
    staticClass: "r-table-footer"
  }, [_c("div", [_vm._v(" 选中职位   " + _vm._s(_vm.selectedRowKeys.length) + " 个      "), _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        _vm.selectedRowKeys = [];
      }
    }
  }, [_vm._v(" 清除 ")])], 1), _c("div", [_vm.isCancel === "1" ? _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.allClose("打开");
      }
    }
  }, [_vm._v("打开职位")]) : _vm._e(), _vm.isCancel === "2" ? _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.allClose("关闭");
      }
    }
  }, [_vm._v("关闭职位")]) : _vm._e()], 1)]) : _vm._e(), _c("share", {
    attrs: {
      visible: _vm.visible,
      shareIdArr: _vm.shareIdArr,
      shareType: "poster"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };