import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import customTree from "./memberTree";
import { getDepEmpInfo } from "@/api/company/contract";
export default {
  components: {
    customTree: customTree
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    number: {
      type: Number,
      default: 0
    },
    defaultSelectedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isRadio: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      treeData: [],
      selectedDepartmentList: [],
      selectedEmployeeList: [],
      unSelectedEmployeeList: [],
      selectedListTemp: [],
      selectedKeysBeforeSearch: []
    };
  },
  watch: {
    show: function show(val) {
      if (val) {
        if (this.defaultSelectedKeys.length !== this.selectedListTemp.length) {
          this.getSelectDepTree();
        }
      }
    }
  },
  mounted: function mounted() {
    this.getSelectDepTree();
  },
  methods: {
    onChange: function onChange(item) {
      this.ergodic(item);
    },
    ergodicInit: function ergodicInit(isSearch) {
      this.selectedListTemp = [];
      this.unSelectedEmployeeList = [];
      this.selectedEmployeeList = [].concat(this.defaultSelectedKeys, isSearch ? this.selectedKeysBeforeSearch : []);
      this.ergodicEmployeeSelected(this.treeData);
      this.ergodicEmployeeList(this.treeData);
      this.ergodicList(this.treeData);
    },
    ergodic: function ergodic(item) {
      if (!item.isDept || !this.isRadio) {
        this.selectedListTemp = [];
      }
      this.unSelectedEmployeeList = [];
      if (item.isDept) {
        if (this.isRadio) {
          item.isSelected = false;
          return;
        }
        this.ergodicDepartmentSelected(this.treeData, item, item.isSelected);
      } else {
        if (!item.isSelected) {
          this.unSelectedEmployeeList.push(item.id);
        } else {
          if (this.isRadio) {
            this.selectedEmployeeList = [item.id];
          }
        }
      }
      if (!this.isRadio) {
        this.selectedEmployeeList = [];
      }
      this.ergodicEmployeeSelected(this.treeData);
      this.ergodicEmployeeList(this.treeData);
      this.ergodicList(this.treeData);
    },
    ergodicDepartmentSelected: function ergodicDepartmentSelected(data, obj, selected) {
      var _this = this;
      if (obj.isDept) {
        data.filter(function (item) {
          return item.isDept;
        }).map(function (item) {
          if (item.id === obj.id) {
            if (item.hasChild) {
              _this.ergodicSelectAll(item.childList, selected);
            }
          } else {
            if (item.hasChild) {
              _this.ergodicDepartmentSelected(item.childList, obj, selected);
            }
          }
        });
      }
    },
    ergodicSelectAll: function ergodicSelectAll(data, selected) {
      var _this2 = this;
      data.map(function (item) {
        item.isSelected = selected;
        if (item.hasChild) {
          _this2.ergodicSelectAll(item.childList, selected);
        }
        if (!item.isDept && !selected && !_this2.unSelectedEmployeeList.includes(item.id)) {
          _this2.unSelectedEmployeeList.push(item.id);
        }
      });
    },
    ergodicEmployeeSelected: function ergodicEmployeeSelected(data) {
      var _this3 = this;
      data.map(function (item) {
        if (item.isDept) {
          if (item.hasChild) {
            _this3.ergodicEmployeeSelected(item.childList);
          }
        } else {
          if (item.isSelected && !_this3.isRadio) {
            if (!_this3.selectedEmployeeList.includes(item.id)) {
              _this3.selectedEmployeeList.push(item.id);
            }
          }
        }
      });
    },
    ergodicEmployeeList: function ergodicEmployeeList(data) {
      var _this4 = this;
      data.map(function (item) {
        if (item.isDept) {
          if (item.hasChild) {
            _this4.ergodicEmployeeList(item.childList);
          }
        } else {
          if (_this4.selectedEmployeeList.includes(item.id) && !_this4.unSelectedEmployeeList.includes(item.id)) {
            if (_this4.selectedListTemp.findIndex(function (it) {
              return it.id === item.id;
            }) === -1) {
              if (_this4.isRadio) {
                _this4.selectedListTemp = [];
                _this4.selectedListTemp.push(item);
              } else if (!_this4.isRadio) {
                _this4.selectedListTemp.push(item);
              }
            }
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        }
      });
    },
    ergodicList: function ergodicList(data, parent) {
      var list = data.filter(function (item) {
        return item.hasChild;
      });
      if (list.length > 0) {
        for (var i = 0; i < list.length; i++) {
          this.ergodicList(list[i].childList, list[i]);
        }
        if (parent) {
          parent.isSelected = data.length === data.filter(function (item) {
            return item.isSelected;
          }).length;
        }
      } else {
        if (parent) {
          parent.isSelected = data.length === data.filter(function (item) {
            return item.isSelected;
          }).length;
        }
      }
    },
    // 获取部门树
    getSelectDepTree: function getSelectDepTree(query) {
      var _this5 = this;
      var params = {
        onlyCurrentDep: true,
        onlyUser: true
      };
      if (query) {
        params["empName"] = query;
      }
      getDepEmpInfo(this.$store.state.hr.companyId, params).then(function (res) {
        _this5.treeData = _this5.formatCompanyData(res.data);
        _this5.ergodicInit(true);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    formatCompanyData: function formatCompanyData(company) {
      var childList = [];
      if (company.depChildList && company.depChildList.length > 0) {
        childList = childList.concat(this.toTree(company.depChildList));
      }
      if (company.empDirectList && company.empDirectList.length > 0) {
        childList = childList.concat(this.formatEmployeeData(company.empDirectList));
      }
      if (company.noDepEmpList && company.noDepEmpList.length > 0) {
        childList = childList.concat(this.formatEmployeeData(company.noDepEmpList));
      }
      return [{
        id: company.id,
        name: company.name,
        isDept: true,
        isSelected: false,
        hasChild: childList.length > 0,
        childList: childList
      }];
    },
    formatEmployeeData: function formatEmployeeData(employee) {
      return employee.map(function (item) {
        return {
          id: item.id,
          name: item.name,
          phone: item.phone,
          idCard: item.idCard,
          depName: item.depName,
          positionName: item.positionName,
          isDept: false,
          isSelected: false,
          hasChild: false
        };
      });
    },
    toTree: function toTree(data) {
      var _this6 = this;
      var data_new = data.map(function (item) {
        var arr = [];
        if (item.depChildList && item.depChildList.length > 0) {
          arr = arr.concat(_this6.toTree(item.depChildList));
        }
        if (item.empDirectList && item.empDirectList.length > 0) {
          arr = arr.concat(_this6.formatEmployeeData(item.empDirectList));
        }
        if (arr.length > 0) {
          return {
            id: item.id,
            name: item.name,
            isDept: true,
            isSelected: false,
            hasChild: arr.length > 0,
            childList: arr
          };
        } else {
          return null;
        }
      });
      return data_new.filter(function (item) {
        return !!item;
      });
    },
    searchPeople: function searchPeople(query) {
      this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
        return item.id;
      });
      this.getSelectDepTree(query);
    },
    deleteItem: function deleteItem(item) {
      item.isSelected = false;
      this.ergodic(item);
    },
    ok: function ok() {
      this.$emit("update:selectedList", this.selectedListTemp);
      this.$emit("update:selectThree", this.selectedListTemp.slice(0, 3).map(function (item) {
        return item.name;
      }).join(","));
      this.$emit("update:show", false);
      this.$emit("change", this.selectedListTemp);
    },
    cancel: function cancel() {
      this.$emit("update:show", false);
    }
  }
};