var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sort page-miH page-bgBack"
  }, [_c("a-button", {
    attrs: {
      icon: "left",
      type: "link"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")]), _c("div", {
    staticClass: "view"
  }, [_c("router-view")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };