export default {
  name: "AppMain",
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  watch: {},
  created: function created() {},
  methods: {}
};