import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      width: 720,
      title: "选择职位",
      visible: _vm.visible,
      okText: "下一步"
    },
    on: {
      ok: _vm.ok,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    }
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "employee-selector-wrapper2"
  }, [_c("div", [_c("div", {
    staticClass: "employee-selector-block"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "搜索职位名称"
    },
    on: {
      search: _vm.searchPeople,
      change: _vm.changePeople
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c("div", {
    staticClass: "tree-item2",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", [_vm._v("全选当页")]), _vm.isAllSelected ? _c("xq-icon", {
    staticClass: "iconStyle",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "icon-wancheng2"
    },
    on: {
      click: function click($event) {
        return _vm.choseAll(_vm.treeData);
      }
    }
  }) : _c("img", {
    staticStyle: {
      width: "18px",
      height: "18px",
      "margin-left": "10px"
    },
    attrs: {
      src: require("@/assets/icons/未选择.png")
    },
    on: {
      click: function click($event) {
        return _vm.choseAll(_vm.treeData);
      }
    }
  })], 1), _c("div", {
    staticClass: "employee-selector",
    staticStyle: {
      position: "relative"
    }
  }, _vm._l(_vm.treeData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "tree-item",
      class: item.isSelected ? "selectStyle" : "",
      on: {
        click: function click($event) {
          return _vm.chose(item, _vm.treeData);
        }
      }
    }, [_c("div", {
      staticClass: "tree-item-div"
    }, [_c("img", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "margin-right": "10px"
      },
      attrs: {
        src: require("@/assets/icons/person1.png")
      }
    }), _c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.postName))])]), _c("div", {
      staticClass: "overflows overflowsWL"
    }, [_vm._v(" " + _vm._s(item.postName) + " ")])], 2), _c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.lowestSalary) + "元 - " + _vm._s(item.highestSalary) + "元")])]), _c("div", {
      staticClass: "overflows overflowsWLM"
    }, [_vm._v(_vm._s(item.lowestSalary) + "元 - " + _vm._s(item.highestSalary) + "元")])], 2)], 1), item.isSelected ? _c("xq-icon", {
      staticClass: "iconStyle",
      attrs: {
        type: "icon-wancheng2"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.chose(item, _vm.treeData);
        }
      }
    }) : _c("img", {
      staticStyle: {
        width: "18px",
        height: "18px"
      },
      attrs: {
        src: require("@/assets/icons/未选择.png")
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.chose(item, _vm.treeData);
        }
      }
    })], 1)]);
  }), 0), _c("a-pagination", {
    staticStyle: {
      "margin-top": "12px",
      "text-align": "right"
    },
    attrs: {
      size: "small",
      "page-size-options": _vm.pageSizeOptions,
      total: _vm.total,
      "show-size-changer": "",
      "show-less-items": "",
      "page-size": _vm.pageSize
    },
    on: {
      showSizeChange: _vm.onShowSizeChange,
      change: _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "buildOptionText",
      fn: function fn(props) {
        return [_c("span", [_vm._v(_vm._s(props.value) + "条/页")])];
      }
    }]),
    model: {
      value: _vm.current,
      callback: function callback($$v) {
        _vm.current = $$v;
      },
      expression: "current"
    }
  })], 1), _c("div", {
    staticClass: "employee-selector-block2"
  }, [_c("div", {
    staticClass: "employee-selector-title"
  }, [_c("div", [_vm._v(" 已经选择职位 "), _c("span", {
    staticClass: "selectStyle"
  }, [_vm._v(_vm._s(_vm.selectedListTemp.length))])]), _vm.selectedListTemp.length ? _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.remove
    }
  }, [_vm._v(" 清除 ")]) : _vm._e()], 1), _c("div", {
    staticClass: "employee-selector"
  }, _vm._l(_vm.selectedListTemp, function (item, i) {
    return _c("div", {
      key: i,
      staticClass: "employee-selector-item"
    }, [_c("div", {
      staticClass: "tree-item"
    }, [_c("div", {
      staticClass: "tree-item-div"
    }, [_c("img", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "margin-right": "10px"
      },
      attrs: {
        src: require("@/assets/icons/person1.png")
      }
    }), _c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.postName))])]), _c("div", {
      staticClass: "overflows overflowsWR"
    }, [_vm._v(" " + _vm._s(item.postName) + " ")])], 2)], 1)]), _c("img", {
      staticStyle: {
        width: "14px",
        height: "14px",
        cursor: "pointer"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(item, i);
        }
      }
    })]);
  }), 0)])])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };