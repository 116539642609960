import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { AreaPicker as city } from "xqjr-plugin-common";
import shebaoTable from "./shebaoTable";
import onlyAmountTable from "./onlyAmountTable";
import SelectWithInputAdd from "xqjr-plugin-form/components/XqSelectWithInputAdd.vue";
export default {
  components: {
    city: city,
    shebaoTable: shebaoTable,
    onlyAmountTable: onlyAmountTable,
    SelectWithInputAdd: SelectWithInputAdd
  },
  data: function data() {
    return {
      socAccount: undefined,
      meAccount: undefined,
      pfAccount: undefined,
      showBackground: false,
      mode: "1",
      progId: "",
      progName: "",
      deductionsDate: 1,
      onlySocAmount: false,
      socCityName: "",
      socCity: "",
      onlyAmount: 0,
      servercost: 0
    };
  },
  methods: {
    hideMenu: function hideMenu() {
      this.showBackground = false;
      if (this.mode === "1") {
        this.$refs.bili.hideItemShow();
      } else {
        this.$refs.guding.hideItemShow();
      }
    },
    showMenu: function showMenu() {
      this.showBackground = true;
    },
    // 计算公积金公司总金额和个人总金额
    handleDefault: function handleDefault(value) {
      if (value === null) {
        this.deductionsDate = 1;
      }
    },
    onChangeTab: function onChangeTab(e) {
      this.mode = e.target.value;
    },
    // 处理城市
    handleCity: function handleCity(e) {
      if (e.value.length > 0) {
        var value = e.value;
        var label = e.label;
        this.socCity = value[value.length - 1].toString();
        if (label.length && label.length === 3) {
          this.socCityName = label[0] + "/" + label[1] + "/" + label[2];
        } else if (label.length && label.length === 2) {
          this.socCityName = label[0] + "/" + label[1];
        } else if (label.length && label.length === 1) {
          this.socCityName = label[0];
        }
      } else {
        this.socCity = "";
        this.socCityName = "";
      }
    }
  }
};