import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      title: _vm.show ? "\u7F16\u8F91".concat(_vm.name) : "\u65B0\u589E".concat(_vm.name),
      visible: _vm.visible,
      width: 520,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: _vm.name === "工种" ? "工种名称" : "考勤状态"
    }
  }, [_vm.name === "工种" ? _c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["configurationStatus", {
        rules: [{
          required: true,
          message: "工种名称不能为空"
        }]
      }],
      expression: "['configurationStatus', { rules: [{ required: true, message: '工种名称不能为空' }] }]"
    }],
    staticStyle: {
      width: "248px"
    },
    attrs: {
      maxLength: 20,
      placeholder: "不得超过20个字"
    }
  }) : _c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["configurationStatus", {
        rules: [{
          required: true,
          message: "考勤状态不能为空"
        }]
      }],
      expression: "['configurationStatus', { rules: [{ required: true, message: '考勤状态不能为空' }] }]"
    }],
    staticClass: "inputs",
    staticStyle: {
      width: "248px"
    },
    attrs: {
      max: 99999999999999999999,
      min: 0,
      precision: 0,
      placeholder: "只能输入数字"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: _vm.name === "工种" ? "工种说明" : "状态说明"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["configurationExplain"],
      expression: "['configurationExplain']"
    }],
    staticStyle: {
      width: "248px"
    },
    attrs: {
      maxLength: 20,
      placeholder: "不得超过20个字"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: _vm.name === "工种" ? "基本工资" : "工资影响"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["sallaryImpact", {
        rules: [{
          required: true,
          message: _vm.name === "工种" ? "基本工资不能为空" : "工资影响不能为空"
        }]
      }],
      expression: "[\n            'sallaryImpact',\n            { rules: [{ required: true, message: name === '工种' ? '基本工资不能为空' : '工资影响不能为空' }] }\n          ]"
    }],
    staticStyle: {
      width: "100px"
    },
    attrs: {
      step: 0.01,
      placeholder: "请输入"
    }
  }), _vm._v(" " + _vm._s(_vm.name === "工种" ? "元" : "%") + " ")], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };