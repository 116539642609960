import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
import { selectByCompanyId, addSalaryTemplate, deleteSalaryTemplate, fixSalaryTemplate, haveUsedTemplate } from "@/api/wages/file";
var fixItems = [{
  id: 1,
  name: "基本工资",
  selected: true,
  isDefault: true
}, {
  id: 2,
  name: "交通补贴",
  selected: false,
  isDefault: false
}, {
  id: 3,
  name: "住房补贴",
  selected: false,
  isDefault: false
}, {
  id: 4,
  name: "全勤奖",
  selected: false,
  isDefault: false
}, {
  id: 5,
  name: "餐补",
  selected: false,
  isDefault: false
}, {
  id: 6,
  name: "高温补贴",
  selected: false,
  isDefault: false
}, {
  id: 7,
  name: "工龄补贴",
  selected: false,
  isDefault: false
}, {
  id: 8,
  name: "夜班津贴",
  selected: false,
  isDefault: false
}, {
  id: 9,
  name: "通讯补贴",
  selected: false,
  isDefault: false
}, {
  id: 10,
  name: "节假日补贴",
  selected: false,
  isDefault: false
}, {
  id: 11,
  name: "员工慰问金",
  selected: false,
  isDefault: false
}, {
  id: 12,
  name: "推荐奖",
  selected: false,
  isDefault: false
}, {
  id: 13,
  name: "职务津贴",
  selected: false,
  isDefault: false
}, {
  id: 14,
  name: "绩效工资",
  selected: false,
  isDefault: false
}, {
  id: 15,
  name: "季度奖",
  selected: false,
  isDefault: false
}, {
  id: 16,
  name: "销售提成",
  selected: false,
  isDefault: false
}, {
  id: 17,
  name: "计件工资",
  selected: false,
  isDefault: false
}, {
  id: 18,
  name: "工龄工资",
  selected: false,
  isDefault: false
}, {
  id: 19,
  name: "司龄工资",
  selected: false,
  isDefault: false
}, {
  id: 20,
  name: "职称工资",
  selected: false,
  isDefault: false
}, {
  id: 21,
  name: "技能工资",
  selected: false,
  isDefault: false
}, {
  id: 22,
  name: "学历工资",
  selected: false,
  isDefault: false
}];
export default {
  data: function data() {
    return {
      companyId: "",
      fixItems: fixItems,
      fixData: [],
      addVisible: false,
      editVisible: false,
      deleteVisible: false,
      fixVisible: false,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      addForm: {
        name: ""
      },
      editForm: {
        name: ""
      },
      rules: {
        name: [{
          required: true,
          message: "必填",
          trigger: "blur"
        }]
      },
      deleteId: "",
      editId: "",
      editIndex: 0,
      editRecord: {}
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      var data = {
        companyId: this.companyId
      };
      selectByCompanyId(data).then(function (res) {
        _this.fixData = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleAddOk: function handleAddOk() {
      var _this2 = this;
      var arr = this.fixItems.filter(function (item, index) {
        if (item.selected) return true;
      });
      var data = {
        companyId: this.companyId,
        isDefault: false,
        name: this.addForm.name,
        itemIds: arr.map(function (item) {
          return item.id;
        }).join(",")
      };
      this.$refs.addForm.validate(function (valid) {
        if (valid) {
          addSalaryTemplate(data).then(function (res) {
            _this2.addVisible = false;
            _this2.init();
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        }
      });
    },
    handleAddSelected: function handleAddSelected(item) {
      if (item.id === 1) return;
      item.selected = !item.selected;
    },
    handleAddTemplate: function handleAddTemplate() {
      this.addVisible = true;
      this.addForm = {};
      this.fixItems.forEach(function (item, index) {
        if (index > 0) item.selected = false;
      });
    },
    handleAddCancel: function handleAddCancel() {
      this.$refs.addForm.clearValidate();
    },
    computeItemClass: function computeItemClass(item) {
      if (item.id === 1) return "fix-salary-item-tag fix-salary-item-default";
      if (item.selected) return "fix-salary-item-tag fix-salary-item-selected";
      return "fix-salary-item-tag";
    },
    handleEditTemplate: function handleEditTemplate(record, index) {
      this.editId = record.id;
      this.editIndex = index;
      this.editRecord = JSON.parse(JSON.stringify(record));
      this.editForm.name = record.name;
      var arr = record.itemIds.split(",");
      this.fixItems.forEach(function (item, index) {
        if (arr.includes(String(item.id))) item.selected = true;else item.selected = false;
      });
      this.editVisible = true;
    },
    handleDeleteTemplate: function handleDeleteTemplate(id) {
      this.deleteId = id;
      this.deleteVisible = true;
    },
    handleEditOk: function handleEditOk() {
      var _this3 = this;
      this.$refs.editForm.validate(function (valid) {
        if (valid) {
          var arr = [];
          _this3.fixItems.forEach(function (item, index) {
            if (item.selected) arr.push(item.id);
          });
          var data = {
            fixedSalaryTemplateId: _this3.editId,
            itemIds: arr.join(",")
          };
          haveUsedTemplate(data).then(function (res) {
            var flag = res.data;
            if (flag) _this3.editTemplate();else {
              _this3.editVisible = false;
              _this3.fixVisible = true;
            }
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        }
      });
    },
    editTemplate: function editTemplate() {
      var _this4 = this;
      var arr = this.fixItems.filter(function (item, index) {
        if (item.selected) return true;
      });
      var data = {
        id: this.editId,
        companyId: this.companyId,
        isDefault: this.editIndex === 0,
        name: this.editForm.name,
        itemIds: arr.map(function (item) {
          return item.id;
        }).join(",")
      };
      fixSalaryTemplate(data).then(function (res) {
        _this4.editVisible = false;
        _this4.fixVisible = false;
        _this4.init();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleEditCancel: function handleEditCancel() {
      this.$refs.editForm.clearValidate();
    },
    deleteOk: function deleteOk() {
      var _this5 = this;
      deleteSalaryTemplate(this.deleteId).then(function (res) {
        _this5.init();
        _this5.$message.success("删除成功!");
        _this5.deleteVisible = false;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    fixOk: function fixOk() {
      this.editTemplate();
    }
  }
};