var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper pl24 pr24 pb48"
  }, [_c("div", {
    staticClass: "xq-form-search"
  }, [_c("a-month-picker", {
    attrs: {
      "allow-clear": false,
      valueFormat: "YYYYMM",
      placeholder: "税款所属月"
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.month,
      callback: function callback($$v) {
        _vm.month = $$v;
      },
      expression: "month"
    }
  })], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      "row-selection": _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      } : null
    },
    on: {
      change: _vm.onTableChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(_vm._s(col.customTitle))])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "status" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(_vm.peopleStatusJson[text]))]) : col.dataIndex === "employmentType" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(_vm.employmentTypeJson[text]))]) : col.dataIndex === "submissionState" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(_vm.submissionStateJson[text]))]) : col.dataIndex === "authenticationState" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(_vm.authenticationStateJson[text]))]) : col.dataIndex === "employeeVO.licenseType" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(_vm.licenseTypeJson[text]))]) : col.dataIndex === "employeeVO.gender" ? _c("div", {
            key: index
          }, [text == 1 ? _c("span", [_vm._v("男")]) : _vm._e(), text == 2 ? _c("span", [_vm._v("女")]) : _vm._e()]) : col.dataIndex === "employeeVO.birthday" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text))]) : col.dataIndex === "employeeVO.nationality" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text))]) : col.dataIndex === "personalInvestmentRatio" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text ? "".concat(text, "%") : ""))]) : col.dataIndex === "employeeVO.isDisable" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text ? "是" : text === false ? "否" : ""))]) : col.dataIndex === "employeeVO.isMartyr" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text ? "是" : text === false ? "否" : ""))]) : col.dataIndex === "employeeVO.isOld" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text ? "是" : text === false ? "否" : ""))]) : col.dataIndex === "employeeVO.disableCard" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text))]) : col.dataIndex === "employeeVO.martyrCard" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text))]) : col.dataIndex === "isDeductDeductible" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text ? "是" : text === false ? "否" : ""))]) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1), _c("div", {
    staticClass: "table-body-footer",
    style: {
      width: "calc(100vw - 188px)",
      left: "188px"
    }
  }, [_c("a-button", {
    staticClass: "mr12",
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSetUp
    }
  }, [_vm._v("下一步")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };