export default {
  name: "AddPayslipModal",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      list: [1]
    };
  },
  methods: {
    // 确定
    handleOk: function handleOk(item) {
      this.$emit("change", {
        item: item,
        path: "/wages/add-payslip"
      });
    },
    // 取消
    cancel: function cancel() {
      this.$emit("change");
    }
  }
};