var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      width: "100%",
      height: "100%"
    }
  }, [_vm.show_loading ? _c("div", {
    staticClass: "global_spinning"
  }, [_vm.systemTitle === "橙事新人" ? _c("img", {
    attrs: {
      src: require("../assets/spinningCSXR.gif"),
      alt: ""
    }
  }) : _vm.systemTitle === "星企途" ? _c("img", {
    attrs: {
      src: require("../assets/spinningFjqy.gif"),
      alt: ""
    }
  }) : _vm.systemTitle === "卫人云" ? _c("img", {
    attrs: {
      src: require("../assets/spinningWry.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("../assets/spinning.gif"),
      alt: ""
    }
  })]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };