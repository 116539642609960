import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import { mapState } from "vuex";
import VueWangeditor from "@/components/ZXR/vueWangeditor";
import { fileListDecode, fileListEncode } from "@/utils/tools";
export default {
  name: "",
  components: {
    VueWangeditor: VueWangeditor
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      institutionTypeId: undefined,
      companyId: undefined,
      institutionTitle: "",
      isOpen: true,
      institutionUrl: [],
      institutionContent: "",
      plainText: "",
      content: "",
      contentHasStyle: "",
      groupLst: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    companyList: function companyList() {
      return this.hr.companyList;
    },
    companyLst: function companyLst() {
      return this.companyList.map(function (item) {
        return {
          value: item.id,
          label: item.name
        };
      });
    },
    cId: function cId() {
      return this.$route.query.cId;
    },
    tId: function tId() {
      return this.$route.query.tId;
    },
    institutionId: function institutionId() {
      return this.$route.query.id;
    },
    institutionDetail: function institutionDetail() {
      var temp = localStorage.getItem("tempZhidu");
      if (temp) {
        return JSON.parse(temp);
      }
      return null;
    }
  }),
  watch: {},
  created: function created() {
    this.onGroupSearchChange();
    if (!this.institutionId) {
      this.companyId = this.cId;
      this.institutionTypeId = this.tId;
    } else {
      this.institutionTypeId = this.institutionDetail.institutionTypeId;
      this.companyId = this.institutionDetail.companyId;
      this.institutionTitle = this.institutionDetail.institutionTitle;
      this.isOpen = this.institutionDetail.isOpen;
      this.institutionUrl = this.institutionDetail.institutionUrl ? fileListEncode(this.institutionDetail.institutionUrl) : [];
      this.institutionContent = this.institutionDetail.institutionContent;
    }
  },
  mounted: function mounted() {},
  methods: {
    doSave: function doSave() {
      var _this = this;
      var newValues = {
        institutionTypeId: this.institutionTypeId,
        companyId: this.companyId,
        institutionTitle: this.institutionTitle,
        isOpen: this.isOpen,
        institutionUrl: fileListDecode(this.institutionUrl),
        institutionContent: this.institutionContent,
        plainText: this.plainText
      };
      if (!this.institutionTypeId || !this.companyId || !this.institutionTitle) {
        this.$message.warning("必须选择分类、用工单位，并填写标题");
        return false;
      }
      if (this.institutionId) {
        this.$request({
          url: "/hr/api/v1/institutions",
          method: "put",
          data: _objectSpread({
            id: this.institutionId
          }, newValues)
        }).then(function (res) {
          _this.$router.back();
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        this.$request({
          url: "/hr/api/v1/institutions",
          method: "post",
          data: newValues
        }).then(function (res) {
          _this.$router.back();
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    onEditorChange: function onEditorChange(html, text) {
      this.contentHasStyle = html;
      this.content = text;
      this.plainText = text;
    },
    onFileChange: function onFileChange(_ref) {
      var file = _ref.file,
        fileList = _ref.fileList;
      this.institutionUrl = fileList;
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    onGroupSearchChange: function onGroupSearchChange() {
      var _this2 = this;
      this.$request({
        url: "/hr/api/v1/institutionTypes/selectBySite",
        params: {
          companyId: this.cId
        }
      }).then(function (res) {
        // const temp = res.data.map((item) => ({
        //   value: item.id,
        //   label: item.institutionTypeName
        // }));
        _this2.groupLst = res.data.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            disabled: true
          });
        });
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};