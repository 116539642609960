import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    staticClass: "contentBox",
    attrs: {
      footer: null,
      visible: _vm.visible,
      title: "工资详情",
      width: "423px"
    },
    on: {
      cancel: _vm.cancel
    }
  }, [_c("div", {
    staticClass: "content pb20"
  }, [_c("div", {
    staticClass: "bg pt12"
  }, [_c("div", {
    staticClass: "flex-center-center tc-white"
  }, [_c("div", [_vm._v(_vm._s(_vm.options.empName))]), _vm.options.depName ? _c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }) : _vm._e(), _c("div", [_vm._v(_vm._s(_vm.options.depName))])], 1), _c("div", {
    staticClass: "fs50 tc-white flex-center-center mv4"
  }, [_c("a-statistic", {
    attrs: {
      "value-style": "color:white",
      precision: 2,
      value: _vm.options.salaryReal
    }
  })], 1), _c("div", {
    staticClass: "ta-center tc-white fs16"
  }, [_vm._v("实发金额(元)")])]), _vm.keys && _vm.keys.length > 0 ? _c("div", {
    staticClass: "mh20 content-box box-shadow bgc-white br4",
    staticStyle: {
      "margin-top": "-64px"
    },
    style: _vm.bgImg
  }, [_c("div", {
    staticClass: "p12 ta-left fs16 fw500"
  }, [_vm._v("基础项目")]), _vm._l(_vm.keys, function (item) {
    return _c("div", {
      key: item.key,
      staticClass: "p12 flex-center-between"
    }, [_c("div", [_vm._v(_vm._s(item.name))]), _c("div", [_vm._v(_vm._s(item.value))])]);
  })], 2) : _vm._e(), _vm.keysOther && _vm.keysOther.length > 0 ? _c("div", {
    staticClass: "mh20 mt20 content-box box-shadow bgc-white br4",
    style: _vm.bgImg
  }, [_c("div", {
    staticClass: "p12 ta-left fs16 fw500"
  }, [_vm._v("其他明细")]), _vm._l(_vm.keysOther, function (item) {
    return _c("div", {
      key: item.key,
      staticClass: "p12 flex-center-between"
    }, [_c("div", [_vm._v(_vm._s(item.name))]), _c("div", [_vm._v(_vm._s(item.value))])]);
  })], 2) : _vm._e()])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };