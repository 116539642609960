import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
import CompanyRoleSelect from "@/components/EmployeeSelect2/employeeSelect5";
import EmployeeSelect from "@/components/RoleSelect/employee-select";
import RoleSelect from "@/components/RoleSelect";
import { isJson } from "@/utils/index";
export default {
  name: "ApprovalDrawer",
  components: {
    EmployeeSelect: EmployeeSelect,
    RoleSelect: RoleSelect,
    CompanyRoleSelect: CompanyRoleSelect
  },
  props: {
    approOptions: {
      type: Object,
      default: function _default() {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      title: "",
      level: 1,
      // 主管等级
      autoUp: true,
      // 找不到主管时，由上级主管代审批
      options: null,
      employeeVisible: false,
      addCompanyRole: false,
      roleVisible: false,
      defaultEmployeeKeys: [],
      defaultRoleKeys: [],
      defaultCompanyRoleKeys: [],
      choseEmployees: [],
      // 选中的成员
      choseRoles: [],
      companyRole: [],
      roleType: null,
      // 指定成员
      copyOptions: {}
    };
  },
  computed: {
    // 主管集合
    MANAGEMENT: function MANAGEMENT() {
      var i = 1;
      var box = [];
      while (i <= 20) {
        box.push({
          name: i === 1 ? "直接主管" : "\u7B2C".concat(i, "\u7EA7\u4E3B\u7BA1"),
          level: i
        });
        i++;
      }
      return box;
    }
  },
  watch: {
    show: function show(val) {
      if (val) {
        this.copyOptions = JSON.parse(JSON.stringify(this.approOptions));
        this.initOptions(this.approOptions);
      }
    }
  },
  created: function created() {
    this.initOptions(this.approOptions);
  },
  methods: {
    changeCustom: function changeCustom(e) {
      this.customShow = e.show;
    },
    initKeys: function initKeys() {
      this.defaultEmployeeKeys = [];
      this.defaultRoleKeys = [];
      this.defaultCompanyRoleKeys = [];
    },
    initOptions: function initOptions(val) {
      this.options = Object.assign({}, val, {
        props: isJson(val.props) ? JSON.parse(val.props) : val.props
      });
      this.roleType = "APPROVER_COMPANY_ROLE";
      if (this.options.props && !!this.options.props.values) {
        this.roleType = this.options.props.type;
        switch (this.options.props.type) {
          case "APPROVER_SELECT":
            //  this.defaultEmployeeKeys = this.options.props.values.split(",");
            this.defaultEmployeeKeys = this.options.props.userList;
            break;
          case "APPROVER_MANAGEMENT":
            break;
          case "APPROVER_ROLE":
            //  this.defaultRoleKeys = this.options.props.values.split(",");
            this.defaultRoleKeys = this.options.props.userList;
            break;
          case "APPROVER_COMPANY_ROLE":
            // this.defaultCompanyRoleKeys = this.options.props.values.split(",");
            this.defaultCompanyRoleKeys = this.options.props.userList;
            break;
        }
      }
    },
    onEmployeeChange: function onEmployeeChange(data) {
      this.initKeys();
      this.choseEmployees = data;
      // this.defaultEmployeeKeys = data.map(i => i.id);
      this.defaultEmployeeKeys = data;
      this.options.props.userList = this.choseEmployees;
    },
    onRoleChange: function onRoleChange(data) {
      this.initKeys();
      this.choseRoles = data;
      //  this.defaultRoleKeys = data.map(i => i.id);
      this.defaultRoleKeys = data;
      this.options.props.userList = this.choseRoles;
    },
    onCompanyRoleChange: function onCompanyRoleChange(data) {
      this.initKeys();
      this.companyRole = data;
      // this.defaultCompanyRoleKeys = data.map(i => i.id);
      this.defaultCompanyRoleKeys = data;
      this.options.props.userList = this.companyRole;
    },
    // 添加角色
    addRoles: function addRoles() {
      this.title = "角色";
      this.roleVisible = true;
    },
    // 添加成员
    addEmployees: function addEmployees() {
      this.title = "成员";
      this.employeeVisible = true;
    },
    // 添加公司管理人员
    addCompanyRoles: function addCompanyRoles() {
      this.title = "公司管理人员";
      this.addCompanyRole = true;
    },
    /* radio 选择 */onRadio: function onRadio(val) {
      this.options.props.userList = [];
      this.options.props.type = val.target.value;
      if (val.target.value === "APPROVER_ORIGINATOR") {
        this.options.props.userList = [{
          name: "发起人自己",
          vlaues: 0
        }];
      }
    },
    // 审批方式
    appType: function appType(e) {
      this.options.activateType = e.target.value;
    },
    // 审批人为空时
    appNone: function appNone(e) {
      this.options.noneActionerAction = e.target.value;
    },
    /* 确认 */onOk: function onOk() {
      this.options.props.type = this.roleType;
      if (Boolean(this.options.props.userList)) {
        this.options.props.name = this.options.props.userList.map(function (val) {
          return val.name;
        }).toString();
        this.options.props.values = this.options.props.userList.map(function (val) {
          return val.id;
        }).toString();
      }
      this.$emit("change", this.options);
      this.$emit("update:show", false);
    },
    /* 取消 */onClose: function onClose(e) {
      this.$emit("update:show", false);
      this.$emit("change", this.copyOptions);
    }
  }
};