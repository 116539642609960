import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "body_detail page-miH page-bgBack"
  }, [_c("a-row", {
    staticClass: "company",
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", [_c("a-icon", {
    staticClass: "mg_r20",
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" " + _vm._s(_vm.editor ? "分组详情" : "添加用工单位分组") + " ")], 1), _vm.editor ? _c("a-col", [_c("a-button", {
    staticClass: "mg_r20",
    on: {
      click: _vm.editorDetail
    }
  }, [_vm._v(_vm._s(_vm.disabled ? "编辑" : "取消"))]), _c("a-button", {
    on: {
      click: _vm.deleteComapny
    }
  }, [_vm._v("删除分组")])], 1) : _vm._e()], 1), _c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("div", {
    staticClass: "base_detail"
  }, [_vm._v("基本信息")]), _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 12
      },
      label: "用工单位分组名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "用工单位分组名称必填"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '用工单位分组名称必填' }] }]"
    }],
    attrs: {
      disabled: _vm.disabled,
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 12
      },
      label: "上级分组"
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["parentId"],
      expression: "['parentId']"
    }],
    attrs: {
      treeData: _vm.groupTree,
      replaceFields: {
        children: "childList",
        title: "name",
        key: "id",
        value: "id"
      },
      dropdownStyle: {
        maxHeight: "400px",
        overflow: "auto"
      },
      disabled: _vm.disabled,
      placeholder: "请选择"
    },
    on: {
      change: _vm.onTreeChange
    }
  })], 1), _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 12
      },
      label: "分管单位"
    }
  }, [!_vm.disabled && !_vm.detail.isAllCompany ? _c("a-button", {
    on: {
      click: _vm.select
    }
  }, [_vm._v(" 请选择 ")]) : _vm._e(), _c("a-table", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showDetail && !_vm.detail.isAllCompany,
      expression: "showDetail && !detail.isAllCompany"
    }],
    staticClass: "mg_t10",
    attrs: {
      "row-key": function rowKey(d) {
        return d.id;
      },
      "data-source": _vm.detail.companyList
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "分管单位名称",
      "data-index": "name"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "联系人",
      "data-index": "legalPerson"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "手机号",
      "data-index": "telephone"
    }
  })], 1)], 1), _vm.editor ? _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 12
      },
      label: "管理员"
    }
  }, [_c("a-table", {
    staticClass: "mg_t10",
    attrs: {
      "row-key": function rowKey(d) {
        return d.id;
      },
      "data-source": _vm.detail.adminUserList
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "姓名",
      "data-index": "name"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "手机号",
      "data-index": "phone"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "角色",
      "data-index": "roleName"
    }
  })], 1)], 1) : _vm._e()], 1), !_vm.disabled ? _c("a-button", {
    staticClass: "mg_l72",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.sureData
    }
  }, [_vm._v("确认")]) : _vm._e(), _c("a-modal", {
    attrs: {
      width: 660,
      title: "分管单位"
    },
    on: {
      ok: _vm.handleOk
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-row", {
    staticClass: "all-body",
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "搜索分管单位"
    },
    on: {
      search: _vm.onSearch
    }
  }), _c("div", {
    staticClass: "unit-list",
    on: {
      click: _vm.allSelect
    }
  }, [_c("a-icon", {
    staticClass: "unit-icon",
    attrs: {
      type: "folder"
    }
  }), _vm._v("全部")], 1), _c("a-divider", {
    staticClass: "mg_t5 mg_b5"
  }), _c("ul", {
    staticClass: "unit-body"
  }, _vm._l(_vm.unitList, function (item, j) {
    return _c("li", {
      key: j,
      staticClass: "unit-list",
      on: {
        click: function click($event) {
          return _vm.selectItem(item, _vm.unitList);
        }
      }
    }, [_c("a-icon", {
      staticClass: "unit-icon",
      attrs: {
        type: "folder"
      }
    }), _c("span", [_vm._v(" " + _vm._s(item.name))]), _c("a-icon", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.select,
        expression: "item.select"
      }],
      staticClass: "unit-r-icon",
      attrs: {
        type: "check"
      }
    })], 1);
  }), 0)], 1), _c("span", {
    staticClass: "divider"
  }), _c("a-col", {
    staticClass: "mg_l10",
    attrs: {
      span: 11
    }
  }, [_c("div", [_vm._v("已选")]), _c("ul", {
    staticClass: "unit-r-body"
  }, _vm._l(_vm.chosdeUnitList, function (item, i) {
    return _c("li", {
      key: i,
      staticClass: "unit-list"
    }, [_c("a-icon", {
      staticClass: "unit-icon",
      attrs: {
        type: "folder"
      }
    }), _c("span", [_vm._v(" " + _vm._s(item.name))]), _c("a-icon", {
      staticClass: "unit-r-icon",
      attrs: {
        type: "close"
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(item, _vm.chosdeUnitList);
        }
      }
    })], 1);
  }), 0)])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };