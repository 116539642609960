var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "FixedBox"
  }, [_c("span", {
    staticClass: "FixedBoxLine"
  }), _vm._v("工价规则： "), _c("span", {
    staticClass: "Name"
  }, [_vm._v(_vm._s(_vm.salaryRuleName || "--"))]), _c("a-popover", {
    attrs: {
      placement: "top",
      trigger: "hover"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("div", [_vm._v("当前使用的规则详情")])]), _c("i", {
    staticClass: "iconfont icon-wenhao iconfontStyle"
  })], 2)], 1), _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.data,
      pagination: _vm.paginationOpt1
    }
  }), _c("div", {
    staticClass: "FixedBox"
  }, [_c("span", {
    staticClass: "FixedBoxLine"
  }), _vm._v("招工劳务费规则： "), _c("span", {
    staticClass: "Name"
  }, [_vm._v(_vm._s(_vm.serviceFeeRuleName || "--"))]), _c("a-popover", {
    attrs: {
      placement: "top",
      trigger: "hover"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("div", [_vm._v("当前使用的规则详情")])]), _c("i", {
    staticClass: "iconfont icon-wenhao iconfontStyle"
  })], 2)], 1), _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.data1,
      pagination: _vm.paginationOpt2
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };