import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.unshift.js";
import { saveEmpSocInfoNextMonth, getEmpSocInfoMonthList, empSocMonths, deleteEmpSocInfos, getNoCount } from "@/api/shebao/shebaoInfo.js";
import Open from "./components/open";
var columns = [{
  title: "参保月份",
  key: "yearMonth",
  align: "left",
  ellipsis: true,
  scopedSlots: {
    customRender: "yearMonth"
  }
}, {
  title: "参保人数",
  dataIndex: "empSocInfoCount",
  align: "left",
  ellipsis: true
}, {
  title: "社保缴费",
  dataIndex: "socAmount",
  align: "left",
  ellipsis: true
}, {
  title: "公积金缴费",
  dataIndex: "pfAmount",
  align: "left",
  ellipsis: true
}, {
  title: "本月增员人数",
  dataIndex: "monthIncrement",
  align: "left",
  ellipsis: true
}, {
  title: "本月减员人数",
  dataIndex: "monthStop",
  align: "left",
  ellipsis: true
}, {
  title: "操作",
  key: "action",
  align: "left",
  width: 80,
  scopedSlots: {
    customRender: "action"
  }
}];
export default {
  components: {
    Open: Open
  },
  data: function data() {
    return {
      tempYear: "",
      tempMonth: "",
      selectitem: "全部",
      companyId: this.$store.state.hr.companyId,
      isVisible: false,
      isNextVisible: false,
      searchYear: null,
      year: "",
      month: "",
      columns: columns,
      listcount: 0,
      totalAmount: "",
      data: [],
      dataYear: [],
      lastMonth: "",
      lastYear: "",
      nextempSocInfoCount: "",
      personalAmount: "",
      companyAmount: "",
      dateOpen: false,
      id: "",
      isOpen: true,
      yearList: []
    };
  },
  computed: {
    prevMonth: function prevMonth() {
      var month = this.month - 1 >= 10 ? "" + (this.month - 1) : "0" + (this.month - 1);
      return month;
    },
    nowMonth: function nowMonth() {
      return this.month >= 10 ? "" + this.month : "0" + this.month;
    },
    nextMonth: function nextMonth() {
      var month = this.month + 1 >= 10 ? "" + (this.month + 1) : "0" + (this.month + 1);
      return month;
    }
  },
  created: function created() {
    var _this = this;
    this.getYear();
    this.checkMonthBill();
    getNoCount(this.$store.state.hr.companyId).then(function (res) {
      _this.listcount = res.data ? res.data : 0;
    });
  },
  methods: {
    delet: function delet(item) {
      if (!item.monthWhetherCurrent) {
        return;
      }
      var that = this;
      this.$confirm({
        title: "是否确认删除？",
        // content: "是否确认删除？",
        // content: "确认删除 " + item.year + "年" + item.month + "月 的参保记录？",
        onOk: function onOk() {
          var data = [item.id];
          deleteEmpSocInfos(data).then(function (res) {
            that.$notification["success"]({
              message: "删除成功"
            });
            that.checkMonthBill();
          });
        }
      });
    },
    handleDate: function handleDate(time) {
      return false;
    },
    open: function open() {
      this.isOpen = false;
      if (this.data && this.data.length !== 0) {
        this.isVisible = false;
      } else {
        this.chhooseMonth(this.year, this.nowMonth);
        this.isVisible = true;
      }
    },
    handleYear: function handleYear(val) {
      this.selectitem = val;
      if (val !== "全部") {
        this.year = val;
      }
      this.checkMonthBill();
    },
    // 选择当前参保月份
    chhooseMonth: function chhooseMonth(year, month) {
      this.tempYear = year;
      this.tempMonth = month;
    },
    // 设定当前年月
    getYear: function getYear() {
      var date = new Date();
      var m = date.getMonth() + 1;
      var pm = date.getMonth();
      var year = date.getFullYear();
      var nm = date.getMonth() + 2;
      this.year = year;
      this.month = m;
    },
    // 关闭次月账单
    closeNextDetail: function closeNextDetail() {
      this.isNextVisible = false;
    },
    handleClose: function handleClose() {
      this.tempYear = "";
      this.tempMonth = "";
      this.isVisible = false;
      if (this.data.length === 0) {
        this.isOpen = true;
      }
    },
    enSure: function enSure() {
      var _this2 = this;
      var data = {
        companyId: this.$store.state.hr.companyId,
        month: this.tempMonth,
        year: this.tempYear
      };
      empSocMonths(data).then(function (res) {
        _this2.isVisible = false;
        _this2.year = _this2.tempYear;
        _this2.month = _this2.tempMonth;
        _this2.checkMonthBill();
      });
    },
    zongJianYuan: function zongJianYuan() {
      this.isNextVisible = false;
      this.$router.push({
        path: "/shebao/shebaoManage/shebaoZengJianY"
      });
    },
    // 添加次月参保账单
    addNextMonth: function addNextMonth() {
      var that = this;
      this.$confirm({
        title: "创建次月账单",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        content: "正在创建次月参保，创建后上月参保管理将不能修改，是否确认创建?",
        onOk: function onOk() {
          that.getYear();
          var month = that.month >= 10 ? that.month : "0" + that.month;
          var year = that.year;
          var data = {
            companyId: that.$store.state.hr.companyId,
            month: month,
            year: "" + year
          };
          saveEmpSocInfoNextMonth(data).then(function (res) {
            if (res.data === "") {
              that.isNextVisible = true;
              that.month = month;
              that.year = year;
              that.$notification["success"]({
                message: "添加成功"
              });
            } else {
              that.$notification["error"]({
                message: "已有两个新账单，不能继续添加"
              });
            }
            that.checkMonthBill();
          });
        }
      });
    },
    // 进入月份缴纳社保详情
    enterDetail: function enterDetail(id, month, year) {
      this.isNextVisible = false;
      this.$router.push({
        path: "/shebao/shebaoManage/shebaoDetail",
        query: {
          id: id,
          month: month,
          year: year
        }
      });
    },
    handleCancel: function handleCancel() {
      this.isNextVisible = false;
    },
    // 查询参保月份账单
    checkMonthBill: function checkMonthBill() {
      var _this3 = this;
      this.isOpen = false;
      var data = {
        companyId: this.$store.state.hr.companyId
      };
      if (this.selectitem !== "全部") {
        data.year = this.year;
        // data.month = this.month;
      } else {
        data = {
          companyId: this.$store.state.hr.companyId
        };
      }
      getEmpSocInfoMonthList(data).then(function (res) {
        _this3.data = res.data;
        if (_this3.selectitem === "全部") {
          var hash = {};
          _this3.dataYear = _this3.data.reduce(function (arr, current) {
            hash[current.year] ? "" : hash[current.year] = true && arr.push(current);
            return arr;
          }, []);
          if (_this3.dataYear.length !== 0) {
            var obj3 = JSON.parse(JSON.stringify(_this3.dataYear[0]));
            obj3.year = "全部";
            _this3.dataYear.unshift(obj3);
          }
        }
        if (_this3.data && _this3.data.length !== 0) {
          _this3.personalAmount = _this3.data[0].personalAmount;
          _this3.companyAmount = _this3.data[0].companyAmount;
          _this3.lastMonth = _this3.data[0].month;
          _this3.lastYear = _this3.data[0].year;
          _this3.nextempSocInfoCount = _this3.data[0].empSocInfoCount;
          _this3.totalAmount = _this3.data[0].amountCount;
          _this3.isVisible = false;
          _this3.isOpen = false;
        } else {
          _this3.isOpen = true;
          _this3.getYear();
        }
      });
    }
  }
};