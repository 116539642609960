import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { followColumns, followTypeList } from "../../funcs";
import { followUpLoadReq, followUDelReq, followUpUpdateReq, followUpAddReq } from "../../api";
import { mapState } from "vuex";
import { fillDelItemToList } from "@/utils/tools";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      adminUsersFormatted: [],
      addVisible: false,
      editVisible: false,
      recordId: null,
      recordCur: undefined,
      recordMed: undefined,
      recordCon: "",
      followUser: undefined,
      followType: undefined,
      followTypeList: followTypeList,
      followColumns: followColumns,
      dataSource: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["recruitment"])), {}, {
    adminUsers: function adminUsers() {
      return this.recruitment.adminUsers;
    },
    followUserMap: function followUserMap() {
      var map = {};
      this.adminUsers.forEach(function (item) {
        map[item.value] = item.label;
      });
      return map;
    },
    talentPoolId: function talentPoolId() {
      return this.$route.query.talentPoolId;
    }
  }),
  watch: {
    adminUsers: {
      handler: function handler(newVal) {
        this.adminUsersFormatted = newVal;
      },
      immediate: true
    }
  },
  created: function created() {
    this.loadData();
  },
  mounted: function mounted() {},
  methods: {
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    addItem: function addItem() {
      this.recordCur = undefined;
      this.recordMed = undefined;
      this.recordCon = "";
      this.addVisible = true;
    },
    editItem: function editItem(record) {
      this.adminUsersFormatted = fillDelItemToList({
        label: record.followUserName,
        value: record.followUser,
        disabled: true
      }, this.adminUsers);
      this.recordCur = record.followUser;
      this.recordMed = record.followType;
      this.recordCon = record.followContent;
      this.recordId = record.id;
      this.editVisible = true;
    },
    onAddOk: function onAddOk() {
      var _this = this;
      if (this.recordCur && this.recordMed && this.recordCon) {
        followUpAddReq({
          talentPoolId: this.talentPoolId,
          followUser: this.recordCur,
          followType: this.recordMed,
          followContent: this.recordCon
        }).then(function (res) {
          _this.$message.success("添加成功");
          _this.addVisible = false;
          _this.loadData();
        }).catch(function (err) {
          console.log("followUpAddReq", err);
        });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    editOk: function editOk() {
      var _this2 = this;
      if (this.recordCur && this.recordMed && this.recordCon) {
        followUpUpdateReq({
          talentPoolId: this.talentPoolId,
          followUser: this.recordCur,
          followType: this.recordMed,
          followContent: this.recordCon,
          id: this.recordId
        }).then(function (res) {
          _this2.$message.success("编辑成功");
          _this2.editVisible = false;
          _this2.loadData();
        }).catch(function (err) {
          console.log("followUpUpdateReq", err);
        });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    delItem: function delItem(id) {
      var _this3 = this;
      var h = this.$createElement;
      this.$confirm({
        title: "确认删除该跟进记录吗？",
        content: function content(h) {
          return h("div", {
            "style": "color: red;"
          }, ["\u5220\u9664\u540E\u5C06\u65E0\u6CD5\u6062\u590D"]);
        },
        okType: "danger",
        onOk: function onOk() {
          followUDelReq(id).then(function (res) {
            _this3.$message.success("删除成功");
            _this3.loadData();
          }).catch(function (err) {
            console.log("delItem followUp", err);
          });
        }
      });
    },
    onSelectChange: function onSelectChange() {
      this.pagination.current = 1;
      this.loadData();
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.loadData();
    },
    loadData: function loadData() {
      var _this4 = this;
      var params = {
        talentPoolId: this.talentPoolId,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        "orders[0].property": "gmtCreate",
        "orders[0].desc": true
      };
      if (this.followUser) {
        params.followUserName = this.followUser;
      }
      if (this.followType) {
        params.followType = this.followType;
      }
      followUpLoadReq(params).then(function (res) {
        _this4.dataSource = res.data.entities;
        _this4.pagination.total = res.data.entityCount;
      });
    }
  }
};