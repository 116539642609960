var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper p24"
  }, [_vm.vJson.failList.length !== 0 ? _c("a-alert", {
    staticStyle: {
      "background-color": "#fff1f0"
    },
    attrs: {
      type: "error",
      message: "有" + this.vJson.failList.length + "条数据异常，请尽快处理",
      banner: ""
    }
  }, [_c("span", {
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_c("span", {
    staticClass: "exportErrData",
    on: {
      click: _vm.exportErrData
    }
  }, [_vm._v("导出错误数据")])])]) : _vm._e(), _c("a-spin", {
    staticClass: "xq-form-content",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 180
      },
      pagination: _vm.pagination,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    on: {
      change: _vm.handlePageChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(_vm._s(col.customTitle))])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [_c("div", {
            key: index,
            staticStyle: {
              position: "relative"
            }
          }, [record.reason ? _c("a-popover", {
            key: index,
            attrs: {
              title: "数据错误",
              placement: "right"
            }
          }, [_c("template", {
            slot: "content"
          }, [_c("p", [_vm._v(_vm._s(record.reason))])]), col.dataIndex == "reason" ? _c("div", {
            staticClass: "triangle"
          }) : _vm._e()], 2) : _vm._e(), col.dataIndex == "reason" && record.reason ? _c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [_c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(text))])]), _c("div", {
            staticClass: "overflowsIdentity",
            staticStyle: {
              height: "22px"
            }
          }, [_vm._v(_vm._s(record.reason))])], 2) : col.dataIndex === "status" ? _c("div", [_c("span", [_vm._v(_vm._s(text === 1 ? "正常" : text === 2 ? "非正常" : ""))])]) : col.dataIndex === "isDisable" ? _c("div", [_c("span", [_vm._v(_vm._s(text === true ? "是" : text === false ? "否" : ""))])]) : col.dataIndex === "isMartyr" ? _c("div", [_c("span", [_vm._v(_vm._s(text === true ? "是" : text === false ? "否" : ""))])]) : col.dataIndex === "isOld" ? _c("div", [_c("span", [_vm._v(_vm._s(text === true ? "是" : text === false ? "否" : ""))])]) : col.dataIndex === "isDeductDeductible" ? _c("div", [_c("span", [_vm._v(_vm._s(text === true ? "是" : text === false ? "否" : ""))])]) : col.dataIndex === "personalInvestmentRatio" ? _c("div", [_c("span", [_vm._v(_vm._s(text) + "%")])]) : _c("div", {
            staticStyle: {
              height: "22px"
            }
          }, [_vm._v(_vm._s(text || ""))])], 1)];
        }
      };
    })], null, true)
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };