export default {
  props: {
    wifiOptions: {
      type: Object,
      default: function _default() {
        return {
          show: false,
          obj: {}
        };
      }
    }
  },
  data: function data() {
    return {
      visible: false,
      obj: {
        macAddr: "",
        name: ""
      }
    };
  },
  watch: {
    wifiOptions: {
      handler: function handler(val) {
        this.obj = val.obj;
        this.visible = val.show;
      }
    }
  },
  methods: {
    /* 添加wifi */handleOk: function handleOk() {
      this.visible = false;
      this.$emit("editorWifi", this.obj);
    }
  }
};