import request from "@/utils/request";

// 个税人员库手动录入
export function saveInfo(data) {
  return request({
    url: "/tax/api/v1/employees/saveInfo",
    method: "post",
    data: data
  });
}
export function findBySite(params) {
  return request({
    url: "/admin/api/v1/customTemplateFields/findBySite",
    method: "GET",
    params: params
  });
}
export function findByTemplateId(id) {
  return request({
    url: "/admin/api/v1/customTemplateFields/findByTemplateId/".concat(id),
    method: "GET"
  });
}
export function selectByDefaultAll() {
  return request({
    url: "/admin/api/v1/customTemplates/selectByDefault",
    method: "GET"
  });
}
export function getTalentPoolIdInfo(talentPoolId) {
  return request({
    url: "/recruit2/api/v1/talentPools/selectAllById/".concat(talentPoolId),
    method: "GET"
  });
}
export function selectOneByTypeAndId(params) {
  return request({
    url: "/admin/api/v1/customTemplates/selectOneByTypeAndId",
    method: "GET",
    params: params
  });
}
export function findBySiteCustomTemplates(params) {
  return request({
    url: "/admin/api/v1/customTemplates/findBySite",
    method: "GET",
    params: params
  });
}
export function resumeTransformation(params) {
  return request({
    url: "/recruit2/api/v1/talentPools/resumeTransformationByUrl",
    params: params,
    name: "file",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    method: "post"
  });
}