import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "console-message-add-wrapper"
  }, [_c("a-row", {
    staticClass: "head"
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/thirdService/smsTemplate");
      }
    }
  }), _c("a-col", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("短信模板配置")])])], 1), _c("a-row", [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", [_c("div", {
    staticStyle: {
      "font-size": "20px",
      "font-weight": "500",
      color: "#000000",
      "padding-left": "200px"
    }
  }, [_vm._v("第三方服务信息")])]), _c("a-form-model-item", {
    ref: "",
    attrs: {
      label: "服务商",
      prop: ""
    }
  }, [_c("a-input", {
    attrs: {
      value: "阿里云",
      disabled: true
    }
  })], 1), _c("a-form-model-item", {
    ref: "key",
    attrs: {
      label: "账户名",
      prop: "key"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.form.key,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "key", $$v);
      },
      expression: "form.key"
    }
  })], 1), _c("a-form-model-item", {
    ref: "value",
    attrs: {
      label: "密钥",
      prop: "value"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  })], 1), _c("a-form-model-item", [_c("div", {
    staticStyle: {
      "font-size": "20px",
      "font-weight": "500",
      color: "#000000",
      "padding-left": "200px"
    }
  }, [_vm._v("短信模板信息")])]), _c("a-form-model-item", {
    ref: "signName",
    attrs: {
      label: "公司签名",
      prop: "signName"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "如：xxx"
    },
    model: {
      value: _vm.form.signName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "signName", $$v);
      },
      expression: "form.signName"
    }
  })], 1), _c("a-form-model-item", {
    ref: "type",
    attrs: {
      label: "模板类型",
      prop: "type"
    }
  }, [_c("a-radio-group", {
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_c("a-radio", {
    style: _vm.radioStyle,
    attrs: {
      value: 1
    }
  }, [_vm._v(" 验证码 ")]), _c("a-radio", {
    style: _vm.radioStyle,
    attrs: {
      value: 2
    }
  }, [_vm._v(" 短信通知 ")]), _c("a-radio", {
    style: _vm.radioStyle,
    attrs: {
      value: 3
    }
  }, [_vm._v(" 推广短信 ")])], 1)], 1), _c("a-form-model-item", {
    ref: "name",
    attrs: {
      label: "模板名称",
      prop: "name"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "不超过30个字"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("a-form-model-item", {
    ref: "thirdId",
    attrs: {
      prop: "thirdId",
      label: "模板id"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.form.thirdId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "thirdId", $$v);
      },
      expression: "form.thirdId"
    }
  })], 1), _c("a-form-model-item", {
    ref: "content",
    attrs: {
      label: "模板内容",
      prop: "content"
    }
  }, [_c("a-textarea", {
    attrs: {
      rows: 4,
      placeholder: _vm.placeholders
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }), _vm.type !== null ? _c("p", {
    staticStyle: {
      color: "#444444",
      "font-size": "14px",
      "line-height": "22px"
    }
  }, [_c("a-icon", {
    staticStyle: {
      color: "red"
    },
    attrs: {
      type: "exclamation"
    }
  }), _vm._v(_vm._s(_vm.prompt) + " ")], 1) : _vm._e(), _vm.type === 3 ? _c("p", {
    staticStyle: {
      color: "#bababa",
      "font-size": "14px",
      height: "20px"
    }
  }, [_vm._v(" 不能发送营销/贷款/借款/中奖/抽奖类短信，不支持金融理财&房产通知类短信 ")]) : _vm._e(), _vm.type === 3 ? _c("p", {
    staticStyle: {
      color: "#bababa",
      "font-size": "14px",
      height: "20px"
    }
  }, [_vm._v(" 推广短信建议在结尾加上空格或其他字符，便于区分营销内容 ")]) : _vm._e()], 1), _c("a-form-model-item", {
    ref: "description",
    attrs: {
      label: "申请说明",
      prop: "description"
    }
  }, [_c("a-textarea", {
    attrs: {
      rows: 4,
      placeholder: "请描述详细的使用场景，并提供产品链接、App、公众号、公司标准全称等，例： 使用场景：向会员用户推送与XX相关的营销活动 产品链接：www.zhixinren.work.com"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _c("a-form-model-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end"
    }
  }, [_c("div", [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(" 提交 ")])], 1)])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };