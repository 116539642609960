import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "res-box pt48"
  }, [_c("img", {
    attrs: {
      src: _vm.s
    }
  }), _c("div", {
    staticClass: "tit mt8"
  }, [_vm._v("报送提交成功")]), _vm.dataShow ? _c("div", {
    staticClass: "mt14",
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "14px"
    }
  }, [_vm._v(" " + _vm._s("\u672C\u6B21\u62A5\u9001\u603B\u4EBA\u6570".concat(_vm.dataShow.totalCount, "\uFF0C\u6210\u529F\u62A5\u9001").concat(_vm.dataShow.successCount, "\u4EBA\uFF0C\u62A5\u9001\u5931\u8D25").concat(_vm.dataShow.failCount, "\u4EBA\u3002")) + " ")]) : _vm._e(), _vm.dataShow ? _c("div", {
    staticClass: "mt14",
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "14px"
    }
  }, [_vm._v(" " + _vm._s(_vm.dataShow.failList && _vm.dataShow.failList.length !== 0 ? "".concat(_vm.dataShow.failList[0].empName, "\u7B49").concat(_vm.dataShow.failList.length, "\u4EBA\u62A5\u9001\u5931\u8D25") : "") + " ")]) : _vm._e(), _c("div", {
    staticClass: "desc mt12",
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "14px"
    }
  }, [_vm._v("报送需要一段时间 请稍后刷新查看")]), _c("div", {
    staticClass: "mt24"
  }, [_c("a-button", {
    staticStyle: {
      width: "108px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("确认")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };