import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import { tableAdmin } from "./tableName";
var tableNameWidthList = ["checkCard", "checkTable", "taxCalculateReport"];
export default {
  props: {
    /**
     * @type {string} 表格名称
     */
    tableName: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      tableAdmin: tableAdmin,
      filterBtnffsetLeft: 0,
      screeningWidth: 306
    };
  },
  mounted: function mounted() {
    var dom = document.querySelector("#filterBtn");
    if (dom) this.filterBtnffsetLeft = dom.offsetLeft;
    this.getStyleWidth(this.$store.state.app.sidebar.opened);
  },
  watch: {
    "$store.state.app.sidebar.opened": function $storeStateAppSidebarOpened(v) {
      this.getStyleWidth(v);
    }
  },
  methods: {
    getStyleWidth: function getStyleWidth(v) {
      if (this.tableAdmin.includes(this.tableName)) {
        this.screeningWidth = 284;
      } else {
        this.screeningWidth = v ? 306 : 160;
      }
    },
    clickHandler: function clickHandler(event) {
      this.$store.commit("Table/screeningVisible", true);
      event.stopPropagation();
    },
    onClose: function onClose(event) {
      this.$store.commit("Table/screeningVisible", false);
      this.$emit("onClose");
      event.stopPropagation();
    },
    onSearch: function onSearch(event) {
      this.$store.commit("Table/screeningVisible", false);
      this.$emit("onSearch");
      event.stopPropagation();
    }
  },
  render: function render(h) {
    var _this = this;
    if (!this.$slots.default) return false;
    var getSearchBox = function getSearchBox() {
      if (_this.$store.state.Table.screeningVisible) {
        return h("div", {
          "class": "drawer",
          "style": {
            width: "calc(100vw - ".concat(_this.screeningWidth, "px)"),
            left: "-".concat(tableNameWidthList.includes(_this.tableName) ? _this.filterBtnffsetLeft : _this.filterBtnffsetLeft - 22, "px")
          }
        }, [h("div", {
          "class": "triangle",
          "style": {
            left: "".concat(tableNameWidthList.includes(_this.tableName) ? _this.filterBtnffsetLeft - 6 : _this.filterBtnffsetLeft - 28, "px")
          }
        }), h("div", {
          "class": "search-box"
        }, [h("a-row", {
          "attrs": {
            "type": "flex"
          }
        }, [_this.$slots.default.map(function (e, i) {
          var _e$data;
          if (e.tag && (_e$data = e.data) !== null && _e$data !== void 0 && _e$data.attrs.label) {
            var label = e.data.attrs.label;
            return h("a-col", {
              "attrs": {
                "span": 6
              },
              "class": "search-box-col"
            }, [h("p", [label]), e]);
          }
        })])]), h("div", {
          "class": "action"
        }, [h("a-button", {
          "style": "margin-right: 12px",
          "on": {
            "click": _this.onClose
          }
        }, ["\u53D6\u6D88"]), h("a-button", {
          "attrs": {
            "type": "primary"
          },
          "on": {
            "click": _this.onSearch
          }
        }, ["\u67E5\u8BE2"])])]);
      }
    };
    var getButton = function getButton() {
      var f = false;
      _this.$slots.default.some(function (e) {
        var _e$data2;
        if (e.tag && (_e$data2 = e.data) !== null && _e$data2 !== void 0 && _e$data2.attrs.label) {
          f = true;
          return false;
        }
      });
      if (_this.$slots.default.length > 1 && f) {
        return h("a-button", {
          "attrs": {
            "icon": "filter",
            "id": "filterBtn"
          },
          "style": "color:#AAAAAA;margin-right:12px;",
          "on": {
            "click": _this.clickHandler
          }
        }, [getSearchBox()]);
      }
    };
    // let width = "200px";
    // if (this.$slots.default[0].data.staticStyle && this.$slots.default[0].data.staticStyle.width) {
    //   width = "auto";
    // } else {
    //   this.$nextTick(() => {
    //     this.$slots.default[0].elm.style.width = "100%";
    //   });
    // }
    return h("div", {
      "class": "table-screening-box"
    }, [this.$slots.default.map(function (e) {
      var _e$data3;
      var width = "200px";
      if (e.tag && !((_e$data3 = e.data) !== null && _e$data3 !== void 0 && _e$data3.attrs.label)) {
        var _e$data4;
        if ((_e$data4 = e.data) !== null && _e$data4 !== void 0 && _e$data4.staticStyle && e.data.staticStyle.width) {
          width = "auto";
        } else {
          _this.$nextTick(function () {
            var _e$elm;
            if ((_e$elm = e.elm) !== null && _e$elm !== void 0 && _e$elm.style) e.elm.style.width = "100%";
          });
        }
        return h("div", {
          "class": "mr12",
          "style": {
            width: width
          }
        }, [e]);
      }
    }), getButton()]);
  } // {this.$slots.default.map((e, i) => {
  //         if (e.data.attrs.domType === "button") {
  //           return { e };
  //         }
  //       })}
};