export default {
  fields: {
    orderTitle: {
      model: "orderTitle"
    },
    createUserName: {
      model: "createUserName"
    },
    companyName: {
      model: "companyName"
    },
    totalCount: {
      model: "totalCount"
    },
    money: {
      model: "money"
    },
    gmtCreate: {
      model: "gmtCreate"
    },
    subjectStatus: {
      model: "subjectStatus"
    },
    thirdpartyBatchId: {
      model: "thirdpartyBatchId"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "orderTitle",
        title: "批次号"
      }, {
        model: "createUserName",
        title: "申请人"
      }, {
        model: "companyName",
        title: "用工单位"
      }, {
        model: "totalCount",
        title: "发放人数"
      }, {
        model: "money",
        title: "发放总金额"
      }, {
        model: "gmtCreate",
        title: "申请时间"
      }, {
        model: "subjectStatus",
        title: "状态"
      }],
      // customize: {
      //   rowKey: "empInfoId"
      // },
      extend: {
        batch: true,
        action: true
      }
    }
  }
};