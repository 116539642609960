import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import axios from "axios";
import store from "../store";
var method = {};
method.install = function (Vue, options) {
  Vue.prototype.$exportFile = function (objectUrl) {
    var a = document.createElement("a");
    a.setAttribute("href", objectUrl);
    a.click();
  };
  Vue.prototype.$exportFilePost = function (url, data, name) {
    axios({
      url: url,
      data: data,
      method: "post",
      responseType: "blob",
      headers: {
        token: store.state.auth.token,
        "Content-Type": "application/json"
      }
    }).then(function (res) {
      var link = document.createElement("a");
      var blob = new Blob([res.data], {
        type: "application/vnd.ms-excel"
      });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "".concat(store.state.hr.companyName, "_") + name + ".xls");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(function (err) {
      console.log("debug log --> ", err);
    });
  };
};
export default method;