import request from "@/utils/request";

// userList
export function userSimpleList(data) {
  return request({
    url: "/hr/api/v1/users/list",
    method: "get",
    params: data
  });
}
// userList
export function userList(data) {
  return request({
    url: "/hr/api/v1/users/list",
    method: "get",
    params: data
  });
}
// 查询用户/user/api/v1/users/listAll
export function getMemberlist(params) {
  return request({
    url: "/hr/api/v1/users/userList",
    method: "GET",
    params: params
  });
}
// 新增用户
export function addMember(data) {
  return request({
    url: "/hr/api/v1/users/save",
    method: "post",
    data: data
  });
}

// 修改用户
export function updateMember(data) {
  return request({
    url: "/hr/api/v1/users/updateRole",
    method: "post",
    data: data
  });
}
// 批量删除用户 delete
export function deleteMember(data) {
  return request({
    url: "/hr/api/v1/users/delete",
    method: "post",
    data: data
  });
}

// 批量离职用户 post
export function dimissionMember(data) {
  return request({
    url: "/hr/api/v1/users/dimission",
    method: "post",
    data: data
  });
}

// 查询角色列表
// export function getRoleList(data) {
//   return request({
//     url: "/hr/api/v1/permissions/roleList",
//     method: "get",
//     params: data
//   });
// }
export function getRoleList(data) {
  return request({
    url: "/hr/api/v1/hrRoles/listAll",
    method: "get",
    params: data
  });
}
export function getServiceOpens(params) {
  return request({
    url: "/admin/api/v1/addedServiceOpens/selectServiceBySite",
    method: "get",
    params: params
  });
}
export function getRoleListPage(data) {
  return request({
    url: "/hr/api/v1/hrRoles/listPage",
    method: "get",
    params: data
  });
}
// 删除角色成员
export function deleteRoleMember(data) {
  return request({
    url: "/hr/api/v1/permissions/deleteRoleMember",
    method: "post",
    params: data
  });
}

// 修改小区成员角色
export function editRoleMember(data) {
  return request({
    url: "/hr/api/v1/permissions/updateRoleMember",
    method: "post",
    data: data
  });
}
// 查询当前小区的成员列表
export function getRoleMember(data) {
  return request({
    url: "/hr/api/v1/permissions/roleMemberList",
    method: "get",
    params: data
  });
}
export function getdic() {
  return request({
    url: "/permission/api/v1/permission/dictionary",
    method: "get"
  });
}
// *********************************角色和权限*************************************
// 新增角色
// export function addRole(data) {
//   return request({
//     url: "/hr/api/v1/permissions/addRole",
//     method: "post",
//     data
//   });
// }

// 批量删除人力角色表，彻底删除
export function deleteRoles(data) {
  return request({
    url: "/hr/api/v1/hrRoles",
    method: "delete",
    data: data
  });
}
export function addRole(data) {
  return request({
    url: "/hr/api/v1/hrRoles",
    method: "post",
    data: data
  });
}
// export function deleteRole(data) {
//   return request({
//     url: "/hr/api/v1/permissions/deleteRoles",
//     method: "post",
//     data
//   });
// }
// 删除单个人力角色表，彻底删除
export function deleteRole(hrRoleId) {
  return request({
    url: "/hr/api/v1/hrRoles/".concat(hrRoleId),
    method: "delete"
  });
}

// 新增角色成员
export function addRoleMember(data) {
  return request({
    url: "/hr/api/v1/permissions/addRoleMember",
    method: "post",
    data: data
  });
}

// 根据角色id查询角色详情
// export function roleDetailById(roleId) {
//   return request({
//     url: "/hr/api/v1/permissions/roles/" + roleId,
//     method: "get"
//   });
// }
export function roleDetailById(hrRoleId) {
  return request({
    url: "/hr/api/v1/hrRoles/".concat(hrRoleId),
    method: "get"
  });
}

// 保存角色权限
export function saveRoleRight(data) {
  return request({
    url: "/hr/api/v1/permissions/saveRoleRight",
    method: "post",
    data: data
  });
}

// export function updateRoleSetting(data) {
//   return request({
//     url: "/permission/api/v1/roles",
//     method: "put",
//     data
//   });
// }
export function updateRoleSetting(data) {
  return request({
    url: "/hr/api/v1/hrRoles",
    method: "put",
    data: data
  });
}
// 查询角色权限列表
export function checkRoleRights(data) {
  return request({
    url: "/admin/api/v1/permission/queryRightList",
    method: "get",
    params: data
  });
}
// export function checkRoleRights(data) {
//   return request({
//     url: "/hr/api/v1/permissions/rightList",
//     method: "get",
//     params: data
//   });
// }

// 查询用户权限列表
export function checkUserlist(data) {
  return request({
    url: "/hr/api/v1/permissions/rightList",
    method: "get",
    params: data
  });
}

// 根据id查询公司详情
export function companyById(companyId) {
  return request({
    url: "/hr/api/v1/companies/".concat(companyId),
    method: "get"
  });
}

// 重置密码
export function updatePassword(data) {
  return request({
    url: "/hr/api/v1/users/updatePassword",
    method: "post",
    params: data,
    data: data
  });
}