import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
export default {
  data: function data() {
    return {
      columns: [{
        title: "错误数据",
        dataIndex: "reason",
        key: "reason",
        scopedSlots: {
          customRender: "reason"
        }
      }, {
        title: "姓名",
        dataIndex: "empName",
        key: "empName"
      }, {
        title: "身份证号",
        dataIndex: "licenseNumber",
        key: "licenseNumber"
      }, {
        title: "本期收入",
        dataIndex: "income",
        key: "income"
      }, {
        title: "基本养老保险费",
        dataIndex: "endowmentInsurance",
        key: "endowmentInsurance"
      }, {
        title: "基本医疗保险费",
        dataIndex: "medicalInsurance",
        key: "medicalInsurance"
      }, {
        title: "失业保险费",
        dataIndex: "unemploymentInsurance",
        key: "unemploymentInsurance"
      }, {
        title: "房公积金",
        dataIndex: "houseProvidentFund",
        key: "houseProvidentFund"
      }],
      successList: [],
      failList: [],
      fileName: "",
      message: "",
      data: [],
      failExportUrl: ""
    };
  },
  created: function created() {
    this.successList = this.$route.query.successList || [];
    this.failList = this.$route.query.failList || [];
    this.fileName = this.$route.query.fileName;
    console.log(this.fileName, this.successList, this.failList);
    if (this.failList.length !== 0) {
      this.message = "\u5BFC\u5165".concat(this.fileName, "\uFF0C\u6709").concat(this.failList.length, "\u6761\u6570\u636E\u5F02\u5E38\uFF0C\u8BF7\u5C3D\u5FEB\u5904\u7406");
    } else {
      this.message = "\u5BFC\u5165".concat(this.fileName, "\uFF0C\u5171").concat(this.successList.length, "\u4EBA\uFF0C\u65E0\u5F02\u5E38\u6570\u636E");
    }
    this.data = [].concat(_toConsumableArray(this.failList), _toConsumableArray(this.successList));
    this.failExportUrl = this.$route.query.failExportUrl;
    console.log(this.successList, this.failList, this.fileName);
  },
  methods: {
    nextStep: function nextStep() {
      this.$router.push({
        name: "calculateInfo",
        query: {
          id: this.$route.query.batchId
        }
      });
    },
    exportErrData: function exportErrData() {
      var token = this.$store.state.auth.token;
      window.open("".concat(this.failExportUrl, "?token=").concat(token));
    }
  }
};