import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    staticClass: "distribution",
    attrs: {
      title: "分配客户",
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "tit"
  }, [_vm._v(" 您选择了 "), _c("span", [_vm._v(_vm._s(_vm.distributionVal.length))]), _vm._v(" 客户，请选择客户接收人 ")]), _c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "赏金金额",
      "label-col": {
        span: 0
      },
      "wrapper-col": {
        span: 24
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["memberId", {
        rules: [{
          required: true,
          message: "接受人不能为空"
        }]
      }],
      expression: "['memberId', { rules: [{ required: true, message: '接受人不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请选择"
    }
  }, _vm._l(_vm.tableData, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };