import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { getConfig, getServiceOpens as _getServiceOpens } from "./api.js";
import { mapGetters } from "vuex";
export default {
  data: function data() {
    return {
      dataList: [],
      openUrl: "",
      rightIcon: require("@/assets/img/third/rightIcon.png"),
      rightIcon2: require("@/assets/img/third/rightIcon2.png")
    };
  },
  computed: _objectSpread({}, mapGetters(["permissions_dycl"])),
  created: function created() {
    // 招聘权限
    this.getServiceOpens();
  },
  methods: {
    getServiceOpens: function getServiceOpens() {
      var _this = this;
      _getServiceOpens().then(function (res) {
        _this.dataList = res.data;
      });
    },
    goDetail: function goDetail(e) {
      console.log(e);
      if (e.openStatus) {
        this.openUrl = "";
        if (e.serviceCode === "transferAccount") {
          if (this.$hasPermission("transferAccount:faxinshenqing:query")) {
            this.openUrl = "/paySalary/applyFor";
          } else if (this.$hasPermission("transferAccount:faxinshenhe:query")) {
            this.openUrl = "/paySalary/examine";
          } else if (this.$hasPermission("transferAccount:xinzifafang:query")) {
            this.openUrl = "/paySalary/examine";
          } else if (this.$hasPermission("transferAccount:faxinrenyuanku:query")) {
            this.openUrl = "/paySalary/people";
          } else {
            this.$warning({
              title: "暂无权限"
            });
          }
        }
        if (e.serviceCode === "recruitment") {
          if (this.$hasPermission("recruitment:roverview:query")) {
            this.openUrl = "/recruitment/overview";
          } else if (this.$hasPermission("recruitment:management:query")) {
            this.openUrl = "/recruitment/management";
          } else if (this.$hasPermission("recruitment:people:query")) {
            this.openUrl = "/recruitment/people";
          } else if (this.$hasPermission("recruitment:economic:query")) {
            this.openUrl = "/recruitment/economic";
          } else if (this.$hasPermission("recruitment:rpush:query")) {
            this.openUrl = "/recruitment/push";
          } else if (this.$hasPermission("recruitment:laborUnit:query")) {
            this.openUrl = "/recruitment/laborUnit";
          } else if (this.$hasPermission("recruitment:talent:query")) {
            this.openUrl = "/recruitment/talent";
          } else if (this.$hasPermission("recruitment:zhinengtuijian:query")) {
            this.openUrl = "/recruitment/talentZhineng";
          } else {
            this.$warning({
              title: "暂无权限"
            });
          }
        }
        if (e.serviceCode === "clientele") {
          if (this.$hasPermission("clientele:myClientele:query")) {
            this.openUrl = "/clientele/myClientele";
          } else if (this.$hasPermission("clientele:sea:query")) {
            this.openUrl = "/clientele/sea";
          } else if (this.$hasPermission("clientele:member:query")) {
            this.openUrl = "/clientele/member";
          } else {
            this.$warning({
              title: "暂无权限"
            });
          }
        }
        if (e.serviceCode === "contract") {
          this.openUrl = e.serviceUrl;
        }
        if (e.serviceCode === "insuranceAddService") {
          // 商保
          if (this.$hasPermissionSecond("insuranceAddService:insuranceAddService.zaibaorenyuan")) {
            this.openUrl = "/shangbao/list";
          } else if (this.$hasPermissionSecond("insuranceAddService:insuranceAddService.shangbaofangan")) {
            this.openUrl = "/shangbao/plan";
          } else if (this.$hasPermissionSecond("insuranceAddService:insuranceAddService.insuranceManager")) {
            this.openUrl = "/shangyebaoxian";
          } else {
            this.$warning({
              title: "暂无权限"
            });
          }
        }
        if (e.serviceCode === "zhinengsuanshui") {
          if (this.$hasPermissionSecond("zhinengsuanshui:koujiaoyiwurenguanli")) {
            this.openUrl = "/taxCalculate/management";
          } else if (this.$hasPermissionSecond("zhinengsuanshui:baoshuirenyuanku")) {
            this.openUrl = "/taxCalculate/people";
          } else if (this.$hasPermissionSecond("zhinengsuanshui:renyuanbaosong.daibaosong")) {
            this.openUrl = "/taxCalculate/waiting";
          } else if (this.$hasPermissionSecond("zhinengsuanshui:renyuanbaosong.yibaosong")) {
            this.openUrl = "/taxCalculate/report";
          } else if (this.$hasPermissionSecond("zhinengsuanshui:renyuanbaosong.tingzhibaosong")) {
            this.openUrl = "/taxCalculate/stop";
          } else if (this.$hasPermissionSecond("hinengsuanshui:geshuijisuan")) {
            this.openUrl = "/taxCalculate/calculate";
          } else {
            this.$warning({
              title: "暂无权限"
            });
          }
          // this.openUrl = e.serviceUrl;
        }

        if (e.serviceCode === "dycl") {
          if (this.permissions_dycl.length > 0) {
            this.openUrl = e.serviceUrl;
          } else {
            this.$warning({
              title: "暂无权限"
            });
          }
        }
        if (this.openUrl) {
          if (this.openUrl === "/shangyebaoxian") {
            sessionStorage.setItem("shangbaoPageState", "customer");
          }
          var _this$$router$resolve = this.$router.resolve({
              path: "".concat(this.openUrl) // openUrl
            }),
            href = _this$$router$resolve.href;
          window.open(href, "_blank");
        }
      } else {
        this.$warning({
          title: "暂未开启"
        });
      }
    }
  }
};