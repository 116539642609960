import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.splice.js";
import { salaryCalculateRulesListall, saveSalaryCalculateRule2, saveSalaryCalculateRuleEdit } from "@/api/staff/staff";
import { selectOutsourceSalary } from "./api";
import { salaryRulesListByPage, getSalaryCalculateRuleGroups } from "@/api/outsourcing/outrules";
import { selectByCompanyId, detaiByempId, empWage, addSalaryTemplate, batch, batchWage, SalaryIncrease, getEmpJoinDate as _getEmpJoinDate } from "@/api/wages/file";
import drawer from "./drawer.vue";
import drawers from "./drawers.vue";
import moment from "moment";
var columns2 = [{
  title: "生效时间",
  dataIndex: "timeShow",
  key: "timeShow",
  width: 100
}, {
  title: "基础规则",
  dataIndex: "hourAmountRuleShow",
  key: "hourAmountRuleShow1",
  width: 120
}, {
  title: "离职规则",
  dataIndex: "leaveOfficeRuleShow",
  key: "leaveOfficeRuleShow",
  width: 120
}];
var columns = [{
  title: "定薪项目",
  dataIndex: "itemName",
  align: "itemName",
  key: "itemName",
  scopedSlots: {
    customRender: "itemName"
  },
  width: 150
}, {
  title: "试用期工资",
  dataIndex: "trial",
  align: "trial",
  key: "trial",
  scopedSlots: {
    customRender: "trial"
  },
  width: 150
}, {
  title: "转正后工资",
  dataIndex: "formal",
  align: "formal",
  key: "formal",
  scopedSlots: {
    customRender: "formal"
  },
  width: 150
}];
var columns3 = [{
  title: "调薪项目",
  dataIndex: "itemName",
  align: "itemName",
  key: "itemName",
  scopedSlots: {
    customRender: "itemName"
  },
  width: 150
}, {
  title: "调薪后",
  dataIndex: "afterAmount",
  align: "afterAmount",
  key: "afterAmount",
  scopedSlots: {
    customRender: "afterAmount"
  },
  width: 150
}];
var fixItems = [{
  id: 1,
  name: "基本工资",
  selected: true,
  isDefault: true
}, {
  id: 2,
  name: "交通补贴",
  selected: false,
  isDefault: false
}, {
  id: 3,
  name: "住房补贴",
  selected: false,
  isDefault: false
}, {
  id: 4,
  name: "全勤奖",
  selected: false,
  isDefault: false
}, {
  id: 5,
  name: "餐补",
  selected: false,
  isDefault: false
}, {
  id: 6,
  name: "高温补贴",
  selected: false,
  isDefault: false
}, {
  id: 7,
  name: "工龄补贴",
  selected: false,
  isDefault: false
}, {
  id: 8,
  name: "夜班津贴",
  selected: false,
  isDefault: false
}, {
  id: 9,
  name: "通讯补贴",
  selected: false,
  isDefault: false
}, {
  id: 10,
  name: "节假日补贴",
  selected: false,
  isDefault: false
}, {
  id: 11,
  name: "员工慰问金",
  selected: false,
  isDefault: false
}, {
  id: 12,
  name: "推荐奖",
  selected: false,
  isDefault: false
}, {
  id: 13,
  name: "职务津贴",
  selected: false,
  isDefault: false
}, {
  id: 14,
  name: "绩效工资",
  selected: false,
  isDefault: false
}, {
  id: 15,
  name: "季度奖",
  selected: false,
  isDefault: false
}, {
  id: 16,
  name: "销售提成",
  selected: false,
  isDefault: false
}, {
  id: 17,
  name: "计件工资",
  selected: false,
  isDefault: false
}, {
  id: 18,
  name: "工龄工资",
  selected: false,
  isDefault: false
}, {
  id: 19,
  name: "司龄工资",
  selected: false,
  isDefault: false
}, {
  id: 20,
  name: "职称工资",
  selected: false,
  isDefault: false
}, {
  id: 21,
  name: "技能工资",
  selected: false,
  isDefault: false
}, {
  id: 22,
  name: "学历工资",
  selected: false,
  isDefault: false
}];
export default {
  data: function data() {
    var _this = this;
    return {
      columns: columns,
      columns2: columns2,
      moment: moment,
      columns3: columns3,
      dataList2: [],
      dataList: [],
      fixItems: fixItems,
      fixedSalaryTemplateName: "",
      addForm: {
        name: ""
      },
      rules2: {
        name: [{
          required: true,
          message: "必填",
          trigger: "blur"
        }]
      },
      addVisible: false,
      form: {},
      size: "small",
      showType: null,
      showType2: null,
      show: false,
      show1: false,
      spinning: true,
      templateId: "",
      salaryCalculateRuleId: undefined,
      routerData: {},
      rulusList: [],
      detailsList: [],
      officialList: [],
      ruleShow: false,
      disabledDateEnd: "",
      redPack: [],
      ruleShow1: false,
      redPackId: undefined,
      addManFormRef: {
        numList: [],
        leaveList: [],
        hourAmountDouble: null // 劳工
      },

      paginationOpt2: {
        current: 1,
        defaultCurrent: 1,
        // 默认当前页数
        // defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt2.current = 1;
          _this.paginationOpt2.defaultCurrent = 1;
          _this.paginationOpt2.defaultPageSize = pageSize;
          _this.laowufeiguize(); // 显示列表的接口名称
        },

        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt2.current = current;
          _this.paginationOpt2.defaultCurrent = current;
          _this.paginationOpt2.defaultPageSize = size;
          _this.laowufeiguize();
        }
      },
      paginationOpt: {
        current: 1,
        defaultCurrent: 1,
        // 默认当前页数
        // defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.current = 1;
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;
          _this.gongjiaguize(); // 显示列表的接口名称
        },

        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.current = current;
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;
          _this.gongjiaguize();
        }
      },
      option2: [
      // 劳工
      {
        label: "月",
        value: 1
      }, {
        label: "天",
        value: 2
      }, {
        label: "工时",
        value: 3
      }],
      option: [
      // 劳工
      {
        label: "月",
        value: 1
      }, {
        label: "天",
        value: 2
      }, {
        label: "工时",
        value: 3
      }],
      addManFormRef1: {
        commissionRatio1: null,
        hourAmountDouble1: null,
        // 劳务费
        changeList: [],
        numList1: [],
        leaveList1: [],
        specialCostDouble: undefined
      },
      rules: {
        specialCostDouble: [{
          required: true,
          message: "请输入",
          trigger: "change"
        }]
      },
      rulusListCopy: [],
      templateList: [],
      checked3: false,
      // 工时抽成
      checked4: false,
      empIdList: [],
      empList: [],
      empNameList: [],
      lastId: "",
      takeDate: null,
      checked5: false,
      checked6: false,
      info: {},
      checked: false,
      checked1: false,
      labourList: [],
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
  },
  components: {
    drawer: drawer,
    drawers: drawers
  },
  created: function created() {
    var _this2 = this;
    this.empList = JSON.parse(sessionStorage.getItem("empList"));
    this.getEmpJoinDate();
    this.empNameList = JSON.parse(sessionStorage.getItem("empNameList"));
    this.size = this.$route.query.isFixed === "true" ? "default" : "small";
    this.getSelectByCompanyId();
    this.salaryCalculateListall();
    if (this.$route.query.isFixed === "true") {
      this.SalaryIncreaseList();
    }
    setTimeout(function () {
      _this2.spinning = false;
    }, 1000);
  },
  methods: {
    SalaryIncreaseList: function SalaryIncreaseList() {
      var _this3 = this;
      var id = this.$route.query.templateId[0];
      SalaryIncrease(id).then(function (res) {
        _this3.mainId = res.data.id;
        _this3.fixedSalaryTemplateName = res.data.fixedSalaryTemplateName;
        _this3.officialList = res.data.officialList;
        _this3.trainingList = res.data.trainingList;
        for (var i = 0; i < _this3.trainingList.length; i++) {
          _this3.trainingList[i].afterAmount = _this3.trainingList[i].afterAmount ? _this3.trainingList[i].afterAmount : _this3.trainingList[i].amount;
          _this3.trainingList[i].projectId = i;
          _this3.trainingList[i].amplitude = 0.0 + "%";
          _this3.trainingList[i].relativecolor = "#0DB6AC";
        }
        for (var p = 0; p < _this3.officialList.length; p++) {
          // this.officialList[p].afterAmount = this.officialList[p].afterAmount
          //   ? this.officialList[p].afterAmount
          //   : this.officialList[p].amount;
          _this3.officialList[p].afterAmount = 0;
          _this3.officialList[p].projectId = p;
          _this3.officialList[p].amplitude = 0.0 + "%";
          _this3.officialList[p].relativecolor = "#0DB6AC";
        }
      });
    },
    gongjiaguize: function gongjiaguize() {
      var _this4 = this;
      if (this.salaryCalculateRuleId) {
        salaryRulesListByPage({
          pageNo: this.paginationOpt.defaultCurrent,
          pageSize: this.paginationOpt.defaultPageSize,
          salaryCalculateRuleGroupId: this.salaryCalculateRuleId
        }).then(function (res) {
          _this4.dataList = res.data.entities;
          _this4.paginationOpt.total = res.data.entityCount;
        });
      } else {
        this.dataList = [];
      }
    },
    laowufeiguize: function laowufeiguize() {
      var _this5 = this;
      if (this.redPackId) {
        salaryRulesListByPage({
          pageNo: this.paginationOpt2.defaultCurrent,
          pageSize: this.paginationOpt2.defaultPageSize,
          salaryCalculateRuleGroupId: this.redPackId
        }).then(function (res) {
          _this5.dataList2 = res.data.entities;
          _this5.paginationOpt2.total = res.data.entityCount;
        });
      } else {
        this.dataList2 = [];
      }
    },
    handleAddCancel: function handleAddCancel() {
      this.$refs.addForm.clearValidate();
    },
    handleAddOk: function handleAddOk() {
      var _this6 = this;
      var arr = this.fixItems.filter(function (item, index) {
        if (item.selected) return true;
      });
      var data = {
        companyId: this.companyId,
        isDefault: false,
        name: this.addForm.name,
        itemIds: arr.map(function (item) {
          return item.id;
        }).join(",")
      };
      this.$refs.addForm.validate(function (valid) {
        if (valid) {
          addSalaryTemplate(data).then(function (res) {
            _this6.addVisible = false;
            _this6.getSelectByCompanyId();
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        }
      });
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    computeItemClass: function computeItemClass(item) {
      if (item.id === 1) return "fix-salary-item-tag fix-salary-item-default";
      if (item.selected) return "fix-salary-item-tag fix-salary-item-selected";
      return "fix-salary-item-tag";
    },
    goIncrease: function goIncrease() {
      this.addVisible = true;
      this.addForm = {};
      this.fixItems.forEach(function (item, index) {
        if (index > 0) item.selected = false;
      });
      // this.$router.push({
      //   path: '/wages/file/template'
      // })
    },
    getDetaiByempId: function getDetaiByempId() {
      var _this7 = this;
      var id = this.templateId ? this.templateId : this.lastId;
      detaiByempId(id).then(function (res) {
        _this7.detailsList = res.data;
        _this7.trialTemplateList = JSON.parse(JSON.stringify(res.data));
        _this7.formalTemplateList = JSON.parse(JSON.stringify(res.data));
        for (var i = 0; i < _this7.detailsList.length; i++) {
          _this7.detailsList[i].projectId = i;
          _this7.detailsList[i].trial = _this7.detailsList[i].trial ? _this7.detailsList[i].trial : 0;
          _this7.detailsList[i].formal = _this7.detailsList[i].formal ? _this7.detailsList[i].formal : 0;
        }
      });
    },
    handleAddSelected: function handleAddSelected(item) {
      if (item.id === 1) return;
      item.selected = !item.selected;
    },
    getSelectByCompanyId: function getSelectByCompanyId() {
      var _this8 = this;
      selectByCompanyId({
        companyId: this.companyId
      }).then(function (res) {
        _this8.templateList = res.data;
        _this8.templateList.forEach(function (item, index) {
          _this8.templateList[0].style = "buttonStyleClick";
        });
        _this8.templateId = _this8.templateList[0].id;
        _this8.lastId = _this8.templateList[0].id;
        _this8.lastName = _this8.templateList[0].name;
        // this.templateList[0].color = "#6A66F6";
        _this8.getDetaiByempId();
      });
    },
    templateChange: function templateChange(id, key, item) {
      // item.style = "buttonStyleClick";
      this.templateList[0].style = null;
      this.fixedSalaryTemplateName = item.name;
      // this.templateList[0].color = null;
      for (var i = 0; i < this.templateList.length; i++) {
        if (this.templateList[i].id === id) {
          this.templateList[i].style = "buttonStyleClick";
        } else {
          this.templateList[i].style = null;
        }
      }
      this.detailsList = [];
      this.templateId = id;
      this.detailsList;
      this.getDetaiByempId();
    },
    handleInputChange2: function handleInputChange2(value, key) {
      var arr = JSON.parse(JSON.stringify(this.officialList));
      for (var i = 0; i < arr.length; i++) {
        if (i === key) {
          arr[i].afterAmount = value;
          this.officialList[i].afterAmount = value;
          if (arr[i].amount === 0) {
            arr[i].amplitude = "--";
          } else {
            arr[i].amplitude = Math.round((arr[i].afterAmount - arr[i].amount) / arr[i].amount * 10000) / 100 + "%";
          }
          if (arr[i].afterAmount - arr[i].amount > 0) {
            arr[i].relativecolor = "#FA5051";
          } else {
            arr[i].relativecolor = "#0DB6AC";
          }
        }
      }
      var s = 0;
      for (var t = 0; t < arr.length - 1; t++) {
        s += arr[t].afterAmount;
        arr[arr.length - 1].afterAmount = s;
        if (arr[arr.length - 1].amount === 0) {
          arr[arr.length - 1].amplitude = "--";
        } else {
          arr[arr.length - 1].amplitude = Math.round((arr[arr.length - 1].afterAmount - arr[arr.length - 1].amount) / arr[arr.length - 1].amount * 10000) / 100 + "%";
        }
        if (arr[arr.length - 1].afterAmount - arr[arr.length - 1].amount > 0) {
          arr[arr.length - 1].relativecolor = "#FA5051";
        } else {
          arr[arr.length - 1].relativecolor = "#0DB6AC";
        }
        this.officialList = JSON.parse(JSON.stringify(arr));
      }
    },
    handleSubmit: function handleSubmit() {
      var _this9 = this;
      var serviceFeeRuleAddDTO = {};
      this.empIdList = JSON.parse(JSON.stringify(this.empList));
      for (var i = 0; i < this.detailsList.length; i++) {
        this.trialTemplateList[i].amount = this.trialTemplateList[i].amount ? this.trialTemplateList[i].amount : 0;
        this.formalTemplateList[i].amount = this.formalTemplateList[i].amount ? this.formalTemplateList[i].amount : 0;
      }
      if (this.$route.query.isEdit) {
        if (this.$route.query.isFixed === "true") {
          if (!this.officialList[this.officialList.length - 1].amplitude && !this.trainingList[this.trainingList.length - 1].amplitude) {
            this.$notification["error"]({
              message: "error",
              description: "保存失败,至少更改一项调薪项目"
            });
          } else {
            this.trainingList.forEach(function (item, index) {
              item.amount = item.afterAmount;
            });
            this.officialList.forEach(function (item, index) {
              item.amount = item.afterAmount;
            });
            batchWage({
              serviceFeeRuleId: this.redPackId,
              idList: this.$route.query.templateId,
              id: 0,
              empId: "empId",
              serviceFeeRuleAddDTO: serviceFeeRuleAddDTO,
              isFixed: 0,
              companyId: this.companyId,
              // empId: this.routerData.empId,
              trainingFixedSalaryDetail: this.trainingList,
              officialFixedSalaryDetail: this.officialList,
              reason: this.form.reason,
              effectTime: this.takeDate,
              remark: this.form.remark,
              isOfficial: parseInt(this.routerData.employType),
              // id: this.mainId,
              trainingSalarySum: this.trainingList[this.trainingList.length - 1].afterAmount,
              officialSalarySum: this.officialList[this.officialList.length - 1].afterAmount
            }).then(function (_ref) {
              var success = _ref.success;
              for (var i = 0; i < _this9.trainingList.length; i++) {
                _this9.trainingList[i].amount = _this9.trainingList[i].afterAmount;
              }
              for (var p = 0; p < _this9.officialList.length; p++) {
                _this9.officialList[p].amount = _this9.officialList[p].afterAmount;
              }
              if (success) {
                var values = {
                  // empIdList: JSON.parse(this.$route.query.selectedRowKeys),
                  companyId: _this9.companyId,
                  // 租户id
                  serviceFeeRuleId: _this9.redPackId ? _this9.redPackId : null,
                  // 劳务费id
                  empIdList: _this9.$route.query.templateId,
                  phone: "phone"
                };
                saveSalaryCalculateRuleEdit(_objectSpread(_objectSpread({}, values), {}, {
                  salaryType: _this9.size === "default" ? 1 : 2,
                  id: "Batch"
                })).then(function (res) {
                  _this9.$message.success("编辑成功");
                  if (_this9.$route.query.state === "outsourcing") {
                    _this9.$router.push({
                      path: "/staff/employee"
                    });
                  } else {
                    setTimeout(function () {
                      _this9.$router.push({
                        path: "/wages/file",
                        query: {
                          tabsKey: "2"
                        }
                      });
                    }, 500);
                  }
                });
                _this9.$router.push({
                  path: "/wages/file",
                  query: {
                    tabsKey: "1"
                  }
                });
                _this9.$notification["success"]({
                  message: "编辑成功"
                });
              }
            });
          }
        } else {
          batch({
            // empIdList: this.empIdList,
            idList: this.$route.query.templateId,
            // id: 0,
            empIdList: this.empIdList,
            // effectTime: moment(),
            serviceFeeRuleId: this.redPackId ? this.redPackId : null,
            fixedSalaryTemplateName: this.fixedSalaryTemplateName ? this.fixedSalaryTemplateName : this.lastName,
            isFixed: 1,
            isOfficial: parseInt(this.routerData.employType),
            companyId: this.companyId,
            fixedSalaryTemplateId: this.templateId ? this.templateId : this.lastId,
            reason: 0,
            trainingFixedSalaryDetail: JSON.stringify(this.trialTemplateList),
            officialFixedSalaryDetail: JSON.stringify(this.formalTemplateList),
            officialSalarySum: this.formalTemplateList[this.formalTemplateList.length - 1].amount ? this.formalTemplateList[this.formalTemplateList.length - 1].amount : 0,
            trainingSalarySum: this.trialTemplateList[this.trialTemplateList.length - 1].amount ? this.trialTemplateList[this.trialTemplateList.length - 1].amount : 0,
            remark: this.form.remark
          }).then(function (_ref2) {
            var success = _ref2.success;
            if (success) {
              var values = {
                // empIdList: JSON.parse(this.$route.query.selectedRowKeys),
                companyId: _this9.companyId,
                // 租户id
                serviceFeeRuleId: _this9.redPackId ? _this9.redPackId : null,
                // 劳务费id
                empIdList: _this9.$route.query.templateId,
                phone: "phone"
              };
              saveSalaryCalculateRuleEdit(_objectSpread(_objectSpread({}, values), {}, {
                salaryType: _this9.size === "default" ? 1 : 2,
                id: "Batch"
              })).then(function (res) {
                _this9.$message.success("编辑成功");
                if (_this9.$route.query.state === "outsourcing") {
                  _this9.$router.push({
                    path: "/staff/employee"
                  });
                } else {
                  setTimeout(function () {
                    _this9.$router.push({
                      path: "/wages/file",
                      query: {
                        tabsKey: "2"
                      }
                    });
                  }, 500);
                }
              });
              _this9.$router.push({
                path: "/wages/file",
                query: {
                  tabsKey: "1"
                }
              });
              _this9.$notification["success"]({
                message: "编辑成功"
              });
            }
          });
        }
      } else {
        batch({
          empIdList: this.empIdList,
          serviceFeeRuleId: this.redPackId ? this.redPackId : null,
          fixedSalaryTemplateName: this.fixedSalaryTemplateName ? this.fixedSalaryTemplateName : this.lastName,
          isFixed: 1,
          isOfficial: parseInt(this.routerData.employType),
          companyId: this.companyId,
          fixedSalaryTemplateId: this.templateId ? this.templateId : this.lastId,
          reason: 0,
          trainingFixedSalaryDetail: JSON.stringify(this.trialTemplateList),
          officialFixedSalaryDetail: JSON.stringify(this.formalTemplateList),
          officialSalarySum: this.formalTemplateList[this.formalTemplateList.length - 1].amount ? this.formalTemplateList[this.formalTemplateList.length - 1].amount : 0,
          trainingSalarySum: this.trialTemplateList[this.trialTemplateList.length - 1].amount ? this.trialTemplateList[this.trialTemplateList.length - 1].amount : 0,
          remark: this.form.remark
        }).then(function (_ref3) {
          var success = _ref3.success;
          if (success) {
            _this9.$router.push({
              path: "/wages/file",
              query: {
                tabsKey: "1"
              }
            });
            _this9.$notification["success"]({
              message: "编辑成功"
            });
          }
        });
      }
    },
    handleTimeChange: function handleTimeChange(e) {
      this.takeDate = e;
      console.log(e);
    },
    handleInputChange: function handleInputChange(value, key, col) {
      var arr = JSON.parse(JSON.stringify(this.detailsList));
      if (col === "trial") {
        for (var i = 0; i < arr.length; i++) {
          if (i === key) {
            arr[i].trial = value;
            this.trialTemplateList[i].amount = value;
            this.trialTemplateList[i].name = this.trialTemplateList[i].itemName;
            // this.trialTemplateList[i].projectId = key
          }
        }

        var s = 0;
        for (var t = 0; t < arr.length - 1; t++) {
          s += arr[t].trial;
          arr[arr.length - 1].trial = s;
          this.trialTemplateList[this.trialTemplateList.length - 1].amount = s;
        }
      }
      if (col === "formal") {
        for (var p = 0; p < arr.length; p++) {
          if (p === key) {
            arr[p].formal = value;
            this.formalTemplateList[p].amount = value;
            this.formalTemplateList[p].name = this.formalTemplateList[p].itemName;
            // this.formalTemplateList[p].projectId = key
          }
        }

        var sum = 0;
        for (var a = 0; a < arr.length - 1; a++) {
          sum += arr[a].formal;
          arr[arr.length - 1].formal = sum;
          this.formalTemplateList[this.formalTemplateList.length - 1].amount = sum;
        }
      }
      this.detailsList = JSON.parse(JSON.stringify(arr));
    },
    radioGroupChange: function radioGroupChange(e) {
      // this.salaryCalculateRuleId = undefined;
      // this.handleRulesChange(this.salaryCalculateRuleId);
      // if (this.size === "large") {
      //   var arr = [];
      //   this.rulusList.forEach((item, index) => {
      //     if (item.showType && item.showType === 1) {
      //       arr.push(item);
      //     }
      //   });
      //   this.rulusListCopy = JSON.parse(JSON.stringify(arr));
      // }
      // if (this.size === "small") {
      //   var arr2 = [];
      //   this.rulusList.forEach((item, index) => {
      //     if (item.showType && item.showType === 2) {
      //       arr2.push(item);
      //     }
      //   });
      //   this.rulusListCopy = JSON.parse(JSON.stringify(arr2));
      // }
    },
    // 获取数组里的每一个时间
    onDateChange: function onDateChange(date, dateString, item) {
      item.time = dateString;
    },
    // 返回
    goOut: function goOut() {
      var _this10 = this;
      setTimeout(function () {
        _this10.$router.push({
          path: "/wages/file",
          query: {
            tabsKey: "3"
          }
        });
      }, 500);
    },
    // 打开工价新增弹窗
    createDrawer: function createDrawer() {
      this.$refs.drawers.onCreate();
    },
    // 打开劳务费新增弹窗
    createDrawer1: function createDrawer1() {
      this.$refs.drawersl.onCreate();
    },
    // 劳务费添加完成更新列表
    fatherMethod: function fatherMethod() {
      var _this11 = this;
      salaryCalculateRulesListall({
        ruleType: 2
      }).then(function (res) {
        _this11.redPack = res.data;
      });
    },
    // 工价添加完成更新列表
    update: function update() {
      var _this12 = this;
      this.rulusList = [];
      salaryCalculateRulesListall().then(function (res) {
        if (res.data.length > 0) {
          res.data.map(function (item) {
            if (item.ruleType === 1 || item.ruleType === 3) {
              _this12.rulusList.push(item);
            }
          });
        }
      });
    },
    limitNumber: function limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      } else {
        return 0;
      }
    },
    handleEmpyleeSubmitType: function handleEmpyleeSubmitType() {
      if (this.size === "small" || this.size === "large") {
        this.handleEmpyleeSubmit();
      }
      if (this.size === "default") {
        this.handleSubmit();
      }
    },
    // 保存
    handleEmpyleeSubmit: function handleEmpyleeSubmit(e) {
      var _this13 = this;
      var values = {
        empIdList: this.$route.query.templateId,
        companyId: this.companyId,
        // 租户id
        serviceFeeRuleId: this.redPackId,
        // 劳务费id
        salaryRuleId: this.salaryCalculateRuleId,
        // 工价id
        phone: "phone"
      };
      if (this.$route.query.isEdit) {
        saveSalaryCalculateRuleEdit(_objectSpread(_objectSpread({}, values), {}, {
          salaryType: 2,
          id: "Batch"
        })).then(function (res) {
          _this13.$message.success("编辑成功");
          if (_this13.$route.query.state === "outsourcing") {
            _this13.$router.push({
              path: "/staff/employee"
            });
          } else {
            setTimeout(function () {
              _this13.$router.push({
                path: "/wages/file",
                query: {
                  tabsKey: "2"
                }
              });
            }, 500);
          }
        });
      } else {
        saveSalaryCalculateRule2(values).then(function (res) {
          _this13.$message.success("编辑成功");
          if (_this13.$route.query.state === "outsourcing") {
            _this13.$router.push({
              path: "/staff/employee"
            });
          } else {
            setTimeout(function () {
              _this13.$router.push({
                path: "/wages/file",
                query: {
                  tabsKey: "2"
                }
              });
            }, 500);
          }
        });
      }
    },
    // 工价列表下拉事件渲染数据
    handleRulesChange: function handleRulesChange(e) {
      var _this14 = this;
      if (e) {
        this.paginationOpt.defaultCurrent = 1;
        this.paginationOpt.defaultPageSize = 10;
        salaryRulesListByPage({
          pageNo: this.paginationOpt.defaultCurrent,
          pageSize: this.paginationOpt.defaultPageSize,
          salaryCalculateRuleGroupId: e
        }).then(function (res) {
          _this14.dataList = res.data.entities;
          _this14.paginationOpt.total = res.data.entityCount;
        });
      } else {
        this.dataList = [];
        this.paginationOpt.total = 0;
      }
    },
    // 劳务费规则下拉事件渲染数据
    redPackChange: function redPackChange(e) {
      var _this15 = this;
      if (e) {
        this.paginationOpt2.defaultCurrent = 1;
        this.paginationOpt2.defaultPageSize = 10;
        salaryRulesListByPage({
          pageNo: this.paginationOpt2.defaultCurrent,
          pageSize: this.paginationOpt2.defaultPageSize,
          salaryCalculateRuleGroupId: e
        }).then(function (res) {
          _this15.dataList2 = res.data.entities;
          _this15.paginationOpt2.total = res.data.entityCount;
        });
      } else {
        this.dataList2 = [];
        this.paginationOpt2.total = 0;
      }
      // if (e) {
      //   this.redPackId = e;
      //   this.ruleShow1 = true;

      //   this.redPack.map(item => {
      //     if (item.id === e) {
      //       this.showType2 = item.showType;
      //       if (item.showType === 1) {
      //         this.option2 = [
      //           {
      //             label: "月",
      //             value: 1
      //           },
      //           {
      //             label: "天",
      //             value: 3
      //           }
      //         ];
      //       } else {
      //         this.option2 = [
      //           {
      //             label: "月",
      //             value: 1
      //           },
      //           {
      //             label: "天",
      //             value: 2
      //           },
      //           {
      //             label: "工时",
      //             value: 3
      //           }
      //         ];
      //       }
      //       this.addManFormRef1.commissionRatio1 = item.commissionRatio;
      //       this.addManFormRef1.hourAmountDouble1 = item.hourAmountDouble;
      //       this.addManFormRef1.changeList = JSON.parse(item.hourAmountRule);
      //       this.addManFormRef1.numList1 = JSON.parse(item.redPacketRule);
      //       this.addManFormRef1.leaveList1 = JSON.parse(item.leaveOfficeRule);
      //       this.addManFormRef1.specialCostDouble = item.specialCostDouble;
      //       this.addManFormRef1.changeList.map(item => {
      //         item.type = String(item.type);
      //       });
      //       this.addManFormRef1.leaveList1.map(item => {
      //         item.type = String(item.type);
      //       });
      //       if (
      //         this.addManFormRef1.changeList.length > 0 ||
      //         this.addManFormRef1.hourAmountDouble1 !== 0 ||
      //         this.addManFormRef1.commissionRatio1 > 0
      //       ) {
      //         this.checked3 = true;
      //       }
      //       if (this.addManFormRef1.numList1.length > 0) {
      //         this.checked4 = true;
      //       }
      //       if (this.addManFormRef1.leaveList1.length > 0) {
      //         this.checked5 = true;
      //       }
      //       if (this.addManFormRef1.specialCostDouble) {
      //         this.checked6 = true;
      //       }
      //     }
      //   });
      // } else {
      //   this.ruleShow1 = false;
      //   this.show1 = false;
      // }
    },
    getEmpJoinDate: function getEmpJoinDate() {
      var _this16 = this;
      _getEmpJoinDate({
        companyId: this.$store.state.hr.companyId,
        empIdList: this.empList
      }).then(function (res) {
        _this16.disabledDateEnd = res.data;
      }).catch(function (err) {
        console.log(err);
      });
    },
    disabledEndDate: function disabledEndDate(endValue) {
      var startValue = moment(moment(this.disabledDateEnd).format("YYYY-MM-DD"), "YYYY-MM-DD");
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    // 获取工价劳务费规则列表并首次渲染页面数据
    salaryCalculateListall: function salaryCalculateListall() {
      var _this17 = this;
      getSalaryCalculateRuleGroups({
        companyId: this.$store.state.hr.companyId
      }).then(function (res) {
        res.data.forEach(function (item, index) {
          if (item.ruleType === 1) {
            _this17.rulusListCopy.push(item);
          } else if (item.ruleType === 2) {
            _this17.redPack.push(item);
          }
        });
      });
    },
    // 工价计算数组
    handleMenuClick1: function handleMenuClick1(e) {
      if (e.key === "2") {
        this.labourList.push({
          type: e.key,
          number: null,
          units: "",
          newHourAmount: null
        }); // 不满月/天/工时倒扣工资
      } else {
        this.labourList.push({
          type: e.key,
          time: null,
          newHourAmount: null
        }); // 不满月/天/工时减少全价
      }
    },
    // 工价一次性红包添加事件
    onChange: function onChange(e) {
      if (e.target.checked) {
        this.addManFormRef.numList.push({
          number: null,
          units: "",
          redPacketAmount: null
        });
        this.checked = true;
      } else {
        this.addManFormRef.numList = [];
        this.checked = false;
      }
    },
    // 工价一次性红包删除
    deleteNumList: function deleteNumList(item, index) {
      this.addManFormRef.numList.splice(index, 1);
      if (this.addManFormRef.numList.length === 0) {
        this.checked = false;
      }
    },
    // 工价一次性红包数组
    createNumList: function createNumList() {
      this.addManFormRef.numList.push({
        number: null,
        units: "",
        redPacketAmount: null
      });
    },
    // 工价计算删除
    deletelabourList: function deletelabourList(item, index) {
      this.labourList.splice(index, 1);
    },
    // 工价离职倒扣添加事件
    onChange1: function onChange1(e) {
      if (e.target.checked) {
        this.checked1 = true;
      } else {
        this.checked1 = false;
        this.addManFormRef.leaveList = [];
      }
    },
    // 工价离职倒扣删除
    deleteleaveList: function deleteleaveList(item, index) {
      this.addManFormRef.leaveList.splice(index, 1);
      if (this.addManFormRef.leaveList.length === 0) {
        this.checked1 = false;
      }
    },
    // 工价倒扣数组
    handleMenuClick: function handleMenuClick(e) {
      if (e.key === "1") {
        this.addManFormRef.leaveList.push({
          type: e.key,
          number: null,
          units: "",
          deductAmount: null
        }); // 不满月/天/工时倒扣工资
      } else {
        this.addManFormRef.leaveList.push({
          type: e.key,
          number: null,
          units: "",
          newHourAmount: null
        }); // 不满月/天/工时减少全价
      }
    },
    // 劳务费其他费用
    onChange6: function onChange6(e) {
      if (e.target.checked) {
        this.checked6 = true;
      } else {
        this.checked6 = false;
        this.addManFormRef1.specialCostDouble = 0;
      }
    },
    // 劳务费离职倒扣
    onChange5: function onChange5(e) {
      if (e.target.checked) {
        this.checked5 = true;
      } else {
        this.checked5 = false;
        this.addManFormRef1.leaveList1 = [];
      }
    },
    // 劳务费一次性红包添加事件
    onChange4: function onChange4(e) {
      if (e.target.checked) {
        this.addManFormRef1.numList1.push({
          number: null,
          units: null,
          redPacketAmount: null
        });
        this.checked4 = true;
      } else {
        this.addManFormRef1.numList1 = [];
        this.checked4 = false;
      }
    },
    // 劳务费工时抽成
    onChange3: function onChange3(e) {
      if (e.target.checked) {
        this.checked3 = true;
      } else {
        this.checked3 = false;
        this.addManFormRef1.changeList = [];
      }
    },
    // 劳务费工时抽成变更条件
    handleMenuClick2: function handleMenuClick2(e) {
      if (e.key === "1") {
        this.addManFormRef1.changeList.push({
          type: e.key,
          newHourAmount: null,
          time: null
        });
      } else {
        this.addManFormRef1.changeList.push({
          type: e.key,
          number: null,
          units: "",
          newHourAmount: null
        });
      }
    },
    // 劳务费一次性红包删除
    deleteNumList1: function deleteNumList1(item, index) {
      this.addManFormRef1.numList1.splice(index, 1);
      if (this.addManFormRef1.numList1.length === 0) {
        this.checked4 = false;
      }
    },
    // 劳务费一次性红包数组
    createNumList1: function createNumList1() {
      this.addManFormRef1.numList1.push({
        number: null,
        units: "",
        redPacketAmount: null
      });
    },
    // 劳务费新增倒扣条件
    deducMenuClick: function deducMenuClick(e) {
      if (e.key === "1") {
        this.addManFormRef1.leaveList1.push({
          type: e.key,
          number: null,
          units: "",
          deductAmount: null
        }); // 不满月/天/工时倒扣工资
      } else {
        this.addManFormRef1.leaveList1.push({
          type: e.key,
          number: null,
          units: "",
          newHourAmount: null
        }); // 不满月/天/工时减少全价
      }
    },
    // 劳务费删除工时抽成条件
    deleteChangeList: function deleteChangeList(item, index) {
      this.addManFormRef1.changeList.splice(index, 1);
      if (this.addManFormRef1.changeList.length === 0) {
        this.checked3 = false;
      }
    },
    //  劳务费离职倒扣删除
    deleteLeave: function deleteLeave(item, index) {
      this.addManFormRef1.leaveList1.splice(index, 1);
      if (this.addManFormRef1.leaveList1.length === 0) {
        this.checked5 = false;
      }
    }
  }
};