import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ph48"
  }, [_c("div", {
    staticClass: "flex-center-between"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入工资项"
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  }), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onAdd
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 添加自定义统计项 ")], 1)], 1), _c("div", {
    staticClass: "mt16"
  }, [_c("a-table", {
    attrs: {
      scroll: {
        x: true,
        y: true
      },
      dataSource: _vm.dataSourceNewFiltered,
      columns: _vm.columns,
      loading: _vm.loading,
      rowKey: "id",
      size: "middle"
    },
    scopedSlots: _vm._u([{
      key: "isOpen",
      fn: function fn(text, record) {
        return [_c("a-checkbox", {
          attrs: {
            disabled: !record.canClose,
            checked: text
          },
          on: {
            change: function change($event) {
              return _vm.onOpenChange($event, record.id);
            }
          }
        })];
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("a-space", {
          attrs: {
            size: 12
          }
        }, [record.canUpdate ? _c("span", {
          staticClass: "pointer tc-theme",
          on: {
            click: function click($event) {
              return _vm.onEdit(record);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), record.canDelete ? _c("a-popconfirm", {
          attrs: {
            title: "\u786E\u8BA4\u5220\u9664".concat(record.name, "\u5417\uFF0C\u672C\u7528\u5DE5\u5355\u4F4D\u4E2D\u672A\u5F52\u6863\u7684\u6708\u5DE5\u8D44\u8868\u4E2D\u8BE5\u9879\u5C06\u88AB\u540C\u6B65\u5220\u9664\u5E76\u6E05\u7A7A\u6570\u636E")
          },
          on: {
            confirm: function confirm($event) {
              return _vm.doDelete(record.id);
            }
          }
        }, [_c("span", {
          staticClass: "pointer tc-theme"
        }, [_vm._v("删除")])]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c("div", {
    staticClass: "mt16 ta-center pb24"
  }, [_c("a-space", {
    attrs: {
      size: 24
    }
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        return _vm.$emit("cancel");
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("prev");
      }
    }
  }, [_vm._v("上一步")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("next");
      }
    }
  }, [_vm._v("下一步")])], 1)], 1), _c("a-modal", {
    attrs: {
      title: _vm.modalTitle
    },
    on: {
      cancel: _vm.doCancel,
      ok: _vm.doOk
    },
    model: {
      value: _vm.modalVisible,
      callback: function callback($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c("div", [_c("span", [_c("i", {
    staticClass: "tc-red"
  }, [_vm._v("*")]), _vm._v(" 项目名称：")]), _c("a-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c("div", {
    staticClass: "mt16"
  }, [_c("div", {
    staticClass: "flex-center-between"
  }, [_c("div", [_c("i", {
    staticClass: "tc-red"
  }, [_vm._v("*")]), _vm._v(" 计算公式：")]), _c("a-space", [_c("span", [_vm._v("运算符：")]), _c("a-button", {
    staticClass: "ph6",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("+");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-jiahao",
    staticStyle: {
      "font-size": "12px"
    }
  })]), _c("a-button", {
    staticClass: "ph6",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("-");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-jianhao",
    staticStyle: {
      "font-size": "12px"
    }
  })]), _c("a-button", {
    staticClass: "ph6",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("*");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-chenghao",
    staticStyle: {
      "font-size": "12px"
    }
  })]), _c("a-button", {
    staticClass: "ph6",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("/");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-chuhao",
    staticStyle: {
      "font-size": "12px"
    }
  })]), _c("a-button", {
    staticClass: "ph6",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("(");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-zuokuohao",
    staticStyle: {
      "font-size": "12px"
    }
  })]), _c("a-button", {
    staticClass: "ph6",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        _vm.insertAtCursor(")");
      }
    }
  }, [_c("i", {
    staticClass: "iconfont icon-youkuohao",
    staticStyle: {
      "font-size": "12px"
    }
  })]), _c("a-button", {
    staticClass: "ph8",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("%");
      }
    }
  }, [_vm._v("%")]), _c("a-button", {
    staticClass: "ph10",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor(".");
      }
    }
  }, [_vm._v(".")])], 1)], 1), _c("div", {
    staticClass: "flex-center-between mt8"
  }, [_c("div"), _c("a-space", [_c("a-button", {
    staticClass: "ph8",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("0");
      }
    }
  }, [_vm._v("0")]), _c("a-button", {
    staticClass: "ph8",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("1");
      }
    }
  }, [_vm._v("1")]), _c("a-button", {
    staticClass: "ph8",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("2");
      }
    }
  }, [_vm._v("2")]), _c("a-button", {
    staticClass: "ph8",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("3");
      }
    }
  }, [_vm._v("3")]), _c("a-button", {
    staticClass: "ph8",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("4");
      }
    }
  }, [_vm._v("4")]), _c("a-button", {
    staticClass: "ph8",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("5");
      }
    }
  }, [_vm._v("5")]), _c("a-button", {
    staticClass: "ph8",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("6");
      }
    }
  }, [_vm._v("6")]), _c("a-button", {
    staticClass: "ph8",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("7");
      }
    }
  }, [_vm._v("7")]), _c("a-button", {
    staticClass: "ph8",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("8");
      }
    }
  }, [_vm._v("8")]), _c("a-button", {
    staticClass: "ph8",
    staticStyle: {
      height: "26px"
    },
    on: {
      click: function click($event) {
        return _vm.insertAtCursor("9");
      }
    }
  }, [_vm._v("9")])], 1)], 1), _c("a-textarea", {
    staticClass: "br4 border p8 mt16",
    staticStyle: {
      "min-height": "160px",
      resize: "none"
    },
    attrs: {
      id: "textarea",
      placeholder: "输入公式"
    },
    on: {
      keydown: _vm.onKeydown
    },
    model: {
      value: _vm.statisticalFormula,
      callback: function callback($$v) {
        _vm.statisticalFormula = $$v;
      },
      expression: "statisticalFormula"
    }
  }), _c("div", {
    staticClass: "flex mt16",
    staticStyle: {
      width: "100%"
    }
  }, [_c("div", {
    staticStyle: {
      width: "50%"
    }
  }, [_c("div", {
    staticClass: "title-block mb12"
  }, [_vm._v("工资项")]), _c("div", {
    staticClass: "flex flex-wrap"
  }, _vm._l(_vm.wageItemLst, function (item) {
    return _c("a-button", {
      key: item.name,
      staticClass: "fs12 mt8 mr8",
      attrs: {
        size: "small"
      },
      on: {
        click: function click($event) {
          return _vm.insertAtCursor(item.name, true);
        }
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)]), _c("div", {
    staticStyle: {
      width: "50%"
    }
  }, [_c("div", {
    staticClass: "title-block"
  }, [_vm._v("统计项")]), _c("div", {
    staticClass: "flex flex-wrap"
  }, _vm._l(_vm.statItemLst, function (item) {
    return _c("a-button", {
      key: item.name,
      staticClass: "fs12 mt8 mr8",
      attrs: {
        size: "small"
      },
      on: {
        click: function click($event) {
          return _vm.insertAtCursor(item.name, true);
        }
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)])])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };