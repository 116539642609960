export default {
  fields: {
    empName: {
      model: "empName"
    },
    depName: {
      model: "depName"
    },
    postName: {
      model: "postName"
    },
    empPhone: {
      model: "empPhone"
    },
    empEmail: {
      model: "empEmail"
    },
    recommendCount: {
      model: "recommendCount"
    },
    entryCount: {
      model: "entryCount"
    },
    PositionCount: {
      model: "PositionCount"
    },
    gmtCreate: {
      model: "gmtCreate"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "empName",
        title: "姓名",
        width: 200
      }, {
        model: "depName",
        title: "部门"
      }, {
        model: "postName",
        title: "职位"
      }, {
        model: "empPhone",
        title: "手机号"
      }, {
        model: "empEmail",
        title: "邮箱"
      }, {
        model: "recommendCount",
        title: "推荐人数"
      }, {
        model: "entryCount",
        title: "成功入职"
      }
      // {
      //   model: "PositionCount",
      //   title: "获得赏金"
      // },
      // {
      //   model: "gmtCreate",
      //   title: "创建时间"
      // }
      ],

      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: false
      }
    }
  }
};