var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    staticClass: "share",
    attrs: {
      title: _vm.shareType == "poster" ? "分享职位" : "面试邀请",
      footer: null,
      width: _vm.shareType == "poster" ? 800 : 560
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_vm.loading ? _c("a-spin", {
    staticClass: "spins",
    style: {
      height: _vm.shareType == "poster" ? "670px" : "316px"
    },
    attrs: {
      tip: "加载中"
    }
  }) : _vm._e(), _vm.shareType == "poster" ? _c("div", {
    staticClass: "img"
  }, [_c("div", {
    staticClass: "imgl"
  }, [_c("img", {
    staticClass: "imgl-back",
    attrs: {
      src: _vm.phoneBack
    }
  }), _c("img", {
    staticClass: "imgl-poster",
    attrs: {
      src: _vm.qrCodeUrl + "?token=" + _vm.token
    }
  })]), _c("div", [_c("div", {
    staticClass: "imgtit"
  }, [_vm._v("下载")]), _c("a-button", {
    staticStyle: {
      "margin-bottom": "40px"
    },
    on: {
      click: _vm.saveImg
    }
  }, [_vm._v("下载海报")]), _c("a-button", {
    staticClass: "ml12",
    staticStyle: {
      "margin-bottom": "40px"
    },
    on: {
      click: _vm.saveImgQrCode
    }
  }, [_vm._v("下载二维码")]), _c("div", {
    staticClass: "imgtit"
  }, [_vm._v("其他分享方式")]), _c("a-input", {
    staticStyle: {
      width: "302px",
      "margin-right": "8px"
    },
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.qrCodeUrl,
      callback: function callback($$v) {
        _vm.qrCodeUrl = $$v;
      },
      expression: "qrCodeUrl"
    }
  }), _c("a-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.qrCodeUrl,
      expression: "qrCodeUrl",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.onError,
      expression: "onError",
      arg: "error"
    }]
  }, [_vm._v("复制链接")])], 1)]) : _c("div", [_c("div", {
    staticClass: "ewm"
  }, [_c("img", {
    attrs: {
      src: _vm.qrCodeUrl + "?token=" + _vm.token
    }
  }), _c("div", [_c("div", {
    staticClass: "tit"
  }, [_vm._v("通过小程序码分享到微信")]), _c("a-button", {
    on: {
      click: _vm.saveImg
    }
  }, [_vm._v("下载二维码")])], 1)]), _c("div", {
    staticClass: "btn"
  }, [_c("a-input", {
    staticStyle: {
      width: "302px",
      "margin-right": "8px"
    },
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.qrCodeUrl,
      callback: function callback($$v) {
        _vm.qrCodeUrl = $$v;
      },
      expression: "qrCodeUrl"
    }
  }), _c("a-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.qrCodeUrl,
      expression: "qrCodeUrl",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:error",
      value: _vm.onError,
      expression: "onError",
      arg: "error"
    }]
  }, [_vm._v("复制链接")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };