import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export function basicsPages(moduleCode, data) {
  var fields = {};
  var fieldKeys = [];
  var _loop = function _loop() {
    var element = data[i];
    if (element.isOpen) {
      fieldKeys.push(element.fieldName);
      //   fieldKeys.push({ id: i, name: element.fieldName });
      fields[element.fieldName] = {
        model: element.fieldName,
        type: "",
        isNecessary: element.isNecessaryShow,
        // 筛选出默认必填的，然后用于必填
        isRequired: element.isNecessaryShow,
        // 表示模板默认展示    用于 删除
        isEdit: element.isEdit,
        // 切换选择  0是选中 1是取消
        fieldCode: element.fieldCode,
        wrapper: {
          label: element.fieldName
        },
        customize: {
          style: "width: 100%",
          // || !!(moduleCode === "contact" && element.fieldName === "phone"),
          placeholder: element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName),
          format: element.fieldType === "3" || element.fieldType === 3 ? "YYYY-MM-DD" : ""
        },
        decorator: {
          initialValue: undefined,
          rules: [{
            required: element.isNecessary,
            message: element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName)
          }]
        }
      };
      if (["手机号", "联系电话", "证明人联系电话"].includes(element.fieldName)) {
        fields[element.fieldName].decorator.rules.push({
          pattern: "^1[3456789]\\d{9}$",
          message: "手机号码格式错误"
        });
      }
      if (element.fieldName === "个人邮箱" || element.fieldName === "邮箱") {
        fields[element.fieldName].decorator.rules.push({
          pattern: "\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*",
          message: "邮箱格式错误"
        });
      }
      if (element.fieldName === "身份证") {
        // fields[element.fieldName].decorator.rules.push({
        //   pattern:
        //     "^(^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$)|(^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])((\\d{4})|\\d{3}[Xx])$)$",
        //   message: "身份证格式错误"
        // });
        fields[element.fieldCode].decorator.rules.push({
          pattern: "^((^[HMhm](\\d{8}|\\d{10})$)|(^(\\d{8}|^[a-zA-Z]{1}(\\d{7}))$)|(^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$)|(^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])((\\d{4})|\\d{3}[Xx]$)))$",
          message: "身份证格式错误"
        });
      }
      if (element.fieldType === 1 || element.fieldType === "1") {
        var options = [];
        if (element.dropDownEnumList) {
          element.dropDownEnumList.forEach(function (item, index) {
            for (var key in item) {
              if (item[key] === "true") {
                item[key] = "true";
              }
              if (item[key] === "false") {
                item[key] = "false";
              }
            }
          });
          options = element.dropDownEnumList;
        }
        if (element.dropDownArray) {
          element.dropDownArray.forEach(function (element) {
            options.push({
              value: element,
              label: element
            });
          });
        }
        fields[element.fieldName].customize.options = options;
        fields[element.fieldName].type = "select";
      }
      if (element.fieldType === 2 || element.fieldType === "2") {
        fields[element.fieldName].type = "input";
      }
      if (element.fieldType === 3 || element.fieldType === "3") {
        fields[element.fieldName].type = "date";
      }
      if (element.fieldType === 4 || element.fieldType === "4") {
        fields[element.fieldName].type = "textarea";
        fields[element.fieldName].customize.autoSize = {
          minRows: 5,
          maxRows: 10
        };
      }
      if (element.fieldType === 5 || element.fieldType === "5") {
        fields[element.fieldName].type = "number";
      }
      if (element.fieldType === 6 || element.fieldType === "6") {
        fields[element.fieldName].type = "area";
      }
      if (element.fieldType === 7 || element.fieldType === "7") {
        fields[element.fieldName].type = "upload";
        fields[element.fieldName].customize = {
          accept: ".jpg, .jpeg, .png",
          listType: "picture-card",
          multiple: false
        };
      }
    }
  };
  for (var i = 0; i < data.length; i++) {
    _loop();
  }
  return {
    fields: fields,
    fieldKeys: fieldKeys
  };
}