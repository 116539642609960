import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      width: "100%",
      "min-width": "1200px",
      "min-height": "100%",
      background: "rgba(244, 246, 250, 1)"
    }
  }, [_c("Navbar", {
    ref: "child",
    attrs: {
      sourceType: 1
    }
  }), _c("div", {
    staticStyle: {
      width: "1200px",
      margin: "0 auto"
    }
  }, [_c("div", {
    staticClass: "mt24",
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "box mr24",
    staticStyle: {
      width: "762px",
      height: "188px",
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      width: "174px",
      "border-right": "1px dashed rgba(225, 225, 225, 1)"
    }
  }, [_c("img", {
    staticStyle: {
      width: "66px",
      height: "66px",
      "flex-shrink": "0",
      "border-radius": "50%",
      overflow: "hidden"
    },
    attrs: {
      src: _vm.avatar
    }
  }), _vm.roleName ? _c("div", {
    staticClass: "ta-center mt16 role"
  }, [_vm._v(_vm._s(_vm.roleName))]) : _c("div", {
    staticStyle: {
      width: "120px",
      height: "20px"
    }
  }), _c("div", {
    staticClass: "fs18 fw500 ta-center mt16",
    staticStyle: {
      color: "rgba(60, 61, 67, 1)"
    }
  }, [_vm._v("你好，" + _vm._s(_vm.name))])]), _c("div", {
    staticClass: "pl24"
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("img", {
    staticStyle: {
      width: "50px",
      height: "50px"
    },
    attrs: {
      src: require("../../assets/indexImg/yonggongdanwei@2x.png"),
      alt: ""
    }
  }), _vm.operationMenus.length > 0 ? _c("div", {
    staticClass: "ml16"
  }, [_c("div", {
    staticClass: "fs18 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    },
    on: {
      click: function click($event) {
        return _vm.selectCompany(_vm.firstCompany);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.firstCompany ? _vm.firstCompany.name : "暂无用工单位") + " ")]), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      color: "var(--primary)"
    }
  }, [_c("SelectCompanyGroup", {
    ref: "selectVal",
    staticClass: "mr8 swap",
    attrs: {
      showSelect: "切换公司"
    },
    on: {
      selectCompany: function selectCompany() {
        return _vm.goEntry();
      }
    }
  }), _c("a-icon", {
    attrs: {
      type: "swap"
    },
    on: {
      click: _vm.handleClickChange
    }
  })], 1)]) : _vm._e()]), _c("div", {
    staticClass: "mt24",
    staticStyle: {
      display: "flex",
      width: "518px"
    }
  }, [_c("div", {
    staticStyle: {
      flex: "1"
    }
  }, [_c("div", {
    staticClass: "fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.empInfo.countOnJob || 0))]), _c("div", {
    staticClass: "fs14",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("在职员工数")])]), _c("div", {
    staticStyle: {
      flex: "1"
    }
  }, [_c("div", {
    staticClass: "fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.empInfo.countPre || 0))]), _c("div", {
    staticClass: "fs14",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("待入职员工")])]), _c("div", {
    staticStyle: {
      flex: "1"
    }
  }, [_c("div", {
    staticClass: "fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.empInfo.countLeaveHas || 0))]), _c("div", {
    staticClass: "fs14",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("已离职员工")])])])])]), _c("div", {
    staticClass: "box banner",
    staticStyle: {
      width: "414px",
      height: "188px"
    }
  })]), _c("div", {
    staticClass: "box mt24"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("快捷入口")]), _c("div", {
    staticClass: "mt24"
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, _vm._l(_vm.shortcuts, function (li) {
    return _c("div", {
      key: li.title,
      staticClass: "pv12",
      staticStyle: {
        flex: "1",
        display: "flex",
        "flex-direction": "column",
        "align-items": "center",
        height: "118px",
        cursor: "pointer",
        "border-radius": "8px 8px 0 0"
      },
      style: {
        background: _vm.currentItem.title === li.title ? "rgba(248, 248, 255, 1)" : "#fff"
      },
      on: {
        click: function click($event) {
          _vm.currentItem = li;
        }
      }
    }, [_c("img", {
      staticStyle: {
        width: "48px",
        height: "48px"
      },
      attrs: {
        src: li.icon,
        alt: ""
      }
    }), _c("div", {
      staticClass: "mt8",
      style: {
        color: _vm.currentItem.title === li.title ? "var(--primary)" : "rgba(85, 86, 93, 1)"
      }
    }, [_vm._v(" " + _vm._s(li.title) + " ")]), _vm.currentItem.title === li.title ? _c("div", {
      staticClass: "mt12",
      staticStyle: {
        width: "44px",
        height: "4px",
        "border-radius": "2px",
        background: "var(--primary)"
      }
    }) : _vm._e()]);
  }), 0), _c("div", [_vm._l(_vm.shortcuts, function (li) {
    return [_vm.currentItem.title === li.title ? _c("div", {
      key: li.title,
      staticClass: "p24",
      staticStyle: {
        display: "flex",
        "border-radius": "0 0 8px 8px",
        background: "rgba(248, 248, 255, 1)"
      }
    }, [_vm._l(li.items, function (subLi, subLiIdx) {
      return [_vm.showSubItem(subLi) ? _c("div", {
        key: subLi.title,
        staticClass: "ph24 pt24 pb12 bgc-white moduleItem",
        style: {
          "margin-left": subLiIdx === 0 ? "0" : "24px"
        }
      }, [subLi.items.length > 0 ? [_c("div", {
        staticStyle: {
          display: "flex"
        }
      }, [_c("img", {
        staticClass: "mr16",
        staticStyle: {
          display: "block",
          width: "50px",
          height: "50px"
        },
        attrs: {
          src: subLi.icon,
          alt: ""
        }
      }), _c("div", {
        staticClass: "pv6"
      }, [_c("div", {
        staticClass: "fs16 fw500",
        staticStyle: {
          height: "16px",
          "line-height": "16px",
          color: "rgba(85, 86, 93, 1)"
        }
      }, [_vm._v(" " + _vm._s(subLi.title) + " ")]), _c("div", {
        staticClass: "mt8",
        staticStyle: {
          height: "14px",
          "line-height": "14px",
          color: "rgba(166, 168, 180, 1)"
        }
      }, [_vm._v(_vm._s(subLi.desc))])])]), _c("div", {
        staticClass: "mt16"
      }, _vm._l(subLi.items, function (btn, btnIdx) {
        return _c("a-button", {
          key: btn.title,
          style: {
            "margin-right": btnIdx % 2 === 0 ? "24px" : "0px",
            width: "138px",
            "margin-bottom": "12px"
          },
          on: {
            click: function click($event) {
              return _vm.onBtnClick(btn);
            }
          }
        }, [_vm._v(_vm._s(btn.title))]);
      }), 1)] : _c("div", {
        staticClass: "pb12",
        staticStyle: {
          display: "flex",
          cursor: "pointer"
        },
        on: {
          click: function click($event) {
            return _vm.onBtnClick(subLi);
          }
        }
      }, [_c("img", {
        staticClass: "mr16",
        staticStyle: {
          display: "block",
          width: "50px",
          height: "50px"
        },
        attrs: {
          src: subLi.icon,
          alt: ""
        }
      }), _c("div", {
        staticClass: "pv6"
      }, [_c("div", {
        staticClass: "fs16 fw500",
        staticStyle: {
          height: "16px",
          "line-height": "16px",
          color: "rgba(85, 86, 93, 1)"
        }
      }, [_vm._v(_vm._s(subLi.title))]), _c("div", {
        staticClass: "mt8",
        staticStyle: {
          height: "14px",
          "line-height": "14px",
          color: "rgba(166, 168, 180, 1)"
        }
      }, [_vm._v(_vm._s(subLi.desc))])])])], 2) : _vm._e()];
    })], 2) : _vm._e()];
  })], 2)])]), _c("div", {
    staticClass: "mt24 pb24",
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "box mr24",
    staticStyle: {
      width: "669px"
    }
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "mr4"
  }, [_vm._v("消息通知")]), _c("a-tooltip", {
    attrs: {
      title: "仅展示您管理范围内的数据"
    }
  }, [_c("a-icon", {
    staticStyle: {
      color: "rgba(198, 198, 198, 1)",
      "font-size": "14px",
      "padding-top": "2px"
    },
    attrs: {
      type: "question-circle",
      theme: "filled"
    }
  })], 1)], 1), _vm.noticeSource ? _c("div", {
    staticClass: "mt14"
  }, [_c("NoticeList", {
    attrs: {
      source: _vm.noticeSource,
      companyIds: _vm.companyIds
    }
  })], 1) : _c("a-empty", {
    staticClass: "pv24"
  })], 1), _c("div", {
    staticClass: "box",
    staticStyle: {
      width: "505px"
    },
    style: {
      height: _vm.showZhaopin ? "200px" : "268px"
    }
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "mr4"
  }, [_vm._v("今日招聘")]), _c("a-tooltip", {
    attrs: {
      title: "仅展示您管理范围内的数据"
    }
  }, [_c("a-icon", {
    staticStyle: {
      color: "rgba(198, 198, 198, 1)",
      "font-size": "14px",
      "padding-top": "2px"
    },
    attrs: {
      type: "question-circle",
      theme: "filled"
    }
  })], 1)], 1), _vm.showZhaopin ? _c("div", {
    staticClass: "mt24 pb24",
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "space-around",
      "border-bottom": ""
    },
    style: {
      "border-bottom": _vm.perStrs.includes("recruitment:people:query") ? "1px solid #e8e8e8" : "none"
    }
  }, [_vm.perStrs.includes("recruitment:management:query") ? _c("div", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.jumpToPage("/recruitment/management");
      }
    }
  }, [_c("div", {
    staticClass: "fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.zhaopinInfo.recruitInfoCount))]), _c("div", {
    staticClass: "fs14",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("在招职位")])]) : _vm._e(), _vm.perStrs.includes("recruitment:talent:query") ? _c("div", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.jumpToPage("/recruitment/talent");
      }
    }
  }, [_c("div", {
    staticClass: "fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.zhaopinInfo.talentCount))]), _c("div", {
    staticClass: "fs14",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("人才库人员")])]) : _vm._e()]) : _c("a-empty", {
    staticClass: "pv24",
    attrs: {
      image: _vm.emptyImg
    }
  }, [_vm.perStrs.includes("recruitment:people:query") ? _c("div", {
    staticClass: "ta-center",
    staticStyle: {
      color: "var(--primary)",
      height: "48px",
      "line-height": "48px",
      cursor: "pointer"
    },
    attrs: {
      slot: "description"
    },
    on: {
      click: function click($event) {
        return _vm.jumpToPage("/recruitment/people");
      }
    },
    slot: "description"
  }, [_vm._v(" 查看投递记录 "), _c("a-icon", {
    staticClass: "ml4",
    attrs: {
      type: "right"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "ta-center",
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_vm._v("暂无权限")])]), _vm.showZhaopin && _vm.perStrs.includes("recruitment:people:query") ? _c("div", {
    staticClass: "ta-center",
    staticStyle: {
      color: "var(--primary)",
      height: "48px",
      "line-height": "48px",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.jumpToPage("/recruitment/people");
      }
    }
  }, [_vm._v(" 查看投递记录 "), _c("a-icon", {
    staticClass: "ml4",
    attrs: {
      type: "right"
    }
  })], 1) : _vm._e()], 1)])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };