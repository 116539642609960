import request from "@/utils/request";
export function getPageSubmit(data) {
  return request({
    url: "/tax/api/v1/employeeRecords/pageSubmit",
    method: "POST",
    data: data
  });
}
export function postStopDeclarationSubmit(data) {
  return request({
    url: "/tax/api/v1/employeeRecords/stopDeclarationSubmit",
    method: "POST",
    data: data
  });
}
export function postSubmitFeedBack(data) {
  return request({
    url: "/tax/api/v1/employeeRecords/submitFeedBack",
    method: "POST",
    data: data
  });
}
export function getActiveByType(params) {
  return request({
    url: "/tax/api/v1/requests/getActiveByType",
    method: "GET",
    params: params
  });
}