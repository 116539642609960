import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from "vuex";
export default {
  data: function data() {
    return {
      list: [{
        type: "1",
        name: "待处理",
        num: 74
      }, {
        type: "2",
        name: "已投递",
        num: 74
      }, {
        type: "3",
        name: "已约面",
        num: 74
      }]
    };
  },
  computed: _objectSpread({}, mapState(["admin", "hr"])),
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    handleChange: function handleChange() {}
  }
};