var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-drawer", {
    attrs: {
      title: "编辑调薪提醒",
      placement: "right",
      closable: false,
      destroyOnClose: "",
      visible: _vm.visible,
      width: 540
    },
    on: {
      close: _vm.onClose
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("div", {
    staticClass: "form_item_box"
  }, [_c("a-form-item", {
    attrs: {
      label: "提醒名称",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["msg", {
        rules: [{
          required: true,
          message: "请填写提醒名称"
        }]
      }],
      expression: "[\n              'msg',\n              {\n                rules: [{ required: true, message: '请填写提醒名称' }]\n              }\n            ]"
    }]
  })], 1), _c("a-form-item", {
    attrs: {
      label: "提醒时间",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      border: "0"
    },
    attrs: {
      disabled: "",
      defaultValue: "完成调薪工作,立即出发提醒"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "适用范围",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-tag", {
    attrs: {
      color: "#6A66F6"
    }
  }, [_vm._v("orange")]), _c("a-tag", {
    attrs: {
      color: "#6A66F6"
    }
  }, [_vm._v("orange")]), _c("a-button", {
    attrs: {
      icon: "plus"
    },
    on: {
      click: _vm.showChildrenDrawer
    }
  }, [_vm._v(" 添加适用部门或员工 ")])], 1)], 1), _c("div", {
    staticClass: "form_item_box"
  }, [_c("a-form-item", {
    attrs: {
      label: "提醒方式",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-checkbox-group", {
    on: {
      change: _vm.onChangeCheckboxGroup
    }
  }, [_c("a-row", [_c("a-checkbox", {
    attrs: {
      value: "A"
    }
  }, [_vm._v("短信")]), _c("a-checkbox", {
    attrs: {
      value: "C"
    }
  }, [_vm._v("邮件")])], 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "通知人",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-tag", {
    attrs: {
      color: "#6A66F6"
    }
  }, [_vm._v("orange")]), _c("a-tag", {
    attrs: {
      color: "#6A66F6"
    }
  }, [_vm._v("orange")]), _c("a-button", {
    attrs: {
      icon: "plus"
    },
    on: {
      click: _vm.showChildrenDrawer
    }
  }, [_vm._v(" 添加通知人 ")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "模板类型",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-radio-group", {
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c("a-radio-button", {
    attrs: {
      value: "a"
    }
  }, [_vm._v("员工本人邮件模板")]), _c("a-radio-button", {
    attrs: {
      value: "b"
    }
  }, [_vm._v("管理类者邮件模板")]), _c("a-radio-button", {
    attrs: {
      value: "c"
    }
  }, [_vm._v("短信模板")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "邮件主题",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["msg2", {
        rules: [{
          required: true,
          message: "请填写提醒主题"
        }]
      }],
      expression: "[\n            'msg2',\n            {\n              rules: [{ required: true, message: '请填写提醒主题' }]\n            }\n          ]"
    }]
  })], 1)], 1), _c("a-modal", {
    attrs: {
      title: "添加部门或员工",
      visible: _vm.childrenDrawer,
      footer: null
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleOk
    },
    model: {
      value: _vm.childrenDrawer,
      callback: function callback($$v) {
        _vm.childrenDrawer = $$v;
      },
      expression: "childrenDrawer"
    }
  }, [_c("p", [_vm._v("aaa")])]), _c("footer", {
    staticClass: "footer"
  }, [_c("a-button", {
    staticClass: "btn_bag",
    on: {
      click: _vm.addSave
    }
  }, [_vm._v("保存")]), _c("a-button", {
    on: {
      click: _vm.onClose
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };