var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "安排面试",
      "confirm-loading": _vm.confirmLoading,
      okText: "下一步"
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "面试方式"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["interviewType", {
        initialValue: "1"
      }, {
        rules: [{
          required: true
        }]
      }],
      expression: "['interviewType', { initialValue: '1' }, { rules: [{ required: true }] }]"
    }]
  }, [_c("a-radio", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 现场面试 ")]), _c("a-radio", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 电话面试 ")])], 1)], 1), _c("a-form-item", {
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      label: "面试时间"
    }
  }, [_c("a-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["sss", {
        rules: [{
          required: true
        }]
      }],
      expression: "['sss', { rules: [{ required: true }] }]"
    }],
    attrs: {
      placeholder: "请输入"
    }
  }), _c("a-form-item", {
    staticStyle: {
      float: "left",
      width: "calc(50% - 12px)"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["date", {
        rules: [{
          required: true,
          message: "日期不能为空"
        }]
      }],
      expression: "['date', { rules: [{ required: true, message: '日期不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入",
      format: "YYYY-MM-DD"
    }
  })], 1), _c("span", {
    style: {
      float: "left",
      display: "inline-block",
      width: "24px",
      textAlign: "center"
    }
  }, [_vm._v(" - ")]), _c("a-form-item", {
    staticStyle: {
      float: "left",
      width: "calc(50% - 12px)"
    }
  }, [_c("a-time-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["time", {
        rules: [{
          required: true,
          message: "时间不能为空"
        }]
      }],
      expression: "['time', { rules: [{ required: true, message: '时间不能为空' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      format: "HH:mm",
      placeholder: "请输入"
    }
  })], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "面试人"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["interviewPersonName", {
        rules: [{
          required: true,
          message: "面试人不能为空"
        }]
      }],
      expression: "['interviewPersonName', { rules: [{ required: true, message: '面试人不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "电话"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["phone", {
        rules: [{
          required: true,
          message: ""
        }, {
          validator: _vm.validatorPhone
        }]
      }],
      expression: "['phone', { rules: [{ required: true, message: '' }, { validator: validatorPhone }] }]"
    }],
    attrs: {
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "面试地点"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["interviewLocation", {
        rules: [{
          required: true,
          message: "面试地点不能为空"
        }]
      }],
      expression: "['interviewLocation', { rules: [{ required: true, message: '面试地点不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "备注"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["remarks"],
      expression: "['remarks']"
    }],
    attrs: {
      maxLength: 100,
      placeholder: "请输入",
      "auto-size": {
        minRows: 8,
        maxRows: 10
      }
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };