import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.function.name.js";
import "@/api/wages/template";
var columns = [{
  title: "姓名",
  dataIndex: "name",
  key: "name"
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone"
}, {
  title: "薪资组",
  dataIndex: "salaryGroupName",
  key: "salaryGroupName"
}];
var empColumns = [{
  title: "姓名",
  dataIndex: "empName",
  key: "empName"
}, {
  title: "薪资组",
  dataIndex: "salaryGroupName",
  key: "salaryGroupName"
}, {
  title: "月工资表名称",
  dataIndex: "salaryTemplateName",
  key: "salaryTemplateName"
}];
import { monthlySalary, getMonthlySalary, postMonthlySalary, deleteTemplateSalary, getEmpList, getCreated, deleteEmploye, getnotDetailsList, selectSalaryGroup, getLastOne, getGroup, filedLastMonth, getIsCreated, getSelectById as _getSelectById } from "@/api/wages/monthly";
import { getTemplates } from "@/api/wages/template";
import moment from "moment";
export default {
  data: function data() {
    return {
      nextLoading: false,
      creationTimeYear: null,
      creationTimeMonth: null,
      isBack: "false",
      groupId: null,
      groupList: [],
      loading: true,
      archiveImg: require("@/assets/img/wages/monthly/img1.png"),
      shenheImg: require("@/assets/img/wages/monthly/shenhe.png"),
      cost: require("@/assets/img/wages/monthly/cost.png"),
      IncomeTax: require("@/assets/img/wages/monthly/IncomeTax.png"),
      realHair: require("@/assets/img/wages/monthly/realHair.png"),
      should: require("@/assets/img/wages/monthly/should.png"),
      goDetail: require("@/assets/img/wages/monthly/goDetail.png"),
      decoration: require("@/assets/img/wages/monthly/decoration.png"),
      marshalling: require("@/assets/img/wages/monthly/marshalling.png"),
      defaultDate: null,
      selectedRowKeys: [],
      nameOrPhoneNumber: null,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true
      },
      personalPeriod: null,
      notWagesEmp: [],
      entityCount: 0,
      allTemplatesId: [],
      empState: null,
      empColumns: empColumns,
      allId: [],
      allTemplateId: [],
      salaryTemplateList: [],
      summaryList: [],
      name: "",
      templateId: null,
      createMonth: null,
      createYear: null,
      templatesList: [],
      now: moment().format("YYYY-MM"),
      monthly: null,
      yearly: null,
      columns: columns,
      form: {},
      secondVisible: false,
      nextVisible: false,
      visible: false,
      size: "default",
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      createdList: [],
      wageList: [],
      salaryTemplateInformationList: [],
      empIdList: [],
      selectMonth: null,
      nameOrPhone: null,
      nextState: true,
      itemIsArchive: false,
      isThere: true,
      isLoading: false,
      approStatus: false
    };
  },
  created: function created() {
    this.isBack = this.$route.query.isBack ? this.$route.query.isBack : "false";
    this.getCreationTime();
    this.getTemplatesList();
    this.getLastOneDate();
    // 查询审批状态
  },

  methods: {
    getSelectById: function getSelectById(e) {
      var _this2 = this;
      _getSelectById({
        monthSalaryId: e
      }).then(function (res) {
        if (res.data) {
          _this2.approStatus = true;
        } else {
          _this2.approStatus = false;
        }
      });
    },
    handleGroupChange: function handleGroupChange(e, a) {
      this.groupId = e;
      this.handleSecondOk();
    },
    // 获取到创建的时间
    getCreationTime: function getCreationTime() {
      var _this3 = this;
      getLastOne({
        companyId: this.companyId
      }).then(function (res) {
        _this3.creationTimeMonth = res.data.monthly ? res.data.monthly : parseInt(moment(_this3.now).subtract(1, "months").format("MM"));
        _this3.creationTimeYear = res.data.yearly ? res.data.yearly : parseInt(moment(_this3.now).subtract(1, "months").format("YYYY"));
      });
    },
    // 对于初始时间的处理
    getLastOneDate: function getLastOneDate() {
      var _this4 = this;
      if (this.isBack === "true") {
        this.monthly = this.$route.query.monthly ? this.$route.query.monthly : null;
        this.yearly = this.$route.query.yearly ? this.$route.query.yearly : null;
        if (this.yearly === null && this.monthly === null) {
          getLastOne({
            companyId: this.companyId
          }).then(function (res) {
            _this4.monthly = res.data.monthly;
            _this4.yearly = res.data.yearly;
            if (res.data.yearly) {
              _this4.defaultDate = moment(res.data.yearly + "-" + res.data.monthly, "YYYY-MM");
            } else {
              _this4.defaultDate = moment(_this4.now, "YYYY-MM");
            }
            _this4.getMonthlySalaryPage();
            _this4.getmonthlySalaryByCompanyId();
          });
        }
        this.getMonthlySalaryPage();
        this.getmonthlySalaryByCompanyId();
        this.defaultDate = moment(this.yearly + "-" + this.monthly, "YYYY-MM");
      } else {
        getLastOne({
          companyId: this.companyId
        }).then(function (res) {
          _this4.monthly = res.data.monthly;
          _this4.yearly = res.data.yearly;
          if (res.data.yearly) {
            _this4.defaultDate = moment(res.data.yearly + "-" + res.data.monthly, "YYYY-MM");
          } else {
            _this4.defaultDate = moment(_this4.now, "YYYY-MM");
          }
          _this4.getMonthlySalaryPage();
          _this4.getmonthlySalaryByCompanyId();
        });
      }
    },
    // 员工批量更换模板（table选择）
    onSelectChange: function onSelectChange(e, a) {
      var _this5 = this;
      this.empIdList = [];
      this.selectedRowKeys = e;
      a.forEach(function (item) {
        _this5.empIdList.push(item.employeeSalaryId);
      });
    },
    handleNameChange: function handleNameChange(e) {
      var _this6 = this;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.nameOrPhone = e;
      getEmpList({
        companyId: this.companyId,
        monthly: this.createMonth > 12 ? this.createMonth - 12 : this.createMonth,
        yearly: this.createMonth > 12 ? parseInt(this.creationTimeYear) + 1 : parseInt(this.creationTimeYear),
        salaryGroupId: this.groupId,
        // 加salaryGroupId（薪资组筛选）
        nameOrPhoneNumber: this.nameOrPhone,
        salaryTemplateIds: this.templateId === 99999 ? this.allTemplatesId : this.templateId,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }).then(function (res) {
        _this6.pagination.total = res.data.entityCount; // 共多少条
        _this6.pagination.pageSize = res.data.pageSize; // 每页多少条
        _this6.pagination.current = res.data.pageNo; // 当前第几页
        _this6.wageList = res.data.entities;
        _this6.entityCount = res.data.entityCount;
      });
    },
    // 分页
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      var _this7 = this;
      // this.notWagesEmp = [];
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      getEmpList({
        companyId: this.companyId,
        monthly: this.createMonth > 12 ? this.createMonth - 12 : this.createMonth,
        yearly: this.createMonth > 12 ? parseInt(this.creationTimeYear) + 1 : parseInt(this.creationTimeYear),
        salaryGroupId: this.groupId,
        nameOrPhoneNumber: this.nameOrPhone,
        salaryTemplateIds: this.templateId === 99999 ? this.allTemplatesId : this.templateId,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }).then(function (res) {
        _this7.pagination.total = res.data.entityCount; // 共多少条
        _this7.pagination.pageSize = res.data.pageSize; // 每页多少条
        _this7.pagination.current = res.data.pageNo; // 当前第几页
        _this7.wageList = res.data.entities;
        _this7.entityCount = res.data.entityCount;
      });
    },
    // 检测到以下员工本月已创建月工资表的下一步
    handleSecondOk: function handleSecondOk() {
      var _this8 = this;
      this.notWagesEmp = [];
      this.wageList = [];
      this.nextVisible = true;
      this.nextLoading = true;
      getGroup({
        salaryTemplateIds: this.templateId === 99999 ? this.allTemplatesId : this.templateId
      }).then(function (res) {
        _this8.groupList = res.data;
      });
      getnotDetailsList({
        companyId: this.companyId,
        monthly: this.createMonth > 12 ? this.createMonth - 12 : this.createMonth,
        yearly: this.createMonth > 12 ? parseInt(this.creationTimeYear) + 1 : parseInt(this.creationTimeYear),
        salaryTemplateIds: this.templateId === 99999 ? this.allTemplatesId : this.templateId
      }).then(function (res) {
        _this8.notWagesEmp = res.data;
        getEmpList({
          companyId: _this8.companyId,
          monthly: _this8.createMonth > 12 ? _this8.createMonth - 12 : _this8.createMonth,
          yearly: _this8.createMonth > 12 ? parseInt(_this8.creationTimeYear) + 1 : parseInt(_this8.creationTimeYear),
          salaryGroupId: _this8.groupId,
          salaryTemplateIds: _this8.templateId === 99999 ? _this8.allTemplatesId : _this8.templateId,
          pageNo: 1,
          pageSize: 10
        }).then(function (res) {
          _this8.nextLoading = false;
          _this8.pagination.total = res.data.entityCount; // 共多少条
          _this8.pagination.pageSize = res.data.pageSize; // 每页多少条
          _this8.pagination.current = res.data.pageNo; // 当前第几页
          _this8.wageList = res.data.entities;
          _this8.entityCount = res.data.entityCount;
        }).catch(function (err) {
          _this8.nextLoading = false;
          console.log("debug log --> ", err);
        });
      });
    },
    // 员工更换工资表
    replace: function replace() {
      var _this9 = this;
      deleteEmploye(this.empIdList).then(function (res) {
        _this9.$notification["success"]({
          message: "success",
          description: "已更换"
        });
        _this9.handleOk();
        _this9.selectedRowKeys = [];
      });
    },
    // 删除模板
    deleteTemplate: function deleteTemplate(item) {
      var _this10 = this;
      deleteTemplateSalary(item.id).then(function (res) {
        _this10.$notification["success"]({
          message: "success",
          description: "删除成功"
        });
        getLastOne({
          companyId: _this10.companyId
        }).then(function (res) {
          _this10.monthly = res.data.monthly;
          _this10.yearly = res.data.yearly;
          _this10.creationTimeMonth = res.data.monthly ? res.data.monthly : parseInt(moment(_this10.now).subtract(1, "months").format("MM"));
          _this10.creationTimeYear = res.data.yearly ? res.data.yearly : parseInt(moment(_this10.now).subtract(1, "months").format("YYYY"));
          if (res.data.yearly) {
            _this10.defaultDate = moment(res.data.yearly + "-" + res.data.monthly, "YYYY-MM");
          } else {
            _this10.defaultDate = moment(_this10.now, "YYYY-MM");
          }
          _this10.getMonthlySalaryPage();
          _this10.getmonthlySalaryByCompanyId();
        });
        // this.getmonthlySalaryByCompanyId();
        // this.getMonthlySalaryPage();
        //      this.getCreationTime();
        // this.getTemplatesList();
        // this.getLastOneDate();
      });
    },
    // 查看详情
    showAllDetails: function showAllDetails() {
      this.$router.push({
        path: "/wages/monthly/details",
        query: {
          salaryHeaderId: this.summaryList.id,
          salaryTemplateId: this.allTemplateId.join(","),
          templateId: this.allId.join(","),
          // personalPeriod: this.personalPeriod,
          isAll: true,
          title: this.yearly + "年" + this.monthly + "月工资汇总表",
          monthly: this.monthly,
          yearly: this.yearly,
          approStatus: this.approStatus
        }
      });
    },
    // 获取公司下的工资表模板
    getTemplatesList: function getTemplatesList() {
      var _this11 = this;
      getTemplates({
        companyId: this.companyId
      }).then(function (res) {
        _this11.templatesList = res.data;
        res.data.forEach(function (item, index) {
          _this11.allTemplatesId.push(item.id);
        });
      });
    },
    // 日期选择器变化
    monthChange: function monthChange(e) {
      this.monthly = parseInt(moment(e).format("MM"));
      this.yearly = parseInt(moment(e).format("YYYY"));
      this.getmonthlySalaryByCompanyId();
      this.getMonthlySalaryPage();
    },
    // 查询公司某月份的月工资汇总
    getmonthlySalaryByCompanyId: function getmonthlySalaryByCompanyId() {
      var _this12 = this;
      monthlySalary({
        companyId: this.companyId,
        monthly: this.monthly,
        yearly: this.yearly
      }).then(function (res) {
        if (res.data === null) {
          _this12.summaryList = null;
        } else {
          _this12.getSelectById(res.data.id);
          _this12.summaryList = res.data;
          // this.summaryList.name = res.data.personalPeriod + "工资汇总表";
          _this12.personalPeriod = res.data.personalPeriod ? res.data.personalPeriod : "";
        }
      });
    },
    // 获取公司下的工资表模板月工资
    getMonthlySalaryPage: function getMonthlySalaryPage() {
      var _this13 = this;
      this.isThere = true;
      this.itemIsArchive = false;
      this.allTemplateId = [];
      this.loading = true;
      getMonthlySalary({
        companyId: this.companyId,
        monthly: this.monthly,
        yearly: this.yearly,
        pageNo: 1,
        pageSize: 100
      }).then(function (res) {
        _this13.loading = false;
        if (res.data.entities === null) {
          _this13.isThere = false;
          _this13.salaryTemplateList = [];
        } else {
          if (!res.data.entities.length) {
            _this13.isThere = false;
          }
          _this13.salaryTemplateList = res.data.entities ? res.data.entities : [];
          _this13.salaryTemplateList.forEach(function (item) {
            _this13.allTemplateId.push(item.id);
            _this13.allId.push(item.salaryTemplateId);
            if (item.isArchive === false) {
              _this13.itemIsArchive = false;
            }
          });
        }
      }).catch(function (error) {
        console.log("Error", error);
        _this13.loading = false;
      });
    },
    // 创建工资表选择模板变化
    handleChange: function handleChange(e, a) {
      var _this14 = this;
      this.salaryTemplateInformationList = [];
      e.forEach(function (item, index) {
        _this14.salaryTemplateInformationList.push({
          salaryTemplateId: item,
          name: a[index].componentOptions.children[0].text.trim(),
          salaryTemplateName: a[index].componentOptions.children[0].text.trim()
        });
      });
      this.templateId = e.join(",");
    },
    // 创建工资表选择月份变化
    handleMonthChange: function handleMonthChange(e) {
      if (e === "1") {
        this.createMonth = parseInt(this.creationTimeMonth);
        this.createYear = parseInt(this.creationTimeYear);
        this.selectMonth = 0;
      }
      if (e === "2") {
        this.createMonth = parseInt(this.creationTimeMonth) + 1;
        this.createYear = parseInt(this.createMonth > 12 ? this.creationTimeYear + 1 : this.creationTimeYear);
        this.selectMonth = 1;
      }
    },
    // 创建工资表点击创建
    handleNextOk: function handleNextOk() {
      var _this15 = this;
      this.isLoading = true;
      this.secondVisible = false;
      this.nextVisible = false;
      this.visible = false;
      var data = {
        isArchive: false,
        companyId: this.companyId,
        monthly: this.createMonth > 12 ? this.createMonth - 12 : this.createMonth,
        yearly: this.createMonth > 12 ? parseInt(this.creationTimeYear) + 1 : parseInt(this.creationTimeYear),
        salaryTemplateInformationList: this.salaryTemplateInformationList,
        selectMonth: this.selectMonth
      };
      postMonthlySalary(data).then(function (res) {
        _this15.$notification["success"]({
          message: "success",
          description: "创建成功"
        });
        _this15.isLoading = false;
        _this15.isBack = false;
        _this15.getCreationTime();
        _this15.getLastOneDate();
      }).catch(function (err) {
        _this15.isLoading = false;
        console.log("debug log --> ", err);
      });
    },
    // 员工是否创建月工资(创建工资表下一步)
    handleOk: function handleOk() {
      var _this16 = this;
      var year = parseInt(moment().format("MM")) === 12 ? parseInt(moment().format("YYYY")) + 1 : parseInt(moment().format("YYYY"));
      var month = parseInt(moment().format("MM")) === 12 ? 1 : parseInt(moment().format("MM")) + 1;
      var time = moment("".concat(this.createYear) + "-" + (this.createMonth < 10 ? "0" + "".concat(this.createMonth) : this.createMonth > 12 ? "01" : "".concat(this.createMonth)), "YYYY-MM").valueOf();
      var timeNow = moment("".concat(year) + "-" + (month < 10 ? "0" + "".concat(month) : "".concat(month)), "YYYY-MM").valueOf();
      console.log(time, timeNow);
      // if (time > timeNow) {
      //   this.$message.warning(
      //     `当前是${parseInt(moment().format("YYYY"))}年${parseInt(moment().format("MM"))}月，最多只能创建到${year}年${month}月`
      //   );
      // } else {
      var idList = [];
      var nameList = [];
      this.salaryTemplateInformationList.forEach(function (item, index) {
        idList.push(item.salaryTemplateId);
        nameList.push(item.name);
      });
      // if()
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          getIsCreated({
            // 查询选中的工资表是否已经创建
            salaryTemplateIdListString: idList.join(","),
            nameListString: nameList.join(","),
            companyId: _this16.companyId,
            monthly: _this16.createMonth > 12 ? _this16.createMonth - 12 : _this16.createMonth,
            yearly: _this16.createMonth > 12 ? parseInt(_this16.creationTimeYear) + 1 : parseInt(_this16.creationTimeYear)
          }).then(function (res) {
            if (res.data.length > 0) {
              var notList = [];
              res.data.forEach(function (item, index) {
                notList.push(item.name);
              });
              _this16.$notification["error"]({
                message: "error",
                description: "\u521B\u5EFA\u5931\u8D25,".concat(notList.join(","), "\u5DE5\u8D44\u8868\u5DF2\u521B\u5EFA")
              });
            }
            if (res.data.length === 0) {
              if (_this16.selectMonth === 1 && _this16.itemIsArchive === false && _this16.isThere === true) {
                var _this = _this16;
                _this.nextState = false;
                _this.$refs.ruleForm.validate(function (valid) {
                  if (valid) {
                    _this16.$confirm({
                      title: "正在创建下月工资表，点击确定本月工资表将自动归档",
                      onOk: function onOk() {
                        filedLastMonth({
                          companyId: _this.companyId,
                          monthly: _this.createMonth > 12 ? _this.createMonth - 12 : _this.createMonth,
                          yearly: _this.createMonth > 12 ? parseInt(_this.creationTimeYear) + 1 : parseInt(_this.creationTimeYear)
                        }).then(function (res) {
                          _this.nextState = true;
                          if (_this.nextState === true) {
                            _this.wageList = [];
                            _this.$refs.ruleForm.validate(function (valid) {
                              if (valid) {
                                selectSalaryGroup().then(function (res) {});
                                getCreated({
                                  companyId: _this.companyId,
                                  monthly: _this.createMonth > 12 ? _this.createMonth - 12 : _this.createMonth,
                                  yearly: _this.createMonth > 12 ? parseInt(_this.creationTimeYear) + 1 : parseInt(_this.creationTimeYear),
                                  salaryTemplateIds: _this.templateId === 99999 ? _this.allTemplatesId : _this.templateId
                                }).then(function (res) {
                                  _this.createdList = res.data;
                                  if (_this.createdList.length) {
                                    _this.secondVisible = true;
                                  } else {
                                    _this.notWagesEmp = [];
                                    _this.nextVisible = true;
                                    _this.nextLoading = true;
                                    getGroup({
                                      salaryTemplateIds: _this.templateId === 99999 ? _this.allTemplatesId : _this.templateId
                                    }).then(function (res) {
                                      _this.groupList = res.data;
                                    });
                                    getnotDetailsList({
                                      companyId: _this.companyId,
                                      monthly: _this.createMonth > 12 ? _this.createMonth - 12 : _this.createMonth,
                                      yearly: _this.createMonth > 12 ? parseInt(_this.creationTimeYear) + 1 : parseInt(_this.creationTimeYear),
                                      salaryTemplateIds: _this.templateId === 99999 ? _this.allTemplatesId : _this.templateId
                                    }).then(function (res) {
                                      _this.notWagesEmp = res.data;
                                    });
                                    getEmpList({
                                      companyId: _this.companyId,
                                      monthly: _this.createMonth > 12 ? _this.createMonth - 12 : _this.createMonth,
                                      yearly: _this.createMonth > 12 ? parseInt(_this.creationTimeYear) + 1 : parseInt(_this.creationTimeYear),
                                      salaryGroupId: _this.groupId,
                                      salaryTemplateIds: _this.templateId === 99999 ? _this.allTemplatesId : _this.templateId,
                                      pageNo: 1,
                                      pageSize: 10
                                    }).then(function (res) {
                                      _this.nextLoading = false;
                                      _this.pagination.total = res.data.entityCount;
                                      _this.pagination.pageSize = res.data.pageSize;
                                      _this.pagination.current = res.data.pageNo;
                                      _this.wageList = res.data.entities;
                                      _this.entityCount = res.data.entityCount;
                                      res.data.entities.forEach(function (item, index) {
                                        if (item.isFixed === false) {
                                          _this.notWagesEmp.push(item.empId);
                                        }
                                      });
                                    }).catch(function (err) {
                                      _this.nextLoading = false;
                                      console.log("debug log --> ", err);
                                    });
                                  }
                                });
                              }
                            });
                          }
                        });
                      },
                      onCancel: function onCancel() {
                        _this.nextState = true;
                      }
                    });
                  }
                });
              }
              //  else if (
              //   this.selectMonth === 1 &&
              //   this.itemIsArchive === false &&
              //   this.isThere === false
              // ) {
              //   const _this = this;
              //   _this.nextState = false;
              //   this.$confirm({
              //     title:
              //       "正在创建下月工资表，本月暂无已创建的工资表，点击确定创建下月工资表后将不能创建当前月份工资表",
              //     onOk() {
              //       _this.nextState = true;
              //     }
              //   });
              // }
              if (_this16.nextState === true) {
                _this16.wageList = [];
                _this16.$refs.ruleForm.validate(function (valid) {
                  if (valid) {
                    selectSalaryGroup().then(function (res) {});
                    getCreated({
                      companyId: _this16.companyId,
                      monthly: _this16.createMonth > 12 ? _this16.createMonth - 12 : _this16.createMonth,
                      yearly: _this16.createMonth > 12 ? parseInt(_this16.creationTimeYear) + 1 : parseInt(_this16.creationTimeYear),
                      salaryTemplateIds: _this16.templateId === 99999 ? _this16.allTemplatesId : _this16.templateId
                    }).then(function (res) {
                      _this16.createdList = res.data;
                      if (_this16.createdList.length) {
                        _this16.secondVisible = true;
                      } else {
                        _this16.notWagesEmp = [];
                        _this16.nextVisible = true;
                        _this16.nextLoading = true;
                        getGroup({
                          salaryTemplateIds: _this16.templateId === 99999 ? _this16.allTemplatesId : _this16.templateId
                        }).then(function (res) {
                          _this16.groupList = res.data;
                        });
                        getnotDetailsList({
                          companyId: _this16.companyId,
                          monthly: _this16.createMonth > 12 ? _this16.createMonth - 12 : _this16.createMonth,
                          yearly: _this16.createMonth > 12 ? parseInt(_this16.creationTimeYear) + 1 : parseInt(_this16.creationTimeYear),
                          salaryTemplateIds: _this16.templateId === 99999 ? _this16.allTemplatesId : _this16.templateId
                        }).then(function (res) {
                          _this16.notWagesEmp = res.data;
                        });
                        getEmpList({
                          companyId: _this16.companyId,
                          monthly: _this16.createMonth > 12 ? _this16.createMonth - 12 : _this16.createMonth,
                          yearly: _this16.createMonth > 12 ? parseInt(_this16.creationTimeYear) + 1 : parseInt(_this16.creationTimeYear),
                          salaryGroupId: _this16.groupId,
                          salaryTemplateIds: _this16.templateId === 99999 ? _this16.allTemplatesId : _this16.templateId,
                          pageNo: 1,
                          pageSize: 10
                        }).then(function (res) {
                          _this16.nextLoading = false;
                          _this16.pagination.total = res.data.entityCount; // 共多少条
                          _this16.pagination.pageSize = res.data.pageSize; // 每页多少条
                          _this16.pagination.current = res.data.pageNo; // 当前第几页
                          _this16.wageList = res.data.entities;
                          _this16.entityCount = res.data.entityCount;
                          res.data.entities.forEach(function (item, index) {
                            if (item.isFixed === false) {
                              _this16.notWagesEmp.push(item.empId);
                            }
                          });
                        }).catch(function (err) {
                          _this16.nextLoading = false;
                          console.log("debug log --> ", err);
                        });
                      }
                    });
                  }
                });
              }
            }
          });
        }
      });
      // }
    },
    showDetails: function showDetails(item) {
      this.$router.push({
        path: "/wages/monthly/details",
        query: {
          tableName: item.name,
          isArchive: item.isArchive,
          salaryTemplateId: item.id,
          templateId: item.salaryTemplateId,
          personalPeriod: this.personalPeriod,
          monthly: this.monthly,
          yearly: this.yearly,
          approStatus: this.approStatus
        }
      });
    },
    goUpload: function goUpload() {
      this.$router.push({
        path: "/wages/monthly/uploadPayroll"
      });
    },
    showModal: function showModal() {
      var _this17 = this;
      this.visible = true;
      this.$nextTick(function () {
        _this17.$refs.ruleForm.resetFields();
        _this17.form = {};
      });
      this.templateId = null;
      this.createMonth = null;
      this.createYear = null;
      this.name = "";
    }
  }
};