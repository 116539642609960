import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
export default {
  name: "Shebao",
  components: {
    ContentItem: function ContentItem() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./common/content-item"));
      });
    }
  },
  data: function data() {
    return {
      pageSizeOptions: ["10", "20", "50", "100"],
      keyWord: "",
      modalVisible: false,
      totalOptions: {},
      contentList: [],
      socPayCostTypeMap2: [],
      socPayCostTypeMap: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showQuickJumper: true
      },
      params: {
        pageNo: 1,
        pageSize: 10
      },
      hasMonth: []
    };
  },
  computed: _objectSpread({}, mapState(["admin", "hr"])),
  created: function created() {
    if (JSON.stringify(this.$getCondition("paidan_condition")) !== "{}") {
      this.params = JSON.parse(this.$getCondition("paidan_condition"));
    }

    // 读取缓存
    if (this.$getPageSize("paidan_condition")) {
      this.pagination.pageSize = this.params.pageSize = parseInt(this.$getPageSize("paidan_condition")) === 1 ? 10 : parseInt(this.$getPageSize("paidan_condition"));
      this.params.pageNo = this.pagination.current = parseInt(this.$getPage("paidan_condition")) === 0 ? 1 : parseInt(this.$getPage("paidan_condition"));
    }
    this.getZidan();
    this.getPerson();
    this.getShebaoTotal();
    this.getShebaoList();
    this.gethasCanBaoMonth();
  },
  methods: {
    onReceived: function onReceived() {
      this.getShebaoTotal();
      this.getShebaoList();
    },
    paidan: function paidan(index) {
      this.params.missionCenterState = index;
      this.params.pageNo = 1;
      this.getShebaoTotal();
      this.getShebaoList();
      this.$setCondition("paidan_condition", JSON.stringify(this.params));
    },
    // 按缴费类型搜
    handlePayCostTypeName: function handlePayCostTypeName(val) {
      this.pagination.current = 1;
      this.params.createUserId = val;
      this.params.pageNo = 1;
      // 缓存
      this.$setCondition("paidan_condition", JSON.stringify(this.params));
      this.getShebaoTotal();
      this.getShebaoList();
    },
    handlePayCostTypeName2: function handlePayCostTypeName2(val) {
      this.params.missionMode = null;
      if (val) {
        for (var i = 0; i < this.socPayCostTypeMap2.length; i++) {
          if (this.socPayCostTypeMap2[i].id === val) {
            this.params.missionMode = this.socPayCostTypeMap2[i].dictItemValue;
          }
        }
      }
      // 缓存
      this.$setCondition("paidan_condition", JSON.stringify(this.params));
      this.params.pageNo = 1;
      this.pagination.current = 1;
      this.getShebaoTotal();
      this.getShebaoList();
    },
    onShowSizeChange: function onShowSizeChange(current, size) {
      this.params.pageNo = current;
      this.params.pageSize = size;
      this.getShebaoList();
    },
    handleTotal: function handleTotal(e) {},
    handleContent: function handleContent(e) {
      var query = {
        id: e.id,
        year: e.year,
        month: e.month,
        addOrLess: e.addOrLess,
        socDispatchId: e.id,
        actionDetail: JSON.stringify(e)
      };
      var path = "/admin/shebao-detail-less";
      this.$router.push({
        path: path,
        query: query
      });
    },
    deletItem: function deletItem(e) {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/missionCenters/" + e.id,
        method: "delete"
      }).then(function (_ref) {
        var data = _ref.data;
        if (data === 1) {
          _this.$message.success("删除成功");
        }
        _this.params.pageNo = 1;
        _this.getShebaoTotal();
        _this.getShebaoList();
      });
    },
    // 获取模式
    getZidan: function getZidan() {
      var _this2 = this;
      this.$request({
        url: "/admin/api/v1/dictItems/selectAllDictItem",
        params: {
          dictCode: "1204",
          keyWord: this.keyWord
        }
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this2.socPayCostTypeMap2 = data;
      });
    },
    getPerson: function getPerson() {
      var _this3 = this;
      this.$request({
        url: "/hr/api/v1/missionCenters/createUserList"
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this3.socPayCostTypeMap = data;
      });
    },
    // 获取有参保的月份
    gethasCanBaoMonth: function gethasCanBaoMonth() {
      var _this4 = this;
      this.$request({
        url: "/hr/api/v1/empSocMonths/listYearAndMonth/".concat(this.hr.companyId)
      }).then(function (_ref4) {
        var data = _ref4.data;
        _this4.hasMonth = data || [];
      });
    },
    timeChange: function timeChange(e) {
      this.pagination.current = 1;
      this.params.beginTime = e.length !== 0 ? e[0].valueOf() : null;
      this.params.endTime = e.length !== 0 ? e[1].valueOf() : null;
      this.params.pageNo = 1;
      this.getShebaoTotal();
      this.getShebaoList();
      // 缓存
      this.$setCondition("paidan_condition", JSON.stringify(this.params));
    },
    enterUploadPaidan: function enterUploadPaidan() {
      this.$router.push({
        path: "/admin/shebaoUpload"
      });
    },
    // 获取当月社保派单状况
    getShebaoTotal: function getShebaoTotal() {
      var _this5 = this;
      this.$request({
        url: "/hr/api/v1/missionCenters/selectTopStatistics",
        params: _objectSpread({}, this.params)
      }).then(function (_ref5) {
        var data = _ref5.data;
        _this5.totalOptions = data;
      });
    },
    // 获取社保列表/hr/api/v1/missionCenters
    getShebaoList: function getShebaoList() {
      var _this6 = this;
      this.$request({
        url: "/hr/api/v1/missionCenters",
        params: _objectSpread({}, this.params)
      }).then(function (_ref6) {
        var data = _ref6.data;
        _this6.pagination.total = data.entityCount;
        _this6.contentList = data.entities;
        _this6.pagination.current = data.pageNo;
        _this6.pagination.pageSize = data.pageSize;
        // 缓存
        _this6.$setPage("paidan_condition", _this6.pagination.current + "");
        _this6.$setPageSize("paidan_condition", _this6.pagination.pageSize + "");
      });
    },
    // 分页查询社保
    onChangePage: function onChangePage(e) {
      this.params.pageNo = e;
      this.pagination.current = e;
      // this.getShebaoTotal()
      this.getShebaoList();
    }
  }
};