import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
export default {
  name: "WagesSet",
  data: function data() {
    return {
      defaultSelectedKeys: ["计薪设置"],
      meta: {}
    };
  },
  created: function created() {
    this.defaultSelectedKeys[0] = this.$route.name;
  },
  computed: {
    menuList: function menuList() {
      var men = this.$router.options.routes.find(function (route) {
        return route.path.includes("/wages");
      });
      return men.children[5].children;
    }
  },
  methods: {
    // 跳转单页
    goList: function goList(i, meta) {
      this.$nextTick;
      this.$router.push(this.menuList[i]);
      this.meta = meta;
    }
  }
};