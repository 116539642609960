var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isSuccess ? _c("div", {
    staticStyle: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c("a-icon", {
    staticStyle: {
      color: "green",
      "font-size": "40px"
    },
    attrs: {
      type: "check-circle",
      theme: "filled"
    }
  }), _c("div", {
    staticClass: "mt24"
  }, [_vm._v("提交成功")])], 1) : _c("a-spin", {
    staticClass: "ph24 pb48",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "fs18 fw500 mb24 p12 ta-center",
    staticStyle: {
      "border-bottom": "1px solid #e8e8e8",
      "margin-left": "-24px",
      "margin-right": "-24px"
    }
  }, [_vm._v(" 供应商登记 ")]), _c("Collapse", {
    attrs: {
      title: "基本信息",
      disabled: _vm.basicFormJson.editable,
      showBtns: false
    }
  }, [_vm.basicData ? _c("DynamicForm", {
    ref: "basic",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.basicData,
      editable: _vm.basicFormJson.editable,
      dynamicData: {},
      formFields: _vm.basicFormJson.fields,
      sortedKeys: _vm.basicFormJson.sortedKeys,
      formConfig: {},
      showBtns: false,
      formLayout: "vertical"
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.basicFormJson, "editable", $event);
      }
    }
  }) : _vm._e()], 1), _c("Collapse", {
    attrs: {
      title: "联系信息",
      disabled: _vm.contactFormJson.editable,
      showBtns: false
    }
  }, [_vm.contactData ? _c("DynamicForm", {
    ref: "contact",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.contactData,
      editable: _vm.contactFormJson.editable,
      dynamicData: {},
      formFields: _vm.contactFormJson["fields"],
      sortedKeys: _vm.contactFormJson.sortedKeys,
      formConfig: {},
      showBtns: false,
      formLayout: "vertical"
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.contactFormJson, "editable", $event);
      }
    }
  }) : _vm._e()], 1), _c("Collapse", {
    attrs: {
      title: "法人信息",
      disabled: _vm.corporateFormJson.editable,
      showBtns: false
    }
  }, [_vm.corporateData ? _c("DynamicForm", {
    ref: "corporate",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.corporateData,
      editable: _vm.corporateFormJson.editable,
      dynamicData: {},
      formFields: _vm.corporateFormJson.fields,
      sortedKeys: _vm.corporateFormJson.sortedKeys,
      formConfig: {},
      showBtns: false,
      formLayout: "vertical"
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.corporateFormJson, "editable", $event);
      }
    }
  }) : _vm._e()], 1), _vm.mode === "add" ? _c("div", {
    staticClass: "bgc-white fixedBtn"
  }, [_c("a-space", [_c("a-button", {
    attrs: {
      disabled: _vm.spinning,
      type: "primary"
    },
    on: {
      click: _vm.saveActive
    }
  }, [_vm._v("提交")])], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };