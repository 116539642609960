import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";

// 事件完结
export function selectEventByEmpIdReq(empId) {
  return request({
    url: "/hr/api/v1/treatmentDisposeEvents/selectEventByEmpId/".concat(empId),
    method: "get"
  });
}

// 事件完结
export function finishOrUnfinishedEventReq(eventId, isFinish) {
  return request({
    url: "/hr/api/v1/treatmentDisposeEvents/finishOrUnfinishedEvent?eventId=".concat(eventId, "&status=").concat(isFinish),
    method: "put"
  });
}

// 事件详情
export function selectEventInfoByIdReq(eventId) {
  return request({
    url: "/hr/api/v1/treatmentDisposeEvents/selectEventInfoById/".concat(eventId),
    method: "get"
  });
}

// 模板下流程列表
export function selectProcessChildByTemplateIdReq(templateId) {
  return request({
    url: "/hr/api/v1/treatmentDisposeTemplates/selectProcessChildByTemplateId/".concat(templateId),
    method: "get"
  });
}

// 列表
export function treatmentDisposeEventsReq(params) {
  return request({
    url: "/hr/api/v1/treatmentDisposeEvents",
    method: "get",
    params: params
  });
}
export function delTreatmentDisposeEventsReq(treatmentDisposeEventId) {
  return request({
    url: "hr/api/v1/treatmentDisposeEvents/".concat(treatmentDisposeEventId),
    method: "delete"
  });
}
export function updateTreatmentDisposeEventsReq(data) {
  return request({
    url: "/hr/api/v1/treatmentDisposeEvents",
    method: "put",
    data: data
  });
}
export function createTreatmentDisposeEventsReq(data) {
  return request({
    url: "/hr/api/v1/treatmentDisposeEvents",
    method: "post",
    data: data
  });
}

// 派单客服列表
export function dispatchListReq() {
  return request({
    url: "/hr/api/v1/users/dispatchList",
    method: "get"
  });
}

// 查询租户下所有待遇处理模板
export function selectAllTemplateBySiteReq() {
  return request({
    url: "/hr/api/v1/treatmentDisposeTemplates/selectAllTemplateBySite",
    method: "get"
  });
}

// 公司下员工列表
export function selectByCompanyIdReq(companyId) {
  return request({
    url: "/hr/api/v1/empInfos/selectByCompanyId",
    method: "get",
    params: {
      companyId: companyId
    }
  });
}