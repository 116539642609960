import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
export function formatDate(stamp) {
  if (stamp) {
    var date = new Date(stamp);
    return "".concat(date.getFullYear(), "-").concat(date.getMonth() + 1, "-").concat(date.getDate());
  }
  return "--";
}
export var columns = [{
  title: "姓名",
  dataIndex: "empName",
  width: 120
}, {
  title: "身份证号",
  dataIndex: "empIdCard",
  width: 220
}, {
  title: "接单时间",
  dataIndex: "takeOrdersDate",
  width: 120,
  customRender: function customRender(text) {
    return formatDate(text);
  }
}, {
  title: "是否完结",
  dataIndex: "isFinish",
  width: 80,
  customRender: function customRender(text) {
    return text ? "是" : "否";
  }
}];
export function deFormatValuesItem(item) {
  var res = item.content;
  if (item.fieldType === 9) {
    if (item.content) {
      res = item.content.split(",").map(function (item) {
        var arr = item.split("_");
        if (arr[0] === "d") {
          return arr[1];
        } else {
          return arr[1];
        }
      }).filter(function (item) {
        return item;
      }).join("-");
    }
  } else if ([6, 12].includes(item.fieldType)) {
    if (item.content) {
      res = item.content.split(",").map(function (item) {
        var arr = item.split("_");
        return arr[1];
      }).filter(function (item) {
        return item;
      }).join("-");
    }
  } else if ([3, 8].includes(item.fieldType)) {
    if (item.content) {
      res = item.content;
    }
  }
  return res || "--";
}
export function deFormatValues(list, fieldMap) {
  var data = {};
  var _loop = function _loop() {
    var item = list[i];
    var key = "".concat(item.id, "_").concat(item.fieldCode || "");
    data[key] = item.content;
    if (fieldMap[key]) {
      if (fieldMap[key].type === "area_detail") {
        if (item.content) {
          data[key] = item.content.split(",").map(function (item) {
            var arr = item.split("_");
            if (arr[0] === "d") {
              data["".concat(key, "_detail")] = arr[1];
              return false;
            } else {
              return parseInt(arr[0]);
            }
          }).filter(function (item) {
            return item;
          });
        }
      } else if (fieldMap[key].type === "area") {
        if (item.content) {
          data[key] = item.content.split(",").map(function (item) {
            var arr = item.split("_");
            return parseInt(arr[0]);
          }).filter(function (item) {
            return item;
          });
        }
      } else if (fieldMap[key].type === "date") {
        if (item.content) {
          if (item.content.includes("~")) {
            data[key] = item.content.split("~");
          } else {
            data[key] = item.content;
          }
        }
      }
    }
  };
  for (var i = 0; i < list.length; i++) {
    _loop();
  }
  return data;
}
export function formatValues(res, fieldMap, areaSourceMap) {
  var list = [];
  var fields = Object.values(fieldMap);
  for (var i = 0; i < fields.length; i++) {
    var field = fields[i];
    var key = field.model;
    if (res[key]) {
      var keyArr = key.split("_");
      var value = res[key];
      if (field.type === "area_detail") {
        var areaArr = [];
        for (var j = 0; j < res[key].length; j++) {
          var aId = res[key][j];
          areaArr.push("".concat(aId, "_").concat(areaSourceMap[aId]["name"]));
        }
        if (res["".concat(key, "_detail")]) {
          areaArr.push("d_".concat(res["".concat(key, "_detail")]));
        }
        value = areaArr.join(",");
      } else if (field.type === "area") {
        var _areaArr = [];
        for (var _j = 0; _j < res[key].length; _j++) {
          var _aId = res[key][_j];
          _areaArr.push("".concat(_aId, "_").concat(areaSourceMap[_aId]["name"]));
        }
        value = _areaArr.join(",");
      } else if (field.type === "date") {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          var dateArr = [];
          for (var _j2 = 0; _j2 < res[key].length; _j2++) {
            var date = res[key][_j2];
            dateArr.push(date.format("YYYY-MM-DD"));
          }
          value = dateArr.join("~");
        } else {
          value = res[key];
        }
      } else if (field.type === "upload") {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          value = value.map(function (item) {
            return item.url;
          }).join(",");
        }
      }
      list.push({
        fieldId: parseInt(keyArr[0]),
        fieldName: field.wrapper.label,
        id: field.wrapper.valueId,
        fieldCode: field.wrapper.fieldCode,
        content: value,
        subjectType: "dycl",
        subjectId: 0
      });
    } else {
      var _keyArr = key.split("_");
      list.push({
        fieldId: parseInt(_keyArr[0]),
        fieldName: field.wrapper.label,
        id: field.wrapper.valueId,
        fieldCode: field.wrapper.fieldCode,
        content: null,
        subjectType: "dycl",
        subjectId: 0
      });
    }
  }
  return list;
}