import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import echarts from "echarts";
import moment from "moment";
import { getStatisticsEmpInfoContractConut, empEduCount } from "@/api/overview/staff";
export default {
  props: ["siteId"],
  data: function data() {
    return {
      dateStart: null,
      dateEnd: null,
      xAxis: [],
      series: []
    };
  },
  watch: {
    // dateEnd: {
    //   handler(val) {
    //     this.dateStart = moment(val).subtract(5, "months");
    //     this.initContract();
    //   },
    //   deep: true
    // },
    siteId: function siteId(newValue) {
      this.initContract();
      this.initEducation();
    }
  },
  created: function created() {
    this.dateEnd = moment();
    this.dateStart = moment().subtract(5, "months");
  },
  mounted: function mounted() {
    this.initContract();
    this.initEducation();
  },
  methods: {
    dateEndChange: function dateEndChange() {
      this.dateStart = moment(this.dateEnd).subtract(5, "months");
      this.initContract();
    },
    moment: moment,
    initContract: function initContract() {
      var _this = this;
      var contractChart = echarts.init(document.getElementById("contract"));
      var data = {
        dateStart: moment(this.dateStart).format("YYYY-MM-DD"),
        dateEnd: moment(this.dateEnd).format("YYYY-MM-DD")
      };
      data.dateStart = data.dateStart.substring(0, 8) + "01";
      data.dateEnd = data.dateEnd.substring(0, 8) + "01";
      if (this.siteId && this.siteId !== "undefined") {
        data.companyIds = this.siteId;
      }
      getStatisticsEmpInfoContractConut(data).then(function (res) {
        _this.xAxis = [];
        _this.series = [];
        var temp = JSON.parse(JSON.stringify(res.data));
        temp.forEach(function (item, index) {
          _this.xAxis[index] = item.time;
          _this.series[index] = parseInt(item.count);
        });
        contractChart.setOption({
          color: localStorage.getItem("primary"),
          tooltip: {},
          legend: {
            data: []
          },
          xAxis: {
            data: _this.xAxis,
            max: "30"
          },
          yAxis: {},
          series: [{
            name: "",
            type: "bar",
            barMaxWidth: "30",
            data: _this.series
          }],
          label: {
            distance: 6,
            offset: [0, 2],
            show: true,
            position: "top"
          }
        });
        setTimeout(function () {
          window.onresize = function () {
            contractChart.resize();
          };
        }, 200);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    initEducation: function initEducation() {
      var educationChart = echarts.init(document.getElementById("education"));
      var data1 = {};
      if (this.siteId && this.siteId !== "undefined") {
        data1.companyIds = this.siteId;
      }
      empEduCount(data1).then(function (_ref) {
        var data = _ref.data;
        var option = {
          color: ["#FF7F50", "#9C64D1", "#8CD579", "#6475FF", "#FCCF5E", "#44D7B6", "#2294FF", "#FA5051", "#354785", localStorage.getItem("primary")],
          title: {
            textalign: "center",
            text: data.empCount,
            subtext: "员工数",
            left: "center",
            top: "center",
            // x: "58%",
            // y: "45%",
            textStyle: {
              fontSize: 38,
              color: "#454c5c"
            },
            subtextStyle: {
              fontSize: 12,
              color: "#6c7a89",
              fontWeight: 500
            }
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)"
          },
          series: [{
            name: "访问来源",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "30",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: [{
              value: data.primarySchool,
              name: "小学"
            }, {
              value: data.juniorHighSchool,
              name: "初中"
            }, {
              value: data.technicalSecondary,
              name: "中专"
            }, {
              value: data.highSchool,
              name: "高中"
            }, {
              value: data.juniorCollege,
              name: "大专"
            }, {
              value: data.undergraduate,
              name: "本科"
            }, {
              value: data.master,
              name: "硕士"
            }, {
              value: data.doctor,
              name: "博士"
            }, {
              value: data.others,
              name: "其他"
            }, {
              value: data.unfill,
              name: "未统计"
            }]
          }],
          legend: {
            x: "0%",
            y: 70,
            orient: "vertical",
            data: ["小学", "初中", "中专", "高中", "大专", "本科", "硕士", "博士", "其他", "未统计"],
            formatter: function formatter(name) {
              var data = option.series[0].data;
              var total = 0;
              var tarValue = 0;
              for (var i = 0, l = data.length; i < l; i++) {
                total += parseInt(data[i].value);
                if (data[i].name === name) {
                  tarValue = parseInt(data[i].value);
                }
              }
              var p;
              if (total === 0) {
                p = 0;
              } else {
                p = (tarValue / total * 100).toFixed(2);
              }
              return name + "  " + tarValue + "  " + p + "%";
            }
          }
        };
        educationChart.setOption(option);
        setTimeout(function () {
          window.onresize = function () {
            educationChart.resize();
          };
        }, 200);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleDisabled: function handleDisabled(value) {
      if (value > moment()) return true;
    }
  }
};