import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import ConfigItem from "./configItem.vue";
export default {
  name: "",
  components: {
    ConfigItem: ConfigItem
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      picsArr: [],
      tempPicsArr: [],
      prodList: [],
      prodLoaded: false,
      showCarousel: true
    };
  },
  computed: {
    theLen: function theLen() {
      return this.picsArr.length + this.tempPicsArr.length;
    }
  },
  watch: {},
  created: function created() {
    this.loadProds();
    this.loadBanners();
  },
  mounted: function mounted() {},
  methods: {
    loadProds: function loadProds() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/products/selectBySite",
        params: {
          productTypeId: "0"
        }
      }).then(function (res) {
        _this.prodList = res.data.map(function (item) {
          return {
            value: item.id,
            label: item.productName,
            disabled: item.isBanner
          };
        });
        _this.prodLoaded = true;
      }).catch(function (err) {
        console.log(err);
      });
    },
    onPicEdit: function onPicEdit(data) {
      var _this2 = this;
      this.$request({
        url: "/hr/api/v1/productBanners",
        method: "put",
        data: data
      }).then(function (res) {
        _this2.loadProds();
        _this2.loadBanners();
      }).catch(function (err) {
        console.log(err);
      });
    },
    onPicTempAdd: function onPicTempAdd(data, idx) {
      var _this3 = this;
      this.$request({
        url: "/hr/api/v1/productBanners",
        method: "post",
        data: data
      }).then(function (res) {
        _this3.loadProds();
        _this3.loadBanners();
        var temp = _toConsumableArray(_this3.tempPicsArr);
        temp.splice(idx, 1);
        _this3.tempPicsArr = temp;
      }).catch(function (err) {
        console.log(err);
      });
    },
    onPicDel: function onPicDel(idx) {
      var _this4 = this;
      this.$request({
        url: "/hr/api/v1/productBanners/".concat(this.picsArr[idx]["id"]),
        method: "delete"
      }).then(function () {
        _this4.loadProds();
        _this4.loadBanners();
      }).catch(function (err) {
        console.log(err);
      });
    },
    onPicTempDel: function onPicTempDel(idx) {
      var temp = _toConsumableArray(this.tempPicsArr);
      temp.splice(idx, 1);
      this.tempPicsArr = temp;
    },
    loadBanners: function loadBanners() {
      var _this5 = this;
      this.$request({
        url: "/hr/api/v1/productBanners/selectBySite"
      }).then(function (res) {
        _this5.picsArr = res.data || [];
        _this5.showCarousel = false;
        _this5.$nextTick(function () {
          _this5.showCarousel = true;
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    addItem: function addItem() {
      this.tempPicsArr = [].concat(_toConsumableArray(this.tempPicsArr), [{
        id: null,
        banner: "",
        productId: undefined
      }]);
    }
  }
};