/*
 * @Description: 员工部门关系
 * @Author: zhr
 */
import request from "@/utils/request";

// 获取部门员工列表
export function getEmpListByDep(data) {
  return request({
    // url: '/hr/api/v1/empDeps/getEmpListByDep',
    url: "/hr/api/v1/empInfos/selectEmpInfoALLRelation",
    method: "get",
    params: data
  });
}
// 分配员工到部门
export function empDistribute(data) {
  return request({
    url: "/hr/api/v1/empDeps/empDistribute",
    method: "put",
    data: data
  });
}