import request from "@/utils/request";

// 查询用户有的权限的用工单位
export function getCompany(data) {
  return request({
    url: "/hr/api/v1/childrenCompany/selectMonthlySalaryByCompanyList",
    method: "post",
    data: data
  });
}
export function getCompanyReqNew(params) {
  return request({
    url: "/hr/api/v1/customerDetailsNew/selectSalarySummaryBySiteAndMonthly",
    method: "get",
    params: params
  });
}
// 查询员工的客户明细
export function getDetails(data) {
  return request({
    url: "/hr/api/v1/childrenCompany/selectEmployeeSalaryByCompanyIds",
    method: "post",
    data: data
  });
}
export function getDetailsReqNew(data) {
  return request({
    url: "/hr/api/v1/customerDetailsNew/selectEmployeeDetailsPageByCompanyIdsNew",
    method: "post",
    data: data
  });
}
export function updateHead(data) {
  return request({
    url: "/admin/api/v1/headerFields/updateMyselfHead",
    method: "put",
    data: data
  });
}
export function getSelectHeaders(data) {
  return request({
    url: "/hr/api/v1/childrenCompany/selectHeaderDetail",
    method: "post",
    data: data
  });
}
export function getSelectHeadersReqNew(data) {
  return request({
    url: "/hr/api/v1/customerDetailsNew/selectHeaderDetailNew",
    method: "post",
    data: data
  });
}
// 查询用工单位/分组的表头
export function getTemplates(params) {
  return request({
    url: "/hr/api/v1/childrenCompany/selectUsedTemplateByCompanyList",
    method: "get",
    params: params
  });
}
// 查询当前创建到的最后一个月
export function getDate(params) {
  return request({
    url: "/hr/api/v1/customerDetails/selectLastOne",
    method: "get",
    params: params
  });
}
export function getDateReqNew(params) {
  return request({
    url: "/hr/api/v1/customerDetailsNew/selectLastOne",
    method: "get",
    params: params
  });
}
// 根据月工资新增客户结算表
export function updateAutoTaxGrand(data) {
  return request({
    url: "/salary/api/v1/customerSettlements/saveByMonthlySalary",
    method: "POST",
    data: data
  });
}
// 根据公司Ids新增客户明细表
export function addCustomer(data) {
  return request({
    url: "/hr/api/v1/customerDetails/saveByCompanyIds",
    method: "POST",
    data: data
  });
}
export function addCustomerReqNew(data) {
  return request({
    url: "/hr/api/v1/customerDetailsNew/saveByCompanyIds",
    method: "POST",
    data: data
  });
}
export function deleteItem(customerDetailsId) {
  return request({
    url: "/salary/api/v1/customerDetails/".concat(customerDetailsId),
    method: "delete"
  });
}
export function deleteItemReqNew(customerDetailsId) {
  return request({
    url: "/hr/api/v1/customerDetailsNew",
    method: "delete",
    data: [customerDetailsId]
  });
}