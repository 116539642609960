import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      title: _vm.title,
      visible: _vm.visible
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, [_vm.data && _vm.title === "已提交报送" ? _c("p", [_vm._v(" " + _vm._s("\u672C\u6B21\u62A5\u9001\u603B\u4EBA\u6570".concat(_vm.data.totalCount, "\uFF0C\u6210\u529F\u62A5\u9001").concat(_vm.data.successCount, "\u4EBA\uFF0C\u62A5\u9001\u5931\u8D25").concat(_vm.data.failCount, "\u4EBA\u3002")) + " ")]) : _vm._e(), _vm.data && _vm.title === "获取报送结果" ? _c("p", [_vm._v(" " + _vm._s("\u672C\u6B21\u62A5\u9001\u603B\u4EBA\u6570".concat(_vm.data.totalCount, "\uFF0C\u6210\u529F\u62A5\u9001").concat(_vm.data.successCount, "\u4EBA\u3002")) + _vm._s("\u672C\u6B21\u62A5\u9001\u6B63\u5E38\u4EBA\u6570".concat(_vm.data.normalCount, "\uFF0C\u975E\u6B63\u5E38\u4EBA\u6570").concat(_vm.data.abnormalCount, "\u4EBA\u3002")) + " ")]) : _vm._e(), _vm.data ? _c("p", [_vm._v(" " + _vm._s(_vm.data.failList && _vm.data.failList.length !== 0 ? "".concat(_vm.data.failList[0].empName, "\u7B49").concat(_vm.data.failList.length, "\u4EBA\u62A5\u9001\u5931\u8D25") : "") + " ")]) : _vm._e()])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };