import NodeWrapper from "../NodeWrapper";
import { getFlowDetailReq } from "../../api";
export default {
  name: "FlowSetting",
  components: {
    NodeWrapper: NodeWrapper
  },
  props: {
    value: {
      type: Object,
      default: function _default() {}
    },
    formlist: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      // 放大系数
      nowVal: 100,
      nodeId: 0
    };
  },
  methods: {
    // 获取流程进程
    getDetail: function getDetail() {
      var _this = this;
      getFlowDetailReq(this.$route.query.id).then(function (_ref) {
        var data = _ref.data;
        _this.$emit("flowProcessChange", _this.deep(data));
      });
    },
    deep: function deep(data) {
      if (data.props) {
        if (typeof data.props === "string" && data.nodeType !== "start") {
          data.props = JSON.parse(data.props);
        }
      }
      if (data.childNode) {
        this.deep(data.childNode);
      }
      return data;
    },
    /* 放大缩小 */zoomSize: function zoomSize(type) {
      if (type === 1) {
        if (this.nowVal === 50) {
          return this.nowVal === 50;
        }
        return this.nowVal -= 10;
      } else {
        if (this.nowVal === 300) {
          return this.nowVal === 300;
        }
        return this.nowVal += 10;
      }
    }
  }
};