import request from "@/utils/request";
export function getBrokerPageById(params) {
  return request({
    url: "/recruit2/api/v1/deliverRecords/getEmpRewardInfo",
    method: "GET",
    params: params
  });
}
export function putUpdateNotSiteID(data) {
  return request({
    url: "/recruit2/api/v1/deliverRecords",
    method: "put",
    data: data
  });
}
export function getCompanyUnits(params) {
  return request({
    url: "/recruit2/api/v1/companyUnits/pageByCondition",
    method: "GET",
    params: params
  });
}