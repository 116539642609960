import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { mapState, mapMutations } from "vuex";
export default {
  name: "AddPayslidThird",
  components: {
    step: function step() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./step"));
      });
    }
  },
  data: function data() {
    return {
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      selectedRowKeys: [],
      params: {
        nameOrPhone: undefined,
        sendStatus: undefined
      },
      dataSource: [],
      wei: 0
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    rowSelection: function rowSelection() {
      var _this = this;
      return {
        onChange: function onChange(selectedRowKeys, selectedRows) {
          _this.selectedRowKeys = selectedRows;
          _this.wei = selectedRows.length;
        },
        getCheckboxProps: function getCheckboxProps(record) {
          return {
            props: {
              disabled: record.sendStatus === true,
              // Column configuration not to be checked
              name: record.name
            }
          };
        }
      };
    }
  }),
  created: function created() {
    this.getNoSendList();
  },
  methods: _objectSpread(_objectSpread({
    // 上一步
    doBefor: function doBefor() {
      var path = "add-payslip-second";
      this.$router.push({
        path: path,
        query: _objectSpread({}, this.$route.query)
      });
    },
    changePage: function changePage(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getNoSendList();
    },
    // 获取 未发送列表
    getNoSendList: function getNoSendList() {
      var _this2 = this;
      this.$request({
        url: "/hr/api/v1/salaryBills/listAllRelation",
        params: _objectSpread(_objectSpread({
          companyId: this.hr.companyId,
          reloadCount: false,
          tableId: this.$route.query.tableId
        }, this.params), {}, {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        })
      }).then(function (_ref) {
        var data = _ref.data;
        _this2.dataSource = data.salaryBillListVOList || [];
        _this2.pagination.total = data.entityCount || 0;
      });
    }
  }, mapMutations({
    resetPayslip: "wages/resetPayslip"
  })), {}, {
    // 筛选
    onSearch: function onSearch(e) {
      this.params.nameOrPhone = e;
      this.getNoSendList();
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      var _this3 = this;
      this.selectedRowKeys = selectedRowKeys;
      var dataSource = [];
      selectedRowKeys.map(function (key) {
        dataSource.push(_this3.dataSource[key]);
      });
      this.wei = dataSource.filter(function (key) {
        return key.wechatStatus;
      }).length;
    },
    // 状态
    handleChange: function handleChange(e) {
      this.params.sendStatus = e;
      this.getNoSendList();
    },
    // 发送
    addStart: function addStart(type) {
      var _this4 = this;
      if (!type) {
        this.selectedRowKeys = this.dataSource.filter(function (item) {
          return item.sendStatus !== true;
        });
      }
      if (this.selectedRowKeys.length) {
        this.selectedRowKeys.forEach(function (item) {
          item.sendType = _this4.$route.query.sendType;
          item.sendStatus = true;
        });
      }
      var postData = {
        autoCheckHours: this.$route.query.autoCheckHours,
        autoCheckUnit: this.$route.query.autoCheckUnit,
        companyId: this.hr.companyId,
        checkCount: 0,
        feedbackCount: 0,
        readCount: 0,
        salaryBillDOList: this.selectedRowKeys,
        sendBy: "",
        sendName: this.$route.query.sendName,
        sendType: this.$route.query.sendType,
        showEmptyValue: this.$route.query.showEmptyValue === "true",
        tableId: this.$route.query.tableId,
        withdrawStatus: 0,
        withdrawnCount: 0,
        month: this.$route.query.month,
        year: this.$route.query.year
      };
      this.$request({
        url: "/hr/api/v1/salaryBillSends",
        method: "POST",
        data: postData
      }).then(function (_ref2) {
        var data = _ref2.data,
          success = _ref2.success;
        if (success) {
          _this4.resetPayslip();
          _this4.$notification["success"]({
            message: "发送成功"
          });
          _this4.$router.push({
            path: "add-payslip-forth",
            query: {
              sendCount: data.sendCount,
              tableId: data.tableId,
              sendName: data.sendName,
              year: data.year,
              month: data.month
            }
          });
        }
      });
    }
  })
};