import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: "UploadImg",
  components: {
    UploadSiteImg: function UploadSiteImg() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-plugin-common"));
      });
    }
  },
  props: {
    record: {
      type: Object,
      default: function _default() {}
    },
    value: {
      type: String,
      default: ""
    }
  },
  methods: {
    onChange: function onChange(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    }
  }
};