import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import modifyingPop from "./modifyingPop";
import { getAllChannels, getPrePersonnel, identificationCard } from "@/api/outsourcing/outsourcing";
var columns = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: 80
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  width: 120
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 180
}];
export default {
  components: {
    modifyingPop: modifyingPop
  },
  data: function data() {
    return {
      textState: "",
      columns: columns,
      dataList: [],
      seniorVisible: false,
      seniorList: [],
      selectedRowKeys: [],
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 16
      },
      form: {},
      Channels: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      },
      // //////////////////
      chosenList: [],
      textContent: "",
      copyVisible: false,
      errorState: false,
      // ///////////////////////////
      checkAll: false,
      visible: false,
      staffFrom: {
        nameOrPhoneOrIdcard: null,
        pageNo: 1,
        pageSize: 10
      },
      pageCount: 0,
      staffData: [],
      staffrightData: [],
      confirmLoading: false,
      spinning: false
    };
  },
  watch: {
    textContent: function textContent(e) {
      if (e) {
        this.errorState = false;
      }
    }
  },
  methods: {
    saveOk: function saveOk() {
      this.$emit("saveOk");
    },
    //  `````````````````
    deleteSeniorItem: function deleteSeniorItem(e) {
      var _this = this;
      this.seniorList.forEach(function (item, index) {
        if (item.id === e.id) {
          _this.seniorList.splice(index, 1);
          _this.selectedRowKeys.forEach(function (item2, index2) {
            if (item2 === e.id) {
              _this.selectedRowKeys.splice(index2, 1);
            }
          });
        }
      });
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    onSelectChange: function onSelectChange(selectedRowKeys, e) {
      var _this2 = this;
      this.selectedRowKeys = selectedRowKeys;
      this.dataList.forEach(function (item) {
        selectedRowKeys.forEach(function (item2) {
          if (item.id === item2) {
            _this2.seniorList.push(item);
            _this2.seniorList = _this2.unique(_this2.seniorList);
          }
        });
      });
      this.seniorList.forEach(function (item, index) {
        if (_this2.selectedRowKeys.indexOf(item.id) === -1) {
          item.isSelectedItem = 2;
        }
      });
      var arr = [];
      this.seniorList.forEach(function (item, index) {
        if (!item.isSelectedItem) {
          arr.push(item);
        }
      });
      this.seniorList = JSON.parse(JSON.stringify(arr));
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      // this.checkStatue = 0;
      this.getSeniorList();
    },
    copyOk: function copyOk() {
      this.copyVisible = false;
      this.$refs.pop.showModal(this.chosenList, this.textState);
    },
    seniorOk: function seniorOk() {
      this.seniorVisible = false;
      this.$refs.pop.showModal(this.seniorList, this.textState);
    },
    clearForm: function clearForm() {
      this.form = {};
    },
    seniorCancel: function seniorCancel() {
      this.seniorVisible = false;
      this.selectedRowKeys = [];
    },
    getSeniorListCheck: function getSeniorListCheck() {
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.checkStatue = 1;
      this.getSeniorList();
    },
    showSenior: function showSenior() {
      var _this3 = this;
      this.visible = false;
      this.seniorVisible = true;
      this.clearForm();
      this.pagination = {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      };
      this.form = {};
      this.selectedRowKeys = [];
      this.getSeniorList();
      this.getAllChannelsList();
      if (this.staffrightData.length > 0) {
        this.seniorList = JSON.parse(JSON.stringify(this.staffrightData));
        this.staffrightData.forEach(function (item, index) {
          _this3.selectedRowKeys.push(item.id);
        });
      } else {
        this.selectedRowKeys = [];
        this.seniorList = [];
      }
    },
    getSeniorList: function getSeniorList() {
      var _this4 = this;
      var data = _objectSpread(_objectSpread(_objectSpread({
        notSource: "花名册",
        "orders[0].property": "status",
        "orders[1].property": "gmtCreate",
        "orders[0].desc": false,
        "orders[1].desc": true,
        isShowOtherInfo: true,
        IsBatchUpdateStatus: this.textState === "状态" ? true : null
      }, this.staffFrom), this.form), {}, {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      });
      getPrePersonnel(data).then(function (res) {
        if (res.data.entities === null) {
          _this4.total = 0;
        } else {
          _this4.pagination.total = res.data && res.data.entityCount;
          _this4.dataList = res.data.entities;
        }
      }).catch(function (err) {
        console.log("err==>", err);
      });
    },
    // `````````高级粘贴```````````
    deleteCopyItem: function deleteCopyItem(e) {
      var _this5 = this;
      this.chosenList.forEach(function (item, index) {
        if (item.id === e.id) {
          _this5.chosenList.splice(index, 1);
        }
      });
    },
    getAllChannelsList: function getAllChannelsList() {
      var _this6 = this;
      getAllChannels({
        isNotChannel: true
      }).then(function (res) {
        _this6.Channels = res.data;
      });
    },
    showCopy: function showCopy() {
      this.visible = false;
      this.textContent = "";
      this.copyVisible = true;
      if (this.staffrightData.length > 0) {
        this.chosenList = JSON.parse(JSON.stringify(this.staffrightData));
      } else {
        this.chosenList = [];
      }
    },
    getEmpListByIdCards: function getEmpListByIdCards() {
      var _this7 = this;
      if (this.textContent !== "") {
        var arr = this.textContent.split(/[\W]/);
        // console.log("arr", arr);
        identificationCard({
          cardList: arr,
          IsBatchUpdateStatus: this.textState === "状态" ? true : null
        }).then(function (res) {
          // console.log(res);
          if (_this7.staffrightData.length > 0) {
            _this7.chosenList = res.data.checkInPeopleVOList.concat(_this7.staffrightData);
            _this7.chosenList = _this7.unique(_this7.chosenList);
          } else {
            _this7.chosenList = res.data.checkInPeopleVOList;
          }
          _this7.textContent = res.data.idCardList.join(",");
          if (res.data.idCardList.length > 0) {
            _this7.$nextTick(function () {
              _this7.errorState = true;
            });
          }
        });
      }
    },
    unique: function unique(arr) {
      var res = new Map();
      return arr.filter(function (arr) {
        return !res.has(arr.id) && res.set(arr.id, 1);
      });
    },
    reset: function reset() {
      this.textContent = "";
      this.errorState = false;
    },
    // ··········选择人员·······························
    // 全选当前页面
    onCheckAll: function onCheckAll() {
      var _this8 = this;
      this.checkAll = !this.checkAll;
      if (this.checkAll) {
        this.staffData.forEach(function (element) {
          if (!element.isTrue) {
            _this8.onRow(element);
          }
        });
      } else {
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            _this8.deleteItem(element);
          }
        });
      }
    },
    deleteItem: function deleteItem(item, index) {
      var _this9 = this;
      this.staffrightData.splice(index, 1);
      this.staffData.forEach(function (element) {
        if (element.id === item.id) {
          element.isTrue = false;
          _this9.checkAll = false;
        }
      });
    },
    onSearch: function onSearch() {
      this.staffFrom.pageNo = 1;
      this.getPrePersonnelFun();
    },
    onChangePagination: function onChangePagination(pageNumber) {
      this.staffFrom.pageNo = pageNumber;
      this.getPrePersonnelFun();
    },
    showSizeChange: function showSizeChange(current, size) {
      this.staffFrom.pageNo = 1;
      this.staffFrom.pageSize = size;
      this.getPrePersonnelFun();
    },
    onRow: function onRow(item) {
      var _this10 = this;
      item.isTrue = !item.isTrue;
      if (item.isTrue) {
        var num = 0;
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            num += 1;
          }
        });
        this.checkAll = this.staffData.length === num;
        var arr = this.staffrightData.filter(function (items) {
          return items.id === item.id;
        });
        if (arr.length === 0) {
          this.staffrightData.push(item);
        }
      } else {
        this.checkAll = false;
        this.staffrightData.forEach(function (element, index) {
          if (item.id === element.id) {
            _this10.staffrightData.splice(index, 1);
          }
        });
      }
    },
    getPrePersonnelFun: function getPrePersonnelFun() {
      var _this11 = this;
      var data = _objectSpread({
        notSource: "花名册",
        "orders[0].property": "status",
        "orders[1].property": "gmtCreate",
        "orders[0].desc": false,
        "orders[1].desc": true,
        isShowOtherInfo: true,
        IsBatchUpdateStatus: this.textState === "状态" ? true : null
      }, this.staffFrom);
      getPrePersonnel(data).then(function (res) {
        res.data.entities.forEach(function (element, index) {
          res.data.entities[index].isTrue = false;
        });
        _this11.staffrightData.forEach(function (element) {
          res.data.entities.forEach(function (esy) {
            if (element.id === esy.id) {
              esy.isTrue = true;
            }
          });
        });
        _this11.staffData = res.data.entities;
        _this11.pageCount = res.data.entityCount;
        var arr = _this11.staffData.filter(function (item) {
          return item.isTrue;
        });
        _this11.checkAll = !!(arr.length === _this11.staffData.length && arr.length !== 0);
      }).catch(function (err) {
        console.log("err==>", err);
      });
    },
    showModal: function showModal(e) {
      this.visible = true;
      this.textState = e;
      this.staffFrom = {
        nameOrPhoneOrIdcard: null,
        pageNo: 1,
        pageSize: 10
      };
      this.staffrightData = [];
      this.getPrePersonnelFun();
    },
    handleOk: function handleOk(e) {
      this.confirmLoading = true;
      this.visible = false;
      this.$refs.pop.showModal(this.staffrightData, this.textState);
      this.confirmLoading = false;
    },
    handleCancel: function handleCancel(e) {
      this.visible = false;
    }
  }
};