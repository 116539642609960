import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "padding-bottom": "60px"
    }
  }, [_c("div", {
    staticClass: "toptips mt24"
  }, [_c("a-col", {
    attrs: {
      gutter: 14
    }
  }, [_c("a-alert", {
    attrs: {
      message: "合同变量自动代入花名册最新一份的合同信息，员工相关信息将作为合同内容中的真实信息。",
      banner: "",
      closable: ""
    }
  })], 1), _c("a-col", {
    attrs: {
      gutter: 14
    }
  }, [_c("a-button", {
    staticClass: "mr16",
    attrs: {
      type: "primary",
      disabled: _vm.isBatch
    },
    on: {
      click: _vm.batchEdit
    }
  }, [_vm._v("批量编辑")]), _c("a-button", {
    staticClass: "mr16",
    attrs: {
      type: "primary",
      disabled: _vm.isBatch
    },
    on: {
      click: _vm.batchDelete
    }
  }, [_vm._v("批量删除")]), _c("a-button", {
    attrs: {
      icon: "plus",
      type: "primary"
    },
    on: {
      click: _vm.addMember
    }
  }, [_vm._v(" 添加员工 ")])], 1)], 1), _c("employee-select", {
    attrs: {
      show: _vm.chooseStaffVisible,
      "default-selected-keys": _vm.defaultSelectedKeys,
      "selected-list": _vm.currentSelectList,
      "template-id": _vm.templateId
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.chooseStaffVisible = $event;
      },
      "update:selectedList": function updateSelectedList($event) {
        _vm.currentSelectList = $event;
      },
      "update:selected-list": function updateSelectedList($event) {
        _vm.currentSelectList = $event;
      },
      change: _vm.onMemberChange
    }
  }), _c("a-table", {
    staticStyle: {
      "min-height": "300"
    },
    attrs: {
      loading: _vm.loading,
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      },
      columns: _vm.contractColumns,
      "data-source": _vm.contractlist,
      scroll: {
        x: _vm.contractColumns.length * 150
      },
      customRow: _vm.rowClick,
      rowKey: "empId",
      pagination: _vm.pagination
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "contractStartTime",
      fn: function fn(text, item, idx) {
        return _c("span", {}, [_c("a-date-picker", {
          staticStyle: {
            width: "130px"
          },
          attrs: {
            allowClear: true,
            disabledDate: _vm.disabledStartDate(item.contractEndTime),
            placeholder: "合同起始时间"
          },
          on: {
            change: function change($event) {
              return _vm.onChangeStart($event, item, idx);
            }
          },
          model: {
            value: item.contractStartTime,
            callback: function callback($$v) {
              _vm.$set(item, "contractStartTime", $$v);
            },
            expression: "item.contractStartTime"
          }
        })], 1);
      }
    }, {
      key: "contractExpire",
      fn: function fn(text, item, idx) {
        return _c("span", {}, [_c("a-row", {
          attrs: {
            type: "flex"
          }
        }, [_c("a-col", [_c("a-select", {
          staticStyle: {
            width: "80px",
            "margin-right": "8px"
          },
          attrs: {
            allowClear: true,
            disabled: item.contractType === "laborContractUnlimit",
            placeholder: "期限"
          },
          on: {
            change: function change($event) {
              return _vm.onChangeNum($event, item, idx);
            }
          },
          model: {
            value: item.contractExpire,
            callback: function callback($$v) {
              _vm.$set(item, "contractExpire", $$v);
            },
            expression: "item.contractExpire"
          }
        }, _vm._l(item.leftTime ? item.leftTime : 11, function (time, timeIndex) {
          return _c("a-select-option", {
            key: timeIndex + 1,
            attrs: {
              value: timeIndex + 1
            }
          }, [_vm._v(" " + _vm._s(timeIndex + 1) + " ")]);
        }), 1)], 1), _c("a-col", [_c("a-select", {
          staticStyle: {
            width: "60px"
          },
          attrs: {
            allowClear: true,
            disabled: item.contractType === "laborContractUnlimit",
            placeholder: "期限"
          },
          on: {
            change: function change($event) {
              return _vm.onChangeUnit($event, item, idx);
            }
          },
          model: {
            value: item.timelimitType,
            callback: function callback($$v) {
              _vm.$set(item, "timelimitType", $$v);
            },
            expression: "item.timelimitType"
          }
        }, _vm._l(_vm.rightTime, function (time, timeIndex) {
          return _c("a-select-option", {
            key: timeIndex + 1,
            attrs: {
              value: timeIndex + 1
            }
          }, [_vm._v(" " + _vm._s(time) + " ")]);
        }), 1)], 1)], 1)], 1);
      }
    }, {
      key: "contractEndTime",
      fn: function fn(text, item, idx) {
        return _c("span", {}, [_c("a-date-picker", {
          staticStyle: {
            width: "130px"
          },
          attrs: {
            disabled: item.contractType === "laborContractUnlimit",
            disabledDate: _vm.disabledEndDate(item.contractStartTime),
            allowClear: true,
            placeholder: "合同结束时间"
          },
          on: {
            change: function change($event) {
              return _vm.onChangeEnd($event, item, idx);
            }
          },
          model: {
            value: item.contractEndTime,
            callback: function callback($$v) {
              _vm.$set(item, "contractEndTime", $$v);
            },
            expression: "item.contractEndTime"
          }
        })], 1);
      }
    }, {
      key: "contractType",
      fn: function fn(text, item, idx) {
        return _c("span", {}, [_c("a-dropdown", [_c("a", {
          staticClass: "ant-dropdown-link"
        }, [_vm._v(" " + _vm._s(_vm.getContractType(text)) + " ")]), _c("a-menu", {
          attrs: {
            slot: "overlay"
          },
          slot: "overlay"
        }, _vm._l(_vm.contractTypeList, function (menuitem, index) {
          return _c("a-menu-item", {
            key: index
          }, [_c("div", {
            staticStyle: {
              display: "flex",
              "align-items": "center"
            },
            on: {
              click: function click($event) {
                return _vm.onChangeType(menuitem, item, idx);
              }
            }
          }, [_vm._v(" " + _vm._s(menuitem.value) + " ")])]);
        }), 1)], 1)], 1);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("span", {}, [_c("a", {
          on: {
            click: function click($event) {
              return _vm.edit(record);
            }
          }
        }, [_vm._v("编辑")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a", {
          on: {
            click: function click($event) {
              return _vm.del(record);
            }
          }
        }, [_vm._v("删除")])], 1);
      }
    }])
  }), _c("div", {
    staticClass: "btnline bgc-white",
    style: {
      position: "fixed",
      width: "auto",
      bottom: _vm.fixedBottom,
      right: "24px",
      zIndex: "10",
      left: _vm.fixedLeft
    }
  }, [_c("a-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.backStep
    }
  }, [_vm._v("上一步")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.nextStep
    }
  }, [_vm._v("下一步")])], 1), _c("a-drawer", {
    attrs: {
      title: "确认合同变量信息",
      width: 484,
      visible: _vm.updateVisible,
      "body-style": {
        paddingBottom: "80px"
      }
    },
    on: {
      close: function close($event) {
        _vm.updateVisible = false;
      }
    }
  }, [_c("a-form", {
    staticClass: "qrht",
    attrs: {
      form: _vm.contractForm,
      layout: "vertical",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 16
      },
      colon: true,
      "hide-required-mark": ""
    }
  }, [_c("a-collapse", {
    staticStyle: {
      background: "#fff"
    },
    attrs: {
      activeKey: _vm.activeKey,
      bordered: false
    },
    scopedSlots: _vm._u([{
      key: "expandIcon",
      fn: function fn(props) {
        return [_c("a-icon", {
          staticClass: "colorIcons",
          attrs: {
            type: "caret-right",
            rotate: props.isActive ? 90 : 0
          }
        })];
      }
    }])
  }, [_vm._l(_vm.conVariableMapArr, function (item) {
    return [_vm.showPanel(item.children) ? _c("a-collapse-panel", {
      key: item.name,
      staticClass: "bgc-white fs16 fw600",
      staticStyle: {
        border: "0",
        overflow: "hidden"
      },
      attrs: {
        header: item.name
      }
    }, _vm._l(item.children, function (item2, index2) {
      return _c("a-row", {
        key: index2
      }, _vm._l(item2, function (val, key) {
        return _c("a-row", {
          key: key.index
        }, [!_vm.hideItems.includes(key) ? _c("a-form-item", {
          staticStyle: {
            display: "flex !important",
            "align-items": "center"
          },
          attrs: {
            label: key,
            align: "center"
          }
        }, [_c("a-input", {
          directives: [{
            name: "decorator",
            rawName: "v-decorator",
            value: [key, {
              rules: [{
                required: false,
                message: "请完善信息"
              }],
              initialValue: val.content
            }],
            expression: "[\n                      key,\n                      {\n                        rules: [{ required: false, message: '请完善信息' }],\n                        initialValue: val.content\n                      }\n                    ]"
          }],
          attrs: {
            allowClear: true,
            "max-length": key == "紧急联系人电话" || key == "联系电话" ? 11 : 200,
            placeholder: key == "工资支付方" ? "请填写 甲方 或者 用工单位 " : key
          }
        })], 1) : _vm._e()], 1);
      }), 1);
    }), 1) : _vm._e()];
  })], 2)], 1), _c("div", {
    staticClass: "pv16 ph10 bgc-white ta-right",
    style: {
      position: "absolute",
      right: 0,
      bottom: 0,
      width: "100%",
      borderTop: "1px solid #e9e9e9",
      zIndex: 1
    }
  }, [_c("a-button", {
    style: {
      marginRight: "8px"
    },
    on: {
      click: function click($event) {
        _vm.updateVisible = false;
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmTheContract
    }
  }, [_vm._v("确认")])], 1)], 1), _c("a-modal", {
    attrs: {
      title: "未完善详情"
    },
    on: {
      ok: _vm.handleErrOk
    },
    model: {
      value: _vm.errStaffVisible,
      callback: function callback($$v) {
        _vm.errStaffVisible = $$v;
      },
      expression: "errStaffVisible"
    }
  }, [_c("div", {
    staticStyle: {
      "overflow-y": "scroll",
      height: "250px",
      "white-space": "pre"
    }
  }, [_vm._v(" " + _vm._s(_vm.errStaff) + " ")])]), _c("BatchEdit", {
    on: {
      confirm: _vm.batchEditConfirm
    },
    model: {
      value: _vm.batchEditVisible,
      callback: function callback($$v) {
        _vm.batchEditVisible = $$v;
      },
      expression: "batchEditVisible"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };