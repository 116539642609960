import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
var rowSelection = {
  onChange: function onChange(selectedRowKeys, selectedRows) {},
  onSelect: function onSelect(record, selected, selectedRows) {},
  onSelectAll: function onSelectAll(selected, selectedRows, changeRows) {}
};
var columns = [{
  title: "姓名",
  dataIndex: "name",
  fixed: "left",
  scopedSlots: {
    customRender: "customName"
  }
}, {
  title: "参保城市",
  dataIndex: "socCityName"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200
}, {
  title: "参保方案",
  dataIndex: "socProgName"
}, {
  title: "社保停缴时间",
  dataIndex: "socEndDate",
  scopedSlots: {
    customRender: "socEndDate"
  }
}, {
  title: "公积金停缴时间",
  dataIndex: "fundEndDate",
  scopedSlots: {
    customRender: "fundEndDate"
  }
}, {
  title: "指派公司",
  dataIndex: "assignCompanyName",
  scopedSlots: {
    customRender: "assignCompanyName"
  }
}, {
  title: "操作",
  dataIndex: "",
  scopedSlots: {
    customRender: "action"
  }
}];
import step from "./common/step";
import navItem from "./common/nav-item";
import editorModal from "./common/editor-modal";
export default {
  name: "ShebaoDetailList",
  components: {
    // step,

    navItem: navItem,
    editorModal: editorModal
  },
  data: function data() {
    return {
      selectedRowKeys: [],
      editorShow: false,
      editorOptions: {},
      columns: columns,
      rowSelection: rowSelection,
      tabs: {},
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      titles: {},
      dataSource: [],
      params: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  computed: {
    hasSelected: function hasSelected() {
      return this.selectedRowKeys.length > 0;
    }
  },
  created: function created() {
    this.getList();
    this.getNavs();
  },
  methods: {
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    deleteLits: function deleteLits() {
      var _this = this;
      var data = [];
      this.selectedRowKeys.map(function (item) {
        data.push(_this.dataSource[item].id);
      });
      this.$confirm({
        title: "确定批量删除?",
        okType: "danger",
        onOk: function onOk() {
          _this.$request({
            url: "/hr/api/v1/socDispatchEmpLesses",
            data: data,
            method: "delete"
          }).then(function (_ref) {
            var success = _ref.success;
            if (success) {
              _this.selectedRowKeys = [];
              _this.$notification["success"]({
                message: "删除成功"
              });
              _this.getList();
              _this.getNavs();
            }
          });
        }
      });
    },
    editorCb: function editorCb(e) {
      var _this2 = this;
      this.editorShow = false;
      this.editorOptions = e.options;
      var soceItem = this.$route.query.addOrLess === "true" ? this.editorOptions.socDispatchEmpItemList || [] : this.editorOptions.socProgItemVOList || [];
      this.editorOptions.itemUpdateList = soceItem.map(function (item) {
        return {
          baseAmount: item.cardinalityDefault,
          itemName: item.itemName
        };
      });
      if (e.onOk) {
        this.$request({
          url: "/hr/api/v1/socDispatchEmpAdds/updateEmpAndItem",
          method: "put",
          data: e.options
        }).then(function (_ref2) {
          var success = _ref2.success;
          if (success) {
            _this2.getList();
            _this2.getNavs();
          }
        });
      }
    },
    getList: function getList() {
      var _this3 = this;
      this.$request({
        url: "/hr/api/v1/socDispatchEmpLesses",
        params: _objectSpread(_objectSpread({}, this.params), {}, {
          socDispatchId: this.$route.query.id,
          showItem: true
        })
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this3.dataSource = data.entities;
        _this3.pagination.total = data.entityCount;
      });
    },
    getNavs: function getNavs() {
      var _this4 = this;
      this.$request({
        url: "/hr/api/v1/socDispatches/findDetails/".concat(this.$route.query.id)
      }).then(function (_ref4) {
        var data = _ref4.data;
        _this4.tabs = data;
      });
    },
    handleNav: function handleNav(e) {},
    selectItem: function selectItem(e) {},
    handlePage: function handlePage(e) {
      this.params.pageNo = e.current;
      this.getList();
    },
    // 重新上传
    reUpload: function reUpload() {
      this.$router.push({
        path: "/shebao/shebaoPaidan/shebaoUpload"
      });
    },
    // 派单
    upload: function upload() {
      var _this5 = this;
      this.$request({
        url: "/hr/api/v1/socDispatches/dispatchSend/".concat(this.$route.query.id),
        method: "put"
      }).then(function (_ref5) {
        var success = _ref5.success,
          data = _ref5.data;
        if (success) {
          _this5.$router.push({
            path: "/shebao/shebaoPaidan/shebao-detail-add-third",
            query: _objectSpread(_objectSpread({
              unSendCount: _this5.pagination.total
            }, _this5.$route.query), {}, {
              data: data
            })
          });
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
        throw err;
      });
    },
    expandChange: function expandChange(expanded, record) {},
    // 编辑
    editor: function editor(type) {
      this.editorShow = true;
      this.editorOptions = type;
    },
    // 参保记录
    history: function history(e) {},
    // 删除item
    deleteItem: function deleteItem(_ref6) {
      var _this6 = this;
      var id = _ref6.id;
      this.$confirm({
        title: "确定删除?",
        okType: "danger",
        onOk: function onOk() {
          _this6.$request({
            url: "/hr/api/v1/socDispatchEmpLesses/".concat(id),
            method: "delete"
          }).then(function (_ref7) {
            var success = _ref7.success;
            if (success) {
              _this6.$notification["success"]({
                message: "删除成功"
              });
              _this6.getList();
            }
          });
        }
      });
    }
  }
};