var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-layout", [_c("a-layout-sider", {
    staticStyle: {
      "border-right": "1px solid #e8e8e8"
    },
    attrs: {
      width: 225
    }
  }, [_c("div", {
    staticClass: "allApp",
    on: {
      click: function click($event) {
        _vm.current = [];
        _vm.selectedKeys = [];
        _vm.params = {};
        _vm.form.resetFields();
        _vm.getApprovalList(1);
      }
    }
  }, [_vm._v(" 所有审批类型(" + _vm._s(_vm.listNumAll) + ") ")]), _c("a-menu", {
    staticStyle: {
      "min-height": "600px"
    },
    attrs: {
      mode: "inline",
      openKeys: _vm.current
    },
    on: {
      openChange: _vm.openChange,
      "update:openKeys": function updateOpenKeys($event) {
        _vm.current = $event;
      },
      "update:open-keys": function updateOpenKeys($event) {
        _vm.current = $event;
      }
    },
    model: {
      value: _vm.selectedKeys,
      callback: function callback($$v) {
        _vm.selectedKeys = $$v;
      },
      expression: "selectedKeys"
    }
  }, _vm._l(_vm.items, function (group) {
    return _c("a-sub-menu", {
      key: group.id,
      attrs: {
        defaultSelectedKeys: []
      },
      on: {
        titleClick: function titleClick($event) {
          return _vm.subMenuGroup(group);
        }
      }
    }, [_c("span", {
      attrs: {
        slot: "title"
      },
      slot: "title"
    }, [_c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_vm._v(_vm._s(group.groupName) + "(" + _vm._s(group[_vm.listNumArr[_vm.approvalState - 1]]) + ")")]), _c("strong", [_vm._v(_vm._s(group.groupName) + "(" + _vm._s(group[_vm.listNumArr[_vm.approvalState - 1]]) + ")")])], 2)], 1), _vm._l(group.approFlowList, function (item) {
      return _c("a-menu-item", {
        key: item.id,
        on: {
          click: function click($event) {
            return _vm.menuItem(item);
          }
        }
      }, [_c("a-tooltip", [_c("template", {
        slot: "title"
      }, [_vm._v(_vm._s(item.typeName) + "(" + _vm._s(item[_vm.listNumArr[_vm.approvalState - 1]]) + ")")]), _vm._v(" " + _vm._s(item.typeName) + "(" + _vm._s(item[_vm.listNumArr[_vm.approvalState - 1]]) + ") ")], 2)], 1);
    })], 2);
  }), 1)], 1), _c("a-layout-content", [_c("a-row", {
    staticClass: "approval_title",
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      layout: "inline"
    }
  }, [_c("a-form-item", {
    staticClass: "mg_r20",
    attrs: {
      label: "审批时间",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-range-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["gmtCreate"],
      expression: "['gmtCreate']"
    }],
    staticStyle: {
      width: "240px"
    },
    on: {
      change: _vm.onChange
    }
  })], 1), _vm.approvalState != 1 ? _c("a-form-item", {
    staticClass: "mg_r20",
    attrs: {
      label: "审批类型",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["status"],
      expression: "['status']"
    }],
    staticStyle: {
      width: "200px",
      "margin-left": "20px"
    },
    attrs: {
      "allow-clear": true
    },
    on: {
      change: _vm.selectApproval
    }
  }, _vm._l(_vm.approvalTypeArr(_vm.approvalTabState), function (i) {
    return _c("a-select-option", {
      key: i.value,
      attrs: {
        value: i.value
      }
    }, [_vm._v(_vm._s(i.state))]);
  }), 1)], 1) : _vm._e()], 1)], 1), _c("a-table", {
    staticStyle: {
      "margin-left": "24px"
    },
    attrs: {
      pagination: _vm.pagination,
      "data-source": _vm.data,
      "row-key": function rowKey(record, index) {
        return index;
      }
    },
    on: {
      change: _vm.handleTableChange
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "标题",
      "data-index": "remark"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_vm._v(_vm._s(text ? text : "--"))];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "审批类型",
      "data-index": _vm.approvalTabState == "3" ? "flowName" : "approName"
    }
  }), _vm.approvalTabState != "3" && _vm.approvalTabState != "4" ? _c("a-table-column", {
    attrs: {
      title: "发起人",
      "data-index": "userName"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_vm._v(_vm._s(text ? text : "--"))];
      }
    }], null, false, 3973325167)
  }) : _vm._e(), _c("a-table-column", {
    attrs: {
      title: _vm.approvalTabState == "3" ? "审批总状态" : "当前节点状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(record) {
        return [_c("div", [_c("span", {
          staticClass: "fw500 dontBack",
          style: {
            background: _vm.getApprovalStatusColorBlue(_vm.approvalTabState == "3" ? record.status : record.nodeApproverStatu)
          }
        }), _vm.approvalTabState != "3" ? _c("span", {
          staticClass: "verAlignSuper"
        }, [_vm._v(" " + _vm._s(_vm.getApprovalStatus(_vm.approvalTabState == "4" ? record.approStatu : record.nodeApproverStatu)) + " ")]) : _vm._e(), _vm.approvalTabState == "3" ? _c("span", {
          staticClass: "verAlignSuper"
        }, [_vm._v(" " + _vm._s(_vm.getApprovalStatus(record.status)) + " ")]) : _vm._e()])];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "发起日期",
      "data-index": _vm.approvalTabState == "3" ? "gmtCreate" : "approCreate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(record) {
        return [_vm._v(_vm._s(_vm.formatDateAll(record, "d")))];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(record) {
        return [_c("a", {
          staticClass: "primary_color",
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.statusDetail(record);
            }
          }
        }, [_vm._v("详情")])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };