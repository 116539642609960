import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper page-miH page-bgBack p24"
  }, [_c("div", {
    staticClass: "mb16",
    staticStyle: {
      width: "206px"
    }
  }, [_c("selectCompanyGroup", {
    attrs: {
      siteIds: _vm.siteId
    },
    on: {
      "update:siteIds": function updateSiteIds($event) {
        _vm.siteId = $event;
      },
      "update:site-ids": function updateSiteIds($event) {
        _vm.siteId = $event;
      }
    }
  })], 1), _c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "240px"
    },
    attrs: {
      allowClear: "",
      placeholder: "输入批次名/申请人/用工单位",
      defaultValue: _vm.searchName
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.searchName,
      callback: function callback($$v) {
        _vm.searchName = $$v;
      },
      expression: "searchName"
    }
  }), _c("a-range-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      valueFormat: "YYYY-MM-DD",
      defaultValue: _vm.time
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.time,
      callback: function callback($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })], 1), _c("a-col", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onAdd
    }
  }, [_vm._v(" 发薪申请 ")])], 1)], 1), _c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    staticClass: "colw1"
  }, [_c("div", {
    staticClass: "tabs",
    class: _vm.tabStatus == 1 ? "af-primary-border" : ""
  }, [_c("div", {
    staticClass: "back"
  }, [_c("div", {
    staticClass: "tabs-item-one",
    class: 5 == _vm.applyForStatus ? "af-primary" : "",
    on: {
      click: function click($event) {
        return _vm.onTab(5, "applyForStatus", 1, 9);
      }
    }
  }, [_vm._v(" 未发放 ")])]), _vm._l(_vm.applyForArr, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "back",
      on: {
        click: function click($event) {
          return _vm.onTab(index, "applyForStatus", 1, item.id);
        }
      }
    }, [_c("div", {
      staticClass: "tabs-item"
    }, [_c("div", {
      class: index == _vm.applyForStatus ? "af-primary" : ""
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      class: index == _vm.applyForStatus ? "af-primary" : ""
    }, [_vm._v(_vm._s(item.num))])])]);
  })], 2)]), _c("a-col", {
    staticClass: "colw2"
  }, [_c("div", {
    staticClass: "tabs",
    class: _vm.tabStatus == 2 ? "af-primary-border" : ""
  }, [_c("div", {
    staticClass: "back"
  }, [_c("div", {
    staticClass: "tabs-item-one",
    class: 4 == _vm.payStatus ? "af-primary" : "",
    on: {
      click: function click($event) {
        return _vm.onTab(4, "payStatus", 2, 5);
      }
    }
  }, [_vm._v("已发放")])]), _vm._l(_vm.payArr, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "back",
      on: {
        click: function click($event) {
          return _vm.onTab(index, "payStatus", 2, item.id);
        }
      }
    }, [_c("div", {
      staticClass: "tabs-item"
    }, [_c("div", {
      class: index == _vm.payStatus ? "af-primary" : ""
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      class: index == _vm.payStatus ? "af-primary" : ""
    }, [_vm._v(_vm._s(item.num))])])]);
  })], 2)])], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "gmtCreate" ? _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(text ? _vm.moment(text).format("YYYY-MM-DD HH:mm") : "--") + " ")]) : col.dataIndex === "subjectStatus" ? _c("div", {
            key: index
          }, [text == 1 ? _c("div", [_c("div", {
            staticClass: "statusp",
            staticStyle: {
              background: "#ffbb00"
            }
          }), _vm._v(" 待申请 ")]) : _vm._e(), text == 2 ? _c("div", [_c("div", {
            staticClass: "statusp",
            staticStyle: {
              background: "#4686ff"
            }
          }), _vm._v(" 审批中 ")]) : _vm._e(), text == 3 ? _c("div", [_c("div", {
            staticClass: "statusp",
            staticStyle: {
              background: "#ff7085"
            }
          }), _vm._v(" 已驳回 ")]) : _vm._e(), text == 4 ? _c("div", [record.payStatus == 1 ? _c("div", [_c("div", {
            staticClass: "statusp",
            staticStyle: {
              background: "#32ccaa"
            }
          }), _vm._v(" 待发放 ")]) : _vm._e(), record.payStatus == 5 ? _c("div", [_c("div", {
            staticClass: "statusp",
            staticStyle: {
              background: "#ffbb00"
            }
          }), _vm._v(" 发放中 ")]) : _vm._e()]) : _vm._e(), text == 5 ? _c("div", [record.payStatus == 2 ? _c("div", [_c("div", {
            staticClass: "statusp",
            staticStyle: {
              background: "#32ccaa"
            }
          }), _vm._v(" 发放成功 ")]) : _vm._e(), record.payStatus == 3 ? _c("div", [_c("div", {
            staticClass: "statusp",
            staticStyle: {
              background: "#ff7085"
            }
          }), _vm._v(" 发放失败 ")]) : _vm._e(), record.payStatus == 4 ? _c("div", [record.isDispose ? _c("div", [_c("div", {
            staticClass: "statusp",
            staticStyle: {
              background: "#ff7085"
            }
          }), _vm._v(" 异常已处理 ")]) : _c("div", [_c("div", {
            staticClass: "statusp",
            staticStyle: {
              background: "#ff7085"
            }
          }), _vm._v(" 发放异常 ")])]) : _vm._e()]) : _vm._e()]) : col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [record.subjectStatus == 2 || record.subjectStatus == 3 || record.subjectStatus == 4 || record.subjectStatus == 5 ? _c("a", {
            on: {
              click: function click($event) {
                return _vm.onInfo(record);
              }
            }
          }, [_vm._v("详情")]) : _vm._e(), record.subjectStatus == 3 ? _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }) : _vm._e(), record.subjectStatus == 3 ? _c("a", {
            on: {
              click: function click($event) {
                return _vm.onAgain(record);
              }
            }
          }, [_vm._v("重新发起")]) : _vm._e(), record.subjectStatus == 1 ? _c("a", {
            on: {
              click: function click($event) {
                return _vm.onEdit(record);
              }
            }
          }, [_vm._v("编辑")]) : _vm._e(), record.subjectStatus == 1 ? _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }) : _vm._e(), _c("a-popconfirm", {
            attrs: {
              title: "\u786E\u8BA4\u8981\u5220\u9664\u5417\uFF1F",
              "ok-text": "确认",
              "cancel-text": "取消"
            },
            on: {
              confirm: function confirm($event) {
                return _vm.onClose(record.id);
              }
            }
          }, [record.subjectStatus == 1 ? _c("a", [_vm._v("删除")]) : _vm._e()])], 1) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"],
      "page-size": _vm.pageSize
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1), _c("select-way", {
    attrs: {
      visible: _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      changeSW: _vm.changeSW
    }
  }), _c("editModel", {
    attrs: {
      visible: _vm.visibleEdit,
      dataJson: _vm.editJson
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleEdit = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };