import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
/*
 * @description: 头部描述
 * @Author: Coder
 * @Date: 2019-12-31 10:05:42
 * @LastEditors  : Coder
 */
/* 公共处理方法 */
function needList(data, chiders) {
  var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "id";
  data = Array.from(data);
  return data.map(function (item) {
    item.title = item.name;
    item.value = item[value];
    item.key = item.id;
    item.children = item[chiders];
    if (item[chiders]) needList(item[chiders], chiders, value);
    return item;
  });
}
export { needList };