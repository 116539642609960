import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var detailPagesDict = {
  "staff/detail": "/staff/employee",
  // 花名册-详情
  "staff/employee/todayAdd": "/staff/employee",
  // 花名册-今日新增
  "staff/upload": "/staff/employee",
  // 花名册-批量导入新员工
  "staff/batchEdit": "/staff/employee",
  // 花名册-批量修改员工信息
  "staff/personalInfo": "/staff/edit",
  // 员工设置-个人信息设置
  "staff/socialSecurity": "/staff/edit",
  // 员工设置-工资社保设置
  "staff/information": "/staff/edit",
  // 员工设置-材料附件设置
  "staff/entryTemplateSet": "/staff/edit",
  // 员工设置-入职模板设置
  "staff/hiredEntryTemplateSe": "/staff/edit",
  // 员工设置-待入职模板设置
  "staff/edit/newTemplate": "/staff/edit",
  // 员工设置-入职、待入职模板编辑

  "contract/contract-set": "/contract/elecontract",
  // 签署记录-设置
  "contract/setting": "/contract/type",
  // 发起合同-设置
  "contract/type/update": "/contract/type",
  // 发起合同-模板编辑
  "contract/type/seal": "/contract/type",
  // 发起合同-设置印章位置
  "contract/type/initiateSign": "/contract/type",
  // 发起合同-发起合同
  "contract/stamp/update": "/contract/stamp",
  // 印章管理-编辑
  "contract/stamp/create": "/contract/stamp",
  // 印章管理-添加
  "contract/stamp/verify": "/contract/stamp",
  // 印章管理-认证

  "shebao/shebaoManage/shebaoDetail": "/shebao/shebaoManage",
  // 参保管理-详情
  "shebao/myClientele/imports/batchShebaoImports": "/shebao/shebaoManage",
  // 参保管理-详情-批量修改
  "shebao/shebaoManage/shebaoZengJianY": "/shebao/shebaoManage",
  // 参保管理-增减员计划
  "shebao/shebaoMethod/addMethod": "/shebao/shebaoMethod",
  // 参保方案-添加
  "shebao/shebaoMethod/methodDetail": "/shebao/shebaoMethod",
  // 参保方案-查看
  "shebao/shebaoMethod/buCha2": "/shebao/shebaoMethod",
  // 参保方案-查看-补差
  "shebao/shebaoMethod/editMethod": "/shebao/shebaoMethod",
  // 参保方案-查看-编辑
  "shebao/shebaoMingDan/detailed": "/shebao/shebaoMingDan",
  // 社保名单-详情
  "shebao/detail": "/shebao/check",
  // 社保校验-详情

  "wages/file/outsourcingDetail": "/wages/file",
  // 薪资档案-待定薪档案-定薪
  "wages/file/batchOutsourcing": "/wages/file",
  // 薪资档案-待定薪档案-批量定薪
  "wages/file/editDetail": "/wages/file",
  // 薪资档案-固定薪资档案-调薪
  "wages/file/detail": "/wages/file",
  // 薪资档案-固定薪资档案-薪资详情
  "wages/file/upload": "/wages/file",
  // 薪资档案-固定薪资档案-导入定薪
  "wages/file/template": "/wages/file",
  // 薪资档案-固定薪资档案-定薪模板
  "wages/file/wagesRules": "/wages/file",
  // 薪资档案-工价薪资档案-工价规则
  "wages/file/laborRules": "/wages/file",
  // 薪资档案-工价薪资档案-招工劳务费规则
  "wages/add-payslip": "/wages/payslip",
  // 电子工资条-开通
  "wages/template/add": "/wages/template",
  // 工资表模板-添加
  "wages/template/edit": "/wages/template",
  // 工资表模板-编辑
  "wages/set/group/add": "/wages/set",
  // 薪资设置-薪资组-添加、编辑
  "wages/set/rule/edit": "/wages/set",
  // 薪资设置-计税规则-编辑
  "wages/monthly/details": "/wages/monthly",
  // 月工资表-详情
  "wages/yuegongzibiao/details": "/wages/yuegongzibiao",
  // 月工资表-详情
  "/wages/gongzibiaomuban/edit": "/wages/gongzibiaomuban",
  // 月工资表模板

  "tax/special/importSpecial": "/tax/special",
  // 专项附加扣除-批量导入
  "tax/importTax": "/tax/incomeTax",
  // 个税累计-批量导入

  "check/checkCard/cardDetail": "/check/checkCard",
  // 考勤打卡-考勤打卡表-详情
  "check/checkCard/imports": "/check/checkCard",
  // 考勤打卡-工时导入表-导入
  "check/checkSet/checkEditorRules": "/check/checkSet",
  // 考勤设置-上下班打卡-添加、编辑

  "approval/detail": "/approval/list",
  // 审批列表-详情
  newApproval: "/approval/setting",
  // 审批设置-添加、编辑

  "notice/enterprise/createNotice": "/notice/enterprise",
  // 企业公告-添加、编辑
  "notice/person/createNotice": "/notice/person",
  // 个人通知-添加
  "notice/person/detail": "/notice/person" // 个人通知-编辑
};

export function getRootPage(thePath) {
  var pages = Object.keys(detailPagesDict);
  for (var i = 0; i < pages.length; i++) {
    if (thePath.includes(pages[i])) {
      return detailPagesDict[pages[i]];
    }
  }
  return false;
}