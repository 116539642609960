import ListContent from "./listContent";
export default {
  inject: ["reload"],
  name: "",
  components: {
    ListContent: ListContent
  },
  data: function data() {
    return {
      currentKey: 1
    };
  },
  mounted: function mounted() {
    var st = this.$route.query.operateFromShortcut;
    if (st === "yichuli") {
      this.changeSort(2);
    } else if (st === "wofaqi") {
      this.changeSort(3);
    } else if (st === "chaosongwo") {
      this.changeSort(4);
    }
  },
  methods: {
    changeSort: function changeSort(e) {
      this.currentKey = e;
    }
  }
};