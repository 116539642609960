import request from "@/utils/request";
export function postTransferAccountsBatches(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/pagePost",
    method: "POST",
    data: data
  });
}
export function postStatisticsBatchStatusBySite(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/statisticsBatchStatusByCompanyIds",
    method: "POST",
    data: data
  });
}
export function getTransferAccountsBatches(params) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches",
    method: "GET",
    params: params
  });
}
export function getStatisticsBatchStatusBySite(params) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/statisticsBatchStatusBySite",
    method: "GET",
    params: params
  });
}
export function delTransferAccountsBatches(transferAccountsDetailId) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/".concat(transferAccountsDetailId),
    method: "DELETE"
  });
}