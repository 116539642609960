import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
var columns = [{
  title: "姓名",
  dataIndex: "name",
  scopedSlots: {
    customRender: "customName"
  },
  ellipsis: true
}, {
  title: "参保城市",
  dataIndex: "socCityName",
  ellipsis: true
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200,
  ellipsis: true
}, {
  title: "参保方案",
  dataIndex: "socProgName",
  scopedSlots: {
    customRender: "socProgName"
  },
  ellipsis: true
}, {
  title: "社保起缴时间",
  dataIndex: "socStartDate",
  scopedSlots: {
    customRender: "socStartDate"
  },
  ellipsis: true
}, {
  title: "住房公积金起缴时间",
  dataIndex: "fundStartDate",
  scopedSlots: {
    customRender: "fundStartDate"
  },
  ellipsis: true
}, {
  title: "指派公司",
  dataIndex: "assignCompanyName",
  scopedSlots: {
    customRender: "assignCompanyName"
  },
  ellipsis: true
}, {
  title: "操作",
  dataIndex: "",
  scopedSlots: {
    customRender: "action"
  },
  ellipsis: true
}];
var innerColumns = [{
  title: "缴纳项目",
  dataIndex: "itemName",
  ellipsis: true
}, {
  title: "缴费基数",
  dataIndex: "cardinalityPerson",
  ellipsis: true
}, {
  title: "个人比例",
  dataIndex: "persentPersonal",
  scopedSlots: {
    customRender: "persentPersonal"
  },
  ellipsis: true
}, {
  title: "公司比例",
  dataIndex: "persentCompany",
  scopedSlots: {
    customRender: "persentCompany"
  },
  ellipsis: true
}, {
  title: "个人缴费",
  dataIndex: "amountPersonal",
  ellipsis: true
}, {
  title: "公司交费",
  dataIndex: "amountCompany",
  ellipsis: true
}, {
  title: "缴费合计",
  dataIndex: "amountAll",
  ellipsis: true
}];
import step from "./common/step";
import navItem from "./common/nav-item";
import editorModal from "./common/editor-modal";
import { mapState } from "vuex";
export default {
  name: "ShebaoDetailList",
  components: {
    //  step,

    navItem: navItem,
    editorModal: editorModal
  },
  data: function data() {
    return {
      progList: [],
      editorShow: false,
      editorOptions: {},
      columns: columns,
      innerColumns: innerColumns,
      tabs: {},
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      titles: {},
      dataSource: [],
      params: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  created: function created() {
    this.getList();
    this.getNavs();
    this.getcbList();
  },
  methods: {
    // 获取参保方案
    getcbList: function getcbList() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/socProgs/listAllRelation"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.progList = data;
      });
    },
    hasPaidan: function hasPaidan(name) {
      return this.progList.find(function (item) {
        return item.progName === name;
      });
    },
    editorCb: function editorCb(e) {
      var _this2 = this;
      this.editorShow = false;
      if (Object.keys(e.options).length) {
        this.editorOptions = e.options;
        this.editorOptions.itemUpdateList = this.editorOptions.socDispatchEmpItemList.map(function (item) {
          return {
            baseAmount: item.cardinalityDefault,
            itemName: item.itemName
          };
        });
      }
      if (e.onOk) {
        this.$request({
          url: "/hr/api/v1/socDispatchEmpAdds/updateEmpAndItem",
          method: "put",
          data: e.options
        }).then(function (_ref2) {
          var success = _ref2.success;
          if (success) {
            _this2.getList();
            _this2.getNavs();
          }
        });
      }
    },
    getList: function getList() {
      var _this3 = this;
      this.$request({
        url: "/hr/api/v1/socDispatchEmpAdds",
        params: _objectSpread(_objectSpread({}, this.params), {}, {
          socDispatchId: this.$route.query.id,
          showItem: true
        })
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this3.dataSource = data.entities;
        _this3.pagination.total = data.entityCount;
      });
    },
    getNavs: function getNavs() {
      var _this4 = this;
      this.$request({
        url: "/hr/api/v1/socDispatches/findDetails/".concat(this.$route.query.id)
      }).then(function (_ref4) {
        var data = _ref4.data;
        _this4.tabs = data;
      });
    },
    handleNav: function handleNav(e) {},
    selectItem: function selectItem(e) {},
    handlePage: function handlePage(e) {
      this.params.pageNo = e.current;
      this.getList();
    },
    // 重新上传
    reUpload: function reUpload() {
      this.$router.push({
        path: "/shebao/shebaoPaidan/shebaoUpload"
      });
    },
    // 上传
    upload: function upload() {
      var _this5 = this;
      this.$request({
        url: "/hr/api/v1/socDispatches/dispatchSend/".concat(this.$route.query.id),
        method: "put"
      }).then(function (_ref5) {
        var success = _ref5.success,
          data = _ref5.data;
        if (success) {
          _this5.$router.push({
            path: "/shebao/shebaoPaidan/shebao-detail-add-third",
            query: _objectSpread(_objectSpread({
              unSendCount: _this5.pagination.total
            }, _this5.$route.query), {}, {
              data: data
            })
          });
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
        throw err;
      });
    },
    expandChange: function expandChange(expanded, record) {},
    // 编辑
    editor: function editor(type) {
      this.editorShow = true;
      this.editorOptions = type;
    },
    // 参保记录
    history: function history(e) {},
    // 删除item
    deleteItem: function deleteItem(_ref6) {
      var _this6 = this;
      var id = _ref6.id;
      this.$confirm({
        title: "确定删除?",
        okType: "danger",
        onOk: function onOk() {
          _this6.$request({
            url: "/hr/api/v1/socDispatchEmpAdds/".concat(id),
            method: "delete"
          }).then(function (_ref7) {
            var success = _ref7.success;
            if (success) {
              _this6.$notification["success"]({
                message: "删除成功"
              });
              _this6.getList();
              _this6.getNavs();
            }
          });
        }
      });
    }
  }
};