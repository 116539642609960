import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.to-string.js";
import { formatDate } from "@/utils/index";
import { salaryCalculateRules, getSalaryCalculateRuleGroupsPage, editSalaryCalculateRuleGroups, salaryCalculateRulesDelete, salaryCalculateRulesEdits, selectEmpInfoVoBySalaryCalculateRuleGroupId, selectEmpInfoVoBySalaryCalculateRuleGroupId2, addSalaryCalculateRuleGroups, salaryRulesListByPage as _salaryRulesListByPage, deleteRuleGroupId, issueGroup, deleteItems as _deleteItems } from "@/api/outsourcing/outrules";
import { findCompanyGroup as _findCompanyGroup } from "@/api/company/contract";
var columns = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: 100
}, {
  title: "联系电话",
  dataIndex: "phone",
  key: "phone",
  width: 120
}, {
  title: "最近入职日期",
  dataIndex: "joinDate",
  key: "joinDate",
  customRender: function customRender(text, record, index) {
    return formatDate(text);
  },
  width: 120,
  scopedSlots: {
    customRender: "joinDate"
  }
}, {
  title: "当前用工单位",
  dataIndex: "workingUnitName",
  key: "workingUnitName",
  width: 100
}, {
  title: "渠道来源",
  dataIndex: "channelName",
  key: "channelName",
  width: 100,
  scopedSlots: {
    customRender: "channelName"
  }
}];
var columns2 = [{
  title: "生效时间",
  dataIndex: "timeShow",
  key: "timeShow",
  width: 100
}, {
  title: "基础规则",
  dataIndex: "hourAmountRuleShow",
  key: "hourAmountRuleShow1",
  width: 120
}, {
  title: "离职规则",
  dataIndex: "leaveOfficeRuleShow",
  key: "leaveOfficeRuleShow",
  width: 120
}, {
  title: "操作",
  dataIndex: "action",
  key: "action",
  width: 100,
  align: "center",
  scopedSlots: {
    customRender: "action"
  }
}];
import moment from "moment";
import { isContainByString } from "@/utils/index";
import { mapState } from "vuex";
export default {
  data: function data() {
    var _this = this;
    return {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 16
      },
      pageSizeOptions: ["10", "20", "50", "100"],
      current: 1,
      pageSize: 10,
      total: 0,
      isContainByString: isContainByString,
      value: "",
      columns2: columns2,
      contractList: [],
      companyDataList: [],
      functionBox: "",
      flatDataList: [],
      rangeDate: undefined,
      detaileCopy: {},
      startDatePick: undefined,
      specialCostDouble: undefined,
      EndAstrict: {},
      calculation: false,
      calculation2: false,
      welfareType: null,
      issueModalVisible: false,
      ruleNameInput: false,
      spinning: false,
      nameOrIdCard: "",
      replaceFields: {
        children: "companyList",
        title: "name",
        key: "uuid",
        value: "uuid"
      },
      columns: columns,
      checked: false,
      // 一次性红包
      checked1: false,
      // 离职
      deleteVisible: false,
      deleteVisible2: false,
      companyId: this.$store.state.hr.companyId,
      dataList: [],
      dataList2: [],
      // 列表数据
      ruleType: "1",
      ruleType2: "1",
      ruleType2Copy: "1",
      numList: [],
      leaveList: [],
      labourList: [],
      hourAmountDouble: null,
      dissatisfyMonth: false,
      endAstrict: false,
      showName: false,
      companyList: [],
      salaryCalculateRuleId: "",
      option: [{
        label: "月",
        value: 1
      }, {
        label: "天",
        value: 2
      }, {
        label: "工时",
        value: 3
      }],
      ruleTypeCopy: "1",
      effectType: null,
      demo: {},
      type: null,
      wagesdetail: null,
      departureDetail: null,
      wagesdetailCopy: null,
      departureDetailCopy: null,
      lastLimit: false,
      allState: false,
      lastCheckState: false,
      listIndex: 0,
      title: "",
      isAdd: false,
      visible: false,
      welfareChecked: false,
      welfareChecked2: false,
      RedPacketRule: {},
      RedPacketRule2: {},
      dataListCopy: "",
      dataListCopy2: "",
      deleteItemId: "",
      placement: "right",
      editId: null,
      name: "",
      list: [],
      detaile: null,
      timeStart: {},
      deleteState: false,
      paginationOpt2: {
        current: 1,
        defaultCurrent: 1,
        // 默认当前页数
        // defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt2.current = 1;
          _this.paginationOpt2.defaultCurrent = 1;
          _this.paginationOpt2.defaultPageSize = pageSize;
          _this.redPacketList(); // 显示列表的接口名称
        },

        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt2.current = current;
          _this.paginationOpt2.defaultCurrent = current;
          _this.paginationOpt2.defaultPageSize = size;
          _this.redPacketList();
        }
      },
      paginationOpt: {
        current: 1,
        defaultCurrent: 1,
        // 默认当前页数
        // defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.current = 1;
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;
          _this.salaryRulesListByPage(); // 显示列表的接口名称
        },

        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.current = current;
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;
          _this.salaryRulesListByPage();
        }
      }
    };
  },
  filters: {
    filterType: function filterType(val) {
      if (val === 1) {
        return "月";
      } else if (val === 2) {
        return "天";
      } else if (val === 3) {
        return "工时";
      } else {
        return "";
      }
    },
    filterType2: function filterType2(val) {
      if (val === 1) {
        return "月";
      } else if (val === 3) {
        return "天";
      } else {
        return "";
      }
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState(["app", "hr"])), {}, {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar.opened;
    }
  }),
  watch: {
    sidebar: function sidebar(newVal) {
      this.functionBox = newVal ? "functionBox2" : "functionBox3";
    },
    welfareChecked: function welfareChecked(e) {
      if (e === false) {
        this.RedPacketRule = {};
      }
    },
    welfareChecked2: function welfareChecked2(e) {
      if (e === false) {
        this.RedPacketRule2 = {};
      }
    },
    checked1: function checked1(e) {
      if (e === false) {
        this.specialCostDouble = null;
      }
    },
    lastLimit: function lastLimit(e) {
      if (e === false) {
        this.EndAstrict = {};
      }
    },
    calculation: function calculation(e) {
      if (e === false) {
        this.ruleType = null;
        this.wagesdetail = null;
      } else {
        this.ruleType = this.ruleTypeCopy;
        this.wagesdetail = this.wagesdetailCopy;
      }
    },
    calculation2: function calculation2(e) {
      if (e === false) {
        this.ruleType2 = null;
        this.departureDetail = null;
      } else {
        this.ruleType2 = this.ruleType2Copy;
        this.departureDetail = this.departureDetailCopy;
      }
    }
  },
  created: function created() {
    this.ruleList();
    this.findCompanyGroup();
    this.functionBox = this.isContainByString("file") ? this.$store.state.app.sidebar.opened ? "functionBox2" : "functionBox3" : "functionBox";
    if (this.isContainByString("file")) {
      this.columns = [{
        title: "姓名",
        dataIndex: "name",
        key: "name",
        width: 100
      }, {
        title: "联系电话",
        dataIndex: "phone",
        key: "phone",
        width: 120
      }, {
        title: "最近入职日期",
        dataIndex: "joinDate",
        key: "joinDate",
        customRender: function customRender(text, record, index) {
          return formatDate(text);
        },
        width: 120,
        scopedSlots: {
          customRender: "joinDate"
        }
      }, {
        title: "渠道来源",
        dataIndex: "channelName",
        key: "channelName",
        width: 100,
        scopedSlots: {
          customRender: "channelName"
        }
      }];
    }
  },
  methods: {
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.current = current;
      this.pageSize = pageSize;
      this.ruleList();
    },
    currentChange: function currentChange(current, pageSize) {
      this.current = current;
      this.pageSize = pageSize;
      this.ruleList();
    },
    cancelRule: function cancelRule() {
      this.detaile = this.detaileCopy;
      this.$set(this.detaile, "name", this.detaileCopy.name);
      this.detaile.name = this.detaileCopy.name;
      this.ruleNameInput = false;
      this.dataList = JSON.parse(JSON.stringify(this.dataListCopy));
      this.dataList2 = JSON.parse(JSON.stringify(this.dataList2Copy));
    },
    deleteItems: function deleteItems() {
      var _this2 = this;
      var deleteList = [];
      this.list.forEach(function (item, index) {
        if (item.isChecked) {
          deleteList.push(item.id);
        }
      });
      this.$confirm({
        title: "注意",
        content: "确认批量删除已选规则吗？",
        okText: "确认",
        cancelText: "取消",
        onCancel: function onCancel() {
          console.log("Cancel");
        },
        onOk: function onOk() {
          _deleteItems(deleteList).then(function (res) {
            _this2.ruleList();
            _this2.$notification["success"]({
              message: "成功",
              description: "删除成功"
            });
            _this2.lastCheckState = false;
            _this2.deleteState = false;
          });
        }
      });
    },
    checkCancel: function checkCancel() {
      this.lastCheckState = false;
      this.deleteState = false;
      this.allState = false;
      this.list.forEach(function (item, index) {
        item.isChecked = false;
      });
    },
    itemCheckChange: function itemCheckChange() {
      var num = 0;
      this.list.forEach(function (item, index) {
        if (item.isChecked) {
          num++;
        }
      });
      if (num === this.list.length) {
        this.allState = true;
      } else {
        this.allState = false;
      }
      if (num > 0) {
        this.lastCheckState = true;
      } else {
        this.lastCheckState = false;
      }
    },
    handleMenuClick: function handleMenuClick(e) {
      switch (e.key) {
        case "1":
          this.showModal();
          break;
        case "2":
          this.deleteItem();
          break;
      }
    },
    deleteItem: function deleteItem() {
      this.deleteState = true;
    },
    onCheckChange: function onCheckChange(e) {
      this.list.forEach(function (item, index) {
        item.isChecked = e.target.checked;
      });
      this.list = JSON.parse(JSON.stringify(this.list));
      var num = 0;
      this.list.forEach(function (item, index) {
        if (item.isChecked) {
          num++;
        }
      });
      if (num > 0) {
        this.lastCheckState = true;
      } else {
        this.lastCheckState = false;
      }
    },
    unitChange: function unitChange(e) {
      if (this.timeStart.unit === 2) {
        this.ruleType = "1";
        this.ruleType2 = "1";
        this.ruleTypeCopy = "1";
        this.ruleType2Copy = "1";
      } else if (this.timeStart.unit === 3) {
        this.ruleType = "2";
        this.ruleType2 = "2";
        this.ruleTypeCopy = "2";
        this.ruleType2Copy = "2";
      }
    },
    unitChange2: function unitChange2(e) {
      this.ruleType2 = "";
      this.ruleType2Copy = "";
      this.$set(this.RedPacketRule2, "units", "");
    },
    addTreeSlot: function addTreeSlot(data) {
      var _this3 = this;
      if (!data) {
        return [];
      }
      data.forEach(function (el) {
        el.scopedSlots = {
          title: "custom"
        };
        _this3.addTreeSlot(el.companyList);
      });
      return data;
    },
    findCompanyGroup: function findCompanyGroup(e) {
      var _this4 = this;
      _findCompanyGroup({
        name: e || null
      }).then(function (res) {
        _this4.companyDataList = _this4.addTreeSlot(res.data);
        _this4.flatten(_this4.companyDataList);
        _this4.companyList = JSON.parse(JSON.stringify(_this4.companyList));
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 递归处理为扁平数组
    flatten: function flatten(list) {
      var _this5 = this;
      if (list !== null) {
        list.forEach(function (item) {
          _this5.flatDataList.push(item);
          _this5.flatten(item.companyList);
        });
      }
    },
    treeSearch: function treeSearch(e) {
      this.findCompanyGroup(e);
    },
    showModal: function showModal() {
      this.issueModalVisible = true;
    },
    handleCancelIssueModal: function handleCancelIssueModal() {
      this.issueModalVisible = false;
      // 已选的
      this.contractList = [];
      // 已选的公司数据
      this.companyList = [];
    },
    confirmIssueModal: function confirmIssueModal() {
      var _this6 = this;
      if (this.companyList.length === 0) {
        this.$notification["error"]({
          message: "错误",
          description: "请选择需要下发的公司"
        });
        return;
      }
      if (this.contractList.length === 0) {
        this.$notification["error"]({
          message: "错误",
          description: "请选择需要下发的规则"
        });
        return;
      }
      var companyArr = [];
      this.companyList.forEach(function (item) {
        _this6.flatDataList.forEach(function (item2, index2) {
          if (item2.uuid && item2.uuid === item.value) {
            companyArr.push(item2.id);
          }
        });
      });
      var data = {
        companyIdList: companyArr,
        salaryCalculateRuleGroupIdList: this.contractList
      };
      issueGroup(data).then(function (res) {
        _this6.issueModalVisible = false;
        _this6.$notification["success"]({
          message: "成功",
          description: "下发成功"
        });
        _this6.companyList = [];
        _this6.ruleList();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      // TODO 下发接口
    },
    showDelete: function showDelete(e) {
      this.deleteItemId = e.id;
      this.deleteVisible = true;
    },
    // 分页查询规则组下规则
    salaryRulesListByPage: function salaryRulesListByPage() {
      var _this7 = this;
      _salaryRulesListByPage({
        pageNo: this.paginationOpt.defaultCurrent,
        pageSize: this.paginationOpt.defaultPageSize,
        salaryCalculateRuleGroupId: this.salaryCalculateRuleId
      }).then(function (res) {
        _this7.dataList = res.data.entities;
        _this7.paginationOpt.total = res.data.entityCount;
      });
    },
    bothChange: function bothChange(e) {
      this.$set(this.timeStart, "startTime", moment(e[0]).format("YYYY-MM-DD"));
      this.$set(this.timeStart, "endTime", moment(e[1]).format("YYYY-MM-DD"));
    },
    onlyStartChange: function onlyStartChange(e) {
      this.$set(this.timeStart, "startTime", moment(e).format("YYYY-MM-DD"));
    },
    typeChange: function typeChange(e) {
      this.timeStart = {};
      this.$set(this.timeStart, "type", e.target.value);
    },
    effectTypeChange: function effectTypeChange(e) {
      this.timeStart = {};
      this.ruleType = "2";
      this.ruleTypeCopy = "2";
      this.clear();
      this.timeStart.type = undefined;
      this.calculation = false;
      this.calculation2 = false;
      this.ruleType2 = "2";
      this.ruleType2Copy = "2";
      this.welfareChecked = false;
      this.welfareChecked2 = false;
      this.lastLimit = false;
      this.checked1 = false;
      this.dissatisfyMonth = false;
      this.rangeDate = null;
      this.startDatePick = null;
    },
    limitNumber: function limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      } else {
        return 0;
      }
    },
    // 搜索规则
    onSearch: function onSearch(value) {
      this.ruleNameInput = false;
      this.value = value;
      this.ruleList();
    },
    // 搜索规则
    onSearch1: function onSearch1(value) {
      this.nameOrIdCard = value;
      this.paginationOpt2.current = 1;
      this.redPacketList();
    },
    // 获取列表和右边数据
    ruleList: function ruleList() {
      var _this8 = this;
      this.spinning = true;
      this.lastCheckState = false;
      this.deleteState = false;
      this.allState = false;
      if (this.list.length) {
        this.list.forEach(function (item, index) {
          item.isChecked = false;
        });
      }
      getSalaryCalculateRuleGroupsPage({
        companyId: this.isContainByString("file") ? this.companyId : "0",
        name: this.value,
        ruleType: 1,
        isShowPeopleNumber: true,
        pageNo: this.current,
        pageSize: this.pageSize
      }).then(function (res) {
        _this8.list = res.data.entities;
        _this8.total = res.data.entityCount;
        if (res.data.entities.length === 0 && _this8.current > 1) {
          // 当本页非第一页（比第一页大）时，删除最后一条后台会返回当页页码，
          // 但是本页数据为空，table组件会展示为当前页的上一页数据为空，需要加加判断再拉一下上页数据
          _this8.current = _this8.current - 1;
          _this8.pageSize = 10;
          _this8.ruleList();
        } else {
          if (_this8.list.length > 0) {
            _this8.detaile = JSON.parse(JSON.stringify(_this8.list[0]));
            _this8.salaryCalculateRuleId = res.data.entities[0].id;
            _this8.salaryRulesListByPage();
            if (_this8.isContainByString("file")) {
              selectEmpInfoVoBySalaryCalculateRuleGroupId({
                nameOrIdCard: _this8.nameOrIdCard,
                pageNo: _this8.paginationOpt2.defaultCurrent,
                pageSize: _this8.paginationOpt2.defaultPageSize,
                salaryCalculateRuleId: _this8.salaryCalculateRuleId
              }).then(function (res) {
                _this8.dataList2 = res.data.entities;
                _this8.paginationOpt2.total = res.data.entityCount;
                _this8.spinning = false;
              });
            } else {
              selectEmpInfoVoBySalaryCalculateRuleGroupId2({
                nameOrIdCard: _this8.nameOrIdCard,
                pageNo: _this8.paginationOpt2.defaultCurrent,
                pageSize: _this8.paginationOpt2.defaultPageSize,
                salaryCalculateRuleId: _this8.salaryCalculateRuleId
              }).then(function (res) {
                _this8.dataList2 = res.data.entities;
                _this8.paginationOpt2.total = res.data.entityCount;
                _this8.spinning = false;
              });
            }
            _this8.spinning = false;
          } else {
            _this8.spinning = false;
            _this8.detaile = null;
          }
        }
      });
    },
    redPacketList: function redPacketList() {
      var _this9 = this;
      this.spinning = true;
      if (this.isContainByString("file")) {
        selectEmpInfoVoBySalaryCalculateRuleGroupId({
          nameOrIdCard: this.nameOrIdCard,
          pageNo: this.paginationOpt2.current,
          pageSize: this.paginationOpt2.defaultPageSize,
          salaryCalculateRuleId: this.salaryCalculateRuleId
        }).then(function (res) {
          _this9.dataList2 = res.data.entities;
          _this9.paginationOpt2.total = res.data.entityCount;
          _this9.spinning = false;
        });
      } else {
        selectEmpInfoVoBySalaryCalculateRuleGroupId2({
          nameOrIdCard: this.nameOrIdCard,
          pageNo: this.paginationOpt2.current,
          pageSize: this.paginationOpt2.defaultPageSize,
          salaryCalculateRuleId: this.salaryCalculateRuleId
        }).then(function (res) {
          _this9.dataList2 = res.data.entities;
          _this9.paginationOpt2.total = res.data.entityCount;
          _this9.spinning = false;
        });
      }
    },
    // 获取数组里的每一个时间
    onDateChange: function onDateChange(date, dateString, item) {
      item.time = dateString;
    },
    // 删除规则
    deleteOk2: function deleteOk2() {
      var _this10 = this;
      deleteRuleGroupId(this.salaryCalculateRuleId).then(function (res) {
        if (res.code === 200) {
          _this10.$message.success("删除成功");
          _this10.deleteVisible2 = false;
          _this10.listIndex = 0;
          _this10.ruleList();
        }
      });
    },
    deleteOk: function deleteOk() {
      var _this11 = this;
      salaryCalculateRulesDelete(this.deleteItemId).then(function (res) {
        if (res.code === 200) {
          _this11.$message.success("删除成功");
          _this11.deleteVisible = false;
          _this11.listIndex = 0;
          // this.ruleList();
          if (_this11.isContainByString("file")) {
            editSalaryCalculateRuleGroups({
              companyId: _this11.companyId,
              name: _this11.detaile.name,
              id: _this11.detaile.id,
              ruleType: 1,
              source: "0"
            }).then(function (res) {
              _this11.ruleList();
            });
          } else {
            editSalaryCalculateRuleGroups({
              companyId: "0",
              name: _this11.detaile.name,
              id: _this11.detaile.id,
              ruleType: 1
            }).then(function (res) {
              _this11.ruleList();
            });
            _this11.ruleList();
          }
        }
      });
    },
    // 左侧侧边栏内容点击事件
    onClickRule: function onClickRule(item, index) {
      var _this12 = this;
      this.spinning = true;
      this.listIndex = index;
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultCurrent = 1;
      this.clear();
      this.detaile = JSON.parse(JSON.stringify(item));
      this.spinning = false;
      this.ruleNameInput = false;
      if (item) {
        this.salaryCalculateRuleId = item.id;
        this.salaryRulesListByPage();
      }
      if (this.isContainByString("file")) {
        selectEmpInfoVoBySalaryCalculateRuleGroupId({
          nameOrIdCard: this.nameOrIdCard,
          pageNo: this.paginationOpt2.defaultCurrent,
          pageSize: this.paginationOpt2.defaultPageSize,
          salaryCalculateRuleId: this.salaryCalculateRuleId
        }).then(function (res) {
          _this12.dataList2 = res.data.entities;
          _this12.paginationOpt2.total = res.data.entityCount;
          _this12.spinning = false;
        });
      } else {
        selectEmpInfoVoBySalaryCalculateRuleGroupId2({
          nameOrIdCard: this.nameOrIdCard,
          pageNo: this.paginationOpt2.defaultCurrent,
          pageSize: this.paginationOpt2.defaultPageSize,
          salaryCalculateRuleId: this.salaryCalculateRuleId
        }).then(function (res) {
          _this12.dataList2 = res.data.entities;
          _this12.paginationOpt2.total = res.data.entityCount;
          _this12.spinning = false;
        });
      }
    },
    // 清空内容
    clear: function clear() {
      this.name = "";
      this.hourAmountDouble = "";
      this.checked = false;
      this.checked1 = false;
      this.labourList = [];
      this.numList = [];
      this.leaveList = [];
    },
    // 添加事件
    onCreate: function onCreate() {
      var _this13 = this;
      this.isAdd = true;
      this.ruleNameInput = true;
      this.detaileCopy = this.detaile;
      this.detaile = {};
      this.dataListCopy = JSON.parse(JSON.stringify(this.dataList));
      this.dataList2Copy = JSON.parse(JSON.stringify(this.dataList2));
      this.dataList = JSON.parse(JSON.stringify([]));
      this.dataList2 = JSON.parse(JSON.stringify([]));
      this.paginationOpt.total = 0;
      this.$nextTick(function () {
        _this13.$refs.nameInput.focus();
      });
    },
    onCreateItem: function onCreateItem() {
      this.title = "新增工价规则";
      this.visible = true;
      this.ruleType = "2";
      this.ruleTypeCopy = "2";
      this.clear();
      this.effectType = undefined;
      this.timeStart.type = undefined;
      this.calculation = false;
      this.calculation2 = false;
      this.ruleType2 = "2";
      this.ruleType2Copy = "2";
      this.welfareChecked = false;
      this.welfareChecked2 = false;
      this.lastLimit = false;
      this.checked1 = false;
      this.dissatisfyMonth = false;
      this.rangeDate = null;
      this.startDatePick = null;
    },
    addRuleOk: function addRuleOk() {
      var _this14 = this;
      if (this.detaile.name) {
        if (this.isAdd === true) {
          addSalaryCalculateRuleGroups({
            companyId: this.isContainByString("file") ? this.companyId : "0",
            name: this.detaile.name,
            ruleType: 1
          }).then(function (res) {
            _this14.ruleNameInput = false;
            _this14.$message.success("添加成功");
            _this14.isAdd = false;
            _this14.listIndex = 0;
            _this14.ruleList();
          });
        } else {
          editSalaryCalculateRuleGroups({
            companyId: this.isContainByString("file") ? this.companyId : "0",
            name: this.detaile.name,
            id: this.detaile.id,
            ruleType: 1,
            source: this.isContainByString("file") ? undefined : "0"
          }).then(function (res) {
            _this14.ruleNameInput = false;
            _this14.$message.success("修改成功");
            _this14.isAdd = false;
            _this14.listIndex = 0;
            _this14.ruleList();
          });
        }
      } else {
        this.$notification["error"]({
          message: "错误",
          description: "规则组名称不能为空"
        });
      }
    },
    editRuleName: function editRuleName() {
      var _this15 = this;
      this.ruleNameInput = true;
      this.detaileCopy = JSON.parse(JSON.stringify(this.detaile));
      this.dataListCopy = JSON.parse(JSON.stringify(this.dataList));
      this.dataList2Copy = JSON.parse(JSON.stringify(this.dataList2));
      this.$nextTick(function () {
        _this15.$refs.nameInput.focus();
        _this15.isAdd = false;
      });
    },
    // 一次性红包添加事件
    onChange: function onChange(e) {
      if (e.target.checked) {
        this.numList.push({
          number: null,
          units: "",
          redPacketAmount: null
        });
        this.checked = true;
      } else {
        this.numList = [];
        this.checked = false;
      }
    },
    // 离职倒扣添加事件
    onChange1: function onChange1(e) {
      if (e.target.checked) {
        this.checked1 = true;
      } else {
        this.checked1 = false;
        this.leaveList = [];
      }
    },
    // 添加编辑规则
    onClose: function onClose() {
      var _this16 = this;
      // 限时和半长期时提示时间必填
      if ((this.effectType === "1" || this.effectType === "2") && !this.timeStart.type) {
        this.$notification["error"]({
          message: "注意",
          description: "请选择生效时间类型"
        });
        return;
      }
      // 限时+按时间
      if (this.effectType === "1" && this.timeStart.type === "1") {
        if (!this.timeStart.startNumber || !this.timeStart.endNumber || !this.timeStart.unit) {
          this.$notification["error"]({
            message: "注意",
            description: "生效时间需填写完整"
          });
          return;
        }
      }
      // 限时+按日期
      if (this.effectType === "1" && this.timeStart.type === "2") {
        if (!this.timeStart.startTime || !this.timeStart.endTime) {
          this.$notification["error"]({
            message: "注意",
            description: "生效时间需填写完整"
          });
          return;
        }
      }
      // 半长期+按时间
      if (this.effectType === "2" && this.timeStart.type === "1") {
        if (!this.timeStart.startNumber || !this.timeStart.unit) {
          this.$notification["error"]({
            message: "注意",
            description: "生效时间需填写完整"
          });
          return;
        }
      }
      // 半长期+按日期
      if (this.effectType === "2" && this.timeStart.type === "2") {
        if (!this.timeStart.startTime) {
          this.$notification["error"]({
            message: "注意",
            description: "生效时间需填写完整"
          });
          return;
        }
      }
      if (this.calculation && !this.wagesdetail) {
        this.$notification["error"]({
          message: "注意",
          description: "请填写基础规则工价计算信息"
        });
        return;
      }
      if (this.calculation2 && !this.departureDetail) {
        this.$notification["error"]({
          message: "注意",
          description: "请填写离职规则工价计算信息"
        });
        return;
      }
      if (this.welfareChecked) {
        if (this.RedPacketRule.number === null || this.RedPacketRule.number === undefined || !this.RedPacketRule.units || !this.RedPacketRule.redPacketAmount) {
          this.$notification["error"]({
            message: "注意",
            description: "请填写完整红包信息"
          });
          return;
        }
      }
      if (this.welfareChecked2) {
        if (this.RedPacketRule2.number === null || this.RedPacketRule2.number === undefined || !this.RedPacketRule2.units || !this.RedPacketRule2.redPacketAmount) {
          this.$notification["error"]({
            message: "注意",
            description: "请填写完整红包信息"
          });
          return;
        }
      }
      if (this.lastLimit) {
        if (!this.EndAstrict.number || !this.EndAstrict.unit) {
          this.$notification["error"]({
            message: "注意",
            description: "请填写完整最后阶段限制信息"
          });
          return;
        }
      }
      if (this.checked1 && !this.specialCostDouble) {
        this.$notification["error"]({
          message: "注意",
          description: "请填写离职倒扣信息"
        });
        return;
      }
      if (this.calculation2 && !this.ruleType2) {
        this.$notification["error"]({
          message: "注意",
          description: "工价计算勾选后类型必选"
        });
        return;
      }
      var redPacketRule = [];
      if (JSON.stringify(this.RedPacketRule) !== "{}") {
        this.RedPacketRule.calculateType = 1;
        redPacketRule.push(this.RedPacketRule);
      }
      if (JSON.stringify(this.RedPacketRule2) !== "{}" && JSON.stringify(this.RedPacketRule2) !== '{"units":""}') {
        this.RedPacketRule2.calculateType = 2;
        redPacketRule.push(this.RedPacketRule2);
      }
      if (this.timeStart.type) {
        this.timeStart.type = parseInt(this.timeStart.type);
      }
      var arr = {
        salaryCalculateRuleGroupId: this.salaryCalculateRuleId,
        timeType: parseInt(this.effectType),
        dissatisfyMonth: this.dissatisfyMonth,
        endAstrict: this.lastLimit,
        endAstrictJson: this.lastLimit ? this.EndAstrict : undefined,
        hourAmountRule: this.wagesdetail ? [{
          hourAmount: this.wagesdetail
        }] : undefined,
        leaveOfficeRule: this.departureDetail ? [{
          hourAmount: this.departureDetail
        }] : undefined,
        showType: this.ruleType ? parseInt(this.ruleType) : undefined,
        leaveShowType: this.ruleType2 ? parseInt(this.ruleType2) : undefined,
        ruleType: 1,
        specialCostDouble: this.specialCostDouble ? this.specialCostDouble : undefined,
        timeJson: JSON.stringify(this.timeStart) === "{}" ? undefined : this.timeStart,
        redPacketRule: redPacketRule.length > 0 ? redPacketRule : undefined
      };
      if (this.title === "新增工价规则") {
        salaryCalculateRules(arr).then(function (res) {
          if (res.code === 200) {
            _this16.$message.success("添加成功");
            _this16.listIndex = 0;
            _this16.visible = false;
            if (_this16.isContainByString("file")) {
              editSalaryCalculateRuleGroups({
                companyId: _this16.companyId,
                name: _this16.detaile.name,
                id: _this16.detaile.id,
                ruleType: 1,
                source: "0"
              }).then(function (res) {
                _this16.ruleList();
              });
            } else {
              editSalaryCalculateRuleGroups({
                companyId: "0",
                name: _this16.detaile.name,
                id: _this16.detaile.id,
                ruleType: 1
              }).then(function (res) {
                _this16.ruleList();
              });
              // this.salaryRulesListByPage();
            }
          }
        });
      } else if (this.title === "编辑工价规则") {
        salaryCalculateRulesEdits(_objectSpread(_objectSpread({}, arr), {}, {
          id: this.editId
        })).then(function (res) {
          if (res.code === 200) {
            _this16.$message.success("编辑成功");
            _this16.listIndex = 0;
            _this16.visible = false;
            if (_this16.isContainByString("file")) {
              editSalaryCalculateRuleGroups({
                companyId: _this16.companyId,
                name: _this16.detaile.name,
                id: _this16.detaile.id,
                ruleType: 1,
                source: "0"
              }).then(function (res) {
                _this16.ruleList();
              });
            } else {
              editSalaryCalculateRuleGroups({
                companyId: "0",
                name: _this16.detaile.name,
                id: _this16.detaile.id,
                ruleType: 1
              }).then(function (res) {
                _this16.ruleList();
              });
              // this.salaryRulesListByPage();
            }
          }
        });
      }
    },
    // 点击编辑规则回显
    onClickEdit: function onClickEdit(e) {
      var _this17 = this;
      this.visible = true;
      this.editId = e.id;
      this.title = "编辑工价规则";
      // 回显规则详细信息
      this.welfareChecked = false;
      this.welfareChecked2 = false;
      this.effectType = e.timeType.toString();
      this.dissatisfyMonth = e.dissatisfyMonth !== 0;
      this.lastLimit = e.endAstrict !== 0;
      this.checked1 = !!(e.specialCostDouble && e.specialCostDouble > 0);
      this.specialCostDouble = e.specialCostDouble;
      this.EndAstrict = e.endAstrictJson ? JSON.parse(e.endAstrictJson) : {};
      this.ruleType = e.showType ? e.showType.toString() : null;
      this.ruleType2 = e.leaveShowType ? e.leaveShowType.toString() : null;
      this.ruleTypeCopy = e.showType ? e.showType.toString() : null;
      this.ruleType2Copy = e.leaveShowType ? e.leaveShowType.toString() : null;
      this.departureDetail = e.leaveOfficeRule && JSON.parse(e.leaveOfficeRule).length ? JSON.parse(e.leaveOfficeRule)[0].hourAmount : null;
      this.wagesdetail = e.hourAmountRule && JSON.parse(e.hourAmountRule).length ? JSON.parse(e.hourAmountRule)[0].hourAmount : null;
      this.departureDetailCopy = e.leaveOfficeRule && JSON.parse(e.leaveOfficeRule).length ? JSON.parse(e.leaveOfficeRule)[0].hourAmount : null;
      this.wagesdetailCopy = e.hourAmountRule && JSON.parse(e.hourAmountRule).length ? JSON.parse(e.hourAmountRule)[0].hourAmount : null;
      this.calculation2 = !!this.departureDetail;
      this.calculation = !!this.wagesdetail;
      // this.calculation2 = true;
      // this.calculation = true;
      var redPacketRule = [];
      if (e.redPacketRule) {
        redPacketRule = JSON.parse(e.redPacketRule);
        redPacketRule.forEach(function (item, index) {
          if (item.calculateType === 1) {
            _this17.RedPacketRule = item;
            _this17.welfareChecked = true;
          } else if (item.calculateType === 2) {
            _this17.RedPacketRule2 = item;
            _this17.welfareChecked2 = true;
          }
        });
      }
      // 对于回显时间的处理;
      this.rangeDate = null;
      if (e.timeJson) {
        if (JSON.parse(e.timeJson) && JSON.parse(e.timeJson).type) {
          this.$nextTick(function () {
            _this17.timeStart = JSON.parse(e.timeJson);
            _this17.timeStart.type = _this17.timeStart.type.toString();
            if (_this17.effectType === "1" && _this17.timeStart.type === "1") {
              // 限时+按时间
              _this17.$set(_this17.timeStart, "startNumber", _this17.timeStart.startNumber);
              _this17.$set(_this17.timeStart, "endNumber", _this17.timeStart.endNumber);
              _this17.$set(_this17.timeStart, "unit", _this17.timeStart.unit);
            }
            if (_this17.effectType === "1" && _this17.timeStart.type === "2") {
              // 限时+按日期
              _this17.rangeDate = [];
              _this17.rangeDate[0] = moment(_this17.timeStart.startTime, "YYYY-MM-DD");
              _this17.rangeDate[1] = moment(_this17.timeStart.endTime, "YYYY-MM-DD");
            }
            if (_this17.effectType === "2" && _this17.timeStart.type === "1") {
              // 半长期+按时间
              _this17.$set(_this17.timeStart, "startNumber", _this17.timeStart.startNumber);
              _this17.$set(_this17.timeStart, "unit", _this17.timeStart.unit);
            }
            if (_this17.effectType === "2" && _this17.timeStart.type === "2") {
              // 半长期+按日期
              _this17.startDatePick = moment(_this17.timeStart.startTime, "YYYY-MM-DD");
            }
          });
        } else {
          this.timeStart = {};
        }
      }
    }
  }
};