import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
import { Check, Common } from "@/api/check";
import reportTree from "@/views/custom/customTree/report-tree";
export default {
  components: {
    reportTree: reportTree
  },
  props: ["show", "reportUserOptions"],
  data: function data() {
    return {
      replaceFields: {
        children: "childList",
        title: "name",
        key: "id"
      },
      visible: false,
      obj: {},
      /* 部门 */
      treeData: null,
      /* 添加人员 */
      reportList: []
    };
  },
  watch: {
    reportUserOptions: {
      handler: function handler(val) {
        this.obj = val.obj;
      }
    },
    show: function show(val) {
      if (val) {
        this.visible = val;
        this.getSelectDepTree();
        this.reportList = this.reportUserOptions.obj.reportList;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$eventBus.$on("reportTree", function () {
      _this.reportList = [];
      _this.deep(_this.treeData);
    });
  },
  beforeDestroy: function beforeDestroy() {
    this.$eventBus.$off("reportTree");
  },
  methods: {
    // 递归
    deep: function deep(data, reportList) {
      var _this2 = this;
      data.map(function (v) {
        if (v.isSelected) {
          if (!v.isDept) {
            v.depInfoId = v.parentId;
            v.depInfoName = v.parentName;
            v.empInfoId = v.id;
            v.empInfoName = v.name;
            _this2.reportList.push(v);
          }
        }
        if (v.hasChild) {
          _this2.deep(v.childList);
        }
      });
      return {
        reportList: this.reportList
      };
    },
    // 处理选中状态
    handlerChose: function handlerChose(data) {
      var _this3 = this;
      var resp = this.obj.reportList;
      data.map(function (s) {
        resp.map(function (v, i) {
          if (resp[i].empInfoId === s.id && resp[i].depInfoId === s.parentId) {
            s.isSelected = true;
          }
        });
        if (s.hasChild) {
          _this3.handlerChose(s.childList);
        }
      });
    },
    /* onSelect */onSelect: function onSelect(selectedKeys, info) {
      var _info$node$dataRef = info.node.dataRef,
        depInfoId = _info$node$dataRef.parentId,
        depInfoName = _info$node$dataRef.parentName,
        empInfoName = _info$node$dataRef.name,
        empInfoId = _info$node$dataRef.id;
      if (info.selected) {
        this.reportList.push({
          depInfoId: depInfoId,
          depInfoName: depInfoName,
          empInfoName: empInfoName,
          empInfoId: empInfoId
        });
      } else {
        this.reportList.find(function (value, i, arr) {
          if (value.empInfoId === info.node.dataRef.id) return arr.splice(i, 1);
        });
      }
    },
    /* 获取部门树 */getSelectDepTree: function getSelectDepTree() {
      var _this4 = this;
      Check({
        url: Common.selectDepInfoTree,
        params: {
          companyId: this.$store.state.hr.companyId,
          type: 3
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this4.handlerChose(data);
        _this4.treeData = data;
      });
    },
    /* 删除选中 */delItem: function delItem(i) {
      this.reportList.splice(i, 1);
    },
    /* ok */handleOk: function handleOk(e) {
      this.$emit("editorReportUser", this.reportList);
      this.visible = false;
      this.$emit("update:show", false);
    }
  }
};