import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  path: "/overview",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/adminNew"));
    });
  },
  meta: {
    title: "用工总览"
  },
  children: [{
    path: "staff",
    meta: {
      title: "员工概览"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/overview/staff"));
      });
    }
  }, {
    path: "shebao",
    meta: {
      title: "社保概览"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/overview"));
      });
    }
  }, {
    path: "employer",
    meta: {
      title: "用工单位概览"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/overview/employer"));
      });
    }
  }, {
    path: "uploadSite",
    // 批量导入新员工
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/management/uploadSite"));
      });
    }
  }, {
    path: "subsidiary",
    meta: {
      title: "子公司概览"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/overview/subsidiary"));
      });
    }
  }, {
    path: "staff/todayAdd",
    name: "staff/todayAdd",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/overview/todayAdd"));
      });
    }
  }]
};