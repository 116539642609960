import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import { Wages, WagesSet } from "@/api/wages";
import { mapState } from "vuex";
export default {
  name: "Report",
  components: {
    dialogDetail: function dialogDetail() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./dialog-detail"));
      });
    }
  },
  data: function data() {
    return {
      // 报表日期
      tableDate: "",
      // 计薪月份
      payMonth: 1,
      // 查看详情
      showDetail: false,
      // 详情参数
      reportOptions: {},
      visible: false,
      visible2: false,
      modal: {},
      data: [],
      detialData: {}
    };
  },
  created: function created() {
    this.getSelectSide();
    this.getListStatus();
  },
  computed: _objectSpread({}, mapState(["hr"])),
  methods: {
    // 归档
    record: function record() {
      this.visible2 = true;
    },
    // 查看详情
    showDetailss: function showDetailss(text) {
      this.showDetail = true;
      this.reportOptions = text;
      this.getSalaryDetial(this.reportOptions);
    },
    // 查询计薪周期设置
    getSelectSide: function getSelectSide() {
      var _this = this;
      Wages({
        url: WagesSet.selectSide
      }).then(function (_ref) {
        var data = _ref.data;
        _this.payMonth = data.payMonth;
      });
    },
    // 判断是否有列表
    getListStatus: function getListStatus() {
      var _this2 = this;
      Wages({
        url: WagesSet.listWages,
        params: {
          companyId: this.hr.companyId,
          month: "01",
          year: "2020"
        }
      }).then(function (data) {
        if (data.data) {
          _this2.getWagesList();
        }
      });
    },
    getWagesList: function getWagesList() {
      var _this3 = this;
      Wages({
        url: WagesSet.wagesList,
        method: "POST",
        data: {
          companyId: "932B5BB60904407FB8626B94B656AB00",
          month: "01",
          year: "2020"
        }
      }).then(function (_ref2) {
        var data = _ref2.data;
        if (data) {
          data.forEach(function (val) {
            val.salaryReal /= 100;
          });
          _this3.tableDate = "".concat(data[0].year, "-").concat(data[0].month);
        }
        _this3.data = data;
      });
    },
    // 创建新报表
    openMOdal: function openMOdal() {
      if (this.modal.start) {
        this.visible = true;
      } else {
        this.$warning({
          title: "选择报表月份",
          content: "粉色的星星从天空坠落...."
        });
      }
    },
    // 选择月份
    changeMonth: function changeMonth(date, e) {
      if (date) {
        var days = e.split("-");
        var d = new Date(days[0], days[1], 0).getDate();
        this.modal = {
          title: "".concat(e),
          start: "".concat(e, "-1"),
          end: "".concat(e, "-").concat(d),
          date: date._d,
          e: e
        };
      }
    },
    // 获取月份
    getMonth: function getMonth(time) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth();
      var d = date.getDate();
      var days = new Date(y, m, 0).getDate();
      if (type === 2) {
        if (m === 0) {
          y -= 1;
          m = 12;
        }
      } else {
        m += 1;
      }
      this.modal.start = "".concat(y, "-").concat(m, "-1");
      this.modal.end = "".concat(y, "-").concat(m, "-").concat(days);
    },
    // 生成薪资报表
    createSalary: function createSalary() {
      var _this4 = this;
      Wages({
        url: WagesSet.salaryLists,
        method: "PUT",
        data: {
          companyId: "932B5BB60904407FB8626B94B656AB00",
          month: "01",
          year: "2020"
        }
      }).then(function (rsp) {
        _this4.getSalaryLists();
      });
    },
    // 查询工资报表
    getSalaryLists: function getSalaryLists() {
      var _this5 = this;
      Wages({
        url: WagesSet.getSalaryLists,
        method: "POST",
        data: {
          companyId: "932B5BB60904407FB8626B94B656AB00",
          month: "01",
          year: "2020"
        }
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this5.data = data;
      });
    },
    // 查看归档历史
    reportHistory: function reportHistory() {
      this.$router.push("/wages/report-history");
    },
    // 确认创建
    handleOk: function handleOk() {
      var _this6 = this;
      if (this.modal.start) {
        Wages({
          url: WagesSet.getSalary,
          data: {
            companyId: this.hr.companyId,
            month: this.modal.e.split("-")[1],
            year: this.modal.e.split("-")[0]
          }
        }).then(function (_ref4) {
          var data = _ref4.data;
          _this6.getMonth(_this6.modal.date, data.payMonth);
          _this6.createSalary();
          _this6.visible = false;
        });
      }
    },
    // 确认归档
    handleRecord: function handleRecord() {
      this.visible2 = false;
      this.createSalary();
    },
    getSalaryDetial: function getSalaryDetial(item) {
      var _this7 = this;
      Wages({
        url: WagesSet.salaryDetial,
        method: "POST",
        data: {
          empId: item.empId,
          id: item.id,
          companyId: "932B5BB60904407FB8626B94B656AB00",
          month: "01",
          year: "2020"
        }
      }).then(function (_ref5) {
        var data = _ref5.data;
        _this7.detialData = data;
      });
    }
  }
};