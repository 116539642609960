import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import { selectTreeBySubjectId as _selectTreeBySubjectId } from "../../api";
export default {
  components: {},
  data: function data() {
    return {
      contractData: [],
      templateIcon: require("@/assets/img/overview/templateIcon.png")
    };
  },
  watch: {},
  created: function created() {
    this.selectTreeBySubjectId();
  },
  methods: {
    handleOk: function handleOk() {},
    selectTreeBySubjectId: function selectTreeBySubjectId() {
      var _this = this;
      _selectTreeBySubjectId({
        subjectId: "0"
      }).then(function (res) {
        _this.contractData = res.data;
        _this.contractData.forEach(function (item, index) {
          item.categoryListVOList.forEach(function (item2) {
            item2.selected = false;
          });
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    selectedItem: function selectedItem(item) {
      item.selected = !item.selected;
      this.contractData = JSON.parse(JSON.stringify(this.contractData));
    }
  }
};