import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-layout", [_c("div", {
    staticClass: "header-title-box"
  }, [_c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("社保")]), _c("xq-icon", {
    staticClass: "primary_color",
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-top": "4px",
      "margin-left": "15px"
    },
    attrs: {
      type: "icon-tishi"
    }
  }), _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#767885",
      "margin-top": "2px",
      "margin-left": "5px"
    }
  }, [_vm._v(" 提示：新基数范围下限不得小于原有基数的范围下限 ")])], 1)]), _c("a-modal", {
    attrs: {
      title: "总计尾数处理",
      visible: _vm.editVisible
    },
    on: {
      ok: _vm.confirmAddType,
      cancel: _vm.handleDialCancel
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      form: _vm.typeForm,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      },
      colon: true
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "单位尾数处理"
    }
  }, [_c("a-select", {
    attrs: {
      value: _vm.isSheBao ? _vm.groupItemDefault : _vm.groupItemDefault2
    },
    on: {
      change: _vm.weiShuHandleChange
    }
  }, _vm._l(_vm.groupList, function (group, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: group.id
      }
    }, [_vm._v(" " + _vm._s(group.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "个人尾数处理"
    }
  }, [_c("a-select", {
    attrs: {
      value: _vm.isSheBao ? _vm.GgroupItemDefault : _vm.GgroupItemDefault2
    },
    on: {
      change: _vm.GweiShuHandleChange
    }
  }, _vm._l(_vm.groupList, function (group, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: group.id
      }
    }, [_vm._v(" " + _vm._s(group.name) + " ")]);
  }), 1)], 1)], 1)], 1)]), _c("a-row", [_c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-table", {
    attrs: {
      "data-source": _vm.getSheBao(1),
      pagination: false,
      "row-key": "defaultType",
      columns: _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "itemName",
      fn: function fn(text, record) {
        return record.defaultType < 200 ? _c("span", {}, [_vm._v(_vm._s(text))]) : _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          },
          attrs: {
            "validate-status": text.length > 0 ? "success" : "error",
            help: text.length > 0 ? "" : "必填项"
          }
        }, [_c("a-input", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            placeholder: "项目名称",
            value: record.itemName
          },
          on: {
            change: function change(e) {
              return _vm.handleName(e, record);
            }
          }
        })], 1);
      }
    }, {
      key: "cardinalityDefault",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          staticStyle: {
            width: "0px",
            "margin-bottom": "100px"
          }
        }, [_c("a-input-number", {
          attrs: {
            disabled: true,
            value: record.cardinalityDefault,
            min: record.cardinalityMin,
            max: record.cardinalityMax,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleDefault(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "cardinalityDefaultNew",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          staticStyle: {
            width: "0px",
            "margin-bottom": "100px"
          }
        }, [_c("a-input-number", {
          attrs: {
            value: record.cardinalityDefaultNew,
            min: record.cardinalityMin,
            max: record.cardinalityMax,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleDefault(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            value: record.cardinalityDefaultNew = record.cardinalityMin,
            min: record.cardinalityDefault,
            max: record.cardinalityMax,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleMin(value, record);
            }
          }
        }), _vm._v(" — "), _c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            value: record.cardinalityMax,
            min: record.cardinalityMin,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleMax(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "persentCompany",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            disabled: true,
            value: record.persentCompany,
            min: 0,
            max: 100,
            formatter: function formatter(value) {
              return "".concat(value) + "%";
            }
          },
          on: {
            blur: function blur($event) {
              return _vm.blurhandleCompany(record);
            },
            change: function change(value) {
              return _vm.handleCompany(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "persentPersonal",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            disabled: true,
            min: 0,
            max: 100,
            value: record.persentPersonal,
            formatter: function formatter(value) {
              return "".concat(value) + "%";
            }
          },
          on: {
            blur: function blur($event) {
              return _vm.blurhandlePersonal(record);
            },
            change: function change(value) {
              return _vm.handlePersonal(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "amountCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getCompanyAmount(_vm.groupItemDefault, record)))]);
      }
    }, {
      key: "amountPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getPersonAmount(_vm.GgroupItemDefault, record)))]);
      }
    }], null, true)
  }, [_c("span", {
    staticClass: "addCursor",
    attrs: {
      slot: "customTitle"
    },
    slot: "customTitle"
  }, [_c("span", [_c("span", {
    staticStyle: {
      "margin-right": "5px"
    }
  }, [_vm._v("项目")])]), _vm.itemShow ? _c("div", {
    staticClass: "checkboxWrapper"
  }, [_c("a-checkbox-group", {
    on: {
      change: _vm.handleCheckbox
    },
    model: {
      value: _vm.itemChecked,
      callback: function callback($$v) {
        _vm.itemChecked = $$v;
      },
      expression: "itemChecked"
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "101"
    }
  }, [_vm._v(" 养老保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "102"
    }
  }, [_vm._v(" 医疗保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "103"
    }
  }, [_vm._v(" 失业保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "104"
    }
  }, [_vm._v(" 工伤保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "105"
    }
  }, [_vm._v(" 生育保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "106"
    }
  }, [_vm._v(" 企业(职业)年金 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "107"
    }
  }, [_vm._v(" 商业健康保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "108"
    }
  }, [_vm._v(" 税延养老保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "109"
    }
  }, [_vm._v(" 补充养老保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "110"
    }
  }, [_vm._v(" 补充(大病医疗)保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "111"
    }
  }, [_vm._v(" 残保金 ")])], 1)], 1)], 1), _c("a-divider"), _c("span", {
    on: {
      click: function click() {
        return _vm.addOtherItem(1);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-circle"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v("其他项目")])], 1)], 1) : _vm._e()])])], 1), _c("div", {
    staticClass: "header-title-box",
    staticStyle: {
      "margin-top": "50px"
    }
  }, [_c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("医保")])])]), _c("a-form", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      form: _vm.form
    }
  }, [_c("a-table", {
    attrs: {
      "data-source": _vm.getSheBao(2),
      pagination: false,
      "row-key": "defaultType",
      columns: _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "itemName",
      fn: function fn(text, record) {
        return record.defaultType < 200 ? _c("span", {}, [_vm._v(_vm._s(text))]) : _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          },
          attrs: {
            "validate-status": text.length > 0 ? "success" : "error",
            help: text.length > 0 ? "" : "必填项"
          }
        }, [_c("a-input", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            placeholder: "项目名称",
            value: record.itemName
          },
          on: {
            change: function change(e) {
              return _vm.handleName(e, record);
            }
          }
        })], 1);
      }
    }, {
      key: "cardinalityDefault",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          staticStyle: {
            width: "0px",
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          attrs: {
            disabled: true,
            value: record.cardinalityDefault,
            min: record.cardinalityMin,
            max: record.cardinalityMax,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleDefault(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "cardinalityDefaultNew",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          staticStyle: {
            width: "0px",
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          attrs: {
            value: record.cardinalityDefaultNew,
            min: record.cardinalityMin,
            max: record.cardinalityMax,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleDefault(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            value: record.cardinalityDefaultNew = record.cardinalityMin,
            min: record.cardinalityDefault,
            max: record.cardinalityMax,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleMin(value, record);
            }
          }
        }), _vm._v(" — "), _c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            value: record.cardinalityMax,
            min: record.cardinalityMin,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleMax(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "persentCompany",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            disabled: true,
            value: record.persentCompany,
            min: 0,
            max: 100,
            formatter: function formatter(value) {
              return "".concat(value) + "%";
            }
          },
          on: {
            blur: function blur($event) {
              return _vm.blurhandleCompany(record);
            },
            change: function change(value) {
              return _vm.handleCompany(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "persentPersonal",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            disabled: true,
            min: 0,
            max: 100,
            value: record.persentPersonal,
            formatter: function formatter(value) {
              return "".concat(value) + "%";
            }
          },
          on: {
            blur: function blur($event) {
              return _vm.blurhandlePersonal(record);
            },
            change: function change(value) {
              return _vm.handlePersonal(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "amountCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getCompanyAmount(_vm.groupItemDefault, record)))]);
      }
    }, {
      key: "amountPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getPersonAmount(_vm.GgroupItemDefault, record)))]);
      }
    }], null, true)
  }, [_c("span", {
    staticClass: "addCursor",
    attrs: {
      slot: "customTitle"
    },
    slot: "customTitle"
  }, [_c("span", [_c("span", {
    staticStyle: {
      "margin-right": "5px"
    }
  }, [_vm._v("项目")])]), _vm.itemShow3 ? _c("div", {
    staticClass: "checkboxWrapper"
  }, [_c("a-checkbox-group", {
    on: {
      change: _vm.handleCheckbox
    },
    model: {
      value: _vm.itemChecked,
      callback: function callback($$v) {
        _vm.itemChecked = $$v;
      },
      expression: "itemChecked"
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "101"
    }
  }, [_vm._v(" 养老保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "102"
    }
  }, [_vm._v(" 医疗保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "103"
    }
  }, [_vm._v(" 失业保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "104"
    }
  }, [_vm._v(" 工伤保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "105"
    }
  }, [_vm._v(" 生育保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "106"
    }
  }, [_vm._v(" 企业(职业)年金 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "107"
    }
  }, [_vm._v(" 商业健康保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "108"
    }
  }, [_vm._v(" 税延养老保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "109"
    }
  }, [_vm._v(" 补充养老保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "110"
    }
  }, [_vm._v(" 补充(大病医疗)保险 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "111"
    }
  }, [_vm._v(" 残保金 ")])], 1)], 1)], 1), _c("a-divider"), _c("span", {
    on: {
      click: function click() {
        return _vm.addOtherItem(1);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-circle"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v("其他项目")])], 1)], 1) : _vm._e()])])], 1), _c("div", {
    staticClass: "totalBox"
  }, [_c("div", {
    staticClass: "company"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("社保单位总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getCompanyAll()))])]), _c("div", {
    staticClass: "person"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("社保个人总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPersonAll()))])])])], 1), !_vm.jijinShow ? _c("a-row", [_c("a-row", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm._v("公积金")]), _c("xq-icon", {
    staticClass: "primary_color",
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-top": "5px",
      "margin-left": "-10px"
    },
    attrs: {
      type: "icon-tishi"
    }
  }), _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#767885",
      "margin-top": "3px",
      "margin-left": "5px"
    }
  }, [_vm._v(" 提示：新基数范围下限不得小于原有基数的范围下限 ")])], 1), !_vm.jijinShow ? _c("a-row", [_c("a-table", {
    attrs: {
      "data-source": _vm.jijin,
      pagination: false,
      "row-key": "defaultType",
      columns: _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "itemName",
      fn: function fn(text, record) {
        return record.defaultType < 200 ? _c("span", {}, [_vm._v(_vm._s(text))]) : _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          },
          attrs: {
            "validate-status": text.length > 0 ? "success" : "error",
            help: text.length > 0 ? "" : "必填项"
          }
        }, [_c("a-input", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            placeholder: "项目名称",
            value: record.itemName
          },
          on: {
            change: function change(e) {
              return _vm.handleName(e, record);
            }
          }
        })], 1);
      }
    }, {
      key: "cardinalityDefault",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          staticStyle: {
            "margin-bottom": "0px",
            width: "100px"
          }
        }, [_c("a-input-number", {
          attrs: {
            disabled: true,
            value: record.cardinalityDefault,
            min: record.cardinalityMin,
            max: record.cardinalityMax,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleDefault(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "cardinalityDefaultNew",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          staticStyle: {
            width: "0px",
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          attrs: {
            value: record.cardinalityDefaultNew,
            min: record.cardinalityMin,
            max: record.cardinalityMax,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleDefault(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            value: record.cardinalityDefaultNew = record.cardinalityMin,
            min: record.cardinalityDefault,
            max: record.cardinalityMax,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleMin(value, record);
            }
          }
        }), _vm._v(" — "), _c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            value: record.cardinalityMax,
            min: record.cardinalityMin,
            max: 99999,
            precision: 2
          },
          on: {
            change: function change(value) {
              return _vm.handleMax(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "persentCompany",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            disabled: true,
            value: record.persentCompany,
            min: 0,
            max: 100,
            formatter: function formatter(value) {
              return "".concat(value) + "%";
            }
          },
          on: {
            blur: function blur($event) {
              return _vm.blurhandleCompany(record);
            },
            change: function change(value) {
              return _vm.handleCompany(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "persentPersonal",
      fn: function fn(text, record) {
        return _c("a-form-item", {
          staticStyle: {
            "margin-bottom": "0px"
          }
        }, [_c("a-input-number", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            disabled: true,
            min: 0,
            max: 100,
            value: record.persentPersonal,
            formatter: function formatter(value) {
              return "".concat(value) + "%";
            }
          },
          on: {
            blur: function blur($event) {
              return _vm.blurhandlePersonal(record);
            },
            change: function change(value) {
              return _vm.handlePersonal(value, record);
            }
          }
        })], 1);
      }
    }, {
      key: "amountCompany",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getCompanyAmount(_vm.groupItemDefault2, record)))]);
      }
    }, {
      key: "amountPersonal",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(_vm._s(_vm.getPersonAmount(_vm.GgroupItemDefault2, record)))]);
      }
    }], null, true)
  }, [_c("span", {
    staticClass: "addCursor",
    attrs: {
      slot: "customTitle"
    },
    slot: "customTitle"
  }, [_c("span", [_c("span", {
    staticStyle: {
      "margin-right": "5px"
    }
  }, [_vm._v("项目")])]), _vm.itemShow2 ? _c("div", {
    staticClass: "checkboxWrapper"
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    on: {
      change: _vm.handleJijinCheckbox
    },
    model: {
      value: _vm.jijinChecked,
      callback: function callback($$v) {
        _vm.jijinChecked = $$v;
      },
      expression: "jijinChecked"
    }
  }, [_vm._v(" 公积金 ")])], 1), _c("a-col", {
    attrs: {
      span: 40
    }
  }, [_c("a-checkbox", {
    on: {
      change: _vm.handleJijinCheckbox2
    },
    model: {
      value: _vm.jijinChecked2,
      callback: function callback($$v) {
        _vm.jijinChecked2 = $$v;
      },
      expression: "jijinChecked2"
    }
  }, [_vm._v(" 补充公积金 ")])], 1)], 1), _c("a-divider"), _c("span", {
    on: {
      click: function click() {
        return _vm.addOtherItem(2);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus-circle"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v("其他项目")])], 1)], 1) : _vm._e()])])], 1) : _vm._e(), _c("a-row", [_c("div", {
    staticClass: "totalBox"
  }, [_c("div", {
    staticClass: "shebaoTotal"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("公积金单位总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfCompanyAll()))])]), _c("div", {
    staticClass: "sfTotal"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("公积金个人总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfPersonAll()))])])])])], 1) : _vm._e(), _c("a-divider", {
    attrs: {
      dashed: ""
    }
  }), _c("a-row", [_c("div", {
    staticClass: "totalBox2"
  }, [_c("div", {
    staticClass: "company"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("社保总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfCompanyAll2()))])]), _c("div", {
    staticClass: "person"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("公积金总计：")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getPfPersonAll2()))])])])])], 1), _c("a-modal", {
    attrs: {
      visible: _vm.shouDongBuChaVisible,
      title: "添加补差"
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.shouDongBuChaOkHandel
    }
  }, [_vm._v(" 下一步 ")])], 1), _c("div", {
    staticStyle: {
      "font-weight": "500",
      "font-size": "18px"
    }
  }, [_vm._v("存在未来月份的参保管理，是否需要更新参保信息？")]), _c("div", {
    staticStyle: {
      color: "#000",
      opacity: "0.8",
      "margin-top": "20px"
    }
  }, [_vm._v("说明：根据补差修改的默认个人缴纳基数更新")]), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("a-radio-group", {
    on: {
      change: _vm.onTypeChangeValue
    },
    model: {
      value: _vm.typeValueTime,
      callback: function callback($$v) {
        _vm.typeValueTime = $$v;
      },
      expression: "typeValueTime"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 不更新参保人信息 ")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 更新参保人信息 ")])], 1)], 1), _vm.typeValueTime === 2 ? _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      mode: "multiple",
      placeholder: "请选择要更改的时间"
    },
    on: {
      change: _vm.handleTime
    }
  }, _vm._l(_vm.timeList, function (item) {
    return _c("a-select-option", {
      key: item
    }, [_vm._v(_vm._s(_vm.tranDate(item)))]);
  }), 1)], 1) : _vm._e()], 2), _c("a-modal", {
    attrs: {
      title: "添加补差",
      visible: _vm.removeZengVisible
    },
    on: {
      cancel: _vm.handleCancel,
      ok: _vm.remvoeZenyuanOkHandel
    }
  }, [_c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px",
      "margin-left": "40px"
    },
    attrs: {
      src: require("@/assets/icons/ask.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400",
      width: "300px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getInfo()) + " ")])]), _c("div", {
    staticStyle: {
      "margin-left": "80px",
      "margin-top": "4px",
      "margin-bottom": "20px",
      color: "#767885",
      "font-size": "14px",
      "font-weight": "400",
      width: "380px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getInfo2()) + " ")])]), _c("a-modal", {
    attrs: {
      title: "添加补差",
      visible: _vm.bushoubujiaook
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.BushouBuJiaoOkHandle
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px",
      "margin-top": "2px"
    },
    attrs: {
      src: require("@/assets/icons/duihao.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400",
      "margin-left": "10px"
    }
  }, [_vm._v("补差成功")])]), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("div", {
    staticClass: "rowdialog"
  }, [_c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#767885",
      "font-size": "14px",
      "font-weight": "400",
      "margin-left": "50px"
    }
  }, [_vm._v("补差金额详见当月参保管理")]), _c("div", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "14px",
      "margin-left": "6px",
      cursor: "pointer"
    },
    on: {
      click: _vm.canbaoguanli
    }
  }, [_vm._v("点击跳转 >")])])])], 2), _c("div", {
    staticStyle: {
      width: "85%",
      height: "50px",
      display: "flex",
      "justify-content": "center",
      position: "fixed",
      bottom: "20px",
      "align-items": "center",
      background: "#ffffff",
      "z-index": "1"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.unSaveMethod
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveMethod
    }
  }, [_vm._v("保存")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };