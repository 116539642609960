export default {
  onJob: {
    fields: {
      number: {
        model: "number",
        type: "input",
        wrapper: {
          label: "工号"
        },
        customize: {
          style: "width: 100%",
          placeholder: "请输入工号",
          allowClear: true
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入工号"
          }]
        },
        extend: {}
      },
      preJoinDep: {
        model: "preJoinDep",
        type: "treeSelect",
        wrapper: {
          label: "部门"
        },
        customize: {
          multiple: true,
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择部门",
          showSearch: true,
          treeNodeFilterProp: "title"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择部门"
          }]
        },
        extend: {
          dynamic: true,
          // 是否是动态的
          dynamicKey: "depNameDynamic"
        }
      },
      preJoinPosition: {
        model: "preJoinPosition",
        type: "treeSelect",
        wrapper: {
          label: "岗位"
        },
        customize: {
          multiple: true,
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择岗位",
          showSearch: true,
          treeNodeFilterProp: "title"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择岗位"
          }]
        },
        extend: {
          dynamic: true,
          // 是否是动态的
          dynamicKey: "positionOptions"
        }
      },
      workCity: {
        model: "workCity",
        type: "input",
        wrapper: {
          label: "工作地点"
        },
        customize: {
          style: "width: 100%",
          placeholder: "请输入工作地点",
          allowClear: true
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入工作地点"
          }]
        },
        extend: {}
      },
      joinCityCode: {
        model: "joinCityCode",
        type: "area",
        wrapper: {
          label: "入职城市"
        },
        customize: {
          style: "width: 100%",
          placeholder: "请输入入职城市",
          allowClear: true
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入入职城市"
          }]
        },
        extend: {}
      },
      inputName: {
        model: "inputName",
        type: "input",
        wrapper: {
          label: "录入人"
        },
        customize: {
          style: "width: 100%",
          placeholder: "请输入录入人",
          allowClear: true,
          disabled: true
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入录入人"
          }]
        },
        extend: {}
      },
      sourceType: {
        model: "sourceType",
        type: "select",
        wrapper: {
          label: "录入方式"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择录入方式",
          disabled: true,
          options: [{
            value: 1,
            label: "网页端录入"
          }, {
            value: 2,
            label: "手机端录入"
          }, {
            value: 3,
            label: "更换用工单位"
          }]
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择录入方式"
          }]
        },
        extend: {}
      }
    },
    validators: {},
    config: {}
  },
  employee: {
    fields: {
      postType: {
        model: "postType",
        type: "select",
        wrapper: {
          label: "工作性质"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择工作性质"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择工作性质"
          }]
        },
        extend: {
          dynamic: true,
          dynamicKey: "postTypeLst"
        }
      },
      formalType: {
        model: "formalType",
        type: "select",
        wrapper: {
          label: "员工状态"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择员工状态",
          options: [{
            value: 1,
            label: "试用"
          }, {
            value: 2,
            label: "正式"
          }]
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择员工状态"
          }]
        },
        extend: {}
      },
      joinDate: {
        model: "joinDate",
        type: "date",
        wrapper: {
          label: "入职日期"
        },
        customize: {
          style: "width: 100%",
          placeholder: "请输入joinDate",
          allowClear: true
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入joinDate"
          }]
        },
        extend: {}
      },
      probationDays: {
        model: "probationDays",
        type: "date",
        wrapper: {
          label: "入职日期"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择入职日期",
          format: "YYYY-MM-DD"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择入职日期"
          }]
        },
        extend: {}
      },
      turnStraightDate: {
        model: "turnStraightDate",
        type: "date",
        wrapper: {
          label: "预计转正日期"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择预计转正日期",
          format: "YYYY-MM-DD"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择预计转正日期"
          }]
        },
        extend: {}
      },
      joinDateAge: {
        model: "joinDateAge",
        type: "number",
        wrapper: {
          label: "司龄"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入司龄"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入司龄"
          }]
        },
        extend: {}
      }
    }
  },
  record: {
    fields: {
      archivesNumber: {
        model: "archivesNumber",
        type: "input",
        wrapper: {
          label: "档案编号"
        },
        customize: {
          style: "width: 100%",
          placeholder: "请输入档案编号",
          allowClear: true
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入档案编号"
          }]
        },
        extend: {}
      },
      archivesRemake: {
        model: "archivesRemake",
        type: "textarea",
        wrapper: {
          label: "档案备注"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入档案备注"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入档案备注"
          }]
        },
        extend: {}
      }
    }
  },
  rewards: {
    fields: {
      rewardPunish: {
        model: "rewardPunish",
        type: "select",
        wrapper: {
          label: "奖励/惩罚"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择奖励/惩罚",
          options: [{
            value: 1,
            label: "奖励"
          }, {
            value: 2,
            label: "惩罚"
          }]
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择奖励/惩罚"
          }]
        },
        extend: {}
      },
      rewardType: {
        model: "rewardType",
        type: "select",
        wrapper: {
          label: "奖惩类别"
        },
        customize: {
          notFoundContent: "请选择奖励/惩罚",
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择奖惩类别",
          options: [{
            value: 1,
            label: "通报表扬"
          }, {
            value: 2,
            label: "年度优秀员工奖"
          }, {
            value: 3,
            label: "特殊贡献奖"
          }]
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择奖惩类别"
          }]
        },
        extend: {}
      },
      handleDate: {
        model: "handleDate",
        type: "date",
        wrapper: {
          label: "奖惩时间"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择奖惩时间",
          format: "YYYY-MM-DD"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择奖惩时间"
          }]
        },
        extend: {}
      },
      content: {
        model: "content",
        type: "input",
        wrapper: {
          label: "奖惩内容"
        },
        customize: {
          style: "width: 100%",
          placeholder: "请输入奖惩内容",
          allowClear: true
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入奖惩内容"
          }]
        },
        extend: {}
      },
      amount: {
        model: "amount",
        type: "number",
        wrapper: {
          label: "奖惩金额"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入奖惩金额"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入奖惩金额"
          }]
        },
        extend: {}
      },
      reason: {
        model: "reason",
        type: "input",
        wrapper: {
          label: "奖惩原因"
        },
        customize: {
          style: "width: 100%",
          placeholder: "请输入奖惩原因",
          allowClear: true
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入奖惩原因"
          }]
        },
        extend: {}
      },
      remark: {
        model: "remark",
        type: "textarea",
        wrapper: {
          label: "奖惩备注"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入奖惩备注"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入奖惩备注"
          }]
        },
        extend: {}
      }
    },
    validators: {},
    config: {}
  },
  retire: {
    fields: {
      retirementAge: {
        model: "retirementAge",
        type: "number",
        wrapper: {
          label: "退休年龄"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          min: 0,
          placeholder: "请输入退休年龄"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入退休年龄"
          }]
        },
        extend: {}
      },
      retirementStatus: {
        model: "retirementStatus",
        type: "select",
        wrapper: {
          label: "是否已退休"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择是否已退休",
          options: [{
            value: "true",
            label: "是"
          }, {
            value: "false",
            label: "否"
          }]
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择是否已退休"
          }]
        },
        extend: {}
      },
      retirementDate: {
        model: "retirementDate",
        type: "date",
        wrapper: {
          label: "退休日期"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请选择退休日期",
          format: "YYYY-MM-DD"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请选择退休日期"
          }]
        },
        extend: {}
      },
      retirementRemark: {
        model: "retirementRemark",
        type: "textarea",
        wrapper: {
          label: "退休备注"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入退休备注"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入退休备注"
          }]
        },
        extend: {}
      }
    },
    validators: {},
    config: {}
  },
  individual: {
    fields: {
      cumulativeIncome: {
        model: "cumulativeIncome",
        type: "number",
        wrapper: {
          label: "累计收入额"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入累计收入额"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入累计收入额"
          }]
        },
        extend: {}
      },
      cumulativeDeductions: {
        model: "cumulativeDeductions",
        type: "number",
        wrapper: {
          label: "累计减除费用"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入累计减除费用"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入累计减除费用"
          }]
        },
        extend: {}
      },
      cumulativeSpecialDeduction: {
        model: "cumulativeSpecialDeduction",
        type: "number",
        wrapper: {
          label: "累计专项扣除"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入累计专项扣除"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入累计专项扣除"
          }]
        },
        extend: {}
      },
      cumulativePrepaidTax: {
        model: "cumulativePrepaidTax",
        type: "number",
        wrapper: {
          label: "累计已预缴税额"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入累计已预缴税额"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入累计已预缴税额"
          }]
        },
        extend: {}
      }
    },
    validators: {},
    config: {}
  },
  subjoin: {
    fields: {
      monthlyChildEducation: {
        model: "monthlyChildEducation",
        type: "number",
        wrapper: {
          label: "单月子女教育"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入单月子女教育"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入单月子女教育"
          }]
        },
        extend: {}
      },
      sumChildEducation: {
        model: "sumChildEducation",
        type: "number",
        wrapper: {
          label: "累计子女教育"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入累计子女教育"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入累计子女教育"
          }]
        },
        extend: {}
      },
      monthlyHousingRent: {
        model: "monthlyHousingRent",
        type: "number",
        wrapper: {
          label: "单月住房租金"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入单月住房租金"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入单月住房租金"
          }]
        },
        extend: {}
      },
      sumHousingRent: {
        model: "sumHousingRent",
        type: "number",
        wrapper: {
          label: "累计住房租金"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入累计住房租金"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入累计住房租金"
          }]
        },
        extend: {}
      },
      monthlyHomeLoanInterest: {
        model: "monthlyHomeLoanInterest",
        type: "number",
        wrapper: {
          label: "单月住房贷款利息"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入单月住房贷款利息"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入单月住房贷款利息"
          }]
        },
        extend: {}
      },
      sumHomeLoanInterest: {
        model: "sumHomeLoanInterest",
        type: "number",
        wrapper: {
          label: "累计住房贷款利息"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入累计住房贷款利息"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入累计住房贷款利息"
          }]
        },
        extend: {}
      },
      monthlySupportOldMan: {
        model: "monthlySupportOldMan",
        type: "number",
        wrapper: {
          label: "单月赡养老人"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入单月赡养老人"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入单月赡养老人"
          }]
        },
        extend: {}
      },
      sumSupportOldMan: {
        model: "sumSupportOldMan",
        type: "number",
        wrapper: {
          label: "累计赡养老人"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入累计赡养老人"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入累计赡养老人"
          }]
        },
        extend: {}
      },
      monthlyContinuingEducation: {
        model: "monthlyContinuingEducation",
        type: "number",
        wrapper: {
          label: "单月继续教育"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入单月继续教育"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入单月继续教育"
          }]
        },
        extend: {}
      },
      sumContinuingEducation: {
        model: "sumContinuingEducation",
        type: "number",
        wrapper: {
          label: "累计继续教育"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入累计继续教育"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入累计继续教育"
          }]
        },
        extend: {}
      },
      monthlyChildCare: {
        model: "monthlyChildCare",
        type: "number",
        wrapper: {
          label: "单月3岁以下婴幼儿照护"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入单月3岁以下婴幼儿照护"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入单月3岁以下婴幼儿照护"
          }]
        },
        extend: {}
      },
      sumChildCare: {
        model: "sumChildCare",
        type: "number",
        wrapper: {
          label: "累计3岁以下婴幼儿照护"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入累计3岁以下婴幼儿照护"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入累计3岁以下婴幼儿照护"
          }]
        },
        extend: {}
      },
      individualPensions: {
        model: "individualPensions",
        type: "number",
        wrapper: {
          label: "单月个人养老金"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入个人养老金"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入个人养老金"
          }]
        },
        extend: {}
      },
      sumIndividualPensions: {
        model: "sumIndividualPensions",
        type: "number",
        wrapper: {
          label: "累计个人养老金"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入累计个人养老金"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入累计个人养老金"
          }]
        },
        extend: {}
      }
    },
    validators: {},
    config: {}
  },
  salarySum: {
    fields: {
      trainingSalarySum: {
        model: "trainingSalarySum",
        type: "input",
        wrapper: {
          label: "试用期工资"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入试用期工资"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入退休年龄"
          }]
        },
        extend: {}
      },
      officialSalarySum: {
        model: "officialSalarySum",
        type: "input",
        wrapper: {
          label: "正式工资"
        },
        customize: {
          style: "width: 100%",
          allowClear: true,
          placeholder: "请输入正式工资"
        },
        decorator: {
          initialValue: "",
          rules: [{
            required: false,
            message: "请输入正式工资"
          }]
        },
        extend: {}
      }
    },
    validators: {},
    config: {}
  }
};