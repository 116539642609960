import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import { mapState } from "vuex";
import tableDetail from "./common/table-detail";
import feedBack from "./common/feed-back";
export default {
  name: "ShebaoDetail",
  components: {
    tableDetail: tableDetail,
    feedBack: feedBack
  },
  data: function data() {
    return {
      tabs: {},
      defaultKey: "all",
      allOptions: {},
      finishedOptions: {},
      unFinishedOptions: {},
      errorOptions: {},
      feedVisible: false,
      feedOptions: {},
      params: {
        status: undefined,
        showItem: true,
        pageNo: 1,
        pageSize: 10,
        socDispatchId: ""
      }
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  created: function created() {
    this.params.socDispatchId = this.$route.query.socDispatchId;
    this.getDetail();
    this.getNavs();
  },
  methods: {
    goBack: function goBack() {
      this.$router.push({
        path: "/shebao/paidan",
        query: _objectSpread({}, this.$route.query)
      });
    },
    getDetail: function getDetail() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/socDispatchEmpAdds",
        params: _objectSpread({}, this.params)
      }).then(function (_ref) {
        var data = _ref.data;
        _this.allOptions = data;
      });
    },
    getNavs: function getNavs() {
      var _this2 = this;
      this.$request({
        url: "/hr/api/v1/socDispatches/findDetails/".concat(this.$route.query.socDispatchId)
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this2.tabs = data;
      });
    },
    // 搜索姓名
    onSearchName: function onSearchName(name) {
      name ? this.params.name = name : delete this.params.name;
      this.params.pageNo = 1;
      this.getDetail();
    },
    // 切换详情
    changeTab: function changeTab(i) {
      this.defaultKey = i;
      this.params.status = "";
      if (typeof i === "number") {
        this.params.status = i;
      }
      this.getDetail();
    },
    handleNav: function handleNav(e) {},
    handleTable: function handleTable(e) {
      this.feedVisible = e.visible;
      this.feedOptions = e.e;
      if (e.current) {
        this.params.pageNo = e.current;
        this.getDetail();
      }
    },
    handleDetail: function handleDetail(e) {
      this.feedVisible = e.visible;
    }
  }
};