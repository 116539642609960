import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
export default {
  components: {
    group: function group() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/set/group"));
      });
    },
    rule: function rule() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/set/rule"));
      });
    }
  },
  data: function data() {
    return {
      tabs: "0"
    };
  },
  created: function created() {
    this.tabs = this.$route.query.tabs ? this.$route.query.tabs : "0";
  },
  methods: {
    tabsChange: function tabsChange(e) {
      var query = JSON.parse(JSON.stringify(this.$router.history.current.query)); // 取当前路由query值并且深拷贝
      var path = this.$router.history.current.path; // 取当前路由地址
      query.tabs = e; // 修改变动的query值
      this.$router.push({
        path: path,
        query: query
      }); // 改变query值
    }
  }
};