import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.timers.js";
import { columnsRecordList } from "./funcs";
import SelectConfirm from "./selectConfirm.vue";
export default {
  name: "",
  components: {
    SelectConfirm: SelectConfirm
  },
  mixins: [],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    target: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      columnsRecordList: columnsRecordList,
      recordLst: [],
      modalVisible: false,
      editVisible: false,
      currentItem: null
    };
  },
  computed: {},
  watch: {
    value: {
      handler: function handler(newVal) {
        this.modalVisible = newVal;
      },
      immediate: true
    },
    modalVisible: function modalVisible() {
      this.$emit("input", this.modalVisible);
      this.$emit("change", this.modalVisible);
      if (this.modalVisible) {
        this.clear();
        this.loadData();
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onClose: function onClose() {
      this.modalVisible = false;
      this.$emit("close");
    },
    editConfirm: function editConfirm(obj) {
      var _this = this;
      this.$request({
        url: "/insurance/api/v1/qyInsuranceRecords",
        method: "put",
        data: _objectSpread(_objectSpread({}, this.currentItem), obj)
      }).then(function (res) {
        _this.$message.success("修改成功");
        _this.loadData();
      }).catch(function (err) {
        console.log(err);
      });
    },
    editItem: function editItem(record) {
      var _this2 = this;
      this.currentItem = record;
      setTimeout(function () {
        _this2.editVisible = true;
      }, 0);
    },
    deleteItem: function deleteItem(qyInsuranceRecordId) {
      var _this3 = this;
      this.$request({
        url: "/insurance/api/v1/qyInsuranceRecords/".concat(qyInsuranceRecordId),
        method: "delete"
      }).then(function (res) {
        _this3.$message.success("删除成功");
        _this3.loadData();
      }).catch(function (err) {
        console.log(err);
      });
    },
    clear: function clear() {
      this.recordLst = [];
    },
    formatDate: function formatDate(val) {
      if (val) {
        return this.$dayjs(val).format("YYYY-MM-DD");
      }
      return "--";
    },
    loadData: function loadData() {
      var _this4 = this;
      this.$request({
        url: "/hr/api/v1/hrInsurance/selectEmpInsuranceList/".concat(this.target.empId)
      }).then(function (res) {
        _this4.recordLst = res.data;
      }).catch(function (err) {
        console.log("selectEmpInsuranceList", err);
      });
    }
  }
};