var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "拜访详情",
      footer: null,
      width: 560
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", [_c("div", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.msgVal.memberName))]), _c("div", {
    staticClass: "phone"
  }, [_c("a-icon", {
    attrs: {
      type: "phone"
    }
  }), _vm._v(" " + _vm._s(_vm.msgVal.phone))], 1)]), _c("div", [_c("div", {
    staticClass: "time"
  }, [_vm._v("提交时间：" + _vm._s(_vm.moment(_vm.msgVal.gmtCreate).format("YYYY-MM-DD HH:mm")))])])]), _c("div", {
    staticClass: "bottom"
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 4
    }
  }, [_vm._v("地址：")]), _c("a-col", {
    attrs: {
      span: 20
    }
  }, [_vm._v(_vm._s(_vm.msgVal.visitAddress))])], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 4
    }
  }, [_vm._v("拜访时间：")]), _c("a-col", {
    attrs: {
      span: 20
    }
  }, [_vm._v(_vm._s(_vm.moment(_vm.msgVal.visitTime).format("YYYY-MM-DD HH:mm")))])], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 4
    }
  }, [_vm._v("拜访照片：")]), _c("a-col", {
    attrs: {
      span: 20
    }
  }, [_c("img", {
    attrs: {
      src: _vm.msgVal.visitPicture,
      alt: "",
      srcset: ""
    }
  })])], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 4
    }
  }, [_vm._v("拜访详情：")]), _c("a-col", {
    attrs: {
      span: 20
    }
  }, [_vm._v(_vm._s(_vm.msgVal.visitInfo))])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };