export default {
  name: "PageHeader",
  props: {
    subTitle: {
      type: String,
      default: ""
    },
    backLock: {
      type: Boolean,
      default: true
    }
  }
};