import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";
// 查询公司某月份的月工资汇总
export function monthlySalary(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/selectMonthlySalarySummary",
    method: "get",
    params: params
  });
}
export function monthlySalaryNewReq(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/selectMonthlySalarySummaryNew",
    method: "get",
    params: params
  });
}
// 新增工资模板月工资汇总
export function postMonthlySalaryNewReq(data) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/batchSaveNew",
    method: "post",
    data: data
  });
}
export function postMonthlySalary(data) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/batchSave",
    method: "post",
    data: data
  });
}
// 在线编辑

export function onlineEditNewReq(data) {
  return request({
    url: "/salary/api/v1/employeeAmounts/updateByMap",
    method: "put",
    data: data
  });
}
export function onlineEdit(data) {
  return request({
    url: "/salary/api/v1/employeeSalaries/updateByMap",
    method: "post",
    data: data
  });
}
// 分页查询渠道管理
export function getChannels(params) {
  return request({
    url: "/hr/api/v1/channels",
    method: "get",
    params: params
  });
}
// 查询标签
export function listByCompanyIdGet(params) {
  return request({
    url: "/hr/api/v1/empTag/listByCompanyId",
    method: "get",
    params: params
  });
}
// 系统生成费用账单
export function generatedBills(data) {
  return request({
    url: "/hr/api/v1/costBills/generatedBills",
    method: "post",
    data: data
  });
}
// 分页查询工资模板月工资汇总表
export function getMonthlySalary(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries",
    method: "get",
    params: params
  });
}
export function getMonthlySalaryNewReq(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/selectPageNew",
    method: "get",
    params: params
  });
}
// 薪资分页查询工资模板月工资汇总表
export function getMonthlySalaryXinZi(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/selectByCompanyIds",
    method: "get",
    params: params
  });
}
// 根据工资模板汇总id及薪资组Id查询薪资组月工资汇总
export function monthlySalaryById(params) {
  return request({
    url: "/salary/api/v1/salaryGroupSalaries/selectBySalaryTemplateSalaryIdsAndSalaryGroupId",
    method: "get",
    params: params
  });
}
// 根据工资模板汇总id查询薪资组
export function selectSalaryGroup(params) {
  return request({
    url: "/salary/api/v1/salaryGroupSalaries/selectSalaryGroupByTemplateSalaryIds",
    method: "get",
    params: params
  });
}
export function empSelectPageNewReq(params) {
  return request({
    url: "/salary/api/v1/employeeSalaryNews/selectEmployeeSalaryPage",
    method: "get",
    params: params
  });
}
export function empSelectPage(params) {
  return request({
    url: "/salary/api/v1/employeeSalaries/selectEmployeeSalaryPage",
    method: "get",
    params: params
  });
}
export function getEmpListAll(params) {
  return request({
    url: "/salary/api/v1/employeeSalaries/listAll",
    method: "get",
    params: params
  });
}

// 专项附加项模板下载
export function download(data) {
  return request({
    url: " /salary/api/v1/excel/template/download?templateName=cumulativeDeductionOfSpecialAdditionalItems.xlsx",
    method: "put",
    data: data
  });
}
// 模板id查询模板详情
export function gitTemplateDetail(params) {
  return request({
    url: "/salary/api/v1/salaryGroupSalaries/selectBySalaryTemplateSalaryIdsAndSalaryGroupSalaryId",
    method: "get",
    params: params
  });
}
export function gitTemplateDetailNewReq(params) {
  return request({
    url: "/salary/api/v1/employeeSalaryNews/selectSalaryTemplateSalarySummary",
    method: "get",
    params: params
  });
}
// 查询个人表头
export function gitTemplateHeader(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateItems/selectAllUsedVoBySalaryTemplateSalaryIds",
    method: "get",
    params: params
  });
}
// / 根据Id查询编辑
export function getEditEmpList(params) {
  return request({
    url: "/salary/api/v1/employeeSalaries/selectByIds",
    method: "get",
    params: params
  });
}
// 删除单个工资模板月工资汇总表
export function deleteTemplateSalary(salaryTemplateSalaryId) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/".concat(salaryTemplateSalaryId),
    method: "delete",
    salaryTemplateSalaryId: salaryTemplateSalaryId
  });
}
// 删除单个员工工资表，彻底删除
export function deleteEmploye(data) {
  return request({
    url: "/salary/api/v1/employeeSalaries",
    method: "delete",
    data: data
  });
}
export function deleteEmployeNewReq(data) {
  return request({
    url: "/salary/api/v1/employeeSalaryNews",
    method: "delete",
    data: data
  });
}

// 员工是否已经创建月工资
export function getCreated(params) {
  return request({
    url: "/salary/api/v1/employeeSalaries/isCreatedBySalaryTemplateIds",
    method: "get",
    params: params
  });
}
export function getCreatedReqNew(params) {
  return request({
    url: "/salary/api/v1/employeeSalaryNews/isCreatedBySalaryTemplateIds",
    method: "get",
    params: params
  });
}
// 指定员工在该月是否已经创建
export function getEmpCreated(data) {
  return request({
    url: "/salary/api/v1/employeeSalaries/selectCreatedByEmpIds",
    method: "post",
    data: data
  });
}
export function getEmpCreatedNewReq(data) {
  return request({
    url: "/salary/api/v1/employeeSalaryNews/selectCreatedByEmpIds",
    method: "post",
    data: data
  });
}
// 确认计薪人员
export function getEmpList(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/confirmPayrollPersonnel",
    method: "get",
    params: params
  });
}
export function getEmpListReqNew(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/confirmPayrollPersonnelNew",
    method: "get",
    params: params
  });
}
// 历史工资上传导入
export function importPayroll(data) {
  return request({
    url: "/salary/api/v1/employeeSalaries/import",
    method: "get",
    data: data
  });
}
// 查询模板下未定薪人员信息
export function getnotDetailsList(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/selectNotPayrollPersonnel",
    method: "get",
    params: params
  });
}
// 根据工资模板id同步员工社保公积金
export function synchronous(params) {
  return request({
    url: "/salary/api/v1/employeeSalaries/synchronizeSocialSecurityAndProvidentFund",
    method: "get",
    params: params
  });
}
export function syncSocAndPf(data) {
  return request({
    url: "/salary/api/v1/employeeSalaries/syncSocAndPf",
    method: "put",
    data: data
  });
}
export function syncSocAndPfNewReq(data) {
  return request({
    url: "/salary/api/v1/employeeSalaryNews/syncSocAndPf",
    method: "put",
    data: data
  });
}
// 计算个税
export function taxCalculation(monthly, yearly, companyId, salaryTemplateSalaryId) {
  return request({
    url: "/salary/api/v1/employeeSalaries/taxCalculation?monthly=".concat(monthly, "&yearly=").concat(yearly, "&companyId=").concat(companyId, "&salaryTemplateSalaryId=").concat(salaryTemplateSalaryId),
    method: "put"
  });
}
export function taxCalculationNewReq(monthly, yearly, companyId, salaryTemplateSalaryId) {
  return request({
    url: "/salary/api/v1/employeeSalaryNews/taxCalculation?monthly=".concat(monthly, "&yearly=").concat(yearly, "&companyId=").concat(companyId, "&salaryTemplateSalaryId=").concat(salaryTemplateSalaryId),
    method: "put"
  });
}
// 新增员工（详情）
export function addEmpDetail(data) {
  return request({
    url: "/salary/api/v1/employeeSalaries/saveByEmpList",
    method: "post",
    data: data
  });
}
export function addEmpDetailNewReq(data) {
  return request({
    url: "/salary/api/v1/employeeSalaryNews/saveByEmpList",
    method: "post",
    data: data
  });
}
// 查询最后一个月
export function getLastOne(params) {
  return request({
    url: "/salary/api/v1/monthlySalaries/selectLastOne",
    method: "get",
    params: params
  });
}
// 模板月工资归档
export function archive(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/archive",
    method: "put",
    params: params
  });
}
export function archiveNewReq(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/archiveNew",
    method: "put",
    params: params
  });
}
// 模板月工资批量归档
export function batchArchive(data) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/batchArchive",
    method: "put",
    data: data
  });
}
export function batchArchiveNewReq(data) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/batchArchiveNew",
    method: "put",
    data: data
  });
}
// 分页查询员工个税累计表
// export function getTax(params) {
//   return request({
//     url: "/salary/api/v1/empTaxGrandTotals/selectEmpTaxGrandTotalPage",
//     method: "get",
//     params
//   });
// }
export function getTax(params) {
  return request({
    url: "/salary/api/v1/empTaxGrandTotals/selectCompanyTaxGrandTotalPage",
    method: "get",
    params: params
  });
}
export function editTax(data) {
  return request({
    url: "/salary/api/v1/empTaxGrandTotals",
    method: "put",
    data: data
  });
}
export function account(data) {
  return request({
    url: "/salary/api/v1/employeeSalaries/updateByEmpList",
    method: "put",
    data: data
  });
}
export function getGroup(params) {
  return request({
    url: "/salary/api/v1/salaryGroups/selectBySalaryTemplateIds",
    method: "get",
    params: params
  });
}
// 创建下月月工资表 校验并归档上月工资表
export function filedLastMonth(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/filedLastMonth",
    method: "put",
    params: params
  });
}
export function filedLastMonthNewReq(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/filedLastMonthNew",
    method: "put",
    params: params
  });
}
// 删除模板时 查询该模板是否有未归档的月工资
export function getNotArchiveById(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/selectNotArchiveBySalaryTemplateId",
    method: "get",
    params: params
  });
}
// 模板在该月是否已经创建
export function getIsCreated(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/isCreated",
    method: "get",
    params: params
  });
}
// 根据月工资新增客户结算表
export function updateAutoTaxGrand(data) {
  return request({
    url: "/salary/api/v1/customerSettlements/saveByMonthlySalary",
    method: "POST",
    data: data
  });
}
// 新增工资模板月工资汇总表
export function createWageList(data) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries",
    method: "POST",
    data: data
  });
}
// 生成电子工资条
export function generateWageList(data) {
  return request({
    url: "/salary/api/v1/employeeSalaries/createSalaryBill",
    method: "POST",
    data: data
  });
}
// 查询审批状态
export function getSelectById(params) {
  return request({
    url: "/hr/api/v1/costBills/selectByMonthSalaryId",
    method: "get",
    params: params
  });
}
// 考勤记录
export function getPageList(params) {
  return request({
    url: "/hr/api/v1/attendanceDataRecords/pageList",
    method: "GET",
    params: params
  });
}
// 导入考勤记录
export function getImportMonthSallary(params) {
  return request({
    url: "/hr/api/v1/attendanceDataTemps/importMonthSallary",
    method: "GET",
    params: params
  });
}
export function getImportMonthSalaryNewReq(data) {
  return request({
    url: "/hr/api/v1/attendanceDataTemps/importMonthSalary",
    method: "put",
    data: data
  });
}
// 表头
export function getHeaders(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateHeaders/selectAllHeaderDetail",
    method: "GET",
    params: params
  });
}
export function getSelectHeadersNewReq(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateHeaders/selectHeaderDetailNew",
    method: "GET",
    params: params
  });
}
export function getSelectHeaders(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateHeaders/selectHeaderDetail",
    method: "GET",
    params: params
  });
}
export function editHeaders(data) {
  return request({
    url: "/salary/api/v1/salaryTemplateHeaders/saveOrUpdate",
    method: "put",
    data: data
  });
}
export function updateHead(data) {
  return request({
    url: "/admin/api/v1/headerFields/updateMyselfHead",
    method: "put",
    data: data
  });
}