/*
 * @Description: 员工人事变动
 * @Author: zhr
 */
import request from "@/utils/request";

// 根据员工人事变动记录id查询员工人事变动记录详情
export function getEmpChanges(data) {
  return request({
    url: "/hr/api/v1/empChanges/" + data.id,
    method: "get",
    params: data
  });
}

// 离职
export function leavePosition(data) {
  return request({
    url: "/hr/api/v1/empChanges/batchLeavePosition",
    method: "post",
    data: data
  });
}
// 调岗
export function transferJob(data) {
  return request({
    url: "/hr/api/v1/empChanges/batchTransferPosition",
    method: "post",
    data: data
  });
}
// 查询调岗信息
export function getTransferJobInfo(data) {
  return request({
    url: "/hr/api/v1/positionInfos/findByEmpId/" + data.id,
    method: "get",
    params: data
  });
}