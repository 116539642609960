var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      padding: "10px 0"
    }
  }, [_vm.noApproval ? _c("div", [_vm._v(" 费用账单已删除 ")]) : _c("div", [_vm.autoSystem ? _c("a-table", {
    attrs: {
      columns: _vm.getColumns(),
      "data-source": _vm.data,
      bordered: "",
      scroll: {
        x: _vm.ColumnsLength * 200,
        y: 450
      }
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(text) {
        return [_c("a", [_vm._v(_vm._s(text))])];
      }
    }], null, false, 1091266701)
  }) : _c("div", [_c("div", [_c("a-row", [_c("a-col", {
    staticClass: "textOv",
    attrs: {
      span: 12
    }
  }, [_c("a", {
    staticClass: "textOvSpan",
    attrs: {
      href: _vm.annexUrl,
      target: "view_window"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "paper-clip"
    }
  }), _vm._v(" " + _vm._s(_vm.annexName) + " ")], 1)])], 1)], 1)])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };