import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-tabs", {
    attrs: {
      type: "card"
    },
    on: {
      change: _vm.onGroupTabChange
    }
  }, _vm._l(_vm.source, function (item) {
    return _c("a-tab-pane", {
      key: item.id
    }, [_c("a-badge", {
      attrs: {
        slot: "tab",
        count: item.count,
        showZero: true,
        offset: [10, -6]
      },
      slot: "tab"
    }, [_c("div", [_vm._v(" " + _vm._s(item.name) + " ")])]), _c("div", {
      staticClass: "sub-tab"
    }, [_c("a-tabs", {
      on: {
        change: _vm.onTypeTabChange
      }
    }, _vm._l(item.noticeTypeList, function (subItem) {
      return _c("a-tab-pane", {
        key: subItem.id || "all"
      }, [_c("span", {
        attrs: {
          slot: "tab"
        },
        slot: "tab"
      }, [_vm._v(_vm._s("".concat(subItem.name, "(").concat(subItem.count, ")")))])]);
    }), 1)], 1)], 1);
  }), 1), _vm.noticeList.length > 0 ? _c("div", _vm._l(_vm.noticeList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "mb12",
      staticStyle: {
        display: "flex",
        "align-items": "center",
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.goDetail(item);
        }
      }
    }, [_c("a-tooltip", {
      attrs: {
        title: item.title
      }
    }, [_c("div", {
      staticClass: "notice-title"
    }, [_vm._v(_vm._s(item.title))])]), _c("div", [_c("a-tooltip", {
      attrs: {
        title: item.content
      }
    }, [_c("div", {
      staticClass: "notice-content"
    }, [_vm._v(_vm._s(item.content))])]), _c("div", {
      staticClass: "mt8 fs12",
      staticStyle: {
        height: "20px",
        "line-height": "20px",
        color: "rgba(166, 168, 180, 1)"
      }
    }, [_c("img", {
      staticStyle: {
        height: "16px",
        "margin-right": "4px",
        display: "inline-block"
      },
      attrs: {
        src: require("./bank.png")
      }
    }), _vm._v(" " + _vm._s(item.companyName) + " ")])], 1), _c("div", {
      staticClass: "fs12",
      staticStyle: {
        color: "rgba(166, 168, 180, 1)"
      }
    }, [_vm._v(_vm._s(_vm.formatDateAll(item.gmtCreate)))])], 1);
  }), 0) : _c("a-empty", {
    staticClass: "pb24"
  }), _c("div", {
    staticClass: "ta-center",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)",
      "border-top": "1px solid #e8e8e8",
      height: "48px",
      "line-height": "48px",
      cursor: "pointer",
      margin: "0 -24px -24px -24px"
    },
    on: {
      click: _vm.goMore
    }
  }, [_vm._v(" 查看更多事项 "), _c("a-icon", {
    staticClass: "ml4",
    attrs: {
      type: "right"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };