import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.unshift.js";
import { creatCode as _creatCode, loadAllChannelForSiteReq } from "@/api/staff/staff";
import { getSubjectListReq, editSubjectList, getCalendar, editType } from "./api";
import moment from "moment";
import { afterTime } from "@/utils/index";
import { getModelList_isUse } from "@/api/billing/billing";
import { mapGetters, mapState } from "vuex";
export default {
  data: function data() {
    return {
      appItem: null,
      muban: undefined,
      SMDefault: 5,
      channelDefault: undefined,
      FailureTime: "",
      codeimg: "",
      loadingcode: false,
      refreshCode: false,
      timeDay: 7,
      typeValue: 1,
      appCode: null,
      siteId: null,
      loading: false,
      visible: false,
      dynamicDate: moment(moment().format("YYYY-MM"), "YYYY-MM"),
      weekList: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"],
      dateList: [],
      appList: [],
      companyId: this.$store.state.hr.companyId,
      subjectId: this.$route.query.id,
      isZXR: !window.location.host.includes("csxr.cn"),
      subjectList: [],
      setList: [],
      setName: "",
      appName: null,
      setCode: null,
      keyDate: 0,
      SMMubanList: [],
      siteChannelList: []
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(["settings"])), mapGetters(["isJF"])), {}, {
    xcxCode: function xcxCode() {
      if (window.location.host.includes("csxr.cn")) {
        return "csxr_xcx";
      }
      return this.settings.xcxCode;
    },
    postTypeOptions: function postTypeOptions() {
      return this.$store.state.enums.postTypeLst.map(function (item) {
        return {
          value: item.value,
          label: item.title
        };
      });
    }
  }),
  created: function created() {
    var _this = this;
    if (localStorage.getItem("siteId")) {
      this.siteId = localStorage.getItem("siteId");
    }
    this.getSubjectList();
    // this.getCalendarList();
    // 请求二维码审核模板
    getModelList_isUse(this.companyId, "OnJob").then(function (res) {
      _this.SMMubanList = [];
      for (var i = 0; i < res.data.length; i++) {
        _this.SMMubanList.push({
          value: res.data[i].id,
          label: res.data[i].typeName
        });
      }
      if (_this.SMMubanList.length > 0) {
        // 默认选中第一条
        _this.muban = _this.SMMubanList[0].value;
        console.log(_this.muban);
      } else {
        _this.muban = undefined;
      }
    });
    this.loadSiteChannelList();
  },
  methods: {
    loadSiteChannelList: function loadSiteChannelList() {
      var _this2 = this;
      loadAllChannelForSiteReq().then(function (res) {
        if (res.code === 200) {
          _this2.siteChannelList = res.data ? res.data.map(function (item) {
            return {
              label: item.name,
              value: item.id
            };
          }) : [];
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleOk: function handleOk(e) {
      this.visible = false;
    },
    handleCancel: function handleCancel(e) {
      this.visible = false;
    },
    openSet: function openSet(e) {
      var _this3 = this;
      this.setCode = e.code;
      this.setName = e.name;
      var appList = JSON.parse(JSON.stringify(this.appList));
      appList.forEach(function (item, index) {
        if (item.code === _this3.setCode) {
          _this3.setList = JSON.parse(JSON.stringify(item.childList));
        }
      });
      this.visible = true;
    },
    menuChange: function menuChange(e) {
      var _this4 = this;
      this.appItem = e;
      this.appCode = e.code;
      this.appName = e.name;
      var subjectList = JSON.parse(JSON.stringify(this.subjectList));
      subjectList.forEach(function (item, index) {
        if (item.code === _this4.appCode) {
          _this4.appList = JSON.parse(JSON.stringify(item.childList));
        }
      });
      this.keyDate += 1;
    },
    creatCode: function creatCode() {
      var _this5 = this;
      this.loadingcode = true;
      var par = {
        companyId: this.$route.query.id,
        status: this.typeValue,
        validDays: this.timeDay,
        postType: this.SMDefault,
        channelId: this.channelDefault ? this.channelDefault.split("_")[0] : "",
        expireDate: this.timeDay ? new Date(this.FailureTime + ":00").getTime() : ""
      };
      if (this.typeValue === 1) {
        // 待入职
        par.flowId = this.muban;
        if (!par.flowId && this.SMMubanList.length > 0) {
          this.$notification["success"]({
            message: "success",
            description: "请选择审批模板"
          });
          return;
        }
      }
      _creatCode(par).then(function (res) {
        _this5.loadingcode = false;
        if (res.data) {
          _this5.typeValue = res.data.status;
          _this5.codeimg = res.data.qrCodeUrl;
          _this5.timeDay = res.data.validDays;
          _this5.SMDefault = res.data.postType;
          var date = new Date(res.data.expireDate); // 时间戳转date
          _this5.FailureTime = _this5.moment(date).format("YYYY-MM-DD HH:MM");
          if (res.data.flowId === null) {
            _this5.muban = undefined;
          } else {
            _this5.muban = res.data.flowId;
          }
          _this5.refreshCode = false;
          if (!_this5.codeimg) {
            _this5.refreshCode = true;
          }
        } else {
          _this5.loadingcode = false;
          _this5.$notification["success"]({
            message: "success",
            description: "二维码创建失败"
          });
        }
      }).catch(function (err) {
        console.log(err);
        _this5.loadingcode = false;
      });
    },
    downloadCod: function downloadCod() {
      window.open(this.codeimg + "?token=".concat(this.$store.state.auth.token));
    },
    postTypeSaoMaChange: function postTypeSaoMaChange(value) {
      this.SMDefault = value;
      this.refreshCode = true;
    },
    channelSaoMaChange: function channelSaoMaChange(value) {
      this.channelDefault = value;
      this.refreshCode = true;
    },
    setAllContent: function setAllContent(val) {
      this.timeDay = val;
      if (val) {
        var d = new Date();
        var newTime = afterTime(this.moment(d, "YYYY-MM-DD"), this.timeDay);
        this.FailureTime = newTime + " " + d.getHours() + ":" + d.getMinutes();
      }
      this.refreshCode = true;
    },
    onTypeChange: function onTypeChange(e) {
      this.typeValue = e.target.value;
      console.log(this.typeValue);
      this.refreshCode = true;
    },
    mubanSelect: function mubanSelect(value) {
      this.muban = value;
      this.refreshCode = true;
    },
    dateChange: function dateChange(e) {
      if (e === 1) {
        this.dynamicDate = moment(this.dynamicDate).subtract(1, "months");
        this.getCalendarList();
      }
      if (e === 2) {
        this.dynamicDate = moment(this.dynamicDate).subtract(-1, "months");
        this.getCalendarList();
      }
    },
    // 更改休假上班
    editTypeChange: function editTypeChange(item) {
      var _this6 = this;
      editType({
        date: item.date,
        dateType: item.dateType === 1 ? 2 : 1,
        siteId: this.siteId,
        subjectId: this.subjectId
      }).then(function (res) {
        _this6.$message.success("切换成功");
        _this6.getCalendarList();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    mouseover: function mouseover(item, index) {
      this.$set(this.dateList, index, _objectSpread(_objectSpread({}, this.dateList[index]), {}, {
        hover: true
      }));
    },
    mouseleave: function mouseleave(item, index) {
      this.$set(this.dateList, index, _objectSpread(_objectSpread({}, this.dateList[index]), {}, {
        hover: false
      }));
    },
    mouseover2: function mouseover2(item, index) {
      this.$set(this.dateList, index, _objectSpread(_objectSpread({}, this.dateList[index]), {}, {
        hover2: true
      }));
    },
    mouseleave2: function mouseleave2(item, index) {
      this.$set(this.dateList, index, _objectSpread(_objectSpread({}, this.dateList[index]), {}, {
        hover2: false
      }));
    },
    // 返回今天
    goBack: function goBack() {
      this.dynamicDate = moment(moment().format("YYYY-MM"), "YYYY-MM");
      this.getCalendarList();
    },
    // 选择月份变化
    onMonthChange: function onMonthChange() {
      this.getCalendarList();
    },
    // 查询的
    getCalendarList: function getCalendarList() {
      var _this7 = this;
      this.loading = true;
      getCalendar({
        month: moment(this.dynamicDate).format("MM"),
        year: moment(this.dynamicDate).format("YYYY"),
        siteId: this.siteId,
        subjectId: this.subjectId,
        "orders[0].desc": false,
        "orders[0].property": "date",
        subjectType: "company"
      }).then(function (res) {
        _this7.loading = false;
        _this7.dateList = res.data;
        _this7.dateList = JSON.parse(JSON.stringify(res.data));
        if (moment(_this7.dateList[0].date).day() !== 1) {
          var num = moment(_this7.dateList[0].date).day() - 1;
          for (var i = 0; i < num; i++) {
            _this7.dateList.unshift({});
          }
        }
        if (moment(_this7.dateList[_this7.dateList.length - 1].date).day() !== 0) {
          var _num = 7 - moment(_this7.dateList[_this7.dateList.length - 1].date).day();
          for (var o = 0; o < _num; o++) {
            _this7.dateList.push({});
          }
        }
        _this7.dateList.forEach(function (item, index) {
          if (item.initDateType === 1 && item.month === parseInt(moment(_this7.dynamicDate).format("MM"))) {
            item.style = "background:#24CDAA";
          }
          if (item.initDateType === 1 && item.month !== parseInt(moment(_this7.dynamicDate).format("MM"))) {
            item.style = "background:#24CDAA; opacity: 0.5;";
          }
          if (item.initDateType === 2 && item.month === parseInt(moment(_this7.dynamicDate).format("MM"))) {
            item.style = "background:#C0C0C0";
          }
          if (item.initDateType === 2 && item.month !== parseInt(moment(_this7.dynamicDate).format("MM"))) {
            item.style = "background:#C0C0C0; opacity: 0.5;";
          }
          if (item.initDateType === 3 && item.month === parseInt(moment(_this7.dynamicDate).format("MM"))) {
            item.style = "background:#FFA600";
          }
          if (item.initDateType === 3 && item.month !== parseInt(moment(_this7.dynamicDate).format("MM"))) {
            item.style = "background:#FFA600; opacity: 0.5;";
          }
        });
        _this7.dateList.forEach(function (item, index) {
          item.hover = false;
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this7.loading = false;
      });
    },
    hideChange: function hideChange(e) {
      var _this8 = this;
      console.log(e);
      var arr = JSON.parse(JSON.stringify(e));
      this.loading = true;
      editSubjectList({
        id: arr.id,
        isHide: arr.isHide,
        subjectId: this.subjectId,
        subjectType: "company"
      }).then(function (res) {
        console.log(res);
        _this8.$message.success("修改成功");
        _this8.getSubjectList();
      }).catch(function (error) {
        _this8.getSubjectList();
        console.log("Error", error);
      });
    },
    getSubjectList: function getSubjectList() {
      var _this9 = this;
      getSubjectListReq({
        subjectId: this.subjectId,
        subjectType: "company",
        code: this.xcxCode
      }).then(function (res) {
        _this9.loading = false;
        _this9.subjectList = JSON.parse(JSON.stringify(res.data));
        _this9.editIcon(_this9.subjectList);
        if (!_this9.appCode) {
          _this9.appCode = _this9.subjectList[0].code;
          _this9.appName = _this9.subjectList[0].name;
          _this9.appItem = _this9.subjectList[0];
        }
        if (_this9.appCode) {
          _this9.subjectList.forEach(function (item, index) {
            if (item.code === _this9.appCode) {
              _this9.appList = JSON.parse(JSON.stringify(item.childList));
            }
          });
        }
        if (_this9.setCode) {
          _this9.appList.forEach(function (item, index) {
            if (item.code === _this9.setCode) {
              _this9.setList = JSON.parse(JSON.stringify(item.childList));
            }
          });
        }
      }).catch(function (error) {
        _this9.loading = false;
        console.log("Error", error);
      });
    },
    editIcon: function editIcon(data) {
      var _this10 = this;
      data.forEach(function (item, index) {
        item.icon = item.icon.split(",");
        if (item.childList && item.childList.length) {
          _this10.editIcon(item.childList);
        }
      });
    },
    callback: function callback(key) {
      this.loadingcode = false;
      this.tabKey = key;
      if (this.tabKey === "3") {
        this.loadingcode = true;
        var d = new Date();
        var newTime = afterTime(this.moment(d, "YYYY-MM-DD"), this.timeDay);
        this.FailureTime = newTime + " " + d.getHours() + ":" + d.getMinutes();
        this.creatCode();
        //   getCode({
        //     companyId: this.$store.state.hr.companyId
        //   }).then(res => {
        //     this.loadingcode = false;
        //     if (res.data) {
        //       this.typeValue = res.data.status;
        //       this.codeimg = res.data.qrCodeUrl;
        //       this.timeDay = res.data.validDays;
        //       this.SMDefault = res.data.postType;
        //       const date = new Date(res.data.expireDate); // 时间戳转date
        //       this.FailureTime = this.moment(date).format("YYYY-MM-DD HH:MM");
        //       if (res.data.flowId === null) {
        //         this.muban = undefined;
        //       } else {
        //         this.muban = res.data.flowId;
        //         let has = false;
        //         for (var m = 0; m < this.SMMubanList.length; m++) {
        //           if (this.muban === this.SMMubanList[m].value) {
        //             has = true;
        //             break;
        //           }
        //         }
        //         if (!has) {
        //           this.muban = undefined;
        //         }
        //       }
        //     } else {
        //       var d = new Date();
        //       const newTime = afterTime(this.moment(d, "YYYY-MM-DD"), this.timeDay);
        //       this.FailureTime = newTime + " " + d.getHours() + ":" + d.getMinutes();
        //       this.refreshCode = true;
        //     }
        //   });
      }
    }
  }
};