import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.slice.js";
import { getEmpInfoCount, leavePosition, transferRegular, confirmToJoinBatch, confirmToNoJoinBatch, saveEmpInfoPre, selectEmpInfoOnJobRelation, selectEmpInfoPendingRelation, selectEmpInfoToFormalRelation, empRegisterAccount, selectEmpInfoTransferRelation, onJobAgain, confimLeave, findEmpHaveSocInfo, selectEmpInfoListForLeave, revokeLeaveApi, confimTransferPositionApi, revokeTransferPositionApi, onJobFlows, pageWaitLeave,
// 获取某公司离职可用的的所有审批流程
leavingFlows,
// 获取某公司调岗可用的的所有审批流程
transferDutyFlows,
// 获取某公司转正可用的的所有审批流程
turnStraightFlows, saveEmpInfoAndRule, selectResigned, batchRevokeTransferPositionByEmpId, batchConfirmTransferPosition } from "@/api/staff/staff";
import { getDepartmentInTree } from "@/api/department/department";
import { getPositionInTree } from "@/api/department/position";
import { transferJob } from "@/api/staff/empChanges";
import { formatDate, formatDateDefault, afterTime } from "@/utils/index";
import { validPhone } from "@/utils/validate";
import { getModelList_isUse } from "@/api/billing/billing";
import positonTree from "@/components/SelectTree/position";
import axios from "axios";
import qs from "qs";
import DynamicAddEmployees from "../management/info/component/dynamicAddEmployees";
import { getEmpField, leaveBatchReq } from "../management/info/api";
import moment from "moment";
import BatchLeaveFilter from "./component/batchLeaveFilter";
import { tabList, managementList, columnDialogDetailguding, pagination, pagination1, pilianglizhiColumns } from "./indexFuncs";
import employeesMoveAdvancedScreening from "./component/employeesMoveAdvancedScreening";
export default {
  components: {
    BatchLeaveFilter: BatchLeaveFilter,
    DynamicAddEmployees: DynamicAddEmployees,
    positonTree: positonTree,
    employeesMoveAdvancedScreening: employeesMoveAdvancedScreening
  },
  inject: ["reload"],
  data: function data() {
    var _this = this;
    return {
      pilianglizhiColumns: pilianglizhiColumns,
      pilianglizhiResults: [],
      pilianglizhiResultModal: false,
      batchPreQuitDate: null,
      leaveEmyList: [],
      isBatchLeaveConfirm: false,
      isBatchJoin: false,
      disableJoinDate: null,
      confirmedPostArr: {},
      visibleJoinDate: false,
      confirmLoadingJoinDate: false,
      formPositive: this.$form.createForm(this, {
        name: "coordinated"
      }),
      formJoinDate: this.$form.createForm(this, {
        name: "coordinated1"
      }),
      visiblePositive: false,
      confirmLoadingPositive: false,
      batchArrData: [],
      openBulkDepartures: false,
      selectedRowKeysModelIds: [],
      outEmpDetail: {},
      saveValueFrom: {},
      tempTemplate: undefined,
      templatesListGroup: [],
      isApproval: true,
      isCloseMethod2: false,
      selectModel: false,
      isOut: false,
      status2: [{
        value: 1,
        label: "试用",
        name: "countWaitInformalAll",
        count: 0
      }, {
        value: 2,
        label: "正式",
        name: "countHasInformalAll",
        count: 0
      }],
      empState: true,
      serviceCost: undefined,
      empyleeForm: this.$form.createForm(this),
      liststatus: [{
        value: "待入职",
        id: "1"
      }, {
        value: "审批中",
        id: "2"
      }, {
        value: "审批驳回",
        id: "3"
      }, {
        value: "未到岗",
        id: "4"
      }],
      visibleEmpyleeDrawer2: false,
      spinning: false,
      zzbm: undefined,
      lzbm: undefined,
      nameDisable: false,
      nameDisabled: false,
      columnDialogDetailguding: columnDialogDetailguding,
      selectId: "1",
      pagination: pagination1,
      tabList: tabList,
      // 筛选条件
      searchForm: {},
      columns: [{
        title: "姓名",
        dataIndex: "name"
      }, {
        title: "身份证号",
        dataIndex: "idCard",
        width: 200
      }, {
        title: "手机号",
        dataIndex: "phone"
      }, {
        title: "工作性质",
        dataIndex: "postType",
        customRender: function customRender(text, record, index) {
          return _this.$store.state.enums.postTypeArr[text - 1];
        }
      }, {
        title: "入职日期",
        dataIndex: "joinDate",
        customRender: function customRender(text, record, index) {
          return formatDate(text);
        }
      }
      // { title: "工作邮箱", dataIndex: "email" }
      ],

      pagination2: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1
        // showQuickJumper: true
      },

      validPhone: validPhone,
      haveResigned: false,
      reasonResigned: null,
      companyIdResigned: null,
      visibleModal: false,
      leaveTag: false,
      moveTag: false,
      selectValue: "",
      isCloseMethod: false,
      leaveEmy: undefined,
      empInfoList: [],
      titleSubmit: "办理入职",
      drawerTitle: "",
      oktext: "继续办理",
      visibleConfimSubmit: false,
      visibleJoinDrawer: false,
      visibleLeaveDrawer: false,
      visibleRegularDrawer: false,
      visibleEmpyleeDrawer: false,
      visibleLeaveModal: false,
      visibleMoveDrawer: false,
      entryForm: this.$form.createForm(this),
      regularForm: this.$form.createForm(this),
      leaveForm: this.$form.createForm(this),
      // empyleeForm: this.$form.createForm(this),
      moveForm: this.$form.createForm(this),
      employTypeOptions: [{
        value: "1",
        label: "正式"
      }],
      employType: "",
      informalTypeOptions: [{
        value: "1",
        label: "实习"
      }, {
        value: "2",
        label: "劳务"
      }, {
        value: "3",
        label: "雇佣"
      }, {
        value: "4",
        label: "返聘"
      }, {
        value: "5",
        label: "外包"
      }],
      action: "",
      depInfoOptions: [],
      depInfoOptions2: [],
      // 在所有部门的基础上手动添加了没有任何部门的选项
      positionOptions: [],
      adjustButtonDisabled: false,
      shebaoDate: moment().format("YYYY-MM"),
      gongjijinDate: moment().format("YYYY-MM"),
      yibaoDate: moment().format("YYYY-MM"),
      visibleMerber: false,
      visibleSubmit: false,
      iszheng: false,
      approvalModal: false,
      visibleDrawer: false,
      checkedListGroup: [],
      dataDetail2: [],
      joinFormData: {},
      regularFormData: {},
      companyId: "",
      selectBox: false,
      total: 0,
      sexvalue: "",
      depIdvalue: "",
      employTypevalue: "",
      formalTypevalue: "",
      selectedRowKeys: [],
      selectedTempRowKeys: [],
      monthFormat: "YYYY-MM",
      leaveFormat: "YYYY-MM-DD",
      isShebaoJianYuan: false,
      isYibaoJianYuan: false,
      isGjjJianYuan: false,
      hasShebao: false,
      hasGjj: false,
      hasYibao: false,
      renliStatus: 0,
      // 0拖拽，1成功，2失败
      // 表格
      managementList: managementList,
      leaveList: [],
      // 姓名或手机号
      nameOrPhoneOrEmail: "",
      // 性别
      sexOptions: [{
        value: "1",
        label: "男"
      }, {
        value: "2",
        label: "女"
      }],
      sex: "",
      depId: "",
      // 转正状况
      formalTypeOptions: [{
        value: 1,
        label: "试用期"
      }, {
        value: 2,
        label: "转正"
      }],
      // 员工排序
      staffSort: [{
        id: 0,
        name: "发起离职合同",
        key: "namePinyin"
      }, {
        id: 1,
        name: "导入Excel办理",
        key: "gmtCreate"
      }, {
        id: 2,
        name: "已离职记录",
        key: "gmtModified"
      }],
      formalType: "",
      FlowsData: [],
      redisValues: {},
      gmtOrder: "gmtCreate",
      joinFormStatus: undefined,
      preJoinDateEnd: undefined,
      preJoinDateStart: undefined,
      positonTreeShow: false,
      checkedPostionListGroup: [],
      socFile: undefined,
      isHeTong: false,
      piliang_shebaojianyuan: false,
      newType: null,
      // channelId: "", // 渠道id
      // 转正日期
      proRegularDateShow: false,
      // 试用日期
      testDate: false,
      flag: 0,
      // 文字显示状态
      currentItem: undefined,
      EmpState: null
    };
  },
  computed: {
    postTypeOptions2: function postTypeOptions2() {
      return this.$store.state.enums.postTypeLst;
    }
  },
  created: function created() {
    var _this2 = this;
    if (this.$route.query.selectId) {
      this.selectId = this.$route.query.selectId;
      this.leaveTag = this.$route.query.leaveTag;
      this.moveTag = this.$route.query.moveTag;
      this.searchForm.nameOrPhone = this.$route.query.nameOrPhone;
    }
    if (this.$route.query.outEmpDetail) {
      this.drawerTitle = "添加待入职";
      if (this.$route.query.EmpState) {
        this.EmpState = this.$route.query.EmpState;
      }
      this.outEmpDetail = JSON.parse(this.$route.query.outEmpDetail);
      this.outEmpDetail.idCard = this.outEmpDetail.idcard + "";
      if (this.outEmpDetail.joinDate) {
        this.outEmpDetail.joinDate = this.$moment(this.outEmpDetail.joinDate).format("YYYY-MM-DD");
      }
      var data1 = this.outEmpDetail;
      Object.keys(data1).map(function (z) {
        if (typeof data1[z] === "number" && data1[z].toString().length === 1) {
          data1[z] = data1[z] + "";
        }
      });
      this.joinFormData = data1;
    }
    if (this.EmpState === "out") {
      this.visibleEmpyleeDrawer2 = true;
      this.EmpState = false;
    }
    this.companyId = this.$store.state.hr.companyId;
    if (this.$route.query.selectId || this.$route.query.outEmpDetail) {
      // 外部带参数跳转进来的
      pagination.current = 1;
    } else {
      // 读取缓存
      this.getCaCh();
    }
    this.getTabNumber();
    this.getEmpInfos();
    getDepartmentInTree({
      companyId: this.companyId
    }).then(function (res) {
      _this2.depInfoOptions = _this2.tree(res.data[0].depChildList, "depChildList", false);
      _this2.depInfoOptions2 = _this2.tree(res.data[0].depChildList, "depChildList", false);
    });
    getPositionInTree({
      companyId: this.companyId
    }).then(function (res) {
      _this2.positionOptions = _this2.tree1(res.data[0].positionChildList, "positionChildList", true);
    });
  },
  mounted: function mounted() {
    var st = this.$route.query.operateFromShortcut;
    if (st) {
      switch (st) {
        case "rzdl":
          break;
      }
    }
  },
  methods: {
    formatLeaveEmyListNames: function formatLeaveEmyListNames(list) {
      if (list.length > 2) {
        return "".concat(list[0].name, "\u7B49 ").concat(list.length, " \u4EBA");
      } else {
        return list.map(function (item) {
          return item.name;
        }).join("、");
      }
    },
    getCaCh: function getCaCh() {
      if (JSON.stringify(this.$getCondition("ygyd_condition")) !== "{}") {
        var dparams = JSON.parse(this.$getCondition("ygyd_condition"));
        if (dparams.companyId && dparams.companyId === this.$store.state.hr.companyId) {
          if (dparams.selectId) {
            this.selectId = dparams.selectId;
          }
          if (dparams.nameOrPhone) {
            this.searchForm.nameOrPhone = dparams.nameOrPhone;
          }
          if (dparams.approvalAndStatus) {
            this.searchForm.approvalAndStatus = dparams.approvalAndStatus;
          }
          if (dparams.joinFormStatus) {
            this.searchForm.joinFormStatus = dparams.joinFormStatus;
          }
          // 预计入职开始时间
          if (dparams.preJoinDateStart && dparams.preJoinDateEnd) {
            this.searchForm.preJoinDateMoment = [dparams.preJoinDateStart, dparams.preJoinDateEnd];
          }
          // 入职开始时间
          if (dparams.joinDateStart && dparams.joinDateEnd) {
            this.searchForm.joinDateMoment = [dparams.joinDateStart, dparams.joinDateEnd];
          }
          // 预计转正时间
          if (dparams.turnStraightDateStart && dparams.turnStraightDateEnd) {
            this.searchForm.turnStraightDateMoment = [dparams.turnStraightDateStart, dparams.turnStraightDateEnd];
          }
          // 预计离职时间
          if (dparams.leaveDateStart && dparams.leaveDateEnd) {
            this.searchForm.leaveDateMoment = [dparams.leaveDateStart, dparams.leaveDateEnd];
          }
          //  调岗开始时间 结束时间
          if (dparams.transferDateStart && dparams.transferDateEnd) {
            this.searchForm.transferDateMoment = [dparams.transferDateStart, dparams.transferDateEnd];
          }

          // 读取缓存页码
          if (dparams.pageNo) {
            this.managementList.pagination.current = dparams.pageNo;
          }
          if (dparams.pageSize) {
            this.managementList.pagination.pageSize = dparams.pageSize;
          }
        }
      }
    },
    saveLeaveOk: function saveLeaveOk() {
      this.getTabNumber();
      this.tapChange(this.selectId);
    },
    // 批量离职
    batchLeave: function batchLeave(e) {
      var _this3 = this;
      this.$nextTick(function () {
        _this3.$refs.BatchLeaveFilterSelect.showModal(e);
      });
    },
    // 继续添加
    handleResigneCancel: function handleResigneCancel() {
      this.haveResigned = false;
      this.handleEmpyleeSubmit2Pre();
    },
    // 前往花名册
    handleOk: function handleOk() {
      var _this4 = this;
      var data = {
        queryType: 1,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        companyId: this.companyIdResigned.companyId,
        "orders[0].property": this.gmtOrder,
        "orders[0].desc": this.gmtOrder !== "namePinyin"
      };
      var data1 = JSON.parse(JSON.stringify(data));
      data1.joinDateMoment = null;
      this.$store.dispatch("onCompanyClick", {
        id: this.companyIdResigned.companyId,
        name: this.companyIdResigned.companyName
      });
      this.$router.push({
        path: "/staff/detail",
        query: {
          companyId: this.companyIdResigned.companyId,
          searchFormData: data1,
          id: this.companyIdResigned.id,
          userId: this.companyIdResigned.userId
        }
      });
      setTimeout(function () {
        _this4.$router.go(0);
      }, 1000);
    },
    cancelForm: function cancelForm() {
      this.visibleEmpyleeDrawer2 = false;
      this.visibleJoinDate = false;
      this.isBatchJoin = false;
    },
    saveValueChange: function saveValueChange(data) {
      var _this5 = this;
      console.log(data);
      this.saveValueFrom = data;
      if (this.drawerTitle === "添加待入职") {
        selectResigned({
          idCard: this.saveValueFrom.idCard ? this.saveValueFrom.idCard : null,
          phone: this.saveValueFrom.phone ? this.saveValueFrom.phone : null,
          companyId: this.$store.state.hr.companyId
        }).then(function (res) {
          if (res.data && res.data.haveResigned) {
            _this5.haveResigned = true;
            _this5.reasonResigned = res.data.reason;
            _this5.companyIdResigned = res.data;
          } else {
            _this5.handleEmpyleeSubmit2Pre();
          }
        });
      } else {
        this.handleEmpyleeSubmit2Pre();
      }
    },
    temClick: function temClick(item) {
      for (var j = 0; j < this.templatesListGroup.length; j++) {
        if (this.templatesListGroup[j].id === item.id) {
          this.templatesListGroup[j].select = true;
          this.tempTemplate = this.templatesListGroup[j].id;
        } else {
          this.templatesListGroup[j].select = false;
        }
      }
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    selectChange: function selectChange(value) {
      var _this6 = this;
      this.newType = value;
      if (value === 3) {
        this.empState = false;
      }
      if (value === 1) {
        this.proRegularDateShow = false;
        this.testDate = true;
        // 根据试用期计算
        if (this.empyleeForm.getFieldValue("joinDate") != null) {
          var newTime = afterTime(this.empyleeForm.getFieldValue("joinDate"), 0);
          setTimeout(function () {
            _this6.empyleeForm.setFieldsValue({
              turnStraightDate: _this6.moment(newTime, "YYYY-MM-DD")
            });
          }, 300);
        } else {
          setTimeout(function () {
            _this6.empyleeForm.setFieldsValue({
              turnStraightDate: undefined
            });
          }, 300);
        }
      } else {
        this.proRegularDateShow = true;
        this.testDate = false;
        if (this.empyleeForm.getFieldValue("joinDate") != null) {
          var _newTime = afterTime(this.empyleeForm.getFieldValue("joinDate"), 0);
          setTimeout(function () {
            _this6.empyleeForm.setFieldsValue({
              turnStraightDate: _this6.getMoment(_newTime)
            });
          }, 300);
        } else {
          setTimeout(function () {
            _this6.empyleeForm.setFieldsValue({
              turnStraightDate: undefined
            });
          }, 300);
        }
      }
    },
    finishEdit3: function finishEdit3() {
      var _this7 = this;
      if (this.currentItem) {
        // 再次发起或者重新入职
        onJobAgain({
          empId: this.currentItem.id,
          flowId: this.tempTemplate
        }).then(function (res) {
          _this7.getEmpInfos();
          if (_this7.tempTemplate) {
            _this7.isApproval = true;
          } else {
            // 添加成功
            _this7.isApproval = false;
          }
          _this7.isCloseMethod2 = true;
        });
      } else {
        this.handleEmpyleeSubmit2();
      }
      this.selectModel = false;
    },
    // 重新入职
    resJoinOrreApproval: function resJoinOrreApproval(e, index) {
      var _this8 = this;
      this.currentItem = e;

      // 根据公司查询模板
      getModelList_isUse(this.companyId, "OnJob").then(function (res) {
        _this8.templatesListGroup = [];
        for (var i = 0; i < res.data.length; i++) {
          _this8.templatesListGroup.push({
            id: res.data[i].id,
            select: false,
            typeName: res.data[i].typeName,
            typeDesc: res.data[i].typeDesc
          });
        }
        if (_this8.templatesListGroup.length > 0) {
          if (_this8.templatesListGroup.length === 1) {
            // 默认选中第一条
            _this8.tempTemplate = _this8.templatesListGroup[0].id;
            onJobAgain({
              empId: _this8.currentItem.id,
              flowId: _this8.tempTemplate
            }).then(function (res) {
              _this8.getEmpInfos();
              if (_this8.tempTemplate) {
                _this8.isApproval = true;
              } else {
                // 添加成功
                _this8.isApproval = false;
              }
              _this8.isCloseMethod2 = true;
            });
          } else {
            for (var k = 0; k < _this8.templatesListGroup.length; k++) {
              if (k === 0) {
                _this8.templatesListGroup[k].select = true;
              } else {
                _this8.templatesListGroup[k].select = false;
              }
            }

            // 显示第一条
            _this8.tempTemplate = _this8.templatesListGroup[0].id;
            _this8.selectModel = true;
          }
        } else {
          _this8.tempTemplate = undefined;
          onJobAgain({
            empId: _this8.currentItem.id
          }).then(function (res) {
            _this8.getEmpInfos();
            if (_this8.tempTemplate) {
              _this8.isApproval = true;
            } else {
              // 添加成功
              _this8.isApproval = false;
            }
            _this8.isCloseMethod2 = true;
          });
        }
      });
    },
    handleEmpyleeSubmit2Pre: function handleEmpyleeSubmit2Pre(e) {
      var _this9 = this;
      if (this.drawerTitle === "确认到岗") {
        var values = this.saveValueFrom;
        values.companyId = this.companyId;
        values.id = this.joinFormData.id;
        values.approvalStatus = 5;
        values.formalType = values.formalType || 2;
        values.postType = values.postType || 5;
        values.joinDate = values.joinDate || formatDateDefault();
        values.probationDays = values.probationDays || 0;
        if (values.turnStraightDate) {
          if (moment(values.joinDate) > moment(values.turnStraightDate)) {
            values.turnStraightDate = afterTime(values.joinDate, values.probationDays);
          }
        } else {
          values.turnStraightDate = afterTime(values.joinDate, values.probationDays);
        }
        confirmToJoinBatch([values]).then(function (res) {
          _this9.$notification["success"]({
            message: "success",
            description: "提交成功"
          });
          _this9.visibleDrawer = false;
          _this9.visibleEmpyleeDrawer2 = false;
          _this9.visibleConfimSubmit = true;
          _this9.getTabNumber();
        });
      } else {
        // 添加到入职
        // 根据公司查询模板
        getModelList_isUse(this.companyId, "OnJob").then(function (res) {
          _this9.templatesListGroup = [];
          for (var i = 0; i < res.data.length; i++) {
            _this9.templatesListGroup.push({
              id: res.data[i].id,
              select: false,
              typeName: res.data[i].typeName,
              typeDesc: res.data[i].typeDesc
            });
          }
          if (_this9.templatesListGroup.length > 0) {
            if (_this9.templatesListGroup.length === 1) {
              // 默认选中第一条
              _this9.tempTemplate = _this9.templatesListGroup[0].id;
              _this9.handleEmpyleeSubmit2(e);
            } else {
              for (var m = 0; m < _this9.templatesListGroup.length; m++) {
                if (m === 0) {
                  _this9.templatesListGroup[m].select = true;
                } else {
                  _this9.templatesListGroup[m].select = false;
                }
              }
              _this9.currentItem = undefined;
              // 显示第一条
              _this9.tempTemplate = _this9.templatesListGroup[0].id;
              _this9.selectModel = true;
            }
          } else {
            _this9.tempTemplate = undefined;
            _this9.handleEmpyleeSubmit2(e);
          }
        });
      }
    },
    getShiyongqi: function getShiyongqi() {
      if (this.empyleeForm) {
        return this.empyleeForm.getFieldValue("formalType") === 1; // 试用期
      } else {
        return false;
      }
    },
    handleEmpyleeSubmit2: function handleEmpyleeSubmit2(e) {
      var _this10 = this;
      var values = this.saveValueFrom;
      values.companyId = this.companyId;
      values.employType = 1;
      values.status = 1; // 待入职
      if (this.tempTemplate) {
        values.flowId = this.tempTemplate;
      } else {
        values.flowId = "";
      }
      values.joinCityCode = this.joinCityCode;
      values.joinCityName = this.joinCityName;
      values.formalType = values.formalType || 2;
      values.postType = values.postType || 5;
      values.joinDate = values.joinDate || formatDateDefault();
      values.preJoinDate = values.joinDate;
      values.probationDays = values.probationDays || 0;
      values.turnStraightDate = values.turnStraightDate || afterTime(values.joinDate, values.probationDays);
      saveEmpInfoAndRule(values).then(function (res) {
        _this10.getEmpInfos();
        // this.empyleeForm.resetFields();
        _this10.onCloseDrawer2("visibleEmpyleeDrawer2");
        if (_this10.tempTemplate) {
          _this10.isApproval = true;
        } else {
          // 添加成功
          _this10.isApproval = false;
        }
        _this10.isCloseMethod2 = true;
        _this10.getTabNumber();
      });
    },
    addMember: function addMember() {
      this.proRegularDateShow = false;
      this.testDate = false;
      this.empyleeForm.resetFields();
      this.joinFormData = {};
      this.visibleEmpyleeDrawer2 = true;
      this.drawerTitle = "添加待入职";
    },
    // 按人员状态检索
    handleProgName2: function handleProgName2(val) {
      delete this.searchForm.status;
      this.searchForm.approvalAndStatus = val;
      this.getEmpInfos(1);
    },
    // 分页查询
    handlePageChange: function handlePageChange(pagination, filters, sorter) {
      this.pagination2.current = pagination.current;
    },
    // 根据试用期计算预计转正日期
    handleProba: function handleProba(value) {
      var _this11 = this;
      var probationDay = this.empyleeForm.getFieldValue("probationDays");
      var newTime = afterTime(this.empyleeForm.getFieldValue("joinDate"), value);
      setTimeout(function () {
        _this11.empyleeForm.setFieldsValue({
          turnStraightDate: _this11.moment(newTime, "YYYY-MM-DD")
        });
      }, 300);
    },
    disabledDate2: function disabledDate2(current) {
      return current && current < moment().startOf("month"); // 月份第一天
    },
    // 离职日期限制
    disabledDate3: function disabledDate3(current) {
      return current && current < moment().startOf("month"); // 月份第一天
    },
    // 选择参保年份和月份
    onLeaveChange: function onLeaveChange(date, dateString) {
      if (this.isBatchLeaveConfirm) {
        this.batchPreQuitDate = dateString;
      } else {
        this.leaveEmy.preQuitDate = date.valueOf();
      }
      if (this.hasShebao) {
        this.shebaoDate = dateString;
      }
      if (this.hasGjj) {
        this.gongjijinDate = dateString;
      }
      if (this.hasYibao) {
        this.yibaoDate = dateString;
      }
    },
    // 选择参保年份和月份
    onYearMonthChange_SB: function onYearMonthChange_SB(date, dateString) {
      this.shebaoDate = dateString;
    },
    onYearMonthChange_GJJ: function onYearMonthChange_GJJ(date, dateString) {
      this.gongjijinDate = dateString;
    },
    onYearMonthChange_YB: function onYearMonthChange_YB(date, dateString) {
      this.yibaoDate = dateString;
    },
    handleCancel: function handleCancel() {
      this.isCloseMethod = false;
      this.isCloseMethod2 = false;
      this.selectModel = false;
    },
    handleImportChange: function handleImportChange() {
      var _this12 = this;
      // this.handleCancel();

      var url = "/hr/api/v1/empChanges/importExcel";
      var tdata = new FormData();
      tdata.append("companyId", this.companyId);
      tdata.append("file", this.socFile);
      this.spinning = true;
      this.leaveList = [];
      this.$request({
        url: url,
        method: "post",
        data: tdata
      }).then(function (res) {
        _this12.spinning = false;
        if (res.data) {
          _this12.$message.success("导入成功");
          if (_this12.piliang_shebaojianyuan) {
            _this12.piliang_shebaojianyuan = false;
            _this12.$message.success("减员计划添加成功，请在系统内进行减员确认");
          }
          _this12.handleCancel();
          _this12.leaveList = res.data;
          if (_this12.leaveList.length !== 0) {
            _this12.$message.success("选择签署离职合同");
            _this12.$router.push({
              path: "/contract/type",
              query: {
                leaveList: _this12.leaveList,
                fromLeave: true
              }
            });
          } else {
            _this12.getTabNumber();
          }
        }
      }).catch(function (err) {
        _this12.spinning = false;
        console.log("debug log --> ", err);
      });
    },
    onQuerenlizhi: function onQuerenlizhi(empList) {
      this.isBatchLeaveConfirm = true;
      this.confirmLeaveBatch(empList);
    },
    pilianglizhiModalOk: function pilianglizhiModalOk() {
      this.pilianglizhiResultModal = false;
      this.saveLeaveOk();
    },
    finishEdit: function finishEdit() {
      var _this13 = this;
      if (this.isBatchLeaveConfirm) {
        if (!this.batchPreQuitDate) {
          this.$message.success("请选择离职日期");
          return;
        }
      } else {
        if (this.leaveEmy.preQuitDate === undefined || this.leaveEmy.preQuitDate === undefined) {
          this.$message.success("请选择离职日期");
          return;
        }
      }
      var data = {
        companyId: this.companyId,
        confirmLeaveOfficeDate: this.isBatchLeaveConfirm ? this.batchPreQuitDate : this.leaveEmy.preQuitDate,
        whetherLaunchedContract: this.isHeTong,
        whetherSocDecrease: this.isShebaoJianYuan,
        whetherMeDecrease: this.isYibaoJianYuan,
        whetherPfDecrease: this.isGjjJianYuan,
        whetherDecrease: false,
        serviceCost: this.serviceCost
      };
      if (this.isShebaoJianYuan) {
        var s = this.shebaoDate.split("-");
        data.stopSocYear = s[0];
        data.stopSocMonth = s[1];
        data.whetherDecrease = true;
      }
      if (this.isYibaoJianYuan) {
        var _s = this.yibaoDate.split("-");
        data.stopMeYear = _s[0];
        data.stopMeMonth = _s[1];
        data.whetherDecrease = true;
      }
      if (this.isGjjJianYuan) {
        var _s2 = this.gongjijinDate.split("-");
        data.sealedDateYear = _s2[0];
        data.sealedDateMonth = _s2[1];
        data.whetherDecrease = true;
      }
      if (this.isBatchLeaveConfirm) {
        leaveBatchReq(_objectSpread(_objectSpread({}, data), {}, {
          whetherLaunchedContract: false,
          empIds: this.leaveEmyList.map(function (z) {
            return z.id;
          })
        })).then(function (res) {
          _this13.isCloseMethod = false;
          _this13.isBatchLeaveConfirm = false;
          _this13.pilianglizhiResultModal = true;
          _this13.pilianglizhiResults = res.data;
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        confimLeave(this.leaveEmy.id, data).then(function (res) {
          _this13.isCloseMethod = false;
          _this13.leaveList = res.data;
          if (_this13.isHeTong) {
            _this13.$message.success("选择签署离职合同");
            _this13.$router.push({
              path: "/contract/type",
              query: {
                leaveList: _this13.leaveList,
                fromLeave: true
              }
            });
          } else {
            _this13.$message.success("离职成功");
            if (_this13.isShebaoJianYuan || _this13.isGjjJianYuan || _this13.isYibaoJianYuan) {
              _this13.$message.success("减员计划添加成功，请在系统内进行减员确认");
            }
            _this13.tapChange(_this13.selectId);
            _this13.getTabNumber();
          }
        });
      }
    },
    // 是否发起离职合同
    onHtChange: function onHtChange(checked) {
      this.isHeTong = checked;
    },
    // 是否进行社保减员
    onJYChange: function onJYChange(checked) {
      this.isShebaoJianYuan = checked;
    },
    onJY2Change: function onJY2Change(checked) {
      this.isGjjJianYuan = checked;
    },
    onJyChange2: function onJyChange2(checked) {
      this.isYibaoJianYuan = checked;
    },
    handleChangeStaff: function handleChangeStaff(e) {
      switch (e.key) {
        case "gmtModified":
          // 已离职记录
          this.goto("leaveTag");
          break;
        case "gmtCreate":
          // 导入Excel办理
          this.renliStatus = 0; // 0拖拽，1成功，2失败
          this.spinning = false;
          this.socFile = undefined;
          this.$router.push({
            path: "management/import"
          });
          break;
        case "namePinyin":
          // 发起离职合同
          this.$router.push({
            path: "/contract/type",
            fromLeave: true
          });
          break;
      }
    },
    beforeUpload1: function beforeUpload1(_ref) {
      var file = _ref.file;
      this.socFile = file;
    },
    handleChange: function handleChange(info) {
      if (info.file.status === "done") {
        this.$message.success("".concat(info.file.name, " file uploaded successfully"));
      } else if (info.file.status === "error") {
        this.$message.error("".concat(info.file.name, " file upload failed."));
      }
    },
    disabledDate: function disabledDate(current) {
      return current < this.moment(this.regularFormData.joinDate);
    },
    handleEmpInfoTableChange: function handleEmpInfoTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.EmpListByDep();
    },
    cancelPositionList: function cancelPositionList() {
      this.positonTreeShow = false;
    },
    getPositionList: function getPositionList(e) {
      this.positonTreeShow = false;
      this.checkedPostionListGroup = e;
      var arr = [];
      e.forEach(function (v) {
        arr.push(v.name);
      });
      this.entryForm.setFieldsValue({
        positionIdList: arr
      });
    },
    search: function search() {
      this.$set(this.managementList.pagination, "current", 1);
      // 预计入职开始时间
      if (!this.searchForm.preJoinDateMoment || this.searchForm.preJoinDateMoment.length === 0) {
        this.searchForm.preJoinDateStart = undefined;
        this.searchForm.preJoinDateEnd = undefined;
      } else {
        this.searchForm.preJoinDateStart = this.moment(this.searchForm.preJoinDateMoment[0]).format("YYYY-MM-DD");
        this.searchForm.preJoinDateEnd = this.moment(this.searchForm.preJoinDateMoment[1]).format("YYYY-MM-DD");
      }
      // 入职开始时间
      if (!this.searchForm.joinDateMoment || this.searchForm.joinDateMoment.length === 0) {
        this.searchForm.joinDateStart = undefined;
        this.searchForm.joinDateEnd = undefined;
      } else {
        this.searchForm.joinDateStart = this.moment(this.searchForm.joinDateMoment[0]).format("YYYY-MM-DD");
        this.searchForm.joinDateEnd = this.moment(this.searchForm.joinDateMoment[1]).format("YYYY-MM-DD");
      }
      // 预计转正时间
      if (!this.searchForm.turnStraightDateMoment || this.searchForm.turnStraightDateMoment.length === 0) {
        this.searchForm.turnStraightDateStart = undefined;
        this.searchForm.turnStraightDateEnd = undefined;
      } else {
        this.searchForm.turnStraightDateStart = this.moment(this.searchForm.turnStraightDateMoment[0]).format("YYYY-MM-DD");
        this.searchForm.turnStraightDateEnd = this.moment(this.searchForm.turnStraightDateMoment[1]).format("YYYY-MM-DD");
      }
      // 预计离职时间
      if (!this.searchForm.leaveDateMoment || this.searchForm.leaveDateMoment.length === 0) {
        this.searchForm.leaveDateStart = undefined;
        this.searchForm.leaveDateEnd = undefined;
      } else {
        this.searchForm.leaveDateStart = this.moment(this.searchForm.leaveDateMoment[0]).format("YYYY-MM-DD");
        this.searchForm.leaveDateEnd = this.moment(this.searchForm.leaveDateMoment[1]).format("YYYY-MM-DD");
      }
      //  调岗开始时间 结束时间
      if (!this.searchForm.transferDateMoment || this.searchForm.transferDateMoment.length === 0) {
        this.searchForm.transferDateStart = undefined;
        this.searchForm.transferDateEnd = undefined;
      } else {
        this.searchForm.transferDateStart = this.moment(this.searchForm.transferDateMoment[0]).format("YYYY-MM-DD");
        this.searchForm.transferDateEnd = this.moment(this.searchForm.transferDateMoment[1]).format("YYYY-MM-DD");
      }
      this.getEmpInfos(1);
    },
    getTabNumber: function getTabNumber() {
      var _this14 = this;
      var data = this.tabList.filter(function (item) {
        return item.id === _this14.selectId;
      });
      this.managementList.title = data[0].tableColumn.filter(function (i) {
        return i.align = "left";
      });
      getEmpInfoCount(this.$store.state.hr.companyId).then(function (res) {
        var data = res.data;
        _this14.tabList.forEach(function (item, index) {
          _this14.$set(_this14.tabList, index, _objectSpread(_objectSpread({}, _this14.tabList[index]), {}, {
            count: data[_this14.tabList[index].name]
          }));
        });
      });
    },
    getEmpInfos: function getEmpInfos(resetPageNo) {
      var _this15 = this;
      this.managementList.loading = true;
      var pagination = this.managementList.pagination;
      var pageNo = resetPageNo || pagination.current;
      var pageSize = pagination.pageSize === 0 ? 10 : pagination.pageSize;
      var target = this.tabList.filter(function (item) {
        return item.id === _this15.selectId;
      });
      var data = _objectSpread(_objectSpread({
        pageNo: pageNo === 0 ? 1 : pageNo,
        pageSize: pageSize,
        status: target[0]["status"],
        companyId: this.companyId
      }, this.searchForm), {}, {
        joinFormStatus: this.searchForm.joinFormStatus === "入职登记表是否已提交" ? undefined : this.searchForm.joinFormStatus
      });
      var func;
      switch (this.selectId) {
        case "1":
          // 入职
          func = selectEmpInfoPendingRelation(data);
          this.lzbm = undefined;
          this.zzbm = undefined;
          // 缓存请求参数
          data.selectId = this.selectId;
          this.$setCondition("ygyd_condition", JSON.stringify(data));
          break;
        case "2":
          // 转正
          func = selectEmpInfoToFormalRelation(data);
          this.lzbm = undefined;
          // 缓存请求参数
          data.selectId = this.selectId;
          this.$setCondition("ygyd_condition", JSON.stringify(data));
          break;
        case "3":
          // 在职
          func = selectEmpInfoOnJobRelation(data);
          this.lzbm = undefined;
          this.zzbm = undefined;
          // 缓存请求参数
          data.selectId = this.selectId;
          this.$setCondition("ygyd_condition", JSON.stringify(data));
          break;
        case "4":
          // 离职
          delete data.status;
          func = pageWaitLeave(_objectSpread(_objectSpread({}, data), {}, {
            status: 3
          }));
          this.zzbm = undefined;
          // 缓存请求参数
          data.selectId = this.selectId;
          this.$setCondition("ygyd_condition", JSON.stringify(_objectSpread(_objectSpread({}, data), {}, {
            status: 3
          })));
          break;
        case "5":
          // 调岗
          delete data.status;
          func = selectEmpInfoTransferRelation(data);
          this.lzbm = undefined;
          this.zzbm = undefined;
          // 缓存请求参数
          data.selectId = this.selectId;
          this.$setCondition("ygyd_condition", JSON.stringify(data));
          break;
      }
      func.then(function (res) {
        _this15.selectedRowKeys = [];
        _this15.managementList.data = res.data && res.data.entities;
        if (res.data && res.data.entities) {
          for (var i = 0; i < res.data.entities.length; i++) {
            if (res.data.entities[i].empChangesListVO) {
              _this15.managementList.data[i].quitRemarks = res.data.entities[i].empChangesListVO.quitRemarks;
              _this15.managementList.data[i].quitReason = res.data.entities[i].empChangesListVO.quitReason;
              _this15.managementList.data[i].preQuitDate = res.data.entities[i].empChangesListVO.preQuitDate; // 离职时间
              _this15.managementList.data[i].prePositionName = res.data.entities[i].empChangesListVO.prePositionName; // 原岗位
              _this15.managementList.data[i].currentPositionName = res.data.entities[i].empChangesListVO.currentPositionName; // 变更岗位
              _this15.managementList.data[i].preDepName = res.data.entities[i].empChangesListVO.preDepName; // 原部门
              _this15.managementList.data[i].currentDepName = res.data.entities[i].empChangesListVO.currentDepName; // 变更部门
              _this15.managementList.data[i].adjustmentTime = res.data.entities[i].empChangesListVO.adjustmentDate; // 调岗时间
              _this15.managementList.data[i].adjustmentReason = res.data.entities[i].empChangesListVO.adjustmentReason; // 调岗原因
            }
          }

          _this15.managementList.pagination.total = res.data.entityCount; // 共多少条
          _this15.managementList.pagination.pageSize = res.data.pageSize === 0 ? 10 : res.data.pageSize; // 每页多少条
          _this15.managementList.pagination.current = res.data.pageNo; // 当前第几页
        } else {
          _this15.managementList.pagination.total = 0; // 共多少条
          _this15.managementList.pagination.pageSize = 10; // 每页多少条
          _this15.managementList.pagination.current = 1; // 当前第几页
        }
      }).finally(function () {
        _this15.managementList.loading = false;
      });
    },
    getEmployeeStatus: function getEmployeeStatus(status, record, selectId) {
      var approvalStatus = record.approvalStatus;
      var text = "";
      if (selectId === "0") {
        if (status === 1) {
          return "待入职";
        } else if (status === 2) {
          return "入职";
        } else if (status === 3) {
          return "待离职";
        } else if (status === 4) {
          return "离职";
        }
      } else if (selectId === "1") {
        switch (record.approvalAndStatus) {
          case 1:
            return "待入职";
          case 2:
            return "审批中";
          case 3:
            return "审批驳回";
          case 4:
            return "未到岗";
        }
      } else if (selectId === "2") {
        // 转正管理
        text = "待转正";
      } else if (selectId === "3") {
        // 在职管理
        text = "在职";
      } else if (selectId === "4") {
        // 离职管理
        text = "待离职";
        if (status === 4) {
          text = "离职";
        }
      } else if (selectId === "5") {
        // 调岗管理
        text = "待调岗";
      }
      if (approvalStatus === 5) {
        return text;
      } else if (approvalStatus === 1) {
        return "待审批";
      } else if (approvalStatus === 2) {
        return "审批中";
      } else if (approvalStatus === 3) {
        return "审批未通过";
      } else if (approvalStatus === 4) {
        return "审批通过";
      }
    },
    getColor: function getColor(status, record, selectId) {
      var approvalStatus = record.approvalStatus;
      var text = "";
      if (selectId === "0") {
        if (status === 1) {
          return "#4686FF";
        } else if (status === 2) {
          return "#32CCAA";
        } else if (status === 3) {
          return "#F7B500";
        } else if (status === 4) {
          return "#A6A8B4";
        }
      } else if (selectId === "1") {
        // 在职管理
        switch (record.approvalAndStatus) {
          case 1:
            return "#4686FF";
          case 2:
            return "#6B6BF2";
          case 3:
            return "#FF7085";
          case 4:
            return "#A6A8B4";
        }
      } else if (selectId === "2") {
        // 转正管理
        text = "#4686FF";
      } else if (selectId === "3") {
        // 在职管理
        text = "#32CCAA";
      } else if (selectId === "4") {
        // 离职管理
        text = "#4686FF";
        if (status === 4) {
          text = "#A6A8B4";
        }
      } else if (selectId === "5") {
        // 调岗管理
        text = "#4686FF";
      }
      if (approvalStatus === 5) {
        return text;
      } else if (approvalStatus === 1) {
        return "#4686FF";
      } else if (approvalStatus === 2) {
        return "#4686FF";
      } else if (approvalStatus === 3) {
        return "#FF7085";
      } else if (approvalStatus === 4) {
        return "#32CCAA";
      }
    },
    BatchGetFun: function BatchGetFun() {
      var _this16 = this;
      this.$confirm({
        title: "是否进行批量到岗操作?",
        okType: "warning",
        onOk: function onOk() {
          _this16.drawerTitle = "确认到岗";
          _this16.getBatchGetFunOpen();
        }
      });
    },
    getBatchGetFunOpen: function getBatchGetFunOpen() {
      this.formJoinDate.resetFields();
      this.visibleJoinDate = true;
      this.isBatchJoin = true;
      this.confirmedPostArr = {
        arr: this.batchArrData
      };
    },
    getBatchGetFun: function getBatchGetFun() {
      var _this17 = this;
      var batchArr = [];
      for (var i = 0; i < this.batchArrData.length; i++) {
        batchArr.push({
          id: this.batchArrData[i].id,
          name: this.batchArrData[i].name,
          phone: this.batchArrData[i].phone,
          depIdList: this.batchArrData[i].depName,
          positionIdList: this.batchArrData[i].positionName,
          postType: this.batchArrData[i].postType === 0 ? "" : this.batchArrData[i].postType,
          joinDate: this.getMoment(this.batchArrData[i].joinDate),
          companyId: this.companyId,
          formalType: this.batchArrData[i].formalType,
          preJoinDate: this.batchArrData[i].preJoinDate,
          turnStraightDate: this.batchArrData[i].turnStraightDate,
          approvalStatus: 5
        });
      }
      for (i = 0; i < batchArr.length; i++) {
        if (batchArr[i].postType === 2 || batchArr[i].postType === 3) {
          // 外包或者社保代缴
          batchArr[i].formalType = 2; // 正式
          batchArr[i].preJoinDate = batchArr[i].joinDate;
          batchArr[i].turnStraightDate = batchArr[i].joinDate;
        }
      }
      confirmToJoinBatch(batchArr).then(function (res) {
        _this17.selectedRowKeys = [];
        _this17.$notification["success"]({
          message: "success",
          description: "提交成功"
        });
        // that.reload();
        _this17.visibleDrawer = false;
        _this17.visibleConfimSubmit = true;
        _this17.getTabNumber();
      }).catch(function (err) {
        console.log(err.toString());
      });
    },
    handleOkJoinDate: function handleOkJoinDate(e) {
      delete e.name;
      delete e.arr;
      delete e.addEmpFieldList;
      this.batchArrData = this.batchArrData.map(function (z) {
        for (var i in e) {
          z[i] = e[i];
        }
        return z;
      });
      this.batchArrData = this.batchArrData.map(function (z) {
        if (z.turnStraightDate < moment(z.joinDate).valueOf()) {
          z.turnStraightDate = moment(z.joinDate).valueOf();
        }
        return z;
      });
      this.visibleJoinDate = false;
      this.isBatchJoin = false;
      this.getBatchGetFun();
    },
    openEmployeesMove: function openEmployeesMove(e) {
      var _this18 = this;
      // 打开高级筛选
      this.$nextTick(function () {
        _this18.$refs.employeesMove.showModal(e);
      });
    },
    getNextStep: function getNextStep(data, state) {
      this.batchArrData = data;
      if (state === "批量未到岗") {
        this.BatchNoGetFun();
      } else if (state === "批量到岗") {
        this.BatchGetFun();
      } else if (state === "批量离职") {
        this.bulkDeparturesOk(data);
      } else if (state === "批量转正") {
        this.BatchPositive();
      } else if (state === "批量调岗") {
        this.merberBatchDG();
      } else if (state === "批量撤销离职") {
        this.BatchWithdrawal();
      } else if (state === "批量确认调岗") {
        this.BatchConfirmTransfer();
      } else if (state === "批量撤销调岗") {
        this.BatchCancellationTransfer();
      }
    },
    getbatchTransferRegular: function getbatchTransferRegular(values) {
      var _this19 = this;
      if (moment().isBefore(values.regularDate)) {
        // console.log("未到期");
        this.iszheng = false;
      } else {
        // console.log("已到期");
        this.iszheng = true;
      }
      var data = {
        regularDate: moment(values.regularDate).format("YYYY-MM-DD"),
        companyId: this.companyId,
        empIdS: this.batchArrData.map(function (z) {
          return z.id;
        })
      };
      transferRegular(data).then(function (res) {
        _this19.$notification["success"]({
          message: "success",
          description: res.data
        });
        _this19.formPositive.resetFields();
        _this19.visiblePositive = false;
        _this19.visibleSubmit = true;
        _this19.titleSubmit = "办理转正";
        _this19.oktext = "确定";
      });
    },
    handleOkPositive: function handleOkPositive() {
      var _this20 = this;
      this.formPositive.validateFields(function (err, values) {
        if (!err) {
          var len = _this20.FlowsData && _this20.FlowsData.length;
          values.companyId = _this20.companyId;
          values.empIds = [_this20.regularFormData.id];
          _this20.flag = 2;

          // 没有审批 穿空 1个传当前值 多个选择
          if (len === 1) {
            values.approFlowId = _this20.FlowsData[0].id;
            _this20.getbatchTransferRegular(values);
          } else if (len > 1) {
            _this20.approvalModal = true;
            _this20.selectValue = _this20.FlowsData[0].id;
            _this20.redisValues = values;
          } else {
            values.approFlowId = "";
            _this20.getbatchTransferRegular(values);
          }
        }
      });
    },
    handleCancelPositive: function handleCancelPositive() {
      this.visiblePositive = false;
    },
    disabledDateRegularDate: function disabledDateRegularDate(current) {
      return current && current < this.disableJoinDate;
    },
    BatchPositive: function BatchPositive() {
      this.visiblePositive = true;
      var arr = this.batchArrData;
      this.disableJoinDate = this.getDisableDateFun(arr);
    },
    getDisableDateFun: function getDisableDateFun(arr) {
      var data = arr[0].joinDate;
      for (var i = 0; i < arr.length - 1; i++) {
        data = data < arr[i + 1].joinDate ? arr[i + 1].joinDate : data;
      }
      return moment(data);
    },
    BatchWithdrawal: function BatchWithdrawal() {
      var _this21 = this;
      this.$confirm({
        title: "是否进行批量撤销离职?",
        okType: "warning",
        onOk: function onOk() {
          // 批量未到岗
          var batchArr = [];
          for (var i = 0; i < _this21.batchArrData.length; i++) {
            batchArr.push(_this21.batchArrData[i].id);
          }
          revokeLeaveApi(batchArr).then(function (res) {
            _this21.$notification["success"]({
              message: "success",
              description: "提交成功"
            });
            // that.reload();
            _this21.visibleDrawer = false;
            // this.visibleConfimSubmit = true;
            _this21.getTabNumber();
            _this21.tapChange(_this21.selectId);
          });
        }
      });
    },
    BatchConfirmTransfer: function BatchConfirmTransfer() {
      var _this22 = this;
      this.$confirm({
        title: "是否进行批量确认调岗?",
        okType: "warning",
        onOk: function onOk() {
          // 批量未到岗
          var batchArr = _this22.batchArrData.map(function (z) {
            return z.id;
          });
          batchConfirmTransferPosition(batchArr).then(function (res) {
            _this22.$notification["success"]({
              message: "success",
              description: "提交成功"
            });
            // that.reload();
            _this22.visibleDrawer = false;
            _this22.visibleConfimSubmit = true;
            _this22.getTabNumber();
          });
        }
      });
    },
    BatchCancellationTransfer: function BatchCancellationTransfer() {
      var _this23 = this;
      this.$confirm({
        title: "是否进行批量撤销调岗?",
        okType: "warning",
        onOk: function onOk() {
          // 批量未到岗
          var batchArr = _this23.batchArrData.map(function (z) {
            return z.id;
          });
          batchRevokeTransferPositionByEmpId(batchArr).then(function (res) {
            _this23.$notification["success"]({
              message: "success",
              description: "提交成功"
            });
            // that.reload();
            _this23.visibleDrawer = false;
            _this23.visibleConfimSubmit = true;
            _this23.getTabNumber();
          });
        }
      });
    },
    BatchNoGetFun: function BatchNoGetFun() {
      var _this24 = this;
      this.$confirm({
        title: "是否进行批量未到岗操作?",
        okType: "warning",
        onOk: function onOk() {
          _this24.drawerTitle = "确认未到岗";
          // 批量未到岗
          var batchArr = [];
          for (var i = 0; i < _this24.batchArrData.length; i++) {
            if (_this24.getEmployeeStatus(_this24.batchArrData[i].status, _this24.batchArrData[i], "1") !== "待入职") {
              _this24.$notification["info"]({
                message: "提醒",
                description: "请选择未到岗员工"
              });
              return;
            }
            batchArr.push(_this24.batchArrData[i].id);
          }
          confirmToNoJoinBatch(batchArr).then(function (res) {
            _this24.$notification["success"]({
              message: "success",
              description: "提交成功"
            });
            _this24.getTabNumber();
            _this24.tapChange(_this24.selectId);
          });
        }
      });
    },
    onselectValueChange: function onselectValueChange(e) {
      this.selectValue = e.target.value;
    },
    handleApprovalSubmit: function handleApprovalSubmit() {
      var redisValues = this.redisValues;
      redisValues.approFlowId = this.selectValue;
      if (this.flag === 1) {
        this.funcSaveEmpInfoPre(redisValues);
      } else if (this.flag === 2) {
        this.funcTransferRegular(redisValues);
      } else if (this.flag === 3) {
        this.funcLeavePosition(redisValues);
      } else if (this.flag === 4) {
        this.funcTransferJob(redisValues);
      }
    },
    // 获取某公司离职可用的的所有审批流程
    leavingFlowsFunc: function leavingFlowsFunc() {
      var _this25 = this;
      leavingFlows(this.$store.state.hr.companyId).then(function (res) {
        _this25.FlowsData = res.data;
        _this25.leaveForm.validateFields(function (err, values) {
          if (!err) {
            var len = _this25.FlowsData && _this25.FlowsData.length;
            values.companyId = _this25.companyId;
            values.empIds = _this25.openBulkDepartures ? _this25.selectedRowKeysModelIds : _this25.selectedRowKeys;
            values.quitReason = values.quitReason.join(",");
            _this25.flag = 3;
            // 没有审批 穿空 1个传当前值 多个选择
            if (len === 1) {
              values.approFlowId = _this25.FlowsData[0].id;
              _this25.funcLeavePosition(values);
            } else if (len > 1) {
              _this25.approvalModal = true;
              _this25.selectValue = _this25.FlowsData[0].id;
              _this25.redisValues = values;
            } else {
              values.approFlowId = "";
              _this25.funcLeavePosition(values);
            }
          }
        });
      });
    },
    // 获取某公司调岗可用的的所有审批流程
    transferDutyFlowsFunc: function transferDutyFlowsFunc() {
      var _this26 = this;
      transferDutyFlows(this.$store.state.hr.companyId).then(function (res) {
        _this26.FlowsData = res.data;
      });
    },
    // 获取某公司转正可用的的所有审批流程
    turnStraightFlowsFunc: function turnStraightFlowsFunc() {
      var _this27 = this;
      turnStraightFlows(this.$store.state.hr.companyId).then(function (res) {
        _this27.FlowsData = res.data;
      });
    },
    // 获取某公司入职可用的的所有审批流程
    onJobFlowsFunc: function onJobFlowsFunc() {
      var _this28 = this;
      onJobFlows(this.$store.state.hr.companyId).then(function (res) {
        _this28.FlowsData = res.data;
      });
    },
    revokeLeave: function revokeLeave(e) {
      var _this29 = this;
      revokeLeaveApi([e.id]).then(function (res) {
        _this29.$notification["success"]({
          message: "success",
          description: "操作成功"
        });
        // this.reload()
        _this29.tapChange(_this29.selectId);
        _this29.getTabNumber();
      });
    },
    confimTransferPosition: function confimTransferPosition(e) {
      var _this30 = this;
      debugger;
      confimTransferPositionApi(e.id).then(function (res) {
        _this30.$notification["success"]({
          message: "success",
          description: "操作成功"
        });
        _this30.tapChange(_this30.selectId);
        _this30.getTabNumber();
      });
    },
    revokeTransferPosition: function revokeTransferPosition(e) {
      var _this31 = this;
      revokeTransferPositionApi([e.id]).then(function (res) {
        _this31.$notification["success"]({
          message: "success",
          description: "操作成功"
        });
        _this31.tapChange(_this31.selectId);
        _this31.getTabNumber();
      });
    },
    // 确定离职
    confirmLeaveBatch: function confirmLeaveBatch(lst) {
      this.serviceCost = undefined;
      this.isCloseMethod = true;
      this.leaveEmyList = lst;
      this.hasShebao = true;
      this.hasYibao = true;
      this.hasGjj = true;
      this.isShebaoJianYuan = false;
      this.isYibaoJianYuan = false;
      this.isGjjJianYuan = false;
      this.disableJoinDate = this.getDisableDateFun(lst);
    },
    // 确定离职
    confirmLeave: function confirmLeave(e) {
      var _this32 = this;
      this.isBatchLeaveConfirm = false;
      this.serviceCost = undefined;
      this.isCloseMethod = true;
      this.leaveEmy = e;
      this.hasShebao = false;
      this.hasYibao = false;
      this.hasGjj = false;
      this.isShebaoJianYuan = false;
      this.isYibaoJianYuan = false;
      this.isGjjJianYuan = false;
      this.disableJoinDate = moment(e.joinDate);
      findEmpHaveSocInfo(e.id).then(function (res) {
        if (res.data.haveSoc) {
          _this32.hasShebao = true;
        }
        if (res.data.havePf) {
          _this32.hasGjj = true;
        }
        if (res.data.haveMe) {
          _this32.hasYibao = true;
        }
      });
    },
    openUser: function openUser(e) {
      var _this33 = this;
      empRegisterAccount(e).then(function (res) {
        _this33.$notification["success"]({
          message: "success",
          description: "开通成功"
        });
        _this33.tapChange(_this33.selectId);
        _this33.getTabNumber();
      });
    },
    getMoment: function getMoment(date) {
      return date ? this.moment(date) : null;
    },
    // button group start
    toPage: function toPage(link) {
      this.$router.push({
        path: link
      });
    },
    goto: function goto(tag) {
      var _this34 = this;
      this.nameDisabled = false;
      if (tag === "moveTag") {
        this.$router.push({
          path: "/staff/moveTag",
          query: {
            selectId: this.selectId,
            moveTag: this.moveTag
          }
        });
        return;
      } else if (tag === "leaveTag") {
        this.$router.push({
          path: "/staff/LeaveList",
          query: {
            selectId: this.selectId,
            moveTag: this.moveTag
          }
        });
        return;
      }
      this[tag] = true;
      this.$nextTick(function () {
        _this34.moveForm.resetFields();
      });
    },
    hideList: function hideList(tag) {
      this[tag] = false;
    },
    // button group end
    // 办理入职 start
    onJoinEmp: function onJoinEmp() {
      this.tapChange(this.selectId);
      this.getTabNumber();
      if (this.oktext === "确定") {
        this.visibleSubmit = false;
        this.approvalModal = false;
        // this.reload();

        return;
      }
      this.visibleSubmit = false;
      this.approvalModal = false;
      this.entryForm.resetFields();
      this.visibleDrawer = true;
      // 获取审批流程
      this.onJobFlowsFunc();
    },
    merberLeave: function merberLeave() {
      var _this35 = this;
      this.visibleLeaveDrawer = true;
      this.nameDisable = false;
      this.$nextTick(function () {
        _this35.leaveForm.resetFields();
      });
    },
    bulkDeparturesOk: function bulkDeparturesOk(arr) {
      var _this36 = this;
      this.openBulkDepartures = true;
      this.disableJoinDate = this.getDisableDateFun(arr);
      this.selectedRowKeysModelIds = arr.map(function (z) {
        return z.id;
      });
      var tmep_name = arr.map(function (z) {
        return z.name;
      });
      this.nameDisable = true;
      this.visibleLeaveDrawer = true;
      this.$nextTick(function () {
        _this36.leaveForm.resetFields();
      });
      setTimeout(function () {
        _this36.leaveForm.setFieldsValue({
          name: tmep_name + ""
        });
      }, 300);
      this.leavingFlowsFunc();
    },
    // 批量离职
    merberBatchLZ: function merberBatchLZ(e) {
      this.$refs.BatchLeaveFilterSelect.showModal(e);
    },
    makeLeave: function makeLeave(e) {
      var _this37 = this;
      this.disableJoinDate = moment(e.joinDate);
      this.openBulkDepartures = false;
      this.nameDisable = true;
      this.visibleLeaveDrawer = true;
      this.selectedRowKeys = [e.id];
      this.$nextTick(function () {
        _this37.leaveForm.resetFields();
      });
      setTimeout(function () {
        _this37.leaveForm.setFieldsValue({
          name: e.name
        });
      }, 300);
      this.leavingFlowsFunc();
    },
    onChangeLeaveModal: function onChangeLeaveModal(checkedValues) {
      this.checkedListGroup = checkedValues;
    },
    confirmLeaveModal: function confirmLeaveModal() {
      this.visibleLeaveModal = false;
      this.leaveForm.setFieldsValue({
        quitReason: this.checkedListGroup
      });
    },
    handleCancelLeaveModal: function handleCancelLeaveModal() {
      this.visibleLeaveModal = false;
      // this.flag = false
    },
    LeaveChange: function LeaveChange(tag) {
      this.checkedListGroup = tag;
    },
    showLeaveModal: function showLeaveModal() {
      this.checkedListGroup = [];
      this.visibleLeaveModal = true;
      this.$refs.selectModal.blur();
    },
    // 调岗
    handleMoveSubmit: function handleMoveSubmit(e) {
      var _this38 = this;
      // var that = this;
      e.preventDefault();
      this.moveForm.validateFields(function (err, values) {
        if (!err) {
          var len = _this38.FlowsData && _this38.FlowsData.length;
          values.companyId = _this38.companyId;
          values.empIds = _this38.batchArrData.map(function (z) {
            return z.id;
          });
          _this38.flag = 4;

          // 没有审批 穿空 1个传当前值 多个选择
          if (len === 1) {
            values.approFlowId = _this38.FlowsData[0].id;
            _this38.funcTransferJob(values);
          } else if (len > 1) {
            _this38.approvalModal = true;
            _this38.selectValue = _this38.FlowsData[0].id;
            _this38.redisValues = values;
          } else {
            values.approFlowId = "";
            _this38.funcTransferJob(values);
          }
        }
      });
    },
    funcTransferJob: function funcTransferJob(values) {
      var _this39 = this;
      transferJob(values).then(function (res) {
        _this39.hideList("visibleMoveDrawer");
        _this39.visibleSubmit = true;
        _this39.oktext = "确定";
        _this39.titleSubmit = "办理调岗";
      });
    },
    handleLeaveSubmit: function handleLeaveSubmit(e) {
      this.leavingFlowsFunc();
      e.preventDefault();
    },
    // 下载模板
    download: function download() {
      var token = this.$store.state.auth.token;
      var params = {};
      params = {
        templateName: "batchLeaveOfficeImportTemplate.xlsm"
      };
      axios({
        url: " /hr/api/v1/excel/template/download",
        params: params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "multipart/form-data"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "批量确认离职模板.xlsm");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    funcLeavePosition: function funcLeavePosition(values) {
      var _this40 = this;
      leavePosition(values).then(function (res) {
        _this40.onCloseDrawer("Leave");
        _this40.visibleSubmit = true;
        _this40.oktext = "确定";
        _this40.titleSubmit = "办理离职";
      });
    },
    showMeberDialog: function showMeberDialog(e) {
      e.stopPropagation();
      this.nameOrPhoneOrEmail = "";
      this.selectedTempRowKeys = this.selectedRowKeys;
      // this.selectedRowKeys = this.selectedTempRowKeys;
      //
      this.visibleMerber = true;
      this.adjustButtonDisabled = true;
      this.pagination.current = 1;
      this.$refs.nameModal.blur();
      this.leaveForm.setFieldsValue({
        preQuitDate: null
      });
      this.EmpListByDep();
    },
    selectEmp: function selectEmp() {
      this.pagination.current = 1;
      this.EmpListByDep();
    },
    EmpListByDep: function EmpListByDep() {
      var _this41 = this;
      var data = {
        companyId: this.companyId,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        // parentId: 0,
        nameOrPhoneOrEmail: this.nameOrPhoneOrEmail
      };
      selectEmpInfoListForLeave(data).then(function (res) {
        if (res.data === null) {
          _this41.pagination.total = 0; // 共多少条
          _this41.pagination.current = 1; // 当前第几页
          _this41.empInfoList = [];
        } else {
          _this41.pagination.total = res.data.entityCount; // 共多少条
          _this41.pagination.pageSize = res.data.pageSize === 0 ? 10 : res.data.pageSize; // 每页多少条
          _this41.pagination.current = res.data.pageNo; // 当前第几页
          _this41.empInfoList = res.data.entities;
        }
      });
    },
    adjustEmp: function adjustEmp() {
      var _this42 = this;
      this.selectedRowKeys = this.selectedTempRowKeys;
      var selectObj = this.empInfoList.filter(function (item) {
        return item.id === _this42.selectedRowKeys[0];
      })[0];
      this.disableJoinDate = moment(selectObj.joinDate);
      if (this.selectId === "4") {
        this.leaveForm.setFieldsValue({
          name: selectObj.name
        });
      } else if (this.selectId === "5") {
        this.moveForm.setFieldsValue({
          name: selectObj.name
        });
        this.batchArrData = this.selectedTempRowKeys.map(function (z) {
          return {
            id: z
          };
        });
      }
      this.visibleMerber = false;
    },
    selectionChange: function selectionChange(selection) {
      this.selectedTempRowKeys = [selection.pop()];
      this.adjustButtonDisabled = this.selectedTempRowKeys.length === 0;
    },
    handleSubmit: function handleSubmit(e) {
      var _this43 = this;
      e.preventDefault();
      this.entryForm.validateFields(function (err, values) {
        if (!err) {
          var len = _this43.FlowsData && _this43.FlowsData.length;
          values.companyId = _this43.companyId;
          values.approFlowId = _this43.approFlowId;
          values.preJoinDep = values.depIdList;
          values.preJoinPosition = values.positionIdList;
          _this43.flag = 1;
          // 没有审批 穿空 1个传当前值 多个选择
          if (len === 1) {
            values.approFlowId = _this43.FlowsData[0].id;
            _this43.funcSaveEmpInfoPre(values);
          } else if (len > 1) {
            _this43.approvalModal = true;
            _this43.selectValue = _this43.FlowsData[0].id;
            _this43.redisValues = values;
          } else {
            values.approFlowId = "";
            _this43.funcSaveEmpInfoPre(values);
          }
        }
      });
    },
    funcSaveEmpInfoPre: function funcSaveEmpInfoPre(values) {
      var _this44 = this;
      var that = this;
      saveEmpInfoPre(values).then(function (res) {
        that.onCloseDrawer("");
        that.visibleSubmit = true;
        _this44.oktext = "继续办理";
        that.titleSubmit = "办理入职";
      });
    },
    // 确认未到岗
    handleJoinSubmit: function handleJoinSubmit() {
      var _this45 = this;
      confirmToNoJoinBatch([this.joinFormData.id]).then(function (res) {
        _this45.$notification["success"]({
          message: "success",
          description: "提交成功"
        });
        _this45.visibleDrawer = false;
        _this45.visibleConfimSubmit = true;
        _this45.getTabNumber();
      });
    },
    handleRegularSubmit: function handleRegularSubmit(e) {
      var _this46 = this;
      // var that = this;
      e.preventDefault();
      this.regularForm.validateFields(function (err, values) {
        if (!err) {
          // start
          var len = _this46.FlowsData && _this46.FlowsData.length;
          values.companyId = _this46.companyId;
          values.empIds = [_this46.regularFormData.id];
          _this46.flag = 2;
          // 没有审批 穿空 1个传当前值 多个选择
          if (len === 1) {
            values.approFlowId = _this46.FlowsData[0].id;
            _this46.funcTransferRegular(values);
          } else if (len > 1) {
            _this46.approvalModal = true;
            _this46.selectValue = _this46.FlowsData[0].id;
            _this46.redisValues = values;
          } else {
            values.approFlowId = "";
            _this46.funcTransferRegular(values);
          }
          // end
        }
      });
    },
    funcTransferRegular: function funcTransferRegular(values) {
      var _this47 = this;
      if (moment().isBefore(values.regularDate)) {
        //
        // console.log("未到期");
        this.iszheng = false;
      } else {
        // console.log("已到期");
        this.iszheng = true;
      }
      transferRegular(values).then(function (res) {
        _this47.$notification["success"]({
          message: "success",
          description: "提交成功"
        });
        _this47.visibleSubmit = true;
        _this47.titleSubmit = "办理转正";
        _this47.oktext = "确定";
        _this47.visibleRegularDrawer = false;
      });
    },
    employTypeChange: function employTypeChange(val) {
      this.employType = val;
    },
    confirmJoin: function confirmJoin(e) {
      var _this48 = this;
      this.drawerTitle = "确认到岗";
      getEmpField({
        empId: e.id,
        moduleType: "onJob"
      }).then(function (res) {
        var data = res.data.data;
        var additionalFieldVOList = res.data.additionalFieldVOList.reduce(function (res, key) {
          res[key.fieldId] = key.content;
          return res;
        }, {});
        var data1 = Object.assign(res.data.empInfoVO, data, additionalFieldVOList);
        _this48.joinFormData = Object.assign(data1, {
          id: e.id
        });
        _this48.visibleEmpyleeDrawer2 = true;
      });
      this.$nextTick(function () {
        _this48.empyleeForm.setFieldsValue({
          id: e.id,
          name: e.name,
          phone: e.phone,
          idCard: e.idCard,
          formalType: e.formalType,
          probationDays: e.probationDays,
          turnStraightDate: _this48.getMoment(e.turnStraightDate),
          channelId: e.channelId,
          depIdList: e.depName,
          positionIdList: e.positionName,
          postType: e.postType === 0 ? "" : e.postType,
          joinDate: _this48.getMoment(e.joinDate),
          remark: (e === null || e === void 0 ? void 0 : e.remark) || null
        });
        _this48.handleProba(e.probationDays);
      });
      this.selectChange(e.formalType);
    },
    // 未到岗
    noConfirmJoin: function noConfirmJoin(e) {
      // this.visibleJoinDrawer = true;
      this.joinFormData = e;
      this.drawerTitle = "确认未到岗";
      this.handleJoinSubmit();
    },
    // 办理入职 end
    onCloseDrawer: function onCloseDrawer(str) {
      this["visible" + str + "Drawer"] = false;
    },
    onCloseDrawer2: function onCloseDrawer2(tag) {
      this[tag] = false;
    },
    onCloseJoinDrawer: function onCloseJoinDrawer() {
      this.visibleJoinDrawer = false;
    },
    onCloseRegularDrawer: function onCloseRegularDrawer() {
      this.visibleRegularDrawer = false;
    },
    // 转正点击
    confirmRegular: function confirmRegular(e) {
      this.visibleRegularDrawer = true;
      this.regularFormData = e;
      this.disableJoinDate = moment(e.joinDate);
      this.turnStraightFlowsFunc();
    },
    handleCancelSubmit: function handleCancelSubmit(tag) {
      // this.reload()
      this[tag] = false;
      this.approvalModal = false;
      this.visibleJoinDrawer = false;
      this.tapChange(this.selectId);
      this.getTabNumber();
    },
    // 取消筛选
    cancelSelect: function cancelSelect() {
      this.selectBox = false;
      // 清空条件
      this.nameOrPhone = "";
      this.joinFormStatus = undefined;
      this.defaultTableFormValue = "入职登记表是否已提交";
      this.preJoinDateEnd = "";
      this.preJoinDateStart = "";
      this.sex = "";
      this.employType = "";
      this.formalType = "";
      this.depId = "";
      this.sexvalue = "";
      this.employTypevalue = "";
      this.formalTypevalue = "";
      this.depIdvalue = "";
      this.getEmpInfos();
    },
    preventDefault: function preventDefault(x, y) {
      this[x] = "";
      if (y) {
        this[y] = "";
      }
      this[x + "value"] = "";
      this.getEmpInfos();
      if (this.sex === "" && this.employType === "" && this.formalType === "" && this.depId === "" && this.nameOrPhone === "" && this.joinFormStatus === "" && this.preJoinDateEnd === "" && this.preJoinDateStart === "") {
        this.selectBox = false;
      }
    },
    tapChange: function tapChange(id) {
      var _this49 = this;
      this.managementList.pagination.current = 1;
      this.selectId = id;
      this.hideList("leaveTag");
      this.hideList("moveTag");
      this.searchForm = {};
      var data = this.tabList.filter(function (item) {
        return item.id === _this49.selectId;
      });
      this.managementList.title = data[0].tableColumn;
      this.getEmpInfos();
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    leave: function leave(record) {
      this.$router.push({
        path: "/staff/leave",
        query: {
          empInfoId: record.id
        }
      });
    },
    // 批量调岗
    merberBatchDG: function merberBatchDG() {
      var _this50 = this;
      var tmep_name = "";
      for (var i = 0; i < this.batchArrData.length; i++) {
        tmep_name += this.batchArrData[i].name + ",";
      }
      this.nameDisabled = true;
      this.visibleMoveDrawer = true;
      this.$nextTick(function () {
        _this50.moveForm.resetFields();
      });
      // this.selectedRowKeys = [e.id];

      setTimeout(function () {
        _this50.moveForm.setFieldsValue({
          name: tmep_name
        });
      }, 300);
      this.transferDutyFlowsFunc();
    },
    makeTransfer: function makeTransfer(e) {
      var _this51 = this;
      this.nameDisabled = true;
      this.visibleMoveDrawer = true;
      this.$nextTick(function () {
        _this51.moveForm.resetFields();
      });
      this.batchArrData = [{
        id: e.id
      }];
      setTimeout(function () {
        _this51.moveForm.setFieldsValue({
          name: e.name
        });
      }, 300);
      this.transferDutyFlowsFunc();
    },
    select: function select(e, x) {
      // 清空条件
      this.selectBox = true;
      this[x + "value"] = e.label;
      this.$set(this.managementList.pagination, "current", 1);
      this.getEmpInfos(1);
    },
    detail: function detail(row, type) {
      var _this52 = this;
      var target = this.tabList.filter(function (item) {
        return item.id === _this52.selectId;
      });
      var searchData = _objectSpread(_objectSpread({
        queryType: type,
        status: target[0]["status"],
        companyId: this.companyId
      }, this.searchForm), {}, {
        joinFormStatus: this.searchForm.joinFormStatus === "入职登记表是否已提交" ? undefined : this.searchForm.joinFormStatus
      });
      this.$router.push({
        path: "/staff/detail",
        query: {
          id: row.id,
          userId: row.userId,
          prevpage: location.hash.slice(1)
        }
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      console.log("bbbbbbb" + pagination.current);
      this.managementList.pagination.current = pagination.current;
      this.managementList.pagination.pageSize = pagination.pageSize;
      this.getEmpInfos();
    },
    tree: function tree(array, childKey, onlyLastChild) {
      var _this53 = this;
      if (array === null || array.length < 1) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id;
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        if (item.children) {
          item["disabled"] = onlyLastChild;
          _this53.tree(item.children, childKey, onlyLastChild);
        }
      });
      return array;
    },
    tree1: function tree1(array, childKey, onlyLastChild) {
      var _this54 = this;
      if (array === null || array.length < 1) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id;
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        if (item.leave !== 3) {
          item["disabled"] = onlyLastChild;
        }
        if (item.children) {
          _this54.tree(item.children, childKey, onlyLastChild);
        }
      });
      return array;
    }
  }
};