import upload from "./component/batchShebaoUpload";
import preview from "./component/preview/batchShebaoPreview";
import results from "./component/batchShebaoResults";
export default {
  components: {
    upload: upload,
    preview: preview,
    results: results
  },
  data: function data() {
    return {
      current: 0,
      steps: [{
        title: "上传文件"
      }, {
        title: "数据预览"
      }, {
        title: "查看结果"
      }],
      status: false,
      rdata: "",
      id: "",
      url: "",
      downloadUrl: "/hr/api/v1/excel/template/download?templateName=bulkEditingSocImportTemplate.xlsx"
    };
  },
  created: function created() {
    this.url = "/hr/api/v1/empSocInfos/bulkEditImport/" + this.$route.query.monthId;
  },
  methods: {
    onAction: function onAction(e, v) {
      this.current = e;
      if (v) {
        this.dataJson = v;
      }
    },
    onActionS: function onActionS(e, status, data) {
      this.current = e;
      this.status = status;
      this.rdata = data;
    }
  }
};