var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(_vm._s(_vm.status === "add" ? "新增人员" : "员工报送信息详情"))]), _c("basicInfo", {
    attrs: {
      title: "员工基本信息",
      editable: _vm.basicInfoFormJson.editable
    }
  }, [_c("moduleShow", {
    ref: "basic",
    staticClass: "pt16",
    staticStyle: {
      margin: "0 auto"
    },
    attrs: {
      title: "员工基本信息",
      status: _vm.status,
      dataSource: _vm.basicData,
      editable: _vm.basicInfoFormJson.editable,
      dynamicData: {},
      formFields: _vm.basicInfoFormJson.fields,
      sortedKeys: _vm.basicInfoFormJson.sortedKeys,
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.basicInfoFormJson, "editable", $event);
      },
      itemChange: _vm.itemChangebasic,
      change: function change($event) {
        return _vm.dynamicChange($event, "basic", "basicInfoFormJson", null);
      },
      edit: function edit($event) {
        return _vm.editItem("basicInfoFormJson");
      },
      delete: function _delete($event) {
        return _vm.deleteItem("basicInfoFormJson");
      },
      cancel: function cancel($event) {
        return _vm.cancelItem("basicInfoFormJson");
      }
    }
  })], 1), _c("basicInfo", {
    attrs: {
      title: "联系方式",
      editable: _vm.detailedFormJson.editable
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.detailedFormJson, "editable", $event);
      }
    }
  }, [_c("moduleShow", {
    ref: "detailed",
    staticClass: "pt16",
    staticStyle: {
      margin: "0 auto"
    },
    attrs: {
      title: "联系方式",
      status: _vm.status,
      detailedShow: _vm.detailedShow,
      dataSource: _vm.detailedData,
      editable: _vm.detailedFormJson.editable,
      dynamicData: {},
      formFields: _vm.detailedFormJson.fields,
      sortedKeys: _vm.detailedFormJson.sortedKeys,
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.detailedFormJson, "editable", $event);
      },
      itemChange: _vm.itemChangebasic,
      change: function change($event) {
        return _vm.dynamicChange($event, "detailed", "detailedFormJson", null);
      },
      edit: function edit($event) {
        return _vm.editItem("detailedFormJson");
      },
      delete: function _delete($event) {
        return _vm.deleteItem("detailedFormJson");
      },
      cancel: function cancel($event) {
        return _vm.cancelItem("detailedFormJson");
      }
    }
  })], 1), _c("basicInfo", {
    attrs: {
      title: "任职受雇信息",
      editable: _vm.employmentFormJson.editable
    }
  }, _vm._l(_vm.employmentList, function (i, index) {
    return _c("div", {
      key: i.idNum,
      staticClass: "bore8"
    }, [_c("moduleShow", {
      ref: i.idNum,
      refInFor: true,
      staticClass: "pt16",
      staticStyle: {
        margin: "0 auto"
      },
      attrs: {
        title: "任职受雇信息",
        status: _vm.status,
        remove: _vm.status === "add" ? true : _vm.submissionState(i.submissionState),
        dataSource: i,
        editable: i.editable,
        dynamicData: {},
        formFields: i.fields,
        sortedKeys: i.sortedKeys,
        formConfig: {},
        dataListArr: _vm.dataList
      },
      on: {
        "update:editable": function updateEditable($event) {
          return _vm.$set(i, "editable", $event);
        },
        itemChange: _vm.itemChangebasic,
        remove: function remove($event) {
          return _vm.dynamicRemove(i.idNum, index);
        },
        change: function change($event) {
          return _vm.dynamicChange($event, "employment", "employmentFormJson", i.idNum);
        },
        edit: function edit($event) {
          return _vm.editItem("employmentFormJson", i);
        },
        delete: function _delete($event) {
          return _vm.deleteItem("employmentFormJson", i);
        },
        cancel: function cancel($event) {
          return _vm.cancelItem("employmentFormJson", i);
        }
      }
    })], 1);
  }), 0), _c("div", {
    staticStyle: {
      "text-align": "center",
      "padding-bottom": "65px"
    }
  }, [_vm.addEmploymentInfoValShow ? _c("a-button", {
    on: {
      click: function click($event) {
        return _vm.addEmploymentInfoVal();
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加任职受雇信息 ")], 1) : _vm._e()], 1), _vm.status === "add" ? _c("div", {
    staticClass: "boxBottom"
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.addSave
    }
  }, [_vm._v("完成")])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };