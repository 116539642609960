import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("div", {
    staticClass: "xq-form-search"
  }, [_c("div", {
    staticClass: "list-box-action"
  }, [_c("div", {
    staticStyle: {
      position: "relative"
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "266px",
      "margin-right": "12px"
    },
    attrs: {
      placeholder: "搜索姓名/手机号/公司名/分配人",
      allowClear: ""
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.baseGet,
      callback: function callback($$v) {
        _vm.baseGet = $$v;
      },
      expression: "baseGet"
    }
  }), _c("a-button", {
    staticStyle: {
      color: "#AAAAAA"
    },
    attrs: {
      icon: "filter"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.$store.commit("clientele/screenVisible", true);
      }
    }
  }, [_vm.$store.state.clientele.screenVisible ? _c("div", {
    staticClass: "drawer"
  }, [_c("div", {
    staticClass: "triangle"
  }), _c("a-row", {
    staticClass: "search-box"
  }, [_c("a-col", {
    staticClass: "search-box-item",
    attrs: {
      span: 6
    }
  }, [_c("p", [_vm._v("客户来源")]), _c("a-select", {
    staticClass: "input",
    attrs: {
      allowClear: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.sourceType,
      callback: function callback($$v) {
        _vm.sourceType = $$v;
      },
      expression: "sourceType"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 分配 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 录入 ")]), _c("a-select-option", {
    attrs: {
      value: 3
    }
  }, [_vm._v(" 领取 ")])], 1)], 1), _c("a-col", {
    staticClass: "search-box-item",
    attrs: {
      span: 6
    }
  }, [_c("p", [_vm._v("客户所属")]), _c("a-input", {
    staticClass: "input",
    attrs: {
      placeholder: "请输入",
      allowClear: ""
    },
    model: {
      value: _vm.memberName,
      callback: function callback($$v) {
        _vm.memberName = $$v;
      },
      expression: "memberName"
    }
  })], 1), _c("a-col", {
    staticClass: "search-box-item",
    attrs: {
      span: 6
    }
  }, [_c("p", [_vm._v("分配时间")]), _c("a-range-picker", {
    staticClass: "input",
    attrs: {
      valueFormat: "YYYY-MM-DD",
      allowClear: ""
    },
    model: {
      value: _vm.distributionTime,
      callback: function callback($$v) {
        _vm.distributionTime = $$v;
      },
      expression: "distributionTime"
    }
  })], 1), _c("a-col", {
    staticClass: "search-box-item",
    attrs: {
      span: 6
    }
  }, [_c("p", [_vm._v("拜访时间")]), _c("a-range-picker", {
    staticClass: "input",
    attrs: {
      valueFormat: "YYYY-MM-DD",
      allowClear: ""
    },
    model: {
      value: _vm.visitTime,
      callback: function callback($$v) {
        _vm.visitTime = $$v;
      },
      expression: "visitTime"
    }
  })], 1), _c("a-col", {
    staticClass: "search-box-item",
    attrs: {
      span: 6
    }
  }, [_c("p", [_vm._v("签约时间")]), _c("a-range-picker", {
    staticClass: "input",
    attrs: {
      valueFormat: "YYYY-MM-DD",
      allowClear: ""
    },
    model: {
      value: _vm.signTime,
      callback: function callback($$v) {
        _vm.signTime = $$v;
      },
      expression: "signTime"
    }
  })], 1)], 1), _c("div", {
    style: {
      position: "absolute",
      right: 0,
      bottom: 0,
      width: "100%",
      borderTop: "1px solid #E8E8E8",
      padding: "10px 16px",
      background: "#fff",
      textAlign: "right",
      zIndex: 1
    }
  }, [_c("a-button", {
    style: {
      marginRight: "12px"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.onClose.apply(null, arguments);
      }
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.onSearch.apply(null, arguments);
      }
    }
  }, [_vm._v(" 查询 ")])], 1)], 1) : _vm._e()])], 1), _c("div", [_vm.shows === 1 ? _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      disabled: _vm.selectedRowKeys.length <= 0
    },
    on: {
      click: function click($event) {
        return _vm.onDistribution(null);
      }
    }
  }, [_vm._v(" 批量分配 ")]) : _vm._e(), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "myClienteleAddClientele",
          query: {
            type: 2
          }
        });
      }
    }
  }, [_vm._v(" 新增客户 ")])], 1)])]), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      "row-selection": _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      } : null
    },
    on: {
      change: _vm.onTableChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "companyName" ? _c("span", {
            key: index
          }, [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [_c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(text))])]), _c("div", {
            staticClass: "overflows"
          }, [_vm._v(" " + _vm._s(text) + " ")])], 2)], 1) : col.dataIndex === "companyAddress" ? _c("span", {
            key: index
          }, [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [_c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(text))])]), _c("div", {
            staticClass: "overflows"
          }, [_vm._v(" " + _vm._s(text) + " ")])], 2)], 1) : col.dataIndex === "mark" ? _c("span", {
            key: index
          }, [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [_c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(text))])]), _c("div", {
            staticClass: "overflows"
          }, [_vm._v(" " + _vm._s(text) + " ")])], 2)], 1) : col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_vm.shows === 1 ? _c("a", {
            staticStyle: {
              "vertical-align": "middle"
            },
            on: {
              click: function click($event) {
                return _vm.onDistribution(record.id);
              }
            }
          }, [_vm._v("分配")]) : _c("a", {
            staticStyle: {
              "vertical-align": "middle"
            },
            on: {
              click: function click($event) {
                return _vm.onMoveSea(record.id);
              }
            }
          }, [_vm._v("移至公海")]), _c("tableActionPoint", [_vm.shows === 1 ? _c("li", {
            on: {
              click: function click($event) {
                return _vm.onMoveSea(record.id);
              }
            }
          }, [_vm._v(" 移至公海 ")]) : _vm._e(), _c("li", {
            on: {
              click: function click($event) {
                return _vm.$router.push({
                  name: "myClienteleEditClientele",
                  query: {
                    type: 2,
                    id: record.id
                  }
                });
              }
            }
          }, [_vm._v(" 编辑 ")]), _c("li", {
            on: {
              click: function click($event) {
                return _vm.$router.push({
                  name: "myClienteleMsgClientele",
                  query: {
                    type: 2,
                    id: record.id,
                    name: record.name
                  }
                });
              }
            }
          }, [_vm._v(" 详情 ")])])], 1) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1), _c("moveSea", {
    attrs: {
      visible: _vm.moveSeaVisible,
      id: _vm.moveSeaId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.moveSeaVisible = $event;
      },
      upDate: _vm.onGetCustomerPage
    }
  }), _c("distribution", {
    attrs: {
      visible: _vm.distributionVisible,
      distributionVal: _vm.distributionVal
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.distributionVisible = $event;
      },
      upDate: _vm.onGetCustomerPage
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };