import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { Menu } from "ant-design-vue";
import Item from "./item";
export default {
  name: "SubMenu",
  components: {
    Item: Item
  },
  props: _objectSpread(_objectSpread({}, Menu.SubMenu.props), {}, {
    item: {
      type: Object,
      required: true
    },
    hideMenu: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  })
};