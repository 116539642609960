import { formatDate } from "@/utils/index";
export var pilianglizhiColumns = [{
  title: "姓名",
  dataIndex: "empName",
  width: 100,
  align: "center",
  ellipsis: true
}, {
  title: "确认离职日期",
  dataIndex: "confirmLeaveDate",
  width: 120,
  align: "center",
  ellipsis: true,
  customRender: function customRender(text) {
    return formatDate(text);
  }
}, {
  title: "社保停保年月",
  dataIndex: "socDescription",
  width: 120,
  align: "center",
  ellipsis: true
}, {
  title: "医保停保年月",
  dataIndex: "meDescription",
  width: 120,
  align: "center",
  ellipsis: true
}, {
  title: "公积金停保年月",
  dataIndex: "pfDescription",
  width: 130,
  align: "center",
  ellipsis: true
}, {
  title: "参保方案",
  dataIndex: "schemeName",
  width: 100,
  align: "center",
  ellipsis: true
}, {
  title: "参保城市",
  dataIndex: "socCityName",
  width: 100,
  align: "center",
  ellipsis: true
}
// {
//   title: "减员后服务费",
//   dataIndex: "serviceCost",
//   width: 100,
//   align: "center",
//   ellipsis: true
// }
];

var name = {
  title: "姓名",
  dataIndex: "name",
  width: 140,
  align: "center",
  ellipsis: true
};
var idCard = {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200,
  align: "center",
  ellipsis: true
};
var phone = {
  title: "手机号",
  dataIndex: "phone",
  width: 140,
  align: "center",
  ellipsis: true
};
var entryTime = {
  title: "入职时间",
  dataIndex: "joinDate",
  customRender: function customRender(text, record, index) {
    return formatDate(text);
  },
  width: 130,
  align: "center",
  ellipsis: true
};
// 入职时间
var joinDate = {
  title: "入职时间",
  dataIndex: "joinDate",
  customRender: function customRender(text, record, index) {
    return formatDate(text);
  },
  width: 150,
  align: "center",
  ellipsis: true
};
// 离职时间
var preQuitDate = {
  title: "离职时间",
  dataIndex: "preQuitDate",
  customRender: function customRender(text, record, index) {
    return formatDate(text);
  },
  width: 150,
  align: "center",
  ellipsis: true
};
// 转正时间
var regularData = {
  title: "转正时间",
  dataIndex: "turnStraightDate",
  customRender: function customRender(text, record, index) {
    return formatDate(text);
  },
  width: 150,
  align: "center",
  ellipsis: true
};
var status = {
  title: "状态",
  dataIndex: "status",
  scopedSlots: {
    customRender: "status"
  },
  width: 100,
  align: "center",
  ellipsis: true
};
var operate = {
  fixed: "right",
  title: "操作",
  dataIndex: "operate",
  width: "240px",
  scopedSlots: {
    customRender: "operate"
  },
  align: "center",
  ellipsis: true
};
var quitReason = {
  title: "离职原因",
  dataIndex: "quitReason",
  scopedSlots: {
    customRender: "quitReason"
  },
  width: 150,
  align: "center",
  ellipsis: true
};
var quitRemarks = {
  title: "离职备注",
  dataIndex: "quitRemarks",
  scopedSlots: {
    customRender: "quitRemarks"
  },
  width: 150,
  align: "center",
  ellipsis: true
};
var prePositionName = {
  title: "原岗位",
  dataIndex: "prePositionName",
  scopedSlots: {
    customRender: "prePositionName"
  },
  width: 150,
  align: "center",
  ellipsis: true
};
var currentPositionName = {
  title: "调岗后岗位",
  dataIndex: "currentPositionName",
  scopedSlots: {
    customRender: "currentPositionName"
  },
  width: 150,
  align: "center",
  ellipsis: true
};
var preDepName = {
  title: "原部门",
  dataIndex: "preDepName",
  scopedSlots: {
    customRender: "preDepName"
  },
  width: 150,
  align: "center",
  ellipsis: true
};
var currentDepName = {
  title: "调岗后部门",
  dataIndex: "currentDepName",
  scopedSlots: {
    customRender: "currentDepName"
  },
  width: 150,
  align: "center",
  ellipsis: true
};
var adjustmentTime = {
  title: "调岗时间",
  dataIndex: "adjustmentTime",
  customRender: function customRender(text, record, index) {
    return formatDate(text);
  },
  width: 150,
  align: "center",
  ellipsis: true
};
var adjustmentReason = {
  title: "调岗原因",
  dataIndex: "adjustmentReason",
  scopedSlots: {
    customRender: "adjustmentReason"
  },
  width: 150,
  align: "center",
  ellipsis: true
};
var allColumn1 = [name, idCard, phone, entryTime, status, operate];
var allColumn2 = [name, idCard, phone, regularData, status, operate];
var allColumn3 = [name, idCard, phone, joinDate, regularData, status, operate];
var allColumn4 = [name, idCard, phone, preQuitDate, quitReason, quitRemarks, status, operate];
var allColumn5 = [name, idCard, phone, preDepName, currentDepName, prePositionName, currentPositionName, adjustmentTime, adjustmentReason, status, operate];
var tabList = [{
  id: "1",
  name: "countPre",
  title: "待入职管理",
  color: localStorage.getItem("primary"),
  img: require("./img/1.png"),
  tableColumn: allColumn1,
  count: 0,
  status: 1
}, {
  id: "2",
  name: "countWaitInformalOn",
  title: "待转正管理",
  color: "#FCCF5E",
  img: require("./img/2.png"),
  tableColumn: allColumn2,
  count: 0,
  status: 2,
  formalType: 1,
  employType: 1
}, {
  id: "3",
  name: "countHasInformalOn",
  title: "在职管理",
  color: "#4FAEFC",
  img: require("./img/3.png"),
  tableColumn: allColumn3,
  count: 0,
  status: 2
}, {
  id: "4",
  name: "countLeaveOn",
  title: "待离职管理",
  color: "#FF6D6D",
  img: require("./img/4.png"),
  tableColumn: allColumn4,
  count: 0,
  status: 4
}, {
  id: "5",
  name: "countChange",
  title: "待调岗管理",
  color: "#44D7B6",
  img: require("./img/5.png"),
  tableColumn: allColumn5,
  count: 0,
  status: 5
}];
var columnDialogDetailguding = [{
  title: "姓名",
  dataIndex: "empName",
  fixed: "left",
  align: "center",
  ellipsis: true,
  width: 80
}, {
  title: "是否社保减员",
  dataIndex: "whetherSocDecrease",
  align: "center",
  ellipsis: true,
  width: 80
}, {
  title: "封停社保日期",
  dataIndex: "stopSocDate",
  align: "center",
  ellipsis: true,
  width: 80
}, {
  title: "公积金封存日期",
  dataIndex: "sealedDate",
  align: "center",
  ellipsis: true,
  width: 80
}, {
  title: "是否签署合同",
  dataIndex: "whetherLaunchedContract",
  align: "center",
  ellipsis: true,
  width: 90
}];
var pagination = {
  pageSize: 10,
  total: 0,
  size: "default",
  current: 1,
  showTotal: function showTotal(total) {
    return "\u5171 ".concat(total, " \u6761");
  },
  showQuickJumper: true,
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "50", "100"]
};
var pagination1 = {
  pageSize: 10,
  total: 0,
  size: "default",
  current: 1,
  showTotal: function showTotal(total) {
    return "\u5171 ".concat(total, " \u6761");
  },
  showQuickJumper: true,
  pageSizeOptions: ["10", "20", "50", "100"]
};
var managementList = {
  pageSize: 10,
  title: allColumn1,
  data: [],
  pagination: pagination,
  loading: false
};
export { tabList, managementList, columnDialogDetailguding, pagination, pagination1 };