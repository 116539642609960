import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.timers.js";
import { Wages, Files, Common } from "@/api/wages/index";
var _data = [{
  id: 1,
  key: "1",
  firstName: "John",
  lastName: "Brown",
  age: 32,
  address: "New "
}];
export default {
  name: "Social",
  data: function data() {
    return {
      statusType: null,
      // 本月补缴社保
      showSocRemark: false,
      // 本月补缴公积金
      showPfRemark: false,
      data: _data,
      iconType: "right",
      id: this.$route.query.id,
      form: this.$form.createForm(this, {
        name: "social"
      }),
      options: [],
      city: "",
      needData: {
        empId: this.$route.query.id,
        companyId: this.$store.state.hr.companyId,
        censusType: 1,
        personTaxAmount: 0,
        personTaxProp: 0,
        pfAmountCompany: 0,
        // 公积金企业缴纳金额
        pfAmountPerson: 0,
        // 公积金个人缴纳金额
        pfBasic: 0,
        pfCity: [],
        pfPaybackMonth: false,
        // 0 1
        pfPaybackMonthAmount: 0,
        // 本月补缴公积金金额
        pfPropCompany: 0,
        pfPropPerson: 0,
        pfUnpayMonth: false,
        // 0 1
        salaryBasic: 0,
        socAmountCompany: 0,
        // 社保企业缴纳金额
        socAmountPerson: 0,
        // 社保个人缴纳金额
        socBasic: 0,
        socCity: [],
        socMain: 1,
        socPaybackMonth: false,
        // 0 1
        socPaybackMonthAmount: 0,
        // 本月补缴社保金额
        socPropCompany: 0,
        socPropPerson: 0,
        socRemark: "",
        socType: 1,
        socUnpayMonth: false,
        // 0 1
        valid: 1,
        workInjuryProp: 0
      }
    };
  },
  mounted: function mounted() {
    if (this.id) {
      this.getEmpInfos();
      this.getCity();
    }
  },
  methods: {
    /* 获取省 */getCity: function getCity() {
      var _this = this;
      Wages({
        url: Common.city
      }).then(function (_ref) {
        var data = _ref.data;
        if (data && data.length) {
          _this.loop(data);
          _this.options = data;
        }
      });
    },
    loop: function loop(data) {
      var _this2 = this;
      data.forEach(function (item) {
        item.label = item.name;
        item.value = item.id;
        if (item.children) _this2.loop(item.children);
      });
    },
    /* 城市级联 */onChangeCity: function onChangeCity(value) {},
    /* 员工信息 */getEmpInfos: function getEmpInfos() {
      var _this3 = this;
      Wages({
        url: Files.searchEmpInfos + this.id
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this3.needData = Object.assign({}, _this3.needData, data);
      }).then(function () {
        _this3.getSocInfo();
      });
    },
    /* 员工社保信息 */getSocInfo: function getSocInfo() {
      var _this4 = this;
      Wages({
        url: Files.socInfo,
        params: {
          empId: this.id
        }
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this4.statusType = data.entities.length;
        if (data) {
          _this4.needData = Object.assign({}, _this4.needData, data.entities[0]);
          /* 金钱格式化 */
          _this4.needData.socBasic /= 100;
          _this4.needData.socAmountCompany /= 100;
          _this4.needData.socAmountPerson /= 100;
          _this4.needData.pfBasic /= 100;
          _this4.needData.pfAmountCompany /= 100;
          _this4.needData.pfAmountPerson /= 100;
          /* 金钱格式化 */
          _this4.needData.socType = Number(_this4.needData.socType);
          if (_this4.needData.pfCity.length) {
            _this4.needData.pfCity = JSON.parse(_this4.needData.pfCity);
          }
          if (_this4.needData.socCity.length) {
            _this4.needData.socCity = JSON.parse(_this4.needData.socCity);
          }
        }
      });
    },
    /* 更新保存 */updateSave: function updateSave() {
      var _this5 = this;
      var copyData = JSON.parse(JSON.stringify(this.needData));
      /* 金钱格式化 */
      copyData.socBasic *= 100;
      copyData.socAmountCompany *= 100;
      copyData.socAmountPerson *= 100;
      copyData.pfBasic *= 100;
      copyData.pfAmountCompany *= 100;
      copyData.pfAmountPerson *= 100;
      /* 金钱格式化 */

      this.form.validateFields(function (err, values) {
        copyData.pfCity = values.needData.pfCity;
        copyData.socCity = values.needData.socCity;
        if (!err) {
          if (!!_this5.statusType) {
            Wages({
              url: Files.socInfo,
              data: copyData,
              method: "PUT"
            }).then(function (data) {
              if (data.success) {
                _this5.$notification["success"]({
                  message: "成功",
                  description: "修改成功"
                });
                setTimeout(function () {
                  _this5.$router.go(-1);
                }, 1000);
              }
            });
          } else {
            Wages({
              url: Files.socInfo,
              data: copyData,
              method: "POST"
            }).then(function (data) {
              if (data.success) {
                _this5.$notification["success"]({
                  message: "成功",
                  description: "新增成功"
                });
                setTimeout(function () {
                  _this5.$router.go(-1);
                }, 1000);
              }
            });
          }
        }
      });
    }
  }
};