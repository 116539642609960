export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    edit: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "form"
    },
    editText: {
      type: String,
      default: "编辑"
    },
    showBtns: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      boxshow: true
    };
  },
  methods: {
    isBoxshow: function isBoxshow() {
      this.boxshow = true;
    }
  }
};