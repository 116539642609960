import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wage-set-group"
  }, [_c("div", {
    staticClass: "top-wrapper"
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("a-alert", {
    staticStyle: {
      width: "620px"
    },
    attrs: {
      message: "请先前往【工资表模板】创建用工单位的工资表模板，然后新增薪资组并选择计税规则",
      banner: "",
      closable: ""
    }
  }), _vm._m(0)], 1), _c("div", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addGroup
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _c("span", [_vm._v("新增薪资组")])], 1)], 1)]), _c("div", [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      scroll: {
        x: 1000
      },
      "data-source": _vm.groupList,
      "row-key": "id",
      loading: _vm.loading,
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return _c("span", {
          staticStyle: {
            "margin-left": "-35px"
          }
        }, [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.editClass(record.id);
            }
          }
        }, [_vm._v("编辑")]), _c("span", {
          staticClass: "primary"
        }, [_vm._v("|")]), _c("a-button", {
          attrs: {
            type: "link",
            disabled: record.name === "默认薪资组"
          },
          on: {
            click: function click($event) {
              return _vm.handleVisible(record.id);
            }
          }
        }, [_vm._v(" 删除 ")])], 1);
      }
    }])
  })], 1), _c("a-modal", {
    attrs: {
      title: "删除薪资组"
    },
    on: {
      ok: _vm.handleDelete
    },
    model: {
      value: _vm.groupVisible,
      callback: function callback($$v) {
        _vm.groupVisible = $$v;
      },
      expression: "groupVisible"
    }
  }, [_c("div", [_vm._v("是否确认删除该薪资组？")])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "wage-top-title"
  }, [_vm._v("使用指南")]), _c("div", {
    staticClass: "wage-top-explain"
  }, [_c("div", [_vm._v("1、薪资组管理，即把人员分组，为每个组设置不同的工资表模板，计税方案。")]), _c("div", [_vm._v("2、一位员工只能适用一个薪资组。")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };