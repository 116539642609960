var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [!_vm.abnormalS ? _c("div", {
    staticStyle: {
      "padding-bottom": "64px"
    }
  }, [_c("infoCard", {
    ref: "infoCard"
  }), _c("div", {
    staticClass: "xq-form-wrapper p24 mt24"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      width: "100%",
      "margin-bottom": "16px",
      "justify-content": "space-between"
    }
  }, [_c("titborder", [_vm._v(" 上传数据预览 ")]), _c("div", [_c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: function click($event) {
        return _vm.onEditMoney();
      }
    }
  }, [_vm._v(" 批量编辑薪资 ")]), _c("QrcodeForPay", {
    attrs: {
      title: "微信扫码录入",
      showFunc: _vm.showQrcode
    }
  }), _c("a-button", {
    staticClass: "mr12",
    on: {
      click: _vm.onSelect
    }
  }, [_vm._v(" 新增计薪人员 ")]), _c("a-upload", {
    ref: "upload",
    attrs: {
      showUploadList: false,
      "file-list": _vm.fileList,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      type: "primary"
    }
  }, [_vm._v(" 上传发薪表 ")])], 1)], 1)], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      rowSelection: _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      } : null
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "name" ? _c("div", {
            key: index
          }, [_c("span", [_vm._v(_vm._s(text))])]) : col.dataIndex === "money" ? _c("div", {
            key: index
          }, [_c("span", [_vm._v(_vm._s(text))])]) : col.dataIndex === "identityBinding" ? _c("div", {
            key: index,
            staticStyle: {
              position: "relative"
            }
          }, [_c("a-popover", {
            key: index,
            attrs: {
              title: "数据错误",
              placement: "right"
            }
          }, [_c("template", {
            slot: "content"
          }, [_c("p", [_vm._v("该员工微工卡未授权，请让员工前往小程序端进行绑定或者使用“微信扫码录入”进行微工卡绑定")])]), record.type === 2 && !record.identityBinding ? _c("div", {
            staticClass: "triangle"
          }) : _vm._e()], 2), _c("span", [_vm._v(_vm._s(text ? "已绑定" : "未绑定"))])], 1) : col.dataIndex === "identity" ? _c("div", {
            key: index
          }, [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [_c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(text))])]), _c("div", {
            staticClass: "overflowsIdentity"
          }, [_vm._v(_vm._s(text))])], 2)], 1) : col.dataIndex === "bankName" ? _c("div", {
            key: index
          }, [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [_c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(text))])]), _c("div", {
            staticClass: "overflowsIdentity"
          }, [_vm._v(_vm._s(text))])], 2)], 1) : col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("a", {
            on: {
              click: function click($event) {
                return _vm.onEditMoney(record.id);
              }
            }
          }, [_vm._v("编辑")]), _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a-popconfirm", {
            attrs: {
              title: "\u786E\u8BA4\u8981\u5220\u9664\u5417\uFF1F",
              "ok-text": "确认",
              "cancel-text": "取消"
            },
            on: {
              confirm: function confirm($event) {
                return _vm.onClose(record.id);
              }
            }
          }, [_c("a", [_vm._v("删除")])])], 1) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"],
      "page-size": _vm.pageSize
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "sumbit"
  }, [_c("a-button", {
    staticClass: "mr12",
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 返回 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.tableData.length <= 0 || _vm.tableData[0].type == 2 && !_vm.tableData[0].identityBinding
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v(" 发起申请 ")])], 1), _c("selectScope", {
    attrs: {
      visible: _vm.selectScopeVisible,
      type: "info",
      companyId: _vm.info.companyId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.selectScopeVisible = $event;
      },
      upDate: _vm.upDate
    }
  }), _c("editMoney", {
    attrs: {
      visible: _vm.editMoneyVisible,
      selectedRowKeysId: _vm.selectedRowKeysId,
      selectedRowKeys: _vm.selectedRowKeys
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editMoneyVisible = $event;
      },
      upDate: _vm.upDateEditMoney
    }
  })], 1) : _c("abnormal", {
    attrs: {
      type: "info",
      arr: _vm.abnormalArr
    },
    on: {
      upDate: _vm.upDateAbnormal
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };