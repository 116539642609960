var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      width: 650,
      title: "添加汇报人员"
    },
    on: {
      ok: _vm.handleOk
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-layout-content", [_c("a-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-row", {
    staticClass: "tree-body"
  }, [_c("report-tree", {
    attrs: {
      data: _vm.treeData
    },
    on: {
      "update:data": function updateData($event) {
        _vm.treeData = $event;
      }
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "mg_l20",
    attrs: {
      span: 9
    }
  }, [_c("a-row", {
    staticClass: "sub_tit mg_b5"
  }, [_vm._v("已经选择的部门或成员")]), _vm._l(_vm.reportList, function (item, i) {
    return _c("a-row", {
      key: i,
      staticClass: "pd_6"
    }, [_c("a-icon", {
      staticClass: "mg_r5",
      attrs: {
        type: "user"
      }
    }), _vm._v(_vm._s(item.empInfoName) + " ")], 1);
  })], 2)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };