var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white relative",
    staticStyle: {
      height: "calc(100vh - 112px)",
      "padding-bottom": "65px"
    }
  }, [_c("div", {
    staticClass: "ta-center fs16 fw500 p16 border-bottom relative"
  }, [_c("span", [_vm._v(_vm._s(_vm.institutionId ? "编辑" : "新增") + "规章制度")]), _c("div", {
    staticClass: "absolute left-0 top-0 ml16 pointer flex-center",
    staticStyle: {
      color: "rgba(192, 192, 192, 1)",
      "line-height": "58px"
    },
    on: {
      click: function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c("a-icon", {
    staticClass: "fs24 mr8",
    attrs: {
      type: "left"
    }
  }), _c("span", [_vm._v("返回")])], 1)]), _c("div", {
    staticClass: "overflow-y-auto",
    staticStyle: {
      margin: "16px auto",
      width: "800px",
      height: "calc(100vh - 262px)"
    }
  }, [_c("div", {
    staticClass: "title-block"
  }, [_vm._v("所属分类")]), _vm.groupLst.length > 0 ? _c("div", {
    staticClass: "p16"
  }, [_c("a-tree-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      treeData: _vm.groupLst,
      replaceFields: {
        children: "childList",
        title: "institutionTypeName",
        value: "id"
      },
      treeNodeFilterProp: "title",
      placeholder: "请选择",
      "show-search": ""
    },
    model: {
      value: _vm.institutionTypeId,
      callback: function callback($$v) {
        _vm.institutionTypeId = $$v;
      },
      expression: "institutionTypeId"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "title-block"
  }, [_vm._v("所属用工单位")]), _c("div", {
    staticClass: "p16"
  }, [_c("a-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      options: _vm.companyLst,
      placeholder: "请选择",
      disabled: "",
      filterOption: _vm.filterOption,
      "show-search": ""
    },
    model: {
      value: _vm.companyId,
      callback: function callback($$v) {
        _vm.companyId = $$v;
      },
      expression: "companyId"
    }
  })], 1), _c("div", {
    staticClass: "title-block"
  }, [_vm._v("制度标题")]), _c("div", {
    staticClass: "p16"
  }, [_c("a-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.institutionTitle,
      callback: function callback($$v) {
        _vm.institutionTitle = $$v;
      },
      expression: "institutionTitle"
    }
  })], 1), _c("div", {
    staticClass: "title-block"
  }, [_vm._v("是否开启")]), _c("div", {
    staticClass: "p16"
  }, [_c("a-switch", {
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  })], 1), _c("div", {
    staticClass: "title-block"
  }, [_vm._v("附件上传")]), _c("div", {
    staticClass: "p16"
  }, [_c("a-upload", {
    attrs: {
      fileList: _vm.institutionUrl,
      multiple: "",
      action: "/file/api/v1/general/upload/attachment",
      name: "files"
    },
    on: {
      change: _vm.onFileChange
    }
  }, [_c("a-button", [_c("a-icon", {
    attrs: {
      type: "upload"
    }
  }), _vm._v("点击上传")], 1)], 1)], 1), _c("div", {
    staticClass: "title-block"
  }, [_vm._v("制度内容")]), _c("div", {
    staticClass: "p16"
  }, [_c("VueWangeditor", {
    staticClass: "wangeditor",
    staticStyle: {
      "z-index": "1",
      width: "100%"
    },
    attrs: {
      menus: ["head", "bold", "fontSize", "lineHeight", "fontName", "italic", "underline", "strikeThrough", "foreColor", "backColor", "justify", "image"]
    },
    on: {
      change: _vm.onEditorChange
    },
    model: {
      value: _vm.institutionContent,
      callback: function callback($$v) {
        _vm.institutionContent = $$v;
      },
      expression: "institutionContent"
    }
  })], 1)]), _c("div", {
    staticClass: "p16 ta-right border-top absolute left-0 right-0 bottom-0"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.doSave
    }
  }, [_vm._v("确定")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };