import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "staff-top-item-wrapper"
  }, _vm._l(_vm.topData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "staff-top-item",
      class: {
        mr24: index === 0
      },
      on: {
        click: function click($event) {
          return _vm.goOther(index);
        }
      }
    }, [_c("div", {
      staticClass: "staff-top-item-box",
      style: _vm.cursorIt(index)
    }, [_c("div", {
      staticClass: "staff-top-item-name"
    }, [_vm._v(" " + _vm._s(item.name) + " "), index == 0 ? _c("div", {
      staticClass: "today ml8"
    }, [_vm._v("今日：" + _vm._s(_vm.allCount))]) : _vm._e()]), _c("img", {
      staticClass: "img-left",
      style: index == 0 ? "left:20%" : "",
      attrs: {
        src: item.left,
        alt: "",
        srcset: ""
      }
    }), _c("div", {
      staticClass: "staff-top-item-mcb",
      style: _vm.cursorIt(index)
    }, [_c("img", {
      staticClass: "img-left",
      style: {
        left: index == 0 ? "20%" : ""
      },
      attrs: {
        src: item.left,
        alt: "",
        srcset: ""
      }
    })]), _c("div", {
      staticClass: "staff-top-item-num"
    }, [index == 0 ? _c("a-tooltip", {
      attrs: {
        placement: "bottom"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.num))])]), _c("div", {
      style: index == 0 ? "border-right: 1px solid #fff;" : ""
    }, [_c("div", {
      staticClass: "overflowW",
      on: {
        click: function click($event) {
          return _vm.goTableData(null);
        }
      }
    }, [_vm._v(_vm._s(item.num))])])], 2) : _c("div", [_vm._v(" " + _vm._s(item.num) + " ")]), index == 0 ? _c("div", {
      staticClass: "drz",
      on: {
        click: function click($event) {
          return _vm.goTableData(1);
        }
      }
    }, [_c("div", [_vm._v("待入职")]), _vm._v(" " + _vm._s(item.waitStaffCount) + " ")]) : _vm._e(), index == 0 ? _c("div", {
      staticClass: "drz",
      on: {
        click: function click($event) {
          return _vm.goTableData(2);
        }
      }
    }, [_c("div", [_vm._v("在职")]), _vm._v(" " + _vm._s(item.staffCount) + " ")]) : _vm._e(), index == 0 ? _c("div", {
      staticClass: "drz",
      on: {
        click: function click($event) {
          return _vm.goTableData(4);
        }
      }
    }, [_c("div", [_vm._v("已离职")]), _vm._v(" " + _vm._s(item.leaveStaffCount) + " ")]) : _vm._e(), _c("div", {
      staticClass: "img"
    }, [_c("img", {
      attrs: {
        src: item.right,
        alt: "",
        srcset: ""
      }
    })])], 1)]), _c("div", {
      staticClass: "staff-top-item-mca"
    }), _c("div", {
      staticClass: "staff-top-item-mcc"
    })]);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };