import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white",
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "p16",
    staticStyle: {
      width: "300px",
      "flex-shrink": "0"
    }
  }, [_c("div", {
    staticClass: "flex-center-between"
  }, [_c("span", {
    staticClass: "fs16 fw500"
  }, [_vm._v("提成规则")]), _c("span", {
    staticStyle: {
      display: "inline-block",
      cursor: "pointer"
    },
    on: {
      click: _vm.onAddGroupItem
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1)]), _c("div", {
    staticClass: "mt12"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "268px"
    },
    attrs: {
      placeholder: "搜索规则名称",
      allowClear: ""
    },
    on: {
      change: _vm.onGroupSearchChange
    },
    model: {
      value: _vm.rulesName,
      callback: function callback($$v) {
        _vm.rulesName = $$v;
      },
      expression: "rulesName"
    }
  })], 1), _c("div", {
    staticClass: "mt16"
  }, _vm._l(_vm.groupLst, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "p8",
      style: {
        cursor: "pointer",
        "border-bottom": "1px solid #e8e8e8",
        background: _vm.currentGroupId === item.id ? "var(--primary30)" : "transparent",
        color: _vm.currentGroupId === item.id ? "var(--primary)" : "rgba(0, 0, 0, 0.85)"
      },
      on: {
        click: function click($event) {
          return _vm.onClickGroupItem(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.rulesName) + " ")]);
  }), 0)]), _vm.currentGroupTarget ? _c("div", {
    staticClass: "p16",
    staticStyle: {
      flex: "1",
      "border-left": "1px solid #e8e8e8",
      "min-height": "calc(100vh - 112px)"
    }
  }, [_c("div", {
    staticClass: "flex-center-between"
  }, [_c("div", [_c("div", {
    staticClass: "flex-center"
  }, [_c("span", {
    staticClass: "br4 mr8",
    staticStyle: {
      width: "8px",
      height: "8px",
      background: "var(--primary)"
    }
  }), _c("span", {
    staticClass: "fs16 fw500"
  }, [_vm._v(_vm._s(_vm.currentGroupTarget.rulesName))])]), _c("div", {
    staticClass: "fs12 mt4",
    staticStyle: {
      color: "rgba(102, 102, 102, 1)"
    }
  }, [_vm._v("规则说明：" + _vm._s(_vm.currentGroupTarget.remark))])]), _c("a-space", [_c("a-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.onDelGroupItem
    }
  }, [_vm._v("删除")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onEditGroupItem
    }
  }, [_vm._v("编辑")])], 1)], 1), _c("div", {
    staticClass: "mt16"
  }, [_vm._v("提成规则")]), _c("div", {
    staticClass: "mt16"
  }, [_vm._m(0), _c("div", {
    staticClass: "flex-center",
    staticStyle: {
      "border-bottom": "1px solid #e8e8e8"
    }
  }, [_c("div", {
    staticClass: "ta-center p8",
    staticStyle: {
      width: "50%"
    }
  }, [_vm._v("新购/续费")]), _c("div", {
    staticClass: "ta-center p8",
    staticStyle: {
      width: "50%"
    }
  }, [_vm._v(" 新购" + _vm._s(_vm.currentGroupTarget.newProportion) + "% 续费" + _vm._s(_vm.currentGroupTarget.continueProportion) + "% ")])]), _c("div", {
    staticClass: "flex-center",
    staticStyle: {
      "border-bottom": "1px solid #e8e8e8"
    }
  }, [_c("div", {
    staticClass: "ta-center p8",
    staticStyle: {
      width: "50%"
    }
  }, [_vm._v("业务类型")]), _c("div", {
    staticClass: "ta-center p8",
    staticStyle: {
      width: "50%"
    }
  }, [_vm._v(_vm._s(_vm.currentGroupTarget.subjectType || "--"))])]), _c("div", {
    staticClass: "flex-center",
    staticStyle: {
      "border-bottom": "1px solid #e8e8e8"
    }
  }, [_c("div", {
    staticClass: "ta-center p8",
    staticStyle: {
      width: "50%"
    }
  }, [_vm._v("所属地区")]), _c("div", {
    staticClass: "ta-center p8",
    staticStyle: {
      width: "50%"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentGroupTarget.region ? _vm.currentGroupTarget.region.split("_")[1] : "--") + " ")])])]), _c("div", {
    staticClass: "flex-center mt24"
  }, [_c("span", {
    staticClass: "br4 mr8",
    staticStyle: {
      width: "8px",
      height: "8px",
      background: "var(--primary)"
    }
  }), _c("span", {
    staticClass: "fs16 fw500"
  }, [_vm._v(_vm._s(_vm.currentGroupTarget.rulesName) + "绑定的用工单位")])]), _c("div", {
    staticClass: "mt16"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "用工单位/所属销售",
      allowClear: ""
    },
    on: {
      change: _vm.onEmpSearchChange
    },
    model: {
      value: _vm.companyOrSaleName,
      callback: function callback($$v) {
        _vm.companyOrSaleName = $$v;
      },
      expression: "companyOrSaleName"
    }
  })], 1), _c("a-table", {
    staticClass: "mt16",
    attrs: {
      dataSource: _vm.empLst,
      pagination: _vm.pagination,
      columns: _vm.bindColumns,
      rowKey: "companyId",
      size: "middle"
    },
    on: {
      change: _vm.onEmpTableChange
    }
  })], 1) : _c("div", {
    staticClass: "p16 flex-center-center",
    staticStyle: {
      flex: "1",
      "border-left": "1px solid #e8e8e8",
      "min-height": "calc(100vh - 112px)"
    }
  }, [_c("a-empty")], 1), _c("a-modal", {
    attrs: {
      title: _vm.groupModalTitle,
      loading: _vm.confirmLoadingGroup
    },
    on: {
      ok: _vm.onGroupModalOk
    },
    model: {
      value: _vm.groupModalVisible,
      callback: function callback($$v) {
        _vm.groupModalVisible = $$v;
      },
      expression: "groupModalVisible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.groupForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "规则名称",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["rulesName", {
        rules: [{
          required: true,
          message: "必须填写名称"
        }]
      }],
      expression: "['rulesName', { rules: [{ required: true, message: '必须填写名称' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "业务类型",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["subjectType"],
      expression: "['subjectType']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "所属地区",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-cascader", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["region"],
      expression: "['region']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.common.areaList,
      changeOnSelect: "",
      placeholder: "请选择"
    },
    on: {
      change: _vm.onRegionChange
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "新增提成比例",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["newProportion", {
        rules: [{
          required: true,
          message: "必须填写新增提成比例"
        }, {
          type: "number",
          message: "必须填写数字",
          transform: function transform(value) {
            return Number(value);
          }
        }]
      }],
      expression: "[\n            'newProportion',\n            {\n              rules: [\n                { required: true, message: '必须填写新增提成比例' },\n                {\n                  type: 'number',\n                  message: '必须填写数字',\n                  transform: (value) => {\n                    return Number(value);\n                  }\n                }\n              ]\n            }\n          ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请输入",
      suffix: "%"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "续约提成比例",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["continueProportion", {
        rules: [{
          required: true,
          message: "必须填写续约提成比例"
        }, {
          type: "number",
          message: "必须填写数字",
          transform: function transform(value) {
            return Number(value);
          }
        }]
      }],
      expression: "[\n            'continueProportion',\n            {\n              rules: [\n                { required: true, message: '必须填写续约提成比例' },\n                {\n                  type: 'number',\n                  message: '必须填写数字',\n                  transform: (value) => {\n                    return Number(value);\n                  }\n                }\n              ]\n            }\n          ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请输入",
      suffix: "%"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "规则说明",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["remark"],
      expression: "['remark']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请输入"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-center fw500",
    staticStyle: {
      background: "#fafafa",
      "border-bottom": "1px solid #e8e8e8"
    }
  }, [_c("div", {
    staticClass: "ta-center p8",
    staticStyle: {
      width: "50%"
    }
  }, [_vm._v("规则项")]), _c("div", {
    staticClass: "ta-center p8",
    staticStyle: {
      width: "50%"
    }
  }, [_vm._v("内容")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };