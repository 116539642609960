import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import { formatDateAll } from "@/utils/index";
import { getSysNotices, deleteSysNotices } from "@/api/company/index";
import DraggableTableCell, { headerRow } from "@/components/DraggableTableCell";
import Sortable from "sortablejs";
var noticeColumns = [{
  title: "标题",
  dataIndex: "title",
  width: 260,
  onCell: function onCell() {
    return {
      style: {
        maxWidth: 260,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        cursor: "pointer"
      }
    };
  },
  scopedSlots: {
    customRender: "customTitle"
  }
}, {
  title: "状态",
  dataIndex: "status",
  width: 150,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "发布时间",
  dataIndex: "publishDate",
  width: 200,
  customRender: function customRender(text, record, index) {
    return formatDateAll(text);
  }
}, {
  title: "下线时间",
  dataIndex: "offlineDate",
  width: 200,
  customRender: function customRender(text, record, index) {
    return text != null ? formatDateAll(text) : "--";
  }
}, {
  title: "发布范围",
  dataIndex: "publishEmpRangeIsAll",
  width: 180,
  customRender: function customRender(text, record, index) {
    return text === true ? "全公司" : "部分员工";
  }
}, {
  title: "推送设置",
  dataIndex: "noticeType",
  width: 200,
  customRender: function customRender(text, record, index) {
    var content = [];
    if (text) {
      text.split(",").forEach(function (element) {
        if (element === "2") {
          content.push("短信 ");
        } else if (element === "1") {
          content.push("微信 ");
        } else if (element === "3") {
          content.push("邮件 ");
        }
      });
    } else {
      content.push("--");
    }
    return content;
  }
}, {
  title: "操作",
  dataIndex: "action",
  width: 180,
  scopedSlots: {
    customRender: "action"
  }
}];
export default {
  data: function data() {
    this.components = {
      header: {
        cell: DraggableTableCell(noticeColumns)
      }
    };
    return {
      showTable: true,
      statusVal: null,
      loading: true,
      noticeColumns: noticeColumns,
      searchForm: this.$form.createForm(this),
      selectedRowKeys: [],
      noticelist: [],
      deleteVisible: false,
      detailVisible: false,
      confirmLoading: false,
      companyId: "",
      currentNotice: "",
      title: null,
      status: [{
        id: 0,
        name: "待发布"
      }, {
        id: 1,
        name: "已发布"
      }, {
        id: 2,
        name: "已下线"
      }],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true
      }
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.getAllNoticelist();
  },
  mounted: function mounted() {
    this.initSortable();
  },
  methods: {
    headerRow: headerRow,
    initSortable: function initSortable() {
      new Sortable(document.getElementById("sortable_list"), {
        onEnd: this.onDragEnd
      });
    },
    onDragEnd: function onDragEnd(evt) {
      var _this = this;
      var newIndex = evt.newIndex;
      var oldIndex = evt.oldIndex;
      var columnsTemp = _toConsumableArray(this.noticeColumns);
      var columnsMap = {};
      var initArr = [];
      for (var i = 0; i < columnsTemp.length; i++) {
        columnsMap[i] = columnsTemp[i];
        initArr.push(i);
      }
      initArr.splice(oldIndex, 1);
      initArr.splice(newIndex, 0, oldIndex);
      var columnsTempNew = initArr.map(function (idx) {
        return columnsMap[idx];
      });
      this.noticeColumns = columnsTempNew;
      this.components = {
        header: {
          cell: DraggableTableCell(this.noticeColumns)
        }
      };
      this.showTable = false;
      this.$nextTick(function () {
        _this.showTable = true;
        _this.$nextTick(function () {
          _this.initSortable();
        });
      });
    },
    createNotice: function createNotice() {
      this.$router.push({
        path: "enterprise/createNotice",
        query: {
          sign: "0"
        }
      });
    },
    getAllNoticelist: function getAllNoticelist() {
      var _this2 = this;
      this.loading = true;
      getSysNotices({
        title: this.title,
        status: this.statusVal,
        companyId: this.companyId,
        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current
      }).then(function (res) {
        _this2.noticelist = res.data.entities;
        _this2.pagination.total = res.data.entityCount;
      }).finally(function () {
        _this2.loading = false;
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getAllNoticelist(this.currentTab);
    },
    searchContarct: function searchContarct(e) {
      var _this3 = this;
      this.title = e;
      if (e) {
        getSysNotices({
          companyId: this.companyId,
          status: this.statusVal,
          title: e
        }).then(function (res) {
          _this3.noticelist = res.data.entities;
          _this3.pagination.total = res.data.entityCount;
        });
      } else {
        this.getAllNoticelist();
      }
    },
    searchNoticeByStatus: function searchNoticeByStatus(e) {
      this.statusVal = e;
      this.pagination.current = 1;
      this.getAllNoticelist();
      // getSysNotices({ companyId: this.companyId, status: e }).then(res => {
      //   this.noticelist = res.data.entities;
      //   this.pagination.total = res.data.entityCount;
      // });
    },
    check: function check(record) {
      this.detailVisible = true;
      this.checkEleContract(record.id);
    },
    goDetail: function goDetail(record) {
      this.$router.push({
        path: "enterprise/createNotice",
        query: {
          sign: "1",
          id: record.id
        }
      });
    },
    deleteNotice: function deleteNotice(record) {
      this.currentNotice = record;
      this.deleteVisible = true;
    },
    confirmDelete: function confirmDelete() {
      var _this4 = this;
      var companySysNoticeIds = [];
      companySysNoticeIds.push(this.currentNotice.id);
      deleteSysNotices(companySysNoticeIds).then(function (res) {
        _this4.deleteVisible = false;
        _this4.$message.success("删除成功");
        _this4.getAllNoticelist();
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    }
  }
};