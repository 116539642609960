import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import { groupListReq, groupCreateReq, groupUpdateReq, groupDeleteReq } from "./api";
import { findFormGysReq } from "./api";
import { gysListReq, gysDeleteReq, gysExportReq } from "./api";
import { tableValueAreaDetail, tableValueArea } from "./funcs";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import { mapState } from "vuex";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      groupVisible: false,
      groupTitle: "",
      groupName: "",
      groupDescribe: "",
      groupId: "",
      groupNameQuery: "",
      groupList: null,
      groupParentId: undefined,
      groupParentLevel: "",
      currentGroup: null,
      selectedHead: [],
      selectedRowKeys: [],
      gysList: [],
      columns: null,
      searchKey: null,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      spinning: false,
      columnMap: null,
      qrcodeVisible: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["auth", "app"])), {}, {
    siteId: function siteId() {
      return this.auth.siteId;
    },
    dropdownDisabled: function dropdownDisabled() {
      return this.currentGroup && this.currentGroup.type === "site";
    },
    scrollHeight: function scrollHeight() {
      return this.app.mainHeight - 460;
    }
  }),
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.buildInitColumns();
  },
  methods: {
    formatColumnItem: function formatColumnItem(item, text) {
      if (item.fieldType === 9) {
        return tableValueAreaDetail(text);
      } else if (item.fieldType === 6) {
        return tableValueArea(text);
      } else {
        return text;
      }
    },
    exportExcel: function exportExcel() {
      var _this = this;
      gysExportReq({
        searchKey: this.searchKey,
        groupId: this.currentGroup.type === "site" ? null : this.currentGroup.value
      }).then(function (res) {
        if (res.data && res.data.type === "application/json") {
          var reader = new FileReader();
          reader.readAsText(res.data, "utf-8");
          reader.onload = function () {
            var resJson = JSON.parse(reader.result);
            _this.$message.error(resJson.msg);
          };
        } else {
          var blob = new Blob([res.data], {
            type: "application/octet-stream"
          });
          var link = document.createElement("a");
          link.download = "全部供应商.xlsx";
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(function (err) {
        console.log("error log --> ", err);
        _this.spinning = false;
      });
    },
    onHeadLoaded: function onHeadLoaded() {
      this.loadGroup();
    },
    genQrcode: function genQrcode() {
      var _this2 = this;
      this.qrcodeVisible = true;
      this.$nextTick(function () {
        new QRCode("qrbox", {
          width: 200,
          height: 200,
          // 高度
          text: "".concat(location.origin, "/back/#/gys_reg?mode=add&groupId=").concat(_this2.currentGroup.value, "&siteId=").concat(_this2.$store.state.auth.siteId) // 二维码内容
        });
      });
    },
    // 下载
    saveQrcode: function saveQrcode() {
      var _this3 = this;
      html2canvas(document.getElementById("qrbox"), {
        useCORS: true,
        allowTaint: false
      }).then(function (canvas) {
        var MIME_TYPE = "image/png";
        var imgURL = canvas.toDataURL("image/png");
        var link = document.createElement("a");
        link.download = "\u767B\u8BB0\u4E8C\u7EF4\u7801";
        link.href = imgURL;
        link.dataset.downloadurl = [MIME_TYPE, link.download, link.href].join(":");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        _this3.qrcodeVisible = false;
      }).catch(function (err) {
        console.log(err);
      });
    },
    deleteGys: function deleteGys(item) {
      var _this4 = this;
      gysDeleteReq(item.id).then(function (res) {
        _this4.$message.success("删除成功");
        _this4.loadList();
      }).catch(function (err) {
        console.log(err);
      });
    },
    goDetail: function goDetail(mode, gysId) {
      if (mode === "add") {
        sessionStorage.setItem("currentGroupForAdd", this.currentGroup.value);
      }
      this.$router.push({
        path: "/admin/supplier/detail",
        query: {
          mode: mode,
          groupId: this.currentGroup.value,
          gysId: gysId
        }
      });
    },
    buildInitColumns: function buildInitColumns() {
      var _this5 = this;
      findFormGysReq().then(function (res) {
        var arr1 = [];
        for (var i = 0; i < res.data.length; i++) {
          var li1 = res.data[i];
          for (var j = 0; j < li1.formGroupFieldList.length; j++) {
            var li2 = li1.formGroupFieldList[j];
            arr1.push(li2);
          }
        }
        var arr2 = [];
        var map = {};
        for (var _i = 0; _i < arr1.length; _i++) {
          var item = arr1[_i];
          var dataIndex = item.fieldCode || "" + item.id;
          var itemNew = {
            title: item.fieldName,
            dataIndex: dataIndex,
            fieldType: item.fieldType,
            ellipsis: true,
            scopedSlots: {
              customRender: "".concat(dataIndex)
            }
          };
          // arr2.push(itemNew);
          map["".concat(dataIndex)] = itemNew;
        }
        arr2.push({
          title: "操作",
          dataIndex: "action",
          width: "96px",
          align: "center",
          fixed: "right",
          scopedSlots: {
            customRender: "action"
          }
        });
        _this5.columnMap = map;
        _this5.columns = arr2;
      }).catch(function (err) {
        console.log(err);
      });
    },
    selectFunc: function selectFunc() {
      this.pagination.current = 1;
      this.selectedRowKeys = [];
      this.loadList();
    },
    handleTableChange: function handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.loadList();
    },
    onSelectChange: function onSelectChange(selectedRowKeys, tList) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowList = tList;
    },
    searchGys: function searchGys() {
      this.pagination.current = 1;
      this.loadList();
    },
    loadList: function loadList() {
      var _this6 = this;
      this.spinning = true;
      var params = {
        searchKey: this.searchKey,
        groupId: this.currentGroup.type === "site" ? null : this.currentGroup.value,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      gysListReq(params).then(function (res) {
        _this6.gysList = res.data.entities;
        _this6.pagination.total = res.data.entityCount;
        _this6.spinning = false;
      }).catch(function (err) {
        _this6.spinning = false;
        console.log(err);
      });
    },
    onGroupClick: function onGroupClick(item) {
      if (!this.currentGroup || item.value !== this.currentGroup.value) {
        this.currentGroup = item;
        this.pagination.current = 1;
        this.loadList();
      }
    },
    onGroupParentSelect: function onGroupParentSelect(value, node) {
      this.groupParentId = value;
      this.groupParentLevel = node.dataRef.level;
    },
    loadGroup: function loadGroup() {
      var _this7 = this;
      groupListReq({
        groupName: this.groupNameQuery
      }).then(function (res) {
        var currentGroupForAddId = sessionStorage.getItem("currentGroupForAdd");
        _this7.groupList = formatTreeData(res.data, currentGroupForAddId);
        if (!_this7.currentGroup) {
          try {
            var currentGroupSession = sessionStorage.getItem("currentGroup");
            if (currentGroupSession) {
              _this7.onGroupClick(JSON.parse(currentGroupSession));
              sessionStorage.removeItem("currentGroupForAdd");
              sessionStorage.removeItem("currentGroup");
            } else {
              _this7.onGroupClick(_this7.groupList[0]);
            }
          } catch (err) {
            _this7.onGroupClick(_this7.groupList[0]);
          }
        }
      });
    },
    onSearch: function onSearch() {
      this.loadGroup();
    },
    onGroupClose: function onGroupClose() {
      this.groupVisible = false;
    },
    showAddGroup: function showAddGroup() {
      this.groupVisible = true;
      this.groupTitle = "添加分组";
      this.groupName = undefined;
      this.groupDescribe = undefined;
      this.groupParentId = this.currentGroup.value;
      this.groupParentLevel = this.currentGroup.level;
    },
    showEditGroup: function showEditGroup(item) {
      this.groupVisible = true;
      this.groupTitle = "编辑分组";
      this.groupName = item.title;
      this.groupDescribe = item.desc;
      this.groupId = item.value;
      this.groupParentId = item.parentId;
      this.groupParentLevel = item.level - 1;
    },
    addGroupOk: function addGroupOk() {
      var _this8 = this;
      if (!this.groupName) {
        this.$message.warning("分组名称必填！");
      } else {
        if (this.groupTitle === "添加分组") {
          groupCreateReq({
            groupType: "ordinary",
            sort: 1,
            name: this.groupName,
            groupDesc: this.groupDescribe,
            parentId: this.groupParentId,
            level: this.groupParentLevel + 1
          }).then(function (res) {
            _this8.$notification["success"]({
              message: "success",
              description: "新增成功"
            });
            _this8.loadGroup();
            _this8.groupVisible = false;
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        } else if (this.groupTitle === "编辑分组") {
          groupUpdateReq({
            name: this.groupName,
            groupDesc: this.groupDescribe,
            groupType: "ordinary",
            sort: 1,
            parentId: this.groupParentId,
            level: this.groupParentLevel + 1,
            id: this.groupId
          }).then(function (res) {
            _this8.$notification["success"]({
              message: "success",
              description: "编辑成功"
            });
            _this8.loadGroup();
            _this8.groupVisible = false;
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        }
      }
    },
    deleteGroup: function deleteGroup() {
      var _this9 = this;
      groupDeleteReq(this.groupId).then(function (res) {
        _this9.$notification["success"]({
          message: "success",
          description: "删除成功"
        });
        _this9.loadGroup();
        _this9.groupVisible = false;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};
function formatTreeData(list, currentGroupForAddId) {
  var temp = [];
  list.forEach(function (item) {
    var itemTemp = {
      title: item.name,
      key: item.id,
      value: item.id,
      type: item.groupType,
      desc: item.groupDesc,
      level: item.level,
      parentId: item.parentId,
      count: item.gysCount,
      children: item.childList ? formatTreeData(item.childList, currentGroupForAddId) : [],
      scopedSlots: {
        title: "custom"
      }
    };
    if (currentGroupForAddId && currentGroupForAddId === item.id) {
      sessionStorage.setItem("currentGroup", JSON.stringify(itemTemp));
    }
    temp.push(itemTemp);
  });
  return temp;
}