import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.to-string.js";
import { getRoleList, updateRoleSetting, roleDetailById, saveRoleRight, checkRoleRights, getServiceOpens as _getServiceOpens } from "@/api/company/member";
var columns = [{
  title: "增值服务",
  dataIndex: "categoryName",
  key: "categoryName",
  width: 220,
  scopedSlots: {
    customRender: "categoryName"
  }
}, {
  title: "权限配置",
  dataIndex: "permissionList",
  key: "permissionList",
  scopedSlots: {
    customRender: "permissionList"
  }
}];
export default {
  data: function data() {
    return {
      columns: columns,
      actionId: this.$route.query.actionId,
      hrId: this.$route.query.hrId,
      replaceFields: {
        children: "childList",
        key: "categoryId",
        value: "categoryId",
        title: "categoryName"
      },
      loading: false,
      checkAllState: false,
      permission: {},
      checkedKeys: [],
      treeData: [],
      subjectType: "company",
      roleInfos: "",
      roleIntro: "",
      roleId: this.$route.query.id,
      roleName: "",
      saveArr: [],
      ServiceDataList: [],
      dto: [],
      roleType: "",
      type: [{
        value: "A",
        label: "管理员"
      }, {
        value: "U",
        label: "普通成员"
      }],
      roleOptions: [],
      permissionIds: []
    };
  },
  created: function created() {
    // 获取详情列表
    this.getRoleDetail(this.roleId);
    // 获取权限列表
    this.permissionList();
  },
  methods: {
    getServiceOpens: function getServiceOpens() {
      var _this = this;
      _getServiceOpens().then(function (res) {
        _this.ServiceDataList = res.data;
      });
    },
    onSelect: function onSelect(selectedKeys, info) {
      console.log("selected", selectedKeys, info);
    },
    onCheck: function onCheck(checkedKeys, info) {
      console.log("onCheck", checkedKeys, info);
    },
    showBox: function showBox(item) {
      if (["guanwangxinwen", "kaowubaoming"].includes(item.enName)) {
        if (window.location.host.includes("csxr.cn")) {
          return true;
        }
        return false;
      }
      return true;
    },
    checkItem: function checkItem(e) {
      var _this2 = this;
      var isAllGroup = 0;
      this.treeData.forEach(function (item) {
        if (item.checked === false) {
          _this2.checkAllState = false;
        }
        if (item.children) {
          var isAll = 0;
          item.children.forEach(function (item2) {
            if (item2.checked === true) {
              isAll = isAll + 1;
            }
            if (item2.checked === false) {
              item.checked = false;
              _this2.checkAllState = false;
            }
            if (isAll === item.children.length) {
              item.checked = true;
              isAllGroup = isAllGroup + 1;
            }
          });
        } else {
          isAllGroup = isAllGroup + 1;
        }
      });
      if (isAllGroup === this.treeData.length) {
        this.checkAllState = true;
      } else {
        this.checkAllState = false;
      }
      this.treeData = JSON.parse(JSON.stringify(this.treeData));
    },
    checkAll: function checkAll(e) {
      if (e.target.checked) {
        this.checkAllState = true;
        this.treeData.forEach(function (item) {
          item.checked = true;
          if (item.children) {
            item.children.forEach(function (item2) {
              item2.checked = true;
            });
          }
        });
      } else {
        this.checkAllState = false;
        this.treeData.forEach(function (item) {
          item.checked = false;
          if (item.children) {
            item.children.forEach(function (item2) {
              item2.checked = false;
            });
          }
        });
      }
      this.treeData = JSON.parse(JSON.stringify(this.treeData));
    },
    checkGroup: function checkGroup(e) {
      var _this3 = this;
      var isAll = 0;
      this.treeData.forEach(function (item, index) {
        if (item.checked === true) {
          isAll = isAll + 1;
        } else {
          _this3.checkAllState = false;
        }
      });
      if (isAll === this.treeData.length) {
        this.checkAllState = true;
      }
      if (e.checked) {
        if (e.children) {
          e.children.forEach(function (item) {
            item.checked = true;
          });
        }
      } else {
        this.checkAllState = false;
        if (e.children) {
          e.children.forEach(function (item) {
            item.checked = false;
          });
        }
      }
    },
    onSubjectChange: function onSubjectChange(key) {
      var _this4 = this;
      this.treeData = [];
      this.subjectType = key;
      this.columns[0].title = {
        addedServices: "增值服务",
        xcxAdmin: "小程序管理端权限",
        xcxJiafang: "小程序甲方端权限"
      }[key] || "";
      if (key === "addedServices") {
        _getServiceOpens().then(function (res) {
          _this4.ServiceDataList = res.data;
          _this4.ServiceDataList.forEach(function (item, index) {
            if (!item.openStatus) {
              _this4.ServiceDataList.splice(index, 1);
            }
          });
          var arr = [];
          if (_this4.ServiceDataList && _this4.ServiceDataList.length) {
            _this4.ServiceDataList.forEach(function (item, index) {
              item.childList = [];
              if (item.serviceIcon) {
                item.serviceIcon = item.serviceIcon.split(",");
              }
              if (item.openStatus) {
                _this4.loading = true;
                checkRoleRights({
                  roleId: _this4.subjectType === "company" ? _this4.actionId : _this4.hrId,
                  subjectType: item.serviceCode
                }).then(function (_ref) {
                  var data = _ref.data;
                  if (data && data.length) {
                    arr = arr.concat(data);
                  }
                  _this4.treeData = JSON.parse(JSON.stringify(arr));
                  _this4.buildData();
                  _this4.loading = false;
                }).catch(function (err) {
                  _this4.loading = false;
                  console.log("debug log --> ", err);
                  throw new Error("err", err);
                });
              }
            });
          }
        });
      } else {
        this.permissionList();
      }
    },
    buildData: function buildData() {
      var _this5 = this;
      this.ServiceDataList.forEach(function (item, index) {
        item.categoryName = item.serviceName;
        _this5.treeData.forEach(function (item2, index2) {
          if (item2.subjectType === item.serviceCode) {
            item.childList.push(item2);
          }
        });
      });
      this.treeData = JSON.parse(JSON.stringify(this.ServiceDataList));
      this.treeData.forEach(function (item) {
        if (!item.childList || !item.childList.length) {
          item.childList = undefined;
        } else {
          item.childList = _this5.unique(item.childList);
        }
      });
    },
    unique: function unique(arr) {
      var res = new Map();
      return arr.filter(function (arr) {
        return !res.has(arr.categoryId) && res.set(arr.categoryId, 1);
      });
    },
    tabChange: function tabChange(e) {
      this.getRoleDetail(this.roleId);
    },
    // 获取角色信息-------------
    selectInfo: function selectInfo(id) {
      var item = this.roleOptions.find(function (i) {
        return i.id === id;
      });
      this.roleName = item.name;
      this.roleIntro = item.description;
      this.roleType = item.valid;
      this.roleId = item.id;
      this.getRoleDetail(this.roleId);
    },
    // 更新角色配置------
    updateRole: function updateRole() {
      var _this6 = this;
      if (!this.roleName) {
        this.$notification["error"]({
          message: "Error",
          description: "角色名称不能为空"
        });
        return;
      }
      var updateRoleDTO = {
        description: this.roleIntro,
        name: this.roleName,
        id: this.roleId,
        type: "U"
      };
      updateRoleSetting(updateRoleDTO).then(function (res) {
        if (res.success) {
          _this6.getRoleDetail(_this6.roleId);
          _this6.$notification["success"]({
            message: "更新角色信息成功",
            description: res.msg
          });
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
        throw new Error(err);
      });
    },
    // 保存权限-----
    arrSave: function arrSave(data) {
      var _this7 = this;
      data.forEach(function (item, index) {
        if (item.permissionList) {
          _this7.saveArr = _this7.saveArr.concat(item.permissionList);
          item.permissionList.forEach(function (item2) {
            item2.subjectType = item.subjectType;
          });
        }
        if (item.childList && item.childList.length) {
          _this7.arrSave(item.childList);
        }
      });
    },
    savePermission: function savePermission() {
      var _this8 = this;
      if (this.subjectType === "addedServices") {
        this.saveArr = [];
        var treeDataCopy = [];
        this.treeData.forEach(function (item) {
          if (item.childList && item.childList.length) {
            treeDataCopy.push(item);
          }
        });
        this.arrSave(treeDataCopy);
        var dataArr = [];
        this.saveArr.map(function (mapItem) {
          if (dataArr.length === 0) {
            dataArr.push({
              subjectType: mapItem.subjectType,
              List: [mapItem]
            });
          } else {
            var res = dataArr.some(function (item) {
              // 判断相同屬性，有就添加到当前项
              if (item.subjectType === mapItem.subjectType) {
                item.List.push(mapItem);
                return true;
              }
            });
            if (!res) {
              // 如果没找相同屬性添加一个新对象
              dataArr.push({
                subjectType: mapItem.subjectType,
                List: [mapItem]
              });
            }
          }
        });
        this.dto = [];
        dataArr.forEach(function (item) {
          var arr = [];
          if (item.List && item.List.length) {
            item.List.forEach(function (item2) {
              if (item2.checked) {
                arr.push(item2.permissionId);
              }
            });
            _this8.dto.push({
              permissionIds: arr.toString(),
              subjectIds: "0",
              roleId: _this8.subjectType === "company" ? _this8.actionId : _this8.hrId,
              subjectType: item.subjectType
            });
          }
        });
      } else {
        this.treeData.forEach(function (item, index) {
          // if (item.checked === true) {
          //   this.checkedKeys.push(item.categoryId);
          // }
          if (["xcxAdmin", "xcxJiafang"].includes(_this8.subjectType)) {
            item.permissionList.forEach(function (item2, index2) {
              if (item2.checked === true) {
                _this8.checkedKeys.push(item2.permissionId);
              }
            });
          } else if (item.children) {
            item.children.forEach(function (item2, index2) {
              if (item2.checked === true) {
                _this8.checkedKeys.push(item2.permissionId);
              }
            });
          }
        });
        this.dto = [];
        this.dto.push({
          permissionIds: this.checkedKeys.toString(),
          subjectIds: "0",
          roleId: this.subjectType === "company" ? this.actionId : this.hrId,
          subjectType: this.subjectType
        });
      }
      saveRoleRight(this.dto).then(function (res) {
        if (res.success) {
          // 获取权限列表
          if (_this8.subjectType === "addedServices") {
            _this8.onSubjectChange("addedServices");
          } else {
            _this8.permissionList();
          }
          _this8.$notification["success"]({
            message: "权限配置成功",
            description: res.msg
          });
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
        throw new Error(err);
      });
    },
    // 获取权限列表
    permissionList: function permissionList(e) {
      var _this9 = this;
      this.loading = true;
      this.checkedKeys = [];
      checkRoleRights({
        roleId: this.subjectType === "company" ? this.actionId : this.hrId,
        subjectType: e || this.subjectType
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this9.treeData = _this9.dataDeep(data);
        var isAllGroup = 0;
        _this9.treeData.forEach(function (item) {
          if (item.checked === false) {
            _this9.checkAllState = false;
          }
          if (item.children) {
            var isAll = 0;
            item.children.forEach(function (item2) {
              if (item2.checked === true) {
                isAll = isAll + 1;
              }
              if (item2.checked === false) {
                item.checked = false;
                _this9.checkAllState = false;
              }
              if (isAll === item.children.length) {
                item.checked = true;
                isAllGroup = isAllGroup + 1;
              }
            });
          } else {
            isAllGroup = isAllGroup + 1;
          }
        });
        if (isAllGroup === _this9.treeData.length) {
          _this9.checkAllState = true;
        } else {
          _this9.checkAllState = false;
        }
        _this9.treeData = JSON.parse(JSON.stringify(_this9.treeData));
        _this9.loading = false;
      }).catch(function (err) {
        _this9.loading = false;
        console.log("debug log --> ", err);
        throw new Error("err", err);
      });
    },
    // 递归处理
    dataDeep: function dataDeep(data) {
      data.map(function (item) {
        item.title = item.categoryName;
        item.key = item.categoryId;
        item.children = item.permissionList;
        if (item.children && item.children.length > 0) {
          item.children.forEach(function (i) {
            i.title = i.name;
            i.key = i.permissionId;
          });
        }
      });
      return data;
    },
    // 切换角色权限列表----
    selectChange: function selectChange(e) {
      var _this10 = this;
      this.roleOptions.forEach(function (item, index) {
        if (item.id === e) {
          if (item.roleVOList) {
            item.roleVOList.forEach(function (item2, index2) {
              if (item2.subjectType === "company") {
                _this10.actionId = item2.id;
              }
              if (item2.subjectType === "hr") {
                _this10.hrId = item2.id;
              }
            });
          }
        }
      });
      this.permissionList();
    },
    // 根据角色Id获取角色信息
    getRoleDetail: function getRoleDetail(id) {
      var _this11 = this;
      roleDetailById(id).then(function (res) {
        _this11.roleInfos = res.data;
        _this11.roleName = _this11.roleInfos.name;
        _this11.roleIntro = _this11.roleInfos.description;
        _this11.roleId = _this11.roleInfos.id;
        _this11.roleType = _this11.roleInfos.type;
        _this11.getRolesList();
      }).catch(function (err) {
        console.log("debug log --> ", err);
        throw new Error(err);
      });
    },
    // 获取角色列表
    getRolesList: function getRolesList() {
      var _this12 = this;
      getRoleList({
        subjectType: this.subjectType,
        roleId: this.roleId
      }).then(function (res) {
        _this12.roleOptions = res.data || [];
      }).catch(function (err) {
        console.log("debug log --> ", err);
        throw new Error(err);
      });
    },
    cancel: function cancel() {
      this.$router.go(-1);
    }
  }
};