import { render, staticRenderFns } from "./selectScope.vue?vue&type=template&id=05b66aaf&scoped=true&"
import script from "./selectScope.vue?vue&type=script&lang=js&"
export * from "./selectScope.vue?vue&type=script&lang=js&"
import style0 from "./selectScope.vue?vue&type=style&index=0&id=05b66aaf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b66aaf",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\tools\\frontend_workspace\\e-hr-master\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05b66aaf')) {
      api.createRecord('05b66aaf', component.options)
    } else {
      api.reload('05b66aaf', component.options)
    }
    module.hot.accept("./selectScope.vue?vue&type=template&id=05b66aaf&scoped=true&", function () {
      api.rerender('05b66aaf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/paySalary/applyFor/add/selectPeople/selectScope.vue"
export default component.exports