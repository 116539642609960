import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import { selectTimeInfoByProgId } from "@/api/shebao/shebaoInfo.js";
import { isContainByString } from "@/utils/index";
import { formatDate, afterTime } from "@/utils/index";
import { changeShebaoMethod, changeShebaoMethodBucha, findSocProgRelation, toWeiShuquire as _toWeiShuquire, getPCount, getShebaoMethod } from "@/api/shebao/shebaoMethod.js";
var columns = [{
  dataIndex: "itemName",
  key: "itemName",
  slots: {
    title: "customTitle"
  },
  scopedSlots: {
    customRender: "itemName"
  }
}, {
  title: "原•个人缴纳基数",
  dataIndex: "cardinalityDefaultAmount",
  key: "cardinalityDefaultAmount",
  scopedSlots: {
    customRender: "cardinalityDefaultAmount"
  }
}, {
  title: "新•个人缴纳基数",
  dataIndex: "cardinalityDefaultAmountNew",
  key: "cardinalityDefaultAmountNew",
  scopedSlots: {
    customRender: "cardinalityDefaultAmountNew"
  }
}, {
  title: "原•单位金额",
  dataIndex: "amountCompany",
  key: "amountCompany",
  scopedSlots: {
    customRender: "amountCompany"
  }
}, {
  title: "新•单位金额",
  dataIndex: "amountCompanyNew",
  key: "amountCompanyNew",
  scopedSlots: {
    customRender: "amountCompanyNew"
  }
}, {
  title: "原•个人金额",
  dataIndex: "amountPersonal",
  key: "amountPersonal",
  scopedSlots: {
    customRender: "amountPersonal"
  }
}, {
  title: "新•个人金额",
  dataIndex: "amountPersonalNew",
  key: "amountPersonalNew",
  scopedSlots: {
    customRender: "amountPersonalNew"
  }
}];
var recordSample = {
  amountCompany: 0,
  amountCompanyNew: 0,
  amountPersonal: 0,
  amountPersonalNew: 0,
  cardinalityDefaultAmount: 0,
  cardinalityDefaultAmountNew: 0,
  itemName: "养老保险",
  itemType: 1,
  sort: 0,
  defaultType: 101,
  persentCompany: 0,
  persentPersonal: 0,
  cardinalityDefault: 0,
  cardinalityMax: 99999,
  cardinalityMin: 0
};
var initShebao = [{
  amountCompany: 0,
  amountCompanyNew: 0,
  amountPersonal: 0,
  amountPersonalNew: 0,
  cardinalityDefaultAmount: 0,
  cardinalityDefaultAmountNew: 0,
  itemName: "养老保险",
  itemType: 1,
  sort: 0,
  defaultType: 101,
  persentCompany: 0,
  persentPersonal: 0,
  cardinalityDefault: 0,
  cardinalityMax: 99999,
  cardinalityMin: 0
}, {
  amountCompany: 0,
  amountCompanyNew: 0,
  amountPersonal: 0,
  amountPersonalNew: 0,
  cardinalityDefaultAmount: 0,
  cardinalityDefaultAmountNew: 0,
  itemName: "医疗保险",
  itemType: 1,
  sort: 1,
  defaultType: 102,
  persentCompany: 0,
  persentPersonal: 0,
  cardinalityDefault: 0,
  cardinalityMax: 99999,
  cardinalityMin: 0
}, {
  amountCompany: 0,
  amountCompanyNew: 0,
  amountPersonal: 0,
  amountPersonalNew: 0,
  cardinalityDefaultAmount: 0,
  cardinalityDefaultAmountNew: 0,
  itemName: "失业保险",
  itemType: 1,
  sort: 2,
  defaultType: 103,
  persentCompany: 0,
  persentPersonal: 0,
  cardinalityDefault: 0,
  cardinalityMax: 99999,
  cardinalityMin: 0
}, {
  amountCompany: 0,
  amountCompanyNew: 0,
  amountPersonal: 0,
  amountPersonalNew: 0,
  cardinalityDefaultAmount: 0,
  cardinalityDefaultAmountNew: 0,
  itemName: "工伤保险",
  itemType: 1,
  sort: 3,
  defaultType: 104,
  persentCompany: 0,
  persentPersonal: 0,
  cardinalityDefault: 0,
  cardinalityMax: 99999,
  cardinalityMin: 0
}, {
  amountCompany: 0,
  amountCompanyNew: 0,
  amountPersonal: 0,
  amountPersonalNew: 0,
  cardinalityDefaultAmount: 0,
  cardinalityDefaultAmountNew: 0,
  itemName: "生育保险",
  itemType: 1,
  sort: 4,
  defaultType: 105,
  persentCompany: 0,
  persentPersonal: 0,
  cardinalityDefault: 0,
  cardinalityMax: 99999,
  cardinalityMin: 0
}];
var initJijin = [{
  amountCompany: 0,
  amountCompanyNew: 0,
  amountPersonal: 0,
  amountPersonalNew: 0,
  cardinalityDefaultAmount: 0,
  cardinalityDefaultAmountNew: 0,
  itemName: "公积金",
  itemType: 2,
  sort: 0,
  defaultType: 106,
  persentCompany: 0,
  persentPersonal: 0,
  cardinalityDefault: 0,
  cardinalityMax: 99999,
  cardinalityMin: 0
}];
export default {
  name: "OnlyAmountTable",
  props: {
    progId: {
      type: String,
      default: ""
    },
    progName: {
      type: String,
      default: ""
    },
    deductionsDate: {
      type: Number,
      default: 1
    },
    socCity: {
      type: String,
      default: ""
    },
    socCityName: {
      type: String,
      default: ""
    },
    endDt: {
      type: Number,
      default: 0
    },
    startDt: {
      type: Number,
      default: 0
    }
  },
  watch: {
    endDt: function endDt(val) {},
    startDt: function startDt(val) {}
  },
  data: function data() {
    return {
      isContainByString: isContainByString,
      groupList: [{
        id: 1,
        name: "四舍五入到厘"
      }, {
        id: 2,
        name: "四舍五入到分"
      }, {
        id: 3,
        name: "四舍五入到角"
      }, {
        id: 4,
        name: "四舍五入到元"
      }, {
        id: 5,
        name: "四舍五入到小数点后四位"
      }, {
        id: 6,
        name: "见厘进分"
      }, {
        id: 7,
        name: "见分进角"
      }, {
        id: 8,
        name: "见角进元"
      }, {
        id: 9,
        name: "见厘舍去"
      }, {
        id: 10,
        name: "见角舍去"
      }, {
        id: 11,
        name: "见分舍去"
      }],
      editVisible: false,
      // 社保
      groupItemDefault: 3,
      GgroupItemDefault: 3,
      // 公积金
      groupItemDefault2: 3,
      GgroupItemDefault2: 3,
      isSheBao: true,
      form: this.$form.createForm(this, {
        name: "editMethod"
      }),
      columns: columns,
      onlySocAmount: false,
      jijinShow: false,
      itemShow: false,
      itemShow2: false,
      itemChecked: [],
      // checkbox当前值的数组
      originChecked: [],
      // checkbox变化前选中的值的数组
      jijinChecked: false,
      jijinChecked2: false,
      recordSample: recordSample,
      empCount: "",
      isEnter: false,
      ChangeInfo: 1,
      updateItem: false,
      isTimeShow: false,
      timeList: [],
      time: [],
      jijin: [],
      shebao: [],
      socProgItemDTOList: [],
      bushoubujiaook: false,
      proId: "",
      removeZengVisible: false,
      monthre: "",
      yearre: "",
      typeForm: this.$form.createForm(this)
    };
  },
  created: function created() {
    this.toWeiShuquire();
    if (this.progId === "") {
      this.init(false);
      return;
    }
    this.toInquire();
  },
  methods: {
    getSheBao: function getSheBao(index) {
      if (index === 1) {
        var tempShebao = [];
        for (var i in this.shebao) {
          // 遍历数组
          if (this.shebao[i].itemType === 1) {
            tempShebao.push(this.shebao[i]);
          }
        }
        return tempShebao;
      } else {
        var tempYibao = [];
        for (var j in this.shebao) {
          // 遍历数组
          if (this.shebao[j].itemType === 3) {
            tempYibao.push(this.shebao[j]);
          }
        }
        return tempYibao;
      }
    },
    canbaoguanli: function canbaoguanli() {
      this.bushoubujiaook = false;
      this.$router.push({
        path: "/shebao/shebaoManage/shebaoDetail",
        query: {
          id: this.proId,
          month: this.monthre,
          year: this.yearre
        }
      });
    },
    BushouBuJiaoOkHandle: function BushouBuJiaoOkHandle() {
      this.bushoubujiaook = false;
      this.$router.go(-1);
    },
    getInfo: function getInfo() {
      var s = formatDate(this.startDt).split("-");
      var e = formatDate(this.endDt).split("-");
      return s[0] + "年" + s[1] + "月-" + e[0] + "年" + e[1] + "月间参加'" + this.progName + "方案'的" + this.pCount + "名人员将进行统一调差";
    },
    weiShuHandleChange: function weiShuHandleChange(value) {
      if (this.isSheBao) {
        this.groupItemDefault = value;
      } else {
        this.groupItemDefault2 = value;
      }
    },
    GweiShuHandleChange: function GweiShuHandleChange(value) {
      if (this.isSheBao) {
        this.GgroupItemDefault = value;
      } else {
        this.GgroupItemDefault2 = value;
      }
    },
    confirmAddType: function confirmAddType(e) {
      this.editVisible = false;
    },
    handleDialCancel: function handleDialCancel() {
      this.editVisible = false;
    },
    comZong: function comZong() {
      this.editVisible = true;
      this.isSheBao = true;
    },
    comGJZong: function comGJZong() {
      this.editVisible = true;
      this.isSheBao = false;
    },
    getPfPersonAll2: function getPfPersonAll2() {
      var g = 0;
      if (this.getPfCompanyAll() + "".length >= this.getPfPersonAll() + "".length) {
        g = this.groupItemDefault2;
      } else {
        g = this.GgroupItemDefault2;
      }
      return this.calculationDot(g, NP.strip(parseFloat(this.getPfCompanyAll()) + parseFloat(this.getPfPersonAll())));
    },
    getPfCompanyAll2: function getPfCompanyAll2() {
      var g = 0;
      if (this.getCompanyAll() + "".length >= this.getPersonAll() + "".length) {
        g = this.groupItemDefault;
      } else {
        g = this.GgroupItemDefault;
      }
      return this.calculationDot(g, NP.strip(parseFloat(this.getCompanyAll()) + parseFloat(this.getPersonAll())));
    },
    // 初始化公积金数据
    jijinShowChange: function jijinShowChange() {
      for (var i = this.jijin.length; i >= 0; i--) {
        this.jijin.splice(i, 1);
      }
      var record = {};
      for (var k in this.recordSample) {
        record[k] = recordSample[k];
      }
      record.defaultType = 106;
      record.itemName = "公积金";
      record.itemType = 2;
      this.jijin.push(record);
      this.jijinChecked = true;
      this.jijinChecked2 = false;
    },
    // 用于处理公积金checkbox变化后的一些逻辑
    handleJijinCheckbox: function handleJijinCheckbox() {
      var _this = this;
      var record = {};
      for (var k in this.recordSample) {
        record[k] = recordSample[k];
      }
      record.defaultType = 106;
      record.itemName = "公积金";
      record.itemType = 2;
      if (this.jijinChecked) {
        this.jijin.push(record);
        this.jijinChecked = true;
      } else {
        this.jijin.forEach(function (record, index) {
          if (record.defaultType === 106) {
            _this.deleteShebaoItem(record);
          }
        });
      }
      this.resort(this.jijin);
    },
    handleJijinCheckbox2: function handleJijinCheckbox2() {
      var _this2 = this;
      var record = {};
      for (var k in this.recordSample) {
        record[k] = recordSample[k];
      }
      record.defaultType = 107;
      record.itemName = "补充公积金";
      record.itemType = 2;
      if (this.jijinChecked2) {
        this.jijin.push(record);
        this.jijinChecked2 = true;
      } else {
        this.jijin.forEach(function (record, index) {
          if (record.defaultType === 107) {
            _this2.deleteShebaoItem(record);
          }
        });
      }
      this.resort(this.jijin);
    },
    // 查询尾数处理
    toWeiShuquire: function toWeiShuquire() {
      var _this3 = this;
      this.groupList = [];
      _toWeiShuquire().then(function (res) {
        if (res.data !== null) {
          for (var key in res.data) {
            var h = {
              id: res.data[key],
              name: key
            };
            _this3.groupList.push(h);
            if (h.id === 0) {
              _this3.groupItemDefault = h.id;
              _this3.GgroupItemDefault = h.id;
              _this3.groupItemDefault2 = h.id;
              _this3.GgroupItemDefault2 = h.id;
            }
          }
        }
      });
    },
    // 给自定义项目设定序号
    setType: function setType(itemType) {
      if (itemType === 1 || itemType === 3) {
        var max = 300;
        this.shebao.forEach(function (item, index) {
          if (item.defaultType > max) max = item.defaultType;
        });
        return max + 1;
      }
      if (itemType === 2) {
        var _max = 400;
        this.jijin.forEach(function (item, index) {
          if (item.defaultType > _max) _max = item.defaultType;
        });
        return _max + 1;
      }
    },
    addOtherItem: function addOtherItem(itemType) {
      var record = {};
      for (var k in this.recordSample) {
        record[k] = recordSample[k];
      }
      record.itemName = "";
      record.itemType = itemType;
      if (itemType === 1 || itemType === 3) {
        record.defaultType = this.setType(1);
        this.shebao.push(record);
        this.resort(this.shebao);
      }
      if (itemType === 2) {
        record.defaultType = this.setType(2);
        this.jijin.push(record);
        this.resort(this.jijin);
      }
    },
    // 选出两个数组中不同的元素，返回一个数组（因业务不需要，未考虑单个数组中有重复元素的情况）
    getArrDifference: function getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
    },
    // 用于处理社保checkbox变化后的一些逻辑
    handleCheckbox: function handleCheckbox(values) {
      if (values.length < this.originChecked.length) this.reduceItem(values);else this.addItem(values);
    },
    // checkbox选中添加项目
    addItem: function addItem(values) {
      var _this4 = this;
      var arr = this.getArrDifference(this.originChecked, values);
      var type = parseInt(arr[0]);
      this.originChecked = [];
      this.itemChecked.forEach(function (item, index) {
        _this4.originChecked[index] = item;
      });
      var record = {};
      for (var k in this.recordSample) {
        record[k] = recordSample[k];
      }
      record.defaultType = type;
      if (type === 101) record.itemName = "养老保险";
      if (type === 102) record.itemName = "医疗保险";
      if (type === 103) record.itemName = "失业保险";
      if (type === 104) record.itemName = "工伤保险";
      if (type === 105) record.itemName = "生育保险";
      if (type === 106) record.itemName = "企业(职业)年金";
      if (type === 107) record.itemName = "商业健康保险";
      if (type === 108) record.itemName = "税延养老保险";
      if (type === 109) record.itemName = "补充养老保险";
      if (type === 110) record.itemName = "补充(大病医疗)保险";
      if (type === 111) record.itemName = "残保金";
      this.shebao.push(record);
      this.resort(this.shebao);
    },
    // checkbox取消选中减少项目
    reduceItem: function reduceItem(values) {
      var _this5 = this;
      var arr = this.getArrDifference(this.originChecked, values);
      this.originChecked = [];
      this.itemChecked.forEach(function (item, index) {
        _this5.originChecked[index] = item;
      });
      this.shebao.forEach(function (record, index) {
        if (record.defaultType === parseInt(arr[0])) {
          _this5.deleteShebaoItem(record);
        }
      });
    },
    handleItemShow: function handleItemShow() {
      this.itemShow = !this.itemShow;
      if (this.itemShow) {
        this.$emit("showMenu");
      }
    },
    handleItemShow2: function handleItemShow2() {
      this.itemShow2 = !this.itemShow2;
      if (this.itemShow2) {
        this.$emit("showMenu");
      }
    },
    hideItemShow: function hideItemShow() {
      this.itemShow = false;
      this.itemShow2 = false;
    },
    // 删除社保或公积金项目
    deleteShebaoItem: function deleteShebaoItem(record) {
      var _this6 = this;
      var sort = record.sort,
        defaultType = record.defaultType,
        itemType = record.itemType;
      if (itemType === 1 || itemType === 3) {
        this.itemChecked.forEach(function (item, index) {
          if (parseInt(item) === defaultType) _this6.itemChecked.splice(index, 1);
        });
        this.originChecked.forEach(function (item, index) {
          if (parseInt(item) === defaultType) {
            _this6.originChecked.splice(index, 1);
          }
        });
        this.shebao.splice(sort, 1);
        this.resort(this.shebao);
      }
      if (itemType === 2) {
        this.jijin.splice(sort, 1);
        if (defaultType === 106) this.jijinChecked = false;
        if (defaultType === 107) this.jijinChecked2 = false;
        this.resort(this.jijin);
        if (this.jijin.length === 0) {
          this.jijinShow = true;
        }
      }
    },
    // 删除项目或添加项目以后重新排序，sort值代表一个项目在数组中的位置
    resort: function resort(arr) {
      if (arr != null) {
        arr.map(function (record, index) {
          arr[index].sort = index;
        });
      }
    },
    handleName: function handleName(e, record) {
      var value = e.target.value;
      var sort = record.sort,
        itemType = record.itemType;
      itemType === 1 || itemType === 3 ? this.shebao[sort].itemName = value : this.jijin[sort].itemName = value;
    },
    // reg: 用于判断输入数据是否为非负数的数字的正则表达式
    handleDefault: function handleDefault(value, record) {
      var sort = record.sort,
        itemType = record.itemType;
      var reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
      if (reg.test(value)) {
        if (itemType === 1 || itemType === 3) {
          if (value < this.shebao[sort].cardinalityDefaultAmount) {
            value = this.shebao[sort].cardinalityDefaultAmount;
          }
          this.shebao[sort].cardinalityDefaultAmountNew = value;
        } else {
          if (value < this.jijin[sort].cardinalityDefaultAmount) {
            value = this.jijin[sort].cardinalityDefaultAmount;
          }
          this.jijin[sort].cardinalityDefaultAmountNew = value;
        }
      }
    },
    handleCompanyAmount: function handleCompanyAmount(value, record) {
      var sort = record.sort,
        itemType = record.itemType;
      var reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
      if (reg.test(value)) {
        if (itemType === 1 || itemType === 3) {
          if (value < this.shebao[sort].amountCompany) {
            value = this.shebao[sort].amountCompany;
          }
          this.shebao[sort].amountCompanyNew = value;
        } else {
          if (value < this.jijin[sort].amountCompany) {
            value = this.jijin[sort].amountCompany;
          }
          this.jijin[sort].amountCompanyNew = value;
        }
      }
    },
    handlePersonalAmount: function handlePersonalAmount(value, record) {
      var sort = record.sort,
        itemType = record.itemType;
      var reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
      if (reg.test(value)) {
        if (itemType === 1 || itemType === 3) {
          if (value < this.shebao[sort].amountPersonal) {
            value = this.shebao[sort].amountPersonal;
          }
          this.shebao[sort].amountPersonalNew = value;
        } else {
          if (value < this.jijin[sort].amountPersonal) {
            value = this.jijin[sort].amountPersonal;
          }
          this.jijin[sort].amountPersonalNew = value;
        }
      }
    },
    // 计算公司和个人全部社保金额
    getCompanyAll: function getCompanyAll() {
      var _this7 = this;
      var total = 0;
      if (this.shebao != null) {
        this.shebao.map(function (item) {
          total += parseFloat(_this7.calculationDot(_this7.groupItemDefault, item.amountCompanyNew));
        });
      }
      return this.calculationDot(this.groupItemDefault, NP.strip(total));
    },
    getPersonAll: function getPersonAll() {
      var _this8 = this;
      var total = 0;
      if (this.shebao != null) {
        this.shebao.map(function (item) {
          if (item.itemType === 1 || item.itemType === 3) {
            total += parseFloat(_this8.calculationDot(_this8.GgroupItemDefault, item.amountPersonalNew));
          }
        });
      }
      return this.calculationDot(this.GgroupItemDefault, NP.strip(total));
    },
    // 计算公司和个人全部公积金金额
    getPfCompanyAll: function getPfCompanyAll() {
      var _this9 = this;
      var total = 0;
      if (this.jijin != null) {
        this.jijin.map(function (item) {
          if (item.itemType === 2) {
            total += parseFloat(_this9.calculationDot(_this9.groupItemDefault2, item.amountCompanyNew));
          }
        });
      }
      return this.calculationDot(this.groupItemDefault2, NP.strip(total));
    },
    getPfPersonAll: function getPfPersonAll() {
      var _this10 = this;
      var total = 0;
      if (this.jijin != null) {
        this.jijin.map(function (item) {
          total += parseFloat(_this10.calculationDot(_this10.GgroupItemDefault2, item.amountPersonalNew));
        });
      }
      return this.calculationDot(this.GgroupItemDefault2, NP.strip(total));
    },
    // 计算小数点
    calculationDot: function calculationDot(type, value) {
      switch (type) {
        case 0:
          // 不处理
          var s = value.toString().split(".");
          if (s.length === 1) {
            return s + ".00";
          } else {
            if (s[1].length === 1) {
              s[1] = s[1] + "0";
            }
            return s[0] + "." + s[1];
          }
        case 1:
          // 四舍五入到元
          return this.siWuDot(value, 0);
        case 2:
          // 四舍五入到角
          return this.siWuDot(value, 1);
        case 3:
          // 四舍五入到分
          return this.siWuDot(value, 2);
        case 4:
          // 四舍五入到厘
          return this.siWuDot(value, 3);
        case 5:
          // 四舍五入到小数点后4位
          return this.siWuDot(value, 4);
        case 6:
          // 见厘进分
          return this.jieQueDot(value, 2);
        case 7:
          // 见分进角
          return this.jieQueDot(value, 1);
        case 8:
          // 见角进元
          return this.jieQueDot(value, 0);
        case 9:
          // 见角舍去
          return this.sheQuDot(value, 0);
        case 10:
          // 见分舍去
          return this.sheQuDot(value, 1);
        case 11:
          // 见厘舍去
          return this.sheQuDot(value, 2);
      }
    },
    // 四舍五入
    siWuDot: function siWuDot(value, n) {
      var f = Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
      var s = f.toString();
      var rs = s.indexOf(".");
      if (n !== 0 && rs < 0) {
        s += ".";
      }
      for (var i = s.length - s.indexOf("."); i <= n; i++) {
        s += "0";
      }
      if (n === 0) {
        s += ".00";
      }
      return s;
    },
    // 进1截取
    jieQueDot: function jieQueDot(value, t) {
      var f = value.toString();
      var s = f.split(".");
      if (s.length === 1) {
        return s + ".00";
      } else {
        if (t !== 0) {
          if (s[1].length > t) {
            var n = Number(s[1].substring(0, t));
            var n2 = n + 1;
            s[1] = n2.toString();
          } else if (s[1].length < t) {
            var c = t - s[1].length;
            for (var i = 0; i < c; i++) {
              s[1] += "0";
            }
          }
          return s[0] + "." + s[1];
        } else {
          var _n = Number(s[0]) + 1;
          return _n + ".00";
        }
      }
    },
    // 直接舍去
    sheQuDot: function sheQuDot(value, n) {
      var f = value.toString();
      var s = f.split(".");
      if (s.length === 1) {
        return s + ".00";
      } else {
        if (n !== 0) {
          if (s[1].length > n) {
            s[1] = s[1].substring(0, n);
          } else if (s[1].length < n) {
            var c = n - s[1].length;
            for (var i = 0; i < c; i++) {
              s[1] += "0";
            }
          }
          return s[0] + "." + s[1];
        } else {
          return s[0] + ".00";
        }
      }
    },
    // 取消修改
    unSaveMethod: function unSaveMethod() {
      this.$router.go(-1);
    },
    // 保存修改
    saveMethod: function saveMethod() {
      var _this11 = this;
      if (this.startDt === 0 || this.endDt === 0) {
        this.$message.error("请检查补差开始和结束时间是否填写！");
        return;
      }

      // 请求预览
      var data = {
        socProgId: this.progId,
        companyId: this.$store.state.hr.companyId
      };
      getPCount(data).then(function (res) {
        _this11.removeZengVisible = true;
        _this11.pCount = res.data.empCount;
      });
    },
    // 判断填写内容是否完全
    judgeError: function judgeError() {
      var flag = true;
      if (this.shebao != null) {
        this.shebao.forEach(function (item, index) {
          if (item.itemName === "") flag = false;
        });
      }
      if (this.jijin != null) {
        this.jijin.forEach(function (item, index) {
          if (item.itemName === "") flag = false;
        });
      }
      if (this.progName === "") flag = false;
      if (this.socCity === "" || this.socCityName === "") flag = false;
      if (this.deductionsDate === "" || this.deductionsDate === "") {
        flag = false;
      }
      return flag;
    },
    // 有员工使用此参保方案后的弹出框点击确定后的处理
    handleOk: function handleOk() {
      var _this12 = this;
      this.isEnter = false;
      var data = {
        updateItem: this.updateItem,
        time: this.time,
        id: this.progId,
        socCity: this.socCity,
        socCityName: this.socCityName,
        progName: this.progName,
        companyId: this.isContainByString("contractAdmin") ? "0" : this.$store.state.hr.companyId,
        deductionsDate: this.deductionsDate,
        socProgItemDTOList: this.shebao.concat(this.jijin),
        onlySocAmount: true,
        payProvidentFund: this.jijinShow,
        serviceCost: this.servercost
      };
      if (this.updateItem && this.time && this.time.length === 0) {
        this.$notification["error"]({
          message: "请选择月份"
        });
        return;
      }
      changeShebaoMethod(data).then(function (res) {
        _this12.$message.success("保存成功！");
        _this12.$router.go(-1);
      });
    },
    // 查询参保方案试用月份
    checkProMonth: function checkProMonth() {
      var _this13 = this;
      var data = {
        progId: this.progId
      };
      selectTimeInfoByProgId(data).then(function (res) {
        _this13.timeList = [];
        if (res.data != null) {
          res.data.map(function (item) {
            _this13.timeList.push(item.year + "-" + item.month);
          });
        }
      });
    },
    remvoeZenyuanOkHandel: function remvoeZenyuanOkHandel() {
      var _this14 = this;
      this.removeZengVisible = false;
      var flag = this.judgeError();
      if (this.startDt === 0 || this.endDt === 0) {
        this.$message.error("请检查补差开始和结束时间是否填写！");
        return;
      }
      this.checkProMonth();
      // if (this.empCount > 0) {
      //   this.isEnter = true;
      // } else if (this.empCount === 0) {
      this.isEnter = false;
      var goon = true;
      if (this.shebao != null) {
        this.shebao.map(function (item) {
          item.mantissaProcCompany = _this14.groupItemDefault;
          item.mantissaProcPersonal = _this14.GgroupItemDefault;
          if ((item.itemName === "养老保险" || item.itemName === "医疗保险" || item.itemName === "失业保险") && Number(item.cardinalityDefaultAmountNew) === 0) {
            _this14.$message.error(item.itemName + " 默认个人缴纳基数不允许是0！");
            goon = false;
            return;
          }
        });
      }
      if (this.jijin != null) {
        if (!this.jijinShow) {
          this.jijin.map(function (item) {
            item.mantissaProcCompany = _this14.groupItemDefault2;
            item.mantissaProcPersonal = _this14.GgroupItemDefault2;
            if (item.itemName === "公积金" && Number(item.cardinalityDefaultAmountNew) === 0) {
              _this14.$message.error(item.itemName + " 默认个人缴纳基数不允许是0！");
              goon = false;
              return;
            }
          });
        }
      }
      if (goon) {
        this.shebao.map(function (item) {
          item.cardinalityDefaultAmount = Number(item.cardinalityDefaultAmountNew);
          item.amountCompany = Number(item.amountCompanyNew);
          item.amountPersonal = Number(item.amountPersonalNew);
        });
        this.jijin.map(function (item) {
          item.cardinalityDefaultAmount = Number(item.cardinalityDefaultAmountNew);
          item.amountCompany = Number(item.amountCompanyNew);
          item.amountPersonal = Number(item.amountPersonalNew);
        });
        var merge = this.shebao.concat(this.jijin);
        for (var i = 0; i < merge.length; i++) {
          merge[i].cardinalityDefault = merge[i].cardinalityDefaultAmount;
        }
        var data = {
          fillDifferenceStartDate: this.startDt,
          fillDifferenceEndDate: this.endDt,
          updateItem: this.updateItem,
          time: this.time,
          socProgId: this.progId,
          socCity: this.socCity,
          socCityName: this.socCityName,
          progName: this.progName,
          companyId: this.isContainByString("contractAdmin") ? "0" : this.$store.state.hr.companyId,
          deductionsDate: this.deductionsDate,
          fillDifferenceItem: merge,
          onlySocAmount: this.onlySocAmount,
          payProvidentFund: this.jijinShow
        };
        changeShebaoMethodBucha(data).then(function (res) {
          _this14.bushoubujiaook = true;
          var v = res.data.split("/");
          _this14.proId = v[0];
          _this14.monthre = v[2];
          _this14.yearre = v[1];
        });
      }
      // }
    },
    handleCancel: function handleCancel() {
      this.removeZengVisible = false;
      this.bushoubujiaook = false;
    },
    // 查询方案详情
    toInquire: function toInquire() {
      var _this15 = this;
      findSocProgRelation({
        progId: this.progId,
        companyId: this.isContainByString("contractAdmin") ? "0" : this.$store.state.hr.companyId
      }).then(function (res) {
        _this15.onlySocAmount = res.data.onlySocAmount;
        _this15.init(_this15.onlySocAmount, res);
      });
    },
    // 深拷贝，解决引用赋值
    deepCopy: function deepCopy(arr) {
      var newArr = [];
      arr.forEach(function (item, index) {
        var record = {};
        for (var k in item) {
          record[k] = item[k];
        }
        newArr.push(record);
      });
      return newArr;
    },
    // 根据onlySocAmount初始化组件
    init: function init(flag, res) {
      var _this16 = this;
      if (flag) {
        this.jijinShow = res.data.payProvidentFund;
        this.empCount = res.data.empCount;
        if (this.empCount === null) {
          this.empCount = 0;
        }
        if (res.data.socProgItemVOSocList != null) {
          res.data.socProgItemVOSocList.map(function (item) {
            item.cardinalityDefaultAmountNew = Number(item.cardinalityDefaultAmount);
            item.amountCompanyNew = Number(item.amountCompany);
            item.amountPersonalNew = Number(item.amountPersonal);
          });
        }
        this.shebao = res.data.socProgItemVOSocList;
        if (this.shebao === null) {
          this.shebao = [];
        }
        if (this.shebao != null && this.shebao.length !== 0) {
          if (this.shebao[0].mantissaProcCompany !== undefined) {
            this.groupItemDefault = this.shebao[0].mantissaProcCompany;
          }
          if (this.shebao[0].mantissaProcPersonal !== undefined) {
            this.GgroupItemDefault = this.shebao[0].mantissaProcPersonal;
          }
        }
        if (res.data.socProgItemVOPfList != null) {
          res.data.socProgItemVOPfList.map(function (item) {
            item.cardinalityDefaultAmountNew = Number(item.cardinalityDefaultAmount);
            item.amountCompanyNew = Number(item.amountCompany);
            item.amountPersonalNew = Number(item.amountPersonal);
          });
        }
        this.jijin = res.data.socProgItemVOPfList;
        if (this.jijin === null) {
          this.jijin = [];
        }
        if (this.jijin != null && this.jijin.length !== 0) {
          // 公积金
          if (this.jijin[0].mantissaProcCompany !== undefined) {
            this.groupItemDefault2 = this.jijin[0].mantissaProcCompany;
          }
          if (this.jijin[0].mantissaProcPersonal !== undefined) {
            this.GgroupItemDefault2 = this.jijin[0].mantissaProcPersonal;
          }
        }
      } else {
        this.jijinShow = false;
        this.empCount = 0;
        this.shebao = this.deepCopy(initShebao);
        this.jijin = this.deepCopy(initJijin);
      }
      if (this.shebao != null) {
        this.shebao.forEach(function (item, index) {
          if (item.defaultType === 101) _this16.itemChecked.push("101");
          if (item.defaultType === 102) _this16.itemChecked.push("102");
          if (item.defaultType === 103) _this16.itemChecked.push("103");
          if (item.defaultType === 104) _this16.itemChecked.push("104");
          if (item.defaultType === 105) _this16.itemChecked.push("105");
        });
      }
      this.itemChecked.forEach(function (item, index) {
        _this16.originChecked[index] = item;
      });
      if (this.jijin != null) {
        this.jijin.forEach(function (item, index) {
          if (item.defaultType === 106) _this16.jijinChecked = true;
          if (item.defaultType === 107) _this16.jijinChecked2 = true;
        });
      }
      this.resort(this.jijin); // 旧方案全部删除后这条代码也删除
    },
    // 参保时间选择
    handleTime: function handleTime(e) {
      this.time = e;
    },
    // 是否更新参保人员
    handleChangeInfo: function handleChangeInfo(e) {
      if (e.target.value === 1) {
        this.updateItem = false;
        this.isTimeShow = false;
      } else {
        this.updateItem = true;
        this.isTimeShow = true;
      }
    }
  }
};