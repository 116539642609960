var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "new-main-wrapper"
  }, [_c("div", {
    attrs: {
      id: "app-main"
    }
  }, [_c("div", {
    staticClass: "main-content-wrapper without-tab"
  }, [_c("div", {
    staticClass: "main-content"
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("router-view", {
    key: _vm.key
  })], 1)], 1)])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };