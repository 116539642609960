var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 批量导入 ")]), _c("div", {
    staticClass: "uplaodBoxs"
  }, [_c("a-spin", {
    staticClass: "spin-content",
    attrs: {
      tip: "上传中。。。",
      spinning: _vm.uploading
    }
  }, [_c("div", {
    staticClass: "ant-upload-select-text-box",
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-upload", {
    ref: "upload",
    attrs: {
      "file-list": _vm.fileList,
      remove: _vm.handleRemove,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("img", {
    staticStyle: {
      width: "139px",
      height: "106px"
    },
    attrs: {
      src: _vm.uploadImg
    }
  }), _c("div", {
    staticClass: "ant-modal-body"
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#666"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#6A66F6"
    }
  }, [_vm._v("将文件拖到此处")]), _vm._v("或点击上传上传考勤表")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#999"
    }
  }, [_vm._v(" (文件大小不超过5M) ")]), _c("a-button", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      icon: "plus",
      type: "primary"
    }
  }, [_vm._v(" 添加文件 ")])], 1)])], 1)]), _c("div", {
    staticClass: "tipBox"
  }, [_c("a-row", {
    staticStyle: {
      color: "#252525",
      "font-size": "16px",
      "font-weight": "500",
      "padding-bottom": "10px"
    }
  }, [_vm._v(" 温馨提示 ")]), _c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", [_vm._v("1、上传的预登记人员表，必须包含以下信息： 人员姓名、身份证号；")]), _c("div", [_vm._v(" 2、如上传遇到问题，请使用模版上传；"), _c("span", {
    staticStyle: {
      "font-size": "12px",
      color: "#6A66F6",
      cursor: "pointer"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v(" 预登记人员上传模板 ")])]), _c("div", [_vm._v("3、暂不支持复合表头（即合并单元格）；")])])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };