import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import { saleRebatesRecordsReq, calculationRebatesReq, tileCompanyByUserReq } from "../api";
import { calcColumns, calcErrColumns } from "../funcs";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      term: "",
      spinning: false,
      calcVisible: false,
      errVisible: false,
      calcColumns: calcColumns,
      calcErrColumns: calcErrColumns,
      recordLst: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      pagination2: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      companyOrSaleName: "",
      rulesName: "",
      dateRange: null,
      calcDateRange: null,
      companyIdList: [],
      allCount: 0,
      errorCount: 0,
      successCount: 0,
      errorLst: [],
      companyLst: []
    };
  },
  computed: {
    errorLstFilter: function errorLstFilter() {
      var _this = this;
      if (this.term) {
        return this.errorLst.filter(function (item) {
          return item.companyName.includes(_this.term);
        });
      } else {
        return this.errorLst;
      }
    }
  },
  watch: {
    errorLstFilter: function errorLstFilter(newVal) {
      this.pagination2.total = newVal.length;
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;
    this.onSearchChange();
    tileCompanyByUserReq().then(function (res) {
      _this2.companyLst = res.data.map(function (item) {
        return {
          label: item.name,
          value: item.id
        };
      });
    }).catch(function (err) {
      console.log("tileCompanyByUserReq", err);
    });
  },
  methods: {
    onErrOk: function onErrOk() {
      this.errVisible = false;
      this.onSearchChange();
    },
    onTableChange2: function onTableChange2(pagination) {
      this.pagination2.current = pagination.current;
      this.pagination2.pageSize = pagination.pageSize;
    },
    onRulesNameChange: function onRulesNameChange() {
      this.pagination.current = 1;
      this.onSearchChange();
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    calcOk: function calcOk() {
      var _this3 = this;
      var params = {
        companyIdList: this.companyIdList
      };
      if (this.calcDateRange && this.calcDateRange.length > 0) {
        var dates = this.calcDateRange.map(function (item) {
          return item.format("YYYY-MM");
        });
        params["beginTime"] = dates[0];
        params["endTime"] = dates[1];
      }
      this.spinning = true;
      calculationRebatesReq(params).then(function (res) {
        _this3.calcVisible = false;
        _this3.allCount = res.data.allCount;
        _this3.errorCount = res.data.errorCount;
        _this3.successCount = res.data.successCount;
        _this3.errorLst = res.data.errorInfoList;
        _this3.spinning = false;
        if (_this3.errorLst && _this3.errorLst.length > 0) {
          _this3.term = "";
          _this3.errVisible = true;
        } else {
          _this3.$message.success("计算完成");
          _this3.onSearchChange();
        }
      }).catch(function (err) {
        _this3.spinning = false;
        console.log("calculationRebatesReq", err);
      });
    },
    calc: function calc() {
      this.companyIdList = [];
      this.calcDateRange = null;
      this.calcVisible = true;
    },
    onCalcPanelChange: function onCalcPanelChange(value) {
      this.calcDateRange = value;
    },
    onPanelChange: function onPanelChange(value) {
      this.dateRange = value;
      this.pagination.current = 1;
      this.onSearchChange();
    },
    onTableChange: function onTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.onSearchChange();
    },
    onSearchChange: function onSearchChange() {
      var _this4 = this;
      var params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      if (this.companyOrSaleName) {
        params["companyOrSaleName"] = this.companyOrSaleName;
      }
      if (this.rulesName) {
        params["rulesName"] = this.rulesName;
      }
      if (this.dateRange && this.dateRange.length > 0) {
        var dates = this.dateRange.map(function (item) {
          return item.format("YYYY-MM");
        });
        params["beginTime"] = dates[0];
        params["endTime"] = dates[1];
      }
      saleRebatesRecordsReq(params).then(function (res) {
        _this4.recordLst = res.data.entities;
        _this4.pagination.total = res.data.entityCount;
      }).catch(function (err) {
        console.log("saleRebatesRecordsReq", err);
      });
    }
  }
};