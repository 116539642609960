import request from "@/utils/request";
export function getFindPostByQuery(params) {
  return request({
    url: "/recruit2/api/v1/recruitInfos/findPostByQuery",
    method: "GET",
    params: params
  });
}
export function postSaveBrokerOrEmpPost(data) {
  return request({
    url: "/recruit2/api/v1/recruitInfos/saveBrokerOrEmpPost",
    method: "post",
    data: data
  });
}
export function putRecruitInfos(data) {
  return request({
    url: "/recruit2/api/v1/recruitInfos",
    method: "put",
    data: data
  });
}
export function getNormalPost(params) {
  return request({
    url: "/recruit2/api/v1/recruitInfos/getNormalPost",
    method: "GET",
    params: params
  });
}
export function getCompanies(params) {
  return request({
    url: "/hr/api/v1/companies/listAllNoSynchronization",
    method: "GET",
    params: params
  });
}