import request from "@/utils/request";
// 查询社保名单列表
export function listData(params) {
  return request({
    url: "/hr/api/v1/empSocTransform/selectEmpSocList",
    method: "get",
    params: params
  });
}
// 查询社保名单顶部数量统计
export function selectEmpSocListCount(params) {
  return request({
    url: "/hr/api/v1/empSocTransform/selectEmpSocListCount",
    method: "GET",
    params: params
  });
}

// 社保名单详情顶部金额统计

export function selectEmpSocInfoByEmpIdCount(params) {
  return request({
    url: "/hr/api/v1/empSocTransform/selectEmpSocInfoByEmpIdCount",
    method: "GET",
    params: params
  });
}

// 获取所有社保名单查询参数枚举
export function Enum(companyId) {
  return request({
    url: "/hr/api/v1/empSocTransform/getEmpSocListQueryEnum",
    method: "GET",
    params: {
      companyId: companyId
    }
  });
}

// 社保名单详情显示
export function EmpSocInfoByEmpId(data) {
  return request({
    url: "/hr/api/v1/empSocTransform/selectEmpSocInfoByEmpId",
    method: "POST",
    data: data
  });
}

// 查询社保名单详情左侧信息
export function selectEmpSocBriefly(params) {
  return request({
    url: "/hr/api/v1/empSocTransform/selectEmpSocBriefly",
    method: "GET",
    params: params
  });
}
// 初始化
export function initStatus(companyId) {
  return request({
    url: "hr/api/v1/empSocTransform/initStatus/".concat(companyId),
    method: "post"
  });
}
// 社保名单查询所有表头
export function selectAllHead(companyId) {
  return request({
    url: "/hr/api/v1/empSocTransform/selectAllHead/".concat(companyId),
    method: "GET"
  });
}

// 批量正常参保
export function batchNormalSoc(data) {
  return request({
    url: "/hr/api/v1/empSocTransform/batchNormalSoc",
    method: "POST",
    data: data
  });
}

// 社保名单导出
export function exports(data) {
  return request({
    url: "/hr/api/v1/empSocTransform/export",
    method: "getters",
    data: data
  });
}

// 社保名单导出
export function getCity(params) {
  return request({
    url: "/hr/api/v1/socWebsites/listAllNotSitId",
    method: "get",
    params: params
  });
}

// 社保名单导出
export function getCityAcc(params) {
  return request({
    url: "hr/api/v1/socCache",
    method: "get",
    params: params
  });
}

// 同步社保
export function syncData(data) {
  return request({
    url: "/hr/api/v1/syncSocWebSite/batchSync",
    method: "post",
    data: data
  });
}