import "core-js/modules/es.number.constructor.js";
var dataList = [{
  img: require("@/assets/img/statistic/shebao.png"),
  name: "社保总计（元）",
  num: "",
  compareNum: ""
}, {
  img: require("@/assets/img/statistic/gongjijin.png"),
  name: "公积金总计（元）",
  num: "",
  compareNum: ""
}, {
  img: require("@/assets/img/statistic/canbaoren.png"),
  name: "参保人次（人）",
  num: "",
  compareNum: ""
}, {
  img: require("@/assets/img/statistic/up.png"),
  name: "增员（人）",
  num: "",
  compareNum: ""
}, {
  img: require("@/assets/img/statistic/down.png"),
  name: "减员（人）",
  num: "",
  compareNum: ""
}];
import { selectAmount } from "@/api/shebao/statistic";
export default {
  props: {
    dateBox: {
      type: Object,
      default: function _default() {
        return {
          dataOfNum: []
        };
      }
    },
    queryType: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      upImg: require("@/assets/img/statistic/rise.png"),
      downImg: require("@/assets/img/statistic/down2.png"),
      companyId: this.$store.state.hr.companyId,
      background: require("@/assets/img/statistic/yuan.png"),
      dataList: dataList
    };
  },
  watch: {
    dateBox: {
      handler: function handler(val, oldVal) {
        this.getSelectAmount();
      },
      deep: true
    },
    queryType: {
      handler: function handler(val, oldVal) {
        this.getSelectAmount();
      },
      deep: true
    }
  },
  methods: {
    getSelectAmount: function getSelectAmount() {
      var _this = this;
      selectAmount({
        companyId: this.companyId,
        startMonth: this.dateBox.startMonth,
        endMonth: this.dateBox.endMonth,
        startYear: this.dateBox.startYear,
        endYear: this.dateBox.endYear,
        queryType: this.queryType,
        step: this.dateBox.step,
        isAcrossYear: this.dateBox.startYear !== this.dateBox.endYear
      }).then(function (res) {
        _this.dataOfNum = res.data;
        if (_this.queryType === 1) {
          _this.dataList[0].num = res.data.socAmount ? res.data.socAmount : "--";
          _this.dataList[0].compareNum = res.data.beforeSocAmount ? res.data.beforeSocAmount : "--";
          _this.dataList[1].compareNum = res.data.beforePfAmount ? res.data.beforePfAmount : "--";
          _this.dataList[1].num = res.data.pfAmount ? res.data.pfAmount : "--";
        }
        if (_this.queryType === 2) {
          _this.dataList[0].num = res.data.companySocAmount ? res.data.companySocAmount : "--";
          _this.dataList[0].compareNum = res.data.companySocAmount ? res.data.companySocAmount : "--";
          _this.dataList[1].compareNum = res.data.beforeCompanyPfAmount ? res.data.beforeCompanyPfAmount : "--";
          _this.dataList[1].num = res.data.companyPfAmount ? res.data.companyPfAmount : "--";
        }
        if (_this.queryType === 3) {
          _this.dataList[0].num = res.data.personalSocAmount ? res.data.personalSocAmount : "--";
          _this.dataList[0].compareNum = res.data.beforePersonalSocAmount ? res.data.beforePersonalSocAmount : "--";
          _this.dataList[1].compareNum = res.data.beforePersonalPfAmount ? res.data.beforePersonalPfAmount : "--";
          _this.dataList[1].num = res.data.personalPfAmount ? res.data.personalPfAmount : "--";
        }
        _this.dataList[2].compareNum = res.data.beforeEmpSocInfoCount ? res.data.beforeEmpSocInfoCount : "--";
        _this.dataList[3].compareNum = res.data.beforeIncrement ? res.data.beforeIncrement : "--";
        _this.dataList[4].compareNum = res.data.beforeRevoke ? res.data.beforeRevoke : "--";
        _this.dataList[2].num = res.data.empSocInfoCount ? res.data.empSocInfoCount : "--";
        _this.dataList[3].num = res.data.increment ? res.data.increment : "--";
        _this.dataList[4].num = res.data.revoke ? res.data.revoke : "--";
      });
    },
    cardClass: function cardClass(index) {
      if (index === 0) return "staff-top-item-one staff-top-item";else if (index === 1) {
        return "staff-top-item-two staff-top-item";
      } else if (index === 2) {
        return "staff-top-item-three staff-top-item";
      } else if (index === 3) {
        return "staff-top-item-four staff-top-secondItem";
      } else if (index === 4) {
        return "staff-top-item-five staff-top-thirdItem";
      }
    }
  }
};