import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "body"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 下发合同模板 ")]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(" 选择用工单位 ")]), _c("div", {
    staticStyle: {
      padding: "24px 0 40px 0"
    }
  }, [_c("a-button", [_vm._v("选择单位")]), _c("span", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#767885",
      "margin-left": "24px"
    }
  }, [_vm._v("已选择等0家")])], 1), _c("div", {
    staticClass: "title"
  }, [_vm._v(" 选择合同模板 ")]), _vm._l(_vm.contractData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticStyle: {
        "font-size": "14px",
        "font-weight": "400",
        color: "#767885",
        "margin-top": "24px"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("div", {
      staticStyle: {
        display: "flex",
        "flex-wrap": "wrap"
      }
    }, _vm._l(item.categoryListVOList, function (item, index) {
      return _c("div", {
        key: index,
        class: item.selected ? "templateSelected" : "template",
        staticStyle: {
          position: "relative"
        },
        on: {
          click: function click($event) {
            return _vm.selectedItem(item);
          }
        }
      }, [item.selected ? _c("div", {
        staticClass: "selectedIcon"
      }, [_c("a-icon", {
        staticStyle: {
          position: "absolute",
          top: "-34px",
          left: "3px",
          color: "#fff"
        },
        attrs: {
          type: "check"
        }
      })], 1) : _vm._e(), _c("div", {
        staticStyle: {
          position: "absolute",
          top: "46px",
          left: "66px"
        }
      }, [_c("img", {
        staticStyle: {
          width: "60px",
          height: "60px"
        },
        attrs: {
          src: _vm.templateIcon,
          alt: ""
        }
      })])]);
    }), 0)]);
  }), _c("div", {
    staticClass: "bottom"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleOk
    }
  }, [_vm._v("确认")])], 1)], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };