import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { formatDate } from "./funcs";
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    dataSource: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapGetters(["permissions_dycl"])),
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    detailItem: function detailItem() {
      this.$router.push("/daiyuchuli/".concat(this.dataSource.id, "/edit"));
    },
    deleteItem: function deleteItem() {
      this.$emit("deleteItem", this.dataSource);
    },
    formatDate: formatDate
  }
};