import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    currentGroup: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      payoutContent: "",
      paymentType: "月保",
      schemeDetail: "",
      schemeId: null,
      schemeName: "",
      schemeNameValue: "",
      modalTitle: "添加保险方案",
      modalVisible: false,
      schemeItems: [{
        careerType: "1-3类人员",
        monthAmount: 0
      }, {
        careerType: "4类人员",
        monthAmount: 0
      }, {
        careerType: "5类人员",
        monthAmount: 0
      }],
      planLst: [],
      formatError: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["permissions_shangbao"])), {}, {
    hasAdd: function hasAdd() {
      return this.permissions_shangbao.includes("insuranceAddService:insuranceAddService.shangbaofangan:addScheme");
    },
    hasUpdate: function hasUpdate() {
      return this.permissions_shangbao.includes("insuranceAddService:insuranceAddService.shangbaofangan:updateScheme");
    },
    hasDelete: function hasDelete() {
      return this.permissions_shangbao.includes("insuranceAddService:insuranceAddService.shangbaofangan:deleteScheme");
    }
  }),
  watch: {
    currentGroup: function currentGroup(newVal) {
      this.retrieveRecord();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.retrieveRecord();
  },
  methods: {
    delPlanItem: function delPlanItem(item) {
      var _this = this;
      this.$confirm({
        title: "删除确认",
        content: "方案删除后无法恢复，确认要删除吗？",
        onOk: function onOk() {
          _this.$request({
            url: "/insurance/api/v1/qyCompanySchemes/".concat(item.id),
            method: "delete"
          }).then(function (res) {
            _this.$message.success("删除成功");
            _this.retrieveRecord();
          }).catch(function (err) {
            console.log("qyCompanySchemes", err);
          });
        }
      });
    },
    editPlanItem: function editPlanItem(item) {
      this.schemeId = item.id;
      this.schemeNameValue = item.schemeName;
      this.schemeDetail = item.schemeDetail;
      this.paymentType = item.paymentType;
      this.payoutContent = item.payoutContent;
      this.schemeItems = item.itemList.map(function (li) {
        return {
          careerType: li.careerType,
          monthAmount: li.monthAmount
        };
      });
      this.modalTitle = "编辑保险方案";
      this.modalVisible = true;
    },
    getValues: function getValues() {
      var _this2 = this;
      if (this.formatError) {
        this.$message.warning("格式错误");
        return false;
      }
      if (!this.schemeNameValue) {
        this.$message.warning("有内容未填写");
        return false;
      }
      for (var i = 0; i < this.schemeItems.length; i++) {
        var item = this.schemeItems[i];
        if (!item.careerType || !item.monthAmount && item.monthAmount !== 0) {
          this.$message.warning("有内容未填写");
          return false;
        }
      }
      if (this.modalTitle === "添加保险方案") {
        this.$request({
          url: "/insurance/api/v1/qyCompanySchemes",
          method: "post",
          data: {
            insCompanyId: this.currentGroup,
            schemeName: this.schemeNameValue,
            schemeDetail: this.schemeDetail,
            paymentType: this.paymentType,
            payoutContent: this.payoutContent,
            schemeItemList: this.schemeItems
          }
        }).then(function (res) {
          _this2.$message.success("添加成功");
          _this2.retrieveRecord();
          _this2.modalVisible = false;
        }).catch(function (err) {
          console.log("qyCompanySchemes", err);
        });
      } else {
        this.$request({
          url: "/insurance/api/v1/qyCompanySchemes",
          method: "put",
          data: {
            id: this.schemeId,
            insCompanyId: this.currentGroup,
            schemeName: this.schemeNameValue,
            schemeDetail: this.schemeDetail,
            paymentType: this.paymentType,
            payoutContent: this.payoutContent,
            schemeItemList: this.schemeItems
          }
        }).then(function (res) {
          _this2.$message.success("更新成功");
          _this2.retrieveRecord();
          _this2.modalVisible = false;
        }).catch(function (err) {
          console.log("qyCompanySchemes", err);
        });
      }
    },
    deleteItem: function deleteItem(idx) {
      var temp = _toConsumableArray(this.schemeItems);
      temp.splice(idx, 1);
      this.schemeItems = temp;
    },
    addItem: function addItem() {
      this.schemeItems = [].concat(_toConsumableArray(this.schemeItems), [{
        careerType: "",
        monthAmount: ""
      }]);
    },
    onMonthAmountChange: function onMonthAmountChange(e, idx) {
      var val = e.target.value;
      if (/^[0-9]+(.[0-9]+)?$/.test(val)) {
        var temp = _toConsumableArray(this.schemeItems);
        temp[idx].monthAmount = val;
        this.schemeItems = temp;
        this.formatError = false;
      } else {
        this.$message.warning("请输入正确格式的数字");
        this.formatError = true;
      }
    },
    onCareerTypeChange: function onCareerTypeChange(e, idx) {
      var val = e.target.value;
      var temp = _toConsumableArray(this.schemeItems);
      temp[idx].careerType = val;
      this.schemeItems = temp;
    },
    onInputChange: function onInputChange() {
      this.retrieveRecord();
    },
    showModal: function showModal() {
      this.schemeNameValue = "";
      this.schemeItems = [{
        careerType: "1-3类人员",
        monthAmount: 0
      }, {
        careerType: "4类人员",
        monthAmount: 0
      }, {
        careerType: "5类人员",
        monthAmount: 0
      }];
      this.modalTitle = "添加保险方案";
      this.modalVisible = true;
    },
    retrieveRecord: function retrieveRecord() {
      var _this3 = this;
      var params = {};
      if (this.schemeName) {
        params["schemeName"] = this.schemeName;
      }
      if (this.currentGroup) {
        params["insCompanyId"] = this.currentGroup;
      }
      this.$request({
        url: "/insurance/api/v1/qyCompanySchemes/selectScheme",
        params: params
      }).then(function (res) {
        _this3.planLst = res.data;
      }).catch(function (err) {
        console.log("qyCompanySchemes", err);
      });
    }
  }
};