import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.test.js";
import { getNecessaryFields, postNecessaryFieldBatchDTO, postAdditionalFieldsAddDTO, deleteAdditionalFieldsQuery, putAdditionalFieldsUpdateDTO, putAdditionalFieldsOpen, putAdditionalFieldsNecessary } from "@/api/staff/entry";
import Vue from "vue";
import { FormModel } from "ant-design-vue";
Vue.use(FormModel);
Vue.directive("selection", {
  inserted: function inserted(el, binding) {}
});
export default {
  data: function data() {
    return {
      code: "",
      currentItem: {},
      fieldType: ["选项", "文本", "文件", "图片"],
      status: 0,
      allData: {},
      allNewData: {},
      show: 0,
      formItemLayout: {
        labelCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 4
          }
        },
        wrapperCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 20
          }
        }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 20,
            offset: 4
          }
        }
      },
      dynamicValidateForm: {
        domains: []
      },
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      },
      form: {},
      visible1: false,
      visible2: false,
      visible3: false,
      editvisible: false,
      activeKey: ["1", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16"],
      companyId: "",
      text: 1,
      res: {},
      result: {}
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.getData();
  },
  methods: {
    confirmDelete: function confirmDelete(id) {
      var _this = this;
      this.$confirm({
        title: "删除确认",
        content: "将删除全部用工单位的花名册及模板中的本字段",
        onOk: function onOk() {
          deleteAdditionalFieldsQuery(id).then(function () {
            _this.getData();
          }).catch(function (err) {
            console.log(err);
          });
        }
      });
    },
    remoteControl: function remoteControl(item, tag) {
      var _this2 = this;
      if (item.isNecessary && tag === "isNecessary") {
        item.isOpen = true;
      } else if (!item.isOpen && tag === "isOpen") {
        item.isNecessary = false;
      }
      postNecessaryFieldBatchDTO(item).then(function (res) {}).catch(function (err) {
        console.log("debug log --> ", err);
        _this2.getData();
      });
    },
    edit: function edit(item, tempCode) {
      var _this3 = this;
      this.currentItem = item;
      this.status = 0;
      this.editvisible = true;
      this.code = tempCode;
      this.$confirm({
        title: "修改确认",
        content: "字段的修改将同步到所有用工单位的花名册及模板",
        onOk: function onOk() {
          putAdditionalFieldsUpdateDTO(item).then(function (res) {
            _this3.form.fieldType = item.fieldType;
            _this3.form.fieldName = item.fieldName;
            _this3.form.isNecessary = item.isNecessary;
            _this3.form.isOpen = item.isOpen;
            _this3.form.description = item.description;
            _this3.editvisible = false;
            _this3.getData();
          }).catch(function (err) {
            console.log(err);
          });
        }
      });
    },
    remoteControlAdd: function remoteControlAdd(item, tag) {
      if (item.isNecessary && tag === "isNecessary") {
        item.isOpen = true;
      } else if (!item.isOpen && tag === "isOpen") {
        item.isNecessary = false;
      }
      var req = tag === "isOpen" ? putAdditionalFieldsOpen : putAdditionalFieldsNecessary;
      req(_objectSpread(_objectSpread({}, item), {}, {
        companyAdditionalFieldsId: item.id
      })).catch(function (err) {
        console.log(err);
      });
    },
    handleChange: function handleChange(value) {
      if (value === "1") {
        this.show = 0;
      }
      if (value === "3") {
        this.show = 1;
      }
      if (value === "4") {
        this.show = 2;
      }
      if (value === "2") {
        this.show = 3;
      }
    },
    submitForm: function submitForm(formName) {
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      // this.$refs[formName || "ruleForm"].resetFields();
    },
    removeDomain: function removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain: function addDomain() {
      this.dynamicValidateForm.domains.push({
        value: "",
        key: Date.now()
      });
    },
    showModal: function showModal(tag) {
      this.form = {};
      this[tag] = true;
      this.status = 1;
    },
    handleEditOk: function handleEditOk(code) {
      var _this4 = this;
      this.editvisible = false;
      var str = "";
      this.dynamicValidateForm.domains.forEach(function (item, index) {
        str += item.value;
        if (index !== _this4.dynamicValidateForm.domains.length - 1) {
          str += ",";
        }
      });
      var data = {
        ID: this.currentItem.id,
        companyId: this.companyId,
        fieldType: 3,
        fieldName: this.form.fieldName,
        moduleCode: code,
        options: str,
        description: this.form.description
      };
      if (data.fieldName === "undefined") {
        this.$notification["error"]({
          message: "error",
          description: "字段名称不能为空，修改失败"
        });
        return;
      }
      if (data.fieldName === false) {
        this.$notification["error"]({
          message: "error",
          description: "字段名称不能为空，修改失败"
        });
        return;
      }
      if (this.isNull(data.fieldName) === true) {
        this.$notification["error"]({
          message: "error",
          description: "字段名称不能为空，修改失败"
        });
        return;
      }
      this.$confirm({
        title: "修改确认",
        content: "字段的修改将同步到所有用工单位的花名册及模板",
        onOk: function onOk() {
          putAdditionalFieldsUpdateDTO(data).then(function (res) {
            _this4.$notification["success"]({
              message: "success",
              description: "修改成功"
            });
            _this4.getData();
          }).catch(function (err) {
            console.log(err);
          });
        },
        onCancel: function onCancel() {
          _this4.getData();
        }
      });
    },
    handleAddOk: function handleAddOk(item, code) {
      var _this5 = this;
      this.visible1 = false;
      this.visible2 = false;
      this.visible3 = false;
      var str = "";
      this.dynamicValidateForm.domains.forEach(function (item, index) {
        str += item.value;
        if (index !== _this5.dynamicValidateForm.domains.length - 1) {
          str += ",";
        }
      });
      var data = {
        id: this.form.id,
        companyId: this.companyId,
        fieldName: this.form.fieldName,
        fieldType: 3,
        moduleCode: code,
        options: str,
        description: this.form.description
      };
      if (data.fieldName) {
        if (data.fieldName === "undefined" || data.fieldName === false || this.isNull(data.fieldName) === true) {
          this.$notification["error"]({
            message: "error",
            description: "字段名称不能为空，添加失败"
          });
        } else {
          this.$refs.form.validate(function (valid) {
            if (valid) {
              postAdditionalFieldsAddDTO(data).then(function (res) {
                _this5.$notification["success"]({
                  message: "success",
                  description: "添加成功"
                });
                _this5.getData();
              });
            }
          });
        }
      } else if (!data.fieldName) {
        this.$notification["error"]({
          message: "error",
          description: "字段名称不能为空，添加失败"
        });
      }
    },
    getData: function getData(res) {
      var _this6 = this;
      getNecessaryFields({
        companyId: this.companyId
      }).then(function (res) {
        _this6.allData = res.data;
      });
    },
    goback: function goback() {
      this.$router.push({
        path: "/staff/edit"
      });
    },
    isNull: function isNull(str) {
      if (str === "") return true;
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      return re.test(str);
    }
  }
};