var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "面试评价",
      okText: "评价"
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "tit"
  }, [_vm._v("请根据面试者的表现，给出本轮面试结果及评价")]), _c("div", {
    staticClass: "tab"
  }, [_c("div", {
    staticClass: "tab-item",
    class: _vm.select ? "backgrounds" : "",
    on: {
      click: function click($event) {
        _vm.select = true;
      }
    }
  }, [_vm._v(" 通过 ")]), _c("div", {
    staticClass: "tab-item",
    class: !_vm.select ? "backgrounds" : "",
    on: {
      click: function click($event) {
        _vm.select = false;
      }
    }
  }, [_vm._v(" 未通过 ")])]), _c("div", {
    staticClass: "tit"
  }, [_vm._v("面试评价")]), _c("a-textarea", {
    attrs: {
      placeholder: "请输入",
      "auto-size": {
        minRows: 5,
        maxRows: 5
      }
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };