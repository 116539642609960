import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.sort.js";
import { getProvince, getArea } from "@/api/area";
import { addDepartment, delDepartments, putDepartment, getDepManageByDepId } from "@/api/department/department";
import { listAll } from "@/api/staff/staff";
import { mapState } from "vuex";
var table = [{
  title: "部门名称",
  dataIndex: "name"
}, {
  title: "人数",
  dataIndex: "empDirectCount"
}, {
  title: "子部门",
  dataIndex: "childCount"
}, {
  title: "操作",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  }
}];
export default {
  props: ["selectedId", "level", "isCompany", "getTree"],
  inject: ["reload"],
  data: function data() {
    return {
      form: this.$form.createForm(this),
      title: "添加部门",
      visible: false,
      province: [],
      area: [],
      values: [],
      allEmpInfo: [],
      table: table,
      data: [],
      selectedRowKeys: [],
      expandedKeys: [],
      searchValue: "",
      autoExpandParent: true,
      treeData: [],
      replaceFields: {
        title: "name"
      },
      dataList: [],
      architecture: [],
      depProps: {
        label: "name",
        children: "depChildList"
      },
      posProps: {
        label: "name",
        children: "positionChildList"
      },
      architectureDialogVisible: false,
      architectureForm: this.$form.createForm(this),
      perArchitecture: {
        type: 1
      },
      operateArchitecture: {
        method: "",
        title: ""
      },
      depType: [{
        label: "部门",
        value: 1
      }, {
        label: "事业群",
        value: 2
      }, {
        label: "组",
        value: 3
      }, {
        label: "中心",
        value: 4
      }, {
        label: "子公司",
        value: 5
      }, {
        label: "事业部",
        value: 6
      }],
      empInfoList: [],
      empInfoDialog: {
        visible: false,
        title: ""
      },
      nameOrPhoneOrEmail: "",
      adjustButtonDisabled: true,
      depToTreeButtonDisabled: true,
      empDepRelationVisible: false,
      depTreePopoverVisible: false,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true
      },
      // columns,
      // 待分配员工信息
      empData: {},
      // 员工调整后部门
      empToDep: "",
      position: [],
      perPosition: {},
      positionForm: this.$form.createForm(this),
      positionTypeDialog: {},
      positionDialog: {},
      positionRules: {
        name: {
          required: true,
          message: "不可为空"
        },
        code: {
          required: true,
          message: "不可为空"
        }
      }
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  watch: {
    selectedId: {
      handler: function handler(newVal) {
        if (newVal) {
          this.initData(this.selectedId);
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  methods: {
    Province: function Province() {
      var _this = this;
      getProvince().then(function (res) {
        _this.province = _this.tree(res.data, "children", true);
      });
    },
    selectProvince: function selectProvince(id, adr) {
      // this.addressId1 = id
      this.address = adr;
      this.changValue = true;
    },
    tree: function tree(array, childKey, onlyLastChild) {
      var _this2 = this;
      if (array === null || array.length < 1) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id + "";
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        if (item.children) {
          item["disabled"] = onlyLastChild;
          _this2.tree(item.children, childKey, onlyLastChild);
        }
      });
      return array;
    },
    initData: function initData(id, depName) {
      var _this3 = this;
      getDepManageByDepId({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        companyId: this.hr.companyId,
        depName: depName,
        depId: id
      }).then(function (res) {
        _this3.data = res.data.entities;
        _this3.pagination.total = res.data.entityCount || 0;
      });
    },
    tableChange: function tableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.initData(this.selectedId, this.searchDetail);
    },
    showDrawer: function showDrawer() {
      var _this4 = this;
      this.title = "添加部门";
      this.visible = true;

      // 请求所有负责人
      this.allEmp();
      this.form.resetFields();
      setTimeout(function () {
        _this4.form.setFieldsValue({
          virural: false
        });
      }, 0);
    },
    onClose: function onClose() {
      this.visible = false;
    },
    allEmp: function allEmp() {
      var _this5 = this;
      listAll({
        companyId: this.hr.companyId
      }).then(function (res) {
        _this5.allEmpInfo = res.data;
      });
    },
    handleSubmit: function handleSubmit(e) {
      var _this6 = this;
      var that = this;
      e.preventDefault();
      this.form.validateFields(function (err, values) {
        if (!err) {
          if (_this6.title === "添加部门") {
            var info = {
              level: _this6.level,
              parentId: _this6.selectedId,
              address: _this6.address ? _this6.address[0] : ""
            };
            addDepartment(_objectSpread(_objectSpread(_objectSpread({}, values), info), {}, {
              sort: 1,
              companyId: _this6.hr.companyId
            })).then(function (res) {
              that.$notification["success"]({
                message: "success",
                description: "部门添加成功"
              });
              _this6.$store.dispatch("company/loadDepartmentsAct", _this6.hr.companyId);
              that.visible = false;
              that.initData(_this6.selectedId);
              _this6.$emit("updateDep", res.data.id, res.data.parentId);
            });
          } else {
            values.companyId = _this6.hr.companyId;
            values.sort = 1;
            values.parentId = _this6.record.parentId;
            values.id = _this6.record.id;
            values.level = _this6.record.level;
            if (_this6.changValue) {
              values.address = _this6.address[0];
            }
            putDepartment(values).then(function (res) {
              that.$notification["success"]({
                message: "success",
                description: "修改成功"
              });
              _this6.$store.dispatch("company/loadDepartmentsAct", _this6.hr.companyId);
              that.visible = false;
              that.initData(_this6.selectedId);
              _this6.$emit("updateDep", res.data.id, res.data.parentId);
            });
          }
        }
      });
    },
    del: function del() {
      var that = this;
      this.$confirm({
        title: "删除部门",
        content: "此操作将删除部门, 是否继续?",
        okText: "确认",
        cancelText: "取消",
        onOk: function onOk() {
          delDepartments(that.selectedRowKeys).then(function (res) {
            that.$notification.open({
              title: "Success",
              message: "部门删除成功",
              type: "success",
              duration: 1
            });
            that.initData(that.selectedId);
            that.selectedRowKeys = [];
            that.$emit("getTree");
          });
        },
        onCancel: function onCancel() {}
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    treeFunc: function treeFunc(array, childKey) {
      var _this7 = this;
      if (!array) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id;
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        if (item.children) {
          _this7.treeFunc(item.children, childKey);
        }
      });
      return array;
    },
    edit: function edit(record) {
      var _this8 = this;
      this.title = "编辑部门";
      this.visible = true;
      this.record = record;
      this.changValue = false;
      this.form.resetFields();
      setTimeout(function () {
        _this8.form.setFieldsValue({
          name: record.name,
          desc: record.desc
        });
      }, 100);
    },
    onSearch: function onSearch(value) {
      this.pagination.current = 1;
      this.initData(this.selectedId, value);
    }
  }
};