var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      width: 720,
      title: "新建内推",
      visible: _vm.visible,
      footer: null,
      maskClosable: false,
      keyboard: false,
      destroyOnClose: true
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    }
  }, [_c("a-steps", {
    staticClass: "mb24",
    staticStyle: {
      padding: "0 32px"
    },
    attrs: {
      current: _vm.current
    }
  }, [_c("a-step", {
    attrs: {
      title: "选择职位"
    }
  }), _c("a-step", {
    attrs: {
      title: "选择内推范围"
    }
  }), _c("a-step", {
    attrs: {
      title: "赏金设置"
    }
  })], 1), _vm.current === 0 ? _c("selectPosition", {
    attrs: {
      selectedList: _vm.selectPositionArr
    },
    on: {
      upDate: _vm.upDate
    }
  }) : _vm._e(), _vm.current === 1 ? _c("selectScope", {
    attrs: {
      selectedList: _vm.selectScopeArr
    },
    on: {
      upDate: _vm.upDate
    }
  }) : _vm._e(), _vm.current === 2 ? _c("selectSet", {
    attrs: {
      selectPositionArr: _vm.selectPositionArr,
      selectScopeArr: _vm.selectScopeArr
    },
    on: {
      upDate: _vm.upDate
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };