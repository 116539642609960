import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";

// 获取个状态员工数量
export function getEmpInfoCount(id, params) {
  return request({
    url: "/hr/api/v1/empInfos/getEmpInfoCount/".concat(id),
    method: "get",
    params: params
  });
}
// 获取合同数量
export function getContractCount(data) {
  return request({
    url: "/hr/api/v1/memoInfos/getContractCount",
    method: "get",
    params: data
  });
}
// 获取员工数量概括
export function getEmpInfoCountIndex(companyId) {
  return request({
    url: "/hr/api/v1/empInfos/getEmpInfoCount/".concat(companyId),
    method: "get"
  });
}
// 获取工价计算规则列表
export function salaryCalculateRulesListall(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/listAll",
    method: "get",
    params: params
  });
}
// 获取员工信息
export function getEmpInfos(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoPageRelation",
    method: "get",
    params: params
  });
}

// 获取所有员工信息
export function listAll(params) {
  return request({
    url: "/hr/api/v1/empInfos/listAll",
    method: "get",
    params: params
  });
}

// 删除员工
export function delEmpInfo(data) {
  return request({
    url: "/hr/api/v1/empInfos/" + data.id,
    method: "delete"
  });
}
export function delEmpInfos(data) {
  return request({
    url: "/hr/api/v1/empInfos/",
    method: "delete",
    data: data
  });
}

// 移除某部门下某些员工;
export function deleteEmpByDep(depId, data) {
  return request({
    url: "/hr/api/v1/empDeps/deleteEmpByDep/" + depId,
    method: "delete",
    data: data
  });
}

// 获取员工信息详情,从员工概况进来的tag不为null
export function getEmpInfoDetail(data) {
  var urr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return request({
    url: urr == null ? "/hr/api/v1/empInfos/findEmpInfoRelation/" + data.id : "/hr/api/v1/empInfos/findEmpInfoRelationForStatisticsPage/" + data.id,
    method: "get",
    params: data
  });
}

// 查询员工标题信息
export function findEmpInfoTitle(data) {
  return request({
    url: "/hr/api/v1/empInfos/findEmpInfoTitle/" + data.id,
    method: "get",
    params: data
  });
}

// 修改员工基本信息
export function updateEmpBasic(data) {
  return request({
    url: "/hr/api/v1/empInfos/updateEmpBasic",
    method: "put",
    data: data
  });
}
// 根据员工手机号/身份证号 查询站点下已存在员工
export function selectResigned(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectResignedByPhoneAndIdCard",
    method: "get",
    params: params
  });
}
// 修改员工联系人信息
export function updateEmpBasicContact(data) {
  return request({
    url: "/hr/api/v1/empInfos/updateEmpBasicContact",
    method: "put",
    data: data
  });
}

// 修改员工信息
export function updateEmpInfo(data) {
  return request({
    url: "/hr/api/v1/empInfos",
    method: "put",
    data: data
  });
}

// 增加员工
export function addEmpInfo(data) {
  return request({
    url: "/hr/api/v1/empInfos/saveEmpInfoRelation",
    method: "post",
    data: data
  });
}
// 保存
export function saveSalaryCalculateRule(data) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/saveSalaryCalculateRule",
    method: "post",
    data: data
  });
}
export function saveSalaryCalculateRuleEdit(data) {
  return request({
    url: "hr/api/v1/empInfos/batchUpdateSalaryInfo",
    method: "put",
    data: data
  });
}
export function saveSalaryCalculateRule2(data) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/batchSaveSalaryCalculateRule",
    method: "post",
    data: data
  });
}
export function saveEmpInfoAndRule(data, tabKey) {
  var ur = "/hr/api/v1/empInfos/saveEmpInfoRelation";
  if (data.postType === 2 || tabKey === "1") {
    // 外包
    ur = "/hr/api/v1/empInfos/saveEmpInfoAndRule";
  }
  return request({
    url: ur,
    method: "post",
    data: data
  });
}

// 查询员工岗位信息
export function findEmpInfoPosition(data) {
  return request({
    url: "/hr/api/v1/empInfos/findEmpInfoPosition/" + data.id,
    method: "get",
    data: data
  });
}
// 修改员工岗位信息
export function updateEmpInfoPosition(data) {
  return request({
    url: "/hr/api/v1/empInfos/updateEmpInfoPosition/",
    method: "put",
    data: data
  });
}

// 转正式
export function transferFormal(data) {
  return request({
    url: "/hr/api/v1/empChanges/transferFormal",
    method: "POST",
    data: data
  });
}

// 转正
export function transferRegular(data) {
  return request({
    url: "/hr/api/v1/empChanges/batchTransferRegular",
    method: "POST",
    data: data
  });
}
// 新建
export function addEmpContracts(data) {
  return request({
    url: "/hr/api/v1/empContracts",
    method: "post",
    data: data
  });
}
export function updateEmpAllInfo(data) {
  return request({
    url: "/hr/api/v1/empInfos/updateEmpAllInfo",
    method: "put",
    data: data
  });
}
// 修改合同信息
export function updateEmpContracts(data) {
  return request({
    url: "/hr/api/v1/empContracts",
    //  "/hr/api/v1/empInfos/updateEmpAllInfo",
    method: "put",
    data: data
  });
}

// 删除合同信息
export function delEmpContracts(data) {
  return request({
    url: "/hr/api/v1/empContracts",
    method: "delete",
    data: data
  });
}
// 新建纸质合同
export function addEmpPaperContracts(data) {
  return request({
    url: "/hr/api/v1/empPaperContracts",
    method: "post",
    data: data
  });
}

// 修改纸质合同信息
export function updateEmpPaperContracts(data) {
  return request({
    url: "/hr/api/v1/empPaperContracts",
    method: "put",
    data: data
  });
}

// 删除纸质合同信息
export function delEmpPaperContracts(data) {
  return request({
    url: "/hr/api/v1/empPaperContracts",
    method: "delete",
    data: data
  });
}
// 办理入职
export function saveEmpInfoPre(data) {
  return request({
    url: "/hr/api/v1/empInfos/saveEmpInfoPre",
    method: "post",
    data: data
  });
}

// 确认到岗
export function confirmToJoin(data) {
  return request({
    url: "/hr/api/v1/empInfos/confirmToJoin",
    method: "put",
    data: data
  });
}
// 批量确认到岗
export function confirmToJoinBatch(data) {
  return request({
    url: "/hr/api/v1/empInfos/batchConfirmToJoin",
    method: "put",
    data: data
  });
}
// 批量未到岗
export function confirmToNoJoinBatch(data) {
  return request({
    url: "/hr/api/v1/empInfos/batchNoArrival",
    method: "put",
    data: data
  });
}

// 重新发起或者重新入职
export function onJobAgain(data) {
  return request({
    url: "/hr/api/v1/empInfos/onJobAgain",
    method: "put",
    data: data
  });
}
// 离职
export function leavePosition(data) {
  return request({
    url: "/hr/api/v1/empChanges/batchLeavePosition",
    method: "post",
    data: data
  });
}

// 已离职列表
export function getEmpResignedList(id, params) {
  return request({
    url: "/hr/api/v1/empChanges/pageEmpResignedList/".concat(id),
    method: "get",
    params: params
  });
}

// 已调岗列表
export function getEmpTransferList(id, params) {
  return request({
    url: "/hr/api/v1/empChanges/pageEmpTransferList/".concat(id),
    method: "get",
    params: params
  });
}

// 查询全部员工相关信息
export function selectEmpInfoALLRelation(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoALLRelation",
    method: "get",
    params: params
  });
}
// 员工换公司
export function editCompany(data) {
  return request({
    url: "/hr/api/v1/empInfos/empChangeCompanyId",
    method: "put",
    data: data
  });
}
// 恢复在职
export function reinstatement(params) {
  return request({
    url: "/hr/api/v1/empInfos/reinstatement",
    method: "put",
    params: params
  });
}
// 生成二维码
export function creatCode(data) {
  return request({
    url: "/hr/api/v1/miniProgram/generateQRCode",
    method: "post",
    data: data
  });
}
// 获取二维码
export function getCode(params) {
  return request({
    url: "/hr/api/v1/miniProgram/getQRCode",
    method: "get",
    params: params
  });
}
// 查询租户下的所有渠道来源
export function loadAllChannelForSiteReq(params) {
  return request({
    url: "/hr/api/v1/channels/listWithSite",
    method: "get",
    params: params
  });
}

// 查询离职员工相关信息
export function selectEmpInfoLeaveRelation(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoLeaveRelation",
    method: "get",
    params: params
  });
}

// 查询在职员工相关信息
export function selectEmpInfoOnJobRelation(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoOnJobRelation",
    method: "get",
    params: params
  });
}
// 查询待入职员工相关信息
export function selectEmpInfoPendingRelation(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoPendingRelation",
    method: "get",
    params: params
  });
}
// 查询待转正员工相关信息
export function selectEmpInfoToFormalRelation(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoToFormalRelation",
    method: "get",
    params: params
  });
}
// 查询调岗员工相关信息
export function selectEmpInfoTransferRelation(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoTransferRelation",
    method: "get",
    params: params
  });
}
export function empRegisterAccount(data) {
  return request({
    url: "/hr/api/v1/empInfos/empRegisterAccount",
    method: "post",
    data: data,
    params: data
  });
}
/** 审批流程 start ***************/

// 获取某公司入职可用的的所有审批流程
export function onJobFlows(companyId) {
  return request({
    url: "/hr/api/v1/empInfos/onJobFlows/".concat(companyId),
    method: "get"
  });
}

// 获取某公司离职可用的的所有审批流程
export function leavingFlows(companyId) {
  return request({
    url: "/hr/api/v1/empInfos/leavingFlows/".concat(companyId),
    method: "get"
  });
}

// 获取某公司调岗可用的的所有审批流程
export function transferDutyFlows(companyId) {
  return request({
    url: "/hr/api/v1/empInfos/transferDutyFlows/".concat(companyId),
    method: "get"
  });
}

// 获取某公司转正可用的的所有审批流程
export function turnStraightFlows(companyId) {
  return request({
    url: "/hr/api/v1/empInfos/turnStraightFlows/".concat(companyId),
    method: "get"
  });
}

/** 审批流程 end ***************/

// 查询员工可申请离职列表分页
export function selectEmpInfoListForLeave(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoListForLeave",
    method: "get",
    params: params
  });
}

// 根据员工Id确认离职
export function confimLeave(empId, data) {
  return request({
    url: "/hr/api/v1/empChanges/confimByEmp/leave/".concat(empId),
    method: "post",
    data: data
  });
}
//
export function findEmpHaveSocInfo(empId) {
  return request({
    url: "/hr/api/v1/empSocInfos/findEmpHaveSocInfo/".concat(empId),
    method: "get"
  });
}
// 根据员工Id撤销离职
export function revokeLeaveApi(data) {
  return request({
    url: "/hr/api/v1/empChanges/batchRevokeLeaveByEmpId",
    method: "put",
    data: data
  });
}
// 根据员工id确认调岗
export function confimTransferPositionApi(empId) {
  return request({
    url: "/hr/api/v1/empChanges/confimByEmp/transferPosition/".concat(empId),
    method: "put"
  });
}
// 根据员工id撤销调岗
export function revokeTransferPositionApi(data) {
  return request({
    url: "/hr/api/v1/empChanges/batchRevokeTransferPositionByEmpId",
    method: "put",
    data: data
  });
}

// 给选中的待入职员工发送入职短信
export function entrySmsByIds(data) {
  return request({
    url: "/hr/api/v1/empInfos/entrySmsByIds",
    method: "post",
    data: data
  });
}

// 根据用户审批id获取用户审批详情(包含申请信息及表单填写信息)
export function empApproDetails(type, id) {
  return request({
    url: "/hr/api/v1/hrApproProviders/empApproDetails/".concat(type, "/").concat(id),
    method: "get"
  });
}

// 查询已转正员工(正式)
export function pageHasFormal(params) {
  return request({
    url: "/hr/api/v1/empInfos/pageHasFormal",
    method: "get",
    params: params
  });
}

// 查询在职 / 待离职员工列表分页(花名册使用)
export function pageOnJobEmpList(params) {
  return request({
    url: "/hr/api/v1/empInfos/pageOnJobEmpList",
    method: "get",
    params: params
  });
}

// 查询待转正员工(试用期)
export function pageWaitFormal(params) {
  return request({
    url: "/hr/api/v1/empInfos/pageWaitFormal",
    method: "get",
    params: params
  });
}

// 查询待离职员工
export function pageWaitLeave(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoLeaveRelation",
    method: "get",
    params: params
  });
}

// 查询民族
export function nationListAll(params) {
  return request({
    url: "/admin/api/v1/nations/listAll",
    method: "get",
    params: params
  });
}

// /hr/api/v1/empChanges/getEmpResignedList/{companyId}
// 已离职列表

// GET
// /hr/api/v1/empChanges/getEmpTransferList/{companyId}
// 已调岗列表

export function getEmpCurrentPosition(params) {
  return request({
    url: "/hr/api/v1/empInfos/getEmpCurrentPosition",
    method: "get",
    params: params
  });
}
// 根据员工id,年,月查询专项附加项详情
export function getSpecial(params) {
  return request({
    url: "/salary/api/v1/specialAdditionalDetails/findByEmpIdAndYearAndMonth",
    method: "get",
    params: params
  });
}
// 修改或新增专项附加项详情
export function updateSpecial(data) {
  return request({
    url: "/salary/api/v1/specialAdditionalDetails/updateOrSave",
    method: "POST",
    data: data
  });
}
// 根据员工个税累计表id查询员工个税累计表详情
export function getEmpTaxGrand(params) {
  return request({
    url: "/salary/api/v1/empTaxGrandTotals/findByEmpId",
    method: "get",
    params: params
  });
}
// 查询员工参保月账单列表
export function getEmpGinseng(params) {
  return request({
    url: "/hr/api/v1/empSocInfos/getEmpSocInfoWithEmpInfo",
    method: "get",
    params: params
  });
}
// 新增或修改个税
export function updateTaxGrand(data) {
  return request({
    url: "/salary/api/v1/empTaxGrandTotals/saveOrUpdate",
    method: "POST",
    data: data
  });
}

// 今日新增
export function getQueryCount(params) {
  return request({
    url: "/hr/api/v1/operationEmpRecords/queryCount",
    method: "get",
    params: params
  });
}
export function getQueryEmpPageList(params) {
  return request({
    url: "/hr/api/v1/operationEmpRecords/queryEmpPageList",
    method: "get",
    params: params
  });
}

// 批量转正
export function batchTransferRegular(data) {
  return request({
    url: "/hr/api/v1/empChanges/batchTransferRegular",
    method: "POST",
    data: data
  });
}

// 批量确认调岗
export function batchConfirmTransferPosition(data) {
  return request({
    url: "/hr/api/v1/empChanges/batchConfirmTransferPosition",
    method: "PUT",
    data: data
  });
}

// 批量撤销调岗
export function batchRevokeTransferPositionByEmpId(data) {
  return request({
    url: "/hr/api/v1/empChanges/batchRevokeTransferPositionByEmpId",
    method: "PUT",
    data: data
  });
}

// 批量恢复在职
export function reinstatementUpdate(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/reinstatement/".concat(companyId),
    method: "PUT",
    data: data
  });
}

// 批量删除
export function empInfosDel(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos",
    method: "delete",
    data: data
  });
}
export function checkEmpCanBeDeleted(empId) {
  return request({
    url: "/hr/api/v1/empSocRelateds/checkEmpCanBeDeleted/".concat(empId),
    method: "get"
  });
}

// 根据用工单位id获取扫码入职信息
export function getQRCodeByCompanyId(params) {
  return request({
    url: "/hr/api/v1/miniProgram/getQRCodeByCompanyId",
    method: "get",
    params: params
  });
}