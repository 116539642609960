export default {
  props: {
    msgOptions: {
      type: Object,
      default: function _default() {
        return {
          show: false,
          data: []
        };
      }
    }
  },
  data: function data() {
    return {
      // 选择的模板
      value: "a",
      childrenDrawer: false,
      visible: false,
      form: this.$form.createForm(this, {
        name: "mesageForm"
      })
    };
  },
  watch: {
    msgOptions: {
      handler: function handler() {
        return this.visible = this.msgOptions.show;
      }
    }
  },
  methods: {
    // 关闭弹窗
    onClose: function onClose() {
      this.visible = false;
    },
    // 提交弹窗
    addSave: function addSave() {
      // 提交验证
      this.form.validateFields(function (err, values) {
        console.log("debug log --> ", err);
      });
    },
    // 选择验证
    handleSelectChange: function handleSelectChange(value) {
      this.form.setFieldsValue({
        note: "Hi, ".concat(value === "male" ? "man" : "lady", "!")
      });
    },
    // 添加部门或成员
    showChildrenDrawer: function showChildrenDrawer() {
      this.childrenDrawer = true;
    },
    // 关闭添加部门或弹窗
    handleOk: function handleOk() {
      this.childrenDrawer = false;
    },
    // 提醒方式
    onChangeCheckboxGroup: function onChangeCheckboxGroup(checkedValues) {},
    // 选择模板
    onChange: function onChange() {}
  }
};