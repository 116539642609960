import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-drawer", {
    attrs: {
      title: "简历详情",
      placement: "right",
      visible: _vm.visible,
      width: "50vw"
    },
    on: {
      close: _vm.onClose
    }
  }, [_c("div", {
    staticClass: "drawer-name"
  }, [_c("a-list-item-meta", {
    attrs: {
      description: _vm.address
    }
  }, [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("span", {
    staticStyle: {
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.name))]), _vm.sourceType === "broker" ? _c("span", {
    staticClass: "title-s-j"
  }, [_vm._v("经纪人")]) : _vm._e(), _vm.sourceType === "emp" ? _c("span", {
    staticClass: "title-s-n"
  }, [_vm._v("内推")]) : _vm._e(), _vm.sourceType === "broker" ? _c("span", [_vm._v("来自经纪人")]) : _vm._e(), _vm.sourceType === "broker" ? _c("span", {
    staticStyle: {
      color: "#6a66f6"
    }
  }, [_vm._v(_vm._s(_vm.brokerName))]) : _vm._e(), _vm.sourceType === "emp" ? _c("span", [_vm._v("来自内推人")]) : _vm._e(), _vm.sourceType === "emp" ? _c("span", {
    staticStyle: {
      color: "#32ccaa"
    }
  }, [_vm._v(_vm._s(_vm.brokerName))]) : _vm._e()]), _vm.avatar ? _c("a-avatar", {
    attrs: {
      slot: "avatar",
      src: _vm.avatar
    },
    slot: "avatar"
  }) : _vm._e(), !_vm.avatar && _vm.gender === 1 ? _c("a-avatar", {
    attrs: {
      slot: "avatar",
      src: _vm.n1
    },
    slot: "avatar"
  }) : _vm._e(), !_vm.avatar && _vm.gender === 2 ? _c("a-avatar", {
    attrs: {
      slot: "avatar",
      src: _vm.n2
    },
    slot: "avatar"
  }) : _vm._e()], 1), _c("div", {
    staticClass: "tags"
  }, [_vm.status === "1" ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("onMsg");
      }
    }
  }, [_vm._v(" 安排面试 ")]) : _vm._e(), ["1", "2", "3", "4"].includes(_vm.status) ? _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: function click($event) {
        return _vm.$emit("onAction", 5);
      }
    }
  }, [_vm._v(" 不合适 ")]) : _vm._e(), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    on: {
      click: function click($event) {
        _vm.modalVisible = true;
      }
    }
  }, [_vm._v(" 转移阶段 ")])], 1)], 1), _c("div", {
    staticClass: "drawer-msg"
  }, [_c("div", {
    on: {
      click: _vm.jump
    }
  }, [_c("a-tooltip", {
    attrs: {
      placement: "top"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.companyUnitsInfo.postName))])]), _c("div", {
    staticClass: "overflows"
  }, [_c("span", {
    staticStyle: {
      cursor: "pointer"
    }
  }, [_vm._v(_vm._s(_vm.companyUnitsInfo.postName))])])], 2), _vm._v(" > "), _vm.status === "1" ? _c("p", [_vm._v("已投递")]) : _vm._e(), _vm.status === "2" ? _c("p", [_vm._v("已约面")]) : _vm._e(), _vm.status === "3" ? _c("p", [_vm._v("待入职")]) : _vm._e(), _vm.status === "4" ? _c("p", [_vm._v("已招聘")]) : _vm._e(), _vm.status >= "5" ? _c("p", [_vm._v("未通过")]) : _vm._e()], 1), _c("div", {
    staticClass: "desc"
  }, [_vm._v(" " + _vm._s(_vm.companyUnitsInfo.companyUnitName) + " / " + _vm._s(_vm.companyUnitsInfo.cityName ? _vm.companyUnitsInfo.cityName.split(",")[1].split("/").join("-") : "") + " / " + _vm._s(_vm.companyUnitsInfo.activeDelivery === 1 ? "投递人主动投递" : "投递人非主动投递") + " / "), _vm._v(" " + _vm._s(_vm.moment(_vm.gmtCreate).format("YYYY-MM-DD HH:mm:ss")) + " ")])]), _c("resumeDetails", {
    attrs: {
      status: "deliveryRecord",
      info: _vm.info
    }
  }), _c("a-modal", {
    attrs: {
      title: "转移阶段",
      visible: _vm.modalVisible
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        _vm.modalVisible = false;
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    model: {
      value: _vm.select,
      callback: function callback($$v) {
        _vm.select = $$v;
      },
      expression: "select"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 已投递 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 已约面 ")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 待入职 ")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v(" 已招聘 ")]), _c("a-select-option", {
    attrs: {
      value: "5"
    }
  }, [_vm._v(" 未通过 ")])], 1)], 1)], 1), _c("interview", {
    attrs: {
      visible: _vm.interviewVisible,
      deliverRecordId: _vm.deliverRecordId,
      talentPoolId: _vm.talentPoolId,
      mjson: _vm.mjson
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.interviewVisible = $event;
      },
      onShare: _vm.onShare
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };