import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import ResizeMixin from "./mixin/ResizeHandler";
import Navbar from "./components/navbar";
import Sidebar from "./components/sidebar";
import AppMain from "./components/appmain";
import { mapState } from "vuex";
export default {
  name: "Layout",
  components: {
    Navbar: Navbar,
    Sidebar: Sidebar,
    AppMain: AppMain
  },
  data: function data() {
    return {
      isRouterAlive: true
    };
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    companyId: function companyId() {
      return this.hr.companyId;
    },
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    fixed_header: function fixed_header() {
      return this.$store.state.settings.fixed_header;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  }),
  watch: {
    companyId: function companyId(newVal) {
      var _this = this;
      if (newVal) {
        this.isRouterAlive = false;
        this.$nextTick(function () {
          _this.isRouterAlive = true;
        });
      }
    }
  },
  created: function created() {
    this.$store.dispatch("hr/getIsCompany");
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    }
  }
};