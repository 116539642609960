import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c("div", [_c("a-form", {
    staticStyle: {
      "margin-bottom": "62px"
    },
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "员工姓名:"
    }
  }, [_vm._v(" " + _vm._s(_vm.permissionsJson.name) + " ")]), _c("a-form-item", {
    attrs: {
      label: "员工电话:"
    }
  }, [_vm._v(" " + _vm._s(_vm.permissionsJson.phone) + " ")]), _c("a-form-item", {
    attrs: {
      label: "配置权限:"
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.jurisdiction,
      callback: function callback($$v) {
        _vm.jurisdiction = $$v;
      },
      expression: "jurisdiction"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 客户经理 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 客户专员 ")])], 1)], 1)], 1), _c("div", {
    staticClass: "btnBox"
  }, [_vm.type === "add" ? _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("upDate", 0);
      }
    }
  }, [_vm._v(" 上一步 ")]) : _vm._e(), _vm.jurisdiction === 1 ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.ok
    }
  }, [_vm._v(" 下一步 ")]) : _vm._e(), _vm.jurisdiction === 2 ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.ok
    }
  }, [_vm._v(" 完成 ")]) : _vm._e()], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };