import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
var columns = [{
  title: "员工姓名",
  dataIndex: "name",
  key: "name",
  width: 120,
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 200
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  width: 160
}, {
  title: "累计收入额(截至上月)",
  dataIndex: "cumulativeIncome",
  key: "cumulativeIncome",
  scopedSlots: {
    customRender: "cumulativeIncome"
  },
  width: 150
}, {
  title: "累计减除费用(截至上月)",
  dataIndex: "cumulativeDeductions",
  key: "cumulativeDeductions",
  scopedSlots: {
    customRender: "cumulativeDeductions"
  },
  width: 150
}, {
  title: "累计专项扣除(截至上月)",
  dataIndex: "cumulativeSpecialDeduction",
  key: "cumulativeSpecialDeduction",
  scopedSlots: {
    customRender: "cumulativeSpecialDeduction"
  },
  width: 150
}, {
  title: "累计已预缴税额",
  dataIndex: "cumulativePrepaidTax",
  key: "cumulativePrepaidTax",
  scopedSlots: {
    customRender: "cumulativePrepaidTax"
  },
  width: 150
}, {
  title: "操作",
  key: "action",
  scopedSlots: {
    customRender: "action"
  },
  width: 120
}];
import { getTax, editTax } from "@/api/wages/monthly";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
import moment from "moment";
export default {
  mixins: [mixin],
  data: function data() {
    return {
      abolitionImg: require("@/assets/home/lizhi.png"),
      empyleeForm: {},
      monthly: null,
      selectedRowKeys: [],
      yearly: null,
      salaryTemplateId: null,
      visible: false,
      alertVisible: true,
      inputName: null,
      alertVisible2: true,
      data: [],
      columns: columns,
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      }
    };
  },
  created: function created() {
    this.salaryTemplateId = this.$route.query.salaryTemplateId;
    // this.monthly = this.$route.query.monthly;
    // this.yearly = this.$route.query.yearly;
    this.monthly = moment().format("MM");
    this.yearly = moment().format("YYYY");
    this.getTaxList();
  },
  beforeDestory: function beforeDestory() {},
  methods: {
    goImport: function goImport() {
      this.$router.push("./importTax");
    },
    download: function download(i) {
      if (i === 2) {
        this.exportData = {
          companyId: this.companyId,
          monthly: parseInt(this.monthly),
          yearly: parseInt(this.yearly),
          nameOrPhone: this.inputName ? this.inputName : undefined
          // empIdList: []
        };
      } else {
        this.exportData = {
          companyId: this.companyId,
          monthly: parseInt(this.monthly),
          yearly: parseInt(this.yearly),
          empIdList: this.selectedRowKeys
        };
      }
      this.url = "/salary/api/v1/empTaxGrandTotals/exportCompanyTaxGrandTotalPage";
      this.excelName = "个税累计导出详情" + ".xlsx";
      this.postDownload();
    },
    onSelectChange: function onSelectChange(selectedRowKeys, e) {
      this.selectedRowKeys = selectedRowKeys;
    },
    inputChange: function inputChange(e) {
      this.inputName = e;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getTaxList();
    },
    handleClose2: function handleClose2() {
      this.alertVisible2 = false;
    },
    handleClose: function handleClose() {
      this.alertVisible = false;
    },
    getTaxList: function getTaxList() {
      var _this = this;
      getTax({
        companyId: this.companyId,
        salaryTemplateSalaryId: this.salaryTemplateId,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        monthly: parseInt(this.monthly),
        yearly: parseInt(this.yearly),
        nameOrPhone: this.inputName
      }).then(function (res) {
        _this.data = res.data.entities;
        _this.pagination.total = res.data.entityCount;
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getTaxList();
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;
      editTax(_objectSpread({}, this.empyleeForm)).then(function (res) {
        _this2.getTaxList();
        _this2.$notification["success"]({
          message: "成功",
          description: "修改成功"
        });
        _this2.visible = false;
      });
    },
    onClose: function onClose() {
      this.visible = false;
    },
    showEdit: function showEdit(record) {
      this.visible = true;
      this.empyleeForm = JSON.parse(JSON.stringify(record.empTaxGrandTotalVO));
      this.empyleeForm.empName = record.name;
    },
    goCalculation: function goCalculation() {
      this.$router.push({
        path: "/wages/monthly/calculation"
      });
    },
    goEdit: function goEdit() {
      this.$router.push({
        path: "/wages/monthly/editDetails"
      });
    }
  }
};