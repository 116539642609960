import upload from "./component/upload";
import preview from "./component/preview";
export default {
  components: {
    upload: upload,
    preview: preview
  },
  data: function data() {
    return {
      current: 0,
      status: false,
      rdata: "",
      previewJson: {}
    };
  },
  created: function created() {
    // if (this.$store.state.recruitment.talentPoolTempId) {
    //   this.current = 1;
    //   this.talentPoolTempId = this.$store.state.recruitment.talentPoolTempId;
    // }
  },
  methods: {
    onAction: function onAction(e, v) {
      this.current = e;
      if (v) {
        this.previewJson = v;
      }
    }
  }
};