import "core-js/modules/es.array.push.js";
export default {
  name: "AddPayslipForth",
  methods: {
    goDetail: function goDetail() {
      this.$router.push({
        path: "/admin/sendSingle"
      });
    }
  }
};