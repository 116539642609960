import _createForOfIteratorHelper from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import { transferJob as _transferJob, getTransferJobInfo } from "@/api/staff/empChanges";
import { findEmpInfoTitle } from "@/api/staff/staff";
import { getDepartmentInTree } from "@/api/department/department";
import { getPositionInTree } from "@/api/department/position";
import { uploadAttachmentFiles, saveSubjectAttachment } from "@/api/file/file";
import AreaComponent from "./component/area";
export default {
  name: "Detail",
  filters: {
    formatDate: formatDate
  },
  components: {
    AreaComponent: AreaComponent
  },
  data: function data() {
    return {
      form: this.$form.createForm(this),
      companyId: "",
      name: "",
      status: "",
      formalType: "",
      phone: "",
      joinDate: "",
      employInfoId: this.$route.query.empInfoId,
      employInfo: "",
      currentWorkCityId: "",
      empIdCardPicture: "",
      avatarUrl: "",
      empChanges: {},
      fileList: [],
      uploading: false,
      positionOptions: [],
      departmentOptions: [],
      managerModusOptions: [{
        label: "请选择",
        value: ""
      }, {
        label: "总部",
        value: 0
      }, {
        label: "分城市",
        value: 1
      }, {
        label: "其它",
        value: 2
      }] // 管理形式
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    alertResultMessage: function alertResultMessage(result) {
      var type = "success";
      if (result.code !== 200) {
        type = "error";
      }
      this.$notification[type]({
        message: result.msg
      });
    },
    cancel: function cancel() {
      this.$router.go(-1);
    },
    transferJob: function transferJob(e) {
      var _this = this;
      e.preventDefault();
      if (this.fileList.length > 0 && this.empIdCardPicture !== null) {
        this.$notification["error"]({
          message: "文件未上传"
        });
        return;
      }
      this.form.validateFields(function (err, values) {
        if (!err) {
          var that = _this;
          that.$confirm({
            title: "调岗",
            content: "确定调岗?",
            onOk: function onOk() {
              values.empIds = [that.employInfoId];
              values.companyId = that.companyId;
              values.adjustmentAttachment = that.empIdCardPicture;
              values.currentWorkCityId = that.currentWorkCityId;
              _transferJob(values).then(function (res) {
                that.alertResultMessage(res);
                if (res.code === 200) {
                  that.cancel();
                }
              });
            },
            onCancel: function onCancel() {}
          });
        }
      });
    },
    handleFileRemove: function handleFileRemove(file) {
      var index = this.fileList.indexOf(file);
      var newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      if (newFileList.length === 0) {
        this.empIdCardPicture = null;
        return;
      }
      this.uploadFile();
    },
    beforeUpload: function beforeUpload(file) {
      if (file.size > 5242880) {
        this.$notification["warning"]({
          message: "文件最大为5MB"
        });
        return false;
      }
      if (this.fileList.length > 4) {
        this.$notification["warning"]({
          message: "最多上传5个文件"
        });
        return false;
      }
      this.fileList = [].concat(_toConsumableArray(this.fileList), [file]);
      return false;
    },
    uploadFile: function uploadFile() {
      var _this2 = this;
      this.uploading = true;
      var fileList = this.fileList;
      var formData = new FormData();
      fileList.forEach(function (file) {
        formData.append("files", file);
      });
      uploadAttachmentFiles(formData).then(function (res) {
        if (res.code !== 200) {
          _this2.$notification["warning"]({
            message: "处理失败"
          });
        }
        _this2.uploading = false;
        var fileIds = [];
        var _iterator = _createForOfIteratorHelper(res.data),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var obj = _step.value;
            fileIds.push(obj.id);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        var params = {
          fileIds: fileIds,
          subjectId: _this2.employInfoId,
          subjectType: "hr_emp_job_adjust"
        };
        saveSubjectAttachment(params).then(function (res) {
          _this2.alertResultMessage(res);
          if (res.code === 200) {
            _this2.empIdCardPicture = params.subjectId;
          }
        });
      });
    },
    tree: function tree(array, childKey, onlyLastChild) {
      var _this3 = this;
      if (array === null || array.length < 1) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id;
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        if (item.children) {
          item["disabled"] = onlyLastChild;
          _this3.tree(item.children, childKey, onlyLastChild);
        }
      });
      return array;
    },
    getEmployeeStatus: function getEmployeeStatus(status, formalType) {
      if (status === 1) {
        return "待入职";
      } else if (status === 2) {
        if (formalType === 1) {
          return "适用期";
        } else {
          return "在职";
        }
      } else if (status === 4) {
        return "离职";
      }
      return "在职";
    },
    initData: function initData() {
      var _this4 = this;
      findEmpInfoTitle({
        id: this.employInfoId,
        companyId: this.companyId
      }).then(function (res) {
        var data = res.data;
        _this4.name = data.name;
        _this4.joinDate = data.joinDate;
        _this4.phone = data.phone;
        _this4.avatarUrl = data.empPhoto;
        _this4.formalType = data.formalType;
        _this4.status = data.status;
      });
      getTransferJobInfo({
        id: this.employInfoId,
        companyId: this.companyId
      }).then(function (res) {
        _this4.empChanges = res.data;
      });
      getPositionInTree({
        companyId: this.companyId
      }).then(function (res) {
        _this4.positionOptions = _this4.tree(res.data, "positionChildList", true);
      });
      getDepartmentInTree({
        companyId: this.companyId
      }).then(function (res) {
        _this4.departmentOptions = _this4.tree(res.data[0].depChildList, "depChildList");
      });
    }
  }
};
function formatDate(value) {
  if (isNaN(value) || value === "") {
    return "";
  }
  var date = new Date(value);
  var Y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();
  var H = date.getHours();
  var i = date.getMinutes();
  var s = date.getSeconds();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  if (H < 10) {
    H = "0" + H;
  }
  if (i < 10) {
    i = "0" + i;
  }
  if (s < 10) {
    s = "0" + s;
  }
  var t = Y + "-" + m + "-" + d;
  return t;
}