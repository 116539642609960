var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "peopleBox"
  }, [_c("div", {
    staticClass: "peopleBoxTop"
  }, [_c("div", {
    staticClass: "peopleBoxTopLeft"
  }), _c("div", {
    staticClass: "peopleBoxTopTitle"
  }, [_vm._v(_vm._s(_vm.title))]), _c("div", {
    staticClass: "peopleBoxTopright"
  })]), _vm._t("default")], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };