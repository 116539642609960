import request from "@/utils/request";
export function getCustomerPage(params) {
  return request({
    url: "/hr/api/v1/crmCustomerInfos/getCustomerPage",
    method: "GET",
    params: params
  });
}
export function getMyJurisdiction(params) {
  return request({
    url: "/hr/api/v1/crmMembers/getMyJurisdiction",
    method: "GET",
    params: params
  });
}