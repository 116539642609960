import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm.type == "1" ? _c("span", [_vm._v("个人信息设置")]) : _vm._e(), _vm.type == "2" ? _c("span", [_vm._v("工资社保设置")]) : _vm._e(), _vm.type == "3" ? _c("span", [_vm._v("材料附件设置")]) : _vm._e(), _vm.type == "4" ? _c("span", [_vm._v("在职信息设置")]) : _vm._e()]), _c("a-collapse", {
    staticClass: "p24 collapse",
    staticStyle: {
      "padding-top": "0"
    },
    attrs: {
      bordered: false,
      "expand-icon-position": "right"
    },
    model: {
      value: _vm.activeKey,
      callback: function callback($$v) {
        _vm.activeKey = $$v;
      },
      expression: "activeKey"
    }
  }, _vm._l(_vm.fieldData, function (item, index) {
    return _c("a-collapse-panel", {
      key: String(index + 1),
      staticStyle: {
        "margin-bottom": "24px"
      }
    }, [_c("div", {
      staticClass: "pv12",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("div", {
      staticClass: "firld_title"
    }, [_vm._v(_vm._s(item.moduleName))])]), _c("div", {
      staticStyle: {
        position: "absolute",
        top: "22px",
        left: "180px"
      },
      attrs: {
        slot: "extra"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.checkbox.apply(null, arguments);
        }
      },
      slot: "extra"
    }, [item.supportNecessaryCheck ? _c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.handleClick(item);
        }
      },
      model: {
        value: item.necessary,
        callback: function callback($$v) {
          _vm.$set(item, "necessary", $$v);
        },
        expression: "item.necessary"
      }
    }, [_vm._v("是否必须添加")]) : _vm._e()], 1), _c("a-row", {
      attrs: {
        type: "flex"
      }
    }, [_c("a-col", {
      attrs: {
        span: 4
      }
    }, [_c("div", {
      staticClass: "ta-center firld_header custom textleft"
    }, [_vm._v("字段名称")])]), _c("a-col", {
      attrs: {
        span: 4
      }
    }, [_c("div", {
      staticClass: "ta-center firld_header custom"
    }, [_vm._v("是否必填")])]), _c("a-col", {
      attrs: {
        span: 4
      }
    }, [_c("div", {
      staticClass: "ta-center firld_header custom"
    }, [_vm._v("是否开启")])]), _c("a-col", {
      attrs: {
        span: 4
      }
    }, [_c("div", {
      staticClass: "ta-center firld_header custom border-left textleft"
    }, [_vm._v("字段名称")])]), _c("a-col", {
      attrs: {
        span: 4
      }
    }, [_c("div", {
      staticClass: "ta-center firld_header custom"
    }, [_vm._v("是否必填")])]), _c("a-col", {
      attrs: {
        span: 4
      }
    }, [_c("div", {
      staticClass: "ta-center firld_header custom"
    }, [_vm._v("是否开启")])])], 1), _c("a-row", {
      attrs: {
        type: "flex"
      }
    }, [_vm._l(item.fieldList, function (items, indexs) {
      return _c("a-col", {
        key: indexs,
        attrs: {
          span: 12,
          type: "flex"
        }
      }, [_c("div", {
        staticClass: "border-bottom",
        class: (indexs + 1) % 2 == 0 ? "" : "border-right"
      }, [_c("a-row", [_c("a-col", {
        attrs: {
          span: 8
        }
      }, [_c("div", {
        staticClass: "ta-center firld_body dsy_body textleft"
      }, [_vm._v(_vm._s(items.fieldName))])]), _c("a-col", {
        attrs: {
          span: 8
        }
      }, [_c("div", {
        staticClass: "ta-center firld_body dsy_body"
      }, [_c("a-checkbox", {
        on: {
          change: function change($event) {
            return _vm.remoteControl(items);
          }
        },
        model: {
          value: items.isNecessary,
          callback: function callback($$v) {
            _vm.$set(items, "isNecessary", $$v);
          },
          expression: "items.isNecessary"
        }
      })], 1)]), _c("a-col", {
        attrs: {
          span: 8
        }
      }, [_c("div", {
        staticClass: "ta-center firld_body dsy_body"
      }, [_c("a-checkbox", {
        on: {
          change: function change($event) {
            return _vm.remoteControl(items);
          }
        },
        model: {
          value: items.isOpen,
          callback: function callback($$v) {
            _vm.$set(items, "isOpen", $$v);
          },
          expression: "items.isOpen"
        }
      })], 1)])], 1)], 1)]);
    }), _vm._l(item.additionalFieldList, function (items, indexs) {
      return _c("a-col", {
        key: indexs + 1005,
        attrs: {
          span: 12
        }
      }, [item.supportAdditional ? _c("div", {
        staticClass: "border-bottom",
        class: (item.fieldList.length + indexs + 1) % 2 == 0 ? "" : "border-right"
      }, [_c("a-row", {
        attrs: {
          type: "flex"
        }
      }, [_c("a-col", {
        attrs: {
          span: 8
        }
      }, [_c("div", {
        staticClass: "ta-center firld_body dsy_body textleft"
      }, [_vm._v(_vm._s(items.fieldName))])]), _c("a-col", {
        attrs: {
          span: 8
        }
      }, [_c("div", {
        staticClass: "ta-center firld_body dsy_body"
      }, [_c("a-checkbox", {
        on: {
          change: function change($event) {
            return _vm.remoteControls(items, "necessary");
          }
        },
        model: {
          value: items.isNecessary,
          callback: function callback($$v) {
            _vm.$set(items, "isNecessary", $$v);
          },
          expression: "items.isNecessary"
        }
      })], 1)]), _c("a-col", {
        attrs: {
          span: 8
        }
      }, [_c("div", {
        staticClass: "ta-center firld_body dsy_body",
        staticStyle: {
          position: "relative"
        }
      }, [_c("a-checkbox", {
        staticClass: "mx20",
        on: {
          change: function change($event) {
            return _vm.remoteControls(items, "open");
          }
        },
        model: {
          value: items.isOpen,
          callback: function callback($$v) {
            _vm.$set(items, "isOpen", $$v);
          },
          expression: "items.isOpen"
        }
      }), _c("div", {
        staticClass: "mx20",
        staticStyle: {
          position: "absolute",
          right: "5px",
          top: "0"
        }
      }, [_c("a-space", {
        attrs: {
          size: "middle"
        }
      }, [_c("a-tooltip", {
        attrs: {
          placement: "top",
          title: "\u4FEE\u6539\u5B57\u6BB5\u3010".concat(items.fieldName, "\u3011")
        }
      }, [_c("a-icon", {
        staticClass: "pointer",
        attrs: {
          type: "form"
        },
        on: {
          click: function click($event) {
            return _vm.edit(items);
          }
        }
      })], 1), _c("a-popconfirm", {
        attrs: {
          title: "\u786E\u5B9A\u5220\u9664\u5B57\u6BB5\u3010".concat(items.fieldName, "\u3011\u5417\uFF1F")
        },
        on: {
          confirm: function confirm($event) {
            return _vm.confirmDelete(items);
          }
        }
      }, [_c("a-icon", {
        staticClass: "pointer",
        attrs: {
          type: "delete"
        }
      })], 1)], 1)], 1)], 1)])], 1)], 1) : _vm._e()]);
    }), _c("a-col", {
      attrs: {
        span: 12,
        type: "flex"
      }
    }, [item.supportAdditional ? _c("div", {
      staticClass: "border-bottom",
      class: (item.additionalFieldList.length + item.fieldList.length) % 2 == 0 ? "border-right" : ""
    }, [_c("a-row", [_c("a-col", {
      attrs: {
        span: 24
      }
    }, [_c("div", {
      staticClass: "dsy_body"
    }, [_c("a-button", {
      attrs: {
        type: "link",
        icon: "plus-circle"
      },
      on: {
        click: function click($event) {
          return _vm.addField(item);
        }
      }
    }, [_vm._v("添加字段")])], 1)])], 1)], 1) : _vm._e()])], 2), _c("div", {
      staticStyle: {
        height: "15px"
      }
    })], 1);
  }), 1), _c("a-modal", {
    attrs: {
      title: _vm.title,
      visible: _vm.visible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    staticClass: "ruleFormShowStyle",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "fieldName",
    attrs: {
      help: "1-20个字符，不能与现有字段重复",
      rules: [{
        required: true,
        message: "请输入字段名称"
      }],
      label: "字段名称",
      prop: "fieldName"
    }
  }, [_c("a-input", {
    attrs: {
      allowClear: "",
      placeholder: "请输入字段名称",
      "max-length": 20
    },
    model: {
      value: _vm.form.fieldName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldName", $$v);
      },
      expression: "form.fieldName"
    }
  })], 1), _c("a-form-model-item", {
    ref: "fieldType",
    attrs: {
      rules: {
        required: true,
        message: "请选择字段类型"
      },
      label: "字段类型",
      prop: "fieldType"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      allowClear: "",
      disabled: _vm.marking === 2 ? true : false,
      placeholder: "请选择字段类型"
    },
    on: {
      change: _vm.fieldTypeChange
    },
    model: {
      value: _vm.form.fieldType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldType", $$v);
      },
      expression: "form.fieldType"
    }
  }, _vm._l(_vm.fieldTypeArr[parseInt(_vm.type) - 1], function (item) {
    return _c("a-select-option", {
      key: item.k,
      attrs: {
        value: item.k
      }
    }, [_vm._v(_vm._s(item.v))]);
  }), 1)], 1), _vm.form.fieldType == "1" ? _c("a-form-model-item", {
    attrs: {
      label: "选项值",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 22
      }
    }
  }, [_vm._l(_vm.form.options, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "display-flex",
      staticStyle: {
        "align-items": "center",
        width: "80%",
        margin: "0 auto"
      }
    }, [_c("div", {
      staticStyle: {
        width: "120px"
      }
    }, [_vm._v(_vm._s("\u9009\u9879 ".concat(index)))]), _c("a-input", {
      attrs: {
        allowClear: "",
        placeholder: "请输入字段名称",
        "max-length": 15
      },
      model: {
        value: _vm.form.options[index],
        callback: function callback($$v) {
          _vm.$set(_vm.form.options, index, $$v);
        },
        expression: "form.options[index]"
      }
    }), _vm.form.options.length > 1 ? _c("a-icon", {
      staticClass: "dynamic-delete-button pointer",
      staticStyle: {
        margin: "0 15px"
      },
      attrs: {
        type: "delete",
        disabled: _vm.form.options.length === 1
      },
      on: {
        click: function click($event) {
          return _vm.remove(index);
        }
      }
    }) : _vm._e()], 1);
  }), _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("a-button", {
    staticStyle: {
      width: "60%"
    },
    attrs: {
      type: "dashed"
    },
    on: {
      click: _vm.addDomain
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加自定义选项 ")], 1)], 1)], 2) : _vm._e(), _c("a-form-model-item", {
    ref: "description",
    attrs: {
      label: "字段描述",
      prop: "description"
    }
  }, [_c("a-input", {
    attrs: {
      allowClear: "",
      placeholder: "请输入字段描述",
      "max-length": 8
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };