import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import CompanyDetails from "./components/companyDetails.vue";
import RightDetails from "./components/rightDetails.vue";
import { selectCompanyAndEmpCountStatisticsReq, updateSitePic as _updateSitePic, findCompanyGroup as _findCompanyGroup, findCompanyBalances, companyBalanceRecords as _companyBalanceRecords } from "./api";
import { uploadGeneralImg } from "@/api/file/file";
import selectCompanyGroup from "@/components/SelectCompany";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
var columnKuanxiang = [{
  title: "款项名称",
  dataIndex: "changeType",
  scopedSlots: {
    customRender: "changeType"
  },
  align: "left",
  ellipsis: true,
  width: 150
}, {
  title: "用工单位",
  dataIndex: "companyName",
  align: "left",
  ellipsis: true,
  width: 100
}, {
  title: "金额",
  dataIndex: "changeAmount",
  scopedSlots: {
    customRender: "changeAmount"
  },
  align: "left",
  ellipsis: true,
  width: 80
}, {
  title: "余额",
  dataIndex: "afterAmount",
  align: "left",
  ellipsis: true,
  width: 80
}, {
  title: "操作时间",
  dataIndex: "gmtCreate",
  scopedSlots: {
    customRender: "gmtCreate"
  },
  align: "center",
  ellipsis: true,
  width: 110
}];
export default {
  components: {
    CompanyDetails: CompanyDetails,
    RightDetails: RightDetails,
    selectCompanyGroup: selectCompanyGroup
  },
  data: function data() {
    return {
      ids: null,
      amount: 0,
      dataDetail2: [],
      now: [],
      mode2: ["month", "month"],
      status: null,
      bushouTable: false,
      columnKuanxiang: columnKuanxiang,
      sitePic: require("@/assets/icons/hr_default_logo.png"),
      picUrl: undefined,
      clicked: false,
      selectTreeId: "-1",
      replaceFields: {
        children: "companyList",
        title: "name",
        key: "id",
        value: "id"
      },
      dataList: [],
      siteId: undefined,
      type: undefined,
      pagination: {
        pageSize: 10,
        total: 10,
        size: "default",
        current: 1,
        showQuickJumper: true
      },
      lastNewValue: "",
      statisticsData: {}
    };
  },
  mounted: function mounted() {},
  watch: {
    checkSiteName: function checkSiteName(newData) {
      if (this.dataList.length > 0) {
        this.dataList[0].name = newData;
      }
    },
    siteId: function siteId(newValue) {
      this.lastNewValue = newValue;
      this.selectCompanyAndEmpCountStatistics(newValue);
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState(["site"])), {}, {
    sitePicShow: function sitePicShow() {
      return this.statisticsData.sitePic || this.sitePic;
    },
    checkSiteName: function checkSiteName() {
      return this.statisticsData.siteName;
    }
  }, mapGetters({
    SelectedAllIds: "site/SelectedAllIds"
  })),
  created: function created() {
    this.siteId = this.SelectedAllIds;
    this.findCompanyGroup();
  },
  methods: {
    getCompanyBalances: function getCompanyBalances() {
      var _this = this;
      var a = [];
      if (this.siteId && this.siteId !== "-1") {
        a = this.siteId.split(",");
      }
      findCompanyBalances(a).then(function (res) {
        if (res.data) {
          _this.amount = res.data.amount ? res.data.amount : 0;
          _this.ids = res.data.ids;
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.companyBalanceRecords();
    },
    getType: function getType(item) {
      switch (item.changeType) {
        case 1:
          return "客户回款计划";
        case 2:
          return "社保回款计划";
        case 3:
          return "渠道劳务费结算";
        case 4:
          return "费用账单";
      }
    },
    dateOk: function dateOk() {
      this.$refs.date.$refs.picker.sOpen = false;
    },
    showDetail: function showDetail() {
      this.bushouTable = true;
      this.pagination.current = 1;
      this.companyBalanceRecords();
    },
    // 分页查询明细
    companyBalanceRecords: function companyBalanceRecords() {
      var _this2 = this;
      var param = {
        balanceIds: this.ids,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      if (this.now.length !== 0) {
        param.gmtCreateEnd = moment(this.now[1]).valueOf();
        param.gmtCreateStart = moment(this.now[0]).valueOf();
      }
      if (this.status) {
        param.changeType = parseInt(this.status);
      }
      _companyBalanceRecords(param).then(function (res) {
        if (res.data) {
          _this2.dataDetail2 = res.data.entities;
          _this2.pagination.total = res.data.entityCount;
          _this2.pagination.pageSize = res.data.pageSize;
          _this2.pagination.current = res.data.pageNo;
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleCancel: function handleCancel() {
      this.bushouTable = false;
    },
    handlePanelChange2: function handlePanelChange2(value, mode) {
      this.now = value;
      this.mode2 = [mode[0] === "date" ? "month" : mode[0], mode[1] === "date" ? "month" : mode[1]];
      this.pagination.current = 1;
      this.companyBalanceRecords();
    },
    onMonthChange: function onMonthChange(value) {
      this.now = value;
      this.pagination.current = 1;
      this.companyBalanceRecords();
    },
    approStatusChange: function approStatusChange(e) {
      this.status = e;
      this.pagination.current = 1;
      this.companyBalanceRecords();
    },
    toYongGong: function toYongGong() {
      this.$store.commit("hr/setAdminNavState", "overview");
      this.$router.push({
        path: "/overview/employer"
      });
    },
    toZiGongSi: function toZiGongSi() {
      this.$store.commit("hr/setAdminNavState", "overview");
      this.$router.push({
        path: "/overview/subsidiary"
      });
    },
    toYuanGong: function toYuanGong() {
      this.$store.commit("hr/setAdminNavState", "overview");
      this.$router.push({
        path: "/overview/staff"
      });
    },
    unique: function unique(arr) {
      var res = [];
      var obj = {};
      for (var i = 0; i < arr.length; i++) {
        if (!obj[arr[i]]) {
          obj[arr[i]] = 1;
          res.push(arr[i]);
        }
      }
      return res;
    },
    selectCompanyAndEmpCountStatistics: function selectCompanyAndEmpCountStatistics() {
      var _this3 = this;
      selectCompanyAndEmpCountStatisticsReq(this.site.selectId === -1 ? {} : {
        companyIds: this.siteId,
        type: this.site.selectType || "site"
      }).then(function (res) {
        if (res.code === 200) {
          _this3.statisticsData = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
      this.getCompanyBalances();
    },
    updateSitePic: function updateSitePic() {
      var _this4 = this;
      var da = {
        picUrl: this.picUrl
      };
      _updateSitePic(da).then(function (res) {
        _this4.$message.success("更换成功");
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    beforeUpload: function beforeUpload(file) {
      var _this5 = this;
      var formData = new FormData();
      formData.append("files", file);
      uploadGeneralImg(formData).then(function (res) {
        _this5.picUrl = res.data[0];
        _this5.sitePic = _this5.picUrl;
        _this5.updateSitePic();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      return false;
    },
    findCompanyGroup: function findCompanyGroup(value) {
      var _this6 = this;
      var data1 = {
        name: value || undefined
      };
      _findCompanyGroup(data1).then(function (res) {
        _this6.dataList = _this6.addTreeSlot(res.data);
        var dataListTemporary = [{
          name: _this6.checkSiteName,
          id: "-1",
          companyList: _this6.dataList,
          scopedSlots: {
            title: "custom"
          }
        }];
        _this6.dataList = [].concat(dataListTemporary);
        console.log("debug log --> dataList", _this6.dataList);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // treeData添加字段
    addTreeSlot: function addTreeSlot(data) {
      var _this7 = this;
      if (!data) {
        return [];
      }
      data.forEach(function (el) {
        el.scopedSlots = {
          title: "custom"
        };
        _this7.addTreeSlot(el.companyList);
      });
      return data;
    }
  }
};