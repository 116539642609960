import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    staticClass: "page-miH page-bgBack",
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(" " + _vm._s(_vm.item.billName) + " ")]), _c("div", {
    staticClass: "page"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "fix-item-wrapper"
  }, [_c("div", {
    staticClass: "fix-item-one"
  }, [_c("p", {
    staticClass: "fix-item-name"
  }, [_vm._v("合计")])]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v(" " + _vm._s(_vm.getTitle(_vm.item.subjectType, 0) === "计薪人数" ? "计薪人数" : "人数") + " ")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v(" " + _vm._s(_vm.item ? _vm.getTitle(_vm.item.subjectType, 0) === "计薪人数" ? _vm.getCost(_vm.item, 0) : _vm.item.number : "0") + " ")])])])]), _c("div", {
    staticClass: "fix-item-wrapper2"
  }, [_vm.getTitle(_vm.item.subjectType, 0) !== "计薪人数" ? _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v(_vm._s(_vm.item ? _vm.getTitle(_vm.item.subjectType, 0) : ""))]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v(" " + _vm._s(_vm.item ? _vm.getTitle(_vm.item.subjectType, 0) === "计薪人数" ? _vm.getCost(_vm.item, 0) + "人" : "¥" + _vm.getCost(_vm.item, 0) + "元" : "") + " ")])])]) : _vm._e(), _vm.getTitle(_vm.item.subjectType, 0) !== "计薪人数" ? _c("div", {
    staticStyle: {
      "border-right": "1px solid #e8e8e8",
      "margin-bottom": "20px"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v(_vm._s(_vm.item ? _vm.getTitle(_vm.item.subjectType, 1) : ""))]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.item ? _vm.getCost(_vm.item, 1) : "") + "元")])]), _c("div", {
    staticStyle: {
      "border-right": "1px solid #e8e8e8",
      "margin-bottom": "20px"
    }
  }), _vm.item.subjectType === "社保账单" || _vm.item.subjectType === "公积金账单" ? _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("滞纳金")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.item.fine ? _vm.item.fine : 0) + "元")])])]) : _vm._e(), _vm.item.subjectType === "社保账单" || _vm.item.subjectType === "公积金账单" ? _c("div", {
    staticStyle: {
      "border-right": "1px solid #e8e8e8",
      "margin-bottom": "20px"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v(_vm._s(_vm.item ? _vm.getTitle(_vm.item.subjectType, 2) : ""))]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.item ? _vm.getCost(_vm.item, 2) : "") + "元")])])]), _c("div", {
    staticStyle: {
      "border-right": "1px solid #e8e8e8",
      "margin-bottom": "20px"
    }
  }), _vm.item.isConfirm ? _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("实际支付金额")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.item.finalAmount ? _vm.item.finalAmount : 0) + "元")])])]) : _vm._e()])]), _c("div", {
    staticClass: "operation"
  }, [_c("div", [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "姓名/身份证号"
    },
    on: {
      search: _vm.inputChange
    }
  }), _vm.item.isMerge ? _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "用工单位",
      "allow-clear": ""
    },
    on: {
      change: _vm.handleChange
    }
  }, _vm._l(_vm.templatesList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: index
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1) : _vm._e()], 1), _c("div", [_c("a-button", {
    attrs: {
      disabled: _vm.item.approStatus !== 5 && _vm.item.approStatus !== 0 || _vm.item.isMerge,
      type: "primary",
      ghost: ""
    },
    on: {
      click: function click() {
        _vm.BillConfirm = true;
      }
    }
  }, [_vm._v("账单结算")]), _c("a-button", {
    staticStyle: {
      "margin-left": "15px"
    },
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: function click() {
        return _vm.download();
      }
    }
  }, [_vm._v("全部导出")]), _c("a-button", {
    staticStyle: {
      "margin-left": "15px"
    },
    attrs: {
      disabled: _vm.item.approStatus === 1 || _vm.item.approStatus === 3,
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.shengpiHand
    }
  }, [_vm._v(" 发起审批 ")])], 1)]), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      "row-key": "defaultType",
      columns: _vm.columns,
      "data-source": _vm.dataList,
      pagination: _vm.pagination,
      scroll: {
        x: 1200
      }
    },
    on: {
      change: _vm.handleTableChange
    }
  })], 1)]), _c("a-modal", {
    attrs: {
      title: "发起审批",
      visible: _vm.isMergConfirmPlan,
      width: "500px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.mergConfirmOk
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", [_c("a-form", {
    attrs: {
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "账单时间",
      required: ""
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "500",
      color: "#000"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.year + "年" + _vm.item.month + "月") + " ")])]), _c("a-form-item", {
    attrs: {
      label: "审批账单类型",
      required: ""
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "500",
      color: "#000"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.subjectType) + " ")])]), _c("a-form-item", {
    attrs: {
      label: "用工单位",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "请选择用工单位"
    },
    on: {
      change: _vm.handleCompyChange
    },
    model: {
      value: _vm.companyId,
      callback: function callback($$v) {
        _vm.companyId = $$v;
      },
      expression: "companyId"
    }
  }, _vm._l(_vm.comanyListGroup, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "账单审批模板",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "请选择审批模板",
      disabled: !_vm.companyId
    },
    on: {
      change: _vm.handleChange2
    },
    model: {
      value: _vm.tempTemplate,
      callback: function callback($$v) {
        _vm.tempTemplate = $$v;
      },
      expression: "tempTemplate"
    }
  }, _vm._l(_vm.templatesListGroup, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.typeName) + " ")]);
  }), 1)], 1)], 1)], 1)], 2), _c("a-modal", {
    attrs: {
      title: "账单结算",
      visible: _vm.BillConfirm,
      width: "560px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.BillConfirmOk
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "center",
      "margin-bottom": "20px",
      "font-size": "15px",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.item ? _vm.item.tempCompanyName + _vm.item.billName : "") + " ")]), _c("a-form", {
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [(_vm.item ? _vm.getTitle(_vm.item.subjectType, 0) !== "计薪人数" : false) ? _c("a-form-item", {
    attrs: {
      label: "人数"
    }
  }, [_c("a-input", {
    attrs: {
      suffix: "人",
      disabled: true,
      "default-value": _vm.item ? _vm.getTitle(_vm.item.subjectType, 0) !== "计薪人数" ? _vm.item.number : _vm.getCost(_vm.item, 0) : 0
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: _vm.item ? _vm.getTitle(_vm.item.subjectType, 0) : ""
    }
  }, [_c("a-input", {
    attrs: {
      suffix: "元",
      disabled: true,
      "default-value": _vm.item ? _vm.getCost(_vm.item, 0) : 0
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: _vm.item ? _vm.getTitle(_vm.item.subjectType, 1) : ""
    }
  }, [_c("a-input", {
    attrs: {
      suffix: "元",
      disabled: true,
      "default-value": _vm.item ? _vm.getCost(_vm.item, 1) : 0
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: _vm.item ? _vm.getTitle(_vm.item.subjectType, 2) : ""
    }
  }, [_c("a-input", {
    attrs: {
      suffix: "元",
      disabled: true,
      "default-value": _vm.item ? _vm.getCost(_vm.item, 2) : 0
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "实际付款额"
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      suffix: "元",
      "default-value": _vm.onBillChange(_vm.item ? _vm.getCost(_vm.item, 2) : 0)
    },
    on: {
      change: _vm.onBillChange
    }
  })], 1)], 1)], 1)], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };