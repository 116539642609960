import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.slice.js";
var columns = [{
  title: "所属分组",
  dataIndex: "groupName",
  key: "groupName",
  width: 100,
  scopedSlots: {
    customRender: "groupName"
  }
}, {
  title: "渠道名称",
  dataIndex: "name",
  key: "name",
  width: 100
}, {
  title: "联系电话",
  dataIndex: "phone",
  key: "phone",
  width: 120
}, {
  title: "所在地区",
  dataIndex: "addressCopy",
  key: "addressCopy",
  width: 120
}, {
  title: "身份证号",
  dataIndex: "idcard",
  key: "idcard",
  width: 120
}, {
  title: "银行卡号",
  dataIndex: "bankNo",
  key: "bankNo",
  width: 140
}, {
  title: "渠道招工人数",
  dataIndex: "numberPeople",
  key: "numberPeople",
  width: 100,
  scopedSlots: {
    customRender: "numberPeople"
  }
}, {
  title: "操作",
  dataIndex: "action",
  key: "action",
  scopedSlots: {
    customRender: "action"
  },
  align: "left",
  width: 150
}];
var columns2 = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: 100
}, {
  title: "联系电话",
  dataIndex: "phone",
  key: "phone",
  width: 120
}, {
  title: "最近入职时间",
  dataIndex: "joinDate",
  key: "joinDate",
  width: 150,
  scopedSlots: {
    customRender: "joinDate"
  }
}, {
  title: "当前用工单位",
  dataIndex: "companyName",
  key: "companyName",
  width: 150,
  scopedSlots: {
    customRender: "companyName"
  }
}, {
  title: "人员状态",
  dataIndex: "status",
  key: "status",
  width: 100,
  scopedSlots: {
    customRender: "status"
  }
}];
var columnsEmp = [{
  title: "管理员",
  dataIndex: "userName",
  key: "userName",
  width: 100,
  scopedSlots: {
    customRender: "userName"
  }
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  width: 100,
  scopedSlots: {
    customRender: "phone"
  }
}, {
  title: "操作",
  dataIndex: "action",
  key: "action",
  width: 100,
  align: "center",
  scopedSlots: {
    customRender: "action"
  }
}];
import { mapState } from "vuex";
import { getChannels2, addChannels, editChannels, deleteChannels, getPrePersonnel, findCompanyGroup as _findCompanyGroup, getByUserId as _getByUserId, getHrRole as _getHrRole, addGroup, getAllGroup, editGroup, deleteGroup as _deleteGroup, getUserByGroupId as _getUserByGroupId, addGroupEmp, deleteEmp as _deleteEmp } from "@/api/outsourcing/outsourcing";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
import { AreaPicker as city } from "xqjr-plugin-common";
import axios from "axios";
import qs from "qs";
var mixin2 = new ExportMixin();
var mixin = new UploadMixin();
var treeData = [];
function isChecked(selectedKeys, eventKey) {
  return selectedKeys.indexOf(eventKey) !== -1;
}
function handleTreeData(data) {
  var targetKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  data.forEach(function (item) {
    item["disabled"] = targetKeys.includes(item.key);
    if (item.children) {
      handleTreeData(item.children, targetKeys);
    }
  });
  return data;
}
export default {
  mixins: [mixin, mixin2],
  data: function data() {
    var validatorPhone = function validatorPhone(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入"));
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error("手机号格式不正确!"));
        } else {
          callback();
        }
      }
    };
    var validatorIdCard = function validatorIdCard(rule, value, callback) {
      if (!value) {
        callback();
      } else {
        if (!/^((^[HMhm](\d{8}|\d{10})$)|(^(\d{8}|^[a-zA-Z]{1}(\d{7}))$)|(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx]$)))$/.test(value)) {
          callback(new Error("身份证号格式不正确!"));
        } else {
          callback();
        }
      }
    };
    return {
      replaceFields: {
        children: "companyList",
        title: "name",
        key: "id",
        value: "id"
      },
      groupTitle: "添加分组",
      targetKeys: [],
      empList: [],
      rolesList: [],
      columnsEmp: columnsEmp,
      isChooseStaff: false,
      groupDescribe: undefined,
      groupName: undefined,
      loading: false,
      validatorPhone: validatorPhone,
      showItemDetail: true,
      validatorIdCard: validatorIdCard,
      columns2: columns2,
      onSearchEmpName: undefined,
      title2: null,
      channelId: null,
      title: "新增渠道",
      params: {
        templateName: "channelInfoImportTemplate.xlsx"
      },
      url2: "/hr/api/v1/excel/template/download",
      excelName: "渠道上传模板.xlsx",
      visible: false,
      deleteVisible: false,
      childCompanyIdList: [],
      employerIdList: [],
      dataList: [],
      dataListEmp: [],
      dataList2: [],
      treeData2: [],
      allGroupList: {},
      columns: columns,
      detailVisible: false,
      form: {},
      dataSource: [],
      groupId: "",
      groupNameOrUserName: null,
      isLevel: false,
      status: null,
      selectItem: {},
      channelName: null,
      params2: {},
      nameOrPhoneOrIdcard: null,
      labelCol: {
        span: 8
      },
      addressForSeach: undefined,
      treeVisible: false,
      wrapperCol: {
        span: 12
      },
      groupVisible: false,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        editCompanyName: null,
        ticketRecord: null,
        receivableRecord: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      pagination2: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        editCompanyName: null,
        ticketRecord: null,
        receivableRecord: null
      }
    };
  },
  watch: {
    "form.idcard": function formIdcard(val) {
      this.form.idcard = val ? val.toUpperCase() : null;
    }
  },
  components: {
    city: city,
    EmployeeSelect: function EmployeeSelect() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./addEmp"));
      });
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState(["common"])), {}, {
    cityList: function cityList() {
      return this.common.cityList;
    },
    areaList: function areaList() {
      return this.common.areaList;
    },
    treeData: {
      get: function get() {
        return treeData;
      },
      set: function set(val) {
        return handleTreeData(treeData, this.targetKeys);
      }
    }
  }),
  created: function created() {
    this.getHrRole();
    this.geRoles();
    this.findCompanyGroup();
    this.action = "/hr/api/v1/channels/importChannel";
    this.getAllGroupList();
  },
  methods: {
    handleCityForSeach: function handleCityForSeach(e) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.addressForSeach = e.value[e.value.length - 1];
      this.getChannelsList();
    },
    deleteEmp: function deleteEmp(e) {
      var that = this;
      this.$confirm({
        title: "确认删除吗?",
        onOk: function onOk() {
          _deleteEmp({
            managerId: e.id,
            jurisdiction: that.selectItem.name === "默认分组" ? 1 : that.selectItem.isAll ? 2 : -1
          }).then(function (res) {
            that.$notification["success"]({
              message: "success",
              description: "删除成功"
            });
            that.getUserByGroupId();
          });
        },
        class: "test"
      });
    },
    onEmployeeSelectChange: function onEmployeeSelectChange(e) {
      var _this = this;
      var userIdList = [];
      e.forEach(function (item) {
        userIdList.push(item.id);
      });
      addGroupEmp({
        channelGroupId: this.selectItem.id,
        userIdList: userIdList
      }).then(function (res) {
        _this.$notification["success"]({
          message: "success",
          description: "新增成功"
        });
        _this.getUserByGroupId();
        _this.empList = [];
      });
    },
    onSearchEmp: function onSearchEmp() {
      this.getUserByGroupId();
    },
    showEmp: function showEmp() {
      this.showItemDetail = false;
    },
    onSearch: function onSearch() {
      this.getAllGroupList();
    },
    showItem: function showItem(item, indexs) {
      this.showItemDetail = true;
      this.allGroupList.selectIng = false;
      this.allGroupList.channelGroupVOList.forEach(function (item, index) {
        if (index === indexs) {
          item.selectIng = true;
        } else {
          item.selectIng = false;
        }
      });
      this.selectItem = item;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getChannelsList();
      this.getUserByGroupId();
    },
    getUserByGroupId: function getUserByGroupId() {
      var _this2 = this;
      _getUserByGroupId({
        groupId: this.selectItem.id,
        jurisdiction: this.selectItem.name === "默认分组" ? 1 : this.selectItem.isAll ? 2 : -1,
        userName: this.onSearchEmpName
      }).then(function (res) {
        _this2.dataListEmp = res.data;
      });
    },
    showSiteItem: function showSiteItem() {
      this.showItemDetail = true;
      this.selectItem = this.allGroupList;
      this.allGroupList.selectIng = true;
      this.allGroupList.channelGroupVOList.forEach(function (item, index) {
        item.selectIng = false;
      });
      this.selectItem.isAll = true;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getChannelsList();
      this.getUserByGroupId();
    },
    getAllGroupList: function getAllGroupList() {
      var _this3 = this;
      getAllGroup({
        groupNameOrUserName: this.groupNameOrUserName
      }).then(function (res) {
        _this3.allGroupList = res.data;
        _this3.allGroupList.selectIng = true;
        _this3.selectItem = _this3.allGroupList;
        _this3.selectItem.isAll = true;
        _this3.getChannelsList();
        _this3.getUserByGroupId();
      });
    },
    updateAllGroupList: function updateAllGroupList() {
      var _this4 = this;
      getAllGroup({
        groupNameOrUserName: this.groupNameOrUserName
      }).then(function (res) {
        _this4.allGroupList = res.data;
        _this4.allGroupList.channelGroupVOList.forEach(function (item, index) {
          if (item.id === _this4.selectItem.id) {
            item.selectIng = true;
          } else {
            item.selectIng = false;
          }
        });
      });
    },
    addGroupOk: function addGroupOk() {
      var _this5 = this;
      if (!this.groupName) {
        this.$message.warning("分组名称必填！");
      } else {
        if (this.groupTitle === "添加分组") {
          addGroup({
            name: this.groupName,
            level: 2,
            describe: this.groupDescribe
          }).then(function (res) {
            _this5.$notification["success"]({
              message: "success",
              description: "新增成功"
            });
            _this5.getAllGroupList();
            _this5.groupVisible = false;
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        } else if (this.groupTitle === "编辑分组") {
          editGroup({
            name: this.groupName,
            level: this.isLevel ? 1 : 2,
            describe: this.groupDescribe,
            id: this.groupId
          }).then(function (res) {
            _this5.$notification["success"]({
              message: "success",
              description: "编辑成功"
            });
            _this5.getAllGroupList();
            _this5.groupVisible = false;
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        }
      }
    },
    deleteGroup: function deleteGroup() {
      var _this6 = this;
      _deleteGroup(this.groupId).then(function (res) {
        _this6.$notification["success"]({
          message: "success",
          description: "删除成功"
        });
        _this6.getAllGroupList();
        _this6.groupVisible = false;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    showAddGroup: function showAddGroup() {
      this.groupVisible = true;
      this.groupTitle = "添加分组";
      this.groupName = undefined;
      this.groupDescribe = undefined;
      this.isLevel = false;
    },
    showEditGroup: function showEditGroup(item) {
      this.isLevel = false;
      this.groupVisible = true;
      this.groupTitle = "编辑分组";
      this.groupName = item.name;
      this.groupDescribe = item.describe;
      this.groupId = item.id;
      if (item.jurisdiction) {
        this.isLevel = true;
      } else {
        this.isLevel = false;
      }
    },
    getHrRole: function getHrRole() {
      var _this7 = this;
      _getHrRole().then(function (res) {
        _this7.hrRoleId = res.data.id;
      });
    },
    beforeUpload: function beforeUpload(file) {
      var _this8 = this;
      if (this.fileList.length > 0) {
        this.$message("最多只能上传一个");
        return false;
      }
      this.fileList = [].concat(_toConsumableArray(this.fileList), [file]);
      if (this.fileList && this.fileList.length > 0) {
        var fileList = this.fileList;
        var formData = new FormData();
        fileList.forEach(function (file) {
          formData.append("file", file);
        });
        this.uploading = true;
        axios({
          url: this.action,
          method: "post",
          data: formData,
          headers: this.headers
        }).then(function (res) {
          _this8.getChannelsList();
          _this8.$store.dispatch("hr/loadChannelsList");
          _this8.reset();
          _this8.uploading = false;
        });
      } else {
        this.reset();
        this.handleUploadError();
      }
      return false;
    },
    download2: function download2() {
      var _this9 = this;
      var token = this.$store.state.auth.token;
      axios({
        url: this.url2,
        params: this.params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", _this9.excelName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    goUpload: function goUpload() {
      this.$router.push({
        path: "/admin/management/account/upload"
      });
    },
    exportFile: function exportFile() {
      this.params2 = {
        nameOrPhone: this.channelName ? this.channelName : null,
        level: this.selectItem.isAll ? 1 : 2,
        channelGroupId: this.selectItem.id
      };
      this.url = "/hr/api/v1/channels/channelInfoExport";
      this.excelName = "\u6E20\u9053\u8BE6\u60C5.xlsx";
      this.getDownload();
    },
    getDownload: function getDownload() {
      var _this10 = this;
      axios({
        url: this.url,
        params: this.params2,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: this.token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", _this10.excelName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 递归替换字段
    addTreeSlot: function addTreeSlot(data) {
      var _this11 = this;
      if (!data) {
        return [];
      }
      data.forEach(function (el) {
        if (el.type === "site") {
          el.disabled = true;
          el.key = "-1";
        } else {
          el.key = el.id;
        }
        el.title = el.name;
        _this11.addTreeSlot(el.companyList);
      });
      return data;
    },
    getByUserId: function getByUserId() {
      var _this12 = this;
      _getByUserId({
        userId: this.detail.id
      }).then(function (res) {
        _this12.choseEmp = res.data.isAllChildCompany;
        _this12.targetKeys = res.data.companyIds.concat(res.data.groupIds);
      });
    },
    handleOk: function handleOk(e) {
      this.treeVisible = false;
    },
    findCompanyGroup: function findCompanyGroup() {
      var _this13 = this;
      _findCompanyGroup().then(function (res) {
        _this13.treeData2 = _this13.addTreeSlot(res.data);
        if (_this13.treeData2[0] && _this13.treeData2[0].type === "site") {
          _this13.disabled2 = false;
        } else {
          _this13.disabled2 = true;
        }
        treeData = _this13.treeData2;
        _this13.flatten(_this13.treeData2);
        if (_this13.editor) {
          _this13.detail = _this13.$route.query;
          _this13.getByUserId();
          _this13.copyDetail = _objectSpread({}, _this13.$route.query);
          if (!_this13.detail.employerIdList) {
            _this13.detail.employerIdList = [];
          }
        }
        var formKey = {};
        Object.keys(_this13.form).forEach(function (key) {
          formKey[key] = _this13.detail[key];
        });
        _this13.form = _objectSpread(_objectSpread({}, _this13.form), formKey);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    flatten: function flatten(list) {
      var _this14 = this;
      if (list !== null) {
        list.forEach(function (item) {
          _this14.dataSource.push(item);
          _this14.flatten(item.companyList);
        });
      }
    },
    onChecked: function onChecked(_, e, checkedKeys, itemSelect) {
      var eventKey = e.node.eventKey;
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
    },
    showModal: function showModal() {
      this.targetKeys = JSON.parse(JSON.stringify(this.targetKeys));
      this.treeVisible = true;
    },
    onTransChange: function onTransChange(targetKeys) {
      this.targetKeys = targetKeys;
    },
    // 获取账号角色列表
    geRoles: function geRoles() {
      var _this15 = this;
      this.$request({
        method: "GET",
        url: "/hr/api/v1/hrRoles/listAll",
        params: {
          subjectType: "company"
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this15.rolesList = data;
      });
    },
    // 查询详情
    showDetail: function showDetail(e) {
      this.nameOrPhoneOrIdcard = undefined;
      this.status = undefined;
      this.title2 = e.name;
      this.channelId = e.id;
      this.pagination2.pageSize = 10;
      this.pagination2.current = 1;
      this.getPrePersonnelList();
    },
    inputChange2: function inputChange2(e) {
      this.pagination2.pageSize = 10;
      this.pagination2.current = 1;
      this.nameOrPhoneOrIdcard = e;
      this.getPrePersonnelList();
    },
    selectCheck2: function selectCheck2(e) {
      this.status = e;
      this.pagination2.pageSize = 10;
      this.pagination2.current = 1;
      this.getPrePersonnelList();
    },
    getPrePersonnelList: function getPrePersonnelList() {
      var _this16 = this;
      getPrePersonnel({
        isShowOtherInfo: true,
        "orders[0].property": "status",
        "orders[0].desc": false,
        nameOrPhoneOrIdcard: this.nameOrPhoneOrIdcard ? this.nameOrPhoneOrIdcard : null,
        status: this.status ? this.status : null,
        channelId: this.channelId ? this.channelId : null,
        pageNo: this.pagination2.current,
        pageSize: this.pagination2.pageSize
      }).then(function (res) {
        _this16.detailVisible = true;
        _this16.dataList2 = res.data.entities;
        _this16.pagination2.total = res.data && res.data.entityCount;
      });
    },
    detailCancel: function detailCancel() {
      this.detailVisible = false;
    },
    // 删除渠道
    showDelete: function showDelete(e) {
      this.deleteId = e.id;
      this.deleteVisible = true;
    },
    deleteOk: function deleteOk(e) {
      var _this17 = this;
      deleteChannels(this.deleteId).then(function (res) {
        _this17.$notification["success"]({
          message: "success",
          description: "删除成功"
        });
        _this17.pagination.pageSize = 10;
        _this17.pagination.current = 1;
        _this17.deleteVisible = false;
        _this17.getChannelsList();
        _this17.updateAllGroupList();
        _this17.$store.dispatch("hr/loadChannelsList");
      });
    },
    handleCancel: function handleCancel() {
      this.deleteVisible = false;
    },
    handleCity: function handleCity(e) {
      var addressChinese = e.label.join("/");
      this.form.address = "".concat(e.value[e.value.length - 1], ",") + addressChinese;
    },
    // 编辑渠道
    handleEditOk: function handleEditOk(e) {
      var _this18 = this;
      this.targetKeys = [];
      if (e.companyIds && e.companyIds.length) {
        this.targetKeys = this.targetKeys.concat(e.companyIds);
      }
      if (e.groupIds && e.groupIds.length) {
        this.targetKeys = this.targetKeys.concat(e.groupIds);
      }
      this.title = "编辑渠道";
      this.form = {
        isAllChildCompany: e.isAllChildCompany
      };
      this.visible = true;
      console.log(e);
      this.$nextTick(function () {
        _this18.$refs.ruleForm.clearValidate();
        _this18.form = JSON.parse(JSON.stringify(e));
      });
    },
    // 新增编辑渠道点击确定
    handleAddOK: function handleAddOK() {
      var _this19 = this;
      this.childCompanyIdList = [];
      this.employerIdList = [];
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this19.targetKeys.forEach(function (item, index) {
            _this19.dataSource.forEach(function (item2, index2) {
              if (item === item2.id && item2.type === "group") {
                _this19.childCompanyIdList.push(item);
              }
              if (item === item2.id && item2.type === "company") {
                _this19.employerIdList.push(item);
              }
            });
          });
          for (var i = 0; i < _this19.childCompanyIdList.length; i++) {
            for (var j = i + 1; j < _this19.childCompanyIdList.length; j++) {
              if (_this19.childCompanyIdList[i] === _this19.childCompanyIdList[j]) {
                // 如果第一个等于第二个，splice方法删除第二个
                _this19.childCompanyIdList.splice(j, 1);
                j--;
              }
            }
          }
          for (var o = 0; o < _this19.employerIdList.length; o++) {
            for (var p = o + 1; p < _this19.employerIdList.length; p++) {
              if (_this19.employerIdList[o] === _this19.employerIdList[p]) {
                // 如果第一个等于第二个，splice方法删除第二个
                _this19.employerIdList.splice(p, 1);
                p--;
              }
            }
          }
          if (_this19.title === "新增渠道") {
            addChannels(_objectSpread(_objectSpread({}, _this19.form), {}, {
              password: _this19.form.phone,
              hrRoleId: _this19.hrRoleId,
              userName: _this19.form.name,
              childCompanyIdList: _this19.childCompanyIdList,
              employerIdList: _this19.employerIdList
            })).then(function (res) {
              _this19.$notification["success"]({
                message: "success",
                description: "添加成功"
              });
              _this19.visible = false;
              _this19.getChannelsList();
              _this19.updateAllGroupList();
            });
          }
          if (_this19.title === "编辑渠道") {
            if (_this19.form.address && _this19.form.address.indexOf(",") === -1) {
              var values = _this19.buildValue(_this19.getLastAreaId(_this19.form.address));
              var labels = values.map(function (item) {
                return item.label;
              });
              _this19.form.address = "".concat(_this19.form.address, ",").concat(labels.join("/"));
            }
            editChannels(_objectSpread(_objectSpread({}, _this19.form), {}, {
              childCompanyIdList: _this19.childCompanyIdList,
              employerIdList: _this19.employerIdList
            })).then(function (res) {
              _this19.$notification["success"]({
                message: "success",
                description: "编辑成功"
              });
              _this19.visible = false;
              _this19.getChannelsList();
            });
          }
          _this19.$store.dispatch("hr/loadChannelsList");
        } else {
          return false;
        }
      });
    },
    buildValue: function buildValue(id) {
      var needRecursion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      id = parseInt(id);
      var index = this.common.areaSourceList.findIndex(function (item) {
        return item.id === parseInt(id);
      });
      if (index > -1) {
        var item = this.common.areaSourceList[index];
        var val = {
          value: id,
          label: item.name
        };
        if (needRecursion && item.parentId > 0) {
          return this.buildValue(item.parentId).concat([val]);
        } else {
          return [val];
        }
      }
      return [];
    },
    // 分页查询渠道
    getChannelsList: function getChannelsList() {
      var _this20 = this;
      this.loading = true;
      getChannels2({
        nameOrPhone: this.channelName ? this.channelName : null,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        channelGroupId: this.selectItem.isAll ? undefined : this.selectItem.id,
        level: this.selectItem.isAll ? 1 : 2,
        address: this.addressForSeach
      }).then(function (res) {
        _this20.loading = false;
        _this20.dataList = res.data.entities;
        _this20.dataList.forEach(function (element) {
          if (element.address) {
            if (element.address) {
              var addressCopy = element.address.split(",")[0];
              element.address = addressCopy;
              var values = _this20.buildValue(_this20.getLastAreaId(addressCopy));
              var labels = values.map(function (item) {
                return item.label;
              });
              element.addressCopy = labels.join("/");
            }
          }
        });
        _this20.pagination.total = res.data && res.data.entityCount;
      });
    },
    getLastAreaId: function getLastAreaId(values) {
      if (Array.isArray(values)) {
        return values[values.length - 1];
      } else {
        return values.split(",").slice(-1)[0];
      }
    },
    // 控制新增编辑框
    showAdd: function showAdd() {
      var _this21 = this;
      this.title = "新增渠道";
      this.visible = true;
      this.$nextTick(function () {
        // this.$refs.ruleForm.clearValidate();
        _this21.form = {
          channelGroupId: _this21.selectItem.isAll ? undefined : _this21.selectItem.id,
          isAllChildCompany: true
        };
        _this21.$refs.ruleForm.resetFields();
        _this21.targetKeys = [];
      });
    },
    onClose: function onClose() {
      this.visible = false;
      this.form = {};
    },
    onGroupClose: function onGroupClose() {
      this.groupVisible = false;
    },
    // 搜索框
    inputChange: function inputChange(e) {
      this.channelName = e;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getChannelsList();
    },
    // 分页器
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getChannelsList();
    },
    handleTableChange2: function handleTableChange2(pagination, filters, sorter) {
      this.pagination2.current = pagination.current;
      this.pagination2.pageSize = pagination.pageSize;
      this.getPrePersonnelList();
    }
  }
};