import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", _vm._l(_vm.data, function (item, i) {
    return _c("div", {
      key: i,
      staticClass: "mg_t10"
    }, [_c("div", {
      staticClass: "name pd_l10",
      on: {
        click: function click($event) {
          return _vm.chose(item, _vm.data);
        }
      }
    }, [item.isDept ? _c("a-icon", {
      attrs: {
        type: "folder"
      }
    }) : _vm._e(), !item.isDept ? _c("a-icon", {
      attrs: {
        type: "user"
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(item.name) + " "), item.isSelected ? _c("a-icon", {
      attrs: {
        type: "check"
      }
    }) : _vm._e()], 1), item.childList ? [_c("report-tree", {
      staticClass: "mg_l20",
      attrs: {
        data: item.childList
      }
    })] : _vm._e()], 2);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };