import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "overview-staff-wrapper"
  }, [_c("div", {
    staticClass: "headBox"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("selectCompanyGroup"), _c("a-month-picker", {
    staticStyle: {
      "margin-left": "32px"
    },
    attrs: {
      allowClear: false,
      placeholder: "选择月份"
    },
    on: {
      change: _vm.onMonthChange
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showAdd
    }
  }, [_c("a-icon", {
    attrs: {
      type: "container"
    }
  }), _vm._v("生成明细表")], 1)], 1), _c("a-modal", {
    attrs: {
      title: "生成明细表"
    },
    on: {
      ok: _vm.handleAddOk
    },
    model: {
      value: _vm.addVisible,
      callback: function callback($$v) {
        _vm.addVisible = $$v;
      },
      expression: "addVisible"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      model: _vm.addForm,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "date",
    attrs: {
      rules: {
        required: true,
        message: "请选择"
      },
      label: "明细表月份",
      prop: "date"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择月份"
    },
    model: {
      value: _vm.addForm.date,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "date", $$v);
      },
      expression: "addForm.date"
    }
  })], 1), _c("a-form-model-item", {
    ref: "companyInfoList",
    attrs: {
      rules: {
        required: true,
        message: "请选择"
      },
      label: "选择用工单位",
      prop: "companyInfoList"
    }
  }, [_c("a-tree-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      "tree-data": _vm.companyDataList,
      filterTreeNode: false,
      replaceFields: _vm.replaceFields2,
      labelInValue: "",
      "tree-checkable": "",
      placeholder: "请选择"
    },
    on: {
      search: _vm.treeSearch
    },
    model: {
      value: _vm.addForm.companyInfoList,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "companyInfoList", $$v);
      },
      expression: "addForm.companyInfoList"
    }
  })], 1), _c("a-form-model-item", {
    ref: "type",
    attrs: {
      rules: {
        required: true,
        message: "请选择"
      },
      label: "明细表类型",
      prop: "type"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.addForm.type,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "type", $$v);
      },
      expression: "addForm.type"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "customer"
    }
  }, [_vm._v(" 客户明细表 ")]), _c("a-select-option", {
    attrs: {
      value: "salary"
    }
  }, [_vm._v(" 薪资明细表 ")]), _c("a-select-option", {
    attrs: {
      value: "social"
    }
  }, [_vm._v(" 社保明细表 ")])], 1)], 1), _vm.addForm.type === "customer" || _vm.addForm.type === "salary" ? _c("a-form-model-item", {
    ref: "date",
    attrs: {
      rules: {
        required: true,
        message: "请选择"
      },
      label: "薪资数据来源月份",
      prop: "date"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择月份"
    },
    model: {
      value: _vm.addForm.salaryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "salaryDate", $$v);
      },
      expression: "addForm.salaryDate"
    }
  })], 1) : _vm._e(), _vm.addForm.type === "customer" || _vm.addForm.type === "social" ? _c("a-form-model-item", {
    ref: "date",
    attrs: {
      rules: {
        required: true,
        message: "请选择"
      },
      label: "社保数据来源月份",
      prop: "date"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请选择月份"
    },
    model: {
      value: _vm.addForm.socialDate,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "socialDate", $$v);
      },
      expression: "addForm.socialDate"
    }
  })], 1) : _vm._e()], 1)], 1), _c("div", {
    staticStyle: {
      display: "flex",
      padding: "8px 0 12px 0"
    }
  }, [_c("div", {
    class: _vm.tabButtonKehu ? "tabButton" : "tabButtonNot",
    on: {
      click: function click($event) {
        return _vm.tabChange(3);
      }
    }
  }, [_vm._v("客户明细")]), _c("div", {
    class: _vm.tabButtonXinzi ? "tabButton" : "tabButtonNot",
    on: {
      click: function click($event) {
        return _vm.tabChange(1);
      }
    }
  }, [_vm._v("薪资明细")]), _c("div", {
    class: _vm.tabButtonShebao ? "tabButton" : "tabButtonNot",
    on: {
      click: function click($event) {
        return _vm.tabChange(2);
      }
    }
  }, [_vm._v("社保明细")])]), _vm.groupList && _vm.groupList.length ? _c("div", _vm._l(_vm.groupList, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "fix-item-wrapper"
    }, [_c("div", {
      staticClass: "fix-item-one"
    }, [_c("div", {
      staticClass: "fix-item-name"
    }, [!item.isOpen && item.hrMonthlySalarySummaryVOList && item.hrMonthlySalarySummaryVOList.length > 0 ? _c("div", {
      staticClass: "showIcon",
      staticStyle: {
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.openClick(item);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "plus"
      }
    })], 1) : _vm._e(), item.isOpen && item.hrMonthlySalarySummaryVOList && item.hrMonthlySalarySummaryVOList.length > 0 ? _c("div", {
      staticClass: "showIcon",
      staticStyle: {
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.openClick(item);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "minus"
      }
    })], 1) : _vm._e(), !item.hrMonthlySalarySummaryVOList || item.hrMonthlySalarySummaryVOList.length === 0 ? _c("div", {
      staticClass: "noShowIcon",
      staticStyle: {
        cursor: "pointer"
      }
    }, [_c("a-icon", {
      attrs: {
        type: "file-text",
        theme: "filled"
      }
    })], 1) : _vm._e(), _c("a-tooltip", {
      attrs: {
        title: item.name
      }
    }, [_c("div", {
      staticStyle: {
        width: "75%",
        overflow: "hidden",
        "word-break": "break-all",
        "text-overflow": "ellipsis"
      }
    }, [_vm._v(_vm._s(item.name))])])], 1)]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", {
      staticStyle: {
        width: "25%",
        "text-align": "left"
      }
    }, [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v(" " + _vm._s(_vm.customerDetailsType === "customer" ? "本月人数" : _vm.customerDetailsType === "salary" ? "本月计薪人数" : "本月参保人数") + " ")]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.empNumber ? item.empNumber : 0) + "人")])]), _vm.customerDetailsType === "salary" ? _c("div", {
      staticStyle: {
        width: "25%",
        "text-align": "left"
      }
    }, [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v("应发工资")]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.shouldWages ? item.shouldWages : 0) + "元")])]) : _vm._e(), _vm.customerDetailsType === "salary" ? _c("div", {
      staticStyle: {
        width: "25%",
        "text-align": "left"
      }
    }, [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v("实发工资")]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.paidWages ? item.paidWages : 0) + "元")])]) : _vm._e(), _vm.customerDetailsType === "social" ? _c("div", {
      staticStyle: {
        width: "25%",
        "text-align": "left"
      }
    }, [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v("企业社保")]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.enterpriseSocialSecurity ? item.enterpriseSocialSecurity : 0) + "元")])]) : _vm._e(), _vm.customerDetailsType === "social" ? _c("div", {
      staticStyle: {
        width: "25%",
        "text-align": "left"
      }
    }, [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v("企业公积金")]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.enterpriseProvidentFund ? item.enterpriseProvidentFund : 0) + "元")])]) : _vm._e(), _vm.customerDetailsType === "customer" ? _c("div", {
      staticStyle: {
        width: "25%",
        "text-align": "left"
      }
    }, [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v("人力成本")]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.laborCost ? item.laborCost : 0) + "元")])]) : _vm._e(), _c("div", {
      staticStyle: {
        width: "25%",
        "text-align": "left"
      }
    }, [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v("服务费")]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.serviceCharge ? item.serviceCharge : 0) + "元")])]), _c("div", {
      staticStyle: {
        width: "25%",
        "text-align": "left"
      }
    }, [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v("客户费用")]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.customerFees ? item.customerFees : 0) + "元")])])]), _c("div", {
      staticClass: "fix-item-three"
    }, [_c("div", [_c("a-button", {
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          return _vm.goDetail(item);
        }
      }
    }, [_vm._v("详情")]), _c("a-popconfirm", {
      attrs: {
        title: "确定删除?"
      },
      on: {
        confirm: function confirm($event) {
          return _vm.deleteItem(item);
        }
      }
    }, [_c("a-icon", {
      staticStyle: {
        color: "red"
      },
      attrs: {
        slot: "icon",
        type: "question-circle-o"
      },
      slot: "icon"
    }), !item.hrMonthlySalarySummaryVOList || item.hrMonthlySalarySummaryVOList.length === 0 ? _c("a-button", {
      attrs: {
        type: "link"
      }
    }, [_vm._v(" 删除 ")]) : _vm._e()], 1)], 1)])]), _c("ItemCustomer", {
      attrs: {
        target: item,
        customerDetailsType: _vm.customerDetailsType,
        showDetail: false
      },
      on: {
        openClick: _vm.openClick,
        goDetail: _vm.goDetail,
        deleteItem: _vm.deleteItem
      }
    })], 1);
  }), 0) : _c("div", {
    staticStyle: {
      padding: "120px 0px 560px 0px",
      "background-color": "#f2f3f5"
    }
  }, [_c("a-empty")], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };