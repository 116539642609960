var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-center"
  }, [_c("div", {
    staticStyle: {
      width: "440px"
    }
  }, [_c("a-form-item", {
    attrs: {
      required: "",
      label: "企业服务",
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 19
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "343px"
    },
    attrs: {
      options: _vm.options,
      placeholder: "请选择"
    },
    on: {
      change: _vm.onProdIdChange
    },
    model: {
      value: _vm.prodId,
      callback: function callback($$v) {
        _vm.prodId = $$v;
      },
      expression: "prodId"
    }
  })], 1), _c("a-form-item", {
    staticClass: "custom-form-item-wrapper",
    attrs: {
      required: "",
      label: "轮播图",
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 19
      }
    }
  }, [_c("a-upload", {
    staticClass: "custom-upload",
    attrs: {
      fileList: _vm.fileList,
      name: "files",
      action: "/file/api/v1/general/upload/attachment",
      accept: ".jpg, .jpeg, .png, .bmp, .gif"
    },
    on: {
      change: _vm.onFileChange
    }
  }, [_vm.banner ? _c("div", {
    staticClass: "pointer",
    staticStyle: {
      width: "343px",
      height: "120px",
      "border-radius": "4px",
      position: "relative"
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.banner
    }
  })]) : _c("div", {
    staticClass: "pointer",
    staticStyle: {
      width: "343px",
      height: "120px",
      border: "1px dashed #e8e8e8",
      "border-radius": "4px",
      padding: "16px",
      "text-align": "center"
    }
  }, [_c("a-icon", {
    staticStyle: {
      color: "var(--primary)",
      "font-size": "24px"
    },
    attrs: {
      type: "plus-circle",
      theme: "filled"
    }
  }), _c("div", {
    staticClass: "fs12 tc-theme"
  }, [_vm._v("上传图片")]), _c("div", {
    staticClass: "fs12 mt4",
    staticStyle: {
      color: "rgba(118, 120, 133, 1)"
    }
  }, [_vm._v(" 尺寸：343x120px,图片支持jpg，png格式文件，最多1个，大小不要超过5M。 ")])], 1)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.prodId,
      expression: "!prodId"
    }],
    staticClass: "mask",
    on: {
      click: _vm.beforeUploadClick
    }
  })], 1)], 1), _c("a-popconfirm", {
    attrs: {
      title: "删除后无法恢复，确定要删除吗？"
    },
    on: {
      confirm: _vm.delItem
    }
  }, [_c("a-button", {
    staticClass: "ml8",
    attrs: {
      type: "danger",
      size: "small"
    }
  }, [_vm._v("删除")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };