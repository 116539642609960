import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import { salaryCalculateRules } from "@/api/outsourcing/outrules";
export default {
  data: function data() {
    return {
      option2: [{
        label: "月",
        value: 1
      }, {
        label: "天",
        value: 3
      }],
      name: "",
      hourAmount: "",
      hourAmountDouble: "",
      ruleType: "1",
      title: "",
      placement: "right",
      labourList: [],
      visible: false,
      checked: false,
      checked1: false,
      numList: [],
      leaveList: [],
      option: [{
        label: "月",
        value: 1
      }, {
        label: "天",
        value: 2
      }, {
        label: "工时",
        value: 3
      }]
    };
  },
  methods: {
    limitNumber: function limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      } else {
        return 0;
      }
    },
    tabsChange: function tabsChange(e) {
      this.hourAmountDouble = 0;
      this.labourList = [];
      this.numList = [];
      this.leaveList = [];
      this.checked = false;
      this.checked1 = false;
    },
    // 获取数组里的每一个时间
    onDateChange: function onDateChange(date, dateString, item) {
      item.time = dateString;
    },
    // 添加规则
    onClose: function onClose() {
      var _this = this;
      var num = 0;
      var num1 = 0;
      if (this.ruleType === "1" || this.ruleType === "4") {
        // 判断规则名称有没有
        if (this.name !== "") {
          this.showName = true;
        } else {
          this.showName = false;
          this.$message.error("请填写规则名称");
        }
        // 当规则名称存在,并且红包和倒扣没有的时候执行
        if (this.showName && !this.checked && !this.checked1) {
          this.createList();
        }
        // 判断有没有红包规则
        if (this.checked) {
          if (this.numList.length > 0) {
            this.numList.map(function (res) {
              if (res.number === null || res.units === "" || res.redPacketAmount === null) {
                _this.$message.error("红包规则内容都为必填");
              } else {
                num += 1;
              }
            });
          }
        }
        // 当规则名称和红包规则存在,并且倒扣没有的时候执行
        if (this.showName && this.checked && !this.checked1) {
          if (num === this.numList.length) {
            this.createList();
          }
        }
        // 判断有没有倒扣规则
        if (this.checked1) {
          if (this.leaveList.length > 0) {
            this.leaveList.map(function (res) {
              if (res.type === "1") {
                if (res.number === null || res.units === "" || res.deductAmount === null) {
                  _this.$message.error("倒扣规则内容都为必填");
                } else {
                  num1 += 1;
                }
              } else if (res.type === "2") {
                if (res.number === null || res.units === "" || res.newHourAmount === null) {
                  _this.$message.error("倒扣规则内容都为必填");
                } else {
                  num1 += 1;
                }
              }
            });
          }
        }
        // 当规则名称和红包、倒扣规则都存在
        if (this.showName && this.checked && this.checked1) {
          if (num1 === this.leaveList.length && num === this.numList.length) {
            this.createList();
          }
        }
        // 当规则名称和倒扣规则存在,并且红包规则没有的时候执行
        if (this.showName && this.checked1 && !this.checked) {
          if (num1 === this.leaveList.length) {
            this.createList();
          }
        }
      } else {
        if (this.name !== "") {
          var objs = {
            name: this.name,
            ruleType: 3
          };
          salaryCalculateRules(objs).then(function (res) {
            if (res.code === 200) {
              _this.$message.success("添加成功");
              _this.visible = false;
              _this.$emit("update");
            }
          });
        } else {
          this.$message.error("请填写规则名称");
        }
      }
    },
    // 添加规则方法
    createList: function createList() {
      var _this2 = this;
      var labourList = JSON.parse(JSON.stringify(this.labourList)); // 工价数组
      var leaveList = JSON.parse(JSON.stringify(this.leaveList)); // 倒扣数组
      labourList.map(function (item) {
        item.type = Number(item.type);
      });
      leaveList.map(function (item) {
        item.type = Number(item.type);
      });
      var obj = {
        name: this.name,
        hourAmountDouble: this.hourAmountDouble,
        hourAmountRule: JSON.stringify(labourList),
        leaveOfficeRule: JSON.stringify(leaveList),
        redPacketRule: JSON.stringify(this.numList),
        ruleType: 1,
        showType: this.ruleType === "4" ? 1 : 2
      };
      salaryCalculateRules(obj).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success("添加成功");
          _this2.visible = false;
          _this2.$emit("update");
        }
      });
    },
    // 添加事件
    onCreate: function onCreate() {
      this.title = "新增工价规则";
      this.visible = true;
      this.ruleType = "1";
      this.clear();
    },
    // 清空内容
    clear: function clear() {
      this.name = "";
      this.hourAmountDouble = "";
      this.checked = false;
      this.checked1 = false;
      this.labourList = [];
      this.numList = [];
      this.leaveList = [];
    },
    // 一次性红包添加事件
    onChange: function onChange(e) {
      if (e.target.checked) {
        this.numList.push({
          number: null,
          units: "",
          redPacketAmount: null
        });
        this.checked = true;
      } else {
        this.numList = [];
        this.checked = false;
      }
    },
    // 离职倒扣添加事件
    onChange1: function onChange1(e) {
      if (e.target.checked) {
        this.checked1 = true;
      } else {
        this.checked1 = false;
        this.leaveList = [];
      }
    },
    // 一次性红包数组
    createNumList: function createNumList() {
      this.numList.push({
        number: null,
        units: "",
        redPacketAmount: null
      });
    },
    // 一次性红包删除
    deleteNumList: function deleteNumList(item, index) {
      this.numList.splice(index, 1);
      if (this.numList.length === 0) {
        this.checked = false;
      }
    },
    //  离职倒扣删除
    deleteleaveList: function deleteleaveList(item, index) {
      this.leaveList.splice(index, 1);
      if (this.leaveList.length === 0) {
        this.checked1 = false;
      }
    },
    // 工价计算删除
    deletelabourList: function deletelabourList(item, index) {
      this.labourList.splice(index, 1);
    },
    // 倒扣数组
    handleMenuClick: function handleMenuClick(e) {
      if (e.key === "1") {
        this.leaveList.push({
          type: e.key,
          number: null,
          units: "",
          deductAmount: null
        }); // 不满月/天/工时倒扣工资
      } else {
        this.leaveList.push({
          type: e.key,
          number: null,
          units: "",
          newHourAmount: null
        }); // 不满月/天/工时减少全价
      }
    },
    // 工价计算数组
    handleMenuClick1: function handleMenuClick1(e) {
      if (e.key === "2") {
        this.labourList.push({
          type: e.key,
          number: null,
          units: "",
          newHourAmount: null
        }); // 不满月/天/工时倒扣工资
      } else {
        this.labourList.push({
          type: e.key,
          time: null,
          newHourAmount: null
        }); // 不满月/天/工时减少全价
      }
    }
  }
};