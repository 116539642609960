import "core-js/modules/web.timers.js";
import { getPluginInfo } from "@/api/company/index";
export default {
  name: "Toggle",
  data: function data() {
    return {
      isInstall: false,
      show: false,
      timer: "",
      dat_info: []
    };
  },
  created: function created() {
    var _this = this;
    this.$nextTick(function () {
      // this.checkPluginInfo();
      _this.timer = setInterval(_this.checkPluginInfo, 5000);
    });
  },
  beforeDestroy: function beforeDestroy() {
    // 清楚定时器
    clearInterval(this.timer);
  },
  methods: {
    getTitle: function getTitle(item) {
      switch (item.accountType) {
        case "社保":
          return item.areaName + "市人力资源和社会保障局";
        case "医保":
          return item.areaName + "市医疗保障局";
        case "公积金":
          return item.areaName + "市住房公积金保障局";
      }
    },
    checkPluginInfo: function checkPluginInfo() {
      var _this2 = this;
      if (this.$refs.plugin.getAttribute("sh") === "false") {
        this.isInstall = false;
        // console.log("插件轮询 5s 未安装");
      } else {
        this.isInstall = true;
        // console.log("插件轮询 5s 已安装");
      }
      // 检查是否安装了插件
      if (this.isInstall === true) {
        getPluginInfo().then(function (res) {
          _this2.dat_info = [];
          if (res.data != null) {
            _this2.dat_info = res.data;
          }
        });
      }
    },
    toggle: function toggle() {
      this.show = !this.show;
    },
    change: function change() {
      window.open("https://www.yuque.com/books/share/b4b7f287-9104-40ba-85bf-9d2550cae968?#");
    }
  }
};