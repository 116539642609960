import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      width: 670,
      title: "添加员工",
      visible: _vm.show
    },
    on: {
      cancel: _vm.cancel,
      ok: _vm.ok
    }
  }, [_c("div", {
    staticClass: "employee-selector-wrapper"
  }, [_c("div", {
    staticClass: "employee-selector-block"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "搜索员工"
    },
    on: {
      search: _vm.searchPeople
    }
  }), _c("div", {
    staticClass: "employee-selector"
  }, [_c("custom-tree", {
    attrs: {
      "tree-data": _vm.treeData,
      "is-radio": _vm.isRadio
    },
    on: {
      "update:treeData": function updateTreeData($event) {
        _vm.treeData = $event;
      },
      "update:tree-data": function updateTreeData($event) {
        _vm.treeData = $event;
      },
      change: _vm.onChange
    }
  })], 1)], 1), _c("div", {
    staticClass: "employee-selector-block"
  }, [_c("div", {
    staticClass: "employee-selector-title"
  }, [_vm._v("已经选择的员工(" + _vm._s(_vm.selectedListTemp.length) + ")")]), _vm.isRadio !== true ? _c("div", {
    staticClass: "employee-selector"
  }, _vm._l(_vm.selectedListTemp, function (item, i) {
    return _c("div", {
      key: i,
      staticClass: "employee-selector-item"
    }, [_c("div", [_c("a-icon", {
      staticStyle: {
        "margin-right": "6px"
      },
      attrs: {
        type: "user"
      }
    }), _vm._v(" " + _vm._s(item.name) + " ")], 1), _c("a-icon", {
      attrs: {
        type: "close"
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(item);
        }
      }
    })], 1);
  }), 0) : _vm._e(), _vm.isRadio === true ? _c("div", {
    staticClass: "employee-selector"
  }, _vm._l(_vm.selectedListTemp, function (item, i) {
    return _c("div", {
      key: i,
      staticClass: "employee-selector-item"
    }, [_c("div", [_c("a-icon", {
      staticStyle: {
        "margin-right": "6px"
      },
      attrs: {
        type: "user"
      }
    }), _vm._v(" " + _vm._s(item.name) + " ")], 1), _c("a-icon", {
      attrs: {
        type: "close"
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(item);
        }
      }
    })], 1);
  }), 0) : _vm._e()])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };