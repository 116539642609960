import request from "@/utils/request";
export function getAreas(level) {
  return request({
    url: "/tax/api/v1/areas/level/".concat(level),
    method: "GET"
  });
}
export function getCompanyAuthoritiesListAll(params) {
  return request({
    url: "/tax/api/v1/taxCompanies/listTree",
    method: "GET",
    params: params
  });
}