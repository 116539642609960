import request from "@/utils/request";
export function getJobSeekers(talentPoolId) {
  return request({
    url: "/recruit2/api/v1/talentPools/".concat(talentPoolId),
    method: "GET"
  });
}
export function getJobSeekers2(talentPoolId) {
  return request({
    url: "/recruit2/api/v1/talentPools/selectByIdOnSystem/".concat(talentPoolId),
    method: "GET"
  });
}
export function getInfoById(deliverRecordId) {
  return request({
    // url: `/recruit2/api/v1/talentPools/${talentPoolId}`,
    url: "/recruit2/api/v1/deliverRecords/getInfoById/".concat(deliverRecordId),
    method: "GET"
  });
}
export function getOperationJobSeekerRecords(params) {
  return request({
    // url: `/recruit2/api/v1/talentPools/listAll`,
    url: "/recruit2/api/v1/operationJobSeekerRecords/listAll",
    method: "GET",
    params: params
  });
}
export function getRecruitInfos(params) {
  return request({
    url: "/recruit2/api/v1/recruitInfos/listAllByIsCancel",
    method: "GET",
    params: params
  });
}
export function postDeliverRecords(data) {
  return request({
    url: "/recruit2/api/v1/deliverRecords",
    method: "post",
    data: data
  });
}
export function putDeliverRecords(data) {
  return request({
    url: "/recruit2/api/v1/deliverRecords",
    method: "put",
    data: data
  });
}
export function postAddDeliverRecord(data) {
  return request({
    url: "/recruit2/api/v1/deliverRecords/addDeliverRecord",
    method: "post",
    data: data
  });
}
export function putJobSeekers(data) {
  return request({
    url: "/recruit2/api/v1/talentPools",
    method: "put",
    data: data
  });
}
export function postArrangeAnInterview(data) {
  return request({
    url: "/recruit2/api/v1/interviews/arrangeAnInterview",
    method: "post",
    data: data
  });
}
export function getNoSiteId(interviewId) {
  return request({
    url: "/recruit2/api/v1/interviews/getInterviewInfo/".concat(interviewId),
    method: "GET"
  });
}
export function getDeliverRecords(deliverRecordId) {
  return request({
    url: "/recruit2/api/v1/deliverRecords/".concat(deliverRecordId),
    method: "GET"
  });
}
export function getRecruitNameByJobSeekerId(jobSeekerId) {
  return request({
    url: "/recruit2/api/v1/deliverRecords/getRecruitNameByJobSeekerId/".concat(jobSeekerId),
    method: "GET"
  });
}
export function getListByParentId0() {
  return request({
    url: "/admin/api/v1/areas/getListByParentId/0",
    method: "GET"
  });
}
export function getListByParentId1(val) {
  return request({
    url: "/admin/api/v1/areas/getListByParentId/".concat(val),
    method: "GET"
  });
}
export function deliverRecordsAddJob(data) {
  return request({
    url: "/recruit2/api/v1/deliverRecords/addJob",
    method: "post",
    data: data
  });
}