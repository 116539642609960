import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { findSocProgRelation, deleteSocProgs } from "@/api/shebao/shebaoMethod.js";
import { isContainByString } from "@/utils/index";
var columns = [{
  dataIndex: "itemName",
  key: "itemName",
  title: "项目"
}, {
  title: "个人缴纳基数",
  dataIndex: "cardinalityDefault",
  key: "cardinalityDefault",
  scopedSlots: {
    customRender: "cardinalityDefault"
  }
}, {
  title: "单位缴纳比例",
  key: "persentCompany",
  dataIndex: "persentCompany",
  scopedSlots: {
    customRender: "persentCompany"
  }
}, {
  title: "个人缴纳比例",
  key: "persentPersonal",
  dataIndex: "persentPersonal",
  scopedSlots: {
    customRender: "persentPersonal"
  }
}, {
  title: "单位尾数处理",
  key: "mantissaProcCompany",
  dataIndex: "mantissaProcCompany",
  scopedSlots: {
    customRender: "mantissaProcCompany"
  }
}, {
  title: "个人尾数处理",
  key: "mantissaProcPersonal",
  dataIndex: "mantissaProcPersonal",
  scopedSlots: {
    customRender: "mantissaProcPersonal"
  }
}, {
  title: "单位金额",
  dataIndex: "amountCompany",
  key: "amountCompany",
  scopedSlots: {
    customRender: "amountCompany"
  }
}, {
  title: "个人金额",
  dataIndex: "amountPersonal",
  key: "amountPersonal",
  scopedSlots: {
    customRender: "amountPersonal"
  }
}];
var columnsOnlySoc = [{
  dataIndex: "itemName",
  key: "itemName",
  title: "项目"
}, {
  title: "个人缴纳基数",
  dataIndex: "cardinalityDefault",
  key: "cardinalityDefault",
  scopedSlots: {
    customRender: "cardinalityDefault"
  }
}, {
  title: "单位金额",
  dataIndex: "amountCompany",
  key: "amountCompany",
  scopedSlots: {
    customRender: "amountCompany"
  }
}, {
  title: "个人金额",
  dataIndex: "amountPersonal",
  key: "amountPersonal",
  scopedSlots: {
    customRender: "amountPersonal"
  }
}, {
  title: "单位尾数处理",
  key: "mantissaProcCompany",
  dataIndex: "mantissaProcCompany",
  scopedSlots: {
    customRender: "mantissaProcCompany"
  }
}, {
  title: "个人尾数处理",
  key: "mantissaProcPersonal",
  dataIndex: "mantissaProcPersonal",
  scopedSlots: {
    customRender: "mantissaProcPersonal"
  }
}];
export default {
  data: function data() {
    return {
      isContainByString: isContainByString,
      columns: columns,
      columnsOnlySoc: columnsOnlySoc,
      jijin: [{
        id: 0,
        itemType: 2,
        itemName: "公积金",
        cardinalityDefault: "",
        persentCompany: "",
        persentPersonal: "",
        comLastDeal: "四舍五入到分",
        personLastDeal: "四舍五入到分",
        amountCompany: "",
        amountPersonal: "",
        comFixed: "--",
        personFixed: "--",
        sort: 5
      }],
      shebao: [],
      // 社保
      groupItemDefault: 0,
      GgroupItemDefault: 0,
      // 公积金
      groupItemDefault2: 0,
      GgroupItemDefault2: 0,
      progId: "",
      progName: "",
      jijinShow: true,
      socCityName: "",
      onlySocAmount: false,
      deductionsDate: 1,
      userDate: 0
    };
  },
  created: function created() {
    if (this.$route.query.id) {
      this.progId = this.$route.query.id;
      this.toInquire();
    }
  },
  methods: {
    getSheBao: function getSheBao(index) {
      console.log(this.shebao);
      if (index === 1) {
        var tempShebao = [];
        for (var i in this.shebao) {
          // 遍历数组
          if (this.shebao[i].itemType === 1) {
            tempShebao.push(this.shebao[i]);
          }
        }
        return tempShebao;
      } else {
        var tempYibao = [];
        for (var j in this.shebao) {
          // 遍历数组
          if (this.shebao[j].itemType === 3) {
            tempYibao.push(this.shebao[j]);
          }
        }
        return tempYibao;
      }
    },
    getDateForm: function getDateForm(value) {
      var date = new Date(value);
      var y = date.getFullYear();
      var MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "年" + MM + "月" + d + "日";
    },
    getPfCompanyAll2: function getPfCompanyAll2() {
      var g = 0;
      if (this.getCompanyAll() + "".length >= this.getPfCompanyAll() + "".length) {
        g = this.groupItemDefault;
      } else {
        g = this.groupItemDefault2;
      }
      console.log(this.getCompanyAll() + "----" + this.getPfCompanyAll());
      return this.calculationDot(g, NP.strip(parseFloat(this.getCompanyAll()) + parseFloat(this.getPfCompanyAll())));
    },
    getPfPersonAll2: function getPfPersonAll2() {
      var g = 0;
      if (this.getPersonAll() + "".length >= this.getPfPersonAll() + "".length) {
        g = this.GgroupItemDefault;
      } else {
        g = this.GgroupItemDefault2;
      }
      console.log(this.getPersonAll() + "/" + this.getPfPersonAll());
      return this.calculationDot(g, NP.strip(parseFloat(this.getPersonAll()) + parseFloat(this.getPfPersonAll())));
    },
    // 删除方案跳出modal框
    deleteMethod: function deleteMethod() {
      var that = this;
      this.$confirm({
        title: "删除方案",
        content: "确认删除方案？",
        onOk: function onOk() {
          var data = {
            id: that.progId
          };
          deleteSocProgs(data).then(function (res) {
            that.$router.push({
              name: that.isContainByString("contractAdmin") ? "shebaoMethodAdmin" : "shebaoMethod"
            });
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        }
      });
    },
    // 点击进入编辑页面
    handleEdit: function handleEdit() {
      this.$router.push({
        path: "/".concat(this.isContainByString("contractAdmin") ? "contractAdmin" : "shebao", "/shebaoMethod/editMethod"),
        query: {
          id: this.progId
        }
      });
    },
    buCha: function buCha() {
      // 进入补差界面
      this.$router.push({
        path: "/shebao/shebaoMethod/buCha2",
        query: {
          id: this.progId,
          empSocInfoId: this.empSocInfoId
        }
      });
    },
    getMantissa: function getMantissa(value) {
      switch (value) {
        case 0:
          return "不处理";
        case 1:
          return "四舍五入到元";
        case 2:
          return "四舍五入到角";
        case 3:
          return "四舍五入到分";
        case 4:
          return "四舍五入到厘";
        case 5:
          return "四舍五入到小数点后4位";
        case 6:
          return "见厘进分";
        case 7:
          return "见分进角";
        case 8:
          return "见角进元";
        case 9:
          return "见角舍去";
        case 10:
          return "见分舍去";
        case 11:
          return "见厘舍去";
        default:
          return "不处理";
      }
    },
    // 计算单位金额和个人金额（社保和公积金通用）
    getCompanyAmount: function getCompanyAmount(type, record) {
      if (this.onlySocAmount) {
        return this.calculationDot(type, record.amountCompany);
      } else {
        return this.calculationDot(type, NP.strip(record.cardinalityDefault * record.persentCompany * 0.01));
      }
    },
    getPersonAmount: function getPersonAmount(type, record) {
      if (this.onlySocAmount) {
        return this.calculationDot(type, record.amountPersonal);
      } else {
        return this.calculationDot(type, NP.strip(record.cardinalityDefault * record.persentPersonal * 0.01));
      }
    },
    // 计算单位社保总金额和个人总金额
    getCompanyAll: function getCompanyAll() {
      var _this = this;
      var total = 0;
      if (this.shebao != null) {
        if (this.onlySocAmount) {
          this.shebao.map(function (item) {
            total += Number(_this.calculationDot(_this.groupItemDefault, item.amountCompany));
          });
        } else {
          this.shebao.map(function (item) {
            total += parseFloat(_this.calculationDot(_this.groupItemDefault, item.cardinalityDefault * item.persentCompany * 0.01));
          });
        }
      }
      return this.calculationDot(this.groupItemDefault, NP.strip(total));
    },
    getPersonAll: function getPersonAll() {
      var _this2 = this;
      var total = 0;
      if (this.shebao != null) {
        if (this.onlySocAmount) {
          this.shebao.map(function (item) {
            total += Number(_this2.calculationDot(_this2.GgroupItemDefault, item.amountPersonal));
          });
        } else {
          this.shebao.map(function (item) {
            if (item.itemType === 1 || item.itemType === 3) {
              total += parseFloat(_this2.calculationDot(_this2.GgroupItemDefault, item.cardinalityDefault * item.persentPersonal * 0.01));
            }
          });
        }
      }
      return this.calculationDot(this.GgroupItemDefault, NP.strip(total));
    },
    // 计算公积金单位总金额和个人总金额
    getPfCompanyAll: function getPfCompanyAll() {
      var _this3 = this;
      var total = 0;
      if (this.jijin != null) {
        if (this.onlySocAmount) {
          this.jijin.map(function (item) {
            total += Number(_this3.calculationDot(_this3.groupItemDefault2, item.amountCompany));
          });
        } else {
          this.jijin.map(function (item) {
            if (item.itemType === 2) {
              total += parseFloat(_this3.calculationDot(_this3.groupItemDefault2, item.cardinalityDefault * item.persentCompany * 0.01));
            }
          });
        }
      }
      return this.calculationDot(this.groupItemDefault2, NP.strip(total));
    },
    getPfPersonAll: function getPfPersonAll() {
      var _this4 = this;
      var total = 0;
      if (this.jijin != null) {
        if (this.onlySocAmount) {
          this.jijin.map(function (item) {
            total += Number(_this4.calculationDot(_this4.GgroupItemDefault2, item.amountPersonal));
          });
        } else {
          this.jijin.map(function (item) {
            total += parseFloat(_this4.calculationDot(_this4.GgroupItemDefault2, item.cardinalityDefault * item.persentPersonal * 0.01));
          });
        }
      }
      return this.calculationDot(this.GgroupItemDefault2, NP.strip(total));
    },
    // 计算小数点
    calculationDot: function calculationDot(type, value) {
      switch (type) {
        case 0:
          // 不处理
          var s = value.toString().split(".");
          if (s.length === 1) {
            return s + ".00";
          } else {
            if (s[1].length === 1) {
              s[1] = s[1] + "0";
            }
            return s[0] + "." + s[1];
          }
        case 1:
          // 四舍五入到元
          return this.siWuDot(value, 0);
        case 2:
          // 四舍五入到角
          return this.siWuDot(value, 1);
        case 3:
          // 四舍五入到分
          return this.siWuDot(value, 2);
        case 4:
          // 四舍五入到厘
          return this.siWuDot(value, 3);
        case 5:
          // 四舍五入到小数点后4位
          return this.siWuDot(value, 4);
        case 6:
          // 见厘进分
          return this.jieQueDot(value, 2);
        case 7:
          // 见分进角
          return this.jieQueDot(value, 1);
        case 8:
          // 见角进元
          return this.jieQueDot(value, 0);
        case 9:
          // 见角舍去
          return this.sheQuDot(value, 0);
        case 10:
          // 见分舍去
          return this.sheQuDot(value, 1);
        case 11:
          // 见厘舍去
          return this.sheQuDot(value, 2);
      }
    },
    // 四舍五入
    siWuDot: function siWuDot(value, n) {
      var f = Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
      var s = f.toString();
      var rs = s.indexOf(".");
      if (n !== 0 && rs < 0) {
        s += ".";
      }
      for (var i = s.length - s.indexOf("."); i <= n; i++) {
        s += "0";
      }
      if (n === 0) {
        s += ".00";
      }
      return s;
    },
    // 进1截取
    jieQueDot: function jieQueDot(value, n) {
      var f = value.toString();
      var s = f.split(".");
      if (s.length === 1) {
        return s + ".00";
      } else {
        if (n !== 0) {
          if (s[1].length > n) {
            var _n = Number(s[1].substring(0, _n));
            var n2 = _n + 1;
            s[1] = n2.toString();
          } else if (s[1].length < n) {
            var c = n - s[1].length;
            for (var i = 0; i < c; i++) {
              s[1] += "0";
            }
          }
          return s[0] + "." + s[1];
        } else {
          var _n2 = Number(s[0]) + 1;
          return _n2 + ".00";
        }
      }
    },
    // 直接舍去
    sheQuDot: function sheQuDot(value, n) {
      var f = value.toString();
      var s = f.split(".");
      if (s.length === 1) {
        return s + ".00";
      } else {
        if (n !== 0) {
          if (s[1].length > n) {
            s[1] = s[1].substring(0, n);
          } else if (s[1].length < n) {
            var c = n - s[1].length;
            for (var i = 0; i < c; i++) {
              s[1] += "0";
            }
          }
          return s[0] + "." + s[1];
        } else {
          return s[0] + ".00";
        }
      }
    },
    // 查询方案详情
    toInquire: function toInquire() {
      var _this5 = this;
      findSocProgRelation({
        progId: this.progId,
        companyId: this.isContainByString("contractAdmin") ? "0" : this.$store.state.hr.companyId
      }).then(function (res) {
        _this5.jijin = res.data.socProgItemVOPfList;
        _this5.shebao = res.data.socProgItemVOSocList;
        _this5.jijinShow = res.data.payProvidentFund;
        _this5.progName = res.data.progName;
        _this5.socCityName = res.data.socCityName;
        _this5.onlySocAmount = res.data.onlySocAmount;
        _this5.deductionsDate = res.data.deductionsDate;
        _this5.userDate = res.data.gmtCreate;
        if (_this5.jijin != null && _this5.jijin.length !== 0) {
          // 公积金
          if (_this5.jijin[0].mantissaProcCompany !== undefined) {
            _this5.groupItemDefault2 = _this5.jijin[0].mantissaProcCompany;
          }
          if (_this5.jijin[0].mantissaProcPersonal !== undefined) {
            _this5.GgroupItemDefault2 = _this5.jijin[0].mantissaProcPersonal;
          }
        }
        if (_this5.shebao != null && _this5.shebao.length !== 0) {
          if (_this5.shebao[0].mantissaProcCompany !== undefined) {
            _this5.groupItemDefault = _this5.shebao[0].mantissaProcCompany;
          }
          if (_this5.shebao[0].mantissaProcPersonal !== undefined) {
            _this5.GgroupItemDefault = _this5.shebao[0].mantissaProcPersonal;
          }
        }
      });
    }
  }
};