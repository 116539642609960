import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
var columns = [{
  title: "角色名称",
  dataIndex: "name",
  align: "center",
  width: 150
}, {
  title: "角色描述",
  dataIndex: "description",
  align: "center",
  width: 150
}, {
  title: "权限数量",
  dataIndex: "permissionCount",
  align: "center",
  width: 100
}, {
  title: "操作",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  },
  align: "center",
  fixed: "right",
  width: 150
}];
import { addRole, deleteRole, getRoleList, deleteRoles as _deleteRoles } from "@/api/company/member";
export default {
  data: function data() {
    return {
      actionId: null,
      hrId: null,
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      },
      columns: columns,
      activeName: "company",
      addRoleVisible: false,
      isBatch: true,
      roleIds: [],
      roleName: "",
      roleState: "",
      dataForm: {
        addRoleName: "",
        addRoleIntro: ""
      },
      addRoletype: "",
      subjectType: "company",
      state: [{
        value: false,
        label: "未启用"
      }, {
        value: true,
        label: "启用中"
      }],
      roleType: [{
        value: "A",
        label: "管理员"
      }, {
        value: "U",
        label: "普通成员"
      }],
      editRules: {
        addRoleName: [{
          required: true,
          message: "请输入角色名称",
          trigger: "blur"
        }],
        addRoleIntro: [{
          required: false
        }]
      },
      selectedRowKeys: [],
      defaultRoleList: [],
      multipleSelection: "",
      tableLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 获取数据
    getList: function getList() {
      var _this = this;
      var subjectType = {
        subjectType: this.subjectType
      };
      this.tableLoading = true;
      getRoleList().then(function (res) {
        _this.defaultRoleList = res.data || [];
        var columns = _this.columns.map(function (i) {
          return i.dataIndex;
        });
        _this.defaultRoleList = _this.defaultRoleList.map(function (item) {
          columns.map(function (key) {
            if (item[key] === "" || item[key] === null) {
              item[key] = "--";
            }
          });
          return item;
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      }).finally(function () {
        _this.tableLoading = false;
      });
    },
    // 确定
    handleOk: function handleOk() {
      var _this2 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var addRoleDTO = {
            name: _this2.dataForm.addRoleName,
            description: _this2.dataForm.addRoleIntro
            // type: "U",
            // subjectId: "0",
            // subjectType: this.subjectType // 业务id
          };

          addRole(addRoleDTO).then(function (data) {
            _this2.addRoleVisible = false;
            _this2.getList();
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        } else {
          return false;
        }
      });
    },
    // 查询
    checkByRoleName: function checkByRoleName() {
      var _this3 = this;
      var subjectType = {
        name: this.roleName
        // isValid: this.roleState,
        // subjectType: this.subjectType
      };

      getRoleList(subjectType).then(function (res) {
        _this3.defaultRoleList = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 重置
    reset: function reset() {
      var _this4 = this;
      this.roleName = "";
      this.roleState = "";
      getRoleList().then(function (res) {
        _this4.defaultRoleList = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    changeRoleType: function changeRoleType(e) {
      this.addRoleType = e;
    },
    cancel: function cancel() {
      this.$refs["dataForm"].resetFields();
      this.addRoleVisible = false;
    },
    saveAddRole: function saveAddRole(formName) {
      var _this5 = this;
      var addRoleDTO = {
        name: this.dataForm.addRoleName,
        description: this.dataForm.addRoleIntro,
        valid: this.addRoleState,
        type: "U",
        subjectId: "0",
        subjectType: this.subjectType // 业务id
      };

      addRole(addRoleDTO).then(function (res) {
        _this5.$refs["dataForm"].resetFields();
        _this5.addRoleVisible = false;
        _this5.$notification["success"]({
          message: "success",
          description: "新增角色成功"
        });
        var subjectType = {
          subjectType: _this5.subjectType
        };
        getRoleList().then(function (res) {
          _this5.defaultRoleList = res.data;
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      this.roleIds = this.selectedRowKeys;
      if (selectedRowKeys.length > 1) {
        this.isBatch = false;
      } else {
        this.isBatch = true;
      }
    },
    deleteRoleClick: function deleteRoleClick(row) {
      // this.roleIds = row.id;
      var that = this;
      this.$confirm({
        title: "删除角色",
        content: "此操作将永久删除该角色, 是否继续?",
        onOk: function onOk() {
          // var form = new FormData();
          // form.append("roleIds", that.roleIds);
          deleteRole(row.id).then(function (res) {
            that.$notification["success"]({
              message: "Success",
              description: "删除成功"
            });
            that.isBatch = true;
            var subjectType = {
              subjectType: that.subjectType
            };
            getRoleList().then(function (res) {
              that.defaultRoleList = res.data;
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        },
        onCancel: function onCancel() {}
      });
    },
    deleteRoles: function deleteRoles() {
      var that = this;
      this.$confirm({
        title: "删除角色",
        content: "此操作将永久删除该角色, 是否继续?",
        onOk: function onOk() {
          // var form = new FormData();
          // form.append("hrRoleIds", that.selectedRowKeys);
          _deleteRoles(that.selectedRowKeys).then(function (res) {
            that.$notification["success"]({
              message: "Success",
              description: "删除成功"
            });
            that.selectedRowKeys = [];
            that.isBatch = true;
            var subjectType = {
              subjectType: that.subjectType
            };
            getRoleList().then(function (res) {
              that.defaultRoleList = res.data;
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        },
        onCancel: function onCancel() {}
      });
    },
    configPermission: function configPermission(row) {
      var _this6 = this;
      if (row.roleVOList) {
        row.roleVOList.forEach(function (item2, index2) {
          if (item2.subjectType === "company") {
            _this6.actionId = item2.id;
          }
          if (item2.subjectType === "hr") {
            _this6.hrId = item2.id;
          }
        });
      }
      row.subjectType = this.subjectType;
      this.$router.push({
        path: "roleconfig",
        query: {
          id: row.id,
          actionId: this.actionId,
          hrId: this.hrId
        }
      });
    },
    newRole: function newRole() {
      this.addRoleVisible = true;
      this.dataForm.addRoleName = "";
      this.dataForm.addRoleIntro = "";
    }
  }
};