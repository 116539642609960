var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "talent-action-point"
  }, [_c("a-popover", {
    attrs: {
      placement: "bottomRight",
      overlayClassName: "talent-action-popover"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("ul", {
    staticClass: "talent-action-point-uls"
  }, [_vm._t("default")], 2)]), _vm._t("actionPointBtn", function () {
    return [_c("p", [_c("span"), _c("span"), _c("span")])];
  })], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };