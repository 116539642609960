import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _this = this;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "revset page-miH page-bgBack"
  }, [_c("a-layout", [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goback
    }
  }, [_vm._v(_vm._s(_vm.year) + "年" + _vm._s(_vm.month) + "月参保管理")]), _c("div", {
    staticClass: "flex-center-between pb12",
    staticStyle: {
      "margin-top": "20px"
    }
  }, _vm._l(_vm.topData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "wb24 border_hover h75 color_fa border_dashed top_warp",
      class: _vm.active === index ? "border_active" : "",
      staticStyle: {
        "padding-left": "24px"
      },
      on: {
        click: function click($event) {
          return _vm.onTopTabs(index);
        }
      }
    }, [_c("img", {
      staticStyle: {
        width: "32px",
        height: "32px"
      },
      attrs: {
        src: item.url,
        alt: item.title
      }
    }), _c("div", [_c("div", {
      staticClass: "top_title"
    }, [_vm._v(_vm._s(item.title))]), _c("CountTo", {
      staticClass: "top_number",
      attrs: {
        startVal: 0,
        endVal: item.num,
        duration: 2000
      }
    }), _c("div")], 1)]);
  }), 0), _c("div", {
    staticClass: "typeList"
  }, [_c("div", {
    staticClass: "mothleft"
  }, [_c("a-icon", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.leftClick
    }
  }), _c("div", {
    staticClass: "staff-moth"
  }, [_vm._v(_vm._s(_vm.year) + "年" + _vm._s(_vm.month) + "月")]), _c("a-icon", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      type: "right"
    },
    on: {
      click: _vm.rightClick
    }
  })], 1), _c("div", {
    staticClass: "divshu"
  }, [_c("div", {
    staticClass: "divshu2"
  })]), _c("div", {
    staticClass: "staff-top2-right"
  }, [_c("div", {
    staticClass: "staff-top2"
  }, [_c("div", {
    staticClass: "staff-top-h"
  }, [_c("div", {
    staticClass: "staff-top-item-name"
  }, [_vm._v("个人社保")]), _c("div", {
    staticClass: "staff-top-item-num"
  }, [_vm._v(_vm._s(_vm.personalSocAmount))])])]), _c("div", {
    staticClass: "staff-top2"
  }, [_c("div", {
    staticClass: "staff-top-h"
  }, [_c("div", {
    staticClass: "staff-top-item-name"
  }, [_vm._v("单位社保")]), _c("div", {
    staticClass: "staff-top-item-num"
  }, [_vm._v(_vm._s(_vm.companySocAmount))])])]), _c("div", {
    staticClass: "staff-top2"
  }, [_c("div", {
    staticClass: "staff-top-h"
  }, [_c("div", {
    staticClass: "staff-top-item-name"
  }, [_vm._v("个人公积金")]), _c("div", {
    staticClass: "staff-top-item-num"
  }, [_vm._v(_vm._s(_vm.personalPfAmount))])])]), _c("div", {
    staticClass: "staff-top2"
  }, [_c("div", {
    staticClass: "staff-top-h"
  }, [_c("div", {
    staticClass: "staff-top-item-name"
  }, [_vm._v("单位公积金")]), _c("div", {
    staticClass: "staff-top-item-num"
  }, [_vm._v(_vm._s(_vm.companyPfAmount))])])]), _c("div", {
    staticClass: "staff-top2"
  }, [_c("div", {
    staticClass: "staff-top-h"
  }, [_c("div", {
    staticClass: "staff-top-item-name"
  }, [_vm._v("服务费")]), _c("div", {
    staticClass: "staff-top-item-num"
  }, [_vm._v(_vm._s(_vm.serviceCostAmount))])])]), _vm.accumulationFundFine + _vm.socialSecurityFine != 0 ? _c("div", {
    staticClass: "staff-top2"
  }, [_c("a-popover", {
    attrs: {
      placement: "top"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "row"
    }
  }, [_c("div", [_c("div", {
    staticStyle: {
      color: "#9c9c9c"
    }
  }, [_vm._v("社保滞纳金")]), _c("div", {
    staticStyle: {
      color: "#55565d",
      "font-size": "24px"
    }
  }, [_vm._v(_vm._s(_vm.socialSecurityFine))])]), _c("div", {
    staticStyle: {
      "margin-left": "20px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#9c9c9c"
    }
  }, [_vm._v("公积金滞纳金")]), _c("div", {
    staticStyle: {
      color: "#55565d",
      "font-size": "24px"
    }
  }, [_vm._v(_vm._s(_vm.accumulationFundFine))])])])]), _c("div", {
    staticClass: "staff-top-h"
  }, [_c("div", {
    staticClass: "staff-top-item-name"
  }, [_vm._v("滞纳金")]), _c("div", {
    staticClass: "staff-top-item-num"
  }, [_vm._v(_vm._s(_vm.accumulationFundFine + _vm.socialSecurityFine))])])], 2)], 1) : _vm._e(), _vm.personalDifferenceAmount ? _c("div", {
    staticClass: "staff-top2"
  }, [_c("div", {
    staticClass: "staff-top-h"
  }, [_c("div", {
    staticClass: "staff-top-item-name"
  }, [_vm._v("个人差额费用")]), _c("div", {
    staticClass: "staff-top-item-num"
  }, [_vm._v(_vm._s(_vm.personalDifferenceAmount))])])]) : _vm._e(), _vm.companyDifferenceAmount ? _c("div", {
    staticClass: "staff-top2"
  }, [_c("div", {
    staticClass: "staff-top-h"
  }, [_c("div", {
    staticClass: "staff-top-item-name"
  }, [_vm._v("单位差额费用")]), _c("div", {
    staticClass: "staff-top-item-num"
  }, [_vm._v(_vm._s(_vm.companyDifferenceAmount))])])]) : _vm._e(), _c("div", {
    staticClass: "staff-top2"
  }, [_c("div", {
    staticClass: "staff-top-h"
  }, [_c("div", {
    staticClass: "staff-top-item-name"
  }, [_vm._v("合计")]), _c("div", {
    staticClass: "staff-top-item-num"
  }, [_vm._v(_vm._s(_vm.totalAmount))])])])])]), _vm.inApproval ? _c("div", {
    staticStyle: {
      "margin-top": "15px",
      "padding-left": "10px",
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      height: "35px",
      width: "100%",
      "background-color": "#fffbe6",
      "border-style": "solid",
      "border-color": "#ffe58f",
      "border-width": "1px",
      "border-radius": "5px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "5px"
    },
    attrs: {
      src: require("../../../assets/icons/重复.png")
    }
  }), _c("span", {
    attrs: {
      sytle: "color:#55565D;font-size:14px;"
    }
  }, [_vm._v("账单已生成，无法操作")])]) : _vm._e(), _c("div", {
    staticClass: "mt24"
  }, [_c("TableTitle", {
    staticStyle: {
      padding: "0",
      height: "32px",
      "line-height": "32px",
      margin: "4px 0 16px"
    }
  }, [_c("template", {
    slot: "title"
  }), _c("template", {
    slot: "action"
  }, [_c("a-button", {
    style: _vm.monthWhetherCurrent ? [{
      marginLeft: "13px"
    }, {
      color: "red"
    }, {
      border: "1px solid red"
    }] : [{
      marginLeft: "13px"
    }],
    attrs: {
      disabled: !_vm.monthWhetherCurrent || _vm.inApproval
    },
    on: {
      click: function click($event) {
        _vm.isChooseStaff4 = true;
      }
    }
  }, [_vm._v("差额费用")]), _c("a-button", {
    staticStyle: {
      "margin-left": "13px"
    },
    attrs: {
      disabled: !_vm.monthWhetherCurrent || _vm.inApproval
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "batchMyClienteleImports",
          query: {
            monthId: _vm.monthCurrent
          }
        });
      }
    }
  }, [_vm._v("批量修改")]), _c("a-button", {
    staticStyle: {
      "margin-left": "13px"
    },
    on: {
      click: _vm.addBatchRecordList
    }
  }, [_vm._v("生成回款记录")]), false ? _c("a-button", {
    staticStyle: {
      "margin-left": "13px"
    },
    attrs: {
      type: "primary",
      disabled: !_vm.monthWhetherCurrent || _vm.inApproval
    },
    on: {
      click: _vm.piLiangDaoRu
    }
  }, [_vm._v("批量导入")]) : _vm._e(), _c("a-button", {
    staticStyle: {
      "margin-left": "13px"
    },
    attrs: {
      disabled: !_vm.monthWhetherCurrent || _vm.inApproval
    },
    on: {
      click: _vm.backFeiyong
    }
  }, [_vm._v("退费")]), _c("a-button", {
    staticClass: "ml12",
    attrs: {
      type: "primary",
      disabled: !_vm.monthWhetherCurrent || _vm.inApproval
    },
    on: {
      click: _vm.chooseOnWork2
    }
  }, [_vm._v(" 补收/补缴 ")])], 1)], 2), this.monthCurrent ? _c("TableBody", {
    ref: "tbody",
    staticClass: "mt12 soc-tb",
    attrs: {
      selectHead: "",
      selection: "",
      tableName: "shebao4",
      tableHeadUrl: "/hr/api/v1/empSocInfos/getDynamicHead",
      needUpdateHead: true,
      tableHeadParams: _vm.tableHeadParams,
      selectedHead: _vm.selectedHead,
      selectedRowKeys: _vm.selectedRowKeys,
      source: _vm.dataDetail,
      headerRowID: "sortable_list",
      columns: _vm.columns,
      subjectType: "site"
    },
    on: {
      "update:selectedHead": function updateSelectedHead($event) {
        _vm.selectedHead = $event;
      },
      "update:selected-head": function updateSelectedHead($event) {
        _vm.selectedHead = $event;
      },
      "update:selectedRowKeys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      "update:selected-row-keys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      onClose: function onClose() {},
      onSearch: function onSearch($event) {
        return _vm.handleSearch();
      },
      onReload: function onReload($event) {
        return _vm.checkPeopleAmount();
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(prop) {
        return [_vm.showTable ? _c("a-table", {
          class: prop.className,
          attrs: {
            components: prop.components,
            columns: prop.columns,
            scroll: {
              x: prop.scrollWidth
            },
            customHeaderRow: function customHeaderRow() {
              return {
                domProps: {
                  id: prop.headerRowID
                }
              };
            },
            "data-source": _vm.dataDetail,
            "row-key": "id",
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange
            },
            pagination: _vm.pagination
          },
          on: {
            change: _vm.handlePageChange
          },
          scopedSlots: _vm._u([{
            key: "name",
            fn: function fn(text, record) {
              return [_c("div", {
                staticStyle: {
                  position: "relative"
                }
              }, [record.whetherLeave ? _c("img", {
                staticStyle: {
                  position: "absolute",
                  top: "-16px",
                  left: "5px",
                  width: "45px"
                },
                attrs: {
                  src: require("../../../assets/shebao/离职.png"),
                  alt: ""
                }
              }) : _vm._e()]), _vm._v(" " + _vm._s(text) + " ")];
            }
          }, {
            key: "regType",
            fn: function fn(text) {
              return [_c("span", [_vm._v(_vm._s(["城镇户口", "农村户口", "居民户口"][text - 1]))])];
            }
          }, {
            key: "empSocStatus",
            fn: function fn(text) {
              return [_c("span", [_vm._v(_vm._s(_vm.getPostTypeNew(text)))])];
            }
          }, {
            key: "empMeStatus",
            fn: function fn(text) {
              return [_c("span", [_vm._v(_vm._s(_vm.getPostTypeNew(text)))])];
            }
          }, {
            key: "empPfStatus",
            fn: function fn(text) {
              return [_c("span", [_vm._v(_vm._s(_vm.getPostTypeNew(text)))])];
            }
          }, {
            key: "refundsType",
            fn: function fn(text) {
              return [_c("span", [_vm._v(" " + _vm._s(text === 1 ? "社保退费" : text === 2 ? "公积金退费" : text === null ? "--" : "医保退费") + " ")])];
            }
          }, {
            key: "refundsBeginTime",
            fn: function fn(text) {
              return [_c("span", [_vm._v(_vm._s(_vm.getTuiTime(text)))])];
            }
          }, {
            key: "refundsEndTime",
            fn: function fn(text) {
              return [_c("span", [_vm._v(_vm._s(_vm.getTuiTime(text)))])];
            }
          }, {
            key: "month",
            fn: function fn(text, record) {
              return [_c("span", [_vm._v(_vm._s(record.year) + "-" + _vm._s(record.month))])];
            }
          }, {
            key: "serviceCost",
            fn: function fn(text, record) {
              return [_c("span", [_vm._v(_vm._s(record.serviceCost ? record.serviceCost : 0))])];
            }
          }, {
            key: "socialSecurityFine",
            fn: function fn(text, record) {
              return [_c("span", [_vm._v(_vm._s(_vm.getZhiNaJinCout(record)))])];
            }
          }, {
            key: "action",
            fn: function fn(text, record) {
              return [_c("div", {
                staticClass: "detail_item",
                on: {
                  click: function click($event) {
                    return _vm.openDetail(record);
                  }
                }
              }, [_vm._v("详情")])];
            }
          }, {
            key: "payCostType",
            fn: function fn(record) {
              return [_c("a-tooltip", [_c("template", {
                slot: "title"
              }, [_vm._v(_vm._s(_vm.getPayCostType(record)))]), _vm._v(" " + _vm._s(_vm.getPayCostType(record)) + " ")], 2)];
            }
          }], null, true)
        }) : _vm._e()];
      }
    }], null, false, 1637840359)
  }, [_c("template", {
    slot: "search"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      defaultValue: _vm.searchName,
      placeholder: "搜索员工"
    },
    on: {
      search: _vm.handleSearch
    },
    model: {
      value: _vm.searchName,
      callback: function callback($$v) {
        _vm.searchName = $$v;
      },
      expression: "searchName"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "社保状态",
      placeholder: "请选择社保状态",
      "allow-clear": "",
      defaultValue: _vm.empSocStatus
    },
    model: {
      value: _vm.empSocStatus,
      callback: function callback($$v) {
        _vm.empSocStatus = $$v;
      },
      expression: "empSocStatus"
    }
  }, _vm._l(_vm.socTypeSource, function (item) {
    return _c("a-select-option", {
      key: item.label,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "医保状态",
      placeholder: "请选择医保状态",
      "allow-clear": "",
      defaultValue: _vm.empMeStatus
    },
    model: {
      value: _vm.empMeStatus,
      callback: function callback($$v) {
        _vm.empMeStatus = $$v;
      },
      expression: "empMeStatus"
    }
  }, _vm._l(_vm.socTypeSource, function (item) {
    return _c("a-select-option", {
      key: item.label,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "公积金状态",
      placeholder: "请选择公积金状态",
      "allow-clear": "",
      defaultValue: _vm.empPfStatus
    },
    model: {
      value: _vm.empPfStatus,
      callback: function callback($$v) {
        _vm.empPfStatus = $$v;
      },
      expression: "empPfStatus"
    }
  }, _vm._l(_vm.socTypeSource, function (item) {
    return _c("a-select-option", {
      key: item.label,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "缴费类型",
      placeholder: "请选择缴费类型",
      "allow-clear": "",
      defaultValue: _vm.socPayCostType
    },
    model: {
      value: _vm.socPayCostType,
      callback: function callback($$v) {
        _vm.socPayCostType = $$v;
      },
      expression: "socPayCostType"
    }
  }, _vm._l(_vm.socPayCostTypeMap, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "参保方案",
      placeholder: "请选择参保方案",
      "allow-clear": "",
      defaultValue: _vm.progIdser
    },
    model: {
      value: _vm.progIdser,
      callback: function callback($$v) {
        _vm.progIdser = $$v;
      },
      expression: "progIdser"
    }
  }, _vm._l(_vm.progNameList, function (item) {
    return _c("a-select-option", {
      key: item.socProgItemVOList[0].progId
    }, [_vm._v(" " + _vm._s(item.progName) + " ")]);
  }), 1), _c("city", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "城市",
      placeholder: "请选择城市",
      level: 2,
      "default-value": _vm.socCity,
      "allow-clear": true
    },
    on: {
      change: function change($event) {
        return _vm.handleCity($event);
      }
    }
  }), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "在职状态",
      placeholder: "选择在职状态",
      "allow-clear": "",
      defaultValue: _vm.rStatus
    },
    model: {
      value: _vm.rStatus,
      callback: function callback($$v) {
        _vm.rStatus = $$v;
      },
      expression: "rStatus"
    }
  }, _vm._l(_vm.liststatus, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "标签",
      mode: "multiple",
      placeholder: "请选择标签",
      "allow-clear": "",
      defaultValue: _vm.tags
    },
    model: {
      value: _vm.tags,
      callback: function callback($$v) {
        _vm.tags = $$v;
      },
      expression: "tags"
    }
  }, _vm._l(_vm.labelList, function (item) {
    return _c("a-select-option", {
      key: item.tagName
    }, [_vm._v(_vm._s(item.tagName))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "渠道",
      placeholder: "请选择渠道",
      "allow-clear": true,
      defaultValue: _vm.channelId
    },
    model: {
      value: _vm.channelId,
      callback: function callback($$v) {
        _vm.channelId = $$v;
      },
      expression: "channelId"
    }
  }, _vm._l(_vm.channelNames, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "户口类型",
      allowClear: "",
      placeholder: "请选择户口类型",
      defaultValue: _vm.regType
    },
    model: {
      value: _vm.regType,
      callback: function callback($$v) {
        _vm.regType = $$v;
      },
      expression: "regType"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("城镇户口")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("农村户口")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("居民户口")])], 1), _c("SelectWithInputAdd", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      editable: "",
      allowClear: "",
      mode: "select",
      dictCode: "1220",
      label: "社保账号",
      placeholder: "社保账号"
    },
    model: {
      value: _vm.socAccount,
      callback: function callback($$v) {
        _vm.socAccount = $$v;
      },
      expression: "socAccount"
    }
  }), _c("SelectWithInputAdd", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      editable: "",
      allowClear: "",
      mode: "select",
      dictCode: "1221",
      label: "医保账号",
      placeholder: "医保账号"
    },
    model: {
      value: _vm.meAccount,
      callback: function callback($$v) {
        _vm.meAccount = $$v;
      },
      expression: "meAccount"
    }
  }), _c("SelectWithInputAdd", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      editable: "",
      allowClear: "",
      mode: "select",
      dictCode: "1222",
      label: "公积金账号",
      placeholder: "公积金账号"
    },
    model: {
      value: _vm.pfAccount,
      callback: function callback($$v) {
        _vm.pfAccount = $$v;
      },
      expression: "pfAccount"
    }
  }), _c("SelectWithInputAdd", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      editable: "",
      allowClear: "",
      mode: "select",
      dictCode: "1223",
      label: "签约主体",
      placeholder: "签约主体"
    },
    model: {
      value: _vm.contractSubject,
      callback: function callback($$v) {
        _vm.contractSubject = $$v;
      },
      expression: "contractSubject"
    }
  })], 1), _c("template", {
    slot: "export"
  }, [_c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }), _c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    attrs: {
      disabled: _vm.dataDetail.length === 0
    },
    on: {
      click: function click($event) {
        return _vm.exportExel();
      }
    }
  }, [_vm._v("全部导出")]), _c("a-menu-item", {
    on: {
      click: function click() {
        _this.selectionBtnLock = true;
      }
    }
  }, [_vm._v("批量导出")])], 1), _c("a-button", {
    staticClass: "action-btn",
    attrs: {
      type: "link"
    }
  }, [_vm._v(" 导出 "), _c("a-icon", {
    attrs: {
      type: "caret-down"
    }
  })], 1)], 1)], 1)], 2) : _vm._e()], 1), _c("a-row", {
    staticStyle: {
      height: "25px"
    }
  }), _c("a-modal", {
    attrs: {
      visible: _vm.differenceVisible,
      title: "差额费用"
    },
    on: {
      ok: _vm.differenceOk,
      cancel: function cancel($event) {
        _vm.differenceVisible = false;
      }
    }
  }, [_c("div", {
    staticStyle: {
      padding: "0 45px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v("差额费用名称")]), _c("a-input", {
    staticStyle: {
      width: "220px",
      "margin-left": "20px"
    },
    attrs: {
      maxLength: 20,
      placeholder: "请输入"
    },
    model: {
      value: _vm.nameDifference,
      callback: function callback($$v) {
        _vm.nameDifference = $$v;
      },
      expression: "nameDifference"
    }
  })], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "margin-top": "24px"
    }
  }, [_c("div", [_vm._v("个人差额费用")]), _c("a-input-number", {
    staticStyle: {
      width: "220px",
      "margin-left": "24px"
    },
    attrs: {
      precision: 2,
      placeholder: "请输入"
    },
    model: {
      value: _vm.personalDifference,
      callback: function callback($$v) {
        _vm.personalDifference = $$v;
      },
      expression: "personalDifference"
    }
  })], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "margin-top": "24px"
    }
  }, [_c("div", [_vm._v("单位差额费用")]), _c("a-input-number", {
    staticStyle: {
      width: "220px",
      "margin-left": "24px"
    },
    attrs: {
      precision: 2,
      placeholder: "请输入"
    },
    model: {
      value: _vm.companyDifference,
      callback: function callback($$v) {
        _vm.companyDifference = $$v;
      },
      expression: "companyDifference"
    }
  })], 1), _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "10px",
      "margin-top": "12px"
    }
  }, [_vm._v("当二次操作已存在的差额费用时，填0则覆盖为0，填空则默认不更新")])])]), _c("a-modal", {
    attrs: {
      title: "详情",
      width: "1000px",
      visible: _vm.xiangqing,
      cancelText: ""
    },
    on: {
      cancel: _vm.closeXiangq
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.closeXiangq
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticStyle: {
      "padding-left": "30px",
      "padding-right": "30px"
    }
  }, [_c("div", {
    staticClass: "userInfo-box"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "row",
      "align-items": "center"
    }
  }, [_vm.currentDetail && _vm.currentDetail.empPhoto !== null ? _c("a-avatar", {
    key: _vm.currentDetail && _vm.currentDetail.empPhoto,
    attrs: {
      src: _vm.currentDetail && _vm.currentDetail.empPhoto,
      size: 56
    }
  }) : _c("a-avatar", {
    attrs: {
      icon: "user",
      size: 56
    }
  }), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "column",
      "margin-left": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "row",
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "name fs18",
    staticStyle: {
      color: "#3c3d43"
    }
  }, [_vm._v(_vm._s(_vm.currentDetail ? _vm.currentDetail.name : ""))]), _c("div", {
    staticClass: "depName fs12 ml16",
    staticStyle: {
      color: "#767885"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentDetail ? "\u793E\u4FDD".concat(_vm.getPostTypeNew(_vm.currentDetail.empSocStatus), " / \u533B\u4FDD").concat(_vm.getPostTypeNew(_vm.currentDetail.empMeStatus), " / \u516C\u79EF\u91D1").concat(_vm.getPostTypeNew(_vm.currentDetail.empPfStatus)) : "") + " ")])]), _c("div", {
    staticClass: "ta-left"
  }, [_vm._v(_vm._s(_vm.currentDetail ? _vm.currentDetail.idCard : ""))])])], 1), _c("div", {
    staticClass: "editInfo-box"
  }, [_c("a-button", {
    staticClass: "renyuanxinxi",
    on: {
      click: _vm.personInfo
    }
  }, [_vm._v("个人档案")]), _c("a-button", {
    staticClass: "renyuanxinxi",
    on: {
      click: _vm.enterRecord
    }
  }, [_vm._v("参保记录")]), _c("a-button", {
    staticClass: "liangge",
    attrs: {
      disabled: _vm.currentDetail && (_vm.currentDetail.payCostType === 2 || !_vm.monthWhetherCurrent || _vm.inApproval)
    },
    on: {
      click: function click() {
        return _vm.editShebaoInfo(_vm.currentDetail);
      }
    }
  }, [_vm._v("编辑")]), _vm.currentDetail && _vm.currentDetail.payCostType !== 7 ? _c("a-button", {
    staticClass: "liangge",
    attrs: {
      disabled: _vm.currentDetail && (_vm.currentDetail.payCostType === 2 || _vm.currentDetail.payCostType === 3 || !_vm.monthWhetherCurrent || _vm.inApproval)
    },
    on: {
      click: function click($event) {
        return _vm.stopShebao(_vm.currentDetail);
      }
    }
  }, [_vm._v("减员")]) : _vm._e(), _vm.currentDetail && _vm.currentDetail.payCostType === 3 ? _c("a-popconfirm", {
    attrs: {
      title: "\u786E\u8BA4\u8981\u5220\u9664\u5417\uFF1F",
      okText: "确认",
      cancelText: "取消"
    },
    on: {
      confirm: function confirm($event) {
        return _vm.deletShebao(_vm.currentDetail);
      }
    }
  }, [_c("a-button", {
    staticClass: "liangge"
  }, [_vm._v("删除")])], 1) : _vm._e()], 1)]), _c("div", {
    staticClass: "userInfo-shebaoInfo"
  }, [_c("div", {
    staticClass: "shebaoInfo"
  }, [_c("div", {
    staticClass: "personshebaoInfo"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("个人社保号:")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.currentDetail ? _vm.currentDetail.socCard : ""))])]), _c("div", {
    staticClass: "sfInfo"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("个人公积金号:")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.currentDetail ? _vm.currentDetail.pfCard : ""))])]), _c("div", {
    staticClass: "cityInfo"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("参保城市:")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.currentDetail ? _vm.currentDetail.socCityName : ""))])]), _c("div", {
    staticClass: "methodInfo"
  }, [_c("span", {
    staticClass: "text"
  }, [_vm._v("缴纳方案:")]), _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.currentDetail ? _vm.currentDetail.progName : ""))])])])]), _c("div", {
    staticClass: "payInfo-box"
  }, [_c("a-table", {
    attrs: {
      "data-source": _vm.currentDetail ? _vm.currentDetail.progItemListVo : null,
      pagination: false,
      "row-key": "id"
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "缴纳项目",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text1) {
        return [_c("div", {
          style: {
            fontWeight: text1.itemName === "缴费合计" ? 700 : 400
          }
        }, [_vm._v(" " + _vm._s(text1.itemName) + " ")])];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "缴纳基数",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text1) {
        return [_c("span", [_vm._v(_vm._s(_vm.judgeDefault(text1)))])];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "个人比例",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text1) {
        return [_c("span", [_vm._v(" " + _vm._s(text1.persentPersonal === null ? "--" : _vm.currentDetail.onlySocAmount ? "/" : text1.persentPersonal + "%") + " ")])];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "单位比例",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text1) {
        return [_c("span", [_vm._v(" " + _vm._s(text1.persentCompany === null ? "--" : _vm.currentDetail.onlySocAmount ? "/" : text1.persentCompany + "%") + " ")])];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "个人缴费",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text1) {
        return [_c("div", {
          style: [{
            fontWeight: text1.itemName === "缴费合计" ? 700 : 400
          }]
        }, [_vm._v(_vm._s(text1.amountPersonal))])];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "单位缴费",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text1) {
        return [_c("div", {
          style: [{
            fontWeight: text1.itemName === "缴费合计" ? 700 : 400
          }]
        }, [_vm._v(_vm._s(text1.amountCompany))])];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "缴费合计",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text1) {
        return [_c("div", {
          class: {
            themColor: text1.itemName === "缴费合计"
          },
          style: [{
            fontWeight: text1.itemName === "缴费合计" ? 700 : 400
          }]
        }, [_vm._v(" " + _vm._s(text1.amountCount) + " ")])];
      }
    }])
  })], 1)], 1)])], 2), _c("a-modal", {
    attrs: {
      visible: _vm.shouDongBuChaVisible,
      title: "撤销参保"
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.shouDongBuChaOkHandel
    }
  }, [_vm._v("下一步")])], 1), _c("div", {
    staticStyle: {
      "font-weight": "500",
      "font-size": "18px"
    }
  }, [_vm._v("存在未来月份的参保管理，是否需要更新参保信息？")]), _c("div", {
    staticStyle: {
      color: "#000",
      opacity: "0.8",
      "margin-top": "20px"
    }
  }, [_vm._v("说明：根据补差修改的个人缴纳基数更新")]), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("a-radio-group", {
    on: {
      change: _vm.onTypeChangeValue
    },
    model: {
      value: _vm.typeValueTime,
      callback: function callback($$v) {
        _vm.typeValueTime = $$v;
      },
      expression: "typeValueTime"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("不更新参保人信息")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("更新参保人信息")])], 1)], 1), _vm.typeValueTime === 2 ? _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      mode: "multiple",
      placeholder: "请选择要更改的时间"
    },
    on: {
      change: _vm.handleTime
    }
  }, _vm._l(_vm.timeList, function (item) {
    return _c("a-select-option", {
      key: item
    }, [_vm._v(_vm._s(_vm.tranDate(item)))]);
  }), 1)], 1) : _vm._e()], 2), _c("a-modal", {
    attrs: {
      title: "撤销参保",
      visible: _vm.isUndoShebao
    },
    on: {
      ok: _vm.handleUndo,
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/icons/ask.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" 撤销参保后将删除本月参保记录，是否确认撤销" + _vm._s(_vm.evName) + "的参保？ ")])])]), _c("a-modal", {
    attrs: {
      title: "暂无参保数据",
      visible: _vm.isEmpy
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.gotoZengyuan
    }
  }, [_vm._v("去增员")])], 1), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/icons/ask.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v("该月无参保员工，如需参保，请先进行增员")])])], 2), _c("a-modal", {
    attrs: {
      title: "撤销参保",
      visible: _vm.isUndoShebao2
    },
    on: {
      ok: _vm.handleUndo2,
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/icons/ask.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" 撤销参保后将删除本月参保记录，是否确认撤销" + _vm._s(_vm.selectedRowKeys.length) + "人的参保？ ")])])]), _c("a-modal", {
    attrs: {
      title: "添加参保",
      visible: _vm.isCloseMethod,
      width: "600px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.privCancel
    }
  }, [_vm._v("上一步")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.finishEdit
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticStyle: {
      padding: "0 56px"
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "参保月份"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.year) + "年" + _vm._s(_vm.month) + "月")])]), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "参保方案"
    }
  }, [_c("a-select", {
    attrs: {
      "default-value": _vm.defaultFangan
    },
    on: {
      change: _vm.chooseProgName
    }
  }, _vm._l(_vm.progNameList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.progName))]);
  }), 1)], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "15px",
      "margin-left": "70px",
      color: "#000000",
      "font-weight": "700"
    }
  }, [_vm._v("社保各项目基数")]), _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 1 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault,
        max: item.cardinalityMax,
        min: item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "] ")])], 1) : _vm._e()];
  })], 2), !_vm.payProvidentFund ? _c("div", [_c("div", {
    staticStyle: {
      "margin-bottom": "15px",
      "margin-left": "70px",
      color: "#000000",
      "font-weight": "700"
    }
  }, [_vm._v("公积金各项基数")]), _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 2 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault,
        max: item.cardinalityMax,
        min: item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "] ")])], 1) : _vm._e()];
  })], 2)], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "服务费"
    }
  }, [_c("a-input-number", {
    attrs: {
      placeholder: "每月服务费",
      min: 0
    },
    model: {
      value: _vm.serviceCost,
      callback: function callback($$v) {
        _vm.serviceCost = $$v;
      },
      expression: "serviceCost"
    }
  })], 1)], 1)], 1)], 2), _c("a-modal", {
    attrs: {
      title: "减员",
      visible: _vm.Dvisible
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "back",
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.DhandleOk
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/icons/ask.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" 减员后社保将有较大金额变动，确定对" + _vm._s(_vm.jianyuanItem !== undefined ? _vm.jianyuanItem.name : "") + "进行减员？ ")])])], 2), _c("a-modal", {
    attrs: {
      title: "减员",
      visible: _vm.isStopShebao,
      width: "800px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.finishjian
    }
  }, [_vm._v("下一步")])], 1), _vm.stopyear == "" && _vm.stopmonth == "" && _vm.stopYByear == "" && _vm.stopYBmonth == "" && _vm.stopGjyear == "" && _vm.stopGjmonth == "" ? _c("div", [_vm._v(" 没发现可用于减员的社保项 ")]) : _c("div", [_c("a-form", {
    attrs: {
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 7
      }
    }
  }, [_vm.stopyear !== "" && _vm.stopmonth !== "" ? _c("a-checkbox", {
    staticStyle: {
      "margin-left": "245px",
      "margin-top": "15px",
      "margin-bottom": "15px"
    },
    attrs: {
      checked: _vm.jianShebao,
      disabled: _vm.jianShebao_2
    },
    on: {
      change: _vm.onTypejianChange1
    }
  }, [_vm._v("社保减员")]) : _vm._e(), _vm.stopyear !== "" && _vm.stopmonth !== "" ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "社保停保年月"
    }
  }, [_c("a-month-picker", {
    attrs: {
      value: _vm.moment(_vm.stopyear + "-" + _vm.stopmonth, _vm.monthFormat),
      "disabled-date": _vm.disabledDatejian,
      disabled: !_vm.jianShebao
    },
    on: {
      change: _vm.onStopYearMonthChange
    }
  })], 1) : _vm._e(), _vm.stopYByear !== "" && _vm.stopYBmonth !== "" ? _c("a-checkbox", {
    staticStyle: {
      "margin-left": "245px",
      "margin-top": "15px",
      "margin-bottom": "15px"
    },
    attrs: {
      checked: _vm.jianYibao,
      disabled: _vm.jianYibao_2
    },
    on: {
      change: _vm.onTypejianChange2
    }
  }, [_vm._v("医保减员")]) : _vm._e(), _vm.stopYByear !== "" && _vm.stopYBmonth !== "" ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "医保停保年月"
    }
  }, [_c("a-month-picker", {
    attrs: {
      value: _vm.moment(_vm.stopYByear + "-" + _vm.stopYBmonth, _vm.monthFormat),
      "disabled-date": _vm.disabledDatejian,
      disabled: !_vm.jianYibao
    },
    on: {
      change: _vm.onStopYBYearMonthChange
    }
  })], 1) : _vm._e(), _vm.stopGjyear !== "" && _vm.stopGjmonth !== "" ? _c("a-checkbox", {
    staticStyle: {
      "margin-left": "245px",
      "margin-top": "15px",
      "margin-bottom": "15px"
    },
    attrs: {
      checked: _vm.jianGjj,
      disabled: _vm.jianGjj_2
    },
    on: {
      change: _vm.onTypejianChange3
    }
  }, [_vm._v("公积金减员")]) : _vm._e(), _vm.stopGjyear !== "" && _vm.stopGjmonth !== "" ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "公积金封存年月"
    }
  }, [_c("a-month-picker", {
    attrs: {
      value: _vm.moment(_vm.stopGjyear + "-" + _vm.stopGjmonth, _vm.monthFormat),
      "disabled-date": _vm.disabledDatejian,
      disabled: !_vm.jianGjj
    },
    on: {
      change: _vm.onStopGJYearMonthChange
    }
  })], 1) : _vm._e(), _vm.stopGjyear !== "" && _vm.stopGjmonth !== "" ? _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "10px",
      "margin-top": "-10px",
      "margin-left": "345px"
    }
  }, [_vm._v(" 如仅停缴公积金，请前往新建无参保公积金方案 ")]) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "服务费"
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      placeholder: "请输入服务费",
      min: 0,
      precision: 2,
      disabled: (_vm.jianShebao || _vm.jianYibao || _vm.jianGjj) === false
    },
    model: {
      value: _vm.JYserviceCost,
      callback: function callback($$v) {
        _vm.JYserviceCost = $$v;
      },
      expression: "JYserviceCost"
    }
  })], 1), _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "10px",
      "margin-top": "-10px",
      "margin-left": "345px"
    }
  }, [_vm._v(" 服务费不填写默认不更改，填0即服务费改为0 ")])], 1)], 1)], 2), _c("a-modal", {
    attrs: {
      title: "减员",
      visible: _vm.Lvisible,
      width: "500px"
    },
    on: {
      cancel: _vm.LhandleOk
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.LhandleOk
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px",
      "margin-left": "10px"
    },
    attrs: {
      src: require("@/assets/img/overviewContract/icon4.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("减员计划添加成功，查看计划请前往增减员计划内进行确认")])]), _c("div", [_c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "16px",
      "margin-left": "0px",
      "margin-top": "40px",
      "margin-bottom": "10px"
    }
  }, [_vm._v("减员计划结果")]), _c("a-table", {
    attrs: {
      columns: _vm.columnDialogDetailJianYuan,
      pagination: _vm.pagination2,
      "data-source": _vm.dataDetail4,
      scroll: {
        x: 600
      },
      colspan: "0",
      "row-key": "id"
    },
    on: {
      change: _vm.handlePageChange2
    },
    scopedSlots: _vm._u([{
      key: "socDescription",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.socDescription ? record.socDescription : "--"))])];
      }
    }, {
      key: "meDescription",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.meDescription ? record.meDescription : "--"))])];
      }
    }, {
      key: "pfDescription",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.pfDescription ? record.pfDescription : "--"))])];
      }
    }])
  })], 1)], 2), _c("a-modal", {
    attrs: {
      title: "补收/补缴",
      width: 600,
      visible: _vm.bushoubujiao1
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "back",
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    key: "submit",
    attrs: {
      disabled: _vm.bushouTypeValue === 2 ? !_vm.hasGongjijin : false,
      type: "primary"
    },
    on: {
      click: _vm.bushoubujiao1next
    }
  }, [_vm._v(" 下一步 ")])], 1), _c("a-radio-group", {
    staticClass: "baseInfo-item",
    on: {
      change: _vm.onSheBaoTypeChange
    },
    model: {
      value: _vm.bushouTypeValue,
      callback: function callback($$v) {
        _vm.bushouTypeValue = $$v;
      },
      expression: "bushouTypeValue"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("社保补收")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("公积金补缴")])], 1), _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#767885",
      "font-size": "12px",
      "font-weight": "400",
      "margin-left": "120px",
      width: "320px",
      "margin-bottom": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.bushouTypeValue === 1 ? "企业、机关事业可补收月数不能超过12个月，有雇工的个体工伤户可补收月数不能超过3个月" : "该人员所在参保方案不缴纳公积金，无法计算金额；若需补缴公积金，需更换参保方案。") + " ")]), _vm.bushouTypeValue === 2 && !_vm.hasGongjijin ? _c("div", {
    staticClass: "primary_color",
    staticStyle: {
      "margin-left": "230px",
      position: "absolute",
      "z-index": "10",
      "font-size": "12px",
      "margin-top": "-38px"
    },
    on: {
      click: _vm.gotoEdit
    }
  }, [_vm._v(" 前往编辑 ")]) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "参保方案",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "230px"
    },
    on: {
      change: _vm.chooseEditProgId
    },
    model: {
      value: _vm.progId,
      callback: function callback($$v) {
        _vm.progId = $$v;
      },
      expression: "progId"
    }
  }, _vm._l(_vm.progNameList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.progName))]);
  }), 1)], 1), _vm.bushouTypeValue === 2 ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "补缴原因",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "230px"
    },
    attrs: {
      "default-value": _vm.defaultFeiyongtest
    },
    on: {
      change: _vm.chooseTypetest
    }
  }, _vm._l(_vm.FeiyongtestList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "起始年月",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "230px"
    },
    attrs: {
      value: _vm.shebaostartyear === "" ? "" : _vm.moment(_vm.shebaostartyear + "-" + _vm.shebaostartmonth, _vm.monthFormat),
      "disabled-date": _vm.disabledDate
    },
    on: {
      change: _vm.onShebaoStartChange
    }
  })], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "终止年月",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "230px"
    },
    attrs: {
      value: _vm.shebaoendyear === "" ? "" : _vm.moment(_vm.shebaoendyear + "-" + _vm.shebaoendmonth, _vm.monthFormat),
      "disabled-date": _vm.disabledDate
    },
    on: {
      change: _vm.onShebaoEndChange
    }
  })], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "费用归属",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "230px"
    },
    attrs: {
      "default-value": _vm.defaultFeiyong
    },
    on: {
      change: _vm.chooseType
    }
  }, _vm._l(_vm.FeiyongList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "预收滞纳金",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0
    },
    on: {
      change: _vm.onChangeZhiNajin
    },
    model: {
      value: _vm.zhanajin,
      callback: function callback($$v) {
        _vm.zhanajin = $$v;
      },
      expression: "zhanajin"
    }
  })], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "服务费"
    }
  }, [_c("a-input-number", {
    attrs: {
      placeholder: "每月服务费",
      min: 0
    },
    model: {
      value: _vm.serviceCost,
      callback: function callback($$v) {
        _vm.serviceCost = $$v;
      },
      expression: "serviceCost"
    }
  })], 1), _vm.bushouTypeValue === 1 ? _c("div", [_c("div", {
    staticStyle: {
      "margin-bottom": "15px",
      "margin-left": "100px",
      color: "#000000",
      "font-weight": "700"
    }
  }, [_vm._v("社保各项目基数")]), _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 1 || item.itemType === 3 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault,
        max: item.cardinalityMax,
        min: item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v("基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "]")])], 1) : _vm._e()];
  })], 2)], 1) : _vm._e(), _vm.bushouTypeValue === 2 && !_vm.payProvidentFund ? _c("div", [_c("div", {
    staticStyle: {
      "margin-bottom": "15px",
      "margin-left": "100px",
      color: "#000000",
      "font-weight": "700"
    }
  }, [_vm._v("公积金各项基数")]), _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 2 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault,
        max: item.cardinalityMax,
        min: item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v("基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "]")])], 1) : _vm._e()];
  })], 2)], 1) : _vm._e()], 2), _c("a-modal", {
    attrs: {
      title: "补收/补缴",
      visible: _vm.bushouTable,
      width: "1000px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.prviewBushou
    }
  }, [_vm._v("上一步")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.bushoubujiaof
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v(_vm._s(_vm.bushouTypeValue === 1 ? "社保补收" : "公积金补缴"))])]), _c("div", {
    staticStyle: {
      "margin-bottom": "0px"
    }
  }, [_c("a-form", [_c("a-table", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      columns: _vm.clumnDetailGuding ? _vm.columnDialogDetailguding : _vm.columnDialogDetail,
      "data-source": _vm.dataDetail2,
      scroll: {
        x: 600,
        y: 300
      },
      pagination: false,
      "row-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "belongsDate",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(_vm.formatDate2(text)))])];
      }
    }, {
      key: "persentCompany",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text) + "%")])];
      }
    }, {
      key: "persentPersonal",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text) + "%")])];
      }
    }])
  })], 1)], 1)], 2), _c("a-modal", {
    attrs: {
      title: "补收/补缴",
      visible: _vm.bushoubujiaook
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.BushouBuJiaoOkHandle
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px",
      "margin-top": "2px"
    },
    attrs: {
      src: require("@/assets/icons/duihao.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.bushouTypeValue === 1 ? "补收成功" : "补缴成功") + " ")])]), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "row",
      width: "100%",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#767885",
      "font-size": "14px",
      "font-weight": "400",
      "margin-left": "40px"
    }
  }, [_vm._v(" " + _vm._s(_vm.bushouTypeValue === 1 ? "补收记录可在参保管理中查看" : "补缴记录可在参保管理中查看") + " ")]), _c("div", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "14px",
      "margin-left": "6px",
      cursor: "pointer"
    },
    on: {
      click: _vm.canbaoguanli
    }
  }, [_vm._v("点击跳转 >")])]), _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#767885",
      "font-size": "14px",
      "font-weight": "400",
      "margin-left": "40px"
    }
  }, [_vm._v(" " + _vm._s(_vm.bushouTypeValue === 1 ? "操作人员须在官网操作补收流程" : "操作人员须在官网操作补缴流程") + " ")])], 2), _c("a-modal", {
    attrs: {
      title: "批量导入",
      visible: _vm.isDaoru,
      width: "730px"
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.ExportHandle
    }
  }, [_vm._v("导入")])], 1), _c("div", {
    staticClass: "modal-box2",
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "onwork"
  }, [_c("a-upload", {
    attrs: {
      name: "file",
      multiple: false,
      accept: ".xls, .xlsx",
      "show-upload-list": false,
      action: _vm.action,
      "custom-request": _vm.beforeUpload1
    },
    on: {
      change: _vm.handleChange
    }
  }, [_c("div", {
    staticClass: "staffInfo"
  }, [_c("img", {
    staticStyle: {
      width: "41px",
      height: "48px"
    },
    attrs: {
      src: require("../../../assets/img/上传.png")
    }
  })]), _vm.renliStatus === 0 ? _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.socFile ? _vm.socFile.name : "点击上传或拖拽上传") + " ")]) : _vm.renliStatus === 1 ? _c("div", {
    staticClass: "rowdialog"
  }, [_c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-right": "2px",
      "margin-bottom": "0px"
    },
    attrs: {
      src: require("../../../assets/icons/duihao.png")
    }
  }), _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v("上传成功")])]) : _vm.renliStatus === 2 ? _c("div", [_c("div", {
    staticClass: "rowdialog"
  }, [_vm.renliStatusFail === 0 ? _c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-right": "2px",
      "margin-top": "12px",
      "margin-bottom": "16px"
    },
    attrs: {
      src: require("../../../assets/icons/失败.png")
    }
  }) : _vm._e(), _vm.renliStatusFail === 1 ? _c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-right": "2px",
      "margin-top": "12px",
      "margin-bottom": "16px"
    },
    attrs: {
      src: require("../../../assets/icons/重复.png")
    }
  }) : _vm._e(), _vm.renliStatusFail === 0 ? _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d"
    }
  }, [_vm._v("上传失败")]) : _vm._e(), _vm.renliStatusFail === 1 ? _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d"
    }
  }, [_vm._v("上传重复")]) : _vm._e()]), _c("div", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "6px"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.renliClick(1);
      }
    }
  }, [_vm._v("查看名单")]), _vm.renliStatusFail === 0 ? _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" 请查看名单后重新上传 ")]) : _vm._e(), _vm.renliStatusFail === 1 ? _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" 继续导入将对原数据进行覆盖 ")]) : _vm._e()]) : _vm._e(), _c("div", {
    attrs: {
      sytle: "font-size:14px;color:#55565D;font-weight:bold;"
    }
  }, [_vm._v("人力资源和社会保障局表")]), _c("div", {
    attrs: {
      sytle: "font-size:14px;color:#55565D;font-weight:bold;"
    }
  }, [_vm._v("社保公积金统计表")])])], 1), _c("div", {
    staticClass: "onwork",
    staticStyle: {
      "margin-left": "15px",
      "margin-right": "15px"
    }
  }, [_c("a-upload", {
    attrs: {
      name: "file",
      multiple: false,
      accept: ".xls, .xlsx",
      "show-upload-list": false,
      action: _vm.action,
      "custom-request": _vm.beforeUpload2
    },
    on: {
      change: _vm.handleChange
    }
  }, [_c("div", {
    staticClass: "staffInfo"
  }, [_c("img", {
    staticStyle: {
      width: "41px",
      height: "48px"
    },
    attrs: {
      src: require("../../../assets/img/上传.png")
    }
  })]), _vm.yibaoStatus === 0 ? _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.meFile ? _vm.meFile.name : "点击上传或拖拽上传") + " ")]) : _vm.yibaoStatus === 1 ? _c("div", {
    staticClass: "rowdialog"
  }, [_c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-right": "2px",
      "margin-bottom": "0px"
    },
    attrs: {
      src: require("../../../assets/icons/duihao.png")
    }
  }), _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v("上传成功")])]) : _vm.yibaoStatus === 2 ? _c("div", [_c("div", {
    staticClass: "rowdialog"
  }, [_vm.yibaoStatusFail === 0 ? _c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-right": "2px",
      "margin-top": "12px",
      "margin-bottom": "16px"
    },
    attrs: {
      src: require("../../../assets/icons/失败.png")
    }
  }) : _vm._e(), _vm.yibaoStatusFail === 1 ? _c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-right": "2px",
      "margin-top": "12px",
      "margin-bottom": "16px"
    },
    attrs: {
      src: require("../../../assets/icons/重复.png")
    }
  }) : _vm._e(), _vm.yibaoStatusFail === 0 ? _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d"
    }
  }, [_vm._v("上传失败")]) : _vm._e(), _vm.renliStatusFail === 1 ? _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d"
    }
  }, [_vm._v("上传重复")]) : _vm._e()]), _c("div", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "6px"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.renliClick(2);
      }
    }
  }, [_vm._v("查看名单")]), _vm.renliStatusFail === 0 ? _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" 请查看名单后重新上传 ")]) : _vm._e(), _vm.renliStatusFail === 1 ? _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" 继续导入将对原数据进行覆盖 ")]) : _vm._e()]) : _vm._e(), _c("div", {
    attrs: {
      sytle: "font-size:14px;color:#55565D;font-weight:bold"
    }
  }, [_vm._v("医疗保障局表")])])], 1), _c("div", {
    staticClass: "onwork"
  }, [_c("a-upload", {
    attrs: {
      name: "file",
      multiple: false,
      accept: ".xls, .xlsx",
      "show-upload-list": false,
      action: _vm.action,
      "custom-request": _vm.beforeUpload3
    },
    on: {
      change: _vm.handleChange
    }
  }, [_c("div", {
    staticClass: "staffInfo"
  }, [_c("img", {
    staticStyle: {
      width: "41px",
      height: "48px"
    },
    attrs: {
      src: require("../../../assets/img/上传.png")
    }
  })]), _vm.gongjijinStatus === 0 ? _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.pfFile ? _vm.pfFile.name : "点击上传或拖拽上传") + " ")]) : _vm.gongjijinStatus === 1 ? _c("div", {
    staticClass: "rowdialog"
  }, [_c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-right": "2px",
      "margin-bottom": "0px"
    },
    attrs: {
      src: require("../../../assets/icons/duihao.png")
    }
  }), _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v("上传成功")])]) : _vm.gongjijinStatus === 2 ? _c("div", [_c("div", {
    staticClass: "rowdialog"
  }, [_vm.gongjijinStatusFail === 0 ? _c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-right": "2px",
      "margin-top": "12px",
      "margin-bottom": "16px"
    },
    attrs: {
      src: require("../../../assets/icons/失败.png")
    }
  }) : _vm._e(), _vm.gongjijinStatusFail === 1 ? _c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-right": "2px",
      "margin-top": "12px",
      "margin-bottom": "16px"
    },
    attrs: {
      src: require("../../../assets/icons/重复.png")
    }
  }) : _vm._e(), _vm.gongjijinStatusFail === 0 ? _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d"
    }
  }, [_vm._v("上传失败")]) : _vm._e(), _vm.renliStatusFail === 1 ? _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#55565d"
    }
  }, [_vm._v("上传重复")]) : _vm._e()]), _c("div", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "6px"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.renliClick(3);
      }
    }
  }, [_vm._v("查看名单")]), _vm.renliStatusFail === 0 ? _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" 请查看名单后重新上传 ")]) : _vm._e(), _vm.renliStatusFail === 1 ? _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4",
      "margin-top": "12px",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" 继续导入将对原数据进行覆盖 ")]) : _vm._e()]) : _vm._e(), _c("div", {
    attrs: {
      sytle: "font-size:14px;color:#55565D;font-weight:bold"
    }
  }, [_vm._v("住房公积金表")])])], 1)]), _c("div", {
    staticStyle: {
      "background-color": "#faf9f9",
      "border-radius": "5px",
      "padding-left": "15px",
      "padding-top": "15px",
      "padding-right": "15px",
      "padding-bottom": "15px"
    }
  }, [_c("div", {
    attrs: {
      sytle: "font-size:14px;color:#55565D;font-weight:bold;"
    }
  }, [_vm._v("温馨提示")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4",
      "margin-top": "12px"
    }
  }, [_vm._v("1、支持青岛地区用户导入从官方网站下载的")]), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4"
    }
  }, [_vm._v("2、其他地区用户下载“社保公积金统计表”模板，并上传至左侧上更改模板名称。")]), _c("div", {
    staticClass: "edit",
    staticStyle: {
      "font-size": "12px",
      "margin-left": "5px",
      cursor: "pointer"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("下载标准模板")])]), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4"
    }
  }, [_vm._v("3、导入人员名单需在花名册中，否则需先至花名册中新建。")]), _c("div", {
    staticClass: "edit",
    staticStyle: {
      "font-size": "12px",
      "margin-left": "5px",
      cursor: "pointer"
    },
    on: {
      click: _vm.huamingce
    }
  }, [_vm._v("立即前往")])])])], 2), _c("a-modal", {
    attrs: {
      title: "查看名单",
      visible: _vm.looknames,
      width: "800px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.closeLookNms
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.closeLookNms
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "0px"
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columnDialogName,
      "data-source": _vm.dataDetail3,
      scroll: {
        x: 500
      },
      colspan: "0",
      "row-key": "id"
    }
  })], 1)], 2), _c("employee-select", {
    attrs: {
      show: _vm.isChooseStaff,
      state: _vm.selectState,
      year: _vm.year,
      month: _vm.month
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.isChooseStaff = $event;
      },
      "update:state": function updateState($event) {
        _vm.selectState = $event;
      },
      change: _vm.onEmployeeSelectChange
    }
  }), _c("employee-select2", {
    attrs: {
      state: _vm.selectState2,
      show: _vm.isChooseStaff2
    },
    on: {
      "update:state": function updateState($event) {
        _vm.selectState2 = $event;
      },
      "update:show": function updateShow($event) {
        _vm.isChooseStaff2 = $event;
      },
      change: _vm.onEmployeeSelectChange2
    }
  }), _c("employee-select3", {
    attrs: {
      show: _vm.isChooseStaff3
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.isChooseStaff3 = $event;
      },
      change: _vm.onEmployeeSelectChange3,
      cancel: _vm.handleCancel
    }
  }), _c("screen", {
    attrs: {
      show: _vm.isChooseStaff4,
      monthCurrent: _vm.monthCurrent
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.isChooseStaff4 = $event;
      },
      change: _vm.differenceChange
    }
  }), _c("a-modal", {
    attrs: {
      title: "创建参保方案",
      visible: _vm.isSetting,
      width: "608px"
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }), _c("div", {
    staticClass: "modal-box"
  }, [_c("h4", [_vm._v("你还没有创建任何参保方案")]), _c("div", {
    staticClass: "content-box"
  }, [_c("div", {
    staticClass: "icon-box"
  }, [_c("div", {
    staticClass: "img-box"
  }, [_c("img", {
    staticClass: "icon",
    staticStyle: {
      width: "48px",
      height: "48px"
    },
    attrs: {
      src: require("../../../assets/icons/plus.png"),
      alt: ""
    }
  })]), _c("p", {
    staticClass: "text"
  }, [_vm._v("1.创建自主参保方案")])]), _c("span", [_vm._v("_____")]), _c("div", {
    staticClass: "icon-box"
  }, [_c("div", {
    staticClass: "img-box"
  }, [_c("img", {
    staticClass: "icon",
    staticStyle: {
      width: "48px",
      height: "48px"
    },
    attrs: {
      src: require("../../../assets/icons/face.png"),
      alt: ""
    }
  })]), _c("p", {
    staticClass: "text"
  }, [_vm._v("2.返回本月添加参保人")])]), _c("span", [_vm._v("_____")]), _c("div", {
    staticClass: "icon-box"
  }, [_c("div", {
    staticClass: "img-box"
  }, [_c("img", {
    staticClass: "icon",
    staticStyle: {
      width: "48px",
      height: "48px"
    },
    attrs: {
      src: require("../../../assets/icons/right.png"),
      alt: ""
    }
  })]), _c("p", {
    staticClass: "text"
  }, [_vm._v("3.添加参保成功")])])]), _c("a-button", {
    staticClass: "set-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.toSetMethod
    }
  }, [_vm._v("去创建参保方案")])], 1)]), _c("a-modal", {
    attrs: {
      title: "提示",
      visible: _vm.removeZengVisible
    },
    on: {
      cancel: _vm.handleCancel,
      ok: _vm.remvoeZenyuanOkHandel
    }
  }, [_c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px",
      "margin-left": "40px"
    },
    attrs: {
      src: require("@/assets/icons/ask.png")
    }
  }), _vm.tempEmpSocStatus === 2 || _vm.tempEmpSocStatus === 3 ? _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" 取消增员后将回到未参保状态，是否取消增员？ ")]) : _vm._e(), _vm.tempEmpSocStatus === 4 || _vm.tempEmpSocStatus === 5 ? _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" 取消减员后将回到正常参保状态，是否取消减员？ ")]) : _vm._e()]), _vm.tempEmpSocStatus === 4 || _vm.tempEmpSocStatus === 5 ? _c("a-radio-group", {
    staticStyle: {
      "margin-left": "90px",
      "margin-top": "20px"
    },
    on: {
      change: _vm.onTypeChange
    },
    model: {
      value: _vm.typeValue,
      callback: function callback($$v) {
        _vm.typeValue = $$v;
      },
      expression: "typeValue"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("更新参保人信息")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("删除人员参保记录")])], 1) : _vm._e()], 1), _c("a-modal", {
    attrs: {
      title: "提示",
      visible: _vm.isExistName
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleExistName
    }
  }, [_vm._v("知道了")])], 1), _c("div", {
    staticClass: "exiset-box"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("span", [_vm._v("*")]), _vm._v("系统已自动跳过以下人员")]), _c("div", {
    staticClass: "member"
  }, [_vm._v("已参保人员：")]), _vm._l(_vm.existName, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(item) + " "), _c("span", [_vm._v("的添加")])]);
  })], 2)]), _c("a-modal", {
    attrs: {
      title: "编辑",
      width: "600px",
      visible: _vm.isShowShebaoInfo
    },
    on: {
      ok: _vm.submitEdit,
      cancel: _vm.closeDrawer
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "参保方案"
    }
  }, [_c("a-select", {
    on: {
      change: _vm.chooseEditProgName
    },
    model: {
      value: _vm.evprogName,
      callback: function callback($$v) {
        _vm.evprogName = $$v;
      },
      expression: "evprogName"
    }
  }, _vm._l(_vm.progFilterList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.progName))]);
  }), 1)], 1), _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 1 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault,
        max: item.cardinalityMax,
        min: item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "] ")])], 1) : _vm._e()];
  })], 2), _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 3 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault,
        max: item.cardinalityMax,
        min: item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "] ")])], 1) : _vm._e()];
  })], 2), !_vm.payProvidentFund ? _c("div", [_c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 2 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault,
        max: item.cardinalityMax,
        min: item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "] ")])], 1) : _vm._e()];
  })], 2)], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "服务费"
    }
  }, [_c("a-input-number", {
    attrs: {
      placeholder: "每月服务费",
      min: 0
    },
    model: {
      value: _vm.serviceCost,
      callback: function callback($$v) {
        _vm.serviceCost = $$v;
      },
      expression: "serviceCost"
    }
  })], 1)], 1)], 1)]), _c("a-modal", {
    attrs: {
      title: "自定义表头",
      visible: _vm.ExportVisible,
      maskClosable: false,
      "confirm-loading": _vm.confirmLoading,
      width: 800
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleExportCancel
    }
  }, [_c("Transfer", {
    ref: "transfer",
    attrs: {
      defaultSelectedKeys: _vm.defaultSelectedKeys,
      checkedKey: _vm.checkedKey,
      transferData: _vm.selectAllHeadData
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "退费",
      width: "600px",
      visible: _vm.isTuifei
    },
    on: {
      ok: _vm.submitTuiFei,
      cancel: _vm.handleCancel
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "15px",
      "margin-left": "120px",
      color: "#000000",
      "font-weight": "700"
    }
  }, [_c("a-radio-group", {
    on: {
      change: _vm.onRadioChange
    },
    model: {
      value: _vm.tuifeiType,
      callback: function callback($$v) {
        _vm.tuifeiType = $$v;
      },
      expression: "tuifeiType"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1,
      disabled: _vm.getShebao(1)
    }
  }, [_vm._v("社保退费")]), _c("a-radio", {
    attrs: {
      value: 2,
      disabled: _vm.getShebao(2)
    }
  }, [_vm._v("医保退费")]), _c("a-radio", {
    attrs: {
      value: 3,
      disabled: _vm.getShebao(3)
    }
  }, [_vm._v("公积金退费")])], 1)], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "参保方案"
    }
  }, [_c("a-select", {
    on: {
      change: _vm.chooseEditTuiFeiProgName
    },
    model: {
      value: _vm.defaultFangan,
      callback: function callback($$v) {
        _vm.defaultFangan = $$v;
      },
      expression: "defaultFangan"
    }
  }, _vm._l(_vm.progTuiFeiFilterList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.progName))]);
  }), 1)], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "起始年月"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "default-value": _vm.moment(_vm.stopTuiFeiyear + "-" + _vm.stopTuiFeimonth, _vm.monthFormat),
      "disabled-date": _vm.disabledDateTuifei2
    },
    on: {
      change: _vm.onStopYearMonthChange_Tuifei
    }
  })], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "终止年月"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "default-value": _vm.moment(_vm.stopTuiFeiyear2 + "-" + _vm.stopTuiFeimonth2, _vm.monthFormat),
      "disabled-date": _vm.disabledDateTuiFei3
    },
    on: {
      change: _vm.onStopYearMonthChange_Tuifei2
    }
  })], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "费用归属"
    }
  }, [_c("div", [_vm._v("根据参保方案退费")])]), _vm.tuifeiType === 1 ? _c("div", {
    staticStyle: {
      "margin-bottom": "15px",
      "margin-left": "90px",
      color: "#000000",
      "font-weight": "700"
    }
  }, [_vm._v(" 社保各项目基数 ")]) : _vm._e(), _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 1 && _vm.tuifeiType === 1 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault,
        max: item.cardinalityMax,
        min: item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "] ")])], 1) : _vm._e()];
  })], 2), _vm.tuifeiType === 2 ? _c("div", {
    staticStyle: {
      "margin-bottom": "15px",
      "margin-left": "90px",
      color: "#000000",
      "font-weight": "700"
    }
  }, [_vm._v(" 医保各项目基数 ")]) : _vm._e(), _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 3 && _vm.tuifeiType === 2 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault,
        max: item.cardinalityMax,
        min: item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "] ")])], 1) : _vm._e()];
  })], 2), !_vm.payProvidentFund ? _c("div", [_vm.tuifeiType === 3 ? _c("div", {
    staticStyle: {
      "margin-bottom": "15px",
      "margin-left": "90px",
      color: "#000000",
      "font-weight": "700"
    }
  }, [_vm._v(" 公积金各项基数 ")]) : _vm._e(), _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 2 && _vm.tuifeiType === 3 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault,
        max: item.cardinalityMax,
        min: item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "] ")])], 1) : _vm._e()];
  })], 2)], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "个人退费金额"
    }
  }, [_c("a-input-number", {
    attrs: {
      disabled: true,
      value: _vm.personAmount
    }
  })], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "单位退费金额"
    }
  }, [_c("a-input-number", {
    attrs: {
      disabled: true,
      value: _vm.companyAmount
    }
  })], 1)], 1)], 1)]), _c("a-modal", {
    attrs: {
      title: "退费成功",
      visible: _vm.tuiFeiVisible
    },
    on: {
      cancel: _vm.handleCancel,
      ok: _vm.handleCancel
    }
  }, [_c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px",
      "margin-left": "40px"
    },
    attrs: {
      src: require("@/assets/icons/ask.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v("退费成功，请在参保管理中查看")])])])], 1), _vm.selectedRowKeys.length ? _c("div", {
    staticClass: "table-body-footer",
    class: _vm.selectedRowKeys.length > 0 || _vm.selectionBtnLock ? "table-body-footer-enter" : "table-body-footer-leave",
    style: {
      width: "calc(100vw - ".concat(188, "px)"),
      left: "".concat(188, "px")
    }
  }, [_c("div", {
    staticStyle: {
      "margin-left": "25px"
    }
  }, [_vm._v(" 选中 " + _vm._s(_vm.selectedRowKeys.length) + " 个 "), _c("a-button", {
    attrs: {
      type: "link",
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: _vm.cleanSelects
    }
  }, [_vm._v("清除")])], 1), _c("a-button", {
    staticClass: "mr12",
    on: {
      click: _vm.TBFclose
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: function click($event) {
        return _vm.exportExel();
      }
    }
  }, [_vm._v("导出")])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };