import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.sort.js";
var columns = [{
  title: "岗位名称",
  dataIndex: "name",
  align: "center"
}, {
  title: "岗位编号",
  dataIndex: "code",
  align: "center"
}, {
  title: "在职人数",
  dataIndex: "empChildrenCount",
  scopedSlots: {
    customRender: "empCount"
  },
  align: "center"
}, {
  title: "岗位说明",
  dataIndex: "desc",
  width: 350
}, {
  title: "操作",
  key: "action",
  align: "center",
  scopedSlots: {
    customRender: "action"
  }
}];
var empList = [{
  title: "姓名",
  dataIndex: "name",
  width: 120
}, {
  title: "员工手机号",
  dataIndex: "phone",
  ellipsis: true,
  width: 120
}];
import { getPositionInTree, getPositionInfoListPage, getListPositionType, addPosition, delPosition, editPosition } from "@/api/department/position";
export default {
  inject: ["reload"],
  data: function data() {
    return {
      name22: "",
      desc22: "",
      empList: empList,
      unitSearchForm: {},
      empGroup: [],
      gangNam: "",
      empVisible: false,
      current: [],
      companyId: "",
      parentId: "",
      selectId: "",
      menuData: [],
      tableData: [],
      targetData: {},
      columns: columns,
      titleType: "添加岗位类型",
      title: "新建岗位",
      name: "岗位",
      desc: "岗位说明",
      posTypeForm: this.$form.createForm(this),
      posForm: this.$form.createForm(this),
      loading: false,
      posTypeVisible: false,
      posVisible: false,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        editCompanyName: null,
        ticketRecord: null,
        receivableRecord: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      pagination2: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        pageSizeOptions: ["10", "20", "50", "100"],
        showQuickJumper: true,
        showSizeChanger: true
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;
    this.companyId = this.$store.state.hr.companyId;
    getPositionInTree({
      companyId: this.companyId
    }).then(function (res) {
      _this.parentId = res.data[0].id;
    });
    this.init();
  },
  methods: {
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.loadData();
    },
    handlePageChange: function handlePageChange(pagination, filters, sorter) {
      this.pagination2.current = pagination.current;
      this.pagination2.pageSize = pagination.pageSize;
    },
    unitNameChange: function unitNameChange(e) {
      if (e === "") {
        this.empGroup = this.empRecord.empChildrenList;
        this.pagination2.current = 1;
        this.pagination2.total = this.empRecord.empChildrenList.length;
      } else {
        this.empGroup = [];
        for (var i = 0; i < this.empRecord.empChildrenList.length; i++) {
          if ((this.empRecord.empChildrenList[i].name + "").indexOf(e + "") !== -1 || (this.empRecord.empChildrenList[i].phone + "").indexOf(e + "") !== -1) {
            this.empGroup.push(this.empRecord.empChildrenList[i]);
          }
        }
        this.pagination2.current = 1;
        this.pagination2.total = this.empGroup.length;
      }
    },
    showEmp: function showEmp(record) {
      this.empRecord = record;
      this.unitSearchForm = {};
      this.empVisible = true;
      this.gangNam = record.name;
      this.empGroup = this.empRecord.empChildrenList;
      this.pagination2.current = 1;
      this.pagination2.total = this.empRecord.empChildrenList.length;
    },
    handleEmpOk: function handleEmpOk() {
      this.empVisible = false;
    },
    textChange: function textChange(e, b) {},
    init: function init(deleteId) {
      var _this2 = this;
      getListPositionType(this.companyId).then(function (res) {
        _this2.menuData = res.data;
        if (_this2.menuData === null || _this2.menuData.length === 0) {
          _this2.targetData = {};
          _this2.current = [];
          _this2.selectId = "";
          _this2.name = "";
          _this2.desc = "";
        } else if (_this2.current.length === 0 || deleteId === _this2.selectId) {
          _this2.targetData = _this2.menuData[0];
          _this2.current = [_this2.targetData.id];
          _this2.selectId = _this2.targetData.id;
          _this2.name22 = _this2.targetData.name;
          _this2.desc22 = _this2.targetData.desc;
        }
        _this2.loadData();
      });
    },
    loadData: function loadData(text) {
      var _this3 = this;
      getPositionInfoListPage({
        companyId: this.companyId,
        parentId: this.selectId,
        type: 1,
        name: text,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }).then(function (res) {
        _this3.tableData = res.data.entities;
        _this3.pagination.total = res.data && res.data.entityCount;
      });
    },
    getSelectId: function getSelectId(target) {
      this.targetData = target;
      this.name22 = target.name;
      this.desc22 = target.desc;
      this.selectId = target.id;
      this.loadData();
    },
    delType: function delType() {
      var that = this;
      this.$confirm({
        title: "\u786E\u5B9A\u5220\u9664\u5C97\u4F4D\u7C7B\u578B ".concat(that.posTypeForm.getFieldValue("name"), " \u5417"),
        onOk: function onOk() {
          var deleteId = that.posTypeForm.getFieldValue("id");
          delPosition({
            id: deleteId
          }).then(function (res) {
            that.$notification["success"]({
              message: "success",
              description: "删除成功"
            });
            that.posTypeVisible = false;
            that.init(deleteId);
          });
        }
      });
    },
    delPos: function delPos() {
      var that = this;
      this.$confirm({
        title: "\u786E\u5B9A\u5220\u9664\u5C97\u4F4D ".concat(that.posForm.getFieldValue("name"), " \u5417"),
        onOk: function onOk() {
          delPosition({
            id: that.posForm.getFieldValue("id")
          }).then(function (res) {
            that.$notification["success"]({
              message: "success",
              description: "删除成功"
            });
            that.onClose("posVisible");
            that.init();
          });
        }
      });
    },
    addType: function addType() {
      this.titleType = "添加岗位类型";
      this.posTypeVisible = true;
      this.posTypeForm.resetFields();
    },
    editType: function editType(data) {
      var _this4 = this;
      this.titleType = "编辑岗位类型";
      this.posTypeVisible = true;
      setTimeout(function () {
        _this4.posTypeForm.setFieldsValue({
          name: data.name || _this4.targetData.name,
          id: data.id || _this4.targetData.id,
          desc: data.desc
        });
      }, 10);
    },
    addTypeSubmit: function addTypeSubmit(e) {
      var _this5 = this;
      var that = this;
      e.preventDefault();
      this.posTypeForm.validateFields(function (err, values) {
        if (!err) {
          values.level = 2;
          values.parentId = _this5.parentId;
          values.sort = 0;
          values.type = 2;
          values.companyId = _this5.companyId;
          if (_this5.titleType === "添加岗位类型") {
            addPosition(values).then(function (res) {
              _this5.$notification["success"]({
                message: "success",
                description: "添加成功"
              });
              _this5.onClose("posTypeVisible");
              _this5.init();
            });
          } else {
            editPosition(values).then(function (res) {
              _this5.$notification["success"]({
                message: "success",
                description: "编辑成功"
              });
              _this5.name22 = values.name;
              _this5.desc22 = values.desc;
              _this5.onClose("posTypeVisible");
              // that.reload()
              that.init();
            });
          }
        }
      });
    },
    addPos: function addPos() {
      var _this6 = this;
      this.desc = "";
      this.title = "新建岗位";
      this.posVisible = true;
      this.posForm.resetFields();
      setTimeout(function () {
        _this6.posForm.setFieldsValue({
          parentId: _this6.selectId
        });
      }, 300);
    },
    edit: function edit(data) {
      var _this7 = this;
      this.title = "编辑岗位";
      this.posVisible = true;
      setTimeout(function () {
        _this7.desc = data.desc;
        _this7.posForm.setFieldsValue({
          name: data.name,
          id: data.id,
          parentId: _this7.selectId,
          code: data.code
        });
      }, 10);
    },
    addPosSubmit: function addPosSubmit(e) {
      var _this8 = this;
      e.preventDefault();
      this.posForm.validateFields(function (err, values) {
        if (!err) {
          values.level = 3;
          values.sort = 0;
          values.type = 1;
          values.companyId = _this8.companyId;
          if (_this8.title === "新建岗位") {
            values.desc = _this8.desc;
            addPosition(values).then(function (res) {
              _this8.$notification["success"]({
                message: "success",
                description: "添加成功"
              });
              _this8.$store.dispatch("company/loadPositionsAct", _this8.companyId);
              _this8.onClose("posVisible");
              _this8.init();
            });
          } else {
            values.desc = _this8.desc;
            editPosition(values).then(function (res) {
              _this8.$notification["success"]({
                message: "success",
                description: "编辑成功"
              });
              _this8.$store.dispatch("company/loadPositionsAct", _this8.companyId);
              _this8.onClose("posVisible");
              _this8.loadData();
            });
          }
        }
        setTimeout(function () {
          return _this8.init();
        }, 200);
      });
    },
    onClose: function onClose(tag) {
      this[tag] = false;
    },
    onSearch: function onSearch(text) {
      this.pagination.current = 1;
      this.loadData(text);
    }
  }
};