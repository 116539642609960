import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { Wages, PaySet } from "@/api/wages";
export default {
  inject: ["reload"],
  name: "PaySet",
  components: {
    payEditor: function payEditor() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/common/pay_editor"));
      });
    }
  },
  data: function data() {
    return {
      addData: {
        beforeTax: false,
        // 是否税前发放
        enable: false /* 是否启用 */,
        name: "" /* 方案名称 */,
        nextMonthClear: false /* 是否次月清零 */,
        remark: "" /* 备注 */,
        salaryPlanFormulaAddDTOList: [] /* 计算公式及条件 */,
        systemProperty: false /* 是否是系统字段 */,
        type: 1 /* 工资方案所属类别 1、基本工资 2、岗位工资 3、奖金项 4、调整项 5、成本项 6、其它项 */
      },

      data: [],
      popover: false,
      index: null,
      payOptions: {},
      text: "test"
    };
  },
  created: function created() {
    /* 获取工资设置 */
    this.getPaySet();
  },
  computed: {
    rowSelection: function rowSelection() {
      var selectedRowKeys = this.selectedRowKeys;
      return {};
    }
  },
  methods: {
    /* 新增工资设置回调 */paySetCb: function paySetCb(data) {
      if (data.id) {
        Wages({
          url: PaySet.putPaySet,
          method: "PUT",
          data: data
        });
      } else {
        Wages({
          url: PaySet.addPaySet,
          method: "POST",
          data: data
        });
      }
      this.getPaySet();
    },
    /* 获取工资设置表 */getPaySet: function getPaySet() {
      var _this = this;
      Wages({
        url: PaySet.paySetList
      }).then(function (_ref) {
        var data = _ref.data;
        if (data.entities.length) _this.data = data.entities;
      });
    },
    /* switch */onChange: function onChange(checked) {},
    // 选择每一个item
    handleClick: function handleClick(e) {},
    titleClick: function titleClick(e) {},
    /* 添加子项 */addList: function addList() {
      this.popover = false;
      this.payOptions = {
        show: true,
        obj: {
          title: "添加子项",
          name: "名称",
          remark: "备注",
          type: 1,
          addData: this.addData
        }
      };
    },
    // 编辑
    editor: function editor(addData) {
      this.popover = false;
      this.payOptions = {
        show: true,
        obj: {
          title: "编辑",
          name: "名称",
          addData: addData
        }
      };
    },
    /* 编辑奖金 */editorMoney: function editorMoney(text) {}
  }
};