import request from "@/utils/request";
export function getCompanyUnits(params) {
  return request({
    // url: "/recruit2/api/v1/companyUnits/pageByCondition",
    url: "/hr/api/v1/childrenCompany/listAll/list",
    method: "GET",
    params: params
  });
}
export function getTransferAccountsPeoples(params) {
  return request({
    url: "/hr/api/v1/transferAccountsPeoples",
    method: "GET",
    params: params
  });
}
export function getPageOnJobEmpList(params) {
  return request({
    url: "hr/api/v1/empInfos/pageOnJobEmpList",
    method: "GET",
    params: params
  });
}
export function postSaveBatchAndDetail(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/saveBatchAndDetail",
    method: "POST",
    data: data
  });
}
export function postBuildDetailAddDTOList(data) {
  return request({
    url: "/hr/api/v1/transferAccountsDetails/buildDetailAddDTOList",
    method: "POST",
    data: data
  });
}