import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import moment from "moment";
function disabledDate(current) {
  return current && current > moment().endOf("day");
}

// 旧方法
export function basicsPages(idCard, moduleCode, data) {
  var fields = {};
  var fieldKeys = [];
  var _loop = function _loop() {
    var element = data[i];
    if (element.isOpen) {
      fieldKeys.push(element.fieldCode);
      fields[element.fieldCode] = {
        model: element.fieldCode,
        type: "",
        wrapper: {
          label: element.fieldName
        },
        customize: {
          style: "width: 100%",
          disabled: handleCustomizeDisabled(moduleCode, element, idCard),
          placeholder: element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName),
          format: element.fieldType === "3" || element.fieldType === 3 ? "YYYY-MM-DD" : "",
          formatter: handleCustomizeFormatter(element)
        },
        decorator: {
          initialValue: undefined,
          rules: [{
            required: element.isNecessary,
            message: element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName)
          }]
        }
      };
      if (["手机号", "联系电话", "证明人联系电话"].includes(element.fieldName)) {
        fields[element.fieldCode].decorator.rules.push({
          pattern: "^1[3456789]\\d{9}$",
          message: "手机号码格式错误"
        });
      }
      if (element.fieldName === "个人邮箱" || element.fieldName === "邮箱") {
        fields[element.fieldCode].decorator.rules.push({
          pattern: "\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*",
          message: "邮箱格式错误"
        });
      }
      if (element.fieldName === "身份证") {
        fields[element.fieldCode].decorator.rules.push({
          pattern: "^((^[HMhm](\\d{8}|\\d{10})$)|(^(\\d{8}|^[a-zA-Z]{1}(\\d{7}))$)|(^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$)|(^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])((\\d{4})|\\d{3}[Xx]$)))$",
          message: "身份证格式错误"
        });
      }
      // console.log(element.fieldName);
      if (element.fieldName === "出生日期") {
        fields[element.fieldCode].customize.disabledDate = disabledDate;
      }
      if (element.fieldName === "起缴时间") {
        fields[element.fieldCode].customize.format = "YYYY-MM";
        fields[element.fieldCode].extend = {
          mode: "month"
        };
      }
      if (element.fieldType === 1 || element.fieldType === "1") {
        var options = [];
        if (element.dropDownEnumList) {
          element.dropDownEnumList.forEach(function (item, index) {
            for (var key in item) {
              if (item[key] === "true") {
                item[key] = "true";
              }
              if (item[key] === "false") {
                item[key] = "false";
              }
            }
          });
          options = element.dropDownEnumList;
        }
        if (element.dropDownArray) {
          element.dropDownArray.forEach(function (element) {
            options.push({
              value: element,
              label: element
            });
          });
        }
        fields[element.fieldCode].customize.options = options;
        fields[element.fieldCode].type = "select";
      }
      if (element.fieldType === 2 || element.fieldType === "2") {
        fields[element.fieldCode].type = "input";
      }
      if (element.fieldType === 3 || element.fieldType === "3") {
        fields[element.fieldCode].type = "date";
      }
      if (element.fieldType === 4 || element.fieldType === "4") {
        fields[element.fieldCode].type = "textarea";
        fields[element.fieldCode].customize.autoSize = {
          minRows: 5,
          maxRows: 10
        };
      }
      if (element.fieldType === 5 || element.fieldType === "5") {
        fields[element.fieldCode].type = "number";
      }
      if (element.fieldType === 6 || element.fieldType === "6") {
        fields[element.fieldCode].type = "area";
      }
      if (element.fieldType === 7 || element.fieldType === "7") {
        fields[element.fieldCode].type = "imgUpload";
      }

      // 部门、岗位
      if (element.fieldCode === "preJoinDep" || element.fieldCode === "preJoinPosition") {
        fields[element.fieldCode].type = "treeSelect";
        fields[element.fieldCode].customize = {
          multiple: true,
          style: "width: 100%",
          allowClear: true,
          placeholder: element.fieldCode === "preJoinDep" ? "请选择部门" : "请选择岗位",
          showSearch: true,
          treeNodeFilterProp: "title"
        };
        fields[element.fieldCode].decorator = {
          initialValue: undefined
        };
        fields[element.fieldCode].extend = {
          dynamic: true,
          // 是否是动态的
          dynamicKey: element.fieldCode === "preJoinDep" ? "depNameDynamic" : "positionOptions"
        };
      }
      if (element.fieldCode === "contractSubject") {
        fields[element.fieldCode] = {
          model: "contractSubject",
          wrapper: {
            label: element.fieldName
          },
          type: "select_add",
          extend: {
            dictCode: "1223",
            allowClear: true
          }
        };
      }
    }
  };
  for (var i = 0; i < data.length; i++) {
    _loop();
  }
  return {
    fields: fields,
    fieldKeys: fieldKeys
  };
}
function handleCustomizeDisabled(moduleCode, element, idCard) {
  if (element.isDisabled) {
    return true;
  } else if (moduleCode === "basic") {
    if (["name", "idCard"].includes(element.fieldCode)) {
      return element.isDisabled;
    } else if (["empAge"].includes(element.fieldCode)) {
      return true;
    } else if (idCard && idCard.length === 18 && element.fieldCode === "birthday") {
      return true;
    } else if (idCard && idCard.length === 18 && element.fieldCode === "gender") {
      return true;
    }
  } else if (moduleCode === "socAndPf") {
    if (["startPaymentDate", "insuredCity"].includes(element.fieldCode)) {
      return true;
    }
  }
  return false;
}
function handleCustomizeFormatter(element) {
  if (element.fieldCode === "probationDays") {
    return function (value) {
      return value ? value + " 天" : "0 天";
    };
  } else if (element.fieldCode === "joinDateAge") {
    return function (value) {
      return value ? value + " 年" : "0.0 年";
    };
  } else {
    return null;
  }
}

// 供应商使用的新字段结构
export function basicsPagesNew(idCard, moduleCode, data) {
  var fields = {};
  var fieldKeys = [];
  var _loop2 = function _loop2() {
    var element = data[i];
    var fieldCodeNew = "".concat(element.id, "_").concat(element.fieldCode || "");
    if (element.isOpen) {
      fieldKeys.push(fieldCodeNew);
      fields[fieldCodeNew] = {
        model: fieldCodeNew,
        type: "",
        wrapper: {
          label: element.fieldName
        },
        customize: {
          style: "width: 100%",
          disabled: handleCustomizeDisabled(moduleCode, element, idCard),
          placeholder: element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName),
          format: element.fieldType === "3" || element.fieldType === 3 ? "YYYY-MM-DD" : "",
          formatter: handleCustomizeFormatter(element)
        },
        decorator: {
          initialValue: undefined,
          rules: [{
            required: element.isNecessary,
            message: element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName)
          }]
        }
      };
      if (["手机号", "联系电话", "证明人联系电话"].includes(element.fieldName)) {
        fields[fieldCodeNew].decorator.rules.push({
          pattern: "^1[3456789]\\d{9}$",
          message: "手机号码格式错误"
        });
      }
      if (element.fieldName === "个人邮箱" || element.fieldName === "邮箱") {
        fields[fieldCodeNew].decorator.rules.push({
          pattern: "\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*",
          message: "邮箱格式错误"
        });
      }
      if (element.fieldName === "身份证") {
        fields[fieldCodeNew].decorator.rules.push({
          pattern: "^((^[HMhm](\\d{8}|\\d{10})$)|(^(\\d{8}|^[a-zA-Z]{1}(\\d{7}))$)|(^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$)|(^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])((\\d{4})|\\d{3}[Xx]$)))$",
          message: "身份证格式错误"
        });
      }
      // console.log(element.fieldName);
      if (element.fieldName === "出生日期") {
        fields[fieldCodeNew].customize.disabledDate = disabledDate;
      }
      if (element.fieldName === "起缴时间") {
        fields[fieldCodeNew].customize.format = "YYYY-MM";
        fields[fieldCodeNew].extend = {
          mode: "month"
        };
      }
      if (element.fieldType === 1 || element.fieldType === "1") {
        var options = [];
        if (element.dropDownEnumList) {
          element.dropDownEnumList.forEach(function (item, index) {
            for (var key in item) {
              if (item[key] === "true") {
                item[key] = "true";
              }
              if (item[key] === "false") {
                item[key] = "false";
              }
            }
          });
          options = element.dropDownEnumList;
        }
        if (element.dropDownArray) {
          element.dropDownArray.forEach(function (element) {
            options.push({
              value: element,
              label: element
            });
          });
        }
        fields[fieldCodeNew].customize.options = options;
        fields[fieldCodeNew].type = "select";
      }
      if (element.fieldType === 2 || element.fieldType === "2") {
        fields[fieldCodeNew].type = "input";
      }
      if (element.fieldType === 3 || element.fieldType === "3") {
        fields[fieldCodeNew].type = "date";
      }
      if (element.fieldType === 4 || element.fieldType === "4") {
        fields[fieldCodeNew].type = "textarea";
        fields[fieldCodeNew].customize.autoSize = false;
      }
      if (element.fieldType === 5 || element.fieldType === "5") {
        fields[fieldCodeNew].type = "number";
      }
      if (element.fieldType === 6 || element.fieldType === "6") {
        fields[fieldCodeNew].type = "area";
      }
      if (element.fieldType === 7 || element.fieldType === "7") {
        fields[fieldCodeNew].type = "upload";
        fields[fieldCodeNew].customize["action"] = "/file/api/v1/general/upload/attachment";
        fields[fieldCodeNew].customize["name"] = "files";
        fields[fieldCodeNew].customize["listType"] = "picture-card";
        fields[fieldCodeNew].customize["accept"] = ".doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .jpg, .jpeg, .png";
      }
      if (element.fieldType === 8 || element.fieldType === "8") {
        fields[fieldCodeNew].type = "date";
        fields[fieldCodeNew].extend = {
          mode: "range"
        };
        fields[fieldCodeNew].customize.placeholder = undefined;
      }
      if (element.fieldType === 9 || element.fieldType === "9") {
        fields[fieldCodeNew].type = "area_detail";
      }
      if (element.fieldType === 10 || element.fieldType === "10") {
        fields[fieldCodeNew].type = "input";
        fields[fieldCodeNew].decorator.rules.push({
          pattern: "^1[3456789]\\d{9}$",
          message: "手机号码格式错误"
        });
      }
      if (element.fieldType === 11 || element.fieldType === "11") {
        fields[fieldCodeNew].type = "input";
        fields[fieldCodeNew].decorator.rules.push({
          pattern: "\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*",
          message: "邮箱格式错误"
        });
      }
    }
  };
  for (var i = 0; i < data.length; i++) {
    _loop2();
  }
  return {
    fields: fields,
    fieldKeys: fieldKeys
  };
}

// 待遇处理使用的新字段结构
export function basicsPagesForDycl(idCard, moduleCode, data, isEmpInfo) {
  var fields = {};
  var fieldKeys = [];
  var _loop3 = function _loop3() {
    var element = data[i];
    var fieldCodeNew = "".concat(element.id, "_").concat(element.fieldCode || "");
    if (element.isOpen) {
      fieldKeys.push(fieldCodeNew);
      fields[fieldCodeNew] = {
        model: fieldCodeNew,
        type: "",
        wrapper: {
          label: element.fieldName,
          valueId: element.valueId,
          fieldCode: element.fieldCode
        },
        customize: {
          style: "width: 100%",
          disabled: isEmpInfo ? element.fieldCode === "idCard" : handleCustomizeDisabled(moduleCode, element, idCard),
          placeholder: element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName),
          format: element.fieldType === "3" || element.fieldType === 3 ? "YYYY-MM-DD" : "",
          formatter: handleCustomizeFormatter(element)
        },
        decorator: {
          initialValue: undefined,
          rules: [{
            required: element.isNecessary,
            message: element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName)
          }]
        }
      };
      if (["手机号", "联系电话", "证明人联系电话"].includes(element.fieldName)) {
        fields[fieldCodeNew].decorator.rules.push({
          pattern: "^1[3456789]\\d{9}$",
          message: "手机号码格式错误"
        });
      }
      if (element.fieldName === "个人邮箱" || element.fieldName === "邮箱") {
        fields[fieldCodeNew].decorator.rules.push({
          pattern: "\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*",
          message: "邮箱格式错误"
        });
      }
      if (element.fieldName === "身份证") {
        fields[fieldCodeNew].decorator.rules.push({
          pattern: "^((^[HMhm](\\d{8}|\\d{10})$)|(^(\\d{8}|^[a-zA-Z]{1}(\\d{7}))$)|(^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$)|(^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])((\\d{4})|\\d{3}[Xx]$)))$",
          message: "身份证格式错误"
        });
      }
      // console.log(element.fieldName);
      if (element.fieldName === "出生日期") {
        fields[fieldCodeNew].customize.disabledDate = disabledDate;
      }
      if (element.fieldName === "起缴时间") {
        fields[fieldCodeNew].customize.format = "YYYY-MM";
        fields[fieldCodeNew].extend = {
          mode: "month"
        };
      }
      if (element.fieldType === 1 || element.fieldType === "1") {
        var options = [];
        if (element.dropDownEnumList) {
          element.dropDownEnumList.forEach(function (item, index) {
            for (var key in item) {
              if (item[key] === "true") {
                item[key] = "true";
              }
              if (item[key] === "false") {
                item[key] = "false";
              }
            }
          });
          options = element.dropDownEnumList;
        }
        if (element.dropDownArray) {
          element.dropDownArray.forEach(function (element) {
            options.push({
              value: element,
              label: element
            });
          });
        }
        fields[fieldCodeNew].customize.options = options;
        fields[fieldCodeNew].type = "select";
      }
      if (element.fieldType === 2 || element.fieldType === "2") {
        fields[fieldCodeNew].type = "input";
      }
      if (element.fieldType === 3 || element.fieldType === "3" || element.fieldType === 13 || element.fieldType === "13" || element.fieldType === 14 || element.fieldType === "14") {
        fields[fieldCodeNew].type = "date";
        if (element.fieldType === 3 || element.fieldType === "3") {
          fields[fieldCodeNew].customize.format = "YYYY-MM-DD";
        } else if (element.fieldType === 13 || element.fieldType === "13") {
          fields[fieldCodeNew].customize.format = "YYYY-MM";
          fields[fieldCodeNew].extend = {
            mode: "month"
          };
        } else if (element.fieldType === 14 || element.fieldType === "14") {
          fields[fieldCodeNew].customize.format = "YYYY-MM-DD HH:mm:ss";
          fields[fieldCodeNew].customize.showTime = true;
        }
      }
      if (element.fieldType === 4 || element.fieldType === "4") {
        fields[fieldCodeNew].type = "textarea";
        fields[fieldCodeNew].customize.autoSize = false;
      }
      if (element.fieldType === 5 || element.fieldType === "5") {
        fields[fieldCodeNew].type = "number";
      }
      if (element.fieldType === 6 || element.fieldType === "6" || element.fieldType === 12 || element.fieldType === "12") {
        fields[fieldCodeNew].type = "area";
        // 待遇处理用到
        if (element.fieldType === 12 || element.fieldType === "12") {
          fields[fieldCodeNew].extend = {
            level: 2
          };
        } else {
          fields[fieldCodeNew].extend = {
            level: 3
          };
        }
      }
      if (element.fieldType === 7 || element.fieldType === "7") {
        fields[fieldCodeNew].type = "upload";
        fields[fieldCodeNew].customize["action"] = "/file/api/v1/site/upload/inline";
        fields[fieldCodeNew].customize["name"] = "files";
        fields[fieldCodeNew].customize["listType"] = "picture-card";
        fields[fieldCodeNew].customize["accept"] = ".doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .jpg, .jpeg, .png";
        fields[fieldCodeNew].extend = {
          limit: 10,
          customCard: true
        };
      }
      if (element.fieldType === 8 || element.fieldType === "8") {
        fields[fieldCodeNew].type = "date";
        fields[fieldCodeNew].extend = {
          mode: "range"
        };
        fields[fieldCodeNew].customize.placeholder = undefined;
      }
      if (element.fieldType === 9 || element.fieldType === "9") {
        fields[fieldCodeNew].type = "area_detail";
      }
      if (element.fieldType === 10 || element.fieldType === "10") {
        fields[fieldCodeNew].type = "input";
        fields[fieldCodeNew].decorator.rules.push({
          pattern: "^1[3456789]\\d{9}$",
          message: "手机号码格式错误"
        });
      }
      if (element.fieldType === 11 || element.fieldType === "11") {
        fields[fieldCodeNew].type = "input";
        fields[fieldCodeNew].decorator.rules.push({
          pattern: "\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*",
          message: "邮箱格式错误"
        });
      }
    }
  };
  for (var i = 0; i < data.length; i++) {
    _loop3();
  }
  return {
    fields: fields,
    fieldKeys: fieldKeys
  };
}