import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
var columns = [{
  title: "参保月份",
  dataIndex: "month",
  key: "month",
  scopedSlots: {
    customRender: "month"
  },
  width: 100
}, {
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: 120
}, {
  title: "参保方案",
  dataIndex: "progName",
  key: "progName",
  width: 120
}, {
  title: "参保城市",
  dataIndex: "socCityName",
  key: "socCityName",
  width: 180
}, {
  title: "个人社保费",
  dataIndex: "personalSocAmount",
  key: "personalSocAmount",
  scopedSlots: {
    customRender: "isFixed"
  },
  width: 120
}, {
  title: "公司社保费",
  dataIndex: "companySocAmount",
  key: "companySocAmount",
  scopedSlots: {
    customRender: "companySocAmount"
  },
  width: 120
}, {
  title: "个人公积金",
  dataIndex: "personalPfAmount",
  key: "personalPfAmount",
  scopedSlots: {
    customRender: "personalPfAmount"
  },
  width: 120
}, {
  title: "公司公积金",
  dataIndex: "companyPfAmount",
  key: "companyPfAmount",
  scopedSlots: {
    customRender: "companyPfAmount"
  },
  width: 120
}, {
  title: " ",
  dataIndex: "action",
  key: "action",
  scopedSlots: {
    customRender: "action"
  },
  width: 50,
  fixed: "right"
}];
import echarts from "echarts";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
import { selectEmpSocInfoStatistical, selectMonthStatistical, selectSocProgItem } from "@/api/shebao/statistic";
export default {
  mixins: [mixin],
  props: {
    dateBox: {
      type: Object,
      default: function _default() {
        return {
          dataOfNum: []
        };
      }
    },
    queryType: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      abolitionImg: require("@/assets/home/lizhi.png"),
      verticalData: 0,
      emptyImg: require("@/assets/img/statistic/kong.png"),
      exportImg: require("@/assets/img/statistic/export.png"),
      borderImg: require("@/assets/img/statistic/border.png"),
      detailList: [],
      insuranceList: [],
      accumulationList: [],
      legendX: [],
      empList: [],
      companyId: this.$store.state.hr.companyId,
      columns: columns,
      xAxis: [],
      series: [],
      empId: null,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        pageSizeOptions: ["10", "20", "50", "100"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true
      }
    };
  },
  watch: {
    dateBox: {
      handler: function handler(val, oldVal) {
        this.getselectEmpSocInfoStatistical();
        this.grtSelectSocProgItem();
        this.getSelectMonthStatistical();
        console.log(this.dateBox);
      },
      deep: true
    },
    queryType: {
      handler: function handler(val, oldVal) {
        this.getselectEmpSocInfoStatistical();
        this.grtSelectSocProgItem();
        this.getSelectMonthStatistical();
      },
      deep: true
    }
  },
  created: function created() {
    // this.getselectEmpSocInfoStatistical();
  },
  methods: {
    goDetail: function goDetail(record) {
      this.empId = record.empId;
      this.$router.push({
        path: "./shebaoMingDan/detailed/".concat(this.empId)
      });
    },
    download: function download(i) {
      if (this.empList.length === 0) {
        this.$message.success("当前无统计数据");
        return;
      }
      // this.salaryTemplateSalaryIdList = this.salaryTemplateId.split(",");
      var token = this.$store.state.auth.token;
      this.params = {
        companyId: this.companyId,
        startMonth: this.dateBox.startMonth,
        endMonth: this.dateBox.endMonth,
        startYear: this.dateBox.startYear,
        endYear: this.dateBox.endYear,
        queryType: this.queryType,
        step: this.dateBox.step,
        isAcrossYear: this.dateBox.startYear !== this.dateBox.endYear
      };
      this.url = "/hr/api/v1/socStatistical/exportEmpSocInfo";
      this.excelName = "".concat(this.$store.state.hr.companyName, "_") + "社保/公积金月份统计导出详情" + ".xlsx";
      this.getDownload();
    },
    // 分页
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getselectEmpSocInfoStatistical();
    },
    grtSelectSocProgItem: function grtSelectSocProgItem() {
      var _this = this;
      selectSocProgItem({
        companyId: this.companyId,
        startMonth: this.dateBox.startMonth,
        endMonth: this.dateBox.endMonth,
        startYear: this.dateBox.startYear,
        endYear: this.dateBox.endYear,
        queryType: this.queryType,
        step: this.dateBox.step,
        isAcrossYear: this.dateBox.startYear !== this.dateBox.endYear
      }).then(function (res) {
        _this.detailList = JSON.parse(JSON.stringify(res.data.socDetail));
        _this.getSelectMonthStatistical();
      });
    },
    getSelectMonthStatistical: function getSelectMonthStatistical() {
      var _this2 = this;
      this.legendX = [];
      if (parseInt(this.dateBox.startYear) === parseInt(this.dateBox.endYear)) {
        for (var u = parseInt(this.dateBox.startMonth); u < parseInt(this.dateBox.endMonth) + 1; u++) {
          this.legendX.push(parseInt(this.dateBox.startYear) + "." + u);
        }
      } else {
        for (var i = parseInt(this.dateBox.startMonth); i < 13; i++) {
          this.legendX.push(parseInt(this.dateBox.startYear) + "." + i);
        }
        for (var o = 1; o < parseInt(this.dateBox.endYear) - parseInt(this.dateBox.startYear); o++) {
          for (var q = 1; q < 13; q++) {
            this.legendX.push(parseInt(this.dateBox.startYear) + o + "." + q);
          }
        }
        for (var a = 1; a < parseInt(this.dateBox.endMonth) + 1; a++) {
          this.legendX.push(parseInt(this.dateBox.endYear) + "." + a);
        }
      }
      this.insuranceList = [];
      selectMonthStatistical({
        // 查询某个时间段的各个月份的社保和公积金统计
        companyId: this.companyId,
        startMonth: this.dateBox.startMonth,
        endMonth: this.dateBox.endMonth,
        startYear: this.dateBox.startYear,
        endYear: this.dateBox.endYear,
        queryType: this.queryType,
        step: this.dateBox.step,
        isAcrossYear: this.dateBox.startYear !== this.dateBox.endYear
      }).then(function (res) {
        // if (res.data.length === 0) {
        //   this.$message.error(" 当前暂无社保和公积金统计");
        // }
        _this2.insuranceList = [];
        _this2.accumulationList = [];
        _this2.legendX.forEach(function (item, index) {
          _this2.insuranceList[index] = 0;
          _this2.accumulationList[index] = 0;
        });
        if (_this2.dateBox.startMonth === _this2.dateBox.endMonth && _this2.dateBox.startYear === _this2.dateBox.endYear) {
          _this2.dailyDetails2();
        } else {
          _this2.dailyDetails();
        }
        // 处理柱状图数据与X轴匹配
        var dateList = [];
        res.data.forEach(function (item1, index1) {
          dateList.push(parseInt(item1.year) + "." + parseInt(item1.month));
          _this2.legendX.forEach(function (item2, index2) {
            if (item2 === dateList[index1]) {
              if (_this2.queryType === 1) {
                _this2.insuranceList[index2] = JSON.parse(JSON.stringify(item1.socAmount));
                _this2.accumulationList[index2] = JSON.parse(JSON.stringify(item1.pfAmount));
              } else if (_this2.queryType === 2) {
                _this2.insuranceList[index2] = JSON.parse(JSON.stringify(item1.companySocAmount));
                _this2.accumulationList[index2] = JSON.parse(JSON.stringify(item1.companyPfAmount));
              } else if (_this2.queryType === 3) {
                _this2.insuranceList[index2] = JSON.parse(JSON.stringify(item1.personalSocAmount));
                _this2.accumulationList[index2] = JSON.parse(JSON.stringify(item1.personalPfAmount));
              }
            }
          });
        });
        _this2.verticalData = 0;
        _this2.insuranceList.forEach(function (item, index) {
          _this2.verticalData += parseInt(item);
        });
      });
    },
    getselectEmpSocInfoStatistical: function getselectEmpSocInfoStatistical() {
      var _this3 = this;
      // 查询社保统计页面中部右侧员工参保信息
      selectEmpSocInfoStatistical({
        companyId: this.companyId,
        startMonth: this.dateBox.startMonth,
        endMonth: this.dateBox.endMonth,
        startYear: this.dateBox.startYear,
        endYear: this.dateBox.endYear,
        queryType: this.queryType,
        step: this.dateBox.step,
        isAcrossYear: this.dateBox.startYear !== this.dateBox.endYear,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }).then(function (res) {
        _this3.empList = res.data.entities;
        res.data.entities.forEach(function (item, index) {
          _this3.empList[index].month = parseInt(item.month) + "月";
        });
        _this3.pagination.total = res.data.entityCount; // 共多少条
        _this3.pagination.pageSize = res.data.pageSize <= 0 ? 10 : res.data.pageSize; // 每页多少条
        _this3.pagination.current = res.data.pageNo <= 0 ? 1 : res.data.pageNo; // 当前第几页
      });
    },
    dailyDetails: function dailyDetails() {
      var _this4 = this;
      this.$nextTick(function () {
        var myChart1 = echarts.init(document.getElementById("main"));
        var option1 = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999"
              }
            }
          },
          legend: {
            icon: "circle",
            left: "70%",
            top: 20,
            data: ["社保", "公积金"]
          },
          xAxis: [{
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            type: "category",
            data: _this4.legendX,
            axisPointer: {
              interval: 0,
              type: "shadow"
            }
          }],
          yAxis: [{
            show: false,
            type: "value",
            name: "",
            min: 0,
            max: _this4.verticalData,
            interval: 50,
            axisLabel: {
              formatter: "{value} "
            }
          }],
          series: [{
            name: "社保",
            type: "bar",
            data: _this4.insuranceList,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: localStorage.getItem("primary")
                },
                // 柱图渐变色
                {
                  offset: 1,
                  color: localStorage.getItem("primary")
                } // 柱图渐变色
                ])
              },

              emphasis: {
                color: "#8686FAFF"
              }
            },
            z: 1
          }, {
            name: "公积金",
            type: "bar",
            data: _this4.accumulationList,
            z: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "#FFCD70FF"
                },
                // 柱图渐变色
                {
                  offset: 1,
                  color: "#FFA600FF"
                } // 柱图渐变色
                ])
              },

              emphasis: {
                color: "#FFCD70FF"
              }
            }
          }, {
            xAxisIndex: 1,
            name: "社保",
            type: "line",
            itemStyle: {
              normal: {
                color: localStorage.getItem("primary"),
                lineStyle: {
                  color: localStorage.getItem("primary")
                }
              }
            },
            data: _this4.insuranceList
          }, {
            xAxisIndex: 1,
            name: "公积金",
            type: "line",
            itemStyle: {
              normal: {
                color: "#FFA600FF",
                lineStyle: {
                  color: "#FFA600FF"
                }
              }
            },
            data: _this4.accumulationList
          }]
        };
        // 增加了一个隐藏的x轴，用来控制线图的点的位置
        option1.xAxis[1] = {
          type: "value",
          max: option1.xAxis[0].data.length * 100,
          show: false
        };
        option1.series[2].data = option1.series[2].data.map(function (x, i) {
          return [27 + i * 100, x];
        });
        option1.series[3].data = option1.series[3].data.map(function (x, i) {
          return [73 + i * 100, x];
        });
        myChart1.setOption(option1);
      });
    },
    dailyDetails2: function dailyDetails2() {
      var _this5 = this;
      this.$nextTick(function () {
        var myChart2 = echarts.init(document.getElementById("mainSingle"));
        var option2 = {
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b}: {c} ({d}%)"
          },
          color: ["#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16", "#E8684A", "#6DC8EC", "#9270CA", "#FF9D4D", "#269A99"],
          legend: {
            orient: "vertical",
            type: "scroll",
            icon: "circle",
            left: "65%",
            top: "center",
            data: ["养老保险（元）", "医疗保险（元）", "失业保险（元）", "工伤保险（元）", "补充大病医保（元）", "残保金（元）", "生育保险（元）", "补充养老保险（元）", "其他"]
          },
          series: [{
            name: "所占比例",
            type: "pie",
            radius: "60%",
            avoidLabelOverlap: false,
            center: ["30%", "50%"],
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "30",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            itemStyle: {
              // 此配置
              normal: {
                borderWidth: 1,
                borderColor: "#ffffff"
              },
              emphasis: {
                borderWidth: 0,
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            },
            data: [{
              value: _this5.detailList ? _this5.detailList["养老保险"] : 0,
              name: "养老保险（元）"
            }, {
              value: _this5.detailList ? _this5.detailList["医疗保险"] : 0,
              name: "医疗保险（元）"
            }, {
              value: _this5.detailList ? _this5.detailList["失业保险"] : 0,
              name: "失业保险（元）"
            }, {
              value: _this5.detailList ? _this5.detailList["失业保险"] : 0,
              name: "工伤保险（元）"
            }, {
              value: 0,
              name: "补充大病医保（元）"
            }, {
              value: 0,
              name: "残保金（元）"
            }, {
              value: _this5.detailList ? _this5.detailList["生育保险"] : 0,
              name: "生育保险（元）"
            }, {
              value: 0,
              name: "补充养老保险（元）"
            }, {
              value: _this5.detailList ? _this5.detailList["其他"] : 0,
              name: "其他"
            }]
          }]
        };
        myChart2.setOption(option2);
      });
    }
  }
};