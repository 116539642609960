import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "depInfo"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "avatar"
  }, [_c("a-avatar", {
    attrs: {
      size: 100,
      src: _vm.avatarUrl
    }
  })], 1), _c("div", {
    staticClass: "empInfo"
  }, [_c("div", [_vm._v(" " + _vm._s(_vm.name)), _c("span", {
    staticStyle: {
      display: "inline-block",
      "line-height": "22px",
      width: "60px",
      height: "22px",
      "text-align": "center",
      background: "rgb(255,223,223)",
      "border-radius": "11px",
      border: "1px solid rgba(255,0,0,0.42)",
      color: "rgb(255,124,124)",
      "font-size": "14px",
      "margin-left": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getEmployeeStatus(_vm.status, _vm.formalType)) + " ")])]), _c("div", {
    staticStyle: {
      display: "inline-block",
      width: "166px",
      height: "30px",
      "line-height": "30px",
      "text-align": "center",
      background: "rgba(252,175,79,0.12)",
      "border-radius": "15px",
      "font-size": "14px",
      "font-weight": "500"
    }
  }, [_vm._v(" 手机号 "), _c("span", {
    staticStyle: {
      color: "rgba(106, 101, 246,1)"
    }
  }, [_vm._v(_vm._s(_vm.phone))])]), _vm.joinDate ? _c("div", {
    staticStyle: {
      "margin-left": "10px",
      display: "inline-block",
      width: "166px",
      height: "30px",
      "line-height": "30px",
      "text-align": "center",
      background: "rgba(252,175,79,0.12)",
      "border-radius": "15px",
      "font-size": "14px",
      "font-weight": "500"
    }
  }, [_vm._v(" 入职时间 "), _c("span", {
    staticStyle: {
      color: "rgba(106, 101, 246,1)"
    }
  }, [_vm._v(_vm._s(_vm._f("formatDate")(_vm.joinDate)))])]) : _vm._e()])]), _c("div", {
    staticClass: "container"
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      colon: false,
      label: "岗位"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["positionName", {
        initialValue: _vm.empChanges.positionName
      }],
      expression: "['positionName', { initialValue: empChanges.positionName }]"
    }],
    attrs: {
      disabled: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "变更为"
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["currentPositionIdList"],
      expression: "['currentPositionIdList']"
    }],
    attrs: {
      multiple: "",
      "tree-data": _vm.positionOptions,
      "allow-clear": ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "部门"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["depName", {
        initialValue: _vm.empChanges.depName
      }],
      expression: "['depName', { initialValue: empChanges.depName }]"
    }],
    attrs: {
      disabled: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "变更为"
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["currentDepIdList"],
      expression: "['currentDepIdList']"
    }],
    attrs: {
      multiple: "",
      "tree-data": _vm.departmentOptions,
      "allow-clear": ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "职级"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["level", {
        initialValue: _vm.empChanges.level
      }],
      expression: "['level', { initialValue: empChanges.level }]"
    }],
    attrs: {
      disabled: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "变更为"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["currentLevel"],
      expression: "['currentLevel']"
    }]
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "主管"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["supervisor", {
        initialValue: _vm.empChanges.supervisor
      }],
      expression: "['supervisor', { initialValue: empChanges.supervisor }]"
    }],
    attrs: {
      disabled: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "变更为"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["currentSupervisor"],
      expression: "['currentSupervisor']"
    }]
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "管理形式"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["managerForm", {
        initialValue: _vm.empChanges.managerForm
      }],
      expression: "['managerForm', { initialValue: empChanges.managerForm }]"
    }],
    attrs: {
      disabled: "",
      options: _vm.managerModusOptions
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "变更为"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["currentManagerForm"],
      expression: "['currentManagerForm']"
    }],
    attrs: {
      options: _vm.managerModusOptions
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "工作城市"
    }
  }, [_c("area-component", {
    attrs: {
      disabled: true,
      region: _vm.empChanges.workCityId,
      "only-province": true
    },
    on: {
      "update:region": function updateRegion($event) {
        return _vm.$set(_vm.empChanges, "workCityId", $event);
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "变更为"
    }
  }, [_c("area-component", {
    attrs: {
      region: _vm.currentWorkCityId,
      "only-province": true
    },
    on: {
      "update:region": function updateRegion($event) {
        _vm.currentWorkCityId = $event;
      }
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      display: "block"
    },
    attrs: {
      colon: false,
      label: "调岗时间",
      required: ""
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["adjustmentDate", {
        rules: [{
          required: true,
          message: "请输入"
        }],
        initialValue: _vm.empChanges.adjustmentDate
      }],
      expression: "[\n            'adjustmentDate',\n            {\n              rules: [{ required: true, message: '请输入' }],\n              initialValue: empChanges.adjustmentDate\n            }\n          ]"
    }],
    attrs: {
      type: "date"
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      display: "block"
    },
    attrs: {
      colon: false,
      label: "调岗原因"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["adjustmentReason"],
      expression: "['adjustmentReason']"
    }],
    attrs: {
      type: "textarea"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "附件"
    }
  }, [_c("a-upload", {
    staticClass: "upload-ele",
    attrs: {
      "before-upload": _vm.beforeUpload,
      remove: _vm.handleFileRemove,
      accept: ".doc,.docx,.xls,.xlsx,.pdf,.zip,.rar",
      multiple: "",
      "file-list": _vm.fileList
    }
  }, [_c("a-button", {
    staticStyle: {
      height: "33px"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "upload"
    }
  }), _vm._v(" 选择文件")], 1), _c("a-button", {
    staticStyle: {
      "margin-left": "25px",
      height: "33px"
    },
    attrs: {
      type: "primary",
      loading: _vm.uploading,
      disabled: _vm.fileList.length === 0 || _vm.uploading
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.uploadFile.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.uploading ? "正在上传" : "开始上传"))]), _c("div", [_vm._v(" 文件格式为 DOC/DOCX/XLS/XLSX/PDF/ZIP/RAR，大小在5MB内 ，数量在5个以内 ")])], 1)], 1)], 1), _c("div", {
    staticClass: "button-area"
  }, [_c("a-button", {
    staticClass: "mr20",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticClass: "submit-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.transferJob
    }
  }, [_vm._v(" 提交 ")])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };