import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "signBox"
  }, [_c("p", {
    staticClass: "headerText"
  }, [_vm._v("考务报名")]), _c("a-divider"), _c("a-form", {
    staticStyle: {
      position: "relative",
      left: "0"
    },
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 12
      }
    },
    on: {
      submit: _vm.handleSubmit
    }
  }, [_vm._l(_vm.signUpList, function (item, index) {
    return _c("a-form-item", {
      key: index
    }, [_c("div", {
      staticClass: "signBox-item"
    }, [_c("div", {
      staticClass: "imgBox"
    }, [_c("p", [_vm._v("banner" + _vm._s(item.id))]), _c("UploadGeneralImg", {
      attrs: {
        limit: 1,
        "default-value": item.imgUrl,
        cover: true
      },
      model: {
        value: item.imgUrl,
        callback: function callback($$v) {
          _vm.$set(item, "imgUrl", $$v);
        },
        expression: "item.imgUrl"
      }
    })], 1), _c("a-form-item", [_c("label", [_vm._v("跳转链接：")]), _c("a-input", {
      staticStyle: {
        width: "350px"
      },
      attrs: {
        placeholder: "请输入"
      },
      model: {
        value: item.link,
        callback: function callback($$v) {
          _vm.$set(item, "link", $$v);
        },
        expression: "item.link"
      }
    })], 1)], 1)]);
  }), _c("a-divider", {
    staticStyle: {
      margin: "0"
    }
  }), _c("a-form-model-item", {
    attrs: {
      "wrapper-col": {
        span: 2,
        offset: 22
      }
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-top": "10px",
      "margin-bottom": "10px"
    },
    attrs: {
      type: "primary",
      "html-type": "submit"
    }
  }, [_vm._v(" 保存 ")])], 1)], 2)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };