import request from "@/utils/request";
// 工价规则
export function salaryCalculateRules(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules",
    method: "get",
    params: params
  });
}
export function empInfosShow(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/selectOutsourceSalary",
    method: "get",
    params: params
  });
}

// 查询花名册详情社保相关信息
export function findEmpSocRelated(params) {
  return request({
    url: "/hr/api/v1/empSocRelateds/findEmpSocRelated",
    method: "get",
    params: params
  });
}

// 花名册详情页修改社保相关信息
export function empUpdateSocDate(data) {
  return request({
    url: "/hr/api/v1/empSocRelateds/empUpdateSocDate",
    method: "PUT",
    data: data
  });
}

// 花名册详情页修改社保相关信息
export function getsalaryCalculateRuleGroups(salaryCalculateRuleGroupId) {
  return request({
    url: "hr/api/v1/salaryCalculateRuleGroups/".concat(salaryCalculateRuleGroupId),
    method: "get"
  });
}

// 花名册批量 查询用工单位
export function selectAllByEmpInfoQuery(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectAllByEmpInfoQuery",
    method: "get",
    params: params
  });
}
export function selectAllByCompanyIdAndIdCard(data) {
  return request({
    url: "/hr/api/v1/empInfos/selectAllByCompanyIdAndIdCard",
    method: "post",
    data: data
  });
}

// 花名册 工价规则
export function listAllByEmpId(params) {
  return request({
    url: "/salary/api/v1/fixedSalaryRecords/listAllByEmpId",
    method: "get",
    params: params
  });
}