import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wrap"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", {
    staticClass: "open",
    on: {
      click: _vm.toggleItem
    }
  }, [_vm.opened ? _c("a-icon", {
    attrs: {
      type: "minus"
    }
  }) : _c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1), _c("div", {
    staticStyle: {
      flex: "1"
    }
  }, [_c("div", {
    staticClass: "toptop"
  }, [_c("div", {
    staticClass: "fs16 fw500"
  }, [_vm._v(_vm._s(_vm.dataSource.templateName))]), _vm.dataSource.isFinish ? _c("div", [_c("span", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("完结时间：")]), _c("span", [_vm._v(_vm._s(_vm.formatDate(_vm.dataSource.finishDate)))])]) : _vm._e()]), _c("div", {
    staticClass: "detail"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("目前流程：")]), _c("span", [_vm._v(_vm._s(_vm.dataSource.currentWorkflow))])]), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("接单时间：")]), _c("span", [_vm._v(_vm._s(_vm.formatDate(_vm.dataSource.takeOrdersDate)))])]), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("接单客服：")]), _c("span", [_vm._v(_vm._s(_vm.dataSource.takeOrdersUserName))])])])])]), _vm.opened ? _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("事件概况")]), _c("div", {
    staticClass: "pBox2"
  }, _vm._l(_vm.eventFields, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "pItem"
    }, [_c("div", {
      staticClass: "subtitle"
    }, [_vm._v(_vm._s(item.fieldName) + "：")]), _vm.isUrl(item) ? _c("div", {
      staticClass: "subcon"
    }, [_vm._l(item.content.split(","), function (url) {
      return [_c("PreviewItem", {
        key: url,
        attrs: {
          url: url
        }
      })];
    })], 2) : _c("div", {
      staticClass: "subcon"
    }, [_vm._v(_vm._s(_vm.deFormatValuesItem(item)))])]);
  }), 0), _c("div", {
    staticClass: "title"
  }, [_vm._v("处理过程")]), _vm._l(_vm.processes, function (li, idx) {
    return _c("div", {
      key: "".concat(li.name, "_").concat(idx),
      staticClass: "process",
      class: {
        first: idx === 0
      }
    }, [_c("div", {
      staticClass: "pTitle"
    }, [_vm._v(_vm._s(idx + 1) + " " + _vm._s(li.moduleName))]), _c("div", {
      staticClass: "pBox"
    }, _vm._l(li.formGroupFieldList, function (item) {
      return _c("div", {
        key: item.id,
        staticClass: "pItem"
      }, [_c("div", {
        staticClass: "subtitle"
      }, [_vm._v(_vm._s(item.fieldName) + "：")]), _vm.isUrl(item) ? _c("div", {
        staticClass: "subcon"
      }, [_vm._l(item.content.split(","), function (url) {
        return [_c("PreviewItem", {
          key: url,
          attrs: {
            url: url
          }
        })];
      })], 2) : _c("div", {
        staticClass: "subcon"
      }, [_vm._v(_vm._s(_vm.deFormatValuesItem(item)))])]);
    }), 0)]);
  })], 2) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };