import SelectCompany from "@/components/SelectCompany";
import SummaryBarOverview from "./components/summaryBar_overview.vue";
import OverviewChart from "./components/overview_chart.vue";
import ListElectronic from "./list_electronic.vue";
import ListPaper from "./list_paper.vue";
import ListAll from "./list_all.vue";
export default {
  name: "",
  components: {
    ListElectronic: ListElectronic,
    ListPaper: ListPaper,
    ListAll: ListAll,
    SummaryBarOverview: SummaryBarOverview,
    OverviewChart: OverviewChart,
    SelectCompany: SelectCompany
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      activeKey: "all",
      electronicDidInit: false
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onTabChange: function onTabChange(tab) {
      var _this = this;
      if (tab === "electronic" && !this.electronicDidInit) {
        this.$nextTick(function () {
          if (_this.$refs.list_electronic) {
            _this.$refs.list_electronic.selectFunc();
            _this.electronicDidInit = true;
          }
        });
      }
    },
    onSelectChange: function onSelectChange(tab, value) {
      var _this2 = this;
      var dom = document.querySelector(".main-content-wrapper");
      if (tab === "all") {
        this.activeKey = "all";
        this.$nextTick(function () {
          dom.scrollTop = 746;
          if (_this2.$refs.list_all) {
            _this2.$refs.list_all.selectFunc(value);
          }
        });
      } else if (tab === "electronic") {
        this.activeKey = "electronic";
        this.$nextTick(function () {
          dom.scrollTop = 746;
          if (_this2.$refs.list_electronic) {
            _this2.$refs.list_electronic.selectFunc(value);
            _this2.electronicDidInit = true;
          }
        });
      } else if (tab === "paper") {
        this.activeKey = "paper";
        this.$nextTick(function () {
          dom.scrollTop = 746;
          if (_this2.$refs.list_paper) {
            _this2.$refs.list_paper.selectFunc(value);
          }
        });
      }
    }
  }
};