var _this = this;
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.error.cause.js";
import axios from "axios";
import { notification } from "ant-design-vue";
import store from "@/store";
import { getToken } from "xqjr-module-auth";
import { customAlphabet } from "nanoid";
var CancelToken = axios.CancelToken;
var nanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyz", 8);

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 60000,
  // request timeout
  routeChangeCancel: true // 切换路由时是否取消
});

var params2Query = function params2Query(params) {
  if (!params) {
    return "";
  }
  var keys = Object.keys(params);
  var url = "?";
  for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
    var key = _keys[_i];
    if (!(params[key] === null || params[key] === undefined)) {
      url += "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(params[key]), "&");
    }
  }
  url = url.substring(0, url.length - 1);
  return url;
};
var cancelToken = [];

// request interceptor
service.interceptors.request.use(function (config) {
  // // 如果同样的请求存在，取消之前的请求，处理重复请求的话请求标示就得变，因为可能参数不一样，还可能post data不同
  // const requestUrl = `${config.method}_${config.url}_${JSON.stringify(config.params || {})}`;
  // const cancelIndex = cancelToken.findIndex((item) => item.url === requestUrl);
  // if (cancelIndex > -1) {
  //   cancelToken[cancelIndex].cancel();
  //   cancelToken.splice(cancelIndex, 1);
  // }
  var requestUrl = "".concat(config.method, "_").concat(config.url, "_").concat(nanoid());
  config.requestUrl = requestUrl;
  // 新请求加入 cancel token 配置，并存入本地缓存
  var source = CancelToken.source();
  config.cancelToken = source.token;
  cancelToken.push({
    url: requestUrl,
    cancel: source.cancel,
    routeChangeCancel: config.routeChangeCancel === undefined ? true : config.routeChangeCancel
  });
  config.headers.deviceType = store.state.settings.deviceType || "web";
  config.headers["token"] = getToken();
  if (config.method === "post") {
    if (typeof config.data === "string" && config.data.indexOf("=") === -1) {
      config.data = JSON.stringify(config.data);
      config.headers["Content-Type"] = "application/json;charset=UTF-8";
    }
  } else if (config.method === "get") {
    config.url = config.url + params2Query(config.params);
    config.params = {};
  }
  return config;
}, function (error) {
  console.log("debug log --> ", error);
  return Promise.reject("请求失败");
});
var responseDeleteCancelToken = function responseDeleteCancelToken(config) {
  // 成功的请求从本地缓存中清掉
  var cancelIndex = cancelToken.findIndex(function (item) {
    return item.url === config.requestUrl;
  });
  if (cancelIndex > -1) {
    cancelToken.splice(cancelIndex, 1);
  }
};

// response interceptor
service.interceptors.response.use(function (response) {
  responseDeleteCancelToken(response.config);
  if (response.data) {
    if (response.data.code === 401) {
      store.dispatch("auth/refreshToken").then(function () {
        localStorage.setItem("refreshStatus", "done");
      }).catch(function () {
        setTimeout(function () {
          store.dispatch("auth/resetToken").then(function () {
            location.reload();
          });
        }, 1000);
      });
    } else if (response.data.code === 403) {
      switch (response.data.data) {
        case "check":
          store.dispatch("auth/refreshToken").then(function () {
            localStorage.setItem("refreshStatus", "done");
            notification.warning({
              message: "提示",
              description: "请求提交失败，请重新提交"
            });
          }).catch(function () {
            localStorage.setItem("refreshStatus", "done");
            notification.warning({
              message: "警告",
              description: "登录已失效，即将跳转到登录页面"
            });
            setTimeout(function () {
              store.dispatch("auth/resetToken").then(function () {
                location.reload();
              });
            }, 2000);
          });
          break;
        case "refresh":
          setTimeout(function () {
            store.dispatch("auth/resetToken").then(function () {
              location.reload();
            });
          }, 2000);
          break;
        default:
          notification.warning({
            message: "警告",
            description: response.data.msg
          });
          break;
      }
    } else if (response.data.code === 200) {
      if (response.data.success) {
        return response.data;
      } else {
        var _response$config;
        if (!((_response$config = response.config) !== null && _response$config !== void 0 && _response$config.noNotification)) {
          notification.error({
            message: "失败",
            description: response.data.msg || "请求失败"
          });
        }
        return Promise.reject(new Error(response.data.msg || "请求失败"));
      }
    } else if (response.data.code === 400) {
      var reqStatus = localStorage.getItem("reqStatus");
      if (reqStatus === "400") {
        return Promise.reject(new Error(response.data.msg || "参数错误"));
      }
      localStorage.setItem("reqStatus", "400");
      var timer = setTimeout(function () {
        localStorage.removeItem("reqStatus");
        clearTimeout(timer);
      }, 2000);
      notification.error({
        message: "失败",
        description: response.data.success === false ? response.data.msg || "参数错误" : "参数错误"
      });
      if (response.data.msg === "当前登录租户已到期，请联系管理员续期后重试") {
        store.commit("hr/setAdminNavState", "workbench");
        localStorage.removeItem("isFirstLogin");
        store.dispatch("auth/logout");
        _this.$router.push("/auth/login");
      }
      return Promise.reject(new Error(response.data.msg || "参数错误"));
    } else if (response.data.code === 101) {
      // 租户已过期
      if (response.data.data) {
        notification.error({
          message: "提示",
          description: response.data.msg
        });
        // TODO 这里查一下有没有返回租户名称，有的话把租户名称也传过去
        store.dispatch("auth/switchSite", response.data.data).then(function () {
          localStorage.setItem("switchSite", "true");
          localStorage.setItem("siteId", response.data.data);
          setTimeout(function () {
            location.reload();
          }, 1900);
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      } else {
        store.commit("hr/setAdminNavState", "workbench");
        localStorage.removeItem("isFirstLogin");
        store.dispatch("auth/logout");
        _this.$router.push("/auth/login");
      }
    } else {
      var _reqStatus = localStorage.getItem("reqStatus");
      if (_reqStatus === "500") {
        return Promise.reject(new Error(response.data.msg || "请求失败"));
      }
      localStorage.setItem("reqStatus", "500");
      var _timer = setTimeout(function () {
        localStorage.removeItem("reqStatus");
        clearTimeout(_timer);
      }, 2000);
      var msg = response.data.success === false ? response.data.msg || "请求失败" : "请求失败";
      notification.error({
        message: "失败",
        description: msg
      });
      return Promise.reject(new Error(msg));
    }
  } else {
    return Promise.reject(new Error("请求失败"));
  }
}, function (error) {
  if (axios.isCancel(error)) {
    error.selfCancel = true;
  }
  // 如果是自己取消的请求，不用处理
  if (!error.selfCancel) {
    if (error.response) {
      responseDeleteCancelToken(error.response.config);
      if (error.response.status === 401) {
        var reqStatus = localStorage.getItem("reqStatus");
        if (reqStatus === "401") {
          return Promise.reject(error);
        }
        localStorage.setItem("reqStatus", "401");
        var timer = setTimeout(function () {
          localStorage.removeItem("reqStatus");
          clearTimeout(timer);
        }, 2000);
        if (error.response.data.data === "kicked") {
          notification.warning({
            message: "警告",
            description: error.response.data.msg
          });
          setTimeout(function () {
            store.dispatch("auth/resetToken").then(function () {
              location.reload();
            });
          }, 2000);
        } else {
          store.dispatch("auth/refreshToken").then(function () {
            localStorage.setItem("refreshStatus", "done");
            if (error.response.config.method === "get") {
              notification.warning({
                message: "提示",
                description: "登录已过期，将刷新页面"
              });
              location.reload();
            } else {
              notification.warning({
                message: "提示",
                description: "登录已过期，请重新提交"
              });
            }
          }).catch(function () {
            localStorage.setItem("refreshStatus", "done");
            // notification.warning({
            //   message: "警告",
            //   description: "登录已失效，即将跳转到登录页面"
            // });
            setTimeout(function () {
              store.dispatch("auth/resetToken").then(function () {
                location.reload();
              });
            }, 2000);
          });
        }
      } else if (error.response.status === 403) {
        notification.warning({
          message: "警告",
          description: "没有操作权限"
        });
      } else if (error.response.status === 502) {
        var _reqStatus2 = localStorage.getItem("reqStatus");
        if (_reqStatus2 === "502") {
          return Promise.reject(new Error(error.response.data.msg || "服务器发生错误"));
        }
        localStorage.setItem("reqStatus", "502");
        var _timer2 = setTimeout(function () {
          localStorage.removeItem("reqStatus");
          clearTimeout(_timer2);
        }, 2000);
        notification.error({
          message: "失败",
          description: "服务器发生错误"
        });
      } else {
        notification.error({
          message: "失败",
          description: error.message || "请求失败"
        });
      }
    } else if (error.request) {
      notification.error({
        message: "错误",
        description: error.message || "请求发送错误"
      });
    } else {
      notification.error({
        message: "错误",
        description: error.message
      });
    }
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
});
export default service;
export { cancelToken };