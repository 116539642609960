import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
var initColumns = [{
  title: "姓名",
  dataIndex: "empName",
  key: "empName",
  width: 120,
  fixed: "left"
}, {
  title: "身份证",
  dataIndex: "idCard",
  key: "idCard",
  width: 230,
  fixed: "left"
}, {
  title: "应发工资",
  dataIndex: "shouldWages",
  key: "shouldWages",
  width: 120,
  scopedSlots: {
    customRender: "shouldWages"
  }
}, {
  title: "个人社保",
  dataIndex: "empSocialSecurity",
  key: "empSocialSecurity",
  width: 120,
  scopedSlots: {
    customRender: "empSocialSecurity"
  }
}, {
  title: "企业社保",
  dataIndex: "enterpriseSocialSecurity",
  key: "enterpriseSocialSecurity",
  width: 120,
  scopedSlots: {
    customRender: "enterpriseSocialSecurity"
  }
}, {
  title: "个人公积金",
  dataIndex: "empProvidentFund",
  key: "empProvidentFund",
  width: 140,
  scopedSlots: {
    customRender: "empProvidentFund"
  }
}, {
  title: "企业公积金",
  dataIndex: "enterpriseProvidentFund",
  key: "enterpriseProvidentFund",
  width: 140,
  scopedSlots: {
    customRender: "enterpriseProvidentFund"
  }
}, {
  title: "个人所得税",
  dataIndex: "tax",
  key: "tax",
  width: 140,
  scopedSlots: {
    customRender: "tax"
  }
}, {
  title: "实发工资",
  dataIndex: "paidWages",
  key: "paidWages",
  width: 120,
  scopedSlots: {
    customRender: "paidWages"
  }
}, {
  title: "服务费",
  dataIndex: "serviceCharge",
  key: "serviceCharge",
  width: 120,
  scopedSlots: {
    customRender: "serviceCharge"
  }
}];
import { getDetails, getTemplates, updateAutoTaxGrand, getSelectHeaders as _getSelectHeaders, updateHead } from "./api";
import draggable from "vuedraggable";
import axios from "axios";
import qs from "qs";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
export default {
  mixins: [mixin],
  components: {
    draggable: draggable
  },
  data: function data() {
    return {
      tempSelectedHead: [],
      tempNoSelectedHead: [],
      headVisible: false,
      approStatus: false,
      abolitionVisible: false,
      tableName: undefined,
      generatelVisible: false,
      title: "",
      empVisible: false,
      empList: [],
      empIdsList: [],
      empIdList: [],
      createdList: [],
      salaryTemplateSalaryIdList: [],
      buttonArchive: {},
      isArchive: true,
      buttonState: true,
      inputName: null,
      companyName: this.$store.state.hr.companyName,
      isAll: false,
      selectedList: [],
      defaultSelectedKeys: [],
      chooseMemberVisible: false,
      selectedRowId: [],
      selectedRowKeys: [],
      loading: false,
      selectRowKey: [],
      dataList: [],
      initColumns: initColumns,
      tableId: null,
      columns: [],
      detailList: [],
      allGroupId: [],
      groupList: [],
      empAllList: [],
      templateId: null,
      empStatus: null,
      salaryTemplateId: null,
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 4
      },
      selectString: {},
      wrapperCol: {
        span: 14
      },
      salaryGroupSalaryId: null,
      monthly: 5,
      yearly: 2021,
      bankType: undefined,
      statisticalVisible: false,
      formType: "",
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      }
    };
  },
  created: function created() {
    this.detailList = JSON.parse(this.$route.query.dataList);
    console.log(this.detailList, this.formType, " this.detailList");
    this.monthly = this.$route.query.monthly;
    this.yearly = this.$route.query.yearly;
    this.formType = this.$route.query.formType;
    this.getDetailsList();
    this.getSelectHeaders();
    // this.initColumns.push({
    //   title: "服务费",
    //   dataIndex: "serviceCharge",
    //   key: "serviceCharge",
    //   width: 120
    // });
  },
  beforeDestory: function beforeDestory() {},
  methods: {
    reload: function reload() {
      this.getDetailsList();
      this.$notification["success"]({
        message: "成功",
        description: "刷新成功"
      });
    },
    getSelectHeaders: function getSelectHeaders() {
      var _this = this;
      _getSelectHeaders({
        id: this.detailList.subjectId,
        type: this.detailList.type,
        customerDetailsIdList: this.detailList.customerDetailsIds,
        monthly: this.monthly,
        yearly: this.yearly
      }).then(function (res) {
        var arr = [];
        _this.selectString = res.data;
        _this.tempSelectedHead = [];
        arr = JSON.parse(JSON.stringify(res.data.canSee));
        arr.forEach(function (item, index) {
          if (item.fieldEnName) {
            _this.tempSelectedHead.push({
              title: item.fieldName,
              dataIndex: item.fieldEnName,
              key: item.fieldEnName,
              width: item.fieldName === "身份证号" ? 200 : 130,
              fixed: item.fieldName === "姓名" || item.fieldName === "身份证号" ? "left" : null,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          } else {
            _this.tempSelectedHead.push({
              title: item.fieldName,
              dataIndex: item.fieldName,
              key: item.fieldName,
              width: item.fieldName === "身份证号" ? 200 : 130,
              fixed: item.fieldName === "姓名" || item === "身份证号" ? "left" : null,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          }
        });
        _this.columns = JSON.parse(JSON.stringify(_this.tempSelectedHead));
        var arr2 = [];
        _this.tempNoSelectedHead = [];
        // this.selectString = JSON.parse(JSON.stringify(this.selectString.split(",")));
        arr2 = JSON.parse(JSON.stringify(res.data.ignore));
        arr2.forEach(function (item, index) {
          if (item.fieldEnName) {
            _this.tempNoSelectedHead.push({
              title: item.fieldName,
              dataIndex: item.fieldEnName,
              key: item.fieldEnName,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          } else {
            _this.tempNoSelectedHead.push({
              title: item.fieldName,
              dataIndex: item.fieldName,
              key: item.fieldName,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          }
        });
      });
    },
    handleHeadSearch: function handleHeadSearch() {
      var _this2 = this;
      this.selectString.canSee = this.tempSelectedHead;
      this.selectString.ignore = this.tempNoSelectedHead;
      updateHead(this.selectString).then(function (res) {
        _this2.getSelectHeaders();
        _this2.headVisible = false;
      });
    },
    handleHeadChange: function handleHeadChange(visible) {
      if (visible === false) {
        this.tempSelectedHead = JSON.parse(JSON.stringify(this.selectedHead));
        this.tempNoSelectedHead = JSON.parse(JSON.stringify(this.noSelectedHead));
      }
    },
    addSelectedHead: function addSelectedHead(index) {
      var temp = JSON.parse(JSON.stringify(this.tempNoSelectedHead[index]));
      this.tempNoSelectedHead.splice(index, 1);
      this.tempSelectedHead.push(temp);
    },
    deleteSelectedHead: function deleteSelectedHead(e, index) {
      e.preventDefault();
      var temp = JSON.parse(JSON.stringify(this.tempSelectedHead[index]));
      this.tempSelectedHead.splice(index, 1);
      this.tempNoSelectedHead.push(temp);
    },
    handleHeadCancel: function handleHeadCancel() {
      this.headVisible = false;
      this.tempSelectedHead = JSON.parse(JSON.stringify(this.selectedHead));
      this.tempNoSelectedHead = JSON.parse(JSON.stringify(this.noSelectedHead));
    },
    onStart: function onStart() {
      this.drag = true;
    },
    onEnd: function onEnd() {
      this.drag = false;
    },
    onMove: function onMove(e) {
      if (e.relatedContext.element.title === "姓名" || e.relatedContext.element.title === "身份证号") return false;
      return true;
    },
    autoAdd: function autoAdd() {
      var _this3 = this;
      updateAutoTaxGrand({
        monthly: this.monthly,
        yearly: this.yearly,
        companyId: this.detailList.companyId,
        companyName: this.detailList.name,
        unpaidAmount: this.detailList.customerFees
      }).then(function (res) {
        _this3.addVisible = false;
        _this3.$notification["success"]({
          message: "success",
          description: "生成成功"
        });
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys, selectedRow) {
      var _this4 = this;
      this.selectedRowId = [];
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length) {
        this.buttonState = false;
      } else {
        this.buttonState = true;
      }
      selectedRow.forEach(function (item, index) {
        _this4.selectedRowId.push(item.id);
      });
    },
    getTemplatesList: function getTemplatesList() {
      var _this5 = this;
      this.columns = JSON.parse(JSON.stringify(this.initColumns));
      getTemplates({
        monthly: this.monthly,
        yearly: this.yearly,
        id: this.detailList.subjectId,
        type: this.detailList.type
      }).then(function (res) {
        if (res.data.length > 0) {
          res.data.forEach(function (item, index) {
            if (_this5.detailList.type === "group") {
              _this5.columns.splice(_this5.columns.length - 8, 0, {
                title: item.empName,
                dataIndex: item.empName,
                key: item.empName,
                width: 130
              });
            } else {
              _this5.columns.splice(_this5.columns.length - 7, 0, {
                title: item.empName,
                dataIndex: item.empName,
                key: item.empName,
                width: 130
              });
            }
          });
        }
      });
    },
    getDetailsList: function getDetailsList() {
      var _this6 = this;
      this.loading = true;
      getDetails({
        empStatus: this.empStatus,
        id: this.detailList.subjectId,
        type: this.detailList.type,
        customerDetailsIdList: this.detailList.customerDetailsIds,
        nameOrPhoneOrIdCard: this.inputName,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        monthly: this.monthly,
        yearly: this.yearly
      }).then(function (res) {
        // this.getTemplatesList();
        _this6.loading = false;
        _this6.dataList = res.data.entities;
        _this6.pagination.total = res.data.entityCount; // 共多少条
        // this.pagination.pageSize = res.data.pageSize; // 每页多少条
        // this.pagination.current = res.data.pageNo; // 当前第几页
        if (res.data.entities && res.data.entities.length) {
          res.data.entities.forEach(function (item1, index1) {
            var key = null;
            var value = null;
            if (item1.itemDetail !== null) {
              JSON.parse(item1.itemDetail).forEach(function (item2, index2) {
                key = item2.name;
                value = item2.amount;
                _this6.dataList[index1][key] = value;
              });
            }
          });
        }
        console.log(_this6.dataList, "this.dataList???");
      });
    },
    handleChange2: function handleChange2(e) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.empStatus = e;
      this.getDetailsList();
    },
    // 查询全部员工详情
    getEmpListAll: function getEmpListAll() {
      // getEmpListAll({ companyId: this.companyId }).then(res => {
      //   this.empAllList = res.data;
      // });
    },
    getDownload: function getDownload() {
      var _this7 = this;
      axios({
        url: this.url,
        params: this.params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: this.token
        }
      }).then(function (res) {
        if (res.data && res.data.type === "application/json") {
          var reader = new FileReader();
          reader.readAsText(res.data, "utf-8");
          reader.onload = function () {
            var resJson = JSON.parse(reader.result);
            _this7.$message.error(resJson.msg);
          };
        } else {
          var blob = new Blob([res.data], {
            type: "application/octet-stream"
          });
          var link = document.createElement("a");
          try {
            if (_this7.bankType === "HUAXIA_BANK") {
              link.download = _this7.excelName + ".txt";
            } else {
              link.download = _this7.excelName + ".xlsx";
            }
          } catch (err) {
            console.log("debug log --> ", res);
          }
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    bankChange: function bankChange(e) {
      this.bankType = e;
    },
    excelChange: function excelChange(e) {
      if (e && e.visibleWrong) {
        this.excelVisible = e.visibleWrong;
      } else {
        this.excelVisible = false;
        this.$router.push({
          path: "../add-payslip-sort",
          query: {
            tableId: this.tableId,
            month: this.monthly,
            year: this.yearly,
            sendName: this.tableName
          }
        });
      }
    },
    showStatistical: function showStatistical() {
      this.statisticalVisible = true;
      this.bankType = undefined;
    },
    showGenerate: function showGenerate() {
      this.generatelVisible = true;
      this.tableName = undefined;
    },
    // 录入截止上月个税累计
    // 返回
    goBack: function goBack() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "/wages/monthly",
      //   query: {
      //     isBack: true,
      //     monthly: this.monthly,
      //     yearly: this.yearly
      //   }
      // });
    },
    // 导出
    download: function download(i) {
      var token = this.$store.state.auth.token;
      this.exportData = {
        customerDetailsIdList: this.detailList.customerDetailsIds,
        nameOrPhoneOrIdCard: this.inputName,
        empStatus: this.empStatus,
        id: this.detailList.subjectId,
        type: this.detailList.type,
        monthly: this.monthly,
        yearly: this.yearly
      };
      this.url = "/hr/api/v1/childrenCompany/export";
      this.excelName = "".concat(this.detailList.name + this.detailList.personalPeriod, "_") + "客户明细表导出详情" + ".xlsx";
      this.postDownload();
    },
    templateArchive: function templateArchive() {
      this.abolitionVisible = true;
    },
    inputChange: function inputChange(e) {
      this.inputName = e;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getDetailsList();
    },
    // 分页
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getDetailsList();
    }
  }
};