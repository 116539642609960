import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from "vuex";
export default {
  name: "EditorPayroll",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    payrollOptions: {}
  },
  data: function data() {
    return {
      clickEditor: true,
      obj: {},
      keys: []
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  watch: {
    payrollOptions: {
      handler: function handler(val) {
        this.obj = val;
        this.obj.companyId = this.hr.companyId;
      }
    },
    show: {
      handler: function handler(val) {
        this.clickEditor = !val;
      }
    }
  },
  methods: {
    // 有疑问?
    openHr: function openHr() {
      var _this = this;
      this.$info({
        title: "提示",
        content: "\u8BF7\u8054\u7CFBHR",
        onOk: function onOk() {
          _this.$emit("update:show", false);
        }
      });
    },
    onSave: function onSave() {
      this.$emit("update:show", false);
      this.$emit("editor", this.obj);
    },
    // 查看编辑明细
    lookEditor: function lookEditor() {
      this.clickEditor = !this.clickEditor;
    },
    // 取消
    onCancel: function onCancel() {
      this.show && this.clickEditor ? this.clickEditor = false : this.$emit("update:show", false);
    }
  }
};