import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.json.stringify.js";
import { stringify } from "qs";
var columns = [{
  title: "发送场景",
  dataIndex: "description",
  key: "description"
}, {
  title: "间隔时间",
  dataIndex: "timeDiff",
  key: "timeDiff",
  scopedSlots: {
    customRender: "timeDiff"
  }
}, {
  title: "价格",
  dataIndex: "cost",
  key: "cost",
  scopedSlots: {
    customRender: "cost"
  }
}];
import { getScene, addConfig, getConfig } from "./api";
export default {
  data: function data() {
    return {
      options: [],
      selectedKeys: [],
      sceneData: [],
      columns: columns,
      templateId: ""
    };
  },
  created: function created() {
    this.templateId = this.$route.query.templateId;
    this.init();
  },
  watch: {
    selectedKeys: {
      handler: function handler(val) {
        var _this = this;
        this.sceneData = [];
        val.forEach(function (item, index) {
          _this.options.forEach(function (item2, index2) {
            if (item2.scene === item) {
              _this.sceneData.push({
                description: item2.description,
                cost: item2.cost,
                timeDiff: item2.timeDiff,
                isDefault: false,
                scene: item2.scene,
                templateId: _this.templateId,
                index: index
              });
            }
          });
        });
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      var _this2 = this;
      getScene({
        isDefault: false
      }).then(function (res) {
        _this2.options = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      var dataConfig = {
        isDefault: false,
        templateId: this.templateId
      };
      getConfig(dataConfig).then(function (res) {
        _this2.selectedKeys = [];
        res.data.forEach(function (item, index) {
          _this2.options.forEach(function (item2, index2) {
            if (item2.scene === item.scene) {
              item2.cost = item.cost;
              item2.timeDiff = item.timeDiff;
            }
          });
          _this2.selectedKeys.push(item.scene);
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleCancel: function handleCancel() {
      this.$router.push("/thirdService/smsTemplate");
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;
      var data = {
        smsSiteAddDTOList: this.sceneData
      };
      addConfig(data).then(function (res) {
        _this3.$router.push("/thirdService/smsTemplate");
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleTime: function handleTime($event, index) {
      var temp = JSON.parse(JSON.stringify(this.sceneData));
      temp[index].timeDiff = $event;
      this.sceneData = temp;
    },
    handleCost: function handleCost($event, index) {
      var temp = JSON.parse(JSON.stringify(this.sceneData));
      temp[index].cost = $event;
      this.sceneData = temp;
    }
  }
};