import request from "@/utils/request";

// 个税人员库手动录入
export function saveInfo(data) {
  return request({
    url: "/tax/api/v1/employees/saveInfo",
    method: "post",
    data: data
  });
}
export function getEmployees(params) {
  return request({
    url: "/tax/api/v1/employees",
    method: "GET",
    params: params
  });
}
export function listByEmpId(empId) {
  return request({
    url: "/tax/api/v1/employeeRecords/selectByEmpId/".concat(empId),
    method: "GET"
  });
}
export function getTaxCompanieslistAll(params) {
  return request({
    url: "/tax/api/v1/companyAuthorities/listAll",
    method: "GET",
    params: params
  });
}
export function editEmployees(data) {
  return request({
    url: "/tax/api/v1/employees/updateIncludeNull",
    method: "put",
    data: data
  });
}
export function addEmployeeRecordsSaveRecord(data) {
  return request({
    url: "/tax/api/v1/employeeRecords/saveRecord",
    method: "post",
    data: data
  });
}
export function editEmployeeRecords(data) {
  return request({
    url: "/tax/api/v1/employeeRecords/updateIncludeNull",
    method: "put",
    data: data
  });
}
export function delEmployeeId(employeeId) {
  return request({
    url: "/tax/api/v1/employees/".concat(employeeId),
    method: "DELETE"
  });
}
export function delEmployeeRecords(employeeId) {
  return request({
    url: "/tax/api/v1/employeeRecords/".concat(employeeId),
    method: "DELETE"
  });
}
export function getCompanyAuthoritiesListAll(params) {
  return request({
    url: "/tax/api/v1/taxCompanies/listTree",
    method: "GET",
    params: params
  });
}