var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      width: "100vw",
      "min-height": "100vh",
      padding: "88px 0 24px",
      background: "rgba(244, 246, 250, 1)"
    }
  }, [_vm._m(0), _c("div", {
    staticStyle: {
      width: "1100px",
      margin: "0 auto"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("img", {
    staticStyle: {
      width: "748px",
      height: "280px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/banner1.jpg")
    },
    on: {
      click: _vm.onBanner1
    }
  }), _c("div", {
    staticStyle: {
      "margin-left": "24px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "328px",
      height: "128px",
      "margin-bottom": "24px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/banner2.png")
    },
    on: {
      click: _vm.onBanner2
    }
  }), _c("img", {
    staticStyle: {
      width: "328px",
      height: "128px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/banner3.png")
    },
    on: {
      click: _vm.onBanner3
    }
  })])]), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._m(1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c("img", {
    staticStyle: {
      width: "257px",
      height: "120px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/shangbao.png")
    },
    on: {
      click: _vm.onShangbao
    }
  }), _c("img", {
    staticStyle: {
      width: "257px",
      height: "120px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/xinzidianfu.png")
    },
    on: {
      click: _vm.onXinzidianfu
    }
  }), _c("img", {
    staticStyle: {
      width: "257px",
      height: "120px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/gongyinglianjinrong.png")
    },
    on: {
      click: _vm.onGongyinglianjinrong
    }
  }), _c("img", {
    staticStyle: {
      width: "257px",
      height: "120px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/fulicaigou.png")
    },
    on: {
      click: _vm.onFulicaigou
    }
  })])]), _c("div", {
    staticStyle: {
      "margin-top": "24px",
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "24px",
      background: "#fff",
      "border-radius": "8px",
      width: "600px",
      height: "248px",
      "margin-right": "24px"
    }
  }, [_vm._m(2), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c("img", {
    staticStyle: {
      width: "120px",
      height: "158px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/zhaopin.png")
    },
    on: {
      click: _vm.onZhaopin
    }
  }), _c("img", {
    staticStyle: {
      width: "120px",
      height: "158px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/dianzihetong.png")
    },
    on: {
      click: _vm.onDianzihetong
    }
  }), _c("img", {
    staticStyle: {
      width: "120px",
      height: "158px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/dianzidangan.png")
    },
    on: {
      click: _vm.onDianzidangan
    }
  }), _c("img", {
    staticStyle: {
      width: "120px",
      height: "158px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/geshui.png")
    },
    on: {
      click: _vm.onGeshui
    }
  })])]), _c("div", {
    staticStyle: {
      "padding-top": "24px",
      background: "#fff",
      "border-radius": "8px",
      width: "476px",
      height: "248px"
    }
  }, [_vm._m(3), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      width: "191px",
      height: "194px",
      "padding-top": "24px",
      "padding-left": "24px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "18px",
      "line-height": "18px",
      "font-weight": "500",
      color: "rgba(60, 61, 67, 1)",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" 管理指挥站 ")]), _c("div", {
    staticStyle: {
      "font-size": "14px",
      "line-height": "14px",
      color: "rgba(166, 168, 180, 1)",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" 企业内部人效，成本计算 ")]), _c("img", {
    staticStyle: {
      width: "117px",
      height: "32px",
      cursor: "pointer"
    },
    attrs: {
      src: require("./images/btn.png")
    },
    on: {
      click: _vm.onZhihuizhan
    }
  })]), _c("img", {
    staticStyle: {
      width: "285px",
      height: "194px"
    },
    attrs: {
      src: require("./images/zhihuizhan.jpg")
    }
  })])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      width: "100%",
      height: "64px",
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      position: "fixed",
      top: "0",
      left: "0",
      padding: "0 24px",
      background: "rgba(42, 46, 63, 1)"
    }
  }, [_c("img", {
    staticStyle: {
      width: "88px",
      height: "19px"
    },
    attrs: {
      src: require("./images/logo.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "margin-bottom": "24px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "24px",
      height: "24px",
      "margin-right": "8px"
    },
    attrs: {
      src: require("./images/icon.png")
    }
  }), _c("span", {
    staticStyle: {
      "line-height": "24px",
      "font-size": "18px",
      "font-weight": "500",
      color: "rgba(60, 61, 67, 1)"
    }
  }, [_vm._v("金融衍生服务")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "margin-bottom": "24px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "24px",
      height: "24px",
      "margin-right": "8px"
    },
    attrs: {
      src: require("./images/icon.png")
    }
  }), _c("span", {
    staticStyle: {
      "line-height": "24px",
      "font-size": "18px",
      "font-weight": "500",
      color: "rgba(60, 61, 67, 1)"
    }
  }, [_vm._v("人力资源业务服务")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "padding-left": "24px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "24px",
      height: "24px",
      "margin-right": "8px"
    },
    attrs: {
      src: require("./images/icon.png")
    }
  }), _c("span", {
    staticStyle: {
      "line-height": "24px",
      "font-size": "18px",
      "font-weight": "500",
      color: "rgba(60, 61, 67, 1)"
    }
  }, [_vm._v("内部解析服务")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };