export default {
  fields: {
    name: {
      model: "name"
    },
    phone: {
      model: "phone"
    },
    companyName: {
      model: "companyName"
    },
    companyAddress: {
      model: "companyAddress"
    },
    customerSituation: {
      model: "customerSituation"
    },
    customerDemand: {
      model: "customerDemand"
    },
    mark: {
      model: "mark"
    },
    memberName: {
      model: "memberName"
    },
    sourceType: {
      model: "sourceType"
    },
    distributeName: {
      model: "distributeName"
    },
    distributeOrGetTime: {
      model: "distributeOrGetTime"
    },
    lastVisitTime: {
      model: "lastVisitTime"
    },
    signingTime: {
      model: "signingTime"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "name",
        title: "姓名",
        width: 144,
        fixed: "left"
      }, {
        model: "phone",
        title: "手机号",
        width: 144,
        fixed: "left"
      }, {
        model: "companyName",
        title: "公司名称"
      }, {
        model: "companyAddress",
        title: "地址"
      }, {
        model: "customerSituation",
        title: "客户情况"
      }, {
        model: "customerDemand",
        title: "客户需求"
      }, {
        model: "mark",
        title: "备注"
      }, {
        model: "memberName",
        title: "客户所属"
      }, {
        model: "sourceType",
        title: "来源"
      }, {
        model: "distributeName",
        title: "分配人"
      }, {
        model: "distributeOrGetTime",
        title: "分配/领取时间"
      }, {
        model: "lastVisitTime",
        title: "最后拜访时间"
      }, {
        model: "signingTime",
        title: "签约时间"
      }],
      customize: {
        rowKey: "id"
      },
      extend: {
        batch: true,
        action: true
      }
    }
  }
};