var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "third-service-wrapper page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "titleBox"
  }, [_vm._v("拓展应用")]), _c("div", {
    staticClass: "third-item-wrapper"
  }, _vm._l(_vm.dataList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "third-item",
      on: {
        click: function click($event) {
          return _vm.goDetail(item);
        }
      }
    }, [_c("div", {
      staticClass: "third-item-top"
    }, [item.openStatus ? _c("div", {
      staticClass: "third-right-icon"
    }, [_c("img", {
      staticStyle: {
        width: "66px",
        height: "66px"
      },
      attrs: {
        src: _vm.rightIcon
      }
    })]) : _c("div", {
      staticClass: "third-right-icon"
    }, [_c("img", {
      staticStyle: {
        width: "66px",
        height: "66px"
      },
      attrs: {
        src: _vm.rightIcon2
      }
    })]), _c("div", {
      staticClass: "itemBox"
    }, [_c("div", {
      staticClass: "third-center"
    }, [_c("img", {
      staticStyle: {
        width: "56px",
        height: "56px"
      },
      attrs: {
        src: item.serviceIcon
      }
    })]), _c("div", {
      staticStyle: {
        "margin-left": "24px"
      }
    }, [_c("div", {
      staticClass: "third-center third-item-name"
    }, [_vm._v(_vm._s(item.serviceName))]), _c("div", {
      staticClass: "third-center third-item-text"
    }, [_vm._v(_vm._s(item.serviceDesc))])])])])]);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };