import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.object.keys.js";
import XqFormMixin from "xqjr-plugin-form/mixins/XqFormMixin";
var FormMixin = XqFormMixin();
import moment from "moment";
import { mapState } from "vuex";
export default {
  mixins: [FormMixin],
  props: {
    sortedKeys: {
      type: Array,
      default: function _default() {
        return null;
      }
    },
    remove: {
      type: Boolean,
      default: false
    },
    detailedShow: {
      type: Boolean,
      default: true
    },
    dataSource: {
      type: Object,
      default: function _default() {}
    },
    editable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: true
    },
    workYearsNoExperience: {
      type: Boolean,
      default: false
    },
    dynamicData: {
      type: Object,
      default: function _default() {}
    },
    status: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    intentionJobAll: {
      type: String,
      default: ""
    },
    dataListArr: {
      type: Array,
      default: function _default() {
        return null;
      }
    }
  },
  data: function data() {
    return {
      workYearsCheckbox: false,
      intentionJobText: "",
      intentionJobCopy: null,
      intentionJob: null,
      companyName: "",
      authId: undefined,
      dataList: [],
      replaceFields: {
        children: "childList",
        key: "id",
        value: "id",
        title: "name"
      },
      isReward: true,
      spouseInfo: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["recruitment"])), {}, {
    positionTypes: function positionTypes() {
      return this.recruitment.positionTypes;
    }
  }),
  watch: {
    workYearsNoExperience: function workYearsNoExperience(val) {
      this.workYearsCheckbox = val;
    },
    intentionJobAll: function intentionJobAll(val) {
      if (val) {
        this.intentionJobText = val;
      }
    },
    dataSource: {
      handler: function handler(val) {
        var _this = this;
        console.log("dataSource", val);
        if (val && val.workYears) {
          this.$nextTick(function () {
            _this.form.setFieldsValue({
              workYears: val.workYears
            });
          });
        }
      },
      immediate: true,
      deep: true
    },
    dataListArr: {
      handler: function handler(val) {
        if (val) {
          this.dataList = val.map(function (i) {
            return {
              label: i.name,
              value: i.id,
              children: i.companyAuthorityList ? i.companyAuthorityList.map(function (r) {
                return {
                  label: r.responsibleAuthorityName,
                  value: r.id
                };
              }) : []
            };
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {},
  methods: {
    decoratorIntentionJob: function decoratorIntentionJob(e) {
      return e.decorator;
    },
    onJobChange: function onJobChange(e, a, s) {
      var _this2 = this;
      if (e.length <= 3) {
        this.intentionJob = e;
        this.intentionJobCopy = e;
        var arr = [];
        this.positionTypes.forEach(function (item, index) {
          if (item.childList && item.childList.length) {
            item.childList.forEach(function (item2, index2) {
              if (item2.childList && item2.childList.length) {
                item2.childList.forEach(function (item3) {
                  e.forEach(function (item4) {
                    if (item4 === item3.id) {
                      arr.push("".concat(item.id) + "," + "".concat(item2.id) + "," + item4);
                    }
                  });
                });
              }
            });
          }
        });
        this.intentionJobText = "";
        this.intentionJobText = arr.join(",") + "*" + a.join(",");
      } else {
        this.$message.warning("最多选择3个意向岗位！");
        this.$nextTick(function () {
          _this2.form.setFieldsValue({
            intentionJob: _this2.intentionJobCopy
          });
        });
      }
    },
    onEdit: function onEdit() {
      this.$emit("onEdit");
    },
    filter: function filter(inputValue, path) {
      return path.some(function (option) {
        return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
      });
    },
    editShow: function editShow() {
      var _this3 = this;
      this.$emit("edit");
      this.$nextTick(function () {
        _this3.setFieldsValueShow("authorityId", _this3.dataSource.authorityId);
      });
    },
    changeWorkYears: function changeWorkYears(val) {
      var _this4 = this;
      console.log(val);
      this.$nextTick(function () {
        _this4.workYearsCheckbox = false;
      });
    },
    onChange: function onChange(e) {
      var _this5 = this;
      if (e.target.checked) {
        this.$nextTick(function () {
          _this5.form.setFieldsValue({
            workYears: ""
          });
        });
      }
      //  this.$emit("itemChange", e.target.checked, 'workYearsCheckbox', {});
    },
    onChangeAuthorityId: function onChangeAuthorityId(e, selectedOptions) {
      console.log(e, selectedOptions);
    },
    getFieldValue: function getFieldValue(key) {
      return this.form.getFieldValue(key);
    },
    setFieldsValueShow: function setFieldsValueShow(key, value) {
      var _this6 = this;
      setTimeout(function () {
        _this6.form.setFieldsValue(_defineProperty({}, key, value));
      }, 100);
    },
    vilidateRetirementDate: function vilidateRetirementDate(rule, value, callback) {
      var form = this.form;
      if (this.$attrs.basicData.birthday) {
        if (moment(value).valueOf() < moment(this.$attrs.basicData.birthday).valueOf()) {
          callback("退休日期不能大于出生日期");
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    getDataRules: function getDataRules() {
      this.getData().then(function (res) {
        console.log("debug log --> ", res);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    XqItemChange: function XqItemChange(val, item, dataSource, dynamicData) {
      if (item.model === "annex") {
        this.$emit("itemChange", val, item.model, dataSource);
      }
    },
    save: function save() {
      var _this7 = this;
      this.getData().then(function (res) {
        Object.keys(res).forEach(function (element) {
          if (res[element] === "true") {
            res[element] = true;
          }
          if (res[element] === "false") {
            res[element] = false;
          }
          if (res[element] && res[element]._isAMomentObject) {
            if (element === "birthday") {
              res[element] = moment(res[element]).format("YYYY-MM-DD");
            } else {
              res[element] = moment(res[element]).format("YYYY-MM-DD");
            }
          }
          if (element === "intentionJob") {
            res["intentionJob"] = _this7.intentionJobText;
          }
          if (Array.isArray(res[element]) && res[element].length !== 0 && res[element][0].url) {
            res[element] = res[element].map(function (z) {
              return z.url;
            });
          }
          if (Array.isArray(res[element]) && res[element].length !== 0 && element !== "skill") {
            res[element] = res[element] + "";
          }
          if (element === "workYears") {
            res["workYears"] = _this7.workYearsCheckbox ? 0 : res[element];
          }
        });
        _this7.$emit("change", res);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    onRemove: function onRemove() {
      this.$emit("remove");
    },
    cancel: function cancel() {
      this.$emit("cancel");
    },
    onValuesChangeCustom: function onValuesChangeCustom() {}
  }
};