import request from "@/utils/request";

// 查询官网信息的类型
export function getNewsInfoslistAll(data) {
  return request({
    url: "/hr/api/v1/newsInfos/listAll",
    method: "get",
    params: data
  });
}

// 新增官网信息
export function addNewsInfos(data) {
  return request({
    url: "/hr/api/v1/newsTypes/insertNewsTypeAndInfo",
    method: "post",
    data: data
  });
}
// 修改官网信息
export function updateNewsInfos(data) {
  return request({
    url: "/hr/api/v1/newsTypes/updateNewsTypeAndInfo",
    method: "put",
    data: data
  });
}

// 分页查询类型的列表
export function selectNewsByType(data) {
  return request({
    url: "/hr/api/v1/newsTypes/selectNewsType",
    method: "get",
    params: data
  });
}

// 批量删除官网信息的类型，彻底删除
export function batchDelNewsInfos(data) {
  return request({
    url: "/hr/api/v1/newsInfos",
    method: "DELETE",
    data: data
  });
}

// 根据官网信息的类型id查询官网信息的类型详情
export function getNewDetail(data) {
  return request({
    url: "/hr/api/v1/newsInfos/selectNewsInfoByNewsTypeId",
    method: "get",
    params: data
  });
}
// 查询官网信息的类型
export function getNewsGroups(data) {
  return request({
    url: "/hr/api/v1/newsGroups/listAll",
    method: "get",
    params: data
  });
}
// 新增官网信息的类型
export function addNewsTypes(data) {
  return request({
    url: "/hr/api/v1/newsTypes",
    method: "post",
    data: data
  });
}
// 修改官网信息的类型
export function updateNewsTypes(data) {
  return request({
    url: "/hr/api/v1/newsTypes",
    method: "put",
    data: data
  });
}
// 查询公历表,补全前后日期分别到周一和周日
export function getCalendar(params) {
  return request({
    url: "/admin/api/v1/gregorianCalendars/month",
    method: "GET",
    params: params
  });
}
// 批量删除官网信息的类型，彻底删除
export function batchDelNews(data) {
  return request({
    url: "/hr/api/v1/newsTypes",
    method: "DELETE",
    data: data
  });
}
// 首页备忘录

// 获取当前登录用户信息
export function getCurrentUserInfo(data) {
  return request({
    url: "/hr/api/v1/users/getCurrentUserInfo",
    method: "get"
  });
}
// 获取首页备忘录提醒相关信息
export function getHomePageListAll(data) {
  return request({
    url: "/hr/api/v1/memoInfos/getHomePageMemoRemindRelation",
    method: "get",
    params: data
  });
}
// 获取接单数据
export function viewMission(data) {
  return request({
    url: "/hr/api/v1/missionCenters/viewMission",
    method: "get",
    params: data
  });
}
// 获取今日备忘更多列表
export function getMemoListAll(data) {
  return request({
    url: "/hr/api/v1/memoInfos/getMemoRemindListToday",
    method: "get",
    params: data
  });
}
// 新增备忘录
export function addMemoConfigsReq(data) {
  return request({
    url: "/hr/api/v1/memoConfigs",
    method: "post",
    data: data
  });
}
// 完成备忘
export function finishMemoConfigsReq(data) {
  return request({
    url: "/hr/api/v1/memoInfos/completed",
    method: "put",
    data: data
  });
}
// 编辑
export function updateMemo(data) {
  return request({
    url: "/hr/api/v1/memoInfos",
    method: "put",
    data: data
  });
}
export function deleteMemo(data) {
  return request({
    url: "/hr/api/v1/memoInfos",
    method: "delete",
    data: data
  });
}

// 获取指定年月员工备忘录待办事项
export function getToDoMemo(params) {
  return request({
    url: "/hr/api/v1/memoInfos/getToDoMemo",
    method: "get",
    params: params
  });
}

// 查询鸡汤接口
export function getSentences(data) {
  return request({
    url: "/admin/api/v1/sentences/me",
    method: "GET",
    params: data
  });
}