import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import EmployeeSelect from "@/components/EmployeeSelect2/employeeSelect7";
import { isJson } from "@/utils/index";
export default {
  name: "StartDrawer",
  components: {
    EmployeeSelect: EmployeeSelect
  },
  props: {
    startOptions: {
      type: Object,
      default: function _default() {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      visible: false,
      choseEmployees: [],
      defaultKeys: [],
      copyStartoptions: {},
      options: null
    };
  },
  watch: {
    show: function show(val) {
      if (val) {
        this.copyStartoptions = JSON.parse(JSON.stringify(this.startOptions));
        this.initOptions(this.startOptions);
      }
    }
  },
  created: function created() {
    this.initOptions(this.startOptions);
  },
  methods: {
    initOptions: function initOptions(val) {
      this.options = Object.assign({}, val, {
        props: isJson(val.props) ? JSON.parse(val.props) : val.props
      });
      if (this.options.limitUser) {
        this.defaultKeys = this.options.limitUser.split(",");
      }
    },
    onEmployeeChange: function onEmployeeChange(data) {
      this.choseEmployees = data;
      this.defaultKeys = data.map(function (i) {
        return i.id;
      });
      this.startOptions.limitUser = this.choseEmployees.map(function (item) {
        return item.id;
      }).toString();
      this.startOptions.props = this.choseEmployees.map(function (item) {
        return item.name;
      }).toString();
      this.$emit("change", this.startOptions);
    },
    onOk: function onOk() {
      // 最后确认
      this.startOptions.limitUser = this.choseEmployees.map(function (item) {
        return item.id;
      }).toString();
      this.startOptions.props = this.choseEmployees.map(function (item) {
        return item.name;
      }).toString();
      this.$emit("change", this.startOptions);
      this.$emit("update:show", false);
    },
    onClose: function onClose() {
      // 关闭抽屉
      this.$emit("update:show", false);
      this.$emit("change", this.copyStartoptions);
    },
    addUser: function addUser() {
      // 添发起人
      this.visible = true;
    }
  }
};