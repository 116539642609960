import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
import { mapState } from "vuex";
import moment from "moment";
var mixin = new UploadMixin();
var formalType = ["试用", "正式", "实习"];
var columnDetail = [{
  title: "错误信息",
  dataIndex: "reason",
  fixed: "left",
  scopedSlots: {
    customRender: "reason"
  },
  ellipsis: true,
  width: 260
}];
import axios from "axios";
import qs from "qs";
export default {
  mixins: [mixin],
  data: function data() {
    return {
      count: 0,
      succ: 0,
      fial: 0,
      formalType: formalType,
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showQuickJumper: true
      },
      dataDetail: [],
      callback: false,
      dialogFailVisible: false,
      downloadurl: undefined,
      columnDetail: columnDetail,
      uploadImg: require("@/assets/img/uploadImg.png"),
      params: {
        templateName: "channelInfoImportTemplate.xlsx"
      },
      url: "/hr/api/v1/excel/template/download",
      excelName: "渠道上传模板.xlsx"
    };
  },
  created: function created() {
    var companyId = this.$store.state.hr.companyId;
    this.action = "/hr/api/v1/channels/importChannel";
  },
  computed: _objectSpread(_objectSpread({}, mapState(["common"])), {}, {
    cityList: function cityList() {
      return this.common.cityList;
    },
    areaList: function areaList() {
      return this.common.areaList;
    }
  }),
  methods: {
    handleSuccess: function handleSuccess(res) {
      var _this = this;
      var that = this;
      if (res.success && res.data !== null) {
        var numAll = res.data.importFil.length + res.data.importSuc.length;
        var numFail = res.data.importFil.length;
        var numSuc = res.data.importSuc.length;
        if (that.showFaild) {
          that.Faildata = res.data.importFil;
          that.Faildata.forEach(function (item, index) {
            item = Object.assign(item, item.allMap);
          });
          that.showFaild(numAll, numSuc, numFail, that.Faildata, res.data.failPath);
        } else {
          if (numFail === 0) {
            this.$confirm({
              okText: "确定",
              title: "\u5171\u5BFC\u5165".concat(numAll, "\u4EBA\uFF0C").concat(numSuc, "\u4EBA\u6210\u529F\uFF0C").concat(numFail, "\u4EBA\u672A\u6210\u529F"),
              onOk: function onOk() {
                that.goback();
              },
              onCancel: function onCancel() {
                that.goback();
              }
            });
          } else {
            this.$confirm({
              okText: "确定",
              cancelText: "查看未成功名单",
              title: "\u5171\u5BFC\u5165".concat(numAll, "\u4EBA\uFF0C").concat(numSuc, "\u4EBA\u6210\u529F\uFF0C").concat(numFail, "\u4EBA\u672A\u6210\u529F"),
              onOk: function onOk() {
                that.goback();
              },
              onCancel: function onCancel() {
                that.Faildata = res.data.importFil;
                that.dialogFailVisible = true;
              }
            });
          }
        }
      } else if (res.data === null) {
        this.$confirm({
          okText: "确定",
          title: res.msg
        });
      } else {
        this.$notification["error"]({
          message: "error",
          description: res.msg || "上传失败"
        });
        setTimeout(function () {
          _this.reset();
        }, 2000);
      }
    },
    buCha: function buCha() {
      window.open(this.downloadurl + "?token=".concat(this.$store.state.auth.token));
    },
    isGoBack: function isGoBack() {
      if (this.callback) {
        this.callback = false;
      } else {
        this.goback();
      }
    },
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    },
    handleFailClose: function handleFailClose() {
      this.dialogFailVisible = false;
      this.uploading = false;
      this.goback();
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
    },
    buildValue: function buildValue(id) {
      var needRecursion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      id = parseInt(id);
      var index = this.common.areaSourceList.findIndex(function (item) {
        return item.id === parseInt(id);
      });
      if (index > -1) {
        var item = this.common.areaSourceList[index];
        var val = {
          value: id,
          label: item.name
        };
        if (needRecursion && item.parentId > 0) {
          return this.buildValue(item.parentId).concat([val]);
        } else {
          return [val];
        }
      }
      return [];
    },
    getLastAreaId: function getLastAreaId(values) {
      if (Array.isArray(values)) {
        return values[values.length - 1];
      } else {
        return values.split(",").slice(-1)[0];
      }
    },
    showFaild: function showFaild(numAll, numSuc, numFail, Faildata, downloadurl) {
      this.columnDetail = [{
        title: "错误信息",
        dataIndex: "errorReason",
        scopedSlots: {
          customRender: "errorReason"
        },
        ellipsis: true,
        fixed: "left",
        width: 260
      }, {
        title: "所属分组",
        dataIndex: "groupName",
        scopedSlots: {
          customRender: "groupName"
        },
        ellipsis: true,
        width: 260
      }, {
        title: "渠道名称",
        dataIndex: "name",
        scopedSlots: {
          customRender: "name"
        },
        ellipsis: true,
        width: 260
      }, {
        title: "联系电话",
        dataIndex: "phone",
        scopedSlots: {
          customRender: "reason"
        },
        ellipsis: true,
        width: 260
      }, {
        title: "所在地区",
        dataIndex: "address",
        scopedSlots: {
          customRender: "address"
        },
        ellipsis: true,
        width: 260
      }, {
        title: "身份证号",
        dataIndex: "idcard",
        scopedSlots: {
          customRender: "idcard"
        },
        ellipsis: true,
        width: 260
      }, {
        title: "银行卡号",
        dataIndex: "bankNo",
        scopedSlots: {
          customRender: "bankNo"
        },
        ellipsis: true,
        width: 260
      }];
      this.count = numAll;
      this.succ = numSuc;
      this.fial = numFail;
      this.dataDetail = Faildata;
      // this.dataDetail.forEach(element => {
      //   if (element.address) {
      //     const values = this.buildValue(this.getLastAreaId(element.address));
      //     const labels = values.map(item => item.label);
      //     element.address = labels.join("-");
      //   }
      // });
      if (this.count === this.succ) {
        this.dialogFailVisible = true;
      } else {
        this.callback = true;
      }
      this.downloadurl = downloadurl;
      // columns.forEach((item, index) => {
      //   this.columnDetail.push({
      //     title: `${item}`,
      //     dataIndex: `${item}`,
      //     align: "center",
      //     width: item === "身份证号" ? 220 : 150,
      //     scopedSlots: { customRender: `${item}` },
      //     ellipsis: true
      //     // fixed: item === "身份证号" || item === "姓名" ? "left" : null
      //   });
      // });
    },
    getMaritalStatus: function getMaritalStatus(maritalStatus) {
      if (maritalStatus === 1) {
        return "已婚";
      } else if (maritalStatus === 2) {
        return "未婚";
      } else if (maritalStatus === 3) {
        return "离异";
      }
    }
  }
};