var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wage-set-wrapper p24 page-miH page-bgBack"
  }, [_c("a-tabs", {
    model: {
      value: _vm.tabs,
      callback: function callback($$v) {
        _vm.tabs = $$v;
      },
      expression: "tabs"
    }
  }, [_c("a-tab-pane", {
    key: "0",
    attrs: {
      tab: "正常工资薪金申报"
    }
  }, [_c("wage")], 1), _c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "个税人员信息申报"
    }
  }, [_c("emp-details")], 1), _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "全年一次性奖金申报"
    }
  }, [_c("bonus")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };