var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_vm.device === "mobile" && _vm.sidebar.opened ? _c("div", {
    staticClass: "drawer-bg",
    on: {
      click: _vm.handleClickOutside
    }
  }) : _vm._e(), _c("sidebar"), _c("div", {
    staticClass: "main-container"
  }, [_c("div", {
    class: {
      "fixed-header": _vm.fixed_header
    }
  }, [_c("navbar")], 1), _vm.isRouterAlive ? _c("app-main") : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };