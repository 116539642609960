import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import { getEmpTransferList } from "@/api/staff/staff";
import { formatDate } from "@/utils/index";
var columns = [{
  title: "姓名",
  dataIndex: "empName"
}, {
  title: "手机号",
  dataIndex: "phone"
}, {
  title: "调岗日期",
  dataIndex: "adjustmentDate",
  customRender: function customRender(text, record, index) {
    return formatDate(text);
  }
}, {
  title: "调岗原因",
  dataIndex: "adjustmentReason"
}, {
  title: "操作",
  dataIndex: "operate",
  scopedSlots: {
    customRender: "operate"
  }
}];
var pagination = {
  pageSize: 10,
  total: 0,
  size: "default",
  current: 1,
  showTotal: function showTotal(total) {
    return "\u5171 ".concat(total, " \u6761");
  },
  showQuickJumper: true
};
export default {
  name: "LeaveList",
  // props: {
  //   selectId: {
  //     type: String,
  //     required: true
  //   },
  //   moveTag: {
  //     type: Boolean,
  //     required: true
  //   }
  // },
  data: function data() {
    return {
      columns: columns,
      empName: "",
      pagination: pagination,
      data: [],
      selectedRowKeys: []
    };
  },
  created: function created() {
    this.getEmpInfosData();
  },
  methods: {
    onSearch: function onSearch() {
      this.pagination.current = 1;
      this.getEmpInfosData();
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getEmpInfosData();
    },
    getEmpInfosData: function getEmpInfosData() {
      var _this = this;
      getEmpTransferList(this.$store.state.hr.companyId, {
        empName: this.empName,
        pageNo: this.pagination.current,
        pageSize: 10
      }).then(function (res) {
        _this.pagination.total = res.data && res.data.entityCount;
        _this.data = res.data.entities;
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    goback: function goback() {
      this.$router.push({
        path: "/staff/manger?selectId=5"
      });
    },
    detail: function detail(row) {
      var searchData = {
        companyId: this.$store.state.hr.companyId,
        queryType: 7
      };
      this.$router.push({
        path: "/staff/detail",
        query: {
          id: row.empId,
          userId: row.userId,
          prevpage: location.hash.slice(1)
        }
      });
    }
  }
};