var state = {
  selectedKeys: [],
  openKeys: []
};
var mutations = {
  updateSelectedKeys: function updateSelectedKeys(state, payload) {
    state.selectedKeys = payload;
  },
  updateOpenKeys: function updateOpenKeys(state, payload) {
    state.openKeys = payload;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};