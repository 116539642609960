import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      mode: _vm.mode,
      "default-value": _vm.defaultValue,
      placeholder: _vm.record.options.placeholder,
      disabled: _vm.disabled
    },
    on: {
      change: _vm.onChange
    }
  }, _vm._l(_vm.companyEmployeeList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };