import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", [_c("div", {
    staticClass: "manage-slide pt24 pr24"
  }, [_c("a-layout", [_c("a-layout-sider", {
    staticStyle: {
      height: "calc(100vh - 112px)",
      overflow: "auto",
      "background-color": "#fff"
    },
    attrs: {
      width: 240
    }
  }, [_c("div", {
    staticClass: "manage-slide-title",
    on: {
      mouseenter: function mouseenter($event) {
        _vm.addIcon = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.addIcon = false;
      }
    }
  }, [_vm._v(" 全部合同(" + _vm._s(_vm.categorieslistAllNum) + ") "), _c("a-icon", {
    staticClass: "mg_l74",
    attrs: {
      type: "form"
    },
    on: {
      click: _vm.onJumpGroup
    }
  })], 1), _c("a-menu", {
    ref: "antMenu",
    staticClass: "manage-slide-groupleft",
    model: {
      value: _vm.key,
      callback: function callback($$v) {
        _vm.key = $$v;
      },
      expression: "key"
    }
  }, _vm._l(_vm.groupList, function (item) {
    return _c("a-menu-item", {
      key: item.id,
      staticStyle: {
        padding: "0 0 0 24px"
      },
      on: {
        click: function click($event) {
          return _vm.getCategorieslistAll(item.id);
        }
      }
    }, [_c("a-row", {
      staticStyle: {
        "border-bottom": "1px solid #e8e8e8"
      },
      attrs: {
        type: "flex",
        align: "middle",
        justify: "space-between"
      }
    }, [_c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.name))])]), _c("div", {
      staticClass: "groupname"
    }, [_c("div", {
      staticClass: "ellipsis",
      staticStyle: {
        float: "left",
        "max-width": "140px"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("div", {
      staticStyle: {
        float: "left"
      }
    }, [_vm._v("(" + _vm._s(item.categoryCount) + ")")])])], 2), _c("a-col", [_vm.act == item.id ? _c("a-icon", {
      attrs: {
        type: "setting"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.setting(item);
        }
      }
    }) : _vm._e()], 1)], 1)], 1);
  }), 1)], 1), _c("a-layout", [_c("a-row", {
    staticClass: "lay-head",
    attrs: {
      type: "flex"
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "搜索合同模板",
      allowClear: true
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.searchName,
      callback: function callback($$v) {
        _vm.searchName = $$v;
      },
      expression: "searchName"
    }
  }), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_vm.isContainByString("contractAdmin") ? _c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showIssueModa
    }
  }, [_vm._v(" 下发合同模板 ")]) : _vm._e(), _c("a-button", {
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: function click($event) {
        _vm.addVisible = true;
      }
    }
  }, [_vm._v(" 新增合同模板 ")])], 1)], 1), !!_vm.categorieslistAll.length ? _c("div", {
    staticClass: "listbox"
  }, _vm._l(_vm.categorieslistAll, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "list-item"
    }, [_c("div", {
      staticClass: "list-item-col",
      class: !item.fileType ? "list-item-col-flex" : ""
    }, [_c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.name))])]), _c("div", {
      staticClass: "title overflows mt4",
      style: !item.fileType ? "margin-bottom: 0;" : ""
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 2), item.fileType ? _c("a-tooltip", {
      attrs: {
        placement: "bottom"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(_vm.enumerateJson[item.fileType]))])]), _c("div", {
      staticClass: "description overflows"
    }, [_vm._v(" " + _vm._s(_vm.enumerateJson[item.fileType]) + " ")])], 2) : _vm._e()], 1), _c("div", {
      staticClass: "list-item-col col2"
    }, [item.signType == "person_only" ? _c("div", {
      staticStyle: {
        display: "flex",
        "align-items": "center",
        height: "100%"
      }
    }, [_c("div", {
      staticClass: "title overflows mt4",
      staticStyle: {
        "margin-bottom": "0"
      }
    }, [_vm._v("员工单方签署")])]) : _c("div", [_c("div", {
      staticClass: "title overflows mt4"
    }, [_vm._v(" " + _vm._s(item.signType == "enterprise_only" ? "企业单方签署" : "企业-员工双方签署") + " ")]), item.sealVO != null ? _c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.sealVO.name))])]), _c("div", {
      staticClass: "description2 overflows"
    }, [_vm._v(" " + _vm._s(item.sealVO.name) + " ")])], 2) : _c("a", {
      on: {
        click: function click($event) {
          return _vm.setType(item);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "plus"
      }
    }), _vm._v(" 选择印章 ")], 1)], 1)]), _c("div", {
      staticClass: "list-item-col col3"
    }, [!_vm.isContainByString("contractAdmin") ? _c("div", [_c("a-button", {
      on: {
        click: function click($event) {
          return _vm.selectebatchSign(item);
        }
      }
    }, [_vm._v("发起签署")])], 1) : _vm._e(), _c("actionPoint", [_c("li", {
      on: {
        click: function click($event) {
          return _vm.setType(item);
        }
      }
    }, [_vm._v("编辑")]), _c("li", {
      on: {
        click: function click($event) {
          return _vm.onPreview(item);
        }
      }
    }, [_vm._v("预览")]), _c("li", {
      on: {
        click: function click($event) {
          return _vm.deleteType(item);
        }
      }
    }, [_vm._v("删除")])])], 1)]);
  }), 0) : _c("a-empty", {
    staticStyle: {
      "margin-top": "150px"
    }
  })], 1)], 1), _c("add", {
    attrs: {
      visible: _vm.addVisible,
      isLoadingGroup: _vm.isLoadingGroup
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addVisible = $event;
      },
      confirmAddType: _vm.confirmAddType
    }
  }), _c("a-modal", {
    attrs: {
      visible: _vm.issueModalVisible,
      title: "下发合同模版",
      width: "538px",
      destroyOnClose: true
    },
    on: {
      ok: _vm.confirmIssueModal,
      cancel: _vm.handleCancelIssueModal
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", [_vm._v("请选择合同模板")]), _c("a-tree-select", {
    staticStyle: {
      width: "240px",
      "margin-top": "10px",
      "max-height": "350px",
      "overflow-y": "auto"
    },
    attrs: {
      "tree-data": _vm.contractData,
      "tree-checkable": "",
      replaceFields: _vm.replaceFields,
      "show-search": "",
      treeNodeFilterProp: "title",
      "search-placeholder": "请选择合同模板"
    },
    on: {
      change: _vm.treeSelectChange
    }
  }), _c("a-checkbox", {
    staticClass: "mt8",
    model: {
      value: _vm.isSeal,
      callback: function callback($$v) {
        _vm.isSeal = $$v;
      },
      expression: "isSeal"
    }
  }, [_vm._v(" 是否同步下发合同印章 ")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", [_vm._v("请选择下发用工单位")]), _c("a-tree-select", {
    staticStyle: {
      width: "240px",
      "margin-top": "10px",
      "max-height": "350px",
      overflow: "auto"
    },
    attrs: {
      value: _vm.companyList,
      treeData: _vm.dataList,
      filterTreeNode: _vm.filterTreeNode,
      treeCheckable: "",
      placeholder: "请选择用工单位"
    },
    on: {
      change: _vm.companySelectChange
    }
  })], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      width: 780,
      visible: _vm.errVisible,
      title: "下发结果",
      footer: false
    },
    on: {
      cancel: function cancel($event) {
        _vm.errVisible = false;
      }
    }
  }, [_c("a-alert", {
    attrs: {
      message: "本次下发" + _vm.allList.length + "个用工单位，" + (_vm.allList.length - _vm.errList.length) + "个成功，" + _vm.errList.length + "个失败，请修改问题后重新下发",
      banner: ""
    }
  }), _c("a-table", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      columns: _vm.errColumns,
      pagination: false,
      "data-source": _vm.errList
    },
    scopedSlots: _vm._u([{
      key: "companyName",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "failCategoryStr",
      fn: function fn(text) {
        return [_c("a-tooltip", [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(text) + " ")]), _c("div", {
          staticStyle: {
            width: "160px",
            "text-overflow": "ellipsis",
            "white-space": "nowrap",
            overflow: "hidden"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])], 2)];
      }
    }])
  }, [_c("template", {
    slot: "reason"
  }, [_c("span", [_vm._v("用工单位内存在同名的电子合同，且处于未签署完成阶段")])])], 2)], 1)], 1), _c("preview", {
    attrs: {
      visible: _vm.visible,
      templateId: _vm.templateId
    },
    on: {
      close: function close($event) {
        _vm.visible = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };