import request from "@/utils/request";

// 查询公司下的所有参保月(社保校验页面)
export function getGinseng(params) {
  return request({
    url: "/hr/api/v1/empSocMonths/listAllByCompanyId",
    method: "get",
    params: params
  });
}
// 解析导入文件
export function parseImport(data) {
  return request({
    url: "/hr/api/v1/socChecks/parseImport",
    method: "post",
    data: data
  });
}
// 社保校验Excel入库 数据校验
export function saveImport(data) {
  return request({
    url: "/hr/api/v1/socChecks/saveImport",
    method: "post",
    data: data
  });
}
// 社保名单导出
export function getCity(params) {
  return request({
    url: "/hr/api/v1/socWebsites/listAllNotSitId",
    method: "get",
    params: params
  });
}
// 社保名单导出
export function getCityAcc(params) {
  return request({
    url: "hr/api/v1/socCache",
    method: "get",
    params: params
  });
}
// 社保校验Excel入库
export function socChecks(data) {
  return request({
    url: "/hr/api/v1/socChecks/checkWebSiteDate",
    method: "post",
    data: data
  });
}
// 根据校验类型和参保月Id分页查询检验数据
export function pageByMonthIdAndCheckType(params) {
  return request({
    url: "/hr/api/v1/socChecks/pageByMonthIdAndCheckType",
    method: "get",
    params: params
  });
}
// 校验子项表导出excel
export function exportErr(params) {
  return request({
    url: "/hr/api/v1/socCheckItems/export",
    method: "get",
    params: params
  });
}