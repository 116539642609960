import { render, staticRenderFns } from "./NotifierDrawer.vue?vue&type=template&id=6430c57d&scoped=true&"
import script from "./NotifierDrawer.vue?vue&type=script&lang=js&"
export * from "./NotifierDrawer.vue?vue&type=script&lang=js&"
import style0 from "./NotifierDrawer.vue?vue&type=style&index=0&id=6430c57d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6430c57d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\tools\\frontend_workspace\\e-hr-master\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6430c57d')) {
      api.createRecord('6430c57d', component.options)
    } else {
      api.reload('6430c57d', component.options)
    }
    module.hot.accept("./NotifierDrawer.vue?vue&type=template&id=6430c57d&scoped=true&", function () {
      api.rerender('6430c57d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/approval/editor/components/drawers/NotifierDrawer.vue"
export default component.exports