import request from "@/utils/request";
import Files from "./files";
import WagesSet from "./wages-set";
import Payroll from "./payroll";
import Common from "../common";
import PaySet from "./pay-set";
function Wages(_ref) {
  var url = _ref.url,
    _ref$method = _ref.method,
    method = _ref$method === void 0 ? "GET" : _ref$method,
    data = _ref.data,
    params = _ref.params;
  return request({
    url: url,
    method: method,
    data: data,
    params: params
  });
}
export { Wages, Files, WagesSet, Payroll, Common, PaySet };