import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page_set"
  }, [_c("div", {
    staticClass: "soc_container"
  }, [_c("div", {
    staticClass: "soc_header"
  }, [_c("a-icon", {
    staticClass: "mg_r20 fs18",
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _c("span", {
    staticStyle: {
      color: "#999999",
      background: "#f7f7f7"
    }
  }, [_c("a-icon", {
    staticStyle: {
      color: "red"
    },
    attrs: {
      type: "exclamation-circle"
    }
  }), _vm._v(" 工资报表中将扣除社保的缴纳和补缴数据，请确认核实无误。 灰色数据是系统默认数值，"), _c("span", {
    staticStyle: {
      color: "#000"
    }
  }, [_vm._v("黑色")]), _vm._v(" 数据是您手动保存的数值， "), _c("span", {
    staticStyle: {
      color: "#6a66f6"
    }
  }, [_vm._v("橙色")]), _vm._v(" 数据是测算结果。 ")], 1)], 1), _c("a-row", {
    staticClass: "pd_t40 mg_b20",
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    staticClass: "soc_yuan mg_l55 mg_r25"
  }, [_vm._v(" " + _vm._s(_vm.needData.name[0]) + " ")]), _c("a-col", [_c("a-row", {
    staticClass: "title1 mg_b18"
  }, [_vm._v(" " + _vm._s(_vm.needData.name) + " "), _vm.needData.formalType == 1 ? _c("a-tag", {
    attrs: {
      color: "#2db7f5"
    }
  }, [_vm._v(" 试用期 ")]) : _vm._e(), _vm.needData.formalType == 2 ? _c("a-tag", {
    attrs: {
      color: "#87d068"
    }
  }, [_vm._v(" 已转正 ")]) : _vm._e()], 1), _c("a-row", {
    staticClass: "mg_b18"
  }, [_c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("最新工资基数")]), _c("span", {
    staticClass: "title1"
  }, [_vm._v("0")]), _c("span", {
    staticClass: "sub_tit mg_l10"
  }, [_vm._v("入职时间")]), _c("span", {
    staticClass: "title1"
  }, [_vm._v("2019-12-12")]), _c("span", {
    staticClass: "sub_tit mg_l10"
  }, [_vm._v("身份证号")]), _c("span", {
    staticClass: "title1"
  }, [_vm._v(_vm._s(_vm.needData.idCard ? _vm.needData.idCard : "暂无"))])]), _c("a-row", {
    staticClass: "mg_b18"
  }, [_c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("本月不缴纳社保")]), _c("a-switch", {
    attrs: {
      "checked-children": "开",
      "un-checked-children": "关"
    },
    model: {
      value: _vm.needData.socUnpayMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "socUnpayMonth", $$v);
      },
      expression: "needData.socUnpayMonth"
    }
  }), _c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("本月不缴纳公积金")]), _c("a-switch", {
    attrs: {
      "checked-children": "开",
      "un-checked-children": "关"
    },
    model: {
      value: _vm.needData.pfUnpayMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfUnpayMonth", $$v);
      },
      expression: "needData.pfUnpayMonth"
    }
  })], 1)], 1)], 1), _c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("div", {
    staticClass: "pd_b20",
    staticStyle: {
      "border-bottom": "1px solid #ccc"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "参保城市",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-cascader", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["needData.socCity", {
        initialValue: _vm.needData.socCity,
        rules: [{
          required: true,
          message: "选择城市!"
        }]
      }],
      expression: "[\n              'needData.socCity',\n              {\n                initialValue: needData.socCity,\n                rules: [{ required: true, message: '选择城市!' }]\n              }\n            ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      options: _vm.options,
      placeholder: "选择城市"
    },
    on: {
      change: _vm.onChangeCity
    }
  }, [_c("a-icon", {
    staticClass: "test",
    attrs: {
      slot: "suffixIcon",
      type: "environment"
    },
    slot: "suffixIcon"
  })], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "社保类型",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.socType,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "socType", $$v);
      },
      expression: "needData.socType"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 0
    }
  }, [_vm._v("非首次开户")]), _c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("首次开户")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "户籍类型",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.censusType,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "censusType", $$v);
      },
      expression: "needData.censusType"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("本市城镇")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v("本市农村")]), _c("a-select-option", {
    attrs: {
      value: 3
    }
  }, [_vm._v("外埠城镇")]), _c("a-select-option", {
    attrs: {
      value: 4
    }
  }, [_vm._v("外埠农村")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "社保基数",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-tooltip", {
    attrs: {
      placement: "right",
      title: "（基数范围是3613 ~ 27786）"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.socBasic,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "socBasic", $$v);
      },
      expression: "needData.socBasic"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "工伤比例",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-tooltip", {
    attrs: {
      placement: "right",
      title: "(比例范围是0.1％ ~ 3％，推荐0.2％)"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.workInjuryProp,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "workInjuryProp", $$v);
      },
      expression: "needData.workInjuryProp"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" % ")])], 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" 社保缴纳 ")]), _c("span", {
    staticClass: "mg_r10"
  }, [_vm._v("企业")]), _c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.socAmountCompany,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "socAmountCompany", $$v);
      },
      expression: "needData.socAmountCompany"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1), _c("span", {
    staticClass: "mg_l10"
  }, [_vm._v("个人")]), _c("a-input", {
    staticClass: "mg_l20",
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.socAmountPerson,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "socAmountPerson", $$v);
      },
      expression: "needData.socAmountPerson"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1)], 1), false ? _c("a-table", {
    attrs: {
      "data-source": _vm.data,
      "row-key": function rowKey(data) {
        return data.id;
      }
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "缴纳项目",
      "data-index": "address"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "企业基数"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "企业比例"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "企业缴纳"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("a-input", {
          staticStyle: {
            width: "100px"
          },
          model: {
            value: text.firstName,
            callback: function callback($$v) {
              _vm.$set(text, "firstName", $$v);
            },
            expression: "text.firstName"
          }
        })];
      }
    }], null, false, 3787564674)
  }), _c("a-table-column", {
    attrs: {
      title: "个人基数"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "个人比例"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "个人缴纳"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("a-input", {
          staticStyle: {
            width: "100px"
          },
          model: {
            value: text.lastName,
            callback: function callback($$v) {
              _vm.$set(text, "lastName", $$v);
            },
            expression: "text.lastName"
          }
        })];
      }
    }], null, false, 2976412690)
  })], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "社保备注",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-textarea", {
    attrs: {
      autoSize: {
        minRows: 2,
        maxRows: 6
      }
    },
    model: {
      value: _vm.needData.socRemark,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "socRemark", $$v);
      },
      expression: "needData.socRemark"
    }
  })], 1)], 1), _c("div", {
    staticClass: "pd_b20 mg_t10",
    staticStyle: {
      "border-bottom": "1px solid #ccc"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "公积金城市",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-cascader", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["needData.pfCity", {
        initialValue: _vm.needData.pfCity,
        rules: [{
          required: true,
          message: "选择城市!"
        }]
      }],
      expression: "[\n              'needData.pfCity',\n              {\n                initialValue: needData.pfCity,\n                rules: [{ required: true, message: '选择城市!' }]\n              }\n            ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      options: _vm.options,
      placeholder: "选择城市"
    },
    on: {
      change: _vm.onChangeCity
    }
  }, [_c("a-icon", {
    staticClass: "test",
    attrs: {
      slot: "suffixIcon",
      type: "environment"
    },
    slot: "suffixIcon"
  })], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "公积金基数",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-tooltip", {
    attrs: {
      placement: "right",
      title: "(基数范围是1868.33 ~ 22710。低于下限时，按照下限计算；高于上限时，按照上限计算)"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.pfBasic,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfBasic", $$v);
      },
      expression: "needData.pfBasic"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "企业比例",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-tooltip", {
    attrs: {
      placement: "right",
      title: "(基数范围是1868.33 ~ 22710。低于下限时，按照下限计算；高于上限时，按照上限计算)"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.pfPropCompany,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfPropCompany", $$v);
      },
      expression: "needData.pfPropCompany"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" % ")])], 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "个人比例",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-tooltip", {
    attrs: {
      placement: "right",
      title: "(比例范围是5％ ~ 12％，推荐5％ 比例范围为5.00到12.00)"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.pfPropPerson,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfPropPerson", $$v);
      },
      expression: "needData.pfPropPerson"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" % ")])], 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "公积金缴纳",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("span", {
    staticClass: "mg_r10"
  }, [_vm._v("企业")]), _c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.pfAmountCompany,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfAmountCompany", $$v);
      },
      expression: "needData.pfAmountCompany"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1), _c("span", {
    staticClass: "mg_l10"
  }, [_vm._v("个人")]), _c("a-input", {
    staticClass: "mg_l20",
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.pfAmountPerson,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfAmountPerson", $$v);
      },
      expression: "needData.pfAmountPerson"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "公积金备注",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-textarea", {
    attrs: {
      autoSize: {
        minRows: 2,
        maxRows: 6
      }
    },
    model: {
      value: _vm.needData.pfRemark,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfRemark", $$v);
      },
      expression: "needData.pfRemark"
    }
  })], 1)], 1), false ? _c("div", {
    staticClass: "pd_b20 mg_t10",
    staticStyle: {
      "border-bottom": "1px solid #ccc"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "残障金",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.pfBasic,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfBasic", $$v);
      },
      expression: "needData.pfBasic"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "代缴服务费",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-tooltip", {
    attrs: {
      placement: "right",
      title: "(包含社保、公积金的代理服务费)"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.pfPropPerson,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfPropPerson", $$v);
      },
      expression: "needData.pfPropPerson"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "滞纳金",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.pfBasic,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfBasic", $$v);
      },
      expression: "needData.pfBasic"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1)], 1)], 1) : _vm._e(), !_vm.needData.pfUnpayMonth || !_vm.needData.socUnpayMonth ? _c("div", {
    staticClass: "pd_b20 mg_t10",
    staticStyle: {
      "border-bottom": "1px solid #ccc"
    }
  }, [!_vm.needData.socUnpayMonth ? _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v(" 本月补缴社保 ")]), _c("a-switch", {
    attrs: {
      "checked-children": "开",
      "un-checked-children": "关"
    },
    model: {
      value: _vm.needData.socPaybackMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "socPaybackMonth", $$v);
      },
      expression: "needData.socPaybackMonth"
    }
  }), _vm.needData.socPaybackMonth ? [_c("span", {
    staticClass: "mg_r10 mg_l50"
  }, [_vm._v("企业")]), _c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.socAmountCompany,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "socAmountCompany", $$v);
      },
      expression: "needData.socAmountCompany"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1), _c("span", {
    staticClass: "mg_l10"
  }, [_vm._v("个人")]), _c("a-input", {
    staticClass: "mg_l20",
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.socAmountPerson,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "socAmountPerson", $$v);
      },
      expression: "needData.socAmountPerson"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1), _c("a-icon", {
    staticClass: "mg_l50",
    attrs: {
      type: "tag"
    },
    on: {
      click: function click($event) {
        _vm.showSocRemark = !_vm.showSocRemark;
      }
    }
  })] : _vm._e()], 2) : _vm._e(), false ? _c("a-table", {
    attrs: {
      "data-source": _vm.data,
      "row-key": function rowKey(data) {
        return data.id;
      }
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "缴纳项目",
      "data-index": "address"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "企业基数"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "企业比例"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "企业缴纳"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("a-input", {
          staticStyle: {
            width: "100px"
          },
          model: {
            value: text.firstName,
            callback: function callback($$v) {
              _vm.$set(text, "firstName", $$v);
            },
            expression: "text.firstName"
          }
        })];
      }
    }], null, false, 3787564674)
  }), _c("a-table-column", {
    attrs: {
      title: "个人基数"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "个人比例"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "个人缴纳"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("a-input", {
          staticStyle: {
            width: "100px"
          },
          model: {
            value: text.lastName,
            callback: function callback($$v) {
              _vm.$set(text, "lastName", $$v);
            },
            expression: "text.lastName"
          }
        })];
      }
    }], null, false, 2976412690)
  })], 1) : _vm._e(), _vm.showSocRemark ? _c("a-form-item", {
    attrs: {
      label: "备注",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-textarea", {
    attrs: {
      placeholder: "0-300字",
      autoSize: {
        minRows: 2,
        maxRows: 6
      }
    },
    model: {
      value: _vm.needData.socPaybackMonthRemark,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "socPaybackMonthRemark", $$v);
      },
      expression: "needData.socPaybackMonthRemark"
    }
  })], 1) : _vm._e(), !_vm.needData.pfUnpayMonth ? _c("a-form-item", {
    attrs: {
      label: "本月补缴公积金",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-switch", {
    attrs: {
      "checked-children": "开",
      "un-checked-children": "关"
    },
    model: {
      value: _vm.needData.pfPaybackMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfPaybackMonth", $$v);
      },
      expression: "needData.pfPaybackMonth"
    }
  }), _vm.needData.pfPaybackMonth ? [_c("span", {
    staticClass: "mg_r10 mg_l50"
  }, [_vm._v("企业")]), _c("a-input", {
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.pfAmountCompany,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfAmountCompany", $$v);
      },
      expression: "needData.pfAmountCompany"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1), _c("span", {
    staticClass: "mg_l10"
  }, [_vm._v("个人")]), _c("a-input", {
    staticClass: "mg_l20",
    staticStyle: {
      width: "260px"
    },
    model: {
      value: _vm.needData.pfAmountPerson,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "pfAmountPerson", $$v);
      },
      expression: "needData.pfAmountPerson"
    }
  }, [_c("a-tooltip", {
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  }, [_vm._v(" ¥ ")])], 1), _c("a-icon", {
    staticClass: "mg_l50",
    attrs: {
      type: "tag"
    },
    on: {
      click: function click($event) {
        _vm.showPfRemark = !_vm.showPfRemark;
      }
    }
  })] : _vm._e()], 2) : _vm._e(), _vm.showPfRemark ? _c("a-form-item", {
    attrs: {
      label: "备注",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-textarea", {
    attrs: {
      placeholder: "0-300字",
      autoSize: {
        minRows: 2,
        maxRows: 6
      }
    },
    model: {
      value: _vm.needData.socRemark,
      callback: function callback($$v) {
        _vm.$set(_vm.needData, "socRemark", $$v);
      },
      expression: "needData.socRemark"
    }
  })], 1) : _vm._e()], 1) : _vm._e()])], 1), _c("footer", {
    staticClass: "soc_footer"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateSave
    }
  }, [_vm._v("更新保存")]), _c("a-button", {
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("取消")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };