export default {
  fields: {
    empName: {
      model: "empName"
    },
    licenseNumber: {
      model: "licenseNumber"
    },
    employedDate: {
      model: "employedDate"
    },
    income: {
      model: "income"
    },
    endowmentInsurance: {
      model: "endowmentInsurance"
    },
    medicalInsurance: {
      model: "medicalInsurance"
    },
    unemploymentInsurance: {
      model: "unemploymentInsurance"
    },
    houseProvidentFund: {
      model: "houseProvidentFund"
    },
    payableAmount: {
      model: "payableAmount"
    },
    errorMsg: {
      model: "errorMsg"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "empName",
        title: "姓名"
      }, {
        model: "licenseNumber",
        title: "身份证号"
      }, {
        model: "employedDate",
        title: "任职受雇日期"
      }, {
        model: "income",
        title: "本期收入"
      }, {
        model: "endowmentInsurance",
        title: "基本养老保险费"
      }, {
        model: "medicalInsurance",
        title: "基本医疗保险费"
      }, {
        model: "unemploymentInsurance",
        title: "失业保险费"
      }, {
        model: "houseProvidentFund",
        title: "住房公积金"
      }, {
        model: "payableAmount",
        title: "应缴纳税款"
      }],
      customize: {
        rowKey: "id"
        // pagination: {
        //   pageSize: 10
        // }
      },

      extend: {
        batch: false,
        action: true
      }
    }
  }
};