import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "body page-miH page-bgBack",
    staticStyle: {
      display: "flex",
      padding: "0"
    }
  }, [_c("div", {
    staticClass: "leftBox"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#252525"
    }
  }, [_vm._v("渠道分组")]), _vm.allGroupList.jurisdiction === 3 ? _c("a-popover", {
    attrs: {
      title: "没有权限"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("div", [_vm._v("您的权限不足，只有渠"), _c("br"), _vm._v("道总管理才可创建分组")])]), _c("a-button", {
    attrs: {
      type: "link",
      disabled: ""
    }
  }, [_c("a-icon", {
    staticStyle: {
      color: "#a6a8b4"
    },
    attrs: {
      type: "plus"
    }
  })], 1)], 2) : _c("a-button", {
    staticStyle: {
      padding: "0"
    },
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.showAddGroup
    }
  }, [_c("a-icon", {
    staticStyle: {
      color: "#000000"
    },
    attrs: {
      type: "plus"
    }
  })], 1)], 1), _c("a-input-search", {
    staticStyle: {
      width: "216px",
      "margin-top": "12px"
    },
    attrs: {
      placeholder: "搜索分组名称/分组主管",
      "allow-clear": ""
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.groupNameOrUserName,
      callback: function callback($$v) {
        _vm.groupNameOrUserName = $$v;
      },
      expression: "groupNameOrUserName"
    }
  }), _vm.allGroupList ? _c("div", {
    class: _vm.allGroupList.selectIng ? "itemHoverIng" : "itemHover",
    staticStyle: {
      cursor: "pointer",
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      padding: "12px 12px",
      "margin-top": "10px",
      "border-bottom": "1px solid #e8e8e8"
    },
    on: {
      click: _vm.showSiteItem
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#000000"
    }
  }, [_vm._v(" " + _vm._s(_vm.allGroupList.name ? _vm.allGroupList.name + " (" + _vm.allGroupList.number + ")" : "--") + " ")]), _vm.allGroupList.jurisdiction === 3 ? _c("a-popover", {
    attrs: {
      title: "没有权限"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("div", [_vm._v("您的权限不足，只有渠"), _c("br"), _vm._v("道总管理才可维护此组")])]), _c("a-icon", {
    staticStyle: {
      color: "#a6a8b4",
      cursor: "pointer"
    },
    attrs: {
      type: "setting",
      theme: "filled"
    }
  })], 2) : _c("a-icon", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      type: "setting",
      theme: "filled"
    },
    on: {
      click: function click($event) {
        return _vm.showEditGroup(_vm.allGroupList);
      }
    }
  })], 1) : _vm._e(), _vm.allGroupList.channelGroupVOList && _vm.allGroupList.channelGroupVOList.length ? _c("div", _vm._l(_vm.allGroupList.channelGroupVOList, function (item, index) {
    return _c("div", {
      key: index,
      class: item.selectIng ? "itemHoverIng" : "itemHover",
      staticStyle: {
        cursor: "pointer",
        display: "flex",
        "justify-content": "space-between",
        "align-items": "center",
        padding: "12px 12px 12px 24px",
        "border-bottom": "1px solid #e8e8e8"
      },
      on: {
        click: function click($event) {
          return _vm.showItem(item, index);
        }
      }
    }, [_c("div", {
      staticStyle: {
        "font-size": "14px",
        "font-weight": "400"
      }
    }, [_vm._v(_vm._s(item.name + " (" + item.number + ")"))]), item.name !== "默认分组" ? _c("a-icon", {
      staticStyle: {
        cursor: "pointer"
      },
      attrs: {
        type: "setting",
        theme: "filled"
      },
      on: {
        click: function click($event) {
          return _vm.showEditGroup(item);
        }
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e()], 1), _vm.showItemDetail ? _c("div", {
    staticClass: "rightBox"
  }, [_c("div", [_c("div", {
    staticClass: "titleBox"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "500",
      color: "#3c3d43"
    }
  }, [_vm._v(_vm._s(_vm.selectItem.name))]), _c("a-button", {
    attrs: {
      disabled: _vm.allGroupList.jurisdiction === 3 && _vm.selectItem.isAll === true
    },
    on: {
      click: _vm.showEmp
    }
  }, [_vm._v("分组管理员")])], 1), _vm.allGroupList.jurisdiction !== 3 || _vm.selectItem.isAll !== true ? _c("div", {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "400",
      color: "#a6a8b4"
    }
  }, [_vm._v(" " + _vm._s(_vm.selectItem.describe) + " ")]) : _vm._e()]), _c("div", {
    staticClass: "action"
  }, [_c("div", [_c("a-input-search", {
    staticStyle: {
      width: "225px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "搜索渠道名称/联系电话"
    },
    on: {
      search: _vm.inputChange
    }
  }), _c("city", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      placeholder: "所在地区",
      level: 3
    },
    on: {
      change: function change($event) {
        return _vm.handleCityForSeach($event);
      }
    }
  })], 1), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "18px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showAdd
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 新增渠道 ")], 1), _c("a-button", {
    staticStyle: {
      "margin-right": "18px"
    },
    attrs: {
      block: ""
    },
    on: {
      click: _vm.goUpload
    }
  }, [_vm._v("批量导入")]), _c("a-button", {
    attrs: {
      disabled: !_vm.dataList.length
    },
    on: {
      click: _vm.exportFile
    }
  }, [_vm._v(" 全部导出 ")])], 1)]), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      loading: _vm.loading,
      rowKey: "id",
      columns: _vm.columns,
      "data-source": _vm.dataList,
      pagination: _vm.pagination
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "groupName",
      fn: function fn(text) {
        return [_c("span", [_vm._v(" " + _vm._s(text ? text : "默认分组") + " ")])];
      }
    }, {
      key: "numberPeople",
      fn: function fn(text, record) {
        return _c("a", {
          on: {
            click: function click($event) {
              return _vm.showDetail(record);
            }
          }
        }, [_c("span", {
          staticClass: "primary"
        }, [_vm._v(" " + _vm._s(text) + " ")])]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("a", {}, [_c("span", {
          staticClass: "primary",
          on: {
            click: function click($event) {
              return _vm.handleEditOk(record);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("a-button", {
          staticClass: "primary",
          staticStyle: {
            "margin-left": "17px"
          },
          attrs: {
            type: "link",
            size: "small",
            disabled: record.numberPeople > 0
          },
          on: {
            click: function click($event) {
              return _vm.showDelete(record);
            }
          }
        }, [_vm._v(" 删除 ")])], 1);
      }
    }], null, false, 1234036068)
  })], 1), _c("div", {
    staticClass: "drawerBox"
  }, [_c("a-modal", {
    attrs: {
      title: _vm.title,
      visible: _vm.visible
    },
    on: {
      ok: _vm.handleAddOK,
      cancel: _vm.onClose
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "channelGroupId",
    attrs: {
      label: "所属分组",
      prop: "channelGroupId",
      rules: {
        required: true,
        message: "请选择"
      }
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.channelGroupId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "channelGroupId", $$v);
      },
      expression: "form.channelGroupId"
    }
  }, _vm._l(_vm.allGroupList.channelGroupVOList, function (role) {
    return _c("a-select-option", {
      key: role.id,
      attrs: {
        value: role.id
      }
    }, [_vm._v(_vm._s(role.name))]);
  }), 1)], 1), _c("a-form-model-item", {
    ref: "name",
    attrs: {
      label: "渠道名称",
      prop: "name",
      rules: {
        required: true,
        message: "请输入"
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("a-form-model-item", {
    ref: "phone",
    attrs: {
      label: "联系电话",
      prop: "phone",
      rules: {
        required: true,
        validator: _vm.validatorPhone
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入",
      disabled: _vm.title === "编辑渠道"
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c("a-form-model-item", {
    ref: "address",
    attrs: {
      label: "所在地区",
      prop: "address",
      rules: {
        required: false,
        message: "请选择"
      }
    }
  }, [_c("city", {
    attrs: {
      "default-value": _vm.form.address,
      level: 3
    },
    on: {
      change: function change($event) {
        return _vm.handleCity($event);
      }
    }
  })], 1), _c("a-form-model-item", {
    ref: "idcard",
    attrs: {
      label: "身份证号",
      prop: "idcard",
      rules: {
        validator: _vm.validatorIdCard
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.idcard,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "idcard", $$v);
      },
      expression: "form.idcard"
    }
  })], 1), _c("a-form-model-item", {
    ref: "name",
    attrs: {
      label: "银行卡号",
      prop: "name"
    }
  }, [_c("a-input-number", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.bankNo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bankNo", $$v);
      },
      expression: "form.bankNo"
    }
  })], 1), _c("a-form-model-item", {
    ref: "isAllChildCompany",
    attrs: {
      label: "用工单位",
      prop: "isAllChildCompany",
      rules: {
        required: true,
        message: "请选择"
      }
    }
  }, [_c("a-switch", {
    attrs: {
      "checked-children": "全部",
      "un-checked-children": "自选",
      "default-checked": ""
    },
    model: {
      value: _vm.form.isAllChildCompany,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isAllChildCompany", $$v);
      },
      expression: "form.isAllChildCompany"
    }
  })], 1), !_vm.form.isAllChildCompany ? _c("a-form-model-item", {
    attrs: {
      label: "分管单位"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.showModal
    }
  }, [_vm._v(" 共" + _vm._s(_vm.targetKeys.length) + "个"), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "选择分管单位",
      width: "800px"
    },
    on: {
      ok: _vm.handleOk
    },
    model: {
      value: _vm.treeVisible,
      callback: function callback($$v) {
        _vm.treeVisible = $$v;
      },
      expression: "treeVisible"
    }
  }, [_c("a-transfer", {
    staticClass: "tree-transfer",
    attrs: {
      "list-style": {
        width: "350px",
        height: "400px"
      },
      operations: ["添加", "删除"],
      "data-source": _vm.dataSource,
      "target-keys": _vm.targetKeys,
      render: function render(item) {
        return item.title;
      },
      "show-select-all": false
    },
    on: {
      change: _vm.onTransChange
    },
    scopedSlots: _vm._u([{
      key: "children",
      fn: function fn(_ref) {
        var _ref$props = _ref.props,
          direction = _ref$props.direction,
          selectedKeys = _ref$props.selectedKeys,
          itemSelect = _ref.on.itemSelect;
        return [direction === "left" ? _c("a-tree", {
          staticStyle: {
            height: "300px",
            "overflow-y": "scroll"
          },
          attrs: {
            defaultExpandAll: "",
            blockNode: "",
            checkable: "",
            checkStrictly: "",
            replaceFields: _vm.replaceFields,
            checkedKeys: [].concat(_toConsumableArray(selectedKeys), _toConsumableArray(_vm.targetKeys)),
            "tree-data": _vm.treeData
          },
          on: {
            check: function check(_, props) {
              _vm.onChecked(_, props, [].concat(_toConsumableArray(selectedKeys), _toConsumableArray(_vm.targetKeys)), itemSelect);
            },
            select: function select(_, props) {
              _vm.onChecked(_, props, [].concat(_toConsumableArray(selectedKeys), _toConsumableArray(_vm.targetKeys)), itemSelect);
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 2361672221)
  })], 1)], 1) : _vm._e()], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "删除",
      visible: _vm.deleteVisible
    },
    on: {
      ok: _vm.deleteOk,
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "28px",
      color: "#6a66f6"
    },
    attrs: {
      type: "question-circle"
    }
  }), _c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#3c3d43",
      "line-height": "20px",
      "margin-left": "20px"
    }
  }, [_vm._v(" 您确定要删除该渠道吗？一旦删除不可恢复 ")])], 1)])], 1), _c("a-modal", {
    attrs: {
      title: _vm.title2 + "招工详情",
      visible: _vm.detailVisible,
      footer: null,
      width: 800
    },
    on: {
      cancel: _vm.detailCancel
    }
  }, [_c("div", [_c("a-input-search", {
    staticStyle: {
      width: "235px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "搜索姓名/手机号/身份证号"
    },
    on: {
      search: _vm.inputChange2
    },
    model: {
      value: _vm.nameOrPhoneOrIdcard,
      callback: function callback($$v) {
        _vm.nameOrPhoneOrIdcard = $$v;
      },
      expression: "nameOrPhoneOrIdcard"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "175px",
      "margin-left": "20px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择"
    },
    on: {
      change: _vm.selectCheck2
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 已接到 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 已安排 ")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 已面试 ")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v(" 已入职 ")]), _c("a-select-option", {
    attrs: {
      value: "5"
    }
  }, [_vm._v(" 已离职 ")])], 1)], 1), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      scroll: {
        x: 600
      },
      rowKey: "id",
      columns: _vm.columns2,
      "data-source": _vm.dataList2,
      pagination: _vm.pagination2
    },
    on: {
      change: _vm.handleTableChange2
    },
    scopedSlots: _vm._u([{
      key: "joinDate",
      fn: function fn(text) {
        return _c("a", {
          staticStyle: {
            cursor: "text",
            color: "#595959"
          }
        }, [_vm._v(" " + _vm._s(text ? _vm.moment(text).format("YYYY-MM-DD") : "--") + " ")]);
      }
    }, {
      key: "companyName",
      fn: function fn(text) {
        return _c("a", {
          staticStyle: {
            cursor: "text",
            color: "#595959"
          }
        }, [_vm._v(" " + _vm._s(text ? text : "--") + " ")]);
      }
    }, {
      key: "status",
      fn: function fn(text) {
        return _c("a", {}, [text === 2 ? _c("div", {
          staticStyle: {
            "text-align": "center",
            width: "50px",
            height: "20px",
            background: "rgba(255, 166, 0, 0.1)",
            "border-radius": "2px",
            border: "1px solid #ffa600",
            "font-size": "11px",
            "font-weight": "400",
            color: "#ffa600"
          }
        }, [_vm._v(" 已安排 ")]) : _vm._e(), text === 1 ? _c("div", {
          staticStyle: {
            "text-align": "center",
            width: "50px",
            height: "20px",
            background: "rgba(106, 102, 246, 0.1)",
            "border-radius": "2px",
            border: "1px solid #6a66f6",
            "font-size": "11px",
            "font-weight": "400",
            color: "#6a66f6"
          }
        }, [_vm._v(" 已接到 ")]) : _vm._e(), text === 3 ? _c("div", {
          staticStyle: {
            "text-align": "center",
            width: "50px",
            height: "20px",
            background: "rgba(36, 205, 170, 0.1)",
            "border-radius": "2px",
            border: "1px solid #24cdaa",
            "font-size": "11px",
            "font-weight": "400",
            color: "#24cdaa"
          }
        }, [_vm._v(" 已面试 ")]) : _vm._e(), text === 4 ? _c("div", {
          staticStyle: {
            "text-align": "center",
            width: "50px",
            height: "20px",
            background: "rgba(66, 130, 255, 0.1)",
            "border-radius": "2px",
            border: "1px solid #4282ff",
            "font-size": "11px",
            "font-weight": "400",
            color: "#4282ff"
          }
        }, [_vm._v(" 已入职 ")]) : _vm._e(), text === 5 ? _c("div", {
          staticStyle: {
            "text-align": "center",
            width: "50px",
            height: "20px",
            background: "rgba(0, 0, 0, 0.04)",
            "border-radius": "2px",
            border: "1px solid rgba(0, 0, 0, 0.15)",
            "font-size": "11px",
            "font-weight": "400",
            color: "#767885"
          }
        }, [_vm._v(" 已离职 ")]) : _vm._e()]);
      }
    }], null, false, 2733601783)
  })], 1)])], 1) : _c("div", {
    staticClass: "rightBox",
    staticStyle: {
      padding: "0"
    }
  }, [_c("a-row", {
    staticClass: "head"
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        _vm.showItemDetail = true;
      }
    }
  }), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        _vm.showItemDetail = true;
      }
    }
  }, [_vm._t("subTitle", function () {
    return [_vm._v("返回")];
  })], 2), _vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.selectItem.name) + "分组管理员")];
  })], 2), _c("div", {
    staticStyle: {
      padding: "24px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "搜索管理员姓名"
    },
    on: {
      search: _vm.onSearchEmp
    },
    model: {
      value: _vm.onSearchEmpName,
      callback: function callback($$v) {
        _vm.onSearchEmpName = $$v;
      },
      expression: "onSearchEmpName"
    }
  }), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.allGroupList.jurisdiction === 2 && _vm.selectItem.isAll === true || _vm.allGroupList.jurisdiction === 3
    },
    on: {
      click: function click($event) {
        _vm.isChooseStaff = true;
      }
    }
  }, [_vm._v("新增管理员")])], 1), _c("a-table", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      rowKey: "id2",
      columns: _vm.columnsEmp,
      "data-source": _vm.dataListEmp,
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return _c("a", {}, [_c("a-button", {
          attrs: {
            type: "link",
            disabled: !record.id || _vm.allGroupList.jurisdiction === 2 && _vm.selectItem.isAll === true || _vm.allGroupList.jurisdiction === 3
          },
          on: {
            click: function click($event) {
              return _vm.deleteEmp(record);
            }
          }
        }, [_vm._v(" 删除 ")])], 1);
      }
    }])
  }), _c("employee-select", {
    attrs: {
      show: _vm.isChooseStaff,
      "default-selected-keys": _vm.empList,
      "selected-list": _vm.empList,
      groupId: _vm.selectItem.id
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.isChooseStaff = $event;
      },
      "update:selectedList": function updateSelectedList($event) {
        _vm.empList = $event;
      },
      "update:selected-list": function updateSelectedList($event) {
        _vm.empList = $event;
      },
      change: _vm.onEmployeeSelectChange
    }
  })], 1)], 1), _c("a-drawer", {
    attrs: {
      width: 450,
      title: _vm.groupTitle,
      placement: "right",
      visible: _vm.groupVisible
    },
    on: {
      close: _vm.onGroupClose
    }
  }, [_c("div", [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#fa5051",
      "margin-right": "4px"
    }
  }, [_vm._v("*")]), _c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#55565d"
    }
  }, [_vm._v("分组名称")]), _c("a-input", {
    staticStyle: {
      width: "248px",
      "margin-left": "10px"
    },
    attrs: {
      disabled: _vm.isLevel,
      placeholder: "请输入分组名称"
    },
    model: {
      value: _vm.groupName,
      callback: function callback($$v) {
        _vm.groupName = $$v;
      },
      expression: "groupName"
    }
  })], 1), _c("div", {
    staticClass: "flex-start-center",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#55565d",
      "margin-left": "9px"
    }
  }, [_vm._v("分组说明")]), _c("a-textarea", {
    staticStyle: {
      width: "248px",
      "margin-left": "10px",
      "min-height": "120px"
    },
    attrs: {
      maxLength: 150,
      placeholder: "最多150个字"
    },
    model: {
      value: _vm.groupDescribe,
      callback: function callback($$v) {
        _vm.groupDescribe = $$v;
      },
      expression: "groupDescribe"
    }
  })], 1)]), _c("div", {
    style: {
      position: "absolute",
      right: 0,
      bottom: 0,
      width: "100%",
      borderTop: "1px solid #e9e9e9",
      padding: "10px 16px",
      background: "#fff",
      textAlign: "right",
      zIndex: 1,
      display: "flex",
      justifyContent: "center"
    }
  }, [_vm.groupTitle === "编辑分组" && !_vm.selectItem.isAll && _vm.allGroupList.jurisdiction !== 3 ? _c("a-button", {
    style: {
      marginRight: "8px",
      color: _vm.allGroupList.jurisdiction !== 3 ? "#FA5051" : "none",
      borderColor: _vm.allGroupList.jurisdiction !== 3 ? "#FA5051" : "none"
    },
    on: {
      click: _vm.deleteGroup
    }
  }, [_vm._v(" 删除 ")]) : _vm._e(), _c("a-button", {
    style: {
      marginRight: "8px"
    },
    on: {
      click: _vm.onGroupClose
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addGroupOk
    }
  }, [_vm._v(" 确定 ")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };