import request from "@/utils/request";
export function getSignData(data) {
  return request({
    url: "/hr/api/v1/signUps/listAll",
    method: "get",
    data: data
  });
}
// 批量修改
export function putSignData(data) {
  return request({
    url: "/hr/api/v1/signUps/updateList",
    method: "put",
    data: data
  });
}