import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "24px",
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "optionalFieldsBox"
  }, [_c("div", {
    staticClass: "optionalFieldsLeft"
  }, [_vm._v("可选字段")]), _c("div", {
    staticStyle: {
      "margin-top": "12px",
      display: "flex",
      "flex-wrap": "wrap"
    }
  }, _vm._l(_vm.fieldList, function (item, index) {
    return _c("a-button", {
      key: index,
      staticClass: "optionalFieldsLAddField",
      attrs: {
        disabled: !item.canUpdate
      },
      on: {
        click: function click($event) {
          return _vm.addField(item);
        }
      }
    }, [item.isChecked ? _c("span", {
      staticClass: "icon iconfont icon-xuanzhong iconShow",
      staticStyle: {
        position: "absolute",
        top: "-11px",
        right: "0"
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(item.fieldName) + " ")]);
  }), 1)]), _c("div", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm.siteFieldList ? _c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 14
      }
    }
  }, [_c("draggable", {
    attrs: {
      group: "itxst",
      animation: "300",
      filter: ".undraggable",
      move: _vm.onMove
    },
    model: {
      value: _vm.siteFieldList,
      callback: function callback($$v) {
        _vm.siteFieldList = $$v;
      },
      expression: "siteFieldList"
    }
  }, _vm._l(_vm.siteFieldList, function (item, index) {
    return _c("div", {
      key: index,
      class: _vm.canUpdateArr.includes(item.enName) ? "undraggable" : "draggable",
      staticStyle: {
        cursor: "move"
      }
    }, [_c("div", [_c("a-row", {
      staticClass: "rigBox"
    }, [!_vm.canUpdateArr.includes(item.enName) ? _c("a-col", {
      staticClass: "hei56",
      attrs: {
        span: 1
      }
    }, [_c("a-icon", {
      staticClass: "tableIcon",
      attrs: {
        type: "unordered-list"
      }
    })], 1) : _c("a-col", {
      staticClass: "hei56",
      attrs: {
        span: 1
      }
    }), _c("a-col", {
      staticClass: "hei56",
      attrs: {
        span: 17
      }
    }, [_c("a-row", [_c("a-col", {
      staticStyle: {
        "margin-top": "9px"
      },
      attrs: {
        span: 21
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        editable: true,
        form: _vm.form,
        dataSource: _vm.basicData,
        item: _vm.formField[item.enName]
      }
    })], 1), !_vm.canUpdateArr.includes(item.enName) ? _c("a-col", {
      attrs: {
        span: 3
      }
    }, [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.necessaryChange(item);
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    }, [_vm._v("必填")])], 1) : _vm._e()], 1)], 1), _c("a-col", {
      staticClass: "parelative",
      attrs: {
        span: 6
      }
    }, [!_vm.canUpdateArr.includes(item.enName) ? _c("div", {
      staticClass: "paAbsolut"
    }, [_c("a-icon", {
      staticClass: "tableIcon1",
      attrs: {
        type: "close"
      },
      on: {
        click: function click($event) {
          return _vm.delClose(item, index);
        }
      }
    })], 1) : _vm._e()])], 1)], 1)]);
  }), 0)], 1) : _vm._e()], 1)]), _c("div", {
    staticClass: "bottomButton"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveData
    }
  }, [_vm._v("保存")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };