import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    status: {
      type: Boolean,
      default: true
    },
    rdata: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      s: require("@/assets/recruitment/s1.png"),
      e: require("@/assets/recruitment/e1.png")
    };
  }
};