import request from "@/utils/request";
export function getSharePositionByH5Req() {
  return request({
    url: "/recruit2/api/v1/recruitInfos/getSharePositionByH5",
    method: "post"
  });
}
export function getCompanyUnits(params) {
  return request({
    url: "/recruit2/api/v1/companyUnits/pageByCondition",
    method: "GET",
    params: params
  });
}
export function getCompanyUnitsId(data) {
  return request({
    url: "".concat(data),
    method: "GET"
  });
}
export function getFindByRecruitInfoId(data) {
  return request({
    url: "".concat(data),
    method: "GET"
  });
}
export function getRecruitInfos(params) {
  return request({
    url: "/recruit2/api/v1/recruitInfos/listAllByIsCancel",
    method: "GET",
    params: params
  });
}
export function getPostBrokerInfo(params) {
  return request({
    url: "/recruit2/api/v1/deliverRecords/getPostBrokerInfo",
    method: "GET",
    params: params
  });
}
export function getEmpPostInfo(params) {
  return request({
    url: "/recruit2/api/v1/deliverRecords/getEmpPostInfo",
    method: "GET",
    params: params
  });
}
export function getFindById(recruitInfoId) {
  return request({
    url: "/recruit2/api/v1/recruitInfos/findById/".concat(recruitInfoId),
    method: "GET"
  });
}
export function postRecruitInfos(data) {
  return request({
    url: "/recruit2/api/v1/recruitInfos",
    method: "post",
    data: data
  });
}
export function putRecruitInfos(data) {
  return request({
    url: "/recruit2/api/v1/recruitInfos",
    method: "put",
    data: data
  });
}
export function putCancelBatch(data) {
  return request({
    url: "/recruit2/api/v1/recruitInfos/CancelBatch",
    method: "put",
    data: data
  });
}
export function delRecruitInfos(companyUnitsId) {
  return request({
    url: "/recruit2/api/v1/recruitInfos/".concat(companyUnitsId),
    method: "delete"
  });
}
export function getCompanies(params) {
  return request({
    url: "/hr/api/v1/companies/listAllNoSynchronization",
    method: "GET",
    params: params
  });
}