var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq_warp"
  }, _vm._l(_vm.value, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "xq_tab",
      class: _vm.active == index + 1 ? "xq_tab_active" : "",
      on: {
        click: function click($event) {
          return _vm.onTab(index, item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };