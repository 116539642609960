export default {
  fields: {
    name: {
      model: "name",
      type: "input",
      wrapper: {
        label: "姓名"
      }
    },
    companyName: {
      model: "companyName",
      type: "input",
      wrapper: {
        label: "公司"
      }
    },
    phone: {
      model: "phone",
      type: "input",
      wrapper: {
        label: "手机号"
      }
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "name",
        title: "名次"
      }, {
        model: "companyName",
        title: "姓名"
      }, {
        model: "phone",
        title: "推荐人数"
      }],
      customize: {
        rowKey: "id"
        // pagination: {
        //   pageSize: 10
        // }
      },

      extend: {
        batch: false,
        action: false
      }
    }
  }
};