// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./tj.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".band-wrapper[data-v-1a7e2186] {\n  position: absolute;\n  top: -20px;\n  left: -16px;\n  width: 50px;\n  height: 50px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n}\n.tag[data-v-1a7e2186] {\n  color: #fa8c16;\n  background: #fff7e6;\n  border: 1px solid #ffd591;\n  padding: 0 7px;\n  margin-right: 8px;\n  line-height: 20px;\n  font-size: 12px;\n  display: inline-block;\n  border-radius: 4px;\n}", ""]);
// Exports
module.exports = exports;
