var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "revset"
  }, [_c("a-layout", [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 添加补差 ")]), _c("div", {
    staticStyle: {
      "background-color": "#FAFAFA",
      "margin-bottom": "24px",
      "padding-top": "20px"
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 7,
      offset: 3
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "补差开始时间",
      required: ""
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      "margin-left": "10px",
      width: "150px"
    },
    attrs: {
      placeholder: "请选择",
      "disabled-date": _vm.disabledDate
    },
    on: {
      change: _vm.handleProgTime
    }
  })], 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 7,
      offset: 3
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "补差结束时间",
      required: ""
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      "margin-left": "10px",
      width: "150px"
    },
    attrs: {
      placeholder: "请选择",
      "disabled-date": _vm.disabledDate2
    },
    on: {
      change: _vm.handleProgTime2
    }
  })], 1)], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 7,
      offset: 3
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "补差参保方案"
    }
  }, [_c("a-input", {
    staticStyle: {
      "margin-left": "10px",
      width: "150px"
    },
    attrs: {
      disabled: true,
      placeholder: "请填写方案名称"
    },
    model: {
      value: _vm.progName,
      callback: function callback($$v) {
        _vm.progName = $$v;
      },
      expression: "progName"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.onlyAmount === 0 ? _c("shebao-table", {
    attrs: {
      "prog-id": _vm.progId,
      "prog-name": _vm.progName,
      "soc-city-name": _vm.socCityName,
      "soc-city": _vm.socCity,
      deductionsDate: _vm.deductionsDate,
      endDt: _vm.endDt,
      startDt: _vm.startDt
    }
  }) : _vm._e(), _vm.onlyAmount === 1 ? _c("only-amount-table", {
    attrs: {
      "prog-id": _vm.progId,
      "prog-name": _vm.progName,
      "soc-city-name": _vm.socCityName,
      "soc-city": _vm.socCity,
      deductionsDate: _vm.deductionsDate,
      endDt: _vm.endDt,
      startDt: _vm.startDt
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };