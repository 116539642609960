var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("rTitle", {
    attrs: {
      type: "center",
      tit: "人才智能推荐"
    }
  }), _c("div", {
    staticClass: "p24"
  }, [_c("div", {
    staticClass: "flex-center"
  }, [_c("div", {
    staticClass: "fs16 fw500"
  }, [_vm._v("智能推荐岗位：")]), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      options: _vm.gangweiList,
      filterOption: _vm.filterOption,
      showSearch: "",
      allowClear: "",
      placeholder: "请选择意向岗位"
    },
    on: {
      change: _vm.onGangweiSearch
    },
    model: {
      value: _vm.intentionJob,
      callback: function callback($$v) {
        _vm.intentionJob = $$v;
      },
      expression: "intentionJob"
    }
  })], 1), _c("div", {
    staticClass: "mt12 flex-center"
  }, [_c("div", {
    staticClass: "fs16 fw500"
  }, [_vm._v("智能推荐职位类型：")]), _c("a-cascader", {
    staticClass: "mr24",
    staticStyle: {
      width: "300px"
    },
    attrs: {
      disabled: "",
      options: _vm.positionTypes,
      fieldNames: {
        label: "name",
        value: "id",
        children: "childList"
      },
      placeholder: "职位类型"
    },
    model: {
      value: _vm.positionType,
      callback: function callback($$v) {
        _vm.positionType = $$v;
      },
      expression: "positionType"
    }
  })], 1), _c("div", {
    staticClass: "mt12 flex-center"
  }, [_c("div", {
    staticClass: "fs16 fw500"
  }, [_vm._v("全部筛选项：")]), _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.init
    }
  }, [_c("a-icon", {
    attrs: {
      type: "reload"
    }
  }), _vm._v(" 重置 ")], 1)], 1)]), _c("div", {
    staticClass: "r-talent-box"
  }, [_c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 4
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "姓名/手机号搜索",
      allowClear: ""
    },
    on: {
      change: _vm.debouncedSearch
    },
    model: {
      value: _vm.jobSeekerNameOrPhone,
      callback: function callback($$v) {
        _vm.jobSeekerNameOrPhone = $$v;
      },
      expression: "jobSeekerNameOrPhone"
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 4
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "性别",
      "allow-clear": "",
      placeholder: "请选择性别"
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.gender,
      callback: function callback($$v) {
        _vm.gender = $$v;
      },
      expression: "gender"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "男"
    }
  }, [_vm._v("男")]), _c("a-select-option", {
    attrs: {
      value: "女"
    }
  }, [_vm._v("女")])], 1)], 1), _c("a-col", {
    attrs: {
      span: 4
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "在职状态",
      "allow-clear": "",
      placeholder: "请选择在职状态"
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.serviceStatus,
      callback: function callback($$v) {
        _vm.serviceStatus = $$v;
      },
      expression: "serviceStatus"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "在职"
    }
  }, [_vm._v("在职")]), _c("a-select-option", {
    attrs: {
      value: "未在职"
    }
  }, [_vm._v("未在职")])], 1)], 1), _c("a-col", {
    attrs: {
      span: 5
    }
  }, [_c("city", {
    staticStyle: {
      width: "100%",
      "min-width": "auto"
    },
    attrs: {
      defaultValue: _vm.addressCode,
      level: 2,
      placeholder: "工作城市"
    },
    on: {
      change: _vm.onCityChange
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 7
    }
  }, [_c("a-input-group", {
    attrs: {
      compact: ""
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "110px",
      "text-align": "center"
    },
    attrs: {
      placeholder: "最小年龄"
    },
    on: {
      change: _vm.debouncedSearch
    },
    model: {
      value: _vm.ageStart,
      callback: function callback($$v) {
        _vm.ageStart = $$v;
      },
      expression: "ageStart"
    }
  }), _c("a-input", {
    staticStyle: {
      width: "32px",
      "border-left": "0",
      "pointer-events": "none",
      backgroundcolor: "#fff"
    },
    attrs: {
      placeholder: "~",
      disabled: ""
    }
  }), _c("a-input", {
    staticStyle: {
      width: "110px",
      "text-align": "center",
      "border-left": "0"
    },
    attrs: {
      placeholder: "最大年龄"
    },
    on: {
      change: _vm.debouncedSearch
    },
    model: {
      value: _vm.ageEnd,
      callback: function callback($$v) {
        _vm.ageEnd = $$v;
      },
      expression: "ageEnd"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "mt24 fs14",
    staticStyle: {
      display: "flex",
      "font-weight": "40",
      color: "#636363"
    }
  }, [_c("div", [_vm._v("工作经验：")]), _vm._l(_vm.experienceList, function (item, index) {
    return _c("div", {
      key: index,
      class: _vm.colHighlighted === index ? "colpri" : "",
      staticStyle: {
        "margin-left": "20px",
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.experienceChange(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), _c("div", {
    staticClass: "custom1",
    class: _vm.colHighlighted === 5 ? "colpri" : "",
    staticStyle: {
      "margin-left": "20px"
    },
    on: {
      click: _vm.zdy
    }
  }, [_vm._v("自定义")]), _vm.isCustom ? _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "120px",
      "margin-top": "-4px",
      "margin-left": "20px"
    },
    attrs: {
      min: 0,
      placeholder: "请输入"
    },
    model: {
      value: _vm.startYear,
      callback: function callback($$v) {
        _vm.startYear = $$v;
      },
      expression: "startYear"
    }
  }), _c("div", {
    staticStyle: {
      "margin-left": "12px"
    }
  }, [_vm._v("-")]), _c("a-input-number", {
    staticStyle: {
      width: "120px",
      "margin-top": "-4px",
      "margin-left": "12px"
    },
    attrs: {
      min: _vm.startYear || 0,
      placeholder: "请输入"
    },
    model: {
      value: _vm.endYear,
      callback: function callback($$v) {
        _vm.endYear = $$v;
      },
      expression: "endYear"
    }
  }), _c("a-button", {
    staticStyle: {
      "margin-top": "-4px"
    },
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.experienceChange();
      }
    }
  }, [_vm._v("确认")])], 1) : _vm._e()], 2), _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_vm.tableData.length > 0 ? _c("a-row", {
    staticClass: "mt24",
    attrs: {
      gutter: 16
    }
  }, _vm._l(_vm.tableData, function (record) {
    return _c("a-col", {
      key: record.id,
      attrs: {
        span: 8
      }
    }, [_c("div", {
      staticClass: "table-item p12",
      on: {
        click: function click($event) {
          return _vm.onInfo(record);
        }
      }
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "space-between"
      }
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        "align-items": "center"
      }
    }, [_c("a-tooltip", {
      attrs: {
        title: record.jobSeekerName
      }
    }, [_c("span", {
      staticClass: "fs16 fw500",
      staticStyle: {
        color: "rgba(60, 61, 67, 1)",
        width: "60px",
        overflow: "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap"
      }
    }, [_vm._v(" " + _vm._s(record.jobSeekerName) + " ")])]), _c("a-space", [_c("span", [_vm._v(_vm._s(record.addressCode ? _vm.getAddressCode(record.addressCode) : ""))]), record.addressCode ? _c("span", [_vm._v("|")]) : _vm._e(), _c("span", [_vm._v(_vm._s(record.sex ? record.sex === "男" ? "男" : "女" : ""))]), record.sex && record.birthday ? _c("span", [_vm._v("|")]) : _vm._e(), _c("span", [_vm._v(_vm._s(record.birthday ? _vm.getBirthday(record.birthday) > 0 ? "".concat(_vm.getBirthday(record.birthday), "\u5C81") : "0岁" : ""))])])], 1), _c("div", [_c("a-icon", {
      staticStyle: {
        color: "#bfbfbf"
      },
      attrs: {
        type: "phone",
        theme: "filled"
      }
    }), _vm._v(" " + _vm._s(record.phone) + " ")], 1)]), _c("div", {
      staticClass: "mt16 fs12"
    }, [_c("span", [_vm._v("意向岗位：")]), _vm._v(" " + _vm._s(record.intentionJob ? record.intentionJob.split("*")[1] : "") + " ")]), _c("div", {
      staticClass: "mt12 fs12"
    }, [_c("span", [_vm._v("工作经验：")]), _vm._v(" " + _vm._s(record.workYears ? "".concat(record.workYears, "\u5E74") : "") + " ")]), record.serviceStatus === "在职" ? _c("div", {
      staticClass: "mt16 ph12 pv2 fs12",
      staticStyle: {
        display: "inline-block",
        "border-radius": "4px",
        color: "rgba(247, 181, 0, 1)",
        background: "rgba(247, 181, 0, 0.1)"
      }
    }, [_vm._v(" 在职 ")]) : _c("div", {
      staticClass: "mt16 ph12 pv2 fs12",
      staticStyle: {
        display: "inline-block",
        "border-radius": "4px",
        color: "rgba(109, 212, 0, 1)",
        background: "rgba(109, 212, 0, 0.1)"
      }
    }, [_vm._v(" 未在职 ")]), _c("div", {
      staticStyle: {
        position: "absolute",
        bottom: "16px",
        right: "16px"
      }
    }, [_c("div", [_c("a-button", {
      attrs: {
        size: "small",
        type: "primary"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.addRecord(record);
        }
      }
    }, [_vm._v("添加跟进")])], 1), _c("div", {
      staticClass: "mt8"
    }, [_c("a-button", {
      attrs: {
        size: "small",
        type: "default"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.doInfo(record);
        }
      }
    }, [_vm._v("一键投递")])], 1)])])]);
  }), 1) : _c("div", {
    staticClass: "ta-center pv32"
  }, [_c("img", {
    staticStyle: {
      width: "92px",
      height: "92px"
    },
    attrs: {
      src: require("./zanwushuju.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "mt12"
  }, [_vm._v("暂无数据")]), _c("div", {
    staticClass: "fs12 mt12",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("请先在上方选择需要智能推荐的岗位")]), _c("div", {
    staticClass: "fs12",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("或完善需要智能推荐岗位中的职位类型")])]), _c("div", {
    staticClass: "mt12",
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "flex-end"
    }
  }, [_c("div", {
    staticClass: "mr12"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-quick-jumper": true,
      size: "small"
    },
    on: {
      change: _vm.onPageChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1)]), _c("a-modal", {
    attrs: {
      title: "添加跟进记录"
    },
    on: {
      ok: _vm.onAddOk
    },
    model: {
      value: _vm.addVisible,
      callback: function callback($$v) {
        _vm.addVisible = $$v;
      },
      expression: "addVisible"
    }
  }, [_c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进人",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.adminUsers,
      showSearch: "",
      filterOption: _vm.filterOption,
      placeholder: "请选择跟进人"
    },
    model: {
      value: _vm.recordCur,
      callback: function callback($$v) {
        _vm.recordCur = $$v;
      },
      expression: "recordCur"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进方式",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.followTypeList,
      placeholder: "请选择跟进方式"
    },
    model: {
      value: _vm.recordMed,
      callback: function callback($$v) {
        _vm.recordMed = $$v;
      },
      expression: "recordMed"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进内容",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请填写跟进内容"
    },
    model: {
      value: _vm.recordCon,
      callback: function callback($$v) {
        _vm.recordCon = $$v;
      },
      expression: "recordCon"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };