import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import TemplateConfigTable from "../components/templateConfigTable.vue";
import { generateFieldItem } from "./funcs";
export default {
  name: "",
  components: {
    TemplateConfigTable: TemplateConfigTable
  },
  mixins: [],
  props: {
    dataSource: {
      type: Object,
      default: function _default() {}
    },
    index: {
      type: Number,
      default: 0
    },
    isEnd: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      nameCopy: "",
      fieldsCopy: []
    };
  },
  computed: {},
  watch: {
    dataSource: {
      handler: function handler(val) {
        this.nameCopy = val.name;
        this.fieldsCopy = val.fields;
      },
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    editField: function editField(_ref) {
      var params = _ref.params,
        mode = _ref.mode,
        index = _ref.index;
      if (mode === "add") {
        var newItem = generateFieldItem(params);
        this.$emit("updateItem", this.index, {
          name: this.nameCopy,
          fields: [].concat(_toConsumableArray(this.fieldsCopy), [newItem])
        });
      } else if (mode === "edit") {
        var tempLst = _toConsumableArray(this.fieldsCopy);
        tempLst[index] = generateFieldItem(params, tempLst[index]);
        this.$emit("updateItem", this.index, {
          name: this.nameCopy,
          fields: tempLst
        });
      }
    },
    sortField: function sortField(oldIdx, newIdx) {
      var tempLst = _toConsumableArray(this.fieldsCopy);
      var oldItem = tempLst[oldIdx];
      tempLst.splice(oldIdx, 1);
      tempLst.splice(newIdx, 0, oldItem);
      tempLst.forEach(function (item, index) {
        item.sort = index + 1;
      });
      this.$emit("updateItem", this.index, {
        name: this.nameCopy,
        fields: tempLst
      });
    },
    deleteField: function deleteField(index) {
      var tempLst = _toConsumableArray(this.fieldsCopy);
      tempLst.splice(index, 1);
      this.$emit("updateItem", this.index, {
        name: this.nameCopy,
        fields: tempLst
      });
    },
    delProcessItem: function delProcessItem() {
      this.$emit("delItem", this.index);
    },
    editProcessItem: function editProcessItem() {
      this.$emit("editItem", this.index);
    }
  }
};