var columns = [{
  title: "",
  dataIndex: "window",
  scopedSlots: {
    customRender: "window"
  }
}, {
  title: "姓名",
  dataIndex: "name"
}, {
  title: "参保城市",
  dataIndex: "socCityName"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200
}, {
  title: "参保方案",
  dataIndex: "socProgName"
}, {
  title: "社保停缴时间",
  dataIndex: "socEndDate",
  scopedSlots: {
    customRender: "socEndDate"
  }
}, {
  title: "住房公积金停缴时间",
  dataIndex: "fundEndDate",
  scopedSlots: {
    customRender: "fundEndDate"
  }
}, {
  title: "指派公司",
  dataIndex: "assignCompanyName"
}, {
  title: "状态",
  dataIndex: "status",
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "操作",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  }
}];
var innerData = [];
export default {
  name: "TableDetail",
  props: {
    options: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      innerData: innerData,
      columns: columns,
      dataSource: [],
      pagination: {
        pageSize: 10,
        total: 0
      }
    };
  },
  watch: {
    options: function options(val) {
      this.pagination.total = val.entityCount;
      this.dataSource = val.entities;
    }
  },
  methods: {
    handelEvent: function handelEvent(e) {
      this.$emit("change", {
        visible: true,
        e: e
      });
    },
    handlePage: function handlePage(_ref) {
      var current = _ref.current;
      this.$emit("change", {
        current: current
      });
    },
    openDetail: function openDetail(e) {}
  }
};