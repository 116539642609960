import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", [_vm.showDetail ? _c("div", {
    staticClass: "fix-item-wrapper2"
  }, [_c("div", {
    staticClass: "fix-item-one"
  }, [_c("div", {
    staticClass: "fix-item-name"
  }, [!_vm.target.isOpen && _vm.target.hrMonthlySalarySummaryVOList && _vm.target.hrMonthlySalarySummaryVOList.length > 0 ? _c("div", {
    staticClass: "showIcon",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.openClick(_vm.target);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e(), _vm.target.isOpen && _vm.target.hrMonthlySalarySummaryVOList && _vm.target.hrMonthlySalarySummaryVOList.length > 0 ? _c("div", {
    staticClass: "showIcon",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.openClick(_vm.target);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "minus"
    }
  })], 1) : _vm._e(), !_vm.target.hrMonthlySalarySummaryVOList || _vm.target.hrMonthlySalarySummaryVOList.length === 0 ? _c("div", {
    staticClass: "noShowIcon",
    staticStyle: {
      cursor: "pointer"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "file-text",
      theme: "filled"
    }
  })], 1) : _vm._e(), _c("a-tooltip", {
    attrs: {
      title: _vm.target.name
    }
  }, [_c("div", {
    staticStyle: {
      width: "75%",
      overflow: "hidden",
      "word-break": "break-all",
      "text-overflow": "ellipsis"
    }
  }, [_vm._v(" " + _vm._s(_vm.target.name) + " ")])])], 1)]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", {
    staticStyle: {
      width: "25%",
      "text-align": "left"
    }
  }, [_c("div", {
    staticClass: "childContentName"
  }, [_vm._v(_vm._s(_vm.target.empNumber ? _vm.target.empNumber : 0) + "人")])]), _vm.customerDetailsType === "salary" ? _c("div", {
    staticStyle: {
      width: "25%",
      "text-align": "left"
    }
  }, [_c("div", {
    staticClass: "childContentName"
  }, [_vm._v(_vm._s(_vm.target.shouldWages ? _vm.target.shouldWages : 0) + "元")])]) : _vm._e(), _vm.customerDetailsType === "salary" ? _c("div", {
    staticStyle: {
      width: "25%",
      "text-align": "left"
    }
  }, [_c("div", {
    staticClass: "childContentName"
  }, [_vm._v(_vm._s(_vm.target.paidWages ? _vm.target.paidWages : 0) + "元")])]) : _vm._e(), _vm.customerDetailsType === "social" ? _c("div", {
    staticStyle: {
      width: "25%",
      "text-align": "left"
    }
  }, [_c("div", {
    staticClass: "childContentName"
  }, [_vm._v(_vm._s(_vm.target.enterpriseSocialSecurity ? _vm.target.enterpriseSocialSecurity : 0) + "元")])]) : _vm._e(), _vm.customerDetailsType === "social" ? _c("div", {
    staticStyle: {
      width: "25%",
      "text-align": "left"
    }
  }, [_c("div", {
    staticClass: "childContentName"
  }, [_vm._v(_vm._s(_vm.target.enterpriseProvidentFund ? _vm.target.enterpriseProvidentFund : 0) + "元")])]) : _vm._e(), _vm.customerDetailsType === "customer" ? _c("div", {
    staticStyle: {
      width: "25%",
      "text-align": "left"
    }
  }, [_c("div", {
    staticClass: "childContentName"
  }, [_vm._v(_vm._s(_vm.target.laborCost ? _vm.target.laborCost : 0) + "元")])]) : _vm._e(), _c("div", {
    staticStyle: {
      width: "25%",
      "text-align": "left"
    }
  }, [_c("div", {
    staticClass: "childContentName"
  }, [_vm._v(_vm._s(_vm.target.serviceCharge ? _vm.target.serviceCharge : 0) + "元")])]), _c("div", {
    staticStyle: {
      width: "25%",
      "text-align": "left"
    }
  }, [_c("div", {
    staticClass: "childContentName"
  }, [_vm._v(_vm._s(_vm.target.customerFees ? _vm.target.customerFees : 0) + "元")])])]), _c("div", {
    staticClass: "fix-item-three"
  }, [_c("div", [_c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.goDetail(_vm.target);
      }
    }
  }, [_vm._v("详情")]), _vm.target.type === "company" ? _c("a-popconfirm", {
    attrs: {
      title: "确定删除?"
    },
    on: {
      confirm: function confirm($event) {
        return _vm.deleteItem(_vm.target);
      }
    }
  }, [_c("a-icon", {
    staticStyle: {
      color: "red"
    },
    attrs: {
      slot: "icon",
      type: "question-circle-o"
    },
    slot: "icon"
  }), _c("a-button", {
    attrs: {
      type: "link"
    }
  }, [_vm._v("删除")])], 1) : _vm._e()], 1)])]) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.target.isOpen && _vm.target.hrMonthlySalarySummaryVOList,
      expression: "target.isOpen && target.hrMonthlySalarySummaryVOList"
    }]
  }, _vm._l(_vm.target.hrMonthlySalarySummaryVOList, function (item2, index2) {
    return _c("div", {
      key: index2
    }, [_c("item-customer", {
      attrs: {
        target: item2,
        customerDetailsType: _vm.customerDetailsType
      },
      on: {
        openClick: _vm.openClick,
        goDetail: _vm.goDetail,
        deleteItem: _vm.deleteItem
      }
    })], 1);
  }), 0)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };