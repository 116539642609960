export default {
  props: {
    tit: {
      type: String,
      default: ""
    },
    desc: {
      type: String,
      default: ""
    }
  }
};