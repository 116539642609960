import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { selectSiteDefaultTemplateReq, selectCustomTemplatePageReq } from "./api";
import TempItem from "../components/tempItem";
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {
    TempItem: TempItem
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      keywordT: "",
      keywordU: "",
      defaultLst: [],
      customLst: []
    };
  },
  computed: _objectSpread({}, mapGetters(["permissions_dycl"])),
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.loadTemps();
  },
  methods: {
    goAdd: function goAdd() {
      this.$router.push({
        path: "/daiyuchuli/templates/create"
      });
    },
    loadTemps: function loadTemps() {
      var _this = this;
      selectSiteDefaultTemplateReq().then(function (res) {
        if (res && res.data) {
          _this.defaultLst = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
      selectCustomTemplatePageReq(this.keywordT, this.keywordU).then(function (res) {
        if (res && res.data) {
          _this.customLst = res.data.entities;
        }
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};