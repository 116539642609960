var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", [_c("div", {
    staticClass: "head"
  }, [_c("a-button", {
    staticStyle: {
      color: "#d8d8d8"
    },
    attrs: {
      icon: "left",
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")])], 1), _c("div", {
    staticClass: "operation"
  }, [_c("p", {
    staticStyle: {
      "font-size": "14px",
      color: "#222426",
      "margin-left": "20px"
    }
  }, [_vm._v("批量导入/修改数据")]), _c("div", [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 放弃编辑 ")]), _c("a-button", {
    staticClass: "save",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v(" 保存 ")])], 1)]), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      pagination: false,
      "row-key": "id",
      "data-source": _vm.dataList,
      scroll: {
        x: 1500
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (item, index) {
      return {
        key: "".concat(item.dataIndex),
        fn: function fn(text, record) {
          return _c("div", {
            key: index
          }, [item.fieldGroup !== "wage" ? _c("span", [_vm._v(_vm._s(record["".concat(item.dataIndex)]))]) : item === "出勤天数" ? _c("a-input-number", {
            attrs: {
              min: 0,
              max: _vm.maxDate,
              step: 0.01,
              precision: 2,
              value: record["".concat(item.dataIndex)]
            },
            on: {
              change: function change($event) {
                return _vm.handleSelfChange($event, record.id, item.dataIndex);
              }
            }
          }) : item.dataIndex === "工时" ? _c("a-input-number", {
            attrs: {
              min: 0,
              max: 9999,
              step: 0.0001,
              precision: 4,
              value: record["".concat(item.dataIndex)]
            },
            on: {
              change: function change($event) {
                return _vm.handleSelfChange($event, record.id, item.dataIndex);
              }
            }
          }) : _c("a-input-number", {
            attrs: {
              value: record["".concat(item.dataIndex)]
            },
            on: {
              change: function change($event) {
                return _vm.handleSelfChange($event, record.id, item.dataIndex);
              }
            }
          })], 1);
        }
      };
    })], null, true)
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };