var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.actionDetail.year + "年" + _vm.actionDetail.month + "月" + _vm.actionDetail.missionName))]), _c("div", {
    staticClass: "payslip-detail"
  }, [_c("div", {
    staticClass: "titleBox"
  }, [_c("div", {
    staticStyle: {
      width: "40%",
      height: "100px",
      "border-radius": "4px",
      border: "1px solid #E8E8E8",
      display: "flex",
      padding: "22px"
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center",
      "line-height": "52px",
      width: "200px",
      "font-size": "18px",
      "font-weight": "500",
      color: "#3C3D43",
      "border-right": "1px solid #E8E8E8"
    }
  }, [_vm._v(" 合计 ")]), _c("div", {
    staticStyle: {
      "padding-left": "150px",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.changeTab("all");
      }
    }
  }, [_c("div", {
    class: _vm.params.state === undefined ? "titleStyleSelect" : "titleStyle"
  }, [_vm._v("总派单")]), _c("div", {
    class: _vm.params.state === undefined ? "contentStyleSelect" : "contentStyle"
  }, [_vm._v(" " + _vm._s(_vm.actionDetail.dispatchEmpCount || 0)), _c("span", {
    class: _vm.params.state === undefined ? "personStyleSelect" : "personStyle"
  }, [_vm._v("人")])])]), _c("div")]), _c("div", {
    staticStyle: {
      padding: "22px 0",
      "margin-left": "10px",
      width: "60%",
      height: "100px",
      "border-radius": "4px",
      border: "1px solid #E8E8E8",
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      width: "33%",
      "padding-left": "24px",
      "border-right": "1px solid #E8E8E8",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.changeTab(1);
      }
    }
  }, [_c("div", {
    class: _vm.params.state === 1 ? "titleStyleSelect" : "titleStyle"
  }, [_vm._v("已完成")]), _c("div", {
    class: _vm.params.state === 1 ? "contentStyleSelect" : "contentStyle"
  }, [_vm._v(" " + _vm._s(_vm.actionDetail.completedCount || 0)), _c("span", {
    class: _vm.params.state === 1 ? "personStyleSelect" : "personStyle"
  }, [_vm._v("人")])])]), _c("div", {
    staticStyle: {
      width: "33%",
      "padding-left": "24px",
      "border-right": "1px solid #E8E8E8",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.changeTab(0);
      }
    }
  }, [_c("div", {
    class: _vm.params.state === 0 ? "titleStyleSelect" : "titleStyle"
  }, [_vm._v("未完成")]), _c("div", {
    class: _vm.params.state === 0 ? "contentStyleSelect" : "contentStyle"
  }, [_vm._v(" " + _vm._s(_vm.actionDetail.unfinishedCount || 0)), _c("span", {
    class: _vm.params.state === 0 ? "personStyleSelect" : "personStyle"
  }, [_vm._v("人")])])]), _c("div", {
    staticStyle: {
      width: "33%",
      "padding-left": "24px",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.changeTab(2);
      }
    }
  }, [_c("div", {
    class: _vm.params.state === 2 ? "titleStyleSelect" : "titleStyle"
  }, [_vm._v("异常")]), _c("div", {
    class: _vm.params.state === 2 ? "contentStyleSelect" : "contentStyle"
  }, [_vm._v(" " + _vm._s(_vm.actionDetail.abnormalCount || 0)), _c("span", {
    class: _vm.params.state === 2 ? "personStyleSelect" : "personStyle"
  }, [_vm._v("人")])])])])]), _c("a-row", {
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      loading: _vm.loading,
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.daochu
    }
  }, [_vm._v(" 导出 ")])], 1), _c("a-table", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      columns: _vm.columns,
      scroll: {
        x: 1200
      },
      "data-source": _vm.datalist,
      pagination: _vm.pagination,
      "row-key": "id",
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "state",
      fn: function fn(text) {
        return _c("div", {}, [text === 0 ? _c("span", {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
            width: "100%"
          }
        }, [_c("div", {
          staticStyle: {
            "border-radius": "50%",
            height: "4px",
            width: "4px",
            background: "#FFBB00"
          }
        }), _c("span", {
          staticStyle: {
            "margin-left": "5px"
          }
        }, [_vm._v("未完成")])]) : _vm._e(), text === 1 ? _c("span", {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
            width: "100%"
          }
        }, [_c("div", {
          staticStyle: {
            "border-radius": "50%",
            height: "4px",
            width: "4px",
            background: "#32CCAA"
          }
        }), _c("span", {
          staticStyle: {
            "margin-left": "5px"
          }
        }, [_vm._v("已完成")])]) : _vm._e(), text === 2 ? _c("span", {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
            width: "100%"
          }
        }, [_c("div", {
          staticStyle: {
            "border-radius": "50%",
            height: "4px",
            width: "4px",
            background: "#FF7085"
          }
        }), _c("span", {
          staticStyle: {
            "margin-left": "5px"
          }
        }, [_vm._v("异常")])]) : _vm._e()]);
      }
    }, {
      key: "feedbackDate",
      fn: function fn(text) {
        return _c("div", {}, [_vm._v(" " + _vm._s(text ? _vm.moment(text).format("YYYY-MM-DD HH:mm") : "--") + " ")]);
      }
    }])
  }), _c("feed-back", {
    attrs: {
      visible: _vm.feedVisible,
      options: _vm.feedOptions
    },
    on: {
      change: _vm.handleDetail
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };