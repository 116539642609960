import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";
export function selectSiteWithHr(params) {
  return request({
    url: "/insurance/api/v1/customerSites/selectSiteWithHr",
    method: "get",
    params: params
  });
}
export function bachSaveCustomer(data) {
  return request({
    url: "/insurance/api/v1/customerSites/bachSaveCustomer",
    method: "post",
    data: data
  });
}
export function selectSite(params) {
  return request({
    url: "/insurance/api/v1/customerSites",
    method: "get",
    params: params
  });
}
export function deleleSite(customerSiteId) {
  return request({
    url: "/insurance/api/v1/customerSites/".concat(customerSiteId),
    method: "delete"
  });
}
export function selectSiteWithInsurance(params) {
  return request({
    url: "/insurance/api/v1/companies/selectSiteWithInsurance",
    method: "get",
    params: params
  });
}
export function getPlan(companyId, customerId, params) {
  return request({
    url: "insurance/api/v1/companySchemes/filterCustomerScheme/".concat(companyId, "/").concat(customerId),
    method: "get",
    params: params
  });
}
export function selectCompanyBySiteId(siteId) {
  return request({
    url: "/insurance/api/v1/companies/selectCompanyById/".concat(siteId),
    method: "get"
  });
}
export function addPlanForSite(data) {
  return request({
    url: "/insurance/api/v1/customerConfigs",
    method: "post",
    data: data
  });
}
export function editPlanForSite(data) {
  return request({
    url: "/insurance/api/v1/customerConfigs",
    method: "put",
    data: data
  });
}
export function getPlanList(params) {
  return request({
    url: "/insurance/api/v1/customerConfigs/listAll",
    method: "get",
    params: params
  });
}
export function deletePlan(customerConfigId) {
  return request({
    url: "/insurance/api/v1/customerConfigs/".concat(customerConfigId),
    method: "delete"
  });
}