import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c("div", [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "employee-selector-wrapper2",
    staticStyle: {
      "margin-bottom": "30px"
    }
  }, [_c("div", [_c("div", {
    staticClass: "employee-selector-block"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "搜索人员"
    },
    on: {
      search: _vm.searchPeople,
      change: _vm.changePeople
    }
  })], 1), _c("div", {
    staticClass: "tree-item2",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", [_vm._v("* 选择成员后可查看该客户专员的客户数据")])]), _c("div", {
    staticClass: "employee-selector",
    staticStyle: {
      position: "relative"
    }
  }, _vm._l(_vm.treeData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "mg_t10"
    }, [_c("div", {
      staticClass: "tree-item",
      on: {
        click: function click($event) {
          return _vm.chose(item, _vm.treeData);
        }
      }
    }, [_c("span", [_c("img", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "margin-right": "10px"
      },
      attrs: {
        src: require("@/assets/icons/person1.png")
      }
    }), _c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.name) + " " + _vm._s(item.phone))])]), _c("span", {
      staticClass: "rname",
      staticStyle: {
        width: "210px"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " " + _vm._s(item.phone) + " ")])], 2)], 1), item.isSelected ? _c("xq-icon", {
      staticClass: "iconStyle",
      attrs: {
        type: "icon-wancheng2"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.chose(item, _vm.treeData);
        }
      }
    }) : _c("img", {
      staticStyle: {
        width: "18px",
        height: "18px"
      },
      attrs: {
        src: require("@/assets/icons/未选择.png")
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.chose(item, _vm.treeData);
        }
      }
    })], 1)]);
  }), 0)]), _c("div", {
    staticClass: "verticalBar"
  }), _c("div", {
    staticClass: "employee-selector-block2"
  }, [_c("div", {
    staticClass: "employee-selector-title"
  }, [_vm._v("已选择的人员 " + _vm._s(_vm.selectedListTemp.length) + " 人")]), _c("div", {
    staticClass: "employee-selector"
  }, _vm._l(_vm.selectedListTemp, function (item, i) {
    return _c("div", {
      key: i,
      staticClass: "employee-selector-item"
    }, [_c("div", [_c("img", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "margin-right": "10px"
      },
      attrs: {
        src: require("@/assets/icons/person1.png")
      }
    }), _c("a-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.name))])]), _c("span", {
      staticClass: "rname"
    }, [_vm._v(_vm._s(item.name))])], 2)], 1), _c("img", {
      staticStyle: {
        width: "20px",
        height: "20px"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(item, i);
        }
      }
    })]);
  }), 0)])])]), _c("div", {
    staticClass: "btnBox"
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("upDate", 1);
      }
    }
  }, [_vm._v(" 上一步 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.ok
    }
  }, [_vm._v(" 完成 ")])], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };