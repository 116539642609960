/*
 * @Description: 获取其地区信息
 * @Author: zhr
 */
import request from "@/utils/request";

// 获取省市信息
export function getProvince() {
  return request({
    url: "/common/api/v1/areas/all",
    method: "get"
  });
}
// 获取区信息
export function getArea(data) {
  return request({
    url: "/common/api/v1/areas/parent/" + data.parentId,
    method: "get"
  });
}
export function getAreaList() {
  return request({
    url: "/admin/api/v1/areas/getAllList",
    method: "get"
  });
}