import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "page"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-month-picker", {
    attrs: {
      size: _vm.size,
      allowClear: false,
      placeholder: "选择月份"
    },
    on: {
      change: _vm.monthChange
    },
    model: {
      value: _vm.defaultDate,
      callback: function callback($$v) {
        _vm.defaultDate = $$v;
      },
      expression: "defaultDate"
    }
  }), _c("div", [_c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showModal
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("创建工资表 ")], 1)], 1)], 1), _c("a-spin", {
    attrs: {
      spinning: _vm.isLoading
    }
  }, [_vm.summaryList ? _c("div", [_c("div", {
    staticClass: "fix-item-wrapper"
  }, [_c("div", {
    staticClass: "fix-item-one"
  }, [_c("div", [_c("div", {
    staticClass: "fix-item-one-top"
  }, [_c("div", {
    staticClass: "fix-item-name",
    staticStyle: {
      display: "flex",
      width: "230px"
    }
  }, [_vm._v(" " + _vm._s(this.summaryList.personalPeriod ? this.summaryList.personalPeriod : "--") + " 工资汇总 "), _vm.approStatus ? _c("img", {
    staticStyle: {
      width: "74px",
      height: "22px"
    },
    attrs: {
      src: _vm.shenheImg
    }
  }) : _vm._e()])]), _c("div", {
    staticClass: "fix-item-one-bottom"
  }, [_c("div", {
    staticClass: "fix-item-one-bottomBox"
  }, [_c("div", {
    staticClass: "projectName"
  }, [_vm._v("个税所属期:")]), _c("div", {
    staticClass: "contentName",
    staticStyle: {
      "margin-left": "24px"
    }
  }, [_vm._v(" " + _vm._s(this.summaryList.personalPeriod ? this.summaryList.personalPeriod : "--") + " ")])]), _c("div", {
    staticClass: "fix-item-one-bottomBox"
  }, [_c("div", {
    staticClass: "projectName"
  }, [_vm._v("计薪人数:")]), _c("div", {
    staticClass: "contentName",
    staticStyle: {
      "margin-left": "38px"
    }
  }, [_vm._v(" " + _vm._s(this.summaryList.empNumber ? this.summaryList.empNumber : 0) + "人 ")])])])]), _c("div", [_c("img", {
    staticStyle: {
      width: "58px",
      height: "30px"
    },
    attrs: {
      src: _vm.decoration
    }
  })])]), _c("div", {
    staticClass: "flew-box"
  }, [_c("div", {
    staticClass: "flew-box-content"
  }, [_c("div", {
    staticClass: "flew-box-contentBox"
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: _vm.should
    }
  }), _c("div", {
    staticClass: "flew-box-contentBox-right"
  }, [_c("div", {
    staticClass: "projectName"
  }, [_vm._v("应发工资")]), _c("div", {
    staticClass: "contentName"
  }, [_c("a-tooltip", [_c("template", {
    slot: "title"
  }, [_vm._v(" ¥" + _vm._s(this.summaryList.shouldWages ? this.summaryList.shouldWages : 0) + " ")]), _vm._v(" ¥" + _vm._s(this.summaryList.shouldWages ? this.summaryList.shouldWages : 0) + " ")], 2)], 1)])]), _c("div", {
    staticClass: "flew-box-contentBox"
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: _vm.realHair
    }
  }), _c("div", {
    staticClass: "flew-box-contentBox-right"
  }, [_c("div", {
    staticClass: "projectName"
  }, [_vm._v("实发工资合计")]), _c("div", {
    staticClass: "contentName"
  }, [_c("a-tooltip", [_c("template", {
    slot: "title"
  }, [_vm._v(" ¥" + _vm._s(this.summaryList.paidWages ? this.summaryList.paidWages : 0) + " ")]), _vm._v(" ¥" + _vm._s(this.summaryList.paidWages ? this.summaryList.paidWages : 0) + " ")], 2)], 1)])]), _c("div", {
    staticClass: "flew-box-contentBox"
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: _vm.IncomeTax
    }
  }), _c("div", {
    staticClass: "flew-box-contentBox-right"
  }, [_c("div", {
    staticClass: "projectName"
  }, [_vm._v("人力成本")]), _c("div", {
    staticClass: "contentName"
  }, [_c("a-tooltip", [_c("template", {
    slot: "title"
  }, [_vm._v(" ¥" + _vm._s(this.summaryList.businessCost ? this.summaryList.businessCost : 0) + " ")]), _vm._v(" ¥" + _vm._s(this.summaryList.businessCost ? this.summaryList.businessCost : 0) + " ")], 2)], 1)])]), _c("div", {
    staticClass: "flew-box-contentBox"
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: _vm.cost
    }
  }), _c("div", {
    staticClass: "flew-box-contentBox-right"
  }, [_c("div", {
    staticClass: "projectName"
  }, [_vm._v("总缴税")]), _c("div", {
    staticClass: "contentName"
  }, [_c("a-tooltip", [_c("template", {
    slot: "title"
  }, [_vm._v(" ¥" + _vm._s(this.summaryList.tax ? this.summaryList.tax : 0) + " ")]), _vm._v(" ¥" + _vm._s(this.summaryList.tax ? this.summaryList.tax : 0) + " ")], 2)], 1)])]), _c("div", {
    staticClass: "goDetail"
  }, [_c("img", {
    staticStyle: {
      width: "24px",
      height: "24px",
      cursor: "pointer"
    },
    attrs: {
      src: _vm.goDetail
    },
    on: {
      click: _vm.showAllDetails
    }
  })])])])])]) : _c("div", [_c("a-empty", {
    staticStyle: {
      "margin-top": "100px"
    }
  })], 1), _vm.salaryTemplateList && _vm.salaryTemplateList.length > 0 ? _c("div", {
    staticClass: "formDetail"
  }, [_vm._v("工资表详情")]) : _vm._e(), _vm._l(_vm.salaryTemplateList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "fix-item-childWrapper"
    }, [_c("div", {
      staticClass: "fix-item-one"
    }, [_c("img", {
      staticStyle: {
        width: "32px",
        height: "32px",
        "box-shadow": "0 8px 8px -4px rgba(115, 115, 243, 0.6)"
      },
      attrs: {
        src: _vm.marshalling
      }
    }), _c("div", {
      staticClass: "fix-item-name",
      staticStyle: {
        "margin-left": "12px",
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.showDetails(item);
        }
      }
    }, [_vm._v(_vm._s(item.name))])]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", {
      staticStyle: {
        "font-size": "12px",
        "font-weight": "400",
        color: "#a6a8b4"
      }
    }, [_vm._v("状态")]), item.isArchive === true ? _c("p", {
      staticClass: "fix-item-tip"
    }, [_vm._v("已归档")]) : _c("p", {
      staticClass: "fix-item-tip2"
    }, [_vm._v("未归档")])]), _c("div", {
      staticClass: "fix-item-three"
    }, [_c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v("个税所属期")]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.yearly) + "年" + _vm._s(item.monthly) + "月")])]), _c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v("计薪人数")]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.empNumber ? item.empNumber : 0) + "人")])]), _c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v("应发工资")]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v("¥" + _vm._s(item.shouldWages ? item.shouldWages : 0))])]), _c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v("实发工资")]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v("¥" + _vm._s(item.paidWages ? item.paidWages : 0))])])]), _c("div", {
      staticClass: "fix-item-four"
    }, [_c("div", [_c("a-button", {
      attrs: {
        type: "link",
        block: ""
      },
      on: {
        click: function click($event) {
          return _vm.showDetails(item);
        }
      }
    }, [_vm._v("查看详情")])], 1), _c("div", [_c("a-popconfirm", {
      attrs: {
        title: "确定删除?"
      },
      on: {
        confirm: function confirm($event) {
          return _vm.deleteTemplate(item);
        }
      }
    }, [_c("a-icon", {
      staticStyle: {
        color: "red"
      },
      attrs: {
        slot: "icon",
        type: "question-circle-o"
      },
      slot: "icon"
    }), !_vm.approStatus && item.isArchive === false ? _c("a-button", {
      attrs: {
        type: "link",
        block: ""
      }
    }, [_vm._v("删除")]) : _vm._e()], 1)], 1)])]);
  })], 2)], 1), _c("a-modal", {
    attrs: {
      title: "创建工资表",
      width: "600px",
      okText: "下一步"
    },
    on: {
      ok: _vm.handleOk
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-alert", {
    attrs: {
      message: "创建下月工资表时，本月工资表自动归档且不能再创建本月工资表",
      banner: "",
      closable: ""
    }
  }), _c("a-form-model", {
    ref: "ruleForm",
    staticStyle: {
      "margin-left": "30px",
      "margin-top": "20px"
    },
    attrs: {
      prop: "form",
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "template",
    attrs: {
      prop: "template",
      label: "工资表模板",
      rules: {
        required: true,
        message: "工资表模板必选"
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "300px",
      "margin-left": "10px"
    },
    attrs: {
      placeholder: "请选择",
      mode: "multiple"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.form.template,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "template", $$v);
      },
      expression: "form.template"
    }
  }, _vm._l(_vm.templatesList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-form-model-item", {
    ref: "month",
    attrs: {
      prop: "month",
      label: "计薪月份",
      rules: {
        required: true,
        message: "计薪月份必选"
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "300px",
      "margin-left": "10px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.handleMonthChange
    },
    model: {
      value: _vm.form.month,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "month", $$v);
      },
      expression: "form.month"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1",
      disabled: _vm.approStatus
    }
  }, [_vm._v(" " + _vm._s(_vm.creationTimeYear) + "年" + _vm._s(_vm.creationTimeMonth) + "月 "), _vm.approStatus ? _c("span", [_vm._v("（账单已生成）")]) : _vm._e()]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.creationTimeMonth + 1 > 12 ? _vm.creationTimeYear + 1 : _vm.creationTimeYear) + "年" + _vm._s(_vm.creationTimeMonth + 1 > 12 ? _vm.creationTimeMonth - 11 : _vm.creationTimeMonth + 1) + "月 ")])], 1)], 1)], 1), _c("a-layout")], 1), _c("a-modal", {
    attrs: {
      title: "确定计薪人数",
      width: "800px",
      okText: "创建",
      cancelText: "上一步"
    },
    on: {
      ok: _vm.handleNextOk
    },
    model: {
      value: _vm.nextVisible,
      callback: function callback($$v) {
        _vm.nextVisible = $$v;
      },
      expression: "nextVisible"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "姓名/手机号"
    },
    on: {
      search: _vm.handleNameChange
    },
    model: {
      value: _vm.nameOrPhoneNumber,
      callback: function callback($$v) {
        _vm.nameOrPhoneNumber = $$v;
      },
      expression: "nameOrPhoneNumber"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.handleGroupChange
    }
  }, _vm._l(_vm.groupList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-table", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      loading: _vm.nextLoading,
      columns: _vm.columns,
      "row-key": "key",
      pagination: _vm.pagination,
      "data-source": _vm.wageList
    },
    on: {
      change: _vm.handleTableChange
    }
  }), _c("a-layout")], 1), _c("a-modal", {
    attrs: {
      title: "确认计薪人员",
      width: "600px",
      okText: "下一步",
      cancelText: "上一步"
    },
    on: {
      ok: _vm.handleSecondOk
    },
    model: {
      value: _vm.secondVisible,
      callback: function callback($$v) {
        _vm.secondVisible = $$v;
      },
      expression: "secondVisible"
    }
  }, [_c("div", {
    staticClass: "emp-title"
  }, [_c("span", [_vm._v("检测到以下员工本月已创建月工资表，是否选择更换工资表？")]), _vm.selectedRowKeys.length > 0 ? _c("a-button", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.replace
    }
  }, [_vm._v("更换")]) : _vm._e()], 1), _c("a-table", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      columns: _vm.empColumns,
      "data-source": _vm.createdList,
      "row-key": "key",
      scroll: {
        y: 300
      },
      pagination: false,
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    }
  }), _c("a-layout")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };