import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content page-miH page-bgBack"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("selectCompanyGroup", {
    attrs: {
      siteIds: _vm.siteId,
      siteName: _vm.siteName,
      selectTreeId: _vm.selectTreeId
    },
    on: {
      "update:siteIds": function updateSiteIds($event) {
        _vm.siteId = $event;
      },
      "update:site-ids": function updateSiteIds($event) {
        _vm.siteId = $event;
      },
      "update:siteName": function updateSiteName($event) {
        _vm.siteName = $event;
      },
      "update:site-name": function updateSiteName($event) {
        _vm.siteName = $event;
      },
      "update:selectTreeId": function updateSelectTreeId($event) {
        _vm.selectTreeId = $event;
      },
      "update:select-tree-id": function updateSelectTreeId($event) {
        _vm.selectTreeId = $event;
      }
    }
  }), _c("div", [_c("a-button", {
    attrs: {
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: _vm.barchSetUser
    }
  }, [_vm._v("批量设置负责人")]), _c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.upLoad
    }
  }, [_vm._v("批量导入")]), _c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      type: "primary",
      disabled: !_vm.dataList.length
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("导出")])], 1)], 1), _c("div", {
    staticClass: "head"
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("a-month-picker", {
    attrs: {
      allowClear: false
    },
    on: {
      change: _vm.monthChange
    },
    model: {
      value: _vm.nowDate,
      callback: function callback($$v) {
        _vm.nowDate = $$v;
      },
      expression: "nowDate"
    }
  }), _c("a-input-search", {
    staticStyle: {
      width: "200px",
      "margin-left": "20px"
    },
    attrs: {
      placeholder: "姓名/手机号/身份证号"
    },
    on: {
      search: _vm.onSearch
    }
  }), _c("a-popover", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      trigger: "click",
      placement: "bottom"
    },
    model: {
      value: _vm.popoverVisible,
      callback: function callback($$v) {
        _vm.popoverVisible = $$v;
      },
      expression: "popoverVisible"
    }
  }, [_c("div", {
    staticStyle: {
      width: "725px",
      height: "142px",
      "padding-bottom": "12px"
    },
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", {
    staticStyle: {
      padding: "24px 0",
      display: "flex"
    }
  }, [_c("div", [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#636363"
    }
  }, [_vm._v("负责人姓名")]), _c("a-input", {
    staticStyle: {
      width: "200px",
      "margin-top": "12px"
    },
    attrs: {
      placeholder: "请输入负责人姓名"
    },
    model: {
      value: _vm.empResponsibleName,
      callback: function callback($$v) {
        _vm.empResponsibleName = $$v;
      },
      expression: "empResponsibleName"
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-left": "24px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#636363"
    }
  }, [_vm._v("回款状态")]), _c("a-select", {
    staticStyle: {
      width: "200px",
      "margin-top": "12px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择回款状态"
    },
    model: {
      value: _vm.planStatus,
      callback: function callback($$v) {
        _vm.planStatus = $$v;
      },
      expression: "planStatus"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("未回款")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("部分回款")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("已回款")])], 1)], 1), _c("div", {
    staticStyle: {
      "margin-left": "24px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#636363"
    }
  }, [_vm._v("未回款金额筛选")]), _c("a-input-number", {
    staticStyle: {
      width: "100px",
      "margin-top": "12px"
    },
    attrs: {
      placeholder: "请输入",
      min: 0
    },
    model: {
      value: _vm.surplusAmountMin,
      callback: function callback($$v) {
        _vm.surplusAmountMin = $$v;
      },
      expression: "surplusAmountMin"
    }
  }), _vm._v(" - "), _c("a-input-number", {
    staticStyle: {
      width: "100px",
      "margin-top": "12px"
    },
    attrs: {
      placeholder: "请输入",
      min: _vm.surplusAmountMin ? _vm.surplusAmountMin : 0
    },
    model: {
      value: _vm.surplusAmountMax,
      callback: function callback($$v) {
        _vm.surplusAmountMax = $$v;
      },
      expression: "surplusAmountMax"
    }
  })], 1)]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end"
    }
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        _vm.popoverVisible = false;
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.getRecordInfosList
    }
  }, [_vm._v("查询")])], 1)]), _c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "filter"
    }
  })], 1)], 1)], 1), _c("div", [_c("a-button", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    },
    attrs: {
      type: "link",
      size: "small"
    },
    on: {
      click: _vm.reload
    }
  }, [_vm._v("刷新"), _c("a-icon", {
    attrs: {
      type: "reload"
    }
  })], 1), _c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }), _c("a-popover", {
    attrs: {
      title: "",
      trigger: "click",
      placement: "bottom"
    },
    on: {
      visibleChange: _vm.handleHeadChange
    },
    model: {
      value: _vm.headVisible,
      callback: function callback($$v) {
        _vm.headVisible = $$v;
      },
      expression: "headVisible"
    }
  }, [_c("div", {
    staticStyle: {
      width: "600px",
      height: "320px"
    },
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", [_c("div", [_vm._v("已选择表头")]), _c("div", {
    staticClass: "overview-tag-wrapper",
    staticStyle: {
      height: "100px",
      display: "flex",
      "overflow-y": "auto",
      "flex-direction": "column"
    }
  }, [_c("draggable", {
    attrs: {
      filter: ".forbid",
      animation: "3000",
      move: _vm.onMove
    },
    on: {
      start: _vm.onStart,
      end: _vm.onEnd
    },
    model: {
      value: _vm.tempSelectedHead,
      callback: function callback($$v) {
        _vm.tempSelectedHead = $$v;
      },
      expression: "tempSelectedHead"
    }
  }, [_c("transition-group", _vm._l(_vm.tempSelectedHead, function (item, index) {
    return _c("a-tag", {
      key: index,
      class: item.title !== "姓名" ? "overview-select-tag" : "overview-select-tag-name forbid",
      attrs: {
        closable: item.title !== "姓名",
        color: item.title !== "姓名" ? "#fff" : "#E8E8E8"
      },
      on: {
        close: function close($event) {
          return _vm.deleteSelectedHead($event, index);
        }
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)], 1)], 1)]), _c("div", [_c("div", [_vm._v("可选择表头")]), _c("div", {
    staticStyle: {
      height: "130px",
      display: "flex",
      "overflow-y": "auto",
      "flex-direction": "column"
    }
  }, [_c("div", _vm._l(_vm.tempNoSelectedHead, function (item, index) {
    return _c("a-tag", {
      key: index,
      staticClass: "overview-no-select-tag",
      on: {
        click: function click($event) {
          return _vm.addSelectedHead(index);
        }
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)])]), _c("a-row", {
    staticStyle: {
      position: "absolute",
      bottom: "20px",
      right: "20px"
    },
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: _vm.handleHeadCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleHeadSearch
    }
  }, [_vm._v("查询")])], 1)], 1), _c("a-button", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    },
    attrs: {
      type: "link",
      size: "small"
    }
  }, [_vm._v("选择表头"), _c("a-icon", {
    attrs: {
      type: "menu"
    }
  })], 1)], 1)], 1)]), _c("div", {
    staticClass: "body"
  }, [_c("a-table", {
    staticClass: "xq-draggable-table",
    attrs: {
      loading: _vm.loading,
      columns: _vm.columns,
      components: _vm.components,
      "data-source": _vm.dataList,
      pagination: _vm.pagination,
      customHeaderRow: _vm.headerRow,
      scroll: {
        x: 800
      },
      "row-key": "id",
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    }
  }), _c("a-modal", {
    attrs: {
      title: "新增记录"
    },
    on: {
      ok: _vm.handelActionOk
    },
    model: {
      value: _vm.actionVisible,
      callback: function callback($$v) {
        _vm.actionVisible = $$v;
      },
      expression: "actionVisible"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.actionForm,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "name",
    attrs: {
      label: "姓名",
      prop: "name"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: "",
      placeholder: "请输入"
    },
    model: {
      value: _vm.actionForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.actionForm, "name", $$v);
      },
      expression: "actionForm.name"
    }
  })], 1), _c("a-form-model-item", {
    ref: "month",
    attrs: {
      label: "缴纳月份",
      prop: "month"
    }
  }, [_c("a-month-picker", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.actionForm.nowDate,
      callback: function callback($$v) {
        _vm.$set(_vm.actionForm, "nowDate", $$v);
      },
      expression: "actionForm.nowDate"
    }
  })], 1), _c("a-form-model-item", {
    ref: "totalAmount",
    attrs: {
      label: "本月应收",
      prop: "totalAmount"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: "",
      placeholder: "请输入"
    },
    model: {
      value: _vm.actionForm.totalAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.actionForm, "totalAmount", $$v);
      },
      expression: "actionForm.totalAmount"
    }
  })], 1), _c("a-form-model-item", {
    ref: "wayStatus",
    attrs: {
      label: "回款途径",
      prop: "wayStatus",
      rules: {
        required: true,
        message: "请选择"
      }
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择回款途径"
    },
    model: {
      value: _vm.actionForm.wayStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.actionForm, "wayStatus", $$v);
      },
      expression: "actionForm.wayStatus"
    }
  }, _vm._l(_vm.wayStatusList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.key
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-form-model-item", {
    ref: "realityAmount",
    attrs: {
      label: _vm.actionForm.wayStatus === "5" ? "扣款金额" : "本月实收",
      prop: "realityAmount",
      rules: {
        required: true,
        message: "请输入"
      }
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      placeholder: "请输入"
    },
    model: {
      value: _vm.actionForm.realityAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.actionForm, "realityAmount", $$v);
      },
      expression: "actionForm.realityAmount"
    }
  })], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "回款记录",
      width: 800
    },
    on: {
      ok: _vm.handelRecordOk
    },
    model: {
      value: _vm.recordVisible,
      callback: function callback($$v) {
        _vm.recordVisible = $$v;
      },
      expression: "recordVisible"
    }
  }, [_c("div", {
    staticClass: "recordTitle"
  }, [_c("div", [_vm._v("姓名：" + _vm._s(_vm.empName))]), _c("div", [_vm._v("余额：" + _vm._s(_vm.remainAmount))])]), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      size: "middle",
      pagination: _vm.pagination2,
      columns: _vm.recordColumns,
      "data-source": _vm.dataList2
    },
    on: {
      change: _vm.handleTableChange2
    },
    scopedSlots: _vm._u([{
      key: "month",
      fn: function fn(text, record) {
        return [_vm._v(_vm._s(record.year + "年" + text + "月"))];
      }
    }, {
      key: "wayStatus",
      fn: function fn(text) {
        return [_vm._v(_vm._s(["银行", "微信", "支付宝", "其他", "余额扣款"][text - 1]))];
      }
    }, {
      key: "gmtCreate",
      fn: function fn(text) {
        return [_vm._v(_vm._s(_vm.moment(text).format("YYYY-MM-DD HH:mm:ss")))];
      }
    }])
  })], 1)])], 1), _c("a-modal", {
    attrs: {
      title: _vm.isBatch ? "批量设置负责人" : "设置负责人",
      visible: _vm.setUserVisible
    },
    on: {
      ok: _vm.handleUserOk,
      cancel: function cancel($event) {
        _vm.setUserVisible = false;
      }
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "margin-left": "36px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500"
    }
  }, [_vm._v("计划负责人")]), _c("a-select", {
    staticStyle: {
      width: "300px",
      "margin-left": "12px"
    },
    attrs: {
      allowClear: "",
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      placeholder: "请选择管理员"
    },
    on: {
      change: _vm.userChange
    },
    model: {
      value: _vm.selectedRecord.empResponsibleId,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedRecord, "empResponsibleId", $$v);
      },
      expression: "selectedRecord.empResponsibleId"
    }
  }, _vm._l(_vm.dataSource, function (i) {
    return _c("a-select-option", {
      key: i.id
    }, [_vm._v(" " + _vm._s(i.name) + " ")]);
  }), 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };