import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { mapState } from "vuex";
export default {
  name: "FeedBack",
  props: ["options", "visible"],
  computed: _objectSpread(_objectSpread({}, mapState(["admin"])), {}, {
    footer: function footer() {
      return this.admin.activeCompany && this.admin.activeCompany.includes("总");
    }
  }),
  methods: {
    onOk: function onOk() {
      this.$emit("change", {
        visible: false
      });
    },
    cancel: function cancel() {
      this.$emit("change", {
        visible: false
      });
    }
  }
};