var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "mb24",
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c("a-space", [_c("a-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "跟进人"
    },
    on: {
      change: _vm.onSelectChange
    },
    model: {
      value: _vm.followUser,
      callback: function callback($$v) {
        _vm.followUser = $$v;
      },
      expression: "followUser"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      options: _vm.followTypeList,
      allowClear: "",
      placeholder: "跟进方式"
    },
    on: {
      change: _vm.onSelectChange
    },
    model: {
      value: _vm.followType,
      callback: function callback($$v) {
        _vm.followType = $$v;
      },
      expression: "followType"
    }
  })], 1), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addItem
    }
  }, [_vm._v("添加")])], 1), _c("a-table", {
    attrs: {
      dataSource: _vm.dataSource,
      columns: _vm.followColumns,
      pagination: _vm.pagination,
      rowKey: "id"
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return [_c("a-space", [_c("a-button", {
          attrs: {
            type: "link",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.editItem(record);
            }
          }
        }, [_vm._v("编辑")]), _c("a-button", {
          attrs: {
            type: "link",
            size: "small"
          },
          on: {
            click: function click($event) {
              return _vm.delItem(record.id);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  }), _c("a-modal", {
    attrs: {
      title: "添加跟进记录"
    },
    on: {
      ok: _vm.onAddOk
    },
    model: {
      value: _vm.addVisible,
      callback: function callback($$v) {
        _vm.addVisible = $$v;
      },
      expression: "addVisible"
    }
  }, [_c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进人",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.adminUsers,
      showSearch: "",
      filterOption: _vm.filterOption,
      placeholder: "请选择跟进人"
    },
    model: {
      value: _vm.recordCur,
      callback: function callback($$v) {
        _vm.recordCur = $$v;
      },
      expression: "recordCur"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进方式",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.followTypeList,
      placeholder: "请选择跟进方式"
    },
    model: {
      value: _vm.recordMed,
      callback: function callback($$v) {
        _vm.recordMed = $$v;
      },
      expression: "recordMed"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进内容",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请填写跟进内容"
    },
    model: {
      value: _vm.recordCon,
      callback: function callback($$v) {
        _vm.recordCon = $$v;
      },
      expression: "recordCon"
    }
  })], 1)], 1), _c("a-modal", {
    attrs: {
      title: "编辑跟进记录"
    },
    on: {
      ok: _vm.editOk
    },
    model: {
      value: _vm.editVisible,
      callback: function callback($$v) {
        _vm.editVisible = $$v;
      },
      expression: "editVisible"
    }
  }, [_c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进人",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.adminUsersFormatted,
      showSearch: "",
      filterOption: _vm.filterOption,
      placeholder: "请选择跟进人"
    },
    model: {
      value: _vm.recordCur,
      callback: function callback($$v) {
        _vm.recordCur = $$v;
      },
      expression: "recordCur"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进方式",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.followTypeList,
      placeholder: "请选择跟进方式"
    },
    model: {
      value: _vm.recordMed,
      callback: function callback($$v) {
        _vm.recordMed = $$v;
      },
      expression: "recordMed"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进内容",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请填写跟进内容"
    },
    model: {
      value: _vm.recordCon,
      callback: function callback($$v) {
        _vm.recordCon = $$v;
      },
      expression: "recordCon"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };