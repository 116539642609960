var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-center"
  }, [_c("div", {
    staticClass: "flex-shrink-0 pr16 border-right"
  }, [_c("div", {
    staticClass: "flex-center"
  }, [_vm.options.unfinishedCount === 0 ? _c("img", {
    staticStyle: {
      width: "16px",
      height: "16px"
    },
    attrs: {
      src: require("@/assets/img/shebaopaidan/blueright.png")
    }
  }) : _c("img", {
    staticStyle: {
      width: "16px",
      height: "16px"
    },
    attrs: {
      src: require("@/assets/img/shebaopaidan/rectangle.png")
    }
  }), _c("a-tooltip", {
    attrs: {
      placement: "top",
      title: _vm.options.missionName
    }
  }, [_c("div", {
    staticClass: "fs18 fw600 mh8 ellipsis pointer",
    staticStyle: {
      width: "240px"
    }
  }, [_vm._v(_vm._s(_vm.getName(_vm.options.missionName)))])]), _vm.options.currentUserReceive ? _c("div", {
    staticClass: "ph4 br4 fs12",
    staticStyle: {
      border: "1px solid #32ccaa",
      color: "#32ccaa"
    }
  }, [_vm._v("已接单")]) : _c("div", {
    staticClass: "ph4 br4 fs12",
    staticStyle: {
      border: "1px solid rgba(255, 165, 38, 0.6)",
      color: "#ffbb00"
    }
  }, [_vm._v("未接单")])], 1), _c("div", {
    staticClass: "now-left-time"
  }, [_vm._v(" " + _vm._s(_vm.moment(_vm.options.gmtCreate).format("YYYY-MM-DD HH:mm")) + " ")])]), _c("div", {
    staticClass: "flex-1 flex-center",
    staticStyle: {
      color: "#9c9c9c"
    }
  }, [_c("div", {
    staticClass: "ph16 border-right"
  }, [_c("div", [_vm._v("派单人数")]), _c("div", {
    staticClass: "fs18 fw500 mt4 tc-l1"
  }, [_vm._v(_vm._s(_vm.options.dispatchEmpCount) + "人")])]), _c("div", {
    staticClass: "ph16"
  }, [_c("div", [_vm._v("已完成")]), _c("div", {
    staticClass: "fs18 fw500 mt4 tc-l1"
  }, [_vm._v(_vm._s(_vm.options.completedCount) + "人")])]), _c("div", {
    staticClass: "ph16"
  }, [_c("div", [_vm._v("未完成")]), _c("div", {
    staticClass: "fs18 fw500 mt4 tc-l1"
  }, [_vm._v(_vm._s(_vm.options.unfinishedCount) + "人")])]), _c("div", {
    staticClass: "ph16 border-right"
  }, [_c("div", [_vm._v("异常")]), _c("div", {
    staticClass: "fs18 fw500 mt4 tc-l1"
  }, [_vm._v(_vm._s(_vm.options.abnormalCount) + "人")])])]), _c("div", {
    staticStyle: {}
  }, [_vm.options.fileType === 2 ? _c("span", {
    staticClass: "tc-theme pointer",
    on: {
      click: function click($event) {
        _vm.modalVisible = true;
      }
    }
  }, [_vm._v("查看图片")]) : _c("span", {
    staticClass: "tc-theme pointer",
    on: {
      click: function click($event) {
        return _vm.handleEvent(_vm.options);
      }
    }
  }, [_vm._v("查看详情")])]), _c("a-modal", {
    attrs: {
      title: "查看图片",
      width: "550px"
    },
    model: {
      value: _vm.modalVisible,
      callback: function callback($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c("div", {
    staticClass: "ta-right",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("a-space", {
    attrs: {
      size: 16
    }
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        _vm.modalVisible = false;
      }
    }
  }, [_vm._v("取消")]), !_vm.options.currentUserReceive ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.doConfirm
    }
  }, [_vm._v("确认接单")]) : _vm._e()], 1)], 1), _c("div", {
    staticClass: "flex-center pointer",
    staticStyle: {
      "flex-wrap": "wrap"
    }
  }, _vm._l(_vm.urls, function (url, idx) {
    return _c("div", {
      key: url,
      staticClass: "ml12 mb12",
      style: {
        width: "150px",
        height: "90px",
        "background-image": "url('".concat(url, "?x-oss-process=image/resize,w_150')"),
        "background-size": "contain",
        "background-repeat": "no-repeat",
        "background-position": "center"
      },
      on: {
        click: function click($event) {
          return _vm.previewImg(idx);
        }
      }
    });
  }), 0)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };