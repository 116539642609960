import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "revset"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goback
    }
  }, [_vm._v(" 参保记录 ")]), _c("div", {
    staticClass: "display-flex mt20",
    staticStyle: {
      width: "100%"
    }
  }, [_c("div", {
    staticClass: "left_region ph20 calc_h100"
  }, [_c("div", [_c("div", {
    staticClass: "display-flex flex_dir jc-sb ai-center",
    staticStyle: {
      "font-size": "18px"
    }
  }, [_c("a-avatar", {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      size: 64,
      icon: "user",
      src: _vm.PersonalData.empPhoto
    }
  }), _vm._v(" " + _vm._s(_vm.PersonalData.name || "-") + " ")], 1), _c("div", {
    staticClass: "expend-box-detailed"
  }, [_vm._v(" 社保" + _vm._s(_vm.getPostTypeNew(_vm.PersonalData.empSocStatus)) + " / 医保" + _vm._s(_vm.getPostTypeNew(_vm.PersonalData.empMeStatus)) + " / 公积金" + _vm._s(_vm.getPostTypeNew(_vm.PersonalData.empPfStatus)) + " ")]), _c("div", {
    staticClass: "expend-box-detailed"
  }, [_vm._v(_vm._s(_vm._f("filtersOptions")(_vm.PersonalData.postType, _vm.postTypeLst)))]), _c("a-divider", {
    attrs: {
      dashed: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "display-flex lh26"
  }, [_c("div", {
    staticClass: "w60_mr10_left"
  }, [_vm._v("身份证号")]), _c("div", [_vm._v(_vm._s(_vm.PersonalData.idCard || "-"))])]), _c("div", {
    staticClass: "display-flex lh26"
  }, [_c("div", {
    staticClass: "w60_mr10_left"
  }, [_vm._v("手机号")]), _c("div", [_vm._v(_vm._s(_vm.PersonalData.phone || "-"))])]), _c("div", {
    staticClass: "display-flex lh26"
  }, [_c("div", {
    staticClass: "w60_mr10_left"
  }, [_vm._v("社保编号")]), _c("div", [_vm._v(_vm._s(_vm.PersonalData.socCard || "-"))])]), _c("div", {
    staticClass: "display-flex lh26"
  }, [_c("div", {
    staticClass: "w60_mr10_left"
  }, [_vm._v("公积金编号")]), _c("div", [_vm._v(_vm._s(_vm.PersonalData.pfCard || "-"))])]), _c("div", {
    staticClass: "display-flex lh26"
  }, [_c("div", {
    staticClass: "w60_mr10_left"
  }, [_vm._v("参保方案")]), _c("div", [_vm._v(_vm._s(_vm.PersonalData.socProgName || "-"))])]), _c("div", {
    staticClass: "display-flex lh26"
  }, [_c("div", {
    staticClass: "w60_mr10_left"
  }, [_vm._v("参保城市")]), _c("div", [_vm._v(_vm._s(_vm.PersonalData.socCityName || "-"))])]), _c("div", {
    staticClass: "display-flex lh26"
  }, [_c("div", {
    staticClass: "w60_mr10_left"
  }, [_vm._v("参保时间")]), _c("div", [_vm._v(_vm._s(_vm.formatDate(_vm.PersonalData.socDate) || "-"))])])]), _c("a-divider"), _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.material
    }
  }, [_vm._v(" 个人档案 ")])], 1)], 1)]), _c("div", {
    staticClass: "ph20",
    staticStyle: {
      width: "100%"
    }
  }, [_c("div", {
    staticClass: "flex-center-between top_warp_t wp100"
  }, _vm._l(_vm.topData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "width20 ta-center"
    }, [_c("div", {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      class: item.title === "合计" ? "isColor" : "",
      staticStyle: {
        "font-size": "18px",
        "font-family": "DINAlternate-Bold, DINAlternate",
        "font-weight": "bold"
      }
    }, [_vm._v(" " + _vm._s(item.num) + " ")])]);
  }), 0), _c("div", {
    staticClass: "mv16"
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.selectedRowKeys.length > 0 ? false : true
    },
    on: {
      click: _vm.onExport
    }
  }, [_vm._v(" 导出 ")])], 1), _c("div", [_c("a-table", {
    attrs: {
      rowKey: "empSocInfoId",
      loading: _vm.loading,
      "data-source": _vm.tableData,
      scroll: {
        x: 500,
        y: 800
      },
      "row-selection": {
        onChange: function onChange(selectedRowKeys, selectedRows) {
          return _vm.tableChange(selectedRowKeys, selectedRows);
        }
      },
      pagination: false
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "参保时间",
      align: "center",
      width: "100px",
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.year) + "-" + _vm._s(scope.month) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "缴费类型",
      align: "center",
      width: "100px",
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("a-tooltip", [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(_vm._f("filtersOptions")(scope.payCostType, _vm.payTypeOptions)) + " ")]), _vm._v(" " + _vm._s(_vm._f("filtersOptions")(scope.payCostType, _vm.payTypeOptions)) + " ")], 2)];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "参保方案",
      align: "center",
      width: "100px",
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("a-tooltip", [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(scope.socProgName || "-") + " ")]), _vm._v(" " + _vm._s(scope.socProgName || "-") + " ")], 2)];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "参保城市",
      align: "center",
      width: "100px",
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("a-tooltip", [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(scope.socCityName || "-") + " ")]), _vm._v(" " + _vm._s(scope.socCityName || "-") + " ")], 2)];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "单位社保",
      align: "center",
      width: "100px",
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.companySocAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "个人社保",
      align: "center",
      width: "100px",
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.personalSocAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "单位公积金",
      align: "center",
      width: "135px",
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.companyPfAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "个人公积金",
      align: "center",
      width: "135px",
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.personalPfAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "社保总计",
      align: "center",
      width: "100px",
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.socAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "公积金总计",
      align: "center",
      width: "135px",
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.pfAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "",
      width: "80px",
      fixed: "right",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("a-popover", {
          attrs: {
            placement: "bottomRight"
          }
        }, [_c("template", {
          slot: "content"
        }, [_c("a-table", {
          attrs: {
            size: "small",
            "data-source": scope.empSocItemVOList,
            pagination: false,
            rowKey: "id"
          }
        }, [_c("a-table-column", {
          attrs: {
            title: "缴纳项目",
            align: "center",
            ellipsis: ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scopes) {
              return [_vm._v(" " + _vm._s(scopes.itemName || "-") + " ")];
            }
          }], null, true)
        }), _c("a-table-column", {
          attrs: {
            title: "个人缴纳基数",
            align: "center",
            ellipsis: ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scopes) {
              return [_vm._v(" " + _vm._s(scopes.cardinalityDefault) + " ")];
            }
          }], null, true)
        }), !scope.onlySocAmount ? _c("a-table-column", {
          attrs: {
            title: "个人比例",
            align: "center",
            ellipsis: ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scopes) {
              return [_vm._v(" " + _vm._s(scopes.persentPersonal) + " ")];
            }
          }], null, true)
        }) : _vm._e(), !scope.onlySocAmount ? _c("a-table-column", {
          attrs: {
            title: "公司比例",
            align: "center",
            ellipsis: ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scopes) {
              return [_vm._v(" " + _vm._s(scopes.persentCompany) + " ")];
            }
          }], null, true)
        }) : _vm._e(), _c("a-table-column", {
          attrs: {
            title: "个人缴费",
            align: "center",
            ellipsis: ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scopes) {
              return [_vm._v(" " + _vm._s(scopes.amountPersonal) + " ")];
            }
          }], null, true)
        }), _c("a-table-column", {
          attrs: {
            title: "公司缴费",
            align: "center",
            ellipsis: ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scopes) {
              return [_vm._v(" " + _vm._s(scopes.amountCompany) + " ")];
            }
          }], null, true)
        })], 1)], 1), _c("a-icon", {
          staticClass: "pointer",
          attrs: {
            type: "up-circle"
          }
        })], 2)];
      }
    }])
  })], 1), _c("div", {
    staticClass: "ta-right mt16"
  }, [_c("a-pagination", {
    attrs: {
      current: _vm.queryParams.pageNo,
      "show-quick-jumper": "",
      "show-size-changer": "",
      "default-current": 1,
      pageSizeOptions: ["10", "20", "50", "100"],
      total: _vm.total,
      "show-total": function showTotal(total) {
        return "\u5171 ".concat(total, " \u6761");
      }
    },
    on: {
      change: _vm.onChange,
      showSizeChange: _vm.showSizeChange
    }
  })], 1)], 1)])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };