var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wage-set-wrapper p24 page-miH page-bgBack"
  }, [_c("a-tabs", {
    attrs: {
      activeKey: _vm.currentKey
    },
    on: {
      change: _vm.changeSort
    }
  }, [_c("a-tab-pane", {
    key: 1,
    attrs: {
      tab: "待处理"
    }
  }), _c("a-tab-pane", {
    key: 2,
    attrs: {
      tab: "已处理"
    }
  }), _c("a-tab-pane", {
    key: 3,
    attrs: {
      tab: "我发起"
    }
  }), _c("a-tab-pane", {
    key: 4,
    attrs: {
      tab: "抄送我"
    }
  })], 1), _c("div", {
    staticClass: "approval-content"
  }, [_c("ListContent", {
    attrs: {
      approvalTabState: _vm.currentKey
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };