var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "collapse_warps"
  }, [_c("div", {
    staticClass: "collapse_warp"
  }, [_c("a-icon", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.boxshow,
      expression: "!boxshow"
    }],
    staticClass: "collapse",
    attrs: {
      type: "caret-right"
    },
    on: {
      click: function click($event) {
        _vm.boxshow = !_vm.boxshow;
      }
    }
  }), _c("a-icon", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.boxshow,
      expression: "boxshow"
    }],
    staticClass: "collapse",
    attrs: {
      type: "caret-down"
    },
    on: {
      click: function click($event) {
        _vm.boxshow = !_vm.boxshow;
      }
    }
  }), _c("div", {
    staticClass: "title",
    on: {
      click: function click($event) {
        _vm.boxshow = !_vm.boxshow;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c("div", {
    staticClass: "transverse"
  }), _vm.showBtns && _vm.edit ? _c("a-button", {
    attrs: {
      slot: "right",
      disabled: _vm.disabled,
      type: "link",
      icon: _vm.icon
    },
    on: {
      click: function click($event) {
        return _vm.$emit("edit");
      }
    },
    slot: "right"
  }, [_vm._v(" " + _vm._s(_vm.editText) + " ")]) : _vm._e()], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.boxshow,
      expression: "boxshow"
    }]
  }, [_vm._t("default")], 2)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };