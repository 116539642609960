import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "resume",
    style: {
      width: _vm.width,
      height: _vm.height
    }
  }, [_vm.src ? _c("div", {
    staticStyle: {
      border: "1px solid #e8e8e8"
    }
  }, [_vm.action.length > 0 ? _c("div", {
    staticClass: "btn"
  }, [_vm.action.includes("download") ? _c("a-button", {
    attrs: {
      icon: "download"
    },
    on: {
      click: _vm.onDownload
    }
  }) : _vm._e(), _vm.action.includes("delete") ? _c("a-button", {
    attrs: {
      icon: "delete"
    },
    on: {
      click: _vm.onDelSrc
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.fileType === "img" ? _c("img", {
    attrs: {
      src: "".concat(_vm.src, "?token=").concat(_vm.$store.state.auth.token),
      width: _vm.width,
      height: _vm.height
    }
  }) : _vm.fileType === "pdf" ? _c("embed", {
    attrs: {
      src: "".concat(_vm.src, "?token=").concat(_vm.$store.state.auth.token),
      type: "application/pdf",
      width: _vm.width,
      height: _vm.height
    }
  }) : _c("iframe", {
    attrs: {
      src: "https://view.officeapps.live.com/op/view.aspx?src=".concat(_vm.src, "?token=").concat(_vm.$store.state.auth.token),
      width: _vm.width,
      height: _vm.height,
      frameborder: "1"
    }
  })]) : _c("div", [_vm.upload ? _c("a-upload", {
    staticClass: "uploading",
    style: {
      width: _vm.width,
      height: _vm.height
    },
    attrs: {
      action: "/recruit2/api/v1/talentPools/resumeTransformation",
      "list-type": "picture-card",
      name: "file",
      accept: ".doc,.docx,.pdf,.jpg,.jpeg,.png",
      showUploadList: false,
      beforeUpload: _vm.beforeUpload,
      headers: _vm.headers
    },
    on: {
      change: _vm.handleChange
    }
  }, [_c("div", {
    staticClass: "pdfNull"
  }, [_c("img", {
    staticClass: "pdfNullImg",
    attrs: {
      src: _vm.jl
    }
  }), _c("div", [_vm._v(" 该人才暂无附件简历 "), _c("a-button", {
    attrs: {
      type: "link"
    }
  }, [_vm._v(" 立即上传 ")])], 1), _c("div", [_vm._v("支持格式：PDF、DOC、DOCX、PNG、JPG、JPEG")])])]) : _c("div", {
    staticClass: "pdfNull",
    style: {
      width: _vm.width,
      height: _vm.height
    }
  }, [_c("img", {
    staticClass: "pdfNullImg",
    attrs: {
      src: _vm.jl
    }
  }), _c("div", [_vm._v("该人才暂无附件简历")])])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };