var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "uplaodBox"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goback
    }
  }, [_vm._v(" 批量导入 ")]), _c("a-spin", {
    attrs: {
      spinning: _vm.uploading
    }
  }, [_c("div", {
    staticClass: "ant-upload-select-text-box",
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-upload", {
    ref: "upload",
    attrs: {
      "file-list": _vm.fileList,
      remove: _vm.handleRemove,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("img", {
    staticStyle: {
      width: "139px",
      height: "106px"
    },
    attrs: {
      src: _vm.uploadImg
    }
  }), _c("div", {
    staticClass: "ant-modal-body"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#666"
    }
  }, [_vm._v("点击添加文件或将文件拖拽到此处")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#999"
    }
  }, [_vm._v("(文件大小不超过5M)")])])])], 1)]), _c("div", {
    staticClass: "tipBox"
  }, [_c("a-row", {
    staticStyle: {
      color: "#252525",
      "font-size": "16px",
      "font-weight": "500"
    }
  }, [_vm._v(" 温馨提示： ")]), _c("a-row", [_c("a-col", {
    staticStyle: {
      "line-height": "25px",
      color: "#999"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "6px"
    }
  }, [_vm._v(" 1.首先下载标准模板，填写信息后上传"), _c("a-button", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      size: "small",
      type: "link"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("下载标准模板")])], 1), _c("div", [_vm._v("2.支持WPS、Excel2003以上版本文件")]), _c("div", [_vm._v("3.请不要加密模板文件，将导致错误")]), _c("div", [_vm._v("4.请不要上传带宏的文件，将导致错误")])])], 1)], 1), _c("a-modal", {
    attrs: {
      title: "未成功名单",
      width: "800px"
    },
    on: {
      ok: _vm.handleFailClose
    },
    model: {
      value: _vm.dialogFailVisible,
      callback: function callback($$v) {
        _vm.dialogFailVisible = $$v;
      },
      expression: "dialogFailVisible"
    }
  }, [_c("a-table", {
    attrs: {
      "row-key": function rowKey(d) {
        return d.id;
      },
      columns: _vm.columns,
      "data-source": _vm.Faildata,
      pagination: false
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };