import "core-js/modules/es.array.concat.js";
export default {
  data: function data() {
    return {
      zhifubaoAuthorization: require("@/assets/img/zhifubaoAuthorization.png")
    };
  },
  mounted: function mounted() {
    this.paySalary();
  },
  methods: {
    paySalary: function paySalary() {
      var _this = this;
      var pid = localStorage.getItem("zfbPid");
      var code = this.$route.query.app_auth_code;
      this.$request({
        url: "/hr/api/v1/transferAccountsBatches/updateZfbAppAuthToken/".concat(code, "/").concat(pid)
      }).then(function (res) {
        _this.$message.success("账户绑定成功！");
      }).catch(function (err) {
        console.log(err);
        _this.$message.error("账户绑定失败！");
      });
    }
  }
};