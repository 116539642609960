import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
// 导入draggable组件
import draggable from "vuedraggable";
export default {
  // 注册draggable组件
  components: {
    draggable: draggable
  },
  data: function data() {
    return {
      drag: false,
      rightData: [],
      expandedKeys: [],
      checkedKeys: [],
      selectedKeys: [],
      replaceFields: {
        children: "groupItem",
        title: "name",
        key: "enName"
      }
    };
  },
  created: function created() {
    this.checkedKeys = this.checkedKey.checkedKeys;
    this.rightData = this.checkedKey.rightData;
  },
  watch: {
    checkedKey: function checkedKey(data) {
      this.checkedKeys = data.checkedKeys;
      this.rightData = data.rightData;
    }
  },
  props: {
    transferData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    defaultSelectedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    checkedKey: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    remove: function remove(index) {
      var _this2 = this;
      this.checkedKeys.forEach(function (element, i) {
        if (_this2.rightData[index].enName === element) {
          _this2.checkedKeys.splice(i, 1);
        }
      });
      this.rightData.splice(index, 1);
    },
    transposition: function transposition(index1, index2) {
      this.rightData[index1] = this.rightData.splice(index2, 1, this.rightData[index1])[0];
    },
    // 开始拖拽事件
    onStart: function onStart() {
      this.drag = true;
    },
    // 拖拽结束事件
    onEnd: function onEnd() {
      this.drag = false;
    },
    onCheck: function onCheck(checkedKeys, e) {
      this.checkedKeys = checkedKeys;
      var _this = this;
      checkedKeys.forEach(function (element, index) {
        if (isNaN(element)) {
          if (_this.rightData.filter(function (item) {
            return item.enName === element;
          }).length === 0) {
            _this.rightData.push(e.checkedNodes[index].data.props);
          }
        }
      });
      var arr = [];
      _this.rightData.forEach(function (element, index) {
        if (checkedKeys.filter(function (item) {
          return item === element.enName;
        }).length !== 0) {
          arr.push(element);
        }
      });
      this.rightData = arr;
    }
  }
};