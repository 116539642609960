import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import moment from "moment";
export default {
  data: function data() {
    return {
      paySalaryOrderTitle: "",
      paySalaryTypes: "",
      paySalaryGmtCreate: "",
      paySalaryMoney: "",
      paySalaryTotal: "",
      payArr: ["支付宝", "微工卡", "银行卡"],
      info: {}
    };
  },
  created: function created() {
    var info = JSON.parse(sessionStorage.getItem("paySalaryInfo"));
    this.info = info;
    if (info.type === 1) this.paySalaryTypes = "支付宝";
    if (info.type === 2) this.paySalaryTypes = "微工卡";
    if (info.type === 3) this.paySalaryTypes = "银行卡";
    this.paySalaryOrderTitle = info.orderTitle;
    this.paySalaryGmtCreate = info.gmtCreate ? moment(info.gmtCreate).format("YYYY-MM-DD HH:mm") : "";
    this.paySalaryMoney = info.money;
    this.paySalaryTotal = info.totalCount;
  },
  methods: {
    // 查看详情
    goDetail: function goDetail() {
      var record = JSON.stringify(this.info);
      this.$router.replace({
        path: "/paySalary/examineDetail",
        query: {
          record: record
        }
      });
    }
  }
};