import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "overview--wrapper",
    attrs: {
      id: "detail"
    }
  }, [_c("TableTitle", [_c("template", {
    slot: "title"
  }, [_vm._v(" 员工详情 "), _c("a-tooltip", {
    attrs: {
      placement: "right"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v("默认列表中包括待入职、在职、已离职员工")])]), _c("a-icon", {
    staticClass: "primarys",
    attrs: {
      type: "question-circle",
      theme: "filled"
    }
  })], 2)], 1), _c("template", {
    slot: "action"
  }, [_c("a-space", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goUpload
    }
  }, [_vm._v("批量导入")]), _c("a-badge", {
    attrs: {
      count: _vm.allCount,
      offset: [0, 0]
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onTodayAdd
    }
  }, [_vm._v("今日新增")])], 1)], 1)], 1)], 2), _c("div", {
    staticStyle: {
      padding: "0 24px"
    }
  }, [_c("TableBodyNew", {
    attrs: {
      selectHead: "",
      selection: "",
      tableName: "empInfo",
      fieldCompanyId: "site",
      selectedRowKeys: _vm.selectedRowKeys,
      selectedHead: _vm.selectedHead,
      source: _vm.staffData,
      headerRowID: "sortable_list",
      columns: _vm.columns,
      columnsOld: _vm.columns,
      isJF: _vm.isJF
    },
    on: {
      "update:selectedRowKeys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      "update:selected-row-keys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      "update:selectedHead": function updateSelectedHead($event) {
        _vm.selectedHead = $event;
      },
      "update:selected-head": function updateSelectedHead($event) {
        _vm.selectedHead = $event;
      },
      onClose: _vm.handleCancel,
      onSearch: _vm.handleFormSearch,
      onReload: _vm.init,
      onExport: function onExport() {
        return _vm.download(1);
      },
      onExportAll: function onExportAll() {
        return _vm.download(2);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(prop) {
        return [_c("a-table", {
          class: prop.className,
          attrs: {
            components: prop.components,
            columns: prop.columns,
            scroll: {
              x: prop.scrollWidth
            },
            customHeaderRow: function customHeaderRow() {
              return {
                domProps: {
                  id: prop.headerRowID
                }
              };
            },
            "data-source": _vm.staffData,
            pagination: _vm.pagination,
            "row-key": function rowKey(d) {
              return d.id;
            },
            loading: _vm.tableLoading,
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange
            }
          },
          on: {
            change: _vm.handleTableChange
          },
          scopedSlots: _vm._u([{
            key: "name",
            fn: function fn(text, record) {
              return _c("span", {}, [_c("div", {
                staticClass: "today-tags",
                class: record.status == 1 || record.status == 4 ? "pl10" : ""
              }, [record.status == 1 ? _c("div", {
                staticClass: "tags-item",
                staticStyle: {
                  background: "#ffbb02"
                }
              }, [_vm._v("待入职")]) : _vm._e(), record.status == 4 ? _c("div", {
                staticClass: "tags-item",
                staticStyle: {
                  background: "#b9b9b9"
                }
              }, [_vm._v("已离职")]) : _vm._e(), _c("a-tooltip", {
                attrs: {
                  placement: "top"
                }
              }, [_c("template", {
                slot: "title"
              }, [_c("span", [_vm._v(_vm._s(text))])]), _vm._v(" " + _vm._s(text) + " ")], 2)], 1)]);
            }
          }, {
            key: "contractType",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.getContractType(text) : ""))]);
            }
          }, {
            key: "contractStartTime",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.formatDate(text) : ""))]);
            }
          }, {
            key: "contractEndTime",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.formatDate(text) : ""))]);
            }
          }, {
            key: "contractsStatus",
            fn: function fn(text, record) {
              return [_c("span", [_vm._v(_vm._s(record.contractsStatus != null ? _vm.getConstractsStatusType(Number(record.contractsStatus)) : ""))])];
            }
          }, {
            key: "postType",
            fn: function fn(text, record) {
              return [_c("span", [_vm._v(_vm._s(record.postType != null ? _vm.$store.state.enums.postTypeArr[record.postType - 1] : ""))])];
            }
          }, {
            key: "formalType",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.formalType[Number(text - 1)] : ""))]);
            }
          }, {
            key: "isImprove",
            fn: function fn(text, record) {
              return _c("div", {}, [_c("div", {
                staticClass: "pointer"
              }, [record.isImprove === true ? _c("span", {
                class: record.isImprove ? "color2294FF" : "colorC0C0C0"
              }, [_vm._v("•")]) : _vm._e(), record.isImprove === true ? _c("span", [_vm._v("已完善")]) : _vm._e(), _c("a-popover", {
                attrs: {
                  trigger: "click",
                  arrowPointAtCenter: ""
                }
              }, [_c("template", {
                slot: "content"
              }, [_c("div", {
                staticStyle: {
                  width: "200px"
                }
              }, [_c("span", {
                staticStyle: {
                  color: "red"
                }
              }, [_vm._v("{record.needComplete}")]), _c("span", [_vm._v("信息未完善")])])]), record.isImprove === false ? _c("span", {
                class: record.isImprove ? "color2294FF" : "colorC0C0C0"
              }, [_vm._v("•")]) : _vm._e(), record.isImprove === false ? _c("span", [_vm._v("未完善")]) : _vm._e()], 2)], 1)]);
            }
          }, {
            key: "verified",
            fn: function fn(text) {
              return _c("div", {}, [_c("div", {
                staticClass: "pointer"
              }, [_c("span", {
                class: text ? "color2294FF" : "colorC0C0C0"
              }, [_vm._v("•")]), _vm._v(" " + _vm._s(text ? "已认证" : "未认证") + " ")])]);
            }
          }, {
            key: "gender",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.gender[text - 1] : ""))]);
            }
          }, {
            key: "maritalStatus",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.maritalStatus[text - 1] : ""))]);
            }
          }, {
            key: "birthStatus",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.birthStatus[text - 1] : ""))]);
            }
          }, {
            key: "politicalStatus",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.renderPoliticalStatus(text) : ""))]);
            }
          }, {
            key: "regType",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.regType[text - 1] : ""))]);
            }
          }, {
            key: "birthday",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? text.split(" ")[0] : ""))]);
            }
          }, {
            key: "bloodGroup",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.bloodGroup[text - 1] : ""))]);
            }
          }, {
            key: "teaMethods",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.teaMethods[Number(text) - 1] : ""))]);
            }
          }, {
            key: "joinDate",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(text != null ? _vm.formatDate(text) : ""))]);
            }
          }, {
            key: "bankPlace",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.formatAreaValue(text)))]);
            }
          }, {
            key: "tag",
            fn: function fn(text) {
              return _c("div", {}, [text ? _c("div", [_c("a-popover", {
                attrs: {
                  placement: "topLeft"
                }
              }, [_c("template", {
                slot: "content"
              }, [_c("div", {
                staticClass: "tags-tit"
              }, [_vm._v("员工标签:")]), _c("div", {
                staticClass: "tags-boxs"
              }, _vm._l(text, function (item, index) {
                return _c("p", {
                  key: index,
                  staticClass: "tags-item"
                }, [_vm._v(_vm._s(item))]);
              }), 0)]), _c("div", {
                staticClass: "tags"
              }, [_vm._v(_vm._s(text[0]))]), text.length > 1 ? _c("span", {
                staticClass: "tags-d"
              }, [_vm._v("...")]) : _vm._e()], 2)], 1) : _vm._e()]);
            }
          }, {
            key: "action",
            fn: function fn(text, record) {
              return [_c("a-button", {
                attrs: {
                  size: "small",
                  type: "link"
                },
                on: {
                  click: function click($event) {
                    return _vm.goDetail(record);
                  }
                }
              }, [_vm._v("详情")])];
            }
          }], null, true)
        })];
      }
    }])
  }, [_c("template", {
    slot: "search"
  }, [_c("a-input-search", {
    attrs: {
      placeholder: "姓名/手机号/身份证号",
      allowClear: ""
    },
    on: {
      search: _vm.onSearch
    }
  }), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "在职状态",
      allowClear: ""
    },
    on: {
      change: _vm.init
    },
    model: {
      value: _vm.statusVal,
      callback: function callback($$v) {
        _vm.statusVal = $$v;
      },
      expression: "statusVal"
    }
  }, [_c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("待入职")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("在职")]), _c("a-select-option", {
    key: 4,
    attrs: {
      value: 4
    }
  }, [_vm._v("已离职")])], 1), _c("a-range-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "入职日期",
      placeholder: ["入职开始日期", "入职结束日期"]
    },
    model: {
      value: _vm.joinDateMoment,
      callback: function callback($$v) {
        _vm.joinDateMoment = $$v;
      },
      expression: "joinDateMoment"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "员工排序",
      placeholder: "请选择员工排序"
    },
    model: {
      value: _vm.gmtOrder,
      callback: function callback($$v) {
        _vm.gmtOrder = $$v;
      },
      expression: "gmtOrder"
    }
  }, _vm._l(_vm.staffSort, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.key
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "用工单位",
      placeholder: "请选择用工单位",
      "allow-clear": true
    },
    model: {
      value: _vm.companyId,
      callback: function callback($$v) {
        _vm.companyId = $$v;
      },
      expression: "companyId"
    }
  }, _vm._l(_vm.companyOptions, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "员工状态",
      placeholder: "请选择员工状态",
      "allow-clear": true
    },
    model: {
      value: _vm.formalTypeVal,
      callback: function callback($$v) {
        _vm.formalTypeVal = $$v;
      },
      expression: "formalTypeVal"
    }
  }, [_c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("试用")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("正式")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "性别",
      placeholder: "请选择性别",
      "allow-clear": true
    },
    model: {
      value: _vm.genderVal,
      callback: function callback($$v) {
        _vm.genderVal = $$v;
      },
      expression: "genderVal"
    }
  }, [_c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("男")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("女")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "学历",
      placeholder: "请选择学历",
      "allow-clear": true
    },
    model: {
      value: _vm.highestEducationVal,
      callback: function callback($$v) {
        _vm.highestEducationVal = $$v;
      },
      expression: "highestEducationVal"
    }
  }, _vm._l(_vm.edu, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: index + 1
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "信息补充",
      placeholder: "请选择信息补充",
      "allow-clear": true
    },
    model: {
      value: _vm.isImproveVal,
      callback: function callback($$v) {
        _vm.isImproveVal = $$v;
      },
      expression: "isImproveVal"
    }
  }, [_c("a-select-option", {
    key: 0,
    attrs: {
      value: 0
    }
  }, [_vm._v("未完善")]), _c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("已完善")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "实名认证",
      placeholder: "请选择实名认证",
      "allow-clear": true
    },
    model: {
      value: _vm.verifiedVal,
      callback: function callback($$v) {
        _vm.verifiedVal = $$v;
      },
      expression: "verifiedVal"
    }
  }, [_c("a-select-option", {
    key: 0,
    attrs: {
      value: 0
    }
  }, [_vm._v("未认证")]), _c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("已认证")])], 1), !_vm.isJF ? _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "工作性质",
      placeholder: "请选择工作性质",
      options: _vm.$store.state.enums.postTypeLst,
      "allow-clear": true
    },
    model: {
      value: _vm.postTypeVal,
      callback: function callback($$v) {
        _vm.postTypeVal = $$v;
      },
      expression: "postTypeVal"
    }
  }) : _vm._e(), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "政治面貌",
      placeholder: "请选择政治面貌",
      "allow-clear": true
    },
    model: {
      value: _vm.politicalStatusVal,
      callback: function callback($$v) {
        _vm.politicalStatusVal = $$v;
      },
      expression: "politicalStatusVal"
    }
  }, [_c("a-select-option", {
    key: 0,
    attrs: {
      value: 0
    }
  }, [_vm._v("中共党员")]), _c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("共青团员")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("普通民众/群众")]), _c("a-select-option", {
    key: 3,
    attrs: {
      value: 3
    }
  }, [_vm._v("其它")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "婚姻状况",
      placeholder: "请选择婚姻状况",
      "allow-clear": true
    },
    model: {
      value: _vm.maritalStatusVal,
      callback: function callback($$v) {
        _vm.maritalStatusVal = $$v;
      },
      expression: "maritalStatusVal"
    }
  }, [_c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("已婚")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("未婚")]), _c("a-select-option", {
    key: 3,
    attrs: {
      value: 3
    }
  }, [_vm._v("离异")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "合同签署状态",
      placeholder: "请选择合同签署状态",
      "allow-clear": true
    },
    model: {
      value: _vm.contractsStatusVal,
      callback: function callback($$v) {
        _vm.contractsStatusVal = $$v;
      },
      expression: "contractsStatusVal"
    }
  }, [_c("a-select-option", {
    key: 0,
    attrs: {
      value: 0
    }
  }, [_vm._v("合同未发起")]), _c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("待员工签署")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("待公司签署")]), _c("a-select-option", {
    key: 3,
    attrs: {
      value: 3
    }
  }, [_vm._v("签署完成")]), _c("a-select-option", {
    key: -1,
    attrs: {
      value: -1
    }
  }, [_vm._v("合同过期未签署")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "标签",
      placeholder: "请选择标签",
      mode: "multiple",
      "option-label-prop": "label",
      "allow-clear": true
    },
    model: {
      value: _vm.tagsVal,
      callback: function callback($$v) {
        _vm.tagsVal = $$v;
      },
      expression: "tagsVal"
    }
  }, _vm._l(_vm.labelList, function (item) {
    return _c("a-select-option", {
      key: item.tagName,
      attrs: {
        value: item.tagName,
        label: item.tagName
      }
    }, [_vm._v(_vm._s(item.tagName))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "渠道",
      placeholder: "请选择渠道",
      "allow-clear": true
    },
    model: {
      value: _vm.channelIdVal,
      callback: function callback($$v) {
        _vm.channelIdVal = $$v;
      },
      expression: "channelIdVal"
    }
  }, _vm._l(_vm.channelNames, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "签约主体",
      options: _vm.contractSubjectSource,
      "allow-clear": true,
      placeholder: "请选择签约主体"
    },
    model: {
      value: _vm.contractSubject,
      callback: function callback($$v) {
        _vm.contractSubject = $$v;
      },
      expression: "contractSubject"
    }
  })], 1)], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };