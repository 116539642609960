import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import { onlineEditNewReq } from "@/api/wages/monthly";
export default {
  data: function data() {
    return {
      maxDate: 30,
      id: null,
      visible: false,
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      columns: [],
      selectedRow: [],
      dataList: []
    };
  },
  created: function created() {
    this.dataList = JSON.parse(sessionStorage.getItem("selectedRow"));
    var columnsTemp = JSON.parse(sessionStorage.getItem("salaryColumns"));
    this.columns = columnsTemp.map(function (item) {
      return _objectSpread(_objectSpread({}, item), {}, {
        scopedSlots: {
          customRender: item.dataIndex
        }
      });
    });
    this.id = this.$route.query.id;
    this.maxDate = this.$moment(this.$route.query.date, "YYYY-MM").daysInMonth();
  },
  beforeDestory: function beforeDestory() {},
  methods: {
    limitNumber: function limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      } else {
        return 0;
      }
    },
    handleCancel: function handleCancel() {
      this.$router.go(-1);
    },
    handleSelfChange: function handleSelfChange($event, id, index) {
      var temp = JSON.parse(JSON.stringify(this.dataList));
      var index2 = temp.findIndex(function (item) {
        return item.id === id;
      });
      temp[index2]["".concat(index)] = $event;
      this.dataList = JSON.parse(JSON.stringify(temp));
    },
    handleSave: function handleSave() {
      var _this = this;
      var arr = [];
      this.dataList.forEach(function (item2, index2) {
        var obj = {};
        _this.columns.forEach(function (item, index) {
          if (item.fieldGroup !== "covariance") {
            obj["".concat(item.title)] = item2["".concat(item.dataIndex)];
          }
        });
        arr.push(obj);
      });
      onlineEditNewReq({
        nameContentMapList: arr,
        companyId: this.companyId,
        monthly: this.dataList[0].monthly,
        yearly: this.dataList[0].yearly,
        salaryTemplateSalaryId: this.dataList[0].salaryTemplateSalaryId
      }).then(function (res) {
        _this.$router.go(-1);
        _this.$message.success("保存成功");
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    showEdit: function showEdit() {
      this.visible = true;
    }
  }
};