import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-bgBack"
  }, [_c("rTitle", {
    attrs: {
      type: "back",
      tit: "".concat(_vm.$route.name == "talentEdit" ? "编辑信息" : "新建人才")
    }
  }), _c("showResume", {
    attrs: {
      status: "".concat(_vm.$route.name == "talentEdit" ? "edit" : "add"),
      companyUnitsInfo: _vm.$route.name == "talentEdit" ? {
        talentPoolId: _vm.$route.query.talentPoolId
      } : {}
    },
    on: {
      handleSubmit: _vm.handleSubmit,
      editCancel: function editCancel($event) {
        return _vm.$router.go(-1);
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };