import selectPosition from "./selectPosition";
import selectScope from "./selectScope";
import selectSet from "./selectSet";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    selectPosition: selectPosition,
    selectScope: selectScope,
    selectSet: selectSet
  },
  data: function data() {
    return {
      current: 0,
      selectPositionArr: [],
      selectScopeArr: []
    };
  },
  watch: {
    visible: function visible(val) {
      if (val) {
        this.current = 0;
        this.selectPositionArr = [];
        this.selectScopeArr = [];
      }
    }
  },
  methods: {
    upDate: function upDate(i, e) {
      if (i === -2) {
        this.$emit("update:visible", false);
        this.$emit("upDate", false);
      }
      if (i === -1) {
        this.$emit("update:visible", false);
      }
      if (i === 1 && e) {
        this.selectPositionArr = e;
      }
      if (i === 2 && e) {
        this.selectScopeArr = e;
      }
      this.current = i;
    }
  }
};