var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-form", {
    staticStyle: {
      position: "relative"
    },
    attrs: {
      layout: "inline"
    }
  }, [!_vm.disabled ? _c("a-button", {
    staticStyle: {
      position: "absolute",
      right: "0",
      top: "-60px",
      cursor: "pointer"
    },
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.addItem
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 新增 ")], 1) : _vm._e(), _vm._l(_vm.contacts, function (item, idx) {
    return _c("a-row", {
      key: idx,
      staticStyle: {
        width: "900px",
        margin: "0 auto"
      }
    }, [_c("a-col", {
      attrs: {
        span: 12
      }
    }, [_c("a-form-item", {
      staticClass: "label",
      attrs: {
        required: "",
        label: "服务合作起止时间"
      }
    }, [_c("a-range-picker", {
      staticStyle: {
        width: "260px"
      },
      attrs: {
        value: item.dateRange,
        disabled: _vm.disabled,
        getCalendarContainer: function getCalendarContainer(triggerNode) {
          return triggerNode.parentNode;
        }
      },
      on: {
        change: function change($event) {
          return _vm.onRangeChange($event, idx);
        }
      }
    })], 1)], 1), _c("a-col", {
      staticStyle: {
        position: "relative"
      },
      attrs: {
        span: 12
      }
    }, [_c("a-form-item", {
      staticClass: "label",
      attrs: {
        label: "新增（首年）/续约"
      }
    }, [_c("a-radio-group", {
      attrs: {
        value: item.customType,
        disabled: _vm.disabled
      },
      on: {
        change: function change($event) {
          return _vm.onTypeChange($event, idx);
        }
      }
    }, [_c("a-radio", {
      attrs: {
        value: "0"
      }
    }, [_vm._v("新增客户")]), _c("a-radio", {
      attrs: {
        value: "1"
      }
    }, [_vm._v("续约客户")])], 1)], 1), _vm.del_visible ? _c("a-button", {
      staticClass: "mt4",
      staticStyle: {
        position: "absolute",
        right: "0",
        cursor: "pointer"
      },
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(idx);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "delete"
      }
    }), _vm._v(" 删除 ")], 1) : _vm._e()], 1)], 1);
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };