import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import { selectAllByEmpInfoQuery, selectAllByCompanyIdAndIdCard } from "../info/component/api.js";
import { listByCompanyIdGet, delEmpInfoBatchReq } from "../info/api";
var columns = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: 80
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  width: 120
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 200
}];
export default {
  components: {},
  data: function data() {
    return {
      companyName: this.$store.state.hr.companyName,
      companyId: this.$store.state.hr.companyId,
      postTypeShow: true,
      options: [],
      probationDays: null,
      selectedRows: [],
      // companyId: this.$store.state.hr.companyId,
      labelList: [],
      textState: "",
      columns: columns,
      dataList: [],
      seniorVisible: false,
      seniorList: [],
      selectedRowKeys: [],
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 16
      },
      form: {},
      pagination: {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      },
      // //////////////////
      chosenList: [],
      textContent: "",
      copyVisible: false,
      errorState: false,
      // ///////////////////////////
      checkAll: false,
      visible: false,
      staffFrom: {
        companyId: this.$store.state.hr.companyId,
        nameOrPhone: null,
        pageNo: 1,
        pageSize: 10
      },
      pageCount: 0,
      staffData: [],
      staffrightData: [],
      confirmLoading: false,
      spinning: false
    };
  },
  watch: {
    textContent: function textContent(e) {
      if (e) {
        this.errorState = false;
      }
    }
  },
  methods: {
    handleBatchFunc: function handleBatchFunc(list) {
      var _this = this;
      var ids = list.map(function (item) {
        return item.id;
      });
      this.$confirm({
        title: "删除确认",
        content: "删除后将无法恢复，确认要删除选中的员工吗？",
        onOk: function onOk() {
          delEmpInfoBatchReq(ids).then(function (res) {
            _this.$message.success("删除成功");
            _this.$emit("saveOk1");
          }).catch(function (err) {
            console.log(err);
          });
        },
        onCancel: function onCancel() {}
      });
    },
    handleOk: function handleOk(e) {
      if (this.staffrightData.length === 0) {
        this.$message.error("请您勾选人员后点击确定");
        return;
      }
      this.confirmLoading = true;
      this.visible = false;
      this.handleBatchFunc(this.staffrightData);
      this.confirmLoading = false;
    },
    copyOk: function copyOk() {
      if (this.chosenList.length === 0) {
        this.$message.error("请您勾选人员后点击确定");
        return;
      }
      this.copyVisible = false;
      this.handleBatchFunc(this.chosenList);
    },
    seniorOk: function seniorOk() {
      if (this.seniorList.length === 0) {
        this.$message.error("请您勾选人员后点击确定");
        return;
      }
      this.seniorVisible = false;
      // 批量离职返回上一页，批量确认离职 去新弹出框
      this.handleBatchFunc(this.seniorList);
    },
    deleteSeniorItem: function deleteSeniorItem(e) {
      var _this2 = this;
      this.seniorList.forEach(function (item, index) {
        if (item.id === e.id) {
          _this2.seniorList.splice(index, 1);
          _this2.selectedRowKeys.forEach(function (item2, index2) {
            if (item2 === e.id) {
              _this2.selectedRowKeys.splice(index2, 1);
            }
          });
        }
      });
    },
    onDateChange: function onDateChange(e, text) {
      console.log(e, text);
      if (text === "join") {
        this.form.joinDateStart = e.length !== 0 ? e[0].format("YYYY-MM-DD") : null;
        this.form.joinDateEnd = e.length !== 0 ? e[1].format("YYYY-MM-DD") : null;
      } else {
        this.form.leaveDateStart = e.length !== 0 ? e[0].format("YYYY-MM-DD") : null;
        this.form.leaveDateEnd = e.length !== 0 ? e[1].format("YYYY-MM-DD") : null;
      }
    },
    onSelectChange: function onSelectChange(selectedRowKeys, e) {
      var _this3 = this;
      this.selectedRowKeys = selectedRowKeys;
      this.dataList.forEach(function (item) {
        selectedRowKeys.forEach(function (item2) {
          if (item.id === item2) {
            _this3.seniorList.push(item);
            _this3.seniorList = _this3.unique(_this3.seniorList);
          }
        });
      });
      this.seniorList.forEach(function (item, index) {
        if (_this3.selectedRowKeys.indexOf(item.id) === -1) {
          item.isSelectedItem = 2;
        }
      });
      var arr = [];
      this.seniorList.forEach(function (item, index) {
        if (!item.isSelectedItem) {
          arr.push(item);
        }
      });
      this.seniorList = JSON.parse(JSON.stringify(arr));
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      // this.checkStatue = 0;
      this.getSeniorList();
    },
    clearForm: function clearForm() {
      this.form = {
        companyId: this.companyId,
        nameOrPhone: null,
        pageNo: 1,
        pageSize: 10
      };
    },
    seniorCancel: function seniorCancel() {
      this.seniorVisible = false;
      this.selectedRowKeys = [];
    },
    getSeniorListCheck: function getSeniorListCheck() {
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.checkStatue = 1;
      this.getSeniorList();
    },
    getListByCompanyId: function getListByCompanyId() {
      var _this4 = this;
      listByCompanyIdGet({
        companyId: this.companyId
      }).then(function (_ref) {
        var data = _ref.data;
        _this4.labelList = data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    showSenior: function showSenior() {
      var _this5 = this;
      this.visible = false;
      this.seniorVisible = true;
      this.pagination = {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      };
      this.form = {
        companyId: this.companyId,
        nameOrPhone: null,
        pageNo: 1,
        pageSize: 10
      };
      this.selectedRowKeys = [];
      this.getSeniorList();
      this.getListByCompanyId();
      if (this.staffrightData.length > 0) {
        this.seniorList = JSON.parse(JSON.stringify(this.staffrightData));
        this.staffrightData.forEach(function (item, index) {
          _this5.selectedRowKeys.push(item.id);
        });
      } else {
        this.selectedRowKeys = [];
        this.seniorList = [];
      }
    },
    getSeniorList: function getSeniorList() {
      var _this6 = this;
      var data = _objectSpread(_objectSpread({}, this.form), {}, {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      });
      var fun = "";
      fun = selectAllByEmpInfoQuery(data);
      fun.then(function (res) {
        if (res.data === null || res.data.entities === null) {
          _this6.total = 0;
          _this6.pagination.total = 0;
          _this6.dataList = [];
        } else {
          _this6.pagination.total = res.data && res.data.entityCount;
          _this6.dataList = res.data.entities;
        }
      }).catch(function (err) {
        console.log("err==>", err);
      });
    },
    // `````````高级粘贴```````````
    deleteCopyItem: function deleteCopyItem(e) {
      var _this7 = this;
      this.chosenList.forEach(function (item, index) {
        if (item.id === e.id) {
          _this7.chosenList.splice(index, 1);
        }
      });
    },
    showCopy: function showCopy() {
      this.visible = false;
      this.textContent = "";
      this.copyVisible = true;
      if (this.staffrightData.length > 0) {
        this.chosenList = JSON.parse(JSON.stringify(this.staffrightData));
      } else {
        this.chosenList = [];
      }
    },
    getEmpListByIdCards: function getEmpListByIdCards() {
      var _this8 = this;
      if (this.textContent !== "") {
        var arr = this.textContent.split(/[\W]/);
        var fun = "";
        var data = {
          cardList: arr,
          companyId: this.companyId
        };
        fun = selectAllByCompanyIdAndIdCard(data);
        fun.then(function (res) {
          // console.log(res)
          var obj = {};
          if (_this8.staffrightData.length > 0) {
            _this8.chosenList = [].concat(_toConsumableArray(_this8.staffrightData), _toConsumableArray(_this8.chosenList), _toConsumableArray(res.data.empInfoListVOList)).reduce(function (cur, next) {
              obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
              return cur;
            }, []);
            _this8.chosenList = _this8.unique(_this8.chosenList);
          } else {
            _this8.chosenList = [].concat(_toConsumableArray(_this8.chosenList), _toConsumableArray(res.data.empInfoListVOList)).reduce(function (cur, next) {
              obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
              return cur;
            }, []);
          }
          _this8.textContent = res.data.idCardList.join(",");
          if (res.data.idCardList.length > 0) {
            _this8.$nextTick(function () {
              _this8.errorState = true;
            });
          }
        });
      }
    },
    // showModal(e) {
    //   console.log("companyName", e);
    //   this.selectedRows = e;
    //   this.visible = true;
    //   this.getCompanyId();
    // },
    unique: function unique(arr) {
      var res = new Map();
      return arr.filter(function (arr) {
        return !res.has(arr.id) && res.set(arr.id, 1);
      });
    },
    reset: function reset() {
      this.textContent = "";
      this.errorState = false;
    },
    // ··········选择人员·······························
    // 全选当前页面
    onCheckAll: function onCheckAll() {
      var _this9 = this;
      this.checkAll = !this.checkAll;
      // console.log(this.checkAll,this.staffData)
      if (this.checkAll) {
        this.staffData.forEach(function (element) {
          if (!element.isTrue) {
            _this9.onRow(element);
          }
        });
      } else {
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            _this9.staffrightData.forEach(function (esy, index) {
              if (esy.id === element.id) {
                _this9.deleteItem(element, index);
              }
            });
          }
        });
      }
    },
    deleteItem: function deleteItem(item, index) {
      var _this10 = this;
      this.staffrightData.splice(index, 1);
      this.staffData.forEach(function (element) {
        if (element.id === item.id) {
          element.isTrue = false;
          _this10.checkAll = false;
        }
      });
    },
    onSearch: function onSearch() {
      this.staffFrom.pageNo = 1;
      this.getPrePersonnelFun();
    },
    onChangePagination: function onChangePagination(pageNumber) {
      this.staffFrom.pageNo = pageNumber;
      this.getPrePersonnelFun();
    },
    showSizeChange: function showSizeChange(current, size) {
      this.staffFrom.pageNo = 1;
      this.staffFrom.pageSize = size;
      this.getPrePersonnelFun();
    },
    onRow: function onRow(item) {
      var _this11 = this;
      item.isTrue = !item.isTrue;
      if (item.isTrue) {
        var num = 0;
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            num += 1;
          }
        });
        this.checkAll = this.staffData.length === num;
        var arr = this.staffrightData.filter(function (items) {
          return items.id === item.id;
        });
        if (arr.length === 0) {
          this.staffrightData.push(item);
        }
      } else {
        this.checkAll = false;
        this.staffrightData.forEach(function (element, index) {
          if (item.id === element.id) {
            _this11.staffrightData.splice(index, 1);
          }
        });
      }
    },
    getPrePersonnelFun: function getPrePersonnelFun() {
      var _this12 = this;
      var data = _objectSpread({}, this.staffFrom);
      var fun = "";
      fun = selectAllByEmpInfoQuery(data);
      fun.then(function (res) {
        if (res.data) {
          if (res.data.entities) {
            res.data.entities.forEach(function (element, index) {
              res.data.entities[index].isTrue = false;
            });
          }
          _this12.staffrightData.forEach(function (element) {
            res.data.entities.forEach(function (esy) {
              if (element.id === esy.id) {
                esy.isTrue = true;
              }
            });
          });
        }
        _this12.staffData = res.data ? res.data.entities : [];
        _this12.pageCount = res.data ? res.data.entityCount : 0;
        var arr = _this12.staffData.filter(function (item) {
          return item.isTrue;
        });
        _this12.checkAll = !!(arr.length === _this12.staffData.length && arr.length !== 0);
      }).catch(function (err) {
        console.log("err==>", err);
      });
    },
    showModal: function showModal(e) {
      this.visible = true;
      this.textState = e;
      this.staffFrom = {
        companyId: this.companyId,
        nameOrPhone: null,
        pageNo: 1,
        pageSize: 10
      };
      this.staffrightData = [];
      this.getPrePersonnelFun();
    },
    handleCancel: function handleCancel(e) {
      this.visible = false;
    }
  }
};