import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
import moment from "moment";
var mixin = new UploadMixin();
var formalType = ["试用", "正式", "实习"];
var typeList = {
  laborContractLimit: "固定期限劳动合同",
  laborContractUnlimit: "无固定期限劳动合同",
  laborContractJobs: "以完成一定工作任务为期限的劳动合同",
  internAccords: "实习协议",
  laborContract: "劳务合同",
  reemployAccords: "返聘协议",
  laborContractDispatch: "劳务派遣合同",
  borrowedContract: "借调合同",
  partTime: "兼职合同",
  engagementLetter: "约定书",
  noviciateAgreement: "见习协议",
  dimissionContract: "离职合同",
  retireReEmploy: "退休返聘合同",
  other: "其它"
};
var columnDetail2 = [{
  title: "错误信息",
  dataIndex: "reason",
  fixed: "left",
  scopedSlots: {
    customRender: "reason"
  },
  ellipsis: true,
  width: 260
}, {
  title: "员工姓名",
  dataIndex: "empName",
  scopedSlots: {
    customRender: "empName"
  },
  ellipsis: true,
  width: 120
}, {
  title: "身份证号",
  dataIndex: "idCard",
  scopedSlots: {
    customRender: "idCard"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同名称",
  dataIndex: "name",
  scopedSlots: {
    customRender: "name"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同编号",
  dataIndex: "number",
  scopedSlots: {
    customRender: "number"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同类型",
  dataIndex: "type",
  scopedSlots: {
    customRender: "type"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同开始时间",
  dataIndex: "startTimeStr",
  scopedSlots: {
    customRender: "startTimeStr"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同结束时间",
  dataIndex: "endTimeStr",
  scopedSlots: {
    customRender: "endTimeStr"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同期限",
  dataIndex: "termStr",
  scopedSlots: {
    customRender: "termStr"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同公司",
  dataIndex: "companyName",
  scopedSlots: {
    customRender: "companyName"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同状态",
  dataIndex: "status",
  scopedSlots: {
    customRender: "status"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同签订日期",
  dataIndex: "subscribeTimeStr",
  scopedSlots: {
    customRender: "subscribeTimeStr"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同备注",
  dataIndex: "remarks",
  scopedSlots: {
    customRender: "remarks"
  },
  ellipsis: true,
  width: 200
}];
var columnDetail = [{
  title: "错误信息",
  dataIndex: "reason",
  fixed: "left",
  scopedSlots: {
    customRender: "reason"
  },
  ellipsis: true,
  width: 260
}, {
  title: "员工姓名",
  dataIndex: "empName",
  scopedSlots: {
    customRender: "empName"
  },
  ellipsis: true,
  width: 120
}, {
  title: "身份证号",
  dataIndex: "idCard",
  scopedSlots: {
    customRender: "idCard"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同名称",
  dataIndex: "name",
  scopedSlots: {
    customRender: "name"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同类型",
  dataIndex: "type",
  scopedSlots: {
    customRender: "type"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同开始时间",
  dataIndex: "startTimeStr",
  scopedSlots: {
    customRender: "startTimeStr"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同结束时间",
  dataIndex: "endTimeStr",
  scopedSlots: {
    customRender: "endTimeStr"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同期限",
  dataIndex: "termStr",
  scopedSlots: {
    customRender: "termStr"
  },
  ellipsis: true,
  width: 200
}, {
  title: "合同备注",
  dataIndex: "remark",
  scopedSlots: {
    customRender: "remark"
  },
  ellipsis: true,
  width: 200
}];
import axios from "axios";
import qs from "qs";
export default {
  mixins: [mixin],
  data: function data() {
    return {
      moment: moment,
      typeList: typeList,
      importState: 1,
      columnDetail2: columnDetail2,
      count: 0,
      succ: 0,
      fial: 0,
      formalType: formalType,
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showQuickJumper: true
      },
      dataDetail: [],
      callback: false,
      dialogFailVisible: false,
      downloadurl: undefined,
      columnDetail: columnDetail,
      uploadImg: require("@/assets/img/uploadImg.png"),
      params: {
        templateName: "empContract.xlsx"
      },
      params2: {
        templateName: "empPaperContract.xlsx"
      },
      url: "/hr/api/v1/excel/template/download",
      excelName: "电子合同模板.xlsx",
      excelName2: "纸质电子合同模板.xlsx"
    };
  },
  created: function created() {
    var companyId = this.$store.state.hr.companyId;
    this.action = "/hr/api/v1/empContracts/importFile?companyId=".concat(companyId, "&force=false");
    this.action2 = "/hr/api/v1/empPaperContracts/importFile?companyId=".concat(companyId, "&force=false");
  },
  methods: {
    beforeUpload: function beforeUpload(file) {
      var _this = this;
      if (this.fileList.length > 0) {
        this.$message("最多只能上传一个");
        return false;
      }
      this.fileList = [].concat(_toConsumableArray(this.fileList), [file]);
      if (this.fileList && this.fileList.length > 0) {
        var fileList = this.fileList;
        var formData = new FormData();
        fileList.forEach(function (file) {
          formData.append("file", file);
        });
        this.uploading = true;
        axios({
          url: this.action,
          method: "post",
          data: formData,
          headers: this.headers
        }).then(function (res) {
          _this.reset();
          _this.handleSuccess(res.data);
          _this.uploading = false;
          _this.importState = 1;
        });
      } else {
        this.reset();
        this.handleUploadError();
      }
      return false;
    },
    beforeUpload2: function beforeUpload2(file) {
      var _this2 = this;
      if (this.fileList.length > 0) {
        this.$message("最多只能上传一个");
        return false;
      }
      this.fileList = [].concat(_toConsumableArray(this.fileList), [file]);
      if (this.fileList && this.fileList.length > 0) {
        var fileList = this.fileList;
        var formData = new FormData();
        fileList.forEach(function (file) {
          formData.append("file", file);
        });
        this.uploading = true;
        axios({
          url: this.action2,
          method: "post",
          data: formData,
          headers: this.headers
        }).then(function (res) {
          _this2.reset();
          _this2.handleSuccess(res.data);
          _this2.uploading = false;
          _this2.importState = 2;
        });
      } else {
        this.reset();
        this.handleUploadError();
      }
      return false;
    },
    download: function download(e) {
      var _this3 = this;
      var token = this.$store.state.auth.token;
      if (e === 1) {
        axios({
          url: this.url,
          params: this.params,
          paramsSerializer: function paramsSerializer(param) {
            return qs.stringify(param, {
              indices: false
            });
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: token
          }
        }).then(function (res) {
          var link = document.createElement("a");
          var blob = new Blob([res.data], {
            type: "application/vnd.ms-excel"
          });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", _this3.excelName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      } else if (e === 2) {
        axios({
          url: this.url,
          params: this.params2,
          paramsSerializer: function paramsSerializer(param) {
            return qs.stringify(param, {
              indices: false
            });
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: token
          }
        }).then(function (res) {
          var link = document.createElement("a");
          var blob = new Blob([res.data], {
            type: "application/vnd.ms-excel"
          });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", _this3.excelName2);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      }
    },
    getRegType: function getRegType(regType) {
      if (regType === 1) {
        return "城镇户口";
      } else if (regType === 2) {
        return "农村户口";
      } else if (regType === 3) {
        return "居民户口";
      }
    },
    buCha: function buCha() {
      var token = this.$store.state.auth.token;
      window.open(this.downloadurl + "?token=".concat(token));
    },
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    },
    handleFailClose: function handleFailClose() {
      this.dialogFailVisible = false;
      this.uploading = false;
      this.goback();
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
    },
    showFaild: function showFaild(numAll, numSuc, numFail, Faildata, downloadurl) {
      this.count = numAll;
      this.succ = numSuc;
      this.fial = numFail;
      this.dataDetail = Faildata;
      if (this.count === this.succ) {
        this.dialogFailVisible = true;
      } else {
        this.callback = true;
      }
      this.downloadurl = downloadurl;
      console.log("22222222222");
    },
    getMaritalStatus: function getMaritalStatus(maritalStatus) {
      if (maritalStatus === 1) {
        return "已婚";
      } else if (maritalStatus === 2) {
        return "未婚";
      } else if (maritalStatus === 3) {
        return "离异";
      }
    }
  }
};