export var columns = [{
  title: "姓名",
  dataIndex: "name",
  fixed: "left",
  key: "name",
  colDefault: true,
  ellipsis: true,
  scopedSlots: {
    customRender: "name"
  },
  width: 150
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  colDefault: true,
  width: 150
}, {
  title: "用工单位",
  dataIndex: "companyName",
  key: "companyName",
  colDefault: true,
  width: 150,
  ellipsis: true
}, {
  title: "工作性质",
  dataIndex: "postType",
  ellipsis: true,
  colDefault: true,
  width: 100,
  scopedSlots: {
    customRender: "postType"
  }
}, {
  title: "员工状态",
  dataIndex: "formalType",
  key: "formalType",
  colDefault: true,
  scopedSlots: {
    customRender: "formalType"
  },
  width: 100
}, {
  title: "入职时间",
  dataIndex: "joinDate",
  key: "joinDate",
  colDefault: true,
  scopedSlots: {
    customRender: "joinDate"
  },
  width: 150
}, {
  title: "合同签署状态",
  dataIndex: "contractsStatus",
  key: "contractsStatus",
  colDefault: true,
  scopedSlots: {
    customRender: "contractsStatus"
  },
  width: 150
}, {
  title: "信息补充(必填)",
  dataIndex: "isImprove",
  key: "isImprove",
  scopedSlots: {
    customRender: "isImprove"
  },
  width: 150
}, {
  title: "实名认证",
  dataIndex: "verified",
  colDefault: true,
  scopedSlots: {
    customRender: "verified"
  },
  key: "verified",
  width: 150
}, {
  title: "身份证号",
  dataIndex: "idCard",
  ellipsis: true,
  width: 200
}, {
  title: "性别",
  dataIndex: "gender",
  ellipsis: true,
  width: 150,
  scopedSlots: {
    customRender: "gender"
  }
}, {
  title: "部门",
  dataIndex: "depName",
  ellipsis: true,
  width: 120
}, {
  title: "岗位",
  dataIndex: "positionName",
  ellipsis: true,
  width: 120
}, {
  title: "出生日期",
  dataIndex: "birthday",
  ellipsis: true,
  width: 150,
  scopedSlots: {
    customRender: "birthday"
  }
}, {
  title: "年龄",
  dataIndex: "empAge",
  ellipsis: true,
  width: 150
}, {
  title: "婚姻状况",
  dataIndex: "maritalStatus",
  ellipsis: true,
  width: 150,
  scopedSlots: {
    customRender: "maritalStatus"
  }
}, {
  title: "生育状况",
  dataIndex: "birthStatus",
  key: "birthStatus",
  scopedSlots: {
    customRender: "birthStatus"
  },
  width: 150
}, {
  title: "民族",
  dataIndex: "nation",
  ellipsis: true,
  width: 150
}, {
  title: "政治面貌",
  dataIndex: "politicalStatus",
  ellipsis: true,
  width: 150,
  scopedSlots: {
    customRender: "politicalStatus"
  }
}, {
  title: "户口性质",
  dataIndex: "regType",
  ellipsis: true,
  width: 150,
  scopedSlots: {
    customRender: "regType"
  }
}, {
  title: "户籍地址",
  dataIndex: "nativeLocation",
  ellipsis: true,
  width: 150
}, {
  title: "血型",
  dataIndex: "bloodGroup",
  ellipsis: true,
  width: 150,
  scopedSlots: {
    customRender: "bloodGroup"
  }
}, {
  title: "个人邮箱",
  dataIndex: "email",
  ellipsis: true,
  width: 200
}, {
  title: "现居地址",
  dataIndex: "currentLocation",
  ellipsis: true,
  width: 150
}, {
  title: "学历",
  dataIndex: "highestEducation",
  ellipsis: true,
  width: 150
}, {
  title: "学位",
  dataIndex: "degreeType",
  ellipsis: true,
  width: 150
}, {
  title: "毕业院校",
  dataIndex: "graCollege",
  ellipsis: true,
  width: 150
}, {
  title: "毕业专业",
  dataIndex: "major",
  ellipsis: true,
  width: 150
}, {
  title: "学习形式",
  dataIndex: "teaMethods",
  ellipsis: true,
  width: 150,
  scopedSlots: {
    customRender: "teaMethods"
  }
}, {
  title: "联系人姓名(紧急联系人)",
  dataIndex: "contactsName",
  ellipsis: true,
  width: 150
}, {
  title: "联系电话(紧急联系人)",
  dataIndex: "contactsPhone",
  ellipsis: true,
  width: 150
}, {
  title: "与我的关系(紧急联系人)",
  dataIndex: "contactsRelation",
  ellipsis: true,
  width: 150
}, {
  title: "联系人地址(紧急联系人)",
  dataIndex: "contactsAddress",
  ellipsis: true,
  width: 150
}, {
  title: "工资卡卡号",
  dataIndex: "bankCard",
  ellipsis: true,
  width: 200
}, {
  title: "银行名称",
  dataIndex: "bankName",
  ellipsis: true,
  width: 150
}, {
  title: "工资卡开户城市",
  dataIndex: "bankPlace",
  scopedSlots: {
    customRender: "bankPlace"
  },
  ellipsis: true,
  width: 150
}, {
  title: "工资卡开户支行",
  dataIndex: "bankCardBranch",
  ellipsis: true,
  width: 150
}, {
  title: "个人社保账号",
  dataIndex: "socCard",
  ellipsis: true,
  width: 150
}, {
  title: "个人公积金账号",
  dataIndex: "pfCard",
  ellipsis: true,
  width: 150
}, {
  title: "合同类型",
  dataIndex: "contractType",
  scopedSlots: {
    customRender: "contractType"
  },
  ellipsis: true,
  width: 150
}, {
  title: "合同开始时间",
  dataIndex: "contractStartTime",
  scopedSlots: {
    customRender: "contractStartTime"
  },
  ellipsis: true,
  width: 150
}, {
  title: "合同结束时间",
  dataIndex: "contractEndTime",
  scopedSlots: {
    customRender: "contractEndTime"
  },
  ellipsis: true,
  width: 150
}, {
  title: "合同期限",
  dataIndex: "contactsTerm",
  ellipsis: true,
  width: 150
}, {
  title: "备注",
  dataIndex: "remark",
  ellipsis: true,
  width: 150
}, {
  title: "标签",
  dataIndex: "tag",
  width: 150,
  key: "tag",
  scopedSlots: {
    customRender: "tag"
  }
}, {
  title: "渠道",
  dataIndex: "channelName",
  ellipsis: true,
  width: 150
}, {
  title: "签约主体",
  dataIndex: "contractSubject",
  ellipsis: true,
  width: 150
}, {
  title: "操作",
  fixed: "right",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  },
  width: 80
}];