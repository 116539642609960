import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { costBillInfo, launchApproval, listAllCompanyId, getModelList, selectCompanyByCostBillId, confirmCostBill } from "@/api/billing/billing";
var columns = [];
import moment from "moment";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data: function data() {
    return {
      zongBill: 0,
      tempTemplate: undefined,
      comanyListGroup: [],
      BillConfirm: false,
      isMergConfirmPlan: false,
      templatesList: [],
      templatesListGroup: [],
      item: undefined,
      dataList: [],
      tempList: [],
      columns: columns,
      size: "default",
      loading: false,
      monthly: moment().format("MM"),
      yearly: moment().format("YYYY"),
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null
      },
      companyId: undefined
    };
  },
  created: function created() {
    var _this = this;
    this.item = JSON.parse(this.$route.query.item);
    this.getBillInfo();
    if (this.item.isMerge) {
      listAllCompanyId({
        costBillId: this.item.id
      }).then(function (res) {
        _this.templatesList = res.data;
      });
    }
  },
  computed: _objectSpread({}, mapGetters(["permissions_site"])),
  methods: {
    onBillChange: function onBillChange(value) {
      this.zongBill = value;
      return value;
    },
    BillConfirmOk: function BillConfirmOk() {
      var _this2 = this;
      if (this.item) {
        var param = {
          costBillId: this.item.id,
          finalAmount: this.zongBill
        };
        confirmCostBill(param).then(function (res) {
          _this2.handleCancel();
          if (res.success) {
            _this2.$message.success("账单已结算");
            _this2.item.approStatus = 6;
          }
        });
      }
    },
    handleCompyChange: function handleCompyChange(id) {
      var _this3 = this;
      this.companyId = id;
      this.templatesListGroup = [];
      this.tempTemplate = undefined;
      var type;
      if (this.item.subjectType === "社保账单") {
        type = "socBill";
      } else if (this.item.subjectType === "公积金账单") {
        type = "pfBill";
      } else if (this.item.subjectType === "薪资账单") {
        type = "salaryBill";
      }
      // 根据公司查询模板
      getModelList(id, type).then(function (res) {
        _this3.templatesListGroup = res.data;
        if (_this3.templatesListGroup.length !== 0) {
          _this3.tempTemplate = _this3.templatesListGroup[0].id;
        } else {
          _this3.tempTemplate = undefined;
        }
      });
    },
    handleChange2: function handleChange2(id) {
      // 选择模板
      this.tempTemplate = id;
    },
    mergConfirmOk: function mergConfirmOk() {
      var _this4 = this;
      // 发起审批
      if (!this.companyId) {
        this.$message.success("请选择用工单位");
        return;
      }
      if (!this.tempTemplate) {
        this.$message.success("请选择审批模板");
        return;
      }
      this.isMergConfirmPlan = false;
      launchApproval({
        companyId: this.companyId,
        costBillId: this.item.id,
        flowId: this.tempTemplate
      }).then(function (res) {
        _this4.item.approStatus = res.data.status;
        _this4.$message.success("已发起审批");
      });
    },
    handleCancel: function handleCancel() {
      this.isMergConfirmPlan = false;
      this.BillConfirm = false;
    },
    handleChange: function handleChange(index) {
      this.pagination.current = 1;
      if (index) {
        var count = this.tempList.length;
        var filterList = [];
        for (var i = 0; i < count; i++) {
          if (this.tempList[i]["用工单位"] === this.templatesList[index].name) {
            filterList.push(this.tempList[i]);
          }
        }
        this.dataList = filterList;
      } else {
        this.dataList = this.tempList;
      }
      this.pagination.total = this.dataList.length;
    },
    shengpiHand: function shengpiHand() {
      var _this5 = this;
      this.isMergConfirmPlan = true;
      this.companyId = undefined;
      this.tempTemplate = undefined;
      // 拉取账单的用工单位
      selectCompanyByCostBillId({
        costBillId: this.item.id
      }).then(function (res) {
        _this5.comanyListGroup = res.data;
        if (_this5.comanyListGroup.length !== 0) {
          _this5.companyId = _this5.comanyListGroup[0].id;

          // 拉取模板
          _this5.handleCompyChange(_this5.companyId);
        } else {
          _this5.companyId = undefined;
        }
      });
    },
    getBillInfo: function getBillInfo() {
      var _this6 = this;
      this.loading = true;
      this.columns = [];
      costBillInfo(this.item.id).then(function (res) {
        // console.log(res.data);

        var count = res.data.headList.length;

        // 姓名
        _this6.columns.push({
          title: "员工姓名",
          dataIndex: "员工姓名",
          key: "员工姓名",
          fixed: "left",
          ellipsis: true,
          width: 120
        });
        _this6.columns.push({
          title: "身份证号",
          dataIndex: "身份证号",
          key: "身份证号",
          ellipsis: true,
          width: 170
        });
        for (var i = 0; i < count; i++) {
          if (res.data.headList[i] !== "个人社保合计" && res.data.headList[i] !== "单位社保合计" && res.data.headList[i] !== "个人公积金合计" && res.data.headList[i] !== "单位公积金合计") {
            _this6.columns.push({
              title: res.data.headList[i],
              dataIndex: res.data.headList[i],
              key: res.data.headList[i],
              ellipsis: true,
              width: 120
            });
          }
        }
        if (_this6.item.subjectType === "薪资账单") {
          _this6.columns.push({
            title: "实发工资",
            dataIndex: "实发工资",
            key: "实发工资",
            fixed: "right",
            ellipsis: true,
            width: 130
          });
          // this.columns.push({
          //   title: "个人所得税",
          //   dataIndex: "个人所得税",
          //   key: "个人所得税",
          //   fixed: "right",
          //   ellipsis: true,
          //   width: 130
          // });
        } else if (_this6.item.subjectType === "社保账单") {
          _this6.columns.push({
            title: "个人社保合计",
            dataIndex: "个人社保合计",
            key: "个人社保合计",
            fixed: "right",
            ellipsis: true,
            width: 140
          });
          _this6.columns.push({
            title: "单位社保合计",
            dataIndex: "单位社保合计",
            key: "单位社保合计",
            fixed: "right",
            ellipsis: true,
            width: 140
          });
          _this6.columns.push({
            title: "总缴费",
            dataIndex: "总缴费",
            key: "总缴费",
            fixed: "right",
            ellipsis: true,
            width: 100
          });
        } else if (_this6.item.subjectType === "公积金账单") {
          _this6.columns.push({
            title: "个人公积金合计",
            dataIndex: "个人公积金合计",
            key: "个人公积金合计",
            fixed: "right",
            ellipsis: true,
            width: 140
          });
          _this6.columns.push({
            title: "单位公积金合计",
            dataIndex: "单位公积金合计",
            key: "单位公积金合计",
            fixed: "right",
            ellipsis: true,
            width: 140
          });
          _this6.columns.push({
            title: "总缴费",
            dataIndex: "总缴费",
            key: "总缴费",
            fixed: "right",
            ellipsis: true,
            width: 100
          });
        }
        var datalength = res.data.infoList.length;
        for (var j = 0; j < datalength; j++) {
          var obj = {};
          for (var key in res.data.infoList[j].headDate) {
            obj[key] = res.data.infoList[j].headDate[key] ? res.data.infoList[j].headDate[key] : 0;
          }
          obj["员工姓名"] = res.data.infoList[j].name;
          obj["身份证号"] = res.data.infoList[j].idCard;
          if (_this6.item.subjectType !== "薪资账单") {
            obj["总缴费"] = res.data.infoList[j].totalAmount;
          } else {
            // obj["个人所得税"] = res.data.infoList[j].tax;
            obj["实发工资"] = res.data.infoList[j].totalAmount;
          }
          _this6.dataList.push(obj);
        }
        _this6.tempList = _this6.dataList;
        _this6.pagination.total = _this6.dataList.length;
        _this6.loading = false;
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this6.loading = false;
      });
    },
    getTitle: function getTitle(value, index) {
      switch (value) {
        case "社保账单":
          if (index === 0) {
            return "个人社保";
          } else if (index === 1) {
            return "单位社保";
          } else if (index === 2) {
            return "总缴费";
          } else {
            break;
          }
        case "公积金账单":
          if (index === 0) {
            return "个人公积金";
          } else if (index === 1) {
            return "单位公积金";
          } else if (index === 2) {
            return "总缴费";
          } else {
            break;
          }
        case "薪资账单":
          if (index === 0) {
            return "计薪人数";
          } else if (index === 1) {
            return "总缴税";
          } else if (index === 2) {
            return "总实发工资";
          } else {
            break;
          }
      }
    },
    getCost: function getCost(value, index) {
      switch (value.subjectType) {
        case "社保账单":
          if (index === 0) {
            return value.personalSocAmount;
          } else if (index === 1) {
            return value.companySocAmount;
          } else if (index === 2) {
            return value.totalAmount;
          } else {
            break;
          }
        case "公积金账单":
          if (index === 0) {
            return value.personalPfAmount;
          } else if (index === 1) {
            return value.companyPfAmount;
          } else if (index === 2) {
            return value.totalAmount;
          } else {
            break;
          }
        case "薪资账单":
          if (index === 0) {
            return value.calculateSalaryNumber ? value.calculateSalaryNumber : 0;
          } else if (index === 1) {
            return value.totalTax ? value.totalTax : 0;
          } else if (index === 2) {
            return value.totalAmount ? value.totalAmount : 0;
          } else {
            break;
          }
      }
    },
    goBack: function goBack() {
      this.$router.go(-1);
    },
    download: function download() {
      var _this7 = this;
      var token = this.$store.state.auth.token;
      axios({
        url: "/hr/api/v1/costBills/export/" + this.item.id,
        method: "post",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        if (res.data && res.data.type === "application/json") {
          var reader = new FileReader();
          reader.readAsText(res.data, "utf-8");
          reader.onload = function () {
            var resJson = JSON.parse(reader.result);
            _this7.$message.error(resJson.msg);
          };
        } else {
          var blob = new Blob([res.data], {
            type: "application/octet-stream"
          });
          var link = document.createElement("a");
          try {
            link.download = _this7.item.billName + ".xlsx";
          } catch (err) {
            console.log("debug log --> ", res);
          }
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    inputChange: function inputChange(e) {
      this.pagination.current = 1;
      if (e) {
        var count = this.tempList.length;
        var filterList = [];
        for (var i = 0; i < count; i++) {
          if (this.tempList[i]["员工姓名"] && this.tempList[i]["员工姓名"].indexOf(e) !== -1 || this.tempList[i]["身份证号"] && this.tempList[i]["身份证号"].indexOf(e) !== -1) {
            filterList.push(this.tempList[i]);
          }
        }
        this.dataList = filterList;
      } else {
        this.dataList = this.tempList;
      }
      this.pagination.total = this.dataList.length;
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
    }
  }
};