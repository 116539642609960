import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
var columns = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: 80
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  width: 120
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 180
}];
import { getDepEmpInfo3, getDepEmpInfo4 } from "@/api/company/contract";
import { listByCompanyIdGet } from "@/api/company/contract";
import moment from "moment";
import { addEmpListByIdCards, deleteEmpListByIdCards, increaseSiftEmpCount, decreaseSiftEmpCount } from "./api";
export default {
  props: {
    zengyuan: {
      type: Boolean,
      default: true
    },
    show: {
      type: Boolean,
      default: false
    },
    number: {
      type: Number,
      default: 0
    },
    templateId: {
      type: Number,
      default: 0
    },
    defaultSelectedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isOnlineOrLeave: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      countlist: [],
      form: {},
      columns: columns,
      textContent: "",
      labelCol: {
        span: 7
      },
      dataList: [],
      wrapperCol: {
        span: 16
      },
      labelList: [],
      nameOrPhone: "",
      seniorVisible: false,
      chosenList: [],
      spinning: false,
      mode: "1",
      errorState: false,
      selectedRowKeys: [],
      isAllSelected: false,
      copyVisible: false,
      treeData: [],
      seniorList: [],
      checkStatue: 0,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      },
      selectedListTemp: [],
      selectedKeysBeforeSearch: [],
      querys: "",
      postTypeValue: 3,
      pageSizeOptions: ["10", "20", "50", "100"],
      pageSize: 50,
      current: 1,
      total: 50
    };
  },
  computed: {
    postTypeList: function postTypeList() {
      return this.$store.state.enums.postTypeLst;
    },
    postList: function postList() {
      return this.postTypeList.map(function (item) {
        return {
          value: item.title,
          key: item.value
        };
      });
    }
  },
  watch: {
    textContent: function textContent(e) {
      if (e) {
        this.errorState = false;
      }
    },
    show: function show(val) {
      if (val) {
        this.selectedListTemp = [];
        this.selectedRowKeys = [];
        this.treeData = [];
        this.spinning = false;
        this.current = 1;
        this.postTypeValue = 3;
        this.mode = "1";
        if (this.defaultSelectedKeys.length !== this.selectedListTemp.length || this.defaultSelectedKeys.length === 0) {
          this.getSelectDepTree();
        }
      }
    },
    zengyuan: function zengyuan(val) {
      // 动态监听父类增减员属性变化
      this.getSelectDepTree();
    }
  },
  mounted: function mounted() {
    // this.getSelectDepTree();
  },
  methods: {
    getCount: function getCount(value, value2) {
      for (var i = 0; i < this.countlist.length; i++) {
        if (this.countlist[i].firstType === value) {
          if (!value2) {
            return value + " " + this.countlist[i].firstTypeCount;
          } else {
            for (var p in this.countlist[i].postTypeCount) {
              // 遍历json对象的每个key/value对,p为key
              if (p === value2) {
                return p + " " + this.countlist[i].postTypeCount[p];
              }
            }
            return value2;
          }
        }
      }
      return value;
    },
    reset: function reset() {
      this.textContent = "";
      this.errorState = false;
    },
    getEmpListByIdCards: function getEmpListByIdCards() {
      var _this = this;
      if (this.textContent !== "") {
        var arr = [];
        arr = this.textContent.split(/[\W]/);
        if (this.zengyuan) {
          addEmpListByIdCards(this.$store.state.hr.companyId, arr).then(function (res) {
            if (_this.chosenList.length > 0) {
              _this.chosenList = res.data.empInfoVOList.concat(_this.chosenList);
              _this.chosenList = _this.unique(_this.chosenList);
            } else {
              _this.chosenList = res.data.empInfoVOList;
            }
            _this.textContent = res.data.idCardList.join(",");
            if (res.data.idCardList.length > 0) {
              setTimeout(function () {
                _this.errorState = true;
              });
            }
          });
        } else {
          // if (arr.length > 1) {
          //   // 减员仅允许单选
          //   this.$message.success("减员不支持多选");
          //   return;
          // }
          deleteEmpListByIdCards(this.$store.state.hr.companyId, arr).then(function (res) {
            if (_this.chosenList.length > 0) {
              _this.chosenList = res.data.empInfoVOList.concat(_this.chosenList);
              _this.chosenList = _this.unique(_this.chosenList);
            } else {
              _this.chosenList = res.data.empInfoVOList;
            }
            _this.textContent = res.data.idCardList.join(",");
            if (res.data.idCardList.length > 0) {
              setTimeout(function () {
                _this.errorState = true;
              });
            }
          });
        }
      }
    },
    copyOk: function copyOk() {
      if (this.chosenList.length === 0) {
        this.$message.success("请添加选择人员");
        return;
      }
      this.$emit("update:selectedList", this.chosenList);
      this.$emit("update:selectThree", this.chosenList.slice(0, 3).map(function (item) {
        return item.name;
      }).join(","));
      this.copyVisible = false;
      this.$emit("change", this.chosenList);
    },
    deleteCopyItem: function deleteCopyItem(e) {
      var _this2 = this;
      this.chosenList.forEach(function (item, index) {
        if (item.id === e.id) {
          _this2.chosenList.splice(index, 1);
        }
      });
    },
    deleteSeniorItem: function deleteSeniorItem(e) {
      var _this3 = this;
      this.seniorList.forEach(function (item, index) {
        if (item.id === e.id) {
          _this3.seniorList.splice(index, 1);
          _this3.selectedRowKeys.forEach(function (item2, index2) {
            if (item2 === e.id) {
              _this3.selectedRowKeys.splice(index2, 1);
            }
          });
        }
      });
    },
    getSeniorList: function getSeniorList() {
      var _this4 = this;
      var params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        companyId: this.$store.state.hr.companyId
      };
      if (this.checkStatue === 1) {
        params = {
          // onlyCurrentDep: true,
          // onlyUser: true,
          regType: this.form.regType ? this.form.regType : null,
          postType: this.form.postType ? this.form.postType : null,
          tag: this.form.tag ? this.form.tag : null,
          nameOrPhone: this.form.name ? this.form.name : null,
          joinDateStart: this.form.joinDateStart ? moment(this.form.joinDateStart).format("YYYY-MM-DD ") : null,
          joinDateEnd: this.form.joinDateEnd ? moment(this.form.joinDateEnd).format("YYYY-MM-DD ") : null,
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          companyId: this.$store.state.hr.companyId
        };
        if (this.form.canbaoType) {
          if (this.form.canbaoType === "1") {
            if (this.zengyuan) {
              params["notAll"] = true;
            } else {
              params["haveAll"] = true;
            }
          } else if (this.form.canbaoType === "2") {
            if (this.zengyuan) {
              params["notSoc"] = true;
            } else {
              params["haveSoc"] = true;
            }
          } else if (this.form.canbaoType === "3") {
            if (this.zengyuan) {
              params["notMe"] = true;
            } else {
              params["haveMe"] = true;
            }
          } else if (this.form.canbaoType === "4") {
            if (this.zengyuan) {
              params["notPf"] = true;
            } else {
              params["havePf"] = true;
            }
          }
        }
      }
      if (this.zengyuan) {
        getDepEmpInfo3(params).then(function (res) {
          _this4.spinning = false;
          if (res.data.entities === null) {
            _this4.total = 0;
          } else {
            _this4.pagination.total = res.data && res.data.entityCount;
            _this4.dataList = res.data.entities;
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      } else {
        getDepEmpInfo4(_objectSpread(_objectSpread({}, params), {}, {
          monthly: this.monthly,
          yearly: this.yearly
        })).then(function (res) {
          if (res.data.entities === null) {
            _this4.total = 0;
          } else {
            _this4.pagination.total = res.data && res.data.entityCount;
            _this4.dataList = res.data.entities;
          }
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      }
    },
    getSeniorListCheck: function getSeniorListCheck() {
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.checkStatue = 1;
      this.getSeniorList();
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      // this.checkStatue = 0;
      this.getSeniorList();
    },
    onSelectChange: function onSelectChange(selectedRowKeys, e) {
      var _this5 = this;
      // if (!this.zengyuan) {
      //   // 减员仅允许单选
      //   if (selectedRowKeys.length > 2) {
      //     return;
      //   }
      //   this.selectedRowKeys = [];
      //   const h = selectedRowKeys[selectedRowKeys.length - 1];
      //   this.selectedRowKeys.push(h);
      //   selectedRowKeys = [];
      //   selectedRowKeys.push(h);
      // } else {
      // this.selectedRowKeys = selectedRowKeys;
      // }
      console.log(selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      this.dataList.forEach(function (item) {
        selectedRowKeys.forEach(function (item2) {
          if (item.id === item2) {
            _this5.seniorList.push(item);
            _this5.seniorList = _this5.unique(_this5.seniorList);
          }
        });
      });
      // this.seniorList.forEach((item, index) => {
      //   if (selectedRowKeys.indexOf(item.id) === -1) {
      //     this.seniorList.splice(index, 1);
      //   }
      // });
      this.seniorList.forEach(function (item, index) {
        if (_this5.selectedRowKeys.indexOf(item.id) === -1) {
          item.isSelectedItem = 2;
        }
      });
      var arr = [];
      this.seniorList.forEach(function (item, index) {
        if (!item.isSelectedItem) {
          arr.push(item);
        }
      });
      this.seniorList = JSON.parse(JSON.stringify(arr));
    },
    unique: function unique(arr) {
      var res = new Map();
      return arr.filter(function (arr) {
        return !res.has(arr.id) && res.set(arr.id, 1);
      });
    },
    clearForm: function clearForm() {
      this.form = {};
    },
    showCopy: function showCopy() {
      this.$emit("update:show", false);
      this.copyVisible = true;
      if (this.selectedListTemp.length > 0) {
        this.chosenList = JSON.parse(JSON.stringify(this.selectedListTemp));
      } else {
        this.chosenList = [];
      }
    },
    showSenior: function showSenior() {
      var _this6 = this;
      this.checkStatue = 0;
      this.$emit("update:show", false);
      this.seniorVisible = true;
      this.clearForm();
      this.getSeniorList();
      this.getListByCompanyId();
      if (this.selectedListTemp && this.selectedListTemp.length > 0) {
        this.seniorList = JSON.parse(JSON.stringify(this.selectedListTemp));
        this.selectedListTemp.forEach(function (item, index) {
          _this6.selectedRowKeys.push(item.id);
        });
      } else {
        this.selectedRowKeys = [];
        this.seniorList = [];
      }
    },
    seniorOk: function seniorOk() {
      if (this.seniorList.length === 0) {
        this.$message.success("请添加选择人员");
        return;
      }
      this.$emit("update:selectedList", this.seniorList);
      this.$emit("update:selectThree", this.seniorList.slice(0, 3).map(function (item) {
        return item.name;
      }).join(","));
      this.seniorVisible = false;
      this.$emit("change", this.seniorList);
    },
    seniorCancel: function seniorCancel() {
      this.seniorVisible = false;
      this.selectedRowKeys = [];
    },
    // 分页变化
    onPageChange: function onPageChange(pageNumber) {
      this.current = pageNumber;
      this.getSelectDepTree();
    },
    rangeChange: function rangeChange(e) {
      this.form.joinDateStart = e[0];
      this.form.joinDateEnd = e[1];
    },
    postTypeClick: function postTypeClick(value) {
      this.querys = "";
      this.nameOrPhone = this.query;
      this.postTypeValue = value;
      this.isAllSelected = false;
      this.current = 1;
      this.getSelectDepTree();
    },
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = 1;
      this.getSelectDepTree();
    },
    deleteItem: function deleteItem(item) {
      item.isSelected = false;
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.ergodic(item);
        that.spinning = false;
      }, "10");
    },
    chose: function chose(item, data) {
      // // 减员单选逻辑
      // if (!this.zengyuan && !item.isSelected) {
      //   let id = "";
      //   var index2 = this.treeData.findIndex(it => it.isSelected === true); // 删除之前选择的，维持单选状态
      //   if (index2 !== -1) {
      //     this.treeData[index2].isSelected = false;
      //     id = this.treeData[index2].id;
      //   }

      //   index2 = this.selectedListTemp.findIndex(it => it.id === id); // 删除之前选择的，维持单选状态
      //   if (index2 !== -1) {
      //     this.selectedListTemp.splice(index2, 1); // 删除
      //     this.isAllSelected = false;
      //   }
      // }

      item.isSelected = !item.isSelected;
      this.onChange(item);
    },
    getListByCompanyId: function getListByCompanyId() {
      var _this7 = this;
      listByCompanyIdGet({
        companyId: this.$store.state.hr.companyId
      }).then(function (_ref) {
        var data = _ref.data;
        _this7.labelList = data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    choseAll: function choseAll(data) {
      this.isAllSelected = !this.isAllSelected;
      var that = this;
      this.spinning = true;
      setTimeout(function () {
        for (var j = 0, len = data.length; j < len; j++) {
          data[j].isSelected = that.isAllSelected;
          that.ergodicAll(data[j]);
        }
        that.spinning = false;
      }, "10");
    },
    getAllEmp: function getAllEmp(data) {
      var count = 0;
      for (var j = 0, len = data.length; j < len; j++) {
        if (data[j].childList) {
          count += data[j].childList.length;
        }
      }
      return count;
    },
    onChangeTab: function onChangeTab(e) {
      this.querys = "";
      this.nameOrPhone = this.query;
      this.isAllSelected = false;
      this.current = 1;
      this.mode = e;
      this.getSelectDepTree();
    },
    onChange: function onChange(item) {
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.ergodic(item);
        that.spinning = false;
      }, "10");
    },
    onExpand: function onExpand(item) {
      if (!item.isExpand) {
        //
        this.spinning = true;
      } else {
        this.spinning = false;
      }
    },
    ergodic: function ergodic(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
          this.isAllSelected = false;
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
          var count = this.treeData.length;
          var allSelect = true;
          for (var i = 0; i < count; i++) {
            if (!this.treeData[i].isSelected) {
              allSelect = false;
              break;
            }
          }
          this.isAllSelected = allSelect;
        }
      }
    },
    ergodicAll: function ergodicAll(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
        }
      }
    },
    ergodicList: function ergodicList(data, parent) {
      var list = data.filter(function (item) {
        return item.hasChild;
      });
      if (list.length > 0) {
        for (var i = 0; i < list.length; i++) {
          this.ergodicList(list[i].childList, list[i]);
        }
        if (parent) {
          parent.isSelected = data.length === data.filter(function (item) {
            return item.isSelected;
          }).length;
        }
      } else {
        if (parent) {
          parent.isSelected = data.length === data.filter(function (item) {
            return item.isSelected;
          }).length;
        }
      }
    },
    // 获取部门树
    getSelectDepTree: function getSelectDepTree(query) {
      var _this8 = this;
      if (this.zengyuan) {
        increaseSiftEmpCount(this.$store.state.hr.companyId).then(function (res) {
          _this8.countlist = res.data;
        }).catch(function (err) {
          console.log("debug logdebug log debug log --> ", err);
        });
      } else {
        decreaseSiftEmpCount(this.$store.state.hr.companyId).then(function (res) {
          _this8.countlist = res.data;
        }).catch(function (err) {
          console.log("debug logdebug log debug log --> ", err);
        });
      }
      if (query) {
        this.current = 1;
      }
      var params = {
        companyId: this.$store.state.hr.companyId,
        pageNo: this.current,
        pageSize: this.pageSize,
        postType: this.postTypeValue + ""
      };
      if (this.nameOrPhone) {
        params["nameOrPhone"] = this.nameOrPhone;
      }
      this.spinning = true;
      if (this.zengyuan) {
        // 增员
        if (this.mode === "1") {
          // 全部待增
          params["notAll"] = true;
        } else if (this.mode === "2") {
          // 未参保
          // params["empSocStatus"] = 0;
          params["notSoc"] = true;
        } else if (this.mode === "3") {
          // 停保
          // params["empSocStatus"] = 6;
          params["notMe"] = true;
        } else if (this.mode === "4") {
          // 离职
          // params["empStatus"] = 4;
          params["notPf"] = true;
        }
        getDepEmpInfo3(params).then(function (res) {
          if (res.data.entities === null) {
            _this8.$message.success("暂无数据");
            res.data.entityCount = [];
            _this8.treeData = [];
            _this8.total = 0;
            _this8.isAllSelected = false;
          } else {
            _this8.treeData = _this8.formatCompanyData(res.data.entities);
            _this8.total = res.data.entityCount;
            var count = _this8.treeData.length;
            var allSelect = true;
            if (count !== 0) {
              for (var i = 0; i < count; i++) {
                if (!_this8.treeData[i].isSelected) {
                  allSelect = false;
                  break;
                }
              }
              _this8.isAllSelected = allSelect;
            } else {
              _this8.isAllSelected = false;
            }
          }
          _this8.$nextTick(function () {
            _this8.spinning = false;
          });
        }).catch(function (err) {
          console.log("debug logdebug log debug log --> ", err);
        });
      } else {
        if (this.mode === "1") {
          // 全部待增
          params["haveAll"] = true;
        } else if (this.mode === "2") {
          // 未参保
          params["haveSoc"] = true;
        } else if (this.mode === "3") {
          // 停保
          params["haveMe"] = true;
        } else if (this.mode === "4") {
          // 离职
          params["havePf"] = true;
        }
        // 减员
        getDepEmpInfo4(params).then(function (res) {
          if (res.data.entities === null) {
            _this8.$message.success("暂无数据");
            res.data.entityCount = [];
            _this8.treeData = [];
            _this8.total = 0;
          } else {
            _this8.treeData = _this8.formatCompanyData(res.data.entities);
            _this8.total = res.data.entityCount;
            var count = _this8.treeData.length;
            var allSelect = true;
            for (var i = 0; i < count; i++) {
              if (!_this8.treeData[i].isSelected) {
                allSelect = false;
                break;
              }
            }
            _this8.isAllSelected = allSelect;
          }
          _this8.$nextTick(function () {
            _this8.spinning = false;
          });
        }).catch(function (err) {
          console.log("debug logdebug log debug log --> ", err);
        });
      }
    },
    formatCompanyData: function formatCompanyData(company) {
      var parent = [];
      for (var key = 0; key < company.length; key++) {
        var selected = false;
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === company[key].id;
        }) !== -1) {
          selected = true;
        }
        var emp = {
          id: company[key].id,
          name: company[key].name,
          phone: company[key].phone,
          idCard: company[key].idCard,
          depName: company[key].depName,
          positionName: company[key].positionName,
          isSelected: selected
        };
        parent.push(emp);
      }
      return parent;
    },
    formatEmployeeData: function formatEmployeeData(employee) {
      var emp = {
        id: employee.id,
        name: employee.name,
        phone: employee.phone,
        idCard: employee.idCard,
        depName: employee.depName,
        positionName: employee.positionName,
        isDept: false,
        isSelected: false,
        isExpand: false,
        hasChild: false
      };
      return emp;
    },
    searchPeople: function searchPeople(query) {
      this.querys = query;
      this.nameOrPhone = query;
      this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
        return item.id;
      });
      this.getSelectDepTree(query);
    },
    changePeople: function changePeople(e) {
      this.querys = e.target.value;
      if (e.target.value === "") {
        this.getSelectDepTree();
        this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
          return item.id;
        });
      }
    },
    ok: function ok() {
      if (this.selectedListTemp.length === 0) {
        this.$message.success("请选择人员");
        return;
      }
      this.$emit("update:selectedList", this.selectedListTemp);
      this.$emit("update:selectThree", this.selectedListTemp.slice(0, 3).map(function (item) {
        return item.name;
      }).join(","));
      this.$emit("update:show", false);
      this.$emit("change", this.selectedListTemp);
    },
    cancel: function cancel() {
      this.$emit("update:show", false);
    } // update() {
    //   this.rulusList = [];
    //   salaryCalculateRulesListall().then(res => {
    //     if (res.data.length > 0) {
    //       res.data.map(item => {
    //         if (item.ruleType === 1 || item.ruleType === 3) {
    //           this.rulusList.push(item);
    //         }
    //       });
    //     }
    //   });
    // }
  }
};