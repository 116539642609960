var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: _vm.ws ? "完善企业信息" : _vm.mType ? "新建招工企业" : "编辑招工企业"
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 18
      },
      colon: false
    }
  }, [_c("a-form-item", {
    staticStyle: {
      height: "84px",
      position: "relative"
    },
    attrs: {
      label: "企业logo"
    }
  }, [_c("a-upload", {
    staticClass: "uploads",
    attrs: {
      accept: ".jpg, .jpeg, .png",
      "list-type": "picture-card",
      action: "/file/api/v1/general/uploadImg",
      name: "files",
      "file-list": _vm.fileList,
      beforeUpload: _vm.beforeUpload
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleUpChange
    }
  }, [_vm.fileList.length === 0 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("div", {
    staticClass: "uploadFont"
  }, [_c("div", [_vm._v("点击上传logo")]), _c("div", [_vm._v("支持png, jpg, 大小不超过1m")])]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: function cancel($event) {
        _vm.previewVisible = false;
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })]), _c("a-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["logos", {
        rules: [{
          required: true,
          message: "企业logo不能为空"
        }]
      }],
      expression: "['logos', { rules: [{ required: true, message: '企业logo不能为空' }] }]"
    }]
  })], 1), _c("a-form-item", {
    attrs: {
      label: "企业全称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "企业全称不能为空"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '企业全称不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入",
      maxLength: 50
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "联系人姓名"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["legalPerson", {
        rules: [{
          required: true,
          message: "联系人姓名不能为空"
        }]
      }],
      expression: "['legalPerson', { rules: [{ required: true, message: '联系人姓名不能为空' }] }]"
    }],
    attrs: {
      maxLength: 50,
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "联系电话"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyTelephone", {
        rules: [{
          required: true,
          message: "联系电话不能为空"
        }]
      }],
      expression: "['companyTelephone', { rules: [{ required: true, message: '联系电话不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "联系邮箱"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyEmail", {
        rules: [{
          type: "email",
          message: "请输入正确邮箱"
        }]
      }],
      expression: "[\n          'companyEmail',\n          {\n            rules: [\n              {\n                type: 'email',\n                message: '请输入正确邮箱'\n              }\n            ]\n          }\n        ]"
    }],
    attrs: {
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "企业地址"
    }
  }, [_c("city", {
    attrs: {
      "default-value": _vm.socCity,
      level: 2
    },
    on: {
      change: function change($event) {
        return _vm.handleCity($event);
      }
    }
  }), _c("a-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["socCity", {
        rules: [{
          required: true,
          message: "地区不能为空"
        }]
      }],
      expression: "['socCity', { rules: [{ required: true, message: '地区不能为空' }] }]"
    }]
  })], 1), _c("a-form-item", {
    staticClass: "dnone",
    attrs: {
      label: " "
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["address", {
        rules: [{
          required: true,
          message: "详细地址不能为空"
        }]
      }],
      expression: "['address', { rules: [{ required: true, message: '详细地址不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入详细地址"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "企业性质"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["properties"],
      expression: "['properties']"
    }],
    attrs: {
      placeholder: "请选择"
    }
  }, _vm._l(_vm.propertiesArr, function (item) {
    return _c("a-select-option", {
      key: item,
      attrs: {
        value: item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "企业行业"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyIndustryFirst"],
      expression: "['companyIndustryFirst']"
    }],
    attrs: {
      placeholder: "请选择"
    }
  }, _vm._l(_vm.companyIndustryFirstArr, function (item) {
    return _c("a-select-option", {
      key: item,
      attrs: {
        value: item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "企业规模"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["personSize"],
      expression: "['personSize']"
    }],
    attrs: {
      placeholder: "请选择"
    }
  }, _vm._l(_vm.personSizeArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.v) + " ")]);
  }), 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };