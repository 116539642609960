import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ph48"
  }, [_c("div", {
    staticClass: "flex-center-between mt16"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入工资项"
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  }), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onAdd
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 添加自定义工资项 ")], 1)], 1), _c("div", {
    staticClass: "mt16"
  }, [_c("a-table", {
    attrs: {
      scroll: {
        x: true,
        y: true
      },
      dataSource: _vm.dataSourceNewFiltered,
      columns: _vm.columns,
      loading: _vm.loading,
      rowKey: "id",
      size: "middle"
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(text) + " "), record.remarks ? _c("a-tooltip", {
          attrs: {
            title: record.remarks
          }
        }, [_c("a-icon", {
          staticClass: "ml4",
          attrs: {
            type: "question-circle",
            theme: "filled"
          }
        })], 1) : _vm._e(), record.canUpdate ? _c("a-icon", {
          staticClass: "ml12 pointer",
          staticStyle: {
            color: "var(--primary)"
          },
          attrs: {
            type: "edit"
          },
          on: {
            click: function click($event) {
              return _vm.onEdit(record);
            }
          }
        }) : _vm._e()];
      }
    }, {
      key: "isOpen",
      fn: function fn(text, record) {
        return [_c("a-checkbox", {
          attrs: {
            disabled: !record.canClose,
            checked: text
          },
          on: {
            change: function change($event) {
              return _vm.onOpenChange($event, record.id);
            }
          }
        })];
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("a-space", {
          attrs: {
            size: 12
          }
        }, [record.canDelete ? _c("a-popconfirm", {
          attrs: {
            title: "\u786E\u8BA4\u5220\u9664".concat(record.name, "\u9879\u5417\uFF0C\u6240\u6709\u7528\u5DE5\u5355\u4F4D\u4E2D\u672A\u5F52\u6863\u7684\u6708\u5DE5\u8D44\u8868\u4E2D\u8BE5\u9879\u5C06\u88AB\u540C\u6B65\u5220\u9664\u5E76\u6E05\u7A7A\u6570\u636E")
          },
          on: {
            confirm: function confirm($event) {
              return _vm.doDelete(record.id);
            }
          }
        }, [_c("span", {
          staticClass: "pointer tc-theme"
        }, [_vm._v("删除")])]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c("div", {
    staticClass: "mt16 ta-center pb24"
  }, [_c("a-space", {
    attrs: {
      size: 24
    }
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        return _vm.$emit("cancel");
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("next");
      }
    }
  }, [_vm._v("下一步")])], 1)], 1), _c("a-modal", {
    attrs: {
      title: _vm.modalTitle
    },
    on: {
      cancel: _vm.doCancel,
      ok: _vm.doOk
    },
    model: {
      value: _vm.modalVisible,
      callback: function callback($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_vm.modalVisible === "添加自定义工资项" ? _c("div", {
    staticClass: "fs12 tc-l3 mb16"
  }, [_vm._v(" 添加自定义工资项将同步至所有用工单位，默认不选入工资表模板中，如需使用，请在对应的工资表末班中进行选择 ")]) : _c("div", {
    staticClass: "fs12 tc-l3 mb16"
  }, [_vm._v("编辑自定义工资像名称，将同步到所有用工单位")]), _c("a-form-item", {
    attrs: {
      label: "工资项名称",
      required: "",
      wrapperCol: {
        span: 18
      },
      labelCol: {
        span: 6
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };