export default {
  fields: {
    reason: {
      model: "reason"
    },
    name: {
      model: "name"
    },
    licenseNumber: {
      model: "licenseNumber"
    },
    status: {
      model: "status"
    },
    employmentType: {
      model: "employmentType"
    },
    employmentSituation: {
      model: "employmentSituation"
    },
    phone: {
      model: "phone"
    },
    employedDate: {
      model: "employedDate"
    },
    resignDate: {
      model: "resignDate"
    },
    isDisable: {
      model: "isDisable"
    },
    isMartyr: {
      model: "isMartyr"
    },
    isOld: {
      model: "isOld"
    },
    disableCard: {
      model: "disableCard"
    },
    martyrCard: {
      model: "martyrCard"
    },
    isDeductDeductible: {
      model: "isDeductDeductible"
    },
    totalPersonalInvestment: {
      model: "totalPersonalInvestment"
    },
    personalInvestmentRatio: {
      model: "personalInvestmentRatio"
    },
    domicileProvince: {
      model: "domicileProvince"
    },
    domicileCity: {
      model: "domicileCity"
    },
    domicileArea: {
      model: "domicileArea"
    },
    domicileDetail: {
      model: "domicileDetail"
    },
    habitualResidenceProvince: {
      model: "habitualResidenceProvince"
    },
    habitualResidenceCity: {
      model: "habitualResidenceCity"
    },
    habitualResidenceArea: {
      model: "habitualResidenceArea"
    },
    habitualResidenceDetail: {
      model: "habitualResidenceDetail"
    },
    contactAddressProvince: {
      model: "contactAddressProvince"
    },
    contactAddressCity: {
      model: "contactAddressCity"
    },
    contactAddressArea: {
      model: "contactAddressArea"
    },
    contactAddressDetail: {
      model: "contactAddressDetail"
    },
    email: {
      model: "email"
    },
    education: {
      model: "education"
    },
    bank: {
      model: "bank"
    },
    bankCard: {
      model: "bankCard"
    },
    bankProvince: {
      model: "bankProvince"
    },
    position: {
      model: "position"
    },
    isDeductDeductibleStr: {
      model: "isDeductDeductibleStr"
    },
    statusStr: {
      model: "statusStr"
    },
    isOldStr: {
      model: "isOldStr"
    },
    isMartyrStr: {
      model: "isMartyrStr"
    },
    isDisableStr: {
      model: "isDisableStr"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "reason",
        title: "错误信息",
        ellipsis: true
      }, {
        model: "name",
        title: "姓名"
      }, {
        model: "licenseNumber",
        title: "证件号码"
      }, {
        model: "statusStr",
        title: "人员状态"
      }, {
        model: "employmentType",
        title: "任职受雇从业类型"
      }, {
        model: "employmentSituation",
        title: "入职年度就业情形"
      }, {
        model: "phone",
        title: "手机号码"
      }, {
        model: "employedDate",
        title: "任职受雇从业日期"
      }, {
        model: "resignDate",
        title: "离职日期"
      }, {
        model: "isDisableStr",
        title: "是否残疾"
      }, {
        model: "isMartyrStr",
        title: "是否烈属"
      }, {
        model: "isOldStr",
        title: "是否孤老"
      }, {
        model: "disableCard",
        title: "残疾证号"
      }, {
        model: "martyrCard",
        title: "烈属证号"
      }, {
        model: "isDeductDeductibleStr",
        title: "是否扣除减除费用"
      }, {
        model: "totalPersonalInvestment",
        title: "个人投资额"
      }, {
        model: "personalInvestmentRatio",
        title: "个人投资比例(%)"
      }, {
        model: "domicileProvince",
        title: "户籍所在地（省）"
      }, {
        model: "domicileCity",
        title: "户籍所在地（市）"
      }, {
        model: "domicileArea",
        title: "户籍所在地（区县）"
      }, {
        model: "domicileDetail",
        title: "户籍所在地（详细地址）"
      }, {
        model: "habitualResidenceProvince",
        title: "经常居住地（省）"
      }, {
        model: "habitualResidenceCity",
        title: "经常居住地（市）"
      }, {
        model: "habitualResidenceArea",
        title: "经常居住地（区县）"
      }, {
        model: "habitualResidenceDetail",
        title: "经常居住地（详细地址）"
      }, {
        model: "contactAddressProvince",
        title: "联系地址（省）"
      }, {
        model: "contactAddressCity",
        title: "联系地址（市）"
      }, {
        model: "contactAddressArea",
        title: "联系地址（区县）"
      }, {
        model: "contactAddressDetail",
        title: "联系地址（详细地址）"
      }, {
        model: "email",
        title: "电子邮箱"
      }, {
        model: "education",
        title: "学历"
      }, {
        model: "bank",
        title: "开户银行"
      }, {
        model: "bankCard",
        title: "银行账号"
      }, {
        model: "bankProvince",
        title: "开户行省份"
      }, {
        model: "position",
        title: "职务"
      }],
      customize: {
        rowKey: "id"
        // pagination: {
        //   pageSize: 10
        // }
      },

      extend: {
        batch: false,
        action: false
      }
    }
  }
};