import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "info"
  }, [_c("a-form-item", {
    staticStyle: {
      width: "350px",
      "margin-right": "60px"
    },
    attrs: {
      label: "模板名称",
      required: "",
      wrapperCol: {
        span: 18
      },
      labelCol: {
        span: 6
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      width: "350px"
    },
    attrs: {
      label: "事件类型",
      required: "",
      wrapperCol: {
        span: 18
      },
      labelCol: {
        span: 6
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      options: _vm.eventTypeLst,
      placeholder: "请选择"
    },
    model: {
      value: _vm.eventType,
      callback: function callback($$v) {
        _vm.eventType = $$v;
      },
      expression: "eventType"
    }
  })], 1), _c("a-popconfirm", {
    on: {
      confirm: _vm.addEventType
    }
  }, [_c("div", {
    staticClass: "btn"
  }, [_c("a-icon", {
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v(" 自定义类型 ")], 1), _c("span", {
    attrs: {
      slot: "icon"
    },
    slot: "icon"
  }), _c("a-form-item", {
    attrs: {
      slot: "title",
      required: "",
      label: "添加事件类型"
    },
    slot: "title"
  }, [_c("a-input", {
    model: {
      value: _vm.eventTypeNew,
      callback: function callback($$v) {
        _vm.eventTypeNew = $$v;
      },
      expression: "eventTypeNew"
    }
  })], 1)], 1)], 1), _c("a-divider", {
    attrs: {
      dashed: ""
    }
  }), _vm.empFields && _vm.empFields.length > 0 ? _c("div", [_vm._m(0), _c("TemplateConfigTable", {
    attrs: {
      dataSource: _vm.empFields,
      editIcon: false,
      deleteIcon: false,
      addIcon: false
    },
    on: {
      editOk: _vm.editEmpField,
      sortItem: _vm.sortEmpField
    }
  })], 1) : _vm._e(), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._m(1), _c("TemplateConfigTable", {
    attrs: {
      dataSource: _vm.eventFields
    },
    on: {
      editOk: _vm.editEventField,
      delItem: _vm.deleteEventField,
      sortItem: _vm.sortEventField
    }
  })], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_c("span", [_vm._v("员工个人信息")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_c("span", [_vm._v("事件概况")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };