import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-drawer", {
    attrs: {
      title: _vm.title,
      placement: _vm.placement,
      width: "580px",
      visible: _vm.visible
    },
    on: {
      close: _vm.onClose
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "name",
    attrs: {
      label: "规则名称",
      prop: "name",
      rules: {
        required: true,
        message: "请输入"
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticClass: "shu"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("具体规则")])])]), _c("div", [_c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      checked: _vm.checked3
    },
    on: {
      change: _vm.onChange3
    }
  }, [_vm._v(" 渠道分润 ")])], 1), _vm.checked3 ? _c("a-radio-group", {
    staticStyle: {
      "margin-top": "20px"
    },
    on: {
      change: _vm.tabsChange
    },
    model: {
      value: _vm.ruleType,
      callback: function callback($$v) {
        _vm.ruleType = $$v;
      },
      expression: "ruleType"
    }
  }, [_c("a-radio-button", {
    staticStyle: {
      width: "104px",
      "text-align": "center"
    },
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 工时分润 ")]), _c("a-radio-button", {
    staticStyle: {
      width: "104px",
      "text-align": "center"
    },
    attrs: {
      value: "4"
    }
  }, [_vm._v(" 按天分润 ")]), _c("a-radio-button", {
    staticStyle: {
      width: "104px",
      "text-align": "center"
    },
    attrs: {
      value: "5"
    }
  }, [_vm._v(" 比例分润 ")])], 1) : _vm._e(), _vm.checked3 ? _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "margin-bottom": "20px"
    }
  }, [_vm.ruleType === "5" ? _c("div", [_c("a-input-number", {
    staticStyle: {
      width: "88px",
      "margin-left": "5px"
    },
    attrs: {
      min: 0,
      max: 100,
      step: 0.01,
      precision: 2
    },
    model: {
      value: _vm.form.commissionRatio,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "commissionRatio", $$v);
      },
      expression: "form.commissionRatio"
    }
  }), _vm._v(" %工人实发工资 ")], 1) : _c("div", [_vm._v(" 工价 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 0
    },
    model: {
      value: _vm.form.hourAmountDouble,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "hourAmountDouble", $$v);
      },
      expression: "form.hourAmountDouble"
    }
  }), _vm._v(" " + _vm._s(_vm.ruleType === "1" ? "元/小时" : "元/天") + " ")], 1), _vm.ruleType !== "5" ? _c("div", {
    staticStyle: {
      "margin-left": "20px"
    }
  }, [_c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuClick2
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "2"
  }, [_vm._v(" " + _vm._s(_vm.ruleType === "1" ? "按月/天/工时变更工时抽成" : "按月/天变更抽成") + " ")]), _c("a-menu-item", {
    key: "1"
  }, [_vm._v(_vm._s(_vm.ruleType === "1" ? "固定日期变更工时抽成" : "固定日期变更抽成") + " ")])], 1), _c("a-button", {
    attrs: {
      type: "primary",
      ghost: ""
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 新增变更条件 ")], 1)], 1)], 1) : _vm._e()]), _vm._l(_vm.changeList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "10px",
        display: "flex",
        "align-items": "center"
      }
    }, [item.type === "2" ? _c("div", [_vm._v(" 入职满 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 0
      },
      model: {
        value: item.number,
        callback: function callback($$v) {
          _vm.$set(item, "number", $$v);
        },
        expression: "item.number"
      }
    }), _c("a-select", {
      staticStyle: {
        width: "108px",
        margin: "0 5px"
      },
      model: {
        value: item.units,
        callback: function callback($$v) {
          _vm.$set(item, "units", $$v);
        },
        expression: "item.units"
      }
    }, _vm._l(_vm.option, function (item, index) {
      return _c("a-select-option", {
        key: index,
        attrs: {
          value: item.value
        }
      }, [_vm._v(" " + _vm._s(item.label) + " ")]);
    }), 1), _c("span", [_vm._v(" 抽成变为 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.newHourAmount,
        callback: function callback($$v) {
          _vm.$set(item, "newHourAmount", $$v);
        },
        expression: "item.newHourAmount"
      }
    }), _vm._v(" " + _vm._s(_vm.ruleType === "1" ? "元/小时" : "元/天") + " ")], 1)], 1) : _vm._e(), item.type === "1" ? _c("div", [_vm._v(" 自 "), _c("a-date-picker", {
      staticStyle: {
        margin: "0 5px"
      },
      model: {
        value: item.time,
        callback: function callback($$v) {
          _vm.$set(item, "time", $$v);
        },
        expression: "item.time"
      }
    }), _vm._v(" 抽成变为 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.newHourAmount,
        callback: function callback($$v) {
          _vm.$set(item, "newHourAmount", $$v);
        },
        expression: "item.newHourAmount"
      }
    }), _vm._v(" " + _vm._s(_vm.ruleType === "1" ? "元/小时" : "元/天") + " ")], 1) : _vm._e(), _c("a-icon", {
      staticClass: "deleteIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.deleteChangeList(item, index);
        }
      }
    })], 1);
  })], 2) : _vm._e()], 1), _c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      checked: _vm.checked
    },
    on: {
      change: _vm.onChange
    }
  }, [_vm._v(" 一次性红包 ")]), _vm._l(_vm.numList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "10px"
      }
    }, [_vm._v(" 满 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 0
      },
      model: {
        value: item.number,
        callback: function callback($$v) {
          _vm.$set(item, "number", $$v);
        },
        expression: "item.number"
      }
    }), _c("a-select", {
      staticStyle: {
        width: "108px",
        margin: "0 5px"
      },
      model: {
        value: item.units,
        callback: function callback($$v) {
          _vm.$set(item, "units", $$v);
        },
        expression: "item.units"
      }
    }, _vm._l(_vm.option, function (item, index) {
      return _c("a-select-option", {
        key: index,
        attrs: {
          value: item.value
        }
      }, [_vm._v(" " + _vm._s(item.label) + " ")]);
    }), 1), _vm._v(" 发放 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.redPacketAmount,
        callback: function callback($$v) {
          _vm.$set(item, "redPacketAmount", $$v);
        },
        expression: "item.redPacketAmount"
      }
    }), _vm._v(" 元红包 "), _c("a-icon", {
      staticClass: "deleteIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.deleteNumList(item, index);
        }
      }
    }), _c("a-icon", {
      staticClass: "addIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "plus-circle"
      },
      on: {
        click: _vm.createNumList
      }
    })], 1);
  })], 2), _c("div", [_c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      checked: _vm.checked1
    },
    on: {
      change: _vm.onChange1
    }
  }, [_vm._v(" 离职倒扣 ")])], 1), _vm.checked1 ? _c("div", {
    staticStyle: {
      margin: "15px 0"
    }
  }, [_c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuClick
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "1"
  }, [_vm._v(" " + _vm._s(_vm.ruleType === "1" ? "不满月/天/工时倒扣工资" : "不满月/天倒扣工资") + " ")]), _c("a-menu-item", {
    key: "2"
  }, [_vm._v(" " + _vm._s(_vm.ruleType === "1" ? "不满月/天/工时减少工价" : "不满月/天减少工价") + " ")])], 1), _c("a-button", {
    attrs: {
      type: "primary",
      ghost: ""
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 新增倒扣条件 ")], 1)], 1)], 1) : _vm._e(), _vm._l(_vm.leaveList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "10px"
      }
    }, [_vm._v(" 不满 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 0
      },
      model: {
        value: item.number,
        callback: function callback($$v) {
          _vm.$set(item, "number", $$v);
        },
        expression: "item.number"
      }
    }), _c("a-select", {
      staticStyle: {
        width: "108px",
        margin: "0 5px"
      },
      model: {
        value: item.units,
        callback: function callback($$v) {
          _vm.$set(item, "units", $$v);
        },
        expression: "item.units"
      }
    }, _vm._l(_vm.option, function (item, index) {
      return _c("a-select-option", {
        key: index,
        attrs: {
          value: item.value
        }
      }, [_vm._v(" " + _vm._s(item.label) + " ")]);
    }), 1), item.type === "1" ? _c("span", [_vm._v(" 倒扣 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.deductAmount,
        callback: function callback($$v) {
          _vm.$set(item, "deductAmount", $$v);
        },
        expression: "item.deductAmount"
      }
    }), _vm._v(" 元工资 ")], 1) : _vm._e(), item.type === "2" ? _c("span", [_vm._v(" 工价变为 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.newHourAmount,
        callback: function callback($$v) {
          _vm.$set(item, "newHourAmount", $$v);
        },
        expression: "item.newHourAmount"
      }
    }), _vm._v(" " + _vm._s(_vm.ruleType === "1" ? "元/小时" : "元/天") + " ")], 1) : _vm._e(), _c("a-icon", {
      staticClass: "deleteIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.deleteLeaveList(item, index);
        }
      }
    })], 1);
  })], 2), _c("div", [_c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      checked: _vm.checked2
    },
    on: {
      change: _vm.onChange2
    }
  }, [_vm._v(" 其他费用 ")])], 1), _vm.checked2 ? _c("div", {
    staticStyle: {
      "margin-top": "20px",
      "margin-bottom": "40px"
    }
  }, [_c("span", [_vm._v(" 只要工人在职，额外结算 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 0,
      step: 0.01,
      precision: 2
    },
    model: {
      value: _vm.form.specialCost,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "specialCost", $$v);
      },
      expression: "form.specialCost"
    }
  }), _vm._v(" 元/每月 ")], 1)]) : _vm._e()]), _c("div", {
    staticClass: "drawer-footer-box"
  }, [_c("a-button", {
    style: {
      marginRight: "12px"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    },
    on: {
      click: _vm.handleAddOK
    }
  }, [_vm._v("确定")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };