var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "change_wages_set"
  }, [_c("div", [_c("div", {
    staticClass: "set flex space_around"
  }, [_vm._v(" 员工转正自动调薪 "), _c("a-switch", {
    attrs: {
      defaultChecked: ""
    },
    on: {
      change: _vm.onChange
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };