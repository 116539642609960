import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { Check, CheckWork } from "@/api/check";
import { Wages, WagesSet, Common } from "@/api/wages";
export default {
  props: {
    allOptions: {
      type: Object,
      default: function _default() {
        return {
          show: true,
          obj: {}
        };
      }
    }
  },
  data: function data() {
    return {
      treeData: [],
      /* 岗位 */
      posList: [],
      /* 岗位树 */
      replaceFieldsPos: {
        children: "positionChildList",
        title: "name"
      },
      /* tree替换 */
      replaceFields: {
        children: "childList",
        title: "depName"
      },
      visible2: false,
      visible3: false,
      /* 选择部门表 */
      deps: [],
      /* 选择员工表 */
      users: [],
      depList: [],
      // 部门列表
      userList: [],
      // 员工列表
      radioOptions: [{
        label: "税前",
        value: 1
      }, {
        label: "税后",
        value: 2
      }],
      add: false,
      del: false,
      visible: false,
      childrenDrawer: false,
      /* 修改/新增 detail */
      editorData: {
        companyId: this.$store.state.hr.companyId,
        applyToProgVO: {
          companyId: this.$store.state.hr.companyId,
          applyToProgDepVOList: [],
          // 适用部门
          applyToProgLevelVOList: [],
          // 适用职级
          applyToProgPositionVOList: [],
          // 适用岗位
          busProgId: "",
          // 业务方案ID
          busProgType: "",
          // 业务方案类型
          employType: [],
          employRange: [],
          // 适用范围（1：按部门，2：按职级，3：按岗位）
          gmtCreate: "",
          // 创建日期
          gmtModified: "",
          // 修改日期
          id: "",
          // 主键ID
          mutex: false
        },
        attendanceRange: true,
        // 适用出勤范围 是true,否false
        attendanceRangeDays: 1,
        // 出勤范围天数
        gmtCreate: "",
        // 创建日期
        gmtModified: "",
        // 修改日期
        id: "",
        // 主键ID
        name: "",
        // 方案名称
        remark: "",
        // 备注
        subsidyInfoDOList: [{
          companyId: this.$store.state.hr.companyId,
          amount: "",
          // 津贴金额
          createUserId: "",
          gmtCreate: null,
          // 创建日期
          gmtModified: null,
          // 修改日期
          id: "",
          // 主键ID
          modifiedUserId: "",
          name: "",
          // 津贴名称
          progId: "",
          // 津贴方案id
          siteId: "",
          type: 1,
          // 津贴类型1 每日出勤2每计薪日3每月固定4(每月按出勤日)5(每月按计薪日)
          valid: true
        }],
        // 员工津贴配置信息集合
        taxMethod: 1 // 适用计税方式（1：税前，2：税后）
      }
    };
  },

  watch: {
    allOptions: {
      handler: function handler(val) {
        this.visible = val.show;
        if (val.obj.editorId) {
          this.getDetail();
          this.showDepPeo();
        }
      }
    }
  },
  computed: {
    day: function day() {
      var box = [];
      var i = 1;
      while (i < 32) {
        box.push({
          key: i,
          value: "".concat(i, "\u65E5")
        });
        i++;
      }
      return box;
    }
  },
  methods: {
    /* 展示岗位 */showGangwei: function showGangwei() {
      var _this = this;
      Check({
        url: Common.posList,
        params: {
          companyId: this.$store.state.hr.companyId
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.posList = data;
      });
    },
    /* 选择岗位 */onSelectPos: function onSelectPos(selectedKeys, info) {
      var _info$node$dataRef = info.node.dataRef,
        positionId = _info$node$dataRef.id,
        positionName = _info$node$dataRef.name;
      var isNew = this.editorData.applyToProgVO.applyToProgPositionVOList.find(function (val) {
        return val.positionId === positionId;
      });
      if (isNew) {
        return this.$message.error("岗位存在,不可重复!!!");
      } else {
        this.editorData.applyToProgVO.applyToProgPositionVOList.push({
          positionId: positionId,
          progId: "",
          companyId: this.$store.state.hr.companyId,
          positionName: positionName
        });
      }
    },
    /* 获取津贴详情 */getDetail: function getDetail() {
      var _this2 = this;
      Wages({
        url: WagesSet.detailItem + this.allOptions.obj.editorId
      }).then(function (_ref2) {
        var data = _ref2.data;
        data.applyToProgVO.employType = data.applyToProgVO.employType.split(",").filter(function (item) {
          return item.trim();
        });
        data.applyToProgVO.employRange = data.applyToProgVO.employRange.split(",").filter(function (item) {
          return item.trim();
        });
        _this2.editorData = data;
      });
    },
    /* 展示部门员工 */showDepPeo: function showDepPeo() {
      var _this3 = this;
      Check({
        url: Common.depTreeList,
        params: {
          companyId: this.$store.state.hr.companyId
        }
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this3.treeData = data;
      });
    },
    /* 展示岗位员工 */showDep: function showDep() {},
    /* 选择部门员工 */onSelect: function onSelect(selectedKeys, info) {
      /* this.visible2 = false */
      var _info$node$dataRef2 = info.node.dataRef,
        empName = _info$node$dataRef2.depName,
        depId = _info$node$dataRef2.depId;
      var isNew2 = this.editorData.applyToProgVO.applyToProgDepVOList.find(function (val) {
        return val.depId === depId;
      });
      if (isNew2) {
        if (info.node.dataRef.empInfoDOList && info.node.dataRef.empInfoDOList.length) {
          this.users = info.node.dataRef.empInfoDOList;
          this.childrenDrawer = true;
          this.visible2 = false;
        } else {
          this.$notification["warning"]({
            message: "部门成员为空!",
            description: "该部门下还没有成员,请先添加部门成员!!!"
          });
          /*  return this.$message.error('部门或成员存在,不可重复!!!'); */
        }
      } else {
        this.editorData.applyToProgVO.applyToProgDepVOList.push({
          depId: depId,
          empName: empName,
          companyId: this.$store.state.hr.companyId,
          progId: "",
          type: 1
        });
      }
    },
    /* 选择岗位 */onSelectDep: function onSelectDep(selectedKeys, info) {},
    /* 选择部门员工 */choseDep: function choseDep(env, val) {
      this.childrenDrawer = true;
      var dep = {
        depId: env,
        progId: "",
        type: 2,
        name: val[0]
      };
      if (this.deps.find(function (item) {
        return item.depId === env;
      })) {
        return;
      } else {
        this.deps.push(dep);
      }
      this.users = this.getDepUsers(this.depList, env);
    },
    /* 取消部门 */delDep: function delDep(i) {
      this.deps.splice(i, 1);
    },
    /* 取消选择员工 */delUser: function delUser(i) {
      this.userList.splice(i, 1);
    },
    /* 选择员工 */onChangeUser: function onChangeUser(checkedValue, value) {
      var _this4 = this;
      this.userList = checkedValue.map(function (item, i) {
        var obj = {
          companyId: _this4.$store.state.hr.computed,
          empName: "\u5458\u5DE5".concat(i + 1),
          depId: item,
          gmtCreate: "",
          id: "",
          progId: "",
          type: 2
        };
        return obj;
      });
    },
    /* 获取部门下的员工 */getDepUsers: function getDepUsers(data, depId) {
      var _this5 = this;
      if (data.length) {
        return data.find(function (item) {
          if (item.childList) {
            _this5.getDepUsers(item.childList);
          }
          return item.depId === depId;
        });
      }
    },
    popupScroll: function popupScroll() {},
    /* 处理部门需要色数据 */deepLoop: function deepLoop(data) {
      var _this6 = this;
      var needData = _toConsumableArray(data);
      var d = [];
      needData.map(function (val) {
        var title = val.depName,
          key = val.depId,
          value = val.depId,
          children = val.childList;
        val.title = title;
        val.value = value;
        val.key = key;
        val.children = children;
        if (val.childList) {
          _this6.deepLoop(val.childList);
        }
        d.push(val);
      });
      return d;
    },
    /* 获取部们列表 */getDepList: function getDepList() {
      var _this7 = this;
      Wages({
        url: Common.depTreeList
      }).then(function (_ref4) {
        var data = _ref4.data;
        if (data) _this7.depList = _this7.deepLoop(data);
      });
    },
    // 选择 删除添加
    delItem: function delItem(item, arrs) {
      arrs.find(function (value, i, arr) {
        if (value.depId === item.depId) return arr.splice(i, 1);
      });
    },
    /* 删除岗位 */delItemGw: function delItemGw(item, arrs) {
      arrs.find(function (value, i, arr) {
        if (value.positionId === item.positionId) return arr.splice(i, 1);
      });
    },
    // 交税方式
    // 关闭添加员工
    handleOk: function handleOk(e) {
      var copy = JSON.parse(JSON.stringify(this.editorData.applyToProgVO.applyToProgDepVOList));
      var dep = [].concat(_toConsumableArray(copy), _toConsumableArray(this.userList));
      var obj = {};
      dep = dep.reduce(function (item, next) {
        obj[next.depId] ? "" : obj[next.depId] = true && item.push(next);
        return item;
      }, []);
      this.editorData.applyToProgVO.applyToProgDepVOList = dep;
      this.childrenDrawer = false;
      this.userList = [];
    },
    handleCancel: function handleCancel() {},
    // 添加员工
    showChildrenDrawer: function showChildrenDrawer() {
      this.childrenDrawer = true;
    },
    /* 保存津贴设置 */editor: function editor() {
      var _this8 = this;
      if (this.allOptions.obj.editorId) {
        // put
        var copyData = JSON.parse(JSON.stringify(this.editorData));
        copyData.applyToProgUpdateDTO = copyData.applyToProgVO;
        copyData.applyToProgUpdateDTO.applyToProgDepAddDTOList = copyData.applyToProgVO.applyToProgDepVOList;
        copyData.applyToProgUpdateDTO.applyToProgLevelAddDTOList = copyData.applyToProgVO.applyToProgLevelVOList;
        copyData.applyToProgUpdateDTO.applyToProgPositionAddDTOList = copyData.applyToProgVO.applyToProgPositionVOList;
        copyData.companyId = this.$store.state.hr.companyId;
        copyData.applyToProgUpdateDTO.companyId = this.$store.state.hr.companyId;
        copyData.subsidyInfoDOList.forEach(function (item) {
          item.companyId = _this8.$store.state.hr.companyId;
        });
        copyData.applyToProgUpdateDTO.applyToProgDepAddDTOList.forEach(function (item) {
          item.companyId = _this8.$store.state.hr.companyId;
        });
        copyData.applyToProgUpdateDTO.applyToProgLevelAddDTOList.forEach(function (item) {
          item.companyId = _this8.$store.state.hr.companyId;
        });
        copyData.applyToProgUpdateDTO.applyToProgPositionAddDTOList.forEach(function (item) {
          item.companyId = _this8.$store.state.hr.companyId;
        });
        copyData.applyToProgUpdateDTO.employType = String(copyData.applyToProgUpdateDTO.employType);
        copyData.applyToProgUpdateDTO.employRange = String(copyData.applyToProgUpdateDTO.employRange);
        delete copyData.applyToProgVO;
        delete copyData.applyToProgUpdateDTO.applyToProgDepVOList;
        delete copyData.applyToProgUpdateDTO.applyToProgLevelVOList;
        delete copyData.applyToProgUpdateDTO.applyToProgPositionVOList;
        Wages({
          url: WagesSet.putAllowance,
          method: "PUT",
          data: copyData
        }).then(function (data) {
          if (data.success) {
            _this8.$notification["success"]({
              message: "成功",
              description: "修改成功"
            });
            _this8.$emit("cbFun", true);
            _this8.visible = false;
          }
        });
      } else {
        // post
        var _copyData = JSON.parse(JSON.stringify(this.editorData));
        _copyData.applyToProgAddDTO = _copyData.applyToProgVO;
        _copyData.applyToProgAddDTO.applyToProgDepAddDTOList = _copyData.applyToProgVO.applyToProgDepVOList;
        _copyData.applyToProgAddDTO.applyToProgLevelAddDTOList = _copyData.applyToProgVO.applyToProgLevelVOList;
        _copyData.applyToProgAddDTO.applyToProgPositionAddDTOList = _copyData.applyToProgVO.applyToProgPositionVOList;
        _copyData.applyToProgAddDTO.employType = String(_copyData.applyToProgAddDTO.employType);
        _copyData.applyToProgAddDTO.employRange = String(_copyData.applyToProgAddDTO.employRange);
        delete _copyData.applyToProgVO;
        delete _copyData.applyToProgAddDTO.applyToProgDepVOList;
        delete _copyData.applyToProgAddDTO.applyToProgLevelVOList;
        delete _copyData.applyToProgAddDTO.applyToProgPositionVOList;
        Wages({
          url: WagesSet.postAllowance,
          method: "POST",
          data: _copyData
        }).then(function (data) {
          if (data.success) {
            _this8.$notification["success"]({
              message: "成功",
              description: "新增成功"
            });
            _this8.$emit("cbFun", true);
            _this8.visible = false;
          }
        });
      }
    }
  }
};