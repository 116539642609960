import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
import { getRecordInfos, deleteRecord, addRecordRecords, getRecordRecords as _getRecordRecords, getAllUser, settingsResponsible } from "@/api/shebao/shebaoInfo.js";
import { formatDate as _formatDate, afterTime } from "@/utils/index";
import selectCompanyGroup from "@/components/SelectCompany";
import { mapState } from "vuex";
import moment from "moment";
import DraggableTableCell, { headerRow } from "@/components/DraggableTableCell";
import draggable from "vuedraggable";
import Sortable from "sortablejs";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
import { renderPoliticalStatus } from "@/views/staff/management/employeeFuncs";
var mixin = new ExportMixin();
var formalType = ["试用", "正式"];
var recordColumns = [{
  title: "月份",
  dataIndex: "month",
  key: "month",
  scopedSlots: {
    customRender: "month"
  }
}, {
  title: "服务费",
  dataIndex: "serviceCost",
  key: "serviceCost"
}, {
  title: "社保费",
  dataIndex: "socAmount",
  key: "socAmount"
}, {
  title: "应收款",
  dataIndex: "totalAmount",
  key: "totalAmount"
}, {
  title: "回款",
  dataIndex: "realityAmount",
  key: "realityAmount"
}, {
  title: "回款途径",
  dataIndex: "wayStatus",
  key: "wayStatus",
  scopedSlots: {
    customRender: "wayStatus"
  }
}, {
  title: "余额",
  dataIndex: "amount",
  key: "amount"
}, {
  title: "操作人",
  dataIndex: "createUserName",
  key: "createUserName",
  scopedSlots: {
    customRender: "createUserName"
  }
}, {
  title: "操作时间",
  dataIndex: "gmtCreate",
  key: "gmtCreate",
  scopedSlots: {
    customRender: "gmtCreate"
  }
}];
var initSelectedHead = [{
  title: "姓名",
  width: 120,
  dataIndex: "name",
  key: "name",
  fixed: "left"
}, {
  title: "手机号",
  width: 180,
  dataIndex: "phone",
  key: "phone"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 200
}, {
  title: "负责人",
  dataIndex: "empResponsibleName",
  key: "empResponsibleName",
  width: 180
}, {
  title: "用工单位",
  dataIndex: "companyName",
  key: "companyName",
  width: 150
}, {
  title: "服务费",
  dataIndex: "serviceCost",
  key: "serviceCost",
  width: 120
}, {
  title: "社保费",
  dataIndex: "socAmount",
  key: "socAmount",
  width: 120
}, {
  title: "应收款",
  dataIndex: "totalAmount",
  key: "totalAmount",
  width: 120
}, {
  title: "本月实收",
  dataIndex: "realityAmount",
  key: "realityAmount",
  width: 120
}, {
  title: "未回款金额",
  dataIndex: "surplusAmount",
  key: "surplusAmount",
  width: 120,
  scopedSlots: {
    customRender: "surplusAmount"
  }
}, {
  title: "回款状态",
  dataIndex: "status",
  key: "status",
  width: 120,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "余额",
  dataIndex: "remainAmount",
  key: "remainAmount",
  width: 120
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark",
  width: 120
}, {
  title: "滞纳金",
  dataIndex: "overdueFine",
  key: "overdueFine",
  width: 120
}];
var initNoSelectedHead = [];
var initColumns = [{
  title: "姓名",
  width: 120,
  dataIndex: "name",
  key: "name",
  fixed: "left"
}, {
  title: "手机号",
  width: 180,
  dataIndex: "phone",
  key: "phone"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 200
}, {
  title: "负责人",
  dataIndex: "empResponsibleName",
  key: "empResponsibleName",
  width: 180
}, {
  title: "用工单位",
  dataIndex: "companyName",
  key: "companyName",
  width: 150
}, {
  title: "服务费",
  dataIndex: "serviceCost",
  key: "serviceCost",
  width: 120
}, {
  title: "社保费",
  dataIndex: "socAmount",
  key: "socAmount",
  width: 120
}, {
  title: "应收款",
  dataIndex: "totalAmount",
  key: "totalAmount",
  width: 120
}, {
  title: "本月实收",
  dataIndex: "realityAmount",
  key: "realityAmount",
  width: 120
}, {
  title: "未回款金额",
  dataIndex: "surplusAmount",
  key: "surplusAmount",
  width: 120,
  scopedSlots: {
    customRender: "surplusAmount"
  }
}, {
  title: "回款状态",
  dataIndex: "status",
  key: "status",
  width: 120,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "余额",
  dataIndex: "remainAmount",
  key: "remainAmount",
  width: 120
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark",
  width: 120
}, {
  title: "滞纳金",
  dataIndex: "overdueFine",
  key: "overdueFine",
  width: 120
}, {
  title: "操作",
  dataIndex: "action",
  fixed: "right",
  width: 220,
  scopedSlots: {
    customRender: "action"
  },
  align: "center"
}];
var columns = [{
  title: "姓名",
  width: 120,
  dataIndex: "name",
  key: "name",
  fixed: "left"
}, {
  title: "手机号",
  width: 180,
  dataIndex: "phone",
  key: "phone"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 200
}, {
  title: "负责人",
  dataIndex: "empResponsibleName",
  key: "empResponsibleName",
  width: 180
}, {
  title: "用工单位",
  dataIndex: "companyName",
  key: "companyName",
  width: 150
}, {
  title: "服务费",
  dataIndex: "serviceCost",
  key: "serviceCost",
  width: 120
}, {
  title: "社保费",
  dataIndex: "socAmount",
  key: "socAmount",
  width: 120
}, {
  title: "应收款",
  dataIndex: "totalAmount",
  key: "totalAmount",
  width: 120
}, {
  title: "本月实收",
  dataIndex: "realityAmount",
  key: "realityAmount",
  width: 120
}, {
  title: "未回款金额",
  dataIndex: "surplusAmount",
  key: "surplusAmount",
  width: 120,
  scopedSlots: {
    customRender: "surplusAmount"
  }
}, {
  title: "回款状态",
  dataIndex: "status",
  key: "status",
  width: 120,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "余额",
  dataIndex: "remainAmount",
  key: "remainAmount",
  width: 120
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark",
  width: 120
}, {
  title: "滞纳金",
  dataIndex: "overdueFine",
  key: "overdueFine",
  width: 120
}, {
  title: "操作",
  dataIndex: "action",
  fixed: "right",
  width: 220,
  scopedSlots: {
    customRender: "action"
  },
  align: "center"
}];
var draggableTableCell = new DraggableTableCell(columns);
import { mapGetters } from "vuex";
export default {
  mixins: [mixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    selectCompanyGroup: selectCompanyGroup,
    // eslint-disable-next-line vue/no-unused-components
    draggable: draggable
  },
  data: function data() {
    this.components = {
      header: {
        cell: draggableTableCell
      }
    };
    return {
      selectTreeId: "-1",
      siteId: undefined,
      siteName: undefined,
      treeSiteName: undefined,
      nowDate: sessionStorage.getItem("nowDate") ? sessionStorage.getItem("nowDate") : moment(moment().format("YYYY-MM"), "YYYY-MM"),
      columns: columns,
      initColumns: initColumns,
      selectedRowKeys: [],
      initSelectedHead: initSelectedHead,
      initNoSelectedHead: initNoSelectedHead,
      // 合同状态
      contractsStatus: [{
        id: 0,
        name: "合同未发起"
      }, {
        id: 1,
        name: "待员工签署"
      }, {
        id: 2,
        name: "待公司签署"
      }, {
        id: 3,
        name: "签署完成"
      }],
      headVisible: false,
      tempSelectedHead: [],
      tempNoSelectedHead: [],
      setUserVisible: false,
      dataSource: [],
      popoverVisible: false,
      drag: false,
      surplusAmountMax: null,
      surplusAmountMin: null,
      empResponsibleName: null,
      planStatus: undefined,
      recordColumns: recordColumns,
      remainAmount: 0,
      loading: false,
      actionVisible: false,
      recordVisible: false,
      dataList: [],
      dataList2: [],
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      actionForm: {},
      empName: "",
      nameOrPhoneOrIdCard: null,
      empId: null,
      formalType: formalType,
      selectedRecord: {},
      selectedRowKeys2: [],
      isBatch: false,
      wayStatusList: [{
        key: "1",
        name: "银行"
      }, {
        key: "2",
        name: "微信"
      }, {
        key: "3",
        name: "支付宝"
      }, {
        key: "4",
        name: "其他"
      }, {
        key: "5",
        name: "余额扣款"
      }],
      selectedHead: [],
      pagination2: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        editCompanyName: null,
        ticketRecord: null,
        receivableRecord: null
      },
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        editCompanyName: null,
        ticketRecord: null,
        receivableRecord: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      }
    };
  },
  watch: {
    siteId: function siteId(newValue) {
      this.getRecordInfosList();
    },
    selectedHead: {
      handler: function handler(val) {
        var _this = this;
        var h = this.$createElement;
        var _loop = function _loop(index2) {
          var cols = val.filter(function (item) {
            return item.title === _this.initColumns[index2].title;
          });
          if (cols.length <= 0) {
            // 不显示的列
            if (_this.initColumns[index2]["dataIndex"] === "action") {
              // 右边的操作需要显示出来
              _this.columns[index2] = Object.assign(_this.columns[index2], _this.initColumns[index2], {
                customRender: function customRender(text, record) {
                  return h("div", [h("a", {
                    "style": {
                      color: "var(--primary)"
                    },
                    "on": {
                      "click": function click() {
                        return _this.showAction(record);
                      }
                    }
                  }, ["\u56DE\u6B3E"]), h("a-divider", {
                    "attrs": {
                      "type": "vertical"
                    }
                  }), h("a", {
                    "style": {
                      color: "var(--primary)"
                    },
                    "on": {
                      "click": function click() {
                        return _this.showRecord(record);
                      }
                    }
                  }, ["\u56DE\u6B3E\u8BB0\u5F55"]), h("a-divider", {
                    "attrs": {
                      "type": "vertical"
                    }
                  }), h("a-dropdown", [h("a-menu", {
                    "slot": "overlay",
                    "on": {
                      "click": function click(e) {
                        return _this.handleMenuClick(e, record);
                      }
                    }
                  }, [h("a-menu-item", {
                    "key": "1"
                  }, ["\u8BBE\u7F6E\u8D1F\u8D23\u4EBA"]), h("a-menu-item", {
                    "key": "2"
                  }, [h("div", {
                    "on": {
                      "click": function click() {
                        return _this.deleteItem(record);
                      }
                    }
                  }, ["\u5220\u9664"])])]), h("a-button", {
                    "attrs": {
                      "type": "link"
                    }
                  }, [h("a-icon", {
                    "attrs": {
                      "type": "ellipsis"
                    }
                  })])])]);
                }
              });
            } else {
              // 将不显示的表头宽度设置为0
              _this.columns[index2] = Object.assign(_this.columns[index2], _this.initColumns[index2], {
                width: 0,
                customRender: function customRender() {
                  return null;
                }
              });
            }
          } else {
            // 需要显示的列
            _this.columns[index2] = Object.assign(_this.columns[index2], _this.initColumns[index2], {
              customRender: function customRender(text, record) {
                switch (_this.columns[index2]["dataIndex"]) {
                  case "surplusAmount":
                    return h("span", {
                      "style": "display:flex;align-items: center; color: #fa5051;"
                    }, [record.surplusAmount]);
                  case "status":
                    return h("span", {
                      "style": "display:flex;align-items: center;"
                    }, [h("div", {
                      "style": " border-radius:50%;height:4px;width:4px;background:#A0A0A0"
                    }), h("span", {
                      "style": "margin-left:5px"
                    }, [record.status === 1 ? "未回款" : record.status === 2 ? "部分回款" : "已回款"])]);
                  case "joinDate":
                    return h("span", [record.joinDate != null ? _this.formatDate(record.joinDate) : ""]);
                  case "postType":
                    return h("span", [record.postType != null ? _this.$store.state.enums.postTypeLst[record.postType - 1] : ""]);
                  case "contractType":
                    return h("span", [_this.getContractType(record.contractType)]);
                  case "teaMethods":
                    return h("span", [_this.getTeaMethod(record.teaMethods)]);
                  case "bloodGroup":
                    return h("span", [_this.getBloodGroup(record.bloodGroup)]);
                  case "regType":
                    return h("span", [_this.getRegType(record.regType)]);
                  case "politicalStatus":
                    return h("span", [renderPoliticalStatus(record.politicalStatus)]);
                  case "birthStatus":
                    return h("span", [record.birthStatus != null ? record.birthStatus === 1 ? "已育" : "未生育" : ""]);
                  case "maritalStatus":
                    return h("span", [_this.getMaritalStatus(record.maritalStatus)]);
                  case "birthday":
                    return h("span", [record.birthday != null ? record.birthday.split(" ")[0] : ""]);
                  case "gender":
                    return h("span", [record.gender != null ? record.gender === 1 ? "男" : "女" : ""]);
                  case "contractStartTime":
                    return h("span", [record.contractStartTime != null ? _this.formatDate(record.contractStartTime) : ""]);
                  case "contractEndTime":
                    return h("span", [record.contractEndTime != null ? _this.formatDate(record.contractEndTime) : ""]);
                  case "formalType":
                    return h("span", [_this.formalType[Number(text - 1)]]);
                  case "contractsStatus":
                    return h("span", [record.contractsStatus != null ? _this.getConstractsStatusType(Number(record.contractsStatus)) : ""]);
                  case "isImprove":
                    return h("div", {
                      "class": "pointer"
                    }, [record.isImprove === true && h("span", {
                      "class": record.isImprove ? "tc-theme" : "colorC0C0C0"
                    }, ["\u2022"]), record.isImprove === true && h("span", ["\u5DF2\u5B8C\u5584"]), h("Popover", {
                      "attrs": {
                        "trigger": "click",
                        "arrowPointAtCenter": true,
                        "content": function content() {
                          return h("div", {
                            "style": "width: 200px"
                          }, [h("span", {
                            "style": "color: red"
                          }, [record.needComplete]), h("span", ["\u4FE1\u606F\u672A\u5B8C\u5584"])]);
                        }
                      }
                    }, [record.isImprove === false && h("span", {
                      "class": record.isImprove ? "tc-theme" : "colorC0C0C0"
                    }, ["\u2022"]), record.isImprove === false && h("span", ["\u672A\u5B8C\u5584"])])]);
                  case "verified":
                    return h("div", {
                      "class": "pointer"
                    }, [h("span", {
                      "class": record.verified ? "tc-theme" : "colorC0C0C0"
                    }, ["\u2022"]), record.verified ? "已认证" : "未认证"]);
                  case "tag":
                    if (record.tag) {
                      var arr = record.tag.split(",");
                      return h("Popover", {
                        "attrs": {
                          "placement": "topLeft"
                        }
                      }, [h("template", {
                        "slot": "content"
                      }, [h("div", {
                        "class": "tags-tit"
                      }, ["\u5458\u5DE5\u6807\u7B7E:"]), h("div", {
                        "class": "tags-boxs"
                      }, [arr.map(function (r) {
                        return h("p", {
                          "class": "tags-item"
                        }, [r]);
                      })])]), h("div", {
                        "class": "tags"
                      }, [arr[0]]), arr.length > 1 && h("span", {
                        "class": "tags-d"
                      }, ["..."])]);
                    } else {
                      return h("span", [record.tag]);
                    }
                  default:
                    return h("span", [text]);
                }
              }
            });
          }
        };
        for (var index2 = 0; index2 < this.initColumns.length; index2++) {
          _loop(index2);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    // 获取缓存的数据
    this.selectTreeId = this.SelectedId;
    this.siteName = this.SelectedName;
    this.siteId = this.SelectedAllIds;
    var temp = localStorage.getItem("stafg");
    if (temp) {
      temp = JSON.parse(temp);
    } else {
      temp = {};
      temp.selectedHead = JSON.parse(JSON.stringify(this.initSelectedHead));
      temp.noSelectedHead = JSON.parse(JSON.stringify(this.initNoSelectedHead));
    }
    this.selectedHead = JSON.parse(JSON.stringify(temp.selectedHead));
    this.noSelectedHead = JSON.parse(JSON.stringify(temp.noSelectedHead));
    this.tempSelectedHead = JSON.parse(JSON.stringify(temp.selectedHead));
    this.tempNoSelectedHead = JSON.parse(JSON.stringify(temp.noSelectedHead));
    for (var i = 0; i < this.selectedHead.length; i++) {
      for (var j = 0; j < this.columns.length; j++) {
        if (this.columns[j].title === this.selectedHead[i].title) {
          this.columns[j] = this.columns.splice(i, 1, this.columns[j])[0];
          this.initColumns[j] = this.initColumns.splice(i, 1, this.initColumns[j])[0];
          break;
        }
      }
    }

    // this.getRecordInfosList();
  },

  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(["common"])), mapGetters({
    SelectedId: "site/SelectedId",
    SelectedName: "site/SelectedName",
    SelectedAllIds: "site/SelectedAllIds"
  })), {}, {
    cityList: function cityList() {
      return this.common.cityList;
    },
    areaList: function areaList() {
      return this.common.areaList;
    }
  }),
  mounted: function mounted() {
    this.initSortable();
    this.getUserRolesList();
  },
  methods: {
    upLoad: function upLoad() {
      this.$router.push({
        path: "socialSecurityPlan/upLoad",
        query: {
          companyIds: !this.siteId || this.siteId === "undefined" || this.siteId === "-1" ? null : this.siteId,
          year: moment(this.nowDate).format("YYYY"),
          month: moment(this.nowDate).format("MM")
        }
      });
    },
    download: function download(i) {
      this.exportData = {
        channelId: this.channelId ? this.channelId : undefined,
        companyIds: !this.siteId || this.siteId === "undefined" || this.siteId === "-1" ? null : this.siteId,
        year: moment(this.nowDate).format("YYYY"),
        month: moment(this.nowDate).format("MM"),
        idList: this.selectedRowKeys.length ? this.selectedRowKeys : undefined,
        nameOrPhoneOrIdCard: this.nameOrPhoneOrIdCard ? this.nameOrPhoneOrIdCard : undefined,
        status: this.planStatus ? this.planStatus : undefined,
        empResponsibleName: this.empResponsibleName ? this.empResponsibleName : undefined,
        surplusAmountMax: this.surplusAmountMax ? this.surplusAmountMax : undefined,
        surplusAmountMin: this.surplusAmountMin ? this.surplusAmountMin : undefined
      };
      this.url = "/hr/api/v1/empSocBackRecordInfos/exportRecordInfo";
      this.excelName = "".concat(this.siteName, "_") + "社保回款计划" + ".xlsx";
      this.postDownload();
    },
    userChange: function userChange(e) {
      this.empResponsibleId = e;
    },
    onSelectChange: function onSelectChange(selectedRowKeys, record) {
      var arr = [];
      record.forEach(function (item) {
        arr.push(item.empId);
      });
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowKeys2 = arr;
    },
    getUserRolesList: function getUserRolesList() {
      var _this2 = this;
      getAllUser().then(function (res) {
        _this2.dataSource = res.data;
      });
    },
    headerRow: headerRow,
    initSortable: function initSortable() {
      new Sortable(document.getElementById("sortable_list"), {
        onEnd: this.onDragEnd
      });
      this.selectedHead = JSON.parse(JSON.stringify(this.tempSelectedHead));
      this.noSelectedHead = JSON.parse(JSON.stringify(this.tempNoSelectedHead));
      var temp = {};
      temp.selectedHead = JSON.parse(JSON.stringify(this.selectedHead));
      temp.noSelectedHead = JSON.parse(JSON.stringify(this.noSelectedHead));
      localStorage.setItem("stafg", JSON.stringify(temp));
    },
    onDragEnd: function onDragEnd(evt) {
      var _this3 = this;
      var newIndex = evt.newIndex;
      var oldIndex = evt.oldIndex;
      var columnsTemp = _toConsumableArray(this.tempSelectedHead);
      var columnsMap = {};
      var initArr = [];
      for (var i = 0; i < columnsTemp.length; i++) {
        columnsMap[i] = columnsTemp[i];
        initArr.push(i);
      }
      initArr.splice(oldIndex, 1);
      initArr.splice(newIndex, 0, oldIndex);
      var columnsTempNew = initArr.map(function (idx) {
        return columnsMap[idx];
      });
      this.tempSelectedHead = columnsTempNew;
      this.components = {
        header: {
          cell: DraggableTableCell(this.tempSelectedHead)
        }
      };
      this.showTable = false;
      this.$nextTick(function () {
        _this3.showTable = true;
        _this3.$nextTick(function () {
          _this3.initSortable();
        });
      });
    },
    getStatus: function getStatus(record) {
      var id = "";
      for (var i = 0; i < this.waiList.length; i++) {
        if (this.waiList[i].empInfoVO.id === record.id) {
          return this.waiList[i].status === 1 ? "未回款" : this.waiList[i].status === 2 ? "部分回款" : "已回款";
        }
      }
    },
    handleUserOk: function handleUserOk() {
      var _this4 = this;
      settingsResponsible({
        empIdList: this.isBatch ? this.selectedRowKeys2 : [this.selectedRecord.empId],
        responsibleId: this.empResponsibleId
      }).then(function (res) {
        _this4.setUserVisible = false;
        _this4.selectedRowKeys = [];
        _this4.selectedRowKeys2 = [];
        _this4.$notification["success"]({
          message: "success",
          description: "设置成功"
        });
        _this4.getRecordInfosList();
      }).catch(function (err) {
        console.log(err);
      });
    },
    getConstractsStatusType: function getConstractsStatusType(text) {
      return this.contractsStatus[text].name;
    },
    getMaritalStatus: function getMaritalStatus(maritalStatus) {
      if (maritalStatus === 1) {
        return "已婚";
      } else if (maritalStatus === 2) {
        return "未婚";
      } else if (maritalStatus === 3) {
        return "离异";
      }
    },
    getRegType: function getRegType(regType) {
      if (regType === 1) {
        return "城镇户口";
      } else if (regType === 2) {
        return "农村户口";
      } else if (regType === 3) {
        return "居民户口";
      }
    },
    getBloodGroup: function getBloodGroup(bloodGroup) {
      if (bloodGroup === 1) {
        return "A";
      } else if (bloodGroup === 2) {
        return "B";
      } else if (bloodGroup === 3) {
        return "AB";
      } else if (bloodGroup === 4) {
        return "O";
      } else if (bloodGroup === 5) {
        return "其它";
      }
    },
    getTeaMethod: function getTeaMethod(teaMethods) {
      if (teaMethods === "1") {
        return "全日制";
      } else if (teaMethods === "2") {
        return "成人教育";
      } else if (teaMethods === "3") {
        return "远程教育";
      } else if (teaMethods === "4") {
        return "自学考试";
      } else if (teaMethods === "5") {
        return "其他非全日制";
      }
    },
    getContractType: function getContractType(contractType) {
      if (contractType === "laborContractLimit") {
        return "固定期限劳动合同";
      } else if (contractType === "laborContractUnlimit") {
        return "无固定期限劳动合同";
      } else if (contractType === "laborContractJobs") {
        return "以完成一定工作任务为期限的劳动合同";
      } else if (contractType === "internAccords") {
        return "实习协议";
      } else if (contractType === "laborContract") {
        return "劳务合同";
      } else if (contractType === "reemployAccords") {
        return "返聘协议";
      } else if (contractType === "laborContractDispatch") {
        return "劳务派遣合同";
      } else if (contractType === "borrowedContract") {
        return "借调合同";
      } else if (contractType === "engagementLetter") {
        return "约定书";
      } else if (contractType === "partTime") {
        return "兼职合同";
      } else if (contractType === "noviciateAgreement") {
        return "见习协议";
      } else if (contractType === "dimissionContract") {
        return "离职合同";
      } else if (contractType === "retireReEmploy") {
        return "退休返聘合同";
      } else if (contractType === "other") {
        return "其它";
      }
    },
    formatDate: function formatDate(text) {
      return _formatDate(text);
    },
    // 开始拖拽事件
    onStart: function onStart() {
      this.drag = true;
    },
    onEnd: function onEnd() {
      this.drag = false;
    },
    // 禁止拖动到id为1的对象
    onMove: function onMove(e) {
      if (e.relatedContext.element.title === "姓名") return false;
      return true;
    },
    handleHeadChange: function handleHeadChange(visible) {
      if (visible === false) {
        this.tempSelectedHead = JSON.parse(JSON.stringify(this.selectedHead));
        this.tempNoSelectedHead = JSON.parse(JSON.stringify(this.noSelectedHead));
      }
    },
    addSelectedHead: function addSelectedHead(index) {
      var temp = JSON.parse(JSON.stringify(this.tempNoSelectedHead[index]));
      this.tempNoSelectedHead.splice(index, 1);
      this.tempSelectedHead.push(temp);
    },
    deleteSelectedHead: function deleteSelectedHead(e, index) {
      e.preventDefault();
      var temp = JSON.parse(JSON.stringify(this.tempSelectedHead[index]));
      this.tempSelectedHead.splice(index, 1);
      this.tempNoSelectedHead.push(temp);
    },
    handleHeadSearch: function handleHeadSearch() {
      this.selectedHead = JSON.parse(JSON.stringify(this.tempSelectedHead));
      this.noSelectedHead = JSON.parse(JSON.stringify(this.tempNoSelectedHead));
      var temp = {};
      temp.selectedHead = JSON.parse(JSON.stringify(this.selectedHead));
      temp.noSelectedHead = JSON.parse(JSON.stringify(this.noSelectedHead));
      localStorage.setItem("stafg", JSON.stringify(temp));
      this.headVisible = false;
      for (var i = 0; i < this.selectedHead.length; i++) {
        for (var j = 0; j < this.columns.length; j++) {
          if (this.columns[j].title === this.selectedHead[i].title) {
            this.columns[j] = this.columns.splice(i, 1, this.columns[j])[0];
            this.initColumns[j] = this.initColumns.splice(i, 1, this.initColumns[j])[0];
            break;
          }
        }
      }
    },
    handleHeadCancel: function handleHeadCancel() {
      this.headVisible = false;
      this.tempSelectedHead = JSON.parse(JSON.stringify(this.selectedHead));
      this.tempNoSelectedHead = JSON.parse(JSON.stringify(this.noSelectedHead));
    },
    // 分页器变化
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getRecordInfosList();
    },
    handleTableChange2: function handleTableChange2(pagination, filters, sorter) {
      this.pagination2.current = pagination.current;
      this.pagination2.pageSize = pagination.pageSize;
      this.getRecordRecords();
    },
    // 月份选择
    monthChange: function monthChange(e) {
      this.nowDate = e;
      sessionStorage.setItem("nowDate", this.nowDate);
      this.getRecordInfosList();
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    // 查询信息
    getRecordInfosList: function getRecordInfosList() {
      var _this5 = this;
      this.loading = true;
      getRecordInfos({
        companyIds: !this.siteId || this.siteId === "undefined" || this.siteId === "-1" ? null : this.siteId,
        "orders[0].property": "status",
        "orders[1].property": "gmtCreate",
        "orders[0].desc": false,
        "orders[1].desc": true,
        year: moment(this.nowDate).format("YYYY"),
        month: moment(this.nowDate).format("MM"),
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        nameOrPhoneOrIdCard: this.nameOrPhoneOrIdCard ? this.nameOrPhoneOrIdCard : undefined,
        status: this.planStatus ? this.planStatus : undefined,
        empResponsibleName: this.empResponsibleName ? this.empResponsibleName : undefined,
        surplusAmountMax: this.surplusAmountMax ? this.surplusAmountMax : undefined,
        surplusAmountMin: this.surplusAmountMin ? this.surplusAmountMin : undefined
      }).then(function (res) {
        // let state = false;
        _this5.loading = false;
        _this5.pagination.total = res.data && res.data.entityCount;
        _this5.waiList = JSON.parse(JSON.stringify(res.data.entities));
        res.data.entities.forEach(function (element) {
          if (element.empInfoVO) {
            for (var key in element.empInfoVO) {
              element[key] = element.empInfoVO[key];
            }
            if (element.bankPlace) {
              var values = _this5.buildValue(_this5.getLastAreaId(element.bankPlace));
              var labels = values.map(function (item) {
                return item.label;
              });
              element.bankPlace = labels.join("-");
            }
          }
        });
        _this5.dataList = res.data.entities;
        if (res.data.entities.length === 0 && res.data.pageNo > 1) {
          // 当本页非第一页（比第一页大）时，删除最后一条后台会返回当页页码，
          // 但是本页数据为空，table组件会展示为当前页的上一页数据为空，需要加加判断再拉一下上页数据
          _this5.pagination.current = res.data.pageNo - 1;
          _this5.pagination.pageSize = 10;
          _this5.getRecordInfosList();
        }
        // this.dataList.forEach((item, index) => {
        //   if (item.overdueFine !== 0 && item.overdueFine !== null) {
        //     state = true;
        //   }
        // });
        // if (state === true) {
        //   this.columns.splice(this.columns.length - 5, 0, {
        //     title: "滞纳金",
        //     dataIndex: "overdueFine",
        //     key: "overdueFine",
        //     width: 120
        //   });
        // }
        // this.dataList.forEach((item, index) => {
        //   this.dataList[index].remaining =
        //     item.totalAmount - item.realityAmount >= 0
        //       ? item.totalAmount - item.realityAmount
        //       : 0;
        //    });
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this5.loading = false;
      });
    },
    handleMenuClick: function handleMenuClick(e, record) {
      this.getUserRolesList();
      this.selectedRecord = record;
      if (this.selectedRecord.empResponsibleId === null) {
        this.selectedRecord.empResponsibleId = undefined;
      }
      if (e.key === "1") {
        this.setUserVisible = true;
        this.isBatch = false;
      }
    },
    barchSetUser: function barchSetUser() {
      this.getUserRolesList();
      this.setUserVisible = true;
      this.isBatch = true;
      this.selectedRecord = {};
    },
    buildValue: function buildValue(id) {
      var needRecursion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      id = parseInt(id);
      var index = this.common.areaSourceList.findIndex(function (item) {
        return item.id === parseInt(id);
      });
      if (index > -1) {
        var item = this.common.areaSourceList[index];
        var val = {
          value: id,
          label: item.name
        };
        if (needRecursion && item.parentId > 0) {
          return this.buildValue(item.parentId).concat([val]);
        } else {
          return [val];
        }
      }
      return [];
    },
    getLastAreaId: function getLastAreaId(values) {
      if (Array.isArray(values)) {
        return values[values.length - 1];
      } else {
        return values.split(",").slice(-1)[0];
      }
    },
    // 搜索
    onSearch: function onSearch(e) {
      this.nameOrPhoneOrIdCard = e;
      this.getRecordInfosList();
    },
    // 删除
    deleteItem: function deleteItem(e) {
      // var id = "";
      // for (var i = 0; i < this.waiList.length; i++) {
      //   if (this.waiList[i].empInfoVO.id === e.id) {
      //     id = this.waiList[i].id;
      //     break;
      //   }
      // }
      var that = this;
      this.$confirm({
        title: "\u786E\u5B9A\u5220\u9664".concat(e.name, "\u7684\u56DE\u6B3E\u4FE1\u606F\u5417?"),
        onOk: function onOk() {
          deleteRecord(e.id).then(function (res) {
            that.$notification["success"]({
              message: "success",
              description: "删除成功"
            });
            that.getRecordInfosList();
          });
        },
        onCancel: function onCancel() {
          console.log("Cancel");
        }
      });
    },
    reload: function reload() {
      this.getRecordInfosList();
      this.$notification["success"]({
        message: "成功",
        description: "刷新成功"
      });
    },
    // 回款记录点击
    showRecord: function showRecord(e) {
      this.empName = e.name;
      this.empId = e.empId;
      this.remainAmount = e.remainAmount;
      this.recordVisible = true;
      this.getRecordRecords();
    },
    getRecordRecords: function getRecordRecords() {
      var _this6 = this;
      _getRecordRecords({
        year: moment(this.nowDate).format("YYYY"),
        month: moment(this.nowDate).format("MM"),
        pageNo: this.pagination2.current,
        pageSize: this.pagination2.pageSize,
        empId: this.empId
      }).then(function (res) {
        _this6.dataList2 = res.data.entities;
        _this6.pagination2.total = res.data && res.data.entityCount;
      });
    },
    // 回款记录点击确定
    handelRecordOk: function handelRecordOk() {
      this.recordVisible = false;
    },
    // 点击回款
    showAction: function showAction(e) {
      var _this7 = this;
      this.actionForm = {};
      this.$nextTick(function () {
        _this7.$refs.ruleForm.clearValidate();
      });
      this.actionForm.name = e.name;
      this.actionForm.phone = e.phone;
      this.actionForm.idCard = e.idCard;
      this.actionForm.totalAmount = e.surplusAmount;
      this.actionForm.empId = e.empId;
      this.actionForm.serviceCost = e.serviceCost;
      this.actionForm.socAmount = e.socAmount;
      this.actionForm.overdueFine = e.overdueFine;
      this.actionForm.nowDate = moment(e.year + "-" + e.month, "YYYY-MM");
      this.actionVisible = true;
    },
    // 新增回款记录(回款点击确定)
    handelActionOk: function handelActionOk() {
      var _this8 = this;
      var dateCopy = this.actionForm.nowDate;
      this.actionForm.year = moment(this.actionForm.nowDate).format("YYYY");
      this.actionForm.month = moment(this.actionForm.nowDate).format("MM");
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this8.actionForm.nowDate = undefined;
          addRecordRecords(_this8.actionForm).then(function (res) {
            _this8.$notification["success"]({
              message: "success",
              description: "回款成功"
            });
            _this8.actionVisible = false;
            _this8.getRecordInfosList();
          }).catch(function (err) {
            console.log(err);
            _this8.actionForm.nowDate = dateCopy;
          });
        }
      });
    }
  }
};