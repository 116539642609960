import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "p24"
  }, [_c("a-layout", [_c("a-row", {
    staticClass: "head"
  }, [_c("a-col", {
    staticStyle: {
      cursor: "pointer",
      width: "45px"
    },
    on: {
      click: _vm.goback
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  }), _vm._v("返回 ")], 1)], 1), _c("a-row", [_c("div", {
    staticClass: "baseInfo-box"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "avatar"
  }, [_vm.empPhoto !== null ? _c("a-avatar", {
    key: _vm.empPhoto,
    attrs: {
      src: _vm.empPhoto,
      size: 64
    }
  }) : _c("a-avatar", {
    attrs: {
      icon: "user",
      size: 64
    }
  })], 1), _c("div", {
    staticClass: "baseInfo"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.name))]), _c("div", {
    staticClass: "depName"
  }, [_c("div", {
    staticClass: "depart"
  }, [_vm._v(" 部门： "), _c("span", [_vm._v(_vm._s(_vm.depName))])])])])]), _c("div", {
    staticClass: "right"
  }, [_vm.dataList.length !== 0 ? _c("a-select", {
    staticStyle: {
      width: "136px"
    },
    attrs: {
      placeholder: "搜索年份"
    },
    on: {
      change: _vm.handleYear
    },
    model: {
      value: _vm.year,
      callback: function callback($$v) {
        _vm.year = $$v;
      },
      expression: "year"
    }
  }, _vm._l(_vm.dataYear, function (item) {
    return _c("a-select-option", {
      key: item.year
    }, [_vm._v(_vm._s(item.year))]);
  }), 1) : _vm._e()], 1)])]), _c("a-row", [_c("a-table", {
    attrs: {
      columns: _vm.column,
      "data-source": _vm.dataList,
      pagination: _vm.pagination,
      "row-key": "id"
    },
    on: {
      expand: _vm.expandedRowsChange
    },
    scopedSlots: _vm._u([{
      key: "yearMonth",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.year) + "年" + _vm._s(record.month) + "月")])];
      }
    }, {
      key: "expandedRowRender",
      fn: function fn(text) {
        return _c("div", {
          staticClass: "expend-box",
          staticStyle: {
            padding: "0",
            margin: "0"
          }
        }, [[_c("div", {
          staticClass: "userInfo-box"
        }, [_c("div", {
          staticClass: "shebaoInfo"
        }, [_c("div", {
          staticClass: "userInfo-shebaoInfo"
        }, [_c("div", {
          staticClass: "base-record"
        }, [_vm._v("基本资料")]), _c("div", {
          staticClass: "idCardInfo"
        }, [_c("span", {
          staticClass: "text"
        }, [_vm._v("身份证号:")]), _c("span", {
          staticClass: "number"
        }, [_vm._v(_vm._s(text.idCard))])]), _c("div", {
          staticClass: "personshebaoInfo"
        }, [_c("span", {
          staticClass: "text"
        }, [_vm._v("个人社保号:")]), _c("span", {
          staticClass: "number"
        }, [_vm._v(_vm._s(text.socCard))])]), _c("div", {
          staticClass: "sfInfo"
        }, [_c("span", {
          staticClass: "text"
        }, [_vm._v("个人公积金号:")]), _c("span", {
          staticClass: "number"
        }, [_vm._v(_vm._s(text.pfCard))])]), _c("div", {
          staticClass: "cityInfo"
        }, [_c("span", {
          staticClass: "text"
        }, [_vm._v("参保城市:")]), _c("span", {
          staticClass: "number"
        }, [_vm._v(_vm._s(text.socCityName))])]), _c("div", {
          staticClass: "methodInfo"
        }, [_c("span", {
          staticClass: "text"
        }, [_vm._v("缴纳方案:")]), _c("span", {
          staticClass: "number"
        }, [_vm._v(_vm._s(text.progName))])])])]), _c("div", {
          staticClass: "editInfo-box"
        }, [_c("a-button", {
          staticClass: "stopShebao",
          on: {
            click: _vm.stopShebao
          }
        }, [_vm._v(" 停止参保 ")]), _c("a-button", [_vm._v("补缴")])], 1)]), _c("div", {
          staticClass: "payInfo-box"
        }, [_c("a-table", {
          attrs: {
            "data-source": text.progItemListVo,
            pagination: false,
            "row-key": "id"
          }
        }, [_c("a-table-column", {
          attrs: {
            title: "缴纳项目"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(text1) {
              return [_c("span", [_vm._v(_vm._s(text1.itemName))])];
            }
          }], null, true)
        }), _c("a-table-column", {
          attrs: {
            title: "缴纳基数"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(text1) {
              return [_c("span", [_vm._v(_vm._s(text1.id === "1" ? "--" : text1.cardinalityDefault))])];
            }
          }], null, true)
        }), _c("a-table-column", {
          attrs: {
            title: "个人比例"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(text1) {
              return [_c("span", [_vm._v(_vm._s(text1.persentPersonal === null ? "--" : text1.persentPersonal + "%"))])];
            }
          }], null, true)
        }), _c("a-table-column", {
          attrs: {
            title: "公司比例"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(text1) {
              return [_c("span", [_vm._v(_vm._s(text1.persentCompany === null ? "--" : text1.persentCompany + "%"))])];
            }
          }], null, true)
        }), _c("a-table-column", {
          attrs: {
            title: "个人缴费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(text1) {
              return [_c("span", [_vm._v(_vm._s(text1.amountPersonal))])];
            }
          }], null, true)
        }), _c("a-table-column", {
          attrs: {
            title: "公司缴费"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(text1) {
              return [_c("span", [_vm._v(_vm._s(text1.amountCompany))])];
            }
          }], null, true)
        }), _c("a-table-column", {
          attrs: {
            title: "缴费合计"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(text1) {
              return [_c("span", [_vm._v(_vm._s(text1.amountCount))])];
            }
          }], null, true)
        })], 1)], 1)]], 2);
      }
    }])
  })], 1)], 1), _c("a-modal", {
    attrs: {
      title: "减员月份",
      visible: _vm.isStopShebao
    },
    on: {
      ok: _vm.submitStop,
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    staticClass: "cutMonth"
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "减员月份"
    }
  }, [_c("a-select", {
    on: {
      change: _vm.handleCutMonth
    },
    model: {
      value: _vm.monthYear,
      callback: function callback($$v) {
        _vm.monthYear = $$v;
      },
      expression: "monthYear"
    }
  }, _vm._l(_vm.empMonthList, function (item) {
    return _c("a-select-option", {
      key: item.year + "-" + item.month
    }, [_vm._v(_vm._s(item.year) + "-" + _vm._s(item.month))]);
  }), 1)], 1)], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };