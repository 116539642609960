import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from "vuex";
export default {
  props: {
    collapsed: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      backImg: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["settings"])), {}, {
    systemIcon: function systemIcon() {
      return this.settings.systemIcon;
    },
    leftMenuHideLogoUrl: function leftMenuHideLogoUrl() {
      return this.settings.leftMenuHideLogoUrl;
    }
  }),
  watch: {
    collapsed: {
      handler: function handler(newVal) {
        if (newVal) {
          this.backImg = this.leftMenuHideLogoUrl || require("@/assets/logo1.png");
        } else {
          this.backImg = this.systemIcon || require("@/assets/logo.png");
        }
      },
      immediate: true
    },
    systemIcon: {
      handler: function handler(newVal) {
        if (newVal) {
          this.backImg = this.collapsed ? this.leftMenuHideLogoUrl : this.systemIcon;
        }
      },
      immediate: true
    }
  }
};