import approvalpopup from "./approvalpopup.vue";
import { getApprovalStatus, getApprovalType, getApprovalStatusIcon, getApprovalStatusColor } from "../funcs";
export default {
  components: {
    approvalpopup: approvalpopup
  },
  data: function data() {
    return {
      creatProcessList: []
    };
  },
  props: ["approvalVal"],
  watch: {
    approvalVal: {
      handler: function handler(val, oldval) {
        if (val) {
          this.creatApprovalProcess(val);
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getApprovalStatus: getApprovalStatus,
    getApprovalStatusIcon: getApprovalStatusIcon,
    getApprovalStatusColor: getApprovalStatusColor,
    getApprovalType: getApprovalType,
    creatApprovalProcess: function creatApprovalProcess(val) {
      console.log("123", val);
      this.creatProcessList = val;
    },
    typeOfArray: function typeOfArray(arr) {
      return arr instanceof Array;
    }
  }
};