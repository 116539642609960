var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "add-payslip-second"
  }, [_c("a-row", {
    staticClass: "content-top"
  }, [_c("step", {
    attrs: {
      current: 1
    }
  })], 1), _c("div", {
    staticClass: "content-con",
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c("a-row", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("发送方式")])]), _c("a-row", {
    staticClass: "sub_tit mg_b20"
  }, [_vm._v(" 选择工资条的发送方式，支持发送微信通知和短信通知 ")]), _c("a-row", [_c("a-select", {
    staticStyle: {
      width: "337px"
    },
    attrs: {
      placeholder: "发送方式"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.postParams.sendType,
      callback: function callback($$v) {
        _vm.$set(_vm.postParams, "sendType", $$v);
      },
      expression: "postParams.sendType"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("微信")])], 1), _c("a-tooltip", {
    attrs: {
      placement: "right"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v("只会给绑定了微信的员工发送工资条")])]), _c("a-icon", {
    attrs: {
      type: "question-circle"
    }
  })], 2)], 1), _c("a-row", {
    staticClass: "mg_t12 mg_b24"
  }, [_vm._v(" 共 "), _c("span", {
    staticClass: "primary"
  }, [_vm._v(_vm._s(_vm.$route.query.unSendCount))]), _vm._v(" 名员工，预计发送 "), _c("span", {
    staticClass: "primary"
  }, [_vm._v(_vm._s(_vm.$route.query.unSendCount))]), _vm._v(" 条" + _vm._s(["微信", "短信"][_vm.postParams.sendType - 1]) + "通知 ")]), _c("a-row", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("工资条确认")])]), _c("a-row", {
    staticClass: "sub_tit mg_b20"
  }, [_vm._v(" 设定工资条的确认时间，超出规定时间后系统自动确认 ")]), _c("a-row", [_c("a-input-number", {
    staticClass: "mg_r24",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请输入",
      min: 0,
      precision: 0,
      formatter: _vm.limitNumber,
      parser: _vm.limitNumber
    },
    model: {
      value: _vm.postParams.autoCheckHours,
      callback: function callback($$v) {
        _vm.$set(_vm.postParams, "autoCheckHours", $$v);
      },
      expression: "postParams.autoCheckHours"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "113px"
    },
    attrs: {
      "default-value": "hour",
      placeholder: "请选择"
    },
    on: {
      change: _vm.handleChangeTime
    },
    model: {
      value: _vm.postParams.autoCheckUnit,
      callback: function callback($$v) {
        _vm.$set(_vm.postParams, "autoCheckUnit", $$v);
      },
      expression: "postParams.autoCheckUnit"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("小时")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v("天")])], 1)], 1), _c("a-row", {
    staticClass: "title mg_t20"
  }, [_c("strong", [_vm._v("工资条显示")])]), _c("a-row", {
    staticClass: "sub_tit mg_b20"
  }, [_vm._v(" 设定工资条的显示规则，可以选择不显示工资条为0的值 ")]), _c("a-row", [_c("strong", [_vm._v("显示工资条为0的值")]), _c("a-switch", {
    staticClass: "mg_l10",
    attrs: {
      "checked-children": "开",
      "un-checked-children": "关"
    },
    model: {
      value: _vm.postParams.showEmptyValue,
      callback: function callback($$v) {
        _vm.$set(_vm.postParams, "showEmptyValue", $$v);
      },
      expression: "postParams.showEmptyValue"
    }
  })], 1)], 1), _c("a-row", {
    staticClass: "foot"
  }, [_c("a-button", {
    staticClass: "mg_r12",
    on: {
      click: _vm.doBefor
    }
  }, [_vm._v("上一步")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.next
    }
  }, [_vm._v("下一步")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };