export var calculateTaxStatus = [{
  label: "未算税",
  value: 1
}, {
  label: "算税中",
  value: 2
}, {
  label: "算税失败",
  value: 3
}, {
  label: "算税异常",
  value: 4
}, {
  label: "算税成功",
  value: 5
}];