import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
export default {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      this.levelList = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      });
    }
  }
};