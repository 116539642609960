var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [!_vm.abnormal ? _c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.componentName == "selectPeople" ? "创建算税批次" : "上传算税批次") + " ")]) : _vm._e(), !_vm.abnormal ? _c(_vm.componentName, {
    tag: "div",
    on: {
      upData: _vm.upData
    }
  }) : _c("abnormal", {
    attrs: {
      arr: _vm.abnormalArr
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };