export default {
  fields: {
    withholdingAgentName: {
      model: "withholdingAgentName"
    },
    responsibleAuthorityName: {
      model: "responsibleAuthorityName"
    },
    registrationNumber: {
      model: "registrationNumber"
    },
    taxNo: {
      model: "taxNo"
    },
    withholdingAgentAddress: {
      model: "withholdingAgentAddress"
    },
    gmtCreate: {
      model: "gmtCreate"
    },
    branchRecordSign: {
      model: "branchRecordSign"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "withholdingAgentName",
        title: "企业名称"
      }, {
        model: "responsibleAuthorityName",
        title: "主管税务机关"
      }, {
        model: "registrationNumber",
        title: "登记序号"
      }, {
        model: "taxNo",
        title: "统一社会信用代码"
      }, {
        model: "withholdingAgentAddress",
        title: "生产经营地址"
      }, {
        model: "branchRecordSign",
        title: "是否税局部门备案"
      }
      // },
      // {
      //   model: "gmtCreate",
      //   title: "税局备案部门"
      // }
      ],

      customize: {
        rowKey: "id"
        // pagination: {
        //   pageSize: 10
        // }
      },

      extend: {
        batch: false,
        action: false
      }
    }
  }
};