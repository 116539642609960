var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "po"
  }, [_c("a-row", _vm._l(_vm.createProcessList, function (i, index) {
    return _c("a-col", {
      key: index,
      staticClass: "po-box",
      attrs: {
        span: 3
      }
    }, [_c("a-col", {
      attrs: {
        span: 14
      }
    }, [_c("div", {
      staticClass: "approval-block-po-Left"
    }, [_c("div", {
      staticClass: "approval-block-po-Rela"
    }, [(_vm.typeOfArray(i.approUserList) ? i.approUserList.length == 1 : false) ? _c("span", [_c("a-avatar", {
      attrs: {
        icon: "user",
        size: 64,
        src: i.approUserList[0].avatar
      }
    }), i.approStatus != 0 ? _c("a-icon", {
      staticClass: "approval-block-po-abso",
      style: {
        fontSize: "20px",
        color: "#fff",
        borderRadius: "50%",
        background: _vm.getApprovalStatusColor(i.approStatus)
      },
      attrs: {
        type: _vm.getApprovalStatusIcon(i.approStatus)
      }
    }) : _vm._e()], 1) : _vm._e(), (_vm.typeOfArray(i.approUserList) ? i.approUserList.length == 2 : false) ? _c("span", [_c("a-avatar", {
      staticClass: "approval-block-po-avatar1",
      attrs: {
        size: 48,
        icon: "user",
        src: i.approUserList[0].avatar
      }
    }), _c("a-avatar", {
      staticClass: "approval-block-po-avatar4",
      attrs: {
        size: 48,
        icon: "user",
        src: i.approUserList[1].avatar
      }
    }), i.approStatus != 0 ? _c("a-icon", {
      staticClass: "approval-block-po-abso",
      style: {
        fontSize: "20px",
        color: "#fff",
        borderRadius: "50%",
        right: "-18px",
        background: _vm.getApprovalStatusColor(i.approStatus)
      },
      attrs: {
        type: _vm.getApprovalStatusIcon(i.approStatus)
      }
    }) : _vm._e()], 1) : _vm._e(), (_vm.typeOfArray(i.approUserList) ? i.approUserList.length > 2 : false) ? _c("span", [_c("a-avatar", {
      staticClass: "approval-block-po-avatar1",
      attrs: {
        size: 32,
        icon: "user",
        src: i.approUserList[0].avatar
      }
    }), _c("a-avatar", {
      staticClass: "approval-block-po-avatar2",
      attrs: {
        size: 32,
        icon: "user",
        src: i.approUserList[1].avatar
      }
    }), _c("a-avatar", {
      staticClass: "approval-block-po-avatar3",
      attrs: {
        size: 32,
        icon: "user",
        src: i.approUserList[2].avatar
      }
    }), i.approStatus != 0 ? _c("a-icon", {
      staticClass: "approval-block-po-abso",
      style: {
        fontSize: "20px",
        color: "#fff",
        borderRadius: "50%",
        background: _vm.getApprovalStatusColor(i.approStatus)
      },
      attrs: {
        type: _vm.getApprovalStatusIcon(i.approStatus)
      }
    }) : _vm._e()], 1) : _vm._e()]), _c("div", {
      staticClass: "approval-block-po-title"
    }, [_vm._v(_vm._s(i.nodeName))])])]), _c("a-col", {
      staticClass: "approval-block-po-rig",
      attrs: {
        span: 10
      }
    }, [i.approStatus == 0 || i.approStatus == 1 ? _c("span", _vm._l(5, function (i) {
      return _c("span", {
        key: i,
        staticClass: "approval-block-po-dot"
      });
    }), 0) : _vm._l(5, function (i) {
      return _c("span", {
        key: i,
        staticClass: "approval-block-po-dot approval-block-po-back32C"
      });
    })], 2), _c("a-col", {
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        span: 14
      }
    }, [_vm.typeOfArray(i.approUserList) ? _c("div", {
      staticClass: "approval-block-po-name approval-block-po-white-space"
    }, [_vm._v(" " + _vm._s(i.approUserList[0].userName) + " "), i.approUserList.length > 1 ? _c("span", [_vm._v("等人")]) : _vm._e()]) : _vm._e(), _c("div", {
      staticClass: "approval-block-po-name approval-block-po-white-space"
    }, [i.nodeType !== "notifier" ? _c("div", [_vm._v(" " + _vm._s(index == 0 ? "发起审批" : _vm.getApprovalStatus(i.approStatus)) + " "), i.activateType && i.activateType != "0" ? _c("span", [_vm._v("(" + _vm._s(_vm.getApprovalType(i.activateType)) + ")")]) : _vm._e()]) : _vm._e()])]), _c("approvalpopup", {
      staticClass: "po-box-Show",
      attrs: {
        approvalRecord: i
      }
    })], 1);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };