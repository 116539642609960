var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "add-payslip"
  }, [_c("a-button", {
    staticStyle: {
      color: "#D8D8D8",
      "margin-left": "-12px"
    },
    attrs: {
      icon: "left",
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")]), _c("div", {
    staticClass: "addPayslip-middle"
  }, [_c("a-row", {
    staticClass: "content-con"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("img", {
    staticStyle: {
      width: "24px",
      height: "24px",
      position: "relative",
      right: "10px",
      top: "5px"
    },
    attrs: {
      src: require("@/assets/img/wageMonthFile.png")
    }
  }), _c("h2", {
    staticClass: "title"
  }, [_vm._v("上传历史工资表")])]), _c("a-form", {
    staticClass: "form",
    attrs: {
      layout: "inline",
      form: _vm.form
    }
  }, [_c("a-row", [_c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "请选择月份"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      format: "YYYY-MM",
      placeholder: "请选择"
    },
    model: {
      value: _vm.selectDate,
      callback: function callback($$v) {
        _vm.selectDate = $$v;
      },
      expression: "selectDate"
    }
  })], 1)], 1), _c("a-row", [_c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "工资表名称"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请输入",
      "max-length": 20
    },
    model: {
      value: _vm.tableName,
      callback: function callback($$v) {
        _vm.tableName = $$v;
      },
      expression: "tableName"
    }
  })], 1)], 1), _c("a-form-item", [_c("a-upload", {
    attrs: {
      action: _vm.action
    },
    on: {
      change: _vm.cbUpload
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.disabled
    },
    on: {
      click: _vm.onUpload
    }
  }, [_vm._v(" 导入月工资表 ")])], 1)], 1)], 1)], 1), _c("a-row", {
    staticClass: "content-foot"
  }, [_c("h3", [_vm._v("导入提示")]), _c("div", {
    staticClass: "sub_tit"
  }, [_vm._v(" 1、上传的月工资表，需要提供以下信息： ")]), _c("div", {
    staticClass: "sub_tit"
  }, [_vm._v(" 员工姓名、手机号、定薪工资、应发工资、社保金个人缴纳、公积金个人缴纳、个人所得税、实发工资； ")]), _c("div", {
    staticClass: "sub_tit"
  }, [_vm._v(" 2、如上传遇到问题，请使用标准模版上传 "), _c("span", {
    staticClass: "primary"
  }, [_c("a", {
    on: {
      click: _vm.download
    }
  }, [_vm._v("「历史工资上传模板」")])])]), _c("div", {
    staticClass: "sub_tit"
  }, [_vm._v(" 3、暂不支持复合表头（即合并单元格）； ")]), _c("div", {
    staticClass: "sub_tit"
  }, [_vm._v(" 4、上传的员工必须存在于员工花名册中，否则系统无法识别 ")])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };