var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "boxs-BL"
  }, [_vm.remark.length <= 0 ? _c("div", {
    staticClass: "btn",
    on: {
      click: _vm.remarkShowModal
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("备注")], 1) : _c("a-popover", {
    attrs: {
      placement: "top"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("p", [_vm._v(_vm._s(_vm.remark))])]), _c("div", {
    staticClass: "contents"
  }, [_c("div", {
    staticClass: "contents-font"
  }, [_vm._v(_vm._s(_vm.remark))]), _c("a-icon", {
    staticClass: "icon",
    attrs: {
      type: "edit"
    },
    on: {
      click: _vm.remarkShowModal
    }
  })], 1)], 2), _vm._l(_vm.selectLabelList, function (item, index) {
    return _c("a-popover", {
      key: index,
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "content"
    }, [_c("p", [_vm._v(_vm._s(item))])]), _c("div", {
      staticClass: "contents labels"
    }, [_c("div", {
      staticClass: "contents-font"
    }, [_vm._v(_vm._s(item))]), _c("a-icon", {
      staticClass: "icon",
      attrs: {
        type: "close"
      },
      on: {
        click: function click($event) {
          return _vm.selectLabelClose(index);
        }
      }
    })], 1)], 2);
  }), _c("div", {
    staticClass: "btn",
    on: {
      click: _vm.labelShowModal
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("标签")], 1), _c("a-modal", {
    attrs: {
      title: "编辑备注"
    },
    on: {
      ok: _vm.remarkHandleOk
    },
    model: {
      value: _vm.remarkVisible,
      callback: function callback($$v) {
        _vm.remarkVisible = $$v;
      },
      expression: "remarkVisible"
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "备注内容"
    }
  }, [_c("a-textarea", {
    attrs: {
      placeholder: "",
      maxLength: 200,
      "auto-size": {
        minRows: 5,
        maxRows: 8
      }
    },
    model: {
      value: _vm.remarkValue,
      callback: function callback($$v) {
        _vm.remarkValue = $$v;
      },
      expression: "remarkValue"
    }
  })], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "选择标签"
    },
    model: {
      value: _vm.labelVisible,
      callback: function callback($$v) {
        _vm.labelVisible = $$v;
      },
      expression: "labelVisible"
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "back",
    on: {
      click: function click($event) {
        _vm.labelVisible = false;
        _vm.showInput = false;
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary",
      disabled: _vm.showInput
    },
    on: {
      click: _vm.labelHandleOk
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticClass: "titles"
  }, [_vm._v("选择员工标签")]), _c("div", {
    staticClass: "label-model-boxs"
  }, [_vm._l(_vm.labelList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "contents label-model-itme",
      style: item.type ? "background:".concat(_vm.primaryColor, ";color:#fff;") : "",
      on: {
        click: function click($event) {
          return _vm.selectLable(item, index);
        }
      }
    }, [_c("a-popover", {
      attrs: {
        placement: "top"
      }
    }, [_c("template", {
      slot: "content"
    }, [_c("p", [_vm._v(_vm._s(item.tagName))])]), _c("div", {
      staticClass: "contents-font"
    }, [_vm._v(_vm._s(item.tagName))]), _c("a-icon", {
      staticClass: "icon",
      attrs: {
        type: "close"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.labelClose(item.tagName, index);
        }
      }
    })], 2)], 1);
  }), !_vm.showInput ? _c("div", {
    staticClass: "addLable"
  }, [_c("div", {
    staticClass: "contents label-model-itme add",
    on: {
      click: function click($event) {
        _vm.showInput = true;
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加标签")], 1)]) : _vm._e()], 2), _vm.showInput ? _c("div", [_c("a-input", {
    staticClass: "addInput",
    attrs: {
      placeholder: "输入标签"
    },
    model: {
      value: _vm.inputValue,
      callback: function callback($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }), _c("a-button", {
    attrs: {
      type: "link",
      disabled: _vm.inputValue.length <= 0
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v("确定")]), _c("a-button", {
    staticClass: "closeAddInput",
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        _vm.showInput = false;
        _vm.inputValue = "";
      }
    }
  }, [_vm._v("取消")])], 1) : _vm._e()], 2)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };