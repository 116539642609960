import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
function probationDaysFormatter(value) {
  return "".concat(value, "\u5929");
}
function probationDaysParser(value) {
  return value.replace("天", "");
}
import { getToken } from "xqjr-module-auth";
export function basicsPages(moduleCode, data) {
  var fields = {};
  var fieldKeys = [];
  var _loop = function _loop() {
    var element = data[i];
    if (element.fieldCode === "probationDays") {
      // console.log("debug log --> ", element);
    }
    if (element.isOpen) {
      fieldKeys.push(element.fieldCode);
      fields[element.fieldCode] = {
        model: element.fieldCode,
        fieldName: element.fieldName,
        type: element.fieldType,
        wrapper: {
          label: element.fieldName
        },
        customize: {
          style: "width: 100%",
          // || !!(moduleCode === "contact" && element.fieldCode === "phone"),
          placeholder: element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName),
          format: element.fieldType === "3" || element.fieldType === 3 ? "YYYY-MM-DD" : "",
          formatter: (element.fieldType === "5" || element.fieldType === 5) && element.fieldCode === "probationDays" ? probationDaysFormatter : undefined,
          parser: (element.fieldType === "5" || element.fieldType === 5) && element.fieldCode === "probationDays" ? probationDaysParser : undefined
        },
        decorator: {
          initialValue: undefined,
          rules: [{
            required: element.isNecessary,
            message: element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName)
          }]
        }
      };
      if (["手机号", "联系电话", "手机号码"].includes(element.fieldName)) {
        fields[element.fieldCode].decorator.rules.push({
          pattern: "^1[3456789]\\d{9}$",
          message: "手机号码格式错误"
        });
      }
      if (element.fieldName === "个人邮箱" || element.fieldName === "邮箱") {
        fields[element.fieldCode].decorator.rules.push({
          pattern: "\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*",
          message: "邮箱格式错误"
        });
      }
      // if (element.fieldName === "退休年龄") {
      //   fields[element.fieldCode].customize = {};
      // }
      if (element.fieldCode === "idcard" || element.fieldCode === "idCard") {
        fields[element.fieldCode].decorator.rules.push({
          pattern: "^((^[HMhm](\\d{8}|\\d{10})$)|(^(\\d{8}|^[a-zA-Z]{1}(\\d{7}))$)|(^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$)|(^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])((\\d{4})|\\d{3}[Xx]$)))$",
          message: "身份证格式错误"
        });
        // fields[element.fieldCode].decorator.rules.push({
        //   pattern: "^(\\d{8}|^[a-zA-Z]{1}(\\d{7}))$",
        //   message: "身份证格式错误"
        // });
        // ^[HMhm](\\d{8}|\\d{10})$
        // fields[element.fieldCode].decorator.rules.push({
        //   pattern:
        //     "^(^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$)|(^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])((\\d{4})|\\d{3}[Xx])$)$",
        //   message: "身份证格式错误"
        // });
      }

      if (element.fieldType === 1 || element.fieldType === "1") {
        var options = [];
        if (element.dropDownEnumList) {
          element.dropDownEnumList.forEach(function (item, index) {
            for (var key in item) {
              if (item[key] === "true") {
                item[key] = "true";
              }
              if (item[key] === "false") {
                item[key] = "false";
              }
            }
          });
          options = element.dropDownEnumList;
        }
        if (element.dropDownArray) {
          element.dropDownArray.forEach(function (element) {
            options.push({
              value: element,
              label: element
            });
          });
        }
        fields[element.fieldCode].customize.options = options;
        fields[element.fieldCode].type = "select";
      }
      if (element.fieldType === 2 || element.fieldType === "2") {
        fields[element.fieldCode].type = "input";
      }
      if (element.fieldType === 3 || element.fieldType === "3") {
        fields[element.fieldCode].type = "date";
      }
      if (element.fieldType === 4 || element.fieldType === "4") {
        fields[element.fieldCode].type = "textarea";
        fields[element.fieldCode].customize.autoSize = {
          minRows: 5,
          maxRows: 10
        };
      }
      if (element.fieldType === 5 || element.fieldType === "5") {
        fields[element.fieldCode].type = "number";
      }
      if (element.fieldType === 6 || element.fieldType === "6") {
        fields[element.fieldCode].type = "area";
      }
      if (element.fieldType === 7 || element.fieldType === "7") {
        fields[element.fieldCode].type = "upload";
        if (element.fieldCode === "annexSalaryCard") {
          fields[element.fieldCode].customize = {
            name: "file",
            action: "/file/api/v1/general/uploadImgAndOCRBankcard",
            accept: ".jpg, .jpeg, .png",
            listType: "picture-card",
            multiple: false
          };
          fields[element.fieldCode].extend = {
            size: 10,
            limit: 4,
            needToken: false
          };
        } else {
          fields[element.fieldCode].customize = {
            action: "/file/api/v1/site/uploadImg",
            accept: ".jpg, .jpeg, .png",
            listType: "picture-card",
            multiple: true
          };
          fields[element.fieldCode].extend = {
            size: 10,
            limit: 9,
            needToken: false
          };
        }
      }

      // 部门、岗位
      if (element.fieldCode === "preJoinDep" || element.fieldCode === "preJoinPosition") {
        fields[element.fieldCode].type = "treeSelect";
        fields[element.fieldCode].customize = {
          multiple: true,
          style: "width: 100%",
          allowClear: true,
          placeholder: element.fieldCode === "preJoinDep" ? "请选择部门" : "请选择岗位",
          showSearch: true,
          treeNodeFilterProp: "title"
        };
        fields[element.fieldCode].decorator = {
          initialValue: undefined
        };
        fields[element.fieldCode].extend = {
          dynamic: true,
          // 是否是动态的
          dynamicKey: element.fieldCode === "preJoinDep" ? "depNameDynamic" : "positionOptions"
        };
      }
    }
  };
  for (var i = 0; i < data.length; i++) {
    _loop();
  }
  // console.log("debug log --> ", fields);
  return {
    fields: fields,
    fieldKeys: fieldKeys
  };
}