import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.join.js";
import { AreaPicker, AreaPickerMulti } from "xqjr-plugin-common";
export default {
  name: "AreaSelector",
  components: {
    AreaPicker: AreaPicker,
    AreaPickerMulti: AreaPickerMulti
  },
  props: {
    record: {
      type: Object,
      default: function _default() {}
    },
    value: {
      type: [String, Number],
      default: 0
    }
  },
  methods: {
    onChange: function onChange(val) {
      var res = "";
      if (this.record.options.multiple) {
        res = val.value.join(",");
      } else {
        res = val.value[val.value.length - 1];
      }
      this.$emit("input", res);
      this.$emit("change", res);
    }
  }
};