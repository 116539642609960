export default {
  fields: {
    orderTitle: {
      model: "orderTitle"
    },
    createUserName: {
      model: "createUserName"
    },
    companyName: {
      model: "companyName"
    },
    totalCount: {
      model: "totalCount"
    },
    money: {
      model: "money"
    },
    gmtCreate: {
      model: "gmtCreate"
    },
    thirdPartyInteriorBatchId: {
      model: "thirdPartyInteriorBatchId"
    },
    subjectStatus: {
      model: "subjectStatus"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "orderTitle",
        title: "批次名"
      }, {
        model: "createUserName",
        title: "申请人"
      }, {
        model: "companyName",
        title: "用工单位"
      }, {
        model: "totalCount",
        title: "发薪人数"
      }, {
        model: "money",
        title: "发放总额"
      }, {
        model: "gmtCreate",
        title: "申请时间"
      }, {
        model: "subjectStatus",
        title: "状态"
      }],
      customize: {
        rowKey: "id"
        // pagination: {
        //   pageSize: 10
        // }
      },

      extend: {
        batch: false,
        action: true
      }
    }
  }
};