var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sidebar-wrapper",
    class: {
      "has-logo": _vm.hasLogo,
      collapsed: _vm.isCollapsed
    }
  }, [_vm.hasLogo ? _c("logo", {
    attrs: {
      collapsed: _vm.isCollapsed
    }
  }) : _vm._e(), _c("xq-scrollbar", {
    staticClass: "sidebar-scroll-wrapper"
  }, [_c("a-menu", {
    attrs: {
      mode: "inline",
      theme: _vm.menuTheme,
      openKeys: _vm.$store.state.navtab.openKeys,
      inlineCollapsed: _vm.isCollapsed
    },
    on: {
      "update:openKeys": function updateOpenKeys($event) {
        return _vm.$set(_vm.$store.state.navtab, "openKeys", $event);
      },
      "update:open-keys": function updateOpenKeys($event) {
        return _vm.$set(_vm.$store.state.navtab, "openKeys", $event);
      }
    },
    model: {
      value: _vm.$store.state.navtab.selectedKeys,
      callback: function callback($$v) {
        _vm.$set(_vm.$store.state.navtab, "selectedKeys", $$v);
      },
      expression: "$store.state.navtab.selectedKeys"
    }
  }, [_vm._l(_vm.permissions_menu_tree, function (route) {
    return [!route.children ? [_c("a-menu-item", {
      key: route.url
    }, [_c("router-link", {
      attrs: {
        to: route.url
      }
    }, [_c("item", {
      attrs: {
        icon: route.icon,
        title: route.title
      }
    })], 1)], 1)] : _c("sub-menu", {
      key: route.url,
      attrs: {
        item: route,
        "hide-menu": _vm.hideMenu
      }
    })];
  })], 2)], 1), _c("xq-footer", {
    nativeOn: {
      click: function click($event) {
        return _vm.goOther.apply(null, arguments);
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };