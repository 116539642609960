import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import axios from "axios";
import FormJson from "./FormJson";
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
import moment from "moment";
var curdMixin = new XqCurdMixin({
  FormJson: FormJson,
  tableConfigKey: "table"
});
export default {
  mixins: [curdMixin],
  props: {
    arr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    type: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      spinning: false,
      tableData: [],
      pagination: {
        pageSize: 20,
        total: 0,
        size: "default",
        current: 1,
        pageSizeOptions: ["10", "20", "50", "100"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true
      },
      total: 0,
      pageNo: 1,
      pageSize: 20,
      dataJson: {}
    };
  },
  created: function created() {
    this.tableData = this.arr;
    this.pagination.total = this.arr.length;
  },
  methods: {
    moment: moment,
    handlePageChange: function handlePageChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
    },
    handleClose: function handleClose() {
      var token = this.$store.state.auth.token;
      var paySalaryInfo = JSON.parse(sessionStorage.getItem("paySalaryInfo"));
      axios({
        url: "/hr/api/v1/transferAccountsDetails/exportFailTransferAccountsDetail/".concat(paySalaryInfo.type),
        method: "POST",
        responseType: "blob",
        data: this.arr,
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "错误数据表.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("error log --> ", err);
      });
    },
    handleSubmit: function handleSubmit() {
      this.$router.replace({
        name: "calculateInfo"
      });
    }
  }
};