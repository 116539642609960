import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  path: "/admin",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/adminNew"));
    });
  },
  children: [{
    path: "management",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/management"));
      });
    }
  }, {
    path: "management/prePersonnel",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/management/prePersonnel"));
      });
    }
  }, {
    path: "management/prePersonnel/todayAdd",
    name: "prePersonnel/todayAdd",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/management/prePersonnel/todayAdd"));
      });
    }
  }, {
    path: "management/prePersonnel/upload",
    name: "prePersonnel/upload",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/management/prePersonnel/upload"));
      });
    }
  }, {
    path: "management/account",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/management/account"));
      });
    }
  }, {
    path: "management/account/upload",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/management/account/upload"));
      });
    }
  },
  // 公司管理
  {
    path: "enterprise",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/enterprise/index"));
      });
    }
  }, {
    path: "company_management",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/company/company_management"));
      });
    }
  }, {
    path: "xiaoshouliebiao",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/xiaoshouguanli/xiaoshouliebiao"));
      });
    }
  }, {
    path: "fanfeiguize",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/xiaoshouguanli/fanfeiguize"));
      });
    }
  }, {
    path: "kehufuwufeijilu",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/xiaoshouguanli/kehufuwufeijilu"));
      });
    }
  }, {
    path: "kehufuwufeijilu/upload",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/xiaoshouguanli/kehufuwufeijilu/upload"));
      });
    }
  }, {
    path: "fanfeijisuan",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/xiaoshouguanli/fanfeijisuan"));
      });
    }
  }, {
    path: "fanfeigailan",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/xiaoshouguanli/fanfeigailan"));
      });
    }
  }, {
    path: "productList",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/chanpinjieshao/index"));
      });
    }
  }, {
    path: "productBanner",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/chanpinjieshao/config"));
      });
    }
  }, {
    path: "eventHandling",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/fuwufankui/index"));
      });
    }
  }, {
    path: "zhidugongshi",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/zhidugongshi/index"));
      });
    }
  }, {
    path: "zhidugongshi/edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/zhidugongshi/edit"));
      });
    }
  }, {
    path: "zhengcesudi",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/zhengcesudi/index"));
      });
    }
  }, {
    path: "zhengcesudi/edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/zhengcesudi/edit"));
      });
    }
  }, {
    path: "zhengcesudi/detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/zhengcesudi/edit"));
      });
    }
  }, {
    path: "sendSingle",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/index"));
      });
    }
  }, {
    path: "supplier/gysManager",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/supplier/gysManager"));
      });
    }
  }, {
    path: "supplier/gysFieldManager",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/supplier/gysFieldManager"));
      });
    }
  }, {
    path: "supplier/detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/supplier/detail"));
      });
    }
  }, {
    path: "shebaoUpload",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/shebaoUpload"));
      });
    }
  }, {
    path: "preview",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/preview"));
      });
    }
  }, {
    path: "shebao-add-list-third",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/shebao-add-list-third"));
      });
    }
  }, {
    path: "shebao-detail-less",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/shebao/shebaoPaidan/shebao-detail-less"));
      });
    }
  },
  // 添加公司
  {
    path: "add_company_management",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/company/add_company_management"));
      });
    }
  },
  // 账号管理
  {
    path: "account_management",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/account/account_management"));
      });
    }
  }, {
    path: "account_management_jf",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/account/account_management_jf"));
      });
    }
  },
  // 添加账号
  {
    path: "add_account_management",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/account/add_account_management"));
      });
    }
  },
  // 角色管理
  {
    path: "role",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/account/role/rolemanage"));
      });
    }
  },
  // 角色配置
  {
    path: "roleconfig",
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/account/role/roleconfig"));
      });
    },
    meta: {
      // title: "角色权限",
      icon: "asset"
    }
  },
  // 用工单位
  {
    path: "employment_unit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/employment/employment_unit"));
      });
    }
  }, {
    path: "import",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/employment/import"));
      });
    }
  },
  // 添加用工单位
  {
    path: "add_employment_unit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/employment/add_employment_unit"));
      });
    }
  }, {
    path: "switch",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/employment/switch"));
      });
    }
  }, {
    path: "personal_information",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/admin/personal/personal_information"));
      });
    }
  },
  // {
  //   path: "merchant",
  //   component: () => import("@/views/merchant/index")
  // },
  // 合同概览
  {
    path: "overview_contract",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/ele-contract/overview"));
      });
    }
  }]
};