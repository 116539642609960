import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    staticClass: "page-miH",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_vm.mode === "company" ? _c("TableTitle", {
    staticStyle: {
      padding: "0",
      height: "32px",
      "line-height": "32px",
      margin: "4px 0 16px"
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm._v("签署记录")]), _c("template", {
    slot: "action"
  }, [_c("a-button", {
    attrs: {
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: function click($event) {
        return _vm.deleteContract();
      }
    }
  }, [_vm._v("批量删除")])], 1)], 2) : _vm._e(), _c("div", {
    staticClass: "contract-wrapper"
  }, [_vm.mode === "company" ? _c("SummaryBarPaper", {
    ref: "summaryBar",
    attrs: {
      selectTypeIndex: _vm.selectTypeIndex
    },
    on: {
      typeChange: _vm.selectFunc
    }
  }) : _vm._e(), _c("TableBody", {
    class: _vm.mode === "company" ? "mt24" : "",
    attrs: {
      selectHead: "",
      selection: "",
      tableName: _vm.mode === "overview" ? "paperContractOV" : "paperContract",
      selectedHead: _vm.selectedHead,
      selectedRowKeys: _vm.selectedRowKeys,
      source: _vm.contractList,
      headerRowID: "sortable_list",
      columns: _vm.columns
    },
    on: {
      "update:selectedHead": function updateSelectedHead($event) {
        _vm.selectedHead = $event;
      },
      "update:selected-head": function updateSelectedHead($event) {
        _vm.selectedHead = $event;
      },
      "update:selectedRowKeys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      "update:selected-row-keys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      onClose: function onClose() {},
      onSearch: function onSearch($event) {
        return _vm.selectFunc();
      },
      onReload: function onReload($event) {
        return _vm.selectFunc(_vm.selectTypeIndex);
      },
      onExport: function onExport() {
        return _vm.selectDownloadType(1);
      },
      onExportAll: function onExportAll() {
        return _vm.selectDownloadType(2);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(prop) {
        return [_c("a-table", {
          class: prop.className,
          attrs: {
            components: prop.components,
            columns: prop.columns,
            scroll: {
              x: prop.scrollWidth
            },
            customHeaderRow: function customHeaderRow() {
              return {
                domProps: {
                  id: prop.headerRowID
                }
              };
            },
            "data-source": _vm.contractList,
            "row-key": "id",
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange
            },
            pagination: _vm.pagination
          },
          on: {
            change: _vm.handleTableChange
          },
          scopedSlots: _vm._u([{
            key: "type",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.customRenderContractType(text)))]);
            }
          }, {
            key: "startTime",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.customRenderMoment(text)))]);
            }
          }, {
            key: "endTime",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.customRenderMoment(text)))]);
            }
          }, {
            key: "subscribeTime",
            fn: function fn(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm.customRenderMoment(text)))]);
            }
          }, {
            key: "number",
            fn: function fn(text) {
              return _c("span", {}, [_c("span", {
                staticStyle: {
                  "font-size": "12px"
                }
              }, [_vm._v(_vm._s(text ? text : "--"))])]);
            }
          }, {
            key: "remarks",
            fn: function fn(text) {
              return _c("span", {}, [_c("a-tooltip", {
                attrs: {
                  placement: "bottom"
                }
              }, [text ? _c("template", {
                slot: "title"
              }, [_c("span", [_vm._v(_vm._s(text))])]) : _vm._e(), _c("div", {
                staticClass: "contractNoOver"
              }, [_vm._v(_vm._s(text ? text : "--"))])], 2)], 1);
            }
          }, {
            key: "status",
            fn: function fn(text) {
              return _c("span", {}, [[1, 2, 3].includes(text) ? _c("span", {
                class: text === 3 ? "color2294FF" : text === 2 ? "colorFAC04A" : "colorC0C0C0"
              }, [_vm._v("•")]) : _vm._e(), _vm._v(" " + _vm._s(text === 1 ? "执行中" : text === 2 ? "已到期" : text === 3 ? "未执行" : "--") + " ")]);
            }
          }, {
            key: "remainingValidTime",
            fn: function fn(text) {
              return _c("span", {}, [_c("span", {
                class: text === 3 ? "color2294FF" : text === 2 ? "colorFAC04A" : "colorC0C0C0"
              }, [text <= 30 && text >= 0 && text !== null ? _c("a-icon", {
                style: {
                  fontSize: "16px",
                  color: "#FA5051"
                },
                attrs: {
                  type: "exclamation-circle"
                }
              }) : _vm._e()], 1), text >= 0 && text !== null ? _c("span", {
                style: text <= 30 && text >= 0 && text !== null ? "color: #FA5051" : ""
              }, [_vm._v(_vm._s(text) + " 天")]) : text === null ? _c("span", {
                staticStyle: {
                  color: "#909090"
                }
              }, [_vm._v("--")]) : _c("span", {
                staticStyle: {
                  color: "#909090"
                }
              }, [_vm._v("已到期")])]);
            }
          }, {
            key: "action",
            fn: function fn(text, record) {
              return _c("span", {
                staticClass: "table-action"
              }, [_c("span", {
                staticClass: "flex-center",
                staticStyle: {
                  width: "80px"
                }
              }, [_c("a", {
                staticClass: "edit",
                style: {
                  color: record.file ? "var(--primary)" : "#ddd"
                },
                attrs: {
                  type: "link",
                  disabled: !record.file
                },
                on: {
                  click: function click($event) {
                    return _vm.downloadContract(record);
                  }
                }
              }, [_vm._v("下载")])])]);
            }
          }], null, true)
        })];
      }
    }])
  }, [_c("template", {
    slot: "search"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      allowClear: true,
      placeholder: "姓名/手机号/合同名称"
    },
    on: {
      search: _vm.searchContract
    },
    model: {
      value: _vm.contractNameOrNoOrSignNo,
      callback: function callback($$v) {
        _vm.contractNameOrNoOrSignNo = $$v;
      },
      expression: "contractNameOrNoOrSignNo"
    }
  }), _vm.mode === "overview" ? _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择合同状态"
    },
    on: {
      select: _vm.selectFunc
    },
    model: {
      value: _vm.selectTypeIndex,
      callback: function callback($$v) {
        _vm.selectTypeIndex = $$v;
      },
      expression: "selectTypeIndex"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 4
    }
  }, [_vm._v("全部合同")]), _c("a-select-option", {
    attrs: {
      value: 3
    }
  }, [_vm._v("未执行")]), _c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("执行中")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v("已到期")]), _c("a-select-option", {
    attrs: {
      value: -1
    }
  }, [_vm._v("其他")])], 1) : _vm._e(), _vm.mode === "company" ? [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: true,
      placeholder: "请选择合同类型"
    },
    on: {
      change: _vm.searchContract
    },
    model: {
      value: _vm.fileType,
      callback: function callback($$v) {
        _vm.fileType = $$v;
      },
      expression: "fileType"
    }
  }, _vm._l(_vm.enumerateList, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: true,
      placeholder: "请选择员工状态"
    },
    on: {
      change: _vm.searchContract
    },
    model: {
      value: _vm.empStatus,
      callback: function callback($$v) {
        _vm.empStatus = $$v;
      },
      expression: "empStatus"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("待入职")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("在职")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("待离职")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("离职")])], 1)] : [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: true,
      label: "合同类型",
      placeholder: "请选择合同类型"
    },
    model: {
      value: _vm.fileType,
      callback: function callback($$v) {
        _vm.fileType = $$v;
      },
      expression: "fileType"
    }
  }, _vm._l(_vm.enumerateList, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: true,
      label: "员工状态",
      placeholder: "请选择员工状态"
    },
    model: {
      value: _vm.empStatus,
      callback: function callback($$v) {
        _vm.empStatus = $$v;
      },
      expression: "empStatus"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("待入职")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("在职")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("待离职")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("离职")])], 1)], _c("a-input-search", {
    attrs: {
      label: "身份证号",
      allowClear: true,
      placeholder: "身份证号"
    },
    model: {
      value: _vm.idCard,
      callback: function callback($$v) {
        _vm.idCard = $$v;
      },
      expression: "idCard"
    }
  }), _vm.mode === "overview" ? _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: true,
      label: "用工单位",
      placeholder: "请选择用工单位"
    },
    model: {
      value: _vm.tempSubject,
      callback: function callback($$v) {
        _vm.tempSubject = $$v;
      },
      expression: "tempSubject"
    }
  }, _vm._l(_vm.tempSubjectOptions, function (subItem, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: subItem.value
      }
    }, [_vm._v(" " + _vm._s(subItem.label) + " ")]);
  }), 1) : _vm._e(), _c("a-range-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      allowClear: true,
      placeholder: ["合同开始时间", "合同开始时间"],
      label: "合同开始时间",
      format: "YYYY-MM-DD"
    },
    model: {
      value: _vm.publishDateRange,
      callback: function callback($$v) {
        _vm.publishDateRange = $$v;
      },
      expression: "publishDateRange"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1), _c("a-range-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      allowClear: true,
      placeholder: ["合同结束时间", "合同结束时间"],
      label: "合同结束时间",
      format: "YYYY-MM-DD"
    },
    model: {
      value: _vm.endDateRange,
      callback: function callback($$v) {
        _vm.endDateRange = $$v;
      },
      expression: "endDateRange"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1), _c("a-range-picker", {
    staticStyle: {
      width: "320px"
    },
    attrs: {
      allowClear: true,
      placeholder: ["合同签署完成时间", "合同签署完成时间"],
      label: "合同签署完成时间",
      format: "YYYY-MM-DD"
    },
    model: {
      value: _vm.signFinishDateRange,
      callback: function callback($$v) {
        _vm.signFinishDateRange = $$v;
      },
      expression: "signFinishDateRange"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1)], 2)], 2), _c("a-modal", {
    attrs: {
      title: "预览",
      "confirm-loading": _vm.confirmLoading,
      visible: _vm.detailVisible,
      width: "800px"
    },
    on: {
      ok: function ok($event) {
        ;
        _vm.detailVisible = false, _vm.eleContractDetailShow = false;
      },
      cancel: function cancel($event) {
        ;
        _vm.detailVisible = false, _vm.eleContractDetailShow = false;
      }
    }
  }, [_c("div", {
    staticClass: "flex-start-center"
  }, [_vm.eleContractDetailShow ? _c("iframe", {
    staticClass: "checkbox",
    staticStyle: {
      width: "700px",
      height: "70vh",
      overflow: "auto"
    },
    attrs: {
      src: "".concat(_vm.eleContractDetail.path, "?token=").concat(_vm.auth.token)
    }
  }) : _vm._e()])]), _c("a-modal", {
    staticClass: "notice-modal",
    attrs: {
      title: "删除合同",
      visible: _vm.deleteVisible,
      width: "468px",
      okButtonProps: {
        props: {
          disabled: _vm.loading_delete
        }
      }
    },
    on: {
      ok: _vm.confirmDelete,
      cancel: function cancel($event) {
        _vm.deleteVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "deleteline"
  }, [_c("p", [_c("a-icon", {
    attrs: {
      type: "exclamation-circle"
    }
  }), _vm._v("确定删除该合同？")], 1)])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };