import request from "@/utils/request";
export function getPageJobSeekerByStatus(params) {
  return request({
    url: "/recruit2/api/v1/jobSeekers/pageJobSeekerByStatus",
    method: "GET",
    params: params
  });
}
export function getNoSiteId(data) {
  return request({
    url: "".concat(data),
    method: "GET"
  });
}
export function getprincipalNameById(principalId) {
  return request({
    url: "/recruit2/api/v1/jobSeekers/getprincipalNameById/".concat(principalId),
    method: "GET"
  });
}
export function getJobSeekers(data) {
  return request({
    // url: `/recruit2/api/v1/talentPools/${data}`,
    url: "/recruit2/api/v1/deliverRecords/".concat(data),
    method: "GET"
  });
}
export function getTalentPools(data) {
  return request({
    url: "/recruit2/api/v1/talentPools/".concat(data),
    method: "GET"
  });
}
export function getTalentPools2(data) {
  return request({
    url: "/recruit2/api/v1/talentPools/selectByIdOnSystem/".concat(data),
    method: "GET"
  });
}
export function putDeliverRecords(data) {
  return request({
    url: "/recruit2/api/v1/deliverRecords",
    method: "put",
    data: data
  });
}
export function putJobSeekers(data) {
  return request({
    url: "/recruit2/api/v1/talentPools/updateBySystem",
    method: "put",
    data: data
  });
}
export function putJobSeekers2(data) {
  return request({
    url: "/recruit2/api/v1/talentPools/saveBySystem",
    method: "post",
    data: data
  });
}
export function postArrangeAnInterview(data) {
  return request({
    url: "/recruit2/api/v1/interviews/arrangeAnInterview",
    method: "post",
    data: data
  });
}
export function postAddCandidate(data) {
  return request({
    url: "/recruit2/api/v1/jobSeekers/addCandidate",
    method: "post",
    data: data
  });
}
export function getRecruitInfos(params) {
  return request({
    url: "/recruit2/api/v1/recruitInfos/listAllByIsCancel",
    method: "GET",
    params: params
  });
}
export function getNormalPost(params) {
  return request({
    url: "/recruit2/api/v1/recruitInfos/getNormalPost",
    method: "GET",
    params: params
  });
}
export function getBrokers(brokerId) {
  return request({
    url: "/recruit2/api/v1/brokers/".concat(brokerId),
    method: "GET"
  });
}
export function getInfoById(deliverRecordId) {
  return request({
    url: "/recruit2/api/v1/deliverRecords/getInfoById/".concat(deliverRecordId),
    method: "GET"
  });
}