import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper page-miH page-bgBack",
    staticStyle: {
      padding: "24px"
    }
  }, [_c("a-tabs", {
    attrs: {
      defaultValue: _vm.tabsKey
    },
    on: {
      change: _vm.tabsChange
    },
    model: {
      value: _vm.tabsKey,
      callback: function callback($$v) {
        _vm.tabsKey = $$v;
      },
      expression: "tabsKey"
    }
  }, [_c("a-tab-pane", {
    key: "3",
    attrs: {
      tab: "待定薪档案"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c("div", [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "姓名/手机号/身份证号",
      defaultValue: _vm.nameOrPhone2
    },
    on: {
      search: _vm.inputChange2
    }
  }), _c("a-select", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: "",
      placeholder: "工作性质",
      defaultValue: _vm.postTypeCheck,
      options: _vm.postTypeLst
    },
    on: {
      change: _vm.selectCheck2
    },
    model: {
      value: _vm.postTypeCheck,
      callback: function callback($$v) {
        _vm.postTypeCheck = $$v;
      },
      expression: "postTypeCheck"
    }
  }), _c("a-select", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "append-to-body": false,
      allowClear: "",
      placeholder: "员工状态",
      defaultValue: _vm.empStatus2
    },
    on: {
      change: _vm.handleChange2
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 待入职 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 在职 ")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 待离职 ")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v(" 离职 ")])], 1), _c("a-select", {
    staticStyle: {
      width: "225px"
    },
    attrs: {
      allowClear: "",
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      placeholder: "请选择渠道",
      defaultValue: _vm.channelId2
    },
    on: {
      change: _vm.selectCheckChannel
    },
    model: {
      value: _vm.channelId2,
      callback: function callback($$v) {
        _vm.channelId2 = $$v;
      },
      expression: "channelId2"
    }
  }, _vm._l(_vm.Channels, function (item, index) {
    return _c("a-select-option", {
      key: index,
      model: {
        value: item.id,
        callback: function callback($$v) {
          _vm.$set(item, "id", $$v);
        },
        expression: "item.id"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("div", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.isChooseStaff = true;
      }
    }
  }, [_vm._v("批量定薪")])], 1)]), _c("employee-select", {
    attrs: {
      show: _vm.isChooseStaff,
      "default-selected-keys": _vm.selectedRowKeys3,
      "selected-list": _vm.selectedRowKeys3,
      status: 4
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.isChooseStaff = $event;
      },
      "update:selectedList": function updateSelectedList($event) {
        _vm.selectedRowKeys3 = $event;
      },
      "update:selected-list": function updateSelectedList($event) {
        _vm.selectedRowKeys3 = $event;
      },
      change: _vm.goDetail2
    }
  }), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      scroll: {
        x: 1200
      },
      "row-key": "id",
      columns: _vm.columns3,
      "data-source": _vm.notWageList,
      pagination: _vm.pagination2
    },
    on: {
      change: _vm.handleTableChange2
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(text, record) {
        return _c("a", {
          staticStyle: {
            position: "relative",
            cursor: "default"
          }
        }, [record.status === 4 ? _c("img", {
          staticStyle: {
            position: "absolute",
            top: "-16px",
            left: "-15px",
            width: "40px",
            height: "40px"
          },
          attrs: {
            src: _vm.abolitionImg
          }
        }) : _vm._e(), record.status === 1 ? _c("img", {
          staticStyle: {
            position: "absolute",
            top: "-16px",
            left: "-15px",
            width: "40px",
            height: "40px"
          },
          attrs: {
            src: _vm.abolitionImg2
          }
        }) : _vm._e(), _c("p", {
          staticStyle: {
            "margin-bottom": "0",
            color: "#595959"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])]);
      }
    }, {
      key: "channelVO",
      fn: function fn(text) {
        return _c("span", {}, [_vm._v(" " + _vm._s(text && text.name ? text.name : "--") + " ")]);
      }
    }, {
      key: "workHour",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(" " + _vm._s(record.workHour) + " ")]);
      }
    }, {
      key: "joinDate",
      fn: function fn(text) {
        return _c("span", {}, [_vm._v(" " + _vm._s(_vm.moment(text).format("YYYY-MM-DD")) + " ")]);
      }
    }, {
      key: "postType",
      fn: function fn(text) {
        return _c("span", {}, [_vm._v(" " + _vm._s(_vm.postTypeArr[text - 1]) + " ")]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("a", {
          on: {
            click: function click($event) {
              return _vm.goDetail(record, 1);
            }
          }
        }, [_vm._v(" 定薪 ")]);
      }
    }])
  })], 1)], 1), _c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "固定薪资档案"
    }
  }, [_c("employee-select", {
    attrs: {
      show: _vm.isChooseStaff2,
      "default-selected-keys": _vm.selectedRowKeys4,
      "selected-list": _vm.selectedRowKeys4,
      status: 6
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.isChooseStaff2 = $event;
      },
      "update:selectedList": function updateSelectedList($event) {
        _vm.selectedRowKeys4 = $event;
      },
      "update:selected-list": function updateSelectedList($event) {
        _vm.selectedRowKeys4 = $event;
      },
      change: function change($event) {
        return _vm.batchEdit(1);
      }
    }
  }), _c("div", {
    staticClass: "xq-form-search",
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("XqFormBuild", {
    ref: "searchForm",
    attrs: {
      mode: "search",
      "data-source": {},
      "dynamic-data": _vm.dynamicData,
      "form-fields": _vm.FormJson["fields"],
      "form-validators": _vm.FormJson["validators"],
      "form-config": _vm.FormJson["config"]["search"],
      searchSpan: 8
    },
    on: {
      search: _vm.filterRecord
    }
  }), _c("div", {
    staticClass: "xq-form-action",
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end",
      "margin-left": "20px"
    }
  }, [_c("a-space", {
    staticClass: "xq-form-action-left"
  }, [_vm.selectedRowKeys.length ? _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "default"
    },
    on: {
      click: _vm.batch
    }
  }, [_vm._v(" 批量定薪 ")]) : _vm._e(), _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "default"
    },
    on: {
      click: function click($event) {
        _vm.isChooseStaff2 = true;
      }
    }
  }, [_vm._v(" 批量调薪 ")]), _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "default"
    },
    on: {
      click: _vm.goUpload
    }
  }, [_vm._v(" 导入定薪 ")]), _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "default"
    },
    on: {
      click: _vm.goTemplate
    }
  }, [_vm._v(" 定薪模板 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.exportRecord
    }
  }, [_vm._v("导出")])], 1)], 1)], 1)]), _c("a-spin", {
    staticClass: "xq-form-content",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      customize: _vm.FormJson["config"]["table"]["customize"],
      dataSource: _vm.tableData,
      columns: _vm.columns,
      rowSelection: _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
        getCheckboxProps: function getCheckboxProps(record) {
          return {
            props: {
              disabled: record.salarySum !== null,
              // Column configuration not to be checked
              name: record.name
            }
          };
        }
      } : null
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "name" ? _c("span", {
            key: -index - 1,
            staticStyle: {
              position: "relative",
              cursor: "default"
            }
          }, [record.status === "4" ? _c("img", {
            staticStyle: {
              position: "absolute",
              top: "-20px",
              left: "-15px",
              width: "40px",
              height: "40px"
            },
            attrs: {
              src: _vm.abolitionImg
            }
          }) : _vm._e(), record.status === "1" ? _c("img", {
            staticStyle: {
              position: "absolute",
              top: "-20px",
              left: "-15px",
              width: "40px",
              height: "40px"
            },
            attrs: {
              src: _vm.abolitionImg2
            }
          }) : _vm._e()]) : _vm._e(), col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("a-row", [record.salarySum == null ? _c("span", {
            staticClass: "primary",
            on: {
              click: function click($event) {
                return _vm.fileAdd(record);
              }
            }
          }, [_vm._v("定薪")]) : _c("span", [_c("span", {
            staticClass: "primary",
            on: {
              click: function click($event) {
                return _vm.fileEdit(record);
              }
            }
          }, [_vm._v("调薪")]), _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("span", {
            staticClass: "primary",
            on: {
              click: function click($event) {
                return _vm.fileDetail(record);
              }
            }
          }, [_vm._v(" 薪资详情 ")])], 1)])], 1) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ["10", "20", "50", "100"],
      defaultPageSize: 10
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1), _c("a-modal", {
    attrs: {
      "mask-closable": false,
      "destroy-on-close": true,
      title: "新增产品",
      width: 800
    },
    on: {
      cancel: _vm.createCancel,
      ok: _vm.createOk
    },
    model: {
      value: _vm.createModalVisible,
      callback: function callback($$v) {
        _vm.createModalVisible = $$v;
      },
      expression: "createModalVisible"
    }
  }, [_c("XqFormBuild", {
    ref: "createForm",
    attrs: {
      "data-source": {},
      "dynamic-data": _vm.dynamicData,
      "form-fields": _vm.FormJson["fields"],
      "form-validators": _vm.FormJson["validators"],
      "form-config": _vm.FormJson["config"]["create"]
    }
  })], 1), _c("a-modal", {
    attrs: {
      footer: null,
      width: 350,
      closable: false
    },
    model: {
      value: _vm.effectVisible,
      callback: function callback($$v) {
        _vm.effectVisible = $$v;
      },
      expression: "effectVisible"
    }
  }, [_c("span", [_vm._v(" 该员工已有一条"), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("未生效调薪记录")]), _vm._v(" ，你还要继续吗？ ")]), _c("div", {
    staticStyle: {
      "margin-left": "150px",
      "margin-top": "15px"
    }
  }, [_c("a-button", {
    staticClass: "mg_r10",
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("查看")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleOk
    }
  }, [_vm._v("继续")])], 1)])], 1), _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "工价薪资档案",
      "force-render": ""
    }
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning1
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "operation"
  }, [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "姓名/手机号/身份证号",
      defaultValue: _vm.nameOrPhone
    },
    on: {
      search: _vm.inputChange
    }
  }), _c("a-select", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "append-to-body": false,
      allowClear: "",
      placeholder: "员工状态",
      defaultValue: _vm.empStatus
    },
    on: {
      change: _vm.handleChange
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 待入职 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 在职 ")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 待离职 ")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v(" 离职 ")])], 1), _c("a-select", {
    staticStyle: {
      width: "225px",
      "margin-left": "20px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择渠道",
      defaultValue: _vm.channelId
    },
    on: {
      change: _vm.selectCheck
    }
  }, _vm._l(_vm.Channels, function (item, index) {
    return _c("a-select-option", {
      key: index,
      model: {
        value: item.id,
        callback: function callback($$v) {
          _vm.$set(item, "id", $$v);
        },
        expression: "item.id"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("div", [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "default"
    },
    on: {
      click: function click($event) {
        _vm.isChooseStaff3 = true;
      }
    }
  }, [_vm._v(" 批量编辑规则 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goGongJia
    }
  }, [_vm._v("工价规则")]), _c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goLaoWu
    }
  }, [_vm._v("招工劳务费规则")])], 1)]), _c("employee-select", {
    attrs: {
      show: _vm.isChooseStaff3,
      "default-selected-keys": _vm.selectedRowKeys5,
      "selected-list": _vm.selectedRowKeys5,
      status: 7
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.isChooseStaff3 = $event;
      },
      "update:selectedList": function updateSelectedList($event) {
        _vm.selectedRowKeys5 = $event;
      },
      "update:selected-list": function updateSelectedList($event) {
        _vm.selectedRowKeys5 = $event;
      },
      change: function change($event) {
        return _vm.batchEdit(2);
      }
    }
  }), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      scroll: {
        x: 1200
      },
      "row-key": "id",
      columns: _vm.columns2,
      "data-source": _vm.dataList,
      pagination: _vm.pagination
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(text, record) {
        return _c("a", {
          staticStyle: {
            position: "relative",
            cursor: "default"
          }
        }, [record.status === 4 ? _c("img", {
          staticStyle: {
            position: "absolute",
            top: "-16px",
            left: "-15px",
            width: "40px",
            height: "40px"
          },
          attrs: {
            src: _vm.abolitionImg
          }
        }) : _vm._e(), record.status === 1 ? _c("img", {
          staticStyle: {
            position: "absolute",
            top: "-16px",
            left: "-15px",
            width: "40px",
            height: "40px"
          },
          attrs: {
            src: _vm.abolitionImg2
          }
        }) : _vm._e(), _c("p", {
          staticStyle: {
            "margin-bottom": "0",
            color: "#595959"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])]);
      }
    }, {
      key: "workHour",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(" " + _vm._s(record.workHour) + " ")]);
      }
    }, {
      key: "salaryRuleName",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(" " + _vm._s(record.salaryRule ? record.salaryRule.name : "---") + " ")]);
      }
    }, {
      key: "serviceFeeRuleName",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(" " + _vm._s(record.serviceFeeRule ? record.serviceFeeRule.name : "---") + " ")]);
      }
    }, {
      key: "channelName",
      fn: function fn(text) {
        return _c("span", {}, [_vm._v(" " + _vm._s(text ? text : "---") + " ")]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("a", {
          on: {
            click: function click($event) {
              return _vm.goDetail(record, 2);
            }
          }
        }, [_vm._v(" 编辑 ")]);
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };