import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.fixed.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
var columns = [{
  title: "",
  dataIndex: "window",
  scopedSlots: {
    customRender: "window"
  }
}, {
  title: "姓名",
  dataIndex: "name"
}, {
  title: "参保城市",
  dataIndex: "socCityName"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200
}, {
  title: "参保方案",
  dataIndex: "socProgName"
}, {
  title: "社保起缴时间",
  dataIndex: "socStartDate",
  scopedSlots: {
    customRender: "socStartDate"
  }
}, {
  title: "住房公积金起缴时间",
  dataIndex: "fundStartDate",
  scopedSlots: {
    customRender: "fundStartDate"
  }
}, {
  title: "指派公司",
  dataIndex: "assignCompanyName"
}, {
  title: "状态",
  dataIndex: "status",
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "操作",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  }
}];
var tcloums = [{
  title: "缴纳项目",
  dataIndex: "itemName",
  align: "center",
  ellipsis: true
}, {
  title: "缴费基数",
  dataIndex: "cardinalityPerson",
  align: "center",
  ellipsis: true
}, {
  title: "个人比列",
  dataIndex: "persentPersonal",
  scopedSlots: {
    customRender: "persentPersonal"
  },
  align: "center",
  ellipsis: true
}, {
  title: "公司比例",
  dataIndex: "persentCompany",
  scopedSlots: {
    customRender: "persentCompany"
  },
  align: "center",
  ellipsis: true
}, {
  title: "个人缴费",
  dataIndex: "amountPersonal",
  align: "center",
  ellipsis: true
}, {
  title: "公司交费",
  dataIndex: "amountCompany",
  align: "center",
  ellipsis: true
}, {
  title: "缴费合计",
  dataIndex: "",
  scopedSlots: {
    customRender: "all"
  },
  align: "center",
  ellipsis: true
}];
import { mapState } from "vuex";
import feedBack from "./common/feed-back2";
import axios from "axios";
export default {
  name: "ShebaoDetail",
  components: {
    feedBack: feedBack
  },
  data: function data() {
    return {
      keyWord: undefined,
      columns: columns,
      state: -1,
      tcloums: tcloums,
      paidancount: 0,
      yiwanchengcount: 0,
      weiwanchengcount: 0,
      yichangcount: 0,
      defaultKey: "all",
      feedVisible: false,
      feedOptions: {},
      params: {
        status: undefined,
        showItem: true,
        pageNo: 1,
        pageSize: 10,
        name: ""
      },
      jiedanS: false,
      title: "",
      dataSource: [],
      pagination: {
        pageSize: 10,
        total: 0,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        current: 1,
        pageSizeOptions: ["10", "20", "50", "100"],
        showQuickJumper: true,
        showSizeChanger: true
      },
      loading: false,
      selectedRowKeys: [],
      postData: {
        finshOrError: false,
        remake: "",
        socDispatchCompanyId: "",
        socDispatchEmpIdList: []
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    hasSelected: function hasSelected() {
      return this.selectedRowKeys.length > 0;
    }
  }),
  created: function created() {
    // 菜单选中工作台
    this.$store.state.navtab.openKeys = [];
    this.$store.state.navtab.openKeys.push("/dashboard");
    this.$store.state.navtab.selectedKeys = [];
    this.$store.state.navtab.selectedKeys.push("/dashboard");
    this.jiedanS = this.$route.query.jiedanState !== "false";
    this.paidancount = parseInt(this.$route.query.paidancount);
    this.yiwanchengcount = parseInt(this.$route.query.yiwanchengcount);
    this.weiwanchengcount = parseInt(this.$route.query.weiwanchengcount);
    this.yichangcount = parseInt(this.$route.query.yichangcount);
    this.title = this.$route.query.title;
    // console.log(this.weiwanchengcount);
    // console.log("------------" + this.jiedanS);
    var lc = localStorage.getItem("listjiedan");
    this.tcloums = [];
    var ss = lc.split(",");
    var that = this;
    ss.forEach(function (item) {
      var h = {
        title: item,
        dataIndex: item,
        align: "center",
        ellipsis: true,
        width: 150
      };
      if (item === "姓名") {
        h.fixed = "left";
      } else if (item === "身份证号") {
        h.width = 220;
      }
      that.tcloums.push(h);
    });
    that.tcloums.push({
      title: "最后反馈内容",
      dataIndex: "remark",
      width: 120,
      fixed: "right",
      ellipsis: true
    });
    that.tcloums.push({
      title: "状态",
      dataIndex: "status",
      scopedSlots: {
        customRender: "status"
      },
      width: 90,
      fixed: "right",
      ellipsis: true
    });
    that.tcloums.push({
      title: "操作",
      dataIndex: "action",
      width: 80,
      align: "center",
      fixed: "right",
      scopedSlots: {
        customRender: "action"
      },
      ellipsis: true
    });
    // console.log(this.tcloums)
    this.getDetail();
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/missionCenters/selectReceiveTopStatistics/" + this.$route.query.socDispatchId,
        method: "GET"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.paidancount = data.dispatchEmpCount;
        _this.yiwanchengcount = data.completedCount;
        _this.weiwanchengcount = data.unfinishedCount;
        _this.yichangcount = data.abnormalCount;
      });
    },
    clickTab: function clickTab(index) {
      this.state = index;
      this.getDetail();
    },
    // 初始化数据
    initData: function initData() {
      this.pagination.current = 1;
      this.params.pageNo = 1;
      this.selectedRowKeys = [];
    },
    start: function start() {
      // let erro = false;
      // for (var i = 0; i < this.dataSource.length; i++) {
      //     for (var j = 0; j < count; j++) {
      //       if (this.dataSource[i].id === this.selectedRowKeys[j]) {
      //         name += this.dataSource[i]["姓名"] + ",";
      //         cards += this.dataSource[i]["身份证号"] + ",";
      //       }
      //     }
      //   }
      // if(erro){
      //   this.$message.error("接单后，才能进行反馈");
      //   return;
      // }
      this.loading = true;
      this.loading = false;
      if (this.selectedRowKeys.length > 1) {
        var name = "";
        var cards = "";
        var count = this.selectedRowKeys.length > 3 ? 3 : this.selectedRowKeys.length;
        for (var i = 0; i < this.dataSource.length; i++) {
          for (var j = 0; j < count; j++) {
            if (this.dataSource[i].id === this.selectedRowKeys[j]) {
              name += this.dataSource[i]["姓名"] + ",";
              cards += this.dataSource[i]["身份证号"] + ",";
            }
          }
        }
        this.feedOptions = {
          姓名: name + "等" + this.selectedRowKeys.length + "人",
          身份证号: cards + "等" + this.selectedRowKeys.length + "人",
          status: 0
        };
      } else {
        var _name = "";
        var icard = "";
        for (var ii = 0; ii < this.dataSource.length; ii++) {
          if (this.dataSource[ii].id === this.selectedRowKeys[0]) {
            _name = this.dataSource[ii]["姓名"];
            icard = this.dataSource[ii]["身份证号"];
          }
        }
        this.feedOptions = {
          姓名: _name,
          身份证号: icard,
          status: 0
        };
      }
      this.feedVisible = true;
    },
    daochu: function daochu() {
      var _this2 = this;
      var dat = {
        missionCenterId: this.$route.query.socDispatchId
      };
      if (this.keyWord) {
        dat.keyWord = this.keyWord;
      }
      if (this.state !== -1) {
        dat.state = this.state;
      }
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        dat.itemIdList = this.selectedRowKeys;
      }
      axios({
        url: "/hr/api/v1/missionCenterItems/exportReceivePath",
        method: "post",
        data: dat,
        responseType: "blob"
        // headers: {
        //   token: token,
        //   "Content-Type": "application/json"
        // }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "".concat(_this2.title) + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      // this.$request({
      //   url: `/hr/api/v1/missionCenterItems/exportReceivePath/` + this.$route.query.socDispatchId,
      //   method: "POST",
      //   responseType: "blob"
      // }).then(({ data }) => {
      // if (data && data.type === "application/json") {
      //       const link = document.createElement("a");
      //     const blob = new Blob([data], {
      //       type: "application/vnd.ms-excel"
      //     });
      //     link.style.display = "none";
      //     link.href = URL.createObjectURL(blob);
      //     link.setAttribute("download", `${this.$store.state.hr.companyName}_` + "员工导出记录" + ".xlsx");
      //     document.body.appendChild(link);
      //     link.click();
      //     document.body.removeChild(link);
      // }
      // });
    },
    onSelectChange: function onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 翻页
    handlePage: function handlePage(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.params.pageNo = this.pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getDetail();
    },
    handelEvent: function handelEvent(e) {
      if (!this.jiedanS) {
        return;
      }
      this.feedVisible = true;
      this.feedOptions = e;
      this.$refs.child.$emit("getHistory", this.feedOptions.id);
    },
    goBack: function goBack() {
      // this.$router.push({
      //   path: `/shebao/jiedan`,
      //   query: { ...this.$route.query }
      // });
      this.$router.go(-1);
    },
    onConfi: function onConfi() {
      var _this3 = this;
      this.$request({
        url: "/hr/api/v1/missionCenters/confirmReceive/" + this.$route.query.socDispatchId,
        method: "PUT"
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this3.jiedanS = data;
        if (_this3.jiedanS) {
          _this3.$message.success("接单成功");
        }
      });
    },
    getDetail: function getDetail() {
      var _this4 = this;
      var pa = {
        missionCenterId: this.$route.query.socDispatchId,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      if (this.state >= 0) {
        pa.state = this.state;
      }
      if (this.keyWord) {
        pa.keyWord = this.keyWord;
      }
      this.$request({
        url: "/hr/api/v1/missionCenterItems/selectMissionCenterItem/",
        method: "GET",
        params: pa
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this4.dataSource = [];
        var that = _this4;
        data.entities.forEach(function (item) {
          var j = JSON.parse(item.tableData);
          j.id = item.id;
          j.status = item.state;
          j["action"] = "反馈";
          j["remark"] = item.remark;
          that.dataSource.push(j);
        });
        _this4.pagination.total = data.entityCount;
      });
    },
    // 搜索姓名
    onSearchName: function onSearchName(name) {
      this.initData();
      this.keyWord = name;
      this.getDetail();
    },
    handleDetail: function handleDetail(_ref4) {
      var visible = _ref4.visible,
        success = _ref4.success,
        options = _ref4.options;
      this.feedVisible = visible;
      if (!success) {
        this.selectedRowKeys = [];
        this.getDetail();
      }
      if (success) {
        this.postData.state = options.status;
        this.postData.remark = options.remake;
        // this.postData.itemIds = this.$route.query.id;
        this.postData.itemIdList = this.selectedRowKeys.length ? this.selectedRowKeys : [options.id];
        this.submitFeedBack();
      }
    },
    // 反馈提交
    submitFeedBack: function submitFeedBack() {
      var _this5 = this;
      this.$request({
        url: "/hr/api/v1/missionCenterItems/feedback",
        method: "post",
        data: this.postData
      }).then(function (_ref5) {
        var success = _ref5.success;
        if (success) {
          _this5.initData();
          _this5.getDetail();
          _this5.getInfo();
        }
      });
    }
  }
};