import request from "@/utils/request";

// 查询分组下公司
export function findCompanyGroupReq(data) {
  return request({
    url: "/hr/api/v1/childrenCompany/listAll",
    method: "GET",
    params: data,
    routeChangeCancel: false
  });
}

// 获取公司名
export function childCompanyName(data) {
  return request({
    url: "/hr/api/v1/users/childCompanyName",
    method: "GET",
    params: data
  });
}
// 查询用户用工单位
export function getByUserId(params) {
  return request({
    url: "/hr/api/v1/childrenCompany/selectByUserId",
    method: "GET",
    params: params
  });
}