import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar-wrapper",
    style: _vm.sourceType === 1 ? "background: var(--primary)" : ""
  }, [_c("div", {
    staticClass: "navbar-wrapper-right"
  }, [_c("div", {
    staticClass: "nav-right-wrapper border-no-left border-no-padding-right"
  }, [_c("a-dropdown", {
    staticClass: "avatar-dropdown",
    attrs: {
      trigger: ["hover"],
      placement: "bottomRight"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("img", {
    staticClass: "avatar-img",
    attrs: {
      src: _vm.avatar
    }
  })]), _c("a-menu", {
    attrs: {
      slot: "overlay",
      "default-selected-keys": _vm.defaultSelectedKeys
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "0",
    on: {
      click: _vm.goPerson
    }
  }, [_c("div", {
    staticClass: "item-div"
  }, [_c("img", {
    staticClass: "avatar-img-com",
    attrs: {
      src: _vm.avatar
    }
  }), _c("span", {
    staticClass: "item-span title-name ove"
  }, [_vm._v(_vm._s(_vm.name))]), _c("a-icon", {
    staticClass: "avatar-mid-right jt",
    attrs: {
      type: "right"
    }
  })], 1)]), _c("a-menu-divider", {
    staticStyle: {
      background: "#f2f2f2"
    }
  }), _c("a-menu-item", {
    key: "1",
    on: {
      click: _vm.logout
    }
  }, [_c("a-icon", {
    attrs: {
      type: "poweroff"
    }
  }), _c("span", {
    staticClass: "pd_l4"
  }, [_vm._v("退出登录")])], 1), _vm.showShangbao ? _c("a-menu-item", {
    key: "shangbao",
    on: {
      click: _vm.goShangbao
    }
  }, [_c("span", [_vm._v("保险公司端-商保")])]) : _vm._e(), _vm.showConsole ? _c("a-menu-item", {
    key: "console"
  }, [_c("a", {
    attrs: {
      href: "#/console"
    }
  }, [_vm._v("打开控制台")])]) : _vm._e(), _vm.permissions_site.length > 0 ? [_c("a-menu-divider", {
    staticStyle: {
      background: "#f2f2f2"
    }
  }), _vm._l(_vm.permissions_site, function (site, index) {
    return _c("a-menu-item", {
      key: (index + 2).toString()
    }, [_c("div", {
      staticClass: "item-div",
      on: {
        click: function click($event) {
          return _vm.switchSite(site);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "shop"
      }
    }), _c("span", {
      staticClass: "item-span ove"
    }, [_vm._v(_vm._s(site.name))])], 1)]);
  })] : _vm._e()], 2)], 1)], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };