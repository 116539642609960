var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.backImg ? _c("div", {
    staticClass: "auth-login-box"
  }, [_c("div", {
    staticClass: "auth-bg"
  }, [_c("img", {
    attrs: {
      src: _vm.backImg
    }
  })]), _c("div", {
    staticClass: "auth-login"
  }, [_c("img", {
    staticClass: "auth-login-logo",
    attrs: {
      src: _vm.logo
    }
  }), _c("router-view"), _vm.showBeian ? _c("div", {
    staticStyle: {
      position: "absolute",
      width: "300px",
      left: "50%",
      "margin-left": "-150px",
      bottom: "-60px",
      "text-align": "center"
    }
  }, [_vm._v(" 福建省启业人才发展集团有限公司 © 2022 "), _c("br"), _c("a", {
    attrs: {
      href: "https://beian.miit.gov.cn/",
      target: "_blank"
    }
  }, [_vm._v("闽ICP备2022014004号-1")])]) : _vm._e()], 1)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };