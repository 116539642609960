import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack p24"
  }, [_c("div", {
    staticClass: "titleBox"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "姓名/手机号/身份证号",
      defaultValue: _vm.nameOrPhoneOrIdCard
    },
    on: {
      search: _vm.onSearch
    }
  }), _c("div", [_c("QrcodeForPay", {
    attrs: {
      needBatch: false,
      title: "员工邀请码",
      showFunc: _vm.showQrcode
    }
  }), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addEmp
    }
  }, [_vm._v("新增人员")]), _c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuClick
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "1"
  }, [_vm._v(" 全部导出 ")]), _c("a-menu-item", {
    key: "2"
  }, [_vm._v(" 批量导出 ")])], 1), _c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    }
  }, [_vm._v(" 导出 "), _c("a-icon", {
    attrs: {
      type: "down"
    }
  })], 1)], 1)], 1)], 1), _c("employee-select", {
    attrs: {
      show: _vm.empSelectVisible,
      "default-selected-keys": _vm.selectedList,
      "selected-list": _vm.selectedList,
      status: 5
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.empSelectVisible = $event;
      },
      "update:selectedList": function updateSelectedList($event) {
        _vm.selectedList = $event;
      },
      "update:selected-list": function updateSelectedList($event) {
        _vm.selectedList = $event;
      },
      change: _vm.onEmployeeSelectChange
    }
  }), _c("a-modal", {
    staticClass: "modal-system",
    attrs: {
      visible: _vm.empVisible,
      title: "新增人员",
      placement: "right",
      okText: "确认"
    },
    on: {
      ok: _vm.addEmpOk,
      cancel: _vm.addEmpCancel
    }
  }, [_c("a-form-model", {
    ref: "addForm",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "name",
    attrs: {
      prop: "name",
      label: "姓名",
      rules: {
        required: true,
        message: "姓名必填"
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入名称"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("a-form-model-item", {
    ref: "phone",
    attrs: {
      prop: "phone",
      label: "手机号码"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入手机号码"
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c("a-form-model-item", {
    ref: "idCard",
    attrs: {
      prop: "idCard",
      label: "身份证号",
      rules: {
        required: true,
        message: "身份证号必填"
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入身份证号"
    },
    model: {
      value: _vm.form.idCard,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "idCard", $$v);
      },
      expression: "form.idCard"
    }
  })], 1), _c("a-form-model-item", {
    ref: "bankIdentity",
    attrs: {
      prop: "bankIdentity",
      label: "银行卡号",
      rules: {
        required: false
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入银行卡号"
    },
    model: {
      value: _vm.form.bankIdentity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bankIdentity", $$v);
      },
      expression: "form.bankIdentity"
    }
  })], 1), _c("a-form-model-item", {
    ref: "zfbIdentity",
    attrs: {
      prop: "zfbIdentity",
      label: "支付宝账号",
      rules: {
        required: false
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入支付宝账号"
    },
    model: {
      value: _vm.form.zfbIdentity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "zfbIdentity", $$v);
      },
      expression: "form.zfbIdentity"
    }
  })], 1)], 1)], 1), _c("a-modal", {
    staticClass: "modal-system",
    attrs: {
      visible: _vm.detailVisible,
      title: "发薪人员详情",
      placement: "right",
      okText: "确认",
      width: 750,
      footer: null
    },
    on: {
      ok: _vm.addDetailOk,
      cancel: function cancel($event) {
        _vm.detailVisible = false;
      }
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", [_c("a-avatar", {
    attrs: {
      size: 56,
      src: _vm.editForm.avatar ? _vm.editForm.avatar : "",
      icon: "user"
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-left": "12px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#3c3d43"
    }
  }, [_vm._v(" " + _vm._s(_vm.editForm.name) + " ")]), _c("a-icon", {
    staticStyle: {
      color: "#6bc739"
    },
    attrs: {
      type: "wechat"
    }
  }), _c("span", {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "400",
      color: "#767885",
      "margin-left": "8px"
    }
  }, [_vm._v(_vm._s(_vm.editForm.isVxBinding ? "已绑定" : "未绑定"))])], 1)]), _c("div", {
    staticClass: "textBox"
  }, [_c("div", [_vm._v("个人信息")]), !_vm.editState ? _c("div", {
    staticClass: "editForm",
    on: {
      click: _vm.toEditState
    }
  }, [_c("a-icon", {
    staticStyle: {
      "margin-right": "4px"
    },
    attrs: {
      type: "form"
    }
  }), _vm._v("编辑")], 1) : _vm._e()]), _c("div", {
    staticClass: "itemBox"
  }, [_c("div", {
    staticClass: "items"
  }, [_c("div", {
    staticClass: "itemsTitle"
  }, [_vm._v("姓名：")]), !_vm.editState ? _c("div", {
    staticClass: "itemsContent"
  }, [_vm._v(_vm._s(_vm.editForm.name))]) : _c("a-input", {
    staticStyle: {
      width: "200px",
      "margin-top": "4px"
    },
    model: {
      value: _vm.editForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm, "name", $$v);
      },
      expression: "editForm.name"
    }
  })], 1), _c("div", {
    staticClass: "items"
  }, [_c("div", {
    staticClass: "itemsTitle"
  }, [_vm._v("手机号码：")]), !_vm.editState ? _c("div", {
    staticClass: "itemsContent"
  }, [_vm._v(_vm._s(_vm.editForm.phone))]) : _c("a-input", {
    staticStyle: {
      width: "200px",
      "margin-top": "4px"
    },
    model: {
      value: _vm.editForm.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm, "phone", $$v);
      },
      expression: "editForm.phone"
    }
  })], 1), _c("div", {
    staticClass: "items"
  }, [_c("div", {
    staticClass: "itemsTitle"
  }, [_vm._v("身份证号：")]), _c("div", {
    staticClass: "itemsContent"
  }, [_vm._v(_vm._s(_vm.editForm.idCard))])]), _c("div", {
    staticClass: "items"
  }, [_c("div", {
    staticClass: "itemsTitle"
  }, [_vm._v("银行名称：")]), _c("div", {
    staticClass: "itemsContent"
  }, [_vm._v(_vm._s(_vm.editForm.openingBank))])]), _c("div", {
    staticClass: "items"
  }, [_c("div", {
    staticClass: "itemsTitle"
  }, [_vm._v("银行卡号：")]), !_vm.editState ? _c("div", {
    staticClass: "itemsContent"
  }, [_vm._v(_vm._s(_vm.editForm.bankIdentity))]) : _c("a-input", {
    staticStyle: {
      width: "200px",
      "margin-top": "4px"
    },
    model: {
      value: _vm.editForm.bankIdentity,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm, "bankIdentity", $$v);
      },
      expression: "editForm.bankIdentity"
    }
  })], 1), _c("div", {
    staticClass: "items"
  }, [_c("div", {
    staticClass: "itemsTitle"
  }, [_vm._v("支付宝账号：")]), !_vm.editState ? _c("div", {
    staticClass: "itemsContent"
  }, [_vm._v(_vm._s(_vm.editForm.zfbIdentity))]) : _c("a-input", {
    staticStyle: {
      width: "200px",
      "margin-top": "4px"
    },
    model: {
      value: _vm.editForm.zfbIdentity,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm, "zfbIdentity", $$v);
      },
      expression: "editForm.zfbIdentity"
    }
  })], 1)]), _vm.editState ? _c("div", {
    staticClass: "buttonBox"
  }, [_c("a-button", {
    on: {
      click: _vm.editCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.editOk
    }
  }, [_vm._v("保存")])], 1) : _vm._e(), _c("div", {
    staticClass: "textBox",
    staticStyle: {
      "margin-top": "40px"
    }
  }, [_vm._v("发薪详情")]), _c("a-table", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      columns: _vm.detailColumns,
      pagination: _vm.pagination2,
      "data-source": _vm.detailList
    },
    on: {
      change: _vm.handleTableChange2
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function fn(text) {
        return _c("div", {}, [_vm._v(_vm._s(text === 1 ? "支付宝" : text === 2 ? "微工卡" : text === 3 ? "银行卡" : "--"))]);
      }
    }, {
      key: "payTime",
      fn: function fn(text) {
        return _c("div", {}, [_vm._v(_vm._s(_vm.moment(text).format("YYYY-MM-DD HH:mm:ss")))]);
      }
    }])
  })], 1), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      loading: _vm.loading,
      scroll: {
        x: 1500
      },
      columns: _vm.columns,
      "data-source": _vm.dataList,
      pagination: _vm.pagination
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return _c("div", {
          staticStyle: {
            display: "flex"
          }
        }, [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.showDetail(record);
            }
          }
        }, [_vm._v("详情")]), _c("a-popconfirm", {
          attrs: {
            title: "确定删除?"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.deletePeople(record);
            }
          }
        }, [_c("a-icon", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            slot: "icon",
            type: "question-circle-o"
          },
          slot: "icon"
        }), _c("a-button", {
          attrs: {
            type: "link"
          }
        }, [_vm._v("删除")])], 1)], 1);
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };