export var columns = [{
  title: "制度标题",
  dataIndex: "institutionTitle",
  scopedSlots: {
    customRender: "institutionTitle"
  }
}, {
  title: "制度创建日期",
  dataIndex: "gmtCreate",
  scopedSlots: {
    customRender: "gmtCreate"
  }
}, {
  title: "最后编辑人",
  dataIndex: "modifiedUserName",
  scopedSlots: {
    customRender: "modifiedUserName"
  }
}, {
  title: "开启状态",
  dataIndex: "isOpen",
  scopedSlots: {
    customRender: "isOpen"
  }
}, {
  title: "操作",
  dataIndex: "action",
  width: 140,
  scopedSlots: {
    customRender: "action"
  }
}];