export default {
  fields: {
    name: {
      model: "name"
    },
    phone: {
      model: "phone"
    },
    gmtCreate1: {
      model: "gmtCreate1"
    },
    isWork: {
      model: "isWork"
    },
    dayWorkTime: {
      model: "dayWorkTime"
    },
    workDay: {
      model: "workDay"
    },
    gmtModified: {
      model: "gmtModified"
    },
    editBy: {
      model: "editBy"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "name",
        title: "姓名"
      }, {
        model: "phone",
        title: "手机号"
      }, {
        model: "gmtCreate1",
        title: "打卡日期"
      }, {
        model: "isWork",
        title: "考勤状态"
      }, {
        model: "dayWorkTime",
        title: "工时"
      }, {
        model: "workDay",
        title: "出勤天数"
      }, {
        model: "gmtModified",
        title: "编辑时间"
      }, {
        model: "editBy",
        title: "编辑人"
      }],
      customize: {
        // rowKey: "id"
      },
      extend: {
        batch: false,
        action: false
      }
    }
  }
};