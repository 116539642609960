import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBackPage
    }
  }), _c("div", {
    staticClass: "body"
  }, [_c("div", {
    staticClass: "headerBox",
    staticStyle: {
      height: "104px",
      border: "1px solid #e8e8e8"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", [_c("img", {
    staticStyle: {
      width: "56px",
      height: "56px"
    },
    attrs: {
      src: require("@/assets/icons/importImg.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "textBox"
  }, [_c("div", {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "500",
      color: "#3c3d43",
      "margin-left": "24px"
    }
  }, [_vm._v(_vm._s(_vm.actionForm.TaskName))]), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "contentName"
  }, [_vm._v(" 派单月份："), _c("span", {
    staticStyle: {
      color: "#55565d"
    }
  }, [_vm._v(_vm._s(_vm.actionForm.selectDate ? _vm.moment(_vm.actionForm.selectDate).format("YYYY") + "年" + _vm.moment(_vm.actionForm.selectDate).format("MM") + "月" : "--"))])]), _c("div", {
    staticClass: "contentName"
  }, [_vm._v(" 派单模式："), _c("span", {
    staticStyle: {
      color: "#55565d"
    }
  }, [_vm._v(_vm._s(_vm.actionForm.tableName))])]), _vm.actionForm.companyList.length > 0 ? _c("div", {
    staticClass: "contentName",
    staticStyle: {
      width: "350px",
      overflow: "hidden",
      "text-overflow": "ellipsis",
      "white-space": "nowrap"
    }
  }, [_vm._v(" 派单对象："), _vm._l(_vm.actionForm.companyList, function (item, index) {
    return _c("span", {
      key: index,
      staticStyle: {
        color: "#55565d"
      }
    }, [_vm._v(_vm._s(item.label + " "))]);
  })], 2) : _vm._e(), _vm.actionForm.selectCompanyId.length > 0 ? _c("div", {
    staticClass: "contentName",
    staticStyle: {
      width: "350px",
      overflow: "hidden",
      "text-overflow": "ellipsis",
      "white-space": "nowrap"
    }
  }, [_vm._v(" 派单对象："), _vm._l(_vm.actionForm.selectCompanyId, function (item, index) {
    return _c("span", {
      key: index
    }, [_vm._v(_vm._s(item.label + " "))]);
  })], 2) : _vm._e()])])]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.showEdit
    }
  }, [_vm._v("编辑")])], 1)]), _c("a-modal", {
    attrs: {
      title: "编辑",
      width: 600,
      visible: _vm.editVisible
    },
    on: {
      cancel: function cancel($event) {
        _vm.editVisible = false;
      },
      ok: _vm.editOk
    }
  }, [_c("a-form", {
    staticClass: "form",
    attrs: {
      form: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-row", [_c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "派单任务名称"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.TaskName,
      callback: function callback($$v) {
        _vm.TaskName = $$v;
      },
      expression: "TaskName"
    }
  })], 1)], 1), _c("a-row", [_c("a-form-item", {
    attrs: {
      label: "选择模式"
    }
  }, [_c("SelectWithInputAdd", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      editable: "",
      allowClear: "",
      dictCode: "1204",
      placeholder: "请选择模式"
    },
    model: {
      value: _vm.tableName,
      callback: function callback($$v) {
        _vm.tableName = $$v;
      },
      expression: "tableName"
    }
  })], 1)], 1), _c("a-row", [_c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "选择月份"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      format: "YYYY-MM",
      placeholder: "请选择"
    },
    model: {
      value: _vm.selectDate,
      callback: function callback($$v) {
        _vm.selectDate = $$v;
      },
      expression: "selectDate"
    }
  })], 1)], 1), _c("a-row", [_c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "选择派单对象"
    }
  }, [_c("a-radio-group", {
    attrs: {
      name: "radioGroup"
    },
    on: {
      change: _vm.onRadioChange
    },
    model: {
      value: _vm.comtype,
      callback: function callback($$v) {
        _vm.comtype = $$v;
      },
      expression: "comtype"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 固定用工单位 ")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 固定业务员 ")])], 1)], 1)], 1), _c("a-row", [_c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "派单单位/业务员"
    }
  }, [_vm.comtype === 1 ? _c("a-tree-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      "tree-data": _vm.companyTreeWithUuid,
      filterTreeNode: false,
      replaceFields: _vm.replaceFields2,
      labelInValue: "",
      showSearch: "",
      treeNodeFilterProp: "title",
      placeholder: "请选择用工单位",
      treeCheckable: ""
    },
    model: {
      value: _vm.companyList,
      callback: function callback($$v) {
        _vm.companyList = $$v;
      },
      expression: "companyList"
    }
  }) : _vm._e(), _vm.comtype === 2 ? _c("a-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      labelInValue: "",
      mode: "multiple",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      placeholder: "请选择业务员"
    },
    model: {
      value: _vm.selectCompanyId,
      callback: function callback($$v) {
        _vm.selectCompanyId = $$v;
      },
      expression: "selectCompanyId"
    }
  }, _vm._l(_vm.dataSource, function (i) {
    return _c("a-select-option", {
      key: i.id
    }, [_vm._v(" " + _vm._s(i.name) + " ")]);
  }), 1) : _vm._e()], 1)], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "发现错误数据",
      visible: _vm.erroInfo
    },
    on: {
      cancel: function cancel($event) {
        _vm.erroInfo = false;
      }
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    on: {
      click: function click($event) {
        _vm.erroInfo = false;
      }
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.erroNext
    }
  }, [_vm._v(" 继续 ")])], 1), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/icons/ask.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v("将只导入正确数据，忽略错误数据，是否继续？")])])], 2), _vm.numberOfErr === 0 ? _c("a-alert", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      type: "success",
      "show-icon": ""
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    },
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_c("div", [_vm._v(" " + _vm._s("导入社保派单“" + _vm.$route.query.fileName + "”共" + _vm.datalist.length + "人，无异常数据") + " ")])])]) : _vm._e(), _vm.numberOfErr > 0 ? _c("a-alert", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      type: "error",
      "show-icon": ""
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    },
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_c("div", [_vm._v(" " + _vm._s("导入社保派单“" + _vm.$route.query.fileName + "”共" + _vm.datalist.length + "人，有" + _vm.numberOfErr + "条数据异常，请尽快处理") + " ")]), _c("div", {
    staticStyle: {
      color: "#ff7085",
      cursor: "pointer"
    },
    on: {
      click: _vm.importErrData
    }
  }, [_vm._v("导出错误数据")])])]) : _vm._e(), _c("div", {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      scroll: {
        x: 1200
      },
      "data-source": _vm.datalist,
      pagination: false,
      "row-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "姓名",
      fn: function fn(text, record) {
        return _c("div", {
          staticStyle: {
            position: "relative"
          }
        }, [record.nameWrongs && !record.keep === true ? _c("div", {
          staticClass: "errImg",
          staticStyle: {
            position: "absolute",
            top: "-18px",
            left: "-15px"
          }
        }) : _vm._e(), record.keep === true && record.nameWrongs ? _c("div", {
          staticClass: "ignoreImg",
          staticStyle: {
            position: "absolute",
            top: "-18px",
            left: "-15px"
          }
        }) : _vm._e(), _c("a-popover", {
          attrs: {
            title: "数据错误"
          }
        }, [_c("template", {
          slot: "content"
        }, [_c("span", [_vm._v(_vm._s(record.nameWrongs))])]), record.nameWrongs ? _c("span", {
          staticStyle: {
            "margin-bottom": "0",
            cursor: "pointer"
          }
        }, [_vm._v(" " + _vm._s(text ? text : "--") + " ")]) : _vm._e()], 2), !record.nameWrongs ? _c("span", [_vm._v(" " + _vm._s(text))]) : _vm._e()], 1);
      }
    }, {
      key: "身份证号",
      fn: function fn(text, record) {
        return _c("div", {
          staticStyle: {
            position: "relative"
          }
        }, [record.idCardWrongs && !record.keep === true ? _c("div", {
          staticClass: "errImg",
          staticStyle: {
            position: "absolute",
            top: "-18px",
            left: "-15px"
          }
        }) : _vm._e(), record.keep === true && record.idCardWrongs ? _c("div", {
          staticClass: "ignoreImg",
          staticStyle: {
            position: "absolute",
            top: "-18px",
            left: "-15px"
          }
        }) : _vm._e(), _c("a-popover", {
          attrs: {
            title: "数据错误"
          }
        }, [_c("template", {
          slot: "content"
        }, [_c("span", [_vm._v(_vm._s(record.idCardWrongs))])]), record.idCardWrongs ? _c("span", {
          staticStyle: {
            "margin-bottom": "0",
            cursor: "pointer"
          }
        }, [_vm._v(" " + _vm._s(text ? text : "--") + " ")]) : _vm._e()], 2), !record.idCardWrongs ? _c("span", [_vm._v(" " + _vm._s(text))]) : _vm._e()], 1);
      }
    }, {
      key: "action",
      fn: function fn(text, record, index) {
        return _c("div", {}, [record.nameWrongs || record.idCardWrongs ? _c("a-button", {
          attrs: {
            size: "small",
            type: "link",
            disabled: record.keep === true
          },
          on: {
            click: function click($event) {
              return _vm.keepErrItem(record);
            }
          }
        }, [_vm._v("保留")]) : _vm._e(), _c("a-button", {
          staticStyle: {
            color: "#ff7085"
          },
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.deleteItem(index);
            }
          }
        }, [_vm._v("删除")])], 1);
      }
    }])
  })], 1), _c("div", {
    staticStyle: {
      "margin-top": "24px",
      display: "flex",
      "margin-left": "45%"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.goBackPage
    }
  }, [_vm._v("重新上传")]), _c("a-button", {
    staticStyle: {
      "margin-left": "24px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveExcel
    }
  }, [_vm._v("下一步")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };