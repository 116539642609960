import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      width: 820,
      title: "WiFi添加"
    },
    on: {
      ok: _vm.handleOk
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    staticClass: "lab_l form_l"
  }, [_c("a-form-item", {
    attrs: {
      label: "WiFi名称",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.obj.name,
      callback: function callback($$v) {
        _vm.$set(_vm.obj, "name", $$v);
      },
      expression: "obj.name"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "BSSID",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.obj.macAddr,
      callback: function callback($$v) {
        _vm.$set(_vm.obj, "macAddr", $$v);
      },
      expression: "obj.macAddr"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "获取方式"
    }
  }), _c("a-row", [_vm._v("1.可以向公司IT部门询问公司WiFi的BSSID ")]), _c("a-row", [_vm._v("2.请确保路由器BSSID不会动态变化，否则在连接Wi-Fi后可能出现“不在打卡范围内”等异常")]), _c("div", {
    staticStyle: {
      height: "100px"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };