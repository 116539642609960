export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    eventTypeLst: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    templateName: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      nameOrIdCard: "",
      eventType: undefined,
      currentWorkflow: "",
      takeOrdersBeginDate: null,
      takeOrdersEndDate: null,
      companyName: "",
      takeOrdersUserName: "",
      workCity: ""
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    doSearch: function doSearch() {
      var params = {
        nameOrIdCard: this.nameOrIdCard,
        eventType: this.eventType,
        currentWorkflow: this.currentWorkflow,
        takeOrdersBeginDate: this.takeOrdersBeginDate,
        takeOrdersEndDate: this.takeOrdersEndDate,
        companyName: this.companyName,
        takeOrdersUserName: this.takeOrdersUserName,
        workCity: this.workCity
      };
      for (var key in params) {
        if (!(params[key] || params[key] === false)) {
          delete params[key];
        }
      }
      if (params["takeOrdersBeginDate"]) {
        params["takeOrdersBeginDate"] = params["takeOrdersBeginDate"].format("YYYY-MM-DD");
      }
      if (params["takeOrdersEndDate"]) {
        params["takeOrdersEndDate"] = params["takeOrdersEndDate"].format("YYYY-MM-DD");
      }
      this.$emit("search", params);
    },
    doReset: function doReset() {
      this.nameOrIdCard = "";
      this.eventType = undefined;
      this.currentWorkflow = "";
      this.takeOrdersBeginDate = null;
      this.takeOrdersEndDate = null;
      this.companyName = "";
      this.takeOrdersUserName = "";
      this.workCity = "";
      this.$emit("search", {});
    }
  }
};