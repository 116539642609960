import { Wages, WagesSet } from "@/api/wages";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    reportOptions: {},
    detialData: {}
  },
  data: function data() {
    return {
      // 编辑
      editor: true
    };
  },
  methods: {
    // 确认数据
    onOk: function onOk() {
      Wages({
        url: WagesSet.salaryDetial,
        method: "PUT",
        data: {}
      }).then(function (_ref) {
        var data = _ref.data;
      });
      this.$emit("update:show", false);
    },
    // 取消
    onCancle: function onCancle() {
      this.$emit("update:show", false);
    }
  }
};