import request from "@/utils/request";
export function checkMonth(params) {
  return request({
    url: "/hr/api/v1/attendPunchRecords/pageAttendPunchRecord",
    method: "get",
    params: params
  });
}
export function checkStaffMonth(params) {
  return request({
    url: "/hr/api/v1/attendPunchRecords/selectAttendMonth",
    method: "get",
    params: params
  });
}
export function checkStaffDay(params) {
  return request({
    url: "/hr/api/v1/attendPunchRecords/selectAttendByDayAndEmpId",
    method: "get",
    params: params
  });
}
export function fixStatus(data) {
  return request({
    url: "/hr/api/v1/attendPunchCalibrations",
    method: "post",
    data: data
  });
}
export function checkDay(params) {
  return request({
    url: "/hr/api/v1/attendPunchRecords/selectAttendByDay",
    method: "get",
    params: params
  });
}