import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-center"
  }, [_c("div", {
    staticClass: "br8 mr24 flex-center",
    style: {
      flex: 2.5,
      border: [-1, 1, 2, 3, 4].includes(_vm.selectTypeIndex) ? "1px solid rgba(107, 107, 242, 1)" : "1px solid transparent",
      "box-shadow": "0px 6px 16px 8px rgba(0, 0, 0, 0.03)",
      overflow: "hidden"
    }
  }, [_c("div", {
    staticClass: "pv28 ph32 flex-center pointer",
    style: {
      background: _vm.selectTypeIndex === 4 ? "rgba(0, 0, 0, 0.03)" : "#fff",
      width: 182,
      "flex-shrink": 0
    },
    on: {
      click: function click($event) {
        return _vm.setType(4);
      }
    }
  }, [_vm._m(0), _c("div", [_c("div", {
    staticClass: "flex-center"
  }, [_c("span", {
    staticClass: "mr4",
    staticStyle: {
      color: "rgba(118, 120, 133, 1)"
    }
  }, [_vm._v("电子合同")]), _c("a-popover", {
    attrs: {
      content: "电子合同=待员工签署+待公司签署+签署完成+合同过期未签署",
      title: ""
    }
  }, [_c("span", {
    staticClass: "iconfont icon-wenhao",
    staticStyle: {
      color: "rgba(207, 208, 214, 1)",
      "margin-bottom": "-2px",
      "font-size": "14px"
    }
  })])], 1), _c("div", {
    staticClass: "fs18 fw500 mt4",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.summary.allContractCount || 0))])])]), _c("div", {
    staticStyle: {
      width: "1px",
      height: "48px",
      "flex-shrink": "0",
      background: "rgba(232, 232, 232, 1)"
    }
  }), _c("div", {
    staticClass: "pv28 ph32 ta-center",
    style: {
      cursor: "pointer",
      flex: 1,
      background: _vm.selectTypeIndex === 1 ? "rgba(0, 0, 0, 0.03)" : "#fff"
    },
    on: {
      click: function click($event) {
        return _vm.setType(1);
      }
    }
  }, [_c("div", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("待员工签署")]), _c("div", {
    staticClass: "fs18 fw500 mt4",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.summary.toSignedByEmpCount || 0))])]), _c("div", {
    staticStyle: {
      width: "1px",
      height: "48px",
      "flex-shrink": "0",
      background: "rgba(232, 232, 232, 1)"
    }
  }), _c("div", {
    staticClass: "pv28 ph32 ta-center",
    style: {
      cursor: "pointer",
      flex: 1,
      background: _vm.selectTypeIndex === 2 ? "rgba(0, 0, 0, 0.03)" : "#fff"
    },
    on: {
      click: function click($event) {
        return _vm.setType(2);
      }
    }
  }, [_c("div", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("待公司签署")]), _c("div", {
    staticClass: "fs18 fw500 mt4",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.summary.toSignedByComCount || 0))])]), _c("div", {
    staticStyle: {
      width: "1px",
      height: "48px",
      "flex-shrink": "0",
      background: "rgba(232, 232, 232, 1)"
    }
  }), _c("div", {
    staticClass: "pv28 ph32 ta-center",
    style: {
      cursor: "pointer",
      flex: 1,
      background: _vm.selectTypeIndex === 3 ? "rgba(0, 0, 0, 0.03)" : "#fff"
    },
    on: {
      click: function click($event) {
        return _vm.setType(3);
      }
    }
  }, [_c("div", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("签署完成")]), _c("div", {
    staticClass: "fs18 fw500 mt4",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.summary.signedCompleteCount || 0))])]), _c("div", {
    staticStyle: {
      width: "1px",
      height: "48px",
      "flex-shrink": "0",
      background: "rgba(232, 232, 232, 1)"
    }
  }), _c("div", {
    staticClass: "pv28 ph32 ta-center",
    style: {
      cursor: "pointer",
      flex: 1,
      background: _vm.selectTypeIndex === -1 ? "rgba(0, 0, 0, 0.03)" : "#fff"
    },
    on: {
      click: function click($event) {
        return _vm.setType(-1);
      }
    }
  }, [_c("div", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("合同过期未签署")]), _c("div", {
    staticClass: "fs18 fw500 mt4",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.summary.pastCount || 0))])])]), _c("div", {
    staticClass: "br8 flex-center",
    style: {
      flex: 1,
      border: [5, 6].includes(_vm.selectTypeIndex) ? "1px solid rgba(107, 107, 242, 1)" : "1px solid transparent",
      "box-shadow": "0px 6px 16px 8px rgba(0, 0, 0, 0.03)",
      overflow: "hidden"
    }
  }, [_c("div", {
    staticClass: "pv28 ph32 ta-center",
    style: {
      cursor: "pointer",
      flex: 1,
      background: _vm.selectTypeIndex === 5 ? "rgba(0, 0, 0, 0.03)" : "#fff"
    },
    on: {
      click: function click($event) {
        return _vm.setType(5);
      }
    }
  }, [_c("div", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)",
      position: "relative"
    }
  }, [_vm._v(" 即将到期 "), _c("a-popover", {
    attrs: {
      content: "距离合同结束时间0-90天的合同",
      title: ""
    }
  }, [_c("div", {
    staticClass: "iconfont icon-wenhao",
    staticStyle: {
      color: "rgba(207, 208, 214, 1)",
      "font-size": "14px",
      position: "absolute",
      left: "50%",
      top: "1px",
      "margin-left": "32px"
    }
  })])], 1), _c("div", {
    staticClass: "fs18 fw500 mt4",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.summary.immediatelyMaturityCount || 0))])]), _c("div", {
    staticStyle: {
      width: "1px",
      height: "48px",
      "flex-shrink": "0",
      background: "rgba(232, 232, 232, 1)"
    }
  }), _c("div", {
    staticClass: "pv28 ph32 ta-center",
    style: {
      cursor: "pointer",
      flex: 1,
      background: _vm.selectTypeIndex === 6 ? "rgba(0, 0, 0, 0.03)" : "#fff"
    },
    on: {
      click: function click($event) {
        return _vm.setType(6);
      }
    }
  }, [_c("div", {
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("已到期")]), _c("div", {
    staticClass: "fs18 fw500 mt4",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)"
    }
  }, [_vm._v(_vm._s(_vm.summary.alreadyMaturityCount || 0))])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "br8 bgc-themeA10 mr12 flex-center-between",
    staticStyle: {
      width: "32px",
      height: "32px"
    }
  }, [_c("span", {
    staticClass: "iconfont icon-xingzhuangjiehe tc-theme fs14"
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };