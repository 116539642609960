import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.push.js";
var initNeed = {
  daily: moment().format("YYYY-MM-DD"),
  reason: "",
  periodList: [{
    startPeriod: "09:00",
    endPeriod: "18:00",
    type: true,
    sort: 0,
    hasRestPeriod: false
  }],
  restPeriod: {
    startPeriod: "12:00",
    endPeriod: "13:00",
    type: false,
    sort: 0
  }
};
import moment from "moment";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    needPunchDate: {
      type: Object,
      default: initNeed
    },
    handleNeedList: {
      type: Function,
      default: function _default() {}
    },
    editDateIndex: {
      type: Number,
      default: -1
    },
    handleDateShow: {
      type: Function,
      default: function _default() {}
    }
  },
  methods: {
    /* 上班时间 */onWorkChange: function onWorkChange(time, timeString, i) {
      if (timeString === "") return;
      if (timeString) this.needPunchDate.periodList[i].startPeriod = timeString;
    },
    /* 下班时间 */offWorkChange: function offWorkChange(time, timeString, i) {
      if (timeString === "") return;
      if (timeString) this.needPunchDate.periodList[i].endPeriod = timeString;
    },
    handleData: function handleData() {
      this.$emit("handleNeedList", this.needPunchDate, this.editDateIndex);
      this.$emit("handleDateShow", false);
    },
    /* 日期 */dateChange: function dateChange(item, string) {
      if (string === "") return;
      this.needPunchDate.daily = string;
    },
    onRestChange: function onRestChange(time, timeString) {
      if (timeString === "") return;
      this.needPunchDate.restPeriod.startPeriod = timeString;
    },
    offRestChange: function offRestChange(time, timeString) {
      if (timeString === "") return;
      this.needPunchDate.restPeriod.endPeriod = timeString;
    },
    add: function add() {
      this.needPunchDate.periodList.push({
        startPeriod: "09:00",
        endPeriod: "18:00",
        type: true,
        sort: 0,
        hasRestPeriod: false
      });
    },
    del: function del() {
      this.needPunchDate.periodList.pop();
    }
  }
};