import Cookies from "js-cookie";
import { unauditedCountReq } from "@/views/trialList/api";
var state = {
  sidebar: {
    opened: Cookies.get("sidebarStatus") ? !!+Cookies.get("sidebarStatus") : true,
    withoutAnimation: false
  },
  device: "desktop",
  trialCount: 0,
  mainWidth: 0,
  mainHeight: 0
};
var mutations = {
  updateAppMain: function updateAppMain(state, _ref) {
    var width = _ref.width,
      height = _ref.height;
    state.mainWidth = width;
    state.mainHeight = height;
  },
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set("sidebarStatus", 1);
    } else {
      Cookies.set("sidebarStatus", 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    Cookies.set("sidebarStatus", 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  updateMainHeight: function updateMainHeight(state) {},
  updateTrialCount: function updateTrialCount(state, payload) {
    state.trialCount = payload;
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref2) {
    var commit = _ref2.commit;
    commit("TOGGLE_SIDEBAR");
  },
  closeSideBar: function closeSideBar(_ref3, _ref4) {
    var commit = _ref3.commit;
    var withoutAnimation = _ref4.withoutAnimation;
    commit("CLOSE_SIDEBAR", withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref5, device) {
    var commit = _ref5.commit;
    commit("TOGGLE_DEVICE", device);
  },
  loadTrialCount: function loadTrialCount(_ref6) {
    var commit = _ref6.commit;
    unauditedCountReq().then(function (res) {
      commit("updateTrialCount", res.data);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};