import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("fTitle", {
    attrs: {
      tit: "赏金规则"
    }
  }), _c("a-form", {
    attrs: {
      "label-col": {
        span: 2
      },
      "wrapper-col": {
        span: 10
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "发放方式"
    }
  }, [_c("a-select", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.empWay,
      callback: function callback($$v) {
        _vm.empWay = $$v;
      },
      expression: "empWay"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 一次性发放 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 阶段性发放 ")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "赏金金额"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.empMoneyReward,
      callback: function callback($$v) {
        _vm.empMoneyReward = $$v;
      },
      expression: "empMoneyReward"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "薪资范围"
    }
  }, _vm._l(_vm.empRule, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("a-input", {
      staticStyle: {
        width: "144px"
      },
      attrs: {
        disabled: ""
      },
      model: {
        value: item.workingTime,
        callback: function callback($$v) {
          _vm.$set(item, "workingTime", $$v);
        },
        expression: "item.workingTime"
      }
    }), _c("span", {
      staticClass: "ant-form-text"
    }), _c("a-select", {
      staticStyle: {
        width: "144px"
      },
      attrs: {
        disabled: ""
      },
      model: {
        value: item.timeType,
        callback: function callback($$v) {
          _vm.$set(item, "timeType", $$v);
        },
        expression: "item.timeType"
      }
    }, [_c("a-select-option", {
      attrs: {
        value: "1"
      }
    }, [_vm._v(" 天 ")]), _c("a-select-option", {
      attrs: {
        value: "2"
      }
    }, [_vm._v(" 月 ")])], 1), _c("span", {
      staticClass: "ant-form-text"
    }, [_vm._v(" 发放 ")]), _c("a-input", {
      staticStyle: {
        width: "228px"
      },
      attrs: {
        disabled: ""
      },
      model: {
        value: item.grantCount,
        callback: function callback($$v) {
          _vm.$set(item, "grantCount", $$v);
        },
        expression: "item.grantCount"
      }
    }, [_c("a-select", {
      attrs: {
        slot: "addonAfter",
        disabled: ""
      },
      slot: "addonAfter",
      model: {
        value: item.grantType,
        callback: function callback($$v) {
          _vm.$set(item, "grantType", $$v);
        },
        expression: "item.grantType"
      }
    }, [_c("a-select-option", {
      attrs: {
        value: "1"
      }
    }, [_vm._v(" %   ")]), _c("a-select-option", {
      attrs: {
        value: "2"
      }
    }, [_vm._v(" 元   ")])], 1)], 1)], 1);
  }), 0)], 1), _c("fTitle", {
    attrs: {
      tit: "内推范围"
    }
  }), _c("div", {
    staticClass: "qy pl42"
  }, [_c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: true
    }
  }, [_vm._v(" 选择企业 ")]), _vm.empCompanyIds.length > 0 ? _c("div", {
    staticClass: "yxz"
  }, [_vm._v(" 已选择 "), _vm._l(_vm.empCompanyIds, function (item, index) {
    return _c("span", {
      key: index
    }, [index < 2 ? _c("span", [index == 1 ? _c("span", [_vm._v(" 、")]) : _vm._e(), _vm._v(" " + _vm._s(item.name) + " ")]) : _vm._e()]);
  }), _vm.empCompanyIds.length > 2 ? _c("span", [_vm._v(" 等 "), _c("span", {
    staticClass: "icon-color"
  }, [_vm._v(_vm._s(_vm.empCompanyIds.length))]), _vm._v(" 家 ")]) : _vm._e()], 2) : _c("span", [_vm._v("当前未选择")])], 1), _c("fTitle", {
    attrs: {
      tit: "内推人详情"
    }
  }), _c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      "row-selection": _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      } : null
    },
    on: {
      change: _vm.onTableChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "empName" ? _c("span", {
            key: index
          }, [_c("img", {
            staticClass: "avatar",
            attrs: {
              src: record.avatar
            }
          }), _vm._v(" " + _vm._s(text) + " ")]) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };