import "core-js/modules/es.object.values.js";
export var columns = [{
  title: "政策级别",
  dataIndex: "policyLevel",
  width: 100,
  scopedSlots: {
    customRender: "policyLevel"
  }
}, {
  title: "政策标题",
  dataIndex: "title",
  width: 200,
  ellipsis: true,
  scopedSlots: {
    customRender: "title_slot"
  }
}, {
  title: "所属用工单位",
  dataIndex: "companyTypeList",
  width: 200,
  ellipsis: true,
  scopedSlots: {
    customRender: "companyTypeList"
  }
}, {
  title: "政策附件",
  dataIndex: "annex",
  width: 200,
  ellipsis: true,
  scopedSlots: {
    customRender: "annex"
  }
}, {
  title: "创建时间",
  dataIndex: "createTime",
  width: 120,
  scopedSlots: {
    customRender: "createTime"
  }
}, {
  title: "操作",
  dataIndex: "action",
  width: 160,
  scopedSlots: {
    customRender: "action"
  }
}];
export var levelOptionsDict = {
  1: {
    label: "国家级",
    value: "1"
  },
  2: {
    label: "省级",
    value: "2"
  },
  3: {
    label: "市级",
    value: "3"
  },
  4: {
    label: "区级",
    value: "4"
  }
};
export var levelOptions = Object.values(levelOptionsDict);