import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "notice-editor-wrapper"
  }, [_c("div", {
    staticClass: "ql-editor-content"
  }, [_c("div", {
    staticClass: "preview-new-box"
  }, [_c("div", {
    staticClass: "notice-content"
  }, [_c("div", {
    staticClass: "notice-company-title"
  }, [_vm._v(_vm._s(_vm.noticeTitle))]), _c("div", {
    staticClass: "notice-company-date"
  }, [_vm._v("发布时间：" + _vm._s(_vm.noticeTime))]), _c("div", {
    staticClass: "notice-company-content",
    domProps: {
      innerHTML: _vm._s(_vm.noticeContentHasStyle)
    }
  }), _c("div", {
    staticClass: "mt24"
  }, [_vm._v(" 附件： "), _vm._l(_vm.noticeFileList, function (item, index) {
    return _c("div", {
      key: index
    }, [_vm._v(_vm._s(item.name) + "(点击下载)")]);
  })], 2)])])]), _c("div", {
    staticClass: "btnline"
  }, [_c("a-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.backStep
    }
  }, [_vm._v("上一步")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.NextStep
    }
  }, [_vm._v("确认发布")])], 1), _c("a-modal", {
    staticClass: "publish-wrap",
    attrs: {
      title: "发布公告",
      visible: _vm.publishVisible,
      width: "468px"
    },
    on: {
      ok: _vm.confirmPublish,
      cancel: function cancel($event) {
        _vm.publishVisible = false;
      }
    }
  }, [_c("p", {
    staticClass: "publish-title"
  }, [_vm._v("确定发布此新闻吗？")])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };