import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white p16"
  }, [_vm.groupId ? _c("div", {
    staticClass: "flex-center bgc-white mb16"
  }, [_c("a-popover", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      trigger: "click",
      placement: "bottom"
    },
    model: {
      value: _vm.groupSelectVisible,
      callback: function callback($$v) {
        _vm.groupSelectVisible = $$v;
      },
      expression: "groupSelectVisible"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-gongsi-2-line2x fs16 mr8",
    staticStyle: {
      color: "#55565d"
    }
  }), _vm._v(" " + _vm._s(_vm.groupName) + " "), _c("i", {
    staticClass: "iconfont icon-swap2x fs16 ml8",
    staticStyle: {
      color: "var(--primary)"
    }
  }), _c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("a-tree", {
    attrs: {
      treeData: _vm.groupLst,
      selectedKeys: [_vm.groupId]
    },
    on: {
      select: _vm.onGroupChange
    }
  })], 1)]), _c("a-range-picker", {
    staticClass: "ml16",
    staticStyle: {
      width: "240px"
    },
    attrs: {
      value: _vm.dateRange,
      placeholder: ["开始月份", "结束月份"],
      mode: ["month", "month"],
      format: "YYYY-MM"
    },
    on: {
      panelChange: _vm.onPanelChange
    }
  })], 1) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.countLstByGroupLoaded,
      expression: "countLstByGroupLoaded"
    }],
    staticClass: "flex-center"
  }, [_c("div", {
    staticClass: "pv12 ph24 br4 mr16",
    staticStyle: {
      background: "rgba(190, 190, 190, 0.1)",
      "flex-shrink": "0"
    }
  }, [_c("div", [_vm._v("销售提成统计")]), _c("div", {
    staticClass: "fs16 fw500 ta-center mt4"
  }, [_vm._v(_vm._s(_vm.count))])]), _c("div", {
    staticClass: "flex-center pv12 ph24 br4",
    staticStyle: {
      flex: "1",
      "overflow-x": "auto",
      background: "rgba(190, 190, 190, 0.1)"
    }
  }, [_vm.countLstByGroup.length > 0 ? _vm._l(_vm.countLstByGroup, function (pane) {
    return _c("div", {
      key: pane.key,
      staticClass: "ta-center ph12",
      staticStyle: {
        height: "49px",
        width: "160px"
      }
    }, [_c("a-tooltip", {
      attrs: {
        title: pane.key
      }
    }, [_c("div", {
      staticClass: "ellipsis"
    }, [_vm._v(_vm._s(pane.key))])]), _c("div", {
      staticClass: "fs16 fw500 mt4"
    }, [_vm._v(_vm._s(pane.value))])], 1);
  }) : _vm._e()], 2)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.countLstByGroup.length > 0,
      expression: "countLstByGroup.length > 0"
    }],
    staticClass: "p16"
  }, [_vm._m(0), _c("div", {
    staticStyle: {
      width: "100%",
      height: "140px"
    },
    attrs: {
      id: "group_chart"
    }
  })]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.countLstByMonth.length > 0,
      expression: "countLstByMonth.length > 0"
    }],
    staticClass: "p16"
  }, [_vm._m(1), _c("div", {
    staticStyle: {
      width: "100%",
      height: "140px"
    },
    attrs: {
      id: "month_chart"
    }
  })]), _c("div", {
    staticClass: "mt24"
  }, [_c("a-space", [_c("a-input-search", {
    staticStyle: {
      width: "260px"
    },
    attrs: {
      placeholder: "搜索销售名称/联系电话/身份证号"
    },
    on: {
      change: _vm.loadList
    },
    model: {
      value: _vm.saleNameOrPhoneOrIdCard,
      callback: function callback($$v) {
        _vm.saleNameOrPhoneOrIdCard = $$v;
      },
      expression: "saleNameOrPhoneOrIdCard"
    }
  }), _c("a-cascader", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      options: _vm.common.areaList,
      changeOnSelect: "",
      "show-search": {
        filter: _vm.filter
      },
      placeholder: "所在地区",
      allowClear: ""
    },
    on: {
      change: _vm.loadList
    },
    model: {
      value: _vm.region,
      callback: function callback($$v) {
        _vm.region = $$v;
      },
      expression: "region"
    }
  })], 1)], 1), _c("a-table", {
    staticClass: "mt24",
    attrs: {
      dataSource: _vm.recordLst,
      columns: _vm.tongjiColumns,
      pagination: _vm.pagination,
      rowKey: function rowKey(record, idx) {
        return "".concat(record.salePhone, "_").concat(idx);
      }
    },
    on: {
      change: _vm.onTableChange
    },
    scopedSlots: _vm._u([{
      key: "uc",
      fn: function fn(text, record) {
        return [_c("div", {
          staticClass: "ellipsis pointer",
          staticStyle: {
            width: "200px"
          },
          on: {
            click: function click($event) {
              return _vm.$refs.uc.onItemClick(record.saleUserId);
            }
          }
        }, [_c("a", [_vm._v(_vm._s(text))])])];
      }
    }])
  }), _c("UserCompany", {
    ref: "uc"
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-center-between"
  }, [_c("div", [_c("div", {
    staticClass: "flex-center title"
  }, [_vm._v("销售分组提成")]), _c("div", {
    staticClass: "fs12 pl10 mt4"
  }, [_vm._v("单位：元")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-center-between"
  }, [_c("div", [_c("div", {
    staticClass: "flex-center title"
  }, [_vm._v("销售提成趋势")]), _c("div", {
    staticClass: "fs12 pl10 mt4"
  }, [_vm._v("单位：元")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };