import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "node"
  }, [_vm.process.nodeType !== "block" ? _c("div", {
    staticClass: "node-wrap"
  }, [_c("div", {
    staticClass: "node-wrap-box",
    class: _vm.process.nodeType != "start" ? "hasNext" : ""
  }, [_c("div", [_c("div", {
    staticClass: "title",
    style: _vm.process.nodeType == "start" ? "background:rgb(87, 106, 149)" : _vm.process.nodeType == "approver" ? "background:rgb(255, 148, 62)" : "background:rgb(50, 150, 250)"
  }, [_vm.process.nodeType === "start" ? _c("span", {
    staticClass: "title-span",
    on: {
      click: _vm.editortitle
    }
  }, [_vm._v("发起人")]) : _vm._e(), !_vm.isInput && _vm.process.nodeType !== "start" ? _c("span", {
    staticClass: "title-span",
    on: {
      click: _vm.editortitle
    }
  }, [_vm._v(_vm._s(_vm.process.name))]) : _vm._e(), _vm.isInput && _vm.process.nodeType !== "start" ? _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.process.name,
      expression: "process.name"
    }, {
      name: "focus",
      rawName: "v-focus"
    }],
    staticClass: "title-input",
    attrs: {
      type: "text"
    },
    domProps: {
      value: _vm.process.name
    },
    on: {
      blur: function blur($event) {
        $event.stopPropagation();
        return _vm.changeName.apply(null, arguments);
      },
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.process, "name", $event.target.value);
      }
    }
  }) : _vm._e(), _vm.process.nodeType !== "start" ? _c("a-icon", {
    staticClass: "anticon",
    attrs: {
      type: "close"
    },
    on: {
      click: function click($event) {
        return _vm.delNode();
      }
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "content",
    on: {
      click: function click($event) {
        return _vm.openConfigDrawer(_vm.process);
      }
    }
  }, [_vm.process.nodeType == "start" ? _c("div", {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.process.props ? _vm.process.props : "起始节点") + " ")]) : _vm._e(), _vm.process.nodeType == "approver" ? _c("div", {
    staticClass: "text"
  }, [_c("span", {
    staticClass: "placeholder"
  }, [_vm._v(" " + _vm._s(_vm.process.props.name ? _vm.process.props.name : "审批人") + " ")])]) : _vm._e(), _vm.process.nodeType == "notifier" ? _c("div", {
    staticClass: "text"
  }, [_c("span", {
    staticClass: "placeholder"
  }, [_vm._v(" " + _vm._s(_vm.process.props.name ? _vm.process.props.name : "抄送人") + " ")])]) : _vm._e(), _c("a-icon", {
    staticClass: "arrow",
    attrs: {
      type: "right"
    }
  })], 1)])]), _c("NodeAdd", {
    attrs: {
      "child-node": _vm.process.childNode,
      "node-id": _vm.nodeId
    },
    on: {
      "update:childNode": function updateChildNode($event) {
        return _vm.$set(_vm.process, "childNode", $event);
      },
      "update:child-node": function updateChildNode($event) {
        return _vm.$set(_vm.process, "childNode", $event);
      },
      change: _vm.addNodeId
    }
  })], 1) : _vm._e(), _vm.process.nodeType == "block" && _vm.process.conditionNodes.length > 1 ? _c("div", {
    staticClass: "branch-wrap"
  }, [_c("div", {
    staticClass: "branch-box-wrap"
  }, [_c("div", {
    staticClass: "branch-box"
  }, [_c("a-button", {
    staticClass: "add-branch",
    attrs: {
      size: "small",
      shape: "round"
    },
    on: {
      click: _vm.addTerm
    }
  }, [_vm._v("添加条件")]), _vm._l(_vm.process.conditionNodes, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "col-box"
    }, [_c("div", {
      staticClass: "condition-node"
    }, [_c("div", {
      staticClass: "condition-node-box"
    }, [_c("div", {
      staticClass: "auto-judge"
    }, [_c("a-icon", {
      staticClass: "editor-close",
      attrs: {
        type: "close"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.delTerm(index);
        }
      }
    }), _c("div", {
      staticClass: "title-wrapper"
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.name,
        expression: "item.name"
      }],
      staticClass: "editor-input",
      attrs: {
        type: "text"
      },
      domProps: {
        value: item.name
      },
      on: {
        input: function input($event) {
          if ($event.target.composing) return;
          _vm.$set(item, "name", $event.target.value);
        }
      }
    }), _c("span", {
      staticClass: "priority-title"
    }, [_vm._v("优先级" + _vm._s(index + 1))])]), _c("div", {
      staticClass: "content",
      on: {
        click: function click($event) {
          return _vm.openConfigDrawer(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.operationName) + " ")])], 1), _c("NodeAdd", {
      attrs: {
        "child-node": item.childNode,
        "node-id": _vm.nodeId
      },
      on: {
        "update:childNode": function updateChildNode($event) {
          return _vm.$set(item, "childNode", $event);
        },
        "update:child-node": function updateChildNode($event) {
          return _vm.$set(item, "childNode", $event);
        },
        change: _vm.addNodeId
      }
    })], 1)]), item.childNode && !item.isLastNode ? _c("NodeWrapper", {
      attrs: {
        process: item.childNode,
        formlist: _vm.formlist,
        "node-id": _vm.nodeId
      },
      on: {
        "update:process": function updateProcess($event) {
          return _vm.$set(item, "childNode", $event);
        }
      }
    }) : _vm._e(), index == 0 ? _c("div", {
      staticClass: "top-left-cover-line"
    }) : _vm._e(), index == 0 ? _c("div", {
      staticClass: "bottom-left-cover-line"
    }) : _vm._e(), index == _vm.process.conditionNodes.length - 1 ? _c("div", {
      staticClass: "top-right-cover-line"
    }) : _vm._e(), index == _vm.process.conditionNodes.length - 1 ? _c("div", {
      staticClass: "bottom-right-cover-line"
    }) : _vm._e()], 1);
  })], 2), _c("NodeAdd", {
    attrs: {
      "child-node": _vm.process.childNode,
      "node-id": _vm.nodeId
    },
    on: {
      "update:childNode": function updateChildNode($event) {
        return _vm.$set(_vm.process, "childNode", $event);
      },
      "update:child-node": function updateChildNode($event) {
        return _vm.$set(_vm.process, "childNode", $event);
      },
      change: _vm.addNodeId
    }
  })], 1)]) : _vm._e(), _c("StartDrawer", {
    attrs: {
      show: _vm.showStart,
      "start-options": _vm.startOptions
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.showStart = $event;
      },
      change: _vm.onValueChange
    }
  }), _c("ApprovalDrawer", {
    attrs: {
      show: _vm.showAppro,
      "appro-options": _vm.approOptions
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.showAppro = $event;
      },
      change: _vm.onValueChange
    }
  }), _c("NotifierDrawer", {
    attrs: {
      show: _vm.showNotif,
      "notif-options": _vm.notifOptions
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.showNotif = $event;
      },
      change: _vm.onValueChange
    }
  }), _c("BlockDrawer", {
    attrs: {
      process: _vm.process,
      formlist: _vm.formlist,
      show: _vm.showBlock,
      "block-options": _vm.blockOptions
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.showBlock = $event;
      },
      change: _vm.onValueChange
    },
    model: {
      value: _vm.process,
      callback: function callback($$v) {
        _vm.process = $$v;
      },
      expression: "process"
    }
  }), _vm.process.childNode ? _c("NodeWrapper", {
    attrs: {
      process: _vm.process.childNode,
      formlist: _vm.formlist,
      "node-id": _vm.nodeId
    },
    on: {
      "update:process": function updateProcess($event) {
        return _vm.$set(_vm.process, "childNode", $event);
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };