import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.keys.js";
import inTemplateForm from "./inTemplateForm";
import Draggable from "vuedraggable";
import { loadAllFieldsReq, customTemplateFieldslistAll as _customTemplateFieldslistAll, customTemplatesAdd, customTemplatesEdit, listDefaultField as _listDefaultField } from "./api";
import { basicsPages } from "./dynamic";
import { mapState } from "vuex";
import _ from "lodash";
export default {
  components: {
    Draggable: Draggable,
    inTemplateForm: inTemplateForm
  },
  data: function data() {
    return {
      term: null,
      tempItem: null,
      newAllFields: [],
      newAllFieldKeys: [],
      oneLogin: true,
      customStyle: "border: 0; background: #fff",
      spinning: false,
      templateList: [],
      basicData: {},
      basicFormJson: {
        editable: true
      },
      form: this.$form.createForm(this, {
        name: "horizontal_login"
      })
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    companyId: function companyId() {
      return this.hr.companyId;
    },
    moduleCode: function moduleCode() {
      return this.$route.query.moduleCode;
    },
    status: function status() {
      return this.$route.query.status;
    },
    type: function type() {
      return this.$route.query.type;
    },
    name: function name() {
      return this.$route.query.name;
    },
    openTab: function openTab() {
      return this.$route.query.openTab;
    },
    statusSHow: function statusSHow() {
      return this.$route.query.statusSHow;
    },
    showingList: function showingList() {
      if (this.newAllFields && this.newAllFields.length > 0 && this.templateList && this.templateList.length > 0) {
        var showNames = this.templateList.map(function (item) {
          return item.fieldName;
        });
        var res = [];
        this.newAllFields.forEach(function (mod) {
          var fields = [];
          mod.fieldList.forEach(function (field) {
            fields.push(_objectSpread(_objectSpread({}, field), {}, {
              isEdit: showNames.includes(field.fieldName) ? 0 : 1,
              disabled: showNames.includes(field.fieldName)
            }));
          });
          mod.additionalFieldList.forEach(function (field) {
            fields.push(_objectSpread(_objectSpread({}, field), {}, {
              isEdit: showNames.includes(field.fieldName) ? 0 : 1,
              disabled: showNames.includes(field.fieldName)
            }));
          });
          res.push(_objectSpread(_objectSpread({}, mod), {}, {
            fields: fields
          }));
        });
        return res;
      }
      return [];
    }
  }),
  created: function created() {
    this.listDefaultField();
    this.loadAllFields();
  },
  methods: {
    onSearch: _.debounce(function (value) {
      this.loadAllFields(value);
    }, 1000),
    loadAllFields: function loadAllFields(term) {
      var _this = this;
      loadAllFieldsReq(this.type === "2" ? "site" : this.companyId, this.moduleCode, term).then(function (res) {
        _this.newAllFields = res.data;
        _this.newAllFieldKeys = term ? res.data.map(function (item) {
          return item.moduleName;
        }) : [];
      }).catch(function (err) {
        console.log(err);
      });
    },
    onDragEnd: function onDragEnd(newIndex, oldIndex) {
      var temp = _toConsumableArray(this.templateList);
      var tempItem = _objectSpread({}, temp[oldIndex]);
      temp.splice(oldIndex, 1);
      temp.splice(newIndex, 0, tempItem);
      this.templateList = temp;
      this.getListCompanyModuleCodes(this.templateList);
    },
    onDragAdd: function onDragAdd(newIndex) {
      var temp = _toConsumableArray(this.templateList);
      temp.splice(newIndex, 0, this.tempItem);
      this.templateList = temp;
      this.getListCompanyModuleCodes(this.templateList);
    },
    scrollToTop: function scrollToTop() {
      document.querySelector("#target").scrollIntoView();
    },
    // 删除和必填 操作
    changItemVal: function changItemVal(item, test) {
      if (test === "del") {
        this.templateList = this.templateList.filter(function (key) {
          return ![item].includes(key.fieldName);
        });
        this.getListCompanyModuleCodes(this.templateList);
      } else {
        this.templateList = this.templateList.map(function (key) {
          if (key.fieldName === item) {
            key.isNecessary = !key.isNecessary;
          }
          return key;
        });
        this.getListCompanyModuleCodes(this.templateList);
      }
    },
    // 添加模板字段
    addFieldNameMove: function addFieldNameMove(item) {
      this.tempItem = _objectSpread(_objectSpread({}, item), {}, {
        isEdit: 1,
        isOpen: true
      });
      return item.fieldName;
    },
    addFieldName: function addFieldName(e) {
      var data = this.templateList.filter(function (key) {
        return key.fieldName === e.fieldName && key.isOpen;
      });
      if (data.length === 0) {
        this.templateList = [].concat(_toConsumableArray(this.templateList), [_objectSpread(_objectSpread({}, e), {}, {
          isEdit: 1,
          isOpen: true
        })]);
        this.getListCompanyModuleCodes(this.templateList);
      }
    },
    // 编辑
    customTemplateFieldslistAll: function customTemplateFieldslistAll() {
      var _this2 = this;
      _customTemplateFieldslistAll({
        companyId: this.$route.query.statusSHow === "3" ? this.$store.state.hr.companyId : "site",
        templateId: this.$route.query.id
      }).then(function (res) {
        var fl_add = res.data.additionalFieldList.filter(function (z) {
          return z.isEdit === 1;
        });
        var fl_sys = res.data.systemFieldList.filter(function (z) {
          return z.isOpen === true;
        });
        var fl = [].concat(_toConsumableArray(fl_sys), _toConsumableArray(fl_add)).sort(function (a, b) {
          return a.sort - b.sort;
        });
        _this2.templateList = fl.map(function (z) {
          return _objectSpread(_objectSpread({}, z), {}, {
            isNecessaryShow: z.isEdit === 0
          });
        });
        _this2.getListCompanyModuleCodes(_this2.templateList);
      }).catch(function (err) {
        console.log(err);
      });
    },
    listDefaultField: function listDefaultField() {
      var _this3 = this;
      _listDefaultField({
        companyId: this.$route.query.statusSHow === "3" ? this.$store.state.hr.companyId : "site",
        moduleCode: this.moduleCode
      }).then(function (res) {
        var data = _toConsumableArray(res.data.systemFieldList);
        if (_this3.status === "add" && _this3.oneLogin) {
          _this3.templateList = data.filter(function (z) {
            return z.isRequired;
          }).map(function (z) {
            z.isNecessaryShow = z.isNecessary;
            return z;
          });
          _this3.getListCompanyModuleCodes(_this3.templateList);
          _this3.oneLogin = false;
        }
        if (_this3.status === "edit" && _this3.oneLogin) {
          _this3.customTemplateFieldslistAll();
          _this3.oneLogin = false;
        } else {
          _this3.getListCompanyModuleCodes(_this3.templateList);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    dynamicChange: function dynamicChange(value, sortArray) {
      var _this4 = this;
      this.templateList = sortArray.map(function (i) {
        var a = _this4.templateList.find(function (z) {
          return z.fieldName === i;
        });
        return a;
      });
      // type: 类型；1：系统 2：租户 3：用工单位
      if (this.type === "2") {
        this.templateList.map(function (i) {
          i.templateId = null;
          i.companyId = null;
          return i;
        });
      } else {
        this.templateList.map(function (i) {
          i.templateId = null;
          i.companyId = _this4.$store.state.hr.companyId;
          return i;
        });
      }
      var fieldAddDTOList = this.status === "add" ? this.templateList.map(function (z, index) {
        z.isDefault = 0;
        z.id = null;
        z.isOpen = true;
        z.companyId = _this4.$route.query.statusSHow === "3" ? _this4.$store.state.hr.companyId : "site";
        z.sort = index + 1;
        return z;
      }) : this.templateList.map(function (z, index) {
        z.sort = index + 1;
        z.isOpen = true;
        z.companyId = _this4.$route.query.statusSHow === "3" ? _this4.$store.state.hr.companyId : "site";
        return z;
      });
      var data = {
        templateId: this.status === "add" ? null : this.$route.query.id,
        name: value.name,
        customFieldAddDTOList: fieldAddDTOList.filter(function (z) {
          return z.fieldCode;
        }),
        additionalFieldsAddDTOList: fieldAddDTOList.filter(function (z) {
          return !z.fieldCode;
        }),
        companyId: this.$route.query.statusSHow === "3" ? this.$store.state.hr.companyId : "site",
        isIssue: 0,
        moduleName: this.moduleCode === "onJob" ? "入职" : this.moduleCode === "waitJob" ? "待入职" : "预登记",
        moduleCode: this.moduleCode,
        type: this.type
      };
      this.spinning = true;
      if (this.status === "add") {
        customTemplatesAdd(data).then(function (res) {
          _this4.$message.success(res.msg);
          _this4.spinning = false;
          _this4.goback();
        }).catch(function (err) {
          _this4.spinning = false;
          console.log(err);
        });
      } else {
        data.id = this.$route.query.id;
        data.type = this.$route.query.statusSHow;
        data.companyId = this.$route.query.statusSHow === "3" ? this.$store.state.hr.companyId : "site";
        data.isDefault = JSON.parse(this.$route.query.isDefault);
        customTemplatesEdit(data).then(function (res) {
          _this4.$message.success(res.msg);
          _this4.spinning = false;
          _this4.goback();
        }).catch(function (err) {
          _this4.spinning = false;
          console.log(err);
        });
      }
    },
    goback: function goback() {
      if (this.statusSHow === "3") {
        this.$router.go(-1);
      } else {
        this.$router.push({
          path: "/contractAdmin/ruzhi",
          query: {
            openTab: this.openTab
          }
        });
      }
    },
    // 获取展示数据
    getListCompanyModuleCodes: function getListCompanyModuleCodes(element) {
      var _basicsPages = basicsPages("basic", _toConsumableArray(element && element.length > 0 ? element : [])),
        fields = _basicsPages.fields,
        fieldKeys = _basicsPages.fieldKeys;
      this["basicFormJson"] = {
        editable: false,
        fields: fields,
        sortedKeys: fieldKeys
      };
    },
    mapShow: function mapShow(e) {
      var _this5 = this;
      var arr = e.map(function (z) {
        var name = Object.keys(_this5.basicData).find(function (y) {
          return y === z.fieldName;
        });
        if (name) {
          z.isEdit = 0;
        }
        return z;
      });
      return arr;
    },
    search: function search() {
      this.form.validateFields(function (err, values) {
        if (!err) {
          console.log(values);
        }
      });
    }
  }
};