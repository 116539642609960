import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { mapState, mapGetters } from "vuex";
import { getToken } from "xqjr-module-auth/utils";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(["permissions_menu"])), mapState(["auth"])), {}, {
    permissionStringList: function permissionStringList() {
      return this.auth.permissions && this.auth.permissions.permissionStringList ? this.auth.permissions.permissionStringList : [];
    },
    menuUrls: function menuUrls() {
      if (this.permissions_menu && this.permissions_menu.length > 0) {
        return this.permissions_menu.map(function (item) {
          return item.url;
        });
      }
      return [];
    },
    token: function token() {
      return getToken();
    }
  }),
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onBanner1: function onBanner1() {},
    onBanner2: function onBanner2() {},
    onBanner3: function onBanner3() {},
    onShangbao: function onShangbao() {
      if (this.token) {
        if (this.$hasPermissionFirst("insuranceAddService")) {
          sessionStorage.setItem("shangbaoPageState", "customer");
          this.$router.push({
            path: "/shangyebaoxian"
          });
        } else {
          this.$message.info("没有权限访问该功能...");
        }
      } else {
        this.$router.push({
          path: "/shangyebaoxian"
        });
      }
    },
    onXinzidianfu: function onXinzidianfu() {
      this.$message.info("功能正在开发中...");
    },
    onGongyinglianjinrong: function onGongyinglianjinrong() {
      this.$message.info("功能正在开发中...");
    },
    onFulicaigou: function onFulicaigou() {
      this.$message.info("功能正在开发中...");
    },
    onZhaopin: function onZhaopin() {
      if (this.token) {
        if (this.$hasPermission("recruitment:roverview:query")) {
          this.$router.push({
            path: "/recruitment/overview"
          });
        } else if (this.$hasPermission("recruitment:management:query")) {
          this.$router.push({
            path: "/recruitment/management"
          });
        } else if (this.$hasPermission("recruitment:people:query")) {
          this.$router.push({
            path: "/recruitment/people"
          });
        } else if (this.$hasPermission("recruitment:economic:query")) {
          this.$router.push({
            path: "/recruitment/economic"
          });
        } else if (this.$hasPermission("recruitment:rpush:query")) {
          this.$router.push({
            path: "/recruitment/push"
          });
        } else if (this.$hasPermission("recruitment:laborUnit:query")) {
          this.$router.push({
            path: "/recruitment/laborUnit"
          });
        } else if (this.$hasPermission("recruitment:talent:query")) {
          this.$router.push({
            path: "/recruitment/talent"
          });
        } else if (this.$hasPermission("recruitment:zhinengtuijian:query")) {
          this.$router.push({
            path: "/recruitment/talentZhineng"
          });
        } else {
          this.$message.info("没有权限访问该功能...");
        }
      } else {
        this.$router.push({
          path: "/recruitment/overview"
        });
      }
    },
    onDianzihetong: function onDianzihetong() {
      if (this.token) {
        if (this.menuUrls.includes("/contract/elecontract")) {
          this.$router.push({
            path: "/contract/elecontract"
          });
        } else if (this.menuUrls.includes("/contract/type")) {
          this.$router.push({
            path: "/contract/type"
          });
        } else if (this.menuUrls.includes("/contract/stamp")) {
          this.$router.push({
            path: "/contract/stamp"
          });
        } else {
          this.$message.info("没有权限访问该功能...");
        }
      } else {
        this.$router.push({
          path: "/contract/elecontract"
        });
      }
    },
    onDianzidangan: function onDianzidangan() {
      if (this.token) {
        if (this.menuUrls.includes("/staff/employee")) {
          this.$router.push({
            path: "/staff/employee"
          });
        } else if (this.menuUrls.includes("/staff/manger")) {
          this.$router.push({
            path: "/staff/manger"
          });
        } else if (this.menuUrls.includes("/staff/edit")) {
          this.$router.push({
            path: "/staff/edit"
          });
        } else {
          this.$message.info("没有权限访问该功能...");
        }
      } else {
        this.$router.push({
          path: "/staff/employee"
        });
      }
    },
    onGeshui: function onGeshui() {
      if (this.token) {
        if (this.$hasPermissionSecond("zhinengsuanshui:koujiaoyiwurenguanli")) {
          this.$router.push({
            path: "/taxCalculate/management"
          });
        } else if (this.$hasPermissionSecond("zhinengsuanshui:baoshuirenyuanku")) {
          this.$router.push({
            path: "/taxCalculate/people"
          });
        } else if (this.$hasPermissionSecond("zhinengsuanshui:renyuanbaosong.daibaosong")) {
          this.$router.push({
            path: "/taxCalculate/waiting"
          });
        } else if (this.$hasPermissionSecond("zhinengsuanshui:renyuanbaosong.yibaosong")) {
          this.$router.push({
            path: "/taxCalculate/report"
          });
        } else if (this.$hasPermissionSecond("zhinengsuanshui:renyuanbaosong.tingzhibaosong")) {
          this.$router.push({
            path: "/taxCalculate/stop"
          });
        } else if (this.$hasPermissionSecond("hinengsuanshui:geshuijisuan")) {
          this.$router.push({
            path: "/taxCalculate/calculate"
          });
        } else {
          this.$message.info("没有权限访问该功能...");
        }
      } else {
        this.$router.push({
          path: "/taxCalculate/management"
        });
      }
    },
    onZhihuizhan: function onZhihuizhan() {
      this.$message.info("功能正在开发中...");
    }
  }
};