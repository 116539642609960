import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "select-company-wrapper"
  }, [_c("a-popover", {
    attrs: {
      trigger: "click",
      visible: _vm.clicked,
      placement: "bottom"
    },
    on: {
      visibleChange: _vm.handleClickChange
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      cursor: "pointer",
      "min-height": "24px"
    }
  }, [_vm.hasLeftIcon ? _c("i", {
    staticClass: "iconfont icon-gongsi-2-line2x fs16 mr8",
    staticStyle: {
      color: "#55565d"
    },
    on: {
      click: function click(e) {
        return e.preventDefault();
      }
    }
  }) : _vm._e(), _c("span", {
    staticClass: "font-title",
    on: {
      click: function click(e) {
        return e.preventDefault();
      }
    }
  }, [_vm._v(_vm._s(_vm.showSelect ? _vm.showSelect : _vm.site.selectName))]), _c("i", {
    staticClass: "iconfont icon-swap2x fs16 ml8",
    on: {
      click: function click(e) {
        return e.preventDefault();
      }
    }
  })]), _c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("a-input-search", {
    staticClass: "mb8",
    staticStyle: {
      width: "270px"
    },
    attrs: {
      placeholder: "搜索关键字",
      "allow-clear": true
    },
    on: {
      change: _vm.onSearchChange
    }
  }), _c("a-tree", {
    staticStyle: {
      "max-width": "300px",
      "max-height": "400px",
      overflow: "auto"
    },
    attrs: {
      "show-icon": true,
      "tree-data": _vm.dataList,
      replaceFields: _vm.replaceFields,
      "expanded-keys": _vm.expandedKeys,
      blockNode: true
    },
    on: {
      expand: _vm.onExpand,
      select: _vm.onSelect
    },
    scopedSlots: _vm._u([{
      key: "custom",
      fn: function fn(item) {
        return _c("div", {}, [_c("div", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [item.companyList === null ? _c("a-radio", {
          attrs: {
            slot: "icon",
            checked: _vm.site.selectId === item.id
          },
          slot: "icon"
        }) : _vm._e(), _c("div", {
          staticStyle: {
            flex: "1"
          }
        }, [_c("span", {
          domProps: {
            innerHTML: _vm._s(item.name)
          }
        })])], 1)]);
      }
    }])
  })], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };