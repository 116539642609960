import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.slice.js";
import { recordList, reUpload, getDetailByOrderId, undoOrder as _undoOrder, companyAudit, getDownloadFile } from "./api";
import { publicDownLoad } from "../downLoad";
import moment from "moment";
import { getToken } from "xqjr-module-auth";
import { number } from "mathjs";
var math = require("mathjs");
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      fileList: [],
      headers: {},
      auditResultsCause: undefined,
      recordList: ["1", "2", "3"],
      auditFileList: [],
      detail: {},
      combined: 0,
      current: 1,
      total: 0,
      empNumber: undefined,
      loading: false,
      againVisible: false,
      detailTemplateFile: "",
      pageState: null,
      detailTemplateFileName: "",
      fileListMultiple: [],
      fileListMultipleCopy: [],
      auditFileListCopy: [],
      latestTable: null,
      latestAttachment: [],
      typeCurrent: 1,
      passOrRefuse: null,
      auditVisible: false,
      selectList: [{
        name: "待核保审核",
        value: "0",
        color: "#4282FF",
        background: "rgba(66, 130, 255, 0.2)"
      }, {
        name: "核保审核不通过",
        value: "1",
        color: "#FA5051",
        background: "rgba(250, 80, 81, 0.2)"
      }, {
        name: "已撤销",
        value: "2",
        color: "#8D8D8D",
        background: "rgba(171, 171, 171, 0.2)"
      }, {
        name: "核保审核通过",
        value: "3",
        color: "#24CDAA",
        background: "rgba(36, 205, 170, 0.2)"
      }, {
        name: "进行中",
        value: "4",
        color: "#FFA600",
        background: " rgba(255, 166, 0, 0.2)"
      }, {
        name: "已结束",
        value: "5",
        color: "#8D8D8D",
        background: "rgba(171, 171, 171, 0.2)"
      }]
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    var _this = this;
    this.headers = {
      token: getToken()
    };
    var detail = JSON.parse(this.$route.query.detail);
    this.pageState = sessionStorage.getItem("shangbaoPageState");
    this.loading = true;
    var type = this.pageState === "company" ? "insuranceCompany" : "customer";
    getDetailByOrderId(detail.id, type).then(function (res) {
      _this.detail = res.data;
      _this.getRecordList();
    }).catch(function (err) {
      console.log(err);
      _this.loading = false;
    });
  },
  mounted: function mounted() {},
  methods: {
    limitNumber: function limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) && value !== "0" ? value.replace(/^(0+)|[^\d]/g, "") : value === "0" ? "0" : "";
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/^(0+)|[^\d]/g, "") : "";
      } else {
        return "";
      }
    },
    calculate: function calculate(e) {
      var _this2 = this;
      if (this.detail.insEffectTime && this.detail.insFailureTime) {
        var startDate = moment(moment(moment(this.detail.insEffectTime).format("YYYY-MM-DD"), "YYYY-MM-DD")).format("YYYY-MM");
        var endDate = moment(moment(moment(this.detail.insFailureTime).format("YYYY-MM-DD"), "YYYY-MM-DD")).format("YYYY-MM");
        var monthDiff = moment(endDate).diff(moment(startDate), "month") + 1;
      }
      this.combined = 0;
      this.detail.personTypeList.forEach(function (item, index) {
        _this2.combined = Number(_this2.$NumberAdd(_this2.combined, _this2.$NumberMul(_this2.$NumberMul(item.realityMonthAmount, item.personCount), monthDiff)));
      });
    },
    onChange: function onChange(current) {
      this.typeCurrent = current;
    },
    download: function download(file) {
      if (file.url) {
        publicDownLoad(file.url);
      } else if (file.response.data && file.response.data[0]) {
        publicDownLoad(file.response.data[0]);
      } else {
        this.$message.warning("文件下载失败");
      }
    },
    // 审核
    audit: function audit(e) {
      this.auditVisible = true;
      this.passOrRefuse = e;
      this.combined = 0;
    },
    auditOk: function auditOk() {
      var _this3 = this;
      var nextState = true;
      this.detail.personTypeList.forEach(function (item) {
        if (!item.personCount && item.personCount !== 0) {
          nextState = false;
        }
      });
      if (nextState || this.passOrRefuse === 1) {
        this.loading = true;
        companyAudit({
          id: this.detail.id,
          passOrRefuse: this.passOrRefuse !== 1,
          companyAttachment: this.auditFileListCopy,
          auditResultsCause: this.auditResultsCause,
          personTypeList: this.passOrRefuse === 2 ? this.detail.personTypeList : undefined,
          actualTotalFee: this.passOrRefuse === 2 ? this.combined : undefined
        }).then(function (res) {
          _this3.auditVisible = false;
          _this3.$message.success("审核成功");
          var type = _this3.pageState === "company" ? "insuranceCompany" : "customer";
          getDetailByOrderId(_this3.detail.id, type).then(function (res) {
            _this3.detail = res.data;
            _this3.getRecordList();
          }).catch(function (err) {
            console.log(err);
            _this3.loading = false;
          });
        });
      } else {
        this.$message.warning("请填写投保人数");
      }
    },
    // 重新上傳
    againOK: function againOK() {
      var _this4 = this;
      if (!this.fileList.length) {
        this.$message.warning("请上传投保明细表");
      } else {
        this.loading = true;
        reUpload({
          id: this.detail.id,
          detailFile: this.detailTemplateFile,
          customerAttachment: this.fileListMultipleCopy
          // insuredNumber: this.empNumber,
          // totalFee: this.combined
        }).then(function (res) {
          _this4.againVisible = false;
          _this4.$message.success("上传成功");
          var type = _this4.pageState === "company" ? "insuranceCompany" : "customer";
          getDetailByOrderId(_this4.detail.id, type).then(function (res) {
            _this4.detail = res.data;
            _this4.getRecordList();
          }).catch(function (err) {
            console.log(err);
            _this4.loading = false;
          });
        });
      }
    },
    // 撤销
    undoOrder: function undoOrder() {
      var that = this;
      this.$confirm({
        title: "确定撤销订单吗?",
        onOk: function onOk() {
          that.loading = true;
          _undoOrder(that.detail.id).then(function (res) {
            that.$message.success("撤销成功");
            var type = that.pageState === "company" ? "insuranceCompany" : "customer";
            getDetailByOrderId(that.detail.id, type).then(function (res) {
              that.detail = res.data;
              that.getRecordList();
            }).catch(function (err) {
              console.log(err);
              that.loading = false;
            });
          });
        },
        onCancel: function onCancel() {
          console.log("Cancel");
        },
        class: "test"
      });
    },
    handleChangeAudit: function handleChangeAudit(info) {
      if (_toConsumableArray(info.fileList) && _toConsumableArray(info.fileList).length > 10) {
        this.$message.warning("最多上传十个附件");
      } else {
        var fileList = _toConsumableArray(info.fileList);
        fileList = fileList.map(function (file) {
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });
        this.auditFileList = fileList;
        var arr = [];
        if (this.auditFileList && this.auditFileList.length) {
          this.auditFileList.forEach(function (item, index) {
            if (item.status === "done" && item.response.data && item.response.data[0]) {
              arr.push({
                name: item.name,
                url: item.response.data[0]
              });
            }
          });
        }
        if (arr && arr.length) {
          this.auditFileListCopy = JSON.stringify(arr);
        } else {
          this.auditFileListCopy = undefined;
        }
      }
    },
    handleChangeMultiple: function handleChangeMultiple(info) {
      if (_toConsumableArray(info.fileList) && _toConsumableArray(info.fileList).length > 10) {
        this.$message.warning("最多上传十个附件");
      } else {
        var fileList = _toConsumableArray(info.fileList);
        fileList = fileList.map(function (file) {
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });
        this.fileListMultiple = fileList;
        var arr = [];
        if (this.fileListMultiple && this.fileListMultiple.length) {
          this.fileListMultiple.forEach(function (item, index) {
            if (item.status === "done" && item.response.data && item.response.data[0]) {
              arr.push({
                name: item.name,
                url: item.response.data[0]
              });
            }
          });
        }
        if (arr && arr.length) {
          this.fileListMultipleCopy = JSON.stringify(arr);
        } else {
          this.fileListMultipleCopy = undefined;
        }
      }
    },
    handleChange: function handleChange(info) {
      var fileList = _toConsumableArray(info.fileList);
      fileList = fileList.slice(-1);
      fileList = fileList.map(function (file) {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
      if (this.fileList && this.fileList.length) {
        if (this.fileList[0].status === "done" && this.fileList[0].response.data && this.fileList[0].response.data[0]) {
          this.detailTemplateFile = this.fileList[0].response.data[0];
          this.detailTemplateFileName = this.fileList[0].name;
        }
      } else {
        this.detailTemplateFile = "";
        this.detailTemplateFileName = "";
      }
    },
    // combinedChange(e) {
    //   if (this.detail.insEffectTime && this.detail.insFailureTime) {
    //     var startDate = moment(moment(moment(this.detail.insEffectTime).format("YYYY-MM-DD"), "YYYY-MM-DD")).format("YYYY-MM");
    //     var endDate = moment(moment(moment(this.detail.insFailureTime).format("YYYY-MM-DD"), "YYYY-MM-DD")).format("YYYY-MM");
    //     var monthDiff = moment(endDate).diff(moment(startDate), "month") + 1;
    //   }
    //   if (this.detail.monthAmount && monthDiff) {
    //     this.combined = this.detail.monthAmount * e * monthDiff;
    //   } else {
    //     this.combined = 0;
    //   }
    // },
    downLoad: function downLoad(e) {
      window.open(e);
    },
    downLoadFile: function downLoadFile(e) {
      publicDownLoad(e);
    },
    onPageChange: function onPageChange() {
      this.getRecordList();
    },
    getRecordList: function getRecordList() {
      var _this5 = this;
      recordList({
        pageSize: 5,
        pageNo: this.current,
        orderId: this.detail.id
      }).then(function (res) {
        _this5.recordList = res.data.entities;
        _this5.recordList.forEach(function (item) {
          if (item.customerAttachment) {
            item.customerAttachment = JSON.parse(item.customerAttachment);
          }
          if (item.companyAttachment) {
            item.companyAttachment = JSON.parse(item.companyAttachment);
          }
        });
        if (_this5.recordList && _this5.recordList.length) {
          _this5.latestTable = _this5.recordList[0].detailFile || null;
          _this5.latestAttachment = _this5.recordList[0].customerAttachment || [];
        }
        _this5.total = res.data.entityCount;
        _this5.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this5.loading = false;
      });
    },
    uploadAgain: function uploadAgain() {
      this.againVisible = true;
      this.fileList = [];
      this.fileListMultiple = [];
      this.empNumber = undefined;
    },
    againCancel: function againCancel() {
      this.againVisible = false;
    }
  }
};