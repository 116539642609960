export var columnCustomer = [{
  title: "名称",
  dataIndex: "name"
}, {
  title: "本月人数",
  dataIndex: "empNumber"
}, {
  title: "服务费",
  dataIndex: "serviceCharge"
}, {
  title: "客户费用",
  dataIndex: "customerFees"
}];
export var initColumns = [{
  title: "姓名",
  dataIndex: "empName",
  key: "empName",
  width: 120,
  fixed: "left"
}, {
  title: "身份证",
  dataIndex: "idCard",
  key: "idCard",
  width: 230,
  fixed: "left"
}, {
  title: "应发工资",
  dataIndex: "shouldWages",
  key: "shouldWages",
  width: 120,
  scopedSlots: {
    customRender: "shouldWages"
  }
}, {
  title: "个人社保",
  dataIndex: "empSocialSecurity",
  key: "empSocialSecurity",
  width: 120,
  scopedSlots: {
    customRender: "empSocialSecurity"
  }
}, {
  title: "企业社保",
  dataIndex: "enterpriseSocialSecurity",
  key: "enterpriseSocialSecurity",
  width: 120,
  scopedSlots: {
    customRender: "enterpriseSocialSecurity"
  }
}, {
  title: "个人公积金",
  dataIndex: "empProvidentFund",
  key: "empProvidentFund",
  width: 140,
  scopedSlots: {
    customRender: "empProvidentFund"
  }
}, {
  title: "企业公积金",
  dataIndex: "enterpriseProvidentFund",
  key: "enterpriseProvidentFund",
  width: 140,
  scopedSlots: {
    customRender: "enterpriseProvidentFund"
  }
}, {
  title: "个人所得税",
  dataIndex: "tax",
  key: "tax",
  width: 140,
  scopedSlots: {
    customRender: "tax"
  }
}, {
  title: "实发工资",
  dataIndex: "paidWages",
  key: "paidWages",
  width: 120,
  scopedSlots: {
    customRender: "paidWages"
  }
}, {
  title: "服务费",
  dataIndex: "serviceCharge",
  key: "serviceCharge",
  width: 120,
  scopedSlots: {
    customRender: "serviceCharge"
  }
}];