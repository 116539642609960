var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: this.selectedRowKeysId.length > 0 ? "编辑薪资" : "批量编辑薪资",
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 19
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "薪资"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["money", {
        rules: [{
          required: true,
          message: "薪资不能为空"
        }]
      }],
      expression: "['money', { rules: [{ required: true, message: '薪资不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入",
      min: 1,
      max: 50000,
      precision: 2
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };