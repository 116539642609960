import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "infoCard"
  }, [_c("div", {
    staticClass: "tit"
  }, [_c("img", {
    attrs: {
      src: _vm.img,
      alt: "",
      srcset: ""
    }
  }), _vm._v(" " + _vm._s(_vm.tit) + " ")]), _c("div", {
    staticClass: "item-box"
  }, _vm._l(_vm.arr, function (item) {
    return _c("div", {
      key: item.name,
      staticClass: "item"
    }, [_c("div", [_vm._v(_vm._s(item.name) + "：")]), _c("div", [_vm._v(_vm._s(item.val))])]);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };