import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import { checkEmpSocInfos, updateEmpSocStatus } from "@/api/shebao/shebaoInfo.js";
var column = [{
  title: "参保月份",
  key: "yearMonth",
  align: "center",
  scopedSlots: {
    customRender: "yearMonth"
  },
  ellipsis: true
}, {
  title: "个人社保费",
  dataIndex: "personalSocAmount",
  align: "center",
  ellipsis: true
}, {
  title: "公司社保费",
  dataIndex: "companySocAmount",
  align: "center",
  ellipsis: true
}, {
  title: "个人公积金费",
  dataIndex: "personalPfAmount",
  align: "center",
  ellipsis: true
}, {
  title: "公司公积金费",
  dataIndex: "companyPfAmount",
  align: "center",
  ellipsis: true
}];
export default {
  data: function data() {
    return {
      isStopShebao: false,
      column: column,
      dataList: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171".concat(total, "\u6761");
        },
        showQuickJumper: true
      },
      progItemListVo: [],
      empId: "",
      monthYear: "",
      cutYear: "",
      cutMonth: "",
      empMonthList: [],
      id: "",
      dataYear: [],
      depName: "",
      name: "",
      progName: "",
      pfCard: "",
      socCard: "",
      idCard: "",
      socCityName: "",
      month: "",
      year: "",
      empPhoto: "",
      companyPfAmount: "",
      companySocAmount: "",
      personalPfAmount: "",
      personalSocAmount: "",
      dateOpen: false,
      searchYear: null
    };
  },
  created: function created() {
    if (this.$route.query.id) {
      this.year = this.$route.query.year;
      this.month = this.$route.query.month;
      this.id = this.$route.query.id;
      this.empId = this.$route.query.empId;
      this.depName = this.$route.query.depName;
      this.name = this.$route.query.name;
      this.pfCard = this.$route.query.pfCard;
      this.socCard = this.$route.query.socCard;
      this.idCard = this.$route.query.idCard;
      this.socCityName = this.$route.query.socCityName;
      this.progName = this.$route.query.progName;
      this.empPhoto = this.$route.query.empPhoto;
    }
    this.getEmpInfo();
  },
  methods: {
    // 停止参保
    stopShebao: function stopShebao() {
      this.isStopShebao = true;
    },
    // 按年搜索
    handleYear: function handleYear(val) {
      this.getEmpInfo();
    },
    openChange: function openChange(val) {
      this.year = val.format("YYYY");
      this.getEmpInfo();
      this.dateOpen = false;
    },
    // 处理减员月份
    handleCutMonth: function handleCutMonth(val) {
      this.cutMonth = this.monthYear.substring(5, 7);
      this.cutYear = this.monthYear.substring(0, 4);
    },
    handleCancel: function handleCancel() {
      this.isStopShebao = false;
    },
    submitStop: function submitStop() {
      var _this = this;
      var data = {
        empId: this.empId,
        companyId: this.$store.state.hr.companyId,
        year: this.cutYear,
        month: this.cutMonth,
        empSocStatus: 2
      };
      updateEmpSocStatus(data).then(function (res) {
        _this.$router.go(-1);
      });
      this.isStopShebao = false;
    },
    // 展开个人信息
    expandedRowsChange: function expandedRowsChange(expend, value) {
      this.progItemListVo = value.progItemListVo;
    },
    // 查询员工参保记录
    getEmpInfo: function getEmpInfo() {
      var _this2 = this;
      var data = {
        year: this.year,
        empId: this.empId,
        companyId: this.$store.state.hr.companyId
      };
      checkEmpSocInfos(data).then(function (res) {
        _this2.dataList = res.data.entities;
        _this2.dataList.map(function (item) {
          if (_this2.empMonthList) {
            _this2.empMonthList = [];
            _this2.empMonthList.push(item);
          }
        });
        // if(this.dataYear){
        //   this.dataYear = []
        //   for(var i = 0; i < this.dataList.length; i++){
        //     for(var j = 0; j < this.dataList.length; j++){
        //       if(this.dataList[i].year === this.dataList[j].year){
        //         j = ++i
        //       }
        //     }
        //     this.dataYear.push(this.dataList[i])

        //   }
        // }
        var hash = {};
        _this2.dataYear = _this2.dataList.reduce(function (arr, current) {
          hash[current.year] ? "" : hash[current.year] = true && arr.push(current);
          return arr;
        }, []);
      });
    },
    goback: function goback() {
      this.$router.go(-1);
    }
  }
};