export default {
  fields: {
    punchType: {
      model: "punchType"
    },
    punchStatus: {
      model: "punchStatus"
    },
    punchTime: {
      model: "punchTime"
    },
    location: {
      model: "location"
    },
    punchSource: {
      model: "punchSource"
    },
    remark: {
      model: "remark"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "punchType",
        title: "类型",
        width: 140
      }, {
        model: "punchStatus",
        title: "状态",
        width: 100
      }, {
        model: "punchTime",
        title: "时间",
        width: 150
      }, {
        model: "location",
        title: "地点",
        width: 200
      }, {
        model: "punchSource",
        title: "设备",
        width: 150
      }, {
        model: "remark",
        title: "备注",
        width: 100
      }],
      // customize: {
      //   rowKey: "empInfoId"
      // },
      extend: {
        batch: true,
        action: {
          width: 100
        }
      }
    }
  }
};