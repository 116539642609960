import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "body page-miH page-bgBack"
  }, [_c("a-row", {
    staticClass: "mg_b20",
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", [_c("a-input-search", {
    staticStyle: {
      width: "200px",
      "margin-right": "24px"
    },
    attrs: {
      placeholder: "姓名/手机号/分管公司",
      allowClear: ""
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "账号角色"
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.roleId,
      callback: function callback($$v) {
        _vm.roleId = $$v;
      },
      expression: "roleId"
    }
  }, _vm._l(_vm.roleList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-col", [_c("a-button", {
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: _vm.addCompany
    }
  }, [_vm._v(" 添加账号 ")])], 1)], 1), _c("a-table", {
    attrs: {
      "row-key": function rowKey(a, b) {
        return b;
      },
      pagination: _vm.pagination,
      "data-source": _vm.dataSource,
      loading: _vm.tableLoading
    },
    on: {
      change: function change(_ref) {
        var current = _ref.current;
        return _vm.pagination.current = current;
      }
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "姓名",
      "data-index": "name",
      align: "center"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "手机号",
      "data-index": "phone",
      align: "center"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "分管公司",
      "data-index": "companyName",
      align: "center"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "账号角色",
      "data-index": "roleName",
      align: "center"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "操作",
      width: 212,
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.geEditor(text);
            }
          }
        }, [_vm._v("编辑")]), _c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.deleteCount(text);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };