export default {
  fields: {
    positionName: {
      model: "positionName"
    },
    timeDay: {
      model: "timeDay"
    },
    attendPlanName: {
      model: "attendPlanName"
    },
    "arriveOne.arriveTime": {
      model: "arriveOne.arriveTime"
    },
    "arriveOne.arrivePlace": {
      model: "arriveOne.arrivePlace"
    },
    "leaveOne.leaveTime": {
      model: "leaveOne.leaveTime"
    },
    "leaveOne.leavePlace": {
      model: "leaveOne.leavePlace"
    },
    "arriveTwo.arriveTime": {
      model: "arriveTwo.arriveTime"
    },
    "arriveTwo.arrivePlace": {
      model: "arriveTwo.arrivePlace"
    },
    "leaveTwo.leaveTime": {
      model: "leaveTwo.leaveTime"
    },
    "leaveTwo.leavePlace": {
      model: "leaveTwo.leavePlace"
    },
    workDate: {
      model: "workDate"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "timeDay",
        title: "打卡日期",
        width: 150
      }, {
        model: "positionName",
        title: "岗位",
        width: 150
      }, {
        model: "attendPlanName",
        title: "考勤规则",
        width: 150
      }, {
        model: "arriveOne.arriveTime",
        title: "第一次打卡上班时间",
        width: 160
      }, {
        model: "arriveOne.arrivePlace",
        title: "第一次打卡上班地点",
        width: 300
      }, {
        model: "leaveOne.leaveTime",
        title: "第一次打卡下班时间",
        width: 160
      }, {
        model: "leaveOne.leavePlace",
        title: "第一次打卡下班地点",
        width: 300
      }, {
        model: "arriveTwo.arriveTime",
        title: "第二次打卡上班时间",
        width: 160
      }, {
        model: "arriveTwo.arrivePlace",
        title: "第二次打卡上班地点",
        width: 300
      }, {
        model: "leaveTwo.leaveTime",
        title: "第二次打卡下班时间",
        width: 160
      }, {
        model: "leaveTwo.leavePlace",
        title: "第二次打卡下班地点",
        width: 300
      }, {
        model: "workDate",
        title: "工作时长",
        width: 150
      }],
      // customize: {
      //   rowKey: "empInfoId"
      // },
      extend: {
        batch: true,
        action: true
      }
    }
  }
};