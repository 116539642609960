var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "overview-staff-wrapper"
  }, [_c("div", {
    staticClass: "selectBox"
  }, [_c("a-range-picker", {
    ref: "date",
    staticClass: "datePicker",
    attrs: {
      allowClear: false,
      placeholder: ["开始月份", "结束月份"],
      format: "YYYY-MM",
      mode: _vm.mode,
      value: _vm.value
    },
    on: {
      panelChange: _vm.handlePanelChange2
    }
  }, [_c("template", {
    slot: "renderExtraFooter"
  }, [_c("a-button", {
    key: "submit",
    staticStyle: {
      "margin-left": "460px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.dateOk
    }
  }, [_vm._v(" 确定 ")])], 1)], 2), _c("a-select", {
    staticClass: "selectPicker",
    attrs: {
      placeholder: "请选择",
      "default-value": "1"
    },
    on: {
      change: _vm.handleChange
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 总计 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 单位金额 ")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 个人金额 ")])], 1)], 1), _c("total-count", {
    ref: "totalCount",
    attrs: {
      dateBox: _vm.dateBox,
      queryType: _vm.queryType
    }
  }), _c("statistical", {
    ref: "statistical",
    attrs: {
      dateBox: _vm.dateBox,
      queryType: _vm.queryType
    }
  }), _c("data-details", {
    ref: "dataDetails",
    attrs: {
      dateBox: _vm.dateBox,
      queryType: _vm.queryType
    }
  }), _c("ginseng-details", {
    ref: "ginsengDetails",
    attrs: {
      dateBox: _vm.dateBox,
      queryType: _vm.queryType
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };