import request from "@/utils/request";
export function getCount(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/selectStatisticsCount",
    method: "post",
    data: data
  });
}
export function selectAllStaff(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/selectAllStaff",
    method: "post",
    data: data
  });
}
export function getStatisticsEmpInfoContractConut(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/getStatisticsEmpInfoContractCount",
    method: "post",
    data: data
  });
}
export function empEduCount(data) {
  return request({
    url: "/hr/api/v1/statisticsPage/empEduCount",
    method: "post",
    data: data
  });
}