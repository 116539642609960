export default {
  fields: {
    visitTime: {
      model: "visitTime"
    },
    memberName: {
      model: "memberName"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "visitTime",
        title: "拜访时间"
      }, {
        model: "memberName",
        title: "拜访人"
      }],
      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: true
      }
    }
  }
};