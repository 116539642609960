import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import { mapState } from "vuex";
import { loadApprovalGroupListReq, loadApprovalTypeListReq, loadFormByTypeReq } from "../../../api";
import icons from "./icon";
import { isContainByString } from "@/utils/index";
export default {
  name: "BaseSet",
  props: {
    value: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      groupList: [],
      // 审批分组列表
      typeList: [],
      // 审批类型列表
      showIcon: false,
      icons: icons
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    companyId: function companyId() {
      return this.hr.companyId;
    }
  }),
  created: function created() {
    this.loadGroupList();
    this.loadTypeList();
  },
  methods: {
    onValueChange: function onValueChange(e, key) {
      var val = "";
      switch (key) {
        case "typeName":
          val = e.target.value;
          break;
        case "groupId":
          val = e;
          break;
        case "type":
          val = e;
          this.onApprovalTypeChange(e);
          break;
        case "revokeType":
          val = e;
          break;
        case "isSysStart":
          val = e;
          break;
        case "icon":
          val = e;
          break;
        case "typeDesc":
          val = e.target.value;
          break;
        default:
          break;
      }
      var res = Object.assign({}, this.value, _defineProperty({}, key, val));
      console.log(res);
      this.$emit("input", res);
      this.$emit("change", res);
    },
    // 选择图标
    choseIcon: function choseIcon(icon) {
      this.onValueChange(icon, "icon");
      this.showIcon = false;
    },
    loadGroupList: function loadGroupList() {
      var _this = this;
      loadApprovalGroupListReq(isContainByString("newApprovalAdmin") ? "0" : isContainByString("defaultTransferAccounts") ? "defaultTransferAccounts" : this.companyId, "hr").then(function (res) {
        _this.groupList = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    loadTypeList: function loadTypeList() {
      var _this2 = this;
      loadApprovalTypeListReq().then(function (res) {
        _this2.typeList = res.data ? res.data : [];
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    onApprovalTypeChange: function onApprovalTypeChange(flowType) {
      var _this3 = this;
      loadFormByTypeReq(flowType).then(function (_ref) {
        var data = _ref.data;
        if (data && data.length) {
          data.map(function (v) {
            return v.props = JSON.parse(v.props);
          });
          console.log("debug log --> ", data);
          _this3.$emit("formTypeChange", data);
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};