var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$route.query.id ? "编辑客户" : "新增客户") + " ")]), _c("div", {
    staticClass: "box"
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      layout: "vertical"
    }
  }, [_c("tit", {
    staticStyle: {
      margin: "22px 0 24px"
    }
  }, [_vm._v("公司信息")]), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "公司名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyName", {
        rules: [{
          required: true,
          message: "公司名称不能为空"
        }]
      }],
      expression: "['companyName', { rules: [{ required: true, message: '公司名称不能为空' }] }]"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入公司名称",
      autocomplete: "off"
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "公司类型"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyType"],
      expression: "['companyType']"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请选择公司类型"
    }
  }, _vm._l(_vm.companyTypeSelectArr, function (item) {
    return _c("a-select-option", {
      key: item.k,
      attrs: {
        value: item.v
      }
    }, [_vm._v(" " + _vm._s(item.k) + " ")]);
  }), 1)], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "公司所在城市"
    }
  }, [_c("city", {
    staticClass: "w400",
    attrs: {
      "default-value": _vm.socCity,
      level: 3
    },
    on: {
      change: function change($event) {
        return _vm.handleCity($event);
      }
    }
  }), _c("a-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["socCitys", {
        rules: [{
          required: true,
          message: "公司所在城市不能为空"
        }]
      }],
      expression: "['socCitys', { rules: [{ required: true, message: '公司所在城市不能为空' }] }]"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入公司所在城市",
      autocomplete: "off"
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "公司详细地址"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyAddress", {
        rules: [{
          required: true,
          message: "公司详细地址不能为空"
        }]
      }],
      expression: "['companyAddress', { rules: [{ required: true, message: '公司详细地址不能为空' }] }]"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入公司详细地址",
      autocomplete: "off"
    }
  })], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "公司行业"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyIndustry"],
      expression: "['companyIndustry']"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入公司行业",
      autocomplete: "off"
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "公司规模"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyPersonSize"],
      expression: "['companyPersonSize']"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请选择公司规模"
    }
  }, _vm._l(_vm.companyPersonSizeSelectArr, function (item) {
    return _c("a-select-option", {
      key: item.k,
      attrs: {
        value: item.v
      }
    }, [_vm._v(" " + _vm._s(item.k) + " ")]);
  }), 1)], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "项目名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["entryName", {
        rules: [{
          required: false,
          message: "项目名称不能为空"
        }]
      }],
      expression: "['entryName', { rules: [{ required: false, message: '项目名称不能为空' }] }]"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入项目名称",
      autocomplete: "off"
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "产品名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["productName"],
      expression: "['productName']"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入产品名称",
      autocomplete: "off"
    }
  })], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "客户情况"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["customerSituation"],
      expression: "['customerSituation']"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入客户情况",
      autocomplete: "off"
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "客户需求"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["customerDemand"],
      expression: "['customerDemand']"
    }],
    staticClass: "w400",
    attrs: {
      mode: "multiple",
      allowClear: "",
      placeholder: "请选择客户需求"
    }
  }, _vm._l(_vm.customerDemandSelectArr, function (item) {
    return _c("a-select-option", {
      key: item.k,
      attrs: {
        value: item.v
      }
    }, [_vm._v(" " + _vm._s(item.k) + " ")]);
  }), 1)], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "预计签约时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["estimatedSigningTime"],
      expression: "['estimatedSigningTime']"
    }],
    staticClass: "w400",
    attrs: {
      valueFormat: "YYYY-MM-DD",
      placeholder: "请选择预计签约时间"
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "预计派单时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["estimatedDistributeTime"],
      expression: "['estimatedDistributeTime']"
    }],
    staticClass: "w400",
    attrs: {
      valueFormat: "YYYY-MM-DD",
      placeholder: "请选择预计派单时间"
    }
  })], 1)], 1)], 1), _c("a-row", [_c("a-col", [_c("a-form-item", {
    attrs: {
      label: "客户介绍"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["customerIntroduce"],
      expression: "['customerIntroduce']"
    }],
    attrs: {
      allowClear: "",
      placeholder: "请输入客户介绍",
      "auto-size": {
        minRows: 3,
        maxRows: 5
      }
    }
  })], 1)], 1)], 1), _c("a-row", [_c("a-col", [_c("a-form-item", {
    attrs: {
      label: "备注"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["mark"],
      expression: "['mark']"
    }],
    attrs: {
      allowClear: "",
      placeholder: "请输入备注",
      "auto-size": {
        minRows: 3,
        maxRows: 5
      }
    }
  })], 1)], 1)], 1), _c("a-row", [_c("a-col", [_c("a-form-item", [_c("div", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("span", {
    staticClass: "fs14 fw500"
  }, [_vm._v("上传合同")]), _c("span", {
    staticClass: "fs12 ml8",
    staticStyle: {
      color: "rgba(118, 120, 133, 1)"
    }
  }, [_vm._v("支持 doc，ppt，pdf，jpg，png 等格式文件，文件不得超过20M。")])]), _c("div", {
    staticClass: "uploadBoxes"
  }, [_c("uploadImg", {
    staticStyle: {
      "min-height": "96px"
    },
    attrs: {
      val: _vm.contract,
      num: 10
    },
    on: {
      upData: _vm.upData1
    }
  }, [_c("div", {
    staticClass: "uploadBox"
  }, [_c("div", [_c("a-icon", {
    attrs: {
      type: "plus-circle",
      theme: "filled"
    }
  })], 1), _c("div", {
    staticClass: "font"
  }, [_vm._v("上传合同")])])])], 1), _vm.$route.query.type == 2 ? _c("div", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["contract", {
        rules: [{
          required: true,
          message: "合同不能为空"
        }]
      }],
      expression: "['contract', { rules: [{ required: true, message: '合同不能为空' }] }]"
    }, {
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入",
      autocomplete: "off"
    }
  })], 1) : _vm._e()])], 1)], 1), _c("a-row", [_c("a-col", [_c("a-form-item", {
    attrs: {
      label: "合同有效期"
    }
  }, [_vm.$route.query.type == 1 ? _c("a-range-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["contractTime"],
      expression: "['contractTime']"
    }],
    staticClass: "w400",
    attrs: {
      valueFormat: "YYYY-MM-DD"
    }
  }) : _c("a-range-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["contractTime", {
        rules: [{
          required: true,
          message: "合同有效期不能为空"
        }]
      }],
      expression: "['contractTime', { rules: [{ required: true, message: '合同有效期不能为空' }] }]"
    }],
    staticClass: "w400",
    attrs: {
      valueFormat: "YYYY-MM-DD"
    }
  })], 1)], 1)], 1), _c("a-row", [_c("a-col", [_c("a-form-item", [_c("div", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("span", {
    staticClass: "fs14 fw500"
  }, [_vm._v("上传营业执照")]), _c("span", {
    staticClass: "fs12 ml8",
    staticStyle: {
      color: "rgba(118, 120, 133, 1)"
    }
  }, [_vm._v("支持 doc，ppt，pdf，jpg，png 等格式文件，文件不得超过20M。")])]), _c("div", {
    staticClass: "uploadBoxes"
  }, [_c("uploadImg", {
    staticStyle: {
      "min-height": "96px"
    },
    attrs: {
      num: 10,
      val: _vm.businessLicense
    },
    on: {
      upData: _vm.upData2
    }
  }, [_c("div", {
    staticClass: "uploadBox"
  }, [_c("div", [_c("a-icon", {
    attrs: {
      type: "plus-circle",
      theme: "filled"
    }
  })], 1), _c("div", {
    staticClass: "font"
  }, [_vm._v("上传营业执照")])])])], 1), _vm.$route.query.type == 2 ? _c("div", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["businessLicense", {
        rules: [{
          required: true,
          message: "营业执照不能为空"
        }]
      }],
      expression: "['businessLicense', { rules: [{ required: true, message: '营业执照不能为空' }] }]"
    }, {
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入",
      autocomplete: "off"
    }
  })], 1) : _vm._e()])], 1)], 1), _c("a-row", [_c("a-col", [_c("a-form-item", {
    attrs: {
      label: "合作产品"
    }
  }, [_vm.$route.query.type == 1 ? _c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["cooperativeProducts"],
      expression: "['cooperativeProducts']"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入合作产品",
      autocomplete: "off"
    }
  }) : _c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["cooperativeProducts", {
        rules: [{
          required: true,
          message: "合作产品不能为空"
        }]
      }],
      expression: "['cooperativeProducts', { rules: [{ required: true, message: '合作产品不能为空' }] }]"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入合作产品",
      autocomplete: "off"
    }
  })], 1)], 1)], 1), _c("tit", {
    staticStyle: {
      margin: "22px 0 24px"
    }
  }, [_vm._v("联系人信息")]), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "联系人姓名"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "联系人姓名不能为空"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '联系人姓名不能为空' }] }]"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入联系人姓名",
      maxLength: 10,
      autocomplete: "off"
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "性别"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["gender"],
      expression: "['gender']"
    }],
    staticClass: "w400"
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 男 ")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 女 ")]), _c("a-radio", {
    attrs: {
      value: 3
    }
  }, [_vm._v(" 未知 ")])], 1)], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "手机"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["phone", {
        rules: [{
          required: true,
          message: "手机不能为空"
        }, {
          pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
          message: "请输入11位数字类型手机号"
        }]
      }],
      expression: "[\n                'phone',\n                {\n                  rules: [\n                    { required: true, message: '手机不能为空' },\n                    {\n                      pattern: /^1(3|4|5|6|7|8|9)\\d{9}$/,\n                      message: '请输入11位数字类型手机号'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入手机",
      autocomplete: "off"
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "邮箱"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["email", {
        rules: [{
          pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
          message: "请输入正确的邮箱"
        }]
      }],
      expression: "[\n                'email',\n                {\n                  rules: [\n                    {\n                      pattern: /^([a-zA-Z]|[0-9])(\\w|\\-)+@[a-zA-Z0-9]+\\.([a-zA-Z]{2,4})$/,\n                      message: '请输入正确的邮箱'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入邮箱",
      autocomplete: "off"
    }
  })], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "微信"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["wechat"],
      expression: "['wechat']"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入微信",
      autocomplete: "off",
      maxLength: 18
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "职位"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postName"],
      expression: "['postName']"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入职位",
      autocomplete: "off",
      maxLength: 10
    }
  })], 1)], 1)], 1), _c("tit", {
    staticStyle: {
      margin: "22px 0 24px"
    }
  }, [_vm._v("法人信息")]), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "法人姓名"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["legalPersonName"],
      expression: "['legalPersonName']"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入法人姓名",
      autocomplete: "off",
      maxLength: 10
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "性别"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["legalPersonGender"],
      expression: "['legalPersonGender']"
    }],
    staticClass: "w400"
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 男 ")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 女 ")]), _c("a-radio", {
    attrs: {
      value: 3
    }
  }, [_vm._v(" 未知 ")])], 1)], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "手机"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["legalPersonPhone", {
        rules: [{
          pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
          message: "请输入11位数字类型手机号"
        }]
      }],
      expression: "[\n                'legalPersonPhone',\n                {\n                  rules: [\n                    {\n                      pattern: /^1(3|4|5|6|7|8|9)\\d{9}$/,\n                      message: '请输入11位数字类型手机号'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入法人手机",
      autocomplete: "off"
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "邮箱"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["legalPersonEmail", {
        rules: [{
          pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
          message: "请输入正确的邮箱"
        }]
      }],
      expression: "[\n                'legalPersonEmail',\n                {\n                  rules: [\n                    {\n                      pattern: /^([a-zA-Z]|[0-9])(\\w|\\-)+@[a-zA-Z0-9]+\\.([a-zA-Z]{2,4})$/,\n                      message: '请输入正确的邮箱'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入法人邮箱",
      autocomplete: "off"
    }
  })], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "微信"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["legalPersonWechat"],
      expression: "['legalPersonWechat']"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入法人微信",
      autocomplete: "off",
      maxLength: 18
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "col"
  }, [_c("a-form-item", {
    attrs: {
      label: "职位"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["legalPersonPostName"],
      expression: "['legalPersonPostName']"
    }],
    staticClass: "w400",
    attrs: {
      allowClear: "",
      placeholder: "请输入法人职位",
      autocomplete: "off",
      maxLength: 10
    }
  })], 1)], 1)], 1), _c("a-form-item", {
    staticClass: "btn"
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v(" 提交 ")]), _vm.$route.query.type == 1 && _vm.$route.query.id ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleSubmit("isSign");
      }
    }
  }, [_vm._v(" 签约客户 ")]) : _vm._e()], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };