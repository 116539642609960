import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "body_detail page-miH page-bgBack"
  }, [_c("a-row", {
    staticClass: "company",
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", [_c("a-icon", {
    staticClass: "mg_r20",
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }), !_vm.editor ? _c("span", [_vm._v("添加账号")]) : _vm._e()], 1)], 1), _c("div", {
    staticClass: "base_detail"
  }, [_vm._v("基本信息")]), _c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 12
      },
      label: "账号姓名"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "账号姓名必填"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '账号姓名必填' }] }]"
    }],
    attrs: {
      disabled: _vm.editor,
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 12
      },
      label: "手机号码"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["phone", {
        rules: [{
          required: true,
          message: "手机号必填"
        }, {
          pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
          message: "请输入11位手机号"
        }]
      }],
      expression: "[\n          'phone',\n          {\n            rules: [\n              { required: true, message: '手机号必填' },\n              { pattern: /^1(3|4|5|6|7|8|9)\\d{9}$/, message: '请输入11位手机号' }\n            ]\n          }\n        ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "max-length": 11,
      disabled: _vm.editor,
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 12
      },
      label: "邮箱"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["email", {
        rules: [{
          pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
          message: "请输入正确的邮箱"
        }]
      }],
      expression: "[\n          'email',\n          {\n            rules: [\n              {\n                pattern: /^([a-zA-Z]|[0-9])(\\w|\\-)+@[a-zA-Z0-9]+\\.([a-zA-Z]{2,4})$/,\n                message: '请输入正确的邮箱'\n              }\n            ]\n          }\n        ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      disabled: _vm.editor,
      placeholder: "请输入"
    }
  })], 1), !this.$route.query.id ? _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 12
      },
      label: "初始密码"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["password", {
        rules: [{
          required: true,
          message: "初始密码必填"
        }]
      }],
      expression: "['password', { rules: [{ required: true, message: '初始密码必填' }] }]"
    }],
    attrs: {
      disabled: _vm.disabled,
      placeholder: "请输入"
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 12
      },
      label: "账号角色"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["roleName", {
        rules: [{
          required: true,
          message: "账号角色必填"
        }]
      }],
      expression: "['roleName', { rules: [{ required: true, message: '账号角色必填' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      disabled: _vm.disabled
    },
    on: {
      change: _vm.handleChange
    }
  }, _vm._l(_vm.rolesList, function (role) {
    return _c("a-select-option", {
      key: role.id,
      attrs: {
        value: role.id
      }
    }, [_vm._v(_vm._s(role.name))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 12
      },
      label: "是否游客模式"
    }
  }, [_c("a-switch", {
    attrs: {
      disabled: _vm.disabled2 ? _vm.disabled2 : _vm.disabled,
      "checked-children": "是",
      "un-checked-children": "否",
      "default-checked": ""
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.isReadOnly,
      callback: function callback($$v) {
        _vm.isReadOnly = $$v;
      },
      expression: "isReadOnly"
    }
  }), _c("a-tooltip", {
    staticClass: "ml12",
    attrs: {
      placement: "right"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v("游客模式仅支持查看、下载数据不支持任何修改")])]), _c("a-icon", {
    staticClass: "primarys",
    attrs: {
      type: "question-circle",
      theme: "filled"
    }
  })], 2)], 1), _c("a-form-item", {
    staticClass: "ygdw",
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 12
      },
      label: "用工单位"
    }
  }, [_c("a-switch", {
    attrs: {
      disabled: _vm.disabled2 ? _vm.disabled2 : _vm.disabled,
      "checked-children": "全部",
      "un-checked-children": "自选",
      "default-checked": ""
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.choseEmp,
      callback: function callback($$v) {
        _vm.choseEmp = $$v;
      },
      expression: "choseEmp"
    }
  })], 1), !_vm.choseEmp ? _c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      },
      label: "分管单位"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.showModal
    }
  }, [_vm._v(" 共" + _vm._s(_vm.targetKeys.length) + "个 "), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "选择分管单位",
      width: "800px"
    },
    on: {
      ok: _vm.handleOk
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-transfer", {
    staticClass: "tree-transfer",
    attrs: {
      "list-style": {
        width: "320px",
        height: "400px"
      },
      operations: ["添加", "删除"],
      disabled: _vm.disabled,
      "data-source": _vm.dataSource,
      "target-keys": _vm.targetKeys,
      render: function render(item) {
        return item.title;
      },
      "show-select-all": false,
      "show-search": ""
    },
    on: {
      change: _vm.onTransChange,
      search: _vm.onSeletChange
    },
    scopedSlots: _vm._u([{
      key: "children",
      fn: function fn(_ref) {
        var _ref$props = _ref.props,
          direction = _ref$props.direction,
          selectedKeys = _ref$props.selectedKeys,
          itemSelect = _ref.on.itemSelect;
        return [direction === "left" ? _c("a-tree", {
          staticStyle: {
            height: "300px",
            "overflow-y": "scroll"
          },
          attrs: {
            disabled: _vm.disabled,
            defaultExpandAll: "",
            blockNode: "",
            checkable: "",
            checkStrictly: "",
            replaceFields: _vm.replaceFields,
            checkedKeys: [].concat(_toConsumableArray(selectedKeys), _toConsumableArray(_vm.targetKeys)),
            "tree-data": _vm.treeData
          },
          on: {
            check: function check(_, props) {
              _vm.onChecked(_, props, [].concat(_toConsumableArray(selectedKeys), _toConsumableArray(_vm.targetKeys)), itemSelect);
            },
            select: function select(_, props) {
              _vm.onChecked(_, props, [].concat(_toConsumableArray(selectedKeys), _toConsumableArray(_vm.targetKeys)), itemSelect);
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 623471435)
  })], 1)], 1) : _vm._e()], 1), !_vm.disabled ? _c("a-button", {
    staticClass: "mg_l82",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveData
    }
  }, [_vm._v(_vm._s(_vm.editor ? "确认" : "添加确认"))]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };