import request from "@/utils/request";
export function getEmpInfoCountReq(companyId) {
  return request({
    url: "/hr/api/v1/empInfos/getEmpInfoCount/".concat(companyId),
    method: "get"
  });
}
export function jfHomePageNoticeReq() {
  return request({
    url: "/hr/api/v1/notice/jfHomePageNotice",
    method: "get"
  });
}
export function selectCancelCountBySiteReq() {
  return request({
    url: "/recruit2/api/v1/recruitInfos/selectFrontBySite",
    method: "get"
  });
}