import FormJson from "./FormJson"; // 引入FormJson数据
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
import { retrieveReq } from "./api"; // 引入接口文件

var curdMixin = new XqCurdMixin({
  retrieveReq: retrieveReq,
  FormJson: FormJson,
  tableConfigKey: "table"
});
export default {
  props: {
    siteId: {
      type: String,
      default: undefined
    }
  },
  mixins: [curdMixin],
  data: function data() {
    return {
      pageSize: 20
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {
          siteId: this.siteId
        }
      };
    },
    createParams: function createParams() {
      return {};
    },
    updateParams: function updateParams() {
      return {};
    }
  },
  methods: {
    formatValues: function formatValues(values) {
      return values;
    },
    formatDataSource: function formatDataSource(record) {
      return record;
    }
  }
};