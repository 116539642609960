import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { getFeeSum, getChannelFeeSettlement, deletChannelFeeSettlement } from "@/api/outsourcing/outsourcing";
import { findCompanyGroup as _findCompanyGroup, saveChannelSettlement, getChannelGroupsAll } from "@/api/company/contract";
var columns = [{
  title: "渠道名称",
  dataIndex: "channelName",
  key: "channelName",
  width: 160
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 200
}, {
  title: "所属分组",
  dataIndex: "channelGroupName",
  scopedSlots: {
    customRender: "channelGroupName"
  },
  key: "channelGroupName",
  width: 200
}, {
  title: "产生费用的人数",
  dataIndex: "feePeopleNumber",
  key: "feePeopleNumber",
  width: 150
}, {
  title: "工时抽成合计",
  dataIndex: "sumWorkTimeRake",
  key: "sumWorkTimeRake",
  width: 150
},
// {
//   title: "一次性费用合计",
//   dataIndex: "sumOneTimeFee",
//   key: "sumOneTimeFee",
//   width: 150
// },
{
  title: "一次性费用合计",
  dataIndex: "sumSpecialFee",
  key: "sumSpecialFee",
  width: 150
}, {
  title: "应付总费用合计",
  dataIndex: "sumTotalFee",
  key: "sumTotalFee",
  width: 150
}, {
  title: "操作",
  dataIndex: "action",
  key: "action",
  scopedSlots: {
    customRender: "action"
  },
  width: 120
}];
import moment from "moment";
import axios from "axios";
import { mapGetters } from "vuex";
import { isContainByString } from "@/utils/index";
import qs from "qs";
export default {
  data: function data() {
    return {
      lastSelect: [],
      selectedListTemp: [],
      isAllSelected: false,
      treeData: [],
      querys: "",
      isConfirmPlan: false,
      typeValueTime: 1,
      form: {},
      dataList: [],
      dataList2: [],
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      },
      companyList: [],
      flatDataList: [],
      columns: columns,
      size: "default",
      loading: false,
      selectedRowKeys: [],
      now: null,
      combined: {},
      nameOrIdCard: null,
      monthly: moment().format("MM"),
      yearly: moment().format("YYYY"),
      replaceFields2: {
        children: "companyList",
        title: "name",
        key: "uuid",
        value: "uuid"
      },
      addVisible: false,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      isContainByString: isContainByString
    };
  },
  created: function created() {
    var _this = this;
    getChannelGroupsAll({}).then(function (res) {
      if (res.data) {
        _this.treeData = res.data;
        _this.treeData.map(function (item) {
          item.show = true;
        });
      }
    }).catch(function (err) {
      console.log("debug log --> ", err);
    });
    this.now = this.$route.query.index ? JSON.parse(this.$route.query.index) : moment(moment().format("YYYY-MM"), "YYYY-MM");
    this.getFeeSumDetail();
    this.findCompanyGroup();
    this.getFeeSumContentList();
  },
  computed: _objectSpread({}, mapGetters(["permissions_site"])),
  methods: {
    setGroupPanel: function setGroupPanel() {
      this.isConfirmPlan = true;
    },
    deleteItem: function deleteItem(item) {
      item.isSelected = false;
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.ergodic(item);
      }, "10");
    },
    chose: function chose(item, data) {
      // // 减员单选逻辑
      // if (!this.zengyuan && !item.isSelected) {
      //   let id = "";
      //   var index2 = this.treeData.findIndex(it => it.isSelected === true); // 删除之前选择的，维持单选状态
      //   if (index2 !== -1) {
      //     this.treeData[index2].isSelected = false;
      //     id = this.treeData[index2].id;
      //   }

      //   index2 = this.selectedListTemp.findIndex(it => it.id === id); // 删除之前选择的，维持单选状态
      //   if (index2 !== -1) {
      //     this.selectedListTemp.splice(index2, 1); // 删除
      //     this.isAllSelected = false;
      //   }
      // }

      item.isSelected = !item.isSelected;
      this.onChange(item);
    },
    onChange: function onChange(item) {
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.ergodic(item);
        that.spinning = false;
      }, "10");
    },
    ergodic: function ergodic(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
          this.isAllSelected = false;
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
          var count = this.treeData.length;
          var allSelect = true;
          for (var i = 0; i < count; i++) {
            if (!this.treeData[i].isSelected) {
              allSelect = false;
              break;
            }
          }
          this.isAllSelected = allSelect;
        }
      }
    },
    cleanT: function cleanT() {
      this.isAllSelected = false;
      var that = this;
      that.selectedListTemp = [];
      for (var j = 0, len = that.treeData.length; j < len; j++) {
        that.treeData[j].isSelected = false;
      }
    },
    choseAll: function choseAll(data) {
      this.isAllSelected = !this.isAllSelected;
      var that = this;
      for (var j = 0, len = data.length; j < len; j++) {
        data[j].isSelected = that.isAllSelected;
        that.ergodicAll(data[j]);
      }
    },
    ergodicAll: function ergodicAll(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
        }
      }
    },
    searchPeople: function searchPeople(query) {
      var _this2 = this;
      this.cleanT();
      this.querys = query;
      if (this.querys.length === 0) {
        // 显示宣布
        this.treeData.map(function (item) {
          item.show = true;
        });
      } else {
        // 根据条件显示
        this.treeData.map(function (item) {
          item.show = false;
        });
        this.treeData.map(function (item) {
          if (item.name.indexOf(_this2.querys) !== -1) {
            item.show = true;
          }
        });
      }
    },
    onTypeChangeValue: function onTypeChangeValue(e) {
      this.typeValueTime = e.target.value;
      if (this.typeValueTime === 1) {
        // 保持原定参保时间
        // this.cleanT();
        // this.lastSelect = [];
      } else if (this.typeValueTime === 2) {
        // 批量更改参保时间
      }
    },
    confirmOk: function confirmOk() {
      var a = [];
      a = this.getList();
      if (this.typeValueTime === 2 && a.length === 0) {
        this.$message.error("请选择分组");
        return;
      }
      this.getFeeSumDetail();
      this.getFeeSumContentList();
      this.isConfirmPlan = false;
      this.lastSelect = [];
      for (var i = 0; i < this.selectedListTemp.length; i++) {
        this.lastSelect.push(JSON.parse(JSON.stringify(this.selectedListTemp[i])));
      }
      console.log("=============");
      console.log(this.lastSelect);
    },
    treeSearch: function treeSearch(e) {
      this.findCompanyGroup(e);
    },
    findCompanyGroup: function findCompanyGroup(e) {
      var _this3 = this;
      _findCompanyGroup({
        name: e || null
      }).then(function (res) {
        _this3.dataList2 = _this3.addTreeSlot(res.data);
        _this3.flatten(_this3.dataList2);
        _this3.companyList = JSON.parse(JSON.stringify(_this3.companyList));
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    addTreeSlot: function addTreeSlot(data) {
      var _this4 = this;
      if (!data) {
        return [];
      }
      data.forEach(function (el) {
        el.scopedSlots = {
          title: "custom"
        };
        _this4.addTreeSlot(el.companyList);
      });
      return data;
    },
    // 递归处理为扁平数组
    flatten: function flatten(list) {
      var _this5 = this;
      if (list !== null) {
        list.forEach(function (item) {
          _this5.flatDataList.push(item);
          _this5.flatten(item.companyList);
        });
      }
    },
    handleCancel: function handleCancel(e) {
      this.addVisible = false;
      this.companyList = [];
      // if(!this.getConf){
      //   this.cleanT();
      // }
      //  this.getConf = false;
    },
    handleCancelGroup: function handleCancelGroup() {
      var _this6 = this;
      this.isConfirmPlan = false;
      if (this.typeValueTime === 2 && this.lastSelect.length === 0) {
        this.cleanT();
        this.typeValueTime = 1;
      }
      if (this.lastSelect.length !== 0) {
        for (var i = 0; i < this.treeData.length; i++) {
          this.treeData[i].isSelected = false;
          this.lastSelect.map(function (item) {
            if (_this6.treeData[i].id === item.id) {
              _this6.treeData[i].isSelected = true;
            }
          });
        }
        this.selectedListTemp = this.lastSelect;
      }
    },
    handleOk: function handleOk() {
      var _this7 = this;
      if (this.companyList && this.companyList.length > 0) {
        var companyArr = [];
        this.companyList.forEach(function (item) {
          _this7.flatDataList.forEach(function (item2, index2) {
            if (item2.uuid && item2.uuid === item.value) {
              companyArr.push(item2.id);
            }
          });
        });
        saveChannelSettlement({
          companyIdList: companyArr,
          monthly: moment(this.now).format("MM"),
          yearly: moment(this.now).format("YYYY")
        }).then(function (res) {
          _this7.addVisible = false;
          _this7.$notification["success"]({
            message: "成功",
            description: "创建成功"
          });
          _this7.companyList = [];
          _this7.getFeeSumDetail();
          _this7.findCompanyGroup();
          _this7.getFeeSumContentList();
        }).catch(function (err) {
          console.log(err);
          _this7.companyList = [];
          _this7.addVisible = false;
        });
      } else {
        this.$notification["error"]({
          message: "错误",
          description: "请选择用工单位"
        });
      }
    },
    showAdd: function showAdd() {
      this.addVisible = true;
    },
    download: function download(i) {
      var _this8 = this;
      var token = this.$store.state.auth.token;
      var siteName = "";
      this.permissions_site.forEach(function (item, index) {
        if (item.currSite) siteName = item.name;
      });
      var data = {};
      if (i === 2) {
        data = {
          // 全部
          level: this.typeValueTime,
          monthly: moment(this.now).format("MM"),
          yearly: moment(this.now).format("YYYY")
        };
        if (this.typeValueTime === 2) {
          data.channelGroupIdList = this.getList();
        }
        if (this.nameOrIdCard) {
          data.nameOrIdCard = this.nameOrIdCard;
        }
      } else {
        // 批量
        console.log(this.selectedRowKeys);
        var idss = [];
        // this.selectedRowKeys.map(item => {
        //   idss += item + ",";
        // });
        idss = this.selectedRowKeys;
        data = {
          level: this.typeValueTime,
          ids: idss,
          monthly: moment(this.now).format("MM"),
          yearly: moment(this.now).format("YYYY")
        };
        if (this.typeValueTime === 2) {
          data.channelGroupIdList = this.getList();
        }
        if (this.nameOrIdCard) {
          data.nameOrIdCard = this.nameOrIdCard;
        }
      }
      axios({
        // url: `/salary/api/v1/channelFeeSettlements/export`,
        url: "/hr/api/v1/channelGroups/channelFeeSettlementExport",
        data: data,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "post",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        if (res.data && res.data.type === "application/json") {
          var reader = new FileReader();
          reader.readAsText(res.data, "utf-8");
          reader.onload = function () {
            var resJson = JSON.parse(reader.result);
            _this8.$message.error(resJson.msg);
          };
        } else {
          var blob = new Blob([res.data], {
            type: "application/octet-stream"
          });
          var link = document.createElement("a");
          try {
            link.download = "".concat(siteName, "_") + "渠道劳务费详情" + ".xlsx";
          } catch (err) {
            console.log("debug log --> ", res);
          }
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 查询合计
    getFeeSumDetail: function getFeeSumDetail() {
      var _this9 = this;
      getFeeSum({
        monthly: moment(this.now).format("MM"),
        yearly: moment(this.now).format("YYYY"),
        level: this.typeValueTime,
        channelGroupIdList: this.typeValueTime === 1 ? null : this.getList()
      }).then(function (res) {
        _this9.combined = res.data ? res.data : {};
      });
    },
    inputChange: function inputChange(e) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.nameOrIdCard = e;
      this.getFeeSumContentList();
    },
    // 查询详情
    getFeeSumContentList: function getFeeSumContentList() {
      var _this10 = this;
      getChannelFeeSettlement({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        monthly: moment(this.now).format("MM"),
        yearly: moment(this.now).format("YYYY"),
        level: this.typeValueTime,
        channelGroupIdList: this.typeValueTime === 1 ? null : this.getList(),
        nameOrIdCard: this.nameOrIdCard ? this.nameOrIdCard : null
      }).then(function (res) {
        if (res.data) {
          _this10.dataList = res.data.entities ? res.data.entities : [];
          _this10.pagination.total = res.data.entities && res.data.entityCount;
        } else {
          _this10.dataList = [];
          _this10.pagination.total = 0;
        }
      });
    },
    getList: function getList() {
      var arr = [];
      this.selectedListTemp.map(function (item) {
        arr.push(item.id);
      });
      return arr;
    },
    goDetail: function goDetail(e) {
      this.$router.push({
        path: this.isContainByString("settlementAdmin") ? "/settlementAdmin/labor/detail" : "/settlement/labor/detail",
        query: {
          detail: JSON.stringify(e),
          defaultTime: JSON.stringify(this.now)
        }
      });
    },
    goDelete: function goDelete(e) {
      var _this11 = this;
      deletChannelFeeSettlement(e.id).then(function (res) {
        _this11.getFeeSumDetail();
        _this11.findCompanyGroup();
        _this11.getFeeSumContentList();
      });
    },
    monthChange: function monthChange(e) {
      var query = JSON.parse(JSON.stringify(this.$router.history.current.query)); // 取当前路由query值并且深拷贝
      var path = this.$router.history.current.path; // 取当前路由地址
      query.index = JSON.stringify(e); // 修改变动的query值
      this.$router.push({
        path: path,
        query: query
      }); // 改变query值
      this.monthly = moment(e).format("MM");
      this.yearly = moment(e).format("YYYY");
      this.getFeeSumDetail();
      this.getFeeSumContentList();
      console.log(this.monthly + "/" + this.yearly);
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getFeeSumContentList();
      // this.getEmpSelectPage();
    },
    onSelectChange: function onSelectChange(selectedRowKeys, selectedRow) {
      // this.selectedRowId = [];
      this.selectedRowKeys = selectedRowKeys;
    }
  }
};