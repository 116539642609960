export default {
  fields: {
    planName: {
      model: "planName"
    },
    punchTimeList: {
      model: "punchTimeList"
    },
    wifiList: {
      model: "wifiList"
    },
    locationList: {
      model: "locationList"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "planName",
        title: "规则名称"
      }, {
        model: "punchTimeList",
        title: "打卡时间"
      },
      // {
      //   model: "wifiList",
      //   title: "打卡WiFi",
      //   width: 200
      // },
      {
        model: "locationList",
        title: "打卡地址"
      }],
      // customize: {
      //   rowKey: "empInfoId"
      // },
      extend: {
        batch: true,
        action: true
      }
    }
  }
};