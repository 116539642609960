var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("dir", {
    staticClass: "add-payslip-one"
  }, [_c("a-row", {
    staticClass: "content-top"
  }, [_c("step", {
    attrs: {
      current: 0
    }
  })], 1), _c("a-row", {
    staticClass: "salary",
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    staticClass: "item",
    attrs: {
      span: 9
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 8
    },
    on: {
      click: _vm.selectItem
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "nameStyle"
  }, [_vm._v("总共")])]), _c("a-row", {
    staticClass: "primary"
  }, [_vm._v(_vm._s(_vm.titles.countAll))])], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 6
    },
    on: {
      click: _vm.selectItem
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("企业员工")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.countInCompany))])], 1), _c("a-col", {
    attrs: {
      span: 6
    },
    on: {
      click: _vm.selectItem
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("非企业员工")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.countNotInCompany))])], 1)], 1)], 1), _c("a-col", {
    staticClass: "item",
    attrs: {
      span: 6
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 8
    },
    on: {
      click: _vm.selectItem
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("微信绑定")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.countWeChatBind))])], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 6
    },
    on: {
      click: _vm.selectItem
    }
  }, [_c("a-row", {
    staticClass: "ove"
  }, [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("微信未绑定")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.countWeChatUnBind))])], 1)], 1)], 1), _c("a-col", {
    staticClass: "item",
    attrs: {
      span: 7
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("应发工资")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.sumSalaryAmount))])], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("实发工资")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.sumSalaryReal))])], 1)], 1)], 1)], 1), _c("a-table", {
    staticClass: "mg_t24",
    attrs: {
      scroll: {
        x: 1500
      },
      columns: _vm.columns,
      pagination: _vm.pagination,
      "data-source": _vm.dataSource,
      "row-key": function rowKey(r, i) {
        return i;
      }
    },
    on: {
      change: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "empInCompany",
      fn: function fn(empInCompany) {
        return [empInCompany ? _c("xq-icon", {
          staticClass: "primary_color w32 fs24",
          attrs: {
            type: "icon-wancheng2"
          }
        }) : _vm._e(), !empInCompany ? _c("xq-icon", {
          staticStyle: {
            "font-size": "24px",
            color: "#bfbfbf"
          },
          attrs: {
            type: "icon-wancheng2"
          }
        }) : _vm._e()];
      }
    }, {
      key: "phoneStatus",
      fn: function fn(phoneStatus) {
        return [phoneStatus ? _c("xq-icon", {
          staticClass: "primary_color w32 fs24",
          attrs: {
            type: "icon-wancheng2"
          }
        }) : _vm._e(), !phoneStatus ? _c("xq-icon", {
          staticStyle: {
            "font-size": "24px",
            color: "#bfbfbf"
          },
          attrs: {
            type: "icon-wancheng2"
          }
        }) : _vm._e()];
      }
    }, {
      key: "wechatStatus",
      fn: function fn(wechatStatus) {
        return [wechatStatus ? _c("xq-icon", {
          staticClass: "primary_color w32 fs24",
          attrs: {
            type: "icon-wancheng2"
          }
        }) : _vm._e(), !wechatStatus ? _c("xq-icon", {
          staticStyle: {
            "font-size": "24px",
            color: "#bfbfbf"
          },
          attrs: {
            type: "icon-wancheng2"
          }
        }) : _vm._e()];
      }
    }])
  }), _c("a-row", {
    staticClass: "foot"
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.reUpload
    }
  }, [_vm._v(" 重新上传 ")]), _c("a-button", {
    on: {
      click: _vm.doNext
    }
  }, [_vm._v("下一步")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };