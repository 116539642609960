import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { Wages, WagesSet } from "@/api/wages/index";
export default {
  name: "AllowanceSet",
  components: {
    addAllowance: function addAllowance() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/common/add_allowance"));
      });
    }
  },
  inject: ["reload"],
  data: function data() {
    return {
      data: [],
      addOptions: {},
      obj: {
        applyToProgAddDTO: {
          applyToProgDepAddDTOList: [],
          // 适用部门
          applyToProgLevelAddDTOList: [],
          // 适用职级
          applyToProgPositionAddDTOList: [],
          // 适用岗位
          busProgId: "",
          // 业务方案ID
          busProgType: "",
          // 业务方案类型
          employRange: 1,
          // 适用范围（1：按部门，2：按职级，3：按岗位）
          mutex: false // 是否和其余部门员工互斥
        },

        attendanceRange: true,
        // 适用出勤范围
        attendanceRangeDays: 1,
        // 出勤天数
        name: "",
        // 方案名称
        remark: "",
        // 备注
        subsidyInfoDOList: [{
          amount: 0,
          // 津贴金额
          createUserId: "",
          gmtCreate: "",
          // 创建日期
          gmtModified: "",
          // 修改日期
          id: "",
          // 主键id,
          modifiedUserId: "string",
          name: "",
          // 津贴名称
          progId: "",
          // 津贴方案id
          siteId: "",
          type: 1,
          // 津贴类型1 每日出勤2每计薪日3每月固定4(每月按出勤日)5(每月按计薪日)
          valid: null
        }],
        taxMethod: 1 // 计税方式 1 税前2税后
      }
    };
  },
  created: function created() {
    /* 获取津贴列表 */
    this.getallowanceList();
  },
  methods: {
    cbFun: function cbFun(e) {
      if (e) {
        this.reload();
      }
    },
    /* 添加津贴方案 */addAllowances: function addAllowances() {
      this.addOptions = {
        show: true,
        obj: {
          editorId: null
        }
      };
    },
    /* 获取津贴列表 */getallowanceList: function getallowanceList() {
      var _this = this;
      Wages({
        url: WagesSet.allowance,
        params: {
          companyId: this.$store.state.hr.companyId
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.data = data;
      });
    },
    /* 修改津贴方案 */editorDetail: function editorDetail(i) {
      var copyData = JSON.parse(JSON.stringify(this.data[i]));
      copyData.subsidyInfoDOList.forEach(function (item) {
        item.amount /= 100;
      });
      this.addOptions = {
        show: true,
        init: this.reload,
        id: this.data[i].id,
        obj: {
          editorId: this.data[i].id
        }
      };
    },
    /* 删除津贴方案 */del: function del(id) {
      var _this2 = this;
      Wages({
        url: WagesSet.delAllowance + id,
        method: "DELETE"
      }).then(function (data) {
        if (data.success) _this2.reload();
      });
    }
  }
};