var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "table-title-box",
    staticClass: "table-title-box"
  }, [_c("div", {
    staticClass: "table-title-box-left"
  }, [_vm._t("title")], 2), _c("div", {
    staticClass: "table-title-box-right"
  }, [_vm._t("action")], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };