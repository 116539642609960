import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.splice.js";
import { userList } from "./api";
export default {
  data: function data() {
    return {
      staffData: [],
      pageCount: 0,
      checkAll: false,
      staffFrom: {},
      spinning: true
    };
  },
  props: {
    give: String,
    empList: Array
  },
  model: {
    prop: "give",
    event: "returnBack"
  },
  created: function created() {
    this.getPageEmpLists();
  },
  methods: {
    getPageEmpLists: function getPageEmpLists() {
      var _this = this;
      this.spinning = true;
      userList(this.staffFrom, this.$store.state.hr.companyId).then(function (res) {
        res.data.forEach(function (element, index) {
          res.data[index].isTrue = false;
        });
        _this.empList.forEach(function (element) {
          res.data.forEach(function (esy) {
            if (element.id === esy.id) {
              esy.isTrue = true;
            }
          });
        });
        _this.staffData = res.data;
        _this.pageCount = res.data.entityCount;
        var arr = _this.staffData.filter(function (item) {
          return item.isTrue;
        });
        _this.checkAll = !!(arr.length === _this.staffData.length && arr.length !== 0);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      }).finally(function () {
        _this.spinning = false;
      });
    },
    // 选中某一个
    onRow: function onRow(item) {
      var _this2 = this;
      item.isTrue = !item.isTrue;
      if (item.isTrue) {
        var num = 0;
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            num += 1;
          }
        });
        this.checkAll = this.staffData.length === num;
        var arr = this.empList.filter(function (items) {
          return items.id === item.id;
        });
        if (arr.length === 0) {
          this.empList.push(item);
          var tetx = [];
          this.empList.map(function (item) {
            tetx.push(item.id);
          });
          this.$emit("returnBack", tetx.join(","));
          this.$emit("update:empList", this.empList);
        }
      } else {
        this.checkAll = false;
        this.empList.forEach(function (element, index) {
          if (item.id === element.id) {
            _this2.empList.splice(index, 1);
            var _tetx = [];
            _this2.empList.map(function (item) {
              _tetx.push(item.id);
            });
            _this2.$emit("returnBack", _tetx.join(","));
            _this2.$emit("update:empList", _this2.empList);
          }
        });
      }
    },
    // 全选当前页面
    onCheckAll: function onCheckAll() {
      var _this3 = this;
      if (this.staffData.length === 0) {
        return;
      }
      this.checkAll = !this.checkAll;
      if (this.checkAll) {
        this.staffData.forEach(function (element) {
          if (!element.isTrue) {
            _this3.onRow(element);
          }
        });
      } else {
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            _this3.empList.forEach(function (esy, index) {
              if (esy.id === element.id) {
                _this3.deleteItem(element, index);
              }
            });
          }
        });
      }
    },
    // 删除
    deleteItem: function deleteItem(item, index) {
      var _this4 = this;
      this.empList.splice(index, 1);
      var tetx = [];
      this.empList.map(function (item) {
        tetx.push(item.id);
      });
      this.$emit("returnBack", tetx.join(","));
      this.$emit("update:empList", this.empList);
      this.staffData.forEach(function (element) {
        if (element.id === item.id) {
          element.isTrue = false;
          _this4.checkAll = false;
        }
      });
    },
    // 搜索
    onSearch: function onSearch() {
      this.checkAll = false;
      this.staffFrom.pageNo = 1;
      this.getPageEmpLists();
    }
  }
};