import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper taxStyle page-miH page-bgBack pb24"
  }, [_c("a-layout", [_c("a-layout-sider", {
    staticStyle: {
      flex: "0 0 310px",
      "max-width": "310px",
      "min-width": "310px",
      width: "310px"
    }
  }, [_c("div", {
    staticClass: "taxC-m-sider",
    class: _vm.active == "active" ? "active-select " : ""
  }, [_vm.$hasPermission("zhinengsuanshui:koujiaoyiwurenguanli:sousuo") ? _c("div", {
    staticClass: "mb12"
  }, [_c("a-input-search", {
    attrs: {
      placeholder: "请输入企业名称"
    },
    on: {
      search: _vm.onSearchEnterprise
    },
    model: {
      value: _vm.searchEnterprise,
      callback: function callback($$v) {
        _vm.searchEnterprise = $$v;
      },
      expression: "searchEnterprise"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "tree-title"
  }, [_c("span", {
    staticClass: "organization-left-name",
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#000000"
    }
  }, [_vm._v("企业列表")]), _vm.$hasPermission("zhinengsuanshui:koujiaoyiwurenguanli:xinjianqiye") ? _c("a-button", {
    staticStyle: {
      "padding-right": "0"
    },
    attrs: {
      type: "link",
      icon: "plus"
    },
    on: {
      click: function click($event) {
        return _vm.onAdd(true);
      }
    }
  }, [_vm._v(" 新增企业 ")]) : _vm._e()], 1), _c("div", {
    staticClass: "tree-title",
    class: _vm.active == "active" ? "active " : "",
    on: {
      click: _vm.reloadData
    }
  }, [_c("img", {
    staticClass: "qiyeStyle",
    attrs: {
      src: require("../../../assets/taxCalculate/qiye.png"),
      alt: ""
    }
  }), _c("span", {
    staticClass: "organization-left-name"
  }, [_vm._v(_vm._s(_vm.selectName))])]), _c("a-tree", {
    attrs: {
      "show-icon": "",
      "default-expand-all": "",
      "tree-data": _vm.dataList,
      "expanded-keys": _vm.expandedKeys,
      "auto-expand-parent": _vm.autoExpandParent,
      blockNode: true,
      showIcon: true
    },
    on: {
      expand: _vm.onExpand,
      select: _vm.onSelect
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn(_ref) {
        var title = _ref.title;
        return [_c("a-tooltip", [_c("template", {
          slot: "title"
        }, [_vm._v(_vm._s(title))]), _c("span", [_vm._v(_vm._s(title))])], 2)];
      }
    }])
  }, [_c("img", {
    staticStyle: {
      width: "15px",
      height: "15px"
    },
    attrs: {
      slot: "floder",
      src: require("../../../assets/icons/icon2.png"),
      alt: ""
    },
    slot: "floder"
  })])], 1)]), _c("a-layout", {
    staticClass: "mt16 pl24 pr24"
  }, [_c("div", {
    staticClass: "mb16",
    staticStyle: {
      "font-weight": "500",
      "font-size": "16px",
      color: "#3c3d43"
    }
  }, [_vm._v(_vm._s(_vm.name))]), _c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", [_vm.$hasPermission("zhinengsuanshui:koujiaoyiwurenguanli:sousuo") ? _c("a-input-search", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      allowClear: "",
      placeholder: "统一社会信用代码/登记序号"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.searchName,
      callback: function callback($$v) {
        _vm.searchName = $$v;
      },
      expression: "searchName"
    }
  }) : _vm._e()], 1), _vm.$hasPermission("zhinengsuanshui:koujiaoyiwurenguanli:gengxinshuij") ? _c("a-col", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.onAdd(false);
      }
    }
  }, [_vm._v("更新税局信息")])], 1) : _vm._e()], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(_vm._s(col.customTitle))])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "gmtCreate" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text ? _vm.moment(text).format("YYYY-MM-DD HH:mm") : "--"))]) : _vm._e(), col.dataIndex === "branchRecordSign" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text === "0" ? "否" : text === "1" ? "是" : ""))]) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"],
      "page-size": _vm.pageSize
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1)], 1), _c("add", {
    attrs: {
      visible: _vm.visible,
      type: _vm.addType
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      upDate: _vm.upDate
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };