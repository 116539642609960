import request from "@/utils/request";
export function getTransferAccountsBatches(transferAccountsBatchId) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/".concat(transferAccountsBatchId),
    method: "GET"
  });
}
export function getCompanyUnits(params) {
  return request({
    // url: "/recruit2/api/v1/companyUnits/pageByCondition",
    url: "/hr/api/v1/childrenCompany/listAll/list",
    method: "GET",
    params: params
  });
}
export function postSaveAgainByBatchId(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/saveAgainByBatchId",
    method: "POST",
    data: data
  });
}
export function postCalculateTax(data) {
  return request({
    url: "/tax/api/v1/calculateBatchDetails/calculateTax",
    method: "POST",
    data: data
  });
}
export function getCalculateBatchDetails(params) {
  return request({
    url: "/tax/api/v1/calculateBatchDetails/getBatchDetail",
    method: "GET",
    params: params
  });
}
export function calculateBatchesGet(calculateBatchId) {
  return request({
    url: "/tax/api/v1/calculateBatches/".concat(calculateBatchId),
    method: "GET"
  });
}
export function calculateTaxFeedBack(data) {
  return request({
    url: "/tax/api/v1/calculateBatchDetails/calculateTaxFeedBack",
    method: "POST",
    data: data
  });
}