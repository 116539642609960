var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", _vm._l(_vm.imgData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("img", {
      attrs: {
        src: item,
        alt: ""
      }
    })]);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };