import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
var commonRoutes = [{
  path: "applyFor",
  // 发薪申请
  meta: {
    route: "/paySalary/applyFor",
    routeSession: "paySalaryPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/paySalary/applyFor"));
    });
  }
}, {
  path: "add",
  // 添加
  name: "applyForAdd",
  meta: {
    route: "/paySalary/applyFor",
    routeSession: "paySalaryPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/paySalary/applyFor/add"));
    });
  }
},
// {
//   path: "abnormal", // 异常
//   name: "applyForAbnormal",
//   component: () => import("@/views/paySalary/applyFor/abnormal")
// },
{
  path: "info",
  // 详情
  name: "applyForInfo",
  meta: {
    route: "/paySalary/applyFor",
    routeSession: "paySalaryPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/paySalary/applyFor/info"));
    });
  }
}, {
  path: "results",
  // 状态
  name: "applyForResults",
  meta: {
    route: "/paySalary/applyFor",
    routeSession: "paySalaryPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/paySalary/applyFor/results"));
    });
  }
}, {
  path: "examine",
  // 发薪审批
  meta: {
    route: "/paySalary/examine",
    routeSession: "paySalaryPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/paySalary/examine"));
    });
  }
}, {
  path: "examineDetail",
  // 发薪审批详情
  meta: {
    // route: "/paySalary/examine"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/paySalary/examine/examineDetail"));
    });
  }
}, {
  path: "pay",
  // 薪资发放
  meta: {
    route: "/paySalary/pay",
    routeSession: "paySalaryPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/paySalary/pay"));
    });
  }
}, {
  path: "authorization",
  // 薪资发放
  meta: {
    route: "/paySalary/pay",
    routeSession: "paySalaryPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/paySalary/pay/authorization"));
    });
  }
},
// {
//   path: "payDetail", // 发薪详情
//   component: () => import("@/views/paySalary/pay/payDetail")
// },
{
  path: "people",
  // 发现人员库
  meta: {
    route: "/paySalary/people",
    routeSession: "paySalaryPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/paySalary/people"));
    });
  }
}, {
  path: "merchant",
  // 账号权限
  meta: {
    route: "/paySalary/merchant",
    routeSession: "paySalaryPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("xqjr-module-console/views/businessConfig/paySalary.vue"));
    });
  }
}];
export default {
  path: "/paySalary",
  // 人事管理
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/paySalary"));
    });
  },
  redirect: "/paySalary/applyFor",
  children: [].concat(commonRoutes)
};