var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "logo-wrapper",
    class: {
      collapsed: _vm.collapsed
    }
  }, [_c("router-link", {
    key: "collapse",
    staticClass: "logo-link",
    attrs: {
      to: "/"
    }
  }, [_c("div", {
    staticClass: "logo-img",
    style: {
      "background-image": "url(".concat(_vm.backImg, ")")
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };