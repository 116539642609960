import request from "@/utils/request";
// 根据公司Id查找定薪模板
export function getEmpJoinDate(data) {
  return request({
    url: "hr/api/v1/empInfos/getEmpJoinDate",
    method: "post",
    data: data
  });
}
export function selectByCompanyId(params) {
  return request({
    url: "/salary/api/v1/fixedSalaryTemplates/selectByCompanyId",
    method: "get",
    params: params
  });
}
// 根据员工id查询工资详情 (调薪页面)
export function detaiByempId(fixedSalaryTemplateId) {
  return request({
    url: "/salary/api/v1/fixedSalaryTemplates/ListFindById/".concat(fixedSalaryTemplateId),
    method: "get"
  });
}
// 新增员工定薪记录表
export function empWage(data) {
  return request({
    url: "/salary/api/v1/fixedSalaryRecords",
    method: "post",
    data: data
  });
}
export function batch(data) {
  return request({
    url: "/salary/api/v1/fixedSalaryRecords/batchSave",
    method: "post",
    data: data
  });
}
export function batchWage(data) {
  return request({
    url: "salary/api/v1/fixedSalaryRecords/batchUpdate",
    method: "put",
    data: data
  });
}
// 员工调薪保存
export function empAdjustable(data) {
  return request({
    url: "/salary/api/v1/fixedSalaryRecords",
    method: "put",
    data: data
  });
}
// 根据员工id查询当前定薪内容(调薪页面)
export function SalaryIncrease(empId) {
  return request({
    url: "/salary/api/v1/empFixedSalaries/".concat(empId),
    method: "get"
  });
}
export function addSalaryTemplate(data) {
  return request({
    url: "/salary/api/v1/fixedSalaryTemplates",
    method: "post",
    data: data
  });
}
export function deleteSalaryTemplate(id) {
  return request({
    url: "/salary/api/v1/fixedSalaryTemplates/".concat(id),
    method: "delete"
  });
}
export function fixSalaryTemplate(data) {
  return request({
    url: "/salary/api/v1/fixedSalaryTemplates",
    method: "put",
    data: data
  });
}
export function haveUsedTemplate(params) {
  return request({
    url: "/salary/api/v1/fixedSalaryTemplates/haveUsedTemplate",
    method: "get",
    params: params
  });
}
export function getSalaryDetail(params) {
  return request({
    url: "/salary/api/v1/fixedSalaryRecords/listAllByEmpId",
    method: "get",
    params: params
  });
}