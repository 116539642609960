import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=d92721f8&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\tools\\frontend_workspace\\e-hr-master\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d92721f8')) {
      api.createRecord('d92721f8', component.options)
    } else {
      api.reload('d92721f8', component.options)
    }
    module.hot.accept("./footer.vue?vue&type=template&id=d92721f8&", function () {
      api.rerender('d92721f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/sidebar/components/footer.vue"
export default component.exports