export default {
  fields: {
    name: {
      model: "name"
    },
    phone: {
      model: "phone"
    },
    companyName: {
      model: "companyName"
    },
    companyAddress: {
      model: "companyAddress"
    },
    mark: {
      model: "mark"
    },
    sourceName: {
      model: "sourceName"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "name",
        title: "姓名"
      }, {
        model: "phone",
        title: "手机号"
      }, {
        model: "companyName",
        title: "公司名称"
      }, {
        model: "companyAddress",
        title: "地址"
      }, {
        model: "mark",
        title: "备注"
      }, {
        model: "sourceName",
        title: "录入人"
      }],
      customize: {
        rowKey: "id"
      },
      extend: {
        batch: true,
        action: true
      }
    }
  }
};