import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "p24 page-miH page-bgBack"
  }, [_c("Title", [_vm._v("消息设置")]), _c("p", {
    staticClass: "subhead_title"
  }, [_vm._v("选择接收消息的类型、人员、接收方式以及接收日期时间。")]), _c("a-table", {
    attrs: {
      loading: _vm.loading,
      childrenColumnName: "noticeTypeList",
      expandIcon: _vm.expandIcon,
      pagination: false,
      expandedRowKeys: _vm.expandedRowKeys,
      columns: _vm.columns,
      rowKey: "ids",
      "data-source": _vm.data
    },
    scopedSlots: _vm._u([{
      key: "empInfoVOList",
      fn: function fn(text, record) {
        return _c("div", {}, [record.noticeRuleSettingListVO ? _c("div", [_vm.containType(record.noticeRuleSettingListVO.empType, "0") ? _c("span", [_vm._v(" " + _vm._s(_vm._f("empInfoVOLists")(record.noticeRuleSettingListVO.userVOList)) + " " + _vm._s(_vm._f("empInfoVOList")(record.noticeRuleSettingListVO.userVOList)) + " 人 ")]) : _vm._e(), _vm.containType(record.noticeRuleSettingListVO.empType, "2") ? _c("span", [_vm._v("发起者本人")]) : _vm._e(), _vm.containType(record.noticeRuleSettingListVO.empType, "1") ? _c("span", [_vm._v("全部")]) : _vm._e(), _vm.containType(record.noticeRuleSettingListVO.empType, "3") ? _c("span", [_vm._v("渠道")]) : _vm._e()]) : _vm._e()]);
      }
    }, {
      key: "receiveMode",
      fn: function fn(text, record) {
        return _c("div", {}, [record.noticeRuleSettingListVO ? _c("span", [_vm._v(_vm._s(_vm._f("receiveType")(record.noticeRuleSettingListVO.receiveType)))]) : _vm._e()]);
      }
    }, {
      key: "receiptDate",
      fn: function fn(text, record) {
        return record.code ? _c("div", {}, [record.noticeRuleSettingListVO ? _c("span", [_vm._v(_vm._s(_vm._f("receiptDates")(record.noticeRuleSettingListVO)))]) : _vm._e()]) : _vm._e();
      }
    }, {
      key: "active",
      fn: function fn(text, record) {
        return _c("div", {}, [_c("div", {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: record.code,
            expression: "record.code"
          }]
        }, [_c("a-space", [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.showDrawer(record);
            }
          }
        }, [_vm._v("编辑")]), record.noticeRuleSettingListVO ? _c("a-switch", {
          on: {
            click: function click($event) {
              return _vm.isOpen(record.noticeRuleSettingListVO);
            }
          },
          model: {
            value: record.noticeRuleSettingListVO.enableStatus,
            callback: function callback($$v) {
              _vm.$set(record.noticeRuleSettingListVO, "enableStatus", $$v);
            },
            expression: "record.noticeRuleSettingListVO.enableStatus"
          }
        }) : _vm._e()], 1)], 1)]);
      }
    }], null, true)
  }), _c("a-drawer", {
    attrs: {
      maskClosable: false,
      width: "650",
      title: "编辑",
      placement: "right",
      closable: false,
      visible: _vm.visible,
      "after-visible-change": _vm.afterVisibleChange
    },
    on: {
      close: _vm.onClose
    }
  }, [_c("div", {
    staticClass: "editTop"
  }, [_vm._v(_vm._s(_vm.titleName))]), _c("div", {
    staticClass: "pl24"
  }, [_c("p", {
    staticClass: "editFont14"
  }, [_vm._v("接收人员")]), _vm.editForm.noticeTypeCode === "eventHandling" || _vm.editForm.noticeTypeCode === "jfContract" ? [_c("a-tree-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      treeData: _vm.userTreeData,
      showSearch: "",
      treeCheckable: "",
      treeNodeFilterProp: "title",
      placeholder: "请选择接收人员"
    },
    model: {
      value: _vm.userTreeSelected,
      callback: function callback($$v) {
        _vm.userTreeSelected = $$v;
      },
      expression: "userTreeSelected"
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userTreeSelectedLabels.length > 0,
      expression: "userTreeSelectedLabels.length > 0"
    }],
    staticClass: "mt8"
  }, [_vm._v(" 已选择 " + _vm._s(_vm.userTreeSelectedLabels[0]) + " 等 " + _vm._s(_vm.userTreeSelectedLabels.length) + " 人 ")])] : [_vm.visible ? _c("div", [_c("a-checkbox-group", {
    attrs: {
      "default-value": _vm.getDefaultCheck(_vm.editForm.empType),
      options: _vm.handleP()
    },
    on: {
      change: _vm.checkBoxOnChange
    }
  }), _vm.visible && _vm.getShowSelect(_vm.editForm.empType) ? _c("a-button", {
    on: {
      click: _vm.onEmp
    }
  }, [_vm._v("选择人员和渠道")]) : _vm._e()], 1) : _vm._e(), _vm.getShowSelect(_vm.editForm.empType) ? _c("div", [_vm._v(" 已选择 "), _vm.visible && _vm.editForm.userVOList && _vm.editForm.userVOList.length !== 0 ? _c("span", [_vm._v(_vm._s(_vm._f("empInfoVOLists")(_vm.editForm.userVOList)))]) : _vm._e(), _c("span", [_vm._v(_vm._s(_vm._f("empInfoVOList")(_vm.editForm.userVOList)))]), _vm._v(" 人 ")]) : _vm._e()], _c("p", {
    staticClass: "editFont14"
  }, [_vm._v("接收方式")]), _c("a-checkbox-group", {
    attrs: {
      name: "checkboxgroup",
      options: _vm.plainOptions
    },
    model: {
      value: _vm.editForm.receiveType,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm, "receiveType", $$v);
      },
      expression: "editForm.receiveType"
    }
  }), _c("p", {
    staticClass: "editFont14"
  }, [_vm._v("接收时间")]), _c("a-radio-group", {
    attrs: {
      name: "radioGroup"
    },
    on: {
      change: _vm.changeReceiveDateType
    },
    model: {
      value: _vm.editForm.receiveDateType,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm, "receiveDateType", $$v);
      },
      expression: "editForm.receiveDateType"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" " + _vm._s(_vm.editForm.noticeTypeCode === "retire" || _vm.editForm.noticeTypeCode === "contractExpires" || _vm.editForm.noticeTypeCode === "jfContract" ? "当天" : "实时") + " ")]), _c("a-radio", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getIsShowAdvance(_vm.editForm.noticeTypeCode),
      expression: "getIsShowAdvance(editForm.noticeTypeCode)"
    }],
    attrs: {
      value: 2
    }
  }, [_vm._v("提前提醒")]), _c("a-radio", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getIsShowFixed(_vm.editForm.noticeTypeCode),
      expression: "getIsShowFixed(editForm.noticeTypeCode)"
    }],
    attrs: {
      value: 3
    }
  }, [_vm._v("固定时间")])], 1), _vm.editForm.receiveDateType === 1 && (_vm.editForm.noticeTypeCode === "retire" || _vm.editForm.noticeTypeCode === "contractExpires" || _vm.editForm.noticeTypeCode === "jfContract") ? _c("div", {
    staticClass: "mt16"
  }, [_vm.editForm.noticeTypeCode !== "retire" && _vm.editForm.noticeTypeCode !== "contractExpires" && _vm.editForm.noticeTypeCode !== "jfContract" ? _c("span", [_vm._v("提前  ")]) : _vm._e(), _c("a-time-picker", {
    attrs: {
      "default-open-value": _vm.moment("00:00", "HH:mm"),
      format: "HH:mm"
    },
    on: {
      change: _vm.onChangeTime
    },
    model: {
      value: _vm.appointTime,
      callback: function callback($$v) {
        _vm.appointTime = $$v;
      },
      expression: "appointTime"
    }
  }), _c("span", [_vm._v("   消息提醒")])], 1) : _vm._e(), _vm.editForm.receiveDateType === 2 && _vm.getIsShowAdvance(_vm.editForm.noticeTypeCode) ? _c("div", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("span", [_vm._v("提前  ")]), _c("div", {
    staticStyle: {
      width: "100px",
      display: "inline-block",
      "margin-right": "10px"
    }
  }, [_c("a-input-number", {
    attrs: {
      placeholder: "请选择",
      type: "number",
      min: 1,
      max: _vm.editForm.receiveDateJson.unit === 1 ? 60 : _vm.editForm.receiveDateJson.unit === 2 ? 24 : _vm.editForm.noticeTypeCode === "contractExpires" || _vm.editForm.noticeTypeCode === "jfContract" ? 90 : 365
    },
    model: {
      value: _vm.editForm.receiveDateJson.number,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm.receiveDateJson, "number", $$v);
      },
      expression: "editForm.receiveDateJson.number"
    }
  })], 1), _c("span", [_vm._v("  ")]), _c("a-select", {
    staticStyle: {
      "margin-right": "10px",
      width: "120px"
    },
    model: {
      value: _vm.editForm.receiveDateJson.unit,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm.receiveDateJson, "unit", $$v);
      },
      expression: "editForm.receiveDateJson.unit"
    }
  }, _vm._l(_vm.unitList, function (i) {
    return _c("a-select-option", {
      key: i.id,
      attrs: {
        value: i.id
      }
    }, [_vm._v(_vm._s(i.name))]);
  }), 1), _vm.empCode === "retire" || _vm.empCode === "contractExpires" || _vm.empCode === "jfContract" ? _c("a-time-picker", {
    attrs: {
      "default-open-value": _vm.moment("00:00", "HH:mm"),
      format: "HH:mm"
    },
    on: {
      change: _vm.onChangeTime
    },
    model: {
      value: _vm.appointTime,
      callback: function callback($$v) {
        _vm.appointTime = $$v;
      },
      expression: "appointTime"
    }
  }) : _vm._e(), _c("span", [_vm._v("   消息提醒")])], 1) : _vm._e(), _vm.editForm.receiveDateType === 3 && _vm.getIsShowAdvance(_vm.editForm.noticeTypeCode) ? _c("div", {
    staticClass: "mt16"
  }, [_c("span", [_vm._v("每月   ")]), _c("a-select", {
    staticStyle: {
      width: "120px",
      "margin-right": "10px"
    },
    attrs: {
      "default-value": 1
    },
    model: {
      value: _vm.editForm.receiveDateJson.day,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm.receiveDateJson, "day", $$v);
      },
      expression: "editForm.receiveDateJson.day"
    }
  }, [_c("span", [_vm._v("  ")]), _vm._l(31, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: index + 1
      }
    }, [_vm._v(_vm._s(index + 1))]);
  })], 2), _vm.editForm.noticeTypeCode === "contractExpires" || _vm.editForm.noticeTypeCode === "jfContract" ? _c("span", [_vm._v("号    ")]) : _vm._e(), _vm.editForm.noticeTypeCode === "contractExpires" || _vm.editForm.noticeTypeCode === "jfContract" ? _c("a-time-picker", {
    attrs: {
      value: _vm.editForm.receiveDateJson.time ? _vm.moment(_vm.editForm.receiveDateJson.time, "HH:mm") : "",
      format: "HH:mm"
    },
    on: {
      change: _vm.onChange
    }
  }) : _c("a-time-picker", {
    attrs: {
      value: _vm.editForm.receiveDateJson.time ? _vm.moment(_vm.editForm.receiveDateJson.time, "HH:mm:ss") : ""
    },
    on: {
      change: _vm.onChange
    }
  }), _c("span", [_vm._v("      消息提醒")])], 1) : _vm._e()], 2), _c("div", {
    style: {
      position: "absolute",
      right: 0,
      bottom: 0,
      width: "100%",
      borderTop: "1px solid #e9e9e9",
      padding: "10px 16px",
      background: "#fff",
      textAlign: "center",
      zIndex: 1
    }
  }, [_c("a-button", {
    style: {
      marginRight: "8px"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v("取 消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onEdit
    }
  }, [_vm._v("确 认")])], 1)]), _c("employee-select", {
    attrs: {
      show: _vm.visibleEmp,
      "default-selected-keys": _vm.defaultSelectedKeys
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.visibleEmp = $event;
      },
      change: _vm.onEmployeeSelectChange
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };