var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      title: "".concat(_vm.moment(this.jsonData.timeDay).format("YYYY/MM/DD"), "\u6253\u5361\u8BB0\u5F55"),
      width: "1000px",
      footer: null
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 3
    }
  }, [this.jsonData.empPhoto !== null ? _c("a-avatar", {
    key: this.jsonData.empPhoto,
    attrs: {
      src: this.jsonData.empPhoto,
      size: 64
    }
  }) : _c("a-avatar", {
    attrs: {
      icon: "user",
      size: 64
    }
  })], 1), _c("a-col", {
    staticClass: "info",
    attrs: {
      span: 20
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "500",
      "padding-bottom": "5px"
    }
  }, [_vm._v(" " + _vm._s(this.jsonData.empInfoName) + " ")]), _c("div", [_c("span", [_vm._v("部门：" + _vm._s(this.jsonData.deptName))]), _c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }), _c("span", [_vm._v("所属规则：" + _vm._s(this.jsonData.attendPlanName))])], 1)])], 1), _c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "punchTime" || col.dataIndex === "arriveTwo.arriveTime" ? _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(text ? _vm.moment(text).format("YYYY-MM-DD HH:mm") : "--") + " ")]) : col.dataIndex === "punchType" || col.dataIndex === "arriveTwo.arriveTime" ? _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(_vm.type[text - 1]) + " ")]) : col.dataIndex === "punchStatus" || col.dataIndex === "arriveTwo.arriveTime" ? _c("div", {
            key: index
          }, [_c("div", [_vm._v(_vm._s(_vm.status[text]))]), record.isCalibration ? _c("a-tag", {
            attrs: {
              color: "blue"
            }
          }, [_vm._v(" 管理员校准 ")]) : _vm._e()], 1) : col.dataIndex === "punchSource" || col.dataIndex === "arriveTwo.arriveTime" ? _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(_vm.equipStatus[text - 1]) + " ")]) : col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("a", {
            class: _vm.isNow ? "noClickStatus" : "clickStatus",
            on: {
              click: function click($event) {
                return _vm.enterFix(record);
              }
            }
          }, [_vm._v("校准")])]) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  })], 1)], 1)], 1), _c("fix", {
    attrs: {
      visible: _vm.fixShow,
      jsonData: _vm.fixJsonData
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.fixShow = $event;
      },
      onUpdate: _vm.onUpdate
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };