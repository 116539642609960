import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ql-editor-wrapper",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("div", {
    staticClass: "ql-editor-header"
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  }), _c("div", {
    staticClass: "ql-editor-header-title"
  }, [_vm._v("设置印章位置")])], 1), _c("div", {
    staticClass: "ql-editor-content ql-editor-content2"
  }, [_c("div", {
    staticClass: "editor-main editor-main2"
  }, [_c("div", {
    staticClass: "sealnew-name",
    style: {
      width: "".concat(_vm.a4_width, "px")
    }
  }, [_c("div", {
    staticClass: "left",
    staticStyle: {
      flex: "1"
    }
  }, [_vm._v(_vm._s(_vm.name) + ".pdf")]), _c("div", {
    staticStyle: {
      width: "160px",
      "flex-shrink": "0",
      "text-align": "right"
    }
  }, [_c("a-button", {
    staticStyle: {
      width: "32px",
      padding: "0",
      "margin-right": "10px"
    },
    on: {
      click: _vm.pageUp
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.currentPage) + "/" + _vm._s(_vm.pageCount) + " "), _c("a-button", {
    staticStyle: {
      width: "32px",
      padding: "0",
      "margin-left": "10px"
    },
    on: {
      click: _vm.pageDown
    }
  }, [_c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1)], 1)]), _c("div", {
    style: {
      width: "".concat(_vm.a4_width, "px"),
      position: "relative",
      margin: "0 auto",
      boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.1)"
    }
  }, [_vm.src ? _c("pdf", {
    ref: "pdf",
    attrs: {
      src: _vm.src,
      page: _vm.currentPage
    },
    on: {
      "num-pages": function numPages($event) {
        _vm.pageCount = $event;
      },
      "page-loaded": function pageLoaded($event) {
        _vm.currentPage = $event;
      },
      error: _vm.pdfError
    }
  }) : _vm._e(), _vm._l(_vm.dropList, function (item) {
    return [item.page === _vm.currentPage ? _c("vue-draggable-resizable", {
      key: item.id,
      ref: "".concat(item.type).concat(item.id),
      refInFor: true,
      attrs: {
        parent: true,
        resizable: false,
        w: _vm.seal_size,
        h: item.type === "seal" ? _vm.seal_size : item.type === "sign" ? 50 : 100,
        x: item.x,
        y: item.y,
        "class-name": "editor-main-drag"
      },
      on: {
        dragstop: function dragstop($event) {
          return _vm.onDrag(arguments, item.id);
        }
      }
    }, [_c("div", {
      staticClass: "editor-main-drag-close"
    }, [_c("a-popconfirm", {
      attrs: {
        title: "确认要删除合同内的这个印章吗？",
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: {
        confirm: function confirm($event) {
          return _vm.remove(item.id);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "close-circle"
      }
    })], 1)], 1), item.type === "seal" ? _c("div", [_c("img", {
      attrs: {
        src: require("@/assets/img/seal-circle.png"),
        alt: ""
      }
    })]) : _vm._e(), item.type === "sign" ? _c("div", [_c("div", {
      staticClass: "editor-main-drag-sign"
    }, [_vm._v("电子签名")])]) : _vm._e(), item.type === "holder" ? _c("div", [_c("div", {
      staticClass: "editor-main-drag-sign lp"
    }, [_vm._v("法人章")])]) : _vm._e()]) : _vm._e()];
  })], 2)]), _c("div", {
    staticClass: "editor-config editor-config2"
  }, [_c("div", {
    staticClass: "editor-config-block",
    staticStyle: {
      border: "0"
    }
  }, [_c("div", {
    staticClass: "editor-config-block-content",
    staticStyle: {
      "margin-top": "40px"
    }
  }, [_c("div", {
    staticClass: "fs14 fw400",
    staticStyle: {
      color: "#252525"
    }
  }, [_vm._v("将印章拖拽至签署位置")]), _vm._l(_vm.dragList, function (item) {
    return _c("div", {
      key: item
    }, [item === "seal" ? _c("div", {
      staticClass: "editor-tit"
    }, [_vm._v(" 已添加 "), _c("span", [_vm._v(_vm._s(_vm.seal.length))]), _vm._v(" 处电子印章 ")]) : _vm._e(), item === "sign" ? _c("div", {
      staticClass: "editor-tit"
    }, [_vm._v(" 已添加 "), _c("span", [_vm._v(_vm._s(_vm.sign.length))]), _vm._v(" 处手写签名 ")]) : _vm._e(), item === "holder" ? _c("div", {
      staticClass: "editor-tit"
    }, [_vm._v(" 已添加 "), _c("span", [_vm._v(_vm._s(_vm.holder.length))]), _vm._v(" 处手法人章 ")]) : _vm._e(), _c("div", {
      staticClass: "editor-config-block-content-drag",
      staticStyle: {
        "margin-top": "12px"
      },
      on: {
        click: function click($event) {
          return _vm.add(item);
        }
      }
    }, [item === "seal" ? _c("img", {
      attrs: {
        src: require("@/assets/img/seal-circle.png"),
        alt: ""
      }
    }) : _vm._e(), item === "seal_date" ? _c("div", {
      staticClass: "editor-config-block-content-sign"
    }, [_vm._v("企业签署日期")]) : _vm._e(), item === "sign" ? _c("div", {
      staticClass: "editor-config-block-content-sign"
    }, [_vm._v("手写签名")]) : _vm._e(), item === "sign_date" ? _c("div", {
      staticClass: "editor-config-block-content-sign"
    }, [_vm._v("个人签署日期")]) : _vm._e(), item === "holder" ? _c("div", {
      staticClass: "editor-config-block-content-sign"
    }, [_vm._v("法人章")]) : _vm._e()]), item === "seal" ? _c("div", {
      staticClass: "editor-tags"
    }, _vm._l(_vm.sealJson, function (item, index) {
      return _c("div", {
        key: index,
        staticClass: "editor-tags-item",
        on: {
          click: function click($event) {
            return _vm.jump(index);
          }
        }
      }, [_vm._v(" 第" + _vm._s(index) + "页(" + _vm._s(item) + ") ")]);
    }), 0) : _vm._e(), item === "sign" ? _c("div", {
      staticClass: "editor-tags"
    }, _vm._l(_vm.signJson, function (item, index) {
      return _c("div", {
        key: index,
        staticClass: "editor-tags-item",
        on: {
          click: function click($event) {
            return _vm.jump(index);
          }
        }
      }, [_vm._v(" 第" + _vm._s(index) + "页(" + _vm._s(item) + ") ")]);
    }), 0) : _vm._e(), item === "holder" ? _c("div", {
      staticClass: "editor-tags"
    }, _vm._l(_vm.holderJson, function (item, index) {
      return _c("div", {
        key: index,
        staticClass: "editor-tags-item",
        on: {
          click: function click($event) {
            return _vm.jump(index);
          }
        }
      }, [_vm._v(" 第" + _vm._s(index) + "页(" + _vm._s(item) + ") ")]);
    }), 0) : _vm._e()]);
  })], 2)])])]), _c("div", {
    staticClass: "ql-editor-footer ql-editor-footer2"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保存")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };