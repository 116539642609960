import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "payslip page-miH page-bgBack"
  }, [_c("div", {
    staticStyle: {
      "padding-bottom": "24px"
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "middle"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "120px"
    },
    on: {
      change: _vm.handleChangeYear
    },
    model: {
      value: _vm.params.year,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "year", $$v);
      },
      expression: "params.year"
    }
  }, _vm._l(_vm.yearList, function (item, i) {
    return _c("a-select-option", {
      key: i,
      attrs: {
        value: item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1), !_vm.showBg ? _c("a-button", {
    attrs: {
      icon: "plus",
      type: "primary"
    },
    on: {
      click: _vm.addPayslipModal
    }
  }, [_vm._v(" 添加工资条 ")]) : _vm._e()], 1)], 1), _vm.showBg ? [_c("div", {
    staticClass: "no-payslip"
  }, [_c("img", {
    staticClass: "autoimg",
    attrs: {
      src: require("../../../assets/img/payslip/beijing.png")
    }
  }), _c("div", {
    staticClass: "cent"
  }, [_c("h2", [_vm._v("电子工资条 全新来袭")]), _c("h3", [_vm._v(" 不使用薪酬管理，直接上传您的工资表，发送微信、短信电子工资条,并实时掌握员工的反馈情况 ")]), _c("a-button", {
    staticClass: "mg_t20",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addPayslip
    }
  }, [_vm._v(" 马上开通添加工资表 "), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1)], 1)])] : _vm._e(), !_vm.showBg ? _vm._l(_vm.paySlipList, function (item, i) {
    return _c("div", {
      key: i,
      staticClass: "content"
    }, [_c("a-row", {
      staticClass: "title"
    }, [_c("strong", [_vm._v(_vm._s("".concat(_vm.params.year, "\u5E74").concat(item.title, "\u6708")))])]), _vm._l(item.list, function (ite, j) {
      return _c("a-row", {
        key: j,
        staticClass: "content-con",
        attrs: {
          type: "flex",
          align: "middle"
        }
      }, [_c("a-col", {
        attrs: {
          span: 3
        }
      }, [_c("strong", [_vm._v(_vm._s(ite.tableName))])]), _c("a-divider", {
        staticStyle: {
          height: "66px"
        },
        attrs: {
          type: "vertical"
        }
      }), _c("a-col", {
        attrs: {
          span: 3
        }
      }, [_c("a-row", {
        staticClass: "sub_tit mg_b12"
      }, [_vm._v("计薪人数")]), _c("strong", [_vm._v(_vm._s(ite.empCount) + "人")])], 1), _c("a-col", {
        attrs: {
          span: 4
        }
      }, [_c("a-row", {
        staticClass: "sub_tit mg_b12"
      }, [_vm._v("应发工资")]), _c("strong", [_vm._v(_vm._s(ite.salaryAmount))])], 1), _c("a-divider", {
        staticStyle: {
          height: "66px"
        },
        attrs: {
          type: "vertical"
        }
      }), _c("a-col", {
        attrs: {
          span: 4
        }
      }, [_c("a-row", {
        staticClass: "sub_tit mg_b12"
      }, [_vm._v("已发放")]), _c("strong", [_vm._v(_vm._s(ite.sendCount) + "人")])], 1), _c("a-col", {
        attrs: {
          span: 3
        }
      }, [_c("a-row", {
        staticClass: "sub_tit mg_b12"
      }, [_vm._v("问题反馈")]), _c("strong", [_vm._v(_vm._s(ite.feedbackCount) + "人")])], 1), _c("a-divider", {
        staticStyle: {
          height: "66px"
        },
        attrs: {
          type: "vertical"
        }
      }), _c("a-col", {
        staticClass: "full"
      }, [_c("a-row", {
        attrs: {
          type: "flex",
          justify: "center",
          align: "middle"
        }
      }, [_c("a-col", {
        staticClass: "primary",
        attrs: {
          span: 8
        },
        on: {
          click: function click($event) {
            return _vm.goDetail(item, ite);
          }
        }
      }, [_vm._v(" 发放详情 ")]), _c("a-col", {
        staticClass: "primary",
        attrs: {
          span: 8
        }
      }, [_c("div", [_c("a-popconfirm", {
        attrs: {
          title: "确定删除这条工资条吗?",
          "ok-text": "确定",
          "cancel-text": "取消"
        },
        on: {
          confirm: function confirm($event) {
            return _vm.deletePayslip(ite);
          }
        }
      }, [_vm._v(" 删除工资条 ")])], 1)]), _c("a-col", {
        staticClass: "primary",
        attrs: {
          span: 8
        },
        on: {
          click: function click($event) {
            return _vm.download(item, ite);
          }
        }
      }, [_vm._v(" 导出 ")])], 1)], 1)], 1);
    })], 2);
  }) : _vm._e(), _c("addPayslipModal", {
    attrs: {
      visible: _vm.apmVisible,
      options: _vm.apmOptions
    },
    on: {
      change: _vm.handleChange
    }
  })], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };