import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
var columns = [{
  title: "",
  dataIndex: "window",
  scopedSlots: {
    customRender: "window"
  }
}, {
  title: "姓名",
  dataIndex: "name"
}, {
  title: "参保城市",
  dataIndex: "socCityName"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200
}, {
  title: "参保方案",
  dataIndex: "socProgName",
  scopedSlots: {
    customRender: "socProgName"
  }
}, {
  title: "社保起缴时间",
  dataIndex: "socStartDate",
  scopedSlots: {
    customRender: "socStartDate"
  }
}, {
  title: "住房公积金起缴时间",
  dataIndex: "fundStartDate",
  scopedSlots: {
    customRender: "fundStartDate"
  }
}, {
  title: "指派公司",
  dataIndex: "assignCompanyName"
}, {
  title: "状态",
  dataIndex: "status",
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "操作",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  }
}];
var innerColumns = [{
  title: "缴纳项目",
  dataIndex: "itemName",
  align: "center",
  ellipsis: true
}, {
  title: "缴费基数",
  dataIndex: "cardinalityPerson",
  align: "center",
  ellipsis: true
}, {
  title: "个人比列",
  dataIndex: "persentPersonal",
  scopedSlots: {
    customRender: "persentPersonal"
  },
  align: "center",
  ellipsis: true
}, {
  title: "公司比例",
  dataIndex: "persentCompany",
  scopedSlots: {
    customRender: "persentCompany"
  },
  align: "center",
  ellipsis: true
}, {
  title: "个人缴费",
  dataIndex: "amountPersonal",
  align: "center",
  ellipsis: true
}, {
  title: "公司交费",
  dataIndex: "amountCompany",
  align: "center",
  ellipsis: true
}, {
  title: "缴费合计",
  dataIndex: "amountAll",
  scopedSlots: {
    customRender: "all"
  },
  align: "center",
  ellipsis: true
}];
var innerData = [];
export default {
  name: "TableDetail",
  props: {
    options: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      innerColumns: innerColumns,
      innerData: innerData,
      columns: columns,
      dataSource: [],
      pagination: {
        pageSize: 10,
        total: 0
      },
      progList: []
    };
  },
  watch: {
    options: function options(val) {
      this.pagination.total = val.entityCount;
      this.dataSource = val.entities;
    }
  },
  created: function created() {
    this.getcbList();
  },
  methods: {
    // 获取参保方案
    getcbList: function getcbList() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/socProgs/listAllRelation"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.progList = data;
      });
    },
    hasPaidan: function hasPaidan(name) {
      return this.progList.find(function (item) {
        return item.progName === name;
      });
    },
    handelEvent: function handelEvent(e) {
      this.$emit("change", {
        visible: true,
        e: e
      });
    },
    handlePage: function handlePage(_ref2) {
      var current = _ref2.current;
      this.$emit("change", {
        current: current
      });
    },
    openDetail: function openDetail(e) {}
  }
};