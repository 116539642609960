import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import AuthRouter from "./modules/auth";
import ConsoleRouter from "./modules/console";
import AdminRouter from "./modules/admin";
import { StaffRouter, StaffCRouter } from "./modules/staff";
import { SettlementRouter, SettlementAdminRouter } from "./modules/settlement";
import ContractAdminRouter from "./modules/contractAdmin";
import OverviewRouter from "./modules/overview";
import WagesRouter from "./modules/wages";
import ShebaoRouter from "./modules/shebao";
import RecruitmentRouter from "./modules/recruitment";
import CheckRouter from "./modules/check";
import ClienteleRouter from "./modules/clientele";
import ContractRouter from "./modules/contract";
import PaySalaryRouter from "./modules/paySalary";
import ShangyebaoxianRouter from "./modules/shangyebaoxian";
import TaxCalculateRouter from "./modules/taxCalculate";
import ShangbaoRouter from "./modules/shangbao";
export default [AuthRouter, ConsoleRouter, AdminRouter, StaffRouter, StaffCRouter, SettlementRouter, SettlementAdminRouter, ContractAdminRouter, OverviewRouter, WagesRouter, ShebaoRouter, RecruitmentRouter, CheckRouter, ClienteleRouter, ContractRouter, PaySalaryRouter, TaxCalculateRouter, ShangyebaoxianRouter, ShangbaoRouter, {
  path: "/adminThirdService",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/adminNew"));
    });
  },
  redirect: "/adminThirdService/thirdService",
  children: [{
    path: "/adminThirdService/thirdService",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/thirdService"));
      });
    }
  }]
}, {
  path: "/thirdService",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  children: [{
    path: "/thirdService",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/thirdService"));
      });
    }
  }, {
    path: "/thirdService/eContract",
    // 电子合同
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/thirdService/eContract"));
      });
    }
  }, {
    path: "/thirdService/smsTemplate",
    // 短信发送
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/thirdService/smsTemplate"));
      });
    }
  }, {
    path: "/thirdService/smsTemplate/config",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/thirdService/smsTemplate/config"));
      });
    }
  }, {
    path: "/thirdService/smsTemplate/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/thirdService/smsTemplate/add"));
      });
    }
  }]
}, {
  path: "/message",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  children: [{
    path: "info",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-message/views/index"));
      });
    }
  }, {
    path: "setting",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/messages/settings/index.vue"));
      });
    }
  }, {
    path: "detail/:id",
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-message/views/detail"));
      });
    }
  }]
}, {
  // 劳务外包
  path: "/outsourcing",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/adminNew"));
    });
  },
  children: [
    // {
    //   path: "management",
    //   component: () => import("@/views/outsourcing/management")
    // },
    // {
    //   path: "management/prePersonnel",
    //   component: () => import("@/views/outsourcing/management/prePersonnel")
    // },
    // {
    //   path: "management/account",
    //   component: () => import("@/views/outsourcing/management/account")
    // },
    // {
    //   path: "settlementRules",
    //   component: () => import("@/views/outsourcing/settlementRules")
    // },
    // {
    //   path: "settlementRules/wagesRules",
    //   component: () => import("@/views/outsourcing/settlementRules/wagesRules")
    // },
    // {
    //   path: "settlementRules/laborRules",
    //   component: () => import("@/views/outsourcing/settlementRules/laborRules")
    // }
  ]
}, {
  path: "/notice",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/notice/notices"));
    });
  }
}, {
  path: "/tax",
  // 个税信息
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  children: [{
    path: "declare",
    // 专项附加扣除
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/tax/declare"));
      });
    }
  }, {
    path: "special",
    // 个税申报信息
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/tax/special"));
      });
    }
  }, {
    path: "special/importSpecial",
    // 导入专项附加扣除累计
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/tax/special/importSpecial"));
      });
    }
  }, {
    path: "incomeTax",
    // 个税累计
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/monthly/incomeTax"));
      });
    }
  }, {
    path: "importTax",
    // 个税累计
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/monthly/importTax"));
      });
    }
  }]
}, {
  path: "/organization",
  // 组织架构
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  children: [{
    path: "organization",
    // 组织架构
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/organization/index"));
      });
    }
  }, {
    path: "org",
    // 岗位
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/organization/position"));
      });
    }
  }]
}, {
  path: "/approval",
  // 业务审批
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  children: [{
    path: "setting",
    // 审批设置
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/approval/config"));
      });
    }
  }, {
    path: "list",
    // 审批列表
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/approval/list"));
      });
    }
  }, {
    path: "detail/:approvalId/:userApprovalId",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/approval/detail"));
      });
    }
  }]
}, {
  path: "/approvalAdmin",
  // 业务审批
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/adminNew"));
    });
  },
  children: [{
    path: "setting",
    // 审批设置
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/approval/config"));
      });
    }
  }, {
    path: "list",
    // 审批列表
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/approval/list"));
      });
    }
  }]
}, {
  path: "/newApproval",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/approval/editor/index.vue"));
    });
  }
}, {
  path: "/newApprovalAdmin",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/approval/editor/index.vue"));
    });
  }
}, {
  path: "/notice",
  // 通知公告
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  redirect: "notice/enterprise/index",
  children: [{
    path: "enterprise",
    // 企业公告
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notice/enterprise/index"));
      });
    }
  }, {
    path: "person",
    // 个人通知
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notice/person/index"));
      });
    }
  }, {
    path: "enterprise/createNotice",
    // 新建公告
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notice/enterprise/createNotice"));
      });
    }
  }, {
    path: "person/createNotice",
    // 新建通告
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notice/person/createNotice"));
      });
    }
  }, {
    path: "enterprise/detail",
    // 公告详情
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notice/enterprise/detail"));
      });
    }
  }, {
    path: "person/detail",
    // 通知详情
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notice/person/detail"));
      });
    }
  }]
}, {
  path: "/Notice",
  // 通知公告
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  redirect: "notice/enterprise/index",
  children: [{
    path: "enterprise",
    // 企业公告
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notice/enterprise/index"));
      });
    }
  }, {
    path: "enterprise/createNotice",
    // 新建公告
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notice/enterprise/createNotice"));
      });
    }
  }, {
    path: "person/createNotice",
    // 新建通告
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notice/person/createNotice"));
      });
    }
  }, {
    path: "enterprise/detail",
    // 公告详情
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notice/enterprise/detail"));
      });
    }
  }, {
    path: "person/detail",
    // 通知详情
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/notice/person/detail"));
      });
    }
  }]
}, {
  path: "/news",
  // 官网发布
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  redirect: "news/manage/index",
  children: [{
    path: "manage",
    // 官网新闻
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/news/manage/index"));
      });
    }
  }, {
    path: "news/createNew",
    // 编辑公告
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/news/manage/createNew"));
      });
    }
  }, {
    path: "news/detail",
    // 公告详情
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/news/manage/newDetail"));
      });
    }
  }, {
    path: "sign",
    // 考务报名
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/news/sign/index"));
      });
    }
  }]
}, {
  path: "/aNews",
  // 官网发布
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/adminNew"));
    });
  },
  redirect: "news/manage/index",
  children: [{
    path: "manage",
    // 官网新闻
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/news/manage/index"));
      });
    }
  }, {
    path: "news/createNew",
    // 编辑公告
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/news/manage/createNew"));
      });
    }
  }, {
    path: "news/detail",
    // 公告详情
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/news/manage/newDetail"));
      });
    }
  }, {
    path: "sign",
    // 考务报名
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/news/sign/index"));
      });
    }
  }]
}, {
  path: "/",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/index"));
    });
  }
}, {
  path: "/dashboard",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/dashboard/wrapper"));
    });
  }
}, {
  path: "/empty",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/empty"));
    });
  }
}, {
  path: "/formDesign",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/formDesign"));
    });
  }
}, {
  path: "/renlizhiku",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/renlizhiku"));
    });
  }
}, {
  path: "/daiyuchuli",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/valueAddedService"));
    });
  },
  children: [{
    path: "list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/daiyuchuli/list"));
      });
    }
  }, {
    path: "add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/daiyuchuli/edit"));
      });
    }
  }, {
    path: "upload",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/daiyuchuli/upload"));
      });
    }
  }, {
    path: ":id/edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/daiyuchuli/edit"));
      });
    }
  }, {
    path: "templates",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/daiyuchuli/templates/index"));
      });
    }
  }]
}, {
  path: "/daiyuchuli/templates/create",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/alone/daiyuchuli/templates/edit"));
    });
  }
}, {
  path: "/daiyuchuli/templates/:id/edit",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/alone/daiyuchuli/templates/edit"));
    });
  }
}, {
  path: "/gys_reg",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/admin/supplier/h5"));
    });
  }
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/404"));
    });
  }
}, {
  path: "*",
  redirect: "/404"
}];