import request from "@/utils/request";

// 分页查询公司
export function getCompanies(params) {
  return request({
    url: "/hr/api/v1/companies",
    method: "get",
    params: params
  });
}

// 查询所有通知分组
export function listAllGroup(params) {
  return request({
    url: "/hr/api/v1/notice/listAllByCompanyId",
    method: "GET",
    params: params
  });
}

// 添加公司
export function addCompanies(data) {
  return request({
    url: "/hr/api/v1/companies",
    method: "post",
    data: data
  });
}
// 修改公司
export function putCompanies(data) {
  return request({
    url: "/hr/api/v1/companies",
    method: "put",
    data: data
  });
}

// 删除公司
export function delCompanies(id) {
  return request({
    url: "/hr/api/v1/companies/".concat(id),
    method: "delete"
  });
}

// 公司公告
// 查询公司系统公告信息
export function getSysNotices(params) {
  return request({
    url: "/hr/api/v1/companySysNotices",
    method: "get",
    params: params
  });
}
// 查询公司系统公告信息
export function getPageEmpList(params) {
  return request({
    url: "/hr/api/v1/empInfos/pageEmpList",
    method: "get",
    params: params
  });
}
// 新增公司系统公告信息
export function addSysNotives(data) {
  return request({
    url: "/hr/api/v1/companySysNotices",
    method: "post",
    data: data
  });
}
// 修改公司系统公告信息
export function updateSysNotices(data) {
  return request({
    url: "/hr/api/v1/companySysNotices",
    method: "put",
    data: data
  });
}
// 批量删除公司系统公告信息，彻底删除
export function deleteSysNotices(data) {
  return request({
    url: "/hr/api/v1/companySysNotices",
    method: "delete",
    data: data
  });
}
// 删除单个公司系统公告信息，彻底删除
export function deleteSysNotice(companySysNoticeId) {
  return request({
    url: "/hr/api/v1/companySysNotices/".concat(companySysNoticeId),
    method: "delete"
  });
}
// 筛选符合条件的员工(公司公告选人员使用)
export function screenEmployees(data) {
  return request({
    url: "/hr/api/v1/companySysNotices",
    method: "post",
    data: data
  });
}

// 分页查询公司系统公告信息(管理员查看)
export function managerCheckNotice(companyId, params) {
  return request({
    url: "/hr/api/v1/companySysNotices/pageByCompany/".concat(companyId),
    method: "get",
    params: params
  });
}

// 批量删除公司系统公告信息，彻底删除
export function companySysNoticesDel(companySysNoticeId) {
  return request({
    url: "/hr/api/v1/companySysNotices/".concat(companySysNoticeId),
    method: "DELETE"
  });
}
// 批量删除公司系统公告信息，彻底删除
export function userNoticeIdSysNoticesDel(userNoticeId) {
  return request({
    url: "/hr/api/v1/empNotices/deleteUserNotice/".concat(userNoticeId),
    method: "DELETE"
  });
}
//
// 查看用户通知的详情(通知接收者)
export function getuserNoticeId(empNoticeId) {
  return request({
    url: "/hr/api/v1/empNotices/".concat(empNoticeId),
    method: "get"
  });
}
// 根据公司系统公告信息id查询公司系统公告信息详情
export function getNoticeById(companySysNoticeId) {
  return request({
    url: "/hr/api/v1/companySysNotices/".concat(companySysNoticeId),
    method: "get"
  });
}

// 置顶公告
export function topNotice(companySysNoticeId) {
  return request({
    url: "/hr/api/v1/companySysNotices/topNotice/".concat(companySysNoticeId),
    method: "put"
  });
}
// 取消置顶公告
export function cancelTop(companySysNoticeId) {
  return request({
    url: "/hr/api/v1/companySysNotices/upTopNotice/".concat(companySysNoticeId),
    method: "put"
  });
}

// 下线公告
export function offlineNotice(companySysNoticeId, data) {
  return request({
    url: "/hr/api/v1/companySysNotices/offlineNotice/".concat(companySysNoticeId),
    method: "put",
    data: {
      isOfflineNow: true
    }
  });
}

// 上线公告
export function onlineNotice(companySysNoticeId) {
  return request({
    url: "/hr/api/v1/companySysNotices/onlineNotice/".concat(companySysNoticeId),
    method: "put"
  });
}

// 查询用户通知,根据用户分组,每个用户只展示最新一条
export function empNoticesListAll(companyId, params) {
  return request({
    url: "/hr/api/v1/empNotices/listUserNoticeGroupByUser/".concat(companyId),
    method: "get",
    params: params
  });
}

// 查询用户通知,给某个人发送的所有通知
export function listNoticeCurrentUser(companyId, params) {
  return request({
    url: "/hr/api/v1/empNotices/listNoticeByUser/".concat(companyId),
    method: "get",
    params: params
  });
}
// 根据员工通知id查询员工通知详情
export function empNoticesDetail(empNoticeId) {
  return request({
    url: "/hr/api/v1/empNotices/".concat(empNoticeId),
    method: "get"
  });
}

// 新增员工通知
export function ListEmpNotices(params) {
  return request({
    url: "/hr/api/v1/empNotices",
    method: "get",
    params: params
  });
}
// 新增员工通知
export function addEmpNotices(data) {
  return request({
    url: "/hr/api/v1/empNotices",
    method: "post",
    data: data
  });
}
// 新增员工通知
export function EditEmpNotices(data) {
  return request({
    url: "/hr/api/v1/empNotices",
    method: "PUT",
    data: data
  });
}
// 批量删除用户接收的通知，彻底删除(通知接收者)
export function deleteUserNoticeBatch(data) {
  return request({
    url: "/hr/api/v1/empNotices/deleteUserNoticeBatch",
    method: "delete",
    data: data
  });
}

// 首页消息通知，分页
export function listAllNotices(params) {
  return request({
    url: "/notice/api/v1/userNotices",
    method: "get",
    params: params
  });
}

// 全部消息通知，不分页
export function listAllNoticesAll(params) {
  return request({
    url: "/notice/api/v1/userAllNotices/listAllNotices",
    method: "get",
    params: params
  });
}
export function loadNoReadNumber() {
  return request({
    url: "/notice/api/v1/userAllNotices/getNoReadNumber",
    method: "get"
  });
}

// 消息
export function loadUserMessages(params) {
  return request({
    url: "/notice/api/v1/userMsgs",
    method: "get",
    params: params
  });
}
// 通知
export function loadUserNotices(params) {
  return request({
    url: "/notice/api/v1/userNotices",
    method: "get",
    params: params
  });
}
// 系统公告
export function loadSysNotices(params) {
  return request({
    url: "/notice/api/v1/sysNotices/listByUser",
    method: "get",
    params: params
  });
}
// 将人员的全部未读系统公告全部置为已读
export function readAllSysNotice(params) {
  return request({
    url: "/notice/api/v1/userReadSysNotices/readAllSysNotice",
    method: "put",
    params: params
  });
}

// 将人员的全部未读通知全部置为已读
export function readAllNotice(params) {
  return request({
    url: "/notice/api/v1/userNotices/readAllNotice",
    method: "put",
    params: params
  });
}
// 已读
export function readItem(data) {
  return request({
    url: "/notice/api/v1/userNotices/batchReadUserNotice",
    method: "PUT",
    data: data
  });
}
// 查询社保网站登录信息
export function getPluginInfo() {
  return request({
    url: "/hr/api/v1/socCache",
    method: "get"
  });
}
// 获取快捷入口
export function getHead(params) {
  return request({
    url: "/admin/api/v1/headerFields/getHead",
    method: "get",
    params: params
  });
}
// 保存快捷入口
export function updateHead(data) {
  return request({
    url: "/admin/api/v1/headerFields/updateHead",
    method: "put",
    data: data
  });
}
export function getSelectDepTree(params) {
  return request({
    url: "/hr/api/v1/depInfos/selectDepTree",
    method: "get",
    params: params
  });
}