import "core-js/modules/es.array.push.js";
import { Wages, WagesSet } from "@/api/wages";
export default {
  inject: ["reload"],
  name: "SalarySet",
  data: function data() {
    return {
      /* 计薪设置 */
      salary: {
        companyId: this.$store.state.hr.companyId,
        attendanceModuleItem: 1,
        attendanceDataSource: 1 /* 	考勤数据来源（1：考勤模块，2：自主上传） */,
        id: "",
        leaveDownsizing: 1 /* 离职员工减员（1：自动减员，2：员工离职时选择减员月） */,
        payDate: 21 /* 发薪日期 */,
        payMonth: 1 /* 发薪月份（1：当月，2：次月） */,
        socDataSource: 1 /* 社保数据来源（1：社保模块，2：不计算社保） */,
        socMonth: 2 /* 对应社保自然月（1：上月，2：当月，3：次月） */
      }
    };
  },
  created: function created() {
    /* 获取计薪设置 */
    this.getSatary();
  },
  watch: {
    salary: {
      handler: function handler(val) {},
      deep: true
    }
  },
  computed: {
    day: function day() {
      var box = [];
      var i = 1;
      while (i < 32) {
        box.push({
          key: i,
          value: "".concat(i, "\u65E5")
        });
        i++;
      }
      return box;
    }
  },
  methods: {
    // 下拉选择
    handleChange: function handleChange(value) {},
    /* 获取计薪设置 */getSatary: function getSatary() {
      var _this = this;
      Wages({
        url: WagesSet.getSalary
      }).then(function (_ref) {
        var data = _ref.data;
        if (data) _this.salary = data;
      });
    },
    /* 修改或新增计薪设置  /* 保存 */changeSalary: function changeSalary() {
      var _this2 = this;
      this.salary.companyId = this.$store.state.hr.companyId;
      if (Boolean(this.salary.id)) {
        Wages({
          url: WagesSet.changeSalary,
          method: "PUT",
          data: this.salary
        }).then(function (data) {
          if (data.success) {
            _this2.$notification["success"]({
              message: "保存成功"
            });
          }
        });
      } else {
        Wages({
          url: WagesSet.changeSalary,
          method: "PUT",
          data: this.salary
        }).then(function (data) {
          if (data.success) {
            _this2.$notification["success"]({
              message: "修改成功"
            });
          }
        });
      }
    }
  }
};