import request from "@/utils/request";
export function getCrmCustomerImportDetails(params) {
  return request({
    url: "/hr/api/v1/crmCustomerImportDetails",
    method: "GET",
    params: params
  });
}
export function postImportCustomerInfo(data) {
  return request({
    url: "/hr/api/v1/crmCustomerImportRecords/importCustomerInfo",
    method: "POST",
    data: data
  });
}