import request from "@/utils/request";

// 分页查询
export function retrieveReq(params) {
  return request({
    url: "/hr/api/v1/userRegisterCensors",
    method: "GET",
    params: params
  });
}
export function updateReq(data) {
  return request({
    url: "/hr/api/v1/userRegisterCensors",
    method: "put",
    data: data
  });
}
export function unauditedCountReq() {
  return request({
    url: "/hr/api/v1/userRegisterCensors/unauditedCount?auditStatus=1",
    method: "GET"
  });
}