var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "fTitle-box"
  }, [_vm._v(" " + _vm._s(_vm.tit) + " "), _c("div", {
    staticClass: "fTitle-desc"
  }, [_vm._v(" " + _vm._s(_vm.desc) + " ")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };