import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
export default {
  name: "ExcelBack",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      visibleWrong: false,
      wrongList: []
    };
  },
  computed: {
    allList: function allList() {
      return this.options.importFailList.length + this.options.importSucList.length;
    },
    successList: function successList() {
      return this.options.importSucList.length;
    },
    faildList: function faildList() {
      return this.options.importFailList.length;
    },
    success: function success() {
      return !this.options.importFailList.length;
    }
  },
  methods: {
    // 确认
    handleOk: function handleOk(type) {
      if (type === 1) {
        this.visibleWrong = true;
        this.wrongList = this.options.importFailList;
        this.$emit("change", _objectSpread({
          visibleWrong: true
        }, this.options));
      } else {
        this.$emit("change", _objectSpread({
          visible: false
        }, this.options));
      }
    },
    // 导出错误模板
    cancel: function cancel() {
      this.$emit("change", _objectSpread({
        visible: false
      }, this.options));
    }
  }
};