export default {
  name: "ReportTree",
  props: ["data"],
  beforeDestroy: function beforeDestroy() {
    this.$eventBus.$off("reportTree");
  },
  methods: {
    chose: function chose(item, data) {
      if (!item.isDept) {
        item.isSelected = !item.isSelected;
        this.$eventBus.$emit("reportTree");
      }
    }
  }
};