import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";
export var loadApprovalGroupListReq = function loadApprovalGroupListReq(companyId) {
  var moduleType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "hr";
  return request({
    url: "/approval/api/v1/approFlowGroups/allGroupAndFlowByCompanySite/".concat(companyId, "/").concat(moduleType),
    method: "get"
  });
};
export var loadApprovalTypeListReq = function loadApprovalTypeListReq() {
  var moduleType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "hr";
  return request({
    url: "/approval/api/v1/approTypes/listAll",
    method: "get",
    params: {
      moduleType: moduleType
    }
  });
};
export var loadFormByTypeReq = function loadFormByTypeReq(flowType) {
  return request({
    url: "/approval/api/v1/approFlowForms/allByType/".concat(flowType),
    method: "get"
  });
};
export var getFlowDetailReq = function getFlowDetailReq(flowId) {
  return request({
    url: "/approval/api/v1/approFlows/allFolwProcess/".concat(flowId),
    method: "get"
  });
};
export var getApprovalDetail = function getApprovalDetail(flowId) {
  return request({
    url: "/approval/api/v1/approFlows/allFolwInfo/".concat(flowId, "?moduleType=hr"),
    method: "get"
  });
};
export var publishApproval = function publishApproval(data) {
  return request({
    url: "/hr/api/v1/approval/publishFlow",
    // `/approval/api/v1/approFlows/publishFlow`,
    method: "post",
    data: data
  });
};
export function approvalDetailReq(approvalId) {
  return request({
    url: "/hr/api/v1/hrApproProviders/userApproDetails/".concat(approvalId),
    method: "get"
  });
}
export function approvalHandlerAgreeReq(approvalId, data) {
  return request({
    url: "/approval/api/v1/userFlowApprovers/pass/".concat(approvalId),
    method: "put",
    data: data
  });
}
export function approvalHandlerRejectReq(approvalId, data) {
  return request({
    url: "/approval/api/v1/userFlowApprovers/reject/".concat(approvalId),
    method: "put",
    data: data
  });
}
export function selectByUserApproval(data) {
  return request({
    url: "/hr/api/v1/costBills/selectByUserApprovalId",
    method: "get",
    params: {
      userApprovalId: data
    }
  });
}