import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.splice.js";
import { isJson } from "@/utils";
import AddConditionDrawer from "./AddConditionDrawer";
export default {
  name: "Block",
  components: {
    AddConditionDrawer: AddConditionDrawer
  },
  props: ["show", "blockOptions", "formlist", "process"],
  data: function data() {
    return {
      defNumber: "",
      conditions: [],
      showCondition: false,
      conditionsOptions: [],
      copyConditions: [],
      canUseNum: 0,
      keys: ["phone"]
    };
  },
  computed: {
    // 条件
    connds: function connds() {
      return [{
        key: "不等于(不是)",
        value: "!="
      }, {
        key: "小于",
        value: "<"
      }, {
        key: "小于等于",
        value: "<="
      }, {
        key: "等于(是)",
        value: "=="
      }, {
        key: "大于",
        value: ">"
      }, {
        key: "大于等于",
        value: ">="
      }, {
        key: "包含，属于",
        value: "in"
      }, {
        key: "不包含，不属于",
        value: "notin"
      }];
    },
    // 下拉框
    selects: function selects() {
      var Array = this.formlist.filter(function (key) {
        return key.widgetType === "select";
      }).map(function (key) {
        return {
          key: key.code,
          value: key.props.options.options
        };
      });
      return Array;
    }
  },
  watch: {
    show: function show(val) {
      var _this = this;
      if (val) {
        var props = this.blockOptions.props;
        this.blockOptions.props = isJson(props) ? JSON.parse(props) : props;
        this.conditions = this.blockOptions.props.conditions || [];
        this.copyConditions = JSON.parse(JSON.stringify(this.blockOptions.props.conditions));
        this.canUseNum = this.formlist.filter(function (k) {
          return k.required && !_this.keys.includes(k.code);
        }).length - this.conditions.length;
      }
    }
  },
  methods: {
    // 确认条件
    onSubmit: function onSubmit() {
      var _this2 = this;
      this.conditions = this.conditions.map(function (item) {
        return {
          code: item.code,
          formCode: item.code,
          label: item.label,
          value: item.value,
          rune: item.rune,
          widgetType: item.widgetType
        };
      });
      var conditions = this.conditions.filter(function (key) {
        return key.value && key.rune;
      });
      var operationName;
      if (conditions.length) {
        operationName = conditions.map(function (key) {
          var isSelect = key.widgetType === "select";
          isSelect ? key.operationName = "".concat(key.label, "  ").concat(_this2.connds.find(function (k) {
            return k.value === key.rune;
          }).key, " ").concat(_this2.selects.find(function (k) {
            return k.key === key.code;
          }).value.find(function (v) {
            return v.value === key.value;
          })["label"]) : key.operationName = "".concat(key.label, " ").concat(_this2.connds.find(function (k) {
            return k.value === key.rune;
          }).key, " ").concat(key.value);
          if (key.widgetType === "time") {
            key.operationName = "".concat(key.label, " ").concat(_this2.connds.find(function (k) {
              return k.value === key.rune;
            }).key, " ").concat(key.value.toString().split(" ")[4]);
          }
          if (key.widgetType === "date" && key.value) {
            key.operationName = "".concat(key.label, " ").concat(_this2.connds.find(function (k) {
              return k.value === key.rune;
            }).key, " ").concat(key.value.format("YYYY-MM-DD"));
          }
          return key.operationName;
        });
      }
      this.blockOptions.operationName = operationName ? operationName.join(" 并且 ") : "请选择条件";
      this.blockOptions.props.conditions = conditions;
      this.blockOptions.props.conditions.map(function (k) {
        return k.formSetting = _this2.formlist;
      });
      this.$emit("update:show", false);
      this.$emit("change", this.blockOptions);
    },
    // 关闭弹窗
    onClose: function onClose() {
      this.blockOptions.props.conditions = this.copyConditions;
      this.$emit("update:show", false);
      this.$emit("change", this.blockOptions);
    },
    // 添加条件
    addContition: function addContition() {
      var _this3 = this;
      this.showCondition = true;
      var formlist = JSON.parse(JSON.stringify(this.formlist)).filter(function (k) {
        return k.required;
      });
      var code = this.conditions.map(function (key) {
        return key.code;
      });
      formlist.map(function (key) {
        var haCode = _this3.conditions.find(function (k) {
          return k.code === key.code;
        });
        key.checked = code.includes(key.code);
        key.rune = haCode ? haCode.rune : "";
        key.value = haCode ? haCode.value : undefined;
        return key;
      });
      this.conditionsOptions = formlist.filter(function (k) {
        return !_this3.keys.includes(k.code);
      });
    },
    changeCb: function changeCb(_ref) {
      var visible = _ref.visible,
        options = _ref.options;
      this.showCondition = visible;
      if (options) {
        this.conditions = options.filter(function (key) {
          return key.checked;
        });
        this.canUseNum = this.formlist.filter(function (k) {
          return k.required;
        }).length - this.conditions.length;
      }
    },
    // 删除 条件
    del: function del(item, i) {
      this.conditions.splice(i, 1);
    },
    handleChange: function handleChange(e, formcode) {},
    onChange: function onChange(e) {},
    onChangeInput: function onChangeInput(e) {}
  }
};