import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { mapState } from "vuex";
import { filterEmptyValue } from "@/utils/tools";
var columns = [{
  title: "姓名",
  dataIndex: "name",
  width: 100,
  ellipsis: true
}, {
  title: "手机号",
  dataIndex: "phone",
  width: 120,
  ellipsis: true
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 180,
  ellipsis: true
}, {
  title: "用工单位",
  dataIndex: "companyName",
  width: 150,
  ellipsis: true
}];
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      columns: columns,
      idCards: "",
      nameOrPhoneOrIdCard: "",
      companyId: undefined,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      },
      currentEmpLst: [],
      currentEmpLstKeys: [],
      currentEmpLstDict: {},
      modalVisible: false,
      selectedRowKeys: [],
      loading: false,
      selectedEmpLst: [],
      mode: "select" // input
    };
  },

  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    companyList: function companyList() {
      return this.hr.companyList;
    },
    companyLst: function companyLst() {
      return this.companyList.map(function (item) {
        return {
          value: item.id,
          label: item.name
        };
      });
    }
  }),
  watch: {
    value: {
      handler: function handler(newVal) {
        this.mode = "select";
        this.idCards = "";
        this.modalVisible = newVal;
      },
      immediate: true
    },
    modalVisible: function modalVisible() {
      this.$emit("input", this.modalVisible);
      this.$emit("change", this.modalVisible);
      if (this.modalVisible) {
        this.clear();
      }
    },
    nameOrPhoneOrIdCard: function nameOrPhoneOrIdCard() {
      this.loadEmpLst();
    },
    companyId: function companyId(newVal) {
      if (newVal) {
        this.loadEmpLst();
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    doReset: function doReset() {
      this.idCards = "";
    },
    doDistinguish: function doDistinguish() {
      var _this = this;
      if (!this.idCards) {
        return false;
      }
      this.$request({
        url: "/hr/api/v1/empInfos/selectSiteEmpStickupSelect",
        method: "post",
        data: this.idCards.replaceAll(" ", ",").replaceAll("\r\n", ",").replaceAll("\n", ",").replaceAll("\t", ",").split(",")
      }).then(function (res) {
        _this.selectedEmpLst = [].concat(_toConsumableArray(res.data.empInfoVOList), _toConsumableArray(_this.selectedEmpLst));
      }).catch(function (err) {
        console.log("empInfos/selectSiteEmpStickupSelect", err);
      });
    },
    clear: function clear() {
      this.nameOrPhoneOrIdCard = "";
      this.companyId = undefined;
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.currentEmpLst = [];
      this.currentEmpLstKeys = [];
      this.currentEmpLstDict = {};
      this.selectedRowKeys = [];
      this.selectedEmpLst = [];
    },
    doCancel: function doCancel() {
      this.$emit("cancel");
      this.modalVisible = false;
    },
    doConfirm: function doConfirm() {
      this.$emit("confirm", _toConsumableArray(this.selectedEmpLst));
      this.modalVisible = false;
    },
    delFromSelectedLst: function delFromSelectedLst(key) {
      this.updateSelectedEmpLst(null, key);
    },
    updateSelectedEmpLst: function updateSelectedEmpLst() {
      var keys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var delKey = arguments.length > 1 ? arguments[1] : undefined;
      var otherItems = [];
      var currentItems = [];
      var currentKeys = [];
      for (var i = 0; i < this.selectedEmpLst.length; i++) {
        var item = this.selectedEmpLst[i];
        if (this.currentEmpLstKeys.includes(item.id)) {
          if (keys) {
            if (keys.includes(item.id)) {
              currentItems.push(item);
              currentKeys.push(item.id);
            }
          } else {
            currentItems.push(item);
            currentKeys.push(item.id);
          }
        } else {
          otherItems.push(item);
        }
      }
      // 上面只是处理全部选中，有可能新选中的不在其中，需要补充进去
      if (keys) {
        if (keys.length > currentKeys.length) {
          var leftKeys = keys.filter(function (key) {
            return !currentKeys.includes(key);
          });
          for (var _i = 0; _i < leftKeys.length; _i++) {
            currentItems.push(this.currentEmpLstDict[leftKeys[_i]]);
          }
        }
        currentKeys = currentItems.map(function (item) {
          return item.id;
        });
      }
      if (delKey) {
        if (currentKeys.includes(delKey)) {
          currentKeys = currentKeys.filter(function (key) {
            return key !== delKey;
          });
          currentItems = currentItems.filter(function (item) {
            return delKey !== item.id;
          });
        } else {
          otherItems = otherItems.filter(function (item) {
            return delKey !== item.id;
          });
        }
      }
      this.selectedRowKeys = currentKeys;
      this.selectedEmpLst = [].concat(_toConsumableArray(currentItems), _toConsumableArray(otherItems));
    },
    onSelectChange: function onSelectChange(keys) {
      this.updateSelectedEmpLst(keys);
    },
    handleTableChange: function handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.loadEmpLst();
    },
    loadEmpLst: function loadEmpLst() {
      var _this2 = this;
      this.loading = true;
      this.$request({
        url: "/hr/api/v1/empInfos/selectSiteEmp",
        params: filterEmptyValue({
          nameOrPhoneOrIdCard: this.nameOrPhoneOrIdCard,
          companyId: this.companyId,
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        })
      }).then(function (res) {
        _this2.currentEmpLst = res.data.entities;
        var tempArr = [];
        var tempObj = {};
        for (var i = 0; i < _this2.currentEmpLst.length; i++) {
          var item = _this2.currentEmpLst[i];
          tempArr.push(item.id);
          tempObj[item.id] = item;
        }
        _this2.currentEmpLstKeys = tempArr;
        _this2.currentEmpLstDict = tempObj;
        _this2.updateSelectedEmpLst();
        _this2.pagination.total = res.data.entityCount;
        _this2.loading = false;
      }).catch(function (err) {
        console.log("empInfos/selectSiteEmp", err);
        _this2.loading = false;
      });
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  }
};