var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: _vm.type === "add" ? "新增权限" : "修改权限",
      "confirm-loading": _vm.confirmLoading,
      footer: null,
      destroyOnClose: true,
      width: 660
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "steps"
  }, [_c("a-steps", {
    attrs: {
      current: _vm.current
    }
  }, _vm._l(_vm.steps, function (item) {
    return _c("a-step", {
      key: item.title
    }, [_c("template", {
      slot: "title"
    }, [_c("span", {
      staticStyle: {
        "font-size": "14px",
        "font-weight": "400"
      }
    }, [_vm._v(_vm._s(item.title))])])], 2);
  }), 1)], 1), _c("selectPeople", {
    attrs: {
      visible: _vm.show === 0
    },
    on: {
      upDate: _vm.upDate
    }
  }), _c("selectPeople2", {
    attrs: {
      visible: _vm.show === 2,
      permissionsJson: _vm.permissionsJson,
      jurisdiction: _vm.jurisdiction,
      type: _vm.type
    },
    on: {
      upDate: _vm.upDate,
      upDate2: _vm.upDate2
    }
  }), _c("permissions", {
    attrs: {
      visible: _vm.show === 1,
      permissionsJson: _vm.permissionsJson,
      type: _vm.type
    },
    on: {
      upDate: _vm.upDate
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };