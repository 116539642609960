import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import { findFormGysReq, findFormGysDeleteReq, findFormGysUpdateReq, findFormGysCreateReq } from "./api";
var fieldTypeArr = [{
  k: 1,
  v: "下拉选择器"
}, {
  k: 2,
  v: "单行输入框"
}, {
  k: 3,
  v: "日期选择器"
}, {
  k: 4,
  v: "多行输入框"
}, {
  k: 5,
  v: "数字输入框"
}, {
  k: 6,
  v: "地区选择器"
}, {
  k: 7,
  v: "文件/图片上传"
}, {
  k: 8,
  v: "日期区间"
}, {
  k: 9,
  v: "地区选择+详细地址"
}, {
  k: 10,
  v: "手机号输入框"
}, {
  k: 11,
  v: "邮箱输入框"
}];
export default {
  data: function data() {
    return {
      visible: false,
      confirmLoading: false,
      type: "",
      fieldData: [],
      activeKey: [],
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      },
      form: {
        options: []
      },
      marking: null,
      moduleCode: "",
      moduleName: "",
      title: "",
      fieldTypeArr: fieldTypeArr
    };
  },
  created: function created() {
    this.type = this.$route.params.type;
    this.getListForPc();
  },
  methods: {
    fieldTypeChange: function fieldTypeChange(index) {
      if (index === 1) {
        this.form.options = [""];
      } else {
        this.form.options = null;
      }
    },
    edit: function edit(data) {
      this.form = JSON.parse(JSON.stringify(data));
      if (this.form.fieldType === 1) {
        this.form.options = this.form.options.split(",");
      }
      this.marking = 2;
      this.title = "编辑字段";
      this.visible = true;
    },
    checkbox: function checkbox() {},
    remove: function remove(index) {
      this.form.options.splice(index, 1);
    },
    addDomain: function addDomain() {
      this.form.options.push("");
    },
    addField: function addField(data) {
      this.dataSort = data.formGroupFieldList.length + 1;
      this.moduleCode = data.moduleCode;
      this.moduleName = data.moduleName;
      this.marking = 1;
      this.title = "添加字段";
      this.visible = true;
    },
    showModal: function showModal() {
      this.visible = true;
    },
    handleOk: function handleOk(e) {
      var _this = this;
      this.confirmLoading = true;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var _form = JSON.parse(JSON.stringify(_this.form));
          var flag = false;
          if (_this.form.fieldType === 1) {
            _form.options.forEach(function (item, index) {
              if (item === "") {
                flag = true;
              }
            });
          }
          if (_this.marking === 1) {
            if (flag) {
              _this.$notification["error"]({
                message: "error",
                description: "自定义选项不能为空，添加失败"
              });
              return;
            } else {
              if (_form.fieldType === 1) {
                _form.options = _form.options.join(",");
              }
              findFormGysCreateReq(_objectSpread({
                moduleCode: _this.moduleCode,
                moduleName: _this.moduleName,
                fieldSource: 2,
                sort: _this.dataSort,
                isOpen: true
              }, _form)).then(function (res) {
                _this.$refs.ruleForm.resetFields();
                _this.form = {
                  options: []
                };
                _this.visible = false;
                _this.getListForPc();
                _this.$message.success("添加成功!");
              }).catch(function (err) {
                console.log("debug log --> ", err);
              });
            }
          } else {
            if (_form.fieldType === 1) {
              _form.options = _form.options.join(",");
            }
            findFormGysUpdateReq(_form).then(function (res) {
              _this.$refs.ruleForm.resetFields();
              _this.form = {
                options: []
              };
              _this.visible = false;
              _this.getListForPc();
              _this.$message.success("修改成功!");
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          }
        } else {
          return false;
        }
      });
      this.confirmLoading = false;
    },
    handleCancel: function handleCancel(e) {
      this.$refs.ruleForm.resetFields();
      this.form = {
        options: []
      };
      this.visible = false;
    },
    remoteControl: function remoteControl(data, field) {
      var _this2 = this;
      findFormGysUpdateReq(_objectSpread(_objectSpread({}, data), {}, _defineProperty({}, "".concat(field), !!!data[field]))).then(function (res) {
        _this2.$message.success("修改成功!");
        _this2.getListForPc();
      }).catch(function (err) {
        _this2.$message.error("修改失败!");
        console.log("debug log --> ", err);
      });
    },
    // 删除字段
    confirmDelete: function confirmDelete(item) {
      var _this3 = this;
      findFormGysDeleteReq(item.id).then(function (res) {
        _this3.$message.success("\u786E\u5B9A\u5220\u9664\u5B57\u6BB5\u3010".concat(item.fieldName, "\u3011\u6210\u529F\uFF01"));
        _this3.getListForPc();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    getListForPc: function getListForPc() {
      var _this4 = this;
      findFormGysReq().then(function (res) {
        _this4.fieldData = res.data;
        res.data.forEach(function (element, index) {
          _this4.activeKey.push(String(index + 1));
        });
      }).catch(function (err) {
        _this4.$message.error("失败!");
        console.log("debug log --> ", err);
      });
    }
  }
};