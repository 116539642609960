import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import CardCount from "./components/cardCount";
import ChartCount from "./components/chartCount";
import Detail from "./components/detail";
import selectCompanyGroup from "@/components/SelectCompany";
import { mapGetters } from "vuex";
export default {
  components: {
    CardCount: CardCount,
    ChartCount: ChartCount,
    Detail: Detail,
    selectCompanyGroup: selectCompanyGroup
  },
  data: function data() {
    return {
      statusValShow: null,
      selectTreeId: "-1",
      siteId: undefined,
      siteName: undefined,
      treeSiteName: undefined
    };
  },
  computed: _objectSpread({}, mapGetters({
    SelectedId: "site/SelectedId",
    SelectedName: "site/SelectedName",
    SelectedAllIds: "site/SelectedAllIds"
  })),
  created: function created() {
    // 获取缓存的数据
    this.selectTreeId = this.SelectedId;
    this.siteName = this.SelectedName;
    this.siteId = this.SelectedAllIds;
  }
};