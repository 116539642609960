import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
export var empFieldsSource = [{
  fieldName: "姓名",
  sort: 1,
  fieldSource: 1,
  fieldType: 2,
  isOpen: true,
  isNecessary: true
}, {
  fieldName: "身份证号",
  sort: 2,
  fieldSource: 1,
  fieldType: 2,
  isOpen: true,
  isNecessary: true
}, {
  fieldName: "手机号",
  sort: 3,
  fieldSource: 2,
  fieldType: 2,
  isOpen: true,
  isNecessary: true
}, {
  fieldName: "性别",
  sort: 4,
  fieldSource: 2,
  fieldType: 1,
  isOpen: true,
  isNecessary: true,
  options: "男,女"
}, {
  fieldName: "工作地点",
  sort: 5,
  fieldSource: 2,
  fieldType: 2,
  isOpen: true,
  isNecessary: false
}];
export function generateFieldItem(params, oldItem) {
  return _objectSpread(_objectSpread({
    isNecessary: false,
    isOpen: true,
    isEdit: true,
    fieldSource: 2
  }, oldItem || {}), params);
}
export function formatTemplate(arr1, arr2, arr3, oldTemp) {}
export function formatProcess(pArr) {}