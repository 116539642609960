import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "box"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", [_c("span", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.dataSource.templateName))]), _vm.isDefault ? _c("span", {
    staticClass: "default"
  }, [_vm._v("默认")]) : _vm._e()]), _c("a-dropdown", [_c("a-icon", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      type: "ellipsis"
    }
  }), _c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_vm.permissions_dycl.includes("dycl:dycl.dyclTemplate:update") ? _c("a-menu-item", {
    key: "edit",
    on: {
      click: function click($event) {
        return _vm.goEdit(_vm.dataSource.id);
      }
    }
  }, [_vm._v("编辑")]) : _vm._e(), _vm.permissions_dycl.includes("dycl:dycl.dyclTemplate:copy") ? _c("a-menu-item", {
    key: "copy",
    on: {
      click: function click($event) {
        return _vm.copyItem(_vm.dataSource.id);
      }
    }
  }, [_vm._v("复制")]) : _vm._e(), !_vm.isDefault && _vm.permissions_dycl.includes("dycl:dycl.dyclTemplate:delete") ? _c("a-menu-item", {
    key: "del",
    on: {
      click: function click($event) {
        return _vm.delItem(_vm.dataSource.id);
      }
    }
  }, [_vm._v("删除")]) : _vm._e()], 1)], 1)], 1), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("编辑人：")]), _c("span", {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.dataSource.modifiedUserName))])]), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("编辑时间：")]), _c("span", {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.dataSource.gmtModified)))])]), _c("div", {
    staticClass: "item event"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("事件类型：")]), _c("span", {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.dataSource.eventType))])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };