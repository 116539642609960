import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "uploadStyle"
  }, [_c("a-button", {
    staticClass: "addImgSaveStyle",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addImgSave
    }
  }, [_vm._v("确定保存")]), _c("a-row", [_c("a-col", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.fileList.length < 9,
      expression: "fileList.length < 9"
    }],
    attrs: {
      xl: {
        span: 6
      },
      xxl: {
        span: 6
      },
      lg: {
        span: 8
      },
      sm: {
        span: 8
      },
      xs: {
        span: 8
      }
    }
  }, [_c("div", {
    staticStyle: {
      margin: "0 12px"
    }
  }, [_c("a-upload-dragger", {
    attrs: {
      name: "files",
      "list-type": "text",
      "file-list": _vm.fileList,
      multiple: _vm.multiple,
      action: "/file/api/v1/site/upload/inline",
      accept: ".doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .jpg, .jpeg, .png"
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_c("a-icon", {
    staticStyle: {
      fontsize: "42px",
      color: "#e8e8e8"
    },
    attrs: {
      type: "cloud-upload"
    }
  }), _c("p", {
    staticClass: "uploadtext"
  }, [_vm._v(" 将文件拖动到此处，或 "), _c("span", {
    staticStyle: {
      color: "#6a66f6"
    }
  }, [_vm._v("点击上传")])])], 1)], 1)]), _vm._l(_vm.fileList, function (i, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        xl: {
          span: 6
        },
        xxl: {
          span: 6
        },
        lg: {
          span: 8
        },
        sm: {
          span: 8
        },
        xs: {
          span: 8
        }
      }
    }, [_c("div", {
      staticClass: "listBox"
    }, [_c("div", {
      staticStyle: {
        width: "100%",
        height: "158px",
        background: "#fafafa",
        overflow: "hidden"
      }
    }, [_c("div", {
      staticClass: "shadowStyleDis"
    }, [i.response && !_vm.getSrc(i.response.data[0], ["rar", "zip"]) || !i.response && i.src && !_vm.getSrc(i.src, ["rar", "zip"]) ? _c("div", {
      staticClass: "shadowStyle"
    }, [_c("div", {
      on: {
        click: function click($event) {
          return _vm.handlePreview(i);
        }
      }
    }, [_c("div", {
      staticClass: "shadowStyleEye"
    }, [_c("a-icon", {
      attrs: {
        type: "eye"
      }
    })], 1), _c("div", {
      staticClass: "shadowStyleText"
    }, [_vm._v("预览")])])]) : _vm._e(), i.type === "img" ? _c("img", {
      staticStyle: {
        "max-height": "158px",
        "object-fit": "cover"
      },
      attrs: {
        width: "100%",
        src: "".concat(i.src, "?token=").concat(_vm.token)
      }
    }) : i.type.substring(0, 6) === "image/" && i.response && i.response.data.length !== 0 ? _c("img", {
      staticStyle: {
        "max-height": "158px",
        "object-fit": "cover"
      },
      attrs: {
        width: "100%",
        src: "".concat(i.response.data[0], "?token=").concat(_vm.token)
      }
    }) : _c("div", {
      staticClass: "widCss"
    }, [i.response && _vm.getSrc(i.response.data[0], ["doc", "docx"]) ? _c("img", {
      attrs: {
        width: "100%",
        src: require("./img/word.png")
      }
    }) : i.type === "file" && i.src && _vm.getSrc(i.src, ["doc", "docx"]) ? _c("img", {
      attrs: {
        width: "100%",
        src: require("./img/word.png")
      }
    }) : i.type.substring(0, 12) === "application/" && _vm.getType(i.type, [".sheet", "vnd.ms-excel"]) && i.response ? _c("img", {
      attrs: {
        width: "100%",
        src: require("./img/xls.png")
      }
    }) : i.type === "file" && i.src && _vm.getSrc(i.src, ["xls", "xlsx"]) ? _c("img", {
      attrs: {
        width: "100%",
        src: require("./img/xls.png")
      }
    }) : i.type.substring(0, 12) === "application/" && _vm.getType(i.type, ["pdf"]) && i.response ? _c("img", {
      attrs: {
        width: "100%",
        src: require("./img/pdf.png")
      }
    }) : i.type === "file" && i.src && _vm.getSrc(i.src, ["pdf"]) ? _c("img", {
      attrs: {
        width: "100%",
        src: require("./img/pdf.png")
      }
    }) : i.response && _vm.getType(i.type, ["", "zip", "x-zip-compressed"]) ? _c("img", {
      attrs: {
        width: "100%",
        src: require("./img/rar.png")
      }
    }) : i.type === "file" && i.src && _vm.getSrc(i.src, ["rar", "zip"]) ? _c("img", {
      attrs: {
        width: "100%",
        src: require("./img/rar.png")
      }
    }) : _vm._e()])])]), _c("div", {
      staticClass: "listBoxBootom"
    }, [_c("a-row", [_c("a-col", {
      staticClass: "ovte",
      attrs: {
        span: 14
      }
    }, [_vm._v(_vm._s(i.name))]), _c("a-col", {
      staticStyle: {
        "text-align": "right"
      },
      attrs: {
        span: 10
      }
    }, [_c("a-icon", {
      staticClass: "iconText",
      attrs: {
        type: "download"
      },
      on: {
        click: function click($event) {
          return _vm.onDownload(i);
        }
      }
    }), _c("a-icon", {
      staticClass: "iconText",
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.ondelete(index);
        }
      }
    })], 1)], 1)], 1)])]);
  })], 2), _c("a-modal", {
    attrs: {
      width: "800px",
      title: "查看",
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [!_vm.iframeSrc ? _c("div", {
    directives: [{
      name: "viewer",
      rawName: "v-viewer",
      value: {
        movable: false
      },
      expression: "{ movable: false }"
    }],
    staticClass: "images"
  }, [_c("h3", [_vm._v("单击查看大图")]), _vm._l(_vm.previewImage, function (src) {
    return _c("img", {
      key: src,
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: src
      }
    });
  })], 2) : _vm._e(), _c("a-spin", {
    attrs: {
      tip: "加载中，请稍后...",
      spinning: _vm.spinningval
    }
  }, [_vm.iframeSrc ? _c("iframe", {
    attrs: {
      width: "750px",
      height: "600px",
      src: _vm.iframeSrc,
      frameborder: "0"
    }
  }) : _vm._e()])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };