import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "add-payslip",
    staticStyle: {
      "padding-top": "0"
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _c("a-row", {
    staticClass: "content-top"
  }, [_c("step", {
    attrs: {
      current: 0
    }
  })], 1), !_vm.salary.length ? _c("div", {
    staticClass: "addPayslip-middle"
  }, [_c("a-row", {
    staticClass: "content-con"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("xq-icon", {
    staticClass: "primary_color w32 mr14",
    staticStyle: {
      "font-size": "30px"
    },
    attrs: {
      type: "icon-jilu"
    }
  }), _c("h2", {
    staticClass: "title"
  }, [_vm.pageState === "2" ? _c("span", [_vm._v("同步")]) : _vm._e(), _vm.pageState === "1" ? _c("span", [_vm._v("上传")]) : _vm._e(), _vm._v("月工资列表")])], 1), _c("h3", {
    staticClass: "sub_tit"
  }, [_vm._v("系统自动识别您的工资表，通过短信和微信一键发送给员工")]), _c("a-form", {
    staticClass: "form",
    attrs: {
      layout: "inline",
      form: _vm.form
    }
  }, [_c("a-row", [_c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "请选择月份"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      allowClear: false,
      format: "YYYY-MM",
      placeholder: "请选择"
    },
    on: {
      change: _vm.getMonthlySalaryList
    },
    model: {
      value: _vm.selectDate,
      callback: function callback($$v) {
        _vm.selectDate = $$v;
      },
      expression: "selectDate"
    }
  })], 1)], 1), _c("a-row", [_vm.pageState === "1" ? _c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "工资表名称"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "最多20字",
      "max-length": 20
    },
    model: {
      value: _vm.tableName,
      callback: function callback($$v) {
        _vm.tableName = $$v;
      },
      expression: "tableName"
    }
  })], 1) : _vm._e()], 1), _c("a-row", [_vm.pageState === "2" && _vm.dataList.length ? _c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "工资表模板"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      allowClear: "",
      "label-in-value": "",
      placeholder: "请选择工资表模板"
    },
    on: {
      change: _vm.selectChange
    }
  }, _vm._l(_vm.dataList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      model: {
        value: item.id,
        callback: function callback($$v) {
          _vm.$set(item, "id", $$v);
        },
        expression: "item.id"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1) : _vm._e()], 1), _c("a-row", [_vm.tableName && _vm.pageState === "2" && _vm.dataList.length ? _c("a-form-item", {
    staticClass: "form-item",
    attrs: {
      label: "工资表明细"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      options: _vm.itemsLst,
      mode: "multiple",
      placeholder: "请选择工资表模板"
    },
    model: {
      value: _vm.itemsLstSelected,
      callback: function callback($$v) {
        _vm.itemsLstSelected = $$v;
      },
      expression: "itemsLstSelected"
    }
  })], 1) : _vm._e()], 1), _c("a-form-item", [_vm.pageState === "1" ? _c("a-upload", {
    attrs: {
      action: _vm.action
    },
    on: {
      change: _vm.cbUpload
    }
  }, [_c("a-button", {
    ref: "onUpload",
    attrs: {
      type: "primary",
      disabled: _vm.disabled
    },
    on: {
      click: _vm.onUpload
    }
  }, [_vm._v("导入月工资表 ")])], 1) : _vm._e(), _vm.pageState === "2" ? _c("a-button", {
    ref: "onUpload",
    staticStyle: {
      width: "300px",
      "margin-left": "100px"
    },
    attrs: {
      type: "primary",
      disabled: _vm.disabled
    },
    on: {
      click: _vm.generateWage
    }
  }, [_vm._v("下一步 ")]) : _vm._e()], 1)], 1)], 1), _vm.pageState !== "2" ? _c("a-row", {
    staticClass: "content-foot"
  }, [_c("h2", [_vm._v("温馨提示")]), _c("div", {
    staticClass: "sub_tit"
  }, [_vm._v(" 导入遇到问题? 请使用标准模版 "), _c("span", {
    staticClass: "primary"
  }, [_c("a", {
    on: {
      click: _vm.downLoad
    }
  }, [_vm._v("下载标准模板")])])])]) : _vm._e()], 1) : [_c("a-row", {
    staticClass: "salary",
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    staticClass: "item",
    attrs: {
      span: 9
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 8
    },
    on: {
      click: _vm.selectItem
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "primary"
  }, [_vm._v("总共")])]), _c("a-row", {
    staticClass: "primary"
  }, [_vm._v(_vm._s(_vm.titles.countAll))])], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 6
    },
    on: {
      click: _vm.selectItem
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("企业员工")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.countInCompany))])], 1), _c("a-col", {
    attrs: {
      span: 6
    },
    on: {
      click: _vm.selectItem
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("非企业员工")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.countNotInCompany))])], 1)], 1)], 1), _c("a-col", {
    staticClass: "item",
    attrs: {
      span: 7
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 8
    },
    on: {
      click: _vm.selectItem
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("微信绑定")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.countWeChatBind))])], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 6
    },
    on: {
      click: _vm.selectItem
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("微信未绑定")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.countWeChatUnBind))])], 1)], 1)], 1), _c("a-col", {
    staticClass: "item",
    attrs: {
      span: 7
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("应发工资")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.sumSalaryAmount))])], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("实发工资")])]), _c("a-row", [_vm._v(_vm._s(_vm.titles.sumSalaryReal))])], 1)], 1)], 1)], 1), _c("a-table", {
    staticClass: "mg_t24",
    attrs: {
      scroll: {
        x: 1500
      },
      columns: _vm.columns,
      "list-type": "picture",
      pagination: _vm.pagination,
      "data-source": _vm.salary
    },
    on: {
      change: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "empInCompany",
      fn: function fn(empInCompany) {
        return [!empInCompany ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/weixuanzhong.png")
          }
        }) : _vm._e(), empInCompany ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/xuanzhong.png")
          }
        }) : _vm._e()];
      }
    }, {
      key: "phoneStatus",
      fn: function fn(phoneStatus) {
        return [!phoneStatus ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/weixuanzhong.png")
          }
        }) : _vm._e(), phoneStatus ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/xuanzhong.png")
          }
        }) : _vm._e()];
      }
    }, {
      key: "wechatStatus",
      fn: function fn(wechatStatus) {
        return [!wechatStatus ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/weixuanzhong.png")
          }
        }) : _vm._e(), wechatStatus ? _c("img", {
          attrs: {
            src: require("@/assets/img/payslip/weixin.png")
          }
        }) : _vm._e()];
      }
    }])
  })], _vm.excelVisible ? _c("excelCalback", {
    attrs: {
      visible: _vm.excelVisible,
      options: _vm.excelOptions
    },
    on: {
      change: _vm.excelChange
    }
  }) : _vm._e(), _vm.salary.length ? _c("a-row", {
    staticClass: "mg_t24",
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("a-button", {
    staticClass: "mg_r12",
    on: {
      click: _vm.reUpload
    }
  }, [_vm._v("重新上传")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.next
    }
  }, [_vm._v("下一步")])], 1) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };