var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-center-between"
  }, [_c("div", {
    staticClass: "pv16 ph24 box",
    style: {
      background: _vm.selectTypeIndex === 1 ? "var(--primary10)" : "rgba(250, 250, 250, 1)",
      "box-shadow": "inset 0px -4px 0px 0px ".concat(_vm.selectTypeIndex === 1 ? "var(--primary)" : "rgba(250, 250, 250, 1)")
    },
    on: {
      click: function click($event) {
        return _vm.setType(1);
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: require("./images/all.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px"
    }
  }, [_vm._v("全部合同")]), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.summary.allCount || 0))])])]), _c("div", {
    staticClass: "pv16 ph24 ml24 box",
    style: {
      background: _vm.selectTypeIndex === 2 ? "var(--primary10)" : "rgba(250, 250, 250, 1)",
      "box-shadow": "inset 0px -4px 0px 0px ".concat(_vm.selectTypeIndex === 2 ? "var(--primary)" : "rgba(250, 250, 250, 1)")
    },
    on: {
      click: function click($event) {
        return _vm.setType(2);
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: require("./images/willExpire.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticClass: "flex-center"
  }, [_c("span", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px",
      "margin-right": "4px"
    }
  }, [_vm._v("即将到期")]), _c("a-popover", {
    attrs: {
      content: "距离合同结束时间0-90天的合同",
      title: ""
    }
  }, [_c("span", {
    staticClass: "iconfont icon-wenhao",
    staticStyle: {
      color: "rgba(207, 208, 214, 1)",
      "margin-bottom": "-2px",
      "font-size": "14px"
    }
  })])], 1), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.summary.willExpireCount || 0))])])]), _c("div", {
    staticClass: "pv16 ph24 ml24 box",
    style: {
      background: _vm.selectTypeIndex === 3 ? "var(--primary10)" : "rgba(250, 250, 250, 1)",
      "box-shadow": "inset 0px -4px 0px 0px ".concat(_vm.selectTypeIndex === 3 ? "var(--primary)" : "rgba(250, 250, 250, 1)")
    },
    on: {
      click: function click($event) {
        return _vm.setType(3);
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: require("./images/alreadyExpire.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticClass: "flex-center"
  }, [_c("span", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px",
      "margin-right": "4px"
    }
  }, [_vm._v("已到期且未续签")]), _c("a-popover", {
    attrs: {
      content: "在职员工存在已到期且未续签合同（除了合同类型是空类型、其它、无固定期限劳动合同）",
      title: ""
    }
  }, [_c("span", {
    staticClass: "iconfont icon-wenhao",
    staticStyle: {
      color: "rgba(207, 208, 214, 1)",
      "margin-bottom": "-2px",
      "font-size": "14px"
    }
  })])], 1), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.summary.alreadyExpireCount || 0))])])]), _c("div", {
    staticClass: "pv16 ph24 ml24 box",
    style: {
      background: _vm.selectTypeIndex === 4 ? "var(--primary10)" : "rgba(250, 250, 250, 1)",
      "box-shadow": "inset 0px -4px 0px 0px ".concat(_vm.selectTypeIndex === 4 ? "var(--primary)" : "rgba(250, 250, 250, 1)")
    },
    on: {
      click: function click($event) {
        return _vm.setType(4);
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: require("./images/simple.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticClass: "flex-center"
  }, [_c("span", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px",
      "margin-right": "4px"
    }
  }, [_vm._v("单人合同大于等于两份")]), _c("a-popover", {
    attrs: {
      content: "合同类型仅包括固定期限劳动合同、劳务合同、劳务派遣合同",
      title: ""
    }
  }, [_c("span", {
    staticClass: "iconfont icon-wenhao",
    staticStyle: {
      color: "rgba(207, 208, 214, 1)",
      "margin-bottom": "-2px",
      "font-size": "14px"
    }
  })])], 1), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.summary.exceedDoubleCount || 0))])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };