var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      width: 820,
      title: "不用打卡日期",
      visible: _vm.show,
      "destroy-on-close": true
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("update:show", false);
      },
      ok: _vm.handleOk
    }
  }, [_c("a-form", {
    staticClass: "lab_l form_l"
  }, [_c("a-form-item", {
    attrs: {
      label: "日期",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-date-picker", {
    attrs: {
      "default-value": _vm.moment("".concat(_vm.noNeedPunchDate.daily), "YYYY-MM-DD"),
      "allow-clear": false
    },
    on: {
      change: _vm.onChange
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "事由",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "写点什么..."
    },
    model: {
      value: _vm.noNeedPunchDate.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.noNeedPunchDate, "reason", $$v);
      },
      expression: "noNeedPunchDate.reason"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };