var basicInformation = [{
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "name",
  fieldName: "员工姓名",
  fieldType: 2,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 1,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: [{
    label: "男",
    value: "1"
  }, {
    label: "女",
    value: "2"
  }],
  fieldCode: "gender",
  fieldName: "性别",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 2,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: [{
    label: "居民身份证",
    value: "10"
  }, {
    label: "外国护照",
    value: "20"
  }, {
    label: "中国护照",
    value: "30"
  }, {
    label: "港澳居民来往内地通行证",
    value: "40"
  }, {
    label: "台湾居民来往大陆通行证",
    value: "50"
  }, {
    label: "外国人永久居留身份证",
    value: "60"
  }, {
    label: "中华人民共和国港澳居民居住证",
    value: "70"
  }, {
    label: "中华人民共和国台湾居民居住证",
    value: "80"
  }, {
    label: "《中华人民共和国外国人工作许可证》（A类）",
    value: "90"
  }, {
    label: "《中华人民共和国外国人工作许可证》（B类）",
    value: "100"
  }, {
    label: "《中华人民共和国外国人工作许可证》（C类）",
    value: "110"
  }, {
    label: "其他个人证件",
    value: "120"
  }],
  fieldCode: "licenseType",
  fieldName: "证件类型",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 3,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "licenseNumber",
  fieldName: "证件号码",
  fieldType: 2,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 4,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "birthday",
  fieldName: "出生日期",
  fieldType: 3,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 5,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "nationality",
  fieldName: "国籍（地区）",
  fieldType: 2,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 6,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: [{
    label: "大学本科以下",
    value: "10"
  }, {
    label: "大学本科",
    value: "20"
  }, {
    label: "研究生",
    value: "30"
  }],
  fieldCode: "education",
  fieldName: "学历",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 7,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: [{
    label: "是",
    value: "true"
  }, {
    label: "否",
    value: "false"
  }],
  fieldCode: "isDisable",
  fieldName: "是否残疾",
  initialValue: "false",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 8,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "disableCard",
  fieldName: "残疾证号",
  fieldType: 5,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 9,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: [{
    label: "是",
    value: "true"
  }, {
    label: "否",
    value: "false"
  }],
  fieldCode: "isMartyr",
  fieldName: "是否烈属",
  initialValue: "false",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 10,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "martyrCard",
  fieldName: "烈属证号",
  fieldType: 5,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 11,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: [{
    label: "是",
    value: "true"
  }, {
    label: "否",
    value: "false"
  }],
  fieldCode: "isOld",
  fieldName: "是否孤老",
  initialValue: "false",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "info",
  moduleName: "基本信息",
  sort: 12,
  templateId: null
}];
var detailedInformation = [{
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "contactAddressProvince1",
  fieldName: "联系地址",
  fieldType: 6,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 3,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "contactAddressDetail",
  fieldName: "",
  fieldType: 2,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 4,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "habitualResidenceProvince1",
  fieldName: "经常居住地",
  fieldType: 6,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 5,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "habitualResidenceDetail",
  fieldName: "",
  fieldType: 2,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 6,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "domicileProvince1",
  fieldName: "户籍所在地",
  fieldType: 6,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 7,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "domicileDetail",
  fieldName: "",
  fieldType: 2,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 8,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "bank",
  fieldName: "开户银行",
  fieldType: 2,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 9,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "bankProvince",
  fieldName: "开户行省份",
  fieldType: 2,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 10,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "bankCard",
  fieldName: "银行账号",
  fieldType: 2,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 11,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "email",
  fieldName: "电子邮箱",
  fieldType: 2,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 12,
  templateId: null
}];
var employmentInformation = [{
  companyId: null,
  dropDownEnumList: [{
    label: "正常",
    value: 1
  }, {
    label: "非正常",
    value: 2
  }],
  fieldCode: "status",
  fieldName: "人员状态",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  initialValue: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 1,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "authorityId",
  fieldName: "所属税局",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 2,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "phoneNew",
  fieldName: "手机号",
  fieldType: 2,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 3,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "employedDate",
  fieldName: "任职受雇从业日期",
  fieldType: 3,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 4,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: [{
    label: "雇员",
    value: "10"
  }, {
    label: "保险营销员",
    value: "20"
  }, {
    label: "证券经纪人",
    value: "30"
  }, {
    label: "其他",
    value: "40"
  }, {
    label: "实习学生（全日制学历教育）",
    value: "50"
  }],
  fieldCode: "employmentType",
  initialValue: "10",
  fieldName: "任职受雇从业类型",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 5,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: [{
    label: "高层",
    value: "1"
  }, {
    label: "普通",
    value: "2"
  }],
  fieldCode: "position",
  fieldName: "职务",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 6,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: [{
    label: "当年首次入职学生",
    value: "1"
  }, {
    label: "当年首次入职其他人员",
    value: "2"
  }],
  fieldCode: "employmentSituation",
  fieldName: "入职年度就业情形",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 7,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: [{
    label: "是",
    value: "true"
  }, {
    label: "否",
    value: "false"
  }],
  fieldCode: "isDeductDeductible",
  fieldName: "是否扣除减除费用",
  fieldType: 1,
  gmtCreate: null,
  gmtModified: null,
  initialValue: "true",
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 8,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "resignDate",
  fieldName: "离职日期",
  fieldType: 3,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 9,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "totalPersonalInvestment",
  fieldName: "个人投资金额",
  fieldType: 5,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: false,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 10,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "personalInvestmentRatio",
  fieldName: "个人投资比例(%)",
  fieldType: 5,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: "detailed",
  moduleName: "详细信息",
  sort: 11,
  templateId: null
}];
export { basicInformation, detailedInformation, employmentInformation };