import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.prodList.length > 0 ? _c("div", {
    staticClass: "bgc-white p16 flex",
    staticStyle: {
      "align-items": "flex-start"
    }
  }, [_c("div", {
    staticStyle: {
      width: "520px"
    }
  }, [_c("div", {
    staticClass: "title-block mb16"
  }, [_vm._v("企业服务选择")]), _vm._l(_vm.picsArr, function (item, idx) {
    return _c("ConfigItem", {
      key: "".concat(item.id || "new", "_").concat(idx),
      attrs: {
        target: item,
        options: _vm.prodList
      },
      on: {
        del: function del($event) {
          return _vm.onPicDel(idx);
        },
        edit: function edit($event) {
          return _vm.onPicEdit($event, idx);
        }
      }
    });
  }), _vm._l(_vm.tempPicsArr, function (item, idx) {
    return _c("ConfigItem", {
      key: "".concat(item.id || "new", "_").concat(idx),
      attrs: {
        target: item,
        options: _vm.prodList
      },
      on: {
        del: function del($event) {
          return _vm.onPicTempDel(idx);
        },
        add: function add($event) {
          return _vm.onPicTempAdd($event, idx);
        }
      }
    });
  }), _c("div", {
    staticClass: "ta-center"
  }, [_vm.theLen < 4 ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addItem
    }
  }, [_vm._v("添加")]) : _vm._e()], 1)], 2), _c("div", {
    staticClass: "pl24",
    staticStyle: {
      "border-left": "1px solid #e8e8e8"
    }
  }, [_c("div", {
    staticClass: "title-block mb16"
  }, [_vm._v("小程序预览")]), _c("div", {
    staticClass: "preview"
  }, [_vm.showCarousel ? _c("div", {
    staticClass: "preview-inner"
  }, [_c("a-carousel", {
    attrs: {
      autoplay: ""
    }
  }, _vm._l(_vm.picsArr, function (li) {
    return _c("div", {
      key: li.id,
      staticStyle: {
        width: "343px",
        height: "120px"
      }
    }, [_c("img", {
      staticStyle: {
        width: "100%",
        height: "100%"
      },
      attrs: {
        src: li.banner
      }
    })]);
  }), 0)], 1) : _vm._e()])])]) : _c("div", {
    staticClass: "bgc-white p16 flex-center-center",
    staticStyle: {
      height: "calc(100vh - 112px)"
    }
  }, [_vm.prodLoaded ? _c("a-empty", [_c("template", {
    slot: "description"
  }, [_c("div", [_vm._v("抱歉，暂无企业服务")]), _c("div", {
    staticClass: "mt12"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/admin/productList");
      }
    }
  }, [_vm._v("添加企业服务")])], 1)])], 2) : _c("a-empty", [_vm._v("加载中...")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };