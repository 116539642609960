import request from "@/utils/request";
import axios from "axios";
import { getToken } from "xqjr-module-auth/utils";
export function selectDefaultGroupIdReq() {
  return request({
    url: "/hr/api/v1/saleUsers/selectDefaultGroupId",
    method: "get"
  });
}
export function selectCompanyBySaleUserIdReq(userId) {
  return request({
    url: "/hr/api/v1/companySaleUsers/selectCompanyBySaleUserId/".concat(userId),
    method: "get"
  });
}

// 统计
export function rebateOverviewListReq(params) {
  return request({
    url: "/hr/api/v1/saleRebatesRecords/rebateOverviewList",
    method: "get",
    params: params
  });
}
export function rebateOverviewGroupReq(params) {
  return request({
    url: "/hr/api/v1/saleRebatesRecords/rebateOverviewGroup",
    method: "get",
    params: params
  });
}
export function rebateOverviewMonthReq(params) {
  return request({
    url: "/hr/api/v1/saleRebatesRecords/rebateOverviewMonth",
    method: "get",
    params: params
  });
}

// 提成计算
export function saleRebatesRecordsReq(params) {
  return request({
    url: "/hr/api/v1/saleRebatesRecords",
    method: "get",
    params: params
  });
}
export function tileCompanyByUserReq() {
  return request({
    url: "/hr/api/v1/companies/tileCompanyByUser",
    method: "get"
  });
}
export function calculationRebatesReq(data) {
  return request({
    url: "/hr/api/v1/saleRebatesRecords/calculationRebates",
    method: "post",
    data: data
  });
}

// 服务费
export function saleServerCostRecordsReq(params) {
  return request({
    url: "/hr/api/v1/saleServerCostRecords",
    method: "get",
    params: params
  });
}
export function overlaidDataReq(data) {
  return request({
    url: "/hr/api/v1/saleServerCostRecords/overlaidData",
    method: "put",
    data: data
  });
}

// 提成规则
export function selectSiteRebatesRulesReq(rulesName) {
  var p = rulesName ? "?rulesName=".concat(rulesName) : "";
  return request({
    url: "/hr/api/v1/saleRebatesRules/selectSiteRebatesRules".concat(p),
    method: "get"
  });
}
export function addSaleRebatesRulesReq(data) {
  return request({
    url: "/hr/api/v1/saleRebatesRules",
    method: "post",
    data: data
  });
}
export function updateSaleRebatesRulesReq(data) {
  return request({
    url: "/hr/api/v1/saleRebatesRules",
    method: "put",
    data: data
  });
}
export function delSaleRebatesRulesReq(saleRebatesRulesId) {
  return request({
    url: "/hr/api/v1/saleRebatesRules/".concat(saleRebatesRulesId),
    method: "delete"
  });
}
export function selectBindCompanyReq(params) {
  return request({
    url: "/hr/api/v1/saleRebatesRules/selectBindCompany",
    method: "get",
    params: params
  });
}

// 销售
export function saleUsersReq(data) {
  return request({
    url: "/hr/api/v1/saleUsers",
    method: "post",
    data: data
  });
}
export function updateSaleUsersReq(data) {
  return request({
    url: "/hr/api/v1/saleUsers",
    method: "put",
    data: data
  });
}
export function exportSaleUsersReq(params) {
  return axios({
    url: "/hr/api/v1/saleUsers/exportSale",
    method: "get",
    params: params,
    responseType: "blob",
    headers: {
      token: getToken(),
      "Content-Type": "application/json"
    }
  });
}
export function delSaleUsersReq(saleUserId) {
  return request({
    url: "/hr/api/v1/saleUsers/".concat(saleUserId),
    method: "delete"
  });
}
export function selectSaleByGroupIdReq(params) {
  return request({
    url: "/hr/api/v1/saleUsers/selectSaleByGroupId",
    method: "get",
    params: params
  });
}

// 分组
export function selectGroupTreeReq(groupOrAdminName) {
  var p = groupOrAdminName ? "?groupOrAdminName=".concat(groupOrAdminName) : "";
  return request({
    url: "/hr/api/v1/saleUsers/selectGroupTree".concat(p),
    method: "get"
  });
}
export function saveGroupReq(data) {
  return request({
    url: "/hr/api/v1/saleUsers/saveGroup",
    method: "post",
    data: data
  });
}
export function updateGroupReq(data) {
  return request({
    url: "/hr/api/v1/saleUsers/updateGroup",
    method: "put",
    data: data
  });
}
// 销售分组管理员
export function queryUserListLessToMeAddReq() {
  return request({
    url: "/hr/api/v1/users/queryUserListLessToMeAdd",
    method: "get"
  });
}

// 销售
export function queryUserListLessToMeReq() {
  return request({
    url: "/hr/api/v1/users/queryUserListLessToMe",
    method: "get"
  });
}
export function selectGroupInfoReq(groupId) {
  return request({
    url: "/hr/api/v1/saleUsers/selectGroupInfo/".concat(groupId),
    method: "get"
  });
}
export function deleteGroupReq(groupId) {
  return request({
    url: "/hr/api/v1/saleUsers/deleteGroup/".concat(groupId),
    method: "delete"
  });
}