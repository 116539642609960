var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "main-sidebar-height",
    staticStyle: {
      "padding-bottom": "24px",
      "background-color": "#fff"
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.backHandler();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.dataDetail2 === null || _vm.dataDetail2.length === 0 ? _c("div", [_c("a-spin", {
    attrs: {
      spinning: _vm.uploading
    }
  }, [_c("div", {
    staticClass: "ant-upload-select-text-box",
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-upload", {
    attrs: {
      name: "file",
      multiple: false,
      accept: ".xls, .xlsx, .xlsm",
      "show-upload-list": false,
      action: _vm.action,
      "custom-request": _vm.beforeUpload1
    }
  }, [_c("img", {
    staticStyle: {
      width: "139px",
      height: "106px"
    },
    attrs: {
      src: _vm.uploadImg
    }
  }), _c("div", {
    staticClass: "ant-modal-body"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#666"
    }
  }, [_vm._v("点击添加文件或将文件拖拽到此处")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#999"
    }
  }, [_vm._v("(文件大小不超过5M)")])])])], 1)]), _c("div", {
    staticClass: "tipBox"
  }, [_c("a-row", {
    staticStyle: {
      color: "#252525",
      "font-size": "16px",
      "font-weight": "500"
    }
  }, [_vm._v(" 温馨提示： ")]), _c("a-row", [_c("a-col", {
    staticStyle: {
      "line-height": "25px",
      color: "#999"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "6px"
    }
  }, [_vm._v(" 请先下载模板，根据模板填写信息后再进行上传"), _c("a-button", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      size: "small",
      type: "link"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("下载标准模板")])], 1), _c("div", [_vm._v("1.该功能用于进行" + _vm._s(_vm.title) + "操作，" + _vm._s(_vm.title) + "仅创建增减员计划")]), _c("div", [_vm._v("2.模板文件大小不得超过5M，单次导入上传数据不得超过1000条")]), _c("div", [_vm._v("3.支持wps，excel2003以上版本文件，支持xlsx格式")]), _c("div", [_vm._v("4.请不要上传加密或带宏的文件，不然将导致上传错误")])])], 1)], 1)], 1) : _c("div", {
    staticStyle: {
      display: "flex",
      "padding-top": "15px",
      "padding-left": "24px",
      "padding-right": "24px",
      "flex-direction": "column",
      width: "100%"
    }
  }, [_c("div", [_c("div", {
    staticStyle: {
      "padding-left": "10px",
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      height: "35px",
      width: "100%",
      "background-color": "#fffbe6",
      "border-style": "solid",
      "border-color": "#ffe58f",
      "border-width": "1px",
      "border-radius": "5px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "5px"
    },
    attrs: {
      src: require("../../../assets/icons/重复.png")
    }
  }), _c("span", {
    attrs: {
      sytle: "color:#55565D;font-size:14px;"
    }
  }, [_vm._v(" 共" + _vm._s(_vm.allCount) + "条数据，成功导入" + _vm._s(_vm.successCount) + "条数据， "), _c("label", {
    staticClass: "redc"
  }, [_vm._v(_vm._s(_vm.errorCount))]), _vm._v("条数据导入失败 ")])])]), _c("div", {
    staticClass: "header-title-box"
  }, [_vm._m(0), _c("div", {
    staticClass: "btn-box"
  }, [_c("a-button", {
    staticClass: "fr",
    attrs: {
      type: "primary",
      disabled: _vm.uploading
    },
    on: {
      click: _vm.BatchGet
    }
  }, [_vm._v(_vm._s(_vm.uploading ? "数据导出中..." : "导出错误数据"))])], 1)]), _c("a-table", {
    attrs: {
      columns: _vm.columnDialogDetailguding,
      "data-source": _vm.dataDetail2,
      scroll: {
        x: 800
      },
      "row-key": "id"
    },
    scopedSlots: _vm._u([_vm._l(_vm.columnDialogDetailguding, function (item) {
      return {
        key: item.key,
        fn: function fn(text, record) {
          return _c("div", {
            key: item.key
          }, [[!_vm.getErro(record.errorInfo, item.key) ? _c("div", [_vm._v(" " + _vm._s(text) + " ")]) : _c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [_c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(_vm.getErroInfo(record.errorInfo, item.key)))])]), _c("div", {
            staticStyle: {
              color: "#ff0000"
            }
          }, [_vm._v(_vm._s(text))])], 2)]], 2);
        }
      };
    })], null, true)
  })], 1), _c("a-modal", {
    attrs: {
      title: _vm.title,
      width: "500px"
    },
    on: {
      ok: _vm.handleCancel
    },
    model: {
      value: _vm.dialogFailVisible,
      callback: function callback($$v) {
        _vm.dialogFailVisible = $$v;
      },
      expression: "dialogFailVisible"
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleLizhi
    }
  }, [_vm._v("查看增减员计划")])], 1), _c("div", {
    staticStyle: {
      "padding-left": "10px",
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      height: "35px",
      width: "100%"
    }
  }, [_c("img", {
    staticStyle: {
      width: "25px",
      height: "25px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("../../../assets/icons/duihao.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "15px",
      "font-weight": "700"
    }
  }, [_vm._v("共" + _vm._s(_vm.allCount) + "条数据，全部导入成功！")])])], 2)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticStyle: {
      "font-size": "15px",
      "font-weight": "700",
      "font-color": "#252525"
    }
  }, [_vm._v("错误信息")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };