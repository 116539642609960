import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
var commonRoutes = [{
  path: "myClientele",
  // 我的客户
  meta: {
    route: "/clientele/myClientele",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/myClientele"));
    });
  }
}, {
  path: "myClientele/add",
  // 客户
  name: "myClienteleAddClientele",
  meta: {
    route: "/clientele/myClientele",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/add"));
    });
  }
}, {
  path: "myClientele/edit",
  // 编辑
  name: "myClienteleEditClientele",
  meta: {
    route: "/clientele/myClientele",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/add"));
    });
  }
}, {
  path: "myClientele/msg",
  // 详情
  name: "myClienteleMsgClientele",
  meta: {
    route: "/clientele/myClientele",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/msg"));
    });
  }
}, {
  path: "sea",
  // 客户公海
  meta: {
    route: "/clientele/sea",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/sea"));
    });
  }
}, {
  path: "sea/add",
  // 新增
  name: "seaAddClientele",
  meta: {
    route: "/clientele/sea",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/add"));
    });
  }
}, {
  path: "sea/edit",
  // 编辑
  name: "seaEditClientele",
  meta: {
    route: "/clientele/sea",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/add"));
    });
  }
}, {
  path: "sea/msg",
  // 详情
  name: "seaMsgClientele",
  meta: {
    route: "/clientele/sea",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/msg"));
    });
  }
}, {
  path: "member",
  // 成员管理
  meta: {
    route: "/clientele/member",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/member"));
    });
  }
}, {
  path: "member/msg",
  // 成员客户明细
  name: "memberMsg",
  meta: {
    route: "/clientele/member",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/member/msg"));
    });
  }
}, {
  path: "member/edit",
  // 编辑
  name: "memberEditClientele",
  meta: {
    route: "/clientele/member",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/add"));
    });
  }
}, {
  path: "member/Clientele/msg",
  // 成员-客户详情
  name: "memberMsgClientele",
  meta: {
    route: "/clientele/member",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/msg"));
    });
  }
}, {
  path: "myClientele/imports",
  // 导入
  name: "myClienteleImports",
  meta: {
    route: "/clientele/myClientele",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/imports"));
    });
  }
}, {
  path: "sea/imports",
  // 导入
  name: "seaImports",
  meta: {
    route: "/clientele/sea",
    routeSession: "clientelePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele/imports"));
    });
  }
}];
export default {
  path: "/clientele",
  // 人事管理
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/clientele"));
    });
  },
  redirect: "myClientele",
  children: [].concat(commonRoutes)
};