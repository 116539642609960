import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-back approval-detail-wrapper"
  }, [_c("a-space", [_c("a-button", {
    on: {
      click: _vm.handlerBack
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  }), _vm._v("返回 ")], 1)], 1), _vm.approval ? _c("a-row", {
    staticClass: "approval-block"
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "mb10"
  }, [_c("span", {
    staticClass: "approval-block-top-title"
  }, [_vm._v(_vm._s(_vm.approval && _vm.approval.approName))]), _c("a-tag", {
    attrs: {
      color: _vm.getApprovalStatusColorBlue(_vm.approval && _vm.approval.status)
    }
  }, [_vm._v(" " + _vm._s(_vm.getApprovalStatus(_vm.approval && _vm.approval.status)) + " ")])], 1), _c("div", {
    staticClass: "mb10 approval-block-top-num"
  }, [_vm._v("编号：" + _vm._s(_vm.approval && _vm.approval.approNumber))]), _c("div", {
    staticClass: "mb10 approval-block-top-num"
  }, [_vm._v("公司：" + _vm._s(_vm.companyName))]), _c("div", {
    staticClass: "mb10 approval-block-top-num"
  }, [_vm._v("类型：" + _vm._s(_vm.approval && _vm.approval.approTypeCodeName))])])], 1) : _vm._e(), _c("a-row", {
    staticClass: "approval-block"
  }, [_c("a-col", {
    staticClass: "approval-block-title",
    attrs: {
      span: 24
    }
  }, [_vm._v("审批流程")]), _c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("ApprovalProcess", {
    attrs: {
      approvalVal: _vm.approvalNodes
    }
  })], 1)], 1), _c("a-row", {
    staticClass: "approval-block"
  }, [_c("a-col", {
    staticClass: "approval-block-title",
    attrs: {
      span: 24
    }
  }, [_vm._v("审批表单")]), _c("a-col", {
    staticClass: "mt24",
    attrs: {
      span: 12
    }
  }, [_vm.formVisible ? _c("ApprovalForm", {
    ref: "approvalForm",
    staticClass: "approval-form",
    attrs: {
      dataSource: _vm.dataSource,
      formFields: _vm.formFields,
      formConfig: _vm.formConfig
    }
  }) : _vm._e()], 1)], 1), this.approval && ["salaryBill", "socBill", "pfBill"].includes(this.approval.approTypeCode) && _vm.appRovalDet ? _c("a-row", {
    staticClass: "approval-block"
  }, [_c("a-col", {
    staticClass: "approval-block-title",
    attrs: {
      span: 24
    }
  }, [_vm._v("审批明细")]), _c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("approvalDetail", {
    attrs: {
      approvalId: _vm.approvalId
    },
    on: {
      getappRovalDet: function getappRovalDet($event) {
        _vm.appRovalDet = false;
      }
    }
  })], 1)], 1) : _vm._e(), _c("a-row", {
    staticClass: "approval-block"
  }, [_c("a-col", {
    staticClass: "approval-block-title",
    attrs: {
      span: 24
    }
  }, [_vm._v("审批记录")]), _c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("approvalTimeLine", {
    attrs: {
      approvalLog: _vm.approvalNodes
    }
  })], 1)], 1), _vm.approval && _vm.approval.needAppro && _vm.userApprovalId && _vm.userApprovalId !== "read" ? _c("div", [_c("div", {
    staticStyle: {
      height: "64px"
    }
  }), _c("div", {
    staticClass: "approval-block approvalBor"
  }, [_vm.approval && _vm.approval.status == "1" ? _c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    on: {
      click: function click($event) {
        return _vm.openModal("reject");
      }
    }
  }, [_vm._v("拒绝")]) : _vm._e(), _vm.approval && _vm.approval.status == "1" ? _c("a-button", {
    staticClass: "detailBack",
    on: {
      click: function click($event) {
        return _vm.openModal("agree");
      }
    }
  }, [_vm._v("同意")]) : _vm._e()], 1)]) : _vm._e(), _c("a-modal", {
    attrs: {
      title: _vm.Title,
      visible: _vm.visible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        _vm.visible = false;
      }
    }
  }, [_c("a-textarea", {
    attrs: {
      placeholder: _vm.placeholder,
      rows: 4
    },
    model: {
      value: _vm.remark,
      callback: function callback($$v) {
        _vm.remark = $$v;
      },
      expression: "remark"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };