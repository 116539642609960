import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "p24 page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "headerBox"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "row"
    }
  }, [_c("selectCompanyGroup", {
    staticClass: "mt4 mr20",
    attrs: {
      hasLeftIcon: true,
      siteIds: _vm.siteId,
      siteName: _vm.siteName,
      selectTreeId: _vm.selectTreeId,
      type: _vm.type
    },
    on: {
      "update:siteIds": function updateSiteIds($event) {
        _vm.siteId = $event;
      },
      "update:site-ids": function updateSiteIds($event) {
        _vm.siteId = $event;
      },
      "update:siteName": function updateSiteName($event) {
        _vm.siteName = $event;
      },
      "update:site-name": function updateSiteName($event) {
        _vm.siteName = $event;
      },
      "update:selectTreeId": function updateSelectTreeId($event) {
        _vm.selectTreeId = $event;
      },
      "update:select-tree-id": function updateSelectTreeId($event) {
        _vm.selectTreeId = $event;
      },
      "update:type": function updateType($event) {
        _vm.type = $event;
      }
    }
  }), _c("a-month-picker", {
    staticClass: "mr20",
    attrs: {
      "default-value": _vm.now ? _vm.moment(_vm.now, "YYYY-MM") : null,
      placeholder: "选择月份"
    },
    on: {
      change: _vm.monthChange
    }
  }), _c("a-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      placeholder: "状态筛选",
      allowClear: ""
    },
    on: {
      change: _vm.approStatusChange
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "0"
    }
  }, [_vm._v(" 待结算 ")]), _c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 审批中 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 审批驳回 ")]), _c("a-select-option", {
    attrs: {
      value: "5"
    }
  }, [_vm._v(" 已通过待结算 ")]), _c("a-select-option", {
    attrs: {
      value: "6"
    }
  }, [_vm._v(" 已结算 ")])], 1)], 1), _c("div", [false ? _c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.mergBilling
    }
  }, [_vm._v(" 合并账单")]) : _vm._e(), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.creatBilling
    }
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "5px",
      "margin-bottom": "2px"
    },
    attrs: {
      src: require("../../../assets/img/zhangd.png")
    }
  }), _vm._v(" 生成账单 ")])], 1)]), _vm.list.length === 0 ? [_c("a-empty", {
    staticStyle: {
      "margin-top": "100px"
    }
  })] : _vm._l(_vm.list, function (item, i) {
    return _c("div", {
      key: i
    }, [item.companyName ? _c("div", {
      staticStyle: {
        "font-size": "16px",
        "font-weight": "500",
        color: "#000",
        "margin-top": "25px",
        "margin-bottom": "-10px"
      }
    }, [_vm._v(" " + _vm._s(item.companyName) + " ")]) : _vm._e(), _c("div", {
      staticStyle: {
        display: "flex",
        "justify-content": "space-between"
      }
    }, [_c("div", {
      staticClass: "fix-item-wrapper"
    }, [_c("div", {
      class: [item.isConfirm ? "fix-item-one" : "fix-item-one2"]
    }, [_c("p", {
      staticClass: "fix-item-name"
    }, [_vm._v(_vm._s(_vm.getBillName(item.billName)))]), _vm.getSubT(item.approStatus) !== "" ? _c("div", {
      staticStyle: {
        "flex-flow": "row",
        width: "100%",
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        "margin-top": "10px"
      }
    }, [_c("p", {
      style: [{
        textAlign: "center"
      }, {
        color: _vm.getColor(item.approStatus)
      }, {
        background: _vm.getBackground(item.approStatus)
      }, {
        borderRadius: "3px 3px 3px 3px"
      }, {
        paddingLeft: "5px"
      }, {
        paddingRight: "5px"
      }, {
        border: _vm.getBorder(item.approStatus)
      }, {
        fontSize: "11px"
      }, {
        marginRight: "8px"
      }, {
        cursor: "pointer"
      }]
    }, [_vm._v(" " + _vm._s(_vm.getSubT(item.approStatus)) + " ")])]) : _vm._e()]), _c("div", {
      class: [item.subjectType === "社保账单" || item.subjectType === "公积金账单" ? "fix-item-two-shebao" : "fix-item-two"]
    }, [_c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v(_vm._s(_vm.getTitle(item.subjectType, 0)))]), _vm.getTitle(item.subjectType, 0) !== "计薪人数" ? _c("p", {
      staticClass: "childContentName"
    }, [_vm._v("¥" + _vm._s(_vm.getCost(item, 0)) + "元")]) : _c("p", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(_vm.getCost(item, 0)) + "人")])])]), _c("div", {
      class: [item.subjectType === "社保账单" || item.subjectType === "公积金账单" ? "fix-item-two-shebao" : "fix-item-two"]
    }, [_c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v(_vm._s(_vm.getTitle(item.subjectType, 1)))]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v("¥" + _vm._s(_vm.getCost(item, 1)) + "元")])])]), item.subjectType === "社保账单" || item.subjectType === "公积金账单" ? _c("div", {
      staticClass: "fix-item-two-shebao"
    }, [_c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v("滞纳金")]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v("¥" + _vm._s(item.fine ? item.fine : 0) + "元")])])]) : _vm._e(), _c("div", {
      class: [item.subjectType === "社保账单" || item.subjectType === "公积金账单" ? "fix-item-two-shebao" : "fix-item-two"]
    }, [_c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v(_vm._s(_vm.getTitle(item.subjectType, 2)))]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v("¥" + _vm._s(_vm.getCost(item, 2)) + "元")])])]), item.isConfirm ? _c("div", {
      class: [item.subjectType === "社保账单" || item.subjectType === "公积金账单" ? "fix-item-two-shebao" : "fix-item-two"]
    }, [_c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v("实际支付金额")]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v("¥" + _vm._s(item.finalAmount ? item.finalAmount : 0) + "元")])])]) : _vm._e(), _c("div", {
      class: [item.isConfirm ? "fix-item-two2" : "fix-item-two3"]
    }, [_c("a-dropdown", {
      attrs: {
        placement: "bottomLeft"
      }
    }, [_c("a-icon", {
      staticClass: "primary_color w24 mr20",
      staticStyle: {
        "font-size": "30px"
      },
      attrs: {
        type: "ellipsis"
      }
    }), _c("a-menu", {
      attrs: {
        slot: "overlay"
      },
      slot: "overlay"
    }, [_c("a-menu-item", {
      attrs: {
        disabled: !item.userApproId
      },
      on: {
        click: function click($event) {
          return _vm.handleMenuClick(0, item);
        }
      }
    }, [_vm._v("查看审核流程")]), _c("a-menu-item", {
      attrs: {
        disabled: item.approStatus === 1 || item.approStatus === 3
      },
      on: {
        click: function click($event) {
          return _vm.handleMenuClick(1, item);
        }
      }
    }, [_vm._v(" 发起审批")]), _c("a-menu-item", {
      attrs: {
        disabled: item.approStatus !== 5 && item.approStatus !== 0 || item.isMerge
      },
      on: {
        click: function click($event) {
          return _vm.handleMenuClick(3, item);
        }
      }
    }, [_vm._v(" 账单结算")]), _c("a-menu-item", {
      attrs: {
        disabled: item.approStatus === 1
      },
      on: {
        click: function click($event) {
          return _vm.handleMenuClick(2, item);
        }
      }
    }, [_vm._v(" 删除")])], 1)], 1), _c("div", {
      staticClass: "fix-item-one-end",
      staticStyle: {
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.onDetail(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.autoSystem ? "查看详情" : item.annexUrl ? item.annexUrl.indexOf(".jpg") !== -1 || item.annexUrl.indexOf(".jpeg") !== -1 || item.annexUrl.indexOf(".png") !== -1 ? "预览附件" : "下载附件" : "无附件") + " ")])], 1)])])]);
  }), _c("a-modal", {
    attrs: {
      title: "合并账单",
      visible: _vm.isMergConfirmPlan,
      width: "560px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.mergConfirmOk
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", [_c("a-form", {
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "选择用工单位分组",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "305px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.handleChange2
    }
  }, _vm._l(_vm.templatesListGroup, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: index
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1), _c("div", {
    staticStyle: {
      width: "305px"
    }
  }, _vm._l(_vm.group, function (item, index) {
    return _c("a-tag", {
      key: index
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "账单时间",
      required: ""
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "305px"
    },
    attrs: {
      "default-value": _vm.billingD != null ? _vm.moment(_vm.billingD, "YYYY-MM") : null
    },
    on: {
      change: _vm.onStopYearMonthChange
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "合并账单类型",
      required: ""
    }
  }, [_c("a-radio-group", {
    staticStyle: {
      width: "320px"
    },
    on: {
      change: _vm.onCheckOutChange
    },
    model: {
      value: _vm.billingType,
      callback: function callback($$v) {
        _vm.billingType = $$v;
      },
      expression: "billingType"
    }
  }, [_c("a-radio", {
    attrs: {
      value: "shebao"
    }
  }, [_vm._v(" 社保账单 ")]), _c("a-radio", {
    attrs: {
      value: "gongjijin"
    }
  }, [_vm._v(" 公积金账单 ")]), _c("a-radio", {
    attrs: {
      value: "xinzi"
    }
  }, [_vm._v(" 薪资账单 ")])], 1)], 1)], 1)], 1)], 2), _c("a-modal", {
    attrs: {
      title: "账单结算",
      visible: _vm.BillConfirm,
      width: "560px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.BillConfirmOk
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "center",
      "margin-bottom": "20px",
      "font-size": "15px",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentItem ? _vm.currentItem.tempCompanyName + _vm.currentItem.billName : "") + " ")]), _c("a-form", {
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [(_vm.currentItem ? _vm.getTitle(_vm.currentItem.subjectType, 0) !== "计薪人数" : false) ? _c("a-form-item", {
    attrs: {
      label: "人数"
    }
  }, [_c("a-input", {
    attrs: {
      suffix: "人",
      disabled: true,
      "default-value": _vm.currentItem ? _vm.getTitle(_vm.currentItem.subjectType, 0) !== "计薪人数" ? _vm.currentItem.number : _vm.getCost(_vm.currentItem, 0) : 0
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: _vm.currentItem ? _vm.getTitle(_vm.currentItem.subjectType, 0) : ""
    }
  }, [_c("a-input", {
    attrs: {
      suffix: "元",
      disabled: true,
      "default-value": _vm.currentItem ? _vm.getCost(_vm.currentItem, 0) : 0
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: _vm.currentItem ? _vm.getTitle(_vm.currentItem.subjectType, 1) : ""
    }
  }, [_c("a-input", {
    attrs: {
      suffix: "元",
      disabled: true,
      "default-value": _vm.currentItem ? _vm.getCost(_vm.currentItem, 1) : 0
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: _vm.currentItem ? _vm.getTitle(_vm.currentItem.subjectType, 2) : ""
    }
  }, [_c("a-input", {
    attrs: {
      suffix: "元",
      disabled: true,
      "default-value": _vm.currentItem ? _vm.getCost(_vm.currentItem, 2) : 0
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "实际付款额"
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      suffix: "元",
      "default-value": _vm.onBillChange(_vm.currentItem ? _vm.getCost(_vm.currentItem, 2) : 0)
    },
    on: {
      change: _vm.onBillChange
    }
  })], 1)], 1)], 1)], 2), _c("a-modal", {
    attrs: {
      title: "生成账单",
      visible: _vm.isConfirmPlan,
      width: "530px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmOk
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", [_c("div", {
    staticClass: "baseInfo-item2"
  }, [_c("div", {
    class: _vm.isSysBilling ? "onwork2" : "onwork",
    staticStyle: {
      "margin-right": "20px"
    },
    on: {
      click: function click($event) {
        return _vm.tabClick(true);
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "28px",
      "margin-right": "15px"
    },
    attrs: {
      src: require("../../../assets/img/xitongc.png")
    }
  }), _c("span", [_vm._v("从系统中生成账单")])]), _c("div", {
    class: _vm.isSysBilling ? "onwork" : "onwork2",
    on: {
      click: function click($event) {
        return _vm.tabClick(false);
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "28px",
      "margin-right": "15px"
    },
    attrs: {
      src: require("../../../assets/img/ziji.png")
    }
  }), _vm._v(" 自己创建账单 ")])]), _c("a-form", {
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "选择用工单位",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      allowClear: "",
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      placeholder: "请选择",
      mode: "multiple"
    },
    on: {
      change: _vm.handleChange
    }
  }, _vm._l(_vm.templatesList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "账单时间",
      required: ""
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      "default-value": _vm.billingD != null ? _vm.moment(_vm.billingD, "YYYY-MM") : null
    },
    on: {
      change: _vm.onStopYearMonthChange
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "生成账单类型",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      "default-value": _vm.billingType
    },
    on: {
      change: _vm.handleSelectChange
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "shebao"
    }
  }, [_vm._v(" 社保账单 ")]), _c("a-select-option", {
    attrs: {
      value: "gongjijin"
    }
  }, [_vm._v(" 公积金账单 ")]), _c("a-select-option", {
    attrs: {
      value: "xinzi"
    }
  }, [_vm._v(" 薪资账单 ")])], 1)], 1), !_vm.isSysBilling && _vm.billingType === "shebao" ? _c("a-form-item", {
    attrs: {
      label: "单位社保",
      required: ""
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      placeholder: "请输入单位社保金额",
      formatter: function formatter(value) {
        return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      parser: function parser(value) {
        return value.replace(/\$\s?|(,*)/g, "");
      },
      "default-value": _vm.DanWeiSheBao
    },
    on: {
      change: _vm.onDanWeiSheBaoChange
    },
    model: {
      value: _vm.DanWeiSheBao,
      callback: function callback($$v) {
        _vm.DanWeiSheBao = $$v;
      },
      expression: "DanWeiSheBao"
    }
  })], 1) : _vm._e(), !_vm.isSysBilling && _vm.billingType === "shebao" ? _c("a-form-item", {
    attrs: {
      label: "个人社保",
      required: ""
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      placeholder: "请输入个人社保金额",
      formatter: function formatter(value) {
        return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      parser: function parser(value) {
        return value.replace(/\$\s?|(,*)/g, "");
      },
      "default-value": _vm.GeRenSheBao
    },
    on: {
      change: _vm.onGeRenSheBaoChange
    },
    model: {
      value: _vm.GeRenSheBao,
      callback: function callback($$v) {
        _vm.GeRenSheBao = $$v;
      },
      expression: "GeRenSheBao"
    }
  })], 1) : _vm._e(), !_vm.isSysBilling && _vm.billingType === "gongjijin" ? _c("a-form-item", {
    attrs: {
      label: "单位公积金",
      required: ""
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      placeholder: "请输入单位公积金金额",
      formatter: function formatter(value) {
        return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      parser: function parser(value) {
        return value.replace(/\$\s?|(,*)/g, "");
      },
      "default-value": _vm.DanWeiGongJiJin
    },
    on: {
      change: _vm.onDanWeiGongJiJinChange
    },
    model: {
      value: _vm.DanWeiGongJiJin,
      callback: function callback($$v) {
        _vm.DanWeiGongJiJin = $$v;
      },
      expression: "DanWeiGongJiJin"
    }
  })], 1) : _vm._e(), !_vm.isSysBilling && _vm.billingType === "gongjijin" ? _c("a-form-item", {
    attrs: {
      label: "个人公积金",
      required: ""
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      placeholder: "请输入个人公积金金额",
      formatter: function formatter(value) {
        return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      parser: function parser(value) {
        return value.replace(/\$\s?|(,*)/g, "");
      },
      "default-value": _vm.GeRenGongJiJin
    },
    on: {
      change: _vm.onGeRenGongJiJinChange
    },
    model: {
      value: _vm.GeRenGongJiJin,
      callback: function callback($$v) {
        _vm.GeRenGongJiJin = $$v;
      },
      expression: "GeRenGongJiJin"
    }
  })], 1) : _vm._e(), !_vm.isSysBilling && _vm.billingType === "xinzi" ? _c("a-form-item", {
    attrs: {
      label: "计薪人数",
      required: ""
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      placeholder: "请输入计薪人数",
      formatter: function formatter(value) {
        return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      parser: function parser(value) {
        return value.replace(/\$\s?|(,*)/g, "");
      },
      "default-value": _vm.JiXinRenShu
    },
    on: {
      change: _vm.onJiXinRenShuChange
    },
    model: {
      value: _vm.JiXinRenShu,
      callback: function callback($$v) {
        _vm.JiXinRenShu = $$v;
      },
      expression: "JiXinRenShu"
    }
  })], 1) : _vm._e(), !_vm.isSysBilling && _vm.billingType === "xinzi" ? _c("a-form-item", {
    attrs: {
      label: "总实发工资",
      required: ""
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      placeholder: "请输入总实发工资",
      formatter: function formatter(value) {
        return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      parser: function parser(value) {
        return value.replace(/\$\s?|(,*)/g, "");
      },
      "default-value": _vm.ZongShiFaGongZi
    },
    on: {
      change: _vm.onZongShiFaGongZiChange
    },
    model: {
      value: _vm.ZongShiFaGongZi,
      callback: function callback($$v) {
        _vm.ZongShiFaGongZi = $$v;
      },
      expression: "ZongShiFaGongZi"
    }
  })], 1) : _vm._e(), !_vm.isSysBilling && _vm.billingType === "xinzi" ? _c("a-form-item", {
    attrs: {
      label: "总缴税",
      required: ""
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      placeholder: "请输入总缴税金额",
      formatter: function formatter(value) {
        return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      parser: function parser(value) {
        return value.replace(/\$\s?|(,*)/g, "");
      },
      "default-value": _vm.ZongJiaoShui
    },
    on: {
      change: _vm.onZongJiaoShuiChange
    },
    model: {
      value: _vm.ZongJiaoShui,
      callback: function callback($$v) {
        _vm.ZongJiaoShui = $$v;
      },
      expression: "ZongJiaoShui"
    }
  })], 1) : _vm._e(), !_vm.isSysBilling ? _c("a-form-item", {
    attrs: {
      label: "账单附件"
    }
  }, [_c("div", {
    staticStyle: {
      "padding-top": "10px"
    }
  }, [_c("Uploading", {
    attrs: {
      srcData: _vm.url,
      limit: 1
    },
    on: {
      "update:srcData": function updateSrcData($event) {
        _vm.url = $event;
      },
      "update:src-data": function updateSrcData($event) {
        _vm.url = $event;
      },
      change: _vm.onFileChange
    }
  }), _c("UploadSiteFile", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "uploadTool",
    attrs: {
      limit: 1,
      "default-value": _vm.url,
      accept: ".doc,.docx,.xls,.xlsx,.pdf,.zip,.rar,.jpg,.jpeg,.png"
    }
  })], 1)]) : _vm._e()], 1)], 1)], 2), _c("a-modal", {
    staticClass: "notice-modal",
    attrs: {
      title: "删除通知",
      visible: _vm.deleteVisible,
      width: "468px"
    },
    on: {
      ok: function ok($event) {
        return _vm.confirmDelete();
      },
      cancel: function cancel($event) {
        _vm.deleteVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "deleteline"
  }, [_c("p", [_c("a-icon", {
    attrs: {
      type: "exclamation-circle"
    }
  }), _vm._v(" 确定删除这条账单？ ")], 1)])]), _c("a-modal", {
    attrs: {
      title: "发起审批",
      visible: _vm.isMergConfirmPlan2,
      width: "500px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.mergConfirmOk2
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", [_c("a-form", {
    attrs: {
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "账单时间",
      required: ""
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "500",
      color: "#000"
    }
  }, [_vm._v(" " + _vm._s(_vm.tempItem ? _vm.tempItem.year + "年" + _vm.tempItem.month + "月" : "") + " ")])]), _c("a-form-item", {
    attrs: {
      label: "审批账单类型",
      required: ""
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "500",
      color: "#000"
    }
  }, [_vm._v(" " + _vm._s(_vm.tempItem ? _vm.tempItem.subjectType : "") + " ")])]), _c("a-form-item", {
    attrs: {
      label: "用工单位",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "请选择用工单位"
    },
    on: {
      change: _vm.handleCompyChange
    },
    model: {
      value: _vm.companyId,
      callback: function callback($$v) {
        _vm.companyId = $$v;
      },
      expression: "companyId"
    }
  }, _vm._l(_vm.comanyListGroup, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "账单审批模板",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "请选择审批模板",
      disabled: !_vm.companyId
    },
    on: {
      change: _vm.handleChange3
    },
    model: {
      value: _vm.tempTemplate,
      callback: function callback($$v) {
        _vm.tempTemplate = $$v;
      },
      expression: "tempTemplate"
    }
  }, _vm._l(_vm.templatesListGroup2, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.typeName) + " ")]);
  }), 1)], 1)], 1)], 1)], 2), _vm.total !== 0 ? _c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      width: "100%",
      "justify-content": "flex-end",
      "margin-top": "15px"
    }
  }, [_c("a-pagination", {
    attrs: {
      "page-size-options": _vm.pageSizeOptions,
      "show-size-changer": "",
      "default-current": _vm.pageNo,
      total: _vm.total
    },
    on: {
      showSizeChange: _vm.onShowSizeChange
    }
  })], 1) : _vm._e()], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };