import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import moment from "moment";
var initTimeList = {
  periodList: [{
    startPeriod: "09:00",
    endPeriod: "18:00",
    type: true,
    sort: 0,
    hasRestPeriod: false
  }],
  restPeriod: {
    startPeriod: "12:00",
    endPeriod: "13:00",
    type: false,
    sort: 0
  },
  afterWorkNeedPunch: true,
  earliestPunchTime: "15",
  earlyMinute: 1,
  isAllowLateLate: false,
  isAllowedLateEarly: false,
  lateMinute: 1,
  latestPunchTime: "60",
  offWorkLateMinute: 1,
  onWorkLateMinute: 1,
  workWeekList: ["星期一", "星期二", "星期三", "星期四", "星期五"],
  latestOnPunchTime: "15",
  earliestOffPunchTime: "60"
};
export default {
  props: {
    showCardTime: {
      type: Boolean,
      default: false
    },
    timeList: {
      type: Object,
      default: initTimeList
    },
    editIndex: {
      type: Number,
      default: -1
    },
    handleTimeList: {
      type: Function,
      default: function _default() {}
    },
    handleTimeShow: {
      type: Function,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      // editorIndex: null,
      // supers: false,
      // index: 0,
      // visible: false,
      // timeList: JSON.parse(JSON.stringify(initTimeList))
    };
  },
  methods: {
    // isHasRestPeriod() {
    //   if (this.timeList.periodList.length === 2) {
    //     this.timeList.periodList[1].hasRestPeriod = this.timeList.periodList[0].hasRestPeriod;
    //   }
    // },
    deleteNumList: function deleteNumList(item, index) {
      this.timeList.periodList.splice(index, 1);
    },
    addWorkList: function addWorkList() {
      this.timeList.periodList.push({
        startPeriod: "09:00",
        endPeriod: "18:00",
        type: true,
        sort: 0,
        hasRestPeriod: false
      });
    },
    moment: moment,
    momentTime: function momentTime(v, t) {
      if (t === "earliestPunchTime") {
        var seconds = this.timeList.earliestPunchTime * 60;
        var time = moment(v, "HH:mm").unix() - seconds;
        return moment(time * 1000).format("HH:mm");
      }
      if (t === "latestOnPunchTime") {
        var _seconds = this.timeList.latestOnPunchTime * 60;
        var _time = moment(v, "HH:mm").unix() + _seconds;
        return moment(_time * 1000).format("HH:mm");
      }
      if (t === "latestPunchTime") {
        var _seconds2 = this.timeList.latestPunchTime * 60;
        var _time2 = moment(v, "HH:mm").unix() + _seconds2;
        return moment(_time2 * 1000).format("HH:mm");
      }
    },
    /* 确认 */handleOk: function handleOk() {
      this.$emit("handleTimeList", this.timeList, this.editIndex);
      this.$emit("handleTimeShow", false);
    },
    /*  */onChange: function onChange(checkedValues) {},
    /* 上班时间 */onWorkChange: function onWorkChange(time, timeString) {
      if (timeString === "") return;
      if (timeString) this.timeList.periodList[0].startPeriod = timeString;
    },
    /* 下班时间 */onOffChange: function onOffChange(time, timeString) {
      if (timeString === "") return;
      if (timeString) this.timeList.periodList[0].endPeriod = timeString;
    },
    onWorkChange2: function onWorkChange2(time, timeString) {
      if (timeString === "") return;
      if (timeString) this.timeList.periodList[1].startPeriod = timeString;
    },
    /* 下班时间 */onOffChange2: function onOffChange2(time, timeString) {
      if (timeString === "") return;
      if (timeString) this.timeList.periodList[1].endPeriod = timeString;
    },
    /* 时间类型 */onRestChange: function onRestChange(time, timeString) {
      if (timeString === "") return;
      this.timeList.restPeriod.startPeriod = timeString;
    },
    offRestChange: function offRestChange(time, timeString) {
      if (timeString === "") return;
      this.timeList.restPeriod.endPeriod = timeString;
    }
  }
};