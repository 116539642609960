import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "预览",
      destroyOnClose: true,
      footer: null,
      width: 900
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("close");
      }
    },
    model: {
      value: _vm.modelVisible,
      callback: function callback($$v) {
        _vm.modelVisible = $$v;
      },
      expression: "modelVisible"
    }
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "ql-editor-wrapper",
    staticStyle: {
      "min-height": "500px"
    }
  }, [_c("div", {
    staticClass: "ql-editor-content ql-editor-content2"
  }, [_c("div", {
    staticClass: "editor-main editor-main2"
  }, [_c("div", {
    staticClass: "sealnew-name",
    style: {
      width: "".concat(_vm.a4_width, "px")
    }
  }, [_c("div", {
    staticClass: "left",
    staticStyle: {
      flex: "1"
    }
  }, [_vm._v(_vm._s(_vm.name) + ".pdf")]), _c("div", {
    staticStyle: {
      width: "160px",
      "flex-shrink": "0",
      "text-align": "right"
    }
  }, [_c("a-button", {
    staticStyle: {
      width: "32px",
      padding: "0",
      "margin-right": "10px"
    },
    on: {
      click: _vm.pageUp
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.pageCount > 0 ? _vm.currentPage : 0) + "/" + _vm._s(_vm.pageCount || 0) + " "), _c("a-button", {
    staticStyle: {
      width: "32px",
      padding: "0",
      "margin-left": "10px"
    },
    on: {
      click: _vm.pageDown
    }
  }, [_c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1)], 1)]), _c("div", {
    style: {
      width: "".concat(_vm.a4_width, "px"),
      position: "relative",
      margin: "0 auto",
      boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.1)"
    }
  }, [_vm.src ? _c("pdf", {
    ref: "pdf",
    attrs: {
      src: _vm.src,
      page: _vm.currentPage
    },
    on: {
      "num-pages": function numPages($event) {
        _vm.pageCount = $event;
      },
      "page-loaded": function pageLoaded($event) {
        _vm.currentPage = $event;
      },
      error: _vm.pdfError
    }
  }) : _vm._e(), _vm._l(_vm.dropList, function (item) {
    return [item.page === _vm.currentPage ? _c("vue-draggable-resizable", {
      key: item.id,
      ref: "".concat(item.type).concat(item.id),
      refInFor: true,
      attrs: {
        draggable: false,
        parent: true,
        resizable: false,
        w: _vm.seal_size,
        h: item.type === "seal" ? _vm.seal_size : 50,
        x: item.x,
        y: item.y,
        "class-name": "editor-main-drag"
      }
    }, [item.type === "seal" ? _c("div", [_c("img", {
      attrs: {
        src: require("@/assets/img/seal-circle.png"),
        alt: ""
      }
    })]) : _vm._e(), item.type === "sign" ? _c("div", [_c("div", {
      staticClass: "editor-main-drag-sign"
    }, [_vm._v("电子签名")])]) : _vm._e(), item.type === "holder" ? _c("div", [_c("div", {
      staticClass: "editor-main-drag-sign lp"
    }, [_vm._v("法人章")])]) : _vm._e()]) : _vm._e()];
  })], 2)])])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };