import FormJson from "./FormJson"; // 引入FormJson数据
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
import { retrieveReq, createReq, deleteReq, deleteBatchReq, updateReq, recharge as _recharge, openContract as _openContract, disableContract as _disableContract } from "./api"; // 引入接口文件

import rechargeList from "./components";
var curdMixin = new XqCurdMixin({
  createReq: createReq,
  retrieveReq: retrieveReq,
  updateReq: updateReq,
  deleteReq: deleteReq,
  deleteBatchReq: deleteBatchReq,
  FormJson: FormJson,
  tableConfigKey: "table"
});
export default {
  components: {
    rechargeList: rechargeList
  },
  mixins: [curdMixin],
  data: function data() {
    return {
      pageSize: 20,
      rechargeModalVisible: false,
      rechargeListModalVisible: false,
      record: undefined,
      quota: undefined,
      siteId: undefined
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {}
      };
    },
    createParams: function createParams() {
      return {};
    },
    updateParams: function updateParams() {
      return {};
    }
  },
  methods: {
    formatValues: function formatValues(values) {
      return values;
    },
    formatDataSource: function formatDataSource(record) {
      return record;
    },
    deleteConfig: function deleteConfig(data) {
      var _this = this;
      deleteReq(data.siteId, data.id).then(function (res) {
        _this.$message.success("删除成功!");
        _this.retrieveRecord();
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this.$message.error("删除失败!");
      });
    },
    // 开启租户合同
    openContract: function openContract(data) {
      var _this2 = this;
      _openContract(data.siteId, data.id).then(function (res) {
        _this2.$message.success("已启用");
        _this2.retrieveRecord();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 关闭租户合同
    disableContract: function disableContract(data) {
      var _this3 = this;
      _disableContract(data.siteId, data.id).then(function (res) {
        _this3.$message.success("已禁用");
        _this3.retrieveRecord();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 充值接口
    recharge: function recharge(data, quota) {
      var _this4 = this;
      _recharge(data.siteId, data.id, quota).then(function (res) {
        _this4.$message.success("充值成功");
        _this4.retrieveRecord();
        _this4.quota = undefined;
        _this4.rechargeModalVisible = false;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    showRechargeModal: function showRechargeModal(data) {
      this.record = data;
      this.rechargeModalVisible = true;
    },
    showRechargeListModal: function showRechargeListModal(data) {
      this.siteId = data.siteId;
      this.rechargeListModalVisible = true;
    },
    rechargeModalCancel: function rechargeModalCancel() {
      this.rechargeModalVisible = false;
    },
    rechargeListModalCancel: function rechargeListModalCancel() {
      this.rechargeListModalVisible = false;
    },
    rechargeModalOk: function rechargeModalOk() {
      this.recharge(this.record, this.quota);
    }
  }
};