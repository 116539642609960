import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white relative",
    staticStyle: {
      "min-height": "calc(100vh - 112px)"
    }
  }, [_c("div", {
    staticClass: "ta-center fs16 fw500 p16 border-bottom relative"
  }, [_c("span", [_vm._v(_vm._s(_vm.id ? "编辑" : "新增") + "工资表模板")]), _c("div", {
    staticClass: "absolute left-0 top-0 bottom-0 ml16 pointer flex-center",
    staticStyle: {
      color: "rgba(192, 192, 192, 1)"
    },
    on: {
      click: function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c("a-icon", {
    staticClass: "fs24 mr8",
    attrs: {
      type: "left"
    }
  })], 1)]), _vm.isEditing ? [_c("div", {
    staticClass: "box-shadow p24 flex-center-center m24 br4"
  }, [_c("a-steps", {
    attrs: {
      current: _vm.currentStep
    }
  }, [_c("a-step", {
    attrs: {
      title: "设置工资项"
    }
  }), _c("a-step", {
    attrs: {
      title: "设置统计项"
    }
  }), _c("a-step", {
    attrs: {
      title: "创建工资表"
    }
  })], 1)], 1), _c("div", [_c("EditGongzi", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStep === 0,
      expression: "currentStep === 0"
    }],
    ref: "gongzi",
    attrs: {
      id: _vm.id,
      wageItemLst: _vm.wageItemLst
    },
    on: {
      "update:wageItemLst": function updateWageItemLst($event) {
        _vm.wageItemLst = $event;
      },
      "update:wage-item-lst": function updateWageItemLst($event) {
        _vm.wageItemLst = $event;
      },
      cancel: _vm.onStepCancel,
      next: function next($event) {
        return _vm.onStepChange(1);
      }
    }
  }), _c("EditTongji", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStep === 1,
      expression: "currentStep === 1"
    }],
    ref: "tongji",
    attrs: {
      id: _vm.id,
      wageItemLst: _vm.wageItemLst,
      statItemLst: _vm.statItemLst
    },
    on: {
      "update:statItemLst": function updateStatItemLst($event) {
        _vm.statItemLst = $event;
      },
      "update:stat-item-lst": function updateStatItemLst($event) {
        _vm.statItemLst = $event;
      },
      prev: function prev($event) {
        return _vm.onStepChange(0);
      },
      next: function next($event) {
        return _vm.onStepChange(2);
      },
      cancel: _vm.onStepCancel
    }
  }), _c("EditChuangjian", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.currentStep === 2,
      expression: "currentStep === 2"
    }],
    ref: "chuangjian",
    attrs: {
      id: _vm.id,
      name: _vm.name,
      decimalPlaces: _vm.decimalPlaces,
      wageItemLst: _vm.wageItemLst,
      statItemLst: _vm.statItemLst
    },
    on: {
      "update:name": function updateName($event) {
        _vm.name = $event;
      },
      "update:decimalPlaces": function updateDecimalPlaces($event) {
        _vm.decimalPlaces = $event;
      },
      "update:decimal-places": function updateDecimalPlaces($event) {
        _vm.decimalPlaces = $event;
      },
      prev: function prev($event) {
        return _vm.onStepChange(1);
      },
      save: _vm.onSave,
      del: _vm.onItemDelete,
      cancel: _vm.onStepCancel
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "创建模板"
    },
    on: {
      cancel: _vm.doCancel,
      ok: _vm.doCreate
    },
    model: {
      value: _vm.createVisible,
      callback: function callback($$v) {
        _vm.createVisible = $$v;
      },
      expression: "createVisible"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "模板名称",
      required: "",
      wrapperCol: {
        span: 18
      },
      labelCol: {
        span: 6
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "尾数处理",
      required: "",
      wrapperCol: {
        span: 18
      },
      labelCol: {
        span: 6
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      options: _vm.decimalPlacesOptions,
      placeholder: "请选择"
    },
    model: {
      value: _vm.decimalPlaces,
      callback: function callback($$v) {
        _vm.decimalPlaces = $$v;
      },
      expression: "decimalPlaces"
    }
  })], 1)], 1)] : _vm.templateItems && _vm.templateItems.length > 0 ? _c("div", {
    staticClass: "ph48 pv24"
  }, [_vm._m(0), _c("div", {
    staticClass: "mt24"
  }, [_vm._v("模板名称："), _c("a-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      value: _vm.name,
      disabled: ""
    }
  })], 1), _c("div", {
    staticClass: "mt16"
  }, [_vm._v("尾数处理："), _c("a-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      value: _vm.decimalPlaces,
      options: _vm.decimalPlacesOptions,
      disabled: ""
    }
  })], 1), _c("div", {
    staticClass: "ta-right"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.isEditing = true;
      }
    }
  }, [_vm._v("编辑")])], 1), _c("div", {
    staticClass: "mt24"
  }, [_c("div", {
    staticClass: "ph24 border-bottom",
    staticStyle: {
      background: "rgba(250, 250, 250, 1)",
      "line-height": "48px"
    }
  }, [_vm._v("名称")]), _vm._l(_vm.templateItems, function (item) {
    return _c("div", {
      key: item.name,
      staticClass: "ph24 border-bottom",
      staticStyle: {
        "line-height": "48px"
      }
    }, [_vm._v(_vm._s(item.name))]);
  })], 2)]) : _vm._e()], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bottom pb16"
  }, [_c("div", {
    staticClass: "fs16 fw500"
  }, [_vm._v("使用指南")]), _c("div", {
    staticClass: "mt8"
  }, [_vm._v("设置工资表模板，即对应企业计薪时的月工资表头项目。每位员工只适用一个模板。")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };