import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
// type有值代表用工单位分别为4和 5 代表入职和待入职。   type没有代表租户 三个tabs 展示typeKey代表
var formItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 10
  }
};
var formTailLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 10,
    offset: 4
  }
};
import { customTemplates, customTemplatesOpen, customTemplatesDel, issueTemplate } from "./api";
import { findCompanyGroup as _findCompanyGroup } from "@/api/company/contract";
export default {
  data: function data() {
    return {
      replaceFields2: {
        children: "companyList",
        title: "name",
        key: "uuid",
        value: "uuid"
      },
      companyList: [],
      dataList: [],
      templateEntities: [],
      formItemLayout: formItemLayout,
      formTailLayout: formTailLayout,
      form: this.$form.createForm(this, {
        name: "dynamic_rule"
      }),
      visible: false,
      confirmLoading: false,
      flatDataList: [],
      entities: [],
      typeKey: "1",
      name: "",
      total: 0,
      current: 1,
      spinning: false
    };
  },
  computed: {
    type: function type() {
      return this.$route.params.type;
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.typeKey = this.$route.query.openTab ? this.$route.query.openTab : "1";
    this.findCompanyGroup();
    this.customTemplatesFun();
  },
  methods: {
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    // 递归处理为扁平数组
    flatten: function flatten(list) {
      var _this = this;
      if (list !== null) {
        list.forEach(function (item) {
          _this.flatDataList.push(item);
          _this.flatten(item.companyList);
        });
      }
    },
    addTreeSlot: function addTreeSlot(data) {
      var _this2 = this;
      if (!data) {
        return [];
      }
      data.forEach(function (el) {
        el.scopedSlots = {
          title: "custom"
        };
        _this2.addTreeSlot(el.companyList);
      });
      return data;
    },
    treeSelectChange: function treeSelectChange(value, label, extra) {
      // console.log(value);
    },
    // 用工单位
    treeSearch: function treeSearch(e) {
      this.findCompanyGroup(e);
    },
    findCompanyGroup: function findCompanyGroup(e) {
      var _this3 = this;
      _findCompanyGroup({
        name: e || null
      }).then(function (res) {
        _this3.dataList = _this3.addTreeSlot(res.data);
        _this3.flatten(_this3.dataList);
        _this3.form.setFieldsValue({
          companyList: _this3.form.getFieldValue("companyList") ? JSON.parse(JSON.stringify(_this3.form.getFieldValue("companyList"))) : null
        });
        // 每次查询树形数据后需要把树选择组件绑定的companyList深拷贝
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    editTemplates: function editTemplates(i) {
      if (this.type) {
        this.$router.push({
          path: "/staff/edit/".concat(i.moduleCode === "checkIn" ? "template_ydj" : "newTemplate"),
          query: {
            name: i.name,
            id: i.id,
            isDefault: i.isDefault,
            type: i.type,
            statusSHow: "3",
            status: "edit",
            moduleCode: i.moduleCode
          }
        });
      } else {
        this.$router.push({
          path: "/contractAdmin/ruzhi/".concat(i.moduleCode === "checkIn" ? "template_ydj" : "newTemplate"),
          query: {
            name: i.name,
            id: i.id,
            isDefault: i.isDefault,
            type: i.type,
            statusSHow: "2",
            status: "edit",
            moduleCode: i.moduleCode,
            openTab: this.typeKey
          }
        });
      }
    },
    // 下发模板
    issueTemplate: function issueTemplate() {
      var _this4 = this;
      this.visible = true;
      var moduleCode = null;
      var type = null;
      if (this.type) {
        moduleCode = this.type === "4" ? "onJob" : "waitJob";
        type = 3;
      } else {
        moduleCode = this.typeKey === "1" ? "onJob" : this.typeKey === "2" ? "waitJob" : "checkIn";
        type = 2;
      }
      var data = {
        companyId: this.type ? this.$store.state.hr.companyId : null,
        type: type,
        moduleCode: moduleCode,
        "orders[0].property": "gmtCreate",
        "orders[0].desc": true
      };
      customTemplates(Object.assign(data, {
        pageNo: 1,
        pageSize: 999
      })).then(function (res) {
        _this4.templateEntities = res.data.entities;
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 下发模板 确定
    handleOk: function handleOk() {
      var _this5 = this;
      this.form.validateFields(function (err, values) {
        if (!err) {
          var companyArr = [];
          values.companyList.forEach(function (item) {
            _this5.flatDataList.forEach(function (item2, index2) {
              if (item2.uuid && item2.uuid === item.value) {
                companyArr.push({
                  subjectId: item2.id,
                  subjectName: item2.name
                });
              }
            });
          });
          var data = {
            templateId: values.templateId,
            companyIdList: _toConsumableArray(new Set(companyArr.map(function (z) {
              return z.subjectId;
            })))
          };
          // if (data.companyIdList.length > 5) {
          //   this.$message.error("单次下发模版时，最多可选择5条模版!");
          //   return;
          // }
          _this5.confirmLoading = true;
          issueTemplate(data).then(function (res) {
            _this5.$message.success(res.msg);
            _this5.confirmLoading = false;
            _this5.visible = false;
          }).catch(function (err) {
            _this5.confirmLoading = false;
            console.log(err);
          });
        }
      });
    },
    // 删除
    customTemplatesDel: function customTemplatesDel(id) {
      var that = this;
      this.$confirm({
        title: "确定要删除?",
        onOk: function onOk() {
          that.customTemplatesDelFun(id);
        },
        onCancel: function onCancel() {}
      });
    },
    customTemplatesDelFun: function customTemplatesDelFun(id) {
      var _this6 = this;
      customTemplatesDel(id).then(function (res) {
        _this6.$message.success(res.msg);
        _this6.customTemplatesFun();
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 启用
    isOpenChange: function isOpenChange(val, event) {
      var _this7 = this;
      if (!val) {
        this.$message.error("请先选择一个模板开启");
        this.customTemplatesFun();
        return;
      }
      var data = {
        companyId: this.type ? this.$store.state.hr.companyId : "site",
        templateId: event.id
      };
      customTemplatesOpen(data).then(function (res) {
        _this7.$message.success(res.msg);
        _this7.customTemplatesFun();
      }).catch(function (err) {
        console.log(err);
        _this7.customTemplatesFun();
      });
    },
    customTemplatesFun: function customTemplatesFun() {
      var _this8 = this;
      // onJob 入职 waitJob,待入职 checkIn预登记
      var moduleCode = null;
      var type = null;
      if (this.type) {
        moduleCode = this.type === "4" ? "onJob" : "waitJob";
        type = 3;
      } else {
        moduleCode = this.typeKey === "1" ? "onJob" : this.typeKey === "2" ? "waitJob" : "checkIn";
        type = 2;
      }
      var data = {
        companyId: this.type ? this.$store.state.hr.companyId : null,
        type: type,
        pageSize: 8,
        name: this.name,
        pageNo: this.current,
        moduleCode: moduleCode,
        "orders[0].property": "gmtCreate",
        "orders[0].desc": true
      };
      this.spinning = true;
      customTemplates(data).then(function (res) {
        _this8.spinning = false;
        _this8.entities = res.data.entities;
        _this8.total = res.data.entityCount;
      }).catch(function (err) {
        console.log(err);
      });
    },
    onChange: function onChange(pageNumber) {
      this.current = pageNumber;
      this.customTemplatesFun();
    },
    callback: function callback(key) {
      this.current = 1;
      this.typeKey = key;
      this.customTemplatesFun();
    },
    onSearch: function onSearch(value) {
      this.current = 1;
      this.customTemplatesFun();
    },
    newTemplate: function newTemplate() {
      var moduleCode = null;
      var type = null;
      if (this.type) {
        moduleCode = this.type === "4" ? "onJob" : "waitJob";
        type = 3;
      } else {
        moduleCode = this.typeKey === "1" ? "onJob" : this.typeKey === "2" ? "waitJob" : "checkIn";
        type = 2;
      }
      if (this.type) {
        this.$router.push({
          path: "/staff/edit/".concat(moduleCode === "checkIn" ? "template_ydj" : "newTemplate"),
          query: {
            type: type,
            statusSHow: "3",
            status: "add",
            moduleCode: moduleCode
          }
        });
      } else {
        this.$router.push({
          path: "/contractAdmin/ruzhi/".concat(moduleCode === "checkIn" ? "template_ydj" : "newTemplate"),
          query: {
            type: type,
            status: "add",
            moduleCode: moduleCode,
            statusSHow: "2",
            openTab: this.typeKey
          }
        });
      }
    }
  }
};