import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.timers.js";
import { formatDate as _formatDate } from "@/utils/index";
import { socTypeSource } from "./../funcs";
import { selectEmpSocBriefly, EmpSocInfoByEmpId, selectEmpSocInfoByEmpIdCount, Enum } from "./api";
export default {
  data: function data() {
    return {
      selectedRowKeys: [],
      empId: "",
      PersonalData: {},
      loading: false,
      empSocStatusOptions: [],
      // 社保状态枚举
      payTypeOptions: [],
      // 缴费类型枚举
      progTypeOptions: [],
      // 参保方案枚举
      // 查询参数
      queryParams: {
        companyId: this.$store.state.hr.companyId,
        pageNo: 1,
        pageSize: 10,
        empId: ""
      },
      topData: [{
        title: "个人社保累计",
        num: 0
      }, {
        title: "单位社保累计",
        num: 0
      }, {
        title: "个人公积金累计",
        num: 0
      }, {
        title: "单位公积金累计",
        num: 0
      }, {
        title: "合计",
        num: 0
      }],
      // 总条数
      total: 0,
      // 查询社保名单表格数据
      tableData: [],
      disable: true
    };
  },
  computed: {
    postTypeLst: function postTypeLst() {
      return this.$store.state.enums.postTypeLst;
    }
  },
  created: function created() {
    this.empId = this.$route.params.empId;
    this.queryParams.empId = this.$route.params.empId;
    if (this.$route.query.disable) {
      this.disable = this.$route.query.disable === "true";
    }
    this.getEnum();
    this.getSelectEmpSocListCount();
  },
  filters: {
    // 过滤枚举
    filtersOptions: function filtersOptions(a, b) {
      var data = b.filter(function (item) {
        return item.value === a;
      });
      return data.length > 0 ? data[0].label : "-";
    }
  },
  methods: {
    getPostTypeNew: function getPostTypeNew(val) {
      return socTypeSource[val]["label"];
    },
    formatDate: function formatDate(text) {
      var time = _formatDate(text);
      var index = time.lastIndexOf("-");
      return time.substr(0, index);
    },
    material: function material() {
      if (this.disable) {
        var data = {
          queryType: 1,
          companyId: this.$store.state.hr.companyId,
          pageNo: 1,
          pageSize: 10,
          empId: this.empId
        };
        this.$router.push({
          path: "/staff/detail",
          query: {
            id: this.empId,
            isDelete: true,
            prevpage: location.hash.slice(1)
          }
        });
      } else {
        this.$message.error("员工已被删除");
      }
    },
    goback: function goback() {
      console.log(this.$route);
      if (this.$route.query.prevpage) {
        this.$router.push({
          path: this.$route.query.prevpage,
          query: {
            id: this.$route.query.id,
            month: this.$route.query.month,
            year: this.$route.query.year
          }
        });
      } else {
        this.$router.go(-1);
      }
    },
    onExport: function onExport() {
      this.$exportFile("/hr/api/v1/empSocTransform/empSocDetailsExport?companyId=".concat(this.$store.state.hr.companyId, "&empId=").concat(this.empId, "&empSocInfoIdList=").concat(this.selectedRowKeys));
    },
    tableChange: function tableChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 获取枚举请求
    getEnum: function getEnum() {
      var _this = this;
      Enum(this.$store.state.hr.companyId).then(function (res) {
        _this.payTypeOptions = [];
        _this.progTypeOptions = [{
          value: 999,
          label: "无参保方案"
        }];
        _this.empSocStatusOptions = [];
        if (Object.keys(res.data.socPayCostTypeMap).length > 0) {
          for (var key in res.data.socPayCostTypeMap) {
            _this.payTypeOptions.push({
              value: res.data.socPayCostTypeMap[key],
              label: key
            });
          }
        }
        if (Object.keys(res.data.empSocStatusMap).length > 0) {
          for (var _key in res.data.empSocStatusMap) {
            _this.empSocStatusOptions.push({
              value: res.data.empSocStatusMap[_key],
              label: _key
            });
          }
        }
        if (Object.keys(res.data.socProgMap).length > 0) {
          for (var _key2 in res.data.socProgMap) {
            _this.progTypeOptions.push({
              value: res.data.socProgMap[_key2],
              label: _key2
            });
          }
        }
        _this.getPersonalData();
        _this.getList();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 查询社保名单顶部数量统计
    getSelectEmpSocListCount: function getSelectEmpSocListCount() {
      var _this2 = this;
      selectEmpSocInfoByEmpIdCount({
        companyId: this.$store.state.hr.companyId,
        empId: this.empId
      }).then(function (res) {
        _this2.topData[0].num = res.data.personalSocAmount || 0;
        _this2.topData[1].num = res.data.companySocAmount || 0;
        _this2.topData[2].num = res.data.personalPfAmount || 0;
        _this2.topData[3].num = res.data.companyPfAmount || 0;
        _this2.topData[4].num = res.data.allAmount || 0;
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this2.$message.error("失败!");
      });
    },
    // 个人资料
    getPersonalData: function getPersonalData() {
      var _this3 = this;
      selectEmpSocBriefly({
        empId: this.empId
      }).then(function (res) {
        _this3.PersonalData = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this3.$message.error("失败!");
      });
    },
    /** 查询社保名单列表 */getList: function getList() {
      var _this4 = this;
      this.loading = true;
      EmpSocInfoByEmpId(this.queryParams).then(function (res) {
        _this4.tableData = res.data.entities;
        _this4.total = res.data.entityCount;
        setTimeout(function () {
          _this4.loading = false;
        }, 500);
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this4.$message.error("查询社保名单列表失败!");
        setTimeout(function () {
          _this4.loading = false;
        }, 500);
      });
    },
    showSizeChange: function showSizeChange(current, size) {
      this.queryParams.pageNo = 1;
      this.queryParams.pageSize = size;
    },
    // 分页变化
    onChange: function onChange(pageNumber) {
      this.queryParams.pageNo = pageNumber;
      this.getList();
    }
  }
};