var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack importsData-box"
  }, [_c("PageHeader", {
    staticClass: "PageHeaders",
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        _vm.$router.go(-1);
        _vm.$store.commit("check/componentName", "imports");
      }
    }
  }, [_vm._v(" 导入考勤数据 ")]), _vm.current === 0 ? _c("upload", {
    on: {
      onAction: _vm.onAction
    }
  }) : _vm._e(), _vm.current === 1 ? _c("preview", {
    attrs: {
      vJson: _vm.previewJson
    },
    on: {
      onAction: _vm.onAction
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };