import "core-js/modules/es.function.name.js";
var render = function render() {
  var _this = this;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "p24 page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "flex-center-between pb12"
  }, _vm._l(_vm.topData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "wb19 border_hover h75 color_fa border_dashed top_warp",
      class: _vm.active === index ? "border_active" : "",
      staticStyle: {
        "padding-left": "24px"
      },
      on: {
        click: function click($event) {
          return _vm.onTopTabs(item, index);
        }
      }
    }, [_c("img", {
      staticStyle: {
        width: "32px",
        height: "32px"
      },
      attrs: {
        src: item.url,
        alt: item.title
      }
    }), _c("div", [_c("div", {
      staticClass: "top_title"
    }, [_vm._v(_vm._s(item.title))]), _c("CountTo", {
      staticClass: "top_number",
      attrs: {
        startVal: 0,
        endVal: item.num,
        duration: 2000
      }
    }), _c("div")], 1)]);
  }), 0), _c("div", {
    staticClass: "pb12 flex-center-between"
  }, [_c("a-space", [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "搜索姓名/身份证",
      defaultValue: _vm.queryParams.nameOrIdCard ? _vm.queryParams.nameOrIdCard : undefined,
      "allow-clear": ""
    },
    on: {
      search: function search() {
        _this.queryParams.pageNo = 1;
        _this.getList();
      }
    },
    model: {
      value: _vm.queryParams.nameOrIdCard,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "nameOrIdCard", $$v);
      },
      expression: "queryParams.nameOrIdCard"
    }
  }), _c("a-select", {
    staticStyle: {
      "min-width": "130px",
      width: "130px"
    },
    attrs: {
      title: "",
      placeholder: "选择人员状态",
      "allow-clear": "",
      defaultValue: _vm.rStatus
    },
    on: {
      change: _vm.handleProgName2
    }
  }, _vm._l(_vm.liststatus, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1), _c("a-popover", {
    attrs: {
      trigger: "click",
      placement: "bottom"
    },
    model: {
      value: _vm.searchVisible,
      callback: function callback($$v) {
        _vm.searchVisible = $$v;
      },
      expression: "searchVisible"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("div", {
    staticStyle: {
      width: "900px"
    }
  }, [_c("a-form-model", {
    ref: "searchRef",
    attrs: {
      model: _vm.form
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "社保状态"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择社保状态",
      defaultValue: _vm.form.empSocStatus,
      options: _vm.socTypeSource
    },
    model: {
      value: _vm.form.empSocStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "empSocStatus", $$v);
      },
      expression: "form.empSocStatus"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "医保状态"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择医保状态",
      defaultValue: _vm.form.empMeStatus,
      options: _vm.socTypeSource
    },
    model: {
      value: _vm.form.empMeStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "empMeStatus", $$v);
      },
      expression: "form.empMeStatus"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "公积金状态"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择公积金状态",
      defaultValue: _vm.form.empPfStatus,
      options: _vm.socTypeSource
    },
    model: {
      value: _vm.form.empPfStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "empPfStatus", $$v);
      },
      expression: "form.empPfStatus"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "人员类型"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择人员类型",
      defaultValue: _vm.form.postType,
      options: _vm.postTypeLst
    },
    model: {
      value: _vm.form.postType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "postType", $$v);
      },
      expression: "form.postType"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "参保方案"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      defaultValue: _vm.form.socProgId,
      options: _vm.progTypeOptions,
      placeholder: "请选择参保方案"
    },
    model: {
      value: _vm.form.socProgId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "socProgId", $$v);
      },
      expression: "form.socProgId"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "参保城市"
    }
  }, [_c("AreaPicker", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      level: 1,
      "default-value": _vm.form.socCityId
    },
    on: {
      change: function change($event) {
        return _vm.handleCity($event);
      }
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "参保时间"
    }
  }, [_c("a-date-picker", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      defaultValue: _vm.form.empSocDate,
      format: "YYYY-MM-DD"
    },
    on: {
      change: function change(date, dateString) {
        return _vm.form.empSocDate = dateString;
      }
    },
    model: {
      value: _vm.form.empSocDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "empSocDate", $$v);
      },
      expression: "form.empSocDate"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "停保时间"
    }
  }, [_c("a-date-picker", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      defaultValue: _vm.form.stopSocDate,
      format: "YYYY-MM-DD"
    },
    on: {
      change: function change(date, dateString) {
        return _vm.form.stopSocDate = dateString;
      }
    },
    model: {
      value: _vm.form.stopSocDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "stopSocDate", $$v);
      },
      expression: "form.stopSocDate"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "封存时间"
    }
  }, [_c("a-date-picker", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      defaultValue: _vm.form.sealedDate,
      format: "YYYY-MM-DD"
    },
    on: {
      change: function change(date, dateString) {
        return _vm.form.sealedDate = dateString;
      }
    },
    model: {
      value: _vm.form.sealedDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sealedDate", $$v);
      },
      expression: "form.sealedDate"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "社保账号"
    }
  }, [_c("SelectWithInputAdd", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      editable: "",
      allowClear: "",
      mode: "select",
      dictCode: "1220",
      placeholder: "社保账号"
    },
    model: {
      value: _vm.socAccount,
      callback: function callback($$v) {
        _vm.socAccount = $$v;
      },
      expression: "socAccount"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "医保账号"
    }
  }, [_c("SelectWithInputAdd", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      editable: "",
      allowClear: "",
      mode: "select",
      dictCode: "1221",
      placeholder: "医保账号"
    },
    model: {
      value: _vm.meAccount,
      callback: function callback($$v) {
        _vm.meAccount = $$v;
      },
      expression: "meAccount"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "公积金账号"
    }
  }, [_c("SelectWithInputAdd", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      editable: "",
      allowClear: "",
      mode: "select",
      dictCode: "1222",
      placeholder: "公积金账号"
    },
    model: {
      value: _vm.pfAccount,
      callback: function callback($$v) {
        _vm.pfAccount = $$v;
      },
      expression: "pfAccount"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "签约主体"
    }
  }, [_c("SelectWithInputAdd", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      editable: "",
      allowClear: "",
      mode: "select",
      dictCode: "1223",
      placeholder: "签约主体"
    },
    model: {
      value: _vm.contractSubject,
      callback: function callback($$v) {
        _vm.contractSubject = $$v;
      },
      expression: "contractSubject"
    }
  })], 1)], 1)], 1)], 1)], 1), _c("div", {
    staticClass: "h48 boder_top mt12 flex-center-between align_center"
  }, [_c("a-button", {
    attrs: {
      type: "link",
      icon: "reload"
    },
    on: {
      click: _vm.OnsearchRef
    }
  }, [_vm._v(" 重置 ")]), _c("div", [_c("a-space", [_c("a-button", {
    on: {
      click: function click($event) {
        _vm.searchVisible = false;
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click() {
        _this.searchVisible = false;
        _this.queryParams.pageNo = 1;
        _this.getList();
      }
    }
  }, [_vm._v(" 确认 ")])], 1)], 1)], 1)]), _c("a-button", {
    attrs: {
      icon: "filter"
    }
  })], 2)], 1), _c("a-space", [_vm.tableData ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onExport
    }
  }, [_vm._v(" 导出 ")]) : _vm._e()], 1)], 1), _c("a-table", {
    attrs: {
      rowKey: "empId",
      loading: _vm.loading,
      "data-source": _vm.tableData,
      scroll: {
        x: 2500,
        y: 1000
      },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.tableChange,
        getCheckboxProps: function getCheckboxProps(record) {
          return {
            props: {
              disabled: record.empSocStatus === 0 || record.empSocStatus === 6 ? false : true,
              // Column configuration not to be checked
              name: record.name
            }
          };
        }
      },
      pagination: false
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "姓名",
      width: 130,
      ellipsis: true,
      fixed: "left"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          staticStyle: {
            position: "relative"
          }
        }, [scope.empStatus === 4 ? _c("img", {
          staticStyle: {
            position: "absolute",
            top: "-21px",
            left: "-8px",
            width: "45px"
          },
          attrs: {
            src: require("../../../../assets/shebao/离职.png"),
            alt: ""
          }
        }) : _vm._e()]), _vm._v(" " + _vm._s(scope.empName || "-") + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "身份证号",
      width: 200,
      ellipsis: true,
      fixed: "left"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.idCard || "-") + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "社保状态",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.getPostTypeNew(scope.empSocStatus)))])];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "医保状态",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.getPostTypeNew(scope.empMeStatus)))])];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "公积金状态",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.getPostTypeNew(scope.empPfStatus)))])];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "人员类型",
      width: 100,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm._f("filtersOptions")(scope.postType, _vm.postTypeLst)) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "参保城市",
      width: 100,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(scope.socCityName || "-") + " ")]), _vm._v(" " + _vm._s(scope.socCityName || "-") + " ")], 2)];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "社保账号",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.socAccount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "医保账号",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.meAccount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "公积金账号",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.pfAccount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "签约主体",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.contractSubject) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "参保方案",
      width: 100,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.socProgName || "-") + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "参保时间",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(scope.socDate) || "-") + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "停保时间",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(scope.stopSocDate) || "-") + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "封存时间",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(scope.sealedDate) || "-") + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "单位社保",
      width: 100,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.companySocAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "个人社保",
      width: 100,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.personalSocAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "单位公积金",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.companyPfAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "个人公积金",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.personalPfAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "社保总计",
      width: 100,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.socAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "公积金总计",
      width: 120,
      ellipsis: true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.pfAmount) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "操作",
      width: 80,
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.detailed(scope);
            }
          }
        }, [_vm._v(" 详情 ")])];
      }
    }])
  })], 1), _c("div", {
    staticClass: "ta-right mt16"
  }, [_c("a-pagination", {
    attrs: {
      current: _vm.queryParams.pageNo,
      "show-quick-jumper": "",
      "show-size-changer": "",
      "default-current": 1,
      pageSizeOptions: ["10", "20", "150", "200"],
      total: _vm.total,
      "show-total": function showTotal(total) {
        return "\u5171 ".concat(total, " \u6761");
      }
    },
    on: {
      change: _vm.onChange,
      showSizeChange: _vm.showSizeChange
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "自定义表头",
      visible: _vm.visible,
      maskClosable: false,
      "confirm-loading": _vm.confirmLoading,
      width: 800
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, [_c("Transfer", {
    ref: "transfer",
    attrs: {
      defaultSelectedKeys: _vm.defaultSelectedKeys,
      checkedKey: _vm.checkedKey,
      transferData: _vm.selectAllHeadData
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "数据同步",
      visible: _vm.isCloseMethod,
      width: "620px",
      "destroy-on-close": true,
      "mask-closable": false
    },
    on: {
      cancel: _vm.cancelSyn
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.cancelSyn
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.spinning
    },
    on: {
      click: _vm.SyncHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.spinning ? "数据同步中" : "确认同步") + " ")])], 1), _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticStyle: {
      "padding-left": "100px"
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "数据同步时间",
      required: ""
    }
  }, [_c("a-range-picker", {
    ref: "date",
    staticStyle: {
      width: "60%",
      "margin-left": "20px"
    },
    attrs: {
      placeholder: ["开始月份", "结束月份"],
      format: "YYYY-MM",
      value: _vm.value,
      mode: _vm.mode2,
      allowClear: false
    },
    on: {
      panelChange: _vm.handlePanelChange2
    }
  }, [_c("template", {
    slot: "renderExtraFooter"
  }, [_c("a-button", {
    key: "submit",
    staticStyle: {
      "margin-left": "460px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.dateOk
    }
  }, [_vm._v(" 确定 ")])], 1)], 2)], 1), _c("a-form-item", {
    attrs: {
      label: "选择城市",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "60%",
      "margin-left": "20px"
    },
    attrs: {
      "default-value": _vm.defaultFangan === 0 ? "请选择社保所在城市" : _vm.defaultFangan,
      placeholder: "请选择社保所在城市"
    },
    on: {
      change: _vm.chooseProgName
    }
  }, _vm._l(_vm.progNameList, function (item) {
    return _c("a-select-option", {
      key: item.areaId
    }, [_vm._v(_vm._s(item.areaName))]);
  }), 1)], 1)], 1)], 1), _vm.defaultFangan !== 0 ? _c("div", {
    staticClass: "modal-box2",
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "onwork"
  }, [_c("div", {
    staticClass: "dataInfo2"
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/home/shuju1.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#55565d",
      "font-size": "14px",
      "font-weight": "700"
    }
  }, [_vm._v("社保数据同步")])]), _c("div", {
    staticClass: "dataInfo2",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px",
      "margin-right": "12px"
    }
  }, [_vm._v("账号名称")]), _c("a-tooltip", {
    style: [{
      color: "#55565D"
    }, {
      fontSize: "12px"
    }, {
      width: "80px"
    }, {
      overflow: "hidden"
    }, {
      whiteSpace: "nowrap"
    }, {
      textOverflow: "ellipsis"
    }]
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.shebaoLogin.accountName))])]), _vm._v(" " + _vm._s(_vm.shebaoLogin.accountName) + " ")], 2)], 1), _c("div", {
    staticClass: "dataInfo2",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    }
  }, [_vm._v("登录状态")]), _c("div", {
    style: [{
      color: _vm.shebaoLogin.isLogin ? "#6A66F6" : "#A6A8B4"
    }, {
      fontSize: "12px"
    }, {
      marginLeft: "12px"
    }, {
      cursor: !_vm.shebaoLogin.isLogin ? "pointer" : ""
    }],
    on: {
      click: function click($event) {
        return _vm.Login(1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.shebaoLogin.isLogin ? "已登录" : "未登录") + " ")])]), _c("div", {
    staticClass: "dataInfo2",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    }
  }, [_vm._v("是否同步")]), _c("a-switch", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      disabled: !_vm.shebaoLogin.isLogin
    },
    on: {
      change: _vm.onShebaoChange
    }
  })], 1)]), _c("div", {
    staticClass: "onwork",
    staticStyle: {
      "margin-left": "15px",
      "margin-right": "15px"
    }
  }, [_c("div", {
    staticClass: "dataInfo2"
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/home/shuju2.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#55565d",
      "font-size": "14px",
      "font-weight": "700"
    }
  }, [_vm._v("医保数据同步")])]), _c("div", {
    staticClass: "dataInfo2",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px",
      "margin-right": "12px"
    }
  }, [_vm._v("账号名称")]), _c("a-tooltip", {
    style: [{
      color: "#55565D"
    }, {
      fontSize: "12px"
    }, {
      width: "80px"
    }, {
      overflow: "hidden"
    }, {
      whiteSpace: "nowrap"
    }, {
      textOverflow: "ellipsis"
    }]
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.yibaoLogin.accountName))])]), _vm._v(" " + _vm._s(_vm.yibaoLogin.accountName) + " ")], 2)], 1), _c("div", {
    staticClass: "dataInfo2",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    }
  }, [_vm._v("登录状态")]), _c("div", {
    style: [{
      color: _vm.yibaoLogin.isLogin ? "#6A66F6" : "#A6A8B4"
    }, {
      fontSize: "12px"
    }, {
      marginLeft: "12px"
    }, {
      cursor: !_vm.yibaoLogin.isLogin ? "pointer" : ""
    }],
    on: {
      click: function click($event) {
        return _vm.Login(2);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.yibaoLogin.isLogin ? "已登录" : "未登录") + " ")])]), _c("div", {
    staticClass: "dataInfo2",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    }
  }, [_vm._v("是否同步")]), _c("a-switch", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      disabled: !_vm.yibaoLogin.isLogin
    },
    on: {
      change: _vm.onYibaoChange
    }
  })], 1)]), _c("div", {
    staticClass: "onwork"
  }, [_c("div", {
    staticClass: "dataInfo2"
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/home/shuju.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#55565d",
      "font-size": "14px",
      "font-weight": "700"
    }
  }, [_vm._v("公积金数据同步")])]), _c("div", {
    staticClass: "dataInfo2",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px",
      "margin-right": "12px"
    }
  }), _c("a-tooltip", {
    style: [{
      color: "#55565D"
    }, {
      fontSize: "12px"
    }, {
      width: "80px"
    }, {
      overflow: "hidden"
    }, {
      whiteSpace: "nowrap"
    }, {
      textOverflow: "ellipsis"
    }]
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.gongjijinLogin.accountName))])]), _vm._v(" " + _vm._s(_vm.gongjijinLogin.accountName) + " ")], 2)], 1), _c("div", {
    staticClass: "dataInfo2",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    }
  }, [_vm._v("登录状态")]), _c("div", {
    style: [{
      color: _vm.gongjijinLogin.isLogin ? "#6A66F6" : "#A6A8B4"
    }, {
      fontSize: "12px"
    }, {
      marginLeft: "12px"
    }, {
      cursor: !_vm.gongjijinLogin.isLogin ? "pointer" : ""
    }],
    on: {
      click: function click($event) {
        return _vm.Login(3);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.gongjijinLogin.isLogin ? "已登录" : "未登录") + " ")])]), _c("div", {
    staticClass: "dataInfo2",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    }
  }, [_vm._v("是否同步")]), _c("a-switch", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      disabled: !_vm.gongjijinLogin.isLogin
    },
    on: {
      change: _vm.onGongjijinChange
    }
  })], 1)])]) : _vm._e(), _c("div", {
    staticStyle: {
      "background-color": "#faf9f9",
      "border-radius": "5px",
      "padding-left": "15px",
      "padding-top": "15px",
      "padding-right": "15px",
      "padding-bottom": "15px"
    }
  }, [_c("div", {
    attrs: {
      sytle: "font-size:14px;color:#55565D;font-weight:bold;"
    }
  }, [_vm._v("温馨提示")]), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4"
    }
  }, [_vm._v("1、同步数据需开启")]), _c("div", {
    staticClass: "edit",
    staticStyle: {
      "font-size": "12px",
      "margin-left": "5px",
      cursor: "pointer"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("大数据助手")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4"
    }
  }, [_vm._v("插件，并登录相应网站才可使用")])]), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4"
    }
  }, [_vm._v("2、导入人员名单需在花名册中，否则需先至花名册中新建。")]), _c("div", {
    staticClass: "edit",
    staticStyle: {
      "font-size": "12px",
      "margin-left": "5px",
      cursor: "pointer"
    },
    on: {
      click: _vm.huamingce
    }
  }, [_vm._v("立即前往")])])])])], 2), _c("a-modal", {
    attrs: {
      title: "数据同步",
      visible: _vm.confirmPlanZengVisible,
      width: "1020px"
    },
    on: {
      cancel: _vm.cancelSyn,
      ok: _vm.cancelSyn
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.cancelSyn
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "row",
      width: "100%",
      "align-items": "center",
      "margin-bottom": "20px"
    }
  }, [_c("a-month-picker", {
    attrs: {
      "disabled-date": _vm.disabledDate,
      placeholder: "选择月份",
      "allow-clear": ""
    },
    on: {
      change: _vm.onMonthChange
    }
  }), _c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px",
      "margin-left": "20px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "搜索姓名/身份证"
    },
    on: {
      search: _vm.handleChange
    },
    model: {
      value: _vm.searchContent,
      callback: function callback($$v) {
        _vm.searchContent = $$v;
      },
      expression: "searchContent"
    }
  }), _c("a-select", {
    staticClass: "mr12",
    staticStyle: {
      width: "136px"
    },
    attrs: {
      placeholder: "选择险种",
      "allow-clear": ""
    },
    on: {
      change: _vm.handleChangeStaff
    }
  }, _vm._l(_vm.staffSort, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.key
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-table", {
    attrs: {
      columns: _vm.columnDialogDetailguding,
      "data-source": _vm.dataDetail2,
      scroll: {
        y: 440
      },
      pagination: _vm.pagination,
      "row-key": "id"
    },
    on: {
      change: _vm.handlePageChange
    },
    scopedSlots: _vm._u([{
      key: "belongsDate",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(_vm.formatDate2(text)))])];
      }
    }, {
      key: "persentCompany",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text) + "%")])];
      }
    }, {
      key: "persentPersonal",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text) + "%")])];
      }
    }])
  })], 2), _c("BatchAdd", {
    attrs: {
      arr: _vm.selectedRowKeys
    },
    on: {
      tap: _vm.tap
    },
    model: {
      value: _vm.isLock,
      callback: function callback($$v) {
        _vm.isLock = $$v;
      },
      expression: "isLock"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };