import upload from "./component/upload";
import preview from "./component/preview";
import results from "./component/results";
export default {
  components: {
    upload: upload,
    preview: preview,
    results: results
  },
  data: function data() {
    return {
      current: 0,
      steps: [{
        title: "上传文件"
      }, {
        title: "数据预览"
      }, {
        title: "查看结果"
      }],
      status: false,
      rdata: "",
      id: ""
    };
  },
  methods: {
    onAction: function onAction(e, v) {
      this.current = e;
      if (v) {
        this.dataJson = v;
      }
    },
    onActionS: function onActionS(e, status, data) {
      this.current = e;
      this.status = status;
      this.rdata = data;
    }
  }
};