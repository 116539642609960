import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
var mixin = new UploadMixin();
import { getToken } from "xqjr-module-auth";
import axios from "axios";
import qs from "qs";
var columns = [{
  title: "行号",
  dataIndex: "id",
  key: "id"
}, {
  title: "姓名",
  dataIndex: "name",
  key: "name"
}, {
  title: "原因",
  dataIndex: "reason",
  key: "reason"
}];
export default {
  mixins: [mixin],
  data: function data() {
    return {
      columns: columns,
      uploadImg: require("@/assets/img/uploadImg.png"),
      params: {
        templateName: "allEmployeeSalary.xlsx"
      },
      url: "/salary/api/v1/excel/template/download",
      excelName: "定薪导入模板.xlsx"
    };
  },
  created: function created() {
    var companyId = this.$store.state.hr.companyId;
    this.action = "/salary/api/v1/empFixedSalaries/import?companyId=".concat(companyId, "&force=false");
  },
  methods: {
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    }
  }
};