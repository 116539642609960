import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 17
      }
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 10,
      offset: 1
    }
  }, [_c("div", {
    staticClass: "container-item"
  }, [_c("div", {
    staticClass: "item-title"
  }, [_vm._v("基本信息")]), _vm.requires.name !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "姓名"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '请输入' }] }]"
    }]
  })], 1) : _vm._e(), _vm.requires.gender !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "性别"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["gender"],
      expression: "['gender']"
    }],
    attrs: {
      options: _vm.sexOptions
    }
  })], 1) : _vm._e(), _vm.requires.birthday !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "出生日期"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["birthday"],
      expression: "['birthday']"
    }],
    attrs: {
      type: "date"
    }
  })], 1) : _vm._e(), _vm.requires.birthplace !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "籍贯"
    }
  }, [_c("area-component", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["birthplace"],
      expression: "['birthplace']"
    }],
    attrs: {
      region: _vm.birthplace
    },
    on: {
      "update:region": function updateRegion($event) {
        _vm.birthplace = $event;
      }
    }
  })], 1) : _vm._e(), _vm.requires.nativeLocation !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "户籍所在地"
    }
  }, [_c("area-component", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["nativeLocation"],
      expression: "['nativeLocation']"
    }],
    attrs: {
      region: _vm.nativeLocation
    },
    on: {
      "update:region": function updateRegion($event) {
        _vm.nativeLocation = $event;
      }
    }
  })], 1) : _vm._e(), _vm.requires.idCard !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "身份证号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["idCard"],
      expression: "['idCard']"
    }]
  })], 1) : _vm._e(), _vm.requires.highestEducation !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "最高学历"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["highestEducation"],
      expression: "['highestEducation']"
    }]
  })], 1) : _vm._e(), _vm.requires.maritalStatus !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "婚姻状况"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["maritalStatus"],
      expression: "['maritalStatus']"
    }]
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("未婚")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("初婚")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("再婚")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("离异")]), _c("a-select-option", {
    attrs: {
      value: "5"
    }
  }, [_vm._v("其他")])], 1)], 1) : _vm._e(), _vm.requires.birthStatus !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "生育状况"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["birthStatus"],
      expression: "['birthStatus']"
    }]
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("未育")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("一孩")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("二孩")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("现孕")]), _c("a-select-option", {
    attrs: {
      value: "5"
    }
  }, [_vm._v("哺乳期")]), _c("a-select-option", {
    attrs: {
      value: "6"
    }
  }, [_vm._v("其他")])], 1)], 1) : _vm._e(), _vm.requires.healthCondition !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "健康状况"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["healthCondition"],
      expression: "['healthCondition']"
    }]
  })], 1) : _vm._e(), _vm.requires.hasWeightyMedicalHis !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "重大病史或遗传病"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["hasWeightyMedicalHis"],
      expression: "['hasWeightyMedicalHis']"
    }]
  }, [_c("a-select-option", {
    attrs: {
      value: "0"
    }
  }, [_vm._v("无")]), _c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("有")])], 1)], 1) : _vm._e(), _vm.requires.nation !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "民族"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["nation"],
      expression: "['nation']"
    }]
  })], 1) : _vm._e(), _vm.requires.politicalStatus !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "政治面貌"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["politicalStatus"],
      expression: "['politicalStatus']"
    }]
  })], 1) : _vm._e(), _vm.requires.partyDate !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "入党时间"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["partyDate"],
      expression: "['partyDate']"
    }]
  })], 1) : _vm._e(), _vm.requires.partySite !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "党组织关系所在地"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["partySite"],
      expression: "['partySite']"
    }]
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "container-item"
  }, [_c("div", {
    staticClass: "item-title"
  }, [_vm._v("联系信息")]), _vm.requires.phone !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "手机号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["phone", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['phone', { rules: [{ required: true, message: '请输入' }] }]"
    }]
  })], 1) : _vm._e(), _vm.requires.email !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "邮箱"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["email"],
      expression: "['email']"
    }]
  })], 1) : _vm._e(), _vm.requires.currentLocation !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "居住地址"
    }
  }, [_c("area-component", {
    attrs: {
      region: _vm.currentAddrId
    },
    on: {
      "update:region": function updateRegion($event) {
        _vm.currentAddrId = $event;
      }
    }
  }), _c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["currentLocation"],
      expression: "['currentLocation']"
    }],
    staticStyle: {
      "margin-top": "10px"
    }
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "container-item"
  }, [_c("div", {
    staticClass: "item-title"
  }, [_vm._v("紧急联系人信息")]), _vm.requires.emerContact !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "姓名"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["emerContact"],
      expression: "['emerContact']"
    }]
  })], 1) : _vm._e(), _vm.requires.emerPhone !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "联系电话"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["emerPhone"],
      expression: "['emerPhone']"
    }]
  })], 1) : _vm._e(), _vm.requires.emerLocation !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "联系地址"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["emerLocation"],
      expression: "['emerLocation']"
    }]
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "container-item"
  }, [_c("div", {
    staticClass: "item-title"
  }, [_vm._v("银行开户信息")]), _vm.requires.bankCard !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "卡号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["bankCard"],
      expression: "['bankCard']"
    }]
  })], 1) : _vm._e(), _vm.requires.bankName !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "开户行"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["bankName"],
      expression: "['bankName']"
    }]
  })], 1) : _vm._e(), _vm.requires.bankPlace !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "开户地址"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["bankPlace"],
      expression: "['bankPlace']"
    }]
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "container-item"
  }, [_c("div", {
    staticClass: "item-title"
  }, [_vm._v("社保信息")]), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "是否有社保"
    }
  }, [_vm.requires.socType !== 0 ? _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["socType"],
      expression: "['socType']"
    }]
  }, [_c("a-select-option", {
    attrs: {
      value: "0"
    }
  }, [_vm._v("无")]), _c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("有")])], 1) : _vm._e()], 1), _vm.requires.socCard !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "社保卡号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["socCard"],
      expression: "['socCard']"
    }]
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "container-item"
  }, [_c("div", {
    staticClass: "item-title"
  }, [_vm._v("补充信息")]), _vm.requires.putonghuaLevel !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "普通话等级"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["putonghuaLevel"],
      expression: "['putonghuaLevel']"
    }]
  })], 1) : _vm._e(), _vm.requires.englishLevel !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "英语等级"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["englishLevel"],
      expression: "['englishLevel']"
    }]
  })], 1) : _vm._e(), _vm.requires.ncreLevel !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "计算机等级"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["ncreLevel"],
      expression: "['ncreLevel']"
    }]
  })], 1) : _vm._e(), _vm.requires.prizeWinning !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "获奖情况"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["prizeWinning"],
      expression: "['prizeWinning']"
    }]
  })], 1) : _vm._e(), _vm.requires.speciality !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "特长"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["speciality"],
      expression: "['speciality']"
    }]
  })], 1) : _vm._e()], 1)]), _c("a-col", {
    attrs: {
      span: 10,
      offset: 1
    }
  }, [_c("div", {
    staticClass: "container-item"
  }, [_c("div", {
    staticClass: "item-title"
  }, [_vm._v("岗位信息")]), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "部门"
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["depIdList"],
      expression: "['depIdList']"
    }],
    attrs: {
      "tree-data": _vm.depInfoOptions,
      multiple: "",
      "allow-clear": ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "岗位"
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["positionIdList"],
      expression: "['positionIdList']"
    }],
    attrs: {
      "tree-data": _vm.positionOptions,
      multiple: "",
      "allow-clear": ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "到岗时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["joinDate", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['joinDate', { rules: [{ required: true, message: '请输入' }] }]"
    }],
    attrs: {
      type: "date"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "直属主管"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["supervisor"],
      expression: "['supervisor']"
    }]
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "聘用形式"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["employType", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['employType', { rules: [{ required: true, message: '请输入' }] }]"
    }],
    attrs: {
      options: _vm.employTypeOptions
    }
  })], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "转正日期"
    }
  }, [_c("a-date-picker", {
    attrs: {
      type: "date"
    }
  })], 1), _vm.requires.number !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "工号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["number"],
      expression: "['number']"
    }]
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "container-item"
  }, [_c("div", {
    staticClass: "item-title"
  }, [_vm._v("附件信息")]), _vm.requires.annexBlackWhitePhoto !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "一寸白底电子版照片"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexBlackWhitePhoto", true);
      },
      "list-type": "picture-card",
      "file-list": _vm.BlackWhitePhotoList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.BlackWhitePhotoList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.annexIdCard !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "身份证照（正反面）"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexIdCard", false);
      },
      "list-type": "picture-card",
      "file-list": _vm.IdCardList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.IdCardList.length < 2 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.annexHousehold !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "户口本（首页、索引页、本人页）"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexHousehold", false);
      },
      "list-type": "picture-card",
      "file-list": _vm.HouseholdList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.HouseholdList.length < 3 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.annexMajor !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "专业资格证"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexMajor", true);
      },
      "list-type": "picture-card",
      "file-list": _vm.MajorList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.MajorList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.annexNcreLevel !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "计算机等级证书"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexNcreLevel", true);
      },
      "list-type": "picture-card",
      "file-list": _vm.NcreLevelList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.NcreLevelList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.annexNoCriminal !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "无犯罪记录证明"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexNoCriminal", true);
      },
      "list-type": "picture-card",
      "file-list": _vm.NoCriminalList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.NoCriminalList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.annexPractising !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "执业资格证"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexPractising", true);
      },
      "list-type": "picture-card",
      "file-list": _vm.PractisingList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.PractisingList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.annexPthLevel !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "普通话等级证书"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexPthLevel", true);
      },
      "list-type": "picture-card",
      "file-list": _vm.PthLevelList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.PthLevelList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.annexReward !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "奖励证明"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexReward", true);
      },
      "list-type": "picture-card",
      "file-list": _vm.RewardList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.RewardList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.annexEnglishLevel !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "英语等级证书"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexEnglishLevel", true);
      },
      "list-type": "picture-card",
      "file-list": _vm.EnglishLevelList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.EnglishLevelList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.annexDrivingLicense !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "机动车驾驶证"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexDrivingLicense", true);
      },
      "list-type": "picture-card",
      "file-list": _vm.DrivingLicenseList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.DrivingLicenseList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.annexDiploma !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "毕业证"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexDiploma", true);
      },
      "list-type": "picture-card",
      "file-list": _vm.DiplomaList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.DiplomaList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e(), _vm.requires.nnexDegree !== 0 ? _c("a-form-item", {
    attrs: {
      colon: false,
      label: "学位证"
    }
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("a-upload", {
    attrs: {
      "custom-request": function customRequest(p) {
        return _vm.uploadFiles(p, "annexDegree", true);
      },
      "list-type": "picture-card",
      "file-list": _vm.DegreeList
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleChange
    }
  }, [_vm.DegreeList.length < 1 ? _c("div", [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1)]) : _vm._e()], 1)])], 1), _c("div", {
    staticClass: "button-area"
  }, [_c("a-button", {
    staticClass: "mr20",
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addEmpInfo
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };