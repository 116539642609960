import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from "vuex";
export default {
  name: "AppMain",
  data: function data() {
    return {
      loaded: true,
      hasId: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    companyId: function companyId() {
      return this.hr.companyId;
    },
    key: function key() {
      return this.$route.path;
    }
  }),
  watch: {
    companyId: {
      handler: function handler(newVal) {
        if (newVal && newVal !== "undefined" && newVal !== "null") {
          this.hasId = true;
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  methods: {}
};