import "core-js/modules/es.array.map.js";
import { batchUpdate, getAllChannels } from "@/api/outsourcing/outsourcing";
export default {
  data: function data() {
    return {
      Channels: [],
      checkInIds: [],
      status: "",
      channelId: "",
      title: "",
      form: this.$form.createForm(this, {
        name: "coordinated"
      }),
      visible: false,
      confirmLoading: false
    };
  },
  methods: {
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    getAllChannelsList: function getAllChannelsList() {
      var _this = this;
      getAllChannels({
        isNotChannel: true
      }).then(function (res) {
        _this.Channels = res.data;
      });
    },
    showModal: function showModal(arr, name) {
      this.title = name;
      this.checkInIds = arr;
      this.visible = true;
      this.getAllChannelsList();
    },
    handleOk: function handleOk(e) {
      var _this2 = this;
      var data = {};
      if (this.title === "状态") {
        data = {
          checkInIds: this.checkInIds.map(function (i) {
            return i.id;
          }),
          status: this.status,
          id: "all"
        };
      } else {
        data = {
          checkInIds: this.checkInIds.map(function (i) {
            return i.id;
          }),
          channelId: this.channelId,
          id: "all"
        };
      }
      this.confirmLoading = true;
      batchUpdate(data).then(function (res) {
        _this2.$message.success(res.msg);
        _this2.visible = false;
        _this2.$emit("saveOk");
        _this2.confirmLoading = false;
        _this2.channelId = "";
        _this2.status = "";
      }).catch(function (err) {
        _this2.confirmLoading = false;
        _this2.$message.error(err.msg);
      });
    },
    handleCancel: function handleCancel(e) {
      this.visible = false;
    }
  }
};