import request from "@/utils/request";
export function getQueryTemplateInfoPage(params) {
  return request({
    url: "/hr/api/v1/attendanceSallaryTemplateItems/queryTemplateInfoPage",
    method: "GET",
    params: params
  });
}
export function delAttendanceSallaryTemplateItems(attendanceSallaryTemplateItemId) {
  return request({
    url: "/hr/api/v1/attendanceSallaryTemplateItems/".concat(attendanceSallaryTemplateItemId),
    method: "delete"
  });
}
export function postAttendanceSallaryTemplateItems(data) {
  return request({
    url: "/hr/api/v1/attendanceSallaryTemplateItems",
    method: "post",
    data: data
  });
}
export function putAttendanceSallaryTemplateItems(data) {
  return request({
    url: "/hr/api/v1/attendanceSallaryTemplateItems",
    method: "put",
    data: data
  });
}