import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "box page-miH page-bgBack"
  }, [_c("a-layout", [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(" " + _vm._s("【" + _vm.detailList.name + "】" + _vm.detailList.personalPeriod) + _vm._s(_vm.formType === "customer" ? "客户" : _vm.formType === "salary" ? "薪资" : _vm.formType === "social" ? "社保" : "") + "明细表 ")]), _c("div", {
    staticClass: "head"
  }, [_c("div", {
    staticStyle: {
      "font-size": "18px",
      "margin-left": "20px"
    }
  })]), _c("div", {
    staticClass: "fix-item-wrapper"
  }, [_c("div", {
    staticClass: "fix-item-one"
  }, [_c("p", {
    staticClass: "fix-item-name"
  }, [_vm._v("合计")])]), _c("div", {
    staticClass: "fix-item-two"
  }, [_c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v(" " + _vm._s(_vm.formType === "customer" ? "本月人数" : _vm.formType === "salary" ? "本月计薪人数" : "本月参保人数") + " ")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v(_vm._s(_vm.detailList.empNumber ? _vm.detailList.empNumber : 0) + "人")])]), _vm.formType !== "social" ? _c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("应发工资")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.detailList.shouldWages ? _vm.detailList.shouldWages : 0))])]) : _vm._e(), _vm.formType !== "social" ? _c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("实发工资")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.detailList.paidWages ? _vm.detailList.paidWages : 0))])]) : _vm._e(), _vm.formType !== "salary" ? _c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("企业社保")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.detailList.enterpriseSocialSecurity ? _vm.detailList.enterpriseSocialSecurity : 0))])]) : _vm._e(), _vm.formType !== "salary" ? _c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("企业公积金")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.detailList.enterpriseProvidentFund ? _vm.detailList.enterpriseProvidentFund : 0))])]) : _vm._e(), _c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("服务费")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.detailList.serviceCharge ? _vm.detailList.serviceCharge : 0))])]), _vm.formType !== "salary" ? _c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("滞纳金")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.detailList.fine ? _vm.detailList.fine : 0))])]) : _vm._e(), _c("div", [_c("p", {
    staticClass: "childpProjectName"
  }, [_vm._v("客户费用")]), _c("p", {
    staticClass: "childContentName"
  }, [_vm._v("¥" + _vm._s(_vm.detailList.customerFees ? _vm.detailList.customerFees : 0))])])])]), _c("div", {
    staticClass: "operation"
  }, [_c("div", [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "姓名/手机号/身份证号"
    },
    on: {
      search: _vm.inputChange
    }
  }), _c("a-select", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: "",
      placeholder: "员工状态"
    },
    on: {
      change: _vm.handleChange2
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 在职 ")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 待离职 ")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v(" 离职 ")])], 1)], 1), _c("div", [_c("a-button", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    },
    attrs: {
      type: "link",
      size: "small"
    },
    on: {
      click: _vm.reload
    }
  }, [_vm._v("刷新"), _c("a-icon", {
    attrs: {
      type: "reload"
    }
  })], 1), _c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }), _c("a-popover", {
    attrs: {
      title: "",
      trigger: "click",
      placement: "bottom"
    },
    on: {
      visibleChange: _vm.handleHeadChange
    },
    model: {
      value: _vm.headVisible,
      callback: function callback($$v) {
        _vm.headVisible = $$v;
      },
      expression: "headVisible"
    }
  }, [_c("div", {
    staticStyle: {
      width: "600px",
      height: "320px"
    },
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", [_c("div", [_vm._v("已选择表头")]), _c("div", {
    staticClass: "overview-tag-wrapper"
  }, [_c("draggable", {
    attrs: {
      filter: ".forbid",
      animation: "3000",
      move: _vm.onMove
    },
    on: {
      start: _vm.onStart,
      end: _vm.onEnd
    },
    model: {
      value: _vm.tempSelectedHead,
      callback: function callback($$v) {
        _vm.tempSelectedHead = $$v;
      },
      expression: "tempSelectedHead"
    }
  }, [_c("transition-group", _vm._l(_vm.tempSelectedHead, function (item, index) {
    return _c("a-tag", {
      key: index,
      class: item.title !== "姓名" && item.title !== "身份证号" ? "overview-select-tag" : "overview-select-tag-name forbid",
      attrs: {
        closable: item.title !== "姓名" && item.title !== "身份证号",
        color: item.title !== "姓名" && item.title !== "身份证号" ? "#fff" : "#E8E8E8"
      },
      on: {
        close: function close($event) {
          return _vm.deleteSelectedHead($event, index);
        }
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)], 1)], 1)]), _c("div", [_c("div", [_vm._v("可选择表头")]), _c("div", _vm._l(_vm.tempNoSelectedHead, function (item, index) {
    return _c("a-tag", {
      key: index,
      staticClass: "overview-no-select-tag",
      on: {
        click: function click($event) {
          return _vm.addSelectedHead(index);
        }
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)]), _c("a-row", {
    staticStyle: {
      position: "absolute",
      bottom: "20px",
      right: "20px"
    },
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: _vm.handleHeadCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleHeadSearch
    }
  }, [_vm._v("查询")])], 1)], 1), _c("a-button", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    },
    attrs: {
      type: "link",
      size: "small"
    }
  }, [_vm._v("选择表头"), _c("a-icon", {
    attrs: {
      type: "menu"
    }
  })], 1)], 1), _c("a-divider", {
    attrs: {
      type: "vertical"
    }
  }), _c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.download
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "8",
    attrs: {
      disabled: !_vm.dataList.length
    }
  }, [_vm._v("全部导出")])], 1), _c("a-button", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    },
    attrs: {
      type: "link",
      size: "small"
    }
  }, [_vm._v("导出"), _c("a-icon", {
    attrs: {
      type: "caret-down"
    }
  })], 1)], 1)], 1)]), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      loading: _vm.loading,
      columns: _vm.columns,
      "data-source": _vm.dataList,
      pagination: _vm.pagination,
      scroll: {
        x: 1200
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "shouldWages",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text ? text : 0) + " ")];
      }
    }, {
      key: "empSocialSecurity",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text ? text : 0) + " ")];
      }
    }, {
      key: "enterpriseSocialSecurity",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text ? text : 0) + " ")];
      }
    }, {
      key: "empProvidentFund",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text ? text : 0) + " ")];
      }
    }, {
      key: "enterpriseProvidentFund",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text ? text : 0) + " ")];
      }
    }, {
      key: "tax",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text ? text : 0) + " ")];
      }
    }, {
      key: "paidWages",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text ? text : 0) + " ")];
      }
    }, {
      key: "serviceCharge",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text ? text : 0) + " ")];
      }
    }])
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };