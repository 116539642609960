import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.push.js";
import { getTaskListReq, getTaskDetailReq } from "./api";
import { taskStatusDict, taskResultDict, iconDict, textDict, fulTextDict } from "./funcs";
import { diffTime } from "@/utils/tools";
var columns = [{
  title: "序号",
  dataIndex: "id",
  render: function render(text, record, index) {
    return index + 1;
  }
}, {
  title: "失败原因",
  dataIndex: "errorMessage"
}];
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      iconDict: iconDict,
      textDict: textDict,
      columns: columns,
      timer: null,
      listVisible: false,
      list: [],
      countFinished: 0,
      failReasonVisible: false,
      failReasonTitle: "",
      errorList: [],
      show: false,
      tempCount: 0,
      visibleCount: 0
    };
  },
  computed: {},
  watch: {
    listVisible: function listVisible(val) {
      var el = document.getElementById("drag_task_box");
      if (el) {
        if (val) {
          el.style.top = el.offsetTop - (this.list.length > 5 ? 340 : this.list.length * 68) + "px";
        } else {
          el.style.top = el.offsetTop + (this.list.length > 5 ? 340 : this.list.length * 68) + "px";
        }
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    this.getTaskList();
    this.timer = setInterval(function () {
      _this.getTaskList();
    }, 5000);
  },
  destroyed: function destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    diffTime: diffTime,
    getTaskList: function getTaskList() {
      var _this2 = this;
      getTaskListReq({
        taskStartStart: "",
        taskStartEnd: "",
        "orders[0].property": "taskStart",
        "orders[0].desc": true
      }).then(function (res) {
        if (res && res.data && res.data.length > 0) {
          var listTemp = [];
          var count = 0;
          for (var i = 0; i < res.data.length; i++) {
            var item = res.data[i];
            if (item.taskStatus === "3") {
              count += 1;
            }
            listTemp.push(item);
          }
          _this2.list = listTemp;
          _this2.countFinished = count;
          if (_this2.tempCount !== listTemp.length) {
            _this2.show = true;
          }
          _this2.$nextTick(function () {
            if (_this2.visibleCount !== listTemp.length) {
              _this2.listVisible = true;
              _this2.visibleCount = listTemp.length;
            }
          });
        } else {
          _this2.show = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    hideError: function hideError() {
      this.failReasonVisible = false;
      this.errorList = [];
    },
    showError: function showError(item) {
      var _this3 = this;
      getTaskDetailReq({
        taskId: item.id,
        "orders[0].property": "gmtCreate",
        "orders[0].desc": true,
        subjectResult: 0
      }).then(function (res) {
        if (res && res.data && res.data.length > 0) {
          _this3.errorList = res.data;
          _this3.failReasonTitle = fulTextDict[item.taskType];
          _this3.failReasonVisible = true;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    onClose: function onClose() {
      this.show = false;
      this.tempCount = this.list.length;
    }
  }
};