import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import { getDepartmentInTree } from "@/api/department/department";
import { getPositionInTree } from "@/api/department/position";
import { addEmpInfo as _addEmpInfo } from "@/api/staff/staff";
import { getEmpInfoRequires } from "@/api/staff/entry";
import { uploadAttachmentFiles, saveSubjectAttachment, uploadFile } from "@/api/file/file";
import AreaComponent from "./area";
export default {
  name: "AddEmpInfo",
  components: {
    AreaComponent: AreaComponent
  },
  props: ["status"],
  data: function data() {
    return {
      requires: {},
      sexOptions: [{
        label: "请选择",
        value: ""
      }, {
        label: "男",
        value: 1
      }, {
        label: "女",
        value: 2
      }],
      depInfoOptions: [],
      // 部门信息
      positionOptions: [],
      employTypeOptions: [{
        label: "请选择",
        value: ""
      }, {
        label: "非正式",
        value: 0
      }, {
        label: "正式",
        value: 1
      }],
      // 聘用形式
      form: this.$form.createForm(this),
      pendingForm: this.$form.createForm(this),
      idCardSubjectId: "",
      previewVisible: false,
      previewImage: "",
      nativeLocation: "",
      birthplace: "",
      currentAddrId: "",
      annexHousehold: [],
      annexIdCard: [],
      IdCardList: [],
      DegreeList: [],
      DiplomaList: [],
      DrivingLicenseList: [],
      EnglishLevelList: [],
      RewardList: [],
      PthLevelList: [],
      NoCriminalList: [],
      NcreLevelList: [],
      MajorList: [],
      HouseholdList: [],
      BlackWhitePhotoList: [],
      PractisingList: [],
      pendingIdCardList: [],
      pendingIdCardSubjectId: "",
      pendingPreviewVisible: false,
      pendingPreviewImage: "",
      pendingNativeLocation: "",
      pendingEmpBirthplace: "",
      pendingEmpCurrentAddrId: ""
    };
  },
  watch: {
    status: function status() {}
  },
  created: function created() {
    var _this = this;
    this.companyId = this.$store.state.hr.companyId;
    getDepartmentInTree({
      companyId: this.companyId
    }).then(function (res) {
      _this.depInfoOptions = _this.tree(res.data, "depChildList");
    });
    getPositionInTree({
      companyId: this.companyId
    }).then(function (res) {
      _this.positionOptions = _this.tree(res.data, "positionChildList", true);
    });
    if (this.status === 1) {
      this.getRequires();
    }
  },
  methods: {
    // 获取显示规则
    getRequires: function getRequires() {
      var _this2 = this;
      getEmpInfoRequires(this.companyId, "entry").then(function (res) {
        _this2.requires = res.data;
      });
    },
    renderChange: function renderChange(keys, value) {
      var temp = this;
      for (var i = 0; i < keys.length - 1; i++) {
        temp = temp[keys[i]];
      }
      temp[keys[keys.length - 1]] = value;

      // 根据政治面貌判断是否显示 “入党时间” 和 “党组织关系所在地”
      if (keys[keys.length - 1] === "politicalStatus" && keys[keys.length - 3] === "baseInfo") {
        var idx = this.baseInfo.fieldList.findIndex(function (field) {
          return field["value"] === "partyDate";
        });
        if (value === "1") {
          this.baseInfo.fieldList[idx]["show"] = true;
          this.baseInfo.fieldList[idx + 1]["show"] = true;
        } else {
          this.baseInfo.fieldList[idx]["show"] = false;
          this.baseInfo.fieldList[idx + 1]["show"] = false;
        }
      }
    },
    tree: function tree(array, childKey, onlyLastChild) {
      var _this3 = this;
      if (array === null || array.length < 1) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id;
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        if (item.children) {
          item["disabled"] = onlyLastChild;
          _this3.tree(item.children, childKey, onlyLastChild);
        }
      });
      return array;
    },
    alertResultMessage: function alertResultMessage(result) {
      var type = "success";
      if (result.code !== 200) {
        type = "error";
      }
      this.$notification[type]({
        message: result.msg
      });
    },
    addEmpInfo: function addEmpInfo() {
      var that = this;
      this.form.validateFields(function (err, values) {
        if (!err) {
          values.companyId = that.companyId;
          values.status = that.status;
          values.nativeLocation = that.nativeLocation;
          values.birthplace = that.birthplace;
          values.annexIdCard = that.annexIdCard.join(",");
          values.annexDegree = that.annexDegree;
          values.annexDiploma = that.annexDiploma;
          values.annexDrivingLicense = that.annexDrivingLicense;
          values.annexEnglishLevel = that.annexEnglishLevel;
          values.annexReward = that.annexReward;
          values.annexPthLevel = that.annexPthLevel;
          values.annexNoCriminal = that.annexNoCriminal;
          values.annexNcreLevel = that.annexNcreLevel;
          values.annexMajor = that.annexMajor;
          values.annexHousehold = that.annexHousehold.join(",");
          values.annexBlackWhitePhoto = that.annexBlackWhitePhoto;
          values.annexPractising = that.annexPractising;
          if (that.status === 2) {
            values.employType = 1;
            values.formalType = 1;
          }
          _addEmpInfo(values).then(function (res) {
            that.alertResultMessage(res);
            if (res.code === 200) {
              //   that.$router.go(-1)
              that.cancel();
            }
          });
        }
      });
    },
    addPendingEmpInfo: function addPendingEmpInfo() {
      var that = this;
      this.pendingForm.validateFields(function (err, values) {
        if (!err) {
          values = _objectSpread(_objectSpread({
            companyId: that.companyId,
            name: values.empInfoDO.name,
            phone: values.empInfoDO.phone,
            status: 1
          }, values.empInfoDO), {}, {
            empInfoDO: {
              status: 1
            }
          });
          _addEmpInfo(values).then(function (res) {
            that.alertResultMessage(res);
            if (res.code === 200) {
              that.$router.go(-1);
            }
          });
          // })
        }
      });
    },

    cancel: function cancel() {
      this.$router.go(-1);
    },
    handleCancel: function handleCancel(isPending) {
      this.previewVisible = false;
    },
    handlePreview: function handlePreview(file, isPending) {
      var images = file.url || file.thumbUrl;
      var visible = true;
      if (isPending) {
        this.pendingPreviewImage = images;
        this.pendingPreviewVisible = visible;
      } else {
        this.previewImage = images;
        this.previewVisible = visible;
      }
    },
    handleChange: function handleChange(_ref, isPending) {
      var fileList = _ref.fileList;
    },
    handleRemove: function handleRemove(file, isPending) {
      if (isPending) {
        this.pendingIdCardList = this.pendingIdCardList.filter(function (item) {
          return item.uid !== file.uid;
        });
      } else {
        this.idCardList = this.idCardList.filter(function (item) {
          return item.uid !== file.uid;
        });
      }
      this.saveAttachment(isPending);
    },
    uploadFiles: function uploadFiles(params, type, bool) {
      var that = this;
      var formData = new FormData();
      formData.append("files", params.file);
      uploadFile(formData).then(function (res) {
        that[type.substr(5) + "List"].push({
          uid: res.data[0],
          name: res.data[0],
          status: "done",
          url: res.data[0]
        });
        if (bool) {
          that[type] = res.data[0];
        } else {
          that[type].push(res.data[0]);
        }
      });
    }
  }
};