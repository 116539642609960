var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "xq-form-wrapper p24"
  }, [_vm.tableData.length > 0 ? _c("a-alert", {
    staticClass: "mb16 aler",
    staticStyle: {
      "background-color": "#fff1f0"
    },
    attrs: {
      type: "error",
      message: "有" + _vm.tableData.length + "条数据异常，请尽快处理",
      "show-icon": "",
      closable: "",
      "after-close": _vm.handleClose,
      "close-text": "导出错误数据"
    }
  }) : _c("a-alert", {
    staticClass: "mb16",
    attrs: {
      message: "有" + _vm.tableData.length + "条数据异常，请尽快处理",
      type: "success",
      "show-icon": ""
    }
  }), _c("a-spin", {
    staticClass: "xq-form-content",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: _vm.pagination,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    on: {
      change: _vm.handlePageChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [_c("div", {
            key: index,
            staticStyle: {
              position: "relative"
            }
          }, [_c("a-popover", {
            key: index,
            attrs: {
              title: "数据错误",
              placement: "right"
            }
          }, [_c("template", {
            slot: "content"
          }, [_c("p", [_vm._v(_vm._s(record.importFailReason))])]), col.customTitle == record.failField ? _c("div", {
            staticClass: "triangle"
          }) : _vm._e()], 2), col.dataIndex == "identity" ? _c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [_c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(text))])]), _c("div", {
            staticClass: "overflowsIdentity",
            staticStyle: {
              height: "22px"
            }
          }, [_vm._v(_vm._s(text || ""))])], 2) : _c("div", {
            staticStyle: {
              height: "22px"
            }
          }, [_vm._v(_vm._s(text || ""))])], 1)];
        }
      };
    })], null, true)
  })], 1)], 1), _c("div", {
    staticClass: "sumbit"
  }, [_c("a-button", {
    staticClass: "mr12",
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 返回 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v(" 下一步 ")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };