var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "box"
  }, [_c("a-modal", {
    attrs: {
      title: "选择条件",
      visible: _vm.visible,
      "destroy-on-close": true
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    staticClass: "mg_b24"
  }, [_vm._v("请选择用来区分审批流程的条件字段")]), _c("a-row", {
    attrs: {
      type: "flex",
      gutter: [24, 24]
    }
  }, _vm._l(_vm.options, function (item, i) {
    return _c("a-col", {
      key: i
    }, [_c("a-checkbox", {
      attrs: {
        "default-checked": item.checked
      },
      on: {
        change: function change($event) {
          return _vm.onChange($event, item, i);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")])], 1);
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };