import "core-js/modules/web.timers.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import UploadGeneralInline from "xqjr-plugin-common/FileHandler/UploadGeneralInline.vue";
var receivableColumns = [{
  title: "实际到账金额",
  dataIndex: "receiveAmount",
  key: "receiveAmount",
  width: 120,
  scopedSlots: {
    customRender: "receiveAmount"
  }
}, {
  title: "创建人",
  dataIndex: "createUserName",
  key: "createUserName",
  width: 90
}, {
  title: "回款时间",
  dataIndex: "payBackDate",
  key: "payBackDate",
  scopedSlots: {
    customRender: "payBackDate"
  },
  width: 120
}, {
  title: "银行回单",
  dataIndex: "file",
  key: "file",
  width: 120,
  scopedSlots: {
    customRender: "file"
  }
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark",
  width: 90,
  scopedSlots: {
    customRender: "remark"
  }
}, {
  title: "操作",
  dataIndex: "action",
  key: "action",
  scopedSlots: {
    customRender: "action"
  },
  width: 60
}];
var ticketColumns = [{
  title: "实际开票金额",
  dataIndex: "invoiceAmount",
  key: "invoiceAmount",
  width: 120,
  scopedSlots: {
    customRender: "invoiceAmount"
  }
}, {
  title: "创建人",
  dataIndex: "createUserName",
  key: "createUserName",
  width: 90
}, {
  title: "开票时间",
  dataIndex: "invoiceDate",
  key: "invoiceDate",
  scopedSlots: {
    customRender: "invoiceDate"
  },
  width: 140
}, {
  title: "开票附件",
  dataIndex: "file",
  key: "file",
  width: 120,
  scopedSlots: {
    customRender: "file"
  }
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark",
  width: 90,
  scopedSlots: {
    customRender: "remark"
  }
}, {
  title: "操作",
  dataIndex: "action",
  key: "action",
  scopedSlots: {
    customRender: "action"
  },
  width: 60
}];
import moment from "moment";
import axios from "axios";
import { addTicket, getTicket, getReceivable, addReceivable, deleteReord, deleteBackRecord, wasteTicketReord, getCustomerDetail } from "@/api/settlement/settlement";
import { isContainByString } from "@/utils/index";
export default {
  name: "FileAdd",
  components: {
    UploadGeneralInline: UploadGeneralInline
  },
  data: function data() {
    return {
      detailList: {},
      routerData: JSON.parse(this.$route.query.routerData),
      loading2: false,
      loading3: false,
      receivableList: [],
      ticketList: [],
      receivableColumns: receivableColumns,
      ticketColumns: ticketColumns,
      receivableVisible: false,
      ticketVisible: false,
      abolitionVisible: false,
      loading: true,
      urlList: [],
      urlList2: [],
      fileList: [],
      abolitionImg: require("@/assets/home/feichu.png"),
      receivableForm: {},
      ticketForm: {},
      detailCompanyId: "",
      fixedSalaryTemplateName: null,
      addForm: {
        name: ""
      },
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      continue1: true,
      continue2: true,
      isContainByString: isContainByString
    };
  },
  created: function created() {
    this.currentPage = this.$route.query.currentPage;
    this.detailCompanyId = JSON.parse(this.$route.query.routerData).companyId;
    this.showDetailTable();
    this.getCustomerDetailList();
  },
  beforeDestory: function beforeDestory() {
    this.clearInterval(this.timer);
  },
  methods: {
    // 查询详情
    getCustomerDetailList: function getCustomerDetailList() {
      var _this = this;
      this.loading = true;
      getCustomerDetail(this.routerData.id).then(function (res) {
        _this.detailList = res.data;
        _this.loading = false;
      });
    },
    // 确定废除？
    showAbolition: function showAbolition() {
      this.abolitionVisible = true;
    },
    handleAbolition: function handleAbolition() {
      var _this2 = this;
      deleteReord(this.routerData.id).then(function (res) {
        _this2.abolitionVisible = false;
        _this2.$notification["success"]({
          message: "success",
          description: "删除成功"
        });
        _this2.goBack();
      });
    },
    // 回款点击确定
    handleReceivableOk: function handleReceivableOk() {
      var _this3 = this;
      setTimeout(function () {
        _this3.continue1 = true; // 点击一次时隔两秒后才能再次点击
      }, 2000);
      if (this.continue1 === true) {
        this.continue1 = false;
        this.$refs.ruleForm.validate(function (valid) {
          if (valid) {
            addReceivable({
              payBackDate: _this3.receivableForm.payBackDate,
              receiveAmount: _this3.receivableForm.receiveAmount,
              remark: _this3.receivableForm.remark,
              customerSettlementId: _this3.routerData.id,
              file: _this3.urlList,
              companyId: _this3.detailCompanyId
            }).then(function (res) {
              _this3.receivableVisible = false;
              _this3.fileList = [];
              _this3.$notification["success"]({
                message: "success",
                description: "新增回款成功"
              });
              _this3.showDetailTable();
              _this3.getCustomerDetailList();
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          }
        });
      }
    },
    handleCancel2: function handleCancel2() {
      this.urlList2 = [];
    },
    // 开票点击确定
    handleTicketOk: function handleTicketOk() {
      var _this4 = this;
      setTimeout(function () {
        _this4.continue2 = true; // 点击一次时隔两秒后才能再次点击
      }, 2000);
      if (this.continue2 === true) {
        this.continue2 = false;
        this.$refs.ruleForm2.validate(function (valid) {
          if (valid) {
            addTicket({
              invoiceDate: _this4.ticketForm.invoiceDate,
              invoiceAmount: _this4.ticketForm.invoiceAmount,
              remark: _this4.ticketForm.remark,
              customerSettlementId: _this4.routerData.id,
              file: _this4.urlList2.split(","),
              companyId: _this4.detailCompanyId
            }).then(function (res) {
              _this4.fileList2 = [];
              _this4.ticketVisible = false;
              _this4.$notification["success"]({
                message: "success",
                description: "新增开票成功"
              });
              _this4.showDetailTable();
              _this4.getCustomerDetailList();
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          }
        });
      }
    },
    handleCancel: function handleCancel() {
      this.fileList = [];
    },
    removeChange: function removeChange(e, a) {},
    beforeUpload: function beforeUpload(file) {
      var _this5 = this;
      var formData = new FormData();
      formData.append("files", file);
      axios({
        url: "/file/api/v1/site/default/upload/inline",
        method: "post",
        data: formData,
        headers: {
          token: this.$store.state.auth.token
        }
      }).then(function (res) {
        res.data.data.forEach(function (item, index) {
          _this5.urlList.push(item);
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      return false;
    },
    // 回款
    upLoad: function upLoad(file) {
      this.fileList = file.fileList;
    },
    showReceivable: function showReceivable(record, tag) {
      var _this6 = this;
      this.urlList = [];
      // this.receivableList = [];
      this.continue1 = true;
      this.receivableVisible = true;
      this.$nextTick(function () {
        _this6.$refs.ruleForm.clearValidate();
      });
      this.receivableForm = {};
    },
    // 开票
    showTicket: function showTicket() {
      var _this7 = this;
      this.urlList2 = [];
      this.loading3 = false;
      this.ticketVisible = true;
      this.continue2 = true;
      this.$nextTick(function () {
        _this7.$refs.ruleForm2.clearValidate();
      });
      this.ticketForm = {};
    },
    // 废除开票记录
    wasteTicket: function wasteTicket(record) {
      var _this8 = this;
      this.loading3 = true;
      wasteTicketReord(record.id).then(function (res) {
        _this8.showDetailTable();
        _this8.getCustomerDetailList();
        _this8.$notification["success"]({
          message: "success",
          description: "废除成功"
        });
      });
    },
    // 删除回款记录
    deleteBack: function deleteBack(record) {
      var _this9 = this;
      this.loading2 = true;
      deleteBackRecord(record.id).then(function (res) {
        _this9.showDetailTable();
        _this9.getCustomerDetailList();
        _this9.$notification["success"]({
          message: "success",
          description: "删除成功"
        });
      });
    },
    disabledDate: function disabledDate(current) {
      return current > moment().endOf("day");
    },
    // 请求数据列表
    showDetailTable: function showDetailTable() {
      var _this10 = this;
      this.loading2 = true;
      this.loading3 = true;
      getReceivable(this.routerData.id).then(function (res) {
        _this10.receivableList = res.data ? res.data : [];
        _this10.loading2 = false;
        _this10.loading3 = false;
      });
      getTicket(this.routerData.id).then(function (res) {
        _this10.ticketList = res.data ? res.data : [];
        _this10.loading3 = false;
        if (_this10.isticketShow === true) {
          _this10.$nextTick(function () {
            _this10.$refs.ruleForm2.clearValidate();
          });
        }
        _this10.ticketForm = {};
      });
    },
    goBack: function goBack() {
      this.$router.push({
        path: this.isContainByString("settlementAdmin") ? "/settlementAdmin/record" : "/settlement/record",
        query: {
          currentPage: this.currentPage
        }
      });
    },
    onOk: function onOk() {},
    onChange: function onChange() {}
  }
};