import request from "@/utils/request";
export function followUpAddReq(data) {
  return request({
    url: "/recruit2/api/v1/talentFollows",
    method: "post",
    data: data
  });
}
export function followUpUpdateReq(data) {
  return request({
    url: "/recruit2/api/v1/talentFollows",
    method: "put",
    data: data
  });
}
export function followUpLoadReq(params) {
  return request({
    url: "/recruit2/api/v1/talentFollows",
    method: "get",
    params: params
  });
}
export function followUDelReq(talentFollowId) {
  return request({
    url: "/recruit2/api/v1/talentFollows/".concat(talentFollowId),
    method: "delete"
  });
}