var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      visible: _vm.visible,
      title: "审批详情"
    },
    on: {
      cancel: _vm.handleOk
    }
  }, [_c("a-row", {
    staticClass: "header"
  }, [_c("img", {
    staticClass: "w48",
    attrs: {
      src: require("@/assets/img/48@3x.png"),
      alt: ""
    }
  }), _c("div", [_c("h2", [_vm._v("刘洋 "), _c("span", {
    staticClass: "color9C9C9C fs12"
  }, [_vm._v("（申请人）")])]), _c("div", {
    staticClass: "fs12 tc-theme"
  }, [_vm._v("审批通过")])])]), _c("a-row", {
    staticClass: "btmline lh48 color3"
  }, [_c("span", {
    staticClass: "small-title"
  }, [_vm._v(" 预计调岗时间 ")]), _vm._v(" 566986369756235 ")]), _c("a-row", {
    staticClass: "pt16 color3"
  }, [_c("span", {
    staticClass: "small-title"
  }, [_vm._v(" 调岗部门 ")]), _vm._v(" 2020-01-02 16:25:05 ")]), _c("a-row", {
    staticClass: "pb16 color3"
  }, [_c("span", {
    staticClass: "small-title"
  }, [_vm._v(" 调岗岗位 ")]), _vm._v(" 入职审批 ")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };