import request from "@/utils/request";

// 查找公司下的工资条模板
export function getTemplates(params) {
  return request({
    url: "/salary/api/v1/salaryTemplates/selectByCompanyId",
    method: "GET",
    params: params
  });
}
// 查询默认工资项
export function getTemplateItems(params) {
  return request({
    url: "/salary/api/v1/salaryTemplateItems/selectDefaultTemplateItem",
    method: "GET",
    params: params
  });
}
// 新增工资模板表
export function addTemplateItems(data) {
  return request({
    url: "/salary/api/v1/salaryTemplates",
    method: "post",
    data: data
  });
}
// 修改工资模板表
export function editTemplateItems(data) {
  return request({
    url: "/salary/api/v1/salaryTemplates",
    method: "put",
    data: data
  });
}
// 根据工资模板Id查询工资项
export function getSalaryTemplates(salaryTemplateId) {
  return request({
    url: "/salary/api/v1/salaryTemplates/".concat(salaryTemplateId),
    method: "GET"
  });
}
// 删除单个工资模板表
export function deleteSalaryTemplates(salaryTemplateId) {
  return request({
    url: "/salary/api/v1/salaryTemplates/".concat(salaryTemplateId),
    method: "DELETE"
  });
}
// 删除工资模板表月工资
export function deleteSalary(salaryTemplateSalaryId) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries/".concat(salaryTemplateSalaryId),
    method: "DELETE"
  });
}
export function batchDeleteSalary(data) {
  return request({
    url: "/salary/api/v1/salaryTemplateSalaries",
    method: "DELETE",
    data: data
  });
}
// 薪资组修改，若更换模板 查询是否有未归档的薪资组月工资
export function getNotArchiveById(params) {
  return request({
    url: "/salary/api/v1/salaryGroupSalaries/selectNotArchiveBySalaryGroupIdAndSalaryTemplateId",
    method: "GET",
    params: params
  });
}
// 删除模板月工资接口
export function deleteSalaryGroupSalaries(salaryGroupSalaryId) {
  return request({
    url: "/salary/api/v1/salaryGroupSalaries/".concat(salaryGroupSalaryId),
    method: "DELETE"
  });
}
export function batchDeleteSalaryGroupSalaries(data) {
  return request({
    url: "/salary/api/v1/salaryGroupSalaries",
    method: "DELETE",
    data: data
  });
}