/* 定义初始数据 薪资模块 */
var state = {
  dialog: {
    show: false,
    data: [],
    obj: {},
    type: ""
  },
  postParams: {
    autoCheckHours: "",
    checkCount: "",
    companyId: "",
    feedbackCount: "",
    readCount: "",
    sendBy: "",
    salaryBillDOList: [],
    sendCount: "",
    sendName: "",
    sendType: "",
    showEmptyValue: false,
    tableId: "",
    withdrawStatus: "",
    withdrawnCount: ""
  },
  payslip: {
    successList: [],
    postParams: {},
    cbData: {},
    tableId: ""
  },
  salaryParams: {}
};
/* 同步提交 */
var mutations = {
  setSalaryparams: function setSalaryparams(state, payload) {
    Object.assign(state.salaryParams, payload);
  },
  resetSalaryparams: function resetSalaryparams(state) {
    state.salaryParams = {};
  },
  DIALOGS: function DIALOGS(state) {},
  dialogs: function dialogs(state) {},
  successList: function successList(state, payload) {
    state.payslip.successList = payload;
  },
  postParams: function postParams(state, payload) {
    Object.assign(state.payslip.postParams, payload);
  },
  cbData: function cbData(state, payload) {
    state.payslip.cbData = payload;
  },
  tableId: function tableId(state, payload) {
    state.payslip.tableId = payload;
  },
  resetPostParams: function resetPostParams(state) {
    state.payslip.postParams = {};
  },
  resetPayslip: function resetPayslip(state) {
    state.payslip = {};
  }
};
/* 异步提交 */
var actions = {};
var getters = {
  PAYSLIP: function PAYSLIP(state) {
    return state.payslip;
  },
  SALARY: function SALARY(state) {
    return state.salaryParams;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};