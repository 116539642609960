var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("rTitle", {
    attrs: {
      type: "back",
      tit: "处理异常数据"
    }
  }), _c("a-alert", {
    staticStyle: {
      margin: "24px",
      "background-color": "#fff1f0"
    },
    attrs: {
      message: "\u6709".concat(_vm.errorDataCount, "\u6761\u6570\u636E\u5F02\u5E38\uFF0C\u8BF7\u5C3D\u5FEB\u5904\u7406"),
      description: "您可以点击忽略放弃这条数据，或者点击覆盖替换已有的数据",
      type: "error",
      closable: "",
      "show-icon": ""
    }
  }), _c("div", {
    staticClass: "xq-form-wrapper",
    staticStyle: {
      margin: "24px",
      "padding-bottom": "20px"
    }
  }, [_c("a-spin", {
    staticClass: "xq-form-content",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 70
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "positionCount" ? _c("div", {
            key: index
          }, [_vm._v("导入")]) : col.dataIndex === "skill" && record.skill ? _c("div", {
            key: index,
            staticClass: "tags"
          }, _vm._l(record.skill.split(","), function (item, index) {
            return _c("div", {
              key: index
            }, [_vm._v(_vm._s(item))]);
          }), 0) : col.dataIndex === "errorReason" ? _c("span", {
            key: index
          }, [_c("span", {
            staticClass: "d1"
          }), _vm._v(" "), _c("span", {
            staticClass: "d2"
          }, [_vm._v(_vm._s(text) + " ")])]) : col.dataIndex === "tableAction" ? _c("div", {
            key: index,
            staticClass: "tableAction"
          }, [_c("span", [_c("a-popconfirm", {
            attrs: {
              title: "\u786E\u8BA4\u8981\u5FFD\u7565\u5417\uFF1F",
              "ok-text": "确认",
              "cancel-text": "取消",
              disabled: record.errorReason === "人才库中已有此员工"
            },
            on: {
              confirm: function confirm($event) {
                return _vm.onDelete(record.id);
              }
            }
          }, [_c("a", {
            attrs: {
              disabled: record.errorReason === "人才库中已有此员工"
            }
          }, [_vm._v("忽略")])]), record.errorReason === "人才库中已有此手机号数据" ? _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }) : _vm._e(), _c("a-popconfirm", {
            attrs: {
              title: "\u786E\u8BA4\u8981\u8986\u76D6\u5DF2\u6709\u5417\uFF1F",
              "ok-text": "确认",
              "cancel-text": "取消"
            },
            on: {
              confirm: function confirm($event) {
                return _vm.onDelete(record.id, record.talentPoolId, record.jobSeekerId);
              }
            }
          }, [record.errorReason === "人才库中已有此手机号数据" ? _c("a", [_vm._v("覆盖已有")]) : _vm._e()])], 1)]) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };