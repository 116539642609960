import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export var fieldTypeArr = [{
  k: 1,
  v: "下拉选择器"
}, {
  k: 2,
  v: "单行输入框"
}, {
  k: 3,
  v: "日期选择器（年月日）"
}, {
  k: 13,
  v: "日期选择器（年月）"
},
// { k: 14, v: "日期选择器（年月日时分秒）" },
{
  k: 4,
  v: "多行输入框"
}, {
  k: 5,
  v: "数字输入框"
}, {
  k: 12,
  v: "地区选择器（省市）"
}, {
  k: 6,
  v: "地区选择器（省市区）"
}, {
  k: 7,
  v: "文件/图片上传"
},
// { k: 8, v: "日期选择器（开始-结束）" },
// { k: 9, v: "地区选择+详细地址" },
{
  k: 10,
  v: "手机号输入框"
}, {
  k: 11,
  v: "邮箱输入框"
}];
export var fieldTypeMap = buildMap(fieldTypeArr);
function buildMap(lst) {
  var map = {};
  lst.forEach(function (item) {
    map[item.k] = item.v;
  });
  return map;
}