import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: "ModalDetail",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      companyName: this.$store.state.hr.companyName,
      bgImg: {},
      bgImg2: {},
      keys: [{
        name: "应发工资",
        key: "salaryAmount",
        value: ""
      }, {
        name: "实发工资",
        key: "salaryReal",
        value: ""
      }],
      keysOther: []
    };
  },
  mounted: function mounted() {},
  watch: {
    visible: {
      handler: function handler(val) {
        var _this = this;
        var tempKeys = [{
          name: "手机号",
          key: "phone",
          value: ""
        }, {
          name: "个人所得税",
          key: "empTax",
          value: ""
        }, {
          name: "个人社保",
          key: "empSoc",
          value: ""
        }, {
          name: "个人公积金",
          key: "empPf",
          value: ""
        }, {
          name: "基本工资",
          key: "salaryBasic",
          value: ""
        }, {
          name: "应发工资",
          key: "salaryAmount",
          value: ""
        }, {
          name: "实发工资",
          key: "salaryReal",
          value: ""
        }];
        if (val) {
          var initKeys = window.location.host.includes("csxr.cn") ? [{
            name: "应发工资",
            key: "salaryAmount",
            value: ""
          }, {
            name: "扣个人社保",
            key: "empSoc",
            value: ""
          }] : [{
            name: "应发工资",
            key: "salaryAmount",
            value: ""
          }, {
            name: "扣个人社保",
            key: "empSoc",
            value: ""
          }, {
            name: "基本工资",
            key: "salaryBasic",
            value: ""
          }, {
            name: "扣个人公积金",
            key: "empPf",
            value: ""
          }, {
            name: "扣个人所得税",
            key: "empTax",
            value: ""
          }, {
            name: "实发工资",
            key: "salaryReal",
            value: ""
          }];
          this.setUI();
          tempKeys = initKeys;
          tempKeys.forEach(function (item) {
            item.value = _this.options[item.key];
          });
          var tempKeysOther = [];
          if (this.options.salaryBillFieldsDOList && this.options.salaryBillFieldsDOList.length) {
            if (this.options.showEmptyValue === true) {
              this.options.salaryBillFieldsDOList.map(function (key) {
                if (key.fieldValue || key.fieldValue === 0 || key.fieldValue === "0") {
                  tempKeysOther.push({
                    name: key.fieldName,
                    value: key.fieldValue
                  });
                }
              });
            }
            if (this.options.showEmptyValue === false) {
              this.options.salaryBillFieldsDOList.map(function (key) {
                if (key.fieldValue && key.fieldValue !== "0") {
                  tempKeysOther.push({
                    name: key.fieldName,
                    value: key.fieldValue
                  });
                }
              });
            }
          }
          if (this.options.showEmptyValue === false) {
            tempKeys = tempKeys.filter(function (item) {
              return item.value && item.value !== "0";
            });
            tempKeysOther = tempKeysOther.filter(function (item) {
              return item.value && item.value !== "0";
            });
          } else {
            tempKeys = tempKeys.filter(function (item) {
              return item.value || item.value === 0 || item.value === "0";
            });
            tempKeysOther = tempKeysOther.filter(function (item) {
              return item.value || item.value === 0 || item.value === "0";
            });
          }
          this.keys = tempKeys;
          this.keysOther = tempKeysOther;
        }
      }
    }
  },
  methods: {
    setUI: function setUI() {
      this.bgImg = {
        backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='270' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='50%25' y='50%25' font-size='14' fill-opacity='0.2' text-anchor='middle' dominant-baseline='middle' transform='rotate(-20, 100 100)'%3E".concat(this.companyName + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + this.options.empName, "%3C/text%3E%3C/svg%3E\")")
      };
    },
    // 取消
    cancel: function cancel() {
      this.$emit("change", {
        visible: false,
        options: {}
      });
    }
  }
};