import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.push.js";
// import moment from 'moment';
import { formatDateAll } from "@/utils/index";
import { addNewsInfos, updateNewsInfos } from "@/api/company/news";
export default {
  name: "Preview",
  filters: {
    formatDateAll: formatDateAll
  },
  props: {
    noticeTitle: {
      type: String,
      default: ""
    },
    noticeFileList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    noticeContent: {
      type: String,
      default: ""
    },
    noticeContentHasStyle: {
      type: String,
      default: ""
    },
    groupCode: {
      type: String,
      default: ""
    },
    noticeTime: {
      type: String,
      default: ""
    },
    newsTypeId: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      name: "",
      publishVisible: false,
      isTop: true,
      informEmployee: true,
      isPublishNow: "",
      publishDate: "",
      companyId: ""
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
  },
  methods: {
    backStep: function backStep() {
      this.$emit("current", 1);
    },
    NextStep: function NextStep() {
      // 确认发布
      this.publishVisible = true;
    },
    confirmPublish: function confirmPublish() {
      var _this = this;
      var newsTypeAddDTO = {
        name: this.noticeTitle,
        pushDate: this.noticeTime,
        newsGroupCode: this.groupCode,
        newsFileAddDTOList: this.noticeFileList,
        newsInfoAddDTO: {
          title: this.noticeTitle,
          content: this.noticeContent,
          isPush: false,
          contentHasStyle: this.noticeContentHasStyle,
          publishDate: this.publishDate
        }
      };
      this.noticeFileList.forEach(function (item, index) {
        item.sort = index;
      });
      if (this.newsTypeId) {
        var newsTypeUpdateDTO = {
          pushDate: this.noticeTime,
          id: this.newsTypeId,
          title: this.noticeTitle,
          newsFileUpdateDTOList: this.noticeFileList,
          content: this.noticeContent,
          contentHasStyle: this.noticeContentHasStyle
        };
        updateNewsInfos(newsTypeUpdateDTO).then(function (res) {
          _this.publishVisible = false;
          _this.$message.success("发布成功");
          _this.$router.push({
            path: "/news/manage"
          });
        });
      } else {
        addNewsInfos(newsTypeAddDTO).then(function (res) {
          _this.publishVisible = false;
          _this.$message.success("发布成功");
          _this.$router.push({
            path: "/news/manage"
          });
        });
      }
    },
    handleChange: function handleChange(value, dateString) {
      this.isPublishNow = false;
      this.publishDate = dateString;
    }
  }
};