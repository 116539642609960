import rTitle from "../../component/rTitle";
import upload from "./component/upload";
import preview from "./component/preview";
import results from "./component/results";
export default {
  components: {
    rTitle: rTitle,
    upload: upload,
    preview: preview,
    results: results
  },
  data: function data() {
    return {
      current: 0,
      steps: [{
        title: "上传文件"
      }, {
        title: "数据预览"
      }, {
        title: "查看结果"
      }],
      status: false,
      rdata: "",
      talentPoolTempId: ""
    };
  },
  created: function created() {
    if (this.$store.state.recruitment.talentPoolTempId) {
      this.current = 1;
      this.talentPoolTempId = this.$store.state.recruitment.talentPoolTempId;
    }
  },
  methods: {
    onAction: function onAction(e, id) {
      this.current = e;
      if (id) {
        this.talentPoolTempId = id;
      }
    },
    onActionS: function onActionS(e, status, data) {
      this.current = e;
      this.status = status;
      this.rdata = data;
    }
  }
};