import { selectEventByEmpIdReq } from "./api";
import InfoItem from "./components/infoItem";
export default {
  name: "",
  components: {
    InfoItem: InfoItem
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      dataLst: []
    };
  },
  computed: {
    empId: function empId() {
      return this.$route.query.id;
    }
  },
  watch: {
    empId: {
      handler: function handler() {
        this.loadData();
      },
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    loadData: function loadData() {
      var _this = this;
      if (this.empId) {
        selectEventByEmpIdReq(this.empId).then(function (res) {
          _this.dataLst = res.data;
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  }
};