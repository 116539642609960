import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { deleteSalaryGroup, getRules } from "@/api/wages/set";
var columns = [{
  title: "方案名称",
  dataIndex: "name",
  key: "name",
  width: 200
}, {
  title: "个税类型",
  dataIndex: "type",
  key: "type",
  width: 200,
  scopedSlots: {
    customRender: "type"
  }
}, {
  title: "是否计税",
  dataIndex: "isCalculate",
  key: "isCalculate",
  width: 100,
  scopedSlots: {
    customRender: "isCalculate"
  }
}, {
  title: "起征点",
  key: "threshold",
  dataIndex: "threshold",
  width: 150,
  scopedSlots: {
    customRender: "threshold"
  }
}, {
  title: "结果小数位",
  key: "taxResult",
  dataIndex: "taxResult",
  width: 200,
  scopedSlots: {
    customRender: "taxResult"
  }
}, {
  title: "操作",
  key: "action",
  scopedSlots: {
    customRender: "action"
  },
  width: 100
}];
var monthRule = ["按正式/调薪后的工资计算", "按试用期/调薪前的工资计算", "按转正/调薪前后的工资混合计算"];
var taxRule = ["工资薪金所得", "劳动报酬所得", "不计税"];
export default {
  data: function data() {
    return {
      deleteId: "",
      ruleList: [],
      companyId: "",
      columns: columns,
      groupVisible: false,
      loading: true,
      monthRule: monthRule,
      taxRule: taxRule
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.getData();
  },
  methods: {
    editClass: function editClass(id) {
      this.$router.push({
        path: "/wages/set/group/add",
        query: {
          isEdit: 1,
          groupId: id
        }
      });
    },
    handleDelete: function handleDelete() {
      var _this = this;
      deleteSalaryGroup(this.deleteId).then(function (res) {
        _this.groupVisible = false;
        _this.$message.success("删除成功");
        _this.getData();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    enterDetail: function enterDetail(id, type) {
      if (type === 1) {
        this.$router.push({
          path: "/wages/set/rule/edit",
          query: {
            taxRuleId: id
          }
        });
      } else {
        this.$router.push({
          path: "/wages/set/rule/detail",
          query: {
            taxRuleId: id
          }
        });
      }
    },
    getData: function getData() {
      var _this2 = this;
      this.loading = true;
      var data = {
        companyId: this.companyId
      };
      getRules(data).then(function (res) {
        _this2.loading = false;
        _this2.ruleList = res.data;
        _this2.ruleList.forEach(function (item, index) {
          if (item.empInfoList && item.empInfoList.length > 0) {
            item.range = "".concat(item.empInfoList[0].name, "\u7B49").concat(item.empInfoList.length, "\u540D\u5458\u5DE5");
          } else item.range = "";
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this2.loading = false;
      });
    }
  }
};