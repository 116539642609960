var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-steps", {
    attrs: {
      current: _vm.current
    }
  }, [_c("a-step", {
    attrs: {
      title: "上传并核对工资条"
    }
  }), _c("a-step", {
    attrs: {
      title: "选择发送方式"
    }
  }), _c("a-step", {
    attrs: {
      title: "发送工资条"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };