export default {
  fields: {
    name: {
      model: "name"
    },
    companyName: {
      model: "companyName"
    },
    authName: {
      model: "authName"
    },
    period: {
      model: "period"
    },
    status: {
      model: "status"
    },
    gmtCreate: {
      model: "gmtCreate"
    },
    thirdPartyInteriorBatchId: {
      model: "thirdPartyInteriorBatchId"
    },
    number: {
      model: "number"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "name",
        title: "批次名称"
      }, {
        model: "companyName",
        title: "企业名称"
      }, {
        model: "authName",
        title: "所属税局"
      }, {
        model: "period",
        title: "税款所属月"
      }, {
        model: "status",
        title: "算税状态"
      }, {
        model: "gmtCreate",
        title: "申请时间"
      }, {
        model: "number",
        title: "算税人数"
      }],
      customize: {
        rowKey: "id"
        // pagination: {
        //   pageSize: 10
        // }
      },

      extend: {
        batch: false,
        action: true
      }
    }
  }
};