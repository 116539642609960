export default {
  props: {
    doWorkRulesOptions: {
      type: Object,
      default: function _default() {
        return {
          show: false,
          obj: {}
        };
      }
    }
  },
  data: function data() {
    return {
      visible: false,
      obj: {
        overtimeRule: 1,
        nonWorkDayRuleDTO: {
          isNonWorkDay: true,
          longestMinutes: 240,
          shortMinutes: 30
        },
        workDayRuleDTO: {
          isWorkDay: true,
          longestMinutes: 240,
          shortMinutes: 30,
          startMinutes: 30
        }
      }
    };
  },
  watch: {
    doWorkRulesOptions: function doWorkRulesOptions(val) {
      this.visible = val.show;
    }
  },
  methods: {
    okHander: function okHander() {
      this.$emit("addWorkRules", this.obj);
      this.visible = false;
    }
  }
};