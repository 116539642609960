var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("div", [_vm._l(_vm.resumeList, function (i) {
    return _c("div", {
      key: i.enName,
      staticClass: "textLine"
    }, [_c("div", {
      staticClass: "textLineLeft"
    }, [_vm._v(_vm._s(i.fieldName) + "：")]), i.fieldTypeStr === "upload" ? _c("div", {
      staticClass: "textLineRig"
    }, _vm._l(i.content, function (z) {
      return _c("div", {
        key: z,
        staticClass: "textLineRigIMg"
      }, [_c("img", {
        attrs: {
          width: "100%",
          height: "100%",
          src: z,
          alt: ""
        }
      })]);
    }), 0) : _c("div", {
      staticClass: "textLineRig"
    }, [i.enName === "skill" ? _c("span", _vm._l(i.content, function (z) {
      return _c("span", {
        key: z,
        staticClass: "skillBox"
      }, [_vm._v(_vm._s(z))]);
    }), 0) : _c("span", [i.fieldTypeStr === "area" ? _c("span", [i.content ? _c("city", {
      attrs: {
        content: i.content
      }
    }) : _vm._e()], 1) : _c("span", [_vm._v(" " + _vm._s(i.content))]), i.enName === "workYears" ? _c("span", [_vm._v("年 ")]) : _vm._e()])])]);
  }), _vm.showAnnex ? [_c("div", {
    staticClass: "Attachment"
  }, [_vm._v("附件简历")]), _c("resume", {
    attrs: {
      src: _vm.src,
      upload: false,
      action: ["download"]
    },
    on: {
      upData: _vm.upDataSrc
    }
  })] : _vm._e()], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };