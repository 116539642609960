var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-popover", {
    attrs: {
      visible: _vm.qrcodeVisible
    }
  }, [_c("a-button", {
    staticClass: "mr12",
    on: {
      click: _vm.showQrcode
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c("div", {
    staticClass: "pv8",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v("登记二维码")]), _c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", {
    staticClass: "p8",
    staticStyle: {
      width: "216px",
      height: "216px",
      display: "flex",
      "align-items": "center",
      "justify-content": "center"
    },
    attrs: {
      id: "qrbox"
    }
  }, [_vm.loading ? _c("a-spin", {
    attrs: {
      tip: "加载中"
    }
  }) : _c("img", {
    attrs: {
      src: _vm.imgUrl,
      width: "100%",
      height: "100%"
    }
  })], 1), _c("div", {
    staticClass: "mv12 fs12",
    staticStyle: {
      color: "#a6a8b4",
      width: "216px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("* ")]), _vm._v(" 保存二维码，分享到微信，长按识别录入信息，或者直接微信扫码录入 ")]), _c("div", {
    staticClass: "mt12",
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("a-space", [_c("a-button", {
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.onGetDownload
    }
  }, [_vm._v("保存")]), _c("a-button", {
    attrs: {
      type: "default",
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.qrcodeVisible = false;
      }
    }
  }, [_vm._v("取消")])], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };