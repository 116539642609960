import "core-js/modules/es.array.push.js";
import information from "./information";
import visit from "./visit";
import distribution from "./distribution";
export default {
  components: {
    information: information,
    visit: visit,
    distribution: distribution
  },
  data: function data() {
    return {
      componentName: "information",
      numArr: [{
        type: "information",
        name: "客户信息",
        num: 0
      }, {
        type: "visit",
        name: "拜访记录",
        num: 0
      }, {
        type: "distribution",
        name: "分配记录",
        num: 0
      }],
      address: "",
      isSign: false
    };
  },
  created: function created() {
    if (parseInt(this.$route.query.type) === 1) {
      this.isSign = false;
    }
    if (parseInt(this.$route.query.type) === 2) {
      this.isSign = true;
    }
  },
  methods: {
    upDate: function upDate(e) {
      this.isSign = e;
    },
    upDateAddress: function upDateAddress(e) {
      this.address = e;
    },
    onSigning: function onSigning() {
      var path = this.$route.path.split("/");
      this.$router.push({
        path: "/clientele/".concat(path[2], "/edit"),
        query: {
          type: 2,
          id: this.$route.query.id
        }
      });
    }
  }
};