import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
var commonRoutes = [{
  path: "manger",
  // 员工异动
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/index"));
    });
  }
}, {
  path: "employee",
  // 花名册
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/employee"));
    });
  }
}, {
  path: "upload",
  // 批量导入新员工
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/upload"));
    });
  }
}, {
  path: "uploadFile",
  // 批量导入合同
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/uploadFile"));
    });
  }
}, {
  path: "batchEdit",
  // 批量修改员工信息
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/batchEdit"));
    });
  }
}, {
  path: "fileform",
  // 材料附件
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/fileform"));
    });
  }
}, {
  path: "fileformAdditional",
  // 材料附件
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/fileformAdditional"));
    });
  }
}, {
  path: "detail",
  // 员工详情
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/info/detail"));
    });
  }
}, {
  path: "addEmpInfo",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/info/addEmpInfo"));
    });
  }
}, {
  path: "leave",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/info/leave"));
    });
  }
}, {
  path: "transfer",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/info/transfer"));
    });
  }
}, {
  path: "edit",
  // 员工设置
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/edit/index"));
    });
  }
}, {
  path: "moveTag",
  // 已调岗记录
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/component/MoveList"));
    });
  }
}, {
  path: "LeaveList",
  // 离职记录
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/component/LeaveList"));
    });
  }
}, {
  path: "employee/todayAdd",
  // 今日新增
  name: "staff/employee/todayAdd",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staff/management/todayAdd"));
    });
  }
}];
export var StaffRouter = {
  path: "/staff",
  // 人事管理
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  redirect: "manger",
  children: [].concat(commonRoutes, [{
    path: "personalInfo/:type",
    // 个人信息设置
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/field"));
      });
    }
  }, {
    path: "zaizhixinxi/:type",
    // 在职信息设置
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/field"));
      });
    }
  }, {
    path: "socialSecurity/:type",
    // 员工社保设置
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/field"));
      });
    }
  }, {
    path: "information/:type",
    // 材料附件设置
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/field"));
      });
    }
  }, {
    path: "entryTemplateSet/:type",
    meta: {
      title: "入职模板设置"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/entryTemplateSet4"));
      });
    }
  }, {
    path: "hiredEntryTemplateSet/:type",
    meta: {
      title: "待入职模板设置"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/entryTemplateSet4"));
      });
    }
  }, {
    path: "edit/newTemplate",
    meta: {
      title: "新建入职模板设置"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/newTemplate"));
      });
    }
  }, {
    path: "management/import",
    // 待离职批量导入
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/management/import"));
      });
    }
  }])
};
export var StaffCRouter = {
  path: "/staffc",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/adminNew"));
    });
  },
  redirect: "manger",
  children: [].concat(commonRoutes, [{
    path: "setting3",
    meta: {
      title: "个人信息设置"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/setting"));
      });
    }
  }, {
    path: "setting2",
    meta: {
      title: "员工社保设置"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/setting2"));
      });
    }
  }, {
    path: "setting3",
    meta: {
      title: "材料附件设置"
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/staff/edit/setting3"));
      });
    }
  }])
};