export var tableNameJson = {
  empInfoByCompany: "花名册",
  eleContract: "签署记录",
  paperContract: "纸质合同",
  allContract: "全部合同",
  declareWage: "正常工资薪金申报",
  declareEmpDetails: "个税人员信息申报",
  declareBonus: "全年一次性奖金申报",
  checkCard: "考勤打卡",
  checkTable: "每日考勤",
  empInfo: "员工概览",
  customerDetail: "客户明细表",
  billingDetail: "薪资账单",
  prePersonnel: "预登记人员",
  overviewContractDetail: "管理端合同概览",
  taxCalculateReport: "智能算税人员报送"
};
export var tableAdmin = ["empInfo", "customerDetail", "billingDetail", "prePersonnel", "recruitmentTalent", "overviewContractDetail", "taxCalculateReport"];