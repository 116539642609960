import request from "@/utils/request";
export function getCompanyUnits(params) {
  return request({
    url: "/recruit2/api/v1/companyUnits/pageByCondition",
    method: "GET",
    params: params
  });
}
export function postCompanyUnits(data) {
  return request({
    url: "/recruit2/api/v1/companyUnits",
    method: "post",
    data: data
  });
}
export function putCompanyUnits(data) {
  return request({
    url: "/recruit2/api/v1/companyUnits",
    method: "put",
    data: data
  });
}

// export function delCompanyUnits(companyUnitsId) {
//   return request({
//     url: `/recruit2/api/v1/companyUnits/${companyUnitsId}`,
//     method: "delete"
//   });
// }
export function delCompanyUnits(companyUnitsId) {
  return request({
    url: "/recruit2/api/v1/companyUnits/deleteCompanyUnits/".concat(companyUnitsId),
    method: "delete"
  });
}
export function getCompanies(params) {
  return request({
    url: "hr/api/v1/companies/listAllNoSynchronization",
    method: "GET",
    params: params
  });
}
export function postSynchronizationCompanyToRecruit(data) {
  return request({
    url: "/hr/api/v1/companies/synchronizationCompanyToRecruit",
    method: "post",
    data: data
  });
}