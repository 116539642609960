var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "add-payslip-forth page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "content-con"
  }, [_c("a-result", {
    attrs: {
      status: "success",
      title: "发薪申请已提交"
    },
    scopedSlots: _vm._u([{
      key: "extra",
      fn: function fn() {
        return [_c("a-row", {
          attrs: {
            type: "flex"
          }
        }, [_c("a-col", {
          staticClass: "col-left"
        }, [_c("xq-icon", {
          staticClass: "primary_color w32 f30",
          staticStyle: {
            display: "block"
          },
          attrs: {
            type: "icon-wancheng1"
          }
        }), _c("a-divider", {
          staticClass: "img-v",
          attrs: {
            type: "vertical"
          }
        }), _c("xq-icon", {
          staticClass: "primary_color w32 f30",
          staticStyle: {
            display: "block"
          },
          attrs: {
            type: "icon-wancheng1"
          }
        })], 1), _c("a-col", {
          staticClass: "full",
          staticStyle: {
            "text-align": "left"
          }
        }, [_c("a-row", {
          staticClass: "content-con-text mg_b24"
        }, [_c("a-col", {
          staticClass: "mg_b12 round"
        }, [_c("strong", [_vm._v(_vm._s(_vm.paySalaryOrderTitle) + "发薪已提交")])]), _c("a-col", {
          staticClass: "sub_tit"
        }, [_vm._v(" 可在发薪申请页面查看审批进度 ")])], 1), _c("a-row", {
          staticClass: "content-con-text"
        }, [_c("a-col", {
          staticClass: "mg_b12 round"
        }, [_c("strong", [_vm._v(" 发薪 "), _c("span", {
          staticClass: "primary"
        }, [_vm._v(" " + _vm._s(_vm.paySalaryTotal))]), _vm._v(" 人 ")]), _c("strong", [_vm._v(" 总金额 "), _c("span", {
          staticClass: "primary"
        }, [_vm._v(" ￥" + _vm._s(_vm.paySalaryMoney))])])]), _c("a-col", {
          staticClass: "sub_tit"
        }, [_vm._v(" 到账方式：支持" + _vm._s(_vm.paySalaryTypes) + "到账 "), _c("span", {
          staticClass: "primary mg_l4 mg_r4"
        }), _vm._v(" 申请时间： " + _vm._s(_vm.paySalaryGmtCreate) + " ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goDetail
    }
  }, [_vm._v("查看详情 ")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };