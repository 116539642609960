var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "bgc-white"
  }, [_c("a-row", {
    staticClass: "p24 btmline"
  }, [_c("a-col", {
    attrs: {
      span: 2
    }
  }, [_c("a-icon", {
    staticClass: "fs20",
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  })], 1), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goback
    }
  }, [_vm._v("返回")])], 1), _c("a-collapse", {
    staticClass: "p24",
    attrs: {
      bordered: false
    },
    model: {
      value: _vm.activeKey,
      callback: function callback($$v) {
        _vm.activeKey = $$v;
      },
      expression: "activeKey"
    }
  }, [_c("a-collapse-panel", {
    key: "1",
    attrs: {
      header: "工资卡信息"
    }
  }, [_c("a-col", {
    staticClass: "border-r-line",
    attrs: {
      span: 12
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.table,
      "data-source": _vm.data1,
      "row-key": "enName",
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return _c("span", {}, [_c("a-checkbox", {
          attrs: {
            checked: record.unique == 1
          },
          on: {
            change: function change($event) {
              return _vm.watch(record, "unique");
            }
          }
        })], 1);
      }
    }])
  }, [_c("span", {
    attrs: {
      slot: "open"
    },
    slot: "open"
  }, [_c("a-checkbox", {
    attrs: {
      "default-checked": "",
      disabled: ""
    }
  })], 1)])], 1), _c("a-col", {
    staticClass: "border-r-line",
    attrs: {
      span: 12
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.table,
      "data-source": _vm.data11,
      "row-key": "enName",
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return _c("span", {}, [_c("a-checkbox", {
          attrs: {
            checked: record.unique == 1
          },
          on: {
            change: function change($event) {
              return _vm.watch(record, "unique");
            }
          }
        })], 1);
      }
    }])
  }, [_c("span", {
    attrs: {
      slot: "open"
    },
    slot: "open"
  }, [_c("a-checkbox", {
    attrs: {
      "default-checked": "",
      disabled: ""
    }
  })], 1)])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };