import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      backLock: false
    }
  }, [_vm._v(" 成员管理 ")]), _c("div", {
    staticClass: "list-box"
  }, [_c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("div", {
    staticClass: "xq-form-search"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "266px"
    },
    attrs: {
      allowClear: "",
      placeholder: "搜索员工姓名"
    },
    on: {
      search: _vm.handleChange
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c("div", {
    staticStyle: {
      "text-align": "right",
      "margin-top": "-32px"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.shows != 1
    },
    on: {
      click: function click($event) {
        return _vm.onAdd("add");
      }
    }
  }, [_vm._v(" 新增权限 ")])], 1)], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "jurisdiction" ? _c("span", {
            key: index
          }, [text === 1 ? _c("span", [_vm._v("客户经理")]) : _vm._e(), text === 2 ? _c("span", [_vm._v("客户专员")]) : _vm._e()]) : col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("a", {
            on: {
              click: function click($event) {
                return _vm.onAdd("edit", record);
              }
            }
          }, [_vm._v("配置权限")]), _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.onView(record);
              }
            }
          }, [_vm._v("查看客户")])], 1) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1)]), _c("add", {
    attrs: {
      visible: _vm.addVisible,
      type: _vm.atype,
      info: _vm.info
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addVisible = $event;
      },
      upDate: _vm.upDate
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };