var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm._m(0), _c("a-table", {
    attrs: {
      columns: _vm.columns,
      pagination: {
        pageSize: 5
      },
      "data-source": _vm.data,
      bordered: ""
    },
    scopedSlots: _vm._u([_vm._l(["cardinalityDefault"], function (col) {
      return {
        key: col,
        fn: function fn(text, record) {
          return [_c("div", {
            key: col
          }, [record.editable ? _c("a-input-number", {
            staticStyle: {
              margin: "-5px 0"
            },
            attrs: {
              min: 0,
              max: col === "cardinalityDefault" ? 1000000000000 : 100,
              precision: 2,
              value: text
            },
            on: {
              change: function change(e) {
                return _vm.handleChange(e, record.key, col, "type1");
              }
            }
          }) : [_vm._v(_vm._s(text || "--"))]], 2)];
        }
      };
    })], null, true)
  }), _vm._m(1), _c("a-table", {
    attrs: {
      columns: _vm.columns,
      pagination: {
        pageSize: 5
      },
      "data-source": _vm.data1,
      bordered: ""
    },
    scopedSlots: _vm._u([_vm._l(["cardinalityDefault"], function (col) {
      return {
        key: col,
        fn: function fn(text, record) {
          return [_c("div", {
            key: col
          }, [record.editable ? _c("a-input-number", {
            staticStyle: {
              margin: "-5px 0"
            },
            attrs: {
              min: 0,
              max: col === "cardinalityDefault" ? 1000000000000 : 100,
              precision: 2,
              value: text
            },
            on: {
              change: function change(e) {
                return _vm.handleChange(e, record.key, col, "type2");
              }
            }
          }) : [_vm._v(_vm._s(text || "--"))]], 2)];
        }
      };
    })], null, true)
  }), _vm._m(2), _c("a-table", {
    attrs: {
      columns: _vm.columns,
      pagination: {
        pageSize: 5
      },
      "data-source": _vm.data2,
      bordered: ""
    },
    scopedSlots: _vm._u([_vm._l(["cardinalityDefault"], function (col) {
      return {
        key: col,
        fn: function fn(text, record) {
          return [_c("div", {
            key: col
          }, [record.editable ? _c("a-input-number", {
            staticStyle: {
              margin: "-5px 0"
            },
            attrs: {
              min: 0,
              max: col === "cardinalityDefault" ? 1000000000000 : 100,
              precision: 2,
              value: text
            },
            on: {
              change: function change(e) {
                return _vm.handleChange(e, record.key, col, "type3");
              }
            }
          }) : [_vm._v(_vm._s(text || "--"))]], 2)];
        }
      };
    })], null, true)
  }), _vm.editable ? _c("div", {
    staticClass: "mt24",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("a-button", {
    staticClass: "mr24",
    on: {
      click: function click($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("确定")])], 1) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "FixedBox"
  }, [_c("span", {
    staticClass: "FixedBoxLine"
  }), _vm._v("社保")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "FixedBox"
  }, [_c("span", {
    staticClass: "FixedBoxLine"
  }), _vm._v("医保")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "FixedBox"
  }, [_c("span", {
    staticClass: "FixedBoxLine"
  }), _vm._v("公积金")]);
}];
render._withStripped = true;
export { render, staticRenderFns };