import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/web.timers.js";
import ItemList from "./planList.vue";
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {
    ItemList: ItemList
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      groupModalTitle: "添加公司",
      groupModalVisible: false,
      groupForm: this.$form.createForm(this),
      groupLst: [],
      tempGroup: null,
      confirmLoadingGroup: false,
      groupTerm: "",
      currentGroup: "",
      emptyImg: require("../../admin/chanpinjieshao/empty.png")
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["permissions_shangbao"])), {}, {
    hasAdd: function hasAdd() {
      return this.permissions_shangbao.includes("insuranceAddService:insuranceAddService.shangbaofangan:addInsCompany");
    }
  }),
  watch: {},
  created: function created() {
    this.onGroupSearchChange();
  },
  mounted: function mounted() {},
  methods: {
    onGroupSearchChange: function onGroupSearchChange() {
      var _this = this;
      this.$request({
        url: "/insurance/api/v1/qyCompanies/selectBySite"
      }).then(function (res) {
        _this.groupLst = res.data;
        var idx = res.data.findIndex(function (item) {
          return item.id === _this.currentGroup;
        });
        if (idx === -1) {
          _this.currentGroup = "";
        }
      }).catch(function (err) {
        console.log("qyCompanies/selectBySite", err);
      });
    },
    onDelGroupItem: function onDelGroupItem() {
      var _this2 = this;
      this.$confirm({
        title: "删除确认",
        content: "公司删除后将无法恢复，确认要删除吗？",
        onOk: function onOk() {
          _this2.$request({
            url: "/insurance/api/v1/qyCompanies/".concat(_this2.tempGroup.id),
            method: "delete"
          }).then(function () {
            _this2.$message.success("删除成功");
            _this2.onGroupSearchChange();
            _this2.groupModalVisible = false;
          }).catch(function (err) {
            console.log("deleteReq qyCompanies", err);
          });
        }
      });
    },
    onAddGroupItem: function onAddGroupItem() {
      this.groupForm.resetFields();
      this.groupModalTitle = "添加公司";
      this.tempGroup = null;
      this.groupModalVisible = true;
    },
    onEditGroupItem: function onEditGroupItem(target) {
      var _this3 = this;
      this.tempGroup = target;
      this.groupModalTitle = "编辑公司";
      this.groupModalVisible = true;
      setTimeout(function () {
        _this3.groupForm.setFieldsValue({
          companyName: target.companyName
        });
      }, 0);
    },
    onGroupModalOk: function onGroupModalOk() {
      var _this4 = this;
      this.groupForm.validateFields(function (err, values) {
        if (!err) {
          var newValues = {
            companyName: values.companyName
          };
          _this4.confirmLoadingGroup = true;
          if (_this4.groupModalTitle === "添加公司") {
            _this4.$request({
              url: "/insurance/api/v1/qyCompanies",
              method: "post",
              data: newValues
            }).then(function (res) {
              _this4.$message.success("添加成功");
              _this4.onGroupSearchChange();
              _this4.groupModalVisible = false;
              _this4.confirmLoadingGroup = false;
            }).catch(function (err) {
              _this4.confirmLoadingGroup = false;
              console.log("saveReq qyCompanies", err);
            });
          } else {
            _this4.$request({
              url: "/insurance/api/v1/qyCompanies",
              method: "put",
              data: _objectSpread(_objectSpread({}, newValues), {}, {
                id: _this4.tempGroup.id
              })
            }).then(function (res) {
              _this4.$message.success("修改成功");
              _this4.onGroupSearchChange();
              _this4.groupModalVisible = false;
              _this4.confirmLoadingGroup = false;
            }).catch(function (err) {
              _this4.confirmLoadingGroup = false;
              console.log("updateReq qyCompanies", err);
            });
          }
        }
      });
    }
  }
};