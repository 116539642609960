import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  path: "/shangbao",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/shangbao"));
    });
  },
  children: [{
    path: "list",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/shangbao/list"));
      });
    }
  }, {
    path: "plan",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/shangbao/plan"));
      });
    }
  }]
};