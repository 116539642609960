var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      width: 820,
      title: "打卡时间",
      "destroy-on-close": true,
      visible: _vm.showCardTime
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("handleTimeShow", false);
      },
      ok: _vm.handleOk
    }
  }, [_c("a-form", {
    staticClass: "lab_l form_l"
  }, [_c("a-form-item", [_c("div", {
    staticClass: "box"
  }, [_c("div", {
    staticClass: "icon"
  }, [_c("a-icon", {
    attrs: {
      type: "clock-circle",
      theme: "filled"
    }
  }), _vm._v(" "), _c("span", [_vm._v("当前打卡时间")])], 1), _vm._l(_vm.timeList.periodList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "time"
    }, [_vm._v(" 上班：" + _vm._s(_vm.momentTime(item.startPeriod, "earliestPunchTime")) + "-" + _vm._s(_vm.momentTime(item.startPeriod, "latestOnPunchTime")) + " 为正常上班打卡时间； 下班：" + _vm._s(item.endPeriod) + "-" + _vm._s(_vm.momentTime(item.endPeriod, "latestPunchTime")) + " 为正常下班打卡时间； ")]);
  })], 2)]), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "工作日：",
      "label-col": {
        span: 3
      },
      "warpper-col": {
        span: 16
      }
    }
  }, [_c("a-checkbox-group", {
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.timeList.workWeekList,
      callback: function callback($$v) {
        _vm.$set(_vm.timeList, "workWeekList", $$v);
      },
      expression: "timeList.workWeekList"
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期一"
    }
  }, [_vm._v("星期一")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期二"
    }
  }, [_vm._v("星期二")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期三"
    }
  }, [_vm._v("星期三")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期四"
    }
  }, [_vm._v("星期四")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期五"
    }
  }, [_vm._v("星期五")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期六"
    }
  }, [_vm._v("星期六")])], 1), _c("a-col", [_c("a-checkbox", {
    attrs: {
      value: "星期日"
    }
  }, [_vm._v("星期日")])], 1)], 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      colon: false,
      label: "打卡时间：",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 21
      }
    }
  }, [_vm._l(_vm.timeList.periodList, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("a-row", [index === 0 ? _c("a-col", [_c("span", {
      staticClass: "mg_r10"
    }, [_vm._v("上班")]), _c("a-time-picker", {
      attrs: {
        value: _vm.moment("".concat(item.startPeriod), "HH:mm"),
        format: "HH:mm",
        "allow-clear": false
      },
      on: {
        change: _vm.onWorkChange
      }
    }), _vm._v(" - "), _c("span", {
      staticClass: "mg_r10 sub_tit"
    }, [_vm._v("下班")]), _c("a-time-picker", {
      attrs: {
        value: _vm.moment("".concat(item.endPeriod), "HH:mm"),
        format: "HH:mm",
        "allow-clear": false
      },
      on: {
        change: _vm.onOffChange
      }
    }), _vm.timeList.periodList.length < 2 && index === 0 ? _c("a-icon", {
      staticClass: "addIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "plus-circle"
      },
      on: {
        click: _vm.addWorkList
      }
    }) : _vm._e()], 1) : _vm._e(), index === 1 ? _c("a-col", [_c("span", {
      staticClass: "mg_r10"
    }, [_vm._v("上班")]), _c("a-time-picker", {
      attrs: {
        value: _vm.moment("".concat(item.startPeriod), "HH:mm"),
        format: "HH:mm",
        "allow-clear": false
      },
      on: {
        change: _vm.onWorkChange2
      }
    }), _vm._v(" - "), _c("span", {
      staticClass: "mg_r10 sub_tit"
    }, [_vm._v("下班")]), _c("a-time-picker", {
      attrs: {
        value: _vm.moment("".concat(item.endPeriod), "HH:mm"),
        format: "HH:mm",
        "allow-clear": false
      },
      on: {
        change: _vm.onOffChange2
      }
    }), _vm.timeList.periodList.length > 1 && index > 0 ? _c("a-icon", {
      staticClass: "deleteIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.deleteNumList(item, index);
        }
      }
    }) : _vm._e()], 1) : _vm._e()], 1)], 1);
  }), _c("a-row", [_c("a-checkbox", {
    model: {
      value: _vm.timeList.periodList[0].hasRestPeriod,
      callback: function callback($$v) {
        _vm.$set(_vm.timeList.periodList[0], "hasRestPeriod", $$v);
      },
      expression: "timeList.periodList[0].hasRestPeriod"
    }
  }, [_vm._v(" 休息时间 ")]), _c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("休息时间不算工作时长")]), _vm.timeList.periodList[0].hasRestPeriod ? _c("div", [_c("span", {
    staticClass: "mg_r10"
  }, [_vm._v("开始")]), _c("a-time-picker", {
    attrs: {
      "default-value": _vm.moment("12:00", "HH:mm"),
      format: "HH:mm",
      value: _vm.timeList.restPeriod.startPeriod ? _vm.moment("".concat(_vm.timeList.restPeriod.startPeriod), "HH:mm") : _vm.timeList.restPeriod.startPeriod,
      "allow-clear": false
    },
    on: {
      change: _vm.onRestChange
    }
  }), _vm._v(" - "), _c("span", {
    staticClass: "mg_r10 sub_tit"
  }, [_vm._v("结束")]), _c("a-time-picker", {
    attrs: {
      "default-value": _vm.moment("13:00", "HH:mm"),
      format: "HH:mm",
      value: _vm.timeList.restPeriod.endPeriod ? _vm.moment("".concat(_vm.timeList.restPeriod.endPeriod), "HH:mm") : _vm.timeList.restPeriod.endPeriod,
      "allow-clear": false
    },
    on: {
      change: _vm.offRestChange
    }
  })], 1) : _vm._e()], 1)], 2), [_c("a-form-item", {
    staticClass: "primary_color",
    attrs: {
      colon: false,
      label: "打卡时间限制 ",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 21
      }
    }
  }, [_c("a-row", [_c("a-input-number", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      min: 0,
      precision: 0,
      formatter: function formatter(value) {
        return "".concat(value, "\u5206\u949F");
      }
    },
    model: {
      value: _vm.timeList.earliestPunchTime,
      callback: function callback($$v) {
        _vm.$set(_vm.timeList, "earliestPunchTime", $$v);
      },
      expression: "timeList.earliestPunchTime"
    }
  }), _c("span", {
    staticClass: "sub_tit mg_l10 mg_r10"
  }, [_vm._v("最早上班打卡时间")])], 1)], 1), _c("a-form-item", {
    staticClass: "primary_color",
    attrs: {
      colon: false,
      label: "打卡时间限制 ",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 21
      }
    }
  }, [_c("a-row", [_c("a-input-number", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      min: 0,
      precision: 0,
      formatter: function formatter(value) {
        return "".concat(value, "\u5206\u949F");
      }
    },
    model: {
      value: _vm.timeList.latestOnPunchTime,
      callback: function callback($$v) {
        _vm.$set(_vm.timeList, "latestOnPunchTime", $$v);
      },
      expression: "timeList.latestOnPunchTime"
    }
  }), _c("span", {
    staticClass: "sub_tit mg_l10 mg_r10"
  }, [_vm._v("最晚上班打卡时间")]), _c("a-input-number", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      min: 0,
      precision: 0,
      formatter: function formatter(value) {
        return "".concat(value, "\u5206\u949F");
      }
    },
    model: {
      value: _vm.timeList.latestPunchTime,
      callback: function callback($$v) {
        _vm.$set(_vm.timeList, "latestPunchTime", $$v);
      },
      expression: "timeList.latestPunchTime"
    }
  }), _c("span", {
    staticClass: "sub_tit mg_l10"
  }, [_vm._v("最晚下班打卡时间")])], 1)], 1)]], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };