import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState, mapGetters } from "vuex";
import logo from "./components/logo";
import footer from "./components/footer";
import SubMenu from "./components/SubMenu";
import Item from "./components/item";
export default {
  name: "Sidebar",
  components: {
    "xq-footer": footer,
    logo: logo,
    SubMenu: SubMenu,
    Item: Item
  },
  data: function data() {
    return {
      hasLogo: true
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(["sidebar", "permissions_menu", "permissions_menu_tree"])), mapState(["hr"])), {}, {
    menuTheme: function menuTheme() {
      console.log(localStorage.getItem("menuTheme"));
      return localStorage.getItem("menuTheme");
    },
    isCollapsed: function isCollapsed() {
      return !this.sidebar.opened;
    },
    hideMenu: function hideMenu() {
      var box = [];
      if (process.env.NODE_ENV === "development") {
        box = [];
      }
      //  else {
      //   if (this.hr.isCompany === 1) {
      //     box = ["/shebao/jiedan"];
      //   }
      //   if (this.hr.isCompany === 2) {
      //     box = ["/shebao/paidan"];
      //   }
      //   if (this.hr.isCompany === 3) {
      //     box = ["/shebao/jiedan", "/shebao/jiedan"];
      //   }
      // }
      return box;
    }
  }),
  watch: {},
  created: function created() {},
  methods: {
    // 第三方
    goOther: function goOther() {}
  }
};