var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "title"
  }, [_vm._v("筛选条件")]), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("已选模板：")]), _c("a-input", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      disabled: "",
      allowClear: ""
    },
    model: {
      value: _vm.templateName,
      callback: function callback($$v) {
        _vm.templateName = $$v;
      },
      expression: "templateName"
    }
  })], 1), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("姓名/身份证号：")]), _c("a-input", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      allowClear: "",
      placeholder: "姓名/身份证号"
    },
    model: {
      value: _vm.nameOrIdCard,
      callback: function callback($$v) {
        _vm.nameOrIdCard = $$v;
      },
      expression: "nameOrIdCard"
    }
  })], 1), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("事件类型：")]), _c("a-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      options: _vm.eventTypeLst,
      allowClear: "",
      placeholder: "请选择事件类型"
    },
    model: {
      value: _vm.eventType,
      callback: function callback($$v) {
        _vm.eventType = $$v;
      },
      expression: "eventType"
    }
  })], 1), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("目前流程：")]), _c("a-input", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      allowClear: "",
      placeholder: "目前流程"
    },
    model: {
      value: _vm.currentWorkflow,
      callback: function callback($$v) {
        _vm.currentWorkflow = $$v;
      },
      expression: "currentWorkflow"
    }
  })], 1), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("接单开始时间：")]), _c("a-date-picker", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      allowClear: "",
      placeholder: "接单开始时间"
    },
    model: {
      value: _vm.takeOrdersBeginDate,
      callback: function callback($$v) {
        _vm.takeOrdersBeginDate = $$v;
      },
      expression: "takeOrdersBeginDate"
    }
  })], 1), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("接单结束时间：")]), _c("a-date-picker", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      allowClear: "",
      placeholder: "接单结束时间"
    },
    model: {
      value: _vm.takeOrdersEndDate,
      callback: function callback($$v) {
        _vm.takeOrdersEndDate = $$v;
      },
      expression: "takeOrdersEndDate"
    }
  })], 1), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("用工单位：")]), _c("a-input", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      allowClear: "",
      placeholder: "用工单位"
    },
    model: {
      value: _vm.companyName,
      callback: function callback($$v) {
        _vm.companyName = $$v;
      },
      expression: "companyName"
    }
  })], 1), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("接单客服：")]), _c("a-input", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      allowClear: "",
      placeholder: "接单客服"
    },
    model: {
      value: _vm.takeOrdersUserName,
      callback: function callback($$v) {
        _vm.takeOrdersUserName = $$v;
      },
      expression: "takeOrdersUserName"
    }
  })], 1), _c("div", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("工作地点：")]), _c("a-input", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      allowClear: "",
      placeholder: "工作地点"
    },
    model: {
      value: _vm.workCity,
      callback: function callback($$v) {
        _vm.workCity = $$v;
      },
      expression: "workCity"
    }
  })], 1), _c("div", {
    staticStyle: {
      "text-align": "center",
      "margin-top": "24px"
    }
  }, [_c("a-space", [_c("a-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.doReset
    }
  }, [_vm._v("重置")]), _c("a-button", {
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.doSearch
    }
  }, [_vm._v("搜索")])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };