import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
var commonRoutes = [{
  path: "index",
  meta: {
    route: "shangyebaoxian/index",
    routeSession: "shangyebaoxianPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/shangyebaoxian/index"));
    });
  }
}, {
  path: "client",
  meta: {
    route: "shangyebaoxian/client/index",
    routeSession: "shangyebaoxianPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/shangyebaoxian/client/index"));
    });
  }
}, {
  path: "client/addClient",
  meta: {
    route: "shangyebaoxian/client/addClient",
    routeSession: "shangyebaoxianPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/shangyebaoxian/client/addClient"));
    });
  }
}, {
  path: "client/detail",
  meta: {
    route: "shangyebaoxian/client/detail",
    routeSession: "shangyebaoxianPage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/shangyebaoxian/client/detail"));
    });
  }
}];
export default {
  path: "/shangyebaoxian",
  // 商业保险
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/shangyebaoxian"));
    });
  },
  redirect: "/shangyebaoxian/client",
  children: [].concat(commonRoutes)
};