import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pb24 uplaodBox page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goback
    }
  }, [_vm._v(_vm._s(_vm.query.name) + "的" + _vm._s(_vm.query.fieldName))]), _c("div", {
    staticClass: "topMsg"
  }, [_c("div", {
    staticClass: "topMsgs"
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "top"
  }, [_vm._v("温馨提示")]), _c("div", {
    staticClass: "bottom"
  }, [_vm._v("该材料为重要材料，上传重要材料可降低用工风险,最多上传九份")])]), _c("a-col", {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      span: 12
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-top": "5px"
    },
    attrs: {
      disabled: _vm.edit != null
    },
    on: {
      click: _vm.downloadImg
    }
  }, [_vm._v("打包下载")]), _c("span", {
    staticStyle: {
      padding: "0 57px"
    }
  })], 1)], 1), _c("div")], 1)]), _c("div", {
    staticStyle: {
      padding: "12px",
      "padding-top": "0"
    }
  }, [_c("Uploading", {
    ref: "operation",
    attrs: {
      multiple: true,
      fieldCode: _vm.query.fieldCode,
      srcData: _vm.accessoryData[_vm.query.fieldCode],
      limit: 9
    },
    on: {
      "update:srcData": function updateSrcData($event) {
        return _vm.$set(_vm.accessoryData, _vm.query.fieldCode, $event);
      },
      "update:src-data": function updateSrcData($event) {
        return _vm.$set(_vm.accessoryData, _vm.query.fieldCode, $event);
      },
      change: _vm.onFileChange
    }
  }), _c("UploadSiteFile", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "uploadTool",
    attrs: {
      limit: 9,
      "default-value": _vm.accessoryData[_vm.query.fieldCode],
      accept: ".doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .jpg, .jpeg, .png"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };