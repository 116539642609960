var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "approval approvalEditor-wrapper"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("span", {
    staticClass: "span-box"
  }, [_c("span", {
    staticClass: "backIcon white",
    on: {
      click: _vm.goOut
    }
  }, [_c("a-icon", {
    staticClass: "fs22",
    attrs: {
      type: "left"
    }
  })], 1), _c("a-tooltip", {
    attrs: {
      placement: "bottomLeft"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.baseinfo.typeName || "空模板"))])]), _c("span", {
    staticClass: "empy white ove"
  }, [_vm._v(_vm._s(_vm.baseinfo.typeName || "空模板"))])], 2)], 1), _c("span", {
    staticClass: "tabs"
  }, [_c("span", {
    class: _vm.defaultTabs == 1 ? "isActive" : "",
    on: {
      click: function click($event) {
        return _vm.saveData(1);
      }
    }
  }, [_c("i", {
    staticClass: "order-number"
  }, [_vm._v("1")]), _vm._v("基础设置 "), _vm.defaultTabs == 1 ? _c("div", {
    staticStyle: {
      width: "80%",
      height: "3px",
      background: "#ffffff",
      margin: "0 auto",
      "margin-top": "-10px"
    }
  }) : _vm._e()]), _c("span", {
    class: _vm.defaultTabs == 2 ? "isActive" : "",
    on: {
      click: function click($event) {
        return _vm.saveData(2);
      }
    }
  }, [_c("i", {
    staticClass: "order-number"
  }, [_vm._v("2")]), _vm._v("表单设计 "), _vm.defaultTabs == 2 ? _c("div", {
    staticStyle: {
      width: "80%",
      height: "3px",
      background: "#ffffff",
      margin: "0 auto",
      "margin-top": "-10px"
    }
  }) : _vm._e()]), _c("span", {
    class: _vm.defaultTabs == 3 ? "isActive" : "",
    on: {
      click: function click($event) {
        return _vm.saveData(3);
      }
    }
  }, [_c("i", {
    staticClass: "order-number"
  }, [_vm._v("3")]), _vm._v("流程设计 "), _vm.defaultTabs == 3 ? _c("div", {
    staticStyle: {
      width: "80%",
      height: "3px",
      background: "#ffffff",
      margin: "0 auto",
      "margin-top": "-10px"
    }
  }) : _vm._e()]), _c("span", {
    class: _vm.defaultTabs == 4 ? "isActive" : "",
    on: {
      click: function click($event) {
        return _vm.saveData(4);
      }
    }
  }, [_c("i", {
    staticClass: "order-number"
  }, [_vm._v("4")]), _vm._v("高级设置 "), _vm.defaultTabs == 4 ? _c("div", {
    staticStyle: {
      width: "80%",
      height: "3px",
      background: "#ffffff",
      margin: "0 auto",
      "margin-top": "-10px"
    }
  }) : _vm._e()])]), _c("a-button", {
    staticClass: "fr text_color_o mr16",
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.saveData(null);
      }
    }
  }, [_vm._v("发布")])], 1), _c("div", {
    staticClass: "approvalEditor-content"
  }, [_c("BaseSet", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.defaultTabs === 1,
      expression: "defaultTabs === 1"
    }],
    on: {
      formTypeChange: _vm.onFormTypeChange
    },
    model: {
      value: _vm.baseinfo,
      callback: function callback($$v) {
        _vm.baseinfo = $$v;
      },
      expression: "baseinfo"
    }
  }), _c("FormSet", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.defaultTabs === 2,
      expression: "defaultTabs === 2"
    }],
    ref: "formSet",
    on: {
      change: _vm.onFormListChange
    },
    model: {
      value: _vm.formlist,
      callback: function callback($$v) {
        _vm.formlist = $$v;
      },
      expression: "formlist"
    }
  }), _c("ProcessSet", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.defaultTabs === 3,
      expression: "defaultTabs === 3"
    }],
    attrs: {
      setProcess: _vm.process,
      formlist: _vm.formlist
    },
    model: {
      value: _vm.process,
      callback: function callback($$v) {
        _vm.process = $$v;
      },
      expression: "process"
    }
  }), _c("SeniorSet", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.defaultTabs === 4,
      expression: "defaultTabs === 4"
    }],
    model: {
      value: _vm.setting,
      callback: function callback($$v) {
        _vm.setting = $$v;
      },
      expression: "setting"
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };