import request from "@/utils/request";
export function getCrmMembers(params) {
  return request({
    url: "/hr/api/v1/crmMembers",
    method: "GET",
    params: params
  });
}
export function postAddJurisdiction(data) {
  return request({
    url: "/hr/api/v1/crmMembers/addJurisdiction",
    method: "POST",
    data: data
  });
}
export function putUpdateJurisdiction(data) {
  return request({
    url: "/hr/api/v1/crmMembers/updateJurisdiction",
    method: "put",
    data: data
  });
}
export function getCustomerPage(params) {
  return request({
    url: "/hr/api/v1/crmCustomerInfos/getCustomerPage",
    method: "GET",
    params: params
  });
}
export function getCrmCustomerInfos(params) {
  return request({
    url: "/hr/api/v1/crmCustomerInfos",
    method: "GET",
    params: params
  });
}
export function getJurisdictionRange(params) {
  return request({
    url: "/hr/api/v1/crmMembers/getJurisdictionRange",
    method: "GET",
    params: params
  });
}
export function getAllUser(params) {
  return request({
    url: "/hr/api/v1/crmMembers/getAllUser",
    method: "GET",
    params: params
  });
}
export function getMyJurisdiction(params) {
  return request({
    url: "/hr/api/v1/crmMembers/getMyJurisdiction",
    method: "GET",
    params: params
  });
}