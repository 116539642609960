var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "approval-time-pora"
  }, [_c("a-timeline", _vm._l(_vm.approvalLogArr, function (i, index) {
    return _c("a-timeline-item", {
      key: index
    }, [_c("span", {
      staticClass: "approval-dot",
      attrs: {
        slot: "dot"
      },
      slot: "dot"
    }), _c("div", {
      staticClass: "approval-time-pora-item"
    }, [_c("a-avatar", {
      staticClass: "approval-time-pora-avatar",
      attrs: {
        icon: "user",
        src: i.approverUserAvatar,
        size: 33
      }
    }), _c("div", {
      staticStyle: {
        display: "inline-block"
      }
    }, [_c("div", {
      staticClass: "approval-time-jiedian"
    }, [_vm._v(_vm._s(i.approverUserName))]), _c("div", {
      staticClass: "approval-time-name"
    })]), index == "0" ? _c("div", {
      staticClass: "approval-time-fq"
    }, [_vm._v(_vm._s(i.approverOpinion))]) : _c("div", {
      staticClass: "approval-time-content"
    }, [index !== "0" ? _c("span", [_vm._v(_vm._s(i.status === 2 ? "审批通过" : i.status === 3 ? "审批拒绝" : ""))]) : _vm._e(), i.approverOpinion ? _c("span", [_vm._v("，审批意见:" + _vm._s(i.approverOpinion))]) : _vm._e()])], 1), _c("div", {
      staticClass: "approval-time-time"
    }, [_vm._v(" " + _vm._s(_vm.moment(i.gmtCreate).format("YYYY-MM-DD HH:mm:ss")) + " ")])]);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };