import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "file-edit page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(" 详情 ")]), _c("a-layout", [_c("a-layout-sider", {
    staticClass: "border-r-line ta-center",
    attrs: {
      width: "276"
    }
  }, [_c("div", {
    staticClass: "file-user",
    staticStyle: {
      "margin-top": "40px"
    }
  }, [_c("a-row", {
    staticClass: "file-user-head",
    staticStyle: {
      "padding-left": "30px",
      "padding-right": "30px"
    }
  }, [_c("a-col", [_c("a-row", [_c("div", {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "400",
      color: "#3c3d43",
      height: "40px"
    }
  }, [_vm._v(" " + _vm._s(_vm.detailList.companyName) + " ")])]), _c("a-row", {
    staticClass: "pd_b10",
    staticStyle: {
      "padding-bottom": "15px",
      color: "#adadad",
      "border-bottom": "1px dashed #e8e8e8",
      display: "flex",
      "justify-content": "space-around"
    }
  }, [_vm.detailList.invoiceStatus === 1 ? _c("div", {
    staticClass: "buttonstyle2"
  }, [_vm._v("未开票")]) : _vm._e(), _vm.detailList.invoiceStatus === 3 ? _c("div", {
    staticClass: "buttonStyle"
  }, [_vm._v("部分开票")]) : _vm._e(), _vm.detailList.invoiceStatus === 2 ? _c("div", {
    staticClass: "buttonStyle3"
  }, [_vm._v("已开票")]) : _vm._e(), _vm.detailList.payBackStatus === 1 ? _c("div", {
    staticClass: "buttonstyle2"
  }, [_vm._v("未回款")]) : _vm._e(), _vm.detailList.payBackStatus === 3 ? _c("div", {
    staticClass: "buttonStyle"
  }, [_vm._v("部分回款")]) : _vm._e(), _vm.detailList.payBackStatus === 2 ? _c("div", {
    staticClass: "buttonStyle3"
  }, [_vm._v("已回款")]) : _vm._e()]), _c("a-row", [_c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailName"
  }, [_vm._v("账单创建人：")]), _c("div", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.detailList.createUserName))])]), _vm.detailList.yearly ? _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailName"
  }, [_vm._v("账单月份：")]), _c("div", {
    staticClass: "detail"
  }, [_vm._v(" " + _vm._s(_vm.detailList.monthly >= 10 ? _vm.detailList.yearly + "-" + _vm.detailList.monthly : _vm.detailList.yearly + "-0" + _vm.detailList.monthly) + " ")])]) : _vm._e(), _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailName"
  }, [_vm._v("应到账金额：")]), _c("div", {
    staticClass: "detail"
  }, [_vm._v(" " + _vm._s(!_vm.detailList.totalAmount ? 0 : _vm.detailList.totalAmount.toLocaleString()) + " ")])]), _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailName"
  }, [_vm._v("服务费金额：")]), _c("div", {
    staticClass: "detail"
  }, [_vm._v(" " + _vm._s(!_vm.detailList.serviceFeeAmount ? 0 : _vm.detailList.serviceFeeAmount.toLocaleString()) + " ")])]), _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailName"
  }, [_vm._v("人力成本：")]), _c("div", {
    staticClass: "detail"
  }, [_vm._v(" " + _vm._s(!_vm.detailList.laborCost ? 0 : _vm.detailList.laborCost.toLocaleString()) + " ")])]), _vm.detailList.fine ? _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailName"
  }, [_vm._v("滞纳金：")]), _c("div", {
    staticClass: "detail"
  }, [_vm._v(" " + _vm._s(!_vm.detailList.fine ? 0 : _vm.detailList.fine.toLocaleString()) + " ")])]) : _vm._e()])], 1), _c("a-divider"), _c("a-button", {
    staticStyle: {
      color: "#767885",
      border: "1px solid rgba(0, 0, 0, 0.15)"
    },
    on: {
      click: _vm.showAbolition
    }
  }, [_vm._v(" 删除账单 ")])], 1), _c("a-modal", {
    attrs: {
      title: "废除客户回款记录确认",
      width: 450
    },
    on: {
      ok: _vm.handleAbolition
    },
    model: {
      value: _vm.abolitionVisible,
      callback: function callback($$v) {
        _vm.abolitionVisible = $$v;
      },
      expression: "abolitionVisible"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "left"
    }
  }, [_c("div", {
    staticClass: "primary_color",
    staticStyle: {
      "margin-left": "15px",
      "font-size": "35px"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "question-circle"
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-left": "15px",
      position: "relative",
      top: "3px",
      "font-size": "16px",
      "font-weight": "500",
      color: "#000000ff"
    }
  }, [_c("span", [_vm._v("您确定要 "), _c("span", {
    staticStyle: {
      color: "#ec5252ff"
    }
  }, [_vm._v("删除")]), _vm._v(" 该条记录吗？删除后不可恢复，但可在本月客户明细单中重新点击生成回款记录。")])])])])], 1)]), _c("a-layout-content", {
    staticClass: "ph24 mb100"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "fix-item-wrapper",
    staticStyle: {
      width: "55%"
    }
  }, [_c("div", [_c("div", {
    staticClass: "childpProjectName"
  }, [_vm._v("应到账金额")]), _c("div", {
    staticClass: "childContentName",
    staticStyle: {
      color: "#3c3d43"
    }
  }, [_vm._v(" " + _vm._s(!_vm.detailList.totalAmount ? 0 : _vm.detailList.totalAmount.toLocaleString()) + " ")])]), _c("div", [_c("div", {
    staticClass: "childpProjectName"
  }, [_vm._v("实际到账金额")]), _c("div", {
    staticClass: "childContentName",
    staticStyle: {
      color: "#3c3d43"
    }
  }, [_vm._v(" " + _vm._s(!_vm.detailList.amountPaid ? 0 : _vm.detailList.amountPaid.toLocaleString()) + " ")])]), _c("div", [_c("div", {
    staticClass: "childpProjectName"
  }, [_vm._v("未到账金额")]), _c("div", {
    staticClass: "childContentName"
  }, [_vm._v(" " + _vm._s(!_vm.detailList.unpaidAmount ? 0 : _vm.detailList.unpaidAmount.toLocaleString()) + " ")])])]), _c("div", {
    staticClass: "fix-item-wrapper",
    staticStyle: {
      width: "40%"
    }
  }, [_c("div", [_c("div", {
    staticClass: "childpProjectName"
  }, [_vm._v("已开票金额")]), _c("div", {
    staticClass: "childContentName",
    staticStyle: {
      color: "#3c3d43"
    }
  }, [_vm._v(" " + _vm._s(!_vm.detailList.invoicedAmount ? 0 : _vm.detailList.invoicedAmount.toLocaleString()) + " ")])]), _c("div", [_c("div", {
    staticClass: "childpProjectName"
  }, [_vm._v("未开票金额")]), _c("div", {
    staticClass: "childContentName"
  }, [_vm._v(" " + _vm._s(!_vm.detailList.uninvoicedAmount ? 0 : _vm.detailList.uninvoicedAmount.toLocaleString()) + " ")])])])]), _c("div", [_c("a-tabs", [_c("a-tab-pane", {
    key: "0",
    attrs: {
      tab: "回款记录"
    }
  }, [_vm.detailList.payBackStatus !== 2 ? _c("a-button", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showReceivable
    }
  }, [_vm._v(" 新增回款记录 ")]) : _vm._e(), _c("a-modal", {
    attrs: {
      title: "新增回款记录",
      width: 550
    },
    on: {
      ok: _vm.handleReceivableOk,
      cancel: _vm.handleCancel
    },
    model: {
      value: _vm.receivableVisible,
      callback: function callback($$v) {
        _vm.receivableVisible = $$v;
      },
      expression: "receivableVisible"
    }
  }, [_c("a-row", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm._v("回款信息")]), _c("hr", {
    staticClass: "title-line"
  })]), _c("a-form-model", {
    ref: "ruleForm",
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      model: _vm.receivableForm,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "receiveAmount",
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      label: "本次实际到账金额",
      prop: "receiveAmount",
      rules: {
        required: true,
        message: "请输入"
      }
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      max: 999999,
      placeholder: "请输入"
    },
    model: {
      value: _vm.receivableForm.receiveAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.receivableForm, "receiveAmount", $$v);
      },
      expression: "receivableForm.receiveAmount"
    }
  }), _vm.detailList.unpaidAmount ? _c("span", {
    staticStyle: {
      "font-size": "12px",
      color: "#999999"
    }
  }, [_vm._v(" (本次回款金额不得高于未到账金额" + _vm._s(!_vm.detailList.unpaidAmount ? 0 : _vm.detailList.unpaidAmount) + ") ")]) : _vm._e()], 1), _c("a-form-model-item", {
    ref: "payBackDate",
    attrs: {
      label: "本次回款时间",
      prop: "payBackDate",
      rules: {
        required: true,
        message: "请选择"
      }
    }
  }, [_c("a-date-picker", {
    attrs: {
      "disabled-date": _vm.disabledDate,
      placeholder: "选择回款时间"
    },
    on: {
      change: _vm.onChange,
      ok: _vm.onOk
    },
    model: {
      value: _vm.receivableForm.payBackDate,
      callback: function callback($$v) {
        _vm.$set(_vm.receivableForm, "payBackDate", $$v);
      },
      expression: "receivableForm.payBackDate"
    }
  })], 1), _c("a-form-model-item", {
    ref: "file",
    attrs: {
      label: "银行回单",
      prop: "file"
    }
  }, [_c("a-upload", {
    staticClass: "upload-list-inline",
    attrs: {
      showUploadList: {
        showRemoveIcon: false
      },
      accept: ".doc,.docx,.xls,.xlsx,.pdf,.jpg, .jpeg, .png",
      "before-upload": _vm.beforeUpload,
      "list-type": "picture",
      fileList: _vm.fileList,
      "default-file-list": []
    },
    on: {
      change: _vm.upLoad,
      remove: _vm.removeChange
    }
  }, [_c("a-button", [_c("a-icon", {
    attrs: {
      type: "upload"
    }
  }), _vm._v(" 点击上传 ")], 1)], 1)], 1), _c("a-form-model-item", {
    ref: "remark",
    attrs: {
      label: "备注",
      prop: "remark"
    }
  }, [_c("a-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入"
    },
    model: {
      value: _vm.receivableForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.receivableForm, "remark", $$v);
      },
      expression: "receivableForm.remark"
    }
  })], 1)], 1)], 1), _c("a-table", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      rowKey: "id",
      loading: _vm.loading2,
      columns: _vm.receivableColumns,
      "data-source": _vm.receivableList,
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "file",
      fn: function fn(text) {
        return _c("a", {}, [JSON.parse(text).length ? _c("div", _vm._l(JSON.parse(text), function (item, index) {
          return _c("p", {
            key: index,
            staticStyle: {
              color: "var(--primary)"
            }
          }, [_c("a", {
            attrs: {
              href: "".concat(item, "?token=").concat(_vm.$store.state.auth.token),
              target: "_blank"
            }
          }, [_vm._v(" " + _vm._s(item.substr(item.lastIndexOf("/") + 1)) + " ")])]);
        }), 0) : _vm._e(), !JSON.parse(text).length ? _c("div", [_vm._v("-")]) : _vm._e()]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("a", {
          staticClass: "buttonStyle6"
        }, [_c("a-popconfirm", {
          attrs: {
            title: "确定删除吗?"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.deleteBack(record);
            }
          }
        }, [_vm._v(" 删除 ")])], 1);
      }
    }, {
      key: "remark",
      fn: function fn(text) {
        return _c("a", {}, [_vm._v(" " + _vm._s(text ? text : "-") + " ")]);
      }
    }, {
      key: "receiveAmount",
      fn: function fn(text) {
        return _c("a", {}, [_vm._v(" " + _vm._s(!text ? 0 : text.toLocaleString()) + " ")]);
      }
    }, {
      key: "payBackDate",
      fn: function fn(text) {
        return _c("a", {}, [_vm._v(" " + _vm._s(text ? _vm.moment(text).format("YYYY-MM-DD") : "-") + " ")]);
      }
    }])
  })], 1), _c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "开票记录"
    }
  }, [_vm.detailList.invoiceStatus !== 2 ? _c("a-button", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showTicket
    }
  }, [_vm._v(" 新增开票记录 ")]) : _vm._e(), _c("a-modal", {
    attrs: {
      title: "新增开票记录",
      width: 550
    },
    on: {
      ok: _vm.handleTicketOk,
      cancel: _vm.handleCancel2
    },
    model: {
      value: _vm.ticketVisible,
      callback: function callback($$v) {
        _vm.ticketVisible = $$v;
      },
      expression: "ticketVisible"
    }
  }, [_c("a-row", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm._v("开票信息")]), _c("hr", {
    staticClass: "title-line"
  })]), _c("a-form-model", {
    ref: "ruleForm2",
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      model: _vm.ticketForm,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "invoiceAmount",
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      label: "本次开票金额",
      prop: "invoiceAmount",
      rules: {
        required: true,
        message: "请输入"
      }
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      max: 999999,
      placeholder: "请输入"
    },
    model: {
      value: _vm.ticketForm.invoiceAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.ticketForm, "invoiceAmount", $$v);
      },
      expression: "ticketForm.invoiceAmount"
    }
  }), _vm.detailList.uninvoicedAmount ? _c("span", {
    staticStyle: {
      "font-size": "12px",
      color: "#999999"
    }
  }, [_vm._v(" (本次开票金额不得高于未开票金额" + _vm._s(!_vm.detailList.uninvoicedAmount ? 0 : _vm.detailList.uninvoicedAmount) + ") ")]) : _vm._e()], 1), _c("a-form-model-item", {
    ref: "invoiceDate",
    attrs: {
      label: "本次开票时间",
      prop: "invoiceDate",
      rules: {
        required: true,
        message: "请选择"
      }
    }
  }, [_c("a-date-picker", {
    attrs: {
      "disabled-date": _vm.disabledDate,
      placeholder: "选择开票时间"
    },
    on: {
      change: _vm.onChange,
      ok: _vm.onOk
    },
    model: {
      value: _vm.ticketForm.invoiceDate,
      callback: function callback($$v) {
        _vm.$set(_vm.ticketForm, "invoiceDate", $$v);
      },
      expression: "ticketForm.invoiceDate"
    }
  })], 1), _c("a-form-model-item", {
    ref: "file",
    attrs: {
      label: "附件",
      prop: "file"
    }
  }, [_c("UploadGeneralInline", {
    attrs: {
      limit: 10,
      disabled: false,
      accept: ".doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .jpg, .jpeg, .png"
    },
    model: {
      value: _vm.urlList2,
      callback: function callback($$v) {
        _vm.urlList2 = $$v;
      },
      expression: "urlList2"
    }
  })], 1), _c("a-form-model-item", {
    ref: "remark",
    attrs: {
      label: "备注",
      prop: "remark"
    }
  }, [_c("a-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入"
    },
    model: {
      value: _vm.ticketForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.ticketForm, "remark", $$v);
      },
      expression: "ticketForm.remark"
    }
  })], 1)], 1)], 1), _c("a-table", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      rowKey: "id",
      loading: _vm.loading3,
      columns: _vm.ticketColumns,
      "data-source": _vm.ticketList,
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "file",
      fn: function fn(text) {
        return _c("a", {}, [JSON.parse(text).length ? _c("div", _vm._l(JSON.parse(text), function (item, index) {
          return _c("p", {
            key: index,
            staticStyle: {
              color: "#6a66f6"
            }
          }, [_c("a", {
            attrs: {
              href: "".concat(item, "?token=").concat(_vm.$store.state.auth.token),
              target: "_blank"
            }
          }, [_vm._v(" " + _vm._s(item.substr(item.lastIndexOf("/") + 1)) + " ")])]);
        }), 0) : _vm._e(), !JSON.parse(text).length ? _c("div", [_vm._v("-")]) : _vm._e()]);
      }
    }, {
      key: "invoiceAmount",
      fn: function fn(text, record) {
        return _c("a", {
          staticStyle: {
            position: "relative"
          }
        }, [record.isVoid === false ? _c("img", {
          staticStyle: {
            position: "absolute",
            top: "-16px",
            left: "-15px",
            width: "50px",
            height: "50px"
          },
          attrs: {
            src: _vm.abolitionImg
          }
        }) : _vm._e(), _c("p", {
          staticStyle: {
            "margin-left": "20px",
            "margin-bottom": "0"
          }
        }, [_vm._v(" " + _vm._s(!text ? 0 : text.toLocaleString()) + " ")])]);
      }
    }, {
      key: "remark",
      fn: function fn(text) {
        return _c("a", {}, [_vm._v(" " + _vm._s(text ? text : "-") + " ")]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("a", {}, [record.isVoid === true ? _c("span", {
          staticClass: "buttonStyle6"
        }, [_c("a-popconfirm", {
          attrs: {
            title: "确定作废吗?"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.wasteTicket(record);
            }
          }
        }, [_vm._v(" 作废 ")])], 1) : _vm._e(), record.isVoid === false ? _c("span", {
          staticClass: "buttonStyle6"
        }, [_vm._v(" 作废 ")]) : _vm._e()]);
      }
    }, {
      key: "invoiceDate",
      fn: function fn(text) {
        return _c("a", {}, [_vm._v(" " + _vm._s(text ? _vm.moment(text).format("YYYY-MM-DD") : "-") + " ")]);
      }
    }])
  })], 1)], 1)], 1)])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };