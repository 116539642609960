var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white p24",
    staticStyle: {
      "min-height": "calc(100vh - 112px)"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("SelectCompanyGroup", {
    attrs: {
      hasLeftIcon: true,
      selectTreeId: _vm.siteId,
      type: _vm.type
    },
    on: {
      "update:selectTreeId": function updateSelectTreeId($event) {
        _vm.siteId = $event;
      },
      "update:select-tree-id": function updateSelectTreeId($event) {
        _vm.siteId = $event;
      },
      "update:type": function updateType($event) {
        _vm.type = $event;
      }
    }
  })], 1), _c("div", {
    staticClass: "mv12 p12"
  }, [_c("ListStats", {
    ref: "stats",
    attrs: {
      status: _vm.status,
      params: _vm.params
    },
    on: {
      "update:status": function updateStatus($event) {
        _vm.status = $event;
      }
    }
  })], 1), _c("div", [_c("div", {
    staticClass: "flex-center-between"
  }, [_c("a-space", {
    attrs: {
      size: 16
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "姓名/身份证号"
    },
    on: {
      change: function change($event) {
        return _vm.onInputChange("nameOrIdCard");
      }
    },
    model: {
      value: _vm.nameOrIdCard,
      callback: function callback($$v) {
        _vm.nameOrIdCard = $$v;
      },
      expression: "nameOrIdCard"
    }
  }), _c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "商保方案"
    },
    on: {
      change: function change($event) {
        return _vm.onInputChange("schemeName");
      }
    },
    model: {
      value: _vm.schemeName,
      callback: function callback($$v) {
        _vm.schemeName = $$v;
      },
      expression: "schemeName"
    }
  }), _c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "保险公司"
    },
    on: {
      change: function change($event) {
        return _vm.onInputChange("insCompanyName");
      }
    },
    model: {
      value: _vm.insCompanyName,
      callback: function callback($$v) {
        _vm.insCompanyName = $$v;
      },
      expression: "insCompanyName"
    }
  }), _c("a-range-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      value: _vm.dateRange,
      placeholder: ["在保开始时间", "结束时间"],
      mode: ["date", "date"],
      format: "YYYY-MM-DD"
    },
    on: {
      panelChange: _vm.onPanelChange,
      change: _vm.onRangeChange
    }
  })], 1), _c("a-space", {
    attrs: {
      size: 16
    }
  }, [_vm.hasExport ? _c("a-button", {
    on: {
      click: _vm.doExport
    }
  }, [_vm._v("全部导出")]) : _vm._e(), _vm.hasAdd ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.selectVisible = true;
      }
    }
  }, [_vm._v("新增在保")]) : _vm._e()], 1)], 1)]), _c("div", {
    staticClass: "mt24"
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columnsList,
      dataSource: _vm.dataSource,
      pagination: _vm.pagination,
      scroll: {
        x: true
      },
      loading: _vm.loading,
      rowKey: "id"
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "empName",
      fn: function fn(text, record) {
        return _c("div", {
          staticStyle: {
            position: "relative"
          }
        }, [record.paymentType === "年保" ? _c("img", {
          staticStyle: {
            width: "45px",
            height: "46px",
            position: "absolute",
            top: "-16px",
            left: "-16px"
          },
          attrs: {
            src: require("./nianbao.png")
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(text) + " ")]);
      }
    }, {
      key: "dateRange",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(record.beginTime)) + "-" + _vm._s(_vm.formatDate(record.endTime)) + " ")];
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("a-space", [_c("span", {
          staticClass: "pointer tc-theme",
          on: {
            click: function click($event) {
              return _vm.doDetail(record);
            }
          }
        }, [_vm._v("详情")])])];
      }
    }])
  })], 1), _c("SelectEmployee", {
    on: {
      confirm: _vm.onEmpSelected
    },
    model: {
      value: _vm.selectVisible,
      callback: function callback($$v) {
        _vm.selectVisible = $$v;
      },
      expression: "selectVisible"
    }
  }), _c("SelectConfirm", {
    on: {
      confirm: _vm.doConfirm
    },
    model: {
      value: _vm.confirmVisible,
      callback: function callback($$v) {
        _vm.confirmVisible = $$v;
      },
      expression: "confirmVisible"
    }
  }), _c("ListDetail", {
    attrs: {
      target: _vm.currentEmp
    },
    on: {
      close: _vm.onSearchChange
    },
    model: {
      value: _vm.detailVisible,
      callback: function callback($$v) {
        _vm.detailVisible = $$v;
      },
      expression: "detailVisible"
    }
  }), _c("a-modal", {
    attrs: {
      title: "参保结果",
      width: "1000px"
    },
    on: {
      cancel: _vm.resultClear,
      ok: _vm.doResultConfirm
    },
    model: {
      value: _vm.resultVisible,
      callback: function callback($$v) {
        _vm.resultVisible = $$v;
      },
      expression: "resultVisible"
    }
  }, [_vm.resultData ? _c("Result", {
    attrs: {
      resultData: _vm.resultData
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };