import request from "@/utils/request";
export function getConfig(params) {
  return request({
    url: "/hr/api/v1/hrConfigs/getEnabledConfig",
    method: "get",
    params: params
  });
}
export function getServiceOpens(params) {
  return request({
    url: "/admin/api/v1/addedServiceOpens/selectServiceBySite",
    method: "get",
    params: params
  });
}