import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find-index.js";
import { selectSiteRebatesRulesReq, addSaleRebatesRulesReq, updateSaleRebatesRulesReq, delSaleRebatesRulesReq, selectBindCompanyReq } from "../api";
import { formatRegionValueDe, formatRegionValueEn, bindColumns } from "../funcs";
import { mapState } from "vuex";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      bindColumns: bindColumns,
      groupModalTitle: "添加提成规则",
      groupForm: this.$form.createForm(this),
      groupLst: null,
      groupModalVisible: false,
      tempGroup: null,
      currentGroupId: null,
      currentGroupTarget: null,
      empRegionTemp: null,
      confirmLoadingGroup: false,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      companyOrSaleName: "",
      empLst: [],
      rulesName: ""
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["common"])), {}, {
    areaSourceMap: function areaSourceMap() {
      return this.common.areaSourceMap;
    }
  }),
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.onGroupSearchChange();
  },
  methods: {
    onEmpTableChange: function onEmpTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.onEmpSearchChange();
    },
    onEmpSearchChange: function onEmpSearchChange() {
      var _this = this;
      var params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        rulesId: this.currentGroupId
      };
      if (this.companyOrSaleName) {
        params["companyOrSaleName"] = this.companyOrSaleName;
      }
      selectBindCompanyReq(params).then(function (res) {
        _this.empLst = res.data.entities;
        _this.pagination.total = res.data.entityCount;
      }).catch(function (err) {
        console.log("selectBindCompanyReq", err);
      });
    },
    onRegionChange: function onRegionChange(value, selectedOptions) {
      this.empRegionTemp = selectedOptions;
    },
    onClickGroupItem: function onClickGroupItem(item) {
      this.currentGroupId = item.id;
      this.currentGroupTarget = item;
      this.pagination.current = 1;
      this.onEmpSearchChange();
    },
    onDelGroupItem: function onDelGroupItem() {
      var _this2 = this;
      this.$confirm({
        title: "删除确认",
        content: "规则删除后将无法恢复，确认要删除吗？",
        onOk: function onOk() {
          delSaleRebatesRulesReq(_this2.currentGroupTarget.id).then(function () {
            _this2.$message.success("删除成功");
            _this2.onGroupSearchChange();
            _this2.groupModalVisible = false;
          }).catch(function (err) {
            console.log("deleteGroupReq", err);
          });
        }
      });
    },
    onGroupModalOk: function onGroupModalOk() {
      var _this3 = this;
      this.groupForm.validateFields(function (err, values) {
        if (!err) {
          var newValues = {
            rulesName: values.rulesName,
            subjectType: values.subjectType,
            newProportion: values.newProportion,
            continueProportion: values.continueProportion,
            remark: values.remark
          };
          _this3.confirmLoadingGroup = true;
          if (_this3.groupModalTitle === "添加提成规则") {
            newValues["region"] = formatRegionValueEn(_this3.empRegionTemp);
            addSaleRebatesRulesReq(newValues).then(function (res) {
              _this3.$message.success("添加成功");
              _this3.onGroupSearchChange();
              _this3.groupModalVisible = false;
              _this3.confirmLoadingGroup = false;
            }).catch(function (err) {
              _this3.confirmLoadingGroup = false;
              console.log("addSaleRebatesRulesReq", err);
            });
          } else {
            newValues["region"] = formatRegionValueEn(_this3.empRegionTemp) || _this3.currentGroupTarget.region;
            updateSaleRebatesRulesReq(_objectSpread(_objectSpread({}, newValues), {}, {
              id: _this3.currentGroupTarget.id
            })).then(function (res) {
              _this3.$message.success("修改成功");
              _this3.onGroupSearchChange();
              _this3.groupModalVisible = false;
              _this3.confirmLoadingGroup = false;
            }).catch(function (err) {
              _this3.confirmLoadingGroup = false;
              console.log("updateSaleRebatesRulesReq", err);
            });
          }
        }
      });
    },
    onEditGroupItem: function onEditGroupItem() {
      var _this4 = this;
      this.groupModalTitle = "编辑提成规则";
      this.groupModalVisible = true;
      setTimeout(function () {
        _this4.groupForm.setFieldsValue({
          rulesName: _this4.currentGroupTarget.rulesName,
          subjectType: _this4.currentGroupTarget.subjectType,
          newProportion: _this4.currentGroupTarget.newProportion,
          continueProportion: _this4.currentGroupTarget.continueProportion,
          remark: _this4.currentGroupTarget.remark,
          region: formatRegionValueDe(_this4.currentGroupTarget.region)
        });
      }, 0);
    },
    onAddGroupItem: function onAddGroupItem() {
      this.groupForm.resetFields();
      this.groupModalTitle = "添加提成规则";
      this.tempGroup = null;
      this.groupModalVisible = true;
    },
    onGroupSearchChange: function onGroupSearchChange() {
      var _this5 = this;
      selectSiteRebatesRulesReq(this.rulesName).then(function (res) {
        _this5.groupLst = res.data;
        if (!_this5.currentGroupId) {
          _this5.onClickGroupItem(_this5.groupLst[0]);
        } else {
          var idx = _this5.groupLst.findIndex(function (li) {
            return li.id === _this5.currentGroupId;
          });
          if (idx > -1) {
            _this5.onClickGroupItem(_this5.groupLst[idx]);
          } else {
            _this5.onClickGroupItem(_this5.groupLst[0]);
          }
        }
      }).catch(function (err) {
        console.log("selectSiteRebatesRulesReq", err);
      });
    }
  }
};