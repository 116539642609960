var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack p24"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "padding-top": "120px",
      "flex-direction": "column",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c("img", {
    staticStyle: {
      width: "280px",
      height: "235px"
    },
    attrs: {
      src: _vm.zhifubaoAuthorization,
      alt: ""
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "40px",
      "font-size": "18px",
      "font-weight": "500",
      color: "#3C3D43"
    }
  }, [_vm._v("恭喜你，支付宝账号授权成功")]), _c("div", {
    staticStyle: {
      "margin-top": "16px",
      "font-size": "14px",
      "font-weight": "400",
      color: "#A6A8B4"
    }
  }, [_vm._v("可以用支付宝发放薪资啦！")])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };