import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import moment from "moment";
import { getApprovalStatus, getApprovalType } from "../funcs";
export default {
  props: ["approvalLog"],
  data: function data() {
    return {
      approvalLogArr: []
    };
  },
  methods: {
    getApprovalStatus: getApprovalStatus,
    getApprovalType: getApprovalType
  },
  watch: {
    approvalLog: {
      handler: function handler(val) {
        var arr = [];
        var valARR = val;
        this.approvalLogArr = [];
        valARR.map(function (i) {
          if (i.userFlowApproverList !== null) {
            arr.push.apply(arr, _toConsumableArray(i.userFlowApproverList.filter(function (i) {
              return i.status === 2 || i.status === 3;
            })));
          }
        });
        this.approvalLogArr = arr;
      },
      deep: true,
      immediate: true
    }
  }
};