import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("用工单位设置")]), _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticStyle: {
      padding: "24px"
    }
  }, [_c("a-tabs", {
    attrs: {
      type: "card"
    },
    on: {
      change: _vm.callback
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "小程序设置"
    }
  }, [_c("a-layout", {
    staticStyle: {
      padding: "0",
      background: "#fff"
    }
  }, [_c("a-layout-sider", {
    staticStyle: {
      background: "#fff"
    },
    attrs: {
      width: "200"
    }
  }, [_c("a-menu", {
    attrs: {
      mode: "inline",
      "default-selected-keys": [0]
    }
  }, _vm._l(_vm.subjectList, function (item, index) {
    return _c("a-menu-item", {
      key: index,
      on: {
        click: function click($event) {
          return _vm.menuChange(item);
        }
      }
    }, [_c("span", [_vm._v(_vm._s(item.name))])]);
  }), 1)], 1), _c("a-layout-content", {
    style: {
      padding: "0 18px",
      minHeight: "280px"
    }
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      "font-size": "18px",
      "font-weight": "600",
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.appName))]), _vm.appItem ? _c("div", [_c("a-switch", {
    attrs: {
      "default-checked": ""
    },
    on: {
      change: function change($event) {
        return _vm.hideChange(_vm.appItem);
      }
    },
    model: {
      value: _vm.appItem.isHide,
      callback: function callback($$v) {
        _vm.$set(_vm.appItem, "isHide", $$v);
      },
      expression: "appItem.isHide"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "checkedChildren",
      type: "check"
    },
    slot: "checkedChildren"
  }), _c("a-icon", {
    attrs: {
      slot: "unCheckedChildren",
      type: "close"
    },
    slot: "unCheckedChildren"
  })], 1)], 1) : _vm._e()]), _c("a-divider", {
    staticStyle: {
      "margin-top": "12px"
    }
  }), _vm.appList && _vm.appList.length > 0 ? _c("div", {
    key: _vm.keyDate,
    staticClass: "itemBox"
  }, [_vm._l(_vm.appList, function (item, index) {
    return [!(_vm.isJF && ["工资条", "考勤打卡", "社保公积金"].includes(item.name)) ? _c("div", {
      key: index,
      staticClass: "item"
    }, [_c("div", {
      staticStyle: {
        display: "flex"
      }
    }, [_c("div", [_c("img", {
      staticStyle: {
        width: "42px",
        height: "42px"
      },
      attrs: {
        src: item.icon[0],
        alt: ""
      }
    })]), _c("div", {
      staticStyle: {
        "margin-left": "12px"
      }
    }, [_c("div", {
      staticStyle: {
        "font-weight": "500",
        color: "#3c3d43",
        "font-size": "14px",
        "margin-bottom": "8px"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " "), _c("span", {
      staticClass: "Set",
      staticStyle: {
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.openSet(item);
        }
      }
    }, [_vm._v("设置")])]), _c("div", {
      staticStyle: {
        color: "#a6a8b4",
        "font-size": "12px"
      }
    }, [_vm._v(_vm._s(item.desc ? item.desc : "---"))])])]), _c("div", [_c("a-switch", {
      attrs: {
        "default-checked": ""
      },
      on: {
        change: function change($event) {
          return _vm.hideChange(item);
        }
      },
      model: {
        value: item.isHide,
        callback: function callback($$v) {
          _vm.$set(item, "isHide", $$v);
        },
        expression: "item.isHide"
      }
    }, [_c("a-icon", {
      attrs: {
        slot: "checkedChildren",
        type: "check"
      },
      slot: "checkedChildren"
    }), _c("a-icon", {
      attrs: {
        slot: "unCheckedChildren",
        type: "close"
      },
      slot: "unCheckedChildren"
    })], 1)], 1)]) : _vm._e()];
  }), _c("a-modal", {
    attrs: {
      title: _vm.setName + "设置",
      visible: _vm.visible
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    staticStyle: {
      height: "350px",
      overflow: "scroll"
    }
  }, [_vm.setList && _vm.setList.length > 0 ? _c("div", {
    staticClass: "itemBox"
  }, _vm._l(_vm.setList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item2"
    }, [_c("div", {
      staticStyle: {
        display: "flex"
      }
    }, [_c("div", [_c("img", {
      staticStyle: {
        width: "42px",
        height: "42px"
      },
      attrs: {
        src: item.icon[0],
        alt: ""
      }
    })]), _c("div", {
      staticStyle: {
        "margin-left": "12px"
      }
    }, [_c("div", {
      staticStyle: {
        "font-weight": "500",
        color: "#3c3d43",
        "font-size": "14px",
        "margin-bottom": "8px"
      }
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticStyle: {
        color: "#a6a8b4",
        "font-size": "10px"
      }
    }, [_vm._v(_vm._s(item.desc ? item.desc : "---"))])])]), _c("div", {
      staticStyle: {
        "margin-left": "10px"
      }
    }, [_c("a-switch", {
      attrs: {
        "default-checked": ""
      },
      on: {
        change: function change($event) {
          return _vm.hideChange(item);
        }
      },
      model: {
        value: item.isHide,
        callback: function callback($$v) {
          _vm.$set(item, "isHide", $$v);
        },
        expression: "item.isHide"
      }
    }, [_c("a-icon", {
      attrs: {
        slot: "checkedChildren",
        type: "check"
      },
      slot: "checkedChildren"
    }), _c("a-icon", {
      attrs: {
        slot: "unCheckedChildren",
        type: "close"
      },
      slot: "unCheckedChildren"
    })], 1)], 1)]);
  }), 0) : _c("div", [_c("a-empty", {
    staticStyle: {
      "margin-top": "80px"
    }
  })], 1)])])], 2) : _c("div", [_c("a-empty", {
    staticStyle: {
      "margin-top": "100px"
    }
  })], 1)], 1)], 1)], 1), _c("a-tab-pane", {
    key: "3",
    attrs: {
      tab: "扫码入职设置"
    }
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.loadingcode,
      delay: 300
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: ""
    }
  }, [_c("div", {
    staticClass: "processBox"
  }, [_c("div", {
    staticClass: "processBoxLCJS"
  }, [_vm._v("流程介绍")]), _c("div", {
    staticClass: "maBottom4"
  }, [_vm._v("1.配置入职登记二维码并分享")]), _c("div", {
    staticClass: "maBottom4"
  }, [_vm._v("2.员工填写并提交入职登记信息")]), _c("div", [_vm._v("3.公司管理员在待入职管理中，完善员工信息并确认入职")])])]), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "二维码有效期"
    }
  }, [_c("div", {
    staticStyle: {
      width: "200px",
      display: "flex",
      "align-items": "center",
      "font-size": "12px"
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "100%",
      "margin-right": "10px"
    },
    attrs: {
      min: 1
    },
    on: {
      change: _vm.setAllContent
    },
    model: {
      value: _vm.timeDay,
      callback: function callback($$v) {
        _vm.timeDay = $$v;
      },
      expression: "timeDay"
    }
  }), _c("div", {
    staticStyle: {
      "font-weight": "800",
      "font-size": "14px"
    }
  }, [_vm._v("天")])], 1), _vm.timeDay ? _c("div", [_vm._v("有效期：" + _vm._s(_vm.FailureTime))]) : _vm._e()]), !_vm.isJF ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "工作性质"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType", {
        rules: [{
          required: true,
          message: "请选择工作性质"
        }]
      }],
      expression: "[\n                    'postType',\n                    {\n                      rules: [{ required: true, message: '请选择工作性质' }]\n                    }\n                  ]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择工作性质",
      "default-value": _vm.SMDefault
    },
    on: {
      change: _vm.postTypeSaoMaChange
    },
    model: {
      value: _vm.SMDefault,
      callback: function callback($$v) {
        _vm.SMDefault = $$v;
      },
      expression: "SMDefault"
    }
  }, _vm._l(_vm.postTypeOptions, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "渠道来源"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType", {
        rules: []
      }],
      expression: "['postType', { rules: [] }]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      "default-value": _vm.channelDefault,
      "show-search": "",
      placeholder: "请选择渠道来源"
    },
    on: {
      change: _vm.channelSaoMaChange
    },
    model: {
      value: _vm.channelDefault,
      callback: function callback($$v) {
        _vm.channelDefault = $$v;
      },
      expression: "channelDefault"
    }
  }, _vm._l(_vm.siteChannelList, function (item, index) {
    return _c("a-select-option", {
      key: "".concat(item.value, "_").concat(index),
      attrs: {
        value: "".concat(item.value, "_").concat(item.label)
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1), _vm.typeValue === 1 && _vm.SMMubanList.length > 0 ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "审批模板"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType", {
        rules: [{
          required: true,
          message: "请选择审批模板"
        }]
      }],
      expression: "[\n                    'postType',\n                    {\n                      rules: [{ required: true, message: '请选择审批模板' }]\n                    }\n                  ]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择审批模板",
      "default-value": _vm.muban
    },
    on: {
      change: _vm.mubanSelect
    },
    model: {
      value: _vm.muban,
      callback: function callback($$v) {
        _vm.muban = $$v;
      },
      expression: "muban"
    }
  }, _vm._l(_vm.SMMubanList, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "生成二维码"
    }
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      display: "flex",
      "align-items": "center",
      "margin-top": "15px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "150px",
      height: "150px"
    },
    attrs: {
      src: _vm.codeimg
    }
  }), _vm.refreshCode ? _c("div", {
    staticStyle: {
      position: "absolute",
      "z-index": "100",
      width: "150px",
      height: "150px",
      "background-color": "#252525aa"
    },
    on: {
      click: _vm.creatCode
    }
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer",
      width: "100%",
      height: "100%",
      display: "flex",
      "flex-flow": "column",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c("a-icon", {
    style: {
      fontSize: "18px",
      color: "#fff"
    },
    attrs: {
      type: "reload"
    }
  }), _c("span", {
    staticStyle: {
      color: "#ffffff"
    }
  }, [_vm._v("刷新二维码")])], 1)]) : _vm._e()]), _c("div", [_c("xq-icon", {
    staticClass: "primary_color weiwancheng3xCol",
    attrs: {
      type: "icon-weiwancheng3x"
    }
  }), _c("div", {
    staticClass: "weiwancheng3xShow"
  }, [_vm._v(_vm._s(_vm.isJF ? "" : "工作性质、") + "渠道来源配置完成后，将成为二维码自带属性，请谨慎分享")])], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.refreshCode
    },
    on: {
      click: _vm.downloadCod
    }
  }, [_vm._v("下载二维码")])], 1)])], 1)], 1)], 1)], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };