var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "r-resultd"
  }, [_vm.status ? _c("div", [_c("img", {
    attrs: {
      src: _vm.s
    }
  }), _c("div", {
    staticClass: "tit"
  }, [_vm._v("数据上传成功")]), _c("div", {
    staticClass: "desc"
  }, [_vm._v("成功上传 " + _vm._s(_vm.rdata) + "条 参保管理数据")]), _c("div", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 查看参保管理 ")])], 1)]) : _c("div", [_c("img", {
    attrs: {
      src: _vm.e
    }
  }), _c("div", {
    staticClass: "tit"
  }, [_vm._v("数据上传失败")]), _c("div", {
    staticClass: "desc"
  }, [_vm._v(_vm._s(_vm.rdata))]), _c("div", [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 返回 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("onAction", 0);
      }
    }
  }, [_vm._v(" 重新上传 ")])], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };