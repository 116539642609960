import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import { getNecessaryFields, postNecessaryFieldBatchDTO, postAdditionalFieldsAddDTO, deleteAdditionalFieldsQuery, putAdditionalFieldsUpdateDTO, putAdditionalFieldsOpen, putAdditionalFieldsNecessary, putcompanyEmpNecessaries } from "@/api/staff/entry";
import Vue from "vue";
import { FormModel } from "ant-design-vue";
Vue.use(FormModel);
Vue.directive("selection", {
  inserted: function inserted(el, binding) {}
});
export default {
  data: function data() {
    return {
      data: {},
      code: "",
      currentItem: {},
      fieldType: ["选项", "文本", "日期", "长文本"],
      status: 0,
      allData: {},
      allNewData: {},
      show: 0,
      formItemLayout: {
        labelCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 4
          }
        },
        wrapperCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 20
          }
        }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 20,
            offset: 4
          }
        }
      },
      dropDownArray: [],
      dynamicValidateForm: {
        domains: []
      },
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      },
      form: {},
      visible1: false,
      visible2: false,
      editvisible: false,
      activeKey: ["1", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16"],
      companyId: "",
      text: 1,
      res: {},
      result: {}
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.getData();
  },
  methods: {
    handleStopClick: function handleStopClick() {
      event.stopPropagation();
    },
    handleClick: function handleClick(i, tag) {
      var _this = this;
      event.stopPropagation();
      if (tag === "empFamily") {
        this.data.id = i.id;
        this.data.isNecessary = !i.isNecessary;
        this.data.moduleCode = i.moduleCode;
        this.data.moduleName = i.moduleName;
      }
      if (tag === "empContacts") {
        this.data.id = i.id;
        this.data.isNecessary = !i.isNecessary;
        this.data.moduleCode = i.moduleCode;
        this.data.moduleName = i.moduleName;
      }
      if (tag === "empEducation") {
        this.data.id = i.id;
        this.data.isNecessary = !i.isNecessary;
        this.data.moduleCode = i.moduleCode;
        this.data.moduleName = i.moduleName;
      }
      if (tag === "empWork") {
        this.data.id = i.id;
        this.data.isNecessary = !i.isNecessary;
        this.data.moduleCode = i.moduleCode;
        this.data.moduleName = i.moduleName;
      }
      if (tag === "empTrain") {
        this.data.id = i.id;
        this.data.isNecessary = !i.isNecessary;
        this.data.moduleCode = i.moduleCode;
        this.data.moduleName = i.moduleName;
      }
      putcompanyEmpNecessaries(this.data).then(function (res) {
        _this.getData();
      });
    },
    handleOptionChange: function handleOptionChange($event, index) {
      var val = $event.target.value;
      this.$set(this.form.dropDownArray, index, val);
    },
    confirmDelete: function confirmDelete(id) {
      var _this2 = this;
      this.$confirm({
        title: "删除确认",
        content: "将删除全部用工单位的花名册及模板中的本字段",
        onOk: function onOk() {
          deleteAdditionalFieldsQuery(id).then(function () {
            _this2.getData();
          }).catch(function (err) {
            console.log(err);
          });
        }
      });
    },
    remoteControl: function remoteControl(item, tag) {
      var _this3 = this;
      if (item.isNecessary && tag === "isNecessary") {
        item.isOpen = true;
      } else if (!item.isOpen && tag === "isOpen") {
        item.isNecessary = false;
      }
      postNecessaryFieldBatchDTO(item).then(function (res) {
        _this3.getData();
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this3.getData();
      });
    },
    edit: function edit(item, tempCode, index) {
      this.currentItem = item;
      this.status = 0;
      this.editvisible = true;
      this.code = tempCode;
      this.form = JSON.parse(JSON.stringify(item));
    },
    remoteControlAdd: function remoteControlAdd(item, tag) {
      if (item.isNecessary && tag === "isNecessary") {
        item.isOpen = true;
      } else if (!item.isOpen && tag === "isOpen") {
        item.isNecessary = false;
      }
      var req = tag === "isOpen" ? putAdditionalFieldsOpen : putAdditionalFieldsNecessary;
      req(_objectSpread(_objectSpread({}, item), {}, {
        companyAdditionalFieldsId: item.id
      })).catch(function (err) {
        console.log(err);
      });
    },
    handleChange: function handleChange(value) {
      this.show = "";
      if (value === "1") {
        this.show = 2;
      }
      if (value === "2") {
        this.show = 3;
      }
    },
    submitForm: function submitForm(formName) {
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain: function removeDomain(index) {
      this.form.dropDownArray.splice(index, 1);
    },
    removeDomain2: function removeDomain2(index) {
      this.form.dropDownArray.splice(index, 1);
    },
    addDomain: function addDomain() {
      this.form.dropDownArray.push("");
    },
    addDomain2: function addDomain2() {
      this.form.dropDownArray.push("");
    },
    onSubmit: function onSubmit() {},
    showModal: function showModal(tag) {
      var _this4 = this;
      this.$nextTick(function () {
        _this4.$refs.addForm.resetFields();
      });
      this.show = 0;
      this.dropDownArray = [];
      this.form = {
        dropDownArray: []
      };
      this[tag] = true;
      this.status = 1;
    },
    handleEditOk: function handleEditOk(item, code) {
      var _this5 = this;
      var str = "";
      var flag = false;
      if (this.form.dropDownArray) {
        this.form.dropDownArray.forEach(function (item, index) {
          if (item === "") flag = true;
          str += item;
          if (index !== _this5.form.dropDownArray.length - 1) {
            str += ",";
          }
        });
      }
      if (flag) {
        this.$notification["error"]({
          message: "error",
          description: "自定义选项不能为空，修改失败"
        });
        return;
      }
      var data = {
        id: this.currentItem.id,
        companyId: this.companyId,
        fieldType: this.form.fieldType,
        fieldName: this.form.fieldName,
        moduleCode: item,
        options: str,
        description: this.form.description
      };
      if (data.fieldName && data.options) {
        this.$refs.form.validate(function (valid) {
          if (valid) {
            _this5.$confirm({
              title: "修改确认",
              content: "字段的修改将同步到所有用工单位的花名册及模板",
              onOk: function onOk() {
                putAdditionalFieldsUpdateDTO(data).then(function (res) {
                  _this5.$notification["success"]({
                    message: "success",
                    description: "修改成功"
                  });
                  _this5.editvisible = false;
                  _this5.getData();
                }).catch(function (err) {
                  console.log(err);
                });
              }
            });
          }
        });
      } else if (data.fieldName && data.fieldType === 2 || data.fieldType === 3 || data.fieldType === 4) {
        this.$refs.form.validate(function (valid) {
          if (valid) {
            _this5.$confirm({
              title: "修改确认",
              content: "字段的修改将同步到所有用工单位的花名册及模板",
              onOk: function onOk() {
                putAdditionalFieldsUpdateDTO(data).then(function (res) {
                  _this5.$notification["success"]({
                    message: "success",
                    description: "修改成功"
                  });
                  _this5.editvisible = false;
                  _this5.getData();
                }).catch(function (err) {
                  console.log(err);
                });
              }
            });
          }
        });
      }
    },
    handleAddOk: function handleAddOk(code) {
      var _this6 = this;
      var str = "";
      var flag = false;
      this.form.dropDownArray.forEach(function (item, index) {
        if (item === "") flag = true;
        str += item;
        if (index !== _this6.form.dropDownArray.length - 1) {
          str += ",";
        }
      });
      if (flag && this.form.fieldType === "1") {
        this.$notification["error"]({
          message: "error",
          description: "自定义选项不能为空，添加失败"
        });
        return;
      }
      if (this.form.dropDownArray.length === 0 && this.form.fieldType === "1") {
        this.$notification["error"]({
          message: "error",
          description: "自定义选项不能为空，添加失败，点击添加自定义选项"
        });
        return;
      }
      var data = {
        id: this.form.id,
        companyId: this.companyId,
        fieldName: this.form.fieldName,
        fieldType: this.form.fieldType,
        moduleCode: code,
        options: str,
        // textLength: this.form.textLength,
        description: this.form.description
      };
      this.$refs.addForm.validate(function (valid) {
        if (valid) {
          postAdditionalFieldsAddDTO(data).then(function (res) {
            _this6.$notification["success"]({
              message: "success",
              description: "添加成功"
            });
            _this6.visible1 = false;
            _this6.visible2 = false;
            _this6.getData();
          });
        }
      });
    },
    getData: function getData(res) {
      var _this7 = this;
      getNecessaryFields({
        companyId: this.companyId
      }).then(function (res) {
        _this7.allData = res.data;
      });
    },
    goback: function goback() {
      this.$router.push({
        path: "/staff/edit"
      });
    }
  }
};