import request from "@/utils/request";
export function getCrmMembers(params) {
  return request({
    // url: "/hr/api/v1/crmMembers/listAll",
    url: "/hr/api/v1/crmMembers/getMyManagerList",
    method: "GET",
    params: params
  });
}
export function postBatchDistribution(data) {
  return request({
    url: "/hr/api/v1/crmCustomerInfos/batchDistribution",
    method: "POST",
    data: data
  });
}
export function putCrmCustomerInfos(data) {
  return request({
    url: "/hr/api/v1/crmCustomerInfos",
    method: "PUT",
    data: data
  });
}