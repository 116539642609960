import "core-js/modules/es.array.concat.js";
import TabJcxx from "./jcxx";
import TabClgc from "./clgc";
import { detailTemplateReq, addTemplateReq, updateTemplateReq } from "./api";
export default {
  name: "",
  components: {
    TabJcxx: TabJcxx,
    TabClgc: TabClgc
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      activeTab: "jcxx"
    };
  },
  computed: {
    tempId: function tempId() {
      return this.$route.params.id;
    },
    isCreate: function isCreate() {
      return !!!this.tempId;
    },
    groupKey: function groupKey() {
      return this.isCreate ? "formGroupAddList" : "formGroupUpdateList";
    }
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    if (this.tempId) {
      this.loadDetail();
    }
  },
  methods: {
    save: function save() {
      var _this = this;
      var infoBase = this.$refs.jcxx.getValues();
      var infoProcess = this.$refs.clgc.getValues();
      infoBase[this.groupKey] = [].concat(infoBase[this.groupKey], infoProcess);
      var reqFunc = this.isCreate ? addTemplateReq : updateTemplateReq;
      reqFunc(infoBase).then(function (res) {
        if (res && res.data) {
          _this.$message.success("保存成功");
          _this.back();
        } else {
          _this.$message.error("保存失败");
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    loadDetail: function loadDetail() {
      var _this2 = this;
      detailTemplateReq(this.tempId).then(function (res) {
        _this2.$refs.jcxx.setValues(res.data);
        _this2.$refs.clgc.setValues(res.data);
      }).catch(function (err) {
        console.log(err);
      });
    },
    back: function back() {
      this.$router.go(-1);
    },
    onTabChange: function onTabChange(key) {
      if (this.activeTab !== key) {
        this.activeTab = key;
      }
    }
  }
};