export default {
  fields: {
    configurationName: {
      model: "configurationName"
    },
    configurationStatus: {
      model: "configurationStatus"
    },
    configurationExplain: {
      model: "configurationExplain"
    },
    sallaryImpact: {
      model: "sallaryImpact"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "configurationStatus",
        title: "考勤状态"
      }, {
        model: "configurationExplain",
        title: "状态说明"
      }, {
        model: "sallaryImpact",
        title: "工资影响"
      }],
      customize: {
        rowKey: "id"
      },
      extend: {
        batch: false,
        action: true
      }
    }
  }
};