export var decimalPlacesOptions = [{
  value: 0,
  label: "保留为整数"
}, {
  value: 1,
  label: "保留到小数点后 1 位"
}, {
  value: 2,
  label: "保留到小数点后 2 位"
}, {
  value: 3,
  label: "保留到小数点后 3 位"
}, {
  value: 4,
  label: "保留到小数点后 4 位"
}];