import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
export default {
  name: "Toggle",
  data: function data() {
    return {
      show: !window.location.host.includes("csxr.cn"),
      isZXR: !window.location.host.includes("csxr.cn"),
      hover: false
    };
  },
  methods: {
    hoverHandle: function hoverHandle(v) {
      this.hover = v;
      console.log(this.hover);
    },
    toggle: function toggle() {
      this.show = !this.show;
    },
    change: function change() {
      window.open("https://www.yuque.com/books/share/c4450b35-3d42-49af-a9ed-e3dbd672707d?#");
    },
    yindao: function yindao() {
      console.log(process.env.VUE_APP_DOMAIN);
      // this.$parent.openYindao();
      this.$emit("openYindao", ""); // 自定义事件  传递值“子向父组件传值”
    }
  }
};