var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-layout", {
    staticClass: "page_set"
  }, [_c("a-layout-sider", {
    staticClass: "a_layout_sider",
    attrs: {
      width: "280"
    }
  }, [_c("a-menu", {
    attrs: {
      defaultSelectedKeys: _vm.defaultSelectedKeys
    }
  }, [_c("a-menu-item", {
    key: "app",
    staticClass: "item_title",
    attrs: {
      disabled: ""
    }
  }, [_vm._v(" 薪资设置 ")]), _vm._l(_vm.menuList, function (_ref, i) {
    var meta = _ref.meta;
    return _c("a-menu-item", {
      key: meta.title,
      staticClass: "bot_bot",
      on: {
        click: function click($event) {
          return _vm.goList(i, meta);
        }
      }
    }, [_vm._v(_vm._s(meta.title))]);
  })], 2)], 1), _c("a-layout", {
    staticClass: "a_layout"
  }, [_c("router-view")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };