export default {
  fields: {
    jobSeekerName: {
      model: "jobSeekerName"
    },
    postName: {
      model: "postName"
    },
    companyUnitName: {
      model: "companyUnitName"
    },
    gmtCreate: {
      model: "gmtCreate"
    },
    status: {
      model: "status"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "jobSeekerName",
        title: "姓名"
      }, {
        model: "postName",
        title: "推荐职位"
      }, {
        model: "companyUnitName",
        title: "招工企业"
      }, {
        model: "gmtCreate",
        title: "推荐日期"
      }, {
        model: "status",
        title: "状态"
      }],
      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: false
      }
    }
  }
};