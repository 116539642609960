export var peopleStatusArr = [{
  label: "正常",
  value: "1"
}, {
  label: "非正常",
  value: "2"
}];
export var employmentTypeArr = [{
  label: "雇员",
  value: "10"
}, {
  label: "保险营销员",
  value: "20"
}, {
  label: "证券经纪人",
  value: "30"
}, {
  label: "其他",
  value: "40"
}, {
  label: "实习学生（全日制学历教育）",
  value: "50"
}];
export var submissionStateArr = [{
  label: "待报送",
  value: "1"
}, {
  label: "报送中",
  value: "2"
}, {
  label: "报送失败",
  value: "3"
}, {
  label: "报送成功",
  value: "4"
}];
export var validationStateArr = [{
  label: "验证中",
  value: "0"
}, {
  label: "验证通过",
  value: "1"
}, {
  label: "验证不通过",
  value: "2"
}, {
  label: "待验证",
  value: "4"
}];
export var authenticationStateArr = [{
  label: "验证中",
  value: "0"
}, {
  label: "验证通过",
  value: "1"
}, {
  label: "验证不通过",
  value: "2"
}, {
  label: "待验证",
  value: "4"
}, {
  label: "验证中",
  value: "9"
}];
export var personnelAreaArr = [{
  label: "境内",
  value: "1"
}, {
  label: "境外",
  value: "2"
}];
export var CompletenessInformationArr = [{
  label: "是",
  value: 1
}, {
  label: "否",
  value: 0
}];
export var licenseTypeArr = [{
  label: "居民身份证",
  value: "10"
}, {
  label: "外国护照",
  value: "20"
}, {
  label: "中国护照",
  value: "30"
}, {
  label: "港澳居民来往内地通行证",
  value: "40"
}, {
  label: "台湾居民来往大陆通行证",
  value: "50"
}, {
  label: "外国人永久居留身份证",
  value: "60"
}, {
  label: "中华人民共和国港澳居民居住证",
  value: "70"
}, {
  label: "中华人民共和国台湾居民居住证",
  value: "80"
}, {
  label: "《中华人民共和国外国人工作许可证》（A类）",
  value: "90"
}, {
  label: "《中华人民共和国外国人工作许可证》（B类）",
  value: "100"
}, {
  label: "《中华人民共和国外国人工作许可证》（C类）",
  value: "110"
}, {
  label: "其他个人证件",
  value: "120"
}];