import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
export default {
  data: function data() {
    return {
      fieldsData1: [{
        title: "入职模板设置",
        url: require("@/assets/icons/ruzhi1.png"),
        type: "4",
        mark: "entryTemplateSet"
      }, {
        title: "待入职模板设置",
        url: require("@/assets/icons/dairuzhi.png"),
        type: "5",
        mark: "hiredEntryTemplateSet"
      }],
      fieldsData: [{
        title: "个人信息设置",
        url: require("@/assets/icons/personal.png"),
        type: "1",
        mark: "personalInfo"
      }, {
        title: "在职信息设置",
        url: require("@/assets/icons/personal.png"),
        type: "4",
        mark: "zaizhixinxi"
      }, {
        title: "工资社保设置",
        url: require("@/assets/icons/salary.png"),
        type: "2",
        mark: "socialSecurity"
      }, {
        title: "材料附件设置",
        url: require("@/assets/icons/enclosure.png"),
        type: "3",
        mark: "information"
      }]
    };
  },
  methods: {
    goTemplate: function goTemplate(data) {
      if (JSON.stringify(data) !== "{}") {
        this.$router.push({
          path: "/staff/".concat(data.mark, "/").concat(data.type)
        });
      }
    }
  }
};