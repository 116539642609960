import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
var getters = {
  cityList: function cityList(state) {
    return state.area.cityList;
  },
  areaList: function areaList(state) {
    return state.area.areaList;
  },
  areaSourceList: function areaSourceList(state) {
    return state.area.areaSourceList;
  },
  companyEmployeeList: function companyEmployeeList(state) {
    return state.company.companyEmployeeList;
  },
  companyAdminList: function companyAdminList(state) {
    return state.company.companyAdminList;
  },
  companyRoleList: function companyRoleList(state) {
    return state.company.companyRoleList;
  },
  companyPositionList: function companyPositionList(state) {
    return state.company.companyPositionList;
  },
  companyDepartmentList: function companyDepartmentList(state) {
    return state.company.companyDepartmentList;
  },
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  name: function name(state) {
    var _state$auth, _state$auth$userInfo;
    return (state === null || state === void 0 ? void 0 : (_state$auth = state.auth) === null || _state$auth === void 0 ? void 0 : (_state$auth$userInfo = _state$auth.userInfo) === null || _state$auth$userInfo === void 0 ? void 0 : _state$auth$userInfo.name) || "";
  },
  dialogs: function dialogs(state) {
    return state.wages.dialogs;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.auth.token;
  },
  avatar: function avatar(state) {
    return state.auth.avatar;
  },
  permissions: function permissions(state) {
    return state.auth.permissions;
  },
  permissions_menu: function permissions_menu(state) {
    if (state.auth.permissions && state.auth.permissions.menuList) {
      return state.auth.permissions.menuList;
    }
    return [];
  },
  permissions_menu_urls: function permissions_menu_urls(state) {
    if (state.auth.permissions && state.auth.permissions.menuList) {
      return state.auth.permissions.menuList.map(function (item) {
        return item.url;
      });
    }
    return [];
  },
  permissions_menu_tree: function permissions_menu_tree(state) {
    if (state.auth.permissions && state.auth.permissions.menuList) {
      return toTree(state.auth.permissions.menuList);
    }
    return [];
  },
  permissions_handle: function permissions_handle(state) {
    if (state.auth.permissions && state.auth.permissions.permissionStringList) {
      return state.auth.permissions.permissionStringList.filter(function (handle) {
        return handle.includes("hr:");
      });
    }
    return [];
  },
  permissions_dycl: function permissions_dycl(state) {
    if (state.auth.permissions && state.auth.permissions.permissionStringList) {
      return state.auth.permissions.permissionStringList.filter(function (handle) {
        return handle.includes("dycl:");
      });
    }
    return [];
  },
  permissions_shangbao: function permissions_shangbao(state) {
    if (state.auth.permissions && state.auth.permissions.permissionStringList) {
      return state.auth.permissions.permissionStringList.filter(function (handle) {
        return handle.includes("insuranceAddService:");
      });
    }
    return [];
  },
  permissions_site: function permissions_site(state) {
    if (state.auth.permissions && state.auth.permissions.siteList) {
      return state.auth.permissions.siteList;
    }
    return [];
  },
  permissions_type: function permissions_type(state) {
    if (state.auth.permissions && state.auth.permissions.subjectTypeList) {
      return state.auth.permissions.subjectTypeList;
    }
    return [];
  },
  isJF: function isJF(state) {
    if (state.auth.permissions && state.auth.permissions.siteList) {
      var siteList = state.auth.permissions.siteList;
      var currentSite = siteList.filter(function (item) {
        return item.currSite;
      })[0];
      return [3, 4].includes(currentSite.siteType);
    }
    return false;
  }
};
export default getters;
function toTree(data) {
  data.forEach(function (item) {
    delete item.children;
  });
  var map = {};
  data.forEach(function (item) {
    map[item.id] = item;
  });
  var val = [];
  data.forEach(function (item) {
    var parent = map[item.parentId];
    if (!!parent) {
      (parent.children || (parent.children = [])).push(Object.assign(item, {
        title: item.title || item.name,
        key: "".concat(item.id, "&&").concat(item.title || item.name, "&&").concat(item.isDirectory)
      }));
    } else {
      val.push(Object.assign(item, {
        title: item.title || item.name,
        key: "".concat(item.id, "&&").concat(item.title || item.name, "&&").concat(item.isDirectory)
      }));
    }
  });
  return val;
}