var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", [_c("div", {
    staticClass: "tableBox"
  }, [_c("TableBody", {
    attrs: {
      selectHead: "",
      selection: "",
      tableName: "declareEmpDetails",
      selectedRowKeys: _vm.selectedRowKeys,
      source: _vm.dataList,
      headerRowID: "sortable_list_declareEmpDetails",
      columns: _vm.columns
    },
    on: {
      "update:selectedRowKeys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      "update:selected-row-keys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      onReload: _vm.getIncomeTaxList,
      onExport: function onExport() {
        return _vm.download(1);
      },
      onExportAll: function onExportAll() {
        return _vm.download(2);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(prop) {
        return [_c("a-table", {
          class: prop.className,
          attrs: {
            components: prop.components,
            columns: prop.columns,
            scroll: {
              x: prop.scrollWidth
            },
            customHeaderRow: function customHeaderRow() {
              return {
                domProps: {
                  id: prop.headerRowID
                }
              };
            },
            loading: _vm.loading,
            "data-source": _vm.dataList,
            pagination: _vm.pagination,
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange
            }
          },
          on: {
            change: _vm.handleTableChange
          },
          scopedSlots: _vm._u([{
            key: "empName",
            fn: function fn(text, record) {
              return _c("a", {
                staticStyle: {
                  position: "relative",
                  cursor: "default"
                }
              }, [record.status === 4 ? _c("img", {
                staticStyle: {
                  position: "absolute",
                  top: "-16px",
                  left: "-15px",
                  width: "50px",
                  height: "50px"
                },
                attrs: {
                  src: _vm.abolitionImg
                }
              }) : _vm._e(), _c("p", {
                staticStyle: {
                  "margin-left": "20px",
                  "margin-bottom": "0",
                  color: "#595959"
                }
              }, [_vm._v(" " + _vm._s(text) + " ")])]);
            }
          }, {
            key: "gender",
            fn: function fn(text) {
              return _c("a", {
                staticStyle: {
                  cursor: "pointer",
                  color: "#595959"
                }
              }, [text === 1 ? _c("span", [_vm._v("男")]) : _vm._e(), text === 2 ? _c("span", [_vm._v("女")]) : _vm._e()]);
            }
          }, {
            key: "status",
            fn: function fn(text) {
              return [_vm._v(" " + _vm._s(["待入职", "在职", "待离职", "离职"][text - 1]) + " ")];
            }
          }, {
            key: "birthday",
            fn: function fn(text) {
              return _c("a", {
                staticStyle: {
                  cursor: "pointer",
                  color: "#595959"
                }
              }, [_vm._v(" " + _vm._s(text ? _vm.moment(parseInt(text)).format("YYYY-MM-DD") : null) + " ")]);
            }
          }, {
            key: "certificateType",
            fn: function fn(text) {
              return _c("a", {
                staticStyle: {
                  cursor: "pointer",
                  color: "#595959"
                }
              }, [_vm._v(" " + _vm._s(_vm.certificate[parseInt(text)]) + " ")]);
            }
          }, {
            key: "employmentType",
            fn: function fn(text) {
              return _c("a", {
                staticStyle: {
                  cursor: "pointer",
                  color: "#595959"
                }
              }, [_vm._v(" " + _vm._s(_vm.employmentType[parseInt(text) - 1]) + " ")]);
            }
          }], null, true)
        })];
      }
    }])
  }, [_c("template", {
    slot: "search"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "姓名/手机号/身份证号"
    },
    on: {
      search: _vm.inputChange
    },
    model: {
      value: _vm.search1,
      callback: function callback($$v) {
        _vm.search1 = $$v;
      },
      expression: "search1"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: "",
      placeholder: "员工状态"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.search2,
      callback: function callback($$v) {
        _vm.search2 = $$v;
      },
      expression: "search2"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 待入职 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 在职 ")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 待离职 ")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v(" 离职 ")])], 1)], 1)], 2)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };