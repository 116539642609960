import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.join.js";
import { findCompanyGroup as _findCompanyGroup } from "@/api/company/contract";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    date: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      tempItem: null,
      modalTitle: "",
      replaceFields2: {
        children: "companyList",
        title: "name",
        key: "uuid",
        value: "uuid"
      },
      addVisible: false,
      addForm: {},
      groupList: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      companyDataList: [],
      flatDataList: []
    };
  },
  computed: {},
  watch: {
    date: {
      handler: function handler(newVal) {
        if (newVal) {
          this.loadData();
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.findCompanyGroup();
  },
  methods: {
    treeSearch: function treeSearch(e) {
      this.findCompanyGroup(e);
    },
    findCompanyGroup: function findCompanyGroup(e) {
      var _this = this;
      _findCompanyGroup({
        name: e || null
      }).then(function (res) {
        _this.companyDataList = _this.addTreeSlot(res.data);
        _this.flatten(_this.companyDataList);
        _this.addForm.companyInfoList = _this.addForm.companyInfoList ? JSON.parse(JSON.stringify(_this.addForm.companyInfoList)) : undefined;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    addTreeSlot: function addTreeSlot(data) {
      var _this2 = this;
      if (!data) {
        return [];
      }
      data.forEach(function (el) {
        el.scopedSlots = {
          title: "custom"
        };
        _this2.addTreeSlot(el.companyList);
      });
      return data;
    },
    flatten: function flatten(list) {
      var _this3 = this;
      if (list !== null) {
        list.forEach(function (item) {
          _this3.flatDataList.push(item);
          _this3.flatten(item.companyList);
        });
      }
    },
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.loadData();
    },
    formatYM: function formatYM(yl, ml) {
      if (yl) {
        return "".concat(yl, "-").concat(ml < 10 ? "0" : "").concat(ml);
      }
      return "--";
    },
    onCancel: function onCancel() {
      this.addVisible = false;
    },
    goDetail: function goDetail(item) {
      localStorage.setItem("kehumingxibiao_item", JSON.stringify(item));
      this.$router.push({
        path: "kehumingxibiao/customerDetail",
        query: {
          monthly: this.$dayjs(this.date).format("MM"),
          yearly: this.$dayjs(this.date).format("YYYY"),
          formType: item.customerDetailsType,
          groupType: item.subjectType
        }
      });
    },
    showEdit: function showEdit(item) {
      console.log(item.companyIds);
      this.tempItem = item;
      this.modalTitle = "编辑自定义分组";
      this.addVisible = true;
      this.addForm = {
        date: this.$moment("".concat(item.yearly, "-").concat(item.monthly < 10 ? "0" : "").concat(item.monthly), "YYYY-MM"),
        name: item.name,
        companyInfoList: this.flatDataList.filter(function (li) {
          return item.companyIds.includes(li.id);
        }).map(function (li) {
          return {
            value: li.uuid,
            label: li.name
          };
        })
      };
    },
    showAdd: function showAdd() {
      this.modalTitle = "新增自定义分组";
      this.addVisible = true;
      this.addForm = {};
    },
    loadData: function loadData() {
      var _this4 = this;
      this.$request({
        url: "/hr/api/v1/customerDetailsNew/selectCustomGroupingBySiteAndMonthly",
        params: {
          monthly: this.$dayjs(this.date).format("MM"),
          yearly: this.$dayjs(this.date).format("YYYY"),
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          customerDetailsType: "customer"
        }
      }).then(function (res) {
        _this4.groupList = res.data.entities;
        _this4.pagination.total = res.data.entityCount;
      }).catch(function (err) {
        console.log(err);
      });
    },
    save: function save() {
      var _this5 = this;
      var companyArr = [];
      this.addForm.companyInfoList.forEach(function (item) {
        _this5.flatDataList.forEach(function (item2) {
          if (item2.uuid && item2.uuid === item.value) {
            companyArr.push(item2.id);
          }
        });
      });
      if (this.modalTitle === "编辑自定义分组") {
        this.$request({
          url: "/hr/api/v1/companyCustomGroupings",
          method: "put",
          data: {
            id: this.tempItem.subjectId,
            name: this.addForm.name,
            moduleCode: "customerDetails",
            monthly: this.$dayjs(this.addForm.date).format("MM"),
            yearly: this.$dayjs(this.addForm.date).format("YYYY"),
            companyIds: companyArr.join(",")
          }
        }).then(function (res) {
          _this5.$message.success("编辑成功");
          _this5.loadData();
          _this5.onCancel();
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        this.$request({
          url: "/hr/api/v1/companyCustomGroupings",
          method: "post",
          data: {
            name: this.addForm.name,
            moduleCode: "customerDetails",
            monthly: this.$dayjs(this.addForm.date).format("MM"),
            yearly: this.$dayjs(this.addForm.date).format("YYYY"),
            companyIds: companyArr.join(",")
          }
        }).then(function (res) {
          _this5.$message.success("新增成功");
          _this5.loadData();
          _this5.onCancel();
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    deleteItem: function deleteItem(companyCustomGroupingId) {
      var _this6 = this;
      this.$request({
        url: "/hr/api/v1/companyCustomGroupings/".concat(companyCustomGroupingId),
        method: "delete"
      }).then(function (res) {
        _this6.$message.success("删除成功");
        _this6.loadData();
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};