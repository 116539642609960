export var preStatusVarList = [{
  label: "已接到",
  value: "1",
  bgColor: "",
  brColor: "",
  color: ""
}, {
  label: "已安排",
  value: "2",
  bgColor: "",
  brColor: "",
  color: ""
}, {
  label: "已面试",
  value: "3",
  bgColor: "",
  brColor: "",
  color: ""
}, {
  label: "待入职",
  value: "6",
  bgColor: "",
  brColor: "",
  color: ""
}, {
  label: "已入职",
  value: "4",
  bgColor: "",
  brColor: "",
  color: ""
}, {
  label: "未到岗",
  value: "7",
  bgColor: "",
  brColor: "",
  color: ""
}, {
  label: "已离职",
  value: "5",
  bgColor: "",
  brColor: "",
  color: ""
}];
export var columns = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  colDefault: true,
  isEdit: false,
  width: 120,
  fixed: "left"
}, {
  title: "身份证号",
  dataIndex: "idcard",
  key: "idcard",
  colDefault: true,
  isEdit: false,
  width: 210
}, {
  title: "手机号码",
  dataIndex: "phone",
  key: "phone",
  colDefault: true,
  width: 140
}, {
  title: "渠道来源",
  dataIndex: "channelName",
  key: "channelName",
  colDefault: true,
  width: 100,
  scopedSlots: {
    customRender: "channelName"
  }
}, {
  title: "用工单位",
  dataIndex: "companyName",
  key: "companyName",
  colDefault: true,
  width: 200,
  ellipsis: true
}, {
  title: "人员状态",
  dataIndex: "status",
  key: "status",
  colDefault: true,
  width: 100,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark",
  colDefault: true,
  width: 300,
  ellipsis: true,
  scopedSlots: {
    customRender: "remark"
  }
}, {
  title: "民族",
  dataIndex: "nation",
  key: "nation",
  colDefault: true,
  width: 100,
  scopedSlots: {
    customRender: "nation"
  }
}, {
  title: "出生日期",
  dataIndex: "birthday",
  key: "birthday",
  colDefault: true,
  width: 120,
  scopedSlots: {
    customRender: "birthday"
  }
}, {
  title: "户籍住址",
  dataIndex: "nativeLocation",
  key: "nativeLocation",
  colDefault: true,
  width: 150,
  ellipsis: true,
  scopedSlots: {
    customRender: "nativeLocation"
  }
}, {
  title: "签发机关",
  dataIndex: "issuedBy",
  key: "issuedBy",
  colDefault: true,
  width: 130,
  scopedSlots: {
    customRender: "issuedBy"
  }
}, {
  title: "开始时间",
  dataIndex: "startDate",
  key: "startDate",
  colDefault: true,
  width: 100,
  scopedSlots: {
    customRender: "startDate"
  }
}, {
  title: "结束时间",
  dataIndex: "endDate",
  key: "endDate",
  colDefault: true,
  width: 100,
  scopedSlots: {
    customRender: "endDate"
  }
}, {
  title: "登记时间",
  dataIndex: "gmtCreate",
  key: "gmtCreate",
  colDefault: true,
  width: 180,
  scopedSlots: {
    customRender: "gmtCreate"
  }
}, {
  title: "工作性质",
  dataIndex: "postType",
  key: "postType",
  width: 180,
  scopedSlots: {
    customRender: "postType"
  }
}, {
  title: "入职日期",
  dataIndex: "joinDate",
  key: "joinDate",
  width: 180,
  scopedSlots: {
    customRender: "joinDate"
  }
}, {
  title: "员工状态",
  dataIndex: "formalType",
  key: "formalType",
  width: 180,
  scopedSlots: {
    customRender: "formalType"
  }
}, {
  title: "操作",
  dataIndex: "action",
  key: "action",
  scopedSlots: {
    customRender: "action"
  },
  align: "center",
  width: 140,
  fixed: "right"
}];