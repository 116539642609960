var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    on: {
      click: _vm.onHidden
    }
  }, [_c("a-config-provider", {
    attrs: {
      locale: _vm.zh_CN
    }
  }, [_c("div", {
    attrs: {
      id: "app"
    }
  }, [_vm.isRouterAlive ? _c("router-view") : _c("div", {
    staticStyle: {
      width: "100%",
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "global_spinning"
  }, [_vm.systemTitle === "橙事新人" ? _c("img", {
    attrs: {
      src: require("./assets/spinningCSXR.gif"),
      alt: ""
    }
  }) : _vm.systemTitle === "星企途" ? _c("img", {
    attrs: {
      src: require("./assets/spinningFjqy.gif"),
      alt: ""
    }
  }) : _vm.systemTitle === "卫人云" ? _c("img", {
    attrs: {
      src: require("./assets/spinningWry.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("./assets/spinning.gif"),
      alt: ""
    }
  })])]), _vm.token ? _c("TaskPanel") : _vm._e(), _c("Common"), _c("Console"), _vm.login ? _c("div", [_c("toggle2"), _c("toggle", {
    on: {
      openYindao: _vm.openYindao
    }
  }), _vm.openGuid ? _c("toggle3", {
    on: {
      closeYindao: _vm.closeYindao,
      guidPath: _vm.ab
    }
  }) : _vm._e()], 1) : _vm._e()], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };