import "core-js/modules/es.array.push.js";
import Navbar from "@/views/paySalary/component/navbar.vue";
import Sidebar from "@/layout/components/sidebar/adminNew";
import AppMain from "@/layout/components/appmain/adminNew";
export default {
  components: {
    Navbar: Navbar,
    Sidebar: Sidebar,
    AppMain: AppMain
  },
  data: function data() {
    return {
      menuState: !this.$route.query.app_auth_code
    };
  },
  watch: {
    $route: function $route(to) {
      var _to$meta;
      if ((_to$meta = to.meta) !== null && _to$meta !== void 0 && _to$meta.route) {
        sessionStorage.setItem("shangyebaoxianPage", to.meta.route);
      }
    }
  },
  computed: {
    adminMenu: function adminMenu() {
      var arr = [];
      // if (this.$hasPermission("clientele:myClientele:query")) {
      //
      // }
      arr.push({
        icon: "",
        name: "商保管理",
        router: "/shangyebaoxian/client"
      });
      return arr;
    }
  },
  destroyed: function destroyed() {
    sessionStorage.setItem("shangyebaoxianPage", "");
  }
};