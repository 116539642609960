var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "uplaodBox"
  }, [_c("a-row", {
    staticClass: "head"
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goback
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  }), _c("div", {
    staticClass: "left2"
  }, [_vm._v("返回")])], 1), _c("a-col", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("导入专项附加扣除累计")])])], 1), _c("div", {
    staticClass: "ant-upload-select-text-box",
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-upload", {
    ref: "upload",
    attrs: {
      "file-list": _vm.fileList,
      remove: _vm.handleRemove,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("img", {
    staticStyle: {
      width: "139px",
      height: "106px"
    },
    attrs: {
      src: _vm.uploadImg
    }
  }), _c("div", {
    staticClass: "ant-modal-body"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#666"
    }
  }, [_vm._v(" 点击添加文件或将文件拖拽到此处 ")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#999"
    }
  }, [_vm._v(" (文件大小不超过5M) ")])])])], 1), _c("div", {
    staticClass: "tipBox"
  }, [_c("a-row", {
    staticStyle: {
      color: "#252525",
      "font-size": "16px",
      "font-weight": "500",
      "padding-bottom": "10px"
    }
  }, [_vm._v(" 温馨提示 ")]), _c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", [_vm._v(" 1、建议直接导入税务系统中下载的“正常工资薪金所得报表”，系统将自动读取专项附加扣除累计。 ")]), _c("div", [_vm._v(" 2、您也可以通过标准模板进行导入，点击 "), _c("span", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "12px",
      cursor: "pointer"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v(" 下载标准模板 ")])])])], 1)], 1), _c("a-modal", {
    attrs: {
      title: "未成功名单",
      width: "800px"
    },
    on: {
      ok: _vm.handleFailClose
    },
    model: {
      value: _vm.dialogFailVisible,
      callback: function callback($$v) {
        _vm.dialogFailVisible = $$v;
      },
      expression: "dialogFailVisible"
    }
  }, [_c("a-table", {
    attrs: {
      "row-key": function rowKey(d) {
        return d.id;
      },
      columns: _vm.columns,
      "data-source": _vm.Faildata,
      pagination: false
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };