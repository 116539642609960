import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.timers.js";
import { mapState } from "vuex";
import echarts from "echarts";
import moment from "moment";
import { getComCountPostReq, getTongji } from "../api";
export default {
  data: function data() {
    return {
      total: 0,
      pageNo: 1,
      open: true,
      selectF: 2,
      dateStartTemp: null,
      dateEndTemp: null,
      dateStart: {},
      dateEnd: {},
      joinDateStart: null,
      joinDateEnd: null,
      joinDateMoment: ["month", "month"],
      xAxis: [],
      series: [],
      pData: [],
      siteId: undefined
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr", "site"])), {}, {
    targetIds: function targetIds() {
      if (this.site.selectAllIds && this.site.selectAllIds !== "-1") {
        return this.site.selectAllIds.split(",");
      }
      return [];
    }
  }),
  watch: {
    targetIds: {
      handler: function handler(newVal) {
        var _this = this;
        if (newVal.length > 0) {
          this.$nextTick(function () {
            _this.initContract(newVal);
          });
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onSelect: function onSelect(index, subjectIds) {
      var _this2 = this;
      this.selectF = index;
      var contractChart = echarts.init(document.getElementById("contract_chart"));
      var params = {
        subjectIds: subjectIds || this.targetIds
      };
      if (index === -1) {
        params["publishDateStart"] = moment(this.dateStart.date).valueOf() + "";
        params["publishDateEnd"] = moment(this.dateEnd.date).valueOf() + "";
      } else {
        this.dateStart.dateString = "";
        this.dateStart.date = null;
        this.dateStartTemp = "";
        this.dateEnd.dateString = "";
        this.dateEnd.date = null;
        this.dateEndTemp = "";
      }
      getTongji(index, params).then(function (res) {
        _this2.xAxis = [];
        _this2.series1 = [];
        _this2.series2 = [];
        _this2.series3 = [];
        var temp = JSON.parse(JSON.stringify(res.data));
        temp.forEach(function (item, index) {
          _this2.xAxis[index] = item.date;
          item.list.forEach(function (da, ind) {
            for (var i in da) {
              // i 就是键，d[i]就是值
              if (i === "toSignedByComCount") {
                // 发起合同数
                _this2.series1[index] = parseInt(da[i]);
              }
            }
          });
        });
        contractChart.setOption({
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
            }
          },

          grid: {
            right: 0,
            bottom: 24,
            left: 36,
            top: 12
          },
          legend: {
            show: false
          },
          xAxis: {
            data: _this2.xAxis,
            max: "30"
          },
          yAxis: {},
          series: [{
            name: "发起合同数",
            type: "bar",
            stack: "统计",
            barMaxWidth: "20",
            color: "#4282FF",
            data: _this2.series1
          }],
          label: {}
        });
        setTimeout(function () {
          window.onresize = function () {
            contractChart.resize();
          };
        }, 200);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    onChange: function onChange(pageNumber) {
      var _this3 = this;
      this.pageNo = pageNumber;
      var a = [];
      if (this.siteId && this.siteId !== "-1") {
        a = this.siteId.split(",");
      }
      var data = {
        pageNo: this.pageNo,
        pageSize: 7,
        subjectIds: a
      };
      getComCountPostReq(data).then(function (res) {
        _this3.total = res.data.entityCount;
        _this3.pageNo = res.data.pageNo;
        // 获取排行榜
        _this3.pData = res.data.entities;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    initContract: function initContract(subjectIds) {
      var _this4 = this;
      this.onSelect(this.selectF, subjectIds); // 初始化柱状图
      getComCountPostReq({
        pageNo: this.pageNo,
        pageSize: 7,
        subjectIds: subjectIds
      }).then(function (res) {
        // 获取排行榜
        _this4.total = res.data.entityCount;
        _this4.pageNo = res.data.pageNo;
        _this4.pData = res.data.entities;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    startTimeChange: function startTimeChange(date, dateString) {
      var h = this.$createElement;
      this.dateStart.dateString = dateString;
      this.dateStart.date = date;
      this.dateStartTemp = date;
      if (this.dateEndTemp != null && this.dateStart.date != null && this.dateEnd.date != null) {
        if (this.dateStart.date < this.dateEnd.date) {
          this.onSelect(-1);
        } else {
          this.$notification.open({
            message: "日期选择错误",
            description: "开始日期不允许大于结束日期",
            icon: h("a-icon", {
              "attrs": {
                "type": "smile"
              },
              "style": "color: #108ee9"
            })
          });
        }
      } else if (this.dateStart.date === null && this.dateEnd.date === null) {
        this.onSelect(0);
      }
    },
    endTimeChange: function endTimeChange(date, dateString) {
      var h = this.$createElement;
      this.dateEnd.dateString = dateString;
      this.dateEnd.date = date;
      this.dateEndTemp = date;
      if (this.dateStartTemp != null && this.dateStart.date != null && this.dateEnd.date != null) {
        if (this.dateStart.date < this.dateEnd.date) {
          this.onSelect(-1);
        } else {
          this.$notification.open({
            message: "日期选择错误",
            description: "开始日期不允许大于结束日期",
            icon: h("a-icon", {
              "attrs": {
                "type": "smile"
              },
              "style": "color: #108ee9"
            })
          });
        }
      } else if (this.dateStart.date === null && this.dateEnd.date === null) {
        this.onSelect(0);
      }
    }
  }
};