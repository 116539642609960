import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import moment from "moment";
import { addRules } from "@/api/outsourcing/outsourcing";
export default {
  data: function data() {
    return {
      form: {},
      ruleType: "1",
      option: [{
        label: "工时",
        value: 1
      }, {
        label: "月",
        value: 2
      }, {
        label: "天",
        value: 3
      }],
      title: "",
      placement: "right",
      checked: false,
      checked1: false,
      checked2: false,
      checked3: false,
      visible: false,
      changeList: [],
      numList: [],
      leaveList: [],
      goOnState1: true,
      goOnState2: true,
      goOnState3: true,
      goOnState4: true,
      goOnState5: true,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 20
      }
    };
  },
  methods: {
    limitNumber: function limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      } else {
        return 0;
      }
    },
    tabsChange: function tabsChange(e) {
      var name = this.form.name;
      this.form = {};
      this.$set(this.form, "name", name);
      // this.form.hourAmountDouble = null;
      // this.form.commissionRatio = null;
      // this.form.specialCostDouble = null;
      this.onChange({
        target: {
          checked: false
        }
      });
      this.onChange1({
        target: {
          checked: false
        }
      });
      this.onChange2({
        target: {
          checked: false
        }
      });
      // this.onChange3({ target: { checked: false } });
      if (e.target.value === "1" || e.target.value === "5") {
        this.option = [{
          label: "月",
          value: 1
        }, {
          label: "天",
          value: 2
        }, {
          label: "工时",
          value: 3
        }];
      }
      if (e.target.value === "4") {
        this.option = [{
          label: "月",
          value: 1
        }, {
          label: "天",
          value: 3
        }];
      }
    },
    // 新增规则
    handleAddOK: function handleAddOK() {
      var _this = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this.changeList.forEach(function (item, index) {
            if (item.time) {
              item.time = moment(item.time).format("YYYY-MM-DD");
            }
          });
          var changeList = JSON.parse(JSON.stringify(_this.changeList)); // 工价数组
          var leaveList = JSON.parse(JSON.stringify(_this.leaveList)); // 倒扣数组
          changeList.map(function (item) {
            item.type = Number(item.type);
          });
          leaveList.map(function (item) {
            item.type = Number(item.type);
          });
          _this.form.hourAmountRule = JSON.parse(JSON.stringify(changeList));
          _this.form.redPacketRule = _this.numList;
          _this.form.leaveOfficeRule = leaveList;
          if (_this.form.hourAmountRule.length > 0) {
            _this.form.hourAmountRule.forEach(function (item, index) {
              if (item.type === "1") {
                if (item.newHourAmount === null || item.time === null || item.newHourAmount === undefined || item.time === undefined) {
                  _this.$message.error("固定日期变更规则内容都为必填");
                  _this.goOnState1 = false;
                } else {
                  _this.goOnState1 = true;
                }
              }
              if (item.type === "2") {
                if (item.newHourAmount === null || item.units === null || item.number === null || item.newHourAmount === undefined || item.units === undefined || item.number === undefined) {
                  _this.$message.error("按月/天/工时变更规则内容都为必填");
                  _this.goOnState2 = false;
                } else {
                  _this.goOnState2 = true;
                }
              }
            });
          }
          if (_this.form.redPacketRule.length > 0) {
            _this.form.redPacketRule.forEach(function (item, index) {
              if (item.redPacketAmount === null || item.units === null || item.number === null || item.redPacketAmount === undefined || item.units === undefined || item.number === undefined) {
                _this.$message.error("一次性红包规则内容都为必填");
                _this.goOnState3 = false;
              } else {
                _this.goOnState3 = true;
              }
            });
          }
          if (_this.form.leaveOfficeRule.length > 0) {
            _this.form.leaveOfficeRule.forEach(function (item, index) {
              if (item.type === "1") {
                if (item.number === null || item.units === null || item.deductAmount === null || item.number === undefined || item.units === undefined || item.deductAmount === undefined) {
                  _this.$message.error("倒扣工资规则内容都为必填");
                  _this.goOnState4 = false;
                } else {
                  _this.goOnState4 = true;
                }
              }
              if (item.type === "2") {
                if (item.number === null || item.units === null || item.newHourAmount === null || item.number === undefined || item.units === undefined || item.newHourAmount === undefined) {
                  _this.$message.error("减少工价规则内容都为必填");
                  _this.goOnState5 = false;
                } else {
                  _this.goOnState5 = true;
                }
              }
            });
          }
          if (_this.title === "新增招工劳务费规则" && _this.goOnState1 === true && _this.goOnState2 === true && _this.goOnState3 === true && _this.goOnState4 === true && _this.goOnState5 === true) {
            _this.form.specialCostDouble = _this.form.specialCost;
            addRules(_objectSpread(_objectSpread({
              ruleType: 2
            }, _this.form), {}, {
              showType: _this.ruleType === "4" ? 1 : _this.ruleType === "5" ? 3 : 2
            })).then(function (res) {
              _this.$message.success("添加成功");
              _this.$emit("fatherMethod");
              _this.visible = false;
            });
          }
        }
      });
    },
    onClose: function onClose() {
      this.visible = false;
    },
    // 日期选择器变化
    dateChange: function dateChange() {},
    // 添加事件
    onCreate: function onCreate() {
      var _this2 = this;
      this.title = "新增招工劳务费规则";
      this.visible = true;
      this.$nextTick(function () {
        _this2.$refs.ruleForm.clearValidate();
        _this2.form = {};
        _this2.changeList = [];
        _this2.numList = [];
        _this2.leaveList = [];
        _this2.checked = false;
        _this2.checked1 = false;
        _this2.checked2 = false;
        _this2.checked3 = false;
      });
    },
    // 一次性红包添加事件
    onChange: function onChange(e) {
      if (e.target.checked) {
        this.numList.push({
          number: null,
          units: null,
          redPacketAmount: null
        });
        this.checked = true;
      } else {
        this.numList = [];
        this.checked = false;
      }
    },
    // 离职倒扣
    onChange1: function onChange1(e) {
      if (e.target.checked) {
        this.checked1 = true;
      } else {
        this.checked1 = false;
        this.leaveList = [];
      }
    },
    // 其他费用
    onChange2: function onChange2(e) {
      if (e.target.checked) {
        this.checked2 = true;
      } else {
        this.checked2 = false;
        this.form.specialCost = 0;
      }
    },
    // 工时抽成
    onChange3: function onChange3(e) {
      if (e.target.checked) {
        this.checked3 = true;
      } else {
        this.checked3 = false;
        this.changeList = [];
      }
    },
    createNumList: function createNumList() {
      this.numList.push({
        number: null,
        units: null,
        redPacketAmount: null
      });
    },
    // 删除发放红包项
    deleteNumList: function deleteNumList(item, index) {
      this.numList.splice(index, 1);
      if (this.numList.length === 0) {
        this.checked = false;
      }
    },
    // 新增倒扣条件
    handleMenuClick: function handleMenuClick(e) {
      if (e.key === "1") {
        this.leaveList.push({
          type: e.key,
          number: null,
          units: "",
          deductAmount: null
        }); // 不满月/天/工时倒扣工资
      } else {
        this.leaveList.push({
          type: e.key,
          number: null,
          units: "",
          newHourAmount: null
        }); // 不满月/天/工时减少全价
      }
    },
    // 新增变更条件
    handleMenuClick2: function handleMenuClick2(e) {
      if (e.key === "1") {
        this.changeList.push({
          type: e.key,
          newHourAmount: null,
          time: null
        });
      } else {
        this.changeList.push({
          type: e.key,
          number: null,
          units: null,
          newHourAmount: null
        });
      }
    },
    // 删除离职倒扣项
    deleteLeaveList: function deleteLeaveList(item, index) {
      this.leaveList.splice(index, 1);
      if (this.leaveList.length === 0) {
        this.checked1 = false;
      }
    },
    // 删除变更条件
    deleteChangeList: function deleteChangeList(item, index) {
      this.changeList.splice(index, 1);
      if (this.changeList.length === 0) {
        this.checked3 = false;
      }
    }
  }
};