import { getNewDetail } from "@/api/company/news";
import editNew from "./components/editNew";
import previewNew from "./components/previewNew";
export default {
  components: {
    EditNew: editNew,
    PreviewNew: previewNew
  },
  data: function data() {
    return {
      pushDate: undefined,
      pageTitle: "新建",
      isActive: -1,
      current: 1,
      currentNew: null,
      companyId: "",
      noticeTitle: "",
      noticeFileList: [],
      groupCode: null,
      noticeTime: undefined,
      noticeContent: "",
      noticeContentHasStyle: "",
      noticeTitleInit: "",
      noticeContentHasStyleInit: "",
      newsTypeId: "",
      noticeTimeString: undefined
    };
  },
  mounted: function mounted() {
    this.companyId = this.$store.state.hr.companyId;
    this.groupCode = this.$route.query.groupCode;
    this.newsTypeId = this.$route.query.id;
    if (this.$route.query.state === "edit") {
      this.pageTitle = "编辑";
      this.getDetail(this.newsTypeId);
    } else {
      this.pageTitle = "新建";
    }
  },
  methods: {
    getDetail: function getDetail(newsTypeId) {
      var _this = this;
      getNewDetail({
        newsTypeId: newsTypeId
      }).then(function (res) {
        _this.currentNew = res.data;
        _this.noticeTitle = _this.currentNew.title;
        _this.pushDate = _this.currentNew.pushDate;
        _this.noticeFileList = _this.currentNew.newsFileListVO;
        _this.noticeContentHasStyle = _this.currentNew.contentHasStyle;
        _this.noticeTime = _this.currentNew.pushDate;
        _this.noticeTimeString = _this.currentNew.pushDate + "";
      });
    },
    goback: function goback() {
      this.$router.go(-1);
    },
    nextStep: function nextStep() {
      this.current = 1;
    },
    stepModulechange: function stepModulechange(e) {
      this.current = e;
    }
  }
};