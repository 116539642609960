export default {
  name: "ItemCustomer",
  components: {},
  mixins: [],
  props: {
    showDetail: {
      type: Boolean,
      default: true
    },
    customerDetailsType: {
      type: String,
      default: ""
    },
    target: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    openClick: function openClick(item) {
      this.$emit("openClick", item);
    },
    goDetail: function goDetail(item) {
      this.$emit("goDetail", item);
    },
    deleteItem: function deleteItem(item) {
      this.$emit("deleteItem", item);
    }
  }
};