import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import axios from "axios";
import qs from "qs";
import { getToken } from "xqjr-module-auth";
import { message } from "ant-design-vue";
export function publicDownLoad(file) {
  axios({
    url: "/file/api/v1/general/download",
    params: {
      filePath: file,
      downloadType: "attachment"
    },
    paramsSerializer: function paramsSerializer(param) {
      return qs.stringify(param, {
        indices: false
      });
    },
    method: "get",
    responseType: "blob",
    headers: {
      token: getToken()
    }
  }).then(function (res) {
    if (res.data && res.data.type === "application/json") {
      var reader = new FileReader();
      reader.readAsText(res.data, "utf-8");
      reader.onload = function () {
        var resJson = JSON.parse(reader.result);
        message.error(resJson.msg);
      };
    } else {
      var rspHeaders = res.headers;
      var blob = new Blob([res.data], {
        type: "application/octet-stream"
      });
      var link = document.createElement("a");
      try {
        var fileName = decodeURI(rspHeaders["content-disposition"].split(";")[1].split("=")[1].replaceAll('"', ""));
        link.download = fileName;
      } catch (err) {
        console.log(err);
      }
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    }
  }).catch(function (err) {
    console.log("debug log --> ", err);
  });
}