import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "seal-editor-wrapper page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.back
    }
  }), _c("div", {
    staticClass: "edit-seal-editor-content"
  }, [_c("div", {
    staticClass: "seal-editor-content-block"
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "印章名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入印章名称！"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '请输入印章名称！' }] }]"
    }],
    attrs: {
      placeholder: "请输入印章名称",
      "allow-clear": ""
    },
    on: {
      input: function input(e) {
        return _vm.name = e.target.value;
      }
    }
  }), _c("div", {
    staticClass: "seal-editor-content-block-tip"
  }, [_vm._v("用于区别您的印章，不显示在印章图片上")])], 1), _c("a-form-item", {
    attrs: {
      label: "企业名称"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入企业名称",
      "allow-clear": ""
    },
    model: {
      value: _vm.roundText,
      callback: function callback($$v) {
        _vm.roundText = $$v;
      },
      expression: "roundText"
    }
  }), _c("div", {
    staticClass: "seal-editor-content-block-tip"
  }, [_vm._v(" 企业名称需要和营业执照上的企业名称一致，申请开通中不允许修改，通过申请后会显示在印章上 ")])], 1), _c("a-form-item", {
    attrs: {
      label: "企业法人"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入企业法人",
      allowClear: "",
      maxLength: 4
    },
    model: {
      value: _vm.legalPerson,
      callback: function callback($$v) {
        _vm.legalPerson = $$v;
      },
      expression: "legalPerson"
    }
  }), _c("div", {
    staticClass: "seal-editor-content-block-tip"
  }, [_vm._v("填写法人后会生成法人章，合同签署时可以选择是否使用，最多输入 4 个字")])], 1), _c("a-form-item", {
    attrs: {
      label: "横向文"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "此处填写横向文",
      "allow-clear": ""
    },
    model: {
      value: _vm.rowText,
      callback: function callback($$v) {
        _vm.rowText = $$v;
      },
      expression: "rowText"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "下弦文"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "此处填写下弦文",
      "allow-clear": ""
    },
    model: {
      value: _vm.bottomText,
      callback: function callback($$v) {
        _vm.bottomText = $$v;
      },
      expression: "bottomText"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "印章类型"
    }
  }, [_c("div", {
    staticClass: "seal-editor-type-wrapper"
  }, [_c("div", {
    staticClass: "seal-editor-type",
    class: {
      active: _vm.enterpriseSealType === "STAR"
    },
    on: {
      click: function click($event) {
        return _vm.setType("STAR");
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/img/seal-circle.png")
    }
  })]), _c("div", {
    staticClass: "seal-editor-type",
    class: {
      active: _vm.enterpriseSealType === "OVAL"
    },
    on: {
      click: function click($event) {
        return _vm.setType("OVAL");
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/img/seal-oval.png")
    }
  })])])]), _c("a-form-item", {
    attrs: {
      label: "印章颜色"
    }
  }, [_c("div", {
    staticClass: "seal-editor-color-wrapper"
  }, [_c("div", {
    staticClass: "seal-editor-color red",
    class: {
      active: _vm.color === "RED"
    },
    on: {
      click: function click($event) {
        return _vm.setColor("RED");
      }
    }
  }), _c("div", {
    staticClass: "seal-editor-color black",
    class: {
      active: _vm.color === "BLACK"
    },
    on: {
      click: function click($event) {
        return _vm.setColor("BLACK");
      }
    }
  }), _c("div", {
    staticClass: "seal-editor-color blue",
    class: {
      active: _vm.color === "BLUE"
    },
    on: {
      click: function click($event) {
        return _vm.setColor("BLUE");
      }
    }
  })])])], 1)], 1), _c("div", {
    staticClass: "seal-editor-content-block border"
  }, [_c("div", {
    staticClass: "seal-editor-content-block-title"
  }, [_vm._v("预览图")]), _c("div", {
    staticClass: "seal-editor-seal-wrapper"
  }, [_c("seal", {
    attrs: {
      "round-text": _vm.roundText,
      "row-text": _vm.rowText,
      "bottom-text": _vm.bottomText,
      type: _vm.enterpriseSealType === "STAR" ? "circle" : _vm.enterpriseSealType,
      color: _vm.color
    },
    model: {
      value: _vm.sealString,
      callback: function callback($$v) {
        _vm.sealString = $$v;
      },
      expression: "sealString"
    }
  })], 1), _vm.legalPersonText && _vm.legalPersonText.length && _vm.legalPersonText.length > 0 ? _c("div", {
    staticClass: "seal-editor-seal-wrapper",
    staticStyle: {
      width: "170px",
      height: "170px",
      "margin-top": "24px"
    }
  }, [_c("div", {
    staticStyle: {
      width: "140px",
      height: "140px",
      "font-size": "50px",
      border: "8px solid red",
      color: "red",
      "font-weight": "500",
      position: "relative",
      "line-height": "56px"
    },
    attrs: {
      id: "legalPersonSeal"
    }
  }, _vm._l(_vm.legalPersonText, function (lt, lt_i) {
    return _c("div", {
      key: lt_i,
      staticStyle: {
        position: "absolute",
        width: "62px",
        height: "62px",
        "line-height": "31px"
      },
      style: "top: ".concat(lt_i === 0 || lt_i === 2 ? "16px" : "76px", "; left: ").concat(lt_i === 0 || lt_i === 1 ? "66px" : "8px", ";")
    }, [_vm._v(" " + _vm._s(lt) + " ")]);
  }), 0)]) : _vm._e()])]), _c("div", {
    staticClass: "edit-seal-editor-footer"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" 确认并" + _vm._s(this.id === null ? "添加" : "更新"))])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };