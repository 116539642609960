import "core-js/modules/es.array.push.js";
import { getdetail } from "@/api/wages/set";
var taxRule = ["工资薪金所得", "劳动报酬所得", "不计税"];
export default {
  data: function data() {
    return {
      dataDetail: {},
      taxRule: taxRule
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    goDetail: function goDetail() {
      this.$router.push({
        path: "/wages/set",
        query: {
          tabs: "1"
        }
      });
    },
    init: function init() {
      var _this = this;
      var taxRuleId = this.$route.query.taxRuleId;
      getdetail(taxRuleId).then(function (res) {
        _this.dataDetail = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};