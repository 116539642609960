var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-row", {
    staticClass: "salary",
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    staticClass: "item",
    attrs: {
      span: 10
    }
  }, [_c("a-row", {
    ref: "box",
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.getItem.apply(null, arguments);
      }
    }
  }, [_c("a-col", {
    attrs: {
      span: 7
    },
    on: {
      click: _vm.handleEvent
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "primary"
  }, [_vm._v("总接单")])]), _c("a-row", {
    staticClass: "primary"
  }, [_c("a-statistic", {
    attrs: {
      value: _vm.options.numAll || 0
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function fn() {
        return [_vm._v("人")];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 7
    },
    on: {
      click: _vm.handleEvent
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("已完成")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      value: _vm.options.numImportNoError || 0
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function fn() {
        return [_vm._v("人")];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 7
    },
    on: {
      click: _vm.handleEvent
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("未完成")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      value: _vm.options.numImportError || 0
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function fn() {
        return [_vm._v("人")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c("a-col", {
    staticClass: "item",
    attrs: {
      span: 8
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 10
    },
    on: {
      click: _vm.handleEvent
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("个人缴费合计")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      precision: 2,
      value: _vm.options.personalAllAmount || 0
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function fn() {
        return [_vm._v("￥")];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 10
    },
    on: {
      click: _vm.handleEvent
    }
  }, [_c("a-row", {
    staticClass: "ove"
  }, [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("公司缴费合计")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      precision: 2,
      value: _vm.options.companyAllAmount || 0
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function fn() {
        return [_vm._v("￥")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c("a-col", {
    staticClass: "item",
    attrs: {
      span: 4
    }
  }, [_c("a-row", [_c("strong", {
    staticClass: "sub_tit"
  }, [_vm._v("总合计预缴金额")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      precision: 2,
      value: _vm.options.allAmount || 0
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function fn() {
        return [_vm._v("￥")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };