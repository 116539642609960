var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-spin", {
    attrs: {
      spinning: _vm.uploadSpinning,
      tip: "数据保存中..."
    }
  }, [_c("a-alert", {
    staticStyle: {
      margin: "24px",
      "background-color": "#fff1f0"
    },
    attrs: {
      type: "error",
      message: "有" + this.dataJson.errorList.length + "条数据异常，请尽快处理",
      "show-icon": "",
      closable: "",
      "after-close": _vm.handleClose,
      "close-text": "导出错误数据"
    }
  }), _c("div", {
    staticClass: "xq-form-wrapper pbox"
  }, [_c("a-table", {
    staticClass: "xq-draggable-table",
    attrs: {
      columns: _vm.columnsw,
      "data-source": this.dataJson.errorList,
      "row-key": function rowKey(d) {
        return d["姓名"];
      },
      scroll: {
        x: 800
      },
      pagination: _vm.pagination
    }
  })], 1), _c("div", {
    staticClass: "preview-btn"
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("onAction", 0);
      }
    }
  }, [_vm._v("返回")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(" 下一步 ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };