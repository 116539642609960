import request from "@/utils/request";
export function getTalentPoolInfo(params) {
  return request({
    url: "/recruit2/api/v1/jobSeekers/getTalentPoolInfo",
    method: "GET",
    params: params
  });
}
export function getTalentPoolInfoIntelligentRecommendationReq(params) {
  return request({
    url: "/recruit2/api/v1/jobSeekers/intelligentRecommendation",
    method: "GET",
    params: params
  });
}
export function getCompanyUnits(params) {
  return request({
    url: "/recruit2/api/v1/companyUnits/pageByCondition",
    method: "GET",
    params: params
  });
}
export function delJobSeekers(talentPoolId) {
  return request({
    url: "/recruit2/api/v1/talentPools/".concat(talentPoolId),
    // url: `/recruit2/api/v1/jobSeekers/${jobSeekerId}`,
    method: "delete"
  });
}
export function getOperationJobSeekerRecords(params) {
  return request({
    url: "/recruit2/api/v1/operationJobSeekerRecords/listAll",
    method: "GET",
    params: params
  });
}
export function listAllByIsCancelGetListReq() {
  return request({
    url: "/recruit2/api/v1/recruitInfos/listAllByIsCancelGetList",
    method: "GET"
  });
}