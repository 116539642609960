import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { mapState } from "vuex";
import VueWangeditor from "@/components/ZXR/vueWangeditor";
import { fileListDecode, fileListEncode } from "@/utils/tools";
import { levelOptions } from "./funcs";
import { filterTree } from "@/utils/tools";
export default {
  name: "",
  components: {
    VueWangeditor: VueWangeditor
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      levelOptions: levelOptions,
      policyLevel: "1",
      typeCode: undefined,
      title: "",
      annex: [],
      content: "",
      contentHasStyle: "",
      groupLst: [],
      targetKeys: [],
      treeData: [],
      transferVisible: false,
      replaceFields: {
        children: "companyList",
        title: "name",
        key: "uuid",
        value: "uuid"
      },
      companyTypeList: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["site"])), {}, {
    companyTreeWithUuid: function companyTreeWithUuid() {
      return this.site.companyTreeWithUuid;
    },
    companyUuidDictWithInfo: function companyUuidDictWithInfo() {
      return this.site.companyUuidDictWithInfo;
    },
    cidToUid: function cidToUid() {
      var items = Object.values(this.companyUuidDictWithInfo);
      var dict = {};
      for (var i = 0; i < items.length; i++) {
        dict[items[i].id] = items[i].uuid;
      }
      return dict;
    },
    transferDataSource: function transferDataSource() {
      var newList = Object.values(this.companyUuidDictWithInfo).map(function (item) {
        return {
          key: item.uuid,
          title: item.name,
          disabled: item.type === "site"
        };
      });
      return newList;
    },
    tId: function tId() {
      return this.$route.query.tId;
    },
    id: function id() {
      return this.$route.query.id;
    },
    itemDetail: function itemDetail() {
      var temp = localStorage.getItem("tempZhengce");
      if (temp) {
        return JSON.parse(temp);
      }
      return null;
    },
    isDetail: function isDetail() {
      return this.$route.path.indexOf("detail") > -1;
    }
  }),
  watch: {
    companyUuidDictWithInfo: {
      handler: function handler() {
        if (Object.keys(this.companyUuidDictWithInfo).length > 0) {
          var treeDataTemp = filterTree(this.companyTreeWithUuid, "", "companyList", "name");
          if (treeDataTemp[0].type === "site") {
            treeDataTemp[0].disabled = true;
          }
          this.treeData = treeDataTemp;
        }
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    var _this = this;
    this.onGroupSearchChange();
    if (!this.id) {
      this.typeCode = this.tId;
    } else {
      this.title = this.itemDetail.title;
      this.typeCode = this.itemDetail.typeCode;
      this.policyLevel = this.itemDetail.policyLevel;
      this.annex = this.itemDetail.annex ? fileListEncode(this.itemDetail.annex) : [];
      this.contentHasStyle = this.itemDetail.contentHasStyle;
      this.content = this.itemDetail.content;
      this.companyTypeList = this.itemDetail.companyTypeList;
      this.targetKeys = this.itemDetail.companyTypeList.map(function (item) {
        return _this.cidToUid[item.id];
      });
    }
  },
  mounted: function mounted() {
    localStorage.setItem("tempTypeCode", this.tId);
  },
  methods: {
    onTransferOk: function onTransferOk() {
      var _this2 = this;
      this.companyTypeList = this.targetKeys.map(function (key) {
        return _this2.companyUuidDictWithInfo[key];
      });
      this.transferVisible = false;
    },
    onTransferSearch: function onTransferSearch(dir, val) {
      if (dir === "left") {
        var treeDataTemp = filterTree(this.companyTreeWithUuid, val, "companyList", "name");
        if (treeDataTemp[0].type === "site") {
          treeDataTemp[0].disabled = true;
        }
        this.treeData = treeDataTemp;
      }
    },
    onTransChange: function onTransChange(targetKeys) {
      this.targetKeys = targetKeys;
    },
    onChecked: function onChecked(_, e, checkedKeys, itemSelect) {
      var eventKey = e.node.eventKey;
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
    },
    doSave: function doSave() {
      var _this3 = this;
      var newValues = {
        typeCode: this.typeCode,
        title: this.title,
        policyLevel: this.policyLevel,
        annex: fileListDecode(this.annex),
        contentHasStyle: this.contentHasStyle,
        content: this.content,
        companyTypeList: this.companyTypeList.filter(function (item) {
          return !!item;
        })
      };
      if (!this.typeCode || !this.title || !this.policyLevel) {
        this.$message.warning("必须选择分类、级别，并填写标题");
        return false;
      }
      localStorage.setItem("tempTypeCode", this.typeCode);
      if (this.id) {
        this.$request({
          url: "/hr/api/v1/policyCouriers",
          method: "put",
          data: _objectSpread({
            id: this.id
          }, newValues)
        }).then(function (res) {
          _this3.$router.back();
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        this.$request({
          url: "/hr/api/v1/policyCouriers",
          method: "post",
          data: newValues
        }).then(function (res) {
          _this3.$router.back();
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    onEditorChange: function onEditorChange(html, text) {
      this.contentHasStyle = html;
      this.content = text;
    },
    onFileChange: function onFileChange(_ref) {
      var file = _ref.file,
        fileList = _ref.fileList;
      this.annex = fileList;
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    onGroupSearchChange: function onGroupSearchChange() {
      var _this4 = this;
      this.$request({
        url: "/hr/api/v1/policyCourierTypes/listAll",
        params: {}
      }).then(function (res) {
        _this4.groupLst = res.data.map(function (item) {
          return {
            label: item.name,
            value: item.code
          };
        });
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};
function isChecked(selectedKeys, eventKey) {
  return selectedKeys.indexOf(eventKey) !== -1;
}