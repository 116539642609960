import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-center"
  }, [_c("div", {
    staticClass: "flex-shrink-0 pr16 border-right"
  }, [_c("div", {
    staticClass: "flex-center"
  }, [!_vm.options.unfinishedCount ? _c("img", {
    staticStyle: {
      width: "16px",
      height: "16px"
    },
    attrs: {
      src: require("@/assets/img/shebaopaidan/blueright.png")
    }
  }) : _vm._e(), _vm.options.unfinishedCount ? _c("img", {
    staticStyle: {
      width: "16px",
      height: "16px"
    },
    attrs: {
      src: require("@/assets/img/shebaopaidan/rectangle.png")
    }
  }) : _vm._e(), _c("a-tooltip", {
    attrs: {
      title: _vm.options.missionName
    }
  }, [_c("div", {
    staticClass: "fs18 fw600 mh8 ellipsis pointer",
    staticStyle: {
      width: "240px"
    }
  }, [_vm._v(_vm._s(_vm.options.missionName))])]), _vm.options.state === 0 ? _c("div", {
    staticClass: "ph4 br4 fs12",
    staticStyle: {
      border: "1px solid rgba(255, 165, 38, 0.6)",
      color: "#ffbb00"
    }
  }, [_vm._v("未接单")]) : _vm.options.state === 1 ? _c("a-popover", {
    attrs: {
      placement: "rightTop"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "400",
      color: "#a6a8b4"
    }
  }, [_c("span", [_vm._v("接单人")]), _c("span", {
    staticStyle: {
      "margin-left": "80px"
    }
  }, [_vm._v("接单时间")])]), _vm._l(_vm.catLst, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "font-size": "12px",
        "font-weight": "400",
        color: "#767885",
        "margin-top": "8px"
      }
    }, [_c("span", [_vm._v(_vm._s(item.name))]), _c("span", {
      staticStyle: {
        "margin-left": "80px"
      }
    }, [_vm._v(_vm._s(item.date))])]);
  }), _c("div", [_c("span"), _c("span")])], 2), _c("div", {
    staticClass: "ph4 br4 fs12",
    staticStyle: {
      border: "1px solid #32ccaa",
      color: "#32ccaa"
    }
  }, [_vm._v("已接单")])], 2) : _vm._e()], 1), _c("div", {
    staticClass: "mt12 pl24",
    staticStyle: {
      color: "#9c9c9c"
    }
  }, [_vm._v(_vm._s(_vm.moment(_vm.options.gmtCreate).format("YYYY-MM-DD HH:mm")))])]), _c("div", {
    staticClass: "flex-1 flex-center overflow-x-auto",
    staticStyle: {
      color: "#9c9c9c"
    }
  }, [_c("div", {
    staticClass: "ph16 border-right"
  }, [_c("div", [_vm._v("派单人")]), _c("div", {
    staticClass: "fs18 fw500 mt8 tc-l1"
  }, [_vm._v(_vm._s(_vm.options.createUser))])]), _c("div", {
    staticClass: "ph16 border-right"
  }, [_c("div", [_vm._v("派单人数")]), _c("div", {
    staticClass: "fs18 fw500 mt8 tc-l1"
  }, [_vm._v(_vm._s(_vm.options.dispatchEmpCount) + "人")])]), _c("div", {
    staticClass: "ph16"
  }, [_c("div", [_vm._v("已完成")]), _c("div", {
    staticClass: "fs18 fw500 mt8 tc-l1"
  }, [_vm._v(_vm._s(_vm.options.completedCount) + "人")])]), _c("div", {
    staticClass: "ph16"
  }, [_c("div", [_vm._v("未完成")]), _c("div", {
    staticClass: "fs18 fw500 mt8 tc-l1"
  }, [_vm._v(_vm._s(_vm.options.unfinishedCount) + "人")])]), _c("div", {
    staticClass: "ph16 border-right"
  }, [_c("div", [_vm._v("异常")]), _c("div", {
    staticClass: "fs18 fw500 mt8 tc-l1"
  }, [_vm._v(_vm._s(_vm.options.abnormalCount) + "人")])])]), _c("div", {
    staticClass: "flex-center flex-shrink-0 ml12"
  }, [_vm.options.fileType === 2 ? _c("span", {
    staticClass: "tc-theme pointer",
    on: {
      click: function click($event) {
        _vm.modalVisible = true;
      }
    }
  }, [_vm._v("查看图片")]) : _c("span", {
    staticClass: "tc-theme pointer",
    on: {
      click: function click($event) {
        return _vm.handleEvent(_vm.options);
      }
    }
  }, [_vm._v("查看详情")]), _c("a-dropdown", [_c("div", {
    staticClass: "pointer"
  }, [_c("span", {
    staticClass: "fs16 ml12",
    staticStyle: {
      color: "#9c9c9c"
    }
  }, [_vm._v("•")]), _c("span", {
    staticClass: "fs16 ml2",
    staticStyle: {
      color: "#9c9c9c"
    }
  }, [_vm._v("•")]), _c("span", {
    staticClass: "fs16 ml2",
    staticStyle: {
      color: "#9c9c9c"
    }
  }, [_vm._v("•")])]), _c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    attrs: {
      disabled: _vm.options.unfinishedCount !== _vm.options.dispatchEmpCount
    },
    on: {
      click: function click($event) {
        return _vm.handleMenuchange(_vm.options);
      }
    }
  }, [_vm._v(" 删除 ")])], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "查看图片",
      width: "550px"
    },
    model: {
      value: _vm.modalVisible,
      callback: function callback($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c("div", {
    staticClass: "ta-right",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("a-space", {
    attrs: {
      size: 16
    }
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        _vm.modalVisible = false;
      }
    }
  }, [_vm._v("取消")]), _vm.options.state === 0 ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.doConfirm
    }
  }, [_vm._v("确认接单")]) : _vm._e()], 1)], 1), _c("div", {
    staticClass: "flex-center pointer",
    staticStyle: {
      "flex-wrap": "wrap"
    }
  }, _vm._l(_vm.urls, function (url, idx) {
    return _c("div", {
      key: url,
      staticClass: "ml12 mb12",
      style: {
        width: "150px",
        height: "90px",
        "background-image": "url('".concat(url, "?x-oss-process=image/resize,w_150')"),
        "background-size": "contain",
        "background-repeat": "no-repeat",
        "background-position": "center"
      },
      on: {
        click: function click($event) {
          return _vm.previewImg(idx);
        }
      }
    });
  }), 0)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };