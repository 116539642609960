import request from "@/utils/request";
// 查询社保统计页面中部社保的各项详情
export function exportEmpSocInfo(params) {
  return request({
    url: "/hr/api/v1/socStatistical/exportEmpSocInfo",
    method: "get",
    params: params
  });
}
// 查询社保统计页面的顶部总计
export function selectAmount(params) {
  return request({
    url: "/hr/api/v1/socStatistical/selectAmount",
    method: "get",
    params: params
  });
}
// 查询社保统计页面底部参保方案,参保地统计信息
export function selectCountAndProportion(params) {
  return request({
    url: "/hr/api/v1/socStatistical/selectCountAndProportion",
    method: "get",
    params: params
  });
}
// 查询社保统计页面中部右侧员工参保信息
export function selectEmpSocInfoStatistical(params) {
  return request({
    url: "/hr/api/v1/socStatistical/selectEmpSocInfoStatistical",
    method: "get",
    params: params
  });
}
// 查询某个时间段的各个月份的社保和公积金统计
export function selectMonthStatistical(params) {
  return request({
    url: "/hr/api/v1/socStatistical/selectMonthStatistical",
    method: "get",
    params: params
  });
}
// 查询社保统计页面中部社保的各项详情
export function selectSocProgItem(params) {
  return request({
    url: "/hr/api/v1/socStatistical/selectSocProgItem",
    method: "get",
    params: params
  });
}
// 导出