var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "page_set"
  }, [_c("a-layout", {
    staticClass: "a_layout"
  }, [_c("router-view"), _c("temp")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };