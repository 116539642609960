import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
var commonRoutes = [{
  path: "checkCard",
  // 考勤打卡
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/check/checkCard"));
    });
  }
}, {
  name: "checkCard/imports",
  path: "checkCard/imports",
  // 考勤打卡数据导入
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/check/checkCard/importsDataTwo"));
    });
  }
}, {
  name: "checkCard/workingHours/msg",
  path: "checkCard/workingHours/msg",
  // 考勤打卡
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/check/checkCard/workingHoursMsg"));
    });
  }
}, {
  path: "checkCard/cardDetail",
  // 打卡记录
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/check/checkCard/detail"));
    });
  }
}, {
  path: "checkTable",
  // 每日考勤
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/check/checkTable"));
    });
  }
}, {
  path: "checkSet",
  // 考勤设置
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/check/checkSet"));
    });
  }
}, {
  path: "checkSet/checkEditorRules/:id",
  // 打卡规则
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/check/checkSet/editorRules"));
    });
  }
}, {
  path: "checkTimeEntry",
  // 工时录入
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/check/checkTimeEntry"));
    });
  }
}];
export default {
  path: "/check",
  // 考勤打卡
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  children: [].concat(commonRoutes, [])
};