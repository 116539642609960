// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/banner.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".box[data-v-c535379e] {\n  background: #fff;\n  border-radius: 8px;\n  overflow: hidden;\n  padding: 24px;\n}\n.title[data-v-c535379e] {\n  position: relative;\n  height: 18px;\n  line-height: 18px;\n  font-size: 18px;\n  color: rgb(60, 61, 67);\n  font-weight: 500;\n  padding-left: 16px;\n}\n.title[data-v-c535379e]:before {\n  content: \"\";\n  position: absolute;\n  width: 4px;\n  height: 18px;\n  border-radius: 2px;\n  left: 0;\n  top: 0;\n  background: var(--primary);\n}\n.role[data-v-c535379e] {\n  width: 100px;\n  padding: 0 12px;\n  border: 1px solid var(--primary);\n  color: var(--primary);\n  background: var(--primary10);\n  border-radius: 2px;\n  height: 20px;\n  line-height: 20px;\n}\n.moduleItem[data-v-c535379e] {\n  width: 352px;\n  height: auto;\n  border-radius: 8px;\n}\n.swap[data-v-c535379e] .font-title {\n  font-size: 14px !important;\n  color: var(--primary) !important;\n  font-weight: normal !important;\n  line-height: 14px;\n}\n.banner[data-v-c535379e] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n}", ""]);
// Exports
module.exports = exports;
