import request from "@/utils/request";
export function addPeople(data) {
  return request({
    url: "/hr/api/v1/transferAccountsPeoples",
    method: "post",
    data: data
  });
}
export function editPeople(data) {
  return request({
    url: "/hr/api/v1/transferAccountsPeoples",
    method: "put",
    data: data
  });
}
export function getPeopleList(params) {
  return request({
    url: "/hr/api/v1/transferAccountsPeoples",
    method: "get",
    params: params
  });
}
export function deletePeople(transferAccountsPeopleId) {
  return request({
    url: "/hr/api/v1/transferAccountsPeoples/".concat(transferAccountsPeopleId),
    method: "delete"
  });
}
export function getPeopleDetail(params) {
  return request({
    url: "/hr/api/v1/transferAccountsDetails/selectPageByPeopleId",
    method: "get",
    params: params
  });
}