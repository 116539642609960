var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.dataLst && _vm.dataLst.length > 0 ? _c("div", {
    staticClass: "mt16"
  }, _vm._l(_vm.dataLst, function (item) {
    return _c("InfoItem", {
      key: item.id,
      attrs: {
        dataSource: item
      }
    });
  }), 1) : _c("a-empty", {
    staticClass: "mt16"
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };