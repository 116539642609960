var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("UploadSiteFile", {
    attrs: {
      limit: _vm.record.options.limit,
      multiple: _vm.record.options.multiple,
      disabled: _vm.record.options.disabled,
      "default-value": _vm.value
    },
    on: {
      change: _vm.onChange
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };