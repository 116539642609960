import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import { selectCompanyBySaleUserIdReq } from "../api";
import { getDate } from "@/utils/tools";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      term: "",
      visible: false,
      dataSource: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      columns: [{
        title: "所属用工单位",
        dataIndex: "name"
      }, {
        title: "绑定时间",
        dataIndex: "saleBindTime",
        customRender: function customRender(text) {
          var date = new Date(text);
          return getDate(date, true);
        }
      }]
    };
  },
  computed: {
    dataSourceFilter: function dataSourceFilter() {
      var _this = this;
      if (this.term) {
        return this.dataSource.filter(function (item) {
          return item.name.includes(_this.term);
        });
      } else {
        return this.dataSource;
      }
    }
  },
  watch: {
    dataSourceFilter: function dataSourceFilter(newVal) {
      this.pagination.total = newVal.length;
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onTableChange: function onTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
    },
    onItemClick: function onItemClick(userId) {
      var _this2 = this;
      selectCompanyBySaleUserIdReq(userId).then(function (res) {
        _this2.term = "";
        _this2.dataSource = res.data;
        _this2.pagination.total = res.data.length;
        _this2.visible = true;
      }).catch(function (err) {
        console.log("selectCompanyBySaleUserIdReq", err);
      });
    }
  }
};