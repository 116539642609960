import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.set.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import NewGroup from "./components/new-group";
import { findCompanyGroup as _findCompanyGroup } from "@/api/company/contract";
import { Project, ProjectApi } from "@/api/project/new-index";
import { isContainByString } from "@/utils/index";
import { saveIssuedApproFlow as _saveIssuedApproFlow, selectTreeByCompanyId as _selectTreeByCompanyId } from "./editor/api";
import { formatCompanyTree } from "@/utils/tools";
export default {
  components: {
    NewGroup: NewGroup
  },
  data: function data() {
    return {
      menuKey: [],
      allList: 0,
      // 添加图标
      addIcon: false,
      // 设置图标
      act: false,
      companyId: this.$store.state.hr.companyId,
      id: null,
      groupList: [],
      newGroupOptions: null,
      data: [],
      query: {
        groupId: "",
        typeName: ""
      },
      isContainByString: isContainByString,
      dataList: [],
      issueModalVisible: false,
      // 下发选择框数据
      contractData: [],
      // 已选的模板数据
      contractList: [],
      // 已选的公司数据
      companyList: [],
      replaceFields: {
        children: "approFlowVOList",
        key: "id",
        value: "id",
        title: "typeName"
      },
      issuedAppro: {},
      pagination: {
        pageNo: 1,
        pageSize: 20,
        pageSizeOptions: ["10", "20", "50", "100"],
        // 3,4 有
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      }
    };
  },
  created: function created() {
    this.findCompanyGroup();
  },
  mounted: function mounted() {
    this.getGroupList();
    this.getAllList();
    if (isContainByString("approvalAdmin")) {
      // 调用查询下发公司列表
      this.selectTreeByCompanyId();
    }
    var st = this.$route.query.operateFromShortcut;
    if (st === "chuangjianshenpifenzu") {
      this.newGroup();
    }
  },
  methods: {
    companySelectChange: function companySelectChange(companyKeys) {
      this.companyList = companyKeys.filter(function (item) {
        return item.includes("_company_");
      });
    },
    treeSearch2: function treeSearch2(e) {
      this.selectTreeByCompanyId(e);
    },
    treeSearch: function treeSearch(e) {
      this.findCompanyGroup(e);
    },
    findCompanyGroup: function findCompanyGroup(e) {
      var _this = this;
      _findCompanyGroup({
        name: e || null
      }).then(function (res) {
        _this.dataList = formatCompanyTree(res.data);
        _this.companyList = JSON.parse(JSON.stringify(_this.companyList));
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 启用禁用
    onChangeUse: function onChangeUse(val, text) {
      if (val) {
        this.useItem(text);
      } else {
        this.stopUseItem(text);
      }
    },
    // 删除全部审批
    deleteApproval: function deleteApproval() {
      var approvalAdmin = isContainByString("approvalAdmin") ? "0" : isContainByString("defaultTransferAccounts") ? "defaultTransferAccounts" : this.companyId;
      Project({
        url: ProjectApi.deleteAllApp + approvalAdmin,
        method: "DELETE"
      });
    },
    // 删除某个审批流程
    deleteUseItem: function deleteUseItem(item) {
      var _this2 = this;
      this.$confirm({
        title: "确定删除?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: function onOk() {
          Project({
            url: ProjectApi.baseSetDetail + item.id,
            method: "DELETE"
          }).then(function (data) {
            if (data.success) {
              _this2.$notification["success"]({
                message: "删除成功"
              });
              _this2.getGroupList();
              _this2.getAllList();
            }
          });
        }
      });
    },
    // 启用某个审批流程
    useItem: function useItem(item) {
      var _this3 = this;
      Project({
        url: ProjectApi.useListItem + item.id,
        method: "PUT"
      }).then(function (data) {
        if (data.success) {
          _this3.$notification["success"]({
            message: "修改成功"
          });
        }
      });
    },
    // 停用某个审批流程
    stopUseItem: function stopUseItem(item) {
      var _this4 = this;
      Project({
        url: ProjectApi.stopUseListItem + item.id,
        method: "PUT"
      }).then(function (data) {
        if (data.success) {
          _this4.$notification["success"]({
            message: "修改成功"
          });
        }
      });
    },
    /* 获取分组列表 */getGroupList: function getGroupList() {
      var _this5 = this;
      var approvalAdmin = isContainByString("approvalAdmin") ? "0" : isContainByString("defaultTransferAccounts") ? "defaultTransferAccounts" : this.companyId;
      Project({
        url: ProjectApi.groupList + approvalAdmin + "/hr"
      }).then(function (_ref) {
        var data = _ref.data;
        _this5.groupList = data;
      });
    },
    /* 获取审批列表查询的 */getApprovalList: function getApprovalList(item) {
      var _this6 = this;
      this.query.groupId = item.id;
      Project({
        url: ProjectApi.listQuery + item.id,
        params: {
          moduleType: "hr"
        }
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this6.data = [];
        _this6.data = data;
        _this6.pagination.total = data.length;
      });
    },
    // 获取总的审批列表
    getAllList: function getAllList() {
      var _this7 = this;
      this.menuKey = [];
      this.query = {};
      var approvalAdmin = isContainByString("approvalAdmin") ? "0" : isContainByString("defaultTransferAccounts") ? "defaultTransferAccounts" : this.companyId;
      Project({
        url: ProjectApi.allList + approvalAdmin + "/hr",
        params: {
          moduleType: "hr"
        }
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this7.data = [];
        _this7.data = data;
        _this7.allList = data.length;
        _this7.pagination.total = data.length;
      });
    },
    /* 搜索 */onSearch: function onSearch(e) {
      var _this8 = this;
      this.query.typeName = e;
      var approvalAdmin = isContainByString("approvalAdmin") ? "0" : isContainByString("defaultTransferAccounts") ? "defaultTransferAccounts" : this.companyId;
      Project({
        url: ProjectApi.allList + approvalAdmin + "/hr",
        params: _objectSpread(_objectSpread({}, this.query), {}, {
          moduleType: "hr"
        })
      }).then(function (_ref4) {
        var data = _ref4.data;
        _this8.data = [];
        _this8.data = data;
      });
    },
    /* 新建分组 */newGroup: function newGroup() {
      var approvalAdmin = isContainByString("approvalAdmin") ? "0" : isContainByString("defaultTransferAccounts") ? "defaultTransferAccounts" : this.companyId;
      this.newGroupOptions = {
        show: true,
        obj: {
          title: "新建审批分组",
          subTitle: "新建审批分组名称",
          companyId: approvalAdmin,
          moduleType: "hr"
        }
      };
    },
    /* 设置 */setting: function setting(item) {
      this.newGroupOptions = {
        show: true,
        obj: _objectSpread({
          title: "修改审批分组",
          subTitle: "修改审批分组名称"
        }, item)
      };
    },
    /* 创建新审批 */createApproval: function createApproval(id) {
      if (id) {
        if (isContainByString("defaultTransferAccounts")) {
          this.$router.push({
            path: "/newApproval?defaultTransferAccounts",
            query: {
              id: id
            }
          });
        } else {
          this.$router.push({
            path: "/".concat(isContainByString("approvalAdmin") ? "newApprovalAdmin" : "newApproval"),
            query: {
              id: id
            }
          });
        }
      } else {
        if (isContainByString("defaultTransferAccounts")) {
          this.$router.push({
            path: "/newApproval?defaultTransferAccounts"
          });
        } else {
          this.$router.push("/".concat(isContainByString("approvalAdmin") ? "newApprovalAdmin" : "newApproval"));
        }
      }
    },
    selectTreeByCompanyId: function selectTreeByCompanyId(e) {
      var _this9 = this;
      _selectTreeByCompanyId({
        name: e || null,
        companyId: isContainByString("approvalAdmin") ? "0" : isContainByString("defaultTransferAccounts") ? "defaultTransferAccounts" : this.companyId
      }).then(function (res) {
        var cJsonString = JSON.stringify(res.data);
        _this9.contractData = JSON.parse(cJsonString.replaceAll("groupName", "typeName"));
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 下发接口
    saveIssuedApproFlow: function saveIssuedApproFlow() {
      var _this10 = this;
      _saveIssuedApproFlow(this.issuedAppro).then(function (res) {
        _this10.issueModalVisible = false;
        _this10.$notification["success"]({
          message: "成功",
          description: "下发成功"
        });
        _this10.companyList = [];
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 方法
    confirmIssueModal: function confirmIssueModal() {
      // TODO 下发接口
      if (this.companyList.length === 0) {
        this.$notification["error"]({
          message: "错误",
          description: "请选择需要下发的公司"
        });
        return;
      }
      if (this.contractList.length === 0) {
        this.$notification["error"]({
          message: "错误",
          description: "请选择需要下发的审批模板"
        });
        return;
      }
      if (this.contractList.length > 0) {
        var companyArr = Array.from(new Set(this.companyList.map(function (item) {
          return item.split("_")[2];
        })));
        this.issuedAppro = {
          approFlowInforList: this.contractList,
          companyIds: Array.from(new Set(companyArr))
        };
      }
      this.saveIssuedApproFlow();
    },
    handleCancelIssueModal: function handleCancelIssueModal() {
      this.issueModalVisible = false;
    },
    showIssueModa: function showIssueModa() {
      this.issueModalVisible = true;
    },
    treeSelectChange: function treeSelectChange(value, label, extra) {
      var _this11 = this;
      this.contractList = [];
      this.contractData.forEach(function (item) {
        var categoryListVOList = item.approFlowVOList;
        if (categoryListVOList && categoryListVOList.length > 0) {
          item.approFlowVOList.forEach(function (itemItem) {
            // this.contractList.push
            if (value.indexOf(itemItem.id) !== -1) {
              var itemObj = {
                groupName: item.typeName,
                approFlowId: ""
              };
              itemObj.approFlowId = itemItem.id;
              _this11.contractList.push(itemObj);
            }
          });
        }
      });
    }
  }
};