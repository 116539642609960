import tables from "./component/table";
export default {
  components: {
    tables: tables
  },
  data: function data() {
    return {
      isCancel: "2"
    };
  },
  created: function created() {
    if (this.$store.state.recruitment.isCancel) {
      this.isCancel = this.$store.state.recruitment.isCancel;
      if (this.isCancel === "2") this.$store.commit("recruitment/isCancel", null);
    }
  },
  methods: {
    change: function change(v) {
      this.isCancel = v;
      this.$store.commit("recruitment/isCancel", v);
    }
  }
};