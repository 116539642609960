import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "head"
  }, [_c("div", {
    staticClass: "operation"
  }, [_c("a-month-picker", {
    attrs: {
      placeholder: "选择月份",
      "default-value": _vm.moment(_vm.now, "YYYY-MM"),
      allowClear: false
    },
    on: {
      change: _vm.monthChange
    }
  }), _c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px",
      "margin-left": "10px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "用工单位/负责人"
    },
    on: {
      search: _vm.inputChange
    }
  }), _c("a-popover", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      trigger: "click",
      placement: "bottom"
    },
    model: {
      value: _vm.popoverShow,
      callback: function callback($$v) {
        _vm.popoverShow = $$v;
      },
      expression: "popoverShow"
    }
  }, [_c("div", {
    staticStyle: {
      width: "910px",
      height: "220px"
    },
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      padding: "16px"
    }
  }, [_c("div", [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#636363"
    }
  }, [_vm._v("开票状态")]), _c("a-select", {
    staticStyle: {
      width: "180px",
      "margin-top": "6px"
    },
    attrs: {
      placeholder: "请选择",
      allowClear: true
    },
    on: {
      change: _vm.handleChange
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 未开票 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 全部开票 ")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 部分开票 ")])], 1)], 1), _c("div", {
    staticStyle: {
      "margin-left": "12px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#636363"
    }
  }, [_vm._v("回款状态")]), _c("a-select", {
    staticStyle: {
      width: "180px",
      "margin-top": "6px"
    },
    attrs: {
      placeholder: "请选择",
      allowClear: true
    },
    on: {
      change: _vm.handleChange2
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 未回款 ")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 全部回款 ")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v(" 部分回款 ")])], 1)], 1)]), _c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "16px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-left": "12px"
    }
  }, [_c("div", [_vm._v("应回款金额筛选")]), _c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "6px",
      "align-items": "center"
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      placeholder: "请选择"
    },
    model: {
      value: _vm.amountParams.totalAmountStart,
      callback: function callback($$v) {
        _vm.$set(_vm.amountParams, "totalAmountStart", $$v);
      },
      expression: "amountParams.totalAmountStart"
    }
  }), _c("div", [_vm._v("-")]), _c("a-input-number", {
    attrs: {
      min: _vm.amountParams.totalAmountStart ? _vm.amountParams.totalAmountStart : 0,
      placeholder: "请选择"
    },
    model: {
      value: _vm.amountParams.totalAmountEnd,
      callback: function callback($$v) {
        _vm.$set(_vm.amountParams, "totalAmountEnd", $$v);
      },
      expression: "amountParams.totalAmountEnd"
    }
  })], 1)]), _c("div", {
    staticStyle: {
      "margin-left": "12px"
    }
  }, [_c("div", [_vm._v("已到账金额筛选")]), _c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "6px",
      "align-items": "center"
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      placeholder: "请选择"
    },
    model: {
      value: _vm.amountParams.amountPaidStart,
      callback: function callback($$v) {
        _vm.$set(_vm.amountParams, "amountPaidStart", $$v);
      },
      expression: "amountParams.amountPaidStart"
    }
  }), _c("div", [_vm._v("-")]), _c("a-input-number", {
    attrs: {
      min: _vm.amountParams.amountPaidStart ? _vm.amountParams.amountPaidStart : 0,
      placeholder: "请选择"
    },
    model: {
      value: _vm.amountParams.amountPaidEnd,
      callback: function callback($$v) {
        _vm.$set(_vm.amountParams, "amountPaidEnd", $$v);
      },
      expression: "amountParams.amountPaidEnd"
    }
  })], 1)]), _c("div", {
    staticStyle: {
      "margin-left": "12px"
    }
  }, [_c("div", [_vm._v("未到账金额筛选")]), _c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "6px",
      "align-items": "center"
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      placeholder: "请选择"
    },
    model: {
      value: _vm.amountParams.unpaidAmountStart,
      callback: function callback($$v) {
        _vm.$set(_vm.amountParams, "unpaidAmountStart", $$v);
      },
      expression: "amountParams.unpaidAmountStart"
    }
  }), _c("div", [_vm._v("-")]), _c("a-input-number", {
    attrs: {
      min: _vm.amountParams.unpaidAmountStart ? _vm.amountParams.unpaidAmountStart : 0,
      placeholder: "请选择"
    },
    model: {
      value: _vm.amountParams.unpaidAmountEnd,
      callback: function callback($$v) {
        _vm.$set(_vm.amountParams, "unpaidAmountEnd", $$v);
      },
      expression: "amountParams.unpaidAmountEnd"
    }
  })], 1)])]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end",
      "margin-top": "24px"
    }
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        _vm.popoverShow = false;
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.popoverOk
    }
  }, [_vm._v("查询")])], 1)]), _c("a-button", [_c("a-icon", {
    attrs: {
      type: "filter"
    }
  })], 1)], 1)], 1), _c("div", [_c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.download();
      }
    }
  }, [_vm._v(" 导出 ")]), _c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.showAdd();
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("新增记录 ")], 1)], 1)]), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      rowKey: "id",
      scroll: {
        x: 1500
      },
      loading: _vm.loading,
      columns: _vm.columns,
      "data-source": _vm.dataList,
      pagination: _vm.pagination
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "companyName",
      fn: function fn(text, record) {
        return _c("a", {
          staticStyle: {
            position: "relative"
          }
        }, [record.isVoid === false ? _c("img", {
          staticStyle: {
            position: "absolute",
            top: "-16px",
            left: "-15px",
            width: "50px",
            height: "50px"
          },
          attrs: {
            src: _vm.abolitionImg
          }
        }) : _vm._e(), _c("p", {
          staticStyle: {
            "margin-left": "20px",
            "margin-bottom": "0"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])]);
      }
    }, {
      key: "unpaidAmount",
      fn: function fn(text) {
        return _c("a", {}, [text > 0 ? _c("span", {
          staticStyle: {
            color: "#ec5252"
          }
        }, [_vm._v(_vm._s(text))]) : _vm._e(), text <= 0 ? _c("span", {
          staticStyle: {
            color: "#55565d"
          }
        }, [_vm._v(_vm._s(text))]) : _vm._e()]);
      }
    }, {
      key: "uninvoicedAmount",
      fn: function fn(text) {
        return _c("a", {}, [text > 0 ? _c("span", {
          staticStyle: {
            color: "#ec5252"
          }
        }, [_vm._v(_vm._s(text))]) : _vm._e(), text <= 0 ? _c("span", {
          staticStyle: {
            color: "#55565d"
          }
        }, [_vm._v(_vm._s(text))]) : _vm._e()]);
      }
    }, {
      key: "payBackStatus",
      fn: function fn(text) {
        return _c("div", {}, [text === 1 ? _c("span", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [_c("div", {
          staticStyle: {
            "border-radius": "50%",
            height: "4px",
            width: "4px",
            background: "#a0a0a0"
          }
        }), _c("span", {
          staticStyle: {
            "margin-left": "5px"
          }
        }, [_vm._v("未回款")])]) : _vm._e(), text === 2 ? _c("span", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [_c("div", {
          staticStyle: {
            "border-radius": "50%",
            height: "4px",
            width: "4px",
            background: "#24cdaa"
          }
        }), _c("span", {
          staticStyle: {
            "margin-left": "5px"
          }
        }, [_vm._v("全部回款")])]) : _vm._e(), text === 3 ? _c("span", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [_c("div", {
          staticStyle: {
            "border-radius": "50%",
            height: "4px",
            width: "4px",
            background: "#ffa600"
          }
        }), _c("span", {
          staticStyle: {
            "margin-left": "5px"
          }
        }, [_vm._v("部分回款")])]) : _vm._e()]);
      }
    }, {
      key: "invoiceStatus",
      fn: function fn(text) {
        return _c("div", {}, [text === 1 ? _c("span", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [_c("div", {
          staticStyle: {
            "border-radius": "50%",
            height: "4px",
            width: "4px",
            background: "#a0a0a0"
          }
        }), _c("span", {
          staticStyle: {
            "margin-left": "5px"
          }
        }, [_vm._v("未开票")])]) : _vm._e(), text === 2 ? _c("span", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [_c("div", {
          staticStyle: {
            "border-radius": "50%",
            height: "4px",
            width: "4px",
            background: "#24cdaa"
          }
        }), _c("span", {
          staticStyle: {
            "margin-left": "5px"
          }
        }, [_vm._v("全部开票")])]) : _vm._e(), text === 3 ? _c("span", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [_c("div", {
          staticStyle: {
            "border-radius": "50%",
            height: "4px",
            width: "4px",
            background: "#ffa600"
          }
        }), _c("span", {
          staticStyle: {
            "margin-left": "5px"
          }
        }, [_vm._v("部分开票")])]) : _vm._e()]);
      }
    }, {
      key: "payBackDate",
      fn: function fn(text) {
        return _c("a", {}, [_vm._v(" " + _vm._s(text ? _vm.moment(text).format("YYYY-MM-DD") : "-") + " ")]);
      }
    }, {
      key: "monthly",
      fn: function fn(text, record) {
        return _c("div", {}, [text >= 10 ? _c("span", [_vm._v(_vm._s(record.yearly) + "-" + _vm._s(text))]) : _vm._e(), text < 10 ? _c("span", [_vm._v(_vm._s(record.yearly) + "-0" + _vm._s(text))]) : _vm._e()]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("a", {
          staticClass: "primary_color"
        }, [_c("span", {
          staticClass: "primary",
          on: {
            click: function click($event) {
              return _vm.goDetail(record);
            }
          }
        }, [_vm._v(" 详情 ")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("span", {
          staticClass: "primary",
          on: {
            click: function click($event) {
              return _vm.showAbolition(record);
            }
          }
        }, [_vm._v(" 删除 ")])], 1);
      }
    }])
  }), _c("a-modal", {
    attrs: {
      title: _vm.isBackShow ? "回款" : "回款记录",
      width: 700
    },
    on: {
      ok: _vm.handleReceivableOk,
      cancel: _vm.handleCancel
    },
    model: {
      value: _vm.receivableVisible,
      callback: function callback($$v) {
        _vm.receivableVisible = $$v;
      },
      expression: "receivableVisible"
    }
  }, [_vm.isBackShow ? _c("a-row", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm._v("回款信息")]), _c("hr", {
    staticClass: "title-line"
  })]) : _vm._e(), _vm.isBackShow ? _c("a-form-model", {
    ref: "ruleForm",
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      model: _vm.receivableForm,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "receiveAmount",
    attrs: {
      label: "本次实际到账金额",
      prop: "receiveAmount",
      rules: {
        required: true,
        message: "请输入"
      }
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      max: 999999,
      placeholder: "请输入"
    },
    model: {
      value: _vm.receivableForm.receiveAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.receivableForm, "receiveAmount", $$v);
      },
      expression: "receivableForm.receiveAmount"
    }
  })], 1), _c("a-form-model-item", {
    ref: "payBackDate",
    attrs: {
      label: "本次回款时间",
      prop: "payBackDate",
      rules: {
        required: true,
        message: "请选择"
      }
    }
  }, [_c("a-date-picker", {
    staticStyle: {
      width: "320px"
    },
    attrs: {
      "disabled-date": _vm.disabledDate,
      placeholder: "选择回款时间"
    },
    on: {
      change: _vm.onChange,
      ok: _vm.onOk
    },
    model: {
      value: _vm.receivableForm.payBackDate,
      callback: function callback($$v) {
        _vm.$set(_vm.receivableForm, "payBackDate", $$v);
      },
      expression: "receivableForm.payBackDate"
    }
  })], 1), _c("a-form-model-item", {
    ref: "file",
    attrs: {
      label: "银行回单",
      prop: "file"
    }
  }, [_c("a-upload", {
    staticClass: "upload-list-inline",
    attrs: {
      accept: ".doc,.docx,.xls,.xlsx,.pdf,.jpg, .jpeg, .png",
      "before-upload": _vm.beforeUpload,
      "list-type": "picture",
      fileList: _vm.fileList,
      "default-file-list": []
    },
    on: {
      change: _vm.upLoad
    }
  }, [_c("a-button", [_c("a-icon", {
    attrs: {
      type: "upload"
    }
  }), _vm._v(" 点击上传 ")], 1)], 1)], 1), _c("a-form-model-item", {
    ref: "remark",
    attrs: {
      label: "备注",
      prop: "remark"
    }
  }, [_c("a-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入"
    },
    model: {
      value: _vm.receivableForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.receivableForm, "remark", $$v);
      },
      expression: "receivableForm.remark"
    }
  })], 1)], 1) : _vm._e(), _vm.isBackShow ? _c("a-row", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm._v("回款到账记录")]), _c("hr", {
    staticClass: "title-line"
  })]) : _vm._e(), _c("a-table", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      rowKey: "id",
      loading: _vm.loading2,
      columns: _vm.receivableColumns,
      "data-source": _vm.receivableList,
      pagination: false,
      scroll: {
        y: 120
      }
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return _c("a", {
          staticClass: "primary_color"
        }, [_c("a-popconfirm", {
          attrs: {
            title: "确定删除吗?"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.deleteBack(record);
            }
          }
        }, [_vm._v(" 删除 ")])], 1);
      }
    }, {
      key: "payBackDate",
      fn: function fn(text) {
        return _c("a", {}, [_vm._v(" " + _vm._s(text ? _vm.moment(text).format("YYYY-MM-DD") : "-") + " ")]);
      }
    }])
  })], 1), _c("a-modal", {
    attrs: {
      title: _vm.isticketShow ? "开票" : "发票作废",
      width: 700
    },
    on: {
      ok: _vm.handleTicketOk,
      cancel: _vm.handleCancel2
    },
    model: {
      value: _vm.ticketVisible,
      callback: function callback($$v) {
        _vm.ticketVisible = $$v;
      },
      expression: "ticketVisible"
    }
  }, [_vm.isticketShow ? _c("a-row", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm._v("开票信息")]), _c("hr", {
    staticClass: "title-line"
  })]) : _vm._e(), _vm.isticketShow ? _c("a-form-model", {
    ref: "ruleForm2",
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      model: _vm.ticketForm,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "invoiceAmount",
    attrs: {
      label: "本次开票金额",
      prop: "invoiceAmount",
      rules: {
        required: true,
        message: "请输入"
      }
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      max: 999999,
      placeholder: "请输入"
    },
    model: {
      value: _vm.ticketForm.invoiceAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.ticketForm, "invoiceAmount", $$v);
      },
      expression: "ticketForm.invoiceAmount"
    }
  })], 1), _c("a-form-model-item", {
    ref: "invoiceDate",
    attrs: {
      label: "本次开票时间",
      prop: "invoiceDate",
      rules: {
        required: true,
        message: "请选择"
      }
    }
  }, [_c("a-date-picker", {
    staticStyle: {
      width: "320px"
    },
    attrs: {
      "disabled-date": _vm.disabledDate,
      placeholder: "选择开票时间"
    },
    on: {
      change: _vm.onChange,
      ok: _vm.onOk
    },
    model: {
      value: _vm.ticketForm.invoiceDate,
      callback: function callback($$v) {
        _vm.$set(_vm.ticketForm, "invoiceDate", $$v);
      },
      expression: "ticketForm.invoiceDate"
    }
  })], 1), _c("a-form-model-item", {
    ref: "file",
    attrs: {
      label: "附件",
      prop: "file"
    }
  }, [_c("a-upload", {
    staticClass: "upload-list-inline",
    attrs: {
      accept: ".doc,.docx,.xls,.xlsx,.pdf,.jpg, .jpeg, .png",
      "before-upload": _vm.beforeUpload2,
      "list-type": "picture",
      fileList: _vm.fileList2,
      "default-file-list": []
    },
    on: {
      change: _vm.upLoad2
    }
  }, [_c("a-button", [_c("a-icon", {
    attrs: {
      type: "upload"
    }
  }), _vm._v(" 点击上传 ")], 1)], 1)], 1), _c("a-form-model-item", {
    ref: "remark",
    attrs: {
      label: "备注",
      prop: "remark"
    }
  }, [_c("a-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入"
    },
    model: {
      value: _vm.ticketForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.ticketForm, "remark", $$v);
      },
      expression: "ticketForm.remark"
    }
  })], 1)], 1) : _vm._e(), _vm.isticketShow ? _c("a-row", {
    staticClass: "header-title"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm._v("开票记录")]), _c("hr", {
    staticClass: "title-line"
  })]) : _vm._e(), _c("a-table", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      rowKey: "id",
      loading: _vm.loading3,
      columns: _vm.ticketColumns,
      "data-source": _vm.ticketList,
      pagination: false,
      scroll: {
        y: 120
      }
    },
    scopedSlots: _vm._u([{
      key: "invoiceAmount",
      fn: function fn(text, record) {
        return _c("a", {
          staticStyle: {
            position: "relative"
          }
        }, [record.isVoid === false ? _c("img", {
          staticStyle: {
            position: "absolute",
            top: "-16px",
            left: "-15px",
            width: "50px",
            height: "50px"
          },
          attrs: {
            src: _vm.abolitionImg
          }
        }) : _vm._e(), _c("p", {
          staticStyle: {
            "margin-left": "20px",
            "margin-bottom": "0"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("a", {}, [record.isVoid === true ? _c("span", {
          staticClass: "primary_color"
        }, [_c("a-popconfirm", {
          attrs: {
            title: "确定作废吗?"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.wasteTicket(record);
            }
          }
        }, [_vm._v(" 作废 ")])], 1) : _vm._e(), record.isVoid === false ? _c("span", {
          staticStyle: {
            color: "#a6a8b4"
          }
        }, [_vm._v(" 作废 ")]) : _vm._e()]);
      }
    }, {
      key: "invoiceDate",
      fn: function fn(text) {
        return _c("a", {}, [_vm._v(" " + _vm._s(text ? _vm.moment(text).format("YYYY-MM-DD") : "-") + " ")]);
      }
    }])
  })], 1), _c("a-modal", {
    attrs: {
      title: "废除客户回款记录确认",
      width: 450
    },
    on: {
      ok: _vm.handleAbolition
    },
    model: {
      value: _vm.abolitionVisible,
      callback: function callback($$v) {
        _vm.abolitionVisible = $$v;
      },
      expression: "abolitionVisible"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "left"
    }
  }, [_c("div", {
    staticClass: "primary_color",
    staticStyle: {
      "margin-left": "15px",
      "font-size": "35px"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "question-circle"
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-left": "15px",
      position: "relative",
      top: "3px",
      "font-size": "16px",
      "font-weight": "500",
      color: "#000000ff"
    }
  }, [_c("span", [_vm._v("您确定要 "), _c("span", {
    staticStyle: {
      color: "#ec5252ff"
    }
  }, [_vm._v("删除")]), _vm._v(" 该条记录吗？删除后不可恢复，但可在本月客户明细单中重新点击生成回款记录。")])])])]), _c("a-modal", {
    attrs: {
      title: "废除客户回款记录确认",
      width: 450
    },
    on: {
      ok: _vm.handleRestore
    },
    model: {
      value: _vm.restoreVisible,
      callback: function callback($$v) {
        _vm.restoreVisible = $$v;
      },
      expression: "restoreVisible"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "left"
    }
  }, [_c("div", {
    staticClass: "primary_color",
    staticStyle: {
      "margin-left": "15px",
      "font-size": "35px"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "question-circle"
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-left": "15px",
      position: "relative",
      top: "3px",
      "font-size": "16px",
      "font-weight": "500",
      color: "#000000ff"
    }
  }, [_c("span", [_vm._v("您确定要 "), _c("span", {
    staticStyle: {
      color: "#5bdab0ff"
    }
  }, [_vm._v("恢复")]), _vm._v(" 该条记录？")])])])]), _c("a-modal", {
    attrs: {
      title: "新增记录",
      width: 500
    },
    on: {
      ok: _vm.handleAddOk
    },
    model: {
      value: _vm.addVisible,
      callback: function callback($$v) {
        _vm.addVisible = $$v;
      },
      expression: "addVisible"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm3",
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      model: _vm.addForm,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "editCompanyId",
    attrs: {
      rules: {
        required: true,
        message: "请选择"
      },
      label: "用工单位名称",
      prop: "editCompanyId"
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择",
      "option-filter-prop": "children",
      showSearch: "",
      "filter-option": _vm.filterOption
    },
    on: {
      change: _vm.changeId
    },
    model: {
      value: _vm.addForm.editCompanyId,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "editCompanyId", $$v);
      },
      expression: "addForm.editCompanyId"
    }
  }, [_vm._l(_vm.options, function (item) {
    return _c("a-select-option", {
      key: item.id,
      staticClass: "select-text",
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name) + " ")]);
  }), _c("a-icon", {
    staticClass: "select-icon",
    staticStyle: {
      display: "block",
      "margin-left": "10px"
    },
    attrs: {
      slot: "suffixIcon",
      type: "caret-down"
    },
    slot: "suffixIcon"
  })], 2)], 1), _c("a-form-model-item", {
    ref: "serviceFeeAmount",
    attrs: {
      rules: {
        required: true,
        message: "请输入"
      },
      label: "服务费金额",
      prop: "serviceFeeAmount"
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      max: 999999,
      placeholder: "请输入"
    },
    on: {
      change: _vm.calculate
    },
    model: {
      value: _vm.addForm.serviceFeeAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "serviceFeeAmount", $$v);
      },
      expression: "addForm.serviceFeeAmount"
    }
  })], 1), _c("a-form-model-item", {
    ref: "laborCost",
    attrs: {
      rules: {
        required: true,
        message: "请输入"
      },
      label: "人力成本",
      prop: "laborCost"
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      max: 999999,
      placeholder: "请输入"
    },
    on: {
      change: _vm.calculate
    },
    model: {
      value: _vm.addForm.laborCost,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "laborCost", $$v);
      },
      expression: "addForm.laborCost"
    }
  })], 1), _c("a-form-model-item", {
    ref: "fine",
    attrs: {
      rules: {
        required: false,
        message: "请输入"
      },
      label: "滞纳金",
      prop: "fine"
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 0,
      max: 999999,
      placeholder: "请输入"
    },
    on: {
      change: _vm.calculate
    },
    model: {
      value: _vm.addForm.fine,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "fine", $$v);
      },
      expression: "addForm.fine"
    }
  })], 1), _c("a-form-model-item", {
    ref: "totalAmount",
    attrs: {
      rules: {
        required: true,
        message: "请输入"
      },
      label: "应到账金额",
      prop: "totalAmount"
    }
  }, [_c("a-input-number", {
    attrs: {
      disabled: "",
      min: 0,
      placeholder: "请输入"
    },
    model: {
      value: _vm.addForm.totalAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "totalAmount", $$v);
      },
      expression: "addForm.totalAmount"
    }
  })], 1)], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };