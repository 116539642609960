import "core-js/modules/es.json.stringify.js";
export function setCondition(key, value) {
  localStorage.setItem("".concat(key, "_condition"), JSON.stringify(value));
}
export function getCondition(key) {
  var res = localStorage.getItem("".concat(key, "_condition"));
  if (res) {
    return JSON.parse(res);
  }
  return {};
}
export function setPageSize(key, value) {
  localStorage.setItem("".concat(key, "_pageSize"), value);
}
export function getPageSize(key) {
  var res = localStorage.getItem("".concat(key, "_pageSize"));
  return res || 1;
}
export function setPage(key, value) {
  localStorage.setItem("".concat(key, "_page"), value);
}
export function getPage(key) {
  var res = localStorage.getItem("".concat(key, "_page"));
  return res || 1;
}