import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import request from "@/utils/request";
var state = {
  companyEmployeeList: [],
  companyAdminList: [],
  companyRoleList: [],
  companyPositionBaseList: [],
  companyPositionList: [],
  companyDepartmentBaseList: [],
  companyDepartmentList: [],
  relationDataLoaded: false,
  companyDefSource: [],
  companyPosSource: []
};
var mutations = {
  updateEmployees: function updateEmployees(state, payload) {
    state.companyEmployeeList = payload;
  },
  updateAdmins: function updateAdmins(state, payload) {
    state.companyAdminList = payload;
  },
  updateRoles: function updateRoles(state, payload) {
    state.companyRoleList = payload;
  },
  updatePositions: function updatePositions(state, payload) {
    state.companyPositionBaseList = payload;
    state.companyPositionList = buildPosTree(payload, state.companyId);
    if (payload.length > 0) {
      var posTree = buildIterTree(payload, "0");
      // 岗位第一级不能选择
      posTree[0].children = posTree[0].children.map(function (z) {
        z.disabled = true;
        return z;
      });
      state.companyPosSource = posTree[0].children || [];
    } else {
      state.companyPosSource = [];
    }
  },
  updateDepartments: function updateDepartments(state, payload) {
    state.companyDepartmentBaseList = payload;
    state.companyDepartmentList = buildPosTree(payload, state.companyId);
    state.relationDataLoaded = true;
    if (payload.length > 0) {
      var topItem = payload.filter(function (item) {
        return item.isTop;
      })[0];
      var depTree = buildIterTree(payload, topItem.parentId);
      state.companyDefSource = depTree[0].children || [];
    } else {
      state.companyDefSource = [];
    }
  }
};
var actions = {
  loadEmployeesAct: function loadEmployeesAct(_ref, companyId) {
    var commit = _ref.commit;
    request({
      url: "/hr/api/v1/empInfos/listAll?companyId=".concat(companyId)
    }).then(function (res) {
      commit("updateEmployees", res.data);
    }).catch(function (err) {
      console.log("debug log --> ", err);
    });
  },
  loadAdminsAct: function loadAdminsAct(_ref2, companyId) {
    var commit = _ref2.commit;
    request({
      url: "/hr/api/v1/users/listUserHasRoleInCompany/".concat(companyId)
    }).then(function (res) {
      commit("updateAdmins", res.data);
    }).catch(function (err) {
      console.log("debug log --> ", err);
    });
  },
  loadRolesAct: function loadRolesAct(_ref3) {
    var commit = _ref3.commit;
    request({
      url: "/hr/api/v1/permissions/roleList?subjectType=company"
    }).then(function (res) {
      commit("updateRoles", res.data);
    }).catch(function (err) {
      console.log("debug log --> ", err);
    });
  },
  loadPositionsAct: function loadPositionsAct(_ref4, companyId) {
    var commit = _ref4.commit;
    request({
      url: "/hr/api/v1/positionInfos/listAll?companyId=".concat(companyId)
    }).then(function (res) {
      commit("updatePositions", res.data);
    }).catch(function (err) {
      console.log("debug log --> ", err);
    });
  },
  loadDepartmentsAct: function loadDepartmentsAct(_ref5, companyId) {
    var commit = _ref5.commit;
    request({
      url: "/hr/api/v1/depInfos/listAll?companyId=".concat(companyId)
    }).then(function (res) {
      commit("updateDepartments", res.data);
    }).catch(function (err) {
      console.log("debug log --> ", err);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
var buildPosTree = function buildPosTree(data, parentId) {
  var result = [];
  var temp = null;
  for (var i = 0; i < data.length; i++) {
    if (data[i].parentId === parentId) {
      var obj = {
        value: data[i]["id"],
        key: data[i]["id"],
        title: data[i]["name"],
        item: data[i]
      };
      temp = buildPosTree(data, data[i].id);
      if (temp.length > 0) {
        obj.children = temp;
      } else {
        obj.children = [];
      }
      result.push(obj);
    }
  }
  return result;
};
function buildIterTree(data, parentId) {
  var result = [];
  var temp = null;
  for (var i = 0; i < data.length; i++) {
    if (data[i].parentId === parentId) {
      var obj = {
        value: data[i]["id"],
        key: data[i]["id"],
        title: data[i]["name"],
        item: data[i]
      };
      temp = buildIterTree(data, data[i].id);
      if (temp.length > 0) {
        obj.children = temp;
      } else {
        obj.children = [];
      }
      result.push(obj);
    }
  }
  return result;
}