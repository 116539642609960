var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "p24 page-miH page-bgBack"
  }, [_c("a-layout", [_c("a-row", {
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 4
    }
  }, [_c("a-input-search", {
    attrs: {
      placeholder: "搜索方案",
      "allow-clear": ""
    },
    on: {
      search: _vm.handleSearch
    },
    model: {
      value: _vm.searchName,
      callback: function callback($$v) {
        _vm.searchName = $$v;
      },
      expression: "searchName"
    }
  })], 1), _c("a-col", [_vm.isContainByString("contractAdmin") ? _c("a-button", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showIssueModa
    }
  }, [_vm._v(" 下发参保方案 ")]) : _vm._e(), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.enterAddMethod
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加参保方案 ")], 1)], 1)], 1), _c("a-row", [_c("a-table", {
    attrs: {
      "data-source": _vm.methodList,
      pagination: _vm.pagination,
      columns: _vm.columns,
      "row-key": "id",
      scroll: {
        x: 800
      }
    },
    on: {
      change: _vm.handlePageChange
    },
    scopedSlots: _vm._u([{
      key: "buildOptionText",
      fn: function fn(props) {
        return [_c("span", [_vm._v(_vm._s(props.value) + "条/页")])];
      }
    }, {
      key: "progName",
      fn: function fn(text) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(text))])]), _c("span", [_vm._v(_vm._s(_vm.getProgName(text)))])], 2)];
      }
    }, {
      key: "onlySocAmount",
      fn: function fn(text) {
        return _c("span", {}, [_vm._v(_vm._s(text ? "仅设置参保金额" : "设置参保金额和比例"))]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("span", {
          staticClass: "opreate",
          on: {
            click: function click($event) {
              return _vm.enterDetail(record.id);
            }
          }
        }, [_vm._v("查看")])];
      }
    }])
  })], 1)], 1), _c("a-modal", {
    attrs: {
      visible: _vm.isDelete,
      title: "删除参保方案"
    },
    on: {
      ok: _vm.handleChange
    }
  }, [_c("div", {
    staticClass: "delete-box"
  }, [_vm._v(" 确定要删除 "), _c("span", [_vm._v("青岛方案")]), _vm._v("？ ")])]), _c("a-modal", {
    attrs: {
      visible: _vm.issueModalVisible,
      title: "下发参保方案",
      width: "538px",
      "destroy-on-close": true
    },
    on: {
      ok: _vm.confirmIssueModal,
      cancel: _vm.handleCancelIssueModal
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", [_vm._v("请选择参保方案")]), _c("a-select", {
    staticStyle: {
      width: "240px",
      "margin-top": "10px"
    },
    attrs: {
      mode: "multiple",
      "show-search": "",
      placeholder: "请选择参保方案",
      "option-filter-prop": "children"
    },
    model: {
      value: _vm.contractList,
      callback: function callback($$v) {
        _vm.contractList = $$v;
      },
      expression: "contractList"
    }
  }, _vm._l(_vm.contractData, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_c("a-tooltip", {
      attrs: {
        placement: "left",
        title: item.progName
      }
    }, [_vm._v(" " + _vm._s(item.progName) + " ")])], 1);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", [_vm._v("请选择下发用工单位")]), _c("a-tree-select", {
    staticStyle: {
      width: "240px",
      "margin-top": "10px",
      "max-height": "350px",
      overflow: "auto"
    },
    attrs: {
      value: _vm.companyList,
      treeData: _vm.dataList,
      filterTreeNode: _vm.filterTreeNode,
      treeCheckable: "",
      placeholder: "请选择用工单位"
    },
    on: {
      change: _vm.companySelectChange
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };