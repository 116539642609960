import fieldTemplate from "@/components/fieldTemplate/fieldTemplate";
export default {
  components: {
    fieldTemplate: fieldTemplate
  },
  watch: {},
  data: function data() {
    return {
      loading: false
    };
  },
  created: function created() {},
  methods: {}
};