import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper",
    staticStyle: {
      padding: "24px"
    }
  }, [_c("div", {
    staticClass: "xq-form-search"
  }, [_c("XqFormBuild", {
    ref: "searchForm",
    attrs: {
      mode: "search",
      dataSource: {},
      dynamicData: _vm.dynamicData,
      formFields: _vm.FormJson["fields"],
      formValidators: _vm.FormJson["validators"],
      formConfig: _vm.FormJson["config"]["search"]
    }
  }), _c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("a-space", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.filterRecord
    }
  }, [_vm._v("搜索")]), _c("a-button", {
    attrs: {
      type: "default"
    },
    on: {
      click: _vm.resetRecord
    }
  }, [_vm._v("重置")])], 1)], 1)], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150,
        y: 500
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      "row-selection": _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      } : null
    },
    on: {
      change: _vm.onTableChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("a", {
            attrs: {
              disabled: record.auditStatus !== 1
            },
            on: {
              click: function click($event) {
                return _vm.showUpdateModal(record);
              }
            }
          }, [_vm._v("处理")])]) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1), _c("a-modal", {
    attrs: {
      maskClosable: false,
      destroyOnClose: true,
      title: "审核",
      width: 500
    },
    model: {
      value: _vm.updateModalVisible,
      callback: function callback($$v) {
        _vm.updateModalVisible = $$v;
      },
      expression: "updateModalVisible"
    }
  }, [_c("XqFormBuild", {
    ref: "updateForm",
    attrs: {
      dataSource: _vm.tempRecord,
      dynamicData: _vm.dynamicData,
      formFields: _vm.FormJson["fields"],
      formValidators: _vm.FormJson["validators"],
      formConfig: _vm.FormJson["config"]["update"]
    },
    on: {
      change: function change($event) {
        return _vm.onFormChange(arguments[0], arguments[1], "updateForm");
      }
    }
  }), _c("XqModalFooter", {
    attrs: {
      slot: "footer"
    },
    on: {
      cancel: _vm.updateCancel,
      ok: _vm.updateOk
    },
    slot: "footer"
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };