import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import { formatDateAll } from "@/utils/index";
import { getNoticeById as _getNoticeById, topNotice, cancelTop, offlineNotice, onlineNotice } from "@/api/company/index";
var noticeColumns = [{
  title: "发布人",
  dataIndex: "publishUserName",
  width: 200,
  customRender: function customRender(text, record, index) {
    return text != null ? text : "--";
  }
}, {
  title: "查看可见范围",
  dataIndex: "publishEmpRangeIsAll",
  width: 200,
  scopedSlots: {
    customRender: "publishEmpRangeIsAll"
  }
}, {
  title: "可看员工性质范围",
  dataIndex: "publishEmpTypeIsAll",
  width: 200,
  scopedSlots: {
    customRender: "publishEmpTypeIsAll"
  }
}];
var memberColumn = [{
  title: "员工姓名",
  dataIndex: "name",
  align: "center"
}, {
  title: "部门",
  dataIndex: "depName",
  align: "center",
  customRender: function customRender(text, row, index) {
    return text != null ? text : "--";
  }
}, {
  title: "岗位",
  dataIndex: "positionName",
  align: "center",
  customRender: function customRender(text, row, index) {
    return text != null ? text : "--";
  }
}, {
  title: "手机号码",
  dataIndex: "phone",
  align: "center",
  width: 250,
  customRender: function customRender(text, row, index) {
    return text != null ? text : "--";
  }
}];
export default {
  filters: {
    formatDateAll: formatDateAll
  },
  data: function data() {
    return {
      spinning: false,
      noticeColumns: noticeColumns,
      memberColumn: memberColumn,
      bgImage: {
        backgroundImage: "url(" + require("@/assets/img/iphone.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
      },
      noticeId: "",
      currentNotice: {},
      noticelist: [],
      checkedList: [],
      empTableVisible: false,
      natureVisible: false,
      offlineVisible: false,
      onlineVisible: false,
      cancelTopVisible: false,
      isOfflineNow: true,
      isPublishNow: true,
      isHandle: true,
      setTopTip: "确定将该公告取消置顶吗？",
      type: [{
        value: 1,
        label: "全职",
        name: "countFullTime"
      }, {
        value: 2,
        label: "实习",
        name: "countInternship"
      }, {
        value: 3,
        label: "外包",
        name: "countOutSource"
      }, {
        value: 4,
        label: "劳务派遣",
        name: "countLabour"
      }, {
        value: 5,
        label: "顾问",
        name: "countAdviser"
      }, {
        value: 6,
        label: "退休返聘",
        name: "countRehireAfter"
      }, {
        value: 0,
        label: "无工作性质",
        name: "countNoType"
      }]
    };
  },
  created: function created() {
    this.noticeId = this.$route.query.id;
    this.getNoticeById(this.noticeId);
  },
  methods: {
    getNoticeById: function getNoticeById(noticeId) {
      var _this = this;
      this.spinning = true;
      _getNoticeById(noticeId).then(function (res) {
        _this.noticelist = [];
        _this.currentNotice = res.data;
        _this.noticelist.push(res.data);
        _this.isHandle = true;
        if (_this.currentNotice.publishEmpTypeIsAll === true) {
          _this.checkedList = [0, 1, 2, 3, 4, 5, 6];
        } else {
          _this.checkedList = _this.currentNotice.publishEmpType;
        }
      }).finally(function () {
        _this.spinning = false;
      });
    },
    offlineHandle: function offlineHandle() {
      this.offlineVisible = true;
      this.isOfflineNow = true;
    },
    confirmOffline: function confirmOffline() {
      var _this2 = this;
      var offlinedata = {
        // isTop: false,
        isOfflineNow: this.isOfflineNow,
        offlineDate: this.offlineDate
      };
      offlineNotice(this.currentNotice.id, offlinedata).then(function (res) {
        _this2.offlineVisible = false;
        _this2.$message.success("下线成功");
        _this2.getNoticeById(_this2.noticeId);
      });
    },
    onlineHandle: function onlineHandle() {
      this.onlineVisible = true;
      this.isPublishNow = true;
    },
    confirmOnline: function confirmOnline() {
      var _this3 = this;
      onlineNotice(this.currentNotice.id).then(function (res) {
        _this3.onlineVisible = false;
        _this3.$message.success("上线成功");
        _this3.getNoticeById(_this3.noticeId);
      });
    },
    concelTop: function concelTop() {
      this.setTopTip = "确定将该公告取消置顶吗？";
      this.cancelTopVisible = true;
    },
    setTopHandle: function setTopHandle() {
      this.setTopTip = "确定将该公告置顶吗？";
      this.cancelTopVisible = true;
    },
    confirmCancelTop: function confirmCancelTop() {
      var _this4 = this;
      if (this.currentNotice.isTop === true) {
        cancelTop(this.currentNotice.id).then(function (res) {
          _this4.cancelTopVisible = false;
          _this4.$message.success("取消置顶成功");
          _this4.getNoticeById(_this4.noticeId);
        });
      } else {
        topNotice(this.currentNotice.id).then(function (res) {
          _this4.cancelTopVisible = false;
          _this4.$message.success("置顶成功");
          _this4.getNoticeById(_this4.noticeId);
        });
      }
    },
    checkEmpTable: function checkEmpTable() {
      this.empTableVisible = !this.empTableVisible;
    },
    checkEmpTypelist: function checkEmpTypelist() {
      this.natureVisible = !this.natureVisible;
    },
    handleChange: function handleChange(value, dateString) {
      this.isOfflineNow = false;
      this.offlineDate = dateString;
    },
    goback: function goback() {
      this.$router.push({
        path: "/notice/enterprise"
      });
    }
  }
};