import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    class: !_vm.status ? "status-m" : "",
    attrs: {
      title: _vm.type ? "创建企业" : "更新税局信息",
      "confirm-loading": _vm.confirmLoading,
      destroyOnClose: true
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_vm.status ? _c("a-form", {
    attrs: {
      form: _vm.form,
      selfUpdate: true,
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 14
      }
    }
  }, [_vm.type ? _c("div", {
    staticClass: "add"
  }, [_c("div", {
    staticClass: "form-tit"
  }, [_vm._v("企业信息")]), _c("a-form-item", {
    attrs: {
      label: "企业名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "企业名称不能为空"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '企业名称不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "统一社会信用代码"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["taxNo", {
        rules: [{
          required: true,
          message: "统一社会信用代码不能为空"
        }]
      }],
      expression: "['taxNo', { rules: [{ required: true, message: '统一社会信用代码不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入"
    }
  })], 1), _c("div", {
    staticClass: "form-tit"
  }, [_vm._v("税局信息")]), _c("a-form-item", {
    attrs: {
      label: "报税所属区域"
    }
  }, [_c("a-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["areaCodeList", {
        rules: [{
          required: true,
          message: "报税所属区域不能为空"
        }]
      }],
      expression: "['areaCodeList', { rules: [{ required: true, message: '报税所属区域不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入"
    }
  }), _c("cityTreeSelect", {
    attrs: {
      visible: _vm.visible
    },
    on: {
      upDate: _vm.upDate
    }
  })], 1)], 1) : _c("div", [_c("a-form-item", {
    attrs: {
      label: "企业名称"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["id", {
        rules: [{
          required: true,
          message: "企业名称不能为空"
        }]
      }],
      expression: "['id', { rules: [{ required: true, message: '企业名称不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请选择",
      "show-search": "",
      "filter-option": _vm.filterOption
    }
  }, _vm._l(_vm.companiesArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "报税所属区域"
    }
  }, [_c("a-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["areaCodeList", {
        rules: [{
          required: true,
          message: "请选择报税所属区域"
        }]
      }],
      expression: "['areaCodeList', { rules: [{ required: true, message: '请选择报税所属区域' }] }]"
    }],
    attrs: {
      placeholder: "请输入"
    }
  }), _c("cityTreeSelect", {
    attrs: {
      visible: _vm.visible
    },
    on: {
      upDate: _vm.upDate
    }
  })], 1)], 1)]) : _c("div", {
    staticClass: "status"
  }, [_c("img", {
    staticClass: "status-img",
    attrs: {
      src: _vm.s
    }
  }), _c("div", {
    staticClass: "status-tit mb16"
  }, [_vm._v("添加企业成功")]), _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "status-num"
    }, [_vm._v(" " + _vm._s(index) + "获取到 "), _c("span", [_vm._v(_vm._s(item))]), _vm._v(" 个税务局信息 ")]);
  }), _c("a-button", {
    staticClass: "mt32",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onOK
    }
  }, [_vm._v(" 确认 ")])], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };