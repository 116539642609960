import "core-js/modules/es.function.name.js";
import people from "./people";
import position from "./position";
export default {
  components: {
    people: people,
    position: position
  },
  created: function created() {
    if (this.$route.query.name) this.componentName = this.$route.query.name;
    this.$store.commit("recruitment/isCancel", null);
  },
  data: function data() {
    return {
      componentName: "people",
      numArr: [{
        type: "people",
        name: "经纪人",
        num: 0
      }, {
        type: "position",
        name: "经纪人职位",
        num: 0
      }]
    };
  }
};