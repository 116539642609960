var render = function render() {
  var _this = this;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "revset page-miH page-bgBack"
  }, [_c("a-layout", [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goback
    }
  }, [_vm._v(" 增减员计划 ")]), _c("div", {
    staticClass: "flex-center-between pb12",
    staticStyle: {
      "margin-top": "20px"
    }
  }, _vm._l(_vm.topData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "wb19 border_hover h75 color_fa border_dashed top_warp",
      class: _vm.active === index ? "border_active" : "",
      staticStyle: {
        "padding-left": "24px"
      },
      on: {
        click: function click($event) {
          return _vm.onTopTabs(index);
        }
      }
    }, [_c("img", {
      staticStyle: {
        width: "32px",
        height: "32px"
      },
      attrs: {
        src: item.url,
        alt: item.title
      }
    }), _c("div", [_c("div", {
      staticClass: "top_title"
    }, [_vm._v(_vm._s(item.title))]), _c("CountTo", {
      staticClass: "top_number",
      attrs: {
        startVal: 0,
        endVal: item.num,
        duration: 2000
      }
    }), _c("div")], 1)]);
  }), 0), _c("div", {
    staticClass: "headbttw"
  }, [_c("a-row", {
    staticClass: "mt24 mb30"
  }, [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "搜索姓名/身份证号",
      allowClear: ""
    },
    on: {
      search: _vm.handleSearch
    },
    model: {
      value: _vm.searchName,
      callback: function callback($$v) {
        _vm.searchName = $$v;
      },
      expression: "searchName"
    }
  }), _c("a-popover", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      title: "",
      trigger: "click",
      placement: "bottom"
    },
    model: {
      value: _vm.searchVisible,
      callback: function callback($$v) {
        _vm.searchVisible = $$v;
      },
      expression: "searchVisible"
    }
  }, [_c("div", {
    staticStyle: {
      width: "500px"
    },
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("a-row", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      type: "flex",
      justify: "space-around"
    }
  }, [_c("a-col", {
    attrs: {
      span: 7
    }
  }, [_c("a-select", {
    staticClass: "mr12",
    staticStyle: {
      width: "145px"
    },
    attrs: {
      title: "",
      placeholder: "人员类型",
      "allow-clear": ""
    },
    on: {
      change: _vm.handlePersonType
    }
  }, _vm._l(_vm.personTypeList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 7
    }
  }, [_c("a-select", {
    staticClass: "mr12",
    staticStyle: {
      width: "136px"
    },
    attrs: {
      title: "",
      placeholder: "参保方案",
      "allow-clear": ""
    },
    on: {
      change: _vm.handleProgName
    }
  }, _vm._l(_vm.progNameList, function (item) {
    return _c("a-select-option", {
      key: item.socProgItemVOList[0].progId
    }, [_vm._v(_vm._s(item.progName))]);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 7
    }
  }, [_c("city", {
    staticStyle: {
      "min-width": "130px",
      width: "130px"
    },
    attrs: {
      placeholder: "参保城市",
      level: 3,
      "default-value": _vm.socCity,
      "allow-clear": true
    },
    on: {
      change: function change($event) {
        return _vm.handleCity($event);
      }
    }
  })], 1)], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      type: "flex",
      justify: "space-around"
    }
  }, [_c("a-col", {
    attrs: {
      span: 7
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      placeholder: "计划增减员时间"
    },
    on: {
      change: _vm.handleProgTime
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 7
    }
  }, [_c("a-select", {
    staticClass: "mr12",
    staticStyle: {
      width: "136px"
    },
    attrs: {
      title: "",
      placeholder: "增减员类型",
      "allow-clear": ""
    },
    on: {
      change: _vm.handleType
    }
  }, [_c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("社保")]), _c("a-select-option", {
    key: 3,
    attrs: {
      value: 3
    }
  }, [_vm._v("医保")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("公积金")])], 1)], 1), _c("a-col", {
    attrs: {
      span: 7
    }
  }, [_c("a-select", {
    staticClass: "mr12",
    staticStyle: {
      width: "136px"
    },
    attrs: {
      title: "",
      placeholder: "计划状态",
      "allow-clear": ""
    },
    on: {
      change: _vm.handlePlanType
    }
  }, [_c("a-select-option", {
    key: 0,
    attrs: {
      value: 0
    }
  }, [_vm._v("待反馈")]), _c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("已成功")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("已失败")]), _c("a-select-option", {
    key: 3,
    attrs: {
      value: 3
    }
  }, [_vm._v("已取消")])], 1)], 1)], 1), _c("a-row", {
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: _vm.handleCancel2
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleFormSearch
    }
  }, [_vm._v("查询")])], 1)], 1), _c("a-icon", {
    attrs: {
      type: "filter"
    }
  })], 1)], 1), _c("div", [_vm.site.socConfirm ? _c("a-button", {
    staticClass: "ml12",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.shebaopaidan
    }
  }, [_vm._v("社保派单")]) : _vm._e(), _vm.site.socConfirm ? _c("a-button", {
    staticClass: "ml12",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.ZongjianyuanSure
    }
  }, [_vm._v(" 增减员反馈 ")]) : _vm._e(), _c("a-button", {
    staticClass: "ml12",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.piLiangDaoRu
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("增员 ")], 1), _c("a-button", {
    staticClass: "ml12",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.toSetshebao
    }
  }, [_c("a-icon", {
    attrs: {
      type: "minus"
    }
  }), _vm._v("减员 ")], 1), _c("a-button", {
    staticClass: "ml12",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.btzengyuan
    }
  }, [_vm._v(" 批量增员 ")]), _c("a-button", {
    staticClass: "ml12",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.btjianyuan
    }
  }, [_vm._v(" 批量减员 ")])], 1)], 1), _c("a-row", [_c("a-table", {
    staticClass: "mt12",
    attrs: {
      columns: _vm.columnDetail,
      pagination: _vm.pagination,
      "data-source": _vm.dataDetail,
      scroll: {
        x: _vm.columnDetail.length * 200
      },
      size: "middle",
      colspan: "0",
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handlePageChange
    },
    scopedSlots: _vm._u([{
      key: "companySocAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "feedbackFlag",
      fn: function fn(text, record) {
        return [!record.feedbackFlag ? _c("span", {
          staticStyle: {
            color: "#ffa600"
          }
        }, [_vm._v("•")]) : _vm._e(), record.feedbackFlag === 1 ? _c("span", {
          staticStyle: {
            color: "#24cdaa"
          }
        }, [_vm._v("•")]) : _vm._e(), record.feedbackFlag === 2 ? _c("span", {
          staticStyle: {
            color: "#fa5051"
          }
        }, [_vm._v("•")]) : _vm._e(), record.feedbackFlag === 3 ? _c("span", {
          staticStyle: {
            color: "#aeb5bf"
          }
        }, [_vm._v("•")]) : _vm._e(), _c("span", [_vm._v(_vm._s(record.feedbackFlag ? record.feedbackFlag === 1 ? " 已成功" : record.feedbackFlag === 2 ? " 已失败" : " 已取消" : " 待反馈"))])];
      }
    }, {
      key: "type",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text === 1 ? "社保" : text === 2 ? "公积金" : "医保"))])];
      }
    }, {
      key: "personalSocAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "companyPfAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "personalPfAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "serviceCost",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.serviceCost !== null ? record.serviceCost : "-"))])];
      }
    }, {
      key: "schemeName",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "socCityName",
      fn: function fn(text, record) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text) + " ")]), _c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])], 2)];
      }
    }, {
      key: "businessType",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text != null ? text === "1" ? "增加/开户" : "调入/续保" : "-"))])];
      }
    }, {
      key: "socOrStopSocDate",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(_vm.formatDate2(record.socOrStopSocDate)))])];
      }
    }, {
      key: "postType",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(_vm.$store.state.enums.postTypeArr[text - 1]))])];
      }
    }, {
      key: "empSocState",
      fn: function fn(text) {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "flex-flow": "column"
          }
        }, [_c("div", [_vm._v(_vm._s(_vm.getPostType(text)))])])];
      }
    }, {
      key: "opration",
      fn: function fn(text, text1) {
        return [!text1.feedbackFlag ? _c("a-button", {
          attrs: {
            disable: text1.empSocState === 0 || text1.empSocState === 1 || text1.empSocState === 6,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.edito(text1);
            }
          }
        }, [_vm._v(" 编辑 ")]) : _vm._e(), !text1.feedbackFlag ? _c("a-button", {
          attrs: {
            disable: text1.empSocState === 0 || text1.empSocState === 1 || text1.empSocState === 6,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.goDetail(text1);
            }
          }
        }, [_vm._v(" 反馈 ")]) : _vm._e(), text1.feedbackFlag === 1 ? _c("a-button", {
          attrs: {
            disable: text1.empSocState === 0 || text1.empSocState === 1 || text1.empSocState === 6,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.lookPlan(text1);
            }
          }
        }, [_vm._v(" 查看计划 ")]) : _vm._e(), text1.feedbackFlag === 2 ? _c("a-button", {
          attrs: {
            disable: text1.empSocState === 0 || text1.empSocState === 1 || text1.empSocState === 6,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.lookReason(text1);
            }
          }
        }, [_vm._v(" 查看原因 ")]) : _vm._e(), text1.feedbackFlag === 2 ? _c("a-button", {
          attrs: {
            disable: text1.empSocState === 0 || text1.empSocState === 1 || text1.empSocState === 6,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.edito(text1);
            }
          }
        }, [_vm._v(" 重新提交 ")]) : _vm._e(), text1.feedbackFlag === 3 ? _c("a-button", {
          attrs: {
            disable: text1.empSocState === 0 || text1.empSocState === 1 || text1.empSocState === 6,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.edito(text1);
            }
          }
        }, [_vm._v(" 重新提交 ")]) : _vm._e()];
      }
    }])
  })], 1), _c("a-modal", {
    attrs: {
      title: "社保派单",
      width: "1000px"
    },
    on: {
      ok: _vm.onShebaopaidanOk
    },
    model: {
      value: _vm.shebaopaidanVisible,
      callback: function callback($$v) {
        _vm.shebaopaidanVisible = $$v;
      },
      expression: "shebaopaidanVisible"
    }
  }, [_c("a-alert", {
    attrs: {
      message: "将增减员计划分派给对应社保专员，被分配人员在首页工作台进行接单反馈操作，接单反馈完成后，派单的用工单位中增减员计划同步更新",
      banner: ""
    }
  }), _c("div", {
    staticClass: "fs16 fw500 mt16"
  }, [_vm._v("本次派单 " + _vm._s(_vm.selectedRows.length) + " 人")]), _c("a-form-item", {
    staticClass: "mt16",
    staticStyle: {
      "margin-left": "-20px"
    },
    attrs: {
      label: "接单人",
      labelCol: {
        span: 2
      },
      wrapperCol: {
        span: 16
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "236px"
    },
    attrs: {
      options: _vm.queryUserListLessToMeList,
      optionFilterProp: "label",
      filterOption: _vm.filterOption,
      placeholder: "请选择",
      mode: "multiple"
    },
    model: {
      value: _vm.shebaopaidanAdmin,
      callback: function callback($$v) {
        _vm.shebaopaidanAdmin = $$v;
      },
      expression: "shebaopaidanAdmin"
    }
  })], 1), _c("a-table", {
    attrs: {
      columns: _vm.columnDetailNoAction,
      pagination: false,
      "data-source": _vm.selectedRows,
      scroll: {
        x: _vm.columnDetailNoAction.length * 200
      },
      size: "middle",
      colspan: "0"
    },
    on: {
      change: _vm.handlePageChange
    },
    scopedSlots: _vm._u([{
      key: "companySocAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "feedbackFlag",
      fn: function fn(text, record) {
        return [!record.feedbackFlag ? _c("span", {
          staticStyle: {
            color: "#ffa600"
          }
        }, [_vm._v("•")]) : _vm._e(), record.feedbackFlag === 1 ? _c("span", {
          staticStyle: {
            color: "#24cdaa"
          }
        }, [_vm._v("•")]) : _vm._e(), record.feedbackFlag === 2 ? _c("span", {
          staticStyle: {
            color: "#fa5051"
          }
        }, [_vm._v("•")]) : _vm._e(), record.feedbackFlag === 3 ? _c("span", {
          staticStyle: {
            color: "#aeb5bf"
          }
        }, [_vm._v("•")]) : _vm._e(), _c("span", [_vm._v(_vm._s(record.feedbackFlag ? record.feedbackFlag === 1 ? " 已成功" : record.feedbackFlag === 2 ? " 已失败" : " 已取消" : " 待反馈"))])];
      }
    }, {
      key: "type",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text === 1 ? "社保" : text === 2 ? "公积金" : "医保"))])];
      }
    }, {
      key: "personalSocAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "companyPfAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "personalPfAmount",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "serviceCost",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.serviceCost !== null ? record.serviceCost : "-"))])];
      }
    }, {
      key: "schemeName",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "socCityName",
      fn: function fn(text, record) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text) + " ")]), _c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])], 2)];
      }
    }, {
      key: "businessType",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text != null ? text === "1" ? "增加/开户" : "调入/续保" : "-"))])];
      }
    }, {
      key: "socOrStopSocDate",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(_vm.formatDate2(record.socOrStopSocDate)))])];
      }
    }, {
      key: "postType",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(_vm.$store.state.enums.postTypeArr[text - 1]))])];
      }
    }, {
      key: "empSocState",
      fn: function fn(text) {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "flex-flow": "column"
          }
        }, [_c("div", [_vm._v(_vm._s(_vm.getPostType(text)))])])];
      }
    }, {
      key: "opration",
      fn: function fn(text, record) {
        return [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.removeSelected(record);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c("employee-select", {
    attrs: {
      show: _vm.isChooseStaff,
      zengyuan: _vm.zengyuanOrJianyuan
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.isChooseStaff = $event;
      },
      change: _vm.onEmployeeSelectChange,
      cancel: _vm.handleCancel
    }
  }), _c("a-modal", {
    attrs: {
      title: _vm.zengyuanOrJianyuan ? "增员" : "减员",
      visible: _vm.Dvisible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.DhandleCancel
    }
  }, [_vm._v(" 上一步 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.DhandleOk
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/icons/ask.png")
    }
  }), _vm.zengyuanOrJianyuan ? _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" 增员后社保将有较大金额变动，确定添加" + _vm._s(_vm.staffList.length) + "人？ ")]) : _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" 减员后社保将有较大金额变动，确定添加" + _vm._s(_vm.staffList.length) + "人进行减员？ ")])])], 2), _c("a-modal", {
    attrs: {
      title: "提示",
      visible: _vm.removeZengVisible
    },
    on: {
      cancel: _vm.handleCancel,
      ok: _vm.remvoeZenyuanOkHandel
    }
  }, [_c("div", {
    staticClass: "rowdalog2"
  }, [_c("img", {
    staticStyle: {
      width: "30px",
      height: "30px",
      "margin-right": "10px",
      "margin-left": "40px"
    },
    attrs: {
      src: require("@/assets/icons/ask.png")
    }
  }), _vm.tempEmpSocStatus === 2 || _vm.tempEmpSocStatus === 3 ? _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" 取消增员后将回到未参保状态，是否取消增员？ ")]) : _vm._e(), _vm.tempEmpSocStatus === 4 || _vm.tempEmpSocStatus === 5 ? _c("div", {
    staticStyle: {
      "margin-top": "4px",
      color: "#3c3d43",
      "font-size": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" 取消减员后将回到正常参保状态，是否取消减员？ ")]) : _vm._e()])]), _c("a-modal", {
    attrs: {
      title: _vm.zengyuanOrJianyuan ? "增员" : "减员",
      visible: _vm.Lvisible,
      width: "600px"
    },
    on: {
      cancel: _vm.LhandleOk
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.LhandleOk
    }
  }, [_vm._v(" 确定 ")])], 1), _vm.zengyuanOrJianyuan ? _c("div", {
    staticClass: "rowdialog"
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px",
      "margin-left": "10px"
    },
    attrs: {
      src: require("@/assets/img/overviewContract/icon4.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("增员计划添加成功")])]) : _vm._e(), _vm.zengyuanOrJianyuan ? _c("div", [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "13px",
      "margin-left": "52px",
      "margin-top": "5px"
    }
  }, [_vm._v("增员名单可在" + _vm._s(_vm.tempDate) + "参保管理中查看")])]) : _vm._e(), !_vm.zengyuanOrJianyuan ? _c("div", {
    staticClass: "rowdialog"
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px",
      "margin-left": "10px"
    },
    attrs: {
      src: require("@/assets/img/overviewContract/icon4.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("减员计划添加成功，查看计划请前往增减员计划内进行确认")])]) : _vm._e(), !_vm.zengyuanOrJianyuan ? _c("div", [_c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "16px",
      "margin-left": "0px",
      "margin-top": "40px",
      "margin-bottom": "10px"
    }
  }, [_vm._v("减员计划结果")]), _c("a-table", {
    attrs: {
      columns: _vm.columnDialogDetailJianYuan,
      pagination: _vm.pagination2,
      "data-source": _vm.dataDetail4,
      scroll: {
        x: 600
      },
      colspan: "0",
      "row-key": "id"
    },
    on: {
      change: _vm.handlePageChange2
    },
    scopedSlots: _vm._u([{
      key: "socDescription",
      fn: function fn(text, record) {
        return [_c("a-tooltip", [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(record.socDescription ? record.socDescription : "--") + " ")]), _c("span", [_vm._v(_vm._s(record.socDescription ? record.socDescription : "--"))])], 2)];
      }
    }, {
      key: "meDescription",
      fn: function fn(text, record) {
        return [_c("a-tooltip", [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(record.meDescription ? record.meDescription : "--") + " ")]), _c("span", [_vm._v(_vm._s(record.meDescription ? record.meDescription : "--"))])], 2)];
      }
    }, {
      key: "pfDescription",
      fn: function fn(text, record) {
        return [_c("a-tooltip", [_c("template", {
          slot: "title"
        }, [_vm._v(" " + _vm._s(record.pfDescription ? record.pfDescription : "--") + " ")]), _c("span", [_vm._v(_vm._s(record.pfDescription ? record.pfDescription : "--"))])], 2)];
      }
    }], null, false, 1408730358)
  })], 1) : _vm._e()], 2), _c("a-modal", {
    attrs: {
      title: _vm.editPlan ? "编辑增员信息" : "增员",
      visible: _vm.isCloseMethod,
      width: "690px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_vm.editPlan && (_vm.tempEmpSocStatus === 2 || _vm.tempEmpSocStatus === 3) && _vm.currentItem.feedbackFlag !== 3 ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: _vm.cancelZengY
    }
  }, [_vm._v(" " + _vm._s(_vm.getStatusTitle()) + " ")]) : _vm._e(), !_vm.editPlan ? _c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]) : _vm._e(), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.finishEdit
    }
  }, [_vm._v(" " + _vm._s(_vm.editPlan ? "确定" : "下一步") + " ")])], 1), _c("div", {
    staticStyle: {
      padding: "0 56px"
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "参保时间"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "default-value": _vm.getDefaultValue(),
      "disabled-date": _vm.disabledDate,
      allowClear: false
    },
    on: {
      change: _vm.onYearMonthChange
    }
  }), _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "10px",
      "margin-top": "-10px",
      "margin-left": "0px"
    }
  }, [_vm._v(" 若需对之前月份进行补收，请前往社保名单进行设置 ")])], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "参保方案"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "66%"
    },
    attrs: {
      value: _vm.defaultFangan === 0 ? "请选择参保方案" : _vm.defaultFangan,
      placeholder: "请选择参保方案"
    },
    on: {
      change: _vm.chooseProgName
    }
  }, _vm._l(_vm.editPlan ? _vm.getProgNameList() : _vm.progNameList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.progName))]);
  }), 1), _c("a-button", {
    staticClass: "ml12",
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.addFangan
    }
  }, [_vm._v(" 新增方案 ")])], 1), _c("div", [!_vm.editPlan && _vm.hasShebao(_vm.formData) ? _c("a-checkbox", {
    staticStyle: {
      "margin-bottom": "18px",
      "margin-left": "105px",
      "margin-top": "10px"
    },
    attrs: {
      checked: _vm.addShebao
    },
    on: {
      change: _vm.onTypeChange1
    }
  }, [_vm._v(" 社保增员 ")]) : _vm._e(), _vm.addShebao || _vm.editPlan ? _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 1 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault : item.cardinalityDefault,
        max: _vm.useSocImport ? 99999999 : item.cardinalityMax,
        min: _vm.useSocImport ? 0 : item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), !_vm.onlySocAmount ? _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "]")]) : _vm._e()], 1) : _vm._e()];
  })], 2) : _vm._e()], 1), _c("div", [!_vm.editPlan && _vm.hasYibao(_vm.formData) ? _c("a-checkbox", {
    staticStyle: {
      "margin-bottom": "18px",
      "margin-left": "105px",
      "margin-top": "10px"
    },
    attrs: {
      checked: _vm.addYibao
    },
    on: {
      change: _vm.onTypeChange2
    }
  }, [_vm._v(" 医保增员 ")]) : _vm._e(), _vm.addYibao || _vm.editPlan ? _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 3 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault : item.cardinalityDefault,
        max: _vm.useMeImport ? 99999999 : item.cardinalityMax,
        min: _vm.useMeImport ? 0 : item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), !_vm.onlySocAmount ? _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "]")]) : _vm._e()], 1) : _vm._e()];
  })], 2) : _vm._e()], 1), !_vm.payProvidentFund && _vm.progNameList.length > 0 ? _c("div", [!_vm.editPlan ? _c("a-checkbox", {
    staticStyle: {
      "margin-bottom": "18px",
      "margin-left": "105px",
      "margin-top": "10px"
    },
    attrs: {
      checked: _vm.addGjj
    },
    on: {
      change: _vm.onTypeChange3
    }
  }, [_vm._v(" 公积金增员 ")]) : _vm._e(), _vm.addGjj || _vm.editPlan ? _c("a-form", {
    staticClass: "baseInfo",
    attrs: {
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_vm._l(_vm.formData, function (item, index) {
    return [item.itemType === 2 ? _c("a-form-item", {
      key: index,
      staticClass: "baseInfo-item",
      attrs: {
        label: item.itemName + "基数"
      }
    }, [_c("a-input-number", {
      attrs: {
        value: _vm.onlySocAmount ? item.cardinalityDefaultAmount ? item.cardinalityDefaultAmount : item.cardinalityDefault : item.cardinalityDefault,
        max: _vm.usePfImport ? 99999999 : item.cardinalityMax,
        min: _vm.usePfImport ? 0 : item.cardinalityMin
      },
      on: {
        change: function change(val) {
          return _vm.changeDefault(val, index);
        }
      }
    }), !_vm.onlySocAmount ? _c("span", {
      staticStyle: {
        "margin-left": "0"
      }
    }, [_vm._v(" 基数范围[" + _vm._s(item.cardinalityMin) + "-" + _vm._s(item.cardinalityMax) + "]")]) : _vm._e()], 1) : _vm._e()];
  })], 2) : _vm._e()], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "服务费"
    }
  }, [_c("a-input-number", {
    attrs: {
      placeholder: "每月服务费",
      min: 0
    },
    model: {
      value: _vm.serviceCost,
      callback: function callback($$v) {
        _vm.serviceCost = $$v;
      },
      expression: "serviceCost"
    }
  })], 1)], 1)], 1)], 2), _c("a-modal", {
    attrs: {
      title: _vm.editPlan ? "编辑减员信息" : "减员",
      visible: _vm.isJCloseMethod,
      width: _vm.editPlan ? "600px" : "800px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_vm.editPlan && (_vm.tempEmpSocStatus === 4 || _vm.tempEmpSocStatus === 5) && _vm.currentItem.feedbackFlag !== 3 ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: _vm.cancelJianY
    }
  }, [_vm._v(" " + _vm._s(_vm.getStatusTitle()) + " ")]) : _vm._e(), !_vm.editPlan ? _c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]) : _vm._e(), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.finishEdit
    }
  }, [_vm._v(" " + _vm._s(_vm.editPlan ? "确定" : "下一步") + " ")])], 1), _c("div", [_c("a-form", {
    attrs: {
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 7
      }
    }
  }, [!_vm.editPlan && _vm.stopyear !== "" && _vm.stopmonth !== "" ? _c("a-checkbox", {
    staticStyle: {
      "margin-left": "245px",
      "margin-top": "15px",
      "margin-bottom": "15px"
    },
    attrs: {
      checked: _vm.jianShebao,
      disabled: _vm.jianShebao_2
    },
    on: {
      change: _vm.onTypejianChange1
    }
  }, [_vm._v(" 社保减员 ")]) : _vm._e(), _vm.stopyear !== "" && _vm.stopmonth !== "" ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "社保停保年月"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: false,
      value: _vm.moment(_vm.stopyear + "-" + _vm.stopmonth, _vm.monthFormat),
      "disabled-date": _vm.disabledDate,
      disabled: !_vm.jianShebao && !_vm.editPlan
    },
    on: {
      change: _vm.onStopYearMonthChange
    }
  })], 1) : _vm._e(), !_vm.editPlan && _vm.stopYByear !== "" && _vm.stopYBmonth !== "" ? _c("a-checkbox", {
    staticStyle: {
      "margin-left": "245px",
      "margin-top": "15px",
      "margin-bottom": "15px"
    },
    attrs: {
      checked: _vm.jianYibao,
      disabled: _vm.jianYibao_2
    },
    on: {
      change: _vm.onTypejianChange2
    }
  }, [_vm._v(" 医保减员 ")]) : _vm._e(), _vm.stopYByear !== "" && _vm.stopYBmonth !== "" ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "医保停保年月"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: false,
      value: _vm.moment(_vm.stopYByear + "-" + _vm.stopYBmonth, _vm.monthFormat),
      "disabled-date": _vm.disabledDate,
      disabled: !_vm.jianYibao && !_vm.editPlan
    },
    on: {
      change: _vm.onStopYBYearMonthChange
    }
  })], 1) : _vm._e(), !_vm.editPlan && _vm.stopGjyear !== "" && _vm.stopGjmonth !== "" ? _c("a-checkbox", {
    staticStyle: {
      "margin-left": "245px",
      "margin-top": "15px",
      "margin-bottom": "15px"
    },
    attrs: {
      checked: _vm.jianGjj,
      disabled: _vm.jianGjj_2
    },
    on: {
      change: _vm.onTypejianChange3
    }
  }, [_vm._v(" 公积金减员 ")]) : _vm._e(), _vm.stopGjyear !== "" && _vm.stopGjmonth !== "" ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "公积金封存年月"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      allowClear: false,
      value: _vm.moment(_vm.stopGjyear + "-" + _vm.stopGjmonth, _vm.monthFormat),
      "disabled-date": _vm.disabledDate,
      disabled: !_vm.jianGjj && !_vm.editPlan
    },
    on: {
      change: _vm.onStopGJYearMonthChange
    }
  })], 1) : _vm._e(), !_vm.editPlan && _vm.stopGjyear !== "" && _vm.stopGjmonth !== "" ? _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "10px",
      "margin-top": "-10px",
      "margin-left": "345px"
    }
  }, [_vm._v(" 如仅停缴公积金，请前往新建无参保公积金方案 ")]) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "服务费"
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      placeholder: "请输入服务费",
      min: 0,
      precision: 2,
      disabled: !_vm.editPlan && (_vm.jianShebao || _vm.jianYibao || _vm.jianGjj) === false
    },
    model: {
      value: _vm.JYserviceCost,
      callback: function callback($$v) {
        _vm.JYserviceCost = $$v;
      },
      expression: "JYserviceCost"
    }
  })], 1)], 1), !_vm.editPlan ? _c("div", {
    staticClass: "marginL2"
  }, [_vm._v("服务费不填写默认不更改，填0即服务费改为0")]) : _vm._e()], 1)], 2), _c("a-modal", {
    attrs: {
      title: "请登录",
      visible: _vm.accModel,
      width: "600px"
    },
    on: {
      cancel: function cancel() {
        _this.accModel = false;
      }
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: function click() {
        _this.accModel = false;
      }
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.spinning
    },
    on: {
      click: _vm.confirmAccHandel
    }
  }, [_vm._v(" " + _vm._s(_vm.spinning ? "数据同步中" : "继续同步") + " ")])], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "直通车账号:",
      required: ""
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入直通车账号"
    },
    model: {
      value: _vm.shebaoAcc,
      callback: function callback($$v) {
        _vm.shebaoAcc = $$v;
      },
      expression: "shebaoAcc"
    }
  })], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "直通车密码:",
      required: ""
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入直通车密码"
    },
    model: {
      value: _vm.shebaoPassW,
      callback: function callback($$v) {
        _vm.shebaoPassW = $$v;
      },
      expression: "shebaoPassW"
    }
  })], 1)], 2), _c("a-modal", {
    attrs: {
      spinning: _vm.spinning,
      title: _vm.confirmPlanZeng ? "增员计划反馈" : "减员计划反馈",
      visible: _vm.isConfirmPlan,
      width: "800px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.spinning || _vm.biaoji === "计划同步" && !_vm.shebaoLogin.isLogin
    },
    on: {
      click: _vm.confirmOk
    }
  }, [_vm._v(" " + _vm._s(_vm.spinning ? "数据校验中" : "确认") + " ")])], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "0px"
    }
  }, [_c("a-form", [_c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("人员列表")])]), _c("a-table", {
    attrs: {
      columns: _vm.columnDialogDetail,
      pagination: _vm.pagination2,
      "data-source": _vm.dataDetail3,
      scroll: {
        x: 700
      },
      colspan: "0",
      "row-key": "id"
    },
    on: {
      change: _vm.handlePageChange2
    },
    scopedSlots: _vm._u([{
      key: "socOrStopSocDate",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(_vm.formatDate3(record.socOrStopSocDate)))])];
      }
    }, {
      key: "serviceCost",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.serviceCost !== null ? record.serviceCost : "-"))])];
      }
    }, {
      key: "empSocState",
      fn: function fn(record) {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "flex-flow": "column"
          }
        }, [_c("div", [_vm._v(_vm._s(_vm.getPostType(record)))])])];
      }
    }, {
      key: "postType",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(_vm.$store.state.enums.postTypeArr[text - 1]))])];
      }
    }, {
      key: "schemeName",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "socCityName",
      fn: function fn(text, record) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])]), _c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])], 2)];
      }
    }])
  }), _c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("计划操作")])]), _vm.biaoji === "计划反馈" ? _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#252525",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.confirmPlanZeng ? "增员计划是否已执行？" : "减员计划是否已执行？") + " ")]) : _vm._e(), _vm.biaoji === "计划反馈" ? _c("a-form-item", {
    staticClass: "baseInfo-item2"
  }, [_c("a-radio-group", {
    on: {
      change: _vm.onTypeChangeValue
    },
    model: {
      value: _vm.typeValueTime,
      callback: function callback($$v) {
        _vm.typeValueTime = $$v;
      },
      expression: "typeValueTime"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 执行成功 ")]), _c("a-radio", {
    staticStyle: {
      "margin-left": "30px"
    },
    attrs: {
      value: 2
    }
  }, [_vm._v(" 执行失败 ")])], 1)], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "备注"
    }
  }, [_vm.biaoji === "计划反馈" ? _c("a-textarea", {
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      rows: 3,
      maxLength: 200,
      placeholder: "请输入备注（不得超过200字）"
    },
    model: {
      value: _vm.feedbackContent,
      callback: function callback($$v) {
        _vm.feedbackContent = $$v;
      },
      expression: "feedbackContent"
    }
  }) : _vm._e()], 1), _c("a-form-item", {
    attrs: {
      label: "附件"
    }
  }, [_c("UploadGeneralInline", {
    attrs: {
      accept: ".doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .jpg, .jpeg, .png",
      limit: 10
    },
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn(_ref) {
        var list = _ref.list;
        return _c("div", {}, _vm._l(list, function (item) {
          return _c("PreviewItem", {
            key: "" + item.uid,
            staticStyle: {
              width: "238px"
            },
            attrs: {
              url: item.url,
              size: 128
            }
          });
        }), 1);
      }
    }]),
    model: {
      value: _vm.annex,
      callback: function callback($$v) {
        _vm.annex = $$v;
      },
      expression: "annex"
    }
  })], 1), _vm.biaoji === "计划同步" ? _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#252525",
      "font-weight": "700"
    }
  }, [_vm._v("将增员结果同步至社保网站")]) : _vm._e(), _vm.biaoji === "计划同步" ? _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4"
    }
  }, [_vm._v(" *仅支持在青岛进行社保增员的员工信息同步。请在每月15日前进行社保信息同步。 ")]) : _vm._e(), _vm.biaoji === "计划同步" ? _c("a-divider", {
    staticStyle: {
      "margin-top": "15px",
      "margin-bottom": "-1px"
    }
  }) : _vm._e(), _vm.biaoji === "计划同步" ? _c("a-form-item", {
    attrs: {
      label: "选择城市",
      required: ""
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "35%"
    },
    attrs: {
      "default-value": _vm.defaultFangan2 === 0 ? "请选择社保所在城市" : _vm.defaultFangan2,
      placeholder: "请选择社保所在城市"
    },
    on: {
      change: _vm.getCityAcc
    }
  }, _vm._l(_vm.progNameList2, function (item) {
    return _c("a-select-option", {
      key: item.areaId
    }, [_vm._v(_vm._s(item.areaName))]);
  }), 1)], 1) : _vm._e(), _vm.biaoji === "计划同步" ? _c("div", {
    staticClass: "modal-box2",
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "onwork"
  }, [_c("div", {
    staticClass: "dataInfo2"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      width: "100%"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/home/shuju1.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#55565d",
      "font-size": "14px",
      "font-weight": "700"
    }
  }, [_vm._v("社保数据同步")])]), _vm.defaultFangan2 !== 0 ? _c("div", {
    staticStyle: {
      cursor: "pointer",
      color: "#6a66f6",
      "font-size": "14px"
    },
    on: {
      click: function click($event) {
        return _vm.getCityAcc(_vm.defaultFangan2);
      }
    }
  }, [_vm._v(" 刷新 ")]) : _vm._e()])]), _c("div", {
    staticClass: "dataInfo2",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px",
      "margin-right": "12px"
    }
  }, [_vm._v("账号名称")]), _c("a-tooltip", {
    style: [{
      color: "#55565D"
    }, {
      fontSize: "12px"
    }, {
      width: "200px"
    }, {
      overflow: "hidden"
    }, {
      whiteSpace: "nowrap"
    }, {
      textOverflow: "ellipsis"
    }]
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.shebaoLogin.accountName))])]), _vm._v(" " + _vm._s(_vm.shebaoLogin.accountName) + " ")], 2)], 1), _c("div", {
    staticClass: "dataInfo2",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "12px"
    }
  }, [_vm._v("登录状态")]), _c("div", {
    style: [{
      color: _vm.shebaoLogin.isLogin ? "#6A66F6" : "#A6A8B4"
    }, {
      fontSize: "12px"
    }, {
      marginLeft: "12px"
    }, {
      cursor: !_vm.shebaoLogin.isLogin ? "pointer" : ""
    }, {
      textDecoration: _vm.shebaoLogin.isLogin ? "" : "underline"
    }],
    on: {
      click: function click($event) {
        return _vm.Login(1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.shebaoLogin.isLogin ? "已登录" : "点击去登录") + " ")])])])]) : _vm._e(), _vm.biaoji === "计划同步" ? _c("div", {
    staticStyle: {
      "background-color": "#faf9f9",
      "border-radius": "5px",
      "padding-left": "15px",
      "padding-top": "15px",
      "padding-right": "15px",
      "padding-bottom": "15px"
    }
  }, [_c("div", {
    attrs: {
      sytle: "font-size:14px;color:#55565D;font-weight:bold;"
    }
  }, [_vm._v("温馨提示")]), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4"
    }
  }, [_vm._v("1、同步数据需开启")]), _c("div", {
    staticClass: "edit",
    staticStyle: {
      "font-size": "12px",
      "margin-left": "5px",
      cursor: "pointer"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("大数据助手")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4"
    }
  }, [_vm._v("插件，并登录相应网站才可使用")])]), _c("div", {
    staticClass: "rowdalog2"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#a6a8b4"
    }
  }, [_vm._v("2、导入人员名单需在花名册中，否则需先至花名册中新建。")]), _c("div", {
    staticClass: "edit",
    staticStyle: {
      "font-size": "12px",
      "margin-left": "5px",
      cursor: "pointer"
    },
    on: {
      click: _vm.huamingce
    }
  }, [_vm._v("立即前往")])])]) : _vm._e()], 1)], 1)], 2), _c("a-modal", {
    attrs: {
      title: _vm.typeValueTime === 1 ? "查看计划" : "查看原因",
      visible: _vm.confirmPlanZengVisible,
      width: "800px"
    },
    on: {
      cancel: _vm.handleCancel,
      ok: _vm.confirmPlanOkHandel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmPlanOkHandel
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", {
    staticClass: "rowdialog"
  }, [_vm.typeValueTime === 1 ? _c("img", {
    staticStyle: {
      width: "28px",
      height: "28px"
    },
    attrs: {
      src: require("@/assets/img/overviewContract/icon4.png")
    }
  }) : _vm._e(), _vm.typeValueTime === 2 ? _c("img", {
    staticStyle: {
      width: "28px",
      height: "28px"
    },
    attrs: {
      src: require("@/assets/icons/失败.png")
    }
  }) : _vm._e(), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentItem ? _vm.getPostType(_vm.currentItem.empSocState) : "") + "计划执行" + _vm._s(_vm.typeValueTime === 1 ? "成功" : "失败") + " ")])]), _c("a-table", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      columns: _vm.columnDialogDetail,
      pagination: _vm.pagination2,
      "data-source": _vm.dataDetail3,
      scroll: {
        x: 700
      },
      colspan: "0",
      "row-key": "id"
    },
    on: {
      change: _vm.handlePageChange2
    },
    scopedSlots: _vm._u([{
      key: "socOrStopSocDate",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(_vm.formatDate3(record.socOrStopSocDate)))])];
      }
    }, {
      key: "serviceCost",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.serviceCost !== null ? record.serviceCost : "-"))])];
      }
    }, {
      key: "empSocState",
      fn: function fn(text) {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "flex-flow": "column"
          }
        }, [_c("div", [_vm._v(_vm._s(_vm.getPostType(text)))])])];
      }
    }, {
      key: "postType",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(_vm.$store.state.enums.postTypeArr[text - 1]))])];
      }
    }, {
      key: "schemeName",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])];
      }
    }, {
      key: "socCityName",
      fn: function fn(text, record) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])]), _c("span", [_vm._v(_vm._s(record.empSocState === 4 || record.empSocState === 5 ? "-" : text))])], 2)];
      }
    }])
  }), _vm.fankuiDetail && _vm.fankuiDetail.length > 0 ? _c("div", {
    staticClass: "mt16 pt12",
    staticStyle: {
      "border-top": "1px solid #e8e8e8"
    }
  }, [_c("div", {
    staticClass: "fs16 fw500"
  }, [_vm._v("反馈详情")]), _c("div", {
    staticStyle: {
      "max-height": "500px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.fankuiDetail, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "pb12",
      staticStyle: {
        "border-bottom": "1px solid #e8e8e8"
      }
    }, [_c("div", {
      staticClass: "flex-center mt8",
      staticStyle: {
        color: "#919191"
      }
    }, [_c("div", {
      staticStyle: {
        width: "100px"
      }
    }, [_vm._v("反馈人")]), _c("div", [_vm._v(_vm._s(item.userName))])]), _c("div", {
      staticClass: "flex-center mt8",
      staticStyle: {
        color: "#919191"
      }
    }, [_c("div", {
      staticStyle: {
        width: "100px"
      }
    }, [_vm._v("反馈状态")]), _c("div", [_vm._v(_vm._s(item.feedbackState))])]), _c("div", {
      staticClass: "flex-center mt8",
      staticStyle: {
        color: "#919191"
      }
    }, [_c("div", {
      staticStyle: {
        width: "100px"
      }
    }, [_vm._v("反馈时间")]), _c("div", [_vm._v(_vm._s(_vm.getDate(new Date(item.gmtCreate), true)))])]), _c("div", {
      staticClass: "flex-center mt8",
      staticStyle: {
        color: "#919191"
      }
    }, [_c("div", {
      staticStyle: {
        width: "100px"
      }
    }, [_vm._v("反馈内容")]), _c("div", [_vm._v(_vm._s(item.feedbackContent))])]), _c("div", {
      staticClass: "flex mt8",
      staticStyle: {
        color: "#919191"
      }
    }, [_c("div", {
      staticStyle: {
        width: "100px"
      }
    }, [_vm._v("附件")]), _c("div", [_c("UploadGeneralInline", {
      attrs: {
        limit: 10,
        accept: ".doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .jpg, .jpeg, .png",
        disabled: true
      },
      scopedSlots: _vm._u([{
        key: "list",
        fn: function fn(_ref2) {
          var list = _ref2.list;
          return _c("div", {}, _vm._l(list, function (li) {
            return _c("PreviewItem", {
              key: "" + li.uid,
              staticStyle: {
                width: "238px"
              },
              attrs: {
                url: li.url,
                size: 128
              }
            });
          }), 1);
        }
      }], null, true),
      model: {
        value: item.annex,
        callback: function callback($$v) {
          _vm.$set(item, "annex", $$v);
        },
        expression: "item.annex"
      }
    })], 1)])]);
  }), 0)]) : _vm._e()], 2), _c("a-modal", {
    attrs: {
      width: 800,
      title: "补充员工信息",
      visible: _vm.buchongInfo
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("div", [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消同步 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.getSynState() || _vm.spinning
    },
    on: {
      click: _vm.TongBuStart
    }
  }, [_vm._v(" " + _vm._s(_vm.spinning ? "数据同步中" : "开始同步") + " ")])], 1)])]), _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "employee-selector-wrapper2"
  }, [_c("div", [_c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("人员列表(" + _vm._s(_vm.treeData.length) + ")")])]), _c("div", {
    staticClass: "employee-selector"
  }, _vm._l(_vm.treeData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      style: [{
        width: "100%"
      }, {
        height: "50px"
      }, {
        display: "flex"
      }, {
        alignItems: "center"
      }, {
        backgroundColor: item.selected && item.selected === true ? "#6A66F622" : "#fff"
      }, {
        cursor: "pointer"
      }],
      on: {
        click: function click($event) {
          return _vm.chose(item);
        }
      }
    }, [_c("img", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "margin-right": "10px",
        "margin-left": "10px"
      },
      attrs: {
        src: require("@/assets/icons/person1.png")
      }
    }), _c("div", {
      staticStyle: {
        display: "flex",
        "flex-flow": "column"
      }
    }, [_c("span", {
      staticStyle: {
        "font-weight": "700"
      }
    }, [_vm._v(_vm._s(item.xm))]), _c("div", {
      staticStyle: {
        display: "flex",
        "flex-flow": "row",
        "align-items": "center"
      }
    }, [!item.skip && (item.errorInfo || item.errorInfo2) ? _c("img", {
      staticStyle: {
        width: "13px",
        height: "13px",
        "margin-right": "3px"
      },
      attrs: {
        src: require("@/assets/icons/失败.png")
      }
    }) : _vm._e(), item.skip ? _c("img", {
      staticStyle: {
        width: "13px",
        height: "13px",
        "margin-right": "3px"
      },
      attrs: {
        src: require("@/assets/icons/重复.png")
      }
    }) : _vm._e(), _c("a-tooltip", {
      attrs: {
        placement: "topLeft"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.errorInfo ? item.errorInfo : item.errorInfo2 ? item.errorInfo2 : ""))])]), _c("span", {
      staticStyle: {
        "margin-right": "10px",
        "font-size": "12px"
      }
    }, [_vm._v(" " + _vm._s(item.skip ? "员工已跳过同步" : item.errorInfo ? _vm.ellipsis(item.errorInfo) : item.errorInfo2 ? _vm.ellipsis(item.errorInfo2) : "") + " ")])], 2)], 1)])])]);
  }), 0)]), _c("div", {
    staticClass: "verticalBar"
  }), _vm.BCItem !== null ? _c("div", {
    staticClass: "employee-selector-block2"
  }, [_c("div", {
    style: [{
      width: "100%"
    }, {
      marginBottom: "10px"
    }, {
      height: "70px"
    }, {
      display: "flex"
    }, {
      alignItems: "center"
    }, {
      backgroundColor: "#6A66F622"
    }, {
      cursor: "pointer"
    }]
  }, [_c("img", {
    staticStyle: {
      width: "40px",
      height: "40px",
      "margin-right": "10px",
      "margin-left": "10px"
    },
    attrs: {
      src: require("@/assets/icons/person1.png")
    }
  }), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "column",
      width: "100%"
    }
  }, [_c("span", {
    staticStyle: {
      "font-weight": "700"
    }
  }, [_vm._v(_vm._s(_vm.BCItem ? _vm.BCItem.xm : ""))]), _c("span", {
    staticStyle: {
      "margin-right": "10px"
    }
  }, [_vm._v(" 身份证号：" + _vm._s(_vm.BCItem && _vm.BCItem.sfzhm ? _vm.BCItem.sfzhm : "") + " ")])]), _c("a-button", {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.skipItem
    }
  }, [_vm._v(" " + _vm._s(_vm.BCItem.skip ? "取消跳过" : "跳过此人") + " ")])], 1), _c("div", {
    staticStyle: {
      height: "550px",
      "overflow-y": "auto"
    },
    attrs: {
      id: "scrId"
    }
  }, [_c("div", {
    staticClass: "headerTitle",
    staticStyle: {
      "margin-top": "5px"
    }
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("社保信息")])]), _c("a-row", {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("月工资")])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("本单位开始缴费年月")])])], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      min: 0
    },
    model: {
      value: _vm.BCItem.extraInfo.wages,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem.extraInfo, "wages", $$v);
      },
      expression: "BCItem.extraInfo.wages"
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "200px"
    },
    model: {
      value: _vm.BCItem.extraInfo.currentInsuredStartDate,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem.extraInfo, "currentInsuredStartDate", $$v);
      },
      expression: "BCItem.extraInfo.currentInsuredStartDate"
    }
  })], 1)], 1), _c("div", {
    staticClass: "headerTitle",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("就业登记信息")])]), _c("a-row", {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("是否参与就业登记")])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("就业单位")])])], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.handleJiuYeChange
    },
    model: {
      value: _vm.BCItem.extraInfo.employmentPermit,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem.extraInfo, "employmentPermit", $$v);
      },
      expression: "BCItem.extraInfo.employmentPermit"
    }
  }, _vm._l(_vm.jiuyedengjiList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      disabled: _vm.BCItem.extraInfo.employmentPermit === 0,
      placeholder: "请选择"
    },
    model: {
      value: _vm.BCItem.extraInfo.employmentCompanyNum,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem.extraInfo, "employmentCompanyNum", $$v);
      },
      expression: "BCItem.extraInfo.employmentCompanyNum"
    }
  }, _vm._l(_vm.danweiList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1)], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("工种")])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("劳动合同类型")])])], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      "show-search": "",
      "show-arrow": false,
      placeholder: "请输入进行搜索",
      disabled: _vm.BCItem.extraInfo.employmentPermit === 0,
      "option-filter-prop": "children",
      "default-active-first-option": false,
      "filter-option": false
    },
    on: {
      search: _vm.searchData,
      change: _vm.handlePersonnel
    },
    scopedSlots: _vm._u([{
      key: "dropdownRender",
      fn: function fn(menu) {
        return _c("div", {}, [_c("v-nodes", {
          attrs: {
            vnodes: menu
          }
        }), _c("a-divider", {
          staticStyle: {
            margin: "4px 0"
          }
        }), _c("div", {
          staticStyle: {
            width: "100%",
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            cursor: "pointer",
            "margin-bottom": "6px"
          },
          on: {
            mousedown: function mousedown(e) {
              return e.preventDefault();
            },
            click: _vm.reSearch
          }
        }, [_vm._v(" 加载更多 ")])], 1);
      }
    }], null, false, 3968271003),
    model: {
      value: _vm.BCItem.extraInfo.jobsName,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem.extraInfo, "jobsName", $$v);
      },
      expression: "BCItem.extraInfo.jobsName"
    }
  }, _vm._l(_vm.searchPersonnelList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择",
      disabled: _vm.BCItem.extraInfo.employmentPermit === 0
    },
    model: {
      value: _vm.BCItem.extraInfo.laborContractsType,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem.extraInfo, "laborContractsType", $$v);
      },
      expression: "BCItem.extraInfo.laborContractsType"
    }
  }, _vm._l(_vm.hetongleixingList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1)], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("合同签订日期")])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("合同开始日期")])])], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("a-date-picker", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      disabled: _vm.BCItem.extraInfo.employmentPermit === 0
    },
    model: {
      value: _vm.BCItem.extraInfo.contractsSignedDate,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem.extraInfo, "contractsSignedDate", $$v);
      },
      expression: "BCItem.extraInfo.contractsSignedDate"
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-date-picker", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      disabled: _vm.BCItem.extraInfo.employmentPermit === 0
    },
    model: {
      value: _vm.BCItem.extraInfo.contractsStartDate,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem.extraInfo, "contractsStartDate", $$v);
      },
      expression: "BCItem.extraInfo.contractsStartDate"
    }
  })], 1)], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("合同终止日期")])]), _c("a-col", {
    attrs: {
      span: 10
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("灵活性就业人员是否参加医保")])])], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("a-date-picker", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      disabled: _vm.BCItem.extraInfo.employmentPermit === 0
    },
    model: {
      value: _vm.BCItem.extraInfo.contractsEndDate,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem.extraInfo, "contractsEndDate", $$v);
      },
      expression: "BCItem.extraInfo.contractsEndDate"
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择",
      disabled: _vm.BCItem.extraInfo.employmentPermit === 0
    },
    model: {
      value: _vm.BCItem.extraInfo.whetherMe,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem.extraInfo, "whetherMe", $$v);
      },
      expression: "BCItem.extraInfo.whetherMe"
    }
  }, _vm._l(_vm.shifouyibaoList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1)], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("就业人员类别")])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  })])], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择",
      disabled: _vm.BCItem.extraInfo.employmentPermit === 0
    },
    model: {
      value: _vm.BCItem.extraInfo.employmentType,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem.extraInfo, "employmentType", $$v);
      },
      expression: "BCItem.extraInfo.employmentType"
    }
  }, _vm._l(_vm.jiuyeleibieList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 8
    }
  })], 1), _c("div", {
    staticClass: "headerTitle",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticClass: "title-info"
  }, [_vm._v("个人基本信息")])]), _c("a-row", {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("身份证件类型")])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("民族")])])], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.BCItem.yxzjlx,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem, "yxzjlx", $$v);
      },
      expression: "BCItem.yxzjlx"
    }
  }, _vm._l(_vm.zhengjianleixingList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.BCItem.mz,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem, "mz", $$v);
      },
      expression: "BCItem.mz"
    }
  }, _vm._l(_vm.minzuList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1)], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("省(户籍行政区)")])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("市(户籍行政区)")])])], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.handleShengChange
    },
    model: {
      value: _vm.BCItem.province,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem, "province", $$v);
      },
      expression: "BCItem.province"
    }
  }, _vm._l(_vm.shengList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.handleShiChange
    },
    model: {
      value: _vm.BCItem.city,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem, "city", $$v);
      },
      expression: "BCItem.city"
    }
  }, _vm._l(_vm.shiList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1)], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("户口所在地行政区划")])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("户口性质")])])], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.BCItem.hkszdxzqh,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem, "hkszdxzqh", $$v);
      },
      expression: "BCItem.hkszdxzqh"
    }
  }, _vm._l(_vm.quList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.BCItem.hkxz,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem, "hkxz", $$v);
      },
      expression: "BCItem.hkxz"
    }
  }, _vm._l(_vm.xingzhiList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1)], 1)], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("户口所在地")])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._v("通讯地址")])])], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 13
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "200px"
    },
    model: {
      value: _vm.BCItem.addressHousehold,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem, "addressHousehold", $$v);
      },
      expression: "BCItem.addressHousehold"
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "200px"
    },
    model: {
      value: _vm.BCItem.txdz,
      callback: function callback($$v) {
        _vm.$set(_vm.BCItem, "txdz", $$v);
      },
      expression: "BCItem.txdz"
    }
  })], 1)], 1)], 1)]) : _vm._e()])])], 2), _c("a-modal", {
    attrs: {
      title: "同步结果",
      visible: _vm.confirmPlanTongBu,
      width: "800px"
    },
    on: {
      cancel: _vm.handleCancel,
      ok: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 确定 ")])], 1), _c("div", {
    staticClass: "rowdialog"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "column",
      width: "100%"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "row",
      "align-items": "center"
    }
  }, [_c("img", {
    staticStyle: {
      width: "22px",
      height: "22px"
    },
    attrs: {
      src: require("@/assets/img/overviewContract/icon4.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "15px",
      "margin-left": "5px",
      "font-weight": "700"
    }
  }, [_vm._v("同步完成")])]), _c("div", {
    staticStyle: {
      color: "#767885",
      "font-size": "13px",
      "margin-left": "28px"
    }
  }, [_vm._v(" 同步结果：成功" + _vm._s(_vm.sucNum) + "人，失败" + _vm._s(_vm.dataDetailJieG.length - _vm.sucNum) + "人 ")])])]), _c("a-table", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      columns: _vm.columnDialogTongbu,
      pagination: _vm.pagination2,
      "data-source": _vm.dataDetailJieG,
      scroll: {
        x: 700
      },
      colspan: "0",
      "row-key": "id"
    },
    on: {
      change: _vm.handlePageChange2
    },
    scopedSlots: _vm._u([{
      key: "result",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text === "0" ? "失败" : "成功"))])];
      }
    }, {
      key: "info",
      fn: function fn(text, record) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(record.info))])]), _c("span", [_vm._v(_vm._s(_vm.ellipsis(record.info)))])], 2)];
      }
    }])
  })], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };