import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  path: "/wages",
  // 薪资管理
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout"));
    });
  },
  children: [{
    path: "gongzibiaomuban",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/xinzi/gongzibiaomuban"));
      });
    }
  }, {
    path: "gongzibiaomuban/edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/xinzi/gongzibiaomuban/edit"));
      });
    }
  }, {
    path: "yuegongzibiao",
    // 月工资表
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/xinzi/yuegongzibiao"));
      });
    }
  }, {
    path: "yuegongzibiao/details",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/xinzi/yuegongzibiao/details"));
      });
    }
  }, {
    path: "yuegongzibiao/editDetails",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/xinzi/yuegongzibiao/editDetails"));
      });
    }
  }, {
    path: "yuegongzibiao/importWages",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/alone/xinzi/yuegongzibiao/importWages"));
      });
    }
  }, {
    path: "monthly",
    // 月工资表
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/monthly"));
      });
    }
  }, {
    path: "monthly/details",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/monthly/details"));
      });
    }
  }, {
    path: "monthly/editDetails",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/monthly/editDetails"));
      });
    }
  }, {
    path: "monthly/uploadPayroll",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/monthly/uploadPayroll"));
      });
    }
  }, {
    path: "monthly/calculation",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/monthly/calculation"));
      });
    }
  }, {
    path: "monthly/incomeTax",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/monthly/incomeTax"));
      });
    }
  }, {
    path: "monthly/importWages",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/monthly/importWages"));
      });
    }
  }, {
    path: "monthly/special",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/monthly/special"));
      });
    }
  }, {
    path: "set",
    // 薪资设置
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/set"));
      });
    }
  }, {
    path: "set/group",
    // 薪资组
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/set/group"));
      });
    }
  }, {
    path: "set/group/add",
    // 薪资组设置
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/set/group/add"));
      });
    }
  }, {
    path: "set/rule/edit",
    // 编辑
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/set/rule/edit"));
      });
    }
  }, {
    path: "set/rule/detail",
    // 查看
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/set/rule/detail"));
      });
    }
  }, {
    path: "template",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/template"));
      });
    }
  }, {
    path: "template/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/template/add"));
      });
    }
  }, {
    path: "template/edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/template/edit"));
      });
    }
  }, {
    path: "file",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/file"));
      });
    }
  }, {
    path: "file/upload",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/file/upload"));
      });
    },
    meta: {
      title: "导入定薪",
      icon: "dashboard"
    }
  }, {
    path: "file/template",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/file/template"));
      });
    },
    meta: {
      title: "定薪模板"
    }
  }, {
    path: "file/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/file/add"));
      });
    },
    meta: {
      title: "员工定薪"
    }
  }, {
    path: "file/batch",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/file/batch"));
      });
    },
    meta: {
      title: "批量定薪"
    }
  }, {
    path: "file/edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/file/edit"));
      });
    },
    meta: {
      title: "员工调薪"
    }
  }, {
    path: "file/detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/file/detail"));
      });
    },
    meta: {
      title: "薪资详情"
    }
  }, {
    path: "file/outsourcingDetail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/file/outsourcingDetail"));
      });
    },
    meta: {
      title: "编辑"
    }
  }, {
    path: "file/wagesRules",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/settlementRules/wagesRules"));
      });
    },
    meta: {
      title: "工价规则"
    }
  }, {
    path: "file/laborRules",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/contract/settlementRules/laborRules"));
      });
    },
    meta: {
      title: "劳务费规则"
    }
  }, {
    path: "file/editDetail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/file/editDetail"));
      });
    },
    meta: {
      title: "编辑"
    }
  }, {
    path: "file/batchOutsourcing",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/file/batchOutsourcing"));
      });
    },
    meta: {
      title: "批量编辑"
    }
  }, {
    path: "files/social",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/file/social.vue"));
      });
    },
    meta: {
      title: "编辑社保"
    }
  }, {
    path: "payroll",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/payroll/payroll"));
      });
    }
  }, {
    path: "report",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/report/report"));
      });
    },
    meta: {
      title: "薪资计算"
      // icon: "dashboard"
    }
  }, {
    path: "report-history",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/report/report-history"));
      });
    },
    meta: {
      title: "薪资记录"
      // icon: "dashboard"
    }
  }, {
    path: "payslip",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/payslip"));
      });
    },
    meta: {
      title: "电子工资单",
      icon: "dashboard"
    }
  }, {
    path: "add-payslip",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/payslip/add-payslip"));
      });
    }
  }, {
    path: "add-payslip-sort",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/payslip/add-payslip-sort"));
      });
    },
    children: [{
      path: "",
      redirect: "add-payslip-one"
    }, {
      path: "add-payslip-one",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/wages/payslip/add-payslip-one"));
        });
      }
    }, {
      path: "add-payslip-second",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/wages/payslip/add-payslip-second"));
        });
      }
    }, {
      path: "add-payslip-third",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/wages/payslip/add-payslip-third"));
        });
      }
    }, {
      path: "add-payslip-forth",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/wages/payslip/add-payslip-forth"));
        });
      }
    }]
  }, {
    path: "payslip-detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/payslip/payslip-detail"));
      });
    },
    meta: {
      title: "工资发放详情"
      // icon: "dashboard"
    }
  }, {
    path: "payslip-excel-detail",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/payslip/payslip-excel-detail"));
      });
    }
  }, {
    path: "wages-set",
    redirect: "wages-set/salary-set",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/wages/wages-set/wages-set"));
      });
    },
    meta: {
      title: "薪资设置",
      icon: "dashboard"
    },
    children: [{
      hidden: true,
      path: "salary-set",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/wages/wages-set/children/salary-set.vue"));
        });
      },
      meta: {
        title: "计薪设置",
        path: "/wages/wages-set/salary-set"
      }
    }, {
      hidden: true,
      path: "allowance-set",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/wages/wages-set/children/allowance-set.vue"));
        });
      },
      meta: {
        title: "津贴设置",
        path: "/wages/wages-set/allowance-set"
      }
    }, {
      hidden: true,
      path: "pay-set",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/wages/wages-set/children/pay-set"));
        });
      },
      meta: {
        title: "工资设置",
        path: "/wages/wages-set/pay-set"
      }
    }, {
      hidden: true,
      path: "message-set",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/wages/wages-set/children/message-set"));
        });
      },
      meta: {
        title: "消息提醒",
        path: "/wages/wages-set/message-set"
      }
    }, {
      hidden: true,
      path: "split",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/wages/wages-set/children/split"));
        });
      },
      meta: {
        title: "成本拆分",
        path: "/wages/wages-set/split"
      }
    }, {
      hidden: true,
      path: "change-wages-set",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/wages/wages-set/children/change-wages-set"));
        });
      },
      meta: {
        title: "调薪设置",
        path: "/wages/wages-set/change-wages-set"
      }
    }, {
      hidden: true,
      path: "prove-temp",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("@/views/wages/wages-set/children/prove-temp"));
        });
      },
      meta: {
        title: "证明模板",
        path: "/wages/wages-set/prove-temp"
      }
    }]
  }]
};