var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-drawer", {
    attrs: {
      width: 550,
      title: "工资明细",
      placement: "right",
      closable: false,
      visible: _vm.show
    },
    on: {
      close: function close($event) {
        return _vm.$emit("update:show", false);
      }
    }
  }, [_c("a-button", {
    staticClass: "pos",
    on: {
      click: function click($event) {
        _vm.editor = !_vm.editor;
      }
    }
  }, [_vm._v("编辑")]), _c("div", {
    staticClass: "salary-body"
  }, [_c("div", {
    staticClass: "salary-title"
  }, [_vm._v("基本信息")]), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "姓名",
      "label-col": {
        span: 2
      },
      "wrapper-col": {
        span: 22
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.empName,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "empName", $$v);
      },
      expression: "detialData.empName"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "部门",
      "label-col": {
        span: 2
      },
      "wrapper-col": {
        span: 22
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.depName,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "depName", $$v);
      },
      expression: "detialData.depName"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "职位",
      "label-col": {
        span: 2
      },
      "wrapper-col": {
        span: 22
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.positionName,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "positionName", $$v);
      },
      expression: "detialData.positionName"
    }
  })], 1), _c("div", {
    staticClass: "salary-title"
  }, [_vm._v("薪资信息")]), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "基本工资",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.salaryBasic,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "salaryBasic", $$v);
      },
      expression: "detialData.salaryBasic"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "岗位补贴",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.subsidy,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "subsidy", $$v);
      },
      expression: "detialData.subsidy"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "应出勤天数",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.workingDays,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "workingDays", $$v);
      },
      expression: "detialData.workingDays"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "缺勤天数",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.absenteeismTimes,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "absenteeismTimes", $$v);
      },
      expression: "detialData.absenteeismTimes"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "缺勤扣款",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.absenteeismDed,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "absenteeismDed", $$v);
      },
      expression: "detialData.absenteeismDed"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "绩效奖金",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.businessCommission,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "businessCommission", $$v);
      },
      expression: "detialData.businessCommission"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "业绩提成",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.teamCommission,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "teamCommission", $$v);
      },
      expression: "detialData.teamCommission"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "个人社保",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.socDed,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "socDed", $$v);
      },
      expression: "detialData.socDed"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "个人公积金",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.pfDed,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "pfDed", $$v);
      },
      expression: "detialData.pfDed"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "个税",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.personTax,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "personTax", $$v);
      },
      expression: "detialData.personTax"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "应发工资",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.detialData.supplementDed,
      callback: function callback($$v) {
        _vm.$set(_vm.detialData, "supplementDed", $$v);
      },
      expression: "detialData.supplementDed"
    }
  })], 1), _c("a-form-item", {
    staticClass: "ta-left",
    attrs: {
      label: "实发工资",
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.editor
    },
    model: {
      value: _vm.reportOptions.salaryReal,
      callback: function callback($$v) {
        _vm.$set(_vm.reportOptions, "salaryReal", $$v);
      },
      expression: "reportOptions.salaryReal"
    }
  })], 1)], 1), _c("div", {
    staticClass: "drawer-foot foot"
  }, [_c("a-button", {
    staticStyle: {
      marginRight: "8px"
    },
    on: {
      click: _vm.onCancle
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onOk
    }
  }, [_vm._v("确认")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };