import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 客户详情 ")]), _c("div", {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.$route.query.name) + " "), _vm.isSign ? _c("span", {
    staticClass: "t1"
  }, [_vm._v("签约")]) : _c("span", {
    staticClass: "t2"
  }, [_vm._v("潜在")]), !_vm.isSign ? _c("div", {
    staticClass: "btn"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSigning
    }
  }, [_vm._v(" 签约客户 ")])], 1) : _vm._e()]), _c("div", {
    staticClass: "box"
  }, [_c("a-tabs", {
    attrs: {
      type: "card",
      "default-active-key": "information"
    },
    on: {
      change: function change(v) {
        return _vm.componentName = v;
      }
    }
  }, _vm._l(_vm.numArr, function (item) {
    return _c("a-tab-pane", {
      key: item.type
    }, [_c("span", {
      attrs: {
        slot: "tab"
      },
      slot: "tab"
    }, [_c("a-badge", {
      attrs: {
        count: item.num,
        offset: [5, -2]
      }
    }, [_vm._v(_vm._s(item.name))])], 1)]);
  }), 1), _c(_vm.componentName, {
    tag: "div",
    attrs: {
      address: _vm.address
    },
    on: {
      upDate: _vm.upDate,
      upDateAddress: _vm.upDateAddress
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };