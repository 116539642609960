import request from "@/utils/request";
// 新增工价规则
export function salaryCalculateRules(data) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules",
    method: "post",
    data: data
  });
}
// 新增工价规则组
export function addSalaryCalculateRuleGroups(data) {
  return request({
    url: "/hr/api/v1/salaryCalculateRuleGroups",
    method: "post",
    data: data
  });
}
// 查询工价规则组
export function getSalaryCalculateRuleGroupsPage(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRuleGroups",
    method: "get",
    params: params
  });
}
export function getSalaryCalculateRuleGroups(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRuleGroups/listAll",
    method: "get",
    params: params
  });
}
// 查询工价规则
export function salaryCalculateRulesList(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/listAll",
    method: "get",
    params: params
  });
}
// 分页查询工价规则
export function salaryRulesListByPage(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules",
    method: "get",
    params: params
  });
}
export function editEmpInfos(data) {
  return request({
    url: "/hr/api/v1/empInfos",
    method: "put",
    data: data
  });
}
// 下发规则
export function issueGroup(data) {
  return request({
    url: "/hr/api/v1/salaryCalculateRuleGroups/issueGroup",
    method: "post",
    data: data
  });
}
// 查看详情
export function salaryCalculateRulesEdit(salaryCalculateRuleId) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/".concat(salaryCalculateRuleId),
    method: "get"
  });
}
// 删除规则项
export function salaryCalculateRulesDelete(salaryCalculateRuleId) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/".concat(salaryCalculateRuleId),
    method: "delete"
  });
}
// 删除规则组
export function deleteRuleGroupId(salaryCalculateRuleGroupId) {
  return request({
    url: "/hr/api/v1/salaryCalculateRuleGroups/".concat(salaryCalculateRuleGroupId),
    method: "delete"
  });
}
// 编辑规则
export function salaryCalculateRulesEdits(data) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules",
    method: "put",
    data: data
  });
}
// 编辑规则组
export function editSalaryCalculateRuleGroups(data) {
  return request({
    url: "/hr/api/v1/salaryCalculateRuleGroups",
    method: "put",
    data: data
  });
}

// 查询员工信息
export function selectEmpInfoVoBySalaryCalculateRuleId(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/selectEmpInfoVoBySalaryCalculateRuleId",
    method: "get",
    params: params
  });
}
export function selectEmpInfoVoBySalaryCalculateRuleGroupId(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRuleGroups/selectEmpInfoVoBySalaryCalculateRuleGroupId",
    method: "get",
    params: params
  });
}
export function selectEmpInfoVoBySalaryCalculateRuleGroupId2(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRuleGroups/selectEmpInfoVoSiteUse",
    method: "get",
    params: params
  });
}
// 批量删除
export function deleteItems(data) {
  return request({
    url: "/hr/api/v1/salaryCalculateRuleGroups",
    method: "delete",
    data: data
  });
}