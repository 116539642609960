export default {
  fields: {
    brokerName: {
      model: "brokerName"
    },
    brokerPhone: {
      model: "brokerPhone"
    },
    brokerCode: {
      model: "brokerCode"
    },
    address: {
      model: "address"
    },
    recommendCount: {
      model: "recommendCount"
    },
    entryCount: {
      model: "entryCount"
    },
    brokerCreate: {
      model: "brokerCreate"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "brokerName",
        title: "姓名"
      }, {
        model: "brokerPhone",
        title: "手机号"
      }, {
        model: "brokerCode",
        title: "身份证"
      },
      // {
      //   model: "address",
      //   title: "地区"
      // },
      {
        model: "recommendCount",
        title: "推荐人数"
      }, {
        model: "entryCount",
        title: "成功入职"
      }, {
        model: "brokerCreate",
        title: "创建时间"
      }],
      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: false
      }
    }
  }
};