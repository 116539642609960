import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { getCalendar, synchronous as _synchronous, editType } from "./api";
import moment from "moment";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      siteId: null,
      loading: false,
      dynamicDate: moment(moment().format("YYYY-MM"), "YYYY-MM"),
      weekList: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"],
      dateList: []
    };
  },
  created: function created() {
    if (localStorage.getItem("siteId")) {
      this.siteId = localStorage.getItem("siteId");
    }
    this.getCalendarList();
  },
  methods: {
    dateChange: function dateChange(e) {
      if (e === 1) {
        this.dynamicDate = moment(this.dynamicDate).subtract(1, "months");
        this.getCalendarList();
      }
      if (e === 2) {
        this.dynamicDate = moment(this.dynamicDate).subtract(-1, "months");
        this.getCalendarList();
      }
    },
    // 更改休假上班
    editTypeChange: function editTypeChange(item) {
      var _this = this;
      editType({
        date: item.date,
        dateType: item.dateType === 1 ? 2 : 1,
        siteId: this.siteId
      }).then(function (res) {
        _this.$message.success("切换成功");
        _this.getCalendarList();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    mouseover: function mouseover(item, index) {
      this.$set(this.dateList, index, _objectSpread(_objectSpread({}, this.dateList[index]), {}, {
        hover: true
      }));
    },
    mouseleave: function mouseleave(item, index) {
      this.$set(this.dateList, index, _objectSpread(_objectSpread({}, this.dateList[index]), {}, {
        hover: false
      }));
    },
    mouseover2: function mouseover2(item, index) {
      this.$set(this.dateList, index, _objectSpread(_objectSpread({}, this.dateList[index]), {}, {
        hover2: true
      }));
    },
    mouseleave2: function mouseleave2(item, index) {
      this.$set(this.dateList, index, _objectSpread(_objectSpread({}, this.dateList[index]), {}, {
        hover2: false
      }));
    },
    // 同步
    synchronous: function synchronous() {
      var _this2 = this;
      _synchronous({
        year: parseInt(moment().format("YYYY"))
      }).then(function (res) {
        _this2.$message.success("同步成功");
        _this2.getCalendarList();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 返回今天
    goBack: function goBack() {
      this.dynamicDate = moment(moment().format("YYYY-MM"), "YYYY-MM");
      this.getCalendarList();
    },
    // 选择月份变化
    onMonthChange: function onMonthChange() {
      this.getCalendarList();
    },
    // 查询的
    getCalendarList: function getCalendarList() {
      var _this3 = this;
      this.loading = true;
      getCalendar({
        month: moment(this.dynamicDate).format("MM"),
        year: moment(this.dynamicDate).format("YYYY"),
        siteId: this.siteId,
        "orders[0].desc": false,
        "orders[0].property": "date"
      }).then(function (res) {
        _this3.loading = false;
        _this3.dateList = JSON.parse(JSON.stringify(res.data));
        if (moment(_this3.dateList[0].date).day() !== 1) {
          var num = moment(_this3.dateList[0].date).day() - 1;
          for (var i = 0; i < num; i++) {
            _this3.dateList.unshift({});
          }
        }
        if (moment(_this3.dateList[_this3.dateList.length - 1].date).day() !== 0) {
          var _num = 7 - moment(_this3.dateList[_this3.dateList.length - 1].date).day();
          for (var o = 0; o < _num; o++) {
            _this3.dateList.push({});
          }
        }
        _this3.dateList.forEach(function (item, index) {
          if (item.initDateType === 1 && item.month === parseInt(moment(_this3.dynamicDate).format("MM"))) {
            item.style = "background:#24CDAA";
          }
          if (item.initDateType === 1 && item.month !== parseInt(moment(_this3.dynamicDate).format("MM"))) {
            item.style = "background:#24CDAA; opacity: 0.5;";
          }
          if (item.initDateType === 2 && item.month === parseInt(moment(_this3.dynamicDate).format("MM"))) {
            item.style = "background:#C0C0C0";
          }
          if (item.initDateType === 2 && item.month !== parseInt(moment(_this3.dynamicDate).format("MM"))) {
            item.style = "background:#C0C0C0; opacity: 0.5;";
          }
          if (item.initDateType === 3 && item.month === parseInt(moment(_this3.dynamicDate).format("MM"))) {
            item.style = "background:#FFA600";
          }
          if (item.initDateType === 3 && item.month !== parseInt(moment(_this3.dynamicDate).format("MM"))) {
            item.style = "background:#FFA600; opacity: 0.5;";
          }
        });
        _this3.dateList.forEach(function (item, index) {
          item.hover = false;
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this3.loading = false;
      });
    }
  }
};