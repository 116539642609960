import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "bgc-white page-box-employer-setting"
  }, [_c("a-row", {
    staticClass: "p24 btmline",
    staticStyle: {
      border: "none"
    }
  }, [_c("a-col", {
    attrs: {
      span: 2
    }
  }, [_c("a-icon", {
    staticClass: "fs20",
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  })], 1), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer",
      "margin-top": "-3px"
    },
    on: {
      click: _vm.goback
    }
  }, [_vm._v("返回")])], 1), _c("a-collapse", {
    staticClass: "p24",
    attrs: {
      bordered: false
    },
    model: {
      value: _vm.activeKey,
      callback: function callback($$v) {
        _vm.activeKey = $$v;
      },
      expression: "activeKey"
    }
  }, [_c("a-collapse-panel", {
    key: "7",
    attrs: {
      header: "员工基本资料"
    }
  }, [_c("a-row", [_c("div", {
    staticClass: "table-header"
  }, [_c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])]), _c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])])]), _vm._l(_vm.allData.companyEmpNecessaryAttachmentVOList, function (item, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row"
    }, [_c("p", [_c("span", {
      staticClass: "name-box"
    }, [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    })], 1)])]);
  }), _vm._l(_vm.allData.companyAdditionalFieldsAttachmentVOList, function (item, index) {
    return _c("a-col", {
      key: index.id,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row2"
    }, [_c("p", {
      staticClass: "name-box"
    }, [_c("span", [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      staticClass: "checkbox1",
      on: {
        change: function change($event) {
          return _vm.remoteControlAdd(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("div", {
      staticClass: "checkbox2"
    }, [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControlAdd(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    }), _c("div", {
      staticClass: "item-delete"
    }, [_c("div", [_c("a-button", {
      staticClass: "add-field",
      on: {
        click: function click($event) {
          return _vm.edit(item, "annex");
        }
      }
    }, [_c("a-icon", {
      staticClass: "editInformation",
      attrs: {
        type: "form"
      }
    })], 1)], 1), _c("a-popconfirm", {
      attrs: {
        title: "确定删除吗？"
      },
      on: {
        confirm: function confirm($event) {
          return _vm.confirmDelete(item.id);
        }
      }
    }, [_c("a-icon", {
      staticStyle: {
        color: "red"
      },
      attrs: {
        slot: "icon",
        type: "question-circle-o"
      },
      slot: "icon"
    }), _c("a", {
      attrs: {
        href: "#"
      }
    }, [_c("a-icon", {
      staticClass: "add-delete",
      attrs: {
        type: "delete"
      }
    })], 1)], 1)], 1)], 1)])]);
  }), _c("div", [_c("a-button", {
    staticClass: "add-field-add",
    on: {
      click: function click($event) {
        _vm.showModal("visible1");
        _vm.resetForm();
      }
    }
  }, [_c("a-icon", {
    staticClass: "add-field-icon",
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v("添加字段 ")], 1), _c("a-modal", {
    attrs: {
      title: "添加新字段"
    },
    on: {
      ok: function ok($event) {
        return _vm.handleAddOk(_vm.item, "annex");
      }
    },
    model: {
      value: _vm.visible1,
      callback: function callback($$v) {
        _vm.visible1 = $$v;
      },
      expression: "visible1"
    }
  }, [_c("a-form-model", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "字段名称",
      rules: {
        required: true
      },
      help: "1-8个字符，不能与现有字段重复"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段名称",
      "max-length": 8
    },
    model: {
      value: _vm.form.fieldName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldName", $$v);
      },
      expression: "form.fieldName"
    }
  })], 1), _c("a-form-model-item", {
    ref: "description",
    attrs: {
      prop: "description",
      label: "字段描述"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段描述"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm.show === 0 ? _c("a-form-model", _vm._b({
    ref: "dynamicValidateForm",
    attrs: {
      model: _vm.dynamicValidateForm
    }
  }, "a-form-model", _vm.formItemLayoutWithOutLabel, false), [_c("a-form-model-item", _vm._b({}, "a-form-model-item", _vm.formItemLayoutWithOutLabel, false))], 1) : _vm._e()], 1)], 1)], 1)], 2)], 1), _c("a-collapse-panel", {
    key: "98",
    attrs: {
      header: "员工档案资料"
    }
  }, [_c("a-row", [_c("div", {
    staticClass: "table-header"
  }, [_c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])]), _c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])])]), _vm._l(_vm.allData.companyEmpNecessaryArchiveVOList, function (item, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row"
    }, [_c("p", [_c("span", {
      staticClass: "name-box"
    }, [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    })], 1)])]);
  }), _vm._l(_vm.allData.companyAdditionalFieldsArchiveVOList, function (item, index) {
    return _c("a-col", {
      key: index.id,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row2"
    }, [_c("p", {
      staticClass: "name-box"
    }, [_c("span", [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      staticClass: "checkbox1",
      on: {
        change: function change($event) {
          return _vm.remoteControlAdd(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("div", {
      staticClass: "checkbox2"
    }, [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControlAdd(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    }), _c("div", {
      staticClass: "item-delete"
    }, [_c("div", [_c("a-button", {
      staticClass: "add-field",
      on: {
        click: function click($event) {
          return _vm.edit(item, "archive");
        }
      }
    }, [_c("a-icon", {
      staticClass: "editInformation",
      attrs: {
        type: "form"
      }
    })], 1)], 1), _c("a-popconfirm", {
      attrs: {
        title: "确定删除吗？"
      },
      on: {
        confirm: function confirm($event) {
          return _vm.confirmDelete(item.id);
        }
      }
    }, [_c("a-icon", {
      staticStyle: {
        color: "red"
      },
      attrs: {
        slot: "icon",
        type: "question-circle-o"
      },
      slot: "icon"
    }), _c("a", {
      attrs: {
        href: "#"
      }
    }, [_c("a-icon", {
      staticClass: "add-delete",
      attrs: {
        type: "delete"
      }
    })], 1)], 1)], 1)], 1)])]);
  }), _c("div", [_c("a-button", {
    staticClass: "add-field-add",
    on: {
      click: function click($event) {
        return _vm.showModal("visible2");
      }
    }
  }, [_c("a-icon", {
    staticClass: "add-field-icon",
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v("添加字段 ")], 1), _c("a-modal", {
    attrs: {
      title: "添加新字段"
    },
    on: {
      ok: function ok($event) {
        return _vm.handleAddOk(_vm.item, "archive");
      }
    },
    model: {
      value: _vm.visible2,
      callback: function callback($$v) {
        _vm.visible2 = $$v;
      },
      expression: "visible2"
    }
  }, [_c("a-form-model", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "字段名称",
      rules: {
        required: true
      },
      help: "1-8个字符，不能与现有字段重复"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段名称",
      "max-length": 8
    },
    model: {
      value: _vm.form.fieldName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldName", $$v);
      },
      expression: "form.fieldName"
    }
  })], 1), _c("a-form-model-item", {
    ref: "description",
    attrs: {
      prop: "description",
      label: "字段描述"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段描述"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm.show === 0 ? _c("a-form-model", _vm._b({
    ref: "dynamicValidateForm",
    attrs: {
      model: _vm.dynamicValidateForm
    }
  }, "a-form-model", _vm.formItemLayoutWithOutLabel, false), [_c("a-form-model-item", _vm._b({}, "a-form-model-item", _vm.formItemLayoutWithOutLabel, false))], 1) : _vm._e()], 1)], 1)], 1)], 2)], 1), _c("a-collapse-panel", {
    key: "66",
    attrs: {
      header: "员工离职资料"
    }
  }, [_c("a-row", [_c("div", {
    staticClass: "table-header"
  }, [_c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])]), _c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])])]), _vm._l(_vm.allData.companyEmpNecessaryLeaveOfficeVOList, function (item, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row"
    }, [_c("p", [_c("span", {
      staticClass: "name-box"
    }, [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    })], 1)])]);
  }), _vm._l(_vm.allData.companyAdditionalFieldsLeaveOfficeVOList, function (item, index) {
    return _c("a-col", {
      key: index.id,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row2"
    }, [_c("p", {
      staticClass: "name-box"
    }, [_c("span", [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      staticClass: "checkbox1",
      on: {
        change: function change($event) {
          return _vm.remoteControlAdd(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("div", {
      staticClass: "checkbox2"
    }, [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControlAdd(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    }), _c("div", {
      staticClass: "item-delete"
    }, [_c("div", [_c("a-button", {
      staticClass: "add-field",
      on: {
        click: function click($event) {
          return _vm.edit(item, "leaveOffice");
        }
      }
    }, [_c("a-icon", {
      staticClass: "editInformation",
      attrs: {
        type: "form"
      }
    })], 1)], 1), _c("a-popconfirm", {
      attrs: {
        title: "确定删除吗？"
      },
      on: {
        confirm: function confirm($event) {
          return _vm.confirmDelete(item.id);
        }
      }
    }, [_c("a-icon", {
      staticStyle: {
        color: "red"
      },
      attrs: {
        slot: "icon",
        type: "question-circle-o"
      },
      slot: "icon"
    }), _c("a", {
      attrs: {
        href: "#"
      }
    }, [_c("a-icon", {
      staticClass: "add-delete",
      attrs: {
        type: "delete"
      }
    })], 1)], 1)], 1)], 1)])]);
  }), _c("div", [_c("a-button", {
    staticClass: "add-field-add",
    on: {
      click: function click($event) {
        return _vm.showModal("visible3");
      }
    }
  }, [_c("a-icon", {
    staticClass: "add-field-icon",
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v("添加字段 ")], 1), _c("a-modal", {
    attrs: {
      title: "添加新字段"
    },
    on: {
      ok: function ok($event) {
        return _vm.handleAddOk(_vm.item, "leaveOffice");
      }
    },
    model: {
      value: _vm.visible3,
      callback: function callback($$v) {
        _vm.visible3 = $$v;
      },
      expression: "visible3"
    }
  }, [_c("a-form-model", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "字段名称",
      rules: {
        required: true
      },
      help: "1-8个字符，不能与现有字段重复"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段名称",
      "max-length": 8
    },
    model: {
      value: _vm.form.fieldName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldName", $$v);
      },
      expression: "form.fieldName"
    }
  })], 1), _c("a-form-model-item", {
    ref: "description",
    attrs: {
      prop: "description",
      label: "字段描述"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段描述"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm.show === 0 ? _c("a-form-model", _vm._b({
    ref: "dynamicValidateForm",
    attrs: {
      model: _vm.dynamicValidateForm
    }
  }, "a-form-model", _vm.formItemLayoutWithOutLabel, false), [_c("a-form-model-item", _vm._b({}, "a-form-model-item", _vm.formItemLayoutWithOutLabel, false))], 1) : _vm._e()], 1)], 1)], 1)], 2)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "编辑字段"
    },
    on: {
      ok: function ok($event) {
        return _vm.handleEditOk(_vm.code);
      }
    },
    model: {
      value: _vm.editvisible,
      callback: function callback($$v) {
        _vm.editvisible = $$v;
      },
      expression: "editvisible"
    }
  }, [_c("a-form-model", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "字段名称",
      rules: {
        required: true
      },
      help: "1-8个字符，不能与现有字段重复"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段名称",
      "max-length": 8
    },
    model: {
      value: _vm.form.fieldName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldName", $$v);
      },
      expression: "form.fieldName"
    }
  })], 1), _c("a-form-model-item", {
    ref: "description",
    attrs: {
      prop: "description",
      label: "字段描述"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段描述"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm.show === 0 ? _c("a-form-model", _vm._b({
    ref: "dynamicValidateForm",
    attrs: {
      model: _vm.dynamicValidateForm
    }
  }, "a-form-model", _vm.formItemLayoutWithOutLabel, false), [_c("a-form-model-item", _vm._b({}, "a-form-model-item", _vm.formItemLayoutWithOutLabel, false))], 1) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };