var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "revset"
  }, [_c("a-layout", [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 添加参保方案 ")]), _c("div", {
    staticStyle: {
      "background-color": "#fafafa",
      "margin-bottom": "24px",
      "padding-top": "20px"
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 7,
      offset: 3,
      sytle: "position:relative"
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "参保城市",
      required: ""
    }
  }, [_c("city", {
    attrs: {
      "default-value": _vm.socCity,
      level: 2
    },
    on: {
      change: function change($event) {
        return _vm.handleCity($event);
      }
    }
  })], 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 7,
      offset: 3
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "方案名称",
      required: ""
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请填写方案名称",
      maxLength: 50
    },
    model: {
      value: _vm.progName,
      callback: function callback($$v) {
        _vm.progName = $$v;
      },
      expression: "progName"
    }
  })], 1)], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 7,
      offset: 3
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "缴费日期"
    }
  }, [_c("a-input-number", {
    attrs: {
      placeholder: "每月扣费日期",
      min: 1,
      max: 31
    },
    on: {
      change: function change(value) {
        return _vm.handleDefault(value);
      }
    },
    model: {
      value: _vm.deductionsDate,
      callback: function callback($$v) {
        _vm.deductionsDate = $$v;
      },
      expression: "deductionsDate"
    }
  })], 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 7,
      offset: 3
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "服务费"
    }
  }, [_c("a-input-number", {
    attrs: {
      placeholder: "每月服务费",
      min: 0
    },
    model: {
      value: _vm.servercost,
      callback: function callback($$v) {
        _vm.servercost = $$v;
      },
      expression: "servercost"
    }
  })], 1)], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 7,
      offset: 3
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "社保账号"
    }
  }, [_c("SelectWithInputAdd", {
    attrs: {
      allowClear: "",
      editable: "",
      placeholder: "社保账号",
      dictCode: "1220"
    },
    model: {
      value: _vm.socAccount,
      callback: function callback($$v) {
        _vm.socAccount = $$v;
      },
      expression: "socAccount"
    }
  })], 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 7,
      offset: 3
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "医保账号"
    }
  }, [_c("SelectWithInputAdd", {
    attrs: {
      allowClear: "",
      editable: "",
      placeholder: "医保账号",
      dictCode: "1221"
    },
    model: {
      value: _vm.meAccount,
      callback: function callback($$v) {
        _vm.meAccount = $$v;
      },
      expression: "meAccount"
    }
  })], 1)], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 7,
      offset: 3
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "公积金账号"
    }
  }, [_c("SelectWithInputAdd", {
    attrs: {
      allowClear: "",
      editable: "",
      placeholder: "公积金账号",
      dictCode: "1222"
    },
    model: {
      value: _vm.pfAccount,
      callback: function callback($$v) {
        _vm.pfAccount = $$v;
      },
      expression: "pfAccount"
    }
  })], 1)], 1)], 1)], 1)], 1), _c("a-radio-group", {
    attrs: {
      "default-value": _vm.mode
    },
    on: {
      change: _vm.onChangeTab
    }
  }, [_c("a-radio-button", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 设置参保基数和比例 ")]), _c("a-radio-button", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 仅设置参保金额 ")])], 1), _vm.mode === "1" ? _c("shebao-table", {
    ref: "bili",
    attrs: {
      "prog-id": _vm.progId,
      "prog-name": _vm.progName,
      "soc-city-name": _vm.socCityName,
      "soc-city": _vm.socCity,
      deductionsDate: _vm.deductionsDate,
      servercost: _vm.servercost,
      socAccount: _vm.socAccount,
      meAccount: _vm.meAccount,
      pfAccount: _vm.pfAccount
    },
    on: {
      showMenu: _vm.showMenu
    }
  }) : _vm._e(), _vm.mode === "2" ? _c("only-amount-table", {
    ref: "guding",
    attrs: {
      "prog-id": _vm.progId,
      "prog-name": _vm.progName,
      "soc-city-name": _vm.socCityName,
      "soc-city": _vm.socCity,
      deductionsDate: _vm.deductionsDate,
      servercost: _vm.servercost,
      socAccount: _vm.socAccount,
      meAccount: _vm.meAccount,
      pfAccount: _vm.pfAccount
    },
    on: {
      showMenu: _vm.showMenu
    }
  }) : _vm._e(), _vm.showBackground ? _c("div", {
    staticStyle: {
      width: "100%",
      height: "100%",
      position: "absolute",
      "z-index": "900",
      "background-color": "#00000000"
    },
    on: {
      click: _vm.hideMenu
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };