import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { getComany, getEmp } from "@/api/overview/employer";
import { mapGetters } from "vuex";
import FormJson from "./employer/FormJson"; // 引入FormJson数据
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
import { retrieveReq, createReq, deleteReq, deleteBatchReq, updateReq } from "./employer/api"; // 引入接口文件

var curdMixin = new XqCurdMixin({
  // createReq,
  // retrieveReq,
  // updateReq,
  // deleteReq,
  // deleteBatchReq,
  FormJson: FormJson,
  tableConfigKey: "table"
});
var empList = [{
  title: "姓名",
  dataIndex: "name",
  width: 120
}, {
  title: "身份证号",
  dataIndex: "idCard",
  ellipsis: true,
  width: 220
}, {
  title: "手机号",
  dataIndex: "phone",
  ellipsis: true,
  width: 120
}, {
  title: "合同年限",
  dataIndex: "timeLimitStr",
  ellipsis: true,
  width: 120
}, {
  title: "合同到期时间",
  dataIndex: "endTime",
  ellipsis: true,
  width: 160
}];
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
export default {
  mixins: [curdMixin, mixin],
  data: function data() {
    return {
      empList: empList,
      compyNam: "",
      empVisible: false,
      syncType: 0,
      equipmentIdList: [],
      syncVisible: false,
      name: "",
      joinCityCode: "",
      joinCityName: "",
      searchForm: {
        name: ""
      },
      unitSearchForm: {},
      selectedRowKeys: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        pageSizeOptions: ["10", "20", "50", "100"],
        showQuickJumper: true,
        showSizeChanger: true
      },
      pagination2: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        pageSizeOptions: ["10", "20", "50", "100"],
        showQuickJumper: true,
        showSizeChanger: true
      },
      empGroup: [],
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      },
      data: [],
      property: "",
      empRecord: "",
      tableLoading: false,
      ids: null,
      searchName: "",
      currentPage: 1,
      pageSize: 10,
      total: 0
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["permissions_site"])), mapGetters({
    SelectedAllIds: "site/SelectedAllIds"
  })),
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.getComanyList();
  },
  methods: {
    unitNameChange: function unitNameChange(e) {
      var _this = this;
      this.unitSearchForm.keyWord = e;
      getEmp({
        companyId: this.empRecord.id,
        nameOrPhone: this.unitSearchForm.keyWord
      }).then(function (res) {
        _this.empGroup = res.data.entities;
      });
    },
    handlePageChange: function handlePageChange(pagination, filters, sorter) {
      this.pagination2.current = pagination.current;
      this.pagination2.pageSize = pagination.pageSize;
    },
    handleEmpOk: function handleEmpOk() {
      this.empVisible = false;
    },
    showEmp: function showEmp(record) {
      var _this2 = this;
      this.empRecord = record;
      this.pagination2.current = 1;
      this.pagination2.pageSize = 10;
      this.unitSearchForm = {};
      this.empVisible = true;
      this.compyNam = record.name;
      getEmp({
        companyId: record.id
      }).then(function (res) {
        _this2.empGroup = res.data.entities;
      });
    },
    getComanyList: function getComanyList() {
      var _this3 = this;
      this.tableLoading = true;
      var data = {
        name: this.searchName,
        pageNo: this.currentPage,
        pageSize: this.pageSize
      };
      // if (this.ids) data.ids = this.ids.split(",");
      var ids = this.SelectedAllIds;
      data.ids = ids.split(",");
      getComany(data).then(function (res) {
        _this3.tableData = res.data.entities || [];
        _this3.total = res.data.entityCount;
        _this3.tableLoading = false;
      }).catch(function () {
        _this3.tableData = [];
        _this3.tableLoading = false;
      });
    },
    onPageChange: function onPageChange(page) {
      this.currentPage = page;
      this.getComanyList();
    },
    onPageSizeChange: function onPageSizeChange(current, pageSize) {
      this.currentPage = 1;
      this.pageSize = pageSize;
      this.getComanyList();
    },
    onSearch: function onSearch() {
      this.currentPage = 1;
      this.getComanyList();
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 导出员工信息excel表
    download: function download(i) {
      if (i === 2) {
        this.params = {
          companyId: this.companyId,
          name: this.searchName
        };
      } else {
        this.params = {
          companyId: this.companyId,
          ids: this.selectedRowKeys
        };
      }
      if (!this.selectedRowKeys.length && i === 1) {
        this.$notification["error"]({
          message: "error",
          description: "导出失败，至少选择一个单位"
        });
      }
      if (i === 2 || this.selectedRowKeys.length) {
        this.url = "/hr/api/v1/statisticsPage/exportComanyWithEmpCount";
        var siteName = "";
        this.permissions_site.forEach(function (item, index) {
          if (item.currSite) siteName = item.name;
        });
        this.excelName = "".concat(siteName, "\u7528\u5DE5\u5355\u4F4D\u8BE6\u60C5.xlsx");
        this.getDownload();
      }
    }
  }
};