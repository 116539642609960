import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import { getOrderList as _getOrderList, selectCustomerCompanyConfig as _selectCustomerCompanyConfig, topStatistic as _topStatistic } from "./api";
import moment from "moment";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      current: 1,
      total: 0,
      tabButtonWait: true,
      tabButtonAlready: false,
      searchForm: {},
      companyList: [],
      orderList: [],
      loading: false,
      rangeDate: [],
      foldState: 1,
      rangeDate2: [],
      insCompanyId: undefined,
      companyState: undefined,
      insuranceList: [],
      isInit: true,
      defaultKey: "0",
      topDetail: {},
      dynamicDate: moment(moment().format("YYYY-MM"), "YYYY-MM"),
      selectListForCompany: [{
        name: "核保审核不通过",
        value: "1"
      }, {
        name: "核保审核通过",
        value: "3"
      }, {
        name: "进行中",
        value: "4"
      }, {
        name: "已结束",
        value: "5"
      }],
      selectList: [{
        name: "待核保审核",
        value: "0",
        color: "#4282FF",
        background: "rgba(66, 130, 255, 0.2)"
      }, {
        name: "核保审核不通过",
        value: "1",
        color: "#FA5051",
        background: "rgba(250, 80, 81, 0.2)"
      }, {
        name: "已撤销",
        value: "2",
        color: "#8D8D8D",
        background: "rgba(171, 171, 171, 0.2)"
      }, {
        name: "核保审核通过",
        value: "3",
        color: "#24CDAA",
        background: "rgba(36, 205, 170, 0.2)"
      }, {
        name: "进行中",
        value: "4",
        color: "#FFA600",
        background: " rgba(255, 166, 0, 0.2)"
      }, {
        name: "已结束",
        value: "5",
        color: "#8D8D8D",
        background: "rgba(171, 171, 171, 0.2)"
      }]
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.pageState = sessionStorage.getItem("shangbaoPageState");
    // this.pageState = this.$route.query.state ? this.$route.query.state : "customer"; // 区分客户端保险公司端page
    this.defaultKey = this.$route.query.defaultKey ? this.$route.query.defaultKey : "0"; // 默认tabs
    if (this.defaultKey === "0") {
      this.tabButtonWait = true;
      this.tabButtonAlready = false;
    } else if (this.defaultKey === "1") {
      this.tabButtonWait = false;
      this.tabButtonAlready = true;
    }
    this.getOrderList();
    if (this.pageState === "customer") {
      this.selectCustomerCompanyConfig(); // 只有客户端的时候拉取保险公司列表（筛选条件）
    }

    this.topStatistic();
  },
  mounted: function mounted() {},
  methods: {
    topStatistic: function topStatistic() {
      var _this = this;
      _topStatistic({
        time: moment(this.dynamicDate).format("YYYYMM"),
        queryType: this.pageState === "customer" ? "customer" : "insuranceCompany"
      }).then(function (res) {
        _this.topDetail = res.data;
      });
    },
    folding: function folding(e, item) {
      this.$set(item, "foldState", e !== true);
    },
    dateChange: function dateChange(e) {
      if (e === 1) {
        this.dynamicDate = moment(this.dynamicDate).subtract(1, "months");
        console.log(moment(this.dynamicDate).format("YYYY-MM"));
        this.topStatistic();
      }
      if (e === 2) {
        this.dynamicDate = moment(this.dynamicDate).subtract(-1, "months");
        console.log(moment(this.dynamicDate).format("YYYY-MM"));
        this.topStatistic();
      }
    },
    callback: function callback(e) {
      if (e === "0") {
        this.tabButtonWait = true;
        this.tabButtonAlready = false;
      } else if (e === "1") {
        this.tabButtonWait = false;
        this.tabButtonAlready = true;
      }
      console.log(e);
      this.defaultKey = e; // query记录默认tabs
      var query = JSON.parse(JSON.stringify(this.$router.history.current.query)); // 取当前路由query值并且深拷贝
      var path = this.$router.history.current.path; // 取当前路由地址
      query.defaultKey = this.defaultKey; // 修改变动的query值
      this.$router.push({
        path: path,
        query: query
      }); // 改变query值
      this.getOrderList();
    },
    onSearch: function onSearch() {
      this.current = 1;
      this.isInit = false;
      this.getOrderList();
    },
    onPageChange: function onPageChange() {
      // 分页变化
      this.getOrderList();
    },
    // 查询保险公司列表
    selectCustomerCompanyConfig: function selectCustomerCompanyConfig() {
      var _this2 = this;
      _selectCustomerCompanyConfig().then(function (res) {
        _this2.insuranceList = res.data;
      });
    },
    getOrderList: function getOrderList() {
      var _this3 = this;
      // 拉取订单列表
      this.loading = true;
      _getOrderList(_objectSpread(_objectSpread({
        pageSize: 5,
        pageNo: this.current
      }, this.searchForm), {}, {
        companyState: this.pageState === "company" ? this.defaultKey : undefined,
        insEffectBeginTime: this.rangeDate.length ? this.rangeDate[0] : undefined,
        insEffectEndTime: this.rangeDate.length ? this.rangeDate[1] : undefined,
        insFailureBeginTime: this.rangeDate2.length ? this.rangeDate2[0] : undefined,
        insFailureEndTime: this.rangeDate2.length ? this.rangeDate2[1] : undefined,
        insCompanyId: this.insCompanyId
      })).then(function (res) {
        _this3.orderList = res.data.entities || [];
        _this3.total = res.data.entityCount;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    goAdd: function goAdd() {
      this.$router.push("/shangyebaoxian/client/addClient");
    },
    goDetail: function goDetail(e) {
      this.$router.push({
        path: "/shangyebaoxian/client/detail",
        query: {
          detail: JSON.stringify(e),
          pageState: this.pageState
        }
      });
    }
  }
};