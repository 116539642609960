import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import E from "wangeditor";
import axios from "axios";
export default {
  props: ["id", "value", "width", "height", "uploadImgUrl", "disabled", "menus", "mapKey", "menuFixed", "pasteFilter", "codeDefaultLang", "hideLinkImg", "uploadParams", "uploadHeaders", "isRealtime", "disabledMenus"],
  data: function data() {
    return {
      styleObject: {
        width: "600px",
        height: "400px"
      },
      editor: null
    };
  },
  watch: {
    height: function height(val) {
      if (this.editor) {
        this.editor.$textContainerElem.css("height", "".concat(val - 33, "px !important"));
      }
    }
  },
  mounted: function mounted() {
    this.createEditor();
  },
  methods: {
    // 创建编辑器
    createEditor: function createEditor() {
      var _this = this;
      var editor = new E(this.$refs.editor);
      editor.config.height = "500px";
      editor.config.menuTooltipPosition = "down";
      editor.config.uploadImgShowBase64 = false;
      editor.config.showLinkImg = false;
      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        var params = new FormData();
        params.append("files", resultFiles[0]);
        axios({
          url: "/file/api/v1/general/uploadImg",
          method: "post",
          "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq",
          data: params
        }).then(function (success) {
          insertImgFn(success.data.data[0]);
        });
      };
      if (this.menus) {
        editor.config.menus = this.menus;
      }
      editor.config.onchange = function (html) {
        _this.$emit("input", html);
        _this.$emit("change", html);
      };
      editor.create();
      this.editor = editor;
    },
    // 筛选编辑器菜单
    filterMenu: function filterMenu(menus) {
      if (menus instanceof Array) {
        return menus.map(function (item, key) {
          if (item === "source") {
            return null;
          }
          return item;
        });
      }
    },
    // 筛选不可用菜单
    filterDisabledMenu: function filterDisabledMenu(menus, disabledMenus) {
      var menusToString = menus.join(",");
      disabledMenus.forEach(function (res) {
        menusToString = menusToString.replace(res, "").replace(",,", ",");
      });
      if (menusToString.length && menusToString[0] === ",") {
        menusToString.substr(1, menusToString.length);
      }
      return menusToString.split(",");
    },
    // 插入图片
    insertImg: function insertImg(url) {
      this.editor.cmd.do("insertHtml", "<img src=" + url + ' style="max-width:100%;"/>');
    },
    // 获取内容(html)
    getHtml: function getHtml() {
      return this.editor.txt.html();
    },
    // 获取内容(text)
    getText: function getText() {
      return this.editor.txt.text();
    },
    // 设置内容(html)
    setHtml: function setHtml(hmtl) {
      this.editor.txt.html(hmtl);
    },
    // 追加内容(html)
    appendHtml: function appendHtml(html) {
      this.editor.txt.append(html);
    },
    // 插入内容
    insertHtml: function insertHtml(html) {
      this.editor.cmd.do("insertHtml", html);
    },
    // 清空内容
    clear: function clear() {
      this.editor.txt.clear();
    },
    // 启用编辑器
    enable: function enable() {
      this.editor.enable();
    },
    // 禁用编辑器
    disable: function disable() {
      this.editor.disable();
    },
    // 销毁编辑器
    destroy: function destroy() {
      this.editor.destroy();
    },
    // 恢复编辑器
    undestroy: function undestroy() {
      this.editor.undestroy();
    }
  }
};