import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      title: "批量更换用工单位",
      visible: _vm.visible,
      "confirm-loading": _vm.confirmLoading,
      destroyOnClose: true
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "原用工单位"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: true
    },
    model: {
      value: _vm.companyName,
      callback: function callback($$v) {
        _vm.companyName = $$v;
      },
      expression: "companyName"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "新用工单位"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyId", {
        rules: [{
          required: true,
          message: "请选择新用工单位"
        }]
      }],
      expression: "['companyId', { rules: [{ required: true, message: '请选择新用工单位' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "show-search": "",
      "option-filter-prop": "children",
      allowClear: "",
      "filter-option": _vm.filterOption
    }
  }, _vm._l(_vm.options, function (i) {
    return _c("a-select-option", {
      key: i.id,
      attrs: {
        value: i.id
      }
    }, [_vm._v(_vm._s(i.name))]);
  }), 1)], 1), !_vm.isJF ? _c("a-form-item", {
    attrs: {
      label: "工作性质"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType", {
        rules: [{
          required: true,
          message: "请选择工作性质"
        }]
      }],
      expression: "['postType', { rules: [{ required: true, message: '请选择工作性质' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    on: {
      change: _vm.postTypeChange
    }
  }, _vm._l(_vm.postTypeOptions, function (i) {
    return _c("a-select-option", {
      key: i.value,
      attrs: {
        value: i.value
      }
    }, [_vm._v(_vm._s(i.label))]);
  }), 1)], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "原用工单位离职日期"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["preQuitDate", {
        rules: [{
          required: true,
          message: "请选择原用工单位离职日期"
        }]
      }],
      expression: "['preQuitDate', { rules: [{ required: true, message: '请选择原用工单位离职日期' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    on: {
      change: _vm.onChangeDatepreQuitDate
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "新用工单位入职日期"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["JoinDate", {
        rules: [{
          required: true,
          message: "请选择新用工单位入职日期"
        }]
      }],
      expression: "['JoinDate', { rules: [{ required: true, message: '请选择新用工单位入职日期' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    on: {
      change: _vm.onChangeDate
    }
  })], 1), _vm.postTypeShow ? _c("div", [_c("a-form-item", {
    attrs: {
      label: "员工状态"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["formalType", {
        rules: [{
          required: true,
          message: "请选择员工状态"
        }]
      }],
      expression: "['formalType', { rules: [{ required: true, message: '请选择员工状态' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    on: {
      change: _vm.handleChange
    }
  }, _vm._l(_vm.formalTypeOptions, function (i) {
    return _c("a-select-option", {
      key: i.value,
      attrs: {
        value: i.value
      }
    }, [_vm._v(_vm._s(i.label))]);
  }), 1)], 1), _vm.probationDays ? _c("div", [_c("a-form-item", {
    attrs: {
      label: "试用期"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["probationDays", {
        rules: [{
          required: true,
          message: "请填写试用期"
        }]
      }],
      expression: "[\n                'probationDays',\n                {\n                  rules: [{ required: true, message: '请填写试用期' }]\n                }\n              ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      min: 0,
      formatter: function formatter(value) {
        return "".concat(value, "\u5929");
      },
      placeholder: "试用期天数"
    },
    on: {
      change: _vm.probationDaysChange
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "预计转正日期"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["turnStraightDate"],
      expression: "['turnStraightDate']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      placeholder: "请选择",
      disabled: ""
    }
  })], 1)], 1) : _vm._e(), _vm.probationDays === false ? _c("a-form-item", {
    attrs: {
      label: "预计转正日期"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["turnStraightDate", {
        rules: [{
          required: true,
          message: "请填写转正日期"
        }]
      }],
      expression: "[\n              'turnStraightDate',\n              {\n                rules: [{ required: true, message: '请填写转正日期' }]\n              }\n            ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      placeholder: "请选择"
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };