import request from "@/utils/request";
import store from "@/store";
// 发薪审批-审批中
export function pageApproving(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/pageApproving",
    method: "GET",
    params: data
  });
}
// 发薪审批-已通过：
export function pageApprovedPass(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/pageApprovedPass",
    method: "GET",
    params: data
  });
}
// ：发薪审批-已驳回：
export function pageApprovedReject(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/pageApprovedReject",
    method: "GET",
    params: data
  });
}
// 抄送我：
export function pageApproveNotifierMe(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/pageApproveNotifierMe",
    method: "GET",
    params: data
  });
}
export function getWeiXinList(params) {
  return request({
    url: "/hr/api/v1/transferAccountInfos/listAll",
    method: "GET",
    params: params
  });
}
export function sendCode(phone) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/sendSafetyPayPhoneMessage/".concat(phone),
    method: "GET"
  });
}
export function huaBeiPaySalary(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/zfbSafetyStartTransferAccounts",
    method: "post",
    data: data
  });
}
export function bankPaySalary(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/pingAnStartTransferAccounts",
    method: "post",
    data: data
  });
}
export var userApproDetails = function userApproDetails(moduleType) {
  return request({
    url: "/hr/api/v1/hrApproProviders/userApproDetails/".concat(moduleType),
    method: "get"
  });
};
export function returnAuthorizationUrl(params) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/returnAuthorizationUrl",
    method: "GET",
    params: params
  });
}
export function transferAccountsDetails(data) {
  return request({
    url: "/hr/api/v1/transferAccountsDetails",
    method: "get",
    params: data
  });
}
export function editAccountsDetails(data) {
  return request({
    url: "/hr/api/v1/transferAccountsDetails",
    method: "PUT",
    data: data
  });
}
export function weixinPaySalary(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/vxStartTransferAccounts",
    method: "post",
    data: data
  });
}
export function batchAccountsDetails(data) {
  return request({
    url: "/hr/api/v1/transferAccountsDetails/updateBatch",
    method: "PUT",
    data: data
  });
}
export function batchAccountsDetails2(data) {
  return request({
    url: "/hr/api/v1/transferAccountsDetails/updateBatchAndBatchInfo",
    method: "PUT",
    data: data
  });
}
//
export function paySalary(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/zfbStartTransferAccounts",
    method: "POST",
    data: data
  });
}
export function pageRefresh(transferAccountsBatchId) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/".concat(transferAccountsBatchId),
    method: "GET"
  });
}
export function getResults(salaryTransferAccountsBatchId) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/selectTransferAccountsBatch/".concat(salaryTransferAccountsBatchId),
    method: "GET"
  });
}
export function getAllByCompany(companyId) {
  return request({
    url: "/approval/api/v1/approFlows/allByCompany/".concat(companyId, "/hr?moduleType=hr&type=transferAccounts&inUse=true"),
    method: "get"
  });
}
export function approvalHandlerRejectReq(approvalId, data) {
  return request({
    url: "/approval/api/v1/userFlowApprovers/reject/".concat(approvalId),
    method: "put",
    data: data
  });
}
export function approvalHandlerAgreeReq(approvalId, data) {
  return request({
    url: "/approval/api/v1/userFlowApprovers/pass/".concat(approvalId),
    method: "put",
    data: data
  });
}
export function allGroupAndFlowByCompanySite() {
  return request({
    url: "/approval/api/v1/approFlowGroups/allGroupAndFlowByCompanySite/defaultTransferAccounts/hr",
    method: "get"
  });
}