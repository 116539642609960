import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.timers.js";
import moment from "moment";
export default {
  components: {
    totalCount: function totalCount() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./components/totalCount"));
      });
    },
    statistical: function statistical() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./components/statistical"));
      });
    },
    dataDetails: function dataDetails() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./components/dataDetails"));
      });
    },
    ginsengDetails: function ginsengDetails() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./components/ginsengDetails"));
      });
    }
  },
  created: function created() {
    var _this = this;
    setTimeout(function () {
      _this.handleChange("1"); // 点击一次时隔两秒后才能再次点击
    }, 200);
  },
  data: function data() {
    return {
      mode: ["month", "month"],
      value: ["".concat(moment().format("YYYY-MM")), "".concat(moment().format("YYYY-MM"))],
      startMonth: moment().format("MM"),
      endMonth: moment().format("MM"),
      startYear: moment().format("YYYY"),
      endYear: moment().format("YYYY"),
      dateBox: {
        startMonth: moment().format("MM"),
        endMonth: moment().format("MM"),
        startYear: moment().format("YYYY"),
        endYear: moment().format("YYYY"),
        step: -1
      },
      queryType: 1
    };
  },
  methods: {
    dateOk: function dateOk() {
      this.$refs.date.$refs.picker.sOpen = false;
    },
    handleChange: function handleChange(e) {
      this.queryType = parseInt(e);
      this.step = this.endYear - this.startYear - 1;
      this.dateBox = {
        startMonth: this.startMonth,
        endMonth: this.endMonth,
        startYear: this.startYear,
        endYear: this.endYear,
        step: this.step
      };
      console.log(this.queryType, this.step, this.dateBox, " this.queryType");
    },
    handlePanelChange2: function handlePanelChange2(value, mode) {
      this.value = value;
      this.startMonth = moment(value[0]).format("MM") ? moment(value[0]).format("MM") : moment().format("MM");
      this.startYear = moment(value[0]).format("YYYY") ? moment(value[0]).format("YYYY") : moment().format("YYYY");
      this.endMonth = moment(value[1]).format("MM") ? moment(value[1]).format("MM") : moment().format("MM");
      this.endYear = moment(value[1]).format("YYYY") ? moment(value[1]).format("YYYY") : moment().format("YYYY");
      this.step = this.endYear - this.startYear - 1;
      this.dateBox = {
        startMonth: this.startMonth,
        endMonth: this.endMonth,
        startYear: this.startYear,
        endYear: this.endYear,
        step: this.step
      };
    }
  }
};