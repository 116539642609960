var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "body page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "mb12 fs16 fw500"
  }, [_vm._v("用工单位")]), _c("a-row", {
    staticClass: "mg_t20 mg_b20",
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "名称/联系人/手机号",
      defaultValue: _vm.para,
      allowClear: ""
    },
    on: {
      search: _vm.onSearch
    }
  })], 1), _c("a-col", [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: _vm.goImport
    }
  }, [_vm._v(" 批量导入 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: _vm.addEmp
    }
  }, [_vm._v(" 添加用工单位 ")])], 1)], 1), _c("a-table", {
    attrs: {
      pagination: _vm.pagination,
      "data-source": _vm.dataSource,
      "row-key": function rowKey(dataSource) {
        return dataSource.id;
      },
      loading: _vm.tableLoading
    },
    on: {
      change: _vm.pageChange
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "用工单位名称",
      "data-index": "name",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text, record) {
        return _c("div", {
          staticStyle: {
            position: "relative"
          }
        }, [_vm._v(" " + _vm._s(text) + " "), !record.contractStatus ? _c("div", {
          staticClass: "band-wrapper"
        }) : _vm._e()]);
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      title: "联系人",
      "data-index": "legalPerson",
      align: "center"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "联系电话",
      "data-index": "telephone",
      align: "center"
    }
  }), _c("a-table-column", {
    attrs: {
      title: "操作",
      width: 212,
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.management(text);
            }
          }
        }, [_vm._v("设置")]), _c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.geEditor(text);
            }
          }
        }, [_vm._v("详情")])];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };