import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import { decimalPlacesOptions } from "./funcs";
import Draggable from "vuedraggable";
export default {
  name: "",
  components: {
    Draggable: Draggable
  },
  mixins: [],
  props: {
    id: {
      type: String,
      default: ""
    },
    wageItemLst: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    statItemLst: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    name: {
      type: String,
      default: ""
    },
    decimalPlaces: {
      type: [String, Number],
      default: ""
    }
  },
  data: function data() {
    return {
      decimalPlacesOptions: decimalPlacesOptions,
      sortedList: [],
      updateDict: {},
      keyword: "",
      nameTemp: "",
      decimalPlacesTemp: 4
    };
  },
  computed: {},
  watch: {
    wageItemLst: {
      handler: function handler() {
        this.initData();
      },
      immediate: true
    },
    statItemLst: {
      handler: function handler() {
        this.initData();
      },
      immediate: true
    },
    name: {
      handler: function handler(newVal) {
        this.nameTemp = newVal;
      },
      immediate: true
    },
    decimalPlaces: {
      handler: function handler(newVal) {
        this.decimalPlacesTemp = newVal;
      },
      immediate: true
    },
    nameTemp: function nameTemp(newVal) {
      this.$emit("update:name", newVal);
    },
    decimalPlacesTemp: function decimalPlacesTemp(newVal) {
      this.$emit("update:decimalPlaces", newVal);
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    onDragEnd: function onDragEnd(evt) {
      this.updateDict = Object.assign({}, this.sortedList.map(function (li) {
        return li.id;
      }));
    },
    initData: function initData() {
      if (Object.keys(this.updateDict).length > 0) {
        var itemDict = {};
        for (var i = 0; i < this.wageItemLst.length; i++) {
          var item = this.wageItemLst[i];
          itemDict[item.id] = item;
        }
        for (var _i = 0; _i < this.statItemLst.length; _i++) {
          var _item = this.statItemLst[_i];
          itemDict[_item.id] = _item;
        }
        var sortedArr = [];
        var sortedKeys = Object.values(this.updateDict);
        sortedKeys.sort();
        for (var _i2 = 0; _i2 < sortedKeys.length; _i2++) {
          var key = sortedKeys[_i2];
          if (itemDict[key]) {
            sortedArr.push(itemDict[key]);
            delete itemDict[key];
          }
        }
        var leftItems = Object.values(itemDict);
        if (leftItems && leftItems.length > 0) {
          sortedArr = [].concat(sortedArr, leftItems);
        }
        this.sortedList = sortedArr;
      } else {
        this.sortedList = [].concat(_toConsumableArray(this.wageItemLst), _toConsumableArray(this.statItemLst));
      }
    },
    removeItem: function removeItem(record) {
      if (record.statisticalFormula || record.statisticalFormula === null) {
        this.$emit("del", "tongji", record.id);
      } else {
        this.$emit("del", "gongzi", record.id);
      }
    },
    doSave: function doSave() {
      this.$emit("save", this.sortedList);
    }
  }
};