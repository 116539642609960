import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import { mapState } from "vuex";
import moment from "moment";
import { viewMission } from "@/api/company/news";
import ContentItem from "./common/content-item2";
export default {
  name: "Shebao",
  components: {
    ContentItem: ContentItem
  },
  data: function data() {
    return {
      pageSizeOptions: ["10", "20", "50", "100"],
      params: {},
      socPayCostTypeMap2: [],
      socPayCostTypeMap: [],
      totalOptions: {},
      contentList: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showQuickJumper: true
      }
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  created: function created() {
    // 菜单选中工作台
    this.$store.state.navtab.openKeys = [];
    this.$store.state.navtab.openKeys.push("/dashboard");
    this.$store.state.navtab.selectedKeys = [];
    this.$store.state.navtab.selectedKeys.push("/dashboard");
    if (JSON.stringify(this.$getCondition("jiedan_condition")) !== "{}") {
      this.params = JSON.parse(this.$getCondition("jiedan_condition"));
    }
    // 读取缓存
    if (this.$getPageSize("jiedan_condition")) {
      this.pagination.pageSize = this.params.pageSize = parseInt(this.$getPageSize("jiedan_condition")) === 1 ? 10 : parseInt(this.$getPageSize("jiedan_condition"));
      this.params.pageNo = this.pagination.current = parseInt(this.$getPage("jiedan_condition")) === 0 ? 1 : parseInt(this.$getPage("jiedan_condition"));
    }
    this.getZidan();
    this.getPerson();
    this.getShebaoTotal();
    this.getShebaoList();
    // this.gethasCanBaoMonth();
  },

  methods: {
    onReceived: function onReceived() {
      this.getShebaoTotal();
      this.getShebaoList();
    },
    // 获取模式
    getZidan: function getZidan() {
      var _this = this;
      this.$request({
        url: "/admin/api/v1/dictItems/selectAllDictItem",
        params: {
          dictCode: "1204",
          keyWord: this.keyWord
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.socPayCostTypeMap2 = data;
      });
    },
    handlePayCostTypeName2: function handlePayCostTypeName2(val) {
      this.params.missionMode = undefined;
      if (val) {
        for (var i = 0; i < this.socPayCostTypeMap2.length; i++) {
          if (this.socPayCostTypeMap2[i].id === val) {
            this.params.missionMode = this.socPayCostTypeMap2[i].dictItemValue;
          }
        }
      }
      // 缓存
      this.$setCondition("jiedan_condition", JSON.stringify(this.params));
      this.params.pageNo = 1;
      this.pagination.current = 1;
      this.getShebaoTotal();
      this.getShebaoList();
    },
    paidan: function paidan(index) {
      this.params.missionCenterState = index;
      this.params.pageNo = 1;
      this.pagination.current = 1;
      this.getShebaoTotal();
      this.getShebaoList();
      this.$setCondition("jiedan_condition", JSON.stringify(this.params));
    },
    getPerson: function getPerson() {
      var _this2 = this;
      this.$request({
        url: "/hr/api/v1/missionCenters/createUserList"
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this2.socPayCostTypeMap = data;
      });
    },
    // 按缴费类型搜
    handlePayCostTypeName: function handlePayCostTypeName(val) {
      this.params.createUserId = val;
      this.params.pageNo = 1;
      this.getShebaoTotal();
      this.getShebaoList();
    },
    timeChange: function timeChange(e) {
      this.pagination.current = 1;
      this.params.beginTime = e.length !== 0 ? e[0].valueOf() : null;
      this.params.endTime = e.length !== 0 ? e[1].valueOf() : null;
      this.params.pageNo = 1;
      this.getShebaoTotal();
      this.getShebaoList();
      // 缓存
      this.$setCondition("jiedan_condition", JSON.stringify(this.params));
    },
    disabledDate: function disabledDate(current) {
      //  Can not select days before today and today
      // const month = this.hasMonth.map(i => i.year + "-" + i.month);
      // const c = current.format("YYYY-MM");
      // return current && !month.includes(c);
      return false;
    },
    // 获取有参保的月份
    // gethasCanBaoMonth() {
    //   this.$request({
    //     url: `/hr/api/v1/empSocMonths/listYearAndMonth/${this.hr.companyId}`
    //   }).then(({ data }) => {
    //     this.hasMonth = data || [];
    //   });
    // },
    onShowSizeChange: function onShowSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.size = size;
      this.params.pageSize = size;
      this.params.pageNo = current;
      this.getShebaoList();
    },
    handleTotal: function handleTotal(e) {},
    handleContent: function handleContent(e) {
      var query = {
        paidancount: e.dispatchEmpCount,
        yiwanchengcount: e.completedCount,
        weiwanchengcount: e.unfinishedCount,
        yichangcount: e.abnormalCount,
        jiedanState: e.currentUserReceive,
        socDispatchId: e.id,
        title: moment(e.gmtCreate).format("YYYY年MM月") + e.missionName
      };
      localStorage.setItem("listjiedan", e.tableHead);
      // const path = e.addOrLess
      //   ? `/shebao/shebaoJieDan/shebao-add-list`
      //   : `/shebao/shebaoJieDan/shebao-add-list-less`;
      var path = "/shebao/shebaoJieDan/shebao-add-list";
      this.$router.push({
        path: path,
        query: query
      });
    },
    // 获取当月社保接单状况
    getShebaoTotal: function getShebaoTotal() {
      var _this3 = this;
      this.$request({
        url: "/hr/api/v1/missionCenters/selectAllReceiveTopStatistics",
        method: "GET",
        params: _objectSpread({}, this.params)
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this3.totalOptions = data;
      });
    },
    // 获取社保列表/hr/api/v1/socDispatches
    getShebaoList: function getShebaoList() {
      var _this4 = this;
      var para = _objectSpread({
        companyId: this.hr.companyId,
        // year: moment(this.date).format("YYYY"),
        // month: moment(this.date).format("MM"),
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.params);
      viewMission(para).then(function (res) {
        if (res.data && res.data.entities) {
          _this4.contentList = res.data.entities;
          _this4.pagination.total = res.data.entityCount;
          _this4.pagination.current = res.data.pageNo;
          _this4.pagination.pageSize = res.data.pageSize;
          // 缓存
          _this4.$setPage("jiedan_condition", _this4.pagination.current + "");
          _this4.$setPageSize("jiedan_condition", _this4.pagination.pageSize + "");
        }
      });
    },
    // 分页查询社保
    onChangePage: function onChangePage(e) {
      this.params.pageNo = e;
      // this.getShebaoTotal()
      // this.pagination.total = res.data.entityCount;
      this.pagination.current = e;
      this.getShebaoList();
    }
  }
};