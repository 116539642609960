import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { getEditEmpList, onlineEdit } from "@/api/wages/monthly";
import moment from "moment";
var columns1 = [{
  title: "姓名",
  dataIndex: "empName",
  key: "empName",
  width: 100
}, {
  title: "薪资组",
  dataIndex: "salaryGroupName",
  key: "salaryGroupName",
  width: 100
}
// {
//   title: "基本工资",
//   dataIndex: "fixedSalarySum",
//   key: "fixedSalarySum",
//   width: 100
// },
// {
//   title: "一次性费用",
//   dataIndex: "oneTimeFee",
//   key: "oneTimeFee",
//   width: 120
// },
// {
//   title: "工时",
//   dataIndex: "workTime",
//   key: "workTime",
//   width: 120
// }
];

var columns2 = [{
  title: "个人社保",
  dataIndex: "empSocialSecurity",
  key: "empSocialSecurity",
  width: 100
}, {
  title: "企业社保",
  dataIndex: "enterpriseSocialSecurity",
  key: "enterpriseSocialSecurity",
  width: 100
}, {
  title: "企业公积金",
  dataIndex: "enterpriseProvidentFund",
  key: "enterpriseProvidentFund",
  width: 120
},
// {
//   title: "应发工资",
//   dataIndex: "shouldWages",
//   key: "shouldWages",
//   width: 100
// },
{
  title: "个人所得税",
  dataIndex: "tax",
  key: "tax",
  width: 120
}
// {
//   title: "实发工资",
//   dataIndex: "paidWages",
//   key: "paidWages",
//   width: 100
// }
];

export default {
  data: function data() {
    return {
      maxDate: 30,
      id: null,
      visible: false,
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      columns: [],
      selectedRow: [],
      columns1: columns1,
      columns2: columns2,
      dataList: [],
      selfLength: 0
    };
  },
  created: function created() {
    var _this = this;
    this.selfLength = [];
    this.dataList = JSON.parse(sessionStorage.getItem("selectedRow"));
    this.columns = JSON.parse(sessionStorage.getItem("salaryColumns"));
    this.columns.forEach(function (item, index) {
      item.scopedSlots = {
        customRender: item.dataIndex
      };
      _this.selfLength.push(item.dataIndex);
    });
    console.log(this.dataList, this.columns, this.selfLength, "this.selectedRow ");
    // this.getEditEmp();
    //  scopedSlots: { customRender: `selfdef${item2.name}` }
    this.id = this.$route.query.id;
    this.maxDate = moment(this.$route.query.date, "YYYY-MM").daysInMonth();
    console.log(this.maxDate);
  },
  beforeDestory: function beforeDestory() {},
  methods: {
    limitNumber: function limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      } else {
        return 0;
      }
    },
    handleCancel: function handleCancel() {
      this.$router.go(-1);
    },
    handleSelfChange: function handleSelfChange($event, id, index) {
      var temp = JSON.parse(JSON.stringify(this.dataList));
      var index2 = temp.findIndex(function (item) {
        return item.id === id;
      });
      temp[index2]["".concat(index)] = $event;
      this.dataList = JSON.parse(JSON.stringify(temp));
    },
    handleSave: function handleSave() {
      var _this2 = this;
      // const data = {
      //   companyId: this.companyId,
      //   monthly: this.dataList[0].monthly,
      //   yearly: this.dataList[0].yearly,
      //   salaryTemplateSalaryId: this.dataList[0].salaryTemplateSalaryId,
      //   empSalaryList: []
      // };
      // this.dataList.forEach((item, index) => {
      //   for (const key in item) {
      //     if (key.substr(0, 7) === "selfdef") {
      //       const itemId = Number(key.substr(7));
      //       const itemIndex = item.itemDetail.findIndex((item2, index2) => {
      //         return index2 === itemId;
      //       });
      //       item.itemDetail[itemIndex].amount = item[`selfdef${itemId}`];
      //     }
      //   }
      // });
      // this.dataList.forEach((item, index) => {
      //   data.empSalaryList.push({
      //     empId: item.empId,
      //     itemDetail: item.itemDetail ? JSON.stringify(item.itemDetail) : null
      //   });
      // });
      console.log(this.dataList, this.columns);
      var arr = [];
      this.dataList.forEach(function (item2, index2) {
        var obj = {};
        _this2.columns.forEach(function (item, index) {
          obj["".concat(item.title)] = item2["".concat(item.dataIndex)];
        });
        arr.push(obj);
      });
      onlineEdit({
        nameContentMapList: arr,
        companyId: this.companyId,
        monthly: this.dataList[0].monthly,
        yearly: this.dataList[0].yearly,
        salaryTemplateSalaryId: this.dataList[0].salaryTemplateSalaryId
      }).then(function (res) {
        _this2.$router.go(-1);
        _this2.$message.success("保存成功");
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    getEditEmp: function getEditEmp() {
      var _this3 = this;
      getEditEmpList({
        employeeSalaryIds: this.$route.query.id
      }).then(function (res) {
        _this3.dataList = JSON.parse(JSON.stringify(res.data));
        _this3.columns = [];
        _this3.columns = _this3.columns.concat(JSON.parse(JSON.stringify(_this3.columns1)));
        _this3.selfLength = [];
        _this3.dataList.forEach(function (item, index) {
          if (item.itemDetail !== "") {
            item.itemDetail = JSON.parse(item.itemDetail);
            item.itemDetail.forEach(function (item2, index2) {
              item["selfdef".concat(index2)] = item2.amount;
              if (index === 0) {
                _this3.selfLength.push(item2.name);
                _this3.columns.push({
                  title: item2.name,
                  width: 120,
                  dataIndex: "selfdef".concat(item2.name),
                  key: "selfdef".concat(item2.name),
                  scopedSlots: {
                    customRender: "selfdef".concat(item2.name)
                  }
                });
              }
            });
          }
        });
        _this3.columns = _this3.columns.concat(JSON.parse(JSON.stringify(_this3.columns2)));
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    showEdit: function showEdit() {
      this.visible = true;
    }
  }
};