export default {
  fields: {
    name: {
      model: "name",
      type: "input",
      wrapper: {
        label: "姓名"
      },
      customize: {
        allowClear: false,
        placeholder: "搜索姓名"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    },
    companyName: {
      model: "companyName",
      type: "input",
      wrapper: {
        label: "公司"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入公司名称"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    },
    phone: {
      model: "phone",
      type: "input",
      wrapper: {
        label: "手机号"
      },
      customize: {
        allowClear: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    },
    email: {
      model: "email",
      type: "input",
      wrapper: {
        label: "邮箱"
      },
      customize: {
        allowClear: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    },
    cityName: {
      model: "cityName",
      type: "input",
      wrapper: {
        label: "城市"
      },
      customize: {
        allowClear: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    },
    registerReason: {
      model: "registerReason",
      type: "select",
      wrapper: {
        label: "注册原因"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择注册原因",
        options: [{
          value: 1,
          label: "试用参考"
        }, {
          value: 2,
          label: "学习了解"
        }, {
          value: 3,
          label: "商务合作"
        }]
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    },
    gmtCreate: {
      model: "gmtCreate",
      type: "date",
      wrapper: {
        label: "申请时间"
      },
      customize: {
        showTime: true
      },
      decorator: {
        initialValue: undefined
      }
    },
    leaveMessage: {
      model: "leaveMessage",
      type: "input",
      wrapper: {
        label: "留言"
      },
      customize: {
        allowClear: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "未填写"
        }]
      }
    },
    auditStatus: {
      model: "auditStatus",
      type: "select",
      wrapper: {
        label: "审核状态"
      },
      customize: {
        options: [{
          value: 1,
          label: "未审核"
        }, {
          value: 2,
          label: "已审核通过"
        }, {
          value: 3,
          label: "拒绝通过"
        }],
        placeholder: "请选择"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "拒绝原因必填"
        }]
      }
    },
    denialReason: {
      model: "denialReason",
      type: "textarea",
      wrapper: {
        label: "拒绝原因"
      },
      customize: {
        allowClear: true,
        placeholder: "如果拒绝通过，请填写拒绝原因"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "拒绝原因必填"
        }]
      }
    }
  },
  validators: {},
  config: {
    search: {
      fieldKeys: ["name", "companyName", "phone", "email", "cityName", "registerReason", "auditStatus"],
      customize: {
        layout: "inline",
        labelCol: {
          span: 8
        },
        wrapperCol: {
          span: 16
        }
      }
    },
    update: {
      fieldKeys: {
        auditStatus: {}
      }
    },
    table: {
      fields: [{
        model: "name",
        title: "姓名"
      }, {
        model: "companyName",
        title: "公司名称"
      }, {
        model: "phone",
        title: "手机"
      }, {
        model: "email",
        title: "邮箱"
      }, {
        model: "cityName",
        title: "城市"
      }, {
        model: "registerReason",
        title: "注册原因",
        width: 100
      }, {
        model: "gmtCreate",
        title: "申请时间",
        width: 200
      }, {
        model: "leaveMessage",
        title: "留言"
      }, {
        model: "auditStatus",
        title: "审核状态"
      }, {
        model: "denialReason",
        title: "拒绝原因"
      }],
      customize: {
        rowKey: "id"
      },
      extend: {
        batch: false,
        action: {
          width: 80
        }
      }
    }
  }
};