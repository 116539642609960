var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "赏金规则设置",
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visibleSet", false);
      }
    },
    model: {
      value: _vm.visibleSet,
      callback: function callback($$v) {
        _vm.visibleSet = $$v;
      },
      expression: "visibleSet"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-form-item", [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["brokerRule", {
        rules: [{
          required: true,
          message: "赏金规则不能为空"
        }]
      }],
      expression: "['brokerRule', { rules: [{ required: true, message: '赏金规则不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入赏金规则",
      rows: 4
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };