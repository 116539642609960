var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "revset"
  }, [_c("a-layout", [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c("a-row", {
    staticClass: "salary",
    attrs: {
      gutter: 5
    }
  }, [_c("a-col", {
    staticStyle: {
      width: "38%",
      padding: "24px",
      "text-align": "center",
      border: "1px solid #ccc",
      "border-radius": "4px"
    },
    attrs: {
      span: 1
    }
  }, [_c("a-row", {
    ref: "box",
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("a-col", {
    attrs: {
      span: 11
    },
    on: {
      click: function click($event) {
        return _vm.clickTab(-1);
      }
    }
  }, [_c("a-row", {
    staticClass: "primary"
  }, [_c("a-statistic", {
    attrs: {
      value: "合计"
    }
  })], 1)], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 11
    },
    on: {
      click: function click($event) {
        return _vm.clickTab(-1);
      }
    }
  }, [_c("a-row", [_c("strong", {
    class: _vm.state === -1 ? "primary" : "sub_tit"
  }, [_vm._v("总派单")])]), _c("a-row", {
    staticClass: "primary"
  }, [_c("a-statistic", {
    attrs: {
      value: _vm.paidancount
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function fn() {
        return [_vm._v("人")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c("a-col", {
    staticStyle: {
      width: "2%"
    },
    attrs: {
      span: 1
    }
  }), _c("a-col", {
    staticStyle: {
      width: "60%",
      padding: "24px",
      "text-align": "center",
      border: "1px solid #ccc",
      "border-radius": "4px"
    },
    attrs: {
      span: 1
    }
  }, [_c("a-row", {
    ref: "box",
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("a-col", {
    attrs: {
      span: 8
    },
    on: {
      click: function click($event) {
        return _vm.clickTab(1);
      }
    }
  }, [_c("a-row", [_c("strong", {
    class: _vm.state === 1 ? "primary" : "sub_tit"
  }, [_vm._v("已完成")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      value: _vm.yiwanchengcount
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function fn() {
        return [_vm._v("人")];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 7
    },
    on: {
      click: function click($event) {
        return _vm.clickTab(0);
      }
    }
  }, [_c("a-row", [_c("strong", {
    class: _vm.state === 0 ? "primary" : "sub_tit"
  }, [_vm._v("未完成")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      value: _vm.weiwanchengcount
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function fn() {
        return [_vm._v("人")];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-divider", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("a-col", {
    attrs: {
      span: 7
    },
    on: {
      click: function click($event) {
        return _vm.clickTab(2);
      }
    }
  }, [_c("a-row", [_c("strong", {
    class: _vm.state === 2 ? "primary" : "sub_tit"
  }, [_vm._v("异常")])]), _c("a-row", [_c("a-statistic", {
    attrs: {
      value: _vm.yichangcount
    },
    scopedSlots: _vm._u([{
      key: "suffix",
      fn: function fn() {
        return [_vm._v("人")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1), _c("a-row", {
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-input-search", {
    staticClass: "mg_b12",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请输入关键字"
    },
    on: {
      search: _vm.onSearchName
    }
  }), _c("div", [_c("a-button", {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      loading: _vm.loading,
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.daochu
    }
  }, [_vm._v(" 导出 ")]), !_vm.jiedanS ? _c("a-tooltip", {
    attrs: {
      placement: "top"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_vm._v("请先“确认接单”再进行反馈")])]), _c("a-button", {
    attrs: {
      disabled: true,
      loading: _vm.loading,
      type: "primary",
      ghost: ""
    }
  }, [_vm._v(" 批量反馈 ")])], 2) : _vm._e(), _vm.jiedanS ? _c("a-button", {
    attrs: {
      disabled: !_vm.hasSelected,
      loading: _vm.loading,
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.start
    }
  }, [_vm._v(" 批量反馈 ")]) : _vm._e(), _c("a-button", {
    staticClass: "pos",
    staticStyle: {
      "margin-left": "15px"
    },
    attrs: {
      icon: "file-text",
      type: "primary",
      disabled: _vm.jiedanS
    },
    on: {
      click: _vm.onConfi
    }
  }, [_vm._v(" 确认接单 ")])], 1)], 1), _c("div", [_c("a-table", {
    attrs: {
      "row-key": function rowKey(a, b) {
        return a.id;
      },
      columns: _vm.tcloums,
      "list-type": "picture",
      pagination: _vm.pagination,
      "data-source": _vm.dataSource,
      scroll: {
        x: 800
      },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function fn(text, record) {
        return [_c("span", {
          staticClass: "round",
          class: ["no", "ok", "err"][record.status]
        }), _vm._v(" " + _vm._s(["未完成", "已完成", "异常"][record.status]) + " ")];
      }
    }, {
      key: "socStartDate",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(_vm.moment(record.socStartDate).format("YYYY-MM-DD")) + " ")];
      }
    }, {
      key: "fundStartDate",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(_vm.moment(record.fundStartDate).format("YYYY-MM-DD")) + " ")];
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_vm.jiedanS ? _c("div", {
          staticClass: "primary",
          staticStyle: {
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.handelEvent(record);
            }
          }
        }, [_vm._v("反馈")]) : _c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v("请先“确认接单”再进行反馈")])]), _c("div", {
          staticStyle: {
            color: "#00000044"
          }
        }, [_vm._v("反馈")])], 2)];
      }
    }])
  })], 1), _c("feed-back", {
    ref: "child",
    attrs: {
      visible: _vm.feedVisible,
      options: _vm.feedOptions
    },
    on: {
      change: _vm.handleDetail
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };