import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-drawer", {
    attrs: {
      title: "添加津贴方案",
      placement: "right",
      closable: false,
      destroyOnClose: "",
      visible: _vm.visible,
      width: 540
    },
    on: {
      close: function close($event) {
        _vm.visible = false;
      }
    }
  }, [_c("a-form", [_c("a-form-item", {
    attrs: {
      label: "方案名称 :",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-input", {
    attrs: {
      maxLength: 50
    },
    model: {
      value: _vm.editorData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editorData, "name", $$v);
      },
      expression: "editorData.name"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "备注 :",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-input", {
    model: {
      value: _vm.editorData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.editorData, "remark", $$v);
      },
      expression: "editorData.remark"
    }
  })], 1), _c("div", {
    staticClass: "form_item bg_gray mb20"
  }, [_vm._v(" 员工薪资信息 "), _c("a-checkbox", {
    staticClass: "flr"
  }, [_vm._v("删除")])], 1), _vm._l(_vm.editorData.subsidyInfoDOList, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("a-form-item", {
      attrs: {
        label: "津贴名称 :",
        "label-col": {
          span: 5
        },
        "wrapper-col": {
          span: 15
        }
      }
    }, [_c("a-input", {
      model: {
        value: item.name,
        callback: function callback($$v) {
          _vm.$set(item, "name", $$v);
        },
        expression: "item.name"
      }
    })], 1), _c("a-form-item", {
      attrs: {
        label: "每月固定 :",
        "label-col": {
          span: 5
        },
        "wrapper-col": {
          span: 15
        }
      }
    }, [_c("a-select", {
      staticStyle: {
        width: "100%"
      },
      model: {
        value: item.sendDate,
        callback: function callback($$v) {
          _vm.$set(item, "sendDate", $$v);
        },
        expression: "item.sendDate"
      }
    }, _vm._l(_vm.day, function (d) {
      return _c("a-select-option", {
        key: d.key,
        attrs: {
          value: d.key
        }
      }, [_vm._v(" " + _vm._s(d.value) + " ")]);
    }), 1)], 1), _c("a-form-item", {
      attrs: {
        label: "金额 :",
        "label-col": {
          span: 5
        },
        "wrapper-col": {
          span: 15
        }
      }
    }, [_c("a-input", {
      model: {
        value: item.amount,
        callback: function callback($$v) {
          _vm.$set(item, "amount", $$v);
        },
        expression: "item.amount"
      }
    })], 1)], 1);
  }), _c("a-form-item", {
    staticClass: "sub_tit",
    attrs: {
      label: "适用计税方式 :",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-radio-group", {
    attrs: {
      options: _vm.radioOptions
    },
    model: {
      value: _vm.editorData.taxMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.editorData, "taxMethod", $$v);
      },
      expression: "editorData.taxMethod"
    }
  })], 1), _c("a-form-item", {
    staticClass: "sub_tit",
    attrs: {
      label: "适用出勤范围 :",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-switch", {
    model: {
      value: _vm.editorData.attendanceRange,
      callback: function callback($$v) {
        _vm.$set(_vm.editorData, "attendanceRange", $$v);
      },
      expression: "editorData.attendanceRange"
    }
  })], 1), _c("a-form-item", {
    staticClass: "sub_tit",
    attrs: {
      label: "适用聘用类型 :",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-checkbox-group", {
    model: {
      value: _vm.editorData.applyToProgVO.employType,
      callback: function callback($$v) {
        _vm.$set(_vm.editorData.applyToProgVO, "employType", $$v);
      },
      expression: "editorData.applyToProgVO.employType"
    }
  }, [_c("a-row", [_c("a-col", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      span: 8
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "6"
    }
  }, [_vm._v("正式")])], 1), _c("a-col", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      span: 8
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("实习")])], 1), _c("a-col", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      span: 8
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("劳务")])], 1), _c("a-col", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      span: 8
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("顾问")])], 1), _c("a-col", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      span: 8
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("返聘")])], 1), _c("a-col", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      span: 8
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "5"
    }
  }, [_vm._v("外包")])], 1)], 1)], 1)], 1), _c("a-form-item", {
    staticClass: "sub_tit",
    attrs: {
      label: "适用范围 :",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-checkbox-group", {
    model: {
      value: _vm.editorData.applyToProgVO.employRange,
      callback: function callback($$v) {
        _vm.$set(_vm.editorData.applyToProgVO, "employRange", $$v);
      },
      expression: "editorData.applyToProgVO.employRange"
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("按部门")])], 1), false ? _c("a-col", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("按职级")])], 1) : _vm._e(), _c("a-col", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("按岗位")])], 1)], 1)], 1)], 1), _vm.editorData.applyToProgVO.employRange.includes("1") ? _c("a-form-item", {
    staticClass: "sub_tit",
    attrs: {
      label: "适用范围 :",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 19
      }
    }
  }, [_c("a-popover", {
    attrs: {
      title: "添加部门或员工",
      placement: "leftBottom",
      trigger: "click"
    },
    model: {
      value: _vm.visible2,
      callback: function callback($$v) {
        _vm.visible2 = $$v;
      },
      expression: "visible2"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("a-tree", {
    staticStyle: {
      "min-width": "400px"
    },
    attrs: {
      treeData: _vm.treeData,
      replaceFields: _vm.replaceFields
    },
    on: {
      select: _vm.onSelect
    }
  })], 1), _c("a-button", {
    attrs: {
      icon: "plus-square"
    },
    on: {
      click: _vm.showDepPeo
    }
  }, [_vm._v(" 添加适用部门或员工")])], 2), _c("a-row", {
    attrs: {
      type: "flex"
    }
  }, _vm._l(_vm.editorData.applyToProgVO.applyToProgDepVOList, function (item, i) {
    return _c("a-col", {
      key: i,
      staticClass: "mg_b6"
    }, [item.type == 1 ? _c("a-tag", {
      attrs: {
        color: "#6A66F6"
      }
    }, [_vm._v(" 部门: " + _vm._s(item.empName) + " "), _c("a-icon", {
      attrs: {
        type: "close-circle"
      },
      on: {
        click: function click($event) {
          return _vm.delItem(item, _vm.editorData.applyToProgVO.applyToProgDepVOList);
        }
      }
    })], 1) : _vm._e(), item.type == 2 ? _c("a-tag", {
      attrs: {
        color: "green"
      }
    }, [_vm._v(" 员工:" + _vm._s(item.empName) + " "), _c("a-icon", {
      attrs: {
        type: "close-circle"
      },
      on: {
        click: function click($event) {
          return _vm.delItem(item, _vm.editorData.applyToProgVO.applyToProgDepVOList);
        }
      }
    })], 1) : _vm._e()], 1);
  }), 1)], 1) : _vm._e(), _vm.editorData.applyToProgVO.employRange.includes("3") ? _c("a-form-item", {
    staticClass: "sub_tit",
    attrs: {
      label: "适用范围 :",
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 19
      }
    }
  }, [_c("a-popover", {
    attrs: {
      placement: "leftBottom",
      title: "添加岗位",
      trigger: "click"
    },
    model: {
      value: _vm.visible3,
      callback: function callback($$v) {
        _vm.visible3 = $$v;
      },
      expression: "visible3"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("a-tree", {
    staticStyle: {
      "min-width": "400px"
    },
    attrs: {
      treeData: _vm.posList,
      replaceFields: _vm.replaceFieldsPos
    },
    on: {
      select: _vm.onSelectPos
    }
  })], 1), _c("a-button", {
    attrs: {
      icon: "plus-square"
    },
    on: {
      click: _vm.showGangwei
    }
  }, [_vm._v(" 添加适用岗位")])], 2), _c("a-row", {
    attrs: {
      type: "flex"
    }
  }, _vm._l(_vm.editorData.applyToProgVO.applyToProgPositionVOList, function (item, i) {
    return _c("a-col", {
      key: i
    }, [_c("a-tag", {
      attrs: {
        color: "cyan"
      }
    }, [_vm._v(" 岗位: " + _vm._s(item.positionName) + " "), _c("a-icon", {
      attrs: {
        type: "close-circle"
      },
      on: {
        click: function click($event) {
          return _vm.delItemGw(item, _vm.editorData.applyToProgVO.applyToProgPositionVOList);
        }
      }
    })], 1)], 1);
  }), 1)], 1) : _vm._e()], 2), _c("a-modal", {
    staticClass: "m_h99",
    attrs: {
      mask: "",
      width: 800,
      title: "添加员工",
      okText: "确认",
      cancelText: "取消"
    },
    on: {
      ok: _vm.handleOk
    },
    model: {
      value: _vm.childrenDrawer,
      callback: function callback($$v) {
        _vm.childrenDrawer = $$v;
      },
      expression: "childrenDrawer"
    }
  }, [_c("a-checkbox-group", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      indeterminate: true
    },
    on: {
      change: _vm.onChangeUser
    }
  }, [_c("a-row", _vm._l(_vm.users, function (item) {
    return _c("a-checkbox", {
      key: item.id,
      staticClass: "user_item mg_r10 mg_b10 pd10",
      staticStyle: {
        width: "30%",
        "margin-left": "0px"
      },
      attrs: {
        value: item.id
      }
    }, [_c("a-row", {
      attrs: {
        type: "flex",
        align: "middle"
      }
    }, [_c("a-col", {
      attrs: {
        span: 8
      }
    }, [_vm._v(" 员工 ")]), _c("a-col", {
      attrs: {
        span: 16
      }
    }, [_c("a-row", [_vm._v(_vm._s(item.name))]), _c("a-row", [_vm._v(_vm._s(item.phone))])], 1)], 1)], 1);
  }), 1)], 1)], 1), _c("footer", {
    staticClass: "footer"
  }, [_c("a-button", {
    staticClass: "btn_bag",
    on: {
      click: _vm.editor
    }
  }, [_vm._v("保存")]), _c("a-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("取消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };