import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { getToken } from "xqjr-module-auth";
import axios from "axios";
import qs from "qs";
export default {
  data: function data() {
    return {
      id: "",
      data: {},
      fileList: [],
      uploading: false,
      selectedRowKeys: [],
      headers: {},
      file: "",
      action: "",
      uploadImg: require("@/assets/img/uploadImg.png")
    };
  },
  computed: {},
  created: function created() {
    this.headers = {
      token: getToken(),
      "Content-Type": "multipart/form-data"
    };
    this.id = this.$store.state.hr.companyId;
    this.action = "/salary/api/v1/empFixedSalaries/import?companyId=".concat(this.id, "&force=false");
  },
  methods: {
    handleRemove: function handleRemove(file) {
      var index = this.fileList.indexOf(file);
      var newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload: function beforeUpload(file) {
      var _this = this;
      if (this.fileList.length > 0) {
        this.$message.error("最多只能上传一个");
        return false;
      }
      this.fileList = [].concat(_toConsumableArray(this.fileList), [file]);
      if (this.fileList && this.fileList.length > 0) {
        var fileList = this.fileList;
        var formData = new FormData();
        fileList.forEach(function (file) {
          formData.append("file", file);
        });
        this.uploading = true;
        axios({
          url: "/hr/api/v1/crmCustomerImportRecords/importFile",
          method: "post",
          data: formData,
          headers: this.headers
        }).then(function (res) {
          _this.handleSuccess(res.data);
          _this.uploading = false;
        });
      } else {
        this.handleUploadError();
      }
      return false;
    },
    handleUploadError: function handleUploadError() {
      // this.$message.warning("没有文件");
    },
    // handleFailClose() {
    //   this.dialogFailVisible = false;
    // },
    handleSuccess: function handleSuccess(res) {
      var _this2 = this;
      var that = this;
      if (res.code === 200) {
        if (res.data) {
          this.$confirm({
            okText: "确定",
            title: "\u4E0A\u4F20\u6210\u529F",
            onOk: function onOk() {
              that.$emit("onAction", 1, res.data);
            } // onCancel() {
            // that.Faildata = res.data.importFailList;
            // that.dialogFailVisible = true;
            // }
          });
        } else {
          this.$notification["error"]({
            message: "error",
            description: "上传失败"
          });
        }
      } else if (res.data === null) {
        this.$confirm({
          okText: "确定",
          title: res.msg
        });
      } else {
        this.$notification["error"]({
          message: "error",
          description: res.msg || "上传失败"
        });
      }
      setTimeout(function () {
        _this2.reset();
      }, 2000);
    },
    goback: function goback() {
      this.$router.go(-1);
    },
    download: function download() {
      var token = this.$store.state.auth.token;
      var params = {};
      params = {
        // templateName: "allEmployeeSalary.xlsx"
      };
      axios({
        url: "/hr/api/v1/crmCustomerImportRecords/downloadCustomerTemplate",
        params: params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "GET",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "客户导入模板.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("error log --> ", err);
      });
    },
    reset: function reset() {
      this.fileList = [];
    }
  }
};