import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
export default function (config) {
  var replaceFields = config.replaceFields;
  var RF = _objectSpread(_objectSpread({}, {
    key: "key",
    title: "title",
    children: "children"
  }), replaceFields);
  return {
    data: function data() {
      return {
        replaceFields: replaceFields,
        treeData: [],
        dataList: [],
        searchValue: "",
        autoExpandParent: true,
        expandedKeys: [],
        currentItem: null
      };
    },
    methods: {
      formatData: function formatData(sourceData, currentKey) {
        this.treeData = this.formatTreeData(sourceData);
        this.dataList = this.generateList(sourceData);
        if (currentKey) {
          this.currentItem = this.findNode(currentKey, this.treeData);
          this.initExpandedKeys(currentKey);
        } else {
          if (this.treeData.length > 0) {
            this.currentItem = this.treeData[0];
            this.expandedKeys = [];
          } else {
            this.currentItem = null;
            this.expandedKeys = [];
          }
        }
      },
      findNode: function findNode(targetKey, tree) {
        var target;
        for (var i = 0; i < tree.length; i++) {
          var node = tree[i];
          if (node[RF.key] === targetKey) {
            target = node;
            break;
          }
          if (node[RF.children]) {
            var res = this.findNode(targetKey, node[RF.children]);
            if (res) {
              target = res;
              break;
            }
          }
        }
        return target;
      },
      initExpandedKeys: function initExpandedKeys(targetKey) {
        var _this = this;
        var expandedKeys = this.dataList.map(function (item) {
          if (item[RF.key] === targetKey) {
            return _this.getParentKey(targetKey, _this.treeData);
          }
          return null;
        }).filter(function (item, i, self) {
          return item && self.indexOf(item) === i;
        });
        this.expandedKeys = expandedKeys;
      },
      onExpand: function onExpand(expandedKeys) {
        this.expandedKeys = expandedKeys;
        this.autoExpandParent = false;
      },
      getParentKey: function getParentKey(key, tree) {
        var parentKey;
        for (var i = 0; i < tree.length; i++) {
          var node = tree[i];
          if (node[RF.children]) {
            if (node[RF.children].some(function (item) {
              return item[RF.key] === key;
            })) {
              parentKey = node[RF.key];
            } else if (this.getParentKey(key, node[RF.children])) {
              parentKey = this.getParentKey(key, node[RF.children]);
            }
          }
        }
        return parentKey;
      },
      generateList: function generateList(tree) {
        var dLst = [];
        var gl = function gl(data) {
          for (var i = 0; i < data.length; i++) {
            var _dLst$push;
            var node = data[i];
            dLst.push((_dLst$push = {}, _defineProperty(_dLst$push, "".concat(RF.key), node[RF.key]), _defineProperty(_dLst$push, "".concat(RF.title), node[RF.title]), _dLst$push));
            if (node[RF.children]) {
              gl(node[RF.children]);
            }
          }
        };
        gl(tree);
        return dLst;
      },
      onSearchChange: function onSearchChange(e) {
        var _this2 = this;
        var value = e.target.value;
        var expandedKeys = this.dataList.map(function (item) {
          if (item[RF.title].indexOf(value) > -1) {
            return _this2.getParentKey(item[RF.key], _this2.treeData);
          }
          return null;
        }).filter(function (item, i, self) {
          return item && self.indexOf(item) === i;
        });
        Object.assign(this, {
          expandedKeys: expandedKeys,
          searchValue: value,
          autoExpandParent: true
        });
      },
      formatTreeData: function formatTreeData(tree) {
        var _this3 = this;
        if (!tree) {
          return [];
        }
        return tree.map(function (item) {
          var _objectSpread2;
          return _objectSpread(_objectSpread({}, item), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, "".concat(RF.children), _this3.formatTreeData(item[RF.children])), _defineProperty(_objectSpread2, "scopedSlots", {
            title: "custom"
          }), _objectSpread2));
        });
      }
    }
  };
}