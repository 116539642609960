import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack",
    staticStyle: {
      height: "calc(100vh - 112px)",
      "overflow-y": "auto"
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回",
      id: "target"
    },
    on: {
      back: function back($event) {
        return _vm.goback();
      }
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.moduleCode === "onJob" ? "入职" : _vm.moduleCode === "waitJob" ? "待入职" : "预登记") + "模板")])]), this.type === "3" ? _c("div", {
    staticClass: "pt12 ph24 fs12"
  }, [_c("a-alert", {
    attrs: {
      banner: "",
      closable: ""
    }
  }, [_c("div", {
    attrs: {
      slot: "message"
    },
    slot: "message"
  }, [_vm._v("若想自定义信息字段 请前往员工字段设置添加 "), _c("a", {
    attrs: {
      href: "#/staff/personalInfo/1"
    }
  }, [_vm._v("前往创建")])])])], 1) : _vm._e(), _c("div", {
    staticClass: "parentBox pt12"
  }, [_c("div", {
    staticClass: "leftBox"
  }, [_vm._m(0), _c("div", {
    staticClass: "mt12 mb12"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      allowClear: "",
      placeholder: "搜索"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.term,
      callback: function callback($$v) {
        _vm.term = $$v;
      },
      expression: "term"
    }
  })], 1), _vm.showingList && _vm.showingList.length > 0 ? _c("div", {
    staticClass: "collapseShow",
    staticStyle: {
      height: "calc(100vh - 350px)",
      "overflow-y": "auto"
    }
  }, [_c("a-collapse", {
    attrs: {
      "active-key": _vm.newAllFieldKeys,
      bordered: false
    },
    scopedSlots: _vm._u([{
      key: "expandIcon",
      fn: function fn(props) {
        return [_c("a-icon", {
          attrs: {
            type: "caret-right",
            rotate: props.isActive ? 90 : 0
          }
        })];
      }
    }], null, false, 766930589)
  }, _vm._l(_vm.showingList, function (z) {
    return _c("a-collapse-panel", {
      key: z.moduleName,
      style: _vm.customStyle,
      attrs: {
        header: z.moduleName
      }
    }, [_c("div", {
      staticClass: "nthClid"
    }, [_c("Draggable", {
      attrs: {
        group: {
          name: "form-draggable",
          pull: "clone",
          put: false
        },
        sort: false,
        animation: 180,
        ghostClass: "moving",
        value: z.fields,
        clone: _vm.addFieldNameMove,
        filter: ".disabled",
        tag: "div"
      }
    }, [_vm._l(z.fields, function (i) {
      return [["probationDays", "turnStraightDate"].includes(i.fieldCode) ? _c("div", {
        key: i.id,
        staticClass: "disabled wid45"
      }, [_c("a-tooltip", {
        attrs: {
          placement: "top",
          title: "".concat(i.fieldName, "\u4E0D\u53EF\u5355\u72EC\u9009\u62E9\uFF0C\u9009\u4E2D\u5458\u5DE5\u72B6\u6001\u540E\uFF0C\u5C06\u81EA\u52A8\u9009\u62E9").concat(i.fieldName)
        }
      }, [_c("div", {
        staticClass: "leftBoxCon",
        staticStyle: {
          background: "#f5f5f5",
          color: "rgba(0, 0, 0, 0.25)"
        }
      }, [_c("div", {
        staticClass: "ovHid"
      }, [_vm._v(_vm._s(i.fieldName))])])])], 1) : _c("div", {
        key: i.id,
        staticClass: "wid45",
        class: i.disabled ? "disabled" : ""
      }, [_c("div", {
        staticClass: "leftBoxCon"
      }, [i.isEdit === 0 ? _c("span", {
        staticClass: "icon iconfont icon-xuanzhong iconShow"
      }) : _vm._e(), _c("div", {
        staticClass: "ovHid",
        on: {
          click: function click($event) {
            return _vm.addFieldName(i);
          }
        }
      }, [_c("a-tooltip", {
        attrs: {
          placement: "top",
          title: i.fieldName
        }
      }, [_vm._v(_vm._s(i.fieldName))])], 1)])])];
    })], 2)], 1)]);
  }), 1)], 1) : _vm._e()]), _c("div", {
    staticClass: "rightBox"
  }, [_c("a-spin", {
    attrs: {
      size: "large",
      spinning: _vm.spinning
    }
  }, [_c("inTemplateForm", {
    ref: "basic",
    staticClass: "pt24",
    attrs: {
      dataSource: _vm.basicData,
      status: _vm.status === "edit" && JSON.parse(this.$route.query.isDefault) ? true : false,
      name: _vm.name,
      dynamicData: {},
      formFields: _vm.basicFormJson.fields,
      sortedKeysNew: _vm.basicFormJson.sortedKeys,
      formConfig: {}
    },
    on: {
      changItemVal: _vm.changItemVal,
      change: _vm.dynamicChange,
      scrollTop: _vm.scrollToTop,
      dragAdd: _vm.onDragAdd,
      dragEnd: _vm.onDragEnd
    }
  })], 1)], 1)])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "leftBoxTop"
  }, [_c("span", {
    staticClass: "leftBoxTopAddText"
  }, [_vm._v("录入信息选择")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };