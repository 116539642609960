import request from "@/utils/request";

// export function getAttendanceDataInfo(params) {
//   return request({
//     url: "/hr/api/v1/attendanceDataTemps/getAttendanceDataInfo",
//     method: "GET",
//     params
//   });
// }
export function getAttendanceDataInfo(params) {
  return request({
    url: "/hr/api/v1/attendanceDataTemps/attendanceDataInfo",
    method: "GET",
    params: params
  });
}

// export function getWorkHoursInfoById(attendanceDataTempId) {
//   return request({
//     url: `/hr/api/v1/attendanceDataTemps/getWorkHoursInfoById/${attendanceDataTempId}`,
//     method: "GET"
//   });
// }
export function getWorkHoursInfoById(attendanceDataTempId) {
  return request({
    url: "/hr/api/v1/attendanceDataTemps/workHoursInfoById/".concat(attendanceDataTempId),
    method: "GET"
  });
}
export function delAttendanceDataTemps(attendanceDataTempId) {
  return request({
    url: "/hr/api/v1/attendanceDataTemps/".concat(attendanceDataTempId),
    method: "delete"
  });
}