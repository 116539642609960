var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-row", {
    staticClass: "head"
  }, [_vm.backLock ? _c("a-icon", {
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("back");
      }
    }
  }) : _vm._e(), _vm.backLock ? _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("back");
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.subTitle) + " "), _vm._t("subTitle")], 2) : _vm._e(), _vm._t("default")], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };