var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("div", {
    staticClass: "xq-form-search"
  }, [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "职位名称"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.postName,
      callback: function callback($$v) {
        _vm.postName = $$v;
      },
      expression: "postName"
    }
  }), _c("city", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      "default-value": _vm.socCity,
      level: 2
    },
    on: {
      change: function change($event) {
        return _vm.handleCity($event);
      }
    }
  }), _c("div", {
    staticStyle: {
      "text-align": "right",
      "margin-top": "-32px"
    }
  }, [_c("a-space", [_c("a-button", {
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: function click($event) {
        _vm.visible = true;
      }
    }
  }, [_vm._v(" 新建经纪人职位 ")])], 1)], 1)], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      "row-selection": _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      } : null
    },
    on: {
      change: _vm.onTableChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "gmtCreate" ? _c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(_vm.moment(text).format("YYYY-MM-DD")) + " ")]) : col.dataIndex === "cityName" ? _c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(text ? text.split(",")[1] : "") + " ")]) : col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("a", {
            on: {
              click: function click($event) {
                return _vm.onEdit(record);
              }
            }
          }, [_vm._v("编辑赏金规则")]), _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a-popconfirm", {
            attrs: {
              title: "\u786E\u8BA4\u8981".concat(record.isBroker === 1 ? "关闭" : "打开", "\u5417\uFF1F"),
              "ok-text": "确认",
              "cancel-text": "取消"
            },
            on: {
              confirm: function confirm($event) {
                return _vm.onClose(record.id, record.isBroker);
              }
            }
          }, [_c("a", [_vm._v(_vm._s(record.isBroker === 1 ? "关闭" : "打开"))])])], 1) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1), _c("selectPeople", {
    attrs: {
      visible: _vm.visible,
      type: "isBroker"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      upDate: _vm.upDateSelect
    }
  }), _c("sets", {
    attrs: {
      visibleSet: _vm.visibleSet,
      selectedListTempString: _vm.selectedListTempString
    },
    on: {
      "update:visibleSet": function updateVisibleSet($event) {
        _vm.visibleSet = $event;
      },
      "update:visible-set": function updateVisibleSet($event) {
        _vm.visibleSet = $event;
      },
      upDate: _vm.upDate
    }
  }), _c("edit", {
    attrs: {
      visibleSet: _vm.visibleEdit,
      id: _vm.id,
      val: _vm.val
    },
    on: {
      "update:visibleSet": function updateVisibleSet($event) {
        _vm.visibleEdit = $event;
      },
      "update:visible-set": function updateVisibleSet($event) {
        _vm.visibleEdit = $event;
      },
      upDate: _vm.upDate
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };