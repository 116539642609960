import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import moment from "moment";
import { getListAllRelation, addBatchNormalSoc } from "@/api/shebao/shebaoInfo.js";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    arr: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    value: function value(v) {
      this.isCloseMethods = v;
      if (v) {
        this.staffList = this.arr;
      }
    }
  },
  data: function data() {
    return {
      isCloseMethods: false,
      year: "",
      month: "",
      monthFormat: "YYYY-MM",
      typeValue: 1,
      defaultFangan: 0,
      progNameLists: [],
      defaultCard: 0,
      formData: [],
      isShebaoBase: false,
      payProvidentFund: false,
      // 是否缴纳公积金
      isGongJJBase: true,
      defaultGongjiJin: 0,
      onlySocAmount: false,
      Dvisible: false,
      staffList: []
    };
  },
  created: function created() {
    this.checkShebaoMethod();
    this.init();
  },
  methods: {
    handleCancels: function handleCancels() {
      this.isCloseMethods = false;
      this.$emit("input", false);
    },
    onYearMonthChange: function onYearMonthChange(date, dateString) {
      var s = dateString.split("-");
      this.year = s[0];
      this.month = s[1];
    },
    disabledDates: function disabledDates(current) {
      // 只能选择当月往后的月份
      return current && current < moment().startOf("month"); // 月份第一天
    },
    // 业务类型选择
    onTypeChange: function onTypeChange(e) {
      this.typeValue = e.target.value;
    },
    // 批量正常参保
    onBatchNormalSoc: function onBatchNormalSoc() {
      // batchNormalSoc({
      //   companyId: this.$store.state.hr.companyId,
      //   empIdList: this.selectedRowKeys
      // })
      //   .then(res => {
      //     this.selectedRowKeys = [];
      //     this.getList();
      //     this.getEnum();
      //     this.getSelectEmpSocListCount();
      //     this.$message.success("批量正常参保成功!");
      //   })
      //   .catch(err => {
      //     this.$message.error("批量正常参保失败!");
      //     console.log("debug log --> ", err);
      //   });
    },
    // 创建参保选择方案
    chooseProgNames: function chooseProgNames(value) {
      var _this = this;
      this.defaultFangan = value;
      this.progNameLists.map(function (item) {
        if (item.id === _this.defaultFangan) {
          _this.progId = _this.defaultFangan;
          _this.formData = _this.deepCopy(item.socProgItemVOList);
          _this.payProvidentFund = item.payProvidentFund;
          _this.onlySocAmount = item.onlySocAmount;
          _this.siteId = item.siteId;
          _this.progKeyId = item.id;
        }
      });
      var d = -1;
      var d2 = -1;
      for (var i = 0; i < this.formData.length; i++) {
        var item = this.formData[i];
        if (item.itemType === 1) {
          // 社保
          if (this.onlySocAmount) {
            if (d === -1) {
              d = item.cardinalityDefaultAmount;
            }
            if (d !== item.cardinalityDefaultAmount) {
              // 展开
              this.isShebaoBase = false;
              return;
            }
          } else {
            if (d === -1) {
              d = item.cardinalityDefault;
            }
            if (d !== item.cardinalityDefault) {
              // 展开
              this.isShebaoBase = false;
              return;
            }
          }
        } else if (item.itemType === 2) {
          // 公积金
          if (this.onlySocAmount) {
            if (d2 === -1) {
              d2 = item.cardinalityDefaultAmount;
            }
            if (d2 !== item.cardinalityDefaultAmount) {
              // 展开
              this.isGongJJBase = false;
              return;
            }
          } else {
            if (d2 === -1) {
              d2 = item.cardinalityDefault;
            }
            if (d2 !== item.cardinalityDefault) {
              // 展开
              this.isGongJJBase = false;
              return;
            }
          }
        }
      }
    },
    // 设置社保基数
    setAllContent: function setAllContent(val) {
      var _this2 = this;
      this.formData.map(function (item, index) {
        if (item.itemType === 1) {
          if (_this2.onlySocAmount) {
            item.cardinalityDefaultAmount = _this2.defaultCard;
          } else item.cardinalityDefault = _this2.defaultCard;
        }
      });
    },
    // 编辑参保各项目基数
    editBaseInfo: function editBaseInfo() {
      this.isShebaoBase = false;
    },
    returnEdit: function returnEdit() {
      this.isShebaoBase = true;
    },
    finishEdit: function finishEdit() {
      if ((this.defaultGongjiJin + "").trim() === "") {
        this.$message.success("公积金基数不能为空");
        return;
      }
      if ((this.defaultCard + "").trim() === "") {
        this.$message.success("个人社保基数不能为空");
        return;
      }
      console.log(this.defaultFangan);
      var that = this;
      if (!this.editPlan) {
        if (this.defaultFangan === 0) {
          that.$message.success("请先创建参保方案");
          return;
        } else {
          // 弹出添加增减员下一步对话框
          this.Dvisible = true;
        }
      }
      this.isCloseMethods = false;
      this.$emit("input", false);
    },
    returnEditGJ: function returnEditGJ() {
      this.isGongJJBase = true;
    },
    setGongJiJinContent: function setGongJiJinContent(val) {
      var _this3 = this;
      this.formData.map(function (item, index) {
        if (item.itemType === 2) {
          if (_this3.onlySocAmount) {
            item.cardinalityDefaultAmount = _this3.defaultGongjiJin;
          } else item.cardinalityDefault = _this3.defaultGongjiJin;
        }
      });
    },
    editBaseInfoGJ: function editBaseInfoGJ() {
      this.isGongJJBase = false;
    },
    DhandleCancel: function DhandleCancel() {
      this.isCloseMethods = true;
      this.$emit("input", true);
      this.Dvisible = false;
    },
    DhandleOk: function DhandleOk() {
      this.Dvisible = false;
      this.finishEdit2();
    },
    finishEdit2: function finishEdit2() {
      var _this4 = this;
      if (typeof this.month === "number" && this.month < 10) {
        this.month = "0" + this.month;
      }
      if (this.progId || this.progId === "") {
        var ids = [];
        this.staffList.map(function (item) {
          ids.push(item);
        });
        this.isCloseMethod = false;
        var d = ids.map(function (empId) {
          return {
            onlySocAmount: _this4.onlySocAmount,
            payProvidentFund: _this4.payProvidentFund,
            companyId: _this4.$store.state.hr.companyId,
            // monthId: this.id,
            empId: empId,
            year: _this4.year,
            month: _this4.month,
            empSocItemDTOList: _this4.formData,
            empSocStatus: 1,
            progId: _this4.progId,
            businessType: _this4.typeValue,
            planFlag: true
          };
        });
        addBatchNormalSoc({
          empSocInfoAddDTOList: d
        }).then(function (res) {
          _this4.progId = "";
          if (res.code === 200) {
            _this4.Lvisible = true;
            _this4.Dvisible = false;
            _this4.checkShebaoMethod();
            _this4.init();
            _this4.$emit("tap");
          }
        });
      }
      this.init(2);
    },
    init: function init(type) {
      var _this5 = this;
      var nowDate = new Date();
      this.year = "" + nowDate.getFullYear();
      this.month = nowDate.getMonth() + 1;
      this.formData = [];
      this.defaultFangan = 0;
      this.isShebaoBase = true;
      this.isGongJJBase = true;
      if (type === 1) {
        this.dataDetail.map(function (item) {
          if (item.id === _this5.keyId) _this5.evprogName = item.progName;
        });
      }
      if (type === 2) {
        this.evprogName = "";
      }
    },
    // 深拷贝，解决引用赋值
    deepCopy: function deepCopy(arr) {
      var newArr = [];
      arr.forEach(function (item, index) {
        var record = {};
        for (var k in item) {
          record[k] = item[k];
        }
        newArr.push(record);
      });
      return newArr;
    },
    // 查询参保方案
    checkShebaoMethod: function checkShebaoMethod() {
      var _this6 = this;
      getListAllRelation({
        companyId: this.$store.state.hr.companyId
      }).then(function (res) {
        _this6.progNameLists = res.data;
        if (_this6.progNameLists.length !== 0) {
          _this6.defaultFangan = _this6.progNameLists[0].id;
          _this6.progNameLists.map(function (item) {
            if (item.id === _this6.defaultFangan) {
              _this6.progId = _this6.defaultFangan;
              _this6.formData = _this6.deepCopy(item.socProgItemVOList);
              _this6.payProvidentFund = item.payProvidentFund;
              _this6.onlySocAmount = item.onlySocAmount;
              _this6.siteId = item.siteId;
              _this6.progKeyId = item.id;
            }
          });
        }
      });
    },
    handleCancel: function handleCancel() {
      this.init(2);
      this.isCloseMethods = false;
      this.Dvisible = false;
      this.$emit("input", false);
    },
    // 改变社保基数
    changeDefault: function changeDefault(val, index) {
      if (this.onlySocAmount) {
        this.formData[index].cardinalityDefaultAmount = val;
      } else this.formData[index].cardinalityDefault = val;
    }
  }
};