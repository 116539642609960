var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white br4 wp100 p20"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "row",
      "justify-content": "space-between"
    }
  }, [_vm._m(0), _c("a-dropdown", {
    attrs: {
      placement: "topRight"
    }
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "16px",
      color: "#a8a8a8"
    }
  }, [_vm._v("•")]), _c("span", {
    staticStyle: {
      "margin-left": "0px",
      "font-size": "16px",
      color: "#a8a8a8"
    }
  }, [_vm._v("•")]), _c("span", {
    staticStyle: {
      "margin-left": "0px",
      "font-size": "16px",
      color: "#a8a8a8"
    }
  }, [_vm._v("•")])]), _c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    on: {
      click: function click($event) {
        return _vm.showEdit();
      }
    }
  }, [_vm._v(" 快捷入口设置 ")])], 1)], 1)], 1), _c("div", {
    staticClass: "employ-wrap",
    staticStyle: {
      "margin-top": "0"
    }
  }, _vm._l(_vm.entryList, function (list) {
    return _c("div", {
      key: list.value,
      staticClass: "tapButtonBox flex",
      staticStyle: {
        "margin-top": "0"
      }
    }, [_c("div", {
      staticClass: "employ-right flex",
      staticStyle: {
        "justify-content": "space-around",
        width: "100%",
        "margin-top": "20px",
        "margin-bottom": "20px"
      }
    }, _vm._l(list, function (item) {
      return _c("div", {
        key: item.value,
        ref: "tapButton",
        refInFor: true,
        staticClass: "tapButton pointer flex flex-col justify-content align-items-center",
        style: {
          "flex-wrap": "no-wrap",
          visibility: !_vm.getshow(item) ? "hidden" : ""
        },
        on: {
          click: function click($event) {
            _vm.getshow(item) ? _vm.goEntry(item.url) : null;
          }
        }
      }, [_c("div", {
        staticClass: "tap tapTitle"
      }, [_c("img", {
        staticStyle: {
          width: "55px",
          height: "55px"
        },
        attrs: {
          src: item.img
        }
      })]), _c("span", {
        staticClass: "tap tapSpan"
      }, [_vm._v(_vm._s(item.label))])]);
    }), 0)]);
  }), 0), _c("a-modal", {
    attrs: {
      title: "快捷入口设置",
      visible: _vm.quickEdit,
      width: "690px"
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.seveQuick
    }
  }, [_vm._v("保存")])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "row",
      "align-items": "center",
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "14px",
      "font-weight": "700"
    }
  }, [_vm._v("已选择模块")]), _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "12px",
      "margin-left": "5px"
    }
  }, [_vm._v("可拖拽菜单调整显示顺序，最多6个")])]), _c("div", {
    staticClass: "overview-tag-wrapper",
    staticStyle: {
      display: "flex",
      width: "100%",
      "flex-direction": "row"
    }
  }, [_c("draggable", {
    attrs: {
      filter: ".forbid",
      animation: "3000",
      move: _vm.onMove
    },
    on: {
      start: _vm.onStart,
      end: _vm.onEnd
    },
    model: {
      value: _vm.tempEntryListNet[0],
      callback: function callback($$v) {
        _vm.$set(_vm.tempEntryListNet, 0, $$v);
      },
      expression: "tempEntryListNet[0]"
    }
  }, [_c("transition-group", _vm._l(_vm.tempEntryListNet[0], function (item, index) {
    return _c("a-tag", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.label !== "操作",
        expression: "item.label !== '操作'"
      }],
      key: item.value,
      class: item.label !== "姓名" ? "overview-select-tag" : "overview-select-tag-name",
      staticStyle: {
        "padding-left": "20px",
        "padding-right": "20px",
        "padding-top": "10px",
        "padding-bottom": "10px",
        cursor: "move"
      },
      attrs: {
        closable: false,
        color: item.label !== "姓名" ? "#fff" : "#E8E8E8"
      }
    }, [_c("div", {
      staticStyle: {
        position: "absolute",
        "z-index": "10",
        "margin-top": "-10px"
      },
      on: {
        click: function click($event) {
          return _vm.deleteSelectedHead(index);
        }
      }
    }, [_c("div", {
      staticStyle: {
        "line-height": "0.3",
        height: "20px",
        width: "20px",
        "margin-left": "50px",
        "padding-top": "6px",
        "padding-left": "6px",
        color: "#fff",
        "font-size": "18px",
        cursor: "pointer",
        "border-radius": "50%",
        "background-color": "#00000055"
      }
    }, [_vm._v(" x ")])]), _c("div", {
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        "align-items": "center"
      }
    }, [_c("img", {
      staticStyle: {
        width: "55px",
        height: "55px",
        "margin-bottom": "8px"
      },
      attrs: {
        src: item.img
      }
    }), _c("div", {
      staticStyle: {
        "font-size": "14px",
        color: "#55565d"
      }
    }, [_vm._v(_vm._s(item.label))])])]);
  }), 1)], 1)], 1), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "14px",
      "margin-bottom": "20px",
      "font-weight": "700",
      "margin-top": "20px"
    }
  }, [_vm._v("点击添加模块")]), _c("div", {
    staticStyle: {
      width: "100%",
      display: "flex",
      "flex-direction": "row",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.tempEntryListNet[1], function (item, index) {
    return _c("a-tag", {
      key: item.value,
      class: item.label !== "姓名" ? "overview-select-tag" : "overview-select-tag-name",
      staticStyle: {
        "padding-left": "20px",
        "padding-right": "20px",
        "padding-top": "10px",
        "padding-bottom": "10px"
      },
      attrs: {
        color: item.label !== "姓名" ? "#fff" : "#E8E8E8"
      },
      on: {
        click: function click($event) {
          return _vm.addSelectedHead(index);
        }
      }
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        "align-items": "center"
      }
    }, [_c("img", {
      staticStyle: {
        width: "55px",
        height: "55px",
        "margin-bottom": "8px"
      },
      attrs: {
        src: item.img
      }
    }), _c("div", {
      staticStyle: {
        "font-size": "14px",
        color: "#55565d"
      }
    }, [_vm._v(_vm._s(item.label))])])]);
  }), 1)])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex align-items-center",
    staticStyle: {
      "margin-bottom": "30px"
    }
  }, [_c("img", {
    staticClass: "width24px height24px",
    attrs: {
      src: require("@/assets/img/icon.png"),
      alt: ""
    }
  }), _c("span", {
    staticClass: "font-3C3D43-16px ml8"
  }, [_vm._v("快捷入口")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };