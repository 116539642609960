import _createForOfIteratorHelper from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
/*
 * @Description: 文件上传
 * @Author: zhr
 */
import request from "@/utils/request";
export function uploadGeneralImg(data) {
  return request({
    url: "/file/api/v1/general/uploadImg",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
}
export function uploadGeneralFile(data) {
  return request({
    url: "/file/api/v1/general/upload/attachment",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
}
export function uploadSiteImg(data) {
  return request({
    url: "/file/api/v1/site/uploadImg",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
}
export function uploadSiteFile(data) {
  return request({
    url: "/file/api/v1/site/upload/attachment",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
}

// 上传文件
export function uploadFile(data) {
  return request({
    url: "/file/api/v1/general/uploadImg",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
}
// 上传附件文件到默认资料库
export function uploadAttachmentFiles(data) {
  return request({
    url: "/file/provider/v1/roles/uploadAttachmentFiles",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
}
// 保存附件文件关联记录
export function saveSubjectAttachment(data) {
  var fileIds = "/";
  var fileIdArr = data.fileIds;
  var _iterator = _createForOfIteratorHelper(fileIdArr),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var id = _step.value;
      fileIds += id;
      if (fileIdArr.indexOf(id) !== fileIdArr.length - 1) {
        fileIds += ",";
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return request({
    url: "/file/provider/v1/roles/saveSubjectAttachment/" + data.subjectId + "/" + data.subjectType + fileIds,
    method: "post",
    data: data
  });
}

// 根据业务id，和业务类型查询文件集合
export function getAttachment(data) {
  return request({
    url: "/file/provider/v1/roles/getFileList/" + data.subjectId + "/" + data.subjectType,
    method: "get",
    data: data
  });
}