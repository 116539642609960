var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "选择人员",
      width: "1200px"
    },
    on: {
      ok: _vm.doExport
    },
    model: {
      value: _vm.valueTemp,
      callback: function callback($$v) {
        _vm.valueTemp = $$v;
      },
      expression: "valueTemp"
    }
  }, [_c("div", {
    staticClass: "wrap"
  }, [_c("ListSearchForBox", {
    staticClass: "left",
    attrs: {
      eventTypeLst: _vm.eventTypeLst,
      templateName: _vm.templateName
    },
    on: {
      search: _vm.onSearch
    }
  }), _c("div", {
    staticClass: "table"
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      dataSource: _vm.dataLst,
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      },
      pagination: _vm.pagination,
      rowKey: "id",
      size: "middle"
    },
    on: {
      change: _vm.handleTableChange
    }
  })], 1), _c("div", {
    staticClass: "list"
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "16px"
    }
  }, [_vm._v(" 已选择 "), _c("span", {
    staticStyle: {
      color: "var(--primary)"
    }
  }, [_vm._v(_vm._s(_vm.selectedRows.length))]), _vm._v(" 人 ")]), _vm._l(_vm.selectedRows, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "item"
    }, [_c("span", [_vm._v(_vm._s(item.empName) + " " + _vm._s(item.empIdCard))]), _c("a-icon", {
      staticStyle: {
        color: "#d7d7d7",
        cursor: "pointer"
      },
      attrs: {
        type: "close-circle",
        theme: "filled"
      },
      on: {
        click: function click($event) {
          return _vm.delSelected(item.id);
        }
      }
    })], 1);
  })], 2)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };