import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
export default {
  name: "Toggle",
  data: function data() {
    return {
      isInstall: false,
      title: "",
      timer: "",
      dat_info: [],
      isUp: true,
      mindex: 0,
      treeData: [[[{
        index: 0,
        num: "01",
        title: "快速创建用工单位",
        des: "工价规则、合同模板等各种规则模板初始化，极速助您配置用工单位"
      }, {
        index: 1,
        num: "02",
        title: "角色配置",
        des: "为公司员工定制化配置角色及其权限"
      }, {
        index: 2,
        num: "03",
        title: "创建账号",
        des: "为公司员工创建相应权限的账号"
      }], [{
        index: 3,
        num: "04",
        title: "用工单位分组管理",
        des: "将用工单位分组管理，还可配置相应的分管单位哦"
      }, {}, {}]], [[{
        index: 4,
        num: "01",
        title: "定制入职模板",
        des: "根据公司情况定制专属您的入职/待入职模板"
      }, {
        index: 5,
        num: "02",
        title: "定制员工信息字段",
        des: "根据公司情况定制专属的员工信息、工资社保信息、材料附件信息"
      }, {
        index: 6,
        num: "03",
        title: "员工入职",
        des: "可手动入职、员工自行扫码入职"
      }], [{
        index: 7,
        num: "04",
        title: "员工的入离转调",
        des: "对员工的入职、离职、转正、调岗进行批量管理"
      }, {
        index: 8,
        num: "05",
        title: "今日新增",
        des: "可查看不同录入方式今日新增的员工，还可查看历史每日新增的记录哦"
      }, {}]], [[{
        index: 9,
        num: "01",
        title: "对员工进行定薪",
        des: "对每个员工进行定薪 记录薪资情况  对蓝领工人进行工价规则及招工劳务费规则的制定 查看薪资记录"
      }, {
        index: 10,
        num: "02",
        title: "设置工资表模板",
        des: "根据想要生成的月工资表，进行工资表头项的设置，添加自定义工资表加减项"
      }, {
        index: 11,
        num: "03",
        title: "设置薪资组",
        des: "将同一类型员工放置仅同一个薪资组中适用同一套规则，批量生成工资表"
      }], [{
        index: 12,
        num: "04",
        title: "生成月工资表",
        des: "根据工资表模板和薪资组生成所需的月工资表，导入工资数据，同步社保，一键计算个税"
      }, {
        index: 13,
        num: "05",
        title: "生成电子工资条",
        des: "可自行上传工资表或根据系统的月工资表自动生成电子工资条并发放至员工微信小程序中"
      }, {}]], [[{
        index: 14,
        num: "01",
        title: "创建参保账单",
        des: "指定月份进行账单创建，支持一键式生成各项费用明细"
      }, {
        index: 15,
        num: "02",
        title: "进行增减员操作",
        des: "根据条件筛选员工，通过调整员工基数，修改员工参保情况"
      }, {
        index: 16,
        num: "03",
        title: "社保数据实时同步",
        des: "通过大数据助手同步官方数据，精准校验社保数据"
      }], [{}, {}, {}]], [[{
        index: 17,
        num: "01",
        title: "考勤打卡设置",
        des: "可设置上下班打卡时间和导入表的考勤配置项"
      }, {
        index: 18,
        num: "02",
        title: "考勤报表识别",
        des: "智能识别本地考勤报表，实时计算工种数据；"
      }, {
        index: 19,
        num: "03",
        title: "每日考勤展示",
        des: "显示每位员工的精确考勤数据，方便进行管理和维护"
      }], [{}, {}, {}]], [[{
        index: 20,
        num: "01",
        title: "岗位多渠道发布",
        des: "可将招聘岗位通过多种途径进行发布，增大招聘范围；"
      }, {
        index: 21,
        num: "02",
        title: "展示岗位投递情况",
        des: "展示不同岗位下的员工投递情况及员工当前状态，一键式邀约，方便快捷"
      }, {
        index: 22,
        num: "03",
        title: "精准把握员工状态",
        des: "员工投递简历后，显示员工目前的面试入职状态，方便进行员工管理"
      }], [{}, {}, {}]]],
      menuItem: undefined
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["settings", "consolePermission", "auth", "hr", "site"])), {}, {
    permissionStringList: function permissionStringList() {
      return this.auth.permissions.permissionStringList;
    },
    adminNavState: function adminNavState() {
      return this.hr.adminNavState;
    },
    currentItems: function currentItems() {
      return this.site.currentItems;
    },
    adminMenu: function adminMenu() {
      return this["".concat(this.adminNavState, "Menu")];
    }
  }),
  created: function created() {
    this.menuItem = this.treeData[this.mindex];
    if (window.location.host === "hr.csxr.cn") {
      this.title = "橙事新人";
    } else if (window.location.host === "hr.qiyeyc.com" || window.location.host === "xingqitu.com") {
      this.title = "星企途";
    } else {
      this.title = "职新人";
    }
  },
  beforeDestroy: function beforeDestroy() {
    // 清楚定时器
    clearInterval(this.timer);
  },
  methods: {
    getNumb: function getNumb(item) {
      return item.num.replace("0", "");
    },
    getCurrent: function getCurrent(item) {
      var heArry = [];
      for (var i = 0; i < item.length; i++) {
        for (var j = 0; j < item[i].length; j++) {
          if (item[i][j].index !== undefined) {
            heArry.push(item[i][j]);
          }
        }
      }
      return heArry;
    },
    closeHand: function closeHand() {
      // this.$parent.closeYindao();
      this.$emit("closeYindao", ""); // 自定义事件  传递值“子向父组件传值”
    },
    itemClick: function itemClick(index) {
      this.mindex = index;
      this.menuItem = this.treeData[index];
    },
    upClick: function upClick(va) {
      this.isUp = va;
    },
    switchCurrentComany: function switchCurrentComany() {
      if (this.currentItems && this.currentItems.length > 0) {
        var d = this.currentItems[0];
        this.$store.dispatch("onCompanyClick", d);
      }
    },
    hasCompanyPermission: function hasCompanyPermission(value) {
      for (var i = 0; i < this.$store.state.auth.permissions.permissionStringList.length; i++) {
        if (this.$store.state.auth.permissions.permissionStringList[i] === value) {
          return true;
        }
      }
      return false;
    },
    stepHandl: function stepHandl(item) {
      var _this = this;
      if (this.$route.path === "/auth/login") {
        this.$message.error("请登录");
        return;
      }
      switch (item.index) {
        case 0:
          // 快速创建用工单位
          // 跳转管理后台
          if (this.$route.path !== "/admin/employment_unit") {
            if (this.$hasPermission("hr:workbench:workStation", true, this.permissionStringList) && this.$hasPermissionSecond("hr:enterpriseSetup", this.permissionStringList)) {
              sessionStorage.clear();
              this.$store.commit("hr/setAdminNavState", "manage");
              this.$router.push({
                path: "/admin/employment_unit"
              });
              sessionStorage.setItem("guid", "/admin/employment_unit");
              this.$emit("guidPath", "/admin/employment_unit");
            } else {
              this.$message.error("无访问权限");
            }
          }
          break;
        case 1:
          // 角色配置
          if (this.$route.path !== "/admin/role") {
            if (this.$hasPermission("hr:workbench:workStation", true, this.permissionStringList) && this.$hasPermissionSecond("hr:enterpriseSetup", this.permissionStringList)) {
              sessionStorage.clear();
              this.$store.commit("hr/setAdminNavState", "manage");
              this.$router.push({
                path: "/admin/role"
              });
              sessionStorage.setItem("guid", "/admin/role");
              this.$emit("guidPath", "/admin/role");
            } else {
              this.$message.error("无访问权限");
            }
          }
          break;
        case 2:
          // 创建账号
          if (this.$route.path !== "/admin/account_management") {
            if (this.$hasPermission("hr:workbench:workStation", true, this.permissionStringList) && this.$hasPermissionSecond("hr:enterpriseSetup", this.permissionStringList)) {
              sessionStorage.clear();
              this.$store.commit("hr/setAdminNavState", "manage");
              this.$router.push({
                path: "/admin/account_management"
              });
              sessionStorage.setItem("guid", "/admin/account_management");
              this.$emit("guidPath", "/admin/account_management");
            } else {
              this.$message.error("无访问权限");
            }
          }
          break;
        case 3:
          // 用工单位分组管理
          if (this.$route.path !== "/admin/company_management") {
            if (this.$hasPermission("hr:workbench:workStation", true, this.permissionStringList) && this.$hasPermissionSecond("hr:enterpriseSetup", this.permissionStringList)) {
              sessionStorage.clear();
              this.$store.commit("hr/setAdminNavState", "manage");
              this.$router.push({
                path: "/admin/company_management"
              });
              sessionStorage.setItem("guid", "/admin/company_management");
              this.$emit("guidPath", "/admin/company_management");
            } else {
              this.$message.error("无访问权限");
            }
          }
          break;
        case 4:
          // 定制入职模板
          if (this.hasCompanyPermission("company:people:setting")) {
            this.switchCurrentComany();

            // 先切换到别的模块，再切换回来，
            this.$store.state.navtab.openKeys = [];
            this.$store.state.navtab.openKeys.push("/staff");
            this.$store.state.navtab.selectedKeys = [];
            this.$store.state.navtab.selectedKeys.push("/staff/employee");
            this.$router.push("/staff/employee");
            // ///////////////////////////
            this.$nextTick(function () {
              _this.$store.state.navtab.openKeys = [];
              _this.$store.state.navtab.openKeys.push("/staff");
              _this.$store.state.navtab.selectedKeys = [];
              _this.$store.state.navtab.selectedKeys.push("/staff/edit");
              _this.$router.push("/staff/edit");
            });
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 5:
          // 定制员工信息字段
          if (this.hasCompanyPermission("company:people:setting")) {
            this.switchCurrentComany();

            // 先切换到别的模块，再切换回来，
            this.$store.state.navtab.openKeys = [];
            this.$store.state.navtab.openKeys.push("/staff");
            this.$store.state.navtab.selectedKeys = [];
            this.$store.state.navtab.selectedKeys.push("/staff/employee");
            this.$router.push("/staff/employee");
            // ///////////////////////////
            this.$nextTick(function () {
              _this.$store.state.navtab.openKeys = [];
              _this.$store.state.navtab.openKeys.push("/staff");
              _this.$store.state.navtab.selectedKeys = [];
              _this.$store.state.navtab.selectedKeys.push("/staff/edit");
              _this.$router.push("/staff/edit");
            });
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 6:
          // 员工入职
          if (this.hasCompanyPermission("company:people:list")) {
            this.switchCurrentComany();

            // 先切换到别的模块，再切换回来，
            this.$store.state.navtab.openKeys = [];
            this.$store.state.navtab.openKeys.push("/staff");
            this.$store.state.navtab.selectedKeys = [];
            this.$store.state.navtab.selectedKeys.push("/staff/edit");
            this.$router.push("/staff/edit");
            // //////////////////
            this.$nextTick(function () {
              _this.$store.state.navtab.openKeys = [];
              _this.$store.state.navtab.openKeys.push("/staff");
              _this.$store.state.navtab.selectedKeys = [];
              _this.$store.state.navtab.selectedKeys.push("/staff/employee");
              _this.$router.push({
                path: "/staff/employee",
                query: {
                  selectId: "2"
                }
              });
            });
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 7:
          // 员工的入离转调
          if (this.hasCompanyPermission("company:people:change")) {
            this.switchCurrentComany();

            // 先切换到别的模块，再切换回来，
            this.$store.state.navtab.openKeys = [];
            this.$store.state.navtab.openKeys.push("/staff");
            this.$store.state.navtab.selectedKeys = [];
            this.$store.state.navtab.selectedKeys.push("/staff/edit");
            this.$router.push("/staff/edit");
            // //////////////////

            this.$store.state.navtab.openKeys = [];
            this.$store.state.navtab.openKeys.push("/staff");
            this.$store.state.navtab.selectedKeys = [];
            this.$store.state.navtab.selectedKeys.push("/staff/manger");
            this.$router.push("/staff/manger");
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 8:
          // 今日新增
          if (this.hasCompanyPermission("company:people:list")) {
            this.switchCurrentComany();
            // 先切换到别的模块，再切换回来，
            this.$store.state.navtab.openKeys = [];
            this.$store.state.navtab.openKeys.push("/staff");
            this.$store.state.navtab.selectedKeys = [];
            this.$store.state.navtab.selectedKeys.push("/staff/edit");
            this.$router.push("/staff/edit");
            // /////////////////////////////
            this.$nextTick(function () {
              _this.$store.state.navtab.openKeys = [];
              _this.$store.state.navtab.openKeys.push("/staff");
              _this.$store.state.navtab.selectedKeys = [];
              _this.$store.state.navtab.selectedKeys.push("/staff/employee");
              _this.$router.push("/staff/employee/todayAdd");
            });
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 9:
          // 对员工进行定薪
          if (this.hasCompanyPermission("company:salary:file")) {
            if (this.$route.path !== "/wages/file") {
              this.switchCurrentComany();
              this.$store.state.navtab.openKeys = [];
              this.$store.state.navtab.openKeys.push("/wages");
              this.$store.state.navtab.selectedKeys = [];
              this.$store.state.navtab.selectedKeys.push("/wages/file");
              this.$router.push("/wages/file");
            }
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 10:
          // 设置工资表模板
          if (this.hasCompanyPermission("company:salary:template")) {
            if (this.$route.path !== "/wages/template") {
              this.switchCurrentComany();
              this.$store.state.navtab.openKeys = [];
              this.$store.state.navtab.openKeys.push("/wages");
              this.$store.state.navtab.selectedKeys = [];
              this.$store.state.navtab.selectedKeys.push("/wages/template");
              this.$router.push("/wages/template");
            }
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 11:
          // 设置薪资组
          if (this.hasCompanyPermission("company:salary:setting")) {
            if (this.$route.path !== "/wages/set") {
              this.switchCurrentComany();
              this.$store.state.navtab.openKeys = [];
              this.$store.state.navtab.openKeys.push("/wages");
              this.$store.state.navtab.selectedKeys = [];
              this.$store.state.navtab.selectedKeys.push("/wages/set");
              this.$router.push("/wages/set");
            }
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 12:
          // 生成月工资表
          if (this.hasCompanyPermission("company:salary:salary")) {
            if (this.$route.path !== "/wages/monthly") {
              this.switchCurrentComany();
              this.$store.state.navtab.openKeys = [];
              this.$store.state.navtab.openKeys.push("/wages");
              this.$store.state.navtab.selectedKeys = [];
              this.$store.state.navtab.selectedKeys.push("/wages/monthly");
              this.$router.push("/wages/monthly");
            }
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 13:
          // 生成电子工资条
          if (this.hasCompanyPermission("company:salary:esalary")) {
            if (this.$route.path !== "/wages/payslip") {
              this.switchCurrentComany();
              this.$store.state.navtab.openKeys = [];
              this.$store.state.navtab.openKeys.push("/wages");
              this.$store.state.navtab.selectedKeys = [];
              this.$store.state.navtab.selectedKeys.push("/wages/payslip");
              this.$router.push("/wages/payslip");
            }
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 14:
          if (this.hasCompanyPermission("company:soc:intend")) {
            if (this.$route.path !== "/shebao/shebaoManage") {
              this.switchCurrentComany();
              this.$store.state.navtab.openKeys = [];
              this.$store.state.navtab.openKeys.push("/shebao");
              this.$store.state.navtab.selectedKeys = [];
              this.$store.state.navtab.selectedKeys.push("/shebao/shebaoManage");
              this.$router.push("/shebao/shebaoManage");
            }
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 15:
          if (this.hasCompanyPermission("company:soc:intend")) {
            if (this.$route.path !== "/shebao/shebaoManage/shebaoZengJianY") {
              this.switchCurrentComany();
              this.$store.state.navtab.openKeys = [];
              this.$store.state.navtab.openKeys.push("/shebao");
              this.$store.state.navtab.selectedKeys = [];
              this.$store.state.navtab.selectedKeys.push("/shebao/shebaoManage");
              this.$router.push({
                path: "/shebao/shebaoManage/shebaoZengJianY"
              });
            }
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 16:
          if (this.hasCompanyPermission("company:soc:shebao")) {
            if (this.$route.path !== "/shebao/shebaoMingDan") {
              this.switchCurrentComany();
              this.$store.state.navtab.openKeys = [];
              this.$store.state.navtab.openKeys.push("/shebao");
              this.$store.state.navtab.selectedKeys = [];
              this.$store.state.navtab.selectedKeys.push("/shebao/shebaoMingDan");
              this.$router.push({
                path: "/shebao/shebaoMingDan"
              });
            }
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 17:
          if (this.hasCompanyPermission("company:attendance:setting")) {
            this.switchCurrentComany();
            // 先切换到别的模块，再切换回来，
            this.$store.state.navtab.openKeys = [];
            this.$store.state.navtab.openKeys.push("/check");
            this.$store.state.navtab.selectedKeys = [];
            this.$store.state.navtab.selectedKeys.push("/check/checkTable");
            this.$router.push({
              path: "/check/checkTable"
            });
            // /////////////////////////////
            this.$nextTick(function () {
              _this.$store.state.navtab.openKeys = [];
              _this.$store.state.navtab.openKeys.push("/check");
              _this.$store.state.navtab.selectedKeys = [];
              _this.$store.state.navtab.selectedKeys.push("/check/checkSet");
              _this.$router.push({
                path: "/check/checkSet"
              });
            });
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 18:
          // 工时导入
          if (this.hasCompanyPermission("company:attendance:setting")) {
            this.switchCurrentComany();
            // 先切换到别的模块，再切换回来，
            this.$store.state.navtab.openKeys = [];
            this.$store.state.navtab.openKeys.push("/check");
            this.$store.state.navtab.selectedKeys = [];
            this.$store.state.navtab.selectedKeys.push("/check/checkTable");
            this.$router.push({
              path: "/check/checkTable"
            });
            // /////////////////////////////
            this.$nextTick(function () {
              _this.$store.state.navtab.openKeys = [];
              _this.$store.state.navtab.openKeys.push("/check");
              _this.$store.state.navtab.selectedKeys = [];
              _this.$store.state.navtab.selectedKeys.push("/check/checkSet");
              _this.$router.push({
                path: "/check/checkSet",
                query: {
                  selectId: "2"
                }
              });
            });
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 19:
          // 每日考勤
          if (this.hasCompanyPermission("company:attendance:report")) {
            if (this.$route.path !== "/check/checkTable") {
              this.switchCurrentComany();
              this.$store.state.navtab.openKeys = [];
              this.$store.state.navtab.openKeys.push("/check");
              this.$store.state.navtab.selectedKeys = [];
              this.$store.state.navtab.selectedKeys.push("/check/checkTable");
              this.$router.push({
                path: "/check/checkTable"
              });
            }
          } else {
            this.$message.error("无访问权限");
          }
          break;
        case 20:
          // 岗位多渠道发布
          if (this.$route.path !== "/recruitment/management") {
            if (this.$hasPermission("hr:workbench:workStation", true, this.permissionStringList) && this.$hasPermissionSecond("recruitment:management", this.permissionStringList)) {
              sessionStorage.clear();
              this.$store.commit("hr/setAdminNavState", "recruitment");
              this.$router.push({
                path: "/recruitment/management"
              });
              sessionStorage.setItem("guid", "/recruitment/management");
              this.$emit("guidPath", "/recruitment/management");
            } else {
              this.$message.error("无访问权限");
            }
          }
          break;
        case 21:
          // 展示岗位投递情况
          if (this.$route.path !== "/recruitment/people") {
            if (this.$hasPermission("hr:workbench:workStation", true, this.permissionStringList) && this.$hasPermissionSecond("recruitment:people", this.permissionStringList)) {
              sessionStorage.clear();
              this.$store.commit("hr/setAdminNavState", "recruitment");
              this.$router.push({
                path: "/recruitment/people"
              });
              sessionStorage.setItem("guid", "/recruitment/people");
              this.$emit("guidPath", "/recruitment/people");
            } else {
              this.$message.error("无访问权限");
            }
          }
          break;
        case 22:
          // 精准把握员工状态
          if (this.$route.path !== "/recruitment/talent") {
            if (this.$hasPermission("hr:workbench:workStation", true, this.permissionStringList) && this.$hasPermissionSecond("recruitment:talent", this.permissionStringList)) {
              sessionStorage.clear();
              this.$store.commit("hr/setAdminNavState", "recruitment");
              this.$router.push({
                path: "/recruitment/talent"
              });
              sessionStorage.setItem("guid", "/recruitment/talent");
              this.$emit("guidPath", "/recruitment/talent");
            } else {
              this.$message.error("无访问权限");
            }
          }
          break;
      }
    }
  }
};