import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("header", {
    staticClass: "flex-center-between"
  }, [_c("div", {
    staticClass: "l_title"
  }), _c("a-button", [_vm._v("保存")])], 1), _c("a-collapse", {
    attrs: {
      accordion: "",
      defaultActiveKey: "",
      bordered: false
    }
  }, [_c("a-collapse-panel", {
    key: "1",
    staticStyle: {
      position: "relative"
    },
    attrs: {
      header: "基本工资"
    }
  }, [_c("a-row", {
    staticClass: "box_content bg_gray",
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("基本工资")]), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "shff"
  }, [_vm._v("税后发放")])]), _c("a-col", {
    staticClass: "ta-right",
    attrs: {
      span: 6
    }
  }, [_c("a-popover", {
    attrs: {
      trigger: "click",
      placement: "leftTop"
    },
    model: {
      value: _vm.popover,
      callback: function callback($$v) {
        _vm.popover = $$v;
      },
      expression: "popover"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("a-row", [_c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.addList
    }
  }, [_vm._v("添加子项")])], 1), _c("a-row", [_c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.editor
    }
  }, [_vm._v("编辑")])], 1)], 1), _c("a-button", {
    attrs: {
      type: "link"
    }
  }, [_vm._v("编辑")])], 2), _c("span", {
    staticClass: "xt"
  }, [_vm._v("系统")])], 1)], 1), _vm._l(_vm.data, function (item, i) {
    return _c("a-row", {
      key: i,
      staticClass: "box_content",
      attrs: {
        type: "flex",
        align: "middle"
      },
      nativeOn: {
        mouseenter: function mouseenter($event) {
          _vm.index = i;
        },
        mouseleave: function mouseleave($event) {
          _vm.index = null;
        }
      }
    }, [_c("a-col", {
      attrs: {
        span: 6
      }
    }, [_vm._v(_vm._s(item.name))]), _c("a-col", {
      attrs: {
        span: 6
      }
    }, [_vm._v(_vm._s(item.remark))]), _c("a-col", {
      attrs: {
        span: 6
      }
    }, [_c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.beforeTax,
        expression: "item.beforeTax"
      }],
      staticClass: "shff"
    }, [_vm._v(" 税后发放 ")])]), _c("a-col", {
      staticClass: "ta-right",
      attrs: {
        span: 6
      }
    }, [_c("a-button", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.index === i,
        expression: "index === i"
      }],
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          return _vm.editor(item);
        }
      }
    }, [_vm._v(" 编辑 ")]), _c("span", {
      staticClass: "xt"
    }, [_vm._v("系统")])], 1)], 1);
  })], 2), _c("a-collapse-panel", {
    key: "2",
    attrs: {
      header: "岗位工资"
    }
  }, [_c("p", [_vm._v(_vm._s(_vm.text))])]), _c("a-collapse-panel", {
    key: "3",
    attrs: {
      header: "奖金"
    }
  }, [_c("a-table", {
    attrs: {
      scroll: {
        y: 400
      },
      pagination: false,
      dataSource: _vm.data
    }
  }, [_c("a-table-column", {
    attrs: {
      width: 300,
      title: "名称"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(record.firstName) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      width: 100
    }
  }, [[_c("a-switch", {
    attrs: {
      defaultChecked: ""
    },
    on: {
      change: _vm.onChange
    }
  })]], 2), _c("a-table-column", {
    attrs: {
      width: 100,
      title: "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.editorMoney(text);
            }
          }
        }, [_vm._v("编辑")])];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      width: 300,
      title: "名称"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(record.lastName) + " ")];
      }
    }])
  }), _c("a-table-column", {
    attrs: {
      width: 100
    }
  }, [[_c("a-switch", {
    attrs: {
      defaultChecked: ""
    },
    on: {
      change: _vm.onChange
    }
  })]], 2), _c("a-table-column", {
    attrs: {
      width: 100,
      title: "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.editorMoney(text);
            }
          }
        }, [_vm._v("编辑")])];
      }
    }])
  })], 1)], 1), _c("a-collapse-panel", {
    key: "4",
    attrs: {
      header: "调整项"
    }
  }, [_c("p", [_vm._v(_vm._s(_vm.text))])]), _c("a-collapse-panel", {
    key: "5",
    attrs: {
      header: "成本项"
    }
  }, [_c("p", [_vm._v(_vm._s(_vm.text))])]), _c("a-collapse-panel", {
    key: "6",
    attrs: {
      header: "其他项"
    }
  }, [_c("p", [_vm._v(_vm._s(_vm.text))])])], 1), _c("pay-editor", {
    attrs: {
      payOptions: _vm.payOptions
    },
    on: {
      paySetCb: function paySetCb($event) {
        return _vm.paySetCb($event);
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };