import bonus from "@/views/tax/declare/bonus";
import empDetails from "@/views/tax/declare/empDetails";
import wage from "@/views/tax/declare/wage";
export default {
  components: {
    bonus: bonus,
    empDetails: empDetails,
    wage: wage
  },
  data: function data() {
    return {
      tabs: "0"
    };
  }
};