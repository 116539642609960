import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import Navbar from "@/layout/components/navbar";
import NoticeList from "@/components/notice";
import { buildShortcuts } from "./funcs";
import { mapGetters, mapState } from "vuex";
import { getCurrentUserInfo } from "@/api/company/news";
import SelectCompanyGroup from "@/components/SelectCompany/dashboard";
import { getEmpInfoCountReq, jfHomePageNoticeReq, selectCancelCountBySiteReq } from "./api";
export default {
  name: "",
  components: {
    Navbar: Navbar,
    NoticeList: NoticeList,
    SelectCompanyGroup: SelectCompanyGroup
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      shortcuts: [],
      currentItem: null,
      roleName: "",
      empInfo: {},
      noticeSource: null,
      zhaopinInfo: null,
      emptyImg: require("./images/empty.png")
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(["name", "avatar", "permissions_menu", "permissions_menu_urls"])), mapState(["site", "auth", "hr"])), {}, {
    perStrs: function perStrs() {
      try {
        return this.auth.permissions.permissionStringList;
      } catch (err) {
        return [];
      }
    },
    showZhaopin: function showZhaopin() {
      return (this.perStrs.includes("recruitment:management:query") || this.perStrs.includes("recruitment:talent:query") || this.perStrs.includes("recruitment:zhinengtuijian:query")) && this.zhaopinInfo;
    },
    operationMenus: function operationMenus() {
      var menus = this.permissions_menu.filter(function (item) {
        return item.url !== "/dashboard" && item.level > 1;
      });
      return menus.map(function (item) {
        return item.url;
      });
    },
    currentItems: function currentItems() {
      return this.site.currentItems;
    },
    firstCompany: function firstCompany() {
      return this.currentItems[0];
    },
    companyId: function companyId() {
      return this.firstCompany ? this.firstCompany.id : null;
    },
    companyIds: function companyIds() {
      try {
        return this.hr.companyList.map(function (item) {
          return item.id;
        });
      } catch (err) {
        return [];
      }
    }
  }),
  watch: {
    companyId: {
      handler: function handler(newVal) {
        var _this = this;
        if (newVal) {
          getEmpInfoCountReq(newVal).then(function (res) {
            _this.empInfo = res.data;
          });
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this2 = this;
    this.shortcuts = buildShortcuts().filter(function (item) {
      return _this2.showItem(item);
    });
    this.currentItem = this.shortcuts[0];
    getCurrentUserInfo().then(function (res) {
      _this2.roleName = res.data.roleName;
    }).catch(function (err) {
      console.log(err);
    });
    this.initInfo();
  },
  mounted: function mounted() {},
  methods: {
    showItem: function showItem(item) {
      var flag = false;
      for (var i = 0; i < item.items.length; i++) {
        flag = this.showSubItem(item.items[i]);
        if (flag) {
          break;
        }
      }
      return flag;
    },
    showSubItem: function showSubItem(item) {
      var _this3 = this;
      if (item.items.length > 0) {
        var shows = item.items.filter(function (li) {
          var theMenu = li.menu || li.url;
          return _this3.permissions_menu_urls.includes(theMenu);
        });
        return shows.length > 0;
      } else {
        var theMenu = item.menu || item.url;
        return this.permissions_menu_urls.includes(theMenu);
      }
    },
    jumpToPage: function jumpToPage(url) {
      this.$router.push(url);
    },
    initInfo: function initInfo() {
      var _this4 = this;
      jfHomePageNoticeReq().then(function (res) {
        _this4.noticeSource = res.data;
      }).catch(function (err) {
        console.log(err);
      });
      selectCancelCountBySiteReq().then(function (res) {
        _this4.zhaopinInfo = res.data;
      }).catch(function (err) {
        console.log(err);
      });
    },
    goEntry: function goEntry() {
      if (this.operationMenus.includes("/staff/employee")) {
        this.$router.push("/staff/employee");
        this.$store.commit("navtab/updateSelectedKeys", ["/staff/employee"]);
        this.$store.commit("navtab/updateOpenKeys", ["/staff"]);
      } else {
        this.$router.push(this.operationMenus[0]);
        this.$store.commit("navtab/updateSelectedKeys", [this.operationMenus[0]]);
        this.$store.commit("navtab/updateOpenKeys", ["/".concat(this.operationMenus[0].split("/")[1])]);
      }
    },
    handleClickChange: function handleClickChange() {
      this.$refs.selectVal.handleClickChange(true);
    },
    selectCompany: function selectCompany(theCompany) {
      this.$store.dispatch("onCompanyClick", theCompany);
      this.goEntry(); // 跳转
    },
    onBtnClick: function onBtnClick(item) {
      // 先判断是否已经选择用工单位
      var theMenu = item.menu || item.url;
      if (this.permissions_menu_urls.includes(theMenu)) {
        if (this.companyIds.length > 0) {
          // 菜单展开
          this.$store.commit("navtab/updateSelectedKeys", [theMenu]);
          this.$store.commit("navtab/updateOpenKeys", ["/".concat(theMenu.split("/")[1])]);
          var sc = item.url.includes("?") ? "&operateFromShortcut=".concat(item.type) : "?operateFromShortcut=".concat(item.type);
          this.$router.push("".concat(item.url).concat(sc));
        } else {
          this.$message.warning("未查询到用工单位，请先创建用工单位");
        }
      } else {
        this.$message.warning("您没有该权限，请联系管理员");
      }
    }
  }
};