import request from "@/utils/request";
// 分页查询预登记人员
export function getPrePersonnel(params) {
  return request({
    url: "/hr/api/v1/checkInPeoples",
    method: "get",
    params: params
  });
}
// 新增预登记人员
export function addPrePersonnel(data) {
  return request({
    url: "/hr/api/v1/checkInPeoples",
    method: "post",
    data: data
  });
}
// 编辑预登记人员
export function editPrePersonnel(data) {
  return request({
    url: "/hr/api/v1/checkInPeoples",
    method: "put",
    data: data
  });
}
// 删除单个预登记人员，彻底删除
export function deletePrePersonnel(checkInPeopleId) {
  return request({
    url: "/hr/api/v1/checkInPeoples/".concat(checkInPeopleId),
    method: "delete"
  });
}
// 分页查询渠道管理
export function getChannels(params) {
  return request({
    url: "/hr/api/v1/channels",
    method: "get",
    params: params
  });
}
// 分页查询渠道管理
export function getChannels2(params) {
  return request({
    url: "hr/api/v1/channels/pageChannelList",
    method: "get",
    params: params
  });
}

// 查询全部渠道管理
export function getAllChannels(params) {
  return request({
    url: "/hr/api/v1/channels/listAll",
    method: "get",
    params: params
  });
}
// 新增渠道管理
export function addChannels(data) {
  return request({
    url: "/hr/api/v1/channels",
    method: "post",
    data: data
  });
}
// 修改渠道管理
export function editChannels(data) {
  return request({
    url: "/hr/api/v1/channels",
    method: "put",
    data: data
  });
}
// 删除单个渠道管理，彻底删除
export function deleteChannels(channelId) {
  return request({
    url: "/hr/api/v1/channels/".concat(channelId),
    method: "delete"
  });
}
// 查询渠道详情
export function getChannelsDetail(channelId) {
  return request({
    url: "/hr/api/v1/channels/".concat(channelId),
    method: "get"
  });
}
// 新增工价计算规则
export function addRules(data) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules",
    method: "post",
    data: data
  });
}
// 查询工价计算规则
export function getAllRules(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/listAll",
    method: "get",
    params: params
  });
}
// 修改工价计算规则
export function editRules(data) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules",
    method: "put",
    data: data
  });
}
// 删除单个工价计算规则，彻底删除
export function deleteRules(salaryCalculateRuleId) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/".concat(salaryCalculateRuleId),
    method: "delete"
  });
}
// 查询员工信息
export function getEmpDetails(params) {
  return request({
    url: "/hr/api/v1/salaryCalculateRules/selectEmpInfoVoBySalaryCalculateRuleId",
    method: "get",
    params: params
  });
}
// 渠道劳务费结算合计
export function getFeeSum(data) {
  return request({
    url: "hr/api/v1/channels/feeSum",
    method: "post",
    data: data
  });
}
// 分页查询渠道劳务费结算
export function getFeeSumContent(params) {
  return request({
    url: "/salary/api/v1/channelFeeSettlements",
    method: "get",
    params: params
  });
}
// 分页查询渠道劳务费结算
export function getChannelFeeSettlement(data) {
  return request({
    url: "/hr/api/v1/channelGroups/getChannelFeeSettlement",
    method: "post",
    data: data
  });
}
// 删除
export function deletChannelFeeSettlement(id) {
  return request({
    url: "/salary/api/v1/channelFeeSettlements/" + id,
    method: "delete"
  });
}
export function getAllEmp(params) {
  return request({
    url: "/hr/api/v1/channelGroups/getNotDistributionUser",
    method: "get",
    params: params
  });
}
export function getUserByGroupId(params) {
  return request({
    url: "hr/api/v1/channelGroupUsers/getUserByGroupId",
    method: "get",
    params: params
  });
}
// 分页查询渠道劳务费结算详情
export function getFeeSumDetail(params) {
  return request({
    url: "/salary/api/v1/channelFeeSettlementDetails",
    method: "get",
    params: params
  });
}
// 查询分组下公司
export function findCompanyGroup(data) {
  return request({
    url: "/hr/api/v1/childrenCompany/listAll",
    method: "GET",
    params: data
  });
}
// 查询用户用工单位
export function getByUserId(params) {
  return request({
    url: "/hr/api/v1/childrenCompany/selectByUserId",
    method: "GET",
    params: params
  });
}
// 导出预登记人员
export function exportFile(params) {
  return request({
    url: "/hr/api/v1/checkInPeoples/checkInPeopleExport",
    method: "GET",
    params: params
  });
}
export function getHrRole(params) {
  return request({
    url: "/hr/api/v1/hrRoles/selectChannelHrRole",
    method: "GET",
    params: params
  });
}
export function getCheckInField(params) {
  return request({
    url: "/hr/api/v1/customTemplates/getCheckInField",
    method: "GET",
    params: params
  });
}
export function getQueryCount(params) {
  return request({
    url: "/hr/api/v1/operationEmpRecords/queryCount",
    method: "get",
    params: params
  });
}
export function getQueryEmpPageList(params) {
  return request({
    url: "/hr/api/v1/operationEmpRecords/queryEmpPageList",
    method: "get",
    params: params
  });
}
export function getQueryCheckInPageList(params) {
  return request({
    url: "/hr/api/v1/operationEmpRecords/queryCheckInPageList",
    method: "get",
    params: params
  });
}
export function identificationCard(data) {
  return request({
    url: "/hr/api/v1/checkInPeoples/identificationCard",
    method: "post",
    data: data
  });
}
export function batchUpdate(data) {
  return request({
    url: "/hr/api/v1/checkInPeoples/batchUpdate",
    method: "put",
    data: data
  });
}
// 新增渠道分组
export function addGroup(data) {
  return request({
    url: "/hr/api/v1/channelGroups",
    method: "post",
    data: data
  });
}
export function addGroupEmp(data) {
  return request({
    url: "hr/api/v1/channelGroupUsers/batchSave",
    method: "post",
    data: data
  });
}
export function deleteEmp(data) {
  return request({
    url: "hr/api/v1/channelGroupUsers/deleteGroupManager",
    method: "delete",
    data: data
  });
}
export function editGroup(data) {
  return request({
    url: "/hr/api/v1/channelGroups",
    method: "put",
    data: data
  });
}
export function getAllGroup(params) {
  return request({
    url: "/hr/api/v1/channelGroups/getMyChannelGroup",
    method: "get",
    params: params
  });
}
export function deleteGroup(channelGroupId) {
  return request({
    url: "/hr/api/v1/channelGroups/".concat(channelGroupId),
    method: "delete"
  });
}