import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pSlide"
  }, [_vm.showIndex ? _c("a-layout", [_c("a-layout-sider", {
    attrs: {
      width: 225
    }
  }, [_c("div", {
    staticClass: "pSlide-title",
    on: {
      mouseenter: function mouseenter($event) {
        _vm.addIcon = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.addIcon = false;
      }
    }
  }, [_vm._v("新闻资讯")]), _c("a-menu", {
    staticClass: "groupleft"
  }, _vm._l(_vm.groupList, function (item) {
    return _c("a-menu-item", {
      key: item.id,
      staticStyle: {
        "border-bottom": "1px solid #e8e8e8"
      },
      on: {
        click: function click($event) {
          return _vm.getNewlistByType(item);
        }
      }
    }, [_c("a-row", {
      attrs: {
        type: "flex",
        align: "middle",
        justify: "space-between"
      }
    }, [_c("a-col", {
      staticClass: "groupname"
    }, [_vm._v(_vm._s(item.name))]), _c("a-col", [_vm.act == item.id ? _c("a-icon", {
      attrs: {
        type: "setting"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.setting(item);
        }
      }
    }) : _vm._e()], 1)], 1)], 1);
  }), 1)], 1), _c("a-layout", [_c("a-row", {
    staticClass: "lay-head",
    attrs: {
      type: "flex"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      icon: "plus",
      disabled: !_vm.haveType
    },
    on: {
      click: _vm.createNew
    }
  }, [_vm._v("新建文档")]), _c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "搜索文档",
      allowClear: ""
    },
    on: {
      search: _vm.onSearch
    }
  })], 1), _c("a-table", {
    staticClass: "hidetitle",
    attrs: {
      columns: _vm.noticeColumns,
      "data-source": _vm.newslist,
      "row-key": "id",
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      },
      pagination: _vm.pagination,
      loading: _vm.tableLoading
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn(text) {
        return _c("span", {
          staticStyle: {
            display: "flex"
          }
        }, [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(text != null ? text : "--"))])]), _c("div", {
          staticClass: "titleline"
        }, [_vm._v(_vm._s(text != null ? text : "--"))])], 2)], 1);
      }
    }, {
      key: "status",
      fn: function fn(text) {
        return _c("span", {}, [_c("span", {
          class: text === 0 ? "tc-theme" : text === 1 ? "tc-theme" : "colorC0C0C0"
        }, [_vm._v("• ")]), _vm._v(" " + _vm._s(text === 0 ? "置顶" : "未置顶") + " ")]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("span", {}, [_c("span", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [_c("a", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.updateNew(record);
            }
          }
        }, [_vm._v(" 编辑 ")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a", {
          staticClass: "primary_color",
          staticStyle: {
            cursor: "pointer"
          },
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.goDetail(record);
            }
          }
        }, [_vm._v(" 详情 ")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.deleteNew(record);
            }
          }
        }, [_vm._v(" 删除 ")])], 1)]);
      }
    }], null, false, 203209973)
  })], 1)], 1) : _vm._e(), !_vm.showIndex ? _c("a-layout", [_c("a-layout", [_c("a-row", {
    staticStyle: {
      "justify-content": "space-between"
    },
    attrs: {
      type: "flex"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        _vm.showIndex = true;
      }
    }
  }), _c("h3", [_vm._v("编辑分组")]), _c("a-button", {
    attrs: {
      type: "link",
      icon: "plus"
    },
    on: {
      click: _vm.createGroup
    }
  }, [_vm._v(" 添加分组")])], 1), _c("a-layout-content", {
    staticClass: "groupbox"
  }, [_c("a-table", {
    attrs: {
      "data-source": _vm.groupList,
      "row-key": function rowKey(data) {
        return data.id;
      },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "分组名称",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text.name))])];
      }
    }], null, false, 2220100901)
  }), _c("a-table-column", {
    attrs: {
      title: "分组内合同类型数",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(text.categoryCount) + " ")];
      }
    }], null, false, 3508453834)
  }), _c("a-table-column", {
    attrs: {
      title: "操作",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(text) {
        return [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.updateGroup(text);
            }
          }
        }, [_vm._v("编辑")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a-button", {
          attrs: {
            type: "link",
            disabled: text.categoryCount > 0
          },
          on: {
            click: function click($event) {
              return _vm.deleteGroup(text);
            }
          }
        }, [_vm._v("删除")])];
      }
    }], null, false, 1822710074)
  })], 1)], 1)], 1)], 1) : _vm._e(), _c("a-modal", {
    attrs: {
      title: _vm.groupTitle,
      visible: _vm.addGroupVisible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.confirmAddGroup,
      cancel: _vm.handleCancel
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      form: _vm.groupForm,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      colon: true,
      label: "分组名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '请输入' }] }]"
    }]
  })], 1), _c("a-form-item", {
    attrs: {
      colon: true,
      label: "官网信息展示分组主键"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["newsGroupId", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['newsGroupId', { rules: [{ required: true, message: '请输入' }] }]"
    }]
  })], 1)], 1)], 1)]), _c("a-modal", {
    attrs: {
      title: _vm.groupTitle,
      visible: _vm.addTypeVisible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.confirmAddType,
      cancel: _vm.handleCancel
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      form: _vm.typeForm,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      },
      colon: true
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同模板名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '请输入' }] }]"
    }]
  })], 1), _c("a-form-item", {
    attrs: {
      label: "选择分组"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["groupId", {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "['groupId', { rules: [{ required: true, message: '请选择' }] }]"
    }]
  }, _vm._l(_vm.groupList, function (group, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: group.id
      }
    }, [_vm._v(" " + _vm._s(group.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "名称描述"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["description", {
        rules: [{
          required: false,
          message: "请输入"
        }]
      }],
      expression: "['description', { rules: [{ required: false, message: '请输入' }] }]"
    }],
    attrs: {
      placeholder: "请输入名称描述",
      rows: 4
    }
  })], 1)], 1)], 1)]), _c("a-modal", {
    attrs: {
      title: "提示",
      visible: _vm.batchDeleteGroupVisible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.confirmDeleteGroup,
      cancel: _vm.handleCancel
    }
  }, [_c("p", {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("确定删除分组 " + _vm._s(_vm.theCurrentGroup.name) + "吗？")])]), _c("a-modal", {
    attrs: {
      title: "提示",
      visible: _vm.batchDeleteNewVisible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.confirmDeleteNew,
      cancel: _vm.handleCancel
    }
  }, [_c("p", {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("确定删除 " + _vm._s(_vm.theCurrentGroup.name) + "吗？")])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };