var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper page-miH page-bgBack p24"
  }, [_c("div", {
    staticClass: "mb16"
  }, [_c("selectGroup", {
    on: {
      upDate: _vm.upDate
    }
  })], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("TableBody", {
    ref: "TableBody",
    attrs: {
      tableName: "taxCalculateReport",
      selectedRowKeys: _vm.selectedRowKeys,
      source: _vm.tableData,
      headerRowID: "sortable_list",
      selectHead: false,
      selection: !_vm.stateActive ? true : false,
      selectionSlot: "",
      action: ""
    },
    on: {
      "update:selectedRowKeys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      "update:selected-row-keys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      onClose: _vm.handleCancelTable,
      onSearch: _vm.handleSearch
    }
  }, [_c("template", {
    slot: "search"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "姓名/手机号",
      "allow-clear": ""
    },
    on: {
      search: _vm.onSearchName
    },
    model: {
      value: _vm.searchName,
      callback: function callback($$v) {
        _vm.searchName = $$v;
      },
      expression: "searchName"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "任职受雇类型",
      "allow-clear": "",
      placeholder: "请选择任职受雇类型"
    },
    model: {
      value: _vm.employmentType,
      callback: function callback($$v) {
        _vm.employmentType = $$v;
      },
      expression: "employmentType"
    }
  }, _vm._l(_vm.employmentTypeArr, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "报送状态",
      "allow-clear": "",
      placeholder: "请选择报送状态"
    },
    model: {
      value: _vm.submissionState,
      callback: function callback($$v) {
        _vm.submissionState = $$v;
      },
      expression: "submissionState"
    }
  }, _vm._l(_vm.submissionStateArr, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "身份验证状态",
      "allow-clear": "",
      placeholder: "请选择身份验证状态"
    },
    model: {
      value: _vm.authenticationState,
      callback: function callback($$v) {
        _vm.authenticationState = $$v;
      },
      expression: "authenticationState"
    }
  }, _vm._l(_vm.validationStateArr, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1), _c("a-range-picker", {
    attrs: {
      label: "任职受雇日期",
      valueFormat: "YYYY-MM-DD",
      allowClear: ""
    },
    model: {
      value: _vm.Time1,
      callback: function callback($$v) {
        _vm.Time1 = $$v;
      },
      expression: "Time1"
    }
  }), _c("a-range-picker", {
    attrs: {
      label: "离职日期 ",
      valueFormat: "YYYY-MM-DD",
      allowClear: ""
    },
    model: {
      value: _vm.Time2,
      callback: function callback($$v) {
        _vm.Time2 = $$v;
      },
      expression: "Time2"
    }
  }), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "信息是否完备",
      "allow-clear": "",
      placeholder: "请选择信息是否完备"
    },
    model: {
      value: _vm.isComplete,
      callback: function callback($$v) {
        _vm.isComplete = $$v;
      },
      expression: "isComplete"
    }
  }, _vm._l(_vm.CompletenessInformationArr, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1), _c("template", {
    slot: "action-btn"
  }, [_vm.$route.meta.tabType == "1" ? _c("div", [_vm.$hasPermission("zhinengsuanshui:renyuanbaosong.daibaosong:export") ? _c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: !_vm.tableData.length
    },
    on: {
      click: _vm.onDownload
    }
  }, [_vm._v("导出")]) : _vm._e(), !_vm.stateActive ? _c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_vm.$hasPermission("zhinengsuanshui:renyuanbaosong.daibaosong:huoqubaosongjieguo") ? _c("a-popover", {
    staticStyle: {
      "margin-right": "0"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("请进行报送后，再点击获取结果")])]), _c("a-button", {
    staticClass: "mr12",
    attrs: {
      type: "primary",
      disabled: ""
    }
  }, [_vm._v("获取报送结果")])], 2) : _vm._e(), _vm.$hasPermission("zhinengsuanshui:renyuanbaosong.daibaosong:baosong") ? _c("a-dropdown", {
    attrs: {
      disabled: !_vm.tableData.length
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("报送")]), _c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_c("a-menu-item", [_c("a", {
    on: {
      click: function click($event) {
        return _vm.onSubmitAll("submit");
      }
    }
  }, [_vm._v("一键报送")])]), _c("a-menu-item", {
    on: {
      click: function click($event) {
        return _vm.selectSubmit();
      }
    }
  }, [_c("a", [_vm._v("选择报送")])])], 1)], 1) : _vm._e()], 1) : _c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_vm.$hasPermission("zhinengsuanshui:renyuanbaosong.daibaosong:huoqubaosongjieguo") ? _c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: !_vm.tableData.length,
      loading: _vm.feedBackLoading
    },
    on: {
      click: function click($event) {
        return _vm.onFeedBack("submit");
      }
    }
  }, [_vm._v("获取报送结果")]) : _vm._e(), _vm.$hasPermission("zhinengsuanshui:renyuanbaosong.daibaosong:baosong") ? _c("a-popover", {
    staticStyle: {
      "margin-right": "0"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("请获取上次报送结果后，再进行下次报送")])]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: ""
    }
  }, [_vm._v("报送")])], 2) : _vm._e()], 1)], 1) : _vm._e(), _vm.$route.meta.tabType == "2" ? _c("div", [_vm.$hasPermission("zhinengsuanshui:renyuanbaosong.yibaosong:export") ? _c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: !_vm.tableData.length
    },
    on: {
      click: _vm.onDownload
    }
  }, [_vm._v("导出")]) : _vm._e(), !_vm.stateActive ? _c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_vm.$hasPermission("zhinengsuanshui:renyuanbaosong.yibaosong:baosongfankui") ? _c("a-popover", {
    staticStyle: {
      "margin-right": "0"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("请进行报送后，再点击获取结果")])]), _c("a-button", {
    staticClass: "mr12",
    attrs: {
      type: "primary",
      disabled: ""
    }
  }, [_vm._v("获取报送结果")])], 2) : _vm._e(), _vm.$hasPermission("zhinengsuanshui:renyuanbaosong.yibaosong:tingzhibaosong") ? _c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: function click($event) {
        _vm.visible = true;
      }
    }
  }, [_vm._v("停止报送")]) : _vm._e(), _vm.$hasPermission("zhinengsuanshui:renyuanbaosong.yibaosong:update") ? _c("a-dropdown", {
    attrs: {
      disabled: !_vm.tableData.length
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("信息更新")]), _c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_c("a-menu-item", [_c("a", {
    on: {
      click: function click($event) {
        return _vm.onSubmitAll("update");
      }
    }
  }, [_vm._v("一键报送")])]), _c("a-menu-item", {
    on: {
      click: function click($event) {
        return _vm.selectSubmit();
      }
    }
  }, [_c("a", [_vm._v("选择报送")])])], 1)], 1) : _vm._e()], 1) : _c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_vm.$hasPermission("zhinengsuanshui:renyuanbaosong.yibaosong:baosongfankui") ? _c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: !_vm.tableData.length,
      loading: _vm.feedBackLoading
    },
    on: {
      click: function click($event) {
        return _vm.onFeedBack(_vm.textFeedBack);
      }
    }
  }, [_vm._v("获取报送结果")]) : _vm._e(), _vm.$hasPermission("zhinengsuanshui:renyuanbaosong.yibaosong:tingzhibaosong") ? _c("a-popover", [_c("template", {
    slot: "content"
  }, [_c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("请获取上次报送结果后，再进行下次报送")])]), _c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: ""
    }
  }, [_vm._v("停止报送")])], 2) : _vm._e(), _vm.$hasPermission("zhinengsuanshui:renyuanbaosong.yibaosong:update") ? _c("a-popover", [_c("template", {
    slot: "content"
  }, [_c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("请获取上次报送结果后，再进行下次报送")])]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: ""
    }
  }, [_vm._v("信息更新")])], 2) : _vm._e()], 1)], 1) : _vm._e(), _vm.$route.meta.tabType == "3" ? _c("div", [_vm.$hasPermission("zhinengsuanshui:renyuanbaosong.tingzhibaosong:export") ? _c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: !_vm.tableData.length
    },
    on: {
      click: _vm.onDownload
    }
  }, [_vm._v("导出")]) : _vm._e(), !_vm.stateActive ? _c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_vm.$hasPermission("zhinengsuanshui:renyuanbaosong.tingzhibaosong:baosongfankui") ? _c("a-popover", {
    staticStyle: {
      "margin-right": "0"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("请进行报送后，再点击获取结果")])]), _c("a-button", {
    staticClass: "mr12",
    attrs: {
      type: "primary",
      disabled: ""
    }
  }, [_vm._v("获取报送结果")])], 2) : _vm._e(), _vm.$hasPermission("zhinengsuanshui:renyuanbaosong.tingzhibaosong:huifubaosong") ? _c("a-dropdown", {
    attrs: {
      disabled: !_vm.tableData.length
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("恢复报送")]), _c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_c("a-menu-item", [_c("a", {
    on: {
      click: function click($event) {
        return _vm.onSubmitAll("recover");
      }
    }
  }, [_vm._v("一键报送")])]), _c("a-menu-item", {
    on: {
      click: function click($event) {
        return _vm.selectSubmit();
      }
    }
  }, [_c("a", [_vm._v("选择报送")])])], 1)], 1) : _vm._e()], 1) : _c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_vm.$hasPermission("zhinengsuanshui:renyuanbaosong.tingzhibaosong:baosongfankui") ? _c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: !_vm.tableData.length,
      loading: _vm.feedBackLoading
    },
    on: {
      click: function click($event) {
        return _vm.onFeedBack("recover");
      }
    }
  }, [_vm._v("获取报送结果")]) : _vm._e(), _vm.$hasPermission("zhinengsuanshui:renyuanbaosong.tingzhibaosong:huifubaosong") ? _c("a-popover", {
    staticStyle: {
      "margin-right": "0"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("span", {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v("请获取上次报送结果后，再进行下次报送")])]), _c("a-button", {
    attrs: {
      type: "primary",
      disabled: ""
    }
  }, [_vm._v("恢复报送")])], 2) : _vm._e()], 1)], 1) : _vm._e()]), _c("template", {
    slot: "selection-btn"
  }, [_c("div", {
    staticClass: "pr24",
    staticStyle: {
      "text-align": "right",
      width: "calc(100% - 24px)",
      display: "flex",
      "justify-content": "center",
      top: "15px"
    }
  }, [_c("a-button", {
    staticClass: "mr12",
    on: {
      click: function click($event) {
        return _vm.$refs.TableBody.TBFclose();
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v("报送")])], 1)]), [_c("XqTable", {
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      rowSelection: _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      } : null
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(_vm._s(col.customTitle))])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "status" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(_vm.peopleStatusJson[text]))]) : col.dataIndex === "employmentType" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(_vm.employmentTypeJson[text]))]) : col.dataIndex === "submissionState" ? _c("div", {
            key: index
          }, [_vm.submissionStateJson[text] === "报送中" ? _c("a-tag", {
            attrs: {
              color: "#386FD7"
            }
          }, [_vm._v(_vm._s(_vm.submissionStateJson[text]))]) : _vm.submissionStateJson[text] === "报送失败" ? _c("a-tag", {
            attrs: {
              color: "#FE5770"
            }
          }, [_vm._v(_vm._s(_vm.submissionStateJson[text]))]) : _vm.submissionStateJson[text] === "报送成功" ? _c("a-tag", {
            attrs: {
              color: "#24CDAA"
            }
          }, [_vm._v(_vm._s(_vm.submissionStateJson[text]))]) : _c("span", [_vm._v(_vm._s(_vm.submissionStateJson[text]))])], 1) : col.dataIndex === "authenticationState" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(_vm.authenticationStateJson[text]))]) : col.dataIndex === "isComplete" ? _c("div", {
            key: index
          }, [_vm._v(_vm._s(text ? "是" : "否"))]) : col.dataIndex === "submissionDate" ? _c("div", {
            key: index
          }, [_vm._v(" " + _vm._s(text ? _vm.moment(text).format("YYYY-MM-DD HH:mm:ss") : "--") + " ")]) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  })]], 2), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"],
      "page-size": _vm.pageSize
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1), _c("popUp", {
    ref: "popVal"
  }), _c("stopReport", {
    attrs: {
      visible: _vm.visible,
      recordIds: _vm.selectedRowKeys,
      companyAuthId: _vm.id
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      upDate: _vm.stopOnGetPageSubmit
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };