export default {
  props: ["data"],
  data: function data() {
    return {
      visible: true
    };
  },
  methods: {
    handleOk: function handleOk(e) {
      this.$emit("visibleApprovalModal");
    }
  }
};