import "core-js/modules/es.number.constructor.js";
var dataList = [{
  icon: "icon-yanglaobaoxian1",
  name: "养老保险（元）",
  num: "--",
  compareNum: "--"
}, {
  icon: "icon-yiliaobaoxian",
  name: "医疗保险（元）",
  num: "--",
  compareNum: "--"
}, {
  icon: "icon-shiyebaoxian",
  name: "失业保险（元）",
  num: "--",
  compareNum: "--"
}, {
  icon: "icon-gongshangbaoxian",
  name: "工伤保险（元）",
  num: "--",
  compareNum: "--"
}, {
  icon: "icon-shengyubaoxian",
  name: "生育保险（元）",
  num: "--",
  compareNum: "--"
}, {
  icon: "icon-dabingyibao",
  name: "补充大病医保（元）",
  num: "--",
  compareNum: "--"
}, {
  icon: "icon-buchongyanglao",
  name: "补充养老保险（元）",
  num: "--",
  compareNum: "--"
}, {
  icon: "icon-canbaojin",
  name: "残保金（元）",
  num: "--",
  compareNum: "--"
}, {
  icon: "icon-qita",
  name: "其他（元）",
  num: "--",
  compareNum: "--"
}];
import { selectSocProgItem } from "@/api/shebao/statistic";
export default {
  props: {
    dateBox: {
      type: Object,
      default: function _default() {
        return {
          dataOfNum: []
        };
      }
    },
    queryType: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      borderImg: require("@/assets/img/statistic/border.png"),
      upImg: require("@/assets/img/statistic/rise.png"),
      downImg: require("@/assets/img/statistic/down2.png"),
      detailList: {},
      companyId: this.$store.state.hr.companyId,
      dataList: dataList
    };
  },
  watch: {
    dateBox: {
      handler: function handler(val, oldVal) {
        this.grtSelectSocProgItem();
      },
      deep: true
    },
    queryType: {
      handler: function handler(val, oldVal) {
        this.grtSelectSocProgItem();
      },
      deep: true
    }
  },
  created: function created() {
    // this.grtSelectSocProgItem();
  },
  methods: {
    grtSelectSocProgItem: function grtSelectSocProgItem() {
      var _this = this;
      selectSocProgItem({
        companyId: this.companyId,
        startMonth: this.dateBox.startMonth,
        endMonth: this.dateBox.endMonth,
        startYear: this.dateBox.startYear,
        endYear: this.dateBox.endYear,
        queryType: this.queryType,
        step: this.dateBox.step,
        isAcrossYear: this.dateBox.startYear !== this.dateBox.endYear
      }).then(function (res) {
        _this.detailList = res.data.socDetail;
        _this.dataList[0].num = res.data.socDetail["养老保险"] ? res.data.socDetail["养老保险"] : "--";
        _this.dataList[1].num = res.data.socDetail["医疗保险"] ? res.data.socDetail["医疗保险"] : "--";
        _this.dataList[2].num = res.data.socDetail["失业保险"] ? res.data.socDetail["失业保险"] : "--";
        _this.dataList[3].num = res.data.socDetail["工伤保险"] ? res.data.socDetail["工伤保险"] : "--";
        _this.dataList[4].num = res.data.socDetail["生育保险"] ? res.data.socDetail["生育保险"] : "--";
        _this.dataList[8].num = res.data.socDetail["其他"] ? res.data.socDetail["其他"] : "--";
        _this.dataList[0].compareNum = res.data.socDetailDifference["养老保险"] ? res.data.socDetailDifference["养老保险"] : "--";
        _this.dataList[1].compareNum = res.data.socDetailDifference["医疗保险"] ? res.data.socDetailDifference["医疗保险"] : "--";
        _this.dataList[2].compareNum = res.data.socDetailDifference["失业保险"] ? res.data.socDetailDifference["失业保险"] : "--";
        _this.dataList[3].compareNum = res.data.socDetailDifference["工伤保险"] ? res.data.socDetailDifference["工伤保险"] : "--";
        _this.dataList[4].compareNum = res.data.socDetailDifference["生育保险"] ? res.data.socDetailDifference["生育保险"] : "--";
        _this.dataList[8].compareNum = res.data.socDetailDifference["其他"] ? res.data.socDetailDifference["其他"] : "--";
      });
    }
  }
};