var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack pb32"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("导入算税数据")]), _c("div", {
    staticStyle: {
      padding: "24px"
    }
  }, [_vm.failList.length !== 0 ? _c("a-alert", {
    staticStyle: {
      "background-color": "#fff1f0"
    },
    attrs: {
      type: "error",
      message: _vm.message,
      banner: ""
    }
  }, [_c("span", {
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_c("span", {
    staticClass: "exportErrData",
    on: {
      click: _vm.exportErrData
    }
  }, [_vm._v("导出错误数据")])])]) : _c("a-alert", {
    attrs: {
      message: _vm.message,
      type: "info",
      "show-icon": ""
    }
  }), _c("a-table", {
    staticStyle: {
      padding: "24px 0"
    },
    attrs: {
      columns: _vm.columns,
      bordered: "",
      "data-source": _vm.data
    },
    scopedSlots: _vm._u([{
      key: "reason",
      fn: function fn(text, record) {
        return _c("div", {
          staticStyle: {
            position: "relative"
          }
        }, [record.reason ? _c("a-popover", {
          attrs: {
            title: "数据错误",
            placement: "right"
          }
        }, [_c("template", {
          slot: "content"
        }, [_c("p", [_vm._v(_vm._s(record.reason))])]), _c("div", {
          staticClass: "triangle"
        })], 2) : _vm._e(), record.reason ? _c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(text))])]), _c("div", {
          staticClass: "overflowsIdentity",
          staticStyle: {
            height: "22px"
          }
        }, [_vm._v(_vm._s(record.reason))])], 2) : _vm._e()], 1);
      }
    }])
  })], 1), _c("div", {
    staticClass: "boxBottom"
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.nextStep
    }
  }, [_vm._v("下一步")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };