import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("fTitle", {
    staticStyle: {
      "margin-top": "0"
    },
    attrs: {
      tit: "基础信息"
    }
  }), _c("a-form-item", {
    attrs: {
      label: "职位名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postName"],
      expression: "['postName']"
    }],
    attrs: {
      disabled: "",
      placeholder: "请输入职位名称"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "招工企业"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyUnitId"],
      expression: "['companyUnitId']"
    }],
    attrs: {
      disabled: "",
      placeholder: "选择招工企业"
    },
    on: {
      change: _vm.onCompanyChange
    }
  }, _vm._l(_vm.companyIdArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "岗位招聘负责人员"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["contactUserId"],
      expression: "['contactUserId']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.adminUsers,
      disabled: "",
      placeholder: "请选择负责人员"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "岗位联系电话"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["phone"],
      expression: "['phone']"
    }],
    attrs: {
      disabled: "",
      placeholder: "请输入联系电话"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "职位类型"
    }
  }, [_c("a-cascader", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["positionType"],
      expression: "['positionType']"
    }],
    attrs: {
      disabled: "",
      options: _vm.positionTypes,
      fieldNames: {
        label: "name",
        value: "id",
        children: "childList"
      },
      placeholder: "请选择职位类型"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "学历经验"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["workingSeniority"],
      expression: "['workingSeniority']"
    }],
    staticStyle: {
      width: "49%",
      "margin-right": "2%"
    },
    attrs: {
      disabled: "",
      placeholder: "选择经验"
    }
  }, _vm._l(_vm.workingSeniorityArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.v) + " ")]);
  }), 1), _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["lowestEducation"],
      expression: "['lowestEducation']"
    }],
    staticStyle: {
      width: "49%"
    },
    attrs: {
      disabled: "",
      placeholder: "选择学历"
    }
  }, _vm._l(_vm.lowestEducationArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.v) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "工作城市"
    }
  }, [_c("a-cascader", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["cityName"],
      expression: "['cityName']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.common.cityList,
      disabled: "",
      placeholder: "请选择工作城市"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "具体工作地点"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["detailedAddress"],
      expression: "['detailedAddress']"
    }],
    attrs: {
      disabled: "",
      placeholder: "请输入工作地点"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "是否急招"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["isEmergency", {
        initialValue: false
      }],
      expression: "['isEmergency', { initialValue: false }]"
    }],
    attrs: {
      disabled: ""
    }
  }, _vm._l(_vm.isEmergencyArr, function (item) {
    return _c("a-radio", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.v) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "工作性质"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["workType", {
        initialValue: 1
      }],
      expression: "['workType', { initialValue: 1 }]"
    }],
    attrs: {
      disabled: ""
    }
  }, _vm._l(_vm.workTypeArr, function (item) {
    return _c("a-radio", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.v) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "职位描述"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postDescribe"],
      expression: "['postDescribe']"
    }],
    attrs: {
      disabled: "",
      placeholder: "请输入职位描述",
      "auto-size": {
        minRows: 8,
        maxRows: 15
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "招聘类型"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["recruitType"],
      expression: "['recruitType']"
    }],
    attrs: {
      disabled: ""
    }
  }, [_c("a-radio", {
    key: "cqzp",
    attrs: {
      value: 1
    }
  }, [_vm._v("长期招聘")]), _c("a-radio", {
    key: "zmjt",
    attrs: {
      value: 2
    }
  }, [_vm._v("招满即停")])], 1)], 1), _vm.dataJson.recruitType === 2 ? _c("a-form-item", {
    attrs: {
      label: "招聘人数"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["number"],
      expression: "['number']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      disabled: ""
    }
  })], 1) : _vm._e(), _c("fTitle", {
    staticStyle: {
      "margin-top": "48px"
    },
    attrs: {
      tit: "薪资及福利"
    }
  }), _c("a-form-item", {
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      label: "薪资范围"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["lowestSalary1", {
        rules: [{
          required: true,
          message: ""
        }]
      }],
      expression: "['lowestSalary1', { rules: [{ required: true, message: '' }] }]"
    }],
    attrs: {
      disabled: ""
    }
  }), _c("a-form-item", {
    staticStyle: {
      display: "inline-block",
      width: "30%",
      "margin-right": "2%"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["lowestSalary", {
        rules: [{
          required: true,
          message: "薪资范围请正确输入薪资"
        }]
      }],
      expression: "['lowestSalary', { rules: [{ required: true, message: '薪资范围请正确输入薪资' }] }]"
    }],
    attrs: {
      disabled: "",
      max: this.form.getFieldValue("highestSalary") || 0
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      display: "inline-block",
      width: "30%"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["highestSalary", {
        rules: [{
          required: true,
          message: "薪资范围请正确输入薪资"
        }]
      }],
      expression: "['highestSalary', { rules: [{ required: true, message: '薪资范围请正确输入薪资' }] }]"
    }],
    attrs: {
      disabled: "",
      min: this.form.getFieldValue("lowestSalary") || 0
    }
  })], 1), _c("span", {
    staticStyle: {
      display: "inline-block",
      width: "6%",
      "text-align": "center"
    }
  }, [_vm._v(" x ")]), _c("a-form-item", {
    staticStyle: {
      display: "inline-block",
      width: "32%"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["salaryGrantType", {
        initialValue: 1
      }],
      expression: "['salaryGrantType', { initialValue: 1 }]"
    }],
    attrs: {
      disabled: "",
      placeholder: "选择月份"
    }
  }, _vm._l(_vm.monthlySalaryArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.v) + " ")]);
  }), 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "发薪日"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["payday", {
        initialValue: 1
      }],
      expression: "['payday', { initialValue: 1 }]"
    }],
    attrs: {
      disabled: "",
      placeholder: "选择月份"
    }
  }, [_vm._l(30, function (item) {
    return _c("a-select-option", {
      key: item,
      attrs: {
        value: item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), _vm._l(_vm.paydayArr, function (item) {
    return _c("a-select-option", {
      key: item.val,
      attrs: {
        value: item.val
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2)], 1), _c("a-form-item", {
    attrs: {
      label: "福利待遇"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      disabled: "",
      maxTagCount: 3,
      mode: "tags",
      placeholder: "请选择福利"
    },
    model: {
      value: _vm.highlights,
      callback: function callback($$v) {
        _vm.highlights = $$v;
      },
      expression: "highlights"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("fTitle", {
    staticStyle: {
      "margin-top": "0"
    },
    attrs: {
      tit: "办公环境"
    }
  }), _c("a-form-item", {
    attrs: {
      label: ""
    }
  }, [_c("a-upload", {
    staticClass: "umsg",
    attrs: {
      "list-type": "picture-card",
      action: "/file/api/v1/general/uploadImg",
      name: "files",
      "file-list": _vm.fileList
    },
    on: {
      preview: _vm.handlePreview
    }
  }), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: function cancel($event) {
        _vm.previewVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "mt20"
  }), _c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1), _c("fTitle", {
    attrs: {
      tit: "视频封面"
    }
  }), _c("a-form-item", {
    attrs: {
      label: ""
    }
  }, [_c("a-upload", {
    staticClass: "umsg",
    attrs: {
      "list-type": "picture-card",
      action: "/file/api/v1/general/uploadImg",
      name: "files",
      "file-list": _vm.video
    },
    on: {
      preview: _vm.handlePreview
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };