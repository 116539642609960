import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import { selectByUserApproval } from "../api";
export default {
  props: ["approvalId"],
  data: function data() {
    return {
      noApproval: false,
      annexName: "",
      annexUrl: "",
      autoSystem: "",
      ColumnsLength: 0,
      data: [],
      headList: [],
      infoList: []
    };
  },
  watch: {
    approvalId: {
      handler: function handler(val) {
        this.selectByUserApprovalId();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    openAnnexUrl: function openAnnexUrl() {},
    getColumns: function getColumns() {
      var data = [{
        title: "姓名",
        width: 100,
        align: "center",
        dataIndex: "name"
      }, {
        title: "身份证号",
        width: 100,
        align: "center",
        dataIndex: "idCard"
      }, {
        title: "总缴费",
        width: 100,
        align: "center",
        dataIndex: "totalAmount"
      }];
      this.ColumnsLength = [].concat(data, _toConsumableArray(this.headList)).length;
      return [].concat(data, _toConsumableArray(this.headList));
    },
    selectByUserApprovalId: function selectByUserApprovalId() {
      var _this = this;
      selectByUserApproval(this.approvalId).then(function (res) {
        if (res.data) {
          _this.noApproval = false;
          _this.autoSystem = res.data.autoSystem;
          if (_this.autoSystem) {
            var infoList = res.data.infoList;
            var infoListColumns = [];
            // 制作Columns
            infoList.map(function (z) {
              infoListColumns.push.apply(infoListColumns, _toConsumableArray(Object.keys(z.headDate)));
            });
            var headList = new Set(infoListColumns);
            _this.headList = _toConsumableArray(headList).map(function (i) {
              return {
                title: i,
                dataIndex: i,
                width: 100,
                align: "center"
              };
            });
            // 制作底部
            var data1 = _toConsumableArray(headList).reduce(function (res, key) {
              res[key] = null;
              return res;
            }, {});
            _this.data = infoList.map(function (i, index) {
              var dataShow = Object.keys(data1).reduce(function (res, key) {
                var val = Object.keys(i.headDate).filter(function (y) {
                  return y === key;
                });
                res[key] = i.headDate[val];
                return res;
              }, {});
              var objVal = Object.assign(dataShow, {
                key: index,
                name: i.name,
                idCard: i.idCard,
                totalAmount: i.totalAmount
              });
              return objVal;
            });
          } else {
            _this.annexUrl = res.data.annexUrl;
            _this.annexName = res.data.annexName;
            if (_this.annexUrl === null || _this.annexUrl === "") {
              _this.$emit("getappRovalDet");
            }
          }
        } else {
          _this.noApproval = true;
        }
      });
    }
  }
};