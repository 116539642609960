var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "setter-wrapper-outer"
  }, [_c("div", {
    staticClass: "setter-wrapper"
  }, [_c("a-form", [_c("a-form-item", {
    attrs: {
      label: "审批去重"
    }
  }, [_c("a-select", {
    model: {
      value: _vm.value.uplicateRemoval,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "uplicateRemoval", $$v);
      },
      expression: "value.uplicateRemoval"
    }
  }, _vm._l(_vm.options, function (item, i) {
    return _c("a-select-option", {
      key: i,
      attrs: {
        value: item.type
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "审批意见填写提示(最多100字)"
    }
  }, [_c("a-textarea", {
    attrs: {
      placeholder: "写点什么...",
      "max-length": 100,
      rows: 4
    },
    model: {
      value: _vm.value.tipsRemake,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "tipsRemake", $$v);
      },
      expression: "value.tipsRemake"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "审批意见"
    }
  }, [_c("a-checkbox", {
    model: {
      value: _vm.value.remakeRequire,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "remakeRequire", $$v);
      },
      expression: "value.remakeRequire"
    }
  }, [_vm._v("必填")]), _c("a-checkbox", {
    model: {
      value: _vm.value.remakeShow,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "remakeShow", $$v);
      },
      expression: "value.remakeShow"
    }
  }, [_vm._v("对发起可见")])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };