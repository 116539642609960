import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wage-set-rule-detail-wrapper page-miH page-bgBack"
  }, [_c("a-row", {
    staticClass: "head"
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goDetail
    }
  }), _c("div", {
    staticClass: "left2",
    on: {
      click: _vm.goDetail
    }
  }, [_vm._v("返回")])], 1), _c("a-col", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("计税规则详情")])])], 1), _c("div", {
    staticClass: "rule-item-wrapper"
  }, [_c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("方案名称")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.dataDetail.name))])]), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("个税类型")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.taxRule[_vm.dataDetail.type - 1]))])]), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("描述")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.dataDetail.description))])]), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("是否计税")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.dataDetail.isCalculate ? "是" : "否"))])]), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("起征点")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.dataDetail.threshold === 0 ? "-" : "".concat(_vm.dataDetail.threshold, "/\u6708")))])]), _c("div", {
    staticClass: "rule-item"
  }, [_c("span", {
    staticClass: "rule-item-title"
  }, [_vm._v("个税结果")]), _c("span", {
    staticClass: "rule-item-value"
  }, [_vm._v(_vm._s(_vm.dataDetail.type === 3 ? "-" : _vm.dataDetail.taxResult))])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };