export function getPostType(vl) {
  switch (vl) {
    case 0:
      return "未参保";
    case 1:
      return "正常参保";
    case 2:
      return "增员";
    case 3:
      return "待增员";
    case 4:
      return "减员";
    case 5:
      return "待减员";
    case 6:
      return "停保";
  }
}