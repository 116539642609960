var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "编辑",
      "confirm-loading": _vm.confirmLoading,
      width: 640
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "box"
  }, _vm._l(_vm.arr, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "box-item"
    }, [_c("div", {
      staticClass: "box-label"
    }, [_vm._v(_vm._s(item.label) + "：")]), _c("div", {
      style: index === 4 ? "color:#6A66F6" : ""
    }, [_vm._v(_vm._s(item.v))])]);
  }), 0), _c("a-form", {
    staticStyle: {
      "margin-left": "-18px"
    },
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "工作情况"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["entryStatus", {
        initialValue: "1"
      }],
      expression: "['entryStatus', { initialValue: '1' }]"
    }]
  }, [_c("a-radio", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 在职 ")]), _c("a-radio", {
    attrs: {
      value: "2"
    }
  }, [_vm._v(" 已离职 ")])], 1)], 1), _vm.form.getFieldValue("entryStatus") == 2 ? _c("a-form-item", {
    attrs: {
      label: "离职日期"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["leaveDate"],
      expression: "['leaveDate']"
    }],
    attrs: {
      format: "YYYY-MM-DD"
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "核算情况"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["settlementStatus", {
        initialValue: "1",
        rules: [{
          required: true,
          message: ""
        }]
      }],
      expression: "['settlementStatus', { initialValue: '1', rules: [{ required: true, message: '' }] }]"
    }]
  }, [_c("a-radio", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 核算 ")]), _c("a-radio", {
    attrs: {
      value: "0"
    }
  }, [_vm._v(" 未核算 ")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };