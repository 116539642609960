import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack pb32"
  }, [_c("a-form", {
    staticClass: "forms pt32",
    attrs: {
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "发薪批次名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["orderTitle", {
        rules: [{
          required: true,
          message: "发薪批次名称不能为空"
        }]
      }],
      expression: "['orderTitle', { rules: [{ required: true, message: '发薪批次名称不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入",
      maxLength: 50
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      "margin-top": "-8px"
    },
    attrs: {
      label: "到账方式"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        rules: [{
          required: true,
          message: "到账方式不能为空"
        }]
      }],
      expression: "[\n          'type',\n          {\n            rules: [\n              {\n                required: true,\n                message: '到账方式不能为空'\n              }\n            ]\n          }\n        ]"
    }],
    attrs: {
      placeholder: "请选择"
    }
  }, _vm._l(_vm.typeArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    staticStyle: {
      "margin-top": "-8px"
    },
    attrs: {
      label: "选择用工单位"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyId", {
        rules: [{
          required: true,
          message: "用工单位不能为空"
        }]
      }],
      expression: "[\n          'companyId',\n          {\n            rules: [\n              {\n                required: true,\n                message: '用工单位不能为空'\n              }\n            ]\n          }\n        ]"
    }],
    attrs: {
      "show-search": "",
      "filter-option": _vm.filterOption,
      placeholder: "请选择"
    },
    on: {
      change: _vm.change
    }
  }, _vm._l(_vm.companyIdArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _vm.form.getFieldsValue(["companyId"]).companyId ? _c("a-form-item", {
    staticStyle: {
      "margin-top": "-8px"
    },
    attrs: {
      label: "选择发薪员工"
    }
  }, [_c("div", {
    staticClass: "qy"
  }, [_c("a-button", {
    staticClass: "mt4 mr12",
    on: {
      click: function click($event) {
        _vm.selectScopeVisible = true;
      }
    }
  }, [_vm._v(" 选择人员 ")]), _vm.selectedListTemp.length > 0 ? _c("div", {
    staticClass: "yxz",
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "flex-wrap": "wrap"
    }
  }, [_vm._v(" 已选择 "), _vm._l(_vm.selectedListTemp, function (item, index) {
    return _c("span", {
      key: index
    }, [index < 3 ? _c("span", [_vm._v(" " + _vm._s(item.name) + " "), index !== _vm.selectedListTemp.length - 1 ? _c("span", [_vm._v(" 、")]) : _vm._e()]) : _vm._e()]);
  }), _vm.selectedListTemp.length > 2 ? _c("span", [_vm._v(" 等 "), _c("span", {
    staticClass: "icon-color"
  }, [_vm._v(_vm._s(_vm.selectedListTemp.length))]), _vm._v(" 人 ")]) : _vm._e()], 2) : _c("span", [_vm._v("当前未选择")])], 1)]) : _vm._e(), _c("a-form-item", {
    staticStyle: {
      "margin-top": "-8px"
    },
    attrs: {
      label: "备注"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["remark"],
      expression: "['remark']"
    }],
    attrs: {
      placeholder: "请输入",
      "auto-size": {
        minRows: 2,
        maxRows: 15
      }
    }
  })], 1), _c("a-form-item", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleOk
    }
  }, [_vm._v(" 提交 ")])], 1), _c("selectScope", {
    attrs: {
      companyId: _vm.form.getFieldsValue(["companyId"]).companyId,
      visible: _vm.selectScopeVisible,
      selectedList: _vm.selectedListTemp
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.selectScopeVisible = $event;
      },
      upDate: _vm.upDate
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };