import request from "@/utils/request";
export function getPageList(params) {
  return request({
    url: "/hr/api/v1/attendanceDataRecords/pageList",
    method: "GET",
    params: params
  });
}
export function delAttendanceDataRecords(attendanceDataRecordId) {
  return request({
    url: "/hr/api/v1/attendanceDataRecords/".concat(attendanceDataRecordId),
    method: "delete"
  });
}