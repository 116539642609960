import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "new-sidebar-wrapper",
    class: {
      "has-logo": _vm.hasLogo,
      collapsed: _vm.isCollapsed
    }
  }, [_c("xq-scrollbar", {
    staticClass: "sidebar-scroll-wrapper"
  }, [_c("a-menu", {
    attrs: {
      "open-keys": _vm.openKeys,
      mode: "inline",
      theme: "light"
    },
    on: {
      "update:openKeys": function updateOpenKeys($event) {
        _vm.openKeys = $event;
      },
      "update:open-keys": function updateOpenKeys($event) {
        _vm.openKeys = $event;
      }
    },
    model: {
      value: _vm.selectedKeys,
      callback: function callback($$v) {
        _vm.selectedKeys = $$v;
      },
      expression: "selectedKeys"
    }
  }, [_vm._l(_vm.aMenu, function (route) {
    return [!route.children ? [_c("a-menu-item", {
      key: route.router
    }, [_c("router-link", {
      attrs: {
        to: route.router
      }
    }, [_c("item", {
      attrs: {
        icon: route.icon,
        title: route.name
      }
    })], 1)], 1)] : _c("sub-menu", {
      key: route.router,
      attrs: {
        item: route
      }
    })];
  })], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };