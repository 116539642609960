var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", [_c("a-row", {
    staticClass: "topline",
    attrs: {
      type: "flex"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  }), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goback
    }
  }, [_vm._v("返回")]), _c("h3", [_vm._v(_vm._s(_vm.pageTitle))]), _c("a-icon", {
    attrs: {
      type: "add"
    }
  })], 1), _c("a-layout-content", {
    staticClass: "stepbox"
  }, [_c("a-steps", {
    attrs: {
      current: _vm.current
    }
  }, [_c("a-step", {
    attrs: {
      title: "编辑公告"
    }
  }), _c("a-step", {
    attrs: {
      title: "预览发布"
    }
  })], 1), _c("EditNew", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.current === 1,
      expression: "current === 1"
    }],
    attrs: {
      "notice-title": _vm.noticeTitle,
      "notice-file-list": _vm.noticeFileList,
      "notice-time": _vm.noticeTimeString,
      "notice-content": _vm.noticeContent,
      "notice-content-has-style": _vm.noticeContentHasStyle,
      "notice-file-list-init": _vm.noticeFileList,
      "notice-title-init": _vm.noticeTitle,
      "notice-time-init": _vm.noticeTime,
      "notice-content-init": _vm.noticeContentHasStyle
    },
    on: {
      "update:noticeTitle": function updateNoticeTitle($event) {
        _vm.noticeTitle = $event;
      },
      "update:notice-title": function updateNoticeTitle($event) {
        _vm.noticeTitle = $event;
      },
      "update:noticeFileList": function updateNoticeFileList($event) {
        _vm.noticeFileList = $event;
      },
      "update:notice-file-list": function updateNoticeFileList($event) {
        _vm.noticeFileList = $event;
      },
      "update:noticeTime": function updateNoticeTime($event) {
        _vm.noticeTimeString = $event;
      },
      "update:notice-time": function updateNoticeTime($event) {
        _vm.noticeTimeString = $event;
      },
      "update:noticeContent": function updateNoticeContent($event) {
        _vm.noticeContent = $event;
      },
      "update:notice-content": function updateNoticeContent($event) {
        _vm.noticeContent = $event;
      },
      "update:noticeContentHasStyle": function updateNoticeContentHasStyle($event) {
        _vm.noticeContentHasStyle = $event;
      },
      "update:notice-content-has-style": function updateNoticeContentHasStyle($event) {
        _vm.noticeContentHasStyle = $event;
      },
      current: _vm.stepModulechange
    }
  }), _c("PreviewNew", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.current === 2,
      expression: "current === 2"
    }],
    attrs: {
      "news-type-id": _vm.newsTypeId,
      "notice-title": _vm.noticeTitle,
      "notice-file-list": _vm.noticeFileList,
      "group-code": _vm.groupCode,
      "notice-time": _vm.noticeTimeString,
      "notice-content": _vm.noticeContent,
      "notice-content-has-style": _vm.noticeContentHasStyle,
      "notice-content-init-sync": _vm.noticeContentHasStyle,
      "notice-title-init-sync": _vm.noticeTitle
    },
    on: {
      "update:noticeFileList": function updateNoticeFileList($event) {
        _vm.noticeFileList = $event;
      },
      "update:notice-file-list": function updateNoticeFileList($event) {
        _vm.noticeFileList = $event;
      },
      current: _vm.stepModulechange
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };