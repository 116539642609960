import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";

// 根据公司id查询公司员工所需信息详情
export function getEmpInfoRequires(companyId, type) {
  return request({
    url: "/hr/api/v1/companyEmpInfoRequires/byCompanyId/".concat(companyId, "/").concat(type),
    method: "get"
  });
}
export function putEmpInfoRequires(companyEmpInfoRequireId, code, value) {
  return request({
    url: "/hr/api/v1/companyEmpInfoRequires/updateRequireById/".concat(companyEmpInfoRequireId, "/").concat(code, "/").concat(value),
    method: "put"
  });
}

// 修改公司员工所需信息
export function putEmpRequires(data) {
  return request({
    url: "/hr/api/v1/companyEmpInfoRequires/updateRalation",
    method: "PUT",
    data: data
  });
}
// 查询公司员工必填字段信息
export function getNecessaryFields(params) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/listAllForPc",
    method: "get",
    params: params
  });
}

// 修改公司员工必填字段信息
export function postNecessaryFieldBatchDTO(data) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields",
    method: "put",
    data: data
  });
}
// 新增公司员工额外字段表
export function postAdditionalFieldsAddDTO(data) {
  return request({
    url: "/hr/api/v1/companyAdditionalFields/save2",
    method: "post",
    data: data
  });
}
// 查询公司员工额外字段表
export function getAdditionalFieldsQuery(params) {
  return request({
    url: "/hr/api/v1/companyAdditionalFields/listAll",
    method: "get",
    params: params
  });
}
// 删除公司员工额外字段表
export function deleteAdditionalFieldsQuery(id) {
  var url = "/hr/api/v1/companyAdditionalFields/deleteForPc2/".concat(id);
  return request({
    url: url,
    method: "delete"
  });
}
// 修改公司员工额外字段信息
export function putAdditionalFieldsUpdateDTO(data) {
  return request({
    url: "/hr/api/v1/companyAdditionalFields/update2",
    method: "put",
    data: data
  });
}
// 修改公司员工额外字段信息
export function putAdditionalFieldsOpen(data) {
  return request({
    url: "/hr/api/v1/companyAdditionalFields/openField",
    method: "put",
    data: data
  });
}
// 修改公司员工额外字段信息
export function putAdditionalFieldsNecessary(data) {
  return request({
    url: "/hr/api/v1/companyAdditionalFields/necessaryField",
    method: "put",
    data: data
  });
}
// 修改模块是否必须添加
export function putcompanyEmpNecessaries(data) {
  return request({
    url: "/hr/api/v1/companyEmpNecessaries",
    method: "put",
    data: data
  });
}