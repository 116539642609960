import fieldTemplate from "@/components/fieldTemplate/fieldTemplate";
export default {
  components: {
    fieldTemplate: fieldTemplate
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};