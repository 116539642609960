import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page_set page-miH page-bgBack"
  }, [_vm.isOpen ? _c("open", {
    on: {
      change: _vm.open
    }
  }) : _vm._e(), _vm.data.length > 0 ? _c("a-layout", [_c("a-row", {
    staticStyle: {
      "margin-bottom": "25px"
    }
  }, [_c("a-col", {
    staticClass: "pageName",
    attrs: {
      span: 12
    }
  }, [_vm.data.length !== 0 ? _c("a-select", {
    staticStyle: {
      width: "136px"
    },
    attrs: {
      "default-value": "全部",
      placeholder: "搜索年份"
    },
    on: {
      change: _vm.handleYear
    }
  }, _vm._l(_vm.dataYear, function (item) {
    return _c("a-select-option", {
      key: item.year
    }, [_vm._v(_vm._s(item.year))]);
  }), 1) : _vm._e()], 1), _c("a-col", {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      span: 12
    }
  }, [_c("a-badge", {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      count: _vm.listcount,
      "overflow-count": 99
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.zongJianYuan
    }
  }, [_vm._v("增减员计划")])], 1), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addNextMonth
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加次月账单 ")], 1)], 1)], 1), _vm._l(_vm.data, function (item, i) {
    return _c("div", {
      key: i,
      staticStyle: {
        display: "flex",
        "justify-content": "space-between"
      }
    }, [_c("div", {
      staticClass: "fix-item-wrapper"
    }, [_c("div", {
      staticClass: "fix-item-one"
    }, [_c("p", {
      staticClass: "fix-item-name"
    }, [_vm._v(_vm._s(item.year) + "年" + _vm._s(item.month) + "月")]), item.monthWhetherCurrent && item.canNotEdited ? _c("div", {
      staticStyle: {
        "flex-flow": "row",
        width: "100%",
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        "margin-top": "10px"
      }
    }, [_c("p", {
      style: [{
        textAlign: "center"
      }, {
        color: "#24CDAA"
      }, {
        background: "#24CDAA36"
      }, {
        borderRadius: "3px 3px 3px 3px"
      }, {
        paddingLeft: "5px"
      }, {
        paddingRight: "5px"
      }, {
        border: "1px solid #24CDAA"
      }, {
        fontSize: "11px"
      }, {
        marginRight: "8px"
      }, {
        cursor: "pointer"
      }]
    }, [_vm._v(" 账单已生成 ")])]) : _vm._e()]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v("在保人数")]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.empSocInfoCount) + "人")])])]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v("社保缴费")]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v("¥" + _vm._s(item.socAmount) + "元")])])]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v("公积金缴费")]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v("¥" + _vm._s(item.pfAmount) + "元")])])]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v("本月增员人数")]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.monthIncrement) + "人")])])]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", [_c("p", {
      staticClass: "childpProjectName"
    }, [_vm._v("本月减员人数")]), _c("p", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.monthStop) + "人")])])]), _c("div", {
      staticClass: "fix-item-two2"
    }, [_c("div", {
      staticClass: "fix-item-one-end",
      staticStyle: {
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.enterDetail(item.id, item.month, item.year);
        }
      }
    }, [_vm._v("查看详情")]), item.monthWhetherCurrent ? _c("div", {
      staticClass: "fix-item-one-end",
      staticStyle: {
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.delet(item);
        }
      }
    }, [_vm._v("删除")]) : _vm._e()])])]);
  })], 2) : _vm._e(), _c("a-modal", {
    attrs: {
      title: "添加次月账单",
      visible: _vm.isNextVisible,
      width: "444px"
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "close",
    on: {
      click: _vm.closeNextDetail
    }
  }, [_vm._v("关闭")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.enterDetail(_vm.data[0].id, _vm.data[0].month, _vm.data[0].year);
      }
    }
  }, [_vm._v("查看详情")])], 1), _c("div", {
    staticClass: "modal-box"
  }, [_c("div", {
    staticStyle: {
      color: "#ff6d6d"
    }
  }, [_vm._v(" * "), _c("span", {
    staticStyle: {
      color: "#000000",
      "font-size": "14px"
    }
  }, [_vm._v(" 添加次月账单就会默认根据上月参保人账单和增减人员计划，智能计算 ")])]), _c("div", {
    staticClass: "table-box"
  }, [_c("div", {
    staticClass: "result-info-title"
  }, [_vm._v("计算结果")]), _c("a-form", {
    staticClass: "form-box",
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    staticClass: "form-item-box",
    attrs: {
      label: "参保月份"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.lastYear) + "年" + _vm._s(_vm.lastMonth) + "月")])]), _c("a-form-item", {
    staticClass: "form-item-box",
    attrs: {
      label: "参保人数"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.nextempSocInfoCount))])]), _c("a-form-item", {
    staticClass: "form-item-box",
    attrs: {
      label: "企业缴费"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.companyAmount))])]), _c("a-form-item", {
    staticClass: "form-item-box",
    attrs: {
      label: "个人缴费"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.personalAmount))])]), _c("a-form-item", {
    staticClass: "form-item-box",
    attrs: {
      label: "合计缴费"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.totalAmount))])])], 1)], 1)])], 2), _c("a-modal", {
    attrs: {
      title: "当前参保月份",
      visible: _vm.isVisible
    },
    on: {
      ok: _vm.enSure,
      cancel: _vm.handleClose
    }
  }, [_c("div", {
    staticClass: "modal-box"
  }, [_c("div", {
    staticStyle: {
      color: "#ff6d6d"
    }
  }, [_vm._v(" * "), _c("span", {
    staticStyle: {
      color: "#000000",
      "font-size": "14px"
    }
  }, [_vm._v(" " + _vm._s(_vm.tempYear != "" ? "参保月份：" + _vm.tempYear + "年" + _vm.tempMonth + "月" : "请选择当前的参保月份") + " ")])]), _c("a-form", {
    staticClass: "monthClose"
  }, [_c("a-input", {
    staticClass: "month-btn",
    attrs: {
      type: "button",
      "default-value": "".concat(_vm.prevMonth == "00" ? _vm.year - 1 : _vm.year, "\u5E74").concat(_vm.prevMonth == "00" ? "12" : _vm.prevMonth, "\u6708")
    },
    on: {
      click: function click($event) {
        return _vm.chhooseMonth(_vm.prevMonth == "00" ? _vm.year - 1 : _vm.year, _vm.prevMonth == "00" ? "12" : _vm.prevMonth);
      }
    }
  }), _c("a-input", {
    staticClass: "month-btn",
    attrs: {
      type: "button",
      "default-value": "".concat(_vm.year, "\u5E74").concat(_vm.nowMonth, "\u6708")
    },
    on: {
      click: function click($event) {
        return _vm.chhooseMonth(_vm.year, _vm.nowMonth);
      }
    }
  }), _c("a-input", {
    staticClass: "month-btn",
    attrs: {
      type: "button",
      "default-value": "".concat(_vm.nextMonth == "13" ? _vm.year + 1 : _vm.year, "\u5E74").concat(_vm.nextMonth == "13" ? "01" : _vm.nextMonth, "\u6708")
    },
    on: {
      click: function click($event) {
        return _vm.chhooseMonth(_vm.nextMonth == "13" ? _vm.year + 1 : _vm.year, _vm.nextMonth == "13" ? "01" : _vm.nextMonth);
      }
    }
  })], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };