import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.push({
          path: "/overview/staff"
        });
      }
    }
  }, [_vm._v(" 今日新增 ")]), _c("div", {
    staticClass: "overview--wrapper"
  }, [_c("div", {
    staticClass: "t-row"
  }, [_c("div", {
    staticClass: "t-col",
    on: {
      click: function click($event) {
        return _vm.onScreening(0);
      }
    }
  }, [_vm._v(" 今日新增："), _c("span", [_vm._v(_vm._s(_vm.allCount) + "人")])]), _c("div", {
    staticClass: "t-col",
    on: {
      click: function click($event) {
        return _vm.onScreening(1);
      }
    }
  }, [_vm._v(" 网页端录入："), _c("span", [_vm._v(_vm._s(_vm.pcCount) + "人")])]), _c("div", {
    staticClass: "t-col",
    on: {
      click: function click($event) {
        return _vm.onScreening(2);
      }
    }
  }, [_vm._v(" 手机端录入："), _c("span", [_vm._v(_vm._s(_vm.appCount) + "人")])]), _c("div", {
    staticClass: "t-col",
    on: {
      click: function click($event) {
        return _vm.onScreening(3);
      }
    }
  }, [_vm._v(" 更换用工单位："), _c("span", [_vm._v(_vm._s(_vm.changeCount) + "人")])])]), _c("div", {
    staticClass: "-top-wrapper"
  }, [_c("div", {
    staticClass: "-top-left"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "180px",
      "margin-right": "12px"
    },
    attrs: {
      placeholder: "姓名/手机号/身份证号",
      allowClear: ""
    },
    on: {
      search: function search($event) {
        return _vm.init(true);
      }
    },
    model: {
      value: _vm.nameOrPhoneOrCard,
      callback: function callback($$v) {
        _vm.nameOrPhoneOrCard = $$v;
      },
      expression: "nameOrPhoneOrCard"
    }
  }), _c("a-input-search", {
    staticStyle: {
      width: "180px",
      "margin-right": "12px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "录入人"
    },
    on: {
      search: function search($event) {
        return _vm.init(true);
      }
    },
    model: {
      value: _vm.inputName,
      callback: function callback($$v) {
        _vm.inputName = $$v;
      },
      expression: "inputName"
    }
  }), _c("a-select", {
    staticClass: "mr12",
    staticStyle: {
      width: "136px"
    },
    attrs: {
      placeholder: "员工排序",
      "allow-clear": ""
    },
    on: {
      change: function change($event) {
        return _vm.init(true);
      }
    },
    model: {
      value: _vm.sortType,
      callback: function callback($$v) {
        _vm.sortType = $$v;
      },
      expression: "sortType"
    }
  }, _vm._l(_vm.staffSort, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-date-picker", {
    staticStyle: {
      width: "136px"
    },
    attrs: {
      valueFormat: "YYYY-MM-DD",
      placeholder: "选择日期",
      "allow-clear": false
    },
    on: {
      change: function change($event) {
        return _vm.init(true);
      }
    },
    model: {
      value: _vm.time,
      callback: function callback($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })], 1), _c("div", [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      disabled: !_vm.selectedRowKeys.length,
      type: "primary"
    },
    on: {
      click: function click() {
        return _vm.download(1);
      }
    }
  }, [_vm._v(" 批量导出 ")]), _c("a-button", {
    attrs: {
      disabled: !_vm.staffData.length,
      type: "primary"
    },
    on: {
      click: function click() {
        return _vm.download(2);
      }
    }
  }, [_vm._v("全部导出")])], 1)]), _c("div", [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.staffData,
      pagination: _vm.pagination,
      "row-key": function rowKey(d) {
        return d.id;
      },
      loading: _vm.tableLoading,
      scroll: {
        x: 800
      },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(text, record) {
        return _c("span", {}, [_c("div", {
          staticClass: "today-tags"
        }, [record.status == 1 ? _c("div", {
          staticClass: "tags-item",
          staticStyle: {
            background: "#ffbb02"
          }
        }, [_vm._v("待入职")]) : _vm._e(), record.status == 4 ? _c("div", {
          staticClass: "tags-item",
          staticStyle: {
            background: "#b9b9b9"
          }
        }, [_vm._v("已离职")]) : _vm._e(), _c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(text))])]), _vm._v(" " + _vm._s(text) + " ")], 2)], 1)]);
      }
    }, {
      key: "contractsStatus",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.contractsStatus != null ? _vm.getConstractsStatusType(Number(record.contractsStatus)) : ""))])];
      }
    }, {
      key: "postType",
      fn: function fn(text, record) {
        return [_c("span", [_vm._v(_vm._s(record.postType != null ? _vm.$store.state.enums.postTypeArr[record.postType - 1] : ""))])];
      }
    }, {
      key: "gmtCreate",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text ? _vm.moment(text).format("HH:mm:ss") : ""))])];
      }
    }, {
      key: "sourceType",
      fn: function fn(text) {
        return _c("span", {}, [text === 1 ? _c("span", [_vm._v("网页端")]) : _vm._e(), text === 2 ? _c("span", [_vm._v("手机端")]) : _vm._e(), text === 3 ? _c("span", [_vm._v("更换用工单位")]) : _vm._e()]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.goDetail(record);
            }
          }
        }, [_vm._v(" 详情 ")])];
      }
    }])
  })], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };