var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      position: "absolute",
      height: "100%",
      width: "100%"
    }
  }, [_c("FormDesign", {
    model: {
      value: _vm.fieldConfigs,
      callback: function callback($$v) {
        _vm.fieldConfigs = $$v;
      },
      expression: "fieldConfigs"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };