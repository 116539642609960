var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-tree-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "default-value": _vm.defaultValue,
      "dropdown-style": {
        maxHeight: "400px",
        overflow: "auto"
      },
      "tree-data": _vm.companyDepartmentList,
      "tree-checkable": _vm.record.options.multiple,
      "allow-clear": _vm.record.options.clearable,
      placeholder: _vm.record.options.placeholder,
      disabled: _vm.disabled,
      "tree-default-expand-all": ""
    },
    on: {
      change: _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn(_ref) {
        var key = _ref.key,
          value = _ref.value;
        return _c("span", {}, [_vm._v(" " + _vm._s(value) + " ")]);
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };