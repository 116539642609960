import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "new-navbar-wrapper",
    staticStyle: {
      "background-color": "#01182d"
    }
  }, [_vm.$route.matched[0].path != "/recruitment" ? _c("img", {
    staticClass: "left-logo",
    attrs: {
      src: _vm.$store.state.settings.settings.systemIcon,
      alt: "log"
    }
  }) : _c("img", {
    staticClass: "left-logo",
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      src: _vm.$store.state.settings.settings.systemIcon,
      alt: "log"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/dashboard"
        });
      }
    }
  }), _vm.$route.matched[0].path != "/recruitment" ? _c("div", {
    staticClass: "content-div"
  }, [_vm.authLoaded ? _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_vm.$hasPermissionSecond("hr:workbench", _vm.permissionStringList) ? _c("div", {
    staticClass: "content-div-title",
    staticStyle: {
      "margin-left": "72px"
    }
  }, [_c("span", {
    staticClass: "content-div-font-left",
    style: _vm.adminNavState === "workbench" ? "color: #FFFFFF" : "",
    on: {
      click: _vm.workbench
    }
  }, [_vm._v("工作台")]), _vm.adminNavState === "workbench" ? _c("div", {
    staticClass: "bottom-indicator"
  }) : _vm._e()]) : _vm._e(), _vm.$hasPermissionSecond("hr:settlementCompany", _vm.permissionStringList) ? _c("div", {
    staticClass: "content-div-title",
    staticStyle: {
      "margin-left": "48px"
    }
  }, [_c("span", {
    staticClass: "content-div-font-left",
    style: _vm.adminNavState === "cost" ? "color: #FFFFFF" : "",
    on: {
      click: _vm.cost
    }
  }, [_vm._v("公司结算")]), _vm.adminNavState === "cost" ? _c("div", {
    staticClass: "bottom-indicator"
  }) : _vm._e()]) : _vm._e(), _vm.$hasPermissionSecond("hr:enterpriseOverview", _vm.permissionStringList) ? _c("div", {
    staticClass: "content-div-title",
    staticStyle: {
      "margin-left": "48px"
    }
  }, [_c("span", {
    staticClass: "content-div-font-left",
    style: _vm.adminNavState === "overview" ? "color: #FFFFFF" : "",
    on: {
      click: _vm.overview
    }
  }, [_vm._v("公司总览")]), _vm.adminNavState === "overview" ? _c("div", {
    staticClass: "bottom-indicator"
  }) : _vm._e()]) : _vm._e(), _vm.$hasPermissionSecond("hr:enterprise", _vm.permissionStringList) ? _c("div", {
    staticClass: "content-div-title",
    staticStyle: {
      "margin-left": "48px"
    }
  }, [_c("span", {
    staticClass: "content-div-font-left",
    style: _vm.adminNavState === "setting" ? "color: #FFFFFF" : "",
    on: {
      click: _vm.setting
    }
  }, [_vm._v("公司设置")]), _vm.adminNavState === "setting" ? _c("div", {
    staticClass: "bottom-indicator"
  }) : _vm._e()]) : _vm._e(), _vm.$hasPermissionSecond("hr:enterpriseSetup", _vm.permissionStringList) ? _c("div", {
    staticClass: "content-div-title",
    staticStyle: {
      "margin-left": "48px"
    }
  }, [_c("span", {
    staticClass: "content-div-font-left",
    style: _vm.adminNavState === "manage" ? "color: #FFFFFF" : "",
    on: {
      click: _vm.manage
    }
  }, [_vm._v("公司管理")]), _vm.adminNavState === "manage" ? _c("div", {
    staticClass: "bottom-indicator"
  }) : _vm._e()]) : _vm._e()]) : _vm._e(), _c("div", {
    staticStyle: {
      flex: "1"
    }
  }), _c("div", {
    staticClass: "content-to-index",
    staticStyle: {
      "margin-right": "24px",
      height: "35px",
      "padding-left": "15px",
      "padding-right": "15px"
    },
    on: {
      click: _vm.toServices
    }
  }, [_c("img", {
    staticStyle: {
      width: "12px",
      height: "12px",
      "margin-right": "4px"
    },
    attrs: {
      src: require("@/assets/icons/zengzhifuwu.png"),
      alt: ""
    }
  }), _c("span", {
    staticClass: "content-to-index-font"
  }, [_vm._v("拓展应用")])]), _vm.permissionsMenuList && _vm.permissionsMenuList.length > 0 ? _c("div", {
    staticClass: "content-to-index",
    staticStyle: {
      "margin-right": "24px",
      height: "35px",
      "padding-left": "15px",
      "padding-right": "15px"
    },
    on: {
      click: _vm.toDashboard
    }
  }, [_c("img", {
    staticStyle: {
      width: "12px",
      height: "12px",
      "margin-right": "4px"
    },
    attrs: {
      src: require("@/assets/icons/hr_fff_houtai.png"),
      alt: ""
    }
  }), _c("span", {
    staticClass: "content-to-index-font"
  }, [_vm._v("返回操作后台")])]) : _vm._e()]) : _vm._e(), _vm.$route.matched[0].path != "/recruitment" ? _c("div", {
    staticClass: "navbar-wrapper-right"
  }, [_c("div", {
    staticClass: "nav-right-wrapper border-no-left"
  }, [_c("a-popover", {
    attrs: {
      placement: "bottomRight"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("div", {
    ref: "noticeDropdown",
    staticClass: "overlay-wrapper",
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_c("a-tabs", {
    staticClass: "overlay-tab-wrapper",
    attrs: {
      "default-active-key": "1"
    },
    on: {
      change: _vm.changeTab
    }
  }, [_c("a-tab-pane", {
    key: "1"
  }, [_c("span", {
    attrs: {
      slot: "tab"
    },
    slot: "tab"
  }, [_vm._v(" 消息通知 "), _vm.noNotice ? _c("span", {
    staticClass: "ant-badge ant-badge-status ant-badge-not-a-wrapper",
    staticStyle: {
      position: "absolute",
      top: "10px",
      right: "0px"
    }
  }, [_c("span", {
    staticClass: "ant-badge-status-dot ant-badge-status-red"
  }), _c("span", {
    staticClass: "ant-badge-status-text"
  })]) : _vm._e()]), _c("div", {
    staticClass: "notice-wrapper"
  }, [_c("div", {
    staticClass: "Top_classify_warp"
  }, [_c("div", {
    staticClass: "Top_classify",
    class: _vm.classify === 10086 ? "active" : "",
    on: {
      click: function click($event) {
        return _vm.onClassify(10086, "");
      }
    }
  }, [_vm._v("全部")]), _vm._l(_vm.TopData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "Top_classify",
      class: _vm.classify === index ? "active" : "",
      on: {
        click: function click($event) {
          return _vm.onClassify(index, item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2), _c("div", {
    staticClass: "mamessageList"
  }, [_vm.noticeList.length === 0 ? _c("div", {
    staticStyle: {
      width: "100%",
      height: "100%",
      display: "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c("a-empty")], 1) : _c("a-list", {
    attrs: {
      "item-layout": "horizontal",
      "data-source": _vm.noticeList
    },
    scopedSlots: _vm._u([{
      key: "renderItem",
      fn: function fn(item) {
        return _c("a-list-item", {
          staticStyle: {
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.read(item);
            }
          }
        }, [_c("div", {
          staticClass: "notice_wrapper"
        }, [_c("div", {
          staticClass: "item-left"
        }, [_c("div", {
          class: item.readStatus ? "bac-6D69F71A-radius-2px-gray font-6A66F6-10px-gray" : "bac-6D69F71A-radius-2px font-6A66F6-10px",
          staticStyle: {
            "min-width": "40px",
            "margin-right": "10px",
            "padding-left": "5px",
            "padding-right": "8px",
            "padding-top": "5px",
            "padding-bottom": "5px"
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c("div", {
          class: item.readStatus ? "yidu" : "weidu"
        }, [_vm._v(_vm._s(item.title))])]), _c("div", {
          staticClass: "weidu"
        }, [_vm._v(_vm._s(item.gmtCreate ? _vm.formatDateAll(item.gmtCreate, "d") : "--"))])])]);
      }
    }], null, false, 2731858741)
  })], 1)])])], 1), _c("div", {
    staticClass: "footer-overlay footer-overlays"
  }, [_c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.initNotices(1);
      }
    }
  }, [_vm._v(" 刷新消息 "), _c("a-icon", {
    attrs: {
      type: "redo"
    }
  })], 1), _c("div", {
    staticStyle: {
      height: "50px",
      "border-right": "1px solid #d4d2d2"
    }
  }), _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.getMore
    }
  }, [_vm._v(" 查看更多 "), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1)], 1)], 1)]), _c("span", {
    staticClass: "notice-bell"
  }, [!_vm.noNotice ? _c("img", {
    attrs: {
      src: require("@/assets/icons/notice.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/icons/notice2.png"),
      alt: ""
    }
  })])], 2), _c("a-dropdown", {
    staticClass: "avatar-dropdown",
    attrs: {
      trigger: ["hover"],
      placement: "bottomRight"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("img", {
    staticClass: "avatar-img",
    attrs: {
      src: _vm.avatar
    }
  })]), _c("a-menu", {
    attrs: {
      slot: "overlay",
      "default-selected-keys": _vm.defaultSelectedKeys
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "0",
    on: {
      click: _vm.goPerson
    }
  }, [_c("div", {
    staticClass: "item-div"
  }, [_c("img", {
    staticClass: "avatar-img-com",
    attrs: {
      src: _vm.avatar
    }
  }), _c("span", {
    staticClass: "item-span title-name ove"
  }, [_vm._v(_vm._s(_vm.name))]), _c("a-icon", {
    staticClass: "avatar-mid-right jt",
    attrs: {
      type: "right"
    }
  })], 1)]), _c("a-menu-divider", {
    staticStyle: {
      background: "#f2f2f2"
    }
  }), _c("a-menu-item", {
    key: "1",
    on: {
      click: _vm.logout
    }
  }, [_c("a-icon", {
    attrs: {
      type: "poweroff"
    }
  }), _c("span", {
    staticClass: "pd_l4"
  }, [_vm._v("退出登录")])], 1), _vm.showShangbao ? _c("a-menu-item", {
    key: "shangbao",
    on: {
      click: _vm.goShangbao
    }
  }, [_c("span", [_vm._v("保险公司端-商保")])]) : _vm._e(), _vm.showConsole ? _c("a-menu-item", {
    key: "console"
  }, [_c("a", {
    attrs: {
      href: "#/console"
    }
  }, [_vm._v("打开控制台")])]) : _vm._e(), _vm.permissions_site.length > 0 ? [_c("a-menu-divider", {
    staticStyle: {
      background: "#f2f2f2"
    }
  }), _vm._l(_vm.permissions_site, function (site, index) {
    return _c("a-menu-item", {
      key: (index + 2).toString()
    }, [_c("div", {
      staticClass: "item-div",
      on: {
        click: function click($event) {
          return _vm.switchSite(site);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "shop"
      }
    }), _c("span", {
      staticClass: "item-span ove"
    }, [_vm._v(_vm._s(site.name))])], 1)]);
  })] : _vm._e()], 2)], 1)], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };