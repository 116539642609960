import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    staticClass: "wrapper",
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    ref: "editor",
    attrs: {
      id: "vueWangEditor"
    }
  }), _vm.fileShow ? _c("input", {
    ref: "inputer",
    staticStyle: {
      width: "0px"
    },
    attrs: {
      type: "file",
      id: "fileExport"
    },
    on: {
      change: _vm.handleFileChange
    }
  }) : _vm._e(), _vm._l(_vm.fileList, function (i, index) {
    return _c("div", {
      key: index
    }, [_c("a-icon", {
      attrs: {
        type: "paper-clip"
      }
    }), _vm._v(" " + _vm._s(i.name) + " "), _c("a-popconfirm", {
      attrs: {
        title: "确定删除吗?"
      },
      on: {
        confirm: function confirm() {
          return _vm.delFile(i, index);
        }
      }
    }, [!_vm.disabled ? _c("a-icon", {
      attrs: {
        type: "delete"
      }
    }) : _vm._e()], 1)], 1);
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };