var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "uplaodBoxs"
  }, [_c("a-spin", {
    staticClass: "spin-content",
    attrs: {
      tip: "上传中。。。",
      spinning: _vm.uploading
    }
  }, [_c("div", {
    staticClass: "ant-upload-select-text-box",
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-upload", {
    ref: "upload",
    attrs: {
      accept: ".xls, .xlsx, .xlsm",
      "file-list": _vm.fileList,
      remove: _vm.handleRemove,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("img", {
    staticStyle: {
      width: "139px",
      height: "106px"
    },
    attrs: {
      src: _vm.uploadImg
    }
  }), _c("div", {
    staticClass: "ant-modal-body"
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#666"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#6a66f6"
    }
  }, [_vm._v("将文件拖拽到此处")]), _vm._v(" 或点击添加文件")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#999"
    }
  }, [_vm._v("(文件大小不超过5M)")]), _c("a-button", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary",
      icon: "plus"
    }
  }, [_vm._v(" 添加文件 ")])], 1)])], 1)]), _c("div", {
    staticClass: "tipBox"
  }, [_c("a-row", {
    staticStyle: {
      color: "#252525",
      "font-size": "16px",
      "font-weight": "500",
      "padding-bottom": "10px"
    }
  }, [_vm._v(" 温馨提示 ")]), _c("a-row", [_c("a-col", {
    staticStyle: {
      "font-size": "14px",
      color: "#767885"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", [_vm._v(" 首先下载标准模板，填写信息后再上传 。 "), _c("span", {
    staticStyle: {
      "font-size": "14px",
      color: "#6a66f6",
      cursor: "pointer"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v(" 下载标准模板 ")])]), _c("div", [_vm._v("1、该功能支持通过修改缴纳参数，批量修改员工参保数据；")]), _c("div", [_vm._v("2，模板支持用户新增列，以匹配自定义新增的参保项目名称；")]), _c("div", [_vm._v("3，补收补缴不支持修改；")]), _c("div", [_vm._v("4，支持wps，excel2003以上版本文件，支持xlsx格式；")]), _c("div", [_vm._v("5，模板文件大小不得超过5M，单次导入上传数据不得超过500条；")]), _c("div", [_vm._v("6，请不要上传加密或带宏的文件，不然将导致上传错误；")])])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };