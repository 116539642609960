import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "infoCard"
  }, [_c("div", {
    staticClass: "tit"
  }, [_vm._v(" " + _vm._s(_vm.tit) + " "), _c("span", {
    class: "col".concat(_vm.statusNum)
  }, [_vm._v(_vm._s(_vm.status))])]), _c("div", {
    staticClass: "item-box"
  }, _vm._l(_vm.arr, function (item) {
    return _c("div", {
      key: item.name,
      staticClass: "item"
    }, [_c("div", [_vm._v(_vm._s(item.name) + "：")]), _c("div", {
      class: (_vm.statusNum === 3 || _vm.statusNum === 4) && item.name === "失败原因" ? "colff7" : ""
    }, [_vm._v(_vm._s(item.val))])]);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };