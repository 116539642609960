export default {
  fields: {
    name: {
      model: "name"
    },
    phone: {
      model: "phone"
    },
    gender: {
      model: "gender"
    },
    email: {
      model: "email"
    },
    wechat: {
      model: "wechat"
    },
    val4: {
      model: "val4"
    },
    companyName: {
      model: "companyName"
    },
    companyAddress: {
      model: "companyAddress"
    },
    companyType: {
      model: "companyType"
    },
    companyPersonSize: {
      model: "companyPersonSize"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "name",
        title: "姓名"
      }, {
        model: "phone",
        title: "手机"
      }, {
        model: "gender",
        title: "性别"
      }, {
        model: "email",
        title: "邮箱"
      }, {
        model: "wechat",
        title: "微信"
      },
      // {
      //   model: "val4",
      //   title: "传真"
      // },
      {
        model: "companyName",
        title: "公司名称"
      }, {
        model: "companyAddress",
        title: "公司地址"
      }, {
        model: "companyType",
        title: "公司类型"
      }, {
        model: "companyPersonSize",
        title: "公司规模"
      }],
      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: false
      }
    }
  }
};