import { render, staticRenderFns } from "./setManage.vue?vue&type=template&id=d94804bc&scoped=true&"
import script from "./setManage.vue?vue&type=script&lang=js&"
export * from "./setManage.vue?vue&type=script&lang=js&"
import style0 from "./setManage.vue?vue&type=style&index=0&id=d94804bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d94804bc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\tools\\frontend_workspace\\e-hr-master\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d94804bc')) {
      api.createRecord('d94804bc', component.options)
    } else {
      api.reload('d94804bc', component.options)
    }
    module.hot.accept("./setManage.vue?vue&type=template&id=d94804bc&scoped=true&", function () {
      api.rerender('d94804bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/shebao/shebaoManage/setManage.vue"
export default component.exports