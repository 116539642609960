import request from "@/utils/request";
export function getTaskListReq(params) {
  return request({
    url: "/admin/api/v1/asyncTasks/listAll",
    method: "get",
    params: params
  });
}
export function getTaskDetailReq(params) {
  return request({
    url: "/admin/api/v1/asyncTaskDetails/listAll",
    method: "get",
    params: params
  });
}