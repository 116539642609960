import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      width: 850,
      title: _vm.title,
      visible: _vm.show
    },
    on: {
      cancel: _vm.cancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.ok
    }
  }, [_vm._v(" 确定 ")])], 1), _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "employee-selector-wrapper2"
  }, [_c("div", [_c("div", {
    staticClass: "employee-selector-block"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "搜索" + _vm.title
    },
    on: {
      search: _vm.searchPeople,
      change: _vm.changePeople
    }
  })], 1), _c("a-tabs", {
    staticStyle: {
      "margin-bottom": "10px",
      width: "450px"
    },
    attrs: {
      "default-active-key": "1",
      size: "small",
      tabBarGutter: 3
    },
    on: {
      change: _vm.onChangeTab
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "全部待添"
    }
  }, [_c("div", {
    staticClass: "tree-item2"
  }, [_c("div", [_vm._v("全选当页")]), _vm.isAllSelected ? _c("i", {
    staticClass: "iconfont icon-check-circle-fill primary_color",
    staticStyle: {
      "font-size": "19px"
    },
    on: {
      click: function click($event) {
        return _vm.choseAll(_vm.treeData);
      }
    }
  }) : _c("img", {
    staticStyle: {
      width: "18px",
      height: "18px",
      "margin-left": "10px"
    },
    attrs: {
      src: require("@/assets/icons/未选择.png")
    },
    on: {
      click: function click($event) {
        return _vm.choseAll(_vm.treeData);
      }
    }
  })]), _c("div", {
    staticClass: "employee-selector"
  }, _vm._l(_vm.treeData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "mg_t10"
    }, [_c("div", {
      staticClass: "tree-item",
      on: {
        click: function click($event) {
          return _vm.chose(item, _vm.treeData);
        }
      }
    }, [_c("span", {
      style: [{
        overflow: "hidden"
      }, {
        textOverflow: "ellipsis"
      }, {
        whiteSpace: "nowrap"
      }]
    }, [_c("img", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "margin-right": "10px"
      },
      attrs: {
        src: require("@/assets/icons/person1.png")
      }
    }), _c("a-tooltip", {
      attrs: {
        placement: "left"
      }
    }, [_c("template", {
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.name) + " " + _vm._s(item.phone ? "(" + item.phone + ")" : "") + " ")]), _vm._v(" " + _vm._s(item.name) + " " + _vm._s(item.phone ? "(" + item.phone + ")" : "") + " ")], 2)], 1), item.isSelected ? _c("i", {
      staticClass: "iconfont icon-check-circle-fill primary_color",
      staticStyle: {
        "font-size": "19px"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.chose(item, _vm.treeData);
        }
      }
    }) : _c("img", {
      staticStyle: {
        width: "18px",
        height: "18px"
      },
      attrs: {
        src: require("@/assets/icons/未选择.png")
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.chose(item, _vm.treeData);
        }
      }
    })])]);
  }), 0), _c("a-pagination", {
    attrs: {
      "page-size-options": _vm.pageSizeOptions,
      total: _vm.total,
      "show-size-changer": "",
      "show-less-items": "",
      "page-size": _vm.pageSize
    },
    on: {
      showSizeChange: _vm.onShowSizeChange,
      change: _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "buildOptionText",
      fn: function fn(props) {
        return [_c("span", [_vm._v(_vm._s(props.value) + "条/页")])];
      }
    }]),
    model: {
      value: _vm.current,
      callback: function callback($$v) {
        _vm.current = $$v;
      },
      expression: "current"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "verticalBar"
  }), _c("div", {
    staticClass: "employee-selector-block2"
  }, [_c("div", {
    staticClass: "employee-selector-title"
  }, [_vm._v("已经选择的" + _vm._s(_vm.title) + " " + _vm._s(_vm.selectedListTemp.length) + "人")]), _c("div", {
    staticClass: "employee-selector"
  }, _vm._l(_vm.selectedListTemp, function (item, i) {
    return _c("div", {
      key: i,
      staticClass: "employee-selector-item"
    }, [_c("div", {
      style: [{
        overflow: "hidden"
      }, {
        textOverflow: "ellipsis"
      }, {
        width: "200px"
      }, {
        whiteSpace: "nowrap"
      }]
    }, [_c("img", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "margin-right": "10px"
      },
      attrs: {
        src: require("@/assets/icons/person1.png")
      }
    }), _c("a-tooltip", {
      attrs: {
        placement: "right"
      }
    }, [_c("template", {
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _vm._v(" " + _vm._s(item.name) + " ")], 2)], 1), _c("img", {
      staticStyle: {
        width: "20px",
        height: "20px"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(item);
        }
      }
    })]);
  }), 0)])])])], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };