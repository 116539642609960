import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.splice.js";
import { salaryCalculateRulesListall, saveSalaryCalculateRule } from "@/api/staff/staff";
import { getEmpInfoId } from "./api";
import drawer from "./drawer.vue";
import drawers from "./drawers.vue";
var columns2 = [{
  title: "生效时间",
  dataIndex: "timeShow",
  key: "timeShow",
  width: 100
}, {
  title: "基础规则",
  dataIndex: "hourAmountRuleShow",
  key: "hourAmountRuleShow1",
  width: 120
}, {
  title: "离职规则",
  dataIndex: "leaveOfficeRuleShow",
  key: "leaveOfficeRuleShow",
  width: 120
}];
var columns = [{
  title: "定薪项目",
  dataIndex: "itemName",
  align: "itemName",
  key: "itemName",
  scopedSlots: {
    customRender: "itemName"
  },
  width: 150
}, {
  title: "试用期工资",
  dataIndex: "trial",
  align: "trial",
  key: "trial",
  scopedSlots: {
    customRender: "trial"
  },
  width: 150
}, {
  title: "转正后工资",
  dataIndex: "formal",
  align: "formal",
  key: "formal",
  scopedSlots: {
    customRender: "formal"
  },
  width: 150
}];
import { selectByCompanyId, detaiByempId, empWage, addSalaryTemplate } from "@/api/wages/file";
import { salaryCalculateRules, salaryCalculateRulesList, getSalaryCalculateRuleGroups, salaryRulesListByPage, editEmpInfos } from "@/api/outsourcing/outrules";
import { mapState } from "vuex";
var fixItems = [{
  id: 1,
  name: "基本工资",
  selected: true,
  isDefault: true
}, {
  id: 2,
  name: "交通补贴",
  selected: false,
  isDefault: false
}, {
  id: 3,
  name: "住房补贴",
  selected: false,
  isDefault: false
}, {
  id: 4,
  name: "全勤奖",
  selected: false,
  isDefault: false
}, {
  id: 5,
  name: "餐补",
  selected: false,
  isDefault: false
}, {
  id: 6,
  name: "高温补贴",
  selected: false,
  isDefault: false
}, {
  id: 7,
  name: "工龄补贴",
  selected: false,
  isDefault: false
}, {
  id: 8,
  name: "夜班津贴",
  selected: false,
  isDefault: false
}, {
  id: 9,
  name: "通讯补贴",
  selected: false,
  isDefault: false
}, {
  id: 10,
  name: "节假日补贴",
  selected: false,
  isDefault: false
}, {
  id: 11,
  name: "员工慰问金",
  selected: false,
  isDefault: false
}, {
  id: 12,
  name: "推荐奖",
  selected: false,
  isDefault: false
}, {
  id: 13,
  name: "职务津贴",
  selected: false,
  isDefault: false
}, {
  id: 14,
  name: "绩效工资",
  selected: false,
  isDefault: false
}, {
  id: 15,
  name: "季度奖",
  selected: false,
  isDefault: false
}, {
  id: 16,
  name: "销售提成",
  selected: false,
  isDefault: false
}, {
  id: 17,
  name: "计件工资",
  selected: false,
  isDefault: false
}, {
  id: 18,
  name: "工龄工资",
  selected: false,
  isDefault: false
}, {
  id: 19,
  name: "司龄工资",
  selected: false,
  isDefault: false
}, {
  id: 20,
  name: "职称工资",
  selected: false,
  isDefault: false
}, {
  id: 21,
  name: "技能工资",
  selected: false,
  isDefault: false
}, {
  id: 22,
  name: "学历工资",
  selected: false,
  isDefault: false
}];
export default {
  data: function data() {
    var _this = this;
    return {
      columns: columns,
      columns2: columns2,
      dataList2: [],
      dataList: [],
      showType: null,
      showType2: null,
      show: false,
      size: "small",
      show1: false,
      spinning: true,
      salaryCalculateRuleId: undefined,
      rulusList: [],
      rulusListCopy: [],
      ruleShow: false,
      redPack: [],
      fixedSalaryTemplateName: null,
      fixItems: fixItems,
      addForm: {
        name: ""
      },
      addVisible: false,
      lastId: "",
      lastName: "",
      buttonState: false,
      form: {},
      empName: "",
      showRuleItem: null,
      detailsListCopy: [],
      trialTemplateList: [],
      formalTemplateList: [],
      detailsList: [],
      templateId: "",
      titleType: "",
      templateList: [],
      companyId: this.$store.state.hr.companyId,
      desc: "",
      routerDatas: {},
      rules2: {
        name: [{
          required: true,
          message: "必填",
          trigger: "blur"
        }]
      },
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      currentPage: null,
      ruleShow1: false,
      redPackId: undefined,
      addManFormRef: {
        numList: [],
        leaveList: [],
        hourAmountDouble: null // 劳工
      },

      option2: [
      // 劳工
      {
        label: "月",
        value: 1
      }, {
        label: "天",
        value: 2
      }, {
        label: "工时",
        value: 3
      }],
      option: [
      // 劳工
      {
        label: "月",
        value: 1
      }, {
        label: "天",
        value: 2
      }, {
        label: "工时",
        value: 3
      }],
      addManFormRef1: {
        commissionRatio1: null,
        hourAmountDouble1: null,
        // 劳务费
        changeList: [],
        numList1: [],
        leaveList1: [],
        specialCostDouble: undefined
      },
      rules: {
        specialCostDouble: [{
          required: true,
          message: "请输入",
          trigger: "change"
        }]
      },
      checked3: false,
      // 工时抽成
      checked4: false,
      checked5: false,
      checked6: false,
      info: {},
      checked: false,
      checked1: false,
      labourList: [],
      paginationOpt2: {
        current: 1,
        defaultCurrent: 1,
        // 默认当前页数
        // defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt2.current = 1;
          _this.paginationOpt2.defaultCurrent = 1;
          _this.paginationOpt2.defaultPageSize = pageSize;
          _this.laowufeiguize(); // 显示列表的接口名称
        },

        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt2.current = current;
          _this.paginationOpt2.defaultCurrent = current;
          _this.paginationOpt2.defaultPageSize = size;
          _this.laowufeiguize();
        }
      },
      paginationOpt: {
        current: 1,
        defaultCurrent: 1,
        // 默认当前页数
        // defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.current = 1;
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;
          _this.gongjiaguize(); // 显示列表的接口名称
        },

        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.current = current;
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;
          _this.gongjiaguize();
        }
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    status: function status() {
      return ["待入职", "入职", "待离职", "离职"][this.routerData.status - 1];
    },
    doWords: function doWords() {
      return ["salaryPerformance", "mealSubsidy", "trafficSubsidy", "salaryBasic"];
    },
    allmoney: function allmoney() {
      var _this$postData = this.postData,
        salaryPerformance = _this$postData.salaryPerformance,
        mealSubsidy = _this$postData.mealSubsidy,
        trafficSubsidy = _this$postData.trafficSubsidy,
        salaryBasic = _this$postData.salaryBasic;
      var arr = [salaryPerformance, mealSubsidy, trafficSubsidy, salaryBasic];
      return arr.reduce(function (preValue, curValue) {
        return Number(preValue) + Number(curValue);
      }, 0);
    },
    reasons: function reasons() {
      return [{
        label: "转正",
        value: 1
      }, {
        label: "晋升",
        value: 2
      }, {
        label: "调动",
        value: 3
      }, {
        label: "年中调薪",
        value: 4
      }, {
        label: "年度调薪",
        value: 5
      }, {
        label: "特别调薪",
        value: 6
      }, {
        label: "其他",
        value: 7
      }];
    }
  }),
  components: {
    // drawer,
    // drawers
  },
  beforeDestory: function beforeDestory() {
    this.clearInterval(this.timer);
  },
  created: function created() {
    var _this2 = this;
    //  this.size = this.$route.query.routerData.showType===1?
    this.titleType = this.$route.query.showType;
    this.getSelectByCompanyId();
    if (this.$route.query.state === "outsourcing") {
      var params = {
        pageNo: 1,
        pageSize: "10",
        companyId: this.companyId,
        empId: this.$route.query.empId
      };
      this.routerDatas.empId = this.$route.query.empId;
      getEmpInfoId(this.$route.query.empId, this.companyId).then(function (res) {
        _this2.info = res.data;
        if (res.data.channelVO && res.data.channelVO.name) {
          _this2.info.channelName = res.data.channelVO.name;
        }

        // this.info == null 会报错。
        _this2.salaryCalculateListall();
      });
    } else {
      this.routerDatas = JSON.parse(this.$route.query.routerData);
      this.currentPage = this.$route.query.currentPage;
      this.salaryCalculateListall();
      this.info = JSON.parse(this.$route.query.info);
      // if (!this.info.channelName && this.info.channelVO !== null) {
      //   this.info.channelName = this.info.channelVO.name;
      // }
    }

    this.redPackId = this.info.serviceFeeRuleId ? this.info.serviceFeeRuleId : undefined;
    this.salaryCalculateRuleId = this.info.salaryRuleId ? this.info.salaryRuleId : undefined;
    this.gongjiaguize();
    this.laowufeiguize();
    setTimeout(function () {
      _this2.spinning = false;
    }, 1000);
  },
  methods: {
    gongjiaguize: function gongjiaguize() {
      var _this3 = this;
      if (this.salaryCalculateRuleId) {
        salaryRulesListByPage({
          pageNo: this.paginationOpt.defaultCurrent,
          pageSize: this.paginationOpt.defaultPageSize,
          salaryCalculateRuleGroupId: this.salaryCalculateRuleId
        }).then(function (res) {
          _this3.dataList = res.data.entities;
          _this3.paginationOpt.total = res.data.entityCount;
        });
      } else {
        this.dataList = [];
      }
    },
    laowufeiguize: function laowufeiguize() {
      var _this4 = this;
      if (this.redPackId) {
        salaryRulesListByPage({
          pageNo: this.paginationOpt2.defaultCurrent,
          pageSize: this.paginationOpt2.defaultPageSize,
          salaryCalculateRuleGroupId: this.redPackId
        }).then(function (res) {
          _this4.dataList2 = res.data.entities;
          _this4.paginationOpt2.total = res.data.entityCount;
        });
      } else {
        this.dataList2 = [];
      }
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    goBack: function goBack() {
      this.$router.push({
        path: "/wages/file",
        query: {
          currentPage: this.currentPage
        }
      });
    },
    handleAddSelected: function handleAddSelected(item) {
      if (item.id === 1) return;
      item.selected = !item.selected;
    },
    computeItemClass: function computeItemClass(item) {
      if (item.id === 1) return "fix-salary-item-tag fix-salary-item-default";
      if (item.selected) return "fix-salary-item-tag fix-salary-item-selected";
      return "fix-salary-item-tag";
    },
    handleAddCancel: function handleAddCancel() {
      this.$refs.addForm.clearValidate();
    },
    radioGroupChange: function radioGroupChange(e) {
      // this.salaryCalculateRuleId = undefined;
      // this.handleRulesChange(this.salaryCalculateRuleId);
      // if (this.size === "large") {
      //   var arr = [];
      //   this.rulusList.forEach((item, index) => {
      //     if (item.showType && item.showType === 1) {
      //       arr.push(item);
      //     }
      //   });
      //   this.rulusListCopy = JSON.parse(JSON.stringify(arr));
      // }
      // if (this.size === "small") {
      //   var arr2 = [];
      //   this.rulusList.forEach((item, index) => {
      //     if (item.showType && item.showType === 2) {
      //       arr2.push(item);
      //     }
      //   });
      //   this.rulusListCopy = JSON.parse(JSON.stringify(arr2));
      // }
    },
    handleAddOk: function handleAddOk() {
      var _this5 = this;
      var arr = this.fixItems.filter(function (item, index) {
        if (item.selected) return true;
      });
      var data = {
        companyId: this.companyId,
        isDefault: false,
        name: this.addForm.name,
        itemIds: arr.map(function (item) {
          return item.id;
        }).join(",")
      };
      this.$refs.addForm.validate(function (valid) {
        if (valid) {
          addSalaryTemplate(data).then(function (res) {
            _this5.addVisible = false;
            _this5.getSelectByCompanyId();
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        }
      });
    },
    goIncrease: function goIncrease() {
      this.addVisible = true;
      this.addForm = {};
      this.fixItems.forEach(function (item, index) {
        if (index > 0) item.selected = false;
      });
      // this.$router.push({
      //   path: '/wages/file/template'
      // })
    },
    templateChange: function templateChange(id, key, item) {
      // item.style = "buttonStyleClick";
      this.templateList[0].style = null;
      this.fixedSalaryTemplateName = item.name;
      // this.templateList[0].color = null;
      for (var i = 0; i < this.templateList.length; i++) {
        if (this.templateList[i].id === id) {
          this.templateList[i].style = "buttonStyleClick";
        } else {
          this.templateList[i].style = null;
        }
      }
      this.detailsList = [];
      this.templateId = id;
      this.detailsList;
      this.getDetaiByempId();
    },
    getDetaiByempId: function getDetaiByempId() {
      var _this6 = this;
      var id = this.templateId ? this.templateId : this.lastId;
      detaiByempId(id).then(function (res) {
        _this6.detailsList = res.data;
        _this6.trialTemplateList = JSON.parse(JSON.stringify(res.data));
        _this6.formalTemplateList = JSON.parse(JSON.stringify(res.data));
        for (var i = 0; i < _this6.detailsList.length; i++) {
          _this6.detailsList[i].projectId = i;
          _this6.detailsList[i].trial = _this6.detailsList[i].trial ? _this6.detailsList[i].trial : 0;
          _this6.detailsList[i].formal = _this6.detailsList[i].formal ? _this6.detailsList[i].formal : 0;
        }
      });
    },
    getSelectByCompanyId: function getSelectByCompanyId() {
      var _this7 = this;
      selectByCompanyId({
        companyId: this.companyId
      }).then(function (res) {
        _this7.templateList = res.data;
        _this7.templateList.forEach(function (item, index) {
          _this7.templateList[0].style = "buttonStyleClick";
        });
        _this7.templateId = _this7.templateList[0].id;
        _this7.lastId = _this7.templateList[0].id;
        _this7.lastName = _this7.templateList[0].name;
        // this.templateList[0].color = "#6A66F6";
        _this7.getDetaiByempId();
      });
    },
    goOut: function goOut() {
      this.$router.go(-1);
      // const _this = this;
      // this.$confirm({
      //   title: "员工定薪尚未保存，是否确认离开？",
      //   okText: "确定",
      //   cancelText: "取消",
      //   onOk() {
      //     _this.$router.go(-1);
      //   }
      // });
    },
    // 表格输入框变化
    handleInputChange: function handleInputChange(value, key, col) {
      var arr = JSON.parse(JSON.stringify(this.detailsList));
      if (col === "trial") {
        for (var i = 0; i < arr.length; i++) {
          if (i === key) {
            arr[i].trial = value;
            this.trialTemplateList[i].amount = value;
            this.trialTemplateList[i].name = this.trialTemplateList[i].itemName;
            // this.trialTemplateList[i].projectId = key
          }
        }

        var s = 0;
        for (var t = 0; t < arr.length - 1; t++) {
          s += arr[t].trial;
          arr[arr.length - 1].trial = s;
          this.trialTemplateList[this.trialTemplateList.length - 1].amount = s;
        }
      }
      if (col === "formal") {
        for (var p = 0; p < arr.length; p++) {
          if (p === key) {
            arr[p].formal = value;
            this.formalTemplateList[p].amount = value;
            this.formalTemplateList[p].name = this.formalTemplateList[p].itemName;
            // this.formalTemplateList[p].projectId = key
          }
        }

        var sum = 0;
        for (var a = 0; a < arr.length - 1; a++) {
          sum += arr[a].formal;
          arr[arr.length - 1].formal = sum;
          this.formalTemplateList[this.formalTemplateList.length - 1].amount = sum;
        }
      }
      this.detailsList = JSON.parse(JSON.stringify(arr));
    },
    // 表单提交
    handleSubmit: function handleSubmit() {
      var _this8 = this;
      var serviceFeeRuleAddDTO = {};
      // if (this.redPackId !== "" && this.redPackId !== null && this.redPackId !== undefined) {
      //   this.$refs.addManForm1.validate(valid => {
      //     if (valid) {
      //       const labourList1 = JSON.parse(JSON.stringify(this.addManFormRef1.changeList)); // 劳务费工价数组
      //       const leaveList1 = JSON.parse(JSON.stringify(this.addManFormRef1.leaveList1)); // 劳务费倒扣数组
      //       labourList1.map(item => {
      //         item.type = Number(item.type);
      //       });
      //       leaveList1.map(item => {
      //         item.type = Number(item.type);
      //       });
      //       serviceFeeRuleAddDTO.showType = this.showType2;
      //       serviceFeeRuleAddDTO.hourAmountDouble = this.addManFormRef1.hourAmountDouble1;
      //       serviceFeeRuleAddDTO.commissionRatio = this.addManFormRef1.commissionRatio1;
      //       serviceFeeRuleAddDTO.hourAmountRule = JSON.stringify(labourList1);
      //       serviceFeeRuleAddDTO.leaveOfficeRule = JSON.stringify(leaveList1);
      //       serviceFeeRuleAddDTO.redPacketRule = JSON.stringify(this.addManFormRef1.numList1);
      //       serviceFeeRuleAddDTO.specialCostDouble = this.addManFormRef1.specialCostDouble;
      //       this.show1 = true;
      //     } else {
      //       this.show1 = false;
      //     }
      //   });
      // }
      for (var i = 0; i < this.detailsList.length; i++) {
        this.trialTemplateList[i].amount = this.trialTemplateList[i].amount ? this.trialTemplateList[i].amount : 0;
        this.formalTemplateList[i].amount = this.formalTemplateList[i].amount ? this.formalTemplateList[i].amount : 0;
      }
      empWage({
        serviceFeeRuleId: this.redPackId,
        serviceFeeRuleAddDTO: serviceFeeRuleAddDTO,
        fixedSalaryTemplateName: this.fixedSalaryTemplateName ? this.fixedSalaryTemplateName : this.lastName,
        isFixed: 1,
        isOfficial: parseInt(this.routerDatas.employType),
        companyId: this.companyId,
        empId: this.routerDatas.empId,
        fixedSalaryTemplateId: this.templateId ? this.templateId : this.lastId,
        reason: 0,
        trainingFixedSalaryDetail: JSON.stringify(this.trialTemplateList),
        officialFixedSalaryDetail: JSON.stringify(this.formalTemplateList),
        officialSalarySum: this.formalTemplateList[this.formalTemplateList.length - 1].amount ? this.formalTemplateList[this.formalTemplateList.length - 1].amount : 0,
        trainingSalarySum: this.trialTemplateList[this.trialTemplateList.length - 1].amount ? this.trialTemplateList[this.trialTemplateList.length - 1].amount : 0,
        remark: this.form.remark
      }).then(function (_ref) {
        var success = _ref.success;
        if (success) {
          // if (this.redPackId) {
          editEmpInfos({
            id: _this8.info.id,
            serviceFeeRuleId: _this8.redPackId ? _this8.redPackId : "",
            phone: _this8.info.phone
            // salaryType: 2
          }).then(function (res) {
            _this8.$message.success("编辑成功");
            if (_this8.$route.query.state === "outsourcing") {
              _this8.$router.push({
                path: "/staff/employee"
              });
            } else {
              setTimeout(function () {
                _this8.$router.push({
                  path: "/wages/file",
                  query: {
                    tabsKey: "1"
                  }
                });
              }, 500);
            }
          });
        }
      });
    },
    // 获取数组里的每一个时间
    onDateChange: function onDateChange(date, dateString, item) {
      item.time = dateString;
    },
    // 打开工价新增弹窗
    createDrawer: function createDrawer() {
      this.$refs.drawers.onCreate();
    },
    // 打开劳务费新增弹窗
    createDrawer1: function createDrawer1() {
      this.$refs.drawersl.onCreate();
    },
    // 工价添加完成更新列表
    update: function update() {
      var _this9 = this;
      this.rulusList = [];
      salaryCalculateRulesListall().then(function (res) {
        if (res.data.length > 0) {
          res.data.map(function (item) {
            if (item.ruleType === 1 || item.ruleType === 3) {
              _this9.rulusList.push(item);
            }
          });
        }
      });
    },
    handleEmpyleeSubmitType: function handleEmpyleeSubmitType() {
      if (this.size === "small" || this.size === "large") {
        this.saveRule();
        // this.handleEmpyleeSubmit();
      }

      if (this.size === "default") {
        this.handleSubmit();
      }
    },
    saveRule: function saveRule() {
      var _this10 = this;
      editEmpInfos({
        id: this.info.id,
        serviceFeeRuleId: this.redPackId ? this.redPackId : "",
        salaryRuleId: this.salaryCalculateRuleId ? this.salaryCalculateRuleId : "",
        phone: this.info.phone,
        salaryType: 2
      }).then(function (res) {
        _this10.$message.success("编辑成功");
        if (_this10.$route.query.state === "outsourcing") {
          _this10.$router.push({
            path: "/staff/employee"
          });
        } else {
          setTimeout(function () {
            _this10.$router.push({
              path: "/wages/file",
              query: {
                tabsKey: "2"
              }
            });
          }, 500);
        }
      });
    },
    // 保存
    handleEmpyleeSubmit: function handleEmpyleeSubmit(e) {
      var _this11 = this;
      var that = this;
      var salaryRuleAddDTO = {};
      var serviceFeeRuleAddDTO = {};
      // e.preventDefault();
      // 工价
      if (this.ruleShow) {
        if (this.salaryCalculateRuleId !== "" && this.salaryCalculateRuleId !== null && this.salaryCalculateRuleId !== undefined) {
          this.$refs.addManForm.validate(function (valid) {
            if (valid) {
              var labourList = JSON.parse(JSON.stringify(_this11.labourList)); // 工价数组
              var leaveList = JSON.parse(JSON.stringify(_this11.addManFormRef.leaveList)); // 倒扣数组
              labourList.map(function (item) {
                item.type = Number(item.type);
              });
              leaveList.map(function (item) {
                item.type = Number(item.type);
              });
              salaryRuleAddDTO.showType = _this11.showType;
              salaryRuleAddDTO.hourAmountDouble = _this11.addManFormRef.hourAmountDouble;
              salaryRuleAddDTO.hourAmountRule = JSON.stringify(labourList);
              salaryRuleAddDTO.leaveOfficeRule = JSON.stringify(leaveList);
              salaryRuleAddDTO.redPacketRule = JSON.stringify(_this11.addManFormRef.numList);
              _this11.show = true;
            } else {
              _this11.show = false;
            }
          });
        }
      }
      // 劳务费
      if (this.redPackId !== "" && this.redPackId !== null && this.redPackId !== undefined) {
        this.$refs.addManForm1.validate(function (valid) {
          if (valid) {
            var labourList1 = JSON.parse(JSON.stringify(_this11.addManFormRef1.changeList)); // 劳务费工价数组
            var leaveList1 = JSON.parse(JSON.stringify(_this11.addManFormRef1.leaveList1)); // 劳务费倒扣数组
            labourList1.map(function (item) {
              item.type = Number(item.type);
            });
            leaveList1.map(function (item) {
              item.type = Number(item.type);
            });
            serviceFeeRuleAddDTO.showType = _this11.showType2;
            serviceFeeRuleAddDTO.hourAmountDouble = _this11.addManFormRef1.hourAmountDouble1;
            serviceFeeRuleAddDTO.commissionRatio = _this11.addManFormRef1.commissionRatio1;
            serviceFeeRuleAddDTO.hourAmountRule = JSON.stringify(labourList1);
            serviceFeeRuleAddDTO.leaveOfficeRule = JSON.stringify(leaveList1);
            serviceFeeRuleAddDTO.redPacketRule = JSON.stringify(_this11.addManFormRef1.numList1);
            serviceFeeRuleAddDTO.specialCostDouble = _this11.addManFormRef1.specialCostDouble;
            _this11.show1 = true;
          } else {
            _this11.show1 = false;
          }
        });
      }
      // 两个规则都存在并且值都填满调用接口没有问题
      if (this.show && this.show1) {
        var values = {
          empId: this.info.id,
          phone: this.info.phone,
          empName: this.info.name,
          companyId: this.companyId,
          // 租户id
          salaryRuleAddDTO: salaryRuleAddDTO,
          // 工价数据
          serviceFeeRuleAddDTO: serviceFeeRuleAddDTO,
          // 劳务费数据
          serviceFeeRuleId: this.redPackId,
          // 劳务费id
          salaryRuleId: this.salaryCalculateRuleId // 工价id
        };

        saveSalaryCalculateRule(values).then(function (res) {
          _this11.$message.success("编辑成功");
          if (_this11.$route.query.state === "outsourcing") {
            _this11.$router.push({
              path: "/staff/employee"
            });
          } else {
            setTimeout(function () {
              _this11.$router.push({
                path: "/wages/file",
                query: {
                  tabsKey: "2"
                }
              });
            }, 500);
          }
        });
      }
      // 如果两个规则id都不存在的话没有问题
      if (!this.ruleShow && !this.ruleShow1) {
        var _values = {
          empId: this.info.id,
          phone: this.info.phone,
          empName: this.info.name,
          companyId: this.companyId,
          // 租户id
          serviceFeeRuleId: this.redPackId ? this.redPackId : "",
          // 劳务费id
          salaryRuleId: this.salaryCalculateRuleId ? this.salaryCalculateRuleId : "" // 工价id
        };

        saveSalaryCalculateRule(_values).then(function (res) {
          _this11.$message.success("编辑成功");
          setTimeout(function () {
            _this11.$router.push({
              path: "/wages/file",
              query: {
                tabsKey: "2"
              }
            });
          }, 500);
        });
      }
      // 首先先判断两个规则都存在，但是内容是没有填写完整，给提示，填写完成以后进入都存在的判断中
      if (this.ruleShow && this.ruleShow1) {
        if (!this.show && !this.show1) {
          this.$message.error("规则内容都为必填");
        }
        if (this.show && !this.show1) {
          this.$message.error("劳务费规则内容都为必填");
        }
        if (this.show1 && !this.show) {
          this.$message.error("工价规则内容都为必填");
        }
      }
      // 当一个规则存在,另一个规则不存在的时候请求，并且判断存在的规则内容有没有填写完毕
      if (this.ruleShow && !this.ruleShow1) {
        if (this.show) {
          var _values2 = {
            empId: this.info.id,
            phone: this.info.phone,
            empName: this.info.name,
            companyId: this.companyId,
            // 租户id
            serviceFeeRuleId: "",
            // 劳务费id
            salaryRuleId: this.salaryCalculateRuleId,
            // 工价id
            salaryRuleAddDTO: salaryRuleAddDTO // 工价数据
          };

          saveSalaryCalculateRule(_values2).then(function (res) {
            _this11.$message.success("编辑成功");
            setTimeout(function () {
              _this11.$router.push({
                path: "/wages/file",
                query: {
                  tabsKey: "2"
                }
              });
            }, 500);
          });
        }
      }
      if (this.ruleShow1 && !this.ruleShow) {
        if (this.show1) {
          var _values3 = {
            empId: this.info.id,
            phone: this.info.phone,
            empName: this.info.name,
            companyId: this.companyId,
            // 租户id
            serviceFeeRuleAddDTO: serviceFeeRuleAddDTO,
            // 劳务费数据
            serviceFeeRuleId: this.redPackId,
            // 劳务费id
            salaryRuleId: this.salaryCalculateRuleId // 工价id
          };

          saveSalaryCalculateRule(_values3).then(function (res) {
            _this11.$message.success("编辑成功");
            setTimeout(function () {
              _this11.$router.push({
                path: "/wages/file",
                query: {
                  tabsKey: "2"
                }
              });
            }, 500);
          });
        }
      }
    },
    limitNumber: function limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      } else {
        return 0;
      }
    },
    // 工价列表下拉事件渲染数据
    handleRulesChange: function handleRulesChange(e) {
      var _this12 = this;
      if (e) {
        this.paginationOpt.defaultCurrent = 1;
        this.paginationOpt.defaultPageSize = 10;
        salaryRulesListByPage({
          pageNo: this.paginationOpt.defaultCurrent,
          pageSize: this.paginationOpt.defaultPageSize,
          salaryCalculateRuleGroupId: e
        }).then(function (res) {
          _this12.dataList = res.data.entities;
          _this12.paginationOpt.total = res.data.entityCount;
        });
      } else {
        this.dataList = [];
        this.paginationOpt.total = 0;
      }
      // this.rulusListCopy.forEach((item, index) => {
      //   if (item.id === e) {
      //     this.showRuleItem = item;
      //   }
      // });
      // if (e) {
      //   this.salaryCalculateRuleId = e;
      //   this.rulusList.map(item => {
      //     if (item.id === e) {
      //       console.log(e, item);
      //       if (item.ruleType === 1 || item.ruleType === 3) {
      //         this.ruleShow = true;
      //         this.showType = item.showType;
      //         if (this.showType === 2) {
      //           this.size = "small";
      //         } else if (this.showType === 1) {
      //           this.size = "large";
      //         }
      //         // console.log(this.showType, "this.showType = item.showType;");
      //         if (item.showType === 1) {
      //           this.option = [
      //             {
      //               label: "月",
      //               value: 1
      //             },
      //             {
      //               label: "天",
      //               value: 3
      //             }
      //           ];
      //         } else {
      //           this.option = [
      //             {
      //               label: "月",
      //               value: 1
      //             },
      //             {
      //               label: "天",
      //               value: 2
      //             },
      //             {
      //               label: "工时",
      //               value: 3
      //             }
      //           ];
      //         }
      //         this.addManFormRef.hourAmountDouble = item.hourAmountDouble;
      //         this.labourList = JSON.parse(item.hourAmountRule) || [];
      //         this.addManFormRef.numList = JSON.parse(item.redPacketRule) || [];
      //         this.addManFormRef.leaveList = JSON.parse(item.leaveOfficeRule) || [];
      //         this.labourList.map(item => {
      //           item.type = String(item.type);
      //         });
      //         this.addManFormRef.leaveList.map(item => {
      //           item.type = String(item.type);
      //         });
      //         if (this.addManFormRef.numList.length > 0) {
      //           this.checked = true;
      //         }
      //         if (this.addManFormRef.leaveList.length > 0) {
      //           this.checked1 = true;
      //         }
      //       } else {
      //         this.ruleShow = false;
      //       }
      //     }
      //   });
      // } else {
      //   this.ruleShow = false;
      //   this.show = false;
      // }
    },
    // 劳务费规则下拉事件渲染数据
    redPackChange: function redPackChange(e) {
      var _this13 = this;
      if (e) {
        this.paginationOpt2.defaultCurrent = 1;
        this.paginationOpt2.defaultPageSize = 10;
        salaryRulesListByPage({
          pageNo: this.paginationOpt2.defaultCurrent,
          pageSize: this.paginationOpt2.defaultPageSize,
          salaryCalculateRuleGroupId: e
        }).then(function (res) {
          _this13.dataList2 = res.data.entities;
          _this13.paginationOpt2.total = res.data.entityCount;
        });
      } else {
        this.dataList2 = [];
        this.paginationOpt2.total = 0;
      }
      // if (e) {
      //   this.redPackId = e;
      //   this.ruleShow1 = true;
      //   this.redPack.map(item => {
      //     if (item.id === e) {
      //       this.showType2 = item.showType;
      //       console.log(this.showType2);
      //       if (item.showType === 1) {
      //         this.option2 = [
      //           {
      //             label: "月",
      //             value: 1
      //           },
      //           {
      //             label: "天",
      //             value: 3
      //           }
      //         ];
      //       } else {
      //         this.option2 = [
      //           {
      //             label: "月",
      //             value: 1
      //           },
      //           {
      //             label: "天",
      //             value: 2
      //           },
      //           {
      //             label: "工时",
      //             value: 3
      //           }
      //         ];
      //       }
      //       this.addManFormRef1.commissionRatio1 = item.commissionRatio;
      //       this.addManFormRef1.hourAmountDouble1 = item.hourAmountDouble;
      //       this.addManFormRef1.changeList = JSON.parse(item.hourAmountRule);
      //       this.addManFormRef1.numList1 = JSON.parse(item.redPacketRule);
      //       this.addManFormRef1.leaveList1 = JSON.parse(item.leaveOfficeRule);
      //       this.addManFormRef1.specialCostDouble = item.specialCostDouble;
      //       this.addManFormRef1.changeList.map(item => {
      //         item.type = String(item.type);
      //       });
      //       this.addManFormRef1.leaveList1.map(item => {
      //         item.type = String(item.type);
      //       });
      //       if (
      //         this.addManFormRef1.changeList.length > 0 ||
      //         this.addManFormRef1.hourAmountDouble1 !== 0 ||
      //         this.addManFormRef1.commissionRatio1 > 0
      //       ) {
      //         this.checked3 = true;
      //       }
      //       if (this.addManFormRef1.numList1.length > 0) {
      //         this.checked4 = true;
      //       }
      //       if (this.addManFormRef1.leaveList1.length > 0) {
      //         this.checked5 = true;
      //       }
      //       if (this.addManFormRef1.specialCostDouble) {
      //         this.checked6 = true;
      //       }
      //     }
      //   });
      // } else {
      //   this.ruleShow1 = false;
      //   this.show1 = false;
      // }
    },
    // 获取工价劳务费规则列表并首次渲染页面数据
    salaryCalculateListall: function salaryCalculateListall() {
      var _this14 = this;
      getSalaryCalculateRuleGroups({
        companyId: this.$store.state.hr.companyId
      }).then(function (res) {
        res.data.forEach(function (item, index) {
          if (item.ruleType === 1) {
            _this14.rulusListCopy.push(item);
          } else if (item.ruleType === 2) {
            _this14.redPack.push(item);
          }
        });
      });
    },
    // 工价计算数组
    handleMenuClick1: function handleMenuClick1(e) {
      if (e.key === "2") {
        this.labourList.push({
          type: e.key,
          number: null,
          units: "",
          newHourAmount: null
        }); // 不满月/天/工时倒扣工资
      } else {
        this.labourList.push({
          type: e.key,
          time: null,
          newHourAmount: null
        }); // 不满月/天/工时减少全价
      }
    },
    // 工价一次性红包添加事件
    onChange: function onChange(e) {
      if (e.target.checked) {
        this.addManFormRef.numList.push({
          number: null,
          units: "",
          redPacketAmount: null
        });
        this.checked = true;
      } else {
        this.addManFormRef.numList = [];
        this.checked = false;
      }
    },
    // 工价一次性红包删除
    deleteNumList: function deleteNumList(item, index) {
      this.addManFormRef.numList.splice(index, 1);
      if (this.addManFormRef.numList.length === 0) {
        this.checked = false;
      }
    },
    // 工价一次性红包数组
    createNumList: function createNumList() {
      this.addManFormRef.numList.push({
        number: null,
        units: "",
        redPacketAmount: null
      });
    },
    // 工价计算删除
    deletelabourList: function deletelabourList(item, index) {
      this.labourList.splice(index, 1);
    },
    // 工价离职倒扣添加事件
    onChange1: function onChange1(e) {
      if (e.target.checked) {
        this.checked1 = true;
      } else {
        this.checked1 = false;
        this.addManFormRef.leaveList = [];
      }
    },
    // 工价离职倒扣删除
    deleteleaveList: function deleteleaveList(item, index) {
      this.addManFormRef.leaveList.splice(index, 1);
      if (this.addManFormRef.leaveList.length === 0) {
        this.checked1 = false;
      }
    },
    // 工价倒扣数组
    handleMenuClick: function handleMenuClick(e) {
      if (e.key === "1") {
        this.addManFormRef.leaveList.push({
          type: e.key,
          number: null,
          units: "",
          deductAmount: null
        }); // 不满月/天/工时倒扣工资
      } else {
        this.addManFormRef.leaveList.push({
          type: e.key,
          number: null,
          units: "",
          newHourAmount: null
        }); // 不满月/天/工时减少全价
      }
    },
    // 劳务费其他费用
    onChange6: function onChange6(e) {
      if (e.target.checked) {
        this.checked6 = true;
      } else {
        this.checked6 = false;
        this.addManFormRef1.specialCostDouble = 0;
      }
    },
    // 劳务费离职倒扣
    onChange5: function onChange5(e) {
      if (e.target.checked) {
        this.checked5 = true;
      } else {
        this.checked5 = false;
        this.addManFormRef1.leaveList1 = [];
      }
    },
    // 劳务费一次性红包添加事件
    onChange4: function onChange4(e) {
      if (e.target.checked) {
        this.addManFormRef1.numList1.push({
          number: null,
          units: null,
          redPacketAmount: null
        });
        this.checked4 = true;
      } else {
        this.addManFormRef1.numList1 = [];
        this.checked4 = false;
      }
    },
    // 劳务费工时抽成
    onChange3: function onChange3(e) {
      if (e.target.checked) {
        this.checked3 = true;
      } else {
        this.checked3 = false;
        this.addManFormRef1.changeList = [];
      }
    },
    // 劳务费工时抽成变更条件
    handleMenuClick2: function handleMenuClick2(e) {
      if (e.key === "1") {
        this.addManFormRef1.changeList.push({
          type: e.key,
          newHourAmount: null,
          time: null
        });
      } else {
        this.addManFormRef1.changeList.push({
          type: e.key,
          number: null,
          units: "",
          newHourAmount: null
        });
      }
    },
    // 劳务费一次性红包删除
    deleteNumList1: function deleteNumList1(item, index) {
      this.addManFormRef1.numList1.splice(index, 1);
      if (this.addManFormRef1.numList1.length === 0) {
        this.checked4 = false;
      }
    },
    // 劳务费一次性红包数组
    createNumList1: function createNumList1() {
      this.addManFormRef1.numList1.push({
        number: null,
        units: "",
        redPacketAmount: null
      });
    },
    // 劳务费新增倒扣条件
    deducMenuClick: function deducMenuClick(e) {
      if (e.key === "1") {
        this.addManFormRef1.leaveList1.push({
          type: e.key,
          number: null,
          units: "",
          deductAmount: null
        }); // 不满月/天/工时倒扣工资
      } else {
        this.addManFormRef1.leaveList1.push({
          type: e.key,
          number: null,
          units: "",
          newHourAmount: null
        }); // 不满月/天/工时减少全价
      }
    },
    // 劳务费删除工时抽成条件
    deleteChangeList: function deleteChangeList(item, index) {
      this.addManFormRef1.changeList.splice(index, 1);
      if (this.addManFormRef1.changeList.length === 0) {
        this.checked3 = false;
      }
    },
    //  劳务费离职倒扣删除
    deleteLeave: function deleteLeave(item, index) {
      this.addManFormRef1.leaveList1.splice(index, 1);
      if (this.addManFormRef1.leaveList1.length === 0) {
        this.checked5 = false;
      }
    }
  }
};