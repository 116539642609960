var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wrap"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "header-back",
    on: {
      click: _vm.back
    }
  }, [_c("a-icon", {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      type: "left"
    }
  }), _vm._v(" 待遇处理模板 ")], 1), _c("a-tabs", {
    on: {
      change: _vm.onTabChange
    }
  }, [_c("a-tab-pane", {
    key: "jcxx",
    attrs: {
      tab: "基础信息"
    }
  }), _c("a-tab-pane", {
    key: "clgc",
    attrs: {
      tab: "处理过程"
    }
  })], 1)], 1), _c("div", {
    staticClass: "main"
  }, [_c("TabJcxx", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeTab === "jcxx",
      expression: "activeTab === 'jcxx'"
    }],
    ref: "jcxx",
    attrs: {
      isCreate: _vm.isCreate
    }
  }), _c("TabClgc", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeTab === "clgc",
      expression: "activeTab === 'clgc'"
    }],
    ref: "clgc",
    attrs: {
      isCreate: _vm.isCreate
    }
  })], 1), _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保存")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };