var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    on: {
      back: _vm.goBack
    }
  }, [_vm._v("确认报送员工信息")]), _c("div", {
    staticClass: "tabbox"
  }, [_c("div", {
    staticClass: "setting-stepbox"
  }, [_c("a-steps", {
    attrs: {
      current: _vm.current
    }
  }, [_c("a-step", {
    attrs: {
      title: "确认信息"
    }
  }), _c("a-step", {
    attrs: {
      title: "填写密码信息"
    }
  }), _c("a-step", {
    attrs: {
      title: "查看结果"
    }
  })], 1)], 1)]), _vm.current == 0 ? _c("information", {
    on: {
      upData: _vm.upData
    }
  }) : _vm._e(), _vm.current == 1 ? _c("password", {
    on: {
      upData: _vm.upData,
      upDatadata: _vm.upDatadata
    }
  }) : _vm._e(), _vm.current == 2 ? _c("results", {
    attrs: {
      dataShow: _vm.dataShow
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };