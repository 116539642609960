import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
import { getCompanies } from "@/api/company/index";
import { sealVerifyReq } from "@/api/company/contract";
import { isContainByString } from "@/utils/index";
export default {
  data: function data() {
    return {
      isContainByString: isContainByString,
      companies: [],
      defaultValue: null,
      companyName: "",
      regType: "NORMAL",
      organCode: "",
      legalName: "",
      agentIdNo: ""
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    companyId: function companyId() {
      return this.hr.companyId;
    },
    currentCompanyName: function currentCompanyName() {
      return this.hr.companyName;
    }
  }),
  created: function created() {
    this.loadCompanies();
  },
  methods: {
    setType: function setType(type) {
      this.regType = type;
    },
    back: function back() {
      this.$router.back();
    },
    submit: function submit() {
      var _this = this;
      sealVerifyReq({
        companyId: this.isContainByString("contractAdmin") ? "0" : this.companyId,
        companyName: this.companyName,
        regType: this.regType,
        organCode: this.organCode,
        legalName: this.legalName,
        agentIdNo: this.agentIdNo,
        sealId: this.$route.params.id
      }).then(function (res) {
        _this.$message.success("认证成功");
        _this.$router.push("/".concat(_this.isContainByString("contractAdmin") ? "contractAdmin" : "contract", "/stamp"));
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this.$message.success("认证失败");
      });
    },
    loadCompanies: function loadCompanies() {
      var _this2 = this;
      getCompanies().then(function (res) {
        _this2.companies = res.data.entities;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};