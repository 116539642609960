var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page_set"
  }, [_c("div", {
    staticClass: "no-payslip"
  }, [_c("img", {
    staticClass: "autoimg",
    attrs: {
      src: require("../../../../assets/img/payslip/beijing.png")
    }
  }), _c("div", {
    staticClass: "cent"
  }, [_c("h2", [_vm._v("最智能、最简单的社保公积金系统")]), _c("h3", [_vm._v(" 更多个性化设置、更方便、更快捷 ")]), _c("a-button", {
    staticClass: "mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.open
    }
  }, [_vm._v(" 马上开通 "), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1)], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };