// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".preview[data-v-bdf5abbe] {\n  position: relative;\n  width: 375px;\n  height: 790px;\n  background-size: 100% 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.preview .preview-inner[data-v-bdf5abbe] {\n  position: absolute;\n  top: 54px;\n  left: 16px;\n  width: 343px;\n  height: 120px;\n}", ""]);
// Exports
module.exports = exports;
