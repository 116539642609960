import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-sub-menu", _vm._g(_vm._b({}, "a-sub-menu", _vm.$props, false), _vm.$listeners), [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("item", {
    attrs: {
      icon: _vm.item.icon,
      title: _vm.item.title
    }
  })], 1), _vm._l(_vm.item.children, function (child) {
    return [!child.children ? [!_vm.hideMenu.includes(child.url) ? _c("a-menu-item", {
      key: child.url
    }, [_c("router-link", {
      attrs: {
        to: child.url
      }
    }, [_c("item", {
      attrs: {
        icon: child.icon,
        title: child.title
      }
    })], 1)], 1) : _vm._e()] : _c("sub-menu", {
      key: child.url,
      attrs: {
        item: child,
        "hide-menu": _vm.hideMenu
      }
    })];
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };