import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import { Check, EditorRules } from "@/api/check";
import editorShifts from "./editor-shifts";
import chechCardTime from "./check-card-time";
export default {
  name: "AddFreeTime",
  components: {
    editorShifts: editorShifts,
    chechCardTime: chechCardTime
  },
  props: {
    freeCard: {}
  },
  data: function data() {
    return {
      /* 判断是否编辑/添加 */
      editorType: false,
      /* 班次条件 */
      shiftsOptions: {},
      /* 排班周期条件 */
      chechCardTimeOptions: {},
      // 周期天数
      /*  cycleDay: {
         cycleName: '三班倒',//周期名称
         cycleDays: [{
           daysName: '第1天',//第几天
           shiftName: '休息'//班次名称
         }]
       }, */
      editorData: {
        attendCycleList: [],
        attendShiftList: [],
        attendStaffScheduleList: []
      }
    };
  },
  computed: {
    attendCycleList: function attendCycleList() {
      return this.editorData.attendCycleList;
    },
    attendShiftList: function attendShiftList() {
      return this.editorData.attendShiftList;
    }
  },
  methods: {
    /*  添加班次 */addShifts: function addShifts() {
      this.shiftsOptions = {
        show: true,
        editorType: false,
        obj: {}
      };
    },
    /* 编辑班次 */editorShifts: function editorShifts(item) {
      this.shiftsOptions = {
        show: true,
        editorType: true,
        obj: item
      };
    },
    /* 删除 */deleteItem: function deleteItem(item, arr) {
      arr.find(function (val, i, arrs) {
        val.name === item.name ? arrs.splice(i, 1) : null;
      });
    },
    /* 添加排班周期 */addCardTime: function addCardTime() {
      this.chechCardTimeOptions = {
        show: true,
        editorType: false
      };
    },
    /* 编辑周期 */editorCardTime: function editorCardTime(item) {
      this.chechCardTimeOptions = {
        show: true,
        editorType: true,
        obj: item
      };
    },
    /* 删除周期 */deleteCardTime: function deleteCardTime(item, arr) {
      arr.find(function (val, i, arrs) {
        val.name === item.name ? arrs.splice(i, 1) : null;
      });
    },
    /* 完成 */editorSave: function editorSave() {
      var _this = this;
      this.freeCard.addFreeCard = !this.freeCard.addFreeCard;
      this.editorData.attendStaffScheduleList = [].concat(_toConsumableArray(this.freeCard.deptList), _toConsumableArray(this.freeCard.staffList));
      /* {
          empInfoName: '',//人员姓名
          shiftName: '',//shiftName
          yearMonthDay: ''//年月日
        } */

      Check({
        url: EditorRules.postAttend,
        data: this.editorData,
        method: "POST"
      }).then(function (data) {
        return data.success ? _this.$notification["success"]({
          message: "新增成功"
        }) : null;
      });
    }
  }
};