import request from "@/utils/request";
export function getTalentPoolImportInfo(params) {
  return request({
    url: "/recruit2/api/v1/talentPoolTemps/talentPoolImportInfo",
    method: "GET",
    params: params
  });
}

// export function getToSave(relationId) {
//   return request({
//     url: `/recruit2/api/v1/talentPoolTemps/toSave/${relationId}`,
//     method: "GET"
//   });
// }