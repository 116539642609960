var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      width: 820,
      title: "打卡日期",
      visible: _vm.show,
      "destroy-on-close": true
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("handleDateShow", false);
      },
      ok: _vm.handleData
    }
  }, [_c("a-form", {
    staticClass: "lab_l form_l"
  }, [_c("a-form-item", {
    attrs: {
      label: "日期",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-date-picker", {
    attrs: {
      value: _vm.needPunchDate.daily ? _vm.moment("".concat(_vm.needPunchDate.daily), "YYYY-MM-DD") : _vm.needPunchDate.daily,
      "allow-clear": false
    },
    on: {
      change: _vm.dateChange
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "打卡时间",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_vm._l(_vm.needPunchDate.periodList, function (item, i) {
    return _c("a-row", {
      key: i
    }, [_c("span", {
      staticClass: "mg_r10"
    }, [_vm._v("上班")]), _c("a-time-picker", {
      attrs: {
        value: item.startPeriod ? _vm.moment("".concat(item.startPeriod), "HH:mm") : item.startPeriod,
        format: "HH:mm",
        "allow-clear": false
      },
      on: {
        change: function change(time, timeString) {
          return _vm.onWorkChange(time, timeString, i);
        }
      }
    }), _vm._v(" -"), _c("span", {
      staticClass: "mg_r10"
    }, [_vm._v("下班")]), _c("a-time-picker", {
      attrs: {
        value: item.endPeriod ? _vm.moment("".concat(item.endPeriod), "HH:mm") : item.endPeriod,
        format: "HH:mm",
        "allow-clear": false
      },
      on: {
        change: function change(time, timeString) {
          return _vm.offWorkChange(time, timeString, i);
        }
      }
    }), _vm.needPunchDate.periodList.length < 2 && i < 1 ? _c("a-icon", {
      staticClass: "addIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "plus-circle"
      },
      on: {
        click: _vm.add
      }
    }) : _vm._e(), _vm.needPunchDate.periodList.length > 1 && i >= 1 ? _c("a-icon", {
      staticClass: "deleteIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: _vm.del
      }
    }) : _vm._e()], 1);
  }), _vm.needPunchDate.periodList ? _c("a-row", [_c("a-checkbox", {
    model: {
      value: _vm.needPunchDate.periodList[0].hasRestPeriod,
      callback: function callback($$v) {
        _vm.$set(_vm.needPunchDate.periodList[0], "hasRestPeriod", $$v);
      },
      expression: "needPunchDate.periodList[0].hasRestPeriod"
    }
  }, [_vm._v(" 休息时间 ")]), _c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("休息时间不算工作时长")]), _vm.needPunchDate.periodList[0].hasRestPeriod ? _c("div", [_c("span", {
    staticClass: "mg_r10"
  }, [_vm._v("开始")]), _c("a-time-picker", {
    attrs: {
      "default-value": _vm.moment("12:00", "HH:mm"),
      format: "HH:mm",
      value: _vm.needPunchDate.restPeriod.startPeriod ? _vm.moment("".concat(_vm.needPunchDate.restPeriod.startPeriod), "HH:mm") : _vm.needPunchDate.restPeriod.startPeriod,
      "allow-clear": false
    },
    on: {
      change: _vm.onRestChange
    }
  }), _c("span", [_vm._v(" - ")]), _c("span", {
    staticClass: "mg_r10"
  }, [_vm._v("结束")]), _c("a-time-picker", {
    attrs: {
      "default-value": _vm.moment("13:00", "HH:mm"),
      format: "HH:mm",
      value: _vm.needPunchDate.restPeriod.endPeriod ? _vm.moment("".concat(_vm.needPunchDate.restPeriod.endPeriod), "HH:mm") : _vm.needPunchDate.restPeriod.endPeriod,
      "allow-clear": false
    },
    on: {
      change: _vm.offRestChange
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 2), _c("a-form-item", {
    attrs: {
      label: "事由",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "写点什么..."
    },
    model: {
      value: _vm.needPunchDate.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.needPunchDate, "reason", $$v);
      },
      expression: "needPunchDate.reason"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };