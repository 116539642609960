import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import { findCompanyGroup as _findCompanyGroup } from "@/api/company/contract";
import { getCompanyReqNew, addCustomerReqNew, getDateReqNew, deleteItemReqNew } from "./api";
import TabGroup from "./group.vue";
import moment from "moment";
import _ from "lodash";
export default {
  components: {
    TabGroup: TabGroup
  },
  data: function data() {
    return {
      groupType: "company",
      addForm: {},
      loading: false,
      companyDataList: [],
      date: null,
      customerDetailsType: undefined,
      companyName: "",
      addVisible: false,
      groupList: [],
      flatDataList: [],
      replaceFields2: {
        children: "companyList",
        title: "name",
        key: "uuid",
        value: "uuid"
      },
      typeDict: {
        customer: "客户明细表",
        social: "社保明细表",
        salary: "薪资明细表"
      },
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      fuwufeixiangLst: []
    };
  },
  computed: {},
  watch: {
    groupType: function groupType(newVal) {
      if (newVal === "company") {
        this.getCompanyList();
      } else {
        this.$refs.tabGroup.loadData();
      }
    },
    customerDetailsType: function customerDetailsType() {
      this.pagination.current = 1;
      this.getCompanyList();
    }
  },
  created: function created() {
    this.getDate();
    this.loadFuwufeixiang();
  },
  methods: {
    onNameChange: _.throttle(function () {
      this.pagination.current = 1;
      this.getCompanyList();
    }, 1000),
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    loadFuwufeixiang: function loadFuwufeixiang() {
      var _this = this;
      this.$request({
        url: "/salary/api/v1/serviceCharges/selectByNameLike"
      }).then(function (res) {
        _this.fuwufeixiangLst = res.data.map(function (item) {
          return {
            value: item.id,
            label: item.name
          };
        });
      }).catch(function () {});
    },
    onCancel: function onCancel() {
      this.addVisible = false;
    },
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getCompanyList();
    },
    formatYM: function formatYM(yl, ml) {
      if (yl) {
        return "".concat(yl, "-").concat(ml < 10 ? "0" : "").concat(ml);
      }
      return "--";
    },
    deleteItem: function deleteItem(e) {
      var _this2 = this;
      deleteItemReqNew(e.customerDetailsIds[0]).then(function (res) {
        _this2.$notification["success"]({
          message: "success",
          description: "删除成功"
        });
        _this2.getDate();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    getDate: function getDate() {
      var _this3 = this;
      getDateReqNew().then(function (res) {
        if (_this3.$route.query.date) {
          _this3.date = moment(_this3.$route.query.date, "YYYY-MM");
        } else if (res.data && res.data.yearly && res.data.monthly) {
          _this3.date = moment("".concat(res.data.yearly) + "-" + "".concat(res.data.monthly), "YYYY-MM");
        } else {
          _this3.date = moment(moment().format("YYYY-MM"), "YYYY-MM");
        }
        _this3.findCompanyGroup();
        _this3.getCompanyList();
      });
    },
    treeSearch: function treeSearch(e) {
      this.findCompanyGroup(e);
    },
    showAdd: function showAdd() {
      this.addVisible = true;
      this.addForm = {};
    },
    findCompanyGroup: function findCompanyGroup(e) {
      var _this4 = this;
      _findCompanyGroup({
        name: e || null
      }).then(function (res) {
        _this4.companyDataList = _this4.addTreeSlot(res.data);
        _this4.flatten(_this4.companyDataList);
        _this4.addForm.companyInfoList = _this4.addForm.companyInfoList ? JSON.parse(JSON.stringify(_this4.addForm.companyInfoList)) : undefined;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    addTreeSlot: function addTreeSlot(data) {
      var _this5 = this;
      if (!data) {
        return [];
      }
      data.forEach(function (el) {
        el.scopedSlots = {
          title: "custom"
        };
        _this5.addTreeSlot(el.companyList);
      });
      return data;
    },
    flatten: function flatten(list) {
      var _this6 = this;
      if (list !== null) {
        list.forEach(function (item) {
          _this6.flatDataList.push(item);
          _this6.flatten(item.companyList);
        });
      }
    },
    handleAddOk: function handleAddOk() {
      var _this7 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var companyArr = [];
          _this7.addForm.companyInfoList.forEach(function (item) {
            _this7.flatDataList.forEach(function (item2, index2) {
              if (item2.uuid && item2.uuid === item.value) {
                companyArr.push({
                  companyId: item2.id,
                  companyName: item2.name
                });
              }
            });
          });
          addCustomerReqNew({
            monthly: moment(_this7.addForm.date).format("MM"),
            type: _this7.addForm.type,
            yearly: moment(_this7.addForm.date).format("YYYY"),
            salaryMonthly: _this7.addForm.salaryDate ? moment(_this7.addForm.salaryDate).format("MM") : undefined,
            salaryYearly: _this7.addForm.salaryDate ? moment(_this7.addForm.salaryDate).format("YYYY") : undefined,
            socialMonthly: _this7.addForm.socialDate ? moment(_this7.addForm.socialDate).format("MM") : undefined,
            socialYearly: _this7.addForm.socialDate ? moment(_this7.addForm.socialDate).format("YYYY") : undefined,
            companyInfoList: companyArr,
            serviceChargeIds: _this7.addForm.serviceChargeIds || []
          }).then(function (res) {
            _this7.addVisible = false;
            _this7.getCompanyList();
          });
        }
      });
    },
    goDetail: function goDetail(item) {
      localStorage.setItem("kehumingxibiao_item", JSON.stringify(item));
      this.$router.push({
        path: "kehumingxibiao/customerDetail",
        query: {
          monthly: moment(this.date).format("MM"),
          yearly: moment(this.date).format("YYYY"),
          formType: item.customerDetailsType,
          groupType: item.subjectType
        }
      });
    },
    getCompanyList: function getCompanyList() {
      var _this8 = this;
      this.loading = true;
      this.groupList = [];
      var params = {
        monthly: moment(this.date).format("MM"),
        yearly: moment(this.date).format("YYYY"),
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      if (this.customerDetailsType) {
        params["customerDetailsType"] = this.customerDetailsType;
      }
      if (this.companyName) {
        params["companyName"] = this.companyName;
      }
      getCompanyReqNew(params).then(function (res) {
        _this8.groupList = res.data.entities;
        _this8.pagination.total = res.data.entityCount;
        _this8.loading = false;
      }).catch(function (err) {
        _this8.loading = false;
        console.log("debug log --> ", err);
      });
    },
    // 月份选择变化
    onMonthChange: function onMonthChange(e) {
      this.date = e;
      var query = JSON.parse(JSON.stringify(this.$router.history.current.query)); // 取当前路由query值并且深拷贝
      var path = this.$router.history.current.path; // 取当前路由地址
      query.date = moment(this.date).format("YYYY-MM"); // 修改变动的query值
      this.$router.push({
        path: path,
        query: query
      }); // 改变query值
      this.pagination.current = 1;
      this.getCompanyList();
    }
  }
};