import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("rTitle", {
    attrs: {
      type: "center",
      tit: "人才库"
    }
  }), _c("div", {
    staticClass: "r-talent-box"
  }, [_c("div", {
    staticClass: "xq-form-wrapper"
  }, [_c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "搜索求职者名字",
      allowClear: ""
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.jobSeekerName,
      callback: function callback($$v) {
        _vm.jobSeekerName = $$v;
      },
      expression: "jobSeekerName"
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "性别",
      "allow-clear": "",
      placeholder: "请选择性别"
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.gender,
      callback: function callback($$v) {
        _vm.gender = $$v;
      },
      expression: "gender"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "男"
    }
  }, [_vm._v("男")]), _c("a-select-option", {
    attrs: {
      value: "女"
    }
  }, [_vm._v("女")])], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "手机号",
      "allow-clear": "",
      placeholder: "请输入手机号"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  }, [_c("a-cascader", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.positionTypes,
      "field-names": {
        label: "name",
        value: "id",
        children: "childList"
      },
      displayRender: _vm.displayRender,
      placeholder: "请选择意向岗位",
      "show-search": {
        filter: _vm.filter
      }
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.intentionJob,
      callback: function callback($$v) {
        _vm.intentionJob = $$v;
      },
      expression: "intentionJob"
    }
  })], 1), _c("a-col", {
    staticClass: "mt16",
    attrs: {
      span: 6
    }
  }, [_c("city", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择工作城市",
      level: 2
    },
    on: {
      change: _vm.onCitySearch
    },
    model: {
      value: _vm.addressCode,
      callback: function callback($$v) {
        _vm.addressCode = $$v;
      },
      expression: "addressCode"
    }
  })], 1), _c("a-col", {
    staticClass: "mt16",
    attrs: {
      span: 6
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "请输入创建来源"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.createUserName,
      callback: function callback($$v) {
        _vm.createUserName = $$v;
      },
      expression: "createUserName"
    }
  })], 1), _c("a-col", {
    staticClass: "mt16",
    attrs: {
      span: 6
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "在职状态",
      "allow-clear": "",
      placeholder: "请选择在职状态"
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.serviceStatus,
      callback: function callback($$v) {
        _vm.serviceStatus = $$v;
      },
      expression: "serviceStatus"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "在职"
    }
  }, [_vm._v("在职")]), _c("a-select-option", {
    attrs: {
      value: "未在职"
    }
  }, [_vm._v("未在职")])], 1)], 1)], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "font-size": "14px",
      "font-weight": "40",
      color: "#636363",
      "padding-bottom": "32px",
      "border-bottom": "1px solid #e8e8e8",
      "margin-top": "32px"
    }
  }, [_c("div", [_vm._v("工作经验:")]), _vm._l(_vm.experienceList, function (item, index) {
    return _c("div", {
      key: index,
      class: _vm.colHighlighted === index ? "colpri" : "",
      staticStyle: {
        "margin-left": "20px",
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.experienceChange(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), _c("div", {
    staticClass: "custom1",
    class: _vm.colHighlighted === 5 ? "colpri" : "",
    staticStyle: {
      "margin-left": "20px"
    },
    on: {
      click: _vm.zdy
    }
  }, [_vm._v("自定义")]), _vm.isCustom ? _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "120px",
      "margin-top": "-4px",
      "margin-left": "20px"
    },
    attrs: {
      min: 0,
      placeholder: "请输入"
    },
    model: {
      value: _vm.startYear,
      callback: function callback($$v) {
        _vm.startYear = $$v;
      },
      expression: "startYear"
    }
  }), _c("div", {
    staticStyle: {
      "margin-left": "12px"
    }
  }, [_vm._v("-")]), _c("a-input-number", {
    staticStyle: {
      width: "120px",
      "margin-top": "-4px",
      "margin-left": "12px"
    },
    attrs: {
      min: _vm.startYear || 0,
      placeholder: "请输入"
    },
    model: {
      value: _vm.endYear,
      callback: function callback($$v) {
        _vm.endYear = $$v;
      },
      expression: "endYear"
    }
  }), _c("a-button", {
    staticStyle: {
      "margin-top": "-4px"
    },
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.experienceChange();
      }
    }
  }, [_vm._v("确认")])], 1) : _vm._e()], 2), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "margin-top": "24px"
    }
  }, [_c("div"), _c("div", [_c("a-button", {
    staticClass: "entry",
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "entry"
        });
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "qrcode"
    }
  }), _vm._v("扫码录入 ")], 1), _c("a-button", {
    staticClass: "mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "talentImports"
        });
      }
    }
  }, [_vm._v("导入员工数据")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "peopleAdd"
        });
      }
    }
  }, [_vm._v("新建人才")])], 1)]), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-table", {
    attrs: {
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      bordered: false,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [_c("div", {
            key: index
          }, [col.dataIndex === "name" ? _c("div", {
            staticClass: "table-item",
            on: {
              click: function click($event) {
                return _vm.onInfo(record);
              }
            }
          }, [_c("div", {
            staticClass: "table-tr"
          }, [_c("div", {
            staticClass: "t-row",
            staticStyle: {
              width: "300px",
              "flex-shrink": "0"
            }
          }, [_c("div", {
            staticClass: "t-col t-name",
            staticStyle: {
              "margin-bottom": "12px"
            }
          }, [_c("a-tooltip", {
            attrs: {
              placement: "top"
            }
          }, [record.jobSeekerName ? _c("template", {
            slot: "title"
          }, [_c("span", [_vm._v(_vm._s(record.jobSeekerName))])]) : _vm._e(), _c("div", {
            staticClass: "t-name-font"
          }, [_vm._v(_vm._s(record.jobSeekerName))])], 2), _c("div", {
            staticClass: "t-desc"
          }, [_c("div", {
            staticClass: "t-name-address"
          }, [_vm._v(_vm._s(record.addressCode ? _vm.getAddressCode(record.addressCode) : ""))]), record.addressCode ? _c("span", {
            staticStyle: {
              padding: "0 4px"
            }
          }, [_vm._v("|")]) : _vm._e(), _c("span", {
            staticStyle: {
              padding: "0 4px"
            }
          }, [_vm._v(_vm._s(record.sex ? record.sex === "男" ? "男" : "女" : ""))]), record.sex && record.birthday ? _c("span", {
            staticStyle: {
              padding: "0 4px"
            }
          }, [_vm._v("|")]) : _vm._e(), _c("span", {
            staticStyle: {
              padding: "0 4px"
            }
          }, [_vm._v(_vm._s(record.birthday ? _vm.getBirthday(record.birthday) > 0 ? "".concat(_vm.getBirthday(record.birthday), "\u5C81") : "0岁" : ""))])])], 1), _c("div", {
            staticClass: "t-col"
          }, [_c("a-icon", {
            staticStyle: {
              color: "#bfbfbf"
            },
            attrs: {
              type: "phone",
              theme: "filled"
            }
          }), _vm._v(" " + _vm._s(record.phone) + " ")], 1)]), _c("div", {
            staticClass: "t-row",
            staticStyle: {
              flex: "1"
            }
          }, [_c("div", {
            staticClass: "t-col display"
          }, [_c("div", {
            staticClass: "t-label"
          }, [_vm._v("意向岗位:")]), _c("div", {
            staticClass: "t-content"
          }, [_vm._v(_vm._s(record.intentionJob ? record.intentionJob.split("*")[1] : ""))])]), _c("div", {
            staticClass: "t-col display"
          }, [_c("div", {
            staticClass: "t-label"
          }, [_vm._v("工作经验:")]), _c("div", {
            staticClass: "t-content overflows"
          }, [_vm._v(_vm._s(record.workYears ? "".concat(record.workYears, "\u5E74") : ""))])])]), _c("div", {
            staticClass: "t-row",
            staticStyle: {
              width: "200px",
              "flex-shrink": "0"
            }
          }, [_c("div", {
            staticClass: "t-col display"
          }, [_c("div", {
            staticClass: "t-label"
          }, [_vm._v("创建来源:")]), _c("div", {
            staticClass: "t-content t-remark"
          }, [_vm._v(_vm._s(record.createUserName))])])])]), _c("div", {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              width: "200px",
              "flex-shrink": "0"
            }
          }, [_c("a-button", {
            attrs: {
              size: "small",
              type: "link"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.doInfo(record);
              }
            }
          }, [_vm._v("一键投递")]), _c("a-button", {
            attrs: {
              size: "small",
              type: "link"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.addRecord(record);
              }
            }
          }, [_vm._v("添加跟进")]), _c("a-dropdown-button", {
            staticClass: "dropbtn",
            attrs: {
              placement: "bottomLeft"
            }
          }, [_c("a-menu", {
            attrs: {
              slot: "overlay"
            },
            slot: "overlay"
          }, [_c("a-menu-item", {
            on: {
              click: function click($event) {
                return _vm.onInfo(record);
              }
            }
          }, [_vm._v("查看详情")]), _c("a-menu-item", [_c("a-popconfirm", {
            attrs: {
              title: "\u786E\u8BA4\u8981\u5220\u9664\u5417\uFF1F",
              "ok-text": "确认",
              "cancel-text": "取消"
            },
            on: {
              confirm: function confirm($event) {
                return _vm.onDelJobSeekers(record.talentPoolId);
              }
            }
          }, [_c("div", [_vm._v("删除")])])], 1)], 1)], 1)], 1)]) : _vm._e()])];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1)]), _c("a-modal", {
    attrs: {
      title: "添加跟进记录"
    },
    on: {
      ok: _vm.onAddOk
    },
    model: {
      value: _vm.addVisible,
      callback: function callback($$v) {
        _vm.addVisible = $$v;
      },
      expression: "addVisible"
    }
  }, [_c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进人",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.adminUsers,
      showSearch: "",
      filterOption: _vm.filterOption,
      placeholder: "请选择跟进人"
    },
    model: {
      value: _vm.recordCur,
      callback: function callback($$v) {
        _vm.recordCur = $$v;
      },
      expression: "recordCur"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进方式",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.followTypeList,
      placeholder: "请选择跟进方式"
    },
    model: {
      value: _vm.recordMed,
      callback: function callback($$v) {
        _vm.recordMed = $$v;
      },
      expression: "recordMed"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      required: "",
      label: "跟进内容",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请填写跟进内容"
    },
    model: {
      value: _vm.recordCon,
      callback: function callback($$v) {
        _vm.recordCon = $$v;
      },
      expression: "recordCon"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };