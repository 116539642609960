import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import { columnsResultList } from "./funcs";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    resultData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      columnsResultList: columnsResultList,
      allCount: 0,
      errorCount: 0,
      successCount: 0,
      errorLst: []
    };
  },
  computed: {},
  watch: {
    resultData: {
      handler: function handler(newVal) {
        if (newVal) {
          this.allCount = newVal.allCount;
          this.errorCount = newVal.errorCount;
          this.successCount = newVal.successCount;
          this.errorLst = newVal.errorList;
        }
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    formatDate: function formatDate(val) {
      if (val) {
        return this.$dayjs(val).format("YYYY-MM-DD");
      }
      return "--";
    },
    notConfirm: function notConfirm(record) {
      this.errorLst = this.errorLst.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          confirmed: item.empId === record.empId ? true : item.confirmed
        });
      });
    },
    doConfirm: function doConfirm(record) {
      var _this = this;
      this.$confirm({
        title: "新增在保提示",
        content: record.errorCause,
        onOk: function onOk() {
          _this.$request({
            url: "/insurance/api/v1/qyInsuranceRecords",
            method: "post",
            data: {
              empId: record.empId,
              empName: record.name,
              idCard: record.idCard,
              schemeId: record.schemeId,
              beginTime: record.beginTime,
              endTime: record.endTime,
              careerType: record.careerType,
              monthAmount: record.monthAmount
            }
          }).then(function (res) {
            _this.$message.success("新增成功");
            _this.errorLst = _this.errorLst.map(function (item) {
              return _objectSpread(_objectSpread({}, item), {}, {
                confirmed: item.empId === record.empId ? true : item.confirmed
              });
            });
          }).catch(function (err) {
            console.log("overlaidDataReq", err);
          });
        }
      });
    }
  }
};