import { statisticsCountByCompanyIdReq } from "../api";
export default {
  name: "",
  components: {},
  mixins: [],
  props: ["selectTypeIndex"],
  data: function data() {
    return {
      summary: {},
      loadding: false
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.loadData();
  },
  mounted: function mounted() {},
  methods: {
    loadData: function loadData() {
      var _this = this;
      if (this.loading) {
        return false;
      }
      this.loading = true;
      statisticsCountByCompanyIdReq(this.$store.state.hr.companyId).then(function (res) {
        if (res && res.data) {
          _this.summary = res.data;
        }
        _this.loading = false;
      }).catch(function (err) {
        _this.loading = false;
        console.log(err);
      });
    },
    setType: function setType(val) {
      this.$emit("typeChange", val);
    }
  }
};