import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find-index.js";
import { getDepEmpInfo, getAllEmp, getAllSignEmp, empContractByIdCards, listByCompanyIdGet } from "@/api/company/contract";
import moment from "moment";
var columns = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: 80
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  width: 120
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 180
}];
export default {
  props: {
    year: {
      type: String,
      default: ""
    },
    month: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    },
    number: {
      type: Number,
      default: 0
    },
    templateId: {
      type: Number,
      default: 0
    },
    defaultSelectedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectedList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isOnlineOrLeave: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      form: {},
      columns: columns,
      dataList: [],
      seniorList: [],
      signingStatus: null,
      tabsList: ["全部", "首次签署", "非首次签署"],
      spinning: false,
      chosenList: [],
      nameOrPhone: "",
      checkStatue: 0,
      importDataLength: 0,
      mode: "1",
      treeData: [],
      selectedListTemp: [],
      isAllSelected: false,
      selectedKeysBeforeSearch: [],
      seniorVisible: false,
      labelList: [],
      rulusList: [],
      textContent: "",
      copyVisible: false,
      selectedRowKeys: [],
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 16
      },
      pagination: {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      },
      errorState: false,
      postTypeValue: 3,
      pageSizeOptions: ["10", "20", "50", "100"],
      pageSize: 10,
      current: 1,
      total: 50
    };
  },
  computed: {
    postTypeList: function postTypeList() {
      return this.$store.state.enums.postTypeLst;
    },
    postList: function postList() {
      return this.postTypeList.map(function (item) {
        return {
          value: item.title,
          key: item.value
        };
      });
    }
  },
  watch: {
    textContent: function textContent(e) {
      if (e) {
        this.errorState = false;
      }
    },
    show: function show(val) {
      if (val) {
        this.selectedListTemp = [];
        this.selectedListTemp = JSON.parse(JSON.stringify(this.selectedList));
        this.treeData = [];
        this.current = 1;
        this.postTypeValue = 3;
        this.mode = "1";
        this.importDataLength = this.selectedList.length;
        // this.onPageChange(1);
        this.spinning = false;
        this.getSelectDepTree();
      }
    },
    month: function month(val) {}
  },
  methods: {
    clearForm: function clearForm() {
      this.form = {};
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    rangeChange: function rangeChange(e) {
      this.form.joinDateStart = e[0];
      this.form.joinDateEnd = e[1];
    },
    getListByCompanyId: function getListByCompanyId() {
      var _this = this;
      listByCompanyIdGet({
        companyId: this.$store.state.hr.companyId
      }).then(function (_ref) {
        var data = _ref.data;
        _this.labelList = data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      var _this2 = this;
      this.selectedRowKeys = selectedRowKeys;
      this.dataList.forEach(function (item) {
        item.id = item.empId;
        selectedRowKeys.forEach(function (item2) {
          if (item.id === item2) {
            _this2.seniorList.push(item);
            _this2.seniorList = _this2.unique(_this2.seniorList);
          }
        });
      });
      this.seniorList.forEach(function (item, index) {
        if (_this2.selectedRowKeys.indexOf(item.id) === -1) {
          item.isSelectedItem = 2;
        }
      });
      var arr = [];
      this.seniorList.forEach(function (item, index) {
        if (!item.isSelectedItem) {
          arr.push(item);
        }
      });
      this.seniorList = JSON.parse(JSON.stringify(arr));
      this.seniorList = this.unique2(this.seniorList);
    },
    deleteSeniorItem: function deleteSeniorItem(e) {
      var _this3 = this;
      if (e.id === null) {
        e.id = e.empId;
      }
      this.seniorList.forEach(function (item, index) {
        if (item.id === null) {
          item.id = item.empId;
        }
        if (item.id === e.id) {
          _this3.seniorList.splice(index, 1);
        }
      });
      this.selectedRowKeys.forEach(function (item2, index2) {
        if (item2 === e.id) {
          _this3.selectedRowKeys.splice(index2, 1);
        }
      });
    },
    deleteCopyItem: function deleteCopyItem(e) {
      var _this4 = this;
      this.chosenList.forEach(function (item, index) {
        if (item.id === e.id) {
          _this4.chosenList.splice(index, 1);
        }
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      // this.checkStatue = 0;
      this.getSeniorList();
    },
    getSeniorList: function getSeniorList() {
      var _this5 = this;
      var params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        companyId: this.$store.state.hr.companyId
      };
      if (this.checkStatue === 1) {
        params = {
          // onlyCurrentDep: true,
          // onlyUser: true,
          salaryRuleId: this.form.rule ? this.form.rule : null,
          postType: this.form.postType ? this.form.postType : null,
          tag: this.form.tag ? this.form.tag : null,
          nameOrPhone: this.form.name ? this.form.name : null,
          joinDateStart: this.form.joinDateStart ? moment(this.form.joinDateStart).format("YYYY-MM-DD ") : null,
          joinDateEnd: this.form.joinDateEnd ? moment(this.form.joinDateEnd).format("YYYY-MM-DD ") : null,
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          companyId: this.$store.state.hr.companyId
        };
      }
      // if (this.status === 2) {
      getAllSignEmp(params, this.form.signingStatus ? this.form.signingStatus : "2").then(function (res) {
        _this5.spinning = false;
        if (res.data.entities === null) {
          _this5.pagination.total = 0;
          _this5.dataList = [];
        } else {
          _this5.pagination.total = res.data && res.data.entityCount;
          _this5.dataList = res.data.entities;
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      // } else if (this.status === 1) {
      // getAllEmp2({ ...params, monthly: this.monthly, yearly: this.yearly })
      //   .then(res => {
      //     if (res.data.entities === null) {
      //       this.total = 0;
      //     } else {
      //       this.pagination.total = res.data && res.data.entityCount;
      //       this.dataList = res.data.entities;
      //     }
      //   })
      //   .catch(err => {
      //     console.log("debug log --> ", err);
      //   });
      // }
    },
    getSeniorListCheck: function getSeniorListCheck() {
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.checkStatue = 1;
      this.getSeniorList();
    },
    seniorCancel: function seniorCancel() {
      this.seniorVisible = false;
      this.selectedRowKeys = [];
    },
    showSenior: function showSenior() {
      var _this6 = this;
      this.form = {};
      this.selectedRowKeys = [];
      this.checkStatue = 0;
      this.$emit("update:show", false);
      this.seniorVisible = true;
      this.clearForm();
      this.getSeniorList();
      this.getListByCompanyId();
      // this.update();
      if (this.selectedListTemp.length > 0) {
        this.seniorList = JSON.parse(JSON.stringify(this.selectedListTemp));
        this.selectedListTemp.forEach(function (item, index) {
          if (item.id === null) {
            item.id = item.empId;
          }
          _this6.selectedRowKeys.push(item.id);
        });
      } else {
        this.selectedRowKeys = [];
        this.seniorList = [];
      }
    },
    seniorOk: function seniorOk() {
      if (this.seniorList.length === 0) {
        this.$message.success("请添加选择人员");
        return;
      }
      this.$emit("update:selectedList", this.seniorList);
      this.$emit("update:selectThree", this.seniorList.slice(0, 3).map(function (item) {
        return item.name;
      }).join(","));
      this.seniorVisible = false;
      this.$emit("change", this.seniorList);
    },
    ok: function ok() {
      if (this.selectedListTemp.length === 0) {
        this.$message.success("请选择人员");
        return;
      }
      if (this.selectedListTemp.length > 100) {
        this.$message.success("一次性最多选择100人");
        return;
      }
      this.$emit("update:selectedList", this.selectedListTemp);
      this.$emit("update:selectThree", this.selectedListTemp.slice(0, 3).map(function (item) {
        return item.name;
      }).join(","));
      this.$emit("update:show", false);
      this.$emit("change", this.selectedListTemp);
    },
    reset: function reset() {
      this.textContent = "";
      this.errorState = false;
    },
    getEmpListByIdCards: function getEmpListByIdCards() {
      var _this7 = this;
      if (this.textContent !== "") {
        var arr = [];
        arr = this.textContent.split(/[\W]/);
        empContractByIdCards(this.$store.state.hr.companyId, arr).then(function (res) {
          if (_this7.selectedListTemp.length > 0) {
            _this7.chosenList = res.data.empInfoContractVOList.concat(_this7.selectedListTemp);
            _this7.chosenList.forEach(function (item) {
              if (item.id === null) {
                item.id = item.empId;
              }
            });
            _this7.chosenList = _this7.unique2(_this7.chosenList);
            _this7.selectedListTemp = JSON.parse(JSON.stringify(_this7.chosenList));
          } else {
            _this7.chosenList = res.data.empInfoContractVOList;
            _this7.chosenList.forEach(function (item) {
              if (item.id === null) {
                item.id = item.empId;
              }
            });
          }
          _this7.textContent = res.data.idCardList.join(",");
          if (res.data.idCardList.length > 0) {
            _this7.errorState = true;
          }
        });
      }
    },
    unique: function unique(arr) {
      var res = new Map();
      return arr.filter(function (arr) {
        return !res.has(arr.empId) && res.set(arr.empId, 1);
      });
    },
    unique2: function unique2(arr) {
      var res = new Map();
      return arr.filter(function (arr) {
        return !res.has(arr.id) && res.set(arr.id, 1);
      });
    },
    showCopy: function showCopy() {
      this.$emit("update:show", false);
      this.copyVisible = true;
      if (this.selectedListTemp.length > 0) {
        this.chosenList = JSON.parse(JSON.stringify(this.selectedListTemp));
      } else {
        this.chosenList = [];
      }
    },
    copyOk: function copyOk() {
      if (this.chosenList.length === 0) {
        this.$message.success("请添加选择人员");
        return;
      }
      this.$emit("update:selectedList", this.chosenList);
      this.$emit("update:selectThree", this.chosenList.slice(0, 3).map(function (item) {
        return item.name;
      }).join(","));
      this.copyVisible = false;
      this.$emit("change", this.chosenList);
    },
    // 分页变化
    onPageChange: function onPageChange(pageNumber) {
      this.current = pageNumber;
      this.getSelectDepTree();
    },
    postTypeClick: function postTypeClick(value) {
      this.postTypeValue = value;
      this.isAllSelected = false;
      this.current = 1;
      this.getSelectDepTree();
    },
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = 1;
      this.getSelectDepTree();
    },
    choseAll: function choseAll(data) {
      this.isAllSelected = !this.isAllSelected;
      var that = this;
      this.spinning = true;
      for (var j = 0, len = data.length; j < len; j++) {
        data[j].isSelected = that.isAllSelected;
        that.ergodicAll(data[j]);
      }
      setTimeout(function () {
        that.spinning = false;
      }, "10");
    },
    chose: function chose(item, data) {
      item.isSelected = !item.isSelected;
      this.onChange(item);
    },
    onChangeTab: function onChangeTab(e) {
      this.isAllSelected = false;
      this.current = 1;
      this.mode = e;
      if (e === 0) {
        this.signingStatus = "2";
      } else if (e === 1) {
        this.signingStatus = "0";
      } else {
        this.signingStatus = "1";
      }
      this.getSelectDepTree();
    },
    onChange: function onChange(item) {
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.ergodic(item);
        that.spinning = false;
      }, "10");
    },
    ergodic: function ergodic(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
          // this.isAllSelected = false;
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
        }
      }
      var count = this.treeData.length;
      var allSelect = true;
      for (var i = 0; i < count; i++) {
        if (!this.treeData[i].isSelected) {
          allSelect = false;
          break;
        }
      }
      this.isAllSelected = allSelect;
    },
    ergodicAll: function ergodicAll(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
        }
      }
    },
    // 获取部门树
    getSelectDepTree: function getSelectDepTree(query) {
      var _this8 = this;
      if (query) {
        this.current = 1;
      }
      var params = {
        // onlyCurrentDep: true,
        // onlyUser: true,
        pageSize: this.pageSize,
        pageNo: this.current,
        companyId: this.$store.state.hr.companyId
      };
      if (this.nameOrPhone) {
        params["nameOrPhone"] = this.nameOrPhone;
      }
      this.spinning = true;
      getAllSignEmp(params, this.signingStatus ? this.signingStatus : "2").then(function (res) {
        _this8.spinning = false;
        if (res.data.entities === null) {
          _this8.$message.success("暂无数据");
          _this8.treeData = [];
          _this8.total = 0;
          _this8.isAllSelected = false;
        } else {
          res.data.entities.forEach(function (item, index) {
            item.id = item.empId;
          });
          _this8.treeData = _this8.formatCompanyData(res.data.entities);
          _this8.total = res.data.entityCount;
          var count = res.data.entities.length;
          // this.pageSize = res.data.pageSize;
          // this.current = res.data.pageNo;
          var allSelect = true;
          _this8.spinning = false;
          if (count !== 0) {
            for (var i = 0; i < count; i++) {
              if (!_this8.treeData[i].isSelected) {
                allSelect = false;
                break;
              }
            }
            _this8.isAllSelected = allSelect;
          } else {
            _this8.isAllSelected = false;
          }
        }
      }).catch(function (err) {
        _this8.spinning = false;
        console.log("debug log --> ", err);
      });
    },
    formatCompanyData: function formatCompanyData(company) {
      var parent = [];
      for (var key = 0; key < company.length; key++) {
        var selected = false;
        this.selectedListTemp.forEach(function (item, index) {
          if (item.id === null) {
            item.id = item.empId;
          }
          if (item.id === company[key].id) {
            selected = true;
          }
        });
        var emp = {};
        emp = {
          id: company[key].id,
          name: company[key].name,
          phone: company[key].phone,
          idCard: company[key].idCard,
          depName: company[key].depName,
          positionName: company[key].positionName,
          isSelected: selected,
          contractExpirationDate: company[key].contractExpirationDate ? moment(company[key].contractExpirationDate).format("YYYY-MM-DD") : "--",
          // 到期时间
          contractSignDate: company[key].contractSignDate ? moment(company[key].contractSignDate).format("YYYY-MM-DD") : "--",
          // 签订时间
          contractExpirationDay: company[key].contractExpirationDay // 剩余
        };

        this.selectedListTemp.forEach(function (item, index) {
          item.isSelected = true;
        });
        parent.push(emp);
      }
      this.importDataLength = 0; // 清空传入的默认值
      return parent;
    },
    searchPeople: function searchPeople(query) {
      this.nameOrPhone = query;
      this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
        return item.id;
      });
      this.getSelectDepTree(query);
    },
    changePeople: function changePeople(e) {
      if (e.target.value === "") {
        this.nameOrPhone = "";
        this.getSelectDepTree();
        this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
          return item.id;
        });
      }
    },
    deleteItem: function deleteItem(item, index) {
      var _this9 = this;
      // this.treeData[index].isSelected = false;
      item.isSelected = false;
      this.treeData.forEach(function (item2, index2) {
        if (item2.id === item.id) {
          _this9.treeData[index2].isSelected = false;
        }
      });
      this.treeData = JSON.parse(JSON.stringify(this.treeData));
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.$nextTick(function () {
          that.ergodic(item);
        });
        that.spinning = false;
      }, 100);
    },
    cancel: function cancel() {
      this.$emit("update:show", false);
    }
  }
};