import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import moment from "moment";
import { afterTime } from "@/utils/index";
import { mapState, mapGetters } from "vuex";
export default {
  data: function data() {
    return {
      companyName: this.$store.state.hr.companyName,
      companyId: this.$store.state.hr.companyId,
      postTypeShow: true,
      options: [],
      probationDays: null,
      selectedRows: [],
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this, {
        name: "coordinated"
      }),
      formalTypeOptions: [{
        value: 1,
        label: "试用"
      }, {
        value: 2,
        label: "正式"
      }]
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(["isJF"])), mapState(["hr"])), {}, {
    companyList: function companyList() {
      return this.hr.companyList;
    },
    postTypeOptions: function postTypeOptions() {
      return this.$store.state.enums.postTypeLst;
    }
  }),
  methods: {
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    probationDaysChange: function probationDaysChange(value) {
      var _this = this;
      // 试用天数
      if (this.form.getFieldValue("JoinDate")) {
        var newTime = afterTime(this.form.getFieldValue("JoinDate"), value);
        setTimeout(function () {
          _this.form.setFieldsValue({
            turnStraightDate: _this.moment(newTime, "YYYY-MM-DD")
          });
        }, 300);
      }
    },
    tabData: function tabData(dateString1, dateString2) {
      var startDate = Date.parse(dateString1);
      var endDate = Date.parse(dateString2);
      if (startDate > endDate) {
        return 0;
      }
      if (startDate === endDate) {
        return 0;
      }
      var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
      return days;
    },
    onChangeDatepreQuitDate: function onChangeDatepreQuitDate(date, dateString) {
      var _this2 = this;
      var JoinDate = this.form.getFieldValue("JoinDate");
      if (JoinDate) {
        var data = this.tabData(JoinDate, moment(dateString).format("YYYY-MM-DD"));
        if (data !== 0) {
          this.$message.error("新用工单位入职日期应该大于等于原用工单位离职日期");
          setTimeout(function () {
            _this2.form.setFieldsValue({
              preQuitDate: null
            });
          });
          return;
        }
      }
    },
    onChangeDate: function onChangeDate(date, dateString) {
      var _this3 = this;
      // console.log(date, dateString);
      // 入职日期
      var preQuitDate = this.form.getFieldValue("preQuitDate");
      if (preQuitDate) {
        var data = this.tabData(dateString, moment(preQuitDate).format("YYYY-MM-DD"));
        if (data !== 0) {
          this.$message.error("新用工单位入职日期应该大于等于原用工单位离职日期");
          setTimeout(function () {
            _this3.form.setFieldsValue({
              JoinDate: null
            });
          });
          return;
        }
      }
      var probationDay = this.form.getFieldValue("probationDays");
      if (probationDay && dateString) {
        var newTime = afterTime(dateString, probationDay);
        setTimeout(function () {
          _this3.form.setFieldsValue({
            turnStraightDate: _this3.moment(newTime, "YYYY-MM-DD")
          });
        }, 300);
      }
    },
    postTypeChange: function postTypeChange(val) {
      console.log(val);
      if (val === 2 || val === 3) {
        this.postTypeShow = false;
      } else {
        this.postTypeShow = true;
      }
      console.log(" this.postTypeShow", this.postTypeShow);
    },
    handleChange: function handleChange(value) {
      var _this4 = this;
      // 员工状态
      if (value === 1) {
        this.probationDays = true;
      } else {
        this.probationDays = false;
        if (this.form.getFieldValue("JoinDate")) {
          setTimeout(function () {
            _this4.form.setFieldsValue({
              turnStraightDate: _this4.form.getFieldValue("JoinDate")
            });
          }, 300);
        }
      }
    },
    showModal: function showModal(e) {
      // console.log("companyName", e);
      this.selectedRows = e;
      this.visible = true;
      this.getCompanyId();
    },
    getCompanyId: function getCompanyId() {
      var _this5 = this;
      this.options = JSON.parse(JSON.stringify(this.companyList));
      this.options.forEach(function (element, index) {
        if (element.id === _this5.$store.state.hr.companyId) {
          _this5.options.splice(index, 1);
        }
      });
    },
    handleOk: function handleOk(e) {
      var _this6 = this;
      this.form.validateFields(function (err, values) {
        if (!err) {
          console.log("Received values of form: ", values);
          values.JoinDate = values.JoinDate.format("YYYY-MM-DD");
          // values.preQuitDate = values.preQuitDate.format("YYYY-MM-DD");
          values.turnStraightDate = values.turnStraightDate ? values.turnStraightDate.format("YYYY-MM-DD") : null;
          if (values.postType === 2 || values.postType === 3) {
            values.formalType = 2;
            values.turnStraightDate = moment().format("YYYY-MM-DD");
          }
          var data = {
            preQuitDate: values.preQuitDate.format("YYYY-MM-DD"),
            companyId: _this6.$store.state.hr.companyId,
            currentCompanyId: values.companyId,
            currentFormalType: values.formalType,
            currentJoinDate: values.JoinDate,
            currentPostType: values.postType,
            currentPreJoinDate: values.turnStraightDate,
            currentProbationDays: values.probationDays,
            currentStatus: _this6.selectedRows[0].status === 1 ? 1 : 2,
            currentTurnStraightDate: values.turnStraightDate,
            empInformationList: _this6.selectedRows.map(function (z) {
              return {
                empId: z.id,
                name: z.name
              };
            })
          };
          // console.log(data);
          _this6.confirmLoading = true;
          _this6.$request({
            method: "put",
            url: "/hr/api/v1/empInfos/empBatchChangeCompanyId",
            data: data
          }).then(function (res) {
            _this6.visible = false;
            _this6.confirmLoading = false;
            _this6.$message.success(res.msg);
            _this6.$emit("saveOK");
          }).catch(function (err) {
            _this6.confirmLoading = false;
            console.log("debug log --> ", err);
          });
        }
      });
    },
    handleCancel: function handleCancel(e) {
      this.visible = false;
    }
  }
};