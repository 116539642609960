import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import { getAllUser } from "../api";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    defaultSelectedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectedList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      signingStatus: null,
      tabsList: ["全部"],
      spinning: false,
      importDataLength: 0,
      mode: "1",
      treeData: [],
      selectedListTemp: [],
      isAllSelected: false,
      selectedKeysBeforeSearch: [],
      pageSizeOptions: ["10", "20", "50", "100"],
      pageSize: 1000,
      current: 1,
      total: 50
    };
  },
  watch: {
    visible: function visible(val) {
      if (val) {
        // setTimeout(() => {
        //   this.selectedListTemp = [];
        // });

        // this.selectedListTemp = this.selectedList;
        this.treeData = [];
        this.current = 1;
        // this.postTypeValue = 3;
        // this.mode = "1";
        // this.importDataLength = this.selectedList.length;
        // this.onPageChange(1);
        this.spinning = false;
        this.getSelectDepTree();
      }
    }
  },
  created: function created() {
    this.treeData = [];
    this.current = 1;
    this.spinning = false;
    this.getSelectDepTree();
  },
  methods: {
    // 分页变化
    onPageChange: function onPageChange(pageNumber) {
      this.current = pageNumber;
      this.getSelectDepTree();
    },
    // postTypeClick(value) {
    //   this.postTypeValue = value;
    //   this.isAllSelected = false;
    //   this.current = 1;
    //   this.getSelectDepTree();
    // },
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = 1;
      this.getSelectDepTree();
    },
    choseAll: function choseAll(data) {
      this.isAllSelected = !this.isAllSelected;
      var that = this;
      this.spinning = true;
      for (var j = 0, len = data.length; j < len; j++) {
        data[j].isSelected = that.isAllSelected;
        that.ergodicAll(data[j]);
      }
      setTimeout(function () {
        that.spinning = false;
      }, "10");
    },
    chose: function chose(item, data) {
      if (this.selectedListTemp.length < 1) {
        item.isSelected = !item.isSelected;
        this.onChange(item);
      } else {
        if (item.id === this.selectedListTemp[0].id) {
          item.isSelected = !item.isSelected;
          this.onChange(item);
        } else {
          this.$message.warning("只能选择一名成员新增权限");
        }
      }
    },
    // onChangeTab(e) {
    //   this.isAllSelected = false;
    //   this.current = 1;
    //   this.mode = e;
    //   if (e === 0) {
    //     this.signingStatus = "2";
    //   } else if (e === 1) {
    //     this.signingStatus = "0";
    //   } else {
    //     this.signingStatus = "1";
    //   }
    //   this.getSelectDepTree();
    // },
    onChange: function onChange(item) {
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.ergodic(item);
        that.spinning = false;
      }, "10");
    },
    ergodic: function ergodic(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
          this.isAllSelected = false;
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
          var count = this.treeData.length;
          var allSelect = true;
          for (var i = 0; i < count; i++) {
            if (!this.treeData[i].isSelected) {
              allSelect = false;
              break;
            }
          }
          this.isAllSelected = allSelect;
        }
      }
    },
    ergodicAll: function ergodicAll(item) {
      if (!item.isSelected) {
        var index = this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }); // 存在再删除，防止删除错误
        if (index !== -1) {
          this.selectedListTemp.splice(index, 1); // 删除
        }
      } else {
        if (this.selectedListTemp.findIndex(function (it) {
          return it.id === item.id;
        }) === -1) {
          // 不存在再添加，防止重复添加
          this.selectedListTemp.push(item);
        }
      }
    },
    // 获取树
    getSelectDepTree: function getSelectDepTree(query) {
      var _this = this;
      // if (query) {
      //   this.current = 1;
      // }
      var params = {
        status: 1
        // pageNo: this.current,
        // "orders[0].property": "gmtCreate",
        // "orders[0].desc": true
      };

      if (query) {
        params["name"] = query;
      }
      // if (this.type === "isEmp") {
      //   params["isEmp"] = 1;
      // }
      // if (this.type === "isBroker") {
      //   params["isBroker"] = 1;
      // }
      this.spinning = true;
      getAllUser(params).then(function (res) {
        _this.spinning = false;
        if (res.data.entities === null) {
          _this.$message.success("暂无数据");
          _this.treeData = [];
          _this.total = 0;
          _this.isAllSelected = false;
        } else {
          _this.treeData = _this.formatCompanyData(res.data);
          // this.total = res.data.entityCount;
          var count = res.data.length;
          // this.pageSize = res.data.pageSize;
          // this.current = res.data.pageNo;
          var allSelect = true;
          _this.spinning = false;
          if (count !== 0) {
            _this.selectedListTemp.forEach(function (e) {
              _this.treeData.forEach(function (m) {
                if (e.id === m.id) {
                  m.isSelected = true;
                }
              });
            });
            for (var i = 0; i < count; i++) {
              if (!_this.treeData[i].isSelected) {
                allSelect = false;
                break;
              }
            }
            _this.isAllSelected = allSelect;
          } else {
            _this.isAllSelected = false;
          }
        }
      }).catch(function (err) {
        _this.spinning = false;
        console.log("error err --> ", err);
      });
    },
    formatCompanyData: function formatCompanyData(company) {
      var parent = [];
      for (var key = 0; key < company.length; key++) {
        var selected = false;
        this.selectedListTemp.forEach(function (item, index) {
          if (item.id === company[key].id) {
            selected = true;
          }
        });
        var emp = {};
        emp = {
          id: company[key].id || "",
          name: company[key].name || "",
          phone: company[key].phone || ""
        };
        this.selectedListTemp.forEach(function (item, index) {
          item.isSelected = true;
        });
        parent.push(emp);
      }
      this.importDataLength = 0; // 清空传入的默认值
      return parent;
    },
    searchPeople: function searchPeople(query) {
      this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
        return item.id;
      });
      this.getSelectDepTree(query);
    },
    changePeople: function changePeople(e) {
      if (e.target.value === "") {
        this.getSelectDepTree();
        this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
          return item.id;
        });
      }
    },
    deleteItem: function deleteItem(item, index) {
      var _this2 = this;
      // this.treeData[index].isSelected = false;
      item.isSelected = false;
      this.treeData.forEach(function (item2, index2) {
        if (item2.id === item.id) {
          _this2.treeData[index2].isSelected = false;
        }
      });
      this.treeData = JSON.parse(JSON.stringify(this.treeData));
      this.spinning = true;
      var that = this;
      setTimeout(function () {
        that.$nextTick(function () {
          that.ergodic(item);
        });
        that.spinning = false;
      }, 100);
    },
    ok: function ok() {
      if (this.selectedListTemp.length === 0) {
        this.$message.warning("请选择人员");
        return;
      }
      if (this.selectedListTemp.length > 1) {
        this.$message.warning("只能选择一名成员新增权限");
        return;
      }
      var arr = this.selectedListTemp;
      this.$emit("upDate", 1, arr[0]);
      // this.$emit("change", this.selectedListTemp);
    },
    upDate: function upDate() {
      this.visibleT = false;
      this.$emit("update:visible", false);
      this.$emit("upDate");
    },
    setCancel: function setCancel() {
      this.$emit("update:visible", false);
    }
  }
};