import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import { getnotDetailsList, synchronous, account } from "@/api/wages/monthly";
export default {
  data: function data() {
    return {
      empList: [],
      synchronousState: false,
      empSynchronous: [],
      salaryTemplateId: null,
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      personalPeriod: null,
      monthly: null,
      yearly: null,
      monthDateCount: null
    };
  },
  created: function created() {
    this.salaryTemplateId = this.$route.query.salaryTemplateId;
    this.personalPeriod = this.$route.query.personalPeriod;
    this.templateId = this.$route.query.templateId;
    this.monthly = this.$route.query.monthly;
    this.yearly = this.$route.query.yearly;
    this.getCountDays();
  },
  beforeDestory: function beforeDestory() {},
  methods: {
    accountWage: function accountWage() {
      var _this = this;
      account({
        companyId: this.companyId,
        salaryTemplateSalaryId: parseInt(this.salaryTemplateId),
        empSalaryList: this.empList,
        monthly: parseInt(this.monthly),
        yearly: parseInt(this.yearly)
      }).then(function (res) {
        _this.$notification["success"]({
          message: "成功",
          description: "计算成功"
        });
      });
    },
    getCountDays: function getCountDays() {
      var _this2 = this;
      var year = parseInt(this.monthly);
      var month = parseInt(this.yearly);
      var date = new Date(year, month, 0);
      this.monthDateCount = date.getDate();
      getnotDetailsList({
        companyId: this.companyId,
        salaryTemplateIds: this.templateId,
        monthly: parseInt(this.monthly),
        yearly: parseInt(this.yearly)
      }).then(function (res) {
        // this.empList = res.data.allPayrollPersonnelIds;
        res.data.allPayrollPersonnelIds.forEach(function (item) {
          _this2.empList.push({
            empId: item
          });
        });
        _this2.empSynchronous = res.data;
        _this2.synchronousState = true;
      });
    },
    synchronousDetail: function synchronousDetail() {
      var _this3 = this;
      synchronous({
        salaryTemplateSalaryId: this.salaryTemplateId,
        month: this.monthly,
        year: this.yearly
      }).then(function (res) {
        _this3.$notification["success"]({
          message: "成功",
          description: "同步成功"
        });
      });
    },
    synchronous: function synchronous() {
      var _this4 = this;
      getnotDetailsList({
        companyId: this.companyId,
        salaryTemplateIds: this.templateId,
        monthly: parseInt(this.monthly),
        yearly: parseInt(this.yearly)
      }).then(function (res) {
        // this.empList = res.data.allPayrollPersonnelIds;
        _this4.$notification["success"]({
          message: "成功",
          description: "同步成功"
        });
        res.data.allPayrollPersonnelIds.forEach(function (item) {
          _this4.empList.push({
            empId: item
          });
        });
        _this4.empSynchronous = res.data;
        _this4.synchronousState = true;
      });
    },
    showSpecial: function showSpecial() {
      this.$router.push({
        path: "/wages/monthly/special",
        query: {
          month: this.monthly,
          year: this.yearly
        }
      });
    },
    goTax: function goTax() {
      this.$router.push({
        path: "/wages/monthly/incomeTax",
        query: {
          monthly: this.monthly,
          yearly: this.yearly,
          salaryTemplateId: this.salaryTemplateId
        }
      });
    },
    goCalculation: function goCalculation() {
      this.$router.push({
        path: "/wages/monthly/calculation"
      });
    },
    goEdit: function goEdit() {
      this.$router.push({
        path: "/wages/monthly/editDetails"
      });
    }
  }
};