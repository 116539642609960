import request from "@/utils/request";

// 新增社保方案
export function getShebaoMethod(data) {
  return request({
    url: "/hr/api/v1/socProgs/",
    method: "post",
    data: data
  });
}

// 修改方案列表
export function changeShebaoMethod(data) {
  return request({
    url: "/hr/api/v1/socProgs",
    method: "put",
    data: data
  });
}
// 补差
export function changeShebaoMethodBucha(data) {
  return request({
    url: "/hr/api/v1/empSocTransform/fillDifference",
    method: "post",
    data: data
  });
}
// 删除方案
export function deleteSocProgs(data) {
  return request({
    url: "/hr/api/v1/socProgs/" + data.id,
    method: "delete",
    data: data
  });
}

// 查询参保方案详情
export function findSocProgRelation(data) {
  return request({
    url: "/hr/api/v1/socProgs/findSocProgRelation",
    method: "get",
    params: data
  });
}
// 尾数处理，列表请求
export function toWeiShuquire() {
  return request({
    url: "/hr/api/v1/empSocTransform/selectAllMantissaProcessingType",
    method: "get"
  });
}
// 分页查询参保方案相关
export function pageRelation(params) {
  return request({
    url: "/hr/api/v1/socProgs/pageRelation",
    method: "get",
    params: params
  });
}
export function socProgListAll(data) {
  return request({
    url: "/hr/api/v1/socProgs/listAll",
    method: "GET",
    params: data
  });
}

// 查询参保方案使用人数
export function getPCount(params) {
  return request({
    url: "/hr/api/v1/empSocTransform/selectEmpCountWithSocProgId",
    method: "get",
    params: params
  });
}