export var columns = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: 160,
  fixed: "left",
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "身份证",
  dataIndex: "idCard",
  key: "idCard",
  width: 180
}, {
  title: "累计子女教育",
  dataIndex: "sumChildEducation",
  key: "sumChildEducation",
  width: 150
}, {
  title: "累计住房基金",
  dataIndex: "sumHousingRent",
  key: "sumHousingRent",
  width: 150
}, {
  title: "累计住房贷款利息",
  dataIndex: "sumHomeLoanInterest",
  key: "sumHomeLoanInterest",
  width: 150
}, {
  title: "累计赡养老人",
  dataIndex: "sumSupportOldMan",
  key: "sumSupportOldMan",
  width: 150
}, {
  title: "累计继续教育",
  dataIndex: "sumContinuingEducation",
  key: "sumContinuingEducation",
  width: 150
}, {
  title: "累计3岁以下婴幼儿照护",
  dataIndex: "sumChildCare",
  key: "sumChildCare",
  width: 220
}, {
  title: "累计个人养老金",
  dataIndex: "sumIndividualPensions",
  key: "sumIndividualPensions",
  width: 220
}, {
  title: "单月子女教育",
  dataIndex: "monthlyChildEducation",
  key: "monthlyChildEducation",
  width: 150
}, {
  title: "单月住房基金",
  dataIndex: "monthlyHousingRent",
  key: "monthlyHousingRent",
  width: 150
}, {
  title: "单月住房贷款利息",
  dataIndex: "monthlyHomeLoanInterest",
  key: "monthlyHomeLoanInterest",
  width: 150
}, {
  title: "单月赡养老人",
  dataIndex: "monthlySupportOldMan",
  key: "monthlySupportOldMan",
  width: 150
}, {
  title: "单月继续教育",
  dataIndex: "monthlyContinuingEducation",
  key: "monthlyContinuingEducation",
  width: 150
}, {
  title: "单月3岁以下婴幼儿照护",
  dataIndex: "monthlyChildCare",
  key: "monthlyChildCare",
  width: 200
}, {
  title: "单月个人养老金",
  dataIndex: "individualPensions",
  key: "individualPensions",
  width: 200
}, {
  title: "操作",
  dataIndex: "action",
  key: "action",
  scopedSlots: {
    customRender: "action"
  },
  width: 100,
  fixed: "right"
}];