var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("div", {
    class: _vm.status === "sendResume" ? "" : _vm.status !== "look" ? "width1000" : "",
    staticStyle: {
      margin: "0 auto"
    }
  }, [_vm.isShow ? _c("ModuleShow", {
    ref: "basic",
    staticClass: "pt16",
    staticStyle: {
      margin: "0 auto"
    },
    attrs: {
      title: "员工基本信息",
      status: _vm.status,
      workYearsNoExperience: _vm.workYearsNoExperience,
      dataSource: _vm.basicData,
      editable: _vm.basicInfoFormJson.editable,
      dynamicData: {},
      formFields: _vm.basicInfoFormJson.fields,
      sortedKeys: _vm.basicInfoFormJson.sortedKeys,
      intentionJobAll: _vm.intentionJobAll,
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.basicInfoFormJson, "editable", $event);
      },
      itemChange: _vm.itemChangebasic,
      change: function change($event) {
        return _vm.dynamicChange($event, "basic", "basicInfoFormJson", null);
      },
      edit: function edit($event) {
        return _vm.editItem("basicInfoFormJson");
      },
      delete: function _delete($event) {
        return _vm.deleteItem("basicInfoFormJson");
      },
      cancel: function cancel($event) {
        return _vm.cancelItem("basicInfoFormJson");
      }
    }
  }) : _vm._e()], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };