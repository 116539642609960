import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white relative",
    staticStyle: {
      height: "calc(100vh - 112px)",
      "padding-bottom": "65px"
    }
  }, [_c("div", {
    staticClass: "ta-center fs16 fw500 p16 border-bottom relative"
  }, [_c("span", [_vm._v(_vm._s(_vm.id ? "编辑" : "新增") + "政策")]), _c("div", {
    staticClass: "absolute left-0 top-0 ml16 pointer flex-center",
    staticStyle: {
      color: "rgba(192, 192, 192, 1)",
      "line-height": "58px"
    },
    on: {
      click: function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c("a-icon", {
    staticClass: "fs24 mr8",
    attrs: {
      type: "left"
    }
  }), _c("span", [_vm._v("返回")])], 1)]), _c("div", {
    staticClass: "overflow-y-auto",
    style: {
      margin: "16px auto",
      width: "900px",
      height: _vm.isDetail ? "calc(100vh - 197px)" : "calc(100vh - 262px)"
    }
  }, [_c("div", {
    staticClass: "title-block"
  }, [_vm._v("政策信息")]), _c("div", {
    staticClass: "flex-center flex-wrap"
  }, [_vm.groupLst.length > 0 ? _c("div", {
    staticClass: "flex-center mt24",
    staticStyle: {
      width: "50%"
    }
  }, [_vm._m(0), _c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      disabled: _vm.isDetail,
      options: _vm.groupLst,
      placeholder: "请选择",
      "show-search": "",
      filterOption: _vm.filterOption
    },
    model: {
      value: _vm.typeCode,
      callback: function callback($$v) {
        _vm.typeCode = $$v;
      },
      expression: "typeCode"
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "flex-center mt24",
    staticStyle: {
      width: "50%"
    }
  }, [_c("div", {
    staticClass: "ta-right pr8",
    staticStyle: {
      width: "160px"
    }
  }, [_vm._v("选择用工单位及分组：")]), _c("a-button", {
    attrs: {
      disabled: _vm.isDetail
    },
    on: {
      click: function click($event) {
        _vm.transferVisible = true;
      }
    }
  }, [_vm._v("已选择 " + _vm._s(this.targetKeys.length) + " 个")])], 1), _c("div", {
    staticClass: "flex-center mt24",
    staticStyle: {
      width: "50%"
    }
  }, [_vm._m(1), _c("a-input", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      disabled: _vm.isDetail,
      placeholder: "请输入"
    },
    model: {
      value: _vm.title,
      callback: function callback($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  })], 1), _c("div", {
    staticClass: "flex-center mt24",
    staticStyle: {
      width: "50%"
    }
  }, [_vm._m(2), _c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      disabled: _vm.isDetail,
      options: _vm.levelOptions,
      placeholder: "请选择政策级别"
    },
    model: {
      value: _vm.policyLevel,
      callback: function callback($$v) {
        _vm.policyLevel = $$v;
      },
      expression: "policyLevel"
    }
  })], 1), _c("div", {
    staticClass: "flex-center mt24",
    staticStyle: {
      width: "100%"
    }
  }, [_c("div", {
    staticClass: "ta-right pr8 pt4",
    staticStyle: {
      width: "160px",
      "align-self": "flex-start"
    }
  }, [_vm._v("附件上传：")]), _c("a-upload", {
    attrs: {
      fileList: _vm.annex,
      showUploadList: {
        showRemoveIcon: !_vm.isDetail
      },
      multiple: "",
      action: "/file/api/v1/general/upload/attachment",
      name: "files"
    },
    on: {
      change: _vm.onFileChange
    }
  }, [_c("a-button", {
    attrs: {
      disabled: _vm.isDetail
    }
  }, [_c("a-icon", {
    attrs: {
      type: "upload"
    }
  }), _vm._v("点击上传")], 1)], 1)], 1)]), _c("div", {
    staticClass: "title-block mt24"
  }, [_vm._v("政策内容")]), _c("div", {
    staticClass: "p16"
  }, [_vm.isDetail ? _c("div", {
    staticClass: "editor-preview",
    domProps: {
      innerHTML: _vm._s(_vm.contentHasStyle)
    }
  }) : _c("VueWangeditor", {
    staticClass: "wangeditor",
    staticStyle: {
      "z-index": "1",
      width: "100%"
    },
    attrs: {
      menus: ["head", "bold", "fontSize", "lineHeight", "fontName", "italic", "underline", "strikeThrough", "foreColor", "backColor", "justify", "image"]
    },
    on: {
      change: _vm.onEditorChange
    },
    model: {
      value: _vm.contentHasStyle,
      callback: function callback($$v) {
        _vm.contentHasStyle = $$v;
      },
      expression: "contentHasStyle"
    }
  })], 1)]), !_vm.isDetail ? _c("div", {
    staticClass: "p16 ta-right border-top absolute left-0 right-0 bottom-0"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.doSave
    }
  }, [_vm._v("确定")])], 1) : _vm._e(), _c("a-modal", {
    attrs: {
      title: "选择分管单位",
      width: "800px"
    },
    on: {
      ok: _vm.onTransferOk
    },
    model: {
      value: _vm.transferVisible,
      callback: function callback($$v) {
        _vm.transferVisible = $$v;
      },
      expression: "transferVisible"
    }
  }, [_c("a-transfer", {
    staticClass: "tree-transfer",
    attrs: {
      "list-style": {
        width: "320px",
        height: "400px"
      },
      operations: ["添加", "删除"],
      dataSource: _vm.transferDataSource,
      targetKeys: _vm.targetKeys,
      showSelectAll: false,
      showSearch: "",
      render: function render(item) {
        return item.title;
      }
    },
    on: {
      change: _vm.onTransChange,
      search: _vm.onTransferSearch
    },
    scopedSlots: _vm._u([{
      key: "children",
      fn: function fn(_ref) {
        var _ref$props = _ref.props,
          direction = _ref$props.direction,
          selectedKeys = _ref$props.selectedKeys,
          itemSelect = _ref.on.itemSelect;
        return [direction === "left" ? _c("a-tree", {
          staticStyle: {
            height: "300px",
            "overflow-y": "scroll"
          },
          attrs: {
            treeData: _vm.treeData,
            checkedKeys: [].concat(_toConsumableArray(selectedKeys), _toConsumableArray(_vm.targetKeys)),
            replaceFields: _vm.replaceFields,
            defaultExpandAll: "",
            blockNode: "",
            checkable: "",
            checkStrictly: ""
          },
          on: {
            check: function check(_, props) {
              _vm.onChecked(_, props, [].concat(_toConsumableArray(selectedKeys), _toConsumableArray(_vm.targetKeys)), itemSelect);
            },
            select: function select(_, props) {
              _vm.onChecked(_, props, [].concat(_toConsumableArray(selectedKeys), _toConsumableArray(_vm.targetKeys)), itemSelect);
            }
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ta-right pr8",
    staticStyle: {
      width: "160px"
    }
  }, [_c("span", {
    staticClass: "pr4",
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v("政策分类：")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ta-right pr8",
    staticStyle: {
      width: "160px"
    }
  }, [_c("span", {
    staticClass: "pr4",
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v("政策标题：")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ta-right pr8",
    staticStyle: {
      width: "160px"
    }
  }, [_c("span", {
    staticClass: "pr4",
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v("政策级别：")]);
}];
render._withStripped = true;
export { render, staticRenderFns };