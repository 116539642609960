import signed from "./signed";
import temp from "./temp";
export default {
  components: {
    signed: signed,
    temp: temp
  },
  data: function data() {
    return {
      current: 0,
      componentTit: ["新增合同模板", "编辑合同模板"]
    };
  },
  watch: {
    current: function current(v) {
      sessionStorage.setItem("editSealNewStatus", v);
    }
  },
  mounted: function mounted() {
    var editSealNewStatus = sessionStorage.getItem("editSealNewStatus");
    this.current = editSealNewStatus ? parseInt(editSealNewStatus) : 0;
  },
  methods: {
    goback: function goback() {
      var _this = this;
      this.$confirm({
        title: "合同设置尚未保存，是否确认离开？",
        okText: "确定",
        cancelText: "取消",
        onOk: function onOk() {
          _this.$router.go(-1);
        }
      });
    },
    upData: function upData(e) {
      this.current = e;
    },
    stepsChange: function stepsChange(e) {
      if (e === 1) {
        this.$refs.signed.saveSign();
        return false;
      }
      this.current = e;
    }
  }
};