import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.push.js";
var columns = [{
  title: "员工名称",
  dataIndex: "empName",
  key: "empName",
  width: 120
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 200
}, {
  title: "工时",
  dataIndex: "workTime",
  key: "workTime",
  width: 120
}, {
  title: "工时抽成",
  dataIndex: "workTimeRake",
  key: "workTimeRake",
  width: 120
}, {
  title: "一次性费用",
  dataIndex: "oneTimeFee",
  key: "oneTimeFee",
  width: 120
}, {
  title: "特殊费用",
  dataIndex: "specialFee",
  key: "specialFee",
  width: 120
}, {
  title: "劳务费结算规则",
  dataIndex: "serviceFeeRule",
  key: "serviceFeeRule",
  width: 150
}, {
  title: "用工单位",
  dataIndex: "companyName",
  key: "companyName",
  width: 120
}, {
  title: "应付总费用",
  dataIndex: "totalFee",
  key: "totalFee",
  scopedSlots: {
    customRender: "totalFee"
  },
  width: 120
}];
import { getFeeSumDetail } from "@/api/outsourcing/outsourcing";
import { isContainByString } from "@/utils/index";
import axios from "axios";
import qs from "qs";
import moment from "moment";
export default {
  data: function data() {
    return {
      nameOrIdCard: null,
      routerData: this.$route.query.detail ? JSON.parse(this.$route.query.detail) : {},
      defaultTime: this.$route.query.defaultTime,
      dataList: [{
        name: "4444"
      }],
      columns: columns,
      size: "default",
      companyName: null,
      loading: false,
      selectedRowKeys: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null
      },
      isContainByString: isContainByString
    };
  },
  created: function created() {
    this.getFeeSumDetailList();
  },
  methods: {
    download: function download(i) {
      var _this = this;
      var token = this.$store.state.auth.token;
      var params = {};
      if (i === 2) {
        params = {
          channelFeeId: this.routerData.id
        };
      } else {
        params = {
          ids: this.selectedRowKeys,
          channelFeeId: this.routerData.id
        };
      }
      axios({
        url: "/salary/api/v1/channelFeeSettlementDetails/export",
        params: params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        if (res.data && res.data.type === "application/json") {
          var reader = new FileReader();
          reader.readAsText(res.data, "utf-8");
          reader.onload = function () {
            var resJson = JSON.parse(reader.result);
            _this.$message.error(resJson.msg);
          };
        } else {
          var blob = new Blob([res.data], {
            type: "application/octet-stream"
          });
          var link = document.createElement("a");
          try {
            link.download = "".concat(_this.routerData.channelName, "_") + "渠道劳务费详情" + ".xlsx";
          } catch (err) {
            console.log("debug log --> ", res);
          }
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 表格数据查询
    getFeeSumDetailList: function getFeeSumDetailList() {
      var _this2 = this;
      getFeeSumDetail({
        companyName: this.companyName ? this.companyName : null,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        nameOrIdCard: this.nameOrIdCard ? this.nameOrIdCard : null,
        channelFeeId: this.routerData.id
      }).then(function (res) {
        _this2.dataList = res.data.entities ? res.data.entities : [];
        _this2.pagination.total = res.data && res.data.entityCount;
      });
    },
    inputChange: function inputChange(e) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.nameOrIdCard = e;
      this.getFeeSumDetailList();
    },
    inputChange2: function inputChange2(e) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.companyName = e;
      this.getFeeSumDetailList();
    },
    goBack: function goBack() {
      this.$router.push({
        path: this.isContainByString("settlementAdmin") ? "/settlementAdmin/labor" : "/settlement/labor",
        query: {
          index: this.defaultTime
        }
      });
    },
    monthChange: function monthChange() {},
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getFeeSumDetailList();
      // this.getEmpSelectPage();
    },
    onSelectChange: function onSelectChange(selectedRowKeys, selectedRow) {
      this.selectedRowKeys = selectedRowKeys;
    }
  }
};