import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
export function formatGroupTreeForXS(list) {
  var disabledTag = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (!list) {
    return [];
  }
  var res = [];
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    var newItem = {
      title: item.name,
      value: item.id,
      key: item.id,
      level: item.level,
      count: item.memberCount,
      editable: item.havePermissions === true
    };
    if (disabledTag) {
      newItem["disabled"] = item.addPermissions !== true;
    }
    if (item.childList && item.childList.length > 0) {
      newItem["children"] = formatGroupTreeForXS(item.childList, disabledTag);
    }
    res.push(newItem);
  }
  return res;
}
export var empColumns = [{
  title: "所属分组",
  dataIndex: "groupName"
}, {
  title: "销售名称",
  dataIndex: "saleName"
}, {
  title: "联系电话",
  dataIndex: "salePhone",
  width: 120
}, {
  title: "所在地区",
  dataIndex: "region",
  customRender: function customRender(text) {
    if (text) {
      return text.split("_")[1];
    } else {
      return "";
    }
  }
}, {
  title: "身份证号",
  dataIndex: "saleIdCard"
}, {
  title: "银行卡号",
  dataIndex: "saleBankCard"
}, {
  title: "关联用工单位",
  dataIndex: "companyName",
  scopedSlots: {
    customRender: "uc"
  }
}, {
  title: "操作",
  dataIndex: "action",
  width: 100,
  scopedSlots: {
    customRender: "action"
  }
}];
export function formatRegionValueDe(val) {
  if (val) {
    var ids = val.split("_")[0].split(",").map(function (li) {
      return parseInt(li);
    });
    return ids;
  } else {
    return undefined;
  }
}
export function formatRegionValueEn(arr) {
  if (arr && arr.length > 0) {
    var ids = [];
    var labels = [];
    for (var i = 0; i < arr.length; i++) {
      ids.push(arr[i].id);
      labels.push(arr[i].label);
    }
    return "".concat(ids.join(","), "_").concat(labels.join("/"));
  } else {
    return undefined;
  }
}
export var bindColumns = [{
  title: "用工单位",
  dataIndex: "companyName",
  align: "center",
  width: "50%"
}, {
  title: "所属销售",
  dataIndex: "saleName",
  align: "center",
  width: "50%"
}];
export var feeColumns = [{
  title: "用工单位",
  dataIndex: "companyName",
  align: "center",
  width: "33.33%"
}, {
  title: "月份",
  dataIndex: "costMonth",
  align: "center",
  width: "33.33%"
}, {
  title: "客户服务费金额",
  dataIndex: "serverCost",
  align: "center",
  width: "33.33%"
}];
export var feeErrColumns = [{
  title: "错误信息",
  dataIndex: "errorInfo"
}, {
  title: "用工单位名称",
  dataIndex: "companyName",
  align: "center",
  width: 200
}, {
  title: "月份",
  dataIndex: "costMonth",
  align: "center",
  width: 100
}, {
  title: "服务费金额",
  dataIndex: "serverCost",
  align: "center",
  width: 150
}];
export var calcColumns = [{
  title: "销售姓名",
  dataIndex: "saleName"
}, {
  title: "用工单位",
  dataIndex: "companyName"
}, {
  title: "月份",
  dataIndex: "calculationMonth"
}, {
  title: "客服服务费金额",
  dataIndex: "serverCost"
}, {
  title: "提成规则名称",
  dataIndex: "rulesName"
}, {
  title: "本次适用规则",
  dataIndex: "dividedProportion",
  customRender: function customRender(text, record) {
    return "".concat(record.isNew ? "新增客户" : "续约客户", " ").concat(record.proportion, "% \u63D0\u6210\uFF0C\u5206\u6210\u6BD4\u4F8B ").concat(record.dividedProportion, "%");
  }
}, {
  title: "提成金额",
  dataIndex: "rebatesAmount"
}];
export var calcErrColumns = [{
  title: "用工单位",
  dataIndex: "companyName"
}, {
  title: "月份",
  dataIndex: "month",
  width: 100
}, {
  title: "异常原因",
  dataIndex: "errorInfo"
}];
export var tongjiColumns = [{
  title: "所属分组",
  dataIndex: "saleGroup"
}, {
  title: "销售名称",
  dataIndex: "saleName"
}, {
  title: "联系电话",
  dataIndex: "salePhone"
}, {
  title: "身份证号",
  dataIndex: "saleIdCard"
}, {
  title: "所在地区",
  dataIndex: "region",
  customRender: function customRender(text) {
    if (text) {
      return text.split("_")[1];
    }
    return "";
  }
}, {
  title: "所属用工单位",
  dataIndex: "companyName",
  scopedSlots: {
    customRender: "uc"
  }
}, {
  title: "销售提成",
  dataIndex: "rebatesAmount"
}];
export function genGroupOptions(source, color) {
  var keys = [];
  var values = [];
  for (var i = 0; i < source.length; i++) {
    keys.push({
      value: source[i].key,
      textStyle: {
        width: 36,
        overflow: "truncate"
      }
    });
    values.push(source[i].value);
  }
  return {
    grid: {
      left: 48,
      top: 24,
      bottom: 24,
      right: 0
    },
    xAxis: {
      type: "category",
      data: keys
    },
    yAxis: {},
    series: [{
      type: "bar",
      data: values,
      barWidth: 36,
      itemStyle: {
        color: color
      },
      label: {
        show: true,
        position: "top"
      }
    }]
  };
}
export function genMonthOptions(source, color) {
  var keys = [];
  var values = [];
  for (var i = 0; i < source.length; i++) {
    keys.push(source[i].key);
    values.push(source[i].value);
  }
  return {
    grid: {
      left: 48,
      top: 24,
      bottom: 24,
      right: 0
    },
    xAxis: {
      type: "category",
      data: keys
    },
    yAxis: {},
    series: [{
      type: "line",
      data: values,
      itemStyle: {
        color: color
      },
      label: {
        show: true,
        position: "top"
      }
    }]
  };
}