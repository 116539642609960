import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import { transferAccountsDetails, editAccountsDetails, batchAccountsDetails, batchAccountsDetails2 } from "../api";
import axios from "axios";
import qs from "qs";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
export default {
  data: function data() {
    return {
      mixin: mixin,
      columns: [{
        title: "姓名",
        dataIndex: "name",
        key: "name",
        colDefault: true,
        width: 150
      }, {
        title: "身份证号",
        dataIndex: "idCard",
        key: "idCard",
        colDefault: true,
        width: 180
      }, {
        title: "手机号码",
        dataIndex: "phone",
        key: "phone",
        colDefault: true,
        width: 120
      }, {
        title: "薪资",
        dataIndex: "money",
        key: "money",
        colDefault: true,
        scopedSlots: {
          customRender: "channelName"
        },
        width: 100
      }],
      columnsCopy: [{
        title: "姓名",
        dataIndex: "name",
        key: "name",
        colDefault: true,
        width: 150
      }, {
        title: "身份证号",
        dataIndex: "idCard",
        key: "idCard",
        colDefault: true,
        width: 180
      }, {
        title: "手机号码",
        dataIndex: "phone",
        key: "phone",
        colDefault: true,
        width: 120
      }, {
        title: "薪资",
        dataIndex: "money",
        key: "money",
        colDefault: true,
        scopedSlots: {
          customRender: "channelName"
        },
        width: 100
      }],
      dataList: [],
      visible: false,
      loading: false,
      abnormalItem: {},
      rowSelection: undefined,
      batchVisible: false,
      batchremark: undefined,
      batchisDispose: null,
      selectedRowKeys: [],
      selectedEmp: [],
      params: {},
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        editCompanyName: null,
        ticketRecord: null,
        receivableRecord: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      }
    };
  },
  props: {
    approvalRecord: {
      type: Object,
      default: function _default() {}
    },
    pageState: {
      type: Boolean,
      default: false
    },
    payStatus: {
      type: Number,
      default: 1
    }
  },
  watch: {
    approvalRecord: {
      handler: function handler(newVal) {
        this.getTransferAccountsDetails();
        this.updateDetail();
      },
      immediate: true
    }
  },
  created: function created() {
    this.updateDetail();
    // this.rowSelection =
    //   this.pageState && this.payStatus === 4
    //     ? {
    //         selectedRowKeys: this.selectedRowKeys,
    //         onChange: this.onSelectChange()
    //       }
    //     : undefined;
  },

  methods: {
    updateDetail: function updateDetail() {
      this.columns = JSON.parse(JSON.stringify(this.columnsCopy));
      if (this.approvalRecord && this.approvalRecord.type === 1) {
        this.columns.splice(3, 0, {
          title: "发放账号",
          dataIndex: "identity",
          key: "identity",
          colDefault: true,
          width: 150
        });
      }
      if (this.approvalRecord && this.approvalRecord.type === 3) {
        this.columns.splice(2, 1, {
          title: "银行卡号",
          dataIndex: "identity",
          key: "identity",
          colDefault: true,
          width: 150
        }, {
          title: "银行名称",
          dataIndex: "bankName",
          key: "bankName",
          colDefault: true,
          width: 150
        });
      }
      if (this.approvalRecord && this.approvalRecord.type === 3 && (this.payStatus === 2 || this.payStatus === 4 || this.payStatus === 6)) {
        this.columns.push({
          title: "手续费",
          dataIndex: "fee",
          key: "fee",
          colDefault: true,
          width: 120
        });
      }
      if (this.pageState && this.payStatus === 2) {
        this.columns.splice(this.columns.length - 2, 0, {
          title: "单笔交易流水号",
          dataIndex: "thirdPartyInteriorDetailId",
          key: "thirdPartyInteriorDetailId",
          colDefault: true,
          width: 300
        }, {
          title: "发放完成时间",
          dataIndex: "payTime",
          key: "payTime",
          colDefault: true,
          scopedSlots: {
            customRender: "payTime"
          },
          width: 120
        });
      }
      if (this.pageState && this.payStatus === 4 || this.pageState && this.payStatus === 6) {
        this.columns.splice(this.columns.length, 0, {
          title: "单笔交易流水号",
          dataIndex: "thirdPartyInteriorDetailId",
          key: "thirdPartyInteriorDetailId",
          colDefault: true,
          width: 300
        }, {
          title: "异常原因",
          dataIndex: "failCause",
          key: "failCause",
          colDefault: true,
          width: 120
        }, {
          title: "处理状态",
          dataIndex: "isDispose",
          key: "isDispose",
          colDefault: true,
          scopedSlots: {
            customRender: "isDispose"
          },
          width: 100
        }, {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
          colDefault: true,
          width: 120
        }, {
          title: "操作",
          dataIndex: "action",
          key: "action",
          colDefault: true,
          scopedSlots: {
            customRender: "action"
          },
          width: 100
        });
      }
    },
    importErrData: function importErrData() {
      this.params = {
        isDispose: false,
        batchId: this.approvalRecord.id
      };
      this.url = "/hr/api/v1/transferAccountsDetails/selectExportErrorList";
      this.excelName = "错误数据导出详情.xlsx";
      this.getDownload2();
    },
    getDownload2: function getDownload2() {
      var _this = this;
      var token = this.$store.state.auth.token;
      axios({
        url: this.url,
        params: this.params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", _this.excelName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleBatchOk: function handleBatchOk() {
      var _this2 = this;
      batchAccountsDetails2({
        ids: this.selectedRowKeys,
        isDispose: this.batchisDispose,
        remark: this.batchremark,
        batchId: this.approvalRecord.id,
        id: "1"
      }).then(function (res) {
        _this2.batchVisible = false;
        _this2.$notification.success({
          message: "处理成功"
        });
        _this2.selectedRowKeys = [];
        _this2.$emit("pageRefresh");
        _this2.getTransferAccountsDetails();
      });
    },
    batchToAbnormal: function batchToAbnormal() {
      this.batchVisible = true;
      this.batchremark = undefined;
      this.batchisDispose = null;
    },
    onSelectChange: function onSelectChange(selectedRowKeys, e) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedEmp = JSON.parse(JSON.stringify(e));
      console.log(this.selectedEmp);
    },
    handleOk: function handleOk() {
      var _this3 = this;
      var arr = [];
      arr.push(this.abnormalItem.id);
      batchAccountsDetails2({
        ids: arr,
        isDispose: this.abnormalItem.isDispose,
        remark: this.abnormalItem.remark,
        batchId: this.approvalRecord.id,
        id: "1"
      }).then(function (res) {
        _this3.visible = false;
        _this3.$notification.success({
          message: "处理成功"
        });
        _this3.$emit("pageRefresh");
        _this3.getTransferAccountsDetails();
      });
    },
    toAbnormal: function toAbnormal(record) {
      this.abnormalItem = JSON.parse(JSON.stringify(record));
      this.visible = true;
    },
    inputChange: function inputChange(e) {
      this.name = e;
      this.pagination.current = 1;
      this.getTransferAccountsDetails();
    },
    // 分页器
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getTransferAccountsDetails();
    },
    exportFile: function exportFile() {
      console.log(this.columns);
      this.params2 = {
        batchId: this.approvalRecord.id,
        name: this.name,
        exportColumns: this.columns.map(function (z) {
          return {
            title: z.title,
            dateFormat: z.title === "发放完成时间" ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd",
            dataIndex: z.dataIndex
          };
        })
      };
      this.url = "/hr/api/v1/transferAccountsDetails/export";
      this.excelName = "\u8F6C\u8D26\u6279\u6B21\u660E\u7EC6.xlsx";
      this.getDownload();
    },
    getDownload: function getDownload() {
      var _this4 = this;
      var token = this.$store.state.auth.token;
      axios({
        url: this.url,
        data: this.params2,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "post",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", _this4.excelName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    getTransferAccountsDetails: function getTransferAccountsDetails() {
      var _this5 = this;
      var data = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        batchId: this.approvalRecord.id,
        name: this.name
      };
      this.loading = true;
      transferAccountsDetails(data).then(function (res) {
        _this5.loading = false;
        _this5.dataList = res.data.entities;
        _this5.pagination.total = res.data && res.data.entityCount;
      }).catch(function (err) {
        _this5.loading = false;
        console.log(err);
      });
    }
  }
};