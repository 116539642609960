import FormJson from "./FormJson";
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
// import { retrieveReq } from "./api";

var curdMixin = new XqCurdMixin({
  //   retrieveReq,
  FormJson: FormJson,
  tableConfigKey: "table"
});
export default {
  mixins: [curdMixin],
  data: function data() {
    return {
      pageSize: 20,
      spinning: false,
      tableData: []
    };
  }
};