import request from "@/utils/request";

// 下发印章
export function saveIssuedSeal(data) {
  return request({
    url: "/contract/api/v1/seals/saveIssuedSeal",
    method: "POST",
    data: data
  });
}
// 下发印章
export function saveSeals(data) {
  return request({
    url: "/contract/api/v1/seals",
    method: "POST",
    data: data
  });
}
// 编辑下发印章
export function sealUpdateReq(data) {
  return request({
    url: "/contract/api/v1/seals",
    method: "put",
    data: data
  });
}