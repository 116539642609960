import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ph48"
  }, [_vm._m(0), _c("div", {
    staticClass: "mt16"
  }, [_c("div", {
    staticClass: "title-block mb24"
  }, [_vm._v("模板设置")]), _c("div", {
    staticClass: "mt16"
  }, [_vm._v("模板名称："), _c("a-input", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.nameTemp,
      callback: function callback($$v) {
        _vm.nameTemp = $$v;
      },
      expression: "nameTemp"
    }
  })], 1), _c("div", {
    staticClass: "mt16"
  }, [_vm._v(" 尾数处理："), _c("a-select", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      options: _vm.decimalPlacesOptions,
      placeholder: "请选择"
    },
    model: {
      value: _vm.decimalPlacesTemp,
      callback: function callback($$v) {
        _vm.decimalPlacesTemp = $$v;
      },
      expression: "decimalPlacesTemp"
    }
  })], 1)]), _vm._m(1), _c("div", {
    staticClass: "ta-right mt12"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "请输入工资模板项目"
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  })], 1), _c("div", {
    staticClass: "mt16"
  }, [_c("div", {
    staticClass: "flex-center border-bottom p12 fw500 bgc-back"
  }, [_c("div", {
    staticClass: "flex-1"
  }, [_vm._v("项目")]), _c("a-space", {
    staticClass: "flex-shrink-0",
    staticStyle: {
      width: "50px"
    },
    attrs: {
      size: 16
    }
  }, [_vm._v("操作")])], 1), _c("Draggable", {
    on: {
      end: _vm.onDragEnd
    },
    model: {
      value: _vm.sortedList,
      callback: function callback($$v) {
        _vm.sortedList = $$v;
      },
      expression: "sortedList"
    }
  }, _vm._l(_vm.sortedList, function (item) {
    return _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.name.indexOf(_vm.keyword) >= 0,
        expression: "item.name.indexOf(keyword) >= 0"
      }],
      key: item.name,
      staticClass: "ns-resize flex-center border-bottom p12"
    }, [_c("div", {
      staticClass: "flex-1"
    }, [_c("a-icon", {
      staticClass: "mr4",
      staticStyle: {
        color: "var(--primary)"
      },
      attrs: {
        type: "unordered-list"
      }
    }), _vm._v(" " + _vm._s(item.name) + " "), item.remarks ? _c("a-tooltip", {
      attrs: {
        title: item.remarks
      }
    }, [_c("a-icon", {
      staticClass: "ml4",
      attrs: {
        type: "question-circle",
        theme: "filled"
      }
    })], 1) : _vm._e()], 1), _c("a-space", {
      staticClass: "flex-shrink-0",
      staticStyle: {
        width: "50px"
      },
      attrs: {
        size: 16
      }
    }, [item.canClose ? _c("span", {
      staticClass: "tc-theme pointer fs12",
      on: {
        click: function click($event) {
          return _vm.removeItem(item);
        }
      }
    }, [_vm._v("移除")]) : _vm._e()])], 1);
  }), 0)], 1), _c("div", {
    staticClass: "mt16 ta-center pb24"
  }, [_c("a-space", {
    attrs: {
      size: 24
    }
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        return _vm.$emit("cancel");
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("prev");
      }
    }
  }, [_vm._v("上一步")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.doSave
    }
  }, [_vm._v("保存")])], 1)], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "border-bottom pb16"
  }, [_c("div", {
    staticClass: "fs16 fw500"
  }, [_vm._v("使用指南")]), _c("div", {
    staticClass: "mt8"
  }, [_vm._v("设置工资表模板，即对应企业计薪时的月工资表头项目。每位员工只适用一个模板。")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mt24"
  }, [_c("div", {
    staticClass: "title-block"
  }, [_vm._v("项目模板设置")]), _c("div", {
    staticClass: "tc-l3 mt12"
  }, [_vm._v("设置此工资表模板参与计薪的项目。您可以新增或移除工资表模板的项目")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };