import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.trim.js";
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  var valid_map = ["admin", "editor"];
  return valid_map.indexOf(str.trim()) >= 0;
}
export function isPhone(rule, value, callback) {
  var reg = /^1[3456789]\d{9}$/; // 手机号码验证regEx:第一位数字必须是1，11位数字
  if (reg.test(value)) {
    callback();
  } else {
    callback("请正确输入手机号！");
  }
}
export function validPhone(rule, value, callback) {
  var reg = /^1[3456789]\d{9}$/; // 手机号码验证regEx:第一位数字必须是1，11位数字
  if (reg.test(value)) {
    callback();
  } else {
    callback("请正确输入手机号！");
  }
}
export function isEmail(rule, value, callback) {
  var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/; // 邮箱
  if (reg.test(value)) {
    callback();
  } else {
    callback("请输入正确邮箱");
  }
}