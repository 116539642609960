import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import moment from "moment";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
import axios from "axios";
import qs from "qs";
var mixin = UploadMixin();
var columns = [{
  title: "行号",
  dataIndex: "id",
  key: "id"
}, {
  title: "姓名",
  dataIndex: "empName",
  key: "empName"
}, {
  title: "原因",
  dataIndex: "reason",
  key: "reason"
}];
export default {
  mixins: [mixin],
  data: function data() {
    return {
      action2: "",
      year: moment().format("YYYY"),
      month: moment().format("MM"),
      columns: columns,
      params: {
        templateName: "cumulativeDeductionOfSpecialAdditionalItems.xlsx"
      },
      url: "/salary/api/v1/excel/template/download",
      excelName: "专项附加扣除累计导入模板.xlsx",
      uploadImg: require("@/assets/img/uploadImg.png")
    };
  },
  computed: {
    companyId: function companyId() {
      return this.$store.state.hr.companyId;
    }
  },
  created: function created() {
    this.action = "/salary/api/v1/employeeSalaries/importSpecialAdditionalItems?companyId=".concat(this.companyId, "&force=false&month=").concat(this.month > 1 ? this.month - 1 : 12, "&year=").concat(this.month > 1 ? this.year : this.year - 1);
    this.action2 = "/salary/api/v1/employeeSalaries/importStandardSpecialAdditionalItems?companyId=".concat(this.companyId, "&force=false&month=").concat(this.month > 1 ? this.month - 1 : 12, "&year=").concat(this.month > 1 ? this.year : this.year - 1);
  },
  methods: {
    beforeUpload2: function beforeUpload2(file) {
      var _this = this;
      if (this.fileList.length > 0) {
        this.$message("最多只能上传一个");
        return false;
      }
      this.fileList = [].concat(_toConsumableArray(this.fileList), [file]);
      if (this.fileList && this.fileList.length > 0) {
        var fileList = this.fileList;
        var formData = new FormData();
        fileList.forEach(function (file) {
          formData.append("file", file);
        });
        this.uploading = true;
        axios({
          url: this.action2,
          method: "post",
          data: formData,
          headers: this.headers
        }).then(function (res) {
          _this.reset();
          _this.handleSuccess(res.data);
          _this.uploading = false;
        });
      } else {
        this.reset();
        this.handleUploadError();
      }
      return false;
    },
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    }
  }
};