import { getToken } from "xqjr-module-auth";
export default {
  fields: {
    companyId: {
      model: "companyId",
      type: "select",
      wrapper: {
        label: "选择用工单位"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        dynamic: true,
        dynamicKey: "companyIdList"
      }
    },
    companyName: {
      model: "companyName",
      type: "input",
      wrapper: {
        label: "用工单位"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {}
    },
    month: {
      model: "month",
      type: "date",
      wrapper: {
        label: "选择月份"
      },
      customize: {
        allowClear: true,
        format: "YYYY-MM",
        placeholder: "请选择"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        mode: "month"
      }
    },
    createUser: {
      model: "createUser",
      type: "input",
      wrapper: {
        label: "提交人"
      },
      customize: {
        placeholder: "请选择"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {}
    },
    createDate: {
      model: "createDate",
      type: "date",
      wrapper: {
        label: "提交时间"
      },
      customize: {
        placeholder: "请选择"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {}
    },
    eventTitle: {
      model: "eventTitle",
      type: "input",
      wrapper: {
        label: "服务反馈标题"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {}
    },
    eventLabel: {
      model: "eventLabel",
      type: "select_add",
      wrapper: {
        label: "服务反馈标签"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择或输入"
      },
      decorator: {
        initialValue: undefined,
        rules: []
      },
      extend: {
        dictCode: "1225",
        multiple: false
      }
    },
    eventContent: {
      model: "eventContent",
      type: "textarea",
      wrapper: {
        label: "服务反馈内容"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入"
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {}
    },
    eventAnnex: {
      model: "eventAnnex",
      type: "upload",
      wrapper: {
        label: "材料附件"
      },
      customize: {
        withCredentials: true,
        action: "/file/api/v1/site/default/upload/inline",
        accept: ".jpg, .jpeg, .png, .bmp, .gif, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx",
        multiple: true,
        headers: {
          token: getToken()
        }
      },
      decorator: {
        initialValue: undefined,
        rules: []
      },
      extend: {
        limit: 9
      }
    }
  },
  validators: {},
  // 自定义校验，如果需要自定义校验方法，定义后方法名放在这里面，没有必须是个空对象
  config: {
    // 所有的配置
    search: {
      // 搜索栏的配置，通过 FormJson['config']['search'] 的方式为搜索表单设置 formConfig
      fieldKeys: [
      // 搜索表单包含的字段
      "companyName", "eventTitle", "beginTime", "endTime"],
      customize: {
        // 搜索表单的配置
        layout: "inline",
        labelCol: {
          span: 8
        },
        wrapperCol: {
          span: 16
        }
      }
    },
    create: {
      // 搜索栏的配置，通过 FormJson['config']['create'] 的方式为创建表单设置 formConfig
      fieldKeys: [
      // 创建表单包含的字段
      "companyId", "month", "eventTitle", "eventLabel", "eventContent", "eventAnnex"],
      customize: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      }
    },
    detail: {
      // 搜索栏的配置，通过 FormJson['config']['update'] 的方式为更新表单设置 formConfig
      fieldKeys: [
      // 创建表单包含的字段
      "companyId", "month", "eventTitle", "eventLabel", "eventContent", "eventAnnex", "createUser", "createDate"],
      customize: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      }
    },
    update: {
      // 搜索栏的配置，通过 FormJson['config']['update'] 的方式为更新表单设置 formConfig
      fieldKeys: [
      // 创建表单包含的字段
      "companyId", "month", "eventTitle", "eventLabel", "eventContent", "eventAnnex"],
      customize: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      }
    },
    table: {
      // 表格的配置，可以自定义 key 值，通过 tableConfigKey 来修改，默认为 'table'
      fields: [
      // 表格中的字段
      {
        model: "companyName",
        title: "用工单位"
      }, {
        model: "eventTitle",
        title: "服务反馈标题"
      }, {
        model: "eventLabel",
        title: "服务反馈标签"
      }, {
        model: "eventContent",
        title: "服务反馈内容",
        width: 160
      }, {
        model: "month",
        title: "所属月份"
      }, {
        model: "createUser",
        title: "提交人"
      }, {
        model: "createDate",
        title: "提交时间"
      }, {
        model: "eventAnnex",
        title: "事件附件"
      }],
      customize: {
        // 表格的配置
        rowKey: "id"
      },
      extend: {
        // 表格的自定义配置，例如是否开启批量操作（batch）
        batch: false,
        action: {
          width: 160
        }
      }
    }
  }
};