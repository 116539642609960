var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper"
  }, [_c("Navbar"), _c("a-empty", {
    staticStyle: {
      "margin-top": "80px"
    },
    attrs: {
      description: "暂无权限"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };