import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.values.js";
var perToRouteMap = {
  "hr:workbench:workStation": {
    router: "/admin/enterprise",
    name: "工作台",
    belong: "workbench"
  },
  // 公司总览
  "hr:enterpriseOverview:emp": {
    router: "/overview/staff",
    name: "员工概览",
    belong: "overview"
  },
  "hr:enterpriseOverview:shebao": {
    router: "/overview/shebao",
    name: "社保概览",
    belong: "overview"
  },
  "hr:enterpriseOverview:company": {
    router: "/overview/employer",
    name: "用工单位概览",
    belong: "overview"
  },
  "hr:enterpriseOverview:subCompany": {
    router: "/overview/subsidiary",
    name: "分组概览",
    belong: "overview"
  },
  "hr:enterpriseOverview:contract": {
    router: "/admin/overview_contract",
    name: "合同概览",
    belong: "overview"
  },
  // 公司设置
  "hr:enterprise:wages": {
    router: "/contractAdmin/settlementRules/wagesRules",
    name: "工价规则",
    belong: "setting"
  },
  "hr:enterprise:labor": {
    router: "/contractAdmin/settlementRules/laborRules",
    name: "招工劳务费规则",
    belong: "setting"
  },
  "hr:enterprise:hetongmoban": {
    router: "/contractAdmin/type",
    name: "合同模板",
    belong: "setting"
  },
  "hr:enterprise:yuegongzimoban": {
    router: "/contractAdmin/template",
    name: "月工资模板",
    belong: "setting"
  },
  "hr:enterprise:yuegongzimoban_xin": {
    router: "/contractAdmin/gongzibiaomuban",
    name: "月工资模板 2.0",
    belong: "setting"
  },
  "hr:enterprise:shenpimoban": {
    router: "/approvalAdmin/setting",
    name: "审批模版",
    belong: "setting"
  },
  "hr:enterprise:ruzhimoban": {
    router: "/contractAdmin/ruzhi",
    name: "入职模板",
    belong: "setting"
  },
  "hr:enterprise:canbaofanganshezhi": {
    router: "/contractAdmin/shebaoMethod",
    name: "参保方案设置",
    belong: "setting"
  },
  "hr:enterprise:yinzhangshezhi": {
    router: "/contractAdmin/stamp",
    name: "印章设置",
    belong: "setting"
  },
  "hr:enterprise:holiday": {
    router: "/contractAdmin/holiday",
    name: "节假日设置",
    belong: "setting"
  },
  // 销售管理
  "hr:enterpriseSetup:xiaoshouliebiao": {
    router: "/admin/xiaoshouliebiao",
    name: "销售列表",
    belong: "manage"
  },
  "hr:enterpriseSetup:fanfeiguize": {
    router: "/admin/fanfeiguize",
    name: "提成规则",
    belong: "manage"
  },
  "hr:enterpriseSetup:kehufuwufeijilu": {
    router: "/admin/kehufuwufeijilu",
    name: "客户服务费记录",
    belong: "manage"
  },
  "hr:enterpriseSetup:fanfeijisuan": {
    router: "/admin/fanfeijisuan",
    name: "提成计算",
    belong: "manage"
  },
  "hr:enterpriseSetup:fanfeigailan": {
    router: "/admin/fanfeigailan",
    name: "提成概览",
    belong: "manage"
  },
  // 产品介绍
  "hr:enterpriseSetup:productList": {
    router: "/admin/productList",
    name: "企业服务",
    belong: "manage"
  },
  "hr:enterpriseSetup:productBanner": {
    router: "/admin/productBanner",
    name: "首页企业服务展示",
    belong: "manage"
  },
  "hr:enterpriseSetup:eventHandling": {
    router: "/admin/eventHandling",
    name: "服务反馈",
    belong: "manage"
  },
  "hr:enterpriseSetup:zhidugongshi": {
    router: "/admin/zhidugongshi",
    name: "规章制度",
    belong: "manage"
  },
  "hr:enterpriseSetup:zhengcesudi": {
    router: "/admin/zhengcesudi",
    name: "政策速递",
    belong: "manage"
  },
  // 公司管理
  "hr:enterpriseSetup:companyManage": {
    router: "/admin/company_management",
    name: "分组管理",
    belong: "manage"
  },
  "hr:enterpriseSetup:accountAuthority": {
    router: "/admin/account_management",
    name: "账号权限",
    belong: "manage"
  },
  "hr:enterpriseSetup:jiafangAccountAuthority": {
    router: "/admin/account_management_jf",
    name: "甲方账号权限",
    belong: "manage"
  },
  "hr:enterpriseSetup:roleManagement": {
    router: "/admin/role",
    name: "角色管理",
    belong: "manage"
  },
  "hr:enterpriseSetup:customer": {
    router: "/admin/employment_unit",
    name: "用工单位",
    belong: "manage"
  },
  "hr:enterpriseSetup:guanwangxinwen": {
    router: "/aNews/manage",
    name: "官网新闻",
    belong: "manage"
  },
  "hr:enterpriseSetup:kaowubaoming": {
    router: "/aNews/sign",
    name: "考务报名",
    belong: "manage"
  },
  "hr:enterpriseSetup:prepare": {
    router: "/admin/management/prePersonnel",
    name: "预登记人员",
    belong: "manage"
  },
  "hr:enterpriseSetup:channel": {
    router: "/admin/management/account",
    name: "渠道管理",
    belong: "manage"
  },
  "hr:enterpriseSetup:sendSingle": {
    router: "/admin/sendSingle",
    name: "任务派单",
    belong: "manage"
  },
  "hr:enterpriseSetup:gysManager": {
    router: "/admin/supplier/gysManager",
    name: "供应商管理",
    belong: "manage"
  },
  "hr:enterpriseSetup:gysFieldManager": {
    router: "/admin/supplier/gysFieldManager",
    name: "供应商字段设置",
    belong: "manage"
  },
  // 公司结算
  "hr:settlementCompany:returnoney": {
    router: "/settlementAdmin/record",
    name: "客户回款计划",
    belong: "cost"
  },
  "hr:settlementCompany:kehumingxibiao_new": {
    router: "/settlementAdmin/kehumingxibiao",
    name: "客户明细表 2.0",
    belong: "cost"
  },
  "hr:settlementCompany:fuwufeixiang": {
    router: "/settlementAdmin/fuwufeixiang",
    name: "服务费项",
    belong: "cost"
  },
  "hr:settlementCompany:customers": {
    router: "/settlementAdmin/customers",
    name: "客户明细表",
    belong: "cost"
  },
  "hr:settlementCompany:socialSecurityPlan": {
    router: "/settlementAdmin/socialSecurityPlan",
    name: "社保回款计划",
    belong: "cost"
  },
  "hr:settlementCompany:channelCost": {
    router: "/settlementAdmin/labor",
    name: "渠道劳务费结算",
    belong: "cost"
  },
  "hr:settlementCompany:ExpenseBill": {
    router: "/settlementAdmin/billing",
    name: "费用账单",
    belong: "cost"
  },
  // 招聘
  "recruitment:roverview:query": {
    router: "/recruitment/overview",
    name: "招聘总览",
    belong: "recruitment"
  },
  "recruitment:management:query": {
    router: "/recruitment/management",
    name: "招聘管理",
    belong: "recruitment"
  },
  "recruitment:people:query": {
    router: "/recruitment/people",
    name: "投递记录",
    belong: "recruitment"
  },
  "recruitment:economic:query": {
    router: "/recruitment/economic",
    name: "经纪人管理",
    belong: "recruitment"
  },
  "recruitment:rpush:query": {
    router: "/recruitment/push",
    name: "内推管理",
    belong: "recruitment"
  },
  "recruitment:laborUnit:query": {
    router: "/recruitment/laborUnit",
    name: "招工企业",
    belong: "recruitment"
  },
  "recruitment:talent:query": {
    router: "/recruitment/talent",
    name: "人才库",
    belong: "recruitment"
  },
  "recruitment:zhinengtuijian:query": {
    router: "/recruitment/talentZhineng",
    name: "智能推荐",
    belong: "recruitment"
  },
  // 个税
  "zhinengsuanshui:koujiaoyiwurenguanli": {
    router: "/taxCalculate/management",
    name: "扣缴义务人管理",
    belong: "management"
  },
  "zhinengsuanshui:baoshuirenyuanku": {
    router: "/taxCalculate/people",
    name: "报税人员库",
    belong: "people"
  },
  "zhinengsuanshui:renyuanbaosong.daibaosong": {
    router: "/taxCalculate/waiting",
    name: "待报送",
    belong: "waiting"
  },
  "zhinengsuanshui:renyuanbaosong.yibaosong": {
    router: "/taxCalculate/report",
    name: "已报送",
    belong: "report"
  },
  "zhinengsuanshui:renyuanbaosong.tingzhibaosong": {
    router: "/taxCalculate/stop",
    name: "停止报送",
    belong: "stop"
  },
  "zhinengsuanshui:geshuijisuan": {
    router: "/taxCalculate/calculate",
    name: "个税计算",
    belong: "stop"
  }
};
function buildPathToBelongMap() {
  var map = {};
  Object.values(perToRouteMap).forEach(function (item) {
    map[item.router] = item;
  });
  return map;
}
var pathToBelongMap = buildPathToBelongMap();
export default perToRouteMap;
export { pathToBelongMap };