import request from "@/utils/request";

// 查询所有通知分组
export function listAllGroup(params) {
  return request({
    url: "/hr/api/v1/notice/listAllByCompanyId",
    method: "GET",
    params: params
  });
}

// 关闭消息提醒规则设置
export function close(id) {
  return request({
    url: "/hr/api/v1/notice/close/".concat(id),
    method: "PUT"
  });
}

// 开启消息提醒规则设置
export function open(id) {
  return request({
    url: "/hr/api/v1/notice/open/".concat(id),
    method: "PUT"
  });
}

// 新增或更新消息提醒规则设置
export function saveOrUpdateNoticeRuleSetting(data) {
  return request({
    url: "/hr/api/v1/notice/saveOrUpdateNoticeRuleSetting",
    method: "POST",
    data: data
  });
}