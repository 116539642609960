export default {
  fields: {
    address: {
      model: "address",
      type: "input",
      wrapper: {
        label: "address"
      },
      customize: {
        placeholder: "请输入address",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入address"
        }]
      },
      extend: {}
    },
    administratorList: {
      model: "administratorList",
      type: "input",
      wrapper: {
        label: "administratorList"
      },
      customize: {
        placeholder: "请输入administratorList",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入administratorList"
        }]
      },
      extend: {}
    },
    administratorUserIds: {
      model: "administratorUserIds",
      type: "input",
      wrapper: {
        label: "administratorUserIds"
      },
      customize: {
        placeholder: "请输入administratorUserIds",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入administratorUserIds"
        }]
      },
      extend: {}
    },
    businessScope: {
      model: "businessScope",
      type: "input",
      wrapper: {
        label: "businessScope"
      },
      customize: {
        placeholder: "请输入businessScope",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入businessScope"
        }]
      },
      extend: {}
    },
    depId: {
      model: "depId",
      type: "input",
      wrapper: {
        label: "depId"
      },
      customize: {
        placeholder: "请输入depId",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入depId"
        }]
      },
      extend: {}
    },
    email: {
      model: "email",
      type: "input",
      wrapper: {
        label: "联系邮箱"
      },
      customize: {
        placeholder: "请输入联系邮箱",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入联系邮箱"
        }]
      },
      extend: {}
    },
    empCount: {
      model: "empCount",
      type: "input",
      wrapper: {
        label: "在职员工数"
      },
      customize: {
        placeholder: "请输入在职员工数",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入在职员工数"
        }]
      },
      extend: {}
    },
    gmtCreate: {
      model: "gmtCreate",
      type: "input",
      wrapper: {
        label: "gmtCreate"
      },
      customize: {
        placeholder: "请输入gmtCreate",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入gmtCreate"
        }]
      },
      extend: {}
    },
    gmtModified: {
      model: "gmtModified",
      type: "input",
      wrapper: {
        label: "gmtModified"
      },
      customize: {
        placeholder: "请输入gmtModified",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入gmtModified"
        }]
      },
      extend: {}
    },
    id: {
      model: "id",
      type: "input",
      wrapper: {
        label: "id"
      },
      customize: {
        placeholder: "请输入id",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入id"
        }]
      },
      extend: {}
    },
    industry: {
      model: "industry",
      type: "input",
      wrapper: {
        label: "industry"
      },
      customize: {
        placeholder: "请输入industry",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入industry"
        }]
      },
      extend: {}
    },
    legalPerson: {
      model: "legalPerson",
      type: "input",
      wrapper: {
        label: "联系人姓名"
      },
      customize: {
        placeholder: "请输入联系人姓名",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入联系人姓名"
        }]
      },
      extend: {}
    },
    logo: {
      model: "logo",
      type: "input",
      wrapper: {
        label: "logo"
      },
      customize: {
        placeholder: "请输入logo",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入logo"
        }]
      },
      extend: {}
    },
    name: {
      model: "name",
      type: "input",
      wrapper: {
        label: "单位名称"
      },
      customize: {
        placeholder: "请输入单位名称",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入单位名称"
        }]
      },
      extend: {
        mode: "search"
      }
    },
    officialWebsite: {
      model: "officialWebsite",
      type: "input",
      wrapper: {
        label: "officialWebsite"
      },
      customize: {
        placeholder: "请输入officialWebsite",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入officialWebsite"
        }]
      },
      extend: {}
    },
    personSize: {
      model: "personSize",
      type: "input",
      wrapper: {
        label: "personSize"
      },
      customize: {
        placeholder: "请输入personSize",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入personSize"
        }]
      },
      extend: {}
    },
    profile: {
      model: "profile",
      type: "input",
      wrapper: {
        label: "profile"
      },
      customize: {
        placeholder: "请输入profile",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入profile"
        }]
      },
      extend: {}
    },
    telephone: {
      model: "telephone",
      type: "input",
      wrapper: {
        label: "联系电话"
      },
      customize: {
        placeholder: "请输入联系电话",
        allowClear: true
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "请输入联系电话"
        }]
      },
      extend: {}
    }
  },
  validators: {},
  config: {
    search: {
      fieldKeys: {
        name: {}
      },
      customize: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 16
        },
        layout: "inline"
      }
    },
    table: {
      fields: [{
        model: "name",
        title: "单位名称"
      }, {
        model: "legalPerson",
        title: "联系人姓名"
      }, {
        model: "telephone",
        title: "联系电话"
      }, {
        model: "email",
        title: "联系邮箱"
      }, {
        model: "empCount",
        title: "在职员工数"
      }],
      customize: {
        rowKey: "id",
        labelCol: {},
        wrapperCol: {}
      },
      extend: {
        batch: true,
        action: false
      }
    }
  }
};