import request from "@/utils/request";

// 待入职
export function pageWaitJobAdvanced(params) {
  return request({
    url: "/hr/api/v1/empInfos/pageWaitJobAdvanced",
    method: "GET",
    params: params
  });
}
// 待调岗 粘贴选择
export function listPasteOnJob(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/listPasteOnJob/".concat(companyId),
    method: "post",
    data: data
  });
}

// 待入职 粘贴选择
export function listPasteWaitJob(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/listPasteWaitJob/".concat(companyId),
    method: "post",
    data: data
  });
}

// 待转正
export function selectEmpInfoToFormalRelation(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoToFormalRelation",
    method: "GET",
    params: params
  });
}
// 待转正 粘贴选择
export function listPasteFormal(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/listPasteFormal/".concat(companyId),
    method: "post",
    data: data
  });
}

// 离职
export function selectForLeave(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectForLeave",
    method: "GET",
    params: params
  });
}
// 离职 粘贴选择
export function listPasteFormalForLeave(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/listPasteFormalForLeave/".concat(companyId),
    method: "post",
    data: data
  });
}
// 批量调岗 离职
export function selectEmpInfoOnJobRelation(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoOnJobRelation",
    method: "GET",
    params: params
  });
}

// 批量撤销离职
export function batchRevokeLeaveByEmpId(data) {
  return request({
    url: "/hr/api/v1/empChanges/batchRevokeLeaveByEmpId",
    method: "PUT",
    data: data
  });
}

// 批量 撤销离职
export function selectEmpInfoLeaveRelation(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoLeaveRelation",
    method: "GET",
    params: params
  });
}
// 待转正 粘贴选择
export function listPasteWaitLeave(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/listPasteWaitLeave/".concat(companyId),
    method: "post",
    data: data
  });
}
// 待调岗
export function selectEmpInfoTransferRelation(params) {
  return request({
    url: "/hr/api/v1/empInfos/selectEmpInfoTransferRelation",
    method: "GET",
    params: params
  });
}
// 待调岗 粘贴选择
export function listPasteTransferRelation(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/listPasteTransferRelation/".concat(companyId),
    method: "post",
    data: data
  });
}

// 批量恢复在职
// export function getEmpResignedList(id, params) {
//   return request({
//     url: `/hr/api/v1/empChanges/pageEmpResignedList/${id}`,
//     method: "get",
//     params
//   });
// }
// 已离职高级筛选
export function pageResignedList(params) {
  return request({
    url: "/hr/api/v1/empInfos/pageResignedList",
    method: "get",
    params: params
  });
}
// 删除员工 粘贴选择
export function listPasteResigned(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/listPasteResigned/".concat(companyId),
    method: "post",
    data: data
  });
}
// 批量恢复高级筛选
export function pageReinstatementEmp(params) {
  return request({
    url: "/hr/api/v1/empInfos/pageReinstatementEmp",
    method: "get",
    params: params
  });
}
// 批量恢复在职 粘贴选择
export function listPasteReinstatementEmp(data, companyId) {
  return request({
    url: "/hr/api/v1/empInfos/listPasteReinstatementEmp/".concat(companyId),
    method: "post",
    data: data
  });
}