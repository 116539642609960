import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import { getGinseng, saveImport, getCity, getCityAcc, socChecks } from "./api";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
var mixin = new UploadMixin();
import axios from "axios";
import moment from "moment";
var columns = [{
  title: "姓名",
  width: 120,
  dataIndex: "name",
  key: "name",
  fixed: "left"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 200
}, {
  title: "参保月份",
  dataIndex: "belongsDate",
  key: "belongsDate",
  width: 150
}, {
  title: "缴费类型",
  dataIndex: "payCostType",
  key: "payCostType",
  width: 120
}, {
  title: "个人社保费",
  dataIndex: "personalSocAmount",
  key: "personalSocAmount",
  width: 120
}, {
  title: "单位社保费",
  dataIndex: "companySocAmount",
  key: "companySocAmount",
  width: 120
}, {
  title: "个人公积金费",
  dataIndex: "personalPfAmount",
  key: "personalPfAmount",
  width: 120
}, {
  title: "单位公积金费",
  dataIndex: "companyPfAmount",
  key: "companyPfAmount",
  width: 120
}, {
  title: "服务费",
  dataIndex: "serviceCost",
  key: "serviceCost",
  width: 120
}, {
  title: "滞纳金",
  dataIndex: "lateFees",
  key: "lateFees",
  width: 120
}, {
  title: "检测结果",
  dataIndex: "parseResult",
  key: "parseResult",
  width: 220,
  scopedSlots: {
    customRender: "parseResult"
  },
  fixed: "right"
}];
export default {
  mixins: [mixin],
  data: function data() {
    return {
      columns: columns,
      city: undefined,
      yibaoSycnData: [],
      needSyncDate: [{
        areId: 283,
        needSyncType: ["医保"]
      } // 青岛医保
      ],

      typeList: [],
      cityDetail: {},
      loading: false,
      isContinue: false,
      formData: {},
      urlList: [],
      errData: false,
      infoList: [],
      progNameList: [],
      officialInfoList: [],
      gjsSwich: "",
      sbSwich: "",
      ybSwich: "",
      dataList: [],
      itemDetail: {},
      fileName: "",
      fileList: [],
      nextVisible: false,
      defaultFangan: null,
      tabsKey: this.$route.query.index ? this.$route.query.index : "1",
      params: {
        templateName: "socCheckImportTemplate.xlsx"
      },
      url: "/salary/api/v1/excel/template/download",
      excelName: "数据校验上传模板.xlsx",
      dynamicDate: moment(moment().format("YYYY-MM"), "YYYY-MM"),
      companyId: this.$store.state.hr.companyId,
      checkVisible: false,
      checkingVisible: false,
      checkingImg: require("@/assets/img/checking.png")
    };
  },
  created: function created() {
    this.getGinsengList();
    this.action = "/hr/api/v1/socChecks/parseImport";
    var that = this;
    // 监听来自插件的同步数据
    document.addEventListener("SynDataButton", function (e) {
      that.spinning = false;
      switch (e.detail.from) {
        case "青岛医保":
          var count = e.detail.arry.length;
          for (var i = 0; i < count; i++) {
            that.yibaoSycnData.push(e.detail.arry[i]);
          }
          break;
      }
    });
  },
  methods: {
    swichChange: function swichChange(e) {
      var _this = this;
      if (e === true) {
        // 检查该地区医保是否需要前端进行同步数据
        if (this.yibaoSwitch === true) {
          // 检查该地区医保是否需要前端进行同步数据
          var isSync = false;
          for (var i = 0; i < this.needSyncDate.length; i++) {
            if (this.needSyncDate[i].areId === this.defaultFangan) {
              // 确认地区
              for (var j = 0; j < this.needSyncDate[i].needSyncType.length; j++) {
                if (this.needSyncDate[i].needSyncType[j] === "医保") {
                  isSync = true;
                  break;
                }
              }
              break;
            }
          }
          if (isSync) {
            // 需要同步
            switch (this.defaultFangan) {
              case 283:
                // 青岛地区
                this.spinning = true;
                window.postMessage({
                  start: this.syncDate[0],
                  end: this.syncDate[1],
                  cookie: this.yibaoLogin.cookie,
                  type: "青岛医保" // 这个值会原封不动的传递回来
                }, "*");
                break;
            }
          }
        }
      } else {
        this.yibaoSycnData = [];
      }
      this.isContinue = false;
      this.typeList.forEach(function (item, index) {
        if (item.isSwitch && item.isSwitch === true) {
          _this.isContinue = true;
        }
      });
    },
    Login: function Login(e) {
      if (e) {
        window.open(e);
      }
    },
    downloadDSJ: function downloadDSJ() {
      window.open("https://www.yuque.com/docs/share/aadc62e4-0ea4-4387-80f3-ad8cd724018f?#");
    },
    parseImport: function parseImport(e) {
      var _this2 = this;
      axios({
        url: "/hr/api/v1/socChecks/saveImport?socMonthId=".concat(e.id, "&year=").concat(e.year, "&month=").concat(e.month, "&checkType=1&companyId=").concat(this.companyId, "&fileUrl=").concat(this.urlList[0], "&fileName=").concat(this.fileName),
        method: "post",
        data: this.formData,
        headers: this.headers
      }).then(function (res) {
        _this2.$message.success("校验成功");
        _this2.getGinsengList();
      }).catch(function (err) {
        console.log(err);
      });
    },
    getGinsengList: function getGinsengList() {
      var _this3 = this;
      this.loading = true;
      getGinseng({
        companyId: this.companyId,
        checkType: 1,
        month: moment(this.dynamicDate).format("MM"),
        year: moment(this.dynamicDate).format("YYYY")
      }).then(function (res) {
        _this3.infoList = JSON.parse(JSON.stringify(res.data));
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
      getGinseng({
        companyId: this.companyId,
        checkType: 2,
        month: moment(this.dynamicDate).format("MM"),
        year: moment(this.dynamicDate).format("YYYY")
      }).then(function (res) {
        _this3.officialInfoList = JSON.parse(JSON.stringify(res.data));
      }).catch(function (err) {
        console.log(err);
      });
    },
    beforeUpload: function beforeUpload(file) {
      var _this4 = this;
      this.fileList = [file];
      if (this.fileList && this.fileList.length > 0) {
        this.fileName = this.fileList[0].name;
        var fileList = this.fileList;
        this.formData = new FormData();
        var formData2 = new FormData();
        fileList.forEach(function (file) {
          _this4.formData.append("file", file);
        });
        fileList.forEach(function (file) {
          formData2.append("files", file);
        });
        // this.uploading = true;
        axios({
          url: this.action,
          method: "post",
          data: this.formData,
          headers: this.headers
        }).then(function (res) {
          // this.reset();
          axios({
            url: "/file/api/v1/general/upload/inline",
            method: "post",
            data: formData2,
            headers: _this4.headers
          }).then(function (res) {
            _this4.urlList = res.data.data;
          });
          // this.handleSuccess(res.data);
          // this.uploading = false;
          _this4.nextOk(res.data);
        });
      } else {
        this.reset();
        this.handleUploadError();
      }
      return false;
    },
    // 校验上传文件后弹出本地数据预览列表
    nextOk: function nextOk(e) {
      var _this5 = this;
      this.errData = false;
      this.dataList = e.data;
      this.nextVisible = true;
      this.dataList.forEach(function (item) {
        if (item.parseResult === false) {
          _this5.errData = true;
        }
      });
    },
    // 取消校验
    deleteCheck: function deleteCheck() {
      this.checkingVisible = false;
    },
    // 查看结果
    goDetail: function goDetail(e, state) {
      console.log(e);
      this.$router.push({
        path: "./detail",
        // 员工异动
        query: {
          id: e.id,
          month: e.month,
          year: e.year,
          state: state
        }
      });
    },
    callback: function callback(index) {
      var query = JSON.parse(JSON.stringify(this.$router.history.current.query)); // 取当前路由query值并且深拷贝
      var path = this.$router.history.current.path; // 取当前路由地址
      query.index = index; // 修改变动的query值
      this.$router.push({
        path: path,
        query: query
      }); // 改变query值
    },
    // 月份选择变化
    onMonthChange: function onMonthChange(e) {
      console.log(e);
      this.getGinsengList();
    },
    showCheck: function showCheck(e) {
      var _this6 = this;
      this.city = undefined;
      this.typeList = [];
      this.itemDetail = e;
      getCity({
        isDistinct: true
      }).then(function (res) {
        _this6.progNameList = [];
        if (res.data) {
          _this6.progNameList = res.data;
        }
      }).catch(function (err) {
        _this6.$message.error("失败!");
        console.log("debug log --> ", err);
      });
      this.checkVisible = true;
      this.isContinue = false;

      // this.checkingVisible = true;
    },
    // 点击校验确定按钮
    handleOk: function handleOk(e) {
      var _this7 = this;
      this.gjsSwich = "";
      this.sbSwich = "";
      this.ybSwich = "";
      this.typeList.forEach(function (item, index) {
        if (!item.isSwitch) {
          item.isSwitch = false;
        }
        if (item.accountType === "公积金") {
          _this7.gjsSwich = item.isSwitch;
        } else if (item.accountType === "社保") {
          _this7.sbSwich = item.isSwitch;
        } else if (item.accountType === "医保") {
          _this7.ybSwich = item.isSwitch;
        }
      });
      console.log(this.typeList);
      // setTimeout(() => {
      //   this.checkVisible = false;
      //   this.checkingVisible = true;
      // }, 500);
      if (!this.city) {
        this.$message.error("请选择校验城市");
      } else if (!this.isContinue) {
        this.$message.error("请选择校验类型");
      } else {
        socChecks({
          month: moment(this.dynamicDate).format("MM"),
          year: moment(this.dynamicDate).format("YYYY"),
          companyId: this.companyId,
          monthId: this.itemDetail.id,
          checkType: 2,
          areaId: this.cityDetail.areaId,
          syncServiceBeanName: this.cityDetail.syncServiceBeanName,
          socInfo: [],
          meInfo: this.yibaoSycnData,
          pfInfo: [],
          socIsTrue: this.sbSwich,
          pfIsTrue: this.gjsSwich,
          meIsTrue: this.ybSwich
        }).then(function (res) {
          _this7.$message.success("校验成功");
          _this7.getGinsengList();
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    handleCancel: function handleCancel(e) {
      this.checkVisible = false;
    },
    // 选择城市
    handleSelectChange: function handleSelectChange(e) {
      var _this8 = this;
      console.log(e);
      this.defaultFangan = e;
      this.progNameList.forEach(function (item, index) {
        if (item.areaId === e) {
          _this8.cityDetail = item;
        }
      });
      getCityAcc({
        areaId: e
      }).then(function (res) {
        _this8.typeList = res.data;
      }).catch(function (err) {
        _this8.$message.error("失败!");
        console.log("debug log --> ", err);
      });
    }
  }
};