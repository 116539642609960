var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", _vm._l(_vm.list, function (item) {
    return _c("div", {
      key: item.type,
      staticClass: "i-box"
    }, [_vm._m(0, true), _vm._m(1, true), _c("div", {
      staticClass: "phone"
    }, [_vm._v(" 手机号：1877876676 ")]), _c("div", {
      staticClass: "btn"
    }, [_c("a-button", {
      attrs: {
        type: "link"
      }
    }, [_vm._v(" 查看详情 ")])], 1)]);
  }), 0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm._v(" 11yy111"), _c("br"), _vm._v(" 今天 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm._v(" 号先生"), _c("br"), _c("span", {
    staticClass: "name-desc"
  }, [_vm._v("应聘：产品经理")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };