import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "formFontStyle",
    class: _vm.editable ? "peopleBoxCon1" : "peopleBoxCon"
  }, [!_vm.editable ? _c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "1",
    on: {
      click: _vm.editShow
    }
  }, [_vm._v("编辑 ")]), _vm.title === "任职受雇信息" && !_vm.remove ? _c("a-menu-item", {
    key: "2",
    on: {
      click: function click($event) {
        return _vm.$emit("delete");
      }
    }
  }, [_vm._v(" 删除 ")]) : _vm._e()], 1), _c("a-button", {
    staticClass: "peopleBoxConCZ",
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_vm._v(" 操作 "), _c("a-icon", {
    attrs: {
      type: "down"
    }
  })], 1)], 1) : _vm._e(), _c("a-form", {
    staticClass: "formStyleShow",
    attrs: {
      form: _vm.form,
      labelCol: {
        span: 10
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_vm.isReward && _vm.formFields ? _c("a-row", [_vm.sortedKeys ? _vm._l(_vm.sortedKeys, function (key) {
    return _c("div", {
      key: key
    }, [key === "contactAddressProvince1" || key === "habitualResidenceProvince1" || key === "domicileProvince1" ? _c("a-col", {
      attrs: {
        span: 9
      }
    }, [_c("a-row", [_c("a-col", {
      attrs: {
        span: 24
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: _vm.formFields[key],
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator(key)
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, _vm.formFields[key], _vm.dataSource, _vm.dynamicData);
        }
      }
    })], 1)], 1)], 1) : key === "contactAddressDetail" ? _c("a-col", {
      attrs: {
        span: 12
      }
    }, [_c("a-row", [_c("a-col", {
      staticClass: "hei40",
      attrs: {
        span: 24
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        showLabel: false,
        dataSource: _vm.dataSource,
        item: _vm.formFields["contactAddressDetail"],
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator("contactAddressDetail")
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, _vm.formFields["contactAddressDetail"], _vm.dataSource, _vm.dynamicData);
        }
      }
    })], 1)], 1)], 1) : key === "habitualResidenceDetail" ? _c("a-col", {
      attrs: {
        span: 12
      }
    }, [_c("a-row", [_c("a-col", {
      staticClass: "hei40",
      attrs: {
        span: 24
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        showLabel: false,
        dataSource: _vm.dataSource,
        item: _vm.formFields["habitualResidenceDetail"],
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator("habitualResidenceDetail")
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, _vm.formFields["habitualResidenceDetail"], _vm.dataSource, _vm.dynamicData);
        }
      }
    })], 1)], 1)], 1) : key === "domicileDetail" ? _c("a-col", {
      attrs: {
        span: 12
      }
    }, [_c("a-row", [_c("a-col", {
      staticClass: "hei40",
      attrs: {
        span: 24
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        showLabel: false,
        dataSource: _vm.dataSource,
        item: _vm.formFields["domicileDetail"],
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator("domicileDetail")
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, _vm.formFields["domicileDetail"], _vm.dataSource, _vm.dynamicData);
        }
      }
    })], 1)], 1)], 1) : key === "authorityId" ? _c("a-col", {
      attrs: {
        span: 12
      }
    }, [_vm.editable ? _c("a-form-item", {
      attrs: {
        label: "所属税局",
        "label-col": {
          span: 8
        },
        "wrapper-col": {
          span: 10
        }
      }
    }, [_c("a-cascader", {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["authorityId", {
          rules: [{
            required: true,
            message: "请选择所属税局!"
          }]
        }],
        expression: "['authorityId', { rules: [{ required: true, message: '请选择所属税局!' }] }]"
      }],
      attrs: {
        options: _vm.dataList,
        "expand-trigger": "hover",
        disabled: _vm.formFields["authorityId"].customize.disabled ? _vm.formFields["authorityId"].customize.disabled : false,
        placeholder: "请选择所属税局",
        "show-search": {
          filter: _vm.filter
        }
      },
      on: {
        change: _vm.onChangeAuthorityId
      }
    })], 1) : _vm._e(), !_vm.editable ? _c("a-form-item", {
      staticClass: "widCompany",
      attrs: {
        label: "所属税局",
        "label-col": {
          span: 8
        },
        "wrapper-col": {
          span: 15
        }
      }
    }, [_vm._v(_vm._s(_vm.dataSource.companyName) + "/" + _vm._s(_vm.dataSource.authorityName))]) : _vm._e()], 1) : key === "phoneNew" ? _c("a-col", {
      staticStyle: {
        position: "relative"
      },
      attrs: {
        span: 12
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: _vm.formFields[key],
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator(key)
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, _vm.formFields[key], _vm.dataSource, _vm.dynamicData);
        }
      }
    }), _c("div", {
      staticStyle: {
        position: "absolute",
        bottom: "31px",
        left: "76.6667%",
        color: "#a6a8b4",
        "font-size": "12px"
      }
    }, [_c("a-popover", {
      attrs: {
        trigger: "hover"
      }
    }, [_c("template", {
      slot: "content"
    }, [_c("span", [_vm._v("本次任职受雇时所使用的手机号")])]), _c("span", {
      staticClass: "icon iconfont",
      staticStyle: {
        cursor: "pointer",
        color: "#a6a8b4"
      }
    }, [_vm._v("")])], 2)], 1)], 1) : key === "email" ? _c("a-col", {
      attrs: {
        span: 12
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: _vm.formFields[key],
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator(key)
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, _vm.formFields[key], _vm.dataSource, _vm.dynamicData);
        }
      }
    })], 1) : _c("a-col", {
      attrs: {
        span: 12
      }
    }, [_vm.detailedShow ? _c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: _vm.formFields[key],
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator(key)
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, _vm.formFields[key], _vm.dataSource, _vm.dynamicData);
        }
      }
    }) : _vm._e(), key === "personalInvestmentRatio" ? _c("div", {
      staticStyle: {
        position: "absolute",
        bottom: "31px",
        left: "76.6667%"
      }
    }, [_vm._v("%")]) : _vm._e()], 1)], 1);
  }) : _vm._l(_vm.formFields, function (item) {
    return _c("div", {
      key: item.model
    }, [_c("a-col", {
      attrs: {
        span: 12
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: item,
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator(item.model)
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, item, _vm.dataSource, _vm.dynamicData);
        }
      }
    })], 1)], 1);
  })], 2) : _vm._e()], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editable && _vm.remove && _vm.status === "add",
      expression: "editable && remove && status === 'add'"
    }],
    staticClass: "DynamicForm"
  }, [_c("a-space", [_c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: _vm.onRemove
    }
  }, [_vm._v("删 除")])], 1)], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editable && _vm.status !== "add",
      expression: "editable && status !== 'add'"
    }],
    staticClass: "DynamicForm"
  }, [_c("a-space", [_vm.remove ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: _vm.onRemove
    }
  }, [_vm._v("删 除")]) : _vm._e(), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保 存")]), _c("a-button", {
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };