import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
import { selectByCompanyId, SalaryIncrease, empAdjustable } from "@/api/wages/file";
var columns = [{
  title: "调薪项目",
  dataIndex: "itemName",
  align: "itemName",
  key: "itemName",
  scopedSlots: {
    customRender: "itemName"
  },
  width: 150
}, {
  title: "调薪前",
  dataIndex: "amount",
  align: "amount",
  key: "amount",
  scopedSlots: {
    customRender: "amount"
  },
  width: 150
}, {
  title: "调薪后",
  dataIndex: "afterAmount",
  align: "afterAmount",
  key: "afterAmount",
  scopedSlots: {
    customRender: "afterAmount"
  },
  width: 150
}, {
  title: "调薪幅度",
  dataIndex: "amplitude",
  align: "amplitude",
  key: "amplitude",
  scopedSlots: {
    customRender: "amplitude"
  },
  width: 150
}];
export default {
  name: "FileEdit",
  data: function data() {
    return {
      currentPage: null,
      fixedSalaryTemplateName: "",
      mainId: null,
      takeDate: null,
      form: {},
      trainingList: [],
      officialList: [],
      empId: "",
      templateList: [],
      companyId: this.$store.state.hr.companyId,
      desc: "",
      number: "",
      columns: columns,
      edit: false,
      routerData: {},
      oldData: {},
      putData: {
        salaryPerformance: "",
        mealSubsidy: "",
        trafficSubsidy: "",
        salaryBasic: "",
        reason: "",
        remark: ""
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    doWords: function doWords() {
      return ["salaryPerformance", "mealSubsidy", "trafficSubsidy", "salaryBasic"];
    },
    status: function status() {
      return ["待入职", "入职", "待离职", "离职"][this.routerData.status - 1];
    },
    postType: function postType() {
      return this.$store.state.enums.postTypeArr;
    },
    putAllMoney: function putAllMoney() {
      var _this$putData = this.putData,
        salaryPerformance = _this$putData.salaryPerformance,
        mealSubsidy = _this$putData.mealSubsidy,
        trafficSubsidy = _this$putData.trafficSubsidy,
        salaryBasic = _this$putData.salaryBasic;
      var arr = [salaryPerformance, mealSubsidy, trafficSubsidy, salaryBasic];
      return arr.reduce(function (preValue, curValue) {
        return Number(preValue) + Number(curValue);
      }, 0);
    },
    reasons: function reasons() {
      return [{
        label: "转正",
        value: "1"
      }, {
        label: "晋升",
        value: "2"
      }, {
        label: "调动",
        value: "3"
      }, {
        label: "年中调薪",
        value: "4"
      }, {
        label: "年度调薪",
        value: "5"
      }, {
        label: "特别调薪",
        value: "6"
      }, {
        label: "其他",
        value: "7"
      }];
    }
  }),
  created: function created() {
    this.currentPage = this.$route.query.currentPage;
    this.routerData = JSON.parse(this.$route.query.routerData);
    console.log(this.routerData, "this.routerData ");
    this.getSelectByCompanyId();
    this.SalaryIncreaseList();
  },
  methods: {
    disabledEndDate: function disabledEndDate(endValue) {
      var startValue = this.routerData.joinDate;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleTimeChange: function handleTimeChange(e) {
      this.takeDate = e;
    },
    SalaryIncreaseList: function SalaryIncreaseList() {
      var _this2 = this;
      var id = this.routerData.empId;
      SalaryIncrease(id).then(function (res) {
        _this2.mainId = res.data.id;
        _this2.fixedSalaryTemplateName = res.data.fixedSalaryTemplateName;
        _this2.officialList = res.data.officialList;
        _this2.trainingList = res.data.trainingList;
        for (var i = 0; i < _this2.trainingList.length; i++) {
          _this2.trainingList[i].afterAmount = _this2.trainingList[i].afterAmount ? _this2.trainingList[i].afterAmount : _this2.trainingList[i].amount;
          _this2.trainingList[i].projectId = i;
          _this2.trainingList[i].amplitude = 0.0 + "%";
          _this2.trainingList[i].relativecolor = "#0DB6AC";
        }
        for (var p = 0; p < _this2.officialList.length; p++) {
          _this2.officialList[p].afterAmount = _this2.officialList[p].afterAmount ? _this2.officialList[p].afterAmount : _this2.officialList[p].amount;
          _this2.officialList[p].projectId = p;
          _this2.officialList[p].amplitude = 0.0 + "%";
          _this2.officialList[p].relativecolor = "#0DB6AC";
        }
      });
    },
    getSelectByCompanyId: function getSelectByCompanyId() {
      var _this3 = this;
      selectByCompanyId({
        companyId: this.companyId
      }).then(function (res) {
        _this3.templateList = res.data;
      });
    },
    goOut: function goOut() {
      var _this = this;
      this.$confirm({
        title: "员工调薪尚未保存，是否确认离开？",
        onOk: function onOk() {
          _this.$router.go(-1);
        }
      });
    },
    // 试用表格输入框变化
    handleInputChange1: function handleInputChange1(value, key) {
      var arr = JSON.parse(JSON.stringify(this.trainingList));
      for (var i = 0; i < arr.length; i++) {
        if (i === key) {
          arr[i].afterAmount = value;
          this.trainingList[i].afterAmount = value;
          if (arr[i].amount === 0) {
            arr[i].amplitude = "--";
          } else {
            arr[i].amplitude = Math.round((arr[i].afterAmount - arr[i].amount) / arr[i].amount * 10000) / 100 + "%";
          }
          if (arr[i].afterAmount - arr[i].amount > 0) {
            arr[i].relativecolor = "#FA5051";
          } else {
            arr[i].relativecolor = "#0DB6AC";
          }
        }
      }
      var s = 0;
      for (var t = 0; t < arr.length - 1; t++) {
        s += arr[t].afterAmount;
        arr[arr.length - 1].afterAmount = s;
        if (arr[arr.length - 1].amount === 0) {
          arr[arr.length - 1].amplitude = "--";
        } else {
          arr[arr.length - 1].amplitude = Math.round((arr[arr.length - 1].afterAmount - arr[arr.length - 1].amount) / arr[arr.length - 1].amount * 10000) / 100 + "%";
        }
        if (arr[arr.length - 1].afterAmount - arr[arr.length - 1].amount > 0) {
          arr[arr.length - 1].relativecolor = "#FA5051";
        } else {
          arr[arr.length - 1].relativecolor = "#0DB6AC";
        }
        this.trainingList = JSON.parse(JSON.stringify(arr));
      }
    },
    // 正式表格输入框变化
    handleInputChange2: function handleInputChange2(value, key) {
      var arr = JSON.parse(JSON.stringify(this.officialList));
      for (var i = 0; i < arr.length; i++) {
        if (i === key) {
          arr[i].afterAmount = value;
          this.officialList[i].afterAmount = value;
          if (arr[i].amount === 0) {
            arr[i].amplitude = "--";
          } else {
            arr[i].amplitude = Math.round((arr[i].afterAmount - arr[i].amount) / arr[i].amount * 10000) / 100 + "%";
          }
          if (arr[i].afterAmount - arr[i].amount > 0) {
            arr[i].relativecolor = "#FA5051";
          } else {
            arr[i].relativecolor = "#0DB6AC";
          }
        }
      }
      var s = 0;
      for (var t = 0; t < arr.length - 1; t++) {
        s += arr[t].afterAmount;
        arr[arr.length - 1].afterAmount = s;
        if (arr[arr.length - 1].amount === 0) {
          arr[arr.length - 1].amplitude = "--";
        } else {
          arr[arr.length - 1].amplitude = Math.round((arr[arr.length - 1].afterAmount - arr[arr.length - 1].amount) / arr[arr.length - 1].amount * 10000) / 100 + "%";
        }
        if (arr[arr.length - 1].afterAmount - arr[arr.length - 1].amount > 0) {
          arr[arr.length - 1].relativecolor = "#FA5051";
        } else {
          arr[arr.length - 1].relativecolor = "#0DB6AC";
        }
        this.officialList = JSON.parse(JSON.stringify(arr));
      }
    },
    // 表单提交
    handleSubmit: function handleSubmit() {
      var _this4 = this;
      if (!this.officialList[this.officialList.length - 1].amplitude && !this.trainingList[this.trainingList.length - 1].amplitude) {
        this.$notification["error"]({
          message: "error",
          description: "保存失败,至少更改一项调薪项目"
        });
      } else {
        this.trainingList.forEach(function (item, index) {
          item.amount = item.afterAmount;
        });
        this.officialList.forEach(function (item, index) {
          item.amount = item.afterAmount;
        });
        empAdjustable({
          isFixed: 0,
          companyId: this.companyId,
          empId: this.routerData.empId,
          trainingFixedSalaryDetail: this.trainingList,
          officialFixedSalaryDetail: this.officialList,
          reason: this.form.reason,
          effectTime: this.takeDate,
          remark: this.form.remark,
          isOfficial: parseInt(this.routerData.employType),
          id: this.mainId,
          trainingSalarySum: this.trainingList[this.trainingList.length - 1].afterAmount,
          officialSalarySum: this.officialList[this.officialList.length - 1].afterAmount
        }).then(function (_ref) {
          var success = _ref.success;
          for (var i = 0; i < _this4.trainingList.length; i++) {
            _this4.trainingList[i].amount = _this4.trainingList[i].afterAmount;
          }
          for (var p = 0; p < _this4.officialList.length; p++) {
            _this4.officialList[p].amount = _this4.officialList[p].afterAmount;
          }
          if (success) {
            _this4.goBack();
            _this4.$notification["success"]({
              message: "调薪成功"
            });
          }
        });
      }
    },
    // 时间
    onChange: function onChange() {},
    // 返回
    goBack: function goBack() {
      this.$router.push({
        path: "/wages/file",
        query: {
          currentPage: this.currentPage
        }
      });
    }
  }
};