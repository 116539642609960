import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.function.name.js";
import _ from "lodash";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      lst: [],
      modalTitle: "新增服务费项",
      modalVisible: false,
      form: this.$form.createForm(this),
      tempItem: null,
      nameSearch: "",
      pagination: {
        pageSize: 20,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      loading: false
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    onNameChange: _.throttle(function () {
      this.pagination.current = 1;
      this.loadData();
    }, 1000),
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.$request({
        url: "/salary/api/v1/serviceCharges/selectPageByNameLike",
        params: {
          name: this.nameSearch,
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        }
      }).then(function (res) {
        _this.lst = res.data.entities;
        _this.pagination.total = res.data.entityCount;
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    doAdd: function doAdd() {
      var _this2 = this;
      this.modalTitle = "新增服务费项";
      this.modalVisible = true;
      setTimeout(function () {
        _this2.form.setFieldsValue({
          name: undefined,
          amount: undefined
        });
      }, 0);
    },
    doEdit: function doEdit(item) {
      var _this3 = this;
      this.tempItem = item;
      this.modalTitle = "编辑服务费项";
      this.modalVisible = true;
      setTimeout(function () {
        _this3.form.setFieldsValue({
          name: item.name,
          amount: item.amount
        });
      }, 0);
    },
    doDel: function doDel(item) {
      var _this4 = this;
      this.$confirm({
        title: "删除确认",
        content: "服务费项删除后将无法恢复，确认要删除吗？",
        onOk: function onOk() {
          _this4.$request({
            url: "/salary/api/v1/serviceCharges/".concat(item.id),
            method: "delete"
          }).then(function (res) {
            _this4.$message.success("删除成功");
            _this4.loadData();
          }).catch(function () {});
        }
      });
    },
    onOk: function onOk() {
      var _this5 = this;
      this.form.validateFields(function (err, values) {
        if (!err) {
          if (_this5.modalTitle === "新增服务费项") {
            _this5.$request({
              url: "/salary/api/v1/serviceCharges",
              method: "post",
              data: values
            }).then(function (res) {
              _this5.$message.success("新增成功");
              _this5.onCancel();
              _this5.loadData();
            }).catch(function () {});
          } else {
            _this5.$request({
              url: "/salary/api/v1/serviceCharges",
              method: "put",
              data: _objectSpread({
                id: _this5.tempItem.id
              }, values)
            }).then(function (res) {
              _this5.$message.success("修改成功");
              _this5.onCancel();
              _this5.loadData();
            }).catch(function () {});
          }
        }
      });
    },
    onCancel: function onCancel() {
      this.tempItem = null;
      this.modalVisible = false;
    }
  }
};