var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "approval-Pop",
    class: _vm.popRecord.nodeType === "start" || _vm.popRecord.nodeType === "notifier" ? "approval-Pop14" : null
  }, [_c("div", {
    staticClass: "approval-Pop-triangle-up"
  }), _c("div", {
    staticClass: "approval-Pop-box"
  }, [_c("a-row", {
    staticClass: "approval-Pop-font"
  }, [_c("a-col", {
    staticClass: "approval-Pop-font-left",
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      span: 7
    }
  }, [_vm._v(_vm._s(_vm.popRecord.nodeName) + "：")]), _vm.popRecord.userFlowApproverList ? _c("a-col", {
    attrs: {
      span: 16
    }
  }, _vm._l(_vm.popRecord.userFlowApproverList, function (i, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 8
      }
    }, [_c("a-popover", [_c("template", {
      slot: "content"
    }, [_c("p", [_vm._v("审核备注:" + _vm._s(i.approverOpinion || "暂无填写"))])]), _c("div", {
      staticClass: "approval-Pop-pois"
    }, [_c("a-avatar", {
      attrs: {
        icon: "user",
        size: 32,
        src: i.approverUserAvatar || null
      }
    }), i.status != 0 ? _c("a-icon", {
      staticClass: "approval-Pop-abso",
      style: {
        fontSize: "16px",
        color: "#fff",
        background: _vm.getApprovalStatusColor(i.status),
        borderRadius: "50%"
      },
      attrs: {
        type: _vm.getApprovalStatusIcon(i.status)
      }
    }) : _vm._e()], 1)], 2), _c("div", {
      staticClass: "approval-Pop-colfff",
      staticStyle: {
        "padding-top": "3px"
      }
    }, [_vm._v(_vm._s(i.approverUserName))])], 1);
  }), 1) : _c("a-col", {
    attrs: {
      span: 16
    }
  }, _vm._l(_vm.popRecord.approUserList, function (i, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 8
      }
    }, [_c("div", {
      staticClass: "approval-Pop-pois"
    }, [_c("a-avatar", {
      attrs: {
        icon: "user",
        size: 32,
        src: i.avatar || null
      }
    })], 1), _c("div", {
      staticClass: "approval-Pop-colfff",
      staticStyle: {
        "padding-top": "3px"
      }
    }, [_vm._v(_vm._s(i.userName))])]);
  }), 1)], 1), _c("a-row", {
    staticClass: "approval-Pop-font"
  }, [_c("a-col", {
    staticClass: "approval-Pop-font-left",
    attrs: {
      span: 7
    }
  }, [_vm._v(_vm._s(_vm.popRecord.nodeType === "notifier" ? "抄送时间" : _vm.popRecord.nodeType === "start" ? "申请时间" : "审批时间") + "：")]), _c("a-col", {
    staticClass: "approval-Pop-colfff",
    attrs: {
      span: 12
    }
  }, [_vm._v(" " + _vm._s(_vm.popRecord.gmtNodeEnd ? _vm.moment(_vm.popRecord.gmtNodeEnd).format("YYYY-MM-DD HH:mm") : "") + " ")])], 1), _vm.popRecord.nodeType !== "start" && _vm.popRecord.nodeType !== "notifier" ? _c("a-row", {
    staticClass: "approval-Pop-font"
  }, [_c("a-col", {
    staticClass: "approval-Pop-font-left",
    attrs: {
      span: 7
    }
  }, [_vm._v("审批状态：")]), _c("a-col", {
    style: {
      color: _vm.getApprovalStatusColorBlue(_vm.popRecord.approStatus)
    },
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.getApprovalStatus(_vm.popRecord.approStatus)))])], 1) : _vm._e()], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };