export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dataVal: [],
      replaceFields: {
        children: "children",
        key: "id",
        value: "code",
        title: "name"
      }
    };
  },
  watch: {
    visible: function visible(v) {
      if (v) {
        this.dataVal = [];
      }
    }
  },
  methods: {
    onChange: function onChange(value, selectedOptions) {
      this.$emit("upDate", value);
    }
  }
};