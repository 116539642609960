import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bgc-white",
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "p16",
    staticStyle: {
      width: "260px",
      "flex-shrink": "0"
    }
  }, [_c("div", {
    staticClass: "flex-center-between"
  }, [_c("span", {
    staticClass: "fs16 fw500"
  }, [_vm._v("企业服务分类")]), _c("span", {
    staticStyle: {
      display: "inline-block",
      cursor: "pointer"
    },
    on: {
      click: _vm.onAddGroupItem
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  })], 1)]), _c("div", {
    staticClass: "mt12"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "228px"
    },
    attrs: {
      placeholder: "搜索分类",
      allowClear: ""
    },
    model: {
      value: _vm.groupTerm,
      callback: function callback($$v) {
        _vm.groupTerm = $$v;
      },
      expression: "groupTerm"
    }
  })], 1), _c("div", {
    staticClass: "mt16"
  }, [_c("div", {
    staticClass: "pv8 ph12 pointer",
    class: {
      selected: _vm.currentGroup === "0"
    },
    on: {
      click: function click($event) {
        _vm.currentGroup = "0";
      }
    }
  }, [_vm._v("全部分类")]), _vm._l(_vm.groupLst, function (node) {
    return [node.productTypeName.includes(_vm.groupTerm) ? _c("div", {
      key: node.id,
      staticClass: "flex-center pv8 ph12 pointer",
      class: {
        selected: _vm.currentGroup === node.id
      },
      on: {
        click: function click($event) {
          _vm.currentGroup = node.id;
        }
      }
    }, [_c("div", {
      staticClass: "ellipsis",
      staticStyle: {
        width: "230px"
      }
    }, [_vm._v(_vm._s(node.productTypeName))]), _c("a-icon", {
      attrs: {
        type: "setting"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.onEditGroupItem(node);
        }
      }
    })], 1) : _vm._e()];
  })], 2)]), _c("div", {
    staticClass: "pl16",
    staticStyle: {
      flex: "1",
      "border-left": "1px solid #e8e8e8",
      overflow: "auto",
      "min-height": "calc(100vh - 112px)"
    }
  }, [_vm.groupLst.length > 0 ? _c("ProdList", {
    attrs: {
      productTypeId: _vm.currentGroup,
      productTypeIdList: _vm.groupLst
    }
  }) : _c("div", {
    staticClass: "flex-center-center",
    staticStyle: {
      height: "100%"
    }
  }, [_c("a-empty", {
    attrs: {
      image: _vm.emptyImg
    }
  }, [_c("template", {
    slot: "description"
  }, [_c("div", {
    staticClass: "mb12",
    staticStyle: {
      color: "rgba(159, 163, 176, 1)"
    }
  }, [_vm._v("抱歉，暂无产品数据")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onAddGroupItem
    }
  }, [_vm._v("添加分类")])], 1)], 2)], 1)], 1), _c("a-modal", {
    attrs: {
      title: _vm.groupModalTitle
    },
    model: {
      value: _vm.groupModalVisible,
      callback: function callback($$v) {
        _vm.groupModalVisible = $$v;
      },
      expression: "groupModalVisible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.groupForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "分类名称",
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["productTypeName", {
        rules: [{
          required: true,
          message: "必须填写名称"
        }]
      }],
      expression: "['productTypeName', { rules: [{ required: true, message: '必须填写名称' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请输入"
    }
  })], 1)], 1), _c("a-space", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        _vm.groupModalVisible = false;
      }
    }
  }, [_vm._v("取消")]), _vm.groupModalTitle === "编辑分类" ? _c("a-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.onDelGroupItem
    }
  }, [_vm._v("删除")]) : _vm._e(), _c("a-button", {
    attrs: {
      loading: _vm.confirmLoadingGroup,
      type: "primary"
    },
    on: {
      click: _vm.onGroupModalOk
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };