import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
var employmentType = ["雇员", "保险营销员", "证券经纪人", "实习学生（全日制学历教育）", "其他"];
var certificate = ["中国护照", "居民身份证", "港澳居民来往内地通行证", "台湾居民来往大陆通行证", "外国护照", "中华人民共和国港澳居民居住证", "中华人民共和国台湾居民居住证", "外国人永久居留身份证", "中华人民共和国外国人工作许可证（A类）", "中华人民共和国外国人工作许可证（B类）", "中华人民共和国外国人工作许可证（C类）", "其他个人证件"];
var columns = [{
  title: "姓名",
  dataIndex: "empName",
  key: "empName",
  fixed: "left",
  width: 170,
  colDefault: true,
  isEdit: false,
  scopedSlots: {
    customRender: "empName"
  }
}, {
  title: "证件类型",
  dataIndex: "certificateType",
  key: "certificateType",
  width: 150,
  colDefault: true,
  isEdit: false,
  scopedSlots: {
    customRender: "certificateType"
  }
}, {
  title: "证件号码",
  dataIndex: "idCard",
  key: "idCard",
  colDefault: true,
  isEdit: false,
  width: 180
}, {
  title: "国籍(地区)",
  dataIndex: "nationality",
  key: "nationality",
  colDefault: true,
  width: 100
}, {
  title: "性别",
  dataIndex: "gender",
  key: "gender",
  colDefault: true,
  scopedSlots: {
    customRender: "gender"
  },
  width: 100
}, {
  title: "出生日期",
  dataIndex: "birthday",
  key: "birthday",
  colDefault: true,
  scopedSlots: {
    customRender: "birthday"
  },
  width: 150
}, {
  title: "人员状态",
  dataIndex: "status",
  key: "status",
  colDefault: true,
  width: 100,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "任职受雇从业类型",
  dataIndex: "employmentType",
  key: "employmentType",
  colDefault: true,
  width: 150,
  scopedSlots: {
    customRender: "employmentType"
  }
}, {
  title: "入职年度就业情形",
  dataIndex: "employmentSituation",
  key: "employmentSituation",
  colDefault: true,
  width: 150
}, {
  title: "手机号码",
  dataIndex: "phone",
  key: "phone",
  colDefault: true,
  width: 150
}, {
  title: "任职受雇从业日期",
  dataIndex: "joinDate",
  key: "joinDate",
  colDefault: true,
  width: 150
}, {
  title: "离职日期",
  dataIndex: "leaveDate",
  key: "leaveDate",
  colDefault: true,
  width: 100
}, {
  title: "是否残疾",
  dataIndex: "disability",
  key: "disability",
  colDefault: true,
  width: 100
}, {
  title: "是否烈属",
  dataIndex: "martyr",
  key: "martyr",
  colDefault: true,
  width: 100
}, {
  title: "是否孤老",
  dataIndex: "oldOrNot",
  key: "oldOrNot",
  colDefault: true,
  width: 100
}, {
  title: "残疾证号",
  dataIndex: "disabilityCertificateNumber",
  key: "disabilityCertificateNumber",
  colDefault: true,
  width: 100
}, {
  title: "烈属证号",
  dataIndex: "martyrCertificateNumber",
  key: "martyrCertificateNumber",
  colDefault: true,
  width: 100
}, {
  title: "是否扣除减除费用",
  dataIndex: "deduction",
  key: "deduction",
  colDefault: true,
  width: 150
}, {
  title: "个人投资额 ",
  dataIndex: "personalInvestment",
  key: "personalInvestment",
  colDefault: true,
  width: 120
}, {
  title: "个人投资比例(%)",
  dataIndex: "personalInvestmentRatio",
  key: "personalInvestmentRatio",
  colDefault: true,
  width: 150
}, {
  title: "备注",
  dataIndex: "remark",
  key: "remark",
  colDefault: true,
  width: 100
}, {
  title: " 中文名",
  dataIndex: "empName",
  key: "empName2",
  colDefault: true,
  width: 100
}, {
  title: "涉税事由",
  dataIndex: "taxRelatedReasons",
  key: "taxRelatedReasons",
  colDefault: true,
  width: 100
}, {
  title: "出生国家(地区)",
  dataIndex: "countryOfBirth",
  key: "countryOfBirth",
  colDefault: true,
  width: 140
}, {
  title: " 首次入境时间",
  dataIndex: "firstEntryTime",
  key: "firstEntryTime",
  colDefault: true,
  width: 120
}, {
  title: "预计离境时间",
  dataIndex: "estimatedDepartureTime",
  key: "estimatedDepartureTime",
  colDefault: true,
  width: 120
}, {
  title: "其他证件类型",
  dataIndex: "otherDocumentType",
  key: "otherDocumentType",
  colDefault: true,
  width: 120
}, {
  title: "其他证件号码",
  dataIndex: "otherIDNumber",
  key: "otherIDNumber",
  colDefault: true,
  width: 120
}, {
  title: " 户籍所在地（省）",
  dataIndex: "domicileProvince",
  key: "domicileProvince",
  colDefault: true,
  width: 150
}, {
  title: " 户籍所在地（市）",
  dataIndex: "domicileCity",
  key: "domicileCity",
  colDefault: true,
  width: 150
}, {
  title: "户籍所在地（区县）",
  dataIndex: "domicileDistrict",
  key: "domicileDistrict",
  colDefault: true,
  width: 170
}, {
  title: "户籍所在地（详细地址）",
  dataIndex: "domicileAddress",
  key: "domicileAddress",
  colDefault: true,
  width: 190
}, {
  title: " 经常居住地（省）",
  dataIndex: "habitualResidenceProvince",
  key: "habitualResidenceProvince",
  colDefault: true,
  width: 170
}, {
  title: "经常居住地（市）",
  dataIndex: "habitualResidenceCity",
  key: "habitualResidenceCity",
  colDefault: true,
  width: 150
}, {
  title: " 经常居住地（区县）",
  dataIndex: "habitualResidenceDistrict",
  key: "habitualResidenceDistrict",
  colDefault: true,
  width: 170
}, {
  title: " 经常居住地（详细地址）",
  dataIndex: "habitualResidenceAddress",
  key: "habitualResidenceAddress",
  colDefault: true,
  width: 190
}, {
  title: "联系地址（省）",
  dataIndex: "province",
  key: "province",
  colDefault: true,
  width: 150
}, {
  title: " 联系地址（市）",
  dataIndex: "city",
  key: "city",
  colDefault: true,
  width: 150
}, {
  title: "联系地址（区县）",
  dataIndex: "district",
  key: "district",
  colDefault: true,
  width: 150
}, {
  title: "联系地址（详细地址）",
  dataIndex: "address",
  key: "address",
  colDefault: true,
  width: 190
}, {
  title: "电子邮箱",
  dataIndex: "email",
  key: "email",
  colDefault: true,
  width: 100
}, {
  title: "学历",
  dataIndex: "highestEducation",
  key: "highestEducation",
  colDefault: true,
  width: 100
}, {
  title: "开户银行",
  dataIndex: "bankName",
  key: "bankName",
  colDefault: true,
  width: 100
}, {
  title: "银行账号",
  dataIndex: "bankCard",
  key: "bankCard",
  colDefault: true,
  width: 100
}, {
  title: "开户行省份",
  dataIndex: "bankProvince",
  key: "bankProvince",
  colDefault: true,
  width: 120
}, {
  title: "职务",
  dataIndex: "position",
  key: "position",
  colDefault: true,
  width: 100
}];
import { getIncomeTax } from "@/api/tax/tax";
import moment from "moment";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
export default {
  mixins: [mixin],
  data: function data() {
    return {
      abolitionImg: require("@/assets/home/lizhi.png"),
      employmentType: employmentType,
      certificate: certificate,
      inputName: null,
      loading: false,
      selectedRowKeys: [],
      selectRowKey: [],
      dataList: [],
      columns: columns,
      empStatus: undefined,
      empIdList: [],
      groupList: [],
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      search1: "",
      search2: undefined
    };
  },
  created: function created() {
    this.getIncomeTaxList();
  },
  beforeDestory: function beforeDestory() {},
  methods: {
    handleChange: function handleChange(e) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.empStatus = e;
      this.getIncomeTaxList();
    },
    onSelectChange: function onSelectChange(selectedRowKeys, e) {
      var _this = this;
      this.empIdList = [];
      this.selectedRowKeys = selectedRowKeys;
      e.forEach(function (item, index) {
        _this.empIdList.push(item.empId);
      });
    },
    download: function download(i) {
      var token = this.$store.state.auth.token;
      if (i === 2) {
        this.exportData = {
          companyId: this.companyId,
          empIdList: [],
          status: this.empStatus ? this.empStatus : undefined,
          nameOrPhoneOrIdCard: this.inputName ? this.inputName : undefined
        };
      } else {
        this.exportData = {
          companyId: this.companyId,
          empIdList: this.empIdList
        };
      }
      this.url = "/salary/api/v1/specialAdditionalDetails/exportTaxPersonalInformation";
      this.excelName = "".concat(this.$store.state.hr.companyName, "_") + "个税人员信息申报导出详情" + ".xlsx";
      this.postDownload();
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getIncomeTaxList();
    },
    getIncomeTaxList: function getIncomeTaxList() {
      var _this2 = this;
      this.loading = true;
      getIncomeTax({
        status: this.empStatus,
        name: this.inputName,
        companyId: this.companyId,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        "orders[0].property": "status",
        "orders[0].desc": true
      }).then(function (res) {
        _this2.selectedRowKeys = [];
        _this2.loading = false;
        _this2.dataList = res.data.entities || [];
        _this2.pagination.total = res.data && res.data.entityCount;
      });
    },
    inputChange: function inputChange(e) {
      this.inputName = e;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getIncomeTaxList();
    }
  }
};