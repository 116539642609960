var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.tempData ? _c("DynamicForm", {
    ref: "form",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.tempData,
      editable: _vm.formJson.editable,
      dynamicData: {},
      formFields: _vm.formJson.fields,
      sortedKeys: _vm.formJson.sortedKeys,
      formConfig: {},
      showBtns: false,
      formLayout: "vertical"
    },
    on: {
      change: function change($event) {
        return _vm.dynamicChange($event, "formJson");
      }
    }
  }) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };