export var taskStatusDict = {
  0: "未开始",
  1: "进行中",
  2: "暂停",
  3: "完成"
};
export var taskResultDict = {
  0: "失败",
  1: "成功"
};
export var iconDict = {
  contractPublish: require("./images/launch.png"),
  gongsiqianshu: require("./images/launch.png"),
  empImport: require("./images/issue.png")
};
export var textDict = {
  contractPublish: "发起",
  gongsiqianshu: "签署",
  empImport: "导入"
};
export var fulTextDict = {
  contractPublish: "批量发起合同",
  gongsiqianshu: "公司签署合同",
  empImport: "花名册导入"
};