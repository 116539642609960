import "core-js/modules/es.array.map.js";
import ShebaoJiedanTable from "./table.vue";
var hjLst = [{
  title: "总接单人数",
  key: "allCount",
  icon: require("@/assets/icons/h.png"),
  ff: null,
  color: "var(--primary)"
}, {
  title: "待反馈人数",
  key: "toBeFedBackCount",
  icon: require("@/assets/icons/订单4.png"),
  ff: -1,
  color: "rgba(255, 187, 0, 1)"
}, {
  title: "已反馈人数",
  key: "succeedFeedbackCount",
  icon: require("@/assets/icons/订单3.png"),
  ff: 1,
  color: "rgba(50, 204, 170, 1)"
}, {
  title: "失败反馈人数",
  key: "failFeedbackCount",
  icon: require("@/assets/icons/订单2.png"),
  ff: 2,
  color: "rgba(255, 112, 133, 1)"
}];
export default {
  name: "",
  components: {
    ShebaoJiedanTable: ShebaoJiedanTable
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      hjLst: hjLst,
      nameOrIdCard: "",
      dateRange: null,
      beginTime: "",
      endTime: "",
      countDetail: null,
      feedbackFlag: null,
      selectedRowKeys: []
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.loadCounts();
  },
  mounted: function mounted() {},
  methods: {
    doBatch: function doBatch() {
      if (this.$refs.table) {
        this.$refs.table.doBatch();
      }
    },
    setFeedbackFlag: function setFeedbackFlag(val) {
      this.feedbackFlag = val;
    },
    loadCounts: function loadCounts() {
      var _this = this;
      var params = {};
      if (this.nameOrIdCard) {
        params["nameOrIdCard"] = this.nameOrIdCard;
      }
      if (this.beginTime) {
        params["beginTime"] = this.beginTime;
      }
      if (this.endTime) {
        params["endTime"] = this.endTime;
      }
      this.$request({
        url: "/hr/api/v1/socPlanDispatchUsers/taskOrderUserCount",
        params: params
      }).then(function (res) {
        _this.countDetail = res.data;
      }).catch(function (err) {
        console.log("taskOrderUserCount", err);
      });
    },
    onPanelChange: function onPanelChange(value) {
      this.dateRange = value;
      if (this.dateRange && this.dateRange.length > 0) {
        var dates = this.dateRange.map(function (item) {
          return item.format("YYYY-MM");
        });
        this.beginTime = dates[0];
        this.endTime = dates[1];
        this.loadCounts();
      }
    }
  }
};