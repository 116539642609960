import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import { addPeople, getPeopleList as _getPeopleList, deletePeople as _deletePeople, editPeople, getPeopleDetail } from "./api";
import QrcodeForPay from "@/components/qrcodeForPay";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
var columns = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  width: 100,
  fixed: "left",
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 120
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  width: 100
}, {
  title: "支付宝账号",
  dataIndex: "zfbIdentity",
  key: "zfbIdentity",
  scopedSlots: {
    customRender: "zfbIdentity"
  },
  width: 140
}, {
  title: "微信openId",
  dataIndex: "vxIdentity",
  key: "vxIdentity",
  scopedSlots: {
    customRender: "vxIdentity"
  },
  width: 140
}, {
  title: "银行卡号",
  dataIndex: "bankIdentity",
  key: "bankIdentity",
  scopedSlots: {
    customRender: "bankIdentity"
  },
  width: 140
}, {
  title: "银行名称",
  dataIndex: "openingBank",
  key: "openingBank",
  scopedSlots: {
    customRender: "openingBank"
  },
  width: 140
}, {
  title: "操作",
  dataIndex: "action",
  key: "action",
  width: 140,
  scopedSlots: {
    customRender: "action"
  },
  align: "center",
  fixed: "right"
}];
var detailColumns = [{
  title: "发薪时间",
  dataIndex: "payTime",
  key: "payTime",
  width: 100,
  scopedSlots: {
    customRender: "payTime"
  }
}, {
  title: "所属发薪批次名称",
  dataIndex: "orderTitle",
  key: "orderTitle",
  width: 120
}, {
  title: "发薪方式",
  dataIndex: "type",
  key: "type",
  width: 100,
  scopedSlots: {
    customRender: "type"
  }
}, {
  title: "发薪金额",
  dataIndex: "money",
  key: "money",
  scopedSlots: {
    customRender: "money"
  },
  width: 120
}, {
  title: "单笔交易流水号",
  dataIndex: "thirdPartyInteriorDetailId",
  key: "thirdPartyInteriorDetailId",
  width: 100,
  scopedSlots: {
    customRender: "thirdPartyInteriorDetailId"
  }
}];
export default {
  mixins: [mixin],
  components: {
    QrcodeForPay: QrcodeForPay,
    EmployeeSelect: function EmployeeSelect() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/components/EmployeeSelect2/employeeSelect-xinzi"));
      });
    }
  },
  data: function data() {
    return {
      columns: columns,
      nameOrPhoneOrIdCard: "",
      detailColumns: detailColumns,
      detailList: [],
      loading: false,
      dataList: [],
      form: {},
      editForm: {},
      editFormCopy: {},
      editState: false,
      empVisible: false,
      detailVisible: false,
      labelCol: {
        span: 6
      },
      selectedList: [],
      wrapperCol: {
        span: 14
      },
      empSelectVisible: false,
      companyId: this.$store.state.hr.companyId,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      pagination2: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      }
    };
  },
  created: function created() {
    this.getPeopleList();
  },
  methods: {
    onEmployeeSelectChange: function onEmployeeSelectChange() {
      if (!this.selectedList) {
        this.$message.warning("请选择人员");
      } else {
        var arr = [];
        this.selectedList.forEach(function (item) {
          arr.push(item.id);
        });
        this.exportData = {
          idList: arr
        };
        this.url = "/hr/api/v1/transferAccountsPeoples/exportTransferAccountsPeopleList";
        this.excelName = "人员库导出详情" + ".xlsx";
        this.postDownload();
        this.selectedList = [];
      }
    },
    handleMenuClick: function handleMenuClick(e) {
      if (e.key === "1") {
        this.exportData = {
          nameOrPhoneOrIdCard: this.nameOrPhoneOrIdCard ? this.nameOrPhoneOrIdCard : undefined
        };
        this.url = "/hr/api/v1/transferAccountsPeoples/exportTransferAccountsPeopleList";
        this.excelName = "人员库导出详情" + ".xlsx";
        this.postDownload();
      } else if (e.key === "2") {
        this.empSelectVisible = true;
      }
    },
    showQrcode: function showQrcode() {
      if (this.$refs.infoCard) {
        var info = this.$refs.infoCard.getInfo();
        return info;
      }
      return false;
    },
    editCancel: function editCancel() {
      this.editState = false;
      this.editForm = JSON.parse(JSON.stringify(this.editFormCopy));
    },
    editOk: function editOk() {
      var _this = this;
      if (!this.editForm.name) {
        this.$message.warning("姓名不能为空！");
      } else {
        editPeople({
          id: this.editForm.id,
          // bankIdentity: this.editForm.bankIdentity,
          idCard: this.editForm.idCard,
          name: this.editForm.name,
          phone: this.editForm.phone,
          zfbIdentity: this.editForm.zfbIdentity,
          bankIdentity: this.editForm.bankIdentity
          // openingBank: this.editForm.openingBank
        }).then(function (res) {
          _this.$notification["success"]({
            message: "success",
            description: "保存成功"
          });
          _this.getPeopleList();
          _this.editFormCopy = JSON.parse(JSON.stringify(_this.editForm));
          _this.editState = false;
        });
      }
    },
    deletePeople: function deletePeople(e) {
      var _this2 = this;
      _deletePeople(e.id).then(function (res) {
        _this2.$notification["success"]({
          message: "success",
          description: "删除成功"
        });
        _this2.getPeopleList();
      });
    },
    toEditState: function toEditState() {
      this.editState = true;
    },
    addDetailOk: function addDetailOk() {},
    showDetail: function showDetail(record) {
      var _this3 = this;
      this.detailVisible = true;
      this.editForm = JSON.parse(JSON.stringify(record));
      this.editFormCopy = JSON.parse(JSON.stringify(record));
      this.editState = false;
      getPeopleDetail({
        peopleId: this.editForm.id,
        pageNo: 1,
        pageSize: 10
      }).then(function (res) {
        _this3.pagination2.total = res.data && res.data.entityCount;
        _this3.detailList = res.data.entities;
      });
    },
    getPeopleList: function getPeopleList() {
      var _this4 = this;
      var par = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        nameOrPhoneOrIdCard: this.nameOrPhoneOrIdCard ? this.nameOrPhoneOrIdCard : null
      };
      _getPeopleList(par).then(function (res) {
        _this4.pagination.total = res.data && res.data.entityCount;
        _this4.dataList = res.data.entities;
        if (res.data.entities.length === 0 && _this4.pagination.current > 1) {
          // 当本页非第一页（比第一页大）时，删除最后一条后台会返回当页页码，
          // 但是本页数据为空，table组件会展示为当前页的上一页数据为空，需要加加判断再拉一下上页数据
          _this4.pagination.current = _this4.pagination.current - 1;
          _this4.pagination.pageSize = 10;
          _this4.getPeopleList();
        }
      });
    },
    addEmpOk: function addEmpOk() {
      var _this5 = this;
      this.$refs.addForm.validate(function (valid) {
        if (valid) {
          addPeople(_objectSpread({}, _this5.form)).then(function (res) {
            _this5.$notification["success"]({
              message: "success",
              description: "新增成功"
            });
            _this5.empVisible = false;
            // setTimeout(() => {
            //   this.$refs.addForm.resetFields();
            // });
            _this5.getPeopleList();
          });
        }
      });
    },
    addEmpCancel: function addEmpCancel() {
      this.empVisible = false;
    },
    addEmp: function addEmp() {
      var _this6 = this;
      this.empVisible = true;
      this.$nextTick(function () {
        _this6.$refs.addForm.clearValidate();
      });
      this.form = {};
    },
    onSearch: function onSearch(e) {
      this.nameOrPhoneOrIdCard = e;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getPeopleList();
    },
    editItem: function editItem() {},
    deleteItem: function deleteItem() {},
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getPeopleList();
    },
    handleTableChange2: function handleTableChange2(pagination, filters, sorter) {
      var _this7 = this;
      this.pagination2.current = pagination.current;
      this.pagination2.pageSize = pagination.pageSize;
      getPeopleDetail({
        peopleId: this.editForm.id,
        pageNo: this.pagination2.current,
        pageSize: this.pagination2.pageSize
      }).then(function (res) {
        _this7.pagination2.total = res.data && res.data.entityCount;
        _this7.detailList = res.data.entities;
      });
    }
  }
};