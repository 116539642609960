import "core-js/modules/es.array.push.js";
import Navbar from "./component/navbar";
import Sidebar from "@/layout/components/sidebar/adminNew";
import AppMain from "@/layout/components/appmain/adminNew";
export default {
  components: {
    Navbar: Navbar,
    Sidebar: Sidebar,
    AppMain: AppMain
  },
  data: function data() {
    return {
      menuState: !this.$route.query.app_auth_code
    };
  },
  watch: {
    $route: function $route(to) {
      var _to$meta;
      if ((_to$meta = to.meta) !== null && _to$meta !== void 0 && _to$meta.route) {
        sessionStorage.setItem("clientelePage", to.meta.route);
      }
    }
  },
  computed: {
    adminMenu: function adminMenu() {
      var arr = [];
      if (this.$hasPermission("clientele:myClientele:query")) {
        arr.push({
          icon: "",
          name: "我的客户",
          router: "/clientele/myClientele"
        });
      }
      if (this.$hasPermission("clientele:sea:query")) {
        arr.push({
          icon: "",
          name: "客户公海",
          router: "/clientele/sea"
        });
      }
      if (this.$hasPermission("clientele:member:query")) {
        arr.push({
          icon: "",
          name: "成员管理",
          router: "/clientele/member"
        });
      }
      return arr;
    }
  },
  destroyed: function destroyed() {
    sessionStorage.setItem("clientelePage", "");
  }
};