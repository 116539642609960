import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { getToken } from "xqjr-module-auth";
import { UploadSiteFile } from "xqjr-plugin-common";
import { getEmpFieldValue, empFieldValueSavePut } from "./info/api";
export default {
  components: {
    UploadSiteFile: UploadSiteFile,
    Uploading: function Uploading() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/components/Uploading/uploadingMaterial"));
      });
    }
  },
  props: {},
  data: function data() {
    return {
      accessoryData: {},
      edit: null,
      query: {}
    };
  },
  created: function created() {
    this.query = this.$route.query;
    this.id = this.$store.state.hr.companyId;
    this.getEmpFieldValues();
  },
  methods: {
    // 各项数据源
    getEmpFieldValues: function getEmpFieldValues() {
      var _this = this;
      getEmpFieldValue({
        moduleCode: this.query.sign,
        companyId: this.$store.state.hr.companyId,
        empInfoId: this.query.employInfoId
      }).then(function (result) {
        _this.accessoryData = result.data.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    downloadImg: function downloadImg() {
      this.$refs.uploadTool.download();
    },
    onFileChange: function onFileChange(urls, others) {
      var _this2 = this;
      empFieldValueSavePut({
        companyId: this.$store.state.hr.companyId,
        empInfoId: this.query.employInfoId,
        moduleCode: this.query.sign,
        data: _defineProperty({}, this.query.fieldCode, urls)
      }, "?isVerify=true").then(function (result) {
        _this2.$message.success("修改成功!");
      }).catch(function (err) {
        console.log("debug log --> ", err);
      }).finally(function () {
        _this2.getEmpFieldValues();
      });
    },
    goback: function goback() {
      var that = this;
      if (this.$refs.operation.getReturnOperation()) {
        this.$router.go(-1);
      } else {
        this.$confirm({
          title: "上传附件尚未保存，是否确认离开",
          okText: "确认",
          onOk: function onOk() {
            that.$router.go(-1);
          }
        });
      }
    },
    pageGetToken: function pageGetToken() {
      return getToken();
    }
  }
};