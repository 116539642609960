import request from "@/utils/request";

// export function getSaveCustomer(data) {
//   return request({
//     url: "/hr/api/v1/crmCustomerInfos/saveCustomer",
//     method: "POST",
//     data
//   });
// }
export function getQueryCustomerInfo(params) {
  return request({
    url: "/hr/api/v1/crmCustomerInfos/queryCustomerInfo",
    method: "GET",
    params: params
  });
}
export function getCrmCustomerDistributionRecords(params) {
  return request({
    url: "/hr/api/v1/crmCustomerDistributionRecords/listAll",
    method: "GET",
    params: params
  });
}
export function getCrmVisitRecords(params) {
  return request({
    url: "/hr/api/v1/crmVisitRecords",
    method: "GET",
    params: params
  });
}
export function postCrmVisitRecords(data) {
  return request({
    url: "/hr/api/v1/crmVisitRecords",
    method: "POST",
    data: data
  });
}