import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import { templateDetailReq } from "@/api/company/contract";
export default {
  data: function data() {
    return {
      template: {},
      contractInfo: {},
      htmlContent: ""
    };
  },
  created: function created() {
    this.getDetailBycategoryId(this.$route.query.id);
  },
  methods: {
    getDetailBycategoryId: function getDetailBycategoryId(id) {
      var _this = this;
      templateDetailReq(id).then(function (res) {
        var content = res.data.content;
        // if(content.indexOf("[\u4e00-\u9fa5]") > 0){
        var varibleKeys = content.match(/#[\u4e00-\u9fa5]+#/g);
        _this.contractInfo = JSON.parse(localStorage.getItem("contractInfo"));
        var varibles = _this.contractInfo.conVariableMap;
        varibleKeys.map(function (key) {
          var keyTemp = key.replace(/#/g, "");
          var idx = varibles.findIndex(function (item) {
            return item[keyTemp];
          });
          if (idx > -1) {
            content = content.replace(new RegExp("#".concat(_this.tranHanzi(keyTemp), "#"), "g"), varibles[idx][keyTemp]["content"]);
          }
        });
        // }
        _this.htmlContent = content;
      });
    },
    tranHanzi: function tranHanzi(str) {
      return escape(str).toLocaleLowerCase().replace(/%u/gi, "\\u");
    },
    goback: function goback() {
      this.$router.go(-1);
    }
  }
};