var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", [_c("a-input-search", {
    staticClass: "mg_r24",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "搜索员工"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.names,
      callback: function callback($$v) {
        _vm.names = $$v;
      },
      expression: "names"
    }
  }), _c("a-button", {
    staticClass: "mg_l24",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.reload
    }
  }, [_vm._v("清空")])], 1), _vm.isShow !== false ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.payslip
    }
  }, [_vm._v(" 发放工资条 ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };