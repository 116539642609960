import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import { saleServerCostRecordsReq } from "../api";
import { feeColumns } from "../funcs";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      feeColumns: feeColumns,
      recordLst: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      companyName: "",
      dateRange: null
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.onSearchChange();
  },
  methods: {
    goImport: function goImport() {
      this.$router.push("/admin/kehufuwufeijilu/upload");
    },
    onInputChange: function onInputChange() {
      this.pagination.current = 1;
      this.onSearchChange();
    },
    onPanelChange: function onPanelChange(value) {
      this.dateRange = value;
      this.pagination.current = 1;
      this.onSearchChange();
    },
    onTableChange: function onTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.onSearchChange();
    },
    onSearchChange: function onSearchChange() {
      var _this = this;
      var params = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      if (this.companyName) {
        params["companyName"] = this.companyName;
      }
      if (this.dateRange && this.dateRange.length > 0) {
        var dates = this.dateRange.map(function (item) {
          return item.format("YYYY-MM");
        });
        params["beginTime"] = dates[0];
        params["endTime"] = dates[1];
      }
      saleServerCostRecordsReq(params).then(function (res) {
        _this.recordLst = res.data.entities;
        _this.pagination.total = res.data.entityCount;
      }).catch(function (err) {
        console.log("saleServerCostRecordsReq", err);
      });
    }
  }
};