import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import { columns, levelOptions, levelOptionsDict } from "./funcs";
import SelectCompany from "@/components/SelectCompany";
import _ from "lodash";
import { getFileName } from "@/utils/tools";
export default {
  name: "",
  components: {
    SelectCompany: SelectCompany
  },
  mixins: [],
  props: {
    currentGroup: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      levelOptions: levelOptions,
      levelOptionsDict: levelOptionsDict,
      columns: columns,
      dataSource: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      subjectId: "-1",
      subjectType: "site",
      searchPolicyLevel: undefined,
      searchTitle: "",
      loading: false,
      dragSource: null,
      dragTarget: null
    };
  },
  computed: {},
  watch: {
    subjectId: function subjectId() {
      this.pagination.current = 1;
      this.onSearchChange();
    },
    currentGroup: {
      handler: function handler() {
        this.pagination.current = 1;
        this.onSearchChange();
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    customRow: function customRow(record) {
      var _this = this;
      return {
        props: {},
        style: {
          cursor: "pointer"
        },
        on: {
          mouseenter: function mouseenter(event) {
            var evt = event || window.event;
            evt.target.draggable = true;
          },
          dragstart: function dragstart(event) {
            var evt = event || window.event;
            evt.stopPropagation();
            _this.dragSource = record;
          },
          dragover: function dragover(event) {
            var evt = event || window.event;
            evt.preventDefault();
          },
          drop: function drop(event) {
            var evt = event || window.event;
            evt.stopPropagation();
            _this.dragTarget = record;
            _this.$request({
              url: "/hr/api/v1/policyCouriers/updateSort",
              method: "put",
              data: [{
                id: _this.dragSource.id,
                sort: _this.dragTarget.sort
              }, {
                id: _this.dragTarget.id,
                sort: _this.dragSource.sort
              }]
            }).then(function () {
              _this.onSearchChange();
            }).catch(function () {});
          }
        }
      };
    },
    annexTip: function annexTip(t) {
      return t.split(",").map(function (url) {
        return getFileName(url, true);
      }).join("，");
    },
    annexText: function annexText(t) {
      var urls = t.split(",");
      return getFileName(urls[0], true) + "\u7B49 ".concat(urls.length, " \u4E2A");
    },
    onSearchTitleChange: _.throttle(function () {
      this.pagination.current = 1;
      this.onSearchChange();
    }, 1000),
    onSearchPolicyLevelChange: function onSearchPolicyLevelChange() {
      this.pagination.current = 1;
      this.onSearchChange();
    },
    goEdit: function goEdit(record, page) {
      localStorage.setItem("tempZhengce", JSON.stringify(record));
      if (page === "detail") {
        this.$router.push("/admin/zhengcesudi/detail?id=".concat(record.id, "&tId=").concat(this.currentGroup.code));
      } else {
        this.$router.push("/admin/zhengcesudi/edit?id=".concat(record.id, "&tId=").concat(this.currentGroup.code));
      }
    },
    goAdd: function goAdd() {
      this.$router.push("/admin/zhengcesudi/edit?tId=".concat(this.currentGroup.code));
    },
    doDelete: function doDelete(policyCourierId) {
      var _this2 = this;
      this.$request({
        url: "/hr/api/v1/policyCouriers/".concat(policyCourierId),
        method: "delete"
      }).then(function (res) {
        _this2.$message.success("已删除");
        _this2.onSearchChange();
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleTableChange: function handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.onSearchChange();
    },
    onSearchChange: function onSearchChange() {
      var _this3 = this;
      if (this.loading) {
        return false;
      }
      this.loading = true;
      var reqData = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        typeCode: this.currentGroup.code,
        TypeAndId: {
          id: this.subjectId,
          type: this.subjectType
        }
      };
      if (this.searchPolicyLevel) {
        reqData["policyLevel"] = this.searchPolicyLevel;
      }
      if (this.searchTitle) {
        reqData["title"] = this.searchTitle;
      }
      this.$request({
        url: "/hr/api/v1/policyCouriers/selectPage",
        method: "post",
        data: reqData
      }).then(function (res) {
        _this3.dataSource = res.data.entities;
        _this3.pagination.total = res.data.entityCount;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    }
  }
};