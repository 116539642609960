import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "hrManagementBox page_set page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "headerBox"
  }, _vm._l(_vm.tabList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "headerBoxItem",
      class: item.id === _vm.selectId ? "backF4F" : "",
      on: {
        click: function click($event) {
          return _vm.tapChange(item.id);
        }
      }
    }, [_c("div", {
      staticClass: "headerBoxItemHei26"
    }, [_c("div", {
      staticClass: "headerBoxItemHeiBox"
    }, [_c("img", {
      staticClass: "headerBoxItemHeiImg",
      attrs: {
        src: item.img,
        alt: ""
      }
    }), _c("div", [_c("div", {
      staticClass: "cola6a"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "col55565D"
    }, [_vm._v(_vm._s(item.count))])])])])]);
  }), 0), _c("div", [_c("div", {
    staticClass: "button-group",
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c("div", {
    staticClass: "ml12 mr12",
    staticStyle: {
      display: "inline-block",
      width: "180px"
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "180px"
    },
    attrs: {
      id: "nameOrPhone",
      placeholder: "姓名/手机号/身份证号",
      defaultValue: _vm.searchForm.nameOrPhone
    },
    on: {
      blur: _vm.select
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.select.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchForm.nameOrPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "nameOrPhone", $$v);
      },
      expression: "searchForm.nameOrPhone"
    }
  })], 1), _vm.selectId === "1" ? _c("a-select", {
    staticStyle: {
      "min-width": "130px",
      width: "130px",
      "margin-right": "12px"
    },
    attrs: {
      title: "",
      placeholder: "选择人员状态",
      "allow-clear": "",
      defaultValue: _vm.searchForm.approvalAndStatus
    },
    on: {
      change: _vm.handleProgName2
    }
  }, _vm._l(_vm.liststatus, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.value))]);
  }), 1) : _vm._e(), _vm.selectId === "1" ? _c("a-range-picker", {
    staticClass: "mr12",
    attrs: {
      placeholder: ["入职开始时间", "入职结束时间"],
      defaultValue: _vm.searchForm.preJoinDateMoment
    },
    on: {
      change: _vm.search
    },
    model: {
      value: _vm.searchForm.preJoinDateMoment,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "preJoinDateMoment", $$v);
      },
      expression: "searchForm.preJoinDateMoment"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1) : _vm._e(), _vm.selectId === "2" ? _c("a-range-picker", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      placeholder: ["预计转正开始时间", "预计转正结束时间"],
      defaultValue: _vm.searchForm.turnStraightDateMoment
    },
    on: {
      change: _vm.search
    },
    model: {
      value: _vm.searchForm.turnStraightDateMoment,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "turnStraightDateMoment", $$v);
      },
      expression: "searchForm.turnStraightDateMoment"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1) : _vm._e(), _vm.selectId === "3" ? _c("a-range-picker", {
    staticClass: "mr12",
    attrs: {
      placeholder: ["入职开始时间", "入职结束时间"],
      defaultValue: _vm.searchForm.joinDateMoment
    },
    on: {
      change: _vm.search
    },
    model: {
      value: _vm.searchForm.joinDateMoment,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "joinDateMoment", $$v);
      },
      expression: "searchForm.joinDateMoment"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1) : _vm._e(), _vm.selectId === "3" ? _c("a-range-picker", {
    attrs: {
      placeholder: ["预计转正开始时间", "预计转正结束时间"],
      defaultValue: _vm.searchForm.turnStraightDateMoment
    },
    on: {
      change: _vm.search
    },
    model: {
      value: _vm.searchForm.turnStraightDateMoment,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "turnStraightDateMoment", $$v);
      },
      expression: "searchForm.turnStraightDateMoment"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1) : _vm._e(), _vm.selectId === "4" ? _c("a-range-picker", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      placeholder: ["离职开始时间", "离职结束时间"],
      defaultValue: _vm.searchForm.leaveDateMoment
    },
    on: {
      change: _vm.search
    },
    model: {
      value: _vm.searchForm.leaveDateMoment,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "leaveDateMoment", $$v);
      },
      expression: "searchForm.leaveDateMoment"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1) : _vm._e(), _vm.selectId === "5" ? _c("a-range-picker", {
    attrs: {
      placeholder: ["调岗开始时间", "调岗结束时间"],
      defaultValue: _vm.searchForm.transferDateMoment
    },
    on: {
      change: _vm.search
    },
    model: {
      value: _vm.searchForm.transferDateMoment,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "transferDateMoment", $$v);
      },
      expression: "searchForm.transferDateMoment"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1) : _vm._e(), _vm.selectId === "1" ? _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: _vm.addMember
    }
  }, [_vm._v("添加待入职")]) : _vm._e(), _vm.selectId === "1" ? _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: function click($event) {
        return _vm.openEmployeesMove("批量到岗");
      }
    }
  }, [_vm._v("批量到岗")]) : _vm._e(), _vm.selectId === "1" ? _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: function click($event) {
        return _vm.openEmployeesMove("批量未到岗");
      }
    }
  }, [_vm._v("批量未到岗")]) : _vm._e(), _vm.selectId === "2" ? _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.openEmployeesMove("批量离职");
      }
    }
  }, [_vm._v("批量离职")]) : _vm._e(), _vm.selectId === "2" ? _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.openEmployeesMove("批量转正");
      }
    }
  }, [_vm._v("批量转正")]) : _vm._e(), _vm.selectId === "4" ? _c("a-dropdown", {
    staticClass: "fr",
    staticStyle: {
      width: "110px"
    },
    attrs: {
      type: "primary",
      ghost: ""
    }
  }, [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleChangeStaff
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "namePinyin"
  }, [_vm._v("发起离职合同")]), _c("a-menu-item", {
    key: "gmtCreate"
  }, [_vm._v("导入Excel办理")]), _c("a-menu-item", {
    key: "gmtModified"
  }, [_vm._v("已离职记录")])], 1), _c("a-button", [_vm._v(" 更多功能 "), _c("a-icon", {
    attrs: {
      type: "down"
    }
  })], 1)], 1) : _vm._e(), _vm.selectId === "3" ? _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.openEmployeesMove("批量调岗");
      }
    }
  }, [_vm._v("批量调岗")]) : _vm._e(), _vm.selectId === "3" ? _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.merberBatchLZ("批量离职");
      }
    }
  }, [_vm._v("批量离职")]) : _vm._e(), _vm.selectId === "4" ? _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: _vm.merberLeave
    }
  }, [_vm._v("办理离职")]) : _vm._e(), _vm.selectId === "4" ? _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.batchLeave("批量确认离职");
      }
    }
  }, [_vm._v("批量确认离职")]) : _vm._e(), _vm.selectId === "4" ? _c("a-button", {
    staticClass: "fr mr12",
    on: {
      click: function click($event) {
        return _vm.openEmployeesMove("批量撤销离职");
      }
    }
  }, [_vm._v("批量撤销离职")]) : _vm._e(), _vm.selectId === "5" ? _c("a-button", {
    staticClass: "fr",
    on: {
      click: function click($event) {
        return _vm.goto("moveTag");
      }
    }
  }, [_vm._v("已调岗记录")]) : _vm._e(), _vm.selectId === "5" ? _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: function click($event) {
        return _vm.goto("visibleMoveDrawer");
      }
    }
  }, [_vm._v("办理调岗")]) : _vm._e(), _vm.selectId === "5" ? _c("a-button", {
    staticClass: "fr mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.openEmployeesMove("批量确认调岗");
      }
    }
  }, [_vm._v("批量确认调岗")]) : _vm._e(), _vm.selectId === "5" ? _c("a-button", {
    staticClass: "fr mr12",
    on: {
      click: function click($event) {
        return _vm.openEmployeesMove("批量撤销调岗");
      }
    }
  }, [_vm._v("批量撤销调岗")]) : _vm._e()], 1), _c("a-modal", {
    attrs: {
      title: _vm.isBatchLeaveConfirm ? "批量确认离职" : "确认离职",
      visible: _vm.isCloseMethod,
      width: "620px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.finishEdit
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticStyle: {
      padding: "0 56px"
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "姓名"
    }
  }, [_vm.isBatchLeaveConfirm ? _c("span", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v(_vm._s(_vm.formatLeaveEmyListNames(_vm.leaveEmyList)))]) : _c("span", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v(_vm._s(_vm.leaveEmy ? _vm.leaveEmy.name : ""))])]), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "确认离职日期"
    }
  }, [_vm.isBatchLeaveConfirm ? _c("a-date-picker", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "default-value": _vm.batchPreQuitDate,
      allowClear: false,
      "disabled-date": _vm.disabledDateRegularDate
    },
    on: {
      change: _vm.onLeaveChange
    }
  }) : _c("a-date-picker", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "default-value": _vm.leaveEmy ? _vm.leaveEmy.preQuitDate ? _vm.moment(_vm.leaveEmy.preQuitDate).format(_vm.leaveFormat) : null : null,
      allowClear: false,
      "disabled-date": _vm.disabledDateRegularDate
    },
    on: {
      change: _vm.onLeaveChange
    }
  })], 1), !_vm.isBatchLeaveConfirm ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "是否发起离职合同"
    }
  }, [_c("a-switch", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "default-checked": _vm.isHeTong
    },
    on: {
      change: _vm.onHtChange
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "是否进行社保减员"
    }
  }, [_vm.hasShebao ? _c("a-switch", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "default-checked": _vm.isShebaoJianYuan
    },
    on: {
      change: _vm.onJYChange
    }
  }) : _vm._e(), !_vm.hasShebao ? _c("div", {
    staticClass: "rowdialog"
  }, [_c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-left": "10px"
    },
    attrs: {
      src: require("@/assets/img/tanhao.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "14px",
      "margin-left": "5px"
    }
  }, [_vm._v("员工未缴纳社保")])]) : _vm._e()], 1), _vm.hasShebao && _vm.isShebaoJianYuan ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "社保停保起始年月"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "default-value": _vm.moment(_vm.shebaoDate, _vm.monthFormat),
      allowClear: false,
      "disabled-date": _vm.disabledDate2
    },
    on: {
      change: _vm.onYearMonthChange_SB
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "是否进行医保减员"
    }
  }, [_vm.hasYibao ? _c("a-switch", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "default-checked": _vm.isYibaoJianYuan
    },
    on: {
      change: _vm.onJyChange2
    }
  }) : _vm._e(), !_vm.hasYibao ? _c("div", {
    staticClass: "rowdialog"
  }, [_c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-left": "10px"
    },
    attrs: {
      src: require("@/assets/img/tanhao.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "14px",
      "margin-left": "5px"
    }
  }, [_vm._v("员工未缴纳医保")])]) : _vm._e()], 1), _vm.hasYibao && _vm.isYibaoJianYuan ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "医保停保起始年月"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "default-value": _vm.moment(_vm.yibaoDate, _vm.monthFormat),
      allowClear: false,
      "disabled-date": _vm.disabledDate2
    },
    on: {
      change: _vm.onYearMonthChange_YB
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "是否进行公积金封存"
    }
  }, [_vm.hasGjj ? _c("a-switch", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "default-checked": _vm.isGjjJianYuan
    },
    on: {
      change: _vm.onJY2Change
    }
  }) : _vm._e(), !_vm.hasGjj ? _c("div", {
    staticClass: "rowdialog"
  }, [_c("img", {
    staticStyle: {
      width: "14px",
      height: "14px",
      "margin-left": "10px"
    },
    attrs: {
      src: require("@/assets/img/tanhao.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "14px",
      "margin-left": "5px"
    }
  }, [_vm._v("员工未缴纳公积金")])]) : _vm._e()], 1), _vm.hasGjj && _vm.isGjjJianYuan ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "公积金封存起始年月"
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "default-value": _vm.moment(_vm.gongjijinDate, _vm.monthFormat),
      allowClear: false,
      "disabled-date": _vm.disabledDate2
    },
    on: {
      change: _vm.onYearMonthChange_GJJ
    }
  }), _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "10px",
      "margin-top": "-10px",
      "margin-left": "18px"
    }
  }, [_vm._v(" 如仅停缴公积金，请前往新建无参保公积金方案 ")])], 1) : _vm._e(), _vm.hasGjj || _vm.hasShebao || _vm.hasYibao ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "服务费"
    }
  }, [_c("a-input-number", {
    staticStyle: {
      "margin-left": "10px",
      width: "180px"
    },
    attrs: {
      min: 0,
      precision: 2,
      disabled: (_vm.isShebaoJianYuan || _vm.isGjjJianYuan || _vm.isYibaoJianYuan) === false
    },
    model: {
      value: _vm.serviceCost,
      callback: function callback($$v) {
        _vm.serviceCost = $$v;
      },
      expression: "serviceCost"
    }
  }), _c("div", {
    staticStyle: {
      color: "#a6a8b4",
      "font-size": "10px",
      "margin-top": "-10px",
      "margin-left": "18px"
    }
  }, [_vm._v(" 服务费不填写默认不更改，填0即服务费改为0 ")])], 1) : _vm._e()], 1)], 1)], 2), _c("div", {
    staticClass: "table"
  }, [_c("a-table", {
    attrs: {
      scroll: {
        x: 1200
      },
      columns: _vm.managementList.title,
      "row-key": function rowKey(record) {
        return record.id;
      },
      "data-source": _vm.managementList.data,
      pagination: _vm.managementList.pagination,
      loading: _vm.managementList.loading,
      size: "middle"
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function fn(text, record) {
        return [_c("div", [_vm.selectId != "0" ? _c("span", {
          style: "color:" + _vm.getColor(text, record, _vm.selectId)
        }, [_vm._v("•")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.getEmployeeStatus(text, record, _vm.selectId)) + " ")])];
      }
    }, {
      key: "operate",
      fn: function fn(text, record) {
        return [_vm.selectId == 0 ? _c("div", [record.userId == "" || record.userId == null || record.userId == undefined ? _c("a-button", {
          staticStyle: {
            "padding-left": "0",
            "padding-right": "13px"
          },
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.openUser(record);
            }
          }
        }, [_vm._v("开通账号")]) : _c("span", [_vm._v("已开通账号")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.detail(record, 7);
            }
          }
        }, [_vm._v("详情")])], 1) : _vm._e(), _vm.selectId == 1 ? _c("div", [_vm.getEmployeeStatus(text, record, _vm.selectId) === "待入职" ? _c("a-button", {
          staticStyle: {
            "padding-left": "0"
          },
          attrs: {
            disabled: record.approvalStatus != 4 && record.approvalStatus != 5,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.confirmJoin(record);
            }
          }
        }, [_vm._v("确认到岗")]) : _vm._e(), _vm.getEmployeeStatus(text, record, _vm.selectId) === "审批中" ? _c("a-tooltip", {
          attrs: {
            placement: "topLeft"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v("审批中，审批通过后即可操作")])]), _c("a-button", {
          staticStyle: {
            "padding-left": "0",
            color: "#b8b8b8"
          },
          attrs: {
            disabled: record.approvalStatus != 4 && record.approvalStatus != 5,
            size: "small",
            type: "link"
          }
        }, [_vm._v("确认到岗")])], 2) : _vm._e(), _vm.getEmployeeStatus(text, record, _vm.selectId) === "审批中" || _vm.getEmployeeStatus(text, record, _vm.selectId) === "待入职" ? _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }) : _vm._e(), _vm.getEmployeeStatus(text, record, _vm.selectId) === "待入职" ? _c("a-popconfirm", {
          attrs: {
            placement: "topRight",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.noConfirmJoin(record);
            }
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("p", [_vm._v("确定要未到岗操作？")])]), _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          }
        }, [_vm._v("未到岗")])], 2) : _vm._e(), _vm.getEmployeeStatus(text, record, _vm.selectId) === "审批中" ? _c("a-tooltip", {
          attrs: {
            placement: "topLeft"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v("审批中，审批通过后即可操作")])]), _c("a-button", {
          staticStyle: {
            "padding-left": "0",
            color: "#b8b8b8"
          },
          attrs: {
            size: "small",
            type: "link"
          }
        }, [_vm._v("未到岗")])], 2) : _vm._e(), _vm.getEmployeeStatus(text, record, _vm.selectId) === "审批驳回" ? _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.resJoinOrreApproval(record, 2);
            }
          }
        }, [_vm._v("再次发起")]) : _vm._e(), _vm.getEmployeeStatus(text, record, _vm.selectId) === "未到岗" ? _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.resJoinOrreApproval(record, 1);
            }
          }
        }, [_vm._v("重新入职")]) : _vm._e(), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.detail(record, 2);
            }
          }
        }, [_vm._v("详情")]), _vm.getEmployeeStatus(text, record, _vm.selectId) === "未到岗" || _vm.getEmployeeStatus(text, record, _vm.selectId) === "审批驳回" ? _c("a-button", {
          style: {
            visibility: "hidden"
          },
          attrs: {
            size: "small",
            type: "link"
          }
        }, [_vm._v("重新入职")]) : _vm._e()], 1) : _vm._e(), _vm.selectId == 3 ? _c("div", [_c("a-button", {
          staticStyle: {
            "padding-left": "0"
          },
          attrs: {
            disabled: record.approvalStatus != 4 && record.approvalStatus != 5,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.makeTransfer(record);
            }
          }
        }, [_vm._v("调岗")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a-button", {
          attrs: {
            disabled: record.approvalStatus != 4 && record.approvalStatus != 5,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.makeLeave(record);
            }
          }
        }, [_vm._v("离职")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.detail(record, 5);
            }
          }
        }, [_vm._v("详情")])], 1) : _vm._e(), _vm.selectId == 2 ? _c("div", [_c("a-button", {
          staticStyle: {
            "padding-left": "0"
          },
          attrs: {
            disabled: record.approvalStatus != 5,
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.confirmRegular(record);
            }
          }
        }, [_vm._v("转正")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a-button", {
          attrs: {
            size: "small",
            disabled: record.approvalStatus != 5 && record.approvalStatus != 4,
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.makeLeave(record);
            }
          }
        }, [_vm._v("离职")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a-button", {
          attrs: {
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.detail(record, 4);
            }
          }
        }, [_vm._v("详情")])], 1) : _vm._e(), _vm.selectId == 4 ? _c("div", [_c("a-button", {
          staticStyle: {
            "padding-left": "0"
          },
          attrs: {
            disabled: record.approvalStatus != 5 && record.approvalStatus != 4 || record.status == 4,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.confirmLeave(record);
            }
          }
        }, [_vm._v("确认离职")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a-button", {
          attrs: {
            disabled: record.approvalStatus != 5 && record.approvalStatus != 4 || record.status == 4,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.revokeLeave(record);
            }
          }
        }, [_vm._v("撤销离职")])], 1) : _vm._e(), _vm.selectId == 5 ? _c("div", [_c("a-button", {
          staticStyle: {
            "padding-left": "0"
          },
          attrs: {
            disabled: record.approvalStatus != 5 && record.approvalStatus != 4,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.confimTransferPosition(record);
            }
          }
        }, [_vm._v("确认调岗")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a-button", {
          attrs: {
            disabled: record.approvalStatus != 5 && record.approvalStatus != 4,
            size: "small",
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.revokeTransferPosition(record);
            }
          }
        }, [_vm._v("撤销调岗")])], 1) : _vm._e()];
      }
    }])
  })], 1)], 1), _c("a-drawer", {
    attrs: {
      title: "办理入职",
      width: 540,
      visible: _vm.visibleDrawer,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: function close($event) {
        return _vm.onCloseDrawer("");
      }
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.entryForm
    },
    on: {
      submit: _vm.handleSubmit
    }
  }, [_c("div", {
    staticClass: "drawer-title mb24"
  }, [_vm._v("基本信息")]), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "姓名:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入姓名"
        }]
      }],
      expression: "[\n              'name',\n              {\n                rules: [{ required: true, message: '请输入姓名' }]\n              }\n            ]"
    }],
    attrs: {
      placeholder: "请输入姓名"
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "手机号码:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["phone", {
        rules: [{
          required: true,
          message: "请输入手机号"
        }, {
          validator: _vm.validPhone
        }]
      }],
      expression: "[\n              'phone',\n              {\n                rules: [{ required: true, message: '请输入手机号' }, { validator: validPhone }]\n              }\n            ]"
    }],
    attrs: {
      "max-length": 11,
      placeholder: "请输入手机号码"
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "部门:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-col", {
    attrs: {
      span: 23
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["depIdList", {
        rules: [{
          required: true,
          message: "请选择部门"
        }]
      }],
      expression: "[\n                'depIdList',\n                {\n                  rules: [{ required: true, message: '请选择部门' }]\n                }\n              ]"
    }],
    attrs: {
      "tree-data": _vm.depInfoOptions,
      "allow-clear": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 1
    }
  }, [_c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.toPage("/organization/organization");
      }
    }
  }, [_vm._v("去添加")])], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "岗位:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-col", {
    attrs: {
      span: 23
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["positionIdList", {
        rules: [{
          required: true,
          message: "请选择岗位"
        }]
      }],
      expression: "[\n                'positionIdList',\n                {\n                  rules: [{ required: true, message: '请选择岗位' }]\n                }\n              ]"
    }],
    attrs: {
      "tree-data": _vm.positionOptions,
      "allow-clear": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      span: 1
    }
  }, [_c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.toPage("/organization/org");
      }
    }
  }, [_vm._v("去添加")])], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "工作性质:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType"],
      expression: "['postType']"
    }],
    attrs: {
      placeholder: "请选择工作性质"
    }
  }, _vm._l(_vm.postTypeOptions2, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "预计入职日期:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["preJoinDate", {
        rules: [{
          required: true,
          message: "请选择预计入职日期"
        }]
      }],
      expression: "[\n              'preJoinDate',\n              {\n                rules: [{ required: true, message: '请选择预计入职日期' }]\n              }\n            ]"
    }],
    attrs: {
      type: "date"
    }
  })], 1)], 1), _c("div", {
    staticClass: "drawer-footer-box"
  }, [_c("a-button", {
    style: {
      marginRight: "12px"
    },
    attrs: {
      type: "primary",
      "html-type": "submit"
    }
  }, [_vm._v("提交")]), _c("a-button", {
    on: {
      click: function click($event) {
        return _vm.onCloseDrawer("");
      }
    }
  }, [_vm._v("取消")])], 1)], 1)], 1), _c("a-drawer", {
    attrs: {
      title: "转正",
      width: 540,
      visible: _vm.visibleRegularDrawer,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: function close($event) {
        return _vm.onCloseDrawer("Regular");
      }
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.regularForm
    },
    on: {
      submit: _vm.handleRegularSubmit
    }
  }, [_c("div", {
    staticClass: "drawer-title mb24"
  }, [_vm._v("基本信息")]), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "姓名:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        initialValue: _vm.regularFormData.name
      }],
      expression: "['name', { initialValue: regularFormData.name }]"
    }],
    attrs: {
      placeholder: "请输入姓名",
      disabled: ""
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "手机号码:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["phone", {
        initialValue: _vm.regularFormData.phone
      }],
      expression: "['phone', { initialValue: regularFormData.phone }]"
    }],
    attrs: {
      placeholder: "请输入手机号码",
      disabled: ""
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_vm.regularFormData.employType === 0 ? _c("a-form-item", {
    attrs: {
      label: "形式:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["informalType", {
        initialValue: _vm.regularFormData.informalType
      }],
      expression: "['informalType', { initialValue: regularFormData.informalType }]"
    }],
    attrs: {
      disabled: "",
      placeholder: "请选择"
    }
  }, _vm._l(_vm.informalTypeOptions, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1) : _vm._e()], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "部门:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["depIdList", {
        initialValue: _vm.regularFormData.depName
      }],
      expression: "['depIdList', { initialValue: regularFormData.depName }]"
    }],
    attrs: {
      disabled: "",
      "tree-data": _vm.depInfoOptions,
      multiple: "",
      "allow-clear": ""
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "岗位:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["positionIdList", {
        initialValue: _vm.regularFormData.positionName
      }],
      expression: "['positionIdList', { initialValue: regularFormData.positionName }]"
    }],
    attrs: {
      disabled: "",
      "tree-data": _vm.positionOptions,
      multiple: "",
      "allow-clear": ""
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "入职日期:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["joinDate", {
        initialValue: _vm.getMoment(_vm.regularFormData.joinDate)
      }],
      expression: "['joinDate', { initialValue: getMoment(regularFormData.joinDate) }]"
    }],
    attrs: {
      disabled: "",
      type: "date"
    }
  })], 1)], 1), _c("div", {
    staticClass: "drawer-title mb24"
  }, [_vm._v("转正日期")]), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "转正日期:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["regularDate", {
        rules: [{
          required: true,
          message: "请选择转正日期"
        }]
      }],
      expression: "[\n              'regularDate',\n              {\n                rules: [{ required: true, message: '请选择转正日期' }]\n              }\n            ]"
    }],
    attrs: {
      "disabled-date": _vm.disabledDateRegularDate,
      type: "date",
      format: "YYYY-MM-DD"
    }
  })], 1)], 1), _c("div", {
    staticClass: "drawer-footer-box"
  }, [_c("a-button", {
    style: {
      marginRight: "12px"
    },
    on: {
      click: function click($event) {
        return _vm.onCloseDrawer("Regular");
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    }
  }, [_vm._v("确定")])], 1)], 1)], 1), _c("a-drawer", {
    attrs: {
      title: "办理离职",
      width: 540,
      visible: _vm.visibleLeaveDrawer,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: function close($event) {
        return _vm.onCloseDrawer("Leave");
      }
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.leaveForm
    },
    on: {
      submit: _vm.handleLeaveSubmit
    }
  }, [_c("a-alert", {
    staticClass: "mb20",
    attrs: {
      message: "可选择员工办理离职，提交后所选员工将进入待离职状态",
      banner: "",
      closable: ""
    }
  }), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "姓名:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "必选"
        }]
      }],
      expression: "[\n              'name',\n              {\n                rules: [{ required: true, message: '必选' }]\n              }\n            ]"
    }],
    ref: "nameModal",
    attrs: {
      "auto-size": {
        minRows: 1,
        maxRows: 3
      },
      placeholder: "请选择员工",
      disabled: _vm.nameDisable,
      autocomplete: "off"
    },
    on: {
      click: _vm.showMeberDialog,
      contextmenu: function contextmenu($event) {
        $event.preventDefault();
        return _vm.showMeberDialog.apply(null, arguments);
      }
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "预计离职日期:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["preQuitDate", {
        rules: [{
          required: true,
          message: "请选择预计离职日期"
        }]
      }],
      expression: "[\n              'preQuitDate',\n              {\n                rules: [{ required: true, message: '请选择预计离职日期' }]\n              }\n            ]"
    }],
    attrs: {
      "disabled-date": _vm.disabledDateRegularDate,
      type: "date"
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "离职原因:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("div", {
    staticClass: "selectBoxs",
    on: {
      click: _vm.showLeaveModal
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["quitReason", {
        rules: [{
          required: true,
          message: "请选择离职原因"
        }]
      }],
      expression: "['quitReason', { rules: [{ required: true, message: '请选择离职原因' }] }]"
    }],
    ref: "selectModal",
    attrs: {
      onbeforeactivate: "false",
      mode: "multiple",
      notFoundContent: null
    },
    on: {
      change: _vm.LeaveChange
    }
  })], 1)])], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "离职备注:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["quitRemarks"],
      expression: "['quitRemarks']"
    }],
    attrs: {
      placeholder: "请输入备注",
      rows: 4
    }
  })], 1)], 1), _c("div", {
    staticClass: "drawer-footer-box"
  }, [_c("a-button", {
    style: {
      marginRight: "12px"
    },
    on: {
      click: function click($event) {
        return _vm.onCloseDrawer("Leave");
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    }
  }, [_vm._v("确定")])], 1)], 1)], 1), _c("a-drawer", {
    attrs: {
      title: "办理调岗",
      width: 540,
      visible: _vm.visibleMoveDrawer,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: function close($event) {
        return _vm.onCloseDrawer("Move");
      }
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.moveForm
    },
    on: {
      submit: _vm.handleMoveSubmit
    }
  }, [_c("a-alert", {
    staticClass: "mb20",
    attrs: {
      message: "可选择员工办理调岗，提交后所选员工将进入待调岗状态",
      banner: "",
      closable: ""
    }
  }), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "姓名:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "必选"
        }]
      }],
      expression: "[\n              'name',\n              {\n                rules: [{ required: true, message: '必选' }]\n              }\n            ]"
    }],
    ref: "nameModal",
    attrs: {
      "auto-size": {
        minRows: 1,
        maxRows: 3
      },
      placeholder: "请选择员工",
      disabled: _vm.nameDisabled,
      autocomplete: "off"
    },
    on: {
      contextmenu: function contextmenu($event) {
        $event.preventDefault();
        return _vm.showMeberDialog.apply(null, arguments);
      },
      click: _vm.showMeberDialog
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "岗位变更为",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["currentPositionIdList", {
        rules: [{
          required: true,
          message: "必填"
        }]
      }],
      expression: "[\n              'currentPositionIdList',\n              {\n                rules: [{ required: true, message: '必填' }]\n              }\n            ]"
    }],
    attrs: {
      "tree-data": _vm.positionOptions,
      "allow-clear": ""
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "部门变更为",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["currentDepIdList", {
        rules: [{
          required: true,
          message: "必填"
        }]
      }],
      expression: "[\n              'currentDepIdList',\n              {\n                rules: [{ required: true, message: '必填' }]\n              }\n            ]"
    }],
    attrs: {
      "tree-data": _vm.depInfoOptions,
      "allow-clear": ""
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "调岗日期:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["adjustmentDate", {
        rules: [{
          required: true,
          message: "请选择调岗日期"
        }]
      }],
      expression: "[\n              'adjustmentDate',\n              {\n                rules: [{ required: true, message: '请选择调岗日期' }]\n              }\n            ]"
    }],
    attrs: {
      type: "date"
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "调岗原因:",
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["adjustmentReason"],
      expression: "['adjustmentReason']"
    }],
    attrs: {
      type: "textarea"
    }
  })], 1)], 1), _c("div", {
    staticClass: "drawer-footer-box"
  }, [_c("a-button", {
    style: {
      marginRight: "12px"
    },
    on: {
      click: function click($event) {
        return _vm.onCloseDrawer("Move");
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    }
  }, [_vm._v("确定")])], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: _vm.drawerTitle,
      visible: _vm.visibleConfimSubmit,
      "confirm-loading": false,
      "z-index": 1050
    },
    on: {
      cancel: function cancel($event) {
        return _vm.handleCancelSubmit("visibleConfimSubmit");
      }
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        return _vm.handleCancelSubmit("visibleConfimSubmit");
      }
    }
  }, [_vm._v("关闭")])], 1), _c("div", {
    staticClass: "modal-box"
  }, [_c("img", {
    staticClass: "w200",
    attrs: {
      src: require("@/assets/img/3.png"),
      alt: ""
    }
  }), _c("h3", {
    staticClass: "modal-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.drawerTitle) + "成功")])]), _c("p", {
    staticClass: "modal-content"
  }, [_c("span", [_vm._v(" " + _vm._s(_vm.drawerTitle === "确认到岗" ? _vm.getShiyongqi() ? "可在'转正管理'中查看员工入职相关信息" : "可在'在职管理'中查看员工入职相关信息" : "可在'入职管理'中，重新入职") + " ")])])])], 2), _c("a-modal", {
    attrs: {
      title: _vm.titleSubmit,
      visible: _vm.visibleSubmit,
      "confirm-loading": false,
      "cancel-text": "关闭",
      "ok-text": _vm.oktext,
      "z-index": 1050
    },
    on: {
      ok: _vm.onJoinEmp,
      cancel: function cancel($event) {
        return _vm.handleCancelSubmit("visibleSubmit");
      }
    }
  }, [_vm.FlowsData.length === 0 ? _c("div", {
    staticClass: "modal-box"
  }, [_c("img", {
    staticClass: "w200",
    attrs: {
      src: require("@/assets/img/1.png"),
      alt: ""
    }
  }), _c("h3", {
    staticClass: "modal-title"
  }, [_vm.flag === 1 ? _c("span", [_vm._v("提交成功")]) : _vm._e(), _vm.flag === 2 ? _c("span", [_vm._v(_vm._s(_vm.iszheng ? "转正成功" : "转正日期修改成功"))]) : _vm._e(), _vm.flag === 3 ? _c("span", [_vm._v("已提交至待离职列表")]) : _vm._e(), _vm.flag === 4 ? _c("span", [_vm._v("已提交至待调岗列表")]) : _vm._e()]), _vm.flag === 1 ? _c("p", {
    staticClass: "modal-content"
  }, [_vm._v(" 需要手动发送入职邀请至对方手机 ")]) : _vm._e(), _c("p", {
    staticClass: "modal-content"
  }, [_vm.flag === 1 ? _c("span", [_vm._v("可在转正管理里查看员工入职相关信息")]) : _vm._e(), _vm.flag === 2 ? _c("span", [_vm._v("可在" + _vm._s(_vm.iszheng ? "在职管理" : "转正管理") + "查看员工相关信息")]) : _vm._e(), _vm.flag === 3 ? _c("span", [_vm._v("可在待离职列表内确认离职或撤销离职")]) : _vm._e(), _vm.flag === 4 ? _c("span", [_vm._v("可在待调岗列表内确认调岗或撤销调岗")]) : _vm._e()])]) : _c("div", {
    staticClass: "modal-box"
  }, [_c("img", {
    staticClass: "w200",
    attrs: {
      src: require("@/assets/img/2.png"),
      alt: ""
    }
  }), _c("h3", {
    staticClass: "modal-title"
  }, [_vm._v("待审批")]), _c("p", {
    staticClass: "modal-content"
  }, [_vm._v("已提交至审批流程")]), _vm.flag === 1 ? _c("p", [_vm._v("审批通过后需要手动发送入职邀请至对方手机")]) : _vm._e()])]), _c("a-modal", {
    attrs: {
      title: "选择审批模板",
      visible: _vm.approvalModal,
      "confirm-loading": false,
      "cancel-text": "关闭",
      "ok-text": "下一步",
      "z-index": 1020
    },
    on: {
      ok: _vm.handleApprovalSubmit,
      cancel: function cancel($event) {
        _vm.approvalModal = false;
      }
    }
  }, [_c("div", {
    staticClass: "modal-box overflow-y400"
  }, [_c("a-radio-group", {
    staticStyle: {
      width: "100%"
    },
    on: {
      change: _vm.onselectValueChange
    },
    model: {
      value: _vm.selectValue,
      callback: function callback($$v) {
        _vm.selectValue = $$v;
      },
      expression: "selectValue"
    }
  }, _vm._l(_vm.FlowsData, function (item) {
    return _c("a-row", {
      key: item.id,
      staticClass: "mb20",
      attrs: {
        type: "flex",
        align: "middle"
      }
    }, [_c("a-col", {
      staticClass: "icon mg_r10"
    }, [_c("img", {
      staticStyle: {
        width: "48px"
      },
      attrs: {
        src: item.icon,
        alt: "加载失败"
      }
    })]), _c("a-col", [_c("a-row", {
      staticClass: "title1 ta-left"
    }, [_vm._v(_vm._s(item.typeName))]), _c("a-row", {
      staticClass: "subTitle ta-left"
    }, [_vm._v(_vm._s(item.typeDesc))])], 1), _c("a-col", {
      staticClass: "ta-right",
      staticStyle: {
        flex: "1"
      }
    }, [_c("a-radio", {
      attrs: {
        value: item.id
      }
    })], 1)], 1);
  }), 1)], 1)]), _c("a-modal", {
    attrs: {
      title: "选择离职原因",
      visible: _vm.visibleLeaveModal,
      "confirm-loading": false,
      "cancel-text": "取消",
      "ok-text": "确定"
    },
    on: {
      ok: _vm.confirmLeaveModal,
      cancel: _vm.handleCancelLeaveModal
    }
  }, [_c("a-checkbox-group", {
    on: {
      change: _vm.onChangeLeaveModal
    },
    model: {
      value: _vm.checkedListGroup,
      callback: function callback($$v) {
        _vm.checkedListGroup = $$v;
      },
      expression: "checkedListGroup"
    }
  }, [_c("a-row", {
    staticClass: "lh40"
  }, [_c("a-col", {
    staticClass: "drawer-title mb20",
    attrs: {
      span: 24
    }
  }, [_vm._v("主动原因")]), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "家庭原因"
    }
  }, [_vm._v("家庭原因")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "个人原因"
    }
  }, [_vm._v("个人原因")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "发展原因"
    }
  }, [_vm._v("发展原因")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "合同到期不续签"
    }
  }, [_vm._v("合同到期不续签")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "其他"
    }
  }, [_vm._v("其他")])], 1), _c("a-col", {
    staticClass: "drawer-title mb20 mt20",
    attrs: {
      span: 24
    }
  }, [_vm._v("被动原因")]), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "协议解除"
    }
  }, [_vm._v("协议解除")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "无法胜任工作"
    }
  }, [_vm._v("无法胜任工作")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "经济性裁员"
    }
  }, [_vm._v("经济性裁员")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "严重违法违纪"
    }
  }, [_vm._v("严重违法违纪")])], 1)], 1)], 1)], 1), _c("a-modal", {
    staticClass: "empInfoDialog",
    attrs: {
      "destroy-on-close": "",
      width: "800px",
      top: "100px",
      title: "选择员工",
      visible: _vm.visibleMerber,
      "z-index": 1003
    },
    on: {
      cancel: function cancel($event) {
        _vm.visibleMerber = false;
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "30%",
      "margin-bottom": "10px"
    },
    attrs: {
      placeholder: "请输入姓名、身份证号或手机号"
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.selectEmp.apply(null, arguments);
      }
    },
    model: {
      value: _vm.nameOrPhoneOrEmail,
      callback: function callback($$v) {
        _vm.nameOrPhoneOrEmail = $$v;
      },
      expression: "nameOrPhoneOrEmail"
    }
  }), _c("a-table", {
    ref: "empInfoTable",
    attrs: {
      "row-selection": {
        selectedRowKeys: _vm.selectedTempRowKeys,
        onChange: _vm.selectionChange,
        type: "radio"
      },
      "data-source": _vm.empInfoList,
      pagination: _vm.pagination,
      columns: _vm.columns,
      "row-key": function rowKey(record) {
        return record.id;
      },
      size: "small"
    },
    on: {
      change: _vm.handleEmpInfoTableChange
    }
  }), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        _vm.visibleMerber = false;
      }
    }
  }, [_vm._v("取 消")]), _c("a-button", {
    staticClass: "adjust-button",
    attrs: {
      type: "primary",
      disabled: _vm.adjustButtonDisabled
    },
    on: {
      click: _vm.adjustEmp
    }
  }, [_vm._v("添加")])], 1)], 1), _vm.positonTreeShow ? _c("positonTree", {
    attrs: {
      list: []
    },
    on: {
      getPositionList: _vm.getPositionList,
      cancelPositionList: _vm.cancelPositionList
    }
  }) : _vm._e(), _c("a-drawer", {
    attrs: {
      title: _vm.drawerTitle,
      width: 580,
      destroyOnClose: true,
      visible: _vm.visibleEmpyleeDrawer2,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: function close($event) {
        return _vm.onCloseDrawer2("visibleEmpyleeDrawer2");
      }
    }
  }, [_vm.drawerTitle === "添加待入职" ? _c("DynamicAddEmployees", {
    attrs: {
      outEmpDetail: _vm.joinFormData,
      title: _vm.drawerTitle,
      moduleCode: "waitJob"
    },
    on: {
      saveValueChange: _vm.saveValueChange,
      cancelForm: _vm.cancelForm
    }
  }) : _c("DynamicAddEmployees", {
    attrs: {
      outEmpDetail: _vm.joinFormData,
      title: _vm.drawerTitle,
      confirmedPost: true,
      moduleCode: "onJob"
    },
    on: {
      saveValueChange: _vm.saveValueChange,
      cancelForm: _vm.cancelForm
    }
  }), _c("a-modal", {
    attrs: {
      footer: null,
      bodyStyle: {
        height: "180px"
      },
      title: "数据同步",
      width: "600px"
    },
    model: {
      value: _vm.haveResigned,
      callback: function callback($$v) {
        _vm.haveResigned = $$v;
      },
      expression: "haveResigned"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      "padding-left": "24px"
    }
  }, [_vm._v(_vm._s(_vm.reasonResigned ? _vm.reasonResigned : "--"))]), _c("div", [_c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      "padding-left": "24px",
      color: "#ff0000"
    }
  }, [_vm._v("*")]), _vm._v(" 若希望信息同步，请前往花名册为其更换用工单位 ")]), _c("div", [_c("span", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      "padding-left": "24px",
      color: "#ff0000"
    }
  }, [_vm._v("*")]), _vm._v(" 若不需要信息同步，请点击继续添加 ")]), _vm.companyIdResigned && !_vm.companyIdResigned.haveAuthority ? _c("div", {
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_vm._v(" （您没有【" + _vm._s(_vm.companyIdResigned.companyName) + "】的访问权限，无法为其更换用工单位） ")]) : _vm._e()]), _c("div", {
    staticClass: "drawer-footer-box2"
  }, [_c("a-button", {
    style: {
      marginRight: "12px"
    },
    attrs: {
      disabled: _vm.companyIdResigned && !_vm.companyIdResigned.canAdd
    },
    on: {
      click: _vm.handleResigneCancel
    }
  }, [_vm._v("继续添加")]), _c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit",
      disabled: _vm.companyIdResigned && !_vm.companyIdResigned.haveAuthority
    },
    on: {
      click: _vm.handleOk
    }
  }, [_vm._v("前往花名册")])], 1)])], 1), _c("a-modal", {
    attrs: {
      title: "添加待入职",
      visible: _vm.isCloseMethod2,
      width: "520px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("关闭")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "column",
      "align-items": "center"
    }
  }, [!_vm.isApproval ? _c("img", {
    staticStyle: {
      width: "200px",
      height: "200px"
    },
    attrs: {
      src: require("@/assets/img/1.png")
    }
  }) : _c("img", {
    staticStyle: {
      width: "200px",
      height: "200px"
    },
    attrs: {
      src: require("@/assets/img/2.png")
    }
  }), _c("span", {
    staticStyle: {
      "font-size": "20px",
      "font-weight": "800"
    }
  }, [_vm._v(_vm._s(!_vm.isApproval ? "提交成功" : "审批中"))]), _c("span", {
    staticStyle: {
      color: "#00000077",
      "margin-top": "12px",
      "margin-bottom": "12px"
    }
  }, [_vm._v(" " + _vm._s(!_vm.isApproval ? "可在入职管理中选择确认到岗或者未到岗" : "已提交至审批流程") + " ")])])], 2), _c("a-modal", {
    attrs: {
      title: "选择审批模板",
      visible: _vm.selectModel,
      width: "520px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.finishEdit3
    }
  }, [_vm._v("下一步")])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "column",
      "align-items": "center"
    }
  }, _vm._l(_vm.templatesListGroup, function (item, i) {
    return _c("div", {
      key: i,
      class: {
        backgroundC: item.select
      },
      staticStyle: {
        "padding-top": "10px",
        "padding-bottom": "10px",
        "padding-right": "15px",
        width: "100%",
        display: "flex",
        "flex-flow": "row",
        "align-items": "center",
        "justify-content": "space-between"
      },
      on: {
        click: function click($event) {
          return _vm.temClick(item);
        }
      }
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        "flex-flow": "row"
      }
    }, [_c("img", {
      staticStyle: {
        width: "52px",
        height: "52px",
        "margin-right": "15px"
      },
      attrs: {
        src: require("@/assets/img/模板icon.png")
      }
    }), _c("div", [_c("span", {
      staticStyle: {
        display: "flex",
        "flex-flow": "column",
        "font-size": "16px",
        "font-weight": "600"
      }
    }, [_vm._v(_vm._s(item.typeName))]), _c("span", {
      staticStyle: {
        color: "#00000077"
      }
    }, [_vm._v(_vm._s(item.typeDesc))])])]), item.select ? _c("xq-icon", {
      staticClass: "primary_color fs20",
      attrs: {
        type: "icon-wancheng2"
      }
    }) : _vm._e()], 1);
  }), 0)], 2), _c("a-modal", {
    attrs: {
      title: "批量转正",
      visible: _vm.visiblePositive,
      "confirm-loading": _vm.confirmLoadingPositive
    },
    on: {
      ok: _vm.handleOkPositive,
      cancel: _vm.handleCancelPositive
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.formPositive,
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "转正日期"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["regularDate", {
        rules: [{
          required: true,
          message: "请选择转正日期!"
        }]
      }],
      expression: "['regularDate', { rules: [{ required: true, message: '请选择转正日期!' }] }]"
    }],
    attrs: {
      "disabled-date": _vm.disabledDateRegularDate
    }
  })], 1)], 1)], 1), _c("a-drawer", {
    attrs: {
      title: "确认到岗",
      width: 580,
      destroyOnClose: true,
      visible: _vm.visibleJoinDate,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: function close($event) {
        return _vm.onCloseDrawer2("visibleJoinDate");
      }
    }
  }, [_c("DynamicAddEmployees", {
    attrs: {
      outEmpDetail: _vm.confirmedPostArr,
      isBatchJoin: _vm.isBatchJoin,
      title: "确认到岗",
      moduleCode: "onJob"
    },
    on: {
      saveValueChange: _vm.handleOkJoinDate,
      cancelForm: _vm.cancelForm
    }
  })], 1), _c("BatchLeaveFilter", {
    ref: "BatchLeaveFilterSelect",
    on: {
      bulkDeparturesOk: _vm.bulkDeparturesOk,
      saveOk: _vm.saveLeaveOk,
      querenlizhi: _vm.onQuerenlizhi
    }
  }), _c("employeesMoveAdvancedScreening", {
    ref: "employeesMove",
    on: {
      getNextStep: _vm.getNextStep
    }
  }), _c("a-modal", {
    attrs: {
      title: "批量离职结果",
      width: "840px"
    },
    on: {
      ok: _vm.pilianglizhiModalOk,
      cancel: _vm.pilianglizhiModalOk
    },
    model: {
      value: _vm.pilianglizhiResultModal,
      callback: function callback($$v) {
        _vm.pilianglizhiResultModal = $$v;
      },
      expression: "pilianglizhiResultModal"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px",
      "margin-bottom": "16px",
      "font-weight": "500"
    }
  }, [_vm._v("批量离职成功")]), _c("a-table", {
    attrs: {
      columns: _vm.pilianglizhiColumns,
      scroll: {
        x: 700
      },
      rowKey: "empId",
      dataSource: _vm.pilianglizhiResults
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };