var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-upload", {
    staticClass: "uploadImg",
    class: "".concat(_vm.fileList.length < _vm.num ? "zhanshi" : "yincang"),
    attrs: {
      "list-type": "picture-card",
      action: "/file/api/v1/site/upload/inline",
      name: "files",
      "file-list": _vm.fileList,
      accept: ".jpg, .jpeg, .png, .bmp, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .zip, .rar",
      multiple: true,
      remove: _vm.remove
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleUpChange
    }
  }, [_vm.fileList.length < _vm.num ? _c("div", [_vm._t("default")], 2) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: function cancel($event) {
        _vm.previewVisible = false;
      }
    }
  }, [_c("div", {
    staticStyle: {
      padding: "20px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };