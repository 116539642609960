import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import { getTransferAccountsBatches as _getTransferAccountsBatches, getAccountList as _getAccountList, getAccountListZFB, deleteAccount as _deleteAccount, generateZfbMoneyToBookUrl, getAccountListBank } from "./api";
export default {
  data: function data() {
    return {
      pageSizeOptions: ["10", "20", "50", "100"],
      tabsItem1: "tabsItemOn",
      tabsItem2: "tabsItem",
      tabsItem3: "tabsItem",
      tabsItem4: "tabsItem",
      dataList: [],
      weixinMoney: "绑定后可查看",
      ZFBMoney: "绑定后可查看",
      bankMoney: "绑定后可查看",
      bankItem: require("@/assets/img/bankItem.png"),
      weixinItem: require("@/assets/img/weixinItem.png"),
      zhifubaoItem: require("@/assets/img/zhifubaoItem.png"),
      jzbItem: require("@/assets/img/jzbItem.png"),
      bankbg: require("@/assets/img/bankbg.png"),
      jzbBg: require("@/assets/img/jzbBg.png"),
      weixinbg: require("@/assets/img/weixinbg.png"),
      zhifubaobg: require("@/assets/img/zhifubaobg.png"),
      weixinchange: require("@/assets/img/weixinchange.png"),
      bankchange: require("@/assets/img/bankchange.png"),
      zfbchange: require("@/assets/img/zfbchange.png"),
      companyId: this.$store.state.hr.companyId,
      current: 1,
      pageSize: 10,
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      selectName: undefined,
      subjectStatus: 4,
      form: {},
      clicked: false,
      topUpVisible: false,
      clicked2: false,
      clicked3: false,
      payStatus: null,
      accountListCopy: [],
      loading: false,
      entityCount: 0,
      accountList: [],
      accountListZFB: [],
      accountListBank: [],
      accountListBankCopy: [],
      accountListCopyZFB: [],
      time: [],
      typeIndex: 4
    };
  },
  created: function created() {
    if (JSON.stringify(this.$getCondition("xzff_condition")) !== "{}") {
      var dparams = JSON.parse(this.$getCondition("xzff_condition"));
      console.log("-------------");
      console.log(dparams);
      if (dparams) {
        if (dparams.pageSize) {
          this.pageSize = dparams.pageSize;
        }
        if (dparams.pageNo) {
          this.current = dparams.pageNo;
        }
        if (dparams.selectName) {
          this.selectName = dparams.selectName;
        }
        if (dparams.subjectStatus) {
          this.subjectStatus = dparams.subjectStatus;
        }
        if (dparams.payStatus) {
          this.payStatus = dparams.payStatus;
        }
        if (dparams.gmtCreateStart && dparams.gmtCreateEnd) {
          this.time = [dparams.gmtCreateStart, dparams.gmtCreateEnd];
        }
        if (dparams.typeIndex === 4) {
          this.tabsItem1 = "tabsItemOn";
          this.tabsItem2 = "tabsItem";
          this.tabsItem3 = "tabsItem";
          this.tabsItem4 = "tabsItem";
        } else if (dparams.typeIndex === 5) {
          this.tabsItem1 = "tabsItem";
          this.tabsItem2 = "tabsItemOn";
          this.tabsItem3 = "tabsItem";
          this.tabsItem4 = "tabsItem";
        } else if (dparams.typeIndex === 6) {
          this.tabsItem1 = "tabsItem";
          this.tabsItem2 = "tabsItem";
          this.tabsItem3 = "tabsItemOn";
          this.tabsItem4 = "tabsItem";
        } else if (dparams.typeIndex === 7) {
          this.tabsItem1 = "tabsItem";
          this.tabsItem2 = "tabsItem";
          this.tabsItem3 = "tabsItem";
          this.tabsItem4 = "tabsItemOn";
        }
      }
    }
    this.getTransferAccountsBatches();
    this.getAccountList();
  },
  methods: {
    handleTopUpAddOK: function handleTopUpAddOK() {
      var _this2 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          generateZfbMoneyToBookUrl(_this2.form.transferAccountInfoId, _this2.form.money).then(function (res) {
            if (res.data) {
              window.open(res.data, "_blank");
              _this2.topUpVisible = false;
              _this2.form = {};
              _this2.$refs.ruleForm.clearValidate();
              var _this = _this2;
              _this2.$success({
                title: "请支付成功后刷新页面",
                okText: "立即刷新",
                onOk: function onOk() {
                  _this.getTransferAccountsBatches();
                  _this.getAccountList();
                }
              });
            } else {
              _this2.$notification["error"]({
                message: "失败",
                description: "支付失败"
              });
            }
          }).catch(function (err) {
            console.log(err);
          });
        }
      });
    },
    onTopUpClose: function onTopUpClose() {
      this.topUpVisible = false;
      this.form = {};
      this.$refs.ruleForm.clearValidate();
    },
    deleteAccount: function deleteAccount(e) {
      var that = this;
      this.$confirm({
        title: "确认删除吗?",
        onOk: function onOk() {
          _deleteAccount(e.id).then(function (res) {
            that.$notification["success"]({
              message: "success",
              description: "删除成功"
            });
            that.getTransferAccountsBatches();
          });
        },
        onCancel: function onCancel() {
          console.log("Cancel");
        },
        class: "test"
      });
    },
    onSearchAccount: function onSearchAccount(e) {
      if (e) {
        var arr = [];
        this.accountListCopy.forEach(function (item, index) {
          if (item.name.includes(e)) {
            arr.push(item);
          }
        });
      } else {
        arr = JSON.parse(JSON.stringify(this.accountListCopy));
      }
      this.accountList = JSON.parse(JSON.stringify(arr));
    },
    onSearchAccount2: function onSearchAccount2(e) {
      if (e) {
        var arr = [];
        this.accountListCopyZFB.forEach(function (item, index) {
          if (item.name.includes(e)) {
            arr.push(item);
          }
        });
      } else {
        arr = JSON.parse(JSON.stringify(this.accountListCopyZFB));
      }
      this.accountListZFB = JSON.parse(JSON.stringify(arr));
    },
    onSearchAccount3: function onSearchAccount3(e) {
      if (e) {
        var arr = [];
        this.accountListBankCopy.forEach(function (item, index) {
          if (item.name.includes(e)) {
            arr.push(item);
          }
        });
      } else {
        arr = JSON.parse(JSON.stringify(this.accountListBankCopy));
      }
      this.accountListBank = JSON.parse(JSON.stringify(arr));
    },
    handleClickChange: function handleClickChange(visible) {
      this.clicked = visible;
    },
    handleClickChange2: function handleClickChange2(visible) {
      this.clicked2 = visible;
    },
    handleClickChange3: function handleClickChange3(visible) {
      this.clicked3 = visible;
    },
    handleChange: function handleChange(e, a) {
      if (a === 1) {
        this.weixinMoney = e.money;
        this.clicked = false;
      } else if (a === 2) {
        this.ZFBMoney = e.money;
        this.clicked2 = false;
      } else if (a === 3) {
        this.bankMoney = e.money;
        this.clicked3 = false;
      }
    },
    getAccountList: function getAccountList() {
      var _this3 = this;
      _getAccountList().then(function (res) {
        _this3.accountList = res.data || [];
        _this3.accountListCopy = res.data || [];
        if (_this3.accountList && _this3.accountList.length) {
          _this3.weixinMoney = _this3.accountList[0].money;
        }
      });
      getAccountListZFB().then(function (res) {
        _this3.accountListZFB = res.data || [];
        _this3.accountListCopyZfb = res.data || [];
        if (_this3.accountListZFB && _this3.accountListZFB.length) {
          _this3.ZFBMoney = _this3.accountListZFB[0].money;
        }
      });
      getAccountListBank().then(function (res) {
        _this3.accountListBank = res.data || [];
        _this3.accountListBankCopy = res.data || [];
        if (_this3.accountListBank && _this3.accountListBank.length) {
          _this3.bankMoney = _this3.accountListBank[0].money;
        }
      });
    },
    goDetail: function goDetail(record) {
      this.$router.push({
        path: "/paySalary/examineDetail",
        query: {
          record: JSON.stringify(record),
          pageState: true
        }
      });
    },
    getTransferAccountsBatches: function getTransferAccountsBatches() {
      var _this4 = this;
      this.loading = true;
      var par = {
        pageNo: this.current,
        pageSize: this.pageSize,
        selectName: this.selectName,
        subjectStatus: this.subjectStatus,
        payStatus: this.payStatus ? this.payStatus : undefined,
        gmtCreateStart: this.time[0] ? this.time[0] : "",
        gmtCreateEnd: this.time[1] ? this.time[1] : "",
        "orders[0].property": "payStatus",
        "orders[0].desc": true,
        "orders[1].property": "isDispose",
        "orders[1].desc": false,
        "orders[2].property": "gmtCreate",
        "orders[2].desc": true
      };
      _getTransferAccountsBatches(par).then(function (res) {
        _this4.dataList = res.data.entities;
        _this4.loading = false;
        _this4.entityCount = res.data.entityCount;

        // 缓存参数
        var p = JSON.parse(JSON.stringify(par));
        p.typeIndex = _this4.typeIndex;
        _this4.$setCondition("xzff_condition", JSON.stringify(p));
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this4.loading = false;
      });
    },
    onChange: function onChange(page, pageSize) {
      this.current = page;
      this.pageSize = pageSize;
      this.getTransferAccountsBatches();
    },
    goZFB: function goZFB() {
      window.open("https://b.alipay.com/index2.htm");
    },
    sizeChange: function sizeChange(page, pageSize) {
      this.current = page;
      this.pageSize = pageSize;
      this.getTransferAccountsBatches();
    },
    onSearch: function onSearch(e) {
      this.selectName = e;
      this.current = 1;
      this.pageSize = 10;
      this.getTransferAccountsBatches();
    },
    tabsChange: function tabsChange(e, o, t) {
      this.typeIndex = t;
      console.log("--------------");
      console.log(t);
      this.subjectStatus = e;
      this.payStatus = o;
      this.current = 1;
      this.pageSize = 10;
      this.getTransferAccountsBatches();
      if (t === 4) {
        this.tabsItem1 = "tabsItemOn";
        this.tabsItem2 = "tabsItem";
        this.tabsItem3 = "tabsItem";
        this.tabsItem4 = "tabsItem";
      } else if (t === 5) {
        this.tabsItem1 = "tabsItem";
        this.tabsItem2 = "tabsItemOn";
        this.tabsItem3 = "tabsItem";
        this.tabsItem4 = "tabsItem";
      } else if (t === 6) {
        this.tabsItem1 = "tabsItem";
        this.tabsItem2 = "tabsItem";
        this.tabsItem3 = "tabsItemOn";
        this.tabsItem4 = "tabsItem";
      } else if (t === 7) {
        this.tabsItem1 = "tabsItem";
        this.tabsItem2 = "tabsItem";
        this.tabsItem3 = "tabsItem";
        this.tabsItem4 = "tabsItemOn";
      }
    }
  }
};