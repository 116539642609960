import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";

// 查询部门信息byid
export function getDepInfosById(id, params) {
  return request({
    url: "/hr/api/v1/depInfos/".concat(id),
    method: "get",
    params: params
  });
}

// 查询部门信息
export function getDepartmentAll(params) {
  return request({
    url: "/hr/api/v1/depInfos/listAll",
    method: "get",
    params: params
  });
}

// 以树形结构获取部门信息
export function getDepartmentInTree(params) {
  return request({
    url: "/hr/api/v1/depInfos/listTree",
    method: "get",
    params: params
  });
}
export function getEmpDepInfoTree(params) {
  return request({
    url: "/hr/api/v1/depInfos/getEmpDepInfo",
    method: "get",
    params: params
  });
}
export function selectSchemeByEmpRelated(params) {
  return request({
    url: "/hr/api/v1/socProgs/selectSchemeByEmpRelated",
    method: "get",
    params: params
  });
}
export function refunds(data) {
  return request({
    url: "/hr/api/v1/empSocInfos/refunds",
    method: "post",
    data: data
  });
}

// 查询公司某部门下子部门及部门成员(包含子部门的成员)
export function getChildDepEmpInfo(companyId, id, params) {
  return request({
    url: "/hr/api/v1/depInfos/childDepEmpInfo/".concat(companyId, "/").concat(id),
    method: "get",
    params: params
  });
}

// 添加部门
export function addDepartment(data) {
  return request({
    url: "/hr/api/v1/depInfos/",
    method: "post",
    data: data
  });
}
// 修改部门
export function putDepartment(data) {
  return request({
    url: "/hr/api/v1/depInfos/",
    method: "put",
    data: data
  });
}

// 删除部门
export function delDepartment(id) {
  return request({
    url: "/hr/api/v1/depInfos/".concat(id),
    method: "delete"
  });
}

// 批量删除部门信息，彻底删除
export function delDepartments(data) {
  return request({
    url: "/hr/api/v1/depInfos",
    method: "delete",
    data: data
  });
}

// 根据条件分页查询员工(新组织架构)
export function getEmpInfoByEmp(companyId, params) {
  return request({
    url: "/hr/api/v1/empInfos/pageEmpInfoByEmp/".concat(companyId),
    method: "get",
    params: params
  });
}

// 查询公司下部门及部门成员(新组织架构接口)
export function getDepAndEmpInfo(companyId, params) {
  return request({
    url: "/hr/api/v1/depInfos/getDepAndEmpInfo/".concat(companyId),
    method: "get",
    params: params
  });
}

// /hr/api/v1/depInfos/getCompanyDepEmp/{companyId}查询公司下部门及部门成员(新组织架构接口,最顶级是公司级部门)
export function getCompanyDepEmp(companyId, params) {
  return request({
    url: "/hr/api/v1/depInfos/getCompanyDepEmp/".concat(companyId),
    method: "get",
    params: params
  });
}
export function getDepManageByDepId(params) {
  return request({
    url: "/hr/api/v1/depInfos/depManageByDepId",
    method: "get",
    params: params
  });
}
export function getSelectEmpByDepId(params) {
  return request({
    url: "/hr/api/v1/empDeps/selectEmpByDepId",
    method: "get",
    params: params
  });
}