import request from "@/utils/request";
export function addContract(data) {
  return request({
    url: "/contract/api/v1/contractCredentials",
    method: "POST",
    data: data
  });
}
export function getContract(params) {
  return request({
    url: "/contract/api/v1/contractCredentials/select",
    method: "get",
    params: params
  });
}
export function editContract(data) {
  return request({
    url: "/contract/api/v1/contractCredentials",
    method: "PUT",
    data: data
  });
}