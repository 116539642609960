import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      visible: _vm.visible,
      title: "停止报送"
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    }
  }, [_vm.status == 1 ? _c("a-form", {
    attrs: {
      form: _vm.form,
      selfUpdate: true,
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 14
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "本次停止报送"
    }
  }, [_vm._v(" " + _vm._s(_vm.recordIds.length) + "人 ")]), _c("a-form-item", {
    attrs: {
      label: "税款所属月"
    }
  }, [_c("a-month-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["period", {
        rules: [{
          required: true,
          message: "税款所属月不能为空"
        }]
      }],
      expression: "['period', { rules: [{ required: true, message: '税款所属月不能为空' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      valueFormat: "YYYYMM",
      placeholder: "请选择",
      allowClear: ""
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "离职日期"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["resignDate", {
        rules: [{
          required: true,
          message: "离职日期不能为空"
        }]
      }],
      expression: "['resignDate', { rules: [{ required: true, message: '离职日期不能为空' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      valueFormat: "YYYY-MM-DD",
      placeholder: "请选择",
      allowClear: ""
    }
  })], 1)], 1) : _c("a-form", {
    attrs: {
      form: _vm.formPassword,
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 14
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "密码类型"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["passwordType", {
        initialValue: 0,
        rules: [{
          required: true,
          message: "密码类型不能为空"
        }]
      }],
      expression: "[\n          'passwordType',\n          {\n            initialValue: 0,\n            rules: [\n              {\n                required: true,\n                message: '密码类型不能为空'\n              }\n            ]\n          }\n        ]"
    }],
    attrs: {
      placeholder: "请选择"
    }
  }, _vm._l(_vm.typeArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _vm.formPassword.getFieldValue("passwordType") == 0 ? _c("a-form-item", {
    attrs: {
      label: "申报密码"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["declarePassword", {
        rules: [{
          required: true,
          message: "申报密码不能为空"
        }]
      }],
      expression: "['declarePassword', { rules: [{ required: true, message: '申报密码不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入密码",
      maxLength: 50
    }
  })], 1) : _vm._e(), _vm.formPassword.getFieldValue("passwordType") == 2 ? _c("a-form-item", {
    attrs: {
      label: "实名账号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["realNameAccount", {
        rules: [{
          required: true,
          message: "实名账号不能为空"
        }]
      }],
      expression: "['realNameAccount', { rules: [{ required: true, message: '实名账号不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入账号",
      maxLength: 50
    }
  })], 1) : _vm._e(), _vm.formPassword.getFieldValue("passwordType") == 2 ? _c("a-form-item", {
    attrs: {
      label: "实名密码"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["realNamePassword", {
        rules: [{
          required: true,
          message: "实名密码不能为空"
        }]
      }],
      expression: "['realNamePassword', { rules: [{ required: true, message: '实名密码不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入密码",
      maxLength: 50
    }
  })], 1) : _vm._e()], 1), _vm.status == 1 ? _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        return _vm.$emit("update:visible", false);
      }
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleOk
    }
  }, [_vm._v(" 下一步 ")])], 1) : _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.onUp
    }
  }, [_vm._v(" 上一步 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.confirmLoading
    },
    on: {
      click: _vm.handleOk
    }
  }, [_vm._v(" 确认 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };