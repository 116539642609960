var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "organization page_set"
  }, [_c("a-layout", [_c("a-layout-sider", {
    staticClass: "border-right"
  }, [_c("div", {
    staticClass: "search-box-padding"
  }, [_c("a-input-search", {
    attrs: {
      placeholder: "搜索部门"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.searchParams,
      callback: function callback($$v) {
        _vm.searchParams = $$v;
      },
      expression: "searchParams"
    }
  })], 1), _c("div", {
    staticClass: "orgin-title",
    class: _vm.active == "active" ? "active " : "",
    on: {
      click: _vm.reloadData
    }
  }, [_c("img", {
    staticStyle: {
      width: "18px",
      height: "18px"
    },
    attrs: {
      src: require("../../assets/icons/icon1.png"),
      alt: ""
    }
  }), _c("span", {
    staticClass: "organization-left-name"
  }, [_vm._v(_vm._s(_vm.companyName))]), _c("a-icon", {
    class: _vm.rotate ? "rotate-a" : "rotate-b",
    attrs: {
      type: "reload"
    },
    on: {
      click: _vm.refushIcon
    }
  })], 1), _c("a-tree", {
    attrs: {
      defaultSelectedKeys: [_vm.selectedKeys],
      "show-icon": "",
      "default-expand-all": "",
      "tree-data": _vm.dataList,
      "expanded-keys": _vm.expandedKeys,
      autoExpandParent: _vm.autoExpandParent,
      blockNode: true,
      showIcon: true
    },
    on: {
      expand: _vm.onExpand,
      select: _vm.onSelect
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn(_ref) {
        var title = _ref.title;
        return [title.indexOf(_vm.searchValue) > -1 ? _c("span", [_vm._v(" " + _vm._s(title.substr(0, title.indexOf(_vm.searchValue))) + " "), _c("span", {
          staticStyle: {
            color: "#f50"
          }
        }, [_c("img", {
          staticStyle: {
            width: "15px",
            height: "15px"
          },
          attrs: {
            slot: "floder",
            src: require("../../assets/icons/icon2.png"),
            alt: ""
          },
          slot: "floder"
        }), _vm._v(" " + _vm._s(_vm.searchValue) + " ")]), _vm._v(" " + _vm._s(title.substr(title.indexOf(_vm.searchValue) + _vm.searchValue.length)) + " ")]) : _c("span", [_vm._v(_vm._s(title))])];
      }
    }])
  })], 1), _c("a-layout", {
    staticClass: "pt24 pl24 bgc-white"
  }, [_c("div", {
    staticClass: "pb24"
  }, [_c("a-row", [_c("span", {
    staticClass: "h1"
  }, [_vm._v(_vm._s(_vm.depName))]), _c("span", {
    staticClass: "tag-btn"
  }, [_vm._v(_vm._s(_vm.type === "2" ? "部门" : "公司"))]), _c("a-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.setDrawer(_vm.depName);
      }
    }
  }, [_vm._v("设置")])], 1), _c("a-row", [_vm._v(_vm._s(_vm.desc))])], 1), _vm.page ? _c("a-layout-content", [_c("a-tabs", {
    attrs: {
      "default-active-key": _vm.page
    },
    on: {
      change: _vm.changeTab
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "\u6210\u5458\u7BA1\u7406"
    }
  }, [_vm.page == 1 ? _c("member", _vm._g({
    ref: "member",
    attrs: {
      "selected-id": _vm.selectedKeys,
      company: _vm.isCompany
    },
    on: {
      dataLength: function dataLength($event) {
        return _vm.dataLength($event);
      },
      hasChanged: function hasChanged($event) {
        return _vm.hasChanged($event);
      },
      initData: _vm.initData,
      refush: _vm.refush
    }
  }, _vm.$listeners)) : _vm._e()], 1), _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "部门管理"
    }
  }, [_vm.page == 2 ? _c("department", {
    attrs: {
      company: _vm.isCompany,
      "selected-id": _vm.selectedKeys,
      level: _vm.level
    },
    on: {
      refreshList: _vm.onRefresList,
      getTree: _vm.getTreeList,
      updateDep: _vm.onUpdateDep
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)], 1), _c("a-drawer", {
    attrs: {
      title: "编辑",
      width: 720,
      visible: _vm.visibleCompany,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: function close($event) {
        _vm.visibleCompany = false;
      }
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.formCom,
      layout: "vertical"
    },
    on: {
      submit: _vm.handleCompanySubmit
    }
  }, [_c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "公司名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入公司名称"
        }]
      }],
      expression: "[\n                'name',\n                {\n                  rules: [{ required: true, message: '请输入公司名称' }]\n                }\n              ]"
    }],
    attrs: {
      placeholder: "请输入公司名称"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "官网地址"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["officialWebsite"],
      expression: "['officialWebsite']"
    }],
    attrs: {
      "addon-before": "https://",
      placeholder: "请输入公司网址"
    }
  })], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "公司地址"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["address"],
      expression: "['address']"
    }],
    attrs: {
      placeholder: "请输入公司地址"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "公司法人"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["legalPerson"],
      expression: "['legalPerson']"
    }],
    attrs: {
      placeholder: "请输入公司法人"
    }
  })], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "法人联系方式"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["telephone"],
      expression: "['telephone']"
    }],
    attrs: {
      placeholder: "请输入联系方式"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "联系邮箱"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["email"],
      expression: "['email']"
    }],
    attrs: {
      placeholder: "请输入邮箱"
    }
  })], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "公司规模"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["personSize"],
      expression: "['personSize']"
    }],
    attrs: {
      placeholder: "请选择人数"
    }
  }, _vm._l(_vm.person, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "所在行业"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["industry"],
      expression: "['industry']"
    }],
    attrs: {
      placeholder: "请选择行业"
    }
  }, _vm._l(_vm.industry, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1)], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "公司简介"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["profile"],
      expression: "['profile']"
    }],
    attrs: {
      placeholder: "请输入",
      rows: 4
    }
  })], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["id"],
      expression: "['id']"
    }],
    attrs: {
      type: "hidden"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "drawer-footer-box"
  }, [_c("a-button", {
    style: {
      marginRight: "12px"
    },
    on: {
      click: function click($event) {
        _vm.visibleCompany = false;
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    }
  }, [_vm._v("确定")])], 1)], 1)], 1), _c("a-drawer", {
    attrs: {
      title: _vm.title,
      width: 520,
      visible: _vm.visible,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: _vm.onClose
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.formSetting,
      layout: "vertical"
    },
    on: {
      submit: _vm.handleSubmit
    }
  }, [_c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入名称"
        }]
      }],
      expression: "[\n                'name',\n                {\n                  rules: [{ required: true, message: '请输入名称' }]\n                }\n              ]"
    }],
    attrs: {
      placeholder: "请输入名称"
    }
  })], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "简介"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["desc"],
      expression: "['desc']"
    }],
    attrs: {
      placeholder: "请输入",
      rows: 4
    }
  })], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-form-item", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["id"],
      expression: "['id']"
    }],
    attrs: {
      type: "hidden"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "drawer-footer-box"
  }, [_c("a-button", {
    style: {
      marginRight: "12px"
    },
    on: {
      click: _vm.delDep
    }
  }, [_vm._v(" 删除")]), _c("a-button", {
    style: {
      marginRight: "12px"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v(" 取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };