import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import { Wages, Payroll, Common } from "@/api/wages";
import { mapState } from "vuex";
export default {
  inject: ["reload"],
  name: "Payroll",
  components: {
    editorPayroll: function editorPayroll() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("../common/eidtor_payroll"));
      });
    }
  },
  data: function data() {
    return {
      showPayroll: false,
      depList: [],
      querySearch: {
        year: null,
        month: null
      },
      userList: null,
      data: [],
      payrollOptions: {}
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  watch: {
    querySearch: {
      handler: function handler(val) {
        var _this = this;
        Wages({
          url: Payroll.payrollQueryList,
          params: this.querySearch
        }).then(function (_ref) {
          var data = _ref.data;
          data.entities.forEach(function (da) {
            var keys = ["absenteeismDed", "businessCommission", "lateDed", "leaveDed", "overallDed", "overtimePay", "salaryBasic", "salaryReal", "supplementDed", "teamCommission", "subsidy"];
            keys.map(function (key, i) {
              da[key] /= 100;
            });
          });
          _this.data = data.entities;
        });
      },
      deep: true
    }
  },
  created: function created() {
    this.getPayrollList();
    /* 筛选选择人员 */
    this.getUserList();
    /* 部门列表 */
    this.getDepList();
  },
  methods: {
    /* 获取部们列表 */getDepList: function getDepList() {
      var _this2 = this;
      Wages({
        url: Common.depList,
        params: {
          companyId: this.$store.state.hr.companyId
        }
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this2.depList = _this2.deepLoop(data[0].depChildList);
      });
    },
    /* 处理部门需要色数据 */deepLoop: function deepLoop(data) {
      var _this3 = this;
      var needData = _toConsumableArray(data);
      var d = [];
      needData.map(function (val) {
        var title = val.name,
          key = val.id,
          value = val.id,
          children = val.depChildList;
        val.title = title;
        val.value = value;
        val.key = key;
        val.children = children;
        if (val.depChildList) {
          _this3.deepLoop(val.depChildList);
        }
        d.push(val);
      });
      return d;
    },
    /* 导出明细 */exportList: function exportList() {
      Wages({
        url: Payroll.exportList
      }).then(function (data) {});
      /*  this.exportExcel(this.data, '工资单') */
    },
    /* 编辑工资条 */editroPayroll: function editroPayroll(data) {
      var _this4 = this;
      var keys = ["absenteeismDed", "businessCommission", "lateDed", "leaveDed", "overallDed", "overtimePay", "salaryBasic", "salaryReal", "supplementDed", "teamCommission", "subsidy"];
      keys.map(function (key, i) {
        data[key] *= 100;
      });
      Wages({
        url: Payroll.editorPayroll,
        params: {
          companyId: this.hr.companyId
        },
        method: "PUT",
        data: data
      }).then(function (data) {
        data.success ? _this4.$notification["success"]({
          message: "保存成功"
        }) : null;
      });
    },
    /* 获取员工列表 */getUserList: function getUserList() {
      var _this5 = this;
      Wages({
        url: Common.userList,
        params: {
          companyId: this.$store.state.hr.companyId
        }
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this5.userList = data;
      });
    },
    /* 获取工资单列表 */getPayrollList: function getPayrollList() {
      var _this6 = this;
      Wages({
        url: Payroll.payrollList,
        params: {
          companyId: this.hr.companyId
        }
      }).then(function (_ref4) {
        var data = _ref4.data;
        var keys = ["absenteeismDed", "businessCommission", "lateDed", "leaveDed", "overallDed", "overtimePay", "salaryBasic", "salaryReal", "supplementDed", "teamCommission", "subsidy"];
        data.entities.forEach(function (d, i) {
          keys.map(function (key) {
            d[key] = d[key] / 100;
          });
        });
        _this6.data = data.entities;
      });
    },
    // 搜索
    onSearch: function onSearch(value) {},
    // 日期筛选
    selectJoinDate: function selectJoinDate(e, str) {
      this.querySearch.year = str.split("-")[0];
      this.querySearch.month = str.split("-")[1];
    },
    // 发送
    start: function start(e) {},
    // 编辑
    editor: function editor(e) {
      this.showPayroll = true;
      this.payrollOptions = e;
    },
    // 选择人员
    handleSelectChange: function handleSelectChange(value) {}
  }
};