import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-bgBack"
  }, [_c("rTitle", {
    attrs: {
      type: "back",
      tit: _vm.$route.query.id ? "编辑职位" : "发布职位"
    }
  }), _c("div", {
    staticClass: "r-add-box"
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 17
      }
    }
  }, [_c("div", {
    staticClass: "form-box"
  }, [_c("fTitle", {
    attrs: {
      tit: "基础信息",
      desc: "职位发布成功后，招工企业无法修改"
    }
  }), _c("a-row", {
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "职位名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postName", {
        rules: [{
          required: true,
          message: "职位名称不能为空"
        }]
      }],
      expression: "['postName', { rules: [{ required: true, message: '职位名称不能为空' }] }]"
    }],
    staticClass: "input-w336",
    attrs: {
      placeholder: "请输入职位名称"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "招工企业"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyUnitId", {
        rules: [{
          required: true,
          message: "招工企业不能为空"
        }]
      }],
      expression: "[\n                  'companyUnitId',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: '招工企业不能为空'\n                      }\n                    ]\n                  }\n                ]"
    }],
    staticClass: "input-w336",
    attrs: {
      disabled: _vm.$route.query.id ? true : false,
      placeholder: "选择招工企业"
    },
    on: {
      change: _vm.onChangeCompanyUnit
    }
  }, _vm._l(_vm.companyIdArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), !_vm.isPerfect ? _c("div", {
    staticClass: "ws"
  }, [_vm._v("* 请先完善企业信息 "), _c("a", {
    on: {
      click: _vm.showlaborUnitModal
    }
  }, [_vm._v("点击完善")])]) : _vm._e()], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "岗位招聘负责人员"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["contactUserId", {
        rules: [{
          required: true,
          message: "负责人员不能为空"
        }]
      }],
      expression: "['contactUserId', { rules: [{ required: true, message: '负责人员不能为空' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.adminUsersFormatted,
      filterOption: _vm.filterOption,
      showSearch: "",
      placeholder: "请选择负责人员"
    },
    on: {
      change: _vm.onContactChange
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "岗位联系电话"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["phone", {
        rules: [{
          required: true,
          message: "联系电话不能为空"
        }]
      }],
      expression: "['phone', { rules: [{ required: true, message: '联系电话不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入联系电话"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "职位类型"
    }
  }, [_c("a-cascader", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["positionType", {
        rules: [{
          required: true,
          message: "职位类型不能为空"
        }]
      }],
      expression: "['positionType', { rules: [{ required: true, message: '职位类型不能为空' }] }]"
    }],
    attrs: {
      options: _vm.positionTypes,
      fieldNames: {
        label: "name",
        value: "id",
        children: "childList"
      },
      placeholder: "请选择职位类型"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      label: "学历经验"
    }
  }, [_c("a-form-item", {
    staticStyle: {
      float: "left",
      width: "160px",
      "margin-right": "16px"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["workingSeniority", {
        initialValue: 1
      }],
      expression: "['workingSeniority', { initialValue: 1 }]"
    }],
    attrs: {
      placeholder: "选择经验"
    }
  }, _vm._l(_vm.workingSeniorityArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.v) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    staticStyle: {
      float: "left",
      width: "160px"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["lowestEducation", {
        initialValue: 8
      }],
      expression: "['lowestEducation', { initialValue: 8 }]"
    }],
    attrs: {
      placeholder: "选择学历"
    }
  }, _vm._l(_vm.lowestEducationArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.v) + " ")]);
  }), 1)], 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "工作城市"
    }
  }, [_c("a-cascader", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["cityName", {
        rules: [{
          required: true,
          message: "工作城市不能为空"
        }]
      }],
      expression: "['cityName', { rules: [{ required: true, message: '工作城市不能为空' }] }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      options: _vm.common.cityList,
      placeholder: "请选择工作城市"
    },
    on: {
      change: _vm.onCityChange
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "具体工作地点"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["detailedAddress", {
        rules: [{
          required: true,
          message: "工作地点不能为空"
        }]
      }],
      expression: "['detailedAddress', { rules: [{ required: true, message: '工作地点不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入工作地点"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "工作性质"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["workType", {
        initialValue: 1
      }],
      expression: "['workType', { initialValue: 1 }]"
    }]
  }, _vm._l(_vm.workTypeArr, function (item) {
    return _c("a-radio", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.v) + " ")]);
  }), 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "是否急招"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["isEmergency", {
        initialValue: false
      }],
      expression: "['isEmergency', { initialValue: false }]"
    }]
  }, _vm._l(_vm.isEmergencyArr, function (item) {
    return _c("a-radio", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.v) + " ")]);
  }), 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "职位描述",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 21
      }
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postDescribe", {
        rules: [{
          required: true,
          message: "职位描述不能为空"
        }]
      }],
      expression: "['postDescribe', { rules: [{ required: true, message: '职位描述不能为空' }] }]"
    }],
    staticStyle: {
      width: "814px"
    },
    attrs: {
      placeholder: "请输入职位描述",
      "auto-size": {
        minRows: 8,
        maxRows: 15
      }
    }
  })], 1)], 1)], 1), _c("fTitle", {
    staticClass: "mt10",
    attrs: {
      tit: "招聘类型",
      desc: "选择招满即停类型时，招聘人数一满就会自动关闭岗位 "
    }
  }), _c("a-row", {
    staticStyle: {
      "margin-left": "-72px"
    },
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: " ",
      colon: false,
      labelCol: {
        span: 2
      }
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["recruitType", {
        initialValue: 2
      }],
      expression: "['recruitType', { initialValue: 2 }]"
    }],
    on: {
      change: _vm.onNumberChange
    }
  }, [_c("a-radio", {
    key: "cqzp",
    attrs: {
      value: 1
    }
  }, [_vm._v("长期招聘")]), _c("a-radio", {
    key: "zmjt",
    attrs: {
      value: 2
    }
  }, [_vm._v("招满即停")])], 1)], 1)], 1), _vm.showNumber ? _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "招聘人数"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["number", {
        initialValue: 10,
        rules: [{
          required: true,
          message: "请输入招聘人数"
        }]
      }],
      expression: "[\n                  'number',\n                  {\n                    initialValue: 10,\n                    rules: [{ required: true, message: '请输入招聘人数' }]\n                  }\n                ]"
    }],
    staticClass: "input-w336",
    attrs: {
      min: 1,
      precision: 0,
      max: 99999999,
      placeholder: "请输入招聘人数"
    }
  })], 1)], 1) : _vm._e()], 1), _c("fTitle", {
    staticClass: "mt10",
    attrs: {
      tit: "薪资及福利",
      desc: "请仔细填写，可以为您吸引更多的人才"
    }
  }), _c("a-row", {
    staticStyle: {
      "margin-left": "-72px"
    },
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      label: "薪资范围"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["sss", {
        rules: [{
          required: true
        }]
      }],
      expression: "['sss', { rules: [{ required: true }] }]"
    }, {
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }]
  }), _c("a-form-item", {
    staticStyle: {
      display: "inline-block",
      width: "102px"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["lowestSalary", {
        rules: [{
          required: true,
          message: "请正确输入薪资"
        }]
      }],
      expression: "['lowestSalary', { rules: [{ required: true, message: '请正确输入薪资' }] }]"
    }],
    attrs: {
      min: 0,
      max: 9999999,
      placeholder: "请输入薪资"
    }
  })], 1), _c("span", {
    staticStyle: {
      display: "inline-block",
      width: "20px",
      "text-align": "center",
      margin: "0 2px"
    }
  }, [_vm._v(" - ")]), _c("a-form-item", {
    staticStyle: {
      display: "inline-block",
      width: "102px"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["highestSalary", {
        rules: [{
          required: true,
          message: "请正确输入薪资"
        }]
      }],
      expression: "['highestSalary', { rules: [{ required: true, message: '请正确输入薪资' }] }]"
    }],
    attrs: {
      min: 0,
      max: 9999999,
      placeholder: "请输入薪资"
    }
  })], 1), _c("span", {
    staticStyle: {
      display: "inline-block",
      width: "30px",
      "text-align": "center",
      margin: "0 2px"
    }
  }, [_vm._v(" 每 ")]), _c("a-form-item", {
    staticStyle: {
      display: "inline-block",
      width: "74px"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["salaryGrantType", {
        initialValue: 2
      }],
      expression: "['salaryGrantType', { initialValue: 2 }]"
    }]
  }, _vm._l(_vm.monthlySalaryArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.v) + " ")]);
  }), 1)], 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "发薪日"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["payday", {
        initialValue: 15
      }, {
        rules: [{
          required: true,
          message: "发薪日不能为空"
        }]
      }],
      expression: "[\n                  'payday',\n                  { initialValue: 15 },\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: '发薪日不能为空'\n                      }\n                    ]\n                  }\n                ]"
    }],
    staticClass: "input-w336",
    attrs: {
      placeholder: "选择月份"
    }
  }, [_vm._l(31, function (item) {
    return _c("a-select-option", {
      key: item,
      attrs: {
        value: item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), _vm._l(_vm.paydayArr, function (item) {
    return _c("a-select-option", {
      key: item.val,
      attrs: {
        value: item.val
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "福利待遇"
    }
  }, [_c("a-select", {
    staticClass: "input-w336",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      maxTagCount: 3,
      mode: "tags",
      placeholder: "请选择福利"
    },
    model: {
      value: _vm.highlights,
      callback: function callback($$v) {
        _vm.highlights = $$v;
      },
      expression: "highlights"
    }
  })], 1)], 1)], 1), _c("fTitle", {
    staticClass: "mt10",
    attrs: {
      tit: "办公环境",
      desc: "上传办公室环境，可以招聘到更合适的人才"
    }
  }), _c("div", {
    staticClass: "lable-t"
  }, [_vm._v("上传图片 "), _c("span", [_vm._v("支持最多上传九张图片，支持批量上传")])]), _c("a-form-item", {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_c("a-upload", {
    attrs: {
      accept: ".jpg, .png",
      "list-type": "picture-card",
      action: "/file/api/v1/general/uploadImg",
      name: "files",
      multiple: true,
      "file-list": _vm.fileList,
      beforeUpload: _vm.beforeUpload
    },
    on: {
      preview: _vm.handlePreview,
      change: _vm.handleUpChange
    }
  }, [_vm.fileList.length < 9 ? _c("div", [_c("a-icon", {
    staticClass: "icon-color",
    attrs: {
      type: "plus-circle",
      theme: "filled"
    }
  }), _c("div", {
    staticClass: "font"
  }, [_vm._v("点击上传图片")]), _c("div", {
    staticClass: "font"
  }, [_vm._v("支持jpg，png格式")]), _c("div", {
    staticClass: "font"
  }, [_vm._v("单张尺寸不得大于1M")])], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.previewVisible,
      footer: null
    },
    on: {
      cancel: function cancel($event) {
        _vm.previewVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "mt20"
  }), _c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      alt: "example",
      src: _vm.previewImage
    }
  })])], 1), _c("div", {
    staticClass: "lable-t"
  }, [_vm._v(" 上传视频 "), _c("span", [_vm._v("支持最多上传三个视频，支持批量上传；支持mp4格式； 单个视频不得大于10M；视频封面支持jpg，png格式；单张尺寸不得超过1M")])]), _c("a-form-item", {
    attrs: {
      label: ""
    }
  }, [_c("a-upload", {
    attrs: {
      accept: ".mp4",
      action: "/file/api/v1/general/upload/inline",
      name: "files",
      disabled: _vm.statusV,
      beforeUpload: _vm.beforeUploadV,
      showUploadList: false
    },
    on: {
      change: _vm.videoHandleUpChange
    }
  }, [_vm.newVideoFileList.length < 3 ? _c("div", [_c("a-button", {
    staticClass: "mr12",
    attrs: {
      type: "primary",
      loading: _vm.statusV
    }
  }, [_vm._v(" 点击上传 ")])], 1) : _vm._e()]), _vm._l(_vm.newVideoFileList, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "video-crad mt12"
    }, [_c("div", {
      staticClass: "video-v"
    }, [_c("div", [_c("a-icon", {
      staticClass: "icon-color",
      attrs: {
        type: "play-circle",
        theme: "filled"
      }
    }), _c("span", {
      staticClass: "ml12"
    }, [_vm._v(_vm._s(item.videoName))])], 1), _c("div", [_vm._v("上传成功")]), _c("div", [!item.imgurl ? _c("a-upload", {
      attrs: {
        accept: ".jpg, .jpeg, .png",
        action: "/file/api/v1/general/uploadImg",
        name: "files",
        disabled: _vm.videoNum == index,
        beforeUpload: _vm.beforeUpload,
        showUploadList: false
      },
      on: {
        change: function change(e) {
          return _vm.videoimgHandleUpChange(e, index);
        }
      }
    }, [_c("span", [_c("a-button", {
      staticClass: "mr12",
      staticStyle: {
        padding: "0"
      },
      attrs: {
        type: "link",
        disabled: _vm.videoNum == index,
        loading: _vm.videoNum == index
      }
    }, [_vm._v(" 上传封面 ")])], 1)]) : _vm._e(), _c("a", {
      staticClass: "mr12",
      on: {
        click: function click($event) {
          return _vm.onView(item, "video");
        }
      }
    }, [_vm._v("预览")]), _c("a", {
      on: {
        click: function click($event) {
          return _vm.onDel(index, "video");
        }
      }
    }, [_vm._v("删除")])], 1)]), item.imgurl ? _c("div", {
      staticClass: "video-img"
    }, [_c("div", [_c("a-icon", {
      staticClass: "icon-color",
      attrs: {
        type: "picture",
        theme: "filled"
      }
    }), _c("span", {
      staticClass: "ml12"
    }, [_vm._v(_vm._s(item.imgurlName))])], 1), _c("div", [_vm._v("上传成功")]), _c("div", [_c("a", {
      staticClass: "mr12",
      on: {
        click: function click($event) {
          return _vm.onView(item, "img");
        }
      }
    }, [_vm._v("预览")]), _c("a", {
      on: {
        click: function click($event) {
          return _vm.onDel(index, "img");
        }
      }
    }, [_vm._v("删除")])])]) : _vm._e()]), _c("a-form-item", {
      staticClass: "fmcw",
      attrs: {
        label: ""
      }
    }, [_c("a-input", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: false,
        expression: "false"
      }, {
        name: "decorator",
        rawName: "v-decorator",
        value: ["imgurl".concat(index), {
          rules: [{
            required: true,
            message: "封面不能为空"
          }]
        }],
        expression: "[`imgurl${index}`, { rules: [{ required: true, message: '封面不能为空' }] }]"
      }]
    })], 1)], 1);
  })], 2), _c("fTitle", {
    staticClass: "mt10",
    attrs: {
      tit: "附加信息",
      desc: "支持在创建职位时，将职位设置为内推职位或经纪人职位；可在内推职位/经纪人职位管理处进行职位关闭；"
    }
  }), _c("div", {
    staticClass: "switch-box",
    style: _vm.form.getFieldValue("isEmp") ? "padding-bottom: 24px" : ""
  }, [_c("a-form-item", {
    attrs: {
      label: "内推职位",
      "label-col": {
        span: 2
      }
    }
  }, [_c("a-switch", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["isEmp", {
        valuePropName: "checked"
      }],
      expression: "['isEmp', { valuePropName: 'checked' }]"
    }],
    staticStyle: {
      "margin-left": "6px"
    },
    attrs: {
      disabled: _vm.$route.query.id ? true : false
    }
  })], 1), _vm.form.getFieldValue("isEmp") ? _c("a-form-item", {
    staticClass: "empCompanyIds",
    attrs: {
      label: "内推范围",
      "label-col": {
        span: 2
      }
    }
  }, [_c("div", {
    staticClass: "qy"
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["empCompanyIdsInput", {
        rules: [{
          required: true,
          message: "请选择内推范围"
        }]
      }],
      expression: "['empCompanyIdsInput', { rules: [{ required: true, message: '请选择内推范围' }] }]"
    }, {
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }]
  }), _c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: _vm.$route.query.id ? true : false
    },
    on: {
      click: function click($event) {
        _vm.selectScopeVisible = true;
      }
    }
  }, [_vm._v(" 选择企业 ")]), _vm.selectedListTemp.length > 0 ? _c("div", {
    staticClass: "yxz"
  }, [_vm._v(" 已选择 "), _vm._l(_vm.selectedListTemp, function (item, index) {
    return _c("span", {
      key: index
    }, [index < 2 ? _c("span", [index == 1 ? _c("span", [_vm._v(" 、")]) : _vm._e(), _vm._v(" " + _vm._s(item.name) + " ")]) : _vm._e()]);
  }), _vm.selectedListTemp.length > 2 ? _c("span", [_vm._v(" 等 "), _c("span", {
    staticClass: "icon-color"
  }, [_vm._v(_vm._s(_vm.selectedListTemp.length))]), _vm._v(" 家 ")]) : _vm._e()], 2) : _c("span", [_vm._v("当前未选择")])], 1)]) : _vm._e(), _vm.form.getFieldValue("isEmp") ? _c("a-form-item", {
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      label: "赏金规则",
      "label-col": {
        span: 2
      }
    }
  }, [_c("pushSet", {
    ref: "pushSet",
    attrs: {
      val: _vm.pushSetVal,
      empRuleIsOpen: _vm.empRuleIsOpen,
      empMoneyReward: _vm.empMoneyReward
    }
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "switch-box",
    style: _vm.form.getFieldValue("isBroker") ? "padding-bottom: 2px" : ""
  }, [_c("a-form-item", {
    staticStyle: {
      "margin-left": "-6px"
    },
    attrs: {
      label: "经纪人职位",
      "label-col": {
        span: 2
      }
    }
  }, [_c("a-switch", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["isBroker", {
        valuePropName: "checked"
      }],
      expression: "['isBroker', { valuePropName: 'checked' }]"
    }],
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      disabled: _vm.$route.query.id ? true : false
    }
  })], 1), _vm.form.getFieldValue("isBroker") ? _c("a-form-item", {
    attrs: {
      label: "赏金规则",
      "label-col": {
        span: 2
      }
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["brokerRule", {
        rules: [{
          required: true,
          message: "请正确输入赏金规则"
        }]
      }],
      expression: "['brokerRule', { rules: [{ required: true, message: '请正确输入赏金规则' }] }]"
    }],
    staticStyle: {
      "margin-left": "6px"
    },
    attrs: {
      placeholder: "请输入赏金规则",
      "auto-size": {
        minRows: 8,
        maxRows: 15
      },
      disabled: _vm.$route.query.id ? true : false
    }
  })], 1) : _vm._e()], 1)], 1), _c("div", {
    staticClass: "submit"
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading,
      disabled: !_vm.isPerfect
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v(" 发布 ")])], 1)])], 1), _c("a-modal", {
    attrs: {
      footer: null,
      width: "500px"
    },
    on: {
      cancel: function cancel($event) {
        _vm.ylVisible = false;
      }
    },
    model: {
      value: _vm.ylVisible,
      callback: function callback($$v) {
        _vm.ylVisible = $$v;
      },
      expression: "ylVisible"
    }
  }, [_c("div", {
    staticClass: "mt20"
  }), _vm.ylType == "img" ? _c("img", {
    attrs: {
      src: _vm.ylUrl,
      width: "100%",
      height: "100%"
    }
  }) : _vm._e(), _vm.ylType == "video" ? _c("video", {
    attrs: {
      src: _vm.ylUrl,
      controls: "",
      controlsList: "nodownload",
      width: "100%"
    }
  }) : _vm._e()]), _c("selectScope", {
    attrs: {
      visible: _vm.selectScopeVisible,
      selectedList: _vm.selectedListTemp
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.selectScopeVisible = $event;
      },
      upDate: _vm.upDate
    }
  }), _c("LaborUnitModel", {
    attrs: {
      visible: _vm.laborUnitModelVisible,
      mType: _vm.laborUnitMType,
      id: _vm.laborUnitId,
      dataJson: _vm.laborUnitDataJson,
      ws: true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.laborUnitModelVisible = $event;
      },
      upDate: _vm.laborUnitModelUpDate
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };