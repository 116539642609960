import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "AddPayslipSecond",
  components: {
    step: function step() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./step"));
      });
    }
  },
  data: function data() {
    return {
      // 参数
      postParams: {
        sendType: 1,
        autoCheckHours: 1,
        showEmptyValue: false,
        autoCheckUnit: 1
      }
    };
  },
  computed: _objectSpread({}, mapGetters({
    PAYSLIP: "wages/PAYSLIP"
  })),
  beforeDestroy: function beforeDestroy() {
    this.setSalaryParams(_objectSpread({}, this.postParams));
  },
  methods: _objectSpread(_objectSpread({
    limitNumber: function limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      } else {
        return 0;
      }
    },
    // 上一步
    doBefor: function doBefor() {
      var path = "/wages/add-payslip-sort";
      this.$router.push({
        path: path,
        query: _objectSpread(_objectSpread({}, this.$route.query), this.postParams)
      });
    }
  }, mapMutations({
    postParam: "wages/postParams",
    setSalaryParams: "wages/setSalaryparams"
  })), {}, {
    // 选择方式
    handleChange: function handleChange(e) {},
    handleChangeTime: function handleChangeTime(e) {},
    // 下一步
    next: function next() {
      this.$router.push({
        path: "add-payslip-third",
        query: _objectSpread(_objectSpread({}, this.$route.query), this.postParams)
      });
    }
  })
};