import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
export function reviseCompanyName(data, name, sourceList) {
  var sourceMap = {};
  if (sourceList) {
    for (var i = 0; i < sourceList.length; i++) {
      sourceMap[sourceList[i].id] = sourceList[i];
    }
  }
  try {
    data.forEach(function (item) {
      var sourceItem = sourceMap[item.id];
      if (sourceItem) {
        item.conVariableMap = sourceItem.conVariableMap || [];
        item.contractStartTime = sourceItem.contractStartTime;
        item.contractExpire = sourceItem.contractExpire;
        item.timelimitType = sourceItem.timelimitType;
        item.contractEndTime = sourceItem.contractEndTime;
        item.contractType = sourceItem.contractType;
      } else {
        item.conVariableMap.forEach(function (variable, index) {
          Object.keys(variable).forEach(function (v) {
            if (v === "合同公司") {
              item.conVariableMap[index][v].content = name;
            }
          });
        });
      }
    });
  } catch (err) {
    console.log(err);
  }
  return data;
}
export var contractTypeList = [{
  key: "laborContractLimit",
  value: "固定期限劳动合同"
}, {
  key: "laborContractUnlimit",
  value: "无固定期限劳动合同"
}, {
  key: "laborContractJobs",
  value: "以完成一定工作任务为期限的劳动合同"
}, {
  key: "internAccords",
  value: "实习协议"
}, {
  key: "laborContract",
  value: "劳务合同"
}, {
  key: "reemployAccords",
  value: "返聘协议"
}, {
  key: "laborContractDispatch",
  value: "劳务派遣合同"
}, {
  key: "borrowedContract",
  value: "借调合同"
}, {
  key: "engagementLetter",
  value: "约定书"
}, {
  key: "partTime",
  value: "兼职合同"
}, {
  key: "noviciateAgreement",
  value: "见习协议"
}, {
  key: "dimissionContract",
  value: "离职合同"
}, {
  key: "retireReEmploy",
  value: "退休返聘合同"
}, {
  key: "other",
  value: "其它"
}];
export var contractTypeDict = {
  laborContractLimit: "固定期限劳动合同",
  laborContractUnlimit: "无固定期限劳动合同",
  laborContractJobs: "以完成一定工作任务为期限的劳动合同",
  internAccords: "实习协议",
  laborContract: "劳务合同",
  reemployAccords: "返聘协议",
  laborContractDispatch: "劳务派遣合同",
  borrowedContract: "借调合同",
  engagementLetter: "约定书",
  partTime: "兼职合同",
  noviciateAgreement: "见习协议",
  dimissionContract: "离职合同",
  retireReEmploy: "退休返聘合同",
  other: "其它"
};
export var contractColumns = [{
  title: "员工姓名",
  dataIndex: "name",
  key: "name",
  width: 120,
  fixed: "left"
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  width: 150,
  fixed: "left"
}, {
  title: "用工单位名称",
  dataIndex: "companyName",
  key: "companyName",
  width: 200
}, {
  title: "合同起始时间",
  dataIndex: "contractStartTime",
  key: "contractStartTime",
  width: 200,
  scopedSlots: {
    customRender: "contractStartTime"
  }
}, {
  title: "合同期限",
  dataIndex: "contractExpire",
  key: "contractExpire",
  width: 200,
  scopedSlots: {
    customRender: "contractExpire"
  }
}, {
  title: "合同结束时间",
  dataIndex: "contractEndTime",
  key: "contractEndTime",
  width: 200,
  scopedSlots: {
    customRender: "contractEndTime"
  }
}, {
  title: "合同类型",
  dataIndex: "contractType",
  key: "contractType",
  width: 180,
  scopedSlots: {
    customRender: "contractType"
  }
}, {
  title: "操作",
  dataIndex: "action",
  width: 150,
  fixed: "right",
  scopedSlots: {
    customRender: "action"
  }
}];