import _slicedToArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
var mixin = {
  methods: {
    formatDate: formatDate,
    formatDateAll: formatDateAll,
    confirm: confirm,
    uuid: uuid,
    awaitWrap: awaitWrap
  },
  filters: {
    formatDate: formatDate,
    formatDateAll: formatDateAll
  }
};
export default mixin;
function formatDate(date) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "s";
  var _date = new Date(date);
  var y = _date.getFullYear();
  var mm = _date.getMonth() + 1;
  var day = _date.getDate();
  var h = _date.getHours();
  var m = _date.getMinutes();
  var s = _date.getSeconds();
  // 个位补零
  var _b = [y, mm, day, h, m, s].map(function (n) {
    return n.toString()[1] ? n : "0" + n;
  });
  // 解构赋值取值
  var _b2 = _slicedToArray(_b, 6),
    a = _b2[0],
    b = _b2[1],
    c = _b2[2],
    d = _b2[3],
    e = _b2[4],
    f = _b2[5];
  if (type.endsWith("d")) return "".concat(a, "-").concat(b, "-").concat(c);
  return "".concat(a, "-").concat(b, "-").concat(c, " ").concat(d, ":").concat(e, ":").concat(f);
}
function formatDateAll(date) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "s";
  var _date = new Date(date);
  var y = _date.getFullYear();
  var mm = _date.getMonth() + 1;
  var day = _date.getDate();
  var h = _date.getHours();
  var m = _date.getMinutes();
  var s = _date.getSeconds();
  // 个位补零
  var _b = [y, mm, day, h, m, s].map(function (n) {
    return n.toString()[1] ? n : "0" + n;
  });
  // 解构赋值取值
  var _b3 = _slicedToArray(_b, 6),
    a = _b3[0],
    b = _b3[1],
    c = _b3[2],
    d = _b3[3],
    e = _b3[4],
    f = _b3[5];
  if (type.endsWith("d")) return "".concat(a, "-").concat(b, "-").concat(c, " ").concat(d, ":").concat(e, ":").concat(f);
}
function confirm() {
  var _this = this;
  var msg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "执行删除操作,是否继续?";
  var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "提示";
  return new Promise(function (resolve, reject) {
    _this.$confirm(msg, title, {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(function () {
      _this.$message({
        type: "success",
        message: "操作成功!"
      });
      resolve("success");
    }).catch(function () {
      _this.$message({
        type: "info",
        message: "操作取消!"
      });
      // resolve("false");
    });
  });
}
/*
 * @description: uuid
 * @Author: Coder
 * @Date: 2020-01-20 09:27:44
 * @LastEditors: Coder
 */
function uuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";
  var uuid = s.join("");
  return uuid;
}
function awaitWrap(promise) {
  return promise.then(function (data) {
    return [null, data];
  }).catch(function (err) {
    return [err, null];
  });
}