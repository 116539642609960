import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";
export function selectCustomerConfig(params) {
  return request({
    url: "/insurance/api/v1/customerConfigs/selectCustomerConfig",
    method: "get",
    params: params
  });
}
export function selectCustomerCompanyConfig(params) {
  return request({
    url: "/insurance/api/v1/customerConfigs/selectCustomerCompanyConfig",
    method: "get",
    params: params
  });
}
export function getOrderList(params) {
  return request({
    url: "/insurance/api/v1/orders",
    method: "get",
    params: params
  });
}
export function reUpload(data) {
  return request({
    url: "/insurance/api/v1/orders/reUpload",
    method: "put",
    data: data
  });
}
export function recordList(params) {
  return request({
    url: "/insurance/api/v1/orderCirculations",
    method: "get",
    params: params
  });
}
export function selectCompanyBySiteId(siteId) {
  return request({
    url: "/insurance/api/v1/companies/selectCompanyBySiteId/".concat(siteId),
    method: "get"
  });
}
export function selectCompanyById(id) {
  return request({
    url: "/insurance/api/v1/companies/selectCompanyById/".concat(id),
    method: "get"
  });
}
export function addorder(data) {
  return request({
    url: "/insurance/api/v1/orders",
    method: "post",
    data: data
  });
}
export function getDetailByOrderId(orderId, type) {
  return request({
    url: "/insurance/api/v1/orders/".concat(orderId, "/").concat(type),
    method: "get"
  });
}
export function topStatistic(params) {
  return request({
    url: "/insurance/api/v1/orders/topStatistic",
    method: "get",
    params: params
  });
}
export function undoOrder(orderId) {
  return request({
    url: "/insurance/api/v1/orders/undoOrder/".concat(orderId),
    method: "put"
  });
}
export function companyAudit(data) {
  return request({
    url: "/insurance/api/v1/orders/companyAudit",
    method: "put",
    data: data
  });
}
export function getDownloadFile(params) {
  return request({
    url: "/file/api/v1/general/download",
    method: "get",
    params: params
  });
}