import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.function.name.js";
import ItemList from "./list.vue";
import Draggable from "vuedraggable";
export default {
  name: "",
  components: {
    ItemList: ItemList,
    Draggable: Draggable
  },
  mixins: [],
  props: {},
  data: function data() {
    return {
      groupModalTitle: "添加分组",
      groupModalVisible: false,
      groupForm: this.$form.createForm(this),
      groupLst: [],
      tempGroup: null,
      confirmLoadingGroup: false,
      groupTerm: "",
      currentGroup: null,
      emptyImg: require("../../admin/chanpinjieshao/empty.png")
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.onGroupSearchChange();
  },
  mounted: function mounted() {},
  methods: {
    onDragEnd: function onDragEnd() {
      this.$request({
        url: "/hr/api/v1/policyCourierTypes/updateSort",
        method: "put",
        data: this.groupLst.map(function (item, idx) {
          return {
            id: item.id,
            sort: idx
          };
        })
      });
    },
    onGroupClick: function onGroupClick(item) {
      this.currentGroup = item;
      localStorage.setItem("tempTypeCode", item.code);
    },
    onGroupSearchChange: function onGroupSearchChange() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/policyCourierTypes/listAll"
      }).then(function (res) {
        _this.groupLst = res.data;
        var tempTc = _this.currentGroup ? _this.currentGroup.code : localStorage.getItem("tempTypeCode");
        var idx = res.data.findIndex(function (item) {
          return item.code === tempTc;
        });
        if (idx > -1) {
          if (_this.groupLst.length > 0) {
            _this.currentGroup = _this.groupLst[idx];
          } else {
            _this.currentGroup = null;
          }
        } else {
          if (_this.groupLst.length > 0) {
            _this.currentGroup = _this.groupLst[0];
          } else {
            _this.currentGroup = null;
          }
        }
      }).catch(function (err) {
        console.log("policyCourierTypes/listAll", err);
      });
    },
    onDelGroupItem: function onDelGroupItem() {
      var _this2 = this;
      this.$confirm({
        title: "删除确认",
        content: "分类删除后将无法恢复，确认要删除吗？",
        onOk: function onOk() {
          _this2.$request({
            url: "/hr/api/v1/policyCourierTypes/".concat(_this2.tempGroup.id),
            method: "delete"
          }).then(function () {
            _this2.$message.success("删除成功");
            _this2.onGroupSearchChange();
            _this2.groupModalVisible = false;
          }).catch(function (err) {
            console.log("deleteGroupReq", err);
          });
        }
      });
    },
    onAddGroupItem: function onAddGroupItem() {
      this.groupForm.resetFields();
      this.groupModalTitle = "添加分类";
      this.tempGroup = null;
      this.groupModalVisible = true;
    },
    onEditGroupItem: function onEditGroupItem(target) {
      var _this3 = this;
      this.tempGroup = target;
      this.groupModalTitle = "编辑分类";
      this.groupModalVisible = true;
      setTimeout(function () {
        _this3.groupForm.setFieldsValue({
          name: target.name
        });
      }, 0);
    },
    onGroupModalOk: function onGroupModalOk() {
      var _this4 = this;
      this.groupForm.validateFields(function (err, values) {
        if (!err) {
          var newValues = {
            name: values.name
          };
          _this4.confirmLoadingGroup = true;
          if (_this4.groupModalTitle === "添加分类") {
            _this4.$request({
              url: "/hr/api/v1/policyCourierTypes",
              method: "post",
              data: newValues
            }).then(function (res) {
              _this4.$message.success("添加成功");
              _this4.onGroupSearchChange();
              _this4.groupModalVisible = false;
              _this4.confirmLoadingGroup = false;
            }).catch(function (err) {
              _this4.confirmLoadingGroup = false;
              console.log("saveGroupReq", err);
            });
          } else {
            _this4.$request({
              url: "/hr/api/v1/policyCourierTypes",
              method: "put",
              data: _objectSpread(_objectSpread({}, newValues), {}, {
                id: _this4.tempGroup.id
              })
            }).then(function (res) {
              _this4.$message.success("修改成功");
              _this4.onGroupSearchChange();
              _this4.groupModalVisible = false;
              _this4.confirmLoadingGroup = false;
            }).catch(function (err) {
              _this4.confirmLoadingGroup = false;
              console.log("updateGroupReq", err);
            });
          }
        }
      });
    }
  }
};