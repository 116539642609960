import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wages-set-group-add-wrapper page-miH page-bgBack"
  }, [_c("a-row", {
    staticClass: "head"
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.handleCancel
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  }), _c("div", {
    staticClass: "left2"
  }, [_vm._v("返回")])], 1), _c("a-col", {
    staticClass: "title"
  }, [_c("strong", [_vm._v(_vm._s(_vm.isEdit ? "编辑薪资组" : "新增薪资组"))])])], 1), _c("div", {
    staticClass: "set-add-wrapper"
  }, [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("div", {
    staticClass: "form-title-wrapper"
  }, [_c("span", {
    staticClass: "add-left-align"
  }), _c("span", {
    staticStyle: {
      color: "rgba(0, 0, 0, 0.85)"
    }
  }, [_vm._v("基本设置")])]), _c("a-form-model-item", {
    attrs: {
      label: "薪资组名称",
      prop: "name"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "225px"
    },
    attrs: {
      placeholder: "请输入",
      disabled: _vm.form.name === "默认薪资组"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), !_vm.form.isAllEmp ? _c("a-form-model-item", {
    attrs: {
      prop: "empList",
      rules: {
        required: true,
        message: "请选择"
      },
      label: "适用范围"
    }
  }, [_c("div", {
    staticClass: "check-person-wrapper",
    on: {
      click: _vm.handleStaffVisible
    }
  }, [_c("div", {
    staticClass: "out-person-wrapper"
  }, [_c("span", [_vm._v("共" + _vm._s(_vm.form.empList.length) + "人")]), _c("a-icon", {
    staticClass: "arrowIcon",
    attrs: {
      type: "right"
    }
  })], 1)])]) : _vm._e(), _vm.form.isAllEmp ? _c("a-form-model-item", {
    attrs: {
      prop: "empList",
      label: "适用范围"
    }
  }, [_c("div", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("全部员工")])]) : _vm._e(), _c("a-form-model-item", {
    attrs: {
      label: "适用模板",
      prop: "salaryTemplateId"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "225px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.salaryTemplateId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "salaryTemplateId", $$v);
      },
      expression: "form.salaryTemplateId"
    }
  }, _vm._l(_vm.options, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("div", {
    staticClass: "form-title-wrapper"
  }, [_c("span", {
    staticClass: "add-left-align"
  }), _c("span", {
    staticStyle: {
      color: "rgba(0, 0, 0, 0.85)"
    }
  }, [_vm._v("设置计薪规则")])]), _c("a-form-model-item", {
    attrs: {
      label: "转正、调薪月规则",
      prop: "changeSalaryRule"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "225px"
    },
    model: {
      value: _vm.form.changeSalaryRule,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "changeSalaryRule", $$v);
      },
      expression: "form.changeSalaryRule"
    }
  }, [_c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v(" 按正式/调薪后的工资计算 ")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v(" 按试用期/调薪前的工资计算 ")]), _c("a-select-option", {
    key: 3,
    attrs: {
      value: 3
    }
  }, [_vm._v(" 按转正/调薪前后的工资混合计算 ")])], 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "计税规则",
      prop: "taxId"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "225px"
    },
    model: {
      value: _vm.form.taxId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "taxId", $$v);
      },
      expression: "form.taxId"
    }
  }, _vm._l(_vm.optionsRule, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "应收服务费规则",
      prop: "serviceFeeType"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "225px"
    },
    model: {
      value: _vm.form.serviceFeeType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "serviceFeeType", $$v);
      },
      expression: "form.serviceFeeType"
    }
  }, [_c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v(" 按人头收费 ")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v(" 按百分比收费 ")]), _c("a-select-option", {
    key: 3,
    attrs: {
      value: 3
    }
  }, [_vm._v(" 一次性收费 ")])], 1)], 1), _vm.form.serviceFeeType === 1 || _vm.form.serviceFeeType === 3 ? _c("a-form-model-item", {
    attrs: {
      label: "应收服务费金额",
      prop: "chargeAmount",
      rules: {
        required: true,
        message: "请输入"
      }
    }
  }, [_c("div", [_c("a-input", {
    staticStyle: {
      width: "225px"
    },
    attrs: {
      min: 0,
      addonAfter: "".concat(_vm.form.serviceFeeType === 3 ? "元" : "元/人"),
      type: "number",
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.chargeAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "chargeAmount", $$v);
      },
      expression: "form.chargeAmount"
    }
  })], 1)]) : _vm._e(), _vm.form.serviceFeeType === 2 ? _c("a-form-model-item", {
    attrs: {
      prop: "serviceFeeItem",
      rules: {
        required: true,
        message: "请输入"
      },
      label: "收费百分比类型"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "225px"
    },
    model: {
      value: _vm.form.serviceFeeItem,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "serviceFeeItem", $$v);
      },
      expression: "form.serviceFeeItem"
    }
  }, [_c("a-select-option", {
    key: "人力成本",
    attrs: {
      value: "人力成本"
    }
  }, [_vm._v("人力成本")]), _c("a-select-option", {
    key: "应发工资",
    attrs: {
      value: "应发工资"
    }
  }, [_vm._v("应发工资")])], 1)], 1) : _vm._e(), _vm.form.serviceFeeType === 2 ? _c("a-form-model-item", {
    attrs: {
      prop: "proportion",
      rules: {
        required: true,
        message: "请输入"
      },
      label: "收费百分比"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "225px"
    },
    attrs: {
      "addon-after": "%",
      min: 0,
      max: 100,
      type: "number"
    },
    model: {
      value: _vm.form.proportion,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "proportion", $$v);
      },
      expression: "form.proportion"
    }
  })], 1) : _vm._e(), _vm.form.serviceFeeType === 3 ? _c("a-form-model-item", {
    attrs: {
      label: "应收服务费月份",
      prop: "billingMonth",
      rules: {
        required: true,
        message: "请输入"
      }
    }
  }, [_c("a-month-picker", {
    staticStyle: {
      width: "225px"
    },
    attrs: {
      format: "YYYY-MM",
      placeholder: "请选择"
    },
    model: {
      value: _vm.form.billingMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "billingMonth", $$v);
      },
      expression: "form.billingMonth"
    }
  })], 1) : _vm._e(), _c("a-form-model-item", {
    attrs: {
      "wrapper-col": {
        span: 14,
        offset: 4
      }
    }
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveGroup
    }
  }, [_vm._v(" 确定 ")])], 1)], 1)], 1), _c("employee-select", {
    attrs: {
      show: _vm.isChooseStaff,
      "default-selected-keys": _vm.form.empList,
      "selected-list": _vm.selectedList
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.isChooseStaff = $event;
      },
      "update:selectedList": function updateSelectedList($event) {
        _vm.selectedList = $event;
      },
      "update:selected-list": function updateSelectedList($event) {
        _vm.selectedList = $event;
      },
      change: _vm.onEmployeeSelectChange
    }
  }), _c("a-modal", {
    attrs: {
      title: "人员确认"
    },
    on: {
      ok: _vm.handleEmpConfirm
    },
    model: {
      value: _vm.empConfirmVisible,
      callback: function callback($$v) {
        _vm.empConfirmVisible = $$v;
      },
      expression: "empConfirmVisible"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-right": "10px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "30px"
    },
    attrs: {
      src: _vm.askImg
    }
  })]), _c("div", {
    staticStyle: {
      position: "relative",
      top: "3px"
    }
  }, [_vm._v(_vm._s(_vm.duplicateName) + "已经在其他薪资组中，是否确认更换薪资组？")])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };