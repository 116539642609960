import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("header", {
    staticClass: "flex-center-between"
  }, [_c("div", {
    staticClass: "l_title"
  }, [_c("a-button", {
    staticClass: "btn_bag",
    attrs: {
      icon: "plus"
    },
    on: {
      click: _vm.addAllowances
    }
  }, [_vm._v(" 添加津贴 ")])], 1)]), _c("a-layout-content", {
    staticClass: "a_layout_content"
  }, _vm._l(_vm.data, function (item, i) {
    return _c("div", {
      key: i,
      on: {
        click: function click($event) {
          return _vm.editorDetail(i);
        }
      }
    }, [_c("a-row", {
      staticClass: "a_title",
      attrs: {
        type: "flex",
        align: "middle"
      }
    }, [_c("a-col", {
      staticStyle: {
        color: "rgba(37, 37, 37, 1)",
        "font-weight": "600"
      },
      attrs: {
        span: 6
      }
    }, [_vm._v(_vm._s(item.name))]), _c("a-col", {
      attrs: {
        span: 8
      }
    }, [_c("a-row", {
      staticClass: "sub_tit pb12"
    }, [_vm._v("津贴名称")]), _c("a-tag", {
      attrs: {
        color: "#6A66F6"
      }
    }, [_vm._v(_vm._s(item.subsidyInfoDOList[0].name))])], 1), _c("a-col", {
      attrs: {
        span: 8
      }
    }, [_c("a-row", {
      staticClass: "sub_tit pb12"
    }, [_vm._v("备注")]), _c("a-row", [_vm._v(_vm._s(item.remark))])], 1), _c("a-col", {
      staticClass: "ta-right",
      attrs: {
        span: 2
      }
    }, [_c("a-popconfirm", {
      attrs: {
        placement: "leftBottom",
        title: "确定删除该记录？",
        okText: "确认",
        cancelText: "取消"
      },
      on: {
        confirm: function confirm($event) {
          return _vm.del(item.id);
        }
      }
    }, [_c("a-icon", {
      staticStyle: {
        "font-size": "20px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
        }
      }
    })], 1)], 1)], 1)], 1);
  }), 0), _c("add-allowance", {
    attrs: {
      allOptions: _vm.addOptions
    },
    on: {
      cbFun: function cbFun($event) {
        return _vm.cbFun($event);
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };