export default {
  fields: {
    name: {
      model: "name"
    },
    phone: {
      model: "phone"
    },
    sourceType: {
      model: "sourceType"
    },
    address: {
      model: "address"
    },
    recommendCount: {
      model: "recommendCount"
    },
    entryCount: {
      model: "entryCount"
    },
    gmtCreate: {
      model: "gmtCreate"
    },
    brokerResourceName: {
      model: "brokerResourceName"
    }
  },
  config: {
    table: {
      fields: [{
        model: "name",
        title: "姓名"
      }, {
        model: "phone",
        title: "手机号"
      }, {
        model: "sourceType",
        title: "数据类型"
      },
      // {
      //   model: "address",
      //   title: "地区"
      // },
      {
        model: "recommendCount",
        title: "推荐人数"
      }, {
        model: "entryCount",
        title: "成功入职"
      }, {
        model: "gmtCreate",
        title: "创建时间"
      }, {
        model: "brokerResourceName",
        title: "经纪人所属"
      }],
      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: {
          width: 220,
          align: "center"
        }
      }
    }
  }
};