/*
 * @description: 请求方法全局
 * @Author: Coder
 * @Date: 2019-12-12 16:19:02
 * @LastEditors  : Coder

 */
import request from "@/utils/request";
import EditorRules from "./editor-rules";
import CheckWork from "./check-work";
import CheckCard from "./check-card";
import Common from "@/api/common";
import CheckOutset from "@/api/check/check-outset";
import CheckTable from "@/api/check/check-table";
function Check(_ref) {
  var url = _ref.url,
    _ref$method = _ref.method,
    method = _ref$method === void 0 ? "GET" : _ref$method,
    data = _ref.data,
    params = _ref.params;
  return request({
    url: url,
    method: method,
    data: data,
    params: params
  });
}
export { Check, EditorRules, CheckWork, CheckCard, CheckTable, Common, CheckOutset };