import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.function.name.js";
import { mapState } from "vuex";
import moment from "moment";
import tableDetail from "./common/table-detail-less";
import feedBack from "./common/feed-back";
import axios from "axios";
export default {
  name: "ShebaoDetail",
  components: {
    //  tableDetail,
    feedBack: feedBack
  },
  data: function data() {
    return {
      tabs: {},
      columns: [],
      datalist: [],
      defaultKey: "all",
      allOptions: {},
      finishedOptions: {},
      unFinishedOptions: {},
      errorOptions: {},
      feedVisible: false,
      feedOptions: {},
      actionDetail: {},
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null
      },
      keyWord: undefined,
      selectedRowKeys: [],
      params: {
        state: undefined,
        // showItem: true,
        // pageNo: 1,
        // pageSize: 10,
        missionCenterId: ""
      }
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  created: function created() {
    var _this = this;
    this.params.missionCenterId = this.$route.query.socDispatchId;
    this.actionDetail = JSON.parse(this.$route.query.actionDetail);
    var arr = [];
    arr = this.actionDetail.headList;
    arr.forEach(function (item, index) {
      _this.columns.push({
        title: "".concat(item),
        dataIndex: "".concat(item),
        align: "center",
        width: item === "身份证号" ? 180 : 120,
        fixed: item === "姓名" || item === "身份证号" ? "left" : null
      });
    });
    this.columns.push({
      title: "\u72B6\u6001",
      dataIndex: "state",
      width: 120,
      align: "center",
      scopedSlots: {
        customRender: "state"
      }
    }, {
      title: "\u53CD\u9988\u4EBA",
      dataIndex: "feedbackUser",
      align: "center",
      width: 120
    }, {
      title: "\u53CD\u9988\u5185\u5BB9",
      dataIndex: "remark",
      align: "center",
      width: 110,
      ellipsis: true
    }, {
      title: "\u53CD\u9988\u65F6\u95F4",
      dataIndex: "feedbackDate",
      align: "center",
      width: 180,
      scopedSlots: {
        customRender: "feedbackDate"
      }
    });
    this.getDetail();
    // this.getNavs();
  },

  methods: {
    onSelectChange: function onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    daochu: function daochu() {
      var _this2 = this;
      var dat = {
        missionCenterId: this.params.missionCenterId
      };
      if (this.keyWord) {
        dat.keyWord = this.keyWord;
      }
      if (this.params.state !== undefined) {
        dat.state = this.params.state;
      }
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        dat.itemIdList = this.selectedRowKeys;
      }
      axios({
        url: "/hr/api/v1/missionCenterItems/exportReceivePath",
        method: "post",
        data: dat,
        responseType: "blob"
        // headers: {
        //   token: token,
        //   "Content-Type": "application/json"
        // }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "".concat(_this2.actionDetail.year + "年" + _this2.actionDetail.month + "月" + _this2.actionDetail.missionName) + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      // this.$request({
      //   url: `/hr/api/v1/missionCenterItems/exportReceivePath/` + this.$route.query.socDispatchId,
      //   method: "POST",
      //   responseType: "blob"
      // }).then(({ data }) => {
      // if (data && data.type === "application/json") {
      //       const link = document.createElement("a");
      //     const blob = new Blob([data], {
      //       type: "application/vnd.ms-excel"
      //     });
      //     link.style.display = "none";
      //     link.href = URL.createObjectURL(blob);
      //     link.setAttribute("download", `${this.$store.state.hr.companyName}_` + "员工导出记录" + ".xlsx");
      //     document.body.appendChild(link);
      //     link.click();
      //     document.body.removeChild(link);
      // }
      // });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getDetail();
    },
    goBack: function goBack() {
      this.$router.push({
        path: "/shebao/paidan",
        query: _objectSpread({}, this.$route.query)
      });
    },
    getDetail: function getDetail() {
      var _this3 = this;
      this.datalist = [];
      this.$request({
        url: "/hr/api/v1/missionCenterItems/selectMissionCenterItem",
        params: _objectSpread(_objectSpread({}, this.params), {}, {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        })
      }).then(function (res) {
        var arr = {};
        _this3.pagination.total = res.data && res.data.entityCount;
        // this.datalist = JSON.parse(JSON.stringify(res.data.entities));
        res.data.entities.forEach(function (item) {
          arr = JSON.parse(item.tableData);
          arr.state = item.state;
          arr.feedbackUser = item.feedbackUser;
          arr.remark = item.remark;
          arr.feedbackDate = item.feedbackDate;
          arr.id = item.id;
          _this3.datalist.push(arr);
        });
      });
    },
    getNavs: function getNavs() {
      var _this4 = this;
      this.$request({
        url: "/hr/api/v1/socDispatches/findDetails/".concat(this.$route.query.socDispatchId)
      }).then(function (_ref) {
        var data = _ref.data;
        _this4.tabs = data;
      });
    },
    // 搜索姓名
    onSearchName: function onSearchName(name) {
      name ? this.params.name = name : delete this.params.name;
      // this.params.pageNo = 1;
      this.getDetail();
    },
    // 切换详情
    changeTab: function changeTab(i) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.defaultKey = i;
      this.params.state = undefined;
      if (typeof i === "number") {
        this.params.state = i;
      }
      this.getDetail();
    },
    handleNav: function handleNav(e) {},
    handleTable: function handleTable(e) {
      this.feedVisible = e.visible;
      this.feedOptions = e.e;
      if (e.current) {
        this.params.pageNo = e.current;
        this.getDetail();
      }
    },
    handleDetail: function handleDetail(e) {
      this.feedVisible = e.visible;
    }
  }
};