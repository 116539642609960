var state = {
  cityTreeSelectOption: []
};
var mutations = {
  cityTreeSelectOption: function cityTreeSelectOption(state, payload) {
    state.cityTreeSelectOption = payload;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};