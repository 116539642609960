import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import { mapState } from "vuex";
var baseColumns = [{
  title: "姓名",
  width: 150,
  dataIndex: "empName"
}, {
  title: "企业员工",
  dataIndex: "empInCompany",
  width: 150,
  scopedSlots: {
    customRender: "empInCompany"
  }
}, {
  title: "手机",
  dataIndex: "phoneStatus",
  width: 150,
  scopedSlots: {
    customRender: "phoneStatus"
  }
}, {
  title: "微信",
  dataIndex: "wechatStatus",
  width: 150,
  scopedSlots: {
    customRender: "wechatStatus"
  }
}, {
  title: "手机号",
  dataIndex: "phone",
  width: 150
}, {
  title: "应发工资",
  dataIndex: "salaryAmount",
  width: 150
},
// {
//   title: "个人社保",
//   dataIndex: "empSoc",
//   width: 150
// },
// {
//   title: "个人公积金",
//   dataIndex: "empPf",
//   width: 150
// },
// {
//   title: "个人所得税",
//   dataIndex: "empTax",
//   width: 150
// },
{
  title: "实发工资",
  dataIndex: "salaryReal",
  width: 150
}];
export default {
  name: "AddPayslipOne",
  components: {
    step: function step() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./step"));
      });
    }
  },
  props: {
    options: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      spinning: true,
      columns: baseColumns,
      cbParams: {
        pageNo: 1,
        reloadCount: true,
        pageSize: 1000
      },
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      titles: {
        countAll: 0,
        countInCompany: 0,
        countNotInCompany: 0,
        countWeChatBind: 0,
        countWeChatUnBind: 0,
        sumSalaryAmount: 0,
        sumSalaryReal: 0
      },
      dataSource: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    wagePayrollList: function wagePayrollList() {
      var temp = localStorage.getItem("tempWagePayroll");
      if (temp) {
        return JSON.parse(temp);
      } else {
        return null;
      }
    }
  }),
  created: function created() {
    this.getSalaryList();
  },
  methods: {
    // 下一步
    doNext: function doNext() {
      var path = "add-payslip-second";
      this.$router.push({
        path: path,
        query: _objectSpread(_objectSpread({}, this.$route.query), {}, {
          unSendCount: this.pagination.total
        })
      });
    },
    // 重新上传
    reUpload: function reUpload() {
      this.$router.push({
        path: "/wages/add-payslip",
        query: {
          item: 2
        }
      });
    },
    // 获取已上传工资条
    getSalaryList: function getSalaryList() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/salaryBills/pageRelation",
        params: {
          companyId: this.hr.companyId,
          tableId: this.$route.query.tableId,
          reloadCount: true,
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.spinning = false;
        for (var key in _this.titles) {
          _this.titles[key] = data[key];
        }
        _this.dataSource = data.salaryBillListVOPage.entities || [];
        _this.pagination.total = data.salaryBillListVOPage.entityCount || 0;
        _this.dataSource.forEach(function (item) {
          item.salaryBillFieldsDOList.forEach(function (key) {
            key.dataIndex = key.sort;
          });
        });
        var colum = [];
        _this.dataSource[0].salaryBillFieldsDOList.map(function (key) {
          colum.push({
            dataIndex: key.sort,
            title: key.fieldName,
            width: 150
          });
        });
        _this.dataSource = _this.dataSource.map(function (item) {
          var obj = {};
          item.salaryBillFieldsDOList.map(function (key) {
            obj[key["dataIndex"]] = key["fieldValue"];
          });
          item = _objectSpread(_objectSpread({}, item), obj);
          return item;
        });
        // const filteredColumns = this.wagePayrollList
        //   ? baseColumns.filter((item) => this.wagePayrollList.includes(item.title))
        //   : baseColumns;
        _this.columns = [].concat(baseColumns, colum);
      });
    },
    // 筛选
    selectItem: function selectItem(type) {},
    // 翻页
    handlePage: function handlePage(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getSalaryList();
    }
  }
};