var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.uploading,
      tip: "上传中..."
    }
  }, [_c("div", {
    staticClass: "uplaodBoxB page-miH page-bgBack"
  }, [_c("a-row", {
    staticClass: "head"
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.isGoBack
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  }), _c("div", {
    staticClass: "left2"
  }, [_vm._v("返回")])], 1), _c("a-col", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("上传工资数据表")])])], 1), !_vm.callback ? _c("div", {
    staticClass: "ant-upload-select-text-box",
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-upload", {
    ref: "upload",
    attrs: {
      "file-list": _vm.fileList,
      remove: _vm.handleRemove,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("img", {
    staticStyle: {
      width: "139px",
      height: "106px"
    },
    attrs: {
      src: _vm.uploadImg
    }
  }), _c("div", {
    staticClass: "ant-modal-body"
  }, [_c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#666"
    }
  }, [_vm._v("点击添加文件或将文件拖拽到此处")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#999"
    }
  }, [_vm._v("(文件大小不超过5M)")])]), _c("a-button", {
    staticStyle: {
      "margin-bottom": "15px"
    },
    attrs: {
      type: "primary"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加文件 ")], 1)], 1)], 1) : _vm._e(), !_vm.callback ? _c("div", {
    staticClass: "tipBox"
  }, [_c("a-row", {
    staticStyle: {
      color: "#252525",
      "font-size": "16px",
      "font-weight": "500"
    }
  }, [_vm._v("导入提示：")]), _c("a-row", [_c("a-col", {
    staticStyle: {
      "line-height": "25px"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", [_vm._v("1.上传的月工资表，必须包含以下信息： 员工姓名、身份证号")]), _c("div", [_vm._v("2.不可导入工资表中不存在的工资项，仅支持导入现有工资项")]), _c("div", [_vm._v(" 3.如上传遇到问题，请使用模版上传 "), _c("a-button", {
    staticClass: "primary_color",
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("【工资数据上传模板】")])], 1), _c("div", [_vm._v("4.暂不支持复合表头（即合并单元格）")]), _c("div", [_vm._v("5.上传的员工必须存在于花名册中，否则系统无法识别")])])], 1)], 1) : _vm._e(), _vm.callback ? _c("div", [_c("div", {
    staticStyle: {
      "padding-left": "24px",
      "padding-right": "24px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "15px",
      "padding-left": "10px",
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      height: "35px",
      width: "100%",
      "background-color": "#fffbe6",
      "border-style": "solid",
      "border-color": "#ffe58f",
      "border-width": "1px",
      "border-radius": "5px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "5px"
    },
    attrs: {
      src: require("../../../assets/icons/重复.png")
    }
  }), _c("span", {
    attrs: {
      sytle: "color:#55565D;font-size:14px;"
    }
  }, [_vm._v(" 共" + _vm._s(_vm.count) + "条数据，成功导入" + _vm._s(_vm.succ) + "条数据， "), _c("label", {
    staticClass: "redc"
  }, [_vm._v(_vm._s(_vm.fial))]), _vm._v("条数据导入失败。 ")])])]), _c("div", {
    staticClass: "header-title-box"
  }, [_c("div", {
    staticClass: "headerTitle"
  }, [_c("div", {
    staticClass: "block"
  }), _c("div", {
    staticStyle: {
      "font-size": "15px",
      "font-weight": "700",
      "font-color": "#252525"
    }
  }, [_vm._v("错误信息")])]), _c("div", {
    staticClass: "btn-box"
  }, [_c("a-button", {
    attrs: {
      disabled: !_vm.downloadurl,
      type: "primary"
    },
    on: {
      click: _vm.buCha
    }
  }, [_vm._v("导出错误数据")])], 1)]), _c("a-table", {
    staticStyle: {
      "padding-left": "24px",
      "padding-right": "24px"
    },
    attrs: {
      columns: _vm.columnDetail,
      pagination: _vm.pagination,
      "data-source": _vm.dataDetail,
      scroll: {
        x: 800
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "reason",
      fn: function fn(text) {
        return [_c("a-tooltip", {
          attrs: {
            placement: "topLeft"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(text))])]), _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))])], 2)];
      }
    }, {
      key: "name",
      fn: function fn(text, record) {
        return [record.field === "name" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }, {
      key: "idCard",
      fn: function fn(text, record) {
        return [record.field === "idCard" ? _c("span", {
          staticStyle: {
            color: "#ff0000"
          }
        }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text))])];
      }
    }], null, false, 571487143)
  })], 1) : _vm._e(), _c("a-modal", {
    attrs: {
      title: "批量导入",
      width: "500px"
    },
    on: {
      ok: _vm.handleFailClose
    },
    model: {
      value: _vm.dialogFailVisible,
      callback: function callback($$v) {
        _vm.dialogFailVisible = $$v;
      },
      expression: "dialogFailVisible"
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleFailClose
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticStyle: {
      "padding-left": "10px",
      display: "flex",
      "flex-flow": "row",
      "align-items": "center",
      height: "35px",
      width: "100%"
    }
  }, [_c("img", {
    staticStyle: {
      width: "25px",
      height: "25px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("../../../assets/icons/duihao.png")
    }
  }), _c("div", {
    staticStyle: {
      color: "#3c3d43",
      "font-size": "15px",
      "font-weight": "700"
    }
  }, [_vm._v("共" + _vm._s(_vm.count) + "条数据，全部导入成功！")])])], 2)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };