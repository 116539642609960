import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "changeStyle"
  }, [_c("a-modal", {
    attrs: {
      title: "选择人员",
      visible: _vm.visible,
      width: 850,
      destroyOnClose: true,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    staticClass: "changeStyleText"
  }, [_vm._v(_vm._s(_vm.gettextState(_vm.textState, _vm.textState === "批量删除" ? 1 : "")))]), _c("template", {
    slot: "footer"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticStyle: {
      "padding-top": "4px"
    }
  }, [_c("a-button", {
    attrs: {
      size: "small",
      type: "link"
    },
    on: {
      click: _vm.showSenior
    }
  }, [_c("a-icon", {
    attrs: {
      type: "search"
    }
  }), _vm._v("高级搜索 ")], 1), _c("a-button", {
    attrs: {
      size: "small",
      type: "link"
    },
    on: {
      click: _vm.showCopy
    }
  }, [_c("a-icon", {
    attrs: {
      type: "copy"
    }
  }), _vm._v("粘贴选择 ")], 1)], 1), _c("div", [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleOk
    }
  }, [_vm._v("确定")])], 1)])]), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      "border-right": "1px solid #d8d8d8",
      flex: "1",
      padding: "0 15px"
    }
  }, [_c("a-input-search", {
    attrs: {
      placeholder: "搜索人员或手机号或身份证号"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.staffFrom.nameOrPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.staffFrom, "nameOrPhone", $$v);
      },
      expression: "staffFrom.nameOrPhone"
    }
  }), _c("div", {
    staticStyle: {
      cursor: "pointer",
      margin: "5px"
    },
    on: {
      click: _vm.onCheckAll
    }
  }, [_vm._v(" 全选当页 "), _vm.checkAll ? _c("i", {
    staticClass: "iconfont icon-check-circle-fill primary_color",
    staticStyle: {
      "font-size": "19px"
    }
  }) : _c("img", {
    staticStyle: {
      width: "18px",
      height: "18px"
    },
    attrs: {
      src: require("@/assets/icons/未选择.png")
    }
  })]), _c("div", {
    staticStyle: {
      height: "420px",
      "overflow-y": "auto",
      padding: "0 15px"
    }
  }, _vm._l(_vm.staffData, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        cursor: "pointer",
        height: "40px",
        display: "flex",
        "justify-content": "space-between",
        "align-items": "center"
      },
      on: {
        click: function click($event) {
          return _vm.onRow(item);
        }
      }
    }, [_c("span", [_c("a-avatar", {
      staticStyle: {
        "margin-right": "5px"
      },
      attrs: {
        src: item.empPhoto,
        size: "small",
        icon: "user"
      }
    }), _vm._v(" " + _vm._s(item.name) + _vm._s(item.phone) + " ")], 1), item.isTrue ? _c("i", {
      staticClass: "iconfont icon-check-circle-fill primary_color",
      staticStyle: {
        "font-size": "19px"
      }
    }) : _c("img", {
      staticStyle: {
        width: "18px",
        height: "18px"
      },
      attrs: {
        src: require("@/assets/icons/未选择.png")
      }
    })]);
  }), 0), _c("a-pagination", {
    attrs: {
      size: "small",
      total: _vm.pageCount,
      current: _vm.staffFrom.pageNo,
      "show-total": function showTotal(total) {
        return "\u5171 ".concat(_vm.pageCount, " \u6761");
      },
      pageSizeOptions: ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onChangePagination,
      showSizeChange: _vm.showSizeChange
    }
  })], 1), _c("div", {
    staticStyle: {
      flex: "1",
      padding: "0 15px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "15px"
    }
  }, [_vm._v(" 已选择的人员 "), _c("span", {
    staticClass: "primary_color"
  }, [_vm._v(_vm._s(_vm.staffrightData.length) + "人")]), _c("div", {
    staticStyle: {
      height: "420px",
      "overflow-y": "auto",
      padding: "0 15px"
    }
  }, _vm._l(_vm.staffrightData, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        cursor: "pointer",
        height: "40px",
        display: "flex",
        "justify-content": "space-between",
        "align-items": "center"
      }
    }, [_c("span", [_c("a-avatar", {
      staticStyle: {
        "margin-right": "5px"
      },
      attrs: {
        src: item.empPhoto,
        size: "small",
        icon: "user"
      }
    }), _vm._v(" " + _vm._s(item.name) + _vm._s(item.phone) + " ")], 1), _c("img", {
      staticStyle: {
        width: "18px",
        height: "18px"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteItem(item, index);
        }
      }
    })]);
  }), 0)])])])], 2), _c("a-modal", {
    attrs: {
      title: "适用范围-粘贴选择",
      width: 850,
      visible: _vm.copyVisible,
      destroyOnClose: true
    },
    on: {
      cancel: function cancel($event) {
        _vm.copyVisible = false;
      },
      ok: _vm.copyOk
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      width: "65%"
    }
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      height: "125px",
      background: "#faf9f9",
      "border-radius": "4px",
      padding: "14px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-weight": "400",
      color: "#252525line-height:18px",
      "font-size": "16px"
    }
  }, [_vm._v("温馨提示")]), _c("div", {
    staticStyle: {
      "font-weight": "400",
      color: "#999999",
      "line-height": "18px",
      "font-size": "12px",
      "margin-top": "8px"
    }
  }, [_vm._v(" 1、在文本框内输入或批量粘贴企业在职员工身份证号，便可识别添加。 ")]), _c("div", {
    staticStyle: {
      "font-weight": "400",
      color: "#999999",
      "line-height": "18px",
      "font-size": "12px",
      "margin-top": "8px"
    }
  }, [_vm._v(" 2、" + _vm._s(_vm.gettextState(_vm.textState)) + "。 ")]), _c("div", {
    staticStyle: {
      "font-weight": "400",
      color: "#999999",
      "line-height": "18px",
      "font-size": "12px",
      "margin-top": "8px"
    }
  }, [_vm._v(" 3、支持以换行、回车、空格、逗号等符号隔开的身份证号识别 ")])]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("a-textarea", {
    attrs: {
      placeholder: "请输入或粘贴员工身份证号",
      rows: 15
    },
    model: {
      value: _vm.textContent,
      callback: function callback($$v) {
        _vm.textContent = $$v;
      },
      expression: "textContent"
    }
  })], 1), _c("div", {
    staticStyle: {
      "padding-top": "12px",
      display: "flex",
      "justify-content": "flex-end"
    }
  }, [_vm.errorState ? _c("div", {
    staticStyle: {
      color: "#ff0000",
      "margin-right": "130px"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "16px",
      "margin-right": "8px"
    },
    attrs: {
      type: "exclamation-circle"
    }
  }), _vm._v("文本框内存在无法识别的身份证号 ")], 1) : _vm._e(), _c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: _vm.reset
    }
  }, [_vm._v("重置")]), _c("a-button", {
    attrs: {
      disabled: _vm.textContent === "" || _vm.errorState
    },
    on: {
      click: _vm.getEmpListByIdCards
    }
  }, [_vm._v("识别")])], 1)]), _c("a-divider", {
    staticStyle: {
      height: "480px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("div", {
    staticStyle: {
      width: "35%"
    }
  }, [_c("div", [_vm._v(" 已选择的员工 "), _c("span", {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.chosenList.length) + "人")])]), _c("div", {
    staticStyle: {
      height: "450px",
      overflow: "scroll"
    }
  }, _vm._l(_vm.chosenList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        padding: "8px",
        display: "flex",
        "justify-content": "space-between"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " "), _c("img", {
      staticStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteCopyItem(item);
        }
      }
    })]);
  }), 0)])], 1)]), _c("a-modal", {
    attrs: {
      title: "适用范围-高级筛选",
      width: 1000,
      visible: _vm.seniorVisible,
      destroyOnClose: true
    },
    on: {
      cancel: _vm.seniorCancel,
      ok: _vm.seniorOk
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      width: "35%"
    }
  }, [_c("a-form-model", {
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "筛选条件"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "姓名/手机号/身份证号"
    },
    model: {
      value: _vm.form.nameOrPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameOrPhone", $$v);
      },
      expression: "form.nameOrPhone"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "员工标签"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      mode: "multiple",
      "option-label-prop": "label"
    },
    model: {
      value: _vm.form.tag,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tag", $$v);
      },
      expression: "form.tag"
    }
  }, _vm._l(_vm.labelList, function (item, index) {
    return _c("a-select-option", {
      key: "".concat(item.tagName, "_").concat(index),
      attrs: {
        value: item.tagName,
        label: item.tagName
      }
    }, [_vm._v(_vm._s(item.tagName))]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "工作性质"
    }
  }, [_c("a-select", {
    attrs: {
      options: _vm.$store.state.enums.postTypeLst,
      placeholder: "请选择",
      allowClear: ""
    },
    model: {
      value: _vm.form.postType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "postType", $$v);
      },
      expression: "form.postType"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "入职日期"
    }
  }, [_c("a-range-picker", {
    staticClass: "mr12",
    attrs: {
      placeholder: ["入职开始时间", "入职结束时间"]
    },
    on: {
      change: function change(e) {
        return _vm.onDateChange(e, "join");
      }
    },
    model: {
      value: _vm.form.joinDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "joinDate", $$v);
      },
      expression: "form.joinDate"
    }
  })], 1), _vm.textState === "批量撤销离职" ? _c("a-form-model-item", {
    attrs: {
      label: "离职日期"
    }
  }, [_c("a-range-picker", {
    attrs: {
      placeholder: ["离职日期开始时间", "离职日期结束时间"]
    },
    on: {
      change: function change(e) {
        return _vm.onDateChange(e, "leave");
      }
    },
    model: {
      value: _vm.form.leaveDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "leaveDate", $$v);
      },
      expression: "form.leaveDate"
    }
  })], 1) : _vm._e()], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.clearForm
    }
  }, [_vm._v("重置")]), _c("a-button", {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.getSeniorListCheck
    }
  }, [_vm._v("查询")])], 1)], 1), _c("a-divider", {
    staticStyle: {
      height: "480px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("div", {
    staticStyle: {
      width: "40%"
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.dataList,
      pagination: _vm.pagination,
      scroll: {
        x: 500
      },
      "row-key": "id",
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(text) {
        return _c("span", {}, [_vm._v(_vm._s(text))]);
      }
    }])
  })], 1), _c("a-divider", {
    staticStyle: {
      height: "480px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("div", {
    staticStyle: {
      width: "25%"
    }
  }, [_c("div", [_vm._v(" 已选择的员工 "), _c("span", {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.seniorList.length) + "人")])]), _c("div", {
    staticStyle: {
      height: "450px",
      overflow: "scroll"
    }
  }, _vm._l(_vm.seniorList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        padding: "8px",
        display: "flex",
        "justify-content": "space-between"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " "), _c("img", {
      staticStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer"
      },
      attrs: {
        src: require("@/assets/icons/删除.png")
      },
      on: {
        click: function click($event) {
          return _vm.deleteSeniorItem(item);
        }
      }
    })]);
  }), 0)])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };