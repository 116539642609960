import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("div", {
    staticClass: "buttonBox"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goAdd
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("新增工资表模板 ")], 1), _vm.isContainByString("contractAdmin") ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showIssueModa
    }
  }, [_vm._v(" 下发工资表模板 ")]) : _vm._e()], 1), _c("div", {
    staticClass: "tableBox"
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      pagination: false,
      "row-key": "id",
      loading: _vm.loading,
      "data-source": _vm.data
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return _c("a", {
          staticClass: "edit",
          staticStyle: {
            "margin-left": "-10px"
          }
        }, [_c("span", {
          on: {
            click: function click($event) {
              return _vm.goEdit(record);
            }
          }
        }, [_vm._v("编辑")]), _c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("span", [_c("a-popconfirm", {
          attrs: {
            title: "确定删除" + record.name + "?"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.deleteSalaryTemplatesList(record);
            }
          }
        }, [_c("a-icon", {
          staticStyle: {
            color: "red"
          },
          attrs: {
            slot: "icon",
            type: "question-circle-o"
          },
          slot: "icon"
        }), _c("a", {
          attrs: {
            href: "#",
            disabled: record.name === "默认模板" || record.name === "计时制模板"
          }
        }, [_vm._v("删除")])], 1)], 1)], 1);
      }
    }])
  })], 1), _c("a-modal", {
    attrs: {
      visible: _vm.issueModalVisible,
      title: "下发工资模板",
      width: "538px",
      "destroy-on-close": true
    },
    on: {
      ok: _vm.confirmIssueModal,
      cancel: _vm.handleCancelIssueModal
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", [_vm._v("请选择工资模板")]), _c("a-select", {
    staticStyle: {
      width: "240px",
      "margin-top": "10px"
    },
    attrs: {
      mode: "multiple",
      placeholder: "请选择工资模板",
      "option-filter-prop": "children",
      "show-search": "",
      "filter-option": _vm.filterOption
    },
    model: {
      value: _vm.contractList,
      callback: function callback($$v) {
        _vm.contractList = $$v;
      },
      expression: "contractList"
    }
  }, _vm._l(_vm.contractData, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_c("a-tooltip", {
      attrs: {
        placement: "left",
        title: item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", [_vm._v("请选择下发用工单位")]), _c("a-tree-select", {
    staticStyle: {
      width: "240px",
      "margin-top": "10px",
      "max-height": "350px",
      overflow: "auto"
    },
    attrs: {
      value: _vm.companyList,
      treeData: _vm.dataList,
      filterTreeNode: false,
      treeCheckable: "",
      placeholder: "请选择用工单位"
    },
    on: {
      search: _vm.treeSearch,
      change: _vm.companySelectChange
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };