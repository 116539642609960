import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "bgc-white page-box-employer-setting"
  }, [_c("a-row", {
    staticClass: "p24 btmline",
    staticStyle: {
      border: "none"
    }
  }, [_c("a-col", {
    attrs: {
      span: 2
    }
  }, [_c("a-icon", {
    staticClass: "fs20",
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  })], 1), _c("div", {
    staticClass: "left6",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goback
    }
  }, [_vm._v("返回")])], 1), _c("a-collapse", {
    staticClass: "p24",
    attrs: {
      bordered: false
    },
    model: {
      value: _vm.activeKey,
      callback: function callback($$v) {
        _vm.activeKey = $$v;
      },
      expression: "activeKey"
    }
  }, [_c("a-collapse-panel", {
    key: "1",
    staticStyle: {
      "border-top": "none"
    },
    attrs: {
      header: "基本信息"
    }
  }, [_c("a-row", [_c("div", {
    staticClass: "table-header"
  }, [_c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])]), _c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])])]), _vm._l(_vm.allData.companyEmpNecessaryBasicVOList, function (item, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row"
    }, [_c("p", [_c("span", {
      staticClass: "name-box"
    }, [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-tooltip", [item.fieldCode === "idCard" || item.fieldCode === "name" ? _c("template", {
      slot: "title"
    }, [_vm._v(" 该字段默认必填 ")]) : _vm._e(), _c("a-checkbox", {
      attrs: {
        disabled: item.fieldCode === "idCard" || item.fieldCode === "name"
      },
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 2)], 1), _c("p", [_c("a-tooltip", [item.fieldCode === "idCard" || item.fieldCode === "name" ? _c("template", {
      slot: "title"
    }, [_vm._v(" 该字段默认开启")]) : _vm._e(), _c("a-checkbox", {
      attrs: {
        disabled: item.fieldCode === "idCard" || item.fieldCode === "name"
      },
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    })], 2)], 1)])]);
  }), _vm._l(_vm.allData.companyAdditionalFieldsBasicVOList, function (item, index) {
    return _c("a-col", {
      key: index.id,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row2"
    }, [_c("p", {
      staticClass: "name-box"
    }, [_c("span", [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      staticClass: "checkbox1",
      on: {
        change: function change($event) {
          return _vm.remoteControlAdd(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("div", {
      staticClass: "checkbox2"
    }, [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControlAdd(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    }), _c("div", {
      staticClass: "item-delete"
    }, [_c("div", [_c("a-button", {
      staticClass: "add-field",
      on: {
        click: function click($event) {
          return _vm.edit(item, "basic", index);
        }
      }
    }, [_c("a-icon", {
      staticClass: "editInformation",
      attrs: {
        type: "form"
      }
    })], 1)], 1), _c("a-popconfirm", {
      attrs: {
        title: "确定删除吗？"
      },
      on: {
        confirm: function confirm($event) {
          return _vm.confirmDelete(item.id);
        }
      }
    }, [_c("a-icon", {
      staticStyle: {
        color: "red"
      },
      attrs: {
        slot: "icon",
        type: "question-circle-o"
      },
      slot: "icon"
    }), _c("a", {
      attrs: {
        href: "#"
      }
    }, [_c("a-icon", {
      staticClass: "add-delete",
      attrs: {
        type: "delete"
      }
    })], 1)], 1)], 1)], 1)])]);
  }), _c("div", [_c("a-button", {
    staticClass: "add-field-add",
    on: {
      click: function click($event) {
        return _vm.showModal("visible1");
      }
    }
  }, [_c("a-icon", {
    staticClass: "add-field-icon",
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v("添加字段 ")], 1), _c("a-modal", {
    attrs: {
      title: "添加新字段"
    },
    on: {
      ok: function ok($event) {
        return _vm.handleAddOk("contact");
      }
    },
    model: {
      value: _vm.visible2,
      callback: function callback($$v) {
        _vm.visible2 = $$v;
      },
      expression: "visible2"
    }
  }, [_c("a-form-model", {
    ref: "addForm",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "fieldName",
    attrs: {
      prop: "fieldName",
      label: "字段名称",
      rules: {
        required: true,
        message: "必填"
      },
      help: "1-8个字符，不能与现有字段重复"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段名称",
      "max-length": 8
    },
    model: {
      value: _vm.form.fieldName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldName", $$v);
      },
      expression: "form.fieldName"
    }
  })], 1), _c("a-form-model-item", {
    ref: "fieldType",
    attrs: {
      prop: "fieldType",
      label: "字段类型",
      rules: {
        required: true,
        message: "必选"
      }
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择您要添加的字段类型"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.form.fieldType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldType", $$v);
      },
      expression: "form.fieldType"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("选项")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("文本")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("日期")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("长文本")])], 1)], 1), _vm.show === 2 ? _c("a-form-model", _vm._b({
    ref: "dynamicValidateForm",
    staticStyle: {
      "margin-left": "36px"
    }
  }, "a-form-model", _vm.formItemLayoutWithOutLabel, false), [_vm._l(_vm.form.dropDownArray, function (item, index) {
    return _c("a-form-model-item", _vm._b({
      key: index,
      staticClass: "field option",
      attrs: {
        label: index === 0 ? "字段选项" : "",
        rules: {
          required: true,
          message: "请输入自定义选项",
          trigger: "blur"
        }
      }
    }, "a-form-model-item", index === 0 ? _vm.formItemLayout : {}, false), [_c("a-input", _vm._b({
      staticStyle: {
        width: "60%",
        "margin-left": "10px"
      },
      attrs: {
        value: item,
        placeholder: "自定义选项"
      },
      on: {
        change: function change($event) {
          return _vm.handleOptionChange($event, index);
        }
      }
    }, "a-input", index === 0 ? _vm.formItemLayout : {}, false)), _vm.form.dropDownArray.length > 1 ? _c("a-icon", {
      staticClass: "dynamic-delete-button",
      attrs: {
        type: "delete",
        disabled: _vm.form.dropDownArray.length === 1
      },
      on: {
        click: function click($event) {
          return _vm.removeDomain(index);
        }
      }
    }) : _vm._e()], 1);
  }), _c("a-form-model-item", {
    staticClass: "addbutton"
  }, [_c("a-button", {
    staticStyle: {
      width: "60%",
      "margin-left": "10px"
    },
    attrs: {
      type: "dashed"
    },
    on: {
      click: _vm.addDomain
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加自定义选项 ")], 1)], 1)], 2) : _vm._e(), _c("a-form-model-item", {
    ref: "description",
    attrs: {
      prop: "description",
      label: "字段描述"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段描述"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)], 1)], 1)], 2)], 1), _c("a-collapse-panel", {
    key: "4",
    staticStyle: {
      "border-top": "none"
    },
    attrs: {
      header: "联系信息"
    }
  }, [_c("a-row", [_c("div", {
    staticClass: "table-header"
  }, [_c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])]), _c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])])]), _vm._l(_vm.allData.companyEmpNecessaryContactVOList, function (item, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row"
    }, [_c("p", [_c("span", {
      staticClass: "name-box"
    }, [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-tooltip", [item.fieldCode === "phone" ? _c("template", {
      slot: "title"
    }, [_vm._v(" 该字段默认必填")]) : _vm._e(), _c("a-checkbox", {
      attrs: {
        disabled: item.fieldCode === "phone"
      },
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 2)], 1), _c("p", [_c("a-tooltip", [item.fieldCode === "phone" ? _c("template", {
      slot: "title"
    }, [_vm._v(" 该字段默认开启")]) : _vm._e(), _c("a-checkbox", {
      attrs: {
        disabled: item.fieldCode === "phone"
      },
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    })], 2)], 1)])]);
  }), _vm._l(_vm.allData.companyAdditionalFieldsContactVOList, function (item, index) {
    return _c("a-col", {
      key: index.id,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row2"
    }, [_c("p", {
      staticClass: "name-box"
    }, [_c("span", [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      staticClass: "checkbox1",
      on: {
        change: function change($event) {
          return _vm.remoteControlAdd(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("div", {
      staticClass: "checkbox2"
    }, [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControlAdd(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    }), _c("div", {
      staticClass: "item-delete"
    }, [_c("div", [_c("a-button", {
      staticClass: "add-field",
      on: {
        click: function click($event) {
          return _vm.edit(item, "contact");
        }
      }
    }, [_c("a-icon", {
      staticClass: "editInformation",
      attrs: {
        type: "form"
      }
    })], 1)], 1), _c("a-popconfirm", {
      attrs: {
        title: "确定删除吗？"
      },
      on: {
        confirm: function confirm($event) {
          return _vm.confirmDelete(item.id);
        }
      }
    }, [_c("a-icon", {
      staticStyle: {
        color: "red"
      },
      attrs: {
        slot: "icon",
        type: "question-circle-o"
      },
      slot: "icon"
    }), _c("a", {
      attrs: {
        href: "#"
      }
    }, [_c("a-icon", {
      staticClass: "add-delete",
      attrs: {
        type: "delete"
      }
    })], 1)], 1)], 1)], 1)])]);
  }), _c("div", [_c("a-button", {
    staticClass: "add-field-add",
    on: {
      click: function click($event) {
        return _vm.showModal("visible2");
      }
    }
  }, [_c("a-icon", {
    staticClass: "add-field-icon",
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v("添加字段 ")], 1), _c("a-modal", {
    attrs: {
      title: "添加新字段"
    },
    on: {
      ok: function ok($event) {
        return _vm.handleAddOk("basic");
      }
    },
    model: {
      value: _vm.visible1,
      callback: function callback($$v) {
        _vm.visible1 = $$v;
      },
      expression: "visible1"
    }
  }, [_c("a-form-model", {
    ref: "addForm",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "fieldName",
    attrs: {
      prop: "fieldName",
      label: "字段名称",
      rules: {
        required: true,
        message: "必填"
      },
      help: "1-8个字符，不能与现有字段重复"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段名称",
      "max-length": 8
    },
    model: {
      value: _vm.form.fieldName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldName", $$v);
      },
      expression: "form.fieldName"
    }
  })], 1), _c("a-form-model-item", {
    ref: "fieldType",
    attrs: {
      prop: "fieldType",
      label: "字段类型",
      rules: {
        required: true,
        message: "必选"
      }
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择您要添加的字段类型"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.form.fieldType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldType", $$v);
      },
      expression: "form.fieldType"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("选项")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("文本")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("日期")]), _c("a-select-option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("长文本")])], 1)], 1), _vm.show === 2 ? _c("a-form-model", _vm._b({
    ref: "dynamicValidateForm",
    staticStyle: {
      "margin-left": "36px"
    }
  }, "a-form-model", _vm.formItemLayoutWithOutLabel, false), [_vm._l(_vm.form.dropDownArray, function (item, index) {
    return _c("a-form-model-item", _vm._b({
      key: index,
      staticClass: "field option",
      attrs: {
        label: index === 0 ? "字段选项" : "",
        rules: {
          required: true,
          message: "请输入自定义选项",
          trigger: "blur"
        }
      }
    }, "a-form-model-item", index === 0 ? _vm.formItemLayout : {}, false), [_c("a-input", _vm._b({
      staticStyle: {
        width: "60%",
        "margin-left": "10px"
      },
      attrs: {
        value: item,
        placeholder: "自定义选项"
      },
      on: {
        change: function change($event) {
          return _vm.handleOptionChange($event, index);
        }
      }
    }, "a-input", index === 0 ? _vm.formItemLayout : {}, false)), _vm.form.dropDownArray.length > 1 ? _c("a-icon", {
      staticClass: "dynamic-delete-button",
      attrs: {
        type: "delete",
        disabled: _vm.form.dropDownArray.length === 1
      },
      on: {
        click: function click($event) {
          return _vm.removeDomain(index);
        }
      }
    }) : _vm._e()], 1);
  }), _c("a-form-model-item", {
    staticClass: "addbutton"
  }, [_c("a-button", {
    staticStyle: {
      width: "60%",
      "margin-left": "10px"
    },
    attrs: {
      type: "dashed"
    },
    on: {
      click: _vm.addDomain
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加自定义选项 ")], 1)], 1)], 2) : _vm._e(), _c("a-form-model-item", {
    ref: "description",
    attrs: {
      prop: "description",
      label: "字段描述"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段描述"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)], 1)], 1)], 2)], 1), _c("a-collapse-panel", {
    key: "16",
    staticStyle: {
      "border-top": "none"
    },
    attrs: {
      header: "家庭成员"
    }
  }, [_vm.allData.companyEmpNecessaryVOList ? _c("a-checkbox", {
    staticClass: "extrabox",
    attrs: {
      slot: "extra"
    },
    on: {
      click: function click($event) {
        return _vm.handleClick(_vm.allData.companyEmpNecessaryVOList[2], "empFamily");
      }
    },
    slot: "extra",
    model: {
      value: _vm.allData.companyEmpNecessaryVOList[2].isNecessary,
      callback: function callback($$v) {
        _vm.$set(_vm.allData.companyEmpNecessaryVOList[2], "isNecessary", $$v);
      },
      expression: "allData.companyEmpNecessaryVOList[2].isNecessary"
    }
  }) : _vm._e(), _c("span", {
    staticClass: "extraStopBox",
    attrs: {
      slot: "extra"
    },
    on: {
      click: _vm.handleStopClick
    },
    slot: "extra"
  }, [_vm._v(" 是否必须添加 ")]), _c("a-row", [_c("div", {
    staticClass: "table-header"
  }, [_c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])]), _c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])])]), _vm._l(_vm.allData.companyEmpNecessaryFamilyMembersVOList, function (item, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row"
    }, [_c("p", [_c("span", {
      staticClass: "name-box"
    }, [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("p", [_c("a-tooltip", [item.fieldCode === "name" || item.fieldCode === "phone" ? _c("template", {
      slot: "title"
    }, [_vm._v("该字段默认开启")]) : _vm._e(), _c("a-checkbox", {
      attrs: {
        disabled: item.fieldCode === "name" || item.fieldCode === "phone"
      },
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    })], 2)], 1)])]);
  })], 2)], 1), _c("a-collapse-panel", {
    key: "5",
    staticStyle: {
      "border-top": "none"
    },
    attrs: {
      header: "紧急联系人"
    }
  }, [_vm.allData.companyEmpNecessaryVOList ? _c("a-checkbox", {
    staticClass: "extrabox",
    attrs: {
      slot: "extra"
    },
    on: {
      click: function click($event) {
        return _vm.handleClick(_vm.allData.companyEmpNecessaryVOList[0], "empContacts");
      }
    },
    slot: "extra",
    model: {
      value: _vm.allData.companyEmpNecessaryVOList[0].isNecessary,
      callback: function callback($$v) {
        _vm.$set(_vm.allData.companyEmpNecessaryVOList[0], "isNecessary", $$v);
      },
      expression: "allData.companyEmpNecessaryVOList[0].isNecessary"
    }
  }) : _vm._e(), _c("span", {
    staticClass: "extraStopBox",
    attrs: {
      slot: "extra"
    },
    on: {
      click: _vm.handleStopClick
    },
    slot: "extra"
  }, [_vm._v(" 是否必须添加 ")]), _c("a-row", [_c("div", {
    staticClass: "table-header"
  }, [_c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])]), _c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])])]), _vm._l(_vm.allData.companyEmpNecessaryEmergencyContactVOList, function (item, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row"
    }, [_c("p", [_c("span", {
      staticClass: "name-box"
    }, [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-tooltip", [item.fieldCode === "name" || item.fieldCode === "phone" ? _c("template", {
      slot: "title"
    }, [_vm._v(" 该字段默认必填 ")]) : _vm._e(), _c("a-checkbox", {
      attrs: {
        disabled: item.fieldCode === "name" || item.fieldCode === "phone"
      },
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 2)], 1), _c("p", [_c("a-tooltip", [item.fieldCode === "name" || item.fieldCode === "phone" ? _c("template", {
      slot: "title"
    }, [_vm._v(" 该字段默认开启 ")]) : _vm._e(), _c("a-checkbox", {
      attrs: {
        disabled: item.fieldCode === "name" || item.fieldCode === "phone"
      },
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    })], 2)], 1)])]);
  })], 2)], 1), _c("a-collapse-panel", {
    key: "12",
    staticStyle: {
      "border-top": "none"
    },
    attrs: {
      header: "教育经历"
    }
  }, [_vm.allData.companyEmpNecessaryVOList ? _c("a-checkbox", {
    staticClass: "extrabox",
    attrs: {
      slot: "extra"
    },
    on: {
      click: function click($event) {
        return _vm.handleClick(_vm.allData.companyEmpNecessaryVOList[1], "empEducation");
      }
    },
    slot: "extra",
    model: {
      value: _vm.allData.companyEmpNecessaryVOList[1].isNecessary,
      callback: function callback($$v) {
        _vm.$set(_vm.allData.companyEmpNecessaryVOList[1], "isNecessary", $$v);
      },
      expression: "allData.companyEmpNecessaryVOList[1].isNecessary"
    }
  }) : _vm._e(), _c("span", {
    staticClass: "extraStopBox",
    attrs: {
      slot: "extra"
    },
    on: {
      click: _vm.handleStopClick
    },
    slot: "extra"
  }, [_vm._v(" 是否必须添加 ")]), _c("a-row", [_c("div", {
    staticClass: "table-header"
  }, [_c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])]), _c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])])]), _vm._l(_vm.allData.companyEmpNecessaryEducationVOList, function (item, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row"
    }, [_c("p", [_c("span", {
      staticClass: "name-box"
    }, [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    })], 1)])]);
  })], 2)], 1), _c("a-collapse-panel", {
    key: "15",
    staticStyle: {
      "border-top": "none"
    },
    attrs: {
      header: "工作经历"
    }
  }, [_vm.allData.companyEmpNecessaryVOList ? _c("a-checkbox", {
    staticClass: "extrabox",
    attrs: {
      slot: "extra"
    },
    on: {
      click: function click($event) {
        return _vm.handleClick(_vm.allData.companyEmpNecessaryVOList[4], "empWork");
      }
    },
    slot: "extra",
    model: {
      value: _vm.allData.companyEmpNecessaryVOList[4].isNecessary,
      callback: function callback($$v) {
        _vm.$set(_vm.allData.companyEmpNecessaryVOList[4], "isNecessary", $$v);
      },
      expression: "allData.companyEmpNecessaryVOList[4].isNecessary"
    }
  }) : _vm._e(), _c("span", {
    staticClass: "extraStopBox",
    attrs: {
      slot: "extra"
    },
    on: {
      click: _vm.handleStopClick
    },
    slot: "extra"
  }, [_vm._v(" 是否必须添加 ")]), _c("a-row", [_c("div", {
    staticClass: "table-header"
  }, [_c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])]), _c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])])]), _vm._l(_vm.allData.companyEmpNecessaryWorkVOList, function (item, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row"
    }, [_c("p", [_c("span", {
      staticClass: "name-box"
    }, [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    })], 1)])]);
  })], 2)], 1), _c("a-collapse-panel", {
    key: "8",
    staticStyle: {
      "border-top": "none"
    },
    attrs: {
      header: "培训经历"
    }
  }, [_vm.allData.companyEmpNecessaryVOList ? _c("a-checkbox", {
    staticClass: "extrabox",
    attrs: {
      slot: "extra"
    },
    on: {
      click: function click($event) {
        return _vm.handleClick(_vm.allData.companyEmpNecessaryVOList[3], "empTrain");
      }
    },
    slot: "extra",
    model: {
      value: _vm.allData.companyEmpNecessaryVOList[3].isNecessary,
      callback: function callback($$v) {
        _vm.$set(_vm.allData.companyEmpNecessaryVOList[3], "isNecessary", $$v);
      },
      expression: "allData.companyEmpNecessaryVOList[3].isNecessary"
    }
  }) : _vm._e(), _c("span", {
    staticClass: "extraStopBox",
    attrs: {
      slot: "extra"
    },
    on: {
      click: _vm.handleStopClick
    },
    slot: "extra"
  }, [_vm._v(" 是否必须添加 ")]), _c("a-row", [_c("div", {
    staticClass: "table-header"
  }, [_c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])]), _c("div", {
    staticClass: "table-header-row1"
  }, [_c("p", [_vm._v("字段名称")]), _c("p", [_vm._v("是否必填")]), _c("p", [_vm._v("是否开启")])])]), _vm._l(_vm.allData.companyEmpNecessaryTrainVOList, function (item, index) {
    return _c("a-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "table-header-row"
    }, [_c("p", [_c("span", {
      staticClass: "name-box"
    }, [_vm._v(_vm._s(item.fieldName))])]), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isNecessary");
        }
      },
      model: {
        value: item.isNecessary,
        callback: function callback($$v) {
          _vm.$set(item, "isNecessary", $$v);
        },
        expression: "item.isNecessary"
      }
    })], 1), _c("p", [_c("a-checkbox", {
      on: {
        change: function change($event) {
          return _vm.remoteControl(item, "isOpen");
        }
      },
      model: {
        value: item.isOpen,
        callback: function callback($$v) {
          _vm.$set(item, "isOpen", $$v);
        },
        expression: "item.isOpen"
      }
    })], 1)])]);
  })], 2)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "编辑字段"
    },
    on: {
      ok: function ok($event) {
        return _vm.handleEditOk(_vm.code);
      }
    },
    model: {
      value: _vm.editvisible,
      callback: function callback($$v) {
        _vm.editvisible = $$v;
      },
      expression: "editvisible"
    }
  }, [_c("a-form-model", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "fieldName",
    attrs: {
      prop: "fieldName",
      label: "字段名称",
      rules: {
        required: true,
        message: "必填"
      },
      help: "1-8个字符，不能与现有字段重复"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段名称",
      "max-length": 8
    },
    model: {
      value: _vm.form.fieldName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldName", $$v);
      },
      expression: "form.fieldName"
    }
  })], 1), _c("a-form-model-item", {
    ref: "fieldType",
    attrs: {
      label: "字段类型",
      prop: "fieldType",
      rules: {
        required: true,
        message: "必选"
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "selection",
      rawName: "v-selection"
    }],
    attrs: {
      placeholder: "选项",
      disabled: true
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.form.fieldType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fieldType", $$v);
      },
      expression: "form.fieldType"
    }
  }, _vm._l(_vm.fieldType, function (item2, index2) {
    return _c("a-select-option", {
      key: index2 + 1,
      attrs: {
        value: index2 + 1
      }
    }, [_vm._v(_vm._s(item2))]);
  }), 1)], 1), _vm.form.fieldType === 1 ? _c("a-form-model", _vm._b({
    staticStyle: {
      "margin-left": "36px"
    }
  }, "a-form-model", _vm.formItemLayoutWithOutLabel, false), [_vm._l(_vm.form.dropDownArray, function (item, index) {
    return _c("a-form-model-item", _vm._b({
      key: index,
      staticClass: "field option",
      attrs: {
        label: index === 0 ? "字段选项" : "",
        rules: {
          required: true,
          message: "请输入自定义选项",
          trigger: "blur"
        }
      }
    }, "a-form-model-item", index === 0 ? _vm.formItemLayout : {}, false), [_c("a-input", {
      staticStyle: {
        width: "60%",
        "margin-left": "10px"
      },
      attrs: {
        value: item,
        placeholder: "自定义选项"
      },
      on: {
        change: function change($event) {
          return _vm.handleOptionChange($event, index);
        }
      }
    }), _vm.form.dropDownArray.length > 1 ? _c("a-icon", {
      staticClass: "dynamic-delete-button",
      attrs: {
        type: "delete",
        disabled: _vm.form.dropDownArray.length === 1
      },
      on: {
        click: function click($event) {
          return _vm.removeDomain2(index);
        }
      }
    }) : _vm._e()], 1);
  }), _c("a-form-model-item", _vm._b({}, "a-form-model-item", _vm.formItemLayoutWithOutLabel, false), [_c("a-button", {
    staticStyle: {
      width: "60%",
      "margin-left": "10px"
    },
    attrs: {
      type: "dashed"
    },
    on: {
      click: _vm.addDomain2
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加自定义选项 ")], 1)], 1), _c("a-form-model-item", _vm._b({}, "a-form-model-item", _vm.formItemLayoutWithOutLabel, false))], 2) : _vm._e(), _c("a-form-model-item", {
    ref: "description",
    attrs: {
      prop: "description",
      label: "字段描述"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入字段描述"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };