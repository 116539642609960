import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.splice.js";
import moment from "moment";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    value: {
      type: Array,
      default: function _default() {}
    },
    companyId: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      contacts: []
    };
  },
  computed: {
    del_visible: function del_visible() {
      if (!this.disabled) {
        if (this.isRequired) {
          if (this.contacts.length > 1) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    value: {
      handler: function handler(newVal) {
        if (this.companyId && newVal && newVal.length > 0) {
          this.contacts = newVal.map(function (item) {
            return {
              dateRange: [moment(item.serverBeginTime), moment(item.serverEndTime)],
              customType: item.isFirst === true ? "0" : "1"
            };
          });
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.isRequired) {
      this.addItem();
    }
  },
  methods: {
    getValues: function getValues() {
      var _this = this;
      var lst = this.contacts.map(function (item) {
        var newItem = {};
        if (item.dateRange && item.dateRange.length > 0) {
          var dates = item.dateRange.map(function (item) {
            return item.format("YYYY-MM-DD");
          });
          newItem["serverBeginTime"] = dates[0];
          newItem["serverEndTime"] = dates[1];
        } else {
          return false;
        }
        newItem["isFirst"] = item.customType === "0";
        if (_this.companyId) {
          newItem["companyId"] = _this.companyId;
        }
        return newItem;
      });
      var lstFalse = lst.filter(function (item) {
        return !item;
      });
      if (lstFalse.length > 0) {
        this.$message.warning("服务合作起止时间");
        return false;
      } else {
        return lst;
      }
    },
    addItem: function addItem() {
      this.contacts = [].concat(_toConsumableArray(this.contacts), [{
        dateRange: null,
        customType: "1"
      }]);
    },
    deleteItem: function deleteItem(idx) {
      var temp = _toConsumableArray(this.contacts);
      temp.splice(idx, 1);
      this.contacts = temp;
    },
    onRangeChange: function onRangeChange(dates, idx) {
      var temp = _toConsumableArray(this.contacts);
      temp[idx].dateRange = dates;
      this.contacts = temp;
    },
    onTypeChange: function onTypeChange(e, idx) {
      var val = e.target.value;
      var temp = _toConsumableArray(this.contacts);
      temp[idx].customType = val;
      this.contacts = temp;
    }
  }
};