import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { formatDate, deFormatValuesItem } from "./funcs";
import PreviewItem from "xqjr-plugin-form/components/previewItem";
export default {
  name: "",
  components: {
    PreviewItem: PreviewItem
  },
  mixins: [],
  props: {
    dataSource: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      opened: false,
      eventFields: [],
      processes: []
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.eventFields = this.dataSource.formGroupTree[1].formGroupFieldList;
    this.processes = this.dataSource.formGroupTree[2].childList;
  },
  mounted: function mounted() {},
  methods: {
    isUrl: function isUrl(item) {
      if (item.content) {
        return item.content.includes("https://") || item.content.includes("http://");
      }
      return false;
    },
    toggleItem: function toggleItem() {
      this.opened = !this.opened;
    },
    formatDate: formatDate,
    deFormatValuesItem: deFormatValuesItem
  }
};