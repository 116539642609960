import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
var table = [{
  title: "字段名称",
  dataIndex: "name"
}, {
  title: "是否必填",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  }
}, {
  title: "是否开启",
  dataIndex: "open",
  scopedSlots: {
    customRender: "open"
  }
}];
var table1 = [{
  title: "字段名称",
  dataIndex: "name"
}, {
  title: "是否必填",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  }
}, {
  title: "是否开启",
  dataIndex: "open",
  scopedSlots: {
    customRender: "open"
  }
}, {
  title: "子项",
  dataIndex: "item",
  scopedSlots: {
    customRender: "item"
  }
}];
var data1 = [{
  enName: "bankCard",
  name: "工资卡卡号"
}, {
  enName: "bankPlace",
  name: "工资卡开户城市"
}];
var data11 = [{
  enName: "bankName",
  name: "银行名称"
}, {
  enName: "bankCardBranch",
  name: "工资卡开户支行"
}];
import { getEmpInfoRequires, putEmpRequires } from "@/api/staff/entry";
export default {
  data: function data() {
    return {
      activeKey: ["1", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16"],
      companyId: "",
      text: 1,
      table: table,
      table1: table1,
      data1: data1,
      data11: data11,
      res: {},
      result: {}
    };
  },
  created: function created() {
    // this.defaultSelectedKeys[0] = this.$route.name || "";
    this.companyId = this.$store.state.hr.companyId;
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      getEmpInfoRequires(this.companyId, "entry").then(function (res) {
        _this.res = res.data;
        _this.data1 = _this.translateData(_this.data1);
        _this.data11 = _this.translateData(_this.data11);
      });
    },
    watch: function watch(record, type, item) {
      record[type] = record[type] === 1 ? 0 : 1;
      // 必填
      var val = 0;
      if (record.unique === 1) {
        record.open = 1;
        val = 2;
      } else {
        val = record.open;
      }
      if (item) {
        var _objectSpread2;
        this.result[item] = _objectSpread(_objectSpread({}, this.result[item]), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, record.enName, val), _defineProperty(_objectSpread2, "id", record.id), _objectSpread2));
      } else {
        this.result[record.enName] = val;
      }
    },
    save: function save(data) {
      var _this2 = this;
      delete this.res.type;
      delete this.res.tabCard;
      delete this.res.tabCertificate;
      delete this.res.tabContacts;
      delete this.res.tabEducation;
      delete this.res.tabTrain;
      delete this.res.tabWork;
      delete this.res.empCardRequireVO;
      delete this.res.empCertificateRequireVO;
      delete this.res.empContactsRequireVO;
      delete this.res.empEducationRequireVO;
      delete this.res.empTrainRequireVO;
      delete this.res.empWorkRequireVO;
      putEmpRequires(_objectSpread(_objectSpread({}, this.res), this.result)).then(function (res) {
        _this2.$notification["success"]({
          message: "success",
          description: "修改成功"
        });
        _this2.$router.push({
          path: "/staff/edit"
        });
      });
    },
    transData: function transData(data) {
      var _this3 = this;
      var array = [];
      var unique, open;
      data.forEach(function (e) {
        var val = _this3.res[e.enName];
        // 必填
        if (val === 2) {
          unique = 1;
          open = 1;
        } else {
          unique = 0;
          open = val;
        }
        var listArr = [];
        var a, b;
        if (e.list.length > 0) {
          e.list.forEach(function (item) {
            var n = _this3.res[e.listName][item.enName];
            if (n === 2) {
              a = 1;
              b = 1;
            } else {
              a = 0;
              b = n;
            }
            listArr.push(_objectSpread(_objectSpread({}, item), {}, {
              unique: a,
              open: b,
              id: _this3.res[e.listName].id
            }));
          });
        }
        array.push(_objectSpread(_objectSpread({}, e), {}, {
          unique: unique,
          open: open,
          list: listArr
        }));
      });
      return array;
    },
    translateData: function translateData(data) {
      var _this4 = this;
      var array = [];
      var unique, open;
      data.forEach(function (e) {
        var val = _this4.res[e.enName];
        // 必填
        if (val === 2) {
          unique = 1;
          open = 1;
        } else {
          unique = 0;
          open = val;
        }
        array.push(_objectSpread(_objectSpread({}, e), {}, {
          unique: unique,
          open: open
        }));
      });
      return array;
    },
    goback: function goback() {
      this.$router.push({
        path: "/staff/edit"
      });
    }
  }
};