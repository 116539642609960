import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("TableTitle", [_c("template", {
    slot: "title"
  }, [_vm._v("员工花名册")]), _c("template", {
    slot: "action"
  }, [_c("a-badge", {
    attrs: {
      count: _vm.allCount,
      offset: [-16, 0]
    }
  }, [_c("a-button", {
    staticClass: "mr12",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onTodayAdd
    }
  }, [_vm._v("今日新增")])], 1), _c("a-button", {
    staticClass: "mr12",
    attrs: {
      icon: "plus",
      type: "primary"
    },
    on: {
      click: _vm.addMember
    }
  }, [_vm._v("添加员工")]), _c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuchange
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "1"
  }, [_vm._v("批量导入员工")]), _c("a-menu-item", {
    key: "2"
  }, [_vm._v("批量导入合同")])], 1), _c("a-button", [_vm._v(" 批量导入 "), _c("a-icon", {
    attrs: {
      type: "caret-down"
    }
  })], 1)], 1)], 1)], 2), _c("a-layout", {
    staticClass: "p24",
    staticStyle: {
      "padding-top": "0"
    }
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.loading,
      delay: 300
    }
  }, [_vm.isJF ? _c("div", {
    staticClass: "mb24",
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "pv16 ph24 box",
    style: {
      background: _vm.selectTypeIndex === "all" ? "var(--primary10)" : "rgba(250, 250, 250, 1)",
      "box-shadow": "inset 0px -4px 0px 0px ".concat(_vm.selectTypeIndex === "all" ? "var(--primary)" : "rgba(250, 250, 250, 1)")
    },
    on: {
      click: function click() {
        _vm.selectFunc("all");
        _vm.columnsT();
        _vm.onInitSearch();
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: require("./images/i1.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px"
    }
  }, [_vm._v("在职员工")]), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.type[0].count))])])]), _c("div", {
    staticClass: "pv16 ph24 ml24 box",
    style: {
      background: _vm.selectStatus === 1 ? "var(--primary10)" : "rgba(250, 250, 250, 1)",
      "box-shadow": "inset 0px -4px 0px 0px ".concat(_vm.selectStatus === 1 ? "var(--primary)" : "rgba(250, 250, 250, 1)")
    },
    on: {
      click: function click() {
        _vm.selectStatusFunc(1);
        _vm.onInitSearch();
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: require("./images/i2.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px"
    }
  }, [_vm._v("试用")]), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.status[0].count))])])]), _c("div", {
    staticClass: "pv16 ph24 ml24 box",
    style: {
      background: _vm.selectStatus === 2 ? "var(--primary10)" : "rgba(250, 250, 250, 1)",
      "box-shadow": "inset 0px -4px 0px 0px ".concat(_vm.selectStatus === 2 ? "var(--primary)" : "rgba(250, 250, 250, 1)")
    },
    on: {
      click: function click() {
        _vm.selectStatusFunc(2);
        _vm.onInitSearch();
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: require("./images/i3.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("span", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px",
      "margin-right": "4px"
    }
  }, [_vm._v("正式")])]), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.status[1].count))])])]), _c("div", {
    staticClass: "pv16 ph24 ml24 box",
    style: {
      background: _vm.selectStatus === 3 ? "var(--primary10)" : "rgba(250, 250, 250, 1)",
      "box-shadow": "inset 0px -4px 0px 0px ".concat(_vm.selectStatus === 3 ? "var(--primary)" : "rgba(250, 250, 250, 1)")
    },
    on: {
      click: function click() {
        _vm.selectStatusFunc(3);
        _vm.onInitSearch();
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: require("./images/i4.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("span", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px",
      "margin-right": "4px"
    }
  }, [_vm._v("待入职")])]), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.statuss[0].count))])])]), _c("div", {
    staticClass: "pv16 ph24 ml24 box",
    style: {
      background: _vm.selectStatus === 4 ? "var(--primary10)" : "rgba(250, 250, 250, 1)",
      "box-shadow": "inset 0px -4px 0px 0px ".concat(_vm.selectStatus === 4 ? "var(--primary)" : "rgba(250, 250, 250, 1)")
    },
    on: {
      click: function click() {
        _vm.selectStatusFunc(4);
        _vm.onInitSearch();
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: require("./images/i5.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("span", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px",
      "margin-right": "4px"
    }
  }, [_vm._v("已离职")])]), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.statuss[1].count))])])])]) : _c("a-row", {
    staticStyle: {
      "margin-bottom": "16px"
    },
    attrs: {
      gutter: 24
    }
  }, [_c("a-col", {
    attrs: {
      span: 14
    }
  }, [_c("a-row", {
    staticClass: "typeList",
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, _vm._l(_vm.type, function (item, index) {
    return _c("a-col", {
      key: "".concat(item.value, "_").concat(index),
      staticClass: "typeListDiv",
      class: "".concat(_vm.selectTypeIndex == item.value ? "selectType" : "", " ").concat(index == 0 ? "line" : ""),
      staticStyle: {
        width: "16%"
      },
      attrs: {
        span: 4
      },
      on: {
        click: function click() {
          _vm.selectFunc(item.value);
          _vm.columnsT();
          _vm.onInitSearch();
        }
      }
    }, [_c("div", [_c("div", [_c("h5", [_vm._v(_vm._s(item.label))]), _c("span", {
      staticClass: "number"
    }, [_vm._v(_vm._s(item.count))])])])]);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 5
    }
  }, [_c("a-row", {
    staticClass: "typeList",
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, _vm._l(_vm.status, function (item, index) {
    return _c("a-col", {
      key: "".concat(item.value, "_").concat(index),
      staticClass: "typeListDiv",
      class: _vm.selectStatus == item.value ? "selectType" : "",
      attrs: {
        span: 12
      },
      on: {
        click: function click() {
          _vm.selectStatusFunc(item.value);
          _vm.onInitSearch();
        }
      }
    }, [_c("div", [_c("h5", [_vm._v(_vm._s(item.label))]), _c("span", {
      staticClass: "number"
    }, [_vm._v(_vm._s(item.count))])])]);
  }), 1)], 1), _c("a-col", {
    attrs: {
      span: 5
    }
  }, [_c("a-row", {
    staticClass: "typeList",
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, _vm._l(_vm.statuss, function (item, index) {
    return _c("a-col", {
      key: "".concat(item.value, "_").concat(index),
      staticClass: "typeListDiv",
      class: _vm.selectStatus == item.value ? "selectType" : "",
      attrs: {
        span: 12
      },
      on: {
        click: function click() {
          _vm.selectStatusFunc(item.value);
          _vm.onInitSearch();
        }
      }
    }, [_c("div", [_c("h5", [_vm._v(_vm._s(item.label))]), _c("span", {
      staticClass: "number"
    }, [_vm._v(_vm._s(item.count))])])]);
  }), 1)], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "批量导入目的",
      width: "600px",
      footer: null
    },
    model: {
      value: _vm.chooseVisible,
      callback: function callback($$v) {
        _vm.chooseVisible = $$v;
      },
      expression: "chooseVisible"
    }
  }, [_c("a-button", {
    staticClass: "chooseBox",
    on: {
      click: function click($event) {
        return _vm.chooseOk(1);
      }
    }
  }, [_c("div", {
    staticClass: "choose"
  }, [_c("div", {
    staticClass: "chooseTitle"
  }, [_vm._v("批量导入新员工")]), _c("div", {
    staticClass: "chooseContent"
  }, [_vm._v("适用于首次使用花名册新增员工，仅支持导入花名册中没有的员工，仅支持导入在职员工。")])]), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1), _c("a-button", {
    staticClass: "chooseBox",
    staticStyle: {
      "margin-top": "18px"
    },
    on: {
      click: function click($event) {
        return _vm.chooseOk(2);
      }
    }
  }, [_c("div", {
    staticClass: "choose"
  }, [_c("div", {
    staticClass: "chooseTitle"
  }, [_vm._v("批量修改员工信息")]), _c("div", {
    staticClass: "chooseContent"
  }, [_vm._v("适用于批量更新，仅修改花名册已有的员工的信息。")])]), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1)], 1), _c("a-modal", {
    attrs: {
      footer: null,
      bodyStyle: {
        height: "180px"
      },
      title: "数据同步",
      width: "600px"
    },
    model: {
      value: _vm.haveResigned,
      callback: function callback($$v) {
        _vm.haveResigned = $$v;
      },
      expression: "haveResigned"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      "padding-left": "24px"
    }
  }, [_vm._v(_vm._s(_vm.reasonResigned ? _vm.reasonResigned : "--"))]), _c("div", [_c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      "padding-left": "24px",
      color: "#ff0000"
    }
  }, [_vm._v("*")]), _vm._v(" 若希望信息同步，请前往花名册为其更换用工单位 ")]), _c("div", [_c("span", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      "padding-left": "24px",
      color: "#ff0000"
    }
  }, [_vm._v("*")]), _vm._v(" 若不需要信息同步，请点击继续添加 ")]), _vm.companyIdResigned && !_vm.companyIdResigned.haveAuthority ? _c("div", {
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_vm._v(" （您没有【" + _vm._s(_vm.companyIdResigned.companyName) + "】的访问权限，无法为其更换用工单位） ")]) : _vm._e()]), _c("div", {
    staticClass: "drawer-footer-box"
  }, [_c("a-button", {
    style: {
      marginRight: "12px"
    },
    attrs: {
      disabled: _vm.companyIdResigned && !_vm.companyIdResigned.canAdd
    },
    on: {
      click: _vm.handleResigneCancel
    }
  }, [_vm._v("继续添加")]), _c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit",
      disabled: _vm.companyIdResigned && !_vm.companyIdResigned.haveAuthority
    },
    on: {
      click: _vm.handleOk
    }
  }, [_vm._v("前往花名册")])], 1)]), _c("TableBodyNew", {
    attrs: {
      selectHead: "",
      selection: "",
      tableName: "empInfoByCompany",
      selectedRowKeys: _vm.selectedRowKeys,
      selectedHead: _vm.selectedHeads,
      source: _vm.data,
      headerRowID: "sortable_list",
      columns: _vm.columns,
      columnsOld: _vm.columnsOld,
      isJF: _vm.isJF
    },
    on: {
      "update:selectedRowKeys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      "update:selected-row-keys": function updateSelectedRowKeys($event) {
        _vm.selectedRowKeys = $event;
      },
      "update:selectedHead": function updateSelectedHead($event) {
        _vm.selectedHeads = $event;
      },
      "update:selected-head": function updateSelectedHead($event) {
        _vm.selectedHeads = $event;
      },
      onClose: _vm.handleCancel,
      onSearch: _vm.handleFormSearch,
      onReload: _vm.onReload,
      onExport: function onExport() {
        return _vm.download(1);
      },
      onExportAll: function onExportAll() {
        return _vm.download(2);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(prop) {
        return [_c("a-table", {
          class: prop.className,
          attrs: {
            components: prop.components,
            columns: prop.columns,
            scroll: {
              x: prop.scrollWidth
            },
            customHeaderRow: function customHeaderRow() {
              return {
                domProps: {
                  id: prop.headerRowID
                }
              };
            },
            "data-source": _vm.data,
            "row-key": "id",
            tip: "加载中...",
            loading: _vm.loading,
            pagination: _vm.pagination,
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange
            }
          },
          on: {
            change: _vm.handleTableChange
          },
          scopedSlots: _vm._u([{
            key: "leaveDate",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.moment(text).format("YYYY-MM-DD") : ""))];
            }
          }, {
            key: "postType",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.$store.state.enums.postTypeArr[text - 1] : ""))];
            }
          }, {
            key: "joinDate",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.formatDate(text) : ""))];
            }
          }, {
            key: "contractType",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.getContractType(text) : ""))];
            }
          }, {
            key: "teaMethods",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.getTeaMethod(text) : ""))];
            }
          }, {
            key: "bloodGroup",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.getBloodGroup(text) : ""))];
            }
          }, {
            key: "regType",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.getRegType(text) : ""))];
            }
          }, {
            key: "politicalStatus",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.renderPoliticalStatus(text) : ""))];
            }
          }, {
            key: "birthStatus",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? text === 1 ? "已育" : "未生育" : ""))];
            }
          }, {
            key: "maritalStatus",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.getMaritalStatus(text) : ""))];
            }
          }, {
            key: "birthday",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? text.split(" ")[0] : ""))];
            }
          }, {
            key: "gender",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? text === 1 ? "男" : "女" : ""))];
            }
          }, {
            key: "contractStartTime",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.formatDate(text) : ""))];
            }
          }, {
            key: "contractEndTime",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.formatDate(text) : ""))];
            }
          }, {
            key: "formalType",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.formalType[Number(text - 1)] : ""))];
            }
          }, {
            key: "contractsStatus",
            fn: function fn(text) {
              return [_vm._v(_vm._s(text != null ? _vm.getConstractsStatusType(Number(text)) : ""))];
            }
          }, {
            key: "isImprove",
            fn: function fn(text, record) {
              return _c("div", {}, [_c("div", {
                staticStyle: {
                  cursor: "pointer"
                }
              }, [record.isImprove === true ? _c("span", {
                class: record.isImprove ? "color2294FF" : "colorC0C0C0"
              }, [_vm._v("•")]) : _vm._e(), record.isImprove === true ? _c("span", [_vm._v("已完善")]) : _vm._e(), _c("a-popover", {
                attrs: {
                  trigger: "click",
                  arrowPointAtCenter: ""
                }
              }, [_c("template", {
                slot: "content"
              }, [_c("div", {
                staticStyle: {
                  width: "200px"
                }
              }, [_c("span", {
                staticStyle: {
                  color: "red"
                }
              }, [_vm._v(_vm._s(record.needComplete))]), _c("span", [_vm._v("信息未完善")])])]), record.isImprove === false ? _c("span", {
                class: record.isImprove ? "color2294FF" : "colorC0C0C0"
              }, [_vm._v("•")]) : _vm._e(), record.isImprove === false ? _c("span", [_vm._v("未完善")]) : _vm._e()], 2)], 1)]);
            }
          }, {
            key: "verified",
            fn: function fn(text) {
              return _c("div", {}, [_c("div", [_c("span", {
                class: text ? "color2294FF" : "colorC0C0C0"
              }, [_vm._v("•")]), _vm._v(" " + _vm._s(text ? "已认证" : "未认证") + " ")])]);
            }
          }, {
            key: "tag",
            fn: function fn(text) {
              return _c("div", {}, [text ? _c("div", [_c("a-popover", {
                attrs: {
                  placement: "topLeft"
                }
              }, [_c("template", {
                slot: "content"
              }, [_c("div", {
                staticClass: "tags-tit"
              }, [_vm._v("员工标签:")]), _c("div", {
                staticClass: "tags-boxs"
              }, _vm._l(text.split(","), function (item, index) {
                return _c("p", {
                  key: index,
                  staticClass: "tags-item"
                }, [_vm._v(_vm._s(item))]);
              }), 0)]), _c("div", {
                staticClass: "tags"
              }, [_vm._v(_vm._s(text[0]))]), text.length > 1 ? _c("span", {
                staticClass: "tags-d"
              }, [_vm._v("...")]) : _vm._e()], 2)], 1) : _vm._e()]);
            }
          }, {
            key: "action",
            fn: function fn(text, record) {
              return [_c("a", {
                on: {
                  click: function click($event) {
                    return _vm.detail(record);
                  }
                }
              }, [_vm._v("详请")]), record.status === 4 ? _c("a-divider", {
                attrs: {
                  type: "vertical"
                }
              }) : _vm._e(), record.status === 4 ? _c("a", {
                on: {
                  click: function click($event) {
                    return _vm.restore(record);
                  }
                }
              }, [_vm._v("恢复在职")]) : _vm._e()];
            }
          }], null, true)
        })];
      }
    }])
  }, [_c("template", {
    slot: "search"
  }, [_c("a-input-search", {
    attrs: {
      "allow-clear": "",
      placeholder: "姓名/手机号/身份证号"
    },
    on: {
      search: _vm.handleChange
    },
    model: {
      value: _vm.searchForm.nameOrPhoneOrEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "nameOrPhoneOrEmail", $$v);
      },
      expression: "searchForm.nameOrPhoneOrEmail"
    }
  }), _c("a-range-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "入职时间",
      format: "YYYY-MM-DD",
      "allow-clear": "",
      placeholder: ["入职开始日期", "入职结束日期"]
    },
    model: {
      value: _vm.searchForm.joinDateMoment,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "joinDateMoment", $$v);
      },
      expression: "searchForm.joinDateMoment"
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "clock-circle"
    },
    slot: "suffixIcon"
  })], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "员工排序",
      "allow-clear": "",
      placeholder: "请选择员工排序"
    },
    model: {
      value: _vm.gmtOrder,
      callback: function callback($$v) {
        _vm.gmtOrder = $$v;
      },
      expression: "gmtOrder"
    }
  }, _vm._l(_vm.staffSort, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.key
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "员工状态",
      "allow-clear": "",
      placeholder: "请选择员工状态"
    },
    model: {
      value: _vm.form.formalType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "formalType", $$v);
      },
      expression: "form.formalType"
    }
  }, [_c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("试用")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("正式")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "性别",
      "allow-clear": "",
      placeholder: "请选择员工类型"
    },
    model: {
      value: _vm.form.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }, [_c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("男")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("女")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "学历",
      "allow-clear": "",
      placeholder: "请选择学历"
    },
    model: {
      value: _vm.form.highestEducation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "highestEducation", $$v);
      },
      expression: "form.highestEducation"
    }
  }, _vm._l(_vm.highestEducation, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.label
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "信息补充",
      "allow-clear": "",
      placeholder: "请选择信息补充"
    },
    model: {
      value: _vm.form.isImprove,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isImprove", $$v);
      },
      expression: "form.isImprove"
    }
  }, [_c("a-select-option", {
    key: 0,
    attrs: {
      value: 0
    }
  }, [_vm._v("未完善")]), _c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("已完善")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "实名认证",
      "allow-clear": "",
      placeholder: "请选择实名认证"
    },
    model: {
      value: _vm.form.verified,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "verified", $$v);
      },
      expression: "form.verified"
    }
  }, [_c("a-select-option", {
    key: 0,
    attrs: {
      value: 0
    }
  }, [_vm._v("未认证")]), _c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("已认证")])], 1), !_vm.isJF ? _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "工作性质",
      "allow-clear": "",
      placeholder: "请选择工作性质",
      options: _vm.$store.state.enums.postTypeLst
    },
    model: {
      value: _vm.form.postType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "postType", $$v);
      },
      expression: "form.postType"
    }
  }) : _vm._e(), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "政治面貌",
      "allow-clear": "",
      placeholder: "请选择政治面貌"
    },
    model: {
      value: _vm.form.politicalStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "politicalStatus", $$v);
      },
      expression: "form.politicalStatus"
    }
  }, _vm._l(_vm.politicalStatusOptions, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "婚姻状况",
      "allow-clear": "",
      placeholder: "请选择婚姻状况"
    },
    model: {
      value: _vm.form.maritalStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "maritalStatus", $$v);
      },
      expression: "form.maritalStatus"
    }
  }, [_c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("已婚")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("未婚")]), _c("a-select-option", {
    key: 3,
    attrs: {
      value: 3
    }
  }, [_vm._v("离异")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "合同签署状态",
      "allow-clear": "",
      placeholder: "请选择合同签署状态"
    },
    model: {
      value: _vm.form.contractsStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contractsStatus", $$v);
      },
      expression: "form.contractsStatus"
    }
  }, [_c("a-select-option", {
    key: 0,
    attrs: {
      value: 0
    }
  }, [_vm._v("合同未发起")]), _c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("待员工签署")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("待公司签署")]), _c("a-select-option", {
    key: 3,
    attrs: {
      value: 3
    }
  }, [_vm._v("签署完成")]), _c("a-select-option", {
    key: -1,
    attrs: {
      value: -1
    }
  }, [_vm._v("合同过期未签署")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "标签",
      "allow-clear": "",
      placeholder: "请选择标签",
      mode: "multiple",
      "option-label-prop": "label"
    },
    model: {
      value: _vm.form.tagTL,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tagTL", $$v);
      },
      expression: "form.tagTL"
    }
  }, _vm._l(_vm.labelList, function (item, index) {
    return _c("a-select-option", {
      key: "".concat(item.tagName, "_").concat(index),
      attrs: {
        value: item.tagName,
        label: item.tagName
      }
    }, [_vm._v(_vm._s(item.tagName))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "渠道",
      "allow-clear": "",
      placeholder: "请选择渠道"
    },
    model: {
      value: _vm.form.channelName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "channelName", $$v);
      },
      expression: "form.channelName"
    }
  }, _vm._l(_vm.channelNames, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "证件类型",
      "allow-clear": true,
      placeholder: "请选择证件类型"
    },
    model: {
      value: _vm.form.certificateType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "certificateType", $$v);
      },
      expression: "form.certificateType"
    }
  }, [_c("a-select-option", {
    key: 1,
    attrs: {
      value: 1
    }
  }, [_vm._v("身份证号")]), _c("a-select-option", {
    key: 2,
    attrs: {
      value: 2
    }
  }, [_vm._v("港澳居民往来内地通行证")]), _c("a-select-option", {
    key: 3,
    attrs: {
      value: 3
    }
  }, [_vm._v("台湾居民往来大陆通行证")])], 1), _c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "签约主体",
      options: _vm.contractSubjectSource,
      "allow-clear": true,
      placeholder: "请选择签约主体"
    },
    model: {
      value: _vm.form.contractSubject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contractSubject", $$v);
      },
      expression: "form.contractSubject"
    }
  })], 1), _c("template", {
    slot: "search-right"
  }, [_c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    on: {
      click: _vm.deleteBatchFun
    }
  }, [_vm._v("批量删除")]), _c("a-menu-item", {
    on: {
      click: function click() {
        return _vm.changeUnitFun();
      }
    }
  }, [_vm._v("批量更换用工单位")])], 1), _c("a-button", [_c("a-icon", {
    staticStyle: {},
    attrs: {
      type: "unordered-list"
    }
  }), _vm._v(" 批量操作 ")], 1)], 1)], 1)], 2), _c("a-drawer", {
    attrs: {
      title: "添加员工",
      width: 580,
      destroyOnClose: true,
      visible: _vm.visibleEmpyleeDrawer,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: function close($event) {
        return _vm.onCloseDrawer("visibleEmpyleeDrawer");
      }
    }
  }, [_c("a-tabs", {
    attrs: {
      activeKey: _vm.tabKey,
      type: "card"
    },
    on: {
      change: _vm.callback
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "添加员工"
    }
  }, [_c("div", {
    staticClass: "emp-title"
  }, [_vm._v("员工信息")]), _c("DynamicAddEmployees", {
    attrs: {
      outEmpDetail: _vm.outEmpDetail,
      title: _vm.title,
      moduleCode: "onJob"
    },
    on: {
      saveValueChange: _vm.saveValueChange,
      cancelForm: _vm.cancelForm
    }
  })], 1), _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "添加待入职"
    }
  }, [_c("div", {
    staticClass: "emp-title"
  }, [_vm._v("员工信息")]), _c("DynamicAddEmployees", {
    attrs: {
      title: _vm.title,
      moduleCode: "waitJob"
    },
    on: {
      cancelForm: _vm.cancelForm,
      saveValueChange: _vm.saveValueChange
    }
  })], 1), _c("a-tab-pane", {
    key: "3",
    attrs: {
      tab: "扫码入职"
    }
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.loadingcode,
      delay: 300
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("div", {
    staticClass: "processBox"
  }, [_c("div", {
    staticClass: "processBoxLCJS"
  }, [_vm._v("流程介绍")]), _c("div", {
    staticClass: "maBottom4"
  }, [_vm._v("1.配置入职登记二维码并分享")]), _c("div", {
    staticClass: "maBottom4"
  }, [_vm._v("2.员工填写并提交入职登记信息")]), _c("div", [_vm._v("3.公司管理员在待入职管理中，完善员工信息并确认入职")])]), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "二维码有效期"
    }
  }, [_c("div", {
    staticStyle: {
      width: "200px",
      display: "flex",
      "align-items": "center",
      "font-size": "12px"
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "100%",
      "margin-right": "10px"
    },
    attrs: {
      allowClear: "",
      min: 1
    },
    on: {
      change: _vm.setAllContent
    },
    model: {
      value: _vm.timeDay,
      callback: function callback($$v) {
        _vm.timeDay = $$v;
      },
      expression: "timeDay"
    }
  }), _c("div", {
    staticStyle: {
      "font-weight": "800",
      "font-size": "14px"
    }
  }, [_vm._v("天")])], 1), _vm.timeDay ? _c("div", [_vm._v("有效期：" + _vm._s(_vm.FailureTime))]) : _vm._e()]), _vm.typeValue === 1 && _vm.SMMubanList.length > 1 ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "审批模板"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType", {
        rules: [{
          required: true,
          message: "请选择审批模板"
        }]
      }],
      expression: "[\n                    'postType',\n                    {\n                      rules: [{ required: true, message: '请选择审批模板' }]\n                    }\n                  ]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      "default-value": _vm.muban,
      placeholder: "请选择审批模板"
    },
    on: {
      change: _vm.mubanSelect
    },
    model: {
      value: _vm.muban,
      callback: function callback($$v) {
        _vm.muban = $$v;
      },
      expression: "muban"
    }
  }, _vm._l(_vm.SMMubanList, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1) : _vm._e(), !_vm.isJF ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "工作性质"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType", {
        rules: [{
          required: true,
          message: "请选择工作性质"
        }]
      }],
      expression: "[\n                    'postType',\n                    {\n                      rules: [{ required: true, message: '请选择工作性质' }]\n                    }\n                  ]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      "default-value": _vm.SMDefault,
      options: _vm.$store.state.enums.postTypeLst,
      placeholder: "请选择工作性质"
    },
    on: {
      change: _vm.postTypeSaoMaChange
    },
    model: {
      value: _vm.SMDefault,
      callback: function callback($$v) {
        _vm.SMDefault = $$v;
      },
      expression: "SMDefault"
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "渠道来源"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType", {
        rules: []
      }],
      expression: "['postType', { rules: [] }]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      "default-value": _vm.channelDefault,
      "show-search": "",
      allowClear: "",
      placeholder: "请选择渠道来源"
    },
    on: {
      change: _vm.channelSaoMaChange
    },
    model: {
      value: _vm.channelDefault,
      callback: function callback($$v) {
        _vm.channelDefault = $$v;
      },
      expression: "channelDefault"
    }
  }, _vm._l(_vm.siteChannelList, function (item, index) {
    return _c("a-select-option", {
      key: "".concat(item.value, "_").concat(index),
      attrs: {
        value: "".concat(item.value, "_").concat(item.label)
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "生成二维码"
    }
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      display: "flex",
      "align-items": "center",
      "margin-top": "15px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "150px",
      height: "150px"
    },
    attrs: {
      src: _vm.codeimg
    }
  }), _vm.refreshCode ? _c("div", {
    staticStyle: {
      position: "absolute",
      "z-index": "100",
      width: "150px",
      height: "150px",
      "background-color": "#252525aa"
    },
    on: {
      click: _vm.creatCode
    }
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer",
      width: "100%",
      height: "100%",
      display: "flex",
      "flex-flow": "column",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c("a-icon", {
    style: {
      fontSize: "18px",
      color: "#fff"
    },
    attrs: {
      type: "reload"
    }
  }), _c("span", {
    staticStyle: {
      color: "#ffffff"
    }
  }, [_vm._v("刷新二维码")])], 1)]) : _vm._e()]), _c("div", [_c("xq-icon", {
    staticClass: "primary_color weiwancheng3xCol",
    attrs: {
      type: "icon-weiwancheng3x"
    }
  }), _c("div", {
    staticClass: "weiwancheng3xShow"
  }, [_vm._v(_vm._s(_vm.isJF ? "" : "工作性质、") + "渠道来源配置完成后，将成为二维码自带属性，请谨慎分享")])], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.refreshCode
    },
    on: {
      click: _vm.downloadCod
    }
  }, [_vm._v("下载二维码")])], 1)])], 1)], 1)], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "添加待入职",
      visible: _vm.isCloseMethod2,
      width: "520px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_vm.isApproval ? _c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("关闭")]) : _vm._e(), !_vm.isApproval ? _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.finishEdit2
    }
  }, [_vm._v("继续办理")]) : _vm._e()], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "column",
      "align-items": "center"
    }
  }, [!_vm.isApproval ? _c("img", {
    staticStyle: {
      width: "200px",
      height: "200px"
    },
    attrs: {
      src: require("@/assets/img/1.png")
    }
  }) : _c("img", {
    staticStyle: {
      width: "200px",
      height: "200px"
    },
    attrs: {
      src: require("@/assets/img/2.png")
    }
  }), _c("span", {
    staticStyle: {
      "font-size": "20px",
      "font-weight": "800"
    }
  }, [_vm._v(_vm._s(!_vm.isApproval ? "提交成功" : "审批中"))]), _c("span", {
    staticStyle: {
      color: "#00000077",
      "margin-top": "12px",
      "margin-bottom": "12px"
    }
  }, [_vm._v(_vm._s(!_vm.isApproval ? "可在入职管理中选择确认到岗或者未到岗" : "已提交至审批流程"))])])], 2), _c("a-modal", {
    attrs: {
      title: "选择审批模板",
      visible: _vm.selectModel,
      width: "520px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.finishEdit3
    }
  }, [_vm._v("下一步")])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "column",
      "align-items": "center"
    }
  }, _vm._l(_vm.templatesListGroup, function (item, i) {
    return _c("div", {
      key: i,
      class: {
        backgroundC: item.select
      },
      staticStyle: {
        "padding-top": "10px",
        "padding-bottom": "10px",
        "padding-right": "15px",
        width: "100%",
        display: "flex",
        "flex-flow": "row",
        "align-items": "center",
        "justify-content": "space-between"
      },
      on: {
        click: function click($event) {
          return _vm.temClick(item);
        }
      }
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        "flex-flow": "row"
      }
    }, [_c("img", {
      staticStyle: {
        width: "52px",
        height: "52px",
        "margin-right": "15px"
      },
      attrs: {
        src: require("@/assets/img/模板icon.png")
      }
    }), _c("div", [_c("span", {
      staticStyle: {
        display: "flex",
        "flex-flow": "column",
        "font-size": "16px",
        "font-weight": "600"
      }
    }, [_vm._v(_vm._s(item.typeName))]), _c("span", {
      staticStyle: {
        color: "#00000077"
      }
    }, [_vm._v(_vm._s(item.typeDesc))])])]), item.select ? _c("xq-icon", {
      staticClass: "primary_color f20",
      attrs: {
        type: "icon-wancheng2"
      }
    }) : _vm._e()], 1);
  }), 0)], 2)], 1), _c("ChangeUnit", {
    ref: "select",
    on: {
      saveOk1: _vm.saveOk1
    }
  }), _c("BatchDelete", {
    ref: "selectBatchDelete",
    on: {
      saveOk1: _vm.batchDelete
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };