import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
var commonRoutes = [{
  path: "management",
  // 扣缴义务人管理
  meta: {
    route: "/taxCalculate/management",
    routeSession: "taxCalculatePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/management"));
    });
  }
}, {
  path: "people",
  // 个税人员库
  meta: {
    route: "/taxCalculate/people",
    routeSession: "taxCalculatePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/people"));
    });
  }
}, {
  path: "people/add",
  // 个税人员库 添加人员
  name: "taxCalculatePeopleAdd",
  meta: {
    route: "/taxCalculate/people",
    routeSession: "taxCalculatePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/people/add"));
    });
  }
}, {
  path: "people/import",
  // 个税人员库 添加人员
  name: "taxCalculatePeopleImportAdd",
  meta: {
    route: "/taxCalculate/people",
    routeSession: "taxCalculatePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/people/imports"));
    });
  }
}, {
  path: "waiting",
  // 人员待报送
  meta: {
    route: "/taxCalculate/waiting",
    routeSession: "taxCalculatePage",
    tabType: "1"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/report"));
    });
  }
}, {
  path: "report",
  // 人员已报送
  meta: {
    route: "/taxCalculate/report",
    routeSession: "taxCalculatePage",
    tabType: "2"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/report"));
    });
  }
}, {
  path: "stop",
  // 人员停止报送
  meta: {
    route: "/taxCalculate/stop",
    routeSession: "taxCalculatePage",
    tabType: "3"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/report"));
    });
  }
}, {
  path: "report/confirm",
  // 人员报送 确认报送信息
  name: "reportConfirm",
  meta: {
    route: "/taxCalculate/report",
    routeSession: "taxCalculatePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/report/confirm"));
    });
  }
}, {
  path: "calculate",
  // 个税计算
  meta: {
    route: "/taxCalculate/calculate",
    routeSession: "taxCalculatePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/calculate"));
    });
  }
}, {
  path: "calculate/add",
  // 添加
  name: "calculateAdd",
  meta: {
    route: "/taxCalculate/calculate",
    routeSession: "taxCalculatePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/calculate/add"));
    });
  }
}, {
  path: "calculate/importTaxData",
  // 导入算税数据
  name: "calculateImportTaxData",
  meta: {
    route: "/taxCalculate/importTaxData",
    routeSession: "taxCalculatePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/calculate/add/importPeople/importTaxData"));
    });
  }
},
// {
//   path: "abnormal", // 异常
//   name: "applyForAbnormal",
//   component: () => import("@/views/paySalary/applyFor/abnormal")
// },
{
  path: "calculate/info",
  // 详情
  name: "calculateInfo",
  meta: {
    route: "/taxCalculate/calculate",
    routeSession: "taxCalculatePage"
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate/calculate/info"));
    });
  }
}];
export default {
  path: "/taxCalculate",
  // 个税
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/taxCalculate"));
    });
  },
  redirect: "/taxCalculate/management",
  children: [].concat(commonRoutes)
};