import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";

// 分页查询项目
export function retrieveReq(params) {
  return request({
    url: "/hr/api/v1/contracts/selectAllContractConfig",
    method: "GET",
    params: params
  });
}

// 新增项目
export function createReq(data) {
  return request({
    url: "/hr/api/v1/contracts/site/".concat(data.siteId, "/saveConfig"),
    method: "PUT",
    data: data
  });
}

// 修改项目
export function updateReq(data) {
  return request({
    url: "",
    method: "PUT",
    data: data
  });
}

// 删除项目
export function deleteReq(siteId, projectId) {
  return request({
    url: "/hr/api/v1/contracts/site/".concat(siteId, "/delete/").concat(projectId),
    method: "DELETE"
  });
}

// 删除项目
export function deleteBatchReq(data) {
  return request({
    url: "",
    method: "DELETE",
    data: data
  });
}

// 根据项目id查询详情
export function detailReq(data) {
  return request({
    url: "".concat(data),
    method: "GET"
  });
}

// 充值租户合同余额
export function recharge(siteId, id, quota) {
  return request({
    url: "/hr/api/v1/contracts/site/".concat(siteId, "/pay/").concat(id, "/").concat(quota),
    method: "POST"
  });
}

// 开启租户合同配置
export function openContract(siteId, id) {
  return request({
    url: "/hr/api/v1/contracts/site/".concat(siteId, "/enableConfig/").concat(id),
    method: "POST"
  });
}
// 关闭租户合同配置
export function disableContract(siteId, id) {
  return request({
    url: "/hr/api/v1/contracts/site/".concat(siteId, "/disableConfig/").concat(id),
    method: "POST"
  });
}