import { render, staticRenderFns } from "./area.vue?vue&type=template&id=354b3cbd&scoped=true&"
import script from "./area.vue?vue&type=script&lang=js&"
export * from "./area.vue?vue&type=script&lang=js&"
import style0 from "./area.vue?vue&type=style&index=0&id=354b3cbd&scoped=true&lang=css&"
import style1 from "./area.vue?vue&type=style&index=1&id=354b3cbd&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "354b3cbd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\tools\\frontend_workspace\\e-hr-master\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('354b3cbd')) {
      api.createRecord('354b3cbd', component.options)
    } else {
      api.reload('354b3cbd', component.options)
    }
    module.hot.accept("./area.vue?vue&type=template&id=354b3cbd&scoped=true&", function () {
      api.rerender('354b3cbd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/staff/management/info/component/area.vue"
export default component.exports