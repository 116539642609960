import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { getChildrenComany, getChildCompanyAdmin, getSubCompany } from "@/api/overview/employer";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
import axios from "axios";
import qs from "qs";
import { mapGetters } from "vuex";
var columns = [{
  title: "用工单位组名称",
  dataIndex: "name"
  // width: 170
},
// {
//   title: "公司地址",
//   dataIndex: "companyAddress",
//   ellipsis: true,
//   width: 120
// },
// {
//   title: "分管地区",
//   dataIndex: "companyArea",
//   ellipsis: true,
//   width: 120,
//   scopedSlots: { customRender: "companyArea" }
// },
{
  title: "分管单位",
  dataIndex: "companyStr",
  ellipsis: true,
  // width: 360,
  scopedSlots: {
    customRender: "companyStr"
  }
}
// {
//   title: "管理员",
//   dataIndex: "legalPerson",
//   scopedSlots: { customRender: "legalPerson" },
//   ellipsis: true,
//   width: 160
// }
];

var userGroup = [{
  title: "姓名",
  dataIndex: "userName",
  key: "userName",
  scopedSlots: {
    customRender: "userName"
  },
  width: 80
}, {
  title: "员工手机号",
  dataIndex: "phone",
  key: "phone",
  scopedSlots: {
    customRender: "phone"
  },
  width: 80
}];
var companyGroup = [{
  title: "单位名称",
  dataIndex: "name",
  key: "name",
  scopedSlots: {
    customRender: "name"
  },
  width: 80
}, {
  title: "联系人姓名",
  dataIndex: "legalPerson",
  key: "legalPerson",
  scopedSlots: {
    customRender: "legalPerson"
  },
  width: 80
}, {
  title: "联系电话",
  dataIndex: "telephone",
  key: "telephone",
  scopedSlots: {
    customRender: "telephone"
  },
  width: 80
}];
export default {
  mixins: [mixin],
  data: function data() {
    return {
      unitList: [],
      userList: [],
      userGroup: userGroup,
      companyGroup: companyGroup,
      userVisible: false,
      companyVisible: false,
      equipmentIdList: [],
      name: "",
      joinCityCode: "",
      joinCityName: "",
      searchForm: {},
      unitSearchForm: {},
      selectedRowKeys: [],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      pagination2: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      pagination3: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 12
      },
      data: [],
      regionList: [],
      columns: columns,
      compyName: "",
      userRecord: "",
      companyRecord: "",
      tableLoading: false,
      pageNam: "",
      userListLoading: false
    };
  },
  computed: _objectSpread({}, mapGetters(["permissions_site"])),
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    // if (this.$route.query.name)
    this.getChildrenComanyList();
  },
  methods: {
    showCompany: function showCompany(record) {
      var _this = this;
      this.userListLoading = true;
      this.userList = [];
      this.companyRecord = record;
      this.unitSearchForm = {};
      this.compyName = record.name;
      this.pagination2.current = 1;
      this.companyVisible = true;
      getSubCompany({
        id: record.id
      }).then(function (res) {
        _this.userList = res.data;
        _this.userListLoading = false;
      }).catch(function () {
        _this.userListLoading = false;
      });
    },
    showAdministrator: function showAdministrator(record) {
      this.userListLoading = true;
      this.userList = [];
      this.searchForm = {};
      this.compyName = record.name;
      this.pagination2.current = 1;
      this.userRecord = record;
      this.userVisible = true;
      this.onGetChildCompanyAdmin(record.id);
    },
    onGetChildCompanyAdmin: function onGetChildCompanyAdmin(id, name) {
      var _this2 = this;
      getChildCompanyAdmin({
        userQuery: {
          companyIdList: [id],
          userQuery: name,
          pageNo: this.pagination2.current,
          pageSize: this.pagination2.pageSize
        }
      }).then(function (res) {
        _this2.userList = res.data;
        _this2.userListLoading = false;
      }).catch(function () {
        _this2.userList = [];
        _this2.userListLoading = false;
      });
    },
    handleUserOk: function handleUserOk() {
      this.userVisible = false;
    },
    handleCompanyOk: function handleCompanyOk() {
      this.companyVisible = false;
    },
    handlePageChange: function handlePageChange(pagination, filters, sorter) {
      this.pagination2.current = pagination.current;
      this.pagination2.pageSize = pagination.pageSize;
    },
    handlePageChange2: function handlePageChange2(pagination, filters, sorter) {
      this.pagination3.current = pagination.current;
      this.pagination3.pageSize = pagination.pageSize;
    },
    nameChange: function nameChange(e) {
      var _this3 = this;
      this.searchForm.keyWord = e;
      this.pagination.current = 1;
      getSubCompany({
        id: this.companyRecord.id,
        name: e
      }).then(function (res) {
        _this3.userList = res.data;
        _this3.userListLoading = false;
      }).catch(function () {
        _this3.userListLoading = false;
      });
    },
    unitNameChange: function unitNameChange(e) {
      var _this4 = this;
      getSubCompany({
        id: this.companyRecord.id,
        name: e
      }).then(function (res) {
        _this4.userList = res.data;
        _this4.userListLoading = false;
      }).catch(function () {
        _this4.userListLoading = false;
      });
    },
    getChildrenComanyList: function getChildrenComanyList() {
      var _this5 = this;
      this.tableLoading = true;
      var data = {};
      if (this.$route.query.pageType) {
        var tenantId = sessionStorage.getItem("tenantId");
        data = _objectSpread({
          ids: tenantId
        }, this.searchForm);
      } else {
        data = _objectSpread({}, this.searchForm);
      }
      getChildrenComany(data).then(function (res) {
        _this5.tableLoading = false;
        _this5.data = res.data.entities || [];
        _this5.pagination.total = res.data.entityCount;
      }).catch(function () {
        _this5.tableLoading = false;
      });
    },
    handleSyncOk: function handleSyncOk() {},
    handleSearch: function handleSearch(val) {
      this.searchForm.name = val;
      this.getChildrenComanyList();
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 导出员工信息excel表
    download: function download(i) {
      console.log(this.searchForm);
      if (i === 2) {
        this.exportData = _objectSpread({}, this.searchForm);
      } else {
        this.exportData = _objectSpread(_objectSpread({}, this.searchForm), {}, {
          // companyId: this.companyId,
          ids: this.selectedRowKeys
        });
      }
      if (!this.selectedRowKeys.length && i === 1) {
        this.$notification["error"]({
          message: "error",
          description: "导出失败，至少选择一个公司"
        });
      }
      if (i === 2 || this.selectedRowKeys.length) {
        // this.url = `/hr/api/v1/statisticsPage/export`;
        this.url = "/hr/api/v1/statisticsPage/exportChildCompany";
        var siteName = "";
        this.permissions_site.forEach(function (item, index) {
          if (item.currSite) siteName = item.name;
        });
        this.excelName = "".concat(siteName, "\u5B50\u516C\u53F8\u8BE6\u60C5.xlsx");
        this.postDownload();
      }
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
    }
  }
};