import "core-js/modules/es.function.name.js";
var columns = [{
  title: "生效时间",
  dataIndex: "timeShow",
  key: "timeShow",
  width: 100
}, {
  title: "基础规则",
  dataIndex: "hourAmountRuleShow",
  key: "hourAmountRuleShow1",
  width: 120
}, {
  title: "离职规则",
  dataIndex: "leaveOfficeRuleShow",
  key: "leaveOfficeRuleShow",
  width: 120
}];
import { salaryCalculateRules, empInfosShow, listAllByEmpId, getsalaryCalculateRuleGroups } from "./api";
export default {
  data: function data() {
    var _this = this;
    return {
      empInfo: {},
      defaultEmpId: "",
      companyId: "",
      salaryRuleName: "",
      serviceFeeRuleName: "",
      data: [],
      data1: [],
      salaryRuleId: "",
      serviceFeeRuleId: "",
      columns: columns,
      paginationOpt1: {
        current: 1,
        defaultCurrent: 1,
        // 默认当前页数
        defaultPageSize: 5,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["5", "10", "20"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt1.current = 1;
          _this.paginationOpt1.defaultCurrent = 1;
          _this.paginationOpt1.defaultPageSize = pageSize;
          _this.salaryCalculateRulesFun(); // 显示列表的接口名称
        },

        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt1.current = current;
          _this.paginationOpt1.defaultCurrent = current;
          _this.paginationOpt1.defaultPageSize = size;
          _this.salaryCalculateRulesFun();
        }
      },
      paginationOpt2: {
        current: 1,
        defaultCurrent: 1,
        // 默认当前页数
        defaultPageSize: 5,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["5", "10", "20"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt2.current = 1;
          _this.paginationOpt2.defaultCurrent = 1;
          _this.paginationOpt2.defaultPageSize = pageSize;
          _this.serviceFeeRuleIdFun(); // 显示列表的接口名称
        },

        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt2.current = current;
          _this.paginationOpt2.defaultCurrent = current;
          _this.paginationOpt2.defaultPageSize = size;
          _this.serviceFeeRuleIdFun();
        }
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    empInfosShowFun: function empInfosShowFun(defaultEmpId, companyId, empInfo) {
      var _this2 = this;
      this.defaultEmpId = defaultEmpId;
      this.companyId = companyId;
      this.empInfo = empInfo;
      var data = {
        companyId: companyId,
        empId: defaultEmpId
      };
      empInfosShow(data).then(function (res) {
        if (res.data.entities.length === 0) {
          _this2.data = [];
        } else {
          if (res.data.entities[0].salaryRule) {
            _this2.salaryRuleId = res.data.entities[0].salaryRule.id;
            _this2.salaryRuleName = res.data.entities[0].salaryRule.name;
          } else {
            _this2.data = [];
          }
        }
      }).catch(function (err) {
        console.log("err", err);
      });
      this.serviceFeeRuleIdFun(); // 招工劳务费规则
      this.salaryCalculateRulesFun(); // 工价规则
    },
    serviceFeeRuleIdFun: function serviceFeeRuleIdFun() {
      var _this3 = this;
      if (this.empInfo.serviceFeeRuleId) {
        getsalaryCalculateRuleGroups(this.empInfo.serviceFeeRuleId).then(function (res) {
          _this3.serviceFeeRuleName = res.data ? res.data.name : null;
        }).catch(function (err) {
          console.log(err);
        });
        var data = {
          pageNo: this.paginationOpt2.current,
          pageSize: this.paginationOpt2.defaultPageSize,
          salaryCalculateRuleGroupId: this.empInfo.serviceFeeRuleId // "83D102B6CF6245B1B79E8B8BDEFA7F39"
        };

        salaryCalculateRules(data).then(function (res) {
          _this3.data1 = res.data.entities;
          _this3.paginationOpt2.total = res.data.entityCount;
        }).catch(function (err) {
          console.log("err", err);
        });
      } else {
        this.data1 = [];
        this.paginationOpt2.total = 0;
        this.serviceFeeRuleName = null;
      }
    },
    salaryCalculateRulesFun: function salaryCalculateRulesFun() {
      var _this4 = this;
      if (this.empInfo.salaryType === 2 || this.empInfo.salaryType === "2") {
        var data = {
          pageNo: this.paginationOpt1.current,
          pageSize: this.paginationOpt1.defaultPageSize,
          salaryCalculateRuleGroupId: this.empInfo.salaryRuleId // "83D102B6CF6245B1B79E8B8BDEFA7F39"
        };

        salaryCalculateRules(data).then(function (res) {
          _this4.data = res.data.entities;
          _this4.paginationOpt1.total = res.data.entityCount;
        }).catch(function (err) {
          console.log("err", err);
        });
      } else {
        this.data = [];
        this.paginationOpt1.total = 0;
      }
    }
  }
};