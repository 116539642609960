var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "background-color": "#fff",
      width: "100%",
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "add-payslip-forth"
  }, [_c("div", {
    staticClass: "content-con"
  }, [_c("a-result", {
    attrs: {
      status: "success",
      title: "派单成功"
    },
    scopedSlots: _vm._u([{
      key: "extra",
      fn: function fn() {
        return [_c("a-row", {
          attrs: {
            type: "flex"
          }
        }, [_c("a-col", {
          staticClass: "col-left"
        }, [_c("a-icon", {
          staticClass: "img",
          attrs: {
            type: "check-circle"
          }
        }), _c("a-divider", {
          staticClass: "img-v",
          attrs: {
            type: "vertical"
          }
        }), _c("a-icon", {
          staticClass: "img",
          attrs: {
            type: "check-circle"
          }
        })], 1), _c("a-col", {
          staticClass: "full",
          staticStyle: {
            "text-align": "left"
          }
        }, [_c("a-row", {
          staticClass: "content-con-text mg_b24"
        }, [_c("a-col", {
          staticClass: "mg_b12 round"
        }, [_c("strong", [_vm._v("派单成功")])]), _c("a-col", {
          staticClass: "sub_tit"
        }, [_vm._v(" 分公司可通过“社保接单”查看指派人数。")])], 1), _c("a-row", {
          staticClass: "content-con-text"
        }, [_c("a-col", {
          staticClass: "mg_b12 round"
        }, [_c("strong", [_vm._v("发送完成 "), _c("span", {
          staticClass: "primary"
        }, [_vm._v(" 100%")])])]), _c("a-col", {
          staticClass: "sub_tit"
        }, [_vm._v("共派单" + _vm._s(_vm.$route.query.num) + "人 ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goDetail
    }
  }, [_vm._v("查看发放详情 ")])], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };