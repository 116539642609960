export default {
  fields: {
    empName: {
      model: "empName"
    },
    "employeeVO.licenseType": {
      model: "employeeVO.licenseType"
    },
    licenseNumber: {
      model: "licenseNumber"
    },
    phone: {
      model: "phone"
    },
    status: {
      model: "status"
    },
    personSize: {
      model: "personSize"
    },
    employmentType: {
      model: "employmentType"
    },
    "employeeVO.birthday": {
      model: "employeeVO.birthday"
    },
    employedDate: {
      model: "employedDate"
    },
    "employeeVO.gender": {
      model: "employeeVO.gender"
    },
    "employeeVO.nationality": {
      model: "employeeVO.nationality"
    },
    personnelArea: {
      model: "personnelArea"
    },
    totalPersonalInvestment: {
      model: "totalPersonalInvestment"
    },
    personalInvestmentRatio: {
      model: "personalInvestmentRatio"
    },
    "employeeVO.isDisable": {
      model: "employeeVO.isDisable"
    },
    "employeeVO.isMartyr": {
      model: "employeeVO.isMartyr"
    },
    "employeeVO.isOld": {
      model: "employeeVO.isOld"
    },
    "employeeVO.disableCard": {
      model: "employeeVO.disableCard"
    },
    "employeeVO.martyrCard": {
      model: "employeeVO.martyrCard"
    },
    isDeductDeductible: {
      model: "isDeductDeductible"
    },
    resignDate: {
      model: "resignDate"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "empName",
        title: "姓名"
      }, {
        model: "employeeVO.licenseType",
        title: "证件类型"
      }, {
        model: "licenseNumber",
        title: "证件号码"
      }, {
        model: "phone",
        title: "手机号码"
      }, {
        model: "status",
        title: "人员状态"
      }, {
        model: "resignDate",
        title: "离职日期"
      }, {
        model: "employmentType",
        title: "任职受雇类型"
      }, {
        model: "employedDate",
        title: "任职受雇日期"
      }, {
        model: "employeeVO.gender",
        title: "性别"
      }, {
        model: "employeeVO.birthday",
        title: "出生日期"
      }, {
        model: "employeeVO.nationality",
        title: "国籍"
      }, {
        model: "totalPersonalInvestment",
        title: "个人投资总额"
      }, {
        model: "personalInvestmentRatio",
        title: "个人投资比例"
      }, {
        model: "employeeVO.isDisable",
        title: "是否残疾"
      }, {
        model: "employeeVO.isMartyr",
        title: "是否烈属"
      }, {
        model: "employeeVO.isOld",
        title: "是否孤老"
      }, {
        model: "employeeVO.disableCard",
        title: "残疾证号"
      }, {
        model: "employeeVO.martyrCard",
        title: "烈属证号"
      }, {
        model: "isDeductDeductible",
        title: "是否扣除减除费用"
      }],
      // customize: {
      //   rowKey: "id"
      // },
      extend: {
        batch: false,
        action: false
      }
    }
  }
};