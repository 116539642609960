import detailsTopItem from "./detailsTopItem.vue";
import LogList from "./logList.vue";
import RevenueChart from "./revenueChart.vue";
import moment from "moment";
import { selectSocCount as _selectSocCount, selectHomeEmpCount as _selectHomeEmpCount, selectRevenueAmount as _selectRevenueAmount } from "../api";
export default {
  components: {
    detailsTopItem: detailsTopItem,
    RevenueChart: RevenueChart,
    LogList: LogList
  },
  props: ["siteId"],
  data: function data() {
    return {
      briefingMonth: moment(),
      socTitle: "月参保人数",
      empTitle: "月在职员工数",
      amountTitle: "月营收总金额",
      socCount: 0,
      socDecreaseCount: 0,
      socIncreaseCount: 0,
      empBeforeLeavingCount: 0,
      empBeforeWorkingCount: 0,
      empCurrentLeavingCount: 0,
      empCurrentWorkingCount: 0,
      empWorkingCount: 0,
      amountPaidAmount: 0,
      amountPaidAmountCount: 0,
      amountTotalRevenueAmount: 0,
      amountUnpaidAmount: 0,
      amountUnpaidAmountCount: 0
    };
  },
  watch: {
    briefingMonth: function briefingMonth(val) {
      this.socTitle = moment(val).format("M") + "月参保人数";
      this.empTitle = moment(val).format("M") + "月在职员工数";
      this.amountTitle = moment(val).format("M") + "月营收总金额";
      this.selectSocCount(val);
      this.selectHomeEmpCount(val);
      this.selectRevenueAmount(val);
    },
    siteId: function siteId(newValue) {
      this.socTitle = moment(this.briefingMonth).format("M") + "月参保人数";
      this.empTitle = moment(this.briefingMonth).format("M") + "月在职员工数";
      this.amountTitle = moment(this.briefingMonth).format("M") + "月营收总金额";
      this.selectSocCount(this.briefingMonth);
      this.selectHomeEmpCount(this.briefingMonth);
      this.selectRevenueAmount(this.briefingMonth);
    }
  },
  created: function created() {
    this.socTitle = moment().format("M") + "月参保人数";
    this.empTitle = moment().format("M") + "月在职员工数";
    this.amountTitle = moment().format("M") + "月营收总金额";
    this.selectSocCount(moment());
    this.selectHomeEmpCount(moment());
    this.selectRevenueAmount(moment());
  },
  methods: {
    moment: moment,
    selectSocCount: function selectSocCount(data) {
      var _this = this;
      var da = {
        month: moment(data).format("MM"),
        year: moment(data).format("YYYY")
      };
      if (this.siteId && this.siteId !== "undefined" && this.siteId !== "-1") {
        da.companyIds = this.siteId;
      }
      _selectSocCount(da).then(function (res) {
        _this.socCount = res.data.socCount;
        _this.socDecreaseCount = res.data.decreaseCount;
        _this.socIncreaseCount = res.data.increaseCount;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    selectHomeEmpCount: function selectHomeEmpCount(data) {
      var _this2 = this;
      var da = {
        month: moment(data).format("MM"),
        year: moment(data).format("YYYY")
      };
      if (this.siteId && this.siteId !== "undefined" && this.siteId !== "-1") {
        da.companyIds = this.siteId;
      }
      _selectHomeEmpCount(da).then(function (res) {
        _this2.empBeforeLeavingCount = res.data.beforeLeavingCount ? res.data.beforeLeavingCount : 0;
        _this2.empBeforeWorkingCount = res.data.beforeWorkingCount ? res.data.beforeWorkingCount : 0;
        _this2.empCurrentLeavingCount = res.data.currentLeavingCount ? res.data.currentLeavingCount : 0;
        _this2.empCurrentWorkingCount = res.data.currentWorkingCount ? res.data.currentWorkingCount : 0;
        _this2.empWorkingCount = res.data.workingCount ? res.data.workingCount : 0;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    selectRevenueAmount: function selectRevenueAmount(data) {
      var _this3 = this;
      var da = {
        monthly: moment(data).format("MM"),
        yearly: moment(data).format("YYYY")
      };
      if (this.siteId && this.siteId !== "undefined" && this.siteId !== "-1") {
        da.companyIds = this.siteId;
      }
      _selectRevenueAmount(da).then(function (res) {
        _this3.amountPaidAmount = res.data.paidAmount;
        _this3.amountPaidAmountCount = res.data.paidAmountCount;
        _this3.amountTotalRevenueAmount = "¥ " + res.data.totalRevenueAmount;
        _this3.amountUnpaidAmount = res.data.unpaidAmount;
        _this3.amountUnpaidAmountCount = res.data.unpaidAmountCount;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};