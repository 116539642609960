export default {
  fields: {
    gmtCreate: {
      model: "gmtCreate",
      type: "date",
      wrapper: {
        label: "充值日期"
      },
      customize: {
        allowClear: true,
        placeholder: "请选择充值日期",
        format: "YYYY-MM-DD HH:mm:ss"
      },
      decorator: {
        rules: [{
          required: true,
          message: "请选择充值日期"
        }]
      },
      extend: {}
    },
    id: {
      model: "id",
      type: "input",
      wrapper: {
        label: "id"
      },
      customize: {
        placeholder: "请输入id",
        allowClear: true
      },
      decorator: {
        rules: [{
          required: false,
          message: "请输入id"
        }]
      },
      extend: {}
    },
    quotaPay: {
      model: "quotaPay",
      type: "input",
      wrapper: {
        label: "充值金额"
      },
      customize: {
        placeholder: "请输入充值金额",
        allowClear: true
      },
      decorator: {
        rules: [{
          required: false,
          message: "请输入充值金额"
        }]
      },
      extend: {}
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "quotaPay",
        title: "充值金额"
      }, {
        model: "gmtCreate",
        title: "充值日期"
      }],
      customize: {
        labelCol: {},
        wrapperCol: {}
      },
      extend: {
        batch: false,
        action: false
      }
    }
  }
};