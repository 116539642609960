var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    staticClass: "page-miH page-bgBack",
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "body"
  }, [_c("a-tabs", {
    on: {
      change: _vm.callback
    },
    model: {
      value: _vm.tabsKey,
      callback: function callback($$v) {
        _vm.tabsKey = $$v;
      },
      expression: "tabsKey"
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "本地数据校验"
    }
  }, [_c("div", [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-month-picker", {
    attrs: {
      allowClear: false,
      placeholder: "选择月份"
    },
    on: {
      change: _vm.onMonthChange
    },
    model: {
      value: _vm.dynamicDate,
      callback: function callback($$v) {
        _vm.dynamicDate = $$v;
      },
      expression: "dynamicDate"
    }
  }), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("下载模板")])], 1), _vm.infoList.length > 0 ? _c("div", _vm._l(_vm.infoList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "fix-item-wrapper"
    }, [_c("div", {
      staticClass: "fix-item-one",
      staticStyle: {
        display: "flex",
        "flex-direction": "column"
      }
    }, [_c("div", {
      staticClass: "fix-item-name",
      staticStyle: {
        "margin-bottom": "0"
      }
    }, [_vm._v(" " + _vm._s(item.year + "年" + item.month + "月") + " ")])]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v("参保人数")]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.empCount) + "人")])])]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v("社保缴费")]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.socAmount) + "元")])])]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v("公积金缴费")]), _c("div", {
      staticClass: "childContentName"
    }, [_vm._v(_vm._s(item.pfAmount) + "元")])])]), _c("div", {
      staticClass: "fix-item-two"
    }, [_c("div", [_c("div", {
      staticClass: "childpProjectName"
    }, [_vm._v("校验文件")]), _c("div", {
      staticStyle: {
        display: "flex",
        "padding-right": "12px",
        width: "160px"
      }
    }, [_c("div", {
      staticClass: "childContentName"
    }, [_c("a-upload", {
      ref: "upload",
      refInFor: true,
      attrs: {
        "file-list": _vm.fileList,
        remove: _vm.handleRemove,
        action: "/file/api/v1/general/upload/inline",
        "before-upload": _vm.beforeUpload,
        accept: ".xls, .xlsx"
      }
    }, [_vm.fileList.length === 0 ? _c("a-button", {
      staticStyle: {
        padding: "0"
      },
      attrs: {
        type: "link",
        block: ""
      }
    }, [_c("a-icon", {
      attrs: {
        type: "plus"
      }
    }), _vm._v("上传文件 ")], 1) : _vm._e()], 1)], 1), _c("a-upload", {
      ref: "upload",
      refInFor: true,
      attrs: {
        "file-list": _vm.fileList,
        remove: _vm.handleRemove,
        action: "/file/api/v1/general/upload/inline",
        "before-upload": _vm.beforeUpload,
        accept: ".xls, .xlsx"
      }
    }, [_vm.fileList.length > 0 ? _c("a-button", {
      attrs: {
        type: "link"
      }
    }, [_vm._v("重传")]) : _vm._e()], 1)], 1)])]), _c("div", {
      staticClass: "fix-item-four"
    }, [_c("div", [_c("a-button", {
      attrs: {
        type: "link",
        disabled: _vm.fileList.length === 0 || _vm.errData === true,
        block: ""
      },
      on: {
        click: function click($event) {
          return _vm.parseImport(item);
        }
      }
    }, [_vm._v("数据校验")])], 1), _c("div", [_c("a-button", {
      attrs: {
        disabled: item.checkCount === 0,
        type: "link",
        block: ""
      },
      on: {
        click: function click($event) {
          return _vm.goDetail(item, 1);
        }
      }
    }, [_vm._v("查看结果")])], 1)])]);
  }), 0) : _c("div", {
    staticStyle: {
      "margin-top": "80px"
    }
  }, [_c("a-empty")], 1), _c("a-modal", {
    attrs: {
      width: 600,
      title: "本地数据预览",
      visible: _vm.nextVisible,
      footer: null
    },
    on: {
      cancel: function cancel($event) {
        _vm.nextVisible = false;
      }
    }
  }, [_vm.errData ? _c("a-alert", {
    attrs: {
      message: "上传表数据有误，不可进行数据校验。请检查",
      type: "warning",
      "show-icon": ""
    }
  }) : _vm._e(), _c("a-table", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      pagination: false,
      scroll: {
        x: 600
      },
      columns: _vm.columns,
      "data-source": _vm.dataList
    },
    scopedSlots: _vm._u([{
      key: "parseResult",
      fn: function fn(text, record) {
        return [_vm._v(_vm._s(text === null ? "成功" : "失败：（原因）" + record.parseRemark))];
      }
    }])
  })], 1), _c("a-modal", {
    attrs: {
      title: "数据校验",
      visible: _vm.checkingVisible,
      "ok-button-props": {
        style: {
          display: "none"
        }
      },
      "cancel-text": "取消校验"
    },
    on: {
      cancel: _vm.deleteCheck
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#000000",
      display: "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c("img", {
    staticStyle: {
      width: "width: 57px",
      height: "66px",
      "margin-right": "12px"
    },
    attrs: {
      src: _vm.checkingImg
    }
  }), _vm._v("正在进行数据校验，请稍后... ")])])], 1)])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };