import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.reduce.js";
var additionalArr = [{
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "turnStraightDate",
  fieldName: "预计转正日期",
  fieldType: 3,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isDefault: false,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: null,
  moduleName: null,
  sort: 159,
  templateId: null
}, {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "probationDays",
  fieldName: "试用期",
  fieldType: 5,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isDefault: false,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: true,
  moduleCode: null,
  moduleName: null,
  sort: 158,
  templateId: null
}];
var joinDateDefaultItem = {
  companyId: null,
  dropDownEnumList: null,
  fieldCode: "joinDate",
  fieldName: "入职日期",
  fieldType: 3,
  gmtCreate: null,
  gmtModified: null,
  id: null,
  isDefault: false,
  isEdit: 1,
  isNecessary: true,
  isOpen: true,
  isRequired: false,
  moduleCode: null,
  moduleName: null,
  sort: 152,
  templateId: null
};
import { formatDateDefault, afterTime } from "@/utils/index";
import inTemplateForm from "./inTemplateForm";
import { selectOpenTemplate as selectOpenTemplateReq, selectOpenTemplate3Req } from "../api.js";
import { basicsPages } from "./dynamic";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: {
    inTemplateForm: inTemplateForm
  },
  props: {
    moduleCode: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    confirmedPost: {
      type: Boolean,
      default: false
    },
    outEmpDetail: {
      type: Object,
      default: function _default() {}
    },
    checkInAllValue: {
      type: Object,
      default: function _default() {}
    },
    checkInIdForm: {
      type: String,
      default: ""
    },
    isQuerendaogang: {
      type: Boolean,
      default: false
    },
    isBatchJoin: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      lookShow: false,
      additionalArr: additionalArr,
      joinDateDefaultItem: joinDateDefaultItem,
      remove: false,
      customTemplateFieldVOList: [],
      editListKeys: [],
      templateList: [],
      listDefaultVal: [],
      visibleField: false,
      basicData: {},
      empTrainData: null,
      basicFormJson: {
        editable: true
      },
      form: this.$form.createForm(this, {
        name: "horizontal_login"
      })
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["company"])), {}, {
    empInfoDynamicData: function empInfoDynamicData() {
      return {
        depNameDynamic: this.company.companyDefSource,
        positionOptions: this.company.companyPosSource
      };
    }
  }),
  watch: {
    title: {
      handler: function handler(val) {
        if (val === "编辑") {
          this.remove = true;
        } else if (val === "查看") {
          this.lookShow = true;
        } else if (val === "确认到岗") {
          this.remove = false;
        } else {
          this.remove = false;
        }
        this.selectOpenTemplate();
      },
      immediate: true,
      deep: true
    },
    checkInAllValue: {
      handler: function handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.selectOpenTemplate();
        }
      },
      deep: true
    }
  },
  created: function created() {},
  methods: {
    cancel: function cancel() {
      this.$emit("cancelForm");
    },
    GetDateDiff: function GetDateDiff(time1, time2) {
      time1 = new Date(time1); // 转换为中国标准时间
      time2 = new Date(time2);
      time1 = time1.getTime(); // 转换为时间戳
      time2 = time2.getTime();
      var runTime = (time2 - time1) / 1000; // 开始得出时间差,然后计算
      var year = Math.floor(runTime / 86400 / 365);
      runTime = runTime % (86400 * 365);
      var month = Math.floor(runTime / 86400 / 30);
      runTime = runTime % (86400 * 30);
      var day = Math.floor(runTime / 86400);
      runTime = runTime % 86400;
      var hour = Math.floor(runTime / 3600);
      runTime = runTime % 3600;
      var minute = Math.floor(runTime / 60);
      runTime = runTime % 60;
      var second = runTime;
      return year + "," + month + "," + day;
    },
    onCustomValueChange: function onCustomValueChange(value, key) {
      var _this = this;
      if (key === "formalType" && value) {
        //  员工状态
        if (value === "1") {
          this.customTemplateFieldVOList.map(function (z) {
            if (z.fieldCode === "turnStraightDate" || z.fieldCode === "probationDays") {
              z.isOpen = true;
            }
          });
          this.getListCompanyModuleCodes(this.customTemplateFieldVOList);
          this.$set(this.basicFormJson.fields.probationDays.decorator, "rules", [{
            required: true,
            message: "请选择预计转正日期"
          }]);
          this.$set(this.basicFormJson.fields.turnStraightDate.customize, "disabled", true);
          this.$set(this.basicFormJson.fields.turnStraightDate.decorator, "rules", []);
          this.$set(this.basicFormJson.fields.probationDays.customize, "formatter", function (value) {
            return "".concat(value, "\u5929");
          });
        } else {
          var theFormalType = this.customTemplateFieldVOList.filter(function (item) {
            return item.isOpen && item.fieldCode === "formalType";
          });
          this.customTemplateFieldVOList.filter(function (z) {
            if (z.fieldCode === "probationDays") {
              z.isOpen = false;
            }
            if (z.fieldCode === "turnStraightDate") {
              z.isOpen = theFormalType && theFormalType.length > 0;
            }
          });
          this.getListCompanyModuleCodes(this.customTemplateFieldVOList);
          this.$set(this.basicFormJson.fields.turnStraightDate.customize, "disabled", false);
          this.$set(this.basicFormJson.fields.turnStraightDate.decorator, "rules", [{
            required: true,
            message: "请选择预计转正日期"
          }]);
        }
      } else if (key === "probationDays") {
        var newTime = afterTime(this.$refs.basic.getFieldValue("joinDate"), value);
        this.$nextTick(function () {
          _this.$refs.basic.setFieldsValueShow(_this.moment(newTime));
        });
      } else if (key === "joinDate" && this.$refs.basic.getFieldValue("probationDays")) {
        // 入职日期
        var _newTime = afterTime(value, this.$refs.basic.getFieldValue("probationDays"));
        this.$nextTick(function () {
          _this.$refs.basic.setFieldsValueShow(_this.moment(_newTime));
        });
      } else if (key === "turnStraightDate") {
        // 预计转正日期
        var joinDate = moment(this.$refs.basic.getFieldValue("joinDate")).format("YYYY-MM-DD");
        var num = this.GetDateDiff(joinDate, value).split(",").find(function (z) {
          return z < 0;
        });
        if (num) {
          this.$message.error("预计转正日期要大于入职日期,请重新选择预计转正日期");
          this.$nextTick(function () {
            _this.$refs.basic.setFieldsValueShow1("turnStraightDate", null);
          });
        }
      } else if (key === "joinDate" && this.$refs.basic.getFieldValue("turnStraightDate")) {
        // 预计转正日期
        var turnStraightDate = moment(this.$refs.basic.getFieldValue("turnStraightDate")).format("YYYY-MM-DD");
        var _num = this.GetDateDiff(value, turnStraightDate).split(",").find(function (z) {
          return z < 0;
        });
        if (_num) {
          this.$message.error("预计转正日期要大于入职日期,请重新选择入职日期");
          this.$nextTick(function () {
            _this.$refs.basic.setFieldsValueShow1("joinDate", null);
          });
        }
      }
    },
    onRemove: function onRemove() {
      this.$emit("onRemove");
    },
    dynamicChange: function dynamicChange(value) {
      var _this2 = this;
      console.log(value);
      if (value) {
        var dataKeys = []; // 自定义的
        var dataKeysDefauit = {}; // 系统默认
        if (this.title !== "编辑") {
          Object.keys(value).map(function (z) {
            if (!isNaN(parseInt(z))) {
              var _data = {
                id: z,
                type: _this2.basicFormJson.fields[z].type,
                fieldName: _this2.basicFormJson.fields[z].fieldName,
                content: value[z]
              };
              dataKeys.push(_data);
            } else {
              dataKeysDefauit = Object.assign(_defineProperty({}, z, value[z]), dataKeysDefauit);
            }
          });
        } else {
          Object.keys(value).map(function (z) {
            var item = _this2.checkInAllValue.additionalFieldVOList.find(function (i) {
              return i.fieldId + "" === z;
            });
            if (!isNaN(parseInt(z))) {
              var _data2 = {
                id: item.empAdditionalId,
                fieldId: z,
                type: _this2.basicFormJson.fields[z].type,
                fieldName: _this2.basicFormJson.fields[z].fieldName,
                content: value[z]
              };
              dataKeys.push(_data2);
            } else {
              dataKeysDefauit = Object.assign(_defineProperty({}, z, value[z]), dataKeysDefauit);
            }
          });
        }
        dataKeys.map(function (i) {
          if (i.type === "upload") {
            var elePropType = Object.prototype.toString.call(i.content);
            if (elePropType === "[object String]") {
              if (!i.content.includes("http")) {
                i.content = null;
              }
            } else if (elePropType === "[object Array]") {
              var strJoin = [];
              for (var j = 0; j < i.content.length; j++) {
                if (Object.prototype.toString.call(i.content[j]) === "[object Object]" && i.content[j].url) {
                  strJoin.push(i.content[j].url);
                } else if (Object.prototype.toString.call(i.content[j]) === "[object String]" && i.content[j].includes("http")) {
                  strJoin.push(i.content[j]);
                }
              }
              i.content = strJoin.join(",");
            } else {
              i.content = null;
            }
          }
          if (i.type === "area") {
            i.content = i.content ? i.content + "" : null;
          }
        });
        Object.keys(dataKeysDefauit).map(function (i) {
          if (dataKeysDefauit[i] instanceof Array && dataKeysDefauit[i].length !== 0) {
            if (dataKeysDefauit[i][0].url) {
              dataKeysDefauit[i] = dataKeysDefauit[i].map(function (i) {
                return i.url;
              }) + "";
            }
          }
          return i;
        });
        var outEmpDetail = this.outEmpDetail ? this.outEmpDetail : {};
        var data = Object.assign(outEmpDetail, dataKeysDefauit, {
          addEmpFieldList: dataKeys
        });
        this.$emit("saveValueChange", data);
      }
    },
    unique: function unique(arr) {
      var res = new Map();
      return arr.filter(function (arr) {
        return !res.has(arr.fieldCode) && res.set(arr.fieldCode, 1);
      });
    },
    selectOpenTemplate: function selectOpenTemplate() {
      var _this3 = this;
      var data = {
        companyId: this.moduleCode === "checkIn" ? "site" : this.$store.state.hr.companyId,
        moduleCode: this.moduleCode
      };
      var req = this.isBatchJoin ? selectOpenTemplate3Req : selectOpenTemplateReq;
      req(data).then(function (res) {
        var additionalFieldsListVOS = _this3.isBatchJoin ? [] : res.data.additionalFieldsListVOS.map(function (z) {
          z.fieldCode = z.id;
          return z;
        });
        var data = [].concat(_toConsumableArray(res.data.customTemplateFieldVOList), _toConsumableArray(additionalFieldsListVOS));
        data.map(function (i) {
          if (i.fieldCode === "formalType") {
            _this3.additionalArr[0].sort = i.sort + 0.3;
            _this3.additionalArr[1].sort = i.sort + 0.2;
            _this3.joinDateDefaultItem.sort = i.sort + 0.1;
          }
        });
        var tempFields = data.filter(function (item) {
          return item.isOpen;
        }).map(function (item) {
          return item.fieldCode;
        });
        var needDefaultJoinDate = tempFields.includes("formalType") && !tempFields.includes("joinDate");
        _this3.customTemplateFieldVOList = [].concat(_toConsumableArray(data), _toConsumableArray(needDefaultJoinDate ? [_this3.joinDateDefaultItem] : []), _toConsumableArray(_this3.additionalArr)).sort(function (a, b) {
          return a.sort - b.sort;
        });
        // 加去重
        _this3.customTemplateFieldVOList.map(function (z) {
          if (z.fieldCode === "probationDays") {
            z.isOpen = false;
          }
          if (z.fieldCode === "turnStraightDate") {
            z.isOpen = tempFields.includes("formalType");
          }
        });
        var tempKeys = _this3.customTemplateFieldVOList.filter(function (item) {
          return item.isOpen;
        }).map(function (item) {
          return item.fieldCode;
        });
        _this3.getListCompanyModuleCodes(_this3.customTemplateFieldVOList);
        if (_this3.title === "编辑") {
          var status = _this3.checkInAllValue.status;
          _this3.checkInAllValue.status = status === "4" ? "已入职" : status === "5" ? "已离职" : status === "6" ? "待入职" : status === "7" ? "未到岗" : status;
          _this3.customTemplateFieldVOList.filter(function (i) {
            if (i.fieldCode === "postType") {
              _this3.onCustomValueChange(_this3.checkInAllValue.postType, "postType");
            }
            if (i.fieldCode === "formalType") {
              _this3.onCustomValueChange(_this3.checkInAllValue.formalType, "formalType");
            }
          });
          _this3.getEmpFieldValues(_this3.checkInAllValue);
        } else if (_this3.title === "查看") {
          var _status = _this3.checkInAllValue.status;
          _this3.checkInAllValue.status = _status === "4" ? "已入职" : _status === "5" ? "已离职" : _status === "6" ? "待入职" : _status === "7" ? "未到岗" : _status;
          if (tempKeys.includes("formalType")) {
            _this3.onCustomValueChange(_this3.checkInAllValue.formalType, "formalType");
          }
          if (tempKeys.includes("postType")) {
            _this3.onCustomValueChange(_this3.checkInAllValue.postType, "postType");
          }
          _this3.getEmpFieldValues(_this3.checkInAllValue);
        } else if (_this3.title === "确认到岗" && !_this3.confirmedPost) {
          _this3.customTemplateFieldVOList.map(function (z) {
            if (z.fieldCode === "phone") {
              z.isOpen = false;
            }
            if (z.fieldCode === "idCard") {
              z.isOpen = false;
            }
          });
          _this3.getListCompanyModuleCodes(_this3.customTemplateFieldVOList);
          _this3.$set(_this3.basicFormJson.fields.name.customize, "disabled", true);
          _this3.$nextTick(function () {
            var name = "";
            if (_this3.outEmpDetail.arr.length === 1) {
              name = _this3.outEmpDetail.arr[0].name;
            } else {
              name = "".concat(_this3.outEmpDetail.arr[0].name, "\u7B49").concat(_this3.outEmpDetail.arr.length, "\u4EBA");
            }
            _this3.$refs.basic.setFieldsValueShow1("name", name);
          });
        } else {
          if (_this3.outEmpDetail && JSON.stringify(_this3.outEmpDetail) !== "{}") {
            var additionalFieldVOList = _this3.outEmpDetail.additionalFieldVOList ? _this3.outEmpDetail.additionalFieldVOList : [];
            var dataq = {};
            additionalFieldVOList.map(function (z) {
              var dd = _this3.customTemplateFieldVOList.find(function (y) {
                return y.fieldName === z.fieldName && y.fieldType === z.fieldType;
              });
              if (dd) {
                dataq = Object.assign(dataq, _defineProperty({}, dd.fieldCode, z.content));
              }
            });
            _this3.getEmpFieldValues(Object.assign(_this3.outEmpDetail, dataq));
            if (tempKeys.includes("formalType")) {
              _this3.onCustomValueChange(_this3.outEmpDetail.formalType, "formalType");
            }
            if (tempKeys.includes("postType")) {
              _this3.onCustomValueChange(_this3.outEmpDetail.postType, "postType");
            }
            if (_this3.confirmedPost) {
              _this3.$set(_this3.basicFormJson.fields.name.customize, "disabled", true);
              _this3.$set(_this3.basicFormJson.fields.idCard.customize, "disabled", true);
              _this3.$set(_this3.basicFormJson.fields.phone.customize, "disabled", true);
            }
          } else {
            var _data3 = _this3.basicFormJson.sortedKeys.reduce(function (res, key) {
              res[key] = null;
              return res;
            }, {});
            _data3["formalType"] = "2";
            _data3["joinDate"] = formatDateDefault();
            _data3["probationDays"] = 0;
            _data3["turnStraightDate"] = formatDateDefault();
            _this3.getEmpFieldValues(_data3);
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 获取展示数据
    getListCompanyModuleCodes: function getListCompanyModuleCodes(element) {
      this["basicFormJson"] = {
        editable: false,
        fields: {},
        sortedKeys: {}
      };
      var _basicsPages = basicsPages("basic", _toConsumableArray(element && element.length > 0 ? element : [])),
        fields = _basicsPages.fields,
        fieldKeys = _basicsPages.fieldKeys;
      this["basicFormJson"].fields = fields;
      this["basicFormJson"].sortedKeys = fieldKeys;
      this.list = this["basicFormJson"].sortedKeys;
    },
    // 各项数据源
    getEmpFieldValues: function getEmpFieldValues(moduleCode) {
      for (var key in moduleCode) {
        if (moduleCode[key] === "true") {
          moduleCode[key] = "true";
        }
        if (moduleCode[key] === "false") {
          moduleCode[key] = "false";
        }
      }
      this["basicData"] = moduleCode;
    }
  }
};