import information from "./information";
import password from "./password";
import results from "./results";
export default {
  components: {
    information: information,
    password: password,
    results: results
  },
  data: function data() {
    return {
      dataShow: null,
      current: 0
    };
  },
  methods: {
    goBack: function goBack() {
      var _this = this;
      this.$confirm({
        title: "员工信息尚未上报，是否确认离开？",
        okText: "确定",
        cancelText: "取消",
        onOk: function onOk() {
          _this.$router.go(-1);
        }
      });
    },
    upData: function upData(e) {
      this.current = e;
    },
    upDatadata: function upDatadata(e) {
      this.dataShow = e.data;
    },
    stepsChange: function stepsChange(e) {
      this.current = e;
    }
  },
  destroyed: function destroyed() {
    sessionStorage.setItem("taxCalculate/authorityId", "");
    sessionStorage.setItem("taxCalculate/reportIdArr", "");
  }
};