var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "page-miH page-bgBack",
    staticStyle: {
      position: "relative"
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("新增商保订单")]), _c("div", {
    staticClass: "pageBox"
  }, [_c("div", {
    staticClass: "stepsBox"
  }, [_c("div", {
    staticClass: "steps-head"
  }, [_c("a-steps", {
    attrs: {
      current: _vm.current
    }
  }, _vm._l(_vm.steps, function (item) {
    return _c("a-step", {
      key: item.title,
      attrs: {
        title: item.title
      }
    });
  }), 1)], 1), _c("div", {
    class: _vm.steps[_vm.current].content === "Last" ? "steps-contentLast" : "steps-content"
  }, [_vm.steps[_vm.current].content === "First" ? _c("div", {
    staticStyle: {
      padding: "8px 166px 32px 166px"
    }
  }, [_c("div", {
    staticClass: "contentTitle"
  }, [_vm._v("选择保险公司")]), _c("a-select", {
    staticStyle: {
      width: "300px",
      "margin-top": "12px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择"
    },
    on: {
      change: _vm.insuranceChange
    },
    model: {
      value: _vm.planItem.insCompanyId,
      callback: function callback($$v) {
        _vm.$set(_vm.planItem, "insCompanyId", $$v);
      },
      expression: "planItem.insCompanyId"
    }
  }, _vm._l(_vm.insuranceList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.insCompanyId
      }
    }, [_vm._v(" " + _vm._s(item.companyName) + " ")]);
  }), 1), _c("div", {
    staticClass: "contentTitle"
  }, [_vm._v("选择保险方案")]), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "300px",
      "margin-top": "12px"
    },
    attrs: {
      allowClear: "",
      placeholder: "请选择"
    },
    on: {
      change: _vm.selectPlan
    },
    model: {
      value: _vm.selectPlanItem,
      callback: function callback($$v) {
        _vm.selectPlanItem = $$v;
      },
      expression: "selectPlanItem"
    }
  }, _vm._l(_vm.selectPlanList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.schemeName))]);
  }), 1), _vm.schemeConfirmFile ? _c("div", {
    staticStyle: {
      "margin-top": "12px",
      "font-size": "14px",
      "font-weight": "400",
      color: "#636572",
      "margin-left": "12px"
    }
  }, [_vm._v(" 点击下载"), _c("span", {
    staticClass: "downLoadFile",
    on: {
      click: _vm.downLoadFile
    }
  }, [_vm._v("方案确认函")])]) : _vm._e()], 1), _vm.realityMonthAmount && _vm.realityMonthAmount.length ? _c("div", {
    staticStyle: {
      padding: "16px 12px 16px 24px",
      width: "838px",
      "min-height": "66px",
      background: "#ffffff",
      "box-shadow": "0px 4px 12px 0px rgba(106, 102, 246, 0.1)",
      "border-radius": "4px",
      "margin-top": "16px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#999999",
      "margin-top": "12px"
    }
  }, [_vm._v("方案明细：")]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-start",
      width: "100%",
      "flex-wrap": "wrap"
    }
  }, _vm._l(_vm.realityMonthAmount, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        width: "254px",
        height: "41px",
        background: "#f6f6f6",
        "border-radius": "4px",
        margin: "12px 12px 0 0",
        display: "flex",
        "justify-content": "space-between",
        "align-items": "center",
        padding: "0 16px"
      }
    }, [_c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.typeName) + " ")]), _c("div", {
      staticStyle: {
        "font-size": "14px",
        "font-weight": "500",
        color: "#55565d",
        width: "98px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.typeName) + " ")])], 2), _c("div", {
      staticStyle: {
        display: "flex"
      }
    }, [_c("div", {
      staticClass: "label"
    }, [_vm._v("保险月费：")]), _c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_c("div", [_vm._v(_vm._s(item.monthAmount))])]), _c("div", {
      staticClass: "value",
      staticStyle: {
        "font-size": "14px",
        "font-weight": "500",
        color: "#55565d",
        width: "40px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.monthAmount) + " ")])], 2)], 1)], 1);
  }), 0)]) : _vm._e(), _c("div", {
    staticClass: "contentTitle"
  }, [_vm._v("选择保险周期")]), _c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#999999",
      "margin-top": "12px"
    }
  }, [_vm._v("保险周期涉及到的月份均收取整月保费")]), _c("a-range-picker", {
    staticStyle: {
      width: "300px",
      "margin-top": "12px"
    },
    attrs: {
      valueFormat: "YYYY-MM-DD"
    },
    on: {
      change: _vm.onDateChange
    },
    model: {
      value: _vm.rangeDate,
      callback: function callback($$v) {
        _vm.rangeDate = $$v;
      },
      expression: "rangeDate"
    }
  })], 1) : _vm._e(), _vm.steps[_vm.current].content === "Second" ? _c("div", {
    staticStyle: {
      padding: "35px",
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "bgImg"
  }, [_c("div", [_c("img", {
    staticStyle: {
      width: "260px",
      height: "140px"
    },
    attrs: {
      src: require("@/assets/img/shangbaoBG.png"),
      alt: ""
    }
  })]), _c("div", {
    staticStyle: {
      "font-size": "24px",
      "font-weight": "bold",
      color: "#3c3d43",
      "margin-top": "20px"
    }
  }, [_vm._v("上传投保人员")]), _c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#666666",
      "margin-top": "20px"
    }
  }, [_vm._v(" 下载《投保人员明细表模板》填写缴纳该保险方案的人 ")]), _c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#666666"
    }
  }, [_vm._v("员信息并上传，还可以根据需要上传其他附件。")])]), _c("div", {
    staticStyle: {
      width: "50%",
      "padding-left": "70px",
      "padding-top": "22px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("img", {
    staticStyle: {
      width: "24px",
      height: "24px"
    },
    attrs: {
      src: require("@/assets/img/icon.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "nextTitle"
  }, [_vm._v("下载《投保人员明细表模板》填写缴纳该保险方案的人员信息")])]), _c("a-button", {
    staticStyle: {
      "margin-left": "30px",
      "margin-top": "12px"
    },
    on: {
      click: _vm.downLoadDetailFile
    }
  }, [_c("a-icon", {
    attrs: {
      type: "download"
    }
  }), _vm._v("投保人员明细表模板 ")], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "24px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "24px",
      height: "24px"
    },
    attrs: {
      src: require("@/assets/img/icon.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "nextTitle"
  }, [_vm._v("上传《投保人员明细表》")])]), _c("a-upload", {
    ref: "upload",
    staticStyle: {
      "margin-left": "30px",
      "margin-top": "12px"
    },
    attrs: {
      "file-list": _vm.fileList,
      headers: _vm.headers,
      action: "/file/api/v1/general/upload/attachment",
      name: "files",
      accept: ".xls, .xlsx, .xlsm",
      showUploadList: {
        showDownloadIcon: true
      }
    },
    on: {
      change: _vm.handleChange,
      download: _vm.download
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary"
    }
  }, [_vm._v(_vm._s(!_vm.fileList.length ? "上传文件" : "重新上传"))])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "margin-top": "24px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "24px",
      height: "24px"
    },
    attrs: {
      src: require("@/assets/img/icon.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "nextTitle"
  }, [_vm._v("上传其他附件")]), _c("a-alert", {
    staticStyle: {
      width: "300px",
      "margin-left": "16px"
    },
    attrs: {
      message: "首次投保必须上传被保险人营业执照",
      banner: ""
    }
  })], 1), _c("a-upload", {
    ref: "upload",
    attrs: {
      "file-list": _vm.fileListMultiple,
      headers: _vm.headers,
      action: "/file/api/v1/general/upload/attachment",
      name: "files",
      showUploadList: {
        showDownloadIcon: true
      }
    },
    on: {
      change: _vm.handleChangeMultiple,
      download: _vm.download
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-left": "30px",
      "margin-top": "12px"
    },
    attrs: {
      type: "primary"
    }
  }, [_vm._v("添加文件")])], 1)], 1)]) : _vm._e(), _vm.steps[_vm.current].content === "Last" ? _c("div", [_c("a-alert", {
    attrs: {
      message: "请仔细核对下方列表中的数据，无误后点击“确认提交”按钮。",
      banner: "",
      closable: ""
    }
  }), _c("div", {
    staticClass: "combined"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      height: "20px"
    }
  }, [_c("div", {
    staticClass: "combinedTitle"
  }, [_vm._v("保险周期：")]), _c("div", {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "500",
      color: "#3c3d43"
    }
  }, [_vm._v(_vm._s(_vm.startDate + "~" + _vm.endDate))])]), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "flex-wrap": "wrap"
    }
  }, _vm._l(_vm.realityMonthAmount, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        width: "254px",
        height: "41px",
        background: "#f6f6f6",
        "border-radius": "4px",
        margin: "12px 12px 0 0",
        display: "flex",
        "justify-content": "space-between",
        "align-items": "center",
        padding: "0 16px"
      }
    }, [_c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.typeName) + " ")]), _c("div", {
      staticStyle: {
        "font-size": "14px",
        "font-weight": "500",
        color: "#55565d",
        width: "98px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.typeName) + " ")])], 2), _c("div", {
      staticStyle: {
        display: "flex"
      }
    }, [_c("div", {
      staticClass: "label"
    }, [_vm._v("保险月费：")]), _c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_c("div", [_vm._v(_vm._s(item.monthAmount))])]), _c("div", {
      staticClass: "value",
      staticStyle: {
        "font-size": "14px",
        "font-weight": "500",
        color: "#55565d",
        width: "40px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.monthAmount) + " ")])], 2)], 1)], 1);
  }), 0)]), _c("iframe", {
    staticStyle: {
      width: "100%",
      height: "1300px",
      "margin-top": "24px"
    },
    attrs: {
      src: "https://view.officeapps.live.com/op/view.aspx?src=" + "".concat(_vm.detailTemplateFile)
    }
  })], 1) : _vm._e()])])]), _c("a-modal", {
    attrs: {
      visible: _vm.successVisible,
      footer: null,
      title: "新增商保订单"
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c("img", {
    staticClass: "w200",
    attrs: {
      src: require("@/assets/img/1.png"),
      alt: ""
    }
  }), _c("div", {
    staticStyle: {
      "font-size": "16px",
      "font-weight": "500",
      color: "#3c3d43"
    }
  }, [_vm._v("申请投保成功")]), _c("a-button", {
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("确定")])], 1)]), _c("div", {
    staticClass: "steps-action"
  }, [_vm.current > 0 ? _c("a-button", {
    on: {
      click: _vm.prev
    }
  }, [_vm._v("上一步")]) : _vm._e(), _vm.current == _vm.steps.length - 1 ? _c("a-button", {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v("确认提交")]) : _vm._e(), _vm.current < _vm.steps.length - 1 ? _c("a-button", {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.next
    }
  }, [_vm._v("下一步")]) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };