import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      footer: false,
      title: "查看反馈",
      visible: _vm.visible
    },
    on: {
      ok: _vm.onOk,
      cancel: _vm.cancel
    }
  }, [_c("a-row", {
    staticClass: "title"
  }, [_c("strong", [_vm._v(_vm._s(_vm.options.name))])]), _c("a-row", {
    staticClass: "title"
  }, [_c("a-col", {
    attrs: {
      span: 4
    }
  }, [_c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("身份证号")])]), _c("a-col", {
    attrs: {
      span: 20
    }
  }, [_c("span", {
    staticClass: "sub_tit"
  }, [_vm._v(_vm._s(_vm.options.idCard))])])], 1), _c("a-row", {
    staticClass: "title"
  }, [_c("a-col", {
    attrs: {
      span: 4
    }
  }, [_c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("状态")])]), _c("a-col", {
    attrs: {
      span: 20
    }
  }, [_vm._v(_vm._s(["未完成", "已完成", "异常"][_vm.options.status]))])], 1), _c("a-row", {
    staticClass: "title mg_t12 content"
  }, [_c("a-textarea", {
    attrs: {
      disabled: _vm.footer,
      placeholder: "反馈内容"
    },
    model: {
      value: _vm.options.remake,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "remake", $$v);
      },
      expression: "options.remake"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };