import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "pageStyle"
  }, [_c("div", {
    staticClass: "page-miH",
    staticStyle: {
      "min-width": "250px",
      width: "250px",
      "border-right": "1px solid #e8e8e8"
    }
  }, [_c("div", {
    staticClass: "leftBox"
  }, [_c("div", {
    staticClass: "searchBox"
  }, [_c("a-input-search", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      placeholder: "搜索保险公司"
    },
    on: {
      search: _vm.getSiteList
    },
    model: {
      value: _vm.siteName,
      callback: function callback($$v) {
        _vm.siteName = $$v;
      },
      expression: "siteName"
    }
  })], 1), _vm.siteList && _vm.siteList.length ? _c("div", {
    staticClass: "siteBox"
  }, _vm._l(_vm.siteList, function (item, index) {
    return _c("div", {
      key: index,
      class: item.isSelected ? "sitemItemOn" : "sitemItem",
      on: {
        click: function click($event) {
          return _vm.siteChange(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0) : _vm._e()])]), _c("div", {
    staticClass: "rightBox"
  }, [_c("div", {
    staticClass: "detailHead"
  }, [_c("div", {
    staticClass: "lineForInfo"
  }, [_vm._v(" 基本信息 "), _c("div", {
    staticClass: "line1"
  }), !_vm.isShow ? _c("a-button", {
    attrs: {
      disabled: !_vm.siteList.length,
      type: "link"
    },
    on: {
      click: function click($event) {
        _vm.isShow = true;
      }
    }
  }, [_vm._v("编辑")]) : _vm._e()], 1), _c("div", [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      padding: "0 100px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", [_vm._v("联系人")]), _c("a-input", {
    staticStyle: {
      width: "310px",
      "margin-left": "12px"
    },
    attrs: {
      disabled: !_vm.isShow,
      placeholder: "请输入"
    },
    model: {
      value: _vm.companyDetail.linkmanName,
      callback: function callback($$v) {
        _vm.$set(_vm.companyDetail, "linkmanName", $$v);
      },
      expression: "companyDetail.linkmanName"
    }
  })], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", [_vm._v("联系电话")]), _c("a-input", {
    staticStyle: {
      width: "310px",
      "margin-left": "12px"
    },
    attrs: {
      disabled: !_vm.isShow,
      placeholder: "请输入"
    },
    model: {
      value: _vm.companyDetail.linkmanPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.companyDetail, "linkmanPhone", $$v);
      },
      expression: "companyDetail.linkmanPhone"
    }
  })], 1)]), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      padding: "24px 0 24px 38px"
    }
  }, [_c("div", [_c("span", {
    staticStyle: {
      color: "#ff7085"
    }
  }, [_vm._v("*")]), _vm._v("上传明细表模板")]), _c("a-upload", {
    ref: "upload",
    staticStyle: {
      "margin-left": "12px",
      display: "flex",
      width: "380px"
    },
    attrs: {
      disabled: !_vm.isShow,
      "file-list": _vm.fileList,
      headers: _vm.headers,
      action: "/file/api/v1/general/upload/attachment",
      accept: ".xls, .xlsx",
      showUploadList: {
        showDownloadIcon: true
      },
      name: "files"
    },
    on: {
      change: _vm.handleChange,
      download: _vm.download
    }
  }, [_c("a-button", {
    attrs: {
      disabled: !_vm.isShow
    }
  }, [_vm._v(_vm._s(!_vm.fileList.length ? "上传文件" : "重新上传"))])], 1)], 1), _vm.isShow ? _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      height: "100px"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.updateCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateDetail
    }
  }, [_vm._v("保存")])], 1) : _vm._e()])]), _c("div", {
    staticClass: "detailBody"
  }, [_c("div", {
    staticClass: "lineForInfo"
  }, [_vm._v(" 保险方案 "), _c("div", {
    staticClass: "line2"
  })]), _c("div", {
    staticClass: "functionBtn"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "搜索保险方案"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.searchPlanName,
      callback: function callback($$v) {
        _vm.searchPlanName = $$v;
      },
      expression: "searchPlanName"
    }
  }), _c("a-button", {
    attrs: {
      disabled: !_vm.siteList.length,
      type: "primary"
    },
    on: {
      click: _vm.addPlan
    }
  }, [_vm._v("新增保险方案")])], 1), _vm.planList && _vm.planList.length ? _c("div", {
    staticClass: "planBox"
  }, _vm._l(_vm.planList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "planItem"
    }, [_c("a-dropdown", [_c("a-menu", {
      attrs: {
        slot: "overlay"
      },
      on: {
        click: function click(e) {
          return _vm.handleMenuClick(e, item);
        }
      },
      slot: "overlay"
    }, [_c("a-menu-item", {
      key: "1"
    }, [_vm._v("编辑")]), _c("a-menu-item", {
      key: "2"
    }, [_vm._v("删除")])], 1), _c("a-icon", {
      staticClass: "funcBtn",
      attrs: {
        type: "ellipsis"
      }
    })], 1), _c("div", {
      staticClass: "contentBox"
    }, [_c("div", [_c("img", {
      staticStyle: {
        width: "60px",
        height: "64px"
      },
      attrs: {
        src: require("@/assets/admin-icon/planItem.png"),
        alt: ""
      }
    })]), _c("div", {
      staticStyle: {
        "margin-left": "12px",
        "margin-top": "6px"
      }
    }, [_c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_vm._v(_vm._s(item.schemeName))]), _c("div", {
      staticClass: "planTitle",
      staticStyle: {
        width: "180px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.schemeName) + " ")])], 2), _c("div", {
      staticClass: "planContent",
      staticStyle: {
        display: "flex",
        "align-items": "center"
      }
    }, [_c("div", {
      staticClass: "planKey"
    }, [_vm._v("方案明细:")]), _c("a-popover", {
      attrs: {
        placement: "bottomLeft",
        title: "方案明细"
      }
    }, [_c("template", {
      slot: "content"
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        width: "540px",
        "flex-wrap": "wrap"
      }
    }, _vm._l(item.personTypeList, function (item, index) {
      return _c("div", {
        key: index,
        staticStyle: {
          display: "flex",
          "justify-content": "space-between",
          "align-items": "center",
          padding: "0 16px",
          margin: "12px 12px 0 0",
          width: "254px",
          height: "41px",
          background: "#f6f6f6",
          "border-radius": "4px"
        }
      }, [_c("a-tooltip", [_c("template", {
        slot: "title"
      }, [_vm._v(" " + _vm._s(item.typeName) + " ")]), _c("div", {
        staticStyle: {
          "font-size": "14px",
          "font-weight": "500",
          color: "#55565d",
          width: "98px",
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden"
        }
      }, [_vm._v(" " + _vm._s(item.typeName) + " ")])], 2), _c("div", {
        staticStyle: {
          display: "flex"
        }
      }, [_c("div", {
        staticClass: "label"
      }, [_vm._v("实际保险月费:")]), _c("a-tooltip", [_c("template", {
        slot: "title"
      }, [_c("div", [_vm._v(_vm._s(item.realityMonthAmount))])]), _c("div", {
        staticClass: "value",
        staticStyle: {
          "font-size": "14px",
          "font-weight": "500",
          color: "#55565d",
          width: "28px",
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden"
        }
      }, [_vm._v(" " + _vm._s(item.realityMonthAmount) + " ")])], 2)], 1)], 1);
    }), 0)]), _c("a-button", {
      attrs: {
        type: "link"
      }
    }, [_vm._v("查看")])], 2)], 1)], 1)])], 1);
  }), 0) : _c("div", {
    staticStyle: {
      padding: "120px 40%"
    }
  }, [_c("a-empty")], 1), _c("a-pagination", {
    staticStyle: {
      position: "absolute",
      right: "24px",
      "margin-top": "24px"
    },
    attrs: {
      pageSize: 9,
      total: _vm.total
    },
    on: {
      change: _vm.onPageChange
    },
    model: {
      value: _vm.current,
      callback: function callback($$v) {
        _vm.current = $$v;
      },
      expression: "current"
    }
  })], 1)]), _c("a-modal", {
    attrs: {
      title: _vm.title,
      width: 660,
      okButtonProps: {
        props: {
          disabled: _vm.loading_submit
        }
      }
    },
    on: {
      ok: _vm.handelOk,
      cancel: function cancel($event) {
        _vm.visible = false;
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "modalContent"
  }, [_c("div", {
    staticClass: "itemContent",
    staticStyle: {
      "margin-left": "100px"
    }
  }, [_c("div", {
    staticClass: "itemTitle"
  }, [_c("span", {
    staticStyle: {
      color: "#fa5051"
    }
  }, [_vm._v("*")]), _vm._v("保险方案")]), _c("a-input", {
    staticStyle: {
      width: "300px",
      "margin-left": "24px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.planItem.schemeName,
      callback: function callback($$v) {
        _vm.$set(_vm.planItem, "schemeName", $$v);
      },
      expression: "planItem.schemeName"
    }
  })], 1), _c("a-alert", {
    staticStyle: {
      "margin-left": "50px",
      "margin-top": "24px",
      width: "526px"
    },
    attrs: {
      message: "保险公司给京普的价格；可放心填写，填写仅用于京普记录",
      banner: ""
    }
  }), _c("div", {
    staticStyle: {
      "margin-left": "50px",
      "margin-top": "12px"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "0 36px",
      width: "526px",
      height: "48px",
      background: "#fafafa",
      "box-shadow": "inset 0px -1px 0px 0px #e8e8e8",
      display: "flex",
      "justify-content": "flex-start",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "500",
      color: "#252525"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#fa5051"
    }
  }, [_vm._v("*")]), _vm._v("职业分类")]), _c("div", {
    staticStyle: {
      "margin-left": "160px",
      "font-size": "14px",
      "font-weight": "500",
      color: "#252525"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#fa5051"
    }
  }, [_vm._v("*")]), _vm._v("实际保险月费 ")])]), _vm._l(_vm.typeList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "flex-start",
        width: "526px",
        height: "48px",
        background: "#ffffff",
        "box-shadow": "inset 0px -1px 0px 0px #e8e8e8"
      }
    }, [_c("a-input", {
      staticStyle: {
        width: "180px",
        "margin-left": "36px"
      },
      attrs: {
        placeholder: "请输入"
      },
      model: {
        value: item.typeName,
        callback: function callback($$v) {
          _vm.$set(item, "typeName", $$v);
        },
        expression: "item.typeName"
      }
    }), _c("a-input-number", {
      staticStyle: {
        width: "180px",
        "margin-left": "36px"
      },
      attrs: {
        placeholder: "请输入"
      },
      model: {
        value: item.realityMonthAmount,
        callback: function callback($$v) {
          _vm.$set(item, "realityMonthAmount", $$v);
        },
        expression: "item.realityMonthAmount"
      }
    }), index > 0 ? _c("a-icon", {
      staticStyle: {
        "margin-left": "28px",
        cursor: "pointer"
      },
      attrs: {
        type: "delete",
        theme: "twoTone",
        "two-tone-color": "#FA5051"
      },
      on: {
        click: function click($event) {
          return _vm.onDelete(item.key);
        }
      }
    }) : _vm._e(), index === _vm.typeList.length - 1 ? _c("a-icon", {
      staticStyle: {
        "margin-left": "12px",
        cursor: "pointer"
      },
      attrs: {
        type: "plus-circle",
        theme: "twoTone",
        "two-tone-color": "#6A66F6"
      },
      on: {
        click: _vm.handleAdd
      }
    }) : _vm._e()], 1);
  })], 2)], 1)])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };