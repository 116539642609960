import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      width: 650,
      title: "选择岗位",
      visible: true
    },
    on: {
      cancel: _vm.cancel,
      ok: _vm.handleOk
    }
  }, [_c("a-layout-content", [_c("a-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "290px"
    },
    attrs: {
      placeholder: "搜索岗位"
    },
    on: {
      search: _vm.search
    }
  }), _c("a-row", {
    staticClass: "tree-body"
  }, [_c("custom-tree", {
    ref: "tree",
    attrs: {
      data: _vm.positionOptions
    },
    on: {
      "update:data": function updateData($event) {
        _vm.positionOptions = $event;
      },
      addPosition: _vm.addPosition,
      minus: _vm.minusArray
    }
  })], 1)], 1), _c("a-col", {
    staticClass: "mg_l20",
    attrs: {
      span: 10
    }
  }, [_c("a-row", {
    staticClass: "sub_tit mg_b5"
  }, [_vm._v("已经选择的岗位")]), _c("div", {
    staticClass: "selectedbox"
  }, _vm._l(_vm.List, function (item, i) {
    return _c("a-row", {
      key: i,
      staticClass: "sub_tit mg_b5 staffrow"
    }, [_c("p", [_c("a-icon", {
      staticStyle: {
        "margin-right": "6px"
      },
      attrs: {
        type: "user"
      }
    }), _vm._v(_vm._s(item.name))], 1), _c("a-icon", {
      attrs: {
        type: "close"
      },
      on: {
        click: function click($event) {
          return _vm.minus(i);
        }
      }
    })], 1);
  }), 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };