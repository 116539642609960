var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "xq-form-wrapper p24"
  }, [_c("div", {
    staticClass: "flex-center-between mb24"
  }, [_c("a-space", {
    attrs: {
      size: 24
    }
  }, [!_vm.companyId ? _c("a-input-search", {
    attrs: {
      placeholder: "用工单位名称"
    },
    on: {
      change: _vm.onInputChange
    },
    model: {
      value: _vm.companyName,
      callback: function callback($$v) {
        _vm.companyName = $$v;
      },
      expression: "companyName"
    }
  }) : _vm._e(), _c("a-input-search", {
    attrs: {
      placeholder: "服务反馈标题"
    },
    on: {
      change: _vm.onInputChange
    },
    model: {
      value: _vm.eventTitle,
      callback: function callback($$v) {
        _vm.eventTitle = $$v;
      },
      expression: "eventTitle"
    }
  }), _c("a-range-picker", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      value: _vm.dateRange,
      placeholder: ["开始月份", "结束月份"],
      mode: ["month", "month"],
      format: "YYYY-MM"
    },
    on: {
      panelChange: _vm.onPanelChange,
      change: _vm.onRangeChange
    }
  })], 1), _c("a-space", [_vm.companyId ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goNoticeSetting
    }
  }, [_c("a-icon", {
    attrs: {
      type: "bell"
    }
  }), _vm._v(" 消息提醒设置")], 1) : _vm._e(), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.showCreateModal
    }
  }, [_vm._v("添加服务反馈")])], 1)], 1), _c("div", {
    staticClass: "xq-form-main"
  }, [_c("a-spin", {
    staticClass: "xq-form-content",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: _vm.pagination,
      customize: _vm.FormJson["config"]["table"]["customize"],
      dataSource: _vm.tableData,
      columns: _vm.columns,
      rowSelection: _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      } : null
    },
    on: {
      change: _vm.onTableChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "eventLabel" ? _c("div", {
            key: index
          }, [text ? _vm._l(text.split(","), function (li) {
            return _c("div", {
              key: li,
              staticClass: "tag"
            }, [_vm._v(_vm._s(li))]);
          }) : _vm._e()], 2) : col.dataIndex === "eventAnnex" ? _c("div", {
            key: index
          }, [text ? _vm._l(text.split(","), function (li) {
            return _c("a", {
              key: li,
              staticStyle: {
                display: "block"
              },
              attrs: {
                href: li,
                target: "_blank"
              }
            }, [_vm._v(_vm._s(_vm.getFileName(li, true)))]);
          }) : _vm._e()], 2) : col.dataIndex === "eventContent" ? _c("a-tooltip", {
            key: index,
            attrs: {
              title: text,
              destroyTooltipOnHide: ""
            }
          }, [_c("div", {
            staticClass: "ellipsis",
            staticStyle: {
              width: "140px"
            }
          }, [_vm._v(_vm._s(text))])]) : col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("a", {
            on: {
              click: function click($event) {
                return _vm.showDetailModal(record);
              }
            }
          }, [_vm._v("详情")]), _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.showUpdateModal(record);
              }
            }
          }, [_vm._v("编辑")]), _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a-popconfirm", {
            attrs: {
              title: "\u786E\u8BA4\u8981\u5220\u9664\u8BB0\u5F55\u5417\uFF1F",
              okText: "确认",
              cancelText: "取消"
            },
            on: {
              confirm: function confirm($event) {
                return _vm.deleteRecord(record);
              }
            }
          }, [_c("a", [_vm._v("删除")])])], 1) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      pageSize: _vm.pageSize,
      total: _vm.total,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ["20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      pagination: false,
      maskClosable: false,
      destroyOnClose: true,
      title: "新增服务反馈",
      width: 500
    },
    model: {
      value: _vm.createModalVisible,
      callback: function callback($$v) {
        _vm.createModalVisible = $$v;
      },
      expression: "createModalVisible"
    }
  }, [_c("XqFormBuild", {
    ref: "createForm",
    attrs: {
      dataSource: _vm.dsForCreate,
      dynamicData: _vm.dynamicData,
      formFields: _vm.FormJson["fields"],
      formValidators: _vm.FormJson["validators"],
      formConfig: _vm.FormJson["config"]["create"]
    }
  }), _c("XqModalFooter", {
    attrs: {
      slot: "footer"
    },
    on: {
      cancel: _vm.createCancel,
      ok: _vm.createOk
    },
    slot: "footer"
  })], 1), _c("a-modal", {
    attrs: {
      maskClosable: false,
      destroyOnClose: true,
      title: "更新服务反馈",
      width: 500
    },
    model: {
      value: _vm.updateModalVisible,
      callback: function callback($$v) {
        _vm.updateModalVisible = $$v;
      },
      expression: "updateModalVisible"
    }
  }, [_c("XqFormBuild", {
    ref: "updateForm",
    attrs: {
      dataSource: _vm.tempRecord,
      dynamicData: _vm.dynamicData,
      formFields: _vm.FormJson["fields"],
      formValidators: _vm.FormJson["validators"],
      formConfig: _vm.FormJson["config"]["update"]
    }
  }), _c("XqModalFooter", {
    attrs: {
      slot: "footer"
    },
    on: {
      cancel: _vm.updateCancel,
      ok: _vm.updateOk
    },
    slot: "footer"
  })], 1), _c("a-modal", {
    staticClass: "custom-detail-modal",
    attrs: {
      maskClosable: false,
      destroyOnClose: true,
      title: "服务反馈详情",
      width: 500
    },
    on: {
      cancel: function cancel($event) {
        _vm.detailModalVisible = false;
      }
    },
    model: {
      value: _vm.detailModalVisible,
      callback: function callback($$v) {
        _vm.detailModalVisible = $$v;
      },
      expression: "detailModalVisible"
    }
  }, [_c("XqFormBuild", {
    ref: "detailForm",
    attrs: {
      editable: false,
      dataSource: _vm.tempRecord,
      dynamicData: _vm.dynamicData,
      formFields: _vm.FormJson["fields"],
      formValidators: _vm.FormJson["validators"],
      formConfig: _vm.FormJson["config"]["detail"]
    }
  }), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };