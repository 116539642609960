var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "customFormsHOW"
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 12
      }
    }
  }, [_vm.isReward && _vm.formFields ? _c("a-row", [_vm.sortedKeys ? _vm._l(_vm.sortedKeys, function (key) {
    return _c("div", {
      key: key
    }, [_c("a-col", {
      attrs: {
        span: 24
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: _vm.formFields[key],
        dynamicData: _vm.dynamicData,
        editable: _vm.editable
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, _vm.formFields[key], _vm.dataSource, _vm.dynamicData);
        }
      }
    })], 1)], 1);
  }) : _vm._l(_vm.formFields, function (item) {
    return _c("div", {
      key: item.model
    }, [_c("a-col", {
      attrs: {
        span: 24
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: item,
        dynamicData: _vm.dynamicData,
        editable: _vm.editable
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, item, _vm.dataSource, _vm.dynamicData);
        }
      }
    })], 1)], 1);
  })], 2) : _vm._e()], 1), _c("div", {
    staticStyle: {
      height: "20px"
    }
  }), !_vm.lookShow ? _c("div", {
    staticClass: "DynamicForm"
  }, [_c("a-space", [_vm.remove ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: _vm.onRemove
    }
  }, [_vm._v("删 除")]) : _vm._e(), _c("a-button", {
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取 消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("提 交")])], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };