import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import FormJson from "./FormJson"; // 引入FormJson数据
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
import { retrieveReq, createReq, deleteReq, deleteBatchReq, updateReq } from "./api"; // 引入接口文件

var curdMixin = new XqCurdMixin({
  createReq: createReq,
  retrieveReq: retrieveReq,
  updateReq: updateReq,
  deleteReq: deleteReq,
  deleteBatchReq: deleteBatchReq,
  FormJson: FormJson,
  tableConfigKey: "table"
});
export default {
  name: "",
  components: {},
  mixins: [curdMixin],
  props: {
    productTypeId: {
      type: String,
      default: "0"
    },
    productTypeIdList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      productName: "",
      pageSize: 20,
      dsForCreate: this.productTypeId === "0" ? {} : {
        productTypeId: this.productTypeId
      }
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {
          productTypeId: this.productTypeId
        }
      };
    },
    createParams: function createParams() {
      return {};
    },
    updateParams: function updateParams() {
      return {};
    }
  },
  watch: {
    productTypeId: function productTypeId(newVal) {
      this.retrieveRecord();
      if (newVal === "0") {
        this.dsForCreate = {};
      } else {
        this.dsForCreate = {
          productTypeId: newVal
        };
      }
    },
    productTypeIdList: {
      handler: function handler(newVal) {
        this.dynamicData = _objectSpread(_objectSpread({}, this.dynamicData), {}, {
          productTypeIdList: newVal.map(function (item) {
            return {
              value: item.id,
              label: item.productTypeName
            };
          })
        });
        console.log(this.dynamicData);
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onInputChange: function onInputChange() {
      var params = {};
      if (this.productName) {
        params["productName"] = this.productName;
      }
      this.retrieveRecord(params);
    },
    buildDynamicDataCustom: function buildDynamicDataCustom(tempDynamicData) {
      this.dynamicData = _objectSpread(_objectSpread({}, tempDynamicData), {}, {
        productTypeIdList: this.productTypeIdList.map(function (item) {
          return {
            value: item.id,
            label: item.productTypeName
          };
        })
      });
    },
    formatValues: function formatValues(values) {
      values["productCover"] = this.formatUploadValue(values["productCover"]);
      return values;
    },
    formatDataSource: function formatDataSource(record) {
      return record;
    }
  }
};