import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 今日新增 ")]), _c("a-layout", {
    staticClass: "p24"
  }, [_c("div", {
    staticClass: "t-row"
  }, [_c("div", {
    staticClass: "t-col",
    on: {
      click: function click($event) {
        return _vm.onScreening(0);
      }
    }
  }, [_vm._v(" 今日新增："), _c("span", [_vm._v(_vm._s(_vm.allCount) + "人")])]), _c("div", {
    staticClass: "t-col",
    on: {
      click: function click($event) {
        return _vm.onScreening(1);
      }
    }
  }, [_vm._v(" 网页端录入："), _c("span", [_vm._v(_vm._s(_vm.pcCount) + "人")])]), _c("div", {
    staticClass: "t-col",
    on: {
      click: function click($event) {
        return _vm.onScreening(2);
      }
    }
  }, [_vm._v(" 手机端录入："), _c("span", [_vm._v(_vm._s(_vm.appCount) + "人")])]), _c("div", {
    staticClass: "t-col",
    on: {
      click: function click($event) {
        return _vm.onScreening(3);
      }
    }
  }, [_vm._v(" 更换用工单位："), _c("span", [_vm._v(_vm._s(_vm.changeCount) + "人")])])]), _c("div", {
    staticStyle: {
      "margin-top": "24px",
      "margin-bottom": "24px",
      display: "flex",
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c("div", [_c("a-input-search", {
    staticClass: "mr12",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "姓名/手机号/身份证号"
    },
    on: {
      search: function search($event) {
        return _vm.getData(true);
      }
    },
    model: {
      value: _vm.nameOrPhoneOrCard,
      callback: function callback($$v) {
        _vm.nameOrPhoneOrCard = $$v;
      },
      expression: "nameOrPhoneOrCard"
    }
  }), _c("a-input-search", {
    staticStyle: {
      width: "180px",
      "margin-right": "12px"
    },
    attrs: {
      "allow-clear": "",
      placeholder: "录入人"
    },
    on: {
      search: function search($event) {
        return _vm.getData(true);
      }
    },
    model: {
      value: _vm.inputName,
      callback: function callback($$v) {
        _vm.inputName = $$v;
      },
      expression: "inputName"
    }
  }), _c("a-select", {
    staticClass: "mr12",
    staticStyle: {
      width: "136px"
    },
    attrs: {
      placeholder: "员工排序",
      "allow-clear": ""
    },
    on: {
      change: function change($event) {
        return _vm.getData(true);
      }
    },
    model: {
      value: _vm.sortType,
      callback: function callback($$v) {
        _vm.sortType = $$v;
      },
      expression: "sortType"
    }
  }, _vm._l(_vm.staffSort, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c("a-date-picker", {
    staticStyle: {
      width: "136px"
    },
    attrs: {
      valueFormat: "YYYY-MM-DD",
      placeholder: "选择日期",
      "allow-clear": false
    },
    on: {
      change: function change($event) {
        return _vm.getData(true);
      }
    },
    model: {
      value: _vm.time,
      callback: function callback($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })], 1), _c("div", [_c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: !_vm.selectedRowKeys.length
    },
    on: {
      click: function click() {
        return _vm.download(1);
      }
    }
  }, [_vm._v("批量导出")]), _c("a-button", {
    staticClass: "mr12",
    attrs: {
      disabled: !_vm.data.length
    },
    on: {
      click: function click() {
        return _vm.download(2);
      }
    }
  }, [_vm._v("全部导出")]), _c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuchange
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "1"
  }, [_vm._v("批量导入员工")]), _c("a-menu-item", {
    key: "2"
  }, [_vm._v("批量导入合同")])], 1), _c("a-button", {
    staticClass: "mr12"
  }, [_vm._v(" 批量导入 "), _c("a-icon", {
    attrs: {
      type: "caret-down"
    }
  })], 1)], 1), _c("a-button", {
    attrs: {
      icon: "plus",
      type: "primary"
    },
    on: {
      click: _vm.addMember
    }
  }, [_vm._v("添加员工")])], 1)]), _c("a-modal", {
    attrs: {
      title: "批量导入目的",
      width: "600px",
      footer: null
    },
    model: {
      value: _vm.chooseVisible,
      callback: function callback($$v) {
        _vm.chooseVisible = $$v;
      },
      expression: "chooseVisible"
    }
  }, [_c("a-button", {
    staticClass: "chooseBox",
    on: {
      click: function click($event) {
        return _vm.chooseOk(1);
      }
    }
  }, [_c("div", {
    staticClass: "choose"
  }, [_c("div", {
    staticClass: "chooseTitle"
  }, [_vm._v("批量导入新员工")]), _c("div", {
    staticClass: "chooseContent"
  }, [_vm._v("适用于首次使用花名册新增员工，仅支持导入花名册中没有的员工，仅支持导入在职员工。")])]), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1), _c("a-button", {
    staticClass: "chooseBox",
    staticStyle: {
      "margin-top": "18px"
    },
    on: {
      click: function click($event) {
        return _vm.chooseOk(2);
      }
    }
  }, [_c("div", {
    staticClass: "choose"
  }, [_c("div", {
    staticClass: "chooseTitle"
  }, [_vm._v("批量修改员工信息")]), _c("div", {
    staticClass: "chooseContent"
  }, [_vm._v("适用于批量更新，仅修改花名册已有的员工的信息。")])]), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1)], 1), _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.data,
      "row-key": "id",
      scroll: {
        x: 800
      },
      tip: "加载中...",
      loading: _vm.loading,
      pagination: _vm.pagination,
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(text, record) {
        return _c("span", {}, [_c("div", {
          staticClass: "today-tags"
        }, [record.status == 1 ? _c("div", {
          staticClass: "tags-item",
          staticStyle: {
            background: "#ffbb02"
          }
        }, [_vm._v("待入职")]) : _vm._e(), record.status == 4 ? _c("div", {
          staticClass: "tags-item",
          staticStyle: {
            background: "#b9b9b9"
          }
        }, [_vm._v("已离职")]) : _vm._e(), _c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", [_vm._v(_vm._s(text))])]), _vm._v(" " + _vm._s(text) + " ")], 2)], 1)]);
      }
    }, {
      key: "postType",
      fn: function fn(text) {
        return [_vm._v(_vm._s(text !== null ? _vm.$store.state.enums.postTypeArr[text - 1] : ""))];
      }
    }, {
      key: "gmtCreate",
      fn: function fn(text) {
        return [_c("span", [_vm._v(_vm._s(text ? _vm.moment(text).format("HH:mm:ss") : ""))])];
      }
    }, {
      key: "sourceType",
      fn: function fn(text) {
        return _c("span", {}, [text === 1 ? _c("span", [_vm._v("网页端")]) : _vm._e(), text === 2 ? _c("span", [_vm._v("手机端")]) : _vm._e(), text === 3 ? _c("span", [_vm._v("更换用工单位")]) : _vm._e()]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.detail(record);
            }
          }
        }, [_vm._v(" 详情 ")])];
      }
    }])
  }), _c("a-drawer", {
    attrs: {
      title: "添加员工",
      width: 580,
      destroyOnClose: true,
      visible: _vm.visibleEmpyleeDrawer,
      "wrap-style": {
        overflow: "auto"
      }
    },
    on: {
      close: function close($event) {
        return _vm.onCloseDrawer("visibleEmpyleeDrawer");
      }
    }
  }, [_c("a-tabs", {
    attrs: {
      type: "card"
    },
    on: {
      change: _vm.callback
    }
  }, [_c("a-tab-pane", {
    key: "1",
    attrs: {
      tab: "添加员工"
    }
  }, [_vm.isOut ? _c("a-col", {
    staticStyle: {
      margin: "10px 0 10px 0"
    },
    attrs: {
      gutter: 24
    }
  }, [_c("a-alert", {
    attrs: {
      message: "工价规则、招工劳务费规则修改提交后将生成一条新的规则。",
      banner: "",
      closable: ""
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "emp-title"
  }, [_vm._v("员工信息")]), _c("dynamic-Add-Employees", {
    attrs: {
      outEmpDetail: _vm.outEmpDetail,
      title: _vm.title,
      moduleCode: "onJob"
    },
    on: {
      saveValueChange: _vm.saveValueChange,
      cancelForm: _vm.cancelForm
    }
  })], 1), _c("a-tab-pane", {
    key: "2",
    attrs: {
      tab: "添加待入职"
    }
  }, [_vm.isOut ? _c("a-col", {
    staticStyle: {
      margin: "10px 0 10px 0"
    },
    attrs: {
      gutter: 24
    }
  }, [_c("a-alert", {
    attrs: {
      message: "工价规则、招工劳务费规则修改提交后将生成一条新的规则。",
      banner: "",
      closable: ""
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "emp-title"
  }, [_vm._v("员工信息")]), _c("dynamic-Add-Employees", {
    attrs: {
      title: _vm.title,
      moduleCode: "waitJob"
    },
    on: {
      cancelForm: _vm.cancelForm,
      saveValueChange: _vm.saveValueChange
    }
  })], 1), _c("a-tab-pane", {
    key: "3",
    attrs: {
      tab: "扫码入职"
    }
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.loadingcode,
      delay: 300
    }
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "二维码有效期"
    }
  }, [_c("div", {
    staticStyle: {
      width: "200px",
      display: "flex",
      "align-items": "center",
      "font-size": "12px"
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "100%",
      "margin-right": "10px"
    },
    attrs: {
      min: 1
    },
    on: {
      change: _vm.setAllContent
    },
    model: {
      value: _vm.timeDay,
      callback: function callback($$v) {
        _vm.timeDay = $$v;
      },
      expression: "timeDay"
    }
  }), _c("div", {
    staticStyle: {
      "font-weight": "800",
      "font-size": "14px"
    }
  }, [_vm._v("天")])], 1), _c("div", [_vm._v("有效期：" + _vm._s(_vm.FailureTime))])]), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "工作性质"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType", {
        rules: [{
          required: true,
          message: "请选择工作性质"
        }]
      }],
      expression: "[\n                    'postType',\n                    {\n                      rules: [{ required: true, message: '请选择工作性质' }]\n                    }\n                  ]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择工作性质",
      options: _vm.$store.state.enums.postTypeLst,
      "default-value": _vm.SMDefault
    },
    on: {
      change: _vm.postTypeSaoMaChange
    },
    model: {
      value: _vm.SMDefault,
      callback: function callback($$v) {
        _vm.SMDefault = $$v;
      },
      expression: "SMDefault"
    }
  })], 1), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "渠道来源"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType", {
        rules: []
      }],
      expression: "['postType', { rules: [] }]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      "default-value": _vm.channelDefault,
      "show-search": "",
      allowClear: "",
      placeholder: "请选择渠道来源"
    },
    on: {
      change: _vm.channelSaoMaChange
    },
    model: {
      value: _vm.channelDefault,
      callback: function callback($$v) {
        _vm.channelDefault = $$v;
      },
      expression: "channelDefault"
    }
  }, _vm._l(_vm.siteChannelList, function (item, index) {
    return _c("a-select-option", {
      key: "".concat(item.value, "_").concat(index),
      attrs: {
        value: "".concat(item.value, "_").concat(item.label)
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1), _vm.typeValue === 1 && _vm.SMMubanList.length > 0 ? _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "审批模板"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType", {
        rules: [{
          required: true,
          message: "请选择审批模板"
        }]
      }],
      expression: "[\n                    'postType',\n                    {\n                      rules: [{ required: true, message: '请选择审批模板' }]\n                    }\n                  ]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请选择审批模板",
      "default-value": _vm.muban
    },
    on: {
      change: _vm.mubanSelect
    },
    model: {
      value: _vm.muban,
      callback: function callback($$v) {
        _vm.muban = $$v;
      },
      expression: "muban"
    }
  }, _vm._l(_vm.SMMubanList, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1) : _vm._e(), _c("a-form-item", {
    staticClass: "baseInfo-item",
    attrs: {
      label: "生成二维码"
    }
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      display: "flex",
      "align-items": "center",
      "margin-top": "15px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "150px",
      height: "150px"
    },
    attrs: {
      src: _vm.codeimg
    }
  }), _vm.refreshCode ? _c("div", {
    staticStyle: {
      position: "absolute",
      "z-index": "100",
      width: "150px",
      height: "150px",
      "background-color": "#252525aa"
    },
    on: {
      click: _vm.creatCode
    }
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer",
      width: "100%",
      height: "100%",
      display: "flex",
      "flex-flow": "column",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c("a-icon", {
    style: {
      fontSize: "18px",
      color: "#fff"
    },
    attrs: {
      type: "reload"
    }
  }), _c("span", {
    staticStyle: {
      color: "#ffffff"
    }
  }, [_vm._v("刷新二维码")])], 1)]) : _vm._e()]), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      disabled: _vm.refreshCode
    },
    on: {
      click: _vm.downloadCod
    }
  }, [_vm._v("下载二维码")])], 1)])], 1)], 1)], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "添加待入职",
      visible: _vm.isCloseMethod2,
      width: "520px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_vm.isApproval ? _c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("关闭")]) : _vm._e(), !_vm.isApproval ? _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.finishEdit2
    }
  }, [_vm._v("继续办理")]) : _vm._e()], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "column",
      "align-items": "center"
    }
  }, [!_vm.isApproval ? _c("img", {
    staticStyle: {
      width: "200px",
      height: "200px"
    },
    attrs: {
      src: require("@/assets/img/1.png")
    }
  }) : _c("img", {
    staticStyle: {
      width: "200px",
      height: "200px"
    },
    attrs: {
      src: require("@/assets/img/2.png")
    }
  }), _c("span", {
    staticStyle: {
      "font-size": "20px",
      "font-weight": "800"
    }
  }, [_vm._v(_vm._s(!_vm.isApproval ? "提交成功" : "审批中"))]), _c("span", {
    staticStyle: {
      color: "#00000077",
      "margin-top": "12px",
      "margin-bottom": "12px"
    }
  }, [_vm._v(_vm._s(!_vm.isApproval ? "可在入职管理中选择确认到岗或者未到岗" : "已提交至审批流程"))])])], 2), _c("a-modal", {
    attrs: {
      title: "选择审批模板",
      visible: _vm.selectModel,
      width: "520px",
      "destroy-on-close": true
    },
    on: {
      cancel: _vm.handleCancel
    }
  }, [_c("template", {
    slot: "footer"
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c("a-button", {
    key: "submit",
    attrs: {
      type: "primary",
      ghost: ""
    },
    on: {
      click: _vm.finishEdit3
    }
  }, [_vm._v("下一步")])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-flow": "column",
      "align-items": "center"
    }
  }, _vm._l(_vm.templatesListGroup, function (item, i) {
    return _c("div", {
      key: i,
      class: {
        backgroundC: item.select
      },
      staticStyle: {
        "padding-top": "10px",
        "padding-bottom": "10px",
        "padding-right": "15px",
        width: "100%",
        display: "flex",
        "flex-flow": "row",
        "align-items": "center",
        "justify-content": "space-between"
      },
      on: {
        click: function click($event) {
          return _vm.temClick(item);
        }
      }
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        "flex-flow": "row"
      }
    }, [_c("img", {
      staticStyle: {
        width: "52px",
        height: "52px",
        "margin-right": "15px"
      },
      attrs: {
        src: require("@/assets/img/模板icon.png")
      }
    }), _c("div", [_c("span", {
      staticStyle: {
        display: "flex",
        "flex-flow": "column",
        "font-size": "16px",
        "font-weight": "600"
      }
    }, [_vm._v(_vm._s(item.typeName))]), _c("span", {
      staticStyle: {
        color: "#00000077"
      }
    }, [_vm._v(_vm._s(item.typeDesc))])])]), item.select ? _c("xq-icon", {
      staticClass: "primary_color fs20",
      attrs: {
        type: "icon-wancheng2"
      }
    }) : _vm._e()], 1);
  }), 0)], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };