import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "paperCOM"
  }, [_vm.state != 1 ? _c("div", _vm._l(_vm.dataList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "mb24"
    }, [!item.edit ? _c("a-collapse", {
      attrs: {
        "default-active-key": "1",
        bordered: false
      },
      on: {
        change: function change(e) {
          return _vm.change(e, index);
        }
      },
      scopedSlots: _vm._u([{
        key: "expandIcon",
        fn: function fn(props) {
          return [_c("a-icon", {
            style: (_vm.typeList[item.type] || item.startTime || item.endTime || item.timeLimit || item.companyName || item.subscribeTime) && !item.collapses ? "margin-top: -18px" : "",
            attrs: {
              type: "caret-right",
              rotate: props.isActive ? 90 : 0
            }
          })];
        }
      }], null, true),
      model: {
        value: item.collapse,
        callback: function callback($$v) {
          _vm.$set(item, "collapse", $$v);
        },
        expression: "item.collapse"
      }
    }, [_c("a-collapse-panel", {
      key: "1"
    }, [_c("template", {
      slot: "header"
    }, [_c("div", {
      staticClass: "h-t",
      style: (_vm.typeList[item.type] || item.startTime || item.endTime || item.timeLimit || item.companyName || item.subscribeTime) && !item.collapses ? "margin-bottom: 12px" : ""
    }, [_vm._v(" " + _vm._s(item.name ? item.name : "未命名合同") + " ")]), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.collapses,
        expression: "!item.collapses"
      }],
      staticClass: "h-d"
    }, [_vm.typeList[item.type] ? _c("div", [_vm._v(_vm._s(_vm.typeList[item.type]))]) : _vm._e(), item.startTime || item.endTime ? _c("div", {
      staticClass: "line"
    }) : _vm._e(), item.startTime ? _c("div", [_vm._v(_vm._s(_vm.moment(item.startTime).format("YYYY-MM-DD")))]) : _vm._e(), item.startTime && item.endTime ? _c("div", [_vm._v("~")]) : _vm._e(), item.endTime ? _c("div", [_vm._v(_vm._s(_vm.moment(item.endTime).format("YYYY-MM-DD")))]) : _vm._e(), item.timeLimit ? _c("div", {
      staticClass: "line"
    }) : _vm._e(), item.timeLimit ? _c("div", [_vm._v(_vm._s(item.timeLimit) + " " + _vm._s(item.timeLimitType == 1 ? "年" : "月"))]) : _vm._e(), item.companyName ? _c("div", {
      staticClass: "line"
    }) : _vm._e(), _c("a-tooltip", {
      attrs: {
        placement: "bottom"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.companyName))])]), item.companyName ? _c("div", {
      staticClass: "companyNameOver"
    }, [_vm._v(" " + _vm._s(item.companyName) + " ")]) : _vm._e()], 2), item.subscribeTime ? _c("div", {
      staticClass: "line"
    }) : _vm._e(), item.subscribeTime ? _c("div", [_vm._v(_vm._s(_vm.moment(item.subscribeTime).format("YYYY-MM-DD")) + " 签订")]) : _vm._e()], 1)]), _c("div", {
      staticClass: "collapse-box"
    }, [_c("a-dropdown", {
      attrs: {
        overlayClassName: "action-popover"
      }
    }, [_c("a-menu", {
      attrs: {
        slot: "overlay"
      },
      on: {
        click: function click(e) {
          return _vm.handleMenuchange(e, index, item);
        }
      },
      slot: "overlay"
    }, [_c("a-menu-item", {
      key: "1"
    }, [_c("div", {
      staticClass: "m-div"
    }, [_vm._v("编辑")])]), _c("a-menu-item", {
      key: "2"
    }, [_c("div", {
      staticClass: "m-div"
    }, [_vm._v("删除")])])], 1), _c("a-button", {
      staticClass: "btn"
    }, [_vm._v(" 操作 "), _c("a-icon", {
      attrs: {
        type: "caret-down"
      }
    })], 1)], 1), _c("a-form", {
      attrs: {
        "label-col": {
          span: 6
        },
        "wrapper-col": {
          span: 12
        }
      }
    }, [_c("a-row", {
      attrs: {
        type: "flex"
      }
    }, [_c("a-col", {
      attrs: {
        span: 11
      }
    }, [_c("a-form-item", {
      attrs: {
        label: "合同类型"
      }
    }, [_vm._v(" " + _vm._s(_vm.typeList[item.type]) + " ")])], 1), _c("a-col", {
      attrs: {
        span: 11
      }
    }, [_c("a-form-item", {
      attrs: {
        label: "合同编号"
      }
    }, [item.number ? _c("span", [_vm._v(" " + _vm._s(item.number))]) : _vm._e()])], 1), _c("a-col", {
      attrs: {
        span: 11
      }
    }, [_c("a-form-item", {
      attrs: {
        label: "合同开始时间"
      }
    }, [item.startTime ? _c("span", [_vm._v(_vm._s(_vm.moment(item.startTime).format("YYYY-MM-DD")))]) : _vm._e()])], 1), item.type != "laborContractUnlimit" ? _c("a-col", {
      attrs: {
        span: 11
      }
    }, [_c("a-form-item", {
      attrs: {
        label: "合同结束时间"
      }
    }, [item.endTime ? _c("span", [_vm._v(" " + _vm._s(_vm.moment(item.endTime).format("YYYY-MM-DD")))]) : _vm._e()])], 1) : _vm._e(), item.type != "laborContractUnlimit" ? _c("a-col", {
      attrs: {
        span: 11
      }
    }, [_c("a-form-item", {
      attrs: {
        label: "合同期限"
      }
    }, [item.timeLimit ? _c("span", [_vm._v(" " + _vm._s(item.timeLimit) + " " + _vm._s(item.timeLimitType == 1 ? "年" : "月"))]) : _vm._e()])], 1) : _vm._e(), _c("a-col", {
      attrs: {
        span: 11
      }
    }, [_c("a-form-item", {
      attrs: {
        label: "合同公司"
      }
    }, [_c("a-tooltip", {
      attrs: {
        placement: "bottom"
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("span", [_vm._v(_vm._s(item.companyName))])]), item.companyName ? _c("div", {
      staticClass: "companyNameOver"
    }, [_vm._v(" " + _vm._s(item.companyName) + " ")]) : _vm._e()], 2)], 1)], 1), _c("a-col", {
      attrs: {
        span: 11
      }
    }, [_c("a-form-item", {
      attrs: {
        label: "合同状态"
      }
    }, [item.status ? _c("span", [_vm._v(" " + _vm._s(_vm.status[item.status - 1]) + " ")]) : _vm._e()])], 1), _c("a-col", {
      attrs: {
        span: 11
      }
    }, [_c("a-form-item", {
      attrs: {
        label: "合同签订日期"
      }
    }, [item.subscribeTime ? _c("span", [_vm._v(" " + _vm._s(_vm.moment(item.subscribeTime).format("YYYY-MM-DD")))]) : _vm._e()])], 1)], 1), _c("a-row", [_c("a-form-item", {
      staticStyle: {
        "margin-left": "-1.2%"
      },
      attrs: {
        label: "合同备注",
        "label-col": {
          span: 3
        },
        "wrapper-col": {
          span: 20
        }
      }
    }, [_vm._v(" " + _vm._s(item.remarks) + " ")])], 1), _c("a-row", [_c("a-form-item", {
      staticStyle: {
        "margin-left": "-1.2%"
      },
      attrs: {
        label: "合同附件",
        "label-col": {
          span: 3
        },
        "wrapper-col": {
          span: 20
        }
      }
    }, [item.files ? _c("div", {
      staticClass: "file-box"
    }, _vm._l(item.files, function (item2, index2) {
      return _c("div", {
        key: index2,
        staticClass: "file mr12 mb12"
      }, [_c("div", {
        staticClass: "f-img",
        style: !_vm.item2Name(item2) ? "border: 1px dashed #d7d7d7;" : ""
      }, [_vm.item2Name(item2) ? _c("img", {
        attrs: {
          src: "".concat(item2, "?token=").concat(_vm.token),
          alt: ""
        }
      }) : _c("img", {
        staticClass: "mrFile",
        attrs: {
          src: require("../../../../img/file.png")
        }
      }), _vm.item2File(item2) ? _c("div", [_c("div", {
        staticClass: "model",
        on: {
          click: function click($event) {
            return _vm.check(item2);
          }
        }
      }, [_c("div", [_c("a-icon", {
        attrs: {
          type: "eye"
        }
      }), _c("p", [_vm._v("预览")])], 1)])]) : _vm._e()]), _c("div", {
        staticClass: "f-name"
      }, [_vm._v(_vm._s(item2.split("/").slice(-1)[0]))])]);
    }), 0) : _vm._e(), _c("UploadSiteFile", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: false,
        expression: "false"
      }],
      ref: "uploadTool" + index,
      refInFor: true,
      attrs: {
        limit: 9,
        "default-value": item.file,
        accept: ".doc, .docx, .xls, .xlsx, .pdf, .zip, .rar, .jpg, .jpeg, .png"
      }
    }), item.file ? _c("a-button", {
      staticClass: "mt24",
      attrs: {
        size: "small",
        type: "primary"
      },
      on: {
        click: function click($event) {
          return _vm.downloadImg(index);
        }
      }
    }, [_vm._v(" 打包下载 ")]) : _vm._e()], 1)], 1)], 1)], 1)], 2)], 1) : _c("forms", {
      attrs: {
        index: index,
        employInfoId: _vm.employInfoId,
        defaultVal: item
      },
      on: {
        upDate: _vm.upDate
      }
    })], 1);
  }), 0) : _c("forms", {
    attrs: {
      index: -1,
      employInfoId: _vm.employInfoId,
      defaultVal: {}
    },
    on: {
      upDate: _vm.upDate
    }
  }), _c("a-modal", {
    attrs: {
      title: "删除确认",
      "confirm-loading": _vm.confirmLoading,
      width: 400
    },
    on: {
      ok: _vm.handleEnable
    },
    model: {
      value: _vm.deleteVisible,
      callback: function callback($$v) {
        _vm.deleteVisible = $$v;
      },
      expression: "deleteVisible"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "40px",
      color: "red"
    },
    attrs: {
      type: "question-circle"
    }
  }), _c("div", {
    staticStyle: {
      "margin-left": "15px"
    }
  }, [_vm._v("是否确认删除？")])], 1)]), _c("a-modal", {
    attrs: {
      title: "预览",
      visible: _vm.detailVisible,
      width: "800px",
      footer: null
    },
    on: {
      cancel: _vm.onCancelFile
    }
  }, [_vm.item2File(_vm.path, "img") ? _c("div", [_c("img", {
    attrs: {
      src: "".concat(_vm.path, "?token=").concat(_vm.token),
      width: "100%"
    }
  })]) : _c("div", [_vm.eleContractDetailShow ? _c("iframe", {
    staticClass: "checkbox",
    staticStyle: {
      width: "756px",
      height: "70vh",
      overflow: "auto"
    },
    attrs: {
      src: _vm.path.includes(".pdf") ? "".concat(_vm.path, "?token=").concat(_vm.token) : "https://view.officeapps.live.com/op/view.aspx?src=".concat(_vm.path, "?token=").concat(_vm.token)
    }
  }) : _vm._e()])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };