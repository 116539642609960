import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { mapGetters } from "vuex";
import { getByUserId as _getByUserId, findCompanyGroup as _findCompanyGroup } from "./api";
export default {
  components: {},
  name: "AddAccountManagement",
  data: function data() {
    return {
      treeData2: [],
      replaceFields: {
        children: "companyList",
        title: "name",
        key: "id",
        value: "id"
      },
      targetKeys: [],
      dataSource: [],
      choseEmp: true,
      allCompany: [],
      type: undefined,
      selectTreeId: "-1",
      siteName: undefined,
      employerIdList: [],
      childCompanyIdList: [],
      isAllChildCompany: false,
      form: this.$form.createForm(this, {
        name: "account"
      }),
      // 编辑
      editor: false,
      disabled: false,
      // 角色列表
      rolesList: [],
      disabled2: false,
      name: "",
      visible: false,
      copyDetail: {},
      siteId: undefined,
      detail: {
        hrRoleId: "",
        password: "",
        name: "",
        phone: "",
        isAllEmployer: true,
        employerIdList: []
      },
      selectedCityCodes: [],
      treeDatas: [],
      isReadOnly: false
    };
  },
  computed: _objectSpread({
    treeData: {
      get: function get() {
        return this.treeData2;
      },
      set: function set(val) {
        return handleTreeData(this.treeData2, this.targetKeys);
      }
    }
  }, mapGetters({
    COMPANYLIST: "admin/COMPANYLIST"
  })),
  watch: {
    type: function type(newValue) {
      console.log("debug log --> type", newValue);
    }
  },
  created: function created() {
    this.findCompanyGroup(null, true);
    this.geRoles();
    this.editState();
    this.isReadOnly = this.$route.query.isReadOnly === "true";
  },
  mounted: function mounted() {},
  methods: {
    onSeletChange: function onSeletChange(e, a, b) {
      // 根据搜索条件筛选
      this.findCompanyGroup(a);
    },
    showModal: function showModal() {
      this.visible = true;
    },
    handleOk: function handleOk(e) {
      this.visible = false;
    },
    findCompanyGroup: function findCompanyGroup(value, v) {
      var _this = this;
      var data1 = {
        name: value || undefined
      };
      _findCompanyGroup(data1).then(function (res) {
        _this.treeData2 = _this.addTreeSlot(res.data);
        if (_this.treeData2[0] && _this.treeData2[0].type === "site") {
          _this.disabled2 = false;
        } else {
          _this.disabled2 = true;
        }
        _this.treeDatas = [];
        _this.treeDatas = _this.treeData2;
        if (v) {
          _this.dataSource = [];
          _this.flatten(_this.treeData2);
        }
        if (_this.editor && v) {
          _this.detail = _this.$route.query;
          _this.getByUserId();
          _this.copyDetail = _objectSpread({}, _this.$route.query);
          if (!_this.detail.employerIdList) {
            _this.detail.employerIdList = [];
          }
        }
        if (_this.editor) {
          var formKey = _this.form.getFieldsValue();
          Object.keys(formKey).forEach(function (key) {
            formKey[key] = _this.detail[key];
          });
          _this.form.setFieldsValue(formKey);
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 递归替换字段
    addTreeSlot: function addTreeSlot(data) {
      var _this2 = this;
      if (!data) {
        return [];
      }
      data.forEach(function (el) {
        if (el.type === "site") {
          el.disabled = true;
          el.key = "-1";
        } else {
          el.key = el.id;
        }
        el.title = el.name;
        _this2.addTreeSlot(el.companyList);
      });
      return data;
    },
    // 递归处理扁平数组
    flatten: function flatten(list) {
      var _this3 = this;
      if (list !== null) {
        list.forEach(function (item) {
          _this3.dataSource.push(item);
          _this3.flatten(item.companyList);
        });
      }
    },
    onTransChange: function onTransChange(targetKeys) {
      this.targetKeys = targetKeys;
    },
    onChecked: function onChecked(_, e, checkedKeys, itemSelect) {
      var eventKey = e.node.eventKey;
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
    },
    editState: function editState() {
      this.editor = !!this.$route.query.id;
      setTimeout(function () {}, 100);
    },
    getByUserId: function getByUserId() {
      var _this4 = this;
      _getByUserId({
        userId: this.detail.id
      }).then(function (res) {
        _this4.choseEmp = res.data.isAllChildCompany;
        if (res.data.groupIds) {
          _this4.targetKeys = res.data.companyIds.concat(res.data.groupIds);
        }
      });
    },
    // 选择用工单位
    onChange: function onChange(e) {
      if (e) {
        this.detail.isAllEmployer = true;
        this.detail.isAllChildCompany = true;
        this.detail.employerIdList = [];
      } else {
        this.detail.isAllEmployer = false;
        this.detail.isAllChildCompany = false;
      }
    },
    // 编辑
    editorDetail: function editorDetail() {
      this.disabled = !this.disabled;
      if (this.disabled) {
        history.back();
      }
    },
    // 删除账号
    deleteCount: function deleteCount() {
      var _this5 = this;
      this.$confirm({
        title: "删除提示",
        content: "该操作将永久删除,确定删除该账号吗?",
        onOk: function onOk() {
          _this5.$request({
            method: "POST",
            url: "/hr/api/v1/users/delete",
            data: [_this5.$route.query.id]
          }).then(function (_ref) {
            var success = _ref.success;
            if (success) {
              _this5.$notification["success"]({
                message: "删除成功,返回列表"
              });
              history.back();
            }
          });
        }
      });
    },
    // 获取账号角色列表
    geRoles: function geRoles() {
      var _this6 = this;
      var params = {
        subjectType: "company"
      };
      if (this.$route.query.code) {
        params["code"] = this.$route.query.code;
      }
      this.$request({
        method: "GET",
        url: "/hr/api/v1/hrRoles/listAll",
        params: params
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this6.rolesList = data;
      });
    },
    // 选择账号角色
    handleChange: function handleChange(e) {
      this.detail.hrRoleId = e;
    },
    // 新增/修改账号
    saveData: function saveData() {
      var _this7 = this;
      this.form.validateFields(function (err, values) {
        _this7.targetKeys.forEach(function (item, index) {
          _this7.dataSource.forEach(function (item2, index2) {
            if (item === item2.id && item2.type === "group") {
              _this7.childCompanyIdList.push(item);
            }
            if (item === item2.id && item2.type === "company") {
              _this7.employerIdList.push(item);
            }
          });
        });
        _this7.detail.isAllChildCompany = _this7.choseEmp;
        _this7.detail.childCompanyIdList = _this7.childCompanyIdList;
        _this7.detail.employerIdList = _this7.employerIdList;
        _this7.detail.isReadOnly = _this7.isReadOnly;
        Object.keys(values).forEach(function (key) {
          _this7.detail[key] = values[key];
        });
        if (!err) {
          if (_this7.editor) {
            _this7.detail.userId = _this7.detail.id;
            _this7.$request({
              method: "POST",
              url: "/hr/api/v1/users/updateRole",
              data: _this7.detail
            }).then(function (_ref3) {
              var success = _ref3.success;
              if (success) {
                _this7.$notification["success"]({
                  message: "修改成功"
                });
                history.back();
              }
            });
          } else {
            _this7.$request({
              method: "POST",
              url: "/hr/api/v1/users/save",
              data: _this7.detail
            }).then(function (_ref4) {
              var success = _ref4.success;
              if (success) {
                _this7.$notification["success"]({
                  message: "新增成功"
                });
                history.back();
              }
            });
          }
        }
      });
    }
  }
};
function isChecked(selectedKeys, eventKey) {
  return selectedKeys.indexOf(eventKey) !== -1;
}
function handleTreeData(data) {
  var targetKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  data.forEach(function (item) {
    item["disabled"] = targetKeys.includes(item.key);
    if (item.children) {
      handleTreeData(item.children, targetKeys);
    }
  });
  return data;
}