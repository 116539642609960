import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.push.js";
import { mapMutations, mapGetters } from "vuex";
export default {
  data: function data() {
    return {
      current: 0
    };
  },
  computed: _objectSpread({}, mapGetters({
    SALARY: "wages/SALARY"
  })),
  created: function created() {
    if (this.$route.query.current) {
      this.current = Number(this.$route.query.current);
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    resetSalaryParams: "wages/resetSalaryParams",
    setSalaryParams: "wages/setSalaryParams"
  })), {}, {
    // 返回
    goBack: function goBack() {
      this.$router.push("/wages/payslip");
      this.resetSalaryParams();
    }
  })
};