import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import { getPositionInTree } from "@/api/department/position";
export default {
  name: "PositonTree",
  props: {
    List: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      visible: true,
      // List:[],
      positionOptions: []
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.getData();
  },
  methods: {
    getData: function getData(data) {
      var _this = this;
      getPositionInTree(_objectSpread({
        companyId: this.companyId
      }, data)).then(function (res) {
        _this.positionOptions = _this.tree(res.data[0].positionChildList, "positionChildList", true);
      });
    },
    addPosition: function addPosition(e) {
      var obj = this.List.filter(function (v) {
        return v.id === e.id;
      });
      if (obj.length > 0) {
        return;
      }
      this.List.push(e);
      this.$set(this.positionOptions, this.tree(this.positionOptions, "positionChildList", true));
    },
    minus: function minus(i) {
      this.List.splice(i, 1);
      this.$set(this.positionOptions, this.tree(this.positionOptions, "positionChildList", true));
    },
    minusArray: function minusArray(e) {
      this.List = this.getSubtract(this.List, [e]);
    },
    getSubtract: function getSubtract(unionArr, subsetArr) {
      var new_tmp = [];
      for (var i = 0; i < unionArr.length; i++) {
        var flag = true;
        for (var j = 0; j < subsetArr.length; j++) {
          if (unionArr[i].id === subsetArr[j].id) {
            flag = false;
          }
        }
        if (flag) {
          new_tmp.push(unionArr[i]);
        }
      }
      return new_tmp;
    },
    search: function search(e) {
      this.getData({
        name: e
      });
    },
    showModal: function showModal() {
      this.visible = true;
    },
    cancel: function cancel() {
      // this.visible = false;

      this.$emit("cancelPositionList");
    },
    handleOk: function handleOk(e) {
      this.$emit("getPositionList", this.List);
      this.visible = false;
    },
    tree: function tree(array, childKey, onlyLastChild) {
      var _this2 = this;
      if (array === null || array.length < 1) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id;
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        var arr = _this2.List.filter(function (v) {
          return v.id === item.id;
        });
        item.isSelected = arr.length !== 0;
        if (item.children) {
          // item["disabled"] = onlyLastChild
          _this2.tree(item.children, childKey, onlyLastChild);
        }
      });
      return array;
    }
  }
};