import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import { generatedBills, costBills, costBillIdDelete, launchApproval, listAllCompany as _listAllCompany, costBillSites, listAllCompanyGroup as _listAllCompanyGroup, getModelList, selectCompanyByCostBillId, confirmCostBill } from "@/api/billing/billing";
import { getMonthlySalaryXinZi, batchArchive } from "@/api/wages/monthly";
import { UploadSiteFile } from "xqjr-plugin-common";
import selectCompanyGroup from "@/components/SelectCompany";
import { isContainByString } from "@/utils/index";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    selectCompanyGroup: selectCompanyGroup,
    UploadSiteFile: UploadSiteFile,
    Uploading: function Uploading() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/components/Uploading/uploading"));
      });
    }
  },
  data: function data() {
    return {
      zongBill: 0,
      currentItem: undefined,
      BillConfirm: false,
      isMergConfirmPlan: false,
      isMergConfirmPlan2: false,
      templatesList: [],
      templatesListGroup: [],
      templatesListGroup2: [],
      url: "",
      pageSizeOptions: ["10", "20", "50", "100"],
      deleteVisible: false,
      currentitem: undefined,
      xinziName: "",
      billingType: "shebao",
      isSysBilling: true,
      billingD: null,
      isConfirmPlan: false,
      list: [],
      dataList: [],
      size: "default",
      loading: false,
      now: null,
      status: null,
      action: "",
      combined: {},
      nameOrIdCard: null,
      DanWeiSheBao: 0,
      GeRenSheBao: 0,
      DanWeiGongJiJin: 0,
      GeRenGongJiJin: 0,
      JiXinRenShu: 0,
      ZongShiFaGongZi: 0,
      ZongJiaoShui: 0,
      pageNo: 1,
      pageSize: 20,
      total: 0,
      isContainByString: isContainByString,
      seletComyId: [],
      group: [],
      siteId: undefined,
      siteName: undefined,
      selectTreeId: "-1",
      companyId: undefined,
      tempTemplate: undefined,
      comanyListGroup: [],
      tempItem: undefined
    };
  },
  watch: {
    siteName: function siteName(sitName) {
      this.pageNo = 1;
      this.getCostBills();
    },
    selectTreeId: function selectTreeId(_selectTreeId) {}
  },
  created: function created() {
    this.selectTreeId = this.SelectedId;
    this.now = moment();
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState(["site"])), mapGetters(["permissions_site"])), mapGetters({
    SelectedId: "site/SelectedId"
  })), {}, {
    type: function type() {
      return this.site.selectType;
    }
  }),
  methods: {
    onBillChange: function onBillChange(value) {
      this.zongBill = value;
      return value;
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleCompyChange: function handleCompyChange(id) {
      var _this = this;
      this.companyId = id;
      this.templatesListGroup2 = [];
      this.tempTemplate = undefined;
      var type;
      if (this.tempItem.subjectType === "社保账单") {
        type = "socBill";
      } else if (this.tempItem.subjectType === "公积金账单") {
        type = "pfBill";
      } else if (this.tempItem.subjectType === "薪资账单") {
        type = "salaryBill";
      }
      // 根据公司查询模板
      getModelList(id, type).then(function (res) {
        _this.templatesListGroup2 = res.data;
        if (_this.templatesListGroup2.length !== 0) {
          _this.tempTemplate = _this.templatesListGroup2[0].id;
        } else {
          _this.tempTemplate = undefined;
        }
      });
    },
    handleChange3: function handleChange3(id) {
      // 选择模板
      this.tempTemplate = id;
    },
    mergConfirmOk2: function mergConfirmOk2() {
      var _this2 = this;
      // 发起审批
      if (!this.companyId) {
        this.$message.success("请选择用工单位");
        return;
      }
      if (!this.tempTemplate) {
        this.$message.success("请选择审批模板");
        return;
      }
      this.isMergConfirmPlan2 = false;
      launchApproval({
        companyId: this.companyId,
        costBillId: this.tempItem.id,
        flowId: this.tempTemplate
      }).then(function (res) {
        _this2.tempItem.approStatus = res.data.status;
        _this2.$message.success("已发起审批");
      });
    },
    getBillName: function getBillName(name) {
      var f = name.indexOf("]");
      return name.substring(f + 1, name.length);
    },
    getSubT: function getSubT(name) {
      switch (name) {
        case 0:
          return "待结算";
        case 1:
          return "审批中";
        case 2:
          return "审批驳回";
        case 5:
          return "已通过待结算";
        case 6:
          return "已结算";
        default:
          return "";
      }
    },
    getColor: function getColor(status) {
      switch (status) {
        case 0:
          return "#F7B500";
        case 1:
          return "#4282FF";
        case 2:
          return "#FF7085";
        case 5:
          return "#F7B500";
        case 6:
          return "#24CDAA";
        default:
          return "#F7B500";
      }
    },
    getBackground: function getBackground(status) {
      switch (status) {
        case 0:
          return "#F7B50036";
        case 1:
          return "#4282FF36";
        case 2:
          return "#FF708536";
        case 5:
          return "#F7B50036";
        case 6:
          return "#24CDAA36";
        default:
          return "#F7B50036";
      }
    },
    getBorder: function getBorder(status) {
      switch (status) {
        case 0:
          return "1px solid #F7B500";
        case 1:
          return "1px solid #4282FF";
        case 2:
          return "1px solid #FF7085";
        case 5:
          return "1px solid #F7B500";
        case 6:
          return "1px solid #24CDAA";
        default:
          return "1px solid #F7B500";
      }
    },
    onCheckOutChange: function onCheckOutChange(checkedValues) {
      this.billingType = checkedValues.target.value;
    },
    handleChange: function handleChange(e, a) {
      this.seletComyId = e;
    },
    handleChange2: function handleChange2(e) {
      this.seletComyId = this.templatesListGroup[e].id;
      this.group = this.templatesListGroup[e].companyList;
      // console.log(this.templatesListGroup)
      // console.log(this.seletComyId)
    },
    onFileChange: function onFileChange(urls) {
      console.log(urls);
    },
    confirmDelete: function confirmDelete() {
      var _this3 = this;
      this.deleteVisible = false;
      costBillIdDelete(this.currentitem.id).then(function (res) {
        _this3.$message.success("账单删除成功");
        _this3.getCostBills();
      });
    },
    getTitle: function getTitle(value, index) {
      switch (value) {
        case "社保账单":
          if (index === 0) {
            return "个人社保";
          } else if (index === 1) {
            return "单位社保";
          } else if (index === 2) {
            return "总缴费";
          } else {
            break;
          }
        case "公积金账单":
          if (index === 0) {
            return "个人公积金";
          } else if (index === 1) {
            return "单位公积金";
          } else if (index === 2) {
            return "总缴费";
          } else {
            break;
          }
        case "薪资账单":
          if (index === 0) {
            return "计薪人数";
          } else if (index === 1) {
            return "总缴税";
          } else if (index === 2) {
            return "总实发工资";
          } else {
            break;
          }
      }
    },
    getCost: function getCost(value, index) {
      switch (value.subjectType) {
        case "社保账单":
          if (index === 0) {
            return value.personalSocAmount;
          } else if (index === 1) {
            return value.companySocAmount;
          } else if (index === 2) {
            return value.totalAmount;
          } else {
            break;
          }
        case "公积金账单":
          if (index === 0) {
            return value.personalPfAmount;
          } else if (index === 1) {
            return value.companyPfAmount;
          } else if (index === 2) {
            return value.totalAmount;
          } else {
            break;
          }
        case "薪资账单":
          if (index === 0) {
            return value.calculateSalaryNumber ? value.calculateSalaryNumber : 0;
          } else if (index === 1) {
            return value.totalTax ? value.totalTax : 0;
          } else if (index === 2) {
            return value.totalAmount ? value.totalAmount : 0;
          } else {
            break;
          }
      }
    },
    onShowSizeChange: function onShowSizeChange(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.getCostBills();
    },
    listAllCompany: function listAllCompany() {
      var _this4 = this;
      _listAllCompany().then(function (res) {
        _this4.templatesList = res.data;
      });
    },
    listAllCompanyGroup: function listAllCompanyGroup() {
      var _this5 = this;
      _listAllCompanyGroup().then(function (res) {
        _this5.templatesListGroup = res.data;
      });
    },
    getCostBills: function getCostBills() {
      var _this6 = this;
      var param = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      if (this.selectTreeId !== "-1") {
        // 总公司
        if (this.type === "group") {
          param.isMerge = true;
        } else {
          param.isMerge = false;
        }
        param.companyId = this.selectTreeId;
      } else {
        param.isMerge = false;
      }
      if (this.now) {
        param.year = moment(this.now).format("YYYY");
        param.month = moment(this.now).format("MM") === "10" ? moment(this.now).format("MM") : moment(this.now).format("MM").replace("0", "");
      }
      if (this.status) {
        param.status = parseInt(this.status);
      }
      costBills(param).then(function (res) {
        _this6.pageNo = res.data.pageNo === 0 ? 1 : res.data.pageNo;
        // this.pageSize = res.data.pageSize;
        _this6.total = res.data.entityCount;
        if (res.data.entities === null) {
          _this6.list = [];
        } else {
          _this6.list = [];
          _this6.$nextTick(function () {
            var count = res.data.entities.length;
            var name = null;
            for (var i = 0; i < count; i++) {
              res.data.entities[i].tempCompanyName = res.data.entities[i].companyName;
              if (!name || res.data.entities[i].companyName !== name) {
                name = res.data.entities[i].companyName;
              } else {
                res.data.entities[i].companyName = null;
              }
            }
            _this6.list = res.data.entities;
          });
        }
      });
    },
    onDanWeiSheBaoChange: function onDanWeiSheBaoChange(value) {
      this.DanWeiSheBao = value;
    },
    onGeRenSheBaoChange: function onGeRenSheBaoChange(value) {
      this.GeRenSheBao = value;
    },
    onDanWeiGongJiJinChange: function onDanWeiGongJiJinChange(value) {
      this.DanWeiGongJiJin = value;
    },
    onGeRenGongJiJinChange: function onGeRenGongJiJinChange(value) {
      this.GeRenGongJiJin = value;
    },
    onJiXinRenShuChange: function onJiXinRenShuChange(value) {
      this.JiXinRenShu = value;
    },
    onZongShiFaGongZiChange: function onZongShiFaGongZiChange(value) {
      this.ZongShiFaGongZi = value;
    },
    onZongJiaoShuiChange: function onZongJiaoShuiChange(value) {
      this.ZongJiaoShui = value;
    },
    tabClick: function tabClick(value) {
      this.isSysBilling = value;
    },
    handleSelectChange: function handleSelectChange(value) {
      this.billingType = value;
      this.url = "";
      this.DanWeiSheBao = 0;
      this.GeRenSheBao = 0;
      this.DanWeiGongJiJin = 0;
      this.GeRenGongJiJin = 0;
      this.JiXinRenShu = 0;
      this.ZongShiFaGong = 0;
      this.ZongJiaoShui = 0;
    },
    handleSelectGZChange: function handleSelectGZChange(value) {
      this.xinziName = value;
    },
    onStopYearMonthChange: function onStopYearMonthChange(date, dateString) {
      this.billingD = date;
    },
    handleCancel: function handleCancel() {
      this.isConfirmPlan = false;
      this.isMergConfirmPlan = false;
      this.isMergConfirmPlan2 = false;
      this.BillConfirm = false;
    },
    getType: function getType() {
      switch (this.billingType) {
        case "shebao":
          return "社保账单";
        case "gongjijin":
          return "公积金账单";
        case "xinzi":
          return "薪资账单";
      }
    },
    mergConfirmOk: function mergConfirmOk() {
      var _this7 = this;
      if (!this.billingD) {
        this.$message.error("请选择账单时间");
        return;
      }
      // console.log(this.seletComyId)
      if (this.seletComyId.length === 0) {
        this.$message.error("请选择用工单位分组");
        return;
      }
      var param = {
        year: moment(this.billingD).format("YYYY"),
        month: moment(this.billingD).format("MM"),
        subjectType: this.getType(),
        autoSystem: true,
        companyGroupId: this.seletComyId
      };
      costBillSites(param).then(function (res) {
        _this7.isMergConfirmPlan = false;
        _this7.$message.success("账单已合成");
        _this7.pageNo = 1;
        _this7.getCostBills();
      });
    },
    BillConfirmOk: function BillConfirmOk() {
      var _this8 = this;
      if (this.currentItem) {
        var param = {
          costBillId: this.currentItem.id,
          finalAmount: this.zongBill
        };
        confirmCostBill(param).then(function (res) {
          _this8.handleCancel();
          _this8.getCostBills();
        });
      }
    },
    confirmOk: function confirmOk() {
      if (!this.billingD) {
        this.$message.error("请选择账单时间");
        return;
      }
      if (this.seletComyId.length === 0) {
        this.$message.error("请选择用工单位");
        return;
      }
      var param = {
        year: moment(this.billingD).format("YYYY"),
        month: moment(this.billingD).format("MM"),
        subjectType: this.getType(),
        autoSystem: this.isSysBilling,
        companyIdList: this.seletComyId
      };
      var that = this;
      this.$confirm({
        title: "即将创建账单",
        content: param.subjectType === "薪资账单" ? "是否确认生成本月薪资账单，一旦生成工资表将会归档，本月工资表不可进行更改" : "是否确认生成本月" + param.subjectType + "账单，一旦生成，本月" + param.subjectType + "将不可进行更改",
        okText: "确认",
        onOk: function onOk() {
          if (!that.isSysBilling) {
            // 自己创建
            switch (that.billingType) {
              case "shebao":
                param.companySocAmount = that.DanWeiSheBao;
                param.personalSocAmount = that.GeRenSheBao;
                break;
              case "gongjijin":
                param.companyPfAmount = that.DanWeiGongJiJin;
                param.personalPfAmount = that.GeRenGongJiJin;
                break;
              case "xinzi":
                param.calculateSalaryNumber = that.JiXinRenShu;
                param.totalAmount = that.ZongShiFaGongZi;
                param.totalTax = that.ZongJiaoShui;
                break;
            }
            if (that.url) {
              // 附件
              param.annexUrl = that.url;
            }
            generatedBills(param).then(function (res) {
              that.isConfirmPlan = false;
              that.$message.success("账单已生成");
              that.pageNo = 1;
              that.getCostBills();
            });
          } else {
            // 查询是否有归档
            if (param.subjectType === "薪资账单") {
              var selsid = "";
              for (var i = 0; i < that.seletComyId.length; i++) {
                if (i === that.seletComyId.length - 1) {
                  selsid += that.seletComyId[i];
                } else {
                  selsid += that.seletComyId[i] + ",";
                }
              }
              getMonthlySalaryXinZi({
                companyIds: selsid,
                monthly: param.month,
                yearly: param.year
              }).then(function (res) {
                if (!res.data || res.data.entities) {
                  var weiGuiDang = [];
                  for (var i = 0; i < res.data.entities.length; i++) {
                    if (!res.data.entities[i].isArchive) {
                      weiGuiDang.push(res.data.entities[i].id);
                    }
                  }
                  // 有未归档模板
                  if (weiGuiDang.length !== 0) {
                    that.$confirm({
                      title: "发现未归档薪资模板",
                      content: "是否确认归档？归档后工资表所有数据 不允许删除或更改",
                      okText: "确认",
                      onOk: function onOk() {
                        batchArchive(weiGuiDang).then(function (res) {
                          if (res.success) {
                            generatedBills(param).then(function (res) {
                              that.isConfirmPlan = false;
                              that.$message.success("账单已生成");
                              that.pageNo = 1;
                              that.getCostBills();
                            });
                          }
                        });
                      },
                      onCancel: function onCancel() {}
                    });
                  } else {
                    generatedBills(param).then(function (res) {
                      that.isConfirmPlan = false;
                      that.$message.success("账单已生成");
                      that.pageNo = 1;
                      that.getCostBills();
                    });
                  }
                } else {
                  generatedBills(param).then(function (res) {
                    that.isConfirmPlan = false;
                    that.$message.success("账单已生成");
                    that.pageNo = 1;
                    that.getCostBills();
                  });
                }
              }).catch(function (error) {
                console.log("Error", error);
                that.loading = false;
              });
            } else {
              generatedBills(param).then(function (res) {
                that.isConfirmPlan = false;
                that.$message.success("账单已生成");
                that.pageNo = 1;
                that.getCostBills();
              });
            }
          }
        },
        onCancel: function onCancel() {}
      });
    },
    onDetail: function onDetail(item) {
      if (item.autoSystem) {
        this.$router.push({
          path: this.isContainByString("settlementAdmin") ? "/settlementAdmin/billing/billingDetail" : "/settlement/billing/billingDetail",
          query: {
            item: JSON.stringify(item)
          }
        });
      } else {
        console.log(item.annexUrl);
        if (item.annexUrl) {
          // 下载
          window.open(item.annexUrl + "?token=".concat(this.$store.state.auth.token));
        }
      }
    },
    handleMenuClick: function handleMenuClick(index, item) {
      var _this9 = this;
      switch (index) {
        case 0:
          if (item.userApproId) {
            this.$router.push("/approval/detail/".concat(item.userApproId, "/read"));
          }
          break;
        case 1:
          // 重新发起审批
          this.tempItem = item;
          this.isMergConfirmPlan2 = true;
          this.companyId = undefined;
          this.tempTemplate = undefined;
          // 拉取账单的用工单位
          selectCompanyByCostBillId({
            costBillId: this.tempItem.id
          }).then(function (res) {
            _this9.comanyListGroup = res.data;
            if (_this9.comanyListGroup.length !== 0) {
              _this9.companyId = _this9.comanyListGroup[0].id;

              // 拉取模板
              _this9.handleCompyChange(_this9.companyId);
            } else {
              _this9.companyId = undefined;
            }
          });
          break;
        case 2:
          this.deleteVisible = true;
          this.currentitem = item;
          break;
        case 3:
          this.currentItem = item;
          this.BillConfirm = true;
          break;
      }
    },
    // 创建账单
    creatBilling: function creatBilling() {
      this.listAllCompany();
      this.isConfirmPlan = true;
      this.billingType = "shebao";
      this.isSysBilling = true;
      this.url = "";
      this.DanWeiSheBao = 0;
      this.GeRenSheBao = 0;
      this.DanWeiGongJiJin = 0;
      this.GeRenGongJiJin = 0;
      this.JiXinRenShu = 0;
      this.ZongShiFaGong = 0;
      this.ZongJiaoShui = 0;
      this.billingD = null;
      this.seletComyId = [];
    },
    // 合并账单
    mergBilling: function mergBilling() {
      this.listAllCompanyGroup();
      this.isMergConfirmPlan = true;
      this.group = [];
      this.billingD = null;
      this.seletComyId = [];
      this.billingType = "shebao";
    },
    monthChange: function monthChange(e) {
      console.log(e);
      this.now = e;
      this.pageNo = 1;
      this.getCostBills();
    },
    approStatusChange: function approStatusChange(e) {
      this.status = e;
      this.getCostBills();
    }
  }
};