import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import ListSearchForBox from "./listSearchForBox.vue";
import { treatmentDisposeEventsReq } from "../api";
import { columns } from "./funcs";
import { getToken } from "xqjr-module-auth";
import axios from "axios";
export default {
  name: "",
  components: {
    ListSearchForBox: ListSearchForBox
  },
  mixins: [],
  props: {
    isFinish: {
      type: Boolean,
      default: false
    },
    eventTypeLst: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: Boolean,
      default: false
    },
    templateId: {
      type: String,
      default: ""
    },
    templateName: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      pagination: {
        pageSize: 8,
        total: 0,
        current: 1
      },
      selectedRowKeys: [],
      selectedMap: {},
      columns: columns,
      valueTemp: false,
      dataLst: [],
      searchParams: {},
      pageNo: 1,
      pageSize: 8,
      total: 0
    };
  },
  computed: {
    selectedRows: function selectedRows() {
      return Object.values(this.selectedMap);
    }
  },
  watch: {
    value: {
      handler: function handler(newVal) {
        if (this.valueTemp !== newVal) {
          this.valueTemp = newVal;
          if (this.valueTemp) {
            this.loadData();
          }
        }
      },
      immediate: true
    },
    valueTemp: function valueTemp(newVal) {
      this.$emit("input", newVal);
      this.$emit("change", newVal);
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    delSelected: function delSelected(id) {
      var tempMap = _objectSpread({}, this.selectedMap);
      delete tempMap[id];
      this.selectedMap = tempMap;
    },
    doExport: function doExport() {
      if (this.templateId && this.selectedRows.length > 0) {
        axios({
          url: "/hr/api/v1/treatmentDisposeEvents/export",
          method: "post",
          data: {
            eventIdList: this.selectedRows.map(function (item) {
              return item.id;
            }),
            templateId: this.templateId
          },
          responseType: "blob",
          headers: {
            token: getToken()
          }
        }).then(function (res) {
          var link = document.createElement("a");
          var blob = new Blob([res.data], {
            type: "application/vnd.ms-excel"
          });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", "批量导出.xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).catch(function (err) {
          console.log("error log --> ", err);
        });
      } else {
        this.$message.error("未选中导出项");
      }
    },
    handleTableChange: function handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.loadData();
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      var tempMap = _objectSpread({}, this.selectedMap);
      this.dataLst.forEach(function (item) {
        if (selectedRowKeys.includes(item.id)) {
          tempMap[item.id] = item;
        } else if (tempMap[item.id]) {
          delete tempMap[item.id];
        }
      });
      this.selectedRowKeys = selectedRowKeys;
      this.selectedMap = tempMap;
    },
    onSearch: function onSearch(params) {
      this.searchParams = params;
      this.pagination.current = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      var params = _objectSpread({
        isFinish: this.isFinish,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        templateId: this.templateId
      }, this.searchParams || {});
      treatmentDisposeEventsReq(params).then(function (res) {
        if (res && res.data) {
          _this.dataLst = res.data.entities;
          _this.pagination.total = res.data.entityCount;
          var selectedIds = Object.keys(_this.selectedMap);
          var dataIds = _this.dataLst.map(function (item) {
            return item.id;
          });
          _this.selectedRowKeys = selectedIds.filter(function (id) {
            return dataIds.includes(id);
          });
        }
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};