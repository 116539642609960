export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {
      recordLst: []
    };
  },
  computed: {
    empId: function empId() {
      return this.$route.query.id;
    }
  },
  watch: {
    empId: {
      handler: function handler() {
        this.loadData();
      },
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    formatDate: function formatDate(val) {
      if (val) {
        return this.$dayjs(val).format("YYYY-MM-DD");
      }
      return "--";
    },
    loadData: function loadData() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/hrInsurance/selectEmpInsuranceList/".concat(this.empId)
      }).then(function (res) {
        _this.recordLst = res.data;
      }).catch(function (err) {
        console.log("selectEmpInsuranceList", err);
      });
    }
  }
};