var state = {
  screenVisible: false
};
var mutations = {
  screenVisible: function screenVisible(state, payload) {
    state.screenVisible = payload;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};