export default {
  fields: {
    empName: {
      model: "empName"
    },
    licenseNumber: {
      model: "licenseNumber"
    },
    phone: {
      model: "phone"
    },
    status: {
      model: "status"
    },
    taxpayerIdentifier: {
      model: "taxpayerIdentifier"
    },
    companyName: {
      model: "companyName"
    },
    authorityName: {
      model: "authorityName"
    },
    thirdPartyInteriorBatchId: {
      model: "thirdPartyInteriorBatchId"
    },
    subjectStatus: {
      model: "subjectStatus"
    },
    employmentType: {
      model: "employmentType"
    },
    employedDate: {
      model: "employedDate"
    },
    submissionState: {
      model: "submissionState"
    },
    authenticationState: {
      model: "authenticationState"
    },
    resignDate: {
      model: "resignDate"
    },
    submissionDate: {
      model: "submissionDate"
    },
    reason: {
      model: "reason"
    },
    isComplete: {
      model: "isComplete"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "empName",
        title: "姓名"
      }, {
        model: "licenseNumber",
        title: "身份证号"
      }, {
        model: "phone",
        title: "手机号"
      }, {
        model: "status",
        title: "人员状态"
      }, {
        model: "taxpayerIdentifier",
        title: "纳税人识别号"
      }, {
        model: "companyName",
        title: "企业名称"
      }, {
        model: "authorityName",
        title: "税务分局"
      }, {
        model: "isComplete",
        title: "信息是否完备"
      }, {
        model: "employmentType",
        title: "任职受雇从业类型"
      }, {
        model: "employedDate",
        title: "任职受雇从业日期",
        dateFormat: "yyyy-MM-dd"
      }, {
        model: "submissionState",
        title: "报送状态"
      }, {
        model: "authenticationState",
        title: "身份验证状态"
      }, {
        model: "resignDate",
        title: "离职日期",
        dateFormat: "yyyy-MM-dd"
      }, {
        model: "submissionDate",
        title: "报送时间",
        dateFormat: "yyyy-MM-dd HH:mm:ss"
      }, {
        model: "reason",
        title: "错误原因"
      }],
      customize: {
        rowKey: "id"
        // pagination: {
        //   pageSize: 10
        // }
      },

      extend: {
        batch: true,
        action: false
      }
    }
  }
};