import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("a-layout", [_c("a-row", {
    staticClass: "head"
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  }), _c("div", {
    staticClass: "left2"
  }, [_vm._v("返回")])], 1), _c("a-col", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("编辑工资表模板")])])], 1), _c("a-row", {
    staticClass: "describeHead"
  }, [_c("a-col", [_c("span", {
    staticClass: "title"
  }, [_vm._v("使用指南")])]), _c("a-col", {
    staticStyle: {
      "margin-top": "13px"
    }
  }, [_c("span", {
    staticClass: "font"
  }, [_vm._v(" 设置工资表模板，即对应企业计薪时的月工资表头项目。每位员工只适用一个模板。 ")])]), _c("a-divider")], 1), _c("a-row", {
    staticClass: "inputBox"
  }, [_c("a-col", [_c("div", {
    staticClass: "inpputBoxTitle"
  }, [_vm._v("模板名称")])]), _c("a-col", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "337px"
    },
    attrs: {
      placeholder: "请输入",
      disabled: _vm.inputName === "默认模板"
    },
    model: {
      value: _vm.inputName,
      callback: function callback($$v) {
        _vm.inputName = $$v;
      },
      expression: "inputName"
    }
  })], 1)], 1), _c("a-row", {
    staticClass: "content"
  }, [_c("div", [_c("div", {
    staticClass: "flexBoxTitle"
  }, [_c("span", [_vm._v("项目模板设置")])]), _c("div", {
    staticStyle: {
      width: "100%",
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "little"
  }, [_vm._v("设置此工资表模板参与计薪的项目。您可以新增或移除工资表模板的项目")]), _c("a-button", {
    staticClass: "primaryButton",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleClick
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("新增项目 ")], 1)], 1)]), _c("a-col", [_c("a-col", {
    staticStyle: {
      "margin-top": "8px",
      "margin-bottom": "24px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "400",
      color: "#55565d"
    }
  }, [_vm._v("尾数处理(四舍五入)")]), _c("a-select", {
    staticStyle: {
      width: "300px",
      "margin-left": "12px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.decimalPlaces,
      callback: function callback($$v) {
        _vm.decimalPlaces = $$v;
      },
      expression: "decimalPlaces"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 0
    }
  }, [_vm._v(" 保留为整数 ")]), _c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 保留到小数点后1位 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 保留到小数点后2位 ")]), _c("a-select-option", {
    attrs: {
      value: 3
    }
  }, [_vm._v(" 保留到小数点后3位 ")]), _c("a-select-option", {
    attrs: {
      value: 4
    }
  }, [_vm._v(" 保留到小数点后4位 ")])], 1)], 1)]), _c("a-table", {
    attrs: {
      columns: _vm.columns,
      pagination: false,
      "row-key": "id",
      "data-source": _vm.dataList,
      loading: _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(text) {
        return _c("div", {}, [_vm._v(" " + _vm._s(text) + " "), text === "定薪工资" ? _c("a-tooltip", [_c("template", {
          slot: "title"
        }, [_vm._v(" 定薪工资:"), _c("br"), _vm._v("是该员工定薪项目的合计（基本工资、各种补贴等）该项数值将自动从员工薪资档案中获取 ")]), _c("a-icon", {
          attrs: {
            type: "question-circle"
          }
        })], 2) : _vm._e()], 1);
      }
    }, {
      key: "isAdd",
      fn: function fn(text) {
        return _c("div", {}, [text === 0 ? _c("span", [_vm._v("非工资计算项")]) : _vm._e(), text === 1 ? _c("span", [_vm._v("工资增加项")]) : _vm._e(), text === 2 ? _c("span", [_vm._v("工资减少项")]) : _vm._e()]);
      }
    }, {
      key: "isUse",
      fn: function fn(text, record) {
        return _c("div", {}, [_c("a-tooltip", [_c("a-checkbox", {
          attrs: {
            disabled: record.name === "应发工资" || record.name === "实发工资" || record.name === "个人社保" || record.name === "企业社保" || record.name === "个人公积金" || record.name === "企业公积金" || record.name === "个人所得税"
          },
          on: {
            change: _vm.checkChange
          },
          model: {
            value: record.isUse,
            callback: function callback($$v) {
              _vm.$set(record, "isUse", $$v);
            },
            expression: "record.isUse"
          }
        })], 1), record.isDefault === false ? _c("span", {
          staticClass: "actionBox"
        }, [_c("a-icon", {
          staticClass: "iconStyle",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            type: "form"
          },
          on: {
            click: function click($event) {
              return _vm.handleEditClick(record);
            }
          }
        }), _c("a-popconfirm", {
          attrs: {
            title: "确定删除" + record.name + "?"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.handleDeleteClick(record);
            }
          }
        }, [_c("a-icon", {
          staticClass: "iconStyle",
          attrs: {
            type: "delete"
          }
        })], 1)], 1) : _vm._e()], 1);
      }
    }])
  })], 1)], 1), _c("a-row", {
    staticClass: "file-foot-save",
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("a-col", {
    staticClass: "fr"
  }, [_c("a-button", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("保存")])], 1)], 1), _c("a-modal", {
    attrs: {
      title: "添加自定义项目",
      width: "570px"
    },
    on: {
      ok: _vm.handleOk
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    staticStyle: {
      "margin-left": "30px"
    },
    attrs: {
      prop: "form",
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "name",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      label: "项目名称",
      prop: "name",
      rules: {
        required: true,
        message: "项目名称必填"
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      "margin-left": "15px",
      width: "300px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("a-form-model-item", {
    ref: "mode",
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      label: "工资加减项",
      prop: "mode",
      rules: {
        required: true,
        message: "工资加减项必选"
      }
    }
  }, [_c("a-radio-group", {
    staticStyle: {
      "margin-left": "20px"
    },
    model: {
      value: _vm.form.mode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mode", $$v);
      },
      expression: "form.mode"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("工资增加项")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("工资减少项")]), _c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v("非工资计算项")])], 1)], 1), _c("a-form-model-item", {
    ref: "isTaxCalculation",
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      label: "是否计税",
      prop: "isTaxCalculation",
      rules: {
        required: true,
        message: "是否计税必选"
      }
    }
  }, [_c("a-radio-group", {
    staticStyle: {
      "margin-left": "20px"
    },
    model: {
      value: _vm.form.isTaxCalculation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isTaxCalculation", $$v);
      },
      expression: "form.isTaxCalculation"
    }
  }, [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_vm._v("计税")]), _c("a-radio", {
    attrs: {
      value: false
    }
  }, [_vm._v("不计税")])], 1)], 1)], 1), _c("a-layout")], 1), _c("a-modal", {
    attrs: {
      title: "编辑自定义项目",
      width: "570px"
    },
    on: {
      ok: _vm.handleEditOk
    },
    model: {
      value: _vm.editVisible,
      callback: function callback($$v) {
        _vm.editVisible = $$v;
      },
      expression: "editVisible"
    }
  }, [_c("a-form-model", {
    ref: "ruleEditForm",
    staticStyle: {
      "margin-left": "30px"
    },
    attrs: {
      prop: "editList",
      model: _vm.editList,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    ref: "name",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      label: "项目名称",
      prop: "name",
      rules: {
        required: true,
        message: "项目名称必填"
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      "margin-left": "15px",
      width: "300px"
    },
    attrs: {
      placeholder: "请输入"
    },
    on: {
      change: _vm.inputChange
    },
    model: {
      value: _vm.editList.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editList, "name", $$v);
      },
      expression: "editList.name"
    }
  })], 1), _c("a-form-model-item", {
    ref: "isAdd",
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      label: "工资加减项",
      prop: "isAdd",
      rules: {
        required: true,
        message: "工资加减项必选"
      }
    }
  }, [_c("a-radio-group", {
    staticStyle: {
      "margin-left": "20px"
    },
    model: {
      value: _vm.editList.isAdd,
      callback: function callback($$v) {
        _vm.$set(_vm.editList, "isAdd", $$v);
      },
      expression: "editList.isAdd"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("工资增加项")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("工资减少项")]), _c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v("非工资计算项")])], 1)], 1), _c("a-form-model-item", {
    ref: "isTaxCalculation",
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      label: "是否计税",
      prop: "isTaxCalculation",
      rules: {
        required: true,
        message: "是否计税必选"
      }
    }
  }, [_c("a-radio-group", {
    staticStyle: {
      "margin-left": "20px"
    },
    model: {
      value: _vm.editList.isTaxCalculation,
      callback: function callback($$v) {
        _vm.$set(_vm.editList, "isTaxCalculation", $$v);
      },
      expression: "editList.isTaxCalculation"
    }
  }, [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_vm._v("计税")]), _c("a-radio", {
    attrs: {
      value: false
    }
  }, [_vm._v("不计税")])], 1)], 1)], 1), _c("a-layout")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };