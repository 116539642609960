import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from "vuex";
import rTitle from "../component/rTitle";
import interview from "./component/interview";
import resume from "./component/resume";
import tables from "./component/table";
export default {
  components: {
    rTitle: rTitle,
    interview: interview,
    resume: resume,
    tables: tables
  },
  data: function data() {
    return {
      heights: 0,
      numArr: [{
        type: "1",
        name: "待处理",
        num: 0
      }, {
        type: "2",
        name: "已投递",
        num: 0
      }, {
        type: "3",
        name: "已约面",
        num: 0
      }, {
        type: "4",
        name: "offer沟通",
        num: 0
      }, {
        type: "5",
        name: "已入职",
        num: 0
      }]
    };
  },
  computed: _objectSpread({}, mapState(["admin", "hr"])),
  created: function created() {
    this.$store.commit("recruitment/isCancel", null);
  },
  mounted: function mounted() {
    this.heights = document.querySelector(".main-content").offsetHeight;
  },
  methods: {
    handleChange: function handleChange() {},
    callback: function callback() {}
  }
};