var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mb24 flex-center-between"
  }, [_c("div", {
    staticClass: "bgc-white br4",
    staticStyle: {
      flex: "1"
    }
  }, [_c("div", {
    staticClass: "pv16 ph24 flex-center pointer",
    on: {
      click: function click($event) {
        return _vm.handleClick("all", 1);
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: require("./images/overview_all.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px"
    }
  }, [_vm._v("全部合同")]), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.summary.allCount || 0))])])]), _c("div", {
    staticClass: "ph24 pointer",
    staticStyle: {
      "border-top": "1px solid rgba(232, 232, 232, 1)",
      "line-height": "32px"
    },
    on: {
      click: function click($event) {
        return _vm.handleClick("all", 2);
      }
    }
  }, [_c("span", {
    staticClass: "fs12 mr4",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("即将到期")]), _c("span", {
    staticClass: "tc-theme fs12"
  }, [_vm._v(_vm._s(_vm.summary.willExpireCount))])])]), _c("div", {
    staticClass: "bgc-white br4 ml24 pointer",
    staticStyle: {
      flex: "1",
      "min-height": "112px"
    },
    on: {
      click: function click($event) {
        return _vm.handleClick("paper", 4);
      }
    }
  }, [_c("div", {
    staticClass: "pv16 ph24 flex-center"
  }, [_c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: require("./images/overview_paper.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px"
    }
  }, [_vm._v("纸质合同")]), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.summary.paperCount || 0))])])])]), _c("div", {
    staticClass: "bgc-white br4 ml24",
    staticStyle: {
      flex: "1",
      "min-width": "350px",
      "flex-shrink": "0"
    }
  }, [_c("div", {
    staticClass: "pv16 ph24 flex-center",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.handleClick("electronic", 4);
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: require("./images/overview_electronic.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticStyle: {
      color: "rgba(118, 120, 133, 1)",
      "line-height": "14px"
    }
  }, [_vm._v("电子合同")]), _c("div", {
    staticClass: "mt8 fs24 fw500",
    staticStyle: {
      color: "rgba(85, 86, 93, 1)",
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.summaryEle.allContractCount || 0))])])]), _c("div", {
    staticClass: "ph24 flex-center",
    staticStyle: {
      "border-top": "1px solid rgba(232, 232, 232, 1)",
      "line-height": "32px"
    }
  }, [_c("div", {
    staticClass: "mr16",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.handleClick("electronic", 1);
      }
    }
  }, [_c("span", {
    staticClass: "fs12 mr4",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("待员工签署")]), _c("span", {
    staticClass: "tc-theme fs12"
  }, [_vm._v(_vm._s(_vm.summaryEle.toSignedByEmpCount))])]), _c("div", {
    staticClass: "mr16",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.handleClick("electronic", 2);
      }
    }
  }, [_c("span", {
    staticClass: "fs12 mr4",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)"
    }
  }, [_vm._v("待公司签署")]), _c("span", {
    staticClass: "tc-theme fs12"
  }, [_vm._v(_vm._s(_vm.summaryEle.toSignedByComCount))])]), _c("div", [_c("span", {
    staticClass: "fs12 mr4",
    staticStyle: {
      color: "rgba(166, 168, 180, 1)",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.handleClick("electronic", 3);
      }
    }
  }, [_vm._v("签署完成")]), _c("span", {
    staticClass: "tc-theme fs12"
  }, [_vm._v(_vm._s(_vm.summaryEle.signedCompleteCount))])])])]), _c("div", {
    staticClass: "br8 ml24 bgc-theme flex-center",
    staticStyle: {
      width: "280px",
      "flex-shrink": "0",
      "min-height": "112px",
      "background-image": "url(./images/bg.png)"
    }
  }, [_c("div", {
    staticClass: "pv16 ph24 flex-center"
  }, [_c("img", {
    staticStyle: {
      width: "31px",
      height: "36px"
    },
    attrs: {
      src: require("./images/overview_left.png")
    }
  }), _c("div", {
    staticClass: "ml12"
  }, [_c("div", {
    staticClass: "tc-white",
    staticStyle: {
      "line-height": "14px"
    }
  }, [_vm._v("电子合同余额（份）")]), _c("div", {
    staticClass: "mt8 fs24 tc-white fw500",
    staticStyle: {
      "line-height": "24px"
    }
  }, [_vm._v(_vm._s(_vm.summaryEle.quotaRemain || 0))])])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };