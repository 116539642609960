import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { mapState, mapGetters } from "vuex";
import SubMenu from "./components/SubMenuNew";
import Item from "./components/itemNew";
export default {
  name: "Sidebar",
  components: {
    SubMenu: SubMenu,
    Item: Item
  },
  props: {
    aMenu: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  inject: ["guidMenu"],
  data: function data() {
    return {
      hasLogo: true,
      selectedKeys: [],
      openKeys: []
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(["sidebar", "permissions_menu", "permissions_menu_tree"])), mapState(["hr"])), {}, {
    isCollapsed: function isCollapsed() {
      return !this.sidebar.opened;
    },
    hideMenu: function hideMenu() {
      var box = [];
      if (process.env.NODE_ENV === "development") {
        box = [];
      }
      return box;
    },
    getGuidMenu: function getGuidMenu() {
      return this.guidMenu();
    }
  }),
  watch: {
    aMenu: function aMenu() {
      var _this = this;
      setTimeout(function () {
        _this.defaultMenu();
      }, 500);
    },
    getGuidMenu: function getGuidMenu(val) {
      // 注意这里不能使用lampda表达式，否则this无效!!!!!!
      if (val) {
        this.selectedKeys = [val];
      }
    }
    // $route: {
    //   handler(to, from) {
    //     console.log(to);
    //     console.log(from);
    //     console.log(this.$route.path);
    //   },
    //   immediate: true
    // }
  },
  created: function created() {
    var _this2 = this;
    setTimeout(function () {
      _this2.defaultMenu();
    }, 500);
  },
  methods: {
    defaultMenu: function defaultMenu() {
      var r = this.aMenu;
      if (r.length > 0) {
        if (this.$route.path === "/approvalAdmin/setting") {
          this.selectedKeys = ["/approvalAdmin/setting"];
          this.openKeys = ["/contractAdmin"];
        } else if (this.$route.path === "/shangyebaoxian/client") {
          this.selectedKeys = ["/shangyebaoxian/client"];
        } else if (this.$route.path === "/recruitment/talent/msg") {
          this.selectedKeys = ["/recruitment/talent"];
        } else if (this.$route.path === "/admin/kehufuwufeijilu/upload") {
          this.openKeys = ["/admin/xiaoshou"];
          this.selectedKeys = ["/admin/kehufuwufeijilu"];
        } else if (this.$route.path === "/admin/add_employment_unit") {
          this.openKeys = ["/admin/company_management"];
          this.selectedKeys = ["/admin/employment_unit"];
        } else if (this.$route.path === "/admin/zhidugongshi/edit") {
          this.openKeys = ["/admin/jiafangduan"];
          this.selectedKeys = ["/admin/zhidugongshi"];
        } else if (this.$route.path === "/admin/zhengcesudi/edit") {
          this.openKeys = ["/admin/jiafangduan"];
          this.selectedKeys = ["/admin/zhengcesudi"];
        } else if (this.$route.path === "/settlementAdmin/record/details") {
          this.openKeys = ["/customerCost"];
          this.selectedKeys = ["/settlementAdmin/record"];
        } else if (this.$route.path === "/settlementAdmin/kehumingxibiao/customerDetail") {
          this.openKeys = ["/customerCost"];
          this.selectedKeys = ["/settlementAdmin/kehumingxibiao"];
        } else {
          this.selectedKeys = [this.$route.path];
          this.defaultOpenKeys();
          if (this.$route.meta.routeSession) {
            var routeSession = this.$route.meta.routeSession;
            var storage = sessionStorage.getItem(routeSession);
            if (storage) {
              this.selectedKeys = [storage];
            } else {
              this.selectedKeys = [this.$route.meta.route];
            }
          }
        }
        if (sessionStorage.getItem("guid")) {
          this.selectedKeys = [sessionStorage.getItem("guid")];
          sessionStorage.setItem("guid", undefined);
          this.openKeys = [r[0].router];
        }
      }
    },
    defaultOpenKeys: function defaultOpenKeys() {
      var _this3 = this,
        _routeJson$;
      var lock = false;
      var routeJson = this.aMenu.filter(function (e) {
        if (e.router === _this3.$route.path) {
          return e;
        } else {
          if (e.children && e.children.length > 0) {
            e.children.some(function (s) {
              if (s.router === _this3.$route.path) {
                lock = true;
                return false;
              }
            });
          }
          if (lock) return e;
        }
      });
      if (routeJson.length > 0 && (_routeJson$ = routeJson[0]) !== null && _routeJson$ !== void 0 && _routeJson$.children) this.openKeys = [routeJson[0].router];
    }
  }
};