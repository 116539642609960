import request from "@/utils/request";
export function getCompanyBrokerInfoById() {
  return request({
    url: "/recruit2/api/v1/companyUnits/getCompanyBrokerInfoById",
    method: "GET"
  });
}
export function getBrokerPageById(params) {
  return request({
    url: "/recruit2/api/v1/companyUnits/getBrokerPageById",
    method: "GET",
    params: params
  });
}
export function getFindBrokerJobSeekerById(params) {
  return request({
    url: "/recruit2/api/v1/brokers/findBrokerJobSeekerById",
    method: "GET",
    params: params
  });
}
export function postSaveWithPC(data) {
  return request({
    url: "/recruit2/api/v1/brokers/saveWithPC",
    method: "post",
    data: data
  });
}
export function putBrokers(data) {
  return request({
    url: "/recruit2/api/v1/brokers",
    method: "put",
    data: data
  });
}

// export function delCompanyUnits(companyUnitsId) {
//   return request({
//     url: `/recruit2/api/v1/companyUnits/${companyUnitsId}`,
//     method: "delete"
//   });
// }
export function delBrokerId(brokerId) {
  return request({
    url: "/recruit2/api/v1/brokers/".concat(brokerId),
    method: "delete"
  });
}
export function getChannels(params) {
  return request({
    // url: "hr/api/v1/channels/listAll",
    url: "/hr/api/v1/channels/listAllNoSynchronizationBroker",
    method: "GET",
    params: params
  });
}
export function postSynchronizationBroker(data) {
  return request({
    url: "/hr/api/v1/channels/synchronizationBroker",
    method: "post",
    data: data
  });
}
export function getUsers(params) {
  return request({
    url: "/hr/api/v1/users/selectHadRoleUserIdBySiteId",
    method: "GET",
    params: params
  });
}