export default {
  fields: {
    empInfoName: {
      model: "empInfoName"
    },
    positionName: {
      model: "positionName"
    },
    attendPlanName: {
      model: "attendPlanName",
      width: 200
    },
    shouldDayCount: {
      model: "shouldDayCount"
    },
    normalDay: {
      model: "normalDay"
    },
    unusualDay: {
      model: "unusualDay"
    },
    workDate: {
      model: "workDate"
    },
    unusualCount: {
      model: "unusualCount"
    },
    beLateCount: {
      model: "beLateCount"
    },
    beLateTime: {
      model: "beLateTime"
    },
    leaveEarlyCount: {
      model: "leaveEarlyCount"
    },
    leaveEarlyTime: {
      model: "leaveEarlyTime"
    },
    lackCount: {
      model: "lackCount"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "empInfoName",
        title: "姓名",
        colDefault: true,
        isEdit: false,
        fixed: "left",
        width: 150
      }, {
        model: "positionName",
        title: "岗位",
        colDefault: true,
        isEdit: false,
        width: 150
      }, {
        model: "attendPlanName",
        title: "考勤规则",
        colDefault: true,
        width: 150
      }, {
        model: "shouldDayCount",
        title: "应打卡天数",
        colDefault: true,
        width: 150
      }, {
        model: "normalDay",
        title: "正常天数",
        colDefault: true,
        width: 150
      }, {
        model: "unusualDay",
        title: "异常天数",
        colDefault: true,
        width: 150
      }, {
        model: "workDate",
        title: "工作时长",
        colDefault: true,
        width: 150
      }, {
        model: "unusualCount",
        title: "异常合计",
        colDefault: true,
        width: 150
      }, {
        model: "beLateCount",
        title: "迟到次数",
        colDefault: true
      }, {
        model: "beLateTime",
        title: "迟到时长",
        colDefault: true,
        width: 150
      }, {
        model: "leaveEarlyCount",
        title: "早退次数",
        colDefault: true,
        width: 150
      }, {
        model: "leaveEarlyTime",
        title: "早退时长",
        colDefault: true,
        width: 150
      }, {
        model: "lackCount",
        title: "缺卡次数",
        colDefault: true,
        width: 150
      }],
      customize: {
        rowKey: "empInfoId"
      },
      extend: {
        batch: true,
        action: true
      }
    }
  }
};