var state = {
  screeningVisible: false,
  tableHeight: 0
};
var mutations = {
  screeningVisible: function screeningVisible(state, payload) {
    state.screeningVisible = payload;
  },
  tableHeight: function tableHeight(state, payload) {
    state.tableHeight = payload;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};