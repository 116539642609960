import "core-js/modules/es.array.push.js";
import temp from "./template";
export default {
  components: {
    temp: temp
  },
  data: function data() {
    return {
      defaultSelectedKeys: ["模板设置"],
      menuList: [{
        title: "模板设置"
      }]
    };
  },
  methods: {
    // 跳转单页
    goList: function goList(i, meta) {
      this.$nextTick;
      this.$router.push(this.menuList[i]);
      this.meta = meta;
    }
  }
};