export var politicalStatusOptions = [{
  value: 1,
  label: "中共党员"
}, {
  value: 6,
  label: "预备党员"
}, {
  value: 5,
  label: "积极分子"
}, {
  value: 2,
  label: "共青团员"
}, {
  value: 3,
  label: "普通民众/群众"
}, {
  value: 4,
  label: "其它"
}];
var politicalStatusDict = {
  1: "中共党员",
  6: "预备党员",
  5: "积极分子",
  2: "共青团员",
  3: "普通民众/群众",
  4: "其它"
};
export function renderPoliticalStatus(val) {
  return politicalStatusDict[val];
}
export var columnsOld = [{
  title: "姓名",
  dataIndex: "name",
  ellipsis: true,
  colDefault: true,
  isEdit: false,
  width: 150
}, {
  title: "手机号",
  dataIndex: "phone",
  ellipsis: true,
  colDefault: true,
  width: 150
}, {
  title: "入职时间",
  dataIndex: "joinDate",
  ellipsis: true,
  scopedSlots: {
    customRender: "joinDate"
  },
  colDefault: true,
  width: 150
}, {
  title: "工作性质",
  dataIndex: "postType",
  ellipsis: true,
  scopedSlots: {
    customRender: "postType"
  },
  colDefault: true,
  width: 150
}, {
  title: "员工状态",
  dataIndex: "formalType",
  key: "formalType",
  scopedSlots: {
    customRender: "formalType"
  },
  colDefault: true,
  width: 150
}, {
  title: "合同签署状态",
  dataIndex: "contractsStatus",
  key: "contractsStatus",
  ellipsis: true,
  colDefault: true,
  scopedSlots: {
    customRender: "contractsStatus"
  },
  width: 150
}, {
  title: "信息补充（必填项）",
  dataIndex: "isImprove",
  ellipsis: true,
  colDefault: true,
  scopedSlots: {
    customRender: "isImprove"
  },
  width: 150
}, {
  title: "实名认证",
  dataIndex: "verified",
  ellipsis: true,
  colDefault: true,
  scopedSlots: {
    customRender: "verified"
  },
  width: 150
}, {
  title: "身份证号",
  dataIndex: "idCard",
  ellipsis: true,
  width: 200
}, {
  title: "性别",
  dataIndex: "gender",
  ellipsis: true,
  scopedSlots: {
    customRender: "gender"
  },
  width: 150
}, {
  title: "部门",
  dataIndex: "depName",
  ellipsis: true,
  width: 150
}, {
  title: "出生日期",
  dataIndex: "birthday",
  ellipsis: true,
  scopedSlots: {
    customRender: "birthday"
  },
  width: 150
}, {
  title: "年龄",
  dataIndex: "empAge",
  ellipsis: true,
  width: 150
}, {
  title: "婚姻状况",
  dataIndex: "maritalStatus",
  ellipsis: true,
  scopedSlots: {
    customRender: "maritalStatus"
  },
  width: 150
}, {
  title: "生育状况",
  dataIndex: "birthStatus",
  key: "birthStatus",
  scopedSlots: {
    customRender: "birthStatus"
  },
  width: 150
}, {
  title: "民族",
  dataIndex: "nation",
  ellipsis: true,
  width: 150
}, {
  title: "政治面貌",
  dataIndex: "politicalStatus",
  ellipsis: true,
  scopedSlots: {
    customRender: "politicalStatus"
  },
  width: 150
}, {
  title: "户口性质",
  dataIndex: "regType",
  ellipsis: true,
  scopedSlots: {
    customRender: "regType"
  },
  width: 150
}, {
  title: "户籍地址",
  dataIndex: "nativeLocation",
  ellipsis: true,
  width: 150
}, {
  title: "血型",
  dataIndex: "bloodGroup",
  ellipsis: true,
  scopedSlots: {
    customRender: "bloodGroup"
  },
  width: 150
}, {
  title: "个人邮箱",
  dataIndex: "email",
  ellipsis: true,
  width: 200
}, {
  title: "现居地址",
  dataIndex: "currentLocation",
  ellipsis: true,
  width: 150
}, {
  title: "学历",
  dataIndex: "highestEducation",
  scopedSlots: {
    customRender: "highestEducation"
  },
  ellipsis: true,
  width: 150
}, {
  title: "学位",
  dataIndex: "degreeType",
  ellipsis: true,
  scopedSlots: {
    customRender: "degreeType"
  },
  width: 150
}, {
  title: "毕业院校",
  dataIndex: "graCollege",
  ellipsis: true,
  width: 150
}, {
  title: "毕业专业",
  dataIndex: "major",
  ellipsis: true,
  width: 150
}, {
  title: "学习形式",
  dataIndex: "teaMethods",
  ellipsis: true,
  scopedSlots: {
    customRender: "teaMethods"
  },
  width: 150
}, {
  title: "联系人姓名(紧急联系人)",
  dataIndex: "contactsName",
  ellipsis: true,
  width: 150
}, {
  title: "联系电话(紧急联系人)",
  dataIndex: "contactsPhone",
  ellipsis: true,
  width: 150
}, {
  title: "与我的关系(紧急联系人)",
  dataIndex: "contactsRelation",
  ellipsis: true,
  width: 150
}, {
  title: "联系人地址(紧急联系人)",
  dataIndex: "contactsAddress",
  ellipsis: true,
  width: 150
}, {
  title: "工资卡卡号",
  dataIndex: "bankCard",
  ellipsis: true,
  width: 200
}, {
  title: "银行名称",
  dataIndex: "bankName",
  ellipsis: true,
  width: 150
}, {
  title: "工资卡开户城市",
  dataIndex: "bankPlace",
  scopedSlots: {
    customRender: "bankPlace"
  },
  ellipsis: true,
  width: 150
}, {
  title: "工资卡开户支行",
  dataIndex: "bankCardBranch",
  ellipsis: true,
  width: 150
}, {
  title: "个人社保账号",
  dataIndex: "socCard",
  ellipsis: true,
  width: 150
}, {
  title: "个人公积金账号",
  dataIndex: "pfCard",
  ellipsis: true,
  width: 150
}, {
  title: "合同类型",
  dataIndex: "contractType",
  ellipsis: true,
  scopedSlots: {
    customRender: "contractType"
  },
  width: 150
}, {
  title: "合同开始时间",
  dataIndex: "contractStartTime",
  scopedSlots: {
    customRender: "contractStartTime"
  },
  ellipsis: true,
  width: 150
}, {
  title: "合同结束时间",
  dataIndex: "contractEndTime",
  scopedSlots: {
    customRender: "contractEndTime"
  },
  ellipsis: true,
  width: 150
}, {
  title: "合同期限",
  dataIndex: "contactsTerm",
  ellipsis: true,
  width: 150
}, {
  title: "备注",
  dataIndex: "remark",
  ellipsis: true,
  width: 150
}, {
  title: "标签",
  dataIndex: "tag",
  width: 150,
  key: "tag",
  scopedSlots: {
    customRender: "tag"
  }
}, {
  title: "渠道",
  dataIndex: "channelName",
  ellipsis: true,
  width: 150
}, {
  title: "工作地点",
  dataIndex: "workCity",
  ellipsis: true,
  width: 150
}, {
  title: "签约主体",
  dataIndex: "contractSubject",
  ellipsis: true,
  width: 150
}, {
  title: "操作",
  dataIndex: "action",
  ellipsis: true,
  align: "center",
  fixed: "right",
  scopedSlots: {
    customRender: "action"
  },
  width: 150
}];