export function buildShortcuts() {
  var initRes = [{
    title: "人事管理",
    icon: require("./images/i1.png"),
    items: [{
      title: "花名册",
      icon: require("./images/h1.png"),
      desc: "员工档案云储存，员工关系管理",
      items: [{
        title: "添加员工",
        type: "tianjiayuangong",
        url: "/staff/employee"
      }, {
        title: "添加待入职员工",
        type: "tianjiadairuzhiyuangong",
        url: "/staff/employee"
      }, {
        title: "扫码入职",
        type: "saomaruzhi",
        url: "/staff/employee"
      }, {
        title: "批量导入",
        type: "piliangdaoru",
        url: "/staff/employee"
      }, {
        title: "今日新增",
        type: "jinrixinzeng",
        url: "/staff/employee/todayAdd",
        menu: "/staff/employee"
      }]
    }, {
      title: "员工异动",
      icon: require("./images/h2.png"),
      desc: "对公司员工进行在线的管理",
      items: [{
        title: "办理入职",
        type: "rzdl",
        url: "/staff/manger?selectId=1",
        menu: "/staff/manger"
      }, {
        title: "办理转正",
        type: "rzdl",
        url: "/staff/manger?selectId=2",
        menu: "/staff/manger"
      }, {
        title: "办理离职",
        type: "rzdl",
        url: "/staff/manger?selectId=4",
        menu: "/staff/manger"
      }, {
        title: "办理调岗",
        type: "rzdl",
        url: "/staff/manger?selectId=5",
        menu: "/staff/manger"
      }]
    }, {
      title: "员工设置",
      icon: require("./images/h3.png"),
      desc: "设置花名册相关字段、模板",
      items: [{
        title: "个人信息设置",
        type: "gerenxinxi",
        url: "/staff/personalInfo/1",
        menu: "/staff/edit"
      }, {
        title: "在职信息设置",
        type: "zaizhixinxi",
        url: "/staff/zaizhixinxi/4",
        menu: "/staff/edit"
      }, {
        title: "工资社保设置",
        type: "gongzishebao",
        url: "/staff/socialSecurity/2",
        menu: "/staff/edit"
      }, {
        title: "材料附件设置",
        type: "cailiaofujian",
        url: "/staff/information/3",
        menu: "/staff/edit"
      }, {
        title: "入职模板设置",
        type: "ruzhimuban",
        url: "/staff/entryTemplateSet/4",
        menu: "/staff/edit"
      }, {
        title: "待入职模板设置",
        type: "dairuzhimuban",
        url: "/staff/hiredEntryTemplateSet/5",
        menu: "/staff/edit"
      }]
    }]
  }, {
    title: "组织架构",
    icon: require("./images/i2.png"),
    items: [{
      title: "部门管理",
      icon: require("./images/h4.png"),
      desc: "高效维护企业的部门结构",
      items: [{
        title: "创建部门",
        type: "chuangjianbumen",
        url: "/organization/organization?page=department",
        menu: "/organization/organization"
      }, {
        title: "添加部门人员",
        type: "tianjiabumenrenyuan",
        url: "/organization/organization"
      }]
    }, {
      title: "岗位管理",
      icon: require("./images/h5.png"),
      desc: "管理公司内的岗位体系",
      items: [{
        title: "创建岗位",
        type: "chuangjiangangwei",
        url: "/organization/org"
      }]
    }]
  }, {
    title: "合同管理",
    icon: require("./images/i3.png"),
    items: [{
      title: "签署记录",
      icon: require("./images/h6.png"),
      desc: "查看公司内的合同签署记录",
      items: [{
        title: "电子合同记录",
        type: "dianzihetong",
        url: "/contract/elecontract?tab=electronic",
        menu: "/contract/elecontract"
      }, {
        title: "纸质合同记录",
        type: "zhizhihetong",
        url: "/contract/elecontract?tab=paper",
        menu: "/contract/elecontract"
      }, {
        title: "全部合同记录",
        type: "quanbuhetong",
        url: "/contract/elecontract?tab=all",
        menu: "/contract/elecontract"
      }]
    }, {
      title: "发起合同",
      icon: require("./images/h7.png"),
      desc: "在线发起合同",
      items: [{
        title: "创建合同模板",
        type: "chuangjianhetongmuban",
        url: "/contract/type"
      }, {
        title: "发起合同",
        type: "faqihetong",
        url: "/contract/type"
      }]
    }, {
      title: "印章管理",
      icon: require("./images/h8.png"),
      desc: "管理公司内的印章",
      items: [{
        title: "在线创建印章",
        type: "zaixianchuangjianyinzhang",
        url: "/contract/stamp/create",
        menu: "/contract/stamp"
      }, {
        title: "上传印章",
        type: "shangchuanyinzhang",
        url: "/contract/stamp"
      }]
    }]
  }, {
    title: "审批管理",
    icon: require("./images/i4.png"),
    items: [{
      title: "审批列表",
      icon: require("./images/h9.png"),
      desc: "简单、实用的流程审批工具",
      items: [{
        title: "待处理",
        type: "daichuli",
        url: "/approval/list"
      }, {
        title: "已处理",
        type: "yichuli",
        url: "/approval/list"
      }, {
        title: "我发起",
        type: "wofaqi",
        url: "/approval/list"
      }, {
        title: "抄送我",
        type: "chaosongwo",
        url: "/approval/list"
      }]
    }, {
      title: "审批设置",
      icon: require("./images/h10.png"),
      desc: "支持多种条件配置不同的审批流程",
      items: [{
        title: "创建审批分组",
        type: "chuangjianshenpifenzu",
        url: "/approval/setting"
      }, {
        title: "创建审批模板",
        type: "chuangjianshenpimuban",
        url: "/newApproval",
        menu: "/approval/setting"
      }]
    }]
  }, {
    title: "消息设置",
    icon: require("./images/i5.png"),
    items: [{
      title: "人事提醒",
      icon: require("./images/h11.png"),
      desc: "设置人事相关的消息通知",
      items: [],
      url: "/message/setting",
      type: "人事管理"
    }, {
      title: "审批提醒",
      icon: require("./images/h12.png"),
      desc: "设置审批相关的消息通知",
      items: [],
      url: "/message/setting",
      type: "业务审批"
    }, {
      title: "合同提醒",
      icon: require("./images/h13.png"),
      desc: "设置合同相关的消息通知",
      items: [],
      url: "/message/setting",
      type: "合同管理"
    }]
  }, {
    title: "通知公告",
    icon: require("./images/i6.png"),
    items: [{
      title: "企业公告",
      icon: require("./images/h14.png"),
      desc: "发布、管理企业公告",
      items: [{
        title: "新建公告",
        type: "xinjiangonggao",
        url: "/notice/enterprise/createNotice?sign=0",
        menu: "/notice/enterprise"
      }]
    }, {
      title: "个人通知",
      icon: require("./images/h15.png"),
      desc: "发布、管理个人通知",
      items: [{
        title: "新建通知",
        type: "xinjiantongzhi",
        url: "/notice/person/createNotice?sign=0",
        menu: "/notice/person"
      }]
    }]
  }];
  return initRes;
}