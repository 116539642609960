import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "material_emp antWordWrapStyle page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goback
    }
  }, [_vm._v(_vm._s(_vm.employInfo.name) + "个人档案")]), _c("a-layout", [_c("a-layout-sider", {
    staticClass: "border-r-line ta-center",
    attrs: {
      width: "226"
    }
  }, [_c("div", {
    staticClass: "photoBox"
  }, [_c("a-row", [_c("UploadAvatar", {
    attrs: {
      "default-value": _vm.employInfo.empPhoto === null ? require("../../../../assets/icons/persony.png") : _vm.employInfo.empPhoto,
      "border-radius": "50%"
    },
    on: {
      change: _vm.onChange
    }
  })], 1), _c("a-row", [_c("span", {
    staticClass: "h4"
  }, [_vm._v(_vm._s(_vm.employInfo.name))])]), _c("a-row", {
    staticClass: "header-box",
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("a-tag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.employInfo.verified,
      expression: "employInfo.verified"
    }],
    staticStyle: {
      "margin-bottom": "4px"
    },
    attrs: {
      color: _vm.primary_theme
    }
  }, [_vm._v("已认证")]), _c("a-tag", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.employInfo.verified,
      expression: "!employInfo.verified"
    }],
    staticStyle: {
      "margin-bottom": "4px"
    }
  }, [_vm._v("未认证")]), _vm.employInfo.status === 1 ? _c("a-tag", {
    staticStyle: {
      "margin-bottom": "4px"
    },
    attrs: {
      color: "orange"
    }
  }, [_vm._v("待入职")]) : _vm.employInfo.status === 4 ? _c("a-tag", {
    staticStyle: {
      "margin-bottom": "4px"
    },
    attrs: {
      color: "orange"
    }
  }, [_vm._v("离职")]) : _vm.employInfo.status === 2 ? _c("a-tag", {
    staticStyle: {
      "margin-bottom": "4px"
    },
    attrs: {
      color: _vm.primary_theme
    }
  }, [_vm._v("在职")]) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.$route.query.isDelete || _vm.$route.query.userId,
      expression: "!$route.query.isDelete || $route.query.userId"
    }]
  }, [_c("i", {
    staticClass: "iconfont icon-weixin",
    class: _vm.WXuserInfo.isBindWechat ? "binding" : ""
  }), _vm._v(" " + _vm._s(_vm.WXuserInfo.isBindWechat ? "已绑定" : "未绑定") + " "), _c("span", {
    staticClass: "bindingText",
    on: {
      click: _vm.reset
    }
  }, [_vm._v(_vm._s(_vm.WXuserInfo.isBindWechat ? "解绑" : ""))])])], 1)], 1), _c("a-row", {
    staticClass: "header-box"
  }, [_c("RemarkLabel", {
    attrs: {
      remarkData: _vm.remarkData,
      labelData: _vm.labelData
    }
  })], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.$route.query.isDelete,
      expression: "!$route.query.isDelete"
    }],
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.switchCompany
    }
  }, [_vm._v("更换用工单位")])], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.$route.query.isDelete,
      expression: "!$route.query.isDelete"
    }],
    attrs: {
      size: "large",
      disabled: _vm.employInfo.status != 2
    },
    on: {
      click: _vm.merberLeave
    }
  }, [_vm._v("办理离职")])], 1), _c("a-row", [_c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.$route.query.isDelete,
      expression: "!$route.query.isDelete"
    }],
    attrs: {
      size: "large",
      disabled: _vm.urr != null
    },
    on: {
      click: _vm.delEmpInfo
    }
  }, [_vm._v("删除")])], 1)], 1), _c("a-layout-content", {
    staticClass: "ph24 mb100"
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-row", {
    staticClass: "tabList"
  }, _vm._l(_vm.tabListType, function (item) {
    return _c("div", {
      key: item.value,
      class: _vm.tabselect == item.value ? "select" : "",
      staticStyle: {
        cursor: "pointer"
      },
      on: {
        click: function click($event) {
          return _vm.tabChange(item.value);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _c("a-layout", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabselect == 0,
      expression: "tabselect == 0"
    }]
  }, [_c("Collapse", {
    attrs: {
      title: "基本信息",
      disabled: _vm.basicFormJson.editable
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("basicFormJson");
      }
    }
  }, [_vm.basicData ? _c("DynamicForm", {
    ref: "basic",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.basicData,
      editable: _vm.basicFormJson.editable,
      dynamicData: _vm.dynamicData,
      formFields: _vm.basicFormJson.fields,
      sortedKeys: _vm.basicFormJson.sortedKeys,
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.basicFormJson, "editable", $event);
      },
      change: function change($event) {
        return _vm.dynamicChange($event, "basic", "basicFormJson");
      }
    }
  }) : _vm._e()], 1), _c("Collapse", {
    attrs: {
      title: "联系信息",
      disabled: _vm.contactFormJson.editable
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("contactFormJson");
      }
    }
  }, [_vm.contactData ? _c("DynamicForm", {
    ref: "contact",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.contactData,
      editable: _vm.contactFormJson.editable,
      dynamicData: _vm.dynamicData,
      formFields: _vm.contactFormJson["fields"],
      sortedKeys: _vm.contactFormJson.sortedKeys,
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.contactFormJson, "editable", $event);
      },
      change: function change($event) {
        return _vm.dynamicChange($event, "contact", "contactFormJson");
      }
    }
  }) : _vm._e()], 1), _vm.empIdCardData !== null ? _c("Collapse", {
    attrs: {
      title: "身份证信息",
      disabled: _vm.empIdCardFormJson.editable
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("empIdCardFormJson");
      }
    }
  }, [_vm.empIdCardData ? _c("DynamicForm", {
    ref: "empIdCard",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.empIdCardData,
      editable: _vm.empIdCardFormJson.editable,
      dynamicData: _vm.dynamicData,
      formFields: _vm.empIdCardFormJson.fields,
      sortedKeys: _vm.empIdCardFormJson.sortedKeys,
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.empIdCardFormJson, "editable", $event);
      },
      change: function change($event) {
        return _vm.dynamicChange($event, "empIdCard", "empIdCardFormJson");
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _c("Collapse", {
    attrs: {
      title: "家庭成员",
      icon: "plus",
      editText: "新增"
    },
    on: {
      edit: function edit($event) {
        return _vm.addFoms("empFamilyData");
      }
    }
  }, [_vm.empFamilyData ? _c("div", _vm._l(_vm.empFamilyData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "rewards_top"
    }, [_c("a-button", {
      attrs: {
        slot: "right",
        type: "link",
        icon: "form",
        disabled: item.editable
      },
      on: {
        click: function click($event) {
          item.editable = true;
        }
      },
      slot: "right"
    }, [_vm._v("编辑")])], 1), _c("DynamicForm", {
      ref: "empFamily" + index,
      refInFor: true,
      staticStyle: {
        "max-width": "880px",
        margin: "0 auto"
      },
      attrs: {
        dataSource: item,
        editable: item.editable,
        dynamicData: _vm.dynamicData,
        formFields: _vm.empFamilyFormJson["fields"],
        sortedKeys: _vm.empFamilyFormJson.sortedKeys,
        formConfig: {},
        remove: item.remove ? false : true
      },
      on: {
        "update:editable": function updateEditable($event) {
          return _vm.$set(item, "editable", $event);
        },
        cancel: function cancel($event) {
          return _vm.dynamicCacels(item, index, "empFamily", "empFamilyFormJson");
        },
        remove: function remove($event) {
          return _vm.dynamicRemove(item, index, "empFamily", "empFamilyFormJson");
        },
        change: function change($event) {
          return _vm.dynamicChanges($event, item, "empFamily", "empFamilyFormJson");
        }
      }
    }), index + 1 !== _vm.empFamilyData.length ? _c("a-divider", {
      attrs: {
        dashed: ""
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e()]), _c("Collapse", {
    attrs: {
      title: "紧急联系人",
      icon: "plus",
      editText: "新增"
    },
    on: {
      edit: function edit($event) {
        return _vm.addFoms("empContactsData");
      }
    }
  }, [_vm.empContactsData ? _c("div", _vm._l(_vm.empContactsData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "rewards_top"
    }, [_c("a-button", {
      attrs: {
        slot: "right",
        type: "link",
        icon: "form",
        disabled: item.editable
      },
      on: {
        click: function click($event) {
          item.editable = true;
        }
      },
      slot: "right"
    }, [_vm._v("编辑")])], 1), _c("DynamicForm", {
      ref: "empContacts" + index,
      refInFor: true,
      staticStyle: {
        "max-width": "880px",
        margin: "0 auto"
      },
      attrs: {
        dataSource: item,
        editable: item.editable,
        dynamicData: _vm.dynamicData,
        formFields: _vm.empContactsFormJson["fields"],
        sortedKeys: _vm.empContactsFormJson.sortedKeys,
        formConfig: {},
        remove: item.remove ? false : true
      },
      on: {
        "update:editable": function updateEditable($event) {
          return _vm.$set(item, "editable", $event);
        },
        cancel: function cancel($event) {
          return _vm.dynamicCacels(item, index, "empContacts", "empContactsFormJson");
        },
        remove: function remove($event) {
          return _vm.dynamicRemove(item, index, "empContacts", "empContactsFormJson");
        },
        change: function change($event) {
          return _vm.dynamicChanges($event, item, "empContacts", "empContactsFormJson");
        }
      }
    }), index + 1 !== _vm.empContactsData.length ? _c("a-divider", {
      attrs: {
        dashed: ""
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e()]), _c("Collapse", {
    attrs: {
      title: "教育经历",
      icon: "plus",
      editText: "新增"
    },
    on: {
      edit: function edit($event) {
        return _vm.addFoms("empEducationData");
      }
    }
  }, [_vm.empEducationData ? _c("div", _vm._l(_vm.empEducationData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "rewards_top"
    }, [_c("a-button", {
      attrs: {
        slot: "right",
        type: "link",
        icon: "form",
        disabled: item.editable
      },
      on: {
        click: function click($event) {
          item.editable = true;
        }
      },
      slot: "right"
    }, [_vm._v("编辑")])], 1), _c("DynamicForm", {
      ref: "empEducation" + index,
      refInFor: true,
      staticStyle: {
        "max-width": "880px",
        margin: "0 auto"
      },
      attrs: {
        dataSource: item,
        editable: item.editable,
        dynamicData: _vm.dynamicData,
        formFields: _vm.empEducationFormJson["fields"],
        sortedKeys: _vm.empEducationFormJson.sortedKeys,
        formConfig: {},
        remove: item.remove ? false : true
      },
      on: {
        "update:editable": function updateEditable($event) {
          return _vm.$set(item, "editable", $event);
        },
        cancel: function cancel($event) {
          return _vm.dynamicCacels(item, index, "empEducation", "empEducationFormJson");
        },
        remove: function remove($event) {
          return _vm.dynamicRemove(item, index, "empEducation", "empEducationFormJson");
        },
        change: function change($event) {
          return _vm.dynamicChanges($event, item, "empEducation", "empEducationFormJson");
        }
      }
    }), index + 1 !== _vm.empEducationData.length ? _c("a-divider", {
      attrs: {
        dashed: ""
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e()]), _c("Collapse", {
    attrs: {
      title: "工作经历",
      icon: "plus",
      editText: "新增"
    },
    on: {
      edit: function edit($event) {
        return _vm.addFoms("empWorkData");
      }
    }
  }, [_vm.empWorkData ? _c("div", _vm._l(_vm.empWorkData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "rewards_top"
    }, [_c("a-button", {
      attrs: {
        slot: "right",
        type: "link",
        icon: "form",
        disabled: item.editable
      },
      on: {
        click: function click($event) {
          item.editable = true;
        }
      },
      slot: "right"
    }, [_vm._v("编辑")])], 1), _c("DynamicForm", {
      ref: "empWork" + index,
      refInFor: true,
      staticStyle: {
        "max-width": "880px",
        margin: "0 auto"
      },
      attrs: {
        dataSource: item,
        editable: item.editable,
        dynamicData: _vm.dynamicData,
        formFields: _vm.empWorkFormJson["fields"],
        sortedKeys: _vm.empWorkFormJson.sortedKeys,
        formConfig: {},
        remove: item.remove ? false : true
      },
      on: {
        "update:editable": function updateEditable($event) {
          return _vm.$set(item, "editable", $event);
        },
        cancel: function cancel($event) {
          return _vm.dynamicCacels(item, index, "empWork", "empWorkFormJson");
        },
        remove: function remove($event) {
          return _vm.dynamicRemove(item, index, "empWork", "empWorkFormJson");
        },
        change: function change($event) {
          return _vm.dynamicChanges($event, item, "empWork", "empWorkFormJson");
        }
      }
    }), index + 1 !== _vm.empWorkData.length ? _c("a-divider", {
      attrs: {
        dashed: ""
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e()]), _c("Collapse", {
    attrs: {
      title: "培训经历",
      icon: "plus",
      editText: "新增"
    },
    on: {
      edit: function edit($event) {
        return _vm.addFoms("empTrainData");
      }
    }
  }, [_vm.empTrainData ? _c("div", _vm._l(_vm.empTrainData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "rewards_top"
    }, [_c("a-button", {
      attrs: {
        slot: "right",
        type: "link",
        icon: "form",
        disabled: item.editable
      },
      on: {
        click: function click($event) {
          item.editable = true;
        }
      },
      slot: "right"
    }, [_vm._v("编辑")])], 1), _c("DynamicForm", {
      ref: "empTrain" + index,
      refInFor: true,
      staticStyle: {
        "max-width": "880px",
        margin: "0 auto"
      },
      attrs: {
        dataSource: item,
        editable: item.editable,
        dynamicData: _vm.dynamicData,
        formFields: _vm.empTrainFormJson["fields"],
        sortedKeys: _vm.empTrainFormJson.sortedKeys,
        formConfig: {},
        remove: item.remove ? false : true
      },
      on: {
        "update:editable": function updateEditable($event) {
          return _vm.$set(item, "editable", $event);
        },
        cancel: function cancel($event) {
          return _vm.dynamicCacels(item, index, "empTrain", "empTrainFormJson");
        },
        remove: function remove($event) {
          return _vm.dynamicRemove(item, index, "empTrain", "empTrainFormJson");
        },
        change: function change($event) {
          return _vm.dynamicChanges($event, item, "empTrain", "empTrainFormJson");
        }
      }
    }), index + 1 !== _vm.empTrainData.length ? _c("a-divider", {
      attrs: {
        dashed: ""
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e()])], 1), _c("a-layout", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabselect == 1,
      expression: "tabselect == 1"
    }]
  }, [_c("Collapse", {
    attrs: {
      title: "员工状态",
      disabled: _vm.empStateFormJson.editable
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("empStateFormJson");
      }
    }
  }, [_vm.empStateData ? _c("DynamicForm", {
    ref: "empState",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.empStateData,
      editable: _vm.empStateFormJson.editable,
      dynamicData: _vm.dynamicData,
      formFields: _vm.empStateFormJson.fields,
      sortedKeys: _vm.empStateFormJson.sortedKeys,
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.empStateFormJson, "editable", $event);
      },
      itemChange: _vm.onItemChange,
      change: function change($event) {
        return _vm.dynamicChange($event, "empState", "empStateFormJson");
      }
    }
  }) : _vm._e()], 1), _c("Collapse", {
    attrs: {
      title: "在职信息",
      disabled: _vm.onJobFormJson.editable
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("onJobFormJson");
      }
    }
  }, [_vm.onJobData ? _c("DynamicForm", {
    ref: "onJob",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.onJobData,
      editable: _vm.onJobFormJson.editable,
      dynamicData: _vm.empInfoDynamicData,
      formFields: _vm.onJobFormJson.fields,
      sortedKeys: _vm.onJobFormJson.sortedKeys,
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.onJobFormJson, "editable", $event);
      },
      change: function change($event) {
        return _vm.dynamicChange($event, "onJob", "onJobFormJson");
      },
      keydownEnter: _vm.onItemKeydownEnter
    }
  }) : _vm._e()], 1), _c("Collapse", {
    attrs: {
      title: "档案信息",
      disabled: _vm.fileFormJson.editable
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("fileFormJson");
      }
    }
  }, [_vm.fileData ? _c("DynamicForm", {
    ref: "file",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.fileData,
      editable: _vm.fileFormJson.editable,
      dynamicData: _vm.dynamicData,
      formFields: _vm.fileFormJson.fields,
      sortedKeys: _vm.fileFormJson.sortedKeys,
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.fileFormJson, "editable", $event);
      },
      change: function change($event) {
        return _vm.dynamicChange($event, "file", "fileFormJson");
      }
    }
  }) : _vm._e()], 1), _c("Collapse", {
    attrs: {
      title: "奖惩记录",
      icon: "plus",
      editText: "新增"
    },
    on: {
      edit: function edit($event) {
        return _vm.addFoms("rewardsPunishmentsData");
      }
    }
  }, [_vm.rewardsPunishmentsData ? _c("div", _vm._l(_vm.rewardsPunishmentsData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "rewards_top"
    }, [_c("a-button", {
      attrs: {
        slot: "right",
        type: "link",
        icon: "form",
        disabled: item.editable
      },
      on: {
        click: function click($event) {
          item.editable = true;
        }
      },
      slot: "right"
    }, [_vm._v("编辑")])], 1), _c("DynamicForm", {
      ref: "rewardsPunishments" + index,
      refInFor: true,
      staticStyle: {
        "max-width": "880px",
        margin: "0 auto"
      },
      attrs: {
        dataSource: item,
        editable: item.editable,
        dynamicData: _vm.dynamicData,
        formFields: _vm.rewardsPunishmentsFormJson["fields"],
        sortedKeys: _vm.rewardsPunishmentsFormJson.sortedKeys,
        formConfig: {},
        remove: item.remove ? false : true
      },
      on: {
        "update:editable": function updateEditable($event) {
          return _vm.$set(item, "editable", $event);
        },
        cancel: function cancel($event) {
          return _vm.dynamicCacels(item, index, "rewardsPunishments", "rewardsPunishmentsFormJson");
        },
        remove: function remove($event) {
          return _vm.dynamicRemove(item, index, "rewardsPunishments", "rewardsPunishmentsFormJson");
        },
        change: function change($event) {
          return _vm.dynamicChanges($event, item, "rewardsPunishments", "rewardsPunishmentsFormJson");
        }
      }
    }), index + 1 !== _vm.rewardsPunishmentsData.length ? _c("a-divider", {
      attrs: {
        dashed: ""
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e()]), _c("Collapse", {
    attrs: {
      title: "退休信息",
      disabled: _vm.retireFormJson.editable
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("retireFormJson");
      }
    }
  }, [_vm.retireData ? _c("DynamicForm", {
    ref: "retire",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.retireData,
      editable: _vm.retireFormJson.editable,
      dynamicData: _vm.dynamicData,
      formFields: _vm.retireFormJson.fields,
      sortedKeys: _vm.retireFormJson.sortedKeys,
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.retireFormJson, "editable", $event);
      },
      change: function change($event) {
        return _vm.dynamicChange($event, "retire", "retireFormJson");
      }
    }
  }) : _vm._e()], 1)], 1), _c("a-layout", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabselect === 2 && _vm.allEmpValuesLoaded,
      expression: "tabselect === 2 && allEmpValuesLoaded"
    }]
  }, [_c("Collapse", {
    attrs: {
      title: "用工档案",
      edit: false
    }
  }, [_c("EmployeeFile", {
    ref: "mychild",
    attrs: {
      "emp-id-one": _vm.employInfoId,
      edit: _vm.urr
    }
  })], 1)], 1), _c("a-layout", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabselect === 3 && _vm.allEmpValuesLoaded,
      expression: "tabselect === 3 && allEmpValuesLoaded"
    }]
  }, [_c("contract", {
    attrs: {
      employInfoId: _vm.employInfoId,
      findEmpInfoRelationRes: _vm.findEmpInfoRelationRes
    },
    on: {
      updateContractData: _vm.loadEmpDetailInfo
    }
  })], 1), _vm.tabselect == 4 ? _c("a-layout", [_c("Collapse", {
    attrs: {
      title: "工资卡信息",
      disabled: _vm.bankFormJson.editable
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("bankFormJson");
      }
    }
  }, [_vm.bankData ? _c("DynamicForm", {
    ref: "bank",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.bankData,
      editable: _vm.bankFormJson.editable,
      sortedKeys: _vm.bankFormJson.sortedKeys,
      dynamicData: _vm.dynamicData,
      formFields: _vm.bankFormJson["fields"],
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.bankFormJson, "editable", $event);
      },
      change: function change($event) {
        return _vm.dynamicChange($event, "bank", "bankFormJson");
      }
    }
  }) : _vm._e()], 1), !_vm.isJF ? _c("Collapse", {
    attrs: {
      title: "工资信息",
      edit: false
    }
  }, [_c("div", {
    staticClass: "FixedBox"
  }, [_c("span", {
    staticClass: "FixedBoxLine"
  }), _vm._v("固定薪资")]), _c("DynamicForm", {
    ref: "salary",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.salarySumData,
      editable: _vm.editable.salary,
      dynamicData: _vm.dynamicData,
      formFields: _vm.FormJson["salarySum"]["fields"],
      formConfig: _vm.FormJson["salarySum"]["config"]
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.editable, "salary", $event);
      }
    }
  }), _c("wagesPaid", {
    ref: "wages"
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.tabselect == 7 ? _c("a-layout", [_c("Collapse", {
    attrs: {
      title: "社保公积金信息",
      disabled: _vm.socAndPfFormJson.editable
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("socAndPfFormJson");
      }
    }
  }, [_vm.socAndPfData ? _c("DynamicForm", {
    ref: "socAndPf",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.socAndPfData,
      editable: _vm.socAndPfFormJson.editable,
      sortedKeys: _vm.socAndPfFormJson.sortedKeys,
      dynamicData: _vm.dynamicData,
      formFields: _vm.socAndPfFormJson["fields"],
      formConfig: {}
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.socAndPfFormJson, "editable", $event);
      },
      change: function change($event) {
        return _vm.dynamicChange($event, "socAndPf", "socAndPfFormJson");
      }
    }
  }) : _vm._e()], 1), _c("Collapse", {
    attrs: {
      title: "社保公积金基数",
      edit: false,
      disabled: _vm.ProvidentFundBaseFormJson.editable
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdits("ProvidentFundBaseFormJson");
      }
    }
  }, [_c("a-alert", {
    staticStyle: {
      margin: "16px 0"
    },
    attrs: {
      message: "若需要修改社保相关信息，请前往社保模块",
      banner: ""
    }
  }), _c("providentFundBase", {
    ref: "provident",
    attrs: {
      editable: _vm.ProvidentFundBaseFormJson.editable
    },
    on: {
      closeEdit: _vm.closeEdit,
      noSocialSecurityData: _vm.noSocialSecurityData,
      turnOnEditingFun: _vm.turnOnEditingFun
    }
  })], 1)], 1) : _vm._e(), _vm.tabselect == 5 ? _c("a-layout", [_c("Collapse", {
    attrs: {
      edit: false,
      title: "员工基本资料"
    }
  }, [_vm.annexFormJson && _vm.annexData ? _c("div", {
    staticClass: "accessory_warp"
  }, _vm._l(_vm.annexFormJson, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "accessory"
    }, [_c("div", {
      staticClass: "icon_warp"
    }, [_c("div", {
      staticClass: "shade",
      on: {
        click: function click($event) {
          return _vm.enterImgBox("annex", item.fieldCode, item.fieldName);
        }
      }
    }, [_c("a-icon", {
      staticClass: "fs20 icon",
      staticStyle: {
        color: "#fdfdfd"
      },
      attrs: {
        type: "eye"
      }
    }), _c("div", {
      staticClass: "num"
    }, [_vm._v(_vm._s(_vm._f("fieldCodes")(_vm.annexData[item.fieldCode])))])], 1), _c("xq-icon", {
      class: _vm.annexData[item.fieldCode] && _vm.annexData[item.fieldCode] !== "" ? "primary_color" : "color_E2",
      staticStyle: {
        "font-size": "45px"
      },
      attrs: {
        type: _vm.fieldsIcon[item.fieldCode] ? _vm.fieldsIcon[item.fieldCode] : "icon-zidingyi"
      }
    })], 1), _c("div", {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.fieldName))])]);
  }), 0) : _vm._e()]), _c("Collapse", {
    attrs: {
      title: "员工档案资料",
      edit: false
    }
  }, [_vm.archiveFormJson && _vm.archiveData ? _c("div", {
    staticClass: "accessory_warp"
  }, _vm._l(_vm.archiveFormJson, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "accessory"
    }, [_c("div", {
      staticClass: "icon_warp"
    }, [_c("div", {
      staticClass: "shade",
      on: {
        click: function click($event) {
          return _vm.enterImgBox("archive", item.fieldCode, item.fieldName);
        }
      }
    }, [_c("a-icon", {
      staticClass: "fs20 icon",
      staticStyle: {
        color: "#fdfdfd"
      },
      attrs: {
        type: "eye"
      }
    }), _c("div", {
      staticClass: "num"
    }, [_vm._v(_vm._s(_vm._f("fieldCodes")(_vm.archiveData[item.fieldCode])))])], 1), _c("xq-icon", {
      class: _vm.archiveData[item.fieldCode] && _vm.annexData[item.fieldCode] !== "" ? "primary_color" : "color_E2",
      staticStyle: {
        "font-size": "45px"
      },
      attrs: {
        type: _vm.fieldsIcon[item.fieldCode] ? _vm.fieldsIcon[item.fieldCode] : "icon-zidingyi"
      }
    })], 1), _c("div", {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.fieldName))])]);
  }), 0) : _vm._e()]), _c("Collapse", {
    attrs: {
      title: "员工离职资料",
      edit: false
    }
  }, [_vm.leaveOfficeFormJson && _vm.leaveOfficeData ? _c("div", {
    staticClass: "accessory_warp"
  }, _vm._l(_vm.leaveOfficeFormJson, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "accessory"
    }, [_c("div", {
      staticClass: "icon_warp"
    }, [_c("div", {
      staticClass: "shade",
      on: {
        click: function click($event) {
          return _vm.enterImgBox("leaveOffice", item.fieldCode, item.fieldName);
        }
      }
    }, [_c("a-icon", {
      staticClass: "fs20 icon",
      staticStyle: {
        color: "#fdfdfd"
      },
      attrs: {
        type: "eye"
      }
    }), _c("div", {
      staticClass: "num"
    }, [_vm._v(_vm._s(_vm._f("fieldCodes")(_vm.leaveOfficeData[item.fieldCode])))])], 1), _c("xq-icon", {
      class: _vm.leaveOfficeData[item.fieldCode] && _vm.annexData[item.fieldCode] !== "" ? "primary_color" : "color_E2",
      staticStyle: {
        "font-size": "45px"
      },
      attrs: {
        type: _vm.fieldsIcon[item.fieldCode] ? _vm.fieldsIcon[item.fieldCode] : "icon-zidingyi"
      }
    })], 1), _c("div", {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.fieldName))])]);
  }), 0) : _vm._e()])], 1) : _vm._e(), _vm.tabselect == 6 ? _c("a-layout", [_c("Collapse", {
    attrs: {
      title: "本年截止上个月个税累计值",
      disabled: _vm.editable.individual
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdit("individual");
      }
    }
  }, [_c("DynamicForm", {
    ref: "individual",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.individualData,
      editable: _vm.editable.individual,
      dynamicData: _vm.dynamicData,
      formFields: _vm.FormJson["individual"]["fields"],
      formConfig: _vm.FormJson["individual"]["config"]
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.editable, "individual", $event);
      },
      change: function change($event) {
        return _vm.individualChange($event, "individual");
      }
    }
  })], 1), _c("Collapse", {
    attrs: {
      title: "本年专项附加扣除累计",
      disabled: _vm.editable.subjoin
    },
    on: {
      edit: function edit($event) {
        return _vm.onEdit("subjoin");
      }
    }
  }, [_c("DynamicForm", {
    ref: "subjoin",
    staticClass: "pt16",
    staticStyle: {
      "max-width": "880px",
      margin: "0 auto"
    },
    attrs: {
      dataSource: _vm.subjoinData,
      editable: _vm.editable.subjoin,
      dynamicData: _vm.dynamicData,
      formFields: _vm.FormJson["subjoin"]["fields"],
      formConfig: _vm.FormJson["subjoin"]["config"]
    },
    on: {
      "update:editable": function updateEditable($event) {
        return _vm.$set(_vm.editable, "subjoin", $event);
      },
      change: function change($event) {
        return _vm.subjoinChange($event, "subjoin");
      }
    }
  })], 1)], 1) : _vm._e(), _vm.tabselect === 9 && _vm.allEmpValuesLoaded ? _c("a-layout", [_c("Shangbao")], 1) : _vm._e(), _vm.tabselect === 8 && _vm.allEmpValuesLoaded ? _c("a-layout", [_c("Daiyuchuli")], 1) : _vm._e()], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "更换用工单位",
      visible: _vm.switchVisible,
      width: 500
    },
    on: {
      ok: _vm.handleOk2,
      cancel: _vm.handleCancel
    }
  }, [_c("div", {
    staticStyle: {
      width: "80%"
    }
  }, [_c("a-form", _vm._b({
    attrs: {
      form: _vm.empyleeForm
    }
  }, "a-form", {
    labelCol: {
      span: 6
    },
    wrapperCol: {
      span: 10
    }
  }, false), [_c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "原用工单位:",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      "min-width": "240px",
      border: "1px solid #e8e8e8",
      "border-radius": "15px"
    },
    attrs: {
      disabled: "",
      "default-value": _vm.defaultCompanyName,
      "show-search": ""
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v(_vm._s(_vm.defaultCompanyName))])], 1)], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "新用工单位:",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyId", {
        rules: [{
          required: true,
          message: "请选择用工单位"
        }]
      }],
      expression: "[\n                'companyId',\n                {\n                  rules: [{ required: true, message: '请选择用工单位' }]\n                }\n              ]"
    }],
    staticStyle: {
      "min-width": "240px",
      border: "1px solid #e8e8e8",
      "border-radius": "15px"
    },
    attrs: {
      "show-search": "",
      placeholder: "更换用工单位",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption
    },
    on: {
      change: _vm.changeId
    }
  }, [_vm._l(_vm.companyList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      staticClass: "select-text",
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), _c("a-icon", {
    staticClass: "select-icon",
    staticStyle: {
      display: "block",
      "margin-left": "10px"
    },
    attrs: {
      slot: "suffixIcon",
      type: "caret-down"
    },
    slot: "suffixIcon"
  })], 2)], 1)], 1), !_vm.isJF ? _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "工作性质:",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["postType", {
        rules: [{
          required: true,
          message: "请选择工作性质"
        }]
      }],
      expression: "[\n                'postType',\n                {\n                  rules: [{ required: true, message: '请选择工作性质' }]\n                }\n              ]"
    }],
    attrs: {
      placeholder: "请选择工作性质"
    },
    on: {
      change: _vm.postTypeChange
    }
  }, _vm._l(_vm.postTypeOptions, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1)], 1) : _vm._e(), _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "原用工单位离职日期:",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["preQuitDate", {
        rules: [{
          required: true,
          message: "请选择原用工单位离职日期"
        }]
      }],
      expression: "[\n                'preQuitDate',\n                {\n                  rules: [{ required: true, message: '请选择原用工单位离职日期' }]\n                }\n              ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      placeholder: "请选择原用工单位离职日期"
    },
    on: {
      change: _vm.onChangeDatepreQuitDate
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "新用工单位入职日期:",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["JoinDate", {
        rules: [{
          required: true,
          message: "请选择新用工单位入职日期"
        }]
      }],
      expression: "[\n                'JoinDate',\n                {\n                  rules: [{ required: true, message: '请选择新用工单位入职日期' }]\n                }\n              ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      placeholder: "请选择新用工单位入职日期"
    },
    on: {
      change: _vm.JoinDateChange2
    }
  })], 1)], 1), _vm.postTypeShow ? _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "员工状态:",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["formalType", {
        rules: [{
          required: true,
          message: "请填写员工状态"
        }]
      }],
      expression: "[\n                'formalType',\n                {\n                  rules: [{ required: true, message: '请填写员工状态' }]\n                }\n              ]"
    }],
    attrs: {
      placeholder: "请选择状态"
    },
    on: {
      change: _vm.selectChange2
    }
  }, _vm._l(_vm.status2, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1)], 1) : _vm._e(), _vm.testDate && _vm.postTypeShow ? _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "试用期:",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-row", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["probationDays", {
        rules: [{
          required: true,
          message: "请填写试用期"
        }]
      }],
      expression: "[\n                  'probationDays',\n                  {\n                    rules: [{ required: true, message: '请填写试用期' }]\n                  }\n                ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      id: "inputNumber",
      min: 0,
      formatter: function formatter(value) {
        return "".concat(value, "\u5929");
      },
      placeholder: "试用期天数"
    },
    on: {
      change: _vm.handleProba2
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.testDate && _vm.postTypeShow ? _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "预计转正日期",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["turnStraightDate"],
      expression: "['turnStraightDate']"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      placeholder: "请选择",
      disabled: ""
    }
  })], 1)], 1) : _vm._e(), _vm.proRegularDateShow && _vm.postTypeShow ? _c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "预计转正日期",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["turnStraightDate", {
        rules: [{
          required: true,
          message: "请填写转正日期"
        }]
      }],
      expression: "[\n                'turnStraightDate',\n                {\n                  rules: [{ required: true, message: '请填写转正日期' }]\n                }\n              ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      placeholder: "请选择"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)]), _c("a-drawer", {
    attrs: {
      title: "办理离职",
      width: 540,
      visible: _vm.visibleLeaveDrawer
    },
    on: {
      close: function close($event) {
        return _vm.onCloseDrawer("visibleLeaveDrawer");
      }
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.leaveForm
    },
    on: {
      submit: _vm.handleLeaveSubmit
    }
  }, [_c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "预计离职日期:",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["preQuitDate", {
        rules: [{
          required: true,
          message: "请选择预计离职日期"
        }]
      }],
      expression: "[\n              'preQuitDate',\n              {\n                rules: [{ required: true, message: '请选择预计离职日期' }]\n              }\n            ]"
    }],
    attrs: {
      type: "date",
      "disabled-date": _vm.disabledDateRegularDate
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "离职原因:",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("div", {
    staticClass: "selectBox",
    on: {
      click: _vm.showLeaveModal
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["quitReason", {
        rules: [{
          required: true,
          message: "请选择离职原因"
        }]
      }],
      expression: "['quitReason', { rules: [{ required: true, message: '请选择离职原因' }] }]"
    }],
    ref: "selectModal",
    attrs: {
      mode: "multiple",
      onbeforeactivate: "false",
      "menu-item-selected-icon": "none",
      notFoundContent: null
    },
    on: {
      change: _vm.LeaveChange
    }
  })], 1)])], 1), _c("a-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "离职备注:",
      "label-col": {
        span: 9
      },
      "wrapper-col": {
        span: 15
      }
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["quitRemarks"],
      expression: "['quitRemarks']"
    }],
    attrs: {
      placeholder: "请输入备注",
      rows: 4
    }
  })], 1)], 1), _c("div", {
    staticClass: "drawer-footer-box"
  }, [_c("a-button", {
    style: {
      marginRight: "12px"
    },
    on: {
      click: function click($event) {
        return _vm.onCloseDrawer("visibleLeaveDrawer");
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary",
      "html-type": "submit"
    }
  }, [_vm._v("确定")])], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "选择离职原因",
      visible: _vm.visibleLeaveModal,
      "confirm-loading": false,
      "cancel-text": "取消",
      "ok-text": "确定"
    },
    on: {
      ok: _vm.confirmLeaveModal,
      cancel: function cancel($event) {
        return _vm.onCloseDrawer("visibleLeaveModal");
      }
    }
  }, [_c("a-checkbox-group", {
    on: {
      change: _vm.onChangeLeaveModal
    },
    model: {
      value: _vm.checkedListGroup,
      callback: function callback($$v) {
        _vm.checkedListGroup = $$v;
      },
      expression: "checkedListGroup"
    }
  }, [_c("a-row", {
    staticClass: "lh40"
  }, [_c("a-col", {
    staticClass: "drawer-title mb20",
    attrs: {
      span: 24
    }
  }, [_vm._v("主动原因")]), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "家庭原因"
    }
  }, [_vm._v("家庭原因")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "个人原因"
    }
  }, [_vm._v("个人原因")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "发展原因"
    }
  }, [_vm._v("发展原因")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "合同到期不续签"
    }
  }, [_vm._v("合同到期不续签")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "其他"
    }
  }, [_vm._v("其他")])], 1), _c("a-col", {
    staticClass: "drawer-title mb20 mt20",
    attrs: {
      span: 24
    }
  }, [_vm._v("被动原因")]), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "协议解除"
    }
  }, [_vm._v("协议解除")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "无法胜任工作"
    }
  }, [_vm._v("无法胜任工作")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "经济性裁员"
    }
  }, [_vm._v("经济性裁员")])], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-checkbox", {
    attrs: {
      value: "严重违法违纪"
    }
  }, [_vm._v("严重违法违纪")])], 1)], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "选择审批模板",
      visible: _vm.approvalModal,
      "confirm-loading": false,
      "cancel-text": "关闭",
      "ok-text": "下一步"
    },
    on: {
      ok: _vm.handleApprovalSubmit,
      cancel: function cancel($event) {
        _vm.approvalModal = false;
      }
    }
  }, [_c("div", {
    staticClass: "modal-box overflow-y400"
  }, [_c("a-radio-group", {
    staticStyle: {
      width: "100%"
    },
    on: {
      change: _vm.onselectValueChange
    },
    model: {
      value: _vm.selectValue,
      callback: function callback($$v) {
        _vm.selectValue = $$v;
      },
      expression: "selectValue"
    }
  }, _vm._l(_vm.FlowsData, function (item) {
    return _c("a-row", {
      key: item.id,
      staticClass: "mb20",
      attrs: {
        type: "flex",
        align: "middle"
      }
    }, [_c("a-col", {
      staticClass: "icon mr8"
    }, [_c("img", {
      staticStyle: {
        width: "48px"
      },
      attrs: {
        src: item.icon,
        alt: "加载失败"
      }
    })]), _c("a-col", [_c("a-row", {
      staticClass: "title1"
    }, [_vm._v(_vm._s(item.typeName))]), _c("a-row", {
      staticClass: "subTitle fs12 ta-left"
    }, [_vm._v(_vm._s(item.typeDesc))])], 1), _c("a-col", {
      staticClass: "ta-right",
      staticStyle: {
        flex: "1"
      }
    }, [_c("a-radio", {
      attrs: {
        value: item.id
      }
    })], 1)], 1);
  }), 1)], 1)]), _c("a-modal", {
    attrs: {
      title: _vm.titleSubmit,
      visible: _vm.visibleSubmit,
      "confirm-loading": false,
      "cancel-text": "关闭",
      "ok-text": _vm.oktext
    },
    on: {
      ok: function ok($event) {
        return _vm.onCloseDrawer("visibleSubmit");
      },
      cancel: function cancel($event) {
        return _vm.onCloseDrawer("visibleSubmit");
      }
    }
  }, [_vm.FlowsData.length === 0 ? _c("div", {
    staticClass: "modal-box"
  }, [_c("img", {
    staticClass: "w200",
    attrs: {
      src: require("@/assets/img/1.png"),
      alt: ""
    }
  }), _c("h3", {
    staticClass: "modal-title"
  }, [_c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === 1,
      expression: "flag === 1"
    }]
  }, [_vm._v("提交成功")]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === 2,
      expression: "flag === 2"
    }]
  }, [_vm._v("转正成功")]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === 3,
      expression: "flag === 3"
    }]
  }, [_vm._v("已提交至待离职列表")]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === 4,
      expression: "flag === 4"
    }]
  }, [_vm._v("已提交至待调岗列表")])]), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === 1,
      expression: "flag === 1"
    }],
    staticClass: "modal-content"
  }, [_vm._v("已自动发送入职邀请至对方手机")])]) : _c("div", {
    staticClass: "modal-box"
  }, [_c("img", {
    staticClass: "w200",
    attrs: {
      src: require("@/assets/img/2.png"),
      alt: ""
    }
  }), _c("h3", {
    staticClass: "modal-title"
  }, [_vm._v("待审批")]), _c("p", {
    staticClass: "modal-content"
  }, [_vm._v("已提交至审批流程")]), _c("p", {
    staticClass: "modal-content"
  }, [_vm._v(" 待审批通过后， "), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === 1,
      expression: "flag === 1"
    }]
  }, [_vm._v("可在入职管理里查看员工入职信息")]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === 2,
      expression: "flag === 2"
    }]
  }, [_vm._v("即可转正")]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === 3,
      expression: "flag === 3"
    }]
  }, [_vm._v("即可离职")]), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === 4,
      expression: "flag === 4"
    }]
  }, [_vm._v("即可调岗")])]), _c("p", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.flag === 1,
      expression: "flag === 1"
    }]
  }, [_vm._v("审批通过后自动发送入职邀请至对方手机")])])]), _vm.recordApprovalModal ? _c("approvalDetail", {
    attrs: {
      data: _vm.approvalDetailData
    },
    on: {
      visibleApprovalModal: function visibleApprovalModal($event) {
        _vm.recordApprovalModal = false;
      }
    }
  }) : _vm._e(), _vm.bottomInfo.lastEmpInfoListVO && !_vm.$route.query.Marking && _vm.bottomInfo.allNumber > 0 ? _c("div", {
    staticClass: "detail-bottomInfo-wrapper"
  }, [_c("div", {
    staticClass: "info-item info-side-left",
    on: {
      click: function click($event) {
        return _vm.goEmployee(_vm.bottomInfo.lastEmpInfoListVO.id);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    }
  }), _vm._v(" " + _vm._s(_vm.bottomInfo.lastEmpInfoListVO.name == null ? "没有了" : _vm.bottomInfo.lastEmpInfoListVO.name) + " ")], 1), _c("div", {
    staticClass: "info-item info-side-middle"
  }, [_vm._v(_vm._s("(".concat(_vm.bottomInfo.currentPosition, "/").concat(_vm.bottomInfo.allNumber, ")")))]), _c("div", {
    staticClass: "info-item info-side-right",
    on: {
      click: function click($event) {
        return _vm.goEmployee(_vm.bottomInfo.nextEmpInfoListVO.id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.bottomInfo.nextEmpInfoListVO.name == null ? "没有了" : _vm.bottomInfo.nextEmpInfoListVO.name) + " "), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1)]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };