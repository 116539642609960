import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-alert", {
    staticStyle: {
      width: "610px",
      "margin-bottom": "24px"
    },
    attrs: {
      "show-icon": "",
      type: "warning",
      message: "员工单条工资识别计算规则为：工种 X 考勤状态",
      closable: ""
    }
  })], 1)], 1), _c("div", {
    staticClass: "box"
  }, [_c("a-tabs", {
    attrs: {
      type: "card",
      "default-active-key": "typeWork"
    },
    on: {
      change: function change(v) {
        return _vm.componentName = v;
      }
    }
  }, _vm._l(_vm.numArr, function (item) {
    return _c("a-tab-pane", {
      key: item.type
    }, [_c("span", {
      attrs: {
        slot: "tab"
      },
      slot: "tab"
    }, [_c("a-badge", {
      attrs: {
        count: item.num,
        offset: [5, -2]
      }
    }, [_vm._v(_vm._s(item.name))])], 1)]);
  }), 1), _c(_vm.componentName, {
    tag: "div"
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };