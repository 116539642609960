import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "shebao-detail-list"
  }, [_c("a-button", {
    attrs: {
      icon: "left",
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")]), _c("a-row", {
    staticClass: "content-top"
  }), _c("navItem", {
    attrs: {
      options: _vm.tabs
    },
    on: {
      change: _vm.handleNav
    }
  }), _c("div", {
    staticClass: "table-box"
  }, [_c("a-table", {
    staticClass: "mg_t24",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.dataSource,
      pagination: _vm.pagination,
      "row-key": function rowKey(a, b) {
        return b;
      }
    },
    on: {
      change: _vm.handlePage
    },
    scopedSlots: _vm._u([{
      key: "customName",
      fn: function fn(text, record) {
        return [record.noError ? _c("img", {
          staticStyle: {
            width: "24px"
          },
          attrs: {
            src: require("@/assets/img/shebaopaidan/Info2@2x.png"),
            alt: ""
          }
        }) : _c("img", {
          staticStyle: {
            width: "24px"
          },
          attrs: {
            src: require("@/assets/img/shebaopaidan/Info-Circle@2x.png"),
            alt: ""
          }
        }), _vm._v(" " + _vm._s(record.name) + " ")];
      }
    }, {
      key: "fundStartDate",
      fn: function fn(record) {
        return [_vm._v(_vm._s(_vm.moment(record).format("YYYY-MM-DD")))];
      }
    }, {
      key: "socProgName",
      fn: function fn(record) {
        return [_c("span", {
          style: _vm.hasPaidan(record) ? "" : "color:red"
        }, [_vm._v(_vm._s(record))])];
      }
    }, {
      key: "socStartDate",
      fn: function fn(record) {
        return [_vm._v(_vm._s(_vm.moment(record).format("YYYY-MM-DD")))];
      }
    }, {
      key: "assignCompanyName",
      fn: function fn(text, record) {
        return [_vm._v(_vm._s(record.noError ? record.assignCompanyName : "--"))];
      }
    }, {
      key: "action",
      fn: function fn(record) {
        return [_c("span", {
          staticClass: "primary",
          on: {
            click: function click($event) {
              return _vm.deleteItem(record);
            }
          }
        }, [_vm._v("删除")])];
      }
    }, {
      key: "expandedRowRender",
      fn: function fn(text) {
        return _c("div", {
          staticClass: "expend-box",
          staticStyle: {
            padding: "0",
            margin: "0"
          }
        }, [_c("a-row", {
          attrs: {
            type: "flex",
            align: "middle"
          }
        }, [_c("a-col", {
          staticClass: "info-left"
        }, [_c("a-row", {
          staticClass: "center"
        }, [_c("a-avatar", {
          attrs: {
            src: "avatar.png",
            size: 64
          }
        })], 1), _c("a-row", {
          staticClass: "center name"
        }, [_c("strong", [_vm._v(_vm._s(text.name))])]), _c("a-divider", {
          attrs: {
            dashed: true
          }
        }), _c("a-row", {
          staticClass: "info-item"
        }, [_c("a-col", {
          staticClass: "sub_tit",
          attrs: {
            span: 12
          }
        }, [_vm._v("身份证号")]), _c("a-col", {
          attrs: {
            span: 12
          }
        }, [_vm._v(_vm._s(text.idCard))])], 1), _c("a-row", {
          staticClass: "info-item"
        }, [_c("a-col", {
          staticClass: "sub_tit",
          attrs: {
            span: 12
          }
        }, [_vm._v("个人社保号")]), _c("a-col", {
          attrs: {
            span: 12
          }
        }, [_vm._v("--")])], 1), _c("a-row", {
          staticClass: "info-item"
        }, [_c("a-col", {
          staticClass: "sub_tit",
          attrs: {
            span: 12
          }
        }, [_vm._v("个人公积金号")]), _c("a-col", {
          attrs: {
            span: 12
          }
        }, [_vm._v("--")])], 1), _c("a-row", {
          staticClass: "info-item"
        }, [_c("a-col", {
          staticClass: "sub_tit",
          attrs: {
            span: 12
          }
        }, [_vm._v("参保城市")]), _c("a-col", {
          attrs: {
            span: 12
          }
        }, [_vm._v(_vm._s(text.socCityName))])], 1), _c("a-row", {
          staticClass: "info-item"
        }, [_c("a-col", {
          staticClass: "sub_tit",
          attrs: {
            span: 12
          }
        }, [_vm._v("缴纳方案")]), _c("a-col", {
          attrs: {
            span: 12
          }
        }, [_vm._v(_vm._s(text.socProgName))])], 1), _c("a-divider", {
          attrs: {
            dashed: true
          }
        }), _c("a-row", {
          attrs: {
            type: "flex",
            align: "middle"
          }
        }, [_c("a-button", {
          on: {
            click: function click($event) {
              return _vm.editor(text);
            }
          }
        }, [_vm._v("编辑")])], 1)], 1), _c("a-col", {
          staticStyle: {
            flex: "1"
          }
        }, [_c("a-table", {
          attrs: {
            "row-key": function rowKey(a, b) {
              return b;
            },
            columns: _vm.innerColumns,
            "data-source": text.socDispatchEmpItemList || [],
            pagination: false
          },
          scopedSlots: _vm._u([{
            key: "persentPersonal",
            fn: function fn(persentPersonal) {
              return [_vm._v(" " + _vm._s(persentPersonal) + "%")];
            }
          }, {
            key: "persentCompany",
            fn: function fn(persentCompany) {
              return [_vm._v(" " + _vm._s(persentCompany) + "%")];
            }
          }], null, true)
        }), _c("a-row", {
          staticClass: "inner-right-foot",
          attrs: {
            type: "flex"
          }
        }, [_c("a-col", {
          attrs: {
            span: 15
          }
        }, [_c("strong", [_vm._v("缴费合计")])]), _c("a-col", {
          staticClass: "center",
          attrs: {
            span: 3
          }
        }, [_c("strong", [_vm._v(_vm._s(text.personalAmount || "--"))])]), _c("a-col", {
          staticClass: "center",
          attrs: {
            span: 3
          }
        }, [_c("strong", [_vm._v(_vm._s(text.companyAmount || "--"))])]), _c("a-col", {
          staticClass: "center",
          attrs: {
            span: 3
          }
        }, [_c("strong", [_c("span", {
          staticClass: "primary"
        }, [_vm._v(_vm._s(text.allAmount || "--"))])])])], 1)], 1)], 1), _c("div")], 1);
      }
    }])
  })], 1), _c("editor-modal", {
    attrs: {
      show: _vm.editorShow,
      options: _vm.editorOptions
    },
    on: {
      change: _vm.editorCb
    }
  }), _c("a-row", {
    staticClass: "foot"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.reUpload
    }
  }, [_vm._v("重新上传")]), _c("a-button", {
    attrs: {
      disabled: !_vm.dataSource.length
    },
    on: {
      click: _vm.upload
    }
  }, [_vm._v("派单")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };