var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    staticClass: "del-modal",
    attrs: {
      title: "提示",
      footer: null,
      closable: false,
      maskClosable: false,
      keyboard: false,
      zIndex: 9999,
      width: "424px"
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "del-box"
  }, [_c("div", [_c("a-icon", {
    staticClass: "del-icon",
    attrs: {
      type: "info-circle",
      theme: "filled"
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-left": "18px"
    }
  }, [_vm.title ? _c("div", {
    staticClass: "del-title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.desc ? _c("div", {
    staticClass: "del-desc"
  }, [_vm._v(_vm._s(_vm.desc))]) : _vm._e()])]), _c("div", {
    staticClass: "del-btn"
  }, [_c("a-button", {
    staticClass: "mr12",
    on: {
      click: function click($event) {
        _vm.$emit("update:visible", false);
        _vm.$emit("onCancel");
      }
    }
  }, [_vm._v(_vm._s(_vm.cancelText))]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.$emit("update:visible", false);
        _vm.$emit("onOk");
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.okText) + " ")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };