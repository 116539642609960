import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
var empColumns = [{
  title: "姓名",
  dataIndex: "empName",
  key: "empName"
}, {
  title: "薪资组",
  dataIndex: "salaryGroupName",
  key: "salaryGroupName"
}, {
  title: "月工资表名称",
  dataIndex: "salaryTemplateName",
  key: "salaryTemplateName"
}];
var columns = [{
  title: "姓名",
  dataIndex: "empName",
  key: "empName",
  width: 120,
  fixed: "left"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 230,
  fixed: "left"
}, {
  title: "应发工资",
  dataIndex: "shouldWages",
  key: "shouldWages",
  width: 120
}, {
  title: "个人社保",
  dataIndex: "empSocialSecurity",
  key: "empSocialSecurity",
  width: 120
}, {
  title: "企业社保",
  dataIndex: "enterpriseSocialSecurity",
  key: "enterpriseSocialSecurity",
  width: 120
}, {
  title: "个人公积金",
  dataIndex: "empProvidentFund",
  key: "empProvidentFund",
  width: 140
}, {
  title: "企业公积金",
  dataIndex: "enterpriseProvidentFund",
  key: "enterpriseProvidentFund",
  width: 140
}, {
  title: "个人所得税",
  dataIndex: "tax",
  key: "tax",
  width: 140
}, {
  title: "实发工资",
  dataIndex: "paidWages",
  key: "paidWages",
  width: 120
}];
var initColumns = [{
  title: "姓名",
  dataIndex: "empName",
  key: "empName",
  width: 120,
  fixed: "left"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 230,
  fixed: "left"
}, {
  title: "应发工资",
  dataIndex: "shouldWages",
  key: "shouldWages",
  width: 120
}, {
  title: "个人社保",
  dataIndex: "empSocialSecurity",
  key: "empSocialSecurity",
  width: 120
}, {
  title: "企业社保",
  dataIndex: "enterpriseSocialSecurity",
  key: "enterpriseSocialSecurity",
  width: 120
}, {
  title: "个人公积金",
  dataIndex: "empProvidentFund",
  key: "empProvidentFund",
  width: 140
}, {
  title: "企业公积金",
  dataIndex: "enterpriseProvidentFund",
  key: "enterpriseProvidentFund",
  width: 140
}, {
  title: "个人所得税",
  dataIndex: "tax",
  key: "tax",
  width: 140
}, {
  title: "实发工资",
  dataIndex: "paidWages",
  key: "paidWages",
  width: 120
}];
import { account, getHeaders as _getHeaders, getSelectHeaders as _getSelectHeaders, selectSalaryGroup, empSelectPage, gitTemplateDetail, gitTemplateHeader, deleteEmploye, addEmpDetail, archive, getEmpCreated, getnotDetailsList, synchronous as _synchronous, generateWageList, getEmpListAll as _getEmpListAll, generatedBills, listByCompanyIdGet, getChannels, syncSocAndPf, taxCalculation as _taxCalculation, updateHead } from "@/api/wages/monthly";
import axios from "axios";
import qs from "qs";
import draggable from "vuedraggable";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
var mixin = new ExportMixin();
import moment from "moment";
export default {
  mixins: [mixin],
  components: {
    draggable: draggable,
    EmployeeSelect: function EmployeeSelect() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/components/EmployeeSelect2/employeeSelect-xinzi"));
      });
    },
    checkModal: function checkModal() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./components/checkModal"));
      });
    }
  },
  data: function data() {
    return {
      headVisible: false,
      tempSelectedHead: [],
      tempNoSelectedHead: [],
      status: 1,
      postType: undefined,
      approStatus: false,
      abolitionVisible: false,
      pageSizeOptions: ["10", "20", "50", "100"],
      tableName: undefined,
      generatelVisible: false,
      title: "",
      empVisible: false,
      tags: [],
      empList: [],
      empIdsList: [],
      empIdList: [],
      createdList: [],
      empColumns: empColumns,
      salaryTemplateSalaryIdList: [],
      buttonArchive: {},
      isArchive: true,
      buttonState: true,
      loading: true,
      combined: require("@/assets/img/wages/monthly/combined.png"),
      inputName: null,
      companyName: this.$store.state.hr.companyName,
      isAll: false,
      selectedList: [],
      defaultSelectedKeys: [],
      chooseMemberVisible: false,
      selectedRowId: [],
      selectedRowKeys: [],
      selectedRow: [],
      selectRowKey: [],
      regType: undefined,
      channelId: undefined,
      channelNames: [],
      dataList: [],
      labelList: [],
      initColumns: initColumns,
      tableId: null,
      columns: columns,
      detailList: [],
      allGroupId: [],
      groupList: [],
      empAllList: [],
      templateId: null,
      empStatus: null,
      salaryTemplateId: null,
      companyId: this.$store.state.hr.companyId,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      salaryGroupSalaryId: null,
      monthly: null,
      yearly: null,
      bankType: undefined,
      synchronousMonth: undefined,
      statisticalVisible: false,
      selectString: {},
      ignore: [],
      canSee: [],
      synchronousVisible: false,
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      visibleKQ: false
    };
  },
  created: function created() {
    this.approStatus = this.$route.query.approStatus;
    this.isArchive = this.$route.query.isArchive ? this.$route.query.isArchive : true;
    this.$set(this.buttonArchive, "isArchive", this.$route.query.isArchive);
    this.salaryHeaderId = this.$route.query.salaryHeaderId;
    this.salaryTemplateId = this.$route.query.salaryTemplateId;
    this.title = this.$route.query.title;
    this.templateId = this.$route.query.templateId;
    this.yearly = this.$route.query.yearly;
    this.monthly = this.$route.query.monthly;
    this.tableName = this.$route.query.tableName;
    this.getSelectSalaryGroup();
    this.gitTemplateDetailList();
    // this.gitTemplateHeaderList();
    this.getEmpListAll();
    this.getListByCompanyId();
    this.getChannelsList();
    this.personalPeriod = this.$route.query.personalPeriod;
    this.isAll = this.$route.query.isAll ? this.$route.query.isAll : false;
    // if (this.isAll === "true") {
    //   this.rowSelection = undefined;
    // } else if (this.isAll === false) {
    //   this.rowSelection = {
    //     selectedRowKeys: this.selectedRowKeys,
    //     onChange: this.onSelectChange
    //   };
    // }
    this.getSelectHeaders();
    // if (this.isAll === "true") {
    //   this.columns.push({
    //     title: "服务费",
    //     dataIndex: "serviceCharge",
    //     key: "serviceCharge",
    //     width: 120
    //   });
    // }
  },
  beforeDestory: function beforeDestory() {},
  methods: {
    getSelectHeaders: function getSelectHeaders() {
      var _this2 = this;
      _getSelectHeaders({
        sourceId: this.salaryHeaderId || this.salaryTemplateId,
        moduleCode: this.isAll === "true" ? "monthly" : "template",
        companyId: this.companyId
      }).then(function (res) {
        var arr = [];
        _this2.selectString = res.data;
        _this2.tempSelectedHead = [];
        arr = JSON.parse(JSON.stringify(res.data.canSee));
        arr.forEach(function (item, index) {
          if (item.fieldEnName) {
            _this2.tempSelectedHead.push({
              title: item.fieldName,
              dataIndex: item.fieldEnName,
              key: item.fieldEnName,
              width: item.fieldName === "身份证号" ? 200 : 130,
              fixed: item.fieldName === "姓名" || item.fieldName === "身份证号" ? "left" : null,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          } else {
            _this2.tempSelectedHead.push({
              title: item.fieldName,
              dataIndex: item.fieldName,
              key: item.fieldName,
              width: item.fieldName === "身份证号" ? 200 : 130,
              fixed: item.fieldName === "姓名" || item === "身份证号" ? "left" : null,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          }
        });
        _this2.columns = JSON.parse(JSON.stringify(_this2.tempSelectedHead));
        var arr2 = [];
        _this2.tempNoSelectedHead = [];
        // this.selectString = JSON.parse(JSON.stringify(this.selectString.split(",")));
        arr2 = JSON.parse(JSON.stringify(res.data.ignore));
        arr2.forEach(function (item, index) {
          if (item.fieldEnName) {
            _this2.tempNoSelectedHead.push({
              title: item.fieldName,
              dataIndex: item.fieldEnName,
              key: item.fieldEnName,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          } else {
            _this2.tempNoSelectedHead.push({
              title: item.fieldName,
              dataIndex: item.fieldName,
              key: item.fieldName,
              fieldName: item.fieldName,
              fieldEnName: item.fieldEnName,
              isEdit: item.isEdit
            });
          }
        });
      });
    },
    getHeaders: function getHeaders() {
      var _this3 = this;
      _getHeaders({
        subjectId: this.salaryHeaderId || this.salaryTemplateId,
        subjectType: this.isAll === "true" ? "monthly" : "template"
      }).then(function (res) {
        var arr2 = [];
        _this3.tempNoSelectedHead = [];
        _this3.selectString = JSON.parse(JSON.stringify(_this3.selectString.split(",")));
        // arr2 = arr.filter(v => !this.selectString.includes(v));
        arr2.forEach(function (item, index) {
          var itemIndex = "";
          switch (item) {
            case "姓名":
              itemIndex = "empName";
              break;
            case "身份证号":
              itemIndex = "idCard";
              break;
            case "应发工资":
              itemIndex = "shouldWages";
              break;
            case "个人社保":
              itemIndex = "empSocialSecurity";
              break;
            case "企业社保":
              itemIndex = "enterpriseSocialSecurity";
              break;
            case "个人公积金":
              itemIndex = "empProvidentFund";
              break;
            case "企业公积金":
              itemIndex = "enterpriseProvidentFund";
              break;
            case "个人所得税":
              itemIndex = "tax";
              break;
            case "实发工资":
              itemIndex = "paidWages";
              break;
            default:
              itemIndex = item;
              break;
          }
          _this3.tempNoSelectedHead.push({
            title: item,
            dataIndex: itemIndex,
            key: itemIndex
          });
        });
      });
    },
    handleHeadSearch: function handleHeadSearch() {
      var _this4 = this;
      this.selectString.canSee = this.tempSelectedHead;
      this.selectString.ignore = this.tempNoSelectedHead;
      updateHead(this.selectString).then(function (res) {
        _this4.getSelectHeaders();
        _this4.headVisible = false;
      });
    },
    handleHeadChange: function handleHeadChange(visible) {
      if (visible === false) {
        this.tempSelectedHead = JSON.parse(JSON.stringify(this.selectedHead));
        this.tempNoSelectedHead = JSON.parse(JSON.stringify(this.noSelectedHead));
      }
    },
    addSelectedHead: function addSelectedHead(index) {
      var temp = JSON.parse(JSON.stringify(this.tempNoSelectedHead[index]));
      this.tempNoSelectedHead.splice(index, 1);
      this.tempSelectedHead.push(temp);
    },
    deleteSelectedHead: function deleteSelectedHead(e, index) {
      e.preventDefault();
      var temp = JSON.parse(JSON.stringify(this.tempSelectedHead[index]));
      this.tempSelectedHead.splice(index, 1);
      this.tempNoSelectedHead.push(temp);
    },
    handleHeadCancel: function handleHeadCancel() {
      this.headVisible = false;
      this.tempSelectedHead = JSON.parse(JSON.stringify(this.selectedHead));
      this.tempNoSelectedHead = JSON.parse(JSON.stringify(this.noSelectedHead));
    },
    onStart: function onStart() {
      this.drag = true;
    },
    onEnd: function onEnd() {
      this.drag = false;
    },
    onMove: function onMove(e) {
      if (e.relatedContext.element.title === "姓名" || e.relatedContext.element.title === "身份证号") return false;
      return true;
    },
    taxCalculation: function taxCalculation() {
      var _this5 = this;
      _taxCalculation(parseInt(this.monthly), parseInt(this.yearly), this.companyId, this.salaryTemplateId).then(function (res) {
        _this5.$notification["success"]({
          message: "success",
          description: "计算成功"
        });
        _this5.gitTemplateDetailList();
      });
    },
    // 同步社保
    showSynchronous: function showSynchronous() {
      this.synchronousMonth = undefined;
      this.synchronousVisible = true;
    },
    synchronousOk: function synchronousOk() {
      var _this6 = this;
      if (!this.synchronousMonth) {
        this.$notification["error"]({
          message: "错误",
          description: "请选择月份"
        });
        return;
      }
      syncSocAndPf({
        socYear: parseInt(moment(this.synchronousMonth).format("YYYY")),
        socMonth: parseInt(moment(this.synchronousMonth).format("MM")),
        companyId: this.companyId,
        salaryTemplateSalaryId: this.salaryTemplateId
      }).then(function (res) {
        _this6.$notification["success"]({
          message: "success",
          description: "同步成功"
        });
        _this6.gitTemplateDetailList();
        _this6.synchronousVisible = false;
      });
    },
    getChannelsList: function getChannelsList() {
      var _this7 = this;
      getChannels({
        pageNo: 1,
        pageSize: 1000
      }).then(function (res) {
        _this7.channelNames = res.data.entities;
      });
    },
    getListByCompanyId: function getListByCompanyId() {
      var _this8 = this;
      listByCompanyIdGet({
        companyId: this.$store.state.hr.companyId
      }).then(function (_ref) {
        var data = _ref.data;
        _this8.labelList = data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    reload: function reload() {
      this.getEmpSelectPage();
      this.$notification["success"]({
        message: "成功",
        description: "刷新成功"
      });
    },
    handleMenuClick: function handleMenuClick(e) {
      switch (e.key) {
        case "1":
          this.showStatistical();
          break;
        case "2":
          this.generateWage();
          break;
        case "3":
          this.deleteEmployes();
          break;
        case "4":
          this.goEdit();
          break;
        case "5":
          this.chooseMemberVisible = true;
          break;
        case "6":
          this.templateArchive();
          break;
        case "7":
          this.autoAdd();
          break;
        case "8":
          this.download();
          break;
        case "9":
          this.recalculate();
          break;
        case "10":
          this.goImportKQ();
          break;
      }
    },
    handleChange2: function handleChange2(e) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.empStatus = e;
      this.getEmpSelectPage();
    },
    // 重新计算工资
    recalculate: function recalculate() {
      var _this9 = this;
      var templateList = [];
      templateList.push(this.salaryTemplateId);
      empSelectPage({
        pageNo: 1,
        pageSize: this.pagination.total,
        salaryTemplateSalaryIds: templateList.join(",")
      }).then(function (res) {
        _this9.empAllList = res.data.entities;
        account({
          companyId: _this9.companyId,
          salaryTemplateSalaryId: parseInt(_this9.salaryTemplateId),
          empSalaryList: _this9.empAllList,
          isInitiative: 1
        }).then(function (res) {
          _this9.buttonState = true;
          _this9.$notification["success"]({
            message: "成功",
            description: "计算成功"
          });
          // this.getEmpSelectPage();
          _this9.gitTemplateDetailList();
        });
      });
    },
    // 查询全部员工详情
    getEmpListAll: function getEmpListAll() {
      var _this10 = this;
      _getEmpListAll({
        companyId: this.companyId
      }).then(function (res) {
        _this10.empAllList = res.data;
      });
    },
    getDownload: function getDownload() {
      var _this11 = this;
      axios({
        url: this.url,
        params: this.params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: this.token
        }
      }).then(function (res) {
        if (res.data && res.data.type === "application/json") {
          var reader = new FileReader();
          reader.readAsText(res.data, "utf-8");
          reader.onload = function () {
            var resJson = JSON.parse(reader.result);
            _this11.$message.error(resJson.msg);
          };
        } else {
          var blob = new Blob([res.data], {
            type: "application/octet-stream"
          });
          var link = document.createElement("a");
          try {
            if (_this11.bankType === "HUAXIA_BANK") {
              link.download = _this11.excelName + ".txt";
            } else {
              link.download = _this11.excelName + ".xlsx";
            }
          } catch (err) {
            console.log("debug log --> ", res);
          }
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    bankChange: function bankChange(e) {
      this.bankType = e;
    },
    statisticalOk: function statisticalOk() {
      this.params = {
        bankType: this.bankType,
        salaryTemplateSalaryIds: this.salaryTemplateId
      };
      this.url = "/salary/api/v1/employeeSalaries/exportBankDeduction";
      this.excelName = "银行报盘详情";
      this.getDownload();
    },
    generateWage: function generateWage() {
      var _this12 = this;
      generateWageList({
        companyId: this.companyId,
        monthly: this.monthly,
        salaryTemplateSalaryId: parseInt(this.salaryTemplateId),
        yearly: this.yearly,
        tableName: this.tableName
      }).then(function (res) {
        _this12.tableId = res.data;
        _this12.excelChange();
        _this12.generatelVisible = false;
      });
    },
    excelChange: function excelChange(e) {
      if (e && e.visibleWrong) {
        this.excelVisible = e.visibleWrong;
      } else {
        this.excelVisible = false;
        this.$router.push({
          path: "../add-payslip-sort",
          query: {
            tableId: this.tableId,
            month: this.monthly,
            year: this.yearly,
            sendName: this.tableName
          }
        });
      }
    },
    showStatistical: function showStatistical() {
      this.statisticalVisible = true;
      this.bankType = undefined;
    },
    showGenerate: function showGenerate() {
      this.generatelVisible = true;
      this.tableName = undefined;
    },
    autoAdd: function autoAdd() {
      var _this13 = this;
      generatedBills({
        autoSystem: true,
        subjectType: "薪资账单",
        month: this.monthly,
        year: this.yearly,
        companyIdList: [this.companyId]
        // companyId: this.companyId,
        // companyName: this.companyName
      }).then(function (res) {
        _this13.addVisible = false;
        _this13.$notification["success"]({
          message: "success",
          description: "生成成功"
        });
      });
    },
    // 录入截止上月个税累计
    goTax: function goTax() {
      this.$router.push({
        path: "/wages/monthly/incomeTax",
        query: {
          monthly: this.monthly,
          yearly: this.yearly,
          salaryTemplateId: this.salaryTemplateId
        }
      });
    },
    // 倒入工资
    goImport: function goImport() {
      this.$router.push({
        path: "/wages/monthly/importWages",
        query: {
          monthly: this.monthly,
          yearly: this.yearly,
          salaryTemplateId: this.salaryTemplateId
        }
      });
    },
    // 同步计薪人员
    synchronous: function synchronous() {
      var _this14 = this;
      getnotDetailsList({
        salaryTemplateIds: this.templateId,
        monthly: this.monthly,
        yearly: this.yearly,
        companyId: this.companyId
      }).then(function (res) {});
      _synchronous({
        salaryTemplateSalaryId: this.salaryTemplateId,
        month: this.monthly,
        year: this.yearly,
        companyId: this.companyId
      }).then(function (res) {
        _this14.getEmpSelectPage();
      });
    },
    // 返回
    goBack: function goBack() {
      this.$router.push({
        path: "/wages/monthly",
        query: {
          isBack: true,
          monthly: this.monthly,
          yearly: this.yearly
        }
      });
    },
    // 导出
    download: function download(i) {
      this.salaryTemplateSalaryIdList = this.salaryTemplateId.split(",");
      var token = this.$store.state.auth.token;
      this.exportData = {
        channelId: this.channelId ? this.channelId : undefined,
        tag: this.tags.join(",") ? this.tags.join(",") : undefined,
        postType: this.postType ? this.postType : undefined,
        empName: this.inputName ? this.inputName : undefined,
        companyId: this.companyId,
        monthly: this.monthly,
        yearly: this.yearly,
        sourceId: this.salaryHeaderId || this.salaryTemplateId,
        moduleCode: this.isAll === "true" ? "monthly" : "template",
        // salaryTemplateSalaryIdList: this.salaryTemplateSalaryIdList
        salaryTemplateSalaryIds: this.salaryTemplateSalaryIdList.join(",")
      };
      this.url = "/salary/api/v1/employeeSalaries/export";
      this.excelName = "".concat(this.$store.state.hr.companyName, "_") + "员工工资导出详情" + ".xlsx";
      this.postDownload();
    },
    // 归档
    handleAbolition: function handleAbolition() {
      var _this = this;
      archive({
        salaryTemplateSalaryId: _this.salaryTemplateId
      }).then(function (res) {
        _this.buttonArchive.isArchive = true;
        _this.$router.go(-1);
        _this.$notification["success"]({
          message: "success",
          description: "已归档"
        });
      });
    },
    templateArchive: function templateArchive() {
      this.abolitionVisible = true;
    },
    selectChange: function selectChange() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getEmpSelectPage();
    },
    inputChange: function inputChange(e) {
      this.inputName = e;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getEmpSelectPage();
    },
    memberSelectedChange: function memberSelectedChange(e) {
      var _this15 = this;
      this.empList = [];
      this.empIdsList = [];
      e.forEach(function (item) {
        _this15.empList.push({
          empId: item.id,
          itemDetail: ""
        });
        _this15.empIdsList.push(item.id);
      });
      getEmpCreated({
        empIds: this.empIdsList,
        monthly: this.monthly,
        yearly: this.yearly
      }).then(function (res) {
        _this15.createdList = res.data;
        if (_this15.createdList.length) {
          _this15.empVisible = true;
        } else {
          _this15.handleEmpOk();
        }
      });
    },
    handleEmpOk: function handleEmpOk() {
      var _this16 = this;
      addEmpDetail({
        companyId: this.companyId,
        salaryTemplateSalaryId: parseInt(this.salaryTemplateId),
        empSalaryList: this.empList,
        monthly: this.monthly,
        yearly: this.yearly,
        isInitiative: 1
      }).then(function (res) {
        _this16.pagination.current = 1;
        _this16.pagination.pageSize = 10;
        _this16.getEmpSelectPage();
        _this16.gitTemplateDetailList();
        _this16.empVisible = false;
        _this16.$notification["success"]({
          message: "success",
          description: "添加成功"
        });
        _this16.getSelectSalaryGroup();
      });
    },
    onSelectChange2: function onSelectChange2(e, a) {
      var _this17 = this;
      this.selectRowKey = [];
      this.empIdList = [];
      this.selectRowKey = e;
      a.forEach(function (item) {
        _this17.empIdList.push(item.employeeSalaryId);
      });
    },
    replace: function replace() {
      var _this18 = this;
      deleteEmploye(this.empIdList).then(function (res) {
        _this18.handleEmpOk();
      });
    },
    addEmp: function addEmp() {},
    deleteEmployes: function deleteEmployes() {
      if (this.selectedRowId.length === 0) {
        this.$notification["error"]({
          message: "error",
          description: "删除失败，请选择需要删除的员工"
        });
      } else {
        var that = this;
        this.$confirm({
          title: "确认删除已选计薪人员吗?",
          onOk: function onOk() {
            deleteEmploye(that.selectedRowId).then(function (res) {
              that.$notification["success"]({
                message: "success",
                description: "删除成功"
              });
              that.selectedRowId = [];
              that.selectedRowKeys = [];
              that.buttonState = true;
              that.gitTemplateDetailList();
              that.getSelectSalaryGroup();
              setTimeout(function () {
                that.pagination.current = 1;
                that.pagination.pageSize = 10;
                that.getEmpSelectPage();
              }, 100);
            });
          },
          onCancel: function onCancel() {
            console.log("Cancel");
          }
        });
      }
    },
    // 表格选择
    onSelectChange: function onSelectChange(selectedRowKeys, selectedRow) {
      var _this19 = this;
      this.selectedRowId = [];
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length && this.isAll === false) {
        this.buttonState = false;
      } else {
        this.buttonState = true;
      }
      selectedRow.forEach(function (item, index) {
        _this19.selectedRowId.push(item.id);
      });
      this.selectedRow = JSON.parse(JSON.stringify(selectedRow));
    },
    // 分页
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getEmpSelectPage();
    },
    // 查询表头
    gitTemplateHeaderList: function gitTemplateHeaderList() {
      var _this20 = this;
      this.columns = JSON.parse(JSON.stringify(this.initColumns));
      gitTemplateHeader({
        salaryTemplateSalaryIds: this.salaryTemplateId
      }).then(function (res) {
        res.data.forEach(function (item, index) {
          if (_this20.isAll === "true") {
            _this20.columns.splice(_this20.columns.length - 8, 0, {
              title: item.name,
              dataIndex: item.headerId,
              key: item.headerId,
              width: 130
            });
          } else {
            _this20.columns.splice(_this20.columns.length - 7, 0, {
              title: item.name,
              dataIndex: item.headerId,
              key: item.headerId,
              width: 130
            });
          }
        });
      });
    },
    // 根据工资模板汇总id查询详情
    gitTemplateDetailList: function gitTemplateDetailList() {
      var _this21 = this;
      var templateList = [];
      templateList.push(this.salaryTemplateId);
      gitTemplateDetail({
        salaryTemplateSalaryIds: templateList.join(","),
        salaryGroupId: this.salaryGroupSalaryId
      }).then(function (res) {
        _this21.detailList = res.data;
        // this.monthly = res.data.monthly;
        // this.yearly = res.data.yearly;
        if (_this21.isAll === false) {
          // this.synchronous();
          _this21.getEmpSelectPage();
        } else {
          _this21.getEmpSelectPage();
        }
      });
    },
    // 根据工资模板汇总id查询薪资组
    getSelectSalaryGroup: function getSelectSalaryGroup() {
      var _this22 = this;
      var templateList = [];
      templateList.push(this.salaryTemplateId);
      selectSalaryGroup({
        salaryTemplateSalaryIds: templateList.join(",")
      }).then(function (res) {
        _this22.groupList = res.data;
        res.data.forEach(function (item) {
          _this22.allGroupId.push(item.id);
        });
        // this.getEmpSelectPage();
      });
    },
    // 根据薪资组汇总表分页查询员工月工资
    getEmpSelectPage: function getEmpSelectPage() {
      var _this23 = this;
      var templateList = [];
      templateList.push(this.salaryTemplateId);
      var groupId = [];
      if (this.salaryGroupSalaryId) {
        groupId.push(this.salaryGroupSalaryId);
      }
      this.loading = true;
      empSelectPage({
        channelId: this.channelId,
        tag: this.tags.join(",") ? this.tags.join(",") : undefined,
        postType: this.postType,
        companyId: this.companyId,
        empStatus: this.empStatus,
        salaryGroupSalaryId: this.salaryGroupSalaryId,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        empName: this.inputName,
        salaryTemplateSalaryIds: templateList.join(",")
      }).then(function (res) {
        _this23.loading = false;
        _this23.pagination.total = res.data.entityCount; // 共多少条
        _this23.pagination.pageSize = res.data.pageSize; // 每页多少条
        _this23.pagination.current = res.data.pageNo; // 当前第几页
        _this23.dataList = res.data.entities;
        if (res.data.entities && res.data.entities.length) {
          res.data.entities.forEach(function (item1, index1) {
            var key = null;
            var value = null;
            if (item1.itemDetail) {
              JSON.parse(item1.itemDetail).forEach(function (item2, index2) {
                key = item2.name;
                value = item2.amount;
                _this23.dataList[index1][key] = value;
              });
            }
          });
        }
      });
    },
    // 选择薪资组变化
    handleChange: function handleChange(e) {
      this.salaryGroupSalaryId = e;
      this.getEmpSelectPage();
      // this.gitTemplateDetailList();
    },
    goCalculation: function goCalculation() {
      this.$router.push({
        path: "/wages/monthly/calculation",
        query: {
          personalPeriod: this.personalPeriod,
          salaryTemplateId: this.salaryTemplateId,
          templateId: this.templateId,
          monthly: this.monthly,
          yearly: this.yearly
        }
      });
    },
    goEdit: function goEdit() {
      sessionStorage.setItem("selectedRow", JSON.stringify(this.selectedRow));
      sessionStorage.setItem("salaryColumns", JSON.stringify(this.columns));
      this.$router.push({
        path: "/wages/monthly/editDetails",
        query: {
          id: this.selectedRowId.join(","),
          date: this.monthly >= 10 ? "".concat(this.yearly) + "-" + "".concat(this.monthly) : "".concat(this.yearly) + "-0" + "".concat(this.monthly)
        }
      });
    },
    goImportKQ: function goImportKQ() {
      this.visibleKQ = true;
    },
    upDate: function upDate() {
      this.pagination.current = 1;
      var v = this.columns.filter(function (item) {
        return item.title === "考勤工资";
      });
      if (v.length <= 0) {
        this.columns.splice(this.columns.length - 1, 0, {
          title: "考勤工资",
          dataIndex: "attendancePay",
          key: "attendancePay",
          width: 120
        });
      }
      this.gitTemplateDetailList();
      // this.getEmpSelectPage();
    }
  }
};