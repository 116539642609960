import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 详情 ")]), _c("div", {
    staticClass: "pageBox",
    style: _vm.pageState === "company" ? [{
      paddingTop: null
    }] : null
  }, [_c("div", {
    staticClass: "leftBox"
  }, [_c("div", {
    staticClass: "detailBox",
    staticStyle: {
      "justify-content": "center"
    }
  }, [_c("a-tooltip", [_c("template", {
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.detail.schemeName) + " ")]), _c("div", {
    staticClass: "planTitle",
    staticStyle: {
      "font-size": "18px",
      "font-weight": "500",
      color: "#3c3d43",
      width: "240px",
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.schemeName) + " ")])], 2)], 1), _c("div", {
    staticClass: "detailBox",
    staticStyle: {
      "justify-content": "center"
    }
  }, [_c("div", {
    style: [{
      padding: "0 8px 0 8px"
    }, {
      height: "22px"
    }, {
      background: "".concat(_vm.selectList[_vm.detail.state].background)
    }, {
      borderRadius: "2px"
    }, {
      fontSize: "12px"
    }, {
      border: "1px solid ".concat(_vm.selectList[_vm.detail.state].color)
    }, {
      color: "".concat(_vm.selectList[_vm.detail.state].color)
    }, {
      textAlign: "center"
    }]
  }, [_vm._v(" " + _vm._s(_vm.selectList[_vm.detail.state].name) + " ")])]), _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailTitle"
  }, [_vm._v(_vm._s(_vm.pageState === "company" ? "人资公司：" : "保险公司："))]), _c("div", {
    staticClass: "detailContent"
  }, [_vm._v(_vm._s(_vm.pageState === "company" ? _vm.detail.customerName : _vm.detail.companyName))])]), _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailTitle"
  }, [_vm._v("保险周期：")]), _c("div", {
    staticClass: "detailContent"
  }, [_vm._v(" " + _vm._s(_vm.moment(_vm.detail.insEffectTime).format("YYYY-MM-DD") + "~" + _vm.moment(_vm.detail.insFailureTime).format("YYYY-MM-DD")) + " ")])]), _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailTitle"
  }, [_vm._v("订单编号：")]), _c("div", {
    staticClass: "detailContent"
  }, [_vm._v(_vm._s(_vm.detail.id))])]), _c("div", {
    staticClass: "detailBox"
  }, [_c("div", {
    staticClass: "detailTitle"
  }, [_vm._v("最新提交时间：")]), _c("div", {
    staticClass: "detailContent"
  }, [_vm._v(_vm._s(_vm.moment(_vm.detail.latestSubmitTime).format("YYYY-MM-DD HH:mm:ss")))])]), _vm.pageState === "customer" || _vm.pageState === "company" && _vm.detail.state !== 0 ? _c("div", {
    staticStyle: {
      width: "100%",
      height: "1px",
      background: "#e8e8e8",
      "margin-top": "40px"
    }
  }) : _vm._e(), _vm.pageState === "customer" ? _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "margin-top": "36px"
    }
  }, [_vm.detail.state === 0 ? _c("a-button", {
    on: {
      click: _vm.undoOrder
    }
  }, [_vm._v("撤销")]) : _vm._e(), _vm.detail.state === 1 || _vm.detail.state === 2 ? _c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.uploadAgain
    }
  }, [_vm._v("重新上传")]) : _vm._e()], 1) : _vm._e(), _vm.pageState === "company" && _vm.detail.state === 0 ? _c("div", {
    staticStyle: {
      "margin-top": "18px"
    }
  }, [_vm.latestTable ? _c("div", {
    staticStyle: {
      width: "260px",
      height: "76px",
      background: "#ffffff",
      "border-radius": "4px",
      border: "1px solid #e8e8e8",
      display: "flex",
      "align-items": "center",
      "padding-left": "16px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "48px",
      height: "48px"
    },
    attrs: {
      src: require("@/assets/img/detailIcon.png"),
      alt: ""
    }
  }), _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.downLoadFile(_vm.latestTable);
      }
    }
  }, [_vm._v("该订单最新投保明细单"), _c("a-icon", {
    attrs: {
      type: "download"
    }
  })], 1)], 1) : _vm._e(), _vm.latestAttachment && _vm.latestAttachment.length ? _c("div", {
    staticStyle: {
      display: "flex",
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticStyle: {
      "min-width": "72px",
      "font-size": "14px",
      "font-weight": "400",
      color: "#a6a8b4"
    }
  }, [_vm._v("其他附件：")]), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-wrap": "wrap"
    }
  }, _vm._l(_vm.latestAttachment, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        display: "flex",
        "justify-content": "space-between",
        "align-items": "center",
        "min-width": "165px",
        height: "28px",
        "border-radius": "3px",
        border: "1px solid #e8e8e8",
        cursor: "pointer",
        margin: "0 0 8px 8px"
      },
      on: {
        click: function click($event) {
          return _vm.downLoadFile(item.url);
        }
      }
    }, [_c("a-icon", {
      staticClass: "primaryColor",
      attrs: {
        type: "link"
      }
    }), _c("div", {
      staticStyle: {
        width: "120px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("a-icon", {
      staticClass: "primaryColor",
      attrs: {
        type: "download"
      }
    })], 1);
  }), 0)]) : _vm._e()]) : _vm._e()]), _c("div", {
    staticClass: "rightBox"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "contentTitle"
  }, [_vm._v("投保方案明细")]), _c("a-pagination", {
    attrs: {
      pageSize: 5,
      total: _vm.detail.personTypeList.length
    },
    on: {
      change: _vm.onChange
    }
  })], 1), _vm.detail.state === 3 || _vm.detail.state === 4 || _vm.detail.state === 5 ? _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-start",
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "label",
    staticStyle: {
      height: "auto"
    }
  }, [_vm._v("费用合计：")]), _c("div", {
    staticClass: "combined"
  }, [_vm._v(_vm._s(_vm.pageState === "customer" ? _vm.detail.totalFee : _vm.detail.actualTotalFee))])]) : _vm._e(), _c("div", {
    staticStyle: {
      "margin-top": "6px",
      display: "flex",
      "flex-wrap": "wrap"
    }
  }, _vm._l(_vm.detail.personTypeList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        width: "20%"
      }
    }, [_vm.typeCurrent === 1 && index < 5 || _vm.typeCurrent === 2 && index >= 5 ? _c("div", {
      staticStyle: {
        padding: "16px 14px",
        margin: "12px 12px 0 0",
        "min-height": "80px",
        background: "#ffffff",
        "box-shadow": "0px 4px 12px 0px rgba(106, 102, 246, 0.1)",
        "border-radius": "8px"
      }
    }, [_c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.typeName) + " ")]), _c("div", {
      staticStyle: {
        "font-size": "14px",
        "font-weight": "500",
        color: "#55565d",
        width: "98px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.typeName) + " ")])], 2), _c("div", {
      staticStyle: {
        "margin-top": "8px",
        display: "flex",
        "flex-wrap": "wrap"
      }
    }, [_c("div", {
      staticClass: "label",
      style: [{
        minWidth: _vm.pageState === "customer" ? "66px" : "88px"
      }]
    }, [_vm._v(" " + _vm._s(_vm.pageState === "customer" ? "保险月费:" : "实际保险月费:") + " ")]), _c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_vm._v(" " + _vm._s(_vm.pageState === "customer" ? item.monthAmount : item.realityMonthAmount) + " ")]), _c("div", {
      staticClass: "value",
      staticStyle: {
        "margin-left": "4px",
        width: "48px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(_vm.pageState === "customer" ? item.monthAmount : item.realityMonthAmount) + " ")])], 2)], 1), _vm.detail.state === 3 || _vm.detail.state === 4 || _vm.detail.state === 5 ? _c("div", {
      staticStyle: {
        "margin-top": "8px",
        display: "flex",
        "flex-wrap": "wrap"
      }
    }, [_c("div", {
      staticClass: "label",
      staticStyle: {
        "min-width": "66px"
      }
    }, [_vm._v("投保人数:")]), _c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.personCount) + " ")]), _c("div", {
      staticClass: "value",
      staticStyle: {
        width: "48px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.personCount) + " ")])], 2)], 1) : _vm._e()], 1) : _vm._e()]);
  }), 0), _c("div", {
    staticClass: "contentTitle",
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._v("审核记录")]), _c("div", {
    staticClass: "listBox"
  }, _vm._l(_vm.recordList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "recordItem"
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        "justify-content": "space-between",
        "align-items": "center"
      }
    }, [_c("div", {
      staticStyle: {
        display: "flex"
      }
    }, [_c("div", {
      style: [{
        padding: "0 8px 0 8px"
      }, {
        height: "22px"
      }, {
        lineHeight: "20px"
      }, {
        background: "".concat(_vm.selectList[item.state].background)
      }, {
        borderRadius: "16px"
      }, {
        fontSize: "12px"
      }, {
        border: "1px solid ".concat(_vm.selectList[item.state].color)
      }, {
        color: "".concat(_vm.selectList[item.state].color)
      }, {
        textAlign: "center"
      }]
    }, [_vm._v(" " + _vm._s(_vm.selectList[item.state].name) + " ")]), _c("div", {
      staticStyle: {
        "margin-left": "16px"
      }
    }, [_c("span", {
      staticClass: "detailTitle"
    }, [_vm._v("反馈时间：")]), _c("span", {
      staticClass: "detailContent"
    }, [_vm._v(_vm._s(_vm.moment(item.gmtCreate).format("YYYY-MM-DD HH:mm:ss")))])])]), _c("a-button", {
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          return _vm.downLoadFile(item.detailFile);
        }
      }
    }, [_vm._v("本次上传的投保人员明细表"), _c("a-icon", {
      attrs: {
        type: "download"
      }
    })], 1)], 1), (item.state === 1 || item.state === 3) && item.auditResultsCause ? _c("div", {
      staticStyle: {
        display: "flex",
        "margin-top": "14px"
      }
    }, [_c("div", {
      staticClass: "detailTitle"
    }, [_vm._v(" " + _vm._s(item.state === 1 ? "拒绝原因：" : "通过原因：") + " ")]), _c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.auditResultsCause) + " ")]), _c("div", {
      staticClass: "detailContent",
      staticStyle: {
        width: "500px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden"
      }
    }, [_vm._v(" " + _vm._s(item.auditResultsCause) + " ")])], 2)], 1) : _vm._e(), item.companyAttachment && item.companyAttachment.length || item.customerAttachment && item.customerAttachment.length ? _c("div", {
      staticStyle: {
        display: "flex",
        "margin-top": "14px"
      }
    }, [_c("div", {
      staticClass: "detailTitle",
      staticStyle: {
        "min-width": "120px"
      }
    }, [_vm._v(" " + _vm._s(item.state === 0 ? "由人资公司上传：" : "由保险公司上传：") + " ")]), item.state === 0 ? _c("div", {
      staticStyle: {
        display: "flex",
        "flex-wrap": "wrap"
      }
    }, _vm._l(item.customerAttachment, function (item, index) {
      return _c("div", {
        key: index,
        staticStyle: {
          height: "28px",
          "border-radius": "3px",
          border: "1px solid #e8e8e8",
          cursor: "pointer",
          margin: "0 0 8px 8px",
          display: "flex",
          "align-items": "center",
          "justify-content": "space-between"
        },
        on: {
          click: function click($event) {
            return _vm.downLoadFile(item.url);
          }
        }
      }, [_c("a-icon", {
        staticClass: "primaryColor",
        attrs: {
          type: "link"
        }
      }), _c("a-tooltip", [_c("template", {
        slot: "title"
      }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("div", {
        staticStyle: {
          "text-align": "center",
          width: "120px",
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden"
        }
      }, [_vm._v(" " + _vm._s(item.name) + " ")])], 2), _c("a-icon", {
        staticClass: "primaryColor",
        attrs: {
          type: "download"
        }
      })], 1);
    }), 0) : _c("div", {
      staticStyle: {
        display: "flex",
        "flex-wrap": "wrap"
      }
    }, _vm._l(item.companyAttachment, function (item, index) {
      return _c("div", {
        key: index,
        staticStyle: {
          height: "28px",
          "border-radius": "3px",
          border: "1px solid #e8e8e8",
          cursor: "pointer",
          margin: "0 0 8px 8px",
          "line-height": "26px",
          display: "flex",
          "align-items": "center",
          "justify-content": "space-between"
        },
        on: {
          click: function click($event) {
            return _vm.downLoadFile(item.url);
          }
        }
      }, [_c("a-icon", {
        staticClass: "primaryColor",
        attrs: {
          type: "link"
        }
      }), _c("a-tooltip", [_c("template", {
        slot: "title"
      }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c("div", {
        staticStyle: {
          "text-align": "center",
          width: "120px",
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden"
        }
      }, [_vm._v(" " + _vm._s(item.name) + " ")])], 2), _c("a-icon", {
        staticClass: "primaryColor",
        attrs: {
          type: "download"
        }
      })], 1);
    }), 0)]) : _vm._e()]);
  }), 0), _vm.recordList && _vm.recordList.length ? _c("div", {
    staticStyle: {
      width: "100%",
      display: "flex",
      padding: "24px 0 24px 24px",
      "justify-content": "flex-end",
      "align-items": "center"
    }
  }, [_c("a-pagination", {
    attrs: {
      pageSize: 5,
      total: _vm.total
    },
    on: {
      change: _vm.onPageChange
    },
    model: {
      value: _vm.current,
      callback: function callback($$v) {
        _vm.current = $$v;
      },
      expression: "current"
    }
  })], 1) : _vm._e()]), _c("a-modal", {
    attrs: {
      visible: _vm.againVisible,
      title: "重新上传",
      width: 600
    },
    on: {
      ok: _vm.againOK,
      cancel: _vm.againCancel
    }
  }, [_c("div", {
    staticClass: "againBox"
  }, [_c("div", {
    staticClass: "alignItem"
  }, [_c("div", {
    staticClass: "detailTitle"
  }, [_vm._v("保险周期：")]), _c("div", {
    staticClass: "detailContent"
  }, [_vm._v(" " + _vm._s(_vm.moment(_vm.detail.insEffectTime).format("YYYY-MM-DD") + "~" + _vm.moment(_vm.detail.insFailureTime).format("YYYY-MM-DD")) + " ")])]), _c("div", {
    staticClass: "alignItem"
  }, [_c("div", {
    staticClass: "detailTitle",
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v("投保明细表：")]), _c("div", {
    staticClass: "detailContent"
  }, [_c("a-upload", {
    ref: "upload",
    attrs: {
      "file-list": _vm.fileList,
      headers: _vm.headers,
      action: "/file/api/v1/general/upload/attachment",
      accept: ".xls, .xlsx",
      name: "files",
      showUploadList: {
        showDownloadIcon: true
      }
    },
    on: {
      change: _vm.handleChange,
      download: _vm.download
    }
  }, [_c("a-button", [_vm._v(_vm._s(!_vm.fileList.length ? "上传文件" : "重新上传"))])], 1)], 1)]), _c("div", {
    staticClass: "alignItem"
  }, [_c("div", {
    staticClass: "detailTitle",
    staticStyle: {
      "min-width": "99px",
      "margin-top": "8px"
    }
  }, [_vm._v("其他附件：")]), _c("div", {
    staticClass: "detailContent"
  }, [_c("a-upload", {
    ref: "upload",
    attrs: {
      "file-list": _vm.fileListMultiple,
      headers: _vm.headers,
      action: "/file/api/v1/general/upload/attachment",
      name: "files",
      showUploadList: {
        showDownloadIcon: true
      }
    },
    on: {
      change: _vm.handleChangeMultiple,
      download: _vm.download
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("添加文件")])], 1)], 1)])])])], 1), _vm.pageState === "company" && _vm.detail.state === 0 && _vm.$hasPermission("insurance:insuranceService.auditList:audit") ? _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      height: "60px",
      width: "100%",
      "box-shadow": "inset 0px 1px 0px 0px #e8e8e8",
      background: "#ffffff",
      position: "fixed",
      bottom: "0",
      left: "0"
    }
  }, [_c("a-button", {
    on: {
      click: function click($event) {
        return _vm.audit(1);
      }
    }
  }, [_vm._v("驳回")]), _c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.audit(2);
      }
    }
  }, [_vm._v("通过")])], 1) : _vm._e(), _c("a-modal", {
    attrs: {
      width: 600,
      title: _vm.passOrRefuse === 1 ? "驳回理由" : "审核通过",
      visible: _vm.auditVisible
    },
    on: {
      cancel: function cancel($event) {
        _vm.auditVisible = false;
      },
      ok: _vm.auditOk
    }
  }, [_vm.detail.personTypeList && _vm.passOrRefuse === 2 ? _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "0 36px",
      width: "526px",
      height: "48px",
      background: "#fafafa",
      "box-shadow": "inset 0px -1px 0px 0px #e8e8e8",
      display: "flex",
      "justify-content": "flex-start",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "500",
      color: "#252525"
    }
  }, [_vm._v("职业分类")]), _c("div", {
    staticStyle: {
      "margin-left": "90px",
      "font-size": "14px",
      "font-weight": "500",
      color: "#252525"
    }
  }, [_vm._v("实际保险月费")]), _c("div", {
    staticStyle: {
      "margin-left": "72px",
      "font-size": "14px",
      "font-weight": "500",
      color: "#252525"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#fa5051"
    }
  }, [_vm._v("*")]), _vm._v("投保人数 ")])]), _vm._l(_vm.detail.personTypeList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "flex-start",
        width: "526px",
        height: "48px",
        background: "#ffffff",
        "box-shadow": "inset 0px -1px 0px 0px #e8e8e8"
      }
    }, [_c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_c("div", [_vm._v(_vm._s(item.typeName))])]), _c("div", {
      staticClass: "value",
      staticStyle: {
        width: "120px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden",
        "margin-left": "36px"
      }
    }, [_vm._v(" " + _vm._s(item.typeName) + " ")])], 2), _c("a-tooltip", [_c("template", {
      slot: "title"
    }, [_c("div", [_vm._v(_vm._s(item.realityMonthAmount))])]), _c("div", {
      staticClass: "value",
      staticStyle: {
        width: "120px",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden",
        "margin-left": "30px"
      }
    }, [_vm._v(" " + _vm._s(item.realityMonthAmount) + " ")])], 2), _c("a-input-number", {
      staticStyle: {
        width: "180px",
        "margin-left": "32px"
      },
      attrs: {
        formatter: _vm.limitNumber,
        parser: _vm.limitNumber,
        min: 0,
        placeholder: "请输入"
      },
      on: {
        change: _vm.calculate
      },
      model: {
        value: item.personCount,
        callback: function callback($$v) {
          _vm.$set(item, "personCount", $$v);
        },
        expression: "item.personCount"
      }
    })], 1);
  })], 2) : _vm._e(), _vm.detail.personTypeList && _vm.passOrRefuse === 2 ? _c("div", {
    staticStyle: {
      "margin-top": "18px",
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "margin-bottom": "18px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "detailTitle"
  }, [_vm._v("保险周期：")]), _c("div", {
    staticClass: "detailContent"
  }, [_vm._v(" " + _vm._s(_vm.moment(_vm.detail.insEffectTime).format("YYYY-MM-DD") + "~" + _vm.moment(_vm.detail.insFailureTime).format("YYYY-MM-DD")) + " ")])]), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "label",
    staticStyle: {
      height: "auto"
    }
  }, [_vm._v("实际费用合计：")]), _c("div", {
    staticClass: "combined"
  }, [_vm._v(_vm._s(_vm.combined))])])]) : _vm._e(), _c("a-textarea", {
    attrs: {
      placeholder: _vm.passOrRefuse === 1 ? "驳回理由" : "通过理由",
      "auto-size": {
        minRows: 3,
        maxRows: 5
      }
    },
    model: {
      value: _vm.auditResultsCause,
      callback: function callback($$v) {
        _vm.auditResultsCause = $$v;
      },
      expression: "auditResultsCause"
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "12px",
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "detailTitle",
    staticStyle: {
      "min-width": "32px"
    }
  }, [_vm._v("附件：")]), _c("div", [_c("a-upload", {
    ref: "upload",
    attrs: {
      "file-list": _vm.auditFileList,
      headers: _vm.headers,
      action: "/file/api/v1/general/upload/attachment",
      showUploadList: {
        showDownloadIcon: true
      },
      name: "files"
    },
    on: {
      change: _vm.handleChangeAudit,
      download: _vm.download
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("添加附件")], 1)], 1)], 1)])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };