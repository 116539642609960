import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "填写密码信息",
      destroyOnClose: true,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 14
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "密码类型"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["passwordType", {
        initialValue: 0,
        rules: [{
          required: true,
          message: "密码类型不能为空"
        }]
      }],
      expression: "[\n          'passwordType',\n          {\n            initialValue: 0,\n            rules: [\n              {\n                required: true,\n                message: '密码类型不能为空'\n              }\n            ]\n          }\n        ]"
    }],
    attrs: {
      placeholder: "请选择"
    }
  }, _vm._l(_vm.typeArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _vm.form.getFieldValue("passwordType") == 0 ? _c("a-form-item", {
    attrs: {
      label: "申报密码"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["declarePassword", {
        rules: [{
          required: true,
          message: "申报密码不能为空"
        }]
      }],
      expression: "['declarePassword', { rules: [{ required: true, message: '申报密码不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入密码",
      maxLength: 50
    }
  })], 1) : _vm._e(), _vm.form.getFieldValue("passwordType") == 2 ? _c("a-form-item", {
    attrs: {
      label: "实名账号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["realNameAccount", {
        rules: [{
          required: true,
          message: "实名账号不能为空"
        }]
      }],
      expression: "['realNameAccount', { rules: [{ required: true, message: '实名账号不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入账号",
      maxLength: 50
    }
  })], 1) : _vm._e(), _vm.form.getFieldValue("passwordType") == 2 ? _c("a-form-item", {
    attrs: {
      label: "实名密码"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["realNamePassword", {
        rules: [{
          required: true,
          message: "实名密码不能为空"
        }]
      }],
      expression: "['realNamePassword', { rules: [{ required: true, message: '实名密码不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入密码",
      maxLength: 50
    }
  })], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };