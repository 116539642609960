import "core-js/modules/es.number.constructor.js";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    params: {
      type: Object,
      default: function _default() {}
    },
    status: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      dataSource: {}
    };
  },
  computed: {},
  watch: {
    params: {
      handler: function handler() {
        this.loadData();
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    setStatus: function setStatus(val) {
      this.$emit("update:status", val);
    },
    loadData: function loadData() {
      var _this = this;
      if (this.params && this.params.type && this.params.type.id) {
        this.$request({
          url: "/hr/api/v1/hrInsurance/recordListStatistics",
          method: "post",
          data: this.params
        }).then(function (res) {
          _this.dataSource = res.data;
        }).catch(function (err) {
          console.log("recordListStatistics", err);
        });
      }
    }
  }
};