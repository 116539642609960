import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import TreeNode from "./TreeNode";
import { getRoleList } from "@/api/company/member";
import { mapGetters } from "vuex";
export default {
  components: {
    TreeNode: TreeNode
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "角色"
    },
    defaultSelectedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      roleList: [],
      selectedListTemp: [],
      selectedKeysBeforeSearch: [],
      params: {
        name: ""
      },
      copyList: []
    };
  },
  computed: _objectSpread({}, mapGetters(["companyRoleList"])),
  watch: {
    show: function show(val) {
      if (val) {
        this.copyList = JSON.parse(JSON.stringify(this.defaultSelectedKeys));
        this.selectedListTemp = this.defaultSelectedKeys;
        if (this.defaultSelectedKeys.length !== this.selectedListTemp.length) {
          this.roleList = this.formatCompanyData(this.companyRoleList);
        }
      }
    }
  },
  mounted: function mounted() {
    this.roleList = this.formatCompanyData(this.companyRoleList);
  },
  methods: {
    onChange: function onChange(item) {
      var isChose = item.isSelected;
      if (isChose) {
        return this.selectedListTemp.push(item);
      }
      this.selectedListTemp = this.selectedListTemp.filter(function (i) {
        return i.id !== item.id;
      });

      // this.ergodic(item)
    },
    ergodicInit: function ergodicInit(isSearch) {
      var selectedKeys = [].concat(this.defaultSelectedKeys, isSearch ? this.selectedKeysBeforeSearch : []);
      this.roleList.map(function (item) {
        if (selectedKeys.includes(item.id.toString())) {
          item.isSelected = true;
        }
      });
      this.selectedListTemp = this.roleList.filter(function (item) {
        return item.isSelected;
      });
    },
    ergodic: function ergodic(item) {
      this.selectedListTemp = this.roleList.filter(function (item) {
        return item.isSelected;
      });
    },
    // 获取部门树
    getSelectDepTree: function getSelectDepTree(query) {
      var _this = this;
      var params = {
        subjectType: "company"
        // subjectId: this.$store.state.hr.companyId
      };

      if (query) {
        params["roleQuery"] = query;
        params["name"] = query;
      }
      getRoleList(params).then(function (res) {
        _this.roleList = _this.formatCompanyData(res.data);
        // this.ergodicInit(true)
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    formatCompanyData: function formatCompanyData(company) {
      var _this2 = this;
      return company.map(function (item) {
        return {
          id: item.id,
          name: item.name,
          isSelected: _this2.selectedListTemp.map(function (i) {
            return i.id;
          }).includes(item.id)
        };
      });
    },
    searchPeople: function searchPeople(query) {
      this.params.name = query;
      this.selectedKeysBeforeSearch = this.selectedListTemp.map(function (item) {
        return item.id;
      });
      this.getSelectDepTree(query);
    },
    deleteItem: function deleteItem(item) {
      item.isSelected = false;
      this.ergodic(item);
    },
    ok: function ok() {
      var copyList = this.selectedListTemp.map(function (i) {
        return i.id;
      });
      this.roleList.forEach(function (item) {
        item.isSelected = copyList.includes(item.id);
      });
      this.$emit("update:show", false);
      this.$emit("change", this.selectedListTemp);
    },
    cancel: function cancel() {
      var copyList = this.copyList.map(function (i) {
        return i.id;
      });
      this.roleList.forEach(function (item) {
        item.isSelected = copyList.includes(item.id);
      });
      this.$emit("update:show", false);
      this.$emit("change", this.copyList);
    }
  }
};