import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import request from "@/utils/request";
var state = {
  talentPoolTempId: "",
  isCancel: null,
  adminUsers: [],
  positionTypes: []
};
var mutations = {
  talentPoolTempId: function talentPoolTempId(state, payload) {
    state.talentPoolTempId = payload;
  },
  isCancel: function isCancel(state, payload) {
    state.isCancel = payload;
  },
  updatePostionTypes: function updatePostionTypes(state, payload) {
    state.positionTypes = payload;
  },
  updateAdminUsers: function updateAdminUsers(state, payload) {
    state.adminUsers = payload;
  }
};
var actions = {
  loadPositionTypeAct: function loadPositionTypeAct(_ref) {
    var commit = _ref.commit;
    request({
      url: "/recruit2/api/v1/positionTypes/selectPositionType",
      method: "GET"
    }).then(function (res) {
      commit("updatePostionTypes", res.data);
    }).catch(function (err) {
      console.log("loadPositionTypeAct", err);
    });
  },
  loadAdminUsersAct: function loadAdminUsersAct(_ref2) {
    var commit = _ref2.commit;
    request({
      url: "/hr/api/v1/users/listUserHasRoleInSite",
      method: "get"
    }).then(function (res) {
      commit("updateAdminUsers", res.data.map(function (item) {
        return {
          label: item.name,
          value: item.id,
          phone: item.phone
        };
      }));
    }).catch(function (err) {
      console.log("loadAdminUsersAct", err);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};