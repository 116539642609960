import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { getSalaryGroups, deleteSalaryGroup } from "@/api/wages/set";
var columns = [{
  title: "薪资组名称",
  dataIndex: "name",
  key: "name",
  width: 250
}, {
  title: "工资表模板",
  dataIndex: "salaryTemplateName",
  key: "salaryTemplateName",
  width: 150,
  scopedSlots: {
    customRender: "salaryTemplateName"
  }
}, {
  title: "计税规则",
  dataIndex: "taxName",
  key: "taxName",
  width: 150,
  scopedSlots: {
    customRender: "taxName"
  }
}, {
  title: "适用范围",
  key: "range",
  dataIndex: "range",
  width: 300,
  scopedSlots: {
    customRender: "range"
  }
}, {
  title: "操作",
  key: "action",
  scopedSlots: {
    customRender: "action"
  },
  width: 150
}];
var monthRule = ["按正式/调薪后的工资计算", "按试用期/调薪前的工资计算", "按转正/调薪前后的工资混合计算"];
var taxRule = ["工资薪金所得税", "劳动报酬所得税", "不计税"];
export default {
  data: function data() {
    return {
      deleteId: "",
      groupList: [],
      companyId: "",
      columns: columns,
      groupVisible: false,
      loading: true,
      monthRule: monthRule,
      taxRule: taxRule
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.getData();
  },
  methods: {
    editClass: function editClass(id) {
      this.$router.push({
        path: "/wages/set/group/add",
        query: {
          isEdit: 1,
          groupId: id
        }
      });
    },
    handleDelete: function handleDelete() {
      var _this = this;
      deleteSalaryGroup(this.deleteId).then(function (res) {
        _this.groupVisible = false;
        _this.$message.success("删除成功");
        _this.getData();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleVisible: function handleVisible(id) {
      this.deleteId = id;
      this.groupVisible = true;
    },
    addGroup: function addGroup() {
      this.$router.push({
        path: "/wages/set/group/add",
        query: {
          isEdit: 0
        }
      });
    },
    getData: function getData() {
      var _this2 = this;
      this.loading = true;
      var data = {
        companyId: this.companyId
      };
      getSalaryGroups(data).then(function (res) {
        _this2.loading = false;
        _this2.groupList = res.data;
        _this2.groupList.forEach(function (item, index) {
          if (item.isAllEmp === true) {
            item.range = "全部员工";
          }
          if (item.isAllEmp === false) {
            if (item.empInformationList && item.empInformationList.length > 0) {
              item.range = "".concat(item.empInformationList[0].name, "\u7B49").concat(item.empInformationList.length, "\u540D\u5458\u5DE5");
            } else item.range = "";
          }
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this2.loading = false;
      });
    }
  }
};