import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import moment from "moment";
import { mapState } from "vuex";
import { userApproDetails, approvalHandlerAgreeReq, approvalHandlerRejectReq, pageRefresh as _pageRefresh, getResults, returnAuthorizationUrl, getWeiXinList as _getWeiXinList, weixinPaySalary, paySalary, sendCode as _sendCode, huaBeiPaySalary, bankPaySalary } from "./api";
import ApprovalProcess from "./components/approvalProcess";
import { getApprovalStatus, getApprovalStatusColorBlue, typeFun, getPayStatus, getPayStatusApplyFor, getPayStatusApplyForSuccess } from "./funcs";
import approvalDetail from "./components/approvalDetail";
import img from "@/assets/img/shebaopaidan2x.png";
import titborder from "../applyFor/component/titborder.vue";
export default {
  name: "",
  components: {
    ApprovalProcess: ApprovalProcess,
    approvalDetail: approvalDetail,
    titborder: titborder
  },
  props: {},
  data: function data() {
    return {
      weixinList: [],
      paySelectItem: null,
      remark: "",
      placeholder: "",
      confirmLoading: false,
      visible: false,
      Title: "",
      subMchid: undefined,
      approvalNodes: [],
      approval: null,
      payStatus: null,
      Verification: false,
      img: img,
      codeBySend: undefined,
      issueUserName: null,
      clickable: true,
      buttonState: false,
      currentTime: 60,
      weiXinPay: false,
      contentSend: "发送验证码",
      issueCreate: null,
      pageState: false,
      applyForSubjectStatus: 0
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr", "company"])), {}, {
    companyName: function companyName() {
      return this.hr.companyName;
    },
    approvalId: function approvalId() {
      return this.$route.params.approvalId;
    },
    userApprovalId: function userApprovalId() {
      return this.$route.params.userApprovalId;
    },
    dynamicValueMap: function dynamicValueMap() {
      return {
        applyUser: this.hr.companyEmployeeList,
        oldDepartment: this.hr.companyDepartmentBaseList,
        newDepartment: this.hr.companyDepartmentBaseList,
        oldPosition: this.hr.companyPositionBaseList,
        newPosition: this.hr.companyPositionBaseList
      };
    },
    dynamicValueMapKeys: function dynamicValueMapKeys() {
      return Object.keys(this.dynamicValueMap);
    },
    relationDataLoaded: function relationDataLoaded() {
      return this.company.companyEmployeeList.length > 0 && this.company.companyPositionBaseList.length > 0 && this.company.companyDepartmentBaseList.length > 0;
    }
  }),
  // watch: {
  //   relationDataLoaded: {
  //     handler(newVal) {
  //       if (newVal) {
  //       }
  //     },
  //     immediate: true
  //   }
  // },
  created: function created() {
    // console.log("this.approvalRecord", this.approvalRecord);
    this.pageState = !!this.$route.query.pageState;
    if (this.pageState) {
      this.approvalRecord = JSON.parse(this.$route.query.record);
      this.pageRefresh();
      this.getWeiXinList();
    } else {
      this.approvalRecord = JSON.parse(this.$route.query.record);
      this.loadData();
    }
  },
  mounted: function mounted() {},
  methods: {
    sendCode: function sendCode(e) {
      var _this = this;
      console.log(e);
      if (this.clickable) {
        this.clickable = false;
        this.contentSend = this.currentTime + "s";
        var timer = setInterval(function () {
          _this.currentTime--;
          _this.contentSend = _this.currentTime + "s";
          if (_this.currentTime <= 0) {
            _this.clickable = true;
            _this.contentSend = "重新获取";
            _this.currentTime = 60;
            clearInterval(timer);
          }
        }, 1000);
        _sendCode(this.paySelectItem.phone).then(function (res) {
          _this.$notification["success"]({
            message: "success",
            description: "发送成功"
          });
        });
      }
    },
    payContinue: function payContinue() {
      console.log(this.approvalRecord);
      if (this.approvalRecord.payUrl) {
        window.open(this.approvalRecord.payUrl, "_blank");
        console.log(this.approvalRecord.payUrl);
      }
    },
    chakanjieguo: function chakanjieguo() {
      var _this2 = this;
      getResults(this.approvalRecord.id).then(function (res) {
        _this2.pageRefresh();
        _this2.$notification["success"]({
          message: "success",
          description: "查询成功"
        }).catch(function (err) {
          console.log(err);
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    getWeiXinList: function getWeiXinList() {
      var _this3 = this;
      _getWeiXinList({
        type: this.approvalRecord.type === 1 ? undefined : this.approvalRecord.type,
        "typeList[0]": this.approvalRecord.type === 1 ? 1 : undefined,
        "typeList[1]": this.approvalRecord.type === 1 ? 4 : undefined
      }).then(function (res) {
        _this3.weixinList = res.data;
      });
    },
    pageRefresh: function pageRefresh() {
      var _this4 = this;
      _pageRefresh(this.approvalRecord.id).then(function (res) {
        _this4.approvalRecord = res.data;
        var record = JSON.parse(JSON.stringify(_this4.approvalRecord));
        if (record.subjectStatus === 5 && record.payStatus !== 4) {
          _this4.payStatus = record.payStatus;
        } else if (record.subjectStatus === 4 && record.payStatus === 5) {
          _this4.payStatus = 1;
        } else if (record.subjectStatus === 4 && record.payStatus === 1) {
          _this4.payStatus = 5;
        } else if (record.subjectStatus === 5 && record.payStatus === 4) {
          if (record.isDispose === true) {
            _this4.payStatus = 6;
          } else {
            _this4.payStatus = 4;
          }
        }
        if (record.issueUserName) _this4.issueUserName = record.issueUserName;
        if (record.issueCreate) _this4.issueCreate = record.issueCreate ? moment(record.issueCreate).format("YYYY-MM-DD HH:mm") : "";
        userApproDetails(record.userApproId).then(function (res) {
          _this4.approval = res.data;
          _this4.approvalNodes = res.data.userApproNodeList;
          _this4.approvalNodes[0].approUserList = [{
            userName: _this4.approval.userName,
            avatar: _this4.approval.userAvatar
          }];
          // 用于审批流程展示
          _this4.approvalNodes[0].userFlowApproverList = [{
            approverUserAvatar: _this4.approval.userAvatar,
            approverUserName: _this4.approval.userName,
            gmtCreate: _this4.approval.gmtCreate,
            approverOpinion: "发起审批",
            status: 2
          }];
        }).catch(function (err) {
          console.log(err);
        });
        // this.$notification["success"]({
        //   message: "success",
        //   description: "刷新成功"
        // });
      });
    },
    subMchidChange: function subMchidChange(e) {
      this.paySelectItem = this.weixinList[e];
      console.log(this.paySelectItem);
      if (this.paySelectItem.type === 4 || this.paySelectItem.type === 3) {
        this.Verification = true;
      } else {
        this.Verification = false;
      }
    },
    handleWXOk: function handleWXOk() {
      var _this5 = this;
      if (this.paySelectItem) {
        if (this.paySelectItem.type === 1) {
          // 支付宝
          paySalary({
            batchId: this.approvalRecord.id,
            payType: "PC",
            pid: this.paySelectItem.zfbPid,
            appAuthToken: this.paySelectItem.zfbToken
          }).then(function (res) {
            _this5.weiXinPay = false;
            if (res.data) {
              window.open(res.data.urlPc, "_blank");
            }
            var that = _this5;
            _this5.pageRefresh();
            setTimeout(function () {
              _this5.$confirm({
                title: "查询发放结果",
                content: "系统发放会略有延迟，支付成功后可刷新订单查看结果",
                okText: "查询发放结果",
                onOk: function onOk() {
                  var _this6 = this;
                  getResults(that.approvalRecord.id).then(function (res) {
                    _this6.pageRefresh();
                  }).catch(function (err) {
                    console.log(err);
                  });
                },
                onCancel: function onCancel() {
                  console.log("Cancel");
                }
              });
            }, 1000);
          });
        } else if (this.paySelectItem.type === 2) {
          // 微信
          weixinPaySalary({
            batchId: this.approvalRecord.id,
            subMchid: this.paySelectItem.subMchid
          }).then(function (res) {
            _this5.weiXinPay = false;
            var that = _this5;
            _this5.pageRefresh();
            setTimeout(function () {
              _this5.$confirm({
                title: "查询发放结果",
                content: "系统发放会略有延迟，支付成功后可刷新订单查看结果",
                okText: "查询发放结果",
                onOk: function onOk() {
                  var _this7 = this;
                  getResults(that.approvalRecord.id).then(function (res) {
                    _this7.pageRefresh();
                  }).catch(function (err) {
                    console.log(err);
                  });
                },
                onCancel: function onCancel() {
                  console.log("Cancel");
                }
              });
            }, 1000);
          });
        } else if (this.paySelectItem.type === 4) {
          if (this.codeBySend) {
            huaBeiPaySalary({
              batchId: this.approvalRecord.id,
              accountId: this.paySelectItem.id,
              code: this.codeBySend
            }).then(function (res) {
              _this5.weiXinPay = false;
              _this5.pageRefresh();
              var that = _this5;
              setTimeout(function () {
                _this5.$confirm({
                  title: "查询发放结果",
                  content: "系统发放会略有延迟，支付成功后可刷新订单查看结果",
                  okText: "查询发放结果",
                  onOk: function onOk() {
                    var _this8 = this;
                    getResults(that.approvalRecord.id).then(function (res) {
                      _this8.pageRefresh();
                    }).catch(function (err) {
                      console.log(err);
                    });
                  },
                  onCancel: function onCancel() {
                    console.log("Cancel");
                  }
                });
              }, 1000);
            });
          } else {
            this.$message.warning("请输入验证码");
          }
        } else if (this.paySelectItem.type === 3) {
          if (this.codeBySend) {
            bankPaySalary({
              batchId: this.approvalRecord.id,
              accountId: this.paySelectItem.id,
              code: this.codeBySend
            }).then(function (res) {
              _this5.weiXinPay = false;
              _this5.pageRefresh();
              var that = _this5;
              setTimeout(function () {
                _this5.$confirm({
                  title: "查询发放结果",
                  content: "系统发放会略有延迟，预估5-10分钟后，可点击查看结果获取发薪详情",
                  okText: "查询发放结果",
                  onOk: function onOk() {
                    var _this9 = this;
                    getResults(that.approvalRecord.id).then(function (res) {
                      _this9.pageRefresh();
                    }).catch(function (err) {
                      console.log(err);
                    });
                  },
                  onCancel: function onCancel() {
                    console.log("Cancel");
                  }
                });
              }, 1000);
            });
          } else {
            this.$message.warning("请输入验证码");
          }
        }
      } else {
        this.$message.warning("请选择发放方式");
      }
    },
    goPaySalary: function goPaySalary() {
      // if (this.approvalRecord.type === 2) {
      this.weiXinPay = true;
      // }
      // else if (this.approvalRecord.type === 1) {
      //   var that = this;
      //   returnAuthorizationUrl().then(res => {
      //     if (res.data) {
      //       window.open(res.data, "_blank");
      //     }
      //     this.pageRefresh();
      //     setTimeout(() => {
      //       this.$confirm({
      //         title: "查询发放结果",
      //         content: "系统发放会略有延迟，支付成功后可刷新订单查看结果",
      //         okText: "查询发放结果",
      //         onOk() {
      //           getResults(that.approvalRecord.id)
      //             .then(res => {
      //               this.pageRefresh();
      //             })
      //             .catch(err => {
      //               console.log(err);
      //             });
      //         },
      //         onCancel() {
      //           console.log("Cancel");
      //         }
      //       });
      //     }, 1000);
      //   });
      //   localStorage.setItem("batchId", this.approvalRecord.id);
      // }
    },

    moment: moment,
    loadData: function loadData() {
      var _this10 = this;
      var record = JSON.parse(this.$route.query.record);
      if (this.$route.query.pageType) {
        this.applyForSubjectStatus = record.subjectStatus;
      } else {
        if (record.subjectStatus === 5 && record.payStatus !== 4) {
          this.payStatus = record.payStatus;
        } else if (record.subjectStatus === 4 && record.payStatus === 5) {
          this.payStatus = 1;
        } else if (record.subjectStatus === 4 && record.payStatus === 1) {
          this.payStatus = 5;
        } else if (record.subjectStatus === 5 && record.payStatus === 4) {
          if (record.isDispose === true) {
            this.payStatus = 6;
          } else {
            this.payStatus = 4;
          }
        }
      }
      if (record.issueUserName) this.issueUserName = record.issueUserName;
      if (record.issueCreate) this.issueCreate = record.issueCreate ? moment(record.issueCreate).format("YYYY-MM-DD HH:mm") : "";
      userApproDetails(record.userApproId).then(function (res) {
        _this10.approval = res.data;
        _this10.approvalNodes = res.data.userApproNodeList;
        _this10.approvalNodes[0].approUserList = [{
          userName: _this10.approval.userName,
          avatar: _this10.approval.userAvatar
        }];
        // 用于审批流程展示
        _this10.approvalNodes[0].userFlowApproverList = [{
          approverUserAvatar: _this10.approval.userAvatar,
          approverUserName: _this10.approval.userName,
          gmtCreate: _this10.approval.gmtCreate,
          approverOpinion: "发起审批",
          status: 2
        }];
      }).catch(function (err) {
        console.log(err);
      });
    },
    openModal: function openModal(e) {
      this.openState = e;
      this.visible = true;
      this.remark = "";
      if (e === "agree") {
        this.Title = "审批同意";
        this.placeholder = "请输入同意理由";
      } else {
        this.Title = "驳回意见";
        this.placeholder = "请输入驳回理由";
      }
    },
    handleOk: function handleOk() {
      if (this.openState === "agree") {
        this.handlerAgree();
      } else {
        this.handlerReject();
      }
    },
    handlerBack: function handlerBack() {
      this.$router.go(-1);
    },
    buildUserApproInfoList: function buildUserApproInfoList(values) {
      var keys = Object.keys(values);
      var userApproInfoList = this.userApproInfoList.filter(function (item) {
        return item.settingType === "edit";
      });
      keys.map(function (key) {
        var idx = userApproInfoList.findIndex(function (item) {
          return item.formId === key;
        });
        if (idx > -1) {
          userApproInfoList[idx]["value"] = values[key];
        }
      });
      return {
        userApproInfoList: userApproInfoList
      };
    },
    handlerAgree: function handlerAgree() {
      var _this11 = this;
      approvalHandlerAgreeReq(this.approval.userFlowApproverId, {
        remark: this.remark,
        userApproInfoList: []
      }).then(function (res) {
        // TODO 审批完成的提示，刷新数据
        _this11.$message.success(res.msg);
        _this11.visible = false;
        _this11.loadData();
      });
    },
    handlerReject: function handlerReject() {
      var _this12 = this;
      approvalHandlerRejectReq(this.approval.userFlowApproverId, {
        remark: this.remark,
        userApproInfoList: []
      }).then(function (res) {
        // TODO 审批完成的提示，刷新数据
        _this12.$message.success(res.msg);
        _this12.visible = false;
        _this12.loadData();
      });
    },
    getApprovalStatusColorBlue: getApprovalStatusColorBlue,
    getApprovalStatus: getApprovalStatus,
    typeFun: typeFun,
    getPayStatus: getPayStatus,
    getPayStatusApplyFor: getPayStatusApplyFor,
    getPayStatusApplyForSuccess: getPayStatusApplyForSuccess,
    buildForm: function buildForm(list) {
      var _this13 = this;
      var formMap = {};
      var valueMap = {};
      var fieldKeys = [];
      for (var i = 0; i < list.length; i++) {
        var item = list[i];
        var field = JSON.parse(item.props);
        if (item.settingType === "read") {
          field.customize.disabled = true;
        }
        valueMap[field.model] = this.formatFormValues(item.value, item.widgetType, item.formCode);
        formMap[field.model] = field;
        if (["read", "edit"].includes(item.settingType)) {
          fieldKeys.push(field.model);
        }
      }
      this.dataSource = valueMap;
      this.formFields = formMap;
      this.formConfig = {
        fieldKeys: fieldKeys,
        customize: {
          layout: "vertical",
          labelAlign: "left"
        }
      };
      this.$nextTick(function () {
        _this13.formVisible = true;
      });
    },
    formatFormValues: function formatFormValues(value, type, model) {
      if (type === "switch") {
        return value === "true";
      } else if (type === "number") {
        return value ? Number(value) : value;
      } else {
        if (this.dynamicValueMapKeys.includes(model)) {
          var dataMap = this.dynamicValueMap[model];
          var theItem = dataMap.filter(function (item) {
            return item.id === value;
          });
          if (theItem.length > 0) {
            return theItem[0].name;
          } else {
            return value;
          }
        }
        return value;
      }
    }
  }
};