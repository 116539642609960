var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "考勤数据导入"
    },
    on: {
      ok: _vm.onSumbit,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    staticStyle: {
      "margin-top": "6px",
      "margin-bottom": "12px"
    },
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "考勤记录"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["recordId", {
        rules: [{
          required: true,
          message: "请选择考勤记录"
        }]
      }],
      expression: "[\n          'recordId',\n          {\n            rules: [\n              {\n                required: true,\n                message: '请选择考勤记录'\n              }\n            ]\n          }\n        ]"
    }],
    attrs: {
      placeholder: "请选择"
    }
  }, _vm._l(_vm.arr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.fileName) + " ")]);
  }), 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };