import request from "@/utils/request";

// 复制
export function copyTemplateReq(templateId) {
  return request({
    url: "/hr/api/v1/treatmentDisposeTemplates/copyTemplate/".concat(templateId),
    method: "post"
  });
}

// 查询对应模板下是否存在未完结的待遇处理事件
export function delTemplateReq(treatmentDisposeTemplateId) {
  return request({
    url: "/hr/api/v1/treatmentDisposeTemplates/".concat(treatmentDisposeTemplateId),
    method: "delete"
  });
}

// 查询对应模板下是否存在未完结的待遇处理事件
export function existsNotFinishReq(templateId) {
  return request({
    url: "/hr/api/v1/treatmentDisposeEvents/existsNotFinish/".concat(templateId),
    method: "get"
  });
}
export function selectSiteDefaultTemplateReq() {
  return request({
    url: "/hr/api/v1/treatmentDisposeTemplates/selectSiteDefaultTemplate",
    method: "get"
  });
}
export function selectCustomTemplatePageReq(templateName, modifiedUserName) {
  var params = {
    pageNo: 1,
    pageSize: 10000
  };
  if (templateName) {
    params.templateName = templateName;
  }
  if (modifiedUserName) {
    params.modifiedUserName = modifiedUserName;
  }
  return request({
    url: "/hr/api/v1/treatmentDisposeTemplates/selectCustomTemplatePage",
    method: "get",
    params: params
  });
}

// 模板
export function detailTemplateReq(templateId) {
  return request({
    url: "/hr/api/v1/treatmentDisposeTemplates/selectTemplateInfoById/".concat(templateId),
    method: "get"
  });
}
export function addTemplateReq(data) {
  return request({
    url: "/hr/api/v1/treatmentDisposeTemplates",
    method: "post",
    data: data
  });
}
export function updateTemplateReq(data) {
  return request({
    url: "/hr/api/v1/treatmentDisposeTemplates",
    method: "put",
    data: data
  });
}

// 事件类型
export function loadEventTypeReq() {
  return request({
    url: "/admin/api/v1/dictItems/selectAllDictItem?dictCode=1216",
    method: "get"
  });
}
export function addEventTypeReq(data) {
  return request({
    url: "/admin/api/v1/dictItems",
    method: "post",
    data: data
  });
}