import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      title: "\u6279\u91CF\u4FEE\u6539".concat(_vm.title),
      destroyOnClose: true,
      visible: _vm.visible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    }
  }, [_vm.title === "状态" ? _c("div", [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "人员状态"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      "show-search": ""
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("已接到")]), _c("a-select-option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("已安排")]), _c("a-select-option", {
    attrs: {
      value: "3"
    }
  }, [_vm._v("已面试")])], 1)], 1)], 1)], 1) : _c("div", [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "渠道来源"
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择",
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption
    },
    model: {
      value: _vm.channelId,
      callback: function callback($$v) {
        _vm.channelId = $$v;
      },
      expression: "channelId"
    }
  }, _vm._l(_vm.Channels, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };