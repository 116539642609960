// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/wageMonthBg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".add-payslip[data-v-1417ef74] {\n  padding: 24px;\n}\n.add-payslip .addPayslip-middle .content-con[data-v-1417ef74] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n}\n.add-payslip .content-top[data-v-1417ef74] {\n  margin-top: 24px;\n  padding: 40px 162px;\n  background: #fafafa;\n  border-radius: 4px;\n}\n.add-payslip .content-con[data-v-1417ef74] {\n  text-align: center;\n  margin-top: 24px;\n  padding: 40px 162px;\n  background: #ffffff;\n  -webkit-box-shadow: 0px 4px 12px 0px var(--primary10);\n          box-shadow: 0px 4px 12px 0px var(--primary10);\n  border-radius: 4px;\n}\n.add-payslip .content-con[data-v-1417ef74].form {\n  text-align: center;\n}\n.add-payslip .content-con[data-v-1417ef74].form .form-item {\n  margin: 12px 0;\n  text-align: left;\n}\n.add-payslip .content-foot[data-v-1417ef74] {\n  margin-top: 24px;\n  padding: 24px;\n  background: #fafafa;\n  border-radius: 4px;\n}\n.add-payslip .salary[data-v-1417ef74] {\n  cursor: pointer;\n  margin: 24px 0;\n}\n.add-payslip .salary .item[data-v-1417ef74] {\n  padding: 24px;\n  text-align: center;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}", ""]);
// Exports
module.exports = exports;
