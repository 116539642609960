import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import { getEmpInfoDetail } from "@/api/staff/staff";
import electronicCOM from "./electronic/index";
import paperCOM from "./paper/index";
export default {
  components: {
    electronicCOM: electronicCOM,
    paperCOM: paperCOM
  },
  props: {
    employInfoId: {
      type: String,
      default: ""
    },
    findEmpInfoRelationRes: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      electronic: [],
      paper: [],
      addVisible: false,
      borderStyle1: "",
      borderStyle2: "",
      length: 0,
      state: 0,
      pageLastState: 0
    };
  },
  watch: {
    findEmpInfoRelationRes: function findEmpInfoRelationRes(v) {
      this.updateContractData();
    }
  },
  methods: {
    initData: function initData() {
      this.$emit("updateContractData");
    },
    updateContractData: function updateContractData() {
      this.paper = this.findEmpInfoRelationRes && this.findEmpInfoRelationRes.empPaperContractList ? this.findEmpInfoRelationRes.empPaperContractList : [];
      this.electronic = this.findEmpInfoRelationRes && this.findEmpInfoRelationRes.empContractList ? this.findEmpInfoRelationRes.empContractList : [];
      this.paper.forEach(function (e) {
        e.edit = false;
        e.collapses = false;
      });
      this.electronic.forEach(function (e) {
        e.edit = false;
        e.collapses = false;
        e.closable = true;
      });
      this.state = 0;
      this.length = this.paper.length + this.electronic.length;
    },
    addClick: function addClick() {
      this.addVisible = true;
      this.pageLastState = null;
      this.borderStyle1 = "";
      this.borderStyle2 = "";
    },
    paperClick: function paperClick(e) {
      this.pageLastState = e;
      var primary = localStorage.getItem("primary");
      this.borderStyle1 = "border: 2px solid ".concat(primary, ";background: ").concat(primary, "10;");
      this.borderStyle2 = "";
    },
    electronicClick: function electronicClick(e) {
      var primary = localStorage.getItem("primary");
      this.pageLastState = e;
      this.borderStyle1 = "";
      this.borderStyle2 = "border: 2px solid ".concat(primary, ";background: ").concat(primary, "10;");
    },
    handleOk: function handleOk() {
      if (this.pageLastState) {
        this.state = this.pageLastState;
        this.addVisible = false;
        this.paper.forEach(function (e) {
          e.edit = false;
        });
        this.electronic.forEach(function (e) {
          e.edit = false;
        });
      } else {
        this.$notification["error"]({
          message: "error",
          description: "请选择要创建的合同类型"
        });
      }
    },
    handleCancel: function handleCancel() {
      this.addVisible = false;
      this.state = 0;
    },
    upState: function upState() {
      this.state = 0;
      this.pageLastState = null;
      this.borderStyle1 = "";
      this.borderStyle2 = "";
    }
  }
};