import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import { bachSaveCustomer, selectSite as _selectSite, deleleSite as _deleleSite, selectSiteWithInsurance, getPlan, addPlanForSite, editPlanForSite, getPlanList as _getPlanList, selectCompanyBySiteId, deletePlan } from "./api";
import { publicDownLoad } from "../downLoad";
import { getToken } from "xqjr-module-auth";
export default {
  components: {
    EmployeeSelect: function EmployeeSelect() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./selectSite.vue"));
      });
    }
  },
  data: function data() {
    return {
      siteList: [],
      planList: [],
      visible: false,
      visibleForSite: false,
      selectPlanItem: undefined,
      selectSiteName: undefined,
      title: "新增投保方案",
      loading: false,
      planItem: {},
      companyDetail: {},
      selectedSiteList: [],
      realityMonthAmount: null,
      current: 1,
      total: 0,
      insuranceList: [],
      selectPlanList: [],
      fileList: [],
      selectedSite: {},
      headers: {}
    };
  },
  watch: {
    visible: function visible(val) {
      if (val === false) {
        this.getPlanList();
      }
    }
  },
  created: function created() {
    this.selectSite();
    this.getSiteList();
    this.headers = {
      token: getToken()
    };
  },
  methods: {
    download: function download(file) {
      if (file.url) {
        publicDownLoad(file.url);
      } else if (file.response.data && file.response.data[0]) {
        publicDownLoad(file.response.data[0]);
      } else {
        this.$message.warning("文件下载失败");
      }
    },
    // 新增编辑投保方案-保存
    handelOk: function handelOk() {
      var _this = this;
      var haAmount = true;
      var isZero = true;
      if (this.realityMonthAmount && this.realityMonthAmount.length) {
        this.realityMonthAmount.forEach(function (item) {
          if (!item.monthAmount && item.monthAmount !== 0) {
            haAmount = false;
          } else if (item.monthAmount === 0) {
            isZero = false;
          }
        });
      }
      if (!this.planItem.insCompanyId) {
        this.$message.warning("投保公司必选");
      } else if (!this.selectPlanItem) {
        this.$message.warning("投保方案必选");
      } else if (!haAmount) {
        this.$message.warning("保险月费必填");
      } else if (!isZero) {
        this.$message.warning("保险月费不能为0");
      } else {
        var selectPlanItemName = "";
        this.selectPlanList.forEach(function (item) {
          if (item.id === _this.selectPlanItem) {
            selectPlanItemName = item.schemeName;
          }
        });
        // var companyName = "";
        // this.insuranceList.forEach((item) => {
        //   if (item.id === this.planItem.insCompanyId) {
        //     companyName = item.name;
        //   }
        // });
        var arr = _objectSpread(_objectSpread({}, this.planItem), {}, {
          schemeId: this.selectPlanItem,
          customerId: this.selectedSite.id,
          companyName: this.companyDetail.companyName,
          schemeName: selectPlanItemName,
          insCompanyId: this.companyDetail.id,
          personTypeList: this.realityMonthAmount
        });
        if (this.title === "新增投保方案") {
          addPlanForSite(arr).then(function (res) {
            _this.visible = false;
            _this.getPlanList();
            _this.$message.success("保存成功");
          });
        } else if (this.title === "编辑投保方案") {
          editPlanForSite(arr).then(function (res) {
            _this.visible = false;
            _this.getPlanList();
            _this.$message.success("保存成功");
          });
        }
      }
    },
    // 方案确认函
    handleChange: function handleChange(info) {
      var fileList = _toConsumableArray(info.fileList);
      fileList = fileList.slice(-1);
      fileList = fileList.map(function (file) {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
      if (this.fileList && this.fileList.length) {
        if (this.fileList[0].status === "done" && this.fileList[0].response.data && this.fileList[0].response.data[0]) {
          this.planItem.schemeConfirmFile = this.fileList[0].response.data[0];
          this.planItem.remark = this.fileList[0].name;
        }
      } else {
        this.planItem.schemeConfirmFile = "";
        this.planItem.remark = "";
      }
    },
    // 左侧列表选中变化
    siteChange: function siteChange(item, index) {
      var _this2 = this;
      this.siteList.forEach(function (e, idx) {
        if (idx === index) {
          e.isSelected = true;
          _this2.selectedSite = e;
          _this2.getPlanList();
        } else {
          e.isSelected = false;
        }
      });
      this.siteList = JSON.parse(JSON.stringify(this.siteList));
    },
    // 查询右侧方案列表
    getPlanList: function getPlanList() {
      var _this3 = this;
      this.loading = true;
      _getPlanList({
        customerId: this.selectedSite.id
      }).then(function (res) {
        _this3.planList = res.data;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    // 新增编辑投保方案-选择保险公司变化
    insuranceChange: function insuranceChange(e) {
      var _this4 = this;
      this.selectPlanItem = undefined;
      this.realityMonthAmount = null;
      if (e) {
        selectCompanyBySiteId(e).then(function (res) {
          _this4.companyDetail = res.data;
          if (res.data.id) {
            getPlan(res.data.id, _this4.selectedSite.id, {
              schemeId: _this4.selectPlanItem ? _this4.selectPlanItem : undefined
            }).then(function (res) {
              _this4.selectPlanList = res.data;
            });
          }
        });
      } else {
        this.selectPlanList = [];
      }
    },
    // 新增编辑投保方案-选择参保方案变化
    selectPlan: function selectPlan(e) {
      var _this5 = this;
      if (e) {
        this.selectPlanList.forEach(function (item) {
          if (item.id === e) {
            _this5.realityMonthAmount = item.personTypeList;
          }
        });
      } else {
        this.realityMonthAmount = null;
      }
    },
    // 查询保险公司列表
    getSiteList: function getSiteList() {
      var _this6 = this;
      selectSiteWithInsurance().then(function (res) {
        _this6.insuranceList = res.data;
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 列表中移除已经添加的租户
    deleleSite: function deleleSite(item) {
      var _this7 = this;
      _deleleSite(item.id).then(function (res) {
        _this7.$message.success("删除成功");
        _this7.selectSite();
      });
    },
    // 左侧分页
    onPageChange: function onPageChange() {
      this.selectSite();
    },
    // 查询左侧租户列表
    selectSite: function selectSite() {
      var _this8 = this;
      _selectSite({
        pageSize: 10,
        pageNo: this.current,
        siteName: this.selectSiteName
      }).then(function (res) {
        if (res.data.entities.length === 0 && _this8.current > 1) {
          // 当本页非第一页（比第一页大）时，删除最后一条后台会返回当页页码，
          // 但是本页数据为空，table组件会展示为当前页的上一页数据为空，需要加加判断再拉一下上页数据
          _this8.current = _this8.current - 1;
          _this8.pageSize = 10;
          _this8.selectSite();
        } else {
          _this8.siteList = res.data.entities;
          _this8.total = res.data.entityCount;
          if (_this8.siteList.length) {
            _this8.siteList[0].isSelected = true;
            _this8.selectedSite = _this8.siteList[0];
            _this8.getPlanList();
          } else {
            _this8.planList = [];
            _this8.loading = false;
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 租户筛选框变化
    onEmployeeSelectChange: function onEmployeeSelectChange(list) {
      var _this9 = this;
      var arr = [];
      list.forEach(function (item) {
        arr.push({
          siteId: item.id,
          siteName: item.name
        });
      });
      bachSaveCustomer({
        bachSaveCustomerList: arr
      }).then(function (res) {
        _this9.$message.success("保存成功");
        _this9.visibleForSite = false;
        _this9.selectSite();
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 租户筛选框弹出
    showSiteAll: function showSiteAll() {
      this.visibleForSite = true;
      this.selectedSiteList = [];
    },
    addPlan: function addPlan() {
      this.visible = true;
      this.title = "新增投保方案";
      this.planItem = {};
      this.fileList = [];
      this.selectPlanItem = undefined;
      this.realityMonthAmount = null;
      this.selectPlanList = [];
    },
    // 左侧搜索
    onSearch: function onSearch() {
      this.current = 1;
      this.selectSite();
    },
    // 右侧方案编辑删除
    handleMenuClick: function handleMenuClick(e, item) {
      var _this10 = this;
      var h = this.$createElement;
      if (e.key === "1") {
        this.visible = true;
        this.title = "编辑投保方案";
        this.fileList = [];
        this.realityMonthAmount = null;
        selectCompanyBySiteId(item.insCompanyId).then(function (response) {
          _this10.companyDetail = response.data;
          getPlan(_this10.companyDetail.id, _this10.selectedSite.id, {
            schemeId: item.schemeId ? item.schemeId : undefined
          }).then(function (res) {
            _this10.selectPlanList = res.data;
            // this.selectPlanList.forEach((i) => {
            //   if (i.id === item.schemeId) {
            //     this.realityMonthAmount = i.personTypeList;
            //   }
            // });
          });
        });

        this.realityMonthAmount = item.personTypeList;
        this.planItem = {
          insCompanyId: item.insCompanyId,
          schemeConfirmFile: item.schemeConfirmFile,
          remark: item.remark,
          id: item.id
        };
        if (item.remark && item.schemeConfirmFile) {
          this.fileList = [{
            uid: "1",
            name: item.remark,
            status: "done",
            response: "Server Error 500",
            url: item.schemeConfirmFile
          }];
        }
        this.selectPlanItem = item.schemeId;
      } else if (e.key === "2") {
        var that = this;
        this.$confirm({
          title: "确定删除此投保方案吗?",
          content: function content(h) {
            return h("div", {
              "style": "color:red;"
            }, [item.schemeName]);
          },
          onOk: function onOk() {
            deletePlan(item.id).then(function (res) {
              that.$message.success("删除成功");
              that.getPlanList();
            });
          },
          onCancel: function onCancel() {
            console.log("Cancel");
          },
          class: "test"
        });
      }
    }
  }
};