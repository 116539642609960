import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "f-box"
  }, [_c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 24
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        initialValue: _vm.defaultVal.name
      }],
      expression: "['name', { initialValue: defaultVal.name }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      allowClear: "",
      placeholder: "请输入",
      maxLength: 80
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同类型"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        initialValue: _vm.defaultVal.type || undefined
      }],
      expression: "['type', { initialValue: defaultVal.type || undefined }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "show-search": "",
      allowClear: "",
      "filter-option": _vm.filterOption,
      disabled: _vm.getDisabled(_vm.defaultVal.contractId),
      placeholder: "请选择"
    },
    on: {
      change: _vm.changeType
    }
  }, _vm._l(_vm.typeList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1), _vm.show ? _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同期限"
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["termYear", {
        initialValue: _vm.gettermValue(_vm.defaultVal) || undefined
      }],
      expression: "[`termYear`, { initialValue: gettermValue(defaultVal) || undefined }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      allowClear: "",
      "show-search": "",
      disabled: _vm.getDisabled(_vm.defaultVal.contractId),
      "filter-option": _vm.filterOption,
      placeholder: "请选择"
    },
    on: {
      change: _vm.change1
    }
  }, _vm._l(12, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["timeLimitType", {
        initialValue: _vm.gettimeLimitType(_vm.defaultVal)
      }],
      expression: "[\n                        'timeLimitType',\n                        {\n                          initialValue: gettimeLimitType(defaultVal)\n                        }\n                      ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "show-search": "",
      disabled: _vm.getDisabled(_vm.defaultVal.contractId),
      "filter-option": _vm.filterOption,
      placeholder: "请选择"
    },
    on: {
      change: _vm.change3
    }
  }, _vm._l(_vm.timeList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同开始时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["startTime", {
        initialValue: _vm.getMoment(_vm.defaultVal.startTime)
      }],
      expression: "['startTime', { initialValue: getMoment(defaultVal.startTime) }]"
    }],
    attrs: {
      disabled: _vm.getDisabled(_vm.defaultVal.contractId)
    },
    on: {
      change: _vm.change2
    }
  })], 1)], 1), _vm.show ? _c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同结束时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["endTime", {
        initialValue: _vm.getMoment(_vm.defaultVal.endTime)
      }],
      expression: "['endTime', { initialValue: getMoment(defaultVal.endTime) }]"
    }],
    attrs: {
      disabled: _vm.getDisabled(_vm.defaultVal.contractId)
    },
    on: {
      change: _vm.change2
    }
  })], 1)], 1) : _vm._e()], 1), _c("a-form-item", {
    attrs: {
      label: "合同备注"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["remark", {
        initialValue: _vm.defaultVal.remark
      }],
      expression: "['remark', { initialValue: defaultVal.remark }]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      allowClear: "",
      maxLength: 255,
      "auto-size": {
        minRows: 8,
        maxRows: 15
      }
    }
  })], 1), _c("a-form-item", {
    staticClass: "mr24",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v(" 保存 ")]), _c("a-button", {
    staticClass: "ml12",
    on: {
      click: function click($event) {
        return _vm.$emit("upDate", _vm.index, false);
      }
    }
  }, [_vm._v(" 取消 ")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };