import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { mapGetters } from "vuex";
export default {
  name: "AddPayslipForth",
  methods: {
    // 查看详情
    goDetail: function goDetail() {
      this.$router.push({
        path: "/wages/payslip-detail",
        query: _objectSpread({}, this.$route.query)
      });
    }
  }
};