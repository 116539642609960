import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { findSocProgRelation } from "@/api/shebao/shebaoMethod.js";
import shebaoTable from "./shebaoTable2";
import onlyAmountTable from "./onlyAmountTable2";
import { isContainByString } from "@/utils/index";
import moment from "moment";
export default {
  components: {
    shebaoTable: shebaoTable,
    onlyAmountTable: onlyAmountTable
  },
  data: function data() {
    return {
      isContainByString: isContainByString,
      progId: "",
      progName: "",
      deductionsDate: 1,
      onlySocAmount: false,
      socCityName: "",
      socCity: "",
      onlyAmount: 0,
      startDt: 0,
      endDt: 0
    };
  },
  created: function created() {
    if (this.$route.query.id) {
      this.progId = this.$route.query.id;
      // this.empSocInfoId = this.$route.query.empSocInfoId;
      this.toInquire();
    }
  },
  methods: {
    disabledDate: function disabledDate(current) {
      if (this.endDt !== 0) {
        return current && moment(moment(this.endDt).format("YYYY-MM")).isBefore(moment(moment(current).format("YYYY-MM")));
      }
      return false;
    },
    disabledDate2: function disabledDate2(current) {
      if (this.startDt !== 0) {
        return current && moment(moment(current).format("YYYY-MM")).isBefore(moment(moment(this.startDt).format("YYYY-MM")));
      }
      return false;
    },
    handleProgTime: function handleProgTime(date, dateString) {
      if (date !== null) {
        this.startDt = Number(date);
      } else {
        this.startDt = 0;
      }
    },
    handleProgTime2: function handleProgTime2(date, dateString) {
      if (date !== null) {
        this.endDt = Number(date);
      } else {
        this.endDt = 0;
      }
    },
    // 处理城市
    handleCity: function handleCity(e) {
      if (e.value.length > 0) {
        var value = e.value;
        var label = e.label;
        this.socCity = value[value.length - 1].toString();
        if (label.length && label.length === 3) {
          this.socCityName = label[0] + "/" + label[1] + "/" + label[2];
        } else if (label.length && label.length === 2) {
          this.socCityName = label[0] + "/" + label[1];
        }
      } else {
        this.socCity = "";
        this.socCityName = "";
      }
    },
    // 查询方案详情
    toInquire: function toInquire() {
      var _this = this;
      findSocProgRelation({
        progId: this.progId,
        companyId: this.isContainByString("contractAdmin") ? "0" : this.$store.state.hr.companyId
      }).then(function (res) {
        _this.progName = res.data.progName;
        _this.socCityName = res.data.socCityName;
        _this.socCity = res.data.socCity.toString();
        _this.onlySocAmount = res.data.onlySocAmount;
        _this.onlyAmount = Number(res.data.onlySocAmount);
        _this.deductionsDate = res.data.deductionsDate;
      });
    }
  }
};