var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mb24"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "margin-top": "20px"
    }
  }, [_c("p", {
    staticStyle: {
      margin: "0"
    }
  }, [_vm._v(" 共 "), _c("span", {
    staticStyle: {
      color: "#f7b500ff"
    }
  }, [_vm._v(_vm._s(_vm.length))]), _vm._v(" 条记录 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      disabled: _vm.state != 0
    },
    on: {
      click: _vm.addClick
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("新增合同 ")], 1)], 1), _vm.electronic.length > 0 || _vm.state == 2 ? _c("a-row", {
    staticClass: "header-title mt16 mb12"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm._v("电子合同")]), _c("hr", {
    staticClass: "title-line"
  })]) : _vm._e(), _c("electronicCOM", {
    attrs: {
      list: _vm.electronic,
      state: _vm.state,
      employInfoId: _vm.employInfoId
    },
    on: {
      upState: _vm.upState,
      upDate: _vm.initData
    }
  }), _vm.paper.length > 0 || _vm.state == 1 ? _c("a-row", {
    staticClass: "header-title mt16 mb12"
  }, [_c("div", {
    staticClass: "title-info"
  }, [_vm._v("纸质合同")]), _c("hr", {
    staticClass: "title-line"
  })]) : _vm._e(), _c("paperCOM", {
    attrs: {
      list: _vm.paper,
      state: _vm.state,
      employInfoId: _vm.employInfoId
    },
    on: {
      upState: _vm.upState,
      upDate: _vm.initData
    }
  }), !_vm.state && _vm.paper.length <= 0 && _vm.electronic.length <= 0 ? _c("a-empty", {
    staticStyle: {
      "margin-top": "80px"
    }
  }) : _vm._e(), _c("a-modal", {
    attrs: {
      title: "新增合同"
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    },
    model: {
      value: _vm.addVisible,
      callback: function callback($$v) {
        _vm.addVisible = $$v;
      },
      expression: "addVisible"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("a-space", {
    attrs: {
      size: "large"
    }
  }, [_c("div", {
    staticStyle: {
      cursor: "pointer",
      width: "120px",
      height: "126px",
      "box-shadow": "0px 0px 8px 0px rgba(0, 0, 0, 0.12)",
      "border-radius": "4px"
    },
    style: _vm.borderStyle1,
    on: {
      click: function click($event) {
        return _vm.paperClick(1);
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "70px",
      height: "75px",
      "margin-left": "25px",
      "margin-top": "15px"
    },
    attrs: {
      src: require("@/assets/img/fileicon/hetong1.png")
    }
  }), _c("p", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "600",
      color: "#55565d",
      "line-height": "21px",
      "margin-left": "30px",
      "margin-bottom": "0"
    }
  }, [_vm._v(" 纸质合同 ")])]), _c("div", {
    staticStyle: {
      cursor: "pointer",
      width: "120px",
      height: "126px",
      "box-shadow": "0px 0px 8px 0px rgba(0, 0, 0, 0.12)",
      "border-radius": "4px"
    },
    style: _vm.borderStyle2,
    on: {
      click: function click($event) {
        return _vm.electronicClick(2);
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "70px",
      height: "75px",
      "margin-left": "25px",
      "margin-top": "15px"
    },
    attrs: {
      src: require("@/assets/img/fileicon/hetong3.png")
    }
  }), _c("p", {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "600",
      color: "#55565d",
      "line-height": "21px",
      "margin-left": "30px",
      "margin-bottom": "0"
    }
  }, [_vm._v(" 电子合同 ")])])])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };