import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "info"
  }, [_vm._m(0), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addProcessItem
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 添加流程 ")], 1)], 1), _c("a-divider", {
    attrs: {
      dashed: ""
    }
  }), _c("div", {
    staticStyle: {
      "padding-left": "12px"
    }
  }, [_vm.isRendered ? _c("div", _vm._l(_vm.processes, function (li, idx) {
    return _c("ProcessItem", {
      key: "".concat(li.name, "_").concat(idx),
      attrs: {
        dataSource: li,
        index: idx,
        isEnd: idx === _vm.processes.length - 1
      },
      on: {
        updateItem: _vm.onUpdateItem,
        delItem: _vm.delProcessItem,
        editItem: _vm.editProcessItem
      }
    });
  }), 1) : _vm._e(), _c("div", {
    staticClass: "addPes",
    on: {
      click: _vm.addProcessItem
    }
  }, [_c("a-icon", {
    staticStyle: {
      "margin-right": "12px"
    },
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v(" 添加流程 ")], 1)]), _c("a-modal", {
    attrs: {
      title: _vm.modalTitle
    },
    on: {
      ok: _vm.handleOk
    },
    model: {
      value: _vm.modalVisible,
      callback: function callback($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.nameNew,
      callback: function callback($$v) {
        _vm.nameNew = $$v;
      },
      expression: "nameNew"
    }
  })], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "title"
  }, [_vm._v("处理过程设置")]), _c("div", {
    staticClass: "desc"
  }, [_vm._v("设置待遇处理模板重点流程")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };