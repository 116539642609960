import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
var commonRoutes = [{
  path: "labor",
  // 渠道劳务费结算
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/settlement/labor"));
    });
  }
}, {
  path: "labor/detail",
  // 详情
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/settlement/labor/detail"));
    });
  }
}, {
  path: "billing",
  // 费用账单
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/settlement/billing"));
    });
  }
}, {
  path: "billing/billingDetail",
  // 详情
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/settlement/billing/billingDetail"));
    });
  }
}, {
  path: "record",
  // 客户回款记录
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/settlement/record"));
    });
  }
}, {
  path: "record/details",
  // 详情
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/settlement/record/details"));
    });
  }
}];
export var SettlementRouter = {
  path: "/settlementAdmin",
  // 客户结算
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/adminNew"));
    });
  },
  children: [].concat(commonRoutes, [{
    path: "kehumingxibiao",
    // 客户明细表
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/settlement/customers/index_new"));
      });
    }
  }, {
    path: "kehumingxibiao/customerDetail",
    // 客户明细表
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/settlement/customers/customerDetail_new"));
      });
    }
  }, {
    path: "fuwufeixiang",
    // 服务费项
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/settlement/customers/fuwufeixiang"));
      });
    }
  }, {
    path: "customers",
    // 客户明细表
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/settlement/customers"));
      });
    }
  }, {
    path: "customers/customerDetail",
    // 详情
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/settlement/customers/customerDetail"));
      });
    }
  }, {
    path: "socialSecurityPlan",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/settlement/socialSecurityPlan"));
      });
    }
  }, {
    path: "socialSecurityPlan/upLoad",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/settlement/socialSecurityPlan/upLoad"));
      });
    }
  }])
};
export var SettlementAdminRouter = {
  path: "/settlementAdmin",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/adminNew"));
    });
  },
  meta: {
    title: "客户结算"
  },
  children: [].concat(commonRoutes, [])
};