var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "赏金设置",
      "confirm-loading": _vm.confirmLoading,
      width: 700
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visibleSet", false);
      }
    },
    model: {
      value: _vm.visibleSet,
      callback: function callback($$v) {
        _vm.visibleSet = $$v;
      },
      expression: "visibleSet"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-switch", {
    attrs: {
      checked: _vm.switchs
    },
    on: {
      change: function change($event) {
        _vm.switchs = !_vm.switchs;
      }
    }
  }), _vm._v(" " + _vm._s(_vm.switchs ? "已开启" : "已关闭") + "赏金规则 ")], 1), _c("a-form-item", {
    attrs: {
      label: "赏金金额",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 20
      }
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["empMoneyReward", {
        rules: [{
          required: _vm.switchs,
          message: "赏金金额不能为空"
        }]
      }],
      expression: "['empMoneyReward', { rules: [{ required: switchs, message: '赏金金额不能为空' }] }]"
    }],
    attrs: {
      disabled: !_vm.switchs,
      min: 0,
      placeholder: "请输入"
    }
  })], 1), _vm._l(_vm.form.getFieldValue("keys"), function (k, i) {
    return _c("div", {
      key: i
    }, [_c("a-form-item", {
      staticStyle: {
        width: "38%",
        float: "left",
        "z-index": "10"
      },
      attrs: {
        label: "工作满",
        "label-col": {
          span: 8
        },
        "wrapper-col": {
          span: 15
        }
      }
    }, [_c("a-input-number", {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["workingTime".concat(i), {
          validateTrigger: ["change", "blur"],
          rules: [{
            required: _vm.switchs,
            whitespace: true,
            message: "该项不能为空"
          }]
        }],
        expression: "[\n            `workingTime${i}`,\n            {\n              validateTrigger: ['change', 'blur'],\n              rules: [\n                {\n                  required: switchs,\n                  whitespace: true,\n                  message: '该项不能为空'\n                }\n              ]\n            }\n          ]"
      }],
      attrs: {
        disabled: !_vm.switchs,
        min: 0,
        placeholder: "请输入"
      }
    })], 1), _c("a-form-item", {
      staticStyle: {
        width: "32%",
        float: "left",
        "z-index": "10"
      },
      attrs: {
        "label-col": {
          span: 0
        },
        "wrapper-col": {
          span: 23
        },
        required: false
      }
    }, [_c("a-select", {
      staticStyle: {
        width: "76%",
        "margin-right": "6%"
      },
      attrs: {
        disabled: !_vm.switchs
      },
      model: {
        value: _vm.timeType,
        callback: function callback($$v) {
          _vm.timeType = $$v;
        },
        expression: "timeType"
      }
    }, [_c("a-select-option", {
      attrs: {
        value: "1"
      }
    }, [_vm._v(" 天 ")]), _c("a-select-option", {
      attrs: {
        value: "2"
      }
    }, [_vm._v(" 月 ")])], 1), _vm._v(" 发放 ")], 1), _c("a-form-item", {
      staticStyle: {
        width: "30%",
        float: "left",
        "z-index": "10"
      },
      attrs: {
        "label-col": {
          span: 0
        },
        "wrapper-col": {
          span: 24
        },
        required: false
      }
    }, [_c("a-input-group", {
      attrs: {
        compact: ""
      }
    }, [_c("a-input-number", {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["grantCount".concat(i), {
          validateTrigger: ["change", "blur"],
          rules: [{
            required: _vm.switchs,
            whitespace: true,
            message: "该项不能为空"
          }]
        }],
        expression: "[\n              `grantCount${i}`,\n              {\n                validateTrigger: ['change', 'blur'],\n                rules: [\n                  {\n                    required: switchs,\n                    whitespace: true,\n                    message: '该项不能为空'\n                  }\n                ]\n              }\n            ]"
      }],
      staticStyle: {
        width: "56%"
      },
      attrs: {
        disabled: !_vm.switchs,
        min: 0,
        placeholder: "请输入"
      }
    }), _c("a-select", {
      staticClass: "grantType",
      attrs: {
        disabled: !_vm.switchs
      },
      model: {
        value: _vm.grantType,
        callback: function callback($$v) {
          _vm.grantType = $$v;
        },
        expression: "grantType"
      }
    }, [_c("a-select-option", {
      attrs: {
        value: "1"
      }
    }, [_vm._v(" %   ")]), _c("a-select-option", {
      attrs: {
        value: "2"
      }
    }, [_vm._v(" 元   ")])], 1), _vm.form.getFieldValue("keys").length > 1 ? _c("a-icon", {
      style: {
        color: _vm.switchs ? "#000000" : "#d9d9d9",
        margin: "10px 0 0 10px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.remove(k);
        }
      }
    }) : _vm._e()], 1)], 1)], 1);
  }), _c("a-form-item", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("a-button", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      disabled: !_vm.switchs
    },
    on: {
      click: _vm.add
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v(" 添加阶段 ")], 1)], 1)], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };