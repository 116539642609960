export function getApprovalStatus(status) {
  // 0:待审批 1:审批中 2:通过 3:驳回 4:撤回 5:其他人通过 6:其他人驳回
  if (status === 0) {
    return "待审批";
  } else if (status === 1) {
    return "审批中";
  } else if (status === 2) {
    return "已通过";
  } else if (status === 3) {
    return "已驳回";
  } else if (status === 4) {
    return "已撤回";
  } else if (status === 5) {
    return "其他人通过";
  } else if (status === 6) {
    return "其他人驳回";
  }
  return "其他";
}
// 状态图标
export function getApprovalStatusIcon(status) {
  if (status === 0) {
    return "message";
  } else if (status === 1) {
    return "message";
  } else if (status === 2) {
    return "check-circle";
  } else if (status === 3) {
    return "close-circle";
  } else if (status === 4) {
    return "close-circle";
  } else if (status === 5) {
    return "check-circle";
  } else if (status === 6) {
    return "close-circle";
  }
  return "";
}
// 颜色
export function getApprovalStatusColor(status) {
  if (status === 0) {
    return "#fff";
  } else if (status === 1) {
    return "#F7B500";
  } else if (status === 2) {
    return "#32CCAA";
  } else if (status === 3) {
    return "#FF7085";
  } else if (status === 4) {
    return "#FF7085";
  } else if (status === 5) {
    return "#32CCAA";
  } else if (status === 6) {
    return "#FF7085";
  }
  return "";
}
// 详情蓝色的文字和表格颜色
export function getApprovalStatusColorBlue(status) {
  if (status === 0) {
    return "#fff";
  } else if (status === 1) {
    return "#4686FF";
  } else if (status === 2) {
    return "#32CCAA";
  } else if (status === 3) {
    return "#FF7085";
  } else if (status === 4) {
    return "#FF7085";
  } else if (status === 5) {
    return "#32CCAA";
  } else if (status === 6) {
    return "#FF7085";
  }
  return "";
}
export function getApprovalType(type) {
  // 0:依次审批 1:会签(需所有审批人同意) 2:或签(一名审批人同意或拒绝即可)
  if (type === 0) {
    return "依次审批";
  } else if (type === 1) {
    return "会签";
  } else if (type === 2) {
    return "或签";
  }
  return "其他";
}

// 审核列表的状态
export function approvalTypeArr(type) {
  if (type === 2) {
    return [{
      state: "通过",
      value: 2
    }, {
      state: "驳回",
      value: 3
    }, {
      state: "其他人通过",
      value: 5
    }, {
      state: "其他人驳回",
      value: 6
    }];
  } else if (type === 3) {
    return [{
      state: "审批中",
      value: 1
    }, {
      state: "通过",
      value: 2
    }, {
      state: "驳回",
      value: 3
    }, {
      state: "撤回",
      value: 4
    }];
  } else if (type === 4) {
    return [{
      state: "审批中",
      value: 1
    }, {
      state: "通过",
      value: 2
    }, {
      state: "驳回",
      value: 3
    }];
  }
  return [];
}