var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: function back($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("投递简历设置")]), _c("div", {
    staticStyle: {
      padding: "24px"
    }
  }, [_c("fieldTemplate", {
    attrs: {
      typeVal: "tenant"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };