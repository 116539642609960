import request from "@/utils/request";
// 系统生成费用账单
export function generatedBills(data) {
  return request({
    url: "/hr/api/v1/costBills/generatedBills",
    method: "post",
    data: data
  });
}

// 分页查询工价规则
export function costBills(data) {
  return request({
    url: "/hr/api/v1/costBills/pagePost",
    // "/hr/api/v1/costBills",
    method: "post",
    data: data
  });
}

// 分页查询工价规则
export function costBillInfo(costBillId) {
  return request({
    url: "/hr/api/v1/costBills/selectCostBillInfoById/".concat(costBillId),
    method: "get"
  });
}

// 删除规则
export function costBillIdDelete(costBillId) {
  return request({
    url: "/hr/api/v1/costBills/".concat(costBillId),
    method: "delete"
  });
}
// 发起审批
export function launchApproval(data) {
  return request({
    url: "/hr/api/v1/costBills/launchApproveByCompanyId",
    method: "post",
    data: data
  });
}
export function listAllCompany() {
  return request({
    url: "/hr/api/v1/companies/listAllCompany",
    method: "get"
  });
}
export function listAllCompanyId(params) {
  return request({
    url: "/hr/api/v1/companies/selectByCostBillId",
    method: "get",
    params: params
  });
}
export function listAllCompanyGroup() {
  return request({
    url: "/hr/api/v1/childrenCompany/listAll",
    method: "get"
  });
}
export function costBillSites(data) {
  return request({
    url: "/hr/api/v1/costBillSites",
    method: "post",
    data: data
  });
}
export function getModelList(company_id, type) {
  return request({
    url: "/approval/api/v1/approFlows/allByCompany/".concat(company_id, "/hr?moduleType=hr&type=") + type,
    method: "get"
  });
}
export function getModelList_isUse(company_id, type) {
  var isUse = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return request({
    url: "/approval/api/v1/approFlows/allByCompany/".concat(company_id, "/hr?moduleType=hr&type=") + type + "&inUse=" + isUse,
    method: "get"
  });
}
export function selectCompanyByCostBillId(params) {
  return request({
    url: "/hr/api/v1/costBills/selectCompanyByCostBillId",
    method: "get",
    params: params
  });
}

// 确认账单
export function confirmCostBill(data) {
  return request({
    url: "/hr/api/v1/costBills/confirmCostBill",
    method: "put",
    data: data
  });
}