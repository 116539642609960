import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_c("div", {
    staticClass: "page-miH page-bgBack",
    staticStyle: {
      height: "100%"
    }
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(" 校验结果 ")]), _c("div", {
    staticClass: "body"
  }, [_c("div", {
    staticClass: "headBox"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("div", [_c("a-button", {
    attrs: {
      type: "link",
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.dateChange(1);
      }
    }
  }, [_c("a-icon", {
    staticStyle: {
      position: "relative",
      bottom: "2px"
    },
    attrs: {
      type: "caret-left"
    }
  })], 1), _c("a-button", {
    attrs: {
      type: "link"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "24px",
      "font-weight": "500",
      color: "#3c3d43"
    }
  }, [_vm._v(" " + _vm._s(_vm.moment(_vm.dynamicDate).format("YYYY-MM")) + " ")])]), _c("a-button", {
    attrs: {
      size: "small",
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.dateChange(2);
      }
    }
  }, [_c("a-icon", {
    staticStyle: {
      position: "relative",
      bottom: "2px"
    },
    attrs: {
      type: "caret-right"
    }
  })], 1)], 1)]), _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "itemHeadText"
  }, [_vm._v("参保人数")]), _c("div", {
    staticClass: "itemContentText"
  }, [_c("span", {
    staticClass: "itemContentTextNum"
  }, [_vm._v(_vm._s(_vm.infoList.length ? _vm.infoList[0].empCount : "--"))]), _c("span", {
    staticClass: "itemUnit"
  }, [_vm._v("人")])])]), _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "itemHeadText"
  }, [_vm._v("社保缴费")]), _c("div", {
    staticClass: "itemContentText"
  }, [_c("span", {
    staticClass: "itemContentTextNum"
  }, [_vm._v(_vm._s(_vm.infoList.length ? _vm.infoList[0].socAmount : "--"))]), _c("span", {
    staticClass: "itemUnit"
  }, [_vm._v("元")])])]), _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "itemHeadText"
  }, [_vm._v("公积金缴费")]), _c("div", {
    staticClass: "itemContentText"
  }, [_c("span", {
    staticClass: "itemContentTextNum"
  }, [_vm._v(_vm._s(_vm.infoList.length ? _vm.infoList[0].pfAmount : "--"))]), _c("span", {
    staticClass: "itemUnit"
  }, [_vm._v("元")])])])]), _c("a-alert", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      message: "仅校验该月份正常缴纳记录，补收补缴记录不做校验、减员记录不做校验",
      type: "warning",
      "show-icon": ""
    }
  }), _vm.infoList.length ? _c("div", {
    staticClass: "contentBox"
  }, [_c("div", _vm._l(_vm.dataList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-bottom": "12px"
      }
    }, [_c("div", {
      staticClass: "contentItem"
    }, [_c("div", {
      staticClass: "contentItemOne"
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        "align-items": "center"
      }
    }, [_c("xq-icon", {
      staticClass: "jilu",
      attrs: {
        type: "icon-jilu"
      }
    }), _c("div", {
      staticStyle: {
        "font-size": "18px",
        color: "#222426",
        "font-weight": "500",
        "margin-left": "6px"
      }
    }, [_vm._v(" " + _vm._s(_vm.moment(item.gmtCreate).format("YYYY-MM-DD HH:mm:ss")) + " ")]), _vm.state !== "2" ? _c("div", {
      staticStyle: {
        "font-size": "14px",
        "font-weight": "400",
        color: "#55565d",
        "margin-left": "45px"
      }
    }, [_vm._v("校验文件：")]) : _vm._e(), _vm.state !== "2" ? _c("div", [_vm._v(_vm._s(item.fileName ? item.fileName : "--"))]) : _vm._e(), _vm.state !== "2" ? _c("a-button", {
      attrs: {
        type: "link",
        disabled: !item.fileName
      },
      on: {
        click: function click($event) {
          return _vm.downLoad(item.fileUrl);
        }
      }
    }, [_vm._v("下载")]) : _vm._e()], 1), _c("div", {
      staticStyle: {
        display: "flex"
      }
    }, [_c("div", {
      staticClass: "number"
    }, [_c("span", {
      staticClass: "numberTitle"
    }, [_vm._v("参保人数差：")]), _c("span", {
      staticClass: "numberContent"
    }, [_vm._v(_vm._s(item.empDifferenceNum))]), _vm._v("人 ")]), _c("div", {
      staticClass: "number",
      staticStyle: {
        "margin-left": "160px"
      }
    }, [_c("span", {
      staticClass: "numberTitle"
    }, [_vm._v("社保缴费差：")]), _c("span", {
      staticClass: "numberContent"
    }, [_vm._v(_vm._s(item.socDifferenceAmount))]), _vm._v("元 ")]), _c("div", {
      staticClass: "number",
      staticStyle: {
        "margin-left": "160px"
      }
    }, [_c("span", {
      staticClass: "numberTitle"
    }, [_vm._v("公积金缴费差：")]), _c("span", {
      staticClass: "numberContent"
    }, [_vm._v(_vm._s(item.pfDifferenceAmount))]), _vm._v("元 ")])])]), _c("div", {
      staticClass: "contentItemTwo"
    }, [_vm._v(" " + _vm._s(!item.isContentShow ? "展开" : "收起") + " "), _c("a-button", {
      staticStyle: {
        "font-size": "22px",
        "margin-left": "4px"
      },
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          return _vm.switchChange(item, index);
        }
      }
    }, [!item.isContentShow ? _c("a-icon", {
      attrs: {
        type: "right-circle"
      }
    }) : _c("a-icon", {
      attrs: {
        type: "down-circle"
      }
    })], 1)], 1)]), item.isContentShow ? _c("div", {
      staticClass: "itemTable"
    }, [_c("div", {
      staticClass: "itemTableTitle"
    }, [_c("div", {
      staticClass: "shuxian"
    }), _vm._v(" 异常数据表 ")]), _c("div", {
      staticClass: "buttonBox"
    }, [_c("div", [_c("a-select", {
      staticStyle: {
        width: "180px"
      },
      attrs: {
        placeholder: "全部状态"
      },
      on: {
        change: function change($event) {
          return _vm.handleSelectChange(item, index);
        }
      },
      model: {
        value: _vm.selectItem,
        callback: function callback($$v) {
          _vm.selectItem = $$v;
        },
        expression: "selectItem"
      }
    }, _vm._l(_vm.tableList, function (item, index) {
      return _c("a-select-option", {
        key: index,
        attrs: {
          value: item.key
        }
      }, [_vm._v(" " + _vm._s(item.name) + " ")]);
    }), 1)], 1), _c("div", [_c("a-button", {
      attrs: {
        type: "primary"
      },
      on: {
        click: function click($event) {
          return _vm.exportFile(item);
        }
      }
    }, [_vm._v("下载异常数据")])], 1)]), _c("div", {
      staticStyle: {
        "margin-top": "24px"
      }
    }, [_c("a-table", {
      attrs: {
        columns: _vm.columns,
        "data-source": item.socCheckItemList,
        pagination: false,
        scroll: {
          x: 1500
        }
      },
      scopedSlots: _vm._u([{
        key: "sysPersonalSocAmount",
        fn: function fn(text, record) {
          return [_c("span", [_vm._v(_vm._s(text + "/"))]), _c("span", {
            staticClass: "recordStyle"
          }, [_vm._v(_vm._s(record.excelPersonalSocAmount))])];
        }
      }, {
        key: "sysCompanySocAmount",
        fn: function fn(text, record) {
          return [_c("span", [_vm._v(_vm._s(text + "/"))]), _c("span", {
            staticClass: "recordStyle"
          }, [_vm._v(_vm._s(record.excelCompanySocAmount))])];
        }
      }, {
        key: "sysPersonalPfAmount",
        fn: function fn(text, record) {
          return [_c("span", [_vm._v(_vm._s(text + "/"))]), _c("span", {
            staticClass: "recordStyle"
          }, [_vm._v(_vm._s(record.excelPersonalPfAmount))])];
        }
      }, {
        key: "sysCompanyPfAmount",
        fn: function fn(text, record) {
          return [_c("span", [_vm._v(_vm._s(text + "/"))]), _c("span", {
            staticClass: "recordStyle"
          }, [_vm._v(_vm._s(record.excelCompanyPfAmount))])];
        }
      }, {
        key: "sysServiceCost",
        fn: function fn(text, record) {
          return [_c("span", [_vm._v(_vm._s(text + "/"))]), _c("span", {
            staticClass: "recordStyle"
          }, [_vm._v(_vm._s(record.excelServiceCost))])];
        }
      }, {
        key: "sysSocialSecurityFine",
        fn: function fn(text, record) {
          return [_c("span", [_vm._v(_vm._s(text + "/"))]), _c("span", {
            staticClass: "recordStyle"
          }, [_vm._v(_vm._s(record.excelSocialSecurityFine))])];
        }
      }, {
        key: "sysAccumulationFundFine",
        fn: function fn(text, record) {
          return [_c("span", [_vm._v(_vm._s(text + "/"))]), _c("span", {
            staticClass: "recordStyle"
          }, [_vm._v(_vm._s(record.excelAccumulationFundFine))])];
        }
      }, {
        key: "payCostType",
        fn: function fn(text) {
          return [_vm._v(_vm._s(["正常应缴", "调整计划", "补收/补缴", "个人退收"][text - 1]))];
        }
      }, {
        key: "wrongsStatus",
        fn: function fn(text) {
          return [_vm._v(_vm._s(["系统内无此人", "文件内无此人", "未参保", "社保金异常", "公积金异常", "社保局无此人", "医保局无此人", "公积金无此人", "服务费异常", "滞纳金异常"][text - 1]))];
        }
      }, {
        key: "action",
        fn: function fn(text, record) {
          return [record.wrongsStatus === 1 ? _c("a-button", {
            attrs: {
              type: "link"
            },
            on: {
              click: function click($event) {
                return _vm.goActionDetail(1);
              }
            }
          }, [_vm._v("添加员工")]) : _vm._e(), record.wrongsStatus === 2 ? _c("a-button", {
            attrs: {
              type: "link"
            },
            on: {
              click: function click($event) {
                return _vm.goActionDetail(2);
              }
            }
          }, [_vm._v("减员")]) : _vm._e(), record.wrongsStatus === 3 ? _c("a-button", {
            attrs: {
              type: "link"
            },
            on: {
              click: function click($event) {
                return _vm.goActionDetail(2);
              }
            }
          }, [_vm._v("增员")]) : _vm._e(), record.wrongsStatus === 4 ? _c("a-button", {
            attrs: {
              type: "link"
            }
          }, [_vm._v("同步为文件数据")]) : _vm._e(), record.wrongsStatus === 5 ? _c("a-button", {
            attrs: {
              type: "link"
            }
          }, [_vm._v("同步为文件数据")]) : _vm._e()];
        }
      }], null, true)
    })], 1)]) : _vm._e()]);
  }), 0)]) : _c("div", {
    staticStyle: {
      "margin-top": "80px"
    }
  }, [_c("a-empty")], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end",
      "margin-top": "24px"
    }
  }, [_c("a-pagination", {
    attrs: {
      current: _vm.current,
      total: _vm.total
    },
    on: {
      change: _vm.onChange
    }
  })], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };