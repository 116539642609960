import request from "@/utils/request";

// 分页查询项目
export function retrieveReq(params) {
  return request({
    url: "/hr/api/v1/products/selectByProductTypeId",
    method: "GET",
    params: params
  });
}

// 新增项目
export function createReq(data) {
  return request({
    url: "/hr/api/v1/products",
    method: "POST",
    data: data
  });
}

// 修改项目
export function updateReq(data) {
  return request("/hr/api/v1/products", {
    method: "PUT",
    data: data
  });
}

// 删除项目
export function deleteReq(prodId) {
  return request({
    url: "/hr/api/v1/products/".concat(prodId),
    method: "DELETE"
  });
}

// 删除项目
export function deleteBatchReq(data) {
  return request({
    url: "/hr/api/v1/products",
    method: "DELETE",
    data: data
  });
}

// 根据项目id查询详情
export function detailReq(prodId) {
  return request({
    url: "/hr/api/v1/products/".concat(prodId),
    method: "GET"
  });
}