import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "padding-bottom": "60px"
    }
  }, [_c("div", {
    staticClass: "flex-center-between mb14 mt24"
  }, [_c("a-alert", {
    attrs: {
      message: "请仔细检查确保以下签署方信息",
      banner: "",
      closable: ""
    }
  })], 1), _vm.clicked ? _c("div", {
    staticClass: "example"
  }, [_c("a-spin", {
    staticStyle: {
      color: "#6a66f6"
    },
    attrs: {
      size: "large"
    }
  })], 1) : _vm._e(), _c("a-table", {
    attrs: {
      columns: _vm.contractColumns,
      "data-source": _vm.contractlist,
      "row-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "contractName",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(" " + _vm._s(record.name + "的" + _vm.category.name) + " ")]);
      }
    }, {
      key: "contractNo",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(" " + _vm._s(record.contractNo ? record.contractNo : "--") + " "), _c("a-icon", {
          staticStyle: {
            color: "#e8e8e8",
            "font-size": "18px",
            "margin-left": "10px"
          },
          attrs: {
            type: "form"
          },
          on: {
            click: function click($event) {
              return _vm.updateContactNumber(record);
            }
          }
        })], 1);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("span", {}, [_c("a", {
          on: {
            click: function click($event) {
              return _vm.preview(record);
            }
          }
        }, [_vm._v("预览")])]);
      }
    }])
  }), _c("div", {
    staticClass: "btnline bgc-white",
    style: {
      position: "fixed",
      width: "auto",
      bottom: _vm.fixedBottom,
      right: "24px",
      zIndex: "10",
      left: _vm.fixedLeft
    }
  }, [_c("a-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.backStep
    }
  }, [_vm._v("上一步")]), _c("a-button", {
    attrs: {
      disabled: _vm.clicked,
      type: "primary"
    },
    on: {
      click: _vm.confirmSign
    }
  }, [_vm._v("发起签署")])], 1), _c("a-modal", {
    attrs: {
      title: "编辑合同编号",
      visible: _vm.updateVisible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.confirmUpdateContactNumber,
      cancel: _vm.handleCancel
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      form: _vm.updateForm,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      },
      colon: true
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "合同编号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["contractNo", {
        rules: [{
          required: true,
          message: "请输入"
        }]
      }],
      expression: "['contractNo', { rules: [{ required: true, message: '请输入' }] }]"
    }]
  })], 1)], 1)], 1)]), _c("a-modal", {
    staticClass: "ht ql-editor-wrapper",
    attrs: {
      title: "合同预览",
      "confirm-loading": _vm.confirmLoading,
      visible: _vm.detailVisible,
      width: "900px",
      "destroy-on-close": ""
    },
    on: {
      ok: function ok($event) {
        _vm.detailVisible = false;
      },
      cancel: function cancel($event) {
        _vm.detailVisible = false;
      }
    }
  }, [_c("h3", {
    staticClass: "mb16"
  }, [_vm._v(_vm._s(_vm.currentContract.name + "的" + _vm.category.name))]), _c("div", {
    staticClass: "ql-editor-content",
    staticStyle: {
      height: "auto",
      "padding-right": "0",
      "padding-left": "0"
    }
  }, [_c("div", {
    staticClass: "editor-main",
    staticStyle: {
      "padding-top": "0"
    }
  }, [_c("div", {
    style: {
      width: "".concat(_vm.a4_width, "px"),
      position: "relative",
      margin: "0 auto"
    }
  }, [_vm.src ? _c("pdf", {
    ref: "pdf",
    attrs: {
      src: _vm.src,
      page: _vm.currentPage
    },
    on: {
      "num-pages": function numPages($event) {
        _vm.pageCount = $event;
      },
      "page-loaded": function pageLoaded($event) {
        _vm.currentPage = $event;
      }
    }
  }) : _vm._e(), _vm._l(_vm.dropList, function (item) {
    return [item.page === _vm.currentPage ? _c("vue-draggable-resizable", {
      key: item.id,
      ref: "".concat(item.type).concat(item.id),
      refInFor: true,
      attrs: {
        parent: true,
        resizable: false,
        w: _vm.seal_size,
        h: _vm.seal_size,
        x: item.x,
        y: item.y,
        draggable: false,
        "class-name": "editor-main-drag"
      }
    }, [item.type === "seal" ? _c("div", [_c("img", {
      attrs: {
        src: require("../../../../assets/img/seal-circle.png"),
        alt: ""
      }
    })]) : _vm._e(), item.type === "sign" ? _c("div", [_c("div", {
      staticClass: "editor-main-drag-sign"
    }, [_vm._v("电子签名")])]) : _vm._e(), item.type === "holder" ? _c("div", [_c("div", {
      staticClass: "editor-main-drag-sign lp"
    }, [_vm._v("法人章")])]) : _vm._e()]) : _vm._e()];
  })], 2)])]), _c("div", {
    staticClass: "editor-config",
    staticStyle: {
      padding: "0",
      width: "70px",
      bottom: "77px",
      right: "24px",
      "border-left": "none"
    }
  }, [_c("div", {
    staticClass: "editor-config-page",
    staticStyle: {
      display: "flex",
      "flex-direction": "row"
    }
  }, [_c("div", {
    staticClass: "editor-config-page-btn mr8",
    staticStyle: {
      "margin-bottom": "0"
    },
    on: {
      click: _vm.pageUp
    }
  }, [_c("a-icon", {
    attrs: {
      type: "up"
    }
  })], 1), _c("div", {
    staticClass: "editor-config-page-btn",
    staticStyle: {
      "margin-bottom": "0"
    },
    on: {
      click: _vm.pageDown
    }
  }, [_c("a-icon", {
    attrs: {
      type: "down"
    }
  })], 1)])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };