import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "重新发起",
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "发薪批次名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["orderTitle", {
        rules: [{
          required: true,
          message: "发薪批次名称不能为空"
        }]
      }],
      expression: "['orderTitle', { rules: [{ required: true, message: '发薪批次名称不能为空' }] }]"
    }],
    attrs: {
      placeholder: "请输入",
      maxLength: 50
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "到账方式"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        rules: [{
          required: true,
          message: "到账方式不能为空"
        }]
      }],
      expression: "[\n          'type',\n          {\n            rules: [\n              {\n                required: true,\n                message: '到账方式不能为空'\n              }\n            ]\n          }\n        ]"
    }],
    attrs: {
      disabled: true,
      placeholder: "请选择"
    }
  }, _vm._l(_vm.typeArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "选择用工单位"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyId", {
        rules: [{
          required: true,
          message: "用工单位不能为空"
        }]
      }],
      expression: "[\n          'companyId',\n          {\n            rules: [\n              {\n                required: true,\n                message: '用工单位不能为空'\n              }\n            ]\n          }\n        ]"
    }],
    attrs: {
      disabled: true,
      "show-search": "",
      "filter-option": _vm.filterOption,
      placeholder: "请选择"
    }
  }, _vm._l(_vm.companyIdArr, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "备注"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["remark"],
      expression: "['remark']"
    }],
    attrs: {
      placeholder: "请输入",
      "auto-size": {
        minRows: 8,
        maxRows: 15
      }
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };