export default {
  fields: {
    name: {
      model: "name"
    },
    companyTelephone: {
      model: "companyTelephone"
    },
    legalPerson: {
      model: "legalPerson"
    },
    companyIndustryFirst: {
      model: "companyIndustryFirst"
    },
    sourceType: {
      model: "sourceType"
    },
    positionCount: {
      model: "positionCount"
    },
    gmtCreate: {
      model: "gmtCreate"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "name",
        title: "企业名称"
      }, {
        model: "legalPerson",
        title: "企业联系人"
      }, {
        model: "companyTelephone",
        title: "联系电话"
      },
      // {
      //   model: "companyIndustryFirst",
      //   title: "公司行业"
      // },
      // {
      //   model: "personSize",
      //   title: "公司规模"
      // },
      {
        model: "sourceType",
        title: "数据来源"
      }, {
        model: "positionCount",
        title: "招聘职位数"
      }, {
        model: "gmtCreate",
        title: "创建时间"
      }],
      customize: {
        rowKey: "id"
      },
      extend: {
        batch: false,
        action: true
      }
    }
  }
};