import { contractTypeList } from "../funcs";
var rightTime = ["年", "月"];
var rightTimeEn = ["years", "months"];
export default {
  name: "",
  components: {},
  mixins: [],
  props: ["value"],
  data: function data() {
    return {
      batchEditVisible: false,
      rightTime: rightTime,
      contractTypeList: contractTypeList,
      contractStartTime: undefined,
      contractEndTime: undefined,
      contractType: undefined,
      contractExpire: undefined,
      timelimitType: undefined
    };
  },
  computed: {},
  watch: {
    value: {
      handler: function handler(val) {
        if (val !== this.batchEditVisible) {
          this.batchEditVisible = val;
        }
        if (val) {
          this.contractStartTime = undefined;
          this.contractEndTime = undefined;
          this.contractType = undefined;
          this.contractExpire = undefined;
          this.timelimitType = undefined;
        }
      },
      immediate: true
    },
    batchEditVisible: function batchEditVisible(newVal) {
      this.$emit("input", newVal);
      this.$emit("change", newVal);
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    disabledStartDate: function disabledStartDate(val) {
      var endValue = this.contractEndTime;
      if (!val || !endValue) {
        return false;
      }
      return val.valueOf() > endValue.valueOf();
    },
    disabledEndDate: function disabledEndDate(val) {
      var startValue = this.contractStartTime;
      if (!val || !startValue) {
        return false;
      }
      return val.valueOf() < startValue.valueOf();
    },
    batchEditConfirm: function batchEditConfirm() {
      var params = {};
      if (this.contractStartTime) {
        params["contractStartTime"] = this.contractStartTime;
      }
      if (this.contractEndTime) {
        params["contractEndTime"] = this.contractEndTime;
      }
      if (this.contractType) {
        params["contractType"] = this.contractType;
      }
      if (this.contractExpire) {
        params["contractExpire"] = this.contractExpire;
      }
      if (this.timelimitType) {
        params["timelimitType"] = this.timelimitType;
      }
      this.$emit("confirm", params);
      this.batchEditVisible = false;
    },
    onChangeStart: function onChangeStart(val) {
      this.contractStartTime = val;
      if (this.contractType !== "laborContractUnlimit" && this.timelimitType && this.contractExpire && val) {
        var temp = this.contractStartTime.clone();
        this.contractEndTime = temp.add(this.contractExpire, rightTimeEn[this.timelimitType - 1]).add(-1, "days");
      }
    },
    onChangeNum: function onChangeNum(val) {
      this.contractExpire = val;
      if (this.contractStartTime && this.timelimitType && val) {
        var temp = this.contractStartTime.clone();
        this.contractEndTime = temp.add(this.contractExpire, rightTimeEn[this.timelimitType - 1]).add(-1, "days");
      }
    },
    onChangeUnit: function onChangeUnit(val) {
      this.timelimitType = val;
      if (this.contractStartTime && this.contractExpire && val) {
        var temp = this.contractStartTime.clone();
        this.contractEndTime = temp.add(this.contractExpire, rightTimeEn[this.timelimitType - 1]).add(-1, "days");
      }
    },
    onChangeEnd: function onChangeEnd(val) {
      this.contractEndTime = val;
    },
    onChangeType: function onChangeType(val) {
      this.contractType = val;
      if (val === "laborContractUnlimit") {
        this.contractEndTime = undefined;
        this.contractExpire = undefined;
        this.timelimitType = undefined;
      }
    }
  }
};