import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import { deleteSalaryTemplates, batchDeleteSalary } from "@/api/wages/template";
import { getNotArchiveById } from "@/api/wages/monthly";
import { isContainByString } from "@/utils/index";
import { saveIssuedSeal as _saveIssuedSeal } from "./api";
var columnsInit = [{
  title: "模板名称",
  dataIndex: "name",
  width: 200,
  ellipsis: true
}, {
  title: "工资项",
  dataIndex: "gongziItems",
  width: 300,
  ellipsis: true,
  scopedSlots: {
    customRender: "gongziItems"
  }
}, {
  title: "统计项",
  dataIndex: "tongjiItems",
  width: 300,
  ellipsis: true,
  scopedSlots: {
    customRender: "tongjiItems"
  }
}];
export default {
  data: function data() {
    return {
      loading: true,
      columns: [],
      data: [],
      companyId: this.$store.state.hr.companyId,
      // modal显示隐藏
      issueModalVisible: false,
      // 下发选择框合同数据
      contractData: [],
      // 已选的合同模板数据
      contractList: [],
      // 已选的公司数据
      companyList: []
    };
  },
  computed: {
    isAdmin: function isAdmin() {
      return isContainByString("contractAdmin");
    },
    companyTreeWithUuid: function companyTreeWithUuid() {
      return this.$store.state.site.companyTreeWithUuid;
    },
    companyUuidDict: function companyUuidDict() {
      return this.$store.state.site.companyUuidDict;
    }
  },
  watch: {
    companyTreeWithUuid: {
      handler: function handler(newVal) {
        if (newVal.length > 0) {
          this.companyList = JSON.parse(JSON.stringify(this.companyList));
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.getTemplatesList();
    var columnsTemp = [].concat(columnsInit);
    if (!this.isAdmin) {
      columnsTemp.push({
        title: "适用薪资组",
        dataIndex: "groupItems",
        width: 200,
        ellipsis: true,
        scopedSlots: {
          customRender: "groupItems"
        }
      });
    }
    this.columns = [].concat(_toConsumableArray(columnsTemp), [{
      title: "操作",
      dataIndex: "action",
      width: 100,
      scopedSlots: {
        customRender: "action"
      }
    }]);
  },
  methods: {
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    deleteSalaryTemplatesList: function deleteSalaryTemplatesList(record) {
      var _this2 = this;
      getNotArchiveById({
        salaryTemplateId: record.id
      }).then(function (res) {
        if (res.data) {
          var arr = [];
          res.data.forEach(function (item, index) {
            arr.push(item.monthly);
          });
          arr = arr.join(",");
          var idList = [];
          res.data.forEach(function (item, index) {
            idList.push(item.id);
          });
          var _this = _this2;
          _this2.$confirm({
            okText: "确定",
            cancelText: "取消",
            title: "\u8BE5\u6A21\u677F\u5DF2\u5728".concat(arr, "\u6708\u521B\u5EFA\u6708\u5DE5\u8D44\u8868\uFF0C\u5220\u9664\u6A21\u677F\u4F1A\u540C\u65F6\u5220\u9664\u672A\u5F52\u6863\u7684\u6708\u5DE5\u8D44\u8868\uFF0C\u4ECD\u65E7\u7EE7\u7EED\u5417\uFF1F"),
            onOk: function onOk() {
              deleteSalaryTemplates(record.id).then(function (_ref) {
                var success = _ref.success;
                if (success) {
                  _this.$notification["success"]({
                    message: "删除成功"
                  });
                  _this.getTemplatesList();
                }
              });
              batchDeleteSalary(idList).then(function (_ref2) {
                var success = _ref2.success;
              });
            }
          });
        } else {
          deleteSalaryTemplates(record.id).then(function (_ref3) {
            var success = _ref3.success;
            if (success) {
              _this2.$notification["success"]({
                message: "删除成功"
              });
              _this2.getTemplatesList();
            }
          });
        }
      });
    },
    getTemplatesList: function getTemplatesList() {
      var _this3 = this;
      this.loading = true;
      this.$request({
        url: "/salary/api/v1/salaryTemplates/selectByCompanyIdNew",
        params: {
          companyId: this.isAdmin ? "0" : this.companyId
        }
      }).then(function (res) {
        _this3.loading = false;
        _this3.data = res.data.map(function (item) {
          var gzItems = item.wageItemVOList ? item.wageItemVOList.map(function (li) {
            return li.name;
          }) : [];
          var tjItems = item.templateCovarianceItemVOList ? item.templateCovarianceItemVOList.map(function (li) {
            return li.name;
          }) : [];
          var groupItems = item.salaryGroupVOList ? item.salaryGroupVOList.map(function (li) {
            return li.name;
          }) : [];
          return _objectSpread(_objectSpread({}, item), {}, {
            gongziItems: gzItems.join("，"),
            tongjiItems: tjItems.join("，"),
            groupItems: groupItems.join("，")
          });
        });
        _this3.contractData = res.data;
      });
    },
    goAdd: function goAdd() {
      this.$router.push({
        path: "/".concat(this.isAdmin ? "contractAdmin" : "wages", "/gongzibiaomuban/edit")
      });
    },
    goEdit: function goEdit(record) {
      localStorage.setItem("xinzimubanTemp", JSON.stringify(record));
      this.$router.push({
        path: "/".concat(this.isAdmin ? "contractAdmin" : "wages", "/gongzibiaomuban/edit"),
        query: {
          id: record.id
        }
      });
    },
    saveIssuedSeal: function saveIssuedSeal() {
      var _this4 = this;
      if (this.companyList.length === 0) {
        this.$notification["error"]({
          message: "错误",
          description: "请选择需要下发的公司"
        });
        return;
      }
      if (this.contractList.length === 0) {
        this.$notification["error"]({
          message: "错误",
          description: "请选择需要下发的合同模板"
        });
        return;
      }
      var data = {
        companyId: this.companyList(function (li) {
          return _this4.companyUuidDict[li];
        }),
        salaryTemplateIds: this.contractList
      };
      _saveIssuedSeal(data).then(function (res) {
        _this4.issueModalVisible = false;
        _this4.$notification["success"]({
          message: "成功",
          description: "下发成功"
        });
        _this4.companyList = [];
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 方法
    confirmIssueModal: function confirmIssueModal() {
      // TODO 下发接口
      this.saveIssuedSeal();
    },
    handleCancelIssueModal: function handleCancelIssueModal() {
      this.issueModalVisible = false;
      // 已选的合同模板数据
      this.contractList = [];
      // 已选的公司数据
      this.companyList = [];
    },
    showIssueModal: function showIssueModal() {
      this.issueModalVisible = true;
    }
  }
};