var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "taxCalculate-select-company-wrapper"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "min-height": "24px"
    }
  }, [_c("i", {
    staticClass: "iconfont icon-gongsi-2-line2x",
    staticStyle: {
      "margin-right": "8px",
      "font-size": "16px",
      color: "#55565d"
    },
    on: {
      click: function click(e) {
        return e.preventDefault();
      }
    }
  }), _c("span", {
    staticClass: "font-title",
    on: {
      click: function click(e) {
        return e.preventDefault();
      }
    }
  }, [_vm._v(_vm._s(_vm.enterprise) + " / " + _vm._s(_vm.selectedName))]), _c("a-popover", {
    attrs: {
      trigger: "click",
      visible: _vm.clicked,
      placement: "bottom"
    },
    on: {
      visibleChange: _vm.handleClickChange
    }
  }, [_c("i", {
    staticClass: "iconfont icon-swap2x",
    staticStyle: {
      "margin-left": "8px",
      "font-size": "16px",
      cursor: "pointer"
    },
    on: {
      click: function click(e) {
        return e.preventDefault();
      }
    }
  }), _vm.clicked ? _c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("a-input-search", {
    staticStyle: {
      "margin-bottom": "8px"
    },
    attrs: {
      placeholder: "请输入企业"
    },
    on: {
      change: _vm.onChangeFilter
    }
  }), _c("a-tree", {
    attrs: {
      selectedKeys: [_vm.value],
      "expanded-keys": _vm.expandedKeys,
      "auto-expand-parent": _vm.autoExpandParent,
      replaceFields: _vm.replaceFields,
      "tree-data": _vm.dataList
    },
    on: {
      expand: _vm.onExpand,
      select: _vm.onChange
    }
  })], 1) : _vm._e()])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };