export default {
  fields: {
    postName: {
      model: "postName"
    },
    companyUnitName: {
      model: "companyUnitName"
    },
    candidateCount: {
      model: "candidateCount"
    },
    faceToFaceCount: {
      model: "faceToFaceCount"
    },
    toBeEmployedCount: {
      model: "toBeEmployedCount"
    },
    recruitedCount: {
      model: "recruitedCount"
    },
    gmtCreate: {
      model: "gmtCreate"
    },
    failCount: {
      model: "failCount"
    }
  },
  validators: {},
  config: {
    table: {
      fields: [{
        model: "postName",
        title: "职位",
        width: 360
      }, {
        model: "candidateCount",
        title: "已投递"
      }, {
        model: "faceToFaceCount",
        title: "已约面"
      }, {
        model: "toBeEmployedCount",
        title: "待入职"
      }, {
        model: "recruitedCount",
        title: "已招聘"
      }, {
        model: "failCount",
        title: "未通过"
      }, {
        model: "gmtCreate",
        title: "时间"
      }],
      customize: {
        rowKey: "id"
        // pagination: {
        //   pageSize: 10
        // }
      },

      extend: {
        batch: true,
        action: true
      }
    }
  }
};