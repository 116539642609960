import request from "@/utils/request";
export function getCalculateSalary(recordId) {
  return request({
    url: "/hr/api/v1/attendanceDataTemps/newCalculateSalary/".concat(recordId),
    method: "get"
  });
}
export function getQueryTemplateInfoPage(params) {
  return request({
    url: "/hr/api/v1/attendanceSallaryTemplateItems/queryTemplateInfoPage",
    method: "GET",
    params: params
  });
}