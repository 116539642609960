import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import { mapState } from "vuex";
export default {
  name: "PayslipWaitProvide",
  components: {
    search: function search() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./search"));
      });
    },
    modalDetail: function modalDetail() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./modal-detail"));
      });
    }
  },
  data: function data() {
    return {
      params: {
        empName: "",
        depId: ""
      },
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      dataSource: [],
      options: {},
      visibleModal: false
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  created: function created() {
    this.getList();
  },
  methods: {
    payslip: function payslip(e) {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/salaryBills/listAllRelation",
        params: {
          companyId: this.hr.companyId,
          tableId: this.$route.query.tableId,
          sendStatus: 0,
          reloadCount: true
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.$router.push({
          path: "../wages/add-payslip-sort/add-payslip-second",
          query: _objectSpread({
            unSendCount: _this.pagination.total
          }, _this.$route.query)
        });
      });
    },
    // 获取列表
    getList: function getList() {
      var _this2 = this;
      for (var key in this.params) {
        if (!this.params[key]) delete this.params[key];
      }
      this.$request({
        url: "/hr/api/v1/salaryBillSends/salaryBillListPageBySend",
        params: _objectSpread(_objectSpread({
          companyId: this.hr.companyId,
          tableId: this.$route.query.tableId,
          sendStatus: 0
        }, this.params), {}, {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        })
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this2.pagination.total = data.entityCount || 0;
        _this2.dataSource = data.entities;
      });
    },
    Detail: function Detail(e) {
      this.visibleModal = true;
      this.options = e;
    },
    // 弹窗
    handleChange: function handleChange(e) {
      this.visibleModal = e.visible;
    },
    // search
    searchChange: function searchChange(e) {
      this.params.empName = e.name;
      this.params.depId = e.dep;
      // this.params.pageNo = 1;
      // this.pagination.current = 1;
      this.getList();
    },
    // 翻页
    changePage: function changePage(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getList();
    }
  }
};