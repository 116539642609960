var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "工时详情",
      width: 750,
      footer: null
    },
    on: {
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "modal-box"
  }, [_c("div", {
    staticClass: "m-box"
  }, [_c("a-list-item-meta", [_c("div", {
    staticClass: "tit",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", [_vm._v(_vm._s(_vm.empName))])]), _c("div", {
    staticClass: "description",
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_c("div", [_vm._v(_vm._s(_vm.empPhone))]), _c("div", [_vm._v("|")]), _c("div", [_vm._v("岗位：" + _vm._s(_vm.positionName))])]), _c("a-avatar", {
    staticClass: "avatar",
    attrs: {
      slot: "avatar",
      src: _vm.avatar
    },
    slot: "avatar"
  })], 1)], 1), _c("div", {
    staticClass: "xq-form-wrapper",
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-table", {
    staticClass: "xq-form-content-table",
    attrs: {
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text) {
          return [_c("span", {
            key: index
          }, [_c("span", [_vm._v(_vm._s(text))])])];
        }
      };
    })], null, true)
  })], 1)], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };