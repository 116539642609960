import { render, staticRenderFns } from "./editDetail.vue?vue&type=template&id=db1e0e00&scoped=true&"
import script from "./editDetail.vue?vue&type=script&lang=js&"
export * from "./editDetail.vue?vue&type=script&lang=js&"
import style0 from "./editDetail.vue?vue&type=style&index=0&id=db1e0e00&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db1e0e00",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\tools\\frontend_workspace\\e-hr-master\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db1e0e00')) {
      api.createRecord('db1e0e00', component.options)
    } else {
      api.reload('db1e0e00', component.options)
    }
    module.hot.accept("./editDetail.vue?vue&type=template&id=db1e0e00&scoped=true&", function () {
      api.rerender('db1e0e00', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/wages/file/editDetail.vue"
export default component.exports