import request from "@/utils/request";
import axios from "axios";
import { getToken } from "xqjr-module-auth/utils";

// 电子合同概况
export function getContractCountReq(params) {
  return request({
    url: "/hr/api/v1/memoInfos/getContractCount",
    method: "get",
    params: params
  });
}

// 电子合同分页查询
export function selectContractPageReq(data) {
  return request({
    url: "/hr/api/v1/empContracts/selectContractPagePost",
    method: "post",
    data: data
  });
}

// 查询合同原文件
export function contractAppendixReq(data) {
  return request({
    url: "/contract/api/v1/appendixes/listAll",
    method: "get",
    params: data
  });
}

// 导出电子合同原文件
export function downloadElectronicReq(data) {
  return axios({
    url: "/hr/api/v1/contracts/downloadContract",
    method: "post",
    responseType: "blob",
    data: data,
    headers: {
      token: getToken(),
      "Content-Type": "application/json"
    }
  });
}

// 导出电子合同列表 excel
export function exportAllEleContractReq(data) {
  return axios({
    url: "/hr/api/v1/contracts/exportAllContract",
    method: "post",
    responseType: "blob",
    data: data,
    headers: {
      token: getToken(),
      "Content-Type": "application/json"
    }
  });
}

// 根据合同id删除电子合同及相关信息
export function deleteContractAndRelationByIdReq(contractId) {
  return request({
    url: "/hr/api/v1/contracts/deleteContractAndRelationById/".concat(contractId),
    method: "delete"
  });
}

// 批量删除电子合同
export function deleteContractBatchReq(data) {
  return request({
    url: "/hr/api/v1/contracts/deleteContractBatch",
    method: "delete",
    data: data
  });
}

// 分页查询纸质合同(可以根据emp的相关信息搜索)
export function selectPageByEmpInfoReq(data) {
  return request({
    url: "/hr/api/v1/empPaperContracts/selectPageByEmpInfoPost",
    method: "post",
    data: data
  });
}

// 统计纸质合同各种类型的数量(用工单位级使用，用工单位id必传)
export function statisticsCountByCompanyIdReq(companyId) {
  return request({
    url: "/hr/api/v1/empPaperContracts/statisticsCountByCompanyId/".concat(companyId),
    method: "get"
  });
}

// 导出纸质合同列表 excel
export function exportPaperContractReq(data) {
  return axios({
    url: "/hr/api/v1/empPaperContracts/exportPaperContract",
    method: "post",
    responseType: "blob",
    data: data,
    headers: {
      token: getToken(),
      "Content-Type": "application/json"
    }
  });
}

// 删除单个纸质合同
export function empPaperContractsDeleteReq(empPaperContractId) {
  return request({
    url: "/hr/api/v1/empPaperContracts/".concat(empPaperContractId),
    method: "delete"
  });
}

// 批量删除纸质合同
export function empPaperContractsDeleteBatchReq(data) {
  return request({
    url: "/hr/api/v1/empPaperContracts",
    method: "delete",
    data: data
  });
}

// 下载附件
export function downloadAttachmentReq(data) {
  return axios({
    url: "/file/api/v1/site/download",
    method: "post",
    data: data,
    responseType: "blob",
    headers: {
      token: getToken()
    }
  });
}

// 分页查询员工全部合同信息（电子、纸质都查询）
export function pageElectronicAndPaperReq(data) {
  return request({
    url: "/hr/api/v1/empContracts/pageElectronicAndPaperPost",
    method: "post",
    data: data
  });
}

// 查询员工各种合同数量（电子、纸质都查询）
export function selectElectronicAndPaperNumberReq(companyIdList) {
  return request({
    url: "/hr/api/v1/empContracts/selectElectronicAndPaperNumberPost",
    method: "post",
    data: {
      companyIdList: companyIdList
    }
  });
}

// 导出全部合同列表
export function exportAllContractReq(data) {
  return axios({
    url: "/hr/api/v1/empContracts/exportAllContract",
    method: "post",
    responseType: "blob",
    data: data,
    headers: {
      token: getToken(),
      "Content-Type": "application/json"
    }
  });
}

// 批量删除全部合同
export function deleteBatchElectronicAndPaperNumberReq(data) {
  return request({
    url: "/hr/api/v1/empContracts/deleteBatchElectronicAndPaperNumber",
    method: "delete",
    data: data
  });
}

// 总览合同数量
export function getContractCountBySubjectIdsReq(data) {
  return request({
    url: "/hr/api/v1/overview/getContractCountBySubjectIds",
    method: "post",
    data: data
  });
}

// 获取合同签约排行
export function getComCountPostReq(data) {
  return request({
    url: "/hr/api/v1/contracts/getComCountPost",
    // "/hr/api/v1/contracts/getComCount", // "/contract/api/v1/overview/getComCount?pageNo=" + params + "&pageSize=7",
    method: "post",
    data: data
  });
}

// 按时间段统计合同数量
export function getTongji(index, data) {
  switch (index) {
    case 0:
      return request({
        url: "/contract/api/v1/overview/selectContractCountWeek",
        // "/contract/api/v1/overview/getWeekList",
        method: "post",
        data: data
      });
    case 1:
      return request({
        url: "/contract/api/v1/overview/selectContractCountMonth",
        // "/contract/api/v1/overview/getMonthList",
        method: "post",
        data: data
      });
    case 2:
      return request({
        url: "/contract/api/v1/overview/selectContractCountYear",
        // "/contract/api/v1/overview/getYearList",
        method: "post",
        data: data
      });
    case -1:
      // 时间段
      return request({
        url: "/contract/api/v1/overview/selectContractCountFree",
        // "/contract/api/v1/overview/getTimeSlotCount/" + params.startTime + "/" + params.endTime,
        method: "post",
        data: data
      });
  }
}
export function enterpriseSignReq(data) {
  return request({
    url: "/contract/api/v1/signs/enterpriseSignAgain",
    method: "post",
    data: data
  });
}
export function enterpriseSignBatchReq(data) {
  return request({
    url: "/hr/api/v1/contracts/enterpriseSignAgainBatch",
    method: "post",
    data: data
  });
}