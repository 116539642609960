import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import { getTemplateItems, addTemplateItems } from "@/api/wages/template";
import { isContainByString } from "@/utils/index";
var columns = [{
  title: "工资表项目",
  dataIndex: "name",
  key: "name",
  scopedSlots: {
    customRender: "name"
  },
  width: 300
}, {
  title: "加/减项",
  dataIndex: "isAdd",
  key: "isAdd",
  scopedSlots: {
    customRender: "isAdd"
  },
  width: 80
}, {
  title: "是否开启",
  key: "isUse",
  scopedSlots: {
    customRender: "isUse"
  },
  width: 100
}];
export default {
  data: function data() {
    return {
      isContainByString: isContainByString,
      loading: true,
      addState: true,
      relative: {},
      editList: {},
      inputName: "",
      companyId: this.$store.state.hr.companyId,
      editVisible: false,
      dataList: [],
      dataListCopy: [],
      columns: columns,
      visible: false,
      decimalPlaces: undefined,
      form: {},
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 18
      },
      recordKey: null
    };
  },
  created: function created() {
    this.getTemplateItemsList();
  },
  methods: {
    // 拖动排序
    // customRow(record, index) {
    //   return {
    //     props: {
    //       // draggable: "true"
    //     },
    //     style: {
    //       cursor: "pointer"
    //     },
    //     on: {
    //       // 鼠标移入
    //       mouseenter: event => {
    //         // 兼容IE
    //         var ev = event || window.event;
    //         ev.target.draggable = true;
    //       },
    //       // 开始拖拽
    //       dragstart: event => {
    //         // 兼容IE
    //         var ev = event || window.event;
    //         // 阻止冒泡
    //         ev.stopPropagation();
    //         // 得到源目标数据
    //         this.sourceObj = record;
    //         this.sourceIndex = index;
    //       },
    //       // 拖动元素经过的元素
    //       dragover: event => {
    //         // 兼容 IE
    //         var ev = event || window.event;
    //         // 阻止默认行为
    //         ev.preventDefault();
    //       },
    //       // 鼠标松开
    //       drop: event => {
    //         // 兼容IE
    //         var ev = event || window.event;
    //         // 阻止冒泡
    //         ev.stopPropagation();
    //         // 得到目标数据
    //         this.targetObj = record;
    //         this.targetIndex = index;
    //         this.dataList[this.targetIndex] = this.sourceObj;
    //         this.dataList[this.sourceIndex] = this.targetObj;
    //         this.dataList = JSON.parse(JSON.stringify(this.dataList));
    //       }
    //     }
    //   };
    // },
    goBack: function goBack() {
      var _this = this;
      this.$confirm({
        title: "工资表模板尚未保存，是否确认离开？",
        okText: "确定",
        cancelText: "取消",
        onOk: function onOk() {
          _this.$router.go(-1);
        }
      });
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;
      this.dataListCopy = JSON.parse(JSON.stringify(this.dataList));
      if (this.inputName && this.decimalPlaces || this.inputName && this.decimalPlaces === 0) {
        var arr = JSON.parse(JSON.stringify(this.dataList));
        arr.splice(arr.length - 7, 7);
        arr.forEach(function (item, index) {
          item.sort = index;
        });
        this.loading = true;
        addTemplateItems({
          companyId: this.isContainByString("contractAdmin") ? "0" : this.companyId,
          name: this.inputName,
          salaryTemplateItemAddDTOList: arr,
          decimalPlaces: this.decimalPlaces
        }).then(function (_ref) {
          var success = _ref.success;
          if (success) {
            history.back();
            _this2.$notification["success"]({
              message: "保存成功"
            });
          }
          _this2.loading = false;
        }).catch(function (err) {
          _this2.loading = false;
          _this2.dataList = JSON.parse(JSON.stringify(_this2.dataListCopy));
          console.log("debug log --> ", err, _this2.dataListCopy);
        });
      } else if (!this.inputName) {
        this.$notification["error"]({
          message: "error",
          description: "保存失败,模板名称必填"
        });
      } else if (this.decimalPlaces === undefined) {
        this.$notification["error"]({
          message: "error",
          description: "保存失败,尾数处理位数必选"
        });
      }
    },
    handleEditClick: function handleEditClick(record) {
      this.recordKey = record.key;
      this.editVisible = true;
      this.editList = JSON.parse(JSON.stringify(record));
    },
    handleEditOk: function handleEditOk() {
      var _this3 = this;
      this.addState = true;
      this.$refs.ruleEditForm.validate(function (valid) {
        if (valid) {
          _this3.dataList.forEach(function (item, index) {
            if (_this3.recordKey !== index) {
              if (item.name === _this3.editList.name) {
                _this3.addState = false;
                _this3.$notification["error"]({
                  message: "error",
                  description: "工资表项目已有此项目名称"
                });
              }
            }
          });
          if (_this3.addState === true) {
            _this3.dataList.forEach(function (item) {
              if (item.key === _this3.editList.key) {
                item.name = JSON.parse(JSON.stringify(_this3.editList.name));
                item.isAdd = JSON.parse(JSON.stringify(_this3.editList.isAdd));
                item.isTaxCalculation = JSON.parse(JSON.stringify(_this3.editList.isTaxCalculation));
                _this3.editVisible = false;
              }
            });
            _this3.$notification["success"]({
              message: "success",
              description: "编辑成功"
            });
          } else {
            return false;
          }
        }
      });
    },
    getTemplateItemsList: function getTemplateItemsList() {
      var _this4 = this;
      this.loading = true;
      getTemplateItems().then(function (res) {
        _this4.loading = false;
        _this4.dataList = [];
        res.data.forEach(function (item, index) {
          _this4.dataList.push(_objectSpread(_objectSpread({}, item), {}, {
            id: item.id ? item.id : index
          }));
        });
        _this4.dataList.splice(_this4.dataList.length, 0, {
          name: "应发工资",
          isAdd: 0,
          isUse: true,
          isDefault: true
        }, {
          name: "个人社保",
          isAdd: 0,
          isUse: true,
          isDefault: true
        }, {
          name: "企业社保",
          isAdd: 0,
          isUse: true,
          isDefault: true
        }, {
          name: "个人公积金",
          isAdd: 0,
          isUse: true,
          isDefault: true
        }, {
          name: "企业公积金",
          isAdd: 0,
          isUse: true,
          isDefault: true
        }, {
          name: "个人所得税",
          isAdd: 0,
          isUse: true,
          isDefault: true
        }, {
          name: "实发工资",
          isAdd: 0,
          isUse: true,
          isDefault: true
        });
      });
    },
    handleClick: function handleClick() {
      this.visible = true;
      this.form = {};
      // this.$refs.ruleForm.resetFields();
    },
    handleOk: function handleOk() {
      var _this5 = this;
      this.addState = true;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this5.dataList.forEach(function (item) {
            if (item.name === _this5.form.name) {
              _this5.addState = false;
              _this5.$notification["error"]({
                message: "error",
                description: "工资表项目已有此项目名称"
              });
            }
          });
          if (_this5.addState === true) {
            _this5.dataList.splice(_this5.dataList.length - 7, 0, {
              name: _this5.form.name,
              isAdd: _this5.form.mode,
              isUse: true,
              isDefault: false,
              isTaxCalculation: _this5.form.isTaxCalculation
            });
            _this5.dataList.forEach(function (item, index) {
              item.key = index;
              _this5.visible = false;
            });
            _this5.$notification["success"]({
              message: "success",
              description: "添加成功"
            });
          }
        } else {
          return false;
        }
      });
    },
    handleDeleteClick: function handleDeleteClick(record) {
      var _this6 = this;
      this.dataList.forEach(function (item) {
        _this6.dataList = _this6.dataList.filter(function (item) {
          return item.key !== record.key;
        });
      });
      this.$notification["success"]({
        message: "success",
        description: "删除成功"
      });
    },
    checkChange: function checkChange() {
      var _this7 = this;
      this.dataList.forEach(function (item) {
        if (item.key === _this7.editList.key) {
          item.isUse = JSON.parse(JSON.stringify(_this7.editList.isuse));
        }
      });
    }
  }
};