var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "uplaodBoxs"
  }, [_c("a-spin", {
    staticClass: "spin-content",
    attrs: {
      tip: "上传中。。。",
      spinning: _vm.uploading
    }
  }, [_c("div", {
    staticClass: "ant-upload-select-text-box",
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-upload", {
    ref: "upload",
    attrs: {
      accept: ".xls, .xlsx, .xlsm",
      "file-list": _vm.fileList,
      remove: _vm.handleRemove,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("img", {
    staticStyle: {
      width: "139px",
      height: "106px"
    },
    attrs: {
      src: _vm.uploadImg
    }
  }), _c("div", {
    staticClass: "ant-modal-body"
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#666"
    }
  }, [_vm._v("点击添加文件或将文件拖拽到此处")]), _c("div", {
    staticStyle: {
      "font-size": "12px",
      color: "#999"
    }
  }, [_vm._v("(文件大小不超过5M)")])])])], 1)]), _c("div", {
    staticClass: "tipBox"
  }, [_c("a-row", {
    staticStyle: {
      color: "#252525",
      "font-size": "16px",
      "font-weight": "500",
      "padding-bottom": "10px"
    }
  }, [_vm._v(" 温馨提示 ")]), _c("a-row", [_c("a-col", {
    staticStyle: {
      "font-size": "14px"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", [_vm._v(" 1、建议通过标准模板进行导入,点击 "), _c("span", {
    staticStyle: {
      "font-size": "14px",
      color: "#6a66f6",
      cursor: "pointer"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v(" 下载标准模板 ")])]), _c("div", [_vm._v("2、支持xlsx，xlx格式，单次导入最多支持读取500条数据")])])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };