var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "mb24 pv16 ph24 bgc-white br4 flex-center"
  }, [_c("SelectCompany")], 1), _c("SummaryBarOverview", {
    on: {
      selectChange: _vm.onSelectChange
    }
  }), _c("OverviewChart"), _c("a-tabs", {
    staticClass: "bgc-white ph24 pv12",
    on: {
      change: _vm.onTabChange
    },
    model: {
      value: _vm.activeKey,
      callback: function callback($$v) {
        _vm.activeKey = $$v;
      },
      expression: "activeKey"
    }
  }, [_c("a-tab-pane", {
    key: "all",
    attrs: {
      tab: "全部合同记录"
    }
  }, [_c("ListAll", {
    ref: "list_all",
    attrs: {
      mode: "overview"
    }
  })], 1), _c("a-tab-pane", {
    key: "electronic",
    attrs: {
      tab: "电子合同记录"
    }
  }, [_c("ListElectronic", {
    ref: "list_electronic",
    attrs: {
      mode: "overview"
    }
  })], 1), _c("a-tab-pane", {
    key: "paper",
    attrs: {
      tab: "纸质合同记录"
    }
  }, [_c("ListPaper", {
    ref: "list_paper",
    attrs: {
      mode: "overview"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };