import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find-index.js";
import vueWangeditor from "./vueWangeditor";
import { variableListAll, variableListAll2, templateUpdateReq, templateDetailReq, templateVariablesBatchReq } from "@/api/company/contract";
import { isContainByString } from "@/utils/index";
export default {
  name: "Edit",
  components: {
    vueWangeditor: vueWangeditor
  },
  data: function data() {
    return {
      isContainByString: isContainByString,
      templateId: null,
      template: null,
      name: "",
      menus: ["bold",
      // 粗体
      "underline",
      // 下划线
      "italic",
      // 斜体
      "strikeThrough",
      // 中线
      "eraser",
      // 清空格式
      "foreColor",
      // 文字颜色
      "backColor",
      // 背景颜色
      "quote",
      // 引用
      "fontSize",
      // 字号
      "head",
      // 标题
      "list",
      // 无序列表
      "justify",
      // 有序列表
      "undo",
      // 撤销
      "redo",
      // 重做
      "fullscreen"],
      initContent: "",
      content: "",
      options: {},
      cursorPosition: null,
      variables: [],
      variableList: [],
      editorHeight: 0,
      customStyle: "background: #fff;border: 0;overflow: hidden;",
      footerBtnW: 0,
      personalVars: []
    };
  },
  created: function created() {
    this.categoryId = this.$route.params.categoryId;
    this.templateId = this.$route.params.id;
    this.loadTemplate();
    this.loadVaribles();
  },
  mounted: function mounted() {
    // this.getCursorPosition()
    if (document.getElementById("editor-main")) {
      this.editorHeight = document.getElementById("editor-main").offsetHeight - 40;
    }
    // this.footerBtnW = document.querySelector(".main-content-wrapper").offsetWidth;
    // this.footerBtnW = document.querySelector(".main-contentr").offsetHeight;
  },

  methods: {
    loadVaribles: function loadVaribles() {
      var _this = this;
      variableListAll2({
        companyId: this.$store.state.hr.companyId
      })
      // variableListAll()
      .then(function (res) {
        var varibles = res.data;
        _this.variableList = res.data;
        var jsonArr = [];
        var enterpriseVars = varibles.filter(function (item) {
          return item.type === "enterprise";
        });
        // const personalVars = varibles.filter(item => item.type === "personal");
        var contractVars = varibles.filter(function (item) {
          return item.type === "contract";
        });
        // 基本信息
        var basicArr = varibles.filter(function (item) {
          return item.type === "basic";
        });
        if (basicArr.length > 0) jsonArr.push({
          name: "基本信息",
          val: basicArr
        });
        // 身份信息
        var empIdCardArr = varibles.filter(function (item) {
          return item.type === "empIdCard";
        });
        if (empIdCardArr.length > 0) jsonArr.push({
          name: "身份信息",
          val: empIdCardArr
        });
        // 联系信息
        var contactArr = varibles.filter(function (item) {
          return item.type === "contact";
        });
        if (contactArr.length > 0) jsonArr.push({
          name: "联系信息",
          val: contactArr
        });
        // 家庭成员
        var empFamilyArr = varibles.filter(function (item) {
          return item.type === "empFamily";
        });
        if (empFamilyArr.length > 0) jsonArr.push({
          name: "家庭成员",
          val: empFamilyArr
        });
        // 紧急联系人
        var empContactsArr = varibles.filter(function (item) {
          return item.type === "empContacts";
        });
        if (empContactsArr.length > 0) jsonArr.push({
          name: "紧急联系人",
          val: empContactsArr
        });
        // 教育经历
        var empEducationArr = varibles.filter(function (item) {
          return item.type === "empEducation";
        });
        if (empEducationArr.length > 0) jsonArr.push({
          name: "教育经历",
          val: empEducationArr
        });
        _this.variables = [{
          title: "企业信息",
          children: enterpriseVars
        }, {
          title: "员工个人信息",
          children: jsonArr
        }, {
          title: "合同信息",
          children: contractVars
        }];
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      variableListAll().then(function (res) {
        _this.personalVars = res.data.filter(function (item) {
          return item.type === "personal";
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    loadTemplate: function loadTemplate() {
      var _this2 = this;
      templateDetailReq(this.templateId).then(function (res) {
        _this2.template = res.data;
        _this2.name = _this2.template.name;
        _this2.initContent = _this2.template.content.replaceAll("&lt;", "<").replaceAll("&gt;", ">").replaceAll("&amp;", "&");
        _this2.content = _this2.template.content;
        _this2.$refs.editor.setHtml(_this2.initContent);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    saveTemplate: function saveTemplate() {
      var _this3 = this;
      templateUpdateReq(Object.assign(this.template, {
        name: this.name,
        content: this.content,
        contentJson: this.content
      })).then(function (res) {
        _this3.updateTemplateVariables();
        _this3.$message.success("合同模板更新成功");
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this3.$message.error("合同模板更新失败");
      });
    },
    updateTemplate: function updateTemplate() {
      var _this4 = this;
      templateUpdateReq(Object.assign(this.template, {
        name: this.name,
        content: this.content,
        contentJson: this.content
      })).then(function (res) {
        _this4.updateTemplateVariables();
        _this4.$message.success("默认保存合同模板，正在跳转印章设置");
        _this4.$router.push("/".concat(_this4.isContainByString("contractAdmin") ? "contractAdmin" : "contract", "/type/seal/").concat(_this4.templateId));
      }).catch(function (err) {
        console.log("debug log --> ", err);
        _this4.$message.error("合同模板更新失败");
      });
    },
    updateTemplateVariables: function updateTemplateVariables() {
      var _this5 = this;
      // 正则匹配
      // const regex1ARR = this.content.match(/#[A-Za-z0-9\u4e00-\u9fa5]+#/g) || [];
      // const regex2ARR = this.content.match(/#[\u4e00-\u9fa5]+\((.+?)\)+#/g) || [];
      // const regex3ARR = this.content.match(/#.*?#/g) || [];
      var varibleKeys = this.content.match(/#.*?#/g) || [];
      if (varibleKeys && varibleKeys.length > 0) {
        var listTemp = varibleKeys.map(function (key) {
          var keyTemp = key.replace(/#/g, "");
          var idx = _this5.variableList.findIndex(function (item) {
            return item["name"] === keyTemp;
          });
          var idx2 = _this5.personalVars.findIndex(function (item) {
            return item["name"] === keyTemp;
          });
          if (idx > -1) {
            return {
              templateId: _this5.templateId,
              variableId: _this5.variableList[idx]["id"],
              necessaryId: _this5.variableList[idx]["necessaryId"],
              additionalId: _this5.variableList[idx]["additionalId"],
              personalVarId: idx2 !== -1 ? _this5.personalVars[idx2]["id"] : null
            };
          }
        });
        var json = {
          templateId: this.templateId,
          variableIds: [],
          necessaryIds: [],
          additionalIds: [],
          personalVarIds: []
        };
        listTemp.map(function (item) {
          if (item) {
            var idx1 = json.variableIds.findIndex(function (it) {
              return it === item.variableId;
            });
            if (idx1 === -1 && item.variableId) {
              json.variableIds.push(item.variableId);
            }
            var idx2 = json.necessaryIds.findIndex(function (it) {
              return it === item.necessaryId;
            });
            if (idx2 === -1 && item.necessaryId) {
              json.necessaryIds.push(item.necessaryId);
            }
            var idx3 = json.additionalIds.findIndex(function (it) {
              return it === item.additionalId;
            });
            if (idx3 === -1 && item.additionalId) {
              json.additionalIds.push(item.additionalId);
            }
            var idx4 = json.personalVarIds.findIndex(function (it) {
              return it === item.personalVarId;
            });
            if (idx4 === -1 && item.personalVarId) {
              json.personalVarIds.push(item.personalVarId);
            }
          }
        });
        json.variableIds = [].concat(_toConsumableArray(json.variableIds), _toConsumableArray(json.personalVarIds));
        delete json.personalVarIds;
        // console.log(json)
        templateVariablesBatchReq(json).then(function (res) {}).catch(function (err) {
          console.log("debug log --> ", err);
        });
      }
    },
    back: function back() {
      this.$router.go(-1);
    },
    handleVariable: function handleVariable(text) {
      // this.getCursorPosition()
      this.insertVariable(text);
    },
    getCursorPosition: function getCursorPosition() {
      this.$refs.editor.quill.focus();
      var range = this.$refs.editor.quill.getSelection();
      this.cursorPosition = range.index;
    },
    insertVariable: function insertVariable(text) {
      this.$refs.editor.insertHtml("<span>&nbsp;</span>");
      var tempText = "<a style=\"color: #6A66F6; text-decoration: underline\">#".concat(text, "#</a>");
      this.$refs.editor.insertHtml(tempText);
      this.$refs.editor.insertHtml("<span>&nbsp;</span>");
    }
  }
};