import "core-js/modules/es.array.fill.js";
export default {
  name: "Seal",
  props: {
    roundText: {
      type: String,
      default: "此处为企业名称"
    },
    rowText: {
      type: String,
      default: "此处文横向文"
    },
    bottomText: {
      type: String,
      default: "此处为下弦文"
    },
    type: {
      type: String,
      default: "circle" // circle || oval
    },

    color: {
      type: String,
      default: "black"
    },
    value: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      sealString: ""
    };
  },
  watch: {
    roundText: function roundText() {
      this.updateSeal();
    },
    rowText: function rowText() {
      this.updateSeal();
    },
    bottomText: function bottomText() {
      this.updateSeal();
    },
    type: function type() {
      this.updateSeal();
    },
    color: function color() {
      this.updateSeal();
    }
  },
  mounted: function mounted() {
    this.updateSeal();
  },
  methods: {
    updateSeal: function updateSeal() {
      if (document.getElementById("canvas")) {
        this.type === "circle" ? this.updateCircleSeal(this.roundText, this.rowText, this.bottomText, this.color) : this.updateOvalSeal(this.roundText, this.rowText, this.bottomText, this.color);
      }
    },
    emitChange: function emitChange(canvas) {
      this.sealString = canvas.toDataURL("image/png");
      this.$emit("input", this.sealString);
      this.$emit("change", this.sealString);
    },
    updateCircleSeal: function updateCircleSeal(company, name, downText, color) {
      var canvas = document.getElementById("canvas");
      var context = canvas.getContext("2d");
      context.setTransform(1, 0, 0, 1, 0, 0);
      context.clearRect(0, 0, canvas.width, canvas.height);

      // 绘制印章边框
      var width = canvas.width / 2;
      var height = canvas.height / 2;
      context.lineWidth = 4;
      context.strokeStyle = color;
      context.beginPath();
      context.arc(width, height, 110, 0, Math.PI * 2);
      context.stroke();

      // 画五角星
      this.createStar(context, width, height - 15, 25, color, 0);

      // 绘制印章名称
      context.font = "18px Helvetica";
      context.textBaseline = "middle"; // 设置文本的垂直对齐方式
      context.textAlign = "center"; // 设置文本的水平对对齐方式
      context.lineWidth = 1;
      context.fillStyle = color;
      context.fillText(name, width, height + 30);
      context.translate(width, height); // 平移到此位置

      // 绘制印章单位
      var count = company.length; // 字数
      var isLongName = count > 12;
      context.font = isLongName ? "23px Helvetica" : "28px Helvetica";
      var rangeAngle = isLongName ? Math.PI * 1.3 : count >= 8 ? Math.PI : Math.PI * 5 / 6;
      var spaceAngle = (Math.PI - rangeAngle) / 2;
      var angle = rangeAngle / (count - 1); // 字间角度
      var chars = company.split("");
      var c;
      for (var i = 0; i < count; i++) {
        c = chars[i]; // 需要绘制的字符
        if (i === 0) context.rotate(-Math.PI + spaceAngle);else context.rotate(angle);
        context.save();
        context.translate(90, 0); // 平移到此位置,此时字和x轴垂直
        context.rotate(Math.PI / 2); // 旋转90度,让字平行于x轴
        context.fillText(c, 0, isLongName ? -2 : 3); // 此点为字的中心点
        context.restore();
      }
      context.rotate(spaceAngle);

      // 绘制下弦文
      context.font = isLongName ? "16px Helvetica" : "20px Helvetica";
      var count2 = downText.length; // 字数
      var rangeAngle2 = isLongName ? Math.PI * 0.5 : count2 >= 6 ? 2 / 3 * Math.PI : Math.PI / 2;
      var spaceAngle2 = (Math.PI - rangeAngle2) / 2;
      var angle2 = rangeAngle2 / (count2 - 1); // 字间角度
      var chars2 = downText.split("");
      var c2 = "";
      for (var _i = count2 - 1; _i >= 0; _i--) {
        c2 = chars2[_i]; // 需要绘制的字符
        if (_i === count2 - 1) context.rotate(spaceAngle2);else context.rotate(angle2);
        context.save();
        context.translate(90, 0); // 平移到此位置,此时字和x轴垂直
        context.rotate(Math.PI / 2 * 3); // 旋转90度,让字平行于x轴
        context.fillText(c2, 0, isLongName ? 8 : -2); // 此点为字的中心点
        context.restore();
      }
      this.emitChange(canvas);
    },
    updateOvalSeal: function updateOvalSeal(company, name, downText, color) {
      var canvas = document.getElementById("canvas");
      var context = canvas.getContext("2d");
      context.setTransform(1, 0, 0, 1, 0, 0);
      context.clearRect(0, 0, canvas.width, canvas.height);
      var width = canvas.width;
      var height = canvas.height;
      var lineWidth = 2;
      context.strokeStyle = color; // 设置文本颜色
      context.textBaseline = "middle"; // 设置文本的垂直对齐方式
      context.textAlign = "center"; // 设置文本的水平对对齐方式
      context.lineWidth = lineWidth; // 椭圆1宽度
      // 3个参数： 左边距 上边据 宽度 椭圆扁度
      this.BezierEllipse4(context, width / 2, height / 2, (width - lineWidth) / 2, (width - lineWidth) / 3); // 椭圆1

      // 绘制印章类型
      context.font = "16px SimSun";
      context.lineWidth = 1;
      context.fillStyle = color;
      context.fillText(name, width / 2, height / 2);
      context.save();

      // 绘制中文
      var ccircle = {
        x: width / 2,
        y: height / 2,
        radius: width / 3
      };
      var cstartAngle = 160; // 控制字符起始位置度数
      var cendAngle = 20; // 首位字符相隔度数
      var cradius = ccircle.radius - 11; // 圆的半径
      var cangleDecrement = (cstartAngle - cendAngle) / (company.length - 1); // 每个字母占的弧度
      context.font = "18px SimSun";
      var cratioX = 1.5; // 横轴缩放比率
      var cratioY = 1.1; // 纵轴缩放比率
      // 进行缩放（均匀压缩）
      context.scale(cratioX, cratioY);
      for (var cindex = 0; cindex < company.length; cindex++) {
        context.save();
        context.beginPath();
        // 绘制点
        context.translate(ccircle.x + Math.cos(Math.PI / 180 * cstartAngle) * cradius - 42, ccircle.y - Math.sin(Math.PI / 180 * cstartAngle) * cradius);
        context.rotate(Math.PI / 2 - Math.PI / 180 * cstartAngle); // Math.PI/2为旋转90度  Math.PI/180*X为旋转多少度
        context.fillText(company.charAt(cindex), 0, 0);
        context.strokeText(company.charAt(cindex), 0, 0);
        cstartAngle -= cangleDecrement;
        context.restore();
      }
      context.scale(1, 1);

      // 绘制中文2
      var ccircl2 = {
        x: width / 2,
        y: height / 2,
        radius: width / 3
      };
      var cstartAngl2 = -30; // 控制字符起始位置度数
      var cradiu2 = ccircl2.radius - 25; // 圆的半径
      var cangleDecrement2 = 120 / (downText.length - 1); // 每个字母占的弧度
      context.font = "12px SimSun";
      var cratioX2 = 1.2; // 横轴缩放比率
      var cratioY2 = 1; // 纵轴缩放比率
      // 进行缩放（均匀压缩）
      context.scale(cratioX2, cratioY2);
      for (var _cindex = downText.length - 1; _cindex >= 0; _cindex--) {
        context.save();
        context.beginPath();
        // 绘制点
        context.translate(ccircl2.x + Math.cos(Math.PI / 180 * cstartAngl2) * cradiu2 - 55, ccircl2.y - Math.sin(Math.PI / 180 * cstartAngl2) * cradiu2 - 5);
        context.rotate(Math.PI / 2 * 3 - Math.PI / 180 * cstartAngl2); // Math.PI/2为旋转90度  Math.PI/180*X为旋转多少度
        context.fillText(downText.charAt(_cindex), 0, 0);
        context.strokeText(downText.charAt(_cindex), 0, 0);
        cstartAngl2 -= cangleDecrement2;
        context.restore();
      }
      this.emitChange(canvas);
    },
    createStar: function createStar(context, sx, sy, radius, color, rotato) {
      context.save();
      context.fillStyle = color;
      context.translate(sx, sy); // 移动坐标原点
      context.rotate(Math.PI + rotato); // 旋转
      context.beginPath(); // 创建路径
      var x = Math.sin(0);
      var y = Math.cos(0);
      var dig = Math.PI / 5 * 4;
      for (var i = 0; i < 5; i++) {
        // 画五角星的五条边
        x = Math.sin(i * dig);
        y = Math.cos(i * dig);
        context.lineTo(x * radius, y * radius);
      }
      context.closePath();
      context.stroke();
      context.fill();
      context.restore();
    },
    BezierEllipse4: function BezierEllipse4(ctx, x, y, a, b) {
      var k = 0.5522848;
      var ox = a * k; // 水平控制点偏移量
      var oy = b * k; // 垂直控制点偏移量
      ctx.beginPath();
      // 从椭圆的左端点开始顺时针绘制四条三次贝塞尔曲线
      ctx.moveTo(x - a, y);
      ctx.bezierCurveTo(x - a, y - oy, x - ox, y - b, x, y - b);
      ctx.bezierCurveTo(x + ox, y - b, x + a, y - oy, x + a, y);
      ctx.bezierCurveTo(x + a, y + oy, x + ox, y + b, x, y + b);
      ctx.bezierCurveTo(x - ox, y + b, x - a, y + oy, x - a, y);
      ctx.closePath();
      ctx.stroke();
    }
  }
};