export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // 确定
    handleOk: function handleOk(v) {
      this.$emit("changeSW", v);
      this.$emit("update:visible", false);
    }
  }
};