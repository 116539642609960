import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  props: ["value"],
  computed: {
    imgData: function imgData() {
      var _this = this;
      if (this.value && this.value !== "") {
        var srcData = JSON.parse(JSON.stringify(this.value)).split(",");
        srcData.forEach(function (element, index) {
          srcData[index] = srcData[index] + "?token=".concat(_this.$store.state.auth.token);
        });
        return srcData;
      } else {
        return [];
      }
    }
  }
};