import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page_set page-miH page-bgBack"
  }, [_c("a-form", {
    staticClass: "formrow",
    attrs: {
      layout: "inline",
      form: _vm.searchForm,
      colon: true
    }
  }, [_c("a-form-item", [_c("a-input-search", {
    staticStyle: {
      width: "240px"
    },
    attrs: {
      placeholder: "关键字搜索",
      "allow-clear": true
    },
    on: {
      search: _vm.searchContarct
    },
    model: {
      value: _vm.title,
      callback: function callback($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      "margin-right": "0px"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      icon: "plus"
    },
    on: {
      click: _vm.createNotice
    }
  }, [_vm._v("新建通告")])], 1)], 1), _c("a-row", {
    staticClass: "allNews"
  }, [_vm._v(" 全部消息 "), _c("span", [_vm._v("（消息最多保存180天）")])]), _c("a-table", {
    attrs: {
      columns: _vm.noticeColumns,
      "data-source": _vm.noticelist,
      pagination: _vm.pagination,
      "row-key": "id",
      loading: _vm.loading
    },
    on: {
      change: _vm.tabChange
    },
    scopedSlots: _vm._u([{
      key: "signNo",
      fn: function fn(text, record) {
        return _c("span", {}, [_c("span", {
          staticStyle: {
            "font-size": "12px"
          }
        }, [_vm._v(_vm._s(record.signNo ? record.signNo : "--"))])]);
      }
    }, {
      key: "readStatus",
      fn: function fn(text, record) {
        return _c("a", {
          on: {
            click: function click($event) {
              return _vm.showDetail(record);
            }
          }
        }, [_c("span", {
          class: text === 0 ? "colorC0C0C0" : text === 1 ? "tc-theme" : "colorC0C0C0"
        }, [_vm._v("•")]), text === 0 ? _c("span", {
          staticStyle: {
            color: "#595959"
          }
        }, [_vm._v("共" + _vm._s(record.readCount + record.noReadCount) + "人未读" + _vm._s(record.noReadCount) + "人")]) : _vm._e(), text === 1 ? _c("span", {
          staticStyle: {
            color: "#595959"
          }
        }, [_vm._v("共" + _vm._s(record.readCount + record.noReadCount) + "人全部已读")]) : _vm._e(), text === 2 ? _c("span", {
          staticStyle: {
            color: "#595959"
          }
        }, [_vm._v("共" + _vm._s(record.readCount + record.noReadCount) + "人未读" + _vm._s(record.noReadCount) + "人")]) : _vm._e()]);
      }
    }, {
      key: "employeeName",
      fn: function fn(text, record) {
        return _c("span", {}, [_vm._v(" " + _vm._s(record.partySignAccountList.filter(function (item) {
          return item.type === 1;
        })[0] && record.partySignAccountList.filter(function (item) {
          return item.type === 1;
        })[0].name !== null ? record.partySignAccountList.filter(function (item) {
          return item.type === 1;
        })[0].name : "--") + " ")]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("span", {}, [_c("span", {
          staticStyle: {
            display: "flex",
            "align-items": "center"
          }
        }, [_c("a", {
          staticClass: "primary_color pointer",
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.goDetail(record);
            }
          }
        }, [_vm._v("详情")])])]);
      }
    }])
  }), _c("a-modal", {
    attrs: {
      title: "阅读状态",
      visible: _vm.recordVisible,
      width: "750px"
    },
    on: {
      ok: function ok($event) {
        _vm.recordVisible = false;
      },
      cancel: function cancel($event) {
        _vm.recordVisible = false;
      }
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      width: "350px",
      color: "#767885",
      "font-size": "14px",
      height: "300px",
      "margin-left": "8px",
      overflow: "scroll"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_vm._v("已读人员"), _c("span", {
    staticClass: "primary"
  }, [_vm._v(_vm._s(_vm.recordDetail.readCount) + "人")])]), _vm.recordDetail.readCount === 0 || !_vm.recordDetail.readCount ? _c("div", [_c("a-empty", {
    staticStyle: {
      "margin-top": "40px"
    }
  })], 1) : _c("div", [_vm.recordDetail.readEmpInformation ? _c("div", {
    staticStyle: {
      display: "flex",
      "flex-wrap": "wrap"
    }
  }, _vm._l(_vm.recordDetail.readEmpInformation, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticStyle: {
        width: "100px",
        height: "40px",
        "margin-left": "8px",
        "margin-top": "12px"
      }
    }, [_c("a-avatar", {
      attrs: {
        src: item.avatar ? item.avatar : "avatar.png",
        size: 30
      }
    }), _c("span", {
      staticStyle: {
        color: "#767885",
        "font-size": "14px",
        "margin-left": "10px"
      }
    }, [_vm._v(_vm._s(item.empName))])], 1)]);
  }), 0) : _vm._e()])]), _c("a-divider", {
    staticStyle: {
      height: "300px"
    },
    attrs: {
      type: "vertical"
    }
  }), _c("div", {
    staticStyle: {
      width: "350px",
      color: "#767885",
      "font-size": "14px",
      height: "300px",
      overflow: "scroll"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_vm._v("未读人员"), _c("span", {
    staticClass: "primary"
  }, [_vm._v(_vm._s(_vm.recordDetail.noReadCount) + "人")])]), _vm.recordDetail.noReadCount === 0 || !_vm.recordDetail.noReadCount ? _c("div", [_c("a-empty", {
    staticStyle: {
      "margin-top": "40px"
    }
  })], 1) : _c("div", [_vm.recordDetail.noReadEmpInformation ? _c("div", {
    staticStyle: {
      display: "flex",
      "flex-wrap": "wrap"
    }
  }, _vm._l(_vm.recordDetail.noReadEmpInformation, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticStyle: {
        width: "100px",
        height: "40px",
        "margin-left": "8px",
        "margin-top": "12px"
      }
    }, [_c("a-avatar", {
      attrs: {
        src: item.avatar ? item.avatar : "avatar.png",
        size: 30
      }
    }), _c("span", {
      staticStyle: {
        color: "#767885",
        "font-size": "14px",
        "margin-left": "10px"
      }
    }, [_vm._v(_vm._s(item.empName))])], 1)]);
  }), 0) : _vm._e()])])], 1)]), _c("a-modal", {
    staticClass: "notice-modal",
    attrs: {
      title: "预览",
      "confirm-loading": _vm.confirmLoading,
      visible: _vm.detailVisible,
      width: "800px"
    },
    on: {
      ok: function ok($event) {
        _vm.detailVisible = false;
      },
      cancel: function cancel($event) {
        _vm.detailVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "flex-start-center"
  }, [_c("iframe", {
    staticClass: "checkbox",
    staticStyle: {
      width: "700px",
      height: "500px",
      overflow: "auto"
    },
    attrs: {
      src: _vm.eleContractDetail.path
    }
  })])]), _c("a-modal", {
    staticClass: "notice-modal",
    attrs: {
      title: "删除通知",
      visible: _vm.deleteVisible,
      width: "468px"
    },
    on: {
      ok: _vm.confirmDelete,
      cancel: function cancel($event) {
        _vm.deleteVisible = false;
      }
    }
  }, [_c("div", {
    staticClass: "deleteline"
  }, [_c("p", [_c("a-icon", {
    attrs: {
      type: "exclamation-circle"
    }
  }), _vm._v("确定删除这条通知？")], 1)])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };