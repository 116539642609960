import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import Navbar from "@/layout/components/navbar/empty";
import { mapGetters } from "vuex";
export default {
  components: {
    Navbar: Navbar
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["permissions_menu"])), {}, {
    permissionsMenu: function permissionsMenu() {
      return this.permissions_menu;
    }
  }),
  watch: {
    permissionsMenu: {
      handler: function handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.$router.push("/");
        }
      }
    },
    immediate: true
  },
  created: function created() {
    this.checkStatus();
  },
  methods: {
    checkStatus: function checkStatus() {
      var _this = this;
      this.$store.dispatch("auth/checkToken").catch(function () {
        _this.$store.dispatch("auth/refreshToken").catch(function () {
          _this.$store.dispatch("auth/resetToken");
        });
      });
    }
  }
};