import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.timers.js";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
import { getToken } from "xqjr-module-auth";
var mixin = new UploadMixin();
var columns = [{
  title: "行号",
  dataIndex: "id",
  key: "id"
}, {
  title: "姓名",
  dataIndex: "name",
  key: "name"
}, {
  title: "原因",
  dataIndex: "reason",
  key: "reason"
}];
export default {
  mixins: [mixin],
  data: function data() {
    return {
      columns: columns,
      uploadImg: require("@/assets/img/uploadImg.png"),
      params: {
        templateName: "companyImportTemplate.xlsx"
      },
      url: "/hr/api/v1/excel/template/download",
      excelName: "用工单位上传模板.xlsx"
    };
  },
  created: function created() {
    this.headers = {
      token: getToken(),
      "Content-Type": "multipart/form-data"
    };
    this.action = "/hr/api/v1/companies/import?force=true";
  },
  methods: {
    handleSuccess: function handleSuccess(res) {
      var _this = this;
      var that = this;
      if (res.success && res.data !== null) {
        var numAll = res.data.importFailList.length + res.data.importSucList.length;
        var numFail = res.data.importFailList.length;
        var numSuc = res.data.importSucList.length;
        if (numFail === 0) {
          this.$confirm({
            okText: "确定",
            title: "\u5171\u5BFC\u5165".concat(numAll, "\u6761\uFF0C").concat(numSuc, "\u6761\u6210\u529F\uFF0C").concat(numFail, "\u6761\u672A\u6210\u529F"),
            onOk: function onOk() {
              that.goback();
            },
            onCancel: function onCancel() {
              that.goback();
            }
          });
        } else {
          this.$confirm({
            okText: "确定",
            cancelText: "查看未成功名单",
            title: "\u5171\u5BFC\u5165".concat(numAll, "\u6761\uFF0C").concat(numSuc, "\u6761\u6210\u529F\uFF0C").concat(numFail, "\u6761\u672A\u6210\u529F"),
            onOk: function onOk() {
              that.goback();
            },
            onCancel: function onCancel() {
              that.Faildata = res.data.importFailList;
              that.dialogFailVisible = true;
            }
          });
        }
      } else if (res.data === null) {
        this.$confirm({
          okText: "确定",
          title: res.msg
        });
      } else {
        this.$notification["error"]({
          message: "error",
          description: res.msg || "上传失败"
        });
        setTimeout(function () {
          _this.reset();
        }, 2000);
      }
    },
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    }
  }
};