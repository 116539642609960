import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "footer",
    on: {
      click: function click($event) {
        return _vm.$router.push("/thirdService");
      }
    }
  }, [_c("img", {
    attrs: {
      src: "icon.png",
      alt: ""
    }
  }), _vm._v(" 拓展应用 "), _c("img", {
    attrs: {
      src: "chevrons-right.png",
      alt: ""
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };