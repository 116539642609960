import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
var columns = [{
  title: "",
  dataIndex: "window",
  scopedSlots: {
    customRender: "window"
  }
}, {
  title: "姓名",
  dataIndex: "name"
}, {
  title: "参保城市",
  dataIndex: "socCityName"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200
}, {
  title: "参保方案",
  dataIndex: "socProgName"
}, {
  title: "社保停缴时间",
  dataIndex: "socEndDate",
  scopedSlots: {
    customRender: "socEndDate"
  }
}, {
  title: "住房公积金停缴时间",
  dataIndex: "fundEndDate",
  scopedSlots: {
    customRender: "fundEndDate"
  }
}, {
  title: "指派公司",
  dataIndex: "assignCompanyName"
}, {
  title: "状态",
  dataIndex: "status",
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "操作",
  dataIndex: "action",
  scopedSlots: {
    customRender: "action"
  }
}];
import { mapState } from "vuex";
export default {
  name: "ShebaoDetail",
  components: {
    feedBack: function feedBack() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./common/feed-back2"));
      });
    }
  },
  data: function data() {
    return {
      loading: false,
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      columns: columns,
      dataSource: [],
      tabs: {},
      defaultKey: "all",
      feedVisible: false,
      feedOptions: {},
      params: {
        status: undefined,
        showItem: true,
        pageNo: 1,
        pageSize: 10,
        name: ""
      },
      selectedRowKeys: [],
      postData: {
        finshOrError: false,
        remake: "",
        socDispatchCompanyId: "",
        socDispatchEmpIdList: []
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["hr"])), {}, {
    hasSelected: function hasSelected() {
      return this.selectedRowKeys.length > 0;
    }
  }),
  created: function created() {
    this.getDetail();
    this.getNavs();
  },
  methods: {
    // 翻页
    handlePage: function handlePage(_ref) {
      var current = _ref.current;
      this.params.pageNo = current;
      this.pagination.current = current;
      this.getDetail();
    },
    start: function start() {
      this.loading = true;
      this.feedVisible = true;
      this.loading = false;
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 搜索姓名
    onSearchName: function onSearchName(name) {
      name ? this.params.name = name : delete this.params.name;
      this.selectedRowKeys = [];
      this.params.pageNo = 1;
      this.pagination.current = 1;
      this.getDetail();
    },
    goBack: function goBack() {
      this.$router.push({
        path: "/shebao/jiedan",
        query: _objectSpread({}, this.$route.query)
      });
    },
    getDetail: function getDetail() {
      var _this = this;
      console.log(this.$route.query, "this.$route.query");
      var id = this.$route.query.id;
      this.$request({
        url: "/hr/api/v1/socDispatchCompanies/pageEmpLess/".concat(id),
        params: _objectSpread(_objectSpread({}, this.params), this.$route.query)
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this.dataSource = data.entities;
        _this.pagination.total = data.entityCount;
      });
    },
    getNavs: function getNavs() {
      var _this2 = this;
      var id = this.$route.query.id;
      this.$request({
        url: "/hr/api/v1/socDispatchCompanies/details/".concat(id)
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this2.tabs = data;
      });
    },
    // 切换详情
    changeTab: function changeTab(i) {
      this.params.name = "";
      this.selectedRowKeys = [];
      this.defaultKey = i;
      this.params.status = "";
      this.params.pageNo = 1;
      this.pagination.current = 1;
      if (typeof i === "number") {
        this.params.status = i;
      }
      this.getDetail();
    },
    handleDetail: function handleDetail(_ref4) {
      var visible = _ref4.visible,
        success = _ref4.success,
        options = _ref4.options;
      this.feedVisible = visible;
      if (!success) {
        this.selectedRowKeys = [];
        this.getDetail();
      }
      if (success) {
        this.postData.finshOrError = [true, false][options.status - 1];
        this.postData.remake = options.remake;
        this.postData.socDispatchCompanyId = this.$route.query.id;
        this.postData.socDispatchEmpIdList = this.selectedRowKeys.length ? this.selectedRowKeys : [options.id];
        this.commitFeedBack();
      }
    },
    // 反馈消息
    handelEvent: function handelEvent(e) {
      this.feedVisible = true;
      this.feedOptions = e;
    },
    // 提交反馈
    commitFeedBack: function commitFeedBack() {
      var _this3 = this;
      this.$request({
        url: "/hr/api/v1/socDispatchCompanies/feedBack",
        method: "post",
        data: this.postData
      }).then(function (_ref5) {
        var success = _ref5.success;
        if (success) {
          _this3.getDetail();
          _this3.getNavs();
        }
      });
    }
  }
};