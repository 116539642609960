var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      width: 820,
      title: "加班规则"
    },
    on: {
      ok: _vm.okHander
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    staticClass: "lab_l form_l"
  }, [_c("a-form-item", {
    attrs: {
      label: "加班方式",
      "label-col": {
        span: 3
      },
      "wrapper-col": {
        span: 16
      }
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.obj.overtimeRule,
      callback: function callback($$v) {
        _vm.$set(_vm.obj, "overtimeRule", $$v);
      },
      expression: "obj.overtimeRule"
    }
  }, [_c("a-row", [_c("a-radio", {
    staticClass: "w110 gutter",
    attrs: {
      value: 1
    }
  }, [_vm._v("以加班申请为准")]), _c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("加班申请通过后,直接记录加班时长")])], 1), _c("a-row", [_c("a-radio", {
    staticClass: "w110 gutter",
    attrs: {
      value: 2
    }
  }, [_vm._v("以打卡时间为准")]), _c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("根据打卡时间启动加班时长")])], 1), _c("a-row", [_c("a-radio", {
    staticClass: "w110 gutter",
    attrs: {
      value: 3
    }
  }, [_vm._v(" 以加班申请可算打卡记录 ")]), _c("span", {
    staticClass: "sub_tit"
  }, [_vm._v("打卡后,根据加班申请核算加班时长")])], 1)], 1)], 1), _vm.obj.overtimeRule !== 1 ? [_c("div", {
    staticClass: "sub_tit mg_b20"
  }, [_vm._v("加班规则:")]), _c("a-row", [_c("a-checkbox", {
    staticClass: "mg_b20",
    model: {
      value: _vm.obj.workDayRuleDTO.isWorkDay,
      callback: function callback($$v) {
        _vm.$set(_vm.obj.workDayRuleDTO, "isWorkDay", $$v);
      },
      expression: "obj.workDayRuleDTO.isWorkDay"
    }
  }, [_vm._v(" 允许工作日加班 ")])], 1), _vm.obj.workDayRuleDTO.isWorkDay && _vm.obj.overtimeRule === 2 ? [_c("div", {
    staticClass: "mg_b20"
  }, [_c("span", {
    staticClass: "sub_tit mg_r10"
  }, [_vm._v("加班开始时间:")]), _vm._v(" 下班后 "), _c("a-input", {
    staticStyle: {
      width: "40px"
    },
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.obj.workDayRuleDTO.startMinutes,
      callback: function callback($$v) {
        _vm.$set(_vm.obj.workDayRuleDTO, "startMinutes", $$v);
      },
      expression: "obj.workDayRuleDTO.startMinutes"
    }
  }), _vm._v(" 分钟开始计算加班 ")], 1), _c("div", {
    staticClass: "mg_b20"
  }, [_c("span", {
    staticClass: "sub_tit mg_r10"
  }, [_vm._v("最短加班时长:")]), _c("a-input", {
    staticStyle: {
      width: "40px"
    },
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.obj.workDayRuleDTO.shortMinutes,
      callback: function callback($$v) {
        _vm.$set(_vm.obj.workDayRuleDTO, "shortMinutes", $$v);
      },
      expression: "obj.workDayRuleDTO.shortMinutes"
    }
  }), _vm._v(" 分钟 "), _c("span", {
    staticClass: "sub_tit mg_l20"
  }, [_vm._v("不足30分钟记录为未加班")])], 1), _c("div", {
    staticClass: "mg_b20"
  }, [_c("span", {
    staticClass: "sub_tit mg_r10"
  }, [_vm._v("最长加班时长:")]), _c("a-input", {
    staticStyle: {
      width: "40px"
    },
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.obj.workDayRuleDTO.longestMinutes,
      callback: function callback($$v) {
        _vm.$set(_vm.obj.workDayRuleDTO, "longestMinutes", $$v);
      },
      expression: "obj.workDayRuleDTO.longestMinutes"
    }
  }), _vm._v(" 分钟 "), _c("span", {
    staticClass: "sub_tit mg_l20"
  }, [_vm._v("超过则记录为 40分钟")])], 1)] : _vm._e(), _c("a-row", [_c("a-checkbox", {
    staticClass: "mg_b20",
    model: {
      value: _vm.obj.nonWorkDayRuleDTO.isNonWorkDay,
      callback: function callback($$v) {
        _vm.$set(_vm.obj.nonWorkDayRuleDTO, "isNonWorkDay", $$v);
      },
      expression: "obj.nonWorkDayRuleDTO.isNonWorkDay"
    }
  }, [_vm._v("允许非工作日加班")])], 1), _vm.obj.nonWorkDayRuleDTO.isNonWorkDay && _vm.obj.overtimeRule === 2 ? [_c("div", {
    staticClass: "mg_b20"
  }, [_c("span", {
    staticClass: "sub_tit mg_r10"
  }, [_vm._v("最短加班时长:")]), _c("a-input", {
    staticStyle: {
      width: "40px"
    },
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.obj.nonWorkDayRuleDTO.shortMinutes,
      callback: function callback($$v) {
        _vm.$set(_vm.obj.nonWorkDayRuleDTO, "shortMinutes", $$v);
      },
      expression: "obj.nonWorkDayRuleDTO.shortMinutes"
    }
  }), _vm._v(" 分钟 "), _c("span", {
    staticClass: "sub_tit mg_l20"
  }, [_vm._v("不足30分钟记录为未加班")])], 1), _c("div", {
    staticClass: "mg_b20"
  }, [_c("span", {
    staticClass: "sub_tit mg_r10"
  }, [_vm._v("最长加班时长:")]), _c("a-input", {
    staticStyle: {
      width: "40px"
    },
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.obj.nonWorkDayRuleDTO.longestMinutes,
      callback: function callback($$v) {
        _vm.$set(_vm.obj.nonWorkDayRuleDTO, "longestMinutes", $$v);
      },
      expression: "obj.nonWorkDayRuleDTO.longestMinutes"
    }
  }), _vm._v(" 分钟 "), _c("span", {
    staticClass: "sub_tit mg_l20"
  }, [_vm._v("超过则记录为 40分钟")])], 1)] : _vm._e()] : _vm._e(), _c("div", {
    staticStyle: {
      height: "50px"
    }
  })], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };