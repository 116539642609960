import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
export function filterTree(treeData, keyword) {
  var childrenName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "children";
  var keywordName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "name";
  if (!treeData) {
    return [];
  }
  if (!keyword) {
    return treeData;
  }
  var resList = [];
  for (var i = 0; i < treeData.length; i++) {
    var item = treeData[i];
    if (item[childrenName] && item[childrenName].length > 0) {
      var childrenNew = filterTree(item[childrenName], keyword, childrenName, keywordName);
      if (childrenNew.length > 0) {
        resList.push(_objectSpread(_objectSpread({}, item), {}, _defineProperty({}, "".concat(childrenName), childrenNew)));
      } else {
        if (item[keywordName].indexOf(keyword) > -1) {
          resList.push(_objectSpread(_objectSpread({}, item), {}, _defineProperty({}, "".concat(childrenName), [])));
        }
      }
    } else {
      if (item[keywordName].indexOf(keyword) > -1) {
        resList.push(_objectSpread(_objectSpread({}, item), {}, _defineProperty({}, "".concat(childrenName), [])));
      }
    }
  }
  return resList;
}
export function filterEmptyValue(obj) {
  var newObj = {};
  Object.keys(obj).map(function (key) {
    if (obj[key]) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}
export function getFileName(url) {
  var needExt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var http = url.split("?")[0];
  var file = http.split("/");
  var name = file[file.length - 1];
  if (needExt) {
    return name;
  }
  var endArr = name.split(".");
  endArr.pop();
  return endArr.join(".");
}
export function fileListDecode(list) {
  if (list && list.length > 0) {
    var urls = list.map(function (item) {
      var url = item.response ? item.status === "done" ? item.response.data[0] : false : item.url || false;
      return url;
    }).filter(function (li) {
      return !!li;
    });
    return urls.join(",");
  }
  return "";
}
export function fileListEncode(str) {
  if (str) {
    var urls = str.split(",");
    return urls.map(function (url, idx) {
      var url_s = url.split("/");
      return {
        uid: "-".concat(idx + 1),
        name: url_s[url_s.length - 1],
        status: 200,
        url: url
      };
    });
  }
  return [];
}
export function fillDelItemToList(item, listOld) {
  var idx = listOld.findIndex(function (li) {
    return li.value === item.value;
  });
  if (idx > -1) {
    return listOld;
  } else {
    return [item].concat(listOld);
  }
}
export function findAllParentIds(list) {
  if (!list) {
    return [];
  }
  var res = [];
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    if (item.companyList && item.companyList.length > 0) {
      res.push(item.id);
    }
    res = [].concat(res, findAllParentIds(item.companyList));
  }
  return res;
}
export function buildGroupTreeMap(list) {
  if (!list) {
    return {};
  }
  var res = {};
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    res[item.id] = {
      label: item.name,
      value: item.id
    };
    if (item.childList && item.childList.length > 0) {
      res = _objectSpread(_objectSpread({}, res), buildGroupTreeMap(item.childList));
    }
  }
  return res;
}
export function formatGroupTree(list) {
  if (!list) {
    return [];
  }
  var res = [];
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    var newItem = {
      title: item.name,
      value: item.id,
      key: item.id,
      disabled: !item.hasPermission
    };
    if (item.childList && item.childList.length > 0) {
      newItem["children"] = formatGroupTree(item.childList);
    }
    res.push(newItem);
  }
  return res;
}
export function formatCompanyTree(list, parent) {
  if (!list) {
    return [];
  }
  var res = [];
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    var key = "".concat(parent ? parent.id : "system", "_").concat(item.type, "_").concat(item.id);
    res.push({
      title: item.name,
      key: key,
      value: key,
      children: formatCompanyTree(item.companyList, item)
    });
  }
  return res;
}
export function formatCompanyIdNameDict(list) {
  if (!list) {
    return [];
  }
  var dict = {};
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    dict[item.id] = item.name;
    if (item.companyList && item.companyList.length > 0) {
      dict = _objectSpread(_objectSpread({}, dict), formatCompanyIdNameDict(item.companyList));
    }
  }
  return dict;
}
export function findAreaArrFromMap(id, map) {
  try {
    var idInt = parseInt(id);
    var arr = [idInt];
    var item = map[idInt];
    if (item.parentId !== 0) {
      var arrP = findAreaArrFromMap(item.parentId, map);
      arr = [].concat(arrP, arr);
    }
    return arr;
  } catch (err) {
    return [];
  }
}
export function getExtFromUrl(str) {
  var strLst = str.split(".");
  var ext = strLst[strLst.length - 1];
  var format = "";
  if (["jpg", "jpeg", "png", "bmp", "gif"].includes(ext)) {
    format = "img";
  } else if (["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(ext)) {
    format = "office";
  } else if (["pdf"].includes(ext)) {
    format = "pdf";
  } else if (["zip", "rar"].includes(ext)) {
    format = "zip";
  } else {
    format = "file";
  }
  return [format, ext];
}
var getDate = function getDate() {
  var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
  var showTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  month = month > 9 ? month : "0".concat(month);
  day = day > 9 ? day : "0".concat(day);
  if (!showTime) {
    return "".concat(year, "-").concat(month, "-").concat(day);
  }
  var hour = date.getHours();
  var minute = date.getMinutes();
  var second = date.getSeconds();
  hour = hour > 9 ? hour : "0".concat(hour);
  minute = minute > 9 ? minute : "0".concat(minute);
  second = second > 9 ? second : "0".concat(second);
  return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(minute, ":").concat(second);
};
function diffTime(time) {
  try {
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var halfAMonth = day * 15;
    var month = day * 30;
    var diffValue = new Date().getTime() - new Date(time).getTime();
    var monthC = diffValue / month;
    var weekC = diffValue / (7 * day);
    var dayC = diffValue / day;
    var hourC = diffValue / hour;
    var minC = diffValue / minute;
    var result = "";
    if (parseInt(monthC) >= 1) {
      result = parseInt(monthC) + "个月前";
    } else if (parseInt(dayC) > 1) {
      result = parseInt(dayC) + "天前";
    } else if (parseInt(dayC) === 1) {
      result = "昨天";
    } else if (parseInt(hourC) >= 1) {
      result = parseInt(hourC) + "小时前";
    } else if (parseInt(minC) >= 1) {
      result = parseInt(minC) + "分钟前";
    } else {
      result = "刚刚";
    }
    return result;
  } catch (err) {
    return "--";
  }
}
export { getDate, diffTime };