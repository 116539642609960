import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import UploadMixin from "xqjr-plugin-common/UploadOrExport/uploadMixin";
import axios from "axios";
var mixin = new UploadMixin();
import { overlaidDataReq } from "../api";
import { feeErrColumns } from "../funcs";
export default {
  mixins: [mixin],
  data: function data() {
    return {
      feeErrColumns: feeErrColumns,
      excelName: "服务费导入模板.xlsx",
      tempInfo: {},
      uploading: false,
      allCount: 0,
      errorCount: 0,
      successCount: 0,
      uploadImg: require("@/assets/img/uploadImg.png"),
      action: "",
      socFile: undefined,
      dialogFailVisible: false,
      errorLst: [],
      errorDataFull: null,
      selectedRowKeys: []
    };
  },
  computed: {
    url: function url() {
      return "/hr/api/v1/excel/template/download?templateName=saleServerCostRecordImportTemplate.xlsx";
    }
  },
  watch: {
    errorLst: {
      handler: function handler(newVal) {
        if (newVal && newVal.length > 0) {
          var filteredLst = newVal.filter(function (item) {
            return item.overlaid;
          });
          if (filteredLst.length > 0) {
            this.feeErrColumns = [].concat(_toConsumableArray(feeErrColumns), [{
              title: "操作",
              dataIndex: "action",
              width: 150,
              scopedSlots: {
                customRender: "action"
              }
            }]);
          } else {
            this.feeErrColumns = feeErrColumns;
          }
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.tempInfo = JSON.parse(localStorage.getItem("importDaiyuchuliItem"));
    this.action = "/hr/api/v1/saleServerCostRecords/import";
    this.errorLst = [];
  },
  methods: {
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    coverItems: function coverItems(ids) {
      var _this = this;
      var lst = this.errorLst.filter(function (item) {
        return ids.includes(item.id);
      });
      overlaidDataReq(lst).then(function (res) {
        _this.$message.success("覆盖成功");
        _this.errorLst = _this.errorLst.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            overlaid: ids.includes(item.id) ? false : item.overlaid
          });
        });
        _this.selectedRowKeys = [];
      }).catch(function (err) {
        console.log("overlaidDataReq", err);
      });
    },
    handleCancel: function handleCancel() {
      this.dialogFailVisible = false;
      this.goback();
    },
    handleSuccess: function handleSuccess(res) {
      this.uploading = false;
      if (res && res.code === 200) {
        this.allCount = res.data.allCount;
        this.errorCount = res.data.errorCount;
        this.successCount = res.data.successCount;
        this.errorLst = res.data.errorList;
        this.errorDataFull = res.data;
        if (this.errorLst == null || this.errorLst.length === 0) {
          this.dialogFailVisible = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    handleUploadError: function handleUploadError() {
      this.$message.warning("没有文件");
    },
    batchGet: function batchGet() {
      var _this2 = this;
      this.uploading = true;
      var url = "/hr/api/v1/saleServerCostRecords/exportErrorList";
      axios({
        url: url,
        data: this.errorDataFull,
        method: "post",
        responseType: "blob",
        headers: {
          token: this.$store.state.auth.token,
          "Content-Type": "application/json"
        }
      }).then(function (res) {
        _this2.uploading = false;
        if (res.data && res.data.type === "application/json") {
          var reader = new FileReader();
          reader.readAsText(res.data, "utf-8");
          reader.onload = function () {
            var resJson = JSON.parse(reader.result);
            _this2.$message.error(resJson.msg);
          };
        } else {
          var blob = new Blob([res.data], {
            type: "application/octet-stream"
          });
          var link = document.createElement("a");
          link.download = "服务费导入错误.xlsx";
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};