var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "history"
  }, [_c("a-row", {
    staticClass: "title mg_l36"
  }, [_c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v("返回")], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("归档历史")])], 1), _c("a-row", {
    staticClass: "middle mg_l36"
  }, [_c("a-month-picker")], 1), _c("a-table", {
    attrs: {
      dataSource: _vm.dataSource,
      rowKey: function rowKey(dataSource) {
        return dataSource.id;
      }
    }
  }, [_c("a-table-column", {
    attrs: {
      title: "月薪酬报表",
      dataIndex: ""
    }
  }), _c("a-table-column", {
    attrs: {
      title: "人工成本",
      dataIndex: ""
    }
  }), _c("a-table-column", {
    attrs: {
      title: "五险一金",
      dataIndex: ""
    }
  }), _c("a-table-column", {
    attrs: {
      title: "月薪酬报表",
      dataIndex: ""
    }
  }), _c("a-table-column", {
    attrs: {
      title: "操作"
    }
  }, [[_vm._v(" 查看详情 ")]], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };