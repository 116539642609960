import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import { pageWaitJobAdvanced, selectEmpInfoToFormalRelation, selectForLeave, listPasteFormalForLeave, selectEmpInfoOnJobRelation, selectEmpInfoLeaveRelation, selectEmpInfoTransferRelation, listPasteWaitJob, listPasteOnJob, listPasteFormal, listPasteWaitLeave, listPasteTransferRelation, listPasteReinstatementEmp, listPasteResigned, pageReinstatementEmp, pageResignedList } from "./employeesMoveApi";
import { listByCompanyIdGet } from "../info/api";
var columns = [{
  title: "姓名",
  dataIndex: "name",
  key: "name",
  scopedSlots: {
    customRender: "name"
  },
  width: 80
}, {
  title: "手机号",
  dataIndex: "phone",
  key: "phone",
  width: 120
}, {
  title: "身份证号",
  dataIndex: "idCard",
  key: "idCard",
  width: 200
}];
export default {
  components: {},
  data: function data() {
    return {
      pageSizeOptions: ["10", "20", "50", "100"],
      companyId: this.$store.state.hr.companyId,
      labelList: [],
      textState: "",
      columns: columns,
      dataList: [],
      seniorVisible: false,
      seniorList: [],
      selectedRowKeys: [],
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 16
      },
      form: {},
      pagination: {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      },
      // //////////////////
      chosenList: [],
      textContent: "",
      copyVisible: false,
      errorState: false,
      // ///////////////////////////
      checkAll: false,
      visible: false,
      staffFrom: {
        companyId: this.$store.state.hr.companyId,
        nameOrPhone: null,
        pageNo: 1,
        pageSize: 10
      },
      pageCount: 0,
      staffData: [],
      staffrightData: [],
      confirmLoading: false,
      spinning: false
    };
  },
  watch: {
    textContent: function textContent(e) {
      if (e) {
        this.errorState = false;
      }
    }
  },
  methods: {
    gettextState: function gettextState(e, state) {
      if (e === "批量未到岗" || e === "批量到岗") {
        return "只支持识别待入职管理列表中状态为“待入职”员工";
      } else if (e === "批量转正") {
        return "只支持识别待入职管理列表中状态为“待转正”员工";
      } else if (e === "批量离职") {
        return "只支持识别待转正管理列表中的员工";
      } else if (e === "批量调岗") {
        return "只支持识别在职管理列表中的员工";
      } else if (e === "批量撤销离职") {
        return "只支持识别待离职管理列表中状态为“待离职”、“审批通过”员工";
      } else if (e === "批量撤销调岗" || e === "批量确认调岗") {
        return "只支持识别待入职管理列表中状态为“审批通过”、“待调岗”的员工";
      } else if (e === "批量恢复在职") {
        return "仅可批量恢复未入职到其他用工单位的员工";
      } else if (e === "批量删除") {
        if (state === 1) {
          return "仅可删除不是正在参保的员工";
        } else {
          return "只支持识别已离职列表中不是正在参保的员工 ";
        }
      }
    },
    saveOk: function saveOk() {
      this.$emit("saveOk1");
    },
    //  `````````````````
    deleteSeniorItem: function deleteSeniorItem(e) {
      var _this = this;
      this.seniorList.forEach(function (item, index) {
        if (item.id === e.id) {
          _this.seniorList.splice(index, 1);
          _this.selectedRowKeys.forEach(function (item2, index2) {
            if (item2 === e.id) {
              _this.selectedRowKeys.splice(index2, 1);
            }
          });
        }
      });
    },
    onDateChange: function onDateChange(e, text) {
      if (text === "join") {
        this.form.joinDateStart = e.length !== 0 ? e[0].format("YYYY-MM-DD") : null;
        this.form.joinDateEnd = e.length !== 0 ? e[1].format("YYYY-MM-DD") : null;
      } else {
        this.form.leaveDateStart = e.length !== 0 ? e[0].format("YYYY-MM-DD") : null;
        this.form.leaveDateEnd = e.length !== 0 ? e[1].format("YYYY-MM-DD") : null;
      }
    },
    onSelectChange: function onSelectChange(selectedRowKeys, e) {
      var _this2 = this;
      this.selectedRowKeys = selectedRowKeys;
      this.dataList.forEach(function (item) {
        selectedRowKeys.forEach(function (item2) {
          if (item.id === item2) {
            _this2.seniorList.push(item);
            _this2.seniorList = _this2.unique(_this2.seniorList);
          }
        });
      });
      this.seniorList.forEach(function (item, index) {
        if (_this2.selectedRowKeys.indexOf(item.id) === -1) {
          item.isSelectedItem = 2;
        }
      });
      var arr = [];
      this.seniorList.forEach(function (item, index) {
        if (!item.isSelectedItem) {
          arr.push(item);
        }
      });
      this.seniorList = JSON.parse(JSON.stringify(arr));
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getSeniorList();
    },
    copyOk: function copyOk() {
      if (this.chosenList.length === 0) {
        this.$message.error("请您勾选人员后点击确定");
        return;
      }
      this.copyVisible = false;
      this.goNextStep(this.chosenList);
      // this.$refs.pop.showModal(this.chosenList);
    },
    seniorOk: function seniorOk() {
      if (this.seniorList.length === 0) {
        this.$message.error("请您勾选人员后点击确定");
        return;
      }
      this.seniorVisible = false;
      this.goNextStep(this.seniorList);
      // this.$refs.pop.showModal(this.seniorList);
    },
    clearForm: function clearForm() {
      this.form = {
        companyId: this.companyId,
        nameOrPhone: null,
        pageNo: 1,
        pageSize: 10
      };
    },
    seniorCancel: function seniorCancel() {
      this.seniorVisible = false;
      this.selectedRowKeys = [];
    },
    getSeniorListCheck: function getSeniorListCheck() {
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.checkStatue = 1;
      this.getSeniorList();
    },
    getListByCompanyId: function getListByCompanyId() {
      var _this3 = this;
      listByCompanyIdGet({
        companyId: this.companyId
      }).then(function (_ref) {
        var data = _ref.data;
        _this3.labelList = data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    showSenior: function showSenior() {
      var _this4 = this;
      this.visible = false;
      this.seniorVisible = true;
      this.pagination = {
        pageSize: 10,
        total: 0,
        size: "small",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        }
      };
      this.form = {
        companyId: this.companyId,
        nameOrPhone: null,
        status: this.textState === "批量未到岗" || this.textState === "批量到岗" ? "1" : this.textState === "批量撤销离职" ? "3" : this.textState === "批量恢复在职" || this.textState === "批量删除" ? null : "2",
        pageNo: 1,
        pageSize: 10
      };
      this.selectedRowKeys = [];
      this.getSeniorList();
      this.getListByCompanyId();
      if (this.staffrightData.length > 0) {
        this.seniorList = JSON.parse(JSON.stringify(this.staffrightData));
        this.staffrightData.forEach(function (item, index) {
          _this4.selectedRowKeys.push(item.id);
        });
      } else {
        this.selectedRowKeys = [];
        this.seniorList = [];
      }
    },
    getSeniorList: function getSeniorList() {
      var _this5 = this;
      var data = _objectSpread(_objectSpread({}, this.form), {}, {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      });
      var fun = "";
      if (this.textState === "批量未到岗" || this.textState === "批量到岗") {
        fun = pageWaitJobAdvanced(data);
      } else if (this.textState === "批量离职") {
        fun = selectForLeave(data);
      } else if (this.textState === "批量转正") {
        var data1 = _objectSpread(_objectSpread({}, data), {}, {
          approvalStatus: 5
        });
        fun = selectEmpInfoToFormalRelation(data1);
      } else if (this.textState === "批量调岗") {
        var _data = _objectSpread(_objectSpread({}, data), {}, {
          isApproPass: true
        });
        fun = selectEmpInfoOnJobRelation(_data);
      } else if (this.textState === "批量撤销离职") {
        var _data2 = _objectSpread(_objectSpread({}, data), {}, {
          isApproPass: true
        });
        fun = selectEmpInfoLeaveRelation(_data2);
      } else if (this.textState === "批量确认调岗" || this.textState === "批量撤销调岗") {
        var _data3 = _objectSpread(_objectSpread({}, data), {}, {
          isApproPass: true
        });
        fun = selectEmpInfoTransferRelation(_data3);
      } else if (this.textState === "批量恢复在职") {
        var _data4 = _objectSpread(_objectSpread({}, data), {}, {
          companyId: this.$store.state.hr.companyId
        });
        fun = pageReinstatementEmp(_data4);
      } else if (this.textState === "批量删除") {
        var _data5 = _objectSpread(_objectSpread({}, data), {}, {
          companyId: this.$store.state.hr.companyId
        });
        fun = pageResignedList(_data5);
      }
      fun.then(function (res) {
        if (res.data === null || res.data.entities === null) {
          _this5.total = 0;
          _this5.pagination.total = 0;
          _this5.dataList = [];
        } else {
          _this5.pagination.total = res.data && res.data.entityCount;
          _this5.dataList = res.data.entities;
        }
      }).catch(function (err) {
        console.log("err==>", err);
      });
    },
    // `````````高级粘贴```````````
    deleteCopyItem: function deleteCopyItem(e) {
      var _this6 = this;
      this.chosenList.forEach(function (item, index) {
        if (item.id === e.id) {
          _this6.chosenList.splice(index, 1);
        }
      });
    },
    showCopy: function showCopy() {
      this.visible = false;
      this.textContent = "";
      this.copyVisible = true;
      if (this.staffrightData.length > 0) {
        this.chosenList = JSON.parse(JSON.stringify(this.staffrightData));
      } else {
        this.chosenList = [];
      }
    },
    getEmpListByIdCards: function getEmpListByIdCards() {
      var _this7 = this;
      if (this.textContent !== "") {
        var arr = this.textContent.split(/[\W]/);
        var cardList = arr;
        var fun = "";
        if (this.textState === "批量未到岗" || this.textState === "批量到岗") {
          fun = listPasteWaitJob(cardList, this.companyId);
        } else if (this.textState === "批量离职") {
          fun = listPasteFormalForLeave(cardList, this.companyId);
        } else if (this.textState === "批量转正") {
          fun = listPasteFormal(cardList, this.companyId);
        } else if (this.textState === "批量调岗") {
          fun = listPasteOnJob(cardList, this.companyId);
        } else if (this.textState === "批量撤销离职") {
          fun = listPasteWaitLeave(cardList, this.companyId);
        } else if (this.textState === "批量确认调岗" || this.textState === "批量撤销调岗") {
          fun = listPasteTransferRelation(cardList, this.companyId);
        } else if (this.textState === "批量恢复在职") {
          fun = listPasteReinstatementEmp(cardList, this.companyId);
        } else if (this.textState === "批量删除") {
          fun = listPasteResigned(cardList, this.companyId);
        }
        fun.then(function (res) {
          var empInfoListVOList = res.data.empInfoListVOList || [];
          var obj = {};
          if (_this7.staffrightData.length > 0) {
            _this7.chosenList = [].concat(_toConsumableArray(_this7.staffrightData), _toConsumableArray(_this7.chosenList), _toConsumableArray(empInfoListVOList)).reduce(function (cur, next) {
              obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
              return cur;
            }, []);
            _this7.chosenList = _this7.unique(_this7.chosenList);
          } else {
            _this7.chosenList = [].concat(_toConsumableArray(_this7.chosenList), _toConsumableArray(empInfoListVOList)).reduce(function (cur, next) {
              obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
              return cur;
            }, []);
          }
          _this7.textContent = res.data.idCardList.join(",");
          if (res.data.idCardList.length > 0) {
            _this7.$nextTick(function () {
              _this7.errorState = true;
            });
          }
        });
      }
    },
    unique: function unique(arr) {
      var res = new Map();
      return arr.filter(function (arr) {
        return !res.has(arr.id) && res.set(arr.id, 1);
      });
    },
    reset: function reset() {
      this.textContent = "";
      this.errorState = false;
    },
    // ··········选择人员·······························
    // 全选当前页面
    onCheckAll: function onCheckAll() {
      var _this8 = this;
      this.checkAll = !this.checkAll;
      if (this.checkAll) {
        this.staffData.forEach(function (element) {
          if (!element.isTrue) {
            _this8.onRow(element);
          }
        });
      } else {
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            _this8.staffrightData.forEach(function (esy, index) {
              if (esy.id === element.id) {
                _this8.deleteItem(element, index);
              }
            });
          }
        });
      }
    },
    deleteItem: function deleteItem(item, index) {
      var _this9 = this;
      this.staffrightData.splice(index, 1);
      this.staffData.forEach(function (element) {
        if (element.id === item.id) {
          element.isTrue = false;
          _this9.checkAll = false;
        }
      });
    },
    onSearch: function onSearch() {
      this.staffFrom.pageNo = 1;
      this.getPrePersonnelFun();
    },
    onChangePagination: function onChangePagination(pageNumber) {
      this.staffFrom.pageNo = pageNumber;
      this.getPrePersonnelFun();
    },
    showSizeChange: function showSizeChange(current, size) {
      this.staffFrom.pageNo = 1;
      this.staffFrom.pageSize = size;
      this.getPrePersonnelFun();
    },
    onRow: function onRow(item) {
      var _this10 = this;
      item.isTrue = !item.isTrue;
      if (item.isTrue) {
        var num = 0;
        this.staffData.forEach(function (element) {
          if (element.isTrue) {
            num += 1;
          }
        });
        this.checkAll = this.staffData.length === num;
        var arr = this.staffrightData.filter(function (items) {
          return items.id === item.id;
        });
        if (arr.length === 0) {
          this.staffrightData.push(item);
        }
      } else {
        this.checkAll = false;
        this.staffrightData.forEach(function (element, index) {
          if (item.id === element.id) {
            _this10.staffrightData.splice(index, 1);
          }
        });
      }
    },
    getPrePersonnelFun: function getPrePersonnelFun() {
      var _this11 = this;
      var data = _objectSpread({}, this.staffFrom);
      var fun = "";
      if (this.textState === "批量未到岗" || this.textState === "批量到岗") {
        fun = pageWaitJobAdvanced(data);
      } else if (this.textState === "批量离职") {
        fun = selectForLeave(data);
      } else if (this.textState === "批量转正") {
        var data1 = _objectSpread(_objectSpread({}, data), {}, {
          approvalStatus: 5
        });
        fun = selectEmpInfoToFormalRelation(data1);
      } else if (this.textState === "批量调岗") {
        var _data6 = _objectSpread(_objectSpread({}, data), {}, {
          isApproPass: true
        });
        fun = selectEmpInfoOnJobRelation(_data6);
      } else if (this.textState === "批量撤销离职") {
        var _data7 = _objectSpread(_objectSpread({}, data), {}, {
          isApproPass: true
        });
        fun = selectEmpInfoLeaveRelation(_data7);
      } else if (this.textState === "批量确认调岗" || this.textState === "批量撤销调岗") {
        var _data8 = _objectSpread(_objectSpread({}, data), {}, {
          isApproPass: true
        });
        fun = selectEmpInfoTransferRelation(_data8);
      } else if (this.textState === "批量恢复在职") {
        var _data9 = _objectSpread(_objectSpread({}, data), {}, {
          companyId: this.$store.state.hr.companyId
        });
        fun = pageReinstatementEmp(_data9);
      } else if (this.textState === "批量删除") {
        var _data10 = _objectSpread(_objectSpread({}, data), {}, {
          companyId: this.$store.state.hr.companyId
        });
        fun = pageResignedList(_data10);
      }
      fun.then(function (res) {
        if (res.data) {
          if (res.data.entities) {
            res.data.entities.forEach(function (element, index) {
              res.data.entities[index].isTrue = false;
            });
          }
          _this11.staffrightData.forEach(function (element) {
            res.data.entities.forEach(function (esy) {
              if (element.id === esy.id) {
                esy.isTrue = true;
              }
            });
          });
        }
        _this11.staffData = res.data ? res.data.entities : [];
        _this11.pageCount = res.data ? res.data.entityCount : 0;
        var arr = _this11.staffData.filter(function (item) {
          return item.isTrue;
        });
        _this11.checkAll = !!(arr.length === _this11.staffData.length && arr.length !== 0);
      }).catch(function (err) {
        console.log("err==>", err);
      });
    },
    showModal: function showModal(e) {
      this.visible = true;
      this.textState = e;
      // status  待入职1 待转正2 批量调岗2 批量撤销离职3
      this.staffFrom = {
        companyId: this.companyId,
        nameOrPhone: null,
        status: e === "批量未到岗" || e === "批量到岗" ? "1" : e === "批量撤销离职" ? "3" : e === "批量恢复在职" || e === "批量删除" ? null : "2",
        pageNo: 1,
        pageSize: 10
      };
      this.staffrightData = [];
      this.getPrePersonnelFun();
    },
    handleOk: function handleOk(e) {
      if (this.staffrightData.length === 0) {
        this.$message.error("请您勾选人员后点击确定");
        return;
      }
      this.confirmLoading = true;
      this.visible = false;
      this.goNextStep(this.staffrightData);
      // this.$refs.pop.showModal();
      this.confirmLoading = false;
    },
    goNextStep: function goNextStep(dataList) {
      this.$emit("getNextStep", dataList, this.textState);
    },
    handleCancel: function handleCancel(e) {
      this.visible = false;
    }
  }
};