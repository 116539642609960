import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ql-editor-wrapper ql-editor-wrapperStyle"
  }, [_c("div", {
    staticClass: "editor-top",
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "space-between",
      "margin-bottom": "12px"
    }
  }, [_vm._m(0), _c("a-button", {
    staticStyle: {
      "margin-right": "270px"
    },
    attrs: {
      type: "primary",
      ghost: "",
      loading: _vm.previewBtn
    },
    on: {
      click: _vm.onPreview
    }
  }, [_vm._v("在线预览")])], 1), _c("div", {
    staticClass: "ql-editor-content ql-editor-content2",
    staticStyle: {
      "padding-left": "24px",
      "padding-right": "318px"
    }
  }, [_c("div", {
    staticClass: "editor-main",
    staticStyle: {
      padding: "0"
    },
    attrs: {
      id: "editor-main"
    }
  }, [_c("div", {
    staticStyle: {
      width: "936px",
      margin: "0 auto"
    }
  }, [_c("vue-wangeditor", {
    ref: "editor",
    attrs: {
      menus: _vm.menus,
      height: _vm.editorHeight
    },
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1)]), _c("div", {
    staticClass: "editor-config editor-config2",
    staticStyle: {
      border: "0",
      "z-index": "10",
      background: "#fff",
      padding: "0",
      width: "280px",
      overflow: "inherit"
    }
  }, [_c("div", {
    staticClass: "mb12",
    staticStyle: {
      "margin-top": "-44px"
    }
  }, [_c("a-input-search", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      allowClear: "",
      placeholder: "输入关键字"
    },
    on: {
      search: _vm.debouncedSearch
    },
    model: {
      value: _vm.varQuery,
      callback: function callback($$v) {
        _vm.varQuery = $$v;
      },
      expression: "varQuery"
    }
  })], 1), _c("div", {
    staticClass: "editor-config-block",
    staticStyle: {
      border: "0",
      overflow: "auto",
      height: "calc(100% - 12px)"
    }
  }, [_c("div", {
    staticClass: "editor-config-block-content",
    staticStyle: {
      "margin-top": "0"
    }
  }, _vm._l(_vm.variables, function (item, index) {
    return _c("div", {
      key: index
    }, [item.title === "员工个人信息" ? _c("div", [_c("div", {
      staticClass: "editor-config-block-content-title",
      staticStyle: {
        "margin-bottom": "8px"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c("a-collapse", {
      staticStyle: {
        background: "#fff"
      },
      attrs: {
        "default-active-key": "0",
        bordered: false
      },
      scopedSlots: _vm._u([{
        key: "expandIcon",
        fn: function fn(props) {
          return [_c("a-icon", {
            staticClass: "colorIcons",
            attrs: {
              type: "caret-right",
              rotate: props.isActive ? 90 : 0
            }
          })];
        }
      }], null, true)
    }, _vm._l(item.children, function (item2, index2) {
      return _c("a-collapse-panel", {
        key: index2,
        style: _vm.customStyle,
        attrs: {
          header: item2.name
        }
      }, [_c("div", {
        staticClass: "editor-config-block-content-tag-wrapper"
      }, _vm._l(item2.val, function (item3, index3) {
        return _c("div", {
          key: index3,
          staticClass: "editor-config-block-content-tag mb10",
          on: {
            click: function click($event) {
              return _vm.handleVariable(item3.name);
            }
          }
        }, [_vm._v(" " + _vm._s(item3.name) + " ")]);
      }), 0)]);
    }), 1)], 1) : _c("div", [_c("div", {
      staticClass: "editor-config-block-content-title",
      style: {
        marginTop: index === 0 ? 0 : "15px"
      }
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "editor-config-block-content-tag-wrapper"
    }, _vm._l(item.children, function (tag, idx) {
      return _c("div", {
        key: idx,
        staticClass: "editor-config-block-content-tag",
        on: {
          click: function click($event) {
            return _vm.handleVariable(tag.name);
          }
        }
      }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
    }), 0)])]);
  }), 0)])])]), _c("div", {
    staticClass: "ql-editor-footer footerBtnTemp",
    staticStyle: {
      width: "100%",
      "min-width": "1002px"
    }
  }, [_c("div", [_c("a-button", {
    on: {
      click: function click($event) {
        return _vm.$emit("upData", 0);
      }
    }
  }, [_vm._v(" 上一步")])], 1), _c("div", [_c("a-button", {
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      click: _vm.updateTemplate
    }
  }, [_vm._v("设置默认印章位置")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveTemplate
    }
  }, [_vm._v("保存")])], 1)]), _c("preview", {
    attrs: {
      visible: _vm.visible,
      templateId: _vm.templateId
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: function close($event) {
        _vm.visible = false;
      }
    }
  }), _c("a-modal", {
    attrs: {
      title: "提示",
      visible: _vm.tipsetVisible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.savafileSignDo,
      cancel: _vm.cancelSavefileSignDo
    }
  }, [_c("p", {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("确定要保存合同模板？")])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "labels",
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("p"), _vm._v(" 设置模板变量 "), _c("div", {
    staticClass: "tips",
    staticStyle: {
      "margin-bottom": "0",
      "margin-left": "12px"
    }
  }, [_vm._v("快速添加员工姓名、部门、岗位等信息，内容中会自动替换为真实信息")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };