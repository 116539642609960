var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [!_vm.isUp ? _c("div", {
    staticClass: "toggle2"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      width: "50px",
      "align-items": "center",
      background: "#fff",
      "box-shadow": "0px -5px 10px 0px rgba(0, 0, 0, 0.1)",
      "border-radius": "25px 25px 0px 0px",
      color: "#fff",
      height: "25px"
    },
    on: {
      click: function click($event) {
        return _vm.upClick(true);
      }
    }
  }, [_c("a-icon", {
    staticClass: "arrow_up",
    attrs: {
      type: "double-left"
    }
  })], 1)]) : _vm._e(), !_vm.isUp ? _c("div", {
    staticClass: "toggle"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      width: "100%",
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "col"
  }, [_c("img", {
    staticStyle: {
      width: "24px",
      height: "24px",
      "margin-left": "20px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/icons/1.png"),
      alt: ""
    }
  }), _c("span", {
    staticStyle: {
      "font-size": "14px",
      color: "#000",
      "font-weight": "700",
      "text-align": "center",
      "margin-right": "15px"
    }
  }, [_vm._v(_vm._s(_vm.title) + "新手引导")]), _vm._l(_vm.getCurrent(_vm.menuItem), function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        display: "flex",
        "flex-direction": "row"
      },
      on: {
        click: function click($event) {
          return _vm.stepHandl(item);
        }
      }
    }, [_c("div", {
      staticClass: "indexlayout"
    }, [_c("span", [_vm._v(_vm._s(_vm.getNumb(item)))])]), _c("span", {
      staticClass: "titleSty"
    }, [_vm._v(_vm._s(item.title))])]);
  })], 2), _c("a-icon", {
    staticStyle: {
      color: "#000",
      "margin-right": "20px"
    },
    attrs: {
      type: "close"
    },
    on: {
      click: _vm.closeHand
    }
  })], 1)]) : _vm._e(), _vm.isUp ? _c("div", {
    staticClass: "toggle3_top"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      width: "50px",
      "align-items": "center",
      background: "#fff",
      "box-shadow": "0px -5px 10px 0px rgba(0, 0, 0, 0.1)",
      "border-radius": "25px 25px 0px 0px",
      color: "#fff",
      height: "25px"
    },
    on: {
      click: function click($event) {
        return _vm.upClick(false);
      }
    }
  }, [_c("a-icon", {
    staticClass: "arrow_up",
    attrs: {
      type: "double-left"
    }
  })], 1)]) : _vm._e(), _vm.isUp ? _c("div", {
    staticClass: "toggle3_down"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      width: "100%",
      "margin-top": "15px"
    }
  }, [_c("div", {
    staticClass: "col"
  }, [_c("img", {
    staticStyle: {
      width: "24px",
      height: "24px",
      "margin-left": "20px",
      "margin-right": "10px"
    },
    attrs: {
      src: require("@/assets/icons/1.png"),
      alt: ""
    }
  }), _c("span", {
    staticStyle: {
      "font-size": "14px",
      color: "#000",
      "font-weight": "700",
      "text-align": "center",
      "margin-right": "15px"
    }
  }, [_vm._v(_vm._s(_vm.title) + "新手引导")])]), _c("a-icon", {
    staticStyle: {
      color: "#000",
      "margin-right": "20px"
    },
    attrs: {
      type: "close"
    },
    on: {
      click: _vm.closeHand
    }
  })], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      width: "100%",
      "margin-top": "15px",
      height: "100%"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column",
      width: "160px"
    }
  }, [_c("div", {
    class: _vm.mindex === 0 ? "indexlayout" : "indexlayout2",
    staticStyle: {
      "margin-bottom": "0px"
    },
    on: {
      click: function click($event) {
        return _vm.itemClick(0);
      }
    }
  }, [_c("span", [_vm._v("快速配置")])]), _c("div", {
    class: _vm.mindex === 1 ? "indexlayout" : "indexlayout2",
    staticStyle: {
      "margin-bottom": "0px"
    },
    on: {
      click: function click($event) {
        return _vm.itemClick(1);
      }
    }
  }, [_c("span", [_vm._v("定制花名册和员工异动")])]), _c("div", {
    class: _vm.mindex === 2 ? "indexlayout" : "indexlayout2",
    staticStyle: {
      "margin-bottom": "0px"
    },
    on: {
      click: function click($event) {
        return _vm.itemClick(2);
      }
    }
  }, [_c("span", [_vm._v("薪资管理")])]), _c("div", {
    class: _vm.mindex === 3 ? "indexlayout" : "indexlayout2",
    staticStyle: {
      "margin-bottom": "0px"
    },
    on: {
      click: function click($event) {
        return _vm.itemClick(3);
      }
    }
  }, [_c("span", [_vm._v("社保服务")])]), _c("div", {
    class: _vm.mindex === 4 ? "indexlayout" : "indexlayout2",
    staticStyle: {
      "margin-bottom": "0px"
    },
    on: {
      click: function click($event) {
        return _vm.itemClick(4);
      }
    }
  }, [_c("span", [_vm._v("考勤")])]), _c("div", {
    class: _vm.mindex === 5 ? "indexlayout" : "indexlayout2",
    staticStyle: {
      "margin-bottom": "0px"
    },
    on: {
      click: function click($event) {
        return _vm.itemClick(5);
      }
    }
  }, [_c("span", [_vm._v("招聘")])])]), _c("div", {
    staticStyle: {
      "margin-left": "70px",
      "border-right": "1px solid #e8e8e8",
      height: "250px"
    }
  })]), _c("div", {
    staticStyle: {
      width: "100%",
      "margin-right": "25px"
    }
  }, _vm._l(_vm.treeData[_vm.mindex], function (row, index) {
    return _c("a-row", {
      key: index,
      staticStyle: {
        "margin-bottom": "15px"
      },
      attrs: {
        type: "flex",
        justify: "space-around",
        align: "middle"
      }
    }, _vm._l(row, function (item, index) {
      return _c("a-col", {
        key: index,
        attrs: {
          span: 7
        }
      }, [item.index !== undefined ? _c("div", {
        staticClass: "itembackgound",
        on: {
          click: function click($event) {
            return _vm.stepHandl(item);
          }
        }
      }, [_c("span", {
        staticClass: "backfont"
      }, [_vm._v(_vm._s(item.num))]), _c("div", {
        staticClass: "backfontText"
      }, [_c("div", {
        staticStyle: {
          "font-size": "16px",
          "font-weight": "700",
          color: "#3c3d43"
        }
      }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c("div", {
        staticStyle: {
          "font-size": "14px",
          color: "#a6a8b4"
        }
      }, [_vm._v(" " + _vm._s(item.des) + " ")])])]) : _vm._e()]);
    }), 1);
  }), 1)])]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };