import request from "@/utils/request";
export function getAreas(level) {
  return request({
    url: "/tax/api/v1/areas/level/".concat(level),
    method: "GET"
  });
}
export function getCompaniesListAll(params) {
  return request({
    url: "/tax/api/v1/taxCompanies/listAll",
    method: "GET",
    params: params
  });
}
export function postTaxCompanies(data) {
  return request({
    url: "/tax/api/v1/taxCompanies",
    method: "POST",
    data: data
  });
}
export function getCompanyAuthorities(params) {
  return request({
    url: "/tax/api/v1/companyAuthorities",
    method: "GET",
    params: params
  });
}
export function putTaxCompanies(data) {
  return request({
    url: "/tax/api/v1/taxCompanies",
    method: "PUT",
    data: data
  });
}
export function findCompanyGroup(data) {
  return request({
    url: "/hr/api/v1/childrenCompany/listAll",
    method: "GET",
    params: data
  });
}