import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";

// 查询所有通知分组
export function listAllGroup(params) {
  return request({
    url: "/hr/api/v1/notice/listAllGroup",
    method: "GET",
    params: params
  });
}

/**
 * 用户全部通知(未读) /notice/api/v1/userAllNotices/listAllNoRead
 */
export function allNoRead(params) {
  return request({
    url: "/notice/api/v1/userAllNotices/listAllNoRead",
    method: "get",
    params: params
  });
}
/**
 * 户全部通知(未读) /notice/api/v1/userAllNotices/listAllRead
 */
export function allRead(params) {
  return request({
    url: "/notice/api/v1/userAllNotices/listAllRead",
    method: "get",
    params: params
  });
}

/**
 * 增通知分类 /notice/api/v1/noticeGroups
 */
export function postNotifyTypeGroup(data) {
  return request({
    url: "/notice/api/v1/noticeGroups",
    method: "POST",
    data: data
  });
}

/**
 * 获取通知分类 /notice/api/v1/noticeGroups
 */
export function getNotifyTypeGroup(params) {
  return request({
    url: "/notice/api/v1/noticeGroups",
    method: "get",
    params: params
  });
}
/**
 * 增通知类型 /notice/api/v1/noticeTypes
 */
export function postNotifyType(data) {
  return request({
    url: "/notice/api/v1/noticeTypes",
    method: "POST",
    data: data
  });
}
/**
 * 查询通知类型/notice/api/v1/noticeTypes/listAll
 */
export function getNotifyType(params) {
  return request({
    url: "/notice/api/v1/noticeTypes/listAll",
    method: "get",
    params: params
  });
}
/**
 * 查询用户通知设置(包含多级通知分类/通知类型) /notice/api/v1/noticeUserSettings/listAllBySite
 */
export function getUserSetNotifyList() {
  return request({
    url: "/notice/api/v1/noticeUserSettings/listAllBySite",
    method: "GET"
  });
}
/**
 * 修改用户通知设置(包含多级通知分类/通知类型) /notice/api/v1/noticeUserSettings
 */
export function putUserSetNotifyList(data) {
  return request({
    url: "/notice/api/v1/noticeUserSettings",
    method: "PUT",
    data: data
  });
}
/**
 * 分页查询用户接收的通知 /notice/api/v1/userNotices
 */
export function getUserNoticeList(params) {
  return request({
    url: "/notice/api/v1/userNotices",
    method: "GET",
    params: params
  });
}

// 修改阅读状态
export function hasReadUserNoticeList(id) {
  return request({
    url: "/notice/api/v1/userNotices/updateReadStatu/" + id,
    method: "PUT"
  });
}

// 获取站内信设置
export function msgUserBySite(params) {
  return request({
    url: "/notice/api/v1/msgUserSettings/listAllBySite",
    method: "get",
    params: params
  });
}

// 修改站内信设置
export function updateAllUserSetting(data, type, value) {
  return request({
    url: "/notice/api/v1/msgUserSettings/updateAllUserSetting/".concat(type, "/").concat(value),
    method: "put",
    data: data
  });
}

// 消息通知设置
export function updateNoticeUserSetting(id, type, value, data) {
  return request({
    url: "/notice/api/v1/noticeUserSettings/updateUserSetting/".concat(id, "/").concat(type, "/").concat(value),
    method: "put",
    data: data
  });
}

// 修改设置
export function updateUserSettingByGroup(id, type, value, data) {
  return request({
    url: "/notice/api/v1/msgUserSettings/updateUserSettingByGroup/".concat(id, "/").concat(type, "/").concat(value),
    method: "put",
    data: data
  });
}

//
export function updateNoticeSettingByGroup(id, type, value, data) {
  return request({
    url: "/notice/api/v1/noticeUserSettings/updateUserSettingByGroup/".concat(id, "/").concat(type, "/").concat(value),
    method: "put",
    data: data
  });
}

// 修改通知已阅读
export function updateMsgReadStatus(id, data) {
  return request({
    url: "/notice/api/v1/userMsgs/updateReadStatu/".concat(id),
    method: "put",
    data: data,
    params: data
  });
}

// 修改系统公告已阅读
export function postSysReadStatus(sysNoticeId) {
  return request({
    url: "/notice/api/v1/userReadSysNotices/readSysNotice/".concat(sysNoticeId),
    method: "put"
  });
}
export function peoplealLetter(data) {
  return request({
    url: "/notice/api/v1/peoplealLetter",
    method: "put",
    data: data
  });
}

// 将人员的全部未读系统公告全部置为已读
export function readAllSysNotice(params) {
  return request({
    url: "/notice/api/v1/userReadSysNotices/readAllSysNotice",
    method: "put",
    params: params
  });
}

// 将人员的全部未读通知全部置为已读
export function readAllNotice(params) {
  return request({
    url: "/notice/api/v1/userNotices/readAllNotice",
    method: "put",
    params: params
  });
}

/** ****详情*******/
// 根据用户接收的通知id查询用户接收的通知详情
export function getUserNoticeListDetail(id) {
  return request({
    url: "/notice/api/v1/userNotices/" + id,
    method: "GET"
  });
}

// 根据系统公告id查询系统公告详情
export function getSysNoticeDetail(sysNoticeId) {
  return request({
    url: "/notice/api/v1/sysNotices/".concat(sysNoticeId),
    method: "GET"
  });
}

// 修改系统中全部用户通知设置的值
export function updateAllNoticeUserSetting(data, type, value) {
  return request({
    url: "/notice/api/v1/noticeUserSettings/updateAllUserSetting/".concat(type, "/").concat(value),
    method: "put",
    data: data
  });
}

// 未读消息数量
export function loadNoReadNumber(params) {
  return request({
    url: "/notice/api/v1/userAllNotices/getNoReadNumber",
    method: "get",
    params: params
  });
}

// 消息
export function loadUserMessages(params) {
  return request({
    url: "/notice/api/v1/userMsgs",
    method: "get",
    params: params
  });
}
export function deleteUserMessages(data) {
  return request({
    url: "/notice/api/v1/userMsgs",
    method: "delete",
    data: data
  });
}

// 通知
export function loadUserNotices(params) {
  return request({
    url: "/notice/api/v1/userNotices",
    method: "get",
    params: params
  });
}
export function deleteUserNotices(data) {
  return request({
    url: "/notice/api/v1/userNotices",
    method: "delete",
    data: data
  });
}

// 系统公告
export function loadSysNotices(params) {
  return request({
    url: "/notice/api/v1/sysNotices/pageByUser",
    method: "get",
    params: params
  });
}

// 系统公告
export function loadAllSysNotices(params) {
  return request({
    url: "/notice/api/v1/sysNotices/listByUser",
    method: "get",
    params: params
  });
}
export function deleteSysNotices(data) {
  return request({
    url: "/notice/api/v1/sysNotices",
    method: "delete",
    data: data
  });
}

// @我
export function loadPersonalLetter(params) {
  return request({
    url: "/notice/api/v1/peoplealLetter",
    method: "get",
    params: params
  });
}
export function deletePersonalLetter(data) {
  return request({
    url: "/notice/api/v1/peoplealLetter",
    method: "delete",
    data: data
  });
}