import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import moment from "moment";
import store from "@/store";
function disabledDate(current) {
  return current && current > moment().endOf("day");
}
export function basicsPages(moduleCode, data, status) {
  var fields = {};
  var fieldKeys = [];
  var _loop = function _loop() {
    var element = data[i];
    if (element.isOpen) {
      fieldKeys.push(element.fieldCode);
      fields[element.fieldCode] = {
        model: element.fieldCode,
        type: "",
        wrapper: {
          label: element.fieldName,
          labelCol: {
            span: element.fieldCode === "contactAddressProvince1" || element.fieldCode === "domicileProvince1" || element.fieldCode === "habitualResidenceProvince1" ? 11 : 8
          },
          wrapperCol: {
            span: element.fieldCode === "contactAddressProvince1" || element.fieldCode === "domicileProvince1" || element.fieldCode === "habitualResidenceProvince1" ? 13 : 10
          }
        },
        customize: {
          style: "width: 100%",
          disabled: !!(moduleCode === "info" && (element.fieldCode === "gender" || element.fieldCode === "licenseType" || element.fieldCode === "birthday" || element.fieldCode === "nationality")),
          placeholder: element.fieldCode === "contactAddressDetail" || element.fieldCode === "habitualResidenceDetail" || element.fieldCode === "domicileDetail" ? "请输入详细地址" : element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" || element.fieldType === "5" || element.fieldType === 5 ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName),
          format: element.fieldType === "3" || element.fieldType === 3 ? "YYYY-MM-DD" : ""
        },
        decorator: {
          initialValue: status === "edit" ? undefined : element.initialValue || undefined,
          rules: [{
            required: element.isNecessary,
            message: element.fieldType === 2 || element.fieldType === 4 || element.fieldType === "2" || element.fieldType === "4" || element.fieldType === "5" || element.fieldType === 5 ? "\u8BF7\u8F93\u5165".concat(element.fieldName) : "\u8BF7\u9009\u62E9".concat(element.fieldName)
          }]
        }
      };
      if (["手机号", "联系电话", "证明人联系电话"].includes(element.fieldName)) {
        fields[element.fieldCode].decorator.rules.push({
          pattern: "^1[3456789]\\d{9}$",
          message: "手机号码格式错误"
        });
      }
      if (element.fieldName === "电子邮箱" || element.fieldName === "邮箱") {
        fields[element.fieldCode].decorator.rules.push({
          pattern: "\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*",
          message: "邮箱格式错误"
        });
      }
      if (element.fieldName === "证件号码") {
        fields[element.fieldCode].decorator.rules.push({
          pattern: "^((^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$)|(^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])((\\d{4})|\\d{3}[Xx]$)))$",
          message: "证件号码格式错误"
        });
      }
      if (element.fieldCode === "bankCard") {
        fields[element.fieldCode].decorator.rules.push({
          pattern: "^[0-9]*[1-9][0-9]*$",
          message: "银行账号只能输入数字"
        });
      }

      // if (element.fieldCode === "resignDate" || element.fieldCode === "employedDate") {
      //   fields[element.fieldCode].customize.disabledDate = disabledDate;
      // }
      // if (element.fieldName === "起缴时间") {
      //   fields[element.fieldCode].customize.format = "YYYY-MM";
      //   fields[element.fieldCode].extend = {
      //     mode: "month"
      //   };
      // }
      if (element.fieldType === 1 || element.fieldType === "1") {
        var options = [];
        if (element.dropDownEnumList) {
          element.dropDownEnumList.forEach(function (item, index) {
            for (var key in item) {
              if (item[key] === "true") {
                item[key] = "true";
              }
              if (item[key] === "false") {
                item[key] = "false";
              }
            }
          });
          options = element.dropDownEnumList;
        }
        if (element.dropDownArray) {
          element.dropDownArray.forEach(function (element) {
            options.push({
              value: element,
              label: element
            });
          });
        }
        fields[element.fieldCode].customize.options = options;
        fields[element.fieldCode].type = "select";
      }
      if (element.fieldType === 2 || element.fieldType === "2") {
        fields[element.fieldCode].type = "input";
      }
      if (element.fieldType === 3 || element.fieldType === "3") {
        fields[element.fieldCode].type = "date";
      }
      if (element.fieldType === 4 || element.fieldType === "4") {
        fields[element.fieldCode].type = "textarea";
        fields[element.fieldCode].customize.autoSize = {
          minRows: 5,
          maxRows: 10
        };
      }
      if (element.fieldType === 5 || element.fieldType === "5") {
        fields[element.fieldCode].type = "number";
        if (element.fieldCode === "personalInvestmentRatio") {
          fields[element.fieldCode].customize.min = 0;
          fields[element.fieldCode].customize.max = 100;
          fields[element.fieldCode].customize.precision = 4;
        } else if (element.fieldCode === "totalPersonalInvestment") {
          fields[element.fieldCode].customize.min = 0;
          fields[element.fieldCode].customize.precision = 2;
        }
      }
      if (element.fieldType === 6 || element.fieldType === "6") {
        fields[element.fieldCode].type = "area";
        fields[element.fieldCode].extend = {
          showWords: true,
          taxList: true
        };
        if (store.state.common.taxAreaSourceList.length === 0) {
          store.dispatch("common/getAreaCodeList");
        }
      }
      if (element.fieldType === 7 || element.fieldType === "7") {
        fields[element.fieldCode].type = "imgUpload";
      }
    }
  };
  for (var i = 0; i < data.length; i++) {
    _loop();
  }
  return {
    fields: fields,
    fieldKeys: fieldKeys
  };
}