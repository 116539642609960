import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
import { mapGetters } from "vuex";
export default {
  name: "Position",
  props: ["record", "value"],
  data: function data() {
    return {
      positionList: [],
      defaultValue: null,
      disabled: this.record.options.disabled
    };
  },
  computed: _objectSpread({}, mapGetters(["companyPositionList"])),
  created: function created() {
    var defaultValue = this.record.options.defaultValue;
    if (defaultValue && defaultValue.length > 0) {
      this.defaultValue = this.record.options.multiple ? defaultValue.split(",") : defaultValue;
    }
  },
  methods: {
    onChange: function onChange(val) {
      var val_temp = this.record.options.multiple ? val.join(",") : val;
      this.$emit("input", val_temp);
      this.$emit("change", val_temp);
    }
  }
};