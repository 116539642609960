var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "seal-editor-wrapper page-miH page-bgBack"
  }, [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.back
    }
  }, [_vm._v(" 企业认证 ")]), _c("div", {
    staticClass: "seal-editor-content"
  }, [_c("div", {
    staticClass: "seal-editor-content-block"
  }, [_c("a-form", {
    attrs: {
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "企业全称"
    }
  }, [_c("a-input", {
    attrs: {
      "default-value": _vm.currentCompanyName
    },
    model: {
      value: _vm.companyName,
      callback: function callback($$v) {
        _vm.companyName = $$v;
      },
      expression: "companyName"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "组织机构代码/社会统一信用代码"
    }
  }, [_c("div", {
    staticClass: "seal-editor-type-wrapper",
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c("div", {
    staticClass: "seal-editor-btnType",
    class: {
      active: _vm.regType === "NORMAL"
    },
    on: {
      click: function click($event) {
        return _vm.setType("NORMAL");
      }
    }
  }, [_vm._v("组织机构代码")]), _c("div", {
    staticClass: "seal-editor-btnType",
    class: {
      active: _vm.regType === "MERGE"
    },
    on: {
      click: function click($event) {
        return _vm.setType("MERGE");
      }
    }
  }, [_vm._v("社会统一信用代码")])]), _c("a-input", {
    model: {
      value: _vm.organCode,
      callback: function callback($$v) {
        _vm.organCode = $$v;
      },
      expression: "organCode"
    }
  }), _vm.regType === "NORMAL" ? _c("div", {
    staticClass: "seal-editor-content-block-tip"
  }, [_vm._v("请输入9位组织机构代码")]) : _c("div", {
    staticClass: "seal-editor-content-block-tip"
  }, [_vm._v("请输入社会统一信用代码")])], 1), _c("a-form-item", {
    attrs: {
      label: "法人姓名"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.legalName,
      callback: function callback($$v) {
        _vm.legalName = $$v;
      },
      expression: "legalName"
    }
  }), _c("div", {
    staticClass: "seal-editor-content-block-tip"
  }, [_vm._v("按照营业执照上填写的法人姓名")])], 1), _c("a-form-item", {
    attrs: {
      label: "法人身份证"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.agentIdNo,
      callback: function callback($$v) {
        _vm.agentIdNo = $$v;
      },
      expression: "agentIdNo"
    }
  })], 1)], 1)], 1)]), _c("div", {
    staticClass: "seal-editor-footer"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("提交")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };