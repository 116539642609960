import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import axios from "axios";
import qs from "qs";
import FormJson from "./FormJson";
// import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
import { allGroupAndFlowByCompanySite, getAllByCompany, pageApproving, pageApprovedPass, pageApprovedReject, pageApproveNotifierMe } from "./api";
export default {
  data: function data() {
    return {
      dataVal: [],
      loading: false,
      changeKey: 1,
      dataApproved: [],
      columns: [{
        title: "批次号",
        dataIndex: "orderTitle",
        key: "orderTitle"
      }, {
        title: "申请人",
        dataIndex: "createUserName",
        key: "createUserName"
      }, {
        title: "用工单位",
        dataIndex: "companyName",
        key: "companyName"
      }, {
        title: "发放人数",
        dataIndex: "totalCount",
        key: "totalCount"
      }, {
        title: "发放总金额",
        dataIndex: "money",
        key: "money"
      }, {
        title: "申请时间",
        dataIndex: "gmtCreate",
        key: "gmtCreate",
        scopedSlots: {
          customRender: "gmtCreate"
        }
      },
      // {
      //   title: "状态",
      //   dataIndex: "subjectStatus",
      //   key: "subjectStatus",
      //   scopedSlots: { customRender: "subjectStatus" }
      // },
      {
        title: "操作",
        dataIndex: "name",
        key: "name",
        scopedSlots: {
          customRender: "name"
        }
      }],
      pagination: {
        pageSize: 10,
        total: 0,
        size: "default",
        current: 1,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showQuickJumper: true,
        showSizeChanger: true,
        personalPeriod: null,
        editCompanyName: null,
        ticketRecord: null,
        receivableRecord: null,
        pageSizeOptions: ["10", "20", "50", "100"]
      },
      subjectStatusArr: [{
        name: "待申请",
        color: "#4686FF"
      }, {
        name: "审批中",
        color: "#4686FF"
      }, {
        name: "审批驳回",
        color: "#FF7085"
      }, {
        name: "待发放",
        color: "#32CCAA"
      }, {
        name: "已发放",
        color: "#32CCAA"
      }],
      spinning: false,
      tableData: [],
      total: 0,
      pageNo: 1,
      pageSize: 20,
      selectName: "",
      gmtCreateStart: "",
      gmtCreateEnd: ""
    };
  },
  watch: {
    "$route.query": function $routeQuery(newVal, oldVal) {
      this.changeKey = this.$route.query.changeKey ? Number(this.$route.query.changeKey) : 1;
      this.getApprovalList();
    }
  },
  created: function created() {
    this.changeKey = this.$route.query.changeKey ? Number(this.$route.query.changeKey) : 1;
    if (!this.$route.query.changeKey) {
      console.log(JSON.stringify(this.$getCondition("fxsh_condition")));
      if (JSON.stringify(this.$getCondition("fxsh_condition")) !== "{}") {
        // 不是带参数调转进来就使用缓存数据
        var dparams = JSON.parse(this.$getCondition("fxsh_condition"));
        if (dparams) {
          if (dparams.selectName) {
            this.selectName = dparams.selectName;
          }
          if (dparams.gmtCreateStart && dparams.gmtCreateEnd) {
            this.dataVal = [dparams.gmtCreateStart, dparams.gmtCreateEnd];
          }
          if (dparams.changeKey) {
            this.changeKey = dparams.changeKey;
          }
          if (dparams.pageNo) {
            this.pagination.current = dparams.pageNo;
          }
          if (dparams.pageSize) {
            this.pagination.pageSize = dparams.pageSize;
          }
        }
      }
    }
    this.getApprovalList();
  },
  methods: {
    onChange: function onChange(date, dateString) {
      console.log(date, dateString);
      this.dataVal = dateString;
      this.pagination.current = 1;
      this.getApprovalList(true);
    },
    // 分页器
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getApprovalList(true);
    },
    getApprovalList: function getApprovalList(updateCache) {
      var _this = this;
      var data = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        selectName: this.selectName,
        gmtCreateEnd: this.dataVal.length !== 0 ? this.dataVal[1] : null,
        // companyId: this.$store.state.hr.companyId,
        gmtCreateStart: this.dataVal.length !== 0 ? this.dataVal[0] : null
      };
      var fun = null;
      if (this.changeKey === 1) {
        fun = pageApproving(data);
      } else if (this.changeKey === 2) {
        fun = pageApprovedPass(data);
      } else if (this.changeKey === 3) {
        fun = pageApprovedReject(data);
      } else if (this.changeKey === 4) {
        fun = pageApproveNotifierMe(data);
      }
      this.loading = true;
      fun.then(function (res) {
        console.log(res);
        _this.loading = false;
        _this.dataApproved = res.data.entities;
        _this.pagination.total = res.data && res.data.entityCount;
        var p = JSON.parse(JSON.stringify(data));
        p.changeKey = _this.changeKey; // 仅用来缓存数据
        if (updateCache) {
          _this.$setCondition("fxsh_condition", JSON.stringify(p));
        }
      }).catch(function (err) {
        console.log(err);
        _this.loadding = false;
      });
    },
    changeSort: function changeSort(e) {
      this.selectName = "";
      this.dataVal = [];
      this.pagination.current = 1;
      this.getApprovalList();
    },
    enterDetail: function enterDetail(record) {
      this.$router.push({
        path: "/paySalary/examineDetail",
        query: {
          record: JSON.stringify(record)
        }
      });
    },
    onSearch: function onSearch() {
      this.pagination.current = 1;
      this.getApprovalList(true);
    },
    goExaminationApproval: function goExaminationApproval() {
      var _this2 = this;
      getAllByCompany("defaultTransferAccounts").then(function (res) {
        console.log("res", res);
        if (res.data && res.data.length !== 0) {
          _this2.$router.push({
            path: "/newApproval?defaultTransferAccounts&id=".concat(res.data[0].id)
          });
        } else {
          _this2.$router.push({
            path: "/newApproval?defaultTransferAccounts"
          });
          // this.$router.push({ path: "/approval/setting?defaultTransferAccounts" });
          //
        }
      }).catch(function (err) {
        console.log("err", err);
      });
    }
  }
};