import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-layout", [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goOut
    }
  }, [_vm._v(_vm._s(_vm.titleType === "1" ? "定薪" : "编辑") + " ")]), _c("a-layout", [_c("a-layout-sider", {
    staticClass: "border-r-line ta-center border",
    attrs: {
      width: "276"
    }
  }, [_c("div", {
    staticClass: "file-user",
    staticStyle: {
      "margin-top": "40px"
    }
  }, [_c("a-row", {
    staticClass: "file-user-head",
    staticStyle: {
      "padding-left": "30px",
      "padding-right": "30px"
    }
  }, [_c("a-col", {
    staticStyle: {
      height: "200px"
    }
  }, [_c("a-row", {
    staticClass: "pd_b10"
  }, [_c("strong", {
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.info && _vm.info.name ? _vm.info.name : "暂无姓名"))])]), _c("a-row", [_c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "0px"
    }
  }, [_vm._v(" 工时累计 : "), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(" " + _vm._s(_vm.info && _vm.info.workHour ? _vm.info.workHour : "0") + "工时")])]), _c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "35px"
    }
  }, [_vm._v(" 出勤天数 : "), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(" " + _vm._s(_vm.info && _vm.info.workDay ? _vm.info.workDay : "0") + "天")])]), _c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "70px"
    }
  }, [_vm._v(" 入职日期 : "), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(" " + _vm._s(_vm.info ? _vm.formatDate(Number(_vm.info.joinDate), "d") : ""))])]), _c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "105px"
    }
  }, [_vm._v(" 工作性质 : "), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(" " + _vm._s(_vm.info ? _vm.$store.state.enums.postTypeArr[_vm.info.postType - 1] : ""))])]), _c("p", {
    staticStyle: {
      color: "#adadad",
      "padding-bottom": "20px",
      position: "absolute",
      left: "30px",
      top: "140px"
    }
  }, [_vm._v(" 渠道来源 : "), _c("span", {
    staticStyle: {
      color: "#222426"
    }
  }, [_vm._v(" " + _vm._s(_vm.info ? _vm.info.channelName ? _vm.info.channelName : "---" : ""))])])])], 1), _c("a-divider")], 1)], 1)]), _c("a-layout-content", {
    staticClass: "ph24 mb20"
  }, [_c("div", {
    staticClass: "shu"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("定薪规则")])]), _c("a-radio-group", {
    staticStyle: {
      "margin-top": "16px"
    },
    on: {
      change: _vm.radioGroupChange
    },
    model: {
      value: _vm.size,
      callback: function callback($$v) {
        _vm.size = $$v;
      },
      expression: "size"
    }
  }, [_c("a-radio-button", {
    attrs: {
      value: "small"
    }
  }, [_vm._v(" 计时制 ")]), _c("a-radio-button", {
    attrs: {
      value: "default"
    }
  }, [_vm._v(" 固定薪资 ")])], 1), _vm.size === "default" ? _c("div", [_c("a-form-model", {
    ref: "ruleForm",
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      model: _vm.form
    }
  }, [_c("a-form-model-item", {
    ref: "template",
    attrs: {
      label: "定薪模板",
      prop: "template",
      rules: {
        required: true,
        message: "必选"
      }
    }
  }, [_c("div", [_vm._l(_vm.templateList, function (item, index) {
    return _c("a-button", {
      key: index,
      staticClass: "modelBotton",
      class: item.style,
      attrs: {
        value: "horizontal"
      },
      on: {
        click: function click($event) {
          return _vm.templateChange(item.id, index, item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), _c("a-button", {
    staticClass: "add-field-add",
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.goIncrease();
      }
    }
  }, [_c("a-icon", {
    staticClass: "add-field-icon",
    attrs: {
      type: "plus-circle"
    }
  }), _vm._v("新增模板 ")], 1)], 2)]), _c("a-form-model-item", {
    ref: "detailsList",
    attrs: {
      label: "定薪明细",
      prop: "detailsList",
      rules: {
        required: true,
        message: "必填"
      }
    }
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      "row-key": "itemName",
      "data-source": _vm.detailsList,
      pagination: false
    },
    scopedSlots: _vm._u([_vm._l(["trial", "formal"], function (col) {
      return {
        key: col,
        fn: function fn(text, record) {
          return [_c("div", {
            key: col
          }, [record.isSum !== "1" ? _c("a-input-number", {
            staticStyle: {
              margin: "-5px 0",
              width: "120px"
            },
            attrs: {
              value: text,
              min: 0,
              step: 1,
              max: 100000,
              precision: 2
            },
            on: {
              change: function change(value) {
                return _vm.handleInputChange(value, record.projectId, col);
              }
            }
          }, [_vm._v(_vm._s(text))]) : _c("span", [_vm._v(_vm._s(text.toFixed(2)))])], 1)];
        }
      };
    })], null, true)
  })], 1), _c("a-form-model-item", [_c("label", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("* ")]), _c("span", {
    staticStyle: {
      color: "rgba(0, 0, 0, 0.85)"
    }
  }, [_vm._v(" 定薪原因： ")])]), _c("span", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v(" 入职定薪")])]), _c("a-form-model-item", {
    ref: "remark",
    attrs: {
      prop: "remark",
      label: "备注",
      "label-col": {
        span: 1
      },
      "wrapper-col": {
        span: 13
      }
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "400px"
    },
    attrs: {
      "max-length": 50
    },
    model: {
      value: _vm.form.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  }), _c("a-row", {
    staticClass: "ta-right textarea-tip",
    staticStyle: {
      width: "405px"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.remark ? _vm.form.remark.length : 0) + "/50字 ")])], 1)], 1), _c("a-modal", {
    attrs: {
      title: "新增定薪模板"
    },
    on: {
      ok: _vm.handleAddOk,
      cancel: _vm.handleAddCancel
    },
    model: {
      value: _vm.addVisible,
      callback: function callback($$v) {
        _vm.addVisible = $$v;
      },
      expression: "addVisible"
    }
  }, [_c("a-form-model", {
    ref: "addForm",
    attrs: {
      model: _vm.addForm,
      rules: _vm.rules2,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "模板名称",
      prop: "name"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请填写"
    },
    model: {
      value: _vm.addForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "name", $$v);
      },
      expression: "addForm.name"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "定薪项",
      prop: "itemIds"
    }
  }, [_c("div", {
    staticClass: "fix-salary-item-wrapper"
  }, _vm._l(_vm.fixItems, function (item, index) {
    return _c("div", {
      key: index,
      class: _vm.computeItemClass(item),
      on: {
        click: function click($event) {
          return _vm.handleAddSelected(item);
        }
      }
    }, [_c("span", [_vm._v(" " + _vm._s(item.name) + " ")])]);
  }), 0)])], 1)], 1)], 1) : _vm._e(), _vm.size === "small" || _vm.size === "large" ? _c("div", [_c("div", {
    staticClass: "gj",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("工价规则")]), _c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "请选择",
      allowClear: "",
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption
    },
    on: {
      change: _vm.handleRulesChange
    },
    model: {
      value: _vm.salaryCalculateRuleId,
      callback: function callback($$v) {
        _vm.salaryCalculateRuleId = $$v;
      },
      expression: "salaryCalculateRuleId"
    }
  }, _vm._l(_vm.rulusListCopy, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "15px",
      "font-size": "16px",
      "font-weight": "400",
      color: "#000000"
    }
  }, [_vm._v("已添加规则")]), _c("div", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("a-table", {
    attrs: {
      rowKey: "id",
      columns: _vm.columns2,
      "data-source": _vm.dataList,
      pagination: _vm.paginationOpt
    }
  })], 1)])]) : _vm._e(), _c("div", {
    staticClass: "shu2"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("招工劳务费规则")])]), _c("div", {
    staticClass: "gj",
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("span", {
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("招工劳务费规则")]), _c("a-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      "show-search": "",
      "option-filter-prop": "children",
      "filter-option": _vm.filterOption,
      placeholder: "请选择",
      allowClear: ""
    },
    on: {
      change: _vm.redPackChange
    },
    model: {
      value: _vm.redPackId,
      callback: function callback($$v) {
        _vm.redPackId = $$v;
      },
      expression: "redPackId"
    }
  }, _vm._l(_vm.redPack, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "15px",
      "font-size": "16px",
      "font-weight": "400",
      color: "#000000"
    }
  }, [_vm._v("已添加规则")]), _c("div", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("a-table", {
    attrs: {
      rowKey: "id",
      columns: _vm.columns2,
      "data-source": _vm.dataList2,
      pagination: _vm.paginationOpt2
    }
  })], 1), _c("a-divider"), _c("a-row", [_c("a-col", {
    staticClass: "fr"
  }, [_c("a-button", {
    staticClass: "mg_r10",
    on: {
      click: _vm.goOut
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleEmpyleeSubmitType
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };