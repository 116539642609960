import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-drawer", {
    staticClass: "drawer",
    attrs: {
      title: _vm.title,
      placement: _vm.placement,
      width: "580px",
      visible: _vm.visible
    },
    on: {
      close: function close($event) {
        _vm.visible = false;
      }
    }
  }, [_c("div", [_vm._v(" 规则名称 "), _c("a-input", {
    staticStyle: {
      width: "220px",
      "margin-left": "15px"
    },
    attrs: {
      placeholder: "请输入"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticClass: "shu"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("工价计算")])])]), _c("a-radio-group", {
    staticStyle: {
      "margin-top": "20px"
    },
    on: {
      change: _vm.tabsChange
    },
    model: {
      value: _vm.ruleType,
      callback: function callback($$v) {
        _vm.ruleType = $$v;
      },
      expression: "ruleType"
    }
  }, [_c("a-radio-button", {
    staticStyle: {
      width: "104px",
      "text-align": "center"
    },
    attrs: {
      value: "1"
    }
  }, [_vm._v(" 计时制 ")]), _c("a-radio-button", {
    staticStyle: {
      width: "104px",
      "text-align": "center"
    },
    attrs: {
      value: "4"
    }
  }, [_vm._v(" 计天制 ")])], 1), _vm.ruleType === "4" ? _c("div", [_c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" 工价 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 0
    },
    model: {
      value: _vm.hourAmountDouble,
      callback: function callback($$v) {
        _vm.hourAmountDouble = $$v;
      },
      expression: "hourAmountDouble"
    }
  }), _vm._v(" 元/天 "), _c("a-dropdown", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuClick1
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "2"
  }, [_vm._v(" 满月/天变更工价 ")]), _c("a-menu-item", {
    key: "1"
  }, [_vm._v(" 固定日期变更工价 ")])], 1), _c("a-button", {
    attrs: {
      type: "primary",
      ghost: ""
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("新增变更条件")], 1)], 1)], 1), _vm._l(_vm.labourList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "15px"
      }
    }, [item.type === "2" ? _c("span", [_vm._v(" 入职满 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 0
      },
      model: {
        value: item.number,
        callback: function callback($$v) {
          _vm.$set(item, "number", $$v);
        },
        expression: "item.number"
      }
    }), _c("a-select", {
      staticStyle: {
        width: "108px",
        margin: "0 5px"
      },
      model: {
        value: item.units,
        callback: function callback($$v) {
          _vm.$set(item, "units", $$v);
        },
        expression: "item.units"
      }
    }, _vm._l(_vm.option2, function (item, index) {
      return _c("a-select-option", {
        key: index,
        attrs: {
          value: item.value
        }
      }, [_vm._v(" " + _vm._s(item.label) + " ")]);
    }), 1), _vm._v(" 工价变为 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.newHourAmount,
        callback: function callback($$v) {
          _vm.$set(item, "newHourAmount", $$v);
        },
        expression: "item.newHourAmount"
      }
    }), _vm._v(" 元/天 ")], 1) : _vm._e(), item.type === "1" ? _c("span", [_vm._v(" 自 "), _c("a-date-picker", {
      staticStyle: {
        margin: "0 5px"
      },
      attrs: {
        value: item.time ? _vm.moment(item.time, "YYYY-MM-DD") : ""
      },
      on: {
        change: function change(date, dateString) {
          return _vm.onDateChange(date, dateString, item);
        }
      }
    }), _vm._v(" 工价变为 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.newHourAmount,
        callback: function callback($$v) {
          _vm.$set(item, "newHourAmount", $$v);
        },
        expression: "item.newHourAmount"
      }
    }), _vm._v(" 元/天 ")], 1) : _vm._e(), _c("a-icon", {
      staticClass: "deleteIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.deletelabourList(item, index);
        }
      }
    })], 1);
  }), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticClass: "shu"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("额外规则")])])]), _c("a-checkbox", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      checked: _vm.checked
    },
    on: {
      change: _vm.onChange
    }
  }, [_vm._v(" 一次性红包 ")]), _vm._l(_vm.numList, function (item, index) {
    return _c("div", {
      key: "info1-" + index,
      staticStyle: {
        "margin-top": "10px"
      }
    }, [_vm._v(" 满 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 0
      },
      model: {
        value: item.number,
        callback: function callback($$v) {
          _vm.$set(item, "number", $$v);
        },
        expression: "item.number"
      }
    }), _c("a-select", {
      staticStyle: {
        width: "108px",
        margin: "0 5px"
      },
      model: {
        value: item.units,
        callback: function callback($$v) {
          _vm.$set(item, "units", $$v);
        },
        expression: "item.units"
      }
    }, _vm._l(_vm.option2, function (item, index) {
      return _c("a-select-option", {
        key: index,
        attrs: {
          value: item.value
        }
      }, [_vm._v(" " + _vm._s(item.label) + " ")]);
    }), 1), _vm._v(" 发放 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.redPacketAmount,
        callback: function callback($$v) {
          _vm.$set(item, "redPacketAmount", $$v);
        },
        expression: "item.redPacketAmount"
      }
    }), _vm._v(" 元红包 "), _c("a-icon", {
      staticClass: "deleteIcon",
      staticStyle: {
        "font-size": "18px",
        margin: "0 5px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.deleteNumList(item, index);
        }
      }
    }), _c("a-icon", {
      staticClass: "addIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "plus-circle"
      },
      on: {
        click: _vm.createNumList
      }
    })], 1);
  }), _c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      checked: _vm.checked1
    },
    on: {
      change: _vm.onChange1
    }
  }, [_vm._v(" 离职倒扣 ")])], 1), _vm.checked1 ? _c("div", {
    staticStyle: {
      margin: "15px 0"
    }
  }, [_c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuClick
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "1"
  }, [_vm._v(" 不满月/天倒扣工资 ")]), _c("a-menu-item", {
    key: "2"
  }, [_vm._v(" 不满月/天减少全价 ")])], 1), _c("a-button", {
    attrs: {
      type: "primary",
      ghost: ""
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("新增倒扣条件")], 1)], 1)], 1) : _vm._e(), _vm._l(_vm.leaveList, function (item, index) {
    return _c("div", {
      key: "info2-" + index,
      staticStyle: {
        "margin-top": "15px"
      }
    }, [_vm._v(" 不满 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 0
      },
      model: {
        value: item.number,
        callback: function callback($$v) {
          _vm.$set(item, "number", $$v);
        },
        expression: "item.number"
      }
    }), _c("a-select", {
      staticStyle: {
        width: "108px",
        margin: "0 5px"
      },
      model: {
        value: item.units,
        callback: function callback($$v) {
          _vm.$set(item, "units", $$v);
        },
        expression: "item.units"
      }
    }, _vm._l(_vm.option2, function (item, index) {
      return _c("a-select-option", {
        key: index,
        attrs: {
          value: item.value
        }
      }, [_vm._v(" " + _vm._s(item.label) + " ")]);
    }), 1), item.type === "1" ? _c("span", [_vm._v(" 倒扣 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.deductAmount,
        callback: function callback($$v) {
          _vm.$set(item, "deductAmount", $$v);
        },
        expression: "item.deductAmount"
      }
    }), _vm._v(" 元工资 ")], 1) : _vm._e(), item.type === "2" ? _c("span", [_vm._v(" 工价变为 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.newHourAmount,
        callback: function callback($$v) {
          _vm.$set(item, "newHourAmount", $$v);
        },
        expression: "item.newHourAmount"
      }
    }), _vm._v(" 元/天 ")], 1) : _vm._e(), _c("a-icon", {
      staticClass: "deleteIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.deleteleaveList(item, index);
        }
      }
    })], 1);
  })], 2) : _vm._e(), _vm.ruleType === "1" ? _c("div", [_c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" 工价 "), _c("a-input-number", {
    staticStyle: {
      width: "88px",
      margin: "0 5px"
    },
    attrs: {
      min: 0
    },
    model: {
      value: _vm.hourAmountDouble,
      callback: function callback($$v) {
        _vm.hourAmountDouble = $$v;
      },
      expression: "hourAmountDouble"
    }
  }), _vm._v(" 元/小时 "), _c("a-dropdown", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuClick1
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "2"
  }, [_vm._v(" 满月/天/工时变更工价 ")]), _c("a-menu-item", {
    key: "1"
  }, [_vm._v(" 固定日期变更工价 ")])], 1), _c("a-button", {
    attrs: {
      type: "primary",
      ghost: ""
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("新增变更条件")], 1)], 1)], 1), _vm._l(_vm.labourList, function (item, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "15px"
      }
    }, [item.type === "2" ? _c("span", [_vm._v(" 入职满 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 0
      },
      model: {
        value: item.number,
        callback: function callback($$v) {
          _vm.$set(item, "number", $$v);
        },
        expression: "item.number"
      }
    }), _c("a-select", {
      staticStyle: {
        width: "108px",
        margin: "0 5px"
      },
      model: {
        value: item.units,
        callback: function callback($$v) {
          _vm.$set(item, "units", $$v);
        },
        expression: "item.units"
      }
    }, _vm._l(_vm.option, function (item, index) {
      return _c("a-select-option", {
        key: index,
        attrs: {
          value: item.value
        }
      }, [_vm._v(" " + _vm._s(item.label) + " ")]);
    }), 1), _vm._v(" 工价变为 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.newHourAmount,
        callback: function callback($$v) {
          _vm.$set(item, "newHourAmount", $$v);
        },
        expression: "item.newHourAmount"
      }
    }), _vm._v(" 元/小时 ")], 1) : _vm._e(), item.type === "1" ? _c("span", [_vm._v(" 自 "), _c("a-date-picker", {
      staticStyle: {
        margin: "0 5px"
      },
      attrs: {
        value: item.time ? _vm.moment(item.time, "YYYY-MM-DD") : ""
      },
      on: {
        change: function change(date, dateString) {
          return _vm.onDateChange(date, dateString, item);
        }
      }
    }), _vm._v(" 工价变为 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.newHourAmount,
        callback: function callback($$v) {
          _vm.$set(item, "newHourAmount", $$v);
        },
        expression: "item.newHourAmount"
      }
    }), _vm._v(" 元/小时 ")], 1) : _vm._e(), _c("a-icon", {
      staticClass: "deleteIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.deletelabourList(item, index);
        }
      }
    })], 1);
  }), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticClass: "shu"
  }, [_c("div", {
    staticClass: "shuxian"
  }), _c("div", {
    staticStyle: {
      color: "#000000",
      "font-size": "16px",
      "margin-left": "10px"
    }
  }, [_vm._v("额外规则")])])]), _c("a-checkbox", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      checked: _vm.checked
    },
    on: {
      change: _vm.onChange
    }
  }, [_vm._v(" 一次性红包 ")]), _vm._l(_vm.numList, function (item, index) {
    return _c("div", {
      key: "info1-" + index,
      staticStyle: {
        "margin-top": "10px"
      }
    }, [_vm._v(" 满 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 0
      },
      model: {
        value: item.number,
        callback: function callback($$v) {
          _vm.$set(item, "number", $$v);
        },
        expression: "item.number"
      }
    }), _c("a-select", {
      staticStyle: {
        width: "108px",
        margin: "0 5px"
      },
      model: {
        value: item.units,
        callback: function callback($$v) {
          _vm.$set(item, "units", $$v);
        },
        expression: "item.units"
      }
    }, _vm._l(_vm.option, function (item, index) {
      return _c("a-select-option", {
        key: index,
        attrs: {
          value: item.value
        }
      }, [_vm._v(" " + _vm._s(item.label) + " ")]);
    }), 1), _vm._v(" 发放 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.redPacketAmount,
        callback: function callback($$v) {
          _vm.$set(item, "redPacketAmount", $$v);
        },
        expression: "item.redPacketAmount"
      }
    }), _vm._v(" 元红包 "), _c("a-icon", {
      staticClass: "deleteIcon",
      staticStyle: {
        "font-size": "18px",
        margin: "0 5px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.deleteNumList(item, index);
        }
      }
    }), _c("a-icon", {
      staticClass: "addIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "plus-circle"
      },
      on: {
        click: _vm.createNumList
      }
    })], 1);
  }), _c("div", [_c("a-checkbox", {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      checked: _vm.checked1
    },
    on: {
      change: _vm.onChange1
    }
  }, [_vm._v(" 离职倒扣 ")])], 1), _vm.checked1 ? _c("div", {
    staticStyle: {
      margin: "15px 0"
    }
  }, [_c("a-dropdown", [_c("a-menu", {
    attrs: {
      slot: "overlay"
    },
    on: {
      click: _vm.handleMenuClick
    },
    slot: "overlay"
  }, [_c("a-menu-item", {
    key: "1"
  }, [_vm._v(" 不满月/天/工时倒扣工资 ")]), _c("a-menu-item", {
    key: "2"
  }, [_vm._v(" 不满月/天/工时减少全价 ")])], 1), _c("a-button", {
    attrs: {
      type: "primary",
      ghost: ""
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("新增倒扣条件")], 1)], 1)], 1) : _vm._e(), _vm._l(_vm.leaveList, function (item, index) {
    return _c("div", {
      key: "info2-" + index,
      staticStyle: {
        "margin-top": "15px"
      }
    }, [_vm._v(" 不满 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 0
      },
      model: {
        value: item.number,
        callback: function callback($$v) {
          _vm.$set(item, "number", $$v);
        },
        expression: "item.number"
      }
    }), _c("a-select", {
      staticStyle: {
        width: "108px",
        margin: "0 5px"
      },
      model: {
        value: item.units,
        callback: function callback($$v) {
          _vm.$set(item, "units", $$v);
        },
        expression: "item.units"
      }
    }, _vm._l(_vm.option, function (item, index) {
      return _c("a-select-option", {
        key: index,
        attrs: {
          value: item.value
        }
      }, [_vm._v(" " + _vm._s(item.label) + " ")]);
    }), 1), item.type === "1" ? _c("span", [_vm._v(" 倒扣 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.deductAmount,
        callback: function callback($$v) {
          _vm.$set(item, "deductAmount", $$v);
        },
        expression: "item.deductAmount"
      }
    }), _vm._v(" 元工资 ")], 1) : _vm._e(), item.type === "2" ? _c("span", [_vm._v(" 工价变为 "), _c("a-input-number", {
      staticStyle: {
        width: "88px",
        margin: "0 5px"
      },
      attrs: {
        min: 1
      },
      model: {
        value: item.newHourAmount,
        callback: function callback($$v) {
          _vm.$set(item, "newHourAmount", $$v);
        },
        expression: "item.newHourAmount"
      }
    }), _vm._v(" 元/小时 ")], 1) : _vm._e(), _c("a-icon", {
      staticClass: "deleteIcon",
      staticStyle: {
        "font-size": "18px",
        "margin-left": "10px"
      },
      attrs: {
        type: "delete"
      },
      on: {
        click: function click($event) {
          return _vm.deleteleaveList(item, index);
        }
      }
    })], 1);
  })], 2) : _vm._e(), _c("div", {
    style: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      borderTop: "1px solid #e8e8e8",
      padding: "10px 16px",
      textAlign: "center",
      left: 0,
      background: "#fff",
      borderRadius: "0 0 4px 4px"
    }
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "20px"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onClose
    }
  }, [_vm._v(" 确定 ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };