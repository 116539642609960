import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";
export function getTransferAccountsBatches(params) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches",
    method: "GET",
    params: params
  });
}
export function paySalary(data) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/zfbStartTransferAccounts",
    method: "POST",
    data: data
  });
}
export function getAccountList(params) {
  return request({
    url: "/hr/api/v1/transferAccountInfos/selectVxAccountMoney",
    method: "GET",
    params: params
  });
}
export function generateZfbMoneyToBookUrl(transferAccountInfoId, money) {
  return request({
    url: "/hr/api/v1/transferAccountInfos/generateZfbMoneyToBookUrl/".concat(transferAccountInfoId, "/").concat(money),
    method: "GET"
  });
}
export function getAccountListZFB(params) {
  return request({
    url: "/hr/api/v1/transferAccountInfos/selectZfbSafetyAccountMoney",
    method: "GET",
    params: params
  });
}
export function getAccountListBank(params) {
  return request({
    url: "/hr/api/v1/transferAccountInfos/selectPingAnBankAccountMoney",
    method: "GET",
    params: params
  });
}
export function deleteAccount(transferAccountsBatchId) {
  return request({
    url: "/hr/api/v1/transferAccountsBatches/".concat(transferAccountsBatchId),
    method: "delete"
  });
}