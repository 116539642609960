export default {
  data: function data() {
    return {
      data: null,
      title: "",
      visible: false
    };
  },
  methods: {
    showModal: function showModal(data, text) {
      console.log(data);
      this.visible = true;
      this.title = text;
      this.data = data.data;
    },
    handleOk: function handleOk(e) {
      this.visible = false;
    },
    handleCancel: function handleCancel(e) {
      this.visible = false;
    }
  }
};