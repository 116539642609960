var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("header", {
    staticClass: "flex-center-between"
  }, [_vm._m(0), _c("a-button", {
    on: {
      click: _vm.changeSalary
    }
  }, [_vm._v("保存")])], 1), _c("a-layout-content", {
    staticClass: "a_layout_content"
  }, [_c("a-alert", {
    staticClass: "a_alert",
    attrs: {
      message: "对该页面的修改将在保存后立即生效。保存后请重新核对数据，进行必要的上传或归档",
      banner: ""
    }
  }), _c("div", {
    staticClass: "a_content"
  }, [_c("a-row", {
    staticClass: "row_item",
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("发薪日期")]), _c("a-select", {
    staticClass: "mr20",
    staticStyle: {
      width: "200px"
    },
    model: {
      value: _vm.salary.payMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.salary, "payMonth", $$v);
      },
      expression: "salary.payMonth"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("当月")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v("次月")])], 1), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    model: {
      value: _vm.salary.payDate,
      callback: function callback($$v) {
        _vm.$set(_vm.salary, "payDate", $$v);
      },
      expression: "salary.payDate"
    }
  }, _vm._l(_vm.day, function (item) {
    return _c("a-select-option", {
      key: item.ley,
      attrs: {
        value: item.key
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 1)], 1), _c("a-row", {
    staticClass: "row_item",
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("对应社保自然月")]), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    model: {
      value: _vm.salary.socMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.salary, "socMonth", $$v);
      },
      expression: "salary.socMonth"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("上月")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v("当月")]), _c("a-select-option", {
    attrs: {
      value: 3
    }
  }, [_vm._v("次月")])], 1)], 1), _c("a-row", {
    staticClass: "row_item",
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("社保数据来源")]), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    model: {
      value: _vm.salary.socDataSource,
      callback: function callback($$v) {
        _vm.$set(_vm.salary, "socDataSource", $$v);
      },
      expression: "salary.socDataSource"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("社保模块")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v("不计算社保")])], 1)], 1), _c("a-row", {
    staticClass: "row_item",
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("考勤数据来源")]), _c("a-select", {
    staticClass: "mr20",
    staticStyle: {
      width: "200px"
    },
    model: {
      value: _vm.salary.attendanceDataSource,
      callback: function callback($$v) {
        _vm.$set(_vm.salary, "attendanceDataSource", $$v);
      },
      expression: "salary.attendanceDataSource"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("考勤模块")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v("自主上传")])], 1), _vm.salary.attendanceDataSource === 1 ? _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      defaultValue: 1
    },
    model: {
      value: _vm.salary.attendanceModuleItem,
      callback: function callback($$v) {
        _vm.$set(_vm.salary, "attendanceModuleItem", $$v);
      },
      expression: "salary.attendanceModuleItem"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 按调薪前后工资加权值计算请假扣款 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 按调薪前后实际日薪计算请假扣款 ")])], 1) : _vm._e()], 1), _c("a-row", {
    staticClass: "row_item",
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("离职员工减员")]), _c("a-select", {
    staticStyle: {
      width: "200px"
    },
    model: {
      value: _vm.salary.leaveDownsizing,
      callback: function callback($$v) {
        _vm.$set(_vm.salary, "leaveDownsizing", $$v);
      },
      expression: "salary.leaveDownsizing"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("自动减员")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v("员工离职时选择减员月")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "l_title"
  }, [_vm._v(" 计薪周期: "), _c("span", {
    staticClass: "primary_color"
  }, [_vm._v(" 当月1日 至 当月31日")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };