import "core-js/modules/es.array.concat.js";
import request from "@/utils/request";
export function loadAllFieldsReq(cId, moduleCode, term) {
  var url = term ? "/hr/api/v1/companyNecessaryFields/listForPc2?companyId=".concat(cId, "&moduleCode=").concat(moduleCode, "&fieldName=").concat(term) : "/hr/api/v1/companyNecessaryFields/listForPc2?companyId=".concat(cId, "&moduleCode=").concat(moduleCode);
  return request({
    url: url,
    method: "get"
  });
}

// pc端查询公司员工必填字段信息v2
export function listForPc(params) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/listForPc",
    method: "GET",
    params: params
  });
}

// 修改公司员工必填字段信息
export function companyNecessaryFields(data) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields",
    method: "put",
    data: data
  });
}

// 修改模块是否必须添加
export function companyEmpNecessaries(data) {
  return request({
    url: "/hr/api/v1/companyEmpNecessaries",
    method: "put",
    data: data
  });
}
// 新增公司员工额外字段表
export function postAdditionalFieldsAddDTO(data) {
  return request({
    url: "/hr/api/v1/companyAdditionalFields/save2",
    method: "post",
    data: data
  });
}
// 查询公司动态表单模块
export function listCompanyModuleCodes(params) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/listCompanyModuleCodes",
    method: "GET",
    params: params
  });
}
// 查询公司动态表单员工填写
export function getEmpFieldValue(params) {
  return request({
    url: "/hr/api/v1/companyNecessaryFields/getEmpFieldValue",
    method: "GET",
    params: params
  });
}
// 查询自定义模板
export function customTemplates(params) {
  return request({
    url: "/hr/api/v1/customTemplates",
    method: "GET",
    params: params
  });
}
//  启模板
export function customTemplatesOpen(data) {
  return request({
    url: "/hr/api/v1/customTemplates/open2",
    method: "post",
    data: data
  });
}
//  删除模板
export function customTemplatesDel(customTemplateId) {
  return request({
    url: "/hr/api/v1/customTemplates/".concat(customTemplateId),
    method: "DELETE"
  });
}

//  下发模板
export function issueTemplate(data) {
  return request({
    url: "/hr/api/v1/customTemplates/issueTemplate2",
    method: "post",
    data: data
  });
}

// 查询系统默认字段
export function listDefaultField(params) {
  return request({
    url: "/hr/api/v1/customTemplateFields/listDefaultField",
    method: "get",
    params: params
  });
}
// 查询自定义模板字段
export function customTemplateFieldslistAll(params) {
  return request({
    url: "/hr/api/v1/customTemplateFields/selectAllField",
    method: "get",
    params: params
  });
}
// 新增字段
export function customTemplatesAdd(data) {
  return request({
    url: "/hr/api/v1/customTemplates/saveTemplate",
    method: "post",
    data: data
  });
}

// 修改字段
export function customTemplatesEdit(data) {
  return request({
    url: "/hr/api/v1/customTemplates/update2",
    method: "put",
    data: data
  });
}
// 新增自定义字段
export function saveForCustom(data) {
  return request({
    url: "/hr/api/v1/companyAdditionalFields/saveForCustom",
    method: "post",
    data: data
  });
}
// 修改自定义字段
export function updateForCustom(data) {
  return request({
    url: "/hr/api/v1/companyAdditionalFields/updateForCustom",
    method: "put",
    data: data
  });
}
// 删除自定义字段
export function deleteForCustom(companyAdditionalFieldsId) {
  return request({
    url: "/hr/api/v1/companyAdditionalFields/deleteForCustom/".concat(companyAdditionalFieldsId),
    method: "DELETE"
  });
}