var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "p24 main-sidebar-height",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("a-row", {
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    attrs: {
      span: 10
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("a-range-picker", {
    attrs: {
      "allow-clear": true,
      "default-value": _vm.params.beginTime ? [_vm.moment(_vm.params.beginTime).format("YYYY-MM-DD"), _vm.moment(_vm.params.endTime).format("YYYY-MM-DD")] : undefined
    },
    on: {
      change: _vm.timeChange
    }
  }), _c("a-select", {
    staticClass: "mr12",
    staticStyle: {
      width: "145px",
      "margin-left": "12px"
    },
    attrs: {
      title: "",
      "default-value": _vm.params.missionMode ? _vm.params.missionMode : undefined,
      placeholder: "请选择接单类型",
      "allow-clear": ""
    },
    on: {
      change: _vm.handlePayCostTypeName2
    }
  }, _vm._l(_vm.socPayCostTypeMap2, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(_vm._s(item.dictItemValue))]);
  }), 1)], 1)])], 1), _vm._m(0), _c("a-row", {
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", {
    staticStyle: {
      width: "23%"
    },
    on: {
      click: function click($event) {
        return _vm.paidan(undefined);
      }
    }
  }, [_c("div", {
    class: _vm.params.missionCenterState === null || _vm.params.missionCenterState === undefined ? "last-item-wrapper2" : "last-item-wrapper",
    staticStyle: {
      cursor: "pointer"
    }
  }, [_c("div", {
    staticStyle: {
      position: "absolute",
      top: "18px",
      width: "40px",
      height: "40px",
      right: "15px"
    }
  }, [_vm.params.missionCenterState === null || _vm.params.missionCenterState === undefined ? _c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: require("@/assets/icons/订单1.png")
    }
  }) : _c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: require("@/assets/icons/h.png")
    }
  })]), _c("div", {
    staticClass: "last-item-bottom-wrapper last-title2"
  }, [_vm._v("合计接单数")]), _c("div", {
    staticClass: "last-item-bottom-wrapper"
  }, [_c("div", {
    staticClass: "left-dot",
    class: _vm.params.missionCenterState === null || _vm.params.missionCenterState === undefined ? "dot-color1" : "dot-color5"
  }), _c("div", {
    staticClass: "people"
  }, [_vm._v(_vm._s(_vm.totalOptions.receiveCount))])])])]), _c("a-col", {
    staticStyle: {
      width: "23%"
    },
    on: {
      click: function click($event) {
        return _vm.paidan(0);
      }
    }
  }, [_c("div", {
    class: _vm.params.missionCenterState === 0 ? "last-item-wrapper2" : "last-item-wrapper",
    staticStyle: {
      cursor: "pointer"
    }
  }, [_c("div", {
    staticStyle: {
      position: "absolute",
      top: "18px",
      width: "40px",
      height: "40px",
      right: "15px"
    }
  }, [_vm.params.missionCenterState === 0 ? _c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: require("@/assets/icons/订单1.png")
    }
  }) : _c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: require("@/assets/icons/订单2.png")
    }
  })]), _c("div", {
    staticClass: "last-item-bottom-wrapper last-title2"
  }, [_vm._v("未完成接单数")]), _c("div", {
    staticClass: "last-item-bottom-wrapper"
  }, [_c("div", {
    staticClass: "left-dot",
    class: _vm.params.missionCenterState === 0 ? "dot-color1" : "dot-color3"
  }), _c("div", {
    staticClass: "people"
  }, [_vm._v(_vm._s(_vm.totalOptions.unfinishedDispatchCount))])])])]), _c("a-col", {
    staticStyle: {
      width: "23%"
    },
    on: {
      click: function click($event) {
        return _vm.paidan(1);
      }
    }
  }, [_c("div", {
    class: _vm.params.missionCenterState === 1 ? "last-item-wrapper2" : "last-item-wrapper",
    staticStyle: {
      cursor: "pointer"
    }
  }, [_c("div", {
    staticStyle: {
      position: "absolute",
      top: "18px",
      width: "40px",
      height: "40px",
      right: "15px"
    }
  }, [_vm.params.missionCenterState === 1 ? _c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: require("@/assets/icons/订单1.png")
    }
  }) : _c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: require("@/assets/icons/订单3.png")
    }
  })]), _c("div", {
    staticClass: "last-item-bottom-wrapper last-title2"
  }, [_vm._v("已完成接单数")]), _c("div", {
    staticClass: "last-item-bottom-wrapper"
  }, [_c("div", {
    staticClass: "left-dot",
    class: _vm.params.missionCenterState === 1 ? "dot-color1" : "dot-color2"
  }), _c("div", {
    staticClass: "people"
  }, [_vm._v(_vm._s(_vm.totalOptions.completedDispatchCount))])])])]), _c("a-col", {
    staticStyle: {
      width: "23%"
    },
    on: {
      click: function click($event) {
        return _vm.paidan(2);
      }
    }
  }, [_c("div", {
    class: _vm.params.missionCenterState === 2 ? "last-item-wrapper2" : "last-item-wrapper",
    staticStyle: {
      cursor: "pointer"
    }
  }, [_c("div", {
    staticStyle: {
      position: "absolute",
      top: "18px",
      width: "40px",
      height: "40px",
      right: "15px"
    }
  }, [_vm.params.missionCenterState === 2 ? _c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: require("@/assets/icons/订单1.png")
    }
  }) : _c("img", {
    staticStyle: {
      width: "40px",
      height: "40px"
    },
    attrs: {
      src: require("@/assets/icons/订单4.png")
    }
  })]), _c("div", {
    staticClass: "last-item-bottom-wrapper last-title2"
  }, [_vm._v("异常接单单次数")]), _c("div", {
    staticClass: "last-item-bottom-wrapper"
  }, [_c("div", {
    staticClass: "left-dot",
    class: _vm.params.missionCenterState === 2 ? "dot-color1" : "dot-color4"
  }), _c("div", {
    staticClass: "people"
  }, [_vm._v(_vm._s(_vm.totalOptions.abnormalDispatchCount))])])])])], 1), _vm._m(1), _c("div", {
    staticClass: "last-title-wrapper"
  }, [_c("span", {
    staticStyle: {
      color: "#767885"
    }
  }, [_vm._v("接单人数：")]), _c("span", {
    staticStyle: {
      "margin-right": "20px",
      "font-size": "14px",
      "font-weight": "700"
    }
  }, [_vm._v(_vm._s(_vm.totalOptions.receiveEmpCount ? _vm.totalOptions.receiveEmpCount : 0) + "人")]), _c("span", {
    staticStyle: {
      color: "#767885"
    }
  }, [_vm._v("已完成：")]), _c("span", {
    staticStyle: {
      "margin-right": "20px",
      "font-size": "14px",
      "font-weight": "700"
    }
  }, [_vm._v(_vm._s(_vm.totalOptions.completedCount ? _vm.totalOptions.completedCount : 0) + "人")]), _c("span", {
    staticStyle: {
      color: "#767885"
    }
  }, [_vm._v("未完成：")]), _c("span", {
    staticStyle: {
      "margin-right": "20px",
      "font-size": "14px",
      "font-weight": "700"
    }
  }, [_vm._v(_vm._s(_vm.totalOptions.unfinishedCount ? _vm.totalOptions.unfinishedCount : 0) + "人")]), _c("span", {
    staticStyle: {
      color: "#767885"
    }
  }, [_vm._v("异常：")]), _c("span", {
    staticStyle: {
      "margin-right": "20px",
      "font-size": "14px",
      "font-weight": "700"
    }
  }, [_vm._v(_vm._s(_vm.totalOptions.abnormalCount ? _vm.totalOptions.abnormalCount : 0) + "人")])]), !_vm.contentList.length ? [_c("a-empty", {
    staticStyle: {
      height: "100vh"
    }
  })] : _vm._l(_vm.contentList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "now-item-wrapper br4 border mt12 p16"
    }, [_c("ContentItem", {
      attrs: {
        options: item
      },
      on: {
        change: _vm.handleContent,
        received: _vm.onReceived
      }
    })], 1);
  }), _vm.pagination.total ? _c("div", {
    staticClass: "bottom-pagination"
  }, [_c("a-row", {
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("a-col", [_c("a-pagination", {
    attrs: {
      "show-total": function showTotal() {
        return "\u5171 ".concat(_vm.pagination.total, " \u6761");
      },
      total: _vm.pagination.total,
      "page-size-options": _vm.pageSizeOptions,
      "default-page-size": _vm.pagination.pageSize,
      "show-size-changer": "",
      "show-quick-jumper": ""
    },
    on: {
      change: _vm.onChangePage,
      showSizeChange: _vm.onShowSizeChange
    },
    scopedSlots: _vm._u([{
      key: "buildOptionText",
      fn: function fn(props) {
        return [_c("span", [_vm._v(_vm._s(props.value) + "条/页")])];
      }
    }], null, false, 198454938),
    model: {
      value: _vm.pagination.current,
      callback: function callback($$v) {
        _vm.$set(_vm.pagination, "current", $$v);
      },
      expression: "pagination.current"
    }
  })], 1)], 1)], 1) : _vm._e()], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "last-title-wrapper",
    staticStyle: {
      "padding-bottom": "12px"
    }
  }, [_c("span", {
    staticClass: "left-line"
  }), _c("span", {
    staticClass: "last-title"
  }, [_vm._v(" 合计情况")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "last-title-wrapper",
    staticStyle: {
      "padding-bottom": "12px",
      "padding-top": "15px"
    }
  }, [_c("span", {
    staticClass: "left-line"
  }), _c("span", {
    staticClass: "last-title"
  }, [_vm._v("接单详情")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };