import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "memo-wrap",
    staticStyle: {
      height: "100%"
    }
  }, [!_vm.isModule ? _c("div", {
    staticClass: "btmline pagetitle"
  }, [_c("a-icon", {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goBack
    }
  }), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")]), _c("div", [_vm._v(_vm._s(_vm.pageTitle))]), _c("a-icon", {
    staticStyle: {
      "font-size": "20px",
      color: "#fff"
    },
    attrs: {
      type: "left"
    }
  })], 1) : _vm._e(), _c("a-layout", {
    staticClass: "mt24",
    staticStyle: {
      height: "100%"
    }
  }, [_c("a-layout-sider", {
    staticStyle: {
      "border-right": "1px solid #e8e8e8"
    },
    attrs: {
      width: 220
    }
  }, [_c("div", {
    staticClass: "pSlide-title"
  }, [_vm._v("今日备忘")]), _c("a-menu", {
    staticClass: "groupleft"
  }, _vm._l(_vm.groupList, function (item) {
    return _c("a-menu-item", {
      key: item.id,
      staticStyle: {
        "border-bottom": "1px solid #e8e8e8",
        margin: "0 10px"
      },
      on: {
        click: function click($event) {
          return _vm.changeStatus(item.id);
        }
      }
    }, [_c("a-row", {
      attrs: {
        type: "flex",
        align: "middle"
      }
    }, [_c("a-col", {
      staticClass: "groupname"
    }, [_vm._v(_vm._s(item.name) + "(" + _vm._s(item.count) + ")")])], 1)], 1);
  }), 1)], 1), _c("a-layout", {
    staticStyle: {
      height: "65vh"
    }
  }, [_c("a-row", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.statusFlag === 0,
      expression: "statusFlag === 0"
    }],
    staticClass: "pl24",
    attrs: {
      type: "flex"
    }
  }, [_c("a-date-picker", {
    attrs: {
      "default-value": _vm.moment()
    },
    on: {
      change: _vm.changeDate
    }
  })], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.statusFlag === 0,
      expression: "statusFlag === 0"
    }],
    staticClass: "dash-title matter-title mt12",
    staticStyle: {
      display: "flex",
      "align-items": "flex-end",
      border: "1px solid reed",
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "bag-primary",
    staticStyle: {
      "margin-right": "12px"
    }
  }), _c("span", [_vm._v("待办事项(" + _vm._s(_vm.memoData.memoListToday ? _vm.memoData.memoListToday.length : 0) + ")")])]), _c("a-button", {
    attrs: {
      icon: "plus",
      type: "primary"
    },
    on: {
      click: _vm.addMemo
    }
  }, [_vm._v("添加备忘")])], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.statusFlag === 0,
      expression: "statusFlag === 0"
    }],
    staticClass: "memolist",
    staticStyle: {
      margin: "0 20px 20px 20px"
    }
  }, [_vm.memoData.memoListToday && _vm.memoData.memoListToday.length === 0 ? _c("p", {
    staticClass: "tip"
  }, [_vm._v("当前暂无事项")]) : _vm._l(_vm.memoData.memoListToday, function (memo, index) {
    return _c("div", {
      key: index,
      staticClass: "memo-item"
    }, [memo.completed ? _c("i", {
      staticClass: "iconfont icon-wancheng2",
      on: {
        click: function click($event) {
          return _vm.changeComplete(memo);
        }
      }
    }) : _c("i", {
      staticClass: "iconfont icon-dengdai",
      on: {
        click: function click($event) {
          return _vm.changeComplete(memo);
        }
      }
    }), _c("div", {
      staticClass: "memo-left"
    }, [_c("p", [_vm._l(memo.memoEmpDOList, function (people, idx) {
      return _c("span", {
        key: idx
      }, [_vm._v(" " + _vm._s(people.empName) + " "), memo.memoEmpDOList.length - idx > 1 ? _c("span", {
        staticClass: "pr10"
      }, [_vm._v(",")]) : _vm._e()]);
    }), _vm._v(" " + _vm._s(memo.content) + " ")], 2), _c("p", [_vm._v(" " + _vm._s(memo.remindDate ? _vm.formatDate(memo.remindDate, "d") : "") + " " + _vm._s(memo.remindTime.substring(0, 5)) + " ")])]), _c("div", {
      staticClass: "memo-right"
    }, [_c("a", {
      class: memo.completed ? "colorC0C0C0" : "link-color",
      attrs: {
        type: "link",
        disabled: memo.completed
      },
      on: {
        click: function click($event) {
          return _vm.changeComplete(memo);
        }
      }
    }, [_vm._v("完成")]), _c("a-divider", {
      attrs: {
        type: "vertical"
      }
    }), _c("a", {
      class: memo.completed ? "colorC0C0C0" : "link-color",
      attrs: {
        type: "link",
        disabled: memo.completed
      },
      on: {
        click: function click($event) {
          return _vm.editTheMemo(memo);
        }
      }
    }, [_vm._v("编辑")]), _c("a-divider", {
      attrs: {
        type: "vertical"
      }
    }), _c("a", {
      staticClass: "link-color",
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          return _vm.deleteTheMemo(memo);
        }
      }
    }, [_vm._v("删除")])], 1)]);
  })], 2), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.statusFlag === 1,
      expression: "statusFlag === 1"
    }],
    staticClass: "dash-title matter-title mt12",
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", {
    staticClass: "bag-primary",
    staticStyle: {
      "margin-right": "12px"
    }
  }), _c("span", [_vm._v("延期事项（" + _vm._s(_vm.memoData.memoListDelay ? _vm.memoData.memoListDelay.length : 0) + "）")])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.statusFlag === 1,
      expression: "statusFlag === 1"
    }],
    staticClass: "memolist",
    staticStyle: {
      margin: "0 20px 20px 20px"
    }
  }, [_vm.memoData.memoListDelay && _vm.memoData.memoListDelay.length === 0 ? _c("p", {
    staticClass: "tip"
  }, [_vm._v("当前暂无事项")]) : _vm._l(_vm.memoData.memoListDelay, function (memo, index) {
    return _c("div", {
      key: index,
      staticClass: "memo-item"
    }, [_c("img", {
      attrs: {
        src: _vm.delayed,
        alt: ""
      }
    }), _c("div", {
      staticClass: "memo-left"
    }, [_c("p", [_vm._l(memo.memoEmpDOList, function (people, idx) {
      return _c("span", {
        key: idx
      }, [_vm._v(" " + _vm._s(people.empName) + " "), memo.memoEmpDOList.length - idx > 1 ? _c("span", {
        staticClass: "pr10"
      }, [_vm._v(",")]) : _vm._e()]);
    }), _vm._v(" " + _vm._s(memo.content) + " ")], 2), _c("p", [_vm._v(" " + _vm._s(memo.remindDate ? _vm.formatDate(memo.remindDate, "d") : "") + " " + _vm._s(memo.remindTime.substring(0, 5)) + " ")])]), _c("div", {
      staticClass: "memo-right"
    }, [_c("a", {
      class: memo.completed ? "colorC0C0C0" : "link-color",
      attrs: {
        type: "link",
        disabled: memo.completed
      },
      on: {
        click: function click($event) {
          return _vm.changeComplete(memo);
        }
      }
    }, [_vm._v("完成")]), _c("a-divider", {
      attrs: {
        type: "vertical"
      }
    }), _c("a", {
      staticClass: "link-color",
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          return _vm.editTheMemo(memo);
        }
      }
    }, [_vm._v("编辑")]), _c("a-divider", {
      attrs: {
        type: "vertical"
      }
    }), _c("a", {
      staticClass: "link-color",
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          return _vm.deleteTheMemo(memo);
        }
      }
    }, [_vm._v("删除")])], 1)]);
  })], 2)], 1)], 1), _c("a-modal", {
    attrs: {
      title: _vm.modalTitle,
      visible: _vm.addMemoVisible,
      "confirm-loading": _vm.confirmLoading,
      "destroy-on-close": true
    },
    on: {
      ok: _vm.confirmAddMemo,
      cancel: function cancel($event) {
        _vm.addMemoVisible = false;
      }
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.memoForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: ""
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["content", {
        rules: [{
          required: true,
          message: "请输入备忘"
        }]
      }],
      expression: "[\n            'content',\n            {\n              rules: [{ required: true, message: '请输入备忘' }]\n            }\n          ]"
    }],
    attrs: {
      placeholder: "请输入备忘",
      "auto-size": {
        minRows: 5,
        maxRows: 10
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "用工单位",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["companyId", {
        rules: [{
          required: true,
          message: "请选择用工单位"
        }]
      }],
      expression: "[\n            'companyId',\n            {\n              rules: [{ required: true, message: '请选择用工单位' }]\n            }\n          ]"
    }],
    staticStyle: {
      width: "300px"
    },
    attrs: {
      placeholder: "请选择用工单位"
    }
  }, _vm._l(_vm.selectOptions, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "提醒模式",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["remindType", {
        initialValue: _vm.remindType,
        rules: [{
          required: true,
          message: "请选择提醒模式"
        }]
      }],
      expression: "[\n            'remindType',\n            {\n              initialValue: remindType,\n              rules: [{ required: true, message: '请选择提醒模式' }]\n            }\n          ]"
    }],
    on: {
      change: _vm.onRemindTypeChange
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("固定日期提醒")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("周期性提醒")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: _vm.remindType === 1 ? "固定日期提醒" : "周期性提醒",
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.remindType === 1,
      expression: "remindType === 1"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["remindDate", {
        initialValue: _vm.initRemindDate
      }],
      expression: "['remindDate', { initialValue: initRemindDate }]"
    }],
    attrs: {
      "disabled-date": _vm.disabledDate
    }
  }), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.remindType === 2,
      expression: "remindType === 2"
    }]
  }, [_vm._v(" 每月 "), _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["remindMonthUnitValue", {
        initialValue: 1,
        rules: [{
          required: true,
          message: "请选择提醒时间"
        }]
      }],
      expression: "[\n              'remindMonthUnitValue',\n              {\n                initialValue: 1,\n                rules: [{ required: true, message: '请选择提醒时间' }]\n              }\n            ]"
    }],
    staticStyle: {
      width: "80px"
    },
    attrs: {
      options: _vm.dayList,
      mode: "multiple"
    }
  }), _vm._v(" 号 ")], 1), _c("a-time-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["remindTime", {
        initialValue: _vm.initRemindTime
      }],
      expression: "['remindTime', { initialValue: initRemindTime }]"
    }],
    staticStyle: {
      "margin-left": "24px"
    },
    attrs: {
      format: "HH:mm"
    }
  })], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "确定"
    },
    on: {
      ok: _vm.handleDelete
    },
    model: {
      value: _vm.deleteVisible,
      callback: function callback($$v) {
        _vm.deleteVisible = $$v;
      },
      expression: "deleteVisible"
    }
  }, [_c("p", [_vm._v("确定删除该事项吗？")])]), _c("a-modal", {
    attrs: {
      title: "确定"
    },
    on: {
      ok: _vm.handleConfirm
    },
    model: {
      value: _vm.confirmVisible,
      callback: function callback($$v) {
        _vm.confirmVisible = $$v;
      },
      expression: "confirmVisible"
    }
  }, [_c("p", [_vm._v("确定已完成该事项吗？")])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };