var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "formFontStyle",
    class: _vm.editable ? "peopleBoxCon1" : "peopleBoxCon"
  }, [_c("a-form", {
    staticClass: "formStyleShow",
    attrs: {
      form: _vm.form,
      labelCol: {
        span: _vm.status === "deliveryRecord" ? 3 : _vm.status === "sendResume" ? 5 : 10
      },
      wrapperCol: {
        span: _vm.status === "sendResume" ? 10 : 12
      }
    }
  }, [_vm.isReward && _vm.formFields ? _c("a-row", [_vm.sortedKeys ? _vm._l(_vm.sortedKeys, function (key) {
    return _c("div", {
      key: key
    }, [key === "intentionJob" ? _c("a-col", {
      attrs: {
        span: _vm.status === "sendResume" || _vm.status === "deliveryRecord" ? 24 : 12
      }
    }, [_vm.editable ? _c("a-form-item", {
      attrs: {
        label: "意向岗位",
        "label-col": {
          span: _vm.status === "deliveryRecord" ? 3 : _vm.status === "sendResume" ? 5 : 10
        },
        "wrapper-col": {
          span: _vm.status === "sendResume" ? 10 : 12
        }
      }
    }, [_c("a-tree-select", {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["intentionJob", _vm.decoratorIntentionJob(_vm.formFields[key])],
        expression: "['intentionJob', decoratorIntentionJob(formFields[key])]"
      }],
      staticStyle: {
        width: "100%"
      },
      attrs: {
        showSearch: true,
        treeNodeFilterProp: "title",
        "tree-data": _vm.positionTypes,
        "tree-checkable": "",
        replaceFields: _vm.replaceFields,
        placeholder: "请选择意向岗位"
      },
      on: {
        change: _vm.onJobChange
      }
    })], 1) : _vm._e(), !_vm.editable ? _c("a-form-item", {
      staticClass: "widCompany",
      attrs: {
        label: "意向岗位",
        "label-col": {
          span: _vm.status === "deliveryRecord" ? 3 : _vm.status === "sendResume" ? 5 : 10
        },
        "wrapper-col": {
          span: _vm.status === "sendResume" ? 10 : 12
        }
      }
    }, [_vm._v(_vm._s(_vm.dataSource && _vm.dataSource.intentionJob ? _vm.dataSource.intentionJob : "--"))]) : _vm._e()], 1) : key === "skill" ? _c("a-col", {
      attrs: {
        span: _vm.status === "sendResume" || _vm.status === "deliveryRecord" ? 24 : 12
      }
    }, [_vm.editable ? _c("a-form-item", {
      attrs: {
        label: "擅长技能",
        "label-col": {
          span: _vm.status === "deliveryRecord" ? 3 : _vm.status === "sendResume" ? 5 : 10
        },
        "wrapper-col": {
          span: _vm.status === "sendResume" ? 10 : 12
        }
      }
    }, [_c("a-select", {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["skill", _vm.decoratorIntentionJob(_vm.formFields[key])],
        expression: "['skill', decoratorIntentionJob(formFields[key])]"
      }],
      staticStyle: {
        width: "100%"
      },
      attrs: {
        maxTagCount: 3,
        mode: "tags",
        placeholder: "请输入擅长技能"
      }
    })], 1) : _vm._e(), !_vm.editable ? _c("a-form-item", {
      staticClass: "widCompany",
      attrs: {
        label: "擅长技能",
        "label-col": {
          span: _vm.status === "deliveryRecord" ? 3 : _vm.status === "sendResume" ? 5 : 10
        },
        "wrapper-col": {
          span: _vm.status === "sendResume" ? 10 : 12
        }
      }
    }, [_vm._v(_vm._s(_vm.dataSource && _vm.dataSource.skill ? _vm.dataSource.skill : "--"))]) : _vm._e()], 1) : key === "workYears" ? _c("a-col", {
      attrs: {
        span: _vm.status === "sendResume" || _vm.status === "deliveryRecord" ? 24 : 12
      }
    }, [_vm.editable ? _c("a-form-item", {
      attrs: {
        label: "工作经验",
        "label-col": {
          span: _vm.status === "deliveryRecord" ? 3 : _vm.status === "sendResume" ? 5 : 10
        },
        "wrapper-col": {
          span: _vm.status === "sendResume" ? 14 : 14
        }
      }
    }, [_c("a-input-number", {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["workYears", _vm.decoratorIntentionJob(_vm.formFields[key])],
        expression: "['workYears', decoratorIntentionJob(formFields[key])]"
      }],
      staticStyle: {
        width: "45%"
      },
      attrs: {
        min: 0,
        placeholder: "请输入工作经验"
      },
      on: {
        change: _vm.changeWorkYears
      }
    }), key === "workYears" ? _c("div", {
      staticClass: "widWorkYears",
      style: {
        right: _vm.status === "sendResume" ? "47%" : "41%"
      }
    }, [_vm._v("年")]) : _vm._e(), _c("a-checkbox", {
      staticStyle: {
        "margin-left": "29px"
      },
      on: {
        change: _vm.onChange
      },
      model: {
        value: _vm.workYearsCheckbox,
        callback: function callback($$v) {
          _vm.workYearsCheckbox = $$v;
        },
        expression: "workYearsCheckbox"
      }
    }, [_c("span", {
      staticStyle: {
        "font-size": "12px"
      }
    }, [_vm._v("暂无工作经验")])])], 1) : _c("a-form-item", {
      staticClass: "widCompany",
      attrs: {
        label: "工作经验",
        "label-col": {
          span: _vm.status === "deliveryRecord" ? 3 : _vm.status === "sendResume" ? 5 : 10
        },
        "wrapper-col": {
          span: _vm.status === "sendResume" ? 10 : 12
        }
      }
    }, [_vm._v(_vm._s(_vm.dataSource && _vm.dataSource.workYears ? _vm.dataSource.workYears : "--") + "年")])], 1) : _c("a-col", {
      attrs: {
        span: _vm.status === "sendResume" || _vm.status === "deliveryRecord" ? 24 : 12
      }
    }, [_vm.detailedShow ? _c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: _vm.formFields[key],
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator(key)
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, _vm.formFields[key], _vm.dataSource, _vm.dynamicData);
        }
      }
    }) : _vm._e()], 1)], 1);
  }) : _vm._l(_vm.formFields, function (item) {
    return _c("div", {
      key: item.model
    }, [_c("a-col", {
      attrs: {
        span: 24
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: item,
        dynamicData: _vm.dynamicData,
        editable: _vm.editable,
        validator: _vm.getValidator(item.model)
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, item, _vm.dataSource, _vm.dynamicData);
        }
      }
    })], 1)], 1);
  })], 2) : _vm._e()], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editable && _vm.remove && _vm.status === "add",
      expression: "editable && remove && status === 'add'"
    }],
    staticClass: "DynamicForm"
  }, [_c("a-space", [_c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: _vm.onRemove
    }
  }, [_vm._v("删 除")])], 1)], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editable,
      expression: "editable"
    }],
    staticClass: "DynamicForm"
  }, [_c("a-space", [_vm.remove ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    },
    on: {
      click: _vm.onRemove
    }
  }, [_vm._v("删 除")]) : _vm._e(), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保 存")]), _c("a-button", {
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };