import employ from "./component/employee";
export default {
  name: "AddEmpInfo",
  components: {
    employ: employ
  },
  data: function data() {
    return {
      key: 1
    };
  },
  methods: {
    changeTab: function changeTab(e) {
      this.key = e;
    }
  }
};