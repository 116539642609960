export default {
  data: function data() {
    return {
      data: null,
      s: require("@/assets/taxCalculate/rs.png"),
      e: require("@/assets/taxCalculate/re.png")
    };
  },
  props: {
    dataShow: {
      type: Object,
      default: function _default() {}
    }
  },
  created: function created() {
    console.log(this.dataShow);
  }
};