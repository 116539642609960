var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column",
      width: "100%"
    }
  }, [_c("div", {
    staticClass: "mb16"
  }, [_c("div", {
    staticClass: "flex-center pl10",
    staticStyle: {
      "flex-flow": "row",
      height: "35px",
      width: "100%",
      "background-color": "#fffbe6",
      border: "1px solid #ffe58f",
      "border-radius": "5px"
    }
  }, [_c("img", {
    staticStyle: {
      width: "16px",
      height: "16px",
      "margin-right": "5px"
    },
    attrs: {
      src: require("@/assets/icons/重复.png")
    }
  }), _c("span", {
    staticStyle: {
      color: "#55565d",
      "font-size": "14px"
    }
  }, [_vm._v(" 本次参保 " + _vm._s(_vm.allCount) + " 人，" + _vm._s(_vm.successCount) + " 人成功，"), _c("span", {
    staticClass: "fw500"
  }, [_vm._v(_vm._s(_vm.errorCount))]), _vm._v(" 人失败，失败结果如下： ")])])]), _c("a-table", {
    attrs: {
      columns: _vm.columnsResultList,
      dataSource: _vm.errorLst,
      scroll: {
        x: true
      },
      rowKey: "empId",
      pagination: false
    },
    scopedSlots: _vm._u([{
      key: "dateRange",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(record.beginTime)) + "～" + _vm._s(_vm.formatDate(record.endTime)) + " ")];
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("a-space", [_c("span", {
          staticClass: "pointer tc-theme",
          class: {
            "tc-l3": record.confirmed
          },
          on: {
            click: function click($event) {
              record.confirmed ? function () {} : _vm.doConfirm(record);
            }
          }
        }, [_vm._v("确认新增")]), _c("span", {
          staticClass: "pointer tc-theme",
          class: {
            "tc-l3": record.confirmed
          },
          on: {
            click: function click($event) {
              record.confirmed ? function () {} : _vm.notConfirm(record);
            }
          }
        }, [_vm._v("放弃新增")])])];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };