import request from "@/utils/request";

// 查询拥有商保应用的租户
export function selectSiteWithInsurance(params) {
  return request({
    url: "/insurance/api/v1/companies/selectSiteWithInsurance",
    method: "get",
    params: params
  });
}
export function selectCompanyBySiteId(siteId) {
  return request({
    url: "/insurance/api/v1/companies/selectCompanyBySiteId/".concat(siteId),
    method: "get"
  });
}
export function updateCompanyBySiteId(data) {
  return request({
    url: "/insurance/api/v1/companies",
    method: "put",
    data: data
  });
}
export function addPlan(data) {
  return request({
    url: "/insurance/api/v1/companySchemes",
    method: "post",
    data: data
  });
}
export function editPlan(data) {
  return request({
    url: "/insurance/api/v1/companySchemes",
    method: "put",
    data: data
  });
}
export function getPlan(params) {
  return request({
    url: "/insurance/api/v1/companySchemes",
    method: "get",
    params: params
  });
}
export function deletePlan(companySchemeId) {
  return request({
    url: "/insurance/api/v1/companySchemes/".concat(companySchemeId),
    method: "delete"
  });
}