import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "file-edit page-miH page-bgBack",
    staticStyle: {
      padding: "0"
    }
  }, [_c("div", [_c("PageHeader", {
    attrs: {
      subTitle: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }, [_vm._v(" 薪资详情 ")])], 1), _c("div", {
    staticStyle: {
      padding: "24px 24px 0 24px"
    }
  }, [_c("div", {
    staticClass: "file-user"
  }, [_c("a-row", {
    staticClass: "file-user-head",
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("a-col", {
    staticClass: "file-user-left mg_r20"
  }, [!_vm.routerData.empPhoto ? _c("img", {
    staticClass: "file-user-img",
    attrs: {
      src: require("@/assets/img/moren.png"),
      alt: "加载失败"
    }
  }) : _vm._e(), _vm.routerData.empPhoto ? _c("img", {
    staticClass: "file-user-img",
    attrs: {
      src: _vm.routerData.empPhoto,
      alt: "加载失败"
    }
  }) : _vm._e()]), _c("a-col", [_c("a-row", {
    staticClass: "pd_b10"
  }, [_c("strong", [_vm._v(_vm._s(_vm.routerData.name))])]), _c("a-row", {
    attrs: {
      type: "flex",
      gutter: 24
    }
  }, [_c("a-col", [_vm._v("入职日期：" + _vm._s(_vm.formatDate(Number(_vm.routerData.joinDate), "d")))]), _c("a-col", [_vm._v("工作性质：" + _vm._s(_vm.$store.state.enums.postTypeArr[_vm.routerData.postType - 1]))]), _c("a-col", [_vm._v("员工状态：" + _vm._s(["试用", "正式"][_vm.routerData.formalType - 1]))])], 1)], 1)], 1)], 1)]), _vm._l(_vm.detailData, function (item, i) {
    return _c("div", {
      key: item.id + i,
      staticClass: "file-user-con"
    }, [_c("a-row", {
      attrs: {
        type: "flex",
        align: "middle",
        gutter: [16, 16]
      }
    }, [_c("a-col", [_c("span", {
      staticClass: "round"
    })]), _c("a-col", [_c("span", {
      staticClass: "primary",
      staticStyle: {
        cursor: "default"
      }
    }, [_vm._v(_vm._s(_vm.formatDate(Number(item.gmtCreate), "d")))]), _c("strong", [_vm._v(" " + _vm._s(item.isFixed ? "定薪" : "调薪"))]), item.isStart ? _c("img", {
      staticStyle: {
        width: "50px",
        "margin-left": "5px"
      },
      attrs: {
        src: _vm.effectImg
      }
    }) : _c("img", {
      staticStyle: {
        width: "50px",
        "margin-left": "5px"
      },
      attrs: {
        src: _vm.noEffectImg
      }
    })])], 1), _c("a-row", [_c("a-col", [_c("span", {
      staticClass: "dir"
    })]), _c("a-col", [_c("a-table", {
      attrs: {
        pagination: false,
        columns: item.columns,
        "data-source": item.dataSource,
        "row-key": function rowKey(record, index) {
          return index;
        },
        scroll: {
          x: 800
        }
      },
      scopedSlots: _vm._u([{
        key: "effectTime",
        fn: function fn(effectTime) {
          return [_vm._v(" " + _vm._s(_vm.formatDate(Number(effectTime), "d")) + " ")];
        }
      }, {
        key: "remark",
        fn: function fn(remark) {
          return [_c("a-tooltip", {
            attrs: {
              trigger: "hover",
              placement: "bottom"
            }
          }, [_c("template", {
            slot: "title"
          }, [_vm._v(" " + _vm._s(remark) + " ")]), _c("span", {
            staticClass: "look"
          }, [_vm._v("查看")])], 2)];
        }
      }, {
        key: "trainingSalarySum",
        fn: function fn(text) {
          return [_c("span", {
            staticClass: "look"
          }, [_vm._v(_vm._s(text))])];
        }
      }, {
        key: "officialSalarySum",
        fn: function fn(text) {
          return [_c("span", {
            staticClass: "look"
          }, [_vm._v(_vm._s(text))])];
        }
      }], null, true)
    })], 1)], 1)], 1);
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };