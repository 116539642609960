import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _typeof from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.splice.js";
import { getEmployeeFile, addPersonalEvent, uploadGeneralFile, updatePersonalEvent, deletePersonalEvent } from "@/api/staff/employeeFile";
import moment from "moment";
export default {
  props: {
    empIdOne: {
      type: String,
      default: ""
    },
    edit: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      spinning: false,
      moment: moment,
      companyId: "",
      fileData: [],
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 14
      },
      rules: {
        eventName: [{
          required: true,
          message: "必填",
          trigger: "blur"
        }],
        eventTime: [{
          required: true,
          message: "必填",
          trigger: "change"
        }]
      },
      form: {
        eventName: "",
        eventTime: "",
        description: "",
        attachUrl: ""
      },
      eventVisible: false,
      deleteVisible: false,
      isAdd: true,
      fileList: [],
      tempList: [],
      tempCount: 0,
      deleteId: ""
    };
  },
  watch: {
    empIdOne: {
      handler: function handler(val, oldval) {
        // 现在的刷新
        if (val) {
          this.empId = val;
          this.init();
        }
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
  },
  methods: {
    // 外部刷新该组件
    refresh: function refresh(empId) {
      // this.init();
    },
    init: function init() {
      var _this = this;
      var data = {
        empId: this.empId
      };
      this.spinning = true;
      getEmployeeFile(data).then(function (res) {
        _this.spinning = false;
        _this.fileData = res.data;
        _this.fileData.forEach(function (item, index) {
          item.eventByYear.forEach(function (item2, index2) {
            item2.eventByDay.forEach(function (item3, index3) {
              if (item3.attachUrl != null) {
                if (_this.isJSON(item3.attachUrl)) {
                  item3.attachUrl = item3.attachUrl ? JSON.parse(item3.attachUrl) : [];
                } else {
                  // 将自动添加的文件，转换格式
                  var urlArr = item3.attachUrl.split("/");
                  var i = {};
                  i.name = urlArr[urlArr.length - 1];
                  i.url = item3.attachUrl;
                  item3.attachUrl = [];
                  item3.attachUrl.push(i);
                }
              }
            });
          });
          item.eventByYear.push({
            eventTime: "",
            eventByDay: []
          });
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleAdd: function handleAdd() {
      this.fileList = [];
      this.tempList = [];
      this.tempCount = this.tempList.length;
      this.form = {
        eventName: "",
        eventTime: "",
        description: "",
        attachUrl: ""
      };
      this.eventVisible = true;
      this.isAdd = true;
    },
    isJSON: function isJSON(str) {
      try {
        var obj = JSON.parse(str);
        if (_typeof(obj) === "object" && obj) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    uploadFile: function uploadFile(params) {
      var _this2 = this;
      this.tempCount += 1;
      if (this.tempCount > 9) {
        this.$message.error("上传数量最多9个");
        return;
      }
      var size = params.file.size / 1024 / 1024;
      if (size > 10) {
        this.$message.error("上传文件大小不应超过10M！");
        return;
      }
      var formData = new FormData();
      formData.append("files", params.file);
      uploadGeneralFile(formData).then(function (res) {
        var temp = {
          name: params.file.name,
          url: res.data[0]
        };
        _this2.tempList.push(temp);
        _this2.tempCount === _this2.tempList.length;
        params.onSuccess(true);
      }).catch(function (err) {
        console.log("debug log --> ", err);
        params.onError(true);
      });
    },
    handleChange: function handleChange(info) {
      this.fileList = info.fileList;
    },
    handleOk: function handleOk() {
      var _this3 = this;
      this.form.attachUrl = this.tempList.map(function (item, index) {
        return {
          name: item.name,
          url: item.url
        };
      });
      var data = _objectSpread(_objectSpread({}, this.form), {}, {
        companyId: this.companyId,
        empId: this.empId,
        eventType: 12,
        ifAuto: false,
        companyName: this.$store.state.hr.companyName
      });
      data.eventTime = moment(data.eventTime).format("YYYY-MM-DD HH:mm:ss");
      data.attachUrl = JSON.stringify(data.attachUrl);
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this3.isAdd) {
            addPersonalEvent(data).then(function (res) {
              _this3.init();
              _this3.eventVisible = false;
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          } else {
            updatePersonalEvent(data).then(function (res) {
              _this3.init();
              _this3.eventVisible = false;
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          }
        }
      });
    },
    handleCancel: function handleCancel() {
      this.fileList = [];
      this.tempList = [];
      this.tempCount = this.tempList.length;
      this.form = {
        eventName: "",
        eventTime: "",
        description: "",
        attachUrl: ""
      };
      this.$refs.form.resetFields();
    },
    handleDelete: function handleDelete(id) {
      this.deleteId = id;
      this.deleteVisible = true;
    },
    handleEdit: function handleEdit(item, item2, item3) {
      this.form.eventTime = moment(item2.eventTime);
      this.form.eventName = item3.eventName;
      this.form.description = item3.description;
      this.form.id = item3.id;
      this.tempList = JSON.parse(JSON.stringify(item3.attachUrl));
      this.tempCount = this.tempList.length;
      this.eventVisible = true;
      this.isAdd = false;
    },
    handleFileDelete: function handleFileDelete(index) {
      this.tempList.splice(index, 1);
      this.tempCount = this.tempList.length;
    },
    handleDeleteOk: function handleDeleteOk() {
      var _this4 = this;
      deletePersonalEvent(this.deleteId).then(function (res) {
        _this4.$message.success("删除成功");
        _this4.init();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      this.deleteVisible = false;
    }
  }
};