import "core-js/modules/es.function.name.js";
import reward from "./reward";
import position from "./position";
export default {
  components: {
    reward: reward,
    position: position
  },
  created: function created() {
    this.$store.commit("recruitment/isCancel", null);
    if (this.$route.query.name) this.componentName = this.$route.query.name;
  },
  data: function data() {
    return {
      componentName: "reward",
      numArr: [{
        type: "reward",
        name: "内推奖励管理",
        num: 0
      }, {
        type: "position",
        name: "内推职位",
        num: 0
      }]
    };
  }
};