import selectPeople from "./selectPeople";
import importPeople from "./importPeople";
import abnormal from "../abnormal";
export default {
  components: {
    selectPeople: selectPeople,
    importPeople: importPeople,
    abnormal: abnormal
  },
  data: function data() {
    return {
      componentName: "selectPeople",
      abnormalArr: [],
      abnormal: false
    };
  },
  created: function created() {
    this.componentName = this.$route.query.pageType;
    // for (let k = 0; k < 100; k++) {
    //   const json = {
    //     bankId: null,
    //     batchId: "2C7674E06EE54A93952838970EC6F0F9",
    //     companyId: "245B73034EFF4BF494063C91127AEDEC",
    //     failCause: null,
    //     idCard: "150102199003079801",
    //     identity: "1501021990030798011111",
    //     importFailReason: "nononononononn",
    //     money: null,
    //     name: "测试港澳" + k,
    //     orderTitle: null,
    //     payStatus: 1,
    //     payTime: null,
    //     peopleId: "93E0F97848B34F1396C2F8C2BF8671F1",
    //     phone: "18561358601",
    //     remark: null,
    //     subjectId: null,
    //     subjectStatus: null,
    //     thirdpartyDetailId: null,
    //     type: 2
    //   };
    //   this.abnormalArr.push(json);
    // }
  },

  methods: {
    upData: function upData(arr) {
      this.abnormalArr = arr;
      this.abnormal = true;
    }
  }
};