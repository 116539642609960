import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import { getScene, getMessage } from "./api";
var columns = [{
  title: "模板名称",
  dataIndex: "name",
  key: "name",
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "模板类型",
  dataIndex: "type",
  key: "type",
  scopedSlots: {
    customRender: "type"
  }
}, {
  title: "模板内容",
  dataIndex: "content",
  key: "content",
  scopedSlots: {
    customRender: "content"
  }
}, {
  title: "关联场景",
  dataIndex: "scene	",
  key: "scene	",
  scopedSlots: {
    customRender: "scene	"
  }
}, {
  title: "操作",
  key: "action",
  scopedSlots: {
    customRender: "action"
  }
}];
var messageType = ["验证码", "通知", "推广"];
export default {
  data: function data() {
    return {
      columns: columns,
      messageData: [],
      messageType: messageType,
      searchForm: {},
      options: []
    };
  },
  created: function created() {
    this.init();
  },
  watch: {
    searchForm: {
      handler: function handler() {
        this.init();
      },
      deep: true
    }
  },
  methods: {
    init: function init() {
      var _this = this;
      var data = _objectSpread({
        isDefault: false
      }, this.searchForm);
      getMessage(data).then(function (res) {
        _this.messageData = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      getScene().then(function (res) {
        _this.options = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    goAdd: function goAdd() {
      this.$router.push({
        path: "/thirdService/smsTemplate/add",
        query: {
          isEdit: 0
        }
      });
    },
    handleSearch: function handleSearch(val) {
      this.searchForm.name = val;
      this.init();
    },
    goConfig: function goConfig(id) {
      this.$router.push({
        path: "/thirdService/smsTemplate/config",
        query: {
          templateId: id
        }
      });
    },
    goEdit: function goEdit(id) {
      this.$router.push({
        path: "/thirdService/smsTemplate/add",
        query: {
          isEdit: 1,
          id: id
        }
      });
    }
  }
};