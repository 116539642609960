import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
var column = [{
  title: "姓名",
  dataIndex: "name",
  fixed: "left",
  scopedSlots: {
    customRender: "customName"
  }
}, {
  title: "参保城市",
  dataIndex: "socCityName"
}, {
  title: "身份证号",
  dataIndex: "idCard",
  width: 200
}, {
  title: "参保方案",
  dataIndex: "socProgName",
  scopedSlots: {
    customRender: "socProgName"
  }
}];
var endList = [{
  title: "社保起缴时间",
  dataIndex: "socStartDate",
  scopedSlots: {
    customRender: "socStartDate"
  }
}, {
  title: "住房公积金起缴时间",
  dataIndex: "fundStartDate",
  scopedSlots: {
    customRender: "fundStartDate"
  }
}, {
  title: "指派公司",
  dataIndex: "assignCompanyName",
  scopedSlots: {
    customRender: "assignCompanyName"
  }
}, {
  title: "操作",
  dataIndex: "",
  scopedSlots: {
    customRender: "action"
  }
}];
import step from "./common/step";
import navItem from "./common/nav-item2";
export default {
  name: "ShebaoDetailList",
  components: {
    // step,
    navItem: navItem
  },
  data: function data() {
    return {
      custom: [],
      tabs: {},
      params: {
        pageSize: 10,
        pageNo: 1
      },
      column: column,
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      titles: {},
      dataSource: [],
      progList: [],
      customList: []
    };
  },
  created: function created() {
    this.getList();
    this.getNavs();
    this.getcbList();
  },
  methods: {
    // 获取参保方案
    getcbList: function getcbList() {
      var _this = this;
      this.$request({
        url: "/hr/api/v1/socProgs/listAllRelation"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.progList = data;
      });
    },
    hasPaidan: function hasPaidan(name) {
      return this.progList.find(function (item) {
        return item.progName === name;
      });
    },
    getList: function getList() {
      var _this2 = this;
      var url = "/hr/api/v1/socDispatchEmpAdds";
      this.$request({
        url: url,
        params: _objectSpread(_objectSpread({}, this.params), {}, {
          socDispatchId: this.$route.query.id,
          showItem: true
        })
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this2.dataSource = data.entities;
        _this2.pagination.total = data.entityCount;
        if (_this2.dataSource.length) {
          var socDispatchEmpItemList = _this2.dataSource[0].socDispatchEmpItemList || [];
          if (!_this2.customList.length) {
            _this2.custom = _this2.customList = socDispatchEmpItemList.map(function (item) {
              item.sort = item.sort.toString();
              return {
                title: item.itemName,
                dataIndex: item.itemName.toString(),
                scopedSlots: {
                  customRender: item.itemName.toString()
                }
              };
            });
            _this2.column = [].concat(_toConsumableArray(_this2.column), _toConsumableArray(_this2.customList), endList);
          }
        }
        _this2.dataSource.forEach(function (item) {
          var d = [];
          if (item.socDispatchEmpItemList) {
            item.socDispatchEmpItemList.map(function (s) {
              d.push(_defineProperty({}, s.itemName.toString(), {
                value: s.cardinalityPerson,
                error: s.noError
              }));
            });
          }
          Object.assign.apply(Object, [item].concat(d));
        });
      });
    },
    getNavs: function getNavs() {
      var _this3 = this;
      this.$request({
        url: "/hr/api/v1/socDispatches/findDetails/".concat(this.$route.query.id)
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this3.tabs = data;
      });
    },
    selectItem: function selectItem(e) {},
    handlePage: function handlePage(_ref4) {
      var current = _ref4.current;
      this.params.pageNo = current;
      this.getList();
    },
    handleNav: function handleNav(e) {},
    // 删除item
    deleteItem: function deleteItem(_ref5) {
      var _this4 = this;
      var id = _ref5.id;
      this.$confirm({
        title: "确定删除?",
        okType: "danger",
        onOk: function onOk() {
          _this4.$request({
            url: "/hr/api/v1/socDispatchEmpAdds/".concat(id),
            method: "delete"
          }).then(function (_ref6) {
            var success = _ref6.success;
            if (success) {
              _this4.$notification["success"]({
                message: "删除成功"
              });
              _this4.getList();
              _this4.getNavs();
            }
          });
        }
      });
    },
    // 重新上传
    reUpload: function reUpload() {
      this.$router.push("/shebao/shebaoPaidan/shebaoUpload");
    },
    // 下一页
    doNext: function doNext() {
      var path = this.$route.query.addOrLess.toString() === "true" ? "/shebao/shebaoPaidan/shebao-detail-add-second" : "/shebao/shebaoPaidan/shebao-detail-add-second-less";
      this.$router.push({
        path: path,
        query: _objectSpread({}, this.$route.query)
      });
    }
  }
};