import checkCard from "./card";
import imports from "./workingHours";
export default {
  components: {
    checkCard: checkCard,
    imports: imports
  },
  data: function data() {
    return {
      componentName: "checkCard",
      numArr: [{
        type: "checkCard",
        name: "考勤打卡表",
        num: 0
      }, {
        type: "imports",
        name: "工时导入表",
        num: 0
      }]
    };
  },
  created: function created() {
    if (this.$store.state.check.componentName) {
      this.componentName = this.$store.state.check.componentName;
      this.$store.commit("check/componentName", null);
    }
  }
};