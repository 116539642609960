import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import selectCompanyGroup from "@/components/SelectCompany";
import { findCompanyGroup as _findCompanyGroup } from "@/api/company/contract";
import { getCompany, addCustomer, getDate as _getDate, deleteItem as _deleteItem } from "./api";
import moment from "moment";
import { mapState } from "vuex";
import ItemCustomer from "./itemCustomer.vue";
export default {
  components: {
    ItemCustomer: ItemCustomer,
    selectCompanyGroup: selectCompanyGroup
  },
  data: function data() {
    return {
      paramType: null,
      addForm: {},
      tabButtonXinzi: false,
      tabButtonShebao: false,
      tabButtonKehu: true,
      loading: false,
      dataList: [],
      companyDataList: [],
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 12
      },
      date: moment(moment().format("YYYY-MM"), "YYYY-MM"),
      customerDetailsType: "customer",
      isOpen: false,
      addVisible: false,
      treeSiteName: undefined,
      groupList: [],
      companyList: [],
      flatDataList: [],
      replaceFields2: {
        children: "companyList",
        title: "name",
        key: "uuid",
        value: "uuid"
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["site"])), {}, {
    type: function type() {
      return this.site.selectType;
    },
    selectTreeId: function selectTreeId() {
      return this.site.selectId;
    },
    siteName: function siteName() {
      return this.site.selectName;
    }
  }),
  watch: {
    siteName: {
      handler: function handler() {
        this.dataList = this.site.companyTree ? JSON.parse(this.site.companyTree) : [];
        console.log(this.dataList);
        this.getDate();
      },
      immediate: true
    }
  },
  created: function created() {},
  methods: {
    deleteItem: function deleteItem(e) {
      var _this = this;
      _deleteItem(e.customerDetailsIds[0]).then(function (res) {
        _this.$notification["success"]({
          message: "success",
          description: "删除成功"
        });
        _this.getDate();
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    getDate: function getDate() {
      var _this2 = this;
      _getDate().then(function (res) {
        if (_this2.$route.query.date) {
          _this2.date = moment(_this2.$route.query.date, "YYYY-MM");
        } else if (res.data && res.data.yearly && res.data.monthly) {
          _this2.date = moment("".concat(res.data.yearly) + "-" + "".concat(res.data.monthly), "YYYY-MM");
        } else {
          _this2.date = moment(moment().format("YYYY-MM"), "YYYY-MM");
        }
        _this2.findCompanyGroup();
        _this2.getList();
      });
    },
    treeSearch: function treeSearch(e) {
      this.findCompanyGroup(e);
    },
    showAdd: function showAdd() {
      this.addVisible = true;
      this.addForm = {};
    },
    findCompanyGroup: function findCompanyGroup(e) {
      var _this3 = this;
      _findCompanyGroup({
        name: e || null
      }).then(function (res) {
        _this3.companyDataList = _this3.addTreeSlot(res.data);
        _this3.flatten(_this3.companyDataList);
        _this3.addForm.companyInfoList = _this3.addForm.companyInfoList ? JSON.parse(JSON.stringify(_this3.addForm.companyInfoList)) : undefined;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    addTreeSlot: function addTreeSlot(data) {
      var _this4 = this;
      if (!data) {
        return [];
      }
      data.forEach(function (el) {
        el.scopedSlots = {
          title: "custom"
        };
        _this4.addTreeSlot(el.companyList);
      });
      return data;
    },
    flatten: function flatten(list) {
      var _this5 = this;
      if (list !== null) {
        list.forEach(function (item) {
          _this5.flatDataList.push(item);
          _this5.flatten(item.companyList);
        });
      }
    },
    handleAddOk: function handleAddOk() {
      var _this6 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var companyArr = [];
          _this6.addForm.companyInfoList.forEach(function (item) {
            _this6.flatDataList.forEach(function (item2, index2) {
              if (item2.uuid && item2.uuid === item.value) {
                companyArr.push({
                  companyId: item2.id,
                  companyName: item2.name
                });
              }
            });
          });
          addCustomer({
            companyId: _this6.$store.state.hr.companyId,
            monthly: moment(_this6.addForm.date).format("MM"),
            type: _this6.addForm.type,
            yearly: moment(_this6.addForm.date).format("YYYY"),
            salaryMonthly: _this6.addForm.salaryDate ? moment(_this6.addForm.salaryDate).format("MM") : undefined,
            salaryYearly: _this6.addForm.salaryDate ? moment(_this6.addForm.salaryDate).format("YYYY") : undefined,
            socialMonthly: _this6.addForm.socialDate ? moment(_this6.addForm.socialDate).format("MM") : undefined,
            socialYearly: _this6.addForm.socialDate ? moment(_this6.addForm.socialDate).format("YYYY") : undefined,
            companyInfoList: companyArr
          }).then(function (res) {
            _this6.addVisible = false;
            _this6.getCompanyList();
          });
        }
      });
    },
    tabChange: function tabChange(e) {
      if (e === 1) {
        this.tabButtonXinzi = true;
        this.tabButtonShebao = false;
        this.tabButtonKehu = false;
        this.customerDetailsType = "salary";
      } else if (e === 2) {
        this.tabButtonXinzi = false;
        this.tabButtonShebao = true;
        this.tabButtonKehu = false;
        this.customerDetailsType = "social";
      } else if (e === 3) {
        this.tabButtonXinzi = false;
        this.tabButtonShebao = false;
        this.tabButtonKehu = true;
        this.customerDetailsType = "customer";
      }
      this.getCompanyList();
    },
    getList: function getList() {
      if (this.selectTreeId === "-1" || this.selectTreeId === null || this.selectTreeId === undefined) {
        this.paramType = {
          id: this.dataList[0].id,
          type: this.dataList[0].type
        };
      } else {
        this.paramType = {
          id: this.selectTreeId,
          type: this.type
        };
      }
      this.getCompanyList();
    },
    goDetail: function goDetail(item) {
      this.$router.push({
        path: "customers/customerDetail",
        query: {
          dataList: JSON.stringify(item),
          monthly: moment(this.date).format("MM"),
          yearly: moment(this.date).format("YYYY"),
          formType: this.customerDetailsType
        }
      });
    },
    getCompanyList: function getCompanyList() {
      var _this7 = this;
      this.loading = true;
      this.groupList = [];
      getCompany({
        monthly: moment(this.date).format("MM"),
        yearly: moment(this.date).format("YYYY"),
        type: this.paramType,
        customerDetailsType: this.customerDetailsType
      }).then(function (res) {
        _this7.groupList = JSON.parse(JSON.stringify(res.data));
        _this7.loading = false;
      }).catch(function (err) {
        _this7.loading = false;
        console.log("debug log --> ", err);
      });
    },
    openClick: function openClick(e) {
      console.log(e);
      e.isOpen = !e.isOpen;
      this.groupList = JSON.parse(JSON.stringify(this.groupList));
    },
    // 月份选择变化
    onMonthChange: function onMonthChange(e) {
      this.date = e;
      var query = JSON.parse(JSON.stringify(this.$router.history.current.query)); // 取当前路由query值并且深拷贝
      var path = this.$router.history.current.path; // 取当前路由地址
      query.date = moment(this.date).format("YYYY-MM"); // 修改变动的query值
      this.$router.push({
        path: path,
        query: query
      }); // 改变query值
      this.getCompanyList();
    }
  }
};