import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { getTemplates, getNotArchiveById, deleteSalaryGroupSalaries, batchDeleteSalaryGroupSalaries } from "@/api/wages/template";
import { addSalaryGroup, selectSalaryGroupEmp, getSalaryDetail, editSalaryGroup, findTaxRule } from "@/api/wages/set";
import Moment from "moment";
export default {
  components: {
    EmployeeSelect: function EmployeeSelect() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/components/EmployeeSelect2/employeeSelect-xinzi"));
      });
    }
  },
  data: function data() {
    return {
      showEmpList: false,
      size: null,
      originalSalaryTemplateId: "",
      groupId: "",
      duplicateName: "",
      selectedList: [],
      empConfirmVisible: false,
      isChooseStaff: false,
      isEdit: false,
      form: {
        empList: [],
        name: "",
        salaryTemplateId: undefined,
        taxId: undefined,
        changeSalaryRule: undefined,
        serviceFeeType: undefined,
        serviceFeeItem: undefined,
        billingMonth: null,
        chargeAmount: "",
        proportion: "",
        isAllEmp: false
      },
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 16
      },
      rules: {
        name: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }],
        isAllEmp: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }],
        salaryTemplateId: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }],
        changeSalaryRule: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }],
        taxId: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }],
        serviceFeeType: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }]
      },
      cacheEmpList: [],
      askImg: require("@/assets/img/wages/set/ask.png"),
      options: [],
      optionsRule: [],
      companyId: ""
    };
  },
  created: function created() {
    this.companyId = this.$store.state.hr.companyId;
    this.init();
  },
  methods: {
    inputChange: function inputChange(e) {},
    keyPress: function keyPress(event) {
      var keyCode = event.keyCode;
      if (keyCode >= 48 && keyCode <= 57) {
        event.returnValue = true;
      } else {
        event.returnValue = false;
      }
    },
    handleSizeChange: function handleSizeChange(e) {
      if (this.form.isAllEmp === true) {
        this.showEmpList = false;
      }
      if (this.form.isAllEmp === false) {
        this.showEmpList = true;
      }
    },
    init: function init() {
      var _this2 = this;
      this.isEdit = Number(this.$route.query.isEdit) === 1;
      if (this.isEdit) {
        this.groupId = this.$route.query.groupId;
        getSalaryDetail(this.groupId).then(function (res) {
          _this2.form.name = res.data.name;
          _this2.selectedList = res.data.empInformationList;
          _this2.form.empList = res.data.empInformationList.map(function (item, index) {
            return item.id;
          });
          _this2.form.salaryTemplateId = Number(JSON.parse(JSON.stringify(res.data.salaryTemplateId)));
          _this2.originalSalaryTemplateId = JSON.parse(JSON.stringify(res.data.salaryTemplateId));
          _this2.form.taxId = Number(res.data.taxId);
          _this2.form.changeSalaryRule = Number(res.data.changeSalaryRule);
          _this2.form.serviceFeeType = Number(res.data.serviceFeeType);
          _this2.form.serviceFeeItem = res.data.serviceFeeItem;
          _this2.form.billingMonth = res.data.billingMonth ? Moment(res.data.billingMonth) : null;
          _this2.form.chargeAmount = res.data.chargeAmount;
          _this2.form.proportion = res.data.proportion;
          _this2.form.isAllEmp = res.data.isAllEmp;
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      }
      var data = {
        companyId: this.companyId
      };
      getTemplates(data).then(function (res) {
        _this2.options = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
      findTaxRule(this.companyId).then(function (res) {
        _this2.optionsRule = res.data;
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleStaffVisible: function handleStaffVisible() {
      this.isChooseStaff = true;
    },
    onEmployeeSelectChange: function onEmployeeSelectChange(val) {
      var _this3 = this;
      var empList = val.map(function (item) {
        return item.id;
      });
      var data = {
        id: this.isEdit ? this.groupId : "",
        empList: empList,
        companyId: this.companyId
      };
      selectSalaryGroupEmp(data).then(function (res) {
        if (res.data.length !== 0) {
          _this3.empConfirmVisible = true;
          _this3.duplicateName = res.data.map(function (item, index) {
            return item.name;
          }).join(",");
          _this3.cacheEmpList = empList;
        } else {
          _this3.form.empList = empList;
        }
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    handleEmpConfirm: function handleEmpConfirm() {
      this.form.empList = this.cacheEmpList;
      this.empConfirmVisible = false;
    },
    saveGroup: function saveGroup() {
      var _this4 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var data = _objectSpread({
            companyId: _this4.companyId
          }, _this4.form);
          console.log(data);
          if (data.billingMonth) {
            try {
              data.billingMonth = _this4.form.billingMonth.format("YYYY-MM-DD");
            } catch (err) {
              console.log("xxx", err);
            }
          }
          if (_this4.isEdit) {
            if (_this4.originalSalaryTemplateId !== _this4.form.salaryTemplateId) {
              getNotArchiveById({
                salaryGroupId: _this4.groupId,
                salaryTemplateId: _this4.originalSalaryTemplateId
              }).then(function (res) {
                if (res.data && !res.data.length || !res.data) {
                  data.id = _this4.groupId;
                  editSalaryGroup(data).then(function (res) {
                    _this4.$router.push({
                      path: "/wages/set"
                    });
                    _this4.$message.success("薪资组编辑成功");
                  }).catch(function (err) {
                    console.log("debug log --> ", err);
                  });
                } else {
                  var idList = [];
                  res.data.forEach(function (item, index) {
                    idList.push(item.id);
                  });
                  var arr = [];
                  res.data.forEach(function (item, index) {
                    arr.push(item.monthly);
                  });
                  arr = arr.join(",");
                  var _this = _this4;
                  _this4.$confirm({
                    title: "\u8BE5\u85AA\u8D44\u7EC4\u5DF2\u5728".concat(arr, "\u6708\u521B\u5EFA\u6708\u5DE5\u8D44\u8868\uFF0C\u66F4\u6362\u6A21\u677F\u4F1A\u540C\u65F6\u5220\u9664\u672A\u5F52\u6863\u7684\u6708\u5DE5\u8D44\u8868\u4E2D\u8BE5\u85AA\u8D44\u7EC4\u7684\u5458\u5DE5\uFF0C\u4ECD\u65E7\u7EE7\u7EED\u5417\uFF1F"),
                    onOk: function onOk() {
                      batchDeleteSalaryGroupSalaries(idList).then(function (_ref) {
                        var success = _ref.success;
                        // deleteSalaryGroupSalaries(res.data.id).then(({ success }) => {
                        if (success) {
                          data.id = _this.groupId;
                          editSalaryGroup(data).then(function (res) {
                            _this.$router.push({
                              path: "/wages/set"
                            });
                            _this.$message.success("薪资组编辑成功");
                          }).catch(function (err) {
                            console.log("debug log --> ", err);
                          });
                        }
                      });
                    }
                  });
                }
              });
            } else {
              data.id = _this4.groupId;
              editSalaryGroup(data).then(function (res) {
                _this4.$router.push({
                  path: "/wages/set"
                });
                _this4.$message.success("薪资组编辑成功");
              }).catch(function (err) {
                console.log("debug log --> ", err);
              });
            }
          } else {
            addSalaryGroup(data).then(function (res) {
              _this4.$router.push({
                path: "/wages/set"
              });
              _this4.$message.success("薪资组创建成功");
            }).catch(function (err) {
              console.log("debug log --> ", err);
            });
          }
        }
      });
    },
    handleCancel: function handleCancel() {
      var _this = this;
      this.$confirm({
        title: "薪资组内容未保存，是否确认离开？",
        okText: "确定",
        cancelText: "取消",
        onOk: function onOk() {
          _this.$router.go(-1);
        }
      });
    }
  }
};