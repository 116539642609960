var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "请选择税局",
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleOk,
      cancel: function cancel($event) {
        return _vm.$emit("update:visible", false);
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      selfUpdate: true,
      "label-col": {
        span: 5
      },
      "wrapper-col": {
        span: 18
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "企业/税局"
    }
  }, [_c("a-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["resourceUserId", {
        rules: [{
          required: true,
          message: "企业/税局不能为空"
        }]
      }],
      expression: "['resourceUserId', { rules: [{ required: true, message: '企业/税局不能为空' }] }]"
    }]
  }), _c("authorityCascader", {
    attrs: {
      visible: _vm.visible
    },
    on: {
      upDate: _vm.upDate
    }
  }), _c("div", {
    staticClass: "desc"
  }, [_vm._v("请选择本次导入报送人员的所属税局")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };