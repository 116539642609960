import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pb24 uplaodBox"
  }, [_c("a-row", {
    staticClass: "bbE8E8E8 h60 lh60"
  }, [_c("a-col", {
    staticClass: "exportTitle",
    attrs: {
      span: 24
    }
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    },
    on: {
      click: _vm.goback
    }
  }), _c("div", {
    staticClass: "left2",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.goback
    }
  }, [_vm._v("返回")]), _vm._v(" " + _vm._s(_vm.name) + "的" + _vm._s(_vm.itemType) + " ")], 1)], 1), _c("div", {
    staticClass: "pl24 pr24 pt24"
  }, [_c("div", {
    staticClass: "tipBox pl24 pr24"
  }, [_c("a-row", {
    staticClass: "fs16"
  }, [_vm._v("温馨提示")]), _c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("span", {
    staticClass: "color9 pr24"
  }, [_vm._v(" 该材料为重要材料，上传重要材料可降低用工风险")])])], 1)], 1)]), _c("a-row", {
    staticStyle: {
      padding: "30px",
      display: "flex",
      "justify-content": "flex-end"
    }
  }, [_c("a-button", {
    attrs: {
      size: "small",
      type: "primary",
      disabled: _vm.edit != null
    },
    on: {
      click: _vm.downloadImg
    }
  }, [_vm._v("打包下载")])], 1), _c("div", {
    staticStyle: {
      padding: "20px"
    }
  }, [_c("UploadSiteFile", {
    ref: "uploadTool",
    attrs: {
      disabled: _vm.edit != null,
      limit: 9,
      "default-value": _vm.url,
      accept: ".doc,.docx,.xls,.xlsx,.pdf,.zip,.rar,.jpg, .jpeg, .png"
    },
    on: {
      change: _vm.onFileChange
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };