import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import { getEmpResignedList, reinstatement, reinstatementUpdate, empInfosDel } from "@/api/staff/staff";
import employeesMoveAdvancedScreening from "./employeesMoveAdvancedScreening";
import { formatDate } from "@/utils/index";
var columns = [{
  title: "姓名",
  dataIndex: "empName",
  ellipsis: true,
  align: "center"
}, {
  title: "部门",
  dataIndex: "depName",
  ellipsis: true,
  align: "center"
}, {
  title: "岗位",
  dataIndex: "positionName",
  ellipsis: true,
  align: "center"
}, {
  title: "手机号",
  dataIndex: "phone",
  ellipsis: true,
  align: "center"
}, {
  title: "离职日期",
  dataIndex: "leaveDate",
  ellipsis: true,
  align: "center",
  customRender: function customRender(text, record, index) {
    return formatDate(text);
  }
}, {
  title: "离职原因",
  dataIndex: "quitReason"
}, {
  title: "操作",
  dataIndex: "operate",
  scopedSlots: {
    customRender: "operate"
  }
}];
var pagination = {
  pageSize: 10,
  total: 0,
  size: "default",
  current: 1,
  showTotal: function showTotal(total) {
    return "\u5171 ".concat(total, " \u6761");
  },
  showQuickJumper: true
};
export default {
  name: "LeaveList",
  data: function data() {
    return {
      empName: "",
      companyId: this.$store.state.hr.companyId,
      columns: columns,
      pagination: pagination,
      data: [],
      selectedRowKeys: []
    };
  },
  components: {
    employeesMoveAdvancedScreening: employeesMoveAdvancedScreening
  },
  created: function created() {
    this.pagination.current = 1;
    this.getEmpInfosData();
  },
  methods: {
    getNextStep: function getNextStep(data, state) {
      console.log(data, state);
      this.batchArrData = data;
      if (state === "批量恢复在职") {
        this.getReinstatementUpdate();
      } else if (state === "批量删除") {
        this.getEmpInfosDel();
      }
    },
    getEmpInfosDel: function getEmpInfosDel() {
      var _this = this;
      empInfosDel(this.batchArrData.map(function (i) {
        return i.id;
      })).then(function (res) {
        _this.$notification["success"]({
          message: "success",
          description: "操作成功"
        });
        _this.getEmpInfosData();
      });
    },
    getReinstatementUpdate: function getReinstatementUpdate() {
      var _this2 = this;
      reinstatementUpdate(this.batchArrData.map(function (i) {
        return i.id;
      }), this.$store.state.hr.companyId).then(function (res) {
        _this2.$notification["success"]({
          message: "success",
          description: "操作成功"
        });
        _this2.getEmpInfosData();
      });
    },
    openEmployeesMove: function openEmployeesMove(e) {
      var _this3 = this;
      // 打开高级筛选
      this.$nextTick(function () {
        _this3.$refs.employeesMove.showModal(e);
      });
    },
    onSearch: function onSearch() {
      this.pagination.current = 1;
      this.getEmpInfosData();
    },
    restore: function restore(record) {
      var _this4 = this;
      reinstatement({
        companyId: this.$store.state.hr.companyId,
        empId: record.empId
      }).then(function (res) {
        _this4.$notification["success"]({
          message: "success",
          description: "恢复成功"
        });
        _this4.pagination.current = 1;
        _this4.getEmpInfosData();
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getEmpInfosData();
    },
    getEmpInfosData: function getEmpInfosData() {
      var _this5 = this;
      getEmpResignedList(this.$store.state.hr.companyId, {
        empName: this.empName,
        pageNo: this.pagination.current,
        pageSize: 10
      }).then(function (res) {
        _this5.data = res.data.entities;
        _this5.pagination.total = res.data && res.data.entityCount;
      });
    },
    onSelectChange: function onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    goback: function goback() {
      this.$router.push({
        path: "/staff/manger?selectId=4"
      });
    },
    detail: function detail(row) {
      var searchData = {
        companyId: this.$store.state.hr.companyId,
        queryType: 6
      };
      this.$router.push({
        path: "/staff/detail",
        query: {
          id: row.empId,
          userId: row.userId,
          prevpage: location.hash.slice(1)
        }
      });
    }
  }
};