import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-layout", {
    staticClass: "console-contract-add-wrapper"
  }, [_c("a-row", {
    staticClass: "head"
  }, [_c("a-icon", {
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        return _vm.$router.push("/thirdService");
      }
    }
  }), _c("a-col", {
    staticClass: "title"
  }, [_c("strong", [_vm._v("电子合同配置")])])], 1), _c("a-row", [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      "label-col": _vm.labelCol,
      "wrapper-col": _vm.wrapperCol,
      rules: _vm.rules
    }
  }, [_c("a-form-model-item", [_c("div", {
    staticStyle: {
      "font-size": "20px",
      "font-weight": "500",
      color: "#000000",
      "padding-left": "200px"
    }
  }, [_vm._v(" 第三方服务信息 ")])]), _c("a-form-model-item", {
    ref: "",
    attrs: {
      label: "服务商",
      prop: ""
    }
  }, [_c("a-input", {
    attrs: {
      value: "阿里云",
      disabled: true
    }
  })], 1), _c("a-form-model-item", {
    ref: "name",
    attrs: {
      label: "名称",
      prop: "name"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("a-form-model-item", {
    ref: "projectId",
    attrs: {
      label: "账户名",
      prop: "projectId"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.form.projectId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "projectId", $$v);
      },
      expression: "form.projectId"
    }
  })], 1), _c("a-form-model-item", {
    ref: "secret",
    attrs: {
      label: "密钥",
      prop: "secret"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.form.secret,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "secret", $$v);
      },
      expression: "form.secret"
    }
  })], 1), _c("a-form-model-item", {
    ref: "apiHost",
    attrs: {
      label: "接口域名",
      prop: "apiHost"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.form.apiHost,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "apiHost", $$v);
      },
      expression: "form.apiHost"
    }
  })], 1), _c("a-form-model-item", {
    ref: "apiUrl",
    attrs: {
      label: "接口地址",
      prop: "apiUrl"
    }
  }, [_c("a-input", {
    model: {
      value: _vm.form.apiUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "apiUrl", $$v);
      },
      expression: "form.apiUrl"
    }
  })], 1), _c("a-form-model-item", [_c("div", {
    staticStyle: {
      "font-size": "20px",
      "font-weight": "500",
      color: "#000000",
      "padding-left": "200px"
    }
  }, [_vm._v(" 服务收费信息 ")])]), _c("a-form-model-item", {
    ref: "cost",
    attrs: {
      label: "价格",
      prop: "cost"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "370px"
    },
    attrs: {
      placeholder: "如：职薪人"
    },
    model: {
      value: _vm.form.cost,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cost", $$v);
      },
      expression: "form.cost"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "20px"
    }
  }, [_vm._v("元/条")])], 1)]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end",
      margin: "0 24px 24px 0"
    }
  }, [_vm.isEdit ? _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(" 更新保存配置 ")]) : _c("div", [_c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(" 取消 ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(" 保存 ")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };