import request from "@/utils/request";
export function getSaveCustomer(data) {
  return request({
    url: "/hr/api/v1/crmCustomerInfos/saveCustomer",
    method: "POST",
    data: data
  });
}
export function putCrmCustomerInfos(data) {
  return request({
    url: "/hr/api/v1/crmCustomerInfos",
    method: "PUT",
    data: data
  });
}
export function getQueryCustomerInfo(params) {
  return request({
    url: "/hr/api/v1/crmCustomerInfos/queryCustomerInfo",
    method: "GET",
    params: params
  });
}