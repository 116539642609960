import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-tabs", {
    staticClass: "bgc-white"
  }, [_c("a-tab-pane", {
    key: "用工单位",
    attrs: {
      tab: "用工单位"
    }
  }, [_c("a-spin", {
    staticClass: "body_detail page-miH page-bgBack",
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-row", {
    staticClass: "company",
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("a-col", [_c("a-icon", {
    staticClass: "mg_r20",
    attrs: {
      type: "left"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm._v(" " + _vm._s(_vm.editor ? "用工单位详情" : "添加用工单位"))], 1), _vm.editor ? _c("a-col", [_c("a-button", {
    staticClass: "mg_r20",
    on: {
      click: _vm.editorDetail
    }
  }, [_vm._v(_vm._s(_vm.disabled ? "编辑" : "取消"))]), _c("a-button", {
    on: {
      click: _vm.deleteEmp
    }
  }, [_vm._v("删除用工单位")])], 1) : _vm._e()], 1), _c("div", {
    staticClass: "base_detail"
  }, [_c("span", {
    staticClass: "font-14px-3333"
  }, [_vm._v("基本信息")])]), [_c("a-form", {
    attrs: {
      form: _vm.form
    }
  }, [_c("a-row", {
    staticStyle: {
      width: "900px",
      margin: "0 auto"
    }
  }, [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      },
      label: "单位名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "单位名称必填"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '单位名称必填' }] }]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      disabled: _vm.disabled,
      placeholder: "请输入"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      },
      label: "所属分组"
    }
  }, [_c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["groupIds", {
        rules: _vm.isRequired ? [{
          required: true,
          message: "所属分组必选"
        }] : []
      }],
      expression: "['groupIds', { rules: isRequired ? [{ required: true, message: '所属分组必选' }] : [] }]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      treeData: _vm.groupTree,
      disabled: _vm.disabled,
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      },
      treeNodeFilterProp: "title",
      treeCheckStrictly: "",
      treeCheckable: "",
      placeholder: "请输入"
    },
    on: {
      change: _vm.onGroupIdsChange
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShow,
      expression: "!isShow"
    }],
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      },
      label: "联系人姓名"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["legalPerson"],
      expression: "['legalPerson']"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      disabled: _vm.disabled,
      placeholder: "请输入"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShow,
      expression: "!isShow"
    }],
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      },
      label: "联系电话"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["telephone", {
        rules: [{
          required: false,
          message: "手机号必填"
        }, {
          pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
          message: "请输入11位数字类型手机号码"
        }]
      }],
      expression: "[\n                    'telephone',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '手机号必填'\n                        },\n                        {\n                          pattern: /^1(3|4|5|6|7|8|9)\\d{9}$/,\n                          message: '请输入11位数字类型手机号码'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      disabled: _vm.disabled,
      "max-length": 11,
      placeholder: "请输入"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShow,
      expression: "!isShow"
    }],
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      },
      label: "客户级别"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["clientLevel"],
      expression: "['clientLevel']"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      disabled: _vm.disabled,
      placeholder: "请输入"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShow,
      expression: "!isShow"
    }],
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      },
      label: "客户专属响应电话"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["clientPhone", {
        rules: [{
          required: false,
          message: "手机号必填"
        }, {
          pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
          message: "请输入11位数字类型手机号码"
        }]
      }],
      expression: "[\n                    'clientPhone',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: '手机号必填'\n                        },\n                        {\n                          pattern: /^1(3|4|5|6|7|8|9)\\d{9}$/,\n                          message: '请输入11位数字类型手机号码'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      disabled: _vm.disabled,
      "max-length": 11,
      placeholder: "请输入"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShow,
      expression: "!isShow"
    }],
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      },
      label: "联系邮箱"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["email", {
        rules: [{
          pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
          message: "请输入正确的邮箱格式"
        }]
      }],
      expression: "[\n                    'email',\n                    {\n                      rules: [\n                        {\n                          pattern: /^[A-Za-z\\d]+([-_.][A-Za-z\\d]+)*@([A-Za-z\\d]+[-.])+[A-Za-z\\d]{2,4}$/,\n                          message: '请输入正确的邮箱格式'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      disabled: _vm.disabled,
      placeholder: "请输入"
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShow,
      expression: "!isShow"
    }],
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      },
      label: "单位规模"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["personSize"],
      expression: "['personSize']"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      disabled: _vm.disabled,
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      }
    },
    on: {
      change: _vm.handleChange
    }
  }, _vm._l(_vm.person, function (item) {
    return _c("a-select-option", {
      key: item,
      attrs: {
        value: item
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShow,
      expression: "!isShow"
    }],
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      },
      label: "所在行业"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["industry"],
      expression: "['industry']"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      disabled: _vm.disabled,
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      }
    },
    on: {
      change: _vm.handleChange
    }
  }, _vm._l(_vm.industry, function (item) {
    return _c("a-select-option", {
      key: item,
      attrs: {
        value: item
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShow,
      expression: "!isShow"
    }],
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      },
      label: "上传合同"
    }
  }, [_c("a-upload", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["serverAnnex", {
        rules: [{
          required: _vm.isRequired,
          message: "必须上传合同"
        }]
      }],
      expression: "['serverAnnex', { rules: [{ required: isRequired, message: '必须上传合同' }] }]"
    }],
    attrs: {
      multiple: "",
      fileList: _vm.fileListTemp,
      disabled: _vm.disabled,
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon: !(_vm.editor && _vm.disabled)
      },
      action: "/file/api/v1/general/upload/inline",
      name: "files"
    },
    on: {
      preview: _vm.previewFile,
      change: _vm.onUploadChange
    }
  }, [!_vm.disabled ? _c("div", {
    staticClass: "ta-center br4 p12",
    staticStyle: {
      border: "1px dashed var(--primary)",
      cursor: "pointer",
      width: "260px"
    }
  }, [_c("div", [_c("a-icon", {
    staticStyle: {
      color: "var(--primary)",
      "font-size": "24px"
    },
    attrs: {
      type: "plus-circle",
      theme: "filled"
    }
  })], 1), _c("div", {
    staticStyle: {
      color: "var(--primary)"
    }
  }, [_vm._v("上传材料")]), _c("div", {
    staticClass: "ta-left mt4",
    staticStyle: {
      color: "rgba(118, 120, 133, 1)"
    }
  }, [_vm._v(" 支持doc，ppt，pdf，jpg，png等格式文件，文件不得超过20M。 ")])]) : _vm._e()])], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShow,
      expression: "!isShow"
    }],
    attrs: {
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 16
      },
      label: "备注"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["profile"],
      expression: "['profile']"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      disabled: _vm.disabled,
      placeholder: "请输入",
      autoSize: {
        minRows: 4,
        maxRows: 4
      }
    }
  })], 1)], 1)], 1)], 1)], !_vm.editor ? _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("div", {
    staticClass: "but-open-show",
    on: {
      click: function click($event) {
        _vm.isShow = !_vm.isShow;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isShow ? "更多" : "收起") + " "), _c("a-icon", {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      type: _vm.isShow ? "down" : "up"
    }
  })], 1)]) : _vm._e(), !_vm.isJF ? _c("div", {
    staticClass: "base_detail"
  }, [_c("span", {
    staticClass: "font-14px-3333"
  }, [_vm._v("合同信息")])]) : _vm._e(), !_vm.isJF ? _c("ContractFrom", {
    ref: "ContractFrom",
    attrs: {
      value: _vm.contractFormValues,
      companyId: _vm.$route.query.id,
      disabled: _vm.disabled,
      isRequired: _vm.isRequired
    }
  }) : _vm._e(), !_vm.isJF ? _c("div", {
    staticClass: "base_detail"
  }, [_c("span", {
    staticClass: "font-14px-3333"
  }, [_vm._v("费用结算")])]) : _vm._e(), !_vm.isJF ? _c("FeeForm", {
    ref: "FeeForm",
    attrs: {
      value: _vm.feeFormValues,
      companyId: _vm.$route.query.id,
      disabled: _vm.disabled,
      isRequired: _vm.isRequired
    }
  }) : _vm._e(), !_vm.isJF ? _c("div", {
    staticClass: "base_detail"
  }, [_c("span", {
    staticClass: "font-14px-3333"
  }, [_vm._v("高级配置")])]) : _vm._e(), !_vm.isJF ? _c("a-form", {
    attrs: {
      form: _vm.formHigh
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      padding: "0 15% 0 12%"
    }
  }, [_c("a-form-item", {
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      },
      label: "参保方案设置"
    }
  }, [_vm.editor ? _c("div", {
    staticClass: "ellipsis",
    staticStyle: {
      width: "260px"
    }
  }, [_vm._v(_vm._s(_vm.socProg ? _vm.socProg : "--"))]) : _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["socProgsIds", {
        rules: [{
          required: false,
          message: "请选择参保方案"
        }]
      }],
      expression: "[\n                'socProgsIds',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: '请选择参保方案'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      mode: "multiple",
      placeholder: "请选择",
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      },
      disabled: _vm.disabled
    }
  }, _vm._l(_vm.socData, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.progName) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      },
      label: "合同模板"
    }
  }, [_vm.editor ? _c("div", {
    staticClass: "ellipsis",
    staticStyle: {
      width: "260px"
    }
  }, [_vm._v(_vm._s(_vm.category ? _vm.category : "--"))]) : _c("a-tree-select", {
    staticStyle: {
      width: "260px"
    },
    attrs: {
      disabled: _vm.disabled,
      "tree-data": _vm.contractData,
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      },
      "tree-checkable": "",
      replaceFields: _vm.replaceFields,
      "show-checked-strategy": _vm.SHOW_PARENT,
      placeholder: "请选择合同模板"
    },
    model: {
      value: _vm.contractDefaultValue,
      callback: function callback($$v) {
        _vm.contractDefaultValue = $$v;
      },
      expression: "contractDefaultValue"
    }
  }), !_vm.editor ? _c("a-checkbox", {
    attrs: {
      disabled: !_vm.contractDefaultValue.length
    },
    model: {
      value: _vm.isSeal,
      callback: function callback($$v) {
        _vm.isSeal = $$v;
      },
      expression: "isSeal"
    }
  }, [_vm._v(" 是否同步下发印章 ")]) : _vm._e()], 1)], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      padding: "0 15% 0 12%"
    }
  }, [_c("a-form-item", {
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      },
      label: "工资表模板"
    }
  }, [_vm.editor ? _c("div", {
    staticClass: "ellipsis",
    staticStyle: {
      width: "260px"
    }
  }, [_vm._v(_vm._s(_vm.salaryTemplate ? _vm.salaryTemplate : "--"))]) : _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["salaryTemplateIds", {
        rules: [{
          required: false,
          message: "请选择工资表模板"
        }]
      }],
      expression: "[\n                'salaryTemplateIds',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: '请选择工资表模板'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      mode: "multiple",
      placeholder: "请选择",
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      },
      disabled: _vm.disabled
    }
  }, _vm._l(_vm.templateData, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 14
      },
      label: "合同印章"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_vm.editor ? _c("div", {
    staticClass: "ellipsis",
    staticStyle: {
      width: "260px"
    }
  }, [_vm._v(_vm._s(_vm.seal ? _vm.seal : "--"))]) : _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["sealsIds", {
        rules: [{
          required: false,
          message: "请选择合同印章"
        }]
      }],
      expression: "[\n                  'sealsIds',\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        message: '请选择合同印章'\n                      }\n                    ]\n                  }\n                ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      mode: "multiple",
      placeholder: "请选择",
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      },
      disabled: _vm.disabled
    }
  }, _vm._l(_vm.sealData, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        disabled: item.status !== 1,
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1), !_vm.editor ? _c("a-popover", {
    attrs: {
      placement: "top"
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("span", {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("未认证的印章不支持下发")])]), _c("a-icon", {
    staticStyle: {
      cursor: "pointer",
      color: "#bebebe",
      "margin-left": "12px"
    },
    attrs: {
      type: "question-circle",
      theme: "filled"
    }
  })], 2) : _vm._e()], 1)])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      padding: "0 15% 0 12%"
    }
  }, [_c("a-form-item", {
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      },
      label: "工价规则"
    }
  }, [_vm.editor ? _c("div", {
    staticClass: "ellipsis",
    staticStyle: {
      width: "260px"
    }
  }, [_vm._v(_vm._s(_vm.GJrules ? _vm.GJrules : "--"))]) : _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["wagesRules", {
        rules: [{
          required: false,
          message: "请选择工价规则"
        }]
      }],
      expression: "[\n                'wagesRules',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: '请选择工价规则'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      mode: "multiple",
      "show-search": "",
      placeholder: "请选择规则",
      "option-filter-prop": "children",
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      }
    }
  }, _vm._l(_vm.wageList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      },
      label: "招工劳务费规则"
    }
  }, [_vm.editor ? _c("div", {
    staticClass: "ellipsis",
    staticStyle: {
      width: "260px"
    }
  }, [_vm._v(_vm._s(_vm.LWFrules ? _vm.LWFrules : "--"))]) : _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["laborRules", {
        rules: [{
          required: false,
          message: "请选择招工劳务费规则"
        }]
      }],
      expression: "[\n                'laborRules',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: '请选择招工劳务费规则'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      mode: "multiple",
      "show-search": "",
      placeholder: "请选择规则",
      "option-filter-prop": "children",
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      }
    }
  }, _vm._l(_vm.laborList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      padding: "0 15% 0 12%"
    }
  }, [_c("a-form-item", {
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      },
      label: "审批模板"
    }
  }, [_vm.editor ? _c("div", {
    staticClass: "ellipsis",
    staticStyle: {
      width: "260px"
    }
  }, [_vm._v(_vm._s(_vm.approFlowList ? _vm.approFlowList : "--"))]) : _c("a-tree-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["approFlowInforList", {
        rules: [{
          required: false,
          message: "请选择审批模板"
        }]
      }],
      expression: "[\n                'approFlowInforList',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: '请选择审批模板'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      "tree-data": _vm.approvalData,
      "tree-checkable": "",
      replaceFields: _vm.replaceFields2,
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      },
      filterTreeNode: false,
      placeholder: "请选择审批模板"
    },
    on: {
      search: _vm.treeSearch2,
      change: _vm.treeSelectChange2
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      },
      label: "入职模板"
    }
  }, [_vm.editor ? _c("div", {
    staticClass: "ellipsis",
    staticStyle: {
      width: "260px"
    }
  }, [_vm._v(_vm._s(_vm.onJobList ? _vm.onJobList : "--"))]) : _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["onWorkList", {
        rules: [{
          required: false,
          message: "请选择入职模板"
        }]
      }],
      expression: "[\n                'onWorkList',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: '请选择入职模板'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      allowClear: "",
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      },
      "option-filter-prop": "children",
      "show-search": "",
      "filter-option": _vm.filterOption,
      placeholder: "请选择模板"
    }
  }, _vm._l(_vm.templateEntities, function (i) {
    return _c("a-select-option", {
      key: i.id,
      attrs: {
        value: i.id
      }
    }, [_vm._v(_vm._s(i.name))]);
  }), 1)], 1)], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-start",
      padding: "0 15% 0 12%"
    }
  }, [_c("a-form-item", {
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "label-col": {
        span: 10
      },
      "wrapper-col": {
        span: 12
      },
      label: "待入职模板"
    }
  }, [_vm.editor ? _c("div", {
    staticClass: "ellipsis",
    staticStyle: {
      width: "260px"
    }
  }, [_vm._v(_vm._s(_vm.waitJobList ? _vm.waitJobList : "--"))]) : _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["waitWorkList", {
        rules: [{
          required: false,
          message: "请选择待入职模板"
        }]
      }],
      expression: "[\n                'waitWorkList',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: '请选择待入职模板'\n                    }\n                  ]\n                }\n              ]"
    }],
    staticStyle: {
      width: "260px"
    },
    attrs: {
      allowClear: "",
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      },
      "option-filter-prop": "children",
      "show-search": "",
      "filter-option": _vm.filterOption,
      placeholder: "请选择模板"
    }
  }, _vm._l(_vm.templateEntities2, function (i) {
    return _c("a-select-option", {
      key: i.id,
      attrs: {
        value: i.id
      }
    }, [_vm._v(_vm._s(i.name))]);
  }), 1)], 1)], 1)]) : _vm._e(), _c("div", {
    class: "".concat(_vm.isJF ? "mt24" : ""),
    staticStyle: {
      "text-align": "center"
    }
  }, [!_vm.disabled ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveData
    }
  }, [_vm._v(_vm._s(_vm.editor ? "确认" : "确认添加"))]) : _vm._e()], 1)], 2)], 1), _vm.visibleFuwufankui && _vm.$route.query.id ? _c("a-tab-pane", {
    key: "服务反馈",
    attrs: {
      tab: "服务反馈"
    }
  }, [_c("Fuwufankui", {
    attrs: {
      companyId: _vm.$route.query.id
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };