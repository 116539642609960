var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-modal", {
    attrs: {
      title: "商保记录",
      width: "1000px",
      closable: "",
      footer: false
    },
    on: {
      cancel: _vm.onClose
    },
    model: {
      value: _vm.modalVisible,
      callback: function callback($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_vm.target ? _c("div", {
    staticClass: "mb16 flex-center"
  }, [_c("span", {
    staticClass: "mr8 fs16 fw500"
  }, [_vm._v(_vm._s(_vm.target.empName))]), _c("span", [_vm._v(_vm._s(_vm.target.idCard))])]) : _vm._e(), _c("a-table", {
    attrs: {
      columns: _vm.columnsRecordList,
      dataSource: _vm.recordLst,
      pagination: false,
      scroll: {
        x: true
      },
      rowKey: "id",
      size: "middle"
    },
    scopedSlots: _vm._u([{
      key: "schemeName",
      fn: function fn(text, record) {
        return _c("div", {
          staticStyle: {
            position: "relative"
          }
        }, [record.paymentType === "年保" ? _c("img", {
          staticStyle: {
            width: "45px",
            height: "46px",
            position: "absolute",
            top: "-12px",
            left: "-8px"
          },
          attrs: {
            src: require("./nianbao.png")
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(text) + " ")]);
      }
    }, {
      key: "dateRange",
      fn: function fn(text, record) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(record.beginTime)) + "-" + _vm._s(_vm.formatDate(record.endTime)) + " ")];
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return [_c("a-space", [_c("span", {
          staticClass: "pointer tc-theme",
          on: {
            click: function click($event) {
              return _vm.editItem(record);
            }
          }
        }, [_vm._v("编辑")]), _c("a-popconfirm", {
          attrs: {
            title: "你确定要删除该条商保记录吗？"
          },
          on: {
            confirm: function confirm($event) {
              return _vm.deleteItem(record.id);
            }
          }
        }, [_c("span", {
          staticClass: "pointer tc-theme"
        }, [_vm._v("删除")])])], 1)];
      }
    }])
  })], 1), _c("SelectConfirm", {
    attrs: {
      target: _vm.currentItem
    },
    on: {
      confirm: _vm.editConfirm
    },
    model: {
      value: _vm.editVisible,
      callback: function callback($$v) {
        _vm.editVisible = $$v;
      },
      expression: "editVisible"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };