import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-miH page-bgBack"
  }, [_c("rTitle", {
    attrs: {
      type: "center",
      tit: "招工企业管理"
    }
  }), _c("div", {
    staticClass: "xq-form-wrapper",
    staticStyle: {
      padding: "24px"
    }
  }, [_c("div", {
    staticClass: "xq-form-search"
  }, [_c("a-input-search", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      allowClear: "",
      placeholder: "搜索招工企业名称"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c("div", {
    staticStyle: {
      "text-align": "right",
      "margin-top": "-32px"
    }
  }, [_c("a-space", [_c("a-button", {
    staticClass: "mr4",
    on: {
      click: function click($event) {
        _vm.batchSelectVisible = true;
      }
    }
  }, [_vm._v(" 同步企业数据 ")]), _c("a-button", {
    attrs: {
      type: "primary",
      icon: "file-text"
    },
    on: {
      click: _vm.onShowModal
    }
  }, [_vm._v(" 新建企业 ")])], 1)], 1)], 1), _c("a-spin", {
    staticClass: "xq-form-content",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("XqTable", {
    staticClass: "xq-form-content-table",
    attrs: {
      customize: _vm.FormJson["config"]["table"]["customize"],
      scroll: {
        x: _vm.columns.length * 150
      },
      pagination: false,
      dataSource: _vm.tableData,
      columns: _vm.columns,
      "row-selection": _vm.FormJson["config"]["table"]["extend"]["batch"] ? {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      } : null
    },
    on: {
      change: _vm.onTableChange
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (col, index) {
      return {
        key: "xq_".concat(col.dataIndex),
        fn: function fn() {
          return [_c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(col.customTitle) + " ")])];
        },
        proxy: true
      };
    }), _vm._l(_vm.columns, function (col, index) {
      return {
        key: col.dataIndex,
        fn: function fn(text, record) {
          return [col.dataIndex === "gmtCreate" ? _c("span", {
            key: index
          }, [_vm._v(" " + _vm._s(_vm.moment(text).format("YYYY-MM-DD")) + " ")]) : col.dataIndex === "personSize" ? _c("span", {
            key: index
          }, [text === 1 ? _c("span", [_vm._v("0-20人")]) : _vm._e(), text === 2 ? _c("span", [_vm._v("20-99人")]) : _vm._e(), text === 3 ? _c("span", [_vm._v("99-200人")]) : _vm._e(), text === 4 ? _c("span", [_vm._v("200-500人")]) : _vm._e(), text === 5 ? _c("span", [_vm._v("500-1000人")]) : _vm._e(), text === 6 ? _c("span", [_vm._v("1000人以上")]) : _vm._e()]) : col.dataIndex === "sourceType" ? _c("span", {
            key: index
          }, [text == 1 ? _c("span", [_vm._v("手动添加")]) : _vm._e(), text == 2 ? _c("span", [_vm._v("同步数据")]) : _vm._e()]) : col.dataIndex === "tableAction" ? _c("span", {
            key: index
          }, [_c("a", {
            on: {
              click: function click($event) {
                return _vm.showUpdateModal(record);
              }
            }
          }, [_vm._v("编辑")]), _c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.deleteRecord(record);
              }
            }
          }, [_vm._v("删除")])], 1) : _c("XqTableCell", {
            key: index,
            attrs: {
              record: record,
              field: _vm.FormJson["fields"][col.dataIndex],
              dynamicData: _vm.dynamicData
            }
          })];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "xq-form-content-pagination"
  }, [_c("div", {
    staticClass: "xq-form-content-pagination-total"
  }, [_vm._v("共 " + _vm._s(_vm.total) + " 条")]), _c("a-pagination", {
    attrs: {
      "page-size": _vm.pageSize,
      total: _vm.total,
      "show-size-changer": true,
      "show-quick-jumper": true,
      "page-size-options": ["10", "20", "50", "100"]
    },
    on: {
      change: _vm.onPageChange,
      showSizeChange: _vm.onPageSizeChange
    },
    model: {
      value: _vm.pageNo,
      callback: function callback($$v) {
        _vm.pageNo = $$v;
      },
      expression: "pageNo"
    }
  })], 1)], 1)], 1), _c("delModal", {
    attrs: {
      visible: _vm.delVisible,
      title: "确定删除该招工企业吗？",
      desc: "删除后将关闭该单位的所有招聘岗位，且无法重新打开",
      okText: "删除"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.delVisible = $event;
      },
      onOk: _vm.onOk
    }
  }), _c("batchSelectModal", {
    attrs: {
      visible: _vm.batchSelectVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.batchSelectVisible = $event;
      },
      upDate: _vm.onGetCompanyUnits
    }
  }), _c("laborUnitModel", {
    attrs: {
      visible: _vm.visible,
      mType: _vm.mType,
      id: _vm.id,
      dataJson: _vm.dataJson
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      upDate: _vm.onGetCompanyUnits
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };