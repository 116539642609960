import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.loading
    }
  }, [_vm.pageState === "company" && !_vm.$hasPermissionSecond("insurance:insuranceService.auditList") ? _c("a-result", {
    attrs: {
      status: "403",
      title: "暂无权限",
      "sub-title": "您没有商业保险公司端的访问权限"
    }
  }) : _vm.pageState === "customer" && !_vm.orderList.length && _vm.isInit ? _c("div", [_c("div", {
    staticClass: "no-payslip"
  }, [_c("img", {
    staticClass: "autoimg",
    attrs: {
      src: require("@/assets/img/payslip/beijing.png")
    }
  }), _c("div", {
    staticClass: "cent"
  }, [_c("h2", [_vm._v("商保服务 全新来袭")]), _c("h3", [_vm._v("办理商保，上传投保人员明细表，实现查看全流程投保审核记录")]), _vm.$hasPermission("insuranceAddService:insuranceAddService.insuranceManager:shangbaobanli") ? _c("a-button", {
    staticClass: "mg_t20",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goAdd
    }
  }, [_vm._v(" 创建商保订单 "), _c("a-icon", {
    attrs: {
      type: "right"
    }
  })], 1) : _vm._e()], 1)])]) : _c("div", {
    staticClass: "page-miH"
  }, [_vm.pageState === "company" ? _c("div", {
    staticStyle: {
      display: "flex",
      padding: "8px 0 24px 0"
    }
  }, [_c("div", {
    class: _vm.tabButtonWait ? "tabButton" : "tabButtonNot",
    staticStyle: {
      "margin-left": "0"
    },
    on: {
      click: function click($event) {
        return _vm.callback("0");
      }
    }
  }, [_vm._v("待核保审核订单")]), _c("div", {
    class: _vm.tabButtonAlready ? "tabButton" : "tabButtonNot",
    on: {
      click: function click($event) {
        return _vm.callback("1");
      }
    }
  }, [_vm._v("已核保审核订单")])]) : _vm._e(), _c("div", {
    staticClass: "pageBox"
  }, [_c("div", [_vm.pageState === "customer" || _vm.pageState === "company" && _vm.tabButtonAlready ? _c("div", {
    staticClass: "contentTitle"
  }, [_vm._v("商保月度统计")]) : _vm._e(), _vm.pageState === "customer" || _vm.pageState === "company" && _vm.tabButtonAlready ? _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "statisticalBox"
  }, [_c("div", {
    staticClass: "statisticalItem",
    staticStyle: {
      "justify-content": "center"
    }
  }, [_c("a-button", {
    staticStyle: {
      color: "#a9a9a9"
    },
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.dateChange(1);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "caret-left"
    }
  })], 1), _c("div", {
    staticStyle: {
      "font-size": "24px",
      "font-weight": "bold",
      color: "#3c3d43"
    }
  }, [_vm._v(_vm._s(_vm.moment(_vm.dynamicDate).format("YYYY-MM")))]), _c("a-button", {
    staticStyle: {
      color: "#a9a9a9"
    },
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.dateChange(2);
      }
    }
  }, [_c("a-icon", {
    attrs: {
      type: "caret-right"
    }
  })], 1)], 1), _c("div", {
    staticClass: "statisticalItem",
    staticStyle: {
      "border-left": "1px solid #e8e8e8",
      "padding-left": "42px"
    }
  }, [_c("img", {
    staticClass: "statisticalIcon",
    attrs: {
      src: require("@/assets/img/payslip/shangbaoIcon1.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-left": "24px"
    }
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("月成功投保人数")]), _c("div", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.topDetail.successInsNumber))])])]), _c("div", {
    staticClass: "statisticalItem",
    staticStyle: {
      "border-left": "1px solid #e8e8e8",
      "padding-left": "42px"
    }
  }, [_c("img", {
    staticClass: "statisticalIcon",
    attrs: {
      src: require("@/assets/img/payslip/shangbaoIcon2.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-left": "24px"
    }
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("月度金额合计")]), _c("div", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.topDetail.totalAmount))])])])]), _vm.pageState === "company" ? _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "padding-left": "42px",
      "margin-left": "16px",
      width: "306px",
      height: "100px",
      background: "#ffffff",
      "border-radius": "8px"
    }
  }, [_c("img", {
    staticClass: "statisticalIcon",
    attrs: {
      src: require("@/assets/img/payslip/cooperationCompany.png")
    }
  }), _c("div", {
    staticStyle: {
      "margin-left": "24px"
    }
  }, [_c("div", {
    staticStyle: {
      height: "14px",
      "font-size": "14px",
      "font-weight": "400",
      color: "#a6a8b4"
    }
  }, [_vm._v("已合作公司")]), _c("div", {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "bold",
      color: "#55565d",
      "margin-top": "8px"
    }
  }, [_vm._v(_vm._s(_vm.topDetail.cooperateCompanyCount))])])]) : _vm._e()]) : _vm._e()]), _vm.pageState === "customer" || _vm.pageState === "company" && _vm.tabButtonAlready ? _c("div", {
    staticClass: "contentTitle"
  }, [_vm._v("商保列表")]) : _vm._e(), _c("div", {
    staticClass: "headBox"
  }, [_c("div", {
    staticClass: "selectBox"
  }, [_vm.pageState === "company" ? _c("a-input-search", {
    staticStyle: {
      width: "200px",
      "margin-right": "12px"
    },
    attrs: {
      allowClear: "",
      placeholder: "搜索人资公司"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.searchForm.customerName,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "customerName", $$v);
      },
      expression: "searchForm.customerName"
    }
  }) : _vm._e(), _vm.pageState === "customer" ? _c("a-select", {
    staticStyle: {
      width: "200px",
      "margin-right": "12px"
    },
    attrs: {
      allowClear: "",
      placeholder: "当前投保状态"
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.searchForm.state,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "state", $$v);
      },
      expression: "searchForm.state"
    }
  }, _vm._l(_vm.selectList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1) : _vm._e(), _vm.pageState === "company" && _vm.defaultKey !== "0" ? _c("a-select", {
    staticStyle: {
      width: "200px",
      "margin-right": "12px"
    },
    attrs: {
      allowClear: "",
      placeholder: "当前投保状态"
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.searchForm.state,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "state", $$v);
      },
      expression: "searchForm.state"
    }
  }, _vm._l(_vm.selectListForCompany, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1) : _vm._e(), _c("a-input-search", {
    staticStyle: {
      width: "200px",
      "margin-right": "12px"
    },
    attrs: {
      allowClear: "",
      placeholder: "搜索保险方案"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.searchForm.schemeName,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "schemeName", $$v);
      },
      expression: "searchForm.schemeName"
    }
  }), _c("a-input-search", {
    staticStyle: {
      width: "200px",
      "margin-right": "12px"
    },
    attrs: {
      allowClear: "",
      placeholder: "搜索订单编号"
    },
    on: {
      search: _vm.onSearch
    },
    model: {
      value: _vm.searchForm.orderIndex,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "orderIndex", $$v);
      },
      expression: "searchForm.orderIndex"
    }
  }), _c("a-popover", {
    staticStyle: {
      cursor: "pointer"
    },
    attrs: {
      trigger: "click",
      placement: "bottom"
    }
  }, [_c("div", {
    staticStyle: {
      width: "785px",
      height: "80px"
    },
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("a-range-picker", {
    staticStyle: {
      "margin-left": "12px",
      "margin-top": "24px",
      width: "280px"
    },
    attrs: {
      valueFormat: "YYYY-MM-DD",
      placeholder: ["保险生效日开始", "保险生效日结束"]
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.rangeDate,
      callback: function callback($$v) {
        _vm.rangeDate = $$v;
      },
      expression: "rangeDate"
    }
  }), _c("a-select", {
    staticStyle: {
      "margin-left": "12px",
      "margin-top": "24px",
      width: "160px"
    },
    attrs: {
      placeholder: "请选择保险公司",
      "allow-clear": true
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.insCompanyId,
      callback: function callback($$v) {
        _vm.insCompanyId = $$v;
      },
      expression: "insCompanyId"
    }
  }, _vm._l(_vm.insuranceList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.insCompanyId
      }
    }, [_vm._v(_vm._s(item.companyName))]);
  }), 1), _c("a-range-picker", {
    staticStyle: {
      "margin-left": "12px",
      "margin-top": "24px",
      width: "280px"
    },
    attrs: {
      valueFormat: "YYYY-MM-DD",
      placeholder: ["保险失效日开始", "保险失效日结束"]
    },
    on: {
      change: _vm.onSearch
    },
    model: {
      value: _vm.rangeDate2,
      callback: function callback($$v) {
        _vm.rangeDate2 = $$v;
      },
      expression: "rangeDate2"
    }
  })], 1), _vm.pageState === "customer" ? _c("a-button", [_c("a-icon", {
    attrs: {
      type: "filter"
    }
  })], 1) : _vm._e()], 1)], 1), _c("div", {
    staticClass: "funcBox"
  }, [_vm.pageState !== "company" && _vm.$hasPermission("insuranceAddService:insuranceAddService.insuranceManager:shangbaobanli") ? _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goAdd
    }
  }, [_c("a-icon", {
    attrs: {
      type: "plus"
    }
  }), _vm._v("新增商保订单")], 1) : _vm._e()], 1)]), _vm.orderList && _vm.orderList.length ? _c("div", {
    staticClass: "contentBox"
  }, _vm._l(_vm.orderList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "orderItem"
    }, [_c("div", {
      staticClass: "top"
    }, [_c("div", {
      staticStyle: {
        display: "flex",
        "justify-content": "space-between"
      }
    }, [_c("div", [_c("span", {
      staticClass: "label"
    }, [_vm._v("订单编号：")]), _c("span", {
      staticClass: "value"
    }, [_vm._v(_vm._s(item.id))])]), _c("div", [_c("span", {
      staticClass: "label"
    }, [_vm._v("最新提交时间：")]), _c("span", {
      staticClass: "value"
    }, [_vm._v(_vm._s(_vm.moment(item.latestSubmitTime).format("YYYY-MM-DD HH:mm:ss")))])])]), _c("div", {
      staticStyle: {
        padding: "18px 0",
        display: "flex",
        "justify-content": "space-between"
      }
    }, [_c("div", [_c("div", {
      staticStyle: {
        display: "flex",
        "align-items": "center"
      }
    }, [_c("div", {
      staticStyle: {
        "font-size": "18px",
        "font-weight": "500",
        color: "#3c3d43"
      }
    }, [_vm._v(_vm._s(item.schemeName))]), _c("div", {
      staticStyle: {
        "margin-left": "12px"
      }
    }, [_c("div", {
      style: [{
        padding: "0 8px 0 8px"
      }, {
        height: "22px"
      }, {
        background: "".concat(_vm.selectList[item.state].background)
      }, {
        borderRadius: "2px"
      }, {
        fontSize: "12px"
      }, {
        border: "1px solid ".concat(_vm.selectList[item.state].color)
      }, {
        color: "".concat(_vm.selectList[item.state].color)
      }, {
        textAlign: "center"
      }, {
        lineHeight: "20px"
      }]
    }, [_vm._v(" " + _vm._s(_vm.selectList[item.state].name) + " ")])])]), _c("div", {
      staticStyle: {
        "margin-top": "6px"
      }
    }, [_c("span", {
      staticClass: "label"
    }, [_vm._v(_vm._s(_vm.pageState === "company" ? "人资公司：" : "保险公司："))]), _c("span", {
      staticClass: "value"
    }, [_vm._v(_vm._s(_vm.pageState === "company" ? item.customerName : item.companyName))]), _c("span", {
      staticClass: "label",
      staticStyle: {
        "margin-left": "120px"
      }
    }, [_vm._v("保险周期：")]), _c("span", {
      staticClass: "value"
    }, [_vm._v(_vm._s(_vm.moment(item.insEffectTime).format("YYYY-MM-DD") + "~" + _vm.moment(item.insFailureTime).format("YYYY-MM-DD")))])])]), _c("div"), _c("div", [_vm.$hasPermission("insuranceAddService:insuranceAddService.insuranceManager:detail") && _vm.pageState === "customer" || _vm.$hasPermission("insurance:insuranceService.auditList:detail") && _vm.pageState === "company" ? _c("a-button", {
      attrs: {
        type: "primary"
      },
      on: {
        click: function click($event) {
          return _vm.goDetail(item);
        }
      }
    }, [_vm._v("查看详情")]) : _vm._e()], 1)])]), item.state === 3 || item.state === 4 || item.state === 5 ? _c("div", {
      staticClass: "bottom"
    }, [_c("div", {
      staticStyle: {
        width: "100%",
        height: "38px",
        display: "flex",
        "align-items": "flex-end",
        "justify-content": "space-between",
        "font-size": "14px",
        "font-weight": "400",
        color: "#a6a8b4",
        "padding-bottom": "4px"
      }
    }, [_c("div", [_c("span", {
      staticClass: "label"
    }, [_vm._v("费用合计：")]), _c("span", {
      staticClass: "value",
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v(_vm._s(_vm.pageState === "company" ? item.actualTotalFee : item.totalFee))])]), !item.foldState ? _c("a-button", {
      staticStyle: {
        "margin-bottom": "-6px"
      },
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          return _vm.folding(false, item);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "down"
      }
    }), _vm._v("展开详情")], 1) : _vm._e(), item.foldState ? _c("a-button", {
      staticStyle: {
        "margin-bottom": "-6px"
      },
      attrs: {
        type: "link"
      },
      on: {
        click: function click($event) {
          return _vm.folding(true, item);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "up"
      }
    }), _vm._v("收起详情")], 1) : _vm._e()], 1), item.foldState ? _c("div", {
      staticClass: "typeBox"
    }, _vm._l(item.personTypeList, function (item, index) {
      return _c("div", {
        key: index,
        staticClass: "typeItem"
      }, [_c("a-tooltip", [_c("template", {
        slot: "title"
      }, [_vm._v(" " + _vm._s(item.typeName) + " ")]), _c("div", {
        staticStyle: {
          "font-size": "14px",
          "font-weight": "500",
          color: "#55565d",
          width: "98px",
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden"
        }
      }, [_vm._v(" " + _vm._s(item.typeName) + " ")])], 2), _c("div", {
        staticClass: "bottomItem"
      }, [_c("span", {
        staticClass: "label"
      }, [_vm._v("投保人数：")]), _c("span", {
        staticClass: "value"
      }, [_vm._v(_vm._s(item.personCount ? item.personCount : 0) + "人")])]), _c("div", {
        staticClass: "bottomItem"
      }, [_c("span", {
        staticClass: "label"
      }, [_vm._v(_vm._s(_vm.pageState === "company" ? "实际保险月费：" : "保险月费："))]), _c("span", {
        staticClass: "value"
      }, [_vm._v(_vm._s(_vm.pageState === "company" ? item.realityMonthAmount : item.monthAmount))])])], 1);
    }), 0) : _vm._e()]) : _vm._e()]);
  }), 0) : _c("div", {
    staticStyle: {
      "padding-top": "120px"
    }
  }, [_c("a-empty")], 1), _vm.orderList && _vm.orderList.length ? _c("div", {
    staticStyle: {
      width: "100%",
      display: "flex",
      padding: "24px 0 24px 24px",
      "justify-content": "flex-end",
      "align-items": "center"
    }
  }, [_c("a-pagination", {
    attrs: {
      pageSize: 5,
      total: _vm.total
    },
    on: {
      change: _vm.onPageChange
    },
    model: {
      value: _vm.current,
      callback: function callback($$v) {
        _vm.current = $$v;
      },
      expression: "current"
    }
  })], 1) : _vm._e()])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };