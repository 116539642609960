import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _interopRequireWildcard from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import moment from "moment";
import { mapState, mapMutations, mapGetters } from "vuex";
import { getMonthlySalary, generateWageList } from "@/api/wages/monthly";
import ExportMixin from "xqjr-plugin-common/UploadOrExport/exportMixin";
import axios from "axios";
import qs from "qs";
var mixin = new ExportMixin();
export default {
  mixins: [mixin],
  name: "AddPayslip",
  components: {
    step: function step() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./step"));
      });
    },
    excelCalback: function excelCalback() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./excel-calback"));
      });
    }
  },
  data: function data() {
    return {
      salaryTemplateId: null,
      monthly: null,
      yearly: null,
      dataList: [],
      pageState: this.$route.query.item,
      selectDate: undefined,
      tableName: undefined,
      tableName2: "",
      action: "",
      columns: [{
        key: "empName",
        title: "姓名",
        width: 150,
        dataIndex: "empName",
        fixed: "left"
      }, {
        title: "企业员工",
        key: "empInCompany",
        dataIndex: "empInCompany",
        width: 150,
        scopedSlots: {
          customRender: "empInCompany"
        }
      }, {
        title: "手机",
        dataIndex: "phoneStatus",
        key: "phoneStatus",
        width: 150,
        scopedSlots: {
          customRender: "phoneStatus"
        }
      }, {
        title: "微信",
        dataIndex: "wechatStatus",
        key: "wechatStatus",
        width: 150,
        scopedSlots: {
          customRender: "wechatStatus"
        }
      }, {
        title: "手机号",
        dataIndex: "phone",
        key: "phone",
        width: 150
      }, {
        title: "基本工资",
        dataIndex: "salaryBasic",
        key: "salaryBasic",
        width: 150
      }, {
        title: "个人社保",
        dataIndex: "empSoc",
        key: "empSoc",
        width: 150
      }, {
        title: "实发工资",
        dataIndex: "salaryReal",
        key: "salaryReal",
        width: 150
      }],
      titles: {},
      cbParams: {
        pageNo: 1,
        reloadCount: true,
        pageSize: 10
      },
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      salary: [],
      excelVisible: false,
      excelOptions: {},
      hide: false,
      form: this.$form.createForm(this, {
        name: "coordinated"
      }),
      params: {
        month: "",
        year: "",
        tableName: ""
      },
      tableId: "",
      itemsLst: [],
      itemsLstSelected: []
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(["hr"])), mapGetters({
    PAYSLIP: "wages/PAYSLIP"
  })), {}, {
    disabled: function disabled() {
      return !(this.tableName !== undefined && this.selectDate !== undefined);
    }
  }),
  methods: _objectSpread(_objectSpread({
    loadItems: function loadItems() {
      var _this = this;
      this.$request({
        url: "/salary/api/v1/salaryTemplateSalaries/selectByHeaderListId?salaryTemplateSalaryId=".concat(this.salaryTemplateId)
      }).then(function (res) {
        _this.itemsLstSelected = res.data;
        _this.itemsLst = res.data.map(function (item) {
          return {
            label: item,
            value: item,
            disabled: item === "实发工资" || item === "应发工资"
          };
        });
      }).catch(function () {});
    },
    selectChange: function selectChange(e) {
      this.salaryTemplateId = e.key;
      this.tableName = e.label;
      this.loadItems();
    },
    generateWage: function generateWage() {
      var _this2 = this;
      generateWageList({
        companyId: this.hr.companyId,
        monthly: this.monthly,
        salaryTemplateSalaryId: parseInt(this.salaryTemplateId),
        yearly: this.yearly,
        tableName: this.tableName,
        itemNameList: this.itemsLstSelected
      }).then(function (res) {
        // localStorage.setItem("tempWagePayroll", JSON.stringify(this.itemsLstSelected));
        _this2.tableId = res.data;
        _this2.excelChange();
      });
    },
    getMonthlySalaryList: function getMonthlySalaryList(e) {
      var _this3 = this;
      this.dataList = [];
      this.tableName = undefined;
      this.monthly = moment(e).format("MM");
      this.yearly = moment(e).format("YYYY");
      if (this.pageState === "2") {
        getMonthlySalary({
          companyId: this.hr.companyId,
          monthly: this.monthly,
          yearly: this.yearly,
          pageNo: 1,
          pageSize: 100
        }).then(function (res) {
          _this3.dataList = res.data.entities;
        });
      }
    }
  }, mapMutations({
    getSuccessList: "wages/successList",
    cbData: "wages/cbData",
    tableIds: "wages/tableId",
    postParams: "wages/postParams",
    resetPostParams: "wages/resetPostParams"
  })), {}, {
    // 导入工资表
    onUpload: function onUpload() {
      var _this$selectDate$form = this.selectDate.format("YYYY-MM").split("-"),
        _this$selectDate$form2 = _slicedToArray(_this$selectDate$form, 2),
        year = _this$selectDate$form2[0],
        month = _this$selectDate$form2[1];
      this.action = "/hr/api/v1/salaryBills/import?companyId=".concat(this.hr.companyId, "&month=").concat(month, "&year=").concat(year, "&tableName=").concat(this.tableName);
      this.params.year = year;
      this.params.month = month;
    },
    cbUpload: function cbUpload(e) {
      if (e.file) {
        if (e.file.response && e.file.response.data) {
          var data = e.file.response.data;
          this.tableId = data.tableId;
          var response = e.file.response;
          if (response && response.data && response.data) {
            this.onUploadChange(response.data);
          }
        } else {
          if (e.file.response && e.file.response.msg) {
            this.$notification["error"]({
              message: e.file.response.msg
            });
          }
        }
      }
    },
    onUploadChange: function onUploadChange(data) {
      this.excelVisible = true;
      this.excelOptions = data;
      this.tableId = data.tableId;
    },
    // 上传成功的excel
    successExcel: function successExcel() {
      var _this4 = this;
      this.$request({
        url: "/hr/api/v1/salaryBills/pageRelation",
        params: _objectSpread(_objectSpread({
          companyId: this.hr.companyId,
          tableId: this.tableId
        }, this.cbParams), {}, {
          pageSize: this.pagination.pageSize
        })
      }).then(function (_ref) {
        var data = _ref.data;
        //  this.cbData(data);
        _this4.titles = {
          countAll: data.countAll,
          countInCompany: data.countInCompany,
          countNotInCompany: data.countNotInCompany,
          countWeChatBind: data.countWeChatBind,
          countWeChatUnBind: data.countWeChatUnBind,
          sumSalaryAmount: data.sumSalaryAmount,
          sumSalaryReal: data.sumSalaryReal
        };
        _this4.salary = data.salaryBillListVOPage.entities || [];
        // this.getSuccessList(this.salary);
        _this4.pagination.total = data.salaryBillListVOPage.entityCount || 0;
        _this4.salary.forEach(function (item) {
          item.salaryBillFieldsDOList.forEach(function (key) {
            key.dataIndex = key.sort;
          });
        });
        var colum = [];
        _this4.salary[0].salaryBillFieldsDOList.map(function (key) {
          colum.push({
            dataIndex: key.sort,
            title: key.fieldName,
            width: 150,
            key: key.sort
          });
        });
        _this4.salary = _this4.salary.map(function (item) {
          var obj = {};
          item.salaryBillFieldsDOList.map(function (key) {
            obj[key["dataIndex"]] = key["fieldValue"];
          });
          item = _objectSpread(_objectSpread({}, item), obj);
          return item;
        });
        _this4.columns = [].concat(_toConsumableArray(_this4.columns), colum);
      });
    },
    // excelCalback
    excelChange: function excelChange(e) {
      if (e && e.visibleWrong) {
        this.excelVisible = e.visibleWrong;
      } else {
        this.excelVisible = false;
        this.$router.push({
          path: "add-payslip-sort",
          query: {
            tableId: this.tableId,
            month: this.monthly,
            year: this.yearly,
            sendName: this.tableName
          }
        });
      }
    },
    // 下载模板
    // downLoad() {
    //   const link = document.createElement("a");
    //   const MIME_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    //   link.download = "工资导入模板";
    //   link.href =
    //     "https://hjrl-public.oss-cn-qingdao.aliyuncs.com/excel_template/emp_info/salaryBill/%E4%B8%8A%E4%BC%A0%E5%B7%A5%E8%B5%84%E8%A1%A8%E6%A8%A1%E7%89%88-.xlsx";
    //   link.dataset.downloadurl = [MIME_TYPE, link.download, link.href].join(":");
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // },
    downLoad: function downLoad() {
      var token = this.$store.state.auth.token;
      var params = {};
      params = {
        templateName: "electronicPayrollTemplate.xlsx"
      };
      axios({
        url: "/hr/api/v1/excel/template/download",
        params: params,
        paramsSerializer: function paramsSerializer(param) {
          return qs.stringify(param, {
            indices: false
          });
        },
        method: "get",
        responseType: "blob",
        headers: {
          token: token
        }
      }).then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res.data], {
          type: "multipart/form-data"
        });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "上传工资表模板.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    // 表单提交
    handleSubmit: function handleSubmit(e) {
      var _this5 = this;
      this.form.validateFields(function (err, values) {
        if (!err) {
          values.selectDate = values.selectDate.format("YYYY-MM");
          _this5.params.tableName = values.tableName;
          _this5.params.year = values.selectDate.split("-")[0];
          _this5.params.month = values.selectDate.split("-")[1];
          _this5.hide = !_this5.hide;
          _this5.postParams({
            sendName: values.tableName,
            month: _this5.params.month,
            year: _this5.params.year
          });
        }
      });
    },
    // 重新上传
    reUpload: function reUpload() {
      this.salary = [];
      this.tableName = undefined;
      this.selectDate = undefined;
    },
    // 翻页
    handlePage: function handlePage(e) {
      var _this6 = this;
      this.pagination.current = e.current;
      this.cbParams.pageNo = e.current;
      this.$request({
        url: "/hr/api/v1/salaryBills/pageRelation",
        params: _objectSpread({
          companyId: this.hr.companyId,
          tableId: this.tableId
        }, this.cbParams)
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this6.titles = {
          countAll: data.countAll,
          countInCompany: data.countInCompany,
          countNotInCompany: data.countNotInCompany,
          countWeChatBind: data.countWeChatBind,
          countWeChatUnBind: data.countWeChatUnBind,
          sumSalaryAmount: data.sumSalaryAmount,
          sumSalaryReal: data.sumSalaryReal
        };
        _this6.salary = data.salaryBillListVOPage.entities || [];
        _this6.pagination.total = data.salaryBillListVOPage.entityCount || 0;
        _this6.getSuccessList(_this6.salary);
      });
    },
    // 下一页
    next: function next() {
      this.$router.push({
        path: "/wages/add-payslip-second",
        query: {
          unSendCount: this.pagination.total
        }
      });
    },
    // 筛选
    selectItem: function selectItem(type) {}
  })
};