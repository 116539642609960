import _toConsumableArray from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "D:/tools/frontend_workspace/e-hr-master/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import ProcessItem from "./processItem";
export default {
  name: "",
  components: {
    ProcessItem: ProcessItem
  },
  mixins: [],
  props: {
    isCreate: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isRendered: true,
      processes: [],
      nameNew: "",
      idxEdit: null,
      modalVisible: false,
      tempInfo: null
    };
  },
  computed: {
    modalTitle: function modalTitle() {
      return this.idxEdit || this.idxEdit === 0 ? "编辑流程" : "添加流程";
    },
    fieldKey: function fieldKey() {
      return this.isCreate ? "formGroupFieldAddList" : "formGroupFieldUpdateList";
    }
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    getValues: function getValues() {
      var _this = this;
      var children = this.processes.map(function (item, idx) {
        return _objectSpread(_objectSpread({
          moduleCode: "processChild",
          formType: "dycl",
          subjectType: "site",
          parentId: "0"
        }, item), {}, _defineProperty({
          sort: idx + 1,
          moduleName: item.name
        }, "".concat(_this.fieldKey), item.fields.map(function (li, idx_li) {
          return _objectSpread(_objectSpread({
            moduleCode: "processChild",
            subjectType: "site",
            parentId: "0"
          }, li), {}, {
            sort: idx_li + 1,
            moduleName: item.name
          });
        })));
      });
      console.log(children);
      children.forEach(function (item) {
        delete item.name;
        delete item.fields;
      });
      children.unshift(_objectSpread({
        moduleName: "处理流程",
        moduleCode: "process",
        formType: "dycl",
        subjectType: "site",
        parentId: "0"
      }, this.tempInfo));
      return children;
    },
    setValues: function setValues(initValues) {
      var _this2 = this;
      this.$nextTick(function () {
        var temp = _objectSpread({}, initValues.formGroupTree[2]);
        _this2.processes = temp.childList.map(function (item) {
          var itemNew = _objectSpread(_objectSpread({}, item), {}, {
            name: item.moduleName,
            fields: item.formGroupFieldList
          });
          delete itemNew.formGroupFieldList;
          return itemNew;
        });
        delete temp.childList;
        _this2.tempInfo = temp;
      });
    },
    onUpdateItem: function onUpdateItem(idx, item) {
      var tempLst = _toConsumableArray(this.processes);
      tempLst[idx] = _objectSpread(_objectSpread({}, tempLst[idx]), item);
      this.updateProcesses(tempLst);
    },
    delProcessItem: function delProcessItem(idx) {
      var tempLst = _toConsumableArray(this.processes);
      tempLst.splice(idx, 1);
      this.updateProcesses(tempLst);
    },
    editProcessItem: function editProcessItem(idx) {
      this.idxEdit = idx;
      this.nameNew = this.processes[idx].name;
      this.modalVisible = true;
    },
    addProcessItem: function addProcessItem() {
      this.idxEdit = null;
      this.nameNew = "";
      this.modalVisible = true;
    },
    handleOk: function handleOk() {
      if (this.nameNew) {
        if (this.idxEdit || this.idxEdit === 0) {
          this.onUpdateItem(this.idxEdit, _objectSpread(_objectSpread({}, this.processes[this.idxEdit]), {}, {
            name: this.nameNew
          }));
        } else {
          var tempLst = _toConsumableArray(this.processes);
          tempLst.push({
            name: this.nameNew,
            fields: []
          });
          this.updateProcesses(tempLst);
        }
        this.modalVisible = false;
      }
    },
    updateProcesses: function updateProcesses(lstNew) {
      var _this3 = this;
      this.isRendered = false;
      this.processes = lstNew;
      this.$nextTick(function () {
        _this3.isRendered = true;
      });
    }
  }
};