var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "templateCss"
  }, [_c("div", [_c("a-row", {
    ref: "dataInfo",
    staticClass: "rigBox1",
    attrs: {
      id: "target1"
    }
  }, [_c("a-col", {
    staticClass: "hei56",
    attrs: {
      span: 1
    }
  }), _c("a-col", {
    staticClass: "hei56",
    attrs: {
      span: 17
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.form1,
      "label-col": {
        span: 7
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "模板标题"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入模板标题!"
        }]
      }],
      expression: "['name', { rules: [{ required: true, message: '请输入模板标题!' }] }]"
    }],
    attrs: {
      disabled: _vm.status ? true : false
    }
  }), _c("div", {
    staticClass: "form1Class"
  }, [_vm._v("*标题仅做管理使用，不在员工填写页面展示")])], 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 6
    }
  })], 1)], 1), _c("div", [_c("a-form", {
    attrs: {
      form: _vm.form,
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 14
      }
    }
  }, [_c("Draggable", {
    attrs: {
      group: "form-draggable",
      animation: "300"
    },
    on: {
      add: _vm.onDragAdd,
      end: _vm.onDragEnd
    },
    model: {
      value: _vm.sortedKeys,
      callback: function callback($$v) {
        _vm.sortedKeys = $$v;
      },
      expression: "sortedKeys"
    }
  }, _vm._l(_vm.sortedKeys, function (item) {
    return _c("div", {
      key: item,
      staticStyle: {
        cursor: "move"
      }
    }, [_c("div", [_c("a-row", {
      staticClass: "rigBox"
    }, [_c("a-col", {
      staticClass: "hei56",
      attrs: {
        span: 1
      }
    }, [_c("a-icon", {
      staticClass: "tableIcon",
      attrs: {
        type: "unordered-list"
      }
    })], 1), _c("a-col", {
      staticClass: "hei56",
      attrs: {
        span: 21
      }
    }, [_vm.formFields[item] ? _c("div", {
      staticStyle: {
        display: "flex",
        "align-items": "center"
      }
    }, [_c("div", {
      staticStyle: {
        "margin-top": "9px",
        flex: "1"
      }
    }, [_c("XqItem", {
      ref: "XqItem",
      refInFor: true,
      attrs: {
        form: _vm.form,
        dataSource: _vm.dataSource,
        item: _vm.formFields[item],
        dynamicData: _vm.dynamicData,
        editable: _vm.editable
      },
      on: {
        change: function change($event) {
          return _vm.XqItemChange($event, _vm.formFields[item], _vm.dataSource, _vm.dynamicData);
        }
      }
    })], 1), !_vm.formFields[item].isNecessary ? _c("div", {
      staticClass: "dn",
      staticStyle: {
        width: "70px",
        "flex-shrink": "0"
      }
    }, [_c("a-checkbox", {
      on: {
        change: function change(e) {
          return _vm.handleChange(e.target.value, item);
        }
      },
      model: {
        value: _vm.formFields[item].decorator.rules[0].required,
        callback: function callback($$v) {
          _vm.$set(_vm.formFields[item].decorator.rules[0], "required", $$v);
        },
        expression: "formFields[item].decorator.rules[0].required"
      }
    }, [_vm._v(" 必填 ")])], 1) : _c("div", {
      staticStyle: {
        width: "70px",
        height: "20px",
        "flex-shrink": "0"
      }
    })]) : _vm._e()]), _c("a-col", {
      staticClass: "parelative",
      attrs: {
        span: 2
      }
    }, [_vm.formFields[item] && !_vm.formFields[item].isRequired ? _c("div", {
      staticClass: "paAbsolut"
    }, [_c("a-icon", {
      staticClass: "tableIcon1 dn",
      attrs: {
        type: "close"
      },
      on: {
        click: function click($event) {
          return _vm.delClose(item);
        }
      }
    })], 1) : _vm._e()])], 1)], 1)]);
  }), 0)], 1)], 1), _c("div", {
    staticStyle: {
      height: "60px"
    }
  }), _c("div", {
    staticClass: "saveShow",
    style: {
      left: _vm.fixedLeft
    }
  }, [_c("a-space", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保 存")])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };