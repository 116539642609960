import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import { getArea } from "@/api/area";
export default {
  name: "AreaComponent",
  props: {
    region: {
      type: String,
      default: ""
    },
    onlyProvince: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    display: {
      type: String,
      default: "block"
    },
    offset: {
      type: Array,
      default: function _default() {
        return [0, 0];
      }
    },
    span: {
      type: Array,
      default: function _default() {
        return [24, 24];
      }
    }
  },
  data: function data() {
    return {
      province: [],
      area: [],
      values: []
    };
  },
  mounted: function mounted() {},
  methods: {
    loadArea: function loadArea(value, notResetArea) {
      var _this = this;
      if (this.onlyProvince) {
        this.submitValues();
        return;
      }
      getArea({
        parentId: value
      }).then(function (res) {
        if (notResetArea === null || !(notResetArea === true)) {
          _this.values[1] = "";
        }
        _this.area = _this.tree(res.data);
        _this.submitValues();
      });
    },
    tree: function tree(array, childKey, onlyLastChild) {
      var _this2 = this;
      if (array === null || array.length < 1) {
        return [];
      }
      array.map(function (item, index) {
        item["value"] = item.id + "";
        item["key"] = item.id;
        item["label"] = item.name;
        item["children"] = item[childKey];
        if (item.children) {
          item["disabled"] = onlyLastChild;
          _this2.tree(item.children, childKey, onlyLastChild);
        }
      });
      return array;
    },
    submitValues: function submitValues() {
      var region = this.values.join(",");
      if (region.lastIndexOf(",") === region.length - 1) {
        region = region.substring(0, region.length - 2);
      }
      this.$emit("update:region", region);
    }
  }
};