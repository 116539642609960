var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "uplaodBoxs"
  }, [_c("a-spin", {
    staticClass: "spin-content",
    attrs: {
      tip: "上传中。。。",
      spinning: _vm.uploading
    }
  }, [_c("div", {
    staticClass: "ant-upload-select-text-box",
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c("a-upload", {
    ref: "upload",
    attrs: {
      accept: ".xls, .xlsx, .xlsm",
      "file-list": _vm.fileList,
      remove: _vm.handleRemove,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("img", {
    staticStyle: {
      width: "139px",
      height: "106px"
    },
    attrs: {
      src: _vm.uploadImg
    }
  }), _c("div", {
    staticClass: "ant-modal-body"
  }, [_c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "#666"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#6a66f6"
    }
  }, [_vm._v("将文件拖到此处")]), _vm._v("或点击添加文件")]), _c("a-button", {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      icon: "plus",
      type: "primary"
    }
  }, [_vm._v("添加文件")])], 1)])], 1)]), _c("div", {
    staticClass: "tipBox"
  }, [_c("a-row", {
    staticStyle: {
      color: "#252525",
      "font-size": "16px",
      "font-weight": "500",
      "padding-bottom": "10px"
    }
  }, [_vm._v("导入说明")]), _c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", [_vm._v(" 1、请使用模版上传。 "), _c("span", {
    staticStyle: {
      "font-size": "12px",
      color: "#6a66f6",
      cursor: "pointer"
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("「个税人员上传模板」")])]), _c("div", [_vm._v("2、可以在扣缴客户端-人员信息采集-导出「客户端普通列表」，再将导出的表格导入本系统；")]), _c("div", [_vm._v("3、每次导入仅支持单税局导入 多税局请分不同文件导入；")])])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };